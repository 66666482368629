import React from 'react'

// import p from 'x/config/platform-specific'
import BaseSettingResetPasswordView from 'x/modules/setting/BaseSettingResetPasswordView'
import XCustomHeader from 'xui/components/XCustomHeader'
// import { Input } from 'react-native-elements'
import { COLORS } from 'x/config/styles'
import XText from 'xui/components/XText'
import XButton from 'xui/components/XButton'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import XCard from 'xui/components/XCard'
import XInput from 'xui/components/XInput'
import Box from 'xui/components/Box'
import HStack from 'xui/components/HStack'
import VStack from 'xui/components/VStack'
import * as util from 'x/utils/util'

export default abstract class BaseUISettingResetPasswordView extends BaseSettingResetPasswordView {
  _renderHeader = () => (
    <XCustomHeader
      title='เปลี่ยนรหัสผ่าน'
      headerLeftProps={{
        backIcon: true,
        onPressItem: () => util.navGoBack(this.props),
      }}
    />
  )

  _renderOldPasswordTextInput = () => {
    const { txtOldPassword } = this.state
    const txtPlaceholder = 'รหัสผ่านปัจจุบัน'
    return (
      <XInput
        secureTextEntry
        type='password'
        ref={this.txtOldPasswordRef}
        value={txtOldPassword}
        placeholder={txtPlaceholder}
        placeholderTextColor={COLORS.TEXT_INACTIVE}
        onChangeText={(text: string) => this.onChangeOldPasswordText(text)}
        // autoFocus
      />
    )
  }

  _renderNewPasswordTextInput = () => {
    const { txtNewPassword } = this.state
    const txtPlaceholder = 'รหัสผ่านใหม่'
    return (
      <XInput
        secureTextEntry
        type='password'
        value={txtNewPassword}
        placeholder={txtPlaceholder}
        placeholderTextColor={COLORS.TEXT_INACTIVE}
        onChangeText={(text: string) => this.onChangeNewPasswordText(text)}
      />
    )
  }

  _renderConfirmNewPasswordTextInput = () => {
    const { txtConfirmNewPassword } = this.state
    const txtPlaceholder = 'ยืนยันรหัสผ่านใหม่'
    return (
      <XInput
        secureTextEntry
        type='password'
        value={txtConfirmNewPassword}
        placeholder={txtPlaceholder}
        placeholderTextColor={COLORS.TEXT_INACTIVE}
        onChangeText={(text: string) => this.onChangeConfirmNewPasswordText(text)}
      />
    )
  }

  _renderFooter = () => (
    <HStack alignItems='center' justifyContent='center'>
      <XButton onPress={() => this._submitFunction()}>
        <XText color='white'>เปลี่ยนรหัสผ่าน</XText>
      </XButton>
      <Box w='2' />
      <XButton variant='ghost' onPress={() => this._handleForgottenPassword()}>
        <XText>ลืมรหัสผ่าน?</XText>
      </XButton>
    </HStack>
  )

  _renderBody = () => (
    <XCard p='2' w='full'>
      <VStack space='2' w='full'>
        {this._renderOldPasswordTextInput()}
        <Box h='8' />
        {this._renderNewPasswordTextInput()}
        {this._renderConfirmNewPasswordTextInput()}
        {this._renderFooter()}
      </VStack>
    </XCard>
  )

  render() {
    return (
      <XContainer>
        {this._renderHeader()}
        <XContent p='2' w='full'>
          {this._renderBody()}
        </XContent>
      </XContainer>
    )
  }
}
