// import EntypoIcon from 'react-native-vector-icons/Entypo'

// import BackIcon from '../../components/BackIcon'
// import XTabNavIcon from '../../components/XTabNavIcon'

import BaseUIPaymentSubscriptionPackageView from 'xui/modules/subscription/BaseUIPaymentSubscriptionPackageView'

export default class PaymentSubscriptionPackageView extends BaseUIPaymentSubscriptionPackageView {
  static displayName = 'PaymentSubscriptionPackageView'
}
