import React from 'react'
// import { Actionsheet, Text, ScrollView, Modal } from 'native-base'
// import { Actionsheet } from 'native-base'
import Box from 'xui/components/Box'
import ScrollView from 'xui/components/XScrollView'
import Modal from 'xui/components/Modal'
import { IActionSheetConfiguration } from 'x/index'
import { Dimensions, TouchableWithoutFeedback } from 'react-native'
import * as util from 'x/utils/util'
import XText from 'xui/components/XText'
import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import XPressable from 'xui/components/XPressable'

const DEVICE_HEIGHT = Dimensions.get('window').height
const EXPECTED_MAX_HEIGHT = Math.floor(DEVICE_HEIGHT / 2)
const MIN_ACTIONSHEET_HEIGHT = 150
const MAX_ACTIONSHEET_HEIGHT = EXPECTED_MAX_HEIGHT > MIN_ACTIONSHEET_HEIGHT ? EXPECTED_MAX_HEIGHT : MIN_ACTIONSHEET_HEIGHT

interface IActionSheetState {
  isOpen: boolean
  cfg?: IActionSheetConfiguration
  onSelect?: (index: number) => Promise<void> | void
}

class ActionSheet extends React.Component<{}, IActionSheetState> {
  static displayName = 'SingletonActionSheet'

  // static show(cfg: IActionSheetConfiguration, onSelect: (index: number) => Promise<void> | void) {
  //   ActionSheet.__singletonRef.__show(cfg, onSelect)
  // }

  // static hide() {
  //   ActionSheet.__singletonRef.__hide()
  // }

  constructor(props) {
    super(props)
    // ActionSheet.__singletonRef = this
    this.state = {
      isOpen: false,
      onSelect: undefined,
    }
  }

  show = async (cfg: IActionSheetConfiguration, onSelect: (index: number) => Promise<void> | void) => {
    await util.setStatePromise(this, { cfg, onSelect, isOpen: true })
    // this.setState({ cfg, onSelect },  () => {
    //   this.setState({ isOpen: true })
    // })
  }

  onClose = () => {
    this._onClose()
  }

  _onClose = async () => {
    const { onSelect } = this.state
    if (onSelect) {
      await onSelect(-1)
    }
    this.setState({ isOpen: false, cfg: undefined, onSelect: undefined })
  }

  _getOnPressHandler = (index: number) => {
    const { cfg } = this.state
    if (typeof cfg.cancelButtonIndex === 'number' && cfg.cancelButtonIndex === index) {
      return () => this.onClose()
    }
    return () => {
      this.state.onSelect(index)
      this.setState({ isOpen: false, cfg: undefined, onSelect: undefined })
    }
  }

  _renderItem = (option, index: number) => {
    const { isOpen } = this.state
    //   return (
    //   <Actionsheet.Item key={index.toString()} onPress={this._getOnPressHandler(index)}>
    //     {option}
    //   </Actionsheet.Item>
    // )
    return (
      <XPressable key={index.toString()} onPress={this._getOnPressHandler(index)}>
        <HStack w='full' px='4' py='3' borderTopWidth='1' borderTopColor='muted.100'>
          <XText w='full' fontSize='md'>
            {option}
          </XText>
        </HStack>
      </XPressable>
    )
  }

  _renderItems = () => {
    const { cfg } = this.state
    if (!cfg || !cfg.options || cfg.options.length === 0) {
      return null
    }

    return cfg.options.map(this._renderItem)
  }

  _renderTitle = () => {
    const { cfg } = this.state
    if (!cfg || !cfg.title) {
      return null
    }

    return (
      <HStack
        w='full'
        // h={60}
        px='3'
        py='2'
        justifyContent='center'>
        <XText
          variant='inactive'
          fontSize='md'
          // fontSize='16'
          // color='gray.500'
          // _dark={{
          //   color: 'gray.300',
          // }}
        >
          {cfg.title}
        </XText>
      </HStack>
    )
  }

  _renderContent = () => {
    const { isOpen, cfg } = this.state
    // if (!isOpen || !cfg) {
    //   return null
    // }
    if (!cfg) {
      return null
    }

    // return (
    //   <Actionsheet.Content>
    //     <ScrollView width='100%'>
    //       {this._renderTitle()}
    //       {this._renderItems()}
    //     </ScrollView>
    //   </Actionsheet.Content>
    // )

    return (
      <Box flex={1} w='full' h='full' justifyContent='flex-end'>
        <TouchableWithoutFeedback onPress={this._onClose}>
          <Box w='full' h='full' position='absolute' top='0' right='0' bottom='0' left='0' bg='gray.500:alpha.50' />
        </TouchableWithoutFeedback>
        <VStack
          w='full'
          // minH={MIN_ACTIONSHEET_HEIGHT}
          // maxH={MAX_ACTIONSHEET_HEIGHT}
          // bg='amber.100'
          px='2'>
          <VStack
            w='full'
            borderTopLeftRadius='lg'
            borderTopRightRadius='lg'
            borderTopWidth='1'
            borderTopColor='muted.200'
            px='3'
            py='2'
            _light={{ bg: 'white' }}
            _dark={{ bg: 'black' }}
            style={{
              minHeight: MIN_ACTIONSHEET_HEIGHT,
              maxHeight: MAX_ACTIONSHEET_HEIGHT,
            }}>
            {this._renderTitle()}
            <ScrollView w='full'>{this._renderItems()}</ScrollView>
          </VStack>
        </VStack>
      </Box>
    )
  }

  render() {
    const { isOpen } = this.state
    if (!isOpen) {
      return null
    }

    // if (Platform.OS === 'web') {
    //   return (
    //     <Modal isOpen={isOpen} onClose={this.onClose}>
    //       <Actionsheet isOpen onClose={this.onClose}>
    //         {this._renderContent()}
    //       </Actionsheet>
    //     </Modal>
    //   )
    // }

    // return (
    //   <Actionsheet isOpen={isOpen} onClose={this.onClose}>
    //     {this._renderContent()}
    //   </Actionsheet>
    // )

    return (
      <Modal transparent statusBarTranslucent visible={isOpen} animationType='slide' onRequestClose={this.onClose}>
        {this._renderContent()}
      </Modal>
    )
  }
}

export default ActionSheet
