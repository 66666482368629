import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as StoreActions from 'x/modules/store/StoreState'
import XContainer from 'xui/components/XContainer'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContent from 'xui/components/XContent'
import HStack from 'xui/components/HStack'
import Box from 'xui/components/Box'
import NB from 'x/config/nativebase'
import { getSelectedStore, getEditingStore } from 'x/redux/selectors'

import p from 'x/config/platform-specific'
import XCard from 'xui/components/XCard'
import XSettingRow from 'xui/components/XSettingRow'
import { ActionApiParams, ISelectedStoreMap, IXScreenProps } from 'x/types'
import _ from 'lodash'
import * as util from 'x/utils/util'

interface IMenuOrderBillOnlineShowAmountProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
  submitEditing: ActionApiParams
  // addNewStoreSetting: (newSetting: any) => void
}

const MenuOrderBillOnlineShowAmount: React.FC<IMenuOrderBillOnlineShowAmountProps> = props => {
  const { submitEditing, selectedStore } = props
  // const [visibleShippingTypes, setVisibleShippingTypes] = useState<number[]>([])
  // const [selectedShippingTypeIds, setSelectedShippingTypeIds] = useState<number[]>([])
  // const [isSetDataBeforeRender, setIsSetDataBeforeRender] = useState<boolean>(false)
  // // isUpdateSelectedShippingTypeIds ไว้ใช้สำหรับแยกว่าจะเป็นการ อัพเดตหรือสร้าง storesetting ตอนยิง api
  // const [isUpdateSelectedShippingTypeIds, setIsUpdateSelectedShippingTypeIds] = useState<boolean>(false)
  // const [isEditing, setIsEditing] = useState<boolean>(false)
  // const [isLoading, setIsLoading] = useState<boolean>(false)
  // const [isPickerVisible, setIsPickerVisible] = useState(false)
  // const selectedStore = getStoreSelected(p.op.getAppState())
  // console.log('selectedStore => ', selectedStore.toJS())
  // useEffect(() => {}, [])
  // console.log('ui_show_only_shipping_type_ids => ', ui_show_only_shipping_type_ids)
  // console.log('defaultVisibleShippingTypes => ', defaultVisibleShippingTypes)

  // eslint-disable-next-line react/no-unstable-nested-components
  const _renderHeader = () => (
    <XCustomHeader
      title={p.op.t('StoreSetting.MenuOrderBillOnlineShowAmount')}
      headerLeftProps={{ backIcon: true, onPressItem: () => util.navGoBack(props) }}
    />
  )

  const _onSubmit = async (valueInt: number) => {
    const store_id = selectedStore.get('id')
    const response = await new Promise(resolve => {
      // @ts-ignore
      submitEditing({
        body: { store_id, s_bill_online_show_amount: valueInt },
        successCallback: resolve,
        failedCallback: () => resolve(null),
      })
    })
    // console.log('response => ', response)
  }

  const isSwitchOnChange = async (newValue: { isValue: boolean; stateKey: string }) => {
    const isValueToInt = boolToInt(newValue.isValue)
    _onSubmit(isValueToInt)
  }

  const boolToInt = flag => (flag ? 1 : 0)

  // eslint-disable-next-line react/no-unstable-nested-components
  const _renderBody = () => {
    const s_bill_online_show_amount = selectedStore.get('s_bill_online_show_amount')
    let supTitleLable = p.op.t('StoreSetting.NoteFalseLabelOrderBillOnlineShowAmount')

    let switchValue = s_bill_online_show_amount
    if (_.isNumber(s_bill_online_show_amount)) {
      switchValue = s_bill_online_show_amount !== 0
    }
    if (switchValue) {
      supTitleLable = p.op.t('StoreSetting.NoteTrueLabelOrderBillOnlineShowAmount')
    }
    // \.log('supTitleLable => ', supTitleLable)
    // console.log('s_bill_online_show_amount => ', s_bill_online_show_amount)
    return (
      <XCard mx='2' mt='2' mb='2'>
        <HStack py='1.5'>
          <XSettingRow
            title={p.op.t('StoreSetting.LabelOrderBillOnlineShowAmount')}
            supTitle={supTitleLable}
            value={switchValue}
            onClick={(newValue: { isValue: boolean; stateKey: string }) => isSwitchOnChange(newValue)}
            stateKey='s_bill_online_show_amount'
          />
        </HStack>
      </XCard>
    )
  }

  return (
    <XContainer>
      {_renderHeader()}
      <XContent px='2' pb='2'>
        <Box w='full' mt='2' borderRadius='md' _light={{ bg: 'white' }} _dark={{ bg: NB.C.D.BG.CLEAR }}>
          {_renderBody()}
        </Box>
        {/* {isLoading ? VIEW_LOADING(windowWidth, windowHeight) : null} */}
      </XContent>
    </XContainer>
  )
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// // @ts-ignore
// MenuOrderBillOnlineShowAmount.navigationOptions = {
//   header: null,
// }

export default connect(
  state => ({
    selectedStore: getSelectedStore(state),
    editingStore: getEditingStore(state),
  }),
  dispatch => ({
    submitEditing: bindActionCreators(StoreActions.submitEditingStore, dispatch),
    resetEditingStore: bindActionCreators(StoreActions.resetEditingStore, dispatch),
    // storeActions: bindActionCreators(StoreState, dispatch),
  })
)(MenuOrderBillOnlineShowAmount)
