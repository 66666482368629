import BaseUIAddressView from 'xui/modules/address/BaseUIAddressView'
import * as util from 'x/utils/util'

export default class AddressView extends BaseUIAddressView {
  static displayName = 'AddressView'

  static navigationOptions = {
    // To hide the ActionBar/NavigationBar For App
    header: null,
  }
  // static navigationOptions = ({ navigation }) => {
  //   return null
  //   const { goBack, dispatch, state } = navigation
  //   const { mode, handleBtn } = state.params
  //   const { CUSTOMER_EDIT, STORE_EDIT } = CONS.ADDRESS_MODE_KEYS
  //   let title = ''
  //   if (_.has(CONS.ADDRESS_MODE_KEYS, mode)) {
  //     title = p.op.t(`AddressView.TitleByMode.${mode}`)
  //   }

  //   return {
  //     headerLeft: (
  //       <Button
  //         transparent
  //         onPress={() => {
  //           goBack(state.key)
  //         }}>
  //         <BackIcon />
  //       </Button>
  //     ),
  //     title,
  //     headerRight: handleBtn ? (
  //       <Button onPress={() => handleBtn()}>
  //         <Text style={{ color: COLORS.FORM_PRIMARY, paddingLeft: 16, paddingRight: 16 }}>บันทึก</Text>
  //       </Button>
  //     ) : null,
  //   }
  // }

  goBack = () => {
    // const { navigation } = this.props
    // const { state, goBack } = navigation
    // goBack(state.key)
    util.navGoBack(this.props)
  }
}
// static propTypes = {
//   // selectedAddresses: PropTypes.object, //Immutable List
//   selectedAddress: PropTypes.object.isRequired, //Immutable
//   editingAddress: PropTypes.object.isRequired, //Immutable

//   load: PropTypes.func.isRequired,
//   reset: PropTypes.func.isRequired,
//   onChange: PropTypes.func.isRequired,
//   saveNew: PropTypes.func.isRequired,
//   saveEditing: PropTypes.func.isRequired,

//   navigation: PropTypes.object.isRequired,
// }

// constructor(props) {
//   super(props)
//   this.state = {
//     currentRef: null,
//   }
//   this.inputRefs = []
//   this.scrollViewRef = null
// }

// componentDidMount() {
//   // util.hideAppLoadingScreen()
//   // setTimeout(() => util.focusFirstRefIfExisted(this.inputRefs, this.scrollViewRef), 200)
// }

// componentWillReceiveProps(nextProps) {
//   util.log('AddressView componentWillReceiveProps', nextProps)
// }

// componentDidUpdate(prevProps, prevState) {
//   const prevListSize = prevProps.selectedAddresses.size
//   const curListSize = this.props.selectedAddresses.size
//   if (prevListSize !== curListSize && curListSize > prevListSize && this.shouldAutoFocus === true) {
//     this.shouldAutoFocus = false
//     this.refs[`txtInput_address1_${curListSize - 1}`]._root.focus()
//   }
// }

// componentWillUnmount() {
//   // TODO :: ตอนนี้เอาออกไปก่อน เพราะมันมีปัญหาข้อมูลถูรีเซตหลังจากกด back เบื้องต้นใช้งาน ยังไม่เจอปัญหา
//   // this.props.reset()
// }

// _renderEditableAddressCard = (item: ISelectedStoreMap) => {
//   return (
//     <Card style={CARD_COMMON}>
//       <CardItem cardBody>
//         <Body>
//           <Form style={{ alignSelf: 'stretch' }}>
//             {CONS.CONTACT_EDITABLE_ADDRESS_KEYS.map((field, index) =>
//               this._renderEditableAddressItem({
//                 index,
//                 label: field.label,
//                 key: field.key,
//                 data: item.get(field.key) ? item.get(field.key).toString() : '',
//                 inputType: field.inputType || 'text', // Default is InputBox
//                 options: field.options || [],
//               })
//             )}
//           </Form>
//         </Body>
//       </CardItem>
//     </Card>
//   )
// }

// _renderEditableAddressItem = ({ index, label, key, data, inputType, options, required = null }) => {
//   const { onChange } = this.props
//   const displayLabel = required ? `${label}*` : label

//   if (inputType === 'switch') {
//     const switchFlag = false // FIXME: MOCK
//     return (
//       <ListItem inlineLabel key={`switchItem_${key}_${index}`} style={{ paddingRight: 10 }}>
//         <Left>
//           <Label style={s.txtLabel}>{displayLabel}</Label>
//         </Left>
//         <Body />
//         <Right style={STYLES.NO_MARGIN_PADDING}>
//           <View
//             style={[
//               STYLES.NO_MARGIN_PADDING,
//               { flex: 1, flexDirection: 'row', width: 90, height: 35, justifyContent: 'flex-end', alignItems: 'center' },
//             ]}>
//             {_.has(options, 'sideIcon') ? (
//               <View style={{ marginRight: 5, flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
//                 <Icon
//                   name={options.sideIcon}
//                   style={{
//                     fontSize: STYLES.FONT_ICON_NORMAL,
//                     color: data ? COLORS.FORM_PRIMARY : COLORS.TEXT_INACTIVE,
//                   }}
//                 />
//               </View>
//             ) : (
//               <View />
//             )}
//             <View style={{ flex: 1, flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center' }}>
//               <Switch
//                 ref={`switchInput_${key}_${index}`}
//                 value={switchFlag}
//                 // onValueChange={ () => onChange({ key, value: !switchFlag }) }
//               />
//             </View>
//           </View>
//         </Right>
//       </ListItem>
//     )
//   }
//   return (
//     <Item inlineLabel key={`txtItem_${key}_${index}`}>
//       <Label style={s.txtLabel}>{displayLabel}</Label>
//       <Input
//         // ref={`txtInput_${key}_${index}`}
//         ref={r => util.pushNativeBaseRef(this.inputRefs, `txtInput_${key}`, r)}
//         onFocus={() => this.setState({ currentRef: `txtInput_${key}` })}
//         style={s.txtRight}
//         value={data}
//         selectTextOnFocus={true}
//         onChangeText={value => onChange({ key, value })}
//       />
//     </Item>
//   )
// }

//   _renderEInput = (label: string, key: string, value: string, required: boolean) => {
//     const { onChange } = this.props
//     const placeholder = this._getPlaceholderInput(key)
//     return (
//       <EInput
//         key={key}
//         value={value}
//         onChangeText={(value: string) => onChange({ key, value })}
//         inputContainerStyle={{ height: 30, minHeight: 20 }}
//         label={label}
//         labelStyle={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE, fontWeight: 'bold' }}
//         placeholder={placeholder}
//         errorStyle={{ color: 'red', height: 12, fontSize: STYLES.FONT_SIZE_SMALLER, margin: 0 }}
//         errorMessage=' '
//         inputStyle={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.BLACK }}
//       />
//     )
//   }

//   _renderEntityInput = () => {
//     // const { indexEntityOption, entityTxt } = this.state
//     const { editingAddress, onChange } = this.props
//     const indexEntityOption = _.isNil(editingAddress.get(`legal_entity_type`)) ? 0 : editingAddress.get(`legal_entity_type`)
//     const entityTxt = _.isNil(editingAddress.get(`legal_entity_id`)) ? '' : editingAddress.get(`legal_entity_id`)
//     const label = indexEntityOption === 1 ? `เลขประจำตัวประชาชน` : `เลขประจำตัวผู้เสียภาษี`
//     return (
//       <EInput
//         value={entityTxt}
//         onChangeText={(value: string) => onChange({ key: 'legal_entity_id', value })}
//         inputContainerStyle={{ height: 30, minHeight: 20 }}
//         label={label}
//         labelStyle={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE, fontWeight: 'bold' }}
//         placeholder={`ระบุ${label}`}
//         errorStyle={{ color: 'red', height: 12, fontSize: STYLES.FONT_SIZE_SMALLER, margin: 0 }}
//         errorMessage=' '
//         inputStyle={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.BLACK }}
//       />
//     )
//   }

//   _renderEntityType = () => {
//     // const { indexEntityOption } = this.state
//     const { editingAddress, onChange } = this.props
//     const indexEntityOption = _.isNil(editingAddress.get(`legal_entity_type`)) ? 0 : editingAddress.get(`legal_entity_type`)
//     return (
//       <View style={{ paddingLeft: 8, paddingBottom: 8 }}>
//         <View>
//           <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE, fontWeight: 'bold' }}>{`สภาพบุคคล`}</Text>
//         </View>
//         <View style={{ width: 300, justifyContent: 'center', alignItems: 'center' }}>
//           <Segment
//             onSegmentChange={newIndex => onChange({ key: `legal_entity_type`, value: newIndex })}
//             options={this.ENTITY_OPTION}
//             selectedIndex={indexEntityOption}
//           />
//         </View>
//       </View>
//     )
//   }

//   _renderInputNote = () => {
//     const { editingAddress, onChange } = this.props
//     // const { noteTxt } = this.state
//     const noteValue = _.isNil(editingAddress.get(`note`)) ? '' : editingAddress.get(`note`)
//     return (
//       <EInput
//         multiline={true}
//         value={noteValue}
//         onChangeText={(value: string) => onChange({ key: `note`, value })}
//         inputContainerStyle={{ height: 60, minHeight: 20 }}
//         label={`หมายเหตุ`}
//         labelStyle={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE, fontWeight: 'bold' }}
//         placeholder={`อื่นๆ`}
//         errorStyle={{ color: 'red', height: 12, fontSize: STYLES.FONT_SIZE_SMALLER, margin: 0 }}
//         errorMessage=' '
//         inputStyle={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.BLACK }}
//       />
//     )
//   }

//   _checkBoxPrimary = () => {
//     // const { checkBoxPrimary } = this.state
//     const { editingAddress, onChange } = this.props
//     // const { noteTxt } = this.state
//     const isPrimary = _.isNil(editingAddress.get(`is_primary`)) ? false : editingAddress.get(`is_primary`)
//     const isPrimaryChange = !isPrimary
//     return (
//       <View style={{ paddingLeft: 8, backgroundColor: COLORS.BG_LIGHT_GREY, paddingBottom: 2 }}>
//         <TouchableOpacity
//           style={{ flexDirection: 'row', paddingTop: 4, paddingBottom: 4, width: 150 }}
//           onPress={() => onChange({ key: 'is_primary', value: isPrimaryChange })}>
//           <FAIcon
//             style={{
//               flex: 0,
//               width: 30,
//               minWidth: 30,
//               fontSize: STYLES.FONT_ICON_NORMAL,
//               color: COLORS.APP_MAIN,
//             }}
//             name={!isPrimary ? 'square-o' : 'check-square-o'}
//           />
//           <Text
//             style={{
//               fontSize: STYLES.FONT_SIZE_NORMAL,
//               color: COLORS.TEXT_ACTIVE,
//               textAlign: 'center',
//               paddingTop: 4,
//             }}>{`เลือกเป็นที่อยู่หลัก`}</Text>
//         </TouchableOpacity>
//       </View>
//     )
//   }

//   render() {
//     // util.logRender(this)
//     // const { indexEntityOption } = this.state
//     const { editingAddress } = this.props
//     const indexEntityOption = _.isNil(editingAddress.get(`legal_entity_type`)) ? 0 : editingAddress.get(`legal_entity_type`)
//     // console.log(`editingAddress`, editingAddress.toJS())
//     return (
//       // <XContainer>
//       <View style={{ flex: 1, paddingTop: 8 }}>
//         <ScrollView>
//           {this._checkBoxPrimary()}
//           {CONS.CONTACT_EDITABLE_ADDRESS_KEYS.map(field => {
//             const label = field.label
//             const key = field.key
//             const value = editingAddress.get(field.key) ? editingAddress.get(field.key).toString() : ''
//             const required = field.required
//             if (field.inputType === 'text') {
//               return this._renderEInput(label, key, value, required)
//             }
//           })}

//           {this._renderEntityType()}
//           {indexEntityOption !== 0 ? this._renderEntityInput() : null}
//           {this._renderInputNote()}
//           {/* <StickyKeyboardTab arrayOfRefs={this.inputRefs} currentRef={this.state.currentRef} scrollRef={this.scrollViewRef} /> */}
//         </ScrollView>
//       </View>
//       // </XContainer>
//     )
//   }
// }

/* <Content
          ref={r => r && (this.scrollViewRef = r._root)}
          keyboardShouldPersistTaps={CONS.KEYBOARD_PERSIST_TAB_MODE}
          disableKBDismissScroll={false}
          enableResetScrollToCoords={false}
          extraScrollHeight={50}>
          <List style={{ width: '100%', paddingLeft: 10, paddingRight: 10 }}>{this._renderEditableAddressCard(editingAddress)}</List>
        </Content> */
