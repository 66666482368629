import React from 'react'
// import PropTypes from 'prop-types'

import _ from 'lodash'
import { List, Map } from 'immutable'

import p from 'x/config/platform-specific'
import { E_MSG_TYPE } from 'x/utils/op-interface'
import * as util from 'x/utils/util'
import { APP_CONFIG } from 'x/config/mode'
import * as xFmt from '../../utils/formatter'
import CONS from '../../config/constants'
import { COLORS } from '../../config/styles'

import {
  IBaseProductListViewParams,
  IBaseProductListViewProps,
  IBaseProductListViewState,
  IProductListViewOnPressParams,
  ICategory,
  categoryByStore,
  ISubmitDrawerCategory,
  IProductItemDataMap,
  IProductItemVariantDataMap,
} from '../../index'
import { log, isDiff, setStatePromise } from '../../utils/util'

const {
  ADD,
  EDIT_SELF,
  EDIT_SELLER,
  PULL_PRODUCT,
  VIEW_SELF,
  VIEW_SELLER,
  PULL_MY_PRODUCT_TO_ORDER,
  PULL_SELLER_PRODUCT_TO_ORDER,
  VIEW_VOLUME_DISCOUNT,
} = CONS.PRODUCT_VIEW_MODE

const { COMPUTED_USE_PRODUCT_SKU, COMPUTED_USE_PRODUCT_UPC } = CONS.STORE_SETTINGS

export default abstract class BaseProductListView extends React.Component<IBaseProductListViewProps, IBaseProductListViewState> {
  inProcess: boolean

  currScrollPos: number

  lastSelectedProductOffset: number

  lastSearchedProductOffset: number

  isTxtSearchModified: boolean

  lastTxtSearch: string

  MIN_SEARCH_TEXT: number // local constants

  returnCategoryInfo: boolean

  abstract _getParams(): IBaseProductListViewParams

  abstract _onShowQuickAddProductSelector(
    BTN_LABELS: string[],
    product: IProductItemDataMap,
    variants: List<any>,
    DISPLAY_VARIANTS: any[]
  ): Promise<void>

  abstract handlePressPullToMyStore(params: IProductListViewOnPressParams): void | Promise<void>

  abstract handlePressPullMyToOrder(params: IProductListViewOnPressParams): void | Promise<void>

  abstract handlePressPullSellerToOrder(params: IProductListViewOnPressParams): void | Promise<void>

  abstract handlePressViewMyProduct(params: IProductListViewOnPressParams): void | Promise<void>

  abstract handlePressEtcCase(params: IProductListViewOnPressParams): void | Promise<void>

  abstract doNavToProductDetailAfterSearchMatchOneProduct(product?: { id: number; [key: string]: any }): void

  abstract _beforeInitDidMount(): Promise<void>

  abstract _afterInitDidMount(): Promise<void>

  abstract _doAfterDeactivateSearch(): void

  protected constructor(props: IBaseProductListViewProps) {
    super(props)
    this.state = {
      txtSearch: '',
      isSearch: false,
      isSearching: false,
      searchProducts: List([]),
      refreshing: false,
      // products: [],
      isVisibleBarcodeCamera: false,
      isVisibleProductCategoryFilterModal: false,
      catLevelCategoryList: null,
      catSeletedCategory: null,
      catSupCategory: null,
      allChildrenCategory: true,
      productCategoryScrollViewFlags: {
        isVisibleScrollToBeginButton: false,
        isVisibleScrollToEndButton: false,
      },
      mkp_ch_ids: [],
    }
    this.currScrollPos = 0
    this.lastSelectedProductOffset = null
    this.lastSearchedProductOffset = null

    this._onRefresh = this._onRefresh.bind(this)
    this._isLoading = this._isLoading.bind(this)
    this._hasMoreRow = this._hasMoreRow.bind(this)
    this._isDoneLoadingAndHasZeroRow = this._isDoneLoadingAndHasZeroRow.bind(this)
    this._handleLoadMore = this._handleLoadMore.bind(this)
    this._onSearchProducts = this._onSearchProducts.bind(this)
    this._onPressAddOneProductToCart = this._onPressAddOneProductToCart.bind(this)
    this._pullProductToOrder = this._pullProductToOrder.bind(this)
    this._getFetchStatus = this._getFetchStatus.bind(this)
    this._deactivateSearch = this._deactivateSearch.bind(this)
    this._onProductPress = this._onProductPress.bind(this)
    this._getIsAvailableBarcodeSearch = this._getIsAvailableBarcodeSearch.bind(this)

    // // Abstract binding
    // this.handlePressPullToMyStore = this.handlePressPullToMyStore.bind(this)
    // this.handlePressPullMyToOrder = this.handlePressPullMyToOrder.bind(this)
    // this.handlePressPullSellerToOrder = this.handlePressPullSellerToOrder.bind(this)
    // this.handlePressViewMyProduct = this.handlePressViewMyProduct.bind(this)
    // this.handlePressEtcCase = this.handlePressEtcCase.bind(this)

    this.MIN_SEARCH_TEXT = 3
    this.returnCategoryInfo = false
  }

  async componentDidMount() {
    await this._beforeInitDidMount()
    await this._initDidMount()
    await this._afterInitDidMount()
    await this._configCategory()
  }

  shouldComponentUpdate(nextProps: IBaseProductListViewProps, nextState: IBaseProductListViewState) {
    const isStateDiff = isDiff(this.state, nextState, [
      'txtSearch',
      'isSearch',
      'refreshing',
      'isSearching',
      'isVisibleBarcodeCamera',
      'isVisibleProductCategoryFilterModal',
      'catLevelCategoryList',
      'catSeletedCategory',
      'productCategoryScrollViewFlags',
    ])
    if (isStateDiff) {
      return true
    }

    const isPropsDiff = isDiff(this.props, nextProps, [
      'selectedProducts',
      'searchedProducts',
      // 'selectedStoreID',
      'shouldFetch',
      'selectedFetchStatus',
      'searchedFetchStatus',
      'shouldFetch',
      'resetSearchResults',
    ])

    return isPropsDiff
  }

  // componentWillReceiveProps(nextProps) {
  //   this.isTxtSearchModified = this.lastTxtSearch !== this.state.txtSearch // not using nextState.txtSearch as the state is already changed
  //   if (nextProps.shouldFetch) {
  //     if (this.state.txtSearch && this.state.txtSearch.length > 0) {
  //       // deactivate search if being displayed
  //       this._deactivateSearch()
  //     }
  //     this._fetchData({ isLoadMore: false })
  //   }
  // }
  componentDidUpdate(prevProps, prevState) {
    this.isTxtSearchModified = this.lastTxtSearch !== this.state.txtSearch // not using nextState.txtSearch as the state is already changed
    if (this.props.shouldFetch) {
      if (this.state.txtSearch && this.state.txtSearch.length > 0) {
        // deactivate search if being displayed
        this._deactivateSearch()
      }
      this._fetchData({ isLoadMore: false })
    }
  }

  _initDidMount = async (): Promise<void> => {
    const { selectedStore, fetchMyStore, productListClear } = this.props
    const { store_id, skipInitClearProductList = false, initTxtSearch = null } = this._getParams()

    if (!selectedStore.get('id')) {
      const myStore = await new Promise((resolveInitMyStore) => {
        fetchMyStore({
          body: { store_id },
          successCallback: resolveInitMyStore,
          failedCallback: () => resolveInitMyStore(null),
        })
      })

      if (!myStore) {
        // Worse case
        p.op.alert('ไม่สามารถโหลดข้อมูลได้', 'กรุณากลับไปที่ร้านฉันแล้วลองใหม่อีกครั้ง')
        return
      }
    }

    if (!skipInitClearProductList) {
      productListClear()
    }

    await this._onRefresh()

    if (_.isString(initTxtSearch)) {
      await setStatePromise(this, { txtSearch: initTxtSearch, isSearch: true, isSearching: false })
    }
  }

  _readyToRefresh = async () => {
    this.returnCategoryInfo = true
    await this._onRefresh()
  }

  async _onRefresh(): Promise<void> {
    await setStatePromise(this, { refreshing: true })
    // this.setState({ refreshing: true }, () => this._fetchData({ isLoadMore: false }))
    await this._fetchData({ isLoadMore: false })
    await setStatePromise(this, { refreshing: false })
  }

  _fetchData = async ({ isLoadMore = false, queryTxt = null, isNewSearch = false, isBarcodeSearch = false }): Promise<void> => {
    // log('_fetchData.isNewSearch: ' + isNewSearch)
    // log('BaseProductListView._fetchData.queryTxt: ' + queryTxt)
    const { mode, store_id, seller_store_id, ug_id, pg_ids, volumeDiscountID } = this._getParams()
    log('BaseProductListView _fetchData mode => ', mode)
    log('BaseProductListView _fetchData store_id => ', store_id)
    log('BaseProductListView _fetchData seller_store_id => ', seller_store_id)
    const { selectedStore } = this.props
    // const s_use_retail_price = selectedStore.get('s_use_retail_price') || false
    // const s_use_retail_price = false // เลิกใช้ flag นี้
    const role_id = selectedStore.get('role_id')

    // const afterFetchedCallback = (res: Response) => {
    //   this.setState({ refreshing: false }) // local callback
    //   if (_.isFunction(callback)) {
    //     // external callack
    //     callback(res)
    //   }
    // }

    if (mode && store_id) {
      const fetchParams: { [key: string]: any } = {
        store_id,
        ug_id,
        pg_ids,

        queryTxt,
        mode: isLoadMore ? CONS.PRODUCTS_FETCH_MODE_MORE : CONS.PRODUCTS_FETCH_MODE_INIT,
        isNewSearch,
        // r: s_use_retail_price && order_use_retail_price ? 2 : 1,
        // callback: afterFetchedCallback,
      }

      if (mode === PULL_PRODUCT) {
        fetchParams.store_id = store_id
        fetchParams.seller_store_id = seller_store_id
        // fetchParams = {
        //   ...fetchParams,
        //   store_id,
        //   seller_store_id,
        // }
      } else if (mode === PULL_MY_PRODUCT_TO_ORDER) {
        fetchParams.v = 1 // Request for variants
        fetchParams.role_id = role_id
      } else if (mode === PULL_SELLER_PRODUCT_TO_ORDER) {
        fetchParams.store_id = seller_store_id
        fetchParams.v = 1 // Request for variants
        fetchParams.role_id = role_id
      } else if (mode === VIEW_SELLER) {
        fetchParams.store_id = seller_store_id // Request for variants
        fetchParams.role_id = role_id
      } else if (mode === VIEW_VOLUME_DISCOUNT) {
        fetchParams.vd_id = volumeDiscountID
      } else {
        fetchParams.role_id = role_id
      }

      if (isBarcodeSearch) {
        if (selectedStore.get(COMPUTED_USE_PRODUCT_SKU)) {
          fetchParams.isSKU = true
        }
        // if (selectedStore.get(COMPUTED_USE_PRODUCT_UPC)) {
        //   fetchParams.isUPC = true
        // }

        // Always search default with UPC barcode
        fetchParams.isUPC = true

        // fetchParams.isBarcode = true
        if (_.includes([VIEW_SELF, VIEW_SELLER, PULL_MY_PRODUCT_TO_ORDER, PULL_SELLER_PRODUCT_TO_ORDER], mode)) {
          fetchParams.returnDetailIfMatchOne = true
        }
      }
      // สำหรับ channels filter
      if (this.state.mkp_ch_ids.length > 0) {
        fetchParams.mkp_ch_ids = this.state.mkp_ch_ids
      }
      // @ts-ignore ยังไม่ได้ประกาศ type สำหรับฟังก์ชั่น
      await this._fetchProductList(fetchParams)
      // this.setState({ refreshing: false })
    } else {
      p.op.alert('เกิดข้อผิดพลาด', 'มีการเข้าถึงที่ไม่ถูกต้อง กรุณาเลือกร้านค้าใหม่ แล้วลองดูอีกครั้ง')
    }

    // if (mode && store_id) {
    //   if (mode === PULL_PRODUCT) {
    //     this._fetchProductList({
    //       ug_id,
    //       pg_ids,
    //       store_id,
    //       seller_store_id,
    //       queryTxt,
    //       mode: isLoadMore ? CONS.PRODUCTS_FETCH_MODE_MORE : CONS.PRODUCTS_FETCH_MODE_INIT,
    //       isNewSearch,
    //       r: s_use_retail_price && order_use_retail_price ? 2 : 1,
    //       callback: afterFetchedCallback,
    //     })
    //   } else if (mode === PULL_MY_PRODUCT_TO_ORDER) {
    //     this._fetchProductList({
    //       ug_id,
    //       pg_ids,
    //       store_id,
    //       v: 1,
    //       queryTxt,
    //       role_id,
    //       mode: isLoadMore ? CONS.PRODUCTS_FETCH_MODE_MORE : CONS.PRODUCTS_FETCH_MODE_INIT,
    //       isNewSearch,
    //       r: s_use_retail_price && order_use_retail_price ? 2 : 1,
    //       callback: afterFetchedCallback,
    //     }) // Request for variants
    //   } else if (mode === PULL_SELLER_PRODUCT_TO_ORDER) {
    //     this._fetchProductList({
    //       ug_id,
    //       pg_ids,
    //       store_id: seller_store_id,
    //       v: 1,
    //       queryTxt,
    //       role_id,
    //       mode: isLoadMore ? CONS.PRODUCTS_FETCH_MODE_MORE : CONS.PRODUCTS_FETCH_MODE_INIT,
    //       isNewSearch,
    //       r: s_use_retail_price && order_use_retail_price ? 2 : 1,
    //       callback: afterFetchedCallback,
    //     }) // Request for variants
    //   } else if (mode === VIEW_SELLER) {
    //     this._fetchProductList({
    //       ug_id,
    //       pg_ids,
    //       store_id: seller_store_id,
    //       // seller_store_id,
    //       queryTxt,
    //       role_id,
    //       mode: isLoadMore ? CONS.PRODUCTS_FETCH_MODE_MORE : CONS.PRODUCTS_FETCH_MODE_INIT,
    //       isNewSearch,
    //       r: s_use_retail_price && order_use_retail_price ? 2 : 1,
    //       callback: afterFetchedCallback,
    //     })
    //   } else {
    //     this._fetchProductList({
    //       ug_id,
    //       pg_ids,
    //       store_id,
    //       queryTxt,
    //       role_id,
    //       mode: isLoadMore ? CONS.PRODUCTS_FETCH_MODE_MORE : CONS.PRODUCTS_FETCH_MODE_INIT,
    //       isNewSearch,
    //       r: s_use_retail_price && order_use_retail_price ? 2 : 1,
    //       callback: afterFetchedCallback,
    //     })
    //   }
    //   // this.setState({ refreshing: false })
    // } else {
    //   p.op.alert('เกิดข้อผิดพลาด', 'มีการเข้าถึงที่ไม่ถูกต้อง กรุณาเลือกร้านค้าใหม่ แล้วลองดูอีกครั้ง')
    // }
  }

  // // FIXME: Refactor move from ProductState
  // _fetchProductList({
  //   ug_id,
  //   pg_ids,
  //   store_id,
  //   seller_store_id,
  //   mode,
  //   v,
  //   queryTxt,
  //   isNewSearch = false,
  //   r = 0,
  //   callback = null,
  // }) {
  //   const { selectedFetchStatus, searchedFetchStatus, fetchProducts, selectedStore } = this.props
  //
  //   // TODO: Keng 1) Store currQueryTxt on reduxStore 2) Reset searchedFetchStatus && seachedProducts if queryTxt the new is diff from old
  //   // log('BaseOrderListState.fetchOrders')
  //   // log(getOrdersFetchStatusForTabName)
  //   // log('fetchProducts.storeId: ' + storeId + ' orderStoreId: ' + orderStoreId + ' mode: ' + mode + ' tabName: ' + tabName + ' type: ' + type)
  //   // log('fetchProducts.seller_store_id: ' + seller_store_id + ' mode: ' + mode + ' queryTxt: ' + queryTxt + ' isNewSearch: ' + isNewSearch)
  //
  //   const payload = v ? { store_id, v } : { store_id }
  //   if (seller_store_id) {
  //     payload.seller_store_id = seller_store_id
  //   }
  //
  //   if (r) {
  //     payload.r = r
  //   }
  //
  //   let getFetchStatus
  //   if (queryTxt && queryTxt.length > 0) {
  //     getFetchStatus = searchedFetchStatus
  //   } else {
  //     getFetchStatus = selectedFetchStatus
  //   }
  //
  //   const shouldInitLoadWithLimit = getFetchStatus.get(CONS.PRODUCT_FETCH_STATUS.SHOULD_INIT_LOAD_WITH_LIMIT)
  //   const shouldOverrideLimit =
  //     shouldInitLoadWithLimit && !isNaN(shouldInitLoadWithLimit) && shouldInitLoadWithLimit > 0
  //   // v = request for variant info
  //   // c = request for product count (1 = true)
  //   switch (mode) {
  //     case CONS.PRODUCTS_FETCH_MODE_INIT:
  //     case CONS.PRODUCTS_FETCH_MODE_REFRESH:
  //     case CONS.PRODUCTS_SEARCH_MODE_INIT:
  //     case CONS.PRODUCTS_SEARCH_MODE_REFRESH: {
  //       payload.offset = 0
  //       break
  //     }
  //     case CONS.PRODUCTS_FETCH_MODE_MORE:
  //     case CONS.PRODUCTS_SEARCH_MODE_MORE: {
  //       if (shouldOverrideLimit) {
  //         payload.offset = 0
  //       } else {
  //         // let status = getFetchStatus(store.getState())
  //         const status = getFetchStatus
  //         // log('PRODUCTS_FETCH_MODE_MORE.status')
  //         // log(status)
  //         if (status) {
  //           // log('****Found status')
  //           // log(status.toJS())
  //         }
  //         payload.offset = status ? status.get(CONS.PRODUCT_FETCH_STATUS.OFFSET) : 0
  //       }
  //       break
  //     }
  //     default:
  //       // log("!!!!!!Default offset should not happen")
  //       payload.offset = 0
  //       break
  //   }
  //   if (payload.offset === 0) {
  //     payload.c = 1 // request for product count for the first call
  //     if (shouldOverrideLimit) {
  //       payload.limit = shouldInitLoadWithLimit
  //     } else {
  //       payload.limit = CONS.PRODUCTS_FETCH_LIMIT_INIT
  //     }
  //   } else {
  //     payload.limit = CONS.PRODUCTS_FETCH_LIMIT_MORE
  //   }
  //
  //   if (queryTxt) {
  //     payload.queryTxt = queryTxt
  //   }
  //   // log('fetchProducts for mode: ' + mode)
  //   // log(payload)
  //   // log('ProductState.isNewSearch')
  //
  //   // backend require if role_id === 2 (helper)
  //   const role_id = selectedStore.get('role_id') || null
  //   if (role_id === 2) {
  //     // ถ้าเป็นการ fetch สินค้าของร้านฉันให้ส่ง role_id ของ selectedStore ของฉันไป
  //     payload.role_id = role_id
  //   }
  //
  //   if (ug_id && ug_id > 0) {
  //     payload.user_group_id = ug_id
  //   }
  //
  //   if (pg_ids && _.isArray(pg_ids)) {
  //     payload.product_group_ids = []
  //     pg_ids.forEach(pg_id => {
  //       if (pg_id && pg_id > 0) {
  //         payload.product_group_ids.push(pg_id)
  //       }
  //     })
  //     if (payload.product_group_ids.length === 0) {
  //       delete payload.product_group_ids
  //     }
  //   }
  //
  //   if (payload.store_id) {
  //     fetchProducts({ payload, isNewSearch }, callback)
  //   }
  // }
  _fetchProductList = async ({
    ug_id,
    pg_ids,
    store_id,
    seller_store_id,
    mode,
    v,
    queryTxt,
    isNewSearch = false,
    r = 0,
    returnDetailIfMatchOne = false,
    isUPC = false,
    isSKU = false,
    vd_id,
    mkp_ch_ids = [],
    // isBarcode = false,
  }): Promise<void> => {
    const { selectedFetchStatus, searchedFetchStatus, fetchProducts, selectedStore } = this.props

    // TODO: Keng 1) Store currQueryTxt on reduxStore 2) Reset searchedFetchStatus && seachedProducts if queryTxt the new is diff from old
    // log('BaseOrderListState.fetchOrders')
    // log(getOrdersFetchStatusForTabName)
    // log('fetchProducts.storeId: ' + storeId + ' orderStoreId: ' + orderStoreId + ' mode: ' + mode + ' tabName: ' + tabName + ' type: ' + type)
    // log('fetchProducts.seller_store_id: ' + seller_store_id + ' mode: ' + mode + ' queryTxt: ' + queryTxt + ' isNewSearch: ' + isNewSearch)
    let body: { [key: string]: any }

    if (v) {
      body = { store_id, v }
    } else {
      body = { store_id }
    }

    if (seller_store_id) {
      body.seller_store_id = seller_store_id
    }

    if (r) {
      body.r = r
    }

    if (vd_id) {
      body.vd_id = vd_id
    }

    if (_.includes([PULL_MY_PRODUCT_TO_ORDER], this._getParams().mode)) {
      body.mode = this._getParams().mode
    }

    let getFetchStatus
    if (queryTxt && queryTxt.length > 0) {
      getFetchStatus = searchedFetchStatus
    } else {
      getFetchStatus = selectedFetchStatus
    }

    const shouldInitLoadWithLimit = getFetchStatus.get(CONS.PRODUCT_FETCH_STATUS.SHOULD_INIT_LOAD_WITH_LIMIT)
    const shouldOverrideLimit = shouldInitLoadWithLimit && !isNaN(shouldInitLoadWithLimit) && shouldInitLoadWithLimit > 0
    // v = request for variant info
    // c = request for product count (1 = true)
    switch (mode) {
      case CONS.PRODUCTS_FETCH_MODE_INIT:
      case CONS.PRODUCTS_FETCH_MODE_REFRESH:
      case CONS.PRODUCTS_SEARCH_MODE_INIT:
      case CONS.PRODUCTS_SEARCH_MODE_REFRESH: {
        body.offset = 0
        break
      }
      case CONS.PRODUCTS_FETCH_MODE_MORE:
      case CONS.PRODUCTS_SEARCH_MODE_MORE: {
        if (shouldOverrideLimit) {
          body.offset = 0
        } else {
          // let status = getFetchStatus(store.getState())
          const status = getFetchStatus
          // log('PRODUCTS_FETCH_MODE_MORE.status')
          // log(status)
          if (status) {
            // log('****Found status')
            // log(status.toJS())
          }
          body.offset = status ? status.get(CONS.PRODUCT_FETCH_STATUS.OFFSET) : 0
        }
        break
      }
      default:
        // log("!!!!!!Default offset should not happen")
        body.offset = 0
        break
    }
    if (body.offset === 0) {
      body.c = 1 // request for product count for the first call
      if (shouldOverrideLimit) {
        body.limit = shouldInitLoadWithLimit
      } else {
        body.limit = CONS.PRODUCTS_FETCH_LIMIT_INIT
      }
    } else {
      body.limit = CONS.PRODUCTS_FETCH_LIMIT_MORE
    }

    if (queryTxt) {
      body.queryTxt = queryTxt
    }
    // log('fetchProducts for mode: ' + mode)
    // log(body)
    // log('ProductState.isNewSearch')

    // backend require if role_id === 2 (helper)
    const role_id = selectedStore.get('role_id') || null
    if (role_id === 2) {
      // ถ้าเป็นการ fetch สินค้าของร้านฉันให้ส่ง role_id ของ selectedStore ของฉันไป
      body.role_id = role_id
    }

    if (ug_id && ug_id > 0) {
      body.user_group_id = ug_id
    }

    if (pg_ids && _.isArray(pg_ids)) {
      body.product_group_ids = []
      pg_ids.forEach((pg_id) => {
        if (pg_id && pg_id > 0) {
          body.product_group_ids.push(pg_id)
        }
      })
      if (body.product_group_ids.length === 0) {
        delete body.product_group_ids
      }
    }

    if (returnDetailIfMatchOne) {
      body.returnDetailIfMatchOne = true
    }

    // if (isBarcode) {
    //   body.isBarcode = true
    // }
    if (isUPC) {
      body.isUPC = true
    }

    if (isSKU) {
      body.isSKU = true
    }

    const selectedCatehory = this.state.catSeletedCategory
    if (!_.isNil(selectedCatehory)) {
      body.category_id = selectedCatehory.id
      body.withSubCategories = this.state.allChildrenCategory
    }

    if (this.returnCategoryInfo) {
      body.returnCategoryInfo = true
      this.returnCategoryInfo = false
    }

    if (mkp_ch_ids.length > 0) {
      body.mkp_ch_ids = mkp_ch_ids
    }

    if (body.store_id) {
      const response: any = await new Promise((resolveFetch) => {
        fetchProducts({ body, isNewSearch, successCallback: resolveFetch, failedCallback: () => resolveFetch(null) })
      })

      if (response && !_.isEmpty(response)) {
        // Maybe do something
        if (_.isArray(response.products) && response.products.length === 1 && returnDetailIfMatchOne) {
          // if (response.product && returnDetailIfMatchOne) {
          this.doNavToProductDetailAfterSearchMatchOneProduct(response.products[0])
        } else if (_.isObject(response.product) && response.product.id && returnDetailIfMatchOne) {
          // if (response.product && returnDetailIfMatchOne) {
          this.doNavToProductDetailAfterSearchMatchOneProduct(response.product)
        }
      }
    }
  }

  // _onProductPress(product_id: number) {
  _onProductPress(data: Map<string, any>): void {
    const params = this._getParams()
    const { mode } = params
    if (!Map.isMap(data)) {
      return
    }
    // log(this, '_onProductPress data.toJS() => ', data.toJS())

    const product_id = data.get('id')
    if (!product_id) {
      p.op.alert('ไม่พบข้อมูลสินค้า', 'กรุณาโหลดรายการสินค้าใหม่อีกครั้ง')
      return
    }

    if (mode === PULL_MY_PRODUCT_TO_ORDER) {
      // Safety frist for pull product to order with empty price
      // const hasFirstPrice = data.hasIn(['v', 0, 's', 0, 'p'])
      // log(this, '_onProductPress hasFirstPrice => ', hasFirstPrice)
      // if (!hasFirstPrice) {
      //   return
      // }
      this.handlePressPullMyToOrder({ ...params, product_id })
    } else if (mode === PULL_SELLER_PRODUCT_TO_ORDER) {
      this.handlePressPullSellerToOrder({ ...params, product_id })
    } else if (mode === PULL_PRODUCT) {
      this.handlePressPullToMyStore({ ...params, product_id })
    } else if (mode === VIEW_SELF) {
      this.handlePressViewMyProduct({ ...params, product_id })
    } else {
      this.handlePressEtcCase({ ...params, product_id })
    }
  }

  // _onPressAddOneProductToCart(product, isShowRetailPrice, isUseRetailPrice) { // Immutable
  async _onPressAddOneProductToCart(product: IProductItemDataMap) {
    // Immutable
    const { seller_store_id = null } = this._getParams()
    // ไปใช้ดูว่าจะมีราคาขายปลีกให้ดึงเข้าออเดอร์ได้รึเปล่า ถ้าไม่มีให้ดึงเป็น price = 0 // deprecated
    if (this.inProcess) {
      return
    }
    // const useRetailPrice = isShowRetailPrice && isUseRetailPrice
    // log(product.toJS())
    this.inProcess = true
    try {
      const variants = product.get('v') || List([])
      const DISPLAY_VARIANTS = []
      if (variants.size > 1) {
        const BTN_LABELS = []
        variants.forEach((v) => {
          // log(v.toJS())
          if (v.get('q') > 0) {
            const variantName = v.get('n')
            const variantPrice = seller_store_id ? v.get('p') : v.getIn(['s', 0, 'p'])
            const txtVariantPrice = `${variantName} ${xFmt.formatCurrency(variantPrice)}`
            BTN_LABELS.push(txtVariantPrice)
            // log('variantPrice, txtvariantPrice', variantPrice, txtVariantPrice)
            DISPLAY_VARIANTS.push(v)
          }
        })
        BTN_LABELS.push('ยกเลิก')
        await this._onShowQuickAddProductSelector(BTN_LABELS, product, variants, DISPLAY_VARIANTS)
      } else {
        DISPLAY_VARIANTS.push(variants.get(0))
        await this._pullProductToOrder(product, DISPLAY_VARIANTS, 0)
      }
    } catch (err) {
      log(err)
    }
    this.inProcess = false
    // setTimeout(() => { this.inProcess = false }, 500)
  }

  // _pullProductToOrder(selectedProduct, DISPLAY_VARIANTS, idx, useRetailPrice = false) { // selectedProduct Immutable
  async _pullProductToOrder(selectedProduct: IProductItemDataMap, DISPLAY_VARIANTS: IProductItemVariantDataMap[], idx: number) {
    // selectedProduct Immutable
    const { selectedUserGroups, selectedProductGroups, quickAddProductToOrder } = this.props
    const { store_id, seller_store_id, ug_id, pg_ids } = this._getParams()
    // const variants = selectedProduct.get('v') // Assume ว่าต้องที variants
    // const v = variants.get(idx) // v is selected variants
    // log(DISPLAY_VARIANTS)
    // log(idx)
    if (!DISPLAY_VARIANTS || idx >= DISPLAY_VARIANTS.length) {
      // cancel button is pressed
      return
    }
    const v = DISPLAY_VARIANTS[idx]
    if (!v) {
      // possibly cancel operation
      return
    }

    if (store_id) {
      // const callback = (newState) => {
      //   console.log('success callback of quick add => newState.toJS()', newState.toJS())
      //   const store_products = newState.getIn(['editingOrder', 'store_products']) || null
      //   const store_ids = store_products.keySeq().toArray() || []
      //   console.log('success callback of quick add => store_ids', store_ids)
      //   if (store_products && store_ids.length > 0) {
      //     let products = List([])
      //     for (let sIdx = 0; sIdx < store_ids.length; sIdx++) {
      //       const sId = store_ids[sIdx]
      //       const sProds = store_products.get(sId) || List([])
      //       products = products.concat(sProds)
      //     }
      //
      //     let txtSummary = ''
      //     for (let i = 0; i < products.size; i++) {
      //       if (i === 0) {
      //         txtSummary = 'สินค้าในออเดอร์ทั้งหมด\n'
      //       }
      //       const p = products.get(i)
      //       const name = p.get('name')
      //       const variant = p.get('variant')
      //       const qty = p.get('qty')
      //       const txtProductName = variant ? `${name} (${variant})` : name
      //       txtSummary += `\n${txtProductName} x${qty}`
      //     }
      //     // showSuccessToast(`เพิ่ม ${txtProductName} แล้ว`)
      //     if (txtSummary) {
      //       p.op.showToast( txtSummary, E_MSG_TYPE.SUCCESS)
      //     }
      //   }
      // }

      // log(this, '_pullProductToOrder store_id,', store_id)
      // log(this, '_pullProductToOrder seller_store_id,', seller_store_id)
      // log(this, '_pullProductToOrder ug_id,', ug_id)
      // log(this, '_pullProductToOrder pg_ids,', pg_ids)
      // log(this, '_pullProductToOrder selectedProduct.toJS(),', selectedProduct.toJS())
      // log(this, '_pullProductToOrder v.toJS(),', v.toJS())
      // const callback = (newState) => this._handleOnPullProductToOrderSuccess(newState)

      const parent_store_id = selectedProduct.get('s') ? selectedProduct.get('s') : seller_store_id
      let cost = v.has('c') ? parseFloat(v.get('c')) : null
      const product_id = selectedProduct.get('id')
      const name = selectedProduct.get('n')
      const variant = v.get('n') // variant name
      // const qty = 1 // +1 จากของเดิม
      const available_qty = v.get('q') || 0
      const pp_id = parseFloat(v.get('i'))
      const thumbnail_uris = selectedProduct.get('t')
      const h = v.get('h') || null

      // const price = parseFloat(v.get('p'))
      // const price_rate_value = parseFloat(v.get('p'))
      // const price_rate_type = 1

      // FIXME: Implement VDs
      // @ts-ignore FIXME: no interface yet
      const my_vds = selectedProduct.get('my_vds')
      // @ts-ignore FIXME: no interface yet
      const my_vds_type = selectedProduct.get('my_vds_type')
      // @ts-ignore FIXME: no interface yet
      const seller_vds = selectedProduct.get('seller_vds')
      // @ts-ignore FIXME: no interface yet
      const seller_vds_type = selectedProduct.get('seller_vds_type')

      let price = null // to be compute
      let pg_id = null // to be compute
      let ugpgSaleDiscountAmount = null // to be compute
      let ugpgSaleDiscountPercent = null // to be compute

      const productDiscount = selectedProduct.get('pdc') || Map({})
      // @ts-ignore FIXME: no interface yet
      const ugpgPurchaseDiscountAmount = productDiscount.get('ua') ? parseFloat(productDiscount.get('ua')) : null
      // @ts-ignore FIXME: no interface yet
      const ugpgPurchaseDiscountPercent = productDiscount.get('up') ? parseFloat(productDiscount.get('up')) : null

      let hasEmptyPrice = false

      if (seller_store_id) {
        // ถ้าเปิดด้วยสินค้าของร้านขายส่ง
        price = v.get('p') ? parseFloat(v.get('p')) : null
        // log('####pullProductToOrder.price', price)
        cost = price
      } else {
        // ถ้าเปิดด้วยสินค้าของร้านฉัน
        // const prices = v.get('s')
        // if (!prices || !List.isList(prices) || prices.size < 1) { // should be impossible case
        //   p.op.alert('สินค้าชิ้นนี้ไม่มีราคา', 'กรุณาระบุราคาของสินค้าชิ้นนี้ก่อนใช้เปิดออเดอร์')
        //   return
        // }
        let prices = v.get('s')
        if (!prices || !List.isList(prices) || prices.size < 1) {
          // @ts-ignore should be impossible case
          prices = List([])
        }

        // @ts-ignore FIXME: no interface yet
        price = prices.hasIn([0, 'p']) ? parseFloat(prices.getIn([0, 'p'])) : null
        // @ts-ignore FIXME: no interface yet
        pg_id = prices.hasIn([0, 'g']) ? parseInt(prices.getIn([0, 'g'])) : null
        if (_.isNull(price) || _.isUndefined(price) || !pg_id) {
          // p.op.alert('ไม่พบราคาสินค้าชิ้นนี้', 'กรุณาระบุราคาของสินค้าชิ้นนี้ก่อนใช้เปิดออเดอร์')
          // return
          // const isUserConfirmed = await p.op.isUserConfirm('ไม่พบราคาสินค้าชิ้นนี้', 'กรุณายืนยันว่าจะให้จะหยิบสินค้าเข้าออเดอร์แล้วกำหนดราคาไว้ ฿0 โดยคุณสามารถปรับราคาได้ภายหลัง')
          const txtProductName = variant ? `${name} ${variant}` : name
          const isUserConfirmed = await p.op.isUserConfirm(
            'ไม่พบราคาสินค้า',
            `กรุณายืนยันว่าจะให้จะหยิบสินค้า "${txtProductName}" เข้าสู่ออเดอร์แล้วกำหนดราคาไว้ ฿0 โดยคุณสามารถปรับราคาได้ภายหลัง`
          )
          if (!isUserConfirmed) {
            return
          }
          price = 0
          hasEmptyPrice = true
        }

        if (ug_id && pg_id) {
          // ถ้ามีการเลือกเปิดโดย UG มา และมี pg_id สำหรับคิดส่วนลด
          const focusedUserGroup = selectedUserGroups.find((ug) => Map.isMap(ug) && ug.get('id') === ug_id)
          if (!Map.isMap(focusedUserGroup) || !focusedUserGroup.get('id')) {
            p.op.alert('ไม่พบข้อมูลกลุ่มสมาชิก', 'กรุณาลองใหม่อีกครั้ง') // should be impossible case
            return
          }
          const discountRelations = focusedUserGroup.get('pgs') // discount relation
          // @ts-ignore
          const focusedDiscount: any = discountRelations.find((pgd: Map<string, any>) => Map.isMap(pgd) && pgd.get('id') === pg_id)
          if (Map.isMap(focusedDiscount)) {
            ugpgSaleDiscountAmount = focusedDiscount.get('discount_amount') ? parseFloat(focusedDiscount.get('discount_amount')) : null
            ugpgSaleDiscountPercent = focusedDiscount.get('discount_percent') ? parseFloat(focusedDiscount.get('discount_percent')) : null
          }
        } else if (_.isArray(pg_ids) && pg_ids.length > 0) {
          // TODO: ตอนนี้ยังไม่มีส่วนลดของ PG โดดๆ อนาคตมีก็มา compute ตรงนี้
        }
      }
      const pulledOrderProduct = {
        variant,
        cost,
        product_id,
        name,
        qty: APP_CONFIG.order.create_order.quick_add_product_to_order_qty, // + จาก mode ts จากของเดิม
        available_qty,
        pp_id,
        price,
        price_rate_value: hasEmptyPrice ? -1.33 : price, // default price ถ้าถูก user เปลี่ยน จะทำให้หน้าบ้านส่ง custom price ไป
        price_rate_type: 1, // default mode
        thumbnail_uris,
        h,
        parent_store_id,

        // FIXME: Maybe use in when Enable Vds in version 2.1
        my_vds,
        my_vds_type,
        seller_vds,
        seller_vds_type,

        // for create api body with pg_id
        ug_id,
        pg_id,

        // for compute real-time discount
        ugpgPurchaseDiscountAmount, // discount per each
        ugpgPurchaseDiscountPercent, // discount per each
        ugpgSaleDiscountAmount, // discount per each
        ugpgSaleDiscountPercent, // discount per each
      }

      quickAddProductToOrder({
        store_id,
        seller_store_id,
        orderProducts: [pulledOrderProduct],
        // callback,
        callback: this._handleOnPullProductToOrderSuccess,

        // for order create validation
        ug_id,
        pg_ids,
      })

      // // ถ้ามีการเลือก ug มา
      // if (ug_id) {
      //   pulledProduct['ug_id'] = ug_id
      // } else if (_.isArray(pg_ids) && pg_ids.length > 0) {
      //   pulledProduct['pg_ids'] = pg_ids
      // }

      // quickAddProductToOrder({
      //   store_id,
      //   seller_store_id,
      //   orderProducts: [{
      //     variant: v.get('n'),
      //     cost: parseFloat(v.get('c') || v.get('p')),
      //     price: parseFloat(v.get('p')),
      //     product_id: selectedProduct.get('id'),
      //     name: selectedProduct.get('n'),
      //     // cost: parseFloat(v.get(COST)),
      //     qty: 1, // +1 จากของเดิม
      //     // weight: parseInt(v.get(WEIGHT)),
      //     available_qty: v.get('q') || 0,
      //     pp_id: parseFloat(v.get('i')),
      //     price_rate_value: parseFloat(v.get('p')),
      //     // price_rate_type: useRetailPrice ? 2 : 1,
      //     price_rate_type: 1,
      //     thumbnail_uris: selectedProduct.get('t'),
      //     h: parseInt(v.get('h')) || null,
      //     parent_store_id,
      //
      //     // FIXME: Refactor me better than this
      //     my_vds: selectedProduct.get('my_vds'),
      //     my_vds_type: selectedProduct.get('my_vds_type'),
      //     seller_vds: selectedProduct.get('seller_vds'),
      //     seller_vds_type: selectedProduct.get('seller_vds_type'),
      //
      //     ug_id,
      //     pg_ids,
      //   }],
      //   callback,
      // })
    }
  }

  _handleOnPullProductToOrderSuccess = (newState: Map<string, any>): void => {
    // log('success callback of quick add => newState.toJS()', newState.toJS())
    const store_products = newState.getIn(['editingOrder', 'store_products']) || null
    const store_ids = store_products.keySeq().toArray() || []
    // log('success callback of quick add => store_ids', store_ids)
    if (store_products && store_ids.length > 0) {
      let products = List([])

      for (let sIdx = 0; sIdx < store_ids.length; sIdx++) {
        const sId = store_ids[sIdx]
        const sProds = store_products.get(sId) || List([])
        products = products.concat(sProds)
      }

      let txtSummary = ''
      for (let i = 0; i < products.size; i++) {
        if (i === 0) {
          txtSummary = 'สินค้าในออเดอร์ทั้งหมด\n'
        }
        const product = products.get(i)
        const name = product.get('name')
        const variant = product.get('variant')
        const qty = product.get('qty')
        const txtProductName = variant ? `${name} (${variant})` : name
        txtSummary += `\n${txtProductName} x${qty}`
      }
      // showSuccessToast(`เพิ่ม ${txtProductName} แล้ว`)
      if (txtSummary) {
        p.op.showToast(txtSummary, E_MSG_TYPE.SUCCESS)
      }
    }
  }

  _hasMoreRow() {
    const fetchStatus = this._getFetchStatus()
    if (fetchStatus) {
      // log('_hasMoreRow 1')
      // log(fetchStatus.toJS())
      return !fetchStatus.get('allFetched')
    }
    // log('_hasMoreRow 2')
    return true
  }

  _getProductCount() {
    const fetchStatus = this._getFetchStatus()
    if (fetchStatus && fetchStatus.get('count')) {
      return fetchStatus.get('count')
    }
    return 0
  }

  _isDoneLoadingAndHasZeroRow() {
    const fetchStatus = this._getFetchStatus()
    // log('_isDoneLoadingAndHasZeroRow.fetchStatus')
    // log(fetchStatus)
    // log('this.state.isSearch')
    // log(this.state.isSearch)
    // log('this.props')
    // log(this.props)
    if (!fetchStatus) {
      return false
    }
    return fetchStatus.has('count') && fetchStatus.get('count') === 0
    // return !(fetchStatus && fetchStatus.has('count') && fetchStatus.get('count') > 0)
    // return !fetchStatus || (fetchStatus.has('count') && fetchStatus.get('count') === 0)
  }

  _isLoading() {
    const fetchStatus = this._getFetchStatus()
    if (fetchStatus) {
      return fetchStatus.get('loading')
    }
    return false
  }

  _getFetchStatus() {
    return this.state.isSearch ? this.props.searchedFetchStatus : this.props.selectedFetchStatus
  }

  _handleLoadMore() {
    // log('ProductListVIew._handleLoadMore')
    if (this.currScrollPos <= 0) {
      // log('return as this.currScrollPos is zero', this.currScrollPos)
      return
    }

    // prevent calling again if it's the same offset
    const { isSearch } = this.state
    const fetchStatus = this._getFetchStatus()
    // log(fetchStatus.toJS())
    const nextOffset = fetchStatus.get('offset')
    // log('nextOffset: ' + nextOffset + ' lastSelected: ' + this.lastSelectedProductOffset + ' lastSearch: ' + this.lastSearchedProductOffset)
    const lastProductOffset = isSearch ? this.lastSearchedProductOffset : this.lastSelectedProductOffset
    if (lastProductOffset && nextOffset && lastProductOffset === nextOffset) {
      // log('force return as offset is the same as ' + lastProductOffset + ' isSearch: ' + isSearch)
      return
    }
    if (isSearch) {
      this.lastSearchedProductOffset = nextOffset || 0
      // log('1 Set lastSearchedProductOffset to ' + this.lastSearchedProductOffset)
    } else {
      this.lastSelectedProductOffset = nextOffset || 0
      // log('2 Set lastSelectedProductOffset to ' + this.lastSelectedProductOffset)
    }

    // let { shouldFetch } = this.props
    // log(this.props.fetchStatus.toJS())
    //
    // // let { loading, allFetched } = this.props.fetchStatus
    // log('isLoading() ')
    // log(this._isLoading())
    // log('hasMoreRow()')
    // log(this._hasMoreRow())
    if (!this._isLoading() && this._hasMoreRow()) {
      // if (shouldFetch && !loading && !allFetched) {
      //   log('BaseProductListView._fetchData from _handleLoadMore')
      if (this.state.isSearch) {
        this._onSearchProducts({ isLoadMore: true })
      } else {
        this._fetchData({ isLoadMore: true })
      }
    }
  }

  async _onSearchProducts({ isLoadMore, isBarcodeSearch = false }): Promise<void> {
    const { isSearch, txtSearch } = this.state

    if (isSearch && txtSearch && txtSearch.length >= this.MIN_SEARCH_TEXT) {
      // App will Search at least 3 char
      await setStatePromise(this, { isSearching: true })
      if (txtSearch.length >= this.MIN_SEARCH_TEXT) {
        const isNewSearch = this.lastSearchedProductOffset === 0
        await this._fetchData({ isLoadMore, queryTxt: txtSearch, isNewSearch, isBarcodeSearch })
      }
      await setStatePromise(this, { isSearching: false })
    }
  }

  async _deactivateSearch() {
    const _isSearch = false
    if (this.state.isSearch !== _isSearch) {
      // reset the last item offset as it switches mode
      this.lastSearchedProductOffset = 0
    }

    await setStatePromise(this, { txtSearch: '', isSearch: _isSearch })
    this._doAfterDeactivateSearch()
    // this.setState({ txtSearch: '', isSearch: _isSearch }, () =>
    //   this.flatListRef.scrollToOffset({ x: 0, y: 0, animated: false }),
    // )
  }

  // _getProductListItemInfo(
  //   index: number,
  //   product: IProductListItemMap,
  //   isShowRetailPrice: boolean,
  //   isUseRetailPrice: boolean
  // ): object | null {
  //   if (!product) {
  //     return null
  //   }
  //   const data = product
  //   // console.log('In getProductListItemInfo:: ')
  //   // console.log('product => ', product)
  //   // console.log('data => ', data)
  //   const product_id = data.get('id') || null
  //   let product_name = data.get('n') ? data.get('n') : data.get('name')
  //   if (!product_name) {
  //     product_name = ''
  //   }
  //
  //   let thumbnail_uri = data.getIn(['t', 0]) ? data.getIn(['t', 0]) : data.getIn(['tiny_img_uris', 0])
  //   if (!thumbnail_uri) {
  //     thumbnail_uri = ''
  //   }
  //
  //   const price_min = data.get('i') ? data.get('i') : data.get('min')
  //   const price_max = data.get('x') ? data.get('x') : data.get('max')
  //   const qty = data.get('q') ? data.get('q') : data.get('qty')
  //   let variants = data.get('v') ? data.get('v') : data.get('variants')
  //   if (!variants) {
  //     variants = List([])
  //   }
  //   // const sumVariantsQty = variants.reduce((prevSum, variant) => {
  //   //   return prevSum + parseInt(variant.get('q'))
  //   // }, 0)
  //   // const hasQtyToSell = sumVariantsQty > 0
  //
  //   const isOdd = index % 2 !== 0 // RightBlock
  //   const isMyProduct = data.get('m') || false
  //   const hasZeroWeight = data.get('z') || false // TODO: Keng Remove this migration
  //   // log('name: ' + name)
  //   // log(hasZeroWeight)
  //
  //   let txtPriceDisplay = ''
  //   if (price_max && price_min && parseFloat(price_max) !== parseFloat(price_min)) {
  //     txtPriceDisplay = `${xFmt.formatCurrency(price_min)} - ${xFmt.currencyFormatter.format(price_max)}`
  //   } else if (parseFloat(price_max) === parseFloat(price_min)) {
  //     txtPriceDisplay = `${xFmt.formatCurrency(price_max)}`
  //   } else {
  //     txtPriceDisplay = '-' // should be impossible case
  //   }
  //
  //   // Calculate Warning Flag sould display ?
  //   const infoText = null
  //   const infoBgColor = xCOLORS.BRAND_Warning // default สีเหลือง (ถ้ามี infoText)
  //
  //   const priceArr = variants.map(v => (v.get('p') ? v.get('p') : v.get('price'))) || List([])
  //   let hasNullPrice = false
  //   priceArr.forEach(focusPrice => {
  //     if (!focusPrice) {
  //       hasNullPrice = true
  //     }
  //   })
  //
  //   const isEmptyQty = qty <= 0 // ดูแล้ว avilable qty ใน product list ไม่ตรงกับใน sum variant qty
  //   const isLowQty = qty > 0 && qty <= 5
  //   const isNoRetailPrice = hasNullPrice && isShowRetailPrice && isUseRetailPrice // เนื่องจากไม่มี flag บอก
  //
  //   return {
  //     // Product info
  //     product_id,
  //     product_name,
  //     thumbnail_uri,
  //     // price_min,
  //     // price_max,
  //     txtPriceDisplay,
  //     txtQtyDisplay: qty ? xFmt.currencyFormatter.format(qty) : '-',
  //     // Addition info
  //     isOdd, // index เป็นเลขคี่
  //     infoText, // ข้อความบนแถบคาดการ์ด
  //     infoBgColor, // สีของแถบคาดคาดการ์ด
  //     isEmptyQty, // ของหมดสต๊อก
  //     isLowQty,
  //     isNoRetailPrice,
  //     isMyProduct,
  //     hasZeroWeight,
  //   }
  // }

  _getIsAvailableBarcodeSearch(): boolean {
    // Bypass Always show UPC Barcode
    return true
    // const { selectedStore, sellerStores } = this.props
    // const { mode, seller_store_id } = this._getParams()
    // const isStoreUseUPC = selectedStore.get(COMPUTED_USE_PRODUCT_UPC) || false
    // const isStoreUseSKU = selectedStore.get(COMPUTED_USE_PRODUCT_SKU) || false
    // if (_.includes([VIEW_SELF, PULL_MY_PRODUCT_TO_ORDER], mode)) {
    //   return isStoreUseUPC || isStoreUseSKU
    // } else if (
    //   _.includes([VIEW_SELLER, PULL_SELLER_PRODUCT_TO_ORDER], mode) &&
    //   seller_store_id &&
    //   List.isList(sellerStores)
    // ) {
    //   const selectedSellerStoreIndex = sellerStores.findIndex(store => store.get('id') === seller_store_id)
    //   if (selectedSellerStoreIndex > -1) {
    //     return !_.isNil(sellerStores.getIn([selectedSellerStoreIndex, 's_u']))
    //   }
    // }
    // return false
  }

  _configCategory = async () => {
    const { categoryList, requestCategoryList } = this.props
    const { mode, store_id, seller_store_id } = this._getParams()
    let storeId = store_id
    if (mode === PULL_PRODUCT) {
      storeId = seller_store_id
    } else if (mode === PULL_SELLER_PRODUCT_TO_ORDER) {
      storeId = seller_store_id
    } else if (mode === VIEW_SELLER) {
      storeId = seller_store_id
    }
    let CATEGORYS = null
    const CettoJS = _.isNil(categoryList) ? [] : categoryList.toJS()
    CettoJS.map((data: categoryByStore) => {
      if (storeId === data.store_id) {
        CATEGORYS = data.category
      }
    })
    if (_.isNil(CATEGORYS) || CATEGORYS.length < 1) {
      const res: ICategory[] = await new Promise((resolve) => {
        requestCategoryList({
          body: {
            store_id: storeId,
          },
          successCallback: resolve,
          failedCallback: resolve,
        })
      })
      if (!_.isNil(res) && _.isArray(res)) {
        CATEGORYS = res
      }
    }
    const catSeletedCategory = null
    const catLevelCategoryList = []
    const catSupCategory = []
    if (_.isNil(CATEGORYS)) {
      CATEGORYS = []
    }
    const haveUnknownCat = CATEGORYS.find((data: ICategory) => data.id === 0)
    if (_.isNil(haveUnknownCat)) {
      const unknownCat: ICategory = { id: 0, n: `ไม่ระบุหมวดหมู่`, l: COLORS.TEXT_INACTIVE }
      CATEGORYS.push(unknownCat)
    }
    CATEGORYS.map((data: ICategory) => {
      if (_.isNil(data.p)) {
        catSupCategory.push(data)
        catLevelCategoryList.push(data)
      }
    })

    await util.setStatePromise(this, {
      catSeletedCategory,
      catLevelCategoryList,
      catSupCategory,
    })
  }

  _setCallBackMKPChannels = async (mkp_ch_ids: number[]) => {
    if (!_.isNil(mkp_ch_ids)) {
      await util.setStatePromise(this, {
        mkp_ch_ids,
      })
    } else {
      await util.setStatePromise(this, {
        mkp_ch_ids: [],
      })
    }
  }

  _callBackSubmitForDrawer = async (submit: ISubmitDrawerCategory) => {
    const { category } = submit
    await this._setCallBackMKPChannels(submit.mkp_ch_ids)
    await this._setallChildrenCategory(submit.allChildrenCategory)
    await this._selectedSubCategory(category)
  }

  _setallChildrenCategory = async (value: boolean) => {
    await util.setStatePromise(this, { allChildrenCategory: value })
  }

  _selectedSubCategory = async (category: ICategory) => {
    if (_.isNil(category)) {
      await this._configCategory()
      await this._onRefresh()
      return
    }
    const { categoryList, selectedStore } = this.props
    // const store_id = selectedStore.get('id')
    const { store_id, seller_store_id, mode } = this._getParams()
    let storeId = store_id
    if (mode === PULL_PRODUCT) {
      storeId = seller_store_id
    } else if (mode === PULL_SELLER_PRODUCT_TO_ORDER) {
      storeId = seller_store_id
    } else if (mode === VIEW_SELLER) {
      storeId = seller_store_id
    }
    let CATEGORYS = null
    const CettoJS = _.isNil(categoryList) ? [] : categoryList.toJS()
    CettoJS.map((data: categoryByStore) => {
      if (storeId === data.store_id) {
        CATEGORYS = data.category
        if (category.id === 0) {
          CATEGORYS.push(category)
        } else {
          const unknownCat: ICategory = { id: 0, n: `ไม่ระบุหมวดหมู่`, l: COLORS.TEXT_INACTIVE }
          CATEGORYS.push(unknownCat)
        }
      }
    })
    if (!_.isNil(CATEGORYS) && _.isArray(CATEGORYS)) {
      const newLvlCat = []
      const newSubCat = []
      CATEGORYS.map((xData: ICategory) => {
        const data = xData
        if (xData.id === category.id) {
          data.selected = true
        }

        if (!_.isNil(data.p) && data.p === category.id) {
          newSubCat.push(data)
        }
        if (_.isNil(category.p) && _.isNil(data.p)) {
          newLvlCat.push(data)
        } else if (data.p === category.p) {
          newLvlCat.push(data)
        }
      })
      // if (_.isNil(category.p)) {
      //   const unknownCat: ICategory = { id: 0, n: `ไม่ระบุหมวดหมู่`, l: COLORS.TEXT_INACTIVE }
      //   newLvlCat.push(unknownCat)
      // }
      await util.setStatePromise(this, {
        catLevelCategoryList: newLvlCat,
        catSeletedCategory: category,
        catSupCategory: newSubCat,
      })
      await this._onRefresh()
    }
  }
}
