import React from 'react'
import HStack from 'xui/components/HStack'
import * as xFmt from 'x/utils/formatter'
import { View } from 'react-native'
import dayjs from 'dayjs'
import { VictoryChart, VictoryBar, VictoryLine, VictoryAxis, VictoryTheme } from 'victory-native'

import BaseDailySalesReportView, { IChart, IChartData } from 'x/modules/report/BaseDailySalesReportView'
// import * as util from 'x/utils/util'
import _ from 'lodash'
import { getReportDailySalesExcelUrl, delay } from 'x/utils/util'

import p from 'x/config/platform-specific'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import xCONS from 'x/config/constants'
import DateRangePicker from 'xui/components/XDateRangePicker'

import { COLORS, STYLES } from 'x/config/styles'
import XCustomHeader from 'xui/components/XCustomHeader'
import XCard from 'xui/components/XCard'
import XText from 'xui/components/XText'
import Segment from 'xui/components/Segment'
import XButton from 'xui/components/XButton'
import Box from 'xui/components/Box'
import XIcon from 'xui/components/XIcon'
import VStack from 'xui/components/VStack'
import * as util from 'x/utils/util'

const {
  CARD_COMMON,
  NO_MARGIN_PADDING,
  TXT_LARGER_BOLD,
  TXT_LABEL_BOLD,
  TXT_LABEL_SMALLER,
  TXT_LABEL_SMALLER_BOLD,
  TXT_LARGER_3_BOLD,
  FONT_SIZE_NORMAL,
  FONT_SIZE_SMALLER,
  FONT_SIZE_SMALLEST,
} = STYLES

const { BG_LIGHT_GREY_ALTERNATIVE, TEXT_ACTIVE, TEXT_INACTIVE, APP_MAIN } = COLORS

require('moment-timezone')

export default abstract class DailySalesReportView extends BaseDailySalesReportView {
  abstract _handleOnDownloadFileByPlatform(url: string, fileName: string): Promise<void>
  // constructor(props) {
  //   super(props)
  //   this.deviceWidth = Dimensions.get('window').width
  //   this.chartWidth = this.deviceWidth - 30

  //   // this._onPressChartButton = this._onPressChartButton.bind(this)
  // }

  // componentWillMount() {
  //   this._fetchReport()
  // }

  renderCustomHeader = () => {
    const leftBtn = { backIcon: true, onPressItem: () => util.navGoBack(this.props) }
    return (
      <XCustomHeader
        title='รายงาน'
        headerLeftProps={leftBtn}
        // headerRightProps={{ label: `แก้ไข`, onPressItem: () => util.setStatePromise(this, { mode: EDIT }) }}
      />
    )
  }

  _renderPastSummaryBlocks = () => {
    const { displayCharts, selectedChartIndex } = this.state
    if (!displayCharts || displayCharts.length <= 0) {
      return null
    }
    return (
      <HStack mt='2' space='2'>
        {displayCharts.map((chartProps: IChart, index: number) => (
          <Box flex={1}>
            {this._renderChartButton({
              // ...chartProps,
              data: chartProps.data,
              activeBgColor: chartProps.activeBgColor,
              label_y: chartProps.label_y,
              parseSumToShowFunction: chartProps.parseSumToShowFunction,
              index,
              isSelected: selectedChartIndex === index,
              // isFirst: index === 0,
              // isLast: index === displayCharts.length - 1,
            })}
          </Box>
        ))}
      </HStack>
    )

    // return (
    //   <View
    //     style={{
    //       flex: 1,
    //       // flexWrap: 'wrap',
    //       // backgroundColor: 'skyblue',
    //     }}>
    //     <Grid>
    //       <Row>
    //         {displayCharts.map((chartProps, index) =>
    //           this._renderChartButton({
    //             ...chartProps,
    //             index,
    //             isSelected: selectedChartIndex === index,
    //             isFirst: index === 0,
    //             isLast: index === displayCharts.length - 1,
    //           })
    //         )}
    //       </Row>
    //     </Grid>
    //   </View>
    // )
  }

  _renderChartButton = ({ index, data, label_y, activeBgColor, parseSumToShowFunction, isSelected }) => {
    const { selectedOptionTimePeriodIndex } = this.state
    // const data = displayCharts[index] && displayCharts[index].data ? displayCharts[index].data : []

    if (!_.isArray(data) && data.length > 0) {
      return null
    }

    // const sumData = data.reduce((prevSum, record) => {
    //   const { y } = record
    //   return y && y > 0 ? prevSum + y : prevSum
    // }, 0)

    let timePeriod = this.TIME_PERIOD
    switch (selectedOptionTimePeriodIndex) {
      case 0:
        timePeriod = 7
        this.TIME_PERIOD = 7
        break
      case 1:
        timePeriod = 30
        this.TIME_PERIOD = 30
        break
      case 2:
        if (this.SELECTED_DATE) {
          timePeriod = dayjs().diff(this.state.selectedDateRange.begin, 'day')
          this.TIME_PERIOD = timePeriod
        }
        break
      default:
        timePeriod = 7
        this.TIME_PERIOD = 7
      // console.log('timePeriod', timePeriod)
    }

    let sumData = 0
    for (let i = 0; i < timePeriod; i++) {
      if (_.isArray(data) && data[i] && data[i].y) {
        const dataValue = _.isString(data[i].y) ? parseFloat(data[i].y) : data[i].y
        sumData += dataValue
      }
    }
    // log('_renderChartButton sumData => ', sumData)

    let txtSumData
    if (_.isFunction(parseSumToShowFunction)) {
      txtSumData = parseSumToShowFunction(sumData)
    } else {
      txtSumData = sumData.toString()
    }
    // return null
    return (
      <XCard
        justifyContent='center'
        alignItems='center'
        onPress={() => {
          this._onPressChartButton(index)
        }}
        bg={isSelected ? activeBgColor : BG_LIGHT_GREY_ALTERNATIVE}>
        <Box mt='1' w='full' flex={1} justifyContent='center' alignItems='center'>
          <XText color={isSelected ? 'white' : 'black'}>{txtSumData}</XText>
        </Box>
        <Box w='full' flex={1} justifyContent='center' alignItems='center'>
          <XText color={isSelected ? 'white' : 'gray.400'}>{label_y}</XText>
        </Box>
      </XCard>
    )

    // return (
    //   <Col key={`ChartButton_${key}`}>
    //     <TouchableOpacity
    //       onPress={(x) => {
    //         this._onPressChartButton(index)
    //       }}
    //       // first={ isFirst }
    //       // last={ isLast }
    //     >
    //       <Card
    //       // style={[
    //       //   CARD_COMMON,
    //       //   {
    //       //     flex: 1,
    //       //     borderWidth: 5,
    //       //     shadowOffset: { width: 1, height: 3 },
    //       //     shadowOpacity: 0.7,
    //       //     shadowRadius: 1.5,
    //       //     backgroundColor: 'white',
    //       //   },
    //       // ]}
    //       >
    //         <CardItem style={{ backgroundColor: isSelected ? activeBgColor : BG_LIGHT_GREY_ALTERNATIVE }}>
    //           <Grid>
    //             <Row>
    //               <Col>
    //                 <Text
    //                   style={[
    //                     TXT_LARGER_BOLD,
    //                     {
    //                       textAlign: 'center',
    //                       color: isSelected ? activeColor : TEXT_ACTIVE,
    //                       paddingTop: 8,
    //                       paddingLeft: 0,
    //                       paddingRight: 0,
    //                     },
    //                   ]}>
    //                   {txtSumData}
    //                 </Text>
    //               </Col>
    //             </Row>
    //             <Row>
    //               <Col>
    //                 <Text
    //                   style={[
    //                     TXT_LABEL_SMALLER_BOLD,
    //                     {
    //                       textAlign: 'center',
    //                       color: isSelected ? activeColor : TEXT_INACTIVE,
    //                       paddingTop: 2,
    //                     },
    //                   ]}>
    //                   {label_y}
    //                 </Text>
    //               </Col>
    //             </Row>
    //           </Grid>
    //         </CardItem>
    //       </Card>
    //     </TouchableOpacity>
    //   </Col>
    // )
  }

  _getTickFormatXFunction = (dataCount, parseFunction) => {
    const limitLength = 9
    if (dataCount > limitLength) {
      const splitedPoint = Math.floor(dataCount / limitLength)
      return _.isFunction(parseFunction)
        ? (data, index) => (index % splitedPoint === 0 ? parseFunction(data) : null)
        : (data, index) => (index % splitedPoint === 0 ? data : null)
    }
    return _.isFunction(parseFunction) ? (data) => parseFunction(data) : (data) => data
  }

  _downloadDataAsExcel = async () => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    const storeId = this.props.selectedStore.get('id')
    if (!_.isNumber(storeId)) {
      this.inProcess = false
      p.op.alert('เกิดข้อผิดพลาด', 'กรุณาลองใหม่อีกครั้ง')
      return
    }
    const { selectedOptionTimePeriodIndex, selectedDateRange } = this.state
    p.op.aLogEvent(xCONS.EVENT_NAME.REPORT_DAILY_PRINT_EXCEL, { store_id: storeId })
    // p.op.alert('ฟีเจอร์พรีเมี่ยมนี้จะเปิดใช้ได้เร็วๆ นี้')
    const today = dayjs()
    const yesterday = today.subtract(1, 'days').clone().endOf('day')
    // const endDate = today.format('DD-MM-YYYY')
    // const endDate = today.format(xCONS.SERVER_DATE_FORMAT)
    let endDate = yesterday.format(xCONS.SERVER_DATE_FORMAT)
    let daysInPast = 1
    switch (selectedOptionTimePeriodIndex) {
      case 0:
        daysInPast = 7
        break
      case 1:
        daysInPast = 30
        break
      case 2:
        daysInPast = today.diff(this.state.selectedDateRange.begin, 'day')
        endDate = dayjs(selectedDateRange.end).format(xCONS.SERVER_DATE_FORMAT)
        break
      default:
    }

    const startDate = yesterday.clone().subtract(daysInPast, 'days').format(xCONS.SERVER_DATE_FORMAT)

    // let stime = util.addSecond(moment(startDate), 0)
    // stime = util.addMilliseconds(stime, 0)
    // let etime = util.addSecond(moment(endDate), 59)
    // etime = util.addMilliseconds(etime, 999)
    // const newStartDate = stime.format(xCONS.SERVER_DATETIME_FORMAT_WITH_MILLISECONDS)
    // const newEndDate = etime.format(xCONS.SERVER_DATETIME_FORMAT_WITH_MILLISECONDS)
    // console.log('newStartDate', newStartDate)
    // console.log('newEndDate', newEndDate)
    const url = getReportDailySalesExcelUrl({ store_id: storeId, startDate, endDate })
    const fileName = `DailySales_${startDate}_to_${endDate}_XSelly.xlsx`
    if (url) {
      await this._handleOnDownloadFileByPlatform(url, fileName)
      // await utilN.openFileFromUrl(url, fileName, 'xlsx')
      await delay(500)
    }
    this.inProcess = false
  }

  _renderPastChart = () => {
    const { displayCharts, selectedChartIndex, selectedOptionTimePeriodIndex } = this.state
    if (!displayCharts || !displayCharts[selectedChartIndex]) {
      return null
    }
    // console.log(selectedChartIndex)
    // Take data from constant merge with response if it has
    const {
      data,
      label_y,
      parseFunctionToTickLabelX,
      parseFunctionToTickLabelY,
      parseFunctionToAverageData,
      activeBgColor,
    } = displayCharts[selectedChartIndex]
    if (data.length === 0) {
      return (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <XText style={TXT_LABEL_SMALLER}>ยังไม่มีข้อมูลเพียงพอ</XText>
        </View>
      )
    }

    // FIXME: Optimize me!!!
    const dataCount = data.length
    const sumData = data.reduce((prevSum, record) => {
      const { y } = record
      return y && y > 0 ? prevSum + y : prevSum
    }, 0)
    const avgData = sumData / dataCount
    // @ts-ignore
    const txtAverageData = `${label_y}เฉลี่ยต่อวัน: ${parseFunctionToAverageData ? parseFunctionToAverageData(avgData) : avgData}`
    const tickLabelXFunc = this._getTickFormatXFunction(dataCount, parseFunctionToTickLabelX || null)
    const generatingAverageLineData = []
    data.forEach((d: IChartData) => {
      const avg = _.cloneDeep(d)
      avg.y = avgData
      generatingAverageLineData.push(avg)
    })
    // log(generatingAverageLineData)
    return (
      <VStack w='full'>
        <XText mt='2' variant='inactive' textAlign='center'>
          {txtAverageData}
        </XText>
        <VStack w='full'>
          <VStack
            w='full'
            pointerEvents='none'
            minH='16'
            onLayout={(event) => {
              const { x, y, width, height } = event.nativeEvent.layout
              this.setState({ victoryChartWidth: width })
            }}>
            {this.state.victoryChartWidth ? (
              <VictoryChart
                key={`chart_${selectedChartIndex}${selectedOptionTimePeriodIndex}`}
                // pointerEvents='none'
                // width={this.chartWidth}
                // width={this.state.victoryChartWidth}
                width={this.state.victoryChartWidth || 320}
                style={{
                  background: { fill: '#f8f8f8' },
                }}
                theme={VictoryTheme.material}
                // domainPadding={15}
                // animate={{
                //   duration: 1000,
                //   onLoad: { duration: 500 },
                // }}
                padding={{ top: 25, left: 57, bottom: 45, right: 16 }}
                // animate={{
                //   duration: 2000,
                //   easing: 'bounce',
                // }}
              >
                <VictoryAxis
                  // label={label_y}
                  style={{
                    axis: { stroke: TEXT_INACTIVE },
                    // axisLabel: {
                    //   fontSize: FONT_SIZE_SMALLER,
                    //   fill: TEXT_INACTIVE,
                    //   padding: 50,
                    // },
                    ticks: { stroke: TEXT_INACTIVE },
                    tickLabels: {
                      fontSize: FONT_SIZE_SMALLEST,
                      fill: TEXT_INACTIVE,
                      fontWeight: 'normal',
                    },
                    grid: { stroke: '#B3E5FC', strokeWidth: 0.25 },
                  }}
                  dependentAxis
                  // @ts-ignore
                  tickFormat={parseFunctionToTickLabelY || null}
                />
                <VictoryAxis
                  // label={label_x}
                  style={{
                    axis: { stroke: TEXT_INACTIVE },
                    // axisLabel: {
                    //   fontSize: FONT_SIZE_SMALLER,
                    //   padding: 40,
                    // },
                    // ticks: { stroke: TEXT_INACTIVE },
                    tickLabels: {
                      fontSize: FONT_SIZE_SMALLER,
                      fill: TEXT_INACTIVE,
                      fontWeight: 'normal',
                      angle: 60,
                      textAnchor: 'start',
                      verticalAnchor: 'middle',
                      // fontFamily: 'Serif',
                      fontFamily: 'Roboto',
                    },
                  }}
                  tickFormat={tickLabelXFunc}
                  // tickFormat={(label, i) => {
                  //   log('tickFormat label => ', label)
                  //   log('tickFormat i => ', i)
                  //   return`${label}x${i}`}
                  // }
                  // tickFormat={() => null}
                />

                {/* {this._renderDisplayCharts({ data, color: activeBgColor, chartType })} */}
                {this._renderDisplayCharts({ data, color: activeBgColor, chartType: 'line', opacity: 1 })}

                {/* FIXME: Improve me */}
                {this._renderDisplayCharts({
                  data: generatingAverageLineData,
                  // label: avgData,
                  color: activeBgColor,
                  chartType: 'line',
                  opacity: 0.3,
                })}
              </VictoryChart>
            ) : null}
          </VStack>

          <XText position='absolute' left='3' top='1.5' fontSize='xs' variant='inactive'>
            {label_y}
          </XText>
        </VStack>
        <XButton mt='2' onPress={() => this._downloadDataAsExcel()} leftIcon={<XIcon name='file-excel-o' family='FontAwesome' />}>
          ดาวน์โหลดข้อมูลเป็น Excel ตามกราฟด้านบน
        </XButton>
        <XText fontSize='sm' variant='inactive' flex={1} mt='2'>
          {'*ยอดขาย และกำไร คำนวณโดยไม่รวมค่าจัดส่ง และต้นทุนค่าจัดส่ง\n' +
            '**ยอดขาย กำไร และจำนวนออเดอร์นับตามวันที่ออเดอร์เสร็จสิ้น (ยืนยัน+ชำระ+จัดส่ง แล้ว)\n' +
            '***ข้อมูลย้อนหลังอาจมีการเปลี่ยนแปลงได้ เนื่องจากระบบมีการคำนวณใหม่ทุกวันตามข้อมูลออเดอร์ที่ถูกยกเลิก\n'}
        </XText>
      </VStack>
    )
    // return (
    //   <View
    //     style={{
    //       flex: 1,
    //       justifyContent: 'flex-start',
    //       alignItems: 'flex-start',
    //       // backgroundColor: 'green',
    //     }}>
    //     <Text style={{ width: '100%', fontSize: FONT_SIZE_NORMAL, color: TEXT_INACTIVE, textAlign: 'center' }}>{txtAverageData}</Text>
    //     {Platform.OS === 'ios' && label_y ? (
    //       <View style={{ width: '100%', position: 'absolute', top: 23, left: 25 }}>
    //         <XText bold fontSize='sm' variant='inactive'>
    //           {label_y}
    //         </XText>
    //       </View>
    //     ) : null}
    //     <View pointerEvents='none'>
    //       <VictoryChart
    //         key={`chart_${selectedChartIndex}${selectedOptionTimePeriodIndex}`}
    //         pointerEvents='none'
    //         width={this.chartWidth}
    //         theme={VictoryTheme.material}
    //         domainPadding={15}
    //         // animate={{
    //         //   duration: 1000,
    //         //   onLoad: { duration: 500 },
    //         // }}
    //         padding={{ top: 25, left: 57, bottom: 45, right: 0 }}
    //         // animate={{
    //         //   duration: 2000,
    //         //   easing: 'bounce',
    //         // }}
    //       >
    //         <VictoryAxis
    //           // label={label_y}
    //           style={{
    //             axis: { stroke: TEXT_INACTIVE },
    //             // axisLabel: {
    //             //   fontSize: FONT_SIZE_SMALLER,
    //             //   fill: TEXT_INACTIVE,
    //             //   padding: 50,
    //             // },
    //             ticks: { stroke: TEXT_INACTIVE },
    //             tickLabels: {
    //               fontSize: FONT_SIZE_SMALLEST,
    //               fill: TEXT_INACTIVE,
    //               fontWeight: 'normal',
    //             },
    //             grid: { stroke: '#B3E5FC', strokeWidth: 0.25 },
    //           }}
    //           dependentAxis
    //           tickFormat={parseFunctionToTickLabelY || null}
    //         />
    //         <VictoryAxis
    //           // label={label_x}
    //           style={{
    //             axis: { stroke: TEXT_INACTIVE },
    //             // axisLabel: {
    //             //   fontSize: FONT_SIZE_SMALLER,
    //             //   padding: 40,
    //             // },
    //             // ticks: { stroke: TEXT_INACTIVE },
    //             tickLabels: {
    //               fontSize: FONT_SIZE_SMALLER,
    //               fill: TEXT_INACTIVE,
    //               fontWeight: 'normal',
    //               angle: 60,
    //               textAnchor: 'start',
    //               verticalAnchor: 'middle',
    //               // fontFamily: 'Serif',
    //               fontFamily: 'Roboto',
    //             },
    //           }}
    //           tickFormat={tickLabelXFunc}
    //           // tickFormat={(label, i) => {
    //           //   log('tickFormat label => ', label)
    //           //   log('tickFormat i => ', i)
    //           //   return`${label}x${i}`}
    //           // }
    //           // tickFormat={() => null}
    //         />
    //         {/* {this._renderDisplayCharts({ data, color: activeBgColor, chartType })} */}
    //         {this._renderDisplayCharts({ data, color: activeBgColor, chartType })}
    //         {/* FIXME: Improve me */}
    //         {this._renderDisplayCharts({
    //           data: generatingAverageLineData,
    //           label: avgData,
    //           color: activeBgColor,
    //           chartType: 'line',
    //           opacity: 0.3,
    //         })}
    //       </VictoryChart>
    //     </View>
    //     {/* { */}
    //     {/* Platform.OS === 'ios' && label_x */}
    //     {/* ? ( */}
    //     {/* <View style={{ width: '100%', position: 'relative', top: -5 }}> */}
    //     {/* <Text style={[TXT_LABEL_SMALLER_BOLD, { flex: 1, textAlign: 'center' }]}>{ label_x }</Text> */}
    //     {/* </View> */}
    //     {/* ) : null */}
    //     {/* } */}
    //     <View style={{ flex: 1, width: '100%', padding: 3 }}>
    //       <TouchableOpacity
    //         style={[
    //           STYLES.BTN_MODE_ACTIVE_SECONDARY,
    //           {
    //             flex: 1,
    //             padding: 3,
    //             borderWidth: 1,
    //             borderRadius: 5,
    //             margin: 0,
    //           },
    //         ]}
    //         onPress={this._downloadDataAsExcel}>
    //         <Grid>
    //           <Row>
    //             <Col style={{ width: '10%', justifyContent: 'center', alignItems: 'center' }}>
    //               <FAIcon name='file-excel-o' style={{ fontSize: STYLES.FONT_ICON_SMALLER, color: APP_MAIN }} />
    //             </Col>
    //             <Col style={{ width: '90%', justifyContent: 'center', alignItems: 'center' }}>
    //               <XText color='#f46938' bold>
    //                 ดาวน์โหลดข้อมูลเป็น Excel ตามกราฟด้านบน
    //               </XText>
    //             </Col>
    //           </Row>
    //         </Grid>
    //       </TouchableOpacity>
    //     </View>
    //     <View style={{ flex: 1, padding: 8 }}>
    //       {/* { chartNote ? <Text style={[TXT_LABEL_SMALLER, { flex: 1, textAlign: 'left' }]}>{ chartNote }</Text> : null } */}
    //       <XText fontSize='sm' variant='inactive' flex={1}>
    //         {'*ยอดขาย และกำไร คำนวณโดยไม่รวมค่าจัดส่ง และต้นทุนค่าจัดส่ง\n' +
    //           '**ยอดขาย กำไร และจำนวนออเดอร์นับตามวันที่ออเดอร์เสร็จสิ้น (ยืนยัน+ชำระ+จัดส่ง แล้ว)\n' +
    //           '***ข้อมูลย้อนหลังอาจมีการเปลี่ยนแปลงได้ เนื่องจากระบบมีการคำนวณใหม่ทุกวันตามข้อมูลออเดอร์ที่ถูกยกเลิก\n'}
    //       </XText>
    //     </View>
    //   </View>
    // )
  }
  // {/*'***ข้อมูลจะถูกคำนวณใหม่ 7 วันย้อนหลังเมื่อขี้นวันใหม่ ทำให้ข้อมูลออเดอร์ที่ถูกยกเลิกภายใน 7 วันหลังจากที่สร้างได้รับการอัพเดทถูกต้อง' }*/}

  _renderDisplayCharts = ({ data, color, chartType, opacity = 0 }) => {
    if (!chartType) {
      return null
    }
    switch (chartType) {
      case 'line':
        return this._renderChartLine({ data, color, opacity })
      case 'bar':
        return this._renderChartBar({ data, color, opacity })
      default:
        return null
    }
  }

  _renderChartLine = ({ data, color, opacity = 1 }) => (
    <VictoryLine
      style={{
        data: {
          stroke: color || 'black',
          strokeWidth: 3,
          strokeLinecap: 'round',
          opacity,
        },
        // parent: { border: '2px solid #ccc' },
      }}
      data={data}
      animate={{
        duration: 1500,
        onLoad: { duration: 1000 },
        onExit: {
          duration: 500,
          before: () => ({
            _y1: 0,
            fill: 'orange',
          }),
        },
      }}
    />
  )

  _renderChartBar = ({ data, color, opacity = 1 }) => (
    <VictoryBar
      data={data}
      style={{
        data: { fill: color, opacity },
        labels: { fill: TEXT_INACTIVE },
      }}
      labels={(d) => d.y}
      animate={{
        duration: 1500,
        onLoad: { duration: 1000 },
        onExit: { duration: 500 },
      }}
    />
  )

  renderTodaySalesInfoComponent = ({ txtLabel, txtData, txtColor, isLastComponent = false }) => (
    <Box flex={1} borderRightColor='gray.500' borderRightWidth={isLastComponent ? '0' : '1'}>
      <Box justifyContent='center' alignItems='center'>
        <XText color={txtColor}>{txtData}</XText>
      </Box>
      <Box justifyContent='center' alignItems='center'>
        <XText variant='inactive'>{txtLabel}</XText>
      </Box>
    </Box>
  )

  _renderTodaySalesInfo = () => {
    const { displayTodayInfo, displayCharts } = this.state
    const { sales_amount, profit_amount, order_count } = displayTodayInfo
    return (
      <XCard w='full' py='2' mt='2'>
        <HStack justifyContent='center' alignItems='center'>
          <XText variant='active' fontSize='lg'>
            วันนี้
          </XText>
        </HStack>
        <HStack h='12'>
          {this.renderTodaySalesInfoComponent({
            txtLabel: 'ยอดขาย',
            txtColor: displayCharts[0] ? displayCharts[0].activeBgColor : null,
            txtData: xFmt.formatCurrency(sales_amount),
          })}
          {this.renderTodaySalesInfoComponent({
            txtLabel: 'กำไร',
            txtColor: displayCharts[1] ? displayCharts[1].activeBgColor : null,
            txtData: xFmt.formatCurrency(profit_amount),
          })}
          {this.renderTodaySalesInfoComponent({
            txtLabel: 'ออเดอร์ขาย',
            txtColor: displayCharts[2] ? displayCharts[2].activeBgColor : null,
            txtData: order_count,
            isLastComponent: true,
          })}
        </HStack>
      </XCard>
    )
  }

  _renderPastSalesInfo = () => (
    // const { selectedOptionTimePeriod } = this.state
    <XCard w='full' p='2' mt='2'>
      <HStack justifyContent='center' alignItems='center'>
        <XText variant='active' fontSize='lg'>
          ย้อนหลัง
        </XText>
      </HStack>
      <Segment
        options={this.OPTIONS_TIME_PERIOD}
        selectedIndex={this.state.selectedOptionTimePeriodIndex}
        onSegmentChange={this._onSegmentPastTimePeriodChange}
      />
      {this._renderShippingDateRange()}
      <Box mt='2' />
      {this._renderPastSalesInfoByOption()}
    </XCard>
  )

  // _renderPastSalesInfoByOption = sOpt => {
  _renderPastSalesInfoByOption = () => {
    const { selectedOptionTimePeriodIndex } = this.state
    // if (sOpt === this.OPTIONS_TIME_PERIOD[0] || sOpt === this.OPTIONS_TIME_PERIOD[1]) {
    if (_.includes([0, 1, 2], selectedOptionTimePeriodIndex)) {
      return (
        <VStack w='full'>
          {this._renderPastSummaryBlocks()}
          {this._renderPastChart()}
        </VStack>
      )
    }
    return null
  }

  _renderShippingDateRange(): JSX.Element {
    const { subscription } = this.props
    const type = subscription.get('type')
    const isVisible = this._isVisibleDateRangePicker()
    const isDisabledDateRange = this._computeAllowDays()
    const num = type === 5 ? 99999 : +p.op.t(`Subscription.packageReportSeller.${type}`)
    const labels = {
      begin: 'วันที่',
      end: 'ถึง',
    }
    const { selectedDateRange } = this.state
    let period = dayjs().diff(selectedDateRange.begin, 'day')
    if (period === 0 || period === 1) {
      period = 2
    }
    const maxDate = dayjs().subtract(1, 'days')
    return isVisible ? (
      <Box mt='3'>
        <Box h='24'>
          <DateRangePicker
            disabled={isDisabledDateRange}
            allowOnlyDaysFromToday={num}
            allowDateRangeLimitDays={0}
            // @ts-ignore
            selectedDates={selectedDateRange}
            onChangeDate={(newDate) => this._onChangeDateRange(newDate)}
            labels={labels}
            maxDate={maxDate}
            // isVisibleEndDate={false}
          />
        </Box>
        {isDisabledDateRange ? (
          <XText fontSize='sm' variant='primary' textAlign='center'>
            {this.TXT_WARNING_DATE_RANGE}
          </XText>
        ) : (
          <XText fontSize='sm' variant='primary' textAlign='center'>
            {type === 5 ? `แพ็กเกจของคุณดูย้อนหลังได้ตั้งแต่เริ่มใช้งานระบบ` : `แพ็กเกจของคุณดูย้อนหลังได้ ${num} วัน`}
          </XText>
        )}
        <XButton mt='2' onPress={() => this._onSegmentPastTimePeriodChangeFromOnpress()}>
          ดูรายงาน
        </XButton>
      </Box>
    ) : (
      <Box />
    )
  }

  // _onRefresh = async () => {
  //   // await setStatePromise(this, {
  //   //   refreshing: true,
  //   //   selectedChartIndex: 0,
  //   //   selectedOptionTimePeriod: this.OPTIONS_TIME_PERIOD[0],
  //   // })
  //   const { selectedOptionTimePeriodIndex } = this.state
  //   this.SELECTED_DATE = true
  //   this._onSegmentPastTimePeriodChange(selectedOptionTimePeriodIndex)
  //   // await this.fetch()
  // }

  render() {
    // logRender(this)
    return (
      <XContainer>
        {this.renderCustomHeader()}
        <XContent px='2'>
          {this._renderTodaySalesInfo()}
          {this._renderPastSalesInfo()}
        </XContent>
      </XContainer>
    )
  }
}
