// import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
// import * as xStoreActions from 'x/modules/store/StoreState'
import { bindActionCreators } from 'redux'
import * as StoreState from 'x/modules/store/StoreState'

import {
  getSelectedStore,
  // getSubscriptionIsActiveWarning,
  getShouldFetchSelectedStore,
} from 'x/redux/selectors'
import StoreOrdersDonutListView from './StoreOrdersDonutListView'

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
    // เดี๋ยวต้องวาง mechanic สำหรับ set/get shouldFetchStoreInfo โดยมีข้างในเป็น array of key เพื่อความละเอียดของการเลือก fetch
    shouldFetchStoreInfo: getShouldFetchSelectedStore(state),
    // isActiveSubscriptionWarning: getSubscriptionIsActiveWarning(state),
  }),
  (dispatch) => ({
    dispatch,
    fetchMyStoreInfo: bindActionCreators(StoreState.fetchMyStore, dispatch),
    updateXShippingBalance: bindActionCreators(StoreState.updateXShippingBalance, dispatch),
    updateSelectedStoreByKey: bindActionCreators(StoreState.updateSelectedStoreByKey, dispatch),
    // fetchMyStore: params => dispatch(xStoreActions.fetchMyStore(params)),
    // fetchMyStores: params => dispatch(xStoreActions.fetchMyStores(params)),
  })
)(StoreOrdersDonutListView)
