import React from 'react'
import { StyleSheet } from 'react-native'

import _ from 'lodash'
// import p from 'x/config/platform-specific'
// import CONS from 'x/config/constants'
import { IXSegmentProps } from 'x/index'

import Segment from './Segment'
import XTitle from './XTitle'

// Generic Function Component: https://stackoverflow.com/questions/51459971/type-of-generic-stateless-component-react-or-extending-generic-function-interfa
// export const XSegmentGenric = <T extends any>() => {
//   // Fc component
//   const ComponentInstance: React.FC<IXSegmentProps<T>> = (props) => {
//     const [selectedSegmentIndex, setSelectedSegmentIndex] = React.useState(0)
//     const itemWidthPercent = 100 / props.options.length

//     React.useEffect(() => {
//       if (props.initialIndex > 0) {
//         _onSegmentChange(props.initialIndex)
//       } else {
//         _onSegmentChange(0)
//       }
//     }, [])

//     async function _onSegmentChange(newIndex: number) {
//       setSelectedSegmentIndex(newIndex)
//       await util.delay(100)

//       const selectedOption = props.options[newIndex]
//       if (util.isAsyncFunction(props.onSegmentChange)) {
//         await props.onSegmentChange(selectedOption, newIndex)
//       } else {
//         props.onSegmentChange(selectedOption, newIndex)
//       }

//       await util.delay(100)
//     }

//     return (
//       <View>
//         <SegmentTitle value={props.title} />

//         <View style={{ minHeight: 25 }}>
//           <Segment
//             selectedIndex={selectedSegmentIndex}
//             onSegmentChange={_onSegmentChange}
//             options={props.options.map((opt) => opt.label)}
//           />
//         </View>
//       </View>
//     )
//   }

//   ComponentInstance.defaultProps = {
//     initialIndex: 0,
//   }

//   return ComponentInstance
// }

function XSegment<T>(props: IXSegmentProps<T>) {
  const [selectedSegmentIndex, setSelectedSegmentIndex] = React.useState(0)
  const [isChangingSegment, setIsChangingSegment] = React.useState(false)
  const [hideOptionIndexes, setHideOptionIndexes] = React.useState([])
  // const itemWidthPercent = 100 / props.options.length

  React.useEffect(() => {
    let isMounted = true
    if (isMounted) {
      _loadSelectedSegmentIndexFromInitialIndex()
    }
    return () => {
      isMounted = false
    }
  }, [])

  React.useEffect(() => {
    _loadSelectedSegmentIndexFromProps()
  }, [props.selectedIndex])

  React.useEffect(() => {
    const newHideOptionIndexes = props.hideOptionKeys?.map((key) => props.options.findIndex((opt) => opt.key === key))
    if (!_.isEqual(hideOptionIndexes, newHideOptionIndexes)) {
      setHideOptionIndexes(newHideOptionIndexes)
    }
  }, [props.hideOptionKeys])

  // async function _initialize() {
  //   await _loadSelectedSegmentIndexFromInitialIndex()
  //   await _loadSelectedSegmentIndexFromProps()
  // }

  async function _loadSelectedSegmentIndexFromInitialIndex() {
    if (_.isNumber(props.initialIndex) && props.initialIndex >= 0) {
      await _onSegmentChange(props.initialIndex)
    }
  }

  async function _loadSelectedSegmentIndexFromProps() {
    if (_.isNumber(props.selectedIndex) && selectedSegmentIndex !== props.selectedIndex) {
      // await _onSegmentChange(props.selectedIndex)
      setSelectedSegmentIndex(props.selectedIndex)
    }
  }

  async function _onSegmentChange(newIndex: number) {
    const selectedOption = props.options[newIndex]
    // if (util.isAsyncFunction(props.onSegmentChange)) {
    //   await props.onSegmentChange(selectedOption, newIndex)
    // } else {
    //   props.onSegmentChange(selectedOption, newIndex)
    // }
    props.onSegmentChange(selectedOption, newIndex)
    setSelectedSegmentIndex(newIndex)
  }

  return (
    <>
      {/* <SegmentTitle value={props.title} style={props.titleStyle} /> */}
      <XTitle value={props.title} style={props.titleStyle} />
      <Segment
        disabled={props.disabled}
        containerStyle={props.segmentContainerStyle}
        textStyle={StyleSheet.flatten(props.segmentTextStyle)}
        selectedIndex={_.isNumber(props.selectedIndex) ? props.selectedIndex : selectedSegmentIndex}
        onSegmentChange={_onSegmentChange}
        options={props.options.map((opt) => opt.label)}
        hideOptionIndexes={hideOptionIndexes}
      />
    </>
  )
}

XSegment.defaultProps = {
  // initialIndex: 0,
  titleStyle: {},
  segmentContainerStyle: {},
  segmentTextStyle: {},
}

// const SegmentTitle = ({ value = null, style = {} }) => {
//   if (!value) {
//     return null
//   }
//   return (
//     <Text
//       allowFontScaling={false}
//       style={[
//         {
//           fontSize: STYLES.FONT_SIZE_NORMAL,
//           color: COLORS.TEXT_ACTIVE,
//           fontWeight: 'bold',
//           marginLeft: 2,
//           marginBottom: 2,
//         },
//         style,
//       ]}>
//       {value}
//     </Text>
//   )
// }

// const XSegment = XSegmentGenric<any>()

export default XSegment
