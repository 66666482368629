import NavInterface from 'x/utils/nav-interface'

// import {fromJS, isImmutable} from "immutable";

// import * as analytics from './analytics'
// import _ from 'lodash'
// import * as xUtil from 'x/utils/util'
// import store from '../redux/store'

// import xCONS from 'x/config/constants'

export default class NavImplWeb implements NavInterface {
  navToOrderDetail(orderId: number, storeId: number): void {
    console.log('Not Yet Implemented --> NavImplNative.navToOrderDetail')
    return null
    // return NavActions.navToOrderDetail({ order_id: orderId, store_id: storeId })
  }

  resetToHome(): void {
    // TODO
  }

  resetToAppLauncher(): void {
    // TODO
  }
}
