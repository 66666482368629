import React, { useState } from 'react'

import p from 'x/config/platform-specific'
import NB from 'x/config/nativebase'
import CONS from 'x/config/constants'

import { OrdersTabKeyType } from 'x/index'
import { COLORS } from 'x/config/styles'
import XHorizontalScrollView from '../XHorizontalScrollView'
import Badge from '../Badge'
import XButtonWithIcon from '../XButtonWithIcon'
import XText from '../XText'
import HStack from '../HStack'
import VStack from '../VStack'
import Box from '../Box'
// const MAIN_TAB_KEYS = ['myTasks', 'custTasks', 'resellerTasks', 'sellerTasks', 'doneTasks']

// const SUB_TAB_KEYS = {
//   myTasks: ['confirmOrder', 'pay', 'confirmGettingPaid', 'ship'],
//   custTasks: ['pay', 'autoComplete'],
//   resellerTasks: ['confirmOrder', 'pay', 'confirmGettingPaid'],
//   sellerTasks: ['confirmOrder', 'confirmGettingPaid', 'ship', 'pay'],
//   doneTasks: ['shipped', 'autoCompleted', 'cancelled'],
// }

// const ALL_TAB_KEYS: string[] = MAIN_TAB_KEYS.reduce(
//   (prevKeys, mainKey) => prevKeys.concat(SUB_TAB_KEYS[mainKey].map((subKey) => `${mainKey}_${subKey}`)),
//   []
// )

interface IOrderListHeaderTabsProps {
  disabled?: boolean
  availableTabKeys?: OrdersTabKeyType[] // full key eg. myTasks_confirmOrder
  onChangeTab: (tabKey: OrdersTabKeyType) => void // full key eg. myTasks_confirmOrder

  orderCountSummaryMap?: {
    [tabKey: string]: number // eg. { custTasks_pay: 108 }
  }
}

const OrderListHeaderTabs: React.FC<IOrderListHeaderTabsProps> = ({
  disabled = false,
  // availableTabKeys = ALL_TAB_KEYS,
  availableTabKeys = CONS.ORDER_SUMMARY_ALL,
  onChangeTab,
  orderCountSummaryMap = {},
}) => {
  const [isInitialized, setIsInitialized] = useState<boolean>(false)
  const [selectedMainTabIndex, setSelectedMainTabIndex] = useState<number>(0)
  const [selectedSubTabIndex, setSelectedSubTabIndex] = useState<number>(0)
  const [mainTabKeys, setMainTabKeys] = useState<string[]>([])
  const [subTabKeys, setSubTabKeys] = useState<string[]>([])

  const _onPressMainTab = (newMainTabKey: string, newMainTabIndex: number) => {
    setSelectedMainTabIndex(newMainTabIndex)
    const generatedSubTabKeys = getSubTabKeys(availableTabKeys, newMainTabKey)
    setSubTabKeys(generatedSubTabKeys)
    // _onPressSubTab(generatedSubTabKeys[0], 0)
  }

  const _onPressSubTab = (newSubTabKey: string, newSubTabIndex: number) => {
    setSelectedSubTabIndex(newSubTabIndex)
    onChangeTab(`${mainTabKeys[selectedMainTabIndex]}_${newSubTabKey}`)
  }

  const _computeKeysFromProps = () => {
    const generatedMainTabKeys = getMainTabKeys(availableTabKeys)
    setMainTabKeys(generatedMainTabKeys)
    const generatedSubTabKeys = getSubTabKeys(availableTabKeys, generatedMainTabKeys[0])
    setSubTabKeys(generatedSubTabKeys)
  }

  React.useEffect(() => {
    let isMounted = true
    if (isMounted) {
      _computeKeysFromProps()
      if (!isInitialized) {
        setTimeout(() => {
          setIsInitialized(true)
        }, 150)
      }
    }
    return () => {
      isMounted = false
    }
  }, [])

  React.useEffect(() => {
    // // console.log('OrderListHeaderTabs availableTabKeys => ', availableTabKeys)
    // const generatedMainTabKeys = getMainTabKeys(availableTabKeys)
    // setMainTabKeys(generatedMainTabKeys)
    // const generatedSubTabKeys = getSubTabKeys(availableTabKeys, generatedMainTabKeys[0])
    // setSubTabKeys(generatedSubTabKeys)
    _computeKeysFromProps()
  }, [availableTabKeys])

  // when subTabKeys was changed
  React.useEffect(() => {
    if (mainTabKeys.length > 0 && subTabKeys.length > 0) {
      _onPressSubTab(subTabKeys[0], 0)
    }
  }, [subTabKeys])

  if (mainTabKeys.length === 0) {
    return null
  }

  const _renderOneLevelTabs = () =>
    subTabKeys.map((subTabKey, subTabIdx) => {
      const fullTabKey = `${mainTabKeys[selectedMainTabIndex]}_${subTabKey}`
      const badgeCount = orderCountSummaryMap ? orderCountSummaryMap[fullTabKey] : undefined
      return (
        <OneLevelTabButton
          disabled={disabled}
          key={`${subTabKey}_${subTabIdx.toString()}`}
          onPress={_onPressSubTab}
          mainTabKey={mainTabKeys[selectedMainTabIndex]}
          subTabKey={subTabKey}
          tabIndex={subTabIdx}
          isHighlighted={subTabIdx === selectedSubTabIndex}
          badgeCount={badgeCount}
        />
      )
    })

  const _renderMainTabs = () =>
    mainTabKeys.map((mainTabKey, mainTabIdx) => {
      const relatedTabKeys = Object.keys(orderCountSummaryMap).filter((tk) => tk.startsWith(mainTabKey))
      const badgeCount = relatedTabKeys.reduce((prevBadgeCount, rtk) => {
        const bc = orderCountSummaryMap ? orderCountSummaryMap[rtk] : undefined
        return prevBadgeCount + bc
      }, 0)

      return (
        <MainTabButton
          disabled={disabled}
          key={`${mainTabKey}_${mainTabIdx.toString()}`}
          onPress={_onPressMainTab}
          mainTabKey={mainTabKey}
          tabIndex={mainTabIdx}
          isHighlighted={mainTabIdx === selectedMainTabIndex}
          badgeCount={badgeCount}
        />
      )
    })

  const _renderSubTabs = () =>
    subTabKeys.map((subTabKey, subTabIdx) => {
      const fullTabKey = `${mainTabKeys[selectedMainTabIndex]}_${subTabKey}`
      const badgeCount = orderCountSummaryMap ? orderCountSummaryMap[fullTabKey] : undefined
      return (
        <SubTabButton
          disabled={disabled}
          key={`${subTabKey}_${subTabIdx.toString()}`}
          onPress={_onPressSubTab}
          mainTabKey={mainTabKeys[selectedMainTabIndex]}
          subTabKey={subTabKey}
          tabIndex={subTabIdx}
          isHighlighted={subTabIdx === selectedSubTabIndex}
          badgeCount={badgeCount}
        />
      )
    })

  if (!isInitialized) {
    return null
  }

  if (mainTabKeys.length === 1) {
    return (
      <VStack w='full'>
        <XHorizontalScrollView key={`${mainTabKeys.length}_${availableTabKeys.length}`}>{_renderOneLevelTabs()}</XHorizontalScrollView>
      </VStack>
    )
  }

  return (
    <VStack w='full'>
      <XHorizontalScrollView key={`${mainTabKeys.length}_${availableTabKeys.length}`}>{_renderMainTabs()}</XHorizontalScrollView>

      {!subTabKeys || subTabKeys.length === 0 ? null : (
        <XHorizontalScrollView
          key={`${mainTabKeys[selectedMainTabIndex]}_${mainTabKeys.length}_${subTabKeys.length}`}
          // _light={{ bg: NB.C.L.TEXT.PRIMARY }}
          // _dark={{ bg: NB.C.L.TEXT.PRIMARY }}
          style={{ backgroundColor: COLORS.APP_MAIN }}>
          {_renderSubTabs()}
        </XHorizontalScrollView>
      )}
    </VStack>
  )
}

function splitOrdersTabKey(tabKeyString: string): string[] {
  // split once: https://stackoverflow.com/questions/2878703/split-string-once-in-javascript
  const i = tabKeyString.indexOf('_')
  if (i === -1) {
    return [tabKeyString]
  }
  const splits = [tabKeyString.slice(0, i), tabKeyString.slice(i + 1)]
  return splits
}

function getMainTabKeys(allTabKeys: string[]): string[] {
  const availableMainTabKeys = allTabKeys.map((tk) => splitOrdersTabKey(tk)[0])
  const uniqueAvailableMainTabKeys = availableMainTabKeys.filter((mk, idx) => availableMainTabKeys.indexOf(mk) === idx)
  return uniqueAvailableMainTabKeys
}

function getSubTabKeys(allTabKeys: string[], mainTabKey: string): string[] {
  const availableSubTabKeys = allTabKeys.filter((tk) => tk.startsWith(mainTabKey)).map((tk) => splitOrdersTabKey(tk)[1])
  const uniqueAvailableSubTabKeys = availableSubTabKeys.filter((mk, idx) => availableSubTabKeys.indexOf(mk) === idx)
  return uniqueAvailableSubTabKeys
}

interface IOneLevelTabButtonProps {
  disabled?: boolean
  mainTabKey: string
  subTabKey: string
  tabIndex: number
  isHighlighted?: boolean
  badgeCount?: number
  onPress: (subTabKey: string, tabIndex: number) => void
}

const OneLevelTabButton: React.FC<IOneLevelTabButtonProps> = ({
  disabled,
  mainTabKey,
  subTabKey,
  tabIndex,
  onPress,
  isHighlighted = false,
  badgeCount,
}) => {
  const _onPressButton = React.useCallback(() => {
    onPress(subTabKey, tabIndex)
  }, [onPress, subTabKey, tabIndex])
  return (
    <XButtonWithIcon
      disabled={disabled}
      // isDisabled={disabled}
      onPress={_onPressButton}
      variant={isHighlighted ? 'solid' : 'outline'}
      // _text={isHighlighted ? { variant: 'activeDark', fontSize: 'md', bold: true } : { variant: 'primary' }}
      borderRadius='none'
      borderTopRightRadius='2xl'
      borderBottomWidth='0'
      mr='1'
      // w='36'
      minW='12'
      px='2'
      py={p.op.isWeb() ? '1' : '2'}
      // rightIcon={
      //   badgeCount ? (
      //     <Badge  variant='outline'>
      //       {badgeCount}
      //     </Badge>
      //   ) : undefined
      // }
    >
      <HStack space='1' alignItems='center'>
        <XText variant={isHighlighted ? 'activeDark' : 'primary'} bold={isHighlighted}>
          {p.op.t(`OrderListHeaderOneLevelTab.${mainTabKey}_${subTabKey}`)}
        </XText>
        {badgeCount ? <Badge variant='outline'>{badgeCount}</Badge> : undefined}
      </HStack>
    </XButtonWithIcon>
  )
}

interface IMainTabButtonProps {
  disabled?: boolean
  mainTabKey: string
  tabIndex: number
  isHighlighted?: boolean
  badgeCount?: number
  onPress: (mainTabKey: string, tabIndex: number) => void
}

const MainTabButton: React.FC<IMainTabButtonProps> = ({ disabled, mainTabKey, tabIndex, onPress, isHighlighted = false, badgeCount }) => {
  const _onPressButton = React.useCallback(() => {
    onPress(mainTabKey, tabIndex)
  }, [mainTabKey, onPress, tabIndex])
  return (
    <XButtonWithIcon
      disabled={disabled}
      // isDisabled={disabled}
      onPress={_onPressButton}
      variant={isHighlighted ? 'solid' : 'outline'}
      // _text={isHighlighted ? { variant: 'activeDark', fontSize: 'md', bold: true } : { variant: 'primary' }}
      borderRadius='none'
      borderTopRightRadius='2xl'
      borderBottomWidth='0'
      mr='1'
      // w='36'
      minW='12'
      px='2'
      py={p.op.isWeb() ? '1' : '2'}
      // rightIcon={
      //   badgeCount ? (
      //     <Badge  variant='outline'>
      //       {badgeCount}
      //     </Badge>
      //   ) : undefined
      // }
    >
      {/* {p.op.t(`Order.${mainTabKey}`)} */}
      {/* {p.op.t(`Order.mainHeaderTab.${mainTabKey}`)} */}
      <HStack space='1' alignItems='center'>
        <XText variant={isHighlighted ? 'activeDark' : 'primary'} bold={isHighlighted}>
          {p.op.t(`Order.mainHeaderTab.${mainTabKey}`)}
        </XText>
        {badgeCount ? <Badge variant='outline'>{badgeCount}</Badge> : undefined}
      </HStack>
    </XButtonWithIcon>
  )
}

interface ISubTabButtonProps {
  disabled?: boolean
  mainTabKey: string
  subTabKey: string
  tabIndex: number
  isHighlighted?: boolean
  badgeCount?: number
  onPress: (subTabKey: string, tabIndex: number) => void
}

const SubTabButton: React.FC<ISubTabButtonProps> = ({ disabled, mainTabKey, subTabKey, tabIndex, onPress, isHighlighted, badgeCount }) => {
  const _onPressButton = React.useCallback(() => {
    onPress(subTabKey, tabIndex)
  }, [onPress, subTabKey, tabIndex])
  return (
    <XButtonWithIcon
      disabled={disabled}
      // isDisabled={disabled}
      onPress={_onPressButton}
      variant='solid'
      _text={isHighlighted ? { fontSize: 'md', bold: true } : {}}
      // borderRadius='none'
      borderTopWidth='0'
      minW='12'
      px='2'
      py={p.op.isWeb() ? '1' : '2'}
      // pt={p.op.isWeb() ? '0.5' : '1.5'}
      // pb={p.op.isWeb() ? '1' : '2'}
      // rightIcon={
      //   badgeCount ? (
      //     <Badge  variant='outline'>
      //       {badgeCount}
      //     </Badge>
      //   ) : undefined
      // }
    >
      <VStack space='0.5'>
        {/* {p.op.t(`Order.${subTabKey}`)} */}
        <HStack space='0.5' alignItems='center'>
          <XText variant='activeDark' bold={isHighlighted}>
            {p.op.t(`Order.subHeaderTab.${mainTabKey}_${subTabKey}`)}
          </XText>
          {badgeCount ? <Badge variant='outline'>{badgeCount}</Badge> : null}
        </HStack>
        <Box
          w='full'
          borderBottomWidth='1'
          // borderBottomColor={NB.C.L.BG.CLEAR}
          _light={{ borderBottomColor: isHighlighted ? NB.C.L.BG.CLEAR : 'transparent' }}
          _dark={{ borderBottomColor: isHighlighted ? NB.C.L.BG.CLEAR : 'transparent' }}
        />
      </VStack>
    </XButtonWithIcon>
  )
}

export default OrderListHeaderTabs
