import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as PreferenceState from 'x/modules/preference/PreferenceState'

import { getSelectedStore, getSelectedCustomOrderView } from 'x/redux/selectors'
import OrderCopyTemplateView from './OrderCopyTemplateView'

// import * as AddressActions from '../address/AddressState'

export default connect(
  (state) => {
    return {
      selectedStore: getSelectedStore(state),
      selectedCustomOrderView: getSelectedCustomOrderView(state),
    }
  },
  (dispatch) => {
    return {
      // pickerClear: bindActionCreators(bpActions.pickerClear, dispatch),
      // pickerSelect: bindActionCreators(bpActions.pickerSelect, dispatch),
      addCustomOrder: bindActionCreators(PreferenceState.addCustomOrder, dispatch),
      loadPrefUIOrderView: bindActionCreators(PreferenceState.loadPrefUIOrderView, dispatch),
    }
  }
)(OrderCopyTemplateView)
