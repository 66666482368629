import React from 'react'
import * as NavActions from 'x/utils/navigation'
import _ from 'lodash'
import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'
import { ISelectedStoreMap, IProfileMap, IXScreenProps } from 'x/index'

interface IBaseSettingResetPasswordViewProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
  profile: IProfileMap
  dispatch?: (param?: any) => void
}

interface IBaseSettingResetPasswordViewState {
  refreshing: boolean
  txtOldPassword: string
  txtNewPassword: string
  txtConfirmNewPassword: string
}
export default abstract class BaseSettingResetPasswordView extends React.Component<
  IBaseSettingResetPasswordViewProps,
  IBaseSettingResetPasswordViewState
> {
  // abstract async handleSubmitForm(response: any): Promise<void>
  // abstract didMountCheckPermissionForPhone?: () => void
  // validationSchema: Yup.ObjectSchema<any>
  txtOldPasswordRef: React.RefObject<any>

  constructor(props) {
    super(props)
    this.state = {
      refreshing: false,
      txtOldPassword: '',
      txtNewPassword: '',
      txtConfirmNewPassword: '',
    }
    this.txtOldPasswordRef = React.createRef()
  }

  componentDidMount() {
    this.configtxtOldPasswordRef()
    // await this._onRefresh()
  }

  configtxtOldPasswordRef = () => {
    if (this.txtOldPasswordRef && this.txtOldPasswordRef.current) {
      setTimeout(() => this.txtOldPasswordRef.current.focus(), 200)
    }
  }

  onChangeOldPasswordText = (newText: string) => {
    this.setState({ txtOldPassword: newText.trim() })
  }

  onChangeNewPasswordText = (newText: string) => {
    this.setState({ txtNewPassword: newText.trim() })
  }

  onChangeConfirmNewPasswordText = (newText: string) => {
    this.setState({ txtConfirmNewPassword: newText.trim() })
  }

  _setRefreshing = async () => {
    await util.setStatePromise(this, {
      refreshing: true,
    })
  }

  _unSetRefreshing = async () => {
    await util.setStatePromise(this, {
      refreshing: false,
    })
  }

  _handleForgottenPassword = () => {
    const { navigation, profile, dispatch } = this.props
    const email = profile.get('email') || null
    if (email) {
      const sendingPassword = () => {
        dispatch({
          type: 'AUTH/FORGOT_ACCOUNT_PASSWORD',
          payload: { email },
        })
      }

      const changeEmail = () => {
        navigation.dispatch(NavActions.navToSettingEmailView)
      }

      p.op.showConfirmationThreeButtons(
        'ยืนยันส่งรหัสผ่านไปยังอีเมล',
        `"${email}" คืออีเมลของคุณ คุณต้องการให้เราส่งรหัสผ่านใหม่ไปที่อีเมลนี้หรือไม่`,
        'ส่งรหัสผ่าน',
        sendingPassword,
        'เปลี่ยนอีเมล',
        changeEmail,
        'ยกเลิก'
      )
    }
  }

  _NewPasswordValidation = () => {
    const { txtOldPassword, txtNewPassword } = this.state
    if (txtNewPassword === txtOldPassword) {
      return false
    }
    // 8 - 20 ตัวอักษร ตัวพิมพ์เล็ก/ใหญ่ อย่างน้อยอย่าละ 1 และ และตัวเลข 1 ถึงจะยอมให้ผ่าน
    return util.isEffilyPasswordValidFormat(txtNewPassword)
  }

  _NewPasswordConfirmationValidation() {
    const { txtNewPassword, txtConfirmNewPassword } = this.state
    if (txtNewPassword !== txtConfirmNewPassword) {
      return false
    }
    return util.isEffilyPasswordValidFormat(txtConfirmNewPassword)
  }

  _submitFunction = async () => {
    const { dispatch } = this.props
    const { txtOldPassword, refreshing, txtNewPassword, txtConfirmNewPassword } = this.state
    if (refreshing) {
      return
    }
    await this._setRefreshing()
    if (txtOldPassword.length < 6) {
      p.op.showConfirmationOkOnly(
        'รหัสผ่านปัจจุบันไม่ถูกต้อง',
        'กรุณาระบุรหัสผ่านที่ในรูปแบบที่มีความปลอดภัย ประกอบไปด้วย\n' +
          '- ตัวพิมพ์เล็ก อย่างน้อย 1 ตัวอักษร\n' +
          '- ตัวพิมพ์ใหญ่ อย่างน้อย 1 ตัวอักษร\n' +
          '- ตัวเลข อย่างน้อย 1 ตัวอักษร\n' +
          '- มีจำนวนตัวอักษรรวมทั้งหมด 8 ตัวอักษรขึ้นไป'
      )
      await this._unSetRefreshing()
      return
    }

    if (!this._NewPasswordValidation()) {
      p.op.showConfirmationOkOnly(
        'รูปแบบรหัสผ่านใหม่ไม่ถูกต้อง',
        'กรุณาระบุรหัสผ่านใหม่ที่ในรูปแบบที่มีความปลอดภัย ประกอบไปด้วย\n' +
          '- ตัวพิมพ์เล็ก อย่างน้อย 1 ตัวอักษร\n' +
          '- ตัวพิมพ์ใหญ่ อย่างน้อย 1 ตัวอักษร\n' +
          '- ตัวเลข อย่างน้อย 1 ตัวอักษร\n' +
          '- มีจำนวนตัวอักษรรวมทั้งหมด 8 ตัวอักษรขึ้นไป\n' +
          '- รหัสผ่านใหม่ไม่ซ้ำกับรหัสผ่านเก่า'
      )
      await this._unSetRefreshing()
      return
    }

    if (!this._NewPasswordConfirmationValidation()) {
      p.op.showConfirmationOkOnly('ยืนยันรหัสผ่านใหม่ไม่ถูกต้อง', 'กรุณาระบุช่องยืนยันรหัสผ่านใหม่ให้ตรงกับรหัสผ่านใหม่')
      await this._unSetRefreshing()
      return
    }

    const body = {
      old_password: txtOldPassword,
      password: txtNewPassword,
      password_confirmation: txtConfirmNewPassword,
    }
    const successCallback = () => {
      util.navGoBack(this.props)
    }

    dispatch({
      type: 'AUTH/RESET_ACCOUNT_PASSWORD',
      payload: body,
      successCallback,
      failedCallback: (err) => {
        // console.log('ERR => ', err)
        if (!_.isNil(err.error)) {
          if (err.error.error_code === '03_043') {
            ///
            const newState = _.cloneDeep(this.state)
            this.onChangeOldPasswordText('')
            p.op.showConfirmationOkOnly('รหัสผ่านเก่าไม่ถูกต้อง', 'กรุณาระบุรหัสผ่านเก่าอีกครั้ง')
            // this.setState({ currentRef: 'txtInput_old_password' })
          }
        }
      },
    })
    await this._unSetRefreshing()
  }
}
