import { connect } from 'react-redux'
import ProductListViewMkpView from './ProductListViewMkpView'
import { getState, getDispatch } from 'x/modules/productlist/ProductListViewConnect'
import { getMkpChannels } from 'x/redux/selectors'

export default connect(
  (state) => {
    return {
      ...getState(state),
      mkpChannels: getMkpChannels(state),
    }
  },
  (dispatch) => getDispatch(dispatch)
)(ProductListViewMkpView)
