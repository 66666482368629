import React, { useState } from 'react'
import { TouchableOpacity, Linking } from 'react-native'
import { IFaqModal } from 'x/index'
import _ from 'lodash'
import { Pressable } from 'native-base'
import HStack from 'xui/components/HStack'
import p from 'x/config/platform-specific'
import NB from 'x/config/nativebase'
import XIcon from './XIcon'
import XModal from './XModal'
import XText from './XText'
import XContent from './XContent'
import VStack from './VStack'
import XImage from './XImage'
import XButton from './XButton'

interface XFaqModalProps {
  onClose?: () => void
  FAQ: IFaqModal[]
  // urlVideo?: string
  renderButton: (onPressOpenOverlay: () => void) => JSX.Element
  headerTitle: string
  initiateOpenIndex?: number | number[]
}

const XFaqModal: React.FC<XFaqModalProps> = function ({
  onClose,
  FAQ,
  // urlVideo,
  renderButton,
  headerTitle,
  initiateOpenIndex,
}) {
  const [isOpenModal, setOpenModal] = useState<boolean>(false)
  const [selectedOpenAnswersListIndex, setSelectedOpenAnswersListIndex] = useState<number[]>(
    // eslint-disable-next-line no-nested-ternary
    _.isNil(initiateOpenIndex) ? [] : _.isArray(initiateOpenIndex) ? initiateOpenIndex : [initiateOpenIndex]
  )

  // useEffect(() => {
  //   // console.log('value  ?? ', value)
  //   setValue(value)
  // }, [value])

  const openModal = () => {
    setOpenModal(true)
  }

  const closeModal = () => {
    setOpenModal(false)
    if (_.isFunction(onClose)) {
      onClose()
    }
  }

  const onClickQuestions = (index: number) => {
    let newSelectedOpenAnswersListIndex: number[] = []
    if (selectedOpenAnswersListIndex.length < 1) {
      newSelectedOpenAnswersListIndex.push(index)
      setSelectedOpenAnswersListIndex(newSelectedOpenAnswersListIndex)
      return
    }
    const findIndex = _.find(selectedOpenAnswersListIndex, (idx: number) => idx === index)
    if (_.isNil(findIndex)) {
      newSelectedOpenAnswersListIndex = [...selectedOpenAnswersListIndex]
      newSelectedOpenAnswersListIndex.push(index)
      setSelectedOpenAnswersListIndex(newSelectedOpenAnswersListIndex)
      return
    }
    newSelectedOpenAnswersListIndex = selectedOpenAnswersListIndex.filter((idx: number) => idx !== index)
    setSelectedOpenAnswersListIndex(newSelectedOpenAnswersListIndex)
  }

  // const renderLeftHeader = () => (
  //   <TouchableOpacity
  //     onPress={() => closeModal()}
  //     style={{ width: 50, height: 50, justifyContent: 'center', alignItems: 'center', alignContent: 'center', marginLeft: -10 }}>
  //     <XIcon
  //       name='arrowleft'
  //       family='AntDesign'
  //       style={{ color: COLORS.APP_MAIN, minWidth: 25, width: 25, textAlign: 'center', flex: 0 }}
  //     />
  //   </TouchableOpacity>
  // )

  // const renderVdoView = (vdo: { i: string; t: string; u: string }) => (
  //   <HStack m='2' key={vdo.t}>
  //     <TouchableOpacity
  //       onPress={() => {
  //         if (p.op.isWeb()) {
  //           window.open(vdo.u, '_blank')
  //         } else {
  //           Linking.openURL(vdo.u)
  //         }
  //       }}>
  //       <XImage style={{ height: 94, width: 168 }} source={{ uri: vdo.i }} />
  //     </TouchableOpacity>
  //     <HStack flex={1}>
  //       <XText bold ml='2'>
  //         {vdo.t}
  //       </XText>
  //     </HStack>
  //   </HStack>
  // )

  const renderModal = () => {
    if (!isOpenModal) {
      return null
    }
    return (
      <XModal
        title={headerTitle}
        // headerLeft={() => renderLeftHeader()}
        onRequestClose={() => closeModal()}
        visible={isOpenModal}
        hasCloseButton
        // webWrapperContainerStyle={{ backgroundColor: 'white' }}
        webNumColumns={1}>
        <XContent>
          <VStack w='full'>
            {FAQ.map((qa: IFaqModal, index: number) => {
              const findIndex = _.find(selectedOpenAnswersListIndex, (idx: number) => idx === index)
              const isOpenAnswers = !_.isNil(findIndex)
              // @ts-ignore FIXME: Property 'v' does not exist on type 'IQuestionsAndAnswers'.
              // const vdos = _.isArray(qa.v) ? qa.v : null
              // const text = qa.a ? qa.a : null
              const { blocks } = qa
              // console.log('vdos => ', vdos)
              return (
                <VStack
                  key={`QA_Item_${index.toString()}`}
                  w='full'
                  // style={[
                  //   S.COLUMN_LEFT_TOP,
                  //   { backgroundColor: COLORS.WHITE, borderBottomWidth: 0.5, borderBottomColor: COLORS.BG_SOFT_GREY, width: '100%' },
                  // ]}
                >
                  <Pressable
                    // style={[S.ROW_MIDDLE_BETWEEN, S.PADDING_VERTICAL_8, S.PADDING_HORIZONTAL_6, S.BG_SECONDARY]}
                    onPress={() => onClickQuestions(index)}>
                    {({ isHovered, isPressed }) => {
                      let bgColor = 'primary.100'
                      if (isHovered) {
                        bgColor = 'primary.100:alpha.70'
                      }
                      if (isPressed) {
                        bgColor = 'primary.100:alpha.60'
                      }
                      return (
                        <HStack w='full' py='2' px='3' space='2' flexWrap='wrap' alignItems='center' bg={bgColor}>
                          <XText flex={1} bold>
                            {qa.title}
                          </XText>
                          <XIcon name={isOpenAnswers ? 'remove-circle-outline' : 'add-circle-outline'} />
                        </HStack>
                      )
                    }}
                  </Pressable>
                  {isOpenAnswers ? (
                    <VStack _light={{ bg: NB.C.L.BG.CLEAR }} _dark={{ bg: NB.C.D.BG.CLEAR }} w='full'>
                      {blocks.map(
                        (box) => (
                          <VStack pb='2'>
                            {box.text ? (
                              <VStack w='full' py='2' px='3' key={box.text}>
                                <XText w='full'>{box.text}</XText>
                              </VStack>
                            ) : null}
                            {box.clip ? (
                              <HStack m='2' key={box.clip.title}>
                                <TouchableOpacity
                                  onPress={() => {
                                    if (p.op.isWeb()) {
                                      window.open(box.clip.clip_url, '_blank')
                                    } else {
                                      Linking.openURL(box.clip.clip_url)
                                    }
                                  }}>
                                  <XImage style={{ height: 94, width: 168 }} source={{ uri: box.clip.poster_url }} />
                                </TouchableOpacity>
                                <HStack flex={1}>
                                  <XText bold ml='2'>
                                    {box.clip.title}
                                  </XText>
                                </HStack>
                              </HStack>
                            ) : null}
                            {box.button ? (
                              <HStack w='full' px='2'>
                                <XButton
                                  flex={1}
                                  onPress={() => {
                                    if (p.op.isWeb()) {
                                      window.open(box.button.open_url, '_blank')
                                    } else {
                                      Linking.openURL(box.button.open_url)
                                    }
                                  }}
                                  variant={box.button.type}>
                                  {box.button.label}
                                </XButton>
                              </HStack>
                            ) : null}
                          </VStack>
                        )
                        // if (_.has(box, 'text')) {
                        //   return (
                        //     <VStack w='full' py='2' px='3' key={box.text}>
                        //       <XText w='full'>{box.text}</XText>
                        //     </VStack>
                        //   )
                        // }
                        // if (box.clip) {
                        //   return (
                        //     <HStack m='2' key={box.clip.title}>
                        //       <TouchableOpacity
                        //         onPress={() => {
                        //           if (p.op.isWeb()) {
                        //             window.open(box.clip.clip_url, '_blank')
                        //           } else {
                        //             Linking.openURL(box.clip.clip_url)
                        //           }
                        //         }}>
                        //         <XImage style={{ height: 94, width: 168 }} source={{ uri: box.clip.poster_url }} />
                        //       </TouchableOpacity>
                        //       <HStack flex={1}>
                        //         <XText bold ml='2'>
                        //           {box.clip.title}
                        //         </XText>
                        //       </HStack>
                        //     </HStack>
                        //   )
                        // }
                        // return null
                      )}
                    </VStack>
                  ) : null}
                  {/* {!_.isNil(vdos) && isOpenAnswers ? (
                    <VStack _light={{ bg: NB.C.L.BG.CLEAR }} _dark={{ bg: NB.C.D.BG.CLEAR }} w='full'>
                      {vdos.map((vdoData) => renderVdoView(vdoData))}
                    </VStack>
                  ) : null} */}
                  {/* {isOpenAnswers && !_.isNil(text) ? (
                    <VStack w='full' py='2' px='3' _light={{ bg: NB.C.L.BG.CLEAR }} _dark={{ bg: NB.C.D.BG.CLEAR }}>
                      <XText w='full'>{text}</XText>
                    </VStack>
                  ) : null} */}
                </VStack>
              )
            })}
          </VStack>
        </XContent>
      </XModal>
    )
  }

  return (
    <VStack justifyContent='center'>
      {renderButton(openModal)}
      {renderModal()}
    </VStack>
  )
}

export default XFaqModal
