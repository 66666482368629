import React from 'react'
import { Button, ButtonProps } from 'react-native'

import CompInterface from 'x/utils/comp-interface'

import XModal from '../components/XModal'
import XOverlay from '../components/XOverlay'
import DateRangePicker from '../components/DateRangePicker'
import XCamera from '../components/XCamera'
import ImgManager from '../components/ImgManager'
import TimePicker from '../components/TimePickerWeb'
// import PaymentAccountPicker from '../components/PaymentAccountPicker'
import DateTimeSinglePicker from '../components/DateTimeSinglePicker'
import BarcodeScanner from '../components/BarcodeScanner/BarcodeScanner'
import TextEditor from '../components/textEditor/TextEditor'
import XGalleryModal from '../components/XGalleryModal'
import XWebView from '../components/XWebView'

class TestButton extends React.Component<ButtonProps> {
  render() {
    return <Button color='red' {...this.props} />
  }
}

export default class CompImplWeb implements CompInterface {
  getTestButton() {
    // console.log('Not Yet Implemented --> NavImplNative.navToOrderDetail')
    return TestButton
  }

  getXModal() {
    return XModal
  }

  getXOverlay() {
    return XOverlay
  }

  getXDateRangePicker() {
    return DateRangePicker
  }

  // getXTimePicker :: ยังไม่พร้อม
  getXTimePicker() {
    return TimePicker
  }

  getXCamera() {
    return XCamera
  }

  getImgManager() {
    return ImgManager
  }

  // getPaymentAccountPicker() {
  //   return PaymentAccountPicker
  // }

  // @ts-check
  getXDateTimeSinglePicker() {
    return DateTimeSinglePicker
  }

  getBarcodeScanner() {
    return BarcodeScanner
  }

  getXTextEditor() {
    return TextEditor
  }

  getXGalleryModal() {
    return XGalleryModal
  }

  getXWebView() {
    return XWebView
  }
}
