import React from 'react'
import HStack from './HStack'
import XSpinner, { IXSpinnerProps } from './XSpinner'

export interface IXSpinnerOverlay extends IXSpinnerProps {
  visible?: boolean
}

const XSpinnerOverlay: React.FC<IXSpinnerOverlay> = ({ visible = false, ...restProps }) => {
  if (!visible) {
    return null
  }

  return (
    <HStack
      position='absolute'
      top='0'
      left='0'
      bottom='0'
      right='0'
      bg='black:alpha.50'
      alignItems='center'
      justifyContent='center'
      zIndex={999}>
      <XSpinner {...restProps} />
    </HStack>
  )
}

XSpinnerOverlay.displayName = 'XSpinner'

export default XSpinnerOverlay
