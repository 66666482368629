import { connect } from 'react-redux'
import AboutView from './AboutView'
// import { NavigationActions } from 'react-navigation'
// import * as AboutState from './AboutState'
// import { getCodepushInfo, getFCMToken } from '../../redux/selectors'

export default connect(
  (state) => ({
    // codepush: getCodepushInfo(state),
    // fcmToken: getFCMToken(state),
    pp: state.getIn(['bp', 'pagePicker']), // TODO: Remove on production
  }),
  (dispatch) => ({
    // navigate: bindActionCreators(NavigationActions.navigate, dispatch),
    dispatch,
    // aboutStateActions: bindActionCreators(AboutState, dispatch),
  })
)(AboutView)
