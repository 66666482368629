import React from 'react'
import { COLORS, STYLES, S } from 'x/config/styles'
import { log, logRender, setStatePromise, delay } from 'x/utils/util'
// import { Storage } from 'aws-amplify'
import _ from 'lodash'
// import settings from 'x/config/settings'
// import { Map, List, fromJS } from 'immutable'

import BaseImgManager from 'x/components/BaseImgManager'
import { IImgManagerProps, IImgFile } from 'x/index'

// Begin Native code (cannot share)
import {
  TouchableOpacity,
  View,
  Dimensions,
  Image as RNImage,
  ImageBackground,
  StyleSheet,
  TouchableHighlight,
  TextStyle,
} from 'react-native'

import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import ActionSheet from 'xui/components/singleton/ActionSheet'
import Icon from 'xui/components/Icon'

// import { getTimeString, getRandomString } from 'x/utils/file'
// import RNPermissions from 'react-native-permissions'
// import SortableListView from 'react-native-sortable-listview'
// import FontAwesomeIcon from 'react-native-vector-icons/FontAwesome'
// import ImagePicker from 'react-native-image-crop-picker'
// import ImageResizer from 'react-native-image-resizer'
// import RNFS from 'react-native-fs'
// import * as permN from '../utils/permission-native'
// import * as utilN from '../utils/util-native'
import p from 'x/config/platform-specific'
import Dropzone from 'react-dropzone'
import { fileAccepted, isEvtWithFiles } from 'react-dropzone/src/utils/index'
import { CircularProgress } from 'react-native-svg-circular-progress'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import XHorizontalScrollView from 'xui/components/XHorizontalScrollView'
import XText from 'xui/components/XText'
import XIcon from 'xui/components/XIcon'
import FastImage from 'react-native-fast-image'
import XCustomHeader from 'xui/components/XCustomHeader'
import XIconButton from 'xui/components/XIconButton'
import XOverlay from 'xui/components/XOverlay'
import Hoverable from './Hoverable'
import XGalleryModal from './XGalleryModal'

// import FastImage from 'react-native-fast-image'
// import PercentageCircle from 'react-native-percentage-circle'
// import XModal from './XModal'
// End Native code

const SortableItem = SortableElement(({ index, value, isFirst = false, isLast = false }) => (
  <div style={{ float: 'left', marginRight: !isLast ? 10 : 0 }}>
    <ImageBackground
      resizeMode='cover'
      source={{ uri: value }}
      // @ts-ignore
      style={INTL_STYLES.sortableImg}>
      <XText
        variant='primary'
        bold
        position='absolute'
        top='0'
        right='0'
        bg='muted.500'
        p='1'
        // style={{
        //   // fontSize: FONT_SIZE_NORMAL,
        //   // fontWeight: 'bold',
        //   // color: COLORS.APP_MAIN,
        //   position: 'absolute',
        //   top: 0,
        //   right: 0,
        // }}
      >
        {index + 1}
      </XText>
    </ImageBackground>
  </div>
))

// @ts-ignore
const SortableList: React.FC<any> = SortableContainer(({ children }) => (
  // console.log('SortableList children => ', children)
  <ul
    style={{
      marginLeft: 20,
      marginRight: 20,
      padding: 0,
      display: 'flex',
      overflowX: 'auto',
      overflowY: 'hidden',
      alignItems: 'center',
      justifyContent: children.length < 9 ? 'center' : undefined,
    }}>
    {children}
  </ul>
))

const {
  CARD_COMMON,
  FONT_SIZE_LARGER,
  FONT_SIZE_NORMAL,
  FONT_SIZE_SMALLER,
  FONT_ICON_NORMAL,
  FONT_ICON_SMALLER,
  FONT_ICON_SMALLEST,
  FONT_ICON_LARGEST,
  FONT_ICON_LARGER,
  NO_MARGIN_PADDING,
  NO_MARGIN,
  NUMBER_PADDING_RIGHT_PLUS_1,
  COLOR_UNCOMMON,
  COLOR_COMMON,
  COLOR_DONE,
  BTN_BASE,
  BTN_MODE_ACTIVE_PRIMARY,
  BTN_TEXT_MODE_ACTIVE_PRIMARY,
  NUMBER_PADDING_RIGHT,
  FOOTER_BG,
  TXT_NORMAL,
  TXT_LABEL,
  TXT_LABEL_BOLD,
  TXT_NORMAL_BOLD,
  TXT_LABEL_SMALLER,
  TXT_LABEL_LARGER,
} = STYLES

const {
  APP_MAIN,
  APP_SECONDARY,
  TEXT_INACTIVE,
  FORM_ERROR,
  TEXT_ACTIVE,
  TEXT_PRIMARY,
  FORM_INACTIVE,
  FORM_PRIMARY_BG,
  FORM_SUCCESS,
  BG_LIGHT_GREY,
  BG_LIGHT_GREY_ALTERNATIVE,
  TEXT_ACTIVE_DARK,
  BRAND_Danger,
  BRAND_Success,
} = COLORS

// LOCAL CONSTANTS
const IMG_HEIGHT = 96
const IMG_WIDTH = 96
// const IMG_HEIGHT = 120
// const IMG_WIDTH = 140
const IMG_PADDING_RIGHT = 4

const IMG_CONTAINER_VERTICAL_PADDING = 4
const IMG_CONTAINER_HORIZONTAL_PADDING = 4

const IMG_CONTAINER_BORDER_RADIUS = Math.round(IMG_WIDTH * 0.1)
const IMG_CONTAINER_BORDER_CIRCLE_RADIUS = Math.round(IMG_WIDTH * 0.5)
const IMG_CHILD_BORDER_RADIUS = Math.round(IMG_WIDTH * 0.08)
const IMG_CHILD_BORDER_CIRCLE_RADIUS = Math.round(IMG_WIDTH * 0.48)

const INTL_STYLES = {
  imgContainer: {
    // flex: 1,
    flexGrow: 0,
    flexShrink: 0,
    flexDirection: 'row',
    // backgroundColor: 'red',
    height: IMG_HEIGHT + IMG_CONTAINER_VERTICAL_PADDING * 2,
    width: '100%',
    // paddingTop: IMG_CONTAINER_VERTICAL_PADDING,
    // paddingBottom: IMG_CONTAINER_VERTICAL_PADDING,
    // paddingLeft: IMG_CONTAINER_HORIZONTAL_PADDING,
    // paddingRight: IMG_CONTAINER_HORIZONTAL_PADDING,
  },
  imgContainerPadding: {
    paddingTop: IMG_CONTAINER_VERTICAL_PADDING,
    paddingBottom: IMG_CONTAINER_VERTICAL_PADDING,
    paddingLeft: IMG_CONTAINER_HORIZONTAL_PADDING,
    paddingRight: IMG_CONTAINER_HORIZONTAL_PADDING,
  },
  imageButton: {
    width: IMG_WIDTH,
    height: IMG_HEIGHT,
    marginRight: IMG_PADDING_RIGHT,
    borderColor: TEXT_INACTIVE,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: IMG_CONTAINER_BORDER_RADIUS,
    overflow: 'hidden',
  },
  imageButtonHover: {
    borderRadius: IMG_CONTAINER_BORDER_RADIUS - 1,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(15,15,15,0.4)',
    // textAlign: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: 8,
    flex: 0,
  },
  imageButtonHoverContainer: {
    width: FONT_ICON_NORMAL + 8,
    height: FONT_ICON_NORMAL + 8,
    // color: BRAND_Success,
    // fontWeight: 'bold',
    // textAlign: 'center',
    // backgroundColor: BG_LIGHT_GREY,
    // backgroundColor: 'black',
    backgroundColor: 'rgba(0,0,0,0.5)',
    // opacity: 0.5,
    // flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: (FONT_ICON_NORMAL + 8) / 2,
    margin: 4,
  },
  displayImage: {
    flex: 1,
    height: IMG_HEIGHT,
    width: IMG_WIDTH,
    borderRadius: IMG_CHILD_BORDER_RADIUS,
  },
  adderButton: {
    width: IMG_WIDTH,
    height: IMG_HEIGHT,
    marginRight: IMG_PADDING_RIGHT,
    backgroundColor: APP_SECONDARY,
    borderColor: TEXT_INACTIVE,
    borderWidth: 1,
    borderRadius: IMG_CONTAINER_BORDER_RADIUS,
    borderStyle: 'dashed',
    // flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  overlayProgressBlur: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    // color: BRAND_Success,
    // fontWeight: 'bold',
    // textAlign: 'center',
    // backgroundColor: BG_LIGHT_GREY,
    // backgroundColor: 'black',
    backgroundColor: 'rgba(0,0,0,0.5)',
    // opacity: 0.5,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: IMG_CHILD_BORDER_RADIUS,
  },
  overlayProgressText: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    color: BRAND_Success,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  overlayProgressTransparent: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    // opacity: 0.5,
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    borderRadius: IMG_CHILD_BORDER_RADIUS,
  },
  editableIconContainer: {
    position: 'absolute',
    top: Math.floor(IMG_HEIGHT / 2 - (FONT_ICON_NORMAL + 16) / 2),
    left: Math.floor(IMG_WIDTH / 2 - (FONT_ICON_NORMAL + 16) / 2),
    width: FONT_ICON_NORMAL + 16,
    height: FONT_ICON_NORMAL + 16,
    // color: BRAND_Success,
    // fontWeight: 'bold',
    // textAlign: 'center',
    // backgroundColor: BG_LIGHT_GREY,
    // backgroundColor: 'black',
    backgroundColor: 'rgba(0,0,0,0.5)',
    // opacity: 0.5,
    // flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: (FONT_ICON_NORMAL + 16) / 2,
  },
  txtIcon: {
    fontSize: FONT_ICON_SMALLER,
    width: FONT_ICON_SMALLER,
    height: FONT_ICON_SMALLER,
  },
  sortItem: {
    marginVertical: 8,
    flex: 0,
    // width: 168,
    width: 196,
    height: 114,
    padding: 8,
    backgroundColor: 'white',
    borderColor: TEXT_INACTIVE,
    borderRightWidth: 0.5,
    borderBottomWidth: 0.5,
    // alignItems: 'center',
    // justifyContent: 'center',
  },
  btnSort: {
    marginTop: 12,
    marginBottom: 8,
    width: '100%',
    height: 26,
    backgroundColor: BG_LIGHT_GREY,
    borderColor: APP_MAIN,
    borderWidth: 1,
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
  },
  // sortableItemContainer: {
  //   margin: 8,
  //   backgroundColor: BG_LIGHT_GREY,
  // },
  sortableImg: {
    borderColor: TEXT_INACTIVE,
    borderWidth: 1,
    borderRadius: 4,
    height: IMG_HEIGHT,
    width: IMG_WIDTH,
    resizeMode: 'cover',
    cursor: 'move',
  },
  sortableContentContainerStyle: {
    flex: 1,
    backgroundColor: BG_LIGHT_GREY,
    padding: 8,
  },
  separatorMoveSort: {
    height: 1,
    width: 38,
    borderColor: BG_LIGHT_GREY_ALTERNATIVE,
    borderBottomWidth: 1,
  },
  btnMoveSort: {
    marginVertical: 4,
    height: 38,
    width: 38,
    // backgroundColor: BG_LIGHT_GREY,
    // borderColor: APP_MAIN,
    // borderWidth: 1,
    // borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconSort: {
    fontSize: FONT_ICON_LARGER,
    height: FONT_ICON_LARGER,
    width: FONT_ICON_LARGER,
    color: APP_MAIN,
  },
  colNumber: {
    flex: 0,
    width: 20,
    alignContent: 'center',
    justifyContent: 'center',
    marginRight: 8,
  },
  colImage: {
    flex: 0,
    width: 44,
    alignContent: 'center',
    justifyContent: 'center',
  },
}

const ACTION_SHEET_PROPERTIES = {
  ADD_IMAGE: {
    buttons: ['กล้องถ่ายรูป', 'คลังรูปภาพ', 'ยกเลิก'],
    indexes: {
      camera: 0,
      gallery: 1,
      delete: -1,
      inspect: -1,
    },
  },
  EDIT_IMAGE: {
    buttons: ['ดูรูปภาพ', 'เปลี่ยนภาพจากกล้อง', 'เปลี่ยนภาพจากคลัง', 'ลบ', 'ยกเลิก'],
    indexes: {
      inspect: 0,
      camera: 1,
      gallery: 2,
      delete: 3,
    },
  },
  EDIT_SINGLE_IMAGE: {
    buttons: ['ดูรูปภาพ', 'เปลี่ยนภาพจากกล้อง', 'เปลี่ยนภาพจากคลัง', 'ยกเลิก'],
    indexes: {
      inspect: 0,
      camera: 1,
      gallery: 2,
      delete: -1,
    },
  },
  VIEW_IMAGE: {
    buttons: ['ดูรูปภาพ', 'บันทึกรูปภาพ', 'แชร์', 'ยกเลิก'],
    indexes: {
      inspect: 0,
      save: 1,
      share: 2,
      camera: -1,
      gallery: -1,
      delete: -1,
    },
    inspectMultiple: true,
  },
  CROP: {
    TITLE: 'เลือกลักษณะการครอบตัดรูปภาพ...',
    BUTTONS: ['จัตุรัส', 'แนวนอน', 'แนวตั้ง', 'ไม่ครอบตัด', 'ยกเลิก'],
    ACTION_INDEXES: {
      SQUARE: 0,
      HORIZONTAL: 1,
      VERTICAL: 2,
      NOT_CROPPED: 3,
      CANCEL: 4,
    },
  },
  // UPLOADING: {
  //   buttons: ['ดูรูปภาพ', 'ยกเลิก'],
  //   indexes: {
  //     inspect: 0,
  //     delete: -1,
  //     save: -1,
  //     share: -1,
  //     camera: -1,
  //     gallery: -1,
  //   },
  // },
}

// END LOCAL CONSTANTS

// const Fragment = React.Fragment
interface IImageHoverProp {
  tip?: string
  iconName: string
  iconType?:
    | 'AntDesign'
    | 'Entypo'
    | 'EvilIcons'
    | 'Feather'
    | 'FontAwesome'
    | 'FontAwesome5'
    | 'Foundation'
    | 'Ionicons'
    | 'MaterialCommunityIcons'
    | 'MaterialIcons'
    | 'Octicons'
    | 'SimpleLineIcons'
    | 'Zocial'
  iconCustomStyle?: TextStyle
  onClick: (...args) => void
}

export default class ImgManager extends BaseImgManager {
  static displayName = 'ImgManager'

  DEVICE_WIDTH?: number

  DEVICE_HEIGHT?: number

  _onProcessButtonPress?: boolean

  // dropzoneRef?: React.RefObject<DropzoneRef>
  dropzoneRef?: { open: () => void }

  fileInputRefs?: { [key: string]: any }

  IMAGE_HOVER_MODE_BUTTONS: {
    EDIT_IMAGE: IImageHoverProp[]
    EDIT_SINGLE_IMAGE: IImageHoverProp[]
    VIEW_IMAGE: IImageHoverProp[]
    [key: string]: IImageHoverProp[]
  }

  constructor(props: IImgManagerProps) {
    super(props)
    this.fileInputRefs = {}
    const imgLimit = props.maxLength || 50
    for (let i = 0; i < imgLimit; i++) {
      this.fileInputRefs[i] = React.createRef()
    }
    this.IMAGE_HOVER_MODE_BUTTONS = {
      EDIT_IMAGE: [
        {
          tip: 'ดูรูปภาพ',
          iconName: 'eye',
          iconType: 'FontAwesome',
          onClick: this._viewImage,
        },
        {
          tip: 'เปลี่ยนภาพ',
          iconName: 'edit',
          iconType: 'FontAwesome',
          // iconCustomStyle: {
          //   paddingTop: 2,
          //   paddingLeft: 1,
          // },
          onClick: this._changeImage,
        },
        {
          tip: 'ลบ',
          iconName: 'trash',
          iconType: 'FontAwesome',
          // iconCustomStyle: {
          //   paddingLeft: 3,
          // },
          onClick: this._deleteImage,
        },
      ],
      EDIT_SINGLE_IMAGE: [
        {
          tip: 'ดูรูปภาพ',
          iconName: 'eye',
          iconType: 'FontAwesome',
          onClick: this._viewImage,
        },
        {
          tip: 'เปลี่ยนภาพ',
          iconName: 'edit',
          iconType: 'FontAwesome',
          // iconCustomStyle: {
          //   paddingTop: 2,
          //   paddingLeft: 1,
          // },
          onClick: this._changeImage,
        },
      ],
      VIEW_IMAGE: [
        {
          tip: 'ดูรูปภาพ',
          iconName: 'eye',
          iconType: 'FontAwesome',
          onClick: this._viewImage,
        },
        {
          tip: 'บันทึกรูปภาพ',
          iconName: 'cloud-download',
          iconType: 'FontAwesome',
          onClick: this._saveImage,
        },
        // {
        //   tip: 'แชร์',
        //   iconName: 'cloud-download',
        //   iconType: 'FontAwesome',
        //   onClick: this._shareImage,
        // },
      ],
    }
  }

  // --------------------------------------
  // -- Begin Native Logic (cannot Share --
  // --------------------------------------
  // _onPressPickImage() {
  //   ImagePicker.openPicker({
  //     multiple: true,
  //     // width: this.state.uploadWidth,
  //     // height: this.state.uploadHeight,
  //     compressImageQuality: 1,
  //     includeBase64: true,
  //     // cropping: !allowFullSizeUpload,
  //     mediaType: 'photo',
  //     // maxFiles: maxSelection,
  //   }).then(selectedImages => {
  //     // be array in multiple pick
  //     // be object in one pick
  //     log('_imageChoosePressed: ', selectedImages)
  //     // log('_imageChoosePressed stringify: ', JSON.stringify(selectedImages))
  //     log('###### _AddNewImages ########')
  //     this.addNewImages(selectedImages).then(() => {
  //       log('###### _AddNewImages ######## then')
  //     }).catch(() => {
  //       log('###### _AddNewImages ######## catch')
  //     })
  //
  //   }).catch(error => {
  //     log('Error from ImagePicker _imageChoosePressed: ', error)
  //     log('Error from ImagePicker _imageChoosePressed stringify ', JSON.stringify(error))
  //
  //     // if (error.code) {
  //     //   const { code } = error
  //     //   if ((CONS.IS_IOS && code === 'E_PERMISSION_MISSING') || (!CONS.IS_IOS && error.code === 'E_NO_IMAGE_DATA_FOUND')) {
  //     //     permN.alertStoragePermission()
  //     //   }
  //     // }
  //     // E_PICKER_CANCELLED
  //     // E_FAILED_TO_OPEN_CAMERA
  //     // Error: User cancelled image selection = ปิด perm กล้อง
  //     // Invalid image selected = ไม่สามารถเข้าถึงไฟล์หรือแฟ้มได้
  //   })
  // }

  async _getResizedImage(image: IImgFile, newWidth: number, newHeight: number): Promise<IImgFile | null> {
    const imageData = image && !!image.data && image.mime ? image.data : null
    // console.log('_getResizedImage imageData => ', imageData)

    if (!imageData) {
      // console.log('_getResizedImage has empty image')
      return null
    }
    const base64withMime = this.rawBase64ToDataUri(imageData, image.mime)
    // const resizedImageData = await this._getResizeBase64Image(imageData, newWidth, newHeight)
    const resizedImageData = await this._getResizeBase64Image(base64withMime, newWidth, newHeight)
    // console.log('_getResizedImage resizedImageData => ', resizedImageData)

    if (!resizedImageData) {
      return null
    }

    return {
      data: resizedImageData,
      mime: image.mime, // Must have
      width: newWidth,
      height: newHeight,
    }
  }

  async getImageSizeFromUrl(imgUrl: string): Promise<{ width?: number; height?: number }> {
    return new Promise<{ width?: number; height?: number }>((resolveSize) => {
      RNImage.getSize(
        imgUrl,
        (width, height) => {
          resolveSize({ width, height })
        },
        () => {
          resolveSize({ width: undefined, height: undefined })
        }
      )
    })
  }

  // async _getResizedImage(image: IImgFile, maxWidth: number, maxHeight: number) {
  //   // let imageURI = Platform.OS === 'ios' ? image.path : image.sourceURL
  //   return new Promise<IImgFile>((resolve, reject) => {
  //     let imagePATH = image.path || null
  //     if (!imagePATH) {
  //       reject(new Error('No path of image file.'))
  //     }

  //     ImageResizer.createResizedImage(imagePATH, maxWidth, maxHeight, 'JPEG', 85)
  //       .then((resizedImage: IImgFile|any) => {
  //         let readImage: IImgFile = _.cloneDeep(resizedImage)
  //         RNFS.readFile(readImage.path, 'base64').then(base64data => {
  //           readImage.data = base64data
  //           readImage.mime = image.mime
  //           resolve(readImage)
  //         })
  //       })
  //       .catch((err) => {
  //         reject(err)
  //       })

  //     setTimeout(() => reject(new Error('Resizing image timeout.')), 10000)
  //   })
  // }

  convertImgUrltoBase64 = (url, callback) => {
    // O: Quick Fix แก้ปัญหากดดาวน์โหลดรูปภาพผ่านเว็บแล้วติด CORS
    // https://app.clickup.com/t/8btw44
    const quickFixUrlReplaceList = [
      { target: 's1.xselly.com', endpoint: 'xsf.sgp1.digitaloceanspaces.com' },
      { target: 's2.xselly.com', endpoint: 'xs2.sfo2.digitaloceanspaces.com' },
      { target: 's3.xselly.com', endpoint: 'xs3.ams3.digitaloceanspaces.com' },
      { target: '.cdn', endpoint: '' },
    ]
    let imgUrl = url
    for (const qrp of quickFixUrlReplaceList) {
      imgUrl = imgUrl.replace(qrp.target, qrp.endpoint)
    }
    // console.log('convertImgUrltoBase64 imgUrl => ', imgUrl)
    const xhr = new XMLHttpRequest()

    xhr.onreadystatechange = function () {
      /* .. */
    }
    xhr.withCredentials = false
    xhr.onload = function () {
      const reader = new FileReader()
      reader.onloadend = function () {
        callback(reader.result)
      }
      reader.readAsDataURL(xhr.response)
    }
    // xhr.open('GET', url)
    xhr.open('GET', imgUrl)
    xhr.responseType = 'blob'
    xhr.send()
  }

  getImgBase64FromImageUrl = (url) =>
    new Promise((resolve) => {
      this.convertImgUrltoBase64(url, resolve)
    })

  async _getBase64fromImageURL(imgURL: string): Promise<string> {
    if (!imgURL) {
      p.op.alert('เกิดข้อผิดพลาด', 'ไม่สามารถอ่านไฟล์ภาพนี้ได้')
      throw new Error('No image URL to compute.')
    }

    // const base64image = await this._getResizeBase64Image(imgURL)
    const base64image = await this.getImgBase64FromImageUrl(imgURL)
    // console.log('base64image => ', base64image)
    if (!base64image) {
      p.op.alert('เกิดข้อผิดพลาด', 'ไม่สามารถอ่านไฟล์ภาพนี้ได้')
      throw new Error('Cannot convert this file to base64 image.')
    }

    log('computedImage => ', base64image)
    // @ts-ignore
    return base64image
  }

  _constructorExtended(that: any, props: IImgManagerProps): void {
    that.INTL_STYLES = _.cloneDeep(INTL_STYLES)
    const { width, height } = Dimensions.get('window')
    that.DEVICE_WIDTH = width
    that.DEVICE_HEIGHT = height

    if (props.shape === 'circle') {
      that.INTL_STYLES.imageButton.borderRadius = IMG_CONTAINER_BORDER_CIRCLE_RADIUS
      that.INTL_STYLES.adderButton.borderRadius = IMG_CONTAINER_BORDER_CIRCLE_RADIUS
      that.INTL_STYLES.displayImage.borderRadius = IMG_CHILD_BORDER_CIRCLE_RADIUS
      that.INTL_STYLES.overlayProgressBlur.borderRadius = IMG_CHILD_BORDER_CIRCLE_RADIUS
      that.INTL_STYLES.overlayProgressTransparent.borderRadius = IMG_CHILD_BORDER_CIRCLE_RADIUS
    }

    // that._handleInternalInspectImage = that._handleInternalInspectImage.bind(this)
    that._onProcessButtonPress = false
  }

  // Ref => https://miguelmota.com/bytes/base64-mime-regex/
  getbase64MimeType = (encoded: string) => {
    const dataType = encoded.split(';')[0]
    return dataType && dataType.split(':')[1] ? dataType.split(':')[1] : null
    // var result = null
    //
    // if (typeof encoded !== 'string') {
    //   return result
    // }
    //
    // var txtMime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/)
    //
    // if (txtMime && txtMime.length) {
    //   result = mime[1]
    // }
    //
    // return result
  }

  rawBase64ToDataUri = (base64: string, mime: string): string => `data:${mime || 'image/png'};base64,${base64}`

  // Ref => https://stackoverflow.com/questions/20958078/resize-a-base-64-image-in-javascript-without-using-canvas
  // Takes a data URI and returns the Data URI corresponding to the resized image at the wanted size.
  _getResizeBase64Image = async (base64data: string, maxWidth?: number, maxHeight?: number): Promise<string | null> =>
    // We create an image to receive the Data URI
    new Promise<string | null>((resolve) => {
      const img = document.createElement('img')
      img.crossOrigin = 'anonymous'

      // When the event "onload" is triggered we can resize the image.
      img.onload = () => {
        // We create a canvas and get its context.
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        canvas.width = img.width // ทำให้ canvas size เท่ากับ ori image ก่อน
        canvas.height = img.height

        const oldWidth = canvas.width // เริ่มคำนวณหา output width with ratio
        const oldHeight = canvas.height

        let preferWidth = oldWidth
        let preferHeight = oldHeight
        // log('_getResizeBase64Image oldWidth => ', oldWidth)
        // log('_getResizeBase64Image oldHeight => ', oldHeight)

        // We set the dimensions at the wanted size.
        if (maxWidth && maxHeight) {
          if (oldWidth > maxWidth) {
            // ยึด width เป็นหลัก ถ้า width เกิน ให้คำนวณหา height
            const newHeight = (oldHeight / oldWidth) * maxWidth

            canvas.width = maxWidth
            // canvas.height = maxHeight
            canvas.height = newHeight
            preferWidth = maxWidth
            // preferHeight = maxHeight
            preferHeight = newHeight
          } else if (oldHeight > maxHeight) {
            // worse case ถ้า height เกิน
            const newWidth = (oldWidth / oldHeight) * maxWidth
            canvas.width = newWidth
            canvas.height = oldHeight
            preferWidth = newWidth
            preferHeight = oldHeight
          }
        }

        // We resize the image with the canvas method drawImage()
        ctx.drawImage(img, 0, 0, preferWidth, preferHeight)

        // const dataURI = canvas.toDataURL()
        const dataURI = canvas.toDataURL('image/jpeg')
        // log('_getResizeBase64Image canvas dataURI => ', dataURI)
        resolve(dataURI)
        /// //////////////////////////////////////
        // Use and treat your Data URI here !! //
        /// //////////////////////////////////////
      }

      img.onerror = (error) => {
        console.log('_getResizeBase64Image Error => ', error)
        resolve(null)
      }

      // We put the Data URI in the image's src attribute
      img.src = base64data
    })
  // Use it like that : resizedataURL('yourDataURIHere', 50, 50);

  getImageBase64FromFiles = async (file: File): Promise<string | null> => {
    const reader = new FileReader()
    return new Promise<string | null>((resolve: any) => {
      reader.readAsDataURL(file)
      // @ts-ignore
      reader.onloadend = (rd, evt) => {
        log('getImageBase64FromFiles rd => ', rd)
        log('getImageBase64FromFiles evt => ', evt)
        resolve(reader.result)
      }

      reader.onerror = (error) => {
        log('getImageBase64FromFiles error => ', error)
        resolve(null)
      }

      setTimeout(resolve, 12000) // Don't convert more than 12 sec
    })
  }

  isUsableImageFile = async (file: File): Promise<boolean> => {
    const base64Image = await this.getImageBase64FromFiles(file)
    if (!base64Image) {
      return false
    }
    return this.isUsableBase64Image(base64Image)
  }

  isUsableBase64Image = async (base64Image: string): Promise<boolean> => !!(await this._getResizeBase64Image(base64Image, 12, 12))

  // @ts-ignore
  onDropImages = async (acceptedFiles: File[], rejectFiles: File[], evt): Promise<void> => {
    const { onPressChooseSuccess, onPressChooseFailed, maxLength = 50 } = this.props
    // console.log('onDropImages evt.target', evt.target)
    // const idx = this.state.originalImages.size
    try {
      const imgQuotaLeft = maxLength - this.state.originalImages.size
      if (imgQuotaLeft < 0) {
        p.op.alert(`รูปภาพเต็มโควต้า ${maxLength} รูป แล้ว`)
        throw new Error('ImgManager:: image exceeded')
      }

      // await setStatePromise(this, { dropzoneActive: false })
      // console.log('onDropImages acceptedFiles => ', acceptedFiles)
      // console.log('onDropImages rejectFiles => ', rejectFiles)
      // log('onDropImages idx => ', idx)
      if (!_.isArray(acceptedFiles) || !_.isArray(rejectFiles)) {
        // return // should be impossible case but safety first
        throw new Error('ImgManager:: no images (should beimpossible case)')
      }

      if (_.isArray(rejectFiles) && rejectFiles.length > 0) {
        const rejectedFileName = rejectFiles[0].name ? rejectFiles[0].name : null
        if (rejectedFileName) {
          p.op.alert(`ไม่สามารถอ่านไฟล์ ${rejectedFileName}`, 'กรุณาเลือกรูปภาพที่มีนามสกุลเป็น jpeg, jpg, bmp หรือ png เท่านั้น')
        } else {
          p.op.alert(`ไม่สามารถอ่านไฟล์ได้`, 'กรุณาเลือกรูปภาพที่มีนามสกุลเป็น jpeg, jpg, bmp หรือ png เท่านั้น')
        }
        // return
        throw new Error('ImgManager:: cannot convert file')
      }

      // for (let i = 0; i < imgQuotaLeft && i < acceptedFiles.length; i++) {
      //   const focusedFile = acceptedFiles[i]
      //   const readableImage = await this.isUsableImageFile(focusedFile)
      //   if (!readableImage) {
      //     const focusedFileName = focusedFile && focusedFile.name ? focusedFile.name : null
      //     if (focusedFileName) {
      //       p.op.alert(`ไม่สามารถอ่านไฟล์ ${focusedFileName}`, 'กรุณาเลือกรูปภาพที่มีนามสกุลเป็น jpeg, jpg, bmp หรือ png เท่านั้น')
      //     } else {
      //       p.op.alert(`ไม่สามารถอ่านไฟล์ได้`, 'กรุณาเลือกรูปภาพที่มีนามสกุลเป็น jpeg, jpg, bmp หรือ png เท่านั้น')
      //     }
      //     return
      //   }
      // }
      // Add new image to OriImages

      const imgFiles: IImgFile[] = []
      // for (let i = 0; i < acceptedFiles.length; i++) {
      for (let i = 0; i < imgQuotaLeft && i < acceptedFiles.length; i++) {
        const focusedFile = acceptedFiles[i]
        const base64 = await this.getImageBase64FromFiles(focusedFile)
        if (_.isString(base64) && base64.startsWith('data:image')) {
          const mime = this.getbase64MimeType(base64)
          log('onDropImages base64 => ', base64)
          log('onDropImages mime => ', mime)
          imgFiles.push({ data: base64.split(',')[1], mime, path: null })
        }
      }
      //  await Promise.all(acceptedFiles.map(async (aFile: File): Promise<IImgFile> => {
      //   const base64 = await this.getImageBase64FromFiles(aFile)
      //   const mime = this.getbase64MimeType(base64)
      //   return {
      //     data: base64,
      //     mime,
      //   }
      // })

      await this.addNewImages(imgFiles)
    } catch (err) {
      if (_.isFunction(onPressChooseFailed)) {
        // console.log('call onPressChooseSuccess')
        onPressChooseFailed()
      }
    }

    if (_.isFunction(onPressChooseSuccess)) {
      // console.log('call onPressChooseSuccess')
      onPressChooseSuccess()
    }
  }

  // --------------------------------------
  // -- End Native Logic
  // --------------------------------------

  // --------------------------------------
  // --         Begin Native Render      --
  // --------------------------------------
  _setDropzoneRef = (r) => r && (this.dropzoneRef = r)

  _renderImages = (): JSX.Element => {
    // const { originalImages } = this.state
    // return originalImages.map((oriImage, idx) => this._renderSingleImage(idx, oriImage))
    const { maxLength } = this.props
    const renderImgUris = this._getImgRenderImageUris()
    const imgQuotaLeft = maxLength - renderImgUris.length
    const canAddMore = imgQuotaLeft > 0
    // console.log('_renderImages renderImgUris => ', renderImgUris)
    if (maxLength === 1) {
      return (
        <Dropzone
          key='SingleImageDropzone'
          ref={this._setDropzoneRef}
          noClick
          accept='image/jpeg, image/jpg, image/png, image/bmp'
          noDrag={!canAddMore}
          // @ts-ignore
          onDrop={this.onDropImages}>
          {({ getRootProps, getInputProps, isDragActive }) => (
            <div {...getRootProps()} style={{ backgroundColor: isDragActive ? 'red' : 'white' }}>
              <input {...getInputProps({ disabled: false })} />
              <HStack w='full' flex={1} alignItems='center' justifyContent='center'>
                {/* {canAddMore ? this._renderAdderImage() : this._renderSingleImage(0, renderImgUris[0])} */}
                {canAddMore ? this._renderAdderImage(true) : this._renderSingleImage({ index: 0, item: renderImgUris[0] })}
              </HStack>
            </div>
          )}
        </Dropzone>
      )
    }

    return (
      <Dropzone
        key='MultipleImageDropzone'
        // ref={this.dropzoneRef}
        // style={ dropzoneActive ? INTL_STYLES.adderButtonActive : INTL_STYLES.adderButton }
        // onMouseEnter={ this._dropzoneOn }
        // onMouseLeave={ this._dropzoneOff }
        // onDragEnter={ this._dropzoneOn }
        // onDragLeave={ this._dropzoneOff }
        // disabled={false}
        // maxSize={imgQuotaLeft}
        onDragEnter={() => console.log('onDragEnter')}
        onDragLeave={() => console.log('onDragLeave')}
        onDragOver={() => console.log('onDragOver')}
        ref={this._setDropzoneRef}
        noClick
        accept='image/jpeg, image/jpg, image/png, image/bmp'
        noDrag={!canAddMore}
        // @ts-ignore
        onDrop={this.onDropImages}>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div
            // @ts-ignore
            style={INTL_STYLES.imgContainer}
            {...getRootProps()}>
            {/* https://github.com/react-dropzone/react-dropzone/issues/859 */}
            <input {...getInputProps({ disabled: false })} />
            {/* <FlatList<string>
              data={renderImgUris}
              renderItem={this._renderSingleImage}
              horizontal
              scrollEnabled={false}
              keyExtractor={this._keyExtractor}
              // @ts-ignore
              contentContainerStyle={StyleSheet.flatten([INTL_STYLES.imgContainer, INTL_STYLES.imgContainerPadding])}
              ListFooterComponent={this._renderAdderImage(canAddMore)}
            /> */}
            <HStack w='full'>
              {renderImgUris.map((iURIs, idx) => this._renderSingleImage({ index: idx, item: iURIs }))}
              {this._renderAdderImage(canAddMore)}
            </HStack>
            {/* <XText>{imgQuotaLeft}</XText> */}
            {isDragActive ? (
              <View
                // @ts-ignore
                style={[
                  // @ts-ignore
                  INTL_STYLES.imgContainer,
                  {
                    // flex: 1,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: COLORS.BG_LIGHT_GREY,
                    padding: 8,
                  },
                ]}>
                <View
                  style={{
                    flex: 1,
                    height: '100%',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderWidth: 1,
                    borderColor: COLORS.APP_MAIN,
                    borderRadius: IMG_CHILD_BORDER_RADIUS,
                    borderStyle: 'dashed',
                  }}>
                  <XText>วางรูปภาพลงที่นี่</XText>
                </View>
              </View>
            ) : null}
          </div>
        )}
      </Dropzone>
    )
  }

  _keyExtractor = (item, index) => index.toString()

  // _renderSingleImage = (idx, imgUri): JSX.Element => {
  _renderSingleImage = ({ item, index }): JSX.Element => {
    const imgUri = item // for compatibility with flatlist
    const idx = index // for compatibility with flatlist
    // const uploading = this.props.uploading
    const { uploading, imageStyle = {}, imageButtonStyle = {}, readonly } = this.props
    const progress = this._getImgXProgress(idx)
    // if (uploading) {
    // progress = this._getImgXProgress(idx)
    // }

    // let ImgComponent
    const imgProps = {
      style: [this.INTL_STYLES.displayImage, imageStyle],
      source: { uri: imgUri },
    }

    // if (imgUri && ~imgUri.indexOf('file:///')) {
    //   ImgComponent = () => <RNImage resizeMode='cover' {...imgProps} />
    // } else if (imgUri) {
    //   ImgComponent = () => <RNImage resizeMode='cover' {...imgProps} />
    // } else {
    //   ImgComponent = null
    // }

    let injectedEditableIconContainer = {}
    if (imageStyle.width && imageStyle.height && !readonly && !uploading) {
      injectedEditableIconContainer = {
        top: Math.floor(imageStyle.height / 2 - (FONT_ICON_NORMAL + 16) / 2),
        left: Math.floor(imageStyle.width / 2 - (FONT_ICON_NORMAL + 16) / 2),
      }
    }

    return (
      <Hoverable key={`Image_${idx}`}>
        {(isHover) => (
          <View
            style={[this.INTL_STYLES.imageButton, imageButtonStyle]}
            // onPress={() => this._showActionSheet(idx, false)}
          >
            <FastImage resizeMode='cover' {...imgProps} />
            {/* {ImgComponent ? (
              <ImgComponent />
            ) : (
              <View
                key={idx}
                style={[
                  this.INTL_STYLES.displayImage,
                  {
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                  },
                ]}>
                <XText variant='inactive' bold>
                  ไม่มีรูปภาพ
                </XText>
              </View>
            )} */}
            <input
              // ref={r => this._onFileInputRef(r, idx)}
              ref={this.fileInputRefs[idx]}
              id={`file_${idx}`}
              type='file'
              style={{ display: 'none' }}
              multiple={false}
              onChange={(evt) => this._onChangeFile(idx, evt)}
            />

            {uploading && _.isNumber(progress) && progress < 100 ? (
              <View style={this.INTL_STYLES.overlayProgressBlur}>
                <CircularProgress
                  // blankColor={'#eaeaea'}
                  blankColor={BG_LIGHT_GREY_ALTERNATIVE}
                  donutColor={APP_MAIN}
                  fillColor={BG_LIGHT_GREY}
                  progressWidth={Math.round(IMG_WIDTH * 0.3)}
                  size={Math.round(IMG_WIDTH * 0.8)}
                  percentage={progress}>
                  <View>
                    <XText variant='inactive' fontSize='xs' bold>
                      {`${progress}%`}
                    </XText>
                  </View>
                </CircularProgress>
              </View>
            ) : null}

            {/* {// editable
              !uploading && !readonly ? (
                <View style={[INTL_STYLES.editableIconContainer, injectedEditableIconContainer]}>
                  <Icon type='FontAwesome' name='edit' style={[INTL_STYLES.txtIcon, { color: APP_MAIN }]} />
                </View>
              ) : null} */}

            {this.props.showUploaded && _.isNumber(progress) && progress >= 100 ? (
              <View style={this.INTL_STYLES.overlayProgressTransparent}>
                <Icon name='checkmark-circle' style={[INTL_STYLES.txtIcon, { color: BRAND_Success }]} />
              </View>
            ) : null}

            {isHover ? this._renderImageHoverMenu(idx) : null}
          </View>
        )}
      </Hoverable>
    )
  }

  // _onFileInputRef = (r, idx) => {
  //   if (r) {
  //     this.fileInputRefs[idx] = r
  //   }
  // }

  _onChangeFile = async (idx, evt) => {
    console.log('_onChangeFile idx => ', idx)
    const { onPressChooseSuccess, onPressChooseFailed, maxLength = 50 } = this.props

    try {
      console.log('_onChangeFile idx => ', idx)
      // console.log('_onChangeFile evt => ', evt)
      // console.log('_onChangeFile evt.target.files[0] => ', evt.target.files[0])
      evt.preventDefault()
      if (_.isNumber(idx) && isEvtWithFiles(evt)) {
        const file = evt.target.files[0]
        const acceptedFiles = []
        const rejectedFiles = []
        // if (fileAccepted(file, accept) && fileMatchSize(file, maxSize, minSize)) {
        if (fileAccepted(file, 'image/jpeg, image/jpg, image/png, image/bmp')) {
          acceptedFiles.push(file)
        } else {
          rejectedFiles.push(file)
        }
        const imgQuotaLeft = maxLength - this.state.originalImages.size
        if (imgQuotaLeft < 0) {
          p.op.alert(`รูปภาพเต็มโควต้า ${maxLength} รูป แล้ว`)
          // return
          throw new Error('ImgManager:: exceeded file')
        }

        if (!_.isArray(acceptedFiles) || !_.isArray(rejectedFiles)) {
          // return // should be impossible case but safety first
          throw new Error('ImgManager::No changed file (impossible case)')
        }

        if (_.isArray(rejectedFiles) && rejectedFiles.length > 0) {
          const rejectedFileName = rejectedFiles[0].name ? rejectedFiles[0].name : null
          if (rejectedFileName) {
            p.op.alert(`ไม่สามารถอ่านไฟล์ ${rejectedFileName}`, 'กรุณาเลือกรูปภาพที่มีนามสกุลเป็น jpeg, jpg, bmp หรือ png เท่านั้น')
          } else {
            p.op.alert(`ไม่สามารถอ่านไฟล์ได้`, 'กรุณาเลือกรูปภาพที่มีนามสกุลเป็น jpeg, jpg, bmp หรือ png เท่านั้น')
          }
          // return
          throw new Error('ImgManager:: Cannot convert image')
        }

        // console.log('_onChangeFile acceptedFiles => ', evt)
        // console.log('_onChangeFile rejectedFiles => ', evt)
        if (acceptedFiles.length > 0) {
          // const imgFiles: IImgFile[] = []
          // for (let i = 0; i < acceptedFiles.length; i++) {
          const focusedFile = acceptedFiles[0]
          const base64 = await this.getImageBase64FromFiles(focusedFile)
          if (_.isString(base64) && base64.startsWith('data:image')) {
            const mime = this.getbase64MimeType(base64)
            log('_onChangeFile base64 => ', base64)
            log('_onChangeFile mime => ', mime)
            // imgFiles.push({ data: base64.split(',')[1], mime, path: null })
            this.replaceNewImageAtIndex(idx, { data: base64.split(',')[1], mime, path: null })
          }
        }
      }
    } catch (err) {
      if (_.isFunction(onPressChooseFailed)) {
        console.log('call onPressChooseSuccess')
        onPressChooseFailed()
      }
    }

    if (_.isFunction(onPressChooseSuccess)) {
      console.log('call onPressChooseSuccess')
      onPressChooseSuccess()
    }
  }

  _changeImage = (idx) => {
    try {
      if (this.props.maxLength === 1) {
        console.log('_changeImage single image idx => ', idx)
        console.log('_changeImage single image this.fileInputRefs => ', this.fileInputRefs)
        // @ts-ignore
        // this._dropzoneOpen()
        this.fileInputRefs[0].current.click()
      } else {
        console.log('_changeImage idx => ', idx)
        console.log('_changeImage this.fileInputRefs => ', this.fileInputRefs)
        this.fileInputRefs[idx].current.click()
      }
    } catch (err) {
      console.log('ImgManager::_changeImage:: Error: ', err)
    }
  }

  _renderImageHoverMenu = (imgIndex: number) => {
    const { readonly, uploading, disabledRemoveSingleImage, maxLength } = this.props
    let buttonProps: IImageHoverProp[] = this.IMAGE_HOVER_MODE_BUTTONS.VIEW_IMAGE
    if (uploading) {
      // do nothing
      // } else if (!readonly && this.state.xImages.size === 1 && disabledRemoveSingleImage) {
    } else if (!readonly && maxLength === 1 && disabledRemoveSingleImage) {
      buttonProps = this.IMAGE_HOVER_MODE_BUTTONS.EDIT_SINGLE_IMAGE
    } else if (!readonly) {
      buttonProps = this.IMAGE_HOVER_MODE_BUTTONS.EDIT_IMAGE
    }
    return (
      <View
        key={`HoverMenu_${imgIndex}`}
        // @ts-ignore
        style={INTL_STYLES.imageButtonHover}>
        {buttonProps.map((prop, i) => (
          // <TouchableOpacity
          //   key={`hover_${imgIndex}${i}`}
          //   // @ts-ignore
          //   style={INTL_STYLES.imageButtonHoverContainer}
          //   onPress={() => prop.onClick(imgIndex)}>
          <XIconButton
            key={`HoverButton_${imgIndex}_${i.toString()}`}
            onPress={() => prop.onClick(imgIndex)}
            variant='outline'
            rounded='full'
            m='2px'
            family={prop.iconType}
            name={prop.iconName}
            style={prop.iconCustomStyle ? prop.iconCustomStyle : {}}
            // style={[INTL_STYLES.txtIcon, { color: APP_MAIN }, prop.iconCustomStyle ? prop.iconCustomStyle : {}]}
          />
          // </TouchableOpacity>
        ))}
      </View>
    )
  }

  // _dropzoneOn = () => this.setState({ dropzoneActive: true })
  // _dropzoneOff = () => this.setState({ dropzoneActive: false })
  _dropzoneOpen = () => {
    if (_.isFunction(this.dropzoneRef.open)) {
      this.dropzoneRef.open()
    }
  }

  _renderAdderImage = (canAddMore?: boolean): JSX.Element => {
    const { uploading, readonly } = this.props
    const idx = this.state.xImages.size
    return uploading || readonly || !canAddMore ? null : (
      <TouchableOpacity
        key={idx}
        // onPress={() => this._showActionSheet(idx, true)}
        onPress={this._dropzoneOpen}
        style={this.INTL_STYLES.adderButton}>
        <XIcon
          inactive
          family='FontAwesome'
          name='plus'
          // style={{
          //   paddingLeft: 2,
          //   fontSize: FONT_ICON_NORMAL,
          //   color: TEXT_INACTIVE,
          //   backgroundColor: 'transparent',
          // }}
        />
        <XText variant='inactive'>เพิ่มรูป</XText>
      </TouchableOpacity>
    )
  }

  _renderInternalInspectModal = () => {
    // console.log('NoImpl._renderInternalInspectModal')
    // return null
    const { previewImages = [], isVisiblePreview = false, previewInitialIndex = 0 } = this.state
    const isVisible = isVisiblePreview && _.isArray(previewImages) && previewImages.length > 0
    if (!isVisible) {
      return null
    }
    return (
      <XGalleryModal
        isVisible={isVisible}
        initialIndex={previewInitialIndex}
        onRequestClose={() => {
          this.setState({ isVisiblePreview: false, previewImages: [] })
        }}
        images={previewImages || []}
      />
    )
  }

  // _onRowMoved(params: { from: number; to: number }): void {
  //   const { from, to } = params
  //   // const { editingProductGroups } = this.state
  //   // const newEditingProductGroups = _.cloneDeep(editingProductGroups)
  //   // const pgCount = newEditingProductGroups.length
  //   // const newPriority = pgCount - to
  //   console.log('_onRowMoved from => ', from, ' to => ', to)
  //   // console.log('_onRowMoved newEditingProductGroups[from] => ', newEditingProductGroups[from])
  //   // console.log('_onRowMoved newPriority => ', newPriority)

  //   // newEditingProductGroups.splice(to, 0, newEditingProductGroups.splice(from, 1)[0])
  //   // newEditingProductGroups.forEach((pg, idx) => {
  //   //   // newEditingProductGroups[idx]['newPriority'] = pgCount - idx
  //   //   newEditingProductGroups[idx].priority = pgCount - idx
  //   // })
  //   // this.setState({ editingProductGroups: newEditingProductGroups, isSorted: true })
  //   // this.forceUpdate()
  // }

  _openSortModal = () => this.setState({ isVisibleSortModal: true })

  _closeSortModal = () => this.setState({ isVisibleSortModal: false })

  onSortEnd = ({ oldIndex, newIndex }) => {
    // this.setState(({items}) => ({
    //   items: arrayMove(items, oldIndex, newIndex),
    // }))
    this.rearrangeImages({ from: oldIndex, to: newIndex })
  }

  _renderSortModal = () => {
    // return null
    // const sortable = true

    // if (!sortable) {
    //   return null
    // }
    const { isVisibleSortModal } = this.state
    if (!isVisibleSortModal) {
      return null
    }
    const renderImgUris = this._getImgRenderImageUris()
    return (
      <XOverlay
        // title={'จัดเรียงรูปภาพใหม่'}
        visible={isVisibleSortModal}
        onRequestClose={this._closeSortModal}
        contentStyle={{ minWidth: 400 }}
        // webNumColumns={2}
        // webWrapperContainerStyle={{ height: 220 }}
        // hasCloseButton={true}
        // safeHeaderMargin={true}
      >
        {/* <XCard w='full'> */}
        <VStack w='full'>
          <XCustomHeader title='จัดเรียงรูปภาพ' headerRightProps={{ closeIcon: true, onPressItem: this._closeSortModal }} />

          {isVisibleSortModal ? (
            <SortableList onSortEnd={this.onSortEnd} useDragHandle={false} lockAxis='x' axis='x'>
              {renderImgUris.map((value, index) => {
                const isFirst = index === 0
                const isLast = index === this.state.xImages.size - 1
                return <SortableItem key={`item-${index.toString()}`} index={index} isFirst={isFirst} isLast={isLast} value={value} />
              })}
            </SortableList>
          ) : null}
          {/* <View style={{ flex: 0, minHeight: 20, paddingHorizontal: 4, paddingVertical: 8 }}> */}
          <XText variant='inactive' textAlign='center'>
            แตะค้างแล้วเลื่อนซ้ายหรือขวาเพื่อจัดลำดับรูปภาพใหม่ "ซ้ายสุดคือลำดับแรก"
          </XText>
          {/* </View> */}
          {/* {isVisibleSortModal ? (
            <SortableListView
              key={xImages.size}
              style={{ flex: 1 }}
              contentContainerStyle={{ alignItems: 'center' }}
              sortRowStyle={{ alignItems: 'center' }}
              // sortRowStyle={INTL_STYLES.sortItem}
              data={renderImgUris}
              onMoveStart={this._onStartMoveSortItem}
              onMoveEnd={this._onStopMoveSortItem}
              limitScrolling={this.state.isMovingSortItem}
              // disableSorting={this.state.submitting}
              activeOpacity={0.8}
              // onRowMoved={this._onRowMoved}
              onRowMoved={this.rearrangeImages}
              renderRow={this._renderSortableItem}
            />
          ) : null} */}
        </VStack>
        {/* </XCard> */}
      </XOverlay>
    )
  }

  _onStartMoveSortItem = () => this.setState({ isMovingSortItem: true })

  _onStopMoveSortItem = () => this.setState({ isMovingSortItem: false })

  _renderSortableItem = (data, section, index, highlightfn, active) => {
    const idx = parseInt(index)
    const isFirst = idx === 0
    const isLast = idx === this.state.xImages.size - 1
    return (
      <SortableImageItem
        index={idx}
        data={data}
        // section={section}
        // highlightfn={highlightfn}
        active={active}
        isFirst={isFirst}
        isLast={isLast}
        handleMoveUp={this._handleSortMoveUp}
        handleMoveDown={this._handleSortMoveDown}
      />
    )
  }

  _handleSortMoveUp = (index) => {
    this.rearrangeImages({ from: index, to: index - 1 })
  }

  _handleSortMoveDown = (index) => {
    this.rearrangeImages({ from: index, to: index + 1 })
  }

  _renderSortButton = () => {
    const { uploading, readonly } = this.props
    if (uploading || readonly || this.state.xImages.size < 2) {
      return null
    }
    return (
      <TouchableOpacity
        // @ts-ignore
        style={INTL_STYLES.btnSort}
        onPress={this._openSortModal}>
        <HStack w='full' space='1' alignItems='center' justifyContent='center'>
          <XIcon
            family='MaterialCommunityIcons'
            name='swap-horizontal'
            // style={{
            //   fontSize: FONT_ICON_NORMAL,
            //   width: FONT_ICON_NORMAL,
            //   height: FONT_ICON_NORMAL,
            //   color: APP_MAIN,
            //   marginRight: 8,
            // }}
          />
          <XText variant='primary'>จัดเรียงรูปภาพ</XText>
        </HStack>
      </TouchableOpacity>
    )
  }

  render() {
    logRender(this)
    const { hide, maxLength } = this.props
    if (!_.isNil(hide) && hide) {
      return null
    }
    const { isInitialized } = this.state
    if (!isInitialized) {
      return null
    }
    return (
      <VStack w='full'>
        {maxLength === 1 ? this._renderImages() : <XHorizontalScrollView w='full'>{this._renderImages()}</XHorizontalScrollView>}
        {this._renderInternalInspectModal()}
        {this._renderSortModal()}
        {this._renderSortButton()}
        {/* <Button full light onPress={ () => this.submitUploadImages() }> */}
        {/* <XText>UPLOAD...</XText> */}
        {/* </Button> */}
      </VStack>
    )
  }
  // --------------------------------------
  // --         End Native Render      --
  // --------------------------------------

  // Refactor from ImgBlock
  _showActionSheet = async (imgIndex: number, isAdder: boolean) => {
    if (this._onProcessButtonPress) {
      return
    }
    this._onProcessButtonPress = true

    const { readonly, onPressInspectImage, uploading, openGalleryChoose, openCameraChoose, disabledRemoveSingleImage } = this.props
    let ASOpts

    if (uploading) {
      // Bypass to XGallery without multiple inspecting
      await this._viewImage(imgIndex)
      this._onProcessButtonPress = false
      return
      // ASOpts = ACTION_SHEET_PROPERTIES.UPLOADING
    }

    if (readonly) {
      // Bypass to XGallery
      await this._viewImage(imgIndex, true)
      this._onProcessButtonPress = false
      return
    }

    if (isAdder) {
      ASOpts = ACTION_SHEET_PROPERTIES.ADD_IMAGE
      // } else if (readonly) {
      // ASOpts = ACTION_SHEET_PROPERTIES.VIEW_IMAGE
    } else if (this.state.xImages.size === 1 && disabledRemoveSingleImage) {
      ASOpts = ACTION_SHEET_PROPERTIES.EDIT_SINGLE_IMAGE
    } else {
      ASOpts = ACTION_SHEET_PROPERTIES.EDIT_IMAGE
    }

    if (!_.isFunction(onPressInspectImage) && !_.isFunction(this._handleInternalInspectImage)) {
      delete ASOpts.indexes.inspect
    }
    if (!_.isNil(openGalleryChoose) && openGalleryChoose) {
      this._galleryChoosePressed(imgIndex, isAdder)
      this._onProcessButtonPress = false
      return
    }
    if (!_.isNil(openCameraChoose) && openCameraChoose) {
      this._cameraChoosePressed(imgIndex, isAdder)
      this._onProcessButtonPress = false
      return
    }
    const CANCEL_INDEX = ASOpts.buttons.length - 1
    const buttonIndex = await new Promise(async (resolve) => {
      ActionSheet.show(
        {
          options: ASOpts.buttons,
          cancelButtonIndex: CANCEL_INDEX,
          destructiveButtonIndex: ASOpts.indexes.delete,
          title: 'เลือกรูปภาพจาก...',
        },
        resolve
      )
    })
    const idx = _.isString(buttonIndex) ? parseInt(buttonIndex) : buttonIndex
    switch (idx) {
      case ASOpts.indexes.camera:
        await this._cameraChoosePressed(imgIndex, isAdder)
        break
      case ASOpts.indexes.gallery:
        await this._galleryChoosePressed(imgIndex, isAdder)
        break
      //  not neccessary anymore
      case ASOpts.indexes.inspect:
        const { inspectMultiple = false } = ASOpts
        // log('กดดูรูปภาพ')
        await this._viewImage(imgIndex, inspectMultiple)
        break
      case ASOpts.indexes.delete:
        await this._deleteImage(imgIndex)
        break
      case ASOpts.indexes.share:
        await this._shareImage(imgIndex)
        break
      case ASOpts.indexes.save:
        await this._saveImage(imgIndex)
        break
      default:
        this._onProcessButtonPress = false
        return
    }
    await delay(300)
    this._onProcessButtonPress = false
  }

  // _checkAppPermission = async (): Promise<{
  //   isCameraGranted: boolean
  //   isAndroidOldVersion: boolean
  //   isPhotoGranted: boolean,
  // }> => {
  //   const { APP_GRANTED_CAMERA, APP_GRANTED_PHOTO, APP_IS_OLD_ANDROID } = permN
  //   const propertyObj = await p.op.storageGet(xCONS.STORAGE_KEYS.APP_PROPERTY)
  //   return {
  //     isCameraGranted: propertyObj[APP_GRANTED_CAMERA],
  //     isAndroidOldVersion: propertyObj[APP_IS_OLD_ANDROID],
  //     isPhotoGranted: propertyObj[APP_GRANTED_PHOTO],
  //   }
  // }

  // App Overations
  _cameraChoosePressed = async (imgIndex: number, isAddMore: boolean) => {
    console.log('NoImpl._cameraChoosePressed')
    // const { metadata, maxLength } = this.props
    // const firstPriorityKey = metadata[0].key
    // const { width, height } = this._getImgSize(firstPriorityKey)
    // const startCameraTime: Date = new Date()
    // const needCropping = false
    // ImagePicker.openCamera({
    //   // multiple: isAddMore && imgCount < maxLength ? true : false,
    //   width,
    //   height,
    //   cropping: needCropping,
    //   compressImageQuality: 1,
    //   mediaType: 'photo',
    //   includeBase64: true,
    //   // cropping: !allowFullSizeUpload
    // })
    //   .then(image => {
    //     // image is a single image
    //     if (isAddMore) {
    //       this.addNewImages(image)
    //     } else if (!_.isArray(image) && _.isNumber(imgIndex)) {
    //       this.replaceNewImageAtIndex(imgIndex, image)
    //     }
    //   })
    //   .catch(error => {
    //     // const stopCameraTime: Date = new Date()
    //     // const diffTime: number = stopCameraTime.getMilliseconds() - startCameraTime.getMilliseconds()
    //     // log('Error from ImagePicker diffTime => ', diffTime)
    //     // if (diffTime <= 350) {
    //     //   permN.alertCameraPermission(p.op.t('e_perm.DENIED_CAMERA_TITLE'), p.op.t('e_perm.DENIED_CAMERA_MSG'), true)
    //     // }

    //     log('Error from ImagePicker _cameraChoosePressed: ', error)
    //     // ENOENT = ไม่สามารถเข้าถึงไฟล์หรือแฟ้มได้
    //   })
  }

  async _galleryChoosePressed(imgIndex, isAddMore) {
    this._changeImage(imgIndex)
    // console.log('NoImpl._galleryChoosePressed')
    // const { metadata, maxLength, onPressChooseSuccess, onPressChooseFailed } = this.props
    // const { xImages } = this.state
    // const count = xImages.size ? xImages.size : 0
    // const maxSelection = maxLength - count

    // const firstPriorityKey = metadata[0].key
    // const { width, height } = this._getImgSize(firstPriorityKey)
    // // console.log(width + ' - ' + height)

    // ImagePicker.openPicker({
    //   multiple: isAddMore && maxSelection > 1 ? true : false,
    //   // width,
    //   // height,
    //   compressImageQuality: 1,
    //   // cropping: !allowFullSizeUpload,
    //   // cropping: needCropping,
    //   mediaType: 'photo',
    //   maxFiles: maxSelection,
    //   includeBase64: true,
    // })
    //   .then(async selectedImages => {
    //     // be array in multiple pick
    //     // be object in one pick
    //     // log('_galleryChoosePressed: ', selectedImages)

    //     let needCropping = false
    //     if (!_.isNil(metadata[0].cropping)) {
    //       needCropping = metadata[0].cropping
    //     }
    //     // console.log(needCropping)
    //     let SelectedImgWidth = selectedImages.width
    //     let SelectedImgHeight = selectedImages.height
    //     if (needCropping) {
    //       await new Promise(async resolve => {
    //         const option = await this._optionActionSheet(ACTION_SHEET_PROPERTIES.CROP, SelectedImgWidth, SelectedImgHeight)
    //         if (option) {
    //           if (!_.isNil(option.cropped)) {
    //             needCropping = option.cropped
    //           }
    //           SelectedImgWidth = option.width
    //           SelectedImgHeight = option.height
    //           setTimeout(resolve, 200)
    //         } else {
    //           return
    //         }
    //       })
    //       const myPath = selectedImages.path
    //       ImagePicker.openCropper({
    //         path: myPath,
    //         width: SelectedImgWidth,
    //         height: SelectedImgHeight,
    //       })
    //         .then(imageCropper => {
    //           if (isAddMore) {
    //             this.addNewImages(imageCropper)
    //           } else {
    //             if (!_.isArray(imageCropper)) {
    //               this.replaceNewImageAtIndex(imgIndex, imageCropper)
    //             } else if (_.isArray(imageCropper) && imageCropper.length === 1) {
    //               this.replaceNewImageAtIndex(imgIndex, imageCropper[0])
    //             } else {
    //               log('_galleryChoosePressed Invalid selectedImages for replace')
    //             }
    //           }
    //           // console.log(this.state.originalImages.toJS())
    //           if (_.isFunction(onPressChooseSuccess)) {
    //             onPressChooseSuccess()
    //           }
    //           // console.log(imageCropper)
    //           return
    //         })
    //         .catch(error => {
    //           log('Error from ImagePicker _galleryChoosePressed: ', error)
    //           log('Error from ImagePicker _galleryChoosePressed stringify ', JSON.stringify(error))
    //           if (error.code) {
    //             const { code } = error
    //             // if ((p.op.isIOS() && code === 'E_PERMISSION_MISSING') || (!p.op.isIOS() && error.code === 'E_NO_IMAGE_DATA_FOUND')) {
    //             if (p.op.isIOS() && code === 'E_PERMISSION_MISSING') {
    //               permN.alertStoragePermission()
    //             }
    //             // TODO: IOS ยังไม่ได้ทดสอบ
    //             else if (code === 'E_PICKER_CANCELLED') {
    //               if (_.isFunction(onPressChooseFailed)) {
    //                 onPressChooseFailed()
    //               }
    //             }
    //           }
    //           return
    //         })
    //     } else {
    //       if (isAddMore) {
    //         this.addNewImages(selectedImages)
    //       } else {
    //         if (!_.isArray(selectedImages)) {
    //           this.replaceNewImageAtIndex(imgIndex, selectedImages)
    //         } else if (_.isArray(selectedImages) && selectedImages.length === 1) {
    //           this.replaceNewImageAtIndex(imgIndex, selectedImages[0])
    //         } else {
    //           log('_galleryChoosePressed Invalid selectedImages for replace')
    //         }
    //       }
    //       // console.log(this.state.originalImages.toJS())
    //       if (_.isFunction(onPressChooseSuccess)) {
    //         onPressChooseSuccess()
    //       }
    //     }
    //   })
    //   .catch(error => {
    //     log('Error from ImagePicker _galleryChoosePressed: ', error)
    //     log('Error from ImagePicker _galleryChoosePressed stringify ', JSON.stringify(error))

    //     if (error.code) {
    //       const { code } = error
    //       // if ((p.op.isIOS() && code === 'E_PERMISSION_MISSING') || (!p.op.isIOS() && error.code === 'E_NO_IMAGE_DATA_FOUND')) {
    //       if (p.op.isIOS() && code === 'E_PERMISSION_MISSING') {
    //         permN.alertStoragePermission()
    //       }
    //       // TODO: IOS ยังไม่ได้ทดสอบ
    //       else if (code === 'E_PICKER_CANCELLED') {
    //         if (_.isFunction(onPressChooseFailed)) {
    //           onPressChooseFailed()
    //         }
    //       }
    //     }
    //     // E_PICKER_CANCELLED
    //     // E_FAILED_TO_OPEN_CAMERA
    //     // Error: User cancelled image selection = ปิด perm กล้อง
    //     // Invalid image selected = ไม่สามารถเข้าถึงไฟล์หรือแฟ้มได้
    //   })
  }

  async _shareImage(index) {
    console.log('NoImpl._shareImage')
    // const { xImages } = this.state
    // const { shareClipboardText, shareClipboardToastMessage } = this.props
    // // log('On Press Image: ', index)

    // const imgUrl = this._getImgVisibleURLAtIndex(index)

    // if (!imgUrl) {
    //   p.op.alert('ไม่พบลิงก์ของรูปภาพ หรือรูปภาพยังไม่ถูกอัพโหลด', 'กรุณาอัพโหลดรูปภาพก่อนแชร์รูปภาพนี้')
    // }

    // if (shareClipboardText) {
    //   Clipboard.setString(shareClipboardText)
    //   if (shareClipboardToastMessage) {
    //     p.op.showToast(shareClipboardToastMessage, E_MSG_TYPE.SUCCESS)
    //   }
    // }
    // utilN.sharePhotoToShareSheet(imgUrl, { message: shareClipboardText })
  }

  _saveImage = async (index) => {
    const imgUrl = this._getImgVisibleURLAtIndex(index, false)
    // console.log('_saveImage imgUrl => ', imgUrl)
    let fileName
    let imageBase64
    // console.log('_saveImage base64 => ', imageBase64)
    if (_.isString(imgUrl) && (imgUrl.startsWith('https://') || imgUrl.startsWith('http://'))) {
      imageBase64 = await this._getBase64fromImageURL(imgUrl)
      fileName = imgUrl.split(/(\\|\/)/g).pop()
    } else if (_.isString(imgUrl) && imgUrl.startsWith('data:image')) {
      imageBase64 = imgUrl
      fileName = 'image.'
      if (imgUrl.indexOf('.jpg') > -1) {
        fileName += '.jpg'
      } else if (imgUrl.indexOf('.jpeg') > -1) {
        fileName += '.jpeg'
      } else if (imgUrl.indexOf('.png') > -1) {
        fileName += '.png'
      } else if (imgUrl.indexOf('.gif') > -1) {
        fileName += '.gif'
      } else {
        fileName += '.png'
      }
    } else {
      p.op.alert('เกิดข้อผิดพลาด', 'ไม่สามารถดาวน์โหลดรูปภาพชนิดนี้ได้')
      return
    }

    // Check if the last character of the file name is a question mark
    const lastFileName = fileName.slice(-1)
    if (lastFileName === '?') {
      // Remove question mark from the file name
      fileName = fileName.slice(0, -1)
    }

    // https://stackoverflow.com/questions/14011021/how-to-download-a-base64-encoded-image
    const a = document.createElement('a') // Create <a>
    a.href = imageBase64 // Image Base64 Goes here
    a.download = fileName // File name Here
    a.click() // Downloaded file

    // const { isPhotoGranted } = await this._checkAppPermission()
    // if (!isPhotoGranted) {
    //   await permN.requestAppPhotoPermission()
    //   return
    // }

    // const imgUrl = this._getImgVisibleURLAtIndex(index)
    // if (imgUrl) {
    //   await utilN.savePhotoToGallery(imgUrl)
    // }
  }

  _handleInternalInspectImage = async (base64images: string[], initialIndex = 0): Promise<void> => {
    if (!base64images || _.isArray(base64images)) {
      return
    }

    await setStatePromise(this, {
      isVisiblePreview: true,
      previewImages: base64images,
      previewInitialIndex: initialIndex,
    })
  }

  _viewImage = async (imgIndex: number, inspectMultiple = true): Promise<void> => {
    // log('In ImgManager _viewImage')
    const { originalImages } = this.state
    const { onPressInspectImage = null } = this.props
    // let inspectImage

    // เพราะ typescript ไม่อนุญาตให้เรียกตรงๆ :(
    // Ref: https://github.com/Microsoft/TypeScript/issues/7960
    if (!_.isNull(onPressInspectImage) && (!(typeof onPressInspectImage === 'function') || onPressInspectImage instanceof Array)) {
      throw new Error('Expected onPressInspectImage prop to be a function.')
    }
    // log('In ImgManager imgIndex => ', imgIndex, ' inspectMultiple => ', inspectMultiple)
    // inspectImage = _.isFunction(onPressInspectImage) ? onPressInspectImage : this._handleInternalInspectImage
    // log('In ImgManager inspectImage => ', inspectImage)
    if (_.isFunction(onPressInspectImage)) {
      const imgURL = this._getImgVisibleURLAtIndex(imgIndex)
      let base64image = null
      if (_.isNumber(imgIndex) && imgURL) {
        // log('In ImgManager imgURL => ', imgURL)
        base64image = await this._getBase64fromImageURL(imgURL)
        // await inspectImage(base64image)
        await onPressInspectImage(base64image)
        return
      }
    }

    let imgUris = null
    if (inspectMultiple) {
      imgUris = await this._getFirstProitySizeImageUrls()
    } else {
      const imgURL = this._getImgVisibleURLAtIndex(imgIndex)
      // log('_viewImage:: imgURL => ', imgURL)
      let base64image = null
      if (_.isNumber(imgIndex) && imgURL) {
        base64image = await this._getBase64fromImageURL(imgURL)
        imgUris = [base64image]
        // log('_viewImage:: base64image => ', base64image)
      }
    }

    if (imgUris) {
      // console.log('imgUris => ', imgUris)
      await setStatePromise(this, {
        isVisiblePreview: true,
        previewImages: imgUris,
        previewInitialIndex: imgIndex,
      })
    }

    // const xImgs = [
    // 'http://1.bp.blogspot.com/-tdCnZC9ZBtw/U16vRMOL0iI/AAAAAAAAMIw/muYrLXsZ8UA/s1600/beautiful-angel-hd-wallpapers-%25282%2529.jpg',
    // 'http://2.bp.blogspot.com/-mtq0VxMUnuA/U16vSsNC7vI/AAAAAAAAMJA/ePv-vPhpbK4/s1600/beautiful-angel-hd-wallpapers-%25283%2529.jpg',
    // 'http://2.bp.blogspot.com/-On3tatWuReQ/U16vTAJwuzI/AAAAAAAAMI8/RzAIWMWCvoc/s1600/beautiful-angel-hd-wallpapers-%25284%2529.jpg',
    // 'http://3.bp.blogspot.com/-ZeKgAH1MOW4/U16vYN4FT4I/AAAAAAAAMJQ/KPApPGiImuQ/s1600/beautiful-angel-hd-wallpapers-%25285%2529.jpg',
    // 'http://3.bp.blogspot.com/-KlojHHp5PSo/U16vZo4yrgI/AAAAAAAAMJY/crkYouHK8nM/s1600/beautiful-angel-hd-wallpapers-%25286%2529.jpg',
    // 'http://3.bp.blogspot.com/-asA8sRxZ_DM/U16vYD6r-RI/AAAAAAAAMJM/z65CiF3vBi8/s1600/beautiful-angel-hd-wallpapers-%25287%2529.jpg',
    // 'http://1.bp.blogspot.com/-HXs3Nsi7ffw/U16va_0Gr7I/AAAAAAAAMJg/u881_Mil6PU/s1600/beautiful-angel-hd-wallpapers-%25288%2529.jpg',
    // 'http://2.bp.blogspot.com/-Mk21lKNUnLc/U16vO6flwbI/AAAAAAAAMIo/2_BTWvAd60M/s1600/beautiful-angel-hd-wallpapers-%25281%2529.jpg',
    // 'http://1.bp.blogspot.com/-tdCnZC9ZBtw/U16vRMOL0iI/AAAAAAAAMIw/muYrLXsZ8UA/s1600/beautiful-angel-hd-wallpapers-%25282%2529.jpg',
    // ]
    // await setStatePromise(this, {
    //   isVisiblePreview: true,
    //   previewImages: xImgs,
    //   previewInitialIndex: imgIndex,
    // })

    // const originalImage = originalImages.get(imgIndex)
    // if (originalImage && originalImage.data) { // data is base64
    //   // log('In ImgManager originalImage => ', originalImage)
    //   base64image = `data:${originalImage.mime};base64,${originalImage.data}`
    //   await inspectImage(base64image)
    //   return
    // } else {
    //   p.op.alert('เกิดข้อผิดพลาด', 'ไม่พบข้อมูลรูปภาพ กรุณาลองใหม่อีกครั้ง')
    // }
  }

  // _optionActionSheet = async (actionProps, SelectedImgWidth, SelectedImgHeight) => {
  //   let checker = false
  //   // let { width, height } = Dimensions.get('window')
  //   let imgSize
  //   await new Promise(async (resolve) => {
  //     ActionSheet.show(
  //       {
  //         options: actionProps.BUTTONS,
  //         cancelButtonIndex: actionProps.ACTION_INDEXES.CANCEL,
  //         // destructiveButtonIndex: null,
  //         title: actionProps.TITLE,
  //       },
  //       (buttonIndex) => {
  //         switch (+buttonIndex) {
  //           case actionProps.ACTION_INDEXES.SQUARE:
  //             imgSize = { width: SelectedImgWidth, height: SelectedImgWidth }
  //             checker = true
  //             setTimeout(resolve, 200)
  //             break
  //           case actionProps.ACTION_INDEXES.HORIZONTAL:
  //             imgSize = { width: SelectedImgWidth, height: 0.8 * SelectedImgWidth }
  //             checker = true
  //             setTimeout(resolve, 200)
  //             break
  //           case actionProps.ACTION_INDEXES.VERTICAL:
  //             imgSize = { width: SelectedImgWidth, height: 1.4 * SelectedImgWidth }
  //             checker = true
  //             setTimeout(resolve, 200)
  //             break
  //           case actionProps.ACTION_INDEXES.NOT_CROPPED:
  //             imgSize = { width: SelectedImgWidth, height: SelectedImgHeight, cropped: false }
  //             checker = true
  //             setTimeout(resolve, 200)
  //             break
  //           case actionProps.ACTION_INDEXES.CANCEL:
  //             checker = false
  //             if (_.isFunction(this.props.onPressChooseFailed)) {
  //               this.props.onPressChooseFailed()
  //             }
  //             setTimeout(resolve, 200)
  //             break
  //           default:
  //             checker = false
  //             setTimeout(resolve, 200)
  //             break
  //         }
  //       }
  //     )
  //   })
  //   if (checker) {
  //     return imgSize
  //   }
  //   return false
  // }

  _deleteImage = async (imgIndex: number) => {
    // log('In ImgManager _deleteImage')
    await this.deleteImageAtIndex(imgIndex)
  }
}

interface ISortableImageItemProps {
  sortHandlers?: any // มาจาก sortable-listview
  index: number
  data: string
  active?: boolean
  isFirst: boolean
  isLast: boolean
  handleMoveDown: (currentIndex: number) => void
  handleMoveUp: (currentIndex: number) => void
}

class SortableImageItem extends React.PureComponent<ISortableImageItemProps> {
  isMoving?: boolean

  _doMoveUp = () => {
    if (this.isMoving) return
    this.isMoving = true
    const { index, handleMoveUp } = this.props
    handleMoveUp(index)
    setTimeout(() => {
      this.isMoving = false
    }, 500)
  }

  _doMoveDown = () => {
    if (this.isMoving) return
    this.isMoving = true
    const { index, handleMoveDown } = this.props
    handleMoveDown(index)
    setTimeout(() => {
      this.isMoving = false
    }, 500)
  }

  render() {
    const { index, data, sortHandlers, isFirst, isLast, active } = this.props
    return (
      <TouchableHighlight style={INTL_STYLES.sortItem} underlayColor={APP_MAIN} delayLongPress={100} delayPressIn={50} {...sortHandlers}>
        <View style={S.ROW_MIDDLE_START}>
          <VStack
            // @ts-ignore
            style={StyleSheet.flatten(INTL_STYLES.colNumber)}>
            <XText>{index + 1}</XText>
          </VStack>
          <VStack size={100}>
            <RNImage
              resizeMode='cover'
              source={{ uri: data }}
              // @ts-ignore
              style={StyleSheet.flatten(INTL_STYLES.sortableImg)}
            />
          </VStack>
          <VStack
            // @ts-ignore
            style={StyleSheet.flatten(INTL_STYLES.colImage)}>
            {isFirst ? (
              <View
                // @ts-ignore
                style={StyleSheet.flatten(INTL_STYLES.btnMoveSort)}
              />
            ) : (
              <TouchableOpacity
                // @ts-ignore
                style={StyleSheet.flatten(INTL_STYLES.btnMoveSort)}
                onPress={this._doMoveUp}>
                <Icon type='FontAwesome' name='arrow-circle-o-up' style={StyleSheet.flatten(INTL_STYLES.iconSort)} />
              </TouchableOpacity>
            )}
            {!isFirst && !isLast ? <View style={StyleSheet.flatten(INTL_STYLES.separatorMoveSort)} /> : null}
            {isLast ? (
              <View
                // @ts-ignore
                style={StyleSheet.flatten(INTL_STYLES.btnMoveSort)}
              />
            ) : (
              <TouchableOpacity
                // @ts-ignore
                style={StyleSheet.flatten(INTL_STYLES.btnMoveSort)}
                onPress={this._doMoveDown}>
                <Icon type='FontAwesome' name='arrow-circle-o-down' style={StyleSheet.flatten(INTL_STYLES.iconSort)} />
              </TouchableOpacity>
            )}
          </VStack>
        </View>
      </TouchableHighlight>
    )
  }
}
