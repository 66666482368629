import React, { Component } from 'react'
import {
  View,
  Keyboard,
  Dimensions,
  // StatusBar,
  Platform,
  // NativeModules,
} from 'react-native'

// import { NavigationActions } from 'react-navigation'
// import * as NavActions from '../../services/navigation'

import * as util from 'x/utils/util'
import p from 'x/config/platform-specific'
import actions from 'x/config/actions'

import { STYLES, COLORS } from 'x/config/styles'
import _ from 'lodash'
// const { StatusBarManager } = NativeModules
// const STATUSBAR_HEIGHT = Platform.OS === 'ios' ? 20 : StatusBarManager.HEIGHT;

import XInput from 'xui/components/XInput'
import xCONS from 'x/config/constants'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import XCard from 'xui/components/XCard'
import XText from 'xui/components/XText'
import XButton from 'xui/components/XButton'
import HStack from 'xui/components/HStack'
import VStack from 'xui/components/VStack'
import Box from 'xui/components/Box'
import XImage from 'xui/components/XImage'
import BackIcon from 'xui/components/BackIcon'
import * as NavActions from 'x/utils/navigation'
import { IXScreenProps } from 'x/types'

const X_LOGO = require('../../img/logo_1024.png')

interface IForgottenPasswordViewProps extends IXScreenProps {
  dispatch: (params: any) => void
}

interface IForgottenPasswordViewState {
  keyboardHeight?: number
  visibleHeight?: number
  deviceWidth?: number
  submitCount?: number

  focusedKey?: string
  email?: string

  isSuccess?: boolean
}

export default class ForgottenPasswordView extends Component<IForgottenPasswordViewProps, IForgottenPasswordViewState> {
  static displayName = 'ForgottenPasswordView'
  // static propTypes = {
  //   navigation: PropTypes.object.isRequired,
  // }

  // static navigationOptions = ({ navigation }) => {
  //   const { goBack, state } = navigation
  //   return {
  //     headerLeft: <NavHeaderButton backIcon onPressItem={() => goBack(state.key)}></NavHeaderButton>,
  //     title: 'ลืมรหัสผ่าน',
  //     // headerRight: null,
  //   }
  // }

  inProcess?: boolean

  inputRefs?: any

  inputFields?: any

  constructor(props) {
    super(props)
    this.state = {
      keyboardHeight: 0,
      visibleHeight: Dimensions.get('window').height,
      deviceWidth: Dimensions.get('window').width,
      submitCount: 0,

      focusedKey: 'email',
      email: '',

      isSuccess: false,
    }

    this.inputRefs = {}
    // Local constants
    this.inputFields = [
      {
        key: 'email',
        validationFunction: text => util.isEmailValidFormat(text),
        lowerCaseOnly: true,
        // txtPlaceholder: 'ระบุอีเมลที่ลืมรหัสผ่าน เช่น example@xselly.com',
        txtPlaceholder: 'เช่น example@xselly.com',
        txtSubmitButton: 'เปลี่ยนรหัสผ่าน',
        txtSuggestTitle: 'กรุณาระบุอีเมลในรูปแบบที่ถูกต้อง',
        txtSuggestMessage: null,
        keyboardType: 'email-address',
        inputMaxLength: 50,
        isLast: true,
      },
    ]
  }

  // componentWillMount() {
  //   this.keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', this._keyboardDidShow.bind(this))
  //   this.keyboardWillHideListener = Keyboard.addListener('keyboardWillHide', this._keyboardWillHide.bind(this))
  // }

  // componentDidMount() {
  //   // Auto focus if input ref was setup
  //   setTimeout(() => {
  //     const firstFieldKey = this.inputFields[0].key
  //     // console.log("this.inputRefs: ", this.inputRefs)
  //     // By pKeng 27 Feb 2021: App Crashes here on iOS & Android
  //     // This problem seems that, by transitioning from LoginByEmail to this view, React Native does NOT
  //     // reset the inputRefs properly. Hence app crashes as it tried to call .focus on an element from
  //     // the previous view, ForgottenPasswordView.

  //     // this line make the app crashes 100% with the reason that couldn't find focus function on the object
  //     // if (this.inputRefs[firstFieldKey]) {

  //     // The fix here is to check for the existence of the function, so it won't crash.
  //     if (this.inputRefs[firstFieldKey] && typeof this.inputRefs[firstFieldKey].focus === 'function') {
  //       // this block if highly likely that it'll never be executed.
  //       this.inputRefs[firstFieldKey].focus()
  //     }
  //   }, 500)
  // }

  // componentWillUnmount() {
  //   this.keyboardDidShowListener.remove()
  //   this.keyboardWillHideListener.remove()
  // }

  // =========   For get keyboard offset ============
  // _keyboardDidShow(e) {
  //   // util.log('Keyboard Shown: ', e)
  //   this.setState({ keyboardHeight: e.endCoordinates.height })
  // }

  // _keyboardWillHide(e) {
  //   // util.log('Keyboard Hidden: ', e)
  //   this.setState({ keyboardHeight: 0 })
  // }

  _renderFields = () => this.inputFields.map(input => this._renderField({ ...input }))

  _renderField = ({ key, validationFunction, txtPlaceholder, keyboardType, inputMaxLength, lowerCaseOnly, isSecureText, isLast }) => {
    const { deviceWidth } = this.state
    const inputValue = this.state[key]
    const isCompletedInput = !_.isFunction(validationFunction) || (_.isFunction(validationFunction) && validationFunction(inputValue))
    return (
      <HStack
        key={`inputField_${key}`}
        w='full'
        // success={isCompletedInput}
        // style={[
        //   STYLES.NO_MARGIN_PADDING,
        //   {
        //     flex: 1,
        //     paddingLeft: deviceWidth * 0.1,
        //     paddingTop: deviceWidth * 0.08,
        //   },
        // ]}
      >
        <XInput
          w='full'
          InputLeftElement={
            <HStack h='9' p='1' alignItems='center' bg='muted.200' borderRightWidth='1' borderRightColor='muted.300'>
              <XText minW='52px' fontSize='xs' textAlign='center' variant='inactive' bold>
                อีเมลที่ลืมรหัสผ่าน
              </XText>
            </HStack>
          }
          onRef={r => r && (this.inputRefs[key] = r)}
          // style={{
          //   // color: COLORS.TEXT_PRIMARY,
          //   // fontSize: 24,
          //   fontWeight: '800',
          //   // marginLeft: -20,
          //   // height: 54,
          // }}
          onFocus={() => this.setState({ focusedKey: key })}
          maxLength={inputMaxLength || null}
          autoCorrect={false}
          autoCapitalize='none'
          placeholder={txtPlaceholder || null}
          placeholderTextColor={COLORS.TEXT_INACTIVE}
          value={inputValue || ''}
          keyboardType={keyboardType || 'default'}
          selectTextOnFocus={false}
          // returnKeyType={'done'}
          blurOnSubmit={false}
          secureTextEntry={isSecureText || false}
          onChangeText={text => {
            util.log(`${key} onChangeText input  => `, text)
            const newState = _.cloneDeep(this.state)

            if (lowerCaseOnly) {
              newState[key] = text.toString().toLowerCase().trim()
            } else {
              newState[key] = text.toString().trim()
            }

            util.log(`${key} onChangeText trim  => `, newState[key])
            if (this.isGeneralRegExPass(text)) {
              this.setState(newState)
            }
          }}
          onKeyPress={e => this._handleKeyDown({ e, isLast })}
        />
      </HStack>
    )
  }

  isGeneralRegExPass = txt => {
    const re = /^[a-zA-Z0-9*-@!.#$%^&*()-_+={}|\\/,'"]*$/gi
    return re.test(txt)
  }

  _handleKeyDown = ({ e, isLast }) => {
    util.log('_handleKeyDown e => ', e)
    if (e.nativeEvent.key === 'Enter') {
      this._handleSubmit({ isLast })
    }
  }

  _renderSubmitButton({ txtSubmitButton, isLast }) {
    if (this.state.isSuccess) {
      return null
    }

    return (
      <HStack w='full' minH='10' p='1'>
        <XButton
          w='full'
          minH='10'
          onPress={() => this._handleSubmit({ isLast })}
          // style={[STYLES.NO_MARGIN_PADDING, STYLES.BTN_MODE_ACTIVE_PRIMARY, { height: 50, borderRadius: 0 }]}
        >
          {txtSubmitButton || 'ตกลง'}
        </XButton>
      </HStack>
    )
  }

  _handleSubmit = ({ isLast }) => {
    if (isLast) {
      this._submit()
    } else {
      const { focusedKey } = this.state
      const foundIndex = this.inputFields.findIndex(input => input.key === focusedKey)
      if (this.inputRefs && foundIndex > -1) {
        const nextKey = this.inputFields[foundIndex + 1].key
        this.inputRefs[nextKey].focus()
      }
    }
  }

  _submit = () => {
    Keyboard.dismiss()
    if (this.inProcess) {
      return
    }
    this.inProcess = true

    const body = {}
    for (let i = 0; i < this.inputFields.length; i++) {
      const { key, validationFunction, txtSuggestTitle, txtSuggestMessage } = this.inputFields[i]
      const currentValue = this.state[key]
      if (_.isFunction(validationFunction) && !validationFunction(currentValue)) {
        p.op.alert(
          txtSuggestTitle,
          txtSuggestMessage
          // [
          // {
          //   text: 'ตกลง',
          //   onPress: () => {
          //     if (this.inputRefs[key]) {
          //       this.inputRefs[key].focus()
          //     }
          //   },
          // },
          // ]
        )
        this.inProcess = false
        return
      }
      body[key] = this.state[key]
    }
    this.props.dispatch({
      type: actions.FORGOT_ACCOUNT_PASSWORD,
      payload: body,
      successCallback: () => this._onSuccessRecoveryPassword(),
    })
    this.inProcess = false
  }

  _onSuccessRecoveryPassword() {
    this.setState({ isSuccess: true })
    p.op.showToast('แจ้งลืมรหัสผ่านสำเร็จ', 'success')
  }

  _renderConditionTabBar() {
    const { focusedKey } = this.state
    const focusedIndex = this.inputFields.findIndex(input => input.key === focusedKey)

    const { txtSubmitButton, isLast } = this.inputFields[focusedIndex]
    const { keyboardHeight } = this.state
    const isIOS = Platform.OS === 'ios'

    return this._renderTabBarAboveKB({
      isVisible: true,
      offsetBottom: isIOS ? keyboardHeight : 0,
      tabHeight: 50,
      childComponent: this._renderSubmitButton({ txtSubmitButton, isLast }),
    })
  }

  _renderTabBarAboveKB = ({ isVisible, offsetBottom, tabHeight, childComponent }) => {
    if (!isVisible) {
      return <View />
    }
    return (
      <View
        style={{
          height: tabHeight,
          // backgroundColor: '#111122',
          backgroundColor: COLORS.BG_LIGHT_GREY,
          position: p.op.isWeb() ? 'relative' : 'absolute',
          bottom: offsetBottom,
          flex: 1,
          width: '100%',
          flexDirection: 'column',
          justifyContent: 'flex-start',
        }}>
        {childComponent}
      </View>
    )
  }

  _goBack = () => {
    const { navigation } = this.props
    if (p.op.isWeb()) {
      navigation.dispatch(NavActions.resetToFirstPage)
    } else {
      util.navGoBack(this.props)
    }
  }

  renderXSellyLogo = () => (
    <Box
      w='80px'
      h='80px'
      borderTopLeftRadius='md'
      borderTopRightRadius='md'
      _light={{ bg: 'white' }}
      alignItems='center'
      justifyContent='center'>
      <XImage resizeMode='contain' source={X_LOGO} w='76px' h='76px' />
    </Box>
  )

  _renderBackButton = () => (
    <XButton variant='ghost' w='100px' leftIcon={<BackIcon />} onPress={this._goBack}>
      ย้อนกลับ
    </XButton>
  )

  render() {
    util.logRender(this)
    const { email, isSuccess } = this.state

    // for display valid email checking
    let displayMail = null
    const isCompletedInput = util.isEmailValidFormat(email)
    if (isCompletedInput) {
      displayMail = email
    }

    return (
      <XContainer style={[STYLES.NO_MARGIN_PADDING, { backgroundColor: COLORS.FORM_PRIMARY_BG }]}>
        {/* <XCustomHeader headerLeftProps={{ backIcon: true, onPressItem: this._goBack }} title='ลืมรหัสผ่าน' /> */}
        {p.op.isWeb() ? (
          <XImage
            resizeMode='cover'
            style={{
              flex: 1,
              position: 'absolute',
              width: '100%',
              height: '100%',
              justifyContent: 'center',
            }}
            source={{ uri: 'https://xselly.com/x/bg.jpg' }}
          />
        ) : null}

        <XContent
          // extraScrollHeight={50}
          keyboardShouldPersistTaps='always'
          keyboardDismissMode='none'
          style={[STYLES.NO_MARGIN_PADDING, { backgroundColor: 'transparent' }]}>
          <VStack w='full' p='2' alignItems='center'>
            <XCard minW='300px' maxW='520px' mt='60px' overflow='visible'>
              <Box position='absolute' ml='auto' mr='auto' top='-40px' left='0' right='0' alignItems='center' justifyContent='center'>
                {this.renderXSellyLogo()}
              </Box>
              <Box position='absolute' top='0' left='0' zIndex={999}>
                {this._renderBackButton()}
              </Box>
              <VStack w='full' p='2' pt='50px' space='2'>
                <HStack w='full' alignItems='center' justifyContent='center'>
                  <XText fontSize='md' bold>
                    ลืมรหัสผ่าน
                  </XText>
                </HStack>

                <VStack w='full'>{!isSuccess ? this._renderFields() : null}</VStack>
                <XText mt='2' fontSize='md'>
                  ขั้นตอนการเปลี่ยนรหัสผ่าน
                </XText>
                <XText>
                  {'1. กดปุ่ม "เปลี่ยนรหัสผ่าน" ด้านล่าง\n' +
                    `2. เปิดอีเมลของคุณ ${displayMail ? `"${displayMail}" ` : ''}ที่ส่งมาจาก ${xCONS.APP_NAME}\n` +
                    '3. ในอีเมลกดปุ่ม "แก้ไขรหัสผ่าน"\n' +
                    `4. กรอกอีเมลนี้${displayMail ? ` (${displayMail})` : ''} และรหัสผ่านใหม่ที่ต้องการ\n` +
                    '5. เมื่อการเปลี่ยนรหัสผ่านเสร็จสิ้น คุณสามารถเข้าสู่ระบบด้วยรหัสผ่านใหม่ได้ทันที'}
                </XText>

                {p.op.isWeb() ? this._renderConditionTabBar() : null}
              </VStack>
            </XCard>
          </VStack>
        </XContent>
        {p.op.isWeb() ? null : this._renderConditionTabBar()}
      </XContainer>
    )
  }
}
