import React from 'react'
import { Keyboard, Linking, TouchableOpacity } from 'react-native'
import { FormikProps, FormikHelpers as FormikActions } from 'formik'
import { delay } from 'x/utils/util'
import api from 'x/utils/api'
import * as auth from 'x/utils/authentication'

import Yup, { FIRSTNAME, LASTNAME, EMAIL, PASSWORD, PASSWORD_CONFIRMATION, isRequired } from 'x/utils/validator'
import _ from 'lodash'
// import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'
import p from 'x/config/platform-specific'
import XIcon from 'xui/components/XIcon'
import HStack from 'xui/components/HStack'
import XFormikInput from 'xui/components/XFormikInput'
import XButton from 'xui/components/XButton'
// import * as NavActions from '../../services/navigation'
import * as NavActions from 'x/utils/navigation'
import VStack from 'xui/components/VStack'
import XText from 'xui/components/XText'
import BaseOnboardingView from './BaseOnboardingView'

export default class OnboardingRegisterView extends BaseOnboardingView {
  static displayName = 'OnboardingRegisterView'

  constructor(props) {
    super(props)

    this.state = {}

    this.initialValues = {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      password_confirmation: '',
    }

    this.validationSchema = Yup.object().shape({
      first_name: isRequired(FIRSTNAME),
      last_name: isRequired(LASTNAME),
      email: isRequired(EMAIL),
      password: isRequired(PASSWORD),
      password_confirmation: isRequired(PASSWORD_CONFIRMATION),
    })

    this.txtHeaderTitle = 'ลงทะเบียนผู้ใช้ใหม่'
    this.txtBtnFooter = 'ลงทะเบียน'
  }

  componentDidMount() {
    setTimeout(this._autoFocusFirstInput, 1500)
  }

  renderInputLeftElement = (label: string) => (
    <HStack h='9' p='1' alignItems='center' bg='muted.200' borderRightWidth='1' borderRightColor='muted.300'>
      <XText minW='52px' fontSize='xs' textAlign='center' variant='inactive' bold>
        {label}
      </XText>
    </HStack>
  )

  renderContent(fProps: FormikProps<any>): React.ReactNode {
    return (
      <VStack w='full' py='2' space='1'>
        <HStack
          // style={this.styles.GRID_COL_MIDDLE_CENTER}
          w='full'
          p='2'
          justifyContent='center'
          alignItems='center'>
          {this._renderHeaderTitle()}
        </HStack>

        <HStack w='full'>
          <HStack w='50%' space='1' alignItems='center'>
            {/* <XText variant='inactive'>ชื่อ</XText> */}
            <XFormikInput
              flex={1}
              // style={this.styles.input}
              name='first_name'
              InputLeftElement={this.renderInputLeftElement('ชื่อ')}
              placeholder='พรทิพย์'
              order={1}
              handleRegisterRef={this._doRegisterRef}
              handleOnSubmitEditing={this._doSubmitEditing}
              returnKeyType='next'
              // getReturnKeyType={this._getReturnKeyType}
            />
          </HStack>
          <HStack w='50%' pl='2' space='1' alignItems='center'>
            {/* <XText variant='inactive'>นามสกุล</XText> */}
            <XFormikInput
              flex={1}
              // style={this.styles.input}
              name='last_name'
              InputLeftElement={this.renderInputLeftElement('นามสกุล')}
              placeholder='มารวย'
              order={2}
              handleRegisterRef={this._doRegisterRef}
              handleOnSubmitEditing={this._doSubmitEditing}
              returnKeyType='next'
              // getReturnKeyType={this._getReturnKeyType}
            />
          </HStack>
        </HStack>

        {/* <HStack>
          <XText variant='inactive'>อีเมล</XText>
        </HStack> */}

        <XFormikInput
          autoCapitalize='none'
          keyboardType='email-address'
          // flex={1}
          // style={this.styles.input}
          name='email'
          InputLeftElement={this.renderInputLeftElement('อีเมล')}
          placeholder='อีเมลใดก็ได้ที่คุณใช้ประจำ เปลี่ยนภายหลังได้'
          order={3}
          handleRegisterRef={this._doRegisterRef}
          handleOnSubmitEditing={this._doSubmitEditing}
          returnKeyType='next'
          // getReturnKeyType={this._getReturnKeyType}
        />

        <XFormikInput
          autoCapitalize='none'
          secureTextEntry
          // flex={1}
          // style={this.styles.input}
          name='password'
          InputLeftElement={this.renderInputLeftElement('รหัสผ่าน')}
          placeholder='รหัสผ่านสำหรับเข้าแอป'
          order={4}
          handleRegisterRef={this._doRegisterRef}
          handleOnSubmitEditing={this._doSubmitEditing}
          returnKeyType='next'
          // getReturnKeyType={this._getReturnKeyType}
        />
        {/* <XText variant='inactive' fontSize='xs'>
            รหัสผ่าน (อย่างน้อย 8 ตัว โดยประกอบด้วยตัวเลข ตัวอักษรภาษาอังกฤษตัวพิมพ์เล็กและใหญ่)
          </XText> */}

        {/* <HStack>
          <XText variant='inactive'>ยืนยันรหัสผ่าน</XText>
        </HStack> */}

        <XFormikInput
          autoCapitalize='none'
          secureTextEntry
          // flex={1}
          // style={this.styles.input}
          name='password_confirmation'
          InputLeftElement={this.renderInputLeftElement('ยืนยันรหัสผ่าน')}
          placeholder='ยืนยันรหัสผ่านเข้าแอป'
          order={5}
          handleRegisterRef={this._doRegisterRef}
          // handleOnSubmitEditing={this._doSubmitEditing}
          handleOnSubmitEditing={fProps.handleSubmit}
          returnKeyType='done'
          // getReturnKeyType={this._getReturnKeyType}
        />

        {/* <HStack> */}
        {/* <XButton onPress={fProps.handleSubmit}> */}
        {/* <Text>SUBMIT</XText> */}
        {/* </XButton> */}
        {/* </HStack> */}
        {/* <HStack> */}
        {/* <Text style={this.styles.txtLog}>{JSON.stringify(fProps, null, 2)}</XText> */}
        {/* </HStack> */}

        <HStack w='full' space='2'>
          {this.renderConsentCheckbox()}
          <HStack flex={1} flexWrap='wrap'>
            <XText>
              {'ข้าพเจ้าได้อ่าน เข้าใจ และตกลงตามข้อกำหนดในนโยบายคุ้มครองข้อมูลส่วนบุคคลของ XSelly ซึ่งสามารถ '}
              <XText color='blue.500' onPress={() => Linking.openURL('https://xselly.com/pdpa')}>
                กดดูได้ที่นี่
              </XText>
            </XText>
          </HStack>
        </HStack>
      </VStack>
    )
  }

  _toggleConsentCheckbox = () => {
    // @ts-ignore
    const { isConsentChecked = false } = this.state
    this.setState({ isConsentChecked: !isConsentChecked })
  }

  renderConsentCheckbox = () => {
    // @ts-ignore
    const { isConsentChecked = false } = this.state
    return (
      <TouchableOpacity onPress={this._toggleConsentCheckbox}>
        <XIcon
          // family='FontAwesome'
          // name={checked ? 'check-circle' : 'circle-o'}
          minW='28px'
          family={isConsentChecked ? 'Ionicons' : 'FontAwesome'}
          name={isConsentChecked ? 'checkmark-circle' : 'circle-o'}
          variant={isConsentChecked ? 'success' : 'primary'}
          // style={StyleSheet.flatten([S.MIN_WIDTH_24, isConsentChecked ? S.TEXT_ICON_SUCCESS : S.TEXT_ICON_PRIMARY, S.TEXT_ICON_LARGER])}
          // variant={checked ? 'success' : 'inactive'}
        />
      </TouchableOpacity>
    )
  }

  renderFooter = (fProps: FormikProps<any>) => {
    // @ts-ignore
    const { isConsentChecked = false } = this.state

    const { isSubmitting, isValid, handleSubmit } = fProps
    // const { handleSubmit } = fProps
    // const isDisabled = !isConsentChecked
    const isDisabled = isSubmitting || !isValid || !isConsentChecked
    // const btnStyle = isDisabled ? this.styles.btnFooterDisabled : this.styles.btnFooter
    // const txtBtnStyle = isDisabled ? this.styles.txtBtnFooterDisabled : this.styles.txtBtnFooter
    return (
      <HStack
        w='full'
        p='1'
        _light={{
          bg: 'white',
        }}
        // style={this.styles.footer}
      >
        {/* <FooterTab style={this.styles.footerTab}> */}
        <XButton
          w='full'
          minH='9'
          // @ts-ignore
          onPress={handleSubmit}
          disabled={isDisabled}
          isDisabled={isDisabled}
          // style={btnStyle}
          // primary
          // full
        >
          {/* <Text style={txtBtnStyle}>{this.txtBtnFooter}</XText> */}
          {this.txtBtnFooter}
        </XButton>
        {/* </FooterTab> */}
      </HStack>
    )
  }

  async handleSubmitForm(values: any, formActions: FormikActions<any>): Promise<void> {
    Keyboard.dismiss()

    // @ts-ignore
    const { isConsentChecked = false } = this.state
    if (!isConsentChecked) {
      await p.op.alertPromise(
        'ยังไม่ได้ยืนยันยอมรับนโยบายคุ้มครองข้อมูลส่วนบุคคล',
        'กรุณาอ่านเงื่อนไขข้อกำหนดในนโยบายคุ้มครองข้อมูลส่วนบุคคล แล้วกดติ๊กถูกเพื่อยอมรับ'
        // [{ text: 'ตกลง', onPress: () => {} }],
        // { cancelable: false }
      )
      return
    }

    const { navigation } = this.props
    // log(JSON.stringify(values, null, 2))
    // log('formActions => ', formActions)
    if (!util.isEffilyPasswordValidFormat(values.password)) {
      await p.op.alertPromise(
        'กรุณาระบุรหัสผ่านที่ถูกต้อง',
        'รหัสผ่านต้องมีอย่างน้อย 8 ตัวอักษร ต้องระบุด้วยภาษาอังกฤษ ตัวพิมพ์เล็กและตัวพิมพ์ใหญ่อย่างน้อย 1 ตัว และตัวเลขอารบิกอย่างน้อย 1 ตัว'
        // [{ text: 'ตกลง', onPress: () => {} }],
        // { cancelable: false }
      )
      return
    }

    const reqBody = { ...values }

    // ถ้า submit ได้ควรจะยินยอม PDPA แล้ว
    reqBody.is_consented = true

    try {
      const response = await api.post(api.POST_SIGNUP, reqBody, { showSpinner: true, noToken: true })

      // log('handleSubmitForm::response => ', response)
      // log(JSON.stringify(values, null, 2))
      if (_.has(response, 'status') && response.status === 'ok') {
        if (_.has(response, 'token') && _.isString(response.token)) {
          await auth.setAuthenticationToken(response.token)
          formActions.resetForm()
          if (_.has(response, 'missing') && response.missing === 'username') {
            // navigation.navigate('OnboardingUsernameView')
            navigation.dispatch(NavActions.switchToOnboardingUsername)
          } else if (_.has(response, 'missing') && response.missing === 'store') {
            // navigation.navigate('OnboardingFirstStoreNameView')
            navigation.dispatch(NavActions.switchToOnboardingFirstStore)
          } else if (_.has(response, 'missing') && response.missing === 'email') {
            // navigation.navigate('OnboardingEmailView')
            navigation.dispatch(NavActions.switchToOnboardingEmail({ email: response.default || '' }))
          } else {
            // FIXME: nav ไป main
            // navigation.dispatch(NavActions.switchToAuthStackNavigator)
            navigation.dispatch(NavActions.resetToAuthStackNavigator())
          }
        } else {
          await delay(1500)
          formActions.setSubmitting(false)
        }
      } else {
        throw new Error('Its no have the expecting values.')
      }
    } catch (err) {
      // log('handleSubmitForm::err')
      // log(err)
      await delay(1500)
      formActions.setSubmitting(false)
    }
  }
}
