import React from 'react'

import _ from 'lodash'

import { IDateRange, IXDateRangeSelectorOptionKey, IXDateRangeSelectorOption, IXDateRangeSelectorProps } from 'x/index'
import CONS from 'x/config/constants'
import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'

import VStack from './VStack'
import HStack from './HStack'
import XButton from './XButton'
import XText from './XText'
import XDateRangePicker from './XDateRangePicker'
import XHorizontalScrollView from './XHorizontalScrollView'

// import { } from 'x/utils/time'

// const SELECTOR_OPTION = {
//   Today: 'Today',
//   Yesterday: 'Yesterday',
//   Last7Days: 'Last7Days',
//   Last30Days: 'Last30Days',
//   ThisWeekStartWithSun: 'ThisWeekStartWithSun',
//   ThisWeekStartWithMon: 'ThisWeekStartWithMon',
//   LastWeekStartWithSun: 'LastWeekStartWithSun',
//   LastWeekStartWithMon: 'LastWeekStartWithMon',
//   ThisMonth: 'ThisMonth',
//   LastMonth: 'LastMonth',
//   Custom: 'Custom',
// }

interface IXDateRangeSelectorState {
  // selectedDates: IDateRange
  // selectedOptionKey: IXDateRangeSelectorOptionKey
  // [key: string]: any
  isInitialized: boolean
}

// const SELECTOR_OPTIONS: IXDateRangeSelectorOption[] = [
//   { key: SELECTOR_OPTION.Today, label: 'Today' },
//   { key: SELECTOR_OPTION.Yesterday, label: 'Yesterday' },
//   { key: SELECTOR_OPTION.Last7Days, label: 'Last7Days' },
//   { key: SELECTOR_OPTION.Last30Days, label: 'Last30Days' },
//   { key: SELECTOR_OPTION.ThisWeekStartWithSun, label: 'ThisWeekStartWithSun' },
//   { key: SELECTOR_OPTION.ThisWeekStartWithMon, label: 'ThisWeekStartWithMon' },
//   { key: SELECTOR_OPTION.LastWeekStartWithSun, label: 'LastWeekStartWithSun' },
//   { key: SELECTOR_OPTION.LastWeekStartWithMon, label: 'LastWeekStartWithMon' },
//   { key: SELECTOR_OPTION.ThisMonth, label: 'ThisMonth' },
//   { key: SELECTOR_OPTION.LastMonth, label: 'LastMonth' },
//   { key: SELECTOR_OPTION.Custom, label: 'Custom' },
// ]

const { OPTION: SELECTOR_OPTION } = CONS.DATERANGE_SELECTOR

const SELECTOR_OPTION_KEYS = Object.keys(SELECTOR_OPTION) as IXDateRangeSelectorOptionKey[]
const SELECTOR_OPTIONS: IXDateRangeSelectorOption[] = SELECTOR_OPTION_KEYS.map((optKey) => ({
  key: SELECTOR_OPTION[optKey],
  label: p.op.t(`dateRangeSelector.options.${SELECTOR_OPTION[optKey]}`),
}))

class XDateRangeSelector extends React.Component<IXDateRangeSelectorProps, IXDateRangeSelectorState> {
  static displayName = 'XDateRangeSelector'

  constructor(props) {
    super(props)

    this.state = {
      // selectedDates: this._getDateRangeFromOption(SELECTOR_OPTIONS[0].key),
      // selectedOptionKey: SELECTOR_OPTIONS[0].key,
      isInitialized: false,
    }
  }

  async componentDidMount(): Promise<void> {
    try {
      const { selectedDates, selectedOptionKey = SELECTOR_OPTION.Today, onChange } = this.props
      if (!selectedDates || !selectedOptionKey) {
        onChange({
          dates: this._getDateRangeFromOption(selectedOptionKey),
          option: this._getOptionByOptionKey(selectedOptionKey),
          optionKey: selectedOptionKey,
        })
      }
    } catch (err) {
      console.log('XDateRangeSelector::componentDidMount err => ', err)
    }
    await util.delay(100)
    this.setState({ isInitialized: true })
  }

  _getOptionByOptionKey = (key: IXDateRangeSelectorOptionKey) => SELECTOR_OPTIONS.find((so) => so.key === key)

  _onChangeDate = (newDates: IDateRange) => {
    // console.log('_onChangeDate newDates => ', newDates)
    // this.setState({ selectedDates: newDates, selectedOptionKey: SELECTOR_OPTION.Custom })
    try {
      const { onChange } = this.props
      onChange({ dates: newDates, option: this._getOptionByOptionKey(SELECTOR_OPTION.Custom), optionKey: SELECTOR_OPTION.Custom })
    } catch (err) {
      console.log('XDateRangeSelector::_onChangeDate err => ', err)
    }
  }

  _onChangeSelectorOption = (newOpt: IXDateRangeSelectorOption) => {
    // const newState: any = { selectedOptionKey: newOpt.key }

    // const dateRangeFromKey = this._getDateRangeFromOption(newOpt.key)
    // console.log('_onChangeSelectorOption dateRangeFromKey => ', dateRangeFromKey)
    // if (_.isObject(dateRangeFromKey)) {
    //   newState.selectedDates = dateRangeFromKey
    // }

    // this.setState(newState)

    try {
      const { onChange, selectedDates } = this.props
      const dateRangeFromKey = this._getDateRangeFromOption(newOpt.key)

      const params = {
        dates: _.isObject(dateRangeFromKey) ? dateRangeFromKey : selectedDates,
        option: this._getOptionByOptionKey(newOpt.key),
        optionKey: newOpt.key,
      }

      onChange(params)
    } catch (err) {
      console.log('XDateRangeSelector::_onChangeSelectorOption err => ', err)
    }
  }

  _getDateRangeFromOption = (optKey: IXDateRangeSelectorOptionKey): IDateRange | null => util.getDateRangeFromOption(optKey)
  // const now = dayjs()
  // const dr: IDateRange = {
  //   begin: now.clone(),
  //   end: now.clone(),
  // }

  // switch (optKey) {
  //   case SELECTOR_OPTION.Today: {
  //     dr.begin = now.clone().startOf('day')
  //     dr.end = now.clone().endOf('day')
  //     break
  //   }
  //   case SELECTOR_OPTION.Yesterday: {
  //     const yesterday = now.clone().subtract(1, 'days')
  //     dr.begin = yesterday.clone().startOf('day')
  //     dr.end = yesterday.clone().endOf('day')
  //     break
  //   }
  //   case SELECTOR_OPTION.Last7Days: {
  //     const last7date = now.clone().subtract(6, 'days')
  //     dr.begin = last7date.clone().startOf('day')
  //     dr.end = now.clone().endOf('day')
  //     break
  //   }
  //   case SELECTOR_OPTION.Last30Days: {
  //     const last30date = now.clone().subtract(29, 'days')
  //     dr.begin = last30date.clone().startOf('day')
  //     dr.end = now.clone().endOf('day')
  //     break
  //   }
  //   case SELECTOR_OPTION.ThisWeekStartWithSun: {
  //     // dr.begin = now.clone().startOf('week')
  //     // dr.end = now.clone().endOf('week')
  //     dr.begin = now.clone().startOf('week')
  //     dr.end = now.clone().endOf('day')
  //     break
  //   }
  //   case SELECTOR_OPTION.ThisWeekStartWithMon: {
  //     // dr.begin = now.clone().startOf('isoWeek')
  //     // dr.end = now.clone().endOf('isoWeek')
  //     dr.begin = now.clone().startOf('isoWeek')
  //     dr.end = now.clone().endOf('day')
  //     break
  //   }
  //   case SELECTOR_OPTION.LastWeekStartWithSun: {
  //     const lastWeekDate = now.clone().subtract(1, 'weeks')
  //     dr.begin = lastWeekDate.clone().startOf('week')
  //     dr.end = lastWeekDate.clone().endOf('week')
  //     break
  //   }
  //   case SELECTOR_OPTION.LastWeekStartWithMon: {
  //     const lastWeekDate = now.clone().subtract(1, 'weeks')
  //     dr.begin = lastWeekDate.clone().startOf('isoWeek')
  //     dr.end = lastWeekDate.clone().endOf('isoWeek')
  //     break
  //   }
  //   case SELECTOR_OPTION.ThisMonth: {
  //     // dr.begin = now.clone().startOf('month')
  //     // dr.end = now.clone().endOf('month')
  //     dr.begin = now.clone().startOf('month')
  //     dr.end = now.clone().endOf('day')
  //     break
  //   }
  //   case SELECTOR_OPTION.LastMonth: {
  //     const lastMonthDate = now.clone().subtract(1, 'months')
  //     dr.begin = lastMonthDate.clone().startOf('month')
  //     dr.end = lastMonthDate.clone().endOf('month')
  //     break
  //   }
  //   case SELECTOR_OPTION.Custom: {
  //     return null
  //     // break
  //   }
  //   default:
  //     return null
  // }

  // return dr

  renderSelectorOptions = () => {
    const { isInitialized } = this.state

    if (!isInitialized) {
      return null
    }

    const { isOptionsScrollable, allowDateRangeLimitDays } = this.props

    if (isOptionsScrollable) {
      return (
        <XHorizontalScrollView w='full' pt='1'>
          {SELECTOR_OPTIONS.map(this.renderSelectorOption)}
        </XHorizontalScrollView>
      )
    }

    return (
      <HStack w='full' pt='1' space='0.5' flexWrap='wrap'>
        {SELECTOR_OPTIONS.map(this.renderSelectorOption)}
      </HStack>
    )
  }

  renderSelectorOption = (opt: IXDateRangeSelectorOption, index: number) => {
    const { selectedOptionKey, disabled, isOptionsScrollable, allowDateRangeLimitDays } = this.props
    const isSelected = opt.key === selectedOptionKey

    if (opt.key === SELECTOR_OPTION.Last90Days && allowDateRangeLimitDays < 90) {
      return null
    }
    if (opt.key === SELECTOR_OPTION.Last60Days && allowDateRangeLimitDays < 60) {
      return null
    }
    if (opt.key === SELECTOR_OPTION.Last45Days && allowDateRangeLimitDays < 45) {
      return null
    }
    if (
      _.includes([SELECTOR_OPTION.LastMonth, SELECTOR_OPTION.ThisMonth, SELECTOR_OPTION.Last30Days], opt.key) &&
      allowDateRangeLimitDays < 30
    ) {
      return null
    }

    return (
      <XButton
        disabled={disabled}
        isDisabled={disabled}
        key={`SelectorOpt_${index}_${opt.key}`}
        variant='outline'
        mb='1'
        mr={isOptionsScrollable ? '1' : undefined}
        colorScheme={isSelected && !disabled ? 'primary' : 'muted'}
        onPress={() => this._onChangeSelectorOption(opt)}>
        <XText>{opt.label}</XText>
      </XButton>
    )
  }

  renderDateRangePicker = () => {
    const { isInitialized } = this.state

    if (!isInitialized) {
      return null
    }

    const { selectedOptionKey, onChange, ...restProps } = this.props

    return (
      <HStack w='full'>
        <XDateRangePicker {...restProps} onChangeDate={this._onChangeDate} />
      </HStack>
    )
  }

  render() {
    return (
      <VStack w='full' px='2' py='1' space='2' flexWrap='wrap'>
        {this.renderSelectorOptions()}
        {this.renderDateRangePicker()}
      </VStack>
    )
  }
}

export default XDateRangeSelector
