import React from 'react'
// import { InfiniteListContextProvider } from './InfiniteListStateContext'
import { InfiniteListContextProvider, useInfiniteListContext } from './InfiniteListStateContext'
import InfiniteListBase, { IInfiniteListProps } from './InfiniteListBase'

const InfiniteListWithContextAndRef = React.forwardRef((props: any, ref) => {
  const context = useInfiniteListContext()
  return <InfiniteListBase<any> context={context} {...props} ref={ref} />
})

const InfiniteListContainer = React.forwardRef((props: any, ref) => {
  return (
    <InfiniteListContextProvider>
      <InfiniteListWithContextAndRef {...props} ref={ref} />
    </InfiniteListContextProvider>
  )
}) as <T>(p: IInfiniteListProps<T> & { ref?: React.RefObject<InfiniteListBase<T>> }) => React.ReactElement
// https://stackoverflow.com/questions/58469229/react-with-typescript-generics-while-using-react-forwardref

export default InfiniteListContainer
