import React from 'react'
import Box from 'xui/components/Box'
import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import { TouchableOpacity, View, Linking } from 'react-native'
import _ from 'lodash'

import XCustomHeader from 'xui/components/XCustomHeader'

import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'

import BaseStoreSettingPaperang from 'x/modules/store/BaseStoreSettingPaperang'
// import xCONS from 'x/config/constants'
import HelpButton from 'xui/components/HelpButton'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import XText from 'xui/components/XText'
import XSwitch from 'xui/components/XSwitch'
import XCard from 'xui/components/XCard'
import XIcon from 'xui/components/XIcon'
import XButton from 'xui/components/XButton'
import BackIcon from '../../components/BackIcon'

const HELP_BTN_PRTING = {
  1121: {
    component: (
      <HelpButton
        key={1121}
        message='รองรับรูปแนบใบปะหน้าพัสดุ เช่น จาก Shopee หรือ Lazada'
        style={{ alignItems: 'center', justifyContent: 'center' }}
      />
    ),
  },
  1122: {
    component: (
      <HelpButton
        key={1122}
        message='รองรับรูปแนบใบปะหน้าพัสดุ เช่น จาก Shopee หรือ Lazada โดยหมุนรูปแนบเป็นแนวนอน เพื่อประหยัดกระดาษ'
        style={{ alignItems: 'center', justifyContent: 'center' }}
      />
    ),
  },
}

export default abstract class BaseUIStoreSettingPaperangView extends BaseStoreSettingPaperang {
  static displayName = 'BaseUIStoreSettingPaperangView'

  // abstract renderMain?(): JSX.Element

  renderHeader = () => (
    <XCustomHeader
      title={p.op.t('StoreSetting.HeaderPaperangShippingLabel')}
      renderHeaderLeft={this._renderHeaderLeft}
      renderHeaderRight={this._renderHeaderRight}
    />
  )

  _renderHeaderRight = () => {
    const params = util.getNavParams(this.props)
    const cancelBtn = params ? params.cancelBtn : null
    const onChange = params ? params.onChange : null
    return (
      <View>
        {!_.isNil(cancelBtn) && !_.isNil(onChange) && onChange ? (
          <TouchableOpacity style={{ width: 50, height: 40, justifyContent: 'center', paddingRight: 4 }} onPress={() => cancelBtn()}>
            <XText variant='primary'>{p.op.t('Order.cancelled')}</XText>
          </TouchableOpacity>
        ) : null}
      </View>
    )
  }

  _renderHeaderLeft = () => {
    const params = util.getNavParams(this.props)
    const saveBtn = params ? params.saveBtn : null
    const onChange = params ? params.onChange : null
    return (
      <View>
        {!_.isNil(saveBtn) && !_.isNil(onChange) && onChange ? (
          <TouchableOpacity style={{ width: 50, height: 40, justifyContent: 'center', paddingLeft: 10 }} onPress={() => saveBtn()}>
            <XText variant='primary'>{p.op.t('save')}</XText>
          </TouchableOpacity>
        ) : (
          <TouchableOpacity
            style={{ width: 50, height: 40, alignItems: 'center', justifyContent: 'center' }}
            onPress={() => this._goBack()}>
            <BackIcon />
          </TouchableOpacity>
        )}
      </View>
    )
  }

  _renderTextAndSwitch = (textLabel: string, value: boolean) => (
    <HStack alignItems='center' justifyContent='space-between'>
      <XText>{textLabel}</XText>
      <XSwitch value={value} onValueChange={(val) => this.onChangeValue('isActive', val)} />
    </HStack>
  )

  _renderListOptions = (text: string, key: string, value: boolean) => (
    <TouchableOpacity onPress={() => this.onChangeValue(key, !value)}>
      <HStack py='2'>
        <XIcon name={value ? 'check-square-o' : 'square-o'} family='FontAwesome' />
        <XText variant='active' pl='2'>
          {text}
        </XText>
      </HStack>
    </TouchableOpacity>
  )

  _goBack = () => {
    util.navGoBack(this.props)
  }

  _rendeRadioPaperangTypeList = () => {
    const { printSetting, printingTypeId } = this.state
    if (_.isNil(printSetting)) {
      return null
    }
    return this.PAPERRANG_TYPE.map((data: { id: number; url: string; txt: string }, index: number) => {
      const checked = printingTypeId === data.id
      let HELP_BTN = HELP_BTN_PRTING[data.id]
      if (!_.isNil(HELP_BTN)) {
        HELP_BTN = HELP_BTN.component
      }
      return (
        // eslint-disable-next-line react/no-array-index-key
        <TouchableOpacity key={`PaperangTypeList${index}`} onPress={() => this.onChangeSelectedValue(data)}>
          <HStack w='full'>
            <Box style={{ width: 20, alignItems: 'center', justifyContent: 'center' }}>
              <XIcon name={checked ? 'radio-button-on' : 'radio-button-off'} family='Ionicons' />
            </Box>
            <Box style={{ flex: 1 }}>
              <XText pl='2' variant='active'>
                {data.txt}
              </XText>
            </Box>
            {_.isNil(HELP_BTN) ? (
              <Box w='10' />
            ) : (
              <Box alignItems='center' alignSelf='center'>
                {HELP_BTN}
              </Box>
            )}
            {_.isNil(data.url) ? null : (
              <XButton
                onPress={() => Linking.openURL(data.url)}
                alignSelf='center'
                size='sm'
                style={{ width: 80, height: 35 }}
                variant='outline'>
                ดูตัวอย่าง
              </XButton>
            )}
          </HStack>
        </TouchableOpacity>
      )
    })
  }

  _renderOnOffSetting = () => {
    const { isActive } = this.state
    return (
      <XCard p='4' borderRadius='xl' w='full'>
        {this._renderTextAndSwitch(p.op.t('enabled'), isActive)}
      </XCard>
    )
  }

  _renderShippingLabel = () => {
    const { autoSavePaperang, isActive } = this.state
    if (!isActive) {
      return null
    }
    return (
      <XCard p='4' borderRadius='xl' w='full'>
        <XText variant='active' bold>
          {p.op.t('ShippingLabel')}
        </XText>
        {this._renderListOptions(p.op.t('AddressList.TitleByMode.PAPERANG_SHIPPING_LABLE_AUTO_SAVE'), 'autoSavePaperang', autoSavePaperang)}
      </XCard>
    )
  }

  _renderOtherData = () => {
    const {
      showSenderNameAndAddress,
      showProductListWhenNoShippingLabelImage,
      printingTypeId,
      showProductListWhenHasShippingLabelImage,
      isActive,
    } = this.state
    if (!isActive) {
      return null
    }
    const isShowTextshowProductListWhenNoShippingLabelImage =
      printingTypeId === 57 ? p.op.t('Order.productList') : p.op.t('printing.showProductListWhenNoShippingLabelImage')
    const hideShowProductListWhenHasShippingLabelImage = printingTypeId === 57
    return (
      <XCard p='4' borderRadius='xl' w='full'>
        <VStack w='full'>
          <XText variant='active' bold>
            แสดงข้อมูลเพิ่มเติม
          </XText>
          {this._renderListOptions(
            p.op.t('AddressList.TitleByMode.SENDER_NAME_AND_ADDRESS'),
            'showSenderNameAndAddress',
            showSenderNameAndAddress
          )}
          {this._renderListOptions(
            isShowTextshowProductListWhenNoShippingLabelImage,
            'showProductListWhenNoShippingLabelImage',
            showProductListWhenNoShippingLabelImage
          )}
          {hideShowProductListWhenHasShippingLabelImage
            ? null
            : this._renderListOptions(
                p.op.t('printing.showProductListWhenHasShippingLabelImage'),
                'showProductListWhenHasShippingLabelImage',
                showProductListWhenHasShippingLabelImage
              )}
        </VStack>
      </XCard>
    )
  }

  _renderShippingLabelList = () => {
    const { isActive } = this.state
    if (!isActive) {
      return null
    }
    return (
      <XCard p='4' borderRadius='xl' w='full'>
        <XText variant='active' bold>
          {`รูปแบบ${p.op.t('ShippingLabel')}`}
        </XText>
        <XText variant='inactive' bold>
          {p.op.t('detailInShippingLabelPDF')}
        </XText>
        <VStack space='3' mt='2' w='full'>
          {this._rendeRadioPaperangTypeList()}
        </VStack>
      </XCard>
    )
  }

  renderContent = () => (
    <VStack w='full' p='2' space='2'>
      {this._renderOnOffSetting()}
      {this._renderShippingLabel()}
      {this._renderShippingLabelList()}
      {this._renderOtherData()}
    </VStack>
  )

  render() {
    return (
      <XContainer>
        {this.renderHeader()}
        <XContent>{this.renderContent()}</XContent>
      </XContainer>
    )
  }
}
