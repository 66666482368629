import { Component } from 'react'
// import { NavigationScreenProp, NavigationState } from 'react-navigation'
import _ from 'lodash'
import CONS from 'x/config/constants'
import * as util from 'x/utils/util'
import { ISelectedStoreMap, IProfile, ActionApiParams } from 'x/index'
import p from 'x/config/platform-specific'

const { VIEW, EDIT } = CONS.MODE
interface IBaseXProfileViewProps {
  selectedStore: ISelectedStoreMap
  profile: IProfile
  isShowEditProfileBtn?: boolean // Default = false
  onPressEditProfileBtn?: () => void // isShowEditProfileBtn = true => onPressEditProfileBtn is Active
  mode?: string // (VIEW, EDIT) Default = VIEW
  /// //// onSubmit ต้องส่ง onSubmitCallBack มาด้วยเสอ ////////
  onSubmit?: (params: ActionApiParams) => void
  onSubmitCallBack?: (profile: IProfile) => void
  /// /////////////////////////////////////////////////////
}

interface IBaseXProfileViewState {
  // isImgUploading: boolean
  mode: string
  codeId: string
  nickName: string
  firstName: string
  lastName: string
  gender: number
  phone: string
  phone2: string
  email: string
  facebook: string
  line: string
  instagram: string

  profile: IProfile
}

export default class BaseXXProfileView extends Component<IBaseXProfileViewProps, IBaseXProfileViewState> {
  //   imgManagerRef: React.RefObject<any>
  isSetProfile: boolean

  protected constructor(props: IBaseXProfileViewProps) {
    super(props)
    // this.imgManagerRef = React.createRef()
    this.isSetProfile = false
    this.state = {
      // isImgUploading: false,
      mode: VIEW,
      codeId: '',
      nickName: '',
      firstName: '',
      lastName: '',
      gender: 0,
      phone: '',
      phone2: '',
      email: '',
      facebook: '',
      line: '',
      instagram: '',

      profile: null,
    }
  }

  // static getDerivedStateFromProps(nextProps: IBaseXProfileViewProps, prevState) {
  //   // do things with nextProps.someProp and prevState.cachedSomeProp
  //   if (!_.isNil(nextProps.profile)) {
  //     const newProfile = nextProps.profile
  //     return {
  //       profile: newProfile,
  //       // ... other derived state properties
  //     }
  //   }
  // }

  async componentDidMount() {
    await this._setProfile()
  }

  _setProfile = () => {
    // if (this.isSetProfile) {
    //   return
    // }
    // const { profile } = this.state
    const { mode, profile } = this.props
    if (_.isNil(profile)) {
      return
    }
    // this.isSetProfile = true

    let codeId = ''
    let nickName = ''
    let firstName = ''
    let lastName = ''
    let gender = 2
    let phone = ''
    let phone2 = ''
    let email = ''
    let facebook = ''
    let line = ''
    let instagram = ''

    if (!_.isNil(profile)) {
      codeId = ''
      nickName = _.isNil(profile.nick_name) ? '' : profile.nick_name
      firstName = _.isNil(profile.first_name) ? '' : profile.first_name
      lastName = _.isNil(profile.last_name) ? '' : profile.last_name
      gender = _.isNil(profile.gender) ? 2 : profile.gender === 'male' ? 0 : 1
      phone = _.isNil(profile.phone1) ? '' : profile.phone1
      phone2 = _.isNil(profile.phone2) ? '' : profile.phone2
      email = _.isNil(profile.contact_email) ? '' : profile.contact_email
      facebook = _.isNil(profile.fb_id) ? '' : profile.fb_id
      line = _.isNil(profile.line_id) ? '' : profile.line_id
      instagram = _.isNil(profile.instagram_id) ? '' : profile.instagram_id
    }

    util.setStatePromise(this, {
      mode: _.isNil(mode) ? VIEW : mode,
      codeId,
      nickName,
      firstName,
      lastName,
      gender,
      phone,
      phone2,
      email,
      facebook,
      line,
      instagram,
      profile,
    })
  }

  _onSubmit = async () => {
    const { onSubmit = null, onSubmitCallBack = null } = this.props
    if (_.isNil(onSubmit)) {
      return
    }
    const {
      // profile,
      firstName,
      lastName,
      phone,
      phone2,
      gender,
      email,
      facebook,
      line,
      instagram,
      nickName,
    } = this.state

    const { profile } = this.props

    const firstNameTrim = firstName.trim()
    const lastNameTrim = lastName.trim()
    const phoneTrim = phone.trim()
    const phone2Trim = phone2.trim()
    const genderToString = gender === 0 ? 'male' : gender === 1 ? 'female' : `n/a`
    const emailTrim = email.trim()
    const facebookTrim = facebook.trim()
    const lineTrim = line.trim()
    const instagramTrim = instagram.trim()
    const nickNameTrim = nickName.trim()
    let isChange = false
    const res = await new Promise((resolve) => {
      const body: { [key: string]: any } = {
        org_id: 1,
        profile_id: profile.id,
      }
      if (profile.first_name !== firstNameTrim) {
        body.first_name = firstNameTrim
        isChange = true
      }
      if (profile.last_name !== lastNameTrim) {
        body.last_name = lastNameTrim
        isChange = true
      }
      if (profile.phone1 !== phoneTrim) {
        body.phone1 = phoneTrim
        isChange = true
      }
      if (profile.phone2 !== phone2Trim) {
        body.phone2 = phone2Trim
        isChange = true
      }
      if (profile.gender !== genderToString) {
        if (_.isNil(profile.gender) && genderToString === 'n/a') {
          //
        } else {
          body.gender = genderToString
          isChange = true
        }
      }
      if (profile.contact_email !== emailTrim) {
        body.contact_email = emailTrim
        isChange = true
      }
      if (profile.fb_id !== facebookTrim) {
        body.fb_id = facebookTrim
        isChange = true
      }
      if (profile.line_id !== lineTrim) {
        body.lime_id = lineTrim
        isChange = true
      }
      if (profile.instagram_id !== instagramTrim) {
        body.instagram_id = instagramTrim
        isChange = true
      }
      if (profile.nick_name !== nickNameTrim) {
        body.nick_name = nickNameTrim
        isChange = true
      }

      if (!isChange) {
        p.op.showConfirmationOkOnly(``, `ไม่มีข้อมูลเปลี่ยนแปลง`)
        return
      }

      // console.log(`BODY `, body)
      onSubmit({
        body,
        // @ts-ignore
        successCallback: resolve,
        // @ts-ignore
        failedCallback: resolve,
      })
    })

    // console.log(`SUBMIT REF => `, res)

    await util.setStatePromise(this, {
      mode: VIEW,
    })
    // @ts-ignore res ยังไม่ได้ประกาศ types
    if (res.profile) {
      if (_.isFunction(onSubmitCallBack)) {
        // @ts-ignore res ยังไม่ได้ประกาศ types
        onSubmitCallBack(res.profile)
      }
    }
  }

  _onPressRadioBtn = (key: string, index: number) => {
    util.setStatePromise(this, {
      [key]: index,
    })
  }
}
