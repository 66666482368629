import BaseUIReportPeakView from 'xui/modules/report/BaseUIReportPeakView'
import * as util from '../../utils/util-web'

export default class ReportPeakView extends BaseUIReportPeakView {
  _downloadReportStockView = async (url: string, fileName: string): Promise<void> => {
    if (url) {
      await util.downloadFileFromUrl({ url, fileName })
    }
  }
}
