import React from 'react'
import { Image, View, ImageSourcePropType } from 'react-native'

import FAQ from 'x/config/FAQ'
import * as util from 'x/utils/util'
import p from 'x/config/platform-specific'
import { COLORS } from 'x/config/styles'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContent from 'xui/components/XContent'
import XContainer from 'xui/components/XContainer'
import BaseStoreSettingXShippingKerryView from 'x/modules/xshipping/BaseStoreSettingXShippingKerryView'
import { VIEW_LOADING } from 'xui/utils/BaseShareUI'
import XSettingRow from 'xui/components/XSettingRow'
import XHelpModal from 'xui/components/XHelpModal'
import Box from 'xui/components/Box'
import XText from 'xui/components/XText'
import XButton from 'xui/components/XButton'
import XFaqModal from 'xui/components/XFaqModal'
import FAQ_CONSTANTS from 'x/config/FAQ_CONSTANTS'
import HStack from 'xui/components/HStack'

const IMG_KERRY: ImageSourcePropType = require('../../images/courier/kerry.png')

export default abstract class BaseUIStoreSettingXShippingView extends BaseStoreSettingXShippingKerryView {
  static displayName = 'BaseUIStoreSettingXShippingView'
  // abstract goBack: () => void

  _renderCustomHeader = () => (
    <XCustomHeader
      headerLeftProps={{
        backIcon: true,
        onPressItem: () => {
          util.navGoBack(this.props)
        },
      }}
      title={p.op.t('Order.shippingTypeItems.kerry')}
    />
  )

  _renderUseKerry = () => {
    const { useKerry, useAutoXShippingIsKerryReadyToShip, isKerryLoading, loadingKerryCODWidth, loadingKerryCODHeight } = this.state
    const textTitle = 'ใช้งานการขอเลขพัสดุ\n"แบบไม่เก็บเงินปลายทาง"'
    return (
      <View
        onLayout={(event) => {
          const { x, y, width, height } = event.nativeEvent.layout
          util.setStatePromise(this, { loadingKerryCODWidth: width, loadingKerryCODHeight: height })
        }}>
        <XSettingRow
          title={textTitle}
          value={useKerry}
          onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
          stateKey='useKerry'
          helper={{
            title: 'Kerry ออกเลขพัสดุ',
            FAQ: FAQ.XSHIPPING_NOT_COD,
            initiateOpenIndex: 0,
          }}
          backgroundColor={COLORS.APP_SECONDARY}
        />

        {useKerry ? (
          <XSettingRow
            title='ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง'
            value={useAutoXShippingIsKerryReadyToShip}
            onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
            stateKey='useAutoXShippingIsKerryReadyToShip'
            helper={{
              title: 'ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง',
              FAQ: FAQ.XSHIPPING_READY_TO_SHIP,
              initiateOpenIndex: 0,
            }}
            backgroundColor={COLORS.APP_SECONDARY}
          />
        ) : null}

        {isKerryLoading ? VIEW_LOADING(loadingKerryCODWidth, loadingKerryCODHeight) : null}
      </View>
    )
  }

  _renderUseKerryCOD = () => {
    const {
      useKerryCOD,
      useAutoXShippingIsKerryCODReadyToShip,
      loadingKerryWidth,
      loadingKerryHeight,
      isKerryCODLoading,
      useAutoCreateCODPaymentWhenDelivered,
    } = this.state
    const textTitle = 'ใช้งานการขอเลขพัสดุ\n"แบบเก็บเงินปลายทาง"'
    return (
      <View
        onLayout={(event) => {
          const { x, y, width, height } = event.nativeEvent.layout
          util.setStatePromise(this, { loadingKerryWidth: width, loadingKerryHeight: height })
        }}>
        <XSettingRow
          title={textTitle}
          value={useKerryCOD}
          onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
          stateKey='useKerryCOD'
          helper={{
            title: 'Kerry ออกเลขพัสดุ',
            FAQ: FAQ.XSHIPPING_COD,
            initiateOpenIndex: 0,
          }}
          backgroundColor={COLORS.APP_SECONDARY}
        />

        {useKerryCOD ? (
          <XSettingRow
            title='ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง'
            value={useAutoXShippingIsKerryCODReadyToShip}
            onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
            stateKey='useAutoXShippingIsKerryCODReadyToShip'
            helper={{
              title: 'ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง',
              FAQ: FAQ.XSHIPPING_READY_TO_SHIP,
              initiateOpenIndex: 0,
            }}
            backgroundColor={COLORS.APP_SECONDARY}
          />
        ) : null}

        {useKerryCOD ? (
          <XSettingRow
            title='บันทึกรับชำระอัตโนมัติเมื่อส่งพัสดุถึงผู้รับแล้ว'
            value={useAutoCreateCODPaymentWhenDelivered}
            onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
            stateKey='useAutoCreateCODPaymentWhenDelivered'
            helper={{
              title: 'บันทึกรับชำระอัตโนมัติเมื่อส่งพัสดุถึงผู้รับแล้ว',
              FAQ: FAQ.XSHIPPING_AUTO_CREATE_COD_PAYMENT_WHEN_DELIVERED,
              initiateOpenIndex: 0,
            }}
            backgroundColor={COLORS.APP_SECONDARY}
          />
        ) : null}
        {isKerryCODLoading ? VIEW_LOADING(loadingKerryWidth, loadingKerryHeight) : null}
      </View>
    )
  }

  _renderAgreement = (onPressOpenOverlay: () => void) => (
    <XButton onPress={() => onPressOpenOverlay()} w='48' variant='outline' alignSelf='center'>
      <XText variant='primary'>ดูข้อตกลงและวิธีการใช้งาน</XText>
    </XButton>
  )

  _renderHowtoFaq = (onPressOpenOverlay: () => void) => (
    <HStack flex={1} alignItems='center' justifyContent='center' mt='2'>
      <XText textDecorationLine='underline' color={COLORS.BRAND_Info} onPress={() => onPressOpenOverlay()}>
        ดูคลิปแนะนำวิธีเปิดใช้งานขอเลขส่ง Kerry Express
      </XText>
    </HStack>
  )

  _renderKerryDirect = () => (
    <Box
      _light={{ bg: 'white' }}
      _dark={{ bg: 'gray.500' }}
      borderRadius='md'
      style={{
        // backgroundColor: COLORS.WHITE,
        marginTop: 10,
        marginRight: 6,
        marginLeft: 6,
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 6,
        paddingBottom: 15,
      }}>
      <Box style={{ justifyContent: 'center', alignItems: 'center' }}>
        <Image
          source={IMG_KERRY}
          resizeMode='stretch'
          style={{
            // position: 'absolute',
            width: 164,
            height: 68,
            backgroundColor: 'transparent',
            zIndex: 70,
          }}
        />
      </Box>
      <Box style={{ justifyContent: 'center', alignItems: 'center' }}>
        <XText variant='active'>ขอเลขพัสดุจาก Kerry Express โดยตรง</XText>
      </Box>

      <XHelpModal
        key='XHelpModalFromXSettingRow'
        headerTitle='ข้อตกลงและวิธีการใช้งาน'
        FAQ={FAQ.XSHIPPING_KERRY_AGREEMENT}
        initiateOpenIndex={4}
        renderButton={(onPressOpenOverlay: () => void) => this._renderAgreement(onPressOpenOverlay)}
      />

      <XFaqModal
        key='XHelpModalKerryExpress_HowTo'
        headerTitle='ดูคลิปแนะนำวิธีเปิดใช้งานขอเลขส่ง Kerry Express'
        FAQ={FAQ_CONSTANTS.FAQ_KERRY_EXPRESS_XSHIPPING}
        initiateOpenIndex={0}
        renderButton={(onPressOpenOverlay: () => void) => this._renderHowtoFaq(onPressOpenOverlay)}
      />

      <Box style={{ height: 10 }} />
      <Box style={{ paddingBottom: 2, flexDirection: 'row' }}>
        <XText variant='inactive'>{'รูปแบบจัดส่ง: '}</XText>
        <XText variant='active' bold>{`${p.op.t('Order.shippingTypeItems.kerry')}`}</XText>
      </Box>
      {this._renderUseKerry()}
      <Box style={{ height: 10 }} />
      <Box style={{ paddingBottom: 2, flexDirection: 'row' }}>
        <XText variant='inactive'>{'รูปแบบจัดส่ง: '}</XText>
        <XText variant='active' bold>{`${p.op.t('Order.shippingTypeItems.kerry_cod')}`}</XText>
      </Box>
      {this._renderUseKerryCOD()}
    </Box>
  )

  render() {
    return (
      <XContainer>
        <XContent>
          {this._renderCustomHeader()}
          {this._renderKerryDirect()}
        </XContent>
      </XContainer>
    )
  }
}
