import React from 'react'
import XContent from 'xui/components/XContent'
import XCustomHeader from 'xui/components/XCustomHeader'
import BaseAssistantGroupListView, { IPermission } from 'x/modules/store/Assistant/BaseAssistantGroupListView'
import XContainer from 'xui/components/XContainer'
import { View, TouchableOpacity } from 'react-native'
import XText from 'xui/components/XText'
import _ from 'lodash'
import XCard from 'xui/components/XCard'
import Box from 'xui/components/Box'
import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import XIcon from 'xui/components/XIcon'
import * as NavActions from 'x/utils/navigation'
import XQuickInfoButton from 'xui/components/XQuickInfoButton'
import * as util from 'x/utils/util'
import XButton from 'xui/components/XButton'
import XHelpModal from 'xui/components/XHelpModal'
import HelpButton from 'xui/components/HelpButton'
import FAQ from 'x/config/FAQ'

// const QuickInfoButton = (props) => {
//   let fontLableTopSize = 'md'
//   if (!_.isNil(props.labelTopsize)) {
//     fontLableTopSize = props.labelTopsize
//   }
//   return (
//     <Box flex={1}>
//       <TouchableOpacity
//         onPress={props.onPress}
//         style={{
//           flex: 1,
//           backgroundColor: COLORS.BG_LIGHT_GREY,
//           borderColor: COLORS.TEXT_INACTIVE,
//           borderWidth: 0.5,
//           borderRadius: 7,
//           height: 55,
//         }}>
//         <Box alignItems='center' h='7' pt='1'>
//           <XText variant='active' fontSize={fontLableTopSize}>
//             {props.labelTop}
//           </XText>
//         </Box>
//         <HStack alignItems='center' justifyContent='center'>
//           <XIcon name={props.iconName} family={props.iconFamily} variant='inactive' />
//           <XText variant='inactive' pl='2'>
//             {props.label}
//           </XText>
//         </HStack>
//       </TouchableOpacity>
//     </Box>
//   )
// }

export default abstract class BaseUIAssistantGroupListView extends BaseAssistantGroupListView {
  // abstract goBack: () => void

  renderCustomHeader = () => (
    <XCustomHeader
      headerLeftProps={{
        backIcon: true,
        onPressItem: () => util.navGoBack(this.props),
      }}
      title='รายการสิทธิ์การใช้งาน'
      renderHeaderRight={this._renderHeaderRight}
    />
  )

  // <XIconButton
  //       name='plus'
  //       family='AntDesign'
  //       onPress={() => {
  //         dispatch(
  //           NavActions.navToAssistantGroupViewContainer({
  //             mode: 'CREATE',
  //             callBack: (val?: boolean) => this._callBackCreateGroupView(val),
  //             permission: null,
  //           })
  //         )
  //       }}
  //     />

  _renderHeaderRight = () => {
    const { dispatch } = this.props.navigation
    const _renderAgreement = (onPressOpenOverlay: () => void) => <HelpButton onPress={() => onPressOpenOverlay()} />
    return (
      <HStack>
        <XHelpModal
          key='กำหนดสิทธิ์การใช้งานให้ผู้ช่วย'
          headerTitle='กำหนดสิทธิ์การใช้งานให้ผู้ช่วย'
          FAQ={FAQ.HELPER_FAQ}
          initiateOpenIndex={[0]}
          renderButton={(onPressOpenOverlay: () => void) => _renderAgreement(onPressOpenOverlay)}
        />
        <XButton
          variant='ghost'
          leftIcon={<XIcon family='AntDesign' name='plus' />}
          onPress={() =>
            dispatch(
              NavActions.navToAssistantGroupViewContainer({
                mode: 'CREATE',
                callBack: (val?: boolean) => this._callBackCreateGroupView(val),
                permission: null,
              })
            )
          }
        />
      </HStack>
    )
  }

  // _handleRightBtn = () => {
  //   const { dispatch } = this.props.navigation
  //   dispatch(
  //     NavActions.navToAssistantGroupViewContainer({
  //       mode: 'CREATE',
  //       callBack: (val?: boolean) => this._callBackCreateGroupView(val),
  //       permission: null,
  //     })
  //   )
  // }

  _renderNoBody = () => {
    const { permissions } = this.state
    if (_.isNil(permissions)) {
      return (
        <View style={{ marginTop: 10, marginLeft: 10 }}>
          <XText variant='active'>กำลงัโหลด...</XText>
        </View>
      )
    }
    return (
      <View style={{ marginTop: 10, marginLeft: 10 }}>
        <XText variant='active'>
          คุณสามารถเริ่มกำหนดสิทธิ์การใช้งานของแต่ละผู้ช่วย ได้โดยการเริ่มต้นสร้างสิทธิ์การใช้งานก่อน โดยกดที่ปุ่ม + ที่ขวาบนของหน้านี้
        </XText>
        <XText variant='active'>{`\nเมื่อสร้างสิทธิ์การใช้งานแล้ว คุณสามารถเพิ่มผู้ช่วยสำหรับแต่ละสิทธิ์การใช้งานได้`}</XText>
      </View>
    )
  }

  _renderHeaderCard = (data: IPermission) => {
    // console.log('data => ', data)
    const { dispatch } = this.props.navigation
    return (
      <Box flex={1} flexDirection='row'>
        <Box flex={1}>
          <XText variant='active' bold>
            {data.name}
          </XText>
          <XText variant='inactive' numberOfLines={1}>
            {data.description}
          </XText>
        </Box>
        <TouchableOpacity
          onPress={() => {
            dispatch(
              NavActions.navToAssistantGroupViewContainer({
                mode: 'VIEW',
                callBack: (val: boolean) => this._callBackCreateGroupView(val),
                permission: data,
              })
            )
          }}
          style={{ width: 40, height: 40, justifyContent: 'flex-start', alignItems: 'flex-end' }}>
          <XIcon family='FontAwesome' name='gear' variant='primary' />
        </TouchableOpacity>
      </Box>
    )
  }

  _renderQuickInfoButton = (data: IPermission) => {
    const { dispatch } = this.props.navigation
    const { permissions } = this.state
    return (
      <HStack w='full' space='2'>
        <XQuickInfoButton
          onPress={() =>
            dispatch(
              NavActions.navToAssistantMember({
                callBack: (val: boolean) => this._callBackMemberView(val),
                tabIndex: 0,
                permissionList: permissions,
                permission_id: data.id,
              })
            )
          }
          labelTop={`${data.helper_count}`}
          label='รายชื่อผู้ช่วย'
          iconName='people'
          iconFamily='Ionicons'
        />
        <XQuickInfoButton
          onPress={() =>
            dispatch(
              NavActions.navToAssistantMember({
                callBack: (val: boolean) => this._callBackMemberView(val),
                tabIndex: 1,
                permissionList: permissions,
                permission_id: data.id,
              })
            )
          }
          iconTopName='plus'
          iconTopFamily='MaterialCommunityIcons'
          label='เพิ่มผู้ช่วย'
          iconName='person-add'
          iconFamily='Ionicons'
        />
      </HStack>
    )
  }

  _renderBody = () => {
    const { permissions } = this.state
    if (_.isNil(permissions) || permissions.length < 1) {
      return this._renderNoBody()
    }
    // this._checkFetchPermisstion()
    // eslint-disable-next-line array-callback-return
    return (
      <VStack w='full' p='2' space='2'>
        {permissions.map((data: IPermission) => (
          <XCard p='2' w='full' key={data.id}>
            {this._renderHeaderCard(data)}
            {this._renderQuickInfoButton(data)}
          </XCard>
        ))}
      </VStack>
    )
  }

  render() {
    // return <Box />
    return (
      <XContainer>
        {this.renderCustomHeader()}
        <XContent>{this._renderBody()}</XContent>
      </XContainer>
    )
  }
}
