import React from 'react'
import _ from 'lodash'
import { View, StyleSheet, Image, ImageBackground, TextInput } from 'react-native'
// import FacebookLogin from 'react-facebook-login'

import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import { Formik, FormikHelpers, FormikValues } from 'formik'
import Yup, { EMAIL, PASSWORD_LOGIN } from 'x/utils/validator'
import actions from 'x/config/actions'
import { getAuthenticationToken, setAuthenticationToken, setFBAccessToken } from 'x/utils/authentication'
import XInput from 'xui/components/XInput'
import XContainer from 'xui/components/XContainer'
import { STYLES, COLORS, S } from 'x/config/styles'

import p from 'x/config/platform-specific'
import settings from 'x/config/settings'
import { delay } from 'x/utils/util'
import { WEB_RUNTIME } from 'x/config/app/generated-runtime-web-config'
import XButton from 'xui/components/XButton'
import XCard from 'xui/components/XCard'
import XText from 'xui/components/XText'
import * as xUtil from 'x/utils/util'
import { IAnyObject, IXScreenProps } from 'x/index'
import PdpaConfirmation from 'xui/components/singleton/PdpaConfirmation'
import * as NavActions from 'x/utils/navigation'
import XIcon from 'xui/components/XIcon'
import { xtoken } from '../../../scripts/devtools/xtoken'
import { switchToLandingView } from '../../utils/navigation'
import { isChromium } from '../../utils/util-web'

interface ILoginViewProps extends IXScreenProps {
  dispatch: (params: any) => void
}

interface ILoginViewState {
  devToolText1: string
  devToolText2: string
}

interface ILoginFormValues {
  email: string
  password: string
}

const LoginSchema = Yup.object().shape({
  email: EMAIL,
  password: PASSWORD_LOGIN,
})

const Item: React.FC<any> = (props) => <HStack {...props} />

const isImpersonateMode = (process && process.env && process.env.REACT_APP_IMPERSONATE) || false

export default class LoginView extends React.Component<ILoginViewProps, ILoginViewState> {
  static navigationOptions: () => {
    headerBackTitle: null
    title: `LoginView`
  }

  // forminkRef: React.RefObject<Formik<{ email: string; password: string }>>

  passwordInputRef: React.RefObject<TextInput>

  _inProcess?: boolean

  constructor(props) {
    super(props)
    // this.forminkRef = React.createRef()
    this.passwordInputRef = React.createRef()

    this.state = {
      devToolText1: '',
      devToolText2: '',
    }
  }

  async componentDidMount() {
    try {
      // TODO: Refresh token
      const token = await getAuthenticationToken()
      if (token) {
        this._navToLandingView()
        return
      }
      if (isImpersonateMode) {
        const devToolText1 = await p.op.storageGet('devToolText1', false)
        const devToolText2 = await p.op.storageGet('devToolText2', false)
        // @ts-ignore
        this.setState({ devToolText1, devToolText2 })
      }
    } catch (error) {}
  }

  // async componentDidMount() {
  //   try {
  //     // TODO: Refresh token
  //     const token = await getAuthenticationToken()
  //     if (token) {
  //       this._navToAuthNavigator()
  //     }
  //   } catch (error) {

  //   }
  // }

  // _showActionSheet = () => {}

  // _showToast = () => {
  //   Toast.show({
  //     text: 'Wrong password!',
  //     buttonText: 'Okay',
  //     position: 'bottom',
  //     type: 'success',
  //   })
  // }

  // _navToAuthNavigator = () => {
  //   this.props.navigation.navigate({
  //     routeName: 'AuthSwitchNavigator',
  //     // params: {
  //     //   x: Math.random(),
  //     // },
  //   })
  // }

  _navToOnboardingRegisterView = () => {
    // this.props.navigation.navigate(switchToLandingView)
    this.props.navigation.dispatch(NavActions.switchToOnboardingRegisterView)
  }

  _navToLandingView = () => {
    // this.props.navigation.navigate(switchToLandingView)
    this.props.navigation.dispatch(switchToLandingView)
  }

  // _handleSubmitLogin = (values: FormikValues<ILoginFormValues> , helpers: FormikHelpers<ILoginFormValues>) => {
  _handleSubmitLogin = async (values: FormikValues, helpers: FormikHelpers<ILoginFormValues>) => {
    if (this._inProcess) {
      return
    }
    this._inProcess = true

    // console.log('_handleSubmitLogin values => ', values)
    const { navigation, dispatch } = this.props
    const { email, password } = values

    const response = await new Promise<IAnyObject>((resolve) => {
      dispatch({
        type: actions.LOGIN_WITH_ACCOUNT,
        body: {
          email,
          password,
        },
        successCallback: resolve,
        failedCallback: () => resolve(null),

        // successCallback: async ({ token }) => {
        //   // console.log('successCallback token => ', token)
        //   await setAuthenticationToken(token)

        //   // this._navToAuthNavigator()
        //   this._navToLandingView()

        //   resolve(true)
        // },
        // failedCallback: (err) => {
        //   // console.log('failedCallback err => ', err)
        //   // this.forminkRef.current.resetForm()

        //   resolve(false)
        // },
      })
    })

    if (response && _.isString(response.token)) {
      await setAuthenticationToken(response.token)
      await xUtil.delay(200)
    }

    if (_.has(response, 'is_consented') && response.is_consented === false) {
      const confirmationStatus = await PdpaConfirmation.show()
      // console.log('_handleNavWhenReceivedLoginResponse confirmationStatus => ', confirmationStatus)
      await xUtil.handleAfterCheckPdpaConfirmationStatus(confirmationStatus)
      if (confirmationStatus !== 'accepted') {
        this._inProcess = false
        return
      }
    }

    if (response) {
      const missing = _.has(response, 'missing') ? response.missing : null
      if (missing === 'email') {
        navigation.dispatch(NavActions.switchToOnboardingEmail({ email: response.default }))
      } else if (missing === 'username') {
        navigation.dispatch(NavActions.switchToOnboardingUsername)
      } else if (missing === 'store') {
        navigation.dispatch(NavActions.switchToOnboardingFirstStore)
      } else if (!missing) {
        // navigation.dispatch(NavActions.switchToAuthStackNavigator)
        navigation.dispatch(NavActions.resetToAuthStackNavigator())
      }
    }

    if (response && response.token && !response.missing) {
      this._navToLandingView()
      this._inProcess = false
      return
    }

    helpers.setSubmitting(false)

    // if (!isLoggedIn) {
    helpers.setFieldValue('password', '')
    helpers.setFieldError('password', 'กรุณาระบุรหัสผ่านใหม่ให้ถูกต้อง')
    this.focusPasswordInput()
    this._inProcess = false
    // }
  }

  focusPasswordInput = () => {
    if (this.passwordInputRef && this.passwordInputRef.current && _.isFunction(this.passwordInputRef.current.focus)) {
      this.passwordInputRef.current.focus()
    }
  }

  _renderForm = () => (
    <Formik<ILoginFormValues>
      // https://github.com/formium/formik/issues/2212
      // @ts-ignore formink v2 ถ้าไม่ใช้ hook เหมือนจะใช้ ref ตรงๆ ไม่ได้แล้ว
      // innerRef={this.forminkRef}
      // initialValues={{ email: 'keng4go@gmail.com', password: 'aaaaAaaa1' }}
      initialValues={{ email: '', password: '' }}
      validationSchema={LoginSchema}
      validateOnChange={false}
      // validateOnChange={true}
      // onSubmit={(values, { setSubmitting }) => {
      //   setTimeout(() => {
      //     console.log(JSON.stringify(values, null, 2))
      //     setSubmitting(false)
      //   }, 400)
      // }}
      onSubmit={this._handleSubmitLogin}>
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <VStack w='full' maxW='240px' px='2' flexWrap='wrap' space='2'>
          <VStack w='full'>
            {/* <XText minW='52px' selectable={false} variant='inactive' bold>
              อีเมล
            </XText> */}
            <XInput
              disabled={isSubmitting}
              // w='230px'
              w='full'
              autoFocus
              onChangeText={handleChange('email')}
              onBlur={handleBlur('email')}
              onSubmitEditing={() => this.focusPasswordInput()}
              value={values.email}
              placeholder='example@xselly.com'
              InputLeftElement={
                <HStack h='9' p='1' alignItems='center' bg='muted.200' borderRightWidth='1' borderRightColor='muted.300'>
                  <XText minW='52px' fontSize='xs' textAlign='center' variant='inactive' bold>
                    อีเมล
                  </XText>
                </HStack>
              }
            />
            {/* {errors.email && touched.email ? <XText style={StyleSheet.flatten(s.txtError)}>{errors.email}</XText> : null} */}
            {errors.email && touched.email ? (
              <XText flex={1} fontSize='xs' variant='danger'>
                {errors.email}
              </XText>
            ) : null}
          </VStack>

          <VStack w='full'>
            {/* <XText minW='52px' variant='inactive' bold>
              รหัสผ่าน
            </XText> */}
            <XInput
              disabled={isSubmitting}
              // w='230px'
              w='full'
              ref={this.passwordInputRef}
              secureTextEntry
              borderRadius='lg'
              onChangeText={handleChange('password')}
              onBlur={handleBlur('password')}
              onSubmitEditing={() => handleSubmit()}
              value={values.password}
              placeholder='ระบุรหัสผ่าน'
              InputLeftElement={
                <HStack h='9' p='1' alignItems='center' bg='muted.200' borderRightWidth='1' borderRightColor='muted.300'>
                  <XText minW='52px' fontSize='xs' textAlign='center' variant='inactive' bold>
                    รหัสผ่าน
                  </XText>
                </HStack>
              }
            />
            {/* {errors.password && touched.password ? <XText style={StyleSheet.flatten(s.txtError)}>{errors.password}</XText> : null} */}
            {errors.password && touched.password ? (
              <XText flex={1} fontSize='xs' variant='danger'>
                {errors.password}
              </XText>
            ) : null}
          </VStack>

          {/* <Button full={true} disabled={isSubmitting} style={{ flex: 1, height: 64, borderRadius: 4 }} onPress={handleSubmit}>
              <XText style={[s.txtNormalActiveDark, s.txtBold]}>{'เข้าสู่ระบบ'}</XText>
            </Button> */}
          <XButton
            w='full'
            isDisabled={isSubmitting}
            disabled={isSubmitting}
            isLoading={isSubmitting}
            // @ts-ignore
            onPress={handleSubmit}>
            เข้าสู่ระบบ
          </XButton>

          <XButton variant='ghost' onPress={() => this.props.navigation.dispatch(NavActions.navToForgottenPasswordView)}>
            ลืมรหัสผ่าน?
          </XButton>

          <VStack alignItems='center' justifyContent='center' px='1' py='2'>
            <XText variant='inactive'>- หรือ -</XText>
          </VStack>

          {/* <FacebookLogin
            appId='360835604336091'
            // autoLoad
            fields='name,email,picture'
            scope='public_profile,email'
            initParams={{ appsecret_proof: 'c6f67233bf25115d63f298744d445e0a' }}
            callback={this.responseFacebook}
          /> */}

          {/* <XButton colorScheme='blue' onPress={this.loginFacebook}>
            Facebook Login
          </XButton> */}

          <XButton w='full' variant='outline' onPress={this._navToOnboardingRegisterView}>
            ลงทะเบียนด้วยอีเมล
          </XButton>

          <XButton w='full' colorScheme='blue' leftIcon={<XIcon name='logo-facebook' />} onPress={this.loginFacebook}>
            เข้าสู่ระบบด้วย Facebook
          </XButton>

          {/* <XButton colorScheme='blue' onPress={this.checkLoginState}>
            Facebook Check Login Status
          </XButton> */}

          {/* <VStack alignItems='center' justifyContent='center' px='1' py='2'>
            <XText variant='inactive' textDecorationLine='underline' onPress={this._navToOnboardingRegisterView}>
              ลงทะเบียนด้วยอีเมล
            </XText>
          </VStack> */}
        </VStack>
      )}
    </Formik>
  )

  checkLoginState = () => {
    // @ts-ignore
    FB.getLoginStatus((response) => {
      console.log('checkFbLoginState response => ', response)
      // statusChangeCallback(response);
    })
  }

  loginFacebook = async () => {
    if (this._inProcess) {
      return
    }
    this._inProcess = true

    try {
      const loginStatus: any = await new Promise((resolve) => {
        // @ts-ignore
        FB.getLoginStatus((res) => {
          console.log('FB.getLoginStatus res => ', res)
          resolve(res)
          // statusChangeCallback(response);
        })
      })

      if (loginStatus.status === 'connected' && typeof loginStatus.authResponse.accessToken === 'string') {
        await this._loginWithFacebookAccessToken(loginStatus.authResponse.accessToken)
      } else {
        // @ts-ignore
        FB.login(
          (res) => {
            console.log('FB.login res => ', res)
            if (typeof res.authResponse.accessToken === 'string') {
              this._loginWithFacebookAccessToken(res.authResponse.accessToken)
            }
          },
          {
            // scope: 'user_likes',
            scope: 'public_profile,email',
            // fields: 'name,email,picture',
            // auth_type: 'rerequest'
          }
        )
      }
    } catch (err) {
      console.log('loginFacebook err => ', err)
    }

    this._inProcess = false
    // console.log('checkFbLoginState res => ', res)
  }

  // responseFacebook = (response) => {
  //   console.log('responseFacebook  response => ', response)
  // }

  _loginWithFacebookAccessToken = async (accessToken: string): Promise<void> => {
    if (!accessToken) {
      p.op.alert('เกิดข้อผิดพลาด', 'ไม่พบ Access Key สำหรับใช้งานด้วย Facebook')
      return
    }

    await setFBAccessToken(accessToken)

    // const userName = await new Promise((resolve) => {
    //   const infoRequest = new GraphRequest('/me', { accessToken }, (reqErr, reqResult) => {
    //     console.loglog('_loginWithFacebook.GraphRequest reqErr => ', reqErr)
    //     console.loglog('_loginWithFacebook.GraphRequest reqResult => ', reqResult)
    //     if (reqErr) {
    //       resolve(null)
    //     } else {
    //       resolve(reqResult.name)
    //     }
    //   })
    //   // Start the graph request.
    //   new GraphRequestManager().addRequest(infoRequest).start()
    // })

    // if (!userName) {
    //   await clearAuthenticationToken()
    //   // do Retry or reject user
    //   p.op.alert('เกิดข้อผิดพลาด', 'ไม่สามารถร้องขอข้อมูลผู้ใช้จากเฟสบุ๊คได้ กรุณาลองใหม่อีกครั้ง')
    //   return
    // }

    try {
      const { dispatch } = this.props
      const response: any = await new Promise((resolveAuth) => {
        dispatch({
          type: actions.LOGIN_WITH_FACEBOOK,
          body: { accessToken },
          successCallback: resolveAuth,
          failedCallback: () => resolveAuth(null),
        })
      })
      console.log('_loginWithFacebookAccessToken response => ', response)

      await this._handleNavWhenReceivedLoginResponse(response)
    } catch (err) {
      // p.op.alert('เกิดข้อผิดพลาด', 'ไม่สามารถร้องขอข้อมูลจากเฟสบุ๊คได้ กรุณาลองใหม่อีกครั้ง')
      console.log('_loginWithFacebookAccessToken err => ', err)
    }
  }

  _handleNavWhenReceivedLoginResponse = async (response) => {
    const { navigation } = this.props

    if (response) {
      if (response && _.isString(response.token)) {
        await setAuthenticationToken(response.token)
        // const jwtToken = await auth.getAuthenticationToken()
        // console.log('jwtToken => ', jwtToken)
        // const jwtClaims = parseJwt(jwtToken)
        // console.log('jwtClaims => ', jwtClaims)
      }

      if (_.has(response, 'is_consented') && response.is_consented === false) {
        const confirmationStatus = await PdpaConfirmation.show()
        // console.log('_handleNavWhenReceivedLoginResponse confirmationStatus => ', confirmationStatus)
        await xUtil.handleAfterCheckPdpaConfirmationStatus(confirmationStatus)
        if (confirmationStatus !== 'accepted') {
          return
        }
      }

      const missing = _.has(response, 'missing') ? response.missing : null
      if (missing === 'email') {
        navigation.dispatch(NavActions.switchToOnboardingEmail({ email: response.default || '' }))
      } else if (missing === 'username' && _.has(response, 'token')) {
        navigation.dispatch(NavActions.switchToOnboardingUsername)
      } else if (missing === 'store' && _.has(response, 'token')) {
        navigation.dispatch(NavActions.switchToOnboardingFirstStore)
      } else if (!missing && _.isString(response.token)) {
        navigation.dispatch(NavActions.resetToAuthStackNavigator())
      }
    }
  }

  _loginWithX = async () => {
    // log('_loginWithX')
    if (this._inProcess) {
      return
    }
    this._inProcess = true

    // const txtPaste = await Clipboard.getString()
    // // log('txtPaste => ', txtPaste)
    // if (txtPaste.length < 14) {
    //   return
    // }
    // const strArr = txtPaste.split('#')
    // if (strArr.length !== 2) {
    //   return
    // }

    // const id = parseInt(strArr[0])
    // const secret = strArr[1]
    const { devToolText1, devToolText2 } = this.state
    const id = devToolText1
    const secret = devToolText2
    // log('_loginWithX id => ', id)

    let txtFrom = `${settings.app.API_ROOT}/auth/login`
    // log('_loginWithX txtFrom => ', txtFrom)
    txtFrom = txtFrom.replace(/\//g, '\\/')
    const x = xtoken(txtFrom, secret, id)
    // log('_loginWithX x => ', x)
    await setAuthenticationToken(x)
    this._navToLandingView()

    this._inProcess = false
  }

  _handleXKeyDownEnter = async (evt) => {
    //  console.log('_handleXKeyDownEnter evt => ', evt)
    if (evt.key === 'Enter') {
      //  console.log('do validate')
      const { devToolText1, devToolText2 } = this.state
      if (devToolText1 && devToolText1.length > 0 && devToolText2 && devToolText2.length > 0) {
        await p.op.storageSet('devToolText1', devToolText1, false)
        await delay(200)
        await p.op.storageSet('devToolText2', devToolText2, false)
        await delay(200)
        this._loginWithX()
      }
    }
  }

  _handleChangeDefToolText1 = (newText) => {
    this.setState({ devToolText1: newText })
  }

  _handleChangeDefToolText2 = (newText) => {
    this.setState({ devToolText2: newText })
  }

  _renderDevTool = () => {
    const { devToolText1, devToolText2 } = this.state
    return (
      <View style={[S.ROW_CENTER, S.WIDTH_FULL, S.HEIGHT_FULL]}>
        <View style={[S.ROW_CENTER, { width: 44 }]}>
          <XInput value={devToolText1} onChangeText={this._handleChangeDefToolText1} onKeyPress={this._handleXKeyDownEnter} />
        </View>
        <View style={[S.ROW_CENTER, { width: 200, marginLeft: 4 }]}>
          <XInput value={devToolText2} onChangeText={this._handleChangeDefToolText2} onKeyPress={this._handleXKeyDownEnter} />
        </View>
      </View>
    )
  }

  _renderBrowserNotSupportWarning = () => (
    <VStack p='3' style={{ justifyContent: 'center', backgroundColor: 'red' }}>
      <HStack style={{ justifyContent: 'center', backgroundColor: 'red' }}>
        <XText style={s.txtBrowserWarning}>ในการเข้าสู่ระบบ กรุณาใช้ </XText>
        <a href='https://www.google.com/chrome/' target='_blank' rel='noreferrer'>
          <XText style={[s.txtBrowserWarning, s.txtBrowserWarningLink]}>Google Chrome</XText>
        </a>
        <XText style={s.txtBrowserWarning}> หรือ </XText>
        <a href='https://www.microsoft.com/th-TH/edge' target='_blank' rel='noreferrer'>
          <XText style={[s.txtBrowserWarning, s.txtBrowserWarningLink]}>Microsoft Edge</XText>
        </a>
      </HStack>
      <HStack style={{ justifyContent: 'center', backgroundColor: 'red' }}>
        <XText>(เนื่องจากระบบไม่รองรับเว็บเบราว์เซอร์ที่ใช้อยู่นี้ ทางทีมงานขออภัยในความไม่สะดวกมา ณ ที่นี่)</XText>
      </HStack>
    </VStack>
  )

  _renderStartWithAppNotice = () => (
    <XCard mt='5' style={{ backgroundColor: COLORS.BG_LIGHT_GREY_ALTERNATIVE }}>
      <VStack py='2' px='3' space='2'>
        <HStack>
          <VStack style={{ backgroundColor: 'transparent', flexDirection: 'column', alignItems: 'flex-start' }}>
            <HStack>
              <XText style={s.txtNormalInactive}>ก่อนใช้งานผ่านเว็บ คุณต้องมีแอป XSelly</XText>
            </HStack>
            <HStack>
              <XText style={s.txtNormalInactive}>เพื่อตั้งค่าอีเมลและรหัสผ่าน</XText>
            </HStack>
            <HStack>
              <a href='https://help.xselly.com/kb/set-password/' target='_blank' rel='noreferrer'>
                <XText style={s.txtNormalPrimary}>• ดูวิธีตั้งรหัสผ่าน กดที่นี่</XText>
              </a>
            </HStack>
          </VStack>
        </HStack>
        <HStack space='2'>
          {/* <a
              href='https://apps.apple.com/th/app/xselly-%E0%B8%AD%E0%B8%AD%E0%B9%80%E0%B8%94%E0%B8%AD%E0%B8%A3-%E0%B8%AA%E0%B8%95-%E0%B8%AD%E0%B8%81-%E0%B8%95-%E0%B8%A7%E0%B9%81%E0%B8%97%E0%B8%99/id1279749848'
              target='_blank'
              rel='noreferrer'>
              <Image
                style={{ width: '100%', height: 100, resizeMode: 'contain' }}
                source={{ uri: 'https://xselly.com/wp-content/uploads/2018/04/appstore.png' }}
              />
            </a> */}
          <a
            target='_blank'
            href='https://apps.apple.com/th/app/xselly-%E0%B8%AD%E0%B8%AD%E0%B9%80%E0%B8%94%E0%B8%AD%E0%B8%A3-%E0%B8%AA%E0%B8%95-%E0%B8%AD%E0%B8%81-%E0%B8%95-%E0%B8%A7%E0%B9%81%E0%B8%97%E0%B8%99/id1279749848'
            rel='noreferrer'>
            <picture>
              <source type='image/webp' srcSet='https://xselly.com/img/appstore.png' />
              <img alt='Download on AppStore' width='120' height='36' loading='lazy' src='https://xselly.com/img/appstore.png' />
            </picture>
          </a>
          {/* <a href='https://play.google.com/store/apps/details?id=com.xselly&hl=th' target='_blank' rel='noreferrer'>
            <Image
              style={{ width: '100%', height: 100, resizeMode: 'contain' }}
              source={{ uri: 'https://xselly.com/wp-content/uploads/2018/04/playstore.png' }}
            />
          </a> */}
          <a target='_blank' href='https://play.google.com/store/apps/details?id=com.xselly&hl=th' rel='noreferrer'>
            <picture>
              <source type='image/webp' srcSet='https://xselly.com/img/playstore.png' />
              <img alt='Download on Playstore' width='120' height='36' loading='lazy' src='https://xselly.com/img/playstore.png' />
            </picture>
          </a>
        </HStack>
      </VStack>
    </XCard>
  )

  render() {
    if (isImpersonateMode) {
      return this._renderDevTool()
    }
    return (
      <XContainer>
        <ImageBackground style={s.backgroundImage} source={{ uri: 'https://xselly.com/x/bg.jpg' }}>
          {/* <XContent contentContainerStyle={[s.contentContainer, s.backgroundTransperant]}> */}
          <VStack w='full' alignItems='center' justifyContent='center'>
            <XCard>
              <XCard.Body>
                <VStack py='2' w='full'>
                  <HStack w='full' alignItems='center' justifyContent='center'>
                    <Image source={WEB_RUNTIME.APP_LOGO_COLOR_TEXT} resizeMode='contain' style={s.imageLogo} />
                  </HStack>
                  {isChromium() ? this._renderForm() : this._renderBrowserNotSupportWarning()}
                  {/* {this._renderBrowserNotSupportWarning()} */}
                </VStack>
              </XCard.Body>
            </XCard>
            {/* {isChromium() ? this._renderStartWithAppNotice() : null} */}
            {this._renderStartWithAppNotice()}
          </VStack>
          {/* </XContent> */}
        </ImageBackground>
      </XContainer>
    )
  }
}

const s = StyleSheet.create({
  contentContainer: {
    // flex: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  colLeft: {
    // flex: 1,
    width: 76,

    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    paddingRight: 10,
  },
  colRight: {
    flex: 1,
    width: 160,
  },
  txtError: {
    maxWidth: 250,
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.BRAND_Danger,
  },
  imageLogo: { width: 200, height: 70 },
  txtBold: {
    fontWeight: 'bold',
  },
  txtNormalInactive: {
    fontSize: STYLES.FONT_SIZE_LARGER,
    color: COLORS.TEXT_INACTIVE,
  },
  txtNormalPrimary: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.APP_MAIN,
  },
  txtNormalActiveDark: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: 'white',
  },
  txtBrowserWarning: {
    fontSize: 18,
    fontWeight: 'bold',
    color: 'white',
  },
  txtBrowserWarningLink: {
    textDecorationLine: 'underline',
    textDecorationColor: 'white',
    textDecorationStyle: 'solid',
  },
  backgroundImage: {
    flex: 1,
    resizeMode: 'cover',
    position: 'absolute',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
  },
  backgroundTransperant: {
    backgroundColor: 'transparent',
  },
})
