import React from 'react'

import XCustomHeader from 'xui/components/XCustomHeader'
import { Text } from 'react-native'
import ThemeSizeContext from '../../context/ThemeSizeContext'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'

export default class ResellerRegisterView extends React.Component {
  static contextType = ThemeSizeContext

  context!: React.ContextType<typeof ThemeSizeContext>

  render() {
    // @ts-ignore: FIXME: อย่าลืมประกาศ types
    const { navigation } = this.props

    return (
      <XContainer style={this.context.contentOneOfThreeColumnWidthStyle}>
        <XCustomHeader title='ResellerRegisterView' />
        <XContent contentContainerStyle={this.context.contentOneOfThreeColumnWidthStyle} style={{ backgroundColor: 'violet' }}>
          <h2>ResellerRegisterView</h2>
          <Text>{`${JSON.stringify(navigation.state)}`}</Text>
        </XContent>
      </XContainer>
    )
  }
}
