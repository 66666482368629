import { log } from '../utils/util'
import flow from './flow'
import xActions from 'x/config/actions'
import settings from '../config/settings'
import { Moment } from 'moment'
var moment = require('moment')
require('moment/locale/th')
require('moment-timezone')
moment.locale('th')

export function startFlow(name) {
  log('startFlow: ' + name + '-- Current flow: ' + flow._flowInProgress)
  if (flow._flowInProgress) {
    log('return false')
    return false
  } else {
    flow._flowInProgress = name
    log('return true --> ' + flow._flowInProgress)
    // cancel the previous timeout safeguard if any
    if (flow._flowTimeout) {
      // @ts-ignore
      clearTimeout(flow._flowTimeout)
    }
    // then set the new timeout safeguard
      // @ts-ignore
    flow._flowTimeout = setTimeout(() => {
      resetFlow()
    }, settings.FLOW_TIMEOUT)
    return true
  }
}

export function isInFlow(name) {
  return name && flow._flowInProgress === name
}

export function flowDone(name) {
  log('flowDone: ' + name + ' -- Current flow: ' + flow._flowInProgress)
  if (name === flow._flowInProgress) {
    resetFlow()
    return true
  } else {
    return false
  }
}

export function isFlowAllowed(name, targetKey, currKey) {
  // log('isFlowAllowed: ' + name + '...' + flow._flowInProgress + ' targetKey: ' + targetKey + ' currKey' + currKey)
  if (!flow._flowInProgress && name === 'Navigation/SET_PARAMS' && targetKey && targetKey !== currKey) {
    // log('isFlowAllowed.result: Rejected as Navigation/SET_PARAMS on not current page. This is to prevent infinity loop')
    return false
  }
  if (!flow._flowInProgress || name === xActions.SESSION_READY || name === 'Navigation/SET_PARAMS') {
    // log('isFlowAllowed.result: ' + true)
    return true
  } else {
    // log('isFlowAllowed.result: ' + (flow._flowInProgress === name))
    return flow._flowInProgress === name
  }
}

function resetFlow() {
  if (flow._flowTimeout) {
      // @ts-ignore
    clearTimeout(flow._flowTimeout)
  }
  flow._flowInProgress = null
  flow._flowTimeout = null
}

export let isFirstMountAppLauncher: boolean = true
export let lastUpdatedSubscription: Moment|null = null
export let appGlobalMethods: { [key: string]: any } = {
  openWeb: null,                  // will define in AppView
  askRateApp: null,               // will define in AppView
  openSubscriptionWarning: null,  // will define in AppView
  closeSubscriptionWarning: null,  // will define in AppView
  subscriptionUpdate: () => { lastUpdatedSubscription = moment() },
  subscriptionReset: () => { lastUpdatedSubscription = null },
  alreadyMountAppLauncher: () => {
    isFirstMountAppLauncher = false
    // console.log('alreadyMountAppLauncher isFirstMountAppLauncher => ', isFirstMountAppLauncher)
  },
}

export default global
