import React from 'react'
import * as util from 'x/utils/util'
// import BackIcon from '../../components/BackIcon'
// import XTabNavIcon from '../../components/XTabNavIcon'
// import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
// import SRowItem from '../../components/renderRowLeftAndRight'
import { COLORS } from 'x/config/styles'
import XCustomHeader from 'xui/components/XCustomHeader'
import VStack from 'xui/components/VStack'
import XText from 'xui/components/XText'
import _ from 'lodash'
import HStack from 'xui/components/HStack'
import XCard from 'xui/components/XCard'
import XInput from 'xui/components/XInput'
import { IXScreenProps } from 'x/index'
import p from 'x/config/platform-specific'
// const _renderAgreement = (onPressOpenOverlay: () => void) => <HelpButton onPress={() => onPressOpenOverlay()} />

type IEditCountdownTimeViewProps = IXScreenProps

interface IEditCountdownTimeViewState {
  // salePage: IStoreWebsite
  // webItems: IWebItem[]
  itemIndex: number
  countdownTime: number // min
  isOnChangeData: boolean
  popViewNumber: number
  isEditMode: boolean
}

export default abstract class EditCountdownTimeView extends React.Component<IEditCountdownTimeViewProps, IEditCountdownTimeViewState> {
  // abstract async handleSubmitForm(response: any): Promise<void>
  // _editor: any

  constructor(props) {
    super(props)
    this.state = {
      itemIndex: 0,
      countdownTime: 0,
      isOnChangeData: false,
      popViewNumber: 1,
      isEditMode: false,
    }
  }

  componentDidMount() {
    // @ts-ignore
    const params = util.getNavParams(this.props)
    const { index, countdownTime, popViewNumber } = params
    console.log('countdownTime => ', countdownTime)
    this.setState({
      itemIndex: index,
      countdownTime: _.isNil(countdownTime) ? 60 : countdownTime,
      popViewNumber,
      isEditMode: !_.isNil(countdownTime),
      isOnChangeData: !!_.isNil(countdownTime),
    })
  }

  _renderHeader = () => {
    const title = 'แก้ไขนับเวลาถอยหลัง'
    const { isOnChangeData, countdownTime, itemIndex } = this.state
    let leftProps = { backIcon: true, onPressItem: () => util.navGoBack(this.props) }
    let rightProps = null
    if (isOnChangeData) {
      leftProps = {
        // @ts-ignore
        label: 'บันทึก',
        onPressItem: () => {
          const params = util.getNavParams(this.props)
          const { callBack } = params
          if (_.isFunction(callBack)) {
            callBack({ countdown_timer_in_minute: countdownTime }, itemIndex, this.state.isEditMode)
            p.op.showToast('ดำเดินการเรียบร้อย', 'success')
            this.props.navigation.pop(this.state.popViewNumber)
            return
          }
          p.op.showToast('เกิดข้อผิดพลาด ไม่สามารถทำราบการได้', 'danger')
          this.props.navigation.pop(this.state.popViewNumber)
        },
      }
      rightProps = {
        label: 'ยกเลิก',
        onPressItem: () => {
          p.op.showConfirmation(
            '',
            'ยกเลิกการทำรายการ',
            () => util.navGoBack(this.props),
            () => null
          )
        },
      }
    }
    return (
      <XCustomHeader
        title={title}
        // @ts-ignore
        headerLeftProps={leftProps}
        headerRightProps={rightProps}
      />
    )
  }

  _renderExampleView = () => {
    const { itemIndex, countdownTime } = this.state
    const countdownTimeToString = `${countdownTime}`
    return (
      <VStack w='full' key={`renderCountdownTimerInMinute${itemIndex}`} bgColor={COLORS.WHITE}>
        {/* <HStack flex={1} justifyContent='flex-end' pt='2' pr='2'>
          <XText color={COLORS.APP_MAIN}>ตัวอย่าง</XText>
        </HStack> */}
        <VStack w='full'>
          <VStack w='full' minH='12' py='2' alignItems='center' justifyContent='center'>
            <XText pr='2'>โปรโมทชั่นจะหมดภายใน</XText>
            <XText pr='2' bold>
              {countdownTimeToString}
            </XText>
            <XText pr='2'>นาที</XText>
          </VStack>
        </VStack>
      </VStack>
    )
  }

  _renderEditView = () => {
    const { countdownTime } = this.state
    return (
      <XCard w='full' p='2' mt='2'>
        <HStack flex={1} alignItems='center' justifyContent='center'>
          <XText>ตัวนับเวลาถอยหลัง</XText>
          <XInput
            mx='2'
            w='12'
            value={`${countdownTime}`}
            isNumber
            onChangeText={(newTime) => this.setState({ countdownTime: +newTime, isOnChangeData: true })}
          />
          <XText>นาที</XText>
        </HStack>
      </XCard>
    )
  }

  render() {
    return (
      <XContainer>
        {this._renderHeader()}
        <XContent p='2' style={{ backgroundColor: COLORS.BG_LIGHT_GREY }}>
          {this._renderExampleView()}
          {this._renderEditView()}
        </XContent>
        {/* {this._renderFooter()} */}
      </XContainer>
    )
  }
}
