import React, { Component, PureComponent } from 'react'
import { TouchableOpacity, View, Linking, Clipboard, Text, Image } from 'react-native'
import _ from 'lodash'
import { Spinner, Icon } from 'native-base'
import { COLORS, STYLES } from 'x/config/styles'
import { isDiffAccuracy, getLineUrlByLineId } from 'x/utils/util'
import p from 'x/config/platform-specific'
import FastImage from 'react-native-fast-image'
import { List, Map } from 'immutable'
import { UserGroupMap, UserInUserGroupsMap } from 'x/index'

import { connect } from 'react-redux'
// import { bindActionCreators } from 'redux'
import { getSelectedUserGroups } from 'x/redux/selectors'
import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import Box from 'xui/components/Box'
import XText from 'xui/components/XText'

const INTL_STYLES: { [key: string]: any } = {
  userImage: {
    height: 50,
    width: 50,
    borderRadius: 25,
  },
  userEmptyImageIcon: {
    height: 50,
    width: 50,
    fontSize: 50,
  },
  userContactIcon: {
    width: STYLES.FONT_ICON_LARGER,
    height: STYLES.FONT_ICON_LARGER,
  },
  middleCenterColumn: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  middleLeftColumn: {
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  topLeftColumn: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
}

const MESSENGER_IMAGE = require('../../img/about/Messenger_Icon.png')
const LINE_IMAGE = require('../../img/about/LINE_Icon.png')

const UserImage = ({ imgUri = null, thumbUri }) =>
  imgUri || thumbUri ? (
    <FastImage
      style={INTL_STYLES.userImage}
      source={{
        uri: thumbUri || imgUri,
        priority: FastImage.priority.low,
      }}
      resizeMode={FastImage.resizeMode.cover}
    />
  ) : (
    <Icon name='contact' style={INTL_STYLES.userEmptyImageIcon} />
  )

interface IUserItemProps {
  rowHeight?: number // default = 60
  data: UserInUserGroupsMap | null
  index?: number
  onPressItem?: (arg: { index?: number; data?: UserInUserGroupsMap }) => void
  onPressEmpty?: (index?: number) => void
  renderRightComponent?: (arg: { index: number; data: UserInUserGroupsMap }) => JSX.Element
  showIndex?: boolean
  selectedUserGroups: List<UserGroupMap>
}

class UserItem extends Component<IUserItemProps> {
  shouldComponentUpdate(props, nextProps) {
    return isDiffAccuracy(this.props, nextProps, ['index', 'data', 'renderRightComponent'])
    // log(`UserItem:: shouldComponentUpdate ${props.index} props => `, props)
    // return false
    // if ((!props.data && !!nextProps.data) || (!!props.data && !nextProps.data) || (!!props.data && !!nextProps.data && props.data !== nextProps.data)) {
    //   return true
    // }
    // return (!props.index && nextProps.index) || (props.index && !nextProps.index) || (props.index !== nextProps.index)
  }

  // _onPressItem = () => {
  //   const { onPressItem, index, data } = this.props
  //   if (_.isFunction(onPressItem) && Map.isMap(data)) {
  //     onPressItem({ index, data })
  //   }
  // }
  _onPressEmpty = (index: number) => {
    const { onPressEmpty } = this.props
    if (_.isFunction(onPressEmpty)) {
      onPressEmpty(index)
    }
  }

  _renderRightComponent = () => {
    const { index, data, renderRightComponent } = this.props
    if (!_.isFunction(renderRightComponent) && Map.isMap(data)) {
      return null
    }
    return renderRightComponent({ index, data })
  }

  _openLinkURL = (link: string) => {
    Linking.canOpenURL(link).then((supported) => {
      if (supported) {
        Linking.openURL(link)
      } else {
        Clipboard.setString(link)
        p.op.showToast(`คัดลอก ${link} แล้ว`, 'success')
      }
    })
  }

  _handleOpenMessenger = () => {
    const { data } = this.props
    if (!data || !Map.isMap(data) || !data.has('f')) {
      return
    }
    const facebook_id = data.get('f')
    this._openLinkURL(`http://m.me/${facebook_id}`)
  }

  _handleOpenLINE = () => {
    const { data } = this.props
    if (!data || !Map.isMap(data) || !data.has('l')) {
      return
    }
    const line_id = data.get('l')
    this._openLinkURL(getLineUrlByLineId(line_id))
  }

  _handleOnPressUserItem = () => {
    const { index, data, onPressItem } = this.props
    if (_.isFunction(onPressItem)) {
      onPressItem({ index, data })
    }
  }

  render() {
    const { data, rowHeight = 60, index, onPressItem, showIndex, selectedUserGroups } = this.props
    if (!data || !Map.isMap(data)) {
      return <EmptyItem index={index} rowHeight={rowHeight} onPressItem={this._onPressEmpty} />
    }

    // const id = data.get('i')
    // const image_uri = data.get('iu')
    // const register_at = data.get('ra')
    const display_name = data.get('d')
    const thumbnail_uri = data.get('t')
    const facebook_id = data.get('f')
    const line_id = data.get('l')
    const currentUserGroupId = data.get('ug_curr')
    let txtCurrentUserGroupName = null
    if (currentUserGroupId && List.isList(selectedUserGroups)) {
      const foundUserGroup = selectedUserGroups.find((ug) => ug.get('id') === currentUserGroupId)
      if (Map.isMap(foundUserGroup) && foundUserGroup.get('name')) {
        txtCurrentUserGroupName = `(ขอย้ายจาก ${foundUserGroup.get('name')})`
      }
    }

    let backgroundColor = 'white'
    if (_.isNumber(index) && index % 2 !== 0) {
      backgroundColor = COLORS.BG_LIGHT_GREY
    }
    return (
      <TouchableOpacity
        disabled={!_.isFunction(onPressItem)}
        onPress={this._handleOnPressUserItem}
        style={{
          height: rowHeight,
          backgroundColor,
          justifyContent: 'center',
        }}>
        <VStack>
          <HStack>
            <Box flex={2} style={INTL_STYLES.middleCenterColumn}>
              <UserImage
                // imgUri={image_uri}
                // @ts-ignore
                thumbUri={thumbnail_uri}
              />
            </Box>
            <Box flex={4} style={INTL_STYLES.topLeftColumn}>
              <HStack style={{ flex: 1 }}>
                <Box flex={1} style={INTL_STYLES.middleLeftColumn}>
                  <XText numberOfLines={1} variant='active'>
                    {display_name}
                  </XText>
                </Box>
              </HStack>
              {txtCurrentUserGroupName ? (
                <HStack style={{ flex: 0, paddingTop: 2 }}>
                  <Box flex={1} style={INTL_STYLES.middleLeftColumn}>
                    <Text numberOfLines={1} style={{ color: COLORS.TEXT_INACTIVE, fontSize: STYLES.FONT_SIZE_SMALLER }}>
                      {txtCurrentUserGroupName}
                    </Text>
                  </Box>
                </HStack>
              ) : null}

              <HStack style={{ flex: 1, paddingTop: 2 }}>
                <Box flex={1} style={INTL_STYLES.middleCenterColumn}>
                  {facebook_id ? (
                    <TouchableOpacity onPress={this._handleOpenMessenger}>
                      <Image resizeMode='cover' style={INTL_STYLES.userContactIcon} source={MESSENGER_IMAGE} />
                    </TouchableOpacity>
                  ) : null}
                </Box>
                {facebook_id ? <Box w='4' /> : null}
                <Box flex={1} style={INTL_STYLES.middleCenterColumn}>
                  {line_id ? (
                    <TouchableOpacity onPress={this._handleOpenLINE}>
                      <Image resizeMode='cover' style={INTL_STYLES.userContactIcon} source={LINE_IMAGE} />
                    </TouchableOpacity>
                  ) : null}
                </Box>
                {/* <Box flex={1} /> */}
              </HStack>
              {/* <HStack> */}
              {/* <Text style={{ color: textColor }}>DEBUG ID: {id}</Text> */}
              {/* </HStack> */}
            </Box>
            <Box flex={4} pr='2' style={INTL_STYLES.middleCenterColumn}>
              {this._renderRightComponent()}
            </Box>
          </HStack>
        </VStack>
        {_.isNumber(index) && showIndex ? (
          <View style={{ position: 'absolute', top: 0, left: 0 }}>
            <Text
              style={{
                fontSize: 8,
                color: COLORS.TEXT_INACTIVE,
              }}>
              {index + 1}
            </Text>
          </View>
        ) : null}
      </TouchableOpacity>
    )
  }
}

interface EmptyItemProps {
  rowHeight?: number // default = 60
  index?: number
  onPressItem: (index?: number) => void
}

export class EmptyItem extends PureComponent<EmptyItemProps> {
  _onPress = () => {
    const { index = null, onPressItem } = this.props
    onPressItem(index)
  }

  render() {
    const { rowHeight = 60, index } = this.props
    return (
      <TouchableOpacity onPress={this._onPress}>
        <View
          style={{
            height: rowHeight,
            backgroundColor: index % 2 === 0 ? COLORS.BG_LIGHT_GREY : 'white',
          }}>
          <Spinner color={COLORS.TEXT_INACTIVE} />
        </View>
        <View style={{ position: 'absolute', top: 0, left: 0 }}>
          <Text
            style={{
              fontSize: 8,
              color: COLORS.TEXT_INACTIVE,
            }}>
            {index + 1}
          </Text>
        </View>
      </TouchableOpacity>
    )
  }
}

export default connect(
  (state) => ({
    selectedUserGroups: getSelectedUserGroups(state),
  }),
  (dispatch) => ({
    dispatch,
  })
)(UserItem)
