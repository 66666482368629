import { connect } from 'react-redux'
import VolumeDiscountView from './VolumeDiscountView'
import { bindActionCreators } from 'redux'
import * as StoreActions from 'x/modules/store/StoreState'
import { getSelectedStore, getSelectedUserGroups, getSubscription, getMySubscription } from 'x/redux/selectors'

export default connect(
  state => ({
    selectedStore: getSelectedStore(state),
    selectedUserGroups: getSelectedUserGroups(state),
    isReady: state.getIn(['session', 'isReady']),
    subscription: getSubscription(state),
    mySubscription: getMySubscription(state),
  }),
  dispatch => ({
    createVolumeDiscount: bindActionCreators(StoreActions.createVolumeDiscount, dispatch),
    updateVolumeDiscount: bindActionCreators(StoreActions.updateVolumeDiscount, dispatch),
    deleteVolumeDiscount: bindActionCreators(StoreActions.deleteVolumeDiscount, dispatch),
  }),
)(VolumeDiscountView)
