import React from 'react'
import { TouchableOpacity } from 'react-native'

import * as util from 'x/utils/util'
import CONS from 'x/config/constants'

import HStack from 'xui/components/HStack'

import XText from 'xui/components/XText'
import { IErpDocTypeOption } from 'x/index'
import _ from 'lodash'
import XSpinner from '../XSpinner'
import XIcon from '../XIcon'
import VStack from '../VStack'

interface ICheckboxProps {
  disabled?: boolean
  onPress: () => void
  isChecked: boolean
}

const Radio: React.FC<ICheckboxProps> = ({ disabled, onPress, isChecked }) => (
  <TouchableOpacity onPress={onPress}>
    <XIcon variant={disabled ? 'inactive' : 'primary'} name={isChecked ? 'radio-button-on' : 'radio-button-off'} />
  </TouchableOpacity>
)
const Checkbox: React.FC<ICheckboxProps> = ({ disabled, onPress, isChecked }) => (
  <TouchableOpacity onPress={onPress}>
    <XIcon family='FontAwesome' variant={disabled ? 'inactive' : 'primary'} name={isChecked ? 'check-square-o' : 'square-o'} />
  </TouchableOpacity>
)

interface IProps {
  disabled?: boolean
  selectedIndex: number
  isTaxInvoiceChecked: boolean
  onOptionChange: (selectedIndex: number, selectedOption: IErpDocTypeOption, isTaxInvoice?: boolean) => void
  visibleErpDocTypeIds?: number[]
}

interface IState {
  isInitialized: boolean
  docTypeTaxInvoiceChecked: {
    [id: number]: boolean
  }
}

// const ERP_DOC_TYPE_OPTIONS: IErpDocTypeOption[] = [
//   {
//     id: 11,
//     key: 'quotation',
//     label: 'quotation',
//   },
//   {
//     id: 12,
//     key: 'billing_note',
//     label: 'ใบแจ้งหนี้',
//     is_tax_invoice_available: true,
//   },
//   {
//     id: 13,
//     key: 'tax_invoice',
//     label: 'tax_invoice',
//   },
//   {
//     id: 14,
//     key: 'receipt',
//     label: 'ใบเสร็จรับเงิน',
//     is_tax_invoice_available: true,
//   },
//   {
//     id: 15,
//     key: 'cash_invoice',
//     label: 'cash_invoice',
//   },
//   {
//     id: 16,
//     key: 'debit_note',
//     label: 'debit_note',
//   },
//   {
//     id: 17,
//     key: 'credit_note',
//     label: 'credit_note',
//   },
//   {
//     id: 21,
//     key: 'purchase_order',
//     label: 'purchase_order',
//   },
//   {
//     id: 22,
//     key: 'receiving_inventory',
//     label: 'receiving_inventory',
//   },
//   {
//     id: 31,
//     key: 'expense',
//     label: 'expense',
//   },
//   {
//     id: 32,
//     key: 'withhilding_tax',
//     label: 'withhilding_tax',
//   },
// ]

export default class ErpDocTypeSelector extends React.Component<IProps, IState> {
  static displayName = 'ErpDocTypeSelector'

  static defaultProps = {
    visibleErpDocTypeIds: CONS.ERP_DOC_TYPE_OPTIONS.map(({ id }) => id),
  }

  inProcess: boolean

  constructor(props) {
    super(props)

    this.state = {
      isInitialized: false,
      docTypeTaxInvoiceChecked: {
        12: false,
        14: false,
      },
    }
  }

  async componentDidMount(): Promise<void> {
    await this._initData()
  }

  _initData = async () => {
    const newState: Partial<IState> = { isInitialized: true }
    try {
      const { selectedIndex, isTaxInvoiceChecked } = this.props

      const preSelectedOption =
        _.isNumber(selectedIndex) && CONS.ERP_DOC_TYPE_OPTIONS[selectedIndex] ? CONS.ERP_DOC_TYPE_OPTIONS[selectedIndex] : null
      if (preSelectedOption && preSelectedOption.id && _.isBoolean(isTaxInvoiceChecked)) {
        newState.docTypeTaxInvoiceChecked = {}
        newState.docTypeTaxInvoiceChecked[preSelectedOption.id] = isTaxInvoiceChecked
      }
    } catch (err) {
      console.log('ErpDocTypeSelector componentDidMount err => ', err)
    }
    await util.setStatePromise(this, newState)
  }

  _onIsTaxInvoiceCheckboxToggle = (id: number) => {
    const { selectedIndex } = this.props

    const { docTypeTaxInvoiceChecked: oldOne } = this.state
    const docTypeTaxInvoiceChecked = { ...oldOne }
    // console.log('ErpDocTypeSelector _onIsTaxInvoiceCheckboxToggle docTypeTaxInvoiceChecked => ', docTypeTaxInvoiceChecked)

    if (_.isNil(docTypeTaxInvoiceChecked[id])) {
      docTypeTaxInvoiceChecked[id] = true
    } else if (_.isBoolean(docTypeTaxInvoiceChecked[id])) {
      docTypeTaxInvoiceChecked[id] = !docTypeTaxInvoiceChecked[id]
    }

    this.setState({ docTypeTaxInvoiceChecked }, () => {
      this._onOptionChange(selectedIndex, CONS.ERP_DOC_TYPE_OPTIONS[selectedIndex], docTypeTaxInvoiceChecked[id])
    })
  }

  _onOptionChange = (selectedIndex: number, selectedOption: IErpDocTypeOption, isTaxInvoiceByCheckbox?: boolean) => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true

    try {
      const { onOptionChange } = this.props
      const { docTypeTaxInvoiceChecked } = this.state
      const isTaxInvoice = _.isBoolean(isTaxInvoiceByCheckbox) ? isTaxInvoiceByCheckbox : docTypeTaxInvoiceChecked[selectedOption.id]
      // console.log('ErpDocTypeSelector _onOptionChange selectedIndex => ', selectedIndex)
      // console.log('ErpDocTypeSelector _onOptionChange selectedOption => ', selectedOption)
      // console.log('ErpDocTypeSelector _onOptionChange isTaxInvoice => ', isTaxInvoice)

      onOptionChange(selectedIndex, selectedOption, isTaxInvoice)
    } catch (error) {
      console.log('ErpDocTypeSelector _onOptionChange err => ', error)
    }

    this.inProcess = false
  }

  renderRadioOption = (opt: IErpDocTypeOption, idx: number) => {
    const { disabled, selectedIndex, isTaxInvoiceChecked, visibleErpDocTypeIds } = this.props
    // const { docTypeTaxInvoiceChecked } = this.state
    const { key, id, label, is_tax_invoice_available } = opt

    if (!_.includes(visibleErpDocTypeIds, id)) {
      return null
    }

    return (
      <HStack key={`${idx}_${key}`} w='full' alignItems='center' space='2'>
        <HStack alignItems='center' space='1'>
          <Radio disabled={disabled} onPress={() => this._onOptionChange(idx, opt)} isChecked={selectedIndex === idx} />
          <XText minW='120px' onPress={() => this._onOptionChange(idx, opt)} isChecked={selectedIndex === idx}>
            {label}
          </XText>
        </HStack>

        {selectedIndex === idx && is_tax_invoice_available && (
          <HStack alignItems='center' space='1'>
            <Checkbox disabled={disabled} isChecked={isTaxInvoiceChecked} onPress={() => this._onIsTaxInvoiceCheckboxToggle(id)} />
            <XText onPress={() => this._onIsTaxInvoiceCheckboxToggle(id)}>ออกใบกำกับภาษี</XText>
          </HStack>
        )}
      </HStack>
    )
  }

  renderRadioOptions = () => CONS.ERP_DOC_TYPE_OPTIONS.map(this.renderRadioOption)

  _renderMain = () => {
    const { isInitialized } = this.state
    if (!isInitialized) {
      return <XSpinner />
    }

    return (
      <VStack w='full' space='1.5'>
        {this.renderRadioOptions()}
      </VStack>
    )
  }

  render() {
    return this._renderMain()
  }
}
