import React, { useState } from 'react'
import { TouchableOpacity, View, ScrollView, Linking, StyleSheet } from 'react-native'
import XDateRangePicker from 'xui/components/XDateRangePicker'
import _ from 'lodash'
import XOverlay from 'xui/components/XOverlay'
import { STYLES, COLORS, S } from 'x/config/styles'
import p from 'x/config/platform-specific'
import CONS from 'x/config/constants'
import * as util from 'x/utils/util'
import Segment from 'xui/components/Segment'
import XIcon from 'xui/components/XIcon'
import { IProductDetailMap, IProductDetailVariantMap } from 'x/index'
import { VIEW_HEIGHT_TEN } from 'xui/utils/BaseShareUI'
import * as xAcl from 'x/utils/acl'
import dayjs, { Dayjs } from 'dayjs'
import XText from '../XText'
import XButton from '../XButton'
import VStack from '../VStack'
import Box from '../Box'
import HStack from '../HStack'

interface IReportHistoryProps {
  visible: boolean
  onRequestClose: () => void
  downloadHistoryReport: (variant: IProductDetailVariantMap) => void
  selectedProduct: IProductDetailMap
  onChangeDateRange: (newDates: { begin: Dayjs; end: Dayjs }) => void
}

// const DateRangePicker = p.comp.getXDateRangePicker()
// const XOverlay = p.comp.getXOverlay()
const DATE_RANGE = ['วันนี้', '7 วัน', '30 วัน', 'ระบุ']

const ReportHistory: React.FC<IReportHistoryProps> = function ({
  visible,
  downloadHistoryReport,
  onRequestClose,
  selectedProduct,
  onChangeDateRange,
}) {
  // const [isReportHistory, setIsReportHistory] = useState(null)
  const [selectedOptSegment, setSelectedOptSegment] = useState(0)
  const [selectedVariant, setIsSelectedVariant] = useState(null)
  // const [historyReportVariants, setHistoryReportVariants] = useState(null)
  const [selectedDateRange, setSelectedDateRange] = useState({ begin: dayjs(), end: dayjs() })
  // const [isDownloadingHistoryReport, setIsDownloadingHistoryReport] = useState(null)

  const _onChangeDateRange = (newDates: { begin: Dayjs; end: Dayjs }) => {
    setSelectedDateRange(newDates)
    onChangeDateRange(newDates)
  }
  const seletedRadioBtn = (variant: any) => {
    setIsSelectedVariant(variant)
  }

  const onChangeSegment = async (newIndex: number) => {
    const newDateTime = selectedDateRange

    newDateTime.end = dayjs() // set to today
    switch (newIndex) {
      case 0: // today
        newDateTime.begin = newDateTime.end
        break
      case 1: // Within 7 days
        newDateTime.begin = dayjs().subtract(6, 'd')
        break
      case 2: // Within 30 days
        newDateTime.begin = dayjs().subtract(29, 'd')
        break
      default:
        // default to today
        newDateTime.begin = newDateTime.end
    }
    setSelectedOptSegment(newIndex)
    setSelectedDateRange(newDateTime)
    onChangeDateRange(newDateTime)
  }

  const subscription = util.getSubscriptionFromRedux()
  const r_shpg_day = subscription.get('r_shpg_day')
  const variants = selectedProduct.get('variants')
  const variantsLength = variants.size
  const firstVariants = _.isNil(variants) ? null : variants.toJS()[0]

  const WARNINGTEXT = () => {
    const owner = xAcl.isSelectedStoreOwner()
    const r_shpg_day = subscription.get('r_shpg_day')
    let text = 'แพ็กเกจของคุณไม่สามารถใช้งานฟังก์ชันนี้ได้'
    if (owner) {
      if (!xAcl.packFree()) {
        text = `แพ็กเกจของคุณดูย้อนหลังได้ ${r_shpg_day} วัน`
      }
    } else {
      // FIXME :: ถ้าเป็นตัวแทนร้าน type 1 ล่ะ ?
      const canDo = xAcl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.REPORT_INVENTORY, CONS.REPORT_SET_BIT_BINARY.report_stock_card)
      if (canDo && !xAcl.packFree()) {
        text = `แพ็กเกจของคุณดูย้อนหลังได้ ${r_shpg_day} วัน`
      } else {
        text = `คุณไม่ได้รับสิทธิในการใช้งานฟังก์ชันนี้`
      }
    }
    return text
  }

  let itemHeight = 138
  // addHeight มีไว้เพิ่มความสูงของ layout บนเว็บ ไม่อย่างนั้นบนเว็บ layout จะพัง
  const addHeight = p.op.isWeb() ? 60 : 0

  let AppOverlayHeight = 180 + addHeight
  if (variantsLength === 1) {
  } else if (variantsLength === 2) {
    itemHeight = 53
    AppOverlayHeight = 240 + addHeight
  } else if (variantsLength === 3) {
    itemHeight = 78
    AppOverlayHeight = 270 + addHeight
  } else if (variantsLength === 4) {
    itemHeight = 103
    AppOverlayHeight = 310 + addHeight
  } else if (variantsLength >= 5) {
    itemHeight = 130
    AppOverlayHeight = 350 + addHeight
  }

  const warningText = WARNINGTEXT()

  return (
    <XOverlay
      contentStyle={{ padding: 8, width: 315, alignSelf: 'center', flexBasis: 'auto' }}
      visible={visible}
      // numColumns={1}
      // children={_renderChildren()}
      onRequestClose={() => (_.isFunction(onRequestClose) ? onRequestClose() : null)}>
      <Box w='full'>
        {/* HREADER */}
        <HStack w='full' h='35px' alignItems='center'>
          <Box flex={1}>
            <XText variant='active' bold>
              รายงาน ประวัติการเคลื่อนไหวสินค้า
            </XText>
          </Box>
          <XButton
            alignItems='center'
            justifyContent='center'
            variant='outline'
            onPress={() => Linking.openURL('https://xsf.sgp1.cdn.digitaloceanspaces.com/x/samples/rp/StockCard.xlsx')}>
            <XText alignSelf='center' color={COLORS.APP_MAIN}>
              ดูตัวอย่าง
            </XText>
          </XButton>
        </HStack>
        {/* DateRangPicker */}
        {/* <Box h='25px' w='full' mt='2'> */}
        <Box mt='2' />
        <Segment
          onSegmentChange={(newIndex) => onChangeSegment(newIndex)}
          options={DATE_RANGE}
          selectedValue={DATE_RANGE[selectedOptSegment]}
        />
        {/* </Box> */}
        {/* {VIEW_HEIGHT_TEN} */}
        <VStack w='full' h='20' mt='2' px='2'>
          <XDateRangePicker
            oneLineDateTime={false}
            // disabled={selectedOptSegment < 3}
            allowOnlyDaysFromToday={r_shpg_day}
            // allowDateRangeLimitDays={this.state.limitDay}
            selectedDates={selectedDateRange}
            onChangeDate={_onChangeDateRange}
          />
        </VStack>
        <XText color={COLORS.APP_MAIN} textAlign='center' justifyContent='center'>
          {warningText}
        </XText>
        {/* เลือก 1 ตัวเลือกสินค้าที่ต้องการ */}
        {variantsLength === 1 ? null : (
          <Box w='full'>
            <XText variant='active'>เลือก 1 ตัวเลือกสินค้าที่ต้องการ</XText>
            <Box style={[S.BORDER, { height: itemHeight }]}>
              <ScrollView>
                {variants.map((data: any, index: number) => {
                  let checker = false
                  if (_.isNil(selectedVariant) && index === 0) {
                    checker = true
                    seletedRadioBtn(data)
                  } else if (!_.isNil(selectedVariant) && selectedVariant.get('name') === data.get('name')) {
                    checker = true
                  }
                  // console.log('NAME => ', data.get('name'))
                  return (
                    <TouchableOpacity
                      style={{ height: 25, paddingLeft: 4, paddingTop: 2 }}
                      key={index}
                      onPress={() => seletedRadioBtn(data)}>
                      <View style={{ flexDirection: 'row' }}>
                        <XIcon
                          name={checker ? 'radio-button-on' : 'radio-button-off'}
                          family='Ionicons'
                          style={{ flex: 0, minWidth: 30 }}
                        />
                        <XText variant='inactive' pl='1' alignItems='center' justifyContent='center' numberOfLines={1}>{` ${data.get(
                          'name'
                        )}`}</XText>
                      </View>
                    </TouchableOpacity>
                  )
                })}
              </ScrollView>
            </Box>
          </Box>
        )}

        {VIEW_HEIGHT_TEN}
        {/* FOOTER */}
        <HStack w='full'>
          <TouchableOpacity
            style={{
              flex: 1,
              borderColor: COLORS.APP_MAIN,
              backgroundColor: COLORS.APP_MAIN,
              borderRadius: 7,
              alignSelf: 'center',
              marginRight: 4,
              justifyContent: 'center',
              height: 30,
              borderWidth: 1,
              flexDirection: 'row',
            }}
            onPress={async () => {
              let v
              if (variantsLength === 1) {
                v = firstVariants
              } else {
                v = selectedVariant
              }
              if (p.op.isIOS()) {
                // pKeng on 19 Jan 2024: Must close dialog before download on iOS
                // Otherwise, the native excel dialog will not be displayed
                onRequestClose()
                await util.delay(200)
                downloadHistoryReport(v)
              } else {
                downloadHistoryReport(v)
                onRequestClose()
              }
            }}>
            <XIcon
              family='FontAwesome'
              name='file-excel-o'
              style={{
                fontSize: STYLES.FONT_ICON_SMALLER,
                color: COLORS.WHITE,
                flex: 0,
                minWidth: 30,
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
                alignSelf: 'center',
              }}
            />
            <XText alignItems='center' justifyContent='center' alignSelf='center' color={COLORS.WHITE}>
              ดาวน์โหลดรายงาน
            </XText>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              width: 40,
              borderColor: COLORS.APP_MAIN,
              borderRadius: 7,
              height: 30,
              justifyContent: 'center',
              alignSelf: 'center',
              // borderWidth: 1,
              borderRightWidth: 1,
              borderBottomWidth: 1,
              borderLeftWidth: 1,
              borderTopWidth: 1,
            }}
            onPress={() => onRequestClose()}>
            <XText alignItems='center' justifyContent='center' alignSelf='center' color={COLORS.APP_MAIN}>
              ปิด
            </XText>
          </TouchableOpacity>
        </HStack>
      </Box>
    </XOverlay>
  )

  // return (
  //   // <View style={{ flex: 1, backgroundColor: COLORS.WHITE, padding: 8 }}>
  //   <XOverlay
  //     containerStyle={{ alignSelf: 'center', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}
  //     contentStyle={{ alignSelf: 'center', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}
  //     // contentStyle={{ height: XOverlayHeight, padding: 8, width: 315, alignSelf: 'center' }}
  //     webNumColumns={1}
  //     visible={visible}
  //     onRequestClose={() => onRequestClose()}>
  //     <View style={{ backgroundColor: COLORS.WHITE, paddingLeft: 4, paddingRight: 4 }}>
  //       {/* HREADER */}
  //       <View style={{ flex: 1, height: 30, flexDirection: 'row', alignItems: 'center' }}>
  //         <View style={{ flex: 1 }}>
  //           <Text
  //             style={{
  //               fontSize: STYLES.FONT_SIZE_LARGER,
  //               color: COLORS.TEXT_ACTIVE,
  //               fontWeight: 'bold',
  //             }}>{`รายงาน ประวัติการเคลื่อนไหวสินค้า`}</Text>
  //         </View>
  //         <View style={{ width: 60 }}>
  //           <TouchableOpacity
  //             style={{
  //               borderColor: COLORS.APP_MAIN,
  //               borderRadius: 7,
  //               borderWidth: 0.7,
  //               alignSelf: 'center',
  //               height: 20,
  //               backgroundColor: COLORS.BG_LIGHT_GREY,
  //             }}
  //             onPress={() => Linking.openURL('https://xsf.sgp1.cdn.digitaloceanspaces.com/x/samples/rp/StockCard.xlsx')}>
  //             <Text
  //               style={{
  //                 fontSize: STYLES.FONT_SIZE_NORMAL,
  //                 color: COLORS.APP_MAIN,
  //                 paddingLeft: 3,
  //                 paddingRight: 3,
  //                 justifyContent: 'center',
  //               }}>{`ดูตัวอย่าง`}</Text>
  //           </TouchableOpacity>
  //         </View>
  //       </View>
  //     </View>
  //     {isDownloadingHistoryReport ? (
  //       <View
  //         style={{
  //           flex: 1,
  //           position: 'absolute',
  //           top: 0,
  //           left: 0,
  //           backgroundColor: 'rgba(52, 52, 52, 0.7)',
  //           width: '110%',
  //           height: '110%',
  //           zIndex: 999,
  //           justifyContent: 'center',
  //         }}>
  //         <Spinner
  //           color={COLORS.APP_MAIN}
  //           size={'large'}
  //           style={{
  //             alignSelf: 'center',
  //             justifyContent: 'center',
  //           }}
  //         />
  //       </View>
  //     ) : null}
  //   </XOverlay>
  //   // </View>
  // )
}

export default ReportHistory

const stylesReport = StyleSheet.create({
  box: {
    height: 50,
    borderWidth: 1,
    borderColor: COLORS.TEXT_ACTIVE,
  },
})
