import React from 'react'
import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import { Clipboard } from 'react-native'

import * as util from 'x/utils/util'
import _ from 'lodash'
import moment from 'moment'

import { STYLES } from 'x/config/styles'
import p from 'x/config/platform-specific'
// import xCONS from 'x/config/constants'

import XCustomHeader from 'xui/components/XCustomHeader'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import XText from 'xui/components/XText'
import XCard from 'xui/components/XCard'
import XIcon from 'xui/components/XIcon'
import XInput from 'xui/components/XInput'
import XButton from 'xui/components/XButtonWithIcon'
import { IXScreenProps } from 'x/types'

const { FONT_SIZE_NORMAL, FONT_SIZE_SMALLER } = STYLES

const WHITE_COLOR = '#fff'

export interface Props extends IXScreenProps {
  addNotesOrder
  subscription
  order
  editingOrder
  fetchOrderNotes
  selectedStore
  addOrderNote
  editOrderNote
  deleteOrderNote
}

interface State {
  myNote: string
  outPutNote: string
  sellerNotes: string

  showSellerNote: boolean
  showMyNote: boolean
  showAddNote: boolean

  notes: any[]
  oldNote: string

  cancalBtn: boolean
}

const PRIVATE = 'PRIVATE'

const MY_NOTE = 'MY_NOTE'
const SELLER_NOTE = 'SELLER_NOTE'
const OUTPUT_NOTE = 'OUTPUT_NOTE'

const BLACK_COLOR = '#000'

export default abstract class BaseUIOrderPrivateAndPublicNotes extends React.Component<Props, State> {
  static displayName = 'BaseUIOrderPrivateAndPublicNotes'

  // static navigationOptions = ({ navigation }) => {
  //   const { state, goBack } = navigation

  //   const goBackButton = state.params ? state.params.goBackButton : null
  //   const headerTitle = state.params ? state.params.headerTitle : null
  //   // let inProcess = state.params ? state.params.inProcess : true
  //   // let handleSaveBtn = state.params ? state.params.handleSaveBtn : null
  //   return {
  //     headerLeft:
  //       state && state.params && state.params.inProcess ? null : (
  //         <Button
  //           transparent={true}
  //           onPress={() => {
  //             if (!_.isFunction(goBackButton)) {
  //               return
  //             } else {
  //               goBackButton()
  //             }
  //           }}>
  //           <BackIcon />
  //         </Button>
  //       ),
  //     headerRight: null,
  //     title: headerTitle,
  //   }
  // }

  constructor(props) {
    super(props)
    this.state = {
      myNote: null,
      outPutNote: null,
      sellerNotes: null,
      showSellerNote: false,
      showMyNote: false,
      showAddNote: false,
      notes: null,
      oldNote: null,
      cancalBtn: false,
    }
  }

  componentDidMount() {
    const { navigation, selectedStore } = this.props

    const focusedOrder = this._getFocusedOrder()
    // const { key } = state.params
    const key = util.getNavParam(this.props, 'key')
    let respond
    // let noteType = 4
    let showSellerNote
    let showMyNote
    let showAddNote

    let title

    // console.log(key)
    // console.log(focusedOrder.toJS())
    if (!_.isNil(focusedOrder.get('notes'))) {
      respond = focusedOrder.get('notes').toJS()
    }

    if (!_.isNil(respond)) {
      if (key === PRIVATE) {
        title = 'บันทึกช่วยจำสำหรับฉัน'
        const noteObj = _.find(respond, (note) => note.type === 3)
        if (_.isNil(noteObj)) {
          showSellerNote = false
          showMyNote = false
          showAddNote = true
        } else {
          respond = [noteObj]
          showSellerNote = false
          showMyNote = true
          showAddNote = false
        }
      } else {
        title = `ข้อความออเดอร์ ${focusedOrder.get('id')}`
        let text = 'r'
        if (focusedOrder.get('type') === 3) {
          text = 's'
        }
        const mynote = _.find(respond, (note) => note.created_by === 'm' && note.type === 4)
        const otherNote = _.find(respond, (note) => note.created_by === text && note.type === 4)

        if (!_.isNil(mynote)) {
          showMyNote = true
          showAddNote = false
        } else {
          showMyNote = false
          showAddNote = true
        }

        if (!_.isNil(otherNote)) {
          showSellerNote = true
        }
      }
    } else if (key === PRIVATE) {
      showSellerNote = false
      showMyNote = false
      showAddNote = true
    } else {
      showSellerNote = false
      showMyNote = false
      showAddNote = true
    }

    navigation.setParams({
      inProcess: false,
      headerTitle: title,
      // goBackButton: () => {
      //   this.goBackBtn()
      // },
    })
    this.setState({
      notes: respond,
      showSellerNote,
      showMyNote,
      showAddNote,
    })
  }

  _getFocusedOrder = () => {
    const { editingOrder, navigation } = this.props
    const sourceOrder = editingOrder
    const targetIndex = util.getNavParam(this.props, 'orderTabIndex', 0)
    return util.getFocusedOrder(sourceOrder, targetIndex)
  }

  goBackBtn = () => {
    const { oldNote, myNote, notes, outPutNote } = this.state
    const noteObj = _.find(notes, (note) => note.type === 3)
    if (oldNote !== myNote) {
      p.op.showConfirmation(
        null,
        'คุณต้องการยกเลิกการแก้ไขจริงๆใช่หรือไม่',
        () => {
          util.navGoBack(this.props)
        },
        () => {},
        'ใช่',
        'ไม่ใช่'
      )
    } else if (_.isNil(noteObj) && !_.isNil(outPutNote) && outPutNote.length > 0) {
      p.op.showConfirmation(
        'บันทึกข้อความ',
        'คุณต้องการบันทีกข้อความที่ระบุไว้หรือไม่',
        () => {
          util.navGoBack(this.props)
        },
        () => {},
        'ไม่บันทึก',
        'บันทึก'
      )
    } else {
      util.navGoBack(this.props)
    }
  }

  _renderSellerNote = () => {
    const { notes } = this.state

    const focusedOrder = this._getFocusedOrder()
    let text = 'r'
    if (_.isNil(focusedOrder.get('type'))) {
      return null
    }
    if (focusedOrder.get('type') === 3) {
      text = 's'
    }
    let who = 'ผู้ซื้อ'
    if (focusedOrder.get('type') === 3) {
      who = 'ผู้ขาย'
    }
    const otherNote = _.find(notes, (note) => note.created_by === text && note.type === 4)
    if (_.isNil(otherNote)) {
      return null
    }
    let dateTime
    const { updated_at } = otherNote
    if (!_.isNil(updated_at)) {
      dateTime = ` (เมื่อ ${moment(otherNote.updated_at).fromNow()})`
    }
    return (
      <VStack w='full' space='2'>
        <HStack w='full' alignItems='center'>
          <HStack flex={1} alignItems='center'>
            <XText variant='inactive'>{`จาก ${who} ถึง ฉัน`}</XText>
            <XText variant='inactive' fontSize='xs'>
              {!_.isNil(dateTime) ? dateTime : null}
            </XText>
          </HStack>

          <XButton
            w='100px'
            variant='outline'
            leftIcon={<XIcon name='copy' family='FontAwesome' />}
            onPress={() => this._copyToClipboard(otherNote.value)}>
            คัดลอก
          </XButton>
        </HStack>

        <XText>{otherNote.value}</XText>
      </VStack>
    )
  }

  // // FIXME: O => Artid นี่เป็นการเดา magic number ในฝั่งเว็บไม่ให้ layout พัง ถ้ามีโอกาสให้กลับมาแก้ด้วยครับ
  // getFixedWebLayoutTextLimitWidth = () => undefined

  _renderMyNote = () => {
    const { navigation, selectedStore } = this.props
    const { notes, myNote } = this.state
    let noteData = null
    // const { key } = state.params
    const key = util.getNavParam(this.props, 'key')
    const focusedOrder = this._getFocusedOrder()
    const orderType = focusedOrder.get('type')
    const to = orderType === 3 ? 'ผู้ขาย' : 'ผู้ซื้อ'

    let titleTxt = `จาก ฉัน ถึง ${to}`
    if (key === PRIVATE) {
      noteData = _.find(notes, (note) => note.type === 3)
      titleTxt = ''
    } else {
      noteData = _.find(notes, (note) => note.created_by === 'm' && note.type === 4)
      titleTxt = `จาก ฉัน ถึง ${to}`
    }
    if (_.isNil(myNote) && _.isNil(noteData)) {
      return null
    }
    const textNote = _.isNil(myNote) ? noteData.value : myNote
    let dateTime
    const { updated_at } = noteData
    if (!_.isNil(updated_at)) {
      dateTime = ` (เมื่อ ${moment(noteData.updated_at).fromNow()})`
    }
    return (
      // <View style={{ borderColor: COLORS.APP_MAIN, borderWidth: 1, margin: 4 }}>
      <VStack w='full' space='2' flexWrap='wrap'>
        <HStack w='full' alignItems='center' justifyContent='flex-end'>
          <HStack flex={1} flexWrap='wrap'>
            <XText variant='inactive'>{titleTxt}</XText>
            <XText variant='inactive' fontSize='sm'>
              {!_.isNil(dateTime) ? dateTime : null}
            </XText>
          </HStack>

          <XButton
            variant='outline'
            w='100px'
            leftIcon={<XIcon name='copy' family='FontAwesome' />}
            onPress={() => this._copyToClipboard(textNote)}>
            คัดลอก
          </XButton>
        </HStack>

        <HStack w='full' borderWidth='1' p='2' borderColor='muted.200' flexWrap='wrap'>
          <XText flex={1}>{textNote}</XText>
        </HStack>

        <HStack w='full' space='2' alignItems='center' justifyContent='space-between'>
          <XButton
            colorScheme='danger'
            w='100px'
            leftIcon={<XIcon name='trash-o' family='FontAwesome' />}
            onPress={() => this.deleteNote(noteData)}>
            ลบ
          </XButton>

          <XButton
            variant='outline'
            w='100px'
            leftIcon={<XIcon name='edit' family='FontAwesome' />}
            onPress={() => this.editNote(key, textNote)}>
            แก้ไข
          </XButton>
        </HStack>
      </VStack>
    )
  }

  // <XText style={{ fontSize: FONT_SIZE_NORMAL }}>
  //           {titleTxt}
  //         </XText>
  //       </Box>
  //       <Box style={{ paddingTop: 0 }}>
  //         <XText style={{ fontSize: FONT_SIZE_NORMAL, color: BLACK_COLOR }}>
  //           {textNote}
  //         </XText>
  //       </Box>
  //       <Box style={{ paddingTop: 0, marginRight: 4, marginLeft: 0 }}>
  //         <View style={{ flex: 1, justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'row' }}>
  //           <TouchableOpacity
  //             style={{
  //               width: 70,
  //               padding: 2,
  //               borderWidth: 1,
  //               borderRadius: 7,
  //               marginRight: 4,
  //               borderColor: COLORS.BRAND_Danger,
  //               backgroundColor: COLORS.BRAND_Danger,
  //               marginLeft: 4,
  //               alignItems: 'center',
  //             }}
  //             onPress={() => {
  //               this.deleteNote(noteData)
  //             }}>
  //             <XText style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: WHITE_COLOR }}>ลบ</XText>
  //           </TouchableOpacity>
  //           <TouchableOpacity
  //             style={{
  //               width: 40,
  //               padding: 2,
  //               borderWidth: 1,
  //               borderRadius: 7,
  //               marginRight: 1,
  //               // marginLeft: 12,
  //               // marginTop: -5,
  //               borderColor: COLORS.BRAND_Danger,
  //               backgroundColor: COLORS.BRAND_Danger,
  //               marginLeft: 4,
  //               alignItems: 'center',
  //             }}
  //             onPress={() => {
  //               this.deleteNote(noteData)
  //             }}>
  //             <XIcon name='trash-o' family={'FontAwesome'} style={{ fontSize: STYLES.FONT_ICON_SMALLER, color: WHITE_COLOR }} />
  //           </TouchableOpacity>
  //         </View>
  //         <View style={{ flex: 1, justifyContent: 'flex-end', flexDirection: 'row' }}>
  //           <TouchableOpacity
  //             style={{
  //               width: 70,
  //               padding: 2,
  //               borderWidth: 1,
  //               borderRadius: 7,
  //               borderColor: COLORS.APP_MAIN,
  //               backgroundColor: COLORS.BG_LIGHT_GREY,
  //               marginLeft: 4,
  //               alignItems: 'center',
  //             }}
  //             onPress={() => {
  //               this._copyToClipboard(textNote)
  //             }}>
  //             <XText style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.APP_MAIN }}>คัดลอก</XText>
  //           </TouchableOpacity>
  //           <TouchableOpacity
  //             style={{
  //               width: 70,
  //               padding: 2,
  //               borderWidth: 1,
  //               borderRadius: 7,
  //               marginRight: 4,
  //               borderColor: COLORS.APP_MAIN,
  //               backgroundColor: COLORS.BG_LIGHT_GREY,
  //               marginLeft: 4,
  //               alignItems: 'center',
  //             }}
  //             onPress={() => {
  //               this.editNote(key, textNote)
  //             }}>
  //             <XText style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.APP_MAIN }}>แก้ไข</XText>
  //           </TouchableOpacity>
  //         </View>

  _renderOutputNote = () => {
    // const { navigation, selectedStore } = this.props
    // const { key } = state.params
    const key = util.getNavParam(this.props, 'key')
    const focusedOrder = this._getFocusedOrder()
    const orderType = focusedOrder.get('type')
    let partner_name = ''
    if (!_.isNil(focusedOrder.get('partner_name'))) {
      partner_name = focusedOrder.get('partner_name')
    }
    const to = orderType === 3 ? 'ผู้ขาย' : 'ผู้ซื้อ'

    let titleTxt = `ฝากข้อความถึง ${to} ${partner_name}`
    if (key === PRIVATE) {
      if (!_.isNil(this.state.notes)) {
        titleTxt = `บันทีกนี้มีแต่ฉันที่เห็น และจะไม่แสดงบนใบปะหน้าพัสดุ`
      } else {
        titleTxt = `เพิ่มบันทึกช่วยจำสำหรับฉัน`
      }
    }
    return (
      <VStack w='full' space='2'>
        <XInput
          w='full'
          // selectTextOnFocus
          value={this.state.outPutNote}
          onChangeText={(text) => this.onChangeText(text)}
          minH={p.op.isWeb() ? '200px' : '80px'}
          placeholder='กรุณากรอกข้อความใหม่ในช่องนี้'
          //  placeholderTextColor={COLORS.TEXT_INACTIVE}
          multiline
        />

        <HStack space='2' alignItems='center' justifyContent='flex-end'>
          <XButton
            w='100px'
            leftIcon={<XIcon name='save' family='FontAwesome' />}
            onPress={() => {
              this._saveNote()
            }}>
            บันทึก
          </XButton>
          {this.state.cancalBtn ? (
            <XButton
              variant='outline'
              w='100px'
              leftIcon={<XIcon name='cancel' family='MaterialCommunityIcons' />}
              onPress={() => {
                this.cancalBtn()
              }}>
              ยกเลิก
            </XButton>
          ) : null}
        </HStack>
      </VStack>
    )
  }

  _saveNote = () => {
    const { notes, outPutNote, oldNote } = this.state
    if (outPutNote === oldNote) {
      p.op.showToast('กรุณาแก้ไขข้อความก่อนบันทึก', 'warning', 2000)
      return
    }
    const { navigation, selectedStore } = this.props
    const focusedOrder = this._getFocusedOrder()
    const focusedOrderNotes = focusedOrder.get('notes').toJS()
    let updated_at
    const myNoteToSeleted = _.find(focusedOrderNotes, (note) => note.type === 3 && note.created_by === 'm')
    if (!_.isNil(myNoteToSeleted)) {
      updated_at = myNoteToSeleted.updated_at
    }

    const key = util.getNavParam(this.props, 'key')
    if (key === PRIVATE) {
      const noteData = _.find(focusedOrderNotes, (note) => note.type === 3)
      if (_.isNil(noteData)) {
        // ถ้า noteData isNil แปลว่านี่คือการเพิ่ม Note
        this.props.addOrderNote({
          body: {
            store_id: selectedStore.get('id'),
            order_id: focusedOrder.get('id'),
            type: 3,
            value: outPutNote,
            // updated_at,
          },
          successCallback: (res) => {
            this.setState({
              showMyNote: true,
              myNote: outPutNote,
              showAddNote: false,
              outPutNote: null,
              notes: res.order.notes,
              cancalBtn: false,
              oldNote: outPutNote,
            })
          },
          failedCallback: () => {
            util.navGoBack(this.props)
            p.op.showToast('พบข้อผิดพลาด กรุณาทำรายการใหม่ภายหลัง', 'danger', 2000)
          },
        })
      } else {
        this.props.editOrderNote({
          body: {
            store_id: selectedStore.get('id'),
            order_id: focusedOrder.get('id'),
            note_id: noteData.id,
            value: outPutNote,
            updated_at,
          },
          successCallback: (res) => {
            this.setState({
              showMyNote: true,
              myNote: outPutNote,
              showAddNote: false,
              outPutNote: null,
              cancalBtn: false,
              oldNote: outPutNote,
              notes: res.order.notes,
            })
          },
          failedCallback: () => {
            util.navGoBack(this.props)
            p.op.showToast('พบข้อผิดพลาด กรุณาทำรายการใหม่ภายหลัง', 'danger', 2000)
          },
        })
      }
    } else {
      const noteData = _.find(focusedOrderNotes, (note) => note.created_by === 'm' && note.type === 4)
      if (_.isNil(noteData)) {
        // ถ้า noteData isNil แปลว่านี่คือการเพิ่ม Note
        this.props.addOrderNote({
          body: {
            store_id: selectedStore.get('id'),
            order_id: focusedOrder.get('id'),
            type: 4,
            value: outPutNote,
            // updated_at,
          },
          successCallback: (res) => {
            this.setState({
              showMyNote: true,
              myNote: outPutNote,
              showAddNote: false,
              outPutNote: null,
              notes: res.order.notes,
              oldNote: outPutNote,
            })
          },
          failedCallback: () => {
            util.navGoBack(this.props)
            p.op.showToast('พบข้อผิดพลาด กรุณาทำรายการใหม่ภายหลัง', 'danger', 2000)
          },
        })
      } else {
        const noteToSeleted = _.find(focusedOrderNotes, (note) => note.type === 4 && note.created_by === 'm')
        if (!_.isNil(noteToSeleted)) {
          updated_at = noteToSeleted.updated_at
        }
        this.props.editOrderNote({
          body: {
            store_id: selectedStore.get('id'),
            order_id: focusedOrder.get('id'),
            note_id: noteData.id,
            value: outPutNote,
            updated_at,
          },
          successCallback: (res) => {
            this.setState({
              myNote: outPutNote,
              showMyNote: true,
              showAddNote: false,
              outPutNote: null,
              oldNote: outPutNote,
              notes: res.order.notes,
            })
          },
          failedCallback: () => {
            util.navGoBack(this.props)
            p.op.showToast('พบข้อผิดพลาด กรุณาทำรายการใหม่ภายหลัง', 'danger', 2000)
          },
        })
      }
    }
  }

  editNote = (key, note) => {
    if (key === PRIVATE) {
      this.setState({
        outPutNote: note,
        oldNote: note,
        myNote: note,
        showMyNote: false,
        showAddNote: true,
        cancalBtn: true,
      })
    } else {
      this.setState({
        outPutNote: note,
        oldNote: note,
        showMyNote: false,
        showAddNote: true,
        cancalBtn: true,
      })
    }
  }

  cancalBtn = () => {
    // const { navigation, selectedStore } = this.props
    // const { key } = state.params
    const { outPutNote, notes, oldNote } = this.state
    if (!_.isNil(notes)) {
      if (outPutNote !== oldNote) {
        p.op.showConfirmation(
          'ยกเลิกการแก้ไข',
          'หากคุณยกเลิก เราจะไม่บันทึกการแก้ไขของคุณ',
          () => {
            this.setState({
              outPutNote: null,
              showMyNote: true,
              showAddNote: false,
              oldNote: this.state.myNote,
            })
          },
          () => {},
          'ใช่',
          'ไม่ใช่'
        )
      } else {
        this.setState({
          outPutNote: null,
          showMyNote: true,
          showAddNote: false,
          oldNote: this.state.myNote,
        })
      }
    } else if (!_.isNil(outPutNote) || outPutNote !== '') {
      p.op.showConfirmation(
        null,
        'คุณต้องการยกเลิกใช่หรือไม่',
        () => {
          this.setState({ outPutNote: null })
        },
        () => {},
        'ใช่',
        'ไม่ใช่'
      )
    }
  }

  onChangeText = (text) => {
    this.setState({ outPutNote: text })
  }

  deleteNote = (notes) => {
    const focusedOrder = this._getFocusedOrder()
    const orderNotes = focusedOrder.get('notes').toJS()
    let note
    const { navigation, selectedStore } = this.props

    if (_.isNil(notes)) {
      note = _.find(orderNotes, (myNote) => myNote.created_by === 'm')
    }

    // const updated_at = utilNative.getTimeFormatYYYYMMDDhhmmss()
    const { updated_at } = notes
    p.op.showConfirmation(
      null,
      'คุณต้องการลบข้อความนี้ใช่หรือไม่',
      () => {
        this.props.deleteOrderNote({
          body: {
            store_id: selectedStore.get('id'),
            order_id: focusedOrder.get('id'),
            note_id: notes.id,
            updated_at,
          },
          successCallback: (res) => {
            this.setState({
              showMyNote: false,
              myNote: null,
              showAddNote: true,
              outPutNote: null,
              oldNote: null,
              notes: res.order.notes,
            })
          },
          failedCallback: () => {
            util.navGoBack(this.props)
            p.op.showToast('พบข้อผิดพลาด กรุณาทำรายการใหม่ภายหลัง', 'danger', 2000)
          },
        })
      },
      () => {},
      'ใช่',
      'ไม่ใช่',
      true
    )
  }

  _copyToClipboard(note) {
    Clipboard.setString(note)
    p.op.showToast(`คัดลอก ${note} เรียบร้อยแล้ว`, 'success')
  }

  _renderHeader = () => {
    const focusedOrder = this._getFocusedOrder()
    let partner_name = ''
    if (!_.isNil(focusedOrder.get('partner_name'))) {
      partner_name = focusedOrder.get('partner_name')
    }
    // const { key } = state.params
    const key = util.getNavParam(this.props, 'key')
    let who = 'ผู้ซื้อ'
    if (focusedOrder.get('type') === 3) {
      who = 'ผู้ขาย'
    }
    if (key === PRIVATE) {
      const text = `บันทึกนี้ฉันเห็นแต่เพียงผู้เดียว`
      return (
        <VStack>
          <XText variant='inactive'>{text}</XText>
        </VStack>
      )
    }
    const text = `ข้อความในหน้านี้ทั้ง ${who} และ ฉัน จะเห็นเหมือนกัน`
    return (
      <VStack>
        <XText variant='inactive'>{text}</XText>
        <HStack>
          <XText variant='inactive'>{`${who}: `}</XText>
          <XText>{partner_name}</XText>
        </HStack>
      </VStack>
    )
  }

  renderHeader = () => {
    const { navigation } = this.props
    const title = util.getNavParam(this.props, 'headerTitle', '')
    const inProcess = util.getNavParam(this.props, 'inProcess', false)
    return <XCustomHeader title={title} headerLeftProps={{ backIcon: true, onPressItem: this.goBackBtn, submitting: inProcess }} />
  }

  renderContent = () => {
    const { showSellerNote, showMyNote, showAddNote } = this.state
    // const { navigation, selectedStore } = this.props
    // const { state, setParams, goBack } = navigation
    // const key = state.params.key
    return (
      <XContent>
        <VStack w='full' p='1'>
          <XCard>
            <XCard.Body>
              <VStack w='full'>
                {this._renderHeader()}
                {showSellerNote ? this._renderSellerNote() : null}
                {showMyNote ? this._renderMyNote() : null}
                {showAddNote ? this._renderOutputNote() : null}
              </VStack>
            </XCard.Body>
          </XCard>
        </VStack>
      </XContent>
    )
  }

  renderMain = () => (
    <XContainer>
      {this.renderHeader()}
      {this.renderContent()}
    </XContainer>
  )

  render() {
    return this.renderMain()
  }
}
