import React, { useEffect, useState } from 'react'
import { Dimensions } from 'react-native'

// import s from './_styles'

import * as util from 'x/utils/util'
import _ from 'lodash'
import p from 'x/config/platform-specific'
import CONS from 'x/config/constants'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContent from 'xui/components/XContent'
import XContainer from 'xui/components/XContainer'
// import EntypoIcon from 'react-native-vector-icons/Entypo'
import ShippingTypeSelectorSegmentView from 'xui/components/ShippingTypeSelectorSegmentView'
import api from 'x/utils/api'
// import {
//   getStoreSelected,
//   getMyStores,
//   getSellerStores,
//   getSelectedStore,
//   getLoadingInProgress,
//   getSubscription,
//   getSelectedProduct,
//   getSelectedStoreProductGroups,
//   getSelectedStoreWarehouses,
// } from 'x/redux/selectors'
import { ISelectedStoreMap, IXScreenProps } from 'x/index'
import { VIEW_LOADING } from 'xui/utils/BaseShareUI'
import XButton from 'xui/components/XButton'
import XText from 'xui/components/XText'
import XIcon from 'xui/components/XIcon'
import Box from 'xui/components/Box'
import NB from 'x/config/nativebase'

interface IPaymentAccountPickerProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
  addNewStoreSetting: (newSetting: any) => void
}

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const defaultVisibleShippingTypes: number[] = []
CONS.ORDER_VIEW_SHIPPING_TYPE_ITEMS.map((shipping: { value: number }) => {
  defaultVisibleShippingTypes.push(shipping.value)
})

const StoreSettingMenuShippingTypeView: React.FC<IPaymentAccountPickerProps> = (props) => {
  const { selectedStore, addNewStoreSetting } = props
  const [visibleShippingTypes, setVisibleShippingTypes] = useState<number[]>([])
  const [selectedShippingTypeIds, setSelectedShippingTypeIds] = useState<number[]>([])
  const [isSetDataBeforeRender, setIsSetDataBeforeRender] = useState<boolean>(false)
  // isUpdateSelectedShippingTypeIds ไว้ใช้สำหรับแยกว่าจะเป็นการ อัพเดตหรือสร้าง storesetting ตอนยิง api
  const [isUpdateSelectedShippingTypeIds, setIsUpdateSelectedShippingTypeIds] = useState<boolean>(false)
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  // const [isPickerVisible, setIsPickerVisible] = useState(false)
  // const selectedStore = getStoreSelected(p.op.getAppState())
  // console.log('selectedStore => ', selectedStore.toJS())
  useEffect(() => {
    // isSetDataBeforeRender เอาไว้ set ข้อมูลเริ่มต้น
    if (!isSetDataBeforeRender) {
      const storeSetting = selectedStore.has('store_setting') ? selectedStore.get('store_setting') : null
      const ui_show_only_shipping_type_ids = util.getVisibleShippingTypesIds()
      // console.log('ui_show_only_shipping_type_ids => ', ui_show_only_shipping_type_ids)
      setSelectedShippingTypeIds(ui_show_only_shipping_type_ids)
      setVisibleShippingTypes(defaultVisibleShippingTypes)

      if (!_.isNil(storeSetting)) {
        setIsUpdateSelectedShippingTypeIds(true)
      }
      // console.log('01 =>')
    }
    // console.log('02 =>')
    setIsSetDataBeforeRender(true)
  }, [])
  // console.log('ui_show_only_shipping_type_ids => ', ui_show_only_shipping_type_ids)
  // console.log('defaultVisibleShippingTypes => ', defaultVisibleShippingTypes)
  const onPressGoBack = () => {
    // const { state, goBack } = navigation
    // const { onPressGoBack } = state.params
    // // console.log('state.params => ', state.params)
    // goBack(state.key)
    util.navGoBack(props)
    const onPressGoBack = util.getNavParam(props, 'onPressGoBack')
    if (_.isFunction(onPressGoBack)) {
      onPressGoBack()
    }
  }

  const onPressGoBackAction = () => {
    if (isEditing) {
      p.op.showConfirmation(
        '',
        'ต้องการปิดและยกเลิกการแก้ไขข้อมูลใช่หรือไม่',
        () => {
          onPressGoBack()
        },
        () => {},
        'ตกลง',
        'แก้ไขต่อ'
      )
    } else {
      onPressGoBack()
    }
    return true
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  const _renderHeader = () => {
    // const { navigation } = this.props
    // const { state, goBack } = navigation
    // const leftBtn = { backIcon: true, onPressItem: () => goBack(state.key) }
    let rightMenu = () => (
      <XButton variant='ghost' onPress={() => _onSubmit()}>
        <XText variant='primary'>บันทึก</XText>
      </XButton>
      // <TouchableOpacity onPress={() => _onSubmit()} style={{ width: 50, height: 50, alignItems: 'center', justifyContent: 'center' }}>
      //   <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.APP_MAIN }}>บันทึก</Text>
      // </TouchableOpacity>
    )
    let leftMenu = () => (
      <XButton
        variant='ghost'
        onPress={() => {
          p.op.showConfirmation(
            '',
            'ต้องการปิดและยกเลิกการแก้ไขข้อมูลใช่หรือไม่',
            () => onPressGoBack(),
            () => null,
            'ตกลง',
            'แก้ไขต่อ'
          )
        }}>
        <XText variant='primary'>ยกเลิก</XText>
      </XButton>
      // <TouchableOpacity
      //   onPress={() => {
      //     p.op.showConfirmation(
      //       '',
      //       'ต้องการปิดและยกเลิกการแก้ไขข้อมูลใช่หรือไม่',
      //       () => onPressGoBack(),
      //       () => null,
      //       'ตกลง',
      //       'แก้ไขต่อ'
      //     )
      //   }}
      //   style={{ width: 50, height: 50, alignItems: 'center', justifyContent: 'center' }}>
      //   <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.APP_MAIN }}>ยกเลิก</Text>
      // </TouchableOpacity>
    )

    if (!isEditing) {
      rightMenu = () => null
      leftMenu = () => <XButton variant='ghost' onPress={() => onPressGoBack()} leftIcon={<XIcon name='arrow-back' family='Ionicons' />} />
    }

    return <XCustomHeader title='ซ่อน/แสดง รูปแบบจัดส่ง' renderHeaderRight={() => rightMenu()} renderHeaderLeft={() => leftMenu()} />
  }

  const _onSubmit = async () => {
    if (isLoading) {
      return
    }
    setIsLoading(true)
    const storeId = selectedStore.get('id')
    // console.log('selectedStore => ', selectedStore)
    const reqBody = {
      store_id: storeId,
      ui_show_only_shipping_type_ids: selectedShippingTypeIds,
    }

    let API_ACTION = api.putV2
    if (isUpdateSelectedShippingTypeIds) {
      API_ACTION = api.patchV2
    }

    try {
      const res = await API_ACTION(api.PUT_STORE_SETTING, reqBody)
      // console.log('_syncOrderWithMKPOrder res => ', res)
      if (!_.isNil(res)) {
        p.op.showToast('ตั้งค่า ซ่อน/แสดงรูปแบบจัดส่งเรียบร้อยแล้ว', 'success')
        addNewStoreSetting(res.store_setting)
        setIsEditing(false)
        setIsLoading(false)
        setIsUpdateSelectedShippingTypeIds(true)
        return
      }
      // @ts-ignore
      await util.setStatePromise(this, { isLoading: false })
    } catch (err) {
      // console.log('fetchOrderListSummary err => ', err)
      if (util.isXSellyErrorV2(err)) {
        // util.alertWithXSellyErrorV2(err.error)
      }
      setIsLoading(false)
    }
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  const _renderDescription = () => (
    <Box px='2' pt='2'>
      <XText variant='inactive'>
        การตั้งค่า ซ่อน/แสดง รูปแบบจัดส่ง จะส่งผลเวลาเลือกรูปแบบจัดส่งของร้าน จะปรากฎเฉพาะรูปแบบจัดส่งที่เลือกเท่านั้น
      </XText>
    </Box>
  )

  const _renderBody = () => {
    if (isSetDataBeforeRender) {
      return (
        <Box px='2' pt='2'>
          <ShippingTypeSelectorSegmentView
            headertitle='เลือกรูปแบบจัดส่ง'
            footerLable='ยืนยัน'
            visibleShippingTypes={visibleShippingTypes}
            activeShippingTypeIds={selectedShippingTypeIds}
            onSubmit={(callBackSelectedShippingTypeIds: number[]) => {
              setIsEditing(true)
              setSelectedShippingTypeIds(callBackSelectedShippingTypeIds)
            }}
          />
        </Box>
      )
    }
    return null
  }

  return (
    <XContainer>
      {_renderHeader()}
      <XContent px='2' pb='2'>
        <Box w='full' mt='2' borderRadius='md' _light={{ bg: 'white' }} _dark={{ bg: NB.C.D.BG.CLEAR }}>
          {_renderDescription()}
          {_renderBody()}
        </Box>
        {isLoading ? VIEW_LOADING(windowWidth, windowHeight) : null}
      </XContent>
    </XContainer>
  )
}

// // @ts-ignore disable react-navigation header
// StoreSettingMenuShippingTypeView.navigationOptions = {
//   header: null,
// }

export default StoreSettingMenuShippingTypeView
