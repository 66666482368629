import React from 'react'
import { StyleProp, TextStyle, StyleSheet } from 'react-native'

import XText from './XText'

interface IXTitleProps {
  value: string
  style: StyleProp<TextStyle>
}

// const DEFAULLT_X_TITLE_STYLE: TextStyle = {
//   fontSize: STYLES.FONT_SIZE_NORMAL,
//   color: COLORS.TEXT_ACTIVE,
//   fontWeight: 'bold',
//   marginLeft: 2,
//   marginBottom: 2,
// }

const XTitle: React.FC<IXTitleProps> = props => {
  if (!props.value) {
    return null
  }
  return (
    <XText allowFontScaling={false} variant='active' bold style={[StyleSheet.flatten(props.style)]}>
      {props.value}
    </XText>
  )
}

XTitle.defaultProps = {
  value: '',
  style: {},
}

export default XTitle
