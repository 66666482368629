import { Dispatch, useReducer } from 'react'
import constate from 'constate'
import _ from 'lodash'
import produce, { Draft } from 'immer'

const ACTIONS = {
  ADD: 'ADD',
  RESET_STATE: 'RESET_STATE',
  LOAD_ITEMS: 'LOAD_ITEMS',
  // ALLOCATE_EMPTY_PRODUCTS: 'ALLOCATE_EMPTY_PRODUCTS',
  REMOVE_ITEM_BY_INDEX: 'REMOVE_ITEM_BY_INDEX',
  REMOVE_ITEM_BY_KEY: 'REMOVE_ITEM_BY_KEY',
  PREPEND_ITEM: 'PREPEND_ITEM',
  REPLACE_ITEM_BY_KEY: 'REPLACE_ITEM_BY_KEY',
  SET_IS_LOADING_BY_OFFSET: 'SET_IS_LOADING_BY_OFFSET',
  SET_IS_REFRESHING: 'SET_IS_REFRESHING',
  SET_COUNT: 'SET_COUNT',
}

interface IInfiniteListItem {
  // id?: number
  // n: string
  // t?: string[]
  isEmpty?: boolean // เอาไว้บอกว่า item นี้ยังไม่ถูก load มา
  [key: string]: any
}

interface IInfiniteListState<T extends IInfiniteListItem> {
  items: T[]
  count: number
  isLoadingByOffset: {
    [key: number]: boolean
  }
  isRefreshing: boolean
  hasLoadMore: boolean
  errors?: any
}

const emptyItem: IInfiniteListItem = {
  isEmpty: true,
  // id: null,
  // n: '',
}

const generateEmptyItems = (n: number) => {
  const items = []
  for (let i = 0; i < n; i++) {
    items.push(emptyItem)
  }
  return items
}

// Define the initial state of our app
const initialState: IInfiniteListState<any> = {
  // items: generateEmptyItems(CONS.PRODUCTS_FETCH_LIMIT_INIT),
  items: [],
  // [
  // {
  //   id: 1,
  //   name: 'Knuckle',
  // },
  // {
  //   id: 2,
  //   name: 'Jumping Rope',
  // },
  // {
  //   id: 3,
  //   name: 'Battle Rope',
  // },
  // {
  //   id: 4,
  //   name: 'Tank-Top shirt',
  // },
  // ],
  count: null,
  isLoadingByOffset: {
    0: false,
  },
  isRefreshing: false,
  hasLoadMore: false,
  errors: null,
}

// Define a pure function reducer
const reducer = produce((draft: Draft<IInfiniteListState<any>>, action) => {
  const { type, payload } = action
  switch (type) {
    // case ACTIONS.ADD:
    //   draft.items.push(payload)
    //   return

    case ACTIONS.REMOVE_ITEM_BY_INDEX: {
      // payload is index
      if (_.isObject(draft.items[payload])) {
        draft.items.splice(payload, 1)
      }
      // draft.items = draft.items.filter((product) => product.id !== payload)
      return
    }
    case ACTIONS.REMOVE_ITEM_BY_KEY: {
      // const { key, filter }: IRemoveByKeyParams = payload
      // payload is { key, value }
      const { key, value }: IRemoveByKeyParams = payload
      const removeIndexs = []
      draft.items = draft.items.filter((product, index) => {
        const shouldStay = product[key] !== value
        if (!shouldStay) {
          removeIndexs.push(index)
        }
        return shouldStay
      })
      if (removeIndexs.length > 0) {
        draft.count -= removeIndexs.length
      }
      return
    }
    case ACTIONS.PREPEND_ITEM: {
      // const { key, filter }: IRemoveByKeyParams = payload
      // payload is { key, value }
      const item: IInfiniteListItem = payload
      if (_.isObject(item)) {
        draft.items.unshift(item)
        draft.count += 1
      }
      return
    }
    case ACTIONS.REPLACE_ITEM_BY_KEY: {
      // const { key, filter }: IRemoveByKeyParams = payload
      // payload is { key, value }
      const { key, value, newItem }: IReplaceByKeyParams = payload
      const foundItemIndex = draft.items.findIndex((it) => it[key] === value)
      if (foundItemIndex > -1) {
        draft.items[foundItemIndex] = newItem
      }
      return
    }

    case ACTIONS.SET_IS_LOADING_BY_OFFSET: {
      // return {
      //   isLoading: payload,
      // }
      if (typeof payload !== 'object' || typeof payload.offset !== 'number') {
        return
      }
      draft.isLoadingByOffset[payload.offset] = payload.isLoading
      if (payload.limit) {
        let predictedLength = payload.offset + payload.limit
        if (typeof draft.count === 'number' && predictedLength > draft.count) {
          predictedLength = draft.count
        }
        if (draft.items.length > 0 && draft.items.length < predictedLength) {
          draft.items = draft.items.concat(generateEmptyItems(predictedLength - draft.items.length))
        }
      }
      return
    }

    case ACTIONS.SET_IS_REFRESHING: {
      draft.isRefreshing = payload
      return
    }

    case ACTIONS.SET_COUNT: {
      draft.count = payload
      draft.hasLoadMore = draft.items.length < draft.count
      return
    }

    case ACTIONS.LOAD_ITEMS: {
      const { items = [], offset = 0 } = payload
      const hasEnoughSlot = draft.items.length >= offset + items.length
      const removeNum = hasEnoughSlot ? items.length : draft.items.length - offset
      draft.items.splice(offset, removeNum, ...items)
      if (draft.count) {
        draft.hasLoadMore = draft.items.length < draft.count
      }
      if (typeof draft.count === 'number' && draft.items.length > draft.count) {
        draft.items.length = draft.count
      }
      return
    }

    case ACTIONS.RESET_STATE: {
      return initialState
    }

    default: {
      throw new Error('No action case on InfiniteList Reducer')
    }
  }
})

export interface IRemoveByKeyParams {
  key: string
  value: any
  // filter: (item: IInfiniteListItem) => boolean // if true item will remove
}

export interface IReplaceByKeyParams {
  key: string
  value: any
  newItem: IInfiniteListItem
}

// Define your custom hook that contains your state, dispatcher and actions
export const useItems = () => {
  const [state, dp] = useReducer(reducer, initialState)
  // FIXME: by pO - cast type ใหม่ เนื่องจาก typescript web เลือกใช้ type ผิด
  const dispatch: Dispatch<any> = dp
  // const { items, count, isLoading, hasLoadMore } = state

  // const addItem = (name) => {
  //   dispatch({
  //     type: ACTIONS.ADD,
  //     payload: { id: parseInt(_.uniqueId('10')), n: name },
  //   })
  // }
  const resetState = () => dispatch({ type: ACTIONS.RESET_STATE })
  const loadItems = (newItems: IInfiniteListItem[] = [], offset = 0) => {
    dispatch({
      type: ACTIONS.LOAD_ITEMS,
      payload: {
        items: newItems,
        offset,
      },
    })
  }
  const removeItemByIndex = (index: number) => dispatch({ type: ACTIONS.REMOVE_ITEM_BY_INDEX, payload: index })
  const removeItemByKey = (params: IRemoveByKeyParams) => dispatch({ type: ACTIONS.REMOVE_ITEM_BY_KEY, payload: params })
  const prependItem = (item: IInfiniteListItem) => dispatch({ type: ACTIONS.PREPEND_ITEM, payload: item })
  const repalceItemByKey = (params: IReplaceByKeyParams) => dispatch({ type: ACTIONS.REPLACE_ITEM_BY_KEY, payload: params })
  const setIsLoadingByOffset = (isLoadingStatus = false, offset = 0, limit?: number) => {
    dispatch({
      type: ACTIONS.SET_IS_LOADING_BY_OFFSET,
      payload: {
        isLoading: isLoadingStatus,
        offset,
        limit,
      },
    })
  }
  const setIsRefreshing = (isRefreshingStatus = false) => dispatch({ type: ACTIONS.SET_IS_REFRESHING, payload: isRefreshingStatus })
  const setCount = (newCount: number) => dispatch({ type: ACTIONS.SET_COUNT, payload: newCount })
  // const allocateEmptyItems = (allocateNum: number) => dispatch({ type: ACTIONS.ALLOCATE_EMPTY_PRODUCTS, payload: allocateNum })

  return {
    ...state,
    resetState,

    // addItem,
    removeItemByIndex,
    removeItemByKey,
    prependItem,
    repalceItemByKey,

    loadItems,
    setIsLoadingByOffset,
    setIsRefreshing,
    // allocateEmptyItems,
    setCount,
  }
}

export type ConsumerContextType = ReturnType<typeof useItems>

// Share your custom hook
// export const [InfiniteListContextProvider, useInfiniteListContext] = constate(useItems)

// https://github.com/diegohaz/constate/pull/101
// const useInfiniteListContext = constate(useItems)
export const [InfiniteListContextProvider, useInfiniteListContext] = constate(useItems)

// export default useInfiniteListContext
