// import PropTypes from 'prop-types'

import BaseUIStoreSettingListView from 'xui/modules/store/storeSetting/BaseUIStoreSettingListView'

// const PULL_VOLUMN_DISCOUNT_ICON_IMAGE = require('../../img/volumn_discount.png')
// const ICON_MY_STORE = require('../../img/tb/tb_my0.png')

class StoreSettingListView extends BaseUIStoreSettingListView {
  // static displayName = 'StoreSettingListView'
}

export default StoreSettingListView
