import React from 'react'

import { connect } from 'react-redux'
import { getSelectedStore, getEditingStore } from 'x/redux/selectors'

import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import * as xFmt from 'x/utils/formatter'

import { fetchVerifySlipCreditSummary } from 'x/utils/api'
import * as util from 'x/utils/util'
import { logRender } from 'x/utils/util'

import XText from 'xui/components/XText'
import XCard from 'xui/components/XCard'
import XCustomHeader from 'xui/components/XCustomHeader'
import { ISelectedStoreMap, IXScreenProps, IVerifySlipCreditSummaryItem } from 'x/types'
import XSpinner from 'xui/components/XSpinner'
import time from 'x/utils/time'
import XContainer from '../../components/XContainer'
import XContent from '../../components/XContent'

interface IProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
}

interface IState {
  isInitialized?: boolean
  isRefreshing?: boolean
  unusableCreditItems: IVerifySlipCreditSummaryItem[]
}

class VerifySlipCreditSummaryUnusableView extends React.Component<IProps, IState> {
  static displayName = 'VerifySlipCreditSummaryUnusableView'

  constructor(props) {
    super(props)

    this.state = {
      isInitialized: false,
      isRefreshing: false,
      unusableCreditItems: [],
    }
  }

  async componentDidMount() {
    await this._onRefresh()
    await util.delay(150)
    await util.setStatePromise(this, { isInitialized: true })
  }

  _onRefresh = async () => {
    await util.setStatePromise(this, { isRefreshing: true })

    const { selectedStore } = this.props
    const store_id = selectedStore.get('id')

    try {
      const res = await fetchVerifySlipCreditSummary({ store_id, show_unusable_credits: true, offset: 0, limit: 20 })
      console.log('_onRefresh res', res)

      await util.setStatePromise(this, { unusableCreditItems: res.unusable_credits })
    } catch (err) {
      console.log('_onRefresh err', err)
    }

    await util.setStatePromise(this, { isRefreshing: false })
  }

  _goBack = () => {
    util.navGoBack(this.props)
  }

  renderCustomHeader = () => {
    // return null
    const { navigation } = this.props

    return (
      <XCustomHeader
        // headerStyle={fixedAndroidMarginTopStyle}
        headerLeftProps={{ backIcon: true, onPressItem: this._goBack }}
        // headerRightProps={headerRightProps}
        title='ประวัติเครดิตที่หมดแล้ว'
      />
    )
  }

  renderCreditListItem = (item: IVerifySlipCreditSummaryItem, index: number) => {
    const { remaining_credit, original_credit, created_at, expiration_date, updated_at } = item

    return (
      <XCard>
        <VStack w='full' p='2'>
          <HStack w='full' alignItems='center'>
            <XText variant='inactive' flex={1}>
              ยอดเครดิตคงเหลือทั้งหมด
            </XText>
            <XText w='150px' textAlign='right'>
              {xFmt.formatDecimal(remaining_credit)}
            </XText>
          </HStack>

          <HStack w='full' alignItems='center'>
            <XText variant='inactive' flex={1}>
              ยอดเครดิตที่เติม
            </XText>
            <XText w='150px' textAlign='right'>
              {xFmt.formatDecimal(original_credit)}
            </XText>
          </HStack>

          <HStack w='full' alignItems='center'>
            <XText variant='inactive' flex={1}>
              เติมเมื่อ
            </XText>
            <XText w='150px' textAlign='right'>
              {time.convertServerDateTimeToReadableDateTimeText(created_at)}
            </XText>
          </HStack>

          <HStack w='full' alignItems='center'>
            <XText variant='inactive' flex={1}>
              หมดอายุภายใน
            </XText>
            <XText w='150px' textAlign='right'>
              {time.convertServerDateTimeToReadableDateTimeText(expiration_date)}
            </XText>
          </HStack>

          {/* {remaining_credit !== original_credit ? (
            <HStack w='full' alignItems='center' justifyContent='flex-end'>
              <XButton w='160px' h='34px' bg='gray.100' variant='ghost' leftIcon={<XIcon name='text' family='MaterialCommunityIcons' />}>
                ดูประวัติการใช้งาน
              </XButton>
            </HStack>
          ) : null} */}
        </VStack>
      </XCard>
    )
  }

  renderCreditListItems = () => {
    const { unusableCreditItems, isRefreshing } = this.state

    if (isRefreshing) {
      return <XSpinner />
    }

    return unusableCreditItems.map(this.renderCreditListItem)
  }

  renderMain = () => {
    const { isInitialized } = this.state
    if (!isInitialized) {
      return null
    }

    return (
      <XContainer>
        {this.renderCustomHeader()}
        <XContent>
          <VStack w='full' p='2' space='2'>
            {this.renderCreditListItems()}
          </VStack>
        </XContent>
      </XContainer>
    )
  }

  render() {
    logRender(this)
    return this.renderMain()
  }
}

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
    editingStore: getEditingStore(state),
    // subscription: getSubscription(state),
  }),
  (dispatch) => ({
    dispatch,
    // submitEditing: bindActionCreators(StoreActions.submitEditingStore, dispatch),
    // resetEditingStore: bindActionCreators(StoreActions.resetEditingStore, dispatch),
    // storeActions: bindActionCreators(StoreState, dispatch),
  })
)(VerifySlipCreditSummaryUnusableView)
