import { Component } from 'react'
import _ from 'lodash'
import * as util from 'x/utils/util'
import p from 'x/config/platform-specific'
import { ActionApiParams, IAnyObject, ISelectedStoreMap } from 'x/index'
import api from 'x/utils/api'
import { IAddress, IProfile, ISelectedStore, IXScreenProps } from 'x/types'

export interface BaseAddressListViewProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
  customer: (body: { profile_id: number }) => void // TODO :: retrue respone coustomer
  deleteAddress: () => void

  saveNew: any
  saveEditing: (params: ActionApiParams) => void
  createAddress: (params: ActionApiParams) => void
  editOrgAddress: (params: ActionApiParams) => void
  addCustomer: (params: ActionApiParams) => void
}

export interface BaseAddressListViewState {
  addresses: IAddress[] | null
}

export default abstract class BaseAddressListView extends Component<BaseAddressListViewProps, BaseAddressListViewState> {
  protected constructor(props) {
    super(props)

    this.state = {
      // eslint-disable-next-line react/no-unused-state
      addresses: null,
    }
  }

  async componentDidMount() {
    await this._fetchAddresses()
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  _deleteAddress = async (address: IAddress) => {
    const isUserConfirmed = await new Promise((passConfirm) => {
      p.op.showConfirmation(
        'ลบที่อยู่',
        `ต้องการลบที่อยู่ ${address.name} ใช่หรือไม่`,
        () => passConfirm(true),
        () => passConfirm(false)
      )
    })
    if (isUserConfirmed) {
      // const { profile_id } = this.props.navigation.state.params
      const profile_id = util.getNavParam(this.props, 'profile_id')
      const apiOptions = {
        showSpinner: true,
      }
      const body = {
        address_id: address.id,
        type: _.isNil(profile_id) ? 'STORE' : 'PROFILE',
      }
      const res: { addresses: IAddress[] } = await api.del(api.DELETE_ADDRESS, body, apiOptions)
      // console.log('RED DELETE -> ', res)
      if (res.addresses) {
        await util.setStatePromise(this, {
          addresses: res.addresses,
        })
      }
    }
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  _succeedCallback = async (addresses: IAddress[]) => {
    await util.setStatePromise(this, {
      addresses,
    })
  }

  _fetchAddresses = async () => {
    // const { navigation } = this.props
    const params = util.getNavParams(this.props)
    const { profile_id, store_id, useSellerAddress, useSellerAddressCustomText } = params
    const apiOptions = {
      showSpinner: true,
    }
    // console.log('isFetchProfileAddresses => ', isFetchProfileAddresses)
    if (profile_id) {
      const reqBody = { profile_id }
      const res: {
        profile: IProfile
      } = await api.post(api.POST_CUSTOMER, reqBody, apiOptions)
      // const res: { profile: any } = await new Promise((resolve) => {
      //   customer({
      //     // @ts-ignore
      //     body: {
      //       profile_id: 1,
      //     },
      //     successCallback: resolve,
      //     failedCallback: resolve,
      //   })
      // })
      if (res.profile) {
        await util.setStatePromise(this, {
          addresses: res.profile.addresses,
        })
      }
    } else if (store_id) {
      const reqBody = { store_id, skip_count: true }
      const res: {
        store: ISelectedStore
      } = await api.post(api.POST_STORE, reqBody, apiOptions)
      // console.log('res => ', res.store)
      if (res.store) {
        const newAddress = res.store.addresses
        if (!_.isNil(useSellerAddress) && useSellerAddress) {
          const sellerAddress = {
            id: null,
            name: null,
            address1: _.isNil(useSellerAddressCustomText) ? '<< ใช้ที่อยู่ผู้ขายส่ง >>' : useSellerAddressCustomText,
          }
          const hasSellerAddress = []
          hasSellerAddress.push(sellerAddress)
          newAddress.forEach((address: IAddress) => {
            hasSellerAddress.push(address)
          })
          await util.setStatePromise(this, {
            addresses: hasSellerAddress,
          })
          // @ts-ignore
          this.props.loadList(hasSellerAddress)
          return
        }
        await util.setStatePromise(this, {
          addresses: newAddress,
        })
      }
    } else {
      util.log('___No Request Parameter____: ')
    }
  }

  _handleSubmitForm = async (type: string, addr: IAddress): Promise<boolean> => {
    // const { selectedAddress, editingAddress, navigation } = this.props
    // const { address, segmentLegalEntityTypeIndex, radioLegalEntityTypeMoreId } = this.state
    // const addressToFromJS = fromJS(address)
    // const { STORE_EDIT, CUSTOMER_EDIT, CUSTOMER_ADD, STORE_ADD } = CONS.ADDRESS_MODE_KEYS
    const store_id = util.getNavParam(this.props, 'store_id')
    const profile_id = util.getNavParam(this.props, 'profile_id')
    const body: IAnyObject = { ...addr }
    delete body.id

    if (!_.isEmpty(body)) {
      const address_id = addr.id ? addr.id : null
      // console.log('address => // ', address)
      // console.log('address_id => ', address_id)
      if (address_id) {
        body.address_id = address_id
      } else if (type === 'PROFILE') {
        body.addressable_id = profile_id
      } else if (type === 'STORE') {
        body.addressable_id = store_id
      }

      if (type !== 'CUSTOMER') {
        body.type = type
      }

      try {
        let isSuccess = false
        if (type !== 'CUSTOMER') {
          isSuccess = await this._doSaveNewAddress(body)
        } else {
          isSuccess = await this._dosaveCustomer(body)
        }
        return isSuccess
      } catch (err) {
        console.log('_handleSubmitForm err => ', err)
      }
    } else {
      p.op.showToast('ยังไม่มีการแก้ไขข้อมูลเกิดขึ้น', 'warning')
    }

    return false
  }

  _doSaveNewAddress = async (body): Promise<boolean> => {
    const apiOptions = {
      messages: {
        successMsg: 'สร้างที่อยู่สำเร็จ',
        // errorMsg: 'สร้างที่อยู่ล้มเหลว',
      },
      showSpinner: true,
    }
    const API_TO_GO = body.address_id ? api.patchV2 : api.putV2

    const reqBody = { ...body }

    // API นี้หลังบ้านต้องการ zipcode เป็น int ไม่งั้น error
    if (reqBody.postal_code && reqBody.postal_code.length === 5) {
      reqBody.postal_code = parseInt(reqBody.postal_code)
    }

    const res: { addresses: IAddress[] } = await API_TO_GO(api.PUT_ADDRESS, reqBody, apiOptions)

    if (res.addresses) {
      this._succeedCallback(res.addresses)
      return true
    }
  }

  _doSaveEditingAddress = async (body): Promise<boolean> => {
    const { navigation, saveEditing, editOrgAddress, selectedStore } = this.props
    // @ts-ignore
    // const { isStorePickMode, callbackResponse } = navigation.state && navigation.state.params ? navigation.state.params : false
    const isStorePickMode = util.getNavParam(this.props, 'isStorePickMode')
    const callbackResponse = util.getNavParam(this.props, 'callbackResponse')
    const newBody = body
    const orgz = selectedStore.get(`org`)
    if (!_.isNil(orgz) && orgz.size > 0) {
      const orgzToJS = orgz.toJS()
      newBody.org_id = orgzToJS[0].id
      delete newBody.type
      const response: any = await new Promise((resolveSave) => {
        editOrgAddress({
          body: newBody,
          isStorePickMode,
          successCallback: resolveSave,
          failedCallback: () => resolveSave(null),
        })
      })
      if (response && response.addresses) {
        this._succeedCallback(response.addresses)
        return true
      }
    } else {
      const response: any = await new Promise((resolveSave) => {
        saveEditing({
          body,
          isStorePickMode,
          successCallback: resolveSave,
          failedCallback: () => resolveSave(null),
        })
      })
      if (response) {
        this._succeedCallback(response.addresses)
        return true
      }
    }

    return false
  }

  _dosaveCustomer = async (body): Promise<boolean> => {
    // console.log('_dosaveCustomer => ')
    const { addCustomer, navigation } = this.props
    const data = {
      first_name: body.name,
      last_name: '',
      phone1: body.telephone,
      addresses: [body],
    }
    if (body.address2) {
      // @ts-ignore
      data.address2 = body.address2
    }

    try {
      const isSuccess = await new Promise<boolean>((resolveEdit) => {
        addCustomer({
          body: data,
          successCallback: () => resolveEdit(true),
          failedCallback: () => resolveEdit(false),
        })
      })

      return isSuccess
    } catch (error) {
      return false
    }
  }
}
