/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-unused-class-component-methods */
import React from 'react'
// import { NavigationScreenProp, NavigationState } from 'react-navigation'
import { IOrder, IOrderProductItem } from 'x/index'
import * as util from 'x/utils/util'
import _ from 'lodash'
import p from 'x/config/platform-specific'
import { IParcel } from './BaseMultiParcelView'

interface BaseSelectedProductMultiParcelViewProps {
  // navigation: NavigationScreenProp<NavigationState>
}

interface BaseSelectedProductMultiParcelViewState {
  products: IOrderProductItem[]
  selectedProduct: IOrderProductItem
  maxSelectedProduct: number
  inputSelectedProduct: number
  isVisibleOverlayEditProduct: boolean
  parcels: IParcel[]
  parcelIndex: number
  order: IOrder
  parcel: IParcel
}

export default class BaseSelectedProductMultiParcelView extends React.Component<
  BaseSelectedProductMultiParcelViewProps,
  BaseSelectedProductMultiParcelViewState
> {
  inProcess: boolean

  constructor(props) {
    super(props)
    this.state = {
      products: null,
      isVisibleOverlayEditProduct: false,
      selectedProduct: null,
      maxSelectedProduct: 0,
      inputSelectedProduct: 0,
      parcels: [],
      parcelIndex: 0,
      order: null,
      parcel: null,
    }
    this.inProcess = false
  }

  componentDidMount() {
    // await this._setConfig()
    // const { navigation } = this.props
    // console.log('navigation => ', navigation)
    // console.log('navigation // => ', navigation.state)
    // @ts-ignore
    const params = util.getNavParams(this.props)
    // console.log('params => ', params)
    const { order, parcels, parcelIndex } = params
    const { products } = order
    // console.log('products => ', products)
    this.setState({ products, parcels, parcelIndex, order })
  }

  _goBack = () => {
    // @ts-ignore
    util.navGoBack(this.props)
  }

  _closeOverlayEditProduct = () => {
    this.setState({ isVisibleOverlayEditProduct: false })
  }

  _openOverlayEditProduct = () => {
    this.setState({ isVisibleOverlayEditProduct: true })
  }

  _onPressEditProduct = (product: IOrderProductItem, maxSelectedProduct: number) => {
    this.setState({ selectedProduct: product, maxSelectedProduct, inputSelectedProduct: maxSelectedProduct })
    this._openOverlayEditProduct()
  }

  _checkMultiParcelCount = () => {
    const { parcels, order } = this.state
    if (_.isNil(order)) {
      return null
    }
    let countTotal = 0
    parcels.forEach((parcel) => {
      parcel.items.forEach((itam) => {
        countTotal += itam.qty
      })
    })
    // const { amountQty } = order
    let amountQty = 0
    const products = _.isNil(order) ? null : order.products
    if (!_.isNil(order)) {
      products.forEach((product) => {
        amountQty += product.qty
        amountQty -= product.shipped_qty
      })
    }
    return amountQty - countTotal
  }

  _submitSelectedProduct = () => {
    const { inputSelectedProduct, selectedProduct, parcel } = this.state
    const newParcel = _.isNil(parcel) ? { items: [] } : parcel
    // console.log('newParcel 01 => ', newParcel)
    if (newParcel.items.length < 1) {
      const newItem = {
        pp_id: selectedProduct.pp_id,
        qty: inputSelectedProduct,
      }
      newParcel.items.push(newItem)
    } else {
      const selectedProductId = selectedProduct.pp_id
      // console.log('selectedProductId => ', selectedProductId)
      const { items } = newParcel
      const newItems = items

      let indexofProduct = null
      items.forEach((item, idx) => {
        // console.log(' // => 01 ')
        // const newItem = _.cloneDeep(item)
        if (item.pp_id === selectedProductId) {
          // console.log(' // => 02 ')
          indexofProduct = idx
        }
      })
      if (_.isNil(indexofProduct)) {
        newItems.push({
          pp_id: selectedProduct.pp_id,
          qty: inputSelectedProduct,
        })
      } else {
        newItems[indexofProduct].qty = inputSelectedProduct
      }

      // console.log('newItems ? => ', newItems)
      // @ts-ignore
      newParcel.items = newItems
    }

    // console.log('newParcel => ', newParcel)
    this.setState({
      parcel: newParcel,
      selectedProduct: null,
      maxSelectedProduct: 0,
      inputSelectedProduct: 0,
      isVisibleOverlayEditProduct: false,
      // products,
    })
  }

  _selectedAllProduct = () => {
    const { parcel, products } = this.state
    const countTotal = this._checkMultiParcelCount()
    let countParcel = 0
    if (!_.isNil(parcel)) {
      parcel.items.forEach((item) => {
        countParcel += item.qty
      })
    }
    const isAllDone = countParcel === countTotal
    if (isAllDone) {
      this.setState({ parcel: { items: [] } })
    } else {
      const newParcel = { items: [] }
      products.forEach((product) => {
        const maxProduct = this._checkProductToList(product)
        if (maxProduct > 0) {
          newParcel.items.push({
            pp_id: product.pp_id,
            qty: maxProduct,
          })
        }
      })
      this.setState({ parcel: newParcel })
    }
  }

  _selectedProduct = async (product: IOrderProductItem) => {
    const { parcel } = this.state
    // let qtyPickNow = 0
    let checkbox = false
    const maxProduct = this._checkProductToList(product)
    if (!_.isNil(parcel)) {
      parcel.items.forEach((item) => {
        if (item.pp_id === product.pp_id) {
          // qtyPickNow = item.qty
          if (item.qty === maxProduct) {
            checkbox = true
          }
        }
      })
    }
    if (checkbox) {
      const newParcel = { items: [] }
      parcel.items.forEach((parcel) => {
        if (parcel.pp_id !== product.pp_id) {
          newParcel.items.push(parcel)
        }
      })
      await util.setStatePromise(this, {
        parcel: newParcel,
        // inputSelectedProduct: maxProduct,
        // selectedProduct: product,
      })
    } else {
      await util.setStatePromise(this, {
        inputSelectedProduct: maxProduct,
        selectedProduct: product,
      })
      this._submitSelectedProduct()
    }
  }

  // _checkProductToList = (product: IOrderProductItem) => {
  //   // Get the parcels from the state and the pp_id from the product
  //   const { parcels } = this.state
  //   const { pp_id } = product

  //   // Use the reduce function to calculate the productQty
  //   const productQty = parcels.reduce((qty, parcel) => {
  //     // Use reduce to sum the quantity of all items with the same pp_id in the parcel
  //     const itemsQty = parcel.items.reduce((sum, item) => {
  //       // If the item has the same pp_id as the product, add its quantity to the sum
  //       if (item.pp_id === pp_id) {
  //         return sum + item.qty
  //       }
  //       // Otherwise, just return the sum
  //       return sum
  //     }, 0)

  //     // Subtract the sum of itemsQty from the previous qty value
  //     return qty - itemsQty
  //   }, product.qty)

  //   // Return the final productQty
  //   return productQty
  // }
  _checkProductToList = (product: IOrderProductItem) => {
    const { parcels } = this.state
    const { pp_id, shipped_qty } = product
    let productQty = product.qty - shipped_qty
    parcels.forEach((parcel) => {
      parcel.items.forEach((item) => {
        if (item.pp_id === pp_id) {
          if (productQty === item.qty) {
            productQty = 0
          } else {
            productQty -= item.qty
          }
        }
      })
    })
    return productQty
  }

  _onPressFooter = () => {
    // @ts-ignore
    const params = util.getNavParams(this.props)
    // console.log('params => ', params)
    const { callBack } = params
    const { parcel, parcels } = this.state
    if (parcel.items.length < 1) {
      p.op.showConfirmationOkOnly('', 'กรุณาเลือกอย่างน้อย 1 รายการสินค้า')
      return
    }
    const newParcels = parcels
    newParcels.push(parcel)
    // console.log('_onPressFooter newParcels => ', newParcels)
    if (_.isFunction(callBack)) {
      callBack(newParcels)
      this._goBack()
      return
    }
    p.op.showConfirmationOkOnly('', 'เกิดข้อผิดพลาด (CALLBACK IS NOT FUNCTION)')
  }
}
