import React from 'react'
import { View, Text } from 'react-native'
import FastImage from 'react-native-fast-image'

import { connect } from 'react-redux'
// import { bindActionCreators } from 'redux'

import CONS from 'x/config/constants'
import { STYLES, COLORS, S } from 'x/config/styles'
import { IProductDetailItem } from 'x/index'
import { getSelectedStore } from 'x/redux/selectors'
import XSellyLogo from '../../components/XSellyLogo'

const ProductName = (props: { product: IProductDetailItem }) => {
  const pn = props.product.name
  return (
    <Text style={S.TEXT_ACTIVE} numberOfLines={1}>
      {pn}
    </Text>
  )
}

const ProductDesciption = (props: { product: IProductDetailItem }) => {
  const pn = props.product.description
  return (
    <Text style={{ fontSize: STYLES.FONT_SIZE_SMALLER, color: COLORS.TEXT_INACTIVE }} numberOfLines={1}>
      {pn}
    </Text>
  )
}

const ProductStoreNameWithIcon = ({ storeName }) => {
  return (
    <View style={S.ROW_CENTER}>
      <XSellyLogo width={STYLES.FONT_ICON_SMALLER} height={STYLES.FONT_ICON_SMALLER} />
      <Text style={[S.TEXT_INACTIVE, S.TEXT_SMALL, { marginLeft: 2 }]} numberOfLines={1}>
        {storeName}
      </Text>
    </View>
  )
}

const ProductThumbnail = (props: { product: IProductDetailItem }) => {
  if (!props.product || !props.product) {
    return null
  }
  const tn = props.product.thumbnail_uris[0] ? props.product.thumbnail_uris[0] : CONS.IMG_PLACEHOLDER_URL
  return <FastImage style={{ width: 54, height: 54 }} resizeMode='cover' source={{ uri: tn }} />
}

const CoreProductInfo = (props: { product: IProductDetailItem; storeName?: string }) => {
  const { product, storeName } = props
  if (!product) {
    return null
  }
  return (
    <View
      style={[
        S.BORDER_INACTIVE,
        S.BG_SECONDARY,
        S.WIDTH_FULL,
        { flexBasis: 'auto', height: 62, borderRadius: 0, flexDirection: 'column' },
      ]}>
      {/* <View style={[S.BORDER, { flexBasis: 'auto', height: 62, width: '100%', borderColor: COLORS.GREY, flexDirection: 'column' }]}> */}
      {/* <View style={{ width: 30 }} /> */}
      <View style={{ flexBasis: 'auto', flexDirection: 'row', alignItems: 'center' }}>
        <View style={[S.PADDING_4, { justifyContent: 'center', alignItems: 'center' }]}>
          <ProductThumbnail product={product} />
        </View>
        <View
          style={[
            S.FLEX,
            S.PADDING_4,
            S.COLUMN_LEFT_TOP,
            S.HEIGHT_FULL,
            // { height: '100%', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' },
          ]}>
          {storeName ? <ProductStoreNameWithIcon storeName={storeName} /> : storeName}
          <ProductName product={product} />
          <ProductDesciption product={product} />
        </View>
      </View>
    </View>
  )
}

const CoreProductInfoContainer = connect(
  (state) => {
    const selectedStore = getSelectedStore(state)
    return {
      // selectedProduct: getSelectedProduct(state),
      storeName: selectedStore ? selectedStore.get('name') : null,
    }
  },
  (dispatch) => {
    return {
      dispatch,
    }
  }
)(CoreProductInfo)

export default CoreProductInfoContainer

// export default CoreProductInfo
