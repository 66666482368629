import React from 'react'
import { View, Dimensions } from 'react-native'

import CONS from 'x/config/constants'

import { COLORS } from 'x/config/styles'
// import { COLORS as xCOLORS } from 'x/config/styles'
// import ImgBlock from '../../components/imgblock'
import _ from 'lodash'

import { log, setStatePromise, isSystemBankID } from 'x/utils/util'
// import BasePaymentView, { OPTION_COD_AMOUNT, OPTION_ORDER_AMOUNT } from 'x/modules/payment/BasePaymentView'
// import SwitchCard from '../../components/SwitchCard'
// import settings from "x/config/settings"
import p from 'x/config/platform-specific'
import { getConfig } from 'x/config/mode'
import moment from 'moment'

// import I18n from '../i18n'
// import BackIcon from '../../components/BackIcon'

// import s from './_style'
// import DatePicker from 'react-native-datepicker'
// import XCustomHeader from '../../components/XCustomHeader'
// import ImagePreview from '../../components/ImagePreview'
// import XSegment from '../../components/Segment'

// import * as indicator from '../../services/indicator'
// import StickyKeyboardTab from '../../components/StickyKeyboardTab'
// import * as utilN from '../../utils/util-native'
import XContent from 'xui/components/XContent'
import VStack from 'xui/components/VStack'
import XInput from 'xui/components/XInput'
import BaseUIPaymentView from 'xui/modules/payment/BaseUIPaymentView'

import DatePicker from 'react-datepicker'
import { Portal } from 'react-overlays'
import axios, { AxiosRequestConfig } from 'axios'
import { CommonActions } from '@react-navigation/native'
import XGalleryModal from '../../components/XGalleryModal'
import ThemeSizeContext from '../../context/ThemeSizeContext'

const FileSaver = require('file-saver')

// Fixed DatePicker inside Modal :: https://github.com/Hacker0x01/react-datepicker/issues/1111
const CalendarContainer = ({ children }) => {
  const el = document.getElementById('calendar-portal')

  return <Portal container={el}>{children}</Portal>
}

const DateTimeInput = (props) => {
  // console.log('DateTimeInput props => ', props)
  const { value, onClick, onChange, onBlur } = props
  return (
    <XInput
      style={{
        // ...s.txtNormal,
        color: COLORS.TEXT_PRIMARY,
        backgroundColor: COLORS.FORM_PRIMARY_BG,
        height: 44,
        marginLeft: 0,
        marginTop: 0,
        marginBottom: 0,
        paddingLeft: 10,
      }}
      keyboardType='default'
      // placeholder={''}
      // placeholderTextColor={COLORS.TEXT_INACTIVE}
      value={value}
      // onChangeText={handleChangeText}
      // onChangeText={onChange}
      onChange={onChange}
      onBlur={onBlur}
      multiline={false}
      blurOnSubmit
      onFocus={onClick}
    />
  )
}

// const InitView = () => (
//   <Spinner color={COLORS.APP_MAIN} />
// )

// const InitView = () => (
//   <Text>Loading...</Text>
// )

// const SwitchNavigator = createSwitchNavigator({
//   init: {
//     screen: InitView,
//   },
//   ol: {
//     screen: PaymentOrderListTabNavigatorContainer,
//   },
// }, {
//   initialRouteName: 'init',
// })

const config = getConfig()
const DEVICE_WIDTH = Dimensions.get('window').width

class PaymentView extends BaseUIPaymentView {
  static displayName = 'PaymentView'

  static navigationOptions = {
    header: null,
    title: null,
    headerLeft: null,
    headerRight: null,
  }
  // static router = SwitchNavigator.router

  static contextType = ThemeSizeContext

  context!: React.ContextType<typeof ThemeSizeContext>

  // waitingUploadCount: number
  // datePickerRef: React.RefObject<DatePicker>
  // inputRefs: any[]
  // scrollViewRef: any
  // imgBlockRef: any
  // isCheckingImages: boolean

  _renderMultilineInput = ({ key, placeholder, keyboardType }) => {
    const inputValue = this.props.editingPayment.get(key) || ''
    const handleChangeText = (value) => this.props.onPaymentChange({ key, value })
    return this._renderItemWithMarginLeftCard(
      <XInput
        style={{
          // ...s.txtNormal,
          color: COLORS.TEXT_PRIMARY,
          backgroundColor: COLORS.FORM_PRIMARY_BG,
          height: 100,
          marginLeft: 0,
          marginTop: 0,
          marginBottom: 0,
          paddingLeft: 10,
          flexBasis: 'auto',
        }}
        keyboardType={keyboardType || 'default'}
        placeholder={placeholder || ''}
        placeholderTextColor={COLORS.TEXT_INACTIVE}
        value={inputValue}
        onChangeText={handleChangeText}
        multiline
        blurOnSubmit={false}
      />
    )
  }

  // ==============  Abstract method (BEGIN) ==============
  _handleAfterAlertMissingField(fieldKey): void {
    // util.focusKeyRefIfExisted(this.inputRefs, fieldKey, this.scrollViewRef)
    if (fieldKey === 'seller_payment_account' && this.state.paymentMethod === CONS.PAYMENT_METHOD.BANK_TRANSFER) {
      p.op.alert(`กรุณา${this._getTxtAccountSelector()}`)
      // this.props.navigation.dispatch(
      //   NavActions.navToSellerStorePaymentAccountListView({
      //     store_id: this.payToStoreId,
      //     title: this.paymentAccountSelectionTitle,
      //   }),
      // )
    }
  }

  // async _handleAfterSuccessSubmitPayment(res: Response | AxiosResponse, isGBPay = false): Promise<void> {
  //   const { navigation } = this.props
  //   const fromPaymentList = navigation.getParam('fromPaymentList', false)
  //   // console.log('_handleAfterSuccessSubmitPayment fromPaymentList? => ', fromPaymentList)
  //   if (!isGBPay) {
  //     // p.op.showToast('บันทึกชำระสินค้าเสร็จสิ้น', 'success')
  //     this._goBack()
  //   }
  //   // if (fromPaymentList) {
  //   //   // navigation.dispatch(NavActions.pop(2))
  //   //   navigation.pop(2)
  //   // } else {
  //   //   navigation.goBack(navigation.state.key)
  //   // }
  // }

  navToPaymentOrderList = (params) => {
    const { navigation } = this.props
    navigation.dispatch(
      CommonActions.navigate({
        name: 'PaymentOrderListView',
        params,
        key: CONS.ORDERS_VIEW_MODE_PAYMENT,
      })
    )

    // this.props.navigation.navigate({
    //   routeName: 'PaymentOrderListView',
    //   params,
    //   key: CONS.ORDERS_VIEW_MODE_PAYMENT,
    // })

    // this.props.navigation.navigate({
    //   routeName: 'PaymentOrderListView',
    //   params,
    //   key: CONS.ORDERS_VIEW_MODE_PAYMENT,
    // })

    // // // log('_navToPaymentOrderList')
    // // const { orderId, myStoreId, orderStoreId, partnerStoreId, orderType, isShipBeforePay } = this._getParams()
    // // const { isRefund } = this.state
    // // // console.log('navToPaymentOrderList isRefund? => ', isRefund)
    // // this.props.navigation.navigate({
    // //   routeName: 'PaymentOrderListView',
    // //   params: {
    // //     store_id: myStoreId,
    // //     orderStoreId: isRefund && orderType !== 1 ? partnerStoreId : orderStoreId,
    // //     isRefund,
    // //     isCOD: this.getIsCOD(),
    // //     isShipBeforePay,
    // //   },
    // //   key: CONS.ORDERS_VIEW_MODE_PAYMENT,
    // // })

    // // this.props.navigation.navigate('ol',{
    // //     store_id: myStoreId,
    // //     orderStoreId: isRefund && orderType !== 1 ? partnerStoreId : orderStoreId,
    // //     isRefund,
    // //     isCOD: this.getIsCOD(),
    // //     isShipBeforePay,
    // //   },
    // // )

    // // this.props.navigation.dispatch(
    // //   NavActions.navToPaymentOrders({
    // //     store_id: myStoreId,
    // //     orderStoreId: isRefund && orderType !== 1 ? partnerStoreId : orderStoreId,
    // //     isRefund,
    // //     isCOD: this.getIsCOD(),
    // //     isShipBeforePay,
    // //   }),
    // // )
  }

  openGBPayQRCodeFileFromUrl = (imgUrl: string, fileName: string, fileType: string) => {
    const { navigation } = this.props
    // utilN.openFileFromUrl(imgUrl, fileName, 'png', () => navigation.goBack(navigation.state.key), true)
    console.log('openGBPayQRCodeFileFromUrl imgUrl => ', imgUrl)
    this.downloadQRCodeFromUrl(imgUrl)
  }

  downloadQRCodeFromUrl = async (endpoint: string): Promise<void> => {
    if (!endpoint) {
      return
    }
    await setStatePromise(this, { submitting: true })
    try {
      const fileMime = 'image/png'
      const token = await p.op.storageGet(CONS.AUTHENTICATION_STORAGE_KEY, false)
      const fetchToken = _.isString(token) && token.substring(0, 6) === 'Bearer' ? token : `Bearer ${token}`
      log('downloadRequisition fetchToken => ', fetchToken)
      let headers: any = {
        Accept: fileMime,
        'Content-Type': fileMime,
      }

      if (_.isString(fetchToken)) {
        headers = {
          ...headers,
          Authorization: fetchToken,
        }
      }

      // const options =  { headers: headers }
      const reqParams: AxiosRequestConfig = {
        headers,
        responseType: 'blob',
        // responseType: 'arraybuffer',
        // ...options,
        // onDownloadProgress: this.withPercentage((percent: number) => {
        //   // log("Download... ", percent);
        //   this.setState({ downloadProgress: percent })
        // }),
      }
      // log('sendRequest axios reqParams => ', reqParams)
      const res = await axios.get(endpoint, reqParams)
      log('downloadQRCodeFromUrl res => ', res)
      if (res && res.status > 201) {
        throw new Error(res.statusText)
      }

      if (res && res.data) {
        // let file = new File([res.data], { type: fileMime })
        // FileSaver.saveAs(file)
        const blob = new Blob([res.data], { type: fileMime })
        const nowDate = moment().format('DD-MM-YYYY_hh-mm')
        const fileName = `GBPay_QR_${nowDate}.png`

        await setStatePromise(this, { submitting: false })

        FileSaver.saveAs(blob, fileName)

        const base64image = await this.readBlobToBase64(blob)
        await setStatePromise(this, { isVisibleGBPayQRCodeImage: true, imgGBPayQRCode: base64image })

        log('downloadQRCodeFromUrl base64image => ', base64image)
        // await this._handleAfterSuccessSubmitPayment(res, true)
        // await this._handleAfterSuccessSubmitPayment()
      }
    } catch (err) {
      p.op.alert('เกิดข้อผิดพลาดในการดาวโหลด QR Code', 'กรุณาตรวจสอบการเชื่อมต่อินเตอร์เน็ต แล้วลองใหม่อีกครั้ง ' + `(${err})`)
      // alertWithXSellyErrorV2(err)
      await setStatePromise(this, { submitting: false })
      log('error downloading file err =>', err)
      // throw new Error(e)
    }
  }

  readBlobToBase64 = async (blob: Blob): Promise<string | null> =>
    new Promise<string | null>((resolve) => {
      try {
        const reader = new FileReader()
        reader.onloadend = function () {
          if (reader.result) {
            // @ts-ignore
            resolve(reader.result)
          } else {
            resolve(null)
          }
        }

        reader.readAsDataURL(blob)
      } catch (err) {
        log('readBlobToBase64 error => ', err)
        resolve(null)
      }
    })

  onRequestCloseGBPayQRCode = () => {
    this.setState({ isVisibleGBPayQRCodeImage: false })
    this._goBack()
  }

  showPaymentSpinner = () => {
    // indicator.isLoading()
    console.log('showPaymentSpinner')
  }

  hidePaymentSpinner = () => {
    // indicator.isReady()
    console.log('hidePaymentSpinner')
  }

  // ============== Abstract method (END) ==============

  // _onNavToSellerStorePaymentAccount = () => {
  //   this.props.navigation.dispatch(
  //     NavActions.navToSellerStorePaymentAccountListView({
  //       store_id: this.payToStoreId,
  //       title: this.paymentAccountSelectionTitle,
  //     }),
  //   )
  // }
  // _onChangePaymentAccount = (selectedPaymentAccount) => {
  //   const { selectSellerPaymentAccount } = this.props
  //   selectSellerPaymentAccount(selectedPaymentAccount)
  //   return selectedPaymentAccount
  // }

  // renderBankAccountCard = () => {
  //   const { isInitialized = false, isFetchingAccounts } = this.state
  //   if (!isInitialized || isFetchingAccounts) {
  //     return null
  //   }

  //   const { editingPayment } = this.props
  //   const payment = editingPayment
  //   // const bankAccount = payment.get('seller_payment_account')
  //   // console.log('bankAccount => ', bankAccount)
  //   return (
  //     <PaymentAccountPicker
  //       targetStoreId={this.payToStoreId}
  //       onChangePaymentAccount={this._onChangePaymentAccount}
  //       placeholderText={this._getTxtAccountSelector()}
  //     />
  //   )
  // }
  // renderBankAccountCard = (bankName: string, accountName: string, accountNumber: string, showAccountSelector: boolean) => {
  //   const { selectSellerPaymentAccount, paymentAccounts, editingPayment } = this.props
  //   const paymentAccountsExcludeGB: any[] = []
  //   const allPaymentAccounts = paymentAccounts && paymentAccounts.has(this.payToStoreId) ? paymentAccounts.get(this.payToStoreId) : List([])

  //   allPaymentAccounts.forEach((pa, idx) => {
  //     if (pa.get('bank_id') !== CONS.GBPAY_BANK_ID) {
  //       paymentAccountsExcludeGB.push({
  //         index: paymentAccountsExcludeGB.length,
  //         ...pa.toJS(),
  //       })
  //     }
  //   })

  //   // console.log('_renderBankAccountSelector paymentAccountsExcludeGB => ', paymentAccountsExcludeGB)

  //   const sellerPaymentAccountId = editingPayment.getIn(['seller_payment_account', 'id'])
  //   const selectedPaymentAccount = paymentAccountsExcludeGB.find((pae) => pae.id === sellerPaymentAccountId)

  //   // console.log('allPaymentAccounts => ', allPaymentAccounts.toJS())
  //   // console.log('selectedPaymentAccount => ', selectedPaymentAccount)

  //   return (
  //     <RSelect
  //       styles={{
  //         menuList: (base, state) => {
  //           // console.log('menuList base => ', base)
  //           // console.log('menuList state => ', state)
  //           return {
  //             ...base,
  //             // ':hover': COLORS.APP_MAIN,
  //             // color: COLORS.APP_MAIN,
  //             paddingBottom: 0,
  //             paddingTop: 0,
  //           }
  //         },
  //         dropdownIndicator: (base, state) => {
  //           // console.log('dropdownIndicator base => ', base)
  //           // console.log('dropdownIndicator state => ', state)
  //           return {
  //             ...base,
  //             ':hover': {
  //               color: COLORS.APP_MAIN,
  //             },
  //             // color: COLORS.APP_MAIN,
  //           }
  //         },
  //         // indicatorSeparator: (base, state) => {
  //         //   // console.log('indicatorSeparator base => ', base)
  //         //   return {
  //         //     ...base,
  //         //     ':hover': COLORS.APP_MAIN,
  //         //     // color: COLORS.APP_MAIN,
  //         //   }
  //         // },
  //         control: (base, state) => {
  //           // console.log('control base => ', base)
  //           // console.log('control state => ', state)
  //           return {
  //             ...base,
  //             '&:hover': {
  //               borderColor: COLORS.APP_MAIN,
  //             },
  //           }
  //         },
  //         placeholder: (base) => ({
  //           ...base,
  //           fontSize: STYLES.FONT_SIZE_NORMAL,
  //           color: COLORS.TEXT_INACTIVE,
  //           fontWeight: 'normal',
  //         }),
  //         container: (base) => ({
  //           ...base,
  //           // backgroundColor: colourOptions[2].color,
  //           margin: 2,
  //         }),
  //       }}
  //       theme={(theme) => ({
  //         ...theme,
  //         borderRadius: 0,
  //         colors: {
  //           ...theme.colors,
  //           primary25: COLORS.APP_SECONDARY,
  //           primary: COLORS.APP_MAIN,
  //         },
  //       })}
  //       blurInputOnSelect={true}
  //       isSearchable={false}
  //       placeholder={this._getTxtAccountSelector()}
  //       value={selectedPaymentAccount}
  //       onChange={(value, action) => {
  //         // console.log('on react-select change data => ', value)
  //         // console.log('on react-select change action => ', action)
  //         selectSellerPaymentAccount(value)
  //       }}
  //       options={paymentAccountsExcludeGB}
  //       components={{ Option: this.renderSelectComponentCustomOption, SingleValue: this.renderSelectComponentSingleValue }}
  //     />
  //   )

  // return this._renderItemWithMarginLeftCard(
  //   <Box button={true} onPress={this._onNavToSellerStorePaymentAccount}>
  //     <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
  //       <Card
  //         style={[
  //           // s.flexNoWrap,
  //           {
  //             flex: 1,
  //             borderTopWidth: 0,
  //             borderBottomWidth: 0,
  //             borderLeftWidth: 0,
  //             borderRightWidth: 0,
  //             width: '90%',
  //             marginRight: 10,
  //           },
  //         ]}
  //         transparent={true}>
  //         <Box cardBody={true}>
  //           <Stack>
  //             <VStack>
  //               {this._renderAccountLineBank(bankName)}
  //               {this._renderAccountLineName(accountName)}
  //               {this._renderAccountLineNumber(accountNumber)}
  //               {this._renderAccountSelector(showAccountSelector)}
  //             </VStack>
  //           </Stack>
  //         </Box>
  //       </Card>
  //       <View style={{ width: 20, justifyContent: 'center', alignItems: 'flex-end' }}>
  //         <Icon
  //           name='arrow-forward'
  //           style={{
  //             width: 20,
  //             fontSize: STYLES.FONT_ICON_NORMAL,
  //             color: COLORS.TEXT_PRIMARY,
  //             // width: 10,
  //             textAlign: 'right',
  //             // backgroundColor: 'red',
  //           }}
  //         />
  //       </View>
  //     </View>
  //   </Box>,
  // )
  // }

  renderSelectComponentCustomOption = (props) => {
    // console.log('Option props => ', props)
    const { data, innerProps, isDisabled, options, isFocused } = props
    if (isDisabled) return null
    const { index } = data
    const isLast = index === options.length - 1
    return (
      <div {...innerProps}>
        {/* your component internals */}
        <View
          style={{
            width: '100%',
            padding: 8,
            paddingBottom: isLast ? 4 : 8,
            borderBottomWidth: isLast ? 0 : 0.5,
            borderBottomColor: COLORS.TEXT_INACTIVE,
            backgroundColor: isFocused ? COLORS.APP_SECONDARY : 'white',
          }}>
          {this.renderBankAccountInfoComponentFromSelectData(data)}
        </View>
      </div>
    )
  }

  renderSelectComponentSingleValue = ({ children, ...props }) => (
    // console.log('SingleValue => props => ', props)
    <div {...props} style={{ width: '97%' }}>
      {this.renderBankAccountInfoComponentFromSelectData(props.data)}
    </div>
  )

  renderBankAccountInfoComponentFromSelectData = (data): JSX.Element => {
    const { account_number, name, bank_id } = data
    if (isSystemBankID(bank_id)) {
      return null
    }
    let bankNameKey
    const foundBankIndex = CONS.BANK_INFO.findIndex((sBank) => sBank.id === bank_id)
    if (foundBankIndex > -1) {
      bankNameKey = CONS.BANK_INFO[foundBankIndex].key
    } else {
      bankNameKey = CONS.BANK_INFO[0].key
    }
    const txtBankName = p.op.t(`Bank.${bankNameKey}`)
    return (
      <VStack>
        {this._renderAccountLineBank(txtBankName)}
        {this._renderAccountLineName(name)}
        {this._renderAccountLineNumber(account_number)}
        {/* {this._renderAccountSelector(showAccountSelector)} */}
      </VStack>
    )
  }

  // _onImgManagerRef = r => (this.imgManagerRef = r)
  // renderImageUpload = () => {
  //   const txtPrefixImageName = config.s3_prefix_type_payslip + this._getPrefixForImageSlip()
  //   // return <Text>I am ImgManager</Text>
  //   return (
  //     <View style={{ minHeight: 110, marginLeft: 25 }}>
  //       <ImgManager
  //         // ref={this._onImgManagerRef}
  //         ref={this.imgManagerRef}
  //         fileNamePrefix={txtPrefixImageName}
  //         metadata={[
  //           { key: 'p', width: 2000, height: 2000 },
  //           { key: 't', width: 1000, height: 1000 },
  //         ]}
  //         uploading={_.has(this.state, 'uploading') ? this.state.uploading : false}
  //         readonly={_.has(this.state, 'uploading') ? this.state.uploading : false}
  //         showUploaded
  //         maxLength={4}
  //       />
  //     </View>
  //   )
  // }

  handleDateTimeChangeAdapter = (date: Date) => {
    // console.log('handleDateTimeChangeAdapter date => ', date)
    // console.log('handleDateTimeChangeAdapter moment(date).format() => ', moment(date).format())
    // this.setState({ date })
    // this._onChangeDateTime(moment(date).format())

    this.onChangePaymentPostDate(date)
  }

  // แก้ปัญหาพิมพ์ลง input ตรงๆ ไม่ได้:: https://github.com/Hacker0x01/react-datepicker/issues/1414
  handleDateTimeChangeAdapterRaw = (evt: React.FocusEvent<HTMLInputElement> | any) => {
    // console.log('handleDateTimeChangeAdapterRaw evt => ', evt)
    // console.log('handleDateTimeChangeAdapterRaw typeof evt => ', typeof evt)
    const isDateEvt = _.isString(evt)
    let newValue = null

    if (isDateEvt) {
      newValue = moment(evt, this._getDateTimeFormat(true), 'th', false)
    }

    const isHtmlInputEvt = _.isNil(newValue) && evt && evt.target
    if (isHtmlInputEvt) {
      newValue = moment((evt.target as HTMLInputElement).value, this._getDateTimeFormat(false), 'th', true)
    }
    // console.log('handleDateTimeChangeAdapterRaw (event.target as HTMLInputElement) => ', (event.target as HTMLInputElement))
    // console.log('handleDateTimeChangeAdapterRaw newValue => ', newValue)
    // console.log('handleDateTimeChangeAdapterRaw newValue => ', newValue)
    if (newValue && _.isFunction(newValue.isValid) && newValue.isValid()) {
      // this._onChangeDateTime(moment(newValue).format())
      // @ts-ignore
      this.onChangePaymentPostDate(moment(newValue).format())
    }
  }

  // _getDateTimeFormat = () => 'd-MM-yyyy  HH:mm น.'
  // _getDateTimeFormat = () => 'd/MM/YYYY HH:mm'
  _getDateTimeFormat = (isMoment = false) => (isMoment ? 'D/MM/YYYY   HH:mm น.' : 'd/MM/yyyy   HH:mm น.')

  renderDateTimePickerCard = () => {
    const { paymentPostDate = new Date() } = this.state
    // const dateStr = this.props.editingPayment.get('post_date')
    // const date = _.isString(dateStr) ? moment(dateStr).toDate() : new Date()
    // const tDate = moment(date).format('DD/MM/YYYY')
    // const tTime = moment(date).format('HH:mm')
    // console.log('renderDateTimePickerCard date => ', date)
    return (
      <DatePicker
        popperContainer={CalendarContainer}
        popperModifiers={{
          flip: {
            // เอาไว้บอกตัว popper ว่าเราจะจำกัดขอบเขตไว้แค่ช่วงของใน Browser
            boundariesElement: 'window',
          },
        }}
        // selected={date}
        selected={paymentPostDate}
        onChange={this.handleDateTimeChangeAdapter}
        onChangeRaw={this.handleDateTimeChangeAdapterRaw}
        // showTimeInput={true}
        // timeInputLabel='เวลา'
        showTimeSelect
        disabledKeyboardNavigation
        timeFormat='HH:mm'
        timeIntervals={1}
        // dateFormat="d MMMM yyyy  HH:mm 'น.'"
        // dateFormat="d MMM yyyy  HH:mm 'น.'"
        dateFormat={this._getDateTimeFormat()}
        timeCaption='เวลา'
        dropdownMode='select'
        // @ts-ignore
        customInput={<DateTimeInput />}
        // strictParsing={true}
      />
    )

    // return (
    //   <View style={{ width: 190 }}>
    //     <TouchableOpacity onPress={this._onPressDateTimePicker}>
    //       <Item
    //         // onPress={() => this.datePickerRef.onPressDate()}>
    //         // button={true}
    //         style={{ height: 35, backgroundColor: COLORS.FORM_PRIMARY_BG, paddingLeft: 5, paddingRight: 5 }}>
    //         <Left>
    //           <Text allowFontScaling={false} style={[s.txtNormal, { fontWeight: 'bold', color: COLORS.TEXT_PRIMARY }]}>{`${tDate}`}</Text>
    //         </Left>
    //         {/* <Text style={s.txtRight}>{`${tTime} น.`}</Text> */}
    //         <Right style={{ borderBottomWidth: 0 }}>
    //           {/* <Icon name='arrow-forward' /> */}
    //           <Text allowFontScaling={false} style={[s.txtRight, { fontWeight: 'bold', color: COLORS.TEXT_PRIMARY }]}>{`${tTime} น.`}</Text>
    //           <DatePicker
    //             ref={this.datePickerRef}
    //             style={{ width: 0, height: 0 }}
    //             date={moment(date).format()}
    //             showIcon={false}
    //             hideText={true}
    //             mode='datetime'
    //             androidMode='default'
    //             placeholder='เลือกวันเวลาที่โอนเงิน'
    //             // format='DD-MM-YYYY hh:mm'
    //             // format='YYYY-MM-DD hh:mm'
    //             confirmBtnText='ตกลง'
    //             cancelBtnText='ยกเลิก'
    //             is24Hour={true}
    //             customStyles={{
    //               // ... You can check the source to find the other keys.
    //               dateInput: {
    //                 borderWidth: 0,
    //               },
    //               dateText: {
    //                 color: COLORS.TEXT_ACTIVE,
    //                 fontSize: STYLES.FONT_SIZE_NORMAL,
    //               },
    //             }}
    //             // onDateChange={newDate =>
    //             //   this.props.onPaymentChange({
    //             //     key: 'date',
    //             //     value: moment(newDate).format(),
    //             //   })
    //             // }
    //             onDateChange={this._onChangeDateTime}
    //             TouchableComponent={TouchableOpacity}
    //           />
    //         </Right>
    //       </Item>
    //     </TouchableOpacity>
    //   </View>
    // )
  }

  // render() {
  //   logRender(this)
  //   const { contentOneOfThreeColumnWidthStyle, contentTwoOfThreeColumnWidthStyle, contentHeightStyle } = this.context
  //   const { previewImageURI, previewVisible, isGBPayMode, orderType = 0 } = this.state
  //   const { navigation } = this.props
  //   // log('___this.datePickerRef_: ', this.datePickerRef)
  //   return (
  //     <XModal numColumns={2} visible={true} onRequestClose={this._goBack}>
  //       <Stack style={[{ flex: 1, alignSelf: 'center' }, contentTwoOfThreeColumnWidthStyle, contentHeightStyle]}>
  //         <VStack style={[{ flex: 1 }, contentOneOfThreeColumnWidthStyle]}>
  //           <XContainer>
  //             <XContent scrollEnabled={true} contentContainerStyle={{ flex: 1, backgroundColor: 'white' }}>
  //               {this._renderPaymentForm()}
  //             </XContent>
  //             {this._renderFooter()}
  //           </XContainer>
  //         </VStack>
  //         <VStack style={[{ flex: 1 }, contentOneOfThreeColumnWidthStyle]}>
  //           <XContainer>
  //             <XContent contentContainerStyle={{ flex: 1, backgroundColor: 'white' }}>
  //               <SwitchNavigator
  //                 // @ts-ignore
  //                 navigation={navigation} />
  //             </XContent>
  //           </XContainer>
  //         </VStack>
  //       </Stack>
  //       <XGalleryModal
  //         isVisible={this.state.isVisibleGBPayQRCodeImage}
  //         initialIndex={0}
  //         images={[this.state.imgGBPayQRCode]}
  //         onRequestClose={this.onRequestCloseGBPayQRCode}
  //       />
  //     </XModal>
  //   )
  // }

  renderContent = () => (
    <XContent p='1'>
      {this.renderPaymentForm()}
      <XGalleryModal
        isVisible={this.state.isVisibleGBPayQRCodeImage}
        initialIndex={0}
        images={[this.state.imgGBPayQRCode]}
        onRequestClose={this.onRequestCloseGBPayQRCode}
      />
    </XContent>
  )

  render() {
    return this.renderMain()
  }

  // render() {
  //   logRender(this)
  //   const {
  //     contentOneOfThreeColumnWidthStyle,
  //     contentTwoOfThreeColumnWidthStyle,
  //     contentHeightStyle,
  //     contentWithNavHeaderHeightStyle,
  //   } = this.context
  //   // const { previewImageURI, previewVisible, orderType = 0 } = this.state
  //   // const { navigation } = this.props
  //   // log('___this.datePickerRef_: ', this.datePickerRef)

  //   return (
  //     <XContainer>
  //       <XCustomHeader headerLeftProps={{ backIcon: true, onPressItem: this._goBack }} title={this.getHeaderTitle()} />
  //       <XContent
  //         scrollEnabled={false}
  //         contentContainerStyle={[
  //           {
  //             flex: 1,
  //             backgroundColor: 'white',
  //             paddingRight: CONS.SPECIFIC_PLATFORM.WEB.SCROLLBAR_WIDTH,
  //             paddingLeft: 2,
  //           },
  //           contentOneOfThreeColumnWidthStyle,
  //           contentWithNavHeaderHeightStyle,
  //         ]}>
  //         {this._renderPaymentForm()}
  //       </XContent>
  //       {this._renderFooter()}
  //       <XGalleryModal
  //         isVisible={this.state.isVisibleGBPayQRCodeImage}
  //         initialIndex={0}
  //         images={[this.state.imgGBPayQRCode]}
  //         onRequestClose={this.onRequestCloseGBPayQRCode}
  //       />
  //     </XContainer>
  //   )

  //   // return (
  //   //   <Stack style={[{ flex: 1, alignSelf: 'center', flexDirection: 'column' }, contentOneOfThreeColumnWidthStyle, contentHeightStyle]}>
  //   //     <VStack style={[{ flex: 1 }, contentOneOfThreeColumnWidthStyle]}>
  //   //       <XContainer>
  //   //         <XContent scrollEnabled={true} contentContainerStyle={{ flex: 1, backgroundColor: 'white' }}>
  //   //           <XCustomHeader
  //   //             headerLeftProps={{ backIcon: true, onPressItem: this._goBack }}
  //   //             title={this.getHeaderTitle()} />
  //   //           {this._renderPaymentForm()}
  //   //         </XContent>
  //   //         {this._renderFooter()}
  //   //       </XContainer>
  //   //     </VStack>
  //   //     <XGalleryModal
  //   //       isVisible={this.state.isVisibleGBPayQRCodeImage}
  //   //       initialIndex={0}
  //   //       images={[this.state.imgGBPayQRCode]}
  //   //       onRequestClose={this.onRequestCloseGBPayQRCode}
  //   //     />
  //   //   </Stack>
  //   // )
  // }
}

export default PaymentView
