import { connect } from 'react-redux'

import LoginView from './LoginView'

export default connect(
  (state) => ({
    //
  }),
  (dispatch) => {
    return {
      // onBarcodeRead: bindActionCreators(BarcodeListenerActions.onBarcodeRead, dispatch),
      dispatch,
    }
  }
)(LoginView)
