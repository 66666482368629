import React, { PureComponent } from 'react'

import { StyleSheet } from 'react-native'
// import Icon from 'xui/components/Icon'
import _ from 'lodash'
import { STYLES, COLORS } from 'x/config/styles'
import NB from 'x/config/nativebase'
// import { Pressable } from 'native-base'
import HStack from 'xui/components/HStack'
import XIcon from './XIcon'
import XText from './XText'
import XPressable from './XPressable'
import MkpLogo from './MkpLogo'

interface IXCheckButtonProps<DataType = any> {
  checked: boolean
  onPress?: (args: {
    index?: number
    data?: DataType
    name?: string
    // current checked
    checked: boolean
  }) => void
  disabled?: boolean
  index?: number
  name?: string
  data?: DataType
  label: string
  iconStyle?: { [key: string]: any }
  buttonStyle?: { [key: string]: any }
  mkp_id?: number
}

export default class XCheckButton<T> extends PureComponent<IXCheckButtonProps<T>> {
  _onPress = () => {
    const { index, name, data, onPress, checked } = this.props
    if (_.isFunction(onPress)) {
      onPress({ index, data, name, checked })
    }
  }

  render() {
    const { checked = false, label = 'test', disabled = false, iconStyle = {}, buttonStyle = {}, mkp_id = null } = this.props
    // let btnStyle: ViewStyle = checked ? s.BUTTON_ACTIVE : s.BUTTON_INACTIVE
    // let lblStyle: TextStyle = checked ? s.LABEL_ACTIVE : s.LABEL_INACTIVE
    // if (disabled) {
    //   // @ts-ignore
    //   btnStyle = StyleSheet.flatten([s.BUTTON_INACTIVE, { backgroundColor: COLORS.BG_LIGHT_GREY }])
    //   // @ts-ignore
    //   lblStyle = StyleSheet.flatten([s.LABEL_ACTIVE, { color: COLORS.TEXT_ACTIVE }])
    // }
    // const checkIconStyle = disabled ? StyleSheet.flatten([s.ICON_CHECKMARK, { color: COLORS.TEXT_ACTIVE }]) : s.ICON_CHECKMARK
    const mkpIconStyle = { width: 20, height: 20, color: COLORS.APP_MAIN, textAlign: 'center' }
    // const mkpIconStyle = checked
    //   ? { width: 20, height: 20, color: COLORS.APP_MAIN, textAlign: 'center' }
    //   : {
    //       width: 20,
    //       height: 20,
    //       color: COLORS.TEXT_INACTIVE,
    //       textAlign: 'center',
    //       marginLeft: STYLES.FONT_ICON_NORMAL + 9,
    //     }
    /// MKP ICON
    let mkpIcon = null
    // if (!_.isNil(mkp_id)) {
    //   if (mkp_id === 1) {
    //     mkpIcon = <ShopeeLogo style={mkpIconStyle} width={20} />
    //   } else if (mkp_id === 2) {
    //     mkpIcon = <LazadaLogo style={mkpIconStyle} width={20} />
    //   }
    // }
    if (!_.isNil(mkp_id)) {
      mkpIcon = <MkpLogo mkpId={mkp_id} style={mkpIconStyle} width={20} />
    }

    return (
      <XPressable disabled={disabled} onPress={this._onPress}>
        {/* {({ isHovered, isPressed }) => ( */}
        <HStack
          px='2'
          py='1'
          space='1'
          alignItems='center'
          // bg={isHovered ? 'primary.200' : undefined}
          // opacity={isPressed ? 0.8 : undefined}
          borderWidth='1'
          // borderColor={checked ? NB.HC.TEXT.ACTIVE() : NB.HC.TEXT.INACTIVE()}
          borderColor={checked ? NB.C.L.TEXT.ACTIVE : NB.C.L.TEXT.INACTIVE}
          borderRadius='lg'
          style={buttonStyle}
          // style={[btnStyle, buttonStyle]}
        >
          {checked ? (
            <XIcon name='checkmark' style={StyleSheet.flatten(iconStyle)} />
          ) : (
            <XIcon name='checkmark' color='transparent' style={StyleSheet.flatten(iconStyle)} />
          )}
          {!_.isNil(mkpIcon) ? mkpIcon : null}
          <XText
            variant={checked ? 'active' : 'inactive'}
            numberOfLines={1}
            //  style={lblStyle}
          >
            {label}
          </XText>
        </HStack>
        {/* )} */}
      </XPressable>
    )
    // return (
    //   <TouchableOpacity style={[btnStyle, buttonStyle]} disabled={disabled} onPress={this._onPress}>
    //     <View style={s.COLUMN_LEFT}>
    //       {/* <Icon name={'checkmark'} style={[s.ICON_CHECKMARK, iconStyle]} /> */}
    //       {checked ? (
    //         <XIcon name='checkmark' style={StyleSheet.flatten([checkIconStyle, iconStyle])} />
    //       ) : (
    //         <View style={StyleSheet.flatten([s.ICON_CHECKMARK_HOLDER, iconStyle])} />
    //       )}
    //     </View>
    //     <View style={[s.COLUMN_RIGHT, { flexDirection: 'row' }]}>
    //       {!_.isNil(mkpIcon) ? mkpIcon : null}
    //       {/* <Text style={[lblStyle, { marginLeft: !_.isNil(mkp_id) ? 6 : checked ? 0 : 30 }]}>{label}</Text> */}
    //       <Text style={lblStyle}>{label}</Text>
    //     </View>
    //   </TouchableOpacity>
    // )
  }
}

const s = StyleSheet.create({
  COLUMN_LEFT: {
    // flex: 0,
    // width: 44,
    alignItems: 'center',
    justifyContent: 'center',
  },
  COLUMN_RIGHT: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  BUTTON_ACTIVE: {
    // minWidth: 120,
    // height: 32,
    borderTopWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderColor: COLORS.APP_MAIN,
    // borderRadius: 4,
    // flexDirection: 'row',
    // alignItems: 'center',
    // justifyContent: 'center',
  },
  BUTTON_INACTIVE: {
    // minWidth: 120,
    // height: 32,
    borderTopWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderColor: COLORS.TEXT_INACTIVE,
    // borderRadius: 4,
    // flexDirection: 'row',
    // alignItems: 'center',
    // justifyContent: 'center',
  },
  LABEL_ACTIVE: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.APP_MAIN,
    textAlign: 'center',
    marginRight: STYLES.FONT_ICON_NORMAL,
    marginLeft: 6,
  },
  LABEL_INACTIVE: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_INACTIVE,
    textAlign: 'center',
    marginRight: STYLES.FONT_ICON_NORMAL,
    marginLeft: 6,
  },
  ICON_CHECKMARK: {
    // flex: 0,
    // flexBasis: 'auto',
    fontSize: STYLES.FONT_ICON_NORMAL,
    minWidth: STYLES.FONT_ICON_NORMAL,
    width: STYLES.FONT_ICON_NORMAL,
    height: STYLES.FONT_ICON_NORMAL,
    color: COLORS.APP_MAIN,
    textAlign: 'center',
    marginLeft: 3,
    marginRight: 6,
    paddingTop: 3,
  },
  ICON_CHECKMARK_HOLDER: {
    // flex: 0,
    // flexBasis: 'auto',
    minWidth: STYLES.FONT_ICON_NORMAL,
    width: STYLES.FONT_ICON_NORMAL,
    height: STYLES.FONT_ICON_NORMAL,
    marginLeft: 3,
    marginRight: 6,
    paddingTop: 3,
  },
})
