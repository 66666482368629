import React from 'react'
import _ from 'lodash'

import * as NavActions from 'x/utils/navigation'
import {
  IProductListViewPullSellersProductsToOrderNavParams,
  IProductItemData,
  IProductListViewProps,
  IProductListViewState,
} from 'x/index'
import * as util from 'x/utils/util'

import BaseUIProductListView from './BaseUIProductListView'
import QuickAddProductButton from './QuickAddProductButton'

// const QUICK_ADD_PRODUCT_TO_ORDER_LABEL = `เพิ่ม ${APP_CONFIG.order.create_order.quick_add_product_to_order_qty} ชิ้นในออเดอร์...`
const INITIAL_QUICKADD_REF_NUMBER = 500

type IProductListViewPullSellersProductsToOrderProps = IProductListViewProps<IProductListViewPullSellersProductsToOrderNavParams>

class ProductListViewPullSellersProductsToOrder extends BaseUIProductListView<
  IProductListViewPullSellersProductsToOrderProps,
  IProductListViewState
> {
  static displayName = 'ProductListViewPullSellersProductsToOrder'

  quickAddButtonRefs?: {
    [key: number]: React.RefObject<QuickAddProductButton>
  }

  constructor(props) {
    super(props)
    this.quickAddButtonRefs = {}
    for (let i = 0; i < INITIAL_QUICKADD_REF_NUMBER; i++) {
      this.quickAddButtonRefs[i] = React.createRef()
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.mainProductCount > INITIAL_QUICKADD_REF_NUMBER) {
      for (let i = INITIAL_QUICKADD_REF_NUMBER; i < nextState.mainProductCount; i++) {
        this.quickAddButtonRefs[i] = React.createRef()
      }
    }
    return true
  }

  // // override
  // getApiEndpoint = () => api.POST_SELLER_PRODUCTS

  // override
  isRequiredProductVariants = () => true

  // override
  isAddProductButtonVisible = () => false

  // override
  isSkuSearchAvailable = () => false

  // override
  getHaveNoProductItemText = () => 'ไม่พบรายการสินค้า\nหรือ ร้านค้าไม่มีรายการสินค้าที่คุณสามารถดึงได้'

  // // override
  // isSellerStore = () => true

  // override
  getTargetStoreId = () => {
    const { navigation } = this.props
    const storeId = util.getNavParam(this.props, 'seller_store_id')
    if (typeof storeId !== 'number') {
      throw new Error('seller_store_id is required')
    }
    return storeId
  }

  // override
  renderProductListItemBottom = (item: { index: number; data: IProductItemData }) => (
    <QuickAddProductButton w='full' data={item.data} onPress={() => this._onPressAddOneProductToCart(item.data)} />
  )

  _onPressAddOneProductToCart = async (product: IProductItemData) => {
    const { navigation, selectedUserGroups, quickAddProductToOrder } = this.props
    const store_id = util.getNavParam(this.props, 'store_id')
    const seller_store_id = util.getNavParam(this.props, 'seller_store_id')
    const selectedVariantIndex = await util.getActionSheetAddOneProductToCartSelectedVariantIndex({
      selectedProductListItem: product,
      store_id,
      seller_store_id,
    })

    if (_.isNil(selectedVariantIndex)) {
      return
    }

    const willPullProductParams = await util.getPullProductParamsFromProductListItem({
      selectedProductListItem: product,
      selectedVariantIndex,
      store_id,
      seller_store_id,
      selectedUserGroups,
    })

    if (!willPullProductParams) {
      return
    }

    quickAddProductToOrder({
      store_id,
      seller_store_id,
      orderProducts: [willPullProductParams],
      callback: this.handleOnPullProductToOrderSuccess,
    })
  }

  // implement abstract
  onPressProductItem = (item) => {
    // console.log('ProductListViewPullSellersProductsToMyStore onPressProductItem item => ', item)
    this.navToProductViewPullSellersProductToOrderMode(item.data.id)
  }

  navToProductViewPullSellersProductToOrderMode = (product_id: number) => {
    const { navigation } = this.props
    const storeId = util.getNavParam(this.props, 'store_id')
    const sellerStoreId = util.getNavParam(this.props, 'seller_store_id')
    navigation.dispatch(
      NavActions.navToProductViewPullSellerProductToOrder({
        store_id: storeId,
        seller_store_id: sellerStoreId,
        product_id,
        onSuccessPullSellersProductToOrderCallback: this._onSuccessPullSellersProductToOrderCallback,
      })
    )
  }

  // ทำการ goBack กลับไปยัง OrderView ถ้าหากมีการเลือก Add Product จาก Product Detail
  _onSuccessPullSellersProductToOrderCallback = async () => {
    await util.delay(500)
    util.navGoBack(this.props)
  }
}

export default ProductListViewPullSellersProductsToOrder
