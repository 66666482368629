import React from 'react'
import _ from 'lodash'

import HStack from 'xui/components/HStack'
import VStack from 'xui/components/VStack'
import DatePicker from 'react-datepicker'
// import { Portal } from 'react-overlays'
import BaseDateTimeSinglePicker from 'x/components/BaseDateTimeSinglePicker'
import XInput from 'xui/components/XInput'
import moment, { Moment } from 'moment'
import * as util from 'x/utils/util'
// import { Modal } from 'react-native'

const INPUT_HEIGHT = 34
// .react-datepicker-wrapper

// // Fixed DatePicker inside Modal :: https://github.com/Hacker0x01/react-datepicker/issues/1111
// const CalendarContainer = ({ children }) => {
//   const el = document.getElementById('calendar-portal')

//   // return <Portal container={el}>{children}</Portal>
//   return (
//     <Modal style={{ backgroundColor: 'red' }} visible>
//       {children}
//     </Modal>
//   )
// }

class DateTimeInput extends React.PureComponent<any> {
  // console.log('DateTimeInput props => ', props)
  _onFocus = () => {
    const { value, onClick, onChange, onBlur, onFocus, onOpen, validatorOnPress } = this.props

    // onFocus()
    let validatorOnPressProps = true
    if (_.isFunction(validatorOnPress)) {
      validatorOnPressProps = validatorOnPress()
    }
    if (!validatorOnPressProps) {
      return
    }
    onOpen()
  }

  render() {
    const { value, onClick, onChange, onBlur, onFocus, onOpen, validatorOnPress } = this.props
    return (
      // <View style={{ flex: 1, width: '100%', height: INPUT_HEIGHT }}>
      <XInput
        w='full'
        // style={{
        //   // ...s.txtNormal,
        //   color: COLORS.TEXT_PRIMARY,
        //   backgroundColor: COLORS.FORM_PRIMARY_BG,
        //   // flex: 1,
        //   height: INPUT_HEIGHT,
        //   width: '100%',
        //   marginLeft: 0,
        //   marginTop: 0,
        //   marginBottom: 0,
        //   paddingLeft: 10,
        // }}
        keyboardType='default'
        // placeholder={''}
        // placeholderTextColor={COLORS.TEXT_INACTIVE}
        value={value}
        // onChangeText={handleChangeText}
        // onChangeText={onChange}
        onChange={onChange}
        onBlur={onBlur}
        multiline={false}
        blurOnSubmit
        onFocus={this._onFocus}
      />
      // </View>
    )
  }
}

// const Fragment = React.Fragment

export default class DateTimeSinglePicker extends BaseDateTimeSinglePicker {
  static displayName = 'DateTimeSinglePicker'

  _computeDisabledTime(
    selectedDate: Moment,
    minDate: Moment | null,
    maxDate: Moment | null
  ): {
    disabledHours?: () => number[]
    disabledMinutes?: () => number[]
    disabledSeconds?: () => number[]
  } {
    // log('_computeDisabledTime selectedDate => ', selectedDate)
    // log('_computeDisabledTime minDate => ', minDate)
    // log('_computeDisabledTime maxDate => ', maxDate)
    const isDisabledValue = (targetNum: number, unit: 'h' | 'm' | 's') => {
      const selectedTime = selectedDate.clone().set(unit, targetNum)
      return (minDate && selectedTime.isBefore(minDate, unit)) || (maxDate && selectedTime.isAfter(maxDate, unit))
    }

    const disableUnitArr = (begin: number, end: number, unit: 'h' | 'm' | 's'): number[] => {
      const disableUnits = []
      for (let i = begin; i < end; i++) {
        if (isDisabledValue(i, unit)) {
          disableUnits.push(i)
        }
      }
      return disableUnits
    }

    return {
      disabledHours: () => disableUnitArr(0, 24, 'h'),
      disabledMinutes: () => disableUnitArr(0, 60, 'm'),
      disabledSeconds: () => disableUnitArr(0, 60, 's'),
    }
  }

  _renderDateTimePickerContainer = () => (
    // const { showTime, selectedDate } = this.props
    // const { dates, txtLabels, limit } = this.state
    // const selectedDate = selectedDates && selectedDates[key] ? selectedDates[key] : dates[key]

    // const txtLabel = labels && labels[key] ? labels[key] : txtLabels[key]
    <HStack style={{ flex: 1, height: INPUT_HEIGHT, flexBasis: 'auto', flexWrap: 'wrap', alignItems: 'center', marginBottom: 8 }}>
      <VStack style={{ flex: 8, flexBasis: 'auto', width: '100%', height: INPUT_HEIGHT, alignItems: 'center', justifyContent: 'center' }}>
        {this._renderDateTimePicker()}
      </VStack>
    </HStack>
  )

  handleDateTimeChangeAdapter = (date, e) => {
    // console.log('EEEEE => ', e)
    const { stopDidUpdateEditingDateTime } = this.state
    if (!stopDidUpdateEditingDateTime) {
      util.setStatePromise(this, {
        stopDidUpdateEditingDateTime: true,
      })
    }
    const { showTime = false } = this.props
    if (!showTime) {
      this._onDateChange(date)
      this._closePicker()
      return
    }
    if (_.isNil(e)) {
      this._closePicker()
      this._onDateChange(date)
    } else {
      util.setStatePromise(this, {
        editingDateTime: date,
      })
    }
  }

  // แก้ปัญหาพิมพ์ลง input ตรงๆ ไม่ได้:: https://github.com/Hacker0x01/react-datepicker/issues/1414
  handleDateTimeChangeAdapterRaw = (evt: React.FocusEvent<HTMLInputElement> | any, key: 'begin' | 'end') => {
    const isDateEvt = _.isString(evt)
    let newValue = null

    if (isDateEvt) {
      newValue = moment(evt, this._getDateTimeFormat(true), 'th', false)
    }

    const isHtmlInputEvt = _.isNil(newValue) && evt && evt.target
    if (isHtmlInputEvt) {
      newValue = moment((evt.target as HTMLInputElement).value, this._getDateTimeFormat(false), 'th', true)
    }
    if (newValue && _.isFunction(newValue.isValid) && newValue.isValid()) {
      // this._onChangeDateTime(moment(newValue).format())
      this._onDateChange(newValue)
    }
  }

  _getDateTimeFormat = (isMoment = false) => {
    const { showTime = false } = this.props
    if (showTime) {
      return isMoment ? 'D/MM/YYYY   HH:mm น.' : 'd/MM/yyyy   HH:mm น.'
    }
    return isMoment ? 'D/MM/YYYY' : 'd/MM/yyyy'
  }

  _closePicker = () => {
    util.setStatePromise(this, {
      isShowTimePicker: false,
    })
  }

  _openDatePicker = () => {
    util.setStatePromise(this, {
      isShowTimePicker: true,
    })
  }

  _onClickOutside = () => {
    const { isWarningOnClickOutside } = this.props
    const { editingDateTime } = this.state
    this._onDateChange(editingDateTime)
    this._closePicker()
    // if (!_.isNil(isWarningOnClickOutside) && isWarningOnClickOutside) {
    // const editingDateTimeFotmat = util.getTimeFormatYYYYMMDDhhmm(editingDateTime)
    // p.op.showConfirmation(
    //   'ยืนยันการเปลี่ยนแปลงเวลา',
    //   `เวลาที่เลือกไว้คือ ${editingDateTimeFotmat}`,
    //   () => {
    //     this._onDateChange(editingDateTime)
    //     this._closePicker()
    //   },
    //   () => {},
    //   'ตกลง',
    //   'แก้ไขต่อ'
    // )
    // }
  }

  _renderDateTimePicker = () => {
    const { showTime, selectedDate, validatorOnPress } = this.props
    const { isShowTimePicker, editingDateTime } = this.state
    // const { dates, txtLabels, limit } = this.state
    // const selectedDate = selectedDates && selectedDates[key] ? selectedDates[key] : dates[key]
    // const txtLabel = labels && labels[key] ? labels[key] : txtLabels[key]

    // let minDate: Moment | null = limit[key] && limit[key].min ? limit[key].min : null
    // let maxDate: Moment | null = limit[key] && limit[key].max ? limit[key].max : null
    // if (moment.isMoment(minDate)) {
    //   // minDate = minDate.format(showTime ? this.DATETIME_FORMAT : this.DATE_FORMAT)
    //   minDate = minDate.toDate()
    // }
    // if (moment.isMoment(maxDate)) {
    //   // maxDate = maxDate.format(showTime ? this.DATETIME_FORMAT : this.DATE_FORMAT)
    //   maxDate = maxDate.toDate()
    // }

    return (
      <DatePicker
        portalId='root-portal'
        // popperContainer={CalendarContainer}
        popperModifiers={{
          flip: {
            // เอาไว้บอกตัว popper ว่าเราจะจำกัดขอบเขตไว้แค่ช่วงของใน Browser
            boundariesElement: 'window',
          },
        }}
        // popupStyle={{
        //   position: 'relative',
        // }}
        // minDate={moment.isMoment(minDate) ? minDate.toDate() : undefined}
        // maxDate={moment.isMoment(maxDate) ? maxDate.toDate() : undefined}
        selected={editingDateTime}
        onChange={(newDate, e) => this.handleDateTimeChangeAdapter(newDate, e)}
        // onChangeRaw={(evt) => this.handleDateTimeChangeAdapterRaw(evt, key)}
        // showTimeInput={true}
        // timeInputLabel='เวลา'
        showTimeSelect={showTime}
        disabledKeyboardNavigation
        timeFormat='HH:mm'
        timeIntervals={1}
        // dateFormat="d MMMM yyyy  HH:mm 'น.'"
        // dateFormat="d MMM yyyy  HH:mm 'น.'"
        dateFormat={this._getDateTimeFormat()}
        timeCaption='เวลา'
        dropdownMode='select'
        // @ts-ignore
        customInput={<DateTimeInput onOpen={() => this._openDatePicker()} validatorOnPress={validatorOnPress} />}
        open={isShowTimePicker}
        onClickOutside={() => this._onClickOutside()}
        // strictParsing={true}
        // onSelect={(date, e) => console.log('onSelect => ', e)}
        // onChangeRaw={(e) => console.log('onChangeRaw => ', e)}
        // onCalendarOpen={() => console.log('onCalendarOpen')}
        // onCalendarClose={() => console.log('onCalendarClose')}
        // renderCustomHeader={() => {
        //   return <TouchableOpacity style={{ width: 50, height: 50, backgroundColor: COLORS.ORANGE_SOFT }} />
        // }}
        shouldCloseOnSelect={false}
      />
    )
  }

  render() {
    return (
      <VStack
        style={{
          // flex: 1,
          flexBasis: 'auto',
          // flex: 1,
          width: '100%',
          // height: '100%',
          // padding: 4,
          flexWrap: 'wrap',
        }}>
        {/* { this.INPUT_KEYS.map((key: 'begin'|'end') => this._renderDateTimePicker(key)) } */}
        {this._renderDateTimePickerContainer()}
      </VStack>
    )
  }
}
