import React, { useState, useRef } from 'react'
import { View, StyleSheet, Text, TouchableOpacity, ViewStyle } from 'react-native'
// import Icon from 'xui/components/Icon'
// import { diff } from 'deep-object-diff'

import * as util from 'x/utils/util'
// import p from 'x/config/platform-specific'
import { S, COLORS } from 'x/config/styles'
import { IBarcodeScannerProps, IBarcodeScannerOutput } from 'x/index'
// import CONS from 'x/config/constants'

import XInput from 'xui/components/XInput'
import BarcodeListener from '../BarcodeListener'
// const sound = require('../../assets/sounds/barcode_sound.mp3')

// const barcodeSound = new Audio(sound)

const BarcodeScanner: React.FC<IBarcodeScannerProps> = (props) => {
  const viewContainerRef = useRef<View>(null)
  const [isBarcodeRead, setIsBarcodeRead] = useState(false)
  // const [isInitialized, setIsInitialized] = useState<boolean>(false)
  const [barcodeInputText, setBarcodeInputText] = useState('')
  // console.log('BarcodeScanner isBarcodeRead => ', isBarcodeRead)

  const isSubmitButtonDisabled = isBarcodeRead || barcodeInputText === ''

  async function _handleOnDetectedBarcodeScan(scannedText: string) {
    if (isBarcodeRead || props.disabled) {
      return
    }
    setIsBarcodeRead(true)

    // console.log('BarcodeScanner _handleOnDetectedBarcodeScan scannedText => ', scannedText)
    // await util.delay(500)
    setBarcodeInputText(scannedText)
    await _onFoundScannedData({ data: scannedText, dataRaw: scannedText })

    await util.delay(500)
    setBarcodeInputText('')
    setIsBarcodeRead(false)
  }

  async function _onFoundScannedData(output: IBarcodeScannerOutput) {
    // console.log('BarcodeScanner _onChangeBarcodeInputText output => ', output)
    // await barcodeSound.play()
    // p.op.playSoundBeepShort()
    await props.onBarcodeScanned(output)
    // if (util.isAsyncFunction(props.onBarcodeScanned)) {
    //   await props.onBarcodeScanned(output)
    // } else {
    //   props.onBarcodeScanned(output)
    // }
  }

  async function _onChangeBarcodeInputText(newTypingText: string) {
    // console.log('BarcodeScanner _onChangeBarcodeInputText newTypingText => ', newTypingText)
    setBarcodeInputText(newTypingText)
  }

  async function _onSubmitBarcodeInput() {
    if (barcodeInputText === '') {
      return
    }
    // console.log('BarcodeScanner _onChangeBarcodeInputText _onSubmitBarcodeInput => ', _onSubmitBarcodeInput)
    await _handleOnDetectedBarcodeScan(barcodeInputText)
  }

  async function _onSubmitButtonPress() {
    await _handleOnDetectedBarcodeScan(barcodeInputText)
  }

  const containerStyle: ViewStyle = {}
  if (props.cameraContainerHeight) {
    containerStyle.height = props.cameraContainerHeight
  }
  if (props.cameraContainerWidth) {
    containerStyle.width = props.cameraContainerWidth
  }

  return (
    <View ref={viewContainerRef} style={[S.COLUMN_CENTER_TOP, S.PADDING_8, S.WIDTH_FULL, containerStyle]}>
      <BarcodeListener isDisabled={isBarcodeRead} minLength={3} thresholdHumanTimeMs={350} onScan={_handleOnDetectedBarcodeScan} />
      {props.inputLabel && props.inputLabel !== '' ? (
        <View style={[S.COLUMN_CENTER_TOP, S.WIDTH_FULL, S.MARGIN_TOP_6]}>
          <Text style={[S.TEXT_INACTIVE, S.TEXT_LARGER]}>{props.inputLabel}</Text>
        </View>
      ) : null}
      <View style={[S.COLUMN_CENTER_TOP, S.WIDTH_FULL, S.HEIGHT_34, S.MARGIN_TOP_6]}>
        <XInput
          isDisabled={isBarcodeRead}
          // disabled={isBarcodeScannerDisabled}
          placeholder={props.inputPlaceholder && props.inputPlaceholder !== '' ? props.inputPlaceholder : 'พิมพ์หรือสแกนเลขบาร์โค้ด'}
          // ref={this._barcodeInputRef}
          autoFocus
          textAlign='center'
          focusable
          // onBlur={this._remainFocus}
          // style={{ width: '100%', height: 44 }}
          style={{ flex: 1 }}
          value={barcodeInputText}
          onChangeText={_onChangeBarcodeInputText}
          onSubmitEditing={_onSubmitBarcodeInput}
        />
      </View>
      <View style={[S.ROW_CENTER, S.WIDTH_FULL, S.MARGIN_TOP_6, S.MARGIN_BOTTOM_12]}>
        <TouchableOpacity
          onPress={_onSubmitButtonPress}
          disabled={isSubmitButtonDisabled}
          style={{
            backgroundColor: isSubmitButtonDisabled ? COLORS.TEXT_INACTIVE : COLORS.APP_MAIN,
            borderRadius: 7,
            height: 34,
            width: 80,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Text style={[isSubmitButtonDisabled ? S.TEXT_ACTIVE : S.TEXT_ACTIVE_DARK, S.TEXT_BOLD, S.TEXT_LARGER]}>ยืนยัน</Text>
        </TouchableOpacity>
      </View>
      {/* <XInput
          disabled={isBarcodeRead}
          placeholder={'พิมพ์หรือสแกนเลขบาร์โค้ด'}
          // ref={this._barcodeInputRef}
          autoFocus={true}
          align={'center'}
          // onBlur={this._remainFocus}
          style={{
            height: 34,
            width: 180,
          }}
          value={barcodeInputText}
          onChangeText={_onChangeBarcodeInputText}
          onSubmitEditing={_onSubmitBarcodeInput}
        /> */}
      {/* <View accessibilityRole={'none'} focusable={false} style={[S.COLUMN_LEFT_TOP, styles.childrenContainer]}>
        {props.children ? props.children : null}
      </View> */}
    </View>
  )
}

// const ScannedBound = ({ x, y, height, width }) => {
//   if (!_.isNumber(x) || !_.isNumber(y) || !_.isNumber(height) || !_.isNumber(width)) {
//     return null
//   }
//   return <View style={{ position: 'absolute', top: x, left: y, height, width, backgroundColor: 'blue' }} />
// }

const styles = StyleSheet.create({
  // container: {
  //   flex: 1,
  //   flexDirection: 'column',
  //   // backgroundColor: 'black',
  //   // width: 320,
  //   // height: 200,
  //   overflow: 'hidden',
  // },
  childrenContainer: { position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 },
  // preview: {
  //   // flex: 1,
  //   // justifyContent: 'flex-end',
  //   // alignItems: 'center',
  //   position: 'absolute',
  //   // width: SCREEN_WIDTH,
  //   // height: SCREEN_HEIGHT,
  //   // zIndex: 0,
  // },
  // capture: {
  //   flex: 0,
  //   backgroundColor: '#fff',
  //   borderRadius: 5,
  //   padding: 15,
  //   paddingHorizontal: 20,
  //   alignSelf: 'center',
  //   margin: 20,
  // },
})

BarcodeScanner.displayName = 'BarcodeScanner'
BarcodeScanner.defaultProps = {
  disabled: false,
  cameraContainerWidth: 300,
  cameraContainerHeight: 120,
}

export default BarcodeScanner
