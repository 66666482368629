import { Component } from 'react'

import * as util from 'x/utils/util'
import { ISelectedStoreMap, IXScreenProps } from 'x/index'

interface IFileUpload {
  name: string
  lastModified: number
  webkitRelativePath: string
  size: number
  type: string
  path: string
}

export interface BaseImportCustomerViewProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
  subscription: Map<string, any>
}

export interface BaseImportCustomerViewState {
  fileUpload: IFileUpload
  isLoading: boolean
  successAddProduct: number
  importErrorColumn: string
  importErrorRow: number
  importErrorCode: string
  disabledDownloadTemplate: boolean
}

export default abstract class BaseImportCustomerView extends Component<BaseImportCustomerViewProps, BaseImportCustomerViewState> {
  // abstract async handleOnDownloadFile(url: string, fileName: string): Promise<void>
  inProcess: boolean

  constructor(props) {
    super(props)
    this.state = {
      fileUpload: null,
      isLoading: false,
      successAddProduct: null,
      importErrorColumn: null,
      importErrorRow: null,
      importErrorCode: null,
      disabledDownloadTemplate: false,
    }
    this.inProcess = false
  }

  _goBack = () => {
    util.navGoBack(this.props)
  }

  _setFileUpload = (acceptedFiles: IFileUpload[]) => {
    // console.log(acceptedFiles)
    util.setStatePromise(this, {
      fileUpload: acceptedFiles[0],
    })
  }

  _startLoading = async () => {
    this.inProcess = true
    await util.setStatePromise(this, {
      isLoading: true,
    })
  }

  _stopLoading = async () => {
    this.inProcess = false
    await util.setStatePromise(this, {
      isLoading: false,
    })
  }

  // _alertMaxFileSize = async () => {
  //   // FileSizeTooLarge
  //   p.op.showConfirmationOkOnly('', 'รองรับไฟล์ขนาด 5 MB หรือน้อยกว่าเท่านั้น')
  //   this.inProcess = false
  //   await this._stopLoading()
  // }

  // _alertFileNotSupport = async () => {
  //   p.op.showConfirmationOkOnly('', 'รองรับแค่ไฟล์ .xlsx เท่านั้น')
  //   this.inProcess = false
  //   await this._stopLoading()
  // }
}
