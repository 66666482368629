import { connect } from 'react-redux'
import { getState, getDispatch } from 'x/modules/product/ProductLinkManagerViewConnect'
import { withMkpProductContextPropsAndRef } from 'xui/modules/marketplace/MkpProductContextHOC'
import ProductLinkManagerViewUI from './MkpProductLinkManagerView'

const ProductLinkManagerView = withMkpProductContextPropsAndRef(ProductLinkManagerViewUI)
// // @ts-ignore
// ProductLinkManagerView.navigationOptions = { header: null }

export default connect(
  state => ({
    ...getState(state),
  }),
  dispatch => ({
    ...getDispatch(dispatch),
  })
)(ProductLinkManagerView)
