/* eslint-disable react/no-children-prop */
import React from 'react'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContainer from 'xui/components/XContainer'
import VStack from 'xui/components/VStack'
import Box from 'xui/components/Box'
import HStack from 'xui/components/HStack'
import XText from 'xui/components/XText'
import XCard from 'xui/components/XCard'
import ImgManager from 'xui/components/ImgManager'
import _ from 'lodash'
import XInput from 'xui/components/XInput'
import BaseStoreSettingGeneral from 'x/modules/store/BaseStoreSettingGeneral'
import XContent from 'xui/components/XContent'
import { getConfig } from 'x/config/mode'
import * as util from 'x/utils/util'

const config = getConfig()

export default abstract class BaseUIStoreSettingGeneral extends BaseStoreSettingGeneral {
  renderCustomHeader = () => {
    const { isLoading = false } = this.state
    return (
      <XCustomHeader
        headerLeftProps={{ backIcon: true, submitting: isLoading, onPressItem: () => util.navGoBack(this.props) }}
        headerRightProps={{ label: 'บันทึก', submitting: isLoading, onPressItem: () => this.submitEditingForm() }}
        title='ตั้งค่าทั่วไป'
      />
    )
  }

  _renderImg = () => {
    const { uploading, isLoading = false } = this.state
    if (_.isNil(this.imgManagerRef)) {
      return null
    }
    return (
      <ImgManager
        ref={this.imgManagerRef}
        fileNamePrefix={config.s3_prefix_type_cover_store}
        metadata={[
          { key: 'p', width: 1386, height: 2460 },
          { key: 't', width: 462, height: 820 },
        ]}
        uploading={uploading || isLoading}
        // showUploaded={showUploaded}
        maxLength={1}
        disabledRemoveSingleImage
        // displayFullSize
        // imageStyle={{ width: 320, height: 120, borderRadius: 0 }}
      />
    )
  }

  render() {
    const { name, description, isLoading = false } = this.state
    // const { onChangeEditingStore } = this.props
    return (
      <XContainer>
        {this.renderCustomHeader()}
        <XContent>
          <Box w='full'>
            <XCard p='2' m='2' mt='0' flexBasis='auto'>
              <HStack w='full' my='2' justifyContent='center'>
                {this._renderImg()}
              </HStack>
              <HStack flex={1} space='2' alignItems='center'>
                <XText variant='inactive'>ชื่อร้าน</XText>
                <XInput
                  isDisabled={isLoading}
                  disabled={isLoading}
                  flex={1}
                  value={name}
                  onChangeText={(text) => this.setState({ name: text })}
                  placeholder='กรอกชื่อร้านของคุณ'
                  // placeholderTextColor={COLORS.TEXT_INACTIVE}
                  // multiline={false}
                />
              </HStack>
              <VStack>
                <XText variant='inactive'>คำอธิบาย</XText>
                <XInput
                  isDisabled={isLoading}
                  disabled={isLoading}
                  // minH='32'
                  h='32'
                  maxH='64'
                  textAlignVertical='top'
                  // selectTextOnFocus
                  value={description}
                  onChangeText={(text) => this.setState({ description: text })}
                  placeholder='กรอกรายละเอียดร้านขาย เช่น สินค้ามือสองคุณภาพดี มีการรับประกันสินค้า เป็นต้น'
                  // placeholderTextColor={COLORS.TEXT_INACTIVE}
                  multiline
                />
              </VStack>
            </XCard>
          </Box>
        </XContent>
      </XContainer>
    )
  }
}
