import React from 'react'
import CONS from 'x/config/constants'

const {
  SIDEBAR_WIDTH,
  MAIN_CONTENT_WIDTH,
  MAX_WEB_WIDTH,
  HEADER_HEIGHT,
  MIN_WEB_HEIGHT,
  BOTTOM_SCROLLBAR_HEIGHT,
  RIGHT_SCROLLBAR_WIDTH,
  NAV_HEADER_HEIGHT,
} = CONS.SPECIFIC_PLATFORM.WEB

// interface IThemeSizeContext {
//   contentWidth: number
//   contentWidthStyle: { width: number }

//   browserWidth: number
//   browserHeight: number
//   containerHeight: number
//   isVisibleVerticalScrollbar: boolean
//   contentHeight: number
//   containerHeightStyle: { height: number }
//   contentHeightStyle: { height: number }
// }

const INITIAL_STATE = {
  numOfColumns: 3,
  sidebarWidth: SIDEBAR_WIDTH,
  columnWidth: Math.floor(MAIN_CONTENT_WIDTH / 3),
  columnWidthStyle: { width: Math.floor(MAIN_CONTENT_WIDTH / 3) },

  contentWidth: MAIN_CONTENT_WIDTH,
  contentWidthStyle: { width: MAIN_CONTENT_WIDTH },
  contentOneOfThreeColumnWidth: Math.floor(MAIN_CONTENT_WIDTH / 3),
  contentOneOfThreeColumnWidthStyle: { width: Math.floor(MAIN_CONTENT_WIDTH / 3) },
  contentTwoOfThreeColumnWidth: Math.floor((MAIN_CONTENT_WIDTH * 2) / 3),
  contentTwoOfThreeColumnWidthStyle: { width: Math.floor((MAIN_CONTENT_WIDTH * 2) / 3) },

  browserWidth: MAX_WEB_WIDTH,
  browserWidthStyle: { width: MAX_WEB_WIDTH },
  browserHeight: MIN_WEB_HEIGHT,
  browserHeightStyle: { height: MIN_WEB_HEIGHT },
  containerHeight: MIN_WEB_HEIGHT,
  isVisibleVerticalScrollbar: false,
  contentHeight: MIN_WEB_HEIGHT - HEADER_HEIGHT,
  containerHeightStyle: { height: MIN_WEB_HEIGHT },
  contentHeightStyle: { height: MIN_WEB_HEIGHT - HEADER_HEIGHT },
  contentWithNavHeaderHeight: MIN_WEB_HEIGHT - HEADER_HEIGHT - NAV_HEADER_HEIGHT,
  contentWithNavHeaderHeightStyle: { height: MIN_WEB_HEIGHT - HEADER_HEIGHT - NAV_HEADER_HEIGHT },
}

// const ThemeSizeContext = React.createContext<IThemeSizeContext>(INITIAL_STATE)
const ThemeSizeContext = React.createContext(INITIAL_STATE)
export default ThemeSizeContext

// Example from: https://reactjs.org/docs/context.html
// // Context lets us pass a value deep into the component tree
// // without explicitly threading it through every component.
// // Create a context for the current theme (with "light" as the default).
// const ThemeContext = React.createContext('light');

// class App extends React.Component {
//   render() {
//     // Use a Provider to pass the current theme to the tree below.
//     // Any component can read it, no matter how deep it is.
//     // In this example, we're passing "dark" as the current value.
//     return (
//       <ThemeContext.Provider value="dark">
//         <Toolbar />
//       </ThemeContext.Provider>
//     );
//   }
// }

// // A component in the middle doesn't have to
// // pass the theme down explicitly anymore.
// function Toolbar(props) {
//   return (
//     <div>
//       <ThemedButton />
//     </div>
//   );
// }

// class ThemedButton extends React.Component {
//   // Assign a contextType to read the current theme context.
//   // React will find the closest theme Provider above and use its value.
//   // In this example, the current theme is "dark".
//   static contextType = ThemeContext;
//   render() {
//     return <Button theme={this.context} />;
//   }
// }
