import * as React from 'react'
import { LayoutChangeEvent, LayoutRectangle, TouchableOpacity } from 'react-native'
import { useNavigationBuilder, StackRouter, createNavigatorFactory } from '@react-navigation/native'

import CONS from 'x/config/constants'

import HStack from 'xui/components/HStack'
import VStack from 'xui/components/VStack'
import XIcon from 'xui/components/XIcon'
import XText from 'xui/components/XText'

interface IProps {
  initialRouteName?: any
  children?: any
  screenOptions?: any
  tabBarStyle?: any
  contentStyle?: any
}

function StackExpandNavigator(props: IProps) {
  const { initialRouteName, children, screenOptions, tabBarStyle, contentStyle = {} } = props

  const [containerLayout, setContainerLayout] = React.useState<LayoutRectangle>()

  const _onContainerLayoutChange = (evt: LayoutChangeEvent) => {
    setContainerLayout(evt.nativeEvent.layout)
  }

  const { state, navigation, descriptors, NavigationContent } = useNavigationBuilder(StackRouter, {
    children,
    screenOptions,
    initialRouteName,
  })

  const containerWidth = containerLayout && containerLayout.width ? containerLayout.width : CONS.SPECIFIC_PLATFORM.WEB.MAX_WEB_WIDTH
  // const routeCount = state.routes.length || 1
  // const columnNum = routeCount > 3 ? 3 : routeCount
  let columnNum = 1
  // 350px per column

  if (containerWidth >= 750) {
    columnNum = 2
  }
  if (containerWidth >= 1200) {
    columnNum = 3
  }

  // if (containerWidth >= CONS.SPECIFIC_PLATFORM.WEB.MAIN_CONTENT_ONE_COLUMN_WIDTH * 2) {
  //   columnNum = 2
  // }
  // if (containerWidth >= CONS.SPECIFIC_PLATFORM.WEB.MAIN_CONTENT_ONE_COLUMN_WIDTH * 3) {
  //   columnNum = 3
  // }

  const keyDownHandler = React.useCallback(
    (event) => {
      // console.log('User pressed: ', event.key)

      if (event.key === 'Escape' || event.key === 'Esc') {
        event.preventDefault()

        // 👇️ your logic here
        // popLatestView()
        try {
          // console.log('popLatestView state.routes.length => ', state.routes.length)

          // if (state.routes.length > 1) {
          const latestKey = state.routes[state.routes.length - 1].key
          const currentDescriptor = descriptors[latestKey]
          // console.log('popLatestView currentDescriptor => ', currentDescriptor)

          if (currentDescriptor.navigation.canGoBack()) {
            currentDescriptor.navigation.goBack()
          }
          // }
        } catch (error) {
          console.log('popLatestView error => ', error)
        }
      }
    },
    [state]
  )

  React.useEffect(() => {
    document.addEventListener('keydown', keyDownHandler)

    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [])

  const contentWidth = containerWidth / columnNum
  const lastIndex = state.routes.length - 1

  return (
    <NavigationContent>
      {/* <View style={[{ flexDirection: 'row' }, tabBarStyle]}>
        {state.routes.map((route) => (
          <Pressable
            key={route.key}
            onPress={() => {
              const event = navigation.emit({
                type: 'tabPress',
                target: route.key,
                canPreventDefault: true,
              })

              if (!event.defaultPrevented) {
                navigation.dispatch({
                  ...TabActions.jumpTo(route.name),
                  target: state.key,
                })
              }
            }}
            style={{ flex: 1 }}>
            <Text>{descriptors[route.key].options.title || route.name}</Text>
          </Pressable>
        ))}
      </View> */}
      <HStack
        onLayout={_onContainerLayoutChange}
        flex={1}
        // bg='gray.400'
        bg='gray.300'
        minW='320px'
        // maxW='1200px'
        style={contentStyle}
        //
      >
        {state.routes.map((route, i) => {
          const currentDescriptor = descriptors[route.key]
          // console.log(`${i}::currentDescriptor => `, currentDescriptor)
          const { options = {}, navigation } = currentDescriptor
          const isAlwaysActive = options.isAlwaysActive || false
          const isInactive = i < lastIndex && !isAlwaysActive
          return (
            <VStack
              key={`StackExpandWrapper_${i.toString()}`}
              // flex={1}
              w={`${contentWidth}px`}
              // bg='amber.100'
              position={i >= columnNum ? 'absolute' : undefined}
              top={i >= columnNum ? '0' : undefined}
              right={i >= columnNum ? '0' : undefined}
              bottom={i >= columnNum ? '0' : undefined}
              // borderRightColor={i < 2 ? undefined : 'gray.200'}
              // borderRightWidth='1'
              // style={[
              //   // StyleSheet.absoluteFill,
              //   {
              //     // display: i === state.index ? 'flex' : 'none'
              //     // display: i === state.index ? 'flex' : 'none'
              //   },
              // ]}
              //
            >
              {descriptors[route.key].render()}
              {isInactive ? (
                <VStack
                  alignItems='center'
                  justifyContent='center'
                  position='absolute'
                  top='0'
                  right='0'
                  bottom='0'
                  left='0'
                  bg='black:alpha.50'>
                  <TouchableOpacity
                    onPress={() => {
                      // console.log(`${i}::currentDescriptor => `, currentDescriptor)
                      // console.log(`${i}::getState => `, currentDescriptor.navigation.getState())
                      const shouldPopNum = lastIndex - i
                      // @ts-ignore
                      currentDescriptor.navigation.pop(shouldPopNum)
                    }}>
                    <VStack
                      alignItems='center'
                      justifyContent='center'
                      p='2'
                      space='2'
                      borderTopColor='gray.400'
                      borderLeftColor='gray.400'
                      borderBottomColor='gray.500'
                      borderRightColor='gray.500'
                      borderWidth='1'
                      borderRadius='lg'
                      bg='gray.100'>
                      <XIcon inactive name='close-circle-outline' />
                      <XText variant='inactive' textAlign='center'>
                        {'คลิกที่นี่เพื่อปิดด้านขวาทั้งหมด\nหรือ\nกดปุ่ม esc เพื่อปิดกลับมาทีละหน้า'}
                      </XText>
                    </VStack>
                  </TouchableOpacity>
                </VStack>
              ) : null}
            </VStack>
          )
        })}
      </HStack>
    </NavigationContent>
  )
}

export const createStackExpandNavigator = createNavigatorFactory(StackExpandNavigator)

const Sx = createStackExpandNavigator()

export default Sx
