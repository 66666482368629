import React from 'react'
import { delay, log, setStatePromise } from 'x/utils/util'
import { List, Map, fromJS } from 'immutable'
import _ from 'lodash'
import xCONS from 'x/config/constants'
import settings from 'x/config/settings'
import { IBasePaymentViewProps, IBasePaymentViewState, IPaymentViewNavParams, IApiOptions, IPaymentAccount, IPaymentMethod } from 'x/index'

import * as util from 'x/utils/util'
import * as acl from 'x/utils/acl'

import api from 'x/utils/api'
import { Clipboard } from 'react-native'
import { IAnyObject, IMap } from 'x/types'
import p from '../../config/platform-specific'
import * as xFmt from '../../utils/formatter'
import { getCreateToken, getCreateTokenNoLowercase } from '../../utils/file'

export const OPTION_COD_AMOUNT = 0
export const OPTION_ORDER_AMOUNT = 1

export default abstract class BasePaymentView extends React.Component<IBasePaymentViewProps, IBasePaymentViewState> {
  isUnmounting: boolean

  inProcess: boolean

  paymentAccountSelectionTitle?: string

  showTitleLabel?: boolean

  txtBeforeRequestQRCodeInfo?: string

  txtQRCodeSwitchGuide?: string

  txtBtnDownloadQR?: string

  payToStoreId: number

  gbSegmentOptions: Array<{ label: string; value: number }>

  ctokenForGBPay: string

  ctokenForSCBQRCode: string

  abstract _getParams(): Partial<IPaymentViewNavParams>

  // abstract async _updateTitle(title: string): Promise<void>
  abstract _handleAfterAlertMissingField(fieldKey: string): void

  abstract _handleAfterAlertWrongTotalAmount(): void

  // abstract _handleAfterSuccessSubmitPayment(res?: any): Promise<void>

  constructor(props: IBasePaymentViewProps) {
    super(props)

    this.ctokenForGBPay = getCreateToken() // lower-case + upper-case
    this.ctokenForSCBQRCode = getCreateTokenNoLowercase() // number + lower-case

    this.state = {
      ordersToBePaid: {},

      orderSelectorVisible: false,
      previewImageURI: '',
      previewVisible: false,
      // amountTotal: 0,
      requiredRemainingAmountTotal: 0,
      requiredCodAmountTotal: 0,
      optionChooseAmountWith: OPTION_ORDER_AMOUNT, // 1 คือยอดออเดอร์
      isRefund: false,
      orderType: 3,
      isFetchingAccounts: false,
      canEnableGBPay: false,
      paymentMethod: xCONS.PAYMENT_METHOD.BANK_TRANSFER,
      gbAccountList: List([]),
      ctoken: this.ctokenForGBPay,
      uploading: false,
      submitting: false,
      imageCount: 0,
      isVisibleGBPayQRCodeImage: false,
      imgGBPayQRCode: null,
      currentRef: null,

      selectedSellerPaymentAccount: null,
      isSelectedPaymentAccountVerifySlipSupported: false,
      sellerPaymentAccounts: null,

      paymentTotalAmount: '0',
      paymentNote: '',
      paymentPostDate: new Date(),

      canEnableSCBQRCode: false,
      canEnableSCBApp: false,
      isInitialized: false,

      beamUrlPayment: null,
      canEnableBeam: false,

      isVerifySlipEnabled: false,
      // verifySlipStatus: null,
    }

    this.gbSegmentOptions = [
      { label: 'แจ้งชำระด้วยตนเอง', value: 0 },
      { label: 'แจ้งชำระด้วย GB Pay QR Code', value: 1 },
      { label: 'แจ้งชำระด้วย SCB QR Code QR Code', value: 2 },
      { label: 'แจ้งชำระด้วย Beam', value: 3 },
    ]

    this.txtBeforeRequestQRCodeInfo = ''
    this.txtQRCodeSwitchGuide = ''
    this.txtBtnDownloadQR = ''
    this.inProcess = false

    this._handleAfterAlertMissingField = this._handleAfterAlertMissingField.bind(this)
    this._handleAfterAlertWrongTotalAmount = this._handleAfterAlertWrongTotalAmount.bind(this)
    // this._handleAfterSuccessSubmitPayment = this._handleAfterSuccessSubmitPayment.bind(this)
  }

  async componentDidMount() {
    // log('PyamentView.componentDidMount')
    // const { fetchStorePaymentAccounts, navigation } = this.props

    await this._initializeState()

    // await this._updateStateAfterPropsChanged()
    await this.updateStateAfterOrdersToBePaidChanged()
    const { orderType, myStoreId, orderStoreId, partnerStoreId } = this._getParams()
    const { isRefund } = this.state
    // Initialize wording
    if (orderType === 1) {
      this.payToStoreId = myStoreId // update balance on my account either pay to or from (Buying or refunding)
      this.gbSegmentOptions[0].label = 'บันทึกรับชำระด้วยตนเอง'
      this.gbSegmentOptions[1].label = 'ให้ลูกค้าชำระด้วย GB Pay QR Code'
      this.gbSegmentOptions[2].label = 'ให้ลูกค้าชำระด้วย SCB QR Code'
      this.gbSegmentOptions[3].label = 'ให้ลูกค้าชำระด้วย Beam'
      this.txtBeforeRequestQRCodeInfo = 'กรุณาแชร์ให้ลูกค้าชำระเงินออนไลน์ด้วยแอปของธนาคาร เพื่อเสร็จสิ้นกระบวนการรับชำระเงิน'
      this.txtQRCodeSwitchGuide =
        'ขั้นตอนการรับชำระด้วย QR Code' +
        '\n1. กดปุ่มด้านล่างเพื่อดาวน์โหลด QR Code' +
        '\n2. แชร์ QR Code ให้กับลูกค้า เพื่อให้ลูกค้านำชำระกับแอปของธนาคารใดๆ ก็ได้'
      this.txtBtnDownloadQR = 'ดาวน์โหลด QR Code เพื่อนำไปให้ลูกค้า'
    } else if (orderType === 2) {
      this.payToStoreId = isRefund ? partnerStoreId : myStoreId // For orderType 2, pay to reseller (Refunding).
      this.gbSegmentOptions[0].label = isRefund ? 'แจ้งคืนเงินด้วยตนเอง' : 'บันทึกรับชำระด้วยตนเอง'
      this.gbSegmentOptions[1].label = isRefund
        ? 'แจ้งคืนเงินอัตโนมัติด้วย GB Pay  QR Code'
        : 'โหลดรูป QR CODE จาก GB Pay\nให้ผู้ซื้อนำไปชำระต่อ'
      this.gbSegmentOptions[2].label = isRefund ? 'แจ้งคืนเงินอัตโนมัติด้วย SCB QR Code' : 'โหลดรูป QR CODE จาก SCB\nให้ผู้ซื้อนำไปชำระต่อ'
      this.gbSegmentOptions[3].label = isRefund ? 'แจ้งคืนเงิน ด้วย Beam' : 'ให้ตัวแทนชำระด้วย Beam'
      this.txtBeforeRequestQRCodeInfo = 'กรุณาไปชำระเงินออนไลน์ด้วยแอปของธนาคารที่คุณใช้อยู่เพื่อเสร็จสิ้นกระบวนการคืนเงิน'
      this.txtQRCodeSwitchGuide =
        'ขั้นตอนการคืนเงินด้วย QR Code' + '\n1. กดปุ่มด้านล่างเพื่อดาวน์โหลด QR Code' + '\n2. นำ QR Code ไปชำระกับแอปของธนาคารใดๆ ก็ได้'
      this.txtBtnDownloadQR = 'ดาวน์โหลด QR Code เพื่อนำไปชำระ'
    } else {
      this.payToStoreId = orderStoreId // For orderType 3, pay to seller (Buying).
      this.gbSegmentOptions[0].label = 'แจ้งชำระด้วยตนเอง'
      this.gbSegmentOptions[1].label = 'แจ้งชำระอัตโนมัติด้วย GB Pay QR Code'
      this.gbSegmentOptions[2].label = 'แจ้งชำระอัตโนมัติด้วย SCB QR Code'
      this.txtBeforeRequestQRCodeInfo = 'กรุณาไปชำระเงินออนไลน์ด้วยแอปของธนาคารที่คุณใช้อยู่เพื่อเสร็จสิ้นกระบวนการชำระเงิน'
      this.txtQRCodeSwitchGuide =
        'ขั้นตอนการชำระด้วย QR Code' + '\n1. กดปุ่มด้านล่างเพื่อดาวน์โหลด QR Code' + '\n2. นำ QR Code ไปชำระกับแอปของธนาคารใดๆ ก็ได้'
      this.txtBtnDownloadQR = 'ดาวน์โหลด QR Code เพื่อนำไปชำระ'
    }
    // await setStatePromise(this, { isFetchingAccounts: true })
    // const finishedApiCallback = () => {
    //   this.setState({ isFetchingAccounts: false })
    // }
    // fetchStorePaymentAccounts({ store_id: this.payToStoreId }, finishedApiCallback, finishedApiCallback)
    await setStatePromise(this, { isInitialized: true })
  }

  _initializeState = async () => {
    const { navigation, selectedStore } = this.props
    const initialOrdersToBePaid = util.getNavParam(this.props, 'initialOrdersToBePaid', {})
    const isRefund = util.getNavParam(this.props, 'isRefund', false)
    const isVerifySlipEnabled = selectedStore.get('s_use_verify_slip') || false
    // console.log('componentDidMount initialOrdersToBePaid => ', initialOrdersToBePaid)
    // if (initialOrdersToBePaid && !_.isEmpty(initialOrdersToBePaid)) {
    await util.setStatePromise(this, { ordersToBePaid: initialOrdersToBePaid, isRefund, isVerifySlipEnabled })
    // }
  }

  // async componentWillReceiveProps(nextProps, nextState) {
  //   // log('PyamentView.componentWillReceiveProps')
  //   if (this.isUnmounting) {
  //     return
  //   }
  //   // const { onPaymentChange } = this.props
  //
  //   // const { img_uris, thumbnail_uris } = nextProps
  //   // if (this.props.img_uris !== img_uris || this.props.thumbnail_uris !== thumbnail_uris) {
  //   //   // log('_Immutable_img_uris_is_changed__: ', img_uris)
  //   //   if (img_uris.length > 0 && thumbnail_uris.length > 0) {
  //   //     onPaymentChange({ key: 'img_uris', value: img_uris })
  //   //     onPaymentChange({ key: 'thumbnail_uris', value: thumbnail_uris })
  //   //   } else {
  //   //     onPaymentChange({ key: 'img_uris', value: null })
  //   //     onPaymentChange({ key: 'thumbnail_uris', value: null })
  //   //   }
  //   // }
  //   await this._updateStateAfterPropsChanged(nextProps, false)
  // }

  // async componentDidUpdate(prevProps: IBasePaymentViewProps) {
  //   if (this.isUnmounting) {
  //     return
  //   }

  //   const { paymentAccounts, fetchStorePaymentAccounts } = this.props
  //   const { canEnableGBPay, isFetchingAccounts, canEnableBeam } = this.state
  //   // console.log('paymentAccounts => ', paymentAccounts.toJS())
  //   if (prevProps.ordersToBePaid !== this.props.ordersToBePaid) {
  //     await this._updateStateAfterPropsChanged()
  //   }
  //   // if (Map.isMap(paymentAccounts) && this.payToStoreId && !paymentAccounts.has(this.payToStoreId) && !isFetchingAccounts) {
  //   //   // ถ้ายังไม่มีข้อมูล account ที่จะจ่ายเข้า ให้ fetch เลย
  //   //   log('begin Fetching payment accounts... this.payToStoreId => ', this.payToStoreId)
  //   //   await setStatePromise(this, { isFetchingAccounts: true })
  //   //   const finishedApiCallback = () => {
  //   //     this.setState({ isFetchingAccounts: false })
  //   //   }
  //   //   fetchStorePaymentAccounts({ store_id: this.payToStoreId }, finishedApiCallback, finishedApiCallback)
  //   // }
  //   // console.log('this.payToStoreId => ', this.payToStoreId)
  //   if (!canEnableGBPay && !canEnableBeam && this.payToStoreId) {
  //     log('checking... canEnableGBPay')
  //     await this._shouldSetCanEnableGBPay(paymentAccounts.get(this.payToStoreId))
  //   }
  // }

  componentWillUnmount() {
    if (!this.isUnmounting) {
      this.isUnmounting = true
    }
  }

  // _updateStateAfterPropsChanged = async (): Promise<void> => {
  //   log('PaymentView._updateStateAfterPropsChanged')
  //   const { orderType } = this._getParams()
  //   // const amountTotal = this.getOrdersToBePaidAmountTotal()
  //   // const isRefund = this._isRefund(amountTotal)
  //   // await setStatePromise(this, { amountTotal, isRefund, orderType })
  //   const { requiredRemainingAmountTotal, requiredCodAmountTotal } = this.getOrdersToBePaidAmountTotal()
  //   const { optionChooseAmountWith } = this.state
  //   const isRefund =
  //     optionChooseAmountWith === OPTION_COD_AMOUNT ? this._isRefund(requiredCodAmountTotal) : this._isRefund(requiredRemainingAmountTotal)
  //   await setStatePromise(this, {
  //     requiredRemainingAmountTotal,
  //     requiredCodAmountTotal,
  //     isRefund,
  //     orderType,
  //     optionChooseAmountWith: requiredCodAmountTotal > 0 ? optionChooseAmountWith : OPTION_ORDER_AMOUNT,
  //   })
  //   await delay(50)
  //   await this.onUserRequestExactAmountTotal()
  //   this.inProcess = false
  // }

  getOrderCount = () => {
    const { ordersToBePaid = {} } = this.state
    return Object.keys(ordersToBePaid).length || 0
  }

  updateStateAfterOrdersToBePaidChanged = async (): Promise<void> => {
    const { orderType } = this._getParams()
    // const amountTotal = this.getOrdersToBePaidAmountTotal()
    // const isRefund = this._isRefund(amountTotal)
    // await setStatePromise(this, { amountTotal, isRefund, orderType })
    const { requiredRemainingAmountTotal, requiredCodAmountTotal } = this.getOrdersToBePaidAmountTotal()
    const { optionChooseAmountWith, ordersToBePaid = {} } = this.state
    // const orderCount = this.getOrderCount()
    // Compute isRefund here
    // const isRefund =
    //   optionChooseAmountWith === OPTION_COD_AMOUNT ? this._isRefund(requiredCodAmountTotal) : this._isRefund(requiredRemainingAmountTotal)

    await setStatePromise(this, {
      requiredRemainingAmountTotal,
      requiredCodAmountTotal,
      // isRefund,
      orderType,
      optionChooseAmountWith: requiredCodAmountTotal > 0 && !this.isOrdersAvoidCOD() ? optionChooseAmountWith : OPTION_ORDER_AMOUNT,
    })

    await delay(200)

    this.onUserRequestExactAmountTotal()
  }

  // // TODO: Refactor me use both from app/web
  //   shouldComponentUpdate(nextProps, nextState) {
  //     if (this.isUnmounting) {
  //       return false
  //     }
  //     const isStateChanged = xUtil.isDiff(this.state, nextState, [
  //       'orderSelectorVisible',
  //       'amountTotal',
  //       'previewImageURI',
  //       'previewVisible',
  //       'submitting',
  //       'uploading',
  //     ])
  //     if (isStateChanged) {
  //       // log('PaymentView.shouldComponentUpdate.isStateChanged.true')
  //       return true // Returns true immediately as there is no need to check props
  //     }
  //     const isPropsChanged = xUtil.isDiff(this.props, nextProps, [
  //       'selectedPayment',
  //       'editingPayment',
  //       'img_uris',
  //       'thumbnail_uris',
  //       'uploadStatus',
  //     ])
  //     // log('PaymentView.shouldComponentUpdate.isPropsChanged')
  //     log(isPropsChanged)
  //     return isPropsChanged
  //   }

  // _shouldSetCanEnableGBPay = async (targetPaymentAccounts: List<Map<string, any>>): Promise<void> => {
  //   if (!targetPaymentAccounts || !List.isList(targetPaymentAccounts) || targetPaymentAccounts.size === 0) {
  //     return
  //   }

  //   let gbAccountList = List([])
  //   let canEnableGBPay = false
  //   let canEnableSCBQRCode = false
  //   let canEnableBeam = false
  //   for (let i = 0; i < targetPaymentAccounts.size; i++) {
  //     const paymentAcc: Map<string, any> = targetPaymentAccounts.get(i)
  //     // console.log('paymentAcc => ', paymentAcc.toJS())
  //     if (Map.isMap(paymentAcc) && paymentAcc.get('bank_id') === xCONS.SYSTEM_BANK_IDS.GBPAY) {
  //       gbAccountList = gbAccountList.push(paymentAcc)
  //       canEnableGBPay = true
  //     } else if (Map.isMap(paymentAcc) && paymentAcc.get('bank_id') === xCONS.SYSTEM_BANK_IDS.SCB_QR_CODE) {
  //       gbAccountList = gbAccountList.push(paymentAcc)
  //       canEnableSCBQRCode = true
  //     } else if (Map.isMap(paymentAcc) && paymentAcc.get('bank_id') === xCONS.SYSTEM_BANK_IDS.BEAM) {
  //       gbAccountList = gbAccountList.push(paymentAcc)
  //       canEnableBeam = true
  //     }
  //   }

  //   if (gbAccountList.size > 0) {
  //     await setStatePromise(this, { canEnableGBPay, canEnableSCBQRCode, canEnableBeam, gbAccountList }) // Set to GBMode && SCB qe code if can enable.
  //     await delay(100) // delay for state
  //     if (this.state.isRefund) {
  //       await this._onChangeGBOptions(0)
  //     } else if (canEnableGBPay) {
  //       await this._onChangeGBOptions(1)
  //     } else if (canEnableSCBQRCode) {
  //       await this._onChangeGBOptions(2)
  //     } else if (canEnableBeam) {
  //       await this._onChangeGBOptions(3)
  //     }
  //   }
  // }

  _onPaymentAccountsLoaded = (pas: IPaymentAccount[]) => this.onPaymentAccountsLoaded(pas)

  onPaymentAccountsLoaded = async (sellerPaymentAccounts: IPaymentAccount[]) => {
    // console.log('sellerPaymentAccounts => ', sellerPaymentAccounts)
    // const gba = sellerPaymentAccounts.find((spa) => spa.bank_id === 25)
    // console.log('gba => ', gba)
    await util.setStatePromise(this, { sellerPaymentAccounts })
    await this.updatePaymentGatewayOptions(sellerPaymentAccounts)
  }

  updatePaymentGatewayOptions = async (targetPaymentAccounts: IPaymentAccount[]): Promise<void> => {
    if (!targetPaymentAccounts || !_.isArray(targetPaymentAccounts) || targetPaymentAccounts.length === 0) {
      return
    }

    const { isRefund } = this.state
    let gbAccountList = List([])
    let canEnableGBPay = false
    let canEnableSCBQRCode = false
    let canEnableBeam = false

    if (!isRefund) {
      for (let i = 0; i < targetPaymentAccounts.length; i++) {
        const fpa = targetPaymentAccounts[i]
        if (_.isObject(fpa) && fpa.bank_id) {
          switch (fpa.bank_id) {
            case xCONS.SYSTEM_BANK_IDS.GBPAY:
              gbAccountList = gbAccountList.push(fromJS(fpa))
              canEnableGBPay = true
              break
            case xCONS.SYSTEM_BANK_IDS.SCB_QR_CODE:
              gbAccountList = gbAccountList.push(fromJS(fpa))
              canEnableSCBQRCode = true
              break
            case xCONS.SYSTEM_BANK_IDS.BEAM:
              gbAccountList = gbAccountList.push(fromJS(fpa))
              canEnableBeam = true
              break
            default:
            // no-op
          }
        }
      }
    }

    // if (gbAccountList.size > 0) {
    await setStatePromise(this, { canEnableGBPay, canEnableSCBQRCode, canEnableBeam, gbAccountList }) // Set to GBMode && SCB qe code if can enable.
    await delay(100) // delay for state
    if (isRefund) {
      await this._onChangeGBOptions(0)
    } else if (canEnableGBPay) {
      await this._onChangeGBOptions(1)
    } else if (canEnableSCBQRCode) {
      await this._onChangeGBOptions(2)
    } else if (canEnableBeam) {
      await this._onChangeGBOptions(3)
    }
    // }
  }

  // _isRefund = (amountTotal: number): boolean => amountTotal < 0

  isOrdersAvoidCOD = (): boolean => {
    const { orderType = 3, isRefund = false } = this.state

    // case นี้ไม่รู้เหตุผล แต่เป็นเงื่อนไขนี้ตั้งแต่ดั้งเดิม
    const isOrderType3 = orderType === 3

    // Avoid COD เมื่อ isRefund เนื่องจากถ้าใช้ยอด COD มันจะไปกระทบยอดเกินจำนวน ทำให้อีกฝ่ายต้องคืนเงินมาอีกรอบ
    // พบบั๊คที่ orderType === 2 + isRefund === true
    // ref: https://dd.xselly.com/doc/payment-cqzmY76SBg
    return isOrderType3 || isRefund
  }

  _getTxtPayeeLable(): string | null {
    const { orderType } = this._getParams()
    let payeeLabel = 'สำหรับ'
    if (orderType === 1) {
      payeeLabel = 'ลูกค้า'
    } else if (orderType === 2) {
      payeeLabel += 'ตัวแทน'
    } else if (orderType === 3) {
      payeeLabel += 'ร้านผู้ขายส่ง'
    } else {
      return null
    }
    return payeeLabel
  }

  _getPayeeStoreId = () => {
    const { orderStoreId, orderType, myStoreId, partnerStoreId } = this._getParams()

    // console.log('_getPayeeStoreId orderStoreId => ', orderStoreId)
    // console.log('_getPayeeStoreId orderType => ', orderType)
    // console.log('_getPayeeStoreId myStoreId => ', myStoreId)
    // console.log('_getPayeeStoreId partnerStoreId => ', partnerStoreId)

    // if (orderType === 1) {
    //   payeeLabel = 'ลูกค้า'
    // }

    // if (orderType === 2 && myStoreId !== orderStoreId) {
    //   // payeeLabel += 'ตัวแทน'
    //   return partnerStoreId
    // }

    if (orderType === 3 && myStoreId !== orderStoreId) {
      // payeeLabel += 'ร้านผู้ขายส่ง'
      return orderStoreId
    }

    return myStoreId
  }

  _getTxtAccountLabel(): string {
    const { orderType } = this._getParams()
    return this.state.isRefund && orderType === 1 ? 'จากบัญชี' : 'เข้าบัญชี'
  }

  _getTxtForOrders(): string {
    const { ordersToBePaid = {} } = this.state
    const orderLength = this.getOrderCount()
    let txtForOrders = 'สำหรับออเดอร์'
    if (orderLength > 1) {
      txtForOrders += ` (รวม ${orderLength} รายการ)`
    }
    return txtForOrders
  }

  _getTxtAccountSelector(): string {
    const { orderType } = this._getParams()
    const { isRefund } = this.state
    return isRefund && orderType === 1 ? 'เลือกบัญชีคุณที่จะจ่ายออก' : 'เลือกบัญชีปลายทาง'
  }

  _getTxtSubmitButton(): string {
    const { isRefund } = this.state
    return isRefund ? 'ยืนยันการคืนเงิน' : 'ยืนยันการชำระ'
  }

  _getOrderIds() {
    const { ordersToBePaid } = this.state
    if (!_.isObject(ordersToBePaid)) {
      return null
    }
    let orderIds = Object.keys(ordersToBePaid)
    log('_getOrderIds orderIds => ', orderIds)
    if (orderIds.length > 1) {
      orderIds = orderIds.sort((a, b) => parseInt(a, 10) - parseInt(b, 10))
    }
    return orderIds
  }

  getCurrentBankAccountInfo = () => {
    // const acc = Map({ bank_id: 1, name: 'นายสุขสวัสดิ์ ชนะโชติ', account_number: '123-2-12345-12-1' })
    const acc = this.props.editingPayment.get('seller_payment_account')
    const showAccountSelector = !!(!acc || acc.size === 0)
    let bankId = null
    let bankName = null
    let accountName = null
    let accountNumber = null

    if (!showAccountSelector) {
      bankId = acc.get('bank_id')

      let bankNameKey
      const foundBankIndex = xCONS.BANK_INFO.findIndex((sBank) => sBank.id === bankId)
      if (foundBankIndex > -1) {
        bankNameKey = xCONS.BANK_INFO[foundBankIndex].key
      } else {
        bankNameKey = xCONS.BANK_INFO[0].key
      }
      bankName = p.op.t(`Bank.${bankNameKey}`)

      // bankName = p.op.t(`Bank.${CONS.BANK_INFO[bankId].key}`)
      accountName = acc.get('name')
      accountNumber = acc.get('account_number')
    }

    return { bankId, bankName, accountName, accountNumber, showAccountSelector }
  }

  doValidationAndSubmitPayment = async () => {
    const {
      ordersToBePaid = {},
      paymentPostDate,
      paymentTotalAmount,
      paymentNote = '',
      paymentSlip,
      selectedSellerPaymentAccount,

      // orderType,
      isRefund,
    } = this.state

    const orderToBePaidIds = Object.keys(ordersToBePaid) // convert map to array of orderIds (keys)

    if (orderToBePaidIds.length < 1) {
      p.op.alert('ไม่ได้ระบุออเดอร์', 'กรุณาเลือกออเดอร์ที่รับชำระ')
      return
    }

    const paymentTotalAmountFloat = parseFloat(paymentTotalAmount)
    if (typeof paymentTotalAmountFloat !== 'number' || paymentTotalAmountFloat <= 0) {
      await p.op.alertPromise('ยอดชำระไม่ถูกต้อง', 'กรุณาระบุยอดชำระ โดยยอดชำระต้องมากกว่า 0')
      this._handleAfterAlertWrongTotalAmount()
      return
    }

    // https://masteringjs.io/tutorials/fundamentals/typeof-date
    // const validDate = new Date('2021-01-01');
    // const zeroDate = new Date(0);
    // const invalidDate = new Date('fail');
    // validDate > 0; // true
    // zeroDate > 0; // false
    // invalidDate > 0; // false
    // if (paymentPostDate instanceof Date && paymentPostDate > 0) {
    if (!(paymentPostDate instanceof Date)) {
      await p.op.alertPromise('ไม่ได้ระบุช่วงเวลาชำระ', 'กรุณาระบุช่วงวันเวลาที่โอนเงิน')
      return
    }

    const { orderType } = this._getParams()
    const paymentAcctLabel = isRefund && orderType === 1 ? 'บัญชีฉันที่จะจ่ายออก' : 'บัญชีปลายทาง'
    // seller_payment_account validation
    if (!_.isObject(selectedSellerPaymentAccount) || !selectedSellerPaymentAccount.id) {
      // console.log('doValidationAndSubmitPayment selectedSellerPaymentAccount => ', selectedSellerPaymentAccount)
      await p.op.alertPromise('ยังไม่ได้เลือกบัญชี', `กรุณาเลือก${paymentAcctLabel}`)
      return
    }

    let isUserConfirmTotalAmount = true
    if (orderToBePaidIds.length === 1) {
      isUserConfirmTotalAmount = await this.isUserConfirmSingleOrderTotalAmount()
    } else {
      // orderToBePaidIds.length > 1
      isUserConfirmTotalAmount = await this.isUserConfirmManyOrderTotalAmount()
    }

    if (!isUserConfirmTotalAmount) {
      return
    }

    // do API shooting
    await this.doSubmitPayment()
    // blah blah blah

    // temporary reset ctoken
    this.ctokenForGBPay = getCreateToken()
    this.ctokenForSCBQRCode = getCreateTokenNoLowercase()
    await setStatePromise(this, { ctoken: this._getCTokenBypaymentMethod() })
  }

  // do only after validation passed
  doSubmitPayment = async (): Promise<void> => {
    const { submitPayment, navigation } = this.props
    const {
      optionChooseAmountWith,

      ordersToBePaid,

      selectedSellerPaymentAccount,
      paymentNote = '',
      paymentSlip,
      paymentTotalAmount,
      paymentPostDate,
      verifySlip: vs,

      isRefund,

      ctoken,
    } = this.state
    const myStoreId = util.getNavParam(this.props, 'myStoreId')
    const orderType = util.getNavParam(this.props, 'orderType')

    const reqBody: {
      [key: string]: any
    } = {
      store_id: myStoreId,
      total_amount: parseFloat(paymentTotalAmount),
      post_date: xFmt.formatToServerDateTime(paymentPostDate),
      payment_account_id: selectedSellerPaymentAccount.id,
      pay_to_store_id: this.payToStoreId,

      orders: [], // will be insert later
    }

    // Append if it have
    if (paymentNote.length > 0) {
      reqBody.note = paymentNote
    }

    if (paymentSlip && paymentSlip.img_uris.length > 0) {
      reqBody.img_uris = paymentSlip.img_uris
      reqBody.thumbnail_uris = paymentSlip.thumbnail_uris
    }

    // if (this.getIsCOD()) {
    if (optionChooseAmountWith === OPTION_COD_AMOUNT) {
      reqBody.is_cod = true
    }

    // reqBody.navFromOrderId = payment.navFromOrderId // need to pass orderId, so the order can be refreshed once paid

    const orderIdKeys = Object.keys(ordersToBePaid)
    for (let i = 0; i < orderIdKeys.length; i++) {
      const orderIdKey = orderIdKeys[i]
      const focusOrder = ordersToBePaid[orderIdKey]
      // const amount = Math.abs(optionChooseAmountWith === OPTION_ORDER_AMOUNT ? focusOrder.remaining_forecast_amount : focusOrder.cod_amount)
      const amount = Math.abs(optionChooseAmountWith === OPTION_ORDER_AMOUNT ? focusOrder.t : focusOrder.f)
      // reqBody.orders.push({ order_id: orderIdKey, amount: Math.abs(payment.ordersToBePaid[orderIdKey]) })
      reqBody.orders.push({ order_id: parseInt(orderIdKey, 10), amount })
    }

    // If there is only one order, the pay amount does NOT need to match the order amount.
    // Hence, set the order amount to the pay amount as required by backend-logic.
    if (reqBody.orders.length === 1) {
      reqBody.orders[0].amount = Math.abs(parseFloat(paymentTotalAmount))
    }

    if (isRefund) {
      // default is false, so no need to send if not true
      reqBody.isRefund = true
    }

    if (ctoken) {
      reqBody.ctoken = ctoken
    }

    // console.log('doSubmitPayment.reqBody: ', reqBody)

    const lastPaymentTotalAmount = xFmt.currencyFormatter.format(reqBody.total_amount) || 0
    let txtSummaryMessage = null

    if (reqBody.orders && reqBody.orders.length > 1) {
      const orderCount = reqBody.orders.length
      txtSummaryMessage = `ยืนยันยอดชำระ ฿${lastPaymentTotalAmount} สำหรับ ${orderCount} ออเดอร์ หรือไม่`
    } else {
      txtSummaryMessage = `ยืนยันยอดชำระ ฿${lastPaymentTotalAmount} หรือไม่`
    }

    // if (!_.isNull(submitMethod)) {
    //   p.op.showConfirmation('สรุปยอดชำระ', txtSummaryMessage, submitMethod)
    // }

    const isVerifySlipSuccessCase = vs && vs.create_payment_validation_status && vs.create_payment_validation_status === 'SUCCESS'
    const isVerifySlipFailCase = vs && vs.create_payment_validation_status && vs.create_payment_validation_status !== 'SUCCESS'

    const isVerifySlipSuccessButMismatchAmount =
      // @ts-ignore
      isVerifySlipSuccessCase && parseFloat(vs.amount) !== parseFloat(reqBody.total_amount)

    if (isVerifySlipSuccessCase && vs.transaction_time) {
      reqBody.post_date = vs.transaction_time
    }

    if (isVerifySlipSuccessCase && !isVerifySlipSuccessButMismatchAmount && vs.sending_bank_code) {
      reqBody.verify_slip_sending_bank = vs.sending_bank_code
    }

    if (isVerifySlipSuccessCase && !isVerifySlipSuccessButMismatchAmount && vs.verify_slip_id) {
      reqBody.verify_slip_id = vs.verify_slip_id
    }

    if (vs && vs.transaction_ref) {
      reqBody.verify_slip_transaction_ref = vs.transaction_ref
    }

    if (isVerifySlipFailCase) {
      reqBody.verify_slip_error_status = vs.create_payment_validation_status
    }

    if (isVerifySlipSuccessButMismatchAmount) {
      // const isUserConfirmTotalAmountMismatch = await p.op.isUserConfirm(
      //   'ยอดสลิปไม่ตรงกับยอดชำระ',
      //   'กรุณายืนยันว่าจะบันทึกชำระแบบ "ยอดสลิปไม่ตรงกับยอดชำระ" หรือไม่'
      // )
      // if (!isUserConfirmTotalAmountMismatch) {
      //   return
      // }
      // delete reqBody.verify_slip_id
      reqBody.verify_slip_error_status = 'MismatchAmount'
    }

    if (_.isObject(vs) && !_.isEmpty(vs) && (isVerifySlipFailCase || isVerifySlipSuccessButMismatchAmount)) {
      let userDecision = 0

      if (_.includes([1, 2], orderType) && acl.canDoAtSelectedStore(xCONS.PERM_STORE_HELPER.PAYMENT_EDIT)) {
        userDecision = await new Promise((resolve) => {
          p.op.showConfirmationThreeButtons(
            p.op.t(`verifySlipStatus.${vs.create_payment_validation_status}`),
            'หากคุณกดปุ่ม "บันทึกการชำระแต่ไม่ยืนยัน" สถานะออเดอร์นี้จะเปลื่ยนเป็น "รอฉัน > ยืนยันรับชำระ" ซึ่งเจ้าของร้านหรือผู้มีสิทธิยืนยันรับชำระ จะสามารถตรวจสอบความถูกต้องก่อนยืนยันอีกครั้งได้ หากคุณกดปุ่ม "บันทึกและยืนยันการชำระนี้" สถานะการชำระจะได้รับการยืนยันทันที หากคุณเป็นเจ้าของร้านหรือมีสิทธิในการยืนยันรับชำระ',
            'บันทึกการชำระแต่ไม่ยืนยัน',
            () => resolve(1),
            'บันทึกและยืนยันการชำระนี้',
            () => resolve(2),
            'ยกเลิก',
            () => resolve(0)
          )
        })
      }

      if (orderType === 3 || (_.includes([1, 2], orderType) && !acl.canDoAtSelectedStore(xCONS.PERM_STORE_HELPER.PAYMENT_EDIT))) {
        userDecision = await new Promise((resolve) => {
          p.op.showConfirmation(
            p.op.t(`verifySlipStatus.${vs.create_payment_validation_status}`),
            'หากคุณ “ยืนยันการชำระนี้” ระบบจะบันทึกชำระนี้และ สถานะออเดอร์จะเปลื่ยนเป็น “รอผู้ขายส่ง > ยืนยันรับชำระ“ เพื่อรอการตรวจสอบจากทางผู้ขาย',
            () => resolve(1),
            () => resolve(0),
            'ยืนยันการชำระนี้',
            'ยกเลิก'
          )
        })
      }

      if (!userDecision) {
        return
      }

      if (userDecision === 1) {
        // บันทึกการชำระแต่ไม่ยืนยัน
        reqBody.verify_slip_force_confirm_payment = false
      }

      if (userDecision === 2) {
        // บันทึกและยืนยันการชำระนี้
        reqBody.verify_slip_force_confirm_payment = true
      }

      // pKeng 17 Oct 2023: MUST wrap img_uris and thumbnail_uris as array
      // Otherwise, reverify slip api will fail (500 error)
      reqBody.img_uris = [paymentSlip.img_uris[0]]
      reqBody.thumbnail_uris = [paymentSlip.thumbnail_uris[0]]
    }

    const isUserConfirmed = await p.op.isUserConfirm('สรุปยอดชำระ', txtSummaryMessage)
    if (!isUserConfirmed) {
      return
    }

    try {
      const apiOptions = {
        messages: {
          successMsg: 'แจ้งชำระเงินเสร็จสิ้น',
          errorMsg: 'เกิดข้อผิดพลาดในการแจ้งชำระเงิน',
        },
        // showSpinner: false,
        axiosOptions: {
          retry: 0,
          timeout: 60000, // long fetch 60 วินาที
        },
      }
      // keep orderId and storeId for updating order later
      // let storeId = body.store_id
      // let orderId = body.navFromOrderId

      const res = await api.put(api.PUT_PAYMENT, reqBody, apiOptions)
      // console.log('doSubmitPayment res: ', res)

      if (res && !_.isEmpty(res)) {
        // await this._handleAfterSuccessSubmitPayment(res)
        await this._handleAfterSuccessSubmitPayment()
      }
    } catch (err) {
      console.log('doSubmitPayment error => ', err)
    }

    await delay(500) // delay for doing callback
  }

  // submitPayment = async (): Promise<void> => {
  //   if (this.inProcess) {
  //     return
  //   }
  //   this.inProcess = true
  //   // const clearInProcessCallback = () =>
  //   //   setTimeout(() => {
  //   //     this.inProcess = false
  //   //   }, 500)
  //   // validate value from editingPayment

  //   // Ref: https://stackoverflow.com/questions/30812765/how-to-remove-undefined-and-null-values-from-an-object-using-lodash
  //   // ตัด null / undefined ออก
  //   const payment: { [key: string]: any } = _.pickBy(this.props.editingPayment.toJS(), _.identity)
  //   // log('_BODY_before_validate_payment_: ', payment)

  //   // const requiredTotalAmount = this.state.amountTotal
  //   const requiredTotalAmount = this.state.requiredRemainingAmountTotal
  //   const orderToBePaidIds = Object.keys(payment.ordersToBePaid) // convert map to array of orderIds (keys)
  //   if (orderToBePaidIds.length < 1) {
  //     p.op.alert('ไม่ได้ระบุออเดอร์', 'กรุณาเลือกออเดอร์ที่รับชำระ')
  //     this.inProcess = false
  //     return
  //   }

  //   // Manual Remove empty array
  //   const arrayKeys = ['orders']
  //   arrayKeys.forEach((key) => {
  //     if (payment[key]) {
  //       if (payment[key].length < 1) {
  //         delete payment[key]
  //       }
  //     }
  //   })

  //   if (payment.total_amount) {
  //     payment.total_amount = parseFloat(payment.total_amount) || 0
  //   }

  //   log('_BODY_after_empty_array_validate_payment_: ', payment)

  //   // Ref: https://stackoverflow.com/questions/29001762/lodash-has-for-multiple-keys
  //   // เช็คว่ามี field ครบรึเปล่า
  //   // const requireFields = ['total_amount', 'img_uri', 'thumbnail_uri', 'orders', 'date']
  //   // const { orderType } = this.props.navigation.state.params
  //   const { orderType } = this._getParams()
  //   const paymentAcctLabel = this.state.isRefund && orderType === 1 ? 'บัญชีฉันที่จะจ่ายออก' : 'บัญชีปลายทาง'
  //   const requireFields = ['total_amount', 'ordersToBePaid', 'post_date', 'seller_payment_account']
  //   const requireDisplays = ['ยอดชำระ', 'ออเดอร์ที่ทำรายการ', 'วันเวลาที่โอนเงิน', paymentAcctLabel]
  //   // var isReadyForSubmit = false

  //   for (let i = 0; i < requireFields.length; i++) {
  //     const key = requireFields[i]
  //     const txtWarning = requireDisplays[i]

  //     const value = payment[key]
  //     if (_.isNull(value) || _.isUndefined(value) || value === '') {
  //       p.op.alert('ข้อมูลไม่ครบถ้วน', `กรุณาระบุ "${txtWarning}"`, () => this._handleAfterAlertMissingField(key))
  //       this.inProcess = false
  //       return
  //     }
  //   }

  //   if (payment.total_amount <= 0) {
  //     log(`submitPayment payment.total_amount = ${payment.total_amount}`)
  //     p.op.alert('ยอดชำระไม่ถูกต้อง', 'กรุณาระบุค่าของยอดชำระเงิน', () => this._handleAfterAlertWrongTotalAmount())
  //     this.inProcess = false
  //     return
  //   }

  //   const isUserConfirmPaymentSlip = await this._isUserConfirmEmptyPaymentSlip(payment)
  //   if (!isUserConfirmPaymentSlip) {
  //     this.inProcess = false
  //     return
  //   }

  //   let isUserConfirmTotalAmount = true
  //   if (orderToBePaidIds.length > 1) {
  //     isUserConfirmTotalAmount = await this._isUserConfirmManyOrderTotalAmount(payment, requiredTotalAmount)
  //   } else if (orderToBePaidIds.length === 1) {
  //     isUserConfirmTotalAmount = await this._isUserConfirmSingleOrderTotalAmount(payment, requiredTotalAmount)
  //   } else {
  //     p.op.alert('ไม่ได้ระบุออเดอร์', 'กรุณาเลือกออเดอร์ที่รับชำระ')
  //     this.inProcess = false
  //     return
  //   }

  //   if (!isUserConfirmTotalAmount) {
  //     this.inProcess = false
  //     return
  //   }

  //   await this._doSubmitPayment(payment)

  //   // temporary reset ctoken
  //   this.ctokenForGBPay = getCreateToken()
  //   this.ctokenForSCBQRCode = getCreateTokenNoLowercase()
  //   await setStatePromise(this, { ctoken: this._getCTokenBypaymentMethod() })

  //   this.inProcess = false
  // }

  _getCTokenBypaymentMethod = (pGatewayMode?: IPaymentMethod) => {
    const paymentMethod = _.isNumber(pGatewayMode) ? pGatewayMode : this.state.paymentMethod
    if (_.includes([xCONS.PAYMENT_METHOD.SCB_QR_CODE, xCONS.PAYMENT_METHOD.BEAM], paymentMethod)) {
      return this.ctokenForSCBQRCode
    }
    return this.ctokenForGBPay
  }

  // _onChangeGBOptions = async (newValue): Promise<void> => {
  //   // console.log('_onChangeGBOptions newValue => ', newValue)
  //   const { onPaymentChange } = this.props
  //   const { gbAccountList } = this.state
  //   // let ctoken = this.ctokenForGBPay
  //   if (newValue === 1) {
  //     for (let i = 0; i < gbAccountList.size; i++) {
  //       const paymentAcc: Map<string, any> = gbAccountList.get(i)
  //       if (Map.isMap(paymentAcc) && paymentAcc.get('bank_id') === xCONS.SYSTEM_BANK_IDS.GBPAY) {
  //         // gbPayMode
  //         onPaymentChange({ key: 'seller_payment_account', value: paymentAcc })
  //       }
  //     }
  //   } else if (newValue === 2) {
  //     for (let i = 0; i < gbAccountList.size; i++) {
  //       const paymentAcc: Map<string, any> = gbAccountList.get(i)
  //       if (Map.isMap(paymentAcc) && paymentAcc.get('bank_id') === xCONS.SYSTEM_BANK_IDS.SCB_QR_CODE) {
  //         // SCB QR PayMode
  //         onPaymentChange({ key: 'seller_payment_account', value: paymentAcc })
  //         // ctoken = this.ctokenForSCBQRCode
  //       }
  //     }
  //   } else {
  //     // value === 0 // Normal Mode
  //     onPaymentChange({ key: 'seller_payment_account', value: null })
  //   }
  //   await setStatePromise(this, { paymentMethod: newValue, ctoken: this._getCTokenBypaymentMethod(newValue) })
  // }

  _onChangeGBOptions = async (newValue): Promise<void> => {
    // console.log('_onChangeGBOptions newValue => ', newValue)
    const { gbAccountList } = this.state
    // let ctoken = this.ctokenForGBPay
    let selectedSellerPaymentAccount: IPaymentAccount | null
    if (newValue === 1) {
      for (let i = 0; i < gbAccountList.size; i++) {
        const paymentAcc: IMap<IPaymentAccount> = gbAccountList.get(i)
        if (Map.isMap(paymentAcc) && paymentAcc.get('bank_id') === xCONS.SYSTEM_BANK_IDS.GBPAY) {
          // gbPayMode
          // onPaymentChange({ key: 'seller_payment_account', value: paymentAcc })
          selectedSellerPaymentAccount = paymentAcc.toJS()
        }
      }
    } else if (newValue === 2) {
      for (let i = 0; i < gbAccountList.size; i++) {
        const paymentAcc: IMap<IPaymentAccount> = gbAccountList.get(i)
        if (Map.isMap(paymentAcc) && paymentAcc.get('bank_id') === xCONS.SYSTEM_BANK_IDS.SCB_QR_CODE) {
          // SCB QR PayMode
          // onPaymentChange({ key: 'seller_payment_account', value: paymentAcc })
          selectedSellerPaymentAccount = paymentAcc.toJS()
          // ctoken = this.ctokenForSCBQRCode
        }
      }
    } else {
      // value === 0 // Normal Mode
      // onPaymentChange({ key: 'seller_payment_account', value: null })
      selectedSellerPaymentAccount = null
    }
    await setStatePromise(this, {
      paymentMethod: newValue,
      ctoken: this._getCTokenBypaymentMethod(newValue),
      selectedSellerPaymentAccount,
    })
  }

  getIsCOD = () => {
    const { navigation } = this.props
    const isCOD = util.getNavParam(this.props, 'isCOD', false)
    // const orderType = util.getNavParam(this.props, 'orderType', 3)
    // if (orderType === 3) {
    //   isCOD = false
    // }
    return isCOD
  }

  getGBPayQRCodeUrl = async (): Promise<string | null> => {
    const { navigation } = this.props
    const {
      ordersToBePaid = {},
      paymentMethod,
      paymentTotalAmount,
      isRefund,
      selectedSellerPaymentAccount,
      optionChooseAmountWith,
      requiredCodAmountTotal,
      requiredRemainingAmountTotal,
    } = this.state
    const myStoreId = util.getNavParam(this.props, 'myStoreId')
    const orderType = util.getNavParam(this.props, 'orderType')
    const isCOD = this.getIsCOD()
    const isOptionCODAmountSelected = optionChooseAmountWith === OPTION_COD_AMOUNT
    const requiredTotalAmount = isOptionCODAmountSelected ? requiredCodAmountTotal : requiredRemainingAmountTotal
    const orderToBePaidIds = Object.keys(ordersToBePaid) // convert map to array of orderIds (keys)
    if (orderToBePaidIds.length < 1) {
      p.op.alert('ไม่ได้ระบุออเดอร์', 'กรุณาเลือกออเดอร์ที่รับชำระ')
      return null
    }

    const paymentTotalAmountFloat = parseFloat(paymentTotalAmount)
    if (typeof paymentTotalAmountFloat !== 'number' || paymentTotalAmountFloat <= 0) {
      await p.op.alertPromise('ยอดชำระไม่ถูกต้อง', 'กรุณาระบุยอดชำระ โดยยอดชำระต้องมากกว่า 0')
      this._handleAfterAlertWrongTotalAmount()
      return null
    }

    const paymentAcctLabel = isRefund && orderType === 1 ? 'บัญชีฉันที่จะจ่ายออก' : 'บัญชีปลายทาง'
    // seller_payment_account validation
    if (!_.isObject(selectedSellerPaymentAccount) || !selectedSellerPaymentAccount.id) {
      // console.log('doValidationAndSubmitPayment selectedSellerPaymentAccount => ', selectedSellerPaymentAccount)
      await p.op.alertPromise('ยังไม่ได้เลือกบัญชี', `กรุณาเลือก${paymentAcctLabel}`)
      return null
    }

    let isUserConfirm = true

    if (orderToBePaidIds.length > 1) {
      isUserConfirm = await this.isUserConfirmManyOrderTotalAmount()
    } else if (orderToBePaidIds.length === 1) {
      isUserConfirm = await this.isUserConfirmSingleOrderTotalAmount()
    }

    if (!isUserConfirm) {
      return null
    }

    const body: IAnyObject = {
      payment_account_id: selectedSellerPaymentAccount.id,
      total_amount: paymentTotalAmountFloat,
      store_id: myStoreId,
      // store_id: this.payToStoreId,
    }

    if (this.payToStoreId) {
      body.pay_to_store_id = this.payToStoreId
    }

    // if (payment.note) {
    //   body.note = payment.note
    // }

    // if (isCOD) {
    if (isOptionCODAmountSelected) {
      body.is_cod = true
    }

    // @ts-ignore FIXME: Don't know what exactly types
    _.mapKeys(ordersToBePaid, (val, key) => {
      // log('_.mapKeys(payment.ordersToBePaid.key', key)
      // log('_.mapKeys(payment.ordersToBePaid.val', val)
      // const { remaining_forecast_amount } = val
      // const amountToPay = isOptionCODAmountSelected ? val.cod_amount : val.remaining_forecast_amount
      let amountToPay = isOptionCODAmountSelected ? val.f : val.t
      if (_.isString(amountToPay)) {
        amountToPay = parseFloat(amountToPay)
      }
      if (body.orders && body.orders.length > 0) {
        // body.orders = body.orders + ',' + `${key}${val}`
        body.orders = `${body.orders},${key}`
        body.order_amounts = `${body.order_amounts},${amountToPay}`
      } else {
        // body.orders = `${key}:${val}`
        body.orders = key
        body.order_amounts = Math.abs(amountToPay)
      }
    })

    // const orderIdKeys = Object.keys(ordersToBePaid)
    // for (let i = 0; i < orderIdKeys.length; i++) {
    //   const orderIdKey = orderIdKeys[i]
    //   const focusOrder = ordersToBePaid[orderIdKey]
    //   const amount = Math.abs(optionChooseAmountWith === OPTION_ORDER_AMOUNT ? focusOrder.remaining_forecast_amount : focusOrder.cod_amount)
    //   // reqBody.orders.push({ order_id: orderIdKey, amount: Math.abs(payment.ordersToBePaid[orderIdKey]) })
    //   reqBody.orders.push({ order_id: orderIdKey, amount })
    // }

    // // If there is only one order, the pay amount does NOT need to match the order amount.
    // // Hence, set the order amount to the pay amount as required by backend-logic.
    // if (reqBody.orders.length === 1) {
    //   reqBody.orders[0].amount = Math.abs(parseFloat(paymentTotalAmount))
    // }

    const eCToken = paymentMethod === xCONS.PAYMENT_METHOD.GBPAY ? 'payment-gbpayqr' : ``
    if (body.store_id) {
      body.ctoken = this.state.ctoken + eCToken
      let url = `${settings.app.API_ROOT}/payment/gbpayqr`
      if (paymentMethod === xCONS.PAYMENT_METHOD.SCB_QR_CODE) {
        url = `${settings.app.GO_API_ROOT}/payment/gateway_tran_qr`
      }
      const params = this.getUrlParamsFromJSON(body)
      if (params) {
        url = `${url}?${params}`
      }
      return url
      // requestQRCode({ body, successCallback: clearInProcessCallback, failedCallback: clearInProcessCallback })
    }

    return null
  }

  // getGBPayQRCodeUrl = async (): Promise<string | null> => {
  //   const { navigation } = this.props
  //   const { paymentMethod } = this.state
  //   const myStoreId = util.getNavParam(this.props, 'myStoreId')
  //   const orderType = util.getNavParam(this.props, 'orderType')
  //   const isCOD = this.getIsCOD()
  //   const isOptionCODAmountSelected = this.state.optionChooseAmountWith === OPTION_COD_AMOUNT
  //   const payment: { [key: string]: any } = _.pickBy(this.props.editingPayment.toJS(), _.identity)
  //   // const requiredTotalAmount = this.state.amountTotal
  //   const requiredTotalAmount = isOptionCODAmountSelected ? this.state.requiredCodAmountTotal : this.state.requiredRemainingAmountTotal
  //   const orderToBePaidIds = Object.keys(payment.ordersToBePaid) // convert map to array of orderIds (keys)
  //   if (orderToBePaidIds.length < 1) {
  //     p.op.alert('ไม่ได้ระบุออเดอร์', 'กรุณาเลือกออเดอร์ที่รับชำระ')
  //     return null
  //   }

  //   // Manual Remove empty array
  //   const arrayKeys = ['orders']
  //   arrayKeys.forEach((key) => {
  //     if (payment[key]) {
  //       if (payment[key].length < 1) {
  //         delete payment[key]
  //       }
  //     }
  //   })

  //   log('_payment_after_empty_array_validate_payment_: ', payment)
  //   const paymentAcctLabel = this.state.isRefund && orderType === 1 ? 'บัญชีฉันที่จะจ่ายออก' : 'บัญชีปลายทาง'
  //   const requireFields = ['total_amount', 'ordersToBePaid', 'seller_payment_account']
  //   const requireDisplays = ['ยอดชำระ', 'ออเดอร์ที่ทำรายการ', paymentAcctLabel]

  //   for (let i = 0; i < requireFields.length; i++) {
  //     const key = requireFields[i]
  //     const txtWarning = requireDisplays[i]

  //     const value = payment[key]
  //     if (_.isNull(value) || _.isUndefined(value) || value === '') {
  //       p.op.alert('ข้อมูลไม่ครบถ้วน', `กรุณาระบุ "${txtWarning}"`, () => {
  //         this._handleAfterAlertMissingField(key)
  //       })
  //       return null
  //     }
  //   }

  //   if (payment.total_amount <= 0) {
  //     log(`_getGBPayQRCodeUrl payment.total_amount = ${payment.total_amount}`)
  //     p.op.alert('ยอดชำระไม่ถูกต้อง', 'กรุณาระบุค่าของยอดชำระเงิน', () => this._handleAfterAlertWrongTotalAmount())
  //     return null
  //   }

  //   // if (payment.total_amount) {
  //   //   payment.total_amount = parseFloat(payment.total_amount) || 0
  //   // } else if (!_.isNumber(payment.total_amount) || payment.total_amount <= 0) {
  //   //   const orderIds = Object.keys(payment.ordersToBePaid)
  //   //   let total_amount = 0
  //   //   orderIds.forEach(oId => {
  //   //     total_amount = total_amount + parseFloat(payment.ordersToBePaid[oId])
  //   //   })
  //   //   payment.total_amount = total_amount
  //   // }

  //   let body
  //   body = {}

  //   if (payment.seller_payment_account && payment.seller_payment_account.id) {
  //     body.payment_account_id = parseInt(payment.seller_payment_account.id)
  //   }

  //   // // Validate Total Amount
  //   // const validatedTotalAmount = await new Promise<number | null>(resolve => {
  //   //   const resolveCallback = () => resolve(payment.total_amount)
  //   //   const rejectCallback = () => resolve(null)
  //   //
  //   //   if (orderToBePaidIds.length > 1) {
  //   //     this._checkingTotalAmountForManyOrders(payment, requiredTotalAmount, resolveCallback, rejectCallback)
  //   //   } else if (orderToBePaidIds.length === 1) {
  //   //     this._checkingTotalAmountForOneOrder(payment, requiredTotalAmount, resolveCallback, rejectCallback)
  //   //   } else {
  //   //     // Should be impossible case
  //   //     rejectCallback()
  //   //   }
  //   // })
  //   //
  //   // if (validatedTotalAmount) {
  //   //   body.total_amount = parseFloat(validatedTotalAmount)
  //   // } else {
  //   //   return null
  //   // }

  //   let isUserConfirm = true

  //   if (orderToBePaidIds.length > 1) {
  //     isUserConfirm = await this._isUserConfirmManyOrderTotalAmount(payment, requiredTotalAmount)
  //   } else if (orderToBePaidIds.length === 1) {
  //     isUserConfirm = await this._isUserConfirmSingleOrderTotalAmount(payment, requiredTotalAmount)
  //   }

  //   if (!isUserConfirm) {
  //     return null
  //   }

  //   body.total_amount = parseFloat(payment.total_amount)

  //   if (myStoreId) {
  //     body.store_id = myStoreId
  //   }

  //   if (payment.note) {
  //     body.note = payment.note
  //   }
  //   if (isCOD) {
  //     body.is_cod = true
  //   }

  //   // _.mapKeys(payment.ordersToBePaid, (val, key) => {
  //   //   body.orders.push({ order_id: parseInt(key), amount: parseFloat(val) })
  //   // })
  //   // log('getGBPayQRCodeUrl.this.state', this.state)

  //   // @ts-ignore FIXME: Don't know what exactly types
  //   _.mapKeys(payment.ordersToBePaid, (val, key) => {
  //     // log('_.mapKeys(payment.ordersToBePaid.key', key)
  //     // log('_.mapKeys(payment.ordersToBePaid.val', val)
  //     // const { remaining_forecast_amount } = val
  //     const amountToPay = isOptionCODAmountSelected ? val.cod_amount : val.remaining_forecast_amount
  //     if (body.orders && body.orders.length > 0) {
  //       // body.orders = body.orders + ',' + `${key}${val}`
  //       body.orders = `${body.orders},${key}`
  //       body.order_amounts = `${body.order_amounts},${amountToPay}`
  //     } else {
  //       // body.orders = `${key}:${val}`
  //       body.orders = key
  //       body.order_amounts = Math.abs(amountToPay)
  //     }
  //   })

  //   // log('_requestGBPayQRCode payment => ', payment)
  //   // log('_requestGBPayQRCode body => ', body)
  //   const eCToken = paymentMethod === 1 ? 'payment-gbpayqr' : ``
  //   if (body.store_id) {
  //     body.ctoken = this.state.ctoken + eCToken
  //     let url = `${settings.app.API_ROOT}/payment/gbpayqr`
  //     if (paymentMethod === 2) {
  //       url = `${settings.app.GO_API_ROOT}/payment/gateway_tran_qr`
  //     }
  //     const params = this.getUrlParamsFromJSON(body)
  //     if (params) {
  //       url = `${url}?${params}`
  //     }
  //     return url
  //     // requestQRCode({ body, successCallback: clearInProcessCallback, failedCallback: clearInProcessCallback })
  //   }

  //   return null
  // }

  getUrlParamsFromJSON = (data: { [key: string]: any }): string | null => {
    if (_.isEmpty(data)) {
      return null
    }
    return Object.keys(data)
      .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`)
      .join('&')
  }

  // _checkingAndAlertForPaymentSlip = (payment: object, resolveCallback: () => void, rejectCallback?: () => void) => {
  //   const { orderType } = this._getParams()
  //   if (payment && (!payment.img_uris || payment.img_uris.length < 1) && orderType && orderType !== 1) {
  //     p.op.showConfirmation(
  //       'ไม่ได้แนบสลิปโอนเงิน',
  //       'หากคุณไม่ได้แนบสลิปโอนเงินคู่ค้าของคุณอาจปฏิเสธการแจ้งชำระของคุณได้ คุณยืนยันไม่แนบสลิปโอนเงินหรือไม่',
  //       resolveCallback,
  //       rejectCallback,
  //     )
  //   } else {
  //     resolveCallback()
  //   }
  // }

  // _isSubmitPaymentSlip = payment => {
  //   const { orderType } = this._getParams()
  //   return payment && (!payment.img_uris || payment.img_uris.length < 1) && orderType && orderType !== 1
  // }

  _isUserConfirmEmptyPaymentSlip = async (payment): Promise<boolean> => {
    let isUserConfirm = true
    const { orderType } = this._getParams()
    if (payment && (!payment.img_uris || payment.img_uris.length < 1) && orderType && orderType !== 1) {
      isUserConfirm = await p.op.isUserConfirm(
        'ไม่ได้แนบสลิปโอนเงิน',
        'หากคุณไม่ได้แนบสลิปโอนเงินคู่ค้าของคุณอาจปฏิเสธการแจ้งชำระของคุณได้ คุณยืนยันไม่แนบสลิปโอนเงินหรือไม่'
      )
    }
    return isUserConfirm
  }

  // _checkingTotalAmountForOneOrder = (
  //   payment: { total_amount: number; [key: string]: any },
  //   requiredTotalAmount: number,
  //   resolveCallback: () => void,
  //   rejectCallback?: () => void,
  // ): void => {
  //   let inputTotalAmount = payment && payment.total_amount ? parseFloat(payment.total_amount) : 0
  //
  //   if (this.state.isRefund) {
  //     inputTotalAmount = Math.abs(inputTotalAmount)
  //   }
  //
  //   if (Math.abs(requiredTotalAmount) > inputTotalAmount) {
  //     if (requiredTotalAmount > 0) {
  //       p.op.showConfirmation(
  //         'แจ้งชำระไม่ครบ!',
  //         'ยอดชำระที่คุณแจ้ง น้อยกว่า ยอดรวมออเดอร์ คุณยังต้องการแจ้งชำระตามยอดที่ระบุมาอยู่หรือไม่',
  //         resolveCallback,
  //         rejectCallback,
  //         'ยืนยัน',
  //         'ยกเลิก',
  //         false,
  //       )
  //     } else {
  //       p.op.showConfirmation(
  //         'แจ้งคืนเงินไม่ครบ!',
  //         'ยอดเงินคืนที่คุณแจ้ง น้อยกว่า ยอดรวมที่ต้องคืน คุณยังต้องการแจ้งคืนเงินตามยอดที่ระบุมาอยู่หรือไม่',
  //         resolveCallback,
  //         rejectCallback,
  //         'ยืนยัน',
  //         'ยกเลิก',
  //         false,
  //       )
  //     }
  //   } else if (Math.abs(requiredTotalAmount) < inputTotalAmount) {
  //     if (requiredTotalAmount > 0) {
  //       p.op.showConfirmation(
  //         'แจ้งชำระเกินยอดชำระ!',
  //         'ยอดชำระที่คุณแจ้ง มากกว่า ยอดรวมออเดอร์ คุณยังต้องการแจ้งชำระตามยอดที่ระบุมาอยู่หรือไม่',
  //         resolveCallback,
  //         rejectCallback,
  //         'ยืนยัน',
  //         'ยกเลิก',
  //         false,
  //       )
  //     } else {
  //       p.op.showConfirmation(
  //         'แจ้งคืนเงินเกินยอดรวม!',
  //         'ยอดเงินคืนที่คุณแจ้ง มากกว่า ยอดรวมที่ต้องคืน คุณยังต้องการแจ้งคืนเงินตามยอดที่ระบุมาอยู่หรือไม่',
  //         resolveCallback,
  //         rejectCallback,
  //         'ยืนยัน',
  //         'ยกเลิก',
  //         false,
  //       )
  //     }
  //   } else {
  //     resolveCallback()
  //   }
  // }

  isUserConfirmSingleOrderTotalAmount = async (): Promise<boolean> => {
    const { requiredRemainingAmountTotal, paymentTotalAmount, isRefund } = this.state
    const requiredTotalAmount = requiredRemainingAmountTotal
    let isUserConfirm = true
    let inputTotalAmount = paymentTotalAmount && typeof parseFloat(paymentTotalAmount) ? parseFloat(paymentTotalAmount) : 0

    if (isRefund) {
      inputTotalAmount = Math.abs(inputTotalAmount)
    }

    if (Math.abs(requiredTotalAmount) > inputTotalAmount) {
      if (requiredTotalAmount > 0) {
        isUserConfirm = await p.op.isUserConfirm(
          'แจ้งชำระไม่ครบ!',
          'ยอดชำระที่คุณแจ้ง น้อยกว่า ยอดรวมออเดอร์ คุณยังต้องการแจ้งชำระตามยอดที่ระบุมาอยู่หรือไม่',
          'ยืนยัน',
          'ยกเลิก'
        )
      } else {
        isUserConfirm = await p.op.isUserConfirm(
          'แจ้งคืนเงินไม่ครบ!',
          'ยอดเงินคืนที่คุณแจ้ง น้อยกว่า ยอดรวมที่ต้องคืน คุณยังต้องการแจ้งคืนเงินตามยอดที่ระบุมาอยู่หรือไม่',
          'ยืนยัน',
          'ยกเลิก'
        )
      }
    } else if (Math.abs(requiredTotalAmount) < inputTotalAmount) {
      if (requiredTotalAmount > 0) {
        isUserConfirm = await p.op.isUserConfirm(
          'แจ้งชำระเกินยอดชำระ!',
          'ยอดชำระที่คุณแจ้ง มากกว่า ยอดรวมออเดอร์ คุณยังต้องการแจ้งชำระตามยอดที่ระบุมาอยู่หรือไม่',
          'ยืนยัน',
          'ยกเลิก'
        )
      } else {
        isUserConfirm = await p.op.isUserConfirm(
          'แจ้งคืนเงินเกินยอดรวม!',
          'ยอดเงินคืนที่คุณแจ้ง มากกว่า ยอดรวมที่ต้องคืน คุณยังต้องการแจ้งคืนเงินตามยอดที่ระบุมาอยู่หรือไม่',
          'ยืนยัน',
          'ยกเลิก'
        )
      }
    }
    return isUserConfirm
  }

  isUserConfirmManyOrderTotalAmount = async (): Promise<boolean> => {
    const { requiredRemainingAmountTotal, paymentTotalAmount, isRefund } = this.state
    const requiredTotalAmount = requiredRemainingAmountTotal

    let isUserConfirm = true
    // log('_isUserConfirmManyOrderTotalAmount.this.state', this.state)
    // log('_isUserConfirmManyOrderTotalAmount.payment', payment)
    let inputTotalAmount = paymentTotalAmount && typeof parseFloat(paymentTotalAmount) ? parseFloat(paymentTotalAmount) : 0
    if (isRefund) {
      inputTotalAmount = Math.abs(inputTotalAmount)
    }
    if (Math.abs(requiredTotalAmount) !== inputTotalAmount) {
      // log('_checkingTotalAmountForManyOrders requiredTotalAmount = ' + requiredTotalAmount + ' inputTotalAmount = ' + inputTotalAmount)
      isUserConfirm = await p.op.isUserConfirm('ยอดชำระไม่ถูกต้อง', 'กรุณาระบุยอดชำระให้ตรงกับยอดรวมออเดอร์')
    }
    return isUserConfirm
  }

  // _isUserConfirmSingleOrderTotalAmount = async (payment, requiredTotalAmount): Promise<boolean> => {
  //   let isUserConfirm = true
  //   let inputTotalAmount = payment && payment.total_amount ? parseFloat(payment.total_amount) : 0

  //   if (this.state.isRefund) {
  //     inputTotalAmount = Math.abs(inputTotalAmount)
  //   }

  //   if (Math.abs(requiredTotalAmount) > inputTotalAmount) {
  //     if (requiredTotalAmount > 0) {
  //       isUserConfirm = await p.op.isUserConfirm(
  //         'แจ้งชำระไม่ครบ!',
  //         'ยอดชำระที่คุณแจ้ง น้อยกว่า ยอดรวมออเดอร์ คุณยังต้องการแจ้งชำระตามยอดที่ระบุมาอยู่หรือไม่',
  //         'ยืนยัน',
  //         'ยกเลิก'
  //       )
  //     } else {
  //       isUserConfirm = await p.op.isUserConfirm(
  //         'แจ้งคืนเงินไม่ครบ!',
  //         'ยอดเงินคืนที่คุณแจ้ง น้อยกว่า ยอดรวมที่ต้องคืน คุณยังต้องการแจ้งคืนเงินตามยอดที่ระบุมาอยู่หรือไม่',
  //         'ยืนยัน',
  //         'ยกเลิก'
  //       )
  //     }
  //   } else if (Math.abs(requiredTotalAmount) < inputTotalAmount) {
  //     if (requiredTotalAmount > 0) {
  //       isUserConfirm = await p.op.isUserConfirm(
  //         'แจ้งชำระเกินยอดชำระ!',
  //         'ยอดชำระที่คุณแจ้ง มากกว่า ยอดรวมออเดอร์ คุณยังต้องการแจ้งชำระตามยอดที่ระบุมาอยู่หรือไม่',
  //         'ยืนยัน',
  //         'ยกเลิก'
  //       )
  //     } else {
  //       isUserConfirm = await p.op.isUserConfirm(
  //         'แจ้งคืนเงินเกินยอดรวม!',
  //         'ยอดเงินคืนที่คุณแจ้ง มากกว่า ยอดรวมที่ต้องคืน คุณยังต้องการแจ้งคืนเงินตามยอดที่ระบุมาอยู่หรือไม่',
  //         'ยืนยัน',
  //         'ยกเลิก'
  //       )
  //     }
  //   }
  //   return isUserConfirm
  // }

  // _checkingTotalAmountForManyOrders(
  //   payment: { total_amount: number; [key: string]: any },
  //   requiredTotalAmount: number,
  //   resolveCallback: () => void,
  //   rejectCallback?: () => void,
  // ): void {
  //   let inputTotalAmount = payment && payment.total_amount ? parseFloat(payment.total_amount) : 0
  //
  //   if (this.state.isRefund) {
  //     inputTotalAmount = Math.abs(inputTotalAmount)
  //   }
  //
  //   if (Math.abs(requiredTotalAmount) !== inputTotalAmount) {
  //     log('_checkingTotalAmountForManyOrders requiredTotalAmount = ' + requiredTotalAmount + ' inputTotalAmount = ' + inputTotalAmount)
  //     p.op.alert('ยอดชำระไม่ถูกต้อง', 'กรุณาระบุยอดชำระให้ตรงกับยอดรวมออเดอร์', rejectCallback)
  //   } else {
  //     resolveCallback()
  //   }
  // }

  // _isUserConfirmManyOrderTotalAmount = async (payment, requiredTotalAmount): Promise<boolean> => {
  //   let isUserConfirm = true
  //   // log('_isUserConfirmManyOrderTotalAmount.this.state', this.state)
  //   // log('_isUserConfirmManyOrderTotalAmount.payment', payment)
  //   let inputTotalAmount = payment && payment.total_amount ? parseFloat(payment.total_amount) : 0
  //   if (this.state.isRefund) {
  //     inputTotalAmount = Math.abs(inputTotalAmount)
  //   }
  //   if (Math.abs(requiredTotalAmount) !== inputTotalAmount) {
  //     // log('_checkingTotalAmountForManyOrders requiredTotalAmount = ' + requiredTotalAmount + ' inputTotalAmount = ' + inputTotalAmount)
  //     isUserConfirm = await p.op.isUserConfirm('ยอดชำระไม่ถูกต้อง', 'กรุณาระบุยอดชำระให้ตรงกับยอดรวมออเดอร์')
  //   }
  //   return isUserConfirm
  // }

  // _doSubmitPayment = async (payment): Promise<void> => {
  //   let reqBody: {
  //     [key: string]: any
  //   }
  //   reqBody = {}
  //   const { submitPayment, navigation } = this.props
  //   const { optionChooseAmountWith } = this.state
  //   const myStoreId = util.getNavParam(this.props, 'myStoreId')

  //   await setStatePromise(this, { submitting: true })
  //   await delay(200)
  //   log('_doSubmitPayment.payment: ', payment)
  //   // log(payment)
  //   // prepare 'payment' for submit (map key)

  //   // Append if it have
  //   if ('note' in payment) {
  //     reqBody.note = payment.note
  //   }
  //   if (payment && payment.img_uris) {
  //     reqBody.img_uris = payment.img_uris
  //     // ถ้าไม่เป็น type 1 จะ เช็คว่ามี slip อัพโหลดมาหรือไม่
  //   }
  //   if (payment && payment.thumbnail_uris) {
  //     reqBody.thumbnail_uris = payment.thumbnail_uris
  //   }
  //   if (this.getIsCOD()) {
  //     reqBody.is_cod = true
  //   }

  //   reqBody.navFromOrderId = payment.navFromOrderId // need to pass orderId, so the order can be refreshed once paid
  //   reqBody.total_amount = payment.total_amount
  //   reqBody.post_date = xFmt.formatToServerDateTime(payment.post_date)
  //   reqBody.store_id = myStoreId
  //   reqBody.payment_account_id = payment.seller_payment_account.id
  //   reqBody.pay_to_store_id = this.payToStoreId
  //   // reqBody.pay_to_store_id = orderStoreId
  //   reqBody.orders = []
  //   // _.mapKeys(payment.ordersToBePaid, (val, key) => {
  //   //   reqBody.orders.push({ order_id: key, amount: Math.abs(val) })
  //   // })

  //   const orderIdKeys = Object.keys(payment.ordersToBePaid).sort((a, b) => parseInt(a) - parseInt(b))
  //   for (const orderIdKey of orderIdKeys) {
  //     const focusOrder = payment.ordersToBePaid[orderIdKey]
  //     const amount = Math.abs(optionChooseAmountWith === OPTION_ORDER_AMOUNT ? focusOrder.remaining_forecast_amount : focusOrder.cod_amount)
  //     // reqBody.orders.push({ order_id: orderIdKey, amount: Math.abs(payment.ordersToBePaid[orderIdKey]) })
  //     reqBody.orders.push({ order_id: orderIdKey, amount })
  //   }

  //   // If there is only one order, the pay amount does NOT need to match the order amount.
  //   // Hence, set the order amount to the pay amount as required by backend-logic.
  //   if (reqBody.orders.length === 1) {
  //     reqBody.orders[0].amount = Math.abs(payment.total_amount)
  //   }
  //   if (this.state.isRefund) {
  //     // default is false, so no need to send if not true
  //     reqBody.isRefund = true
  //   }

  //   if (this.state.ctoken) {
  //     reqBody.ctoken = this.state.ctoken
  //   }

  //   log('_doSubmitPayment.reqBody: ', reqBody)
  //   // let submitMethod = () => submitPayment({
  //   //   body: reqBody,
  //   //   refreshOrderDetail: true,
  //   //   callback: (res) => this._handleAfterSuccessSubmitPayment(res),
  //   // })

  //   const lastPaymentTotalAmount = xFmt.currencyFormatter.format(reqBody.total_amount) || 0
  //   let txtSummaryMessage = null

  //   if (reqBody.orders && reqBody.orders.length > 1) {
  //     const orderCount = reqBody.orders.length
  //     txtSummaryMessage = `ยืนยันยอดชำระ ฿${lastPaymentTotalAmount} สำหรับ ${orderCount} ออเดอร์ หรือไม่`
  //   } else {
  //     txtSummaryMessage = `ยืนยันยอดชำระ ฿${lastPaymentTotalAmount} หรือไม่`
  //   }

  //   // if (!_.isNull(submitMethod)) {
  //   //   p.op.showConfirmation('สรุปยอดชำระ', txtSummaryMessage, submitMethod)
  //   // }

  //   const isUserConfirmed = await p.op.isUserConfirm('สรุปยอดชำระ', txtSummaryMessage)
  //   if (!isUserConfirmed) {
  //     await setStatePromise(this, { submitting: false })
  //     return
  //   }

  //   const okResponse = await new Promise<any>((submitted) => {
  //     submitPayment({
  //       body: reqBody,
  //       refreshOrderDetail: true,
  //       successCallback: (res) => submitted(res),
  //       failedCallback: () => submitted(null),
  //     })
  //   })
  //   await new Promise((reduxDelay) => setTimeout(reduxDelay, 200))
  //   // console.log('okResponse => ', okResponse)
  //   if (okResponse && !_.isEmpty(okResponse)) {
  //     await this._handleAfterSuccessSubmitPayment(okResponse)
  //   }

  //   await delay(500) // delay for doing callback
  //   await setStatePromise(this, { submitting: false })
  // }

  // getOrdersToBePaidAmountTotal = () => {
  //   const { ordersToBePaid } = this.props
  //   const { optionChooseAmountWith = OPTION_ORDER_AMOUNT } = this.state
  //   let requiredRemainingAmountTotal = 0
  //   let requiredCodAmountTotal = 0
  //   if (Map.isMap(ordersToBePaid) && ordersToBePaid.size > 0) {
  //     const orderKeyIds = ordersToBePaid.keySeq().toArray()
  //     for (const orderKeyId of orderKeyIds) {
  //       const orderData = ordersToBePaid.get(orderKeyId)
  //       const remainingAmount = orderData.get('remaining_forecast_amount') || 0
  //       const codAmount = orderData.get('cod_amount') || 0
  //       // @ts-ignore maybe string|number
  //       requiredRemainingAmountTotal += parseFloat(remainingAmount)
  //       // @ts-ignore maybe string|number
  //       requiredCodAmountTotal += parseFloat(codAmount)
  //     }
  //   }
  //   return { requiredRemainingAmountTotal, requiredCodAmountTotal }
  // }
  getOrdersToBePaidAmountTotal = () => {
    const { ordersToBePaid } = this.state
    let requiredRemainingAmountTotal = 0
    let requiredCodAmountTotal = 0
    if (this.getOrderCount() > 0) {
      const orderKeyIds = Object.keys(ordersToBePaid)
      for (let i = 0; i < orderKeyIds.length; i++) {
        const orderKeyId = orderKeyIds[i]
        const orderData = ordersToBePaid[orderKeyId]
        // const remainingAmount = orderData.remaining_forecast_amount || 0
        // const codAmount = orderData.cod_amount || 0
        const remainingAmount = orderData.t || 0
        const codAmount = orderData.f || 0
        // @ts-ignore maybe string|number
        requiredRemainingAmountTotal += parseFloat(remainingAmount)
        // @ts-ignore maybe string|number
        requiredCodAmountTotal += parseFloat(codAmount)
      }
    }
    return { requiredRemainingAmountTotal, requiredCodAmountTotal }
  }

  getHeaderTitle = (): string => {
    const { isRefund, orderType } = this.state
    // const { orderType } = this._getParams()
    // const amountTotal = this.getOrdersToBePaidAmountTotal()
    // const isRefund = this._isRefund(this.state.amountTotal)
    // const isRefund = this._isRefund(this.state.requiredRemainingAmountTotal)
    // const { optionChooseAmountWith = OPTION_ORDER_AMOUNT, requiredCodAmountTotal, requiredRemainingAmountTotal } = this.state
    // const isRefund =
    //   optionChooseAmountWith === OPTION_COD_AMOUNT ? this._isRefund(requiredCodAmountTotal) : this._isRefund(requiredRemainingAmountTotal)
    let title = ''
    if (orderType === 1) {
      title = isRefund ? 'แจ้งคืนเงินลูกค้า' : 'บันทึกรับชำระจากลูกค้า'
      this.paymentAccountSelectionTitle = isRefund ? 'เลือกบัญชีฉันที่จ่ายออก' : 'เลือกบัญชีรับชำระ'
    } else if (orderType === 2) {
      title = isRefund ? 'แจ้งคืนเงินตัวแทน' : 'บันทึกรับชำระแทนผู้ซื้อ'
      this.paymentAccountSelectionTitle = isRefund ? 'เลือกบัญชีตัวแทน' : 'เลือกบัญชีรับชำระ'
      isRefund ? (this.showTitleLabel = false) : (this.showTitleLabel = true)
    } else if (orderType === 3) {
      title = isRefund ? 'แจ้งคืนเงินผู้ขายส่ง' : 'แจ้งชำระร้านผู้ขายส่ง'
      this.paymentAccountSelectionTitle = 'เลือกบัญชีผู้ขายส่ง'
    }
    return title
  }

  // onChangeTextInputAmountTotal = (newText: string) => {
  //   this.props.onPaymentChange({
  //     key: 'total_amount',
  //     value: newText,
  //     isCurrency: true,
  //   })
  // }

  // onChangeTextInputAmountTotal = (newTotalAmount: number) => {
  //   this.setState({ paymentTotalAmount: newTotalAmount })
  // }
  onChangeTextInputAmountTotal = (newTotalAmount: string) => {
    this.setState({ paymentTotalAmount: newTotalAmount })
  }

  onChangePaymentPostDate = (newDate: Date) => {
    this.setState({ paymentPostDate: newDate })
  }

  onUserRequestExactAmountTotal = () => {
    // const addAmountTotal = `${this.state.amountTotal}`
    const { requiredRemainingAmountTotal, requiredCodAmountTotal, optionChooseAmountWith = OPTION_ORDER_AMOUNT } = this.state
    const focusAmountTotal = optionChooseAmountWith === OPTION_COD_AMOUNT ? `${requiredCodAmountTotal}` : `${requiredRemainingAmountTotal}`
    // this.props.onPaymentChange({ key: 'total_amount', value: Math.abs(+focusAmountTotal) })
    // this.onChangeTextInputAmountTotal(`${Math.abs(+focusAmountTotal)}`)
    this.onChangeTextInputAmountTotal(`${Math.abs(+focusAmountTotal)}`)
    // this.onChangeTextInputAmountTotal(Math.abs(+focusAmountTotal))
  }

  onChangeSegmentAmountWith = async (newIndex) => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    await setStatePromise(this, { optionChooseAmountWith: newIndex })
    await delay(50)
    this.onUserRequestExactAmountTotal()
    await delay(50)
    this.inProcess = false
  }

  // getBeamLink = async () => {
  //   const { editingPayment, navigation } = this.props
  //   const { beamUrlPayment } = this.state
  //   if (_.isString(beamUrlPayment)) {
  //     p.op.showToast(`คัดลอกลิงก์ Beam สำหรับชำระเรียบร้อยแล้ว\n${beamUrlPayment}`, 'success')
  //     return
  //   }
  //   const selectedStore = util.getSelectedStoreFromRedex()
  //   const ordersToBePaid = editingPayment.get('ordersToBePaid')
  //   const orderIds: string[] = Map.isMap(ordersToBePaid) ? editingPayment.get('ordersToBePaid').keySeq().toArray() : []
  //   const orders = []
  //   if (orderIds.length < 1) {
  //     p.op.showConfirmationOkOnly('', 'ไม่พบรายการออเดอร์ที่ต้องชำระ')
  //     return
  //   }
  //   let sumTotal = 0
  //   ordersToBePaid.map((order, index: number) => {
  //     const orderJS: {
  //       remaining_forecast_amount: string
  //       cod_amount: string
  //     } = order.toJS()
  //     const amountOrder = ordersToBePaid.size === 1 ? +editingPayment.get('total_amount') : +orderJS.remaining_forecast_amount
  //     orders.push({
  //       order_id: _.isString(index) ? +index : index,
  //       amount: amountOrder,
  //     })
  //     sumTotal += amountOrder
  //     // console.log('sumTotal => ', sumTotal)
  //   })
  //   const reqBody = { store_id: selectedStore.get('id'), orders, total_amount: sumTotal }
  //   const apiOptions: IApiOptions = {
  //     showSpinner: true,
  //   }
  //   const res = await api.postV2('payment/beam/create', reqBody, apiOptions)
  //   // console.log('res => ', res)
  //   if (res.ref_url) {
  //     await util.setStatePromise(this, {
  //       beamUrlPayment: res.ref_url,
  //     })
  //     Clipboard.setString(res.ref_url)
  //     p.op.showToast(`คัดลอกลิงก์ Beam สำหรับชำระเรียบร้อยแล้ว\n${res.ref_url}`, 'success')
  //     // navigation.goBack(navigation.state.key)
  //     this._handleAfterSuccessSubmitPayment(null)
  //     this.props.shouldFetchOrderDetail()
  //   }
  // }

  getBeamLink = async () => {
    const { beamUrlPayment, ordersToBePaid = {}, paymentTotalAmount } = this.state

    const { myStoreId } = this._getParams()
    if (_.isString(beamUrlPayment)) {
      p.op.showToast(`คัดลอกลิงก์ Beam สำหรับชำระเรียบร้อยแล้ว\n${beamUrlPayment}`, 'success')
      return
    }
    const orderIds: string[] = _.isObject(ordersToBePaid) ? Object.keys(ordersToBePaid) : []
    if (orderIds.length < 1) {
      p.op.showConfirmationOkOnly('', 'ไม่พบรายการออเดอร์ที่ต้องชำระ')
      return
    }

    let sumTotal = 0
    const orders = []
    orderIds.forEach((orderId, index: number) => {
      const orderJS = ordersToBePaid[orderId]
      // const amountOrder = orderIds.length === 1 ? +paymentTotalAmount : +orderJS.remaining_forecast_amount
      const amountOrder = orderIds.length === 1 ? +paymentTotalAmount : +orderJS.t

      orders.push({
        order_id: _.isString(orderId) ? +orderId : orderId,
        amount: amountOrder,
      })

      sumTotal += amountOrder
      // console.log('sumTotal => ', sumTotal)
    })

    const reqBody = { store_id: myStoreId, orders, total_amount: sumTotal }
    const apiOptions: IApiOptions = {
      showSpinner: true,
    }
    const res = await api.postV2('payment/beam/create', reqBody, apiOptions)
    // console.log('res => ', res)
    if (res.ref_url) {
      await util.setStatePromise(this, {
        beamUrlPayment: res.ref_url,
      })
      Clipboard.setString(res.ref_url)
      p.op.showToast(`คัดลอกลิงก์ Beam สำหรับชำระเรียบร้อยแล้ว\n${res.ref_url}`, 'success')
      // navigation.goBack(navigation.state.key)
      // await this._handleAfterSuccessSubmitPayment(null)
      await this._handleAfterSuccessSubmitPayment()
      // this.props.shouldFetchOrderDetail()
    }
  }

  _handleAfterSuccessSubmitPayment = async (): Promise<void> => {
    const { navigation } = this.props
    const onPaymentSuccess = util.getNavParam(this.props, 'onPaymentSuccess')
    if (_.isFunction(onPaymentSuccess)) {
      onPaymentSuccess()
    }

    const fromPaymentList = util.getNavParam(this.props, 'fromPaymentList', false)
    if (fromPaymentList) {
      // navigation.dispatch(NavActions.pop(2))
      // @ts-ignore
      navigation.pop(2)
    }

    const fromOrderDetail = util.getNavParam(this.props, 'fromOrderDetail', false)
    if (fromOrderDetail) {
      util.navGoBack(this.props)
    }
  }
}
