import { Component } from 'react'

import * as util from 'x/utils/util'

import { ISelectedStoreMap, IXScreenProps } from 'x/index'

interface IFileUpload {
  name: string
  lastModified: number
  webkitRelativePath: string
  size: number
  type: string
  path: string
}

export interface BaseImportProductQTYViewProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
}

export interface BaseImportProductQTYViewState {
  fileUpload: IFileUpload
  disabledDownloadTemplate: boolean
  isLoading: boolean
}

// interface IImportExcelMenuItem {
//   label: string
//   iconName?: string
//   iconFamily?: 'Ionicons' | 'FontAwesome' | 'MaterialIcons' | 'MaterialCommunityIcons' | 'AntDesign' | 'Foundation'
//   iconStyle?: Object
//   nav?: () => void
//   perm?: string | string[]
//   isHeader?: boolean
//   permBitIndex?: number
// }

export default abstract class BaseImportProductQTYView extends Component<BaseImportProductQTYViewProps, BaseImportProductQTYViewState> {
  // abstract async handleOnDownloadFile(url: string, fileName: string): Promise<void>
  inProcess: boolean

  constructor(props) {
    super(props)
    this.state = {
      fileUpload: null,
      disabledDownloadTemplate: false,
      isLoading: false,
    }
    this.inProcess = false
  }

  _goBack = () => {
    util.navGoBack(this.props)
  }

  _setFileUpload = (acceptedFiles: IFileUpload[]) => {
    // console.log(acceptedFiles)
    util.setStatePromise(this, {
      fileUpload: acceptedFiles[0],
    })
  }

  _startLoading = async () => {
    this.inProcess = true
    await util.setStatePromise(this, {
      isLoading: true,
    })
  }

  _stopLoading = async () => {
    this.inProcess = false
    await util.setStatePromise(this, {
      isLoading: false,
    })
  }

  // onPressUpload = async () => {
  //   const { selectedStore } = this.props
  //   const { fileUpload } = this.state
  //   if (this.inProcess) {
  //     return
  //   }
  //   this.inProcess = true
  //   const formData = new FormData()

  //   // Array.from(this.state.fileUpload).forEach(image => {
  //   // // @ts-ignore
  //   //   formData.append('file', image)
  //   // })
  //   // @ts-ignore
  //   formData.append('file', fileUpload)
  //   formData.append('store_id', `${selectedStore.get('id')}`)
  //   // formData.append('store_id', `99999999`)
  //   // console.log(`formData`, formData)
  //   const token = await p.op.storageGet(CONS.AUTHENTICATION_STORAGE_KEY, false)
  //   // console.log(`TOKEN => `, `Bearer ${token}`)
  //   // return
  //   const path = `excel/import/product_qty_update`
  //   const url = getConfig()
  //   axios
  //     .post(`${url.app_goApiRoot}/${path}`, formData, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //         Authorization: `Bearer ${token}`,
  //         // 'Access-Control-Allow-Origin': '*',
  //         // 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
  //       },
  //     })
  //     .then(async res => {
  //       // @ts-ignore res
  //       // console.log('bgjob_uuid => ', res.data.bgjob_uuid)
  //       // console.log(`res => `, res)
  //       if (res.data && res.data.bgjob_uuid) {
  //         await util.alertWithNavToBgJobListView({
  //           title: 'สร้าง "คิวคำสั่งงาน" สำเร็จ',
  //           message:
  //             'คิวคำสั่งงาน สำหรับการ "แก้ไขจำนวนคลัง" ได้ถูกสร้างแล้ว ระบบกำลังดำเนินการอยู่เบื้องหลัง และอาจใช้เวลาซักครู่จนกว่าจะครบทุกออเดอร์',
  //           dispatch: this.props.navigation.dispatch,
  //           selectedStoreId: this.props.selectedStore.get('id'),
  //           onUserConfirm: () => this._goBack(),
  //           initBgJobUUID: res.data.bgjob_uuid,
  //         })
  //         this.inProcess = false
  //       } else {
  //         this.inProcess = false
  //         p.op.showConfirmationOkOnly('', `เกิดข้อผิดพลาด ${res}`)
  //       }
  //     })
  //     .catch(async err => {
  //       // console.log('err => ', JSON.stringify(err))
  //       // console.log('err => ', JSON.stringify(err.response.data))
  //       // console.log(`res 02 => `, err.response.data)
  //       const errorCode = !_.isNil(err.response) && !_.isNil(err.response.data) ? err.response.data.error.error_code : null
  //       if (!_.isNil(errorCode)) {
  //         const textErrorCode = util.getBgTaskErrorText(errorCode)
  //         p.op.showConfirmationOkOnly('', textErrorCode)
  //         this.inProcess = false
  //         return
  //       }
  //       p.op.showConfirmationOkOnly('', `เกิดข้อผิดพลาด ${err}`)
  //       this.inProcess = false
  //     })
  // }
}
