import React, { Component, ReactNode } from 'react'
import _ from 'lodash'
import createHoverMonitor from './createHoverMonitor'
// import { element, func, oneOfType } from 'prop-types'

const hover = createHoverMonitor()

/**
 * Use:
 * <Hoverable>
 *   {(hover) => <View style={hover && styles.hovered} />}
 * </Hoverable>
 *
 * Example: https://imaginary-lycra.glitch.me/
 * Example source: https://glitch.com/edit/#!/imaginary-lycra
 */

type IHoverChildrenFunc = (isHover: boolean) => ReactNode

interface IHoverable {
  onHoverIn?: () => void
  onHoverOut?: () => void
  children?: ReactNode | JSX.Element | IHoverChildrenFunc | any
}

class Hoverable extends Component<IHoverable> {
  static displayName = 'Hoverable'

  // static propTypes = {
  //   children: oneOfType([func, element]).isRequired,
  //   onHoverIn: func,
  //   onHoverOut: func
  // }

  state = { isHovered: false }

  _handleMouseEnter = (e) => {
    if (hover.isEnabled && !this.state.isHovered) {
      const { onHoverIn } = this.props
      if (_.isFunction(onHoverIn)) {
        onHoverIn()
      }
      this.setState(() => ({ isHovered: true }))
    }
  }

  _handleMouseLeave = (e) => {
    if (this.state.isHovered) {
      const { onHoverOut } = this.props
      if (_.isFunction(onHoverOut)) {
        onHoverOut()
      }
      this.setState(() => ({ isHovered: false }))
    }
  }

  render() {
    const {
      children,
      // onHoverIn,
      // onHoverOut,
    } = this.props

    const child = typeof children === 'function' ? children(this.state.isHovered) : children

    return React.cloneElement(React.Children.only(child), {
      onMouseEnter: this._handleMouseEnter,
      onMouseLeave: this._handleMouseLeave,
    })
  }
}

export default Hoverable
