/* eslint-disable no-nested-ternary */
/* eslint-disable indent */
import React from 'react'
import XContent from 'xui/components/XContent'
import XCustomHeader from 'xui/components/XCustomHeader'
import BaseCategoryPropertyView from 'x/modules/store/Category/BaseCategoryPropertyView'
import XContainer from 'xui/components/XContainer'
import _ from 'lodash'
import XText from 'xui/components/XText'
import { TouchableOpacity } from 'react-native'
import BackIcon from 'xui/components/BackIcon'
import p from 'x/config/platform-specific'
import HStack from 'xui/components/HStack'
import Box from 'xui/components/Box'
import XInput from 'xui/components/XInput'
import XCard from 'xui/components/XCard'
import XIcon from 'xui/components/XIcon'
import XButton from 'xui/components/XButton'
import * as util from 'x/utils/util'
import { ICategory } from 'x/types'
import XOverlay from 'xui/components/XOverlay'
import XColorPalette from 'xui/components/XColorPalette'
import VStack from 'xui/components/VStack'
import XIconButton from 'xui/components/XIconButton'
import BaseUICategoryListOverlay from './BaseUICategoryListOverlay'

const MODE = {
  VIEW: 'VIEW',
  CREATE: 'CREATE',
  EDIT: 'EDIT',
}

export default abstract class BaseUICategoryPropertyView extends BaseCategoryPropertyView {
  // abstract goBack: () => void

  _renderHeader = () => {
    const { mode } = this.state
    // let leftText = 'บันทึก'
    let title = util.getNavParam(this.props, 'title', 'หมวดหมู่สินค้า')
    if (_.isNil(title) && mode === MODE.CREATE) {
      title = 'หมวดหมู่สินค้า'
      // leftText = 'สร้าง'
    }
    return <XCustomHeader title={title} renderHeaderLeft={() => this._renderHeaderLeft()} renderHeaderRight={this._renderHeaderRight} />
  }

  _renderHeaderRight = () => {
    const { mode } = this.state
    return _.isNil(mode) || mode === MODE.VIEW ? (
      <TouchableOpacity
        style={{ width: 50, height: 50, justifyContent: 'center', paddingRight: 4 }}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onPress={() => util.setStatePromise(this, { mode: 'EDIT' })}>
        <XText variant='primary'>{p.op.t('edit')}</XText>
      </TouchableOpacity>
    ) : (
      <TouchableOpacity
        style={{ width: 50, height: 50, justifyContent: 'center', paddingRight: 4 }}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onPress={async () => {
          if (mode === 'EDIT') {
            await this._cancleEdit()
          } else if (mode === 'CREATE') {
            this._cancleCreate()
          }
        }}>
        <XText variant='primary'>{p.op.t('cancel')}</XText>
      </TouchableOpacity>
    )
  }

  _renderHeaderLeft = () => {
    const { mode, name } = this.state
    const onPressBtnNotViewMode = async () => {
      if (mode === 'EDIT') {
        await this._submitEditCategory()
      } else if (mode === 'CREATE') {
        if (_.isNil(name) || name === '') {
          await p.op.showConfirmationOkOnly('กรุณาระบุชื่อหมวดหมู่', '')
          return
        }
        await this._createCategory()
      }
    }
    return _.isNil(mode) || mode === MODE.VIEW ? (
      <TouchableOpacity style={{ width: 50, height: 50, justifyContent: 'center', paddingLeft: 10 }} onPress={() => this._goBackCallBack()}>
        <BackIcon />
      </TouchableOpacity>
    ) : (
      <TouchableOpacity
        style={{ width: 50, height: 50, justifyContent: 'center', marginLeft: 8 }}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onPress={() => onPressBtnNotViewMode()}>
        <XText variant='primary'>{p.op.t('save')}</XText>
      </TouchableOpacity>
    )
  }

  _goBack = () => {
    util.navGoBack(this.props)
  }

  _renderInputName = () => {
    const { name } = this.state
    return (
      <Box justifyContent='center' ml={p.op.isWeb() ? '2' : '0'} flex={1}>
        <XInput flex={1} placeholder='ระบุชื่อหมวดหมู่' onChangeText={this._onChangeCategory} value={name} maxLength={240} />
      </Box>
    )
  }

  _renderCategoryName = () => {
    const { name, mode } = this.state
    return (
      <HStack w='full' alignItems='center' space='2'>
        <XText w='40px' variant='inactive'>
          ชื่อ
        </XText>
        {mode === MODE.VIEW ? <XText variant='active'>{name}</XText> : this._renderInputName()}
      </HStack>
    )
  }

  _renderXColor = () => {
    const { color, showColorPinker } = this.state
    const seletedColor = color
    // width: 315
    // height: 400
    return (
      <XOverlay contentStyle={{ width: 134 }} visible={showColorPinker} onRequestClose={this._closeColorPinker}>
        <VStack w='full' alignItems='center' justifyContent='center'>
          <XColorPalette
            value={seletedColor}
            onChange={(Color: string) => this._onChangeColor(Color)}
            colors={[
              '#b79ec7',
              '#7b539d',
              '#593482',

              '#57bfc1',
              '#219aa0',
              '#0e5c68',

              '#34b3e4',
              '#008cc9',
              '#0b4971',

              '#92c749',
              '#4c792d',
              '#3f652d',

              '#f0c23b',
              '#d3a02e',
              '#856a1d',

              '#ec9f48',
              '#d1711b',
              '#933304',

              '#f289b7',
              '#dc4b89',
              '#951343',

              '#f38a78',
              '#dd5143',
              '#98041b',
            ]}
          />
        </VStack>
        <HStack w='full' p='2' pt='3'>
          <XButton w='full' onPress={() => this._closeColorPinker()}>
            ตกลง
          </XButton>
        </HStack>
      </XOverlay>
    )
  }

  _renderCategoryColor = () => {
    const { mode, color } = this.state
    return (
      <HStack alignItems='center' space='2'>
        <XText w='40px' variant='inactive'>
          สี
        </XText>
        <XIcon name='circle' family='FontAwesome' style={{ color }} />
        {mode === MODE.VIEW ? null : (
          <XButton
            // h='10'
            // _android={{ pb: '2' }}
            // _ios={{ pb: '2' }}
            w='98px'
            variant='outline'
            _icon={{ size: 'xs' }}
            leftIcon={<XIcon name='exchange' family='FontAwesome' variant='primary' />}
            onPress={() => this._openColorPinker()}>
            เปลี่ยน
          </XButton>
        )}
      </HStack>
    )
  }

  _renderCategoryListOverlay = () => {
    const { allCategory, visibleCategoryList, category } = this.state
    const { selectedStore, fetchCategory } = this.props
    return (
      <BaseUICategoryListOverlay
        fetchCategory={fetchCategory}
        // eslint-disable-next-line @typescript-eslint/no-shadow
        submit={(category: ICategory) => this._submitChangeCategory(category)}
        store_id={selectedStore.get(`id`)}
        mode='ON_CHANGE_GROUP'
        categoetProps={allCategory}
        visible={visibleCategoryList}
        onRequestClose={() => this._closeCategoryList()}
        selectedCategoryProps={category}
      />
    )
  }

  _renderBtnAction = () => {
    const { mode } = this.state
    if (mode === MODE.VIEW) {
      return (
        <HStack w='full'>
          <XButton
            w='32'
            variant='outline'
            leftIcon={<XIcon name='swap-calls' family='MaterialIcons' variant='primary' />}
            onPress={() => this._openCategoryList()}>
            ย้ายหมวดหมู่
          </XButton>
        </HStack>
      )
    }
    if (mode === MODE.EDIT) {
      return (
        <HStack w='full' justifyContent='flex-end'>
          <XIconButton
            name='delete'
            family='AntDesign'
            colorScheme='danger'
            variant='outline'
            onPress={() => this._confirmedDeleteStepOne()}
          />
        </HStack>
      )
    }
    return null
  }

  _renderContent = () => (
    <XCard>
      <XCard.Body>
        <VStack w='full' space='2'>
          {this._renderCategoryName()}
          {this._renderCategoryColor()}
          {this._renderBtnAction()}
        </VStack>
      </XCard.Body>
    </XCard>
  )

  render() {
    const { mode } = this.state
    if (_.isNil(mode)) {
      return null
    }
    return (
      <XContainer>
        {this._renderHeader()}
        <XContent>
          {this._renderCategoryListOverlay()}
          {this._renderXColor()}
          <VStack w='full' p='2'>
            {this._renderContent()}
          </VStack>
        </XContent>
        {/* {loading} */}
      </XContainer>
    )
  }
}
