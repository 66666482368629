import React from 'react'

import _ from 'lodash'
// import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'
import XCustomHeader from 'xui/components/XCustomHeader'
// import { Input } from 'react-native-elements'
import { ActionApiParams, IMKPChannelDetail, ISelectedStoreMap, IXScreenProps, IMkpMappingChannelListViewPickerNavParams } from 'x/index'
import HelpButton from 'xui/components/HelpButton'
import * as NavActions from 'x/utils/navigation'
import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import XText from 'xui/components/XText'
import XCard from 'xui/components/XCard'
import MkpLogo from 'xui/components/MkpLogo'
import XHelpModal from 'xui/components/XHelpModal'
import FAQ from 'x/config/FAQ'

import { connect } from 'react-redux'
// import { getState, getDispatch } from 'x/modules/order/BaseOrderViewConnect'
import { bindActionCreators } from 'redux'
import * as StoreActions from 'x/modules//store/StoreState'
import { getSelectedStore, getMySubscription, getLoadingInProgress } from 'x/redux/selectors'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import ForwardIcon from 'xui/components/ForwardIcon'
import { isImmutable } from 'immutable'

interface IMkpMappingChannelListViewPickerProps extends IXScreenProps<IMkpMappingChannelListViewPickerNavParams> {
  selectedStore: ISelectedStoreMap
  // updateNewChannelList: (newChannelList: IMKPChannelDetail[]) => void
  // getShopeeAuthUrl: (params: ActionApiParams) => void
  getChannels: (params: ActionApiParams) => void
  // subscription: any
  // loadingInProgress: ILoadingInProgressMap

  // getMkpReAuthUrl: (params: ActionApiParams) => void
}

interface IMkpMappingChannelListViewPickerState {
  channelList: IMKPChannelDetail[]
}

class MkpMappingChannelListViewPicker extends React.Component<
  IMkpMappingChannelListViewPickerProps,
  IMkpMappingChannelListViewPickerState
> {
  static displayName = 'MkpMappingChannelListViewPicker'

  constructor(props) {
    super(props)

    this.state = {
      channelList: [],
    }
  }

  async componentDidMount() {
    await this._initChannelList()
  }

  _initChannelList = async () => {
    const { selectedStore } = this.props
    const channelListMap = selectedStore.get('channels')
    const channelList =
      !_.isNil(channelListMap) && isImmutable(channelListMap) ? channelListMap.toJS() : !_.isNil(channelListMap) ? channelListMap : []
    await util.setStatePromise(this, { channelList })
  }

  _renderHeaderRight = () => (
    <HStack>
      <XHelpModal
        key='วิธีเชื่อมช่องทางการขาย'
        headerTitle='วิธีเชื่อมช่องทางการขาย'
        FAQ={FAQ.MKP_CONNECT_FAQ}
        initiateOpenIndex={[0, 1]}
        renderButton={(onPressOpenOverlay: () => void) => this._renderAgreement(onPressOpenOverlay)}
      />
    </HStack>
  )

  _renderAgreement = (onPressOpenOverlay: () => void) => <HelpButton onPress={() => onPressOpenOverlay()} />

  goBack = () => {
    util.navGoBack(this.props)
  }

  renderCustomHeader = () => (
    <XCustomHeader
      title='เลือกช่องทางการขายที่จะผูกสินค้า'
      headerLeftProps={{ backIcon: true, onPressItem: this.goBack }}
      // headerRight={this._renderHeaderRight()}
    />
  )

  _onMappingSuccess = async () => {
    // const { navigation, mkpProductContext } = this.props
    const onMappingSuccess = util.getNavParam(this.props, 'onMappingSuccess')

    if (onMappingSuccess) {
      await onMappingSuccess()
    }

    // mkpProductContext.destroy(mkpProductContext)

    util.navGoBack(this.props)
  }

  _navToProductListMkpView = (channel) => {
    const { navigation, selectedStore } = this.props
    const selectedProductVariant = util.getNavParam(this.props, 'selectedProductVariant')
    const renderNavReference = util.getNavParam(this.props, 'renderNavReference')

    navigation.dispatch(
      NavActions.navToProductListViewXSellyToMkpMapping({
        store_id: selectedStore.get('id'),
        // mkp_channel_id: channel.id,
        mkp_ch_id: channel.id,
        mkp_id: channel.mkp_id,

        selectedProductVariant,
        renderNavReference,
        onMappingSuccess: this._onMappingSuccess,
      })
    )
  }

  _renderChannelList = () => {
    const { channelList } = this.state
    if (_.isNil(channelList) || channelList.length < 1) {
      return null
    }

    return (
      <VStack w='full' space='2'>
        {channelList.map(this._renderChannelListItem)}
      </VStack>
    )
  }

  _renderChannelListItem = (channel: IMKPChannelDetail, index: number) => {
    const { navigation } = this.props
    return (
      <XCard w='full' key={index} onPress={() => this._navToProductListMkpView(channel)}>
        <HStack w='full' p='2' minH='12' alignItems='center' space='2'>
          <MkpLogo mkpId={channel.mkp_id} width={30} height={30} />

          <XText flex={1} fontSize='md' bold variant='active' numberOfLines={2}>
            {channel.name}
          </XText>

          <ForwardIcon />
        </HStack>
      </XCard>
    )
  }

  render() {
    const renderNavReference = util.getNavParam(this.props, 'renderNavReference')

    return (
      <XContainer>
        {_.isFunction(renderNavReference) ? renderNavReference() : null}
        {this.renderCustomHeader()}

        <XContent p='2'>
          {/* Mkp List  */}
          {this._renderChannelList()}
        </XContent>
      </XContainer>
    )
  }
}

export default connect(
  (state) => ({
    // ...getState(state),
    selectedStore: getSelectedStore(state),
    subscription: getMySubscription(state),
    loadingInProgress: getLoadingInProgress(state),
  }),
  (dispatch) => ({
    updateNewChannelList: bindActionCreators(StoreActions.updateNewChannelList, dispatch),
    getChannels: bindActionCreators(StoreActions.getChannels, dispatch),
    getShopeeAuthUrl: bindActionCreators(StoreActions.shopeeAuthUrl, dispatch),
    getMkpReAuthUrl: bindActionCreators(StoreActions.mkpReAuthUrl, dispatch),
    deleteMkpChannel: bindActionCreators(StoreActions.mkpReAuthUrl, dispatch),
    // ...getDispatch(dispatch),
  })
)(MkpMappingChannelListViewPicker)
