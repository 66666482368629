// import { createAppContainer } from 'react-navigation'
// import { createBrowserApp } from '@react-navigation/web'
// import MainSwitchNavigator from './MainSwitchNavigator'

// const AppContainer = createAppContainer(MainSwitchNavigator)
// const AppContainer = createBrowserApp(MainSwitchNavigator)

// export default AppContainer

import React from 'react'
import _ from 'lodash'
import { Map } from 'immutable'
import { connect } from 'react-redux'
import { NavigationContainer, StackActions } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'

import { IXScreenProps } from 'x/types'
import { getSelectedStore } from 'x/redux/selectors'

import VStack from 'xui/components/VStack'
import XButton from 'xui/components/XButton'
import XText from 'xui/components/XText'

// import { createStackExpandNavigator } from './StackExpandNavigator'

// import StoreMyViewContainer from '../store/StoreMyViewContainer'
import renderAdapterScreens from 'xui/modules/navigator/renderAdapterScreens'
import LandingViewContainer from '../landing/LandingViewContainer'
import LoginViewContainer from '../login/LoginViewContainer'

// import AuthStack from './AuthStack'
import Sx from './StackExpandNavigator'
import StoreMyViewContainer from '../store/StoreMyViewContainer'
import { AUTH_SCREENS } from './auth-screens'
import { GUEST_SCREENS } from './guest-screens'
import linking from './NavigatorLinking'

// import CommonContentStackNavigator from './CommonContentStackNavigator'
// import StoreOrdersDonutListViewContainer from '../order/StoreOrdersDonutListViewContainer'

const Stack = createStackNavigator()

// const linkPrefix = Linking.createURL('/')
// const linkPrefix = Linking.getInitialURL()

const HomeScreen = (props: IXScreenProps) => (
  <VStack p='2' space='2'>
    <XText>Hello World</XText>
    <XButton
      onPress={(e) => {
        // props.navigation.push('Test')
        // props.navigation.dispatch(CommonActions.navigate({ name: 'Test' }))
        props.navigation.dispatch(StackActions.push('Test', { rand: Math.random() }))
        // props.navigation.dispatch(CommonActions.reset({ index: 0, routes: [{ name: 'Test' }] }))
        console.log(props.navigation.getState())
      }}>
      <XText>Nav to Test</XText>
    </XButton>
    <XButton onPress={() => console.log(props)}>XButton</XButton>
    <XButton variant='outline' onPress={() => console.log(props.navigation.getState())}>
      getState
    </XButton>
  </VStack>
)
const TestScreen = (props) => (
  <VStack p='2' space='2'>
    <XText>Test World</XText>
    <XText>{`props.route => ${JSON.stringify(props.route)}`}</XText>
    <XButton onPress={() => console.log(props)}>XButton</XButton>
    <XButton variant='outline' onPress={() => console.log(props.navigation.getState())}>
      getState
    </XButton>
    <XButton onPress={() => console.log(props.navigation.goBack())}>GoBack</XButton>
    <XButton onPress={() => console.log(props.navigation.reset({ index: 0, routes: [{ name: 'Home' }] }))}>Reset to Home</XButton>
  </VStack>
)
const FirstScreen = (props) => (
  <VStack p='2' space='2'>
    <XText>First World</XText>
  </VStack>
)

function NotFoundScreen({ route }) {
  // if (route.path) {
  //   return <WebView source={{ uri: `https://mywebsite.com/${route.path}` }} />;
  // }

  return <XText>This screen doesn't exist!</XText>
}

function AuthStack() {
  return (
    <StoreMyViewContainer>
      <Sx.Navigator id='LoggedIn' initialRouteName='StoreOrdersDonutListView'>
        {renderAdapterScreens(Sx, AUTH_SCREENS)}
        <Sx.Screen name='Home' component={HomeScreen} />
        <Sx.Screen name='Test' component={TestScreen} />
        <Sx.Screen name='NotFound' component={NotFoundScreen} />
      </Sx.Navigator>
    </StoreMyViewContainer>
  )
}

function AppNavigator({ selectedStore }) {
  // const [stateToken, setStateToken] = React.useState(null)
  // // const [isLoggedIn, setIsLoggedIn] = React.useState(false)
  // // const _login = () => {
  // //   setIsLoggedIn(true)
  // // }

  // const initToken = async () => {
  //   const token = await getAuthenticationToken()
  //   if (token && token !== '') {
  //     setStateToken(stateToken)
  //   }
  // }

  // React.useEffect(() => {
  //   setTimeout(initToken, 200)
  // }, [])

  // const isLoggedIn = (_.isString(stateToken) && stateToken !== '') || (selectedStore && selectedStore.get('id'))
  const isLoggedIn = Map.isMap(selectedStore) && _.isNumber(selectedStore.get('id')) && selectedStore.get('id') > 0

  return (
    <NavigationContainer
      documentTitle={{
        formatter: (options, route) =>
          // `${options?.title ?? route?.name} - My Cool App`,
          `XSelly`,
      }}
      linking={linking}>
      {/* <XButton onPress={_login}>Sign in</XButton> */}
      {/* <Box bg='red.100' flex={1} p='3'> */}
      {/* <XText>isLoggedIn = {JSON.stringify(isLoggedIn)}</XText> */}
      {/* <XText numberOfLines={2}>selectedStore = {JSON.stringify(selectedStore)}</XText> */}
      {/* <XText>stateToken = {JSON.stringify(stateToken)}</XText> */}
      <Stack.Navigator
        id='Root'
        // @ts-ignore
        screenOptions={{
          // animationEnabled: true,
          // animationDuration: 500,
          animationTypeForReplace: 'push',
          header: () => null,
        }}>
        {/* path: 'landing' */}
        <Stack.Screen name='LandingView' component={LandingViewContainer} />
        <Stack.Screen name='LoginView' component={LoginViewContainer} />
        {/* อันนี้ไม่แน่ใจว่าเงื่อนไขมีอะไรไม่ถูก ทำให้เข้า onboard ไม่ได้ */}
        {/* {isLoggedIn ? <Stack.Screen name='LoggedInStack' component={AuthStack} /> : renderAdapterScreens(Stack, GUEST_SCREENS)} */}
        <Stack.Screen name='LoggedInStack' component={AuthStack} />
        {renderAdapterScreens(Stack, GUEST_SCREENS)}
        <Stack.Screen name='NotFound' component={NotFoundScreen} />
      </Stack.Navigator>
      {/* </Box> */}
    </NavigationContainer>
  )
}

// export default AppNavigator

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
  }),
  (dispatch) => ({
    // fetchInitializeData: bindActionCreators(StoreActions.fetchInitializeData, dispatch),
    dispatch,
  })
)(AppNavigator)
