// Base from: node_modules\native-base\src\theme\base\borders.ts
const borderWidths = {
  '0': 0,
  // '1': '1px',
  // '2': '2px',
  // '4': '4px',
  // '8': '8px',
  '1': 1,
  '2': 2,
  '4': 4,
  '8': 8,
}

export type IBorderWidth = keyof typeof borderWidths

export default borderWidths
