import { connect } from 'react-redux'
import { getState, getDispatch } from 'x/modules/order/BaseOrderViewConnect'
import * as bpActions from 'x/modules/backparams/BackParamsState'
import { bindActionCreators } from 'redux'
import * as OrderState from 'x/modules/order/OrderState'
import OrderView from './OrderView'

export default connect(
  (state) => {
    return {
      ...getState(state),
      // pagePicker Selector
      pp_shippingTypeIndex: state.getIn(['bp', 'pagePicker', 'shippingType', 'index']),
    }
  },
  (dispatch) => {
    return {
      ...getDispatch(dispatch),
      pickerClear: bindActionCreators(bpActions.pickerClear, dispatch),
      pickerSelect: bindActionCreators(bpActions.pickerSelect, dispatch),
      onOrderDateDeliveryChange: bindActionCreators(OrderState.onOrderDateDeliveryChange, dispatch),
      onOrderDateTimeAutoCancelOrderChange: bindActionCreators(OrderState.onOrderDateTimeAutoCancelOrderChange, dispatch),
    }
  }
)(OrderView)
