import React from 'react'
import _ from 'lodash'
import { Map } from 'immutable'
import moment from 'moment'
import { IXCheckboxListOption, IXScreenProps } from 'x/index'
import CONS from 'x/config/constants'
import * as util from '../../utils/util'
import p from '../../config/platform-specific'

export interface BaseReportStockViewProps extends IXScreenProps {
  selectedStore: Map<string, any>
  subscription: any
}

export interface BaseReportStockViewState {
  isLoading: boolean
  loadingWidth: number
  loadingHeight: number
  checkboxListOption: IXCheckboxListOption[]
  checkboxReportStyleListOption: IXCheckboxListOption[]
}

// export interface IReportStockCheckboxListOption {
//   label: string
//   isChecker: boolean
//   value?: any
// }

const CHECKBOX_LIST_OPTION_KEY = {
  SKU: 'SKU',
  UPC: 'UPC',
  DESC: 'DESC',
  PRICES: 'PRICES',
  CATEGORY: 'CATEGORY',
}

export default abstract class BaseReportStockView extends React.Component<BaseReportStockViewProps, BaseReportStockViewState> {
  abstract _handleOnDownloadFile(url: string, fileName: string): Promise<void>

  protected constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      loadingHeight: 0,
      loadingWidth: 0,
      checkboxListOption: [],
      checkboxReportStyleListOption: [],
    }
  }

  async componentDidMount() {
    await this._getCheckboxListOptionFromLatest()
    await this._getCheckboxReportStyleListOptionFromLatest()
  }

  _getCheckboxReportStyleListOptionFromLatest = async () => {
    let STORAGE = {
      REPORT_STYLE: false,
    }
    await p.op.storageGet(CONS.STORAGE_KEYS.REPORT_STOCK_REPORT_STYLE).then((val: any) => {
      if (!_.isNil(val)) {
        STORAGE = {
          REPORT_STYLE: _.isNil(val.REPORT_STYLE) ? false : val.REPORT_STYLE,
        }
      }
    })
    const DEFAULT_CHECKBOX_REPORT_STYLE_LIST_OPTION: IXCheckboxListOption[] = [
      {
        label: 'รวม column ชื่อสินค้า และ ชื่อตัวเลือกสินค้า ',
        isChecker: STORAGE.REPORT_STYLE,
        value: 'REPORT_STYLE',
      },
    ]
    await util.setStatePromise(this, {
      checkboxReportStyleListOption: DEFAULT_CHECKBOX_REPORT_STYLE_LIST_OPTION,
    })
  }

  _getCheckboxListOptionFromLatest = async () => {
    // TODO :: อยากเพิ่มตัวเลือกเพอ่มทำยังไง => ให้ค้นหาคำว่า ADD_STORAGE แล้วเพิ่มข้อมูลตามลำดับ
    // ADD_STORAGE 01 => เพิ่มค่าเริ่มต้นใน let STORAGE
    let STORAGE = {
      SKU: false,
      UPC: false,
      DESC: false,
      PRICES: false,
      CATEGORY: false,
    }
    await p.op.storageGet(CONS.STORAGE_KEYS.REPORT_STOCK).then((val: any) => {
      // console.log('val => ', val)
      if (!_.isNil(val)) {
        // ADD_STORAGE 02 => เพิ่มค่าในนี้ด้วย เพื่อเอามาจากที่จำครั้งล่าสุดที่ user เคยใช้
        STORAGE = {
          SKU: _.isNil(val.SKU) ? false : val.SKU,
          UPC: _.isNil(val.UPC) ? false : val.UPC,
          DESC: _.isNil(val.DESC) ? false : val.DESC,
          PRICES: _.isNil(val.PRICES) ? false : val.PRICES,
          CATEGORY: _.isNil(val.CATEGORY) ? false : val.CATEGORY,
        }
      }
    })
    // ADD_STORAGE 03 => เพิ่ม DATA ในนี้เพื่อ UI แสดงผล *อย่าลืมเพิ่ม key ใน value ที่ CHECKBOX_LIST_OPTION_KEY
    const DEFAULT_CHECKBOX_LIST_OPTION: IXCheckboxListOption[] = [
      {
        label: 'รหัสสินค้า SKU',
        isChecker: STORAGE.SKU,
        value: CHECKBOX_LIST_OPTION_KEY.SKU,
      },
      {
        label: 'รหัสสินค้า UPC',
        isChecker: STORAGE.UPC,
        value: CHECKBOX_LIST_OPTION_KEY.UPC,
      },
      {
        label: 'คำอธิบายสินค้า',
        isChecker: STORAGE.DESC,
        value: CHECKBOX_LIST_OPTION_KEY.DESC,
      },
      {
        label: 'ราคา',
        isChecker: STORAGE.PRICES,
        value: CHECKBOX_LIST_OPTION_KEY.PRICES,
      },
      {
        label: 'หมวดหมู่สินค้า',
        isChecker: STORAGE.CATEGORY,
        value: CHECKBOX_LIST_OPTION_KEY.CATEGORY,
      },
    ]
    await util.setStatePromise(this, {
      checkboxListOption: DEFAULT_CHECKBOX_LIST_OPTION,
    })
  }

  onChangeCheckboxListOption = async (selectedOption: IXCheckboxListOption[], selectedIndex: number) => {
    // console.log('selectedOption => ', selectedOption)
    // console.log('selectedIndex => ', selectedIndex)
    await util.setStatePromise(this, {
      checkboxListOption: selectedOption,
    })
  }

  onChangeCheckboxReportStyleListOption = async (selectedOption: IXCheckboxListOption[], selectedIndex: number) => {
    // console.log('selectedOption => ', selectedOption)
    // console.log('selectedIndex => ', selectedIndex)
    await util.setStatePromise(this, {
      checkboxReportStyleListOption: selectedOption,
    })
  }

  _showConfirmationDownloadReport = async () => {
    const { selectedStore, subscription } = this.props
    const { checkboxListOption, checkboxReportStyleListOption } = this.state

    // ถ้าเป็น IOS และเข้าเงื่อนไขก็ปล่อยผ่าน // https://app.clickup.com/t/86cvy21qt
    const isNoobIos = util.isIosNoobCheckByPass()
    // if (isNoobIos) {
    //   p.op.showConfirmationOkOnly('', p.op.t('Subscription.warning.iosNoobCheckMsg'))
    //   return
    // }
    // ถ้าเป็น IOS และเข้าเงื่อนไขก็ปล่อยผ่าน // https://app.clickup.com/t/86cvy21qt
    if (!isNoobIos && util.isPackFreeAndSelectedStoreOwner()) {
      p.op.alert(p.op.t('Subscription.warning.insufficientTypeTitle'), p.op.t('Subscription.warning.insufficientTypeMsg'))
      // this.inProcess = false
      // await util.setStatePromise(this, { downloadingReport: false })
      // return
    }

    // TODO: Check for perm on BaseReportProductDailySales line 335?
    // const isHlper = util.isHelper()
    // // if (!isHlper && util.isStoreOwner() && subscription.get('type') === CONS.SUBSCRIPTION_PACKAGE.FREE) {
    // if (!isHlper && util.isStoreOwner()) {
    //   p.op.alert(p.op.t('Subscription.warning.insufficientTypeTitle'), p.op.t('Subscription.warning.insufficientTypeMsg'))
    //   return
    // }

    // ADD_STORAGE 04 => เพิ่มค่าใหม่ใน STORAGE ตรงนี้ด้วย
    const STORAGE = {
      SKU: false,
      UPC: false,
      DESC: false,
      PRICES: false,
      CATEGORY: false,
    }
    // ADD_STORAGE 05 => เพิ่ม else if ค่าใหม่ด้วย เพื่อจำข้อมูลล่าสุดเมื่อดาวโหลด
    checkboxListOption.map((val: IXCheckboxListOption) => {
      // console.log('val 01 => ', val)
      if (val.value === CHECKBOX_LIST_OPTION_KEY.SKU) {
        STORAGE.SKU = val.isChecker
      } else if (val.value === CHECKBOX_LIST_OPTION_KEY.UPC) {
        STORAGE.UPC = val.isChecker
      } else if (val.value === CHECKBOX_LIST_OPTION_KEY.DESC) {
        STORAGE.DESC = val.isChecker
      } else if (val.value === CHECKBOX_LIST_OPTION_KEY.PRICES) {
        STORAGE.PRICES = val.isChecker
      } else if (val.value === CHECKBOX_LIST_OPTION_KEY.CATEGORY) {
        STORAGE.CATEGORY = val.isChecker
      }
    })

    const newCheckboxReportStyleListOption = {
      REPORT_STYLE: false,
    }
    checkboxReportStyleListOption.map((val: IXCheckboxListOption) => {
      // console.log('val => ', val)
      if (val.value === 'REPORT_STYLE') {
        newCheckboxReportStyleListOption.REPORT_STYLE = val.isChecker
      }
    })

    await p.op.storageSet(CONS.STORAGE_KEYS.REPORT_STOCK, STORAGE)
    await p.op.storageSet(CONS.STORAGE_KEYS.REPORT_STOCK_REPORT_STYLE, newCheckboxReportStyleListOption)
    const store_id = selectedStore.get(`id`)
    const startDate = moment().format('DD-MM-YYYY_hh-mm')
    // ADD_STORAGE 06 => เพิ่มของใหม่เข้าไปใน URL ด้วย
    const url = util.getReportStockExcelUrl({
      store_id,
      sku: STORAGE.SKU,
      upc: STORAGE.UPC,
      desc: STORAGE.DESC,
      prices: STORAGE.PRICES,
      category: STORAGE.CATEGORY,
      combineProductVariantName: newCheckboxReportStyleListOption.REPORT_STYLE,
    })
    // console.log('url => ', url)
    const fileName = `Stock_${startDate}.xlsx`
    this._handleOnDownloadFile(url, fileName)
    // p.op.showConfirmation(
    //   `ดาวน์โหลด`,
    //   `คุณต้องการดาวน์โหลดรายงานคงคลังใช่หรือไม่`,
    //   () => this._handleOnDownloadFile(url, fileName),
    //   () => {},
    //   `ตกลง`,
    //   `ยกเลิก`
    // )
  }
}
