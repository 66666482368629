import React from 'react'
import { HStack } from 'native-base'
import { TouchableOpacity, View } from 'react-native'
import * as util from 'x/utils/util'
import _ from 'lodash'

import { COLORS, STYLES } from 'x/config/styles'
// import ReportProductDailySales from '../report/ReportProductDailySales'
// import ReportPayment from '../report/ReportPayment'
// import ReportHelperSales from '../report/ReportHelperSales'
import XIcon from 'xui/components/XIcon'
import BaseImportAddProductWarehouseBySKU from 'x/modules/importExcel/BaseImportAddProductWarehouseBySKU'
import XCustomHeader from 'xui/components/XCustomHeader'

import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import XCard from 'xui/components/XCard'
import Box from 'xui/components/Box'
import XText from 'xui/components/XText'
import XButton from 'xui/components/XButton'
import XSpinner from 'xui/components/XSpinner'
import XFooterImportExcel from 'xui/components/XFooterImportExcel'

export default abstract class BaseUIImportAddProductWarehouseBySKU extends BaseImportAddProductWarehouseBySKU {
  abstract _handleOnDownloadFile(url: string, fileName: string): Promise<void>

  abstract _renderDropzone(): JSX.Element

  abstract onPressUpload(): void

  renderCustomHeader = () => {
    // const { navigation } = this.props
    // const { mode } = this.state
    // const isEditMode = mode === EDIT
    const leftBtn = { backIcon: true, onPressItem: () => this._goBack() }
    // if (isEditMode) {
    //   // @ts-ignore
    //   leftBtn = { label: `บันทึก`, onPressItem: () => util.setStatePromise(this, { mode: VIEW }) }
    // }
    return (
      <XCustomHeader
        title='เพิ่มจำนวนคลังตาม SKU ที่ระบุเอง'
        headerLeftProps={leftBtn}
        // headerRightProps={{ label: `แก้ไข`, onPressItem: () => util.setStatePromise(this, { mode: EDIT }) }}
      />
    )
  }

  _delayDownloadTemplate = async () => {
    util.setStatePromise(this, {
      disabledDownloadTemplate: true,
    })
    await util.delay(5000)
    util.setStatePromise(this, {
      disabledDownloadTemplate: false,
    })
  }

  _downloadTemplate = async () => {
    this._delayDownloadTemplate()
    const storeId = this.props.selectedStore.get('id')
    const url = util.getProductStockQtyEditTemplate({
      store_id: storeId,
      o: 'a',
    })
    // console.log('url => ', url)
    const now = util.getTimeFormatYYYYMMDDhhmmss()
    const fileName = `mass_product_stock_qty_edit_template_plus_${storeId}_${now}.xlsx`
    await this._handleOnDownloadFile(url, fileName)
    // const res = await api.getV2(api.GET_EXCEL_PRODUCT_IMPORT_TEMPLATE, apiOptions)
    // console.log('res => ', res)
    // Linking.openURL(linkDownload)
  }

  _renderDownloadTemplateView = () => {
    const { disabledDownloadTemplate } = this.state
    let textBtnColor = COLORS.TEXT_ACTIVE
    let borderBtnColor = COLORS.APP_MAIN
    if (disabledDownloadTemplate) {
      textBtnColor = COLORS.TEXT_INACTIVE
      borderBtnColor = COLORS.TEXT_INACTIVE
    }
    return (
      <Box
        style={{
          width: '100%',
          justifyContent: 'center',
        }}>
        <XText variant='active'>ใช้เพิ่มจำนวนคลังสำหรับสินค้าที่มี SKU ตรงตามที่ระบุใน Excel</XText>
        <XText variant='active' bold>
          ก่อนเริ่ม
        </XText>

        <XText variant='active'>สินค้าทุกรายการที่คุณต้องการแก้ไขผ่าน Excel นี้มี SKU แล้ว</XText>
        <XText variant='active' bold>
          ขั้นตอน
        </XText>
        <XText variant='active'>1) กดปุ่ม “ดาวน์โหลด Template Excel เพิ่มจำนวนคลังตาม SKU”</XText>
        <Box h='2' />
        <TouchableOpacity
          disabled={disabledDownloadTemplate}
          onPress={() => this._downloadTemplate()}
          style={{
            width: 250,
            flexDirection: 'row',
            height: 40,
            borderColor: borderBtnColor,
            borderRadius: 7,
            borderWidth: 0.8,
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf: 'center',
          }}>
          <XIcon name='exclefile1' family='AntDesign' color={borderBtnColor} />
          <Box w='1' />
          <XIcon
            name='plus-circle'
            family='FontAwesome'
            style={{ flex: 0, minWidth: 25, width: 25, color: '#5CB85C', fontSize: STYLES.FONT_ICON_NORMAL }}
          />
          <XText style={{ fontSize: STYLES.FONT_SIZE_LARGER, color: textBtnColor }}>
            {'ดาวน์โหลด Template Excel\nเพิ่มจำนวนคลังตาม SKU'}
          </XText>
        </TouchableOpacity>
        <Box h='2' />
        <XText variant='active'>2) เปิดไฟล์ Excel เพื่อแก้ไข</XText>
        <XText variant='active'>
          3) ในแต่ละแถวระบุ SKU และ จำนวนคลังที่เพิ่มขึ้น ของสินค้าที่ต้องการ เช่น ถ้าเดิมมีคลังอยู่ 10 และมีเพิ่มมา 3 (รวมเป็น 13) ให้ระบุ
          เลข 3 ในคอลัมน์ที่ 2
        </XText>
        <XText variant='active'>4) ทำข้อ 3. ไปเรื่อยๆจนครบทุก SKU ที่ต้องการแก้ไข และบันทึกไฟล์</XText>
        <XText variant='active'>5) เลือก ไฟล์ Excel ที่แก้ไขแล้ว และกดปุ่ม "อัพโหลด"</XText>
      </Box>
    )
  }

  _renderBtnUpload = () => {
    const { fileUpload } = this.state
    const btnBGColor = _.isNil(fileUpload) ? COLORS.BG_LIGHT_GREY : COLORS.APP_MAIN
    const btnBorderColor = _.isNil(fileUpload) ? COLORS.TEXT_INACTIVE : COLORS.APP_MAIN
    const textBtnColor = _.isNil(fileUpload) ? COLORS.TEXT_INACTIVE : COLORS.WHITE
    return (
      <HStack flex={1} alignItems='center' justifyContent='center' mt='8' w='full'>
        <XButton
          disabled={_.isNil(fileUpload)}
          style={{
            flexDirection: 'row',
            borderColor: btnBorderColor,
            backgroundColor: btnBGColor,
            borderWidth: 1,
            marginRight: 4,
            marginLeft: 4,
            borderRadius: 7,
          }}
          onPress={() => this.onPressUpload()}>
          <XIcon
            name='upload'
            family='AntDesign'
            style={{ flex: 0, minWidth: 25, width: 25, color: textBtnColor, fontSize: STYLES.FONT_ICON_SMALLER, fontWeight: 'bold' }}
          />
          <XText color={textBtnColor} bold>
            อัพโหลด
          </XText>
        </XButton>
      </HStack>
    )
  }

  renderLoading = () => {
    if (!this.state.isLoading) {
      return null
    }
    return (
      <View
        style={{
          // zIndex: 999,
          position: 'absolute',
          width: '100%',
          height: 800,
          backgroundColor: 'rgba(52, 52, 52, 0.7)',
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0,
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
          marginTop: 0,
        }}>
        <XSpinner />
      </View>
    )
  }

  callBackOnPressFooter = async (result: boolean) => {
    // console.log('result => ', result)
    if (!result) {
      this._stopLoading()
      return
    }
    await util.delay(500)
    this._stopLoading()
    this.onPressUpload()
  }

  render() {
    // console.log(` RENDER !! `, this.menuItems)
    const { fileUpload, isLoading } = this.state
    const fileSize = fileUpload && fileUpload.size ? fileUpload.size : null
    return (
      <XContainer>
        {this.renderCustomHeader()}

        <XContent p='2'>
          <XCard p='2'>
            {this._renderDownloadTemplateView()}
            <Box h='2' />
            {this._renderDropzone()}
            {/* {this._renderBtnUpload()} */}
          </XCard>
        </XContent>
        <XFooterImportExcel
          fileSize={fileSize}
          // fileName={fileName}
          callBackOnPress={(result: boolean) => {
            this._startLoading()
            this.callBackOnPressFooter(result)
          }}
          isDisabled={isLoading}
        />
        {this.renderLoading()}
      </XContainer>
    )
  }
}

// <Content
//   style={[
//     NO_MARGIN_PADDING,
//     {
//       flex: 1,
//       flexDirection: 'column',
//       // backgroundColor: 'tomato',
//     },
//   ]} />
