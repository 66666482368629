import React, { RefObject } from 'react'
import { Clipboard, Dimensions } from 'react-native'

import _ from 'lodash'
import * as util from 'x/utils/util'
import api, { fetchProductDetail } from 'x/utils/api'
import XCustomHeader from 'xui/components/XCustomHeader'
import { S } from 'x/config/styles'
import p from 'x/config/platform-specific'

import {
  IMkpProductDetailProps,
  IMkpProductDetailState,
  IProductDetailVariant,
  IApiOptions,
  INavHeaderButtonProps,
  IProductDetailItem,
  IComputedVariantMkpInfo,
  IVariantMkpInfo,
  IProductVariantMkpToXSellyMappingViewNavParams,
} from 'x/index'
// import { getXsellyLogo, getShopeeLogo, getLazadaLogo } from 'xui/utils/BaseShareUI'

import { IMkpProductContextProps } from 'x/modules/marketplace/MkpProductContext'
import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import XImage from 'xui/components/XImage'
import XText from 'xui/components/XText'
import Box from 'xui/components/Box'
import XCard from 'xui/components/XCard'
import XContainer from 'xui/components/XContainer'
import XIcon, { XIconFamily } from 'xui/components/XIcon'
import { connect } from 'react-redux'
import { getState, getDispatch } from 'x/modules/marketplace/MkpProductDetailConnect'
import XRadio from 'xui/components/XRadio'
import ImgManager from 'xui/components/ImgManager'
import ImgManagerTypeObject from 'x/components/BaseImgManager'
import MkpLogo from 'xui/components/MkpLogo'
import XContent from 'xui/components/XContent'
import XSellyLogo from '../../components/XSellyLogo'

// import MkpProductVariantPicker from './MkpProductVariantPicker'

interface IXProductVariantItemInfo {
  index: number
  // variant: IMkpProductDetailVariantItem
  variant: IProductDetailVariant
}

// type IProps = IMkpProductDetailProps<IProductVariantMkpToXSellyMappingViewNavParams> & IMkpProductContextProps
type IProps = IMkpProductDetailProps<IProductVariantMkpToXSellyMappingViewNavParams> & IMkpProductContextProps

interface IState extends IMkpProductDetailState {
  variantPickerVisibleAtIndex?: number
  isMappingSummaryVisible?: boolean

  /**
   * isOneToOneVariantMapping flag is set to TRUE when there is only one variant on both XSelly's pt and MKP' pt.
   * This flag is used to simplify UI as there is no need for users to choose mappings b/w variant anymore;
   * In other word, we can do auto variant mapping for users and allow users to immediately confirm the new
   * mapping without changing any data in the view.
   */
  isOneToOneVariantMapping: boolean

  // oldPairedMap?: {
  //   [ppv_uuid: string]: number // ppv_uuid to pp_id
  // }
  newPairedMap?: {
    // [ppv_uuid: string]: number // ppv_uuid to pp_id
    [itemId_itemVariantId: string]: number // item_id + item_variant_id to pp_id
  }
  hilightPickerMap?: {
    [variantIndex: number]: boolean // true for highlight
  }
  isFetchMapping?: boolean

  xSellyProduct?: IProductDetailItem

  // xSellyVariantPickerItemsContainerHeight?: number
  webWindowHeight?: number

  checkedVariantIndex?: number

  xProduct?: IProductDetailItem
}

const BASE_SUBMIT_BTN_STYLE = [S.ROW_CENTER, S.WIDTH_FULL, { minHeight: 34 }]
const BASE_CLOSE_OVERLAY_BTN_STYLE = [S.ROW_CENTER, S.NO_FLEX, { width: 54, marginRight: 4, minHeight: 44 }]

// class ProductDetailMkpMappingView extends BaseUIMkpProductDetail<IProps, IState> {
class ProductVariantMkpToXSellyMappingView extends React.Component<IProps, IState> {
  static displayName = 'ProductVariantMkpToXSellyMappingView'

  isFetchMapping?: boolean

  mkpVariantButtonPositionYMap?: { [btnIndex: number]: number }

  imgManagerRef: RefObject<ImgManagerTypeObject>

  constructor(props) {
    super(props)

    this.state = {
      isInitialized: false,
      // inNavProcess: false,
      editingMkpProduct: null,
      pairedPtVisibleMap: {},
    } as IState

    this.imgManagerRef = React.createRef<ImgManagerTypeObject>()
    // this.contentScrollViewRef = React.createRef()
  }

  async componentDidMount() {
    // await super.componentDidMount.apply(this)

    // const { navigation } = this.props
    // const { editingMkpProduct } = this.state
    // const selectedProductVariant = util.getNavParam(this.props, 'selectedProductVariant')
    // console.log('ProductVariantMkpToXSellyMappingView componentDidMount selectedProductVariant => ', selectedProductVariant)
    const store_id = util.getNavParam(this.props, 'store_id')
    // console.log('ProductVariantMkpToXSellyMappinglyMappingView componentDidMount store_id => ', store_id)
    const product_id = util.getNavParam(this.props, 'product_id')
    // console.log('ProductVariantMkpToXSellyMappinglyMappingView componentDidMount product_id => ', product_id)
    // const xSellyProduct = util.getNavParam(this.props, 'targetXSellyProduct')
    const role_id = util.getNavParam(this.props, 'role_id', undefined)

    try {
      const xpd = await fetchProductDetail({ store_id, product_id, role_id })
      // console.log('ProductVariantMkpToXSellyMappingView componentDidMount xpd => ', xpd)
      if (xpd) {
        await util.setStatePromise(this, { xProduct: xpd })
        await util.delay(100)
        await this._loadImagesToImgMgr()
      }
    } catch (err) {
      console.log('ProductVariantMkpToXSellyMappingView componentDidMount err => ', err)
    }
  }

  renderProductImages = (): JSX.Element => (
    <ImgManager
      // fileNamePrefix={config.s3_prefix_type_product}
      ref={this.imgManagerRef}
      uploading={false}
      showUploaded={false}
      // shareClipboardText={txtShareContent}
      // shareClipboardToastMessage={shareClipboardToastMessage}
      readonly
    />
  )

  getImgManagerRef = () => this.imgManagerRef.current

  _loadImagesToImgMgr = async (): Promise<void> => {
    const imgMgrRef = this.getImgManagerRef()
    if (imgMgrRef && imgMgrRef.loadImageUrls) {
      const { img_uris, thumbnail_uris, tiny_img_uris } = this.state.xProduct
      await imgMgrRef.loadImageUrls({
        p: img_uris,
        t: thumbnail_uris,
        y: tiny_img_uris,
      })
    }
  }

  _clearImagesInImgMgr = async (): Promise<void> => {
    const imgMgrRef = this.getImgManagerRef()
    if (imgMgrRef && imgMgrRef.clearImages) {
      await imgMgrRef.clearImages()
    }
  }

  renderProductName = () => {
    const { navigation } = this.props
    const { xProduct } = this.state
    if (!xProduct) {
      return null
    }
    // const x = util.getNavParam(this.props, 'x', null)
    // const selectedProduct = this.getFocusProduct()
    const txtProductName = xProduct.name || ''
    const handleCopyProductName = () => {
      Clipboard.setString(txtProductName)
      p.op.showToast('คัดลอกชื่อสินค้าแล้ว', 'success')
    }
    return (
      <HStack w='full' alignItems='center'>
        <XText w='70px' variant='inactive'>
          ชื่อ
        </XText>
        {/* <XText style={S.TEXT_PRIMARY}>{`x = ${x}`}</XText> */}
        <XText onPress={handleCopyProductName} style={S.TEXT_ACTIVE}>
          {txtProductName}
        </XText>
      </HStack>
    )
  }

  renderProductDescription = () => {
    const { xProduct } = this.state
    if (!xProduct) {
      return null
    }
    // const selectedProduct = this.getFocusProduct()
    const txtProducDescription = xProduct.description || ''
    const handleCopyProductDescription = () => {
      Clipboard.setString(txtProducDescription)
      p.op.showToast('คัดลอกรายละเอียดสินค้าแล้ว', 'success')
    }
    return (
      <VStack w='full'>
        <XText variant='inactive'>คำอธิบาย</XText>
        <XText w='full' minH='50px' onPress={handleCopyProductDescription}>
          {txtProducDescription}
        </XText>
      </VStack>
    )
  }

  submitNewMapping = async () => {
    if (this.isFetchMapping) {
      return
    }
    this.isFetchMapping = true

    await util.setStatePromise(this, { isFetchMapping: true })

    const { xProduct, checkedVariantIndex = 0 } = this.state
    // const targetXSellyProduct = this.getXSellyProduct()
    const store_id = util.getNavParam(this.props, 'store_id')

    try {
      const apiOptions: IApiOptions = {
        messages: {
          successMsg: 'ดำเนินการเสร็จสิ้น',
          errorMsg: 'ดำเนินการล้มเหลว',
        },
        showSpinner: true,
        axiosOptions: {
          retry: 0,
          timeout: 60000,
        },
        isApiV2: true,
      }

      // const new_pp_mappings = []

      // // const ppvUUIDs = Object.keys(newPairedMap)
      // const itemId_ItemVariantIds = Object.keys(newPairedMap)
      // for (const itemId_ItemVariantId of itemId_ItemVariantIds) {
      //   const ppid = newPairedMap[itemId_ItemVariantId]
      //   // const item_id = parseInt(itemId_ItemVariantId.split('_')[0])
      //   const item_id = itemId_ItemVariantId.split('_')[0]

      //   // let item_variant_id: string | number | null = itemId_ItemVariantId.split('_')[1]
      //   let item_variant_id: string | null = itemId_ItemVariantId.split('_')[1]

      //   if (item_variant_id === 'null') {
      //     item_variant_id = null
      //   }

      //   if (_.isString(item_variant_id)) {
      //     // item_variant_id = parseInt(item_variant_id)
      //     item_variant_id = item_variant_id
      //   }

      //   new_pp_mappings.push({
      //     pp_id: ppid,
      //     // ppv_uuid: ppvUUID,
      //     item_id,
      //     item_variant_id,
      //   })
      // }

      const svm = util.getNavParam(this.props, 'selectedVariantMkp')
      const xpdv = xProduct.variants[checkedVariantIndex]

      const new_pp_mappings = [
        {
          pp_id: xpdv.pp_id,
          item_id: svm.item_id,
          // @ts-ignore
          item_variant_id: svm.item_variant_id === 'null' ? null : svm.item_variant_id,
        },
      ]

      const reqBody = {
        store_id,
        mkp_ch_id: svm.mkp_ch_id,
        // mkp_id,
        // item_uuid: uuid,
        // pt_id,
        new_pp_mappings,
        // new_pp_mappings: [
        //   {
        //     pp_id: targetXSellyProduct.variants[0].pp_id,
        //     ppv_uuid: editingMkpProduct.variants[0].ppv_uuid,
        //   },
        // ],
      }

      // console.log('submitNewMapping reqBody => ', reqBody)

      const res = await api.post('mkp/v2/product_mapping/update', reqBody, apiOptions)

      // console.log('submitNewMapping res => ', res)

      if (res && res.status === 'ok') {
        const onMappingSuccess = util.getNavParam(this.props, 'onMappingSuccess')
        if (onMappingSuccess) {
          await onMappingSuccess()
          this.goBack()
        }

        // await this._closeMappingSummary()
        // const isPreventDestroyWhenNavBack = util.getNavParam(this.props, 'isPreventDestroyWhenNavBack', false)
        // if (isPreventDestroyWhenNavBack) {
        //   await this.doRefresh()
        // }
        // const mkpState = mkpProductContext.getMkpState(this.getContextViewKey())
        // if (_.isFunction(mkpState.onSuccesssMapping)) {
        //   mkpState.onSuccesssMapping()
        //   await util.delay(500)
        // }
        // this._doGoBackAfterSuccessMapping()
      }

      // console.log('_doFetchApiMkpProductMapping res => ', res)
    } catch (err) {
      // console.log('_doFetchApiMkpProductMapping err => ', err)
      console.log('submitNewMapping err => ', err)
    }

    await util.setStatePromise(this, { isFetchMapping: false })
    this.isFetchMapping = false
  }

  // Override
  renderFooter = () => null

  // Override
  getPairedCoreProductLabelText = () => (this._isNewMappingCoreProduct() ? 'สลับไปผูกกับ' : 'ผูกอยู่กับ')

  getComputedMkpInfo = (mkp: IVariantMkpInfo, variantIndex: number): IComputedVariantMkpInfo | null => {
    if (!_.isObject(mkp) || !mkp.mkp_ch_id) {
      return null
    }

    const { mkp_ch_id } = mkp
    const mkpChannel = util.getMKPChannelByUGID(mkp_ch_id)

    if (!_.isObject(mkpChannel) || !mkpChannel.mkp_id) {
      return null
    }

    const { mkp_id, name: mkpName } = mkpChannel

    return {
      ...mkp,
      mkpName,
      mkp_id,
      variantIndex,
    }
  }

  _renderVariantIdLabel = (info: IXProductVariantItemInfo) => {
    const { index, variant } = info
    const v = variant

    if (!v || !v.pp_id) {
      return null
    }

    const id = v.pp_id
    return (
      <HStack w='full' position='absolute' left='0' right='0' bottom='0' px='1'>
        <XText
          variant='inactive'
          fontSize='2xs'
          numberOfLines={1}
          // @ts-ignore
          onPress={() => {
            Clipboard.setString(id.toString())
            p.op.showToast(`คัดลอก ${id} แล้ว`, 'success')
          }}>
          {`ID:${id}`}
        </XText>
      </HStack>
    )
  }

  // override
  renderPairedXSellyProductVariantInfo = (info: IXProductVariantItemInfo) => {
    const { index, variant } = info
    const isPaired = variant.mkp && variant.mkp.length > 0

    if (!isPaired) {
      return null
    }

    return (
      <VStack w='full' key={`PairedInfo_${index}`}>
        <HStack w='full' px='3' pb='1' alignItems='center' space='1'>
          {isPaired ? <XIcon name='link' family='FontAwesome' inactive minW='28px' /> : null}
          {
            isPaired ? <XText variant='inactive'>ผูกอยู่กับ:</XText> : null
            // (
            //   <XText flex={1} textAlign='center' variant='inactive'>
            //     ยังไม่ได้ผูกกับสินค้าใดๆ
            //   </XText>
            // )
          }
        </HStack>

        <VStack w='full' space='0.5'>
          {isPaired ? variant.mkp.map((mkpi, idx) => this.renderPairedMkpProductVariantInfoCardButton(mkpi, idx, index)) : null}
        </VStack>
      </VStack>
    )
  }

  // renderPairedMkpProductVariantInfoCardButton = (info: IMkpProductVariantItemInfo) => {
  renderPairedMkpProductVariantInfoCardButton = (mkp: IVariantMkpInfo, index: number, variantIndex: number) => {
    const cMkp = this.getComputedMkpInfo(mkp, variantIndex)

    if (!cMkp) {
      return null
    }

    return (
      <XCard key={`PairedInfoCardButton_${index}`} bg='muted.200'>
        <XCard.Body>
          <HStack w='full' space='1'>
            <VStack w='16' h='16'>
              <XImage w='16' h='16' borderRadius='lg' resizeMode='cover' source={{ uri: cMkp.thumbnail_url }} />
              <Box position='absolute' top='1' left='1'>
                <MkpLogo mkpId={cMkp.mkp_id} width={24} height={24} />
              </Box>
            </VStack>
            <VStack flex={1}>
              <XText w='full' numberOfLines={2}>
                {cMkp.item_name}
              </XText>
            </VStack>
            <VStack _web={{ w: '70px' }} w='90px' alignItems='flex-end'>
              <XText w='full' textAlign='right' numberOfLines={3}>
                {cMkp.variant_name}
              </XText>
            </VStack>
          </HStack>
        </XCard.Body>
      </XCard>
    )
  }

  getHeaderLeftProps = (): INavHeaderButtonProps => {
    const { isFetchMapping } = this.state

    return {
      submitting: isFetchMapping,
      label: 'ตกลง',
      onPressItem: this.submitNewMapping,
    }
  }

  getHeaderRightProps = (): INavHeaderButtonProps => {
    const { navigation } = this.props
    const { isFetchMapping } = this.state
    const mkpId = util.getNavParam(this.props, 'mkp_id')

    return {
      label: 'ยกเลิก',
      submitting: isFetchMapping,
      onPressItem: this._handlePressCancelMapping,
      // iconButtonProps: {
      //   _text: {
      //     color: COLORS.TEXT_INACTIVE,
      //   },
      // },
    }
  }

  getHeaderTitle = () => {
    const { navigation } = this.props
    // const mkpId = util.getNavParam(this.props, 'mkp_id')
    // if (!mkpId) {
    return 'จัดการผูกตัวเลือกสินค้า'
    // }
    // const mkpName = util.getMKPName(mkpId)
    // return `จัดการผูกตัวเลือกสินค้า\nXSelly กับระบบ ${mkpName}`
  }

  goBack = () => {
    util.navGoBack(this.props)
  }

  // Override
  renderCustomHeader = () => (
    <VStack w='full'>
      <XCustomHeader
        // title={`จัดการผูกตัวเลือกสินค้า\nXSelly กับระบบ ${mkpName}`}
        title={this.getHeaderTitle()}
        headerLeftProps={this.getHeaderLeftProps()}
        headerRightProps={this.getHeaderRightProps()}
      />
      {this.renderXSellyProductInfo()}
      <HStack w='full' px='3' py='1' space='1' alignItems='center' justifyContent='center'>
        <XText textAlign='center'>เลือกตัวเลือกสินค้าที่ต้องการผูกแล้วกด "ตกลง"</XText>
      </HStack>
    </VStack>
  )

  // getXSellyProduct = () => {
  //   const { navigation } = this.props
  //   const xProduct = util.getNavParam(this.props, 'targetXSellyProduct')
  //   return xProduct
  // }

  renderXSellyProductInfo = () => {
    const { xSellyProduct: xProduct } = this.state

    if (!xProduct || !xProduct.name) {
      return null
    }

    const { name, description, thumbnail_uris = [], variants = [] } = xProduct
    const variantCount = variants.length
    const variantNames = variants.map((xv) => xv.name).join(', ')

    return (
      <HStack w='full' px='3' py='2' space='1'>
        <VStack>
          <XImage w='16' h='16' borderRadius='lg' resizeMode='cover' source={{ uri: thumbnail_uris[0] }} />
          <Box position='absolute' top='1' left='1'>
            <XSellyLogo width={24} height={24} />
          </Box>
        </VStack>
        <VStack flex={1} space='1'>
          <XText w='full' numberOfLines={2}>
            {name}
          </XText>
          {/* <XText variant='inactive' fontSize='xs' w='full' numberOfLines={1}>
            {description}
          </XText> */}
          {variantCount > 1 ? (
            <XText fontSize='xs' w='full' numberOfLines={2}>
              <XText variant='inactive' fontSize='xs'>{`มี ${variantCount} ตัวเลือก: `}</XText>
              {variantNames}
            </XText>
          ) : null}
        </VStack>
      </HStack>
    )
  }

  // _onPickVariant = (mkpVariantIdx, coreVariantIdx) => {
  //   this.setState({})
  // }

  _onRequestCloseProductVariantPicker = () => {
    this.setState({ variantPickerVisibleAtIndex: -1 })
  }

  _onOpenVariantPicker = (pickedMkpIdx: number) => {
    this.setState({ variantPickerVisibleAtIndex: pickedMkpIdx })
  }

  _closeMappingSummary = async () => {
    await util.setStatePromise(this, { isMappingSummaryVisible: false })
  }

  _openMappingSummary = async () => {
    await util.setStatePromise(this, { isMappingSummaryVisible: true })
  }

  _handlePressCancelMapping = async () => {
    this.goBack()
  }

  _doGoBackAfterSuccessMapping = () => {
    if (this._isNewMappingCoreProduct()) {
      this._goBackToProductView()
      return
    }
    this.goBack()
  }

  _isNewMappingCoreProduct = () => {
    const { editingMkpProduct, coreProduct } = this.state
    // @ts-ignore :: FIXME: แก้ mapping ให้ใช้งานได้
    const currentPairedProductTemplateId = editingMkpProduct.pt_id
    const selectedProductId = coreProduct.id
    return currentPairedProductTemplateId !== selectedProductId
  }

  _goBackToProductView = () => {
    // const { navigation } = this.props
    // const { state, goBack } = navigation
    // goBack('MkpProductLinkManagerView')
    util.navGoBack(this.props)
  }

  // _onMkpProductVariantPickerOverlayLayout = (evt: LayoutChangeEvent) => {
  _onMkpProductVariantPickerOverlayLayout = () => {
    try {
      // const { height } = evt.nativeEvent.layout
      const webWindowHeight = Dimensions.get('window').height
      // console.log('_onMkpProductVariantPickerOverlayLayout webWindowHeight => ', webWindowHeight)
      if (p.op.isWeb() && webWindowHeight !== this.state.webWindowHeight) {
        this.setState({ webWindowHeight })
      }
      // }
    } catch (error) {
      //
    }
  }

  onRadioPress = (vIndex: number) => {
    const { checkedVariantIndex = 0 } = this.state
    if (vIndex !== checkedVariantIndex) {
      this.setState({ checkedVariantIndex: vIndex })
    }
  }

  // override
  renderProductVariantItemContainer = (variant: IProductDetailVariant, index: number) => {
    const { checkedVariantIndex = 0, xProduct } = this.state
    const isVariantChecked = checkedVariantIndex === index
    const isEven = index % 2 === 0
    // let  backgroundColor = isEven ? COLORS.BG_LIGHT_GREY : COLORS.WHITE
    let bg = isEven ? 'muted.100' : 'white'
    if (isVariantChecked) {
      bg = 'primary.100'
    }

    let xVariantThumbnailUri = xProduct.thumbnail_uris[0]

    if (variant.tiny_img_url && variant.tiny_img_url !== '') {
      xVariantThumbnailUri = variant.tiny_img_url
    }

    return (
      <XCard
        key={`ProductVariantItem_${index.toString()}`}
        w='full'
        bg={bg}
        // style={{ backgroundColor }}
        onPress={() => this.onRadioPress(index)}>
        <VStack w='full'>
          <HStack w='full' p='1' alignItems='center'>
            <VStack h='32px' w='32px'>
              <XRadio checked={isVariantChecked} onPress={() => this.onRadioPress(index)} />
            </VStack>

            <VStack flex={1} space='1.5'>
              {/* <HStack w='full' p='2' space='1' alignItems='center' justifyContent='center'>
                <XSellyLogo width={24} height={24} />
                <XText variant='inactive' bold>{`ตัวเลือกที่ ${index + 1}`}</XText>
                <Box h='24px' w='24px' />
              </HStack> */}

              <HStack w='full' px='2' py='1' space='1' alignItems='center' justifyContent='center'>
                {xVariantThumbnailUri && xVariantThumbnailUri !== '' && (
                  <VStack w='16' h='16'>
                    <XImage w='16' h='16' borderRadius='lg' resizeMode='cover' source={{ uri: xVariantThumbnailUri }} />

                    <Box position='absolute' top='1' left='1'>
                      <XSellyLogo width={24} height={24} />
                    </Box>
                  </VStack>
                )}

                <HStack flex={1} space='1' alignItems='center' justifyContent='center'>
                  <XSellyLogo width={24} height={24} />
                  <XText variant='inactive' bold>{`ตัวเลือกที่ ${index + 1}`}</XText>
                  <Box h='24px' w='24px' />
                </HStack>

                {xVariantThumbnailUri && xVariantThumbnailUri !== '' && <Box w='16' h='16' />}
              </HStack>

              <VStack flex={1} pb='2.5'>
                {this.renderProductVariantItemContent(variant, index)}
              </VStack>
            </VStack>
          </HStack>

          {this._renderVariantIdLabel({ index, variant })}
        </VStack>
      </XCard>
    )
  }

  // override
  renderProductVariantItemContent = (variant: IProductDetailVariant, index: number) => {
    const { xProduct } = this.state
    // const vCount = this.getVariantCount()
    // const mkpId = xProduct.mkp_id

    return (
      <VStack key={`XPP_${index}`} w='full' space='1.5'>
        {this.renderVariantName({ index, variant })}
        {/* {this.renderVariantSalePrice({ index, variant, mkpId })} */}
        {/* {this.renderVariantPromotionPrice({ index, variant, mkpId })} */}
        {this.renderPairedXSellyProductVariantInfo({ index, variant })}
      </VStack>
    )
  }

  getVariantCount = () => {
    const { xProduct } = this.state
    const vCount = xProduct.variants.length
    return vCount
  }

  renderVariantName = (info: IXProductVariantItemInfo) => {
    const vCount = this.getVariantCount()
    const v = info.variant
    return this.renderGenericVariantItem({
      label: 'ชื่อ',
      value: vCount === 1 ? '-' : v.name,
      iconName: 'create',
      iconFamily: 'Ionicons',
      isValueBold: true,
    })
  }

  renderGenericVariantItem = (info: {
    label: string
    value: string
    iconName?: string
    iconFamily?: XIconFamily
    isValueBold?: boolean
  }) => (
    <HStack w='full' px='3' space='1'>
      <HStack minW='80px' alignItems='center' space='1'>
        <XIcon minW='28px' name={info.iconName} family={info.iconFamily} inactive />
        <XText variant='inactive'>{info.label}</XText>
      </HStack>
      <HStack flex={1} alignItems='center' justifyContent='flex-end'>
        <XText bold={info.isValueBold || undefined}>{info.value}</XText>
      </HStack>
    </HStack>
  )

  renderProductVariants = () => {
    const { xProduct } = this.state
    if (!xProduct || _.isEmpty(xProduct)) {
      return null
    }

    const { variants } = xProduct
    if (!variants || variants.length === 0) {
      return null
    }

    return variants.map(this.renderProductVariant)
  }

  // renderProductVariant = (variant: IMkpProductDetailVariantItem, index: number) => this.renderProductVariantItemContainer(variant, index)
  renderProductVariant = (variant: IProductDetailVariant, index: number) => this.renderProductVariantItemContainer(variant, index)

  renderHeaderStoreName = () => {
    const { selectedStore } = this.props
    const { xProduct } = this.state

    return (
      <VStack w='full' p='1'>
        <HStack bg='primary.100' w='full' alignItems='center' p='2' mb='1' space='1.5'>
          <XSellyLogo />
          {/* <ShopeeLogo height={STYLES.MKP_ICON_SIZE_NORMAL} width={STYLES.MKP_ICON_SIZE_NORMAL} /> */}
          <VStack flex={1}>
            <XText numberOfLines={1} bold>
              เป็นสินค้าจาก XSelly
            </XText>
          </VStack>
        </HStack>
      </VStack>
    )
  }

  render() {
    const renderNavReference = util.getNavParam(this.props, 'renderNavReference')
    // const selectedVariantMkp = util.getNavParam(this.props, 'selectedVariantMkp')

    return (
      <XContainer>
        {_.isFunction(renderNavReference) ? renderNavReference() : null}
        {this.renderCustomHeader()}
        <XContent p='1'>
          <XCard>
            <VStack w='full' p='2' space='1' bg='white'>
              {this.renderHeaderStoreName()}
              {this.renderProductImages()}
              {this.renderProductName()}
              {this.renderProductDescription()}
              {this.renderProductVariants()}

              {/* <XText>Test API ProductVariantMkpToXSellyMappingView</XText> */}
              {/* <XText>selectedVariantMkp = {JSON.stringify(selectedVariantMkp)}</XText> */}
            </VStack>
          </XCard>
        </XContent>
      </XContainer>
    )
  }
}

export default connect(
  (state) => ({
    ...getState(state),
  }),
  (dispatch) => ({
    ...getDispatch(dispatch),
  })
)(ProductVariantMkpToXSellyMappingView)
