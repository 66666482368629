import { takeLatest, put, call, fork, all, take } from 'redux-saga/effects'
import _ from 'lodash'
import * as util from 'x/utils/util'
import { ActionApiParams, IApiOptions } from 'x/index'
import api from '../../utils/api'
import actions from '../../config/actions'

// import * as OnboardActions from '../../modules/onboard/OnboardState'
// import * as NavActions from '../../services/navigation'
// import { fcmGetToken, setCrashlyticsUserId } from '../../utils/analytics'
// import * as auth from 'x/utils/authentication'

import { log } from '../../utils/util'
import p from '../../config/platform-specific'
import { handleSubscriptionFromResponse } from './subscription'
import { handleMyStoresFromResponse, handleSellerStoresFromResponse, loadStoreFromResponse } from './stores'
import { handleProfileFromResponse } from './profile'
import { handleCustomOrderFromResponse } from './preference'

export default function* watchAllAuthentication() {
  yield all([
    fork(watchFacebookLogin),
    fork(watchAccountLogin),
    fork(watchAccountPasswordRecovery),
    fork(watchAccountPasswordReset),
    fork(watchRefreshAppToken),
    fork(watchFetchInitializeData),
    fork(watchLogOut),
  ])
}

function* watchFacebookLogin() {
  log('In watchFacebookLogin')
  // while (true) {
  //   const { payload, failedCallback } = yield take(actions.LOGIN_WITH_FACEBOOK)
  //   if ('accessToken' in payload) {
  //     yield call(loginWithFacebook, payload, failedCallback)
  //   }
  // }
  // yield takeLatest(actions.LOGIN_WITH_FACEBOOK, loginWithFacebook)
  while (true) {
    const action = yield take(actions.LOGIN_WITH_FACEBOOK)
    yield call(loginWithFacebook, action)
  }
}

function* loginWithFacebook(action: ActionApiParams) {
  log('In loginWithFacebook')
  log('In loginWithAccount')
  if (_.isNil(action) || _.isEmpty(action)) {
    throw new Error('No action for loginWithAccount')
  }
  const { body, successCallback, failedCallback } = action
  // log(body)
  try {
    const apiOptions: IApiOptions = {
      messages: {
        successMsg: 'เข้าสู่ระบบสำเร็จ',
        errorMsg: 'เข้าสู่ระบบล้มเหลว',
      },
      showSpinner: true,
      axiosOptions: {
        retry: 0,
        timeout: 60000,
      },
    }
    const res = yield call(api.post, api.POST_FB_LOGIN, body, apiOptions)
    log('In loginWithFacebook res: ', res)
    // yield call(p.op.handleAuthResponseFromSaga, res)
    if (_.isFunction(successCallback)) {
      successCallback(res)
    }
  } catch (error) {
    log('In loginWithFacebook error: ', error)
    yield put({ type: actions.LOGIN_WITH_FACEBOOK_INVALID, error })
    if (_.isFunction(failedCallback)) {
      failedCallback(error)
    }
  }
}

function* watchAccountLogin() {
  log('In watchAccountLogin')
  // while (true) {
  //   const { payload, failedCallback } = yield take(actions.LOGIN_WITH_ACCOUNT)
  //   yield call(loginWithAccount, payload, failedCallback)
  // }
  // yield takeLatest(actions.LOGIN_WITH_ACCOUNT, loginWithAccount)
  while (true) {
    const action = yield take(actions.LOGIN_WITH_ACCOUNT)
    yield call(loginWithAccount, action)
  }
}

function* loginWithAccount(action: ActionApiParams) {
  log('In loginWithAccount')
  if (_.isNil(action) || _.isEmpty(action)) {
    throw new Error('No action for loginWithAccount')
  }
  const { body, successCallback, failedCallback, timeout = 60000 } = action
  try {
    const apiOptions: IApiOptions = {
      messages: {
        successMsg: 'เข้าสู่ระบบสำเร็จ',
        errorMsg: 'เข้าสู่ระบบล้มเหลว',
      },
      showSpinner: true,
      fetchOptions: {
        timeout,
      },
      // axiosOptions: {
      //   retry: 0,
      //   timeout,
      // },
    }
    const res = yield call(api.post, api.POST_LOGIN, body, apiOptions)
    log('In loginWithAccount res: ', res)
    // yield call(p.op.handleAuthResponseFromSaga, res)
    if (_.isFunction(successCallback)) {
      successCallback(res)
    }
  } catch (error) {
    // yield call(p.op.handleAuthErrorFromSaga, error)
    if (_.isFunction(failedCallback)) {
      failedCallback(error)
    }
  }
}

function* watchLogOut() {
  log('In watchLogOut')
  // while (true) {
  //   const { payload, failedCallback } = yield take(actions.LOGIN_WITH_ACCOUNT)
  //   yield call(loginWithAccount, payload, failedCallback)
  // }
  // @ts-ignore
  yield takeLatest(actions.LOGOUT, logOut)
}

function* logOut(params: { payload: { fcm_token?: string }; successCallback?: () => void; failedCallback?: () => void }) {
  const { payload, successCallback = null, failedCallback = null } = params
  log('In logOut')
  const apiOptions = {
    successMsg: 'ออกจากระบบสำเร็จแล้ว',
    showSpinner: true,
  }

  try {
    const res = yield call(api.post, api.POST_LOGOUT, payload, apiOptions)
    log('In logOut res: ', res)
    if (_.isFunction(successCallback)) {
      successCallback(res)
    }
  } catch (error) {
    log('In logOut error: ', error)
    yield put({ type: actions.LOGOUT_INVALID })
    if (_.isFunction(failedCallback)) {
      failedCallback(error)
    }
  }
}

function* watchAccountPasswordRecovery() {
  log('In watchAccountPasswordRecovery')
  // while (true) {
  //   const { payload } = yield take(actions.FORGOT_ACCOUNT_PASSWORD)
  //   yield call(recoveryAccountPassword, payload)
  // }
  // @ts-ignore
  yield takeLatest(actions.FORGOT_ACCOUNT_PASSWORD, recoveryAccountPassword)
}

function* recoveryAccountPassword({ payload, successCallback = null, failedCallback = null }) {
  log('In recoveryAccountPassword')
  try {
    const apiOptions = {
      noToken: true,
    }
    const res = yield call(api.post, api.POST_FORGOT_PASSWORD, payload, apiOptions)
    log('In recoveryAccountPassword res: ', res)
    if (res && res.status === 'ok') {
      yield call(
        p.op.alert,
        'ส่งลิงก์ในการเปลี่ยนรหัสผ่านแล้ว',
        `กรุณาเช็คอีเมล ${payload.email} เพื่อดำเนินการเปลี่ยนรหัสผ่านต่อไป\n\nหากคุณใช้ gmail กรุณาดูใน tab Promotions หากไม่พบอีเมลจาก XSelly ใน Inbox`
      )
      // yield put(NavActions.pop(1))
      if (_.isFunction(successCallback)) {
        successCallback(res)
      }
    }
  } catch (error) {
    log('In recoveryAccountPassword error: ', error)
    yield put({ type: actions.FORGOT_ACCOUNT_PASSWORD_INVALID })
    if (_.isFunction(failedCallback)) {
      failedCallback(error)
    }
  }
}

function* watchAccountPasswordReset() {
  log('In watchAccountPasswordReset')
  // while (true) {
  //   const { payload } = yield take(actions.RESET_ACCOUNT_PASSWORD)
  //   yield call(resetAccountPassword, payload)
  // }
  // @ts-ignore
  yield takeLatest(actions.RESET_ACCOUNT_PASSWORD, resetAccountPassword)
}

function* resetAccountPassword({ payload, successCallback = null, failedCallback = null }) {
  log('In resetAccountPassword')
  try {
    // @ts-ignore
    const res = yield call(api.post, api.POST_RESET_PASSWORD, payload)
    log('In resetAccountPassword res: ', res)
    if (res && res.status === 'ok') {
      yield call(p.op.showToast, 'การเปลี่ยนรหัสผ่านใหม่สำเร็จแล้ว', 'success', 5000)
      // yield put(NavActions.pop(1))
    }
    if (_.isFunction(successCallback)) {
      successCallback(res)
    }
  } catch (error) {
    log('In resetAccountPassword error: ', error)
    yield put({ type: actions.RESET_ACCOUNT_PASSWORD_INVALID })
    if (_.isFunction(failedCallback)) {
      failedCallback(error)
    }
  }
}

function* watchFetchInitializeData() {
  log('In watchFetchInitializeData')
  yield takeLatest(actions.FETCH_INITIALIZE_DATA, fetchInitializeData)
}

function* fetchInitializeData(action = { successCallback: null, failedCallback: null }) {
  log('In fetchInitializeData')
  // log(body)
  try {
    const apiOptions: IApiOptions = {
      messages: {
        successMsg: 'แอปพร้อมใช้งาน',
      },
      showSpinner: true,
      axiosOptions: {
        retry: 3,
        // ตั้งไว้นานเผื่อ init ss โหลดช้า
        timeout: 40000,
      },
    }
    const initX = yield call(util.getInitPlue, action)
    // console.log('initX', initX)
    const res = yield call(api.get, api.GET_FETCH_INITIALIZE_DATA + initX, apiOptions)
    // log('In fetchInitializeData res: ', res)
    yield call(handleSubscriptionFromResponse, res)
    yield call(handleMyStoresFromResponse, res)
    yield call(handleSellerStoresFromResponse, res)
    yield call(handleProfileFromResponse, res)
    yield call(handleCustomOrderFromResponse, res)
    if (res && _.has(res, 'ss')) {
      // p.op.alertPromise('Debug', JSON.stringify(util.convertQueryParamsStringToObject(initX)))
      yield call(loadStoreFromResponse, res, util.convertQueryParamsStringToObject(initX))
      // yield put(StoreActions.setSelectedStore(res.ss))
    }
    if (_.isFunction(action.successCallback)) {
      action.successCallback(res)
    }
  } catch (error) {
    log('In fetchInitializeData error: ', error)
    yield put({ type: actions.FETCH_INITIALIZE_DATA_FAILED, error })
    if (_.isFunction(action.failedCallback)) {
      action.failedCallback(error)
    }
  }
}

function* watchRefreshAppToken() {
  log('In watchRefreshAppToken')
  // @ts-ignore
  yield takeLatest(actions.LOGIN_REFRESH_APP_TOKEN, refreshAppToken)
}

function* refreshAppToken({ successCallback = null, failedCallback = null }) {
  log('In refreshAppToken')
  try {
    // @ts-ignore
    const refreshedToken = yield call(api.getRefreshToken)
    // log('In refreshAppToken refresh:: refreshedToken => ', JSON.stringify(refreshedToken))
    // yield call(p.op.handleAuthResponseFromSaga, refreshedToken)
    if (_.isFunction(successCallback)) {
      successCallback(refreshedToken)
    }
  } catch (error) {
    log('refreshAppToken.error', error)
    // auth.clearAuthenticationToken()
    yield call(p.op.signOut)
    yield put({ type: actions.LOGIN_REFRESH_APP_TOKEN_INVALID })
    if (_.isFunction(failedCallback)) {
      failedCallback(error)
    }
    // yield p.op.alert('การเชื่อมต่อล้มเหลว', 'กรุณาเข้าสู่ระบบใหม่อีกครั้ง')
    // yield put({ type: actions.LOGIN_REFRESH_APP_TOKEN })
    // if ('code' in error) {
    //   log('In refreshAppToken error: ', error)
    //   if (error.code === 401) {
    //     yield put({ type: actions.LOGIN_REFRESH_APP_TOKEN_INVALID })
    //   }
    // } else {
    //   yield put({ type: actions.LOGIN_REFRESH_APP_TOKEN_INVALID })
    // }
  }
}

// export function* handleAuthResponse(res) {
//   log('handleAuthResponse.res', res)
//   if (res && res.token) {
//     const authRegister = yield call(auth.setAuthenticationToken, res.token)
//     log('In handleAuthResponse authRegister: ', authRegister)
//   }
//
//   // initialize FCM Token
//   yield call(fcmGetToken)
//
//   if (res && res.missing && res.default) {
//     // yield put(OnboardActions.nextMissingField, res.missing)
//     log('res has default => ', res)
//     yield put(OnboardActions.nextMissingField(res.missing, res.default))
//   } else if (res && res.missing) {
//     log('res no default => ', res)
//     yield put(OnboardActions.nextMissingField(res.missing))
//   } else {
//     // initialize User Profile (Move to Saga)
//     yield put({
//       type: actions.USER_PROFILE_FETCH,
//       successCallback: ({ profile }) => {
//         if (profile && profile.user_id) {
//           setCrashlyticsUserId(profile.user_id.toString())
//         }
//       },
//     })
//     // const { profile } = yield call(api.get, api.GET_PROFILE)
//     // if (profile) {
//     //   yield call(storageSet, USER_PROFILE, profile)
//     //   yield put({ type: ProfileActions.LOAD_PROFILE, payload: { profile } })
//     // }
//
//     // log('successCallback', successCallback)
//     // if (_.isFunction(successCallback)) {
//     //   yield put(successCallback)
//     //   // successCallback()
//     // }
//     // yield put(p.nav.resetToHome())
//     yield call(p.nav.resetToHome)
//     // yield put(NavActions.resetToHome)
//
//     // Initialize FCM Event
//     yield delay(500)
//     // yield call(util.initFirebase)
//     yield call(p.op.initAnalytics)
//
//     // Fixme: For debug only
//     // yield delay(1500)
//     // yield put({
//     //   type: 'Navigation/NAVIGATE',
//     //   routeName: 'StoreMyView',
//     //   params: {
//     //     reqStoreID: 1,
//     //     store_name: 'Realigned multimedia firmware',
//     //   },
//     // })
//     // yield delay(500)
//     // yield put({
//     //   type: 'Navigation/NAVIGATE',
//     //   routeName: 'DailySalesReportView',
//     // })
//     // yield put({
//     //   type: 'Navigation/NAVIGATE',
//     //   routeName: 'OrderView',
//     //   params: {
//     //     order_id: 364,
//     //     store_id: 1,
//     //     mode: 'VIEW_EDIT'
//     //   }
//     // })
//     // yield put({
//     //   type: 'Navigation/NAVIGATE',
//     //   routeName: 'StoreOrderListViewContainer',
//     //   params: {
//     //     storeId: 1
//     //   }
//     // })
//     // yield put({
//     //   type: 'Navigation/NAVIGATE',
//     //   routeName: 'others',
//     // })
//     // yield put({
//     //   type: 'Navigation/NAVIGATE',
//     //   routeName: 'SettingResetEmailView'
//     // })
//
//     // yield delay(3500)
//     // yield put({
//     //   type: 'Navigation/NAVIGATE',
//     //   routeName: 'ProfileView',
//     //   params: {
//     //     mode: 'VIEW'
//     //   }
//     // })
//     // yield put({
//     //   type: 'Navigation/NAVIGATE',
//     //   routeName: 'SettingResetPasswordView'
//     // })
//
//     // Checking cache from notification tray
//     // const navTarget = yield call(storageGet, FCM_NOTI_NAV_CACHE)
//     //
//     // if (profile && profile.user_id && profile.user_id <= 4) {
//     //   p.op.alert('navTarget', JSON.stringify(navTarget))
//     // }
//     //
//     // yield call(storageSet, FCM_NOTI_NAV_CACHE, {})
//     // log('Dispatch cache navigation/FCM_CACHE_NAVS was clear.')
//
//     // if (navTarget && _.isObject(navTarget) && navTarget.type) {
//     //   yield put(navTarget)
//     // } else {
//     //   yield put(NavActions.resetToHome)
//     //   log('Initialize FCM_CACHE_NAVS successful.')
//     // }
//   }
// }
