import React from 'react'
// import { STYLES, COLORS } from 'x/config/styles'

import XImage, { IXImageProps } from './XImage'

const redIcon = require('../img/verify_slip/red.png')
const blueIcon = require('../img/verify_slip/blue.png')
const greenIcon = require('../img/verify_slip/green.png')
const yellowIcon = require('../img/verify_slip/yellow.png')
const greyIcon = require('../img/verify_slip/grey.png')

interface IVerifySlipIconProps extends Omit<IXImageProps, 'source'> {
  success?: boolean
  warning?: boolean
  danger?: boolean
  inactive?: boolean
  blue?: boolean
  width?: number
  height?: number
}

const VerifySlipIcon: React.FC<IVerifySlipIconProps> = (props) => {
  const { success, warning, danger, inactive, blue, width = 24, height = 24, style = {}, ...restProps } = props

  let imgSrc = greyIcon

  if (blue) {
    imgSrc = blueIcon
  }
  if (inactive) {
    imgSrc = greyIcon
  }
  if (warning) {
    imgSrc = yellowIcon
  }
  if (danger) {
    imgSrc = redIcon
  }
  if (success) {
    imgSrc = greenIcon
  }

  // @ts-ignore
  return <XImage source={imgSrc} {...restProps} style={{ width, height, ...style }} />
}

export default VerifySlipIcon
