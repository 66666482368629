import { Component } from 'react'

import _ from 'lodash'
// import { COLORS, STYLES } from '../../config/styles'
import { ISelectedStoreMap, ActionApiParams, IXScreenProps } from 'x/index'
import * as util from '../../../utils/util'

import { IHelperCanDo } from './BaseAssistantGroupView'

export interface BaseAssistantGroupListViewProps extends IXScreenProps {
  getPermissionList: (params: ActionApiParams) => void
  selectedStore: ISelectedStoreMap
}

export interface BaseAssistantGroupListViewState {
  permissions: IPermission[]
  fetchPermissions: boolean
  reRander: boolean
  // refreshControl: boolean
}

export interface IPermission {
  id: number
  name: string
  description: string
  helper_count: number
  created_at: string
  updated_at: string
  perf: IHelperCanDo
}

export interface ResponesPermissionList {
  permissions: IPermission[]
}

export default abstract class BaseAssistantGroupListView extends Component<
  BaseAssistantGroupListViewProps,
  BaseAssistantGroupListViewState
> {
  // eslint-disable-next-line react/sort-comp
  // abstract _handleRightBtn(): void

  protected constructor(props) {
    super(props)

    this.state = {
      // eslint-disable-next-line react/no-unused-state
      permissions: null,
      fetchPermissions: false,
      reRander: false,
      // refreshControl: false,
    }
  }

  async componentDidMount() {
    // const { navigation } = this.props
    const response: IPermission[] = await this._getPermissionList()
    await util.setStatePromise(this, {
      permissions: response,
    })
    // navigation.setParams({
    //   handleRightBtn: () => this._handleRightBtn(),
    // })
    // this._checkFetchPermisstion()
    // console.log('IPermission[] => ', response)
  }

  _getPermissionList = async () => {
    const { getPermissionList, selectedStore } = this.props
    const store_id = selectedStore.get('id')
    const res: ResponesPermissionList = await new Promise((resolve) => {
      getPermissionList({
        body: {
          store_id,
        },
        successCallback: resolve,
        failedCallback: resolve,
      })
    })
    return res.permissions
    // const resFromJS = fromJS(res)
    // const response = resFromJS.size > 0 ? (_.isNil(resFromJS.get('permissions')) ? null : resFromJS.get('permissions').toJS()) : null
    // return response
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  // _refreshControl = async () => {
  //   await util.setStatePromise(this, { refreshControl: true })
  //   const response = await this._getPermissionList()
  //   await util.setStatePromise(this, {
  //     permissions: response,
  //   })
  //   await util.setStatePromise(this, { refreshControl: false })
  // }

  // eslint-disable-next-line react/no-unused-class-component-methods
  _checkFetchPermisstion = async () => {
    const { fetchPermissions } = this.state
    if (this.props.navigation.isFocused()) {
      if (fetchPermissions) {
        const response = await this._getPermissionList()
        await util.setStatePromise(this, { fetchPermissions: false, permissions: response })
      }
    }
  }

  _callNewReponee = async () => {
    const response = await this._getPermissionList()
    await util.setStatePromise(this, {
      permissions: response,
    })
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  _callBackCreateGroupView = (backToView?: boolean) => {
    const { reRander } = this.state
    const newVul = !reRander
    if (!_.isNil(backToView) && backToView) {
      util.setStatePromise(this, { reRander: newVul })
    } else if (!_.isNil(backToView) && !backToView) {
      this._callNewReponee()
    } else {
      // util.setStatePromise(this, { fetchPermissions: true })
      this._callNewReponee()
    }
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  _callBackMemberView = (backToView?: boolean) => {
    const { reRander } = this.state
    if (!_.isNil(backToView)) {
      util.setStatePromise(this, { reRander: !reRander })
    } else {
      // util.setStatePromise(this, { fetchPermissions: true })
      this._callNewReponee()
    }
  }
}
