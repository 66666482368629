import React from 'react'
import Box from 'xui/components/Box'
import * as util from 'x/utils/util'
import XText from 'xui/components/XText'
import { COLORS, STYLES } from 'x/config/styles'
import BaseUICompleteSalesReportView from 'xui/modules/report/BaseUICompleteSalesReportView'
import * as WUtil from '../../utils/util-web'
import DateRangePicker from '../../components/DateRangePicker'

require('moment-timezone')

const { FONT_SIZE_SMALLER } = STYLES

const { BG_LIGHT_GREY_ALTERNATIVE, TEXT_INACTIVE, APP_MAIN } = COLORS

export default class CompleteSalesReportView extends BaseUICompleteSalesReportView {
  static displayName = 'ReportShippingView'

  _renderShippingDateRange = () => {
    const isVisible = this._isVisibleDateRangePicker()
    const isDisabledDateRange = this._computeAllowDays() <= 1
    return isVisible ? (
      <Box
        mt='3'
        style={{
          flexDirection: 'column',
          marginBottom: 12,
          paddingHorizontal: 12,
          backgroundColor: isDisabledDateRange ? BG_LIGHT_GREY_ALTERNATIVE : 'transparent',
        }}>
        <DateRangePicker
          disabled={isDisabledDateRange}
          allowOnlyDaysFromToday={this.state.allowDays}
          allowDateRangeLimitDays={this.state.limitDay}
          selectedDates={this.state.selectedDateRange}
          onChangeDate={this._onChangeDateRange}
        />
        {isDisabledDateRange ? (
          <XText
            style={{
              // fontSize: FONT_SIZE_SMALLER,
              color: TEXT_INACTIVE,
              textAlign: 'center',
            }}>
            {this.TXT_WARNING_DATE_RANGE}
          </XText>
        ) : (
          <XText
            style={{
              // fontSize: FONT_SIZE_SMALLER,
              color: APP_MAIN,
              textAlign: 'center',
            }}>
            {this.state.dateRangeDescription}
          </XText>
        )}
      </Box>
    ) : (
      <Box />
    )
  }

  _handleOnDownloadFileByPlatform = async (url, fileName) => {
    await util.setStatePromise(this, { isLoading: true })
    await WUtil.downloadFileFromUrl({ url, fileName })
    await util.setStatePromise(this, { isLoading: false })
  }
}
