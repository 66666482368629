import React, { forwardRef, memo } from 'react'
import { IIconButtonProps } from 'native-base'
import { VariantType } from 'native-base/lib/typescript/components/types'
import theme from 'x/config/nativebase/theme'
import { getComputedCustomStyledProps } from 'x/config/nativebase/makeStyledComponent'
import { StyleSheet, TouchableOpacity } from 'react-native'
import XIcon, { XIconFamily } from './XIcon'
// import XBlankButton from './XBlankButton'

// type IXIconButtonProps = Omit<IIconButtonProps, 'icon'> & {
//   name: string
//   family?: XIconFamily
//   iconVariant?: VariantType<'Icon'>
//   variant?: VariantType<'IconButton'>
// }

// const StyledTouchableOpacity = makeStyledComponent(TouchableOpacity, 'IconButton')

interface IXIconButtonProps extends IIconButtonProps {
  name: string
  family?: XIconFamily
  iconVariant?: VariantType<'Icon'>
  variant?: VariantType<'IconButton'>
}

const XIconButton = forwardRef((props, ref) => {
  // console.log('XIconButton props => ', props)
  // @ts-ignore

  // console.log('XIconButton props => ', props)

  const { styleFromProps, underscoreProps, restProps, style = {} } = getComputedCustomStyledProps(props, 'IconButton')
  const { _icon = {} } = underscoreProps

  const {
    // disabled = false,
    // @ts-ignore
    name,
    // @ts-ignore
    family,
    // @ts-ignore
    // variant = 'ghost',
    // @ts-ignore
    iconVariant,
    // @ts-ignore
    // _icon = {},
    // ...restProps
    // @ts-ignore
    // style = {},
    ...restRNProps
  } = restProps

  // let computedIconVariant = iconVariant
  // if (!computedIconVariant) {
  //   switch (variant) {
  //     case 'solid':
  //       computedIconVariant = 'activeDark'
  //       break
  //     case 'ghost':
  //       computedIconVariant = 'activeDark'
  //       break
  //     case 'outline':
  //       computedIconVariant = 'primary'
  //       break
  //     default:
  //       computedIconVariant = 'primary'
  //   }
  // }

  return (
    // @ts-ignore
    <TouchableOpacity
      // @ts-ignore
      ref={ref}
      // disabled={disabled}
      //  isDisabled={disabled}
      {...restRNProps}
      style={[styleFromProps, StyleSheet.flatten(style)]}>
      <XIcon {..._icon} variant={iconVariant} name={name} family={family} />
    </TouchableOpacity>
  )

  // return (
  //   <IconButton
  //     disabled={disabled}
  //     isDisabled={disabled}
  //     icon={
  //       // <Box alignSelf='center' alignItems='center' justifyContent='center'>
  //       <XIcon variant={iconVariant} name={name} family={family} />
  //       // </Box>
  //     }
  //     // borderRadius='full'
  //     // _hover={{
  //     //   bg: 'primary.600:alpha.20',
  //     // }}
  //     {...restProps}
  //   />
  // )
})

// XIconButton.defaultProps = {
//   family: 'Ionicons',
// }
XIconButton.displayName = 'XIconButton'
// @ts-ignore
XIconButton.defaultProps = { family: 'Ionicons', theme }
// XIconButton.defaultProps = {
//   // @ts-ignore
//   family: 'Ionicons',
//   variant: 'ghost',
// }

// const StyledXIconButton = makeStyledComponent(XIconButton, 'IconButton')

export default memo(XIconButton) as React.FC<IXIconButtonProps>
