import React from 'react'
import { View, Text } from 'react-native'
import BaseUICustomerView from 'xui/modules/customer/BaseUICustomerView'
import { getConfig } from 'x/config/mode'
import { COLORS, STYLES } from 'x/config/styles'
import _ from 'lodash'
import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'
import { IProfile } from 'x/index'
import Modal from 'react-modal'
import { Button } from 'react-native-elements'
import ImgManager from '../../components/ImgManager'
import ThemeSizeContext from '../../context/ThemeSizeContext'
import { downloadFileFromUrl } from '../../utils/util-web'

const config = getConfig()

export default class CustomerView extends BaseUICustomerView {
  static contextType = ThemeSizeContext

  context!: React.ContextType<typeof ThemeSizeContext>

  renderContractImg = () => {
    const txtPrefixImageName = config.s3_prefix_type_org_register
    const { isHasImgDocument, isEditModeImgDocument } = this.state
    return (
      <View>
        <ImgManager
          // ref={this._onImgManagerRef}
          ref={this.imgManagerDocumentRef}
          fileNamePrefix={txtPrefixImageName}
          metadata={[
            { key: 'fc', width: 1600, height: 1600 },
            { key: 'tc', width: 128, height: 128 },
          ]}
          uploading={_.has(this.state, 'isImgDocumentUploading') ? this.state.isImgDocumentUploading : false}
          // readonly={_.has(this.state, 'isImgDocumentUploading') ? this.state.isImgDocumentUploading : false}
          readonly={!isEditModeImgDocument}
          showUploaded
          maxLength={10}
          // displayFullSize={true}
          hide={!isHasImgDocument}
        />
      </View>
    )
  }

  navToEditProfile = () => {
    const { profile } = this.state
    this.props.navigation.push('CustomerEditProfileViewContainer', {
      isModal: true,
      profile,
      callBackFromEdit: (profileCallBack: IProfile) => this._callBackFromEdit(profileCallBack),
    })
  }

  navToDealerView = () => {
    const { navigation } = this.props
    const { referalReseller } = this.state
    if (_.isNil(referalReseller)) {
      return null
    }
    if (_.isNil(referalReseller.profile_id)) {
      p.op.showConfirmationOkOnly(``, `ไม่พบข้อมูลแม่ทีม กรุณาทำรายการใหม่ภายหลัง`)
      return
    }

    navigation.push('CustomerViewContainer', {
      profile: null,
      key: referalReseller.profile_id,
      isApproved: referalReseller.is_approved,
      // x: Math.random(),
    })
  }

  goBack = () => {
    util.navGoBack(this.props)
  }

  renderViewImgManager = () => {
    const { isImgUploading, mode } = this.state
    const { contentOneOfThreeColumnWidth } = this.context
    const widthForImg = contentOneOfThreeColumnWidth / 3
    const canEdit = mode === `VIEW`
    return (
      <View style={{ width: '100%', height: 110, justifyContent: 'center', alignItems: 'center', backgroundColor: COLORS.WHITE }}>
        <View style={{ width: widthForImg }} />
        <View style={{ width: widthForImg, justifyContent: 'center', alignItems: 'center', marginLeft: 20 }}>
          <ImgManager
            ref={this.imgManagerRef}
            fileNamePrefix={config.s3_prefix_type_profile}
            metadata={[
              { key: 'p', width: 2000, height: 2000 },
              { key: 't', width: 1000, height: 1000 },
            ]}
            uploading={_.has(this.state, 'isImgUploading') ? isImgUploading : false}
            readonly={canEdit}
            // uploading={true}
            showUploaded
            maxLength={1}
            shape='circle'
            disabledRemoveSingleImage
          />
        </View>
        <View style={{ width: widthForImg }} />
      </View>
    )
  }

  openModelChangeDealerLoading = () => {
    const customStyles = {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: 340,
      height: 150,
    }
    // console.log(`openModelChangeDealerLoading `, this.state.isChangeDealerLoading)
    return (
      <Modal
        isOpen={this.state.isChangeDealerLoading}
        // onAfterOpen={this.afterOpenModal}
        onRequestClose={this._closeModalChangeDealerLoading}
        style={{ content: customStyles }}
        contentLabel='Example Modal'>
        <View style={{ width: '100%', justifyContent: 'center', alignItems: 'center', height: 150 }}>
          <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE }}>{this.state.loadingText}</Text>
          {/* <Text style={{ fontSize: STYLES.FONT_SIZE_SMALLER, color: COLORS.TEXT_ACTIVE }}>
            {`(ระบบอาจใช้เวลาประมาณ 40 วินาที ต่อ 1 บัญชี)`}
          </Text> */}
          <Button
            // title="Loading button"
            loading
            disabled
            type='clear'
          />
        </View>
      </Modal>
    )
  }

  openModelChangeMemberType = () => {
    const customStyles = {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: 380,
      height: 200,
    }
    const { resellerInfo, profile } = this.state
    if (_.isNil(resellerInfo) || _.isNil(profile)) {
      return null
    }

    return (
      <Modal
        isOpen={this.state.isModelChangeMemberType}
        // onAfterOpen={this.afterOpenModal}
        onRequestClose={this._closeModalChangeMemberType}
        style={{ content: customStyles }}
        contentLabel='Example Modal'>
        {this._renderBodyModelChangeMemberType()}
      </Modal>
    )
  }

  openFileFromUrl = async (url: string, fileName: string, type: string) => {
    await downloadFileFromUrl({ url, fileName })
  }
}

// return (
//   <XContainer>
//     <XCustomHeader title={'ProfileView'} headerLeftProps={{ backIcon: true, onPressItem: () => navigation.goBack(navigation.state.key) }} />
//     <XContent style={{ backgroundColor: 'skyblue' }}>
//       <h2>ProfileView</h2>
//       <button onClick={() => {
//         navigation.push('ProfileView', {
//           x: Math.random(),
//         })
//       }}>Next ProfileView</button>
//       <button onClick={() => {
//         navigation.push('ProfileView', {
//           isModal: true,
//           x: Math.random(),
//         })
//       }}>Next ProfileView</button>
//       <button onClick={() => navigation.goBack(null)}>Go BACK</button>
//       <div>{`${JSON.stringify(navigation.state)}`}</div>
//     </XContent>
//   </XContainer>
// )
