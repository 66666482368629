import BaseUISettingView from 'xui/modules/setting/BaseUISettingView'

// const {
//   LoginManager,
// } = FBSDK

export default class SettingView extends BaseUISettingView {
  static displayName = 'SettingView'

  constructor(props) {
    super(props)
    this.state = {
      refreshing: false,
    }
  }
}
