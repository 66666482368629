import { connect } from 'react-redux'
// import * as BankActions from 'x/modules/bank/BankState'
import { getSelectedStore } from 'x/redux/selectors'
import BankEditView from './BankEditView'

export default connect(
  (state) => ({
    accountStores: state.getIn(['bank', 'stores']),
    editingAccount: state.getIn(['bank', 'editingAccount']),
    selectedAccount: state.getIn(['bank', 'selectedAccount']),
    selectedStore: getSelectedStore(state),
  }),
  (dispatch) => ({
    // bankActions: bindActionCreators(BankActions, dispatch),
    // loadEditingAccountFromStore: bindActionCreators(BankActions.loadEditingAccountFromStore, dispatch),
  })
)(BankEditView)
