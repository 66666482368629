import React from 'react'
import { StyleSheet, Dimensions, Platform } from 'react-native'
import { getStyleAndFilteredProps, propConfig } from 'x/config/nativebase/styled-system'
import { cloneDeep, get, isArray, isEmpty, isNil, merge, omitBy } from 'lodash'
import theme, { IThemeType } from './theme'

type IThemeKey = keyof IThemeType['components']
const windowWidth = Dimensions.get('window').width

export const getStyledSystemPropsAndRestProps = (props: any) => {
  const styledSystemProps: any = {}
  const restProps: any = {}

  for (const key in props) {
    if (key in propConfig) {
      styledSystemProps[key] = props[key]
    } else {
      restProps[key] = props[key]
    }
  }

  return { styledSystemProps, restProps }
}

export const getBaseStyleProps = (themeKey: IThemeKey, inputProps) => {
  const props = inputProps || {}
  const compTheme: any = theme.components[themeKey]
  // console.log(`${themeKey}::getBaseStyleProps compTheme =>  `, compTheme)

  // @ts-ignore
  if (!compTheme || !compTheme.baseStyle) {
    return {}
  }

  // const { theme, colorScheme } = props
  // if (!props.theme || !props.colorScheme || !props.theme.colors || !props.theme.colors[props.colorScheme]) {
  //   return {}
  // }

  const colorScheme = props.colorScheme || get(compTheme, 'defaultProps.colorScheme')
  // console.log(`${themeKey}::getBaseStyleProps colorScheme =>  `, colorScheme)

  if (!colorScheme) {
    return {}
  }
  const colorModeProps = { colorMode: 'light' }
  // const extractProps = typeof compTheme.baseStyle === 'function' ? compTheme.baseStyle({ theme, ...props }) : compTheme.baseStyle
  // @ts-ignore
  const extractProps =
    typeof compTheme.baseStyle === 'function'
      ? compTheme.baseStyle({ theme, ...props, colorScheme, ...colorModeProps })
      : compTheme.baseStyle
  // console.log(`${themeKey}::getBaseStyleProps extractProps =>  `, extractProps)
  return resolveInternalProps(extractProps)
  // return extractProps
}

export const getComponentDefaultProps = (themeKey: IThemeKey) => {
  if (!themeKey) {
    return {}
  }
  const comp: any = theme.components[themeKey]
  const defaultProps = comp.defaultProps || {}

  return resolveInternalProps(defaultProps)
  // return defaultProps

  // // const defaultBaseStyleProps = getBaseStyleProps(themeKey, defaultProps)

  // // if (!comp || !comp.defaultProps) {
  // //   return {}
  // // }
  // // const variant = comp.defaultProps.variant
  // // const defaultProps = comp.defaultProps
  // const variantProps = getVariantProps(themeKey, defaultProps)
  // // return { ...defaultProps, ...variantProps }
  // // return { ...defaultBaseStyleProps, ...variantProps }
  // return resolveInternalProps(variantProps)
}

export const resolveInternalProps = (cp: any = {}) => {
  if (isNil(cp) || isEmpty(cp)) {
    return {}
  }
  let newProps = cloneDeep(cp)
  // const _props = Object.keys(cp).filter((propName) => propName.startsWith('_'))

  // TODO: ทำ dark theme ตรงนี้ด้วย
  // if (cp._dark && !isEmpty(cp._dark)) {
  //   newProps = merge(cp._dark, newProps)
  //  // delete newProps._dark
  // }

  // current props
  if (cp._light && !isEmpty(cp._light)) {
    newProps = merge(newProps, cp._light)
    // newProps = { ...newProps, ...cp._light }
    delete newProps._light
  }

  if (cp._ios && !isEmpty(cp._ios) && Platform.OS === 'ios') {
    newProps = merge(newProps, cp._ios)
    // newProps = { ...newProps, ...cp._ios }
    delete newProps._ios
  }

  if (cp._android && !isEmpty(cp._android) && Platform.OS === 'android') {
    newProps = merge(newProps, cp._android)
    // newProps = { ...newProps, ...cp._android }
    delete newProps._android
  }

  if (cp._web && !isEmpty(cp._web) && Platform.OS === 'web') {
    newProps = merge(newProps, cp._web)
    // newProps = { ...newProps, ...cp._web }
    delete newProps._web
  }

  return newProps
}

export function extractUnderscoreProps(props: any) {
  const newProps: any = {}
  const _props = Object.keys(props).filter((propName) => propName.startsWith('_'))
  _props.forEach((propName: string) => {
    newProps[propName] = resolveInternalProps({ ...props[propName] })
  })
  return newProps
}

// export function mergeUnderscoreProps(baseProps: any = {}, incomingProps: any = ) {
//   const newProps = cloneDeep(baseProps)
//   const _props = Object.keys(newProps).filter((propName) => propName.startsWith('_'))
//   _props.forEach((propName: string) => {
//     newProps[propName] = merge(newProps[propName], incomingProps[propName])
//   })
//   return newProps
// }

// export function mergeUnderscoreProps(newProps: any, props: any) {
//   const _props = Object.keys(newProps).filter((propName) => propName.startsWith('_'))
//   _props.forEach((propName: string) => {
//     // // Adding color based on bg contrast if no color is given
//     // const bg = newProps.bg ?? newProps.backgroundColor;
//     // const textColor = bg
//     //   ? {
//     //       color: useContrastText(
//     //         bg,
//     //         newProps[propName]?.color ?? props[propName]?.color
//     //       ),
//     //     }
//     //   : {};
//     // Overriding calculated props with user added props
//     // @ts-ignore
//     newProps[propName] = {
//       // ...textColor,
//       ...newProps[propName],
//       ...props[propName],
//     }
//   })
//   return newProps
// }

export const getVariantProps = (themeKey: IThemeKey, props) => {
  // if (themeKey === 'Text') {
  //   console.log(`${themeKey}::makeStyledComponent:: getVariantProps props => `, props)
  // }
  const pp = props || {}
  // if (!themeKey || !props || !props.variant) {
  if (!themeKey) {
    // return getBaseStyleProps(themeKey, props)
    return {}
  }
  // const { variant } = props
  const compTheme: any = theme.components[themeKey]
  const variant = pp.variant || get(compTheme, 'defaultProps.variant')

  if (!compTheme || !variant || !compTheme.variants || !compTheme.variants[variant]) {
    // return getBaseStyleProps(themeKey, props)
    return {}
  }
  const colorScheme = pp.colorScheme || get(compTheme, 'defaultProps.colorScheme')
  // if (!colorScheme) {
  //   return {}
  // }
  const colorModeProps = { colorMode: 'light' }
  let varintProps
  if (typeof compTheme.variants[variant] === 'function') {
    varintProps = compTheme.variants[variant]({ ...props, colorScheme, theme, ...colorModeProps })
  } else {
    varintProps = compTheme.variants[variant]
  }

  // console.log(`${themeKey}::getVariantProps compTheme.variants[${variant}] => `, varintProps)
  // console.log(`getVariantProps compTheme.variants[${variant}] => `, resolveInternalProps(varintProps))

  // console.log(`${themeKey}::makeStyledComponent:: getVariantProps varintProps => `, varintProps)

  return resolveInternalProps(varintProps)
  // return varintProps
}

export const getInternalComponentSizeProps = (themeKey: IThemeKey, props) => {
  if (!themeKey || !props) {
    return {}
  }

  const compTheme: any = theme.components[themeKey]
  if (!compTheme || !compTheme.sizes) {
    return {}
  }

  const size = props.size || get(compTheme, 'defaultProps.size')

  if (!compTheme.sizes[size]) {
    return {}
  }

  const sizeProps = compTheme.sizes[size]
  // console.log(`getInternalComponentSizeProps ${themeKey} sizeProps => `, sizeProps)
  return resolveInternalProps(sizeProps)
  // return sizeProps
}

// export const computeStyledProps = (themeKey, props) => {
//   let computedProps = {}

//   // Extracting props from defaultProps
//   computedProps = filterDefaultProps(props, getComponentDefaultProps(themeKey))
//   console.log(`${themeKey}::makeStyledComponent:: (1) defaultProps => `, computedProps)

//   // Extracting props from base style
//   const baseStyleProps = getBaseStyleProps(themeKey, props)
//   console.log(`${themeKey}::makeStyledComponent:: (2) baseStyleProps => `, baseStyleProps)
//   computedProps = mergeWith(
//     computedProps,
//     baseStyleProps,
//     // @ts-ignore
//     (objValue, srcValue, key) => {
//       if (!isNil(objValue)) {
//         delete computedProps[key]
//       }
//     }
//   )

//   const variantProps = getVariantProps(themeKey, computedProps)
//   console.log(`${themeKey}::makeStyledComponent:: (3) variantProps => `, variantProps)

//   computedProps = mergeWith(
//     computedProps,
//     variantProps,
//     // @ts-ignore
//     (objValue, srcValue, key) => {
//       if (!isNil(objValue)) {
//         delete computedProps[key]
//       }
//     }
//   )

//   // @ts-ignore
//   delete computedProps.variant
//   // @ts-ignore
//   delete computedProps.colorScheme

//   console.log(`${themeKey}::makeStyledComponent:: (4) computedProps (default+baseStyle+variant) => `, computedProps)

//   // Extracting props from normal props
//   const extractedProps = extractProps(props, theme, null, theme.components[themeKey], 0)
//   console.log(`${themeKey}::makeStyledComponent:: (5) extractedProps (normal props) => `, extractedProps)

//   // @ts-ignore
//   computedProps = mergeWith(computedProps, extractedProps, (objValue, srcValue, key) => {
//     if (!isNil(objValue)) {
//       delete computedProps[key]
//     }
//   })

//   console.log(`${themeKey}::makeStyledComponent:: (6) computedProps (computed+normal) => `, computedProps)

//   return computedProps
// }

export const getComputedStyledPropsFromProps = (baseProps: any, themeKey: IThemeKey = 'Box') => {
  // if (themeKey === 'Input' && !isEmpty(baseProps.style)) {
  //   console.log(`${themeKey}::getComputedStyledPropsFromProps::baseProps => `, baseProps)
  // }
  const { styledSystemProps, restProps: props } = getStyledSystemPropsAndRestProps(baseProps)
  // @ts-ignore
  const { style = {}, variant, colorScheme, ...restRNProps } = props
  // console.log(`${themeKey}::makeStyledComponent::restRNProps => `, restRNProps)

  // console.log('StyledComp props => ', props)
  // const baseStyle = getBaseStyleProps(themeKey, props)

  // @ts-ignore
  // const calculatedProps = calculateProps(props.theme, { colorMode: 'light' }, theme.components[themeKey], props, 300)
  // console.log(`${themeKey}::makeStyledComponent::calculatedProps => `, calculatedProps)

  // let computedProps = {}
  // // Extracting props from defaultProps
  // computedProps = filterDefaultProps(props, getComponentDefaultProps(themeKey))
  // console.log(`${themeKey}::makeStyledComponent:: (1) defaultProps => `, computedProps)

  // // Extracting props from base style
  // const baseStyleProps = getBaseStyleProps(themeKey, props)
  // console.log(`${themeKey}::makeStyledComponent:: (2) baseStyleProps => `, baseStyleProps)
  // computedProps = mergeWith(
  //   computedProps,
  //   baseStyleProps,
  //   // @ts-ignore
  //   (objValue, srcValue, key) => {
  //     if (!isNil(objValue)) {
  //       delete computedProps[key]
  //     }
  //   }
  // )

  // const variantProps = getVariantProps(themeKey, computedProps)
  // console.log(`${themeKey}::makeStyledComponent:: (3) variantProps => `, variantProps)

  // computedProps = mergeWith(
  //   computedProps,
  //   variantProps,
  //   // @ts-ignore
  //   (objValue, srcValue, key) => {
  //     if (!isNil(objValue)) {
  //       delete computedProps[key]
  //     }
  //   }
  // )

  // // @ts-ignore
  // delete computedProps.variant
  // // @ts-ignore
  // delete computedProps.colorScheme

  // console.log(`${themeKey}::makeStyledComponent:: (4) computedProps (default+baseStyle+variant) => `, computedProps)

  // // Extracting props from normal props
  // const extractedProps = extractProps(props, theme, null, theme.components[themeKey], 0)
  // console.log(`${themeKey}::makeStyledComponent:: (5) extractedProps (normal props) => `, extractedProps)

  // // @ts-ignore
  // computedProps = mergeWith(computedProps, extractedProps, (objValue, srcValue, key) => {
  //   if (!isNil(objValue)) {
  //     delete computedProps[key]
  //   }
  // })

  // console.log(`${themeKey}::makeStyledComponent:: (6) computedProps (computed+normal) => `, computedProps)

  // const { styledSystemProps, restProps } = getStyledSystemPropsAndRestProps(props)

  // const internalSizeProps = getInternalComponentSizeProps(themeKey, styledSystemProps)
  // const internalSizeProps = getInternalComponentSizeProps(themeKey, props)
  //  @ts-ignore
  // const { size, ...restStyledWithoutSizeProps } = styledSystemProps
  // const systemdInputProps = size ? restStyledWithoutSizeProps : styledSystemProps

  // @ts-ignore
  // const { size, ...restWithoutSizeProps } = props
  // @ts-ignore
  // const systemdInputProps = size && internalSizeProps && !isEmpty(internalSizeProps) ? restWithoutSizeProps : props
  // const variantProps = getVariantProps(themeKey, props)

  // // const internalProps = props._light || {}
  // let mergedStyleProps = filterDefaultProps(props, getComponentDefaultProps(themeKey))
  const defaultProps = getComponentDefaultProps(themeKey)
  // let mergedStyleProps = merge(defaultProps, styledSystemProps)
  let mergedStyleProps = { ...defaultProps }
  // if (themeKey === 'Input') {
  //   console.log(`${themeKey}::getBaseStyleProps defaultProps =>  `, defaultProps)
  //   const x = merge(defaultProps, omitBy({ variant, colorScheme }, isNil))
  //   console.log(`${themeKey}::getBaseStyleProps x =>  `, x)
  // }

  const baseStyleProps = getBaseStyleProps(themeKey, merge(baseProps, omitBy({ variant, colorScheme }, isNil)))
  // const baseStyleProps = getBaseStyleProps(themeKey, defaultProps)
  // if (themeKey === 'Input') {
  //   console.log(`${themeKey}::getBaseStyleProps baseStyleProps =>  `, baseStyleProps)
  // }
  mergedStyleProps = merge(mergedStyleProps, baseStyleProps)

  const internalSizeProps = getInternalComponentSizeProps(themeKey, baseProps)
  // delete mergedStyleProps.size
  // mergedStyleProps = merge(mergedStyleProps, restStyledWithoutSizeProps)
  mergedStyleProps = merge(mergedStyleProps, internalSizeProps)

  const variantProps = getVariantProps(themeKey, merge(mergedStyleProps, omitBy({ variant, colorScheme }, isNil)))
  mergedStyleProps = merge(mergedStyleProps, variantProps)
  // if (themeKey === 'Text') {
  //   console.log(`${themeKey}::getBaseStyleProps variantProps =>  `, variantProps)
  //   console.log(`${themeKey}::getBaseStyleProps mergedStyleProps =>  `, mergedStyleProps)
  // }
  // mergedStyleProps = merge(mergedStyleProps, variantProps)
  // delete mergedStyleProps.variant

  mergedStyleProps = merge(mergedStyleProps, styledSystemProps)
  delete mergedStyleProps.size

  // if (themeKey === 'Text') {
  //   console.log(`${themeKey}::makeStyledComponent:: styledSystemProps => `, styledSystemProps)
  //   console.log(`${themeKey}::makeStyledComponent:: mergedStyleProps => `, mergedStyleProps)
  // }

  // const { styledSystemProps, restProps } = getStyledSystemPropsAndRestProps(resolveInternalProps(mergedStyleProps))
  // // const { styledSystemProps, restProps } = getStyledSystemPropsAndRestProps(
  // //   resolveInternalProps({
  // //     ...internalSizeProps,
  // //     ...variantProps,
  // //     ...systemdInputProps,
  // //   })
  // // )

  // (systemdInputProps)
  // const computedStyledSystemProps = resolveInternalProps({
  //   // ...baseStyle,
  //   ...internalSizeProps,
  //   ...variantProps,
  //   ...styledSystemProps,
  // })

  // let computedMergedProps = mergeUnderscoreProps(baseStyle, variantProps)
  // computedMergedProps = mergeUnderscoreProps(computedMergedProps, internalSizeProps)
  // computedMergedProps = mergeUnderscoreProps(computedMergedProps, styledSystemProps)

  // const computedStyledSystemProps = resolveInternalProps({
  //   ...baseStyle,
  //   ...variantProps,
  //   ...internalSizeProps,
  //   ...styledSystemProps,
  //   //
  // })

  const extractedMergedUnderscoreStyleProps = extractUnderscoreProps(mergedStyleProps)
  const extractedParentUnderscoreStyleProps = extractUnderscoreProps(restRNProps)
  const mergedUnderscoreProps = merge(extractedMergedUnderscoreStyleProps, extractedParentUnderscoreStyleProps)

  // if (themeKey === 'Button') {
  //   console.log(`${themeKey}::makeStyledComponent:: props => `, props)
  //   console.log(`${themeKey}::makeStyledComponent:: extractedMergedUnderscoreStyleProps => `, extractedMergedUnderscoreStyleProps)
  //   console.log(`${themeKey}::makeStyledComponent:: extractedParentUnderscoreStyleProps => `, extractedParentUnderscoreStyleProps)
  //   console.log(`${themeKey}::makeStyledComponent:: mergedUnderscoreProps => `, mergedUnderscoreProps)
  // }

  let computedStyledProps = resolveInternalProps(merge(mergedStyleProps, omitBy(mergedUnderscoreProps, isNil)))
  // let computedStyledProps = resolveInternalProps(merge(mergedStyleProps, omitBy(extractedParentUnderscoreStyleProps, isNil)))

  if (computedStyledProps._disabled && !isEmpty(computedStyledProps._disabled) && restRNProps.isDisabled) {
    computedStyledProps = merge(computedStyledProps, computedStyledProps._disabled)
    // delete computedStyledProps._disabled
  }

  // if (themeKey === 'Button') {
  //   console.log(`${themeKey}::makeStyledComponent:: computedStyledProps => `, computedStyledProps)
  // }
  return { computedStyledProps, underscoreProps: mergedUnderscoreProps, restProps: restRNProps }
}

export const getComputedCustomStyledProps = (props: any, themeKey: IThemeKey = 'Box') => {
  const { style } = props
  const { computedStyledProps, underscoreProps, restProps } = getComputedStyledPropsFromProps(props, themeKey)
  // const { _text = {}, _icon = {}, _stack = {} } = underscoreProps

  const { styleFromProps } = getStyleAndFilteredProps({
    styledSystemProps: computedStyledProps,
    theme: props && props.theme ? props.theme : theme,
    currentBreakpoint: 0,
  })

  return {
    styleFromProps,
    computedStyledProps,
    underscoreProps,
    restProps,
    style,
  }
}

const styleMap = new Map()

const makeStyledComponent = (Comp, themeKey?: IThemeKey) => {
  const StyledComp = React.forwardRef((props, ref) => {
    // @ts-ignore
    const { style } = props
    const { computedStyledProps, restProps } = getComputedStyledPropsFromProps(props, themeKey)

    // const { styledSystemProps, restProps } = getStyledSystemPropsAndRestProps(props)
    // const { style = {}, ...restRNProps } = restProps
    // const key = stableHash(computedStyledSystemProps)
    // const key = stableHash({ ...computedStyledProps, variant, colorScheme })
    // const key = stableHash(computedStyledProps)
    const { styleFromProps } = getStyleAndFilteredProps({
      styledSystemProps: computedStyledProps,
      // styledSystemProps,
      theme,
      // currentBreakpoint: windowWidth,
      currentBreakpoint: 0,
    })
    // if (!styleMap.has(key)) {
    //   styleMap.set(key, StyleSheet.create({ box: styleFromProps }))
    //   // styleMap.set(key, { box: styleFromProps })
    // }

    // const systemdStyle = styleMap.get(key)
    // return <Comp ref={ref} style={[systemdStyle.box, StyleSheet.flatten(style)]} {...restRNProps} />
    return (
      <Comp
        ref={ref}
        {...computedStyledProps}
        style={[styleFromProps, isArray(style) ? StyleSheet.flatten(style) : style]}
        // {...restRNProps}
        {...restProps}
        // {...mergedUnderscoreProps}
      />
    )
  })

  // @ts-ignore
  // StyledComp.defaultProps = { colorScheme: 'primary', theme, ...getComponentDefaultProps(themeKey) }
  // StyledComp.defaultProps = { colorScheme: 'primary', theme }
  StyledComp.defaultProps = { theme }

  return StyledComp
}

export default makeStyledComponent
