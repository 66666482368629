// import EntypoIcon from 'react-native-vector-icons/Entypo'

// import BackIcon from '../../components/BackIcon'
// import XTabNavIcon from '../../components/XTabNavIcon'

import BaseUISubscriptionView from 'xui/modules/subscription/BaseUISubscriptionView'

export default class SubscriptionView extends BaseUISubscriptionView {
  static displayName = 'SubscriptionView'
}
