import React from 'react'

import BaseUIAddCoverSheetNote from 'xui/modules/order/BaseUIAddCoverSheetNote'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import ThemeSizeContext from '../../context/ThemeSizeContext'

export default class AddCoverSheetNote extends BaseUIAddCoverSheetNote {
  static displayName = 'AddCoverSheetNote'

  static contextType = ThemeSizeContext

  context!: React.ContextType<typeof ThemeSizeContext>

  // Override
  renderMain = () => {
    const { contentOneOfThreeColumnWidthStyle, contentHeightStyle } = this.context
    return (
      <XContainer>
        {this.renderHeader()}
        <XContent>{this.renderContent()}</XContent>
      </XContainer>
    )
  }

  getFixedWebLayoutTextLimitWidth = () => this.context.contentOneOfThreeColumnWidth - 120
}
