import BaseUIBankEditView from 'xui/modules/store/storeSetting/BaseUIBankEditView'

// import BaseBankView from './BaseBankView'

class BankEditView extends BaseUIBankEditView {
  static displayName = 'BankEditView'

  static navigationOptions = {
    header: null,
    title: null,
    headerLeft: null,
    headerRight: null,
  }

  // static propTypes = {
  //   editingAccount: PropTypes.object.isRequired,
  //   accountStores: PropTypes.object.isRequired,

  //   navigation: PropTypes.object.isRequired,
  //   loadEditingAccountFromStore: PropTypes.func.isRequired,
  // }

  // static navigationOptions = ({ navigation }) => {
  //   const { goBack, state } = navigation
  //   const { handleBtnRight } = state.params
  //   return {
  //     headerLeft: (
  //       <Button onPress={() => goBack(state.key)}>
  //         <BackIcon />
  //       </Button>
  //     ),
  //     title: 'แก้ไขบัญชี',
  //     headerRight: handleBtnRight ? (
  //       <Button onPress={() => handleBtnRight()}>
  //         <Text style={{ color: COLORS.APP_MAIN, marginRight: 10 }}>บันทึก</Text>
  //       </Button>
  //     ) : null,
  //   }
  // }

  // Life cycle Zone
  // componentWillMount() {
  //   const { navigation, accountStores, loadEditingAccountFromStore } = this.props
  //   const { state } = navigation
  //   util.log('BankEditView componentWillMount state: ', state)
  //   if (state && state.params) {
  //     const { store_id, account_index } = state.params
  //     if (accountStores.getIn([store_id, account_index, 'id'])) {
  //       loadEditingAccountFromStore({ store_id, account_index })
  //     }
  //   }
  // }

  // @Override
  // componentDidMount() {
  //   this.props.navigation.setParams({
  //     handleBtnRight: () => this._handleSubmitButton(() => this._doEditAccount()),
  //   })
  // }

  // _doEditAccount() {
  //   // MUST NOT check for this.inProcess here as it's already been handled by base case
  //   const { bankActions, selectedAccount, editingAccount, navigation } = this.props
  //   const { state } = navigation
  //   const payment_account_id = editingAccount.get('id') || null
  //   if (!payment_account_id) {
  //     Alert.alert('ไม่พบรหัสบัญชี', 'กรุณากลับไปเลือกบัญชีใหม่ ขออภัยในความไม่สะดวก', [{ text: 'ตกลง', onPress: this._clearInProcess }])
  //     navigation.goBack(state.key)
  //     return
  //   }
  //   const store_id = state.params ? state.params.store_id : null
  //   if (_.isNull(store_id) || _.isUndefined(store_id)) {
  //     Alert.alert('ไม่พบร้านค้า', 'กรุณากลับไปเลือกร้านค้าแล้วลองเลือกบัญชีใหม่ ขออภัยในความไม่สะดวก', [
  //       { text: 'ตกลง', onPress: this._clearInProcess },
  //     ])
  //     navigation.goBack(state.key)
  //     return
  //   }

  //   const body = {}
  //   if (!_.isNil(selectedAccount)) {
  //     util.appendIfImmutableDataChange(selectedAccount, editingAccount, body, 'name', 'string')
  //     util.appendIfImmutableDataChange(selectedAccount, editingAccount, body, 'account_number', 'string')
  //     util.appendIfImmutableDataChange(selectedAccount, editingAccount, body, 'bank_id', 'int')
  //   }
  //   let settingsJson = editingAccount.get('settings_json') || null
  //   let peak = null
  //   if (!_.isNil(settingsJson)) {
  //     settingsJson = isImmutable(settingsJson) ? settingsJson.toJS() : settingsJson
  //     peak = settingsJson.peak
  //   }
  //   const usePeakAccount = !_.isNil(editingAccount.get('peak_account')) ? editingAccount.get('peak_account') : !_.isNil(peak)
  //   const mainPeak = !_.isNil(peak) ? peak.main_acct_num : ''
  //   const subPeak = !_.isNil(peak) ? peak.sub_acct_num : ''
  //   if (usePeakAccount) {
  //     body.settings_json = {
  //       peak: {
  //         main_acct_num: mainPeak,
  //         sub_acct_num: subPeak,
  //       },
  //     }
  //   } else {
  //     body.settings_json = {
  //       peak: null,
  //     }
  //   }
  //   // util.log('new body: ', body)
  //   if (!_.isEmpty(body)) {
  //     body.store_id = store_id
  //     body.payment_account_id = payment_account_id

  //     // util.log('BankEditView__doEditAccount body: ', body)
  //     if (_.isNil(bankActions)) {
  //       Alert.alert('เกิดข้อผิดพลาด', `bankActions ${bankActions}`)
  //       return
  //     }
  //     bankActions.editingAccountSave(body, () => navigation.goBack(state.key))
  //   } else {
  //     // Alert.alert('เกิดข้อผิดพลาด', 'กรุณาป้อนชื่อบัญชี')
  //     p.op.showToast('กรุณาแก้ไขค่าก่อนกดบันทึก', 'warning')
  //   }
  //   // util.log('BankEditView._doEditAccount END')
  // }

  // _clearInProcess = () =>
  //   setTimeout(() => {
  //     this.inProcess = false
  //   }, 200)

  // _deleteAccount() {
  //   if (this.inProcess) {
  //     return
  //   }
  //   this.inProcess = true
  //   const { bankActions, selectedAccount, navigation, accountStores } = this.props
  //   const { state } = navigation
  //   const payment_account_id = selectedAccount.get('id') || null
  //   if (!payment_account_id) {
  //     Alert.alert('ไม่พบรหัสบัญชี', 'กรุณากลับไปเลือกบัญชีใหม่ ขออภัยในความไม่สะดวก', [{ text: 'ตกลง', onPress: this._clearInProcess }])
  //     navigation.goBack(state.key)
  //     return
  //   }

  //   const store_id = state.params ? state.params.store_id : null
  //   const { account_index } = state.params
  //   // const bankList = accountStores.getIn([store_id]).size
  //   const bankList = accountStores.get(store_id)
  //   let bankCount = 0
  //   if (List.isList(bankList)) {
  //     let nonGBCount = 0
  //     bankList.forEach((bank) => {
  //       if (bank && bank.get('bank_id') && bank.get('bank_id') !== 25) {
  //         nonGBCount += 1
  //       }
  //     })
  //     bankCount = nonGBCount
  //   }
  //   // log('bankList => ', bankList)
  //   // log('bankCount => ', bankCount)
  //   if (_.isNull(store_id) || _.isUndefined(store_id)) {
  //     Alert.alert('ไม่พบร้านค้า', 'กรุณากลับไปเลือกร้านค้าแล้วลองเลือกบัญชีใหม่ ขออภัยในความไม่สะดวก', [
  //       { text: 'ตกลง', onPress: this._clearInProcess },
  //     ])
  //     navigation.goBack(state.key)
  //     return
  //   }
  //   if (bankCount <= 1) {
  //     Alert.alert(
  //       'ไม่สามารถลบบัญชีนี้ได้',
  //       'กรุณาเหลือบัญชีไว้รับชำระอย่างน้อย 1 บัญชี',
  //       [{ text: 'ตกลง', onPress: this._clearInProcess }],
  //       { cancelable: false }
  //     )
  //     return
  //   }
  //   const body = {}
  //   body.store_id = store_id
  //   body.payment_account_id = payment_account_id
  //   // util.log('BankEditView__doEditAccount body: ', body)

  //   Alert.alert('ยืนยันการลบบัญชี', 'เมื่อลบบัญชีรับชำระคุณจะไม่สามารถใช้บัญชีนี้ได้อีก คุณตกลงลบบัญชีนี้หรือไม่', [
  //     { text: 'ตกลง', onPress: () => bankActions.editingAccountDelete(body, () => navigation.goBack(state.key)) },
  //     { text: 'ยกเลิก', onPress: this._clearInProcess, style: 'cancel' },
  //   ])

  //   // Quick Fixed android halt when canceled
  //   setTimeout(this._clearInProcess, 1000)
  // }

  // @Override
  // afterAccountInfoRender() {
  //   return (
  //     <View style={{ marginTop: 100, marginBottom: 10, flexDirection: 'row', justifyContent: 'center' }}>
  //       <Button onPress={() => this._deleteAccount()} style={[STYLES.BTN_MODE_ACTIVE_SECONDARY, { borderWidth: 1, width: 140 }]}>
  //         <Text allowFontScaling={false} style={STYLES.BTN_TEXT_MODE_ACTIVE_SECONDARY}>
  //           ลบบัญชีรับชำระนี้
  //         </Text>
  //       </Button>
  //     </View>
  //   )
  // }
}

export default BankEditView
