import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// import * as StoreState from 'x/modules/store/StoreState'
import { getSelectedStore } from 'x/redux/selectors'
import * as profileActions from 'x/modules/profile/ProfileState'
import * as AddressesActions from 'x/modules/address/AddressState'
import * as ContactActions from 'x/modules/contact/ContactState'

import CustomerView from './CustomerView'

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
    // myStores: getMyStores(state),
  }),
  (dispatch) => ({
    fetchProfileDetails: bindActionCreators(profileActions.fetchProfileDetails, dispatch),
    updateLoginEmail: bindActionCreators(profileActions.updateLoginEmail, dispatch),
    changeParent: bindActionCreators(profileActions.changeParent, dispatch),
    changeResellerType: bindActionCreators(profileActions.changeResellerType, dispatch),
    deleteOrgAddress: bindActionCreators(AddressesActions.deleteOrgAddress, dispatch),
    deleteAddress: bindActionCreators(AddressesActions.deleteAddress, dispatch),
    dispatch,

    saveNew: bindActionCreators(AddressesActions.saveNew, dispatch),
    saveEditing: bindActionCreators(AddressesActions.saveEditing, dispatch),
    editOrgAddress: bindActionCreators(AddressesActions.editOrgAddress, dispatch),
    createAddress: bindActionCreators(AddressesActions.createOrgAddress, dispatch),
    addCustomer: bindActionCreators(ContactActions.addCustomer, dispatch),
  })
)(CustomerView)
