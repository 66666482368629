import p from '../config/platform-specific'
import xCONS from '../config/constants'

const { AUTHENTICATION_STORAGE_KEY, FACEBOOK_AUTH_STORAGE_KEY } = xCONS

export async function getAuthenticationToken(): Promise<string | null> {
  // @ts-ignore actually it's string or null
  return p.op.storageGet(AUTHENTICATION_STORAGE_KEY, false)
}

export async function setAuthenticationToken(token: string): Promise<void> {
  let receivedToken = token ? token : ''
  receivedToken = receivedToken.replace(/Bearer/g, '').trim()
  return p.op.storageSet(AUTHENTICATION_STORAGE_KEY, receivedToken, false)
}

export async function getFBAccessToken(): Promise<string | null> {
  // @ts-ignore actually it's string or null
  return p.op.storageGet(FACEBOOK_AUTH_STORAGE_KEY, false)
}

export async function setFBAccessToken(fbAuthToken: string | null): Promise<void> {
  return p.op.storageSet(FACEBOOK_AUTH_STORAGE_KEY, fbAuthToken, false)
}

export async function clearAuthenticationToken(): Promise<void> {
  await p.op.storageClear(FACEBOOK_AUTH_STORAGE_KEY)
  return p.op.storageClear(AUTHENTICATION_STORAGE_KEY)
}
