/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/no-unused-state */
import React from 'react'
import {
  IAddress,
  ISelectedStoreMap,
  ISubscriberAddress,
  ISubscriptionPackageList,
  IXScreenProps,
  SubscriptionPackageListItems,
} from 'x/index'
import * as util from 'x/utils/util'
import * as NavActions from 'x/utils/navigation'
import * as api from 'x/utils/api'
import _ from 'lodash'

interface IBasePaymentSubscriptionPackageViewProps extends IXScreenProps<any> {
  selectedStore: ISelectedStoreMap
  subscription: any
}

interface IBasePaymentSubscriptionPackageViewState {
  subscriptionPackageList: ISubscriptionPackageList
  selectedPackages: SubscriptionPackageListItems
  selectedMonthIndex: number

  useTaxInvoice: boolean
  taxInvoiceAddress: IAddress

  // FIXME: From O: เอาจริงๆ มันไม่น่าจะใช่ sender address (ที่อยู่ผู้ส่ง) นะ ควรเป็น shipping address (ที่อยู่จัดส่ง) รึเปล่า
  useAddressSender: boolean
  senderAddress: IAddress

  isSubsriberAddressLoading?: boolean
}

export default abstract class BasePaymentSubscriptionPackageView extends React.Component<
  IBasePaymentSubscriptionPackageViewProps,
  IBasePaymentSubscriptionPackageViewState
> {
  // abstract async handleSubmitForm(response: any): Promise<void>
  // abstract didMountCheckPermissionForPhone?: () => void
  // validationSchema: Yup.ObjectSchema<any>
  // imgManagerIDRef: React.RefObject<any>
  // webview: React.RefObject<any>
  // mainPackageItems: any

  constructor(props) {
    super(props)
    this.state = {
      subscriptionPackageList: null,
      selectedPackages: null,
      selectedMonthIndex: 0,
      useAddressSender: false,
      senderAddress: null,
      useTaxInvoice: false,
      taxInvoiceAddress: null,
    }
    // this.webview = React.createRef()
    // this.imgManagerIDRef = React.createRef()
    // @ts-ignore
    // eslint-disable-next-line react/no-unused-class-component-methods
    // this.mainPackageItems = xCONS.SUBSCRIPTION_MAIN_PACKAGE_ITEMS.map((item) => ({
    //   ...item,
    //   label: p.op.t(`Subscription.main.${item.key}`),
    // }))
  }

  async componentDidMount() {
    await this._fetchSubscriptionPackageList()
    console.log('subscription => ', this.props.subscription.toJS())
  }

  _fetchSubscriptionPackageList = async () => {
    const apiOptions = {
      showSpinner: true,
    }
    const body = {
      lang: 'th',
      purchase_channel_id: 1,
    }
    try {
      const res = await api.x.postV2(api.x.POST_SUBSCRIPTION_PACKAGE_LIST, body, apiOptions)
      console.log('res => ', res)
      await util.setStatePromise(this, {
        subscriptionPackageList: res,
      })
      // if (!res || _.isEmpty(res)) {
      // }
    } catch (err) {}
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  goBack = () => {
    // @ts-ignore
    util.navGoBack(this.props)
  }

  _getAddOnTskusByKey = (itam: SubscriptionPackageListItems, key: string) => {
    const { subscriptionPackageList } = this.state
    const dataJson = itam.data_json
    const { type } = dataJson

    const addonTskuBySubscriptionType = subscriptionPackageList.addon_tsku_by_subscription_type
    const subscriptionTypeList = addonTskuBySubscriptionType.find((data) => data.type === type)
    const { tskus } = subscriptionTypeList
    // console.log('subscriptionTypeList => ', subscriptionTypeList)
    const ADD_ON_LIST = []
    switch (key) {
      case 'order_count_quota':
        tskus.forEach((tsku) => {
          if (tsku.includes('T-ADDON-QUOTA-ORDER')) {
            const itamByTsku = subscriptionPackageList.items.find((itamx) => itamx.tsku === tsku)
            ADD_ON_LIST.push(itamByTsku)
          }
        })
        // const filteredTsku = subscriptionTypeList.tskus.filter((item) => item.includes('T-ADDON-QUOTA-ORDER'))
        // console.log('filter => ', filteredTsku)
        // ADD_ON_LIST.push(...filteredTsku) // Add the filtered tskus to the ADD_ON_LIST array
        break

      case 'mkp_quota':
        tskus.forEach((tsku) => {
          if (tsku.includes('T-ADDON-QUOTA-MKP')) {
            const itamByTsku = subscriptionPackageList.items.find((itamx) => itamx.tsku === tsku)
            ADD_ON_LIST.push(itamByTsku)
          }
        })
        break

      case 'ug_quota':
        tskus.forEach((tsku) => {
          if (tsku.includes('T-ADDON-QUOTA-UG')) {
            const itamByTsku = subscriptionPackageList.items.find((itamx) => itamx.tsku === tsku)
            ADD_ON_LIST.push(itamByTsku)
          }
        })
        break

      case 'pg_quota':
        tskus.forEach((tsku) => {
          if (tsku.includes('T-ADDON-QUOTA-PG')) {
            const itamByTsku = subscriptionPackageList.items.find((itamx) => itamx.tsku === tsku)
            ADD_ON_LIST.push(itamByTsku)
          }
        })
        break

      case 'helper_count':
        tskus.forEach((tsku) => {
          if (tsku.includes('T-ADDON-QUOTA-HELPER')) {
            const itamByTsku = subscriptionPackageList.items.find((itamx) => itamx.tsku === tsku)
            ADD_ON_LIST.push(itamByTsku)
          }
        })
        break

      case 'store_count':
        tskus.forEach((tsku) => {
          if (tsku.includes('T-ADDON-QUOTA-STORE')) {
            const itamByTsku = subscriptionPackageList.items.find((itamx) => itamx.tsku === tsku)
            ADD_ON_LIST.push(itamByTsku)
          }
        })
        break

      case 'wh_quota':
        tskus.forEach((tsku) => {
          if (tsku.includes('T-ADDON-QUOTA-WH')) {
            const itamByTsku = subscriptionPackageList.items.find((itamx) => itamx.tsku === tsku)
            ADD_ON_LIST.push(itamByTsku)
          }
        })
        break

      default:
        break
    }
    // console.log('ADD_ON_LIST => ', ADD_ON_LIST)
    return ADD_ON_LIST
  }

  _getAddOnSumTotalCount = (items: SubscriptionPackageListItems[]) => {
    // console.log('_getAddOnSumTotalCount => ', items)
    let sumTotal = 0
    items.forEach((item) => {
      const itemSubtype = item.item_subtype
      const selectedCount = _.has(item, 'ui_selected_count') ? item.ui_selected_count : 0
      switch (itemSubtype) {
        case 'order_quota':
          // eslint-disable-next-line no-case-declarations
          const countOrder = item.data_json.order_count_quota
          sumTotal += countOrder * selectedCount
          break

        case 'mkp_quota':
          // eslint-disable-next-line no-case-declarations
          const countMkpQuota = item.data_json.mkp_quota
          sumTotal += countMkpQuota * selectedCount
          break

        case 'ug_quota':
          // eslint-disable-next-line no-case-declarations
          const countUg = item.data_json.ug_quota
          sumTotal += countUg * selectedCount
          break

        case 'pg_quota':
          // eslint-disable-next-line no-case-declarations
          const countPg = item.data_json.pg_quota
          sumTotal += countPg * selectedCount
          break

        case 'helper_count':
          // eslint-disable-next-line no-case-declarations
          const countHelper = item.data_json.helper_count
          sumTotal += countHelper * selectedCount
          break

        case 'store_count':
          // eslint-disable-next-line no-case-declarations
          const countStore = item.data_json.store_count
          sumTotal += countStore * selectedCount
          break

        case 'wh_quota':
          // eslint-disable-next-line no-case-declarations
          const countWh = item.data_json.wh_quota
          sumTotal += countWh * selectedCount
          break

        default:
          break
      }
    })
    return sumTotal
  }

  _getBillingCycle = (itam: SubscriptionPackageListItems, key: string) => {
    const { subscriptionPackageList } = this.state
    const dataJson = itam.data_json
    const { type } = dataJson

    const addonTskuBySubscriptionType = subscriptionPackageList.addon_tsku_by_subscription_type
    const subscriptionTypeList = addonTskuBySubscriptionType.find((data) => data.type === type)
    const { tskus } = subscriptionTypeList
    // console.log('subscriptionTypeList => ', subscriptionTypeList)
    let ADD_ON_LIST = 1
    switch (key) {
      case 'order_count_quota':
        tskus.forEach((tsku) => {
          if (tsku.includes('T-ADDON-QUOTA-ORDER')) {
            const itamByTsku = subscriptionPackageList.items.find((itamx) => itamx.tsku === tsku)
            ADD_ON_LIST = _.has(itamByTsku, 'billing_cycle') ? itamByTsku.billing_cycle : 1
          }
        })
        // const filteredTsku = subscriptionTypeList.tskus.filter((item) => item.includes('T-ADDON-QUOTA-ORDER'))
        // console.log('filter => ', filteredTsku)
        // ADD_ON_LIST.push(...filteredTsku) // Add the filtered tskus to the ADD_ON_LIST array
        break

      case 'mkp_quota':
        tskus.forEach((tsku) => {
          if (tsku.includes('T-ADDON-QUOTA-MKP')) {
            const itamByTsku = subscriptionPackageList.items.find((itamx) => itamx.tsku === tsku)
            // console.log('itamByTsku ** => ', itamByTsku)
            ADD_ON_LIST = _.has(itamByTsku, 'billing_cycle') ? itamByTsku.billing_cycle : 1
          }
        })
        break

      case 'ug_quota':
        tskus.forEach((tsku) => {
          if (tsku.includes('T-ADDON-QUOTA-UG')) {
            const itamByTsku = subscriptionPackageList.items.find((itamx) => itamx.tsku === tsku)
            // console.log('itamByTsku ** => ', itamByTsku)
            ADD_ON_LIST = _.has(itamByTsku, 'billing_cycle') ? itamByTsku.billing_cycle : 1
          }
        })
        break

      case 'pg_quota':
        tskus.forEach((tsku) => {
          if (tsku.includes('T-ADDON-QUOTA-PG')) {
            const itamByTsku = subscriptionPackageList.items.find((itamx) => itamx.tsku === tsku)
            // console.log('itamByTsku ** => ', itamByTsku)
            ADD_ON_LIST = _.has(itamByTsku, 'billing_cycle') ? itamByTsku.billing_cycle : 1
          }
        })
        break

      case 'helper_count':
        tskus.forEach((tsku) => {
          if (tsku.includes('T-ADDON-QUOTA-HELPER')) {
            const itamByTsku = subscriptionPackageList.items.find((itamx) => itamx.tsku === tsku)
            // console.log('itamByTsku ** => ', itamByTsku)
            ADD_ON_LIST = _.has(itamByTsku, 'billing_cycle') ? itamByTsku.billing_cycle : 1
          }
        })
        break

      case 'store_count':
        tskus.forEach((tsku) => {
          if (tsku.includes('T-ADDON-QUOTA-STORE')) {
            const itamByTsku = subscriptionPackageList.items.find((itamx) => itamx.tsku === tsku)
            // console.log('itamByTsku ** => ', itamByTsku)
            ADD_ON_LIST = _.has(itamByTsku, 'billing_cycle') ? itamByTsku.billing_cycle : 1
          }
        })
        break

      case 'wh_quota':
        tskus.forEach((tsku) => {
          if (tsku.includes('T-ADDON-QUOTA-WH')) {
            const itamByTsku = subscriptionPackageList.items.find((itamx) => itamx.tsku === tsku)
            // console.log('itamByTsku ** => ', itamByTsku)
            ADD_ON_LIST = _.has(itamByTsku, 'billing_cycle') ? itamByTsku.billing_cycle : 1
          }
        })
        break

      default:
        break
    }
    // console.log('ADD_ON_LIST => ', ADD_ON_LIST)
    return ADD_ON_LIST
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  // _navToSelectAddOnPackageView = () => {
  //   // @ts-ignore
  //   const { navigation } = this.props
  //   navigation.dispatch(NavActions.navToSelectAddOnPackageView({}))
  // }

  _callbackFormSlectedMainPackage = (itam: SubscriptionPackageListItems) => {
    // console.log('_callbackFormSlectedMainPackage => ', itam)
    this.setState({ selectedPackages: itam })
  }

  _navToSelectMainPackageView = (mode: 'NEW_PACKAGE' | 'UPGRADE_PACKAGE' | 'PER_PACKAGE') => {
    const { subscriptionPackageList } = this.state
    // @ts-ignore
    const { navigation } = this.props
    navigation.dispatch(
      NavActions.navToSelectMainPackageView({
        subscriptionPackageList,
        mode,
        callback: (itam: SubscriptionPackageListItems) => this._callbackFormSlectedMainPackage(itam),
      })
    )
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  _navToAddOnPackageView = (tskuItems: SubscriptionPackageListItems[], key: string) => {
    const { subscriptionPackageList, selectedMonthIndex, selectedPackages } = this.state
    // const billingCycleMap = {
    //   0: 1,
    //   1: 3,
    //   2: 6,
    // }
    // const selectedMonth = billingCycleMap[selectedMonthIndex] || 12
    // @ts-ignore
    const { navigation } = this.props
    // @ts-ignore
    navigation.dispatch(
      NavActions.navToSelectAddOnPackageView({
        subscriptionPackageList,
        tskuItems,
        selectedPackages,
        key,
        callback: (TskuItems: SubscriptionPackageListItems[]) => this._callbackSelectedAddOn(TskuItems),
      })
    )
  }

  _navToPickInvoiceAddress = () => {
    const { selectedStore, navigation } = this.props
    navigation.dispatch(
      NavActions.navToAddressListStore({
        store_id: selectedStore.get('id'),
        headerTitle: 'ที่อยู่ร้านฉัน',
        onPressAddress: (address) => {
          this.setState({
            taxInvoiceAddress: address,
            senderAddress: address,
            useAddressSender: true,
          })
        },
      })
    )
  }

  _navToPickSenderAddress = () => {
    const { selectedStore, navigation } = this.props
    navigation.dispatch(
      NavActions.navToAddressListStore({
        store_id: selectedStore.get('id'),
        headerTitle: 'ที่อยู่ร้านฉัน',
        onPressAddress: (address) => {
          this.setState({
            senderAddress: address,
          })
        },
      })
    )
  }

  _callbackSelectedAddOn = (tskuItems: SubscriptionPackageListItems[]) => {
    console.log('_callbackSelectedAddOn => ', tskuItems)
    const { subscriptionPackageList } = this.state
    const packageList = subscriptionPackageList.items
    console.log('packageList => // ', packageList)
    const newPackageList = []
    packageList.forEach((pack) => {
      let newPack = null
      tskuItems.forEach((tsku) => {
        if (tsku.sku === pack.sku) {
          newPack = tsku
        }
      })
      console.log('newPack => ', newPack)
      if (!_.isNil(newPack)) {
        newPackageList.push(newPack)
      } else {
        newPackageList.push(pack)
      }
    })
    const newSubscriptionPackageList = _.clone(subscriptionPackageList)
    newSubscriptionPackageList.items = newPackageList
    this.setState({ subscriptionPackageList: newSubscriptionPackageList })
  }

  _onUseTexInvoiceToggle = () => {
    const { useTaxInvoice } = this.state

    if (useTaxInvoice) {
      this.setState({ useTaxInvoice: false, useAddressSender: false, taxInvoiceAddress: null, senderAddress: null })
    } else {
      this.setState({ useTaxInvoice: true }, () => {
        this._fetchSubscriberAddress()
      })
    }
  }

  _setUseAddressSender = () => {
    const { taxInvoiceAddress } = this.state

    this.setState({ useAddressSender: true, senderAddress: null })
  }

  _unsetUseAddressSender = () => {
    const { taxInvoiceAddress } = this.state

    this.setState({ useAddressSender: false, senderAddress: taxInvoiceAddress })
  }

  _fetchSubscriberAddress = async () => {
    await util.setStatePromise(this, { isSubsriberAddressLoading: true })

    // FIXME: ยังไม่รู้ว่าจะเอามาจากไหนนะครับ hardcode ไว้ให้ก่อน
    const address_id = 2

    try {
      const sAddr = await api.getSubscriberAddress({ address_id })

      throw new Error('not implemented')
      if (sAddr && 'id' in sAddr) {
        await util.setStatePromise(this, { taxInvoiceAddress: sAddr })
      }
    } catch (error) {
      console.log('_fetchSubscriberAddress error => ', error)
      await util.setStatePromise(this, { taxInvoiceAddress: null })
    }

    await util.setStatePromise(this, { isSubsriberAddressLoading: false })
  }

  _onSubmitTaxInvoiceAddress = async (submitAddr: ISubscriberAddress) => {
    const { selectedStore } = this.props
    let isGoBack = false
    let newAddress = submitAddr
    try {
      // FIXME: อันนี้ไม่แน่ใจว่าทำไมพี่เก่งไม่ใช้ owner_user_id (จาก selectedStore > store_owner_subscription > user_id)
      // เพราะยังไง selectedStore > id ก็เป็น owner_store_id อยู่แล้ว
      // ถ้าไม่ถูกต้องแก้ได้ครับ
      const owner_store_id = selectedStore.get('id')

      if (submitAddr.id) {
        newAddress = await api.updateSubscriberAddress(submitAddr)
      } else {
        newAddress = await api.createSubscriberAddress({ ...submitAddr, owner_store_id })
      }

      if (newAddress && 'id' in newAddress) {
        const { useAddressSender } = this.state
        const newState: Partial<IBasePaymentSubscriptionPackageViewState> = { taxInvoiceAddress: newAddress }

        if (!useAddressSender) {
          newState.senderAddress = newAddress
        }

        await util.setStatePromise(this, newState)
        isGoBack = true
      }
    } catch (error) {
      console.log('_onSubmitTaxInvoiceAddress error => ', error)
    }

    return {
      newAddress,
      isGoBack,
    }
  }

  _navToFillTaxInvoiceAddress = () => {
    const { navigation } = this.props

    navigation.dispatch(
      NavActions.navToAddressFormView({
        headerTitle: 'ระบุที่อยู่ใบกำกับภาษี',
        type: 'subscriber_address',
        onSubmit: this._onSubmitTaxInvoiceAddress,
        useLookupPostalCode: true,
        useLookupLegalEntityId: true,
      })
    )
  }

  _onSubmitShippingAddress = async (submitAddr: ISubscriberAddress) => {
    const { selectedStore } = this.props
    let isGoBack = false
    let newAddress = submitAddr
    try {
      // FIXME: อันนี้ไม่แน่ใจว่าทำไมพี่เก่งไม่ใช้ owner_user_id (จาก selectedStore > store_owner_subscription > user_id)
      // เพราะยังไง selectedStore > id ก็เป็น owner_store_id อยู่แล้ว
      // ถ้าไม่ถูกต้องแก้ได้ครับ
      const owner_store_id = selectedStore.get('id')

      if (submitAddr.id) {
        newAddress = await api.updateSubscriberAddress(submitAddr)
      } else {
        newAddress = await api.createSubscriberAddress({ ...submitAddr, owner_store_id })
      }

      if (newAddress && 'id' in newAddress) {
        await util.setStatePromise(this, { senderAddress: newAddress })
        isGoBack = true
      }
    } catch (error) {
      console.log('_onSubmitTaxInvoiceAddress error => ', error)
    }

    return {
      newAddress,
      isGoBack,
    }
  }

  _navToFillShippingAddress = () => {
    const { navigation } = this.props

    navigation.dispatch(
      NavActions.navToAddressFormView({
        headerTitle: 'ระบุที่อยู่สำหรับการจัดส่งเอกสาร',
        type: 'subscriber_address',
        onSubmit: this._onSubmitShippingAddress,
        useLookupPostalCode: true,
        useLookupLegalEntityId: true,
      })
    )
  }
}
