import { Component } from 'react'
import _ from 'lodash'
import { List } from 'immutable'
// import settings from '../config/settings'
import { diff } from 'deep-object-diff'
import * as util from '../../utils/util'
import { delay, isAsyncFunction } from '../../utils/util'
import { IPGSelectorViewProps, IPGSelectorViewState } from '../../index'
import p from '../../config/platform-specific'

export default class BasePGSelectorView extends Component<IPGSelectorViewProps, IPGSelectorViewState> {
  static displayName = 'BasePGSelectorView'

  static defaultProps = {}

  inProcess?: boolean

  constructor(props: IPGSelectorViewProps) {
    super(props)
    // const { productGroups, selectedIds } = props
    const selectedIds = util.getNavParam(this.props, 'selectedIds', List([]))
    this.state = {
      // productGroups: List.isList(productGroups) ? productGroups.toJS() : productGroups,
      // selectedIds: [],
      selectedIds: List.isList(selectedIds) ? selectedIds.toJS() : selectedIds,
      // isVisibleSelector: false,
    }

    // this._onSubmitEditing = this._onSubmitEditing.bind(this)
    // this._onCancelEditing = this._onCancelEditing.bind(this)
    // this._openSelector = this._openSelector.bind(this)
  }

  // componentDidMount(): void {
  //   const selectedIds = util.getNavParam(this.props, 'selectedIds', List([]))
  //   this.setState({
  //     selectedIds: List.isList(selectedIds) ? selectedIds.toJS() : selectedIds,
  //   })
  // }

  // componentDidUpdate(prevProps: IPGSelectorViewProps, prevState: IPGSelectorViewState): void {
  //   // const { productGroups, selectedIds } = this.props
  //   const { selectedIds } = this.props
  //   const newState: Partial<IPGSelectorViewState> | any = {}
  //   // if (prevProps.productGroups !== productGroups) {
  //   //   newState.productGroups = List.isList(productGroups) ? productGroups.toJS() : productGroups
  //   // }
  //
  //   if (prevProps.selectedIds !== selectedIds) {
  //     newState.selectedIds = List.isList(selectedIds) ? selectedIds.toJS() : selectedIds
  //   }
  //
  //   if (!_.isEmpty(newState)) {
  //     this.setState(newState)
  //   }
  // }

  _toggleCheckboxAtId = (productGroupId: number, checked: boolean) => {
    const { productGroups } = this.props
    const { selectedIds } = this.state
    const newSelectedIds = _.isArray(selectedIds) ? selectedIds.slice() : []
    if (checked) {
      // ถ้าเลือกอยู่ ต่อไปจะปิด
      const indexToRemove = newSelectedIds.findIndex((sId) => sId === productGroupId)
      if (indexToRemove > -1) {
        newSelectedIds.splice(indexToRemove, 1)
      }
    } else {
      // ถ้าปิดอยู่ ต่อไปจะเลือก
      newSelectedIds.push(productGroupId)
      let pgs = List.isList(productGroups) ? productGroups.toJS() : productGroups
      if (!_.isArray(pgs)) {
        pgs = []
      }
      newSelectedIds.sort((aId: number, bId: number) => {
        const pgA = pgs.find((pg) => pg.id === aId)
        const pbB = pgs.find((pg) => pg.id === bId)
        const priorityA = pgA && pgA.priority ? pgA.priority : 0
        const priorityB = pbB && pbB.priority ? pbB.priority : 0
        // const priorityA = pgs.find(pg => pg.id === aId).priority
        // const priorityB = pgs.find(pg => pg.id === bId).priority
        return priorityB - priorityA // DESC
      })
    }
    this.setState({ selectedIds: newSelectedIds })

    // if (_.isFunction(onChange)) {
    //   onChange(List.isList(selectedIds) ? List(newSelectedIds) : selectedIds)
    // } else {
    //   this.setState({ selectedIds: newSelectedIds })
    // }
  }

  // _openSelector(): void {
  //   if (this.props.disabled) {
  //     return
  //   }
  //
  //   this.setState({ isVisibleSelector: true })
  // }

  _onCancelEditing = (): void => {
    // const { selectedIds } = this.props
    const selectedIds = util.getNavParam(this.props, 'selectedIds', List([]))
    this.setState({
      selectedIds: List.isList(selectedIds) ? selectedIds.toJS() : selectedIds,
      // isVisibleSelector: false,
    })
  }

  onPressGoBack = async (): Promise<void> => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    const { navigation } = this.props
    const selectedIds = util.getNavParam(this.props, 'selectedIds', List([]))

    const defaultSelectedIds = List.isList(selectedIds) ? selectedIds.toJS() : selectedIds
    const currentSelectedIds = this.state.selectedIds
    const diffObj = diff(defaultSelectedIds, currentSelectedIds)
    if (_.isEmpty(diffObj)) {
      this._goBack()
    } else {
      const isUserConfirmed = await p.op.isUserConfirm('ละทิ้งการแก้ไข', 'กรุณายืนยันว่าคุณต้องการละทิ้งรายการราคาที่คุณแก้ไขไปแล้วทั้งหมด')
      if (isUserConfirmed) {
        this._goBack()
      }
    }

    await delay(200)
    this.inProcess = false
  }

  _goBack = () => {
    util.navGoBack(this.props)
  }

  onSubmitEditing = async (): Promise<void> => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    // const { selectedIds, onSubmitEditing, allowedEmptySelected = false, onEmptySubmitEditing, navigation } = this.props
    const { navigation } = this.props
    const selectedIds = util.getNavParam(this.props, 'selectedIds', List([]))
    // const allowedEmptySelected = util.getNavParam(this.props, 'allowedEmptySelected', false)
    const onSubmitEditing = util.getNavParam(this.props, 'onSubmitEditing')
    // const onEmptySubmitEditing = util.getNavParam(this.props, 'onEmptySubmitEditing')
    const outputSelectedIds = this.state.selectedIds

    // if (!allowedEmptySelected && outputSelectedIds.length === 0) {
    //   if (isAsyncFunction(onEmptySubmitEditing)) {
    //     await onEmptySubmitEditing()
    //   } else if (_.isFunction(onEmptySubmitEditing)) {
    //     onEmptySubmitEditing()
    //   }
    // } else {
    // this.setState({ isVisibleSelector: false }, () => {
    if (isAsyncFunction(onSubmitEditing)) {
      await onSubmitEditing(outputSelectedIds)
    } else if (_.isFunction(onSubmitEditing)) {
      onSubmitEditing(outputSelectedIds)
    }
    // })
    // }
    this._goBack()
    await delay(200)
    this.inProcess = false
  }
}
