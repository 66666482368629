import * as NavActions from 'x/utils/navigation'
import { IProductListViewSellersStoreNavParams, IProductItemData, IProductListViewProps, IProductListViewState } from 'x/index'
import * as util from 'x/utils/util'

import BaseUIProductListView from './BaseUIProductListView'

type IProductListViewSellersStoreProps = IProductListViewProps<IProductListViewSellersStoreNavParams>

class ProductListViewSellersStore extends BaseUIProductListView<IProductListViewSellersStoreProps, IProductListViewState> {
  static displayName = 'ProductListViewSellersStore'

  // // override
  // isSellerStore = () => true

  // override
  isRequiredProductVariants = () => false

  // override
  isAddProductButtonVisible = () => false

  // override
  isSkuSearchAvailable = () => false

  // override
  getTargetStoreId = () => {
    const storeId = util.getNavParam(this.props, 'seller_store_id')
    if (typeof storeId !== 'number') {
      throw new Error('seller_store_id is required')
    }
    return storeId
  }

  // implement abstract
  onPressProductItem = async (item: { index?: number; data: IProductItemData }) => {
    this.navToProductViewSellersStoreMode(item.data.id)
  }

  navToProductViewSellersStoreMode = async (product_id: number) => {
    const { navigation } = this.props
    navigation.dispatch(
      NavActions.navToProductViewSeller({
        seller_store_id: this.getTargetStoreId(),
        product_id,
      })
    )
  }
}

export default ProductListViewSellersStore
