import React from 'react'
import { TouchableOpacity } from 'react-native'
import { COLORS, STYLES } from 'x/config/styles'
import XIcon from './XIcon'

const ButtonClose = (props: { isVisible: boolean; onPress: () => void }) => {
  if (typeof props.onPress !== 'function') {
    throw new Error('ButtonClose is required onPress props')
  }

  return props.isVisible ? (
    <TouchableOpacity onPress={props.onPress} style={{ padding: 8 }}>
      <XIcon
        style={{
          paddingTop: 2, // Offset because icon is doesn't crop
          color: COLORS.TEXT_INACTIVE,
          fontSize: STYLES.FONT_ICON_NORMAL,
        }}
        name='close-circle'
      />
    </TouchableOpacity>
  ) : null
}

ButtonClose.defaultProops = {
  isVisible: true,
}

export default ButtonClose
