import _ from 'lodash'
import api from 'x/utils/api'
import { take, put, call, fork, all } from 'redux-saga/effects'
import * as CategoryActions from 'x/modules/category/categoryState'
import { ActionApiParams } from 'x/index'

// import { setCrashlyticsUserId } from './../../utils/analytics'

export default function* xWatchAllCategory() {
  yield all([
    fork(watchGetCategoryList),
    fork(watchAddCategoryt),
    fork(watchDeleteCategoryt),
    fork(watchEditCategoryt),
    fork(watchChangeCategoryt),
    fork(watchSortCategoryt),
  ])
}

function* watchGetCategoryList() {
  while (true) {
    const action = yield take(CategoryActions.REQUEST_CATEGORY_LIST)
    yield call(getCategoryList, action)
  }
}

function* getCategoryList(action: ActionApiParams) {
  // console.log(`action => `, action)
  try {
    let res = yield call(api.postV2, api.POST_REQUEST_CATEGORY_LIST, action.body)
    if (res.isEmpty) {
      res = []
    }
    if (_.isFunction(action.successCallback)) {
      const update = { store_id: action.body.store_id, category: res }
      yield put({ type: CategoryActions.UPDATE_CATEGORY, payload: update })
      action.successCallback(res)
    }
  } catch (error) {
    // yield put({ type: actions.USER_PROFILE_CHANGE_EMAIL_INVALID })
    if (_.isFunction(action.failedCallback)) {
      action.failedCallback(error)
    }
  }
}

function* watchAddCategoryt() {
  while (true) {
    const action = yield take(CategoryActions.ADD_CATEGORY)
    yield call(addCategory, action)
  }
}

function* addCategory(action: ActionApiParams) {
  try {
    const res = yield call(api.putV2, api.PUT_ADD_GATEGORY, action.body)
    if (_.isFunction(action.successCallback)) {
      const update = { store_id: action.body.store_id, category: res }
      yield put({ type: CategoryActions.UPDATE_CATEGORY, payload: update })
      action.successCallback(res)
    }
  } catch (error) {
    // yield put({ type: actions.USER_PROFILE_CHANGE_EMAIL_INVALID })
    if (_.isFunction(action.failedCallback)) {
      action.failedCallback(error)
    }
  }
}

function* watchEditCategoryt() {
  while (true) {
    const action = yield take(CategoryActions.EDIT_CATEGORY)
    yield call(editCategory, action)
  }
}

function* editCategory(action: ActionApiParams) {
  try {
    const res = yield call(api.patchV2, api.PATCH_EDIT_CATEGORY, action.body)
    if (_.isFunction(action.successCallback)) {
      const update = { store_id: action.body.store_id, category: res }
      yield put({ type: CategoryActions.UPDATE_CATEGORY, payload: update })
      action.successCallback(res)
    }
  } catch (error) {
    // yield put({ type: actions.USER_PROFILE_CHANGE_EMAIL_INVALID })
    if (_.isFunction(action.failedCallback)) {
      action.failedCallback(error)
    }
  }
}

function* watchDeleteCategoryt() {
  while (true) {
    const action = yield take(CategoryActions.DELETE_CATEGORY)
    yield call(deleteCategory, action)
  }
}

function* deleteCategory(action: ActionApiParams) {
  try {
    let res = yield call(api.delV2, api.DELETE_CATEGORY, action.body)
    if (res.isEmpty) {
      res = []
    }
    if (_.isFunction(action.successCallback)) {
      const update = { store_id: action.body.store_id, category: res }
      if (_.isArray(res)) {
        yield put({ type: CategoryActions.UPDATE_CATEGORY, payload: update })
      }
      action.successCallback(res)
    }
  } catch (error) {
    // yield put({ type: actions.USER_PROFILE_CHANGE_EMAIL_INVALID })
    if (_.isFunction(action.failedCallback)) {
      action.failedCallback(error)
    }
  }
}

function* watchChangeCategoryt() {
  while (true) {
    const action = yield take(CategoryActions.CHANGE_CATEGORY)
    yield call(changeCategory, action)
  }
}

function* changeCategory(action: ActionApiParams) {
  try {
    const res = yield call(api.patchV2, api.PATCH_CHANGE_CATEGORY, action.body)
    if (_.isFunction(action.successCallback)) {
      const update = { store_id: action.body.store_id, category: res }
      if (_.isArray(res)) {
        yield put({ type: CategoryActions.UPDATE_CATEGORY, payload: update })
      }
      action.successCallback(res)
    }
  } catch (error) {
    // yield put({ type: actions.USER_PROFILE_CHANGE_EMAIL_INVALID })
    if (_.isFunction(action.failedCallback)) {
      action.failedCallback(error)
    }
  }
}

function* watchSortCategoryt() {
  while (true) {
    const action = yield take(CategoryActions.SORT_CATEGORY)
    yield call(sortCategory, action)
  }
}

function* sortCategory(action: ActionApiParams) {
  try {
    const res = yield call(api.patchV2, api.PATCH_SORT_CATEGORY, action.body)
    if (_.isFunction(action.successCallback)) {
      const update = { store_id: action.body.store_id, category: res }
      if (_.isArray(res)) {
        yield put({ type: CategoryActions.UPDATE_CATEGORY, payload: update })
      }
      action.successCallback(res)
    }
  } catch (error) {
    // yield put({ type: actions.USER_PROFILE_CHANGE_EMAIL_INVALID })
    if (_.isFunction(action.failedCallback)) {
      action.failedCallback(error)
    }
  }
}
