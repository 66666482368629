/* eslint-disable react/no-unused-state */
import React from 'react'
import { IERPChannel, ISelectedStoreMap, IXScreenProps } from 'x/types'
import * as util from 'x/utils/util'
import * as NavActions from 'x/utils/navigation'
import _ from 'lodash'

interface IBaseERPCreateViewProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
}

interface IBaseERPCreateViewState {
  erpChannelDetail: IERPChannel
}

export default abstract class BaseERPCreateView extends React.Component<IBaseERPCreateViewProps, IBaseERPCreateViewState> {
  // abstract async handleSubmitForm(response: any): Promise<void>
  // abstract didMountCheckPermissionForPhone?: () => void
  // validationSchema: Yup.ObjectSchema<any>
  // imgManagerIDRef: React.RefObject<any>
  // webview: React.RefObject<any>

  constructor(props) {
    super(props)
    this.state = {
      erpChannelDetail: null,
    }
    // this.webview = React.createRef()
    // this.imgManagerIDRef = React.createRef()
  }

  async componentDidMount() {
    // await this._fetchErpChannel()
  }

  // _fetchErpChannel = async () => {
  //   const params = util.getNavParams(this.props)
  //   const { erp_ch_id } = params
  //   const { selectedStore } = this.props
  //   // const {} = this.state
  //   const store_id = selectedStore.get('id')
  //   const reqBody = {
  //     store_id,
  //     erp_ch_id,
  //   }

  //   const apiOptions: IApiOptions = {
  //     showSpinner: true,
  //   }

  //   const res = await api.postV2('erp/channel/detail', reqBody, apiOptions)
  //   console.log('res _fetchErpChannel => ', res)
  //   this.setState({
  //     erpChannelDetail: res.erp_channel_detail,
  //   })
  //   // p.op.showToast('ผูกรหัสบัญชีเรียบร้อยแล้ว', 'success')
  //   // const params = util.getNavParams(this.props)
  //   // const { callBack } = params
  // }

  goBack = () => {
    util.navGoBack(this.props)
  }

  _callBackFromCreateErpChannel = (newErpChannel: IERPChannel) => {
    // console.log('_callBackFromCreateErpChannel => ', newErpChannel)
    const params = util.getNavParams(this.props)
    const { callBack } = params
    // console.log('callBack => ', callBack)
    if (_.isFunction(callBack)) {
      callBack(newErpChannel)
    }
    this.goBack()
  }

  _navToErpCreatePeakView = () => {
    const { navigation, selectedStore } = this.props
    navigation.dispatch(
      NavActions.navToERPCreatePackView({
        store_id: selectedStore.get('id'),
        callBack: (newErpChannel: IERPChannel) => this._callBackFromCreateErpChannel(newErpChannel),
      })
    )
  }
}
