// Extended
import BaseUICategoryListView from 'xui/modules/store/Category/BaseUICategoryListView'

export default class CategoryListView extends BaseUICategoryListView {
  static displayName = 'CategoryListView'

  static navigationOptions = {
    header: null,
  }
}
