import React, { PureComponent } from 'react'
import _ from 'lodash'
import { IButtonRemoveProductVariantProps } from 'x/index'
import XIconButton from 'xui/components/XIconButton'

// const BTN_REMOVE_PRODUCT_VARIANT_STYLE = {
//   fontSize: STYLES.FONT_ICON_LARGER,
//   color: COLORS.FORM_ERROR,
// }

export default class ButtonRemoveProductVariant extends PureComponent<IButtonRemoveProductVariantProps> {
  _onPressItem = () => {
    const {
      variantIndex,
      onPressItem,
      // onRemovePressItem,
      // onUndoRemovePressItem,
      // isDeleted = false
    } = this.props
    if (_.isFunction(onPressItem) && variantIndex >= 0) {
      onPressItem(variantIndex)
    }

    // if (isDeleted && _.isFunction(onUndoRemovePressItem) && variantIndex >= 0) {
    //   onUndoRemovePressItem(variantIndex)
    // }

    // if (_.isFunction(onRemovePressItem) && variantIndex >= 0) {
    //   onRemovePressItem(variantIndex)
    // }
  }

  render() {
    const { disabled = false } = this.props
    return (
      <XIconButton
        // size='md'
        variant='outline'
        // family={isDeleted ? 'FontAwesome' : 'Ionicons'}
        // colorScheme={isDeleted ? 'primary' : 'danger'}
        // name={isDeleted ? 'undo' : 'trash'}
        family='Ionicons'
        colorScheme='danger'
        name='trash'
        // name='md-remove-circle'
        // name='close-circle'
        disabled={disabled}
        onPress={this._onPressItem}
      />
    )
  }
}
