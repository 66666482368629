import React from 'react'
import { LayoutChangeEvent, TouchableOpacity } from 'react-native'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getSelectedStore } from 'x/redux/selectors'
import * as StoreState from 'x/modules/store/StoreState'
import { ISelectedStoreMap } from 'x/index'

import * as util from 'x/utils/util'
import * as xFmt from 'x/utils/formatter'
import * as api from 'x/utils/api'
import CONS from 'x/config/constants'

import HStack from 'xui/components/HStack'
import VStack from 'xui/components/VStack'

import XCard from 'xui/components/XCard'
import XText from 'xui/components/XText'
import Box from 'xui/components/Box'
import { List } from 'immutable'
import XSpinner from '../XSpinner'

interface IProps {
  /** storeId to fetch */
  storeId: number
  onPress?: () => void | Promise<void>

  // redux
  selectedStore: ISelectedStoreMap
  updateSelectedStoreByKey: (key: string, value: any) => void
}

interface IState {
  dailySalesAmount: string
  dailySalesQty: string
  dailySalesOrder: string
  dailySalesProfit: string
  isInitialized: boolean
  containerWidth: number
}

class DailySaleBox extends React.Component<IProps, IState> {
  static displayName = 'DailySaleBox'

  inProcess: boolean

  constructor(props) {
    super(props)
    this.state = {
      dailySalesAmount: '0',
      dailySalesQty: '0',
      dailySalesOrder: '0',
      dailySalesProfit: '0',
      isInitialized: false,
      containerWidth: 320,
    }
  }

  async componentDidMount(): Promise<void> {
    await this._iniData()
  }

  async componentDidUpdate(prevProps: IProps): Promise<void> {
    if (prevProps.storeId !== this.props.storeId) {
      await this._iniData()
    }
  }

  _iniData = async () => {
    try {
      const { selectedStore, updateSelectedStoreByKey } = this.props
      const dsList = selectedStore.get('dailysales')

      let ds
      if (List.isList(dsList)) {
        ds = dsList.toJS()
      } else {
        ds = await this._fetchTodayDailySales()
        updateSelectedStoreByKey('dailysales', ds)
      }

      // const ds = await this._fetchTodayDailySales()
      if (ds.length === 1) {
        await util.setStatePromise(this, {
          dailySalesAmount: Math.round(parseFloat(ds[0].amount)).toString(),
          dailySalesProfit: Math.round(parseFloat(ds[0].profit)).toString(),
          dailySalesOrder: ds[0].order,
          dailySalesQty: ds[0].qty,
        })
      }
    } catch (err) {
      console.log('DailySaleBox componentDidMount err => ', err)
    }
    await util.setStatePromise(this, { isInitialized: true })
  }

  _fetchTodayDailySales = async () => {
    const { storeId } = this.props
    // const store_id = util.getNavParam(this.props, 'store_id')
    const defaultCreatedDateRangeKey = CONS.DATERANGE_SELECTOR.OPTION.Today
    const dr = util.getDateRangeFromOption(defaultCreatedDateRangeKey)
    const orderFilterSetting = {
      createOrderDateRangeOptionKey: defaultCreatedDateRangeKey,
      createOrderFrom: dr.begin,
      createOrderTo: dr.end,
    }

    try {
      const translatedOrderFilter = util.translateOrdersFilterToRequestBody(orderFilterSetting)
      // console.log('_fetchTodayDailySales translatedOrderFilter => ', translatedOrderFilter)

      const data = await api.fetchDailySalesData({ store_id: storeId, ...translatedOrderFilter })
      // console.log('_fetchTodayDailySales data => ', data)
      return data
    } catch (err) {
      console.log('_fetchTodayDailySales err => ', err)
    }
  }

  _renderBox = (label: string, value: string, valueColor: string) => {
    const { containerWidth } = this.state

    let fontSize = 'md'

    if (containerWidth < 320) {
      fontSize = value.length > 8 ? 'xs' : 'sm'
    }

    return (
      <VStack flex={1} alignItems='center' justifyContent='space-between'>
        <XText fontSize={fontSize} color={valueColor || 'black'} bold>
          {value}
        </XText>

        <XText fontSize='sm' textAlign='center'>
          {label}
        </XText>
      </VStack>
    )
  }

  // _navToDailySaleChartView = () => {
  //   const { navigation } = this.props

  //   const store_id = util.getNavParam(this.props, 'store_id')

  //   navigation.dispatch(NavActions.navToDailySaleChartView({ store_id }))
  // }

  _onPress = () => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true

    const { onPress } = this.props
    try {
      onPress()
    } catch (error) {
      console.log('_onPress error => ', error)
    }

    this.inProcess = false
  }

  _renderDailySalesSummary = () => {
    const { dailySalesAmount, dailySalesOrder, dailySalesQty, dailySalesProfit, isInitialized } = this.state

    if (!isInitialized) {
      return (
        <HStack w='full' h='9' alignItems='center' justifyContent='center'>
          <XSpinner variant='inactive' />
        </HStack>
      )
    }

    return (
      <TouchableOpacity onPress={this._onPress}>
        <VStack w='full' space='0.5'>
          <HStack w='full' alignItems='center' justifyContent='center'>
            {/* <Box w='38px' /> */}
            <HStack flex={1} alignItems='center' justifyContent='center'>
              <XText style={{ marginTop: -2 }} variant='inactive' fontSize='xs' textAlignVertical='center' textAlign='center'>
                ข้อมูลออเดอร์ที่สร้างวันนี้
              </XText>
            </HStack>
            {/* <Box w='38px' alignItems='center' justifyContent='center' position='absolute' top='3px' right='0'>
              <ForwardIcon />
            </Box> */}
          </HStack>
          <HStack w='full' space='0.5'>
            {this._renderBox('ยอดขาย', xFmt.formatCurrency(dailySalesAmount), 'blue.500')}
            <Box borderLeftWidth='1' borderLeftColor='gray.200' />
            {this._renderBox('กำไร', xFmt.formatCurrency(dailySalesProfit), 'success.500')}
            <Box borderLeftWidth='1' borderLeftColor='gray.200' />
            {this._renderBox('ออเดอร์', dailySalesOrder, 'primary.500')}
            <Box borderLeftWidth='1' borderLeftColor='gray.200' />
            {this._renderBox('สินค้า', dailySalesQty, 'yellow.700')}
          </HStack>
        </VStack>
      </TouchableOpacity>
    )
  }

  _onLayout = (event: LayoutChangeEvent) => {
    try {
      const { width } = event.nativeEvent.layout
      this.setState({ containerWidth: width })
    } catch (error) {
      //
    }
  }

  render() {
    return (
      <XCard>
        <VStack w='full' p='2' onLayout={this._onLayout}>
          {this._renderDailySalesSummary()}
        </VStack>
      </XCard>
    )
  }
}

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
  }),
  (dispatch) => ({
    updateSelectedStoreByKey: bindActionCreators(StoreState.updateSelectedStoreByKey, dispatch),
    dispatch,
  })
)(DailySaleBox)
