import BaseUIStoreSettingXShippingNinjaVanView from 'xui/modules/xshipping/BaseUIStoreSettingXShippingNinjaVanView'

class StoreSettingXShippingNinjaVanView extends BaseUIStoreSettingXShippingNinjaVanView {
  static displayName = 'StoreSettingXShippingNinjaVanView'

  static navigationOptions = {
    header: null,
    title: null,
    headerLeft: null,
    headerRight: null,
  }
}

export default StoreSettingXShippingNinjaVanView
