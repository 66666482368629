import { connect } from 'react-redux'
import { getState, getDispatch } from 'x/modules/product/ProductListViewConnect'
import ProductListView from './ProductListView'
// import { bindActionCreators } from 'redux'
// import { NavigationActions } from 'react-navigation'
// import * as ProductStateActions from './ProductState'
// import * as OrderActions from 'x/modules/order/OrderState'
// import { getSearchedProducts, getSelectedProductsFetchStatus, getSearchedProductsFetchStatus } from '../../redux/selectors'

export default connect(
  (state) => getState(state),
  (dispatch) => getDispatch(dispatch)
)(ProductListView)
