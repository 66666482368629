import React from 'react'
import BaseUIReportStockView from 'xui/modules/report/BaseUIReportStockView'
import * as XUtil from 'x/utils/util'
import * as util from '../../utils/util-web'
import ThemeSizeContext from '../../context/ThemeSizeContext'

export default class ReportStockView extends BaseUIReportStockView {
  static contextType = ThemeSizeContext

  context!: React.ContextType<typeof ThemeSizeContext>

  _downloadReportStockView = async (url: string, fileName: string) => {
    await XUtil.setStatePromise(this, { isLoading: true })
    // const { selectedStore } = this.props
    // const store_id = selectedStore.get(`id`)
    // const startDate = moment().format('DD-MM-YYYY_hh-mm')
    // const url = XUtil.getReportStockExcelUrl({ store_id })
    // const fileName = `Stock_${startDate}.xlsx`
    if (url) {
      // util.openFileFromUrl(url, fileName, 'xlsx')
      await util.downloadFileFromUrl({ url, fileName })
    }
    await XUtil.setStatePromise(this, { isLoading: false })
  }
}
