import { connect } from 'react-redux'
import { getSelectedStore, getSubscription } from 'x/redux/selectors'
import ReportSalesByChannelView from './ReportSalesByChannelView'

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
    subscription: getSubscription(state),
    // myStores: getMyStores(state),
  }),
  (dispatch) => {
    return {
      // searchMumber: bindActionCreators(profileActions.fetchProfileList, dispatch),
      // fetchInitializeData: bindActionCreators(StoreState.fetchInitializeData, dispatch),
      // fetchMyStore: bindActionCreators(StoreState.fetchMyStore, dispatch),
      dispatch,
    }
  }
)(ReportSalesByChannelView)
