import React from 'react'
import { ICommonLogoOptionalProps } from './CommonLogo'
import ShopeeLogo from './ShopeeLogo'
import LazadaLogo from './LazadaLogo'
import TiktokLogo from './TiktokLogo'
import LineShoppingLogo from './LineShoppingLogo'
import SpotifyLogo from './SpotifyLogo'
import MkpDefaultLogo from './MkpDefaultLogo'

interface IMkpLogoProps extends ICommonLogoOptionalProps {
  mkpId: number
}
const MkpLogo = (props: IMkpLogoProps) => {
  switch (props.mkpId) {
    case 1:
      return <ShopeeLogo {...props} />
    case 2:
      return <LazadaLogo {...props} />
    case 3:
      return <TiktokLogo {...props} />
    case 4:
      return <LineShoppingLogo {...props} />
    case 5:
      return <SpotifyLogo {...props} />
    default:
      return <MkpDefaultLogo {...props} />
  }
}

export default MkpLogo
