import BaseUIStoreSettingXShippingSCGView from 'xui/modules/xshipping/BaseUIStoreSettingXShippingSCGView'

class StoreSettingXShippingSCGView extends BaseUIStoreSettingXShippingSCGView {
  static displayName = 'StoreSettingXShippingSCGView'

  static navigationOptions = {
    header: null,
    title: null,
    headerLeft: null,
    headerRight: null,
  }
}

export default StoreSettingXShippingSCGView
