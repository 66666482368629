import OnboardingRegisterView from 'xui/modules/onboard/OnboardingRegisterView'
import OnboardingFirstStoreNameView from 'xui/modules/onboard/OnboardingFirstStoreNameView'
import OnboardingUsernameView from 'xui/modules/onboard/OnboardingUsernameView'
import OnboardingEmailView from 'xui/modules/onboard/OnboardingEmailView'
import ForgottenPasswordViewContainer from 'xui/modules/login/ForgottenPasswordViewContainer'

export const SHARED_GUEST_SCREENS = {
  OnboardingRegisterView: {
    screen: OnboardingRegisterView,
    path: 'signup',
  },
  OnboardingFirstStoreNameView: {
    screen: OnboardingFirstStoreNameView,
    path: 'signup_store_name',
  },
  OnboardingUsernameView: {
    screen: OnboardingUsernameView,
    path: 'signup_username',
  },
  OnboardingEmailView: {
    screen: OnboardingEmailView,
    path: 'signup_email',
  },
  ForgottenPasswordView: {
    screen: ForgottenPasswordViewContainer,
    path: 'forgot_password',
  },
}
