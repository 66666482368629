import React from 'react'
import { TouchableOpacity } from 'react-native'
import HStack from './HStack'
import XIcon from './XIcon'
import VStack from './VStack'

interface IFilterBoltButtonProps {
  onPress: () => Promise<void> | void
  isFiltered?: boolean
  disabled?: boolean
}

const FilterBoltButton: React.FC<IFilterBoltButtonProps> = (props) => {
  const { disabled = false, isFiltered = false, onPress } = props

  return (
    <TouchableOpacity disabled={disabled} onPress={onPress}>
      <VStack w='44px' h='44px' alignItems='center' justifyContent='center'>
        <HStack alignItems='center' justifyContent='center'>
          <XIcon family='MaterialCommunityIcons' name='filter' />
        </HStack>
        <XIcon
          family='MaterialIcons'
          // variant='success'
          name='bolt'
          position='absolute'
          bottom='5px'
          right='7px'
          color='yellow.400'
          // fontSize='6px'
          style={{
            fontSize: 18,
          }}
        />
        {isFiltered && (
          <XIcon
            family='MaterialCommunityIcons'
            variant='success'
            name='check-circle'
            position='absolute'
            bottom='9px'
            right='9px'
            size='16px'
          />
        )}
      </VStack>
    </TouchableOpacity>
  )
}

FilterBoltButton.defaultProps = {
  disabled: false,
}

export default FilterBoltButton
