import BaseUIERPCreatePeakView from 'xui/modules/erp/BaseUIERPCreatePeakView'
// import { getConfig } from 'x/config/mode'
// import { WebView } from 'react-native-webview'
// import OrdersSummaryDonut from '../../components/OrdersSummaryDonut'

export default class ERPCreatePeakView extends BaseUIERPCreatePeakView {
  static displayName = 'ERPCreatePeakView'

  static navigationOptions = {
    header: null,
  }
}
