import { fromJS, List } from 'immutable'
import { take, put, call, fork, select, all } from 'redux-saga/effects'
import * as orderActions from 'x/modules/order/OrderState'
import _ from 'lodash'
import * as xUtil from 'x/utils/util'
import { handleOrderResponse } from 'x/redux/sagas/orders'
import { ActionApiParams } from 'x/index'
import * as util from '../../utils/util'
import api from '../../utils/api'
// import CONS from '../../config/constants'
import actions from '../../config/actions'
import p from '../../config/platform-specific'

import * as shipmentActions from '../../modules/shipment/ShipmentState'
import { getEditingOrderShip } from '../selectors'
// import * as NavActions from '../../services/navigation'
// import * as indicator from '../../services/indicator'

export default function* watchAllShipments() {
  yield all([fork(watchShipmentOrderFetch), fork(watchOperateShip), fork(watchOrderBarcodeFetch), fork(watchQuickShip)])
}

function* watchShipmentOrderFetch() {
  util.log('In watchShipmentOrderFetch')
  while (true) {
    const action = yield take(actions.SHIPMENT_ORDER_FETCH)
    yield call(fetchShipmentOrder, action)
  }
}

// Fetch Order Detail and prepare for Shipment
function* fetchShipmentOrder(action: ActionApiParams<{ store_id; order_id }>) {
  util.log('Before api fetch ShipmentOrder')
  const { body, successCallback, failedCallback } = action
  try {
    // @ts-ignore
    const res = yield call(api.post, api.POST_ORDER, body)
    util.log('After api fetch ShipmentOrder Response: ', res)
    if (_.has(res, 'order')) {
      const { order } = res
      const shipment: any = {
        order_id: order.id,
        store_id: order.store_id,
        name: order.name,
        shipping_type_id: order.shipping_type_id,
      }
      if (_.has(order, 'products')) {
        shipment.products = _.clone(order.products)
      }
      yield loadOrderToUI(shipment)
    }
    if (_.isFunction(successCallback)) {
      successCallback(res)
    }
  } catch (err) {
    if (_.isFunction(failedCallback)) {
      failedCallback(err)
    }
    util.log('After api fetch ShipmentOrder error: ', err)
  }
}

function* watchOperateShip() {
  util.log('In watchOperateShip')
  // yield takeLatest(actions.SHIPMENT_ORDER_OPERATE_SHIP, handleOperateShip)
  while (true) {
    // const { payload, callback } = yield take(actions.SHIPMENT_ORDER_OPERATE_SHIP)
    // yield call(handleOperateShip, payload, callback) // callback when success api
    const action = yield take(actions.SHIPMENT_ORDER_OPERATE_SHIP)
    yield call(handleOperateShip, action) // callback when success api
  }
}

// function* handleOperateShip(body, callback = null) {
function* handleOperateShip(
  action: ActionApiParams<{
    order_id: number
    store_id: number
    products: List<any>
    tracking_number: string
    shipping_type_id: number
    note?: string
  }>
) {
  const { body, successCallback, failedCallback } = action
  const shipment = yield select(getEditingOrderShip)
  // util.log('Before api fetch handleOperateShip shipment: ', shipment)
  if (shipment) {
    // initialize body for shipping
    // let body = {
    //   order_id: shipment.get('order_id'),
    //   store_id,
    //   tracking_number: shipment.get('tracking_number'), // FIXME: For current backend required
    //   // tracking_number: shipment.get('tracking_number'),
    //   shipping_type_id: parseInt(shipment.get('shipping_type_id')),
    //   products: [],
    // }

    // // If have not assign to body
    // const note = shipment.get('note')
    // if (note.length > 0) {
    //   body.note = note
    // }

    // Preparing send products list
    // util.log('preparing to ship')
    // shipment.get('products').forEach((product, index) => {
    //   if (product.get('selectedQty') > 0) {
    //     // util.log(product.toJS())
    //     body.products.push({
    //       pp_id: product.get('pp_id'),
    //       qty: product.get('selectedQty') - product.get('shipped_qty'),
    //     })
    //   }
    // })

    try {
      const apiOptions = {
        messages: {
          successMsg: 'ดำเนินการการจัดส่งเสร็จสิ้น',
          errorMsg: 'เกิดข้อผิดพลาดในดำเนินการจัดส่ง',
        },
        showSpinner: true,
      }
      util.log('Before api createShipping body: ', body)
      const res = yield call(api.put, api.PUT_ORDER_SHIP, body, apiOptions)
      // indicator.isReady()
      if (res) {
        yield put({
          type: actions.SHIPMENT_ORDER_FETCH,
          // payload: { order_id: body.order_id, store_id: body.store_id },
          body: { order_id: body.order_id, store_id: body.store_id },
        })
        yield put({ type: orderActions.SHOULD_FETCH_ORDER_DETAIL }) // Refresh Order Detail
        // yield put(NavActions.pop(1))
      }
      yield put({ type: actions.STORE_ORDERS_SHOULD_FETCH_ALL }) // Refresh Order List
      yield put({ type: actions.STORE_ORDERS_SHOULD_FETCH_SINGLE_TAB_KEY, payload: 'myTasks_ship' })
      if (_.isFunction(successCallback)) {
        successCallback(res)
      }
      util.log('After api createShipping Response: ', res)
    } catch (err) {
      if (_.isFunction(failedCallback)) {
        successCallback(err)
      }
      util.log('After api createShipping error: ', err)
    }
  }
}

/**
 *  QUICK SHIP
 */
function* watchQuickShip() {
  util.log('In watchQuickShip')
  while (true) {
    const { payload, successCallback, failCallback } = yield take(shipmentActions.ACTION_QS_OPERATION_SHIP)
    yield call(quickShipping, payload, successCallback, failCallback)
  }
}

function* quickShipping(body: Object, successCallback?: (res: Response) => void, failCallback?: (error: Error) => void) {
  try {
    const apiOptions = {
      messages: {
        successMsg: 'ดำเนินการการจัดส่งเสร็จสิ้น',
        errorMsg: 'เกิดข้อผิดพลาดในดำเนินการจัดส่ง',
      },
      showSpinner: true,
    }
    util.log('Before api createShipping body: ', body)
    const res = yield call(api.put, api.PUT_ORDER_SHIP, body, apiOptions)
    if (res) {
      if (successCallback) {
        successCallback(res)
      }
      yield put({ type: shipmentActions.ACTION_QS_RESET })
      yield put({ type: actions.SELECTED_STORE_SHOULD_FETCH })
    }
    util.log('After api createShipping Response: ', res)
  } catch (error) {
    if (failCallback) {
      failCallback(error as Error)
    }
    util.log('After api createShipping error: ', error)
  }
}

/**
 *  FETCH ORDER FROM BARCODE
 */
function* watchOrderBarcodeFetch() {
  util.log('In watchOrderDetailFetch')
  while (true) {
    // const { payload } = yield take(shipmentActions.ACTION_QS_BARCODE_ORDER_FETCH)
    // yield call(fetchOrderFromBarcode, payload)
    const action = yield take(shipmentActions.ACTION_QS_BARCODE_ORDER_FETCH)
    yield call(fetchOrderFromBarcode, action)
  }
}

// function* fetchOrderFromBarcode(body) {
function* fetchOrderFromBarcode(action: ActionApiParams<{ store_id; order_id }>) {
  const { body, successCallback, failedCallback } = action
  try {
    // @ts-ignore
    const res = yield call(api.post, api.POST_ORDER, body)
    util.log('After api fetchOrderFromBarcode Response: ', res)
    if (_.has(res, 'order')) {
      util.log('Order from response: ', res.order)
      yield loadOrderToQuickShip(res.order, body)
      if (p.op.isWeb()) {
        yield handleOrderResponse(res)
      }
      // yield put({ type: shipmentActions.ACTION_QS_ORDER_LOAD, payload: res.order })
    } else {
      yield failedLoadOrderToQuickShip()
    }
    if (_.isFunction(successCallback)) {
      successCallback(res)
    }
  } catch (err) {
    util.log('After api fetchOrderFromBarcode error: ', err)
    yield failedLoadOrderToQuickShip()
    if (_.isFunction(failedCallback)) {
      failedCallback(err)
    }
  }
}

function* loadOrderToQuickShip(order, { store_id, order_id }) {
  // yield put({ type: shipmentActions.ACTION_QS_ORDER_LOAD, payload: order })
  // prepare data for fullfill the shipping
  const shipment = {
    store_id,
    order_id,
    products: [],
    tracking_number: '',
    shipping_type_id: order.shipping_type_id || 0,
    // note: '### Shipped by Quick Ship mode ###',
  }

  order.products.forEach(function appendProduct(product) {
    const { pp_id, qty, shipped_qty } = product
    // if (shipped_qty > 0) {
    //   shipment['warning'] = 'มีการส่งของไปบางส่วนแล้ว ไม่สามารถใช้งานฟังก์ชั่นจัดส่งของแบบรวดเร็วได้'
    //   shipment.products = []
    //   appendProduct.stop = true
    //   return
    // }
    const willShipQty = parseInt(qty) - parseInt(shipped_qty)
    if (willShipQty > 0) {
      shipment.products.push({ pp_id, qty: willShipQty }) // qty - shipped_qty คือจัดส่งที่เหลือทั้งหมด
    }
  })

  yield put({ type: shipmentActions.ACTION_QS_ORDER_AND_SHIPMENT_LOAD, payload: { order, shipment } })

  const shipQty = shipment.products.reduce((prevQty, product) => prevQty + product.qty, 0)
  if (order.type !== 3 && shipQty > 0) {
    // ช่วย validate เรื่อง type 3 เป็นสินค้าดึงมาจากร้านอื่น
    if (xUtil.isOrderInCancelledState(order.state)) {
      let camState = 11
      if (order.type === 2 && order.state > 181) {
        // 182, 183
        camState = 12 // ยกเลิกโดยตัวแทน
      } else if (order.type === 1 && order.state > 181) {
        // 182, 183
        camState = 13 // ยกเลิกโดยลูกค้า
      }
      yield put({ type: shipmentActions.ACTION_QS_CAMERA_STATES_CHANGE, payload: { order: camState, ship: 8 } })
    } else {
      yield put({ type: shipmentActions.ACTION_QS_CAMERA_STATES_CHANGE, payload: { order: 8, ship: 1 } })
    }
  } else {
    yield put({ type: shipmentActions.ACTION_QS_CAMERA_STATES_CHANGE, payload: { order: 8, ship: 8 } })
  }
}

function* failedLoadOrderToQuickShip() {
  yield put({
    type: shipmentActions.ACTION_QS_CAMERA_STATES_CHANGE,
    payload: { order: -1, ship: -1 },
  })
}

// จัดระเบียบ order จาก api จัดลง UI
function* loadOrderToUI(order) {
  let maxQty = 0
  if (_.has(order, 'products')) {
    order.products.forEach((product, index) => {
      const shipped_qty = product.shipped_qty ? product.shipped_qty : 0
      order.products[index].qty = product.qty - shipped_qty
      order.products[index].selectedQty = product.qty // เพื่อ support qty picker
      maxQty += parseInt(product.qty)
    })
  }
  try {
    yield put({ type: shipmentActions.ACTION_SHIPMENT_ORDER_LOAD, payload: fromJS(order) })
    yield put({ type: shipmentActions.ACTION_SHIPMENT_SET_CURRENT_QTY, payload: maxQty })
    yield put({ type: shipmentActions.ACTION_SHIPMENT_SET_MAX_QTY, payload: maxQty })
  } catch (error) {
    util.log('After loadOrderToUI: ', error)
  }
}

function appendDataChange(source, editing, target, key, parseType) {
  // Get non-null changed value
  if (source.get(key) && editing.get(key) && editing.get(key) !== '' && source.get(key) !== null) {
    if (source.get(key) !== editing.get(key)) {
      switch (parseType) {
        case 'int':
          target[key] = parseInt(editing.get(key))
          break
        case 'float':
          target[key] = parseFloat(editing.get(key))
          break
        case 'string':
          target[key] = editing.get(key).toString()
          break
        default:
          target[key] = editing.get(key).toString()
      }
    }
  }
}
