// import EntypoIcon from 'react-native-vector-icons/Entypo'

// import BackIcon from '../../components/BackIcon'
// import XTabNavIcon from '../../components/XTabNavIcon'

import BaseUIActivePackageDetail from 'xui/modules/subscription/BaseUIActivePackageDetail'

export default class ActivePackageDetail extends BaseUIActivePackageDetail {
  static displayName = 'ActivePackageDetail'
}
