import React from 'react'
import { connect } from 'react-redux'

import { getState, getDispatch } from 'x/modules/orderlist/StoreOrderSingleTabListViewConnect'
import {
  IERPSubmitDocumentOrderListViewProps,
  IOrderListFilterPreference,
  IOrderListFilterTemplate,
  IOrderListItem,
  IOrderListViewState,
} from 'x/index'
import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'
import * as api from 'x/utils/api'
import CONS from 'x/config/constants'
import * as NavActions from 'x/utils/navigation'

import XButton from 'xui/components/XButton'
import HStack from 'xui/components/HStack'

import VStack from 'xui/components/VStack'
import XText from 'xui/components/XText'
import ErpLogo from 'xui/components/ErpLogo'
import _ from 'lodash'
import OrderListFilterTemplateSelector from 'xui/components/orders/OrderListFilterTemplateSelector'
import BaseUIOrderListView from './BaseUIOrderListView'

class ERPSubmitDocumentOrderListView extends BaseUIOrderListView<IERPSubmitDocumentOrderListViewProps, IOrderListViewState> {
  static displayName = 'ERPSubmitDocumentOrderListView'

  isTaxInvoice = () => {
    const is_tax_invoice = util.getNavParam(this.props, 'is_tax_invoice')
    return is_tax_invoice
  }

  // getOverrideRequestBody = (): Partial<IFetchOrdersRequestBody> => {
  //   const { ordersFilterSetting: ofs } = this.state
  //   const overrideRequestBody: Partial<IFetchOrdersRequestBody> = util.getNavParam(this.props, 'overrideRequestBody', {})

  //   let payment_states = []
  //   if (_.isBoolean(ofs.hasPaymentCompletedAt) && ofs.hasPaymentCompletedAt === true) {
  //     // ชําระเงินแล้ว
  //     payment_states = [119]
  //   }

  //   if (_.isBoolean(ofs.hasPaymentCompletedAt) && ofs.hasPaymentCompletedAt === false) {
  //     // ยังไม่ชําระเงิน
  //     payment_states = [111, 112, 113, 114, 115]
  //   }

  //   let shipping_states = []
  //   if (_.isBoolean(ofs.hasShippingCompletedAt) && ofs.hasShippingCompletedAt === true) {
  //     // จัดส่งแล้ว
  //     shipping_states = [129]
  //   }

  //   if (_.isBoolean(ofs.hasShippingCompletedAt) && ofs.hasShippingCompletedAt === false) {
  //     // ยังไม่จัดส่ง
  //     shipping_states = [121, 122]
  //   }

  //   if (payment_states.length > 0) {
  //     overrideRequestBody.payment_states = payment_states
  //   }

  //   if (shipping_states.length > 0) {
  //     overrideRequestBody.shipping_states = shipping_states
  //   }

  //   return overrideRequestBody
  // }

  getErpDocumentTypeLabel = () => {
    const erp_doc_type = util.getNavParam(this.props, 'erp_doc_type')

    // if (erp_doc_type === 12) {
    //   return 'ใบแจ้งหนี้'
    // }

    // if (erp_doc_type === 14) {
    //   return 'ใบเสร็จรับเงิน'
    // }

    if (_.isNumber(erp_doc_type)) {
      return CONS.ERP_DOC_TYPE_OPTIONS.find(({ id }) => id === erp_doc_type).label
    }

    return ''
  }

  getDocumentLabel = () => {
    const docTypeText = this.getErpDocumentTypeLabel()
    const is_tax_invoice = this.isTaxInvoice()
    return `${docTypeText}${is_tax_invoice ? '/ใบกำกับภาษี' : ''}`
  }

  getTabLabel = () => `สร้าง${this.getDocumentLabel()}`

  renderExtraHeader = () => {
    const { navigation } = this.props
    const { ordersFilterSetting } = this.state
    const erpChannel = util.getNavParam(this.props, 'erpChannel')

    const selectedTemplate = ordersFilterSetting && 'id' in ordersFilterSetting ? ordersFilterSetting : null

    return (
      <VStack w='full' borderBottomWidth='1' borderBottomColor='gray.300'>
        <VStack w='full' p='2' space='1'>
          <HStack w='full' alignItems='center' space='1'>
            <ErpLogo erpSystemId={erpChannel.erp_system_id} />
            <XText>{erpChannel.name}</XText>
          </HStack>

          <HStack w='full' alignItems='center' space='1'>
            {selectedTemplate && (
              <OrderListFilterTemplateSelector
                type='button'
                navigation={navigation}
                selectedTemplate={selectedTemplate}
                onSelect={this.onOrderListFilterTemplateSelect}
                onSaveSuccess={this.onOrderListFilterTemplateSaveSuccess}
              />
            )}
          </HStack>
        </VStack>
      </VStack>
    )
  }

  // renderExtraHeader = () => {
  //   const { ordersFilterSetting } = this.state
  //   const erpChannel = util.getNavParam(this.props, 'erpChannel')

  //   let paymentStatusText = 'ทั้งหมด'
  //   const hasPaymentCompletedAtUsed = _.isBoolean(ordersFilterSetting.hasPaymentCompletedAt)
  //   if (hasPaymentCompletedAtUsed && ordersFilterSetting.hasPaymentCompletedAt) {
  //     paymentStatusText = 'ชำระครบถ้วน'
  //   }
  //   if (hasPaymentCompletedAtUsed && !ordersFilterSetting.hasPaymentCompletedAt) {
  //     paymentStatusText = 'ชำระไม่ครบถ้วน'
  //   }

  //   let shippingStatusText = 'ทั้งหมด'
  //   const hasShippingCompletedAtUsed = _.isBoolean(ordersFilterSetting.hasShippingCompletedAt)
  //   if (hasShippingCompletedAtUsed && ordersFilterSetting.hasShippingCompletedAt) {
  //     shippingStatusText = 'จัดส่งครบถ้วน'
  //   }
  //   if (hasShippingCompletedAtUsed && !ordersFilterSetting.hasShippingCompletedAt) {
  //     shippingStatusText = 'จัดส่งไม่ครบถ้วน'
  //   }

  //   return (
  //     <VStack w='full' p='2'>
  //       <XCard>
  //         <HStack w='full' p='2' space='1'>
  //           <HStack w='150px' alignItems='center' space='1'>
  //             <ErpLogo erpSystemId={erpChannel.erp_system_id} />
  //             <XText>{erpChannel.name}</XText>
  //           </HStack>
  //           <VStack flex={1}>
  //             <HStack alignItems='center' justifyContent='flex-end' space='1'>
  //               <XText variant='inactive' w='76px'>
  //                 สถานะชำระ:
  //               </XText>
  //               <XText w='106px' textAlign='right'>
  //                 {paymentStatusText}
  //               </XText>
  //             </HStack>
  //             <HStack alignItems='center' justifyContent='flex-end' space='1'>
  //               <XText variant='inactive' w='76px'>
  //                 สถานะจัดส่ง:
  //               </XText>
  //               <XText w='106px' textAlign='right'>
  //                 {shippingStatusText}
  //               </XText>
  //             </HStack>
  //           </VStack>
  //         </HStack>
  //       </XCard>
  //     </VStack>
  //   )
  // }

  onPressOrderListItem = (od: IOrderListItem) => {
    const { navigation } = this.props

    // this.toggleCheckOrderListItem(od.id)
    navigation.dispatch(
      NavActions.navToOrderDetail({
        order_id: od.id,
        store_id: this.getStoreId(),
      })
    )
  }

  getOverridePreSetupInitialState = (): Partial<IOrderListViewState> => ({
    isCheckable: true,
    // availableTabKeys: ['myTasks_ship'],
    // availableTabKeys: ['myTasks_ship', 'myTasks_ship_not_ready', 'myTasks_ship_all'],
    // availableTabKeys: ['myTasks_ship', 'myTasks_ship_not_ready'],
    availableTabKeys: [],
    displayMode: 'page',
    displayPageLimit: 30,
  })

  // hasPermissionToPrintDocumentsWithAlert = () => {
  //   const hasPermissionToPrint = acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.ORDER_PRINT_SHIPPING)
  //   if (!hasPermissionToPrint) {
  //     p.op.alert('ไม่มีสิทธิ์ในการพิมพ์', 'กรุณาติดต่อเจ้าของร้านหากคุณต้องการพิมพ์เอกสารเหล่านี้')
  //   }
  //   return acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.ORDER_PRINT_SHIPPING)
  // }

  onPressSubmit = async () => {
    // const hasPermissionToPrintDocumentsWithAlert = this.hasPermissionToPrintDocumentsWithAlert()
    // if (!hasPermissionToPrintDocumentsWithAlert) {
    //   return
    // }

    if (this.isSubmitting) {
      return
    }
    this.isSubmitting = true
    const { navigation } = this.props
    const { tabName } = this.state
    await util.setStatePromise(this, { isSubmitting: true })

    const erpChannel = util.getNavParam(this.props, 'erpChannel')
    const erp_doc_type = util.getNavParam(this.props, 'erp_doc_type')
    const is_tax_invoice = util.getNavParam(this.props, 'is_tax_invoice', false)

    // const erp_doc_types_not_in = []

    // if (erp_doc_type === 12) {
    //   erp_doc_types_not_in.push(14)
    // }

    // if (erp_doc_type === 14) {
    //   erp_doc_types_not_in.push(12)
    // }

    // const rootAPI = settings.app.GO_API_ROOT
    // const url = `${rootAPI}/erp/mock/document/create?`
    const store_id = this.getStoreId()
    const order_ids = this.getCheckedOrderIds()

    // console.log('onPressSubmit store_id => ', store_id)
    // console.log('onPressSubmit order_ids => ', order_ids)
    // console.log('onPressSubmit erp_doc_type => ', erp_doc_type)
    // console.log('onPressSubmit erp_doc_types_not_in => ', erp_doc_types_not_in)
    // console.log('onPressSubmit is_tax_invoice => ', is_tax_invoice)

    // const serialzedQueryParamsString = util.serializeObjectToQueryParamsString({
    //   store_id,
    //   order_ids,
    //   erp_doc_types_not_in,
    //   is_tax_invoice,
    // })

    // if (serialzedQueryParamsString) {
    //   url = `${url}&${serialzedQueryParamsString}`
    // }

    // console.log('### onPressSubmit url => ', url)

    try {
      const res = await api.fetchCreateErpDocument({ store_id, erp_ch_id: erpChannel.id, order_ids, erp_doc_type, is_tax_invoice })

      if (res.bgjob_uuid) {
        await util.alertWithNavToBgJobListView({
          title: 'สร้าง "คิวคำสั่งงาน" สำเร็จ',
          message: `คิวคำสั่งงาน สำหรับการ "สร้าง ${this.getDocumentLabel()}" ได้ถูกสร้างแล้ว ระบบกำลังดำเนินการอยู่เบื้องหลัง และอาจใช้เวลาซักครู่จนกว่าจะครบทุกออเดอร์`,
          dispatch: navigation.dispatch,
          selectedStoreId: store_id,
          onUserConfirm: () => {
            console.log('close alert')
          },
          initBgJobUUID: res.bgjob_uuid,
        })
      }

      this.uncheckCheckAllOrderListItems()
      await this.loadInitOrders(tabName)
    } catch (error) {
      console.log('### onPressSubmit error => ', error)
    }

    await util.setStatePromise(this, { isSubmitting: false })

    this.isSubmitting = false
  }

  // renderPrintingOptionOverlayButton = () => (
  //   <PrintingOptionOverlayButton
  //     selectedStore={this.props.selectedStore}
  //     // renderButton={this._renderMultiOpFooterButton}
  //     renderButton={this._renderPrintingOptionFooterButton}
  //     onSubmit={this._onPressPrintDocuments}
  //     // disabledBill={true}
  //   />
  // )

  renderSubmitCheckOrdersButton = () => {
    const orderIds = this.getCheckedOrderIds()
    const checkOrderCount = orderIds.length
    const exceededLimit = 50
    const { isSubmitting, isOrdersRefreshing: isRefreshing } = this.state
    const isExceeded = checkOrderCount > exceededLimit
    const isActive = checkOrderCount > 0

    const btnTextCondition = isActive
      ? `สร้าง ${this.getDocumentLabel()}`
      : `กรุณาเลือกออเดอร์ที่สร้าง ${this.getDocumentLabel()} โดยแตะปุ่มกลมทางซ้าย`
    return (
      <HStack w='full' p='1' flexWrap='wrap'>
        <XButton
          w='full'
          minH='9'
          isDisabled={!isActive || isExceeded || isSubmitting || isRefreshing}
          disabled={!isActive || isExceeded || isSubmitting || isRefreshing}
          isLoading={isSubmitting}
          onPress={this.onPressSubmit}>
          {!isExceeded && !isSubmitting && !isRefreshing ? btnTextCondition : null}
          {isExceeded && !isSubmitting && !isRefreshing ? `กรุณาเลือกดำเนินงานไม่เกิน ${exceededLimit} ออเดอร์ต่อครั้ง` : null}
        </XButton>
      </HStack>
    )
  }

  _mockOnPressOrderListItem = (od: IOrderListItem) => {
    p.op.alert('_mockOnPressOrderListItem', JSON.stringify(od))
  }

  _mockOnPressSubmitCheckedOrders = (orderIds: number[]) => {
    p.op.alert('_mockOnPressSubmitCheckedOrders', JSON.stringify(orderIds))
  }
}

// export default OrderListView

export default connect(
  (state) => ({
    ...getState(state),
  }),
  (dispatch) => ({
    ...getDispatch(dispatch),
  })
)(ERPSubmitDocumentOrderListView)
