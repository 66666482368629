import { Map, fromJS } from 'immutable'
import actions from '../../config/actions'
// import {
// // getFBAccessToken,
// // setFBAccessTokenInProfile,
// } from '../../redux/selectors'
// import * as util from '../../utils/util'

const { SUBSCRIPTION_LOAD } = actions

const initWarning: {
  type: 'warning' | 'danger' | 'success'
  message: string | null
  closeable: boolean | null
  priority: number | null
} = {
  type: 'warning',
  message: null,
  closeable: true,
  priority: null,
}

// Initial state
const initialState = Map({
  user_id: null,
  billing_date: null,
  startBillingDate: null,
  endBillingDate: null,
  order_count_curr: null,
  order_count_quota: null,
  packages: null,

  lastUpdated: null,
  isActiveWarning: false,
  warning: initWarning,
})

// Actions
export const INITIALIZE_STATE = 'Subscription/INITIALIZE'

export function loadSubscription(subscriptionData) {
  return {
    type: SUBSCRIPTION_LOAD,
    payload: subscriptionData,
  }
}

// Reducer
export default function SubscriptionStateReducer(state = initialState, action: { [key: string]: any } = { type: null, payload: null }) {
  const newState = state
  const { type, payload } = action

  switch (type) {
    case INITIALIZE_STATE:
    case SUBSCRIPTION_LOAD:
      return newState.mergeDeep(fromJS(payload))

    default:
      return state
  }
}
