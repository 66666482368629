import React from 'react'
import _ from 'lodash'
import { Map } from 'immutable'
import { connect } from 'react-redux'
import { Clipboard } from 'react-native'

import * as util from 'x/utils/util'
import { getStoreSelected, getSelectedProduct } from 'x/redux/selectors'
import * as NavActions from 'x/utils/navigation'
import p from 'x/config/platform-specific'

import {
  IProductDetailItemMap,
  IVariantMkpInfo,
  ISelectedStoreMap,
  IProductDetailVariant,
  IComputedVariantMkpInfo,
  IAnyObject,
  INavHeaderButtonProps,
  IProductDetailMkpInfoDetailViewNavParams,
  IXScreenProps,
} from 'x/index'

import XText from 'xui/components/XText'
import XCard from 'xui/components/XCard'
import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import MkpLogo from 'xui/components/MkpLogo'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import XCustomHeader from 'xui/components/XCustomHeader'
import XImage from 'xui/components/XImage'
import Box from 'xui/components/Box'
import XButton from 'xui/components/XButton'
import XIcon from 'xui/components/XIcon'
import XSellyLogo from 'xui/components/XSellyLogo'

interface IProps extends IXScreenProps<IProductDetailMkpInfoDetailViewNavParams> {
  // mkpChannels: IMKPChannelDetail[]
  selectedStore: ISelectedStoreMap
  selectedProduct: IProductDetailItemMap
}

interface IState {
  isInitialized?: boolean
  variants: IProductDetailVariant[]

  //  เอาไว้แสดงเฉพาะตัวที่สนใจ (pp_id ส่งมาจาก nav param)
  visibleVariantIndex?: number
}

class ProductDetailMkpInfoDetailView extends React.Component<IProps, IState> {
  static displayName = 'ProductDetailMkpInfoDetailView'

  constructor(props) {
    super(props)
    this.state = {
      isInitialized: false,
      variants: [],
    }
  }

  async componentDidMount() {
    await this._initialize()
    await util.delay(200)
    await util.setStatePromise(this, { isInitialized: true })
  }

  _initialize = async () => {
    const { selectedProduct } = this.props
    let variants: IProductDetailVariant[]
    try {
      variants = Map.isMap(selectedProduct) ? selectedProduct.get('variants').toJS() : []
    } catch (error) {
      variants = []
    }

    let visibleVariantIndex = null
    const ppId = util.getNavParam(this.props, 'pp_id')

    if (ppId) {
      const foundIndex = variants.findIndex((v) => v.pp_id === ppId)
      visibleVariantIndex = foundIndex > -1 ? foundIndex : null
    }

    await util.setStatePromise(this, { variants, visibleVariantIndex })
  }

  // getComputedMkpInfo = (v: IProductDetailVariant, idx: number): IComputedVariantMkpInfo | null => {
  //   const { mkp } = v
  getComputedMkpInfo = (mkp: IVariantMkpInfo, variantIndex: number): IComputedVariantMkpInfo | null => {
    if (!_.isObject(mkp) || !mkp.mkp_ch_id) {
      return null
    }

    const { mkp_ch_id } = mkp
    const mkpChannel = util.getMKPChannelByUGID(mkp_ch_id)

    if (!_.isObject(mkpChannel) || !mkpChannel.mkp_id) {
      return null
    }

    const { mkp_id, name: mkpName } = mkpChannel

    return {
      ...mkp,
      mkpName,
      mkp_id,
      variantIndex,
    }
  }

  filterByIsNotNullObject = (obj: IAnyObject) => _.isObject(obj)

  sortByMkpId = (vMkpA: IComputedVariantMkpInfo, vMkpB: IComputedVariantMkpInfo) => vMkpA.mkp_id - vMkpB.mkp_id

  getComputedMkpInfos = (): IComputedVariantMkpInfo[] => {
    const variants = this.getProductVariants()
    // console.log('ProductDetailMkpInfoDetailView getComputedMkpInfos variants => ', variants)

    const variantMkpInfoConcated = []

    for (let variantIndex = 0; variantIndex < variants.length; variantIndex++) {
      const v = variants[variantIndex]
      const { mkp = [] } = v
      for (let mkpIndex = 0; mkpIndex < mkp.length; mkpIndex++) {
        const mkpFocus = mkp[mkpIndex]
        variantMkpInfoConcated.push(this.getComputedMkpInfo(mkpFocus, variantIndex))
      }
    }

    // return variants.map(this.getComputedMkpInfo).filter(this.filterByIsNotNullObject).sort(this.sortByMkpId)
    return variantMkpInfoConcated.filter(this.filterByIsNotNullObject).sort(this.sortByMkpId)
  }

  getProductVariants = (): IProductDetailVariant[] =>
    // const { selectedProduct } = this.props
    // let variants: IProductDetailVariant[]
    // try {
    //   variants = Map.isMap(selectedProduct) ? selectedProduct.get('variants').toJS() : []
    // } catch (error) {
    //   variants = []
    // }
    // return variants || []
    this.state.variants

  // hasMkpPaired = (): boolean => {
  //   const mkpInfos = this.getComputedMkpInfos()
  //   console.log('ProductDetailMkpInfoDetailView hasMkpPaired mkpInfos => ', mkpInfos)

  //   return mkpInfos.length > 0
  // }

  getNonDuplicatedMkpIdMkpInfos = () => {
    const mkpInfos = this.getComputedMkpInfos()
    return mkpInfos.filter((v, i, a) => {
      const isNotDuplicated = a.findIndex((v2) => v2.mkp_id === v.mkp_id) === i
      return isNotDuplicated
    })
  }

  // renderMkpInfos = () => {
  //   // const mkpInfos = this.getComputedMkpInfos()
  //   const mkpInfos = this.getNonDuplicatedMkpIdMkpInfos()
  //   console.log('ProductDetailMkpInfoDetailView renderMkpInfos mkpInfos => ', mkpInfos)

  //   return mkpInfos.map(this.renderMkpInfoItem)
  // }

  // renderMkpInfoItem = (cMkp: IComputedVariantMkpInfo, idx: number) => {
  //   const { mkp_id, mkpName } = cMkp
  //   return (
  //     <HStack key={`MkpInfoItem_${idx}_${mkp_id}`} px='2' py='1' alignItems='center' borderWidth='1' borderColor='muted.200' space='2'>
  //       <MkpLogo width={24} height={24} mkpId={mkp_id} />
  //       <XText>{mkpName}</XText>
  //     </HStack>
  //   )
  // }

  _goBack = () => {
    util.navGoBack(this.props)
  }

  _navToMkpProductDetailView = async (item_uuid: string, mkpChannelId: number, mkpId: number) => {
    // console.log('_navToMkpProductDetailView item_uuid => ', item_uuid)
    const { navigation } = this.props
    const storeId = util.getNavParam(this.props, 'store_id')
    navigation.dispatch(
      NavActions.navToMkpProductDetailView({
        store_id: storeId,
        mkp_ch_id: mkpChannelId,
        item_uuid,
        mkp_id: mkpId,
        fromProductDetail: true,
      })
    )
  }

  getHeaderLeftProps = (): INavHeaderButtonProps => ({
    backIcon: true,
    onPressItem: this._goBack,
  })

  // getHeaderRightProps = (): INavHeaderButtonProps => ({

  // })

  renderHeader = () => <XCustomHeader title='รายละเอียดสินค้าที่ถูกผูกกับช่องทางขาย' headerLeftProps={this.getHeaderLeftProps()} />

  renderContent = () => {
    const { isInitialized = false } = this.state
    if (!isInitialized) {
      return null
    }

    const variants = this.getProductVariants() || []
    if (!variants || !_.isArray(variants) || variants.length === 0) {
      return (
        <VStack w='full'>
          <XText>ไม่สามารถถอดรหัสข้อมูลตัวเลือกสินค้า กรุณาติดต่อทีมงาน XSelly</XText>
        </VStack>
      )
    }

    return <VStack w='full'>{variants.map(this.renderVariantItem)}</VStack>
  }

  renderVariantItem = (v: IProductDetailVariant, variantIndex: number) => {
    const { selectedProduct, selectedStore } = this.props
    const { visibleVariantIndex } = this.state
    if (_.isNumber(visibleVariantIndex) && visibleVariantIndex > -1 && visibleVariantIndex !== variantIndex) {
      return null
    }

    const isUnpaired = _.isNil(v.mkp) || !_.isArray(v.mkp) || (_.isArray(v.mkp) && v.mkp.length === 0)

    let xVariantThumbnailUri = selectedProduct.getIn(['thumbnail_uris', 0])
    if (v.thumbnail_url && v.thumbnail_url !== '') {
      xVariantThumbnailUri = v.thumbnail_url
    }

    return (
      <VStack
        w='full'
        key={variantIndex}
        px='2'
        pt='3'
        pb='4'
        space='1'
        bg={variantIndex % 2 !== 0 ? 'muted.100' : 'white'}
        borderBottomWidth='1'
        borderBottomColor='muted.300'>
        <HStack w='full' alignItems='center' space='1'>
          <XSellyLogo width={24} height={24} />
          <XText variant='inactive'>{`ตัวเลือกที่ ${variantIndex + 1}:`}</XText>
          <XText bold flex={1}>
            {v.name}
          </XText>
          <XButton
            variant='outline'
            size='xs'
            w='114px'
            // h='36px'
            px='1.5'
            py='0.5'
            leftIcon={<XIcon name='link' family='FontAwesome' />}
            onPress={() => this.navToMkpMappingChannelListViewPicker(v)}>
            ผูกช่องทางขาย
          </XButton>
        </HStack>

        <VStack w='16' h='16'>
          <XImage w='16' h='16' borderRadius='lg' resizeMode='cover' source={{ uri: xVariantThumbnailUri }} />
          <Box position='absolute' top='1' left='1'>
            <XSellyLogo width={16} height={16} />
          </Box>
        </VStack>

        {this._renderVariantIdLabel(v, variantIndex)}
        <VStack w='full' pt='1' space='1'>
          {isUnpaired ? (
            <XText w='full' p='2' textAlign='center' variant='inactive'>
              ยังไม่มีการผูกกับสินค้าในช่องทางขาย
            </XText>
          ) : (
            v.mkp.map((mkp: IVariantMkpInfo, mkpIndex: number) => this.renderMkpInfoDetailItem(mkp, mkpIndex, variantIndex))
          )}
        </VStack>
      </VStack>
    )
  }

  _renderVariantIdLabel = (v: IProductDetailVariant, variantIndex: number) => {
    if (!v || !v.pp_id) {
      return null
    }

    const id = v.pp_id
    return (
      <HStack w='full'>
        <XText
          variant='inactive'
          fontSize='2xs'
          numberOfLines={1}
          // @ts-ignore
          onPress={() => {
            Clipboard.setString(id.toString())
            p.op.showToast(`คัดลอก ${id} แล้ว`, 'success')
          }}>
          {`ID:${id}`}
        </XText>
      </HStack>
    )
  }

  _renderNavReference = (spv: IProductDetailVariant) => {
    if (!spv) {
      return null
    }

    const { selectedProduct } = this.props
    const pName = selectedProduct.get('name')
    let thumbnailUri = selectedProduct.getIn(['thumbnail_uris', 0])

    if (spv.thumbnail_url && spv.thumbnail_url !== '') {
      thumbnailUri = spv.thumbnail_url
    }

    return (
      <VStack w='full' py='1.5' px='2' space='1' borderWidth='1' borderColor='muted.400' bg='primary.50'>
        {/* <XText bold>renderNavReference fn</XText> */}
        {/* <XText>{JSON.stringify(selectedProductVariant)}</XText> */}
        <HStack w='full' space='1' alignItems='center' justifyContent='center'>
          <XText variant='inactive' bold>
            ผูกสินค้าจาก XSelly ไปยัง ช่องทางขาย
          </XText>
        </HStack>
        <HStack w='full' space='1.5'>
          <VStack w='16' h='16'>
            <XImage w='16' h='16' borderRadius='lg' resizeMode='cover' source={{ uri: thumbnailUri }} />

            <Box position='absolute' top='1' left='1'>
              <XSellyLogo width={24} height={24} />
            </Box>
          </VStack>

          <VStack flex={1}>
            <HStack w='full'>
              <VStack flex={1}>
                <XText w='full' numberOfLines={2}>
                  {pName}
                </XText>
              </VStack>
              <VStack _web={{ w: '70px' }} w='90px' alignItems='flex-end'>
                <XText w='full' textAlign='right' bold numberOfLines={3}>
                  {spv.name}
                </XText>
              </VStack>
            </HStack>
          </VStack>
        </HStack>
      </VStack>
    )
  }

  _renderMkpNavReference = (vmi: IComputedVariantMkpInfo) => {
    // {
    //   "mkp_ch_id": 147,
    //   "item_id": "1004135478",
    //   "item_variant_id": "15985820",
    //   "item_uuid": "0187dfae-6dd9-f81e-e096-10f2e5642bbf",
    //   "item_name": "ปากาเมจิก",
    //   "variant_name": "ลายไทย, 2เล่ม",
    //   "thumbnail_url": "https://obs.line-scdn.net:443/r/ect/ect/image_16788907178383675196c6b9206t114dfdcd"
    // }

    if (!vmi || !vmi.item_id) {
      return null
    }

    // const { selectedProduct } = this.props
    const pName = vmi.item_name
    const vName = vmi.variant_name
    let thumbnailUri = vmi.thumbnail_url

    if (vmi.img_url && vmi.img_url !== '') {
      thumbnailUri = vmi.img_url
    }

    return (
      <VStack w='full' py='1.5' px='2' space='1' borderWidth='1' borderColor='muted.400' bg='blue.100'>
        {/* <XText bold>renderNavReference fn</XText> */}
        {/* <XText>{JSON.stringify(selectedProductVariant)}</XText> */}
        <HStack w='full' space='1' alignItems='center' justifyContent='center'>
          <XText variant='inactive' bold>
            ย้ายการผูกสินค้าไปยังตัวเลือกสินค้าอื่นของ XSelly
          </XText>
        </HStack>
        <HStack w='full' space='1.5'>
          <VStack w='16' h='16'>
            <XImage w='16' h='16' borderRadius='lg' resizeMode='cover' source={{ uri: thumbnailUri }} />

            <Box position='absolute' top='1' left='1'>
              <MkpLogo mkpId={vmi.mkp_id} width={24} height={24} />
            </Box>
          </VStack>

          <VStack flex={1}>
            <HStack w='full'>
              <VStack flex={1}>
                <XText w='full' numberOfLines={2}>
                  {pName}
                </XText>
              </VStack>
              <VStack _web={{ w: '70px' }} w='90px' alignItems='flex-end'>
                <XText w='full' textAlign='right' bold numberOfLines={3}>
                  {vName}
                </XText>
              </VStack>
            </HStack>
          </VStack>
        </HStack>
      </VStack>
    )
  }

  _onMappingSuccess = async () => {
    // const { navigation, mkpProductContext } = this.props
    const onMappingSuccess = util.getNavParam(this.props, 'onMappingSuccess')

    if (onMappingSuccess) {
      await onMappingSuccess()
      // util.navGoBack(this.props)

      await this._initialize()
      p.op.showToast('เพิ่มการผูกสินค้าเสร็จสิ้น', 'success')
    }

    // mkpProductContext.destroy(mkpProductContext)
    // util.navGoBack(this.props)
  }

  navToMkpMappingChannelListViewPicker = (selectedProductVariant: IProductDetailVariant) => {
    const { navigation } = this.props
    const renderNavReference = () => this._renderNavReference(selectedProductVariant)

    navigation.dispatch(
      NavActions.navToMkpMappingChannelListViewPicker({
        selectedProductVariant,
        renderNavReference,
        onMappingSuccess: this._onMappingSuccess,
      })
    )
  }

  _navToProductListMkpToXSellyMappingView = (mkp: IComputedVariantMkpInfo) => {
    const { navigation, selectedStore } = this.props
    // const selectedProductVariant = util.getNavParam(this.props, 'selectedProductVariant')
    // const renderNavReference = util.getNavParam(this.props, 'renderNavReference')

    navigation.dispatch(
      NavActions.navToProductListViewMkpToXSellyMapping({
        store_id: selectedStore.get('id'),
        // mkp_channel_id: channel.id,
        // mkp_ch_id: channel.id,
        // mkp_id: channel.mkp_id,

        selectedVariantMkp: mkp,
        renderNavReference: () => this._renderMkpNavReference(mkp),
        onMappingSuccess: this._onMappingSuccess,
      })
    )
  }

  renderMkpInfoDetailItem = (mkp: IVariantMkpInfo, mkpIndex: number, variantIndex: number) => {
    const { selectedStore, selectedProduct } = this.props
    const computedMkp = this.getComputedMkpInfo(mkp, variantIndex)
    const onPressItem = () => this._navToMkpProductDetailView(computedMkp.item_uuid, computedMkp.mkp_ch_id, computedMkp.mkp_id)
    // const renderNavReference = () => this._renderMkpNavReference(computedMkp)

    const labelID = computedMkp.item_variant_id || computedMkp.item_id
    let thumbnailUri = computedMkp.thumbnail_url

    if (!_.isNil(computedMkp.img_url) && computedMkp.img_url !== '') {
      thumbnailUri = computedMkp.img_url
    }

    // if (computedMkp.)

    return (
      <XCard key={mkpIndex}>
        <XCard.Body>
          <HStack w='full' space='2' alignItems='center'>
            <VStack w='full' space='1'>
              <HStack w='full' space='2' alignItems='center'>
                <MkpLogo mkpId={computedMkp.mkp_id} width={24} height={24} />
                <XText variant='inactive'>{computedMkp.mkpName}</XText>
              </HStack>

              <HStack w='full' space='2' alignItems='center'>
                {/* <Box w='48px' h='48px' borderRadius='lg' overflow='hidden'>
                  <XImage source={{ uri: thumbnailUri }} w='48px' h='48px' />
                  <Box position='absolute' left='1' top='1'>
                <MkpLogo mkpId={computedMkp.mkp_id} width={24} height={24} />
              </Box>
                </Box> */}

                <VStack w='16' h='16'>
                  <XImage w='16' h='16' borderRadius='lg' resizeMode='cover' source={{ uri: thumbnailUri }} />
                  <Box position='absolute' top='1' left='1'>
                    <MkpLogo mkpId={computedMkp.mkp_id} width={16} height={16} />
                  </Box>
                </VStack>

                <VStack flex={1}>
                  <XText flex={1} variant='inactive' numberOfLines={2}>
                    {computedMkp.item_name}
                  </XText>
                  <XText flex={1} numberOfLines={2} bold>
                    {computedMkp.variant_name}
                  </XText>
                  {/* <XText flex={1} fontSize='xs' color='blue'>
                DEBUG:: {JSON.stringify(computedMkp)}
              </XText> */}
                  {labelID ? (
                    <HStack w='full'>
                      <XText
                        variant='inactive'
                        fontSize='2xs'
                        numberOfLines={1}
                        // @ts-ignore
                        onPress={() => {
                          Clipboard.setString(labelID.toString())
                          p.op.showToast(`คัดลอก ${labelID} แล้ว`, 'success')
                        }}>
                        {`ID:${labelID}`}
                      </XText>
                    </HStack>
                  ) : null}
                </VStack>
              </HStack>
            </VStack>
          </HStack>

          <HStack w='full' pt='1' justifyContent='flex-end' space='1'>
            <XButton
              variant='outline'
              size='xs'
              w='104px'
              // h='36px'
              px='1.5'
              pt='1'
              pb='0.5'
              leftIcon={<XIcon name='unlink' family='FontAwesome' />}
              onPress={() => this._navToProductListMkpToXSellyMappingView(computedMkp)}>
              ย้ายการผูก
            </XButton>

            <XButton
              size='xs'
              px='1.5'
              pt='1'
              pb='0.5'
              w='104px'
              leftIcon={<XIcon name='document-text' family='Ionicons' />}
              variant='outline'
              onPress={onPressItem}>
              ดูรายละเอียด
            </XButton>
          </HStack>
        </XCard.Body>
      </XCard>
    )
  }

  render() {
    return (
      <XContainer>
        {this.renderHeader()}
        <XContent>{this.renderContent()}</XContent>
      </XContainer>
    )
  }
}

export default connect(
  (state) => ({
    selectedStore: getStoreSelected(state),
    selectedProduct: getSelectedProduct(state),
    // mkpChannels: getMkpChannels(state),
  }),
  (dispatch) => ({
    dispatch,
  })
)(ProductDetailMkpInfoDetailView)

// export default ProductDetailMkpInfoDetailView
