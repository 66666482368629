import BaseUIReportListView from 'xui/modules/report/BaseUIReportListView'
import { downloadFileFromUrl } from '../../utils/util-web'
// import { View } from 'react-native'

export default class ReportListView extends BaseUIReportListView {
  openFileFromUrl = async (url: string, fileName: string, type: string) => {
    await downloadFileFromUrl({ url, fileName })
  }

  // renderReportProductDailySales = () => {
  //   const { subscription, selectedStore } = this.props
  //   const { isReportProductDailySales } = this.state
  //   const r_shpg_day = subscription.get('r_shpg_day')
  //   const store_id = selectedStore.get('id')

  //   return (
  //     <ReportProductDailySales
  //       onRequestClose={() => this._handleCloseReportProductDailySales}
  //       visible={isReportProductDailySales}
  //       allowOnlyDaysFromToday={r_shpg_day}
  //       store_id={store_id}
  //     />
  //   )
  // }
}
