import React from 'react'
import { TouchableOpacity, View } from 'react-native'
// import Icon from 'xui/components/Icon'
import p from 'x/config/platform-specific'
import _ from 'lodash'
// import Ionicons from 'react-native-vector-icons/Ionicons'
// import FontAwesome from 'react-native-vector-icons/FontAwesome'
// import MaterialIcons from 'react-native-vector-icons/MaterialIcons'
// import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons'
// import AntDesign from 'react-native-vector-icons/AntDesign'

import { STYLES, COLORS } from 'x/config/styles'
import XIcon from 'xui/components/XIcon'
import { IMenuItem } from 'x/index'
import Icon from 'xui/components/Icon'
import XText from './XText'
import HStack from './HStack'
import Box from './Box'

interface XMenuListViewProps {
  menuItems: IMenuItem[]
}

export default class XMenuListView extends React.Component<XMenuListViewProps> {
  // constructor(props: XMenuListViewProps) {
  //   super(props)
  // }

  _renderMenuItems = (menu: IMenuItem, index: number) => {
    if (!menu.isShowMenu()) {
      return null
    }
    if (menu.isHeader) {
      // console.log('menu.isValidation && menu.isValidation() => ', menu.isShowMenu && menu.isShowMenu())
      if (!menu.isShowMenu()) {
        return null
      }
      return (
        <Box
          py='2'
          style={{ backgroundColor: '#aed6f1', alignItems: 'flex-start', justifyContent: 'center' }}
          key={`renderMenuItemsHeader_${index}`}>
          <XText variant='active' bold pl='2'>
            {menu.label}
          </XText>
        </Box>
      )
    }
    let ICON_LEFT = null
    if (!_.isNil(menu.customIcon)) {
      ICON_LEFT = menu.customIcon()
    }
    if (!_.isNil(menu.iconName)) {
      ICON_LEFT = <XIcon name={menu.iconName} family={menu.iconFamily} style={{ color: COLORS.TEXT_INACTIVE, ...menu.iconStyle }} />
    }
    const ICON_RIGHT = menu.onclick ? (
      <Icon name='arrow-forward' style={{ fontSize: STYLES.FONT_ICON_NORMAL, color: COLORS.APP_MAIN }} />
    ) : null
    return (
      <TouchableOpacity
        key={`renderMenuItemsBody_${index}`}
        style={
          {
            // borderBottomColor: COLORS.TEXT_INACTIVE,
            // borderBottomWidth: 1,
            // alignItems: 'center',
            // justifyContent: 'center',
            // flexDirection: 'row',
          }
        }
        onPress={() => menu.onclick()}>
        <HStack py={p.op.isWeb() ? '3' : '4'} alignItems='center' borderBottomColor='gray.200' borderBottomWidth='1'>
          {_.isNil(menu.customIcon) && _.isNil(menu.iconName) ? null : (
            <Box w='8' justifyContent='center' alignItems='center' pl='2'>
              {ICON_LEFT}
            </Box>
          )}
          <Box flex={1} justifyContent='center'>
            <XText pl='2' style={{ color: COLORS.TEXT_ACTIVE, ...menu.labelStyle }}>
              {menu.label}
            </XText>
          </Box>
          <View style={{ width: 30, marginRight: 4 }}>{ICON_RIGHT}</View>
        </HStack>
        {/* <View style={{ width: 45, justifyContent: 'center', alignItems: 'flex-end' }}>{ICON_LEFT}</View>
        <View style={{ flex: 1 }}>
          <XText variant='active'>{menu.label}</XText>
        </View>
        <View style={{ width: 30, marginRight: 15 }}>{ICON_RIGHT}</View> */}
      </TouchableOpacity>
    )
  }

  render() {
    const { menuItems } = this.props
    if (_.isNil(menuItems)) {
      return null
    }
    return menuItems.map((menu: IMenuItem, index: number) => this._renderMenuItems(menu, index))
  }
}
