import React from 'react'
import * as util from 'x/utils/util'
import _ from 'lodash'
import XText from './XText'

const XDataProtectionPolicyShopee = () => {
  const channels = util.getMKPChannels()
  if (_.isNil(channels) || channels.length < 1) {
    return null
  }
  const haveShopee = channels.some((mkp) => mkp.mkp_id === 1)
  return haveShopee ? (
    <XText variant='inactive' textAlign='center'>
      ออเดอร์จาก Shopee ที่มีอายุเกิน 90 วันจะไม่สามารถเข้าถึงได้เพื่อเป็นไปตามข้อกำหนด Data Protection Policy ของ Shopee
    </XText>
  ) : null
}

export default XDataProtectionPolicyShopee
