import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ResellerChangeGroupView from 'xui/modules/userGroup/ResellerChangeGroupView'

import * as ResellerActions from 'x/modules/reseller/ResellerState'

import { getSelectedStore, getSelectedUserGroups } from 'x/redux/selectors'

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
    selectedUserGroups: getSelectedUserGroups(state),
  }),
  (dispatch) => ({
    // fetchMyStore: bindActionCreators(StoreActions.fetchMyStore, dispatch),
    // clearAllUserList: bindActionCreators(ResellerActions.clearAllUserList, dispatch),
    changeUserGroup: bindActionCreators(ResellerActions.changeUserGroupInJoinedUsers, dispatch),
  })
)(ResellerChangeGroupView)
