import React from 'react'
import Box from 'xui/components/Box'
import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import { TouchableOpacity, View } from 'react-native'
import _ from 'lodash'
import * as util from 'x/utils/util'
import XCustomHeader from 'xui/components/XCustomHeader'
import { getConfig } from 'x/config/mode'
import p from 'x/config/platform-specific'
import XInput from 'xui/components/XInput'
import BaseStoreSettingBillView from 'x/modules/store/BaseStoreSettingBillView'
// import xCONS from 'x/config/constants'
import XText from 'xui/components/XText'
import XCard from 'xui/components/XCard'
import XIcon from 'xui/components/XIcon'
import BackIcon from 'xui/components/BackIcon'
import XContent from 'xui/components/XContent'
import XContainer from 'xui/components/XContainer'
import XSwitch from 'xui/components/XSwitch'
import ImgManager from 'xui/components/ImgManager'
import XHelpModal from 'xui/components/XHelpModal'
import FAQ from 'x/config/FAQ'
import HelpButton from 'xui/components/HelpButton'

const HEADER = 'HEADER'
const FOOTER = 'FOOTER'

export default class BaseUIStoreSettingBillView extends BaseStoreSettingBillView {
  // static displayName = 'BaseUIStoreSettingBillView'

  renderHeader = () => (
    <XCustomHeader
      title={p.op.t('StoreSetting.HeaderBillPaperang')}
      renderHeaderLeft={this._renderHeaderLeft}
      renderHeaderRight={this._renderHeaderRight}
    />
  )

  _renderHeaderRight = () => {
    const params = util.getNavParams(this.props)
    const cancelBtn = params ? params.cancelBtn : null
    const onChange = params ? params.onChange : null
    return (
      <View>
        {!_.isNil(cancelBtn) && !_.isNil(onChange) && onChange ? (
          <TouchableOpacity style={{ width: 50, height: 50, justifyContent: 'center', paddingRight: 4 }} onPress={() => cancelBtn()}>
            <XText variant='primary'>{p.op.t('Order.cancelled')}</XText>
          </TouchableOpacity>
        ) : null}
      </View>
    )
  }

  _renderHeaderLeft = () => {
    const params = util.getNavParams(this.props)
    const saveBtn = params ? params.saveBtn : null
    const onChange = params ? params.onChange : null
    return (
      <View>
        {!_.isNil(saveBtn) && !_.isNil(onChange) && onChange ? (
          <TouchableOpacity style={{ width: 50, height: 50, justifyContent: 'center', paddingLeft: 10 }} onPress={() => saveBtn()}>
            <XText variant='primary'>{p.op.t('save')}</XText>
          </TouchableOpacity>
        ) : (
          <TouchableOpacity
            style={{ width: 50, height: 40, alignItems: 'center', justifyContent: 'center' }}
            onPress={() => this._goBack()}>
            <BackIcon />
          </TouchableOpacity>
        )}
      </View>
    )
  }

  _renderTextAndSwitch = (textLabel: string, value: boolean, key: string) => (
    // let newValue = value
    // if (key === 'headerImgActive') {
    //   newValue = !value
    // } else if (key === 'footerImgActive') {
    //   newValue = !value
    // }
    <HStack alignItems='center' justifyContent='space-between' w='full'>
      <XText>{textLabel}</XText>
      <XSwitch value={value} onValueChange={(val) => this.onChangeValue(key, val)} />
    </HStack>
  )

  _goBack = () => {
    util.navGoBack(this.props)
  }

  _saveImg = async (key: string) => {
    // if (this.inProcess) {
    //   return
    // }
    // this.inProcess = true
    if (key === HEADER) {
      await util.setStatePromise(this, { uploading: true, showHeaderImgUploaded: true })
      // await util.delay(3200)
      const headerImgRef = this.thisImgManagerRef.current
      if (headerImgRef && headerImgRef.submitUploadImages) {
        let output = await headerImgRef.submitUploadImages()
        if (_.isNil(output.p[0])) {
          await util.delay(3200)
          output = await headerImgRef.submitUploadImages()
        }
        if (_.isNil(output.p[0])) {
          p.op.showConfirmationOkOnly(null, 'ไม่สามารถอัพโหลดรูปได้ กรุณาทำรายการนี้ใหม่ภายหลัง')
          return
        }
        await util.setStatePromise(this, { uploading: false, headerActive: 2, showHeaderImgUploaded: false })
        this.props.navigation.setParams({
          onChange: true,
        })
        // @ts-ignore
        return output.p[0]
        // setParams({ inProcess: false })
      }
      // this.inProcess = false
    } else if (key === FOOTER) {
      await util.setStatePromise(this, { uploadingFooter: true, showFooterImgUploaded: true })
      // await util.delay(3200)
      const footerImgRef = this.thisImgManagerFooterRef.current
      if (footerImgRef && footerImgRef.submitUploadImages) {
        let output = await footerImgRef.submitUploadImages()
        if (_.isNil(output.p[0])) {
          await util.delay(3200)
          output = await footerImgRef.submitUploadImages()
        }
        if (_.isNil(output.p[0])) {
          p.op.showConfirmationOkOnly(null, 'ไม่สามารถแนบรูปใบปะหน้าพัสดุได้ กรุณาทำรายการนี้ใหม่อีกครั้ง')
          return
        }
        await util.setStatePromise(this, { uploadingFooter: false, footerActive: 2, showFooterImgUploaded: false })
        this.props.navigation.setParams({
          onChange: true,
        })
        // p.op.showToast('เพิ่มรูปท้ายกระดาษใบเสร็จรับเงินสำเร็จแล้ว', 'success', 2000)
        // setParams({ inProcess: false })
        return output.p[0]
      }
      // this.inProcess = false
    }
  }

  _renderListOptions = (text: string, key: string, value: boolean) => (
    <TouchableOpacity onPress={() => this.onChangeValue(key, !value)}>
      <HStack py='2'>
        <XIcon name={value ? 'check-square-o' : 'square-o'} family='FontAwesome' />
        <XText variant='active'>{text}</XText>
      </HStack>
    </TouchableOpacity>
  )

  _renderOnOffSetting = () => {
    const { usePrintingBillPaperang } = this.state
    return (
      <XCard p='4' borderRadius='xl' w='full'>
        {this._renderTextAndSwitch(p.op.t('enabled'), usePrintingBillPaperang, 'usePrintingBillPaperang')}
      </XCard>
    )
  }

  _renderViewShowVAT = () => {
    const { isShowVAT, usePrintingBillPaperang } = this.state
    if (!usePrintingBillPaperang) {
      return null
    }
    return (
      <XCard p='4' borderRadius='xl' w='full'>
        {this._renderTextAndSwitch(p.op.t('AddressList.TitleByMode.SHOW_VAT'), isShowVAT, 'isShowVAT')}
      </XCard>
    )
  }

  _renderViewAutoSaveBill = () => {
    const { autoSaveImgPaperang, usePrintingBillPaperang } = this.state
    if (!usePrintingBillPaperang) {
      return null
    }
    return (
      <XCard p='4' borderRadius='xl' w='full'>
        {this._renderTextAndSwitch(p.op.t('AddressList.TitleByMode.PAPERANG_BILL_AUTO_SAVE'), autoSaveImgPaperang, 'autoSaveImgPaperang')}
      </XCard>
    )
  }

  _renderInput = (key: string, message: string) => (
    <Box w='full'>
      <XInput
        multiline
        style={{ height: 90 }}
        // onRef={ r => util.pushNativeBaseRef(this.inputRefs, `txtInput_${key}`, r) }
        // onFocus={ () => this.setState({ currentRef: `txtInput_${key}` }) }
        maxLength={250}
        autoCorrect={false}
        // autoCapitalize={'none'}
        placeholder='โปรดระบุข้อความ'
        // placeholderTextColor={COLORS.TEXT_INACTIVE}
        value={message}
        keyboardType='default'
        // selectTextOnFocus={false}
        // returnKeyType={'done'}
        // blurOnSubmit={false}
        // secureTextEntry={ isSecureText || false }
        onChangeText={(text) => {
          this.onChangeInput(key, text)
        }}
      />
    </Box>
  )

  _renderHeaderAndFooterFaq = () => (
    <XHelpModal
      key='ตั้งค่ารูปหัวใบเสร็จ/ท้ายใบเสร็จ'
      headerTitle='ตั้งค่ารูปหัวใบเสร็จ/ท้ายใบเสร็จ'
      FAQ={FAQ.RECEIPT_HEAD_BILL_FAQ}
      initiateOpenIndex={[0, 1]}
      renderButton={(onPressOpenOverlay: () => void) => this._renderAgreement(onPressOpenOverlay)}
    />
  )

  _renderHeaderBill = () => {
    const { headerImgActive, usePrintingBillPaperang, headerTextActive, headerText } = this.state
    if (!usePrintingBillPaperang) {
      return null
    }
    // console.log('headerImgActive => ', headerImgActive)
    return (
      <XCard p='4' borderRadius='xl' w='full'>
        <HStack w='full' alignItems='center' justifyContent='center'>
          <XText variant='active' alignSelf='center' bold>
            {p.op.t('paper_header')}
          </XText>
        </HStack>
        {/* {this._renderTextAndSwitch('รูปหัวใบเสร็จ', headerImgActive, 'headerImgActive')} */}
        <HStack alignItems='center' justifyContent='space-between' w='full'>
          <HStack alignItems='center'>
            <XText>รูปหัวใบเสร็จ</XText>
            {this._renderHeaderAndFooterFaq()}
          </HStack>
          <XSwitch value={headerImgActive} onValueChange={(val) => this.onChangeValue('headerImgActive', val)} />
        </HStack>
        <XText>(ขนาดความกว้าง 750px หรือมากกว่า)</XText>
        <Box h='2.5' />
        {this._renderHeaderImg()}
        <Box h='2.5' />
        {this._renderTextAndSwitch(`ตัวหนังสือหัวใบเสร็จ`, headerTextActive, 'headerTextActive')}
        <Box h='2.5' />
        {headerTextActive ? this._renderInput(HEADER, headerText) : null}
      </XCard>
    )
  }

  _renderHeaderImg = () => {
    const { headerImgActive } = this.state
    const config = getConfig()
    const txtPrefix =
      config.s3_prefix_type_shipping_label + util._getPrefixForImageSlip() + util.getHHMMSSInString() + util.generateRandomString(4)
    return (
      <Box h='32' w='full'>
        <ImgManager
          fileNamePrefix={txtPrefix}
          metadata={[{ key: 'p', width: 2400, height: 3800, cropping: false }]}
          // ref={r => r && (this.thisImgManagerRef = r)}
          ref={this.thisImgManagerRef}
          uploading={this.state.uploading}
          showUploaded={this.state.showHeaderImgUploaded}
          // openGalleryChoose={headerGalleryChoose}
          maxLength={1}
          onPressChooseSuccess={() => {
            this.imgChooseSuccess(HEADER)
            // this._saveImg(HEADER)
          }}
          onPressChooseFailed={() => {
            // p.op.showToast('เกิดข้อผิดพลาก กรุณาทำรายการใหม่ภายหลัง', 'warning', 2000)
            this.inProcess = false
          }}
          onDeleteImgSuccess={() => util.setStatePromise(this, { headerGalleryChoose: true })}
          // imageStyle={{ width: 200, height: 100 }}
          // readonly={false}
          hide={!headerImgActive}
        />
      </Box>
    )
  }

  _renderFooterBill = () => {
    const { footerImgActive, footerTextActive, usePrintingBillPaperang, footerText } = this.state
    // console.log('footerTextActive => ', footerTextActive)
    if (!usePrintingBillPaperang) {
      return null
    }
    return (
      <XCard p='4' borderRadius='xl' w='full'>
        <HStack w='full' alignItems='center' justifyContent='center'>
          <XText variant='active' bold>
            {p.op.t('paper_footer')}
          </XText>
        </HStack>
        <HStack w='full' alignItems='center' justifyContent='center'>
          {this._renderTextAndSwitch(`ตัวหนังสือท้ายใบเสร็จ`, footerTextActive, 'footerTextActive')}
        </HStack>
        <Box h='2.5' />

        {footerTextActive ? this._renderInput(FOOTER, footerText) : null}
        <Box h='2.5' />
        {/* {this._renderTextAndSwitch('รูปท้ายใบเสร็จ', footerImgActive, 'footerImgActive')} */}
        <HStack alignItems='center' justifyContent='space-between' w='full'>
          <HStack alignItems='center'>
            <XText>รูปท้ายใบเสร็จ</XText>
            {this._renderHeaderAndFooterFaq()}
          </HStack>
          <XSwitch value={footerImgActive} onValueChange={(val) => this.onChangeValue('footerImgActive', val)} />
        </HStack>
        <Box h='2.5' />
        {this._renderFooterImg()}
      </XCard>
    )
  }

  _renderFooterImg = () => {
    const { footerImgActive } = this.state
    const config = getConfig()
    const txtPrefix =
      config.s3_prefix_type_shipping_label + util._getPrefixForImageSlip() + util.getHHMMSSInString() + util.generateRandomString(4)
    return (
      <Box h='32' w='full'>
        <ImgManager
          fileNamePrefix={txtPrefix}
          metadata={[{ key: 'p', width: 2400, height: 3800, cropping: false }]}
          // ref={r => r && (this.thisImgManagerFooterRef = r)}
          ref={this.thisImgManagerFooterRef}
          uploading={this.state.uploadingFooter}
          showUploaded={this.state.showFooterImgUploaded}
          // openGalleryChoose={footerGalleryChoose}
          maxLength={1}
          onPressChooseSuccess={() => {
            this.imgChooseSuccess(FOOTER)
            // this._saveImg(FOOTER)
          }}
          onPressChooseFailed={() => {
            // p.op.showToast('เกิดข้อผิดพลาก กรุณาทำรายการใหม่ภายหลัง', 'warning', 2000)
            this.inProcess = false
          }}
          onDeleteImgSuccess={() => util.setStatePromise(this, { footerGalleryChoose: true })}
          // imageStyle={{ width: 200, height: 100 }}
          // readonly={true}
          hide={!footerImgActive}
        />
      </Box>
    )
  }

  _renderCustomer = () => {
    const { isShowName, isShowAddress, isShowPhone } = this.state
    return (
      <XCard p='4' borderRadius='xl' w='full'>
        <HStack w='full' alignItems='center' justifyContent='center'>
          <XText variant='active' alignSelf='center' bold>
            {p.op.t('customer')}
          </XText>
        </HStack>
        <HStack py='2'>{this._renderTextAndSwitch(p.op.t('show_name'), isShowName, 'isShowName')}</HStack>
        <HStack py='2'>{this._renderTextAndSwitch(p.op.t('show_address'), isShowAddress, 'isShowAddress')}</HStack>
        <HStack py='2'>{this._renderTextAndSwitch(p.op.t('show_phone'), isShowPhone, 'isShowPhone')}</HStack>
      </XCard>
    )
  }

  _renderSettingTitle = () => {
    const { documentNaneLabel } = this.state
    return (
      <XCard p='4' borderRadius='xl' w='full'>
        <XText variant='active' alignSelf='center' bold>
          ชื่อเอกสาร
        </XText>
        <Box w='full' mt='2'>
          <XInput
            multiline
            style={{ height: 90 }}
            // onRef={ r => util.pushNativeBaseRef(this.inputRefs, `txtInput_${key}`, r) }
            // onFocus={ () => this.setState({ currentRef: `txtInput_${key}` }) }
            maxLength={500}
            autoCorrect={false}
            // autoCapitalize={'none'}
            placeholder='ใบเสร็จรับเงิน'
            // placeholderTextColor={COLORS.TEXT_INACTIVE}
            value={documentNaneLabel}
            keyboardType='default'
            // selectTextOnFocus={false}
            // returnKeyType={'done'}
            // blurOnSubmit={false}
            // secureTextEntry={ isSecureText || false }
            onChangeText={(text) => {
              this.setState({ documentNaneLabel: text })
            }}
          />
        </Box>
      </XCard>
    )
  }

  _renderAgreement = (onPressOpenOverlay: () => void) => <HelpButton onPress={() => onPressOpenOverlay()} />

  _renderSettingVat = () => {
    const { isShowVAT } = this.state
    const VAT_TEXT = p.op.isWeb() ? p.op.t('AddressList.TitleByMode.SHOW_VAT_TWO_LINE') : p.op.t('AddressList.TitleByMode.SHOW_VAT')
    return (
      <XCard p='4' borderRadius='xl' w='full'>
        <HStack alignItems='center' justifyContent='space-between' w='full'>
          <HStack alignItems='center'>
            <XText>{VAT_TEXT}</XText>
            <XHelpModal
              key='SettingBill'
              headerTitle='ข้อตกลงและวิธีการใช้งาน'
              FAQ={FAQ.VAT_STORE_SETTING_BILL}
              initiateOpenIndex={0}
              renderButton={(onPressOpenOverlay: () => void) => this._renderAgreement(onPressOpenOverlay)}
            />
          </HStack>
          <XSwitch
            value={isShowVAT}
            onValueChange={(val) => {
              this.props.navigation.setParams({
                onChange: true,
              })
              this.setState({ isShowVAT: val })
            }}
          />
        </HStack>
        {/* {this._renderTextAndSwitch('VAT', usePrintingBillPaperang, 'usePrintingBillPaperang')} */}
      </XCard>
    )
  }

  render() {
    return (
      <XContainer>
        {this.renderHeader()}
        <XContent>
          <VStack w='full' p='2' space='2'>
            {/* // เปิดใช้งาน */}
            {this._renderOnOffSetting()}
            {/* // VAT */}
            {/* {this._renderViewShowVAT()} */}
            {/* // บันทึกรูปใบเสร็จ เมื่อเปิดดู */}
            {this._renderViewAutoSaveBill()}
            {/* // TITLE */}
            {this._renderSettingTitle()}
            {/* // ลูกค้า */}
            {this._renderCustomer()}
            {/* // หัวใบเสร็จ */}
            {this._renderHeaderBill()}
            {/* // ท้ายใบเสร็จ */}
            {this._renderFooterBill()}
            {/* // VAT */}
            {this._renderSettingVat()}
          </VStack>
        </XContent>
      </XContainer>
    )
  }
}
