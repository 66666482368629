// import PropTypes from 'prop-types'
import React from 'react'
import _ from 'lodash'
import { View, ViewStyle, TextStyle } from 'react-native'
import { IBadgeProps as INBBadgeProps } from 'native-base'

import makeStyledComponent from 'x/config/nativebase/makeStyledComponent'
import theme from 'x/config/nativebase/base'
import XText from './XText'

export interface IBadgeProps extends INBBadgeProps {
  count?: number
  type?: string // 'nbBadgeTypeBGAppMain' | 'nbBadgeTypeTextAppMain'
  style?: ViewStyle
  textStyle?: TextStyle
}

const utilityPropsByType = {
  nbBadgeTypeBGAppMain: {
    variant: 'solid',
    // px: '1',
    // py: '0.5',
    // alignItems: 'center',
    // justifyContent: 'center',
    // borderRadius: 'lg',
    // bg: `primary.500`,
    // _text: {
    //   color: 'dark.500',
    //   fontSize: 'xs',
    //   fontWeight: 'medium',
    // },
  },
  nbBadgeTypeTextAppMain: {
    variant: 'outline',
    // px: '1',
    // py: '0.5',
    // alignItems: 'center',
    // justifyContent: 'center',
    // borderRadius: 'lg',
    // bg: `white`,
    // _text: {
    //   color: 'primary.500',
    //   fontSize: 'xs',
    //   fontWeight: 'medium',
    // },
  },
}

// const Badge: React.FC<IBadgeProps> = ({ count, children, type, style = {}, ...restProps }) => {
//   const injectUtility = type && utilityPropsByType[type] ? utilityPropsByType[type] : {}
//   return (
//     <NBBadge
//       justifyContent='center'
//       alignItems='center'
//       borderRadius='lg' // ใส่ borderRadius ที่นี่ เนื่องจากใส่ใน baseStyle ของ theme แล้วไม่ work
//       {...injectUtility}
//       style={style}
//       {...restProps}>
//       {children || count}
//     </NBBadge>
//   )
// }

const BadgeBase = React.forwardRef((props, ref) => {
  // @ts-ignore
  const { _text = {}, children, ...restProps } = props

  if (_.isNil(children) || children === '') {
    return null
  }

  return (
    // @ts-ignore
    <View ref={ref} {...restProps}>
      <XText {..._text}>{children}</XText>
    </View>
  )
})

// const BadgeBaseStyled = makeStyledComponent(BadgeBase, 'Badge')
const BadgeBaseStyled = makeStyledComponent(BadgeBase, 'Badge')
// @ts-ignore
BadgeBaseStyled.defaultProps = { theme }

const Badge = React.forwardRef((props, ref) => {
  // @ts-ignore
  const { type, ...restProps } = props
  const injectUtility = type && utilityPropsByType[type] ? utilityPropsByType[type] : utilityPropsByType.nbBadgeTypeBGAppMain
  return <BadgeBaseStyled ref={ref} {...injectUtility} {...restProps} />
})

Badge.displayName = 'XBadge'

export default Badge as React.FC<IBadgeProps>

// /**
//  * Wrapper Badge around Native-base's badge
//  */
// export default class Badge extends Component<{
//   count?: number
//   type?: string // 'badgeTypeBGAppMain' | 'badgeTypeTextAppMain'
//   style?: ViewStyle
//   textStyle?: TextStyle
// }> {
//   // static propTypes = {
//   //   count: PropTypes.number,
//   //   type: PropTypes.string, // my, seller, red
//   //   style: PropTypes.any, // to override style for NB's Badge
//   //   textStyle: PropTypes.any, // to override style for inner Text
//   //   //http://docs.nativebase.io/Components.html#Badge
//   // };

//   constructor(props) {
//     super(props)
//     this._getBadgeStyle = this._getBadgeStyle.bind(this)
//   }

//   shouldComponentUpdate(nextProps) {
//     return isDiff(this.props, nextProps, ['count', 'type'])
//   }

//   render() {
//     logRender(this)
//     // show nothing if count < 0
//     // show 999+ if count > 999
//     const { count, style = {}, textStyle = {} } = this.props
//     if (count && count > 0) {
//       const _numStr = count <= 999 ? `${count}` : '999+'
//       const s = this._getBadgeStyle()
//       // util.log('badge style', s)
//       return (
//         <NBBadge
//           {...this.props}
//           // @ts-ignore
//           style={[styles.nbBadge, s.bg, style]}>
//           <Text
//             allowFontScaling={false}
//             // @ts-ignore
//             style={[styles.badgeText, s.text, textStyle]}>
//             {_numStr}
//           </Text>
//         </NBBadge>
//       )
//     }
//     return <View />
//   }

//   _getBadgeStyle() {
//     const type = this.props.type ? this.props.type : CONS.BADGE_TYPE_BG_APP_MAIN
//     switch (type) {
//       case CONS.BADGE_TYPE_BG_APP_MAIN:
//         return multiStyles.nbBadgeTypeBGAppMain
//       case CONS.BADGE_TYPE_TEXT_APP_MAIN:
//         return multiStyles.nbBadgeTypeTextAppMain
//       default:
//         return multiStyles.nbBadgeTypeBGAppMain
//     }
//   }
// }

// const multiStyles = {
//   nbBadgeTypeBGAppMain: {
//     bg: { backgroundColor: COLORS.APP_MAIN },
//     text: { color: 'white' },
//   },
//   nbBadgeTypeTextAppMain: {
//     bg: { backgroundColor: 'white' },
//     text: { color: COLORS.APP_MAIN },
//   },
// }

// const styles = {
//   nbBadge: {
//     height: 18,
//     backgroundColor: COLORS.RED_BADGE,
//   },
//   badgeText: {
//     fontSize: 12,
//     backgroundColor: 'transparent',
//     ...Platform.select({
//       android: {
//         marginTop: 4,
//       },
//       ios: {
//         marginTop: 1,
//       },
//       web: {
//         marginTop: 3,
//       },
//     }),
//   },
// }
