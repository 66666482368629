import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as StoreState from 'x/modules/store/StoreState'

import { getSelectedStore } from 'x/redux/selectors'
import StoreSettingShippingTypeView from './StoreSettingShippingTypeView'

// import * as AddressActions from '../address/AddressState'

export default connect(
  (state) => {
    return {
      selectedStore: getSelectedStore(state),
      // selectedCustomOrderView: getSelectedCustomOrderView(state),
    }
  },
  (dispatch) => {
    return {
      addNewStoreSetting: bindActionCreators(StoreState.addNewStoreSetting, dispatch),
      // pickerSelect: bindActionCreators(bpActions.pickerSelect, dispatch),
      // addCustomOrder: bindActionCreators(PreferenceState.addCustomOrder, dispatch),
      // loadPrefUIOrderView: bindActionCreators(PreferenceState.loadPrefUIOrderView, dispatch),
    }
  }
)(StoreSettingShippingTypeView)
