import React from 'react'
import _ from 'lodash'
import { TouchableOpacity, View, StyleSheet, Linking } from 'react-native'

import * as util from 'x/utils/util'
import p from 'x/config/platform-specific'
import api from 'x/utils/api'
import { S, COLORS } from 'x/config/styles'
// import CONS from 'x/config/constants'

import XIcon from 'xui/components/XIcon'
import XText from 'xui/components/XText'
import XOverlay from '../../XOverlay'
import XCustomHeader from '../../XCustomHeader'
// import XSegment from '../XSegment'
// import XRadioList from '../XRadioList'
// import { diff } from 'deep-object-diff'
// import XIcon from '../XIcon'

type IConfirmationStatus = 'accepted' | 'rejected' | 'closed'

interface IPdpaConfirmationProps {
  visible?: boolean // ใช้ props ในการ control

  onAccepted?: () => void // after accept
  onRejected?: () => void // after reject
  onRequestClose?: () => void // request to close without reject or accept
}

interface IPdpaConfirmationState {
  visible: boolean
  isConsentChecked: boolean

  resolveConfirmation?: (resolveStatus: IConfirmationStatus) => void
}

// interface IUserConsentStatus {
//   // user_id: number
//   status: 'a' | 'r'
//   // consent_content_id: number
//   lang: 'en' | 'th'
//   //** string of time */
//   // updated_at: string
// }

interface ISetUserConsentRequestBody {
  is_consented: boolean
  lang: 'en' | 'th'
}

interface ISetUserConsentResponse {
  status: 'ok'
}

class PdpaConfirmation extends React.Component<IPdpaConfirmationProps, IPdpaConfirmationState> {
  static displayName = 'PdpaConfirmation'

  inProcess?: boolean

  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      isConsentChecked: false,
      resolveConfirmation: null,
    }

    this.inProcess = false
  }

  // componentDidUpdate(prevProps: Readonly<IPdpaConfirmationProps>, prevState: Readonly<IPdpaConfirmationState>, snapshot?: any): void {
  //     if (prevProps.visible !== this.props.visible) {
  //       this._handleOnOverlayVisibilityPropsChange(prevState.visible, this.state.visible)
  //     }
  // }

  // _handleOnOverlayVisibilityPropsChange = (prevVisible: boolean, currentVisible: boolean) => {
  //   if (currentVisible)
  // }
  show = async (): Promise<IConfirmationStatus> => {
    await this.openOverlay()
    return new Promise<IConfirmationStatus>((resolveConfirmation) => {
      this.setState({ resolveConfirmation })
    })
  }

  openOverlay = async () => {
    await util.setStatePromise(this, { visible: true })
  }

  closeOverlay = async () => {
    await util.setStatePromise(this, { visible: false, isConsentChecked: false })

    const { onRequestClose } = this.props

    if (_.isFunction(onRequestClose)) {
      onRequestClose()
    }

    const { resolveConfirmation } = this.state
    if (_.isFunction(resolveConfirmation)) {
      resolveConfirmation('closed')
      await util.setStatePromise(this, { resolveConfirmation: null })
    }
  }

  _onPressReject = async () => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    // console.log('_onPressReject')

    const { onRejected } = this.props

    const isConsentSetSuccess = await this.setUserConsent(false)
    if (isConsentSetSuccess && _.isFunction(onRejected)) {
      onRejected()
    }

    const { resolveConfirmation } = this.state
    if (isConsentSetSuccess && _.isFunction(resolveConfirmation)) {
      resolveConfirmation('rejected')
      await util.setStatePromise(this, { resolveConfirmation: null })
    }

    if (isConsentSetSuccess) {
      await this.closeOverlay()
    }

    this.inProcess = false
  }

  _onPressAccept = async () => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true

    // console.log('_onPressAccept')

    const { onAccepted } = this.props

    const isConsentSetSuccess = await this.setUserConsent(true)
    if (isConsentSetSuccess && _.isFunction(onAccepted)) {
      onAccepted()
    }

    const { resolveConfirmation } = this.state
    if (isConsentSetSuccess && _.isFunction(resolveConfirmation)) {
      resolveConfirmation('accepted')
      await util.setStatePromise(this, { resolveConfirmation: null })
    }

    if (isConsentSetSuccess) {
      await this.closeOverlay()
    }

    this.inProcess = false
  }

  // getUserConcentStaut = () => {
  //   const {} = this.props
  //   api.post('get_user_consent_status', { user_id: 1 }, { isApiV2: true })
  // }

  setUserConsent = async (is_consented: boolean): Promise<boolean> => {
    let isConsentSetSuccess = false
    try {
      const res = await api.put<ISetUserConsentRequestBody, ISetUserConsentResponse>(
        'set_user_consent',
        {
          is_consented,
          // FIXME: Should use lang from i18n?
          lang: 'th',
        },
        { isApiV2: true }
      )

      // console.log('setUserConsent res => ', res)

      if (res && res.status === 'ok') {
        isConsentSetSuccess = true
      }
    } catch (err) {
      if (util.isXSellyErrorV2(err)) {
        // @ts-ignore
        util.alertWithXSellyErrorV2(err.error)
      }
      // console.log('setUserConsent err => ', err)
    }

    return isConsentSetSuccess
  }

  isOverlayVisible = () => {
    const { visible: vp } = this.props
    const { visible: vs } = this.state
    if (_.isBoolean(vp)) {
      return vp
    }
    return vs
  }

  renderHeader = () => (
    <XCustomHeader
      title='นโยบายคุ้มครองข้อมูลส่วนบุคคล'
      // headerRightProps={{ closeIcon: true, onPressItem: this.closeOverlay }}
    />
  )

  renderConsentButtons = () => {
    const { isConsentChecked } = this.state
    return (
      <View style={{ width: '100%', minHeight: 44, padding: 4, alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
        <TouchableOpacity
          onPress={this._onPressAccept}
          style={[S.FLEX, isConsentChecked ? S.BUTTON_PRIMARY : S.BUTTON_INACTIVE, S.HEIGHT_34]}>
          <XText
            bold
            style={[isConsentChecked ? S.TEXT_ACTIVE_DARK : S.TEXT_ACTIVE, isConsentChecked ? S.TEXT_LARGER : {}, S.TEXT_ALIGN_CENTER]}>
            ยอมรับ
          </XText>
        </TouchableOpacity>
        <View style={{ width: 8 }} />
        <TouchableOpacity onPress={this._onPressReject} style={[S.FLEX, S.ROW_CENTER, S.BUTTON_OUTLINE_PRIMARY, S.HEIGHT_34]}>
          <XText bold style={[S.TEXT_PRIMARY, S.TEXT_ALIGN_CENTER]}>
            ไม่ยอมรับ
          </XText>
        </TouchableOpacity>
      </View>
    )
  }

  _toggleConsentCheckbox = () => {
    const { isConsentChecked } = this.state
    this.setState({ isConsentChecked: !isConsentChecked })
  }

  renderConsentCheckbox = () => {
    const { isConsentChecked: checked } = this.state
    return (
      <TouchableOpacity style={StyleSheet.flatten([S.WIDTH_34, S.HEIGHT_34])} onPress={this._toggleConsentCheckbox}>
        <XIcon
          // family='FontAwesome'
          // name={checked ? 'check-circle' : 'circle-o'}
          family={checked ? 'Ionicons' : 'FontAwesome'}
          name={checked ? 'checkmark-circle' : 'circle-o'}
          style={StyleSheet.flatten([checked ? S.TEXT_ICON_SUCCESS : S.TEXT_ICON_PRIMARY, S.TEXT_ICON_LARGER])}
          // variant={checked ? 'success' : 'inactive'}
        />
      </TouchableOpacity>
    )
  }

  renderContent = () => (
    <View style={{ width: '100%' }}>
      {this.renderHeader()}
      <View style={{ width: '100%', padding: 8, flexDirection: 'row', flexWrap: 'wrap' }}>
        <XText style={[S.FLEX, S.TEXT_INACTIVE, S.TEXT_LARGER]}>กรุณาติ๊ก "ช่องวงกลม" และ กดปุ่ม "ยอมรับ" เพื่อเป็นการยืนยัน</XText>
      </View>
      <View style={{ width: '100%', padding: 8, flexDirection: 'row', flexWrap: 'wrap' }}>
        {this.renderConsentCheckbox()}
        <View style={{ width: 8 }} />
        <XText style={[S.FLEX, S.TEXT_ACTIVE, S.TEXT_LARGER, p.op.isWeb() ? { maxWidth: 250 } : {}]}>
          {'ข้าพเจ้าได้อ่าน เข้าใจ และตกลงตามข้อกำหนดในนโยบายคุ้มครองข้อมูลส่วนบุคคลของ XSelly ซึ่งสามารถ '}
          <XText style={{ color: 'blue' }} onPress={() => Linking.openURL('https://xselly.com/pdpa')}>
            กดดูได้ที่นี่
          </XText>
        </XText>
      </View>

      <View style={{ height: 16 }} />

      {this.renderConsentButtons()}

      {/* <View style={{ height: p.op.isWeb() ? 4 : 0 }} /> */}
    </View>
  )

  renderMain = () => (
    <XOverlay
      contentStyle={StyleSheet.flatten([
        { minHeight: 150, width: 310, alignSelf: 'center', flexDirection: 'column', backgroundColor: COLORS.WHITE },
        p.op.isWeb() ? { height: 240 } : {},
      ])}
      visible={this.isOverlayVisible()}
      // onRequestClose={this.closeOverlay}
    >
      {this.renderContent()}
    </XOverlay>
  )

  render() {
    return this.renderMain()
  }
}

export default PdpaConfirmation
