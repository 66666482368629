import { connect } from 'react-redux'
import { getSelectedStore } from 'x/redux/selectors'
import ImportProductBarcodeView from './ImportProductBarcodeView'

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
    // sellerStores: getSellerStores(state),
    // sellerStoresShouldFetch: getSellerStoresShouldFetch(state),
    // subscription: getSubscription(state),
  }),
  (dispatch) => {
    return {
      // navigate: bindActionCreators(NavigationActions.navigate, dispatch),
      // fetchSellerStores: bindActionCreators(StoreActions.fetchSellerStores, dispatch),
      // fetchAccounts: bindActionCreators(BankActions.fetchStorePaymentAccounts, dispatch),
      // getPermissionMemberList: bindActionCreators(StoreActions.getPermissionMemberList, dispatch),
    }
  }
)(ImportProductBarcodeView)
