import React from 'react'
import { List } from 'immutable'

import * as NavActions from 'x/utils/navigation'

import {
  IProductListViewProps,
  IProductListViewState,
  ImmutableObject,
  IMKPChannelDetail,
  // IMKPProductItemData,
  IMkpProductListItem,
  IProductListViewMkpToXSellyMappingParams,
  IProductListItem,
} from 'x/index'

import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import * as util from 'x/utils/util'
import XButton from 'xui/components/XButton'
import _ from 'lodash'
import XSellyLogo from 'xui/components/XSellyLogo'
import BaseUIProductListView from './BaseUIProductListView'

// import { withMkpProductContextPropsAndRef } from '../marketplace/MkpProductContextHOC'
// import { IMkpProductContextProps } from '../marketplace/MkpProductContext'
// import { connect } from 'react-redux'
// import { getSelectedStore, getSelectedProduct } from 'x/redux/selectors'

interface IProductListViewMkpViewProps extends IProductListViewProps<IProductListViewMkpToXSellyMappingParams> {
  mkpChannels: List<ImmutableObject<IMKPChannelDetail>>
}

class ProductListViewMkpView extends BaseUIProductListView<IProductListViewMkpViewProps, IProductListViewState, IMkpProductListItem> {
  static displayName = 'ProductListViewMkpToXSellyMapping'

  // // override
  // getApiEndpoint = () => 'mkp/v2/products'

  // // true
  // isGoApi = () => true

  // // override
  // isProductListItemPressDisabled = () => true
  isOmitStockQty = () => true

  // override
  isProductListItemSellerIconDisabled = () => true

  // override
  isProductListItemPriceDisabled = () => true

  // override
  isProductListItemQtyDisabled = () => true

  // override
  isRequiredProductVariants = () => false

  // override
  isAddProductButtonVisible = () => false

  // override
  isUpcSearchAvailable = () => false

  // override
  isSkuSearchAvailable = () => false

  // override
  onPressProductItem = undefined
  // onPressProductItem = (item: { index: number; data: IMkpProductListItem }) => {
  //   this._navToMkpProductDetailView(item.data.uuid)
  // }

  // // override
  // getModifiedRequestBody = (body) => {
  //   const { navigation, selectedStore } = this.props
  //   const myStoreId = util.getNavParam(this.props, 'store_id')
  //   // const mkpChannelId = util.getNavParam(this.props, 'mkp_channel_id')
  //   const mkpChannelId = util.getNavParam(this.props, 'mkp_ch_id')
  //   const mkpId = util.getNavParam(this.props, 'mkp_id')
  //   const newBody = _.cloneDeep(body)
  //   newBody.store_id = myStoreId
  //   // newBody.mkp_channel_id = mkpChannelId
  //   newBody.mkp_ch_id = mkpChannelId
  //   newBody.mkp_id = mkpId
  //   delete newBody.sortBy
  //   delete newBody.sortType
  //   if (mkpId === 4) {
  //     newBody.sort_by = 'item_id'
  //   } else {
  //     newBody.sort_by = 'updated_at'
  //   }
  //   newBody.sort_type = 'desc'

  //   if (newBody.c) {
  //     delete newBody.c
  //     newBody.count = true
  //   }

  //   return newBody
  // }

  // // override
  // renderFilterProductsButton = () => null

  // override
  getHaveNoProductItemText = () => 'ไม่พบรายการสินค้า'

  // override
  renderProductListItemIcon = () => <XSellyLogo width={24} height={24} />

  // // override
  // onAfterFetchSeachProducts = async (fetchedData: { count?: number; items: IProductItemData[] }, req: IAnyObject): Promise<void> => {
  //   if (fetchedData.count === 1 && req.offset === 0 && (req.isSKU || req.isUPC)) {
  //     const pd = fetchedData.items[0]
  //     this.navToProductView(pd.id)
  //   }
  // }

  // override
  fetchSearchProducts = async ({ offset, limit }) => {
    type FetchedData = {
      count?: number
      items: IMkpProductListItem[]
    }
    let requestBody = this.getCommonRequestBody({ offset, limit })
    // pKeng on 14/7/2023 For XSelly Product list it's 'queryTxt' But for MKP Product list, it's 'query_text'
    requestBody.queryTxt = this.state.searchText
    requestBody = this.getModifiedRequestBody(requestBody)
    const fetchedData: FetchedData = await this.doFetchProducts(requestBody)

    await this.onAfterFetchSeachProducts(fetchedData, requestBody)

    return fetchedData
  }

  // _navToMkpProductDetailView = async (item_uuid: string) => {
  //   // console.log('_navToMkpProductDetailView item_uuid => ', item_uuid)
  //   const { navigation } = this.props
  //   const storeId = util.getNavParam(this.props, 'store_id')
  //   // const mkpChannelId = util.getNavParam(this.props, 'mkp_channel_id')
  //   const mkpChannelId = util.getNavParam(this.props, 'mkp_ch_id')
  //   const mkpId = util.getNavParam(this.props, 'mkp_id')
  //   navigation.dispatch(
  //     NavActions.navToMkpProductDetailView({
  //       store_id: storeId,
  //       // mkp_channel_id: mkpChannelId,
  //       mkp_ch_id: mkpChannelId,
  //       item_uuid,
  //       mkp_id: mkpId,
  //     })
  //   )
  // }

  _renderSelectedMkpStoreBar = () => {
    const { navigation, mkpChannels } = this.props
    // const mkpChannelId = util.getNavParam(this.props, 'mkp_channel_id')
    // const mkpChannel = mkpChannels.find((mch) => mch.get('id') === mkpChannelId)
    // const mkpChaneelName = mkpChannel.get('name')
    // const mkpId = mkpChannel.get('mkp_id')
    // return (
    //   <View style={[S.CARDLIKE_BORDERS, S.BG_WHITE, { borderTopWidth: 0, alignItems: 'center', justifyContent: 'center', width: '100%' }]}>
    //     {/* <View style={[S.CARDLIKE_BODY]}>
    //       <View style={[S.PADDING_VERTICAL_4, { flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }]}>
    //         <MkpLogo mkpId={mkpId} width={STYLES.FONT_ICON_NORMAL} height={STYLES.FONT_ICON_NORMAL} />
    //         <Text style={[S.TEXT_ACTIVE, { paddingLeft: 4 }]}>{mkpChaneelName}</Text>
    //       </View>
    //     </View> */}
    //     <View style={[S.CARDLIKE_BODY, S.ROW_CENTER, { height: 54, paddingBottom: 4 }]}>
    //       {/* <View style={[S.ROW_CENTER, { width: 44 }]}>
    //         <XIcon
    //           inactive
    //           name='search'
    //           style={{ flex: 0, flexBasis: 'auto', width: STYLES.FONT_ICON_NORMAL, height: STYLES.FONT_ICON_NORMAL }}
    //         />
    //       </View> */}
    //       {this.renderSearchBar()}
    //     </View>
    //   </View>
    // )
    return (
      <HStack w='full' p='2'>
        {this.renderSearchBar()}
      </HStack>
    )
  }

  // _renderHeaderTitleBar = () => {
  //   const { navigation, mkpChannels } = this.props
  //   // const mkpChannelId = util.getNavParam(this.props, 'mkp_channel_id')
  //   const mkpChannelId = util.getNavParam(this.props, 'mkp_ch_id')
  //   const mkpChannel = mkpChannels.find((mch) => mch.get('id') === mkpChannelId)
  //   // const mkpChaneelName = mkpChannel.get('name')
  //   const mkpId = mkpChannel.get('mkp_id')

  //   if (!mkpId) {
  //     return null
  //   }

  //   const mkpChannelName = util.getMKPChannelName(mkpChannelId)
  //   // return (
  //   //   <View style={[S.CARDLIKE_BODY, { flexDirection: 'row' }]}>
  //   //     <View style={[S.PADDING_4, { alignItems: 'center', justifyContent: 'center' }]}>
  //   //       <MkpLogo mkpId={mkpId} width={STYLES.FONT_ICON_LARGEST} height={STYLES.FONT_ICON_LARGEST} />
  //   //     </View>
  //   //     <View style={[S.PADDING_4, { height: '100%', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }]}>
  //   //       <Text style={S.TEXT_INACTIVE}>{'เลือกสินค้าจากรายการของช่องทางขาย'}</Text>
  //   //       <Text style={S.TEXT_ACTIVE}>{mkpChaneelName}</Text>
  //   //     </View>
  //   //   </View>
  //   // )
  //   return (
  //     <HStack w='full' minH='10' p='2' space='1'>
  //       <XIconButton name='arrow-back' onPress={this.goBack} />
  //       <HStack flex={1} space='2' alignItems='center'>
  //         <MkpLogo mkpId={mkpId} width={STYLES.FONT_ICON_LARGER} height={STYLES.FONT_ICON_LARGER} />
  //         <XText flex={1} bold numberOfLines={2}>
  //           {`รายการสินค้าจาก ${mkpChannelName}`}
  //         </XText>
  //       </HStack>

  //       <Box w='10'>
  //         {/* <TouchableOpacity style={S.BUTTON_ICON}>
  //           <XIcon name='search' family='FontAwesome' size={STYLES.FONT_ICON_NORMAL} />
  //         </TouchableOpacity> */}
  //       </Box>
  //     </HStack>
  //   )
  // }

  // // overrdie
  // getSearchTextWithCountPlaceholder = (mainProductCount: number) => {
  //   const { navigation, mkpChannels } = this.props
  //   // const mkpChannelId = util.getNavParam(this.props, 'mkp_channel_id')
  //   const mkpChannelId = util.getNavParam(this.props, 'mkp_ch_id')
  //   const mkpChannel = mkpChannels.find((mch) => mch.get('id') === mkpChannelId)
  //   const mkpChaneelName = mkpChannel.get('name')
  //   // const mkpId = mkpChannel.get('mkp_id')
  //   return `${mainProductCount} สินค้า ที่พร้อมค้นหาใน ${mkpChaneelName}`
  // }

  // // override
  // getSearchTextWithoutCountPlaceholder = () => {
  //   const { navigation, mkpChannels } = this.props
  //   // const mkpChannelId = util.getNavParam(this.props, 'mkp_channel_id')
  //   const mkpChannelId = util.getNavParam(this.props, 'mkp_ch_id')
  //   const mkpChannel = mkpChannels.find((mch) => mch.get('id') === mkpChannelId)
  //   const mkpChannelName = mkpChannel.get('name')
  //   // const mkpId = mkpChannel.get('mkp_id')
  //   return `ไม่พบรายการสินค้าพร้อมใช้งานใน ${mkpChannelName}`
  // }

  // getMkpProduct = (): IMkpProductDetail => {
  //   const { navigation } = this.props
  //   // @ts-ignore
  //   return util.getNavParam(this.props, 'mkpProduct')
  // }

  // isPtIdPairedToMkp = (pt_id: number): boolean => {
  //   const mkpProduct = this.getMkpProduct()
  //   return mkpProduct.variants.findIndex((mpv) => mpv.paired_pt_id === pt_id) > -1
  // }

  // isOnlyOneVariantMkpProduct = (): boolean => {
  //   const mkpProduct = this.getMkpProduct()
  //   return mkpProduct.variants.length === 1
  // }

  _onMappingSuccess = async () => {
    // const { navigation, mkpProductContext } = this.props
    const onMappingSuccess = util.getNavParam(this.props, 'onMappingSuccess')

    if (onMappingSuccess) {
      await onMappingSuccess()
    }

    // mkpProductContext.destroy(mkpProductContext)

    util.navGoBack(this.props)
  }

  // _navToMkpProductDetailMapping = async (product_id: number) => {
  _navToMkpProductDetailMapping = async (data: IProductListItem) => {
    const { navigation, selectedStore } = this.props
    // const { selectedMkpProductListItem } = this.state
    const store_id = util.getNavParam(this.props, 'store_id')
    // const mkp_id = util.getNavParam(this.props, 'mkp_id')
    // const mkp_ch_id = util.getNavParam(this.props, 'mkp_ch_id')

    // const selectedProductVariant = util.getNavParam(this.props, 'selectedProductVariant')
    const selectedVariantMkp = util.getNavParam(this.props, 'selectedVariantMkp')
    const renderNavReference = util.getNavParam(this.props, 'renderNavReference')
    // // @ts-ignore
    // const mkpProduct = util.getNavParam(this.props, 'mkpProduct')

    console.log('_navToMkpProductDetailMapping data => ', data)

    // navigation.dispatch(
    //   NavActions.navToProductVariantXSellyToMkpMappingView({
    //     store_id,
    //     mkp_ch_id,

    //     // pt_id: selectedProduct.get(`id`),
    //     // @ts-ignore
    //     // item_uuid: mkpProduct.uuid,
    //     item_uuid: data.uuid,

    //     mkp_id,

    //     selectedProductVariant,
    //     renderNavReference,
    //     onMappingSuccess: this._onMappingSuccess,
    //   })
    // )

    navigation.dispatch(
      NavActions.navToProductVariantMkpToXSellyMappingView({
        // store_id,
        // mkp_ch_id,

        // pt_id: selectedProduct.get(`id`),
        // @ts-ignore
        // item_uuid: mkpProduct.uuid,
        // item_uuid: data.uuid,

        // mkp_id,

        store_id,
        product_id: data.id,
        role_id: selectedStore.get('role_id'),

        selectedVariantMkp,

        // selectedProductVariant,
        renderNavReference,
        onMappingSuccess: this._onMappingSuccess,
      })
    )

    // navigation.navigate('ProductVariantMkpToXSellyMappingView', {
    //   store_id: selectedStore.get('id'),
    //   product_id: selectedProduct.get('id'),
    //   mkp,

    //   renderNavReference,
    // })

    // try {
    //   const role_id = selectedStore.has('role_id') ? selectedStore.get('role_id') : null
    //   const body: any = { store_id, product_id }
    //   // backend require if role_id === 2 (helper)
    //   if (role_id === 2) {
    //     // ถ้าเป็นการ fetch สินค้าของร้านฉันให้ส่ง role_id ของ selectedStore ของฉันไป
    //     body.role_id = role_id
    //   }

    //   const apiOptions: IApiOptions = {}

    //   const res = await api.post<IViewProductRequest, IViewProductResponse>(api.POST_PRODUCT, body, apiOptions)
    //   // console.log('_fetchPeekProduct res => ', res)
    //   if (res && res.product) {
    //     navigation.dispatch(
    //       NavActions.navToProductVariantXSellyToMkpMappingView({
    //         store_id,
    //         mkp_ch_id,
    //         // pt_id: selectedProduct.get(`id`),
    //         // @ts-ignore
    //         item_uuid: mkpProduct.uuid,
    //         mkp_id,
    //         // viewKey: mkpViewKey,
    //         // viewKey: 'MAPPING',
    //         // store_id: storeId,
    //         // product_id,

    //         // onSuccessEditProductCallback: this.onSuccessEditProductCallback,
    //         // onSuccessDeleteProductCallback: this.onSuccessDeleteProductCallback,

    //         // targetXSellyProduct: res.product,

    //         // onMappingSuccess: this._onMappingSuccess,
    //       })
    //     )
    //   }
    // } catch (err) {
    //   console.log('_fetchPeekProduct err => ', err)
    //   // await util.setStatePromise(this, {
    //   //   selectedPeekProductItem: null,
    //   //   selectedMkpProductListItem: null,
    //   //   isPreviewPeekProductVisible: false,
    //   // })
    //   p.op.alert('เกิดข้อผิดพลาดในการโหลดข้อมูล', 'กรุณาลองใหม่อีกครั้ง')
    // }
  }

  // @ts-ignore override
  renderProductListItemBottom = (item: { index: number; data: IProductListItem }) => {
    const { index, data } = item
    // const ptId = data.id
    // FIXME: const isPaired = this.isPtIdPairedToMkp(ptId)
    const isPaired = false
    // console.log(`renderProductListItemBottom::${index}:: data => `, data)

    // @ts-ignore
    const isPlaceholderData = !data || data.isEmpty
    if (isPlaceholderData) {
      return null
    }

    // const isOnlyOneXSellyVariants = (data && data.v && data.v.length === 1) || false

    // if (isPaired && this.isOnlyOneVariantMkpProduct() && isOnlyOneXSellyVariants) {
    //   return (
    //     <VStack key={`${index}_${ptId}`}>
    //       <XButton w='full' disabled colorScheme='muted' variant='outline' onPress={() => this._navToMkpProductDetailMapping(ptId)}>
    //         ถูกผูกกับสินค้านี้
    //       </XButton>
    //     </VStack>
    //   )
    // }

    return (
      <VStack key={`${index}_${data.id}`}>
        {/* <XText>is already paired = {JSON.stringify(isPaired)}</XText> */}
        {/* <XButton w='full' variant={isPaired ? 'outline' : 'solid'} onPress={() => this._navToMkpProductDetailMapping(ptId)}> */}
        <XButton w='full' variant={isPaired ? 'outline' : 'solid'} onPress={() => this._navToMkpProductDetailMapping(data)}>
          {/* {isPaired ? 'สลับการผูกเดิม' : 'ดำเนินการผูกใหม่'} */}
          ดำเนินการผูก
        </XButton>
      </VStack>
    )
  }

  // renderContent = () => {
  //   const { navigation, mkpChannels } = this.props
  //   // const mkpChannelId = util.getNavParam(this.props, 'mkp_channel_id')
  //   // const mkpChannel = mkpChannels.find((mch) => mch.get('id') === mkpChannelId)
  //   // const mkpChaneelName = mkpChannel.get('name')
  //   // const mkpId = mkpChannel.get('mkp_id')
  //   const renderNavReference = util.getNavParam(this.props, 'renderNavReference')

  //   return (
  //     <VStack flex={1} w='full'>
  //       {_.isFunction(renderNavReference) ? renderNavReference() : null}
  //       {this._renderHeaderTitleBar()}
  //       {/* {this.renderCustomHeader()} */}
  //       {this._renderSelectedMkpStoreBar()}
  //       {this.renderWatingSearchTypingInfo()}
  //       <VStack w='full' flex={1} _light={{ bg: 'coolGray.200' }} _dark={{ bg: 'warmGray.700' }}>
  //         {this.renderMainList()}
  //         {this.renderSearchList()}
  //       </VStack>
  //     </VStack>
  //   )
  // }

  // override
  renderContent = () => {
    const renderNavReference = util.getNavParam(this.props, 'renderNavReference')

    return (
      <VStack flex={1} w='full'>
        {_.isFunction(renderNavReference) ? renderNavReference() : null}
        {this.renderCustomHeader()}
        {this.renderSearchInfoBar()}
        {this.renderCatListBar()}
        <VStack w='full' flex={1} _light={{ bg: 'coolGray.200' }} _dark={{ bg: 'warmGray.700' }}>
          {this.renderMainList()}
          {this.renderWatingSearchTypingInfo()}
          {this.renderSearchList()}
        </VStack>
        {/* {this.renderDrawerCategoryFilterView()} */}
      </VStack>
    )
  }
}

export default ProductListViewMkpView
