import React from 'react'
import { View, TouchableOpacity, Keyboard, Platform, StyleSheet, Image, ImageSourcePropType, Clipboard } from 'react-native'

import {
  Card,
  Box,
  Button,
  // connectStyle,
  Stack,
  VStack,
  HStack,
} from 'native-base'

import CONS from 'x/config/constants'

import { COLORS, STYLES, textOnBtnMode, S as RNStyle } from 'x/config/styles'
// import { COLORS as xCOLORS } from 'x/config/styles'
// import ImgBlock from '../../components/imgblock'
import _ from 'lodash'

import * as xFmt from 'x/utils/formatter'
import NB from 'x/config/nativebase'
import * as NavActions from 'x/utils/navigation'
import * as util from 'x/utils/util'
import { fetchVerifySlip } from 'x/utils/api'

import { delay, log, setStatePromise } from 'x/utils/util'
import BasePaymentView, { OPTION_COD_AMOUNT, OPTION_ORDER_AMOUNT } from 'x/modules/payment/BasePaymentView'
// import SwitchCard from '../../components/SwitchCard'
// import settings from "x/config/settings"
import p from 'x/config/platform-specific'
import { IBasePaymentViewState, IOrderListItem, IPaymentAccount, IPaymentViewNavParams } from 'x/index'
import { getConfig } from 'x/config/mode'

import moment from 'moment'

// // import BackIcon from '../../components/BackIcon'

// import ImgManager from '../../components/ImgManager'
// import s from './_style'
// import * as NavActions from '../../services/navigation'
// import DatePicker from 'react-native-datepicker'
// import XCustomHeader from '../../components/XCustomHeader'
// import ImagePreview from '../../components/ImagePreview'

// import * as indicator from '../../services/indicator'
// import StickyKeyboardTab from '../../components/StickyKeyboardTab'
// import * as utilN from '../../utils/util-native'
import XIcon from 'xui/components/XIcon'
import XText from 'xui/components/XText'
import XContainer from 'xui/components/XContainer'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContent from 'xui/components/XContent'
import XCard from 'xui/components/XCard'
import XIconButton from 'xui/components/XIconButton'
import XButton from 'xui/components/XButton'
import PaymentAccountPicker from 'xui/components/PaymentAccountPicker'
import XDateTimeSinglePicker from 'xui/components/XDateTimeSinglePicker'
import ImgManager from 'xui/components/ImgManager'
import BankIcon from 'xui/components/BankIcon'
import VerifySlipIcon from 'xui/components/VerifySlipIcon'
import XSpinner from 'xui/components/XSpinner'
import XInput from '../../components/XInput'
import XSegment from '../../components/Segment'

const { PAYMENT_METHOD } = CONS

const config = getConfig()
// const DEVICE_WIDTH = p.op.isWeb() ? Math.floor(CONS.SPECIFIC_PLATFORM.WEB.MAIN_CONTENT_WIDTH / 3) : Dimensions.get('window').width
const GB_PAY_LOGO = require('../../images/partner/gbpay.png')
const SCB_QR_LOGO = require('../../images/partner/maemanee.png')
const SCB_APP_LOGO = require('../../images/partner/scbapp.png')
const BEAM_LOGO = require('../../images/partner/beam.png')

abstract class BaseUIPaymentView extends BasePaymentView {
  waitingUploadCount: number

  // datePickerRef: React.RefObject<DatePicker>
  datePickerRef: React.RefObject<any>

  inputRefs: any[]

  scrollViewRef: any

  imgBlockRef: any

  isCheckingImages: boolean

  // imgManagerRef: React.RefObject<ImgManager>
  imgManagerRef: React.RefObject<any>

  static displayName = 'PaymentView'

  abstract navToPaymentOrderList: (params: {
    store_id: number
    orderStoreId?: number
    orderType?: number
    partnerStoreId?: number
    isCOD?: boolean
    isRefund?: boolean
    isShipBeforePay?: boolean
  }) => void

  // abstract renderBankAccountCard: (
  //   bankName: string,
  //   accountName: string,
  //   accountNumber: string,
  //   showAccountSelector: boolean
  // ) => JSX.Element

  // renderImageUpload: () => JSX.Element

  // renderDateTimePickerCard: () => JSX.Element

  openGBPayQRCodeFileFromUrl: (imgUrl: string, fileName: string, fileType: string) => void | Promise<void>

  showPaymentSpinner: () => void

  hidePaymentSpinner: () => void

  constructor(props) {
    super(props)
    this.datePickerRef = React.createRef()
    this.imgManagerRef = React.createRef()
  }

  componentWillUnmount() {
    this.props.resetPaymentOrderList()
  }

  // ==============  Abstract method (BEGIN) ==============
  _getParams(): IPaymentViewNavParams {
    const params = util.getNavParams(this.props)
    if (params) {
      return params
    }
    return {} as IPaymentViewNavParams
    // if (state.params) {
    //   return state.params
    // }
    // return {}
  }

  // _handleAfterAlertMissingField(fieldKey): void {
  //   // util.focusKeyRefIfExisted(this.inputRefs, fieldKey, this.scrollViewRef)
  //   if (fieldKey === 'seller_payment_account' && this.state.paymentMethod === 0) {
  //     this.props.navigation.dispatch(
  //       NavActions.navToSellerStorePaymentAccountListView({
  //         store_id: this.payToStoreId,
  //         title: this.paymentAccountSelectionTitle,
  //       }),
  //     )
  //   }
  // }

  _handleAfterAlertWrongTotalAmount(): void {
    // util.focusKeyRefIfExisted(this.inputRefs, 'total_amount', this.scrollViewRef)
  }

  // async _handleAfterSuccessSubmitPayment(res: Response): Promise<void> {
  //   const { navigation } = this.props
  //   const fromPaymentList = navigation.getParam('fromPaymentList', false)
  //   if (fromPaymentList) {
  //     // navigation.dispatch(NavActions.pop(2))
  //     // @ts-ignore
  //     navigation.pop(2)
  //   } else {
  //     navigation.goBack(navigation.state.key)
  //   }

  //   const onPaymentSuccess = navigation.getParam('onPaymentSuccess')
  //   if (_.isFunction(onPaymentSuccess)) {
  //     onPaymentSuccess()
  //   }
  // }
  // ============== Abstract method (END) ==============

  renderPaymentGatewayOption = (
    lable: string,
    color: string,
    selected: boolean,
    value: number,
    img?: { url: ImageSourcePropType; style: object }
  ) => (
    <HStack w='full' space='2' alignItems='center'>
      <XIcon name='caretright' family='AntDesign' style={{ color: selected ? color : 'transparent' }} />
      <TouchableOpacity
        onPress={() => this._onChangeGBOptions(value)}
        style={[
          RNStyle.BORDER,
          {
            borderWidth: selected ? 2 : 1,
            borderRadius: 4,
            borderColor: selected ? color : COLORS.TEXT_INACTIVE,
            // backgroundColor: color,
            flex: 1,
            width: '100%',
            flexWrap: 'wrap',
          },
        ]}>
        <View
          style={[
            // RNStyle.FLEX,
            RNStyle.CARDLIKE_BODY,
            RNStyle.WIDTH_FULL,
            RNStyle.ROW_MIDDLE_START,
            {
              flexDirection: 'row',
              paddingTop: 5,
              paddingBottom: 5,
              paddingLeft: 8,
              paddingRight: 8,
            },
          ]}>
          {!_.isNil(img) ? (
            <Image
              resizeMode='cover'
              style={{
                width: 30,
                height: 30,
                marginRight: 4,
                opacity: selected ? 1 : 0.3,
                ...img.style,
              }}
              source={img.url}
            />
          ) : (
            <View style={{ width: 34, height: 30 }} />
          )}
          <View style={[RNStyle.FLEX, RNStyle.ROW_MIDDLE_START]}>
            <XText
              variant='inactive'
              bold={selected}
              style={selected ? { color } : {}}
              // style={{
              //   // fontSize: STYLES.FONT_SIZE_NORMAL,
              //   color: selected ? color : COLORS.TEXT_INACTIVE,
              //   // fontWeight: selected ? 'bold' : 'normal',
              //   // ...Platform.select({
              //   //   web: {
              //   //     maxWidth: 175,
              //   //   },
              //   // }),
              // }}
            >
              {lable}
            </XText>
          </View>
        </View>
      </TouchableOpacity>
    </HStack>
  )

  renderPaymentGatewayOptions = () => {
    // console.log('this.gbSegmentOptions => ', this.gbSegmentOptions)
    const { paymentMethod, canEnableGBPay, canEnableBeam, canEnableSCBQRCode, isRefund } = this.state
    // XSeely
    // บันทึก (และแนบสลิป) ด้วยตนเอง
    const payByXSelly = paymentMethod === PAYMENT_METHOD.BANK_TRANSFER
    // โหลดรูป QR CODE จาก GB Pay\nให้ผู้ซื้อนำไปชำระต่อ
    // GB Pay
    const payByGBPay = paymentMethod === PAYMENT_METHOD.GBPAY
    const GB_img = { url: GB_PAY_LOGO, style: { width: 28, height: 28, marginRight: 6 } }
    // SCB QR Code
    const payBySCBQR = paymentMethod === PAYMENT_METHOD.SCB_QR_CODE
    const SCBQR_img = { url: SCB_QR_LOGO, style: { width: 35, height: 35, marginLeft: -3, marginRight: 2 } }
    // QCB App
    // const payBySCBApp = paymentMethod === 3
    // const SCB_APP_img = { url: SCB_APP_LOGO, style: { marginRight: 6 } }

    // BEAM
    const payByBeam = paymentMethod === PAYMENT_METHOD.BEAM
    const BEAM_APP_IMG = { url: BEAM_LOGO, style: { marginRight: 6 } }
    return (
      <XCard w='full'>
        <XCard.Body>
          <VStack w='full' py='2' space='2'>
            {/* Primary Option */}
            {this.renderPaymentGatewayOption(this.gbSegmentOptions[0].label, COLORS.APP_MAIN, payByXSelly, this.gbSegmentOptions[0].value)}

            {canEnableGBPay && !isRefund
              ? this.renderPaymentGatewayOption(
                  this.gbSegmentOptions[1].label,
                  COLORS.GB_PAY_MAIN,
                  payByGBPay,
                  this.gbSegmentOptions[1].value,
                  GB_img
                )
              : null}

            {canEnableSCBQRCode && !isRefund
              ? this.renderPaymentGatewayOption(
                  this.gbSegmentOptions[2].label,
                  COLORS.SCB_MAIN,
                  payBySCBQR,
                  this.gbSegmentOptions[2].value,
                  SCBQR_img
                )
              : null}

            {/* {this.renderPaymentGatewayOption('ชำระด้วย SCB Easy APP', COLORS.SCB_MAIN, payBySCBApp, 3, SCB_APP_img)} */}
            {canEnableBeam && !isRefund
              ? this.renderPaymentGatewayOption(
                  this.gbSegmentOptions[3].label,
                  COLORS.BEAM_MAIN,
                  payByBeam,
                  this.gbSegmentOptions[3].value,
                  BEAM_APP_IMG
                )
              : null}
          </VStack>
        </XCard.Body>
      </XCard>
    )
  }

  // renderPaymentGatewayOptionForm = () => {
  //   const {
  //     // amountTotal,
  //     paymentMethod,
  //     orderType,
  //     canEnableGBPay,
  //     canEnableSCBQRCode,
  //     isRefund,
  //     canEnableBeam,
  //   } = this.state
  //   const { editingPayment } = this.props
  //   // const MOCK_BANK_ACCOUNT = Map({ bank_id: 1, name: 'นายสุขสวัสดิ์ ชนะโชติ', account_number: '123-2-12345-12-1' })

  //   const payeeLabel = this._getTxtPayeeLable()
  //   const txtForOrders = this._getTxtForOrders()
  //   const accountLabel = this._getTxtAccountLabel()
  //   const { bankId, bankName, accountName, accountNumber, showAccountSelector } = this.getCurrentBankAccountInfo()

  //   // const total_amount = editingPayment.get('total_amount') ? parseFloat(editingPayment.get('total_amount')) : 0
  //   // const isCompletedAmountTotal = total_amount !== 0 && (isRefund ? total_amount === Math.abs(amountTotal) : total_amount === amountTotal)
  //   // console.log('paymentMethod', paymentMethod)
  //   const lastIndex = this.gbSegmentOptions.length - 1
  //   // console.log('paymentMethod // => ', paymentMethod)
  //   const selectedBeam = paymentMethod === 3
  //   const beamGuideLable =
  //     'ขั้นตอนการสร้างลิงก์ Beam เพื่อรับชำระ' +
  //     '\n1. กดปุ่มด้านล่าง "สร้างลิงก์สำหรับรับชำระผ่าน Beam"' +
  //     '\n2. แอปจะสร้างและคัดลอกลิงก์ให้อัตโนมัติ คุณสามารถนำลิงก์ไปวางให้ลูกค้า เช่นในแชท เพื่อทำการชำระได้เลย'
  //   return (
  //     <View
  //       // @ts-ignore
  //       // style={StyleSheet.flatten([s.flexNoWrap, { flexBasis: 'auto', paddingRight: 5, marginRight: p.op.isWeb() ? 6 : 2 }])}
  //       style={[RNStyle.CARDLIKE_BORDERS, RNStyle.CARDLIKE_MARGIN, RNStyle.BG_WHITE]}>
  //       {this.showTitleLabel ? (
  //         <View
  //           style={{
  //             flex: 1,
  //             flexDirection: 'row',
  //             justifyContent: 'center',
  //             alignItems: 'center',
  //             backgroundColor: COLORS.BLUE_TAB,
  //             marginTop: 2,
  //             marginLeft: 5,
  //             paddingTop: 2,
  //             ...Platform.select({
  //               web: {
  //                 flex: 0,
  //                 flexBasis: 'auto',
  //                 minHeight: 50,
  //                 width: '100%',
  //               },
  //             }),
  //           }}>
  //           <View
  //             // @ts-ignore
  //             style={{
  //               flex: 1,
  //               ...Platform.select({
  //                 web: {
  //                   flexBasis: 'auto',
  //                   minHeight: 50,
  //                   width: '100%',
  //                   alignItems: 'center',
  //                   justifyContent: 'center',
  //                 },
  //               }),
  //             }}>
  //             <XText
  //               style={{
  //                 textAlign: 'center',
  //                 color: COLORS.TEXT_ACTIVE,
  //                 fontSize: STYLES.FONT_SIZE_NORMAL,
  //                 fontWeight: 'bold',
  //               }}>
  //               {'เมื่อผู้ขาย(ฉัน)บันทึกรับชำระแทนผู้ซื้อในหน้านี้แล้ว\nผู้ซื้อก็จะไม่ต้องมาแจ้งชำระเองอีก หากยอดครบถ้วนแล้ว'}
  //             </XText>
  //           </View>
  //         </View>
  //       ) : null}

  //       <View
  //         style={[
  //           RNStyle.CARDLIKE_BODY,
  //           {
  //             // flex: 1,
  //             flexBasis: 'auto',
  //             backgroundColor: 'transparent',
  //             paddingTop: 4,
  //             paddingRight: 4,
  //             paddingBottom: 4,
  //             paddingLeft: 4,
  //           },
  //         ]}>
  //         {this._renderInlineContentWithLabel({
  //           label: payeeLabel,
  //           content: this._renderDisplayStoreName(),
  //         })}

  //         {this._renderItemLabel(txtForOrders)}
  //         {this._renderRelateOrders()}

  //         {this._renderInlineContentWithLabel({
  //           label: 'ยอดรวม',
  //           content: this._renderCalculateOrderAmount(),
  //           isVerticalCenter: true,
  //         })}

  //         {this._renderInputAmountTotal()}

  //         {canEnableGBPay ? this._renderItemLabel('เลือกรูปแบบชำระ') : null}
  //         {canEnableGBPay || canEnableSCBQRCode || canEnableBeam ? this.renderPaymentGatewayOptions() : null}
  //         {/* {canEnableGBPay ? (
  //             <View
  //               style={{
  //                 flex: 1,
  //                 flexDirection: 'row',
  //                 justifyContent: 'center',
  //                 alignItems: 'center',
  //               }}>
  //               <Segment>
  //                 {this.gbSegmentOptions.map((data, idx) =>
  //                   this._renderGBSegmentItem({
  //                     key: `gbseg_${idx}`,
  //                     ...data,
  //                     onPressSegment: this._onChangeGBOptions,
  //                     isFirst: idx === 0,
  //                     isLast: idx === lastIndex,
  //                     isActive: data.value === paymentMethod,
  //                   }),
  //                 )}
  //               </Segment>
  //             </View>
  //           ) : null} */}
  //         {paymentMethod && !selectedBeam ? (
  //           <View
  //             style={{
  //               flex: 1,
  //               flexDirection: 'row',
  //               justifyContent: 'flex-start',
  //               alignItems: 'flex-start',
  //               paddingHorizontal: 20,
  //               paddingVertical: 10,
  //             }}>
  //             <XText
  //               style={{
  //                 fontSize: STYLES.FONT_SIZE_NORMAL,
  //                 color: COLORS.TEXT_INACTIVE,
  //               }}
  //               allowFontScaling={false}>
  //               {this.txtQRCodeSwitchGuide}
  //             </XText>
  //           </View>
  //         ) : null}

  //         {selectedBeam ? (
  //           <View
  //             style={{
  //               flex: 1,
  //               flexDirection: 'row',
  //               justifyContent: 'flex-start',
  //               alignItems: 'flex-start',
  //               paddingHorizontal: 20,
  //               paddingVertical: 10,
  //             }}>
  //             <XText
  //               style={{
  //                 fontSize: STYLES.FONT_SIZE_NORMAL,
  //                 color: COLORS.BEAM_MAIN,
  //               }}
  //               allowFontScaling={false}>
  //               {beamGuideLable}
  //             </XText>
  //           </View>
  //         ) : null}

  //         {/* {!_.isNil(beamUrlPayment) ? (
  //           <View
  //             style={{
  //               flex: 1,
  //               // flexDirection: 'row',
  //               // justifyContent: 'flex-start',
  //               // alignItems: 'flex-start',
  //               paddingHorizontal: 20,
  //               paddingVertical: 10,
  //             }}>
  //             <XText
  //               style={{
  //                 fontSize: STYLES.FONT_SIZE_LARGER,
  //                 color: COLORS.TEXT_INACTIVE,
  //               }}
  //               allowFontScaling={false}>
  //               {'กดปุ่ม "คัดลอกลิงก์ Beam เพื่อชำระอีกครั้ง" เพื่อคัดลอกลิงก์ Beam ใหม่'}
  //             </XText>
  //             <TouchableOpacity
  //               onPress={() => {
  //                 p.op.showToast('คัดลอกลิงก์ Beam สำหรับชำระเรียบร้อยแล้ว', 'success')
  //               }}
  //               style={{
  //                 justifyContent: 'center',
  //                 alignItems: 'center',
  //                 borderWidth: 1,
  //                 borderColor: COLORS.BEAM_MAIN,
  //                 borderRadius: 7,
  //                 height: 35,
  //                 backgroundColor: COLORS.BEAM_MAIN,
  //               }}>
  //               <XText style={{ fontSize: STYLES.FONT_SIZE_NORMAL, fontWeight: 'bold', color: COLORS.WHITE }}>
  //                 {'คัดลอกลิงก์ Beam เพื่อชำระอีกครั้ง'}
  //               </XText>
  //             </TouchableOpacity>
  //           </View>
  //         ) : null} */}

  //         {paymentMethod ? null : this._renderItemLabel(accountLabel)}
  //         {paymentMethod ? null : this.renderBankAccountCard(bankName, accountName, accountNumber, showAccountSelector)}

  //         {paymentMethod ? null : this._renderItemLabel('สลิปโอนเงิน')}
  //         {paymentMethod ? null : this.renderImageUpload()}

  //         {paymentMethod
  //           ? null
  //           : this._renderInlineContentWithLabel({
  //               label: 'เวลาชำระ',
  //               content: this.renderDateTimePickerCard(),
  //               isVerticalCenter: true,
  //             })}

  //         {paymentMethod === 1 || paymentMethod === 2 || paymentMethod === 3 ? null : this._renderItemLabel('หมายเหตุ')}
  //         {paymentMethod === 1 || paymentMethod === 2 || paymentMethod === 3
  //           ? null
  //           : this._renderMultilineInput({
  //               key: 'note',
  //               // label: 'หมายเหตุ',
  //               placeholder: 'บันทึกสิ่งช่วยจำเกี่ยวกับการชำระเงินครั้งนี้',
  //               keyboardType: 'default',
  //             })}
  //       </View>
  //     </View>
  //   )
  // }

  renderPaymentForm = () => {
    const {
      // amountTotal,
      paymentMethod,
      orderType,
      canEnableGBPay,
      canEnableSCBQRCode,
      isRefund,
      canEnableBeam,
      isVerifySlipEnabled,
      isSelectedPaymentAccountVerifySlipSupported,
      verifySlip,
    } = this.state
    const { editingPayment } = this.props
    // const MOCK_BANK_ACCOUNT = Map({ bank_id: 1, name: 'นายสุขสวัสดิ์ ชนะโชติ', account_number: '123-2-12345-12-1' })

    const payeeLabel = this._getTxtPayeeLable()
    const txtForOrders = this._getTxtForOrders()
    const accountLabel = this._getTxtAccountLabel()
    // const { bankId, bankName, accountName, accountNumber, showAccountSelector } = this.getCurrentBankAccountInfo()

    // const total_amount = editingPayment.get('total_amount') ? parseFloat(editingPayment.get('total_amount')) : 0
    // const isCompletedAmountTotal = total_amount !== 0 && (isRefund ? total_amount === Math.abs(amountTotal) : total_amount === amountTotal)
    // console.log('paymentMethod', paymentMethod)
    const lastIndex = this.gbSegmentOptions.length - 1
    // console.log('paymentMethod // => ', paymentMethod)
    const selectedBeam = paymentMethod === PAYMENT_METHOD.BEAM
    const beamGuideLable =
      'ขั้นตอนการสร้างลิงก์ Beam เพื่อรับชำระ' +
      '\n1. กดปุ่มด้านล่าง "สร้างลิงก์สำหรับรับชำระผ่าน Beam"' +
      '\n2. แอปจะสร้างและคัดลอกลิงก์ให้อัตโนมัติ คุณสามารถนำลิงก์ไปวางให้ลูกค้า เช่นในแชท เพื่อทำการชำระได้เลย'

    // console.log('verifySlip: ', verifySlip)
    // console.log('_.isEmpty(verifySlip): ' ,_.isEmpty(verifySlip))
    return (
      <XCard w='full'>
        <XCard.Body>
          <VStack w='full' space='2'>
            {this.showTitleLabel ? (
              <HStack w='full' minH='50px' bgColor={COLORS.BLUE_TAB} justifyContent='center' alignItems='center'>
                <XText bold textAlign='center'>
                  {'เมื่อผู้ขาย(ฉัน)บันทึกรับชำระแทนผู้ซื้อในหน้านี้แล้ว\nผู้ซื้อก็จะไม่ต้องมาแจ้งชำระเองอีก หากยอดครบถ้วนแล้ว'}
                </XText>
              </HStack>
            ) : null}

            {/* {this._renderInlineContentWithLabel({
              label: payeeLabel,
              content: this._renderDisplayStoreName(),
            })} */}

            <HStack alignItems='center' justifyContent='space-between'>
              <XText variant='inactive'>{payeeLabel}</XText>
              <XText>{this.getPartnerName()}</XText>
            </HStack>

            <VStack w='full'>
              <XText variant='inactive'>{txtForOrders}</XText>
              {this.renderRelateOrders()}
            </VStack>

            {/* {this._renderItemLabel(txtForOrders)} */}
            {/* {this._renderRelateOrders()} */}

            {/* {this._renderInlineContentWithLabel({
              label: 'ยอดรวม',
              content: this._renderCalculateOrderAmount(),
              isVerticalCenter: true,
            })} */}

            <HStack alignItems='center' justifyContent='space-between'>
              <XText variant='inactive'>ยอดรวม</XText>
              {this.renderCalculateOrderAmount()}
            </HStack>

            {this.renderInputAmountTotal()}

            {/* {canEnableGBPay ? this._renderItemLabel('เลือกรูปแบบชำระ') : null} */}
            {/* {canEnableGBPay || canEnableSCBQRCode || canEnableBeam ? this.renderPaymentGatewayOptions() : null} */}

            {/* {isSelectedPaymentAccountVerifySlipSupported ? (
              <VStack w='full'>
                <XText variant='inactive'>Yo</XText>
              </VStack>
            ) : null} */}

            {/* แสดง รูปแบบการชำระ ถ้าไม่มีข้อมูล Verify Slip และ มีหลาย payment gateway */}
            {(!verifySlip || _.isEmpty(verifySlip)) && (canEnableGBPay || canEnableSCBQRCode || (canEnableBeam && !isRefund)) ? (
              <VStack w='full'>
                <XText variant='inactive'>เลือกรูปแบบชำระ</XText>
                {this.renderPaymentGatewayOptions()}
              </VStack>
            ) : null}

            {/* {canEnableGBPay ? (
              <View
                style={{
                  flex: 1,
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Segment>
                  {this.gbSegmentOptions.map((data, idx) =>
                    this._renderGBSegmentItem({
                      key: `gbseg_${idx}`,
                      ...data,
                      onPressSegment: this._onChangeGBOptions,
                      isFirst: idx === 0,
                      isLast: idx === lastIndex,
                      isActive: data.value === paymentMethod,
                    }),
                  )}
                </Segment>
              </View>
            ) : null} */}
            {paymentMethod && !selectedBeam ? (
              <View
                style={{
                  flex: 1,
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  paddingHorizontal: 20,
                  paddingVertical: 10,
                }}>
                <XText
                  style={{
                    fontSize: STYLES.FONT_SIZE_NORMAL,
                    color: COLORS.TEXT_INACTIVE,
                  }}
                  allowFontScaling={false}>
                  {this.txtQRCodeSwitchGuide}
                </XText>
              </View>
            ) : null}

            {selectedBeam ? (
              <View
                style={{
                  flex: 1,
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  paddingHorizontal: 20,
                  paddingVertical: 10,
                }}>
                <XText
                  style={{
                    fontSize: STYLES.FONT_SIZE_NORMAL,
                    color: COLORS.BEAM_MAIN,
                  }}
                  allowFontScaling={false}>
                  {beamGuideLable}
                </XText>
              </View>
            ) : null}

            {/* {!_.isNil(beamUrlPayment) ? (
            <View
              style={{
                flex: 1,
                // flexDirection: 'row',
                // justifyContent: 'flex-start',
                // alignItems: 'flex-start',
                paddingHorizontal: 20,
                paddingVertical: 10,
              }}>
              <XText
                style={{
                  fontSize: STYLES.FONT_SIZE_LARGER,
                  color: COLORS.TEXT_INACTIVE,
                }}
                allowFontScaling={false}>
                {'กดปุ่ม "คัดลอกลิงก์ Beam เพื่อชำระอีกครั้ง" เพื่อคัดลอกลิงก์ Beam ใหม่'}
              </XText>
              <TouchableOpacity
                onPress={() => {
                  p.op.showToast('คัดลอกลิงก์ Beam สำหรับชำระเรียบร้อยแล้ว', 'success')
                }}
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderWidth: 1,
                  borderColor: COLORS.BEAM_MAIN,
                  borderRadius: 7,
                  height: 35,
                  backgroundColor: COLORS.BEAM_MAIN,
                }}>
                <XText style={{ fontSize: STYLES.FONT_SIZE_NORMAL, fontWeight: 'bold', color: COLORS.WHITE }}>
                  {'คัดลอกลิงก์ Beam เพื่อชำระอีกครั้ง'}
                </XText>
              </TouchableOpacity>
            </View>
          ) : null} */}

            {/* {paymentMethod ? null : this._renderItemLabel(accountLabel)} */}
            {/* {paymentMethod ? null : this.renderBankAccountCard(bankName, accountName, accountNumber, showAccountSelector)} */}

            {/* {paymentMethod ? null : (
              <VStack w='full'>
                <XText variant='inactive'>{accountLabel}</XText>
                {this._renderBankAccountCard()}
              </VStack>
            )} */}

            {!verifySlip || _.isEmpty(verifySlip) ? null : this.renderVerifySlipInfo()}

            {!verifySlip || _.isEmpty(verifySlip) ? (
              <VStack w='full' style={paymentMethod !== CONS.PAYMENT_METHOD.BANK_TRANSFER ? { display: 'none' } : {}}>
                <XText variant='inactive'>{accountLabel}</XText>
                {this._renderBankAccountCard()}
              </VStack>
            ) : null}

            {/* {paymentMethod ? null : this._renderItemLabel('สลิปโอนเงิน')} */}
            {/* {paymentMethod ? null : this.renderImageUpload()} */}

            <VStack w='full' style={paymentMethod ? { display: 'none' } : {}}>
              <XText variant='inactive'>สลิปโอนเงิน</XText>
              {this.renderImageUpload()}
            </VStack>

            {/* {paymentMethod
              ? null
              : this._renderInlineContentWithLabel({
                  label: 'เวลาชำระ',
                  content: this.renderDateTimePickerCard(),
                  isVerticalCenter: true,
                })} */}

            {paymentMethod !== CONS.PAYMENT_METHOD.BANK_TRANSFER ? null : this.renderDateTimePickerCardByVerifySlipCondition()}

            {/* {paymentMethod === 1 || paymentMethod === 2 || paymentMethod === 3 ? null : this._renderItemLabel('หมายเหตุ')} */}
            {/* {paymentMethod === 1 || paymentMethod === 2 || paymentMethod === 3
              ? null
              : this._renderMultilineInput({
                  key: 'note',
                  // label: 'หมายเหตุ',
                  placeholder: 'บันทึกสิ่งช่วยจำเกี่ยวกับการชำระเงินครั้งนี้',
                  keyboardType: 'default',
                })} */}

            {_.includes([PAYMENT_METHOD.GBPAY, PAYMENT_METHOD.SCB_QR_CODE, PAYMENT_METHOD.BEAM], paymentMethod) ? null : (
              <VStack w='full'>
                <XText variant='inactive'>หมายเหตุ</XText>
                {this.renderPaymentNoteInput()}
              </VStack>
            )}
          </VStack>
        </XCard.Body>
      </XCard>
    )
  }

  renderVerifySlipInfo = () => {
    const { verifySlip: vs, selectedSellerPaymentAccount: pa, paymentTotalAmount = '0', sellerPaymentAccounts: pas } = this.state

    if (!vs) {
      return null
    }

    // local
    const bankName = util.getBankNameFromBankID(pa.bank_id)
    const bankNumber = pa.account_number || null

    const isVerifySlipSuccess = vs.create_payment_validation_status === 'SUCCESS'
    const verifiedSlipReceivingPaymentAccountId = vs.receiving_payment_account_id || null

    // ใช้กรณี ไม่มี receiving_payment_account_id จะมาแสดง
    // const hasVerifiedSlipReceiverInfo = _.isString(vs.transaction_time) && _.isObject(vs.receiver) && _.isString(vs.receiver.name)
    // const verifiedSlipReceivingBankId = util.convertVerifySlipBankCodeToBankId(vs.receiving_bank_code) || 1
    // const verifiedSlipReceivingBankName = util.getBankNameFromBankID(verifiedSlipReceivingBankId)

    // @ts-ignore
    const isMismatchAmountCase = isVerifySlipSuccess && parseFloat(paymentTotalAmount) !== parseFloat(vs.amount)
    const isSuccessCase = isVerifySlipSuccess && !isMismatchAmountCase

    const isVerifySlipDangerError = _.includes(
      ['InvalidQRCode', 'RemoteServerError', 'TransactionRejected'],
      vs.create_payment_validation_status
    )

    return (
      <VStack w='full' space='1'>
        <VStack w='full' p='2' space='1' bg={isSuccessCase ? COLORS.FORM_SUCCESS_BG : COLORS.FORM_ERROR_BG}>
          <HStack w='full' alignItems='center' space='1'>
            <VerifySlipIcon
              success={isSuccessCase}
              warning={!isSuccessCase && !isVerifySlipDangerError}
              danger={!isSuccessCase && isVerifySlipDangerError}
            />
            <XText color={isSuccessCase ? COLORS.BRAND_Success : COLORS.BRAND_Danger} fontSize='md' bold>
              {isMismatchAmountCase
                ? p.op.t('verifySlipStatus.MismatchAmount')
                : p.op.t(`verifySlipStatus.${vs.create_payment_validation_status}`)}
            </XText>
          </HStack>

          {isMismatchAmountCase ? (
            <HStack w='full' mt='1' mb='1' p='2' alignItems='center' space='1' borderRadius='md' bg='warning.200'>
              <XText color={COLORS.BRAND_Danger} bold>
                {'คุณสามารถเลือกทำ 1 ในวิธีด้านล่างนี้ได้ (เลือกเพียงข้อเดียว)' +
                  '\n1. กดปุ่ม "ยืนยันการชำระ" ด้านล่างท้ายหน้านี้ เพื่อใช้สลิปนี้ในการแจ้งชำระ โดยระบบจะไม่ยืนยันการชำระนี้ให้โดยอัตโนมัติ หรือ' +
                  '\n2. แก้ไข "ยอดชำระ" ให้ตรงกับ "ยอดสลิป" หรือ' +
                  '\n3. กดปุ่ม "ยกเลิกการใช้งานสลิปนี้" ด้านขวาล่าง แล้วแนบสลิปที่ถูกต้องมาใหม่'}
              </XText>
            </HStack>
          ) : null}

          {!isVerifySlipSuccess ? (
            <HStack w='full' mt='1' mb='1' p='2' alignItems='center' space='1' borderRadius='md' bg='warning.200'>
              <XText color={COLORS.BRAND_Danger} bold>
                {'คุณสามารถเลือกทำ 1 ในวิธีด้านล่างนี้ได้ (เลือกเพียงข้อเดียว)' +
                  '\n1. กดปุ่ม "ยืนยันการชำระ" ด้านล่างท้ายหน้านี้ เพื่อใช้สลิปนี้ในการแจ้งชำระ โดยระบบจะไม่ยืนยันการชำระนี้ให้โดยอัตโนมัติ หรือ' +
                  '\n2. กดปุ่ม "ยกเลิกการใช้งานสลิปนี้" ด้านขวาล่าง แล้วแนบสลิปที่ถูกต้องมาใหม่'}
              </XText>
            </HStack>
          ) : null}

          {vs.amount ? (
            <HStack w='full' justifyContent='space-between'>
              <XText variant='inactive' w='100px'>
                ยอดสลิป
              </XText>
              <XText color={isSuccessCase ? COLORS.BRAND_Success : COLORS.BRAND_Danger} flex={1} textAlign='right' fontSize='md' bold>
                {xFmt.formatCurrency(vs.amount)}
              </XText>
            </HStack>
          ) : null}

          {vs.transaction_time ? (
            <HStack w='full' justifyContent='space-between'>
              <XText variant='inactive' w='100px'>
                วันที่ทำรายการ
              </XText>
              <XText color={isSuccessCase ? COLORS.BRAND_Success : COLORS.BRAND_Danger} flex={1} textAlign='right' bold>
                {vs.transaction_time}
              </XText>
            </HStack>
          ) : null}

          <XText variant='inactive' w='100px'>
            บัญชีผู้รับ
          </XText>

          {/* {hasVerifiedSlipReceiverInfo && !verifiedSlipReceivingPaymentAccountId ? (
            <HStack w='full' space='1'>
              <VStack w='42px' justifyContent='center' alignItems='center'>
                <BankIcon bankId={verifiedSlipReceivingBankId} />
              </VStack>

              <HStack w='100px' alignItems='center'>
                <XText numberOfLines={2} variant='inactive'>
                  {verifiedSlipReceivingBankName}
                </XText>
              </HStack>

              <VStack flex={1} minH='9' space='1' flexWrap='wrap'>
                <HStack w='full'>
                  <XText numberOfLines={2} color={isSuccessCase ? COLORS.BRAND_Success : COLORS.BRAND_Danger} flex={1} textAlign='right'>
                    {vs.receiver.name}
                  </XText>
                </HStack>

                {vs.receiver.account_number ? (
                  <XText numberOfLines={2} color={isSuccessCase ? COLORS.BRAND_Success : COLORS.BRAND_Danger} flex={1} textAlign='right'>
                    {vs.receiver.account_number}
                  </XText>
                ) : (
                  <XText>-</XText>
                )}
              </VStack>
            </HStack>
          ) : null} */}

          {/* {verifiedSlipReceivingPaymentAccountId ? ( */}
          <HStack w='full' space='1'>
            <VStack w='42px' justifyContent='center' alignItems='center'>
              <BankIcon bankId={pa.bank_id} />
            </VStack>

            <HStack w='100px' alignItems='center'>
              <XText numberOfLines={2} variant='inactive'>
                {bankName}
              </XText>
            </HStack>

            <VStack flex={1} minH='9' space='1' flexWrap='wrap'>
              <HStack w='full'>
                <XText numberOfLines={2} color={isSuccessCase ? COLORS.BRAND_Success : COLORS.BRAND_Danger} flex={1} textAlign='right'>
                  {pa.name}
                </XText>
              </HStack>

              {bankNumber ? (
                <XText numberOfLines={2} color={isSuccessCase ? COLORS.BRAND_Success : COLORS.BRAND_Danger} flex={1} textAlign='right'>
                  {bankNumber}
                </XText>
              ) : (
                <XText>-</XText>
              )}
            </VStack>
          </HStack>
          {/* ) : null} */}

          <HStack w='full' justifyContent='space-between'>
            <XText variant='inactive' w='100px'>
              เลขที่รายการ
            </XText>
            <XText color={isSuccessCase ? COLORS.BRAND_Success : COLORS.BRAND_Danger} flex={1} textAlign='right'>
              {vs.transaction_ref}
            </XText>
          </HStack>
        </VStack>
        <HStack w='full' justifyContent='flex-end'>
          <XButton variant='outline' onPress={this.onPressCancelVerifySlip}>
            ยกเลิกการใช้งานสลิปนี้
          </XButton>
        </HStack>
      </VStack>
    )
  }

  onPressCancelVerifySlip = async () => {
    const isUserConfirmToCancel = await new Promise((resolve) => {
      p.op.showConfirmation(
        'คุณต้องการยกลิกการใช้สลิปนี้ใช่หรือไม่?',
        '',
        () => resolve(false),
        () => resolve(true),
        'ไม่',
        'ใช่'
      )
    })

    if (isUserConfirmToCancel) {
      try {
        this.imgManagerRef.current.clearImages()
      } catch (err) {
        console.log('onPressCancelVerifySlip err => ', err)
      }
      await util.setStatePromise(this, { verifySlip: null, isSelectedPaymentAccountVerifySlipSupported: false })
    }
  }

  renderDateTimePickerCardByVerifySlipCondition = () => {
    const { paymentTotalAmount = '0', verifySlip: vs } = this.state
    // console.log('renderDateTimePickerCardByVerifySlipCondition paymentTotalAmount => ', paymentTotalAmount)
    // console.log('renderDateTimePickerCardByVerifySlipCondition vs => ', vs)
    // @ts-ignore
    if (vs && vs.create_payment_validation_status === 'SUCCESS' && parseFloat(paymentTotalAmount) === parseFloat(vs.amount)) {
      return null
    }

    return this.renderDateTimePickerCard()
  }

  renderDateTimePickerCard = () => {
    const { paymentPostDate } = this.state

    return (
      <VStack w='full'>
        <XText variant='inactive'>เวลาชำระ</XText>
        <HStack w='full' minH='9'>
          <XDateTimeSinglePicker selectedDate={paymentPostDate || new Date()} onChangeDate={this.onChangePaymentPostDate} />
        </HStack>
      </VStack>
    )
  }

  _onChangePaymentAccount = (selectedPaymentAccount: IPaymentAccount) => {
    // console.log('_onChangePaymentAccount selectedPaymentAccount => ', selectedPaymentAccount)
    // const isSelectedPaymentAccountVerifySlipSupported = selectedPaymentAccount.is_for_verify_slip || false
    const isSelectedPaymentAccountVerifySlipSupported = selectedPaymentAccount.is_verify_slip_active || false

    // await util.setStatePromise(this, { selectedSellerPaymentAccount: selectedPaymentAccount, isSelectedPaymentAccountVerifySlipSupported })
    // await this._onSuccessChooseSlipToVerify()
    this.setState({ selectedSellerPaymentAccount: selectedPaymentAccount, isSelectedPaymentAccountVerifySlipSupported }, () => {
      this._onSuccessChooseSlipToVerify()
    })
    return selectedPaymentAccount
  }

  _onSuccessChooseSlipToVerify = async () => {
    const { isSlipVerifying = false } = this.state
    if (isSlipVerifying) {
      return
    }
    await util.setStatePromise(this, { isSlipVerifying: true })

    // const { selectedSellerPaymentAccount } = this.state
    const isSuccess = await this._doUploadPaymentSlip()
    const { paymentSlip, sellerPaymentAccounts } = this.state
    console.log('_onSuccessChooseSlipToVerify isSuccess => ', isSuccess)
    console.log('_onSuccessChooseSlipToVerify paymentSlip => ', paymentSlip)
    let isWorstVerifyFail = false
    if (isSuccess && paymentSlip && paymentSlip.img_uris.length > 0) {
      // const { myStoreId } = this._getParams()
      const payeeStoreId = this._getPayeeStoreId()

      try {
        const verifySlip = await fetchVerifySlip({ store_id: payeeStoreId, img_url: paymentSlip.img_uris[0] })
        console.log('_onSuccessChooseSlipToVerify verifySlip => ', verifySlip)

        const newState: Partial<IBasePaymentViewState> = { verifySlip }

        if (verifySlip && verifySlip.receiving_payment_account_id) {
          const receivingPaymentAccount = sellerPaymentAccounts.find((spai) => spai.id === verifySlip.receiving_payment_account_id)

          if (_.isObject(receivingPaymentAccount) && receivingPaymentAccount.id) {
            newState.selectedSellerPaymentAccount = receivingPaymentAccount
          }
        }

        await util.setStatePromise(this, newState)
      } catch (err) {
        console.log('_onSuccessChooseSlipToVerify err => ', JSON.stringify(err))
        isWorstVerifyFail = true
        await util.setStatePromise(this, { verifySlip: null })
      }
    }

    if (isWorstVerifyFail) {
      const isUserConfirmed = await p.op.isUserConfirm(
        'การตรวจสอบสลิปล้มเหลว',
        'ยังต้องการใช้สลิปนี้ เพื่อยืนยันชำระในขั้นตอนปกติ ใช่หรือไม่'
      )
      if (isUserConfirmed) {
        await util.setStatePromise(this, { isSlipVerifying: false })
        return
      }
      try {
        this.imgManagerRef.current.clearImages()
      } catch (err) {
        console.log('_onSuccessChooseSlipToVerify isWorstVerifyFail err => ', JSON.stringify(err))
      }
    }

    await util.setStatePromise(this, { isSlipVerifying: false })
  }

  renderImageUpload = () => {
    const { uploading = false, isSelectedPaymentAccountVerifySlipSupported, verifySlip } = this.state
    const txtPrefixImageName = config.s3_prefix_type_payslip + this._getPrefixForImageSlip()
    const hasVerifiedSlip = _.isObject(verifySlip) && !_.isEmpty(verifySlip)
    // return <Text>I am ImgManager</Text>
    return (
      <VStack w='full'>
        <ImgManager
          // ref={this._onImgManagerRef}
          ref={this.imgManagerRef}
          fileNamePrefix={txtPrefixImageName}
          metadata={[
            { key: 'p', width: 2000, height: 2000, allowFullSizeUpload: true },
            { key: 't', width: 1000, height: 1000 },
          ]}
          uploading={uploading}
          readonly={uploading || hasVerifiedSlip}
          showUploaded
          // maxLength={4}
          maxLength={isSelectedPaymentAccountVerifySlipSupported ? 1 : 4}
          onPressChooseSuccess={isSelectedPaymentAccountVerifySlipSupported ? this._onSuccessChooseSlipToVerify : undefined}
        />
      </VStack>
    )
  }

  _renderBankAccountCard = () => {
    const { isInitialized = false, selectedSellerPaymentAccount } = this.state
    if (!isInitialized) {
      return null
    }
    const selectedPaymentAccountId =
      selectedSellerPaymentAccount && selectedSellerPaymentAccount.id ? selectedSellerPaymentAccount.id : null
    // const bankAccount = payment.get('seller_payment_account')
    // console.log('bankAccount => ', bankAccount)
    return (
      <PaymentAccountPicker
        targetStoreId={this.payToStoreId}
        selectedPaymentAccountId={selectedPaymentAccountId}
        // @ts-ignore
        onChangePaymentAccount={this._onChangePaymentAccount}
        onPaymentAccountLoaded={this._onPaymentAccountsLoaded}
        placeholderText={this._getTxtAccountSelector()}
      />
    )
  }

  _renderGBSegmentItem = ({ key, label, value, onPressSegment, isFirst = false, isLast = false, isActive = false }) => {
    const handlePressSegmentItem =
      _.isFunction(onPressSegment) && (!_.isNull(value) || !_.isUndefined(value)) ? () => onPressSegment(value) : null
    return (
      <Button
        key={key}
        // first={isFirst}
        // last={isLast}
        // active={isActive}
        style={{ maxWidth: 150, height: 45 }}
        onPress={handlePressSegmentItem}>
        <XText
          allowFontScaling={false}
          numberOfLines={2}
          style={{
            fontSize: STYLES.FONT_SIZE_NORMAL,
            color: isActive ? COLORS.TEXT_ACTIVE_DARK : COLORS.APP_MAIN,
            fontWeight: isActive ? 'bold' : 'normal',
            textAlign: 'center',
          }}>
          {label}
        </XText>
      </Button>
    )
  }

  // Render with Style Component
  _renderItemLabel(text) {
    // return (
    //   <Item disabled={true} style={{ marginTop: 10, borderBottomWidth: 0 }}>
    //     <Label style={s.txtLabel}>{text}</Label>
    //   </Item>
    // )
    return (
      <View style={{ marginTop: 10, marginLeft: 15, borderBottomWidth: 0 }}>
        <XText style={s.txtLabel}>{text}</XText>
      </View>
    )
  }

  _renderItemWithMarginLeftCard = (content) => this._renderContentWithMarginLeft(this._renderCardWarpContent(content))

  _renderCardWarpContent = (content) => (
    <Card style={[StyleSheet.flatten(s.flexNoWrap), { marginBottom: 4 }]}>
      <Box>{content}</Box>
    </Card>
  )

  _renderContentWithMarginLeft = (content) => (
    <Stack style={{ flexBasis: 'auto' }}>
      <VStack style={{ width: 25, flexBasis: 'auto' }} />
      <VStack style={{ flexBasis: 'auto' }}>{content}</VStack>
    </Stack>
  )

  getPartnerName = () => {
    // const { partnerName } = this.props.navigation.state.params
    const partnerName = util.getNavParam(this.props, 'partnerName')
    const name = partnerName || 'ไม่ระบุ'
    return name
  }

  // Render Form Data
  _renderDisplayStoreName = () => {
    // const { partnerName } = this.props.navigation.state.params
    // const name = partnerName ? partnerName : 'ไม่ระบุ'
    const name = this.getPartnerName()
    // let id = payeeStoreId ? `(Store ID: ${orderStoreId})` : ''
    return (
      <View style={{ flex: 2, justifyContent: 'flex-start', alignItems: 'center' }}>
        <XText allowFontScaling={false} style={[s.txtNormal, { paddingRight: 15 }]}>
          {name}
        </XText>
      </View>
    )
  }

  // _renderCalculateOrderAmount = () => {
  //   const { requiredCodAmountTotal, requiredRemainingAmountTotal } = this.state
  //   return (
  //     <View style={[RNStyle.ROW_MIDDLE_END]}>
  //       <View style={[RNStyle.COLUMN_RIGHT_MIDDLE, { width: 120, paddingRight: 4 }]}>
  //         {this.getIsCOD() && requiredCodAmountTotal > 0 ? (
  //           <XText style={[s.txtNormal, { fontWeight: 'bold', color: COLORS.BRAND_Primary }]}>
  //             {`[COD ${xFmt.formatCurrency(requiredCodAmountTotal)}]`}
  //           </XText>
  //         ) : null}
  //       </View>
  //       <View style={[RNStyle.COLUMN_RIGHT_MIDDLE, { width: 85, paddingRight: 10 }]}>
  //         <XText style={[s.txtNormal, { fontWeight: 'bold' }]}>{xFmt.formatCurrency(requiredRemainingAmountTotal)}</XText>
  //       </View>
  //     </View>
  //   )
  // }

  renderCalculateOrderAmount = () => {
    const { requiredCodAmountTotal, requiredRemainingAmountTotal, ordersToBePaid = {} } = this.state
    return (
      <HStack flex={1} pr='13px' alignItems='center' justifyContent='flex-end' flexWrap='wrap'>
        {this.getIsCOD() && requiredCodAmountTotal > 0 && !this.isOrdersAvoidCOD() ? (
          <XText maxW='85px' color={COLORS.BRAND_Primary} bold textAlign='right'>
            {`[COD ${xFmt.formatCurrency(requiredCodAmountTotal)}]`}
          </XText>
        ) : null}
        <XText minW='85px' bold textAlign='right'>
          {xFmt.formatCurrency(requiredRemainingAmountTotal)}
        </XText>
      </HStack>
    )
  }

  _renderMultilineInput = ({ key, placeholder, keyboardType }) => {
    const inputValue = this.props.editingPayment.get(key) || ''
    const handleChangeText = (value) => this.props.onPaymentChange({ key, value })
    return this._renderItemWithMarginLeftCard(
      <XInput
        style={{
          ...s.txtNormal,
          color: COLORS.TEXT_PRIMARY,
          backgroundColor: COLORS.FORM_PRIMARY_BG,
          height: 100,
          marginLeft: 0,
          marginTop: 0,
          marginBottom: p.op.isWeb() ? 5 : 0,
          paddingLeft: 10,
          flexBasis: 'auto',
        }}
        keyboardType={keyboardType || 'default'}
        placeholder={placeholder || ''}
        placeholderTextColor={COLORS.TEXT_INACTIVE}
        value={inputValue}
        onChangeText={handleChangeText}
        multiline
        blurOnSubmit={false}
      />
    )
  }

  renderPaymentNoteInput = () => {
    const { paymentNote } = this.state
    // const key = 'note'
    // const inputValue = this.props.editingPayment.get(key) || ''
    // const handleChangeText = (value) => this.props.onPaymentChange({ key, value })
    return (
      <XInput
        w='full'
        minH='100px'
        placeholder='บันทึกสิ่งช่วยจำเกี่ยวกับการชำระเงินครั้งนี้'
        // placeholderTextColor={COLORS.TEXT_INACTIVE}
        // value={inputValue}
        value={paymentNote}
        onChangeText={this.onChangePaymentNote}
        multiline
        // blurOnSubmit={false}
      />
    )
  }

  onChangePaymentNote = (newText: string) => {
    this.setState({ paymentNote: newText })
  }

  // _renderSegmentAmountInput = () => {
  //   const { editingPayment } = this.props
  //   const { requiredRemainingAmountTotal, requiredCodAmountTotal, optionChooseAmountWith = OPTION_ORDER_AMOUNT, isRefund } = this.state
  //   // const label = isRefund ? 'บันทึกยอดเงินคืนตาม' : 'บันทึกยอดชำระตาม'
  //   const label = 'ใช้'
  //   return this._renderInlineContentWithLabel({
  //     label,
  //     content: (
  //       <Stack style={{ width: 160 }}>
  //         <HStack>
  //           <XSegment
  //             selectedIndex={optionChooseAmountWith}
  //             onSegmentChange={this.onChangeSegmentAmountWith}
  //             options={[
  //               `ยอด COD\n${xFmt.formatCurrency(requiredCodAmountTotal)}`,
  //               `ยอดออเดอร์\n${xFmt.formatCurrency(requiredRemainingAmountTotal)}`,
  //             ]}
  //           />
  //         </HStack>
  //       </Stack>
  //     ),
  //     isVerticalCenter: true,
  //   })
  // }

  // _renderInputAmountTotal = () => {
  //   const { editingPayment, ordersToBePaid } = this.props
  //   // const { amountTotal, isRefund } = this.state
  //   const { requiredRemainingAmountTotal, requiredCodAmountTotal, optionChooseAmountWith = OPTION_ORDER_AMOUNT, isRefund } = this.state
  //   const label = isRefund ? 'ยอดเงินคืน' : 'ยอดชำระ'
  //   const placeholder = isRefund ? 'จำนวนเงินคืน' : 'จำนวนชำระ'
  //   const inputValue = editingPayment.get('total_amount') || ''
  //   const inputTotalAmount = editingPayment.get('total_amount') ? parseFloat(editingPayment.get('total_amount')) : 0
  //   // const isCompletedAmountTotal =
  //   //   inputTotalAmount !== 0 && (isRefund ? inputTotalAmount === Math.abs(amountTotal) : inputTotalAmount === amountTotal)
  //   const focusAmount = optionChooseAmountWith === OPTION_COD_AMOUNT ? requiredCodAmountTotal : requiredRemainingAmountTotal
  //   const isCompletedAmountTotal =
  //     inputTotalAmount !== 0 && (isRefund ? inputTotalAmount === Math.abs(focusAmount) : inputTotalAmount === focusAmount)
  //   // const addAmountTotal = `${this.state.amountTotal}`
  //   const disabledInput = optionChooseAmountWith === OPTION_COD_AMOUNT || (Map.isMap(ordersToBePaid) && ordersToBePaid.size > 1)
  //   return this._renderInlineContentWithLabel({
  //     label,
  //     content: (
  //       <View style={{ flexDirection: 'row' }}>
  //         {this.getIsCOD() && requiredCodAmountTotal > 0 ? (
  //           <View
  //             style={[
  //               RNStyle.ROW_CENTER,
  //               {
  //                 width: DEVICE_WIDTH <= 320 ? 130 : 180,
  //                 marginHorizontal: 4,
  //                 ...Platform.select({
  //                   web: { width: 80 },
  //                 }),
  //               },
  //             ]}>
  //             <XSegment
  //               selectedIndex={optionChooseAmountWith}
  //               onSegmentChange={this.onChangeSegmentAmountWith}
  //               options={[`ใช้ยอด COD`, `ใช้ยอดออเดอร์`]}
  //             />
  //           </View>
  //         ) : null}

  //         <View
  //           style={{
  //             width: DEVICE_WIDTH <= 320 ? 110 : 140,
  //             borderBottomWidth: 0,
  //             borderBottomColor: COLORS.TEXT_INACTIVE,
  //             flexDirection: 'row',
  //           }}>
  //           <XInput
  //             isDisabled={disabledInput}
  //             style={{
  //               textAlign: 'right',
  //               fontSize: STYLES.FONT_SIZE_NORMAL,
  //               // paddingRight: 18,
  //               paddingRight: Platform.OS === 'ios' ? 12 : 11,
  //               fontWeight: 'bold',
  //               color: isCompletedAmountTotal ? COLORS.FORM_SUCCESS : COLORS.TEXT_PRIMARY,
  //             }}
  //             keyboardType='numeric'
  //             placeholder={placeholder}
  //             // placeholderTextColor={STYLES.TEXT_INACTIVE}
  //             // value={xFmt.formatCurrency(inputValue)}
  //             value={inputValue}
  //             isMoney
  //             maxLength={14}
  //             onChangeText={this.onChangeTextInputAmountTotal}
  //           />
  //         </View>
  //       </View>
  //     ),
  //     isVerticalCenter: true,
  //   })
  // }

  renderInputAmountTotal = () => {
    // const { editingPayment, ordersToBePaid } = this.props
    // const { amountTotal, isRefund } = this.state
    const {
      requiredRemainingAmountTotal,
      requiredCodAmountTotal,
      optionChooseAmountWith = OPTION_ORDER_AMOUNT,
      isRefund,
      paymentTotalAmount = '0',
      ordersToBePaid = {},
      verifySlip,
    } = this.state
    const label = isRefund ? 'ยอดเงินคืน' : 'ยอดชำระ'
    const placeholder = isRefund ? 'จำนวนเงินคืน' : 'จำนวนชำระ'
    // const inputValue = editingPayment.get('total_amount') || ''
    // const inputTotalAmount = editingPayment.get('total_amount') ? parseFloat(editingPayment.get('total_amount')) : 0
    const paymentTotalAmountFloat = parseFloat(paymentTotalAmount)
    // const isCompletedAmountTotal =
    //   inputTotalAmount !== 0 && (isRefund ? inputTotalAmount === Math.abs(amountTotal) : inputTotalAmount === amountTotal)
    const focusAmount = optionChooseAmountWith === OPTION_COD_AMOUNT ? requiredCodAmountTotal : requiredRemainingAmountTotal
    const isCompletedAmountTotal =
      paymentTotalAmountFloat !== 0 &&
      (isRefund ? paymentTotalAmountFloat === Math.abs(focusAmount) : paymentTotalAmountFloat === focusAmount)
    // const addAmountTotal = `${this.state.amountTotal}`
    // const disabledInput = optionChooseAmountWith === OPTION_COD_AMOUNT || (Map.isMap(ordersToBePaid) && ordersToBePaid.size > 1)
    const orderCount = this.getOrderCount()
    let disabledInput = optionChooseAmountWith === OPTION_COD_AMOUNT || orderCount > 1

    if (verifySlip && verifySlip.create_payment_validation_status === 'SUCCESS') {
      // @ts-ignore
      disabledInput = parseFloat(verifySlip.amount) === parseFloat(focusAmount)
    }

    return (
      <VStack w='full' space='1'>
        {this.getIsCOD() && requiredCodAmountTotal > 0 && !this.isOrdersAvoidCOD() ? (
          <VStack alignItems='flex-end'>
            <HStack w='184px'>
              <XSegment
                selectedIndex={optionChooseAmountWith}
                onSegmentChange={this.onChangeSegmentAmountWith}
                options={[`ใช้ยอด COD`, `ใช้ยอดออเดอร์`]}
              />
            </HStack>
          </VStack>
        ) : null}
        <HStack w='full' space='1' alignItems='center' justifyContent='space-between'>
          <XText variant='inactive'>{label}</XText>
          <XInput
            w='184px'
            // _web={{
            //   w: this.getIsCOD() ? '110px' : '140px',
            // }}
            isDisabled={disabledInput}
            color={isCompletedAmountTotal ? 'success.600' : undefined}
            fontWeight='bold'
            // style={{
            //   textAlign: 'right',
            //   fontSize: STYLES.FONT_SIZE_NORMAL,
            //   // paddingRight: 18,
            //   paddingRight: Platform.OS === 'ios' ? 12 : 11,
            //   fontWeight: 'bold',
            //   color: isCompletedAmountTotal ? COLORS.FORM_SUCCESS : COLORS.TEXT_PRIMARY,
            // }}
            // keyboardType='numeric'
            textAlign='right'
            placeholder={placeholder}
            // placeholderTextColor={STYLES.TEXT_INACTIVE}
            // value={xFmt.formatCurrency(inputValue)}
            // value={inputValue}
            isMoney
            value={paymentTotalAmount.toString()}
            maxLength={14}
            onChangeText={this.onChangeTextInputAmountTotal}
            paddingRight={13}
          />
        </HStack>
      </VStack>
    )
  }

  _renderInlineContentWithLabel = (args: { label: string; content: JSX.Element; isVerticalCenter?: boolean }) => {
    const { label, content, isVerticalCenter } = args
    return (
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          flexWrap: 'nowrap',
          justifyContent: 'space-between',
          alignItems: isVerticalCenter ? 'center' : 'flex-start',
          marginTop: 10,
          ...Platform.select({
            web: { minHeight: 34 },
          }),
        }}>
        <View
          style={{
            flex: p.op.isWeb() ? 1 : 0,
            minWidth: 50,
            paddingLeft: 15,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            ...Platform.select({
              web: { minWidth: 75 },
            }),
          }}>
          <XText style={StyleSheet.flatten(s.txtLabel)}>{label}</XText>
        </View>
        {content}
      </View>
    )
  }

  _renderAccountLineBank = (bankName) => {
    if (bankName) {
      return (
        <HStack>
          <VStack>
            <XText style={s.txtRight}>{bankName}</XText>
          </VStack>
        </HStack>
      )
    }
    return null
  }

  _renderAccountLineName = (accountName) => {
    if (accountName) {
      return (
        <HStack>
          <VStack>
            <XText style={s.txtLabel}>ชื่อบัญชี</XText>
          </VStack>
          <VStack>
            <XText style={s.txtRight}>{accountName}</XText>
          </VStack>
        </HStack>
      )
    }
    return null
  }

  _renderAccountLineNumber = (accountNumber) => {
    if (accountNumber) {
      return (
        <HStack>
          <VStack>
            <XText style={s.txtLabel}>เลขบัญชี</XText>
          </VStack>
          <VStack style={{ alignItems: 'flex-end' }}>
            <TouchableOpacity
              onPress={() => {
                Clipboard.setString(accountNumber)
                p.op.showToast(`คัดลอกเลขบัญชี ${accountNumber} เรียบร้อยแล้ว`, `success`)
              }}
              style={{
                backgroundColor: COLORS.BG_LIGHT_GREY,
                borderColor: COLORS.APP_MAIN,
                borderWidth: 0.7,
                width: 100,
                height: 20,
                borderRadius: 7,
                alignItems: 'center',
              }}>
              <XText style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE, textAlign: 'center' }}>{accountNumber}</XText>
            </TouchableOpacity>
          </VStack>
        </HStack>
      )
    }
    return null
  }

  _renderAccountSelector = (showAccountSelector) => {
    if (showAccountSelector) {
      // kengorderType
      // const { orderType } = this.props.navigation.state.params
      const orderType = util.getNavParam(this.props, 'orderType')
      const txt = this.state.isRefund && orderType === 1 ? 'เลือกบัญชีฉันที่จะจ่ายออก' : 'เลือกบัญชีปลายทาง'
      return (
        <HStack>
          <VStack>
            <XText
              allowFontScaling={false}
              style={{
                textAlign: 'center',
                fontSize: STYLES.FONT_SIZE_LARGER,
                fontWeight: 'bold',
                color: COLORS.TEXT_PRIMARY,
              }}>
              {txt}
            </XText>
          </VStack>
        </HStack>
      )
    }
    return null
  }

  _getPrefixForImageSlip = () => {
    const now = moment()
    return now.format('YYMM/D')
  }

  _onInspectedImage = ({ img_uri }) => {
    this.setState({ previewImageURI: img_uri, previewVisible: true })
  }

  // _renderRelateOrders() {
  //   // Wrapper for UI arrange
  //   // return this._renderContentWithMarginLeft(
  //   return (
  //     <View style={{ flexBasis: 'auto', minHeight: 44, marginLeft: 25 }}>
  //       {this._renderOrders()}
  //       {this._renderAddOrderButton()}
  //     </View>
  //   )
  // }

  renderRelateOrders() {
    // Wrapper for UI arrange
    // return this._renderContentWithMarginLeft(
    return (
      <VStack w='full' space='1'>
        {this._renderOrders()}
        {this._renderAddOrderButton()}
      </VStack>
    )
  }

  _renderOrders = () => {
    // log(this.props.ordersToBePaid.toJS())
    // const { ordersToBePaid } = this.props
    // if (!ordersToBePaid) {
    //   return null
    // }
    // let orderIds = ordersToBePaid.keySeq().toArray()
    // log('_renderOrders orderIds => ', orderIds)
    // if (orderIds.length > 1) {
    //   orderIds = orderIds.sort((a: string, b: string) => parseInt(a) - parseInt(b))
    // }

    const { ordersToBePaid = {} } = this.state
    const orderIds = Object.keys(ordersToBePaid)
    if (!orderIds.length) {
      return null
    }
    // orderIds = orderIds.sort((a: string, b: string) => parseInt(a, 10) - parseInt(b, 10))
    return orderIds.map((orderId, index) =>
      // log('amount: ' + ordersToBePaid.get(orderId))
      this._renderOrder(index, orderId, ordersToBePaid[orderId], orderIds.length)
    )
    // const orders = this.props.editingPayment.get('orders') || []
    // return orders.map((order, index) => this._renderOrder({ index, order }))
  }

  _handleRemoveOrder = async (orderId) => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true

    const { ordersToBePaid = {} } = this.state
    const newOrdersToBePaid = { ...ordersToBePaid }

    try {
      delete newOrdersToBePaid[orderId]
    } catch (err) {
      console.log('_handleRemoveOrder err => ', err)
    }
    await setStatePromise(this, { ordersToBePaid: newOrdersToBePaid })
    await this.updateStateAfterOrdersToBePaidChanged()

    this.inProcess = false
  }

  // _renderOrder = (index, orderId, amount) => {
  // _renderOrder = (index, orderId, orderData: IMap<{ remaining_forecast_amount: number; cod_amount?: number }>) => {
  // _renderOrder = (index, orderId, orderData: { remaining_forecast_amount: number; cod_amount?: number }) => {
  _renderOrder = (index, orderId, orderData: IOrderListItem, orderCount: number) => {
    const { orderType } = this.state
    // log('_renderOder--> orderId:' + orderId + ' amount: ' + amount + ' idx: ' + index)
    const isOdd = index % 2 !== 0
    const name = orderId
    const remainingAmount = _.isString(orderData.t) ? parseFloat(orderData.t) : orderData.t
    // const remainingAmount = orderData.remaining_forecast_amount
    const codAmount = _.isString(orderData.f) ? parseFloat(orderData.f) : orderData.f
    // const codAmount = orderData.cod_amount
    const handleRemoveOrder = () => this._handleRemoveOrder(orderId)
    return (
      <XCard
        key={`OrderCard_${orderId}`}
        w='full'
        _light={{ bgColor: isOdd ? NB.C.L.BG.LIGHT_GREY : NB.C.L.BG.CLEAR }}
        _dark={{ bgColor: isOdd ? NB.C.D.BG.LIGHT_GREY : NB.C.D.BG.CLEAR }}>
        <XCard.Body>
          <HStack w='full' space='1' alignItems='center'>
            <HStack space='1' alignItems='center' flexWrap='wrap'>
              {orderCount < 2 ? null : (
                <XIconButton onPress={handleRemoveOrder} name='close-circle' colorScheme='danger' iconVariant='danger' />
              )}
              <XText>{`#${name}`}</XText>
            </HStack>
            <HStack flex={1} space='1' alignItems='center' justifyContent='flex-end' flexWrap='wrap'>
              {this.getIsCOD() && codAmount && codAmount > 0 && !this.isOrdersAvoidCOD() ? (
                <XText textAlign='right' maxW='85px' style={{ color: COLORS.BRAND_Primary }}>{`[COD ${xFmt.formatCurrency(
                  codAmount
                )}]`}</XText>
              ) : null}
              <XText textAlign='right' minW='85px'>
                {xFmt.formatCurrency(remainingAmount)}
              </XText>
            </HStack>
          </HStack>
        </XCard.Body>
      </XCard>
    )
  }

  //  icon button onPress={() =>     this.setState({ orderSelectorVisible: true })

  _navToPaymentOrderList = () => {
    const { ordersToBePaid = {} } = this.state
    const { navigation } = this.props
    const { orderId, myStoreId, orderStoreId, partnerStoreId, orderType, isShipBeforePay } = this._getParams()
    const { isRefund } = this.state
    // this.navToPaymentOrderList({
    //   store_id: myStoreId,
    //   // orderStoreId: isRefund && orderType !== 1 ? partnerStoreId : orderStoreId,
    //   orderStoreId,
    //   orderType,
    //   partnerStoreId,
    //   isRefund,
    //   isCOD: this.getIsCOD(),
    //   isShipBeforePay,
    // })

    // const ordersDefaultFilterSetting: IOrdersFilter = {}
    // ordersDefaultFilterSetting.isCOD = this.getIsCOD()

    const isCOD = this.isOrdersAvoidCOD() ? undefined : this.getIsCOD()
    const ordersDefaultFilterSetting = this.getIsCOD() || this.isOrdersAvoidCOD() ? { isFilterIsCodDisabled: true } : undefined
    navigation.dispatch(
      NavActions.navToPaymentOrderListView({
        store_id: myStoreId,
        paymentOrder: {
          orderStoreId,
          orderType,
          partnerStoreId,
          isRefund,
          // isCOD: this.getIsCOD(),
          isCOD,
          // isShipBeforePay,
        },
        ordersDefaultFilterSetting,
        // initialOrdersToBePaid: Object.keys(ordersToBePaid).map((odIdString) => parseInt(odIdString, 10)),
        initialOrdersToBePaid: ordersToBePaid,
        // isCheckable: true,
        title: 'เลือกออเดอร์ชำระ',
        // onPressOrderListItem?: (order: IOrderListItem) => void | Promise<void>
        onPressSubmitCheckedOrders: this._onPressSubmitCheckedOrders,
        submitCheckOrdersButtonText: 'ยืนยันเลือกออเดอร์ชำระ',
      })
    )
  }

  _onPressSubmitCheckedOrders = async (checkedOrders: IOrderListItem[]) => {
    // console.log('onPressSubmitCheckedOrders checkedOrders => ', checkedOrders)
    const newOrdersToBePaid = {}
    checkedOrders.forEach((od) => {
      // console.log('onPressSubmitCheckedOrders id ', od.id)
      // console.log('onPressSubmitCheckedOrders transaction_cost ', od.t)
      // console.log('onPressSubmitCheckedOrders cod_amount ', od.f)
      // newOrdersToBePaid[od.id] = { remaining_forecast_amount: od.t, cod_amount: od.f }
      newOrdersToBePaid[od.id] = od
    })
    await setStatePromise(this, { ordersToBePaid: newOrdersToBePaid })
    await delay(100)
    // this._updateStateAfterPropsChanged()
    await this.updateStateAfterOrdersToBePaidChanged()
  }

  _renderAddOrderButton = () => {
    const { verifySlip, ordersToBePaid = {} } = this.state
    const orderIds = Object.keys(ordersToBePaid)
    const orderCount = orderIds.length
    if (orderCount === 1 && verifySlip && verifySlip.create_payment_validation_status === 'SUCCESS') {
      return null
    }

    const { orderType } = this._getParams()

    // ให้ refund orderType 1 แค่ order เดียว
    const disabled = orderType === 1 && this.state.isRefund
    let addOrderText = 'เพิ่มออเดอร์​อื่น'
    const partnerName = this.getPartnerName()
    switch (orderType) {
      case 1: {
        addOrderText = 'เพิ่มออเดอร์​อื่น ที่ค้างชำระจากลูกค้า'
        break
      }
      case 2: {
        addOrderText = `เพิ่มออเดอร์อื่น ที่ค้างชำระจากผู้ซื้อ ${partnerName}`
        break
      }
      case 3: {
        addOrderText = `เพิ่มออเดอร์อื่น ที่ค้างชำระอยู่กับผู้ขาย ${partnerName}`
        break
      }
      default:
      // no-op
    }

    return disabled ? null : (
      <XCard w='full' onPress={this._navToPaymentOrderList}>
        <XCard.Body>
          <HStack w='full' px='2' py='1' space='2' alignItems='center'>
            <HStack space='1' alignItems='center' flexWrap='wrap'>
              <XIcon success name='add-circle' />
            </HStack>
            <XText numberOfLines={2} minW='85px'>
              {addOrderText}
            </XText>
          </HStack>
        </XCard.Body>
      </XCard>
    )

    // return disabled ? null : (
    //   <Card
    //     style={[
    //       s.flexNoWrap,
    //       {
    //         marginTop: 0,
    //         marginBottom: 0,
    //         minHeight: 44,
    //         justifyContent: 'center',
    //         backgroundColor: 'white',
    //       },
    //     ]}>
    //     <Box cardBody={true} button={true} onPress={this._navToPaymentOrderList} style={{ backgroundColor: 'transparent' }}>
    //       <Stack>
    //         <Row>
    //           <VStack style={{ flex: p.op.isWeb() ? 1 : 0, width: 50, alignItems: 'center', justifyContent: 'center' }}>
    //             <Icon
    //               style={{ fontSize: STYLES.FONT_ICON_SMALLER, width: STYLES.FONT_ICON_SMALLER, color: COLORS.FORM_SUCCESS }}
    //               name='add-circle'
    //             />
    //           </VStack>
    //           <VStack size={30} style={{ justifyContent: 'center' }}>
    //             <XText style={s.txtLabel}>{addOrderText}</XText>
    //           </VStack>
    //           <VStack size={35} style={{ justifyContent: 'center', paddingRight: 4 }} />
    //           <VStack size={35} style={{ alignItems: 'flex-end', justifyContent: 'center', paddingRight: 8 }}>
    //             <Icon
    //               name='arrow-forward'
    //               style={{ fontSize: STYLES.FONT_ICON_NORMAL, width: STYLES.FONT_ICON_NORMAL, color: COLORS.TEXT_INACTIVE }}
    //             />
    //           </VStack>
    //         </Row>
    //       </Stack>
    //     </Box>
    //   </Card>
    // )
  }

  _onPressPaymentButtonGBPayQRCode = async () => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    // const { navigation } = this.props

    const url = await this.getGBPayQRCodeUrl()

    if (!url) {
      this.inProcess = false
      return
    }

    log('Open file from url => ', url)
    // const { editingPayment } = this.props
    const { ordersToBePaid, paymentTotalAmount } = this.state
    // const inputTotalAmount = xFmt.currencyFormatter.format(editingPayment.get('total_amount') || 0)
    const inputTotalAmount = xFmt.currencyFormatter.format(paymentTotalAmount || 0)
    // const ordersToBePaid = editingPayment.get('ordersToBePaid')
    // const orderCount = Map.isMap(ordersToBePaid) ? ordersToBePaid.keySeq().toArray().length : 0
    const orderCount = this.getOrderCount()

    const nowDate = moment().format('DD-MM-YYYY_hh-mm')
    const fileName = `GBPay_QR_${nowDate}.png`
    p.op.showConfirmation(
      'ยืนยันและดาวน์โหลด QR Code',
      `${`ยอดชำระ ฿${inputTotalAmount} สำหรับ ${orderCount} ออเดอร์ ` + '\n\nเมื่อคุณดาวน์โหลด QR Code เสร็จแล้ว'}${
        this.txtBeforeRequestQRCodeInfo
      }`,
      () => this.openGBPayQRCodeFileFromUrl(url, fileName, 'png'),
      null,
      'ดาวน์โหลด QR Code',
      'ยกเลิก'
    )

    setTimeout(() => {
      this.inProcess = false
    }, 500)
  }

  renderFooter = () => {
    const { submitting, paymentMethod, beamUrlPayment } = this.state
    // const showBtnDownload = paymentMethod === 1 || paymentMethod === 2 || paymentMethod === 3
    const showBtnDownload = _.includes([PAYMENT_METHOD.GBPAY, PAYMENT_METHOD.SCB_QR_CODE, PAYMENT_METHOD.BEAM], paymentMethod)
    let footerShowQRBtnDownload = paymentMethod === PAYMENT_METHOD.GBPAY ? COLORS.GB_PAY_MAIN : COLORS.SCB_MAIN
    const textBtnStyle = textOnBtnMode(submitting ? CONS.BTN_MODE.INACTIVE : CONS.BTN_MODE.ACTIVE_PRIMARY)
    const btnTextVariant = submitting ? 'inactive' : 'activeDark'
    if (showBtnDownload) {
      // interrupted normal mode
      let footerLable = this.txtBtnDownloadQR
      let onPressBtn = this._onPressPaymentButtonGBPayQRCode
      if (paymentMethod === PAYMENT_METHOD.BEAM) {
        footerShowQRBtnDownload = COLORS.BEAM_MAIN
        footerLable = 'สร้างลิงก์สำหรับชำระผ่าน Beam'
        if (_.isString(beamUrlPayment)) {
          footerLable = 'คัดลอกลิงก์สำหรับชำระผ่าน Beam'
          // footerShowQRBtnDownload = COLORS.WHITE
          // textBtnStyle = { color: COLORS.BEAM_MAIN }
        }
        // btnStyle = {  }
        onPressBtn = async () => {
          this.getBeamLink()
          // p.op.showToast('คัดลอกลิงก์ Beam สำหรับชำระเรียบร้อยแล้ว', 'success')
          // await setStatePromise(this, {
          //   // submitting: true,
          //   beamUrlPayment: 'ok',
          // })
        }
      }

      if (paymentMethod === PAYMENT_METHOD.SCB_QR_CODE) {
        footerShowQRBtnDownload = COLORS.SCB_MAIN
        onPressBtn = async () => {
          p.op.alert('TO BE IMPLEMENT')
        }
      }
      return (
        <HStack w='full' p='2' _light={{ bg: NB.C.L.BG.LIGHT_GREY }} _dark={{ bg: NB.C.D.BG.LIGHT_GREY }}>
          <XButton
            w='full'
            isDisabled={submitting}
            disabled={submitting}
            onPress={onPressBtn}
            // onPress={ () => this._onPressPaymentButtonGBPayQRCode() }
            // onPress={ () => {
            //   let url = `${settings.app.API_ROOT}/payment/gbpayqr`
            //   log('Open file from url => ', url)
            //   const fileName = `TestQR.png`
            //   utilN.openFileFromUrl(url, fileName, 'png')
            // }}
            bgColor={footerShowQRBtnDownload}
            borderColor={footerShowQRBtnDownload}
            // style={[
            //   btnOnMode(CONS.BTN_MODE.ACTIVE_PRIMARY),
            //   {
            //     backgroundColor: submitting ? COLORS.TEXT_INACTIVE : footerShowQRBtnDownload,
            //     borderColor: submitting ? COLORS.TEXT_INACTIVE : footerShowQRBtnDownload,
            //   },
            //   {
            //     borderWidth: _.isString(beamUrlPayment) ? 1 : 0,
            //     borderLeftWidth: 1,
            //     borderTopWidth: 1,
            //     borderRightWidth: 1,
            //     borderBottomWidth: 1,
            //   },
            // ]}
          >
            <XText
              //  style={textBtnStyle}
              variant={btnTextVariant}>
              {footerLable}
            </XText>
          </XButton>
        </HStack>
      )
    }

    const btnText = this.state.isRefund ? 'ยืนยันการคืนเงิน' : 'ยืนยันการชำระ'
    return (
      <HStack w='full' p='2' _light={{ bg: NB.C.L.BG.LIGHT_GREY }} _dark={{ bg: NB.C.D.BG.LIGHT_GREY }}>
        <XButton
          w='full'
          isDisabled={submitting}
          disabled={submitting}
          // style={btnOnMode(submitting ? CONS.BTN_MODE.INACTIVE : CONS.BTN_MODE.ACTIVE_PRIMARY)}
          onPress={this._onPressSubmitPaymentButton}>
          {/* <XText style={textOnBtnMode(submitting ? CONS.BTN_MODE.INACTIVE : CONS.BTN_MODE.ACTIVE_PRIMARY)}>{btnText}</XText> */}
          {btnText}
        </XButton>
      </HStack>
    )
  }

  _doUploadPaymentSlip = async (): Promise<boolean> => {
    let isUploadSuccess = false
    const imgMgrRef = this.imgManagerRef.current
    if (imgMgrRef && imgMgrRef.submitUploadImages) {
      await setStatePromise(this, { uploading: true })
      const response = await imgMgrRef.submitUploadImages()
      // log('_onPressSubmitPaymentButton response => ', response)
      await setStatePromise(this, { uploading: false })
      if (response.allUploaded) {
        await setStatePromise(this, {
          paymentSlip: {
            img_uris: response.p,
            thumbnail_uris: response.t,
          },
        })
        isUploadSuccess = true
      } else if (response.isEmpty) {
        await setStatePromise(this, { paymentSlip: null })
        isUploadSuccess = true
      } else {
        p.op.showToast('เกิดข้อผิดพลาดในการอัพโหลดรูปภาพ กรุณาลองใหม่อีกครั้ง', 'danger')
        isUploadSuccess = false
      }
    }
    return isUploadSuccess
  }

  _onPressSubmitPaymentButton = async () => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    await setStatePromise(this, { submitting: true })

    if (!p.op.isWeb()) {
      Keyboard.dismiss()
    }
    this.showPaymentSpinner()

    // // await this._checkingUploadImage()
    // const imgMgrRef = this.imgManagerRef.current
    // if (imgMgrRef && imgMgrRef.submitUploadImages) {
    //   await setStatePromise(this, { uploading: true })
    //   const response = await imgMgrRef.submitUploadImages()
    //   // log('_onPressSubmitPaymentButton response => ', response)
    //   await setStatePromise(this, { uploading: false })
    //   const { onPaymentChange } = this.props
    //   if (response.allUploaded) {
    //     onPaymentChange({ key: 'img_uris', value: response.p })
    //     onPaymentChange({ key: 'thumbnail_uris', value: response.t })
    //     await delay(500) // ทดไว้สำหรับยิง action เปลี่ยนรูปภาพ
    //   } else if (response.isEmpty) {
    //     onPaymentChange({ key: 'img_uris', value: [] })
    //     onPaymentChange({ key: 'thumbnail_uris', value: [] })
    //     await delay(500) // ทดไว้สำหรับยิง action เปลี่ยนรูปภาพ
    //   } else {
    //     p.op.showToast('เกิดข้อผิดพลาดในการอัพโหลดรูปภาพ กรุณาลองใหม่อีกครั้ง', 'danger')
    //     this.inProcess = false
    //     return
    //   }
    // }

    const isUploadSuccess = await this._doUploadPaymentSlip()

    if (!isUploadSuccess) {
      this.inProcess = false
      await setStatePromise(this, { submitting: false })
      return
    }

    // await this.submitPayment()
    await this.doValidationAndSubmitPayment()

    this.hidePaymentSpinner()

    this.inProcess = false
    await setStatePromise(this, { submitting: false })
  }

  _onPressDateTimePicker = () => {
    const datePickerRef = this.datePickerRef.current

    if (p.op.isAndroid()) {
      this.setState({ isShowDateTimePickerAndroid: true, dateTimePickerAndroidShowMode: 'date' })
    } else if (datePickerRef && _.isFunction(datePickerRef.onPressDate)) {
      datePickerRef.onPressDate()
    }
  }

  // จากของเดิมใช้ตัวนี้เป็นหลัง เปลี่ยนไปใช้ getter/setter ของใน state แทน
  // _onChangeDateTime = (newDate) => {
  //   this.props.onPaymentChange({ key: 'post_date', value: moment(newDate).format() })
  // }

  _onChangeDateTime = (newDate: string) => {
    // this.props.onPaymentChange({ key: 'post_date', value: moment(newDate).format() })
    this.onChangePaymentPostDate(new Date(newDate))
  }

  _goBack = () => {
    if (this.isUnmounting) {
      return
    }
    util.navGoBack(this.props)
  }

  renderHeader = () => (
    <XCustomHeader
      // headerStyle={{ marginTop: 0 }}
      headerLeftProps={{
        backIcon: true,
        onPressItem: this._goBack,
      }}
      title={this.getHeaderTitle()}
    />
  )

  renderVerifySlipLoading = () => {
    const { isSlipVerifying = false } = this.state

    if (isSlipVerifying) {
      return (
        <VStack position='absolute' top={0} left={0} right={0} bottom={0} justifyContent='center' alignItems='center' bg='rgba(0,0,0,0.5)'>
          <XSpinner />
        </VStack>
      )
    }

    return null
  }

  renderContent = () => <XContent p='1'>{this.renderPaymentForm()}</XContent>

  renderMain = () => {
    // @ts-ignore
    const { previewImageURI, previewVisible, paymentMethod, orderType = 0, selectedDateTime = new Date() } = this.state
    // log('___this.datePickerRef_: ', this.datePickerRef)
    return (
      <XContainer>
        {this.renderHeader()}
        {this.renderContent()}
        {this.renderFooter()}
        {this.renderVerifySlipLoading()}
      </XContainer>
    )
  }
  // Deprecated
  // _onCloseImagePreview = () => {
  //   this.setState({ previewImageURI: '', previewVisible: false })
  // }

  // render() {
  //   logRender(this)
  //   const { previewImageURI, previewVisible, paymentMethod, orderType = 0 } = this.state
  //   // log('___this.datePickerRef_: ', this.datePickerRef)
  //   return (
  //     <XContainer>
  //       <XCustomHeader
  //         // headerStyle={{ marginTop: 0 }}
  //         headerLeftProps={{
  //           backIcon: true,
  //           onPressItem: this._goBack,
  //         }}
  //         title={this.getHeaderTitle()}
  //       />
  //       <XContent>{this.renderPaymentGatewayOptionForm()}</XContent>
  //       {this._renderFooter()}
  //       <StickyKeyboardTab
  //         arrayOfRefs={this.inputRefs}
  //         // @ts-ignore
  //         currentRef={this.state.currentRef}
  //         scrollRef={this.scrollViewRef}
  //       />
  //       <ImagePreview visible={previewVisible} imgURI={previewImageURI} onClose={this._onCloseImagePreview} />
  //     </XContainer>
  //   )
  // }
}

const s = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'space-between',
  },
  content: {
    flex: 1,
    paddingLeft: 5,
    paddingRight: 5,
  },
  gridColumnContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  halfColumnContainer: {
    width: '50%',
    padding: 5,
  },
  pCardImg: { width: '100%', height: 100 },
  productListItem: {
    margin: 0,
    paddingLeft: 5,
    paddingRight: 5,
    marginLeft: 0,
    marginRight: 0,
  },
  formInCard: {
    alignSelf: 'stretch',
    margin: 0,
    padding: 0,
    // flex: 1,
    flexBasis: 'auto',
    ...Platform.select({
      web: {
        // paddingLeft: 12,
        // paddingRight: 18,
        paddingTop: 8,
        paddingBottom: 8,
        backgroundColor: 'transparent',
      },
    }),
  },
  borderPrimary: {
    borderColor: COLORS.FORM_PRIMARY,
  },
  borderError: {
    borderColor: COLORS.FORM_ERROR,
  },
  borderSuccess: {
    borderColor: COLORS.FORM_SUCCESS,
  },
  separator: {
    height: 5,
  },
  cardStyle: {
    elevation: 3,
    width: '100%',
    flex: 1,
  },
  cardBody: {
    width: '100%',
    height: 220,
    flex: 1,
  },
  cardImg: {
    alignSelf: 'center',
    width: '100%',
    height: 200,
  },
  alignCenter: {
    alignSelf: 'center',
  },
  alignRight: {
    alignSelf: 'flex-end',
  },
  txtHeader: {
    fontSize: STYLES.FONT_SIZE_ORDERS_HEADER_ROW2,
    color: COLORS.TEXT_ACTIVE,
    fontWeight: 'bold',
  },
  txtNormal: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_ACTIVE,
  },
  txtHL: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_PRIMARY,
  },
  txtMultiple: {
    width: '100%',
    height: 100,
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_ACTIVE,
  },
  txtMultipleHL: {
    width: '100%',
    height: 100,
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.FORM_PRIMARY,
  },
  txtLabel: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_INACTIVE,
    paddingBottom: 2,
    paddingTop: 2,
    // color: 'rgba(15,15,15,0.6)',
  },
  txtHLRight: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_PRIMARY,
    textAlign: 'right',
  },
  labelRight: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_INACTIVE,
    textAlign: 'right',
  },
  txtRight: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_ACTIVE,
    textAlign: 'right',
  },
  txtCenter: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_ACTIVE,
    textAlign: 'center',
  },
  iconHL: {
    fontSize: STYLES.FONT_SIZE_NORMAL + 4,
    color: COLORS.TEXT_PRIMARY,
    textAlign: 'right',
  },
  rowBox: {
    borderWidth: 1,
    borderColor: 'lightgrey',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 10,
    paddingBottom: 10,
  },
  colBox50: {
    width: '50%',
    borderWidth: 1,
    borderColor: 'lightgrey',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingTop: 10,
    paddingLeft: 5,
    paddingRight: 5,
    paddingBottom: 10,
  },
  camContainer: {
    width: 250,
    height: 200,
    paddingTop: 5,
  },
  preview: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'red',
  },
  capture: {
    flex: 0,
    backgroundColor: '#fff',
    borderRadius: 5,
    color: '#000',
    padding: 10,
    margin: 40,
  },
  // barcodeCutLine1: {
  //   position: 'absolute',
  //   top: 96,
  //   width: '100%',
  //   height: 2,
  //   backgroundColor: COLORS.FORM_ERROR_OPACITY,
  // },
  // barcodeCutLine2: {
  //   position: 'absolute',
  //   top: 104,
  //   width: '100%',
  //   height: 2,
  //   backgroundColor: COLORS.FORM_ERROR_OPACITY,
  // },
  buttonIcon: {
    width: 38,
    height: 38,
    // flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 5,
    borderRadius: 5,
  },
  iconInButton: {
    color: COLORS.TEXT_ACTIVE_DARK,
    fontSize: STYLES.FONT_ICON_NORMAL,
    fontWeight: 'bold',
    textAlign: 'center',
    paddingLeft: 2,
    paddingRight: 0,
  },
  normalPay: {
    color: COLORS.FORM_SUCCESS,
    fontSize: STYLES.FONT_SIZE_NORMAL,
    fontWeight: 'bold',
  },
  refundPay: {
    color: 'red',
    fontSize: STYLES.FONT_SIZE_NORMAL,
    fontWeight: 'bold',
  },
  flexNoWrap: {
    flexWrap: 'nowrap',
    flexBasis: 'auto',
  },
})

export default BaseUIPaymentView
