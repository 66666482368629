import _ from 'lodash'
import React from 'react'
import { VictoryAxis, VictoryBar, VictoryChart, VictoryTheme } from 'victory-native'

import { IXBarChartProps } from 'x/types'
import p from 'x/config/platform-specific'

import { LayoutChangeEvent } from 'react-native'
import Box from './Box'
import VStack from './VStack'
import XText from './XText'

type IState = {
  containerWidth: number
  chartWidth: number
}
export default class XBarChart extends React.Component<IXBarChartProps, IState> {
  static displayName = 'XBarChart'

  static defaultProps = {
    tickCountX: 7,
    tickCountY: 6,
    lineColor: 'blue',
  }

  constructor(props) {
    super(props)

    this.state = {
      containerWidth: 300,
      chartWidth: 292,
    }
  }

  getFontSize = () => {
    const { fontSize } = this.props

    if (fontSize && fontSize > 0) {
      return fontSize
    }

    if (!p.op.isWeb()) {
      return 14
    }

    const { containerWidth } = this.state

    if (containerWidth <= 300) {
      return 54
    }

    if (containerWidth <= 400) {
      return 48
    }

    if (containerWidth <= 500) {
      return 44
    }

    return 38
  }

  _getLimitTickFormatFunction = (dataCount = 0, parseFunction = null) => {
    // const limitLength = 9
    const { tickCountX } = this.props
    if (dataCount >= tickCountX) {
      // const splitedPoint = Math.floor(dataCount / tickCountX)
      const splitedPoint = tickCountX
      return _.isFunction(parseFunction)
        ? (data, index) => (index % splitedPoint === 0 ? parseFunction(data) : null)
        : (data, index) => (index % splitedPoint === 0 ? data : null)
    }
    return _.isFunction(parseFunction) ? (data) => parseFunction(data) : (data) => data
  }

  getDataCount = () => {
    const { data } = this.props
    return data.length || 0
  }

  getTickValuesY = () => {
    const { data, tickCountY } = this.props
    // const sum = data.reduce((prevY: number, currD) => prevY + parseFloat(currD.y), 0)
    const tickValues = []
    const max = Math.max(...data.map((d) => d.y)) || 1
    let stepValue = max / (tickCountY - 1)

    if (max >= 10) {
      stepValue = Math.round(stepValue)
    } else {
      return [0, 2, 4, 6, 8, 10]
    }

    stepValue = Math.round(stepValue * 100) / 100
    // let number = 5
    // let formattedNumber = number % 1 === 0 ? number.toString() : number.toFixed(2)
    // console.log(formattedNumber) // Output: "5"
    // number = 12.3456
    // formattedNumber = number % 1 === 0 ? number.toString() : number.toFixed(2)
    // console.log(formattedNumber) // Output: "12.35"
    // number = 123.4
    // formattedNumber = number % 1 === 0 ? number.toString() : number.toFixed(2)
    // console.log(formattedNumber) // Output: "123.40"

    for (let i = 0; i < tickCountY - 1; i++) {
      tickValues.push(Math.round(i * stepValue * 100) / 100)
    }
    tickValues.push(max >= 10 ? Math.round(max) : max)
    // console.log('getTickValuesY max => ', max)
    // console.log('getTickValuesY tickValues => ', tickValues)
    return tickValues
  }

  renderBarChart = () => {
    const { chartWidth } = this.state
    const { data, tickFormatX, tickFormatY, tickCountY, tickCountX, lineColor } = this.props
    const dataCount = this.getDataCount()
    // const tickLabelXFunc = this._getLimitTickFormatFunction(dataCount, parseFunctionToTickLabelX || null)
    const tickLabelXFunc = this._getLimitTickFormatFunction(dataCount, tickFormatX)
    // const tickLabelYFunc = this._getLimitTickFormatFunction(dataCount, tickFormatY)

    return (
      <VictoryChart
        // width={this.state.victoryChartWidth || 320}
        height={p.op.isWeb() ? 950 : 350}
        width={p.op.isWeb() ? undefined : chartWidth}
        style={{
          background: { fill: '#f8f8f8' },
        }}
        theme={VictoryTheme.material}
        padding={{
          top: p.op.isWeb() ? 48 : 28,
          left: p.op.isWeb() ? 198 : 82,
          bottom: p.op.isWeb() ? 194 : 88,
          right: p.op.isWeb() ? 146 : 50,
        }}
        // animate={{
        //   duration: 2000,
        //   easing: 'bounce',
        // }}
      >
        <VictoryAxis
          // label={label_y}
          style={{
            axis: { stroke: 28 },
            ticks: { stroke: 28 },
            tickLabels: {
              fontSize: this.getFontSize(),
              fill: 'black',
              // fontWeight: 'normal',
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              padding: 12,
            },
            grid: { stroke: '#B3E5FC', strokeWidth: 0.25 },
          }}
          dependentAxis
          // @ts-ignore
          // tickFormat={parseFunctionToTickLabelY || null}
          // tickFormat={tickLabelYFunc}
          // tickFormat={tickLabelYFunc}
          tickFormat={tickFormatY}
          tickCount={this.getDataCount() > tickCountY ? tickCountY : this.getDataCount()}
          tickValues={this.getTickValuesY()}
        />
        <VictoryAxis
          // label={label_x}

          style={{
            axis: {
              stroke: 28,
            },
            grid: {
              padding: 20,
            },
            // axisLabel: { fontSize: 20, padding: 30 },
            tickLabels: {
              fontSize: this.getFontSize(),
              fill: 'black',
              // fontWeight: 'normal',
              angle: 45,
              textAnchor: 'start',
              verticalAnchor: 'middle',
              // fontFamily: 'Serif',
              // fontFamily: 'Roboto',
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              padding: 2,
              // paddingLeft: 55,
            },
          }}
          // tickFormat={tickLabelXFunc}
          tickFormat={tickFormatX}
          tickCount={this.getDataCount() > tickCountX ? tickCountX : this.getDataCount()}
        />

        <VictoryBar
          name='Bar'
          // labels={(d) => d.x.toFixed(0)}
          style={{
            data: {
              // stroke: 'black',
              // stroke: lineColor,
              // width: 15,
              width: this.getDataCount() < 7 ? 25 : undefined,
              fill: lineColor,
              fillOpacity: 0.8,
              strokeWidth: 1,
              strokeOpacity: 0.8,
              strokeLinecap: 'round',
              opacity: 1,
            },
            labels: {
              fontSize: this.getFontSize(),
            },
          }}
          // events={[
          //   {
          //     target: 'data',
          //     eventHandlers: {
          //       onClick: () => [
          //         {
          //           target: 'data',
          //           mutation: (props) => {
          //             console.log('mutation props => ', props)
          //             const fill = props.style && props.style.fill
          //             return fill === 'black' ? null : { style: { fill: 'black' } }
          //           },
          //         },
          //       ],
          //     },
          //   },
          // ]}
          data={data}
          animate={{
            duration: 1500,
            onLoad: { duration: 1000 },
            onExit: {
              duration: 500,
              before: () => ({
                _y1: 0,
                fill: 'orange',
              }),
            },
          }}
        />

        {/* <VictoryBar style={{ data: { fill: '#c43a31' } }} data={data} /> */}
      </VictoryChart>
    )
  }

  renderLabelX = () => {
    const { labelX } = this.props
    if (!labelX) {
      return null
    }
    return (
      <Box position='absolute' bottom='0' left='0' right='0' alignItems='center'>
        <XText>{labelX}</XText>
      </Box>
    )
  }

  renderLabelY = () => {
    const { labelY } = this.props
    if (!labelY) {
      return null
    }
    return (
      <Box position='absolute' left='0' top='40px'>
        <XText pl='40px'>{labelY}</XText>
      </Box>
    )
  }

  renderChartTitle = () => {
    const { title } = this.props
    if (!title) {
      return null
    }
    return (
      <Box minH='9' pt='2.5' position='absolute' top='0' left='0' right='0' justifyContent='center' alignItems='center'>
        <XText bold>{title}</XText>
      </Box>
    )
  }

  onContainerLayout = (evt: LayoutChangeEvent) => {
    try {
      const { width } = evt.nativeEvent.layout
      // 8 มาจาก p='1' => pl=4px pr=4px
      this.setState({ containerWidth: width, chartWidth: width - 8 })
    } catch (err) {
      console.log('onContainerLayout err =>', err)
    }
  }

  render() {
    return (
      <VStack w='full' p='1' pt='50px' pb='8px' onLayout={this.onContainerLayout}>
        {this.renderChartTitle()}
        {this.renderBarChart()}
        {this.renderLabelY()}
        {this.renderLabelX()}
      </VStack>
    )
  }
}
