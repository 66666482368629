import React from 'react'
import { Linking, Text, View, TouchableOpacity } from 'react-native'
import _ from 'lodash'
import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'
import { COLORS, STYLES } from 'x/config/styles'
import XCustomHeader from 'xui/components/XCustomHeader'
import { Card, VStack } from 'native-base'
import HStack from 'xui/components/HStack'
import BaseStoreSettingPrintingBarcode, {
  IOptionPrintingBarcode,
  IPrintingBarcodeType,
} from 'x/modules/xshipping/BaseStoreSettingPrintingBarcode'
// import HelpButton from 'xui/components/HelpButton'
import Segment from 'xui/components/Segment'
import XContent from 'xui/components/XContent'
import XContainer from 'xui/components/XContainer'
import FAIcon from 'react-native-vector-icons/FontAwesome'
import XIcon from 'xui/components/XIcon'
import XCard from 'xui/components/XCard'
import XText from 'xui/components/XText'
import XButton from 'xui/components/XButton'

const checkBoxIcon = (
  <FAIcon
    style={{
      flex: 1,
      fontSize: STYLES.FONT_ICON_NORMAL,
      color: COLORS.APP_MAIN,
    }}
    name='check-square-o'
  />
)
const uncheckBoxIcon = (
  <FAIcon
    style={{
      flex: 1,
      fontSize: STYLES.FONT_ICON_NORMAL,
      color: COLORS.APP_MAIN,
    }}
    name='square-o'
  />
)

export default abstract class BaseUIStoreSettingPrintingBarcode extends BaseStoreSettingPrintingBarcode {
  static displayName = 'BaseUIStoreSettingPrintingBarcode'
  // abstract goBack: () => void

  _constomHeader = () => (
    <XCustomHeader
      headerRight={this._renderHeaderRight()}
      headerLeft={this._renderHeaderLeft()}
      title={p.op.t('StoreSetting.productLabel')}
    />
  )

  _renderHeaderLeft = () => {
    const { isPageOnChange } = this.state
    if (isPageOnChange) {
      return (
        <TouchableOpacity
          style={{ justifyContent: 'center', alignItems: 'center', width: 50, height: 50 }}
          onPress={() => this.saveStoreSetting()}>
          <XText variant='primary'>บันทึก</XText>
        </TouchableOpacity>
      )
    }
    return (
      <TouchableOpacity
        style={{ justifyContent: 'center', alignItems: 'center', width: 50, height: 50 }}
        onPress={() => util.navGoBack(this.props)}>
        <XIcon name='arrowleft' family='AntDesign' style={{ flex: 0, minWidth: 25, width: 20, color: COLORS.APP_MAIN }} />
      </TouchableOpacity>
    )
  }

  _renderHeaderRight = () => {
    const { isPageOnChange } = this.state
    if (isPageOnChange) {
      return (
        <TouchableOpacity
          style={{ justifyContent: 'center', alignItems: 'center', width: 50, height: 50 }}
          onPress={() => this._cancelEditing()}>
          <XText variant='primary'>ยกเลิก</XText>
        </TouchableOpacity>
      )
    }
    return null
  }

  _cancelEditing = () => {
    p.op.showConfirmation(
      '',
      'คุณต้องการยกเลิกการแก้ไขใช่หรือไม่',
      () => util.navGoBack(this.props),
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {},
      'ยกเลิกการแก้ไข',
      'แก้ไขต่อ'
    )
  }

  _renderOptions = (printing: IPrintingBarcodeType) => {
    const { selectedPrintingTypeId } = this.state
    const checked = selectedPrintingTypeId === printing.id
    let HELP_BTN = printing.help
    if (_.isNil(HELP_BTN)) {
      HELP_BTN = null
    }
    return (
      <HStack py='2'>
        <TouchableOpacity onPress={() => this.onChangeSelectedValue(printing)} style={{ flex: 1, flexDirection: 'row' }}>
          <XIcon name={checked ? 'radio-button-on' : 'radio-button-off'} family='Ionicons' />
          <XText variant='active' pl='1'>
            {printing.txt}
          </XText>
        </TouchableOpacity>
        <XButton onPress={() => Linking.openURL(printing.url)} size='sm' variant='outline'>
          ดูตัวอย่าง
        </XButton>
      </HStack>
    )
  }

  _renderListOptions = () => (
    <XCard p='4' borderRadius='xl' w='full'>
      <XText variant='active' bold>
        ขนาดกระดาษ Sticker
      </XText>
      {this.PRINTING_BARCODE_TYPE.map((printing: IPrintingBarcodeType) => this._renderOptions(printing))}
    </XCard>
  )

  _renderBarcodeSegment = () => {
    const { chooseOptionBarcodeIndex, printingBarcodestoreSetting } = this.state
    if (_.isNil(printingBarcodestoreSetting)) {
      return null
    }
    // if (printingBarcodestoreSetting.p.c === 0 && printingBarcodestoreSetting.p.v === 0 && printingBarcodestoreSetting.p.d === 0) {
    //   return null
    // }
    return (
      <XCard p='4' borderRadius='xl' w='full'>
        <XText variant='active' bold>
          รูปแบบบาร์โค้ด
        </XText>
        <Segment
          options={['QR Code', 'Barcode']}
          selectedIndex={chooseOptionBarcodeIndex}
          onSegmentChange={(inx: number) => this._chooseOptionBarcode(inx)}
        />
      </XCard>
    )
  }

  _renderOptionListOptions = () => {
    const { OPTION_PRINTING_BARCODE, printingBarcodestoreSetting } = this.state
    if (OPTION_PRINTING_BARCODE.length < 1) {
      return null
    }
    const selectedBarcode = printingBarcodestoreSetting.p.t === 'b'
    const selectedA4 = printingBarcodestoreSetting.p.i === 4
    if (selectedBarcode && selectedA4) {
      return null
    }
    return (
      <Card style={{ marginLeft: 4, marginRight: 4, marginTop: 4, paddingLeft: 8, paddingBottom: 4, paddingRight: 8, paddingTop: 4 }}>
        <View style={{ height: 5 }} />
        <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE, fontWeight: 'bold', paddingLeft: 4 }}>
          แสดงข้อมูลเพิ่มเติม
        </Text>
        {/* <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE, paddingTop: 4, paddingBottom: 10 }}>
          {p.op.t('detailInShippingLabelPDF')}
        </Text> */}
        <View style={{ height: 5 }} />
        {OPTION_PRINTING_BARCODE.map((option: IOptionPrintingBarcode) => {
          let typeIcon = null
          if (option.value()) {
            typeIcon = checkBoxIcon
          } else {
            typeIcon = uncheckBoxIcon
          }
          return (
            <TouchableOpacity
              style={{ flexDirection: 'row', flex: 1, padding: 2, justifyContent: 'center' }}
              onPress={() => option.onChange(!option.value())}>
              <View>
                <View style={{ width: 30, height: 35 }}>{typeIcon}</View>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE, paddingTop: 3 }}>{option.textLabel}</Text>
              </View>
            </TouchableOpacity>
          )
        })}
      </Card>
    )
  }

  render() {
    return (
      <XContainer>
        {this._constomHeader()}
        <XContent>
          <VStack w='full' p='2' space='2'>
            {this._renderBarcodeSegment()}
            {this._renderListOptions()}
          </VStack>
          {/* {this._renderOptionListOptions()} */}
        </XContent>
      </XContainer>
    )
  }
}
