// import { Pressable } from 'native-base'
// import { TouchableOpacity } from 'react-native'
// import makeStyledComponent from 'x/config/nativebase/makeStyledComponent'
// import XText from './XText'
// import Box from './Box'
import XButton from './XButton'

// export type XButtonWithIconType = typeof Pressable
// export interface XButtonWithIconProps extends IXBlankButtonProps {
//   isLoading?: boolean
//   /**
//    * The right icon element to use in the button.
//    */
//   rightIcon?: JSX.Element | Array<JSX.Element>
//   /**
//    * The left icon element to use in the button.
//    */
//   leftIcon?: JSX.Element | Array<JSX.Element>
// }

// const XButtonWithIcon = React.forwardRef<XButtonWithIconType, XButtonWithIconProps>((props, fwdRef) => {
//   const { children, leftIcon, rightIcon, _text = {}, _icon = {}, _stack = {}, ...restProps } = props
//   // // const themeProps = useThemeProps('Button', restProps)
//   // // @ts-ignore
//   // const themeProps = usePropsWithComponentTheme(NBTheme.components.Button, restProps)
//   // // const iconThemeProps = useThemeProps('IconButton', restProps)

//   // const defaultThemeIconProps = themeProps._icon || {}
//   // const defaultThemeTextProps = themeProps._text || {}

//   let startIcon = leftIcon
//   let endIcon = rightIcon

//   if (endIcon && React.isValidElement(endIcon)) {
//     endIcon = React.Children.map(endIcon, (child: JSX.Element, index: number) =>
//       React.cloneElement(child, {
//         key: `button-end-icon-${index}`,
//         ..._icon,
//         ...child.props,
//         // ...defaultThemeIconProps,
//       })
//     )
//   }

//   if (startIcon && React.isValidElement(startIcon)) {
//     startIcon = React.Children.map(startIcon, (child: JSX.Element, index: number) =>
//       React.cloneElement(child, {
//         key: `button-start-icon-${index}`,
//         ..._icon,
//         ...child.props,
//         // ...defaultThemeIconProps,
//       })
//     )
//   }

//   const childElementToStyledElement = useCallback(
//     (leafChild, lcIdx) => {
//       if (typeof leafChild === 'string' || typeof leafChild === 'number') {
//         return childStringToTextCompoent(leafChild, lcIdx)
//       }
//       return React.cloneElement(leafChild, {
//         key: `ChildElementGen_${lcIdx.toString()}`,
//         _text,
//         _icon,
//         _stack,
//         ...leafChild.props,
//       })
//     },
//     [_text, _icon, _stack]
//   )

//   const childStringToTextCompoent = useCallback(
//     (child: React.ReactElement, cIdx) => {
//       const key = `ChildTextGen_${cIdx.toString()}`
//       // console.log(`childStringToTextCompoent child-${index} -> `, child)
//       if (typeof child === 'string' || typeof child === 'number') {
//         return (
//           <XText
//             key={key}
//             // {...internalThemeTextProps}
//             {..._text}>
//             {child}
//           </XText>
//         )
//       }
//       return React.Children.map(child, childElementToStyledElement)
//     },
//     // @ts-ignore
//     // [props._text, variant]
//     [_text, _icon, _stack]
//   )

//   return (
//     <TouchableOpacity
//       // @ts-ignore
//       ref={fwdRef}
//       // {...pressableProps}
//       {...restProps}>
//       <Box flex={1} flexDirection='row' space='1' alignItems='center' {..._stack}>
//         {startIcon}
//         {/* <VStack flex={1} px='1' alignItems='center' justifyContent='center'> */}
//         {/* <XText {...defaultThemeTextProps}>{children}</XText> */}
//         {React.Children.map(children, childStringToTextCompoent)}
//         {/* </VStack> */}
//         {endIcon}
//       </Box>
//     </TouchableOpacity>
//   )
// })

// // @ts-ignore
// const StyledXButtonWithIcon = makeStyledComponent(XButtonWithIcon, 'Button')

// export default StyledXButtonWithIcon as React.FC<IXButtonProps>

export default XButton
