import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as MySubscriptionActions from 'x/modules/subscription/MySubscriptionState'
import * as MarketplaceChannelActions from 'x/modules/marketplaceChannel/marketplaceChannelState'
import * as StoreState from 'x/modules/store/StoreState'
import {
  getMyStores,
  getMySubscriptionIsActiveWarning,
  getMySubscriptionWarning,
  getSelectedStore,
  getMySubscriptionQuotaWarning,
  getMySubscriptionIsActiveQuotaWarning,
  getWarningReAuthMKP,
} from 'x/redux/selectors'
import * as XShippingActions from 'x/modules/xshipping/XShippingState'
import StoreMyView from './StoreMyView'

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
    myStores: getMyStores(state),

    isSubscriptionWarningActive: getMySubscriptionIsActiveWarning(state),
    subscriptionWarning: getMySubscriptionWarning(state),
    isSubscriptionWarningQuotaActive: getMySubscriptionIsActiveQuotaWarning(state),
    subscriptionQuotaWarning: getMySubscriptionQuotaWarning(state),
    getWarningReAuthMKP: getWarningReAuthMKP(state),
  }),
  (dispatch) => ({
    // onBarcodeRead: bindActionCreators(BarcodeListenerActions.onBarcodeRead, dispatch),
    fetchInitializeData: bindActionCreators(StoreState.fetchInitializeData, dispatch),
    fetchMyStore: bindActionCreators(StoreState.fetchMyStore, dispatch),
    setXShippingConfig: bindActionCreators(XShippingActions.setXShippingConfig, dispatch),
    showSubscription: bindActionCreators(MySubscriptionActions.showWarning, dispatch),
    hideSubscription: bindActionCreators(MySubscriptionActions.hideWarning, dispatch),
    setWarningReAuthMKP: bindActionCreators(MarketplaceChannelActions.setWarningReAuthMKPState, dispatch),
    unSetWarningReAuthMKPState: bindActionCreators(MarketplaceChannelActions.unSetWarningReAuthMKPState, dispatch),
    showQuotaWarning: bindActionCreators(MySubscriptionActions.showQuotaWarning, dispatch),
    hideQuotaWarning: bindActionCreators(MySubscriptionActions.hideQuotaWarning, dispatch),
    dispatch,
  })
)(StoreMyView)
