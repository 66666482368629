import OpInterface from '../utils/op-interface'
import NavInterface from '../utils/nav-interface'
import CompInterface from '../utils/comp-interface'

export default class PlatformSpecific {
  static op: OpInterface = null

  static nav: NavInterface = null

  static comp: CompInterface = null

  static l = 'th'
}
