import React, { useState, useEffect } from 'react'
import { View } from 'react-native'
import { IQuestionsAndAnswers } from 'x/index'
import _ from 'lodash'

import XSwitch from 'xui/components/XSwitch'
import Box from 'xui/components/Box'
import { COLORS } from 'x/config/styles'
import XHelpModal from './XHelpModal'
import HelpButton from './HelpButton'
import XText from './XText'

interface IXSettingRow {
  title: string
  supTitle?: string
  value: boolean
  stateKey?: string
  onClick: (newValue: { isValue: boolean; stateKey: string }) => void
  helper?: {
    title: string
    FAQ: IQuestionsAndAnswers[]
    initiateOpenIndex?: number
  }
  backgroundColor?: string
  disabled?: boolean
}

const XSettingRow: React.FC<IXSettingRow> = ({ title, value, stateKey, onClick, helper, backgroundColor, disabled, supTitle = null }) => {
  // const [isDisabled, setDisabled] = useState<boolean>(_.isNil(disabled) ? false : disabled)
  const [isValue, setValue] = useState<boolean>(value)

  useEffect(() => {
    // console.log('value  ?? ', value)
    setValue(value)
  }, [value, disabled])

  const isValueChange = (newValue: boolean) => {
    // setValue(newValue)
    const newValueCallBack = {
      isValue: newValue,
      stateKey: _.isNil(stateKey) ? null : stateKey,
    }
    // console.log('newValueCallBack => ', newValueCallBack)
    onClick(newValueCallBack)
  }

  const renderHelpBtn = (onPressOpenOverlay: () => void) => (
    <View style={{ width: 30 }}>
      <HelpButton style={{ flex: 0, minWidth: 25, width: 25 }} onPress={() => onPressOpenOverlay()} />
    </View>
  )
  const isDisabled = _.isNil(disabled) ? false : disabled
  // const TEXT_COLOR = _.isNil(textColorActive) || !textColorActive ? COLORS.TEXT_INACTIVE : COLORS.TEXT_ACTIVE
  return (
    <Box
      minH='44px'
      // _dark={{ bg: 'gray.800' }}
      // _light={{ bg: _.isNil(backgroundColor) ? COLORS.WHITE : backgroundColor }}
      style={{
        flex: 1,
        // height: 40,
        backgroundColor: _.isNil(backgroundColor) ? COLORS.WHITE : backgroundColor,
        // flexDirection: 'row',
        // alignItems: 'center',
      }}>
      <Box
        minH='40px'
        flexDirection='row'
        alignItems='center'
        _dark={{ bg: 'gray.800' }}
        _light={{ bg: _.isNil(backgroundColor) ? COLORS.WHITE : backgroundColor }}>
        <Box style={{ flex: 1, paddingLeft: 12, paddingRight: 12, flexDirection: 'row', alignItems: 'center' }}>
          <XText variant='active'>{title}</XText>
          {_.isNil(helper) ? null : (
            <XHelpModal
              key='XHelpModalFromXSettingRow'
              headerTitle={helper.title}
              FAQ={helper.FAQ}
              initiateOpenIndex={helper.initiateOpenIndex}
              renderButton={(onPressOpenOverlay: () => void) => renderHelpBtn(onPressOpenOverlay)}
            />
          )}
        </Box>
        <Box style={{ width: 50 }}>
          <XSwitch value={isValue} onValueChange={(newValue: boolean) => isValueChange(newValue)} disabled={isDisabled} />
        </Box>
      </Box>
      {!_.isNil(supTitle) ? (
        <Box
          flexDirection='row'
          alignItems='center'
          _dark={{ bg: 'gray.800' }}
          _light={{ bg: _.isNil(backgroundColor) ? COLORS.WHITE : backgroundColor }}>
          <Box style={{ flex: 1, paddingLeft: 12, paddingRight: 12, flexDirection: 'row', alignItems: 'center' }}>
            <XText variant='inactive'>{supTitle}</XText>
          </Box>
          <Box style={{ width: 50 }} />
        </Box>
      ) : null}
    </Box>
  )
}

export default XSettingRow
