// var KJUR = require('jsrsasign')
var KJUR = require('./kjur.min')
var Base64 = require('./base64.min').Base64

export function xtoken(from, secureSecret, id) {
  const secret = Base64.decode(secureSecret) + 'rET'
  Date.prototype.getUnixTime = function() { return this.getTime() / 1000|0 }
  if (!Date.now) {Date.now = function() { return new Date() }}
  Date.time = function() { return Date.now().getUnixTime() }

  var now = new Date().getUnixTime()
  var tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000 * 30).getUnixTime()

  var header = {
    'typ': 'JWT',
    'alg': 'HS256',
  }

  function makeRandomStr() {
    var text = ''
    var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

    for (var i = 0; i < 16; i++)
    {text += possible.charAt(Math.floor(Math.random() * possible.length))}

    return text
  }

  var iat = now
  var exp = tomorrow
  var nbf = now
  var jti = makeRandomStr()
  var prv = '23bd5c8949f600adb39e701c400872db7a5976f7'

  var iss = from
  var sub = id

  var payload = '{"iss":"' + iss + '","iat":' + iat + ',"exp":' + exp + ',"nbf":' + nbf + ',"jti":"' + jti + '","sub":' + sub + ',"prv":"' + prv + '"}'
  var xSellyJWT = KJUR.jws.JWS.sign('HS256', header, payload, secret)
  return xSellyJWT
}
