import { connect } from 'react-redux'
import UserListView from './UserGroupView'
import { bindActionCreators } from 'redux'
import * as StoreActions from 'x/modules/store/StoreState'
import {
  getSelectedStore,
  getSelectedUserGroups,
} from 'x/redux/selectors'

export default connect(
  state => ({
    selectedStore: getSelectedStore(state),
    selectedUserGroups: getSelectedUserGroups(state),
  }),
  dispatch => ({
    fetchMyStore: bindActionCreators(StoreActions.fetchMyStore, dispatch),
    createUserGroup: bindActionCreators(StoreActions.createUserGroup, dispatch),
    updateUserGroup: bindActionCreators(StoreActions.updateUserGroup, dispatch),
    deleteUserGroup: bindActionCreators(StoreActions.deleteUserGroup, dispatch),
    resetUserGroupInviteCode: bindActionCreators(StoreActions.resetUserGroupInviteCode, dispatch),
    generateUserGroupInviteLink: bindActionCreators(StoreActions.generateUserGroupInviteLink, dispatch),
  })
)(UserListView)
