import React from 'react'
import { View, TouchableOpacity, StyleSheet } from 'react-native'
import _ from 'lodash'

import { S, STYLES, COLORS } from 'x/config/styles'
import { IXCheckboxListOption, IXCheckboxListProps } from 'x/index'
import FAIcon from 'react-native-vector-icons/FontAwesome'
import XTitle from './XTitle'
import XText from './XText'

const XCheckboxList = (props: IXCheckboxListProps) => {
  // const [selectedIndex, setSelectedIndex] = React.useState(0)

  // React.useEffect(() => {
  //   if (_.isNumber(props.selectedIndex)) {
  //     _onPressRadioItem(props.selectedIndex)
  //   }
  // }, [])

  // React.useEffect(() => {
  //   if (_.isNumber(props.selectedIndex) && props.selectedIndex !== selectedIndex) {
  //     _onPressRadioItem(props.selectedIndex)
  //   }
  // }, [props.selectedIndex])

  // async function _onPressRadioItem(pressedIdx: number) {
  //   setSelectedIndex(pressedIdx)
  //   await util.delay(100)
  //   if (util.isAsyncFunction(props.onRadioChange)) {
  //     await props.onRadioChange(props.options[pressedIdx], pressedIdx)
  //   } else {
  //     props.onRadioChange(props.options[pressedIdx], pressedIdx)
  //   }
  // }

  const onChangeChackbox = (opt: IXCheckboxListOption, itemIndex: number) => {
    const newOptions = _.clone(props.options)
    newOptions[itemIndex].isChecker = !opt.isChecker
    props.onChange(newOptions, itemIndex)
  }

  const _renderItem = (opt: IXCheckboxListOption, itemIndex: number) => {
    const checked = opt.isChecker
    return (
      <TouchableOpacity
        style={[S.ROW_MIDDLE_START, S.MIN_HEIGHT_34, StyleSheet.flatten(props.itemContainerStyle)]}
        onPress={() => onChangeChackbox(opt, itemIndex)}
        key={`Chackbox_${itemIndex}`}>
        <FAIcon
          style={[
            {
              flex: 0,
              minWidth: 30,
              fontSize: STYLES.FONT_ICON_NORMAL,
              color: COLORS.APP_MAIN,
            },
          ]}
          name={checked ? 'check-square-o' : 'square-o'}
        />
        <XText variant='active'>{opt.label}</XText>
      </TouchableOpacity>
    )
  }

  function _renderList() {
    return props.options.map(_renderItem)
  }

  return (
    <View style={[S.COLUMN_LEFT_TOP, StyleSheet.flatten(props.listContainerStyle)]}>
      <XTitle value={props.title} style={StyleSheet.flatten(props.titleStyle)} />
      {_renderList()}
    </View>
  )
}

XCheckboxList.defaultProps = {
  disabled: false,
  title: undefined,
  selectedIndex: 0,
  titleStyle: {},
  listContainerStyle: {},
  itemContainerStyle: {},
  labelStyle: {},
  // radioIconStyle: {},
}

export default XCheckboxList
