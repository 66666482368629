import React from 'react'

import _ from 'lodash'
import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'
import { ISelectedStoreMap, IXScreenProps } from 'x/index'

interface IBaseProductWarehousesPickerProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
}

interface IBaseProductWarehousesPickerState {
  isLoading: boolean
  loadingWidth: number
  loadingHeight: number
  warehoueseIds: number[]
  isChangeData: boolean
}

export default abstract class BaseProductWarehousesPicker extends React.Component<
  IBaseProductWarehousesPickerProps,
  IBaseProductWarehousesPickerState
> {
  // abstract async handleSubmitForm(response: any): Promise<void>
  // abstract didMountCheckPermissionForPhone?: () => void
  // validationSchema: Yup.ObjectSchema<any>
  // imgManagerIDRef: React.RefObject<any>
  // webview: React.RefObject<any>
  warehoueseIdsFormParams: number[]

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      loadingHeight: 0,
      loadingWidth: 0,
      warehoueseIds: [],
      isChangeData: false,
    }
    // this.webview = React.createRef()
    // this.imgManagerIDRef = React.createRef()
    this.warehoueseIdsFormParams = []
  }

  async componentDidMount() {
    // const { navigation } = this.props
    // const { state } = navigation
    // const { params } = state
    // // console.log(`params `, params)
    const params = util.getNavParams(this.props)
    const warehouses = _.isNil(params.warehouses) ? [] : params.warehouses
    const warehoueseIds = warehouses
    this.warehoueseIdsFormParams = warehouses
    // const warehoueseIdsFormParams = warehouses
    // console.log(`componentDidMount `, warehouses)
    await util.setStatePromise(this, {
      warehoueseIds,
    })
  }

  _onPressCheckBox = (warehouseId: number) => {
    const { warehoueseIds } = this.state
    const newWarehoueseIds = warehoueseIds
    const indexToRemove = warehoueseIds.findIndex((wId) => wId === warehouseId)
    if (indexToRemove > -1) {
      newWarehoueseIds.splice(indexToRemove, 1)
    } else {
      newWarehoueseIds.push(warehouseId)
    }
    // let isChangeData = false
    const isChangeData = true

    // const { navigation } = this.props
    // const { state } = navigation
    // const { params } = state
    // console.log(`params `, params)
    // const warehouses = _.isNil(params.warehouses) ? [] : params.warehouses

    // console.log(`newWarehoueseIds `, newWarehoueseIds)
    // console.log(`warehoueseIdsFormParams `, warehouses)
    // console.log(`this.warehoueseIdsFormParams `, this.warehoueseIdsFormParams)
    // if (newWarehoueseIds !== warehouses) {
    //   isChangeData = true
    // }
    util.setStatePromise(this, {
      warehoueseIds: newWarehoueseIds,
      isChangeData,
    })
  }

  _onPressSubmit = async () => {
    const { warehoueseIds } = this.state
    if (warehoueseIds.length < 1) {
      p.op.showConfirmationOkOnly(``, `กรุณาเลือกอย่างน้อย 1 คลัง`)
      return
    }

    util.navGoBack(this.props)

    const callBackEditWarehouses = util.getNavParam(this.props, 'callBackEditWarehouses')
    if (_.isFunction(callBackEditWarehouses)) {
      callBackEditWarehouses(warehoueseIds)
    }
  }
}
