import React from 'react'
import * as util from 'x/utils/util'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
// import SRowItem from '../../components/renderRowLeftAndRight'
import BaseStoreWebsiteSelectedOptionModeView from 'x/modules/storeWebsite/BaseStoreWebsiteSelectedOptionModeView'
import { COLORS } from 'x/config/styles'
import VStack from 'xui/components/VStack'
import HelpButton from 'xui/components/HelpButton'
import XCard from 'xui/components/XCard'
import { TouchableOpacity } from 'react-native'
import HStack from 'xui/components/HStack'
import XText from 'xui/components/XText'
import XIcon from 'xui/components/XIcon'
import XHelpModal from 'xui/components/XHelpModal'
import FAQ from 'x/config/FAQ'

const _renderAgreement = (onPressOpenOverlay: () => void) => <HelpButton onPress={() => onPressOpenOverlay()} />

const _renderHelpBtn = (onPressOpenOverlay: () => void) => (
  <TouchableOpacity
    onPress={() => onPressOpenOverlay()}
    style={{
      borderRadius: 90,
      borderColor: COLORS.TEXT_INACTIVE,
      borderTopWidth: 1,
      borderBottomWidth: 1,
      borderLeftWidth: 1,
      borderRightWidth: 1,
    }}>
    <HStack alignItems='center' justifyContent='center' pl='2'>
      <XText variant='inactive'>คำถามพบบ่อย</XText>
      <HelpButton onPress={() => onPressOpenOverlay()} />
    </HStack>
  </TouchableOpacity>
)

export default abstract class BaseUIStoreWebsiteSelectedOptionModeView extends BaseStoreWebsiteSelectedOptionModeView {
  _renderHeader = () => {
    const title = 'ประเภทคำสั่งซื้อ'
    return (
      <XCustomHeader
        title={title}
        headerLeftProps={{ backIcon: true, onPressItem: () => util.navGoBack(this.props) }}
        // headerRightProps={rightProps}
        // headerRightProps={{ label: 'ยกเลิก', onPressItem: () => util.navGoBack(this.props) }}
        // renderHeaderRight={this._renderHeaderRight}
      />
    )
  }

  _renderSingleProductMode = () => (
    <XCard p='2' onPress={() => this._onPressMode('SINGLE_PRODUCT_MODE')}>
      <HStack>
        <VStack w='8'>
          <XIcon name='box' family='FontAwesome5' variant='active' size='xs' />
        </VStack>
        <VStack flex={1}>
          <HStack alignItems='center'>
            {/* <XIcon name='box' family='FontAwesome5' variant='active' size='xs' /> */}
            <XText variant='inactive' bold>
              สินค้ารายการเดียวแบบไม่มีตัวเลือก
            </XText>
          </HStack>
          <HStack mt='1'>
            <XText variant='inactive'>มีสินค้ารายการเดียวแบบไม่มีตัวเลือก เช่น ครีมหน้าเด้ง 2 กระปุก</XText>
          </HStack>
          <HStack mt='2'>
            <XHelpModal
              key='สินค้ารายการเดียว'
              headerTitle='สินค้ารายการเดียว'
              FAQ={FAQ.SHIPPING_TYPE_SELECTOR_FAQ}
              initiateOpenIndex={[0]}
              renderButton={(onPressOpenOverlay: () => void) => _renderHelpBtn(onPressOpenOverlay)}
            />
          </HStack>
        </VStack>
        <HStack w='12' alignItems='center' justifyContent='center'>
          <XIcon name='arrowright' family='AntDesign' />
        </HStack>
      </HStack>
      {/* <TouchableOpacity
        style={{ flex: 1, borderBottomColor: COLORS.TEXT_INACTIVE, borderBottomWidth: 1 }}
        onPress={() => this._onPressMode('SINGLE_PRODUCT_MODE')}>
        <HStack flex={1}>
          <VStack flex={1}>
            <HStack alignItems='center'>
              <XIcon name='box' family='FontAwesome5' variant='active' size='xs' />
              <XText ml='2'>สินค้าชิ้นเดียว</XText>
            </HStack>
            <HStack mt='1'>
              <XText variant='inactive'>มีสินค้ารายการเดียวแบบไม่มีตัวเลือก เช่น ครีมหน้าเด้ง 2 กระปุก</XText>
            </HStack>
          </VStack>
          <HStack w='12' alignItems='center' justifyContent='center'>
            <XIcon name='arrowright' family='AntDesign' />
          </HStack>
        </HStack>
      </TouchableOpacity> */}
    </XCard>
  )

  _renderProductMultiOptionsMode = () => (
    <XCard p='2' mt='2' onPress={() => this._onPressMode('PRODUCT_MULTI_OPTIONS_MODE')}>
      <HStack>
        <VStack w='8'>
          <XIcon name='box' family='FontAwesome5' variant='active' size='xs' color={COLORS.WEBSITE_PRODUCT_MULTI_OPTIONS_MODE} />
        </VStack>
        <VStack flex={1}>
          <HStack alignItems='center'>
            {/* <XIcon name='box' family='FontAwesome5' variant='active' size='xs' /> */}
            <XText color={COLORS.WEBSITE_PRODUCT_MULTI_OPTIONS_MODE} bold>
              สินค้ารายการเดียวแบบมีตัวเลือก
            </XText>
          </HStack>
          <HStack mt='1'>
            <XText variant='inactive'>
              {`สินค้ารายการเดียวแบบมีตัวเลือก เช่น เสื้อมีหลายสี (ฟ้า ดำ ขาว ) กางเกงมีหลายไซส์ (S M L XL) รองเท้ามีหลายขนาด (34" 36" 40" 44")`}
            </XText>
          </HStack>
          <HStack mt='2'>
            <XHelpModal
              key='สินค้ารายการเดียว'
              headerTitle='สินค้ารายการเดียว'
              FAQ={FAQ.SHIPPING_TYPE_SELECTOR_FAQ}
              initiateOpenIndex={[0]}
              renderButton={(onPressOpenOverlay: () => void) => _renderHelpBtn(onPressOpenOverlay)}
            />
          </HStack>
        </VStack>
        <HStack w='12' alignItems='center' justifyContent='center'>
          <XIcon name='arrowright' family='AntDesign' />
        </HStack>
      </HStack>
    </XCard>
  )

  _renderProductSetMode = () => (
    // const text =
    //   'เช่น ชุดเซ็ตต้อนรับเปิดเทอม ประกอบด้วย เสื้อ 2 ตัว + กางเกง 2 ตัว + รองเท้า 1 คู่ โดยลูกค้าสามารถเลือก ขนาดของ เสื้อ กางเกงและรองเท้าเองได้'
    <XCard p='2' mt='2' onPress={() => this._onPressMode('PRODUCT_SET_MODE')}>
      <HStack>
        <VStack w='8'>
          <XIcon name='box' family='FontAwesome5' variant='active' size='xs' color={COLORS.WEBSITE_PRODUCT_SET_MODE} />
        </VStack>
        <VStack flex={1}>
          <HStack alignItems='center'>
            {/* <XIcon name='box' family='FontAwesome5' variant='active' size='xs' /> */}
            <XText color={COLORS.WEBSITE_PRODUCT_SET_MODE} bold>
              สินค้าจัดเซ็ท (หลายรายการ)
            </XText>
          </HStack>
          <HStack mt='1'>
            <XText variant='inactive'>
              จัดเซ็ทข้ามสินค้าได้ เลือกได้หลายรายการ เช่น ชุดเซ็ตต้อนรับเปิดเทอม ประกอบด้วย เสื้อ 2 ตัว + กางเกง 2 ตัว + รองเท้า 1 คู่
              โดยลูกค้าสามารถเลือก ขนาดของ เสื้อ กางเกง และรองเท้าเองได้
            </XText>
          </HStack>
          <HStack mt='2'>
            <XHelpModal
              key='สินค้ารายการเดียว'
              headerTitle='สินค้ารายการเดียว'
              FAQ={FAQ.SHIPPING_TYPE_SELECTOR_FAQ}
              initiateOpenIndex={[0]}
              renderButton={(onPressOpenOverlay: () => void) => _renderHelpBtn(onPressOpenOverlay)}
            />
          </HStack>
        </VStack>
        <HStack w='12' alignItems='center' justifyContent='center'>
          <XIcon name='arrowright' family='AntDesign' />
        </HStack>
      </HStack>
    </XCard>
  )

  _renderListMode2 = () => {
    const {} = this.state
    return (
      <XCard p='2' w='full'>
        {/* <HStack>
          <XText>ประเถทคำสั่งซื้อ</XText>
        </HStack> */}
        <VStack w='full' p='2' borderColor={COLORS.TEXT_INACTIVE} borderWidth='1' borderRadius={7}>
          {this._renderSingleProductMode()}
          {this._renderProductMultiOptionsMode()}
          {this._renderProductSetMode()}
        </VStack>
      </XCard>
    )
  }

  _renderListMode = () => (
    <VStack>
      {this._renderSingleProductMode()}
      {this._renderProductMultiOptionsMode()}
      {this._renderProductSetMode()}
    </VStack>
  )

  _renderMain = () => <VStack w='full'>{this._renderListMode()}</VStack>

  render() {
    return (
      <XContainer>
        {this._renderHeader()}
        <XContent p='2' style={{ backgroundColor: COLORS.BG_LIGHT_GREY }}>
          {this._renderMain()}
        </XContent>
      </XContainer>
    )
  }
}
