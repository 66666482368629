import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ResellerAddUserTab from './ResellerAddUserTab'
import * as ResellerStateActions from 'x/modules/reseller/ResellerState'

import {
  getUsersInGroupByKey,
  getIsFetchingUsersInGroupByKey,
  getIsAllFetchedUsersInGroupByKey,
  getCountOfUsersInGroupByKey,
} from 'x/redux/selectors'

const prefixKey = 'pending'
export default connect(
  // state => ({
  //
  // }),
  null,
  dispatch => {
    return {
      addNewUser: bindActionCreators(ResellerStateActions.addNewUser, dispatch),
    }
  }
)(ResellerAddUserTab)
