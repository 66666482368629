import React from 'react'
import _ from 'lodash'

import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'

import { IAnyObject, IXScreenProps } from 'x/types'
import api from 'x/utils/api'
import XFlatPicker from 'xui/components/XFlatPicker'
import { INewXShippingAddress } from '../xshipping/BaseJAntTConnectView'

interface IAddressFromZipcode {
  pid: number
  plname: string
  did: number
  dlname: string
}

export interface IResponeAddressFromZipcode {
  data: IAddressFromZipcode[]
}

export interface BaseAddressXShippingViewProps extends IXScreenProps {
  selectedStore: any
}

export interface BaseAddressXShippingViewState {
  textName: string
  textPhon: string
  textPostcode: string
  // textProvince: string
  // textDistrict: string
  textSubDistrict: string
  textAddress: string

  selectedProvinceIndex: number
  selectedDistrictIndex: number

  provinceFromZipcode: {
    id: number
    label: string
  }[]
  districtFromZipcode: {
    id: number
    label: string
  }[]

  mode: 'CREATE' | 'EDIT'
}

export default abstract class BaseAddressXShippingView extends React.Component<
  BaseAddressXShippingViewProps,
  BaseAddressXShippingViewState
> {
  // DISPLAY_TABS: IUserListTab[]
  // _tabRef: React.RefObject<any>
  _provincePickerRef: React.RefObject<XFlatPicker<any>>

  protected constructor(props) {
    super(props)

    this.state = {
      textName: '',
      textPhon: '',
      textPostcode: '',
      // textProvince: '',
      // textDistrict: '',
      textSubDistrict: '',
      textAddress: '',
      selectedProvinceIndex: 0,
      selectedDistrictIndex: 0,

      districtFromZipcode: null,
      provinceFromZipcode: null,
      mode: 'CREATE',
    }
    this._provincePickerRef = React.createRef()
  }

  async componentDidMount() {
    const { navigation } = this.props
    // const { state } = navigation
    const params = util.getNavParams(this.props)
    const { address, callBackAddressUpdate } = params
    // const address = await api.getV2(`address/lookup_zipcode?z=10110`)
    if (!_.isNil(address)) {
      const addressHasInterface: INewXShippingAddress = address
      let textName = ''
      let textPhon = ''
      let textPostcode = ''
      // let textDistrict = ''
      // let textProvince = ''
      let textSubDistrict = ''
      let textAddress = ''
      let districtFromZipcode = []
      let provinceFromZipcode = []
      let selectedProvinceIndex = 0
      let selectedDistrictIndex = 0
      const addressFromZipcode = await api.getV2(`address/lookup_zipcode?z=${addressHasInterface.postcode}`)
      // @ts-ignore
      if (addressFromZipcode.data) {
        // @ts-ignore
        const newAddressFromRes: IAddressFromZipcode[] = addressFromZipcode.data
        textName = addressHasInterface.name
        textPhon = addressHasInterface.phone
        textPostcode = addressHasInterface.postcode
        newAddressFromRes.map((mapAddress: IAddressFromZipcode, index: number) => {
          if (mapAddress.did === addressHasInterface.district_id) {
            // textDistrict = mapAddress.dlname
            // textProvince = mapAddress.plname
            selectedDistrictIndex = index
          }
          // if (mapAddress.pid === addressHasInterface.province_id) {
          //   selectedProvinceIndex = index
          // }
          const district = {
            id: mapAddress.did,
            label: mapAddress.dlname,
          }
          const province = {
            id: mapAddress.pid,
            label: mapAddress.plname,
          }
          if (provinceFromZipcode.length > 0) {
            provinceFromZipcode.map((pv: { id: number; label: string }) => {
              if (pv.id !== mapAddress.pid) {
                provinceFromZipcode.push(province)
              }
            })
          } else {
            provinceFromZipcode.push(province)
          }
          districtFromZipcode.push(district)
        })
        textSubDistrict = addressHasInterface.sub_district
        textAddress = addressHasInterface.address
        provinceFromZipcode.map((pv: { id: number; label: string }, index: number) => {
          if (pv.id === addressHasInterface.province_id) {
            selectedProvinceIndex = index
          }
        })
        await util.setStatePromise(this, {
          textName,
          textPhon,
          textPostcode,
          // textDistrict,
          // textProvince,
          textSubDistrict,
          textAddress,
          districtFromZipcode,
          provinceFromZipcode,
          selectedProvinceIndex,
          selectedDistrictIndex,
        })
        await util.setStatePromise(this, {
          mode: 'EDIT',
        })
      } else {
        textName = addressHasInterface.name
        textPhon = addressHasInterface.phone
        textPostcode = addressHasInterface.postcode
        // textDistrict = addressHasInterface.district
        // textProvince = addressHasInterface.province
        textSubDistrict = addressHasInterface.sub_district
        textAddress = addressHasInterface.address
        districtFromZipcode = [
          {
            id: addressHasInterface.district_id,
            label: addressHasInterface.district,
          },
        ]
        provinceFromZipcode = [
          {
            id: addressHasInterface.province_id,
            label: addressHasInterface.province,
          },
        ]
        await util.setStatePromise(this, {
          textName,
          textPhon,
          textPostcode,
          // textDistrict,
          // textProvince,
          textSubDistrict,
          textAddress,
          districtFromZipcode,
          provinceFromZipcode,
          selectedProvinceIndex,
          selectedDistrictIndex,
        })
        await util.setStatePromise(this, {
          mode: 'EDIT',
        })
      }
    } else {
      await util.setStatePromise(this, {
        mode: 'CREATE',
      })
    }
  }

  _onChangePostcodeText = async (newText: string) => {
    const newTextTrim = newText.trim()
    if (newTextTrim.length > 5) {
      return
    }
    if (newTextTrim.length === 5 && _.isNumber(+newTextTrim)) {
      const districtFromZipcode = []
      const provinceFromZipcode = []
      const selectedProvinceIndex = 0
      const selectedDistrictIndex = 0
      const addressFromZipcode = await api.getV2(`address/lookup_zipcode?z=${newTextTrim}`)
      // @ts-ignore
      if (addressFromZipcode.data && addressFromZipcode.data.length > 0) {
        // @ts-ignore
        const newAddressFromRes: IAddressFromZipcode[] = addressFromZipcode.data
        newAddressFromRes.map((mapAddress: IAddressFromZipcode, index: number) => {
          const district = {
            id: mapAddress.did,
            label: mapAddress.dlname,
          }
          const province = {
            id: mapAddress.pid,
            label: mapAddress.plname,
          }
          if (provinceFromZipcode.length > 0) {
            provinceFromZipcode.map((pv: { id: number; label: string }) => {
              if (pv.id !== mapAddress.pid) {
                provinceFromZipcode.push(province)
              }
            })
          } else {
            provinceFromZipcode.push(province)
          }
          districtFromZipcode.push(district)
        })
        await util.setStatePromise(this, {
          districtFromZipcode,
          provinceFromZipcode,
          selectedProvinceIndex,
          selectedDistrictIndex,
        })
      }
    }
    await util.setStatePromise(this, {
      textPostcode: newTextTrim,
    })
  }

  _submitBtn = async () => {
    const { selectedStore, navigation } = this.props
    // const { state, goBack } = navigation
    const params = util.getNavParams(this.props)
    const { address, callBackAddressUpdate } = params
    const {
      mode,
      textPostcode,
      textPhon,
      textName,
      textSubDistrict,
      textAddress,
      provinceFromZipcode,
      selectedProvinceIndex,
      districtFromZipcode,
      selectedDistrictIndex,
    } = this.state
    let apiMethod = api.putV2
    if (textName.length < 1) {
      p.op.showConfirmationOkOnly('', 'กรุณาระบุชื่อ')
      return
    }
    if (textPhon.length < 9) {
      p.op.showConfirmationOkOnly('', 'กรุณาระบุเบอร์โทรให้ถูกต้อง')
      return
    }
    if (textPostcode.length !== 5) {
      p.op.showConfirmationOkOnly('', 'กรุณาระบุรหัสไปรษณีย์ให้ถูกต้อง')
      return
    }
    if (textSubDistrict.length < 1) {
      p.op.showConfirmationOkOnly('', 'กรุณาระบุ แขวง/ตำบล')
      return
    }
    if (textAddress.length < 1) {
      p.op.showConfirmationOkOnly('', 'กรุณาระบุที่อยู่')
      return
    }

    const province = provinceFromZipcode[selectedProvinceIndex].id
    const district = districtFromZipcode[selectedDistrictIndex].id
    // return
    const body: IAnyObject = {
      store_id: selectedStore.get('id'),
      name: textName.trim(),
      phone: textPhon,
      address: textAddress,
      province_id: province,
      district_id: district,
      postcode: textPostcode,
      sub_district: textSubDistrict,
    }
    if (mode === 'EDIT') {
      apiMethod = api.patchV2
      const addressHasInterface: INewXShippingAddress = address
      body.address_id = addressHasInterface.id
    }
    const response: { addresses: INewXShippingAddress } = await apiMethod(api.XSHIPPING_ADDRESSES, body)
    // console.log('response => ', response)
    if (response.addresses) {
      if (_.isFunction(callBackAddressUpdate)) {
        callBackAddressUpdate(response.addresses)
      }
      p.op.showToast('บันทึกที่อยู่เรียบร้อยแล้ว', 'success')
      util.navGoBack(this.props)
    }
  }
}
