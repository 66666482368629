import React, { Component, PureComponent } from 'react'
import { Text, Spinner } from 'native-base'

import { View } from 'react-native'

import { isDiffAccuracy } from 'x/utils/util'
import { STYLES, COLORS } from 'x/config/styles'
import { Map } from 'immutable'
import { UserInUserGroupsMap } from 'x/index'
import p from 'x/config/platform-specific'

import BaseResellerListTab from 'x/modules/userGroup/BaseResellerListTab'
import _ from 'lodash'
import { APP_CONFIG } from 'x/config/mode'
import Box from 'xui/components/Box'
import XButton from 'xui/components/XButton'
import XIcon from 'xui/components/XIcon'
import HStack from 'xui/components/HStack'

export default class ResellerListPendingTab extends BaseResellerListTab {
  static displayName = 'ResellerListPendingTab'

  submittngMap: Map<number | string, boolean>

  // Begin Abstract
  constructorExtended(that) {
    this.submittngMap = Map({})
  }

  doAfterRefresh() {
    this.submittngMap = Map({})
  }

  // @ts-ignore
  getListType(): 'p' | 'j' | 'b' {
    return 'p'
  }

  renderUsersRightComponent(arg: { index: number; data: UserInUserGroupsMap }): JSX.Element {
    const { index, data } = arg
    // @ts-ignore
    return <UserActionsComponent index={index} data={data} onApprove={this._approveUser} onReject={this._rejectUser} />
    // return (
    //   <Row style={{ flex: 1 }}>
    //     <ActionColumn>
    //       <ActionIconBtn
    //         index={index}
    //         data={data}
    //         onPressItem={this._rejectUser} />
    //     </ActionColumn>
    //     <ActionColumn>
    //       <ActionIconBtn
    //         index={index}
    //         data={data}
    //         onPressItem={this._approveUser}
    //         isApprove />
    //     </ActionColumn>
    //   </Row>
    // )
  }
  // End Abstract

  _approveUser = async (index: number, data: UserInUserGroupsMap): Promise<void> => {
    if (!Map.isMap(this.submittngMap) || this.submittngMap.has(index)) {
      return
    }
    this.submittngMap = this.submittngMap.set(index, true)
    // log('do _approveUser  this.submittngMap.toJS() => ', this.submittngMap.toJS())
    // log('Do Approve User index => ', index)
    // log('Do Approve User data => ', data.toJS())
    await this._changeUserPendingStatus({ profile_id: data.get('i'), newStatus: 'a', data })
    this.submittngMap = this.submittngMap.delete(index)
  }

  _rejectUser = async (index: number, data: UserInUserGroupsMap): Promise<void> => {
    if (!Map.isMap(this.submittngMap) || this.submittngMap.has(index)) {
      return
    }

    const userDisplayName = data.get('d')
    const isUserConfirmed = await new Promise<boolean>((passConfirm) => {
      p.op.showConfirmation(
        'ยืนยันปฏิเสธคำขอ',
        `กรุณายืนยันว่าฉันต้องการปฏิเสธคำขอเข้าร่วมกลุ่มของผู้ใช้ ${userDisplayName} หรือไม่`,
        () => passConfirm(true),
        () => passConfirm(false)
      )
    })
    if (!isUserConfirmed) {
      return
    }

    this.submittngMap = this.submittngMap.set(index, true)
    // log('Do Reject User index => ', index)
    // log('Do Reject User data => ', data.toJS())
    await this._changeUserPendingStatus({ profile_id: data.get('i'), newStatus: 'r', data })
    this.submittngMap = this.submittngMap.delete(index)
  }

  _changeUserPendingStatus = async (params: { profile_id: number; newStatus: 'a' | 'r'; data: UserInUserGroupsMap }) => {
    const { selectedStoreId, selectedUserGroupId, changeUsersPendingStatus } = this.props
    if (!selectedStoreId || !selectedUserGroupId) {
      throw 'ResellerListPendingTab::_changeUserPendingStatus:: No selectedUserGroupId or selectedUserGroupId'
    }

    if (!params.data || !Map.isMap(params.data)) {
      throw 'ResellerListPendingTab::_changeUserPendingStatus:: No user data'
    }

    const userDisplayName = params.data.get('d')

    if (_.isEmpty(params)) {
      throw 'ResellerListPendingTab::_changeUserPendingStatus:: No changeParams'
    }

    if (!params.profile_id) {
      throw 'ResellerListPendingTab::_changeUserPendingStatus:: Incorrect profile_id'
    }

    if (!_.includes(['a', 'r'], params.newStatus)) {
      throw 'ResellerListPendingTab::_changeUserPendingStatus:: Incorrect status type'
    }

    const { profile_id, newStatus } = params
    const body = {
      store_id: selectedStoreId,
      ug_id: selectedUserGroupId,
      // user_ids: [user_id],
      // id,
      profile_id,
      newStatus,
      // req_latest_at: ,
    }

    const isSuccess = await new Promise<boolean>((resolveAPI) => {
      changeUsersPendingStatus({
        body,
        successCallback: resolveAPI,
        failedCallback: resolveAPI,
      })
    })

    const txtAction = newStatus === 'a' ? 'อนุมัติ' : 'ปธิเสธ'
    const txtSummary = `${txtAction}คำขอเข้าร่วมของ "${userDisplayName}"`
    if (isSuccess) {
      p.op.showToast(`${txtSummary} สำเร็จแล้ว`, 'success')
    } else {
      p.op.showToast(`${txtSummary} ล้มเหลว`, 'danger')
    }

    // log('Finish profile_id => ', profile_id)
  }
}

// Internal Component

// const BtnText = ({ label }) => (
//   <Text
//     numberOfLines={1}
//     allowFontScaling={false}
//     style={{
//       flex: 1,
//       fontSize: STYLES.FONT_SIZE_NORMAL,
//       fontWeight: 'bold',
//       paddingLeft: 4,
//       paddingRight: 4,
//       textAlign: 'center'
//     }}>
//     { label }
//   </Text>
// )
//
// const ActionBtn = ({ onPressItem = null, children = null, danger = false, success = false }) => (
//   <Button
//     onPress={onPressItem}
//     style={{ width: '100%', height: 40 }}
//     danger={danger}
//     success={success}>
//     {children}
//   </Button>
// )

class ActionIconBtn extends Component {
  shouldComponentUpdate() {
    return false
  }

  _onPressBtn = () => {
    // @ts-ignore
    const { index, data, onPressItem } = this.props
    onPressItem(index, data)
  }

  render() {
    // @ts-ignore
    const { isApprove = false } = this.props
    return (
      <XButton
        variant='ghost'
        onPress={this._onPressBtn}
        leftIcon={
          <XIcon
            name={isApprove ? 'checkmark-circle' : 'close-circle'}
            family='Ionicons'
            color={isApprove ? COLORS.BRAND_Success : COLORS.BRAND_Danger}
          />
        }
      />
    )
  }
}

class ActionColumn extends PureComponent {
  // shouldComponentUpdate() {
  //   return false
  // }
  render() {
    return (
      <Box flex={1} style={{ alignItems: 'center', justifyContent: 'center', padding: 4 }}>
        {this.props.children}
      </Box>
    )
  }
}

class UserTextStatus extends PureComponent {
  // shouldComponentUpdate() {
  //   return false
  // }
  render() {
    // @ts-ignore
    const { submitting = false, approved = false } = this.props
    if (submitting) {
      return (
        <View style={{ alignItems: 'center', justifyContent: 'center' }}>
          <Spinner color={COLORS.APP_MAIN} size='small' />
        </View>
      )
    }
    return (
      <View style={{ alignItems: 'center', justifyContent: 'center' }}>
        <Text
          style={{
            fontSize: STYLES.FONT_SIZE_NORMAL,
            color: approved ? COLORS.BRAND_Success : COLORS.BRAND_Danger,
          }}>
          {approved ? 'อนุมัติแล้ว' : 'ปฏิเสธแล้ว'}
        </Text>
      </View>
    )
  }
}

class UserActionsComponent extends Component {
  shouldComponentUpdate(prevProps) {
    return isDiffAccuracy(prevProps, this.props, ['index', 'data'])
  }

  _onApproveUser = () => {
    // @ts-ignore
    const { index, data, onApprove } = this.props
    onApprove(index, data)
  }

  _onRejectUser = () => {
    // @ts-ignore
    const { index, data, onReject } = this.props
    onReject(index, data)
  }

  render() {
    // @ts-ignore
    const { index, data } = this.props
    if (!data || !Map.isMap(data)) {
      return null
    }

    if (!APP_CONFIG.user_group_member_list_view.show_accept_member_btns) {
      return null
    }

    const submitting = data.get('submitting') || false
    const approved = data.get('approved') || false
    const rejected = data.get('rejected') || false

    if (approved) {
      // @ts-ignore
      return <UserTextStatus approved />
    }
    if (rejected) {
      // @ts-ignore
      return <UserTextStatus rejected />
    }
    if (submitting) {
      // @ts-ignore
      return <UserTextStatus submitting />
    }

    return (
      <HStack style={{ flex: 1 }}>
        <ActionColumn>
          <ActionIconBtn
            // @ts-ignore
            index={index}
            data={data}
            onPressItem={this._onRejectUser}
          />
        </ActionColumn>
        <ActionColumn>
          <ActionIconBtn
            // @ts-ignore
            index={index}
            data={data}
            onPressItem={this._onApproveUser}
            isApprove
          />
        </ActionColumn>
      </HStack>
    )
  }
}
