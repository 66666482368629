import { Map, List, fromJS, isImmutable } from 'immutable'
import { ActionApiParams, ISetSelectedStoreOrdersDatesParams } from 'x/index'
import _ from 'lodash'
import { setAppConfig, ORG_OWNER_APP_CONFIG, DEFAULT_APP_CONFIG } from 'x/config/mode'
import * as util from 'x/utils/util'
import {
  setMyStoresInStores,
  setMyStoresIsFetchingInStores,
  setSellerStoresInStores,
  setSellerStoresIsFetchingInStores,
  setSellerStoresShouldFetchInStores,
  setShouldFetchOrderSummaryInStores,
  setMyStoresShouldFetchInStores,
  setShouldFetchSelectedStoreInStores,
  setShouldFetchUserGroupsInStores,
  setPendingStoresInStores,
  getPendingStoresInStores,
  getSelectedStoreInStores,
  setSelectedStoreInStores,
  setEditingStoreInStores,
  setProductGroupsInStores,
  getProductGroupsInStores,
  setProductGroupsUpdatedAtInStores,
  getUserGroupsInStores,
  setUserGroupsInStores,
  getLocalConfigsInStores,
  setLocalConfigsInStores,
  setOrderSummaryToEdittingStore,
  setOrderSummaryToSelectedStore,
} from '../../redux/selectors' // Change path

// import {loop, Effects} from 'redux-loop-symbol-ponyfill'
// import {generateRandomNumber} from '../../services/randomNumberService'
import actions from '../../config/actions'
import { deleteImmutableListAtIndex } from '../../utils/util'
import CONS from '../../config/constants'

const initSelectedStore = Map({
  id: 0,
  role_id: 0,

  cover_thumbnail_uri: null,
  cover_uri: null,
  created_at: null,
  deleted_at: null,
  description: null,
  disabled: null,
  img_uri: null,
  thumbnail_uri: null,
  name: null,

  // C: null,
  // product_count: null,
  // promotion: null,
  // rules: null,
  // updated_at: null,

  // s_auto_confirm_new_order_sell: true,
  // s_show_xselly_on_print: true,
  // s_shipping_calc_mode: 1,
  // s_lock_order_mod_at: 1,
  // s_lock_order_mod_at_new: 1,
  // s_default_shipping_type_by_size: null,
  // s_lock_order_mod_at_new: false,
  // s_charge_more_shipping_cost: false,
  // s_auto_confirm_new_order_buy: true,
  // s_auto_calc_shipping_cost: false,
  // s_shipping_rate_by_weight_at_count: 0
  // s_sender_addr_t1_use_mine: true,
  // s_sender_addr_t2_use_mine: false,
  s_use_retail_price: false, // default is false

  // s_use_volume_discount: true,
  // product_groups: List([]),
  // product_groups: List([
  //   Map({ id: 1, name: 'ขายปลีก', priority: 200, is_default: true }),
  //   Map({ id: 2, name: 'ตัวแทน Bronze', priority: 10 }),
  //   Map({ id: 3, name: 'ตัวแทน Silver', priority: 20 }),
  //   Map({ id: 4, name: 'ตัวแทน Gold', priority: 30 }),
  // ])
  s_use_printing_paperang: null,
  localConfigs: Map({}),
})

// Initial state
const initialState = Map({
  my: Map({
    stores: List(),
    shouldFetch: false,
    isFetching: false,
    storesDetail: Map(),
  }),

  seller: Map({
    stores: List(),
    shouldFetch: false,
    isFetching: false,
    storesDetail: Map(),
  }),

  pending: Map({
    stores: List(),
  }),

  myStoresDetail: Map(),

  // sellerStores: List(),
  // sellerShouldFetch: true,

  selectedStore: initSelectedStore,
  editingStore: initSelectedStore,
  shouldFetchSelectedStore: false,
  shouldFetchUserGroups: false,
  shouldFetchOrderSummary: false,

  selectedStoreOrdersDates: util.getDateRangeFromOption(CONS.DATERANGE_SELECTOR.OPTION.Last30Days),
  selectedStoreOrdersDatesOptionKey: CONS.DATERANGE_SELECTOR.OPTION.Last30Days,
})

// Actions
export const CLEAR_STATE = 'StoreListState/CLEAR'

export const SET_SELECTED_STORE_STATE = 'StoreListState/SET_SELECTED_STORE'
export const CLEAR_SELECTED_STORE_STATE = 'StoreListState/CLEAR_SELECTED_STORE'
export const RESET_EDITING_STORE_STATE = 'StoreListState/RESET_EDITING_STORE_STATE'

export const CHANGE_EDITING_STORE_STATE = 'StoreListState/CHANGE_EDITING_STORE_STATE'
export const MY_STORE_SUBMIT_EDITING = 'StoreListState/MY_STORE_SUBMIT_EDITING'
// export const CLEAR_MY_STORE_STATE = 'StoreListState/CLEAR_MY_STORE'

export const MY_STORES_IS_FETCH_STATE = 'StoreListState/MY_STORES_IS_FETCH'

export const SET_MY_STORE_DETAIL_STATE = 'StoreListState/SET_MY_STORE_DETAIL'
export const CLEAR_MY_STORE_DETAIL_STATE = 'StoreListState/CLEAR_MY_STORE_DETAIL'

export const ADD_NEW_CONTACT_ADDRESSES_TO_MY_STORE = 'ContactState/ADD_NEW_CONTACT_ADDRESSES_TO_MY_STORE'

export const ADD_NEW_STORE_SETTING = 'StoreState/ADD_NEW_STORE_SETTING'

export const UPDATE_STOER_SETTING = 'StoreState/UPDATE_STOER_SETTING'

// Action creators
export function createMyStore(body) {
  return { type: actions.MY_STORE_CREATE, payload: body }
}

export function findStore(body) {
  return { type: actions.FIND_STORE, payload: body }
}

export function fetchMyStores(params: { successCallback?: () => void; failedCallback?: () => void }) {
  let successCallback
  let failedCallback
  if (params) {
    successCallback = params.successCallback || null
    failedCallback = params.failedCallback || null
  }
  return { type: actions.MY_STORES_FETCH, successCallback, failedCallback }
}

export function fetchMyStore(params: { body: object; successCallback?: () => void; failedCallback?: () => void }) {
  let successCallback
  let failedCallback
  if (params) {
    successCallback = params.successCallback || null
    failedCallback = params.failedCallback || null
  }
  return {
    type: actions.MY_STORE_FETCH,
    payload: params.body,
    successCallback,
    failedCallback,
  }
}

export function fetchMyStoreInfo(params: ActionApiParams) {
  return {
    type: actions.MY_STORE_INFO_FETCH,
    ...params,
  }
}

export function addPrinting(body) {
  return {
    type: actions.ADD_PRINTING,
    body,
  }
}

export function editPrinting(body) {
  return {
    type: actions.EDIT_PRINTING,
    body,
  }
}

export function fetchSellerStores({ successCallback, failedCallback }) {
  return { type: actions.SELLER_STORES_FETCH, successCallback, failedCallback }
}

export function setMyStores(stores) {
  return { type: actions.SET_MY_STORES_STATE, payload: fromJS(stores) }
}

export function setSellerStores(stores) {
  return { type: actions.SET_SELLER_STORES_STATE, payload: fromJS(stores) }
}

export function setPendingStores(stores) {
  return { type: actions.SET_PENDING_STORES_STATE, payload: fromJS(stores) }
}
export function appendPendingStore(store) {
  return { type: actions.APPEND_PENDING_STORE_STATE, payload: fromJS(store) }
}

export function clearMyStores(stores) {
  return { type: actions.CLEAR_MY_STORE_STATE, payload: fromJS(stores) }
}

export function clearSellerStores(stores) {
  return { type: actions.CLEAR_SELLER_STORE_STATE, payload: fromJS(stores) }
}

export function clearAll() {
  return { type: CLEAR_STATE }
}

// For autofetch
// export function myStoreIsFetch() {
//   return { type: MY_STORES_IS_FETCH_STATE }
// }

// export function myStoreShouldFetch() {
//   return { type: MY_STORES_SHOULD_FETCH_STATE }
// }

export function sellerStoreIsFetch() {
  return { type: actions.SELLER_STORES_IS_FETCH_STATE }
}

export function sellerStoreShouldFetch() {
  return { type: actions.SELLER_STORES_SHOULD_FETCH_STATE }
}

// For My Store Detail
export function setMyStore(storeDetail) {
  return {
    type: SET_MY_STORE_DETAIL_STATE,
    payload: storeDetail,
  }
}

export function clearMyStore() {
  return {
    type: CLEAR_MY_STORE_DETAIL_STATE,
  }
}

// For Selected Store
export function setSelectedStore(selectedStore) {
  if (util.isOrgOwnerStore(selectedStore)) {
    setAppConfig(ORG_OWNER_APP_CONFIG)
  } else {
    setAppConfig(DEFAULT_APP_CONFIG)
  }
  return {
    type: SET_SELECTED_STORE_STATE,
    payload: fromJS(selectedStore),
  }
}

export function shouldFetchOrderSummary(value: boolean) {
  return {
    type: actions.SET_SHOULD_FETCH_ORDER_SUMMARY,
    payload: value,
  }
}

export function shouldFetchSelectedStore() {
  return {
    type: actions.SELECTED_STORE_SHOULD_FETCH,
  }
}

export function clearSelectedStore() {
  return { type: CLEAR_SELECTED_STORE_STATE }
}

export function resetEditingStore() {
  return { type: RESET_EDITING_STORE_STATE }
}

export function onChangeEditingStore(newObject) {
  return { type: CHANGE_EDITING_STORE_STATE, payload: fromJS(newObject) }
}

export function submitEditingStore(params: ActionApiParams) {
  return {
    type: MY_STORE_SUBMIT_EDITING,
    ...params,
  }
}

export function leaveFromSellerStore({ body, successCallback, failedCallback }) {
  return {
    type: actions.SELLER_STORES_LEAVE,
    payload: body,
    successCallback,
    failedCallback,
  }
}

// PG Management
export function createProductGroup(params: ActionApiParams) {
  return {
    type: actions.CREATE_PRODUCT_GROUP,
    ...params,
  }
}

export function updateProductGroup(params: ActionApiParams) {
  return {
    type: actions.UPDATE_PRODUCT_GROUP,
    ...params,
  }
}

export function deleteProductGroup(params: ActionApiParams) {
  return {
    type: actions.DELETE_PRODUCT_GROUP,
    ...params,
  }
}

export function loadProductGroups(productGroups: Array<{ [key: string]: any }>) {
  return {
    type: actions.LOAD_PRODUCT_GROUPS,
    payload: productGroups,
  }
}

export function loadProductGroupsUpdatedAt(updatedAt: string) {
  return {
    type: actions.LOAD_PRODUCT_GROUP_UPDATED_AT,
    payload: updatedAt,
  }
}

export function loadNewProductGroup(productGroup: { [key: string]: any }) {
  return {
    type: actions.LOAD_NEW_PRODUCT_GROUP,
    payload: productGroup,
  }
}

export function loadUpdatedProductGroup(productGroup: { [key: string]: any }) {
  return {
    type: actions.LOAD_UPDATED_PRODUCT_GROUP,
    payload: productGroup,
  }
}

export function clearRemovedProductGroupFromListById(pg_id: number) {
  return {
    type: actions.CLEAR_REMOVED_PRODUCT_GROUP,
    payload: pg_id,
  }
}

export function sortPrioritiesProductGroups(params: ActionApiParams) {
  return {
    type: actions.SORT_PRIORITIES_PRODUCT_GROUPS,
    ...params,
  }
}

// UG Management
export function createUserGroup(params: ActionApiParams) {
  return {
    type: actions.CREATE_USER_GROUP,
    ...params,
  }
}

export function updateUserGroup(params: ActionApiParams) {
  return {
    type: actions.UPDATE_USER_GROUP,
    ...params,
  }
}

export function deleteUserGroup(params: ActionApiParams) {
  return {
    type: actions.DELETE_USER_GROUP,
    ...params,
  }
}

export function resetUserGroupInviteCode(params: ActionApiParams) {
  return {
    type: actions.RESET_USER_GROUP_INVITE_CODE,
    ...params,
  }
}

export function generateUserGroupInviteLink(params: ActionApiParams) {
  return {
    type: actions.GENERATE_USER_GROUP_INVITE_LINK,
    ...params,
  }
}

export function shouldFetchUserGroups() {
  return {
    type: actions.SHOULD_FETCH_USER_GROUPS,
  }
}

export function loadUserGroups(userGroups: Array<{ [key: string]: any }>) {
  return {
    type: actions.LOAD_USER_GROUPS,
    payload: userGroups,
  }
}

export function loadNewUserGroup(userGroup: { [key: string]: any }) {
  return {
    type: actions.LOAD_NEW_USER_GROUP,
    payload: userGroup,
  }
}

export function loadUpdatedUserGroup(userGroup: { [key: string]: any }) {
  return {
    type: actions.LOAD_UPDATED_USER_GROUP,
    payload: userGroup,
  }
}

export function clearRemovedUserGroupFromListById(ug_id: number) {
  return {
    type: actions.CLEAR_REMOVED_USER_GROUP,
    payload: ug_id,
  }
}

// VD createVolumeDiscount
export function createVolumeDiscount(params: ActionApiParams) {
  return {
    type: actions.CREATE_VOLUME_DISCOUNT,
    ...params,
  }
}

export function updateVolumeDiscount(params: ActionApiParams) {
  return {
    type: actions.UPDATE_VOLUME_DISCOUNT,
    ...params,
  }
}

export function updateNewVD(vd: any) {
  return {
    type: actions.UPDATE_NEW_VOLUME_DISCOUNT,
    payload: vd,
  }
}

export function deleteVolumeDiscount(vd: ActionApiParams) {
  return {
    type: actions.DELETE_VOLUME_DISCOUNT,
    payload: vd,
  }
}

export function clearRemovedVolumeDiscountListById(id: object) {
  return {
    type: actions.CLEAR_REMOVED_VOLUME_DISCOUNT,
    payload: id,
  }
}

// FIXME: O: ประกาศ types ให้ละเอียดกว่านี้
export function loadListVolumeDiscounts(body: object) {
  return {
    type: actions.LOAD_VOLUME_DISCOUNTS,
    payload: body,
  }
}

export function fetchVolumeDiscounts(vds: any) {
  return {
    type: actions.FETCH_VOLUME_DISCOUNTS,
    payload: vds,
  }
}

export function addVDBeforeCreateVolumeDiscount(vd: any) {
  return {
    type: actions.LOAD_NEW_VOLUME_DISCOUNTS,
    payload: vd,
  }
}

// FIXME: O: ประกาศ types ให้ละเอียดกว่านี้
export function pairVolumeDiscount(body: object) {
  return {
    type: actions.PAIR_VOLUME_DISCOUNT,
    payload: body,
  }
}

// FIXME: O: ประกาศ types ให้ละเอียดกว่านี้
export function unpairVolumeDiscount(body: object) {
  return {
    type: actions.UNPAIR_VOLUME_DISCOUNT,
    payload: body,
  }
}

export function updateContInVolumeDiscounts(data: Array<{ vd_id: number; pg_id: number }>) {
  return {
    payload: data,
    type: actions.UPDATE_CONT_IN_VOLUME_DISCOUNTS,
  }
}

// Apply Invite Code for reseller
export function applyInviteCode(params: ActionApiParams) {
  // body = { invite_code: '12345', isConfirm: true }
  return {
    type: actions.APPLY_INVITE_CODE,
    ...params,
  }
}

// Fetch List
export function fetchUserGroups(params: ActionApiParams) {
  return { type: actions.FETCH_USER_GROUPS, ...params }
}

export function fetchProductGroups(params: ActionApiParams) {
  return { type: actions.FETCH_PRODUCT_GROUPS, ...params }
}

// export function addReseller(data) {
//   return { type: actions.MY_STORE_ADD_RESELLER, payload: data }
// }

// UserGroup assign pgs to the group
export function saveSyncProductGroupsToUserGroup(params: ActionApiParams) {
  return {
    type: actions.ASSIGN_PGS_TO_USER_GROUP,
    ...params,
  }
}

export function doneAssignedPgsToUserGroup(params: ActionApiParams) {
  return {
    ...params,
    type: actions.DONE_ASSIGN_PGS_TO_USER_GROUP,
  }
}

export function fetchInitializeData(params: ActionApiParams) {
  return {
    ...params,
    type: actions.FETCH_INITIALIZE_DATA,
  }
}

export function updatePrintingPaperang(payload) {
  return {
    payload,
    type: actions.UPDATE_PRINTING_PAPERANG,
  }
}

export function updatePrintingPDF(payload) {
  return {
    payload,
    type: actions.UPDATE_PRINTING_PDF,
  }
}

export function updatePrintingBillPaperang(payload) {
  return {
    payload,
    type: actions.UPDATE_PRINTING_BILL_PAPERANG,
  }
}

export function updatePrintingStickerPDF(payload) {
  return {
    payload,
    type: actions.UPDATE_PRINTING_STICKER_PDF,
  }
}

export function updatePrintingBarcode(payload) {
  return {
    payload,
    type: actions.UPDATE_PRINTING_BARCODE,
  }
}

export function addNewContactAddressesToMyStore(addresses) {
  return {
    type: ADD_NEW_CONTACT_ADDRESSES_TO_MY_STORE,
    payload: isImmutable(addresses) ? addresses : fromJS(addresses),
  }
}

export function updateXShippingBalance(params: ActionApiParams) {
  return {
    ...params,
    type: actions.UPDATE_XSHIPPING_BALANCE,
  }
}

export function fetchOrderSummary({ body, successCallback, failedCallback }) {
  return {
    type: actions.ORDERS_SUMMARY_FETCH,
    payload: body,
    successCallback,
    failedCallback,
  }
}

export function updateOrderSummary(body: object) {
  return {
    type: actions.UPDATE_ORDER_SUMMARY,
    payload: fromJS(body),
  }
}

export function getPermissionMemberList(params: ActionApiParams) {
  return {
    ...params,
    type: actions.GET_PERMISSION_MEMBER_LUST,
  }
}

export function getPermissionList(params: ActionApiParams) {
  return {
    ...params,
    type: actions.GET_PERMISSION_LIST,
  }
}

export function changePermission(params: ActionApiParams) {
  return {
    ...params,
    type: actions.CHANGE_PERMISSION,
  }
}

export function deleteHelperFromPermission(params: ActionApiParams) {
  return {
    ...params,
    type: actions.DELETE_HELPER_FROM_PERMISSION,
  }
}

export function addHelper(params: ActionApiParams) {
  return {
    ...params,
    type: actions.ADD_HELPER,
  }
}

export function addPermisson(params: ActionApiParams) {
  return {
    ...params,
    type: actions.ADD_PERMISSION,
  }
}

export function editPermisson(params: ActionApiParams) {
  return {
    ...params,
    type: actions.EDIT_PERMISSION,
  }
}

export function deletePermission(params: ActionApiParams) {
  return {
    ...params,
    type: actions.DELETE_PERMISSION,
  }
}

export function shopeeAuthUrl(params: ActionApiParams) {
  return {
    type: actions.SHOPEE_AUTH_URL,
    payload: params,
  }
}

export function mkpReAuthUrl(params: ActionApiParams) {
  return {
    type: actions.MKP_RE_AUTH_URL,
    payload: params,
  }
}

export function countResellers(params: ActionApiParams) {
  return {
    type: actions.COUNT_RESELLERS,
    payload: params,
  }
}

export function updateNewChannelList(params: any) {
  return { type: actions.UPDATE_NEW_CHANNEL_LIST, payload: params }
}

export function getChannel(params: ActionApiParams) {
  return {
    type: actions.GET_CHANNEL,
    payload: params,
  }
}

export function getChannels(params: ActionApiParams) {
  return {
    type: actions.GET_CHANNELS,
    payload: params,
  }
}

export function setPrefTimeslot(params: ActionApiParams) {
  return {
    type: actions.SET_PREF_TIMESLOT,
    payload: params,
  }
}

export function getWarehouses(params: ActionApiParams) {
  return {
    type: actions.GET_WAREHOOUSE,
    payload: params,
  }
}

export function createWarehouse(params: ActionApiParams) {
  return {
    type: actions.CREATE_WAREHOOUSE,
    payload: params,
  }
}

export function updateWarehouse(params: ActionApiParams) {
  return {
    type: actions.UPDATE_WAREHOOUSE,
    payload: params,
  }
}

export function deleteWarehouse(params: ActionApiParams) {
  return {
    type: actions.DELETE_WAREHOOUSE,
    payload: params,
  }
}

export function updateReduxNewWarehouseList(params: any) {
  return { type: actions.UPDATE_REDUX_NEW_WARHOUSE_LIST, payload: params }
}

export function updateChannel(params: ActionApiParams) {
  return {
    type: actions.UPDATE_MKP_CHANNEL,
    ...params,
  }
}

export function deleteChannel(params: ActionApiParams) {
  return {
    type: actions.DELETE_DELETE,
    ...params,
  }
}

export function fetchChannelProductByPT(params: ActionApiParams) {
  return {
    type: actions.FETCH_CHANNEL_PRODUCT_BY_PT,
    ...params,
  }
}

export function mergeMyStoreInfo(newStoreInfoData: { [key: string]: any }) {
  return {
    type: actions.MERGE_MY_STORE_INFO,
    payload: newStoreInfoData,
  }
}

export function syncBackgroundJobs(params: ActionApiParams) {
  return {
    type: actions.ACTION_SYNC_MKP_BG_JOB,
    payload: params,
  }
}

export function syncMKPBackgroungTasks(params: ActionApiParams) {
  // API NOT READY !!
  return {
    type: actions.ACTION_SYNC_MKP_BACKGROUNG_TASKS,
    payload: params,
  }
}

export function syncShopInfo(params: ActionApiParams) {
  return {
    type: actions.ACTION_SYNC_SHOP_INFO,
    payload: params,
  }
}

export function syncMKPProduct(params: ActionApiParams) {
  return {
    type: actions.ACTION_SYNC_MKP_PRODUCT,
    payload: params,
  }
}

export function syncMKPOrder(params: ActionApiParams) {
  return {
    type: actions.ACTION_SYNC_MKP_ORDER,
    payload: params,
  }
}

export function orderCount(params: ActionApiParams) {
  return {
    type: actions.ACTION_ORDER_COUNT,
    payload: params,
  }
}

export function autoCompleteBySegment(params: ActionApiParams) {
  return {
    type: actions.ACTION_AUTO_COMPLETE_BY_SEGMENT,
    payload: params,
  }
}

export function addNewStoreSetting(storeSetting) {
  // console.log('storeSetting => ', storeSetting)
  return {
    type: ADD_NEW_STORE_SETTING,
    payload: isImmutable(storeSetting) ? storeSetting : fromJS(storeSetting),
  }
}

export function setEditingStoreGoApi(params: ActionApiParams) {
  return {
    type: actions.SET_EDITING_STORE_GO_API,
    payload: params,
  }
}

export function addStoreSettingGoApi(params: ActionApiParams) {
  return {
    type: actions.ADD_STORE_SETTING_GO_API,
    payload: params,
  }
}

export function setEditingStoreSetting(stoerSetting) {
  return {
    type: UPDATE_STOER_SETTING,
    payload: isImmutable(stoerSetting) ? stoerSetting : fromJS(stoerSetting),
  }
}

export function setSelectedStoreOrdersDates(params: ISetSelectedStoreOrdersDatesParams) {
  return {
    type: actions.SET_SELECTED_STORE_ORDERS_DATES,
    payload: params,
  }
}

export function getErpChannels(params: ActionApiParams) {
  return {
    type: actions.GET_ERP_CHANNELS,
    payload: params,
  }
}

export function updateNewErpChannelList(params: any) {
  return { type: actions.UPDATE_NEW_ERP_CHANNEL_LIST, payload: params }
}

export function updateSelectedStoreByKey(key: string, value: any) {
  return { type: actions.UPDATE_SELECTED_STORE_BY_KEY, payload: { key, value } }
}

export function getProductCount(params: ActionApiParams) {
  return {
    type: actions.GET_PRODUCT_COUNT,
    payload: params,
  }
}

export function updateProductCount(params: number) {
  return { type: actions.UPDATE_PRODUCT_COUNT, payload: params }
}

// Reducer
export default function StoreListStateReducer(state = initialState, action: ActionApiParams = {}) {
  const { type, payload } = action
  let newState = state
  // console.log('newState.toJS()', newState.toJS())
  switch (type) {
    case CLEAR_STATE:
      return initialState
    case actions.SET_MY_STORES_STATE: {
      let s = setMyStoresIsFetchingInStores(state, false)
      s = setMyStoresShouldFetchInStores(s, false)
      return setMyStoresInStores(s, payload)
    }
    case actions.SET_MY_STORES_IS_FETCHING:
      return setMyStoresIsFetchingInStores(state, payload)
    case actions.CLEAR_MY_STORE_STATE:
      return state.set('myStores', fromJS([]))
    case MY_STORES_IS_FETCH_STATE:
      return state.set('myShouldFetch', false)
    case SET_MY_STORE_DETAIL_STATE:
      return state.mergeDeepIn(['myStoresDetail'], payload)
    case CLEAR_MY_STORE_DETAIL_STATE:
      return state.setIn(['myStoresDetail'], Map())

    // case MY_STORES_SHOULD_FETCH_STATE:
    //   return state.set('myShouldFetch', true)

    case actions.SET_SELLER_STORES_STATE: {
      let s = setSellerStoresIsFetchingInStores(state, false)
      s = setSellerStoresShouldFetchInStores(s, false)
      return setSellerStoresInStores(s, payload)
    }
    case actions.SET_SELLER_STORES_IS_FETCHING:
      return setSellerStoresIsFetchingInStores(state, payload)
    case actions.CLEAR_SELLER_STORE_STATE:
      return state.set('sellerStores', fromJS([]))

    case actions.SELLER_STORES_IS_FETCH_STATE:
      return setSellerStoresShouldFetchInStores(newState, false)
    case actions.SELLER_STORES_SHOULD_FETCH_STATE:
      return setSellerStoresShouldFetchInStores(newState, true)

    case SET_SELECTED_STORE_STATE:
      newState = setShouldFetchSelectedStoreInStores(newState, false)
      // newState = newState.set('selectedStore', payload)
      // // FIXME: MergeDeep cause custom mocking data
      // newState = newState.set('selectedStore', initSelectedStore.mergeDeep(payload))
      // return newState.set('editingStore', payload)
      newState = setSelectedStoreInStores(newState, payload)
      newState = sortSelectedProductGroupsByPriority(newState)
      newState = sortSelectedUserGroupsByName(newState)
      newState = computeInitialShippingRateConfig(newState)
      // newState = changeVariantsVolumeDiscount(newState)
      const newLoadedSelectedStore = getSelectedStoreInStores(newState)
      return setEditingStoreInStores(newState, newLoadedSelectedStore)

    case actions.SET_SHOULD_FETCH_ORDER_SUMMARY:
      return setShouldFetchOrderSummaryInStores(newState, payload)

    case actions.UPDATE_ORDER_SUMMARY:
      newState = setOrderSummaryToSelectedStore(newState, fromJS(payload))
      newState = setOrderSummaryToEdittingStore(newState, fromJS(payload))
      // newState = newState.setIn(['editingStore', 'order_summary'], payload)
      // newState = newState.setIn(['selectedStore', 'order_summary'], payload)
      return newState

    case actions.SELECTED_STORE_SHOULD_FETCH:
      return setShouldFetchSelectedStoreInStores(newState, true)

    case CLEAR_SELECTED_STORE_STATE:
      newState = newState.set('selectedStore', initSelectedStore)
      return newState.set('editingStore', initSelectedStore)

    case RESET_EDITING_STORE_STATE:
      return newState.set('editingStore', newState.get('selectedStore'))
    case CHANGE_EDITING_STORE_STATE:
      return newState.mergeDeepIn(['editingStore'], payload)

    case actions.LOAD_PRODUCT_GROUPS:
      newState = setProductGroupsInStores(newState, fromJS(payload))
      newState = sortSelectedProductGroupsByPriority(newState)
      const newLoadedPGSelectedStore = getSelectedStoreInStores(newState)
      return setEditingStoreInStores(newState, newLoadedPGSelectedStore)
    case actions.LOAD_PRODUCT_GROUP_UPDATED_AT:
      newState = setProductGroupsUpdatedAtInStores(newState, payload)
      const newLoadedUpdatedAtSelectedStore = getSelectedStoreInStores(newState)
      return setEditingStoreInStores(newState, newLoadedUpdatedAtSelectedStore)
    case actions.LOAD_NEW_PRODUCT_GROUP:
      return setNewProductGroupToList(newState, fromJS(payload))
    case actions.LOAD_UPDATED_PRODUCT_GROUP:
      return setUpdatedProductGroupToList(newState, fromJS(payload))
    case actions.CLEAR_REMOVED_PRODUCT_GROUP:
      return clearRemovedProductGroupToList(newState, payload)

    case actions.LOAD_USER_GROUPS:
      newState = setUserGroupsInStores(newState, fromJS(payload))
      newState = sortSelectedUserGroupsByName(newState)
      const newLoadedUGSelectedStore = getSelectedStoreInStores(newState)
      return setEditingStoreInStores(newState, newLoadedUGSelectedStore)
    case actions.LOAD_NEW_USER_GROUP:
      return setNewUserGroupToList(newState, fromJS(payload))
    case actions.LOAD_UPDATED_USER_GROUP:
      return setUpdatedUserGroupToList(newState, fromJS(payload))
    case actions.CLEAR_REMOVED_USER_GROUP:
      return clearRemovedUserGroupToList(newState, payload)

    case actions.SET_PENDING_STORES_STATE: {
      let myPendingStores = payload
      for (let i = 0; i < myPendingStores.size; i++) {
        myPendingStores = myPendingStores.setIn([i, 'isPending'], true)
      }
      return setPendingStoresInStores(newState, myPendingStores)
    }

    case actions.APPEND_PENDING_STORE_STATE: {
      let newPendingStore = payload
      newPendingStore = newPendingStore.set('isPending', true)
      let myPendingStores = getPendingStoresInStores(newState)
      myPendingStores = myPendingStores.push(newPendingStore)
      return setPendingStoresInStores(newState, myPendingStores)
    }
    case actions.DONE_ASSIGN_PGS_TO_USER_GROUP:
      return doDoneAssignedPgsToUserGroup(newState, action)

    case actions.SHOULD_FETCH_USER_GROUPS:
      return setShouldFetchUserGroupsInStores(newState, true)

    case actions.UPDATE_NEW_VOLUME_DISCOUNT: {
      const vds = newState.getIn(['selectedStore', 'vds'])
      const vd_id = payload.id
      const idx = vds.findIndex((vd: Map<string, any>) => vd.get('id') === vd_id)
      if (idx === -1) {
        return newState
      }
      payload.variants.forEach((variant, idx) => {
        variant.begin = variant.min
        variant.end = variant.max
        variant.discount = variant.val
      })
      newState = newState.setIn(['selectedStore', 'vds', idx], fromJS(payload))
      return newState
    }

    case actions.CLEAR_REMOVED_VOLUME_DISCOUNT: {
      const vds = newState.getIn(['selectedStore', 'vds'])
      const { vd_id } = payload
      const idx = vds.findIndex((vd: Map<string, any>) => vd.get('id') === vd_id)
      if (idx === -1) {
        return newState
      }
      const newVds = deleteImmutableListAtIndex(vds, idx) // DELETE
      newState = newState.setIn(['selectedStore', 'vds'], newVds)
      return newState
    }

    case actions.FETCH_VOLUME_DISCOUNTS: {
      const newVds = payload
      // newVds.forEach((vdsItem, index) => {
      //   vdsItem.variants.forEach((variant, idx) => {
      //     newVds[index].variants[idx].begin = variant.min
      //     newVds[index].variants[idx].end = variant.max
      //     newVds[index].variants[idx].discount = variant.val
      //   })
      // })
      newState = newState.setIn(['selectedStore', 'vds'], fromJS(newVds))
      return newState
    }

    case actions.LOAD_NEW_VOLUME_DISCOUNTS: {
      const newVD = payload.volume_discount
      // newVD.variants.map((val, index) => {
      //   newVD.variants[index].begin = val.min
      //   newVD.variants[index].end = val.max
      //   newVD.variants[index].discount = val.val
      // })
      const vds = newState.getIn(['selectedStore', 'vds'])
      const newVds = vds.push(fromJS(newVD))
      newState = newState.setIn(['selectedStore', 'vds'], newVds)
      return newState
    }
    case actions.UPDATE_CONT_IN_VOLUME_DISCOUNTS: {
      newState = newState.setIn(['editingStore', 'vds'], fromJS(payload))
      return newState
    }

    case actions.UPDATE_PRINTING_PAPERANG:
      newState = newState.setIn(['selectedStore', 'printing_paperang'], fromJS(payload))
      newState = newState.setIn(['editingStore', 'printing_paperang'], fromJS(payload))
      newState = newState.setIn(['selectedStore', 's_use_printing_paperang'], fromJS(payload.a))
      newState = newState.setIn(['editingStore', 's_use_printing_paperang'], fromJS(payload.a))
      return newState

    case actions.UPDATE_PRINTING_PDF:
      newState = newState.setIn(['selectedStore', 'printing_pdf'], fromJS(payload))
      newState = newState.setIn(['editingStore', 'printing_pdf'], fromJS(payload))
      newState = newState.setIn(['selectedStore', 's_use_printing_pdf'], fromJS(payload.a))
      newState = newState.setIn(['editingStore', 's_use_printing_pdf'], fromJS(payload.a))
      return newState

    case actions.UPDATE_PRINTING_BILL_PAPERANG:
      newState = newState.setIn(['selectedStore', 'printing_bill_paperang'], fromJS(payload))
      newState = newState.setIn(['editingStore', 'printing_bill_paperang'], fromJS(payload))
      newState = newState.setIn(['selectedStore', 's_use_printing_bill_paperang'], fromJS(payload.a))
      newState = newState.setIn(['editingStore', 's_use_printing_bill_paperang'], fromJS(payload.a))
      return newState

    case actions.UPDATE_PRINTING_STICKER_PDF:
      newState = newState.setIn(['selectedStore', 'printing_sticker_pdf'], fromJS(payload))
      newState = newState.setIn(['editingStore', 'printing_sticker_pdf'], fromJS(payload))
      newState = newState.setIn(['selectedStore', 's_use_printing_sticker_pdf'], fromJS(payload.a))
      newState = newState.setIn(['editingStore', 's_use_printing_sticker_pdf'], fromJS(payload.a))
      return newState

    case actions.UPDATE_PRINTING_BARCODE:
      newState = newState.setIn(['selectedStore', 'printing_barcode'], fromJS(payload))
      newState = newState.setIn(['editingStore', 'printing_barcode'], fromJS(payload))
      return newState

    case ADD_NEW_CONTACT_ADDRESSES_TO_MY_STORE:
      newState = newState.setIn(['selectedStore', 'addresses'], payload)
      return newState.setIn(['editingStore', 'addresses'], payload)

    case ADD_NEW_STORE_SETTING:
      const s_auto_calc_shipping_type_ids = payload.has('ui_show_only_shipping_type_ids')
        ? payload.get('ui_show_only_shipping_type_ids')
        : null
      if (!_.isNil(s_auto_calc_shipping_type_ids)) {
        newState = newState.setIn(['selectedStore', 's_auto_calc_shipping_type_ids'], s_auto_calc_shipping_type_ids)
        newState = newState.setIn(['editingStore', 's_auto_calc_shipping_type_ids'], s_auto_calc_shipping_type_ids)
      }
      newState = newState.setIn(['selectedStore', 'store_setting'], payload)
      return newState.setIn(['editingStore', 'store_setting'], payload)

    case UPDATE_STOER_SETTING:
      newState = newState.setIn(['selectedStore', 'store_setting'], payload)
      return newState.setIn(['editingStore', 'store_setting'], payload)

    case actions.UPDATE_NEW_CHANNEL_LIST:
      // console.log(`payload => `, payload)
      newState = newState.setIn(['selectedStore', 'channels'], fromJS(payload))
      return newState.setIn(['editingStore', 'channels'], fromJS(payload))

    case actions.UPDATE_REDUX_NEW_WARHOUSE_LIST:
      // console.log(`payload => `, payload)
      newState = newState.setIn(['selectedStore', 'warehouses'], fromJS(payload))
      return newState.setIn(['editingStore', 'warehouses'], fromJS(payload))

    case actions.MERGE_MY_STORE_INFO:
      const newStoreData = payload
      const newDataKeys = Object.keys(newStoreData)
      for (const key of newDataKeys) {
        newState = newState.setIn(['selectedStore', key], fromJS(newStoreData[key]))
        newState = newState.setIn(['editingStore', key], fromJS(newStoreData[key]))
      }
      return newState

    case actions.SET_SELECTED_STORE_ORDERS_DATES: {
      newState = newState.set('selectedStoreOrdersDates', payload.selectedStoreOrdersDates)
      newState = newState.set('selectedStoreOrdersDatesOptionKey', payload.selectedStoreOrdersDatesOptionKey)
      return newState
    }

    case actions.UPDATE_NEW_ERP_CHANNEL_LIST:
      // console.log(`payload => `, payload)
      newState = newState.setIn(['selectedStore', 'erp_channels'], fromJS(payload))
      return newState.setIn(['editingStore', 'erp_channels'], fromJS(payload))

    case actions.UPDATE_SELECTED_STORE_BY_KEY:
      // console.log(`payload => `, payload)
      newState = newState.setIn(['selectedStore', payload.key], fromJS(payload.value))
      return newState.setIn(['editingStore', payload.key], fromJS(payload.value))

    case actions.UPDATE_PRODUCT_COUNT:
      newState = newState.setIn(['selectedStore', 'product_count'], fromJS(payload))
      return newState.setIn(['editingStore', 'product_count'], fromJS(payload))

    default:
      return state
  }
}

// Internal PG Compute
function sortSelectedProductGroupsByPriority(state: Map<string, any>): Map<string, any> {
  const newState = state
  let selectedProductGroups = getProductGroupsInStores(newState)
  if (!List.isList(selectedProductGroups)) {
    return state
  }
  selectedProductGroups = selectedProductGroups.sort((pgA, pgB) => {
    const priorityA = pgA.get('priority')
    const priorityB = pgB.get('priority')
    if (priorityA > priorityB) {
      // for desc
      return -1
    }
    if (priorityA < priorityB) {
      return 1
    }

    const idA = pgA.get('id')
    const idB = pgB.get('id')
    if (idA < idB) {
      // for asc
      return -1
    }
    if (priorityA < priorityB) {
      return 1
    }

    // Should be impossible
    return 0
  })

  return setProductGroupsInStores(newState, selectedProductGroups)
}

function setNewProductGroupToList(state: Map<string, any>, newProductGroup: Map<string, any>): Map<string, any> {
  let newState = state
  let selectedProductGroups = getProductGroupsInStores(newState)
  const pg_id = newProductGroup.get('id')
  if (!pg_id) {
    return state
  }
  selectedProductGroups = selectedProductGroups.push(newProductGroup)
  newState = setProductGroupsInStores(newState, selectedProductGroups)
  return sortSelectedProductGroupsByPriority(newState)
}

function setUpdatedProductGroupToList(state: Map<string, any>, newProductGroup: Map<string, any>): Map<string, any> {
  let newState = state
  let selectedProductGroups = getProductGroupsInStores(newState)
  const pg_id = newProductGroup.get('id')
  if (!pg_id) {
    return state
  }
  const foundProductGroupIndex = selectedProductGroups.findIndex((pg) => Map.isMap(pg) && pg.get('id') === pg_id)
  if (foundProductGroupIndex < 0) {
    return state
  }
  selectedProductGroups = selectedProductGroups.set(foundProductGroupIndex, newProductGroup)
  newState = setProductGroupsInStores(newState, selectedProductGroups)
  return sortSelectedProductGroupsByPriority(newState)
}

function clearRemovedProductGroupToList(state: Map<string, any>, pg_id: number): Map<string, any> {
  const newState = state
  let selectedProductGroups = getProductGroupsInStores(newState)
  if (!pg_id) {
    return state
  }

  const foundProductGroupIndex = selectedProductGroups.findIndex((pg) => Map.isMap(pg) && pg.get('id') === pg_id)
  if (foundProductGroupIndex < 0) {
    return state
  }
  selectedProductGroups = deleteImmutableListAtIndex(selectedProductGroups, foundProductGroupIndex)
  return setProductGroupsInStores(newState, selectedProductGroups)
}

// Internal UG Compute
function sortSelectedUserGroupsByName(state: Map<string, any>): Map<string, any> {
  const newState = state
  let selectedUserGroups = getUserGroupsInStores(newState)
  if (!List.isList(selectedUserGroups)) {
    return state
  }
  selectedUserGroups = selectedUserGroups.sortBy((ug) => ug.get('name'))
  return setUserGroupsInStores(newState, selectedUserGroups)
}

function setNewUserGroupToList(state: Map<string, any>, newUserGroup: Map<string, any>): Map<string, any> {
  let newState = state
  let selectedUserGroups = getUserGroupsInStores(newState)
  const pg_id = newUserGroup.get('id')
  if (!pg_id) {
    return state
  }
  selectedUserGroups = selectedUserGroups.push(newUserGroup)
  newState = setUserGroupsInStores(newState, selectedUserGroups)
  return sortSelectedUserGroupsByName(newState)
}

function setUpdatedUserGroupToList(state: Map<string, any>, newUserGroup: Map<string, any>): Map<string, any> {
  let newState = state
  let selectedUserGroups = getUserGroupsInStores(newState)
  const ug_id = newUserGroup.get('id')
  if (!ug_id) {
    return state
  }
  const foundUserGroupIndex = selectedUserGroups.findIndex((ug) => Map.isMap(ug) && ug.get('id') === ug_id)
  if (foundUserGroupIndex < 0) {
    return state
  }
  const mergedUserGroup = selectedUserGroups.get(foundUserGroupIndex).mergeDeep(newUserGroup)
  selectedUserGroups = selectedUserGroups.set(foundUserGroupIndex, mergedUserGroup)
  // selectedUserGroups = selectedUserGroups.set(foundUserGroupIndex, newUserGroup)
  newState = setUserGroupsInStores(newState, selectedUserGroups)
  return sortSelectedUserGroupsByName(newState)
}

function clearRemovedUserGroupToList(state: Map<string, any>, ug_id: number): Map<string, any> {
  const newState = state
  let selectedUserGroups = getUserGroupsInStores(newState)
  if (!ug_id) {
    return state
  }

  const foundUserGroupIndex = selectedUserGroups.findIndex((ug) => Map.isMap(ug) && ug.get('id') === ug_id)
  if (foundUserGroupIndex < 0) {
    return state
  }
  selectedUserGroups = deleteImmutableListAtIndex(selectedUserGroups, foundUserGroupIndex)
  return setUserGroupsInStores(newState, selectedUserGroups)
}

// ใช้สร้าง shipping rate config ไว้เพื่อรู้ว่า ProductView จะ render shipping rate อะไรบ้าง
function computeInitialShippingRateConfig(state: Map<string, any>): Map<string, any> {
  const selectedStore = getSelectedStoreInStores(state)
  const canAutoCalcShippingRateByQty = computeCanSelectedStoreAutoCalcShippingRateBy(
    selectedStore,
    CONS.STORE_SETTINGS.CALC_SHIPPING_MEASURE_QTY
  )
  const canAutoCalcShippingRateByWeight = computeCanSelectedStoreAutoCalcShippingRateBy(
    selectedStore,
    CONS.STORE_SETTINGS.CALC_SHIPPING_MEASURE_WEIGHT
  )
  // log('computeInitialShippingRateConfig state.toJS() => ', state.toJS())
  let currentConfigs = getLocalConfigsInStores(state)
  // log('computeInitialShippingRateConfig currentConfigs => ', currentConfigs)
  currentConfigs = currentConfigs.set(CONS.SELECTED_STORE_LOCAL_CONFIGS.CAN_AUTO_CALC_SHIPPING_RATE_BY_QTY, canAutoCalcShippingRateByQty)
  currentConfigs = currentConfigs.set(
    CONS.SELECTED_STORE_LOCAL_CONFIGS.CAN_AUTO_CALC_SHIPPING_RATE_BY_WEIGHT,
    canAutoCalcShippingRateByWeight
  )

  return setLocalConfigsInStores(state, currentConfigs)
}

// function changeVariantsVolumeDiscount(state: Map<string, any>): Map<string, any> {
//   return
// }

function computeCanSelectedStoreAutoCalcShippingRateBy(selectedStore: Map<string, any>, measure: number): boolean {
  const {
    AUTO_CALC_SHIPPING_COST,
    SHIPPING_CALC_MODE,
    SHIPPING_CALC_MODE_QTY,
    SHIPPING_CALC_MODE_WEIGHT,
    SHIPPING_CALC_MODE_QTY_AND_WEIGHT,
    CALC_SHIPPING_MEASURE_QTY,
    CALC_SHIPPING_MEASURE_WEIGHT,
  } = CONS.STORE_SETTINGS

  if (
    !Map.isMap(selectedStore) ||
    !selectedStore.has(AUTO_CALC_SHIPPING_COST) ||
    !selectedStore.get(AUTO_CALC_SHIPPING_COST) ||
    !selectedStore.has(SHIPPING_CALC_MODE)
  ) {
    return false
  }
  const calcMode = selectedStore.get(SHIPPING_CALC_MODE)
  if (measure === CALC_SHIPPING_MEASURE_QTY && (calcMode === SHIPPING_CALC_MODE_QTY || calcMode === SHIPPING_CALC_MODE_QTY_AND_WEIGHT)) {
    return true
  }
  if (
    measure === CALC_SHIPPING_MEASURE_WEIGHT &&
    (calcMode === SHIPPING_CALC_MODE_WEIGHT || calcMode === SHIPPING_CALC_MODE_QTY_AND_WEIGHT)
  ) {
    return true
  }
  return false
}

function doDoneAssignedPgsToUserGroup(state: Map<string, any>, action: ActionApiParams): Map<string, any> {
  // log('doDoneAssignedPgsToUserGroup action => ', action)
  const { body } = action
  // log('doDoneAssignedPgsToUserGroup body => ', body)
  if (!body || !body.ug_id || !body.pgs) {
    return state
  }
  let newState = state
  const { ug_id, pgs } = body
  let selectedUserGroups = getUserGroupsInStores(newState)
  const foundUserGroupIndex = selectedUserGroups.findIndex((ug) => Map.isMap(ug) && ug.get('id') === ug_id)
  if (foundUserGroupIndex === -1) {
    return state
  }

  let newPgs = fromJS(pgs)
  newPgs.forEach((pg, index) => {
    newPgs = newPgs.setIn([index, 'id'], pg.get('pg_id'))
  })
  selectedUserGroups = selectedUserGroups.setIn([foundUserGroupIndex, 'pgs'], newPgs)
  newState = setUserGroupsInStores(newState, selectedUserGroups)
  return sortSelectedUserGroupsByName(newState)
}
