import React from 'react'
import BaseXProfile from 'x/components/BaseXProfile'
import { Image, Text, View, TouchableOpacity, Linking } from 'react-native'

import { COLORS, STYLES } from 'x/config/styles'
import _ from 'lodash'
import CONS from 'x/config/constants'
import p from 'x/config/platform-specific'
import XCustomHeader from 'xui/components/XCustomHeader'
import { IFormProfileInputs, ISocialProfile } from 'x/index'
import XInput from 'xui/components/XInput'
import * as util from 'x/utils/util'
import XIcon from 'xui/components/XIcon'
import settings from 'x/config/settings'
import XContent from './XContent'
import XContainer from './XContainer'

const { VIEW, EDIT } = CONS.MODE
const inputType = 'input'
const radioType = 'radio'

const formInputs = [
  // {
  //   key: 'codeId',
  //   txtLabel: 'รหัสตัวแทน',
  //   inputType: inputType,
  //   validationFunction: null,
  //   canEdit: false,
  // },
  {
    key: 'nickName',
    txtLabel: 'ชื่อเล่น',
    inputType,
    txtPlaceholder: 'ระบุชื่อเล่น',
    keyboardType: 'default',
    inputMaxLength: 50,
    validationFunction: null,
    canEdit: true,
  },
  {
    key: 'firstName',
    txtLabel: 'ชื่อ',
    inputType,
    txtPlaceholder: 'ระบุชื่อ',
    keyboardType: 'default',
    inputMaxLength: 50,
    validationFunction: null,
    canEdit: true,
  },
  {
    key: 'lastName',
    txtLabel: 'นามสกุล',
    inputType,
    txtPlaceholder: 'ระบุนามสกุล',
    keyboardType: 'default',
    inputMaxLength: 50,
    validationFunction: null,
    canEdit: true,
  },
  {
    key: 'gender',
    txtLabel: 'เพศ',
    inputType: radioType,
    validationFunction: null,
    options: [
      { label: 'ชาย', value: 'male' },
      { label: 'หญิง', value: 'female' },
      { label: 'ไม่ระบุ', value: 'n/a' },
    ],
  },
  {
    key: 'phone',
    txtLabel: 'เบอร์โทรหลัก',
    inputType,
    txtPlaceholder: 'ระบุเบอร์โทรศัพท์',
    keyboardType: 'phone-pad',
    inputMaxLength: 50,
    validationFunction: null,
    canEdit: true,
  },
  {
    key: 'phone2',
    txtLabel: 'เบอร์โทรรอง',
    inputType,
    txtPlaceholder: 'ระบุเบอร์โทรศัพท์สำรอง',
    keyboardType: 'phone-pad',
    inputMaxLength: 50,
    validationFunction: null,
    canEdit: true,
  },
  {
    key: 'email',
    txtLabel: 'อีเมล',
    inputType,
    txtPlaceholder: 'ระบุนอีเมล',
    keyboardType: 'default',
    inputMaxLength: 50,
    validationFunction: null,
    canEdit: true,
  },
]

const socialProfile = [
  {
    key: 'facebook',
    txtLabel: `Facebook ID`,
    txtPlaceholder: 'ระบุ Facebook id',
    txtBtnTest: 'ทดสอบ\nความถูกต้อง',
    icon: (
      <Image
        resizeMode='cover'
        style={{
          width: STYLES.FONT_ICON_NORMAL,
          height: STYLES.FONT_ICON_NORMAL,
          marginRight: 4,
        }}
        source={require('../img/about/Messenger_Icon.png')}
      />
    ),
    btnHelpFunction: () => Linking.openURL(settings.XSELLY_URLS.HOW_TO_FIND_FACEBOOK_ID),
    btnTestFunction: (facebookId: string) => Linking.openURL(`http://m.me/${facebookId}`),
    validationFunction: null,
  },
  {
    key: 'line',
    txtLabel: `LINE ID`,
    txtPlaceholder: 'ระบุ Line id',
    txtBtnTest: 'ทดสอบ\nความถูกต้อง',
    icon: (
      <Image
        resizeMode='cover'
        style={{
          width: STYLES.FONT_ICON_NORMAL,
          height: STYLES.FONT_ICON_NORMAL,
          marginRight: 4,
        }}
        source={require('../img/about/LINE_Icon.png')}
      />
    ),
    btnHelpFunction: () => Linking.openURL(settings.XSELLY_URLS.HOW_TO_FIND_LINE_ID),
    btnTestFunction: (lineId: string) => Linking.openURL(util.getLineUrlByLineId(lineId)),
    validationFunction: null,
  },
  {
    key: 'instagram',
    txtLabel: `Instagram ID`,
    txtPlaceholder: 'ระบุ Instagram id',
    txtBtnTest: 'ทดสอบ\nความถูกต้อง',
    icon: (
      <Image
        resizeMode='cover'
        style={{
          width: STYLES.FONT_ICON_NORMAL,
          height: STYLES.FONT_ICON_NORMAL,
          marginRight: 4,
        }}
        source={require('../img/about/instagram-logo.png')}
      />
    ),
    btnHelpFunction: () => {},
    btnTestFunction: (id: string) => Linking.openURL(util.getInstagramUrlByInstagramId(id)),
    validationFunction: null,
  },
]

export default abstract class XProfile extends BaseXProfile {
  static displayName = 'BaseUIPaymentListView'

  abstract goBack: () => void
  //   abstract renderViewImgManager(): JSX.Element

  renderCustomHeader = () => {
    // const { navigation } = this.props
    const { mode } = this.state
    const isEditMode = mode === EDIT
    let leftBtn = { backIcon: true, onPressItem: () => this.goBack() }
    if (isEditMode) {
      // @ts-ignore
      leftBtn = { label: `บันทึก`, onPressItem: () => util.setStatePromise(this, { mode: VIEW }) }
    }
    return (
      <XCustomHeader
        title='ข้อมูลส่วนตัว'
        headerLeftProps={leftBtn}
        // headerRightProps={{ label: `แก้ไข`, onPressItem: () => util.setStatePromise(this, { mode: EDIT }) }}
      />
    )
  }

  _renderRadioView = (data: IFormProfileInputs) => {
    const { mode } = this.state
    const isViewMode = mode === VIEW
    return (
      <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
        {data.options.map((val: { label: string; value: string }, index: number) => {
          const selected = this.state[data.key] === index
          return (
            <TouchableOpacity
              onPress={() => this._onPressRadioBtn(data.key, index)}
              style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}
              disabled={isViewMode}>
              <XIcon family='Ionicons' style={{ flex: 0, minWidth: 25 }} name={selected ? 'radio-button-on' : 'radio-button-off'} />
              <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE, textAlign: 'center' }}>{val.label}</Text>
            </TouchableOpacity>
          )
        })}
      </View>
    )
  }

  _renderInputType = (data: IFormProfileInputs) => {
    const { mode } = this.state
    const isViewMode = mode === VIEW
    if (data.inputType === inputType) {
      if (isViewMode || !data.canEdit) {
        return <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE }}>{this.state[data.key]}</Text>
      }
      return (
        <XInput
          value={this.state[data.key]}
          placeholder={data.txtPlaceholder}
          maxLength={data.inputMaxLength}
          keyboardType={data.keyboardType}
          onChangeText={(text: string) => {
            const newText = _.isNil(data.validationFunction) ? text : data.validationFunction(text)
            util.setStatePromise(this, { [data.key]: newText })
          }}
        />
      )
    }
    if (data.inputType === radioType) {
      return this._renderRadioView(data)
    }
    return null
  }

  _renderInputView = (data: IFormProfileInputs) => (
    // const { mode } = this.state
    // const isViewMode = mode === VIEW
    <View style={{ width: '100%', height: 40, flexDirection: 'row', paddingTop: 4 }}>
      <View style={{ width: 80, justifyContent: 'center', backgroundColor: COLORS.WHITE, paddingLeft: 4 }}>
        <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE }}>{data.txtLabel}</Text>
      </View>
      <View style={{ flex: 1, flexBasis: 'auto', paddingRight: 4, justifyContent: 'center' }}>{this._renderInputType(data)}</View>
    </View>
  )

  _renderInputs = () => (
    <View style={{ width: '100%', backgroundColor: COLORS.WHITE }}>
      {
        // test
        // @ts-ignore
        formInputs.map((input: IFormProfileInputs) => this._renderInputView(input))
      }
    </View>
  )

  _renderJoinAtView = () => {
    // const { navigation } = this.props
    // const { state } = navigation
    // const { params } = state
    // const profile = params.profile
    // let joined = _.isNil(profile.j) ? null : profile.j
    let joined = null
    if (_.isNil(joined)) {
      return null
    }
    joined = `เข้ารวมเมื่อ ${joined}`
    return (
      <View
        style={{
          width: '100%',
          backgroundColor: COLORS.WHITE,
          alignItems: 'flex-end',
          justifyContent: 'center',
          height: 20,
          paddingRight: 4,
        }}>
        <Text style={{ fontSize: STYLES.FONT_SIZE_SMALLER, color: COLORS.TEXT_INACTIVE }}>{joined}</Text>
      </View>
    )
  }

  _renderSocialContact = () => {
    const { mode } = this.state
    const isViewMode = mode === VIEW
    return (
      <View style={{ width: '100%', backgroundColor: COLORS.WHITE, paddingTop: 4 }}>
        {socialProfile.map((social: ISocialProfile) => {
          const socialName = _.isNil(this.state[social.key]) || this.state[social.key] === '' ? 'ไม่ได้ระบุ' : this.state[social.key]
          return (
            <View
              style={{
                flex: 1,
                flexBasis: 'auto',
                flexDirection: 'row',
                alignItems: 'center',
                height: 40,
                paddingLeft: 4,
                paddingRight: 4,
              }}>
              <View style={{ width: 30 }}>{social.icon}</View>
              {/* {
                isViewMode ? <View style={{ width: 70 }}>
                <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE }}>{social.txtLabel}</Text>
              </View> : null
              } */}
              <View style={{ flex: 1, paddingRight: 4, paddingTop: 4 }}>
                {isViewMode ? (
                  <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE, width: 70, height: 20 }} numberOfLines={1}>
                    {socialName}
                  </Text>
                ) : (
                  <XInput
                    value={this.state[social.key]}
                    placeholder={social.txtPlaceholder}
                    maxLength={50}
                    onChangeText={(text: string) => {
                      const newText = _.isNil(social.validationFunction) ? text : social.validationFunction(text)
                      util.setStatePromise(this, { [social.key]: newText })
                    }}
                  />
                )}
              </View>
              {isViewMode ? (
                <TouchableOpacity
                  onPress={() => {
                    if (!_.isNil(this.state[social.key]) && this.state[social.key] !== '') {
                      social.btnTestFunction(this.state[social.key])
                    }
                  }}
                  style={{
                    width: 80,
                    backgroundColor: COLORS.BG_LIGHT_GREY,
                    borderWidth: 0.8,
                    borderColor: COLORS.TEXT_ACTIVE,
                    borderRadius: 7,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE, textAlign: 'center' }}>
                    {social.txtBtnTest}
                  </Text>
                </TouchableOpacity>
              ) : (
                <TouchableOpacity
                  onPress={() => social.btnHelpFunction()}
                  style={{
                    width: 50,
                    backgroundColor: COLORS.BG_LIGHT_GREY,
                    borderWidth: 0.8,
                    borderColor: COLORS.TEXT_ACTIVE,
                    borderRadius: 7,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <Text
                    style={{
                      fontSize: STYLES.FONT_SIZE_NORMAL,
                      color: COLORS.TEXT_ACTIVE,
                      textAlign: 'center',
                    }}>{`ดูวิธีการ\nหาไอดี`}</Text>
                </TouchableOpacity>
              )}
            </View>
          )
        })}
      </View>
    )
  }

  _renderEditBtn = () => {
    const { isShowEditProfileBtn = false, onPressEditProfileBtn = null } = this.props
    const { mode } = this.state
    if (!isShowEditProfileBtn || mode === EDIT) {
      return null
    }
    return (
      <TouchableOpacity
        onPress={() => {
          if (_.isNil(onPressEditProfileBtn)) {
            return
          }
          onPressEditProfileBtn()
          // this.isSetProfile = false
        }}
        style={{
          flex: 1,
          height: 30,
          borderWidth: 0.8,
          borderColor: COLORS.APP_MAIN,
          borderRadius: 7,
          backgroundColor: COLORS.BG_LIGHT_GREY,
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
        }}>
        <XIcon family='FontAwesome' style={{ flex: 0, minWidth: 25 }} name='edit' />
        <View style={{ width: 10 }} />
        <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.APP_MAIN }}>แก้ไขข้อมูล</Text>
      </TouchableOpacity>
    )
  }

  _renderSubmitBtn = () => {
    const { mode } = this.state
    if (mode === VIEW) {
      return null
    }
    const flex = p.op.isWeb() ? 1 : 0
    return (
      <TouchableOpacity
        onPress={() => this._onSubmit()}
        style={{
          flex,
          marginTop: 10,
          height: 30,
          borderWidth: 0.8,
          borderColor: COLORS.APP_MAIN,
          borderRadius: 7,
          backgroundColor: COLORS.APP_MAIN,
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.WHITE }}>บันทึกข้อมูล</Text>
      </TouchableOpacity>
    )
  }

  _renderMain() {
    // console.log(`PRORP PRO => `, this.props.profile)
    const hasNewProfile = this.state.profile !== this.props.profile
    if (hasNewProfile) {
      this._setProfile()
    }
    return (
      <XContainer>
        {/* {this.renderCustomHeader()} */}
        {/* {this._setProfile()} */}
        <XContent style={{ backgroundColor: COLORS.WHITE }} scrollEnabled>
          {/* {this.renderViewImgManager()} */}
          {this._renderEditBtn()}
          {this._renderJoinAtView()}
          {this._renderInputs()}
          {/* {this._renderAddressesBtn()} */}
          {this._renderSocialContact()}
          {/* {this._renderDealerDataView()} */}
        </XContent>
        {this._renderSubmitBtn()}
      </XContainer>
    )
  }

  render() {
    return this._renderMain()
  }
}
