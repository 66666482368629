import React, { PureComponent } from 'react'

// import { log, setStatePromise } from 'x/utils/util'
import { TouchableOpacity } from 'react-native'
import HStack from 'xui/components/HStack'
import { Map } from 'immutable'
import _ from 'lodash'
import XRadio from './XRadio'
import XText from './XText'

interface IOrderRadioGroupItemProps {
  active?: boolean
  index: number
  onPressItem: (newIndex: number) => void | Promise<void>
  data: Map<string, any>
}

// const { FONT_SIZE_NORMAL } = STYLES
// const { TEXT_ACTIVE } = COLORS

// const INTL_STYLES: { [key: string]: ViewStyle | TextStyle } = {
//   colMiddleCenter: {
//     flexBasis: 'auto',
//     justifyContent: 'center',
//     alignItems: 'center',
//   },
//   colMiddleLeft: {
//     flexBasis: 'auto',
//     justifyContent: 'center',
//     alignItems: 'flex-start',
//   },
//   txtActive: {
//     fontSize: FONT_SIZE_NORMAL,
//     color: TEXT_ACTIVE,
//   },
// }

export default class OrderRadioGroupItem extends PureComponent<IOrderRadioGroupItemProps> {
  _onPress = async (): Promise<void> => {
    const { index, onPressItem } = this.props
    if (_.isFunction(onPressItem)) {
      await onPressItem(index)
    }
  }

  render() {
    const { data, active = false } = this.props
    if (!Map.isMap(data)) {
      return null
    }
    const name = data.get('name')

    return (
      <TouchableOpacity onPress={this._onPress}>
        <HStack alignItems='center' space='1'>
          <XRadio data={data} checked={active} />
          <XText>{name}</XText>
        </HStack>
      </TouchableOpacity>
    )
  }
}
