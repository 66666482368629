import { Platform } from 'react-native'
// import CONS from '../config/constants'
import _ from 'lodash'
import { IXAppConfig, IEndpointConfig } from './app'
import {
  RUNTIME,
  RUNTIME_ENDPOINT_CONFIG,
  RUNTIME_ORG_OWNER_APP_CONFIG,
  RUNTIME_DEFAULT_APP_CONFIG,
} from './app/generated-runtime-app-config'

export let MODE: string
if (Platform.OS === 'web') {
  // บังคับให้ mode มีแค่ prod or dev
  if (_.includes(['prod', 'prodstaging'], process.env.REACT_APP_FLAVOUR)) {
    MODE = 'prod'
  } else {
    MODE = 'dev'
  }
} else {
  // for app
  MODE = RUNTIME.MODE
}

// export const PROD_TEST: boolean = true
// export const CONSOLE_LOG: boolean = true
export const { PROD_TEST } = RUNTIME
export const { CONSOLE_LOG } = RUNTIME
export const { IS_XSELLY_BUILD } = RUNTIME

export const IS_FORCING_VERSION_UPGRADE = false

export const ENDPOINT_CONFIG = RUNTIME_ENDPOINT_CONFIG
// สำหรับ override staging web API endpoint
if (
  MODE === 'dev' &&
  Platform.OS === 'web' &&
  process.env.REACT_APP_OVERRIDE_API_ENDPOINT &&
  process.env.REACT_APP_OVERRIDE_GO_API_ENDPOINT
) {
  ENDPOINT_CONFIG.app_apiRoot = process.env.REACT_APP_OVERRIDE_API_ENDPOINT
  ENDPOINT_CONFIG.app_goApiRoot = process.env.REACT_APP_OVERRIDE_GO_API_ENDPOINT
}

// export const CONFIG_DEMO: IEndpointConfig = {
//   app_apiRoot: 'http://159.65.3.229/api',
//   app_goApiRoot: 'https://superdevgo.xselly.com/api',
//   // app_apiRoot: 'http://172.20.10.4/api',
//   // app_apiRoot: 'http://172.20.10.2/api',
//   // app_apiRoot: 'http://localhost/api',
//   // app_apiRoot: 'https://api.xselly.com/api',
//   // app_apiRoot: 'https://superdev.xselly.com/api',
//
//   s3_prefix_main: 'd/',
//   s3_prefix_type_shipping_label: 's/', // shipping labels
//   s3_prefix_type_product: 'p/',
//   s3_prefix_type_payslip: 'l/',
//   s3_prefix_type_user_group: 'u/',
//   s3_prefix_type_cover_store: 's/',
//   s3_prefix_type_profile: 'm/',
//
//   s3_options_keyPrefix: 'tmp/',
//   s3_options_bucket: 'xsf',
//   s3_prefix_type_org_register: 'o/',
// }

export function getConfig(): IEndpointConfig {
  return ENDPOINT_CONFIG
  // switch (MODE) {
  //   case 'dev':
  //     return CONFIG_DEV
  //   case 'demo':
  //     return CONFIG_DEMO
  //   case 'prod':
  //     return CONFIG_PROD
  //   default:
  //     // return {}
  //     throw new Error('Somethung wrong in mode.ts -> getConfig')
  // }
}

export const ORG_OWNER_APP_CONFIG: IXAppConfig = RUNTIME_ORG_OWNER_APP_CONFIG

export const DEFAULT_APP_CONFIG: IXAppConfig = RUNTIME_DEFAULT_APP_CONFIG

export let APP_CONFIG = DEFAULT_APP_CONFIG

export const setAppConfig = (newConfig: IXAppConfig) => {
  APP_CONFIG = newConfig
}
