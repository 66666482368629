import React from 'react'
import _ from 'lodash'
import { Map, List } from 'immutable'
import Box from 'xui/components/Box'
import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'

import NB from 'x/config/nativebase'
import * as util from 'x/utils/util'
import XText from '../XText'
import XCard from '../XCard'
import XButton from '../XButton'
import XImageIcon from '../XImageIcon'
import OrderProductItem from './OrderProductItem'
import XIcon from '../XIcon'

const tb_my0 = require('../../img/tb/tb_my0.png')
const tb_seller0 = require('../../img/tb/tb_seller0.png')

interface IOrderStoreProductsCardProps {
  store_id: number | string
  products: List<Map<string, any>>
  order: Map<string, any>
  isCreateMode?: boolean
  isEditable?: boolean
  isRemovable?: boolean
  canShowProfit?: boolean
  showProfit?: boolean
  isCanceledOrder?: boolean
  lastStoreId: number
  lastStoreProductIndex: number

  mode: string
  focusedOrderType: number

  myStores: List<any>
  sellerStores: List<any>

  onPressBuyOrderButton: (store_id) => void

  onChangeStoreProductByKey: (params: { pp_id: number; key: string; value: any }) => void
  onDeleteProduct: (params: { store_id: number; pp_id: number }) => void
}

const OrderStoreProductsCard: React.FC<IOrderStoreProductsCardProps> = (props) => {
  const {
    // specific props
    store_id,
    products,
    order,
    isCreateMode,
    isEditable,
    isRemovable,
    canShowProfit,
    showProfit,
    isCanceledOrder,
    lastStoreId,
    lastStoreProductIndex,

    mode,
    focusedOrderType,

    // redux from view
    myStores,
    sellerStores,

    onPressBuyOrderButton,
    onDeleteProduct,
    onChangeStoreProductByKey,
  } = props
  // console.log('renderStoreProductItem item => ', item)

  // const storeIdKeys = item.keys()
  // const store_id = storeIdKeys[0]
  // const product = item.get(store_id)
  // const [store_id, products] = args.item

  // console.log('renderStoreProductItem store_id => ', store_id)
  // console.log('renderStoreProductItem products => ', products.toJS())

  // @ts-ignore
  if (_.isNil(store_id) || parseInt(store_id) <= 0) {
    return null
  }

  const { name, isMyStore, isNotFound } = getStoreNameFromStoreId({
    myStores,
    sellerStores,
    store_id,
    incPrefix: true,
  })
  const txtStoreName = isNotFound ? 'ไม่พบชื่อร้านค้า' : name
  const orderStoreId = order.get('store_id') || null
  const parentOrderType = order.get('type')

  let od = order
  if (orderStoreId !== store_id) {
    const targetOrderIndex = order.get('suborders') ? order.get('suborders').findIndex((so) => so.get('store_id') === store_id) : null
    if (targetOrderIndex > -1 && order.getIn(['suborders', targetOrderIndex, 'id'])) {
      const subOrder = order.getIn(['suborders', targetOrderIndex]) || Map({})
      od = subOrder
    }
    // else {
    // Should be impossible-case
    // return null
    // }
  }
  const orderType = od.get('type')
  const txtOrderName = `#${od.get('name')}` || '-'
  const orderId = od.get('id') || null
  const shippingTypeId = od.get('shipping_type_id') || '-'
  const txtShippingType = util.getShippingTypeNameById(shippingTypeId)

  const txtFromOrderName = txtOrderName ? `${orderType === 3 ? 'ซื้อ' : 'ขาย'}${txtOrderName}` : null

  const _handleOnPressChildOrderId = () => {
    onPressBuyOrderButton(orderId)
  }

  return (
    <XCard w='full' overflow='visible' key={store_id.toString()}>
      <XCard.Header w='full' {...NB.U.BG_L_LIGHT_GREY}>
        <HStack w='full' space='2'>
          <VStack flex={1} space='1'>
            <HStack w='full' space='2'>
              <Box w='6' h='6'>
                <Box
                  // top={0} left={0}
                  // bg='amber.100'
                  w='8'
                  h='8'
                  top={-6}
                  left={-6}
                  position='absolute'>
                  <XImageIcon size='md' variant='inactive' source={isMyStore ? tb_my0 : tb_seller0} />
                </Box>
              </Box>
              <XText
                // pt={1}
                fontSize='xs'>{`สินค้าจาก${txtStoreName}`}</XText>
            </HStack>

            {!isCreateMode && txtShippingType ? (
              <HStack w='full' space='2'>
                {/* <XText variant='inactive' fontSize='xs'>
                  {'รูปแบบจัดส่ง: '}
                </XText> */}
                <XIcon family='MaterialIcons' name='local-shipping' variant='inactive' />
                <XText
                  // pt={1}
                  fontSize='xs'>
                  {txtShippingType}
                </XText>
              </HStack>
            ) : null}
          </VStack>

          {!isCreateMode && txtFromOrderName && parentOrderType !== 3 && orderType === 3 ? (
            <VStack w='108px'>
              <XButton
                // variant={mode !== CONS.ORDER_VIEW_MODE.EDIT ? 'solid' : 'outline'}
                variant='outline'
                onPress={_handleOnPressChildOrderId}>
                {txtFromOrderName}
              </XButton>
            </VStack>
          ) : null}
        </HStack>
      </XCard.Header>
      <XCard.Body w='full'>
        <VStack w='full' space='2'>
          {products.map((...args) => {
            const productItemProps = {
              store_id,
              index: args[1],
              product: args[0],
              focusedOrderType,
              order,

              isCreateMode,
              isEditable,
              isRemovable,
              canShowProfit,
              showProfit,
              isCanceledOrder,
              isLastRow: store_id === lastStoreId && _.isNumber(lastStoreProductIndex),

              onDeleteProduct,
              onChangeStoreProductByKey,
            }
            // @ts-ignore
            return <OrderProductItem key={`OPI_${store_id}_${args[1]}`} {...productItemProps} />
            // this._renderProduct({
            //   store_id,
            //   index: args[1],
            //   product: args[0],
            //   isCreateMode,
            //   isEditable,
            //   isRemovable,
            //   canShowProfit,
            //   isCanceledOrder,
            //   orderType,
            //   isLastRow: store_id === lastStoreId && _.isNumber(lastStoreProductIndex),
            //   order,
            // })
          })}
        </VStack>
      </XCard.Body>
    </XCard>
  )
}

function getStoreNameFromStoreId(arg: {
  myStores: List<any>
  sellerStores: List<any>
  store_id: number | string
  incPrefix?: boolean
}): {
  name: string | null
  isMyStore?: boolean
  isNotFound?: boolean
} {
  const { myStores, sellerStores, store_id, incPrefix } = arg
  // const { myStores, sellerStores } = this.props
  let foundStore = null
  const storeId = _.isString(store_id) ? parseInt(store_id, 10) : store_id
  foundStore = myStores.find((s) => parseInt(s.get('id')) === storeId)
  if (foundStore) {
    return { name: incPrefix ? `ร้านฉัน: ${foundStore.get('name')}` : foundStore.get('name'), isMyStore: true }
  }

  foundStore = sellerStores.find((s) => parseInt(s.get('id')) === storeId)
  if (foundStore) {
    return { name: incPrefix ? `ร้านผู้ขายส่ง: ${foundStore.get('name')}` : foundStore.get('name') }
  }

  return { name: 'ไม่พบชื่อร้านค้า', isNotFound: true } // 'ไม่พบชื่อร้านค้า'
}

export default OrderStoreProductsCard
