import { Platform, StyleSheet } from 'react-native'
// import { useColorModeValue as mode } from 'native-base'
import NB from '../../nativebase'

function baseStyle(props: Record<string, any>) {
  return {
    // bg: mode(`#fff`, `gray.700`),
    _light: {
      bg: 'white',
      borderTopColor: 'coolGray.200',
      borderLeftColor: 'coolGray.200',
      borderBottomColor: 'coolGray.200',
      borderRightColor: 'coolGray.200',
    },
    _dark: {
      bg: 'gray.700',
      borderTopColor: 'gray.600',
      borderLeftColor: 'gray.600',
      borderBottomColor: 'gray.600',
      borderRightColor: 'gray.600',
    },
    // py: 2,
    py: '2px',
    borderWidth: '1',
    // borderColor: mode(`coolGray.200`, `gray.600`),
    borderRadius: 'sm',
    // transition: {
    //   initial: { opacity: 0, translateY: -10 },
    //   animate: {
    //     opacity: 1,
    //     translateY: 0,
    //     transition: { duration: 200 },
    //   },
    //   exit: { opacity: 0, translateY: -10, transition: { duration: 150 } },
    //   style: StyleSheet.absoluteFill,
    // },
    _presenceTransition: {
      initial: { opacity: 0 },
      animate: {
        opacity: 1,
        translateY: 0,
        transition: { duration: 200 },
      },
      exit: { opacity: 0, transition: { duration: 150 } },
      style: StyleSheet.absoluteFill,
    },
    _backdrop: {
      bg: 'black',
    },
    // _web: {
    //   _backdrop: {
    //     bg: 'transparent',
    //   },
    // },
  }
}

export default {
  baseStyle,
}

export const MenuGroup = {
  baseStyle: (props: any) => ({
    _light: {
      _title: {
        color: NB.C.L.TEXT.ACTIVE,
      },
    },
    _dark: {
      _title: {
        color: NB.C.D.TEXT.ACTIVE,
      },
    },
    _title: {
      fontSize: 'xs',
      fontWeight: 600,
      textTransform: 'uppercase',
      // color: mode(`gray.500`, `gray.300`),
      // color: NB.HC.TEXT.ACTIVE(),
    },
    p: 3,
  }),
}

export const MenuItem = {
  baseStyle: (props: any) => ({
    _light: {
      bg: 'white',
      _text: {
        color: NB.C.L.TEXT.INACTIVE,
      },
      _focus: {
        bg: NB.C.L.BG.PRIMARY,
      },
      _pressed: {
        bg: NB.C.L.BG.PRIMARY,
      },
      _icon: {
        color: 'gray.500',
      },
    },
    _dark: {
      bg: 'gray.700',
      _text: {
        color: NB.C.D.TEXT.INACTIVE,
      },
      _focus: {
        bg: NB.C.D.BG.PRIMARY,
      },
      _pressed: {
        bg: NB.C.D.BG.PRIMARY,
      },
      _icon: {
        color: 'gray.100',
      },
    },
    // px: 3,
    // py: 2,
    px: '2px',
    py: '1px',
    outlineWidth: Platform.OS === 'web' ? 0 : undefined,
    // _disabled: {
    //   _text: {
    //     // color: mode('gray.400', 'gray.400'),
    //     color: NB.HC.TEXT.INACTIVE(),
    //   },
    // },
    // _focus: {
    //   // bg: mode(`coolGray.200`, `gray.600`),
    //   bg: NB.HC.BG.PRIMARY(),
    // },
    // _pressed: {
    //   // bg: mode(`coolGray.300`, `gray.500`),
    //   bg: NB.HC.BG.PRIMARY(),
    // },
    _icon: {
      size: 4,
      // color: mode('gray.500', 'gray.100'),
    },
  }),
  defaultProps: {},
}
