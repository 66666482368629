import { bindActionCreators } from 'redux'
import * as StoreOrderListStateActions from './StoreOrderListState'
import {
  getStoreOrders,
  getStoreSelected,
  getStoreOrdersFetchStatus,
  getStoreOrdersTabStatus,
  getOrdersToBePaid,
  getStoreOrdersSummary,
  getStoreOrdersFilterSetting,
  getShouldFetchOrderSummary,
  getStoreOrdersShouldFetchAll,
  getStoreOrdersisCheckOrderIdsMapByKey,
  getSellerStores,
  getSelectedStoreOrdersDates,
  getSelectedStoreOrdersDatesOptionKey,
} from '../../redux/selectors'
import * as OrderState from '../order/OrderState'
import * as StoreState from '../store/StoreState'

export function getState(state) {
  return {
    orders: getStoreOrders(state),
    filterSetting: getStoreOrdersFilterSetting(state),
    selectedStore: getStoreSelected(state),
    tabStatus: getStoreOrdersTabStatus(state),
    fetchStatus: getStoreOrdersFetchStatus(state),
    ordersToBePaid: getOrdersToBePaid(state),
    summary: getStoreOrdersSummary(state),
    getShouldFetchOrderSummary: getShouldFetchOrderSummary(state),
    shouldFetchAll: getStoreOrdersShouldFetchAll(state), // Additional Flag
    // orderList: getOrders(state)//,state.get('orders'),
    isCheckOrderIdsMapByKey: getStoreOrdersisCheckOrderIdsMapByKey(state),
    sellerStores: getSellerStores(state),

    selectedStoreOrdersDates: getSelectedStoreOrdersDates(state),
    selectedStoreOrdersDatesOptionKey: getSelectedStoreOrdersDatesOptionKey(state),
  }
}

export function getDispatch(dispatch) {
  return {
    // navigate: bindActionCreators(NavigationActions.navigate, dispatch),
    dispatch,
    orderListStateActions: bindActionCreators(StoreOrderListStateActions, dispatch),
    cancelTackingCode: bindActionCreators(OrderState.cancelTackingCode, dispatch),
    createTackingCode: bindActionCreators(OrderState.createTackingCode, dispatch),
    updateTackingCode: bindActionCreators(OrderState.updateTackingCode, dispatch),
    autoRequestTracking: bindActionCreators(OrderState.autoRequestTracking, dispatch),
    setShouldFetchOrderSummary: bindActionCreators(StoreState.shouldFetchOrderSummary, dispatch),
    toggleIsCheckOrderId: bindActionCreators(StoreOrderListStateActions.toggleIsCheckOrderId, dispatch),
    checkAllOrderIds: bindActionCreators(StoreOrderListStateActions.checkAllOrderIds, dispatch),
    uncheckAllOrderIds: bindActionCreators(StoreOrderListStateActions.uncheckAllOrderIds, dispatch),
  }
}
