import React from 'react'
import { View, ViewStyle } from 'react-native'

import { S } from 'x/config/styles'
import XButton from './XButton'
import XIcon from './XIcon'
import XIconButton from './XIconButton'
import XText from './XText'

interface IProps {
  containerStyle?: ViewStyle
  onPressShowAlertInfo: () => void
  onPressDoRefresh: () => void
}

const ErrorListItem = (props: IProps) => {
  const containerStyle = props.containerStyle ? props.containerStyle : {}
  return (
    <View style={[S.COLUMN_CENTER, { width: '100%', minHeight: 40, padding: 8, paddingTop: 20 }, containerStyle]}>
      <View style={[S.ROW_CENTER]}>
        <XText variant='inactive' textAlign='center'>
          เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง
        </XText>
        <XIconButton colorScheme='muted' onPress={props.onPressShowAlertInfo} name='help-circle-outline' />
      </View>
      <View style={[S.ROW_CENTER]}>
        <XText variant='inactive' textAlign='center'>
          หากกดปุ่มแล้วยังโหลดไม่สำเร็จ กรุณาปิดแอปแล้วเปิดใหม่ เพื่อลองอีกครั้ง
        </XText>
      </View>
      <View style={{ height: 12 }} />
      <XButton variant='outline' maxW='150px' onPress={props.onPressDoRefresh} leftIcon={<XIcon name='refresh' family='FontAwesome' />}>
        โหลดใหม่
      </XButton>
    </View>
  )
}

export default ErrorListItem
