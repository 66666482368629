import React from 'react'

import {
  IProductListViewPullSellersProductsToSalePageNavParams,
  IProductItemData,
  IProductListViewProps,
  IProductListViewState,
} from 'x/index'
import * as util from 'x/utils/util'
import p from 'x/config/platform-specific'

import _ from 'lodash'
import NavHeaderButton from 'xui/components/NavHeaderButton'
import BaseUIProductListView from './BaseUIProductListView'
import AddProductToSalePageButton from './AddProductToSalePageButton'

// const QUICK_ADD_PRODUCT_TO_ORDER_LABEL = `เพิ่ม ${APP_CONFIG.order.create_order.quick_add_product_to_order_qty} ชิ้นในออเดอร์...`
const INITIAL_QUICKADD_REF_NUMBER = 500

type IProductListViewPullSellersProductsToSalePageProps = IProductListViewProps<IProductListViewPullSellersProductsToSalePageNavParams>

class ProductListViewPullSellersProductsToSalePage extends BaseUIProductListView<
  IProductListViewPullSellersProductsToSalePageProps,
  IProductListViewState
> {
  static displayName = 'ProductListViewPullSellersProductsToSalePage'

  quickAddButtonRefs?: {
    [key: number]: React.RefObject<AddProductToSalePageButton>
  }

  constructor(props) {
    super(props)
    this.quickAddButtonRefs = {}
    for (let i = 0; i < INITIAL_QUICKADD_REF_NUMBER; i++) {
      this.quickAddButtonRefs[i] = React.createRef()
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.mainProductCount > INITIAL_QUICKADD_REF_NUMBER) {
      for (let i = INITIAL_QUICKADD_REF_NUMBER; i < nextState.mainProductCount; i++) {
        this.quickAddButtonRefs[i] = React.createRef()
      }
    }
    return true
  }

  // // override
  // getApiEndpoint = () => api.POST_SELLER_PRODUCTS

  // override
  isRequiredProductVariants = () => true

  // override
  isAddProductButtonVisible = () => false

  // override
  isSkuSearchAvailable = () => false

  // override
  getHaveNoProductItemText = () => 'ไม่พบรายการสินค้า\nหรือ ร้านค้าไม่มีรายการสินค้าที่คุณสามารถดึงได้'

  // // override
  // isSellerStore = () => true

  // override
  getTargetStoreId = () => {
    const { navigation } = this.props
    const storeId = util.getNavParam(this.props, 'seller_store_id')
    if (typeof storeId !== 'number') {
      throw new Error('seller_store_id is required')
    }
    return storeId
  }

  // override
  renderProductListItemBottom = (item: { index: number; data: IProductItemData }) => (
    <AddProductToSalePageButton w='full' data={item.data} onPress={() => this._onPressAddOneProductToSalePage(item.data)} />
  )

  _onPressAddOneProductToSalePage = async (product: IProductItemData) => {
    // const { navigation, selectedUserGroups, quickAddProductToSalePage } = this.props
    const onProductListItemButtonPressed = util.getNavParam(this.props, 'onProductListItemButtonPressed')
    if (!_.isFunction(onProductListItemButtonPressed)) {
      throw new Error('onProductListItemButtonPressed is required')
    }
    // console.log('_onPressAddOneProductToSalePage 01 product => ', product)
    const mode = util.getNavParam(this.props, 'mode')
    if (product.v.length > 1) {
      const BUTTON_LABELS = []
      product.v.forEach((variant) => {
        BUTTON_LABELS.push(variant.n)
      })
      BUTTON_LABELS.push('ยกเลิก')
      const selectedIndex = await new Promise<number>((chooseAtIndex) => {
        p.op.showActionSheet(
          {
            options: BUTTON_LABELS,
            cancelButtonIndex: BUTTON_LABELS.length - 1,
            // destructiveButtonIndex: -1,
            title: 'เลือกสินค้าเพื่อเพิ่ม 1 ชิ้นในรายการตัวเลือก...',
          },
          (idx: string | number) => {
            // log('ActionSheet.show idx => ', idx)

            const intIdx = _.isString(idx) ? parseInt(idx) : idx
            chooseAtIndex(intIdx)
          }
        )
      })

      const selectedVariant = [product.v[selectedIndex]]

      // console.log('selectedIndex => ', selectedIndex)
      if (selectedIndex === -1) {
        return
      }
      // console.log('selectedVariant => ', selectedVariant)
      const newProduct = _.clone(product)
      newProduct.v = selectedVariant
      // console.log('newProduct => ', newProduct)
      onProductListItemButtonPressed(newProduct)
      // p.op.showToast(`เพิ่มสินค้า ${selectedVariant[0].n} เรียบร้อยแล้ว`, 'success')

      // @ts-ignore
      if (mode === 'SINGLE_PRODUCT_MODE') {
        util.navGoBack(this.props)
      }
      // this._onSuccessPullSellersProductToSalePageCallback()
      // if (mode === 'SINGLE_PRODUCT_MODE') {
      //   util.navGoBack(this.props)
      // }
    } else {
      onProductListItemButtonPressed(product)
      // p.op.showToast(`เพิ่มสินค้า ${product.n} เรียบร้อยแล้ว`, 'success')
      // this._onSuccessPullSellersProductToSalePageCallback()
      if (mode === 'SINGLE_PRODUCT_MODE') {
        util.navGoBack(this.props)
      }
    }
  }

  // implement abstract
  onPressProductItem = (item) => {
    // console.log('ProductListViewPullSellersProductsToMyStore onPressProductItem item => ', item)
    // this.navToProductViewPullSellersProductToSalePageMode(item.data.id)
  }

  // navToProductViewPullSellersProductToSalePageMode = (product_id: number) => {
  //   const { navigation } = this.props
  //   const storeId = util.getNavParam(this.props, 'store_id')
  //   const sellerStoreId = util.getNavParam(this.props, 'seller_store_id')
  //   navigation.dispatch(
  //     NavActions.navToProductViewPullSellerProductToSalePage({
  //       store_id: storeId,
  //       seller_store_id: sellerStoreId,
  //       product_id,
  //       onSuccessPullSellersProductToSalePageCallback: this._onSuccessPullSellersProductToSalePageCallback,
  //     })
  //   )
  // }

  // ทำการ goBack กลับไปยัง OrderView ถ้าหากมีการเลือก Add Product จาก Product Detail
  _onSuccessPullSellersProductToSalePageCallback = async () => {
    await util.delay(500)
    util.navGoBack(this.props)
  }

  renderGoBackButton = () => {
    const goBackSalePage = util.getNavParam(this.props, 'goBackSalePage')

    const goBackForSalePage = () => {
      // console.log('Woo Toooo !! ', goBackSalePage)
      this.props.navigation.pop(2)
      // if (_.isFunction(goBackSalePage)) {
      //   // @ts-ignore
      //   // goBackSalePage()
      //   this.props.navigation.pop(2)
      // }
    }
    return <NavHeaderButton backIcon onPressItem={() => goBackForSalePage()} />
  }
}

export default ProductListViewPullSellersProductsToSalePage
