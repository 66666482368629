import React, { memo, forwardRef } from 'react'
import { Platform } from 'react-native'
// import { usePropsResolution } from '../../../hooks/useThemeProps';
import Box from './Box'
import type { IDividerProps } from 'native-base'
// import { getComponentDefaultProps } from 'x/config/nativebase/makeStyledComponent'
// import theme from 'x/config/nativebase/theme'
// import { useHasResponsiveProps } from '../../../hooks/useHasResponsiveProps';

const Divider = forwardRef((props: IDividerProps, ref?: any) => {
  // const { orientation, ...resolvedProps } = usePropsResolution(
  //   'Divider',
  //   props,
  //   {},
  //   { resolveResponsively: ['thickness'] }
  // );
  // //TODO: refactor for responsive prop
  // if (useHasResponsiveProps(props)) {
  //   return null;
  // }
  return (
    <Box
      // {...resolvedProps}
      ref={ref}
      // aria-orientation={orientation}
      //@ts-ignore web only role
      accessibilityRole={Platform.OS === 'web' ? 'separator' : undefined}
      {...props}
    />
  )
})

Divider.displayName = 'XDivider'
// Divider.defaultProps = getComponentDefaultProps('Divider')

export default memo(Divider)
