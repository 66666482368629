import React, { forwardRef } from 'react'
import { TextStyle } from 'react-native'
import Icon, { IIconType, IIconProps } from './Icon'

// import Ionicons from 'react-native-vector-icons/Ionicons'
// import FontAwesome from 'react-native-vector-icons/FontAwesome'
// import MaterialIcons from 'react-native-vector-icons/MaterialIcons'
// import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons'
// import AntDesign from 'react-native-vector-icons/AntDesign'

export type XIconFamily = IIconType
// | 'AntDesign'
// | 'Entypo'
// | 'EvilIcons'
// | 'Feather'
// | 'FontAwesome'
// | 'FontAwesome5'
// | 'Foundation'
// | 'Ionicons'
// | 'MaterialCommunityIcons'
// | 'MaterialIcons'
// | 'Octicons'
// | 'SimpleLineIcons'
// | 'Zocial' // Icon family

// export interface XIconProps  {
//   name: string // IconName
//   family?: XIconFamily
//   inactive?: boolean
//   activeDark?: boolean
//   style?: TextStyle
//   size?: number // no-flex usage
// }

type ModifyIconType = Omit<IIconProps, 'style' | 'type'>

export interface XIconProps extends ModifyIconType {
  name: string // IconName
  family?: XIconFamily
  primary?: boolean
  active?: boolean
  inactive?: boolean
  activeDark?: boolean
  danger?: boolean
  success?: boolean
  style?: TextStyle
  children?: JSX.Element[] | JSX.Element
  // size?: number // no-flex usage
}

// FIXME: should declare type with typescript better than this way :(
// export const ICON_FAMILY: {
//   [key: string]:
//     | 'AntDesign'
//     | 'Entypo'
//     | 'EvilIcons'
//     | 'Feather'
//     | 'FontAwesome'
//     | 'FontAwesome5'
//     | 'Foundation'
//     | 'Ionicons'
//     | 'MaterialCommunityIcons'
//     | 'MaterialIcons'
//     | 'Octicons'
//     | 'SimpleLineIcons'
//     | 'Zocial'
// }
export const ICON_FAMILY: { [key in XIconFamily]: XIconFamily } = {
  AntDesign: 'AntDesign',
  Entypo: 'Entypo',
  EvilIcons: 'EvilIcons',
  Feather: 'Feather',
  FontAwesome: 'FontAwesome',
  FontAwesome5: 'FontAwesome5',
  Foundation: 'Foundation',
  Ionicons: 'Ionicons',
  MaterialCommunityIcons: 'MaterialCommunityIcons',
  MaterialIcons: 'MaterialIcons',
  Octicons: 'Octicons',
  SimpleLineIcons: 'SimpleLineIcons',
  Zocial: 'Zocial',
}

const XIcon = forwardRef((props, ref) => {
  const {
    // @ts-ignore
    name,
    // @ts-ignore
    family = ICON_FAMILY.Ionicons,
    // @ts-ignore
    primary = false,
    // @ts-ignore
    active = false,
    // @ts-ignore
    activeDark = false,
    // @ts-ignore
    inactive = false,
    // @ts-ignore
    danger = false,
    // @ts-ignore
    success = false,
    // size,
    // style = {},
    // @ts-ignore
    variant = 'primary',
    // variant,
    ...restProps
  } = props
  let iconVariant = variant

  if (primary) {
    iconVariant = 'primary'
  }
  if (active) {
    iconVariant = 'active'
  }
  if (activeDark) {
    iconVariant = 'activeDark'
  }
  if (inactive) {
    iconVariant = 'inactive'
  }
  if (danger) {
    iconVariant = 'danger'
  }
  if (success) {
    iconVariant = 'success'
  }

  // const { colorMode } = useColorMode()
  // const isLightTheme = colorMode === 'light'

  // let preDefinedColor = 'primary.500'
  // if (active) {
  //   preDefinedColor = isLightTheme ? 'coolGray.800' : 'warmGray.50'
  // }

  // if (activeDark) {
  //   preDefinedColor = isLightTheme ? 'coolGray.100' : 'warmGray.50'
  // }

  // if (inactive) {
  //   preDefinedColor = isLightTheme ? 'muted.400' : 'muted.300'
  // }

  // const combineStyle = StyleSheet.flatten(style)
  // if (size) {
  //   combineStyle.fontSize = size
  //   combineStyle.width = size
  //   combineStyle.height = size
  // }

  return (
    // @ts-ignore
    <Icon
      // @ts-ignore
      ref={ref}
      // color={preDefinedColor}
      variant={iconVariant}
      type={family}
      name={name}
      {...restProps}
    />
  )
})

XIcon.displayName = 'XIcon'

export default XIcon as React.FC<XIconProps>

// export default class XIcon extends React.Component<XIconProps> {
//   ICON_STYLES: TextStyle

//   constructor(props: XIconProps) {
//     super(props)
//     this._updateNewStyle(props)
//   }

//   shouldComponentUpdate(nextProps: XIconProps) {
//     if (isDiff(this.props, nextProps, ['inactive', 'activeDark', 'name', 'family'])) {
//       this._updateNewStyle(nextProps)
//       return true
//     }

//     if (nextProps.style && isDiffAccuracy(this.props.style, nextProps.style, ['color', 'width', 'height'])) {
//       this._updateNewStyle(nextProps)
//       return true
//     }

//     return false
//   }

//   _updateNewStyle = (props: XIconProps) => {
//     const { inactive = false, activeDark = false, style = {}, size = null } = props

//     let iconColor = COLORS.APP_MAIN
//     if (inactive) {
//       iconColor = COLORS.TEXT_INACTIVE
//     } else if (activeDark) {
//       // iconColor = COLORS.TEXT_ACTIVE_DARK
//       iconColor = 'white'
//     }

//     // const determineSize = size
//     //   ? { fontSize: size, width: size, height: size }
//     //   : { flex: 1, fontSize: STYLES.FONT_ICON_NORMAL, minWidth: 44 }

//     this.ICON_STYLES = {
//       // ...determineSize,
//       color: iconColor,
//       textAlign: 'center',
//       // paddingTop: 6,
//       // paddingLeft: 8,
//       ...style,
//     }
//   }

//   // _renderIconByFamily = () => {
//   //   const { name, family = null } = this.props
//   //   let IconComp = Ionicons
//   //   switch (family) {
//   //     case ICON_FAMILY.FontAwesome:
//   //       IconComp = FontAwesome
//   //       break
//   //     case ICON_FAMILY.MaterialIcons:
//   //       IconComp = MaterialIcons
//   //       break
//   //     case ICON_FAMILY.MaterialCommunityIcons:
//   //       IconComp = MaterialCommunityIcons
//   //       break
//   //     case ICON_FAMILY.AntDesign:
//   //       IconComp = AntDesign
//   //       break

//   //     default:
//   //       IconComp = Ionicons
//   //   }
//   render() {
//     const { name, family = null } = this.props
//     return (
//       <Icon
//         type={_.has(ICON_FAMILY, family) ? family : ICON_FAMILY.Ionicons}
//         // allowFontScaling={false}
//         // name='arrow-back'
//         name={name}
//         style={this.ICON_STYLES}
//       />
//     )
//   }

//   // render() {
//   //   return this._renderIconByFamily()
//   // }

//   // render() {
//   //   return (
//   //     <Icon
//   //       // allowFontScaling={false}
//   //       name='arrow-back'
//   //       style={this.ICON_STYLES}
//   //     />
//   //   )
//   // }
// }

// // const s = {
// //   color: COLORS.APP_MAIN,
// //   width: 36,
// //   height: 36,
// //   fontSize: STYLES.FONT_ICON_NORMAL,
// //   paddingTop: 6,
// //   paddingLeft: 8,
// // }
