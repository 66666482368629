/* eslint-disable react/no-unused-class-component-methods */
import { Component } from 'react'
import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'

import { ISelectedStoreMap } from 'x/index'

import { IPaymentAccount, IXScreenProps } from 'x/types'
// import axios from 'axios'

export interface IFileUpload {
  name: string
  lastModified: number
  webkitRelativePath: string
  size: number
  type: string
  path: string
}

interface iPostDateSameValueForAll {
  mode: 'same-value-for-al'
  value: string
}

interface iPostDateSameCellForAll {
  mode: 'same-cell-for-all'
  col: string
  row: string
}

export interface iSendHTTPPostToUploadFile {
  path: string
  formData: any
  authToken: string | any
  handleResponse: (res: { uuid: string } | string) => void
}

export interface iCustomDataShippingTypeSelector {
  row_starting_data: any
  index: number
  name: string
  logo?: any
  example: string
  starting_data_row: string
  tracking_code_col: string
  cod_amount_col: string
  template_validation_col: string
  template_validation_row: string
  template_validation_val: string
  post_date?: string // iPostDateSameValueForAll | iPostDateSameCellForAll | iPostDateByRow
  sheet_idx: string
}

export interface BaseImportExcelCreateOrderViewProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
  subscription: Map<string, any>
}

export interface BaseImportExcelCreateOrderViewState {
  fileUpload: IFileUpload
  isLoading: boolean
  successAddProduct: number
  importErrorColumn: string
  importErrorRow: number
  importErrorCode: string
  disabledDownloadTemplate: boolean

  indexSelectorShippingType: number
  selectedPaymentAccount: IPaymentAccount | null
  selectedCreateOrderData: any | null
  selectedDateTime: Date

  openDatePicker: boolean
  openTimePicker: boolean

  selectedDate: Date
  selectedTime: Date
}

export default abstract class BaseImportExcelCreateOrderView extends Component<
  BaseImportExcelCreateOrderViewProps,
  BaseImportExcelCreateOrderViewState
> {
  // abstract sendHTTPPostToUploadFile(data: iSendHTTPPostToUploadFile): () => void
  inProcess: boolean

  COSTOM_DATA_DOC_TYPE_SELECTOR: any[]

  constructor(props) {
    super(props)
    this.state = {
      fileUpload: null,
      isLoading: false,
      successAddProduct: null,
      importErrorColumn: null,
      importErrorRow: null,
      importErrorCode: null,
      disabledDownloadTemplate: false,
      indexSelectorShippingType: -1,
      selectedPaymentAccount: null,
      selectedCreateOrderData: null,
      selectedDateTime: new Date(),

      openDatePicker: false,
      openTimePicker: false,
      selectedDate: new Date(),
      selectedTime: new Date(),
    }
    this.inProcess = false
    this.COSTOM_DATA_DOC_TYPE_SELECTOR = [
      {
        index: 0,
        name: 'V Rich',
        example: 'https://s1.xselly.com/x/samples/tp/sample_order_vrich.xlsx',
        row_starting_data: '2',
        col_datasource_order_id: 'A',
        col_date_order: 'C',
        col_customer_name_from_source: 'D',
        source_of_customer_name: 'FB: ',
        col_customer_name: 'E',
        col_address1: 'F',
        col_province: 'G',
        col_postal_code: 'H',
        col_telephone: 'I',
        col_shipping_label_note: 'J',
        col_shipping_type: 'K',
        col_datasource_ref1: 'L',
        col_discount: 'Q',
        col_shipping_fee: 'R',
        col_cod_amount: 'S',
        col_product_sku: 'Z',
        col_product_qty: 'AC',
        col_product_price: 'AD',
        cod_skus: '["กปท", "cod", "เก็บเงินปลายทาง"]',
        prevent_dup_datasource_order_id: true,
        datasource_id: '1',
        sheet_idx: '1',
      },
      {
        index: 1,
        name: 'อื่นๆ',
        example: null,
        row_starting_data: '2',
        col_datasource_order_id: 'A',
        col_date_order: 'C',
        col_customer_name_from_source: 'D',
        source_of_customer_name: 'FB: ',
        col_customer_name: 'E',
        col_address1: 'F',
        col_province: 'G',
        col_postal_code: 'H',
        col_telephone: 'I',
        col_shipping_label_note: 'J',
        col_shipping_type: 'K',
        col_datasource_ref1: 'L',
        col_discount: 'Q',
        col_shipping_fee: 'R',
        col_cod_amount: 'S',
        col_product_sku: 'Z',
        col_product_qty: 'AC',
        col_product_price: 'AD',
        cod_skus: '["กปท", "cod", "เก็บเงินปลายทาง"]',
        prevent_dup_datasource_order_id: true,
        datasource_id: '1',
        sheet_idx: '1',
      },
    ]
  }

  componentDidMount(): void {
    this._onPressSelectorSectedType(this.COSTOM_DATA_DOC_TYPE_SELECTOR[0])
  }

  _goBack = () => {
    util.navGoBack(this.props)
  }

  _onChangeDateTime = (newDates: Date) => {
    util.setStatePromise(this, { selectedDateTime: newDates })
    // if (p.op.isIOS()) {
    //   util.setStatePromise(this, { openDatePicker: false })
    // }
  }

  _setFileUpload = (acceptedFiles: IFileUpload[]) => {
    // console.log(acceptedFiles)
    util.setStatePromise(this, {
      fileUpload: acceptedFiles[0],
    })
  }

  _onPressSelectorSectedType = (shippingType: any) => {
    if (shippingType.index === 1) {
      p.op.showConfirmationOkOnly('', 'เร็วๆนี้')
      return
    }
    console.log('shippingType // => ', shippingType)
    util.setStatePromise(this, {
      selectedCreateOrderData: shippingType,
      indexSelectorShippingType: shippingType.index,
    })
  }

  _startLoading = async () => {
    this.inProcess = true
    await util.setStatePromise(this, {
      isLoading: true,
    })
  }

  _stopLoading = async () => {
    this.inProcess = false
    await util.setStatePromise(this, {
      isLoading: false,
    })
  }

  _getFormData = (formData: FormData) => {
    const { selectedStore } = this.props
    const { selectedCreateOrderData } = this.state
    formData.append('store_id', `${selectedStore.get('id')}`)
    // formData.append('store_id', '11111111')
    formData.append('row_starting_data', `${selectedCreateOrderData.row_starting_data}`)
    formData.append('col_datasource_order_id', `${selectedCreateOrderData.col_datasource_order_id}`)
    formData.append('col_date_order', `${selectedCreateOrderData.col_date_order}`)
    formData.append('col_customer_name_from_source', `${selectedCreateOrderData.col_customer_name_from_source}`)
    formData.append('source_of_customer_name', `${selectedCreateOrderData.source_of_customer_name}`)
    formData.append('col_customer_name', `${selectedCreateOrderData.col_customer_name}`)
    formData.append('col_address1', `${selectedCreateOrderData.col_address1}`)
    formData.append('col_province', `${selectedCreateOrderData.col_province}`)
    formData.append('col_postal_code', `${selectedCreateOrderData.col_postal_code}`)
    formData.append('col_telephone', `${selectedCreateOrderData.col_telephone}`)
    formData.append('col_shipping_label_note', `${selectedCreateOrderData.col_shipping_label_note}`)
    formData.append('col_shipping_type', `${selectedCreateOrderData.col_shipping_type}`)
    formData.append('col_datasource_ref1', `${selectedCreateOrderData.col_datasource_ref1}`)
    formData.append('col_discount', `${selectedCreateOrderData.col_discount}`)
    formData.append('col_shipping_fee', `${selectedCreateOrderData.col_shipping_fee}`)
    formData.append('col_cod_amount', `${selectedCreateOrderData.col_cod_amount}`)
    formData.append('col_product_sku', `${selectedCreateOrderData.col_product_sku}`)
    formData.append('col_product_qty', `${selectedCreateOrderData.col_product_qty}`)
    formData.append('col_product_price', `${selectedCreateOrderData.col_product_price}`)
    // Loop thru each item in the array as this is how to pass array to form data
    const cod_skus_array_obj = JSON.parse(selectedCreateOrderData.cod_skus)
    for (let i = 0; i < cod_skus_array_obj.length; i++) {
      // formData.append('cod_skus[]', `${selectedCreateOrderData.cod_skus[i]}`)
      formData.append('cod_skus', `${cod_skus_array_obj[i]}`)
    }
    formData.append('prevent_dup_datasource_order_id', `${selectedCreateOrderData.prevent_dup_datasource_order_id}`)
    formData.append('datasource_id', `${selectedCreateOrderData.datasource_id}`)
    formData.append('sheet_idx', `${selectedCreateOrderData.sheet_idx}`)
    return formData
  }
}
