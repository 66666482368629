// base from:  node_modules\native-base\src\theme\components\icon-button.ts
// import { useColorModeValue as mode } from 'native-base'

// const disabledTextColor = () => mode(`muted.500`, `muted.300`)

const baseStyle = (props: any) => {
  const { colorScheme = 'primary' } = props
  // const { colors } = props.theme
  // const focusRing = mode(
  //   {
  //     outlineWidth: '2px',
  //     outlineColor: `${colors[colorScheme][600]}`,
  //     outlineStyle: 'solid',
  //   },
  //   {
  //     outlineWidth: '2px',
  //     outlineColor: `${colors[colorScheme][500]}`,
  //     outlineStyle: 'solid',
  //   }
  // )(props);

  return {
    borderRadius: 'sm', // '4px'
    minH: '8',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    _web: {
      _disabled: {
        cursor: 'not-allowed',
      },
      _loading: {
        cursor: 'not-allowed',
      },
      cursor: 'pointer',
      userSelect: 'none',
    },
    _focus: {
      borderColor: `${colorScheme}.400`,
    },
    // _focusVisible: {
    //   _web: {
    //     style: { ...focusRing },
    //   },
    // },
    _loading: {
      opacity: '40',
    },
    _disabled: {
      opacity: '40',
    },
  }
}

function variantGhost(props: Record<string, any>) {
  const { colorScheme: c } = props
  return {
    _light: {
      _icon: {
        color: `${c}.500`,
        // color: `${c}.600`,
      },
      _hover: {
        bg: `${c}.500:alpha.10`,
        // bg: `${c}.600:alpha.10`,
      },
      _pressed: {
        bg: `${c}.500:alpha.20`,
        // bg: `${c}.600:alpha.20`,
      },
    },
    _dark: {
      _icon: {
        color: `${c}.500`,
      },
      _hover: {
        bg: `${c}.500:alpha.10`,
      },
      _pressed: {
        bg: `${c}.500:alpha.20`,
      },
    },
  }
}

function variantOutline(props: Record<string, any>) {
  const { colorScheme: c = 'primary' } = props

  // const predefinedBorderWidth = '1'
  // const predefinedBorderColor = mode(`muted.200`, `muted.500`)

  // let computedBorderColor = bc || undefined
  // // if (!bc) {
  // if (c === 'muted' || isDisabled) {
  //   // computedBorderColor = c === 'muted' ? predefinedBorderColor : props.isDisabled ? disabledTextColor() : mode(`${c}.300`, `${c}.300`)
  //   // computedBorderColor = disabledTextColor()
  //   computedBorderColor = 'muted.500'
  // } else {
  //   // computedBorderColor = mode(`${c}.300`, `${c}.300`)
  //   // computedBorderColor = mode(`${c}.500`, `${c}.500`)
  //   computedBorderColor = `${c}.500`
  // }

  // const injectBorderProps = nbUtil.getInjectBorderUtilityProps({
  //   ...props,
  //   borderWidth: bw || predefinedBorderWidth,
  //   borderColor: computedBorderColor,
  // })

  return {
    borderWidth: '1',
    // borderWidth: '1px',
    borderRadius: 'sm',
    // ...injectBorderProps,
    _light: {
      borderColor: `${c}.500`,
      // borderColor: `${c}.600`,
      _icon: {
        // color: `${c}.600`,
        color: `${c}.500`,
      },
      _hover: {
        bg: `${c}.700`,
        _icon: {
          color: 'muted.50',
        },
      },
      _pressed: {
        bg: `${c}.800`,
        _icon: {
          color: 'muted.50',
        },
      },
      _focus: {
        // bg: `${c}.600`,
        bg: `${c}.500`,
        _icon: {
          color: 'muted.50',
        },
      },
    },
    _dark: {
      borderColor: `${c}.500`,
      _icon: {
        color: `${c}.500`,
      },
      _hover: {
        bg: `${c}.400`,
        _icon: {
          color: 'muted.900',
        },
      },
      _pressed: {
        bg: `${c}.300`,
        _icon: {
          color: 'muted.900',
        },
      },
      _focus: {
        bg: `${c}.500`,
        _icon: {
          color: 'muted.900',
        },
      },
    },
  }
}

function variantSolid(props: Record<string, any>) {
  const { colorScheme: c } = props
  return {
    _light: {
      bg: `${c}.500`,
      // bg: `${c}.600`,
      _hover: {
        bg: `${c}.700`,
      },
      _pressed: {
        bg: `${c}.800`,
      },
      _icon: {
        color: 'muted.50',
      },
    },
    _dark: {
      bg: `${c}.500`,
      _hover: {
        bg: `${c}.400`,
      },
      _pressed: {
        bg: `${c}.300`,
        _icon: {
          color: 'muted.900',
        },
      },
      _icon: {
        color: 'muted.900',
      },
    },
  }
}

// function variantOutline(props) {
//   const { isDisabled = false, colorScheme: c, borderColor: bc, borderWidth: bw } = props

//   const predefinedBorderWidth = '1'
//   // const predefinedBorderColor = mode(`muted.200`, `muted.500`)

//   let computedBorderColor = bc || undefined
//   // if (!bc) {
//   if (c === 'muted' || isDisabled) {
//     // computedBorderColor = c === 'muted' ? predefinedBorderColor : props.isDisabled ? disabledTextColor() : mode(`${c}.300`, `${c}.300`)
//     computedBorderColor = disabledTextColor()
//   } else {
//     // computedBorderColor = mode(`${c}.300`, `${c}.300`)
//     computedBorderColor = mode(`${c}.500`, `${c}.500`)
//   }

//   const injectBorderProps = nbUtil.getInjectBorderUtilityProps({
//     ...props,
//     borderWidth: bw || predefinedBorderWidth,
//     borderColor: computedBorderColor,
//   })

//   return {
//     // borderWidth: '1',
//     // borderColor: `${c}.300`,
//     bgColor: mode('coolGray.50', 'warmGray.900'),
//     ...injectBorderProps,
//     _icon: {
//       color: mode(`${c}.500`, `${c}.300`),
//     },
//     _web: {
//       outlineWidth: 0,
//     },
//     _hover: {
//       bg: tt.transparentize(mode(`${c}.200`, `${c}.400`), 0.5)(props.theme),
//     },
//     _focusVisible: {
//       bg: tt.transparentize(mode(`${c}.200`, `${c}.400`), 0.5)(props.theme),
//     },
//     _pressed: {
//       bg: tt.transparentize(mode(`${c}.300`, `${c}.500`), 0.6)(props.theme),
//     },
//   }
// }

const variants = {
  solid: variantSolid,
  ghost: variantGhost,
  outline: variantOutline,
}

// const sizes = {
//   lg: {
//     p: 3,
//   },
//   md: {
//     p: 2,
//   },
//   sm: {
//     p: 1,
//   },
//   xs: {
//     p: 1,
//   },
// }

const sizes = {
  lg: {
    p: '2.5',
    // p: '3',
    _icon: {
      size: 'lg',
    },
  },
  md: {
    p: '2',
    // p: '2.5',
    _icon: {
      size: 'md',
    },
  },
  sm: {
    p: '1',
    // p: '2',
    _icon: {
      size: 'sm',
    },
  },
  xs: {
    p: '1',
    // p: '1.5',
    _icon: {
      size: 'xs',
    },
  },
}

const defaultProps = {
  variant: 'ghost',
  size: 'sm',
  colorScheme: 'primary',
}

export default {
  baseStyle,
  variants,
  defaultProps,
  sizes,
}
