// import PropTypes from 'prop-types'
import React, { Component } from 'react'

import {
  Text,
  View,
  TouchableOpacity,
  // TouchableHighlight,
  // TouchableWithoutFeedback,
} from 'react-native'

import XInput from 'xui/components/XInput'
import HStack from 'xui/components/HStack'
import XButton from 'xui/components/XButton'

import { STYLES, COLORS } from 'x/config/styles'
// import ForwardIcon from '../../components/ForwardIcon'
import p from 'x/config/platform-specific'
import _ from 'lodash'
import Immutable, { List, fromJS } from 'immutable'

// import SortableListView from 'react-native-sortable-listview'
import { VDViewProps, VDViewState } from 'x/index'
// import XGalleryModal from '../../components/XGalleryModal'
// import QRCode from '../../components/QRCode'
import * as xUtil from 'x/utils/util'
// let X_LOGO = require('../../img/logo_1024.png')

import Segment from 'xui/components/Segment'
import { detailedDiff } from 'deep-object-diff'
import HelpButton from 'xui/components/HelpButton'
import XIcon from 'xui/components/XIcon'
import XCard from 'xui/components/XCard'
import RangeAdjuster from 'xui/components/RangeAdjuster'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import XCustomHeader from 'xui/components/XCustomHeader'
import XIconButton from 'xui/components/XIconButton'
import XText from 'xui/components/XText'

import * as util from 'x/utils/util'

const MODE = ['CREATE', 'EDIT', 'VIEW']
const OPTIONS_VOLUME_DISCOUNT_TYPES = ['บาท', 'ร้อยละ (%)']

export default class VolumeDiscountView extends Component<VDViewProps, VDViewState> {
  static displayName = 'VolumeDiscountView'

  // static navigationOptions = ({ navigation }) => {
  //   const { state, goBack, setParams } = navigation
  //   if (!state.params) {
  //     return null
  //   }

  //   const name = p.op.t('Store.VolumeDiscountView.volumeDiscount')

  //   const {
  //     addVolumeDiscount = null,
  //     methodGoBack = null,
  //     methodCancal = null,
  //     editVolumeDiscount = null,
  //     updateVolumeDiscount = null,
  //   } = state.params
  //   const { editStatus = false } = state.params
  //   let headerLeft = null
  //   let headerRight = null

  //   switch (state.params.mode) {
  //     case MODE[0]: // CREATE
  //       headerLeft = <XIconButton name='arrow-back' onPress={methodGoBack} />
  //       headerRight = (
  //         <XButton variant='ghost' onPress={() => addVolumeDiscount()}>
  //           สร้าง
  //         </XButton>
  //       )
  //       break
  //     case MODE[1]: // EDIT
  //       headerLeft = editStatus ? (
  //         <XButton variant='ghost' onPress={methodCancal}>
  //           ยกเลิก
  //         </XButton>
  //       ) : (
  //         <XIconButton onPress={methodGoBack} name='arrow-back' />
  //       )

  //       headerRight = editStatus ? (
  //         <XButton variant='ghost' onPress={() => updateVolumeDiscount()}>
  //           บันทึก
  //         </XButton>
  //       ) : null
  //       break
  //     case MODE[2]: // VIEW
  //       headerLeft = <XIconButton onPress={methodGoBack} name='arrow-back' />
  //       headerRight = (
  //         <XButton variant='ghost' onPress={() => editVolumeDiscount()}>
  //           แก้ไข
  //         </XButton>
  //       )
  //       break
  //     default:
  //       return null
  //   }

  //   return {
  //     headerLeft,
  //     title: name,
  //     headerRight,
  //   }
  // }

  constructor(props: VDViewProps) {
    super(props)

    this.state = {
      mode: null,
      VDData: null,
      VDName: '',
      VDType: 0,
      VDDataClone: null,
      isCheckBoxSumQty: false,
    }
  }

  componentDidMount() {
    const { navigation } = this.props
    const params = util.getNavParams(this.props)
    if (!params) {
      return
    }

    switch (params.mode) {
      case MODE[0]:
        this.setState({
          VDData: Immutable.fromJS([
            {
              min: 1,
              max: 5,
              val: 10,
            },
            {
              min: 6,
              max: null,
              val: 20,
            },
          ]),
        })
        break
      case MODE[1]:
        this.setState({
          VDData: Immutable.fromJS(params.volume_discount.variants),
          VDName: params.volume_discount.name,
          VDDataClone: Immutable.fromJS(params.volume_discount.variants),
          VDType: params.volume_discount.type - 1,
        })
        break
      case MODE[2]:
        this.setState({
          VDData: Immutable.fromJS(params.volume_discount),
        })
        break
      default:
        break
    }
    const isCheckBoxSumQty =
      params.volume_discount && params.volume_discount.is_sum_qty_across_products
        ? params.volume_discount.is_sum_qty_across_products
        : false
    this.setState({
      mode: params.mode,
      isCheckBoxSumQty,
    })
    navigation.setParams({
      addVolumeDiscount: this._addVolumeDiscount,
      methodGoBack: this._methodGoBack,
      methodCancal: this._methodCancal,
      updateVolumeDiscount: this._updateVolumeDiscount,
      editVolumeDiscount: this._editVolumeDiscount,
    })
  }

  _renderCustomHeader = () => {
    const params = util.getNavParams(this.props)
    if (_.isNil(params)) {
      return null
    }

    const name = p.op.t('Store.VolumeDiscountView.volumeDiscount')

    const {
      addVolumeDiscount = null,
      methodGoBack = null,
      methodCancal = null,
      editVolumeDiscount = null,
      updateVolumeDiscount = null,
    } = params
    const { editStatus = false } = params
    let headerLeft = null
    let headerRight = null

    switch (params.mode) {
      case MODE[0]: // CREATE
        headerLeft = <XIconButton name='arrow-back' onPress={methodGoBack} />
        headerRight = (
          <XButton variant='ghost' onPress={() => addVolumeDiscount()}>
            สร้าง
          </XButton>
        )
        break
      case MODE[1]: // EDIT
        headerLeft = editStatus ? (
          <XButton variant='ghost' onPress={methodCancal}>
            ยกเลิก
          </XButton>
        ) : (
          <XIconButton onPress={methodGoBack} name='arrow-back' />
        )

        headerRight = editStatus ? (
          <XButton variant='ghost' onPress={() => updateVolumeDiscount()}>
            บันทึก
          </XButton>
        ) : null
        break
      case MODE[2]: // VIEW
        headerLeft = <XIconButton onPress={methodGoBack} name='arrow-back' />
        headerRight = (
          <XButton variant='ghost' onPress={() => editVolumeDiscount()}>
            แก้ไข
          </XButton>
        )
        break
      default:
        return null
    }

    return <XCustomHeader renderHeaderLeft={() => headerLeft} renderHeaderRight={() => headerRight} title={name} />
  }

  _editVolumeDiscount = () => {
    const { navigation } = this.props

    navigation.setParams({
      mode: MODE[1],
    })

    this.setState({ mode: MODE[1] })
  }

  checkVolumeDiscountOnChange = () => {
    const params = util.getNavParams(this.props)

    if (params.volume_discount.name != this.state.VDName) {
      // alert('NAME')
      return true
    }
    if (params.volume_discount.type != this.state.VDType + 1) {
      // alert('TYPE')
      return true
    }

    if (params.volume_discount.is_sum_qty_across_products !== this.state.isCheckBoxSumQty) {
      // alert('TYPE')
      return true
    }
    // console.log(detailedDiff(this.state.VDData.toJS(), this.state.VDDataClone.toJS()))
    const checkVariants: any = detailedDiff(this.state.VDData.toJS(), this.state.VDDataClone.toJS())
    if (!_.isEmpty(checkVariants.added)) {
      // alert('VARIANTS ADD')
      return true
    }
    if (!_.isEmpty(checkVariants.deleted)) {
      // alert('VARIANTS DELETE')
      return true
    }
    if (!_.isEmpty(checkVariants.updated)) {
      // alert('VARIANTS UPDATE')
      return true
    }
    return false
  }

  successCallback = (res) => {
    const { navigation } = this.props
    const params = util.getNavParams(this.props)

    const { name } = res.volume_discount
    const { type } = res.volume_discount
    const variants = fromJS(res.volume_discount.variants)
    const { id } = res.volume_discount
    let { p_c } = res.volume_discount
    if (params.mode === MODE[0]) {
      p_c = null
    }
    const newParams = {
      name,
      type,
      variants,
      id,
      p_c,
    }
    navigation.setParams({
      mode: MODE[2],
      volume_discount: newParams,
      editStatus: false,
    })
    let newType
    if (type === 1) {
      newType = 0
    } else {
      newType = 1
    }
    this.setState({
      mode: MODE[2],
      VDDataClone: variants,
      VDType: newType,
      VDData: variants,
    })
  }

  _updateVolumeDiscount = () => {
    const { subscription } = this.props
    const canUseVolumeDiscount = xUtil.canUseVolumeDiscount(subscription)
    if (!canUseVolumeDiscount) {
      p.op.showConfirmationOkOnly(p.op.t('Subscription.warning.insufficientTypeTitle'), p.op.t('Subscription.warning.insufficientTypeMsg'))
      return
    }

    const VolumeDiscountOnChange = this.checkVolumeDiscountOnChange()
    if (VolumeDiscountOnChange) {
      const params = util.getNavParams(this.props)
      if (_.isNil(params)) {
        return
      }
      const checkVDVariants = this._checkVDVariantsDiscount()
      if (checkVDVariants) {
        p.op.alert('เกิดข้อผิดพลาด', 'กรุณาระบุส่วนลดให้ครบถ้วน')
        return
      }
      const checkVDVariantsValue = this._checkVDVariantsValue()
      if (checkVDVariantsValue) {
        p.op.alert('เกิดข้อผิดพลาด', 'กรุณาระบุส่วนลดเป็นตัวเลขเท่านั้น')
        return
      }
      let type
      if (this.state.VDType === 0) {
        type = 1
      } else {
        type = 2
      }
      let checkVDVariantsPercent
      if (type === 2) {
        checkVDVariantsPercent = this._checkVDVariantsPercent()
        if (checkVDVariantsPercent) {
          p.op.alert('เกิดข้อผิดพลาด', 'ไม่สามารถตั้งส่วนลดเกินร้อยละ 100 ได้')
          return
        }
      }
      let costLengthVD = 0
      const listArrVDData = this.state.VDData.map((val) => {
        costLengthVD += 1
        if (costLengthVD === this.state.VDData.toJS().length) {
          return {
            min: val.get('min'),
            max: null,
            val: +val.get('val'),
          }
        }
        return {
          min: val.get('min'),
          max: val.get('max'),
          val: +val.get('val'),
        }
      })
      // console.log(listArrVDData.toJS())
      this.props.updateVolumeDiscount({
        body: {
          store_id: params.store_id,
          vd_id: params.volume_discount.id,
          name: this.state.VDName,
          type,
          variants: listArrVDData,
          is_sum_qty_across_products: this.state.isCheckBoxSumQty,
        },
        successCallback: (res) => this.successCallback(res),
        failedCallback: (err) => {
          p.op.showConfirmation('เกิดข้อผิดพลาด', `กรุณาติดต่อทีมงาน XSelly ${err}`, () => {})
        },
      })
    } else {
      p.op.showToast('ไม่มีการเปลี่ยนแปลง', 'warning', 4000)
    }
  }

  _methodCancal = () => {
    const { navigation } = this.props
    const VolumeDiscountOnChange = this.checkVolumeDiscountOnChange()
    if (VolumeDiscountOnChange) {
      p.op.showConfirmation(
        'ยกเลิกการแก้ไข',
        'การแก้ไขของคุณยังไม่ได้รับการบันทึก คุณต้องการยกเลิกการแก้ไข ใช่หรือไม่',
        () => util.navGoBack(this.props),
        null,
        'ใช่',
        'ไม่ใช่'
      )
    } else {
      util.navGoBack(this.props)
    }
  }

  _methodGoBack = () => {
    util.navGoBack(this.props)
  }

  _checkVDVariantsDiscount = () => {
    const variantData = this.state.VDData

    for (let i = 0; i < this.state.VDData.size; i++) {
      const discountValue = variantData.getIn([i, 'val'])
      if (isNaN(+discountValue) || discountValue === '') {
        return true
      }
    }
    return false
  }

  _checkVDVariantsPercent = () => {
    const variantData = this.state.VDData
    for (let i = 0; i < this.state.VDData.size; i++) {
      const discountValue = +variantData.getIn([i, 'val'])
      if (isNaN(discountValue) || discountValue > 100) {
        return true
      }
    }
    return false
  }

  _checkVDVariantsValue = () => {
    const variantData = this.state.VDData
    for (let i = 0; i <= this.state.VDData.size - 1; i++) {
      const variantsValueMax = +variantData.getIn([i, 'max'])
      const variantsValueVal = +variantData.getIn([i, 'val'])
      // console.log(variantsValueEnd)
      // console.log(variantsValuediscount)
      if (_.isNaN(variantsValueMax) || (_.isNaN(variantsValueVal) && !_.isNull(variantsValueMax))) {
        // console.log('***************************')
        return true
      }
    }
    return false
  }

  _addVolumeDiscount = () => {
    const { subscription } = this.props
    const params = util.getNavParams(this.props)
    if (_.isNil(params)) {
      return
    }

    const canUseVolumeDiscount = xUtil.canUseVolumeDiscount(subscription)
    if (!canUseVolumeDiscount) {
      p.op.showConfirmationOkOnly(p.op.t('Subscription.warning.insufficientTypeTitle'), p.op.t('Subscription.warning.insufficientTypeMsg'))
      return
    }

    if (this.state.VDName.length < 1) {
      p.op.alert('เกิดข้อผิดพลาด', 'กรุณาระบุชื่อส่วนลดนี้')
      return
    }

    const checkVDVariants = this._checkVDVariantsDiscount()
    if (checkVDVariants) {
      p.op.alert('เกิดข้อผิดพลาด', 'กรุณาระบุส่วนลดให้ครบถ้วน')
      return
    }

    let type
    if (this.state.VDType === 0) {
      type = 1
    } else {
      type = 2
    }
    let checkVDVariantsPercent
    if (type === 2) {
      checkVDVariantsPercent = this._checkVDVariantsPercent()
      if (checkVDVariantsPercent) {
        p.op.alert('เกิดข้อผิดพลาด', 'ไม่สามารถตั้งส่วนลดเกินร้อยละ 100 ได้')
        return
      }
    }
    let costLengthVD = 0
    // console.log(this.state.VDData.toJS())
    const listArrVDData = this.state.VDData.map((val) => {
      costLengthVD += 1
      if (costLengthVD === this.state.VDData.toJS().length) {
        return {
          min: val.get('min'),
          max: null,
          val: +val.get('val'),
        }
      }
      return {
        min: val.get('min'),
        max: val.get('max'),
        val: +val.get('val'),
      }
    })

    // const listArrVDData = this.state.VDData
    this.props.createVolumeDiscount({
      body: {
        store_id: params.store_id,
        name: this.state.VDName,
        type,
        variants: listArrVDData,
        is_sum_qty_across_products: this.state.isCheckBoxSumQty,
      },
      successCallback: (res) => this.successCallback(res),
    })
  }

  _onChangeSegment = (newVdTypeIndex) => {
    const { setParams } = this.props.navigation
    this.setState({ VDType: newVdTypeIndex })
    setParams({
      editStatus: true,
    })
  }

  _onChangeVolumeDiscount = async (newVds: List<any>): Promise<void> => {
    const { setParams } = this.props.navigation
    this.setState({
      VDData: newVds,
    })
    setParams({
      editStatus: true,
    })
  }

  _onChangeText = (text) => {
    const { setParams } = this.props.navigation
    this.setState({
      VDName: text,
    })
    setParams({
      editStatus: true,
    })
  }

  _confirmDeleteVolumeDiscount = () => {
    // const { state, goBack } = this.props.navigation
    // const { params } = state
    const params = util.getNavParams(this.props)

    this.props.deleteVolumeDiscount({
      body: {
        store_id: params.store_id,
        vd_id: params.volume_discount.id,
      },
      successCallback: (res) => util.navGoBack(this.props),
    })
  }

  _deleteVolumeDiscount = () => {
    const params = util.getNavParams(this.props)
    if (!_.isNil(params.volume_discount.p_c) && params.volume_discount.p_c > 0) {
      p.op.showConfirmation(
        null,
        `คุณมีสินค้า ${params.volume_discount.p_c} รายการ ที่ช้งานส่วนลดนี้อยู่ คุณต้องการลบส่วนลดนี้หรือไม่`,
        () => this._confirmDeleteVolumeDiscount(),
        null,
        'ใช่',
        'ยกเลิก'
      )
    } else {
      p.op.showConfirmation(null, `คุณต้องการลบส่วนลดนี้หรือไม่`, () => this._confirmDeleteVolumeDiscount(), null, 'ใช่', 'ยกเลิก')
    }
  }

  _renderInputVDName = () => (
    <HStack space='2' alignItems='center' justifyContent='space-between'>
      <XText variant='inactive'>ชื่อ</XText>
      <XInput
        flex={1}
        // style={{flex: 1, }}
        // maxLength={ inputMaxLength || null }
        autoCorrect={false}
        autoCapitalize='none'
        // placeholder={txtPlaceholder || null}
        // placeholderTextColor={COLORS.TEXT_INACTIVE}
        value={this.state.VDName}
        // keyboardType={'phone-pad'}
        // selectTextOnFocus={false}
        // returnKeyType={'done'}
        // blurOnSubmit={false}
        onChangeText={(text) => {
          this._onChangeText(text)
          // console.log(' onChangeText input  => ', text)
        }}
      />
    </HStack>
  )

  _onPressCheckBoxSumQty = async () => {
    const { mode, isCheckBoxSumQty } = this.state
    if (mode === MODE[2]) {
      return
    }
    const { setParams } = this.props.navigation
    await xUtil.setStatePromise(this, {
      isCheckBoxSumQty: !isCheckBoxSumQty,
    })
    setParams({
      editStatus: true,
    })
  }

  _renderCheckBoxSumQty = () => {
    const { mode, isCheckBoxSumQty } = this.state
    // const paddingLeft = mode === MODE[2] ? 13 : 17
    return (
      <HStack space='1' alignItems='center'>
        <TouchableOpacity onPress={() => this._onPressCheckBoxSumQty()}>
          <HStack space='1' alignItems='center'>
            <XIcon inactive={mode === MODE[2]} family='FontAwesome' name={isCheckBoxSumQty ? 'check-square-o' : 'square-o'} />
            <XText pl='1' variant='inactive'>
              คำนวณข้ามรายการสินค้า
            </XText>
          </HStack>
        </TouchableOpacity>

        <HelpButton
          // style={{ margin: 0, marginTop: -4 }}
          title='คำนวณโดยรวมจำนวนสินค้าทุกชนิด'
          message='หากเลือก ระบบจะคำนวณส่วนลดขายส่งของออเดอร์หนึ่งๆ โดยอ้างอิงจากจำนวนรวมของสินค้าทุกรายการที่ผูกอยู่กับส่วนลดนี้ ในออเดอร์นั้นๆ เช่น หากใน ออเดอร์หนึ่งๆมี เสื้อผ้าลายหมี 2 ตัว และ กางเกงขาสั้น 2 ตัว ซึ่งทั้ง 2 สินค้าผูกอยู่กับส่วนลดขายส่งนี้ ระบบจะคำนวณส่วนลดขายส่งให้ที่ระดับ 4 ชิ้น เป็นต้น'
        />
      </HStack>
    )
  }

  _renderHeaderCreateAndEditMode = () => (
    // console.log('_renderHeaderCreateAndEditMode')
    <XCard.Body>
      {this._renderInputVDName()}
      {this._renderCheckBoxSumQty()}
      <XText variant='inactive'>ประเภทส่วนลด</XText>
      <Segment
        // onChange={newValue => this._onChangeSegment(optKey, newValue)}
        onSegmentChange={this._onChangeSegment}
        selectedIndex={this.state.VDType}
        options={OPTIONS_VOLUME_DISCOUNT_TYPES}
        // selectedValue={selectedValue}
      />
    </XCard.Body>
  )

  _renderHeaderViewMode = () => {
    const params = util.getNavParams(this.props)
    return (
      <XCard.Body>
        <HStack alignItems='center' justifyContent='space-between'>
          <XText
            style={{
              fontSize: STYLES.FONT_SIZE_NORMAL,
              color: COLORS.TEXT_ACTIVE,
            }}>
            {this.state.VDName}
          </XText>
          {!_.isNil(params.volume_discount.p_c) ? (
            <View>
              {params.volume_discount.p_c > 0 ? (
                <Text style={{ fontSize: STYLES.FONT_SIZE_SMALLER, color: COLORS.TEXT_INACTIVE }}>
                  มีสินค้าที่ใช้งานกับส่วนลดนี้ {params.volume_discount.p_c} ชิ้น
                </Text>
              ) : (
                <Text style={{ fontSize: STYLES.FONT_SIZE_SMALLER, color: COLORS.TEXT_INACTIVE }}>ไม่มีสินค้าที่ใช้งานกับส่วนลดนี้</Text>
              )}
            </View>
          ) : null}
        </HStack>
        {this._renderCheckBoxSumQty()}
      </XCard.Body>
    )
  }

  _renderDeleteBtn = () => (
    <XCard.Body style={{ justifyContent: 'center' }}>
      <XButton variant='outline' onPress={() => this._deleteVolumeDiscount()} style={{ width: 140 }}>
        ลบส่วนลดนี้
      </XButton>
    </XCard.Body>
  )

  _renderTextCountItem = () => {
    const params = util.getNavParams(this.props)
    return (
      <View>
        {params.mode === MODE[1] ? (
          <XCard.Body style={{ justifyContent: 'center', paddingTop: 0 }}>
            {params.volume_discount.p_c > 0 ? (
              <XText style={{ fontSize: STYLES.FONT_SIZE_SMALLER, color: COLORS.TEXT_INACTIVE }}>
                มีสินค้าที่ใช้งานกับส่วนลดนี้ {params.volume_discount.p_c} ชิ้น
              </XText>
            ) : (
              <XText style={{ fontSize: STYLES.FONT_SIZE_SMALLER, color: COLORS.TEXT_INACTIVE }}>ไม่มีสินค้าที่ใช้งานกับส่วนลดนี้</XText>
            )}
          </XCard.Body>
        ) : null}
      </View>
    )
  }

  render() {
    const { subscription, mySubscription } = this.props
    const params = util.getNavParams(this.props)

    const { VDType, VDData } = this.state

    const vdQuota = subscription.get('vd_quota')
    if (!params) {
      return null
    }
    if (_.isNil(VDData)) {
      return null
    }

    const vds = VDData

    let usePrefixOrSuffix
    const prefix = '฿'
    const suffix = '%'
    let isMoney = false
    let isNumber = false
    if (VDType === 0) {
      usePrefixOrSuffix = prefix
      isMoney = true
      isNumber = false
    } else {
      usePrefixOrSuffix = suffix
      isMoney = false
      isNumber = true
    }
    let isEditable = false
    if (params.mode === MODE[0]) {
      // CREATE MODE

      isEditable = true
    } else if (params.mode === MODE[1]) {
      // EDIT MODE

      isEditable = true
    } else if (params.mode === MODE[2]) {
      // VIEW MODE
      isEditable = false
      isMoney = false
    }
    return (
      <XContainer>
        {this._renderCustomHeader()}
        <XContent _contentContainerStyle={{ px: '2', py: '2' }}>
          <XCard w='full'>
            {params.mode !== MODE[2] ? this._renderHeaderCreateAndEditMode() : null}
            {params.mode === MODE[2] ? this._renderHeaderViewMode() : null}
            <XCard.Body>
              <RangeAdjuster
                disabled={!isEditable}
                mode='free'
                metadata={[
                  {
                    // key: discountKey,
                    prefix,
                    suffix,
                    usePrefixOrSuffix,
                    isMoney,
                    isNumber,
                    key: 'val',
                    label: VDType === 0 ? 'ส่วนลด (บาทต่อชิ้น)' : 'ส่วนลด (%)',
                    span: 2,
                    defaultValue: '',
                    keyboardType: 'numeric',
                    filteredMethod: (newValue: string) => {
                      const newDiscount = !_.isNaN(newValue) && !_.isNil(newValue) ? parseFloat(newValue) : null
                      if (this.state.VDType === 1 && _.isNumber(newDiscount)) {
                        if (newDiscount >= 0 && newDiscount <= 100) {
                          return newDiscount
                        }
                        if (newDiscount > 100) {
                          return 100
                        }
                        return 0
                      }
                      if (this.state.VDType === 0 && _.isNumber(newDiscount)) {
                        if (newDiscount >= 0) {
                          return newDiscount
                        }
                        return 0
                      }
                      return 0
                    },
                  },
                ]}
                data={vds}
                // onChange={newVds => {
                //   onChangeProduct({ key: vdsKey, value: newVds })
                // }}
                onChange={this._onChangeVolumeDiscount}
                labelAddButton='เพิ่มช่วงส่วนลด'
                labelLastEnd='ขึ้นไป'
                limitRow={vdQuota === 0 ? 1 : vdQuota} // Avoid 0 as it means unlimited.
                onExceededLimitRow={() =>
                  p.op.showConfirmationOkOnly('แพ็กเกจของคุณไม่สามารถเพิ่มส่วนลดได้อีก', p.op.t('Subscription.warning.insufficientTypeMsg'))
                }
              />
            </XCard.Body>
            {params.mode === MODE[1] ? this._renderDeleteBtn() : null}
            {this._renderTextCountItem()}
            <XCard.Body />
          </XCard>
        </XContent>
      </XContainer>
    )
  }
}
