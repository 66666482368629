import BaseUIStoreSettingXShippingEMSView from 'xui/modules/xshipping/BaseUIStoreSettingXShippingEMSView'

class StoreSettingXShippingEMSView extends BaseUIStoreSettingXShippingEMSView {
  static displayName = 'StoreSettingXShippingEMSView'

  static navigationOptions = {
    header: null,
    title: null,
    headerLeft: null,
    headerRight: null,
  }
}

export default StoreSettingXShippingEMSView
