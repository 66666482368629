import React, { useState, useEffect } from 'react'
import { COLORS, STYLES } from 'x/config/styles'
import { Text, View, TouchableOpacity } from 'react-native'
import CONS from 'x/config/constants'
import _ from 'lodash'
import p from 'x/config/platform-specific'

// import OverlayEditTimeSlot from 'xui/modules/channels/OverlayEditTimeSlot'
import XModal from 'xui/components/XModal'
import Segment from 'xui/components/Segment'
import XInput from './XInput'
import XRadioList from './XRadioList'
import XText from './XText'
import Box from './Box'
import HStack from './HStack'
import XButton from './XButton'
import VStack from './VStack'

export interface IXPercentDiscountProps {
  isVisible: boolean
  onClose: () => void
  vulueAmountTotal: number
  onSubmit: (discount: number) => void
}

// interface IInputSetPercentDiscointValue {
//   one: number
//   two: number
//   three: number
//   four: number
//   custom: number
// }

const RADIO_LIST = [
  {
    label: 'ปัดทั่วไป (ปัดขึ้นเมื่อ >= 0.50 หรือ ปัดทิ้งเมื่อน้อยกว่า)',
    value: 0,
  },
  {
    label: 'ปัดขึ้น',
    value: 1,
  },
  {
    label: 'ปัดทิ้ง',
    value: 2,
  },
  {
    label: 'ไม่ปัด',
    value: 3,
  },
]

// const ROUND_UP = 'ปัดขึ้น'
// const ROUND_DOWN = 'ปัดลง'
// const NO_ROUND = 'ไม่ปัด'

const underline = () => (
  <View
    style={{
      paddingTop: 12,
      borderBottomColor: COLORS.TEXT_INACTIVE,
      borderStyle: 'dashed',
      borderBottomWidth: 1,
    }}
  />
)

const DEFAULT_SEGMENT_PERCENT_DISCOINT_VALUE_BACKUP = ['5', '10', '15', '20', '25']
// const
const XPercentDiscount: React.FC<IXPercentDiscountProps> = ({ isVisible, onClose, vulueAmountTotal, onSubmit }) => {
  const [SEGMENT_PERCENT_DISCOINT_VALUE, SET_SEGMENT_PERCENT_DISCOINT_VALUE] = useState<number[]>([5, 10, 15, 20, 25])
  const [SEGMENT_PERCENT_DISCOINT_VALUE_BACKUP, SET_SEGMENT_PERCENT_DISCOINT_VALUE_BACKUP] = useState<string[]>(
    DEFAULT_SEGMENT_PERCENT_DISCOINT_VALUE_BACKUP
  )
  const [SEGMENT_PERCENT_DISCOINT_VALUE_BACKUP_FOR_CANCEL, SET_SEGMENT_PERCENT_DISCOINT_VALUE_BACKUP_FOR_CANCEL] = useState<string[]>(
    DEFAULT_SEGMENT_PERCENT_DISCOINT_VALUE_BACKUP
  )
  const [selectedPercentDiscointRoundIndex, setSelectedPercentDiscointRoundIndex] = useState<number>(0)
  const [selectedPercentDiscointIndex, setSelectedPercentDiscointIndex] = useState<number>(0)
  // const [inputSetPercentDiscointValue, setInputSetPercentDiscointValue] = useState<string[] | null>(null)
  const [inputPercentCustomDiscount, setInputPercentCustomDiscount] = useState<string>('0')
  const [isEditMode, setEditMode] = useState<boolean>(false)

  const getStoreData = async () => {
    await p.op.storageGet(CONS.STORAGE_KEYS.ORDER_PERCENT_DISCOUNT_DATA).then((val: any) => {
      // val have =>
      // SEGMENT_PERCENT_DISCOINT_VALUE_BACKUP,
      // selectedPercentDiscointIndex,
      // selectedPercentDiscointRoundIndex,
      // console.log('val => !! ', val)
      if (!_.isNil(val)) {
        // console.log('PASS => ', val.selectedPercentDiscointIndex)
        SET_SEGMENT_PERCENT_DISCOINT_VALUE_BACKUP(val.SEGMENT_PERCENT_DISCOINT_VALUE_BACKUP)
        const VAL_SEGMENT_PERCENT_DISCOINT_VALUE_BACKUP: string[] = val.SEGMENT_PERCENT_DISCOINT_VALUE_BACKUP
        SET_SEGMENT_PERCENT_DISCOINT_VALUE([
          +VAL_SEGMENT_PERCENT_DISCOINT_VALUE_BACKUP[0],
          +VAL_SEGMENT_PERCENT_DISCOINT_VALUE_BACKUP[1],
          +VAL_SEGMENT_PERCENT_DISCOINT_VALUE_BACKUP[2],
          +VAL_SEGMENT_PERCENT_DISCOINT_VALUE_BACKUP[3],
          +VAL_SEGMENT_PERCENT_DISCOINT_VALUE_BACKUP[4],
        ])
        setSelectedPercentDiscointIndex(val.selectedPercentDiscointIndex)
        setSelectedPercentDiscointRoundIndex(val.selectedPercentDiscointRoundIndex)
        setInputPercentCustomDiscount(val.SEGMENT_PERCENT_DISCOINT_VALUE_BACKUP[4])
      }
    })
  }

  useEffect(() => {
    // console.log('useEffect => ', isVisible)
    getStoreData()
  }, [])

  const _onSegmentChangeDiscount = (index: number) => {
    // let discountPercent = SEGMENT_PERCENT_DISCOINT_VALUE[index]
    setSelectedPercentDiscointIndex(index)
    // setInputPercentCustomDiscount(+discountPercent)
  }

  // const _onSegmentChangeDiscountRound = (index: number) => {
  //   setSelectedPercentDiscointRoundIndex(index)
  // }

  const onChangeTextInputPercentCustomDiscount = (newText: string) => {
    // if (!_.isNumber(+newText)) {
    //   return
    // }
    // const NEW_SEGMENT_PERCENT_DISCOINT_VALUE = _.clone(SEGMENT_PERCENT_DISCOINT_VALUE)
    // NEW_SEGMENT_PERCENT_DISCOINT_VALUE[4] = +newText
    // setInputPercentCustomDiscount(+newText)
    // SET_SEGMENT_PERCENT_DISCOINT_VALUE(NEW_SEGMENT_PERCENT_DISCOINT_VALUE)
    setInputPercentCustomDiscount(newText)
  }

  const radioChange = (opt: { label: string; value: number }, idx: number) => {
    setSelectedPercentDiscointRoundIndex(idx)
    // console.log('opt => ', opt)
    // console.log('idx => ', idx)
  }

  const _getSumDiscount = (): string => {
    let sum = (SEGMENT_PERCENT_DISCOINT_VALUE[selectedPercentDiscointIndex] / 100) * vulueAmountTotal
    if (selectedPercentDiscointIndex === 4) {
      sum = (+inputPercentCustomDiscount / 100) * vulueAmountTotal
    } else {
      sum = (SEGMENT_PERCENT_DISCOINT_VALUE[selectedPercentDiscointIndex] / 100) * vulueAmountTotal
    }
    // เลือกปัดเศษ
    if (selectedPercentDiscointRoundIndex === 0) {
      sum = Math.round(sum)
    } else if (selectedPercentDiscointRoundIndex === 1) {
      sum = Math.floor(sum)
    } else if (selectedPercentDiscointRoundIndex === 2) {
      sum = Math.ceil(sum)
    } else if (selectedPercentDiscointRoundIndex === 3) {
      sum = Math.round(sum * 100) / 100
    }
    // console.log('sum => ', sum)
    const sumTotal = vulueAmountTotal - sum
    const sumDiscount = vulueAmountTotal - sumTotal
    return sumDiscount.toFixed(2)
  }

  const _renderDiscountDetail = () => {
    const vulue = _.isNumber(vulueAmountTotal) ? vulueAmountTotal.toFixed(2) : '0.00'
    return (
      <HStack w='full' style={{ paddingTop: 4, height: 30, paddingLeft: 8, paddingRight: 4 }}>
        <View style={{ flex: 1, height: 30, justifyContent: 'center' }}>
          <XText bold variant='inactive'>
            ยอดออเดอร์ (ก่อนส่วนลด)
          </XText>
        </View>
        <View style={{ flex: 1, height: 30, justifyContent: 'center' }}>
          <XInput
            isDisabled
            style={{
              flex: 0,
              fontWeight: 'bold',
              paddingRight: 3,
              textAlign: 'right',
              fontSize: STYLES.FONT_SIZE_LARGER,
              color: COLORS.TEXT_INACTIVE,
            }}
            keyboardType='numeric'
            value={vulue}
            // isMoney
            isInteger
            maxLength={11}
            onChangeText={(newValue) => {}}
            // selectTextOnFocus={false}
          />
        </View>
      </HStack>
    )
  }

  const _renderCalculatedDiscount = () => {
    const Percent = selectedPercentDiscointIndex === 4 ? inputPercentCustomDiscount : DC[selectedPercentDiscointIndex]
    const DISCOUNT_LABEL = `ส่วนลด ${Percent}%`
    return (
      <HStack w='full' mt='3' style={{ paddingTop: 4, height: 30, paddingLeft: 8, paddingRight: 4 }}>
        <View style={{ flex: 1, height: 30, justifyContent: 'center' }}>
          <XText bold variant='active' style={{ fontSize: STYLES.FONT_SIZE_LARGER }}>
            {DISCOUNT_LABEL}
          </XText>
        </View>
        <View style={{ flex: 1, height: 30, justifyContent: 'center' }}>
          <XInput
            isDisabled
            style={{
              flex: 0,
              fontWeight: 'bold',
              paddingRight: 3,
              textAlign: 'right',
              fontSize: STYLES.FONT_SIZE_LARGER,
              color: COLORS.TEXT_ACTIVE,
            }}
            keyboardType='numeric'
            value={sumDiscount}
            // isMoney
            isInteger
            maxLength={11}
            onChangeText={(newValue) => {}}
            selectTextOnFocus={false}
          />
        </View>
      </HStack>
    )
  }

  const _renderOrderAmountAfterDiscount = () => {
    let sum = (SEGMENT_PERCENT_DISCOINT_VALUE[selectedPercentDiscointIndex] / 100) * vulueAmountTotal
    if (selectedPercentDiscointIndex === 4) {
      sum = (+inputPercentCustomDiscount / 100) * vulueAmountTotal
    } else {
      sum = (SEGMENT_PERCENT_DISCOINT_VALUE[selectedPercentDiscointIndex] / 100) * vulueAmountTotal
    }
    // เลือกปัดเศษ
    if (selectedPercentDiscointRoundIndex === 0) {
      sum = Math.round(sum)
    } else if (selectedPercentDiscointRoundIndex === 1) {
      sum = Math.floor(sum)
    } else if (selectedPercentDiscointRoundIndex === 2) {
      sum = Math.ceil(sum)
    } else if (selectedPercentDiscointRoundIndex === 3) {
      sum = Math.round(sum * 100) / 100
    }
    // console.log('sum => ', sum)
    const sumTotal = vulueAmountTotal - sum
    return (
      <HStack w='full' mt='2' style={{ paddingTop: 4, height: 30, paddingLeft: 8, paddingRight: 4 }}>
        <View style={{ flex: 1, height: 30, justifyContent: 'center' }}>
          <XText variant='inactive' bold style={{ fontSize: STYLES.FONT_SIZE_LARGER }}>
            ยอดออเดอร์ (หลังส่วนลด)
          </XText>
        </View>
        <View style={{ flex: 1, height: 30, justifyContent: 'center' }}>
          <XInput
            isDisabled
            style={{
              flex: 0,
              fontWeight: 'bold',
              paddingRight: 3,
              textAlign: 'right',
              fontSize: STYLES.FONT_SIZE_LARGER,
              color: COLORS.TEXT_INACTIVE,
            }}
            keyboardType='numeric'
            value={_.isNumber(sumTotal) ? sumTotal.toFixed(2) : '0.00'}
            // isMoney
            isInteger
            maxLength={11}
            onChangeText={(newValue) => {}}
            selectTextOnFocus={false}
          />
        </View>
      </HStack>
    )
  }
  // const ROUND_LABEL = selectedPercentDiscointRoundIndex === 0 ? ROUND_UP : selectedPercentDiscointRoundIndex === 1 ? ROUND_DOWN : NO_ROUND
  const DC = SEGMENT_PERCENT_DISCOINT_VALUE
  const sumDiscount = _getSumDiscount()

  const renderMain = () => (
    <View
    // style={{ width: p.op.isWeb() ? 310 : 350, flex: 1 }}
    >
      <View style={{ height: 35, marginTop: 4, justifyContent: 'center', alignItems: 'flex-end' }}>
        <XButton
          // mt='2'
          mr='2'
          variant='outline'
          onPress={() => {
            setEditMode(true)
            SET_SEGMENT_PERCENT_DISCOINT_VALUE_BACKUP_FOR_CANCEL(SEGMENT_PERCENT_DISCOINT_VALUE_BACKUP)
          }}>
          <XText style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.APP_MAIN }}>แก้ไข จำนวน% ในช่อง "ส่วนลดให้ลูกค้า"</XText>
        </XButton>
      </View>
      <View style={{ paddingLeft: 8, paddingRight: 4 }}>
        <XText bold variant='inactive' style={{ fontSize: STYLES.FONT_SIZE_LARGER }}>
          ส่วนลดให้ลูกค้า
        </XText>
        <View style={{ paddingTop: 4, height: 30 }}>
          <Segment
            options={[`${DC[0]}%`, `${DC[1]}%`, `${DC[2]}%`, `${DC[3]}%`, 'ระบุ']}
            selectedIndex={selectedPercentDiscointIndex}
            onSegmentChange={_onSegmentChangeDiscount}
          />
        </View>
        {selectedPercentDiscointIndex === 4 ? (
          <View
            style={{
              paddingTop: p.op.isWeb() ? 16 : 12,
              height: 30,
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
              marginTop: 8,
            }}>
            <XText variant='inactive' style={{ fontSize: STYLES.FONT_SIZE_LARGER }}>
              ส่วนลด (%)
            </XText>
            <View style={{ width: 4 }} />
            <View style={{ width: 50 }}>
              <XInput
                value={inputPercentCustomDiscount}
                keyboardType='number-pad'
                onChangeText={(newText: string) => onChangeTextInputPercentCustomDiscount(newText)}
                style={{ textAlign: 'right', width: 50, paddingRight: 3 }}
              />
            </View>
          </View>
        ) : null}
      </View>
      <View style={{ height: 40 }} />
      <View style={{ paddingTop: 8, height: 150, paddingLeft: 8, paddingRight: 4 }}>
        <Text style={{ fontSize: STYLES.FONT_SIZE_LARGER, color: COLORS.TEXT_INACTIVE, fontWeight: 'bold' }}>เศษสตางค์</Text>
        <XRadioList
          labelStyle={{ fontSize: STYLES.FONT_SIZE_LARGER, color: COLORS.TEXT_ACTIVE }}
          selectedIndex={selectedPercentDiscointRoundIndex}
          options={RADIO_LIST}
          onRadioChange={radioChange}
        />
        {/* <Segment
          options={[ROUND_UP, ROUND_DOWN, NO_ROUND]}
          selectedIndex={selectedPercentDiscointRoundIndex}
          onSegmentChange={_onSegmentChangeDiscountRound}
        /> */}
      </View>

      <View style={{ height: 40 }} />

      <Box w='full'>
        {_renderDiscountDetail()}
        {_renderCalculatedDiscount()}
        {underline()}
        {_renderOrderAmountAfterDiscount()}
      </Box>

      <TouchableOpacity
        onPress={async () => {
          const NEW_SEGMENT_PERCENT_DISCOINT_VALUE_BACKUP = _.clone(SEGMENT_PERCENT_DISCOINT_VALUE_BACKUP)
          NEW_SEGMENT_PERCENT_DISCOINT_VALUE_BACKUP[4] = inputPercentCustomDiscount
          const BACKUP_TO_STORAGE = {
            SEGMENT_PERCENT_DISCOINT_VALUE_BACKUP: NEW_SEGMENT_PERCENT_DISCOINT_VALUE_BACKUP,
            selectedPercentDiscointIndex,
            selectedPercentDiscointRoundIndex,
          }
          await p.op.storageSet(CONS.STORAGE_KEYS.ORDER_PERCENT_DISCOUNT_DATA, BACKUP_TO_STORAGE)
          onSubmit(+sumDiscount)
          onClose()
        }}
        style={{
          marginTop: 20,
          marginLeft: 8,
          marginRight: 8,
          backgroundColor: COLORS.APP_MAIN,
          borderRadius: 7,
          justifyContent: 'center',
          alignItems: 'center',
          height: 40,
        }}>
        <XText color='white' bold style={{ fontSize: STYLES.FONT_SIZE_LARGER }}>
          {`ระบุ "ส่วนลดให้ลูกค้า" ฿${sumDiscount}`}
        </XText>
      </TouchableOpacity>
    </View>
  )

  const renderEditMode = () => {
    const ONE = _.isNil(SEGMENT_PERCENT_DISCOINT_VALUE_BACKUP) ? '0' : SEGMENT_PERCENT_DISCOINT_VALUE_BACKUP[0]
    const TWO = _.isNil(SEGMENT_PERCENT_DISCOINT_VALUE_BACKUP) ? '0' : SEGMENT_PERCENT_DISCOINT_VALUE_BACKUP[1]
    const THREE = _.isNil(SEGMENT_PERCENT_DISCOINT_VALUE_BACKUP) ? '0' : SEGMENT_PERCENT_DISCOINT_VALUE_BACKUP[2]
    const FOUR = _.isNil(SEGMENT_PERCENT_DISCOINT_VALUE_BACKUP) ? '0' : SEGMENT_PERCENT_DISCOINT_VALUE_BACKUP[3]

    const onChacngeEditText = (newText: string, index: number) => {
      const NEW_SEGMENT_PERCENT_DISCOINT_VALUE_BACKUP = _.clone(SEGMENT_PERCENT_DISCOINT_VALUE_BACKUP)
      NEW_SEGMENT_PERCENT_DISCOINT_VALUE_BACKUP[index] = newText
      SET_SEGMENT_PERCENT_DISCOINT_VALUE_BACKUP(NEW_SEGMENT_PERCENT_DISCOINT_VALUE_BACKUP)
    }

    const submitEditMode = async () => {
      const NEW_SEGMENT_PERCENT_DISCOINT_VALUE = _.clone(SEGMENT_PERCENT_DISCOINT_VALUE)
      let isAllNumber = true
      let textWraning = ''
      SEGMENT_PERCENT_DISCOINT_VALUE_BACKUP.map((val: string, index: number) => {
        // console.log('+val => ', +val)
        if (!_.isNaN(+val)) {
          NEW_SEGMENT_PERCENT_DISCOINT_VALUE[index] = +val
        } else {
          isAllNumber = false
          textWraning = `ช่องที่ ${index + 1} ไม่ถูกต้อง`
        }
      })
      if (!isAllNumber) {
        p.op.showConfirmationOkOnly('', textWraning)
        return
      }
      SET_SEGMENT_PERCENT_DISCOINT_VALUE_BACKUP_FOR_CANCEL(SEGMENT_PERCENT_DISCOINT_VALUE_BACKUP)
      SET_SEGMENT_PERCENT_DISCOINT_VALUE(NEW_SEGMENT_PERCENT_DISCOINT_VALUE)
      p.op.showToast('บันทึกสำเร็จ', 'success')
      setEditMode(false)
      const BACKUP_TO_STORAGE = {
        SEGMENT_PERCENT_DISCOINT_VALUE_BACKUP,
        selectedPercentDiscointIndex,
        selectedPercentDiscointRoundIndex,
      }
      await p.op.storageSet(CONS.STORAGE_KEYS.ORDER_PERCENT_DISCOUNT_DATA, BACKUP_TO_STORAGE)
    }
    return (
      <VStack w='full' p='2' space='4'>
        <XText variant='inactive' style={{ fontSize: STYLES.FONT_SIZE_LARGER }}>
          ตั้งค่าจำนวน% ในช่อง "ส่วนลดให้ลูกค้า"
        </XText>
        {/* // ONE // */}
        <HStack w='full' h='10' alignItems='center'>
          <XText variant='inactive' style={{ fontSize: STYLES.FONT_SIZE_NORMAL }}>
            ช่องที่ 1
          </XText>
          <Box pl='3' style={{ width: 80 }}>
            <XInput
              // disabled={true}
              style={{
                flex: 0,
                fontWeight: 'bold',
                paddingRight: 3,
                textAlign: 'right',
                fontSize: STYLES.FONT_SIZE_LARGER,
                color: COLORS.TEXT_INACTIVE,
              }}
              keyboardType='numeric'
              value={ONE}
              // isMoney={true}
              // isInteger={true}
              maxLength={11}
              onChangeText={(newValue: string) => onChacngeEditText(newValue, 0)}
              selectTextOnFocus={false}
            />
          </Box>
        </HStack>
        {/* // TWO //  */}
        <HStack w='full' h='10' alignItems='center'>
          <XText variant='inactive' style={{ fontSize: STYLES.FONT_SIZE_NORMAL }}>
            ช่องที่ 2
          </XText>
          <Box pl='2' style={{ width: 80 }}>
            <XInput
              // disabled={true}
              style={{
                flex: 0,
                fontWeight: 'bold',
                paddingRight: 3,
                textAlign: 'right',
                fontSize: STYLES.FONT_SIZE_LARGER,
                color: COLORS.TEXT_INACTIVE,
              }}
              keyboardType='numeric'
              value={TWO}
              // isMoney={true}
              // isInteger={true}
              maxLength={11}
              onChangeText={(newValue) => onChacngeEditText(newValue, 1)}
              selectTextOnFocus={false}
            />
          </Box>
        </HStack>
        {/* // THREE // */}
        <HStack w='full' h='10' alignItems='center'>
          <XText variant='inactive' style={{ fontSize: STYLES.FONT_SIZE_NORMAL }}>
            ช่องที่ 3
          </XText>
          <Box pl='2' style={{ width: 80 }}>
            <XInput
              // disabled={true}
              style={{
                flex: 0,
                fontWeight: 'bold',
                paddingRight: 3,
                textAlign: 'right',
                fontSize: STYLES.FONT_SIZE_LARGER,
                color: COLORS.TEXT_INACTIVE,
              }}
              keyboardType='numeric'
              value={THREE}
              // isMoney={true}
              // isInteger={true}
              maxLength={11}
              onChangeText={(newValue) => onChacngeEditText(newValue, 2)}
              selectTextOnFocus={false}
            />
          </Box>
        </HStack>
        {/* // FOUR // */}
        <HStack w='full' h='10' alignItems='center'>
          <XText variant='inactive' style={{ fontSize: STYLES.FONT_SIZE_NORMAL }}>
            ช่องที่ 4
          </XText>
          <Box pl='2' style={{ width: 80 }}>
            <XInput
              // disabled={true}
              style={{
                flex: 0,
                fontWeight: 'bold',
                paddingRight: 3,
                textAlign: 'right',
                fontSize: STYLES.FONT_SIZE_LARGER,
                color: COLORS.TEXT_INACTIVE,
              }}
              keyboardType='numeric'
              value={FOUR}
              // isMoney={true}
              // isInteger={true}
              maxLength={11}
              onChangeText={(newValue) => onChacngeEditText(newValue, 3)}
              selectTextOnFocus={false}
            />
          </Box>
        </HStack>

        {/* // FOOTER BTN */}
        <View style={{ flexDirection: 'row' }}>
          <TouchableOpacity
            onPress={() => {
              submitEditMode()
            }}
            style={{
              marginTop: 10,
              // marginLeft: 8,
              // marginRight: 8,
              backgroundColor: COLORS.APP_MAIN,
              borderRadius: 7,
              justifyContent: 'center',
              alignItems: 'center',
              height: 40,
              borderWidth: 1,
              borderColor: COLORS.APP_MAIN,
              flex: 1,
            }}>
            <Text style={{ fontSize: STYLES.FONT_SIZE_LARGER, color: COLORS.WHITE, fontWeight: 'bold' }}>บันทึก</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              SET_SEGMENT_PERCENT_DISCOINT_VALUE_BACKUP(SEGMENT_PERCENT_DISCOINT_VALUE_BACKUP_FOR_CANCEL)
              setEditMode(false)
            }}
            style={{
              marginTop: 10,
              marginLeft: 8,
              // marginRight: 8,
              backgroundColor: COLORS.BG_LIGHT_GREY,
              borderRadius: 7,
              justifyContent: 'center',
              alignItems: 'center',
              height: 40,
              width: 60,
              borderWidth: 1,
              borderColor: COLORS.TEXT_INACTIVE,
            }}>
            <Text style={{ fontSize: STYLES.FONT_SIZE_LARGER, color: COLORS.TEXT_INACTIVE, fontWeight: 'bold' }}>ยกเลิก</Text>
          </TouchableOpacity>
        </View>
      </VStack>
    )
  }

  return (
    <XModal
      // title={shippingTypeItem.label}
      webNumColumns={1}
      title='คำนวณส่วนลดให้ลูกค้าเป็นเปอร์เซ็นต์ (%)'
      contentStyle={{ backgroundColor: COLORS.WHITE }}
      onRequestClose={() => onClose()}
      visible={isVisible}
      hasCloseButton>
      <View style={{ flex: 1 }}>
        {/* <Card
          style={{
            paddingTop: 5,
            marginLeft: 10,
            marginRight: 10,
            paddingBottom: 5,
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}> */}
        {isEditMode ? renderEditMode() : renderMain()}
        {/* </Card> */}
      </View>
    </XModal>
  )
}

export default XPercentDiscount
