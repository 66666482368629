import React from 'react'
import { View, TouchableOpacity } from 'react-native'
import _ from 'lodash'
import FAQ from 'x/config/FAQ'
import * as util from 'x/utils/util'
import { COLORS } from 'x/config/styles'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContent from 'xui/components/XContent'
import XContainer from 'xui/components/XContainer'
import BaseStoreSettingXShippingView, { IShippingMenuList } from 'x/modules/xshipping/BaseStoreSettingXShippingView'
import XIcon from 'xui/components/XIcon'
import { VIEW_LOADING } from 'xui/utils/BaseShareUI'
import XSettingRow from 'xui/components/XSettingRow'
import p from 'x/config/platform-specific'
import HStack from 'xui/components/HStack'
import Box from 'xui/components/Box'
import XCard from 'xui/components/XCard'
import XText from 'xui/components/XText'

export default abstract class BaseUIStoreSettingXShippingView extends BaseStoreSettingXShippingView {
  static displayName = 'BaseUIStoreSettingXShippingView'
  // abstract goBack: () => void

  _renderCustomHeader = () => (
    <XCustomHeader
      headerLeftProps={{
        backIcon: true,
        onPressItem: () => {
          // console.log('onPressGoBack => ', onPressGoBack)
          const onPressGoBack = util.getNavParam(this.props, 'onPressGoBack')
          if (_.isFunction(onPressGoBack)) {
            onPressGoBack()
          }
          util.navGoBack(this.props)
        },
      }}
      title='XShipping'
    />
  )

  _renderXShipping = () => {
    const x = this.props
    const { useXShipping } = this.state
    const textTitle = 'ใช้งาน XShipping'
    return (
      <XCard mx='2' mt='2' py='3'>
        <HStack>
          <XSettingRow
            title={textTitle}
            value={useXShipping}
            onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
            stateKey='useXShipping'
            helper={{
              title: 'XShipping',
              FAQ: FAQ.XSHIPPING,
              initiateOpenIndex: 0,
            }}
          />
        </HStack>
      </XCard>
    )
  }

  _renderShippingList = () => {
    const { shippingMenuList, useXShipping } = this.state
    if (_.isNil(shippingMenuList)) {
      return null
    }
    const shippingMenuListLength = shippingMenuList.length
    return (
      <Box mt='2' _light={{ bg: 'white' }} _dark={{ bg: 'gray.300' }} flex={1} mx='2'>
        {shippingMenuList.map((shipping: IShippingMenuList, index: number) => {
          const textActive = shipping.isActive() && useXShipping ? '(เปิดใช้งานอยู่)' : '(ปิดใช้งานอยู่)'
          const textActiveColor = shipping.isActive() && useXShipping ? COLORS.FORM_SUCCESS : COLORS.TEXT_ACTIVE
          const onPressNav = () => {
            if (!useXShipping) {
              p.op.showConfirmation(
                '',
                'หากต้องการใช้งานขนส่ง กรุณาเปิดใช้งานสวิตซ์ XShipping',
                () => {
                  const newValue = { isValue: true, stateKey: 'useXShipping' }
                  this.isSwitchOnChange(newValue)
                },
                () => null,
                'เปิดใช้งาน XShipping',
                'ปิด'
              )
            } else {
              shipping.nav()
            }
          }
          return (
            <Box h='12'>
              {/* <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                  {shipping.shippingImg}
                </View> */}
              <TouchableOpacity
                onPress={() => onPressNav()}
                key={shipping.shippingName}
                style={{ flexDirection: 'row', flex: 1, alignItems: 'center' }}>
                <View style={{ flex: 1, paddingLeft: 6 }}>{shipping.shippingImg}</View>
                <View style={{ width: 100 }}>
                  <XText color={textActiveColor} pr='2' textAlign='right'>
                    {textActive}
                  </XText>
                </View>
                <View style={{ width: 35 }}>
                  <XIcon name='arrowright' family='AntDesign' style={{ width: 25, minWidth: 25, flex: 0 }} />
                </View>
              </TouchableOpacity>
              {shippingMenuListLength === index + 1 ? null : (
                <View
                  style={{
                    borderBottomColor: COLORS.GREY,
                    borderBottomWidth: 1,
                    marginLeft: 10,
                    marginRight: 10,
                  }}
                />
              )}
            </Box>
          )
        })}
      </Box>
    )
  }

  render() {
    const { isPageLoading, pageLoadingWidth, pageLoadingHeight } = this.state
    return (
      <XContainer>
        <XContent>
          <View
            onLayout={(event) => {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { x, y, width, height } = event.nativeEvent.layout
              util.setStatePromise(this, { pageLoadingWidth: width, pageLoadingHeight: height })
            }}>
            {this._renderCustomHeader()}
            {this._renderXShipping()}
            {this._renderShippingList()}
          </View>
          {isPageLoading ? VIEW_LOADING(pageLoadingWidth, pageLoadingHeight) : null}
        </XContent>
      </XContainer>
    )
  }
}
