/* eslint-disable @typescript-eslint/no-misused-promises */
import React from 'react'

import _ from 'lodash'
// import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import p from 'x/config/platform-specific'
import BaseInAppPurchaseView from 'x/modules/subscription/BaseInAppPurchaseView'
import { COLORS } from 'x/config/styles'
import XCard from 'xui/components/XCard'
import XText from 'xui/components/XText'
import HStack from 'xui/components/HStack'
import VStack from 'xui/components/VStack'
import { ScaledSize, View, Dimensions, TouchableOpacity, ImageBackground, ScrollView, Animated, Easing } from 'react-native'

// import { withIAPContext } from 'react-native-iap'
// import { createStackNavigator } from '@react-navigation/stack'
import ProfilePackage from 'xui/components/ProfilePackage'
import PackageTag from 'xui/components/PackageTag'
import * as xFmt from 'x/utils/formatter'
import XIcon from 'xui/components/XIcon'
import XRedDiscount from 'xui/components/XRedDiscount'
import XUISubcriptionDetail from './XUISubcriptionDetail'

const BRONZ_BG = require('../../img/packge_bg/bronz.png')
const SILVER_BG = require('../../img/packge_bg/silver.png')
const GOLD_BG = require('../../img/packge_bg/gold.png')
const PLATINUM_BG = require('../../img/packge_bg/platinum.png')

const PACKAGE_BG_IMG = [BRONZ_BG, SILVER_BG, GOLD_BG, PLATINUM_BG]

const window: ScaledSize = Dimensions.get('window')
// const PAGE_HIGHT = 900
const PAGE_HIGHT = p.op.isWeb() ? 900 : window.height
const PAGE_WIDTH = p.op.isWeb() ? 420 : window.width
// const ElementsText = {
//   AUTOPLAY: 'AutoPlay',
// }
const packageLabelList = ['Bronze', 'Silver', 'Gold', 'Platinum']

// const opacityStart = new Animated.Value(0)
// const opacityEnd = new Animated.Value(0)

// export const screens = [
//   {
//     name: 'Subscriptions',
//     title: 'Subscriptions',
//     component: withIAPContext(Subscriptions),
//     section: 'Context',
//     color: '#cebf38',
//   },
//   {
//     name: 'Home',
//     component: Home,
//     section: 'Context',
//     color: '#cebf38',
//   },
// ]

// const Stack = createStackNavigator()

// export const StackNavigator = () => {
//   return (
//     <Stack.Navigator screenOptions={{ title: 'MainlyPaleo Subscriptions' }}>
//       {screens.map(({ name, component, title }) => (
//         <Stack.Screen
//           key={name}
//           name={name}
//           component={component}
//           //hide the header on these screens
//           options={{
//             title: title,
//             headerShown: name === 'Home' || name === 'Subscriptions' ? false : true,
//           }}
//         />
//       ))}
//     </Stack.Navigator>
//   )
// }

export default abstract class BaseUIInAppPurchaseView extends BaseInAppPurchaseView {
  abstract _purchase: (index: number, type: 'month' | 'year') => void
  abstract _renderCarousel: () => JSX.Element
  _renderHeader = () => {
    const { mode } = this.state
    let title = 'เลือกแพ็กเพจที่เหมาะสมกับคุณ'
    if (mode === 'continue_package') {
      title = 'ต่อแพ็กเกจ'
    }
    return (
      <XCustomHeader
        title={title}
        headerLeftProps={{ backIcon: true, onPressItem: () => this.goBack() }}
        // renderHeaderRight={this._renderHeaderRight}
      />
    )
  }

  _renderAbsolutePackageTag = (packageType: number) => (
    <View
      style={{
        position: 'absolute',
        left: p.op.isWeb() ? 30 : 20,
        top: p.op.isWeb() ? 8 : -16,
        zIndex: 70,
      }}>
      <PackageTag typeId={packageType} width={100} height={40} />
    </View>
  )

  _getPackageOneMonthPrice = (index: number): number => {
    const { subscriptionPackageList } = this.state
    // console.log('get subscriptionPackageList => ', subscriptionPackageList)
    if (_.isNil(subscriptionPackageList)) {
      return 0
    }
    const { items } = subscriptionPackageList
    const PACKAGE_SKU = this.PACKAGE_SKU_CYCLE_1
    const sku = PACKAGE_SKU[index]
    let price = 0
    items.forEach((item) => {
      if (item.sku === sku) {
        price = item.price_with_vat
      }
    })
    return price
  }

  _getPackageOneYearPrice = (index: number): number => {
    const { subscriptionPackageList } = this.state
    // console.log('get subscriptionPackageList => ', subscriptionPackageList)
    if (_.isNil(subscriptionPackageList)) {
      return 0
    }
    const { items } = subscriptionPackageList
    const PACKAGE_SKU = this.PACKAGE_SKU_CYCLE_12
    const sku = PACKAGE_SKU[index]
    let price = 0
    items.forEach((item) => {
      if (item.sku === sku) {
        price = item.price_with_vat
      }
    })
    return price
  }

  _renderDiscountView = (value: number) => {
    return (
      <VStack style={{ position: 'absolute', top: 0, right: 0 }}>
        <XRedDiscount number={value} />
      </VStack>
    )
  }

  _renderPackage = (index: number) => {
    const { mode } = this.state
    const price = this._getPackageOneMonthPrice(index)
    const priceYearly = this._getPackageOneYearPrice(index)
    const oneMonthPriceInOneYear = price * 12
    const differencePrice = oneMonthPriceInOneYear - priceYearly
    const discount = (differencePrice / oneMonthPriceInOneYear) * 100
    const averagePrice = priceYearly / 12
    // return null

    let oneMonthText = 'รายเดือน'
    let OneYearText = 'รายปี'
    let btnText = 'ซื้อแพ็กเกจ'
    if (mode === 'continue_package') {
      oneMonthText = 'ต่อแพ็กเกจ 1 เดือน'
      OneYearText = 'ต่อแพ็กเกจ 1 ปี'
      btnText = 'ต่อแพ็กเกจ'
    }

    return (
      <VStack flex={1}>
        {this._renderAbsolutePackageTag(index + 2)}
        <XCard w='full' h={370} p='2' key={`_renderPackage${index}`} borderRadius={30}>
          {/* <HStack w='full' alignItems={'center'} justifyContent={'center'}>
            <XText bold fontSize={'2xl'}>
              {packName}
            </XText>
          </HStack> */}
          {/* <HStack mt='2' borderBottomColor={COLORS.BG_LIGHT_GREY} borderBottomWidth={'2'} /> */}
          <VStack w='full' alignItems={'center'} justifyContent={'center'}>
            {/* // รายเดือน */}
            <VStack
              alignItems={'center'}
              justifyContent={'center'}
              mt='8'
              pb='4'
              w='full'
              borderBottomColor={COLORS.TEXT_INACTIVE}
              borderBottomWidth={'2'}>
              <VStack alignItems={'center'}>
                <XText bold fontSize={'lg'}>
                  {oneMonthText}
                </XText>
                <HStack alignItems={'center'}>
                  <XText bold fontSize={'2xl'}>
                    {`${xFmt.formatCurrency(price)}`}
                  </XText>
                  <XText bold fontSize={'lg'}>
                    {' / เดือน'}
                  </XText>
                </HStack>
              </VStack>
              <TouchableOpacity
                onPress={() => this._onPressPackageBtn(index, 'month')}
                style={{
                  backgroundColor: COLORS.WHITE,
                  borderColor: COLORS.APP_MAIN,
                  borderWidth: 1,
                  marginTop: 4,
                  borderRadius: 90,
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 180,
                }}>
                <XText my='4' bold fontSize={'lg'} color={COLORS.APP_MAIN}>
                  {btnText}
                </XText>
              </TouchableOpacity>
            </VStack>

            {/* // รายปี */}
            <VStack alignItems={'center'} justifyContent={'center'} mt='2' w='full'>
              {this._renderDiscountView(Math.round(discount))}
              <VStack alignItems={'center'}>
                <XText bold fontSize={'lg'}>
                  {OneYearText}
                </XText>
                <HStack alignItems={'center'}>
                  <XText bold fontSize={'2xl'}>
                    {`${xFmt.formatCurrency(priceYearly)}`}
                  </XText>
                  <XText bold fontSize={'lg'}>
                    {' / ปี'}
                  </XText>
                </HStack>
              </VStack>
              {/* <XText bold fontSize={'lg'} color={COLORS.RED}>
                {`(ประหยัดขึ้น ${Math.round(discount)}%)`}
              </XText> */}
              <XText bold variant='inactive'>
                {`เฉลี่ย ${xFmt.formatCurrency(averagePrice)}/เดือน`}
              </XText>
              <TouchableOpacity
                onPress={() => this._onPressPackageBtn(index, 'year')}
                style={{
                  backgroundColor: COLORS.APP_MAIN,
                  marginTop: 8,
                  borderRadius: 90,
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 180,
                }}>
                <XText my='4' bold color={'white'} fontSize={'lg'}>
                  {btnText}
                </XText>
              </TouchableOpacity>
              {/* <VStack mt='2'>
                <XText bold variant='inactive'>{`(เฉลี่ย ${averagePrice} บาท/เดือน)`}</XText>
              </VStack> */}
            </VStack>
          </VStack>
        </XCard>
      </VStack>
    )
  }

  _renderPackagedetails = () => {
    const { subscriptionPackageList, openPackageIndex } = this.state
    if (_.isNil(subscriptionPackageList)) {
      return null
    }
    const { PACKAGE_SKU_CYCLE_12 } = this
    const { items } = subscriptionPackageList
    const sku = PACKAGE_SKU_CYCLE_12[openPackageIndex]
    let dataJson = null
    items.forEach((item) => {
      if (item.sku === sku) {
        dataJson = item.data_json
      }
    })
    if (_.isNil(dataJson)) {
      return null
    }
    // console.log('items =>? ', items)
    // console.log('openPackageIndex => ', openPackageIndex)
    return (
      <VStack
        w='full'
        mt='2'
        px='2'
        style={{
          alignItems: 'center',
          marginBottom: 8,
          zIndex: 98,
        }}>
        <XCard p='2' alignItems={'cneter'} justifyItems={'center'} w={'full'}>
          <VStack alignItems={'center'} justifyContent={'center'} w='full'>
            <XText bold fontSize={'2xl'}>{`แพ็กเกจ ${packageLabelList[openPackageIndex]}`}</XText>
            <HStack mt='2' borderBottomColor={COLORS.TEXT_ACTIVE} borderBottomWidth={'1'}>
              <XText bold fontSize={'lg'}>{`สิทธิประโยชน์ในการใช้งาน`}</XText>
            </HStack>
            <HStack h='4' />
            <XUISubcriptionDetail subscription={dataJson} type={openPackageIndex + 2} />
          </VStack>
        </XCard>
      </VStack>
    )
  }

  _renderBackground = () => {
    const { openPackageIndex, contentHight } = this.state
    return (
      <View style={{ flex: 1, zIndex: 0, height: contentHight, position: 'absolute', left: 0, right: 0 }}>
        <ImageBackground source={PACKAGE_BG_IMG[openPackageIndex]} resizeMode='stretch' style={{ flex: 1 }} />
      </View>
    )
  }

  _renderContent = () => {
    return (
      <VStack
        w='full'
        onLayout={(event) => {
          const { x, y, width, height } = event.nativeEvent.layout
          this.setState({ contentHight: height })
          // util.setStatePromise(this, { loadingWidth: width, loadingHight: height })
        }}>
        {this._renderCarousel()}
        {this._renderPackagedetails()}
      </VStack>
    )
  }

  _renderMain = () => {
    const { subscriptionPackageList } = this.state
    if (_.isNil(subscriptionPackageList)) {
      return null
    }
    return (
      <VStack w='full' alignItems={'center'} justifyContent={'center'}>
        {this._renderBackground()}
        {this._renderContent()}
      </VStack>
    )
  }

  _renderProfile = () => {
    const { subscription } = this.props
    const { profile } = this.state
    // console.log('profile // => ', profile)
    if (_.isNil(profile) || _.isNil(subscription)) {
      return null
    }
    const typeId: number = subscription.has('type') ? subscription.get('type') : 0
    // console.log('profile => ', profile)
    return <ProfilePackage profile={profile} typeId={typeId} />
  }

  _renderBtnNextCarouselPage = () => {
    const { openPackageIndex } = this.state
    if (openPackageIndex === 3) {
      return null
    }
    // console.log('PAGE_HIGHT => ', PAGE_HIGHT)
    const display = p.op.isWeb() ? 3 : 1.5
    return (
      <View
        style={{
          position: 'absolute',
          right: 0,
          top: PAGE_HIGHT / display,
          zIndex: 70,
          // width: '100%',
          // alignItems: 'flex-end',
          // justifyContent: 'flex-end',
        }}>
        <HStack>
          <TouchableOpacity
            onPress={() => this._nextCarouselPage()}
            style={{
              marginRight: 8,
              width: 52,
              height: 52,
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgba(52, 52, 52, 0.2)',
              borderRadius: 90,
            }}>
            <XIcon name='keyboard-arrow-right' family='MaterialIcons' size={'md'} color={COLORS.WHITE} />
          </TouchableOpacity>
        </HStack>
      </View>
    )
  }

  _renderBtnBackCarouselPage = () => {
    const { openPackageIndex } = this.state
    if (openPackageIndex === 0) {
      return null
    }
    const display = p.op.isWeb() ? 3 : 1.5
    return (
      <View
        style={{
          position: 'absolute',
          left: 0,
          top: PAGE_HIGHT / display,
          zIndex: 70,
          // width: '100%',
          // alignItems: 'flex-end',
          // justifyContent: 'flex-end',
        }}>
        <HStack>
          <TouchableOpacity
            onPress={() => this._goBackCarouselPage()}
            style={{
              marginLeft: 8,
              width: 52,
              height: 52,
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgba(52, 52, 52, 0.2)',
              borderRadius: 90,
            }}>
            <XIcon name='keyboard-arrow-left' family='MaterialIcons' size={'md'} color={COLORS.WHITE} />
          </TouchableOpacity>
        </HStack>
      </View>
    )
  }

  _nextCarouselPage = () => {
    const { openPackageIndex } = this.state
    // console.log('openPackageIndex => ', openPackageIndex)
    // console.log('this.carouselRef => ', this.carouselRef)
    if (openPackageIndex === 3 || this.inProcess) {
      return
    }
    this.carouselRef.current?.scrollTo({ count: 1, animated: true })
    this.setState({
      openPackageIndex: openPackageIndex + 1,
    })

    // Animated.timing(opacityStart, {
    //   toValue: 1,
    //   duration: 10000, // Adjust as needed
    //   easing: Easing.linear,
    //   useNativeDriver: true,
    //   // delay: 2000,
    // }).start()

    // opacityStart.setValue(0)
  }

  _goBackCarouselPage = () => {
    const { openPackageIndex } = this.state
    if (openPackageIndex === 0 || this.inProcess) {
      return
    }
    // console.log('this.carouselRef => ', this.carouselRef)
    this.carouselRef.current?.scrollTo({ count: -1, animated: true })

    this.setState({
      openPackageIndex: openPackageIndex - 1,
    })

    // Animated.timing(opacityStart, {
    //   toValue: 0,
    //   duration: 1000, // Adjust as needed
    //   easing: Easing.linear,
    //   useNativeDriver: true,
    // }).start()

    // opacityStart.setValue(1)
  }

  render() {
    const { openPackageIndex, isLoading } = this.state
    return (
      <XContainer>
        {this._renderHeader()}
        {this._renderBtnNextCarouselPage()}
        {this._renderBtnBackCarouselPage()}
        {this._renderProfile()}
        <XContent color={COLORS.BG_SOFT_GREY}>{this._renderMain()}</XContent>
      </XContainer>
    )
  }
}
