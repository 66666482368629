import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as StoreActions from 'x/modules/store/StoreState'
import XContainer from 'xui/components/XContainer'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContent from 'xui/components/XContent'
import HStack from 'xui/components/HStack'
import Box from 'xui/components/Box'
import NB from 'x/config/nativebase'
import { getSelectedStore, getEditingStore } from 'x/redux/selectors'

import p from 'x/config/platform-specific'
import XCard from 'xui/components/XCard'
import XSettingRow from 'xui/components/XSettingRow'
import { ActionApiParams, ISelectedStoreMap, IXScreenProps } from 'x/types'
import * as util from 'x/utils/util'
import XText from 'xui/components/XText'
import VStack from 'xui/components/VStack'
import XButton from 'xui/components/XButton'
import XNumericInput from 'xui/components/XNumericInput'

interface IStoreSettingShipBeforePayAndCodProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
  submitEditing: ActionApiParams
  // addNewStoreSetting: (newSetting: any) => void
}

const StoreSettingShipBeforePayAndCod: React.FC<IStoreSettingShipBeforePayAndCodProps> = (props) => {
  const { submitEditing, selectedStore } = props
  const s_verify_slip_expire_hour_limit: number = selectedStore.get('s_verify_slip_expire_hour_limit') || 0
  const [verifySlipExpireHourLimit, setVerifySlipExpireHourLimit] = React.useState<number>(s_verify_slip_expire_hour_limit)
  // @ts-ignore
  const isSubmitValueShow = parseInt(verifySlipExpireHourLimit) !== s_verify_slip_expire_hour_limit

  // const [visibleShippingTypes, setVisibleShippingTypes] = useState<number[]>([])

  // eslint-disable-next-line react/no-unstable-nested-components
  const _renderHeader = () => (
    <XCustomHeader title='การชำระ' headerLeftProps={{ backIcon: true, onPressItem: () => util.navGoBack(props) }} />
  )

  // const _onSubmit = async (value: boolean, stateKey: string) => {
  const _onSubmit = async (value: any, stateKey: string) => {
    const store_id = selectedStore.get('id')
    const response = await new Promise((resolve) => {
      // @ts-ignore
      submitEditing({
        body: { store_id, [stateKey]: value },
        successCallback: resolve,
        failedCallback: () => resolve(null),
      })
    })
    // console.log('response => ', response)
  }

  const isSwitchOnChange = async (newValue: { isValue: boolean; stateKey: string }) => {
    // const isValueToInt = boolToInt(newValue.isValue)
    _onSubmit(newValue.isValue, newValue.stateKey)
  }

  // const boolToInt = (flag) => (flag ? 1 : 0)

  const boxOne = () => {
    const s_accept_cod: boolean = selectedStore.get('s_accept_cod')
    let supTitleLable = p.op.t('StoreSetting.NoteFalseShipBeforePayCod')
    if (s_accept_cod) {
      supTitleLable = p.op.t('StoreSetting.NoteTrueShipBeforePayCod')
    }
    return (
      <HStack py='1.5'>
        <XSettingRow
          title={p.op.t('StoreSetting.MenuShipBeforePaySwitchCod')}
          supTitle={supTitleLable}
          value={s_accept_cod}
          onClick={(newValue: { isValue: boolean; stateKey: string }) => isSwitchOnChange(newValue)}
          stateKey='s_accept_cod'
        />
      </HStack>
    )
  }

  const boxTwo = () => {
    const s_accept_ship_before_pay_if_cod: boolean = selectedStore.get('s_accept_ship_before_pay_if_cod')
    let supTitleLable = p.op.t('StoreSetting.NoteFalseAcceptShipBeforePayIfCOD')
    if (s_accept_ship_before_pay_if_cod) {
      supTitleLable = p.op.t('StoreSetting.NoteTrueAcceptShipBeforePayIfCOD')
    }
    return (
      <HStack py='1.5'>
        <XSettingRow
          title={p.op.t('StoreSetting.MenuAcceptShipBeforePayIfCOD')}
          supTitle={supTitleLable}
          value={s_accept_ship_before_pay_if_cod}
          onClick={(newValue: { isValue: boolean; stateKey: string }) => isSwitchOnChange(newValue)}
          stateKey='s_accept_ship_before_pay_if_cod'
        />
      </HStack>
    )
  }

  const boxThree = () => {
    const s_accept_ship_before_pay_if_not_cod: boolean = selectedStore.get('s_accept_ship_before_pay_if_not_cod')
    let supTitleLable = p.op.t('StoreSetting.NoteFalseAcceptShipBeforePayIfNotCOD')
    if (s_accept_ship_before_pay_if_not_cod) {
      supTitleLable = p.op.t('StoreSetting.NoteTrueAcceptShipBeforePayIfNotCOD')
    }
    return (
      <HStack py='1.5'>
        <XSettingRow
          title={p.op.t('StoreSetting.MenuAcceptShipBeforePayIfNotCOD')}
          supTitle={supTitleLable}
          value={s_accept_ship_before_pay_if_not_cod}
          onClick={(newValue: { isValue: boolean; stateKey: string }) => isSwitchOnChange(newValue)}
          stateKey='s_accept_ship_before_pay_if_not_cod'
        />
      </HStack>
    )
  }

  const boxFour = () => {
    const s_use_verify_slip: boolean = selectedStore.get('s_use_verify_slip')
    let supTitleLable = p.op.t('StoreSetting.NoteFalseUseVerifySlip')
    if (s_use_verify_slip) {
      supTitleLable = p.op.t('StoreSetting.NoteTrueUseVerifySlip')
    }
    return (
      <HStack py='1.5'>
        <XSettingRow
          title={p.op.t('StoreSetting.MenuAcceptUseVerifySlip')}
          supTitle={supTitleLable}
          value={s_use_verify_slip}
          onClick={(newValue: { isValue: boolean; stateKey: string }) => isSwitchOnChange(newValue)}
          stateKey='s_use_verify_slip'
        />
      </HStack>
    )
  }

  const boxFive = () => (
    <HStack py='1.5' space='1'>
      <VStack flex={1}>
        <XText>การ verify slip จะจำกัดชั่วโมงหมดอายุทั้งสิ้น</XText>
        <XText>บลา บลา บลา</XText>
        <XText>0 หมายถึง ไม่จำกัด</XText>
      </VStack>
      <VStack w='150px' space='1'>
        <XNumericInput
          h='34px'
          minValue={0}
          maxValue={9999999}
          value={verifySlipExpireHourLimit}
          onChangeText={(newValue) => setVerifySlipExpireHourLimit(newValue)}
        />
        {isSubmitValueShow ? (
          <XButton
            // @ts-ignore
            onPress={() => _onSubmit(parseInt(verifySlipExpireHourLimit), 's_verify_slip_expire_hour_limit')}>
            ยืนยัน
          </XButton>
        ) : null}
      </VStack>
    </HStack>
  )

  // eslint-disable-next-line react/no-unstable-nested-components
  const _renderBody = () => (
    <XCard mx='2' mt='2' mb='2'>
      {boxOne()}
      <Box _dark={{ borderBottomColor: 'white' }} _light={{ borderBottomColor: 'black' }} borderBottomWidth='1' />
      {boxTwo()}
      <Box _dark={{ borderBottomColor: 'white' }} _light={{ borderBottomColor: 'black' }} borderBottomWidth='1' />
      {boxThree()}
      {/* <Box _dark={{ borderBottomColor: 'white' }} _light={{ borderBottomColor: 'black' }} borderBottomWidth='1' />
      {boxFour()} */}
      {/* <Box _dark={{ borderBottomColor: 'white' }} _light={{ borderBottomColor: 'black' }} borderBottomWidth='1' /> */}
      {/* {boxFive()} */}
    </XCard>
  )

  return (
    <XContainer>
      {_renderHeader()}
      <XContent px='2' pb='2'>
        <Box w='full' mt='2' borderRadius='md' _light={{ bg: 'white' }} _dark={{ bg: NB.C.D.BG.CLEAR }}>
          {_renderBody()}
        </Box>
        {/* {isLoading ? VIEW_LOADING(windowWidth, windowHeight) : null} */}
      </XContent>
    </XContainer>
  )
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// // @ts-ignore
// StoreSettingShipBeforePayAndCod.navigationOptions = {
//   header: null,
// }

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
    editingStore: getEditingStore(state),
  }),
  (dispatch) => ({
    submitEditing: bindActionCreators(StoreActions.submitEditingStore, dispatch),
    resetEditingStore: bindActionCreators(StoreActions.resetEditingStore, dispatch),
    // storeActions: bindActionCreators(StoreState, dispatch),
  })
)(StoreSettingShipBeforePayAndCod)
