import React from 'react'
import { Keyboard } from 'react-native'
import { FormikHelpers as FormikActions, FormikProps } from 'formik'
import api from 'x/utils/api'
// import { delay } from 'x/utils/util'
// import * as auth from 'x/utils/authentication'
import p from 'x/config/platform-specific'

import Yup, { STORE_NAME, isRequired } from 'x/utils/validator'
import _ from 'lodash'
import { delay } from 'x/utils/util'
import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import XText from 'xui/components/XText'
import XFormikInput from 'xui/components/XFormikInput'
import * as NavActions from 'x/utils/navigation'
import BaseOnboardingView from './BaseOnboardingView'

// import { log } from 'x/utils/util'

export default class OnboardingFirstStoreNameView extends BaseOnboardingView {
  static displayName = 'OnboardingFirstStoreNameView'

  constructor(props) {
    super(props)
    this.initialValues = {
      name: '',
    }

    this.validationSchema = Yup.object().shape({
      name: isRequired(STORE_NAME),
    })

    this.txtHeaderTitle = 'ตั้งชื่อร้านแรกของคุณ'
    this.txtBtnFooter = 'เสร็จสิ้น'
  }

  componentDidMount() {
    setTimeout(this._autoFocusFirstInput, 1500)
  }

  renderContent(fProps: FormikProps<any>): React.ReactNode {
    return (
      <VStack w='full' space='1'>
        <HStack w='full' p='2' justifyContent='center' alignItems='center'>
          {this._renderHeaderTitle()}
        </HStack>

        <XText variant='inactive'>ชื่อร้านของฉัน</XText>
        <XFormikInput
          flex={1}
          autoCapitalize='none'
          autoFocus
          // style={this.styles.input}
          name='name'
          placeholder='ชื่อร้านของฉันที่ลูกค้ารู้จัก เปลื่ยนภายหลังได้ ที่ "ตั้งค่า" ของร้าน'
          order={4}
          handleRegisterRef={this._doRegisterRef}
          handleOnSubmitEditing={fProps.handleSubmit}
          returnKeyType='done'
        />
      </VStack>
    )
  }

  async handleSubmitForm(values: any, formActions: FormikActions<any>): Promise<void> {
    Keyboard.dismiss()

    const { navigation } = this.props
    let body
    body = _.cloneDeep(values)
    body.s_sender_addr_t1_use_mine = true
    body.s_sender_addr_t2_use_mine = false

    const apiOptions = {
      messages: {
        successMsg: 'ยินดีด้วยคุณสามารถเริ่มต้นใช้งานแอพได้แล้ว',
        errorMsg: 'เกิดข้อผิดพลาดในการบันทึกชื่อร้าน',
      },
      showSpinner: true,
    }
    try {
      const res = await api.put(api.PUT_CREATE_STORE, body, apiOptions)
      if (!res) {
        throw new Error('Failed to create first store')
        // navigation.dispatch(NavActions.switchToAppLauncher)
        // log('No missing store anymore')
        // return
      }
      // log('handleSubmitForm res => ', res)
      await delay(1000)
      // this.props.navigation.navigate('AuthStackNavigator')
      // navigation.dispatch(NavActions.switchToAuthStackNavigator)
      if (p.op.isWeb()) {
        // ไป LandingView แล้วให้ landing view fetch เอาเอง
        navigation.dispatch(NavActions.resetToFirstPage)
      } else {
        navigation.dispatch(NavActions.resetToAuthStackNavigator())
      }
    } catch (err) {
      // log('handleSubmitForm err => ', err)
      // navigation.dispatch(NavActions.switchToAppLauncher)
      formActions.resetForm()
    }
  }
}
