import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// import * as StoreState from 'x/modules/store/StoreState'
import { getSelectedStore } from 'x/redux/selectors'
import * as profileActions from 'x/modules/profile/ProfileState'
import * as StoreActions from 'x/modules/store/StoreState'
import ReportWarehouseAdjustment from './ReportWarehouseAdjustment'

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
    // myStores: getMyStores(state),
  }),
  (dispatch) => ({
    searchMumber: bindActionCreators(profileActions.fetchProfileList, dispatch),
    // fetchInitializeData: bindActionCreators(StoreState.fetchInitializeData, dispatch),
    getPermissionMemberList: bindActionCreators(StoreActions.getPermissionMemberList, dispatch),
    dispatch,
  })
)(ReportWarehouseAdjustment)
