import React from 'react'
import _ from 'lodash'

import * as util from 'x/utils/util'
import api from 'x/utils/api'
import { IApiOptions, IXScreenProps } from 'x/index'
import { IPaymentPartnerTransactions } from 'xui/modules/payment/PaymentPTTComponent'

export interface BasePaymentPTTTransactionsProps extends IXScreenProps {
  selectedStore: any
}

export interface BasePaymentPTTTransactionsState {
  ptts: IPaymentPartnerTransactions[] | null
  dataOnChange: boolean
}

export default abstract class BasePaymentPTTTransactions extends React.Component<
  BasePaymentPTTTransactionsProps,
  BasePaymentPTTTransactionsState
> {
  // DISPLAY_TABS: IUserListTab[]
  // _tabRef: React.RefObject<any>
  // helperProfileId: number

  protected constructor(props) {
    super(props)

    this.state = {
      ptts: null,
      dataOnChange: false,
    }
    // this._tabRef = React.createRef()
  }

  // 1851271
  // 1851289
  // 1851290
  componentDidMount() {
    // const { navigation } = this.props
    // const { state } = navigation
    // const { ptts } = state.params
    const ptts = util.getNavParam(this.props, 'ptts')
    util.setStatePromise(this, { ptts })
  }

  getBeamTransactionStatus = async (ppt_id: number) => {
    const selectedStore = util.getSelectedStoreFromRedex()
    const reqBody = { store_id: selectedStore.get('id'), ppt_id }
    const apiOptions: IApiOptions = {
      showSpinner: true,
    }
    const res: IPaymentPartnerTransactions = await api.postV2('payment/partner/transaction/update', reqBody, apiOptions)
    if (res.id) {
      const { ptts } = this.state
      let dataChange = false
      const newPtts = []
      ptts.map((data: IPaymentPartnerTransactions) => {
        if (data.id === res.id) {
          newPtts.push(res)
          if (data.status !== res.status) {
            dataChange = true
          }
        } else {
          newPtts.push(data)
        }
      })
      await util.setStatePromise(this, {
        ptts: newPtts,
        dataOnChange: dataChange,
      })
    }
  }

  navBack = () => {
    const { ptts, dataOnChange } = this.state
    const navBackAction = util.getNavParam(this.props, 'navBackAction')
    if (_.isFunction(navBackAction) && dataOnChange) {
      navBackAction()
    }
    util.navGoBack(this.props)
  }
}
