import React from 'react'
import _ from 'lodash'

import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'
import { isImmutable } from 'immutable'

import CONS from 'x/config/constants'
import { IXScreenProps } from 'x/types'

export interface BaseStoreSettingPrintingBarcodeProps extends IXScreenProps {
  selectedStore: any
  addPrinting: (payload: any) => void
  editPrinting: (payload: any) => void
}

export interface BaseStoreSettingPrintingBarcodeState {
  selectedPrintingTypeId: number
  printingBarcodestoreSetting: IPrintingBarcodeStoreSetting
  chooseOptionBarcodeIndex: number
  OPTION_PRINTING_BARCODE: IOptionPrintingBarcode[]
  storePrintingId: number | null
  isPageOnChange: boolean
  backupPrintingBarcodestoreSetting: IPrintingBarcodeStoreSetting
}

export interface IPrintingBarcodeType {
  id: number
  url: string
  txt: string
  help?: JSX.Element
}

export interface IPrintingBarcodeStoreSetting {
  id?: number
  t: '3'
  s: '1'
  a: boolean
  p: {
    i: number
    t: 'b' | 'q' // t (type) => b = barcode, q = qrcode (default)
    d: number // ประเภทของรหัสสินค้าว่าเป็น SKU หรือ UPC
    n: number // ชื่อสินค้า
    c: number // ตัวหนังสือ: QR Code/Barcode
    v: number // รูป: QR Code/Barcode
    l: 'th'
  }
}

export interface IOptionPrintingBarcode {
  textLabel: string
  value: () => boolean
  onChange: (newValue: boolean) => void
}

const DEFAULT_PRINTING_BARCODE_TYPE = [
  {
    id: 5030,
    url: 'https://s1.xselly.com/x/samples/pl/barcode_50x30.pdf',
    txt: 'ขนาด 50x30 mm.',
  },
  {
    id: 5040,
    url: 'https://s1.xselly.com/x/samples/pl/barcode_50x40.pdf',
    txt: 'ขนาด 50x40 mm.',
  },
  {
    id: 6040,
    url: 'https://s1.xselly.com/x/samples/pl/barcode_60x40.pdf',
    txt: 'ขนาด 60x40 mm.',
    // help: (
    //   <HelpButton
    //     message={`รองรับรูปแนบใบปะหน้าพัสดุ เช่น จาก Shopee หรือ Lazada`}
    //     style={{ alignItems: 'center', justifyContent: 'center', paddingBottom: 10 }}
    //   />
    // ),
  },
  {
    id: 8040,
    url: 'https://s1.xselly.com/x/samples/pl/barcode_80x40.pdf',
    txt: 'ขนาด 80x40 mm.',
  },
  {
    id: 4,
    url: 'https://s1.xselly.com/x/samples/pl/barcode_A4.pdf',
    txt: 'ขนาด A4',
  },
]

const DEFAULT_PRINTING_QR_CODE_TYPE = [
  {
    id: 4020,
    url: 'https://s1.xselly.com/x/samples/pl/qr_40x20.pdf',
    txt: 'ขนาด 40x20 mm.',
  },
  {
    id: 5030,
    url: 'https://s1.xselly.com/x/samples/pl/qr_50x30.pdf',
    txt: 'ขนาด 50x30 mm.',
  },
  {
    id: 5040,
    url: 'https://s1.xselly.com/x/samples/pl/qr_50x40.pdf',
    txt: 'ขนาด 50x40 mm.',
  },
  {
    id: 6040,
    url: 'https://s1.xselly.com/x/samples/pl/qr_60x40.pdf',
    txt: 'ขนาด 60x40 mm.',
  },
  {
    id: 8040,
    url: 'https://s1.xselly.com/x/samples/pl/qr_80x40.pdf',
    txt: 'ขนาด 80x40 mm.',
  },
]

export default abstract class BaseStoreSettingPrintingBarcode extends React.Component<
  BaseStoreSettingPrintingBarcodeProps,
  BaseStoreSettingPrintingBarcodeState
> {
  // DISPLAY_TABS: IUserListTab[]
  PRINTING_BARCODE_TYPE: IPrintingBarcodeType[]
  // OPTION_PRINTING_BARCODE: IOptionPrintingBarcode[]

  protected constructor(props) {
    super(props)

    this.state = {
      selectedPrintingTypeId: 57,
      printingBarcodestoreSetting: null,
      chooseOptionBarcodeIndex: 0,
      OPTION_PRINTING_BARCODE: [],
      storePrintingId: null,
      isPageOnChange: false,
      backupPrintingBarcodestoreSetting: null,
    }
    this.PRINTING_BARCODE_TYPE = DEFAULT_PRINTING_QR_CODE_TYPE
    // this.OPTION_PRINTING_BARCODE = []
    // this._tabRef = React.createRef()
  }

  async componentDidMount() {
    await this.setPritingBarcodeSettingStart()
    await this.setOptionPrintingBarcode()
  }

  setPritingBarcodeSettingStart = async () => {
    const { navigation, selectedStore } = this.props
    // const { state } = navigation
    // const { tabIndex, permissionList, permission_id } = state.params

    const printingBarcodeSetting: IPrintingBarcodeStoreSetting = selectedStore.has('printing_barcode')
      ? selectedStore.get('printing_barcode')
      : CONS.DEFAULT_BARCODE

    let storePrintingId = null
    // @ts-ignore
    const printingBarcodeSettingJS: IPrintingBarcodeStoreSetting = isImmutable(printingBarcodeSetting)
      ? printingBarcodeSetting.toJS()
      : printingBarcodeSetting
    if (printingBarcodeSettingJS.id) {
      storePrintingId = printingBarcodeSettingJS.id
    }
    const chooseOptionBarcodeIndex = printingBarcodeSettingJS.p.t === 'b' ? 1 : 0
    if (chooseOptionBarcodeIndex === 0) {
      this.PRINTING_BARCODE_TYPE = DEFAULT_PRINTING_QR_CODE_TYPE
    } else {
      this.PRINTING_BARCODE_TYPE = DEFAULT_PRINTING_BARCODE_TYPE
    }
    await util.setStatePromise(this, {
      printingBarcodestoreSetting: printingBarcodeSettingJS,
      backupPrintingBarcodestoreSetting: printingBarcodeSettingJS,
      selectedPrintingTypeId: printingBarcodeSettingJS.p.i,
      storePrintingId,
      chooseOptionBarcodeIndex,
    })
  }

  setOptionPrintingBarcode = async () => {
    const OPTION_PRINTING_BARCODE = [
      {
        textLabel: 'ชื่อสินค้า',
        value: () => this.productNameValue(),
        onChange: (newValue: boolean) => this.onChangeProductNameValue(newValue),
      },
      // {
      //   textLabel: 'ประเภทของรหัสสินค้าว่าเป็น SKU หรือ UPC',
      //   value: () => this.moreExplanationSKUOrUPCValue(),
      //   onChange: (newValue: boolean) => this.onChangeMoreExplanationSKUOrUPCValue(newValue),
      // },
      {
        textLabel: 'ตัวหนังสือ: QR Code/Barcode',
        value: () => this.letterValue(),
        onChange: (newValue: boolean) => this.onChangeLetterValue(newValue),
      },
      {
        textLabel: 'รูป: QR Code/Barcode',
        value: () => this.QROrBarcodeValue(),
        onChange: (newValue: boolean) => this.onChangeQROrBarcodeValue(newValue),
      },
    ]
    await util.setStatePromise(this, {
      OPTION_PRINTING_BARCODE,
    })
  }

  productNameValue = (): boolean => {
    const { printingBarcodestoreSetting } = this.state
    if (_.isNil(printingBarcodestoreSetting)) {
      return true
    }
    return printingBarcodestoreSetting.p.n === 1
  }

  moreExplanationSKUOrUPCValue = (): boolean => {
    const { printingBarcodestoreSetting } = this.state
    if (_.isNil(printingBarcodestoreSetting)) {
      return true
    }
    return printingBarcodestoreSetting.p.d === 1
  }

  letterValue = (): boolean => {
    const { printingBarcodestoreSetting } = this.state
    if (_.isNil(printingBarcodestoreSetting)) {
      return true
    }
    return printingBarcodestoreSetting.p.c === 1
  }

  QROrBarcodeValue = (): boolean => {
    const { printingBarcodestoreSetting } = this.state
    if (_.isNil(printingBarcodestoreSetting)) {
      return true
    }
    return printingBarcodestoreSetting.p.v === 1
  }

  onChangeProductNameValue = (newValue: boolean) => {
    const { printingBarcodestoreSetting } = this.state
    const newPrintingBarcodestoreSetting = _.clone(printingBarcodestoreSetting)
    newPrintingBarcodestoreSetting.p.n = newValue ? 1 : 0
    util.setStatePromise(this, {
      printingBarcodestoreSetting: newPrintingBarcodestoreSetting,
    })
    this.pageOnChange()
  }

  onChangeMoreExplanationSKUOrUPCValue = (newValue: boolean) => {
    const { printingBarcodestoreSetting } = this.state
    const newPrintingBarcodestoreSetting = _.clone(printingBarcodestoreSetting)
    newPrintingBarcodestoreSetting.p.d = newValue ? 1 : 0
    util.setStatePromise(this, {
      printingBarcodestoreSetting: newPrintingBarcodestoreSetting,
    })
    this.pageOnChange()
  }

  onChangeLetterValue = (newValue: boolean) => {
    const { printingBarcodestoreSetting } = this.state
    const newPrintingBarcodestoreSetting = _.clone(printingBarcodestoreSetting)
    newPrintingBarcodestoreSetting.p.c = newValue ? 1 : 0
    util.setStatePromise(this, {
      printingBarcodestoreSetting: newPrintingBarcodestoreSetting,
    })
    this.pageOnChange()
  }

  onChangeQROrBarcodeValue = (newValue: boolean) => {
    const { printingBarcodestoreSetting } = this.state
    const newPrintingBarcodestoreSetting = _.clone(printingBarcodestoreSetting)
    newPrintingBarcodestoreSetting.p.v = newValue ? 1 : 0
    util.setStatePromise(this, {
      printingBarcodestoreSetting: newPrintingBarcodestoreSetting,
    })
    this.pageOnChange()
  }

  onChangeSelectedValue = (printing: IPrintingBarcodeType) => {
    const { printingBarcodestoreSetting } = this.state
    const newPrintingBarcodestoreSetting = _.clone(printingBarcodestoreSetting)
    newPrintingBarcodestoreSetting.p.i = printing.id
    util.setStatePromise(this, {
      selectedPrintingTypeId: printing.id,
      printingBarcodestoreSetting: newPrintingBarcodestoreSetting,
    })
    this.pageOnChange()
  }

  _chooseOptionBarcode = (newIndex: number) => {
    const { chooseOptionBarcodeIndex, printingBarcodestoreSetting } = this.state
    if (chooseOptionBarcodeIndex === newIndex) {
      return
    }

    const newPrintingBarcodestoreSetting = _.clone(printingBarcodestoreSetting)
    newPrintingBarcodestoreSetting.p.t = newIndex === 1 ? 'b' : 'q'

    let { selectedPrintingTypeId } = this.state
    if (newIndex === 0) {
      this.PRINTING_BARCODE_TYPE = DEFAULT_PRINTING_QR_CODE_TYPE
      selectedPrintingTypeId = 5030
      newPrintingBarcodestoreSetting.p.i = 5030
    } else {
      this.PRINTING_BARCODE_TYPE = DEFAULT_PRINTING_BARCODE_TYPE
      selectedPrintingTypeId = 8040
      newPrintingBarcodestoreSetting.p.i = 8040
    }
    util.setStatePromise(this, {
      chooseOptionBarcodeIndex: newIndex,
      selectedPrintingTypeId,
      printingBarcodestoreSetting: newPrintingBarcodestoreSetting,
    })
    this.pageOnChange()
  }

  pageOnChange = () => {
    util.setStatePromise(this, {
      isPageOnChange: true,
    })
  }

  saveStoreSetting = () => {
    const { selectedStore, editPrinting, addPrinting } = this.props
    const { printingBarcodestoreSetting, storePrintingId } = this.state
    // const isProductName = printingBarcodestoreSetting.p.n
    // const isTextQRorBarcode = printingBarcodestoreSetting.p.c
    // const isImgQRorBarocde = printingBarcodestoreSetting.p.v
    // if (!isProductName && !isTextQRorBarcode && !isImgQRorBarocde) {
    //   p.op.showConfirmationOkOnly('', 'กรุณาเลือกอย่างน้อย 1 ตัวเลือกในหัวข้อ "แสดงข้อมูลเพิ่มเติม"')
    //   return
    // }
    // console.log('storePrintingId => ', storePrintingId)
    const store_id = selectedStore.get('id')
    const payload = {
      body: {
        store_id,
        printing_type_id: 3,
        size: 1,
        is_active: true,
        pref: printingBarcodestoreSetting.p,
      },
      successCallback: (res) => this.successCallback(res),
      failedCallback: (err) => this.failedCallback(),
    }
    if (_.isNil(storePrintingId)) {
      addPrinting(payload)
    } else {
      // @ts-ignore
      payload.body.printing_id = storePrintingId
      editPrinting(payload)
    }
  }

  successCallback = (res) => {
    // this.props.navigation.setParams({
    //   onChange: false,
    // })
    // console.log('res => ', res)
    const printing = res.store_printing
    util.setStatePromise(this, {
      printingBarcodestoreSetting: printing,
      backupPrintingBarcodestoreSetting: printing,
      storePrintingId: printing.id,
      isPageOnChange: false,
    })
    p.op.showToast('บันทึกข้อมูลเรียบร้อยแล้ว', 'success')
    // this.inProcess = false
  }

  failedCallback = () => {
    util.setStatePromise(this, {
      isPageOnChange: false,
    })
    p.op.showToast('เกิดข้อผิดพลาดกรุณาทำรายการใหม่ภายหลัง', 'warning')

    // this.inProcess = false
  }
}
