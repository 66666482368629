import React, { Component } from 'react'

import { StyleSheet, TextInputProps, TouchableOpacity } from 'react-native'

import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'

import { logRender, delay } from 'x/utils/util'
import CONS from 'x/config/constants'
import _ from 'lodash'
import { COLORS, STYLES } from 'x/config/styles'
import p from 'x/config/platform-specific'
// import StickyKeyboardTab from '../../components/StickyKeyboardTab'
import { IOrderQuickAddressViewProps, IOrderQuickAddressViewState, IQuickAddress } from 'x/index'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import XAddressesListOverlay, { IAddressList } from 'xui/components/XAddressesListOverlay'
import api from 'x/utils/api'
import * as util from 'x/utils/util'
import XCard from 'xui/components/XCard'
import XText from 'xui/components/XText'
import Segment from 'xui/components/Segment'
import XIcon from 'xui/components/XIcon'
import Box from 'xui/components/Box'
import XInput from '../../components/XInput'

const { CARD_COMMON } = STYLES

class BaseUIOrderQuickAddressView extends Component<IOrderQuickAddressViewProps, IOrderQuickAddressViewState> {
  static displayName = 'OrderQuickAddressView'

  // static navigationOptions = ({ navigation }) => {
  //   const { goBack, state, type } = navigation
  //   let txtTitle = 'แบบไม่บันทึก'
  //   switch (type) {
  //     case 'SENDER':
  //       txtTitle = 'ที่อยู่ผู้ส่ง' + txtTitle
  //       break
  //     case 'RECEIVER':
  //       txtTitle = 'ที่อยู่ผู้รับ' + txtTitle
  //       break
  //     default:
  //       txtTitle = 'ที่อยู่' + txtTitle
  //   }
  //
  //   return {
  //     headerLeft: (
  //       <Button
  //         transparent={true}
  //         onPress={() => {
  //           goBack(state.key)
  //         }}>
  //         <BackIcon />
  //       </Button>
  //     ),
  //     title: txtTitle,
  //     // headerTitleStyle: { fontSize: STYLES.FONT_SIZE_NORMAL },
  //     headerRight: (
  //       <Button onPress={() => state.params.handleSave()}>
  //         <XText style={StyleSheet.flatten([s.txtHL, { color: COLORS.APP_MAIN }])}>ตกลง</XText>
  //       </Button>
  //     ),
  //   }
  // }

  inProcess?: boolean

  constructor(props) {
    super(props)
    // this.state = {
    //   name: '',
    //   address3: '',
    //   postal_code: '',
    //   telephone: '',
    // }
    this.state = {
      id: null,
      name: '',
      address1: null,
      address2: null,
      address3: '',
      district: null,
      sub_district: null,
      province: null,
      postal_code: '',
      telephone: '',

      currentRef: null,
      segmentLegalEntityTypeIndex: 0,
      radioLegalEntityTypeMoreId: null,
    }

    // this.inputRefs = []
    // this.scrollViewRef = null
  }

  // componentWillMount() {
  //   const { navigation } = this.props
  //   const { state } = navigation
  //
  //   if ('params' in state && 'initAddress' in state.params) {
  //     const { initAddress, mode, shippingTypeIndex } = state.params
  //     this.setState({ ...initAddress }, () => {
  //       navigation.setParams({
  //         handleSave: () => this._saveQuickAddress(mode, shippingTypeIndex),
  //       })
  //     })
  //   } else {
  //     const { mode } = state.params
  //     navigation.setParams({
  //       handleSave: () => this._saveQuickAddress(mode, shippingTypeIndex),
  //     })
  //   }
  // }

  componentDidMount() {
    // setTimeout(() => util.focusFirstRefIfExisted(this.inputRefs, this.scrollViewRef), 200)
    const { navigation } = this.props
    const initAddress = util.getNavParam(this.props, 'initAddress', {})

    const newState: IOrderQuickAddressViewState = {
      ...initAddress,
      segmentLegalEntityTypeIndex: 0,
      radioLegalEntityTypeMoreId: null,
    }

    const isLegalEntityIndividual = util.isLegalEntityIndividual(initAddress.legal_entity_type)
    if (isLegalEntityIndividual) {
      newState.segmentLegalEntityTypeIndex = 1
      newState.radioLegalEntityTypeMoreId = initAddress.legal_entity_type
    }

    const isLegalEntityCorporation = util.isLegalEntityCorporation(initAddress.legal_entity_type)
    if (isLegalEntityCorporation) {
      newState.segmentLegalEntityTypeIndex = 2
      newState.radioLegalEntityTypeMoreId = initAddress.legal_entity_type
    }

    this.setState(newState)
  }

  // _saveQuickAddress = (mode, shippingTypeIndex) => {
  _saveQuickAddress = async () => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true

    const { legal_entity_id, email, segmentLegalEntityTypeIndex, radioLegalEntityTypeMoreId } = this.state
    const { CREATE, VIEW_EDIT } = CONS.ORDER_VIEW_MODE
    const { editingOrder, onChangeAddress, editReceiverAddress, navigation } = this.props
    const selectedStoreID = util.getNavParam(this.props, 'store_id')
    const type = util.getNavParam(this.props, 'type')
    const mode = util.getNavParam(this.props, 'mode')
    const shippingTypeIndex = util.getNavParam(this.props, 'shippingTypeIndex')
    // const { name, address3, postal_code, telephone } = this.state

    // validation zone
    let inpName = this.state.name ? this.state.name : ''
    let inpAddr = this.state.address3 ? this.state.address3 : ''
    let inpZipCode = this.state.postal_code ? this.state.postal_code : ''
    let inpTel = this.state.telephone ? this.state.telephone : ''
    inpName = _.isString(inpName) ? inpName.trim() : inpName
    inpAddr = _.isString(inpAddr) ? inpAddr.trim() : inpAddr
    inpZipCode = !_.isNil(inpZipCode) ? inpZipCode.toString().trim() : inpZipCode
    inpTel = !_.isNil(inpTel) ? inpTel.toString().trim() : inpTel

    // const regPhone = /([0-9]{9,10})|/g
    // const regZipcode = /^[0-9][0-9-]{4,}$/
    const regZipcode = /^[1-9][0-9]{4}$/

    const name = inpName || null
    const address3 = inpAddr || null
    const postal_code = inpZipCode || null
    const telephone = inpTel || null
    const postalCodeErrMs = 'กรุณาระบุ รหัสไปรษณีย์ ในรูปแบบที่ถูกต้อง'

    if (postal_code && !regZipcode.test(postal_code)) {
      p.op.alert('รูปแบบไม่ถูกต้อง', postalCodeErrMs)
      await delay(500)
      this.inProcess = false
      return
    }
    if (postal_code && postal_code.length !== 5) {
      // บังคับให้ระบุรหัสไปษณีย์ได้แค่ 5 ตัวเท่านั้น
      p.op.alert('รูปแบบไม่ถูกต้อง', postalCodeErrMs)
      await delay(500)
      this.inProcess = false
      return
    }
    // ถ้า postal_code 00000 ให้หน้าบ้านดักไว้ ( อ้างอิง : https://app.clickup.com/t/860pc78y1 )
    if (postal_code === '00000') {
      p.op.alert('รูปแบบไม่ถูกต้อง', postalCodeErrMs)
      await delay(500)
      this.inProcess = false
      return
    }
    // ตรวจสอบหากเป็น ปณ และ kerry จะต้องระบุเบอร์โทร
    let shippingID = -1
    CONS.ORDER_VIEW_SHIPPING_TYPE_ITEMS.map((shipping) => {
      if (shipping.index === shippingTypeIndex) {
        shippingID = shipping.value
      }
    })
    const useTelephone = telephone === null || telephone === ''
    const useAddress3 = address3 === null || address3 === ''
    const useName = name === null || name === ''
    const usePostalCode = postal_code === null || postal_code === ''
    // หากอยู่ในกลุ่มรูปแบบจัดส่งที่ต้องการข้อมูลครบ ต้องระบุให้ครบ
    if (_.includes(CONS.TEL_NUM_REQUIRED_FOR_SHIPPING_TYPE_IDS, shippingID)) {
      if (useTelephone && useAddress3 && useName) {
        //
      } else {
        if (useName) {
          p.op.alert('คำเตือน', 'กรุณาระบุ ชื่อผู้รับ')
          await delay(500)
          this.inProcess = false
          return
        }
        if (useAddress3) {
          p.op.alert('คำเตือน', 'กรุณาระบุ ที่อยู่ผู้รับ')
          await delay(500)
          this.inProcess = false
          return
        }
        if (usePostalCode) {
          p.op.alert('คำเตือน', 'กรุณาระบุ รหัสไปรษณีย์')
          await delay(500)
          this.inProcess = false
          return
        }
      }
    } else {
      // ถ้าระบุที่อยู่ ก็ต้องระบุชื่อและรหัสไปรษีย์ด้วยด้วย
      if (!useAddress3) {
        if (useName) {
          p.op.alert('คำเตือน', 'กรุณาระบุ ชื่อผู้รับ')
          await delay(500)
          this.inProcess = false
          return
        }
        if (usePostalCode) {
          p.op.alert('คำเตือน', 'กรุณาระบุ รหัสไปรษณีย์')
          await delay(500)
          this.inProcess = false
          return
        }
      }
    }

    if (useTelephone) {
      p.op.alert('คำเตือน', 'กรุณาระบุ เบอร์โทรศัพท์')
      await delay(500)
      this.inProcess = false
      return
    }
    if (telephone && telephone.length < 9) {
      p.op.alert('รูปแบบไม่ถูกต้อง', 'กรุณาระบุ เบอร์โทรศัพท์ ในรูปแบบที่ถูกต้องซึ่งมีเลขหมายมากกว่าหรือเท่ากับ 9 หลัก')
      await delay(500)
      this.inProcess = false
      return
    }

    if (segmentLegalEntityTypeIndex === 2 && (_.isNil(radioLegalEntityTypeMoreId) || radioLegalEntityTypeMoreId < 2)) {
      p.op.alert(
        'คำเตือน',
        `กรุณาเลือกประเภทของ ${CONS.ADDRESS_LEGAL_ENTITY_TYPE_OPTIONS[segmentLegalEntityTypeIndex]} หรือหากไม่ต้องการระบุสามารถปรับสภาพบุคคลเป็น "ไม่ระบุ"`
      )
      await delay(500)
      this.inProcess = false
      return
    }

    const legal_entity_type = segmentLegalEntityTypeIndex === 0 ? 0 : radioLegalEntityTypeMoreId
    const isLegalEntityIndividual = util.isLegalEntityIndividual(legal_entity_type)
    const isLegalEntityCorporation = util.isLegalEntityCorporation(legal_entity_type)

    if (legal_entity_type > 0) {
      if (!_.isString(legal_entity_id) || legal_entity_id.length === 0) {
        p.op.alert(
          'คำเตือน',
          `กรุณาระบุ ${CONS.ADDRESS_LEGAL_ENTITY_LABELS[segmentLegalEntityTypeIndex]} หรือหากไม่ต้องการระบุสามารถปรับสภาพบุคคลเป็น "ไม่ระบุ"`
        )
        await delay(500)
        this.inProcess = false
        return
      }

      const legalEntityIdToTest = legal_entity_id.replace(/-/g, '').trim()
      const regThirteenDigit = /^\d{13}$/
      if (isLegalEntityIndividual && !regThirteenDigit.test(legalEntityIdToTest)) {
        p.op.alert('คำเตือน', `กรุณาระบุ ${CONS.ADDRESS_LEGAL_ENTITY_LABELS[1]} เป็นตัวเลข 13 หลัก`)
        await delay(500)
        this.inProcess = false
        return
      }

      const regTenDigit = /^\d{10}$/
      if (isLegalEntityCorporation && !regTenDigit.test(legalEntityIdToTest) && !regThirteenDigit.test(legalEntityIdToTest)) {
        p.op.alert('คำเตือน', `กรุณาระบุ ${CONS.ADDRESS_LEGAL_ENTITY_LABELS[2]} เป็นตัวเลข 10 หลัก หรือ 13 หลัก`)
        await delay(500)
        this.inProcess = false
        return
      }
    }

    if (email && email.length > 2) {
      const emailTrim = email.trim()
      const isEmailValid = util.isEmailValid(emailTrim)
      if (!isEmailValid) {
        p.op.showConfirmationOkOnly(`อีเมลไม่ถูกต้อง`, 'กรุณาระบุอีเมลในรูปแบบถูกต้อง')
        this.inProcess = false
        return
      }
    }

    /// // START ตรวจสอบที่อยู่ผู้รับซ้ำ สำหรับ VIEW EDIT MODE เท่านั้น /////
    let comfirmChangeReceiverAddress = true
    const customCreateOrder = this.props.editingCustomCreateOrder.toJS()
    const checkDupReceiverAddr = _.has(customCreateOrder, 'check_dup_receiver_addr') ? customCreateOrder.check_dup_receiver_addr : true
    if (checkDupReceiverAddr) {
      if (type === 'RECEIVER' && mode === VIEW_EDIT) {
        await new Promise(async (resovle) => {
          const order_id = editingOrder.get('id')
          try {
            const reqBody = {
              store_id: selectedStoreID,
              postal_code,
              name,
              telephone,
              within_days: 31,
              exclude_order_id: order_id,
            }
            const res: { order_address: IAddressList[] } = await api.postV2(api.POST_FIND_DUP_BY_RECEIVER_ADDRESS, reqBody)
            // console.log('_findDupByReceiverAddr => ', res)

            if (res.order_address && res.order_address.length > 0) {
              util.setStatePromise(this, {
                addressesListOverlay: res.order_address,
                // isVisibleAddressesListOverlay: true,
              })
              await new Promise((resovleComfirm) => {
                // const oldTime = util.getTimeFormatYYYYMMDDhhmm(selectedDateRangeSetUpDateDeliveryOrder)
                // const newTime = util.getTimeFormatYYYYMMDDhhmm(newDate)
                p.op.showConfirmationThreeButtons(
                  'พบที่อยู่ผู้รับซ้ำ!',
                  'พบที่อยู่ผู้รับซ้ำกับออเดอร์ที่สร้างภายใน 30 วันที่ผ่านมา',
                  'ยืนยันการเปลี่ยนที่อยู่ผู้รับ',
                  () => {
                    // @ts-ignore
                    resovleComfirm()
                    // @ts-ignore
                    resovle()
                  },
                  'ดูออเดอร์ที่มีที่อยู่ผู้รับซ้ำ',
                  () => {
                    // @ts-ignore
                    resovleComfirm()
                    comfirmChangeReceiverAddress = false
                    util.setStatePromise(this, {
                      isVisibleAddressesListOverlay: true,
                    })
                    // @ts-ignore
                    resovle()
                  },
                  'ปิดหน้าต่างนี้',
                  () => {
                    // @ts-ignore
                    resovleComfirm()
                    comfirmChangeReceiverAddress = false
                    // @ts-ignore
                    resovle()
                  }
                )
              })
            } else {
              util.setStatePromise(this, {
                addressesListOverlay: null,
              })
              // @ts-ignore
              resovle()
            }
          } catch (err) {
            // @ts-ignore
            resovle()
          }
        })
      }
    }

    if (!comfirmChangeReceiverAddress) {
      this.inProcess = false
      return
    }
    /// // END ตรวจสอบที่อยู่ผู้รับซ้ำ /////

    // if (shippingTypeIndex < 6 && shippingTypeIndex != -1) {
    //   if (telephone === null || telephone === '') {
    //     p.op.alert('คำเตือน', 'กรุณาระบุ เบอร์โทรศัพท์')
    //     return
    //   }

    //   if (telephone && telephone.length < 9) {
    //     p.op.alert('รูปแบบไม่ถูกต้อง', 'กรุณาระบุ เบอร์โทรศัพท์ ในรูปแบบที่ถูกต้องซึ่งมีเลขหมายมากกว่าหรือเท่ากับ 9 หลัก')
    //     return
    //   }
    // }
    // if (telephone && !telephone.match(regPhone)) {
    //   p.op.alert('รูปแบบไม่ถูกต้อง', 'กรุณาระบุ เบอร์โทรศัพท์ ในรูปแบบที่ถูกต้อง')
    //   return
    // }
    let quickAddress: IQuickAddress = { name, address3, postal_code, telephone }
    if (usePostalCode) {
      quickAddress = { name, address3, telephone }
    } else {
      quickAddress = { name, address3, postal_code, telephone }
    }

    if (legal_entity_type === 0) {
      quickAddress.legal_entity_type = 0
      quickAddress.legal_entity_id = null
    } else if (legal_entity_type > 0) {
      quickAddress.legal_entity_type = legal_entity_type
      quickAddress.legal_entity_id = legal_entity_id.replace(/-/g, '').trim()
    }

    if (email && email.length > 2) {
      quickAddress.email = email.trim()
    }

    const order_id = editingOrder.get('id')
    // p.op.alert('Save', `name: ${name}\n address3: ${address3}\n postal_code: ${postal_code}\n telephone: ${telephone} `)
    // log('save!!!')
    // const requireFields = ['name', 'address3', 'postal_code']
    // const displayFields = ['ชื่อผู้รับ', 'ที่อยู่จัดส่ง', 'รหัสไปรษณีย์']
    const requireFields = []
    const displayFields = []
    let isCorrectFields = true
    for (let i = 0; i < requireFields.length; i++) {
      const key = requireFields[i]
      const txtDisplay = displayFields[i]
      if (!quickAddress[key] || (quickAddress[key] && quickAddress[key].length <= 0)) {
        isCorrectFields = false
        p.op.alert('คำเตือน', `${txtDisplay} ยังไม่ถูกระบุ กรุณากรอกข้อมูลที่จำเป็นให้ครบถ้วน`)
        await delay(500)
        this.inProcess = false
        return
      }
    }

    let addrKey = 'receiver'
    if (type === 'SENDER') {
      addrKey = 'sender'
    }
    if (type === 'BILLING_ADDRESS') {
      addrKey = 'billing_address'
    }

    if (isCorrectFields) {
      if (mode === CREATE) {
        const newCreateAddr = {}
        newCreateAddr[addrKey] = {
          ...quickAddress,
          id: null,
          address1: null,
          address2: null,
          district: null,
          sub_district: null,
          province: null,
        }
        onChangeAddress(newCreateAddr)
        util.navGoBack(this.props)
      } else if (mode === VIEW_EDIT) {
        if (selectedStoreID && order_id && type) {
          const reqBody = {
            store_id: selectedStoreID,
            order_id,
            new_address: [quickAddress],
            type,
          }

          if (type === 'BILLING_ADDRESS') {
            // @ts-ignore
            reqBody.type = 'BILLING'
          }
          editReceiverAddress({
            body: reqBody,
            successCallback: () => {
              // @ts-ignore
              const callback = util.getNavParam(this.props, 'callBack')
              util.navGoBack(this.props)
              if (_.isFunction(callback)) {
                callback()
              }
            },
          })
        } else {
          p.op.alert('ไม่พบร้านค้า', 'กรุณากลับไปเลือกร้านค้าแล้วลองใหม่อีกครั้ง ขออภัยในความไม่สะดวก')
          util.navGoBack(this.props)
        }
      } else {
        //
      }
      // navigation.goBack(navigation.state.key)
    }
    await delay(500)
    this.inProcess = false
  }

  _renderNormalInput = (params: {
    key?: string
    label: string
    value: any
    placeholder?: string
    onChangeText: (newText: string) => void
    keyboardType?: TextInputProps['keyboardType']
  }) => {
    const { key, label, value, placeholder, onChangeText, keyboardType } = params
    return (
      <HStack w='full' alignItems='center'>
        <XText minW='140px' variant='inactive'>
          {label}
        </XText>
        <XInput
          flex={1}
          // ref={r => util.pushNativeBaseRef(this.inputRefs, key, r)}
          // onFocus={() => this.setState({ currentRef: key })}
          value={value}
          // style={{
          //   flex: 15,
          //   height: 35,
          //   borderBottomWidth: 0,
          //   paddingLeft: 10,
          //   backgroundColor: COLORS.FORM_PRIMARY_BG,
          //   fontSize: STYLES.FONT_SIZE_NORMAL,
          //   color: COLORS.TEXT_PRIMARY,
          // }}
          keyboardType={keyboardType || 'default'}
          // returnKeyType={'done'}
          placeholder={placeholder}
          placeholderTextColor={COLORS.TEXT_INACTIVE}
          multiline={false}
          onChangeText={onChangeText}
        />
      </HStack>
    )
  }

  _goBack = async () => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true

    util.navGoBack(this.props)

    await delay(500)
    this.inProcess = false
  }

  _renderCustomHeader = () => {
    const type = util.getNavParam(this.props, 'type')
    if (!type || !_.isString(type)) {
      return null
    }
    let txtTitle = 'แบบไม่บันทึก'
    switch (type) {
      case 'SENDER':
        txtTitle = `ที่อยู่ผู้ส่ง${txtTitle}`
        break
      case 'RECEIVER':
        txtTitle = `ที่อยู่ผู้รับ${txtTitle}`
        break
      case 'BILLING_ADDRESS':
        // txtTitle = `ที่อยู่สำหรับใบกำกับภาษี${txtTitle}`
        txtTitle = 'ที่อยู่สำหรับใบกำกับภาษี'
        break
      default:
        txtTitle = `ที่อยู่${txtTitle}`
    }

    return (
      <XCustomHeader
        headerLeftProps={{ backIcon: true, onPressItem: this._goBack }}
        title={txtTitle}
        headerRightProps={{ label: 'ตกลง', onPressItem: this._saveQuickAddress }}
      />
    )

    // headerLeft: (
    //   <Button
    //     transparent={true}
    //     onPress={() => {
    //       goBack(state.key)
    //     }}>
    //     <BackIcon />
    //   </Button>
    // ),
    // title: txtTitle,
    // // headerTitleStyle: { fontSize: STYLES.FONT_SIZE_NORMAL },
    // headerRight: (
    //   <Button onPress={() => state.params.handleSave()}>
    //     <XText style={StyleSheet.flatten([s.txtHL, { color: COLORS.APP_MAIN }])}>ตกลง</XText>
    //   </Button>
    // ),
  }

  renderAddressListOverlay = () => {
    // @ts-ignore
    const { isVisibleAddressesListOverlay, addressesListOverlay } = this.state
    if (_.isNil(addressesListOverlay)) {
      return null
    }
    const isVisibleAddressesListOverlayInUse = _.isNil(isVisibleAddressesListOverlay) ? false : isVisibleAddressesListOverlay
    return (
      <XAddressesListOverlay
        isVisible={isVisibleAddressesListOverlayInUse}
        onClose={() =>
          util.setStatePromise(this, {
            isVisibleAddressesListOverlay: false,
          })
        }
        orderAddress={addressesListOverlay}
      />
    )
  }

  _onRadioLegalEntityTypeMorePress = (legalEntityTypeId: number) => {
    this.setState({ radioLegalEntityTypeMoreId: legalEntityTypeId })
  }

  _onSegmentLegalEntityTypeChange = (newIndex: number) => {
    const newState: Partial<IOrderQuickAddressViewState> = { segmentLegalEntityTypeIndex: newIndex }
    if (newIndex === 0) {
      newState.radioLegalEntityTypeMoreId = null
    } else if (newIndex === 1) {
      newState.radioLegalEntityTypeMoreId = CONS.ADDRESS_LEGAL_ENTITY_TYPE_INDIVIDUAL_MORE_OPTIONS[0].id
    } else if (newIndex === 2) {
      newState.radioLegalEntityTypeMoreId = null
    }

    // @ts-ignore
    this.setState(newState)
  }

  _renderEntityType = () => {
    const { segmentLegalEntityTypeIndex } = this.state

    return (
      <HStack w='full' alignItems='center'>
        <XText minW='140px' variant='inactive'>
          สภาพบุคคล
        </XText>

        <HStack flex={1} alignItems='center'>
          <Segment
            options={CONS.ADDRESS_LEGAL_ENTITY_TYPE_OPTIONS}
            onSegmentChange={this._onSegmentLegalEntityTypeChange}
            selectedIndex={segmentLegalEntityTypeIndex}
          />
        </HStack>
      </HStack>
    )
  }

  _renderEntityTypeMoreItem = (opt, index: number) => {
    if (opt.isHidden) {
      return null
    }

    const { radioLegalEntityTypeMoreId } = this.state
    const checked = opt.id === radioLegalEntityTypeMoreId
    return (
      <HStack key={`entityTypeMoreItem-${index}`} pb='1'>
        <TouchableOpacity onPress={() => this._onRadioLegalEntityTypeMorePress(opt.id)}>
          <HStack
            px='2'
            py='1'
            space='1'
            alignItems='center'
            borderWidth='1'
            borderColor={checked ? 'gray.400' : 'gray.200'}
            borderRadius='lg'>
            <XIcon name={checked ? 'radio-button-on' : 'radio-button-off'} />
            <XText>{opt.label}</XText>
          </HStack>
        </TouchableOpacity>
      </HStack>
    )
  }

  _renderEntityTypeMore = () => {
    const { segmentLegalEntityTypeIndex } = this.state
    if (segmentLegalEntityTypeIndex === 2) {
      return (
        <HStack w='full' space='2' alignItems='center'>
          <Box w='130px' />
          <HStack flex={1} space='1.5' flexWrap='wrap'>
            {CONS.ADDRESS_LEGAL_ENTITY_TYPE_CORPORATION_MORE_OPTIONS.map(this._renderEntityTypeMoreItem)}
          </HStack>
        </HStack>
      )
    }

    if (segmentLegalEntityTypeIndex === 1) {
      return (
        <HStack w='full' space='2' alignItems='center'>
          <Box w='130px' />
          <HStack flex={1} space='1.5' flexWrap='wrap'>
            {CONS.ADDRESS_LEGAL_ENTITY_TYPE_INDIVIDUAL_MORE_OPTIONS.map(this._renderEntityTypeMoreItem)}
          </HStack>
        </HStack>
      )
    }

    return null
  }

  _renderEntityInput = () => {
    const { legal_entity_id, segmentLegalEntityTypeIndex } = this.state
    const label = CONS.ADDRESS_LEGAL_ENTITY_LABELS[segmentLegalEntityTypeIndex]
    if (segmentLegalEntityTypeIndex === 0) {
      return null
    }

    return this._renderNormalInput({
      label,
      key: 'legal_entity_id',
      value: legal_entity_id,
      placeholder: 'เช่น 0123456789012',
      onChangeText: (text) => this.setState({ legal_entity_id: text }),
    })
  }

  render() {
    logRender(this)
    // log('this.inputRefs =>', this.inputRefs)
    const { name, address3, postal_code, telephone, email } = this.state
    const contentProps: any = {
      disableKBDismissScroll: false,
      enableResetScrollToCoords: false,
      extraScrollHeight: p.op.isWeb() ? undefined : 50,
    }
    return (
      <XContainer>
        {this._renderCustomHeader()}
        {this.renderAddressListOverlay()}
        <XContent
          // ref={r => r && (this.scrollViewRef = r._root)}
          // keyboardShouldPersistTaps={CONS.KEYBOARD_PERSIST_TAB_MODE}
          // disableKBDismissScroll={false}
          // enableResetScrollToCoords={false}
          // extraScrollHeight={50}
          {...contentProps}>
          <VStack w='full' p='2'>
            <XCard
              w='full'
              // @ts-ignore
              // style={StyleSheet.flatten([CARD_COMMON, { paddingLeft: 0 }])}
            >
              <XCard.Body>
                <VStack w='full' space='2'>
                  {this._renderNormalInput({
                    label: 'ชื่อผู้รับ',
                    key: 'name',
                    value: name,
                    placeholder: 'เช่น คุณกรุณา แก้ววงศา',
                    onChangeText: (text) => this.setState({ name: text }),
                  })}

                  <HStack w='full'>
                    <XText mt='1' variant='inactive' minW='140px'>
                      ที่อยู่จัดส่ง
                    </XText>
                    {/* <InputGroup */}
                    {/* borderType='regular' */}
                    {/* style={{ borderBottomWidth: 0, paddingTop: 5, marginTop: 10, */}
                    {/* backgroundColor: COLORS.FORM_PRIMARY_BG }}> */}
                    <XInput
                      flex={1}
                      style={{
                        minHeight: 100,
                      }}
                      // ref={r => util.pushNativeBaseRef(this.inputRefs, 'address3', r)}
                      // onFocus={() => this.setState({ currentRef: 'address3' })}
                      value={address3}
                      onChangeText={(text) => this.setState({ address3: text })}
                      // style={{
                      //   flex: 15,
                      //   borderBottomWidth: 0,
                      //   paddingLeft: 10,
                      //   width: '100%',
                      //   height: 80,
                      //   fontSize: STYLES.FONT_SIZE_NORMAL,
                      //   color: COLORS.TEXT_PRIMARY,
                      //   backgroundColor: COLORS.FORM_PRIMARY_BG,
                      // }}
                      placeholder='เช่น 123/5 ถ.ศรีสุข ต.หมากแข้ง อ.เมือง จ.อุดรธานี'
                      placeholderTextColor={COLORS.TEXT_INACTIVE}
                      multiline
                      blurOnSubmit={false} // allow new line on Android keyboard
                    />
                    {/* </InputGroup> */}
                  </HStack>

                  {this._renderNormalInput({
                    label: 'รหัสไปรษณีย์',
                    key: 'postal_code',
                    value: postal_code,
                    placeholder: 'เช่น 10100',
                    onChangeText: (text) => this.setState({ postal_code: text }),
                    keyboardType: 'number-pad',
                  })}

                  {this._renderNormalInput({
                    label: 'โทรศัพท์',
                    key: 'telephone',
                    value: telephone,
                    placeholder: 'เช่น 089-xxx-xxxx',
                    onChangeText: (text) => this.setState({ telephone: text }),
                    keyboardType: 'numbers-and-punctuation',
                  })}

                  {this._renderNormalInput({
                    label: 'อีเมล',
                    key: 'email',
                    value: email,
                    placeholder: 'เช่น xselly@gmail.com',
                    onChangeText: (text) => this.setState({ email: text }),
                    // keyboardType: 'numbers-and-punctuation',
                  })}

                  {this._renderEntityType()}
                  {this._renderEntityTypeMore()}
                  {this._renderEntityInput()}
                </VStack>
              </XCard.Body>
            </XCard>
          </VStack>
        </XContent>
        {/* <StickyKeyboardTab arrayOfRefs={this.inputRefs} currentRef={this.state.currentRef} scrollRef={this.scrollViewRef} /> */}
      </XContainer>
    )
  }
}

export default BaseUIOrderQuickAddressView

const s = StyleSheet.create({
  container: {
    flex: 1,
    flexBasis: 'auto',
    // justifyContent: 'space-between',
  },
  webFlexBasis: {
    flexBasis: 'auto',
  },
  justifyCenter: {
    justifyContent: 'center',
  },
  marginBottom12: {
    marginBottom: 12,
  },
  content: {
    flex: 1,
    flexBasis: 'auto',
    paddingLeft: 5,
    paddingRight: 5,
  },
  gridColumnContainer: {
    flex: 1,
    flexBasis: 'auto',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  halfColumnContainer: {
    width: '50%',
    padding: 5,
  },
  pCardImg: { width: '100%', height: 100 },
  pCardForm: {
    // backgoundColor: 'rgba(45,45,45,0.3)',
  },
  pCardInput: {},
  pCardLabel: {},
  formInCard: {
    alignSelf: 'stretch',
    margin: 0,
    padding: 0,
    flex: 1,
    flexBasis: 'auto',
  },
  borderPrimary: {
    borderColor: COLORS.FORM_PRIMARY,
  },
  borderError: {
    borderColor: COLORS.FORM_ERROR,
  },
  borderSuccess: {
    borderColor: COLORS.FORM_SUCCESS,
  },
  separator: {
    height: 5,
  },
  cardStyle: {
    // elevation: 1,
    // width: '100%',
    flex: 1,
    flexBasis: 'auto',
    marginLeft: 5,
    marginRight: 5,
    // marginLeft: 0,
    // paddingLeft: 0,
  },
  cardBody: {
    width: '100%',
    height: 220,
    flex: 1,
    flexBasis: 'auto',
  },
  cardImg: {
    alignSelf: 'center',
    width: '100%',
    height: 200,
  },
  alignCenter: {
    alignSelf: 'center',
  },
  alignRight: {
    alignSelf: 'flex-end',
  },
  txtHeader: {
    fontSize: STYLES.FONT_SIZE_ORDERS_HEADER_ROW2,
    color: COLORS.TEXT_ACTIVE,
    fontWeight: 'bold',
  },
  txtNormal: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_ACTIVE,
  },
  txtMultiple: {
    width: '100%',
    height: 100,
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_ACTIVE,
  },
  txtLabel: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_INACTIVE,
    // color: 'rgba(15,15,15,0.6)',
  },
  txtHLRight: {
    fontSize: STYLES.FONT_SIZE_LARGER,
    fontWeight: 'bold',
    color: COLORS.TEXT_PRIMARY,
    textAlign: 'right',
  },
  labelRight: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_INACTIVE,
    textAlign: 'right',
  },
  txtRight: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_ACTIVE,
    textAlign: 'right',
  },
  txtHL: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_PRIMARY,
  },
  iconHL: {
    fontSize: STYLES.FONT_SIZE_NORMAL + 4,
    color: COLORS.TEXT_PRIMARY,
    textAlign: 'right',
  },
  rowBox: {
    borderWidth: 1,
    borderColor: 'lightgrey',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 10,
    paddingBottom: 10,
  },
  colBox50: {
    width: '50%',
    borderWidth: 1,
    borderColor: 'lightgrey',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingTop: 10,
    paddingLeft: 5,
    paddingRight: 5,
    paddingBottom: 10,
  },
  statusTableColumnContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  statusTableLabelColumnViewBlank: {
    width: '100%',
    height: 20,
    borderWidth: 0,
  },
  statusTableLabelColumnView: {
    width: '100%',
    height: 20,
    borderWidth: 1,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderColor: COLORS.TEXT_INACTIVE,
    flexDirection: 'row',
    alignItems: 'center',
  },
  statusTableLabelColumnViewLastRow: {
    width: '100%',
    height: 20,
    borderWidth: 1,
    borderRightWidth: 0,
    borderColor: COLORS.TEXT_INACTIVE,
    flexDirection: 'row',
    alignItems: 'center',
  },
  statusTableLabelColumnTxt: {
    fontSize: STYLES.FONT_SIZE_SMALLER,
    color: COLORS.TEXT_INACTIVE,
    paddingLeft: 0,
  },
  itemLabel: {
    borderBottomWidth: 0,
    marginTop: 5,
  },
  shippingIcon: {
    fontSize: STYLES.FONT_ICON_SMALLER,
    color: COLORS.APP_MAIN,
    paddingRight: 6,
    paddingTop: 1,
  },
  productSummaryTableHeader: {
    flex: 1,
    height: 25,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  productSummaryTableHeaderText: {
    flex: 1,
    textAlign: 'center',
    fontSize: STYLES.FONT_SIZE_SMALLER,
    color: COLORS.TEXT_ACTIVE,
  },
  orderStateIcon: {
    width: 22,
    paddingTop: 1,
    fontSize: STYLES.FONT_ICON_SMALLER - 2,
    paddingRight: 1,
    textAlign: 'center',
  },
  paymentStateIcon: {
    width: 22,
    paddingTop: 1,
    fontSize: STYLES.FONT_ICON_SMALLER,
    textAlign: 'center',
  },
  shippingStateIcon: {
    width: 22,
    paddingTop: 1,
    fontSize: STYLES.FONT_ICON_SMALLER,
    textAlign: 'center',
  },
})
