import { connect } from 'react-redux'
import { getState, getDispatch } from 'x/modules/order/OrderShipListViewConnect'
import OrderShipListView from './OrderShipListView'
// import { bindActionCreators } from 'redux'
// import * as OrderActions from 'x/modules/order/OrderState'

export default connect(
  (state) => ({
    // myStores: getMyStores(state),
    // selectedOrder: state.getIn(['order', 'selectedOrder']),
    ...getState(state),
  }),
  (dispatch) => {
    return {
      // autoCompleteShipping: bindActionCreators(OrderActions.autoCompleteShipping, dispatch),
      // cancelShipping: bindActionCreators(OrderActions.cancelShipping, dispatch),
      // fetchOrderDetail: bindActionCreators(OrderActions.fetchOrderDetail, dispatch),
      // shouldFetchOrderDetail: bindActionCreators(OrderActions.shouldFetchOrderDetail, dispatch),
      // shippingStatus: bindActionCreators(OrderActions.getShippingStatus, dispatch),
      ...getDispatch(dispatch),
    }
  }
)(OrderShipListView)
