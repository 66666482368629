// import PropTypes from 'prop-types'
import BaseUIUserGroupResellerTabs from 'xui/modules/store/BaseUIUserGroupResellerTabs'

export default class UserGroupResellerTabs extends BaseUIUserGroupResellerTabs {
  static displayName = 'UserGroupResellerTabs'

  // static navigationOptions = ({ navigation }) => ({
  //   header: null,
  // })

  // renderQRCodeModal = () => {
  //   return <QRCodeModal ref={this.qrModalRef} />
  // }
}
