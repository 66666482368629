import React, { Component } from 'react'
import { View, Text, TouchableOpacity, TextInput } from 'react-native'
import _ from 'lodash'
// import { ActionSheet, Icon } from 'native-base'

import { delay, log, setStatePromise } from 'x/utils/util'
import { IXCameraProps, IXCameraState } from 'x/index'
// import p from 'x/config/platform-specific'
import { STYLES, COLORS, S } from 'x/config/styles'

// import { BarcodeSound } from '../utils/sound'
import XInput from 'xui/components/XInput'
import XCustomHeader from 'xui/components/XCustomHeader'
import XOverlay from './XOverlay'
import BarcodeListener from './BarcodeListener'

const INTL_STYLES: { [key: string]: any } = {
  container: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: 'black',
  },
  preview: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  capture: {
    flex: 0,
    backgroundColor: '#fff',
    borderRadius: 5,
    padding: 15,
    paddingHorizontal: 20,
    alignSelf: 'center',
    margin: 20,
  },
  btnIcon: {
    fontSize: Math.round(STYLES.FONT_ICON_LARGEST * 1.5), // 30 * 1.5
    width: Math.round(STYLES.FONT_ICON_LARGEST * 2) + 4,
    height: Math.round(STYLES.FONT_ICON_LARGEST * 2) + 4,
    color: COLORS.APP_MAIN,
    fontWeight: 'bold',
    textAlign: 'center',
  },
}

const BTN_PADDING = 8
// const BTN_MARGIN = 16
// const BTN_MARGIN = 64
const FROM_DEVICE_BOTTOM_MARGIN = 16

export default class XCamera extends Component<IXCameraProps, IXCameraState> {
  static displayName = 'XCamera'

  _inProcess: boolean

  _inClearingReadingBarcode: boolean

  _isUnmounting: boolean

  _barcodeInputRef?: React.RefObject<TextInput>

  constructor(props) {
    super(props)

    this._inProcess = false
    this._isUnmounting = false
    this._barcodeInputRef = React.createRef()
    // this.cameraRef = null
    this.state = {
      // Just mock for typescript
      cameraBoundContainerLayout: {
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderTopWidth: 2,
        borderBottomWidth: 2,
      },
      cameraBoundLayout: {
        height: 3,
        width: 3,
      },
      cameraBoundRedLineLayoutFirst: {
        top: 0,
        height: 0,
        width: 0,
      },
      cameraBoundRedLineLayoutSecond: {
        top: 0,
        height: 0,
        width: 0,
      },
      isCameraReady: false,
      isReadingBarcode: false,
      ratio: null,
      btnCloseStyle: {
        position: 'absolute',
        bottom: FROM_DEVICE_BOTTOM_MARGIN,
        left: INTL_STYLES.btnIcon.width / 2, // 4 is padding
        padding: BTN_PADDING,
      },

      webInputBarcodeText: '',
    }
  }

  _requestClose = async (): Promise<void> => {
    const { onRequestClose } = this.props
    if (!_.isFunction(onRequestClose)) {
      return
    }

    if (onRequestClose[Symbol.toStringTag] === 'AsyncFunction') {
      await onRequestClose()
    } else {
      onRequestClose()
    }
  }

  _submitBarcodeText = async (barcodeText: string) => {
    const { onBarcodeRead, alwaysVisibleAfterScan = false } = this.props
    if (this._inProcess || !_.isFunction(onBarcodeRead)) {
      return
    }
    this._inProcess = true
    // if (!disabledSound) {
    //   this._playCameraSound()
    // }
    await setStatePromise(this, { isReadingBarcode: true })
    await delay(200)
    if (!alwaysVisibleAfterScan) {
      await this._requestClose()
    }
    await onBarcodeRead(barcodeText)
    await delay(1000)
    await setStatePromise(this, { isReadingBarcode: false, webInputBarcodeText: '' })
    this._inProcess = false
  }

  _handleOnScanBarcode = async (newText: string) => {
    await setStatePromise(this, { webInputBarcodeText: newText, isReadingBarcode: true })
    await delay(500)
    await this._submitBarcodeText(newText)
  }

  _handlePressSubmit = () => {
    this._submitBarcodeText(this.state.webInputBarcodeText)
  }

  _onChangeBarcodeText = (newText: string) => {
    this.setState({ webInputBarcodeText: newText })
  }

  _remainFocus = async () => {
    if (this._inProcess || this.state.isReadingBarcode) {
      return
    }
    try {
      await delay(100)
      this._barcodeInputRef.current.focus()
      // this._barcodeInputRef.current._focusInput()
    } catch (error) {
      log(error)
    }
  }

  render() {
    const { isVisible, title = 'XCamera' } = this.props
    const { isReadingBarcode, webInputBarcodeText = '' } = this.state
    const isSubmitButtonDisabled = isReadingBarcode || webInputBarcodeText.length === 0
    return (
      <XOverlay contentStyle={{ height: 200, width: 380, alignSelf: 'center' }} onRequestClose={this._requestClose} visible={isVisible}>
        <XCustomHeader
          title={title}
          headerStyle={{ backgroundColor: COLORS.BG_LIGHT_GREY }}
          headerRightProps={{ closeIcon: true, onPressItem: this._requestClose }}
        />
        <View style={{ height: 1, width: '100%', backgroundColor: COLORS.TEXT_INACTIVE }} />
        <View style={{ flex: 1, backgroundColor: '#fff', paddingTop: 20 }}>
          <BarcodeListener isDisabled={isReadingBarcode} minLength={3} thresholdHumanTimeMs={350} onScan={this._handleOnScanBarcode} />
          <View style={{ paddingTop: 8, justifyContent: 'center', alignItems: 'center' }}>
            <Text>สแกนบาร์โค้ดหรือพิมพ์</Text>
          </View>
          <View style={{ paddingTop: 8, paddingLeft: 8, paddingRight: 4, justifyContent: 'center', alignItems: 'center' }}>
            <XInput
              isDisabled={isReadingBarcode}
              ref={this._barcodeInputRef}
              autoFocus
              textAlign='center'
              onBlur={this._remainFocus}
              style={{
                height: 34,
                width: 180,
              }}
              value={webInputBarcodeText}
              onChangeText={this._onChangeBarcodeText}
              onSubmitEditing={this._handlePressSubmit}
            />
          </View>
          <View style={{ height: 38, paddingTop: 8, paddingLeft: 8, paddingRight: 4, justifyContent: 'center', alignItems: 'center' }}>
            <TouchableOpacity
              onPress={this._handlePressSubmit}
              disabled={isSubmitButtonDisabled}
              style={{
                backgroundColor: isSubmitButtonDisabled ? COLORS.TEXT_INACTIVE : COLORS.APP_MAIN,
                borderRadius: 7,
                height: 28,
                width: 80,
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Text style={[isSubmitButtonDisabled ? S.TEXT_ACTIVE : S.TEXT_ACTIVE_DARK, S.TEXT_BOLD]}>ตกลง</Text>
            </TouchableOpacity>
          </View>
        </View>
      </XOverlay>
    )
  }
}
