import React from 'react'
import { View, Platform, StyleSheet } from 'react-native'
import _ from 'lodash'
import { diff } from 'deep-object-diff'

import { S } from 'x/config/styles'
import CONS from 'x/config/constants'
import p from 'x/config/platform-specific'
import { IShippingViewSetting, IShippingViewBaseSetting } from 'x/index'

import XCustomHeader from '../XCustomHeader'
import XOverlay from '../XOverlay'
import XSwitch from '../XSwitch'
import XIconButton from '../XIconButton'
import Box from '../Box'
import VStack from '../VStack'
import XText from '../XText'
import HStack from '../HStack'
// import FaqHelpButton from '../../components/FaqHelpButton'

interface IShippingSettingIconButtonProps {
  hideSettingKeys?: Array<keyof IShippingViewBaseSetting> | Array<string>
  currentSetting?: IShippingViewSetting
  onChange: (newSetting: IShippingViewSetting) => void
  myStoreNames?: string[]
  selectedStoreName?: string

  onCloseAfterModified?: (diffSetting: Partial<IShippingViewSetting>) => Promise<void>
}

const ShippingSettingIconButton: React.FC<IShippingSettingIconButtonProps> = function ({
  hideSettingKeys = null,
  currentSetting,
  onChange,
  myStoreNames,
  selectedStoreName,
  onCloseAfterModified,
}) {
  const [isOverlayVisible, setisOverlayVisible] = React.useState(false)
  const [lastOpenedSetting, setLastOpenedSetting] = React.useState(currentSetting)

  React.useEffect(() => {
    if (!currentSetting || _.isEmpty(currentSetting)) {
      onChange(CONS.DEFAULT_SHIPPING_VIEW_SETTING)
    }
  }, [currentSetting])

  async function _closeSettingOverlay() {
    if (_.isFunction(onCloseAfterModified)) {
      const diffSettingModified = diff(lastOpenedSetting, currentSetting)
      const hasSettingModified = !_.isEmpty(diffSettingModified)
      if (hasSettingModified && _.isFunction(onCloseAfterModified)) {
        await onCloseAfterModified(diffSettingModified)
      }
    }

    setisOverlayVisible(false)
  }
  function _openSettingOverlay() {
    setLastOpenedSetting(currentSetting)
    setisOverlayVisible(true)
  }
  function _onToggleSettingChange(toggleKey: keyof IShippingViewBaseSetting) {
    const newSetting = { ...currentSetting }
    newSetting[toggleKey] = !newSetting[toggleKey]
    onChange({ ...newSetting, lastModified: new Date() })
  }
  function _onChangeSkipCheckShippingProducts() {
    _onToggleSettingChange('skipCheckShippingProducts')
  }
  function _onChangeScanOrderInEveryMyStores() {
    _onToggleSettingChange('scanOrderInEveryMyStores')
  }
  function _onChangeSkipScanTrackingNumber() {
    _onToggleSettingChange('skipScanTrackingNumber')
  }

  function _getMyStoresNamesText() {
    const myStoreCount = myStoreNames.length
    if (myStoreCount < 5) {
      return myStoreNames.join(', ')
    }
    const myStoreNamesText = myStoreNames.slice(0, 4).join(', ')
    const otherStoreCount = myStoreCount - 4
    return `${myStoreNamesText} และอีก ${otherStoreCount} ร้าน`
  }

  function _isVisibleSettingKey(key: keyof IShippingViewBaseSetting) {
    if (!hideSettingKeys) {
      return true
    }
    return !hideSettingKeys.includes(key)
  }

  return (
    <Box>
      <XIconButton name='settings' style={[S.BUTTON_ICON]} onPress={_openSettingOverlay} />
      <XOverlay
        // contentStyle={{ padding: 8 }}
        contentStyle={{ width: 300, height: p.op.isWeb() ? 360 : undefined }}
        visible={isOverlayVisible}
        onRequestClose={_closeSettingOverlay}>
        <VStack w='full'>
          <XCustomHeader headerRightProps={{ closeIcon: true, onPressItem: _closeSettingOverlay }} title='ตั้งค่าการจัดส่ง' />
          <View style={[S.WIDTH_FULL, S.BOTTOM_LINE, { height: 1 }]} />
          <VStack w='full' py='1'>
            {/* // content begin // */}

            {_isVisibleSettingKey('scanOrderInEveryMyStores') ? (
              <HStack w='full' p='2' space='1' bg='muted.100'>
                <VStack flex={1}>
                  <XText variant={currentSetting.scanOrderInEveryMyStores ? 'active' : 'inactive'}>ค้นหาออเดอร์ในทุกๆ ร้านของฉัน</XText>
                  {currentSetting.scanOrderInEveryMyStores && myStoreNames ? (
                    <XText variant='inactive'>{`(ระบบจะค้นหาในร้าน ${_getMyStoresNamesText()})`}</XText>
                  ) : null}
                  {!currentSetting.scanOrderInEveryMyStores && selectedStoreName ? (
                    <XText variant='inactive'>{`(ระบบจะค้นหาในร้าน ${selectedStoreName} เท่านั้น)`}</XText>
                  ) : null}
                </VStack>
                <XSwitch value={currentSetting.scanOrderInEveryMyStores} onValueChange={_onChangeScanOrderInEveryMyStores} />
              </HStack>
            ) : null}

            {_isVisibleSettingKey('skipCheckShippingProducts') ? (
              <HStack w='full' p='2' space='1'>
                <VStack flex={1}>
                  <XText variant={currentSetting.skipCheckShippingProducts ? 'active' : 'inactive'}>ข้ามขั้นตอนการตรวจสอบสินค้า</XText>
                </VStack>
                <XSwitch value={currentSetting.skipCheckShippingProducts} onValueChange={_onChangeSkipCheckShippingProducts} />
              </HStack>
            ) : null}

            {_isVisibleSettingKey('skipScanTrackingNumber') ? (
              <HStack w='full' p='2' space='1' bg='muted.100'>
                <VStack flex={1}>
                  <XText variant={currentSetting.skipScanTrackingNumber ? 'active' : 'inactive'}>ข้ามขั้นตอนการระบุเลขพัสดุ</XText>
                </VStack>
                <XSwitch value={currentSetting.skipScanTrackingNumber} onValueChange={_onChangeSkipScanTrackingNumber} />
              </HStack>
            ) : null}

            {/* // content end // */}
          </VStack>
        </VStack>
      </XOverlay>
    </Box>
  )
}

const styles = StyleSheet.create({
  limitLabelWebWidth: {
    ...Platform.select({
      web: {
        maxWidth: 260,
      },
    }),
  },
})

ShippingSettingIconButton.defaultProps = {
  currentSetting: CONS.DEFAULT_SHIPPING_VIEW_SETTING,
}

export default ShippingSettingIconButton
