import React, { useContext } from 'react'
import { Modal } from 'native-base'
import HStack from 'xui/components/HStack'
import VStack from 'xui/components/VStack'
import _ from 'lodash'
// import Modal from 'react-modal'
import { STYLES } from 'x/config/styles'
import NB from 'x/config/nativebase'
import { IXModalProps } from 'x/index'

import XCustomHeader from 'xui/components/XCustomHeader'
import XIconButton from 'xui/components/XIconButton'
import ThemeSizeContext from '../context/ThemeSizeContext'

// interface IXModal {
//   visible: boolean
//   webWrapperContainerStyle?: ViewStyle
//   onRequestClose?: () => void
//   webDisabledRequestClose?: boolean
//   webNumColumns?: 1 | 2 | 3 // default = 3
//   // style?: ViewStyle
//   // zIndex?: number
// }

const customModalStyles = {
  overlay: {
    // backgroundColor: 'rgba(0,0,0,0.7)',
    backgroundColor: 'transparent',
  },
  content: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    // backgroundColor: 'rgba(0,0,0,0.7)',
    backgroundColor: 'transparent',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    padding: 0,
    overflow: 'hidden',
    borderWidth: 0,
    // backgroundColor: 'red',
    // marginRight           : '-50%',
    // transform             : 'translate(-50%, -50%)'
  },
}

const XModal: React.FC<IXModalProps> = (props) => {
  const {
    contentHeight,
    contentHeightStyle,
    contentWidthStyle,
    contentOneOfThreeColumnWidthStyle,
    contentTwoOfThreeColumnWidthStyle,
    contentWidth,
    browserWidth,
  } = useContext(ThemeSizeContext)

  const {
    title,
    hasBackButton,
    hasCloseButton,
    renderHeader,
    headerLeft,
    headerRight,
    submitting,
    children,
    onRequestClose,
    containerStyle = {},
    contentStyle = {},
    webDisabledRequestClose = false,
    webWrapperContainerStyle = {},
    visible = false,
    webNumColumns = 1,
  } = props

  const _onRequestClose = () => {
    if (_.isFunction(onRequestClose) && !webDisabledRequestClose) {
      onRequestClose()
    }
  }

  let contentContainerWidthStyle = contentOneOfThreeColumnWidthStyle
  if (webNumColumns === 2) {
    contentContainerWidthStyle = contentTwoOfThreeColumnWidthStyle
  }
  if (webNumColumns === 3) {
    contentContainerWidthStyle = contentWidthStyle
  }

  const isShowCloseButton = browserWidth > contentWidth + STYLES.FONT_ICON_NORMAL * 3

  const shouldRenderHeader =
    _.isString(title) ||
    hasBackButton ||
    hasCloseButton ||
    _.isFunction(headerLeft) ||
    _.isFunction(headerRight) ||
    _.isFunction(renderHeader)

  const renderChildrenWithHeader = () => (
    // <XContainer style={[contentContainerWidthStyle, contentHeightStyle, containerStyle]}>
    <VStack style={[contentContainerWidthStyle, contentHeightStyle, containerStyle]}>
      {(_.isString(title) || hasBackButton || hasCloseButton || _.isFunction(headerLeft) || _.isFunction(headerRight)) &&
      !_.isFunction(renderHeader) ? (
        <XCustomHeader
          headerLeft={_.isFunction(headerLeft) ? headerLeft() : null}
          headerRight={_.isFunction(headerRight) ? headerRight() : null}
          headerLeftProps={hasBackButton && !headerLeft ? { backIcon: true, onPressItem: _onRequestClose } : null}
          headerRightProps={hasCloseButton && !headerRight ? { closeIcon: true, onPressItem: _onRequestClose } : null}
          title={title}
        />
      ) : null}
      {renderHeader ? renderHeader() : null}
      {/* <VStack
        style={[contentContainerWidthStyle, { height: contentHeight - 44 }, contentStyle]}
        // contentContainerStyle={[contentContainerWidthStyle, { height: contentHeight - 44 }, contentStyle]}
      > */}
      <VStack style={[contentContainerWidthStyle, { height: contentHeight - 44 }, contentStyle]}>{children}</VStack>
      {/* </VStack> */}
      {/* </XContainer> */}
    </VStack>
  )

  return (
    <Modal
      // shouldCloseOnEsc={false}
      isOpen={visible}
      onClose={_onRequestClose}
      // onAfterOpen={afterOpenModal}
      // onRequestClose={_onRequestClose}
      // style={[contentHeightStyle, contentWidthStyle]}
      // style={{
      //   overlay: customModalStyles.overlay,
      //   content: { ...customModalStyles.content, ...style },
      // }}
      // @ts-ignore
      // style={customModalStyles}
    >
      {/* <TouchableOpacity
        onPress={_onRequestClose}
        disabled={!_.isFunction(onRequestClose) || webDisabledRequestClose || submitting}
        // @ts-ignore
        style={[s.btnRequestCloseArea, s.tranparentBg]}
      /> */}

      <HStack
        _light={{ bg: NB.C.L.BG.CLEAR }}
        _dark={{ bg: NB.C.D.BG.CLEAR }}
        borderRadius='lg'
        overflow='hidden'
        style={[contentContainerWidthStyle, contentHeightStyle, webWrapperContainerStyle]}>
        {shouldRenderHeader ? renderChildrenWithHeader() : children}
      </HStack>

      {isShowCloseButton ? (
        <XIconButton
          name='close-circle-outline'
          rounded='full'
          position='absolute'
          top='1'
          right='1'
          onPress={_onRequestClose}
          isDisabled={!_.isFunction(onRequestClose) || webDisabledRequestClose || submitting}
          // @ts-ignore
          // style={s.btnRequestCloseIcon}
        />
      ) : null}
    </Modal>
  )
}

export default XModal

// export default class XModal extends React.PureComponent<IXModal> {
//   static contextType = ThemeSizeContext
//   context!: React.ContextType<typeof ThemeSizeContext>

//   _onRequestClose = () => {
//     const { onRequestClose, webDisabledRequestClose = false } = this.props
//     if (_.isFunction(onRequestClose) && !webDisabledRequestClose) {
//       onRequestClose()
//     }
//   }

//   render() {
//     const { children, onRequestClose, webDisabledRequestClose = false, webWrapperContainerStyle, visible = false, webNumColumns = 3 } = this.props

//     const {
//       contentHeightStyle,
//       contentWidthStyle,
//       contentOneOfThreeColumnWidthStyle,
//       contentTwoOfThreeColumnWidthStyle,
//       contentWidth,
//       browserWidth,
//     } = this.context

//     // if (!visible) {
//     //   return null
//     // }

//     let contentContainerWidthStyle = contentWidthStyle
//     if (webNumColumns === 2) {
//       contentContainerWidthStyle = contentTwoOfThreeColumnWidthStyle
//     } else if (webNumColumns === 1) {
//       contentContainerWidthStyle = contentOneOfThreeColumnWidthStyle
//     }

//     const isShowCloseButton = browserWidth > contentWidth + STYLES.FONT_ICON_NORMAL * 3

//     return (
//       <Modal
//         shouldCloseOnEsc={false}
//         isOpen={visible}
//         // onAfterOpen={this.afterOpenModal}
//         onRequestClose={this._onRequestClose}
//         // style={[contentHeightStyle, contentWidthStyle]}
//         // style={{
//         //   overlay: customModalStyles.overlay,
//         //   content: { ...customModalStyles.content, ...style },
//         // }}
//         style={customModalStyles}>
//         <TouchableOpacity
//           onPress={this._onRequestClose}
//           disabled={!_.isFunction(onRequestClose) || webDisabledRequestClose}
//           // @ts-ignore
//           style={[s.btnRequestCloseArea, s.tranparentBg]}
//         />
//         <View style={[contentContainerWidthStyle, contentHeightStyle, webWrapperContainerStyle]}>{children}</View>
//         {isShowCloseButton ? (
//           <TouchableOpacity
//             onPress={this._onRequestClose}
//             disabled={!_.isFunction(onRequestClose) || webDisabledRequestClose}
//             // @ts-ignore
//             style={s.btnRequestCloseIcon}>
//             <Icon name={'close-circle-outline'} style={{ fontSize: STYLES.FONT_ICON_NORMAL, color: COLORS.APP_MAIN }} />
//           </TouchableOpacity>
//         ) : null}
//       </Modal>
//     )
//   }
// }

const s = {
  btnRequestCloseArea: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'defulat',
  },
  // btnRequestCloseIcon: {
  //   position: 'absolute',
  //   top: -1 * (STYLES.FONT_ICON_NORMAL / 2),
  //   right: -1 * (STYLES.FONT_ICON_NORMAL / 2),
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   width: STYLES.FONT_ICON_NORMAL,
  //   height: STYLES.FONT_ICON_NORMAL,
  //   // zIndex: 101,
  // },
  btnRequestCloseIcon: {
    position: 'absolute',
    top: STYLES.FONT_ICON_NORMAL / 2,
    right: STYLES.FONT_ICON_NORMAL / 2,
    // alignItems: 'center',
    // justifyContent: 'center',
    // width: STYLES.FONT_ICON_NORMAL,
    // height: STYLES.FONT_ICON_NORMAL,
    // zIndex: 101,
  },
  tranparentBg: {
    // backgroundColor: 'rgba(0,0,0,0.7)',
  },
}
