import _ from 'lodash'
import { fromJS } from 'immutable'
import api from 'x/utils/api'
import { take, takeLatest, put, call, fork, all } from 'redux-saga/effects'
import * as ProfileActions from 'x/modules/profile/ProfileState'
import actions from '../../config/actions'
import * as util from '../../utils/util'
import { log } from '../../utils/util'
import p from '../../config/platform-specific'
import xCONS from '../../config/constants'

// import { setCrashlyticsUserId } from './../../utils/analytics'

export default function* watchAllUserProfile() {
  yield all([
    fork(watchUserProfileFetch),
    fork(watchUserProfileEdit),
    // fork(watchUserProfilePatch),
    fork(watchAccountEmailChange),
    fork(watchGetVerificationEmail),
    fork(watchFetchProfileList),
    fork(watchImportProfiles),
    fork(watchApprovalsReseller),
    fork(watchFetchProfileDetails),
    fork(watchupdateOrgMemberProfileDetail),
    fork(watchUpdateLoginEmail),
    fork(watchOrgMemberSignup),
    fork(watchChangeParent),
    fork(watchChangeResellerType),
  ])
}

function* watchAccountEmailChange() {
  log('In watchAccountEmailChange')
  while (true) {
    const action = yield take(actions.USER_PROFILE_CHANGE_EMAIL)
    yield call(changeAccountEmail, action)
  }
}

function* changeAccountEmail(action: { payload: any; successCallback: (res: Response) => void; failedCallback: (err: Error) => void }) {
  log('In changeAccountEmail')
  try {
    const apiOptions = {
      messages: {
        successMsg: 'แก้ไขอีเมล์สำเร็จแล้ว',
        errorMsg: 'เกิดข้อผิดพลาดในแก้ไข',
      },
      showSpinner: true,
    }
    // @ts-ignore
    const res = yield call(api.patch, api.PATCH_CHANGE_EMAIL, action.payload, apiOptions)
    log('In changeAccountEmail res: ', res)
    if (res && res.status === 'ok') {
      // yield call(p.op.showToast, 'เปลี่ยนอีเมลสำเร็จแล้ว', 'success', 5000)
      // @ts-ignore FIXME: Don't know what this mean but ignore because it's work
      yield call(fetchUserProfile, {})
      // yield put(NavActions.pop(1))
    }
    if (_.isFunction(action.successCallback)) {
      action.successCallback(res)
    }
  } catch (error) {
    log('In changeAccountEmail error: ', error)
    yield put({ type: actions.USER_PROFILE_CHANGE_EMAIL_INVALID })
    if (_.isFunction(action.failedCallback)) {
      action.failedCallback(error)
    }
  }
}

function* watchGetVerificationEmail() {
  log('In watchGetVerificationEmail')
  while (true) {
    const { payload } = yield take(actions.USER_PROFILE_GET_VERIFICATION_EMAIL)
    yield call(getVerificationEmail, payload)
  }
}

function* getVerificationEmail(currentEmail) {
  log('In getVerificationEmail')
  try {
    // @ts-ignore
    const res = yield call(api.get, api.GET_VERIFICATION_EMAIL)
    log('In getVerificationEmail res: ', res)
    if (res && res.status === 'ok') {
      yield call(
        p.op.showToast,
        `ส่งอีเมลยืนยันตัวตนไปที่ ${currentEmail ? ` "${currentEmail}" ` : 'อีเมลของคุณ'}สำเร็จ กรุณาเปิดอีเมลและกดปุ่มยืนยันตัวตน`,
        'success',
        5000
      )
      // yield put(NavActions.pop(1))
    }
  } catch (error) {
    log('In getVerificationEmail error: ', error)
    yield put({ type: actions.USER_PROFILE_GET_VERIFICATION_EMAIL_INVALID })
  }
}

function* watchUserProfileFetch() {
  log('In watchAccountLogin')
  // @ts-ignore
  yield takeLatest(actions.USER_PROFILE_FETCH, fetchUserProfile)
}

function* fetchUserProfile({ successCallback, failedCallback }) {
  log('In fetchUserProfile')
  try {
    // @ts-ignore
    const res = yield call(api.get, api.GET_PROFILE)
    log('In fetchUserProfile res: ', res)
    yield call(handleProfileFromResponse, res)
    if (res && _.isFunction(successCallback)) {
      successCallback(res)
    }
  } catch (error) {
    log('In fetchUserProfile error: ', error)
    yield put({ type: actions.USER_PROFILE_FETCH_INVALID })
    if (_.isFunction(failedCallback)) {
      failedCallback(error)
    }
  }
}

function* watchUserProfileEdit() {
  log('In watchUserProfileEdit')
  while (true) {
    const { payload, successCallback, failedCallback } = yield take(actions.USER_PROFILE_EDIT)
    yield call(editUserProfile, payload, successCallback, failedCallback)
  }
}

function* editUserProfile(payload, successCallback, failedCallback) {
  log('In editUserProfile')
  try {
    // @ts-ignore
    const res = yield call(api.patch, api.PATCH_PROFILE, payload, { showSpinner: true })
    log('In editUserProfile res: ', res)
    yield call(handleProfileFromResponse, res)
    util.showSuccessToast('แก้ไขข้อมูลผู้ใช้เรียบร้อยแล้ว')
    if (_.isFunction(successCallback)) {
      successCallback(res)
    }
  } catch (error) {
    log('In editUserProfile error: ', error)
    yield put({ type: actions.USER_PROFILE_EDIT_INVALID })
    if (_.isFunction(failedCallback)) {
      failedCallback(error)
    }
  }
}

export function* handleProfileFromResponse(res) {
  if (res && res.profile) {
    const { profile } = res
    // yield call(p.op.handleAfterGetNewProfile, res.profile)
    p.op.handleAfterGetNewProfile(res.profile)
    util.log('handleProfileFromResponse profile => ', profile)
    // if (profile.user_id) {
    //   setCrashlyticsUserId(profile.user_id)
    // }
    yield put({ type: ProfileActions.LOAD_PROFILE, payload: fromJS(profile) })
    yield call(p.op.storageSet, xCONS.STORAGE_KEYS.USER_PROFILE, profile)
  }
}

function* watchFetchProfileList() {
  while (true) {
    const { payload } = yield take(actions.LOAD_PROFILE_LIST)
    yield call(fetchProfileList, payload)
  }
}

function* fetchProfileList(payload) {
  const { body } = payload
  try {
    // @ts-ignore
    const res = yield call(api.postV2, api.POST_FETCH_PROFILE_LIST, body)
    if (payload.successCallback) {
      payload.successCallback(res)
    }
  } catch (error) {
    if (payload.failedCallback) {
      payload.failedCallback(error)
    }
  }
}

function* watchImportProfiles() {
  while (true) {
    const { payload } = yield take(actions.IMPORT_PROFILES)
    yield call(importProfiles, payload)
  }
}

function* importProfiles(payload) {
  const { body } = payload
  // console.log(`BODY ! `, body)
  try {
    const res = yield call(api.postV2, api.POST_IMPORT_PROFILES, body)
    if (payload.successCallback) {
      payload.successCallback(res)
    }
  } catch (error) {
    if (payload.failedCallback) {
      payload.failedCallback(error)
    }
  }
}

function* watchApprovalsReseller() {
  while (true) {
    const { payload } = yield take(actions.APPROVALS_RESELLER)
    yield call(approvalsReseller, payload)
  }
}

function* approvalsReseller(payload) {
  const { body } = payload
  try {
    // @ts-ignore
    const res = yield call(api.postV2, api.POST_APPROALS_RESELLER, body, {
      axiosOptions: {
        retry: 0,
        timeout: 147600000, // long fetch 1 day with no retry
      },
      showSpinner: true,
    })
    if (payload.successCallback) {
      payload.successCallback(res)
    }
  } catch (error) {
    if (payload.failedCallback) {
      payload.failedCallback(error)
    }
  }
}

function* watchupdateOrgMemberProfileDetail() {
  while (true) {
    const { payload } = yield take(actions.UPDATE_ORG_MEMBER_PROFILE_DETAILE)
    yield call(updateOrgMemberProfileDetail, payload)
  }
}

function* updateOrgMemberProfileDetail(payload) {
  const { body } = payload
  try {
    // @ts-ignore
    const res = yield call(api.patchV2, api.PATCH_UPDATE_ORG_MEMBER_PROFILE_DETAILE, body)
    if (payload.successCallback) {
      payload.successCallback(res)
    }
  } catch (error) {
    if (payload.failedCallback) {
      payload.failedCallback(error)
    }
  }
}

function* watchFetchProfileDetails() {
  while (true) {
    const { payload } = yield take(actions.FETCH_PROFILE_DETAILS)
    yield call(fetchProfileDetails, payload)
  }
}

function* fetchProfileDetails(payload) {
  const { body } = payload
  try {
    // @ts-ignore
    const res = yield call(api.postV2, api.POST_FETCH_PROFILE_DETAILS, body)
    if (payload.successCallback) {
      payload.successCallback(res)
    }
  } catch (error) {
    if (payload.failedCallback) {
      payload.failedCallback(error)
    }
  }
}

function* watchUpdateLoginEmail() {
  while (true) {
    const { payload } = yield take(actions.UPDATE_LOGIN_EMAIL)
    yield call(updateLoginEmail, payload)
  }
}

function* updateLoginEmail(payload) {
  const { body } = payload
  try {
    // @ts-ignore
    const res = yield call(api.patchV2, api.PATCH_UPDATE_LOGIN_EMAIL, body)
    if (payload.successCallback) {
      payload.successCallback(res)
    }
  } catch (error) {
    if (payload.failedCallback) {
      payload.failedCallback(error)
    }
  }
}

function* watchOrgMemberSignup() {
  while (true) {
    const { payload } = yield take(actions.ORG_MEMBER_SIGNUP)
    yield call(orgMemberSignup, payload)
  }
}

function* orgMemberSignup(payload) {
  const { body } = payload
  try {
    // @ts-ignore
    const res = yield call(api.postV2, api.POST_ORG_MEMBER_SIGNUP, body, { noToken: true })
    if (payload.successCallback) {
      payload.successCallback(res)
    }
  } catch (error) {
    if (payload.failedCallback) {
      payload.failedCallback(error)
    }
  }
}

function* watchChangeParent() {
  while (true) {
    const { payload } = yield take(actions.CHANGE_PARENT)
    yield call(changeParent, payload)
  }
}

function* changeParent(payload) {
  const { body } = payload
  try {
    // @ts-ignore
    const res = yield call(api.postV2, api.POST_CHANGE_PARENT, body, {
      axiosOptions: {
        retry: 0,
        timeout: 147600000, // long fetch 1 day with no retry
      },
      // showSpinner: true,
    })
    if (payload.successCallback) {
      payload.successCallback(res)
    }
  } catch (error) {
    if (payload.failedCallback) {
      payload.failedCallback(error)
    }
  }
}

function* watchChangeResellerType() {
  while (true) {
    const { payload } = yield take(actions.CHANGE_RESELLER_TYPE)
    yield call(changeResellerType, payload)
  }
}

function* changeResellerType(payload) {
  const { body } = payload
  try {
    // @ts-ignore
    const res = yield call(api.patchV2, api.PATCH_CHANGE_RESELLER_TYPE, body, {
      axiosOptions: {
        retry: 0,
        timeout: 147600000, // long fetch 1 day with no retry
      },
      // showSpinner: true,
    })
    if (payload.successCallback) {
      payload.successCallback(res)
    }
  } catch (error) {
    if (payload.failedCallback) {
      payload.failedCallback(error)
    }
  }
}
