import React from 'react'
import { TypedNavigator } from '@react-navigation/native'

import { IAnyObject } from 'x/types'

type IAdapterScreenType =
  | {
      screen: JSX.Element | any
      path?: string
      navigationOptions?: IAnyObject
    }
  | JSX.Element
  | any

type IAdapterScreensType = {
  [screenKey: string]: IAdapterScreenType
}

export default function renderAdapterScreens(Navigator: TypedNavigator<any, any, any, any, any>, screens: IAdapterScreensType) {
  const screenKeys = Object.keys(screens)
  return screenKeys.map((sKey) => (
    <Navigator.Screen
      key={sKey}
      name={sKey}
      component={screens[sKey].screen || screens[sKey]}
      options={screens[sKey].navigationOptions || {}}
      //
    />
  ))
}
