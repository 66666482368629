// import PropTypes from 'prop-types'

import BaseUIStoreSettingAllPrintingShippingLabelView from 'xui/modules/store/storeSetting/BaseUIStoreSettingAllPrintingShippingLabelView'

// const PULL_VOLUMN_DISCOUNT_ICON_IMAGE = require('../../img/volumn_discount.png')
// const ICON_MY_STORE = require('../../img/tb/tb_my0.png')

class StoreSettingAllPrintingShippingLabelView extends BaseUIStoreSettingAllPrintingShippingLabelView {
  static displayName = 'StoreSettingAllPrintingShippingLabelView'

  static navigationOptions = {
    header: null,
    title: null,
    headerLeft: null,
    headerRight: null,
  }
}

export default StoreSettingAllPrintingShippingLabelView
