import { take, put, call, fork, all } from 'redux-saga/effects'
import _ from 'lodash'
import api from '../../utils/api'
import { ActionApiParams } from '../../index'
import p from '../../config/platform-specific'
import { log } from '../../utils/util'
import actions from '../../config/actions'
import * as StoreActions from '../../modules/store/StoreState'

export default function* watchAllVolumeDiscount() {
  log('In watchCreateVolumeDiscount')
  yield all([
    fork(watchCreateVolumeDiscount),
    fork(watchUpdateVolumeDiscount),
    fork(watchDeleteVolumeDiscount),
    fork(watchLoadVolumeDiscounts),
    fork(watchPairVolumeDiscount),
    fork(watchUnpairVolumeDiscount),
  ])
}

function* watchCreateVolumeDiscount() {
  log('In watchCreateVolumeDiscount')
  while (true) {
    const action = yield take(actions.CREATE_VOLUME_DISCOUNT)
    yield call(createVolumeDiscount, action)
  }
}

function* createVolumeDiscount(action: ActionApiParams) {
  const apiOptions = {
    messages: {
      successMsg: 'เพิ่มส่วนลดขายส่งสำเร็จ',
      errorMsg: 'เพิ่มส่วนลดขายส่งล้มเหลว',
    },
    showSpinner: true,
  }
  try {
    log('In createUser body => ', action.body)
    const res = yield call(api.put, api.PUT_CREATE_VOLUME_DISCOUNT, action.body, apiOptions)
    log('In createUser response => ', res)
    yield call(loadNewVolumeDiscountFromResponse, res)
    // yield call(loadNewUserGroupFromResponse, res)
    if (_.isFunction(action.successCallback)) {
      action.successCallback(res)
    }
    // yield put(NavActions.goBack) // go back to StoreMyView
  } catch (error) {
    log('In createUser error => ', error)
    // yield put({ type: actions.ERROR_CREATE_USER_GROUP, error })
    // if (_.isFunction(action.failedCallback)) {
    //   action.failedCallback(error)
    // }
  }
}

function* loadNewVolumeDiscountFromResponse(res) {
  if (!_.isEmpty(res)) {
    yield put(StoreActions.addVDBeforeCreateVolumeDiscount(res))
  }
}

function* watchUpdateVolumeDiscount() {
  log('In watchUpdateVolumeDiscount')
  while (true) {
    const action = yield take(actions.UPDATE_VOLUME_DISCOUNT)
    yield call(updateVolumeDiscount, action)
  }
}

function* updateVolumeDiscount(action: ActionApiParams) {
  const apiOptions = {
    messages: {
      successMsg: 'แก้ไขส่วนลดขายส่งสำเร็จ',
      errorMsg: 'แก้ไขส่วนลดขายส่งล้มเหลว',
    },
    showSpinner: true,
  }
  try {
    log('In updateUser body => ', action.body)
    const res = yield call(api.patch, api.PATCH_VOLUME_DISCOUNT, action.body, apiOptions)
    yield put({ type: actions.UPDATE_NEW_VOLUME_DISCOUNT, payload: res.volume_discount })
    // yield call(loadUpdatedUserGroupFromResponse, res)
    if (_.isFunction(action.successCallback)) {
      action.successCallback(res)
    }
    // yield put(NavActions.goBack) // go back to StoreMyView
  } catch (error) {
    log('In updateUser error => ', error)
    // yield put({ type: actions.ERROR_UPDATE_USER_GROUP, error })
    // if (_.isFunction(action.failedCallback)) {
    // action.failedCallback(error)
    // }
  }
}

function* watchDeleteVolumeDiscount() {
  log('In watchDeleteVolumeDiscount')
  while (true) {
    const action = yield take(actions.DELETE_VOLUME_DISCOUNT)
    yield call(deleteVolumeDiscount, action)
  }
}

function* deleteVolumeDiscount(action: ActionApiParams) {
  const apiOptions = {
    messages: {
      successMsg: 'ลบส่วนลดขายส่งสำเร็จ',
      errorMsg: 'ลบส่วนลดขายส่งล้มเหลว',
    },
    showSpinner: true,
  }
  const { payload } = action
  if (!payload) {
    p.op.alert('เกิดข้อผผิดพลาด', 'ไม่สามารถลบส่วนลดขายส่งนี้ได้ กรุณาทำายงานอีกครั้งภายหลัง')
    return
  }
  try {
    // log('In deleteVolumeDiscount body => ', action.payload.body)
    const res = yield call(api.del, api.DELETE_VOLUME_DISCOUNT, payload.body, apiOptions)
    yield call(clearRemovedVolumeDiscountFromResponse, res, payload.body)
    if (_.isFunction(payload.successCallback)) {
      payload.successCallback(res)
    }
  } catch (error) {
    log('In deleteVolumeDiscount error => ', error)
    // yield put({ type: actions.ERROR_DELETE_USER_GROUP, error })
    // if (_.isFunction(payload.failedCallback)) {
    //   payload.failedCallback(error)
    // }
  }
}

function* clearRemovedVolumeDiscountFromResponse(res, requestBody) {
  if (_.has(res, 'status') && res.status === 'ok') {
    yield put(StoreActions.clearRemovedVolumeDiscountListById(requestBody))
  }
}

function* watchLoadVolumeDiscounts() {
  log('In watchLoadVolumeDiscounts')
  while (true) {
    const action = yield take(actions.LOAD_VOLUME_DISCOUNTS)
    yield call(loadVolumeDiscounts, action)
  }
}

function* loadVolumeDiscounts(action: ActionApiParams) {
  try {
    // log('In loadVolumeDiscounts body => ', action.payload.body)
    const res = yield call(api.post, api.LOAD_VOLUME_DISCOUNTS, action.payload)
    yield call(fetchVolumeDiscounts, res)
    if (_.isFunction(action.payload.successCallback)) {
      action.payload.successCallback(res)
    }
  } catch (error) {
    log('In loadVolumeDiscounts error => ', error)
    // yield put({ type: actions.ERROR_DELETE_USER_GROUP, error })
    // if (_.isFunction(payload.failedCallback)) {
    //   payload.failedCallback(error)  // Fetch
    // }
  }
}

function* fetchVolumeDiscounts(res) {
  if (!_.isEmpty(res)) {
    yield put(StoreActions.fetchVolumeDiscounts(res.vds))
  }
}

function* watchPairVolumeDiscount() {
  log('In watchCreateVolumeDiscount')
  while (true) {
    const action = yield take(actions.PAIR_VOLUME_DISCOUNT)
    yield call(pairVolumeDiscount, action)
  }
}

function* pairVolumeDiscount(action: ActionApiParams) {
  try {
    // log('In pairVolumeDiscount body => ', action.payload.body)
    const res = yield call(api.post, api.PAIR_VOLUME_DISCOUNT, action.payload.body)
    // console.log(res)
    // yield call(fetchVolumeDiscounts, res)
    if (_.isFunction(action.payload.successCallback)) {
      action.payload.successCallback(res)
    }
  } catch (error) {
    log('In pairVolumeDiscount error => ', error)
    // yield put({ type: actions.ERROR_DELETE_USER_GROUP, error })
    // if (_.isFunction(payload.failedCallback)) {
    //   payload.failedCallback(error)  // Fetch
    // }
  }
}

function* watchUnpairVolumeDiscount() {
  log('In watchCreateVolumeDiscount')
  while (true) {
    const action = yield take(actions.UNPAIR_VOLUME_DISCOUNT)
    yield call(unpairVolumeDiscount, action)
  }
}

function* unpairVolumeDiscount(action: ActionApiParams) {
  try {
    // log('In pairVolumeDiscount body => ', action.payload.body)
    const res = yield call(api.post, api.UNPAIR_VOLUME_DISCOUNT, action.payload.body)
    // console.log(res)
    // yield call(fetchVolumeDiscounts, res)
    if (_.isFunction(action.payload.successCallback)) {
      action.payload.successCallback(res)
    }
  } catch (error) {
    log('In pairVolumeDiscount error => ', error)
    // yield put({ type: actions.ERROR_DELETE_USER_GROUP, error })
    // if (_.isFunction(payload.failedCallback)) {
    //   payload.failedCallback(error)  // Fetch
    // }
  }
}
