/* eslint-disable @typescript-eslint/no-misused-promises */
import React from 'react'
import _ from 'lodash'
// import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'
import BaseERPChannelContact from 'x/modules/erp/BaseERPChannelContact'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import XCard from 'xui/components/XCard'
import HStack from 'xui/components/HStack'
import XFaqModal from 'xui/components/XFaqModal'
import XText from 'xui/components/XText'
import FAQ_CONSTANTS from 'x/config/FAQ_CONSTANTS'
import HelpButton from 'xui/components/HelpButton'
import VStack from 'xui/components/VStack'
import XInput from 'xui/components/XInput'
import MkpLogo from 'xui/components/MkpLogo'
import XButton from 'xui/components/XButton'
import XSellyLogo from 'xui/components/XSellyLogo'

const LOGO = require('../../img/logo_1024.png')

const _renderAgreement = (onPressOpenOverlay: () => void) => <HelpButton onPress={() => onPressOpenOverlay()} />

export default abstract class BaseUIERPChannelContact extends BaseERPChannelContact {
  renderCustomHeader = () => {
    const isGoBackButtonHidden = util.getNavParam(this.props, 'isGoBackButtonHidden', false)
    // const { isRenderWebView } = this.state
    return (
      <XCustomHeader
        title='รหัสผู้ติดต่อ'
        headerLeftProps={{ backIcon: true, onPressItem: () => this.goBack() }}
        // headerRightProps={{ label: 'บันทึก' }}
      />
    )
  }

  _renderTitle = () => {
    const {} = this.state
    return (
      <XCard p='2' w='full'>
        <HStack alignItems='center' w='full'>
          <HStack flex={1}>
            <XText>{`สำหรับออเดอร์ที่ไม่มีรหัสผู้ติดต่อที่ระบุไว้ในหน้าตั้งค่าระบบบัญชี XSelly จะใช้ "ชื่อและรหัสผู้ติดต่อ" ตามที่แสดงไว้ด้านล่างนี้ในการส่งข้อมูลไปที่ PEAK`}</XText>
          </HStack>
          <HStack w='8'>
            <XFaqModal
              key='รหัสผู้ติดต่อ'
              headerTitle='รหัสผู้ติดต่อ'
              // @ts-ignore
              FAQ={FAQ_CONSTANTS.FAQ_ERP_CONTACT}
              initiateOpenIndex={[0, 1, 2]}
              renderButton={(onPressOpenOverlay: () => void) => _renderAgreement(onPressOpenOverlay)}
            />
          </HStack>
        </HStack>
      </XCard>
    )
  }

  _renderContactListView = () => {
    const { erpMkpContacts, editingContactCodeIndex, mkpChannels, backUpErpMkpContacts } = this.state
    if (_.isNil(erpMkpContacts) || _.isNil(mkpChannels)) {
      return null
    }
    return (
      <VStack>
        {erpMkpContacts.map((contact, idx) => {
          // console.log('mkpChannels => ', mkpChannels)
          // console.log('contact => ', contact)
          const mkpChannel = mkpChannels.find((mkp) => mkp.id === contact.sales_channel_id)
          let erpcontactName = mkpChannel ? mkpChannel.name : '-'
          const isEditMode = editingContactCodeIndex === idx
          const contactName = contact.contact_name ? contact.contact_name : ''
          const contactCode = contact.contact_code ? contact.contact_code : ''

          let LOGO_RENDER = null
          if (contact.sales_channel_type === 'XSELLY') {
            erpcontactName = contact.sales_channel_type
            LOGO_RENDER = <XSellyLogo />
          } else if (contact.sales_channel_type === 'MKP') {
            LOGO_RENDER = <MkpLogo mkpId={mkpChannel.mkp_id} />
          }

          return (
            // eslint-disable-next-line react/no-array-index-key
            <XCard p='2' w='full' mt='2' key={`contact_${idx}`}>
              {/* // Header */}
              <HStack alignItems='center'>
                {_.isNil(LOGO_RENDER) ? null : LOGO_RENDER}
                <XText ml='2' bold>
                  {erpcontactName}
                </XText>
              </HStack>
              {/* //ข้อมูลผู้ติดต่อเฉพาะช่องทางขายนี้  */}
              <HStack w='full' alignItems='center'>
                <HStack flex={1}>
                  <XText>ข้อมูลผู้ติดต่อเฉพาะช่องทางขายนี้</XText>
                </HStack>
                <HStack flex={1} justifyContent='flex-end'>
                  {isEditMode ? (
                    <HStack>
                      <XButton
                        variant='outline'
                        onPress={() =>
                          this.setState({
                            editingContactCodeIndex: -1,
                            erpMkpContacts: backUpErpMkpContacts,
                          })
                        }>
                        ยกเลิก
                      </XButton>
                      <XButton ml='2' variant='outline' onPress={() => this._submitContact()}>
                        บันทึก
                      </XButton>
                    </HStack>
                  ) : (
                    <XButton
                      variant='outline'
                      onPress={() =>
                        this.setState({
                          editingContactCodeIndex: idx,
                        })
                      }>
                      แก้ไข
                    </XButton>
                  )}
                </HStack>
              </HStack>
              {/* // ชื่อผู้ติดต่อ */}
              <HStack w='full' alignItems='center'>
                <HStack flex={1}>
                  <XText>ชื่อผู้ติดต่อ</XText>
                </HStack>
                <HStack flex={2} justifyContent='flex-end' mt='2'>
                  {isEditMode ? (
                    <XInput
                      value={contactName}
                      textAlign='right'
                      placeholder='ลูกค้าทั่วไป'
                      w='48'
                      onChangeText={(newText) => this._onChangeContactName(idx, newText)}
                    />
                  ) : (
                    <XText textAlign='right' numberOfLines={2}>
                      {contactName}
                    </XText>
                  )}
                </HStack>
              </HStack>
              {/* //รหัสผู้ติดต่อ  */}
              <HStack w='full' alignItems='center'>
                <HStack flex={1}>
                  <XText>รหัสผู้ติดต่อ</XText>
                </HStack>
                <HStack flex={2} justifyContent='flex-end' mt='2'>
                  {isEditMode ? (
                    <XInput
                      value={contactCode}
                      textAlign='right'
                      placeholder='XSELLY_CONTACT'
                      w='48'
                      onChangeText={(newText) => this._onChangeContactCode(idx, newText)}
                    />
                  ) : (
                    <XText textAlign='right' numberOfLines={2}>
                      {contactCode}
                    </XText>
                  )}
                </HStack>
              </HStack>
            </XCard>
          )
        })}
      </VStack>
    )
  }

  render() {
    return (
      <XContainer>
        {this.renderCustomHeader()}
        <XContent p='2'>
          {this._renderTitle()}
          {this._renderContactListView()}
          <HStack mt='4' />
        </XContent>
      </XContainer>
    )
  }
}
