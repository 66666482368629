// import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Box from 'xui/components/Box'
import HStack from 'xui/components/HStack'
import VStack from 'xui/components/VStack'
import XButton from 'xui/components/XButton'

import { View, RefreshControl } from 'react-native'

import { logRender } from 'x/utils/util'
import { COLORS, STYLES } from 'x/config/styles'
import XIcon from 'xui/components/XIcon'
import XCard from 'xui/components/XCard'
import p from 'x/config/platform-specific'
import _ from 'lodash'
import { fromJS } from 'immutable'

// import SortableListView from 'react-native-sortable-listview'
import { INavHeaderButtonProps, IXScreenProps, VDListViewProps, VDListViewState } from 'x/index'
// import XGalleryModal from '../../components/XGalleryModal'
// import QRCode from '../../components/QRCode'
import * as xUtil from 'x/utils/util'
// let X_LOGO = require('../../img/logo_1024.png')
import { APP_CONFIG } from 'x/config/mode'
import XText from 'xui/components/XText'
import * as NavActions from 'x/utils/navigation'
import RangeAdjuster from 'xui/components/RangeAdjuster'
import XContent from 'xui/components/XContent'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContainer from 'xui/components/XContainer'

const PULL_MODE = 'PULL_MODE'
const VIEW_MODE = 'VIEW_MODE'
let oldVolumeDiscountId = null

export default class VolumeDiscountListView extends Component<VDListViewProps, VDListViewState> {
  static displayName = 'VolumeDiscountListView'

  // inProcess: boolean
  // isGoback: boolean
  // qrModalRef: any

  // static navigationOptions = ({ navigation }) => {
  //   const { state, goBack, setParams } = navigation
  //   if (!state.params) {
  //     return null
  //   }

  //   const {
  //     addVolumeDiscount = null,
  //     submitting = false,
  //     // mode = null,
  //   } = state.params

  //   let headerLeft = null
  //   let headerRight = null
  //   const headerRightBtn = APP_CONFIG.volume_discount.show_add_btn ? <AddIcon /> : null

  //   switch (state.params.mode) {
  //     case VIEW_MODE:
  //       headerLeft = <XButton disabled={submitting} startIcon={<BackIcon />} variant='ghost' onPress={() => util.xUavGoBack(this.props)} />
  //       headerRight = <XButton disabled={submitting} startIcon={headerRightBtn} variant='ghost' onPress={() => addVolumeDiscount()} />
  //       break
  //     case PULL_MODE:
  //       headerLeft = <XButton disabled={submitting} startIcon={<BackIcon />} variant='ghost' onPress={() => util.xUavGoBack(this.props)} />
  //       headerRight = <XButton disabled={false} startIcon={headerRightBtn} variant='ghost' onPress={() => addVolumeDiscount()} />
  //       break
  //     default:
  //       break
  //   }

  //   return {
  //     headerLeft,
  //     title:
  //       state.params.mode === PULL_MODE
  //         ? `เลือก${p.op.t('Store.VolumeDiscountView.volumeDiscount')}`
  //         : p.op.t('Store.VolumeDiscountView.volumeDiscount'),
  //     headerRight,
  //   }
  // }

  constructor(props: VDListViewProps) {
    super(props)
    // this.inProcess = false
    // this.isGoback = false

    this.state = {
      isRefreshing: false,
      mode: VIEW_MODE,
    }
  }

  async componentDidMount() {
    const params = xUtil.getNavParams(this.props)
    const { pg_vd_ids } = params

    const mode = _.isNil(params.mode) ? VIEW_MODE : PULL_MODE

    if (_.isEmpty(pg_vd_ids)) {
      oldVolumeDiscountId = null
    }
    if (mode === PULL_MODE && !_.isEmpty(pg_vd_ids)) {
      oldVolumeDiscountId = null
      const pg_vd_idsJS = pg_vd_ids.toJS()
      for (let i = 0; i < pg_vd_idsJS.length; i++) {
        if (pg_vd_idsJS[i].pg_id === params.pg_id) {
          oldVolumeDiscountId = pg_vd_idsJS[i].vd_id
          break
        }
      }
      // state.params.pg_vd_ids.map(val => {
      //   if (state.params.pg_id === val.pg_id) {
      //     oldVolumeDiscountId = val.vd_id // จะได้ vd_id เก่า เพื่อเปรียบเทียบกับของใหม่
      //   }
      // })
    } else {
      oldVolumeDiscountId = null
    }

    await xUtil.setStatePromise(this, { mode })
  }

  renderCustomHeader = () => {
    const { mode } = this.state
    const headerTitle =
      mode === PULL_MODE ? `เลือก${p.op.t('Store.VolumeDiscountView.volumeDiscount')}` : p.op.t('Store.VolumeDiscountView.volumeDiscount')
    const headerLeftProps: INavHeaderButtonProps = { backIcon: true, onPressItem: () => xUtil.navGoBack(this.props) }
    const headerRightProps: INavHeaderButtonProps = APP_CONFIG.volume_discount.show_add_btn
      ? { addIcon: true, onPressItem: this._addVolumeDiscount }
      : undefined
    return <XCustomHeader headerLeftProps={headerLeftProps} headerRightProps={headerRightProps} title={headerTitle} />
  }

  _addVolumeDiscount = () => {
    const { mode } = this.state
    const { subscription, navigation, selectedStore } = this.props
    const params = xUtil.getNavParams(this.props)
    if (!params) {
      return
    }

    const canUseVolumeDiscount = xUtil.canUseVolumeDiscount(subscription)
    if (!canUseVolumeDiscount) {
      p.op.showConfirmationOkOnly(p.op.t('Subscription.warning.insufficientTypeTitle'), p.op.t('Subscription.warning.insufficientTypeMsg'))
      return
    }

    let selectedModeStore = selectedStore
    if (mode === PULL_MODE) {
      if (params.productMode === 'EDIT_SELF') {
        selectedModeStore = selectedStore
      }
    }

    const vdQuota = subscription.has(`vd_quota`) ? subscription.get(`vd_quota`) : null
    if (_.isNil(vdQuota) || vdQuota === 0) {
      p.op.alert(p.op.t('Subscription.warning.packageFull'), p.op.t('Subscription.warning.insufficientTypeMsg'))
      // p.op.showConfirmationOkOnly(`เกิดข้อผิดพลาด`, `กรุณาปิดหน้าต่างนี้ แล้วลองใหม่อีกครั้ง (vdq is null)`)
      return
    }
    // const vds = selectedModeStore.has(`vds`) ? selectedModeStore.get(`vds`) : null
    // const vdSize = _.isNil(vds) ? 0 : vds.size
    // if (vdSize >= vdQuota) {
    //   p.op.alert(p.op.t('Subscription.warning.packageFull'), p.op.t('Subscription.warning.insufficientTypeMsg'))
    //   return
    // }

    if (!params.store_id) {
      return
    }

    const { store_id } = params
    navigation.dispatch(
      NavActions.navToVolumeDiscountView({
        store_id,
        volume_discount: null,
        mode: 'CREATE',
      })
    )
  }

  _renderVDList = () => {
    const { selectedStore, editingStore, navigation } = this.props
    const params = xUtil.getNavParams(this.props)
    if (_.isNil(selectedStore)) {
      return null
    }
    if (_.isNil(selectedStore.get('vds'))) {
      return null
    }
    if (_.isEmpty(this.state.mode)) {
      return null
    }

    let selectedModeStore = selectedStore
    if (this.state.mode === PULL_MODE) {
      if (params.productMode === 'EDIT_SELF') {
        selectedModeStore = selectedStore
      }
    }
    // console.log('////////////////////////////////////////')
    // console.log(selectedModeStore.get('vds').toJS())
    // const VolumeDiscount = selectedModeStore.get('vds').toJS()
    // console.log(selectedModeStore.get('vds').size)
    if (selectedModeStore.get('vds').size === 0) {
      return (
        <XCard>
          <XCard.Body alignItems='center' justifyContent='center'>
            <XText variant='inactive' textAlign='center' verticalAlign='center'>
              ไม่พบส่วนลดขายส่ง {'\n'} คุณสามารถสร้างสินค้าได้จากปุ่ม (+) ด้านบน
            </XText>
          </XCard.Body>
        </XCard>
      )
    }
    return selectedModeStore
      .get('vds')
      .toJS()
      .map((vd, idx) => (
        <VolumeDiscountGroup
          key={idx}
          index={idx}
          data={vd}
          navigation={this.props.navigation}
          route={this.props.route}
          pairVolumeDiscount={this.props.pairVolumeDiscount}
          updateDataVolumeDiscountAndProductGroup={this.props.updateDataVolumeDiscountAndProductGroup}
          unpairVolumeDiscount={this.props.unpairVolumeDiscount}
          selectedStore={selectedModeStore}
          updateContInVolumeDiscounts={this.props.updateContInVolumeDiscounts}
          londProductsFromVolumeDiscount={this.props.londProductsFromVolumeDiscount}
          product_group_ids={params.product_group_ids}
        />
      ))
  }

  _handleRefreshVolumeDiscounts = () => {
    this.setState({ isRefreshing: true })
    const store_id = xUtil.getNavParam(this.props, 'store_id')

    this.props.loadListVolumeDiscounts({
      store_id,
      successCallback: res => this.setState({ isRefreshing: false }),
    })
  }

  // _handlePressCopyTextToClipboard = (text: string) => {
  //   if (!text) {
  //     return
  //   }
  //   Clipboard.setString(text)
  //   xUtil.showSuccessToast(`คัดลอก ${text} แล้ว`)
  // }

  render() {
    logRender(this)
    // const { selectedUserGroups, navigation, selectedStore } = this.props
    // const {
    //   editingUserGroups,
    //   isVisibleQRCode = false,
    //   selectedQRCodeUri = null,
    //   selectedInviteCode = null,
    // } = this.state
    // const { state } = navigation

    // if (!List.isList(selectedUserGroups) || !_.isArray(editingUserGroups)) {
    //   return <Spinner color={COLORS.APP_MAIN} />
    // }

    return (
      <XContainer>
        {this.renderCustomHeader()}
        <XContent refreshControl={<RefreshControl refreshing={this.state.isRefreshing} onRefresh={this._handleRefreshVolumeDiscounts} />}>
          <VStack w='full' space='2' px='1' py='2'>
            {this._renderVDList()}
          </VStack>
        </XContent>
      </XContainer>
    )
  }
}

interface VolumeDiscountGroupItemProps extends IXScreenProps {
  index: number
  data: {
    // data คือ ข้อมูลของ VolimeDiscoint 1 ตัว (ตัวที่เลือก)
    name: string
    type: number
    p_c: number
    variants: Array<any>
    id: number
    is_sum_qty_across_products: boolean
  }
  pairVolumeDiscount: any
  updateDataVolumeDiscountAndProductGroup: Function
  unpairVolumeDiscount: any
  selectedStore: any
  updateContInVolumeDiscounts: Function
  londProductsFromVolumeDiscount: Function
  product_group_ids: any
}

class VolumeDiscountGroup extends Component<VolumeDiscountGroupItemProps> {
  _onPressBtnByViewMode = textInBtn => {
    const { data, navigation } = this.props
    const store_id = xUtil.getNavParam(this.props, 'store_id')
    return (
      <XButton
        variant='outline'
        style={{
          width: 50,
          // height: 30,
        }}
        onPress={() =>
          navigation.dispatch(
            NavActions.navToVolumeDiscountView({
              store_id,
              volume_discount: data,
              mode: 'EDIT',
            })
          )
        }>
        {textInBtn}
      </XButton>
    )
  }

  // _checkLastPG = (vd_id, pg_id) => {  // ตรวจสอบ PG ของ PT ว่าเป็นตัวสุดท้ายหรือยังที่ผูกกับ VD
  //   const { params } = this.props.navigation.state
  //   let check
  //   const product_group_ids = params.product_group_ids
  //   const pg_vd_ids = params.pg_vd_ids
  //   console.log(product_group_ids)
  //   console.log(vd_id)
  //   console.log(pg_vd_ids)
  //   pg_vd_ids.map(val => {
  //     if (_.includes(product_group_ids, val.pg_id)) {
  //       if (pg_id === val.pg_id) {
  //         return
  //       } else {

  //       }
  //       return true
  //     } else {
  //       return false
  //     }
  //   })
  // }

  _successCallbackPairVolumeDiscount = (res, params, dataVolumeDiscount) => {
    const pg_id = xUtil.getNavParam(this.props, 'pg_id')
    const resVD_id = res.volume_discount.id

    const newVolumeDiscounts = []
    const newPG_VD_IDS = []
    this.props.selectedStore
      .get('vds')
      .toJS()
      .map(val => {
        if (dataVolumeDiscount.id === val.id) {
          // if (params.productMode === 'EDIT_SELF') {
          // let check = this._checkLastPG(dataVolumeDiscount.id, pg_id)
          // res.volume_discount.p_c = res.volume_discount.p_c + 1
          //   newVolumeDiscounts.push(res.volume_discount)
          // } else {
          newVolumeDiscounts.push(res.volume_discount)
          // }
          // } else if (!_.isNil(oldVolumeDiscountId) && oldVolumeDiscountId === val.id) { // ถ้า vd ที่เลือก เป็น vd ใหม่ ก็ให้ไปลบ p_c อันเดิมออกไปด้วย
          //   if (params.productMode === 'ADD') {
          //     newVolumeDiscounts.push(val)
          //   } else {
          //     // let check = this._checkLastPG(dataVolumeDiscount.id, pg_id)
          //     // val.p_c = val.p_c - 1
          //     newVolumeDiscounts.push(val)
          //   }
        } else {
          newVolumeDiscounts.push(val)
        }
      })
    // console.log(newVolumeDiscounts)
    let check_pg_vd_ids = false
    // this.props.updateContInVolumeDiscounts(newVolumeDiscounts)
    // console.log(params.pg_vd_ids)
    if (_.isEmpty(params.pg_vd_ids)) {
      this.props.updateDataVolumeDiscountAndProductGroup([{ vd_id: resVD_id, pg_id }])
      // p.op.showToast('บันทึกส่วนลดขายส่งเรียบร้อยแล้ว-----', 'success', 2000)
      xUtil.navGoBack(this.props)
      return
    }
    const pg_vd_ids = params.pg_vd_ids.toJS()
    pg_vd_ids.map(val => {
      if (pg_id === val.pg_id) {
        newPG_VD_IDS.push({ vd_id: resVD_id, pg_id })
        check_pg_vd_ids = true
      } else {
        newPG_VD_IDS.push({ vd_id: val.vd_id, pg_id: val.pg_id })
      }
    })

    if (!check_pg_vd_ids) {
      newPG_VD_IDS.push({ vd_id: dataVolumeDiscount.id, pg_id })
    }
    this.props.updateDataVolumeDiscountAndProductGroup(newPG_VD_IDS)
    // p.op.showToast('บันทึกส่วนลดขายส่งเรียบร้อยแล้ว', 'success', 2000)
    xUtil.navGoBack(this.props)
  }

  _successCallbackUnpairVolumeDiscount = (res, params, dataVolumeDiscount) => {
    const pg_id = xUtil.getNavParam(this.props, 'pg_id')
    const resVD_id = res.volume_discount.id
    const newVolumeDiscounts = []
    const newPG_VD_IDS = []
    this.props.selectedStore
      .get('vds')
      .toJS()
      .map(val => {
        if (dataVolumeDiscount.id === val.id) {
          newVolumeDiscounts.push(res.volume_discount)
        } else {
          newVolumeDiscounts.push(val)
        }
      })
    this.props.updateContInVolumeDiscounts(newVolumeDiscounts)
    if (_.isNil(params.pg_vd_ids)) {
      this.props.updateDataVolumeDiscountAndProductGroup([{ vd_id: resVD_id, pg_id }])
      p.op.showToast('ยกเลิกส่วนลดขายส่งเรียบร้อยแล้ว', 'success', 2000)
      xUtil.navGoBack(this.props)
      return
    }
    const pg_vd_idsJS = params.pg_vd_ids.toJS()
    pg_vd_idsJS.map(val => {
      if (pg_id === val.pg_id) {
      } else {
        newPG_VD_IDS.push({ vd_id: val.vd_id, pg_id: val.pg_id })
      }
    })

    this.props.updateDataVolumeDiscountAndProductGroup(newPG_VD_IDS)
    // p.op.showToast('ยกเลิกส่วนลดขายส่งเรียบร้อยแล้ว', 'success', 2000)
    xUtil.navGoBack(this.props)
  }

  _onPressBtnByPullMode = (textInBtn, VDData) => {
    const { data, navigation } = this.props
    const params = xUtil.getNavParams(this.props)
    const TextOnUse = '(ใช้งานอยู่)'
    // console.log(params)
    // console.log(oldVolumeDiscountId)
    // console.log(data)
    let onUse = false
    if (data.id === oldVolumeDiscountId) {
      onUse = true
      textInBtn = 'ยกเลิก'
    }
    return (
      <View style={{ flexDirection: 'row' }}>
        {onUse ? (
          <XText
            style={[
              // @ts-ignore
              // STYLES.BTN_TEXT_MODE_ACTIVE_SECONDARY,
              {
                fontSize: STYLES.FONT_SIZE_NORMAL,
                color: COLORS.APP_MAIN,
                paddingTop: 7,
                paddingLeft: 20,
              },
            ]}>
            {TextOnUse}
          </XText>
        ) : null}
        <XButton
          variant='outline'
          style={{
            width: 50,
            height: 30,
          }}
          onPress={() =>
            onUse
              ? p.op.showConfirmation(
                  null,
                  `คุณต้องการยกเลิกส่วนลดขายส่ง ${VDData.name} นี้หรือไม่ ?`,
                  () => {
                    // console.log('************************* ' + params.productMode)
                    // if (params.productMode === 'ADD') {
                    const res = {
                      volume_discount: VDData,
                    }
                    this._successCallbackUnpairVolumeDiscount(res, params, data)
                    // } else {
                    //   this.props.unpairVolumeDiscount({
                    //     body: {
                    //       store_id,
                    //       vd_id: VDData.id,
                    //       pt_id: params.pt_id,
                    //       pg_id: params.pg_id
                    //     },
                    //     successCallback: (res) => this._successCallbackUnpairVolumeDiscount(res, params, data),
                    //     // failedCallback: (err) => {},
                    //   })
                    // }
                  },
                  () => {},
                  'ตกลง',
                  'ยกเลิก'
                )
              : p.op.showConfirmation(
                  null,
                  `คุณต้องการเลือกส่วนลดขายส่ง ${VDData.name} นี้หรือไม่ ?`,
                  () => {
                    // console.log('************************* ' + params.productMode)
                    // console.log(store_id)
                    // console.log(VDData.id)
                    // console.log(params.pt_id)
                    // console.log(params.pg_id)
                    // if (params.productMode === 'ADD') {
                    const res = {
                      volume_discount: VDData,
                    }
                    this._successCallbackPairVolumeDiscount(res, params, data)
                    // } else {
                    // this.props.pairVolumeDiscount({
                    //   body: {
                    //     store_id,
                    //     vd_id: VDData.id,
                    //     pt_id: params.pt_id,
                    //     pg_id: params.pg_id
                    //   },
                    //   // successCallback: (res) => this._successCallbackPairVolumeDiscount(res, params, data),
                    //   // failedCallback: (err) => {},
                    // })
                    // }
                  },
                  () => {},
                  'ตกลง',
                  'ยกเลิก'
                )
          }>
          {textInBtn}
        </XButton>
      </View>
    )
  }

  _navToProductsList = () => {
    const { data, navigation } = this.props
    const params = xUtil.getNavParams(this.props)
    const { store_id } = params
    // this.props.londProductsFromVolumeDiscount({
    //   body: {
    //     store_id,
    //     offset: 0,
    //     c: 1,
    //     limit: 10,
    //     sortBy: 'updated_at',
    //     sortType: 'desc',
    //     vd_id: data.id,
    //   },
    //   successCallback: (res) =>
    //     dispatch(
    //       NavActions.navToProductListVolumeDiscount({
    //         store_id,
    //         volumeDiscountID: data.id,
    //       })
    //     ),
    //   // failedCallback: (err) => {},
    // })
    navigation.dispatch(NavActions.navToProductListViewMyStoreWithVolumeDiscount({ store_id, vd_id: data.id }))
  }

  _renderVolumeDiscountListProduct = (productModeStatus, p_c) => {
    if (productModeStatus) {
      return <XText variant='inactive'>{`กำลังใช้อยู่กับ ${p_c} สินค้า`}</XText>
    }
    return (
      <HStack space='1' alignItems='center'>
        <XText style={{ color: COLORS.TEXT_INACTIVE, fontSize: STYLES.FONT_SIZE_SMALLER }}>{'กำลังใช้อยู่กับ '}</XText>
        <XButton
          variant='outline'
          // style={{
          //   padding: 3,
          //   borderWidth: 1,
          //   borderRadius: 7,
          //   marginRight: 0,
          //   marginLeft: 0,
          //   paddingLeft: 0,
          //   width: 80,
          //   height: 25,
          //   backgroundColor: COLORS.BG_LIGHT_GREY,
          //   borderColor: COLORS.FORM_PRIMARY,
          // }}
          onPress={() => {
            this._navToProductsList()
          }}>
          {/* <XText
            style={[
              // STYLES.BTN_TEXT_MODE_ACTIVE_SECONDARY,
              {
                fontSize: STYLES.FONT_SIZE_NORMAL,
                color: COLORS.APP_MAIN,
                // paddingTop: 0,
              },
            ]}> */}
          {`${p_c} สินค้า`}
          {/* </XText> */}
        </XButton>
      </HStack>
    )
  }

  render() {
    const { data } = this.props
    const params = xUtil.getNavParams(this.props)
    const { store_id, mode } = params
    const vds = fromJS(data.variants)
    const edit = 'แก้ไข'
    const select = 'เลือก'
    // console.log('params => ', params)
    let textInBtn
    if (mode === VIEW_MODE) {
      textInBtn = edit
    } else {
      textInBtn = select
    }
    let usePrefixOrSuffix
    const prefix = '฿'
    const suffix = '%'
    if (data.type === 1) {
      usePrefixOrSuffix = prefix
    } else {
      usePrefixOrSuffix = suffix
    }
    const { productMode } = params
    let productModeStatus = false
    if (!_.isNil(productMode)) {
      if (productMode === 'EDIT_SELF' || productMode === 'PULL_PRODUCT') {
        productModeStatus = true
      }
    }
    const crossProduct = data.is_sum_qty_across_products ? data.is_sum_qty_across_products : false
    const showEditByn = APP_CONFIG.volume_discount.show_edit_btn
    return (
      <XCard w='full'>
        <XCard.Body style={{ backgroundColor: COLORS.APP_SECONDARY }}>
          <HStack flex={1} alignItems='center' justifyContent='space-between'>
            <XText>{data.name}</XText>
            {showEditByn ? (
              <Box>{textInBtn === edit ? this._onPressBtnByViewMode(textInBtn) : this._onPressBtnByPullMode(textInBtn, data)}</Box>
            ) : null}
          </HStack>
        </XCard.Body>

        <XCard.Body>
          <HStack flex={1} alignItems='center' justifyContent='space-between'>
            {crossProduct ? (
              <XButton
                variant='ghost'
                leftIcon={<XIcon inactive family='FontAwesome' name={crossProduct ? 'check-square-o' : 'square-o'} />}
                // style={{ width: 160, height: 35, flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 3 }}
                disabled>
                <XText style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE }}>คำนวณข้ามรายการสินค้า</XText>
              </XButton>
            ) : (
              <Box />
            )}
            {data.p_c === 0 ? (
              <XText variant='inactive'>ไม่มีสินค้าใดที่ใช้ส่วนลดนี้</XText>
            ) : (
              this._renderVolumeDiscountListProduct(productModeStatus, data.p_c)
            )}
          </HStack>
        </XCard.Body>

        <XCard.Body>
          <RangeAdjuster
            mode='free'
            disabled
            metadata={[
              {
                // key: discountKey,
                prefix,
                suffix,
                usePrefixOrSuffix,
                key: 'val',
                label: data.type === 1 ? 'ส่วนลด (บาทต่อชิ้น)' : 'ส่วนลด (%)',
                span: 2,
                defaultValue: '',
                keyboardType: 'numeric',
              },
            ]}
            data={vds}
            labelAddButton='เพิ่มช่วงส่วนลด'
            labelLastEnd='ขึ้นไป'
          />
        </XCard.Body>
      </XCard>
    )
  }
}
