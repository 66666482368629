import React, { PureComponent } from 'react'
// import {COLORS, STYLES} from '../config/styles'
// import { log, setStatePromise } from 'x/utils/util'

import { StatusBar, StatusBarProps, Platform } from 'react-native'

// const STATUS_BAR_BG_COLOR = 'grey'
const STATUS_BAR_BG_COLOR = '#fff'
const STATUS_BAR_TEXT_STYLE = 'dark-content'

export default class XStatusBar extends PureComponent<StatusBarProps> {
  static displayName = 'XStatusBar'

  render() {
    if (Platform.OS === 'web') {
      return null
    }
    return (
      <StatusBar
        hidden={false}
        showHideTransition='slide'
        translucent={false}
        backgroundColor={STATUS_BAR_BG_COLOR}
        barStyle={STATUS_BAR_TEXT_STYLE}
        {...this.props}
      />
    )
  }
}
