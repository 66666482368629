import _ from 'lodash'
import {
  IAnyObject,
  IApiOptions,
  IPaymentAccount,
  IVerifySlip,
  IVerifySlipCreditHistory,
  IVerifySlipCreditHistoryRequestBody,
  IVerifySlipCreditHistoryResponse,
  IVerifySlipCreditSummary,
  VerifySlipCreditTrialResponse,
} from 'x/types'
// import CONS from 'x/config/constants'
import * as util from 'x/utils/util'
import api from './api'

type IVerifySlipRequestBody = {
  store_id: number
  img_url: string
}

export async function fetchVerifySlip(params: IVerifySlipRequestBody): Promise<IVerifySlip> {
  const { store_id, img_url } = params

  if (!store_id) {
    throw new Error('fetchVerifySlip:: Need store_id')
  }

  if (!img_url) {
    throw new Error('fetchVerifySlip:: Need img_url')
  }

  const reqBody: IAnyObject = {
    store_id,
    img_url,
  }

  const apiOptions: IApiOptions = {
    messages: {
      successMsg: 'ตรวจสลิปอัตโนมัติเสร็จสิ้น',
      errorMsg: 'ตรวจสลิปอัตโนมัติล้มเหลว',
    },
    axiosOptions: {
      timeout: 60000,
    },
    showSpinner: true,
    isApiV2: true,
  }

  try {
    console.log('In fetchVerifySlip:: reqBody => ', reqBody)
    const res = await api.post<any, IVerifySlip>('verify_slip', reqBody, apiOptions)
    console.log('In fetchVerifySlip:: response => ', res)
    // if (!res.product) {
    //   throw new Error('fetchVerifySlip:: No "product" in response')
    // }

    return res
  } catch (err) {
    console.log('In fetchVerifySlip:: err => ', err)
    throw err
  }
}

type IReVerifySlipRequestBody = {
  store_id: number
  payment_id: number
}

type IReVerifySlipResponse = {
  status: 'ok'
}

export async function fetchReVerifySlip(params: IReVerifySlipRequestBody): Promise<boolean> {
  const { store_id, payment_id } = params

  if (!store_id) {
    throw new Error('fetchReVerifySlip:: Need store_id')
  }

  if (!payment_id) {
    throw new Error('fetchReVerifySlip:: Need payment_id')
  }

  const reqBody: IReVerifySlipRequestBody = {
    store_id,
    payment_id,
  }

  const apiOptions: IApiOptions = {
    messages: {
      successMsg: 'ตรวจสลิปอัตโนมัติเสร็จสิ้น',
      errorMsg: 'ตรวจสลิปอัตโนมัติล้มเหลว',
    },
    axiosOptions: {
      timeout: 60000,
    },
    // showSpinner: true,
    isApiV2: true,
  }

  try {
    console.log('In fetchReVerifySlip:: reqBody => ', reqBody)
    const res = await api.post<IReVerifySlipRequestBody, IReVerifySlipResponse>('verify_slip/reverify', reqBody, apiOptions)

    console.log('In fetchReVerifySlip:: response => ', res)
    if (res.status === 'ok') {
      return true
    }
  } catch (err) {
    console.log('In fetchReVerifySlip:: err => ', err)
    throw err
  }

  return false
}

type IVerifySlipCreditSummaryRequestBody = {
  store_id: number
  show_remaining_credits?: boolean
  show_unusable_credits?: boolean
  offset?: number
  limit?: number
}

export async function fetchVerifySlipCreditSummary(params: IVerifySlipCreditSummaryRequestBody): Promise<IVerifySlipCreditSummary> {
  const { store_id, show_remaining_credits, show_unusable_credits, offset, limit } = params

  if (!store_id) {
    throw new Error('fetchVerifySlip:: Need store_id')
  }

  const reqBody: IVerifySlipCreditSummaryRequestBody = {
    store_id,
  }

  if (show_remaining_credits) {
    reqBody.show_remaining_credits = true
  }

  if (show_unusable_credits) {
    reqBody.show_unusable_credits = true
  }

  if (_.isNumber(offset)) {
    reqBody.offset = offset
  }

  if (_.isNumber(limit)) {
    reqBody.limit = limit
  }

  const apiOptions: IApiOptions = {
    // messages: {
    //   successMsg: 'การสร้างสินค้าสำเร็จ',
    //   errorMsg: 'การสร้างสินค้าล้มเหลว',
    // },
    axiosOptions: {
      timeout: 60000,
    },
    showSpinner: true,
    isApiV2: true,
  }

  try {
    // console.log('In fetchVerifySlipCreditSummary:: reqBody => ', reqBody)
    const res = await api.post<IVerifySlipCreditSummaryRequestBody, IVerifySlipCreditSummary>(
      'verify_slip/credit/summary',
      reqBody,
      apiOptions
    )
    // console.log('In fetchVerifySlipCreditSummary:: response => ', res)
    // if (!res.product) {
    //   throw new Error('fetchVerifySlipCreditSummary:: No "product" in response')
    // }

    return res
  } catch (err) {
    console.log('In fetchVerifySlipCreditSummary:: err => ', err)
    throw err
  }
}

export async function fetchVerifySlipCreditHistory(params: IVerifySlipCreditHistoryRequestBody): Promise<IVerifySlipCreditHistory[]> {
  const { store_id, offset, limit, verify_slip_credit_id } = params

  if (!store_id) {
    throw new Error('fetchVerifySlipCreditHistory:: Need store_id')
  }

  const reqBody: IVerifySlipCreditHistoryRequestBody = {
    store_id,
    // action_ids: [12, 21],
  }

  if (_.isNumber(offset)) {
    reqBody.offset = offset
  }

  if (_.isNumber(limit)) {
    reqBody.limit = limit
  }

  if (verify_slip_credit_id) {
    reqBody.verify_slip_credit_id = verify_slip_credit_id
  }

  const apiOptions: IApiOptions = {
    // messages: {
    //   successMsg: 'การสร้างสินค้าสำเร็จ',
    //   errorMsg: 'การสร้างสินค้าล้มเหลว',
    // },
    axiosOptions: {
      timeout: 60000,
    },
    showSpinner: true,
    isApiV2: true,
  }

  try {
    console.log('In fetchVerifySlipCreditHistory:: reqBody => ', reqBody)
    const res = await api.post<IVerifySlipCreditHistoryRequestBody, IVerifySlipCreditHistoryResponse>(
      'verify_slip/credit/history',
      reqBody,
      apiOptions
    )
    console.log('In fetchVerifySlipCreditHistory:: response => ', res)
    if (!res.verify_slip_credit_histories) {
      throw new Error('fetchVerifySlipCreditHistory:: No "verify_slip_credit_histories" in response')
    }

    return res.verify_slip_credit_histories
  } catch (err) {
    console.log('In fetchVerifySlipCreditHistory:: err => ', err)
    throw err
  }
}

type IVerifySlipDetailRequestBody = {
  store_id: number
  verify_slip_id: number
}

export async function fetchVerifySlipDetail(params: IVerifySlipDetailRequestBody): Promise<IVerifySlip> {
  const { store_id, verify_slip_id } = params

  if (!store_id) {
    throw new Error('fetchVerifySlipDetail:: Need store_id')
  }

  if (!verify_slip_id) {
    throw new Error('fetchVerifySlipDetail:: Need verify_slip_id')
  }

  const reqBody: IVerifySlipDetailRequestBody = {
    store_id,
    verify_slip_id,
  }

  const apiOptions: IApiOptions = {
    // messages: {
    //   successMsg: 'การสร้างสินค้าสำเร็จ',
    //   errorMsg: 'การสร้างสินค้าล้มเหลว',
    // },
    axiosOptions: {
      timeout: 60000,
    },
    showSpinner: true,
    isApiV2: true,
  }

  try {
    console.log('In fetchVerifySlipDetail:: reqBody => ', reqBody)
    const res = await api.post<IVerifySlipDetailRequestBody, IVerifySlip>('verify_slip/get', reqBody, apiOptions)

    console.log('In fetchVerifySlipDetail:: response => ', res)
    if (!res.verify_slip_id) {
      throw new Error('fetchVerifySlipDetail:: No "verify_slip_id" in response')
    }

    return res
  } catch (err) {
    console.log('In fetchVerifySlipDetail:: err => ', err)
    throw err
  }
}

type IPaymentAccountsRequestBody = {
  store_id: number
}

type IPaymentAccountsResponse = {
  payment_accounts: IPaymentAccount[]
}

export async function fetchPaymentAccounts(params: IPaymentAccountsRequestBody): Promise<IPaymentAccount[]> {
  // console.log('Just in payments_ts.fetchPaymentAccounts')
  const { store_id } = params
  if (!store_id) {
    throw new Error('fetchPaymentAccounts:: Need store_id')
  }

  const apiOptions: IApiOptions = {
    axiosOptions: {
      timeout: 60000,
    },
    // showSpinner: true,
    // // @ts-ignore :: goback แบบไม่สนใน error object
    // // pKeng on 18 Oct 2023, Fixing weird bug by doing the 'null' check on "this"
    // // as "this" will be to "null" if running on prodstagingapp.xselly.com
    // // or app.xselly.com, but NOT on local dev machine nor app
    // // More info at https://app.clickup.com/t/86ctrug8e
    // onUserCloseAlert: this ? this.goBack : null,
  }

  const reqBody: IPaymentAccountsRequestBody = {
    store_id,
  }

  try {
    // console.log('Before post:: reqBody => ', reqBody)
    const res = await api.post<IPaymentAccountsRequestBody, IPaymentAccountsResponse>(api.POST_BANKACCOUNTS, reqBody, apiOptions)
    // console.log('After post:: res => ', res)

    if (!res.payment_accounts || !_.isArray(res.payment_accounts)) {
      throw new Error('fetchPaymentAccounts:: No "payment_accounts" in response')
    }
    const paymentAccountsBySort = util.sortPaymentAccounts(res.payment_accounts)
    return paymentAccountsBySort
    // const pas = [...res.payment_accounts]

    // // sort by name asc
    // pas.sort((a, b) => {
    //   if (a.name < b.name) {
    //     return -1
    //   }
    //   if (a.name > b.name) {
    //     return 1
    //   }
    //   return 0
    // })

    // //  sort by bank_id asc
    // pas.sort((a, b) => a.bank_id - b.bank_id)

    // // priority verify slip sort
    // // https://stackoverflow.com/questions/17387435/javascript-sort-array-of-objects-by-a-boolean-property
    // pas.sort((a, b) => {
    //   if (a.is_for_verify_slip === b.is_for_verify_slip) {
    //     return 0
    //   }
    //   // true values first
    //   return a.is_for_verify_slip ? -1 : 1
    //   // false values first
    //   // return (a === b)? 0 : a? 1 : -1;
    // })
  } catch (err) {
    console.log('err => ', err)
    throw err
  }
}

type IVerifySlipCreditTrialRequestBody = {
  store_id: number
}

export async function fetchVerifySlipCreditTrial(params: IVerifySlipCreditTrialRequestBody): Promise<VerifySlipCreditTrialResponse> {
  const { store_id } = params
  if (!store_id) {
    throw new Error('fetchVerifySlipCreditTrial:: Need store_id')
  }

  const apiOptions: IApiOptions = {
    showSpinner: true,
    messages: {
      // successMsg: 'รับเครดิตฟรี 1000 เครดิตแล้ว\nคุณสามารถลองใช้งานได้ทันที',
      // errorMsg: 'การรับเครดิตฟรีล้มเหลว กรุณาลองกดใหม่อีกครั้ง',
      successMsg: 'เปิดการทดลองใช้สำเร็จ',
      errorMsg: '"ปิดการทดลองใช้ล้มเหลว กรุณาลองกดใหม่อีกครั้ง',
    },
    axiosOptions: {
      timeout: 60000,
    },
    isApiV2: true,
  }

  const reqBody: IVerifySlipCreditTrialRequestBody = {
    store_id,
  }

  try {
    const res = await api.post<IVerifySlipCreditTrialRequestBody, VerifySlipCreditTrialResponse>(
      'verify_slip/credit/campaign/trial',
      reqBody,
      apiOptions
    )

    if (!res.id) {
      throw new Error('fetchVerifySlipCreditTrial:: No "id" in response')
    }

    return res
  } catch (err) {
    console.log('err => ', err)
    throw err
  }
}
