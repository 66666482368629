import React from 'react'

import _ from 'lodash'

import * as util from 'x/utils/util'

import p from 'x/config/platform-specific'

import xCONS from 'x/config/constants'
import { Map } from 'immutable'
import { ICustomOrderView, IXScreenProps } from 'x/types'

export interface Props extends IXScreenProps {
  editingOrder: any
  selectedStore: any
  customOrderView: Function

  addressLists: Map<string, any>
  loadAddress: Function
  findAddresses: Function
  addCustomOrder: Function

  editingCustomOrderView: any
  selectedCustomOrderView: any
}

interface State {
  // currentRef?: any
  mode: string
  customOrderViewData: ICustomOrderView
  store_id: number

  onChangeData: boolean
}

const { ORDER_VIEW_MODE, PERM_STORE_HELPER, STORE_SETTINGS } = xCONS
const { CREATE, VIEW_EDIT, VIEW_ONLY, EDIT } = ORDER_VIEW_MODE

export default class BaseCustomOrderView extends React.Component<Props, State> {
  static displayName = 'BaseCustomOrderView'

  OPTIONS_CHOOSE_HIDE_AND_SHOW: string[]

  inputRefs: any[]

  editingSenderName: boolean

  shippingTypeItems: { [key: string]: any }

  channelItems: { [key: string]: any }

  inProcess: boolean

  constructor(props) {
    super(props)
    this.state = {
      mode: CREATE,
      customOrderViewData: this.props.editingCustomOrderView.toJS(),
      store_id: null,
      onChangeData: false,
    }

    this.OPTIONS_CHOOSE_HIDE_AND_SHOW = ['ซ่อน', 'แสดง']
    this.inProcess = false
  }

  componentDidMount() {
    // await this._setConfig()
    const { navigation } = this.props
    // const { store_id } = navigation.state.params
    const store_id = util.getNavParam(this.props, 'store_id')
    this.setState({ store_id })
    // navigation.setParams({
    //   saveBtn: this._saveBtn,
    //   // onChangeData: false,
    // })
  }

  // _setConfig = async () => {
  //   const { navigation } = this.props
  //   const { customOrderViewData } = this.state
  //   // console.log(customOrderViewData)
  //   const store_id = navigation.state.params.store_id

  //   xUtil.setStatePromise(this, {
  //     store_id,
  //   })
  // }

  _saveBtn = () => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    const { customOrderView, addCustomOrder, navigation } = this.props
    const { customOrderViewData, store_id } = this.state
    // const callBack = navigation.state.params.callBackFromCustomOrder
    const callBack = util.getNavParam(this.props, 'callBackFromCustomOrder')
    const newCustomCreateOrderData = customOrderViewData
    addCustomOrder({
      body: {
        store_id,
        pref_ui_order_view: newCustomCreateOrderData,
      },
      successCallback: () => {
        customOrderView(newCustomCreateOrderData)
        if (_.isFunction(callBack)) {
          callBack(newCustomCreateOrderData)
        }
        this.inProcess = false
        this._onChangeDataToFalse()
        p.op.showToast('บันทึกตั้งค่าเรียบร้อยแล้ว', 'success')
        const { navigation } = this.props
        const { goBack } = navigation
        goBack()
      },
      failedCallback: () => {
        this.inProcess = false
        p.op.showToast('เกิดข้อผิดพลาด กรุณาทำรายการใหม่ภายหลัง', 'warning')
      },
    })
  }

  _onChangeDataToFalse = async () => {
    await util.setStatePromise(this, {
      onChangeData: false,
    })
  }

  _onChangeData = async () => {
    await util.setStatePromise(this, {
      onChangeData: true,
    })
  }

  // Reciever
  _onSegmentChangeChooseShowAndHideRecieverAdresses = (number: number) => {
    const { customOrderViewData } = this.state
    if (customOrderViewData.receiver.v === number) {
      return
    }
    const newCustomCreateOrderData = customOrderViewData
    newCustomCreateOrderData.receiver.v = number
    this.setState({ customOrderViewData: newCustomCreateOrderData })
    this._onChangeData()
  }

  // Sender
  _onSegmentChangeChooseShowAndHideSenderAdresses = (number: number) => {
    const { customOrderViewData } = this.state
    const newCustomCreateOrderData = customOrderViewData
    if (_.has(customOrderViewData, 'sender')) {
      if (customOrderViewData.sender.v === number) {
        return
      }
    } else {
      const sender = {
        v: 1,
      }
      newCustomCreateOrderData.sender = sender
    }

    newCustomCreateOrderData.sender.v = number
    this.setState({ customOrderViewData: newCustomCreateOrderData })
    this._onChangeData()
  }

  // Link Order
  _onSegmentChangeChooseShowLinkOrder = (number: number) => {
    const { customOrderViewData } = this.state
    if (customOrderViewData.link_order.v === number) {
      return
    }
    const newCustomCreateOrderData = customOrderViewData
    newCustomCreateOrderData.link_order.v = number
    this.setState({ customOrderViewData: newCustomCreateOrderData })
    this._onChangeData()
  }

  // showPriceAndTatal
  _onSegmentChangeChooseshowPriceAndTatalTitle = (number: number) => {
    const { customOrderViewData } = this.state
    if (customOrderViewData.show_price_and_transaction_cost.v === number) {
      return
    }
    const newCustomCreateOrderData = customOrderViewData
    newCustomCreateOrderData.show_price_and_transaction_cost.v = number
    this.setState({ customOrderViewData: newCustomCreateOrderData })
    this._onChangeData()
  }

  _onSegmentChangeChooseshowPriceAndTatalSwitch = (value: boolean) => {
    const { customOrderViewData } = this.state
    if (customOrderViewData.show_price_and_transaction_cost.d === value) {
      return
    }
    const newCustomCreateOrderData = customOrderViewData
    newCustomCreateOrderData.show_price_and_transaction_cost.d = value
    this.setState({ customOrderViewData: newCustomCreateOrderData })
    this._onChangeData()
  }

  // Notes
  _onSegmentChangeChooseshowNote03 = (number: number) => {
    const { customOrderViewData } = this.state
    if (customOrderViewData.notes.n_03.v === number) {
      return
    }
    const newCustomCreateOrderData = customOrderViewData
    newCustomCreateOrderData.notes.n_03.v = number
    this.setState({ customOrderViewData: newCustomCreateOrderData })
    this._onChangeData()
  }

  _onSegmentChangeChooseshowNote04 = (number: number) => {
    const { customOrderViewData } = this.state
    if (customOrderViewData.notes.n_04.v === number) {
      return
    }
    const newCustomCreateOrderData = customOrderViewData
    newCustomCreateOrderData.notes.n_04.v = number
    this.setState({ customOrderViewData: newCustomCreateOrderData })
    this._onChangeData()
  }

  // Shipping
  _onSegmentChangeChooseshowShiping = (number: number) => {
    const { customOrderViewData } = this.state
    if (customOrderViewData.shipping.v === number) {
      return
    }
    const newCustomCreateOrderData = customOrderViewData
    newCustomCreateOrderData.shipping.v = number
    this.setState({ customOrderViewData: newCustomCreateOrderData })
    this._onChangeData()
  }

  // ship before pay
  _onSegmentChangeChooseshowShipBeforePay = (number: number) => {
    const { customOrderViewData } = this.state
    if (customOrderViewData.ship_before_pay.v === number) {
      return
    }
    const newCustomCreateOrderData = customOrderViewData
    newCustomCreateOrderData.ship_before_pay.v = number
    this.setState({ customOrderViewData: newCustomCreateOrderData })
    this._onChangeData()
  }

  // Shipping Label
  _onSegmentChangeChooseshowNote01 = (number: number) => {
    const { customOrderViewData } = this.state
    if (customOrderViewData.shipping_label.n_1.v === number) {
      return
    }
    const newCustomCreateOrderData = customOrderViewData
    newCustomCreateOrderData.shipping_label.n_1.v = number
    this.setState({ customOrderViewData: newCustomCreateOrderData })
    this._onChangeData()
  }

  _onSegmentChangeChooseshowNote02 = (number: number) => {
    const { customOrderViewData } = this.state
    if (customOrderViewData.shipping_label.n_2.v === number) {
      return
    }
    const newCustomCreateOrderData = customOrderViewData
    newCustomCreateOrderData.shipping_label.n_2.v = number
    this.setState({ customOrderViewData: newCustomCreateOrderData })
    this._onChangeData()
  }

  // SalesChannel
  _onSegmentChangeChooseShowAndHideSalesChannel = (number: number) => {
    const { customOrderViewData } = this.state
    const newCustomCreateOrderData = customOrderViewData
    if (_.has(customOrderViewData, 'sales_channel')) {
      if (customOrderViewData.sales_channel.v === number) {
        return
      }
    } else {
      const salesChannel = {
        v: 1,
      }
      newCustomCreateOrderData.sales_channel = salesChannel
    }

    newCustomCreateOrderData.sales_channel.v = number
    this.setState({ customOrderViewData: newCustomCreateOrderData })
    this._onChangeData()
  }

  _onSegmentChangeChooseShowAndHideAutoComplete = (number: number) => {
    const { customOrderViewData } = this.state
    const newCustomCreateOrderData = customOrderViewData
    if (_.has(customOrderViewData, 'auto_complete')) {
      if (customOrderViewData.auto_complete.v === number) {
        return
      }
    } else {
      const autoComplete = {
        v: 1,
      }
      newCustomCreateOrderData.auto_complete = autoComplete
    }

    newCustomCreateOrderData.auto_complete.v = number
    this.setState({ customOrderViewData: newCustomCreateOrderData })
    this._onChangeData()
  }

  _onSegmentChangeChooseShowAndHideDateDelivery = (number: number) => {
    const { customOrderViewData } = this.state
    const newCustomCreateOrderData = customOrderViewData
    if (_.has(customOrderViewData, 'date_delivery')) {
      if (customOrderViewData.date_delivery.v === number) {
        return
      }
    } else {
      const dateDelivery = {
        v: number,
      }
      newCustomCreateOrderData.date_delivery = dateDelivery
    }

    newCustomCreateOrderData.date_delivery.v = number
    this.setState({ customOrderViewData: newCustomCreateOrderData })
    this._onChangeData()
  }

  _onSegmentChangeChooseShowAndHideAutoCancelOrder = (number: number) => {
    const { customOrderViewData } = this.state
    const newCustomCreateOrderData = customOrderViewData
    if (_.has(customOrderViewData, 'auto_cancel_order')) {
      if (customOrderViewData.auto_cancel_order.v === number) {
        return
      }
    } else {
      const autoCancelOrder = {
        v: number,
      }
      newCustomCreateOrderData.auto_cancel_order = autoCancelOrder
    }

    newCustomCreateOrderData.auto_cancel_order.v = number
    this.setState({ customOrderViewData: newCustomCreateOrderData })
    this._onChangeData()
  }

  _getFocusedOrder = () => {
    const { editingOrder, navigation } = this.props
    const sourceOrder = editingOrder
    const targetIndex = util.getNavParam(this.props, 'orderTabIndex', 0)
    return util.getFocusedOrder(sourceOrder, targetIndex)
  }
}
