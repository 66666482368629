/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/no-unused-state */
import React from 'react'
import { ISelectedStoreMap } from 'x/index'
import * as util from 'x/utils/util'
import p from 'x/config/platform-specific'
import _ from 'lodash'

interface IBaseTextEditorViewProps {
  selectedStore: ISelectedStoreMap
  subscription: any
}

interface IBaseTextEditorViewState {
  textValue: string
}

export default abstract class BaseTextEditorView extends React.Component<IBaseTextEditorViewProps, IBaseTextEditorViewState> {
  // abstract async handleSubmitForm(response: any): Promise<void>
  _editor: any

  constructor(props) {
    super(props)
    this.state = {
      // @ts-ignore
      textValue: null,
    }
    // this.webview = React.createRef()
    this._editor = React.createRef()
  }

  componentDidMount() {
    // @ts-ignore
    const params = util.getNavParams(this.props)
    const { html, index } = params
    // console.log('html => ', html)
    this.setState({
      textValue: html,
    })
  }

  setTextValue = (newTextValue: string) => {
    // console.log('newTextValue => ', newTextValue)
    // console.log('this._editor => ', this._editor)
    // console.log('xxxxxxxx => ', newTextValue.substring(newTextValue.length - 11))
    // if (newTextValue.substring(newTextValue.length - 11) === '<p><br></p>') {
    //   this._editor.current.setSelection(newTextValue.length, newTextValue.length)
    // }
    this.setState({ textValue: newTextValue })
  }

  _saveBtnAction = () => {
    // @ts-ignore
    const params = util.getNavParams(this.props)
    const { callBack, index } = params
    const { textValue } = this.state

    if (_.isFunction(callBack)) {
      callBack(textValue, index)
      // @ts-ignore
      util.navGoBack(this.props)
    }
  }

  _cancelBtnAction = () => {
    p.op.showConfirmation(
      '',
      'ยกเลิกการแก้ไข',
      () => {
        // @ts-ignore
        util.navGoBack(this.props)
      },
      () => null,
      'ตกลง',
      'ปิด'
    )
  }
}
