import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
// import { NavigationActions } from 'react-navigation'
import * as AddressActions from 'x/modules/address/AddressState'
import * as ContactActions from 'x/modules/contact/ContactState'
import ContactView from './ContactView'
// import * as BackParamsState from '../backparams/BackParamsState'

export default connect(
  (state) => ({
    selectedContact: state.getIn(['contacts', 'selectedContact']),
    editingContact: state.getIn(['contacts', 'editingContact']),
    shouldFetch: state.getIn(['contacts', 'shouldFetch']),
  }),
  (dispatch) => ({
    // navigate: bindActionCreators(NavigationActions.navigate, dispatch),
    dispatch,
    // @ts-ignore
    // contactActions: bindActionCreators(ContactActions, dispatch),
    // bpActions: bindActionCreators(BackParamsState, dispatch),
    deleteCustomer: bindActionCreators(ContactActions.deleteCustomer, dispatch),
    deleteAddress: bindActionCreators(AddressActions.deleteAddress, dispatch),

    saveNew: bindActionCreators(AddressActions.saveNew, dispatch),
    saveEditing: bindActionCreators(AddressActions.saveEditing, dispatch),
    editOrgAddress: bindActionCreators(AddressActions.editOrgAddress, dispatch),
    createAddress: bindActionCreators(AddressActions.createOrgAddress, dispatch),
    addCustomer: bindActionCreators(ContactActions.addCustomer, dispatch),
  })
)(ContactView)
