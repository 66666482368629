import React from 'react'

// import p from 'x/config/platform-specific'
import BaseSettingEmail from 'x/modules/setting/BaseSettingEmail'
import XCustomHeader from 'xui/components/XCustomHeader'
// import { Input } from 'react-native-elements'
import { COLORS } from 'x/config/styles'
import XText from 'xui/components/XText'
import XButton from 'xui/components/XButton'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import XCard from 'xui/components/XCard'
import XInput from 'xui/components/XInput'
import HStack from 'xui/components/HStack'
import VStack from 'xui/components/VStack'
import * as util from 'x/utils/util'

export default abstract class BaseUISettingEmailView extends BaseSettingEmail {
  _renderHeader = () => (
    <XCustomHeader
      title='เปลี่ยนอีเมล'
      headerLeftProps={{
        backIcon: true,
        onPressItem: () => util.navGoBack(this.props),
      }}
    />
  )

  _renderEmailLabel = () => {
    const { profile } = this.props
    const email = profile.get('email')
    return (
      <HStack>
        <XText variant='inactive'>อีเมลปัจจุบัน</XText>
        <XText variant='active' pl='2'>
          {email}
        </XText>
      </HStack>
    )
  }

  _renderEmailVerifiedStatus = () => {
    const { profile } = this.props
    // console.log('profile => ', profile.toJS())
    const email_verified = profile.get('email_verified')
    const STATUS_TEXT = email_verified ? (
      <XText pl='2' color={COLORS.FORM_SUCCESS}>
        ยืนยันแล้ว
      </XText>
    ) : (
      <XText pl='2' color={COLORS.FORM_ERROR}>
        ยังไม่ได้ยืนยัน
      </XText>
    )
    return (
      <HStack>
        <XText variant='inactive'>สถานะ</XText>
        {STATUS_TEXT}
      </HStack>
    )
  }

  _renderEmailTextInput = () => {
    const { txtNewEmail } = this.state
    const txtPlaceholder = 'ระบุอีเมลใหม่'
    return (
      <XInput
        ref={this.txtNewEmailRef}
        value={txtNewEmail}
        placeholder={txtPlaceholder}
        placeholderTextColor={COLORS.TEXT_INACTIVE}
        onChangeText={(text: string) => this.onChangeNewEmailText(text)}
        // autoFocus
      />
    )
  }

  _renderBtnChangeEmail = () => (
    <XButton mt='6' onPress={() => this._submitFunction()}>
      <XText color='white'>เปลี่ยนอีเมล</XText>
    </XButton>
  )

  _renderBody = () => (
    <XCard p='2' w='full'>
      <VStack space='2' w='full'>
        {this._renderEmailLabel()}
        {this._renderEmailVerifiedStatus()}
        {this._renderEmailTextInput()}
        {this._renderBtnChangeEmail()}
      </VStack>
    </XCard>
  )

  render() {
    return (
      <XContainer>
        {this._renderHeader()}
        <XContent p='2' w='full'>
          {this._renderBody()}
        </XContent>
      </XContainer>
    )
  }
}
