import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as StoreActions from 'x/modules/store/StoreState'
import XContainer from 'xui/components/XContainer'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContent from 'xui/components/XContent'
import HStack from 'xui/components/HStack'
import Box from 'xui/components/Box'
import NB from 'x/config/nativebase'
import { getSelectedStore, getEditingStore, getSubscription } from 'x/redux/selectors'
import XCard from 'xui/components/XCard'
import XSettingRow from 'xui/components/XSettingRow'
import { ActionApiParams, ISelectedStore, ISelectedStoreMap, IXScreenProps } from 'x/types'
import p from 'x/config/platform-specific'
import XText from 'xui/components/XText'
import XInput from 'xui/components/XInput'
import XButton from 'xui/components/XButton'
import XIcon from 'xui/components/XIcon'
import * as util from 'x/utils/util'

interface IStoreSettingStoreCancelOrderAutomationContainerProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
  submitEditing: ActionApiParams
  // subscription: any
}

const StoreSettingStoreCancelOrderAutomationContainer: React.FC<IStoreSettingStoreCancelOrderAutomationContainerProps> = (props) => {
  const {
    // subscription,
    submitEditing,
    selectedStore,
  } = props
  // const [visibleShippingTypes, setVisibleShippingTypes] = useState<number[]>([])
  useEffect(() => {
    const orderExp = selectedStore.has('order_exp') ? selectedStore.get('order_exp') : 0
    const startSwitchValue = orderExp > 1
    setswitchValue(startSwitchValue)
    setInputTime((orderExp / 60).toString())
  }, [selectedStore])
  const [switchValue, setswitchValue] = useState<boolean>(false)
  const [inputTime, setInputTime] = useState<string>('24')
  const [isEdit, setIsEdit] = useState<boolean>(false)
  // eslint-disable-next-line react/no-unstable-nested-components
  const _renderHeader = () => {
    const _renderHeaderLeft = () => {
      if (isEdit) {
        return (
          <XButton onPress={() => _onSubmit()} variant='ghost'>
            บันทึก
          </XButton>
        )
      }
      return <XButton onPress={() => util.navGoBack(props)} variant='ghost' leftIcon={<XIcon name='arrow-back' family='Ionicons' />} />
    }
    const _renderHeaderRight = () => {
      if (isEdit) {
        return (
          <XButton
            variant='ghost'
            onPress={() => {
              p.op.showConfirmation(
                '',
                'ยกเลิกการแก้ไข',
                () => util.navGoBack(props),
                () => null,
                'ตกลง',
                'แก้ไขต่อ'
              )
            }}>
            ยกเลิก
          </XButton>
        )
      }
      return null
    }
    return (
      <XCustomHeader
        title='ยกเลิกออเดอร์อัตโนมัติเมื่อไม่ชำระ'
        renderHeaderLeft={() => _renderHeaderLeft()}
        renderHeaderRight={() => _renderHeaderRight()}
      />
    )
  }

  const _onSubmit = async () => {
    const store_id = selectedStore.get('id')
    let order_exp = +inputTime * 60
    if (switchValue && inputTime === '0') {
      p.op.showConfirmationOkOnly('', 'กรุณาระบุเวลา')
      return
    }
    if (!switchValue) {
      order_exp = 0
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const response: { store: ISelectedStore } = await new Promise((resolve) => {
      // @ts-ignore
      submitEditing({
        body: { store_id, order_exp },
        successCallback: resolve,
        failedCallback: () => resolve(null),
      })
    })
    if (response.store) {
      setIsEdit(false)
    }

    // console.log('response => ', response)
  }

  const isSwitchOnChange = async (newValue: { isValue: boolean; stateKey: string }) => {
    setIsEdit(true)
    setswitchValue(newValue.isValue)
  }

  const onChangeInputTime = (newTextTime: string) => {
    setIsEdit(true)
    setInputTime(newTextTime)
  }

  const renderSwitch = () => (
    // const s_use_product_barcode: boolean = _getBoolUseUPC()
    <HStack py='1.5'>
      <XSettingRow
        title={p.op.t('StoreSetting.AutoCancelOrderIfNotPaidOnTime')}
        value={switchValue}
        onClick={(newValue: { isValue: boolean; stateKey: string }) => isSwitchOnChange(newValue)}
        stateKey='s_use_product_barcode'
      />
    </HStack>
  )

  const renderSetTime = () => (
    <HStack mx='2.5' mb='2'>
      <Box flex={1} flexDirection='row'>
        <XText variant='active'>เวลา</XText>
        <XText pl='1' variant='inactive'>
          (ชั่วโมง)
        </XText>
      </Box>
      <Box flex={1} alignItems='flex-end'>
        {switchValue ? (
          <XInput value={inputTime} onChangeText={onChangeInputTime} textAlign='right' keyboardType='numeric' />
        ) : (
          <XText variant='inactive'>ไม่มีกำหนด</XText>
        )}
        {switchValue ? (
          <Box flex={1} alignItems='flex-end'>
            <XText variant='inactive'>( {+inputTime * 60} นาที )</XText>
          </Box>
        ) : null}
      </Box>
    </HStack>
  )

  // eslint-disable-next-line react/no-unstable-nested-components
  const _renderBody = () => (
    <XCard mx='2' mt='2' mb='2'>
      {renderSwitch()}
      {renderSetTime()}
    </XCard>
  )

  return (
    <XContainer>
      {_renderHeader()}
      <XContent px='2' pb='2'>
        <Box w='full' mt='2' borderRadius='md' _light={{ bg: 'white' }} _dark={{ bg: NB.C.D.BG.CLEAR }}>
          {_renderBody()}
        </Box>
        {/* {isLoading ? VIEW_LOADING(windowWidth, windowHeight) : null} */}
      </XContent>
    </XContainer>
  )
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// // @ts-ignore
// StoreSettingStoreCancelOrderAutomationContainer.navigationOptions = {
//   header: null,
// }

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
    editingStore: getEditingStore(state),
    subscription: getSubscription(state),
  }),
  (dispatch) => ({
    submitEditing: bindActionCreators(StoreActions.submitEditingStore, dispatch),
    resetEditingStore: bindActionCreators(StoreActions.resetEditingStore, dispatch),
    // storeActions: bindActionCreators(StoreState, dispatch),
  })
)(StoreSettingStoreCancelOrderAutomationContainer)
