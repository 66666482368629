// From https://github.com/ptelad/react-native-iphone-x-helper/blob/master/index.js
import { Dimensions, Platform, StatusBar } from 'react-native'
import _ from 'lodash'

export function isIphoneX(): boolean {
  const d = Dimensions.get('window')
  return (
    Platform.OS === 'ios' &&
    // @ts-ignore Platform function maybe not exist in official index.d.ts
    (_.has(Platform, 'isPad') && !Platform.isPad) &&
    // @ts-ignore Platform function maybe not exist in official index.d.ts
    (_.has(Platform, 'isTVOS') && !Platform.isTVOS) &&
    (d.height === 812 || d.width === 812 || (d.height === 896 || d.width === 896))
  )
}

// export function ifIphoneX(iphoneXStyle, regularStyle) {
export function ifIphoneX<T, U>(iphoneXStyle: T, regularStyle: U): T | U {
  if (isIphoneX()) {
    return iphoneXStyle
  }
  return regularStyle
}

export function getStatusBarHeight(safe?: boolean): number {
  return Platform.select({
    ios: ifIphoneX(safe ? 44 : 30, 20),
    android: StatusBar.currentHeight,
    web: 0,
  })
}

export function getBottomSpace(): number {
  return isIphoneX() ? 34 : 0
}
