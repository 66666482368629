// import { bindActionCreators } from 'redux'

export function getState(state) {
  return {
    // selectedPaymentList: state.getIn(['payment', 'selectedPaymentList']),
  }
}

export function getDispatch(dispatch) {
  return {
    // paymentActions: bindActionCreators(PaymentActions, dispatch),
  }
}
