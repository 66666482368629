import { Component } from 'react'
import _ from 'lodash'
import { Map, List } from 'immutable'
import { ImmutableObject } from 'x/types'
import { log, setStatePromise, deleteImmutableListAtIndex } from '../utils/util'
// import settings from '../config/settings'

// eg metadata =>
// [ { key: price, label: 'ราคา' }, { key: 'discount', label: 'ส่วนลด' }]
export interface IMetadata {
  key: string
  label: string
  prefix?: string
  suffix?: string
  usePrefixOrSuffix?: string
  defaultValue: any
  span?: number // default span of textbox = 1
  inputStyle?: { [key: string]: any } // Text Input custom style
  filteredMethod?: (newValue: any) => any // should return filteredValue
  isMoney?: boolean
  isNumber?: boolean
  isInteger?: boolean

  keyboardType?: string // app-only
}

export interface IRowData {
  min?: number // min (start)
  max?: number // max
  [key: string]: any
}

export type IRowDataMap = ImmutableObject<IRowData>

// step = บังคับเป็นขั้นบันได เช่น 1-2 3-3 4-10 11-ขึ้นไป
// free = ไม่บังคับ range เช่น 1-1 5-5 10-ขึ้นไป
export type RangeAdjusterMode = 'step' | 'free' // defalt is 'step' mode

export interface IBaseRangeAdjusterProps {
  metadata: IMetadata[]
  disabled?: boolean
  defaultStep?: number
  labelAddButton?: string
  labelLastEnd?: string // ใช้ disable last row end
  // newRowMode?: 'default' | 'duplicate'
  onChange?: (data: List<IRowDataMap>) => void
  onChangeMetaField?: (metaKey: string, rowIndex: number, newValue: any, updateMethod: (forceVal?: any) => void) => void
  data?: List<IRowDataMap> // to override state
  // 'empty' will place data = ''
  // 'default' will place data = defaultValue in Metadata
  // 'duplicate' will place data = lastRow
  highlightedRowIndex?: number
  limitRow?: number
  onExceededLimitRow?: () => void

  mode?: RangeAdjusterMode
  isEditableMin?: boolean // ใช้เพื่อเปิด column ด้านซ้ายให้แก้ไขได้
}

export interface IBaseRangeAdjusterState {
  // data: List<IRowDataMap>
  isInitialized: boolean
  containerWidth?: number
}

// const ROW_DATA_TEMPLATE: IRowData = { min: null, max: null }
// const ROW_DATA_TEMPLATE_EMPTY: IRowData = { min: null, max: null }

export default class BaseRangeAdjuster extends Component<IBaseRangeAdjusterProps, IBaseRangeAdjusterState> {
  static displayName = 'BaseRangeAdjuster'
  // static defaultProps = {
  //   defaultStep: 5,
  //   labelAddButton: 'เพิ่ม',
  //   newRowMode: 'empty',
  // }

  isComponentMount: boolean

  constructor(props: IBaseRangeAdjusterProps) {
    super(props)
    this.isComponentMount = false
    this.state = { isInitialized: false }
  }

  resetData = async () => {
    const { data, defaultStep = 5, metadata = [] } = this.props
    if (!List.isList(data) || (List.isList(data) && data.size < 2)) {
      // ถ้า data ไม่ได้เป็น List หรือมี row < 2 ให้ init ใหม่
      // เช่น ถ้า props ส่ง null มาแต่แรกก็ให้ init โครงสร้างขึ้นมาก่อน render
      // let row1
      // let row2
      // if (newRowMode === 'empty') {
      //   row1 = _.cloneDeep(ROW_DATA_TEMPLATE_EMPTY)
      //   row2 = _.cloneDeep(ROW_DATA_TEMPLATE_EMPTY)
      // } else {
      // row1 = _.cloneDeep(ROW_DATA_TEMPLATE)
      // row2 = _.cloneDeep(ROW_DATA_TEMPLATE)
      // }
      // row1.min = 1
      // row1.max = defaultStep
      // row2.min = defaultStep + 1
      // row2.max = row2.min + defaultStep
      const row1 = { min: 1, max: defaultStep }
      const row2 = { min: defaultStep + 1, max: defaultStep * 2 + 1 }
      metadata.forEach((md) => {
        row1[md.key] = md.defaultValue
        row2[md.key] = md.defaultValue
      })
      await this._updateData(List([Map(row1), Map(row2)]))
    }

    await setStatePromise(this, { isInitialized: true })
  }

  componentDidMount() {
    this.isComponentMount = true
    this.resetData()
  }

  _getMode = (): RangeAdjusterMode => {
    const { mode = 'step' } = this.props
    return mode
  }

  // componentDidUpdate(prevProps: BaseRangeAdjusterProps, prevState: I): void {
  //   const { data, onChange } = this.props
  //   const oldStateData = prevState.data
  //   const newStateData = this.state.data
  //
  //
  //   if (oldStateData !== newStateData && _.isFunction(onChange)) {
  //     onChange(data)
  //   }
  //
  //   if (_.has(this.props, 'data') && oldStateData === newStateData) {
  //     const newPropData = this.props.data
  //     if (newPropData !== newStateData) {
  //       this.setState({ data: newPropData })
  //     }
  //   }
  // }

  // componentDidUpdate(prevProps: BaseRangeAdjusterProps, prevState: I): void {
  //   const { data, onChange } = this.props
  //   const oldPropsData = prevProps.data
  //   const newPropsData = this.props.data
  //   const newStateData = this.state.data
  //
  //   if ((_.has(this.props, 'data'))
  //     && ((oldPropsData !== newPropsData) || (!oldPropsData && newPropsData))
  //     && (newStateData !== newPropsData)) {
  //     this.setState({ data: newPropsData })
  //   }
  // }

  getData = (source?: { [key: string]: any; data: List<IRowDataMap> }): List<IRowDataMap> => {
    if (source && source.data) {
      return source.data
    }

    return this.props.data
    // return _.has(this.props, 'data') ? this.props.data : this.state.data
  }

  _updateData = async (newData: List<IRowDataMap>) => {
    this.props.onChange(newData)
    // const { onChange } = this.props
    // if (_.isFunction(onChange)) {
    //   onChange(newData)
    //   await delay(100)
    // } else {
    //   await setStatePromise(this, { data: newData })
    // }
  }

  _isRemovableRow(rowIndex: number): boolean {
    return rowIndex > 1
  }

  // _isDisabledBegin = (rowIndex: number): boolean => {
  //   return rowIndex === 0
  // }

  _isDisabledBegin = (rowIndex: number): boolean => {
    const { disabled = false, mode = 'step', isEditableMin = false } = this.props
    if (disabled) {
      return true
    }

    if (isEditableMin || mode === 'free') {
      return false
    }

    if (rowIndex > 0 && mode === 'step') {
      return true
    }

    return false
  }

  _isDisabledEnd = (rowIndex: number): boolean => {
    const lastIndex = this.getData().size - 1
    return rowIndex === lastIndex && _.isString(this.props.labelLastEnd)
  }

  _addNewRow = async () => {
    // log('In BaseRangeAdjuster _addNewRow')
    const { defaultStep = 5, limitRow, labelLastEnd = null, onExceededLimitRow } = this.props
    const data = this.getData()
    const lastIndex = data.size - 1
    const lastRow = data.get(lastIndex)
    let newData = data
    if (!lastRow) {
      return
    }
    // limitRow :: ถ้าเกินก็จะไม่ให้สร้างแถวต่อไปแล้ว
    if (!_.isNil(limitRow)) {
      if (data.size >= limitRow) {
        if (_.isFunction(onExceededLimitRow)) {
          onExceededLimitRow()
        }
        // p.op.showConfirmationOkOnly('แพ็กเกจของคุณไม่สามารถเพิ่มส่วนลดได้อีก', p.op.t('Subscription.warning.insufficientTypeMsg'))
        return
      }
    }

    // let newRow
    // if (newRowMode === 'default') {
    //   newRow = _.cloneDeep(ROW_DATA_TEMPLATE)
    // } else if (newRowMode === 'duplicate') {
    //   newRow = _.cloneDeep(lastRow)
    // } else {
    //   newRow = _.cloneDeep(ROW_DATA_TEMPLATE_EMPTY)
    // }

    // newRow = _.cloneDeep(lastRow)

    // newRow.get('min') = lastRow.get('end') + 1
    // newRow.get('end') =  newRow.get('min') + defaultStep

    // let endNumber
    // if (_.isNil(lastRow.get('max'))) {
    //   endNumber = lastRow.get('min') + defaultStep
    //   newData = newData.setIn([lastIndex, 'max'], endNumber)
    // } else {
    //   endNumber = lastRow.get('max')
    // }

    // newRow.min = endNumber + 1
    // newRow.max = newRow.min + defaultStep
    // log('In BaseRangeAdjuster _addNewRow newRow => ', newRow)

    // newData = newData.push(fromJS(newRow))

    let endNumber = lastRow.get('max')
    if (_.isNil(endNumber)) {
      endNumber = lastRow.get('min') + defaultStep
      newData = newData.setIn([lastIndex, 'max'], endNumber)
    }
    // @ts-ignore force parse for sure
    endNumber = parseInt(endNumber)
    const newRow: IRowDataMap = Map({
      min: endNumber + 1,
      // ถ้ามีเลข max ของบรรทัดสุดท้ายใช้เป็นคำว่า "ขึ้นไป" จะให้ max เป็น null
      max: _.isString(labelLastEnd) ? null : endNumber + 1 + defaultStep,
    })
    newData = newData.push(newRow)

    // this.setState({ data: newData })
    await this._updateData(newData)
  }

  _deleteRow = async (rowIndex: number) => {
    // log('In BaseRangeAdjuster _deleteRow')
    if (!rowIndex || rowIndex <= 1) {
      return
    }

    const data = this.getData()
    const lastIndex = data.size - 1
    // const lastRow = data.get(lastIndex)
    let newData = data
    if (rowIndex === lastIndex) {
      newData = newData.pop()
    } else {
      const rowBefore = data.get(rowIndex - 1)
      // let newRowAfter = _.cloneDeep(data.get(rowIndex + 1))
      // newRowAfter.get('min') = rowBefore.get('end') + 1
      let newRowAfter = data.get(rowIndex + 1)
      newRowAfter = newRowAfter.set('min', rowBefore.get('max') + 1)
      newData = newData.set(rowIndex + 1, newRowAfter)
      newData = deleteImmutableListAtIndex(newData, rowIndex)
    }

    // this.setState({ data: newData })
    await this._updateData(newData)
  }

  // _onChangeBeginAtRowIndex(rowIndex: number, newValue: string): void {
  //   log('In BaseRangeAdjuster _onChangeBegin rowIndex => ', rowIndex, ' newValue => ', newValue)
  //   // if (rowIndex === 0) {
  //   //   return
  //   // }
  //
  //   const data = this.getData()
  //   let nextValue = parseFloat(newValue)
  //   if ( _.isNaN(nextValue) || _.isNull(nextValue) || _.isUndefined(nextValue) || nextValue === 0) {
  //     if (data.get(rowIndex - 1)) {
  //       nextValue = data.get(rowIndex - 1).get('end') + 1
  //     } else {
  //       nextValue = data.get(rowIndex).get('end') - 1
  //     }
  //   }
  //
  //   const newData = data.setIn([rowIndex, 'min'], nextValue)
  //   this.setState({ data: newData })
  // }

  _onChangeTextAtRowIndex = async (key: string, rowIndex: number, newValue: string) => {
    // log('In BaseRangeAdjuster _onChangeEnd rowIndex => ', rowIndex, ' newValue => ', newValue)
    const newData = this.getData().setIn([rowIndex, key], newValue)
    // this.setState({ data: newData })
    await this._updateData(newData)
  }

  // _getLastEndEditingData = (key: 'min' | 'max', rowIndex: number, newValue: any): List<IRowDataMap> => {
  //   const data = this.getData()
  //   let nextValue = parseInt(newValue)
  //   if (_.isNaN(nextValue) || _.isNull(nextValue) || _.isUndefined(nextValue) || nextValue === 0) {
  //     if (key === 'min') {
  //       if (data.get(rowIndex - 1)) {
  //         nextValue = data.getIn([rowIndex - 1, 'max']) + 1
  //       } else {
  //         // nextValue = 1 // first min must be 1
  //       }
  //     } else {
  //       if (data.get(rowIndex + 1)) {
  //         nextValue = data.getIn([rowIndex + 1, 'min']) - 1
  //       } else {
  //         nextValue = data.getIn([rowIndex, 'min']) + 1
  //       }
  //     }
  //   }

  //   return data.setIn([rowIndex, key], nextValue)
  // }

  // // TODO: สร้าง logic ขึ้นมาเพื่อ auto-compute หลังจาก กรอกข้อมูลเสร็จแล้ว
  // _onFinishEditingBegin(rowIndex: number): void {
  //   const currentBegin = this.getData().getIn([rowIndex, 'min'])
  //   const newData = this.getData().setIn([rowIndex - 1, 'end'], currentBegin - 1)
  //   this.setState({ data: newData })
  // }
  //
  // // TODO: สร้าง logic ขึ้นมาเพื่อ auto-compute หลังจาก กรอกข้อมูลเสร็จแล้ว
  // _onFinishEditingEnd(rowIndex: number): void {
  //   const currentEnd = this.getData().getIn([rowIndex, 'end'])
  //   const newData = this.getData().setIn([rowIndex + 1, 'min'], currentEnd + 1)
  //   this.setState({ data: newData })
  // }

  // _syncEndEditingAroundAtRowIndex(key: 'min'|'end', rowIndex: number, inputData?: List<RowDataMap>): List<RowDataMap> {
  //   let currentData = inputData ? inputData : this.getData()
  //   const lastIndex = this.getData().size - 1
  //   let focusedRow = currentData.get(rowIndex)
  //
  //   if (!focusedRow || !_.includes(['min', 'end'], key)) {
  //     // should be impossible case but safety first
  //     return currentData
  //   }
  //
  //   if (focusedRow.get('min') >= focusedRow.get('end')) {
  //     if (key === 'min' && rowIndex <= lastIndex) {
  //       // focusedRow.get('end') = focusedRow.get('min') + 1
  //       focusedRow = focusedRow.set('end', focusedRow.get('min') + 1)
  //     } else if (key === 'end' && rowIndex > 0) { // key = 'end'
  //       // focusedRow.get('min') = focusedRow.get('end') - 1
  //       focusedRow = focusedRow.set('min', focusedRow.get('end') - 1)
  //     } else if (key === 'end' && rowIndex === 0) {
  //       // focusedRow.get('end') = focusedRow.get('min') + 1
  //       focusedRow = focusedRow.set('end', focusedRow.get('min') + 1)
  //     }
  //     currentData.set(rowIndex, focusedRow)
  //   }
  //
  //   let upperRow = currentData.get(rowIndex - 1) // 0 is top
  //   let lowerRow = currentData.get(rowIndex + 1)
  //
  //   if (upperRow && upperRow.get('end') && (upperRow.get('end') !== focusedRow.get('min') - 1)) {
  //     // upperRow.get('end') = focusedRow.get('min') - 1
  //     upperRow = upperRow.set('end', focusedRow.get('min') - 1)
  //
  //     currentData.set(rowIndex - 1, upperRow)
  //   }
  //
  //   if (lowerRow && lowerRow.get('end') && (lowerRow.get('min') !== focusedRow.get('end') + 1)) {
  //     // lowerRow.get('min') = focusedRow.get('end') + 1
  //     lowerRow = lowerRow.set('min', focusedRow.get('end') + 1)
  //
  //     currentData.set(rowIndex + 1, lowerRow)
  //   }
  //
  //   return currentData
  // }

  // // ยึด min เป็นหลัก
  // _syncUpperRowAtRowIndex(rowIndex, inputData?: List<IRowDataMap>): List<IRowDataMap> {
  //   const newComputeData = inputData ? inputData : this.getData()
  //   const focusedRow = newComputeData.get(rowIndex)
  //   let upperRow = newComputeData.get(rowIndex - 1)

  //   if (!focusedRow || !upperRow) {
  //     return newComputeData
  //   }

  //   if (focusedRow.get('min') !== upperRow.get('max') - 1) {
  //     // upperRow.get('end') = focusedRow.get('min') - 1
  //     upperRow = upperRow.set('max', focusedRow.get('min') - 1)
  //   }

  //   if (upperRow.get('min') >= upperRow.get('max')) {
  //     // upperRow.get('min') = upperRow.get('end') - 1
  //     upperRow = upperRow.set('min', upperRow.get('max') - 1)
  //   }

  //   return newComputeData.set(rowIndex - 1, upperRow)
  // }

  // ยึดค่า max เป็นหลัก แล้ว loop คิดจาก row บนลงล่างเสมอ
  _syncLowerRowAtRowIndex = (rowIndex: number, inputData?: List<IRowDataMap>): List<IRowDataMap> => {
    const { mode = 'step', defaultStep = 5 } = this.props
    const newComputeData = inputData || this.getData()
    const upperRow = newComputeData.get(rowIndex)
    let lowerRow = newComputeData.get(rowIndex + 1)

    if (!upperRow || !lowerRow) {
      return newComputeData
    }
    // const focusMin = parseInt(upperRow.get('min'))
    const upperMax = +upperRow.get('max')
    const expectedLowerMin = upperMax + 1

    if (mode === 'step') {
      // ถ้าเป็นโหมดขั้นบันได จะคิดให้ค่า min ชั้นต่ำกว่า เท่ากับค่า max ชั้นบน +1 เสมอ

      // เช็ค บนขวา - ล่างซ้าย
      if (+lowerRow.get('min') !== expectedLowerMin) {
        lowerRow = lowerRow.set('min', expectedLowerMin)
      }

      // เช็ค ล่างซ้าย - ล่างขวา
      if (+lowerRow.get('min') > +lowerRow.get('max')) {
        lowerRow = lowerRow.set('max', +lowerRow.get('min') + defaultStep)
      }
    } else {
      // ถ้าเป็น free mode คิดแค่ว่าห้ามให้ค่าชั้นล่าง น้่อยกว่าค่าชั้นบนเท่านั้น

      // เช็ค บนขวา - ล่างซ้าย
      if (+lowerRow.get('min') < expectedLowerMin) {
        lowerRow = lowerRow.set('min', expectedLowerMin)
      }

      // เช็ค ล่างซ้าย - ล่างขวา
      if (+lowerRow.get('min') > +lowerRow.get('max')) {
        lowerRow = lowerRow.set('max', +lowerRow.get('min') + defaultStep)
      }
    }

    return newComputeData.set(rowIndex + 1, lowerRow)
  }

  // _syncFocusedRowAtRowIndex(key: 'min' | 'max', rowIndex, inputData?: List<IRowDataMap>): List<IRowDataMap> {
  //   let newComputeData = inputData ? inputData : this.getData()

  //   // Sync with top before sync current row
  //   const upperRow = newComputeData.get(rowIndex - 1)
  //   if (upperRow) {
  //     // if (focusedRow.get('min') !== (upperRow.get('max') + 1)) {
  //     //   focusedRow = focusedRow.set('min', upperRow.get('max') + 1)
  //     // }
  //     newComputeData = this._syncLowerRowAtRowIndex(rowIndex - 1, newComputeData)
  //   }

  //   let focusedRow = newComputeData.get(rowIndex)

  //   if (!focusedRow || !_.includes(['min', 'max'], key)) {
  //     return newComputeData
  //   }

  //   if (focusedRow.get('min') >= focusedRow.get('max')) {
  //     if (key === 'min') {
  //       // focusedRow.get('end') = focusedRow.get('min') + 1
  //       focusedRow = focusedRow.set('max', focusedRow.get('min') + 1)
  //     } else {
  //       // end
  //       // focusedRow.get('min') = focusedRow.get('end') - 1
  //       focusedRow = focusedRow.set('min', focusedRow.get('max') + 1)
  //     }
  //   }

  //   return newComputeData.set(rowIndex, focusedRow)
  // }

  // เป็นจุดเริ่มต้นการเช็คค่าทั้งหมด
  // เมื่อสิ้นสุดการ focus input (onBlur)
  // 1. เช็คปรับค่า min-max บนสุดก่อน
  // 2. หลังจากนั้นค่อยลูปเช็คจากบนลงล่างเทียบทีละแถว
  _loopSyncAllDataAtRowIndex = async (key: 'min' | 'max', rowIndex: number, inputData?: List<IRowDataMap>) => {
    // log('In BaseRangeAdjuster _loopSyncAllDataAtRowIndex key => ', key, ' rowIndex => ', rowIndex)
    if (!this.isComponentMount) {
      return
    }

    const { mode = 'step', defaultStep = 5 } = this.props
    let newComputeData = inputData || this.getData()
    const lastIndex = this.getData().size - 1

    // newComputeData = this._syncEndEditingAroundAtRowIndex(key, rowIndex, newComputeData)
    //
    // const firstUpperRowIndex = rowIndex - 1
    // if (firstUpperRowIndex >= 0 && newComputeData.get(firstUpperRowIndex)) {
    //   for (let i = firstUpperRowIndex; i > 0; i--) {
    //     log('In BaseRangeAdjuster _loopSyncAllDataAtRowIndex key => ', key, ' UpperRowIndex => ', i)
    //     newComputeData = this._syncEndEditingAroundAtRowIndex(key, i, newComputeData)
    //   }
    // }
    //
    // const firstLowerRowIndex = rowIndex + 1
    // if (newComputeData.get(firstLowerRowIndex)) {
    //   for (let j = firstLowerRowIndex; j <= lastIndex; j++) {
    //     log('In BaseRangeAdjuster _loopSyncAllDataAtRowIndex key => ', key, ' LowerRowIndex => ', j)
    //     newComputeData = this._syncEndEditingAroundAtRowIndex(key, j, newComputeData)
    //   }
    // }

    // newComputeData = this._syncFocusedRowAtRowIndex(key, rowIndex, newComputeData)

    // for (let i = rowIndex; i >= 0; i--) {
    //   // log('In BaseRangeAdjuster _syncUpperRowAtRowIndex key => ', key, ' UpperRowIndex => ', i)
    //   newComputeData = this._syncUpperRowAtRowIndex(i, newComputeData)
    // }
    //
    // for (let j = rowIndex; j <= lastIndex; j++) {
    //   // log('In BaseRangeAdjuster _syncLowerRowAtRowIndex key => ', key, ' LowerRowIndex => ', j)
    //   newComputeData = this._syncLowerRowAtRowIndex(j, newComputeData)
    // }

    // last check first col min must be 1
    // if (newComputeData.getIn([0, 'min']) !== 1) {
    // newComputeData = newComputeData.setIn([0, 'min'], 1)
    // const firstMin = +newComputeData.getIn([0, 'min'])
    // if (newComputeData.getIn([0, 'min']) >= newComputeData.getIn([0, 'max'])) {
    //   newComputeData = newComputeData.setIn([0, 'max'], firstMin + 1)
    // }

    // เช็คค่าแถวแรกเท่านั้น
    let firstMin = newComputeData.getIn([0, 'min'])
    // @ts-ignore
    const firstMax = parseInt(newComputeData.getIn([0, 'max']))

    if (firstMin) {
      // @ts-ignore
      firstMin = parseInt(firstMin)
    }
    // บังคับให้ min ตัวแรกมีค่าเป็น + เสมอ
    if (!firstMin || firstMin < 0 || firstMin === '' || _.isNaN(firstMin)) {
      firstMin = 1
      newComputeData = newComputeData.setIn([0, 'min'], 1)
    }

    if (firstMin > firstMax) {
      // ถ้าหาก min ตัวแรกมากกว่า max ให้ set เท่ากันไว้ก่อน
      if (mode === 'step') {
        // โหมดคิดแบบขั้นบันไดให้
        if (key === 'min') {
          // ถ้าแก้ที่ min ให้ max = min+step
          // @ts-ignore
          const newMax = firstMin + defaultStep
          newComputeData = newComputeData.setIn([0, 'max'], newMax)
        } else {
          // ถ้าแก้ที่ max ให้ min = max-1
          let newMin = firstMax - 1
          if (newMin <= 0) {
            newMin = 1
          }
          newComputeData = newComputeData.setIn([0, 'min'], newMin)
        }
      } else {
        // โหมด free ให้ปรับให้เท่ากัน
        if (key === 'min') {
          newComputeData = newComputeData.setIn([0, 'max'], firstMin)
        } else {
          newComputeData = newComputeData.setIn([0, 'min'], firstMax)
        }
      }
    }

    // newComputeData = this._syncFocusedRowAtRowIndex('begin', 0, newComputeData)
    for (let rowI = 0; rowI <= lastIndex; rowI++) {
      // log('In BaseRangeAdjuster FINAL _syncLowerRowAtRowIndex key => ', key, ' k => ', k)
      newComputeData = this._syncLowerRowAtRowIndex(rowI, newComputeData)
    }
    // }

    // this.setState({ data: newComputeData })
    await this._updateData(newComputeData)
  }

  forceUpdatMetaAt = async (metaKey, rowIndex, forceVal: any) => {
    const newData = this.getData().setIn([rowIndex, metaKey], forceVal)
    await this._updateData(newData)
  }

  _onChangeMetaColumn = async (metaKey: string, rowIndex: number, newValue: any) => {
    const { onChangeMetaField } = this.props

    log('_onChangeMetaColumn metaKey => ', metaKey)
    log('_onChangeMetaColumn rowIndex => ', rowIndex)
    log('_onChangeMetaColumn newValue => ', newValue)

    if (_.isFunction(onChangeMetaField)) {
      onChangeMetaField(metaKey, rowIndex, newValue, this.forceUpdatMetaAt)
    } else {
      // this.setState({ data: newData })
      await this.forceUpdatMetaAt(metaKey, rowIndex, newValue)
    }
  }

  _onEndEditingMetaDataAtRowIndex = async (key: string, rowIndex: number) => {
    const foundMeta = this.props.metadata.find((mData) => mData.key === key)
    if (foundMeta && _.isFunction(foundMeta.filteredMethod)) {
      const mValue = this.getData().getIn([rowIndex, key])
      const filteredValue = foundMeta.filteredMethod(mValue)
      await this._onChangeTextAtRowIndex(key, rowIndex, filteredValue)
    }
  }

  onChangeTextMin = (newTextMin: string, rowIndex: number) => {
    this._onChangeTextAtRowIndex('min', rowIndex, newTextMin)
  }

  onChangeTextMax = (newTextMax: string, rowIndex: number) => {
    this._onChangeTextAtRowIndex('max', rowIndex, newTextMax)
  }

  onEndEditingMin = (rowIndex: number) => {
    this._loopSyncAllDataAtRowIndex('min', rowIndex)
  }

  onEndEditingMax = (rowIndex: number) => {
    // const data = this.getData()
    // const rowData = data.get(rowIndex)
    // const newData = this._getLastEndEditingData('max', rowIndex, rowData.get('max'))
    // this._loopSyncAllDataAtRowIndex('max', rowIndex, newData)
    this._loopSyncAllDataAtRowIndex('max', rowIndex)
  }
}
