import BaseUIStoreSettingXShippingDHLView from 'xui/modules/xshipping/BaseUIStoreSettingXShippingDHLView'

class StoreSettingXShippingDHLView extends BaseUIStoreSettingXShippingDHLView {
  static displayName = 'StoreSettingXShippingDHLView'

  static navigationOptions = {
    header: null,
    title: null,
    headerLeft: null,
    headerRight: null,
  }
}

export default StoreSettingXShippingDHLView
