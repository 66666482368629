import React, { useState, useEffect } from 'react'
import { View, Image } from 'react-native'
import _ from 'lodash'

import { IApiOptions } from 'x/index'
import { COLORS } from 'x/config/styles'

import XIcon from 'xui/components/XIcon'
import * as util from 'x/utils/util'
import api from 'x/utils/api'
import XCard from 'xui/components/XCard'
import HStack from 'xui/components/HStack'
import XText from 'xui/components/XText'

const BEAM_LOGO = require('../../images/partner/beam.png')

export interface IPaymentPartnerTransactions {
  id: number
  pps_id: number // 2 = beam
  payer_store_id: number
  payee_store_id: number
  payment_account_id: number
  total_amount: number
  status: 'payable' | 'completed'
  ref_id: string
  ref_url: string
  created_at: string
  orders: {
    order_id: number
    amount: number
  }[]
}

interface IPaymentPTTComponentProps {
  order_id: number
  onSelected: (pptsSnSelected: IPaymentPartnerTransactions[]) => void
}

const PaymentPTTComponent: React.FC<IPaymentPTTComponentProps> = ({ order_id, onSelected }) => {
  // const channelFromParam = navigation.getParam(`channel`)
  // const callBackUpdateChannel = navigation.getParam(`callBackUpdateChannel`)

  const [canDoGetOrderTransactions, getOrderTransactions] = useState<boolean>(false)
  const [transactions, setTransactions] = useState<IPaymentPartnerTransactions[] | null>(null)
  const [beamTransactions, setBeamTransactions] = useState<any>(null)

  const getOrderTransactionsFunction = async () => {
    getOrderTransactions(true)
    const selectedStore = util.getSelectedStoreFromRedex()
    const reqBody = { store_id: selectedStore.get('id'), order_id }
    const apiOptions: IApiOptions = {
      showSpinner: true,
    }
    const res: { ppts: IPaymentPartnerTransactions[] } = await api.postV2('payment/partner/transactions', reqBody, apiOptions)
    const transactionsRespone = res.ppts
    const beamTransactionsRespone: IPaymentPartnerTransactions[] = []
    if (transactionsRespone.length > 0) {
      transactionsRespone.map((ts: IPaymentPartnerTransactions) => {
        // pps_id === 2 => BEAM
        if (ts.pps_id === 2) {
          beamTransactionsRespone.push(ts)
        }
      })
    }
    setTransactions(transactionsRespone)
    setBeamTransactions(beamTransactionsRespone)
    await util.delay(2000)
    getOrderTransactions(false)
  }

  useEffect(() => {
    if (!canDoGetOrderTransactions) {
      getOrderTransactionsFunction()
    }
  }, [order_id])

  return (
    <View>
      <PaymentPTTCard transactions={beamTransactions} partner='beam' onSelected={onSelected} />
    </View>
  )
}

const PaymentPTTCard: React.FC<{
  transactions: IPaymentPartnerTransactions[]
  partner: 'beam'
  onSelected: (pptsSnSelected: IPaymentPartnerTransactions[]) => void
}> = ({ transactions, partner, onSelected }) => {
  if (_.isNil(transactions) || transactions.length < 1) {
    return null
  }
  const transactionsLength = transactions.length

  return (
    <XCard
      borderTopColor={COLORS.BEAM_MAIN}
      borderLeftColor={COLORS.BEAM_MAIN}
      borderBottomColor={COLORS.BEAM_MAIN}
      borderRightColor={COLORS.BEAM_MAIN}
      onPress={() => {
        if (_.isFunction(onSelected)) {
          onSelected(transactions)
        }
      }}>
      <XCard.Body>
        <HStack alignItems='center' justifyContent='space-between' space='1'>
          <HStack alignItems='center' space='1'>
            <Image
              resizeMode='contain'
              style={{
                width: 30,
                height: 30,
              }}
              source={BEAM_LOGO}
            />
            <XText color={COLORS.BEAM_MAIN}>{`มี ${transactionsLength} ลิงก์รับชำระผ่าน Beam`}</XText>
          </HStack>
          <XIcon color={COLORS.BEAM_MAIN} family='AntDesign' name='arrowright' />
        </HStack>
      </XCard.Body>
    </XCard>
  )
  // return (
  //   <View>
  //     <TouchableOpacity
  //       onPress={() => {
  //         if (_.isFunction(onSelected)) {
  //           onSelected(transactions)
  //         }
  //       }}
  //       style={{
  //         backgroundColor: COLORS.WHITE,
  //         borderColor: COLORS.BEAM_MAIN,
  //         borderWidth: 1,
  //         borderRadius: 7,
  //         height: 50,
  //         flexDirection: 'row',
  //         justifyContent: 'flex-start',
  //         alignItems: 'center',
  //       }}>
  //       <Image
  //         resizeMode='cover'
  //         style={{
  //           width: 30,
  //           height: 30,
  //           marginLeft: 18,
  //         }}
  //         source={BEAM_LOGO}
  //       />
  //       <Text
  //         style={{
  //           fontSize: STYLES.FONT_SIZE_LARGER,
  //           color: COLORS.BEAM_MAIN,
  //           paddingLeft: 8,
  //         }}>{`มี ${transactionsLength} ลิงก์รับชำระผ่าน Beam`}</Text>
  //       <View style={{ flex: 1 }} />
  //       {/* <XIcon
  //         style={{ minWidth: 25, width: 25, flex: 0, fontSize: STYLES.FONT_ICON_LARGER, marginRight: 18, color: COLORS.BEAM_MAIN }}
  //         family={'FontAwesome'}
  //         name={'arrow-circle-right'}
  //       /> */}
  //       <XIcon
  //         style={{ minWidth: 25, width: 25, flex: 0, fontSize: STYLES.FONT_ICON_NORMAL, marginRight: 18, color: COLORS.BEAM_MAIN }}
  //         family='AntDesign'
  //         name='arrowright'
  //       />
  //     </TouchableOpacity>
  //   </View>
  // )
}

// // @ts-ignore disable react-navigation header
// PaymentPTTComponent.navigationOptions = {
//   header: null,
// }

export default PaymentPTTComponent
