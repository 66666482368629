/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React from 'react'
import BaseUIImportExcelCreateOrderView from 'xui/modules/importExcel/BaseUIImportExcelCreateOrderView'
import _ from 'lodash'
import { View, TouchableOpacity } from 'react-native'
import p from 'x/config/platform-specific'
import { COLORS, STYLES } from 'x/config/styles'
import 'react-datepicker/dist/react-datepicker.css'
import Dropzone from 'react-dropzone'
import axios from 'axios'
import XIcon from 'xui/components/XIcon'
import * as util from 'x/utils/util'
import { getConfig } from 'x/config/mode'
import CONS from 'x/config/constants'
import XText from 'xui/components/XText'
import { downloadFileFromUrl } from '../../utils/util-web'

export default class ImportExcelCreateOrderView extends BaseUIImportExcelCreateOrderView {
  _handleOnDownloadFile = async (url: string, fileName: string) => {
    await downloadFileFromUrl({ url, fileName })
  }

  _openLinkOrderToWebView(hash: any) {
    try {
      // @ts-ignore
      // Linking.openURL(hash, '_blank')
      window.open(hash, '_blank')
    } catch (error) {
      // log(error)
    }
  }

  openLinkOrderToWebView(url: string) {
    console.log('url => ', url)
    if (_.isNil(url)) {
      p.op.showConfirmationOkOnly('', 'เร็วๆนี้')
      return
    }
    try {
      // @ts-ignore
      window.open(url, '_blank')
    } catch (error) {
      // log(error)
    }
  }

  _openLinkVrichDetail = (url: string) => {
    try {
      // @ts-ignore
      window.open(url, '_blank')
    } catch (error) {
      // log(error)
    }
  }

  _renderDropzone = () => {
    const { fileUpload } = this.state
    const lableDropZone = _.isNil(fileUpload) ? `วาง Excel ไฟล์ไว้ตรงนี้\nหรือกดตรงนี้เพื่อเลือกไฟล์` : `ไฟล์ที่เลือก\n"${fileUpload.name}"`
    // const lableDropZone = `TEST`
    const borderStyle = _.isNil(fileUpload) ? 'dashed' : 'solid'
    return (
      <View style={{ width: '100%', height: 150, justifyContent: 'center', alignItems: 'center', backgroundColor: COLORS.WHITE }}>
        <Dropzone
          onDrop={(acceptedFiles) => {
            // @ts-ignore
            this._setFileUpload(acceptedFiles)
            // console.log('acceptedFiles => ', acceptedFiles)
          }}
          accept='.xls, .xlsx'
          multiple={false}>
          {({ getRootProps, getInputProps }) => (
            <TouchableOpacity style={{ width: `100%`, paddingLeft: 4, paddingRight: 4, paddingTop: 4, paddingBottom: 4 }}>
              <div style={{ backgroundColor: COLORS.BG_LIGHT_GREY, borderRadius: 10 }} {...getRootProps()}>
                <input {...getInputProps()} />
                <View
                  style={{
                    height: 150,
                    width: '100%',
                    borderWidth: 0.8,
                    borderColor: COLORS.APP_MAIN,
                    borderStyle,
                    borderRadius: 10,
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingLeft: 6,
                    paddingRight: 6,
                  }}>
                  <XText variant='active' textAlign='center'>
                    {lableDropZone}
                  </XText>
                  {_.isNil(fileUpload) ? null : (
                    <XIcon
                      name='checkcircle'
                      family='AntDesign'
                      style={{ flex: 0, minWidth: 25, width: 40, color: COLORS.GREEN, fontSize: STYLES.FONT_ICON_LARGER }}
                    />
                  )}
                </View>
              </div>
            </TouchableOpacity>
          )}
        </Dropzone>
      </View>
    )
  }

  onPressUpload = async () => {
    const { subscription } = this.props
    if (subscription.get('type') === CONS.SUBSCRIPTION_PACKAGE.FREE) {
      p.op.alert(p.op.t('Subscription.warning.insufficientTypeTitle'), p.op.t('Subscription.warning.insufficientTypeMsg'))
      return
    }
    // const { fileUpload, selectedPaymentAccount, selectedCreateOrderData, selectedDateTime } = this.state
    const { fileUpload } = this.state
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    await util.setStatePromise(this, {
      isLoading: true,
    })

    const formData = new FormData()

    // Array.from(this.state.fileUpload).forEach(image => {
    // // @ts-ignore
    //   formData.append('file', image)
    // })
    const formDataFromGetData = this._getFormData(formData)
    // @ts-ignore
    formDataFromGetData.append('file', fileUpload)

    // THIS CODE UNUSE NOW
    // if (_.isNil(selectedShippingType.post_date)) {
    //   const dateTime = util.getTimeFormatYYYYMMDDhhmmss(selectedDateTime)
    //   const postDate = ` {"mode":"same-value-for-all","value":"${dateTime}","time_format":"2006-01-02 15:04:05"}`
    //   formData.append('post_date', `${postDate}`)
    // } else {
    //   formData.append('post_date', `${selectedShippingType.post_date}`)
    // }

    // console.log(`formData`, formData)
    const token = await p.op.storageGet(CONS.AUTHENTICATION_STORAGE_KEY, false)
    // console.log(`TOKEN => `, `Bearer ${token}`)
    // return
    const path = `excel/import/create_order`
    const url = getConfig()
    axios
      .post(`${url.app_goApiRoot}/${path}`, formDataFromGetData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
          // 'Access-Control-Allow-Origin': '*',
          // 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
        },
        // timeout: 60000,
      })
      .then(async (res) => {
        // @ts-ignore res
        // console.log('REF => ', res)
        if (res.data && res.data.bgjob_uuid) {
          await util.alertWithNavToBgJobListView({
            title: p.op.t('bg_job.title_create_bgjob_succeed'),
            message: p.op.t('bg_job.message_bgjob_import_create_order'),
            dispatch: this.props.navigation.dispatch,
            selectedStoreId: this.props.selectedStore.get('id'),
            onUserConfirm: () => this._goBack(),
            initBgJobUUID: res.data.bgjob_uuid,
          })
          this.inProcess = false
          return
        }
        this.inProcess = false
        // console.log('res => ', res)
        p.op.showConfirmationOkOnly('เกิดข้อผิดพลาด', p.op.t(`e_msg_by_error_code.${res.data.error.error_code}.m`))
        // p.op.showConfirmationOkOnly('', `เกิดข้อผิดพลาด ${res}`)

        this.inProcess = false
        await util.setStatePromise(this, {
          isLoading: false,
        })
      })
      .catch(async (err) => {
        // console.log('err => ', err)
        const errorCode = !_.isNil(err.response) && !_.isNil(err.response.data) ? err.response.data.error.error_code : null
        // console.log('errorCode => ', errorCode)
        if (!_.isNil(errorCode)) {
          const textErrorCode = util.getBgTaskErrorText(errorCode)
          p.op.showConfirmationOkOnly('', textErrorCode)
          await this._stopLoading()
          return
        }
        p.op.showConfirmationOkOnly('', `เกิดข้อผิดพลาด ${err}`)
        await this._stopLoading()
      })
  }
}
