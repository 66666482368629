import { LinkingOptions } from '@react-navigation/native'

const linking: LinkingOptions<any> = {
  // TODO: ยังทำไม่เสร็จ ค่อยเปิดหลังจากหาวิธี deeplink ใน web ได้สมบูรณ์
  enabled: false,
  prefixes: [
    /* your linking prefixes */
    // linkPrefix,
    // '*',
    // 'http://127.0.0.1',
    'http://localhost:39988',
    'https://localhost:39988',
    'xselly://',
    'http://xselly.com',
    'https://xselly.com',
    'http://*.xselly.com',
    'https://*.xselly.com',
  ],
  config: {
    /* configuration for matching screens with paths */
    // initialRouteName: 'Home',
    screens: {
      // First: '',
      // Home: 'home',
      // Test: 'test',
      // NotFound: '*',
      // Test: 'test',
      // NotFound: '*',
      LandingView: 'landing',
      LoginView: 'login',
      LoggedInStack: {
        path: 'main',
        screens: {
          Home: 'home',
          Test: 'test',
          NotFound: '*',
          // CommonContentStackNavigator: 'orders',
          // QuickShipViewStackNavigator: 'quickship',
          // StoreOrdersDonutViewStackNavigator: 'donut',
          // ResellerApprovalListStackNavigator: 'approval_resellers',
          // StoreSettingStackNavigator: 'storeseetinglist',
          // ResellerRegisterView: 'register_reseller',
          // ResellerListStackNavigator: 'resellers',
          // ResellerImportView: 'import_reseller',
          // ReportRequisitionView: 'print_requisition',
          // UserGroupStackNavigator: 'user_groups',
          // ProductGroupStackNavigator: 'product_groups',
          // ReportListStackNavigator: 'reports',
          // importExcelStackNavigator: 'import_excel',
          // SettingViewStackNavigator: 'setting_view',
          // EmptyView: 'empty',
          StoreOrdersDonutListView: 'orders_donut',
        },
      },
      OnboardingRegisterView: 'signup',
      OnboardingFirstStoreNameView: 'signup_store_name',
      OnboardingUsernameView: 'signup_username',
      OnboardingEmailView: 'signup_email',
      ForgottenPasswordView: 'forgot_password',
      NotFound: '*',
    },
  },

  // // Custom function to get the URL which was used to open the app
  // async getInitialURL() {
  //   // First, you would need to get the initial URL from your third-party integration
  //   // The exact usage depend on the third-party SDK you use
  //   // For example, to get to get the initial URL for Firebase Dynamic Links:
  //   // const { isAvailable } = utils().playServicesAvailability;

  //   // if (isAvailable) {
  //   //   const initialLink = await dynamicLinks().getInitialLink();

  //   //   if (initialLink) {
  //   //     return initialLink.url;
  //   //   }
  //   // }

  //   // As a fallback, you may want to do the default deep link handling
  //   const url = await Linking.getInitialURL()
  //   console.log('getInitialURL url => ', url)

  //   return url
  // },

  // getStateFromPath: (path, options) => {
  //   // Return a state object here
  //   // You can also reuse the default logic by importing `getStateFromPath` from `@react-navigation/native`
  //   console.log('getStateFromPath path => ', path)
  //   console.log('getStateFromPath options => ', options)
  //   return getStateFromPath(path, options)
  // },
  // getPathFromState(state, config) {
  //   // Return a path string here
  //   // You can also reuse the default logic by importing `getPathFromState` from `@react-navigation/native`
  //   console.log('getPathFromState path => ', state)
  //   console.log('getPathFromState options => ', config)
  //   return getPathFromState(state, config)
  // },
}

export default linking
