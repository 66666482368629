import React from 'react'
import _ from 'lodash'

import { LayoutChangeEvent } from 'react-native'
import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import BaseRangeAdjuster, { IMetadata, IRowDataMap } from 'x/components/BaseRangeAdjuster'
import NB from 'x/config/nativebase'
import XInput from './XInput'
import XIcon from './XIcon'
import XText from './XText'
import Box from './Box'
import Button from './XButton'

const titleBGColor = '#CACFD7'
const bgWhite = '#fff'
const bgEvenColor = '#E8E9EB'

// const RAText = (props) => (
//   <Text
//     {...props}
//     style={{
//       textAlign: 'right',
//       fontSize: STYLES.FONT_SIZE_NORMAL,
//       color: COLORS.TEXT_INACTIVE,
//       fontWeight: 'normal',
//     }}
//   />
// )

// const RAHeaderTextAlignRight = (props) => (
//   <Text
//     {...props}
//     style={{
//       textAlign: 'right',
//       fontSize: STYLES.FONT_SIZE_NORMAL,
//       color: COLORS.TEXT_INACTIVE,
//       // fontWeight: 'bold',
//     }}
//   />
// )

// const RAHeaderTextAlignLeft = (props) => (
//   <Text
//     {...props}
//     style={{
//       textAlign: 'left',
//       fontSize: STYLES.FONT_SIZE_NORMAL,
//       color: COLORS.TEXT_INACTIVE,
//       // fontWeight: 'bold',
//     }}
//   />
// )

// const RARow = (props) => <HStack {...props} style={{ marginTop: 4, height: 40, ...props.style }} />

// const RACol = (props) => <VStack {...props} style={{ padding: 4, justifyContent: 'center', ...props.style }} />

// const RAColTitle = (props) => <VStack {...props} style={{ padding: 4, justifyContent: 'center', backgroundColor: bgWhite }} />

const RAInput = ({ disabled = false, style = {}, ...restProps }) => (
  <XInput
    isDisabled={disabled}
    textAlign='center'
    minH='8'
    style={style}
    // maxLength={ inputMaxLength || null }
    autoCorrect={false}
    autoCapitalize='none'
    {...restProps}

    // placeholder={txtPlaceholder || null}
    // placeholderTextColor={COLORS.TEXT_INACTIVE}
    // value={ '1234' }
    // keyboardType={'phone-pad'}
    // selectTextOnFocus={false}
    // returnKeyType={'done'}
    // blurOnSubmit={false}
    // onChangeText={ text => {
    //   util.log(' onChangeText input  => ', text)
    // }}
  />
)

export default class RangeAdjuster extends BaseRangeAdjuster {
  static displayName = 'RangeAdjuster'

  _renderGenericTitle = (mData: IMetadata, index: number) => (
    <HStack key={index.toString()} flex={mData.span ? mData.span : 1} alignItems='center' justifyContent='center'>
      <XText variant='inactive'>{mData.label}</XText>
    </HStack>
  )

  _renderGenericInput = (inputIndex: number, rowIndex: number, mData: IMetadata, rowData: IRowDataMap) => {
    // const rowData = this.state.data.get(rowIndex)
    const { disabled = false } = this.props
    if (!rowData) {
      return null
    }
    let bgColor = bgWhite
    const even = this._findEven(rowIndex)
    if (disabled) {
      if (even) {
        bgColor = bgEvenColor
      }
    }
    // const disabled = this.props.disabled
    const mKey = mData.key
    let value = rowData.get(mKey)
    value = !_.isNull(value) && !_.isUndefined(value) ? value : ''

    if (disabled && !value) {
      value = '-'
    }

    if (value !== '-') {
      if (!_.isNil(mData.usePrefixOrSuffix) && disabled && mData.usePrefixOrSuffix === mData.prefix) {
        value = mData.prefix + value
      } else if (!_.isNil(mData.usePrefixOrSuffix) && disabled && mData.usePrefixOrSuffix === mData.suffix) {
        value += mData.suffix
      }
    }

    return this.props.disabled ? (
      <HStack key={`${inputIndex}_${rowIndex}`} flex={mData.span ? mData.span : 1} alignItems='center' justifyContent='center'>
        <XText variant='inactive' textAlign='right'>
          {value.toString()}
        </XText>
      </HStack>
    ) : (
      <RAInput
        key={`${inputIndex}_${rowIndex}`}
        flex={mData.span ? mData.span : 1}
        // disabled={this.props.disabled}
        value={value.toString()}
        style={mData.inputStyle ? { textAlign: 'right', ...mData.inputStyle } : { textAlign: 'right' }}
        keyboardType={mData.keyboardType ? mData.keyboardType : 'default'}
        onChangeText={(newValue) => this._onChangeTextAtRowIndex(mKey, rowIndex, newValue)}
        onBlur={() => this._onEndEditingMetaDataAtRowIndex(mKey, rowIndex)}
        // isMoney={_.isNil(mData.isMoney) ? false : mData.isMoney}
        // isNumber={_.isNil(mData.isNumber) ? false : mData.isNumber}
        isInteger={mData.isInteger}
        isNumber={mData.isNumber || mData.isMoney}
        // onChangeText={ newValue => {
        //   if (_.isFunction(mData.filteredMethod)) {
        //     const filteredValue = mData.filteredMethod(newValue)
        //     this._onChangeMetaColumn(mKey, rowIndex, filteredValue)
        //   } else {
        //     this._onChangeMetaColumn(mKey, rowIndex, newValue)
        //   }
        // }}
        // onBlur={ () => this._onChangeMetaColumn(mKey, rowIndex, value) }
        // onEndEditing={ () => this._onEndEditingMetaDataAtRowIndex(mKey, rowIndex, value) }
      />
    )
  }

  _renderRowData = (rowData: IRowDataMap, rowIndex: number): JSX.Element => {
    const { metadata, labelLastEnd, disabled = false, highlightedRowIndex = null } = this.props
    const isRemovable = this._isRemovableRow(rowIndex)
    // const isDisabledBegin = this._isDisabledBegin(rowIndex)
    const isDisabledEnd = this._isDisabledEnd(rowIndex)
    const isDisabledBegin = this._isDisabledBegin(rowIndex)
    // const txtBegin = _.has(rowData,'begin') ? rowData.get('begin').toString() : ''
    // let txtEnd = _.has(rowData,'end') ? rowData.get('end').toString() : ''
    // // if (_.has(rowData,'end') && rowData.get('end') === 0) {
    // //   txtEnd = ''
    // // }
    // let bgColor = bgWhite
    // const even = this._findEven(rowIndex)
    // if (this.props.disabled) {
    //   if (even) {
    //     bgColor = bgEvenColor
    //   }
    // }

    // let injectedRowStyle: ViewStyle = { backgroundColor: bgColor }
    // if (disabled && !_.isNil(highlightedRowIndex) && highlightedRowIndex === rowIndex) {
    //   injectedRowStyle = {
    //     borderWidth: 0.5,
    //     borderStyle: 'dotted',
    //     borderColor: COLORS.APP_MAIN,
    //     borderRadius: 6,
    //     backgroundColor: COLORS.APP_SECONDARY,
    //   }
    // }
    // const isEvenRow = disabled && this._findEven(rowIndex)
    const isHighlightedRow = disabled && !_.isNil(highlightedRowIndex) && highlightedRowIndex === rowIndex
    let injectedRowProps = {}
    if (isHighlightedRow) {
      injectedRowProps = {
        borderWidth: 1,
        borderStyle: 'dotted',
        borderRadius: 6,
        borderColor: NB.U.TEXT_L_PRIMARY,
      }
    }

    return (
      <HStack
        key={rowIndex.toString()}
        w='full'
        // flex={1}
        space='1'
        py='1'
        alignItems='center'
        _light={isHighlightedRow ? NB.U.BG_L_PRIMARY : NB.U.BG_L_CLEAR}
        _dark={isHighlightedRow ? NB.U.BG_D_PRIMARY : NB.U.BG_D_CLEAR}
        // _light={isHighlightedRow ? NB.U.BG_L_PRIMARY : isEvenRow ? NB.U.BG_L_LIGHT_GREY : NB.U.BG_L_CLEAR}
        // _dark={isHighlightedRow ? NB.U.BG_D_PRIMARY : isEvenRow ? NB.U.BG_D_LIGHT_GREY : NB.U.BG_D_CLEAR}
        {...injectedRowProps}
        style={this.state.containerWidth ? { width: this.state.containerWidth } : {}}
        //  style={injectedRowStyle}
      >
        {disabled || this.getData().size <= 2 ? null : (
          <HStack w='11' alignItems='center' justifyContent='center'>
            {isRemovable ? (
              <Button
                w='11'
                h='8'
                leftIcon={<XIcon activeDark name='trash' />}
                colorScheme='danger'
                onPress={() => this._deleteRow(rowIndex)}
              />
            ) : null}
          </HStack>
        )}

        {/* <RAText>{ rowData.get('begin') === 0 ? '' : rowData.get('begin').toString() }</RAText> */}

        {/* ค่า min */}
        {disabled || isDisabledBegin ? (
          <HStack w='20' alignItems='center' justifyContent='center'>
            <XText variant='inactive'>{rowData.get('min').toString()}</XText>
          </HStack>
        ) : (
          <RAInput
            w='20'
            // disabled={isDisabledBegin}
            value={rowData.get('min').toString()}
            // style={{ textAlign: 'left' }}
            keyboardType='numeric'
            // onChangeText={(newBegin) => this._onChangeTextAtRowIndex('min', rowIndex, newBegin)}
            // onEndEditing={() => this._loopSyncAllDataAtRowIndex('min', rowIndex)}
            onChangeText={(newTextMin) => this.onChangeTextMin(newTextMin, rowIndex)}
            onBlur={() => this.onEndEditingMin(rowIndex)}
            isInteger
          />
        )}
        {/* <RAInput */}
        {/* disabled={isDisabledBegin} */}
        {/* value={rowData.get('begin').toString()} */}
        {/* keyboardType={'phone-pad'} */}
        {/* onChangeText={ newBegin => this._onChangeBegin(rowIndex, newBegin) } */}
        {/* onEndEditing={ () => this._loopSyncAllDataAtRowIndex('begin', rowIndex) } */}
        {/* /> */}

        {/* ค่า max */}
        {disabled || isDisabledEnd ? (
          <HStack w='20' alignItems='center' justifyContent='center'>
            <XText variant='inactive'>{isDisabledEnd ? labelLastEnd : rowData.get('max').toString()}</XText>
          </HStack>
        ) : (
          <RAInput
            w='20'
            // disabled={disabled}
            // style={{ textAlign: 'right' }}
            value={_.isNil(rowData.get('max')) ? '' : rowData.get('max').toString()}
            keyboardType='numeric'
            // onChangeText={(newEnd) => this._onChangeTextAtRowIndex('max', rowIndex, newEnd)}
            // onBlur={(e) => {
            //   // log('onBlur e => ', e)
            //   const newData = this._getLastEndEditingData('max', rowIndex, rowData.get('max'))
            //   this._loopSyncAllDataAtRowIndex('max', rowIndex, newData)
            // }}
            onChangeText={(newTextMax) => this.onChangeTextMax(newTextMax, rowIndex)}
            onBlur={() => this.onEndEditingMax(rowIndex)}
            isInteger
            // onEndEditing={ (e) => {
            //   log('onEndEditing e => ', e.target.value)
            //   this._loopSyncAllDataAtRowIndex('end', rowIndex)
            // }}
          />
        )}

        {/* ช่องขวาสุดเป็นค่า metadata field ที่ props กำหนดขึ้นมา */}
        {metadata.map((mData, ipIndex) => this._renderGenericInput(ipIndex, rowIndex, mData, rowData))}
      </HStack>
    )
  }

  _findEven = (n: number) => n % 2 === 0

  _onContainerLayout = (evt: LayoutChangeEvent) => {
    // console.log('_onContainerLayout evt.nativeEvent.layout => ', evt.nativeEvent.layout)
    const newWidth = evt.nativeEvent.layout.width
    if (!this.state.containerWidth || this.state.containerWidth !== newWidth) {
      this.setState({ containerWidth: newWidth })
    }
  }

  render() {
    const { isInitialized, containerWidth } = this.state
    if (!isInitialized) {
      return null
    }
    const { metadata, labelAddButton = 'เพิ่ม', disabled = false } = this.props
    const data = this.getData()
    const rowCount = data.size

    // <Box flex={1} w='full' onLayout={this._onContainerLayout}>
    return (
      <VStack
        w='full'
        // style={containerWidth ? { width: containerWidth } : {}}
      >
        {/* Table Header */}
        <HStack
          w='full'
          // flex={1}
          space='1'
          alignItems='center'
          py='1'
          // style={containerWidth ? { width: containerWidth } : {}}
          // borderBottomWidth='1'
          // borderBottomColor='warmGray.200'
          _light={NB.U.BG_L_LIGHT_GREY}
          _dark={NB.U.BG_D_LIGHT_GREY}>
          {disabled || rowCount <= 2 ? null : <Box w='11' />}
          <HStack w='20' alignItems='center' justifyContent='center'>
            <XText variant='inactive'>ตั้งแต่</XText>
          </HStack>
          <HStack w='20' alignItems='center' justifyContent='center'>
            <XText variant='inactive'>ถึง</XText>
          </HStack>
          {metadata.map(this._renderGenericTitle)}
        </HStack>

        {/* Body Content (ช่วงปรับขั้นบันได) */}
        {data.map(this._renderRowData)}

        {/* Add more button */}
        {disabled ? null : (
          <HStack w='full' py='2'>
            <Button onPress={this._addNewRow}>{labelAddButton}</Button>
          </HStack>
        )}
      </VStack>
    )
    // </Box>
  }
}
