// import XInput from '../../components/XInput'

import BaseUICustomCreateOrderView from 'xui/modules/order/BaseUICustomCreateOrderView'

export default class CustomCreateOrder extends BaseUICustomCreateOrderView {
  static displayName = 'CustomCreateOrder'

  static navigationOptions = {
    header: null,
  }
}
