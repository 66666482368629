import React from 'react'
import BaseCustomerEditProfileView from 'x/modules/customer/BaseCustomerEditProfileView'
import { Text, View } from 'react-native'

import { COLORS, STYLES } from 'x/config/styles'
import * as util from 'x/utils/util'

import _ from 'lodash'
import CONS from 'x/config/constants'
import p from 'x/config/platform-specific'
import XCustomHeader from 'xui/components/XCustomHeader'
import { IProfile } from 'x/index'
import { Card } from 'react-native-elements'
import XProfile from 'xui/components/XProfile'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'

const { VIEW, EDIT } = CONS.MODE

export default abstract class BaseUICustomerView extends BaseCustomerEditProfileView {
  static displayName = 'BaseUIPaymentListView'

  abstract goBack: () => void

  abstract renderViewImgManager(): JSX.Element

  renderCustomHeader = () => {
    // const { navigation } = this.props
    const { mode } = this.state
    const isEditMode = mode === EDIT
    let leftBtn = { backIcon: true, onPressItem: () => this.goBack() }
    if (isEditMode) {
      // @ts-ignore
      leftBtn = { label: `ยกเลิก`, onPressItem: () => this._cancleEdit() }
    }
    return (
      <XCustomHeader
        title='ข้อมูลลูกค้า'
        headerLeftProps={leftBtn}
        // headerRightProps={{ label: `แก้ไข`, onPressItem: () => util.setStatePromise(this, { mode: EDIT }) }}
      />
    )
  }

  _cancleEdit = () => {
    p.op.showConfirmation(
      ``,
      `ยกเลิกการแก้ไขใช่หรือไม่`,
      () => this.goBack(),
      () => {},
      'ใช่',
      `แก้ไขต่อ`
    )
  }

  _onSubmitCallBack = async (profile: IProfile) => {
    // // console.log(` IM BACK FROM SUMMIT !!`, profile)
    // const { navigation } = this.props
    // const { state } = navigation
    // const { params } = state
    // // @ts-ignore
    // // console.log(`selectedStore`, this.props.selectedStore.toJS())
    // // console.log(`params`, params)
    // const callBackToProfileView = params.callBackFromEdit
    const callBackToProfileView = util.getNavParam(this.props, 'callBackToProfileView')
    this.goBack()
    // await util.delay(5000)
    if (_.isFunction(callBackToProfileView)) {
      callBackToProfileView(profile)
    }
  }

  _renderProfileView = () => {
    const { selectedStore, updateOrgMemberProfileDetail } = this.props
    const { profile, mode } = this.state
    // console.log(`MODE => `, mode)
    return (
      <Card>
        <View style={{ height: 40, flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE, fontWeight: 'bold' }}>ข้อมูลทั่วไป</Text>
        </View>
        <View style={{ height: 600 }}>
          {this.renderViewImgManager()}
          {_.isNil(profile) ? null : (
            <XProfile
              mode={mode}
              profile={profile}
              selectedStore={selectedStore}
              onSubmit={updateOrgMemberProfileDetail}
              onSubmitCallBack={(profile: IProfile) => this._onSubmitCallBack(profile)}
            />
          )}
        </View>
      </Card>
    )
  }

  _renderDealerView = () => (
    <Card>
      <View style={{ height: 40, flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE, fontWeight: 'bold' }}>ข้อมูลแม่ทีม</Text>
      </View>
    </Card>
  )

  _renderMain() {
    return (
      <XContainer>
        {this.renderCustomHeader()}
        <XContent style={{ backgroundColor: COLORS.WHITE }}>
          {this._renderProfileView()}
          {/* {this._renderDealerView()} */}
        </XContent>
      </XContainer>
    )
  }

  render() {
    return this._renderMain()
  }
}
