/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux-immutable'
// import { LOCATION_CHANGE } from 'react-router-redux'

// import globalReducer from 'modules/App/reducer'
// import languageProviderReducer from 'modules/LanguageProvider/reducer'
// import { reducer as formReducer } from 'redux-form/immutable'

// import BarcodeListenerStateReducer from 'modules/BarcodeListener/BarcodeListenerState'

// Import State Reducer from container
import xStoreListStateReducer from 'x/modules/store/StoreState'
import xOrderStateReducer from 'x/modules/order/OrderState'
import xStoreOrderListStateReducer from 'x/modules/orderlist/StoreOrderListState'
import xPaymentOrderListStateReducer from 'x/modules/orderlist/PaymentOrderListState'
import xSessionStateReducer from 'x/modules/session/SessionState'
import xPaymentStateReducer from 'x/modules/payment/PaymentState'
import xBankStateReducer from 'x/modules/bank/BankState'
import xShipmentStateReducer from 'x/modules/shipment/ShipmentState'
import xProductStateReducer from 'x/modules/product/ProductState'
import xProfileStateReducer from 'x/modules/profile/ProfileState'
import xSubscriptionStateReducer from 'x/modules/subscription/SubscriptionState'
import xMySubscriptionStateReducer from 'x/modules/subscription/MySubscriptionState'
import xPreferenceStateReducer from 'x/modules/preference/PreferenceState'
import xCategoryStateReducer from 'x/modules/category/categoryState'
import xAddressStateReducer from 'x/modules/address/AddressState'
import xResellerStateReducer from 'x/modules/reseller/ResellerState'
import ProductSlice from 'x/modules/product/ProductSlice'
import XShippingStateReducer from 'x/modules/xshipping/XShippingState'

import xLoadingInProgressStateReducer from 'x/modules/loadinginprogress/LoadingInProgressState'
import xMarketplaceChannelStateReducer from 'x/modules/marketplaceChannel/marketplaceChannelState'
// import MultiColumnStateReducer from 'modules/MultiColumn/MultiColumnState'
import xContactStateReducer from 'x/modules/contact/ContactState'

// // Initial routing state
// const routeInitialState = fromJS({
//   location: null,
// })

/**
 * Merge route into the global application state
 */
// function routeReducer(state = routeInitialState, action) {
//   switch (action.type) {
//     /* istanbul ignore next */
//     case LOCATION_CHANGE:
//       return state.merge({
//         location: action.payload,
//       })

//     default:
//       return state
//   }
// }

/**
 * Creates the main reducer with the dynamically injected ones
 */
export default combineReducers({
  // route: routeReducer,
  // global: globalReducer,
  // language: languageProviderReducer,

  // form: formReducer, // For Redux-form
  // BCL: BarcodeListenerStateReducer,

  // Reducers From XSelly App
  stores: xStoreListStateReducer,
  preference: xPreferenceStateReducer,
  category: xCategoryStateReducer,
  address: xAddressStateReducer,
  session: xSessionStateReducer,
  storeOrders: xStoreOrderListStateReducer,
  paymentOrders: xPaymentOrderListStateReducer,
  products: xProductStateReducer,
  // mc: MultiColumnStateReducer,
  order: xOrderStateReducer,
  payment: xPaymentStateReducer,
  bank: xBankStateReducer,
  shipment: xShipmentStateReducer,

  profile: xProfileStateReducer,
  subscription: xSubscriptionStateReducer,
  mySubscription: xMySubscriptionStateReducer,
  xshipping: XShippingStateReducer,
  product: ProductSlice.reducer,

  reseller: xResellerStateReducer,

  // สำหรับ Loading In Progress
  loadingInProgress: xLoadingInProgressStateReducer,

  marketplaceChannel: xMarketplaceChannelStateReducer,
  contacts: xContactStateReducer,
})
