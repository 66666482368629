import { bindActionCreators } from 'redux'
import {
  getEditingOrder,
  getSelectedOrder,
  getSelectedStoreName,
  getSelectedStore,
  getMyStores,
  getSellerStores,
  getSellerStoresShouldFetch,
  getMySubscription,
  getSelectedUserGroups,
  getSelectedProductGroups,
  getEditingOrderIsDirty,
  getEditingStore,
  getEditingCustomCreateOrder,
  getSelectedCustomCreateOrder,
  getEditingCustomOrderView,
  getSelectedCustomOrderView,
} from 'x/redux/selectors'
import * as StoreActions from '../store/StoreState'
import * as ProductActions from '../product/ProductState'
import * as MySubscriptionActions from '../subscription/MySubscriptionState'
import * as OrderState from './OrderState'
import * as PreferenceState from '../preference/PreferenceState'
import * as ContactState from '../contact/ContactState'
import * as AddressActions from '../address/AddressState'
import * as ProfileState from '../profile/ProfileState'

export function getState(state) {
  return {
    // Product state
    // For create order
    // selectedStoreID: state.getIn(['stores', 'selectedStore', 'id']),
    selectedStoreName: getSelectedStoreName(state),

    // For create Order
    // selectedProduct: state.getIn(['products', 'selectedProduct']),
    // selectedProducts: state.getIn(['order', 'selectedProducts']),
    // summary: state.getIn(['order', 'summary']),

    // Refactor address
    selectedAddress: state.getIn(['order', 'editingOrder', 'selectedAddress']),
    sAddr: state.getIn(['order', 'editingOrder', 'selectedAddress', 'sender']),
    rAddr: state.getIn(['order', 'editingOrder', 'selectedAddress', 'receiver']),
    bAddr: state.getIn(['order', 'editingOrder', 'selectedAddress', 'billing_address']),
    addressLists: state.getIn(['address', 'selectedAddresses']),
    addressesList: state.getIn(['address', 'addresses']),
    // Reproduce pick value from address
    seekSelectedAddress: state.getIn(['address', 'selectedAddress']),

    // Order state
    editingOrder: getEditingOrder(state),
    isDirty: getEditingOrderIsDirty(state),
    selectedOrder: getSelectedOrder(state),
    // storeProducts: state.getIn(['order', 'editingOrder', 'store_products']),
    mode: state.getIn(['order', 'mode']),

    shouldFetch: state.getIn(['order', 'shouldFetch']),

    orderTabIndex: state.getIn(['order', 'orderTabIndex']),
    orderTabInit: state.getIn(['order', 'orderTabInit']),
    isInitialized: state.getIn(['order', 'isInitialized']),

    // For using options
    selectedStore: getSelectedStore(state),
    profile: state.get('profile'),
    myStores: getMyStores(state),
    sellerStores: getSellerStores(state),
    sellerStoresShouldFetch: getSellerStoresShouldFetch(state),
    subscription: getMySubscription(state),

    userGroups: getSelectedUserGroups(state),
    productGroups: getSelectedProductGroups(state),
    editingStore: getEditingStore(state),

    // Custom Order
    editingCustomOrderView: getEditingCustomOrderView(state),
    selectedCustomOrderView: getSelectedCustomOrderView(state),
    editingCustomCreateOrder: getEditingCustomCreateOrder(state),
    selectedCustomCreateOrder: getSelectedCustomCreateOrder(state),
  }
}

export function getDispatch(dispatch) {
  return {
    // orderActions: bindActionCreators(OrderState, dispatch),

    // getCopyLinkOrder
    getCopyLinkOrder: bindActionCreators(OrderState.getCopyLinkOrder, dispatch),
    cancelTackingCode: bindActionCreators(OrderState.cancelTackingCode, dispatch),
    createTackingCode: bindActionCreators(OrderState.createTackingCode, dispatch),
    updateTackingCode: bindActionCreators(OrderState.updateTackingCode, dispatch),
    autoRequestTracking: bindActionCreators(OrderState.autoRequestTracking, dispatch),

    // form change function
    setMode: bindActionCreators(OrderState.setMode, dispatch),
    setIsInitialized: bindActionCreators(OrderState.setIsInitialized, dispatch),
    onEditingOrderChange: bindActionCreators(OrderState.onEditingOrderChange, dispatch),
    onEditingOptionChange: bindActionCreators(OrderState.onEditingOptionChange, dispatch),
    onShippingCostCalc: bindActionCreators(OrderState.onShippingCostCalc, dispatch),
    onShippingTypeChange: bindActionCreators(OrderState.onShippingTypeChange, dispatch),
    initOrdersToBePaid: bindActionCreators(OrderState.initOrdersToBePaid, dispatch),
    removeProduct: bindActionCreators(OrderState.removeProduct, dispatch),
    onChangeAddress: bindActionCreators(OrderState.onChangeAddress, dispatch),
    addNotesOrder: bindActionCreators(OrderState.addNotesOrder, dispatch),
    editReceiverAddress: bindActionCreators(OrderState.editReceiverAddress, dispatch),

    clearInCartProducts: bindActionCreators(OrderState.clearInCartProducts, dispatch),

    // Generic editing product variant change by key
    onChangeOrderProductVariantByKey: bindActionCreators(OrderState.onChangeOrderProductVariantByKey, dispatch),
    onChangeSuborderProductVariantByKey: bindActionCreators(OrderState.onChangeSuborderProductVariantByKey, dispatch),
    onChangeStoreProductByKey: bindActionCreators(OrderState.onChangeStoreProductByKey, dispatch),

    // Order Tab
    changeOrderTabIndexTo: bindActionCreators(OrderState.changeOrderTabIndexTo, dispatch),

    // api call function
    fetchOrderDetail: bindActionCreators(OrderState.fetchOrderDetail, dispatch),
    createOrder: bindActionCreators(OrderState.createOrder, dispatch),
    orderConfirm: bindActionCreators(OrderState.orderConfirm, dispatch),
    cancelOrder: bindActionCreators(OrderState.cancelOrder, dispatch),

    // Clear function
    orderRevert: bindActionCreators(OrderState.orderRevert, dispatch),
    resetOrder: bindActionCreators(OrderState.resetOrder, dispatch),

    fetchMyStore: bindActionCreators(StoreActions.fetchMyStore, dispatch),
    fetchSellerStores: bindActionCreators(StoreActions.fetchSellerStores, dispatch),
    fetchSubscription: bindActionCreators(MySubscriptionActions.fetchSubscription, dispatch),

    // load all init at once
    fetchInitializeData: bindActionCreators(StoreActions.fetchInitializeData, dispatch),

    fetchProducts: bindActionCreators(ProductActions.fetchProducts, dispatch),
    quickAddProductToOrder: bindActionCreators(OrderState.quickAddProductToOrder, dispatch),

    // orderNotes
    fetchOrderNotes: bindActionCreators(OrderState.fetchOrderNotes, dispatch),
    addOrderNote: bindActionCreators(OrderState.addOrderNote, dispatch),
    editOrderNote: bindActionCreators(OrderState.editOrderNote, dispatch),
    deleteOrderNote: bindActionCreators(OrderState.deleteOrderNote, dispatch),
    selectOrderNote: bindActionCreators(OrderState.selectOrderNote, dispatch),

    // Store
    findStore: bindActionCreators(StoreActions.findStore, dispatch),

    // Custom Order
    customCreateOrder: bindActionCreators(PreferenceState.setCustomCreateOrder, dispatch),
    // customOrderView: bindActionCreators(PreferenceState.setCustomOrderView, dispatch),
    customOrderView: bindActionCreators(PreferenceState.loadPrefCustomOrderView, dispatch),
    addCustomOrder: bindActionCreators(PreferenceState.addCustomOrder, dispatch),

    // Fetch Customer List
    fetchCustomerList: bindActionCreators(ContactState.fetchCustomerList, dispatch),

    // Address
    loadAddress: bindActionCreators(AddressActions.loadAddressProfile, dispatch),
    findAddresses: bindActionCreators(AddressActions.findAddresses, dispatch),
    getResellerAddresses: bindActionCreators(AddressActions.getResellerAddresses, dispatch),

    // Printing
    addPrinting: bindActionCreators(StoreActions.addPrinting, dispatch),
    editPrinting: bindActionCreators(StoreActions.editPrinting, dispatch),

    orgMemberSignup: bindActionCreators(ProfileState.orgMemberSignup, dispatch),

    getWarehouse: bindActionCreators(StoreActions.getWarehouses, dispatch),
    createWarehouse: bindActionCreators(StoreActions.createWarehouse, dispatch),
    updateWarehouse: bindActionCreators(StoreActions.updateWarehouse, dispatch),
    deleteWarehouse: bindActionCreators(StoreActions.deleteWarehouse, dispatch),
    updateReduxNewWarehouseList: bindActionCreators(StoreActions.updateReduxNewWarehouseList, dispatch),

    fetchChannelProductByPT: bindActionCreators(StoreActions.fetchChannelProductByPT, dispatch),

    // Confirm Order Have MKP
    comfirmOrderMKP: bindActionCreators(OrderState.comfirmOrderMKP, dispatch),
    dispatch,
  }
}
