import React from 'react'
import { Spinner } from 'native-base'
import { View, TouchableOpacity } from 'react-native'
import * as util from 'x/utils/util'
import CONS from 'x/config/constants'
import _ from 'lodash'

import { COLORS, STYLES } from 'x/config/styles'
import p from 'x/config/platform-specific'
// import ReportProductDailySales from '../report/ReportProductDailySales'
// import ReportPayment from '../report/ReportPayment'
// import ReportHelperSales from '../report/ReportHelperSales'
import XIcon from 'xui/components/XIcon'
import BaseImportCustomerView from 'x/modules/importExcel/BaseImportCustomerView'
import XCustomHeader from 'xui/components/XCustomHeader'
// FIXME: react-dropzone ไม่ควรมาอยู่บน xui เพราะ app ไม่รู้จัก

import { VIEW_HEIGHT_TEN } from 'xui/utils/BaseShareUI'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import XCard from 'xui/components/XCard'
import Box from 'xui/components/Box'
import XText from 'xui/components/XText'
import XFooterImportExcel from 'xui/components/XFooterImportExcel'

export default abstract class BaseUIImportAddCustomerView extends BaseImportCustomerView {
  abstract _handleOnDownloadFile(url: string, fileName: string): Promise<void>

  abstract openLinkOrderToWebView(url: string): void

  abstract _renderDropzone(): JSX.Element

  abstract onPressUpload(): void

  renderCustomHeader = () => {
    // const { navigation } = this.props
    // const { mode } = this.state
    // const isEditMode = mode === EDIT
    const leftBtn = { backIcon: true, onPressItem: () => this._goBack() }
    // if (isEditMode) {
    //   // @ts-ignore
    //   leftBtn = { label: `บันทึก`, onPressItem: () => util.setStatePromise(this, { mode: VIEW }) }
    // }
    return (
      <XCustomHeader
        title='เพิ่มลูกค้า'
        headerLeftProps={leftBtn}
        // headerRightProps={{ label: `แก้ไข`, onPressItem: () => util.setStatePromise(this, { mode: EDIT }) }}
      />
    )
  }

  _delayDownloadTemplate = async () => {
    util.setStatePromise(this, {
      disabledDownloadTemplate: true,
    })
    await util.delay(5000)
    util.setStatePromise(this, {
      disabledDownloadTemplate: false,
    })
  }

  _downloadTemplate = async () => {
    this._delayDownloadTemplate()
    const storeId = this.props.selectedStore.get('id')
    const { subscription } = this.props
    if (subscription.get('type') === CONS.SUBSCRIPTION_PACKAGE.FREE) {
      let isDownloadTemplate = false
      await new Promise(resolve => {
        p.op.showConfirmation(
          '',
          'แพ็กเกจของคุณไม่สามารถอัพโหลดไฟล์ได้ ต้องการดาวโหลด Excel เพิ่มสินค้า ต่อหรือไม่',
          () => {
            isDownloadTemplate = true
            // @ts-ignore
            resolve(null)
          },
          () => {
            // @ts-ignore
            resolve(null)
          }
        )
      })
      if (!isDownloadTemplate) {
        return
      }
    }
    const url = util.getAddProductTemplate({
      store_id: storeId,
    })
    // console.log('url => ', url)
    const now = util.getTimeFormatYYYYMMDDhhmmss()
    const fileName = `mass_user_contact_template_${storeId}_${now}.xlsx`
    await this._handleOnDownloadFile(url, fileName)
    // const res = await api.getV2(api.GET_EXCEL_PRODUCT_IMPORT_TEMPLATE, apiOptions)
    // console.log('res => ', res)
    // Linking.openURL(linkDownload)
  }

  _renderDownloadTemplateView = () => {
    const { disabledDownloadTemplate } = this.state
    let textBtnColor = COLORS.TEXT_ACTIVE
    let borderBtnColor = COLORS.APP_MAIN
    if (disabledDownloadTemplate) {
      textBtnColor = COLORS.TEXT_INACTIVE
      borderBtnColor = COLORS.TEXT_INACTIVE
    }
    return (
      <Box w='full' justifyContent='center'>
        <XText variant='active' bold>
          ขั้นตอน:
        </XText>

        <XText variant='active'>1) กดปุ่ม ดาวน์โหลด Excel สำหรับเพิ่มลูกค้า นี้</XText>
        {/* <Text style={{ fontSize: STYLES.FONT_SIZE_LARGER, color: COLORS.APP_MAIN, paddingTop: 4 }}>
          {`หมายเหตุ: สามารถอัพโหลดได้เพียง 30 รายการต่อ 1 ครั้งเท่านั้น`}
        </Text> */}
        {VIEW_HEIGHT_TEN}
        <TouchableOpacity
          disabled={disabledDownloadTemplate}
          onPress={() => this.openLinkOrderToWebView('https://xsf.sgp1.digitaloceanspaces.com/x/tp/import_template_user_contact.xlsx')}
          style={{
            width: 260,
            flexDirection: 'row',
            height: 30,
            borderColor: borderBtnColor,
            borderRadius: 7,
            borderWidth: 0.8,
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf: 'center',
          }}>
          <XIcon
            name='exclefile1'
            family='AntDesign'
            style={{ flex: 0, minWidth: 25, width: 25, color: borderBtnColor, fontSize: STYLES.FONT_ICON_SMALLER }}
          />
          <XText color={textBtnColor}>ดาวน์โหลด Excel เพิ่มลูกค้า</XText>
        </TouchableOpacity>
        <Box h='2' />
        <XText variant='active'>2) กรอกข้อมูลลูกค้าใน Excel</XText>
        <TouchableOpacity onPress={() => this.openLinkOrderToWebView('https://youtu.be/wt0rpqBQM_w')}>
          <XText
            style={{
              fontSize: STYLES.FONT_SIZE_LARGER,
              color: '#0084FF',
              paddingTop: 4,
              textDecorationLine: 'underline',
            }}>
            (กดที่นี่เพื่อดูวิดิโอตัวอย่างการกรอกข้อมูล)
          </XText>
        </TouchableOpacity>
        <XText variant='active'>3) ระบุไฟล์ Excel ที่กรอกเสร็จแล้วด้านล่าง แล้วกดปุ่ม "อัพโหลด"</XText>
        <XText variant='active'>
          3) หมายเหตุ: รายชื่อลูกค้านี้จะมีนำเข้าสำหรับบัญชีผู้ใช้นี้เท่านั้น ซึ่งจะสามารถดูได้จากทุกร้านใน XSelly
        </XText>
      </Box>
    )
  }

  _renderBtnUpload = () => {
    const { fileUpload } = this.state
    const btnBGColor = _.isNil(fileUpload) ? COLORS.BG_LIGHT_GREY : COLORS.APP_MAIN
    const btnBorderColor = _.isNil(fileUpload) ? COLORS.TEXT_INACTIVE : COLORS.APP_MAIN
    const textBtnColor = _.isNil(fileUpload) ? COLORS.TEXT_INACTIVE : COLORS.WHITE
    return (
      <View style={{ width: '100%', height: 100, justifyContent: 'center', alignItems: 'center', backgroundColor: COLORS.WHITE }}>
        <TouchableOpacity
          onPress={() => this.onPressUpload()}
          // onPress={() => null}
          disabled={_.isNil(fileUpload)}
          style={{
            width: 180,
            flexDirection: 'row',
            height: 30,
            borderColor: btnBorderColor,
            backgroundColor: btnBGColor,
            borderRadius: 7,
            borderWidth: 0.8,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <XIcon
            name='upload'
            family='AntDesign'
            style={{ flex: 0, minWidth: 25, width: 25, color: textBtnColor, fontSize: STYLES.FONT_ICON_SMALLER }}
          />
          <XText color={textBtnColor}>อัพโหลด</XText>
        </TouchableOpacity>
      </View>
    )
  }

  renderLoading = () => {
    if (!this.state.isLoading) {
      return null
    }
    return (
      <View
        style={{
          // zIndex: 999,
          position: 'absolute',
          width: '100%',
          height: 800,
          backgroundColor: 'rgba(52, 52, 52, 0.7)',
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0,
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
          marginTop: 0,
        }}>
        <Spinner
          color={COLORS.APP_MAIN}
          size='lg'
          style={{
            width: STYLES.FONT_SIZE_SMALLER,
            height: 34,
            alignSelf: 'center',
            justifyContent: 'center',
            // paddingTop: loadingHeight / 2 - 5,
          }}
        />
      </View>
    )
  }

  renderAfterUpload = () => {
    const { successAddProduct, importErrorColumn, importErrorCode, importErrorRow } = this.state
    let successAddProductText = `เพิ่มสินค้าสำเร็จแล้ว ${successAddProduct} รายการ`
    const errorColumn = !_.isNil(importErrorColumn) ? `column ที่ ${importErrorColumn}` : ``
    const errorRow = !_.isNil(importErrorRow) ? `ลำดับที่ ${importErrorRow}` : ``
    const errorAddProductText = `ไม่สามารถนำเข้าสินค้า${errorRow} ${errorColumn}: ${importErrorCode}
    หากต้องการลองอีกครั้ง ในไฟล์ที่จะแนบมาใหม่ ต้องไม่รวมสินค้าลำดับก่อนหน้าที่นำเข้าสำเร็จไปแล้ว`
    if (!_.isNil(successAddProduct) && successAddProduct === 0) {
      successAddProductText = 'ไม่มีสินค้าที่ถูกเพิ่ม\nกรุณาตรวจสอบความถูกต้องของไฟล์และข้อมูลสินค้าที่ต้องการเพิ่ม แล้วลองใหม่อีกครั้ง'
    }
    return (
      <View style={{ alignItems: 'center', justifyContent: 'center', height: 100 }}>
        {_.isNil(successAddProduct) ? null : (
          <XText
            style={{
              fontSize: STYLES.FONT_SIZE_LARGER_2,
              color: successAddProduct === 0 ? COLORS.APP_MAIN : COLORS.BRAND_Success,
              paddingLeft: 22,
              paddingRight: 14,
            }}>
            {successAddProductText}
          </XText>
        )}

        {_.isNil(importErrorColumn) ? null : (
          <XText
            style={{
              fontSize: STYLES.FONT_SIZE_LARGER_2,
              color: COLORS.BRAND_Danger,
            }}>
            {errorAddProductText}
          </XText>
        )}
      </View>
    )
  }

  _renderBtnReUpload = () => {
    const btnBGColor = COLORS.APP_MAIN
    const btnBorderColor = COLORS.APP_MAIN
    const textBtnColor = COLORS.WHITE
    return (
      <View style={{ width: '100%', height: 100, justifyContent: 'center', alignItems: 'center', backgroundColor: COLORS.WHITE }}>
        <TouchableOpacity
          onPress={() => {
            util.setStatePromise(this, {
              fileUpload: null,
              isLoading: false,
              successAddProduct: null,
              importErrorColumn: null,
              importErrorCode: null,
            })
          }}
          style={{
            width: 180,
            flexDirection: 'row',
            height: 30,
            borderColor: btnBorderColor,
            backgroundColor: btnBGColor,
            borderRadius: 7,
            borderWidth: 0.8,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <XIcon
            name='refresh'
            family='MaterialIcons'
            style={{ flex: 0, minWidth: 25, width: 25, color: textBtnColor, fontSize: STYLES.FONT_ICON_SMALLER }}
          />
          <XText color='white' pl='2'>
            อัพโหลดอีกครั้ง
          </XText>
        </TouchableOpacity>
        {/* <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE, paddingLeft: 4, paddingRight: 4, paddingTop: 6 }}>
          {`(ระบบอาจใช้เวลาประมาณ 40 วินาที ต่อ 1 บัญชี ดังนั้นเมื่อกดปุ่มอัพโหลด กรุณาเปิดหน้าต่างนี้ทิ้งไว้จนกว่าจะเสร็จ)`}
        </Text> */}
      </View>
    )
  }

  renderBody = () => {
    const { successAddProduct } = this.state
    if (_.isNil(successAddProduct)) {
      return (
        <View>
          {this._renderDropzone()}
          {/* {this._renderBtnUpload()} */}
        </View>
      )
    }
    return (
      <View>
        {this.renderAfterUpload()}
        {/* {this._renderBtnReUpload()} */}
      </View>
    )
  }

  // _renderFooter = () => {
  //   const { disabledDownloadTemplate } = this.state
  //   return (
  //     <XButton py='2' onPress={() => this.onPressUpload()} leftIcon={<XIcon name='upload' family='AntDesign' />}>
  //       <XText ml='4' color={COLORS.WHITE}>
  //         อัพโหลด
  //       </XText>
  //     </XButton>
  //   )
  // }

  callBackOnPressFooter = async (result: boolean) => {
    // console.log('result => ', result)
    if (!result) {
      this._stopLoading()
      return
    }
    await util.delay(500)
    this._stopLoading()
    this.onPressUpload()
  }

  render() {
    // console.log('selectedStore 02 => ', p.op.getAppState().toJS())
    const { fileUpload, isLoading } = this.state
    const fileSize = fileUpload && fileUpload.size ? fileUpload.size : null
    // const fileName = fileUpload && fileUpload.name ? fileUpload.name : null
    return (
      <XContainer>
        {this.renderCustomHeader()}
        <XContent p='2'>
          <XCard p='2'>
            {this._renderDownloadTemplateView()}
            {this.renderBody()}
          </XCard>
        </XContent>
        <XFooterImportExcel
          fileSize={fileSize}
          // fileName={fileName}
          callBackOnPress={(result: boolean) => {
            this._startLoading()
            this.callBackOnPressFooter(result)
          }}
          isDisabled={isLoading}
        />
        {/* {this._renderFooter()} */}
        {this.renderLoading()}
      </XContainer>
    )
  }
}

// <XContent
//   style={[
//     NO_MARGIN_PADDING,
//     {
//       flex: 1,
//       flexDirection: 'column',
//       // backgroundColor: 'tomato',
//     },
//   ]} />
