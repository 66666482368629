import React from 'react'

import * as util from 'x/utils/util'
import 'intl/locale-data/jsonp/th.js'
import _ from 'lodash'
// import ExpireTimer from '../ExpireTimer'
import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import XButton from '../XButton'
import XText from '../XText'
import XOverlay from '../XOverlay'
import XCustomHeader from '../XCustomHeader'
import XCard from '../XCard'
import XNumericInput from '../XNumericInput'

interface IOrderListCurrentPageButtonProps {
  page: number // current page
  maxPage: number
  onSubmitNewPage: (newPage: number) => Promise<void>
  disabled?: boolean
}
const OrderListCurrentPageButton: React.FC<IOrderListCurrentPageButtonProps> = ({
  page = 1,
  maxPage = 1,
  onSubmitNewPage,
  disabled = false,
}) => {
  const [isVisible, setIsVisible] = React.useState<boolean>(false)
  const [editingPage, setEditingPage] = React.useState<number>(page)
  const [isSubmiting, setIsSubmiting] = React.useState<boolean>(false)

  const hasOnlyOnePage = page === maxPage && maxPage === 1
  const isInvalidPageNumber = _.isNaN(editingPage) || editingPage < 1 || editingPage > maxPage

  React.useEffect(() => {
    let isMounted = true
    if (isMounted && isVisible && editingPage !== page) {
      setEditingPage(page)
    }

    return () => {
      isMounted = false
    }
  }, [isVisible])

  const _onRequestOpen = () => {
    setIsVisible(true)
  }
  const _onRequestClose = () => {
    setIsVisible(false)
  }

  const _onChangeEditingPage = (newPage: number) => {
    setEditingPage(newPage)
  }

  const _onSubmit = async () => {
    const pageForSubmit = editingPage || null
    if (_.isNil(editingPage)) {
      return
    }
    setIsSubmiting(true)
    await util.delay(200)
    await onSubmitNewPage(pageForSubmit)
    await util.delay(200)
    _onRequestClose()
  }

  return (
    <>
      <XButton isDisabled={disabled || hasOnlyOnePage} disabled={disabled || hasOnlyOnePage} variant='outline' onPress={_onRequestOpen}>
        {page}
      </XButton>
      <XOverlay contentStyle={{ width: 300 }} visible={isVisible} onRequestClose={_onRequestClose}>
        <XCard w='full'>
          <XCustomHeader
            headerRightProps={{
              closeIcon: true,
              submitting: isSubmiting,
              onPressItem: isSubmiting ? undefined : _onRequestClose,
            }}
            title='เปลี่ยนหน้ารายการออเดอร์'
          />
          <VStack p='2' alignItems='center'>
            <XText variant='inactive'>แสดงออเดอร์หน้าที่...</XText>
            <XNumericInput
              textAlign='center'
              placeholder='ระบุเลขหน้า'
              value={editingPage}
              minValue={1}
              maxValue={maxPage}
              onChangeValue={_onChangeEditingPage}
            />
            <XText variant={isInvalidPageNumber ? 'danger' : 'inactive'}>
              {isInvalidPageNumber ? `กรุณาระบุเลขหน้าระหว่าง 1-${maxPage} หน้าปัจจุบัน (${page})` : `หน้าปัจจุบัน (${page})`}
            </XText>
          </VStack>
          <HStack w='full' p='1'>
            <XButton
              w='full'
              isDisabled={disabled || isInvalidPageNumber}
              disabled={disabled || isInvalidPageNumber}
              variant={isInvalidPageNumber ? 'outline' : 'solid'}
              // style={[
              //   // S.FLEX,
              //   disabled || isInvalidPageNumber ? S.BUTTON_OUTLINE : S.BUTTON_OUTLINE_PRIMARY,
              //   disabled || isInvalidPageNumber ? S.BG_LIGHT_GREY : S.BG_PRIMARY,
              //   S.MARGIN_HORIZONTAL_4,
              //   { alignItems: 'center', justifyContent: 'center', flex: 1 },
              // ]}
              onPress={_onSubmit}>
              ยืนยัน
            </XButton>
          </HStack>
        </XCard>
      </XOverlay>
    </>
  )
}

export default OrderListCurrentPageButton
