import React from 'react'
import { View, Text, ImageBackground, Image } from 'react-native'

import _ from 'lodash'

import { ISelectedStoreMap, IProfileMap, IAddress } from 'x/index'
import { useNavigation } from 'x/contexts/useNavigation'
import { STYLES, COLORS } from 'x/config/styles'
import p from 'x/config/platform-specific'

import * as util from 'x/utils/util'
import { ActionApiParams } from 'x/types/Api'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import XCustomHeader from '../../components/XCustomHeader'

const PageLayout: React.FC<{
  onSave: () => void
  doGoBack: () => void
  isLoading?: boolean
  isDirty?: boolean
  fixedContainerWidth?: number
}> = ({ children, onSave, doGoBack, isLoading = false, isDirty = false, fixedContainerWidth }) => {
  const handleWarningDirty = async () => {
    const isUserConfirm = await p.op.isUserConfirm(
      'ละทิ้งการแก้ไข',
      'กรุณายืนยันว่าฉันต้องการละทิ้งการแก้ไขทั้งหมด',
      'ละทิ้ง',
      'กลับไปแก้ไข'
    )
    if (isUserConfirm) {
      doGoBack()
    }
  }
  return (
    <XContainer style={fixedContainerWidth ? { width: fixedContainerWidth } : {}}>
      <XCustomHeader
        // headerStyle={{ marginTop: 0 }}
        headerLeftProps={{
          backIcon: true,
          onPressItem: doGoBack,
          submitting: isLoading,
        }}
        title='บัตรตัวแทน'
      />
      <XContent style={fixedContainerWidth ? { width: fixedContainerWidth } : {}}>{children}</XContent>
    </XContainer>
  )
}

// const getGoBackFunction = navigation => () => navigation.goBack(navigation.state.key)
const getGoBackFunction = (navigation) => () => navigation.goBack()

const ListItem = (icon: JSX.Element, text: string, index: number) => (
  <View style={{ flexDirection: 'row', marginTop: 10 }} key={index}>
    <View style={{ width: 25, height: 30 }}>{icon}</View>
    <Text
      style={{
        fontSize: STYLES.FONT_SIZE_LARGER,
        color: COLORS.TEXT_ACTIVE,
        paddingLeft: 10,
        fontWeight: 'bold',
        alignSelf: 'center',
      }}>
      {text}
    </Text>
  </View>
)

const getProfileAddress = (selectedStore: any) => {
  const addresses = selectedStore.get('addresses').toJS()
  if (_.isNil(addresses) || addresses.length === 0) {
    return null
  }
  let address = null
  addresses.map((addressMap: IAddress) => {
    if (addressMap.is_primary) {
      address = addressMap
    }
  })
  if (_.isNil(address)) {
    address = addresses[0]
  }
  let { district } = address
  if (_.isNil(district)) {
    district = null
  }
  let { province } = address
  if (_.isNil(province)) {
    province = null
  }
  let addr = ''
  if (!_.isNil(district)) {
    addr += district
  }
  if (!_.isNil(province)) {
    if (_.isNil(district)) {
      addr += province
    } else {
      addr = `${addr} ${province}`
    }
  }
  if (addr.length < 1) {
    addr = null
  }
  return addr
}

interface ICardInfoView {
  // navigation: NavigationStackProp<NavigationRoute, ITimeSlotsProps>
  webMaxScreenWidth?: number // web only
  // webNavigation?: NavigationStackProp<NavigationRoute, null> // web only
  webNavigation?: any
  setPrefTimeslot: (params: ActionApiParams) => void
  selectedStore: ISelectedStoreMap
  profile: IProfileMap
}

const CardInfoView: React.FC<ICardInfoView> = ({ webMaxScreenWidth, webNavigation, profile, selectedStore }) => {
  // const channelFromParam = navigation.getParam(`channel`)
  // const callBackUpdateChannel = navigation.getParam(`callBackUpdateChannel`)
  const imgProfile = profile.get('img_uri')
  // const [channel, updateChannel] = useState(channelFromParam)

  const nativeNavigation = useNavigation()
  const navBack = p.op.isWeb() ? getGoBackFunction(webNavigation) : getGoBackFunction(nativeNavigation)

  const STROKE = '-'
  const addresses = selectedStore.get('addresses').toJS()
  // let displayName = !_.isNil(profile.get('display_name')) ? profile.get('display_name') : ''
  let displayName = !_.isNil(addresses) ? addresses[0].name : ''
  if (!_.isNil(profile.get('nick_name'))) {
    displayName += ` (${profile.get('nick_name')})`
  }
  // console.log('selectedStore => ', selectedStore.toJS())
  const resellerCode = util.getOrgResellerCode(selectedStore)
  const lineId = !_.isNil(profile.get('line_id')) ? profile.get('line_id') : STROKE
  const facebookId = !_.isNil(profile.get('fb_id')) ? profile.get('fb_id') : STROKE
  const phone1 = !_.isNil(addresses) ? addresses[0].telephone : STROKE
  const address = getProfileAddress(selectedStore)
  const somsaiCodeId = _.isNil(resellerCode) || !resellerCode ? STROKE : resellerCode
  const resellerType = util.getOrgResellerType(selectedStore)
  // console.log('resellerType ', resellerType)
  let namePadiingTop = 10
  if (_.isNil(displayName)) {
    displayName = STROKE
    namePadiingTop = 10
  }
  // https://app.somsai.co.th/x/resller_card_bg.jpg
  let imageBG = { uri: 'https://app.somsai.co.th/x/resller_card_bg.jpg' }
  if (resellerType === 1) {
    imageBG = { uri: 'https://app.somsai.co.th/x/reseller_card_bg_dealer.jpg' }
  } else if (resellerType === 2) {
    imageBG = { uri: 'https://app.somsai.co.th/x/reseller_card_bg_vip.jpg' }
  } else if (resellerType === 3) {
    imageBG = { uri: 'https://app.somsai.co.th/x/reseller_card_bg_member.jpg' }
  }

  const renderMain = () => (
    <View
      style={{
        width: 320,
        height: 528,
        // paddingTop: 10,
        // paddingLeft: 4,
        // paddingRight: 4,
        // paddingBottom: 10,
        backgroundColor: COLORS.APP_SECONDARY,
        borderRadius: 15,
        marginTop: 10,
        alignSelf: 'center',
        opacity: 50,
      }}>
      <ImageBackground source={imageBG} style={{ flex: 1, justifyContent: 'center' }} imageStyle={{ borderRadius: 15 }}>
        <View style={{ opacity: 100, flex: 1 }}>
          <View style={{ flex: 0, height: 213 }}>
            <Image
              style={{ width: 120, height: 120, borderRadius: 400 / 2, alignSelf: 'center', marginTop: 10 }}
              source={{
                uri: imgProfile,
              }}
            />
          </View>
          <View style={{ width: 200, alignSelf: 'center', flex: 1, paddingLeft: 20, marginTop: 18 }}>
            {/* // NAME */}
            <View style={{ flexDirection: 'row', height: 50, width: 240 }}>
              <Text
                style={{
                  fontSize: STYLES.FONT_SIZE_LARGER,
                  color: COLORS.TEXT_ACTIVE,
                  paddingLeft: 10,
                  fontWeight: 'bold',
                  alignSelf: 'center',
                  paddingTop: namePadiingTop,
                }}>
                {displayName}
              </Text>
            </View>
            {/* // Line */}
            <View style={{ flexDirection: 'row', height: 40 }}>
              <Text
                style={{
                  fontSize: STYLES.FONT_SIZE_LARGER,
                  color: COLORS.TEXT_ACTIVE,
                  paddingLeft: 10,
                  fontWeight: 'bold',
                  alignSelf: 'center',
                  paddingTop: 4,
                }}>
                {lineId}
              </Text>
            </View>
            {/* // Facebook */}
            <View style={{ flexDirection: 'row', height: 50 }}>
              <Text
                style={{
                  fontSize: STYLES.FONT_SIZE_LARGER,
                  color: COLORS.TEXT_ACTIVE,
                  paddingLeft: 10,
                  fontWeight: 'bold',
                  alignSelf: 'center',
                  paddingTop: 2,
                }}>
                {facebookId}
              </Text>
            </View>
            {/* // Phone */}
            <View style={{ flexDirection: 'row', height: 40 }}>
              <Text
                style={{
                  fontSize: STYLES.FONT_SIZE_LARGER,
                  color: COLORS.TEXT_ACTIVE,
                  paddingLeft: 10,
                  fontWeight: 'bold',
                  alignSelf: 'center',
                  paddingTop: 2,
                }}>
                {phone1}
              </Text>
            </View>
            {/* // Lucation */}
            <View style={{ flexDirection: 'row', height: 40, width: 240 }}>
              <Text
                style={{
                  fontSize: STYLES.FONT_SIZE_LARGER,
                  color: COLORS.TEXT_ACTIVE,
                  paddingLeft: 10,
                  fontWeight: 'bold',
                  alignSelf: 'center',
                  paddingTop: 6,
                }}>
                {address}
              </Text>
            </View>
            {/* // Mumber code */}
            <View style={{ flexDirection: 'row', height: 40 }}>
              <Text
                style={{
                  fontSize: STYLES.FONT_SIZE_LARGER,
                  color: COLORS.TEXT_ACTIVE,
                  paddingLeft: 10,
                  fontWeight: 'bold',
                  alignSelf: 'center',
                  paddingTop: 10,
                }}>
                {somsaiCodeId}
              </Text>
            </View>
            {/* {listData.map((data: { icon: JSX.Element; text: string }, index: number) => {
                return ListItem(data.icon, data.text, index)
              })} */}
          </View>
        </View>
      </ImageBackground>
    </View>
  )

  return (
    <PageLayout
      // webMaxScreenWidth / fixedContainerWidth ใช้เพื่อระบุ width สำหรับให้ View react-native-web สามารถรู้ขอบเขตของ flex
      fixedContainerWidth={p.op.isWeb() && _.isNumber(webMaxScreenWidth) ? webMaxScreenWidth : null}
      isLoading={false}
      onSave={null}
      doGoBack={navBack}
      isDirty={false}>
      {renderMain()}
    </PageLayout>
  )
}

// // @ts-ignore disable react-navigation header
// CardInfoView.navigationOptions = {
//   header: null,
// }

export default CardInfoView
