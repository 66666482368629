import { take, takeEvery, put, call, fork, all } from 'redux-saga/effects'
import _ from 'lodash'
import api from '../../utils/api'
import { ActionApiParams } from '../../index'
import p from '../../config/platform-specific'
import { log } from '../../utils/util'
import actions from '../../config/actions'
import * as StoreActions from '../../modules/store/StoreState'
import * as ResellerActions from '../../modules/reseller/ResellerState'

export default function* watchAllUserGroups() {
  yield all([
    fork(watchFetchUserGroup),
    fork(watchCreateUserGroup),
    fork(watchUpdateUserGroup),
    fork(watchDeleteUserGroup),
    fork(watchResetUserGroupInviteCode),
    fork(watchGenerateUserGroupInviteLink),

    fork(watchFetchResellerPendingList),
    fork(watchFetchResellerJoinedList),

    fork(watchChangePendingUsersStatus),
    fork(watchAddNewUserToUG),
    fork(watchKickJoinedUserFromStore),
    fork(watchChangeUserGroupInJoinedUsers),

    fork(watchUserGroupAssignPGsToGroup),
    fork(watchAcceptAllPendingUsers),

    fork(watchUpdateChannel),
    fork(watchDeleteChannel),
    fork(watchFetchChannelProductByPT),
  ])
}

function* watchFetchUserGroup() {
  log('In watchFetchUserGroup')
  while (true) {
    const action = yield take(actions.FETCH_USER_GROUPS)
    yield call(fetchUserGroups, action)
  }
}

function* fetchUserGroups(action: ActionApiParams) {
  const apiOptions = { showSpinner: true }
  try {
    log('In fetchUserGroups body => ', action.body)
    const res = yield call(api.post, api.POST_FETCH_USER_GROUPS, action.body, apiOptions)
    log('In fetchUserGroups response => ', res)
    yield call(loadUserGroupListFromResponse, res)
    if (_.isFunction(action.successCallback)) {
      action.successCallback(res)
    }
    // yield put(NavActions.goBack) // go back to StoreMyView
  } catch (error) {
    log('In fetchUserGroups error => ', error)
    yield put({ type: actions.ERROR_FETCH_USER_GROUPS, error })
    if (_.isFunction(action.failedCallback)) {
      action.failedCallback(error)
    }
  }
}

function* watchCreateUserGroup() {
  log('In watchCreateUserGroup')
  while (true) {
    const action = yield take(actions.CREATE_USER_GROUP)
    yield call(createUserGroup, action)
  }
}

function* createUserGroup(action: ActionApiParams) {
  const apiOptions = {
    messages: {
      successMsg: 'เพิ่มกลุ่มตัวแทนสำเร็จ',
      errorMsg: 'เพิ่มกลุ่มตัวแทนล้มเหลว',
    },
    showSpinner: true,
  }
  try {
    log('In createUser body => ', action.body)
    const res = yield call(api.put, api.PUT_CREATE_USER_GROUP, action.body, apiOptions)
    log('In createUser response => ', res)
    // yield call(loadUserGroupListFromResponse, res)
    yield call(loadNewUserGroupFromResponse, res)
    if (_.isFunction(action.successCallback)) {
      action.successCallback(res)
    }
    // yield put(NavActions.goBack) // go back to StoreMyView
  } catch (error) {
    log('In createUser error => ', error)
    yield put({ type: actions.ERROR_CREATE_USER_GROUP, error })
    if (_.isFunction(action.failedCallback)) {
      action.failedCallback(error)
    }
  }
}

function* watchUpdateUserGroup() {
  log('In watchUpdateUserGroup')
  while (true) {
    const action = yield take(actions.UPDATE_USER_GROUP)
    yield call(updateUserGroup, action)
  }
}

function* updateUserGroup(action: ActionApiParams) {
  const apiOptions = {
    messages: {
      successMsg: 'แก้ไขกลุ่มตัวแทนสำเร็จ',
      errorMsg: 'แก้ไขกลุ่มตัวแทนล้มเหลว',
    },
    showSpinner: true,
  }
  try {
    log('In updateUser body => ', action.body)
    const res = yield call(api.patch, api.PATCH_USER_GROUP, action.body, apiOptions)
    log('In updateUser response => ', res)
    yield call(loadUpdatedUserGroupFromResponse, res)
    if (_.isFunction(action.successCallback)) {
      action.successCallback(res)
    }
    // yield put(NavActions.goBack) // go back to StoreMyView
  } catch (error) {
    log('In updateUser error => ', error)
    yield put({ type: actions.ERROR_UPDATE_USER_GROUP, error })
    if (_.isFunction(action.failedCallback)) {
      action.failedCallback(error)
    }
  }
}

function* watchDeleteUserGroup() {
  log('In watchDeleteUserGroup')
  while (true) {
    const action = yield take(actions.DELETE_USER_GROUP)
    yield call(deleteUserGroup, action)
  }
}

function* deleteUserGroup(action: ActionApiParams) {
  const apiOptions = {
    messages: {
      successMsg: 'ลบกลุ่มตัวแทนสำเร็จ',
      errorMsg: 'ลบกลุ่มตัวแทนล้มเหลว',
    },
    showSpinner: true,
  }
  try {
    log('In deleteUser body => ', action.body)
    const res = yield call(api.del, api.DELETE_USER_GROUP, action.body, apiOptions)
    log('In deleteUser response => ', res)
    yield call(clearRemovedUserGroupFromResponse, res, action.body)
    if (_.isFunction(action.successCallback)) {
      action.successCallback(res)
    }
  } catch (error) {
    log('In deleteUser error => ', error)
    yield put({ type: actions.ERROR_DELETE_USER_GROUP, error })
    if (_.isFunction(action.failedCallback)) {
      action.failedCallback(error)
    }
  }
}

function* watchResetUserGroupInviteCode() {
  log('In watchResetUserGroupInviteCode')
  while (true) {
    const action = yield take(actions.RESET_USER_GROUP_INVITE_CODE)
    yield call(resetUserGroupInviteCode, action)
  }
}

function* resetUserGroupInviteCode(action: ActionApiParams) {
  const apiOptions = {
    messages: {
      successMsg: 'เปลี่ยนรหัสขอเข้าร่วมใหม่สำเร็จ',
      errorMsg: 'เปลี่ยนรหัสขอเข้าร่วมใหม่ล้มเหลว',
    },
    showSpinner: true,
  }
  try {
    log('In resetUserGroupInviteCode body => ', action.body)
    const res = yield call(api.patch, api.PATCH_USER_GROUP_RESET_INVITE_CODE, action.body, apiOptions)
    log('In resetUserGroupInviteCode response => ', res)
    // yield call(loadUserGroupListFromResponse, res)
    if (_.isFunction(action.successCallback)) {
      action.successCallback(res)
    }
    // yield put(NavActions.goBack) // go back to StoreMyView
  } catch (error) {
    log('In resetUserGroupInviteCode error => ', error)
    yield put({ type: actions.ERROR_RESET_USER_GROUP_INVITE_CODE, error })
    // @ts-ignore
    if (error && error.error && error.error.attach) {
      // yield call (loadUserGroupListFromResponse, error.error.attach)
    }
    if (_.isFunction(action.failedCallback)) {
      action.failedCallback(error)
    }
  }
}

function* watchGenerateUserGroupInviteLink() {
  log('In watchGenerateUserGroupInviteLink')
  while (true) {
    const action = yield take(actions.GENERATE_USER_GROUP_INVITE_LINK)
    yield call(generateUserGroupInviteLink, action)
  }
}

function* generateUserGroupInviteLink(action: ActionApiParams) {
  const apiOptions = {
    // messages: {
    //   successMsg: 'สร้างลิงก์ขอเข้าร่วมสำเร็จ',
    //   errorMsg: 'สร้างลิงก์ขอเข้าร่วมล้มเหลว',
    // },
    showSpinner: true,
  }
  try {
    log('In generateUserGroupInviteLink body => ', action.body)
    const res = yield call(api.post, api.POST_GENERATE_USER_GROUP_INVITE_LINK, action.body, apiOptions)
    log('In generateUserGroupInviteLink response => ', res)
    yield call(loadUpdatedUserGroupFromResponse, res)
    if (_.isFunction(action.successCallback)) {
      action.successCallback(res)
    }
  } catch (error) {
    log('In generateUserGroupInviteLink error => ', error)
    yield put({ type: actions.ERROR_GENERATE_USER_GROUP_INVITE_LINK, error })
    // @ts-ignore
    if (error && error.error && error.error.attach) {
      // yield call (loadUserGroupListFromResponse, error.error.attach)
    }
    if (_.isFunction(action.failedCallback)) {
      action.failedCallback(error)
    }
  }
}

function* loadNewUserGroupFromResponse(res) {
  if (_.has(res, 'user_group')) {
    log('loadNewUserGroupFromResponse user_group => ', res.user_group)
    yield put(StoreActions.loadNewUserGroup(res.user_group))
  }
  // yield call(loadUserGroupListUpdatedAtFromResponse, res)
}

function* loadUpdatedUserGroupFromResponse(res) {
  if (_.has(res, 'user_group')) {
    log('loadUpdatedUserGroupFromResponse user_group => ', res.user_group)
    yield put(StoreActions.loadUpdatedUserGroup(res.user_group))
  }
  // yield call(loadUserGroupListUpdatedAtFromResponse, res)
}

function* clearRemovedUserGroupFromResponse(res, requestBody) {
  if (_.has(res, 'status') && res.status === 'ok') {
    log('clearRemovedUserGroupFromResponse res => ', res)
    log('clearRemovedUserGroupFromResponse requestBody => ', requestBody)
    const { ug_id } = requestBody
    yield put(StoreActions.clearRemovedUserGroupFromListById(ug_id))
  }
  // yield call(loadUserGroupListUpdatedAtFromResponse, res)
}

function* loadUserGroupListFromResponse(res) {
  if (_.has(res, 'user_groups')) {
    log('res has user_groups => ', res.user_groups)
    yield put(StoreActions.loadUserGroups(res.user_groups))
  }
  // yield call(loadUserGroupListUpdatedAtFromResponse, res)
}

// function* loadUserGroupListUpdatedAtFromResponse(res) {
//   if (_.has(res, 'pgs_updated_at')) {
//     log('loadUserGroupListUpdatedAtFromResponse pgs_updated_at => ', res.pgs_updated_at)
//     yield put(StoreActions.loadUserGroupsUpdatedAt(res.pgs_updated_at))
//   }
// }

// reseller list
function* watchFetchResellerPendingList() {
  log('In watchFetchResellerPendingList')
  // while (true) {
  //   const action = yield take(actions.RESELLER_PENDING_FETCH)
  //   yield put(ResellerActions.setResellerListIsFetching(action.type))
  //   yield call(fetchResellerList, action)
  // }
  yield takeEvery(actions.RESELLER_PENDING_FETCH, prepareFetchResellerList)
}
function* watchFetchResellerJoinedList() {
  log('In watchFetchResellerJoinedList')
  yield takeEvery(actions.RESELLER_JOINED_FETCH, prepareFetchResellerList)
}

function* prepareFetchResellerList(action: ActionApiParams) {
  if (!action.type) {
    throw new Error('prepareFetchResellerList cannot done without action type.')
  }
  // yield put(ResellerActions.setResellerListIsFetching(action.type))
  yield put(ResellerActions.setResellerListIsFetching(action))
  yield call(fetchResellerList, action)
}

function* fetchResellerList(action: ActionApiParams) {
  log('In fetchResellerList action => ', action)
  const apiOptions = {
    // messages: {
    //   successMsg: 'เปลี่ยนรหัสขอเข้าร่วมใหม่สำเร็จ',
    //   errorMsg: 'เปลี่ยนรหัสขอเข้าร่วมใหม่ล้มเหลว',
    // },
    // showSpinner: true,
  }
  try {
    log('In fetchResellerList action.body => ', action.body)
    // const res = yield call(api.post, endpoint, body, apiOptions)
    const res = yield call(api.post, api.POST_USER_GROUP_USER_LIST, action.body, apiOptions)
    log('In fetchResellerList response => ', res)
    yield call(loadResellerListFromResponse, res, action)
    // yield call(loadUserGroupListFromResponse, res)
    if (_.isFunction(action.successCallback)) {
      action.successCallback(res)
    }
    // yield put(NavActions.goBack) // go back to StoreMyView
  } catch (error) {
    log('In fetchResellerList error => ', error)
    yield put({ type: actions.ERROR_RESELLER_FETCH, error })
    // @ts-ignore
    if (error && error.error && error.error.attach) {
      // yield call (loadUserGroupListFromResponse, error.error.attach)
    }
    if (_.isFunction(action.failedCallback)) {
      action.failedCallback(error)
    }
  }
}

function* loadResellerListFromResponse(res: { [key: string]: any }, action: ActionApiParams) {
  if (_.has(res, 'users')) {
    // log('loadResellerListFromResponse pending_users => ', res.pending_users)
    yield put(ResellerActions.loadResellerGenericList(res, action))
  }
}

function* watchChangePendingUsersStatus() {
  log('In watchChangePendingUsersStatus')
  // while (true) {
  //   const action = yield take(actions.CHANGE_PENDING_USERS_STATUS)
  //   yield call(changePendingUsersStatus, action)
  // }

  yield takeEvery(actions.CHANGE_PENDING_USERS_STATUS, prepareChangePendingUserStatus)
}

function* prepareChangePendingUserStatus(action: ActionApiParams) {
  log('In prepareChangePendingUserStatus action => ', action)
  if (!action.body || !_.has(action.body, 'profile_id')) {
    throw new Error('Cannot find profile_id in body')
  }
  yield put(ResellerActions.setSubmittingBeforeChangeUserPendingStatus(action.body.profile_id))
  yield call(changePendingUsersStatus, action)
}

function* changePendingUsersStatus(action: ActionApiParams) {
  try {
    log('In changePendingUsersStatus body => ', action.body)
    const res = yield call(api.post, api.POST_CHANGE_PENDING_USERS_STATUS, action.body)
    log('In changePendingUsersStatus response => ', res)
    yield call(handleResponseAfterChangeUsersStatus, res, action)
    if (_.isFunction(action.successCallback)) {
      action.successCallback(res)
    }
    // yield put(NavActions.goBack) // go back to StoreMyView
  } catch (error) {
    log('In changePendingUsersStatus error => ', error)
    yield put({ type: actions.ERROR_CHANGE_PENDING_USERS_STATUS, error })
    // @ts-ignore
    if (error && error.error && error.error.attach) {
      // yield call (loadUserGroupListFromResponse, error.error.attach)
    }
    if (_.isFunction(action.failedCallback)) {
      action.failedCallback(error)
    }
  }
}

function* watchAddNewUserToUG() {
  log('In watchAddNewUserToUG')
  while (true) {
    const action = yield take(actions.ADD_NEW_USER_TO_UG)
    yield call(addNewUserToUG, action)
  }
}

function* addNewUserToUG(action: ActionApiParams) {
  try {
    log('In addNewUserToUG body => ', action.body)
    const res = yield call(api.put, api.PUT_NEW_USER_TO_UG, action.body)
    log('In addNewUserToUG response => ', res)
    yield call(handleResponseAfterChangeUsersStatus, res, action)
    if (_.isFunction(action.successCallback)) {
      action.successCallback(res)
      p.op.showToast('เพิ่มตัวแทนเข้ากลุ่มเรียบร้อยแล้ว', 'success', 200)
    }
  } catch (error) {
    log('In addNewUserToUG error => ', error)
    yield put({ type: actions.ERROR_ADD_NEW_USER_TO_UG, error })
    // @ts-ignore
    if (error && error.error && error.error.attach) {
      // yield call (loadUserGroupListFromResponse, error.error.attach)
    }
    if (_.isFunction(action.failedCallback)) {
      action.failedCallback(error)
    }
  }
}

function* watchKickJoinedUserFromStore() {
  log('In watchKickJoinedUserFromStore')
  while (true) {
    const action = yield take(actions.KICK_JOINED_USER_FROM_STORE)
    yield call(kickJoinedUserFromStore, action)
  }
}

function* kickJoinedUserFromStore(action: ActionApiParams) {
  try {
    const apiOptions = {
      messages: {
        successMsg: 'การไล่ผู้ใช้ออกจากร้านเสร็จสิ้น',
        errorMsg: 'การไล่ผู้ใช้ออกจากร้านร้านล้มเหลว',
      },
      showSpinner: true,
    }
    log('In kickJoinedUserFromStore body => ', action.body)
    const res = yield call(api.del, api.DELETE_RESELLER, action.body, apiOptions)
    log('In kickJoinedUserFromStore response => ', res)
    yield call(handleResponseAfterKickUserFromStore, res, action)
    if (_.isFunction(action.successCallback)) {
      action.successCallback(res)
    }
  } catch (error) {
    log('In kickJoinedUserFromStore error => ', error)
    yield put({ type: actions.ERROR_KICK_JOINED_USER_FROM_STORE, error })
    // @ts-ignore
    if (error && error.error && error.error.attach) {
      // yield call (loadUserGroupListFromResponse, error.error.attach)
    }
    if (_.isFunction(action.failedCallback)) {
      action.failedCallback(error)
    }
  }
}

function* watchChangeUserGroupInJoinedUsers() {
  log('In watchChangeUserGroupInJoinedUsers')
  while (true) {
    const action = yield take(actions.CHANGE_USER_GROUP_IN_JOINED_USERS)
    yield call(changeUserGroupInJoinedUsers, action)
  }
}

function* changeUserGroupInJoinedUsers(action: ActionApiParams) {
  try {
    const apiOptions = {
      messages: {
        successMsg: 'การเปลี่ยนกลุ่มของสมาชิกเสร็จสิ้น',
        errorMsg: 'การเปลี่ยนกลุ่มของสมาชิกล้มเหลว',
      },
      showSpinner: true,
      axiosOptions: {
        retry: 0,
        timeout: 60000,
      },
    }
    log('In changeUserGroupInJoinedUsers body => ', action.body)
    const res = yield call(api.post, api.POST_RESELLER_CHANGE_USER_GROUP, action.body, apiOptions)
    log('In changeUserGroupInJoinedUsers response => ', res)
    yield call(handleResponseAfterChangeUserGroupInJoinedUsers, res, action)
    if (_.isFunction(action.successCallback)) {
      action.successCallback(res)
    }
  } catch (error) {
    log('In changeUserGroupInJoinedUsers error => ', error)
    yield put({ type: actions.ERROR_CHANGE_USER_GROUP_IN_JOINED_USERS, error })
    // @ts-ignore
    if (error && error.error && error.error.attach) {
      // yield call (loadUserGroupListFromResponse, error.error.attach)
    }
    if (_.isFunction(action.failedCallback)) {
      action.failedCallback(error)
    }
  }
}

function* handleResponseAfterChangeUserGroupInJoinedUsers(res: { [key: string]: any }, action: ActionApiParams) {
  const isSuccessful = _.has(res, 'status') && res.status === 'ok' && action.body && action.body.profile_id
  if (isSuccessful) {
    yield put(ResellerActions.setIsChangedGroupUser(action))
  }
}

function* handleResponseAfterKickUserFromStore(res: { [key: string]: any }, action: ActionApiParams) {
  // log('handleResponseAfterKickUserFromStore res ', res)
  // log('handleResponseAfterKickUserFromStore action ', action)
  const isSuccessful = _.has(res, 'status') && res.status === 'ok' && action.body && action.body.profile_id
  if (isSuccessful) {
    yield put(ResellerActions.setIsKickedUser(action))
  }
}

function* handleResponseAfterChangeUsersStatus(res: { [key: string]: any }, action: ActionApiParams) {
  const isSuccessful = _.has(res, 'status') && res.status === 'ok' && action.body && action.body.newStatus

  if (isSuccessful) {
    // log('handleResponseAfterChangeUsersStatus isSuccessfulReject ', res.pending_users)
    yield put(ResellerActions.setApprovedOrRejectedUserStatusToList(action))
  }
}

function* watchUserGroupAssignPGsToGroup() {
  log('In watchUserGroupAssignPGsToGroup')
  while (true) {
    const action = yield take(actions.ASSIGN_PGS_TO_USER_GROUP)
    yield call(userGroupAssignPGsToGroup, action)
  }
}

function* userGroupAssignPGsToGroup(action: ActionApiParams) {
  try {
    const apiOptions = {
      messages: {
        successMsg: 'การผูกรายการราคาเข้าสู่กลุ่มสมาชิกเสร็จสิ้น',
        errorMsg: 'การผูกรายการราคาเข้าสู่กลุ่มสมาชิกล้มเหลว',
      },
      showSpinner: true,
      axiosOptions: {
        retry: 0,
        timeout: 30000, // long fetch 30 seconds with no retry
      },
    }
    log('In userGroupAssignPGsToGroup body => ', action.body, apiOptions)
    const res = yield call(api.post, api.POST_UG_ASSIGN_PGS, action.body)
    log('In userGroupAssignPGsToGroup response => ', res)
    yield call(handleResponseAfterUserGroupsSyncProductGroups, res, action)
    if (_.isFunction(action.successCallback)) {
      action.successCallback(res)
    }
  } catch (error) {
    log('In userGroupAssignPGsToGroup error => ', error)
    yield put({ type: actions.ERROR_ADD_NEW_USER_TO_UG, error })
    // @ts-ignore
    if (error && error.error && error.error.attach) {
      // yield call (loadUserGroupListFromResponse, error.error.attach)
    }
    if (_.isFunction(action.failedCallback)) {
      action.failedCallback(error)
    }
  }
}

function* handleResponseAfterUserGroupsSyncProductGroups(res: { [key: string]: any }, action: ActionApiParams) {
  // log('handleResponseAfterUserGroupsSyncProductGroups res ', res)
  // log('handleResponseAfterUserGroupsSyncProductGroups action ', action)
  const isSuccessful = _.has(res, 'status') && res.status === 'ok' && action.body && action.body.ug_id && action.body.pgs
  if (isSuccessful) {
    yield put(StoreActions.doneAssignedPgsToUserGroup(action))
  }
}

function* watchAcceptAllPendingUsers() {
  log('In watchAcceptAllPendingUsers')
  while (true) {
    const action = yield take(actions.ACCEPT_ALL_PENDING_USERS)
    yield call(acceptAllPendingUsers, action)
  }
}

function* acceptAllPendingUsers(action: ActionApiParams) {
  const apiOptions = {
    messages: {
      successMsg: 'การอนุญาติผู้สมัครสมาชิกทั้งหมดเสร็จสิ้น',
      errorMsg: 'การอนุญาติผู้สมัครสมาชิกล้มเหลว',
    },
    showSpinner: true,
  }
  try {
    log('In acceptAllPendingUsers body => ', action.body)
    const res = yield call(api.post, api.POST_ACCEPT_ALL_PENDING_USERS, action.body, apiOptions)
    log('In acceptAllPendingUsers response => ', res)
    // yield call(handleResponseAfterAcceptedAllPendingUsers, res, action)
    yield call(handleResponseAfterAcceptedAllPendingUsers, res)
    if (_.isFunction(action.successCallback)) {
      action.successCallback(res)
    }
  } catch (error) {
    log('In acceptAllPendingUsers error => ', error)
    yield put({ type: actions.ERROR_ACCEPT_ALL_PENDING_USERS, error })
    // @ts-ignore
    if (error && error.error && error.error.attach) {
      // yield call (loadUserGroupListFromResponse, error.error.attach)
    }
    if (_.isFunction(action.failedCallback)) {
      action.failedCallback(error)
    }
  }
}

function* handleResponseAfterAcceptedAllPendingUsers(res: { [key: string]: any }) {
  // log('handleResponseAfterAcceptedAllPendingUsers res ', res)
  const isSuccessful = _.has(res, 'status') && res.status === 'ok'
  if (isSuccessful) {
    yield put(ResellerActions.doneAcceptedAllPendingUsers())
    yield put(StoreActions.shouldFetchUserGroups())
  }
}

function* watchUpdateChannel() {
  while (true) {
    const action = yield take(actions.UPDATE_MKP_CHANNEL)
    yield call(updateChannel, action)
  }
}

function* updateChannel(action: ActionApiParams) {
  try {
    const res = yield call(api.postV2, api.POST_UPDATE_MKP_CHANNEL_DETAIL, action.body)
    if (_.isFunction(action.successCallback)) {
      action.successCallback(res)
    }
  } catch (error) {
    log('In updateUser error => ', error)
    if (_.isFunction(action.failedCallback)) {
      action.failedCallback(error)
    }
  }
}

function* watchDeleteChannel() {
  while (true) {
    const action = yield take(actions.DELETE_USER_GROUP)
    yield call(deleteChannel, action)
  }
}

function* deleteChannel(action: ActionApiParams) {
  try {
    const res = yield call(api.del, api.DELETE_USER_GROUP, action.body)
    if (_.isFunction(action.successCallback)) {
      action.successCallback(res)
    }
  } catch (error) {
    if (_.isFunction(action.failedCallback)) {
      action.failedCallback(error)
    }
  }
}

function* watchFetchChannelProductByPT() {
  while (true) {
    const action = yield take(actions.FETCH_CHANNEL_PRODUCT_BY_PT)
    yield call(fetchChannelProductByPT, action)
  }
}

function* fetchChannelProductByPT(action: ActionApiParams) {
  try {
    const res = yield call(api.postV2, api.POST_FETCH_MKP_PRODUCT_BY_PT, action.body)
    if (_.isFunction(action.successCallback)) {
      action.successCallback(res)
    }
  } catch (error) {
    log('In updateUser error => ', error)
    if (_.isFunction(action.failedCallback)) {
      action.failedCallback(error)
    }
  }
}
