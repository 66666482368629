import React from 'react'
import { ICommonLogoOptionalProps } from './CommonLogo'
import FreePackageTag from './FreePackageTag'
import PlatinumPackageTag from './PlatinumPackageTag'
import GoldPackageTag from './GoldPackageTag'
import SilverPackageTag from './SilverPackageTag'
import BronzePackageTag from './BronzePackageTag'
import TrialPackageTag from './TrialPackageTag'

interface IMkpLogoProps extends ICommonLogoOptionalProps {
  typeId: number
}
const PackageTag = (props: IMkpLogoProps) => {
  switch (props.typeId) {
    case 1:
      return <FreePackageTag {...props} />
    case 2:
      return <BronzePackageTag {...props} />
    case 3:
      return <SilverPackageTag {...props} />
    case 4:
      return <GoldPackageTag {...props} />
    case 5:
      return <PlatinumPackageTag {...props} />
    case 999:
      return <TrialPackageTag {...props} />
    default:
      return <FreePackageTag {...props} />
  }
}

export default PackageTag
