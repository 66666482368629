/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/no-unused-state */
/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react'
import { Dimensions } from 'react-native'
import _ from 'lodash'
// import { COLORS, STYLES } from '../../config/styles'
import xCONS from 'x/config/constants'
import dayjs, { Dayjs } from 'dayjs'
import { setStatePromise, delay } from 'x/utils/util'
import * as xAcl from 'x/utils/acl'
import api from 'x/utils/api'
import * as util from 'x/utils/util'
import { Map } from 'immutable'
import { COLORS } from '../../config/styles'
import p from '../../config/platform-specific'
import * as xFmt from '../../utils/formatter'
// import { log, setStatePromise, delay } from '../../utils/util'
import * as acl from '../../utils/acl'
import { ActionApiParams, IMap, ISelectedStoreMap, IXScreenProps } from '../../index'

const { APP_MAIN, TEXT_ACTIVE_DARK, BRAND_Info, BRAND_Success } = COLORS

export interface IBaseDailySalesReportViewProps extends IXScreenProps {
  // chartData: Map<string, any>
  chartData: IResponseChartDataMap
  selectedStore: ISelectedStoreMap
  subscription: any
  // fetchDailySalesReport: (body: { store_id: number; daysInPast?: number; showToday?: boolean }) => void
  fetchDailySalesReport: (params: ActionApiParams) => void
}

export type IResponseChartDataMap = IMap<IResponseChartData>
export interface IResponseChartData {
  store_id: number
  date: string
  today: IResponseChartItem
  past: IResponseChartItem[]
}

interface IResponseChartItem {
  p: number
  f: number
  o: number
  d: string
}

export interface IChartData {
  x: string
  y: number
}

export interface IChart {
  key: string
  key_x: string
  key_y: string
  chartType: boolean
  data: IChartData[]
  chartNote?: string
  activeBgColor?: string
  label_y: string
  label_x: string
  parseSumToShowFunction: (sumValue: string) => string
  parseFunctionToTickLabelX: (x: string) => Date
  parseFunctionToTickLabelY: (y: string) => Date
  parseFunctionToAverageData: (avg: string) => string
}

export interface ITodayInfo {
  sales_amount: number | string
  profit_amount: number | string
  order_count: number | string
}

export interface IBaseDailySalesReportViewState {
  displayTodayInfo: ITodayInfo
  displayCharts: IChart[]
  refreshing: boolean
  selectedChartIndex: number
  selectedOptionTimePeriod: string
  selectedOptionTimePeriodIndex: number

  selectedDateRange: { begin: Dayjs; end: Dayjs }

  victoryChartWidth: number
  chartData: IResponseChartData
}

export interface ICHART_DATA_PROPERTIES {
  key: string
  key_x: string
  key_y: string
  label_x: string
  label_y: string
  activeColor: string
  activeBgColor: string
  chartType: 'line'
  // parseFunctionToTickLabelX: (x: string) => Date
  parseFunctionToTickLabelX: (x: string) => string
  parseFunctionToTickLabelY: (y: string) => string
  parseFunctionToAverageData: (avg: string) => string
  parseSumToShowFunction: (sumValue: string) => string // parse Y Value
  chartNote: string
}

export default abstract class BaseDailySalesReportView extends React.Component<
  IBaseDailySalesReportViewProps,
  IBaseDailySalesReportViewState
> {
  OPTIONS_TIME_PERIOD: string[]

  CHART_DATA_PROPERTIES: ICHART_DATA_PROPERTIES[]

  TXT_WARNING_DATE_RANGE: string

  SELECTED_DATE: boolean

  TIME_PERIOD: number

  FIRST_TIME_SELECTED_DATE: boolean

  FIRST_TIME_SELECTED_DATE_FEOM_CHOICE: number

  inProcess: boolean

  deviceWidth: number

  chartWidth: number

  abstract _getParams(): { store_id: number } | null

  constructor(props) {
    super(props)
    this.CHART_DATA_PROPERTIES = [
      {
        key: 'sales_amount',
        key_x: 'd',
        key_y: 'p',
        label_x: 'วันที่',
        label_y: 'ยอดขาย',
        activeColor: TEXT_ACTIVE_DARK,
        activeBgColor: BRAND_Info,
        chartType: 'line',
        parseFunctionToTickLabelX: (x: string) => dayjs(x, xCONS.REPORT_DAILY_DATE_FORMAT).format('Do MMM'),
        parseFunctionToTickLabelY: (y: string) => xFmt.formatCurrency(y),
        parseFunctionToAverageData: (avg: string) => xFmt.formatCurrency(parseInt(avg, 10)),
        parseSumToShowFunction: (sumValue) => xFmt.formatCurrency(sumValue), // parse Y Value
        chartNote: '*หมายเหตุ: เป็นข้อมูลจากยอดของออเดอร์ขาย โดยไม่รวมค่าส่ง',
      },
      {
        key: 'profit_amount',
        key_x: 'd',
        key_y: 'f',
        label_x: 'วันที่',
        label_y: 'กำไร',
        activeColor: TEXT_ACTIVE_DARK,
        activeBgColor: BRAND_Success,
        chartType: 'line',
        parseFunctionToTickLabelX: (x: string) => dayjs(x, xCONS.REPORT_DAILY_DATE_FORMAT).format('Do MMM'),
        parseFunctionToTickLabelY: (y: string) => xFmt.formatCurrency(y),
        parseFunctionToAverageData: (avg: string) => xFmt.formatCurrency(parseInt(avg, 10)),
        parseSumToShowFunction: (sumValue) => xFmt.formatCurrency(sumValue), // parse Y Value
        chartNote: '*หมายเหตุ: เป็นกำไรของออเดอร์ขาย โดยไม่รวมค่าส่ง',
      },
      {
        key: 'order_count',
        key_x: 'd',
        key_y: 'o',
        label_x: 'วันที่',
        label_y: 'ออเดอร์ขาย',
        activeColor: TEXT_ACTIVE_DARK,
        activeBgColor: APP_MAIN,
        chartType: 'line',
        // chartType: 'bar',
        parseFunctionToTickLabelX: (x: string) => dayjs(x).format('Do MMM'),
        parseFunctionToTickLabelY: (y: string) => y,
        parseFunctionToAverageData: (avg: string) => avg,
        parseSumToShowFunction: null, // parse Y Value
        chartNote: '*หมายเหตุ: เป็นจำนวนของออเดอร์ขายที่ไม่ถูกยกเลิก',
      },
    ]

    this.OPTIONS_TIME_PERIOD = ['7 วัน', '30 วัน', 'ระบุ']
    this.TXT_WARNING_DATE_RANGE = 'แพ็กเกจปัจจุบันของคุณไม่สามารถเลือกช่วงเวลาเพื่อดูรายการจัดส่งย้อนหลังได้'
    this.SELECTED_DATE = false
    this.TIME_PERIOD = 7
    this.FIRST_TIME_SELECTED_DATE = true
    this.FIRST_TIME_SELECTED_DATE_FEOM_CHOICE = -1

    this.inProcess = false

    this.deviceWidth = Dimensions.get('window').width
    this.chartWidth = this.deviceWidth - 30

    this.state = {
      displayTodayInfo: {
        sales_amount: 0,
        profit_amount: 0,
        order_count: 0,
      },
      displayCharts: [],
      refreshing: false,

      selectedChartIndex: 0,
      selectedOptionTimePeriod: this.OPTIONS_TIME_PERIOD[0],
      selectedOptionTimePeriodIndex: 0,

      selectedDateRange: { begin: dayjs().subtract(1, 'days'), end: dayjs() },

      victoryChartWidth: 0,
      chartData: null,
    }

    // this.setSelectedOptionTimePeriod = this.setSelectedOptionTimePeriod.bind(this)
  }

  componentDidMount() {
    // this.initState()
    const canDo =
      xAcl.canDoAtSelectedStore(xCONS.PERM_STORE_HELPER.PRODUCT_STOCK_VIEW) &&
      xAcl.canDoAtSelectedStore(xCONS.PERM_STORE_HELPER.PRODUCT_COST)
    if (this.isAuthenticated() || canDo) {
      // this.fetch()
      this._onSegmentPastTimePeriodChange(0)
    } else {
      p.op.showConfirmationOkOnly('ไม่สามารถดูข้อมูลได้', 'เนื่องจากขาดสิทธิ์ในการใช้งาน', () => {
        // log('yoooo')
      })
      util.navGoBack(this.props)
    }
  }

  isAuthenticated = (): boolean => acl.isSelectedStoreOwner()

  fetch = async (additionBody?: { [key: string]: any }) => {
    const { selectedStore } = this.props
    const selectedStoreId = selectedStore.get('id')
    if (_.isNumber(selectedStoreId)) {
      let body: any = { store_id: selectedStoreId, showToday: true }
      if (!_.isEmpty(additionBody)) {
        body = { ...body, ...additionBody }
      }
      const apiOptions = {
        showSpinner: true,
      }
      const res: IResponseChartData = await api.post(api.POST_REPORT_DAILY_SALES, body, apiOptions)
      if (res.store_id) {
        /// START
        const { date, past } = res // default past.length = 7

        // generate empty data if backend doesn't return
        // log('reqBody =>', reqBody)
        // log('res =>', res)
        // if (_.isNil(reqBody.endDate) && _.isNil(reqBody.daysInPast)) {
        //   return
        // }
        // console.log(reqBody)
        let todayDate = date ? dayjs(date, xCONS.REPORT_DAILY_DATE_FORMAT) : dayjs()
        let daysInPast = body.daysInPast && body.daysInPast > 0 ? body.daysInPast : 7
        let beginDate = todayDate.clone().subtract(daysInPast, 'd')

        if (!_.isNil(body.endDate)) {
          todayDate = dayjs(body.startDate, xCONS.REPORT_DAILY_DATE_FORMAT)
          daysInPast = dayjs(body.endDate).diff(body.startDate, 'day') + 1
          beginDate = dayjs(body.startDate, xCONS.REPORT_DAILY_DATE_FORMAT)
        }

        // console.log('!!! todayDate => ', todayDate)
        // console.log('!!! daysInPast => ', daysInPast)
        // console.log('!!! beginDate => ', beginDate)
        // console.log('past.length => ', past.length)
        const preparedPastData: IResponseChartItem[] = []
        let dateToIndexMap: Map<string, number> = Map({})
        const modifiedResponse = _.cloneDeep(res)

        // Generated empty report data and Modify old res before load if necessary
        if (past && past.length < daysInPast) {
          for (let i = 0; i < daysInPast; i++) {
            const txtFocusedDate = beginDate.clone().add(i, 'day').format(xCONS.REPORT_DAILY_DATE_FORMAT)
            // console.log('loop index i =>', i, 'txtFocusedDate => ', txtFocusedDate)
            const emptyData = { p: 0, f: 0, o: 0, d: txtFocusedDate }
            dateToIndexMap = dateToIndexMap.set(txtFocusedDate, i)
            preparedPastData.push(emptyData)
          }

          // log('dateToIndexMap => ', dateToIndexMap.toJS())

          past.forEach((pData) => {
            const preparedDataIndex = dateToIndexMap.get(pData.d)
            preparedPastData[preparedDataIndex] = pData
            preparedPastData[preparedDataIndex].p = _.isString(pData.p) ? parseFloat(pData.p) : pData.p
            preparedPastData[preparedDataIndex].f = _.isString(pData.f) ? parseFloat(pData.f) : pData.f
            preparedPastData[preparedDataIndex].o = _.isString(pData.o) ? parseFloat(pData.o) : pData.o
            // preparedPastData[preparedDataIndex].p = _.isString(pData.p) ? parseFloat(pData.p.replace(/,/g, '')) : pData.p
            // preparedPastData[preparedDataIndex].f = _.isString(pData.f) ? parseFloat(pData.f.replace(/,/g, '')) : pData.f
            // preparedPastData[preparedDataIndex].o = _.isString(pData.o) ? parseFloat(pData.o.replace(/,/g, '')) : pData.o
          })

          // log('preparedPastData before sort => ', preparedPastData)
          // preparedPastData.sort((d1, d2) => {
          //   const date1 = dayjs(d1.d, xCONS.REPORT_DAILY_DATE_FORMAT)
          //   const date2 = dayjs(d2.d, xCONS.REPORT_DAILY_DATE_FORMAT)
          //   if (date1.isAfter(date2)) {
          //     return 1
          //   }
          //   if (date1.isBefore(date2)) {
          //     return -1
          //   }
          //   return 0
          // })
          // log('preparedPastData after sort => ', preparedPastData)

          // res.past = preparedPastData
          modifiedResponse.past = preparedPastData
          if (_.isObject(modifiedResponse.today)) {
            const { p, f, o } = modifiedResponse.today
            modifiedResponse.today.p = _.isString(p) ? parseFloat(p) : p
            modifiedResponse.today.f = _.isString(f) ? parseFloat(f) : f
            modifiedResponse.today.o = _.isString(o) ? parseFloat(o) : o
            // modifiedResponse.today.p = _.isString(p) ? parseFloat(p.replace(/,/g, '')) : p
            // modifiedResponse.today.f = _.isString(f) ? parseFloat(f.replace(/,/g, '')) : f
            // modifiedResponse.today.o = _.isString(o) ? parseFloat(o.replace(/,/g, '')) : o
          }
        }
        /// END
        await util.setStatePromise(this, {
          chartData: modifiedResponse,
          // displayCharts: res.date
        })
      }
      // log('BaseDailySalesReportView :: call fetchDailySalesReport body => ', body)
      // const response = await new Promise((resolveAPI) => {
      //   fetchDailySalesReport({
      //     body,
      //     successCallback: resolveAPI,
      //     failedCallback: () => resolveAPI(null),
      //   })
      // })

      // if (!_.isNil(response)) {
      //   this.SELECTED_DATE = false
      //   setStatePromise(this, { refreshing: false })
      // }
    } else {
      p.op.alert('ไม่พบร้านค้า', 'กรุณากลับไปเลือกร้านค้าใหม่อีกครั้ง')
    }
  }

  _onPressChartButton = async (selectedChartIndex) => {
    await setStatePromise(this, { selectedChartIndex })
    p.op.aLogEvent(xCONS.EVENT_NAME.REPORT_DAILY_EVENTS[selectedChartIndex], { store_id: this.props.selectedStore.get('id') })
  }

  _onSegmentPastTimePeriodChange = async (selectedOptionIndex: number) => {
    // For 7-day and 30-day only
    const { selectedStore } = this.props
    const { selectedDateRange, selectedOptionTimePeriodIndex, chartData } = this.state
    if (!this.SELECTED_DATE && (selectedOptionTimePeriodIndex === 0 || selectedOptionTimePeriodIndex === 1) && selectedOptionIndex === 2) {
      if (this.FIRST_TIME_SELECTED_DATE) {
        this.FIRST_TIME_SELECTED_DATE = false
        this.FIRST_TIME_SELECTED_DATE_FEOM_CHOICE = selectedOptionTimePeriodIndex
        const { selectedDateRange } = this.state
        const date = selectedOptionTimePeriodIndex === 0 ? 7 : 30
        const { past } = chartData
        const newDate = selectedDateRange
        newDate.begin = dayjs(past[0].d)
        newDate.end = dayjs(newDate.begin).add(date - 1, 'day')
        if (!_.isNil(chartData)) {
          await this.fetch({ daysInPast: date })
          const { displayCharts, displayTodayInfo } = await this._prepareDisplayChartData(date)
          await setStatePromise(this, { selectedOptionTimePeriodIndex: selectedOptionIndex, displayCharts, displayTodayInfo })
          return
        }
        await setStatePromise(this, { selectedOptionTimePeriodIndex: selectedOptionIndex, selectedDateRange: newDate })
        return
      }
      const checkChoiceDate = this.FIRST_TIME_SELECTED_DATE_FEOM_CHOICE === 0 ? 7 : 30
      const { displayCharts, displayTodayInfo } = await this._prepareDisplayChartData(checkChoiceDate)
      await setStatePromise(this, { selectedOptionTimePeriodIndex: selectedOptionIndex, displayCharts, displayTodayInfo })
      return
    }
    // const today = dayjs()
    // const startDate = dayjs(selectedDateRange.begin)
    const pastData = chartData ? chartData.past : []
    const dataCount = pastData.length
    let period = 0
    if (_.includes([0, 1], selectedOptionIndex)) {
      period = selectedOptionIndex === 0 ? 7 : 30
      if (selectedOptionTimePeriodIndex === 2 && this.SELECTED_DATE) {
        await this.fetch({ daysInPast: period })
        // return
      } else if (dataCount <= period) {
        // 7 วัน
        await this.fetch({ daysInPast: period })
      }
    } else if (selectedOptionIndex === 2) {
      if (this.SELECTED_DATE) {
        // body.startDate = selectedDateRange.begin.format(xCONS.SERVER_DATE_FORMAT)
        // body.endDate = selectedDateRange.end.format(xCONS.SERVER_DATE_FORMAT)
        await this.fetch({
          startDate: selectedDateRange.begin.format(xCONS.SERVER_DATE_FORMAT),
          endDate: selectedDateRange.end.format(xCONS.SERVER_DATE_FORMAT),
        })
        period = dayjs(selectedDateRange.end).diff(selectedDateRange.begin, 'day') + 1
        if (period === 0 || period === 1) {
          period = 2
        }
      }
    }
    await delay(100)
    const { displayCharts, displayTodayInfo } = await this._prepareDisplayChartData(period)
    // await delay(5000)
    await setStatePromise(this, { displayCharts, displayTodayInfo, selectedOptionTimePeriodIndex: selectedOptionIndex })
    p.op.aLogEvent(xCONS.EVENT_NAME.REPORT_DAILY_SELECT_PERIOD, { store_id: selectedStore.get('id'), period })
  }

  _onSegmentPastTimePeriodChangeFromOnpress = () => {
    this.SELECTED_DATE = true
    this._onSegmentPastTimePeriodChange(2)
  }

  _isVisibleDateRangePicker(): boolean {
    return this.state.selectedOptionTimePeriodIndex === 2
  }

  _computeAllowDays = () => {
    const { subscription } = this.props
    // console.log(subscription.get('type'))
    if (subscription.get('type') === 5 || subscription.get('type') === 999) {
      return false
    }
    return false
  }

  async _onChangeDateRange(newDates: { begin: Dayjs; end: Dayjs }): Promise<void> {
    // console.log(newDates)
    // console.log(this.state.selectedOptionTimePeriodIndex)
    // this._onSegmentPastTimePeriodChange(this.state.selectedOptionTimePeriodIndex)
    const newDatesClone = { ...newDates }
    const newStarDate = dayjs(newDates.begin).subtract(1, 'days')
    const diff = dayjs(newDates.end).diff(newDates.begin, 'day')
    // console.log(newStarDate)
    // console.log(diff)
    if (diff === 0) {
      newDatesClone.begin = newStarDate
    }
    if (diff > 93) {
      newDatesClone.end = dayjs(newDates.begin).add(93, 'day')
    }

    await setStatePromise(this, { selectedDateRange: newDatesClone })

    // this._onSegmentPastTimePeriodChange(this.state.selectedOptionTimePeriodIndex)
    // this.setState({ selectedDateRange: newDates })
  }

  // _prepareDisplayChartData = (newData, limit?: number) => {
  // const chartData = newData ? newData : this.props.chartData
  // if (!chartData || !isImmutable(chartData)) {
  //   return
  // }
  _prepareDisplayChartData = async (limit?: number): Promise<{ displayTodayInfo: ITodayInfo; displayCharts: IChart[] }> => {
    // console.log('limit ' + limit)
    const { chartData } = this.state
    if (_.isNil(chartData)) {
      return null
    }
    // const todayData = chartData.get('today') ? chartData.get('today') : null
    const pastData = chartData ? chartData.past : []
    // log('_prepareDisplayChartData new todayData => ', todayData.toJS())
    // const allData = todayData && isImmutable(todayData) ? pastData.push(todayData) : pastData
    let allData = pastData || []
    // console.log(allData.toJS())
    if (_.isNumber(limit) && limit > 0 && allData.length > limit) {
      const endIndex = allData.length - 1 // last index
      const beginIndex = endIndex - limit + 1
      allData = allData.slice(beginIndex) // เอาตัวสุดท้ายมาก่อนเสมอ
    }

    const displayCharts = []
    this.CHART_DATA_PROPERTIES.forEach((chartProps: ICHART_DATA_PROPERTIES) => {
      const data: IChartData[] = []
      const { key_x, key_y } = chartProps
      allData.forEach((focusData: IResponseChartItem) => {
        const dataX: string = focusData[key_x] || '0'
        const dataYToNumber = focusData[key_y] || 0
        const dataY = _.isNumber(dataYToNumber) ? dataYToNumber : +dataYToNumber
        // Merge the same data key
        // data.push({ x: dataX, y: _.isString(dataY) ? parseFloat(dataY.replace(/,/, '')) : dataY })
        data.push({ x: dataX, y: dataY })
      })
      displayCharts.push({ data, ...chartProps })
    })
    const todayData: IResponseChartItem = chartData.today ? chartData.today : { d: '', f: 0, o: 0, p: 0 }
    // log('_prepareDisplayChartData new todayData => ', todayData.toJS())
    const displayTodayInfo = {
      sales_amount: todayData.p || 0,
      profit_amount: todayData.f || 0,
      order_count: todayData.o || 0,
    }

    // this.setState({ displayCharts, displayTodayInfo, refreshing: false })
    return {
      displayCharts,
      displayTodayInfo,
    }
  }
}
