// import { Pressable } from 'react-native'
// import { IPressableProps } from 'native-base'
// import { IAnyObject } from 'x/index'
import XButton from './XButton'

// export type IXBlankButtonType = typeof Pressable
// export type IXBlankButtonProps = IPressableProps & IAnyObject

// export interface IXBlankButtonProps extends Omit<PressableProps, 'children' | 'hitSlop' | 'style'>, IBoxProps {
//   /**
//    * Called when a mouse enters the Pressable
//    */
//   onHoverIn?: () => void
//   /**
//    * Called when a mouse leaves the Pressable
//    */
//   onHoverOut?: () => void
//   /**
//    * Called when Pressable receives focus
//    */
//   onFocus?: () => void
//   /**
//    * Called when Pressable loses focus
//    */
//   onBlur?: () => void
//   /**
//    * Style props to be applied when hovered
//    */
//   _hover?: Omit<IPressableProps, '_hover'>
//   /**
//    * Style props to be applied when pressed
//    */
//   _pressed?: Omit<IPressableProps, '_pressed'>
//   /**
//    * Style props to be applied when focus
//    */
//   _focus?: Omit<IPressableProps, '_focus'>
//   /**
//    * Style props to be applied when disabled
//    */
//   _disabled?: Omit<IPressableProps, '_disabled'>
//   /**
//    * 	If true, the p will be disabled.
//    */
//   isDisabled?: boolean
//   /**
//    * 	If true, the p will be hovered.
//    */
//   isHovered?: boolean
//   /**
//    * 	If true, the p will be pressed.
//    */
//   isPressed?: boolean
//   /**
//    * 	If true, the p will be focused.
//    */
//   isFocused?: boolean
//   /**
//    * 	If true, the focus ring will be visible .
//    */
//   isFocusVisible?: boolean

//   colorScheme?: string
//   // variant?: VariantType<'Button'>
//   variant?: 'solid' | 'primary' | 'outline' | 'ghost' | 'subtle'
//   size?: ResponsiveValue<ISizes | (string & {}) | number>
// }

// // const XBlankButton = React.forwardRef<IXBlankButtonType, IXBlankButtonProps>((props, fwdRef) => {
// //   // const {
// //   //   onPress,
// //   //   onPressIn,
// //   //   onPressOut,
// //   //   onLongPress,
// //   //   onHoverIn,
// //   //   onHoverOut,
// //   //   onFocus,
// //   //   onBlur,
// //   //   _hover,
// //   //   _pressed,
// //   //   _focus,
// //   //   _disabled,
// //   //   isDisabled,
// //   //   isHovered,
// //   //   isPressed,
// //   //   isFocused,
// //   //   isFocusVisible,
// //   //   // _focusVisible,
// //   //   children,
// //   //   variant = 'solid',
// //   //   // size,
// //   //   // colorScheme = 'primary',
// //   //   ...restProps
// //   // } = props
// //   // const theme = useTheme()
// //   // console.log('theme => ', theme)
// //   // const athemeProps = useThemeProps('Button', props)
// //   // console.log('athemeProps => ', athemeProps)
// //   // const { components } = theme
// //   // const baseStyleProps = components.Button.baseStyle(props)
// //   // const variantProps = components.Button.variants[variant](props)
// //   // const { defaultProps } = components.Button
// //   // // const size = components.Button.sizes[]

// //   // const themedProps = {
// //   //   _hover: {},
// //   //   _pressed: {},
// //   //   _focus: {},
// //   //   ...baseStyleProps,
// //   //   ...variantProps,
// //   //   ...defaultProps,
// //   // }

// //   // const pressableProps = {
// //   //   onPress,
// //   //   onPressIn,
// //   //   onPressOut,
// //   //   onLongPress,
// //   //   onHoverIn,
// //   //   onHoverOut,
// //   //   onFocus,
// //   //   onBlur,
// //   //   _hover: { ...themedProps._hover, ..._hover },
// //   //   _pressed: { ...themedProps._pressed, ..._pressed },
// //   //   _focus: { ...themedProps._focus, ..._focus },
// //   //   _disabled,
// //   //   isDisabled,
// //   //   isHovered,
// //   //   isPressed,
// //   //   isFocused,
// //   //   isFocusVisible,
// //   //   // _focusVisible,
// //   // }

// //   const themeProps = useThemeProps('Button', props)
// //   const { children } = themeProps
// //   return (
// //     <Pressable
// //       ref={fwdRef}
// //       // _hover={{
// //       //   bg: 'primary.600:alpha.20',
// //       // }}
// //       accessibilityRole={props.accessibilityRole ?? 'button'}
// //       // {...pressableProps}
// //       alignContent='center'
// //       justifyContent='center'
// //       {...themeProps}>
// //       {children}
// //       {/* {({ isFocused, isHovered, isPressed }) => {
// //         let contentContainerProps = { ...themeProps }
// //         if (isFocused) {
// //           contentContainerProps = { ...contentContainerProps, ...themeProps._focus }
// //         }
// //         if (isHovered) {
// //           contentContainerProps = { ...contentContainerProps, ...themeProps._hover }
// //         }
// //         if (isPressed) {
// //           contentContainerProps = { ...contentContainerProps, ...themeProps._press }
// //         }
// //         return <HStack {...contentContainerProps} />
// //       }} */}
// //     </Pressable>
// //   )
// // })

// const XBlankButton = React.forwardRef<IXBlankButtonType, IXBlankButtonProps>((props, fwdRef) => {
//   const { children, variant = 'ghost', _text = {}, ...restProps } = props

//   const childStringToTextCompoent = React.useCallback(
//     (child, index) => {
//       // console.log(`childStringToTextCompoent child-${index} -> `, child)
//       if (typeof child === 'string' || typeof child === 'number') {
//         return (
//           <XText key={`ChildTextGen_${index.toString()}`} {..._text}>
//             {child}
//           </XText>
//         )
//       }
//       return child
//     },
//     [_text]
//   )

//   return (
//     // @ts-ignore
//     <XButton ref={fwdRef} variant={variant} {...restProps}>
//       {React.Children.map(children, childStringToTextCompoent)}
//     </XButton>
//   )
// })

// export default memo(XBlankButton)
export default XButton
