// import BasePaymentView, { OPTION_COD_AMOUNT, OPTION_ORDER_AMOUNT } from 'x/modules/payment/BasePaymentView'
// import SwitchCard from '../../components/SwitchCard'
// import settings from "x/config/settings"
// import s from './_style'
// import * as NavActions from '../../services/navigation'

// import * as indicator from '../../services/indicator'
// import StickyKeyboardTab from '../../components/StickyKeyboardTab'
// import * as utilN from '../../utils/util-native'
import BaseUIStoreSettingXShippingView from 'xui/modules/payment/BaseUIPaymentPTTTransactions'
// import PaymentAccountPicker from 'xui/components/PaymentAccountPicker'

class StoreSettingXShippingView extends BaseUIStoreSettingXShippingView {
  static displayName = 'StoreSettingXShippingView'

  static navigationOptions = {
    header: null,
    title: null,
    headerLeft: null,
    headerRight: null,
  }
}

export default StoreSettingXShippingView
