import React from 'react'
import { Clipboard, View, TouchableOpacity } from 'react-native'
import _ from 'lodash'
import p from 'x/config/platform-specific'
import { COLORS, STYLES } from 'x/config/styles'
import * as util from 'x/utils/util'
import XCustomHeader from 'xui/components/XCustomHeader'
import BaseJAntTConnectView from 'x/modules/xshipping/BaseJAntTConnectView'
import XIcon from 'xui/components/XIcon'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import HStack from 'xui/components/HStack'
import Box from 'xui/components/Box'
import XText from 'xui/components/XText'
import XButton from 'xui/components/XButton'

const titleWidth = 90
export default abstract class BaseUIJAntTConnectView extends BaseJAntTConnectView {
  static displayName = 'BaseUIJAntTConnectView'
  // abstract goBack: () => void

  _renderCustomHeader = () => {
    const { senderAddresses } = this.state
    return (
      <XCustomHeader
        headerLeftProps={{
          backIcon: true,
          onPressItem: () => {
            const callBackSenderAddress = util.getNavParam(this.props, 'callBackSenderAddress')
            if (_.isFunction(callBackSenderAddress)) {
              callBackSenderAddress(senderAddresses)
            }
            util.navGoBack(this.props)
          },
        }}
        title={p.op.t('Order.shippingTypeItems.jt')}
      />
    )
  }

  _renderShopRegisterInfo = () => {
    const { senderAddresses } = this.state
    if (_.isNil(senderAddresses)) {
      return null
    }
    if (senderAddresses.site) {
      return null
    }
    // console.log('senderAddresses => ', senderAddresses)
    // const selectedSenderAddress = senderAddresses[0]
    const shopName = senderAddresses.tp_shop_name
    const shopId = senderAddresses.tp_shop_id
    const textInfo =
      'ต้องการลงทะเบียนออกเลขพัสดุผ่านระบบ XSelly\n' + `Shop ID: ${shopId} (ตัวเล็กทั้งหมดและไม่มีเคาะวรรค)\n` + `Shop name: ${shopName}`
    return (
      <Box
        _light={{ bg: 'white' }}
        _dark={{ bg: 'gray.800' }}
        borderRadius='md'
        style={{
          // backgroundColor: COLORS.WHITE,
          marginTop: 10,
          marginRight: 6,
          marginLeft: 6,
          paddingLeft: 10,
          paddingRight: 10,
          paddingTop: 6,
          paddingBottom: 6,
          justifyContent: 'center',
        }}>
        <View style={{ flex: 1, height: 35, justifyContent: 'center', flexDirection: 'row' }}>
          <View style={{ width: titleWidth, justifyContent: 'center' }}>
            <XText variant='inactive'>สถานะ</XText>
          </View>
          <View style={{ flex: 1, justifyContent: 'center' }}>
            <XText style={{ color: COLORS.BRAND_Warning }}>มีรหัสการเชื่อมต่อแล้ว</XText>
          </View>
        </View>
        <XText variant='active'>
          กรุณาคัดลอกและนำข้อความด้านล่างนี้ไปแจ้งกับสาขา J&T ที่คุณมีรหัส VIP อยู่ (เช่น ส่งทาง LINE ของสาขา) เพื่อดำเนินการเชื่อมต่อ
        </XText>

        <View style={{ height: 10 }} />

        <TouchableOpacity
          onPress={() => {
            Clipboard.setString(textInfo)
            p.op.showToast('คัดลอกสำเร็จแล้ว', 'success')
          }}
          style={{
            alignSelf: 'center',
            width: 320,
            // borderColor: COLORS.APP_MAIN,
            borderRadius: 7,
            // borderWidth: 1,
            borderLeftWidth: 1,
            borderLeftColor: COLORS.APP_MAIN,
            borderRightWidth: 1,
            borderRightColor: COLORS.APP_MAIN,
            borderTopWidth: 1,
            borderTopColor: COLORS.APP_MAIN,
            borderBottomWidth: 1,
            borderBottomColor: COLORS.APP_MAIN,
            backgroundColor: COLORS.BG_LIGHT_GREY,
            paddingBottom: 4,
            paddingLeft: 4,
            paddingRight: 4,
            paddingTop: 4,
          }}>
          <XText style={{ fontSize: STYLES.FONT_SIZE_LARGER, color: COLORS.APP_MAIN, textAlign: 'left', fontWeight: 'bold' }}>
            {textInfo}
          </XText>
          <View style={{ alignSelf: 'center', flexDirection: 'row' }}>
            <XIcon
              name='copy1'
              family='AntDesign'
              style={{ flex: 0, width: 25, minWidth: 25, color: COLORS.TEXT_INACTIVE, fontSize: STYLES.FONT_ICON_SMALLER, paddingTop: 4 }}
            />
            <XText style={{ fontSize: STYLES.FONT_SIZE_LARGER, color: COLORS.TEXT_INACTIVE, textAlign: 'center', paddingTop: 4 }}>
              แตะตรงนี้เพื่อคัดลอก
            </XText>
          </View>
        </TouchableOpacity>
      </Box>
    )
  }

  _renderShopInfo = () => {
    const { senderAddresses } = this.state
    if (_.isNil(senderAddresses)) {
      return null
    }
    if (_.isNil(senderAddresses.site)) {
      return null
    }
    // if (_.isNil(senderAddresses)) {
    //   return null
    // }
    // console.log('senderAddresses => ', senderAddresses)
    const { site } = senderAddresses
    // const site = {
    //   name: '01Bang Khae01',
    //   phone: '02-120-4856',
    //   address: '548/22 548/23 Bangkhae road，Bangkhae District，Bangkok 10160',
    //   code: '801039',
    //   tp_shop_name: 'TEST',
    //   tp_shop_id: '789',
    // }
    const shopName = senderAddresses.tp_shop_name
    const shopId = senderAddresses.tp_shop_id
    // const shopName = 'TEST'
    // const shopId = '789'
    const info =
      // eslint-disable-next-line max-len
      'หมายเหตุ: เลขพัสดุ J&T ที่ออกผ่าน XSelly ต้องนำส่งที่สาขานี้เท่านั้น หากต้องการเปลื่ยนสาขา โดยหากต้องการเปลื่ยนสาขา รบกวนสอบถาม"รหัสสาขา"ของสาขาที่ต้องการเชื่อม หรือหากเคยส่งพัสดุกับสาขานี้มาก่อนก็สามารถใช้"เลขพัสดุ"ได้ แล้วติดต่อมาที่ LINE: @XSelly '
    return (
      <Box
        _light={{ bg: 'white' }}
        _dark={{ bg: 'gray.800' }}
        alignItems='center'
        justifyContent='center'
        mt='2'
        mx='2'
        px='2'
        pb='2'
        w='full'
        borderRadius='md'>
        <HStack alignItems='center' mt='2' w='full'>
          <Box flex={1}>
            <XText variant='inactive' bold>
              ข้อมูลสาขา2
            </XText>
          </Box>
          <XButton
            mr='2'
            leftIcon={<XIcon name='refresh' family='FontAwesome' variant='primary' />}
            variant='outline'
            onPress={() => this._updateSite()}>
            <XText variant='primary'>อัพเดทข้อมูลสาขา</XText>
          </XButton>
        </HStack>
        <XText variant='inactive' mt='2'>
          {info}
        </XText>
        {/* // ชื่อสาขา */}
        <Box h='10' alignItems='center' flex={1} flexDirection='row' w='full'>
          <Box style={{ width: titleWidth }}>
            <XText variant='inactive'>ชื่อสาขา</XText>
          </Box>
          <Box style={{ flex: 1 }}>
            <XText variant='active'>{site.name}</XText>
          </Box>
        </Box>
        {/* // เบอร์โทรสาขา */}
        <Box alignItems='center' flex={1} flexDirection='row' w='full'>
          <Box style={{ width: titleWidth }}>
            <XText variant='inactive'>เบอร์โทรสาขา</XText>
          </Box>
          <Box style={{ flex: 1 }}>
            <XText variant='active'>{site.phone}</XText>
          </Box>
        </Box>
        <Box style={{ height: 4 }} />
        {/* // ที่อยู่สาขา */}
        <Box flexDirection='row' w='full'>
          <Box style={{ width: titleWidth }}>
            <XText variant='inactive'>ที่อยู่สาขา</XText>
          </Box>
          <Box flex={1}>
            <XText variant='active'>{site.address}</XText>
          </Box>
        </Box>
        {/* // รหัสสาขา */}
        <Box alignItems='center' flex={1} flexDirection='row' w='full'>
          <Box style={{ width: titleWidth }}>
            <XText variant='inactive'>รหัสสาขา</XText>
          </Box>
          <Box style={{ flex: 1 }}>
            <XText variant='active'>{site.code}</XText>
          </Box>
        </Box>
        {/* // Shop ID */}
        <Box alignItems='center' flex={1} flexDirection='row' w='full'>
          <Box style={{ width: titleWidth }}>
            <XText variant='inactive'>Shop ID</XText>
          </Box>
          <Box style={{ flex: 1 }}>
            <XText variant='active'>{shopId}</XText>
          </Box>
        </Box>
        {/* // Shop Name */}
        <Box alignItems='center' flex={1} flexDirection='row' w='full'>
          <Box style={{ width: titleWidth }}>
            <XText variant='inactive'>Shop Name</XText>
          </Box>
          <Box style={{ flex: 1 }}>
            <XText variant='active'>{shopName}</XText>
          </Box>
        </Box>
      </Box>
    )
  }

  _renderSenderAddress = () => {
    const { senderAddresses } = this.state
    if (_.isNil(senderAddresses)) {
      return null
    }
    const { address } = senderAddresses
    if (_.isNil(address)) {
      return null
    }
    const addressLabel = `${address.address} ${address.sub_district} ${address.district} ${address.province} ${address.postcode}`
    return (
      <Box>
        <Box _light={{ bg: 'white' }} _dark={{ bg: 'gray.800' }} mt='2' mx='2' px='2' borderRadius='md'>
          {/* // ที่อยู่ผู้รับ */}
          <Box w='full' flexDirection='row' mt='2'>
            <Box justifyContent='center' flex={1}>
              <XText variant='inactive' bold>
                ที่อยู่ผู้ส่ง
              </XText>
            </Box>
            <XButton
              leftIcon={<XIcon name='edit' family='FontAwesome' variant='primary' />}
              variant='outline'
              onPress={() => this._navToCreateXShippingAddressView(address)}>
              <XText variant='primary'>อัพเดทข้อมูลสาขา</XText>
            </XButton>
          </Box>
          {/* // ชื่อ */}
          <Box h='10' alignItems='center' flex={1} flexDirection='row'>
            <Box style={{ width: titleWidth }}>
              <XText variant='inactive'>ชื่อ</XText>
            </Box>
            <Box style={{ flex: 1 }}>
              <XText variant='active'>{address.name}</XText>
            </Box>
          </Box>
          {/* // ที่อยู่ */}
          <Box h='10' alignItems='center' flex={1} flexDirection='row'>
            <Box style={{ width: titleWidth }}>
              <XText variant='inactive'>ที่อยู่</XText>
            </Box>
            <Box style={{ flex: 1 }}>
              <XText variant='active'>{addressLabel}</XText>
            </Box>
          </Box>
          {/* // เบอร์โทร */}
          <Box h='10' alignItems='center' flex={1} flexDirection='row'>
            <Box style={{ width: titleWidth }}>
              <XText variant='inactive'>เบอร์โทร</XText>
            </Box>
            <Box style={{ flex: 1 }}>
              <XText variant='active'>{address.phone}</XText>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }

  _renderDeleteSenderAddress = () => {
    const { senderAddresses } = this.state
    if (_.isNil(senderAddresses)) {
      return null
    }
    return (
      <View style={{ marginLeft: 6, marginRight: 6, marginTop: 10 }}>
        <XButton
          variant='solid'
          color='danger.800'
          onPress={() => {
            p.op.showConfirmation(
              '',
              'ต้องการยกเลิกการเชื่อมต่อ',
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              () => this._deleteSenderAddress(),
              () => null,
              'ใช่',
              'ไม่ใช่'
            )
          }}>
          <XText color='white' bold textAlign='center'>
            ยกเลิกการเชื่อมต่อ
          </XText>
        </XButton>
      </View>
    )
  }

  renderBody = () => (
    <Box w='full'>
      {this._renderShopRegisterInfo()}
      {this._renderShopInfo()}
      {this._renderSenderAddress()}
      {this._renderDeleteSenderAddress()}
    </Box>
  )

  render() {
    return (
      <XContainer>
        {this._renderCustomHeader()}
        <XContent>{this.renderBody()}</XContent>
      </XContainer>
    )
  }
}
