import { connect } from 'react-redux'
import VolumeDiscountListView from './VolumeDiscountListView'
import { bindActionCreators } from 'redux'
import * as StoreActions from 'x/modules/store/StoreState'
import * as ProductState from 'x/modules/product/ProductState'
import {
  getSelectedStore,
  getSelectedUserGroups,
  getSubscription,
  getShouldFetchUserGroups,
  getEditingStore,
} from 'x/redux/selectors'

export default connect(
  state => ({
    selectedStore: getSelectedStore(state),
    editingStore: getEditingStore(state),
    selectedUserGroups: getSelectedUserGroups(state),
    subscription: getSubscription(state),
    shouldFetch: getShouldFetchUserGroups(state),
  }),
  dispatch => ({
    loadListVolumeDiscounts: bindActionCreators(StoreActions.loadListVolumeDiscounts, dispatch), 
    pairVolumeDiscount: bindActionCreators(StoreActions.pairVolumeDiscount, dispatch), 
    unpairVolumeDiscount: bindActionCreators(StoreActions.unpairVolumeDiscount, dispatch), 
    updateContInVolumeDiscounts: bindActionCreators(StoreActions.updateContInVolumeDiscounts, dispatch), 
    updateDataVolumeDiscountAndProductGroup: bindActionCreators(ProductState.updateDataVolumeDiscountAndProductGroup, dispatch),
    londProductsFromVolumeDiscount: bindActionCreators(ProductState.londProductsFromVolumeDiscount, dispatch),
  })
)(VolumeDiscountListView)
