import { Component } from 'react'

import _ from 'lodash'
// import { COLORS, STYLES } from '../../config/styles'
import { Map } from 'immutable'
import { ICategory, ActionApiParams, IXScreenProps } from 'x/index'
import p from '../../../config/platform-specific'
import * as util from '../../../utils/util'

export interface BaseCategoryListViewProps extends IXScreenProps {
  selectedStore: Map<string, any>
  getCategoryList: (params: ActionApiParams) => void
  requestCategoryList: (params: ActionApiParams) => void
  addCategory: (params: ActionApiParams) => void
  sortCategory: (params: ActionApiParams) => void
}

export interface BaseCategoryListViewState {
  mode: 'VIEW' | 'CREATE' | 'EDIT'
  // haveSelectedCategory: boolean
  category: ICategory[] // ALL CATEGORY
  subCategory: ICategory[]
  selectedCategory: ICategory
  // pageLoading: boolean
  breadCrumbs: ICategory[]
}

const VIEW = 'VIEW'
const EDIT = 'EDIT'

export default abstract class BaseCategoryListView extends Component<BaseCategoryListViewProps, BaseCategoryListViewState> {
  abstract _goBack(): void

  protected constructor(props) {
    super(props)

    this.state = {
      mode: null,
      // haveSelectedCategory: false,
      // pageLoading: true,
      subCategory: null,
      category: null,
      breadCrumbs: null,
      selectedCategory: null,
    }
  }

  async componentDidMount() {
    const { navigation, selectedStore, requestCategoryList } = this.props
    if (_.isNil(selectedStore)) {
      p.op.showToast('เกิดข้อผิดพลาด กรุณาติดต่อทีมงาน XSelly', 'warning')
      this._goBack()
      return
    }

    const res: ICategory[] = await new Promise((resolve) => {
      requestCategoryList({
        body: {
          store_id: selectedStore.get('id'),
        },
        successCallback: resolve,
        failedCallback: resolve,
      })
    })
    if (!_.isArray(res)) {
      p.op.showConfirmationOkOnly(``, `เกิดข้อผิดพลาด กรุณาติดต่อทีมงาน XSelly ${res}`)
      this._goBack()
      return
    }
    // const { state } = navigation
    const params = util.getNavParams(this.props)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const mode: 'VIEW' | 'CREATE' | 'EDIT' = params ? params.mode : null
    const category = res
    const subCategory = []
    let i = 0
    category.map((cat: ICategory) => {
      if (_.isNil(cat.p)) {
        const newCat = cat
        newCat.index = i
        i += 1
        subCategory.push(cat)
      }
    })

    await util.setStatePromise(this, {
      mode,
      pageLoading: false,
      category,
      subCategory,
    })
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  _changeToEditMode = async () => {
    await util.setStatePromise(this, { mode: EDIT })
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  _changeToViewMode = async () => {
    const { selectedCategory, category } = this.state
    const subCategory = await this._fineSupCat(selectedCategory, category)
    await util.setStatePromise(this, { mode: VIEW, subCategory })
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  _seletedSupCategory = async (seletedCategory: ICategory) => {
    const { breadCrumbs, mode } = this.state
    if (mode === 'EDIT') {
      return
    }
    await util.setStatePromise(this, { pageLoading: true })
    const newBreadCrumbs = _.isNil(breadCrumbs) ? [] : breadCrumbs
    const subCategory = await this._fineSupCat(seletedCategory)
    const newSeletedCategory = seletedCategory
    newSeletedCategory.indexBreadCrumbs = newBreadCrumbs.length
    newBreadCrumbs.push(newSeletedCategory)
    // await util.delay(500)
    await util.setStatePromise(this, { subCategory, selectedCategory: seletedCategory, breadCrumbs: newBreadCrumbs })
    await util.setStatePromise(this, { pageLoading: false })
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  _seletedBreadCrumbs = async (seletedCategory: ICategory) => {
    await util.setStatePromise(this, { pageLoading: true })
    const { breadCrumbs } = this.state
    const sortBreadCrumbs = _.orderBy(breadCrumbs, ['indexBreadCrumbs'], ['asc'])
    const newBreadCrumbs = []
    sortBreadCrumbs.map((data: ICategory) => {
      if (data.indexBreadCrumbs <= seletedCategory.indexBreadCrumbs) {
        newBreadCrumbs.push(data)
      }
    })
    const subCategory = await this._fineSupCat(seletedCategory)

    await util.setStatePromise(this, { subCategory, selectedCategory: seletedCategory, breadCrumbs: newBreadCrumbs })
    await util.setStatePromise(this, { pageLoading: false })
  }

  _fineSupCat = async (seletedCategory: ICategory, newCat: ICategory[] = null) => {
    const { category } = this.state
    const catList = _.isNil(newCat) ? category : newCat
    const subCategory = []
    if (!_.isNil(seletedCategory)) {
      seletedCategory.c.map((id: number, index: number) => {
        const cat = _.find(catList, (data) => data.id === id)
        if (!_.isNil(cat)) {
          const newCat = cat
          newCat.index = index
          subCategory.push(cat)
        }
      })
    } else if (!_.isNil(newCat)) {
      // สำหรับ cat นอกสุด
      let i = 0
      catList.map((cat: ICategory) => {
        if (_.isNil(cat.p)) {
          const newCat = cat
          newCat.index = i
          i += 1
          subCategory.push(cat)
        }
      })
    }
    return subCategory
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  _onPressBreadCrumbsHome = async () => {
    const { category } = this.state
    await util.setStatePromise(this, { pageLoading: true })
    // await util.delay(500)
    const subCategory = []
    category.map((cat: ICategory) => {
      if (_.isNil(cat.p)) {
        subCategory.push(cat)
      }
    })
    await util.setStatePromise(this, { subCategory, selectedCategory: null, breadCrumbs: null })
    await util.setStatePromise(this, { pageLoading: false })
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  _sortUp = async (category: ICategory[], index: number) => {
    const indexToChange = index - 1
    const supCategory = []
    category.map((cat: ICategory) => {
      const newCat = cat
      if (cat.index === indexToChange) {
        newCat.index = cat.index + 1
        supCategory.push(newCat)
      } else if (cat.index === index) {
        newCat.index = index - 1
        supCategory.push(newCat)
      } else {
        supCategory.push(newCat)
      }
    })
    await util.setStatePromise(this, { subCategory: supCategory })
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  _sortDown = async (category: ICategory[], index: number) => {
    const indexToChange = index + 1
    const supCategory = []
    category.map((cat: ICategory) => {
      const newCat = cat
      if (cat.index === indexToChange) {
        newCat.index = cat.index - 1
        supCategory.push(newCat)
      } else if (cat.index === index) {
        newCat.index = index + 1
        supCategory.push(newCat)
      } else {
        supCategory.push(newCat)
      }
    })
    await util.setStatePromise(this, { subCategory: supCategory })
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  _callBackForCreateAndEdit = async (newCategory: ICategory[]) => {
    const { selectedCategory, subCategory } = this.state
    let newSeletedCat = selectedCategory
    let newSubCategory = subCategory
    if (!_.isNil(selectedCategory)) {
      newSeletedCat = _.find(newCategory, (data) => data.id === selectedCategory.id)
    } else {
      const fineSupCat = []
      newCategory.map((cat: ICategory, index: number) => {
        if (_.isNil(cat.p)) {
          const newCat = cat
          newCat.index = index
          fineSupCat.push(cat)
        }
      })
      newSubCategory = fineSupCat
    }
    if (_.isNil(newCategory)) {
      return
    }

    const newBreadCrumbs = await this._updateBreadCrumbs(newSeletedCat, newCategory, true)
    newSubCategory = await this._fineSupCat(newSeletedCat, newCategory)
    await util.setStatePromise(this, {
      category: newCategory,
      subCategory: newSubCategory,
      selectedCategory: newSeletedCat,
      breadCrumbs: newBreadCrumbs,
    })
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  _callBackForDeleteCategory = async (NewCatUpdate: { newCategory: ICategory[]; deleteCat: ICategory }) => {
    const newUpdateBreadCrumbs = await this._updateBreadCrumbsForCallBackDelete(NewCatUpdate.newCategory)
    const newBreadCrumbs = []
    let newSelectedCategory = null
    let newSupCategory = null
    // find newSupCategory
    if (_.isNil(NewCatUpdate.deleteCat.p)) {
      const newSubCat = []
      NewCatUpdate.newCategory.map((cat: ICategory) => {
        if (_.isNil(cat.p)) {
          newSubCat.push(cat)
        }
      })
      newSupCategory = newSubCat
    } else {
      const iii = []
      NewCatUpdate.newCategory.map((cat: ICategory) => {
        if (cat.p === NewCatUpdate.deleteCat.p) {
          iii.push(cat)
        }
      })
      newSupCategory = iii
      newSelectedCategory = _.find(NewCatUpdate.newCategory, (data) => data.id === NewCatUpdate.deleteCat.p)
    }
    // find new BreadCrumbs
    newUpdateBreadCrumbs.map((data: ICategory) => {
      if (data.id !== NewCatUpdate.deleteCat.id) {
        newBreadCrumbs.push(data)
      }
    })
    // const subCategory = await this._fineSupCat(newSeletedCat, newCategory)
    await util.setStatePromise(this, {
      category: NewCatUpdate.newCategory,
      subCategory: newSupCategory,
      selectedCategory: newSelectedCategory,
      breadCrumbs: newBreadCrumbs,
    })
  }

  _updateBreadCrumbsForCallBackDelete = async (newCategorys: ICategory[]) => {
    const { breadCrumbs } = this.state
    const newBreadCrums = []
    breadCrumbs.map((data: ICategory) => {
      newCategorys.map((cat: ICategory) => {
        if (data.id === cat.id) {
          const newCat = cat
          newCat.indexBreadCrumbs = data.indexBreadCrumbs
          newBreadCrums.push(newCat)
        }
      })
    })
    return newBreadCrums
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  _chackChildren = (categoryIn: ICategory) => {
    const { category } = this.state
    let haveChildren = false
    category.map((data: ICategory) => {
      if (data.p === categoryIn.id) {
        haveChildren = true
      }
    })
    return haveChildren
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  _chackChildrenCount = (cat: ICategory) => {
    const { category } = this.state
    let count = 0
    category.map((data: ICategory) => {
      if (data.p === cat.id) {
        count += 1
      }
    })
    return count
  }

  _updateBreadCrumbs = async (seletedCategory: ICategory, newCategorys: ICategory[], updateMe = false) => {
    // updateMe : ถ้าส่งเข้ามาเป็น true จะทำให้เวลาอัพเดต จะเพิ่ม category ที่ส่งเข้ามาไปต่อท้ายสุด
    const newBreadCrumbs = []
    if (!_.isNil(seletedCategory)) {
      let endOfLoop = true
      let nexted = seletedCategory
      // let i = 0
      do {
        // eslint-disable-next-line no-loop-func
        const newNexted = _.find(newCategorys, (data: ICategory) => data.id === nexted.p)
        if (!_.isNil(newNexted)) {
          nexted = newNexted
          // nexted.indexBreadCrumbs = i
          newBreadCrumbs.push(nexted)
          // i = i + 1
        } else {
          endOfLoop = false
        }
      } while (endOfLoop)

      // if (newBreadCrumbs.length < 1) {
      //   const newSelectedCategory = seletedCategory
      //   newSelectedCategory.indexBreadCrumbs = 0
      //   newBreadCrumbs.push(newSelectedCategory)
      // }

      newBreadCrumbs.reverse()
      const newBreadCrumbsSort = []

      // const sortBreadCrumbs = _.orderBy(newBreadCrumbs, ['indexBreadCrumbs'], ['asc'])
      newBreadCrumbs.map((data: ICategory, index: number) => {
        const newData = data
        newData.indexBreadCrumbs = index
        newBreadCrumbsSort.push(newData)
      })

      if (updateMe) {
        const seletedCategoryUpdate = _.find(newCategorys, (data: ICategory) => data.id === seletedCategory.id)
        if (!_.isNil(seletedCategoryUpdate)) {
          seletedCategoryUpdate.indexBreadCrumbs = newBreadCrumbsSort.length
          newBreadCrumbsSort.push(seletedCategoryUpdate)
        }
      }
      return newBreadCrumbsSort
    }
    return newBreadCrumbs
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  _callBackChangeCategory = async (NewCatUpdate: { newCategory: ICategory[]; changeCat: ICategory }) => {
    const newCategoryList = NewCatUpdate.newCategory
    const categoryChange = NewCatUpdate.changeCat
    const newSelectedCategory = _.find(newCategoryList, (cat: ICategory) => cat.id === categoryChange.p)
    const newSupCat = await this._fineSupCat(newSelectedCategory, newCategoryList)
    const newBreadCrumbs = await this._updateBreadCrumbs(categoryChange, newCategoryList)
    await util.setStatePromise(this, {
      category: newCategoryList,
      subCategory: newSupCat,
      breadCrumbs: newBreadCrumbs,
      selectedCategory: newSelectedCategory,
    })
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  _sortCategory = async () => {
    await util.setStatePromise(this, { pageLoading: true })
    const { selectedCategory, subCategory } = this.state
    const { sortCategory, selectedStore } = this.props
    const findSortCategoryId = []
    const sortCat = _.orderBy(subCategory, ['index'], ['asc'])
    sortCat.map((data: ICategory) => {
      findSortCategoryId.push(data.id)
    })
    const body: { [key: string]: any } = {
      store_id: selectedStore.get(`id`),
      category_sort_order: findSortCategoryId,
    }
    if (!_.isNil(selectedCategory)) {
      body.parent_category_id = selectedCategory.id
    }
    const res: ICategory[] = await new Promise((resolve) => {
      sortCategory({
        body,
        successCallback: resolve,
        failedCallback: resolve,
      })
    })
    if (!_.isArray(res)) {
      p.op.showConfirmationOkOnly(``, `เกิดข้อผิดพลาด กรุณาติดต่อทีมงาน XSelly ${res}`)
      this._goBack()
      return
    }
    const newSelectedCategory = _.isNil(selectedCategory) ? null : _.find(res, (cat: ICategory) => cat.id === selectedCategory.id)
    const newSupCat = await this._fineSupCat(newSelectedCategory, res)
    const newBreadCrumbs = await this._updateBreadCrumbs(newSelectedCategory, res)
    await util.setStatePromise(this, {
      category: res,
      subCategory: newSupCat,
      breadCrumbs: newBreadCrumbs,
      selectedCategory: newSelectedCategory,
      mode: VIEW,
      pageLoading: false,
    })
  }
}
