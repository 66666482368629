import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
// import { NavigationActions } from 'react-navigation'
import * as ContactActions from 'x/modules/contact/ContactState'
import * as AddressActions from 'x/modules/address/AddressState'
import CCustomerListView from './CCustomerListView'

export default connect(
  (state) => ({
    // @ts-ignore
    contactList: state.getIn(['contacts', 'customerList']),
    // @ts-ignore
    contactListGroup: state.getIn(['contacts', 'customerListGroup']),
  }),
  (dispatch) => ({
    // navigate: bindActionCreators(NavigationActions.navigate, dispatch),
    dispatch,
    // @ts-ignore
    fetchContactList: bindActionCreators(ContactActions.fetchCustomerList, dispatch),
    clearContact: bindActionCreators(ContactActions.clearContact, dispatch),
    pickAddress: bindActionCreators(AddressActions.load, dispatch),
    pickAddressToPatch: bindActionCreators(AddressActions.loadToPatch, dispatch),
    addCustomer: bindActionCreators(ContactActions.addCustomer, dispatch),
  })
  // @ts-ignore
)(CCustomerListView)
