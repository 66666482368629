// import { connect } from 'react-redux'
// import { NavigationActions } from 'react-navigation'
// import PaymentListView from './PaymentListView'
import { bindActionCreators } from 'redux'
import * as PaymentActions from './PaymentState'
import { getStorePaymentAccounts, getSelectedOrder } from '../../redux/selectors'

export function getState(state) {
  return {
    selectedPaymentList: state.getIn(['payment', 'selectedPaymentList']),
    shouldFetchList: state.getIn(['payment', 'shouldFetchList']),
    storePaymentAccounts: getStorePaymentAccounts(state),
    selectedOrder: getSelectedOrder(state),
  }
}

export function getDispatch(dispatch) {
  return {
    // paymentActions: bindActionCreators(PaymentActions, dispatch),
    clearPayment: bindActionCreators(PaymentActions.reset, dispatch),
    fetchPaymentList: bindActionCreators(PaymentActions.fetchPaymentList, dispatch),
    shouldFetchPaymentList: bindActionCreators(PaymentActions.shouldFetchPaymentList, dispatch),
    rejectPayment: bindActionCreators(PaymentActions.rejectPayment, dispatch),
    confirmPayment: bindActionCreators(PaymentActions.confirmPayment, dispatch),
    cancelPayment: bindActionCreators(PaymentActions.cancelPayment, dispatch),
    dispatch,
  }
}
