import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { getSelectedStore, getMyStores } from 'x/redux/selectors'
import * as OrderState from 'x/modules/order/OrderState'

import ShippingView from './ShippingView'

export default connect(
  (state: any) => ({
    selectedStore: getSelectedStore(state),
    myStores: getMyStores(state),
  }),
  (dispatch) => ({
    dispatch,
    fetchOrderDetail: bindActionCreators(OrderState.fetchOrderDetail, dispatch),
  })
)(ShippingView)
