function baseStyle(props: Record<string, any>) {
  const { colorScheme: c } = props
  return {
    // px: '2',
    px: '1.5',
    py: '0.5',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 'lg',
    borderTopLeftRadius: 'lg',
    borderTopRightRadius: 'lg',
    borderBottomLeftRadius: 'lg',
    borderBottomRightRadius: 'lg',
    _text: {
      fontSize: 'xs',
      fontWeight: 'medium',
    },
    // borderRadius: '2',
  }
}

function variantSolid(props: Record<string, any>) {
  const { colorScheme: c } = props
  return {
    // bg: mode(`${c}.600`, `${c}.300`)(props),
    _light: {
      _text: {
        color: 'coolGray.100',
      },
    },
    _dark: {
      _text: {
        color: 'coolGray.800',
      },
    },
    bg: `${c}.500`,
    // bg: mode(`${c}.500`, `${c}.500`),
    // _text: { color: mode(`coolGray.100`, `coolGray.800`) },
    borderWidth: '1',
    borderColor: 'transparent',
    borderRadius: '2',
  }
}

function variantSubtle(props: Record<string, any>) {
  const { colorScheme: c } = props
  return {
    _light: {
      bg: `${c}.200`,
      _text: {
        color: `${c}.600`,
      },
    },
    _dark: {
      bg: `${c}.700`,
      _text: {
        color: `${c}.200`,
      },
    },
    // bg: mode(`${c}.200`, `${c}.700`),
    // _text: { color: mode(`${c}.600`, `${c}.200`) },
    borderWidth: '1',
    // borderRadius: '2',
    borderColor: 'transparent',
  }
}

function variantOutline(props: Record<string, any>) {
  const { colorScheme: c } = props

  // const injectBorderProps = nbUtil.getInjectBorderUtilityProps({
  //   // borderColor: mode(`${c}.500`, `${c}.400`),
  //   borderWidth: '1',
  // })

  return {
    // ...injectBorderProps,
    borderWidth: '1',
    // borderColor: mode(`${c}.500`, `${c}.400`),
    _light: {
      borderColor: `${c}.500`,
      _text: {
        color: `${c}.500`,
      },
    },
    _dark: {
      borderColor: `${c}.400`,
      _text: {
        color: `${c}.400`,
      },
    },
    // _text: { color: mode(`${c}.500`, `${c}.400`) },
    // borderRadius: '2',
    // borderWidth: '1',
    bgColor: 'white',
  }
}

const variants = {
  solid: variantSolid,
  subtle: variantSubtle,
  outline: variantOutline,
}

const defaultProps = {
  // variant: 'solid',
  variant: 'subtle',
  colorScheme: 'primary',
}

export default {
  baseStyle,
  variants,
  defaultProps,
}
