import React, { forwardRef } from 'react'
import { View } from 'react-native'
import { IBoxProps } from 'native-base'
// import styled from 'styled-components/native'
// import { system } from 'styled-system'
// import { getStyleAndFilteredProps, propConfig } from 'x/config/nativebase/styled-system'
// import theme from 'x/config/nativebase/theme'
// import stableHash from 'stable-hash'
import makeStyledComponent from 'x/config/nativebase/makeStyledComponent'

// const isNumber = function isNumber(n) {
//   return typeof n === 'number' && !isNaN(n)
// }

// const getWidth = function getWidth(n, scale) {
//   return get(scale, n, !isNumber(n) || n > 1 ? n : `${n * 100}%`)
// }

// const config = {
//   width: {
//     property: 'width',
//     scale: 'sizes',
//     transform: getWidth,
//   },
//   w: {
//     property: 'width',
//     scale: 'sizes',
//     transform: getWidth,
//   },
//   height: {
//     property: 'height',
//     scale: 'sizes',
//   },
//   h: {
//     property: 'height',
//     scale: 'sizes',
//   },
//   minWidth: {
//     property: 'minWidth',
//     scale: 'sizes',
//   },
//   minHeight: {
//     property: 'minHeight',
//     scale: 'sizes',
//   },
//   maxWidth: {
//     property: 'maxWidth',
//     scale: 'sizes',
//   },
//   maxHeight: {
//     property: 'maxHeight',
//     scale: 'sizes',
//   },
//   size: {
//     properties: ['width', 'height'],
//     scale: 'sizes',
//   },
//   overflow: true,
//   overflowX: true,
//   overflowY: true,
//   display: true,
//   verticalAlign: true,
// }
// // @ts-ignore
// const layout = system(config)

// // @ts-ignore
// const nbBox = system(propConfig)

// import { getStyledSystemPropsAndRestProps } from 'native-base/src/utils/styled'

// type ITheme = typeof baseTheme
// interface LayoutPropsExtended extends LayoutProps {
//   w?: keyof ITheme['sizes'] | string | number
//   h?: keyof ITheme['sizes'] | string | number
// }

// interface IBoxProps extends ViewProps, SpaceProps, LayoutPropsExtended, ColorProps, PositionProps, FlexboxProps, BorderProps {
//   flex?: any

//   [key: string]: any
// }

// const utilityStyle = compose(
//   {
//     // @ts-ignore
//     boxSizing: 'border-box',
//   },
//   space,
//   layout,
//   color,
//   position,
//   flexbox,
//   border
// )

// const StyledView = styled.View(nbBox)
// const StyledView = makeStyledComponent(View, 'Box')

// const Box = React.forwardRef<View, IBoxProps>((props, ref) => {
const BoxBase = forwardRef(
  (props, ref) => (
    // const computedProps = computeStyledProps('Box', props)
    // console.log('Box props => ', props)
    // @ts-ignore
    // <View ref={ref} style={[systemdStyle.box, style]} {...restRNProps} />
    <View ref={ref} {...props} />
    // <StyledView ref={ref} {...restProps} />
  )
  // })
)

// Box.displayName = 'XBox'
// Box.displayName = getComponentDefaultProps('Box')

// const s = StyleSheet.create({
//   defaultBox: {
//     flexDirection: 'column',
//   },
// })

const Box = makeStyledComponent(BoxBase, 'Box')
Box.displayName = 'XBox'

export default Box as React.FC<IBoxProps>
