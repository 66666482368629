import React, { PureComponent } from 'react'

import Icon from 'xui/components/Icon'
import { TouchableOpacity } from 'react-native'
import _ from 'lodash'
import { STYLES, COLORS } from 'x/config/styles'
import { IXRadioProps } from 'x/index'

class RadioIcon extends PureComponent<IXRadioProps> {
  render() {
    const { disabled = false, checked = null, iconStyle = {} } = this.props
    return (
      <Icon
        style={{
          fontSize: STYLES.FONT_ICON_NORMAL,
          height: STYLES.FONT_ICON_NORMAL,
          width: STYLES.FONT_ICON_NORMAL,
          color: disabled ? COLORS.TEXT_INACTIVE : COLORS.APP_MAIN,
          textAlign: 'center',
          ...iconStyle,
        }}
        name={checked ? 'radio-button-on' : 'radio-button-off'}
      />
    )
  }
}

export default class XRadio extends PureComponent<IXRadioProps> {
  _onPress = () => {
    const { index, data, onPress, onPressAtIndex } = this.props
    if (_.isFunction(onPress)) {
      onPress({ index, data })
    }
    if (_.isFunction(onPressAtIndex)) {
      onPressAtIndex(index)
    }
  }

  _renderRadio = () => <RadioIcon disabled={this.props.disabled || false} checked={this.props.checked || false} />

  render() {
    const { disabled = false, onPress = null, onPressAtIndex = null, buttonStyle = {} } = this.props
    if (!_.isFunction(onPress) && !_.isFunction(onPressAtIndex)) {
      return this._renderRadio()
    }
    return (
      <TouchableOpacity
        style={{
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          ...buttonStyle,
        }}
        disabled={disabled}
        onPress={this._onPress}>
        {this._renderRadio()}
      </TouchableOpacity>
    )
  }
}
