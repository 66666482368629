import React from 'react'
import XContent from 'xui/components/XContent'
import XCustomHeader from 'xui/components/XCustomHeader'
import BaseBankEditView from 'x/modules/store/storeSetting/BaseBankEditView'
import XContainer from 'xui/components/XContainer'
import HStack from 'xui/components/HStack'
import Box from 'xui/components/Box'
import XText from 'xui/components/XText'
import XInput from 'xui/components/XInput'
import XIcon from 'xui/components/XIcon'
import XCard from 'xui/components/XCard'
import XSwitch from 'xui/components/XSwitch'
import { TouchableOpacity } from 'react-native'
import _ from 'lodash'
import CONS from 'x/config/constants'
import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'
import XBankAccountListModel from 'xui/components/XBankAccountListModel'
import XBankAccountTypeModel from 'xui/components/XBankAccountTypeModel'
import XButton from 'xui/components/XButton'
import VStack from 'xui/components/VStack'
import XHelpModal from 'xui/components/XHelpModal'
import FAQ from 'x/config/FAQ'
import HelpButton from 'xui/components/HelpButton'

export default abstract class BaseUIStorePaymentAccountListView extends BaseBankEditView {
  // static displayName = 'BaseUIStoreSettingXShippingView'
  // abstract goBack: () => void

  renderCustomHeader = () => {
    const { mode, isEdit } = this.state
    const title = mode === 'EDIT' ? 'แก้ไขบัญชีรับชำระ' : 'เพิ่มบัญชีรับชำระ'
    // const store_id = this.props.selectedStore.get('id')
    // console.log('mode => ', mode)
    const rightBtn = isEdit
      ? {
          label: 'บันทึก',
          onPressItem: () => this._saveAction(),
        }
      : null
    return (
      <XCustomHeader
        headerLeftProps={{
          backIcon: true,
          onPressItem: () => {
            if (!isEdit) {
              util.navGoBack(this.props)
              return
            }
            const ms = 'ยังไม่ได้บันทึกข้อมูล  ต้องการออกจากหน้านี้ใช่หรือไม่'
            p.op.showConfirmation(
              '',
              ms,
              () => null,
              () => util.navGoBack(this.props),
              'อยู่หน้านี้',
              'ออกจากหน้านี้'
            )
          },
        }}
        headerRightProps={rightBtn}
        title={title}
      />
    )
  }

  _renderInputBankAccountName = () => {
    const { bankAccount } = this.state
    const textBankAccountName = bankAccount.name
    return (
      <HStack w='full' space='2' alignItems='center' mt='2'>
        <Box w='20' alignItems='flex-start'>
          <XText textAlign='right' variant='inactive'>
            ชื่อบัญชี
          </XText>
        </Box>
        <XInput
          flex={1}
          placeholder='ชื่อบัญชี'
          value={textBankAccountName}
          onChangeText={(text: string) => this._valueOnChange('name', text)}
        />
      </HStack>
    )
  }

  _renderInputBankAccountNumber = () => {
    const { bankAccount } = this.state
    const textBankAccountNumber = bankAccount.account_number
    return (
      <HStack w='full' space='2' alignItems='center' mt='2'>
        <Box w='20' alignItems='flex-start'>
          <XText textAlign='right' variant='inactive'>
            เลขบัญชี
          </XText>
        </Box>
        <XInput
          flex={1}
          placeholder='เช่น 000-0-12345-6'
          value={textBankAccountNumber}
          onChangeText={(text: string) => this._valueOnChange('account_number', text)}
        />
      </HStack>
    )
  }

  _renderSelectedBank = () => (
    <HStack space='2' alignItems='center' mt='4'>
      <Box w='20' alignItems='flex-start'>
        <XText textAlign='right' variant='inactive'>
          รูปแบบบัญชี
        </XText>
      </Box>
      <Box flex={1}>{this._renderBankListModel()}</Box>
    </HStack>
  )

  _renderSelectedBankAccountType = () => {
    const { bankAccount } = this.state
    const hasAccountype = this._isBankHaveBankAccountType(bankAccount.bank_id)
    // CONS.BANK_HAVE_ACCOUNT_TYPE.forEach((bank_id) => {
    //   if (bankAccount.bank_id === bank_id) {
    //     hasAccountype = true
    //   }
    // })
    // console.log('bankAccount => ', bankAccount)
    if (!hasAccountype) {
      return null
    }
    return (
      <HStack space='2' alignItems='center' mt='4'>
        <Box w='20' alignItems='flex-start'>
          <XText textAlign='right' variant='inactive'>
            ประเภทบัญชี
          </XText>
        </Box>
        <Box flex={1}>
          <Box borderWidth={1} py='2' borderRadius='md' borderColor='gray.200'>
            <XBankAccountTypeModel
              headerTitle='เลือกประเภทบัญชี'
              renderButton={(onPressOpenOverlay: () => void) => this._renderBtnOpenBankTypeModel(onPressOpenOverlay)}
              onSelected={(bank_type: string) => this._bankTypeOnChange(bank_type)}
            />
          </Box>
        </Box>
      </HStack>
    )
  }

  _renderBtnOpenBankTypeModel = (onPressOpenOverlay: () => void) => {
    const { bankAccountType } = this.state
    let name = 'ยังไม่ระบุ'
    if (bankAccountType === 'CurrentAccount') {
      name = 'บัญชีกระแสรายวัน'
    }
    if (bankAccountType === 'SavingAccount') {
      name = 'บัญชีออมทรัพย์'
    }
    if (bankAccountType === 'FixedDeposit') {
      name = 'บัญชีเงินฝากประจำ'
    }
    return (
      <TouchableOpacity onPress={onPressOpenOverlay} style={{ flex: 1, flexDirection: 'row' }}>
        <Box flex={1} alignItems='center' justifyContent='center'>
          <XText>{name}</XText>
        </Box>
        <Box alignItems='center' justifyContent='center'>
          <XIcon name='arrowright' family='AntDesign' mr='2' />
        </Box>
      </TouchableOpacity>
    )
  }

  _renderPeakAccountSwitch = () => (
    <HStack space='2' alignItems='center' mt='1'>
      <Box flex={1} alignItems='flex-start' justifyContent='center'>
        <XText textAlign='right' variant='inactive'>
          ตั้งค่าเพิ่มเติมสำหรับ PEAK account
        </XText>
      </Box>
      <Box alignItems='center' justifyContent='center'>
        <XSwitch
          value={this.state.isPeakAccountSwitch}
          onValueChange={(newValue: boolean) => {
            this._setEdit()
            this.setState({ isPeakAccountSwitch: newValue })
          }}
        />
      </Box>
    </HStack>
  )

  _renderInputMainPeakAccount = () => {
    const { bankAccount, isPeakAccountSwitch } = this.state
    if (!isPeakAccountSwitch) {
      return null
    }
    const textMainPeakAccount =
      bankAccount.settings_json && bankAccount.settings_json.peak && bankAccount.settings_json.peak.main_acct_num
        ? bankAccount.settings_json.peak.main_acct_num
        : ''
    // console.log('textMainPeakAccount => ', textMainPeakAccount)
    return (
      <HStack w='full' space='2' alignItems='center' mt='2'>
        <Box w='20' alignItems='flex-start'>
          <XText textAlign='right' variant='inactive'>
            รหัสบัญชี
          </XText>
        </Box>
        <XInput
          flex={1}
          placeholder='เช่น 1201201'
          value={textMainPeakAccount}
          onChangeText={(text: string) => this._valuePeakOnChange('main_acct_num', text)}
        />
      </HStack>
    )
  }

  _renderInputSupPeakAccount = () => {
    const { bankAccount, isPeakAccountSwitch } = this.state
    if (!isPeakAccountSwitch) {
      return null
    }
    const textSupPeakAccount =
      bankAccount.settings_json && bankAccount.settings_json.peak && bankAccount.settings_json.peak.sub_acct_num
        ? bankAccount.settings_json.peak.sub_acct_num
        : ''
    return (
      <HStack w='full' space='2' alignItems='center' mt='2'>
        <Box w='20' alignItems='flex-start'>
          <XText textAlign='right' variant='inactive'>
            รหัสบัญชีย่อย
          </XText>
        </Box>
        <XInput
          flex={1}
          placeholder='เช่น 1301301'
          value={textSupPeakAccount}
          onChangeText={(text: string) => this._valuePeakOnChange('sub_acct_num', text)}
        />
      </HStack>
    )
  }

  _renderDeleteBtn = () => {
    const account = util.getNavParam(this.props, 'account')
    if (_.isNil(account)) {
      return null
    }
    return (
      <HStack justifyContent='center'>
        <XButton
          mt='8'
          bgColor='red.500'
          onPress={() => {
            const { bankAccount } = this.state
            p.op.showConfirmation(
              `ลบบัญชีรับชำระ ${bankAccount.name}`,
              'กด "ตกลง" เพื่อลบบัญชีรับชำระ',
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              () => this._deleteAccount(),
              () => null,
              'ตกลง',
              'ยกเลิก'
            )
          }}>
          ลบบัญชีรับชำระนี้
        </XButton>
      </HStack>
    )
  }

  _renderInfoSectionView = () => (
    <VStack mt='4'>
      <XText variant='active' bold>
        ข้อมูลทั่วไป
      </XText>
      {this._renderInputBankAccountName()}
      {this._renderInputBankAccountNumber()}
      {this._renderSelectedBank()}
      {this._renderSelectedBankAccountType()}
    </VStack>
  )

  _renderPeakSectionView = () => (
    <VStack mt='6'>
      <XText variant='active' bold>
        รายงาน Excel PEAK Account
      </XText>
      {this._renderPeakAccountSwitch()}
    </VStack>
  )

  _renderLinkOrderSectionView = () => {
    const _renderHelpBtn = (onPressOpenOverlay: () => void) => <HelpButton onPress={() => onPressOpenOverlay()} />
    return (
      <VStack mt='6'>
        <XText variant='active' bold>
          ลิงก์ออเดอร์
        </XText>
        <HStack space='2' alignItems='center' mt='1'>
          <HStack flex={1} alignItems='center'>
            <XText textAlign='center' variant='inactive'>
              ซ่อนบัญชีนี้จากลิงก์ออเดอร์
            </XText>
            <XHelpModal
              FAQ={FAQ.HIDE_PAYMENT_ACCOUNT_FROM_ORDER_LINK}
              // FIXME: O: เพราะ typed ไม่ถูกตามที่ประกาศไว้
              // @ts-ignore
              initiateOpenIndex={[0, 1]}
              headerTitle='ซ่อนบัญชีนี้จากลิงก์ออเดอร์'
              renderButton={(onPressOpenOverlay: () => void) => _renderHelpBtn(onPressOpenOverlay)}
            />
          </HStack>
          <Box alignItems='center' justifyContent='center'>
            <XSwitch
              value={this.state.isHideW4C}
              onValueChange={(newValue: boolean) => {
                this._setEdit()
                this.setState({ isHideW4C: newValue })
              }}
            />
          </Box>
        </HStack>
      </VStack>
    )
  }

  _renderBody = () => {
    const { bankAccount } = this.state
    if (_.isNil(bankAccount)) {
      return null
    }
    return (
      <XCard m='2' pb='2' px='2'>
        {this._renderInfoSectionView()}

        {this._renderLinkOrderSectionView()}

        {this._renderPeakSectionView()}

        {this._renderInputMainPeakAccount()}
        {this._renderInputSupPeakAccount()}
        {this._renderDeleteBtn()}
        <HStack h='3' />
      </XCard>
    )
  }

  _renderBtnOpenBankListModel = (onPressOpenOverlay: () => void) => {
    const { bankAccount } = this.state
    const foundBankIndex = CONS.BANK_INFO.findIndex((bi) => bi.id === bankAccount.bank_id)
    const bankNameKey = foundBankIndex > -1 ? CONS.BANK_INFO[foundBankIndex].key : CONS.BANK_INFO[0].key
    return (
      <TouchableOpacity onPress={onPressOpenOverlay} style={{ flex: 1, flexDirection: 'row' }}>
        <Box flex={1} alignItems='center' justifyContent='center'>
          <XText>{p.op.t(`Bank.${bankNameKey}`)}</XText>
        </Box>
        <Box alignItems='center' justifyContent='center'>
          <XIcon name='arrowright' family='AntDesign' mr='2' />
        </Box>
      </TouchableOpacity>
    )
  }

  _renderBankListModel = () => {
    const isVerifySlipBankAccountOnly = util.getNavParam(this.props, 'isVerifySlipBankAccountOnly', false)
    return (
      <Box borderWidth={1} py='2' borderRadius='md' borderColor='gray.200'>
        <XBankAccountListModel
          headerTitle='เลือกรูปแบบบัญชี'
          renderButton={(onPressOpenOverlay: () => void) => this._renderBtnOpenBankListModel(onPressOpenOverlay)}
          onSelected={(bank_id: number) => this._bankIdOnChange(bank_id)}
          isVerifySlipBankAccountOnly={isVerifySlipBankAccountOnly}
        />
      </Box>
    )
  }

  _bankTypeOnChange = (bank_type: string) => {
    // console.log('bank_type => ', bank_type)
    this._setEdit()
    // @ts-ignore
    this.setState({ bankAccountType: bank_type })
  }

  render() {
    return (
      <XContainer>
        <XContent>
          {this.renderCustomHeader()}
          {this._renderBody()}
        </XContent>
      </XContainer>
    )
  }
}
