import { connect } from 'react-redux'
import { getState, getDispatch } from 'x/modules/marketplace/MkpProductDetailConnect'
import MkpProductDetailView from './MkpProductDetailView'
import { withMkpProductContextPropsAndRef } from './MkpProductContextHOC'

const MkpProductDetailViewContainer = withMkpProductContextPropsAndRef(MkpProductDetailView)
// // @ts-ignore
// MkpProductDetailViewContainer.navigationOptions = { header: null }

export default connect(
  state => ({
    ...getState(state),
  }),
  dispatch => ({
    ...getDispatch(dispatch),
  })
)(MkpProductDetailViewContainer)
