import React from 'react'
import { TouchableOpacity } from 'react-native'

import CONS from 'x/config/constants'
import _ from 'lodash'
// import s from './_style'
// import StickyKeyboardTab from '../../components/StickyKeyboardTab'
import BaseAddressView from 'x/modules/address/BaseAddressView'
// import XIcon from 'xui/components/XIcon'
// import * as xUtil from 'x/utils/util'
import * as util from 'x/utils/util'
import Segment from 'xui/components/Segment'
import XCustomHeader from 'xui/components/XCustomHeader'
import XCard from 'xui/components/XCard'
import VStack from 'xui/components/VStack'
import Box from 'xui/components/Box'
import HStack from 'xui/components/HStack'
import XIcon from 'xui/components/XIcon'
import XText from 'xui/components/XText'
import XInput from 'xui/components/XInput'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'

// interface IFieldAddress {
//   lable: string
//   key: string
//   inputType: string
//   required: boolean
// }

export default abstract class BaseUIAddressView extends BaseAddressView {
  static displayName = 'BaseUIAddressView'

  abstract goBack: () => void

  // static propTypes = {
  //   // selectedAddresses: PropTypes.object, //Immutable List
  //   selectedAddress: PropTypes.object.isRequired, //Immutable
  //   editingAddress: PropTypes.object.isRequired, //Immutable

  //   load: PropTypes.func.isRequired,
  //   reset: PropTypes.func.isRequired,
  //   onChange: PropTypes.func.isRequired,
  //   saveNew: PropTypes.func.isRequired,
  //   saveEditing: PropTypes.func.isRequired,

  //   navigation: PropTypes.object.isRequired,
  // }

  // static navigationOptions = ({ navigation }) => {
  //   const { goBack, dispatch, state } = navigation
  //   const { mode, handleBtn } = state.params
  //   const { CUSTOMER_EDIT, STORE_EDIT } = CONS.ADDRESS_MODE_KEYS
  //   let title = ''
  //   if (_.has(CONS.ADDRESS_MODE_KEYS, mode)) {
  //     title = p.op.t(`AddressView.TitleByMode.${mode}`)
  //   }

  //   return {
  //     headerLeft: (
  //       <Button
  //         transparent
  //         onPress={() => {
  //           goBack(state.key)
  //         }}>
  //         <BackIcon />
  //       </Button>
  //     ),
  //     title,
  //     headerRight: handleBtn ? (
  //       <Button onPress={() => handleBtn()}>
  //         <Text style={{ color: COLORS.FORM_PRIMARY, paddingLeft: 16, paddingRight: 16 }}>บันทึก</Text>
  //       </Button>
  //     ) : null,
  //   }
  // }

  renderCustomHeader = () => {
    // const { navigation } = this.props
    // // const { mode } = this.state
    // const { state } = navigation
    // const { headerTitle } = state.params
    // // const isEditMode = mode === EDIT
    // // let title = ''
    // // if (_.has(CONS.ADDRESS_MODE_KEYS, mode)) {
    // //   title = p.op.t(`AddressView.TitleByMode.${mode}`)
    // // }
    const headerTitle = util.getNavParam(this.props, 'headerTitle')
    const leftBtn = { backIcon: true, onPressItem: () => this.goBack() }
    const rightBtn = {
      label: `บันทึก`,
      onPressItem: () => this._handleSubmitForm(),
    }
    // title = `ที่อยู่`
    return <XCustomHeader title={headerTitle} headerLeftProps={leftBtn} headerRightProps={rightBtn} />
  }

  _renderEInput = (label: string, key: string, value: string | number, required: boolean) => {
    const placeholder = this._getPlaceholderInput(key)
    return (
      <HStack w='full' space='2' alignItems='center'>
        <Box w='24'>
          <XText textAlign='right' variant='inactive'>
            {label}
          </XText>
        </Box>
        <XInput
          flex={1}
          mr='2'
          value={_.isNumber(value) ? value.toString() : value}
          placeholder={placeholder}
          onChangeText={(text: string) => this.onChangeText(key, text)}
        />
      </HStack>
    )
  }

  _renderEntityInput = () => {
    const { segmentLegalEntityTypeIndex } = this.state
    const { address } = this.state
    // const indexEntityOption = address.legal_entity_type
    const entityTxt = address.legal_entity_id
    // const label = indexEntityOption === 1 ? `เลขประจำตัวประชาชน` : `เลขประจำตัวผู้เสียภาษี`

    if (segmentLegalEntityTypeIndex === 0) {
      return null
    }

    const label = CONS.ADDRESS_LEGAL_ENTITY_LABELS[segmentLegalEntityTypeIndex]
    return (
      <HStack w='full' space='2' alignItems='center'>
        <Box w='24'>
          <XText textAlign='right' variant='inactive'>
            {label}
          </XText>
        </Box>
        <XInput flex={1} mr='2' value={entityTxt} onChangeText={(text: string) => this.onChangeText('legal_entity_id', text)} />
      </HStack>
    )
  }

  _onSegmentLegalEntityTypeChange = (newIndex: number) => {
    const newState: any = { segmentLegalEntityTypeIndex: newIndex }
    if (newIndex === 0) {
      newState.radioLegalEntityTypeMoreId = null
    } else if (newIndex === 1) {
      newState.radioLegalEntityTypeMoreId = CONS.ADDRESS_LEGAL_ENTITY_TYPE_INDIVIDUAL_MORE_OPTIONS[0].id
    } else if (newIndex === 2) {
      newState.radioLegalEntityTypeMoreId = null
    }

    this.setState(newState)
  }

  _renderEntityType = () => {
    // const { indexEntityOption } = this.state
    const { segmentLegalEntityTypeIndex = 0 } = this.state
    // const { address, segmentLegalEntityTypeIndex = 0 } = this.state
    // const indexEntityOption = _.isNil(address.legal_entity_type) ? 0 : address.legal_entity_type
    return (
      <HStack w='full' space='2' alignItems='center'>
        <Box w='24'>
          <XText textAlign='right' variant='inactive'>
            สภาพบุคคล
          </XText>
        </Box>
        <Box flex={1} mr='2'>
          <Segment
            onSegmentChange={this._onSegmentLegalEntityTypeChange}
            options={CONS.ADDRESS_LEGAL_ENTITY_TYPE_OPTIONS}
            selectedIndex={segmentLegalEntityTypeIndex}
          />
        </Box>
      </HStack>
    )
  }

  _onRadioLegalEntityTypeMorePress = (legalEntityTypeId: number) => {
    this.setState({ radioLegalEntityTypeMoreId: legalEntityTypeId })
  }

  _renderEntityTypeMoreItem = (opt, index: number) => {
    if (opt.isHidden) {
      return null
    }

    const { radioLegalEntityTypeMoreId } = this.state
    const checked = opt.id === radioLegalEntityTypeMoreId
    return (
      <HStack key={`entityTypeMoreItem-${index}`} pb='1'>
        <TouchableOpacity onPress={() => this._onRadioLegalEntityTypeMorePress(opt.id)}>
          <HStack
            px='2'
            py='1'
            space='1'
            alignItems='center'
            borderWidth='1'
            borderColor={checked ? 'gray.400' : 'gray.200'}
            borderRadius='lg'>
            <XIcon name={checked ? 'radio-button-on' : 'radio-button-off'} />
            <XText>{opt.label}</XText>
          </HStack>
        </TouchableOpacity>
      </HStack>
    )
  }

  _renderEntityTypeMore = () => {
    const { segmentLegalEntityTypeIndex } = this.state
    if (segmentLegalEntityTypeIndex === 2) {
      return (
        <HStack w='full' space='2' alignItems='center'>
          <Box w='24' />
          <HStack flex={1} space='1.5' flexWrap='wrap'>
            {CONS.ADDRESS_LEGAL_ENTITY_TYPE_CORPORATION_MORE_OPTIONS.map(this._renderEntityTypeMoreItem)}
          </HStack>
        </HStack>
      )
    }

    if (segmentLegalEntityTypeIndex === 1) {
      return (
        <HStack w='full' space='2' alignItems='center'>
          <Box w='24' />
          <HStack flex={1} space='1.5' flexWrap='wrap'>
            {CONS.ADDRESS_LEGAL_ENTITY_TYPE_INDIVIDUAL_MORE_OPTIONS.map(this._renderEntityTypeMoreItem)}
          </HStack>
        </HStack>
      )
    }

    return null
  }

  _renderInputNote = () => {
    // const { editingAddress, onChange } = this.props
    const { address } = this.state
    const noteValue = address.note
    const indexEntityOption = address.legal_entity_type
    return (
      <HStack w='full' space='2' alignItems='center' mt={indexEntityOption === 0 ? '0' : '1'} mb='2'>
        <Box w='24'>
          <XText textAlign='right' variant='inactive'>
            หมายเหตุ
          </XText>
        </Box>
        <XInput flex={1} mr='2' value={noteValue} onChangeText={(text: string) => this.onChangeText('note', text)} />
      </HStack>
    )
  }

  _checkBoxPrimary = () => {
    const { address } = this.state
    // const { type } = this.props.navigation.state.params
    const type = util.getNavParam(this.props, 'type')
    if (type === 'CUSTOMER') {
      return null
    }
    // const { editingAddress, onChange, navigation } = this.props
    // const { isPrimaryOnly } = navigation.state.params
    // const { noteTxt } = this.state
    const isPrimary = _.isNil(address.is_primary) ? false : address.is_primary
    // let isPrimaryChange = !isPrimary
    // if (!_.isNil(isPrimaryOnly) && isPrimaryOnly) {
    //   isPrimary = true
    //   isPrimaryChange = true
    // } else if (!_.isNil(isPrimaryOnly) && !isPrimaryOnly) {
    //   isPrimary = false
    //   isPrimaryChange = false
    // }
    return (
      <HStack w='full' space='2' alignItems='center'>
        <Box w='24' />
        <TouchableOpacity onPress={() => this.onChangeIsPrimary(!isPrimary)}>
          <HStack flex={1} space='2' alignItems='center'>
            <XIcon name={!isPrimary ? 'square-o' : 'check-square-o'} family='FontAwesome' />
            <XText variant='inactive'>เลือกเป็นที่อยู่หลัก</XText>
          </HStack>
        </TouchableOpacity>
      </HStack>
    )
  }

  _renderBody() {
    // const { editingAddress } = this.props
    const { address } = this.state
    if (_.isNil(address)) {
      return null
    }
    return (
      <XCard w='full'>
        <VStack w='full' p='2' space='2'>
          {/* <ScrollView> */}
          {this._checkBoxPrimary()}
          {CONS.CONTACT_EDITABLE_ADDRESS_KEYS.map((field) => {
            const { label } = field
            const { key } = field
            const value = address[key]
            const { required } = field
            if (field.inputType === 'text') {
              return this._renderEInput(label, key, value, required)
            }
          })}
          {this._renderEntityType()}
          {this._renderEntityTypeMore()}
          {this._renderEntityInput()}
          {this._renderInputNote()}
          {/* <StickyKeyboardTab arrayOfRefs={this.inputRefs} currentRef={this.state.currentRef} scrollRef={this.scrollViewRef} /> */}
          {/* </ScrollView> */}
        </VStack>
      </XCard>
    )
  }

  render() {
    // util.logRender(this)
    // const { indexEntityOption } = this.state
    // console.log(`editingAddress`, editingAddress.toJS())
    return (
      <XContainer>
        {this.renderCustomHeader()}
        <XContent>
          <VStack w='full' p='2'>
            {this._renderBody()}
          </VStack>
          {/* {p.op.isIOS() ? (
                <KeyboardAvoidingView
                  style={{ flex: 1, flexDirection: 'column', justifyContent: 'center' }}
                  behavior='padding'
                  enabled
                  keyboardVerticalOffset={50}>
                  {this._renderBody()}
                </KeyboardAvoidingView>
              ) : (
                <Box w='full'>{this._renderBody()}</Box>
              )} */}
        </XContent>
      </XContainer>
    )
  }
}

/* <XContent
          ref={r => r && (this.scrollViewRef = r._root)}
          keyboardShouldPersistTaps={CONS.KEYBOARD_PERSIST_TAB_MODE}
          disableKBDismissScroll={false}
          enableResetScrollToCoords={false}
          extraScrollHeight={50}>
          <List style={{ width: '100%', paddingLeft: 10, paddingRight: 10 }}>{this._renderEditableAddressCard(editingAddress)}</List>
        </XContent> */
