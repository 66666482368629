import React from 'react'

// import PropTypes from 'prop-types'
import { View, Image, Platform, StyleSheet } from 'react-native'
import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import { COLORS, STYLES } from 'x/config/styles'
import p from 'x/config/platform-specific'

// import DateRangePicker from '../../components/DateRangePicker'
// import * as NavActions from '../../services/navigation'
// import * as utilN from '../../utils/util-native'

import BasePrintShippingLabelView from 'x/modules/print/BasePrintShippingLabelView'
import * as xUtil from 'x/utils/util'
import XCustomHeader from 'xui/components/XCustomHeader'
import ShippingTypeSelectorSegmentView from 'xui/components/ShippingTypeSelectorSegmentView'
import XIcon, { ICON_FAMILY } from 'xui/components/XIcon'
import XButtonWithIcon from 'xui/components/XButtonWithIcon'
import XCard from 'xui/components/XCard'
import XText from 'xui/components/XText'
import XBlankButton from 'xui/components/XBlankButton'
import Segment from '../../components/Segment'
import XContent from '../../components/XContent'
import XContainer from '../../components/XContainer'

const logoPaperang = require('../../images/logo_paperang.png')

export default abstract class BaseUIPrintShippingLabelView extends BasePrintShippingLabelView {
  static displayName = 'BaseUIPrintShippingLabelView'
  // static navigationOptions = ({ navigation }) => {
  //   const { goBack, state } = navigation
  //   return {
  //     headerLeft: (
  //       <Button onPress={() => goBack(state.key)}>
  //         <BackIcon />
  //       </Button>
  //     ),
  //     title: 'ดาวน์โหลดใบปะหน้าพัสดุ',
  //     headerRight: null,
  //   }
  // }

  // static propTypes = {
  //   navigation: PropTypes.object.isRequired,
  // }

  abstract onOpenPDFFromUrl(url: string, fileName: string): Promise<void>

  abstract onOpenPaperangFromUrl(url: string): void | Promise<void>

  abstract renderDateTimePicker(): JSX.Element

  constructor(props) {
    super(props)

    this._renderInlineContentWithLabel = this._renderInlineContentWithLabel.bind(this)
    // this._renderDateTimePickerCard = this._renderDateTimePickerCard.bind(this)
    // this._renderDateTimePicker = this._renderDateTimePicker.bind(this)
  }

  _getParams(): { store_id: number } | null {
    const params = xUtil.getNavParams(this.props)
    if (params) {
      return params
    }
    return null
  }

  // async onOpenPDFFromUrl(url: string, fileName: string): Promise<void> {
  //   await utilN.openPDFFromUrl(url, fileName)
  // }

  // onOpenPaperangFromUrl = (url: string) => {
  //   const { selectedStore } = this.props
  //   const paperangAutoSave = xUtil.checkPaperangAutoSaveImg(selectedStore)
  //   this.props.navigation.dispatch(
  //     NavActions.navToPaperangView({
  //       imageURL: url,
  //       noAutoSaveImgAtHeightBelow: xCONS.NO_AUTO_SAVE_IMG_AT_HEIGHT_BELOW_SHIPING_LABEL.COVER_SHEET,
  //       disabledAutoSave: paperangAutoSave,
  //     })
  //   )
  // }

  // _renderDateTimePicker() {
  //   // const date = mode === this.START_DATE ? this.state.startDate : this.state.endDate
  //   // const tDate = date.format('DD/MM/YYYY')
  //   // const tTime = date.format('HH:mm')
  //   const { startDate, endDate } = this.state
  //   return (
  //     <View style={{ width: '100%' }}>
  //       <DateRangePicker
  //         // allowOnlyDaysFromToday={31}
  //         selectedDates={{ begin: startDate, end: endDate }}
  //         onChangeDate={newDates => this._onDateChange(newDates)}
  //         showTime={true}
  //       />
  //     </View>
  //   )
  // }

  _renderInlineContentWithLabel({ label, content }) {
    return (
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: 10,
          alignItems: 'center',
        }}>
        <View style={{ paddingLeft: 15 }}>
          <XText style={s.txtLabel}>{label}</XText>
        </View>
        {content}
      </View>
    )
  }

  // _renderDateTimePickerCard(mode) {
  //   const date = mode === this.START_DATE ? this.state.startDate : this.state.endDate
  //   const tDate = date.format('DD/MM/YYYY')
  //   const tTime = date.format('HH:mm')
  //
  //   return (
  //     <View style={{ width: 190 }}>
  //       <Item style={{ height: 35 }} button onPress={() => {
  //         if (mode === this.START_DATE) {
  //           this.startDatePickerRef.onPressDate()
  //         } else {
  //           this.endDatePickerRef.onPressDate()
  //         }
  //       }}>
  //         <Left>
  //           <XText style={s.txtNormal}>{`${tDate}`}</XText>
  //         </Left>
  //         {/* <XText style={s.txtRight}>{`${tTime} น.`}</XText> */}
  //         <Right style={{ borderBottomWidth: 0 }}>
  //           {/* <Icon name='arrow-forward' /> */}
  //           <XText style={s.txtRight}>{`${tTime} น.`}</XText>
  //           <DatePicker
  //             ref={r => {
  //               if (r) {
  //                 if (mode === this.START_DATE) {
  //                   this.startDatePickerRef = r
  //                 } else {
  //                   this.endDatePickerRef = r
  //                 }
  //               }
  //             }}
  //             style={{ width: 0, height: 0 }}
  //             date={date.format()}
  //             showIcon={false}
  //             hideText={true}
  //             mode='datetime'
  //             androidMode='default'
  //             placeholder='เลือกวันเวลา'
  //             //format='YYYY-MM-DD hh:mm'
  //             confirmBtnText='ตกลง'
  //             cancelBtnText='ยกเลิก'
  //             is24Hour={true}
  //             customStyles={{
  //               // ... You can check the source to find the other keys.
  //               dateInput: {
  //                 borderWidth: 0,
  //               },
  //               dateText: {
  //                 color: COLORS.TEXT_ACTIVE,
  //                 fontSize: STYLES.FONT_SIZE_NORMAL,
  //               },
  //             }}
  //             onDateChange={(newDate) => this._onDateChange({
  //               key: 'date',
  //               value: moment(newDate).format(),
  //               mode: mode,
  //             })}
  //             TouchableComponent={TouchableOpacity}
  //           />
  //         </Right>
  //       </Item>
  //     </View>
  //   )
  // }

  // _renderTimePeriodBtn = ({ startH, startM, endH, endM }) => {
  //   return (
  //     <ListItem style={{ height: 50 }}>
  //       <TouchableOpacity
  //         onPress={() => this._setTimePeriod({ startH, startM, endH, endM })}
  //         style={[s.fullBtnContainer, btnOnMode(xCONS.BTN_MODE.ACTIVE_SECONDARY), { height: 35 }]}>
  //         <XText style={[s.txtLabel]}>{`จาก ${startH}:${startM}น. จนถึง ${endH}:${endM}น.`}</XText>
  //       </TouchableOpacity>
  //     </ListItem>
  //   )
  // }

  _renderFooter = () => {
    const { downloading = false } = this.state
    const { selectedStore } = this.props
    let isActivePaperang = !!(selectedStore.has('s_use_printing_paperang') && selectedStore.get('s_use_printing_paperang'))
    let isActiveA4 = !!(selectedStore.has('s_use_printing_pdf') && selectedStore.get('s_use_printing_pdf'))
    let isActiveSticker = !!(selectedStore.has('s_use_printing_sticker_pdf') && selectedStore.get('s_use_printing_sticker_pdf'))
    const btnTextH = Platform.OS === 'android' ? 42 : 50
    // showIsActiveA4ForWeb :: ถ้าตั้งค่าดาวโหลดเฉพาะ paperang เว็บต้องแสดงการดาวโหลด A4 PDF เป็นอย่างน้อย
    const showIsActiveA4ForWeb = !!(p.op.isWeb() && !isActiveA4 && !isActiveSticker)
    if (!isActivePaperang && !isActiveA4 && !isActiveSticker) {
      isActiveA4 = true
      isActivePaperang = true
      isActiveSticker = true
    }

    return (
      <HStack w='full' py='2' px='1' space='2'>
        {/* <HStack style={StyleSheet.flatten(STYLES.FOOTER_BG)}> */}
        {!p.op.isWeb() && isActivePaperang ? (
          <XBlankButton
            disabled={downloading}
            isDisabled={downloading}
            flex={1}
            variant='outline'
            colorScheme='#000'
            // style={StyleSheet.flatten([
            //   btnOnMode(xCONS.BTN_MODE.ACTIVE_PRIMARY),
            //   { borderColor: '#000', backgroundColor: '#fff', flexDirection: 'row', height: 50 },
            // ])}
            color={COLORS.TEXT_ACTIVE}
            leftIcon={
              <Image
                source={logoPaperang}
                style={{
                  height: 28,
                  width: 28,
                  resizeMode: 'contain',
                }}
              />
            }
            onPress={() => this._openPDF(`paperang`)}>
            {/* <XText
                // style={[
                //   textOnBtnMode(xCONS.BTN_MODE.ACTIVE_PRIMARY),
                //   {
                //     color: '#000',
                //     width: 100,
                //     height: btnTextH,
                //     fontSize: 14,
                //     textAlign: 'left',
                //     marginLeft: 8,
                //     ...Platform.select({
                //       ios: {
                //         paddingTop: 6,
                //       },
                //     }),
                //   },
                // ]}
                > */}
            พิมพ์ผ่าน Paperang
            {/* </XText> */}
          </XBlankButton>
        ) : null}

        {isActiveA4 || showIsActiveA4ForWeb ? (
          <XButtonWithIcon
            disabled={downloading}
            isDisabled={downloading}
            flex={1}
            _text={{
              fontSize: ['xs', 'xs', 'sm'],
            }}
            leftIcon={<XIcon activeDark name='picture-as-pdf' family={ICON_FAMILY.MaterialIcons} />}
            // @ts-ignore
            // style={StyleSheet.flatten([
            //   btnOnMode(xCONS.BTN_MODE.ACTIVE_PRIMARY),
            //   {
            //     flexDirection: 'row',
            //     height: 50,
            //     ...Platform.select({
            //       web: {
            //         justifyContent: 'flex-start',
            //       },
            //     }),
            //   },
            // ])}
            onPress={() => this._openPDF(`PDFA4`)}>
            {/* <XIcon
                name='picture-as-pdf'
                family={ICON_FAMILY.MaterialIcons}
                style={{
                  height: 20,
                  width: 10,
                  color: '#fff',
                  flex: 0,
                  paddingLeft: 0,
                  paddingRight: 0,
                  marginLeft: 2,
                  marginRight: 0,
                  ...Platform.select({
                    web: {
                      flex: 1,
                    },
                    ios: {
                      paddingTop: 4,
                      height: 30,
                    },
                  }),
                }}
              /> */}
            {/* <XText
                // @ts-ignore
                style={StyleSheet.flatten([
                  textOnBtnMode(xCONS.BTN_MODE.ACTIVE_PRIMARY),
                  {
                    width: 100,
                    height: btnTextH,
                    fontSize: 14,
                    textAlign: 'left',
                    ...Platform.select({
                      web: {
                        width: '100%',
                        height: undefined,
                        paddingRight: 30,
                      },
                      ios: {
                        paddingTop: 6,
                        marginLeft: -4,
                      },
                    }),
                  },
                ])}> */}
            โหลด PDF (A4)
            {/* </XText> */}
          </XButtonWithIcon>
        ) : null}

        {isActiveSticker ? (
          <XButtonWithIcon
            disabled={downloading}
            isDisabled={downloading}
            flex={1}
            _text={{
              fontSize: ['xs', 'xs', 'sm'],
            }}
            leftIcon={<XIcon activeDark name='picture-as-pdf' family={ICON_FAMILY.MaterialIcons} />}
            // @ts-ignore
            // style={StyleSheet.flatten([
            //   btnOnMode(xCONS.BTN_MODE.ACTIVE_PRIMARY),
            //   {
            //     flexDirection: 'row',
            //     height: 50,
            //     ...Platform.select({
            //       web: {
            //         justifyContent: 'flex-start',
            //       },
            //     }),
            //   },
            // ])}
            onPress={() => this._openPDF('PDFSticker')}>
            {/* <XIcon
                name='picture-as-pdf'
                family={ICON_FAMILY.MaterialIcons}
                style={{
                  height: 20,
                  width: 10,
                  color: '#fff',
                  flex: 0,
                  paddingLeft: 0,
                  paddingRight: 0,
                  marginLeft: 2,
                  marginRight: 0,
                  ...Platform.select({
                    web: {
                      flex: 1,
                    },
                    ios: {
                      paddingTop: 4,
                      height: 30,
                    },
                  }),
                }}
              /> */}
            {/* <XText
                // @ts-ignore
                style={StyleSheet.flatten([
                  textOnBtnMode(xCONS.BTN_MODE.ACTIVE_PRIMARY),
                  {
                    width: 100,
                    height: btnTextH,
                    fontSize: 14,
                    textAlign: 'left',
                    ...Platform.select({
                      web: {
                        width: '100%',
                        height: undefined,
                        paddingRight: 30,
                      },
                      ios: {
                        paddingTop: 6,
                        marginLeft: -4,
                      },
                    }),
                  },
                ])}> */}
            โหลด PDF (Sticker)
            {/* </XText> */}
          </XButtonWithIcon>
        ) : null}
        {/* </HStack> */}
      </HStack>
    )
  }

  // _downloadCallbackResult(progressInfo) {
  //   //{jobId, contentLength, bytesWritten}
  //   log('_downloadCallbackResult')
  //   log(progressInfo)
  // }

  _renderSegmentedControlOption(option, selected, onSelect, index) {
    const style: any = selected ? { fontWeight: 'bold', color: 'white' } : { color: COLORS.APP_MAIN }
    return (
      <View style={{ flex: 1, justifyContent: 'center' }}>
        <XText style={[style, { textAlign: 'center' }]}>{option}</XText>
      </View>
    )
  }

  // _renderSegmentedControlContainer(optionNodes) {
  //   return <View style={{ backgroundColor: 'orange'}}>{optionNodes}</View>
  // }

  _handleGoBack = () => {
    xUtil.navGoBack(this.props)
  }

  _renderBody = () => {
    const { selectedShippingTypeIds, visibleShippingTypes, downloading = false } = this.state
    return (
      // <XContent style={StyleSheet.flatten(s.content)}>
      <XContent>
        <VStack w='full' p='1'>
          <XCard>
            <XCard.Body>
              <VStack w='full' py='3' space='2'>
                <VStack>
                  <XText
                    bold
                    // style={s.segmentedControlTextLabel}
                  >
                    {'พิมพ์ออเดอร์ที่อยู่ในสถานะ "รอฉัน > จัดส่ง"'}
                  </XText>
                  <XText
                    variant='inactive'
                    // style={s.segmentedControlTextDesc}
                  >
                    โดยเรียงตามเวลาที่ออเดอร์พร้อมส่ง ก่อนไปหลัง
                  </XText>
                  {/* <View style={s.segmentedControlContainer}> */}
                  {/* <SegmentedControls */}
                  {/*  tint={COLORS.APP_MAIN} */}
                  {/*  selectedTint={'white'} */}
                  {/*  backTint={'white'} */}
                  {/*  allowFontScaling={false} */}
                  {/*  options={this.OPTIONS_PRINT_FILTER} */}
                  {/*  onSelection={this._setSelectedOptionPrintFilter} */}
                  {/*  selectedOption={this.state.selectedOptionPrintFilter} */}
                  {/*  renderOption={this._renderSegmentedControlOption} */}
                  {/* /> */}
                  <Segment
                    disabled={downloading}
                    selectedValue={this.state.selectedOptionPrintFilter}
                    options={this.OPTIONS_PRINT_FILTER}
                    onChange={this._setSelectedOptionPrintFilter}
                  />
                  {/* </View> */}
                </VStack>

                <VStack>
                  <XText
                    bold
                    // style={s.segmentedControlTextLabel}
                  >
                    จำนวนออเดอร์สูงสุดในการพิมพ์ต่อครั้ง
                  </XText>
                  {/* <View style={s.segmentedControlContainer}> */}
                  {/* <SegmentedControls */}
                  {/*  tint={COLORS.APP_MAIN} */}
                  {/*  selectedTint={'white'} */}
                  {/*  backTint={'white'} */}
                  {/*  allowFontScaling={false} */}
                  {/*  options={this.OPTIONS_ORDER_COUNT} */}
                  {/*  onSelection={this._setSelectedOptionOrderCount} */}
                  {/*  selectedOption={this.state.selectedOptionOrderCount} */}
                  {/*  renderOption={this._renderSegmentedControlOption} */}
                  {/* /> */}
                  <Segment
                    disabled={downloading}
                    selectedIndex={this.state.selectedOptionOrderCount}
                    options={this.OPTIONS_ORDER_COUNT}
                    onSegmentChange={(index: number) => this._setSelectedOptionOrderCountByIndex(index)}
                    // onChange={this._setSelectedOptionOrderCount}
                  />
                  {/* </View> */}
                </VStack>

                <VStack>
                  <XText
                    bold
                    // style={s.segmentedControlTextLabel}
                  >
                    ช่วงเวลาที่ต้องการ
                  </XText>
                  {/* <XText style={s.segmentedControlTextDesc}>นับจากเวลาที่ออเดอร์พร้อมส่ง</XText> */}

                  <XText
                    variant='inactive'
                    // style={s.segmentedControlTextDesc}
                  >
                    เลือกช่วงวันเวลาที่ออเดอร์...
                  </XText>

                  {/* <View style={s.segmentedControlContainer}> */}
                  {/* <SegmentedControls */}
                  {/*  tint={COLORS.APP_MAIN} */}
                  {/*  selectedTint={'white'} */}
                  {/*  backTint={'white'} */}
                  {/*  allowFontScaling={false} */}
                  {/*  options={this.OPTIONS_TIME_PERIOD} */}
                  {/*  onSelection={this._setSelectedOptionTimePeriod} */}
                  {/*  selectedOption={this.state.selectedOptionTimePeriod} */}
                  {/*  renderOption={this._renderSegmentedControlOption} */}
                  {/* /> */}
                  {/* <Segment
                selectedValue={this.state.selectedOptionTimePeriod}
                options={this.OPTIONS_TIME_PERIOD_MODE}
                onChange={this._setSelectedOptionTimePeriod}
              /> */}
                  <Segment
                    disabled={downloading}
                    selectedIndex={this.state.selectedOptionTimePeriodModeAtIndex}
                    options={this.OPTIONS_TIME_PERIOD_MODE}
                    onSegmentChange={this.onSegmentOptionTimePeriodChangeAtIndex}
                  />
                  {/* </View> */}
                </VStack>

                {this._isTimePeriodUsed() ? (
                  <VStack
                    m='2'
                    space='2'
                    // style={StyleSheet.flatten(s.formInCard)}
                  >
                    {/* {this._renderDateTimePicker()} */}
                    {this.renderDateTimePicker()}
                    {/* { */}
                    {/* this._renderInlineContentWithLabel({ */}
                    {/* label: 'จาก', content: this._renderDateTimePickerCard(this.START_DATE), */}
                    {/* }) */}
                    {/* } */}
                    {/* { */}
                    {/* this._renderInlineContentWithLabel({ */}
                    {/* label: 'จนถึง', content: this._renderDateTimePickerCard(this.END_DATE), */}
                    {/* }) */}
                    {/* } */}
                  </VStack>
                ) : null}

                <VStack>
                  <ShippingTypeSelectorSegmentView
                    disabled={downloading}
                    headertitle='เลือกรูปแบบจัดส่ง'
                    footerLable='ยืนยัน'
                    visibleShippingTypes={visibleShippingTypes}
                    activeShippingTypeIds={selectedShippingTypeIds}
                    onSubmit={(selectedShippingTypeIds: number[]) => {
                      xUtil.setStatePromise(this, {
                        selectedShippingTypeIds,
                      })
                    }}
                  />
                </VStack>
              </VStack>
            </XCard.Body>
          </XCard>
          {/* {this._renderTimePeriodBtn({ startH: '00', startM: '00', endH: '12', endM: '00' })} */}
          {/* {this._renderTimePeriodBtn({ startH: '12', startM: '00', endH: '15', endM: '00' })} */}
          {/* {this._renderTimePeriodBtn({ startH: '15', startM: '00', endH: '18', endM: '00' })} */}
          {/* {this._renderTimePeriodBtn({ startH: '18', startM: '00', endH: '21', endM: '00' })} */}
          {/* {this._renderTimePeriodBtn({ startH: '21', startM: '00', endH: '00', endM: '00' })} */}
        </VStack>
      </XContent>
    )
  }

  renderMain = () => {
    // console.log('selectedOptionOrderCount', this.state.selectedOptionOrderCount)
    // console.log('render ', this.state)
    const { downloading, loadingWidth, loadingHeight } = this.state
    return (
      <XContainer>
        <XCustomHeader
          title='ดาวน์โหลดใบปะหน้าพัสดุ'
          headerLeftProps={p.op.isWeb() ? undefined : { backIcon: true, onPressItem: this._handleGoBack }}
        />
        {/* <View
          style={{ flex: 1 }}
          onLayout={(event) => {
            const { x, y, width, height } = event.nativeEvent.layout
            xUtil.setStatePromise(this, { loadingWidth: width, loadingHeight: height })
          }}> */}
        {this._renderBody()}
        {this._renderFooter()}
        {/* </View> */}
        {/* {downloading ? VIEW_LOADING(loadingWidth, loadingHeight + 50) : null} */}
      </XContainer>
    )
  }
}

const s = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'space-between',
  },
  content: {
    flex: 1,
    paddingLeft: 5,
    paddingRight: 5,
    ...Platform.select({
      web: {
        backgroundColor: 'white',
      },
    }),
  },
  fullBtnContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  gridColumnContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  halfColumnContainer: {
    width: '50%',
    padding: 5,
  },
  pCardImg: { width: '100%', height: 100 },
  productListItem: {
    margin: 0,
    paddingLeft: 5,
    paddingRight: 5,
    marginLeft: 0,
    marginRight: 0,
  },
  formInCard: {
    alignSelf: 'stretch',
    margin: 0,
    flex: 1,
    paddingTop: 10,
    paddingLeft: p.op.isWeb() ? 0 : 10,
    paddingRight: p.op.isWeb() ? 0 : 16,
  },
  borderPrimary: {
    borderColor: COLORS.FORM_PRIMARY,
  },
  borderError: {
    borderColor: COLORS.FORM_ERROR,
  },
  borderSuccess: {
    borderColor: COLORS.FORM_SUCCESS,
  },
  separator: {
    height: 5,
  },
  cardStyle: {
    elevation: 3,
    width: '100%',
    flex: 1,
  },
  cardBody: {
    width: '100%',
    height: 220,
    flex: 1,
  },
  cardImg: {
    alignSelf: 'center',
    width: '100%',
    height: 200,
  },
  cardItem: {
    margin: 10,
    flexDirection: 'column',
    paddingBottom: 20,
  },
  segmentedControlTextLabel: {
    width: '100%',
    textAlign: 'left',
    paddingLeft: 2,
    paddingBottom: 4,
    fontWeight: 'bold',
    color: COLORS.TEXT_ACTIVE,
  },
  segmentedControlTextDesc: {
    width: '100%',
    textAlign: 'left',
    paddingLeft: 2,
    paddingBottom: 4,
    color: COLORS.TEXT_INACTIVE,
  },
  segmentedControlContainer: {
    width: '100%',
  },
  alignCenter: {
    alignSelf: 'center',
  },
  alignRight: {
    alignSelf: 'flex-end',
  },
  txtHeader: {
    fontSize: STYLES.FONT_SIZE_ORDERS_HEADER_ROW2,
    color: COLORS.TEXT_ACTIVE,
    fontWeight: 'bold',
  },
  txtNormal: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_ACTIVE,
  },
  txtHL: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_PRIMARY,
  },
  txtMultiple: {
    width: '100%',
    height: 100,
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_ACTIVE,
  },
  txtMultipleHL: {
    width: '100%',
    height: 100,
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.FORM_PRIMARY,
  },
  txtLabel: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_INACTIVE,
    // color: 'rgba(15,15,15,0.6)',
  },
  txtHLRight: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_PRIMARY,
    textAlign: 'right',
  },
  labelRight: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_INACTIVE,
    textAlign: 'right',
  },
  txtRight: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_ACTIVE,
    textAlign: 'right',
  },
  txtCenter: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_ACTIVE,
    textAlign: 'center',
  },
  iconHL: {
    fontSize: STYLES.FONT_SIZE_NORMAL + 4,
    color: COLORS.TEXT_PRIMARY,
    textAlign: 'right',
  },
  rowBox: {
    borderWidth: 1,
    borderColor: 'lightgrey',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 10,
    paddingBottom: 10,
  },
  colBox50: {
    width: '50%',
    borderWidth: 1,
    borderColor: 'lightgrey',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingTop: 10,
    paddingLeft: 5,
    paddingRight: 5,
    paddingBottom: 10,
  },
  camContainer: {
    width: 250,
    height: 200,
    paddingTop: 5,
  },
  preview: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  capture: {
    flex: 0,
    backgroundColor: '#fff',
    borderRadius: 5,
    color: '#000',
    padding: 10,
    margin: 40,
  },
  barcodeCutLine1: {
    position: 'absolute',
    top: 96,
    width: '100%',
    height: 2,
    backgroundColor: COLORS.FORM_ERROR_OPACITY,
  },
  barcodeCutLine2: {
    position: 'absolute',
    top: 104,
    width: '100%',
    height: 2,
    backgroundColor: COLORS.FORM_ERROR_OPACITY,
  },

  buttonIcon: {
    width: 38,
    height: 38,
    flex: 1,
    justifyContent: 'center',
    // alignItems: 'center',
    marginRight: 5,
    borderRadius: 5,
  },
  iconInButton: {
    color: COLORS.TEXT_ACTIVE_DARK,
    fontSize: STYLES.FONT_ICON_NORMAL,
    fontWeight: 'bold',
    textAlign: 'center',
  },
})
//
// const styles = StyleSheet.create({
//   pdfContainer: {
//     flex: 1,
//   },
// })
