// import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { ViewStyle } from 'react-native'
// import { COLORS, STYLES } from 'x/config/styles'
import { logRender } from 'x/utils/util'
import p from 'x/config/platform-specific'
import XIconButton from './XIconButton'

// const { FONT_ICON_NORMAL } = STYLES
// const { TEXT_INACTIVE } = COLORS

interface IHelpButtonProps {
  title: string
  message: string
  onPress?: () => void
  style?: ViewStyle
}

export default class HelpButton extends PureComponent<IHelpButtonProps> {
  // static propTypes = {
  //   title: PropTypes.string,
  //   message: PropTypes.string,
  //   onPress: PropTypes.func,
  //   style: PropTypes.any,
  // }

  static defaultProps = {
    title: '',
    message: '',
  }

  // constructor(props) {
  //   super(props)
  //   this._onPressHelpButton = this._onPressHelpButton.bind(this)
  // }

  _onPressHelpButton = () => {
    const { onPress } = this.props
    if (onPress) {
      onPress()
    } else {
      const { title, message } = this.props
      p.op.alert(title, message)
    }
  }

  render() {
    logRender(this)
    return (
      <XIconButton
        variant='ghost'
        colorScheme='muted'
        onPress={this._onPressHelpButton}
        name='help-circle-outline'
        iconVariant='inactive'
      />
    )
    // return (
    //   <TouchableOpacity onPress={this._onPressHelpButton} style={[s.mainContainer, this.props.style]}>
    //     <View
    //       style={{
    //         flex: 1,
    //         flexDirection: 'row',
    //         justifyContent: 'flex-start',
    //         alignItems: 'center',
    //       }}>
    //       <Icon name='help-circle-outline' style={s.icon} />
    //     </View>
    //   </TouchableOpacity>
    // )
  }
}

// const s = StyleSheet.create({
//   mainContainer: {
//     // flex: 1,
//     width: FONT_ICON_NORMAL + 8,
//     height: FONT_ICON_NORMAL + 8,
//     flexDirection: 'column',
//     justifyContent: 'center',
//     alignItems: 'center',
//     backgroundColor: 'transparent',
//     // paddingLeft: 4,
//     margin: 4,
//   },
//   icon: {
//     // paddingTop: 4,
//     // padding: 2,
//     fontSize: FONT_ICON_NORMAL,
//     width: FONT_ICON_NORMAL - 2, // minus offset cause icon
//     color: TEXT_INACTIVE,
//     // ...Platform.select({
//     //   android: {
//     //     minWidth: 25,
//     //   },
//     // }),
//   },
// })
