import React from 'react'
import { STYLES, COLORS } from 'x/config/styles'

import BaseUIReportRequisitionView from 'xui/modules/report/BaseUIReportRequisitionView'
import { View, Text } from 'react-native'
import * as XUtil from 'x/utils/util'
import XDateRangePicker from 'xui/components/XDateRangePicker'
import * as util from '../../utils/util-web'

export default class ReportRequisitionView extends BaseUIReportRequisitionView {
  _downloadReportRequisitionView = async (url: string, fileName: string) => {
    await XUtil.setStatePromise(this, { isLoading: true })
    if (url) {
      await util.downloadFileFromUrl({ url, fileName })
    }
    await XUtil.setStatePromise(this, { isLoading: false })
  }

  renderDetailShippingTypeCardButton = (data) => {
    const { item, index } = data
    let txtShippingType = null
    if (index > -1) {
      txtShippingType = this.shippingTypeItems[index].label
    } else if (index === -1 && item && item.value > 0) {
      txtShippingType = 'กรุณาดาวน์โหลดแอปเวอร์ชั่นใหม่ เพื่อแสดงผลข้อมูลนี้'
    } else {
      txtShippingType = 'ยังไม่ได้เลือก'
    }
    return (
      <View style={{ width: '100%', flexDirection: 'row' }} key={index}>
        <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE }}>{txtShippingType}</Text>
      </View>
    )
  }

  renderDateRangePicker = () => {
    const { selectedDateRange, optionDateTimeIndex } = this.state
    if (optionDateTimeIndex === 0) {
      return null
    }
    // const showTimeSelect = true
    return (
      <View style={{ width: `100%`, height: 100, flexDirection: 'column', marginTop: 6, alignItems: 'center' }}>
        <XDateRangePicker
          oneLineDateTime={false}
          // allowOnlyDaysFromToday={allowOnlyDaysFromToday}
          // allowDateRangeLimitDays={limitDay}
          selectedDates={selectedDateRange}
          onChangeDate={this._onChangeDateRange}
          showTime
          // showTimeSelect={showTimeSelect}
        />
      </View>
    )
  }
}
