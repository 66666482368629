import React from 'react'

import _ from 'lodash'

import p from 'x/config/platform-specific'
import CONS from 'x/config/constants'
import * as xUtil from 'x/utils/util'
import * as xAcl from 'x/utils/acl'
import { IMemberHelperList, IChannel } from 'x/index'

import dayjs, { Dayjs } from 'dayjs'

const {
  // PRODUCT_STOCK,
  REPORT_VIEW,
  PRODUCT_STOCK_VIEW,
  PRODUCT_STOCK_EDIT,
  PRODUCT_COST,
  PRODUCT_PRICE,
  PRODUCT_WEIGHT,
  PRODUCT_SHIPPING_QTY_RATE,
  PRODUCT_CODE_EDIT_SKU,
  PRODUCT_CODE_EDIT_UPC,
} = CONS.PERM_STORE_HELPER

interface IBaseReportHelperProps {
  subscription: any
  // onRequestClose: () => void
  // allowOnlyDaysFromToday: number
  selectedStore: any
  getPermissionMemberList: any
  // dateRangePicker: React.ComponentClass<IDateRangePickerProps, IDateRangePickerState>
  // handleOnDownloadFile: (url: string, fileName: string, type: string) => void
}

interface IBaseReportHelperState {
  selectedDateRange: { begin: Dayjs; end: Dayjs }
  selectedOptSegment: number
  warningDateRangeText: string
  loading: boolean
  haveHlepers: boolean
  hlepers: IMemberHelperList[]
  channelData: IChannel
  showSeletedChannel: boolean
  selectedChannelOtp: number
  overlayHeight: number
  viewReportIndex: number
  downloadingReport: boolean
  visibleOnlyProfileId: boolean
}

const startNewDate = dayjs().format('YYYY-MM-DD 00:00:00')
const endNewDate = dayjs().format('YYYY-MM-DD 23:59:59')

export default abstract class BaseReportPayment extends React.Component<IBaseReportHelperProps, IBaseReportHelperState> {
  DATE_RANGE_REPORT_PAYMENT: string[]

  CHANNEL_OTP: string[]

  VIEW_REPORT_FROM: string[]

  inProcess: boolean

  abstract _handleOnDownloadFileByPlatform(url: string, fileName: string): Promise<void>

  constructor(props: IBaseReportHelperProps) {
    super(props)
    this.state = {
      selectedDateRange: { begin: dayjs(startNewDate), end: dayjs(endNewDate) },
      selectedOptSegment: 0,
      warningDateRangeText: '',
      loading: false,
      haveHlepers: false,
      hlepers: null,
      channelData: null,
      showSeletedChannel: false,
      selectedChannelOtp: 0,
      overlayHeight: 450,
      viewReportIndex: 0,
      downloadingReport: false,
      visibleOnlyProfileId: false,
    }
    this.DATE_RANGE_REPORT_PAYMENT = ['สร้างเมื่อ', 'เสร็จสิ้นเมื่อ']
    this.CHANNEL_OTP = ['ทุกช่องทาง', 'ระบุ']
    this.VIEW_REPORT_FROM = [`เจ้าของบัญชี`, `ผู้ช่วย`, `ทั้งหมด`]
    this.inProcess = false
  }

  componentDidMount() {
    const warningDateRangeText = this._reportwarningTextSetup()
    xUtil.setStatePromise(this, {
      warningDateRangeText,
    })
    let visibleOnlyProfileId = xAcl.isSelectedStoreOwner()
    if (visibleOnlyProfileId) {
      // console.log('getProfile => ', xUtil.getProfile())
      xUtil.setStatePromise(this, {
        visibleOnlyProfileId: false,
        profile: xUtil.getProfile(),
      })
    } else {
      visibleOnlyProfileId = xAcl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.ORDER_VISIBLE_ONLY_PROFILE_ID)
      xUtil.setStatePromise(this, {
        visibleOnlyProfileId,
        profile: xUtil.getProfile(),
      })
    }
  }

  // componentDidUpdate = async (prevProps: IBaseReportHelperProps, prevState: IBaseReportHelperState) => {
  //   if (this.props.visible !== prevProps.visible) {
  //     if (this.props.visible && !prevState.haveHlepers) {
  //       await this._fetchHelpers()
  //     }
  //   }
  // }

  _showSeletedChannel = (index: number) => {
    if (index === 0) {
      xUtil.setStatePromise(this, { showSeletedChannel: false, selectedChannelOtp: index })
    } else {
      xUtil.setStatePromise(this, { showSeletedChannel: true, selectedChannelOtp: index })
    }
  }

  _hideSeletedChannel = () => {
    xUtil.setStatePromise(this, { showSeletedChannel: false })
  }

  _onChangeChannel = (channel: IChannel) => {
    xUtil.setStatePromise(this, { channelData: channel, showSeletedChannel: false })
  }

  _onSeleted = (data: IMemberHelperList) => {
    const { hlepers } = this.state
    const newHelper = []
    hlepers.map((acc: IMemberHelperList) => {
      if (data.i === acc.i) {
        if (_.isNil(acc.seleted) || !acc.seleted) {
          acc.seleted = true
        } else {
          acc.seleted = false
        }
      } else {
        acc.seleted = false
      }
      newHelper.push(acc)
    })
    xUtil.setStatePromise(this, { hlepers: newHelper })
  }

  _fetchHelpers = async () => {
    const { getPermissionMemberList, selectedStore } = this.props
    const { haveHlepers, hlepers } = this.state
    if (haveHlepers && !_.isNil(hlepers)) {
      return
    }
    if (!_.isFunction(getPermissionMemberList)) {
      return
    }
    await xUtil.setStatePromise(this, { loading: true })
    let respone = null
    await new Promise((resolve) => {
      getPermissionMemberList({
        body: {
          store_id: selectedStore.get('id'),
        },
        successCallback: (res) => {
          respone = res
          resolve(null)
        },
        failedCallback: resolve,
      })
    })
    const newHlepers = respone.helpers
    await xUtil.setStatePromise(this, { haveHlepers: true, loading: false, hlepers: newHlepers, downloadingReport: false })
  }

  _reportwarningTextSetup = () => {
    const { subscription } = this.props
    const owner = xAcl.isSelectedStoreOwner()
    const r_shpg_day = subscription.get('r_shpg_day')
    const howDay = `แพ็กเกจของคุณดูย้อนหลังได้ ${r_shpg_day} วัน`
    let text = 'แพ็กเกจของคุณไม่สามารถใช้งานฟีเจอร์นี้ได้'
    if (owner) {
      if (!xAcl.packFree()) {
        text = howDay
      }
    } else {
      if (xUtil.isStoreOwner() && xAcl.packFree()) {
        text = `คุณไม่ได้รับสิทธิในการใช้งานฟีเจอร์นี้`
        return
      }
      const canDo = xAcl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.REPORT_HELPER, CONS.REPORT_SET_BIT_BINARY.report_helper)
      if (canDo) {
        text = howDay
      } else {
        text = `คุณไม่ได้รับสิทธิในการใช้งานฟีเจอร์นี้`
      }
    }

    return text
  }

  _onChangeDateRange = async (newDates: { begin: Dayjs; end: Dayjs }) => {
    xUtil.setStatePromise(this, { selectedDateRange: newDates })
  }

  _onChangeSegmentBaseReportPayment = async (newIndex: number) => {
    const { selectedOptSegment, selectedDateRange } = this.state
    const newSelectedDateRange = selectedDateRange
    if (selectedOptSegment === newIndex) {
      return
    }
    let startDate = dayjs(startNewDate)
    let endDate = dayjs(endNewDate)
    switch (newIndex) {
      case 1:
        const startSubstract = dayjs().subtract(6, 'days')
        startDate = dayjs(startSubstract.format('YYYY-MM-DD 00:00:00'))
        break
      case 2:
        startDate = dayjs().subtract(1, 'months').startOf('month')
        endDate = dayjs().subtract(1, 'months').endOf('month')
        break
      default:
        break
    }
    newSelectedDateRange.begin = startDate
    newSelectedDateRange.end = endDate
    await xUtil.setStatePromise(this, {
      selectedOptSegment: newIndex,
      selectedDateRange: newSelectedDateRange,
    })
  }

  _onChangeSegmentViewReportFrom = async (newIndex: number) => {
    if (newIndex === 1) {
      this._fetchHelpers()
    }
    await xUtil.setStatePromise(this, {
      viewReportIndex: newIndex,
    })
  }

  _seleteChooseOtp = (key: string, value: boolean) => {
    xUtil.setStatePromise(this, {
      [key]: !value,
    })
  }

  _downloadHistoryReport = async () => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true

    await xUtil.setStatePromise(this, { downloadingReport: true })
    const { selectedDateRange, hlepers, loading, viewReportIndex, channelData, selectedChannelOtp, selectedOptSegment } = this.state
    const { selectedStore } = this.props
    const store_id = selectedStore.get('id')
    if (loading) {
      this.inProcess = false
      await xUtil.setStatePromise(this, { downloadingReport: false })
      return
    }

    // if (xAcl.packFree()) {
    //   p.op.alert(p.op.t('Subscription.warning.insufficientTypeTitle'), p.op.t('Subscription.warning.insufficientTypeMsg'))
    //   this.inProcess = false
    //   await xUtil.setStatePromise(this, { downloadingReport: false })
    //   return
    // }

    // ถ้าเป็น IOS และเข้าเงื่อนไขก็ปล่อยผ่าน // https://app.clickup.com/t/86cvy1xnd
    const isNoobIos = xUtil.isIosNoobCheckByPass()
    if (!isNoobIos) {
      // STOPS download if a store owner with free package wants to load report
      if (xUtil.isPackFreeAndSelectedStoreOwner()) {
        p.op.alert(p.op.t('Subscription.warning.insufficientTypeTitle'), p.op.t('Subscription.warning.insufficientTypeMsg'))
        this.inProcess = false
        await xUtil.setStatePromise(this, { downloadingReport: false })
        return
      }
    }

    // STOPS download if not a store owner nor helper with permission
    if (!xAcl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.REPORT_HELPER, CONS.REPORT_SET_BIT_BINARY.report_helper)) {
      p.op.alert('ไม่สามารถดูรายงานได้', 'คุณไม่มีสิทธิ์ดูรายงานตัวนี้')
      this.inProcess = false
      await xUtil.setStatePromise(this, { downloadingReport: false })
      return
    }

    let helper = null
    if (viewReportIndex === 1) {
      if (_.isNil(hlepers) || hlepers.length < 1) {
        p.op.alert('กรุณาเลือกรายชื่อผู้ช่วย', 'ไม่พบรายชื่อผู้ช่วยของคุณ')
        this.inProcess = false
        await xUtil.setStatePromise(this, { downloadingReport: false })
        return
      }
      let haveSeleted = false
      hlepers.map((acc: IMemberHelperList) => {
        if (!_.isNil(acc.seleted) && acc.seleted) {
          haveSeleted = true
          helper = acc.i
        }
      })
      if (!haveSeleted) {
        p.op.alert('กรุณาเลือกรายชื่อผู้ช่วย', 'เลือกผู้ช่วยก่อน แล้วทำรายการใหม่อีกครั้ง')
        this.inProcess = false
        await xUtil.setStatePromise(this, { downloadingReport: false })
        return
      }
    }
    const dateType = selectedOptSegment === 0 ? 'r' : 'p'
    const channel_id = selectedChannelOtp === 0 ? null : channelData.value
    const helper_profile_id = viewReportIndex === 2 ? 0 : viewReportIndex === 1 ? helper : null

    const startDate = selectedDateRange.begin.format('YYYY-MM-DD%20HH:mm:00')
    const endDate = selectedDateRange.end.format('YYYY-MM-DD%20HH:mm:59')
    const url = xUtil.getHelperSalesReportExcelUrl({
      store_id,
      startDate,
      endDate,
      channel_id,
      dateType,
      helper_profile_id,
    })
    // console.log('URL : ', url)
    let fileName = `report product helper sales${startDate}-${endDate}`
    // console.log(`url => `, url)
    fileName = `${fileName}.xlsx`
    await this._handleOnDownloadFileByPlatform(url, fileName)
    p.op.aLogEvent(CONS.EVENT_NAME.REPORT_PRODUCT_HELPER_SALES, { s: store_id })
    // await p.op.storageSet(CONS.STORAGE_KEYS.REPORT_PRODUCT_DAILY_SALES, keepStore)
    await setTimeout(() => {
      this.inProcess = false
    }, 500)

    // await xUtil.delay(3000)
    await xUtil.setStatePromise(this, { downloadingReport: false })
  }
}
