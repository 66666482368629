import React from 'react'
import HStack from 'xui/components/HStack'
import Box from 'xui/components/Box'
import VStack from 'xui/components/VStack'
import { View } from 'react-native'

import _ from 'lodash'

import { COLORS, COLORS as xCOLORS } from 'x/config/styles'

import Segment from 'xui/components/Segment'
import p from 'x/config/platform-specific'
// import * as utilN from '../../utils/util-native'

import BaseCustomOrderView from 'x/modules/order/BaseCustomOrderView'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import XCard from 'xui/components/XCard'
import XCustomHeader from 'xui/components/XCustomHeader'
import XButton from 'xui/components/XButton'
import XIcon from 'xui/components/XIcon'
import XText from 'xui/components/XText'

// require('moment-timezone')

export interface segmentInterface {
  options: string[]
  selectedIndex: number
  onSegmentChange: any
}

const LINE = (
  <Box
    style={{
      borderBottomColor: xCOLORS.TEXT_INACTIVE,
      borderBottomWidth: 0.8,
      marginTop: 15,
      marginBottom: 10,
    }}
  />
)

export default class CustomOrderView extends BaseCustomOrderView {
  _renderHeader = () => (
    <XCustomHeader
      title='ตั้งค่าหน้า ดูออเดอร์'
      // headerLeftProps={onChangeData ? null : { backIcon: true, onPressItem: goBack() }}
      renderHeaderLeft={() => this._renderHeaderLeft()}
      renderHeaderRight={() => this._renderHeaderRight()}
    />
  )

  _renderHeaderLeft = () => {
    const { navigation } = this.props
    const { onChangeData } = this.state
    const { goBack } = navigation
    if (onChangeData) {
      return (
        <XButton onPress={() => this._saveBtn()} variant='ghost'>
          บันทึก
        </XButton>
      )
    }
    // return null
    return <XButton variant='ghost' leftIcon={<XIcon name='arrowleft' family='AntDesign' />} onPress={() => goBack()} />
  }

  _renderHeaderRight = () => {
    const { onChangeData } = this.state
    const { navigation } = this.props
    const { goBack } = navigation
    if (onChangeData) {
      return (
        <XButton
          onPress={() => {
            p.op.showConfirmation(
              '',
              'ยกเลิกการแก้ไข',
              () => goBack(),
              () => null,
              'ตกลง',
              'แก้ไขต่อ'
            )
          }}
          variant='ghost'>
          ยกเลิก
        </XButton>
      )
    }
    return null
  }

  _renderTitleCard = (
    title: string,
    options: string[],
    selectedIndex: number,
    onSegmentChange: (index: number) => void,
    icon?: JSX.Element,
    isSegmentLongFlex?: boolean
  ) => (
    <HStack w='full' alignItems='center' mt='1' py='2'>
      <Box flex={1} flexDirection='row' alignItems='center'>
        {_.isNil(icon) ? null : icon}
        {_.isNil(icon) ? null : <Box w='2' />}
        <XText variant='inactive'>{title}</XText>
      </Box>
      <Box flex={_.isNil(isSegmentLongFlex) ? 1 : 2}>
        <Segment options={options} selectedIndex={selectedIndex} onSegmentChange={onSegmentChange} />
      </Box>
    </HStack>
  )

  _renderHeaderTxt = (title: string) => (
    <XText variant='active' bold textAlign='center'>
      {title}
    </XText>
  )

  _renderDescription = () => (
    // <Card style={[CARD_CUSTOM_ORDER, { marginBottom: 0 }]}>
    <XText variant='inactive'>
      ในหน้า "ดูออเดอร์" คุณสามารถซ่อนหรือแสดงข้อมูลได้ โดยข้อมูลประเภท "บันทึก" และ "ใบปะหน้าพัสดุ"
      ของแต่ละออเดอร์จะไม่ถูกซ่อนหากมีข้อมูลระบุไว้
    </XText>
    // </Card>
  )

  // _renderTxtAndSwitch = (textLabel: string, value: boolean, onChangeFunction: Function) => (
  //   <CardItem style={{ flexDirection: 'row' }}>
  //     <Body style={{ justifyContent: 'flex-start' }}>
  //       <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL }}>{textLabel}</Text>
  //     </Body>
  //     <Right style={{ justifyContent: 'flex-end' }}>
  //       <Switch
  //         thumbColor={Platform.OS === 'ios' ? null : COLORS.APP_MAIN}
  //         // deprecated
  //         // onTintColor={ Platform.OS === 'ios' ? COLORS.APP_MAIN : COLORS.APP_SECONDARY }
  //         // deprecated
  //         // thumbTintColor={ Platform.OS === 'ios' ? null : COLORS.APP_MAIN }
  //         disabled={false}
  //         value={value}
  //         onValueChange={(val) => onChangeFunction(val)}
  //       />
  //     </Right>
  //   </CardItem>
  // )

  // _renderSegment = (options, selectedIndex, onSegmentChange) => {
  //   return <Segment options={options} selectedIndex={selectedIndex} onSegmentChange={onSegmentChange} />
  // }
  _renderLinkOrder = () => {
    const { customOrderViewData } = this.state
    const linkOrder = customOrderViewData.link_order
    const txtLinkOrder = p.op.t('linkOrder')
    const txtHeader = p.op.t('linkOrder')
    const showPriceAndTransactionCost = customOrderViewData.show_price_and_transaction_cost
    return (
      <Box>
        {LINE}
        {this._renderHeaderTxt(txtHeader)}
        {/* {this._renderTitleCard(txtLinkOrder, null, icon, segment)} */}
        {this._renderTitleCard(
          txtLinkOrder,
          this.OPTIONS_CHOOSE_HIDE_AND_SHOW,
          _.isNil(linkOrder.v) ? 0 : linkOrder.v,
          (index: number) => this._onSegmentChangeChooseShowLinkOrder(index),
          <XIcon variant='inactive' name='link' family='Ionicons' />
        )}
        {/* {this._renderTitleCard(txtShowPriceAndTatal, null, iconShowPriceAndTatal, segmentShowPriceAndTatal)} */}
        {this._renderTitleCard(
          txtLinkOrder,
          this.OPTIONS_CHOOSE_HIDE_AND_SHOW,
          _.isNil(showPriceAndTransactionCost.v) ? 0 : showPriceAndTransactionCost.v,
          (index: number) => this._onSegmentChangeChooseshowPriceAndTatalTitle(index),
          <XIcon variant='inactive' name='pricetags' family='Ionicons' />
        )}
      </Box>
    )
  }

  _renderReceiver = () => {
    const { customOrderViewData } = this.state
    // Receiver And Sender
    const { receiver } = customOrderViewData
    const { sender } = customOrderViewData
    const txtReceiver = p.op.t('Order.receiver')
    const txtHeader = p.op.t('Order.shipmentInfo')
    const txtSender = p.op.t('Order.sender')

    // Shipping
    const txtShowShippingType = p.op.t('Order.shippingType')
    const showShipping = customOrderViewData.shipping

    // ShipBeforePay
    const txtShippinBeforePay = p.op.t('Order.shipBeforePay')
    const showShipBeforePay = customOrderViewData.ship_before_pay

    return (
      <Box>
        {LINE}
        {this._renderHeaderTxt(txtHeader)}
        {/* {this._renderTitleCard(txtLinkOrder, null, icon, segment)} */}
        {this._renderTitleCard(
          txtReceiver,
          this.OPTIONS_CHOOSE_HIDE_AND_SHOW,
          _.isNil(receiver.v) ? 0 : receiver.v,
          (index: number) => this._onSegmentChangeChooseShowAndHideRecieverAdresses(index),
          <XIcon variant='inactive' name='home' family='FontAwesome' />
        )}
        {this._renderTitleCard(
          txtSender,
          this.OPTIONS_CHOOSE_HIDE_AND_SHOW,
          _.has(customOrderViewData, 'sender') ? sender.v : 1,
          (index: number) => this._onSegmentChangeChooseShowAndHideSenderAdresses(index),
          <XIcon variant='inactive' name='store' family='MaterialCommunityIcons' />
        )}
        {this._renderTitleCard(
          txtShowShippingType,
          this.OPTIONS_CHOOSE_HIDE_AND_SHOW,
          _.isNil(showShipping.v) ? 0 : showShipping.v,
          (index: number) => this._onSegmentChangeChooseshowShiping(index),
          <XIcon variant='inactive' name='local-shipping' family='MaterialIcons' />
        )}
        {this._renderTitleCard(
          txtShippinBeforePay,
          this.OPTIONS_CHOOSE_HIDE_AND_SHOW,
          _.isNil(showShipBeforePay.v) ? 0 : showShipBeforePay.v,
          (index: number) => this._onSegmentChangeChooseshowShipBeforePay(index),
          <XIcon
            variant='inactive'
            name='truck-delivery'
            family='MaterialCommunityIcons'
            color={COLORS.SUMMARY_ORDER_DONUT_COLOR_SCALE.myTasks_payOther}
          />
        )}
      </Box>
    )

    // return (
    //   <Card style={[CARD_CUSTOM_ORDER, { marginBottom: 0 }]}>
    //     <View>
    //       {/* {this._renderHeaderTxt(txtHeader)} */}
    //       {/* {this._renderTitleCard(txtReceiver, null, icon, segment)} */}
    //       {/* {this._renderTitleCard(txtSender, null, iconSender, segmentSender)} */}
    //       {/* {this._renderTitleCard(txtShowShippingType, null, iconShipping, segmentShipping)} */}
    //       {/* {this._renderTitleCard(txtShippinBeforePay, null, iconShippinBeforePay, segmentShippinBeforePay)} */}
    //       {/* {this._renderSegment(this.OPTIONS_CHOOSE_HIDE_AND_SHOW, receiver.v, this._onSegmentChangeChooseShowAndHideRecieverAdresses)} */}
    //     </View>
    //   </Card>
    // )
  }

  // _renderSender = () => {
  //   const { customOrderViewData } = this.state
  //   const { sender } = customOrderViewData
  //   const txtSender = p.op.t('Order.sender')
  //   const icon = {
  //     iconName: 'store',
  //     iconFamily: 'MaterialCommunityIcons',
  //   }
  //   const segment = {
  //     options: this.OPTIONS_CHOOSE_HIDE_AND_SHOW,
  //     selectedIndex: _.has(customOrderViewData, 'sender') ? sender.v : 1,
  //     onSegmentChange: this._onSegmentChangeChooseShowAndHideSenderAdresses,
  //   }

  //   return (
  //     <Card style={[CARD_CUSTOM_ORDER, { marginBottom: 0 }]}>
  //       <View>{this._renderTitleCard(txtSender, null, icon, segment)}</View>
  //     </Card>
  //   )
  // }

  // _showPriceAndTatal = () => {
  //   const { customOrderViewData } = this.state
  //   const txtShowPriceAndTatal = p.op.t('showPriceAndTransactionCost')
  //   const showPriceAndTransactionCost = customOrderViewData.show_price_and_transaction_cost
  //   // const icon = {
  //   //   iconName: 'home',
  //   //   iconFamily: 'FontAwesome',
  //   // }
  //   const segment = {
  //     options: this.OPTIONS_CHOOSE_HIDE_AND_SHOW,
  //     selectedIndex: _.isNil(showPriceAndTransactionCost.v) ? 0 : showPriceAndTransactionCost.v,
  //     onSegmentChange: this._onSegmentChangeChooseshowPriceAndTatalTitle,
  //   }
  //   return (
  //     <Card style={[CARD_CUSTOM_ORDER, { marginBottom: 0 }]}>
  //       {this._renderTitleCard(txtShowPriceAndTatal, null, null, segment)}
  //       {/* {this._renderTxtAndSwitch(p.op.t('default'), showPriceAndTransactionCost.d, this._onSegmentChangeChooseshowPriceAndTatalSwitch)} */}
  //     </Card>
  //   )
  // }

  _notesOrder = () => {
    const { customOrderViewData } = this.state
    const notsOrder = p.op.t('Order.notsOrder')
    const noteOrderToOther = p.op.t('Order.noteOrderToOther')
    const { notes } = customOrderViewData
    const txtHeader = p.op.t('Order.note')

    return (
      <Box>
        {LINE}
        {this._renderHeaderTxt(txtHeader)}
        {/* {this._renderTitleCard(txtLinkOrder, null, icon, segment)} */}
        {this._renderTitleCard(
          notsOrder,
          this.OPTIONS_CHOOSE_HIDE_AND_SHOW,
          _.isNil(notes.n_03.v) ? 0 : notes.n_03.v,
          (index: number) => this._onSegmentChangeChooseshowNote03(index),
          <XIcon variant='inactive' name='notebook' family='MaterialCommunityIcons' />
        )}
        {/* {this._renderTitleCard(txtShowPriceAndTatal, null, iconShowPriceAndTatal, segmentShowPriceAndTatal)} */}
        {this._renderTitleCard(
          noteOrderToOther,
          this.OPTIONS_CHOOSE_HIDE_AND_SHOW,
          _.isNil(notes.n_04.v) ? 0 : notes.n_04.v,
          (index: number) => this._onSegmentChangeChooseshowNote04(index),
          <XIcon variant='inactive' name='mail-outline' family='MaterialIcons' />
        )}
      </Box>
    )
  }

  // _shipping = () => {
  //   const { customOrderViewData } = this.state
  //   const txtShowShippingType = p.op.t('Order.shippingType')
  //   const showShipping = customOrderViewData.shipping
  //   const icon = {
  //     iconName: 'local-shipping',
  //     iconFamily: 'MaterialIcons',
  //   }
  //   const segment = {
  //     options: this.OPTIONS_CHOOSE_HIDE_AND_SHOW,
  //     selectedIndex: _.isNil(showShipping.v) ? 0 : showShipping.v,
  //     onSegmentChange: this._onSegmentChangeChooseshowShiping,
  //   }
  //   return <Card style={[CARD_CUSTOM_ORDER, { marginBottom: 0 }]}>{this._renderTitleCard(txtShowShippingType, null, icon, segment)}</Card>
  // }

  // _shipBeforePay = () => {
  //   const { customOrderViewData } = this.state
  //   const txtShowShippingType = p.op.t('Order.shipBeforePay')
  //   const showShipBeforePay = customOrderViewData.ship_before_pay
  //   const icon = {
  //     iconName: 'truck-delivery',
  //     iconFamily: 'MaterialCommunityIcons',
  //     color: xCOLORS.SUMMARY_ORDER_DONUT_COLOR_SCALE.myTasks_payOther,
  //   }
  //   const segment = {
  //     options: this.OPTIONS_CHOOSE_HIDE_AND_SHOW,
  //     selectedIndex: _.isNil(showShipBeforePay.v) ? 0 : showShipBeforePay.v,
  //     onSegmentChange: this._onSegmentChangeChooseshowShipBeforePay,
  //   }
  //   return <Card style={[CARD_CUSTOM_ORDER, { marginBottom: 0 }]}>{this._renderTitleCard(txtShowShippingType, null, icon, segment)}</Card>
  // }

  _shippingLabel = () => {
    const { customOrderViewData } = this.state
    const shippingLabel = p.op.t('ShippingLabel')
    const shippingLabelImg = p.op.t('ShippingLabelImg')
    // const shippingLabelNote = p.op.t('ShippingLabelNote')
    const notes = customOrderViewData.shipping_label

    return (
      <Box>
        {LINE}
        {this._renderHeaderTxt(shippingLabel)}
        {/* {this._renderTitleCard(txtLinkOrder, null, icon, segment)} */}
        {this._renderTitleCard(
          shippingLabelImg,
          this.OPTIONS_CHOOSE_HIDE_AND_SHOW,
          _.isNil(notes.n_1.v) ? 0 : notes.n_1.v,
          (index: number) => this._onSegmentChangeChooseshowNote01(index),
          <XIcon variant='inactive' name='photo' family='FontAwesome' />
        )}
        {/* {this._renderTitleCard(txtShowPriceAndTatal, null, iconShowPriceAndTatal, segmentShowPriceAndTatal)} */}
        {this._renderTitleCard(
          shippingLabelImg,
          this.OPTIONS_CHOOSE_HIDE_AND_SHOW,
          _.isNil(notes.n_2.v) ? 0 : notes.n_2.v,
          (index: number) => this._onSegmentChangeChooseshowNote02(index),
          <XIcon variant='inactive' name='edit' family='FontAwesome' />
        )}
      </Box>
    )
  }

  _renderOther = () => {
    const { customOrderViewData } = this.state
    const saleChannel = customOrderViewData.sales_channel
    const autoCompleted = customOrderViewData.auto_complete
    const dateDelivery = customOrderViewData.date_delivery
    const autoCancelOrder = customOrderViewData.auto_cancel_order
    const txtSaleChannel = p.op.t('Order.orderChannel')
    const txtAutoComplete = p.op.t('Order.autoCompleted')
    const txtHeader = p.op.t('others')

    return (
      <Box>
        {LINE}
        {this._renderHeaderTxt(txtHeader)}

        {this._renderTitleCard(
          txtSaleChannel,
          this.OPTIONS_CHOOSE_HIDE_AND_SHOW,
          _.has(customOrderViewData, 'sales_channel') ? saleChannel.v : 1,
          (index: number) => this._onSegmentChangeChooseShowAndHideSalesChannel(index),
          <XIcon variant='inactive' name='locate' family='Ionicons' />
        )}

        {this._renderTitleCard(
          'วันกำหนดส่ง',
          this.OPTIONS_CHOOSE_HIDE_AND_SHOW,
          _.has(customOrderViewData, 'date_delivery') ? dateDelivery.v : 1,
          (index: number) => this._onSegmentChangeChooseShowAndHideDateDelivery(index),
          <XIcon variant='inactive' name='back-in-time' family='Entypo' />
        )}

        {this._renderTitleCard(
          p.op.t('StoreSetting.MenuStoreCancelOrderAutomation'),
          this.OPTIONS_CHOOSE_HIDE_AND_SHOW,
          _.has(customOrderViewData, 'auto_cancel_order') ? autoCancelOrder.v : 1,
          (index: number) => this._onSegmentChangeChooseShowAndHideAutoCancelOrder(index),
          <XIcon variant='inactive' name='cancel' family='MaterialCommunityIcons' />
        )}

        {this._renderTitleCard(
          txtAutoComplete,
          this.OPTIONS_CHOOSE_HIDE_AND_SHOW,
          _.has(customOrderViewData, 'auto_complete') ? autoCompleted.v : 1,
          (index: number) => this._onSegmentChangeChooseShowAndHideAutoComplete(index),
          <XIcon variant='inactive' name='clipboard-check-outline' family='MaterialCommunityIcons' />
        )}
      </Box>
    )
  }

  render() {
    // ชื่อย่อต่างๆ ดูได้จาก spec :
    const { customOrderViewData } = this.state
    return (
      <XContainer>
        {this._renderHeader()}
        <XContent p='2'>
          <XCard w='full' p='2' mb='4'>
            <VStack w='full'>
              {this._renderDescription()}
              {_.isNil(customOrderViewData) ? null : (
                <Box>
                  {this._renderLinkOrder()}
                  {this._notesOrder()}
                  {this._renderReceiver()}
                  {this._shippingLabel()}
                  {this._renderOther()}

                  {/* {this._renderSender()} */}
                  {/* {this._showPriceAndTatal()} */}
                  {/* {this._shipping()} */}
                  {/* {this._shipBeforePay()} */}
                </Box>
              )}
            </VStack>
          </XCard>
          <View style={{ paddingTop: 4 }} />
        </XContent>
      </XContainer>
    )
  }
}
