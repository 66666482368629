// import PropTypes from 'prop-types'
import React from 'react'
import { Spinner } from 'native-base'

import {
  TouchableOpacity,
  Clipboard,
  Image,
  // TouchableHighlight,
  // TouchableWithoutFeedback,
} from 'react-native'

import { COLORS } from 'x/config/styles'
import * as util from 'x/utils/util'
// import QRCodeModal from '../../components/QRCodeModal'
// import ForwardIcon from '../../components/ForwardIcon'
import p from 'x/config/platform-specific'
import _ from 'lodash'
import { List } from 'immutable'

// import SortableListView from 'react-native-sortable-listview'
import { UserGroup } from 'x/index'
// import XGalleryModal from '../../components/XGalleryModal'
// import QRCode from '../../components/QRCode'
// import UGDisplayItem from '../../components/UGDisplayItem'
import { APP_CONFIG } from 'x/config/mode'
import XContent from 'xui/components/XContent'
import XCard from 'xui/components/XCard'
import BaseUserGroupListView from 'x/modules/store/BaseUserGroupListView'
import XContainer from 'xui/components/XContainer'
import XCustomHeader from 'xui/components/XCustomHeader'
import Box from 'xui/components/Box'
import XText from 'xui/components/XText'
import VStack from 'xui/components/VStack'
import XIcon from 'xui/components/XIcon'
import XQuickInfoButton from 'xui/components/XQuickInfoButton'
import HStack from 'xui/components/HStack'
import XButton from 'xui/components/XButton'
import FAQ_CONSTANTS from 'x/config/FAQ_CONSTANTS'
import HelpButton from 'xui/components/HelpButton'
import XFaqModal from 'xui/components/XFaqModal'

export default abstract class BaseUIUserGroupListView extends BaseUserGroupListView {
  abstract renderQRCodeModal: () => void

  _renderCustomHeader = () => {
    const isGoBackButtonHidden = util.getNavParam(this.props, 'isGoBackButtonHidden', false)
    return (
      <XCustomHeader
        title='กลุ่มสมาชิก'
        headerRight={this._renderHeaderRight()}
        headerLeftProps={
          isGoBackButtonHidden
            ? undefined
            : {
                backIcon: true,
                onPressItem: () => util.navGoBack(this.props),
              }
        }
        // headerRightProps={{
        //   addIcon: true,
        //   onPressItem: () => this._addUserGroup(),
        // }}
        // renderHeaderRight={() => this.renderRightBtn()}
      />
    )
  }

  _renderHeaderRight = () => {
    const _renderAgreement = (onPressOpenOverlay: () => void) => <HelpButton onPress={() => onPressOpenOverlay()} />
    // return <XButton variant='ghost' leftIcon={<XIcon family='AntDesign' name='plus' />} onPress={() => this._testABC()} />
    return (
      <HStack>
        <XFaqModal
          key='วิธีเชื่อมช่องทางขาย'
          headerTitle='วิธีเชื่อมช่องทางขาย'
          // @ts-ignore
          FAQ={FAQ_CONSTANTS.FAQ_USER_GROUP_LIST_VIEW}
          initiateOpenIndex={[0, 1]}
          renderButton={(onPressOpenOverlay: () => void) => _renderAgreement(onPressOpenOverlay)}
        />
        <XButton variant='ghost' leftIcon={<XIcon family='AntDesign' name='plus' />} onPress={() => this._addUserGroup()} />
      </HStack>
    )
    // return (
    //   <TouchableOpacity onPress={() => this.navToChannelsView()}>
    //     <XIcon family='AntDesign' name='plus' style={{ flex: 0 }} />
    //   </TouchableOpacity>
    // )
  }

  // renderRightBtn = () => (
  //   // if (!APP_CONFIG.user_group_list_view.show_add_btn) {
  //   //   return null
  //   // }
  //   // if (!canDoAtSelectedStore(CONS.PERM_STORE_HELPER.UG_ADD)) {
  //   //   p.op.alert('คุณไม่มีสิทธิ์ในการเพิ่มกลุ่มสมาชิก')
  //   //   return null
  //   // }
  //   // Start ตรวจสอบ Quota User Groups
  //   // const ugGroupsNumber = this.props.selectedStore.get('user_groups').size
  //   // const subscription = this.props.subscription.toJS()
  //   // if (ugGroupsNumber >= subscription.ug_quota) {
  //   //   p.op.alert('แพ็กเกจของคุณไม่สามารถเพิ่มกลุ่มสมาชิกได้อีก', p.op.t('Subscription.warning.insufficientTypeMsg'))
  //   //   return null
  //   // }
  //   // End ตรวจสอบ Quota User Groups
  //   <XButton variant='ghost' onPress={() => this._addUserGroup()}>
  //     <AddIcon />
  //   </XButton>
  // )

  _renderHeaderCard = (data: UserGroup) => {
    // console.log('data => ', data)
    const { dispatch } = this.props.navigation
    return (
      <Box flex={1} flexDirection='row'>
        {_.isNil(data.img_uri) ? null : (
          <Box alignItems='center' justifyContent='center' w='10'>
            <Image
              resizeMode='cover'
              style={{
                width: 40,
                height: 40,
                borderWidth: 1,
                borderColor: COLORS.TEXT_INACTIVE,
                borderRadius: 90,
              }}
              source={{ uri: data.img_uri }}
            />
          </Box>
        )}
        {_.isNil(data.img_uri) ? null : <Box w='2' />}
        <Box flex={1}>
          <XText variant='active' bold>
            {data.name}
          </XText>
          <XText variant='inactive' numberOfLines={1} w='48'>
            {data.description}
          </XText>
        </Box>
        <TouchableOpacity
          onPress={() => this._viewUserGroup(data.id)}
          style={{ width: 40, height: 40, justifyContent: 'flex-start', alignItems: 'flex-end' }}>
          <XIcon family='FontAwesome' name='gear' variant='primary' />
        </TouchableOpacity>
      </Box>
    )
  }

  _renderQuickInfoButton = (data: UserGroup) => {
    // const { dispatch } = this.props.navigation
    // const { permissions } = this.state
    const pgs_count = data.pgs ? data.pgs.length : 0
    return (
      <VStack space='2' mt='2'>
        <HStack w='full' space='2'>
          <XQuickInfoButton
            onPress={() => this._handleOnPressAskToJoin(data)}
            labelTop={_.isNil(data.pending_count) ? '0' : `${data.pending_count}`}
            label='ขอเข้าร่วม'
            iconName='person-add'
            iconFamily='Ionicons'
            themeColor={COLORS.APP_MAIN}
          />
          <XQuickInfoButton
            onPress={() => this._handleOnPressMembers(data)}
            labelTop={_.isNil(data.joined_count) ? '0' : `${data.joined_count}`}
            label='สมาชิก'
            iconName='people'
            iconFamily='Ionicons'
          />
        </HStack>
        <HStack w='full' space='2'>
          <XQuickInfoButton
            onPress={
              () => this._navToProductGroupSettingForUserGroup(data)
              // dispatch(
              //   NavActions.navToAssistantMember({
              //     callBack: (val: boolean) => this._callBackMemberView(val),
              //     tabIndex: 0,
              //     permissionList: permissions,
              //     permission_id: data.id,
              //   })
              // )
            }
            labelTop={`${pgs_count}`}
            label='รายการราคา'
            iconName='pricetag'
            iconFamily='Ionicons'
          />
          {APP_CONFIG.user_group_list_view.show_copy_link_group_btn ? (
            <XQuickInfoButton
              onPress={() => {
                if (data.invite_link) {
                  Clipboard.setString(data.invite_link)
                  p.op.showToast(`คัดลอก ${data.invite_link}`, 'success')
                } else {
                  p.op.showToast('ไม่พบลิงก์กลุ่มสมาชิก', 'warning')
                }
              }}
              iconTopFamily='MaterialCommunityIcons'
              label={`คัดลอก\nลิงก์ขอเข้ากลุ่ม`}
            />
          ) : null}
        </HStack>
      </VStack>
    )
  }

  _renderUGList = () => {
    // console.log('ugList => ', ugList.toJS())
    const { editingUserGroups } = this.state
    // console.log('editingUserGroups => ', editingUserGroups)
    if (_.isNil(editingUserGroups) || editingUserGroups.length < 1) {
      return null
    }

    return (
      <VStack w='full' p='2' space='2'>
        {editingUserGroups.map((data: UserGroup) => (
          <XCard p='2' w='full' key={data.id}>
            {this._renderHeaderCard(data)}
            {this._renderQuickInfoButton(data)}
          </XCard>
        ))}
      </VStack>
    )

    // return ugList.map((ug, idx) => (
    //   <UserGroupItem
    //     key={idx}
    //     index={idx}
    //     data={ug}
    //     onPressHandler={{
    //       openSetting: this._handleOnPressUserGroupSetting,
    //       openPendingUsers: this._handleOnPressAskToJoin,
    //       openJoinedUsers: this._handleOnPressMembers,
    //       openAssignedPG: this._handleOnPressProductGroups,
    //       // openQRCodeModal: this._handleOnPressOpenQRCode,
    //       copyInviteLink: this._handleOnPressCopyInviteLink,
    //     }}
    //   />
    // ))
  }

  render() {
    const { selectedUserGroups } = this.props
    const { editingUserGroups, isVisibleQRCode = false, selectedQRCodeUri = null, selectedInviteCode = null } = this.state

    if (!List.isList(selectedUserGroups) || !_.isArray(editingUserGroups)) {
      return <Spinner color={COLORS.APP_MAIN} />
    }

    return (
      <XContainer>
        {this._renderCustomHeader()}
        <XContent>{this._renderUGList()}</XContent>
      </XContainer>
    )
  }
}
