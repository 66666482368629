import React, { useState, useEffect, useRef } from 'react'
import { View, TouchableOpacity, Clipboard, StyleSheet, Linking } from 'react-native'

import _ from 'lodash'

import { IOrderCopyTemplateViewProps, IOrderCopyTemplate, ICustomOrderView } from 'x/index'
import { useNavigation } from 'x/contexts/useNavigation'
import CONS from 'x/config/constants'
import { STYLES, COLORS } from 'x/config/styles'
import p from 'x/config/platform-specific'

import XInput from 'xui/components/XInput'
import { translateOrderCopyTemplate } from 'x/utils/util'
import XText from 'xui/components/XText'
import XCustomHeader from '../../components/XCustomHeader'
import XContent from '../../components/XContent'
import XContainer from '../../components/XContainer'

function replaceStringBetween(originalText: string, newText: string, startIndex: number, endIndex: number): string {
  // if (startIndex === 0 && endIndex === originalText.length) {
  //   return newText
  // }
  return originalText.substring(0, startIndex) + newText + originalText.substring(endIndex)
}

// addCustomOrder({
//   body: {
//     store_id,
//     pref_ui_order_view: newCustomCreateOrderData,
//   },
//   successCallback: () => {
//     customOrderView(newCustomCreateOrderData)
//     if (_.isFunction(callBack)) {
//       callBack(newCustomCreateOrderData)
//     }
//     this.inProcess = false
//     navigation.setParams({
//       onChangeData: false,
//     })
//     p.op.showToast('บันทึกตั้งค่าเรียบร้อยแล้ว', 'success')
//   },
//   failedCallback: () => {
//     this.inProcess = false
//     p.op.showToast('เกิดข้อผิดพลาด กรุณาทำรายการใหม่ภายหลัง', 'warning')
//   },
// })
// }

// ออเดอร์ #748594
// รวมยอดชำระ 390.04 บาท
// --------------
// 1 x กระติกน้ำ 100 บาท
// 2 x แก้ว 100.52 บาท
// --------------
// รวมค่าสินค้า 301.04 บาท
// ค่าส่ง 50 บาท
// ค่าธรรมเนียมเก็บเงินปลายทาง 40 บาท
// --------------
// รูปแบบจัดส่ง: ยังไม่ระบุ
// ชื่อที่อยู่ผู้รับ: ยังไม่ระบุ
// ดูรายละเอียดและสถานะการจัดส่งได้ที่ https://xselly.com/a/?h=KDUfK

const SimpleButton = (props) => <TouchableOpacity {...props} style={StyleSheet.flatten([styles.buttonSimple, props.style])} />

// const getGoBackFunction = (navigation) => () => navigation.goBack(navigation.state.key)
const getGoBackFunction = (navigation) => () => navigation.goBack()

const Label = (props) => (
  <XText
    variant='inactive'
    bold
    {...props}
    // style={[
    //   {
    //     fontSize: STYLES.FONT_SIZE_NORMAL,
    //     color: COLORS.TEXT_INACTIVE,
    //     fontWeight: 'bold',
    //   },
    //   props.style,
    // ]}
  />
)

const PageLayout: React.FC<{
  onSave: () => void
  doGoBack: () => void
  isLoading?: boolean
  isDirty?: boolean
  fixedContainerWidth?: number
}> = ({ children, onSave, doGoBack, isLoading = false, isDirty = false, fixedContainerWidth }) => {
  const handleWarningDirty = async () => {
    const isUserConfirm = await p.op.isUserConfirm(
      'ละทิ้งการแก้ไข',
      'กรุณายืนยันว่าฉันต้องการละทิ้งการแก้ไขทั้งหมด',
      'ละทิ้ง',
      'กลับไปแก้ไข'
    )
    if (isUserConfirm) {
      doGoBack()
    }
  }
  return (
    <XContainer style={fixedContainerWidth ? { width: fixedContainerWidth } : {}}>
      <XCustomHeader
        // headerStyle={{ marginTop: 0 }}
        headerLeftProps={
          isDirty
            ? {
                label: 'บันทึก',
                onPressItem: onSave,
                submitting: isLoading,
              }
            : {
                backIcon: true,
                onPressItem: doGoBack,
                submitting: isLoading,
              }
        }
        headerRightProps={
          isDirty
            ? {
                label: 'ยกเลิก',
                onPressItem: handleWarningDirty,
                submitting: isLoading,
              }
            : null
        }
        title='ปรับแต่งการคัดลอก รายละเอียดออเดอร์'
      />
      <XContent style={fixedContainerWidth ? { width: fixedContainerWidth } : {}}>{children}</XContent>
    </XContainer>
  )
}

const OrderCopyTemplateView: React.FC<IOrderCopyTemplateViewProps> = function ({
  selectedStore,
  selectedCustomOrderView,
  addCustomOrder,
  loadPrefUIOrderView,
  webMaxScreenWidth,
  webNavigation,
}) {
  const nativeNavigation = useNavigation()
  const navBack = p.op.isWeb() ? getGoBackFunction(webNavigation) : getGoBackFunction(nativeNavigation)

  // const name = selectedStore.get('name')
  const selectedCustomOrderViewJson = selectedCustomOrderView.toJS()
  const selectedCoptOrderTemplate =
    _.isArray(selectedCustomOrderViewJson.order_copy_templates) && selectedCustomOrderViewJson.order_copy_templates.length > 0
      ? selectedCustomOrderViewJson.order_copy_templates[0]
      : { d: true, v: null }
  // const [orderTemplateString, setOrderTemplateString] = useState<string | null>(
  //   selectedCoptOrderTemplate.v ? selectedCoptOrderTemplate.v : CONS.PREF_DEFAULT_ORDER_COPY_TEMPLATE
  // )
  // const [isDefaultTemplate, setIsDefaultTemplate] = useState<boolean>(selectedCoptOrderTemplate.d)
  const inputRef = useRef()
  const [orderTemplateString, setOrderTemplateString] = useState<string | null>(null)
  const [isDefaultTemplate, setIsDefaultTemplate] = useState<boolean>(true)
  const [isDirty, setIsDirty] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [textSelection, setTextSelection] = useState<{ end: number; start: number } | null>(null)

  let previewTranslatedText = translateOrderCopyTemplate(
    typeof orderTemplateString === 'string' ? orderTemplateString : CONS.PREF_DEFAULT_ORDER_COPY_TEMPLATE,
    {
      orderId: 1234567,
      orderAmountTotal: 615,
      orderAmountProducts: 575,
      orderCreateDateTime: '2021-05-22 13:24:34',
      productTotalQty: 2,
      // orderCodAmount,
      orderShippingCost: 50,
      orderEtcCost: 10,
      orderDiscount: 20,
      // orderMemberDiscount,
      // orderCodFee,

      // isCodOrder,

      txtProductItems: '\nx1 เสื้อคอกลมสุดเทห์ สีขาว' + '\nx1 เสื้อคอกลมสุดเทห์ สีแดง',
      txtProductWithPriceItems: '\nx1 เสื้อคอกลมสุดเทห์ สีขาว ฿275' + '\nx1 เสื้อคอกลมสุดเทห์ สีแดง ฿300',
      txtShippingTypeLabel: 'ไปรษณีย์ด่วนพิเศษ (EMS)',
      txtOrderFullAddress: 'นายอำนาจ สำเร็จลุล่วง 987/123 ถ.พระราม 4 แขวงปทุมวัน เขตปทุมวัน กทม 10330 โทร. 0991234567',
      txtOrderLink: 'https://xselly.com/a/?h=example',
    }
  )

  if (previewTranslatedText === '') {
    previewTranslatedText = '(ระบุรายละเอียดออเดอร์เพื่อแสดงผลตัวอย่าง)'
  }
  // console.log('selectedCustomOrderView.toJS() => ', selectedCustomOrderView.toJS())

  const onChangeCopyTemplate = (newText) => {
    setIsDirty(true)
    setIsDefaultTemplate(false)
    setOrderTemplateString(newText)
  }
  const handleResetToDefault = () => {
    setIsDirty(true)
    setIsDefaultTemplate(true)
    setOrderTemplateString(CONS.PREF_DEFAULT_ORDER_COPY_TEMPLATE)
  }
  const handleClearTemplate = () => {
    setIsDirty(true)
    setIsDefaultTemplate(false)
    setOrderTemplateString('')
  }
  const handleOnSelectionChange = ({ nativeEvent }) => {
    if (nativeEvent.selection) {
      setTextSelection(nativeEvent.selection)
    } else {
      setTextSelection(null)
    }
  }
  const clearTextSelection = () => setTextSelection(null)
  const handleOnSave = async () => {
    let modifiedCopyOrderTemplate: IOrderCopyTemplate | null
    if (isDefaultTemplate) {
      modifiedCopyOrderTemplate = null
    } else {
      modifiedCopyOrderTemplate = { v: orderTemplateString }
    }
    const newCustomCreateOrderData: ICustomOrderView = {
      ...selectedCustomOrderViewJson,
      order_copy_templates: modifiedCopyOrderTemplate ? [modifiedCopyOrderTemplate] : null,
    }
    setIsLoading(true)
    const response: any = await new Promise((resolve) => {
      addCustomOrder({
        body: {
          store_id: selectedStore.get('id'),
          pref_ui_order_view: newCustomCreateOrderData,
        },
        successCallback: resolve,
        failedCallback: () => resolve(null),
      })
    })

    if (response && response.status && response.status === 'ok') {
      loadPrefUIOrderView(newCustomCreateOrderData)
      p.op.showToast('อัพเดทรูปแบบการคัดลอกรายละเอียดออเดอร์ใหม่สำเร็จแล้ว', 'success')
    } else {
      p.op.alert('เกิดข้อผิดพลาด', 'กรุณาลองใหม่ภายหลัง')
    }
    setIsLoading(false)
  }

  const doReplaceOrderTemplateString = (li) => {
    let isFocusedInput
    try {
      // @ts-ignore
      isFocusedInput = inputRef.current.inputRef.current.isFocused()
    } catch (err) {
      isFocusedInput = false
    }

    const isUserHasSelection = textSelection && _.isNumber(textSelection.start) && _.isNumber(textSelection.end)

    // App normal case
    if (isFocusedInput && isUserHasSelection) {
      onChangeCopyTemplate(replaceStringBetween(orderTemplateString, li.tag, textSelection.start, textSelection.end))
      return
    }

    // Web case:: กรณี web input behaviour เปลี่ยน
    if (p.op.isWeb() && isUserHasSelection) {
      onChangeCopyTemplate(replaceStringBetween(orderTemplateString, li.tag, textSelection.start, textSelection.end))
      clearTextSelection()
    }

    // Or else just copy text to clipboard
    Clipboard.setString(li.tag)
    p.op.showToast(`คัดลอก${li.label} ${li.tag} แล้ว`, 'success')
  }

  const renderItem = (li, index) => {
    const handlePressVariableButton = () => doReplaceOrderTemplateString(li)
    return (
      <SimpleButton key={index} style={styles.buttonVariableCode} onPress={handlePressVariableButton}>
        <XText>{li.label}</XText>
      </SimpleButton>
    )
  }

  const renderListItem = (li, index) => {
    if (li.isOptional) {
      return null
    }
    return renderItem(li, index)
  }

  const renderOptionalListItem = (li, index) => {
    if (!li.isOptional) {
      return null
    }
    return renderItem(li, index)
  }

  useEffect(() => {
    setIsDefaultTemplate(selectedCoptOrderTemplate.d ? selectedCoptOrderTemplate.d : true)
    setOrderTemplateString(selectedCoptOrderTemplate.v ? selectedCoptOrderTemplate.v : CONS.PREF_DEFAULT_ORDER_COPY_TEMPLATE)
    setIsDirty(false)
  }, [selectedCustomOrderView])

  const renderMain = () => (
    <View style={styles.card}>
      <View style={[styles.section, styles.bgLightGrey]}>
        {/* <Label>{'ข้อมูลออเดอร์ที่แสดงเมื่อมีค่ามากกว่าศูนย์'}</Label> */}
        <View style={styles.additionInfoTextContainer}>
          <XText variant='inactive'>
            คุณสามารถปรับแต่งการคัดลอก รายละเอียดออเดอร์ ตามต้องการได้ เช่น เพิ่มเลขที่บัญชีธนาคารเพื่อให้ลูกค้าโอน สลับต่ำแหน่งยอดชำระ หรือ
            อื่นๆ ตามสไตล์คุณ
          </XText>
        </View>
      </View>

      <TouchableOpacity style={styles.section} onPress={() => Linking.openURL('https://www.youtube.com/watch?v=H4NZK5tRN4Y')}>
        <XText color='blue.800' textDecorationLine='underline'>
          ตัวอย่างการปรับแต่งลิงก์ออเดอร์
        </XText>
      </TouchableOpacity>

      <View style={styles.section}>
        <Label>
          โค้ดข้อมูลออเดอร์
          <XText variant='inactive'>{' (กดปุ่มเพื่อนำโค้ดไปใส่ใน รายละเอียดออเดอร์)'}</XText>
        </Label>
        <View style={styles.variableButtonsContainer}>{CONS.PREF_ORDER_TEMPLATE_VARIABLES.map(renderListItem)}</View>
      </View>

      <View style={styles.section}>
        <Label>
          โค้ดข้อมูลออเดอร์ที่แสดงเมื่อมีค่ามากกว่าศูนย์
          <XText variant='inactive'>{' (กดปุ่มเพื่อนำโค้ดไปใส่ใน รายละเอียดออเดอร์)'}</XText>
        </Label>
        <View style={styles.variableButtonsContainer}>{CONS.PREF_ORDER_TEMPLATE_VARIABLES.map(renderOptionalListItem)}</View>
      </View>

      <View style={styles.section}>
        <View style={{ flexDirection: 'row' }}>
          <View style={{ flex: 1, flexBasis: 'auto' }}>
            <Label>
              รายละเอียดออเดอร์
              <XText variant='inactive'>{' (แตะที่ช่องสีส้มด้านล่างเพื่อเริ่มการแก้ไข)'}</XText>
            </Label>
          </View>
        </View>
      </View>

      <View style={styles.section}>
        <XInput
          ref={inputRef}
          onSelectionChange={handleOnSelectionChange}
          placeholderTextColor={COLORS.TEXT_INACTIVE}
          placeholder='ระบุรายละเอียดออเดอร์...'
          value={orderTemplateString}
          onChangeText={onChangeCopyTemplate}
          // style={StyleSheet.flatten(styles.inputText)}
          multiline
          minH='120px'
          _web={{
            minH: '240px',
          }}
        />
      </View>

      <View style={styles.section}>
        <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-start' }}>
          <SimpleButton style={styles.buttonClearTemplate} onPress={handleClearTemplate}>
            <XText variant='activeDark' bold>
              ลบทั้งหมด
            </XText>
          </SimpleButton>
          <SimpleButton style={styles.buttonResetToDefault} onPress={handleResetToDefault}>
            <XText variant='activeDark' bold>
              ใช้ค่าเริ่มต้น
            </XText>
          </SimpleButton>
        </View>
      </View>

      <View style={styles.section}>
        <Label>ตัวอย่าง</Label>
        <View style={styles.previewTextContianer}>
          <XText>{previewTranslatedText}</XText>
        </View>
      </View>
    </View>
  )

  return (
    <PageLayout
      // webMaxScreenWidth / fixedContainerWidth ใช้เพื่อระบุ width สำหรับให้ View react-native-web สามารถรู้ขอบเขตของ flex
      // fixedContainerWidth={p.op.isWeb() && _.isNumber(webMaxScreenWidth) ? webMaxScreenWidth : null}
      isLoading={isLoading}
      onSave={handleOnSave}
      doGoBack={navBack}
      isDirty={isDirty}>
      {renderMain()}
    </PageLayout>
  )
}

// // @ts-ignore disable react-navigation header
// OrderCopyTemplateView.navigationOptions = {
//   header: null,
// }

const styles = StyleSheet.create({
  buttonSimple: {
    borderRadius: 8,
    paddingHorizontal: 6,
    paddingVertical: 8,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
    borderColor: COLORS.APP_MAIN,
    backgroundColor: COLORS.WHITE,
    alignItems: 'center',
  },
  buttonVariableCode: {
    borderColor: COLORS.TEXT_INACTIVE,
    marginLeft: 4,
    marginRight: 4,
    marginBottom: 8,
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 4,
    paddingBottom: 4,
  },
  buttonClearTemplate: {
    marginRight: 4,
    paddingLeft: 6,
    paddingRight: 6,
    paddingTop: 2,
    paddingBottom: 2,
    borderColor: COLORS.BRAND_Danger,
    backgroundColor: COLORS.BRAND_Danger,
  },
  buttonResetToDefault: {
    marginLeft: 4,
    paddingLeft: 6,
    paddingRight: 6,
    paddingTop: 2,
    paddingBottom: 2,
    borderColor: COLORS.TEXT_INACTIVE,
    backgroundColor: COLORS.TEXT_INACTIVE,
  },
  section: {
    // margin: 8,
    marginTop: 8,
    marginRight: 8,
    marginBottom: 0,
    marginLeft: 8,
  },
  bgLightGrey: {
    backgroundColor: COLORS.BG_LIGHT_GREY,
  },
  card: {
    margin: 4,
    padding: 4,
    backgroundColor: COLORS.WHITE,
    borderWidth: 1,
    borderColor: COLORS.BG_LIGHT_GREY,
    borderRightColor: COLORS.TEXT_INACTIVE,
    borderBottomColor: COLORS.TEXT_INACTIVE,
  },
  variableButtonsContainer: {
    paddingTop: 8,
    paddingLeft: 8,
    paddingBottom: 8,
    paddingRight: 8,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  additionInfoTextContainer: {
    paddingTop: 8,
    paddingLeft: 8,
    paddingBottom: 8,
    paddingRight: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  previewTextContianer: {
    paddingTop: 8,
    paddingLeft: 8,
    paddingBottom: 8,
    paddingRight: 8,
  },
  inputText: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_ACTIVE,
    backgroundColor: COLORS.APP_SECONDARY,
    minHeight: p.op.isWeb() ? 240 : 120,
  },
})

export default OrderCopyTemplateView
