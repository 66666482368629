import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as StoreActions from 'x/modules/store/StoreState'
import XContainer from 'xui/components/XContainer'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContent from 'xui/components/XContent'
import HStack from 'xui/components/HStack'
import Box from 'xui/components/Box'
import NB from 'x/config/nativebase'
import { getSelectedStore, getEditingStore, getSubscription } from 'x/redux/selectors'
import { getIsSetBoolFromBinaryAtIndex, getToggleIntFromBinaryAtIndex } from 'x/utils/util'
import p from 'x/config/platform-specific'
import XCard from 'xui/components/XCard'
import XSettingRow from 'xui/components/XSettingRow'
import { ActionApiParams, ISelectedStoreMap, IXScreenProps } from 'x/types'
import CONS from 'x/config/constants'
import * as util from 'x/utils/util'

interface IStoreSettingStoreProductSNContainerProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
  submitEditing: ActionApiParams
  subscription: any
}

const StoreSettingStoreProductSNContainer: React.FC<IStoreSettingStoreProductSNContainerProps> = (props) => {
  const { subscription, submitEditing, selectedStore } = props
  // const [visibleShippingTypes, setVisibleShippingTypes] = useState<number[]>([])

  // eslint-disable-next-line react/no-unstable-nested-components
  const _renderHeader = () => (
    <XCustomHeader title='ตั้งค่ารหัสสินค้า SKU/UPC' headerLeftProps={{ backIcon: true, onPressItem: () => util.navGoBack(props) }} />
  )

  const _onSubmit = async (value: boolean) => {
    const store_id = selectedStore.get('id')
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const response = await new Promise((resolve) => {
      // @ts-ignore
      submitEditing({
        body: { store_id, s_use_product_barcode: value },
        successCallback: resolve,
        failedCallback: () => resolve(null),
      })
    })
    // console.log('response => ', response)
  }

  //            Binary Flag Table
  // Index =>   3    2     1      0
  // Number =>  8    4     2      1
  //            X    X     SKU    UPC
  const _getIntUseProductBarcode = () => selectedStore.get(CONS.STORE_SETTINGS.BIN_USE_PRODUCT_BARCODE) || 0

  const _toggleBarcodeFlag = (index) => {
    // _toggleBarcodeFlag = (value) => {
    const settingUseProductBarcode = _getIntUseProductBarcode()
    if (settingUseProductBarcode === 0) {
      if (subscription.get('type') < 3) {
        p.op.showConfirmation(
          p.op.t('Subscription.warning.insufficientTypeTitle'),
          p.op.t('Subscription.warning.insufficientTypeMsg'),
          () => null,
          () => null,
          'ตกลง'
        )
        return
      }
    } else if (index === 0 && settingUseProductBarcode === 1) {
      //
    } else if (index === 1 && settingUseProductBarcode === 2) {
      //
    } else if (settingUseProductBarcode === 3) {
      //
    } else if (subscription.get('type') < 3) {
      p.op.showConfirmation(
        p.op.t('Subscription.warning.insufficientTypeTitle'),
        p.op.t('Subscription.warning.insufficientTypeMsg'),
        () => null,
        () => null,
        'ตกลง'
      )
      return
    }
    // console.log('////////// index -> ', index)
    // @ts-ignore
    _onSubmit(getToggleIntFromBinaryAtIndex(settingUseProductBarcode, index))
    // const currentIsSet = this._getBoolFromIntProductBarcode(value)
    // this._handleSave({ s_use_product_barcode: currentIsSet ? settingUseProductBarcode - value : settingUseProductBarcode + value })
    // this._handleSave({ s_use_product_barcode: getToggleIntFromBinaryAtIndex(settingUseProductBarcode, index) })
  }

  const boxUPC = () => {
    const _getBoolUseUPC = () =>
      // return this._getBoolFromIntProductBarcode(0)
      getIsSetBoolFromBinaryAtIndex(_getIntUseProductBarcode(), 0)

    const s_use_product_barcode: boolean = _getBoolUseUPC()
    return (
      <HStack py='1.5'>
        <XSettingRow
          title={p.op.t('StoreSetting.MenuStoreUseProductUPC')}
          supTitle={p.op.t('StoreSetting.NoteStoreUseProductUPC')}
          value={s_use_product_barcode}
          onClick={() => _toggleBarcodeFlag(0)}
          stateKey='s_use_product_barcode'
        />
      </HStack>
    )
  }

  const boxTwo = () => {
    const _getBoolUseSKU = () =>
      // return this._getBoolFromIntProductBarcode(1)
      getIsSetBoolFromBinaryAtIndex(_getIntUseProductBarcode(), 1)

    const s_use_product_barcode: boolean = _getBoolUseSKU()
    return (
      <HStack py='1.5'>
        <XSettingRow
          title={p.op.t('StoreSetting.MenuStoreUseProductSKU')}
          supTitle={p.op.t('StoreSetting.NoteStoreUseProductSKU')}
          value={s_use_product_barcode}
          onClick={() => _toggleBarcodeFlag(1)}
          stateKey='s_use_product_barcode'
        />
      </HStack>
    )
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  const _renderBody = () => (
    <XCard mx='2' mt='2' mb='2'>
      {boxUPC()}
      <Box _dark={{ borderBottomColor: 'white' }} _light={{ borderBottomColor: 'black' }} borderBottomWidth='1' />
      {boxTwo()}
    </XCard>
  )

  return (
    <XContainer>
      {_renderHeader()}
      <XContent px='2' pb='2'>
        <Box w='full' mt='2' borderRadius='md' _light={{ bg: 'white' }} _dark={{ bg: NB.C.D.BG.CLEAR }}>
          {_renderBody()}
        </Box>
        {/* {isLoading ? VIEW_LOADING(windowWidth, windowHeight) : null} */}
      </XContent>
    </XContainer>
  )
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// // @ts-ignore
// StoreSettingStoreProductSNContainer.navigationOptions = {
//   header: null,
// }

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
    editingStore: getEditingStore(state),
    subscription: getSubscription(state),
  }),
  (dispatch) => ({
    submitEditing: bindActionCreators(StoreActions.submitEditingStore, dispatch),
    resetEditingStore: bindActionCreators(StoreActions.resetEditingStore, dispatch),
    // storeActions: bindActionCreators(StoreState, dispatch),
  })
)(StoreSettingStoreProductSNContainer)
