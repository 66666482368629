import React from 'react'
import _ from 'lodash'

import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'

import { IXShippingConfig } from 'x/config/app'
import api from 'x/utils/api'
import { IXScreenProps } from 'x/types'

export interface BaseStoreSettingXShippingEMSViewProps extends IXScreenProps {
  selectedStore: any
  getXShippingConfigFromRedux: IXShippingConfig[]
  setXShippingConfig: (newConfig: IXShippingConfig[]) => void
}
// isEMSxShipjungLoading
export interface BaseStoreSettingXShippingEMSViewState {
  // EMS X Shipjung
  useEMSxShipjung: boolean
  useAutoXShippingIsEMSxShipjungReadyToShip: boolean
  loadingEMSxShipjungWidth: number
  loadingEMSxShipjungHeight: number
  isEMSxShipjungLoading: boolean

  // EMS
  useEMS: boolean
  useAutoShippingIsEMSReadyToShip: boolean
  loadingEMSWidth: number
  loadingEMSHeight: number
  isEMSLoading: boolean

  // EMS COD
  useEMSCod: boolean
  useAutoShippingIsEMSCodReadyToShip: boolean
  loadingEMSCodWidth: number
  loadingEMSCodHeight: number
  isEMSCodLoading: boolean
  useAutoCreateCODPaymentWhenDelivered: boolean
}

// const STATE_KEY_EMS_X_SHIPJUNG_FOR_UPDATE = {
//   useJAndT: 'useJAndT',
//   useAutoXShippingIsJAndTReadyToShip: 'useAutoXShippingIsJAndTReadyToShip',
//   useJAndTCOD: 'useJAndTCOD',
//   useAutoXShippingIsJAndTCODReadyToShip: 'useAutoXShippingIsJAndTCODReadyToShip',
//   useAutoJAndTCreateCODPaymentWhenDelivered: 'useAutoJAndTCreateCODPaymentWhenDelivered',
// }

const STATE_KEY_EMS_X_SHIPJUNG_FOR_UPDATE = {
  useEMSxShipjung: 'useEMSxShipjung',
  useAutoXShippingIsEMSxShipjungReadyToShip: 'useAutoXShippingIsEMSxShipjungReadyToShip',
}

const STATE_KEY_EMS_FOR_UPDATE = {
  useEMS: 'useEMS',
  useAutoShippingIsEMSReadyToShip: 'useAutoShippingIsEMSReadyToShip',
}

const STATE_KEY_EMS_COD_FOR_UPDATE = {
  useEMSCod: 'useEMSCod',
  useAutoShippingIsEMSCodReadyToShip: 'useAutoShippingIsEMSCodReadyToShip',
  useAutoCreateCODPaymentWhenDelivered: 'useAutoCreateCODPaymentWhenDelivered',
}

export default abstract class BaseStoreSettingXShippingEMSView extends React.Component<
  BaseStoreSettingXShippingEMSViewProps,
  BaseStoreSettingXShippingEMSViewState
> {
  // DISPLAY_TABS: IUserListTab[]
  // _tabRef: React.RefObject<any>
  // helperProfileId: number

  protected constructor(props) {
    super(props)

    this.state = {
      // EMS X Shipjung
      useEMSxShipjung: false,
      useAutoXShippingIsEMSxShipjungReadyToShip: true,
      loadingEMSxShipjungWidth: 0,
      loadingEMSxShipjungHeight: 0,
      isEMSxShipjungLoading: false,

      // EMS
      useEMS: false,
      useAutoShippingIsEMSReadyToShip: true,
      isEMSLoading: false,
      loadingEMSHeight: 0,
      loadingEMSWidth: 0,

      // EMS COD
      useEMSCod: false,
      useAutoShippingIsEMSCodReadyToShip: true,
      isEMSCodLoading: false,
      loadingEMSCodHeight: 0,
      loadingEMSCodWidth: 0,
      useAutoCreateCODPaymentWhenDelivered: true,
    }
    // this._tabRef = React.createRef()
  }

  async componentDidMount() {
    const { getXShippingConfigFromRedux } = this.props
    this._setConfig(getXShippingConfigFromRedux)
  }

  _setConfig = async (config: IXShippingConfig[]) => {
    await this.setEMSConfig(config)
    await this.setEMSCodConfig(config)
    await this.setEMSxShipjungConfig(config)
  }

  setEMSConfig = async (config: IXShippingConfig[]) => {
    if (_.isNil(config)) {
      return
    }
    if (_.isArray(config) && config.length > 0) {
      // EMS
      let useEMS = false
      let useAutoShippingIsEMSReadyToShip = false
      config.forEach((configData: IXShippingConfig) => {
        if (configData.shipping_type_id === 3 && configData.system_id === 8) {
          // EMS
          useEMS = true
          useAutoShippingIsEMSReadyToShip = configData.set_auto_request_xshipping_on_create_order
            ? configData.set_auto_request_xshipping_on_create_order
            : false
        }
      })
      await util.setStatePromise(this, {
        // EMS
        useEMS,
        useAutoShippingIsEMSReadyToShip,
      })
    }
  }

  setEMSCodConfig = async (config: IXShippingConfig[]) => {
    if (_.isNil(config)) {
      return
    }
    // console.log('config ! => ', config)
    if (_.isArray(config) && config.length > 0) {
      // EMS COD
      let useEMSCod = false
      let useAutoShippingIsEMSCodReadyToShip = false
      let useAutoCreateCODPaymentWhenDelivered = false
      config.forEach((configData: IXShippingConfig) => {
        if (configData.shipping_type_id === 37 && configData.system_id === 8) {
          // EMS COD
          useEMSCod = true
          useAutoShippingIsEMSCodReadyToShip = configData.set_auto_request_xshipping_on_create_order
            ? configData.set_auto_request_xshipping_on_create_order
            : false
          useAutoCreateCODPaymentWhenDelivered = configData.create_cod_payment_when_delivered
            ? configData.create_cod_payment_when_delivered
            : false
        }
      })
      await util.setStatePromise(this, {
        // EMS COD
        useEMSCod,
        useAutoShippingIsEMSCodReadyToShip,
        useAutoCreateCODPaymentWhenDelivered,
      })
    }
  }

  setEMSxShipjungConfig = async (config: IXShippingConfig[]) => {
    if (_.isNil(config)) {
      return
    }
    if (_.isArray(config) && config.length > 0) {
      // EMS x Shipjung
      let useEMSxShipjung = false
      let useAutoXShippingIsEMSxShipjungReadyToShip = false
      config.forEach((configData: IXShippingConfig) => {
        if (configData.shipping_type_id === 3 && configData.system_id === 2) {
          // EMS x Shipjung
          useEMSxShipjung = true
          useAutoXShippingIsEMSxShipjungReadyToShip = configData.set_auto_request_xshipping_on_create_order
            ? configData.set_auto_request_xshipping_on_create_order
            : false
        }
      })
      await util.setStatePromise(this, {
        // EMS x Shipjung
        useEMSxShipjung,
        useAutoXShippingIsEMSxShipjungReadyToShip,
      })
    }
  }

  _setNewConfig = async (newValue: { isValue: boolean; stateKey: string }) => {
    // console.log('newValue => ', newValue)
    const { selectedStore, navigation, setXShippingConfig } = this.props
    const body: { [key: string]: any } = {
      store_id: selectedStore.get('id'),
    }

    let apiMethod = api.patchV2
    const STATE_KEY = newValue.stateKey
    const IS_VALUE = newValue.isValue
    // console.log('STATE_KEY => ', STATE_KEY)

    /// //////////////////////// START EMS X SHIPJUNG  ///////////////////////////
    if (STATE_KEY === STATE_KEY_EMS_X_SHIPJUNG_FOR_UPDATE.useEMSxShipjung) {
      // หากต้องการเปิดใช้งาน EMS X Shipjung ให้ไปปิด EMS ต่อตรงก่อน
      if (this.state.useEMS && IS_VALUE) {
        p.op.showConfirmationOkOnly(
          '',
          'หากต้องการเปิดการใช้งานการเชื่อมต่อ\n"EMS ผ่าน Shipjung"\n\nกรุณาปิดการใช้งานการเชื่อมต่อ\n"ขอเลขพัสดุจาก EMS โดยตรง" ก่อน'
        )
        await util.delay(100)
        await util.setStatePromise(this, {
          [STATE_KEY]: !IS_VALUE,
        })
        return
      }
      if (IS_VALUE) {
        p.op.showConfirmationOkOnly('', p.op.t('xshipping.warning.how_to_use_xshipping'))
        await util.setStatePromise(this, {
          [STATE_KEY]: !IS_VALUE,
        })
      } else {
        p.op.showConfirmationOkOnly('', p.op.t('xshipping.warning.how_to_un_use_xshipping'))
        await util.setStatePromise(this, {
          [STATE_KEY]: !IS_VALUE,
        })
      }
      return
    }
    if (STATE_KEY === STATE_KEY_EMS_X_SHIPJUNG_FOR_UPDATE.useAutoXShippingIsEMSxShipjungReadyToShip) {
      await util.setStatePromise(this, {
        isEMSxShipjungLoading: true,
      })
      body.shipping_type_id = 3
      body.system_id = 2
      body.set_auto_request_xshipping_on_create_order = IS_VALUE
    }
    /// //////////////////////// END EMS X SHIPJUNG  ///////////////////////////

    /// //////////////////////// START EMS ///////////////////////////
    if (STATE_KEY === STATE_KEY_EMS_FOR_UPDATE.useEMS) {
      // หากต้องการเปิดใช้งาน EMS ต่อตรง ให้ไปปิด EMS X Shipjung ต่อตรงก่อน
      if (this.state.useEMSxShipjung && IS_VALUE) {
        p.op.showConfirmationOkOnly(
          '',
          'หากต้องการเปิดการใช้งานการเชื่อมต่อกับ\n"EMS โดยตรง"\n\nกรุณาปิดการใช้งานการเชื่อมต่อ\n"ขอเลขพัสดุจาก EMS ผ่าน Shipjung" ก่อน'
        )
        await util.delay(100)
        await util.setStatePromise(this, {
          [STATE_KEY]: !IS_VALUE,
        })
        return
      }
      await util.setStatePromise(this, {
        isEMSLoading: true,
      })
      body.shipping_type_id = 3
      body.system_id = 8
      // body.set_auto_request_xshipping_on_create_order = IS_VALUE
    }
    if (STATE_KEY === STATE_KEY_EMS_FOR_UPDATE.useAutoShippingIsEMSReadyToShip) {
      await util.setStatePromise(this, {
        isEMSLoading: true,
      })
      body.shipping_type_id = 3
      body.system_id = 8
      body.set_auto_request_xshipping_on_create_order = IS_VALUE
    }
    /// //////////////////////// STOP EMS ///////////////////////////

    /// //////////////////////// START EMS COD ///////////////////////////
    if (STATE_KEY === STATE_KEY_EMS_COD_FOR_UPDATE.useEMSCod) {
      // หากต้องการเปิดใช้งาน EMS ต่อตรง ให้ไปปิด EMS X Shipjung ต่อตรงก่อน
      if (this.state.useEMSxShipjung && IS_VALUE) {
        p.op.showConfirmationOkOnly(
          '',
          'หากต้องการเปิดการใช้งานการเชื่อมต่อกับ\n"EMS โดยตรง"\n\nกรุณาปิดการใช้งานการเชื่อมต่อ\n"ขอเลขพัสดุจาก EMS ผ่าน Shipjung" ก่อน'
        )
        await util.delay(100)
        await util.setStatePromise(this, {
          [STATE_KEY]: !IS_VALUE,
        })
        return
      }
      await util.setStatePromise(this, {
        isEMSCodLoading: true,
      })
      body.shipping_type_id = 37
      body.system_id = 8
      // body.set_auto_request_xshipping_on_create_order = IS_VALUE
    }
    if (STATE_KEY === STATE_KEY_EMS_COD_FOR_UPDATE.useAutoShippingIsEMSCodReadyToShip) {
      await util.setStatePromise(this, {
        isEMSCodLoading: true,
      })
      body.shipping_type_id = 37
      body.system_id = 8
      body.set_auto_request_xshipping_on_create_order = IS_VALUE
    }
    if (STATE_KEY === STATE_KEY_EMS_COD_FOR_UPDATE.useAutoCreateCODPaymentWhenDelivered) {
      await util.setStatePromise(this, {
        isEMSCodLoading: true,
      })
      body.shipping_type_id = 37
      body.system_id = 8
      body.create_cod_payment_when_delivered = IS_VALUE
    }
    /// //////////////////////// STOP EMS COD ///////////////////////////

    const isMainBtn =
      STATE_KEY === STATE_KEY_EMS_X_SHIPJUNG_FOR_UPDATE.useEMSxShipjung ||
      STATE_KEY === STATE_KEY_EMS_FOR_UPDATE.useEMS ||
      STATE_KEY === STATE_KEY_EMS_COD_FOR_UPDATE.useEMSCod
    // console.log('isMainBtn => ', isMainBtn)
    // isMainBtn ถ้าเป้นปุ่มหลัก แล้วกดปุ่มปิดใช้งาน ให้ยิง api ไป delV2 เพื่อลบ
    if (isMainBtn && !IS_VALUE) {
      apiMethod = api.delV2
    }
    // isMainBtn ถ้าเป้นปุ่มหลัก แล้วกดปุ่มเปิดใช้งาน ให้ยิง api ไป putV2 เพื่อสร้าง
    if (isMainBtn && IS_VALUE) {
      body.set_auto_request_xshipping_on_create_order = true
      apiMethod = api.putV2
    }
    const response: { xshipping_config: IXShippingConfig[] } = await apiMethod(api.XSHIPPING_CONFIG, body)
    if (response.xshipping_config) {
      setXShippingConfig(response.xshipping_config)
      await this._setConfig(response.xshipping_config)
      // const { state } = navigation
      // const { onPressGoBack } = state.params
      // // console.log('onPressGoBack => ', onPressGoBack)
      const onPressGoBack = util.getNavParam(this.props, 'onPressGoBack')
      if (_.isFunction(onPressGoBack)) {
        onPressGoBack()
      }
    }
    // UN SET LOADING //
    if (
      STATE_KEY === STATE_KEY_EMS_X_SHIPJUNG_FOR_UPDATE.useEMSxShipjung ||
      STATE_KEY === STATE_KEY_EMS_X_SHIPJUNG_FOR_UPDATE.useAutoXShippingIsEMSxShipjungReadyToShip
    ) {
      await util.setStatePromise(this, {
        isEMSxShipjungLoading: false,
      })
    }

    if (STATE_KEY === STATE_KEY_EMS_FOR_UPDATE.useEMS || STATE_KEY === STATE_KEY_EMS_FOR_UPDATE.useAutoShippingIsEMSReadyToShip) {
      await util.setStatePromise(this, {
        isEMSLoading: false,
      })
    }

    if (
      STATE_KEY === STATE_KEY_EMS_COD_FOR_UPDATE.useEMSCod ||
      STATE_KEY === STATE_KEY_EMS_COD_FOR_UPDATE.useAutoShippingIsEMSCodReadyToShip ||
      STATE_KEY === STATE_KEY_EMS_COD_FOR_UPDATE.useAutoCreateCODPaymentWhenDelivered
    ) {
      await util.setStatePromise(this, {
        isEMSCodLoading: false,
      })
    }

    // console.log('response => ', response)
  }

  isSwitchOnChange = async (newValue: { isValue: boolean; stateKey: string }) => {
    await this._setNewConfig(newValue)
  }
}
