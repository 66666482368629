import React, { useState, useEffect } from 'react'
import _ from 'lodash'

import XSwitch from 'xui/components/XSwitch'
import FAQ from 'x/config/FAQ'
import Box from 'xui/components/Box'
import XHelpModal from './XHelpModal'
import HelpButton from './HelpButton'
import XText from './XText'
import HStack from './HStack'
import VStack from './VStack'

interface IXForceCreateShipping {
  disabled?: boolean
  value: boolean
  onClick: (newValue: boolean) => void
  textColorActive?: boolean
}

const XForceCreateShipping: React.FC<IXForceCreateShipping> = ({ disabled, value, onClick, textColorActive }) => {
  const [isDisabled, setDisabled] = useState<boolean>(_.isNil(disabled) ? false : disabled)
  const [isValue, setValue] = useState<boolean>(value)

  useEffect(() => {
    // console.log('value  ?? ', value)
    setValue(value)
  }, [value])

  const isValueChange = (newValue: boolean) => {
    setValue(newValue)
    onClick(newValue)
  }

  const renderHelpBtn = (onPressOpenOverlay: () => void) => (
    <Box style={{ width: 30 }}>
      <HelpButton style={{ minWidth: 10, width: 10 }} onPress={() => onPressOpenOverlay()} />
    </Box>
  )

  // const TEXT_COLOR = _.isNil(textColorActive) || !textColorActive ? COLORS.TEXT_INACTIVE : COLORS.TEXT_ACTIVE
  return (
    <VStack pl='2' pr='1' py='2' w='full'>
      <HStack>
        <HStack flex={1} alignItems='center'>
          <XText variant={_.isNil(textColorActive) || !textColorActive ? 'inactive' : 'active'}>
            บันทึกจัดส่งแม้จำนวนสินค้าในคลังไม่พอ
          </XText>
          <XHelpModal
            key='XHelpModalFromXForceCreateShipping'
            headerTitle='บันทึกจัดส่งแม้จำนวนสินค้าในคลังไม่พอ'
            FAQ={FAQ.FORCE_CREATE_SHIPPING}
            renderButton={(onPressOpenOverlay: () => void) => renderHelpBtn(onPressOpenOverlay)}
          />
        </HStack>
        <Box style={{ alignItems: 'flex-end', justifyContent: 'center', paddingRight: 6 }}>
          <XSwitch disabled={isDisabled} value={isValue} onValueChange={(newValue: boolean) => isValueChange(newValue)} />
        </Box>
      </HStack>
    </VStack>
  )
}

export default XForceCreateShipping
