import React from 'react'
import { StyleSheet } from 'react-native'
import { Formik, FormikProps, FormikHelpers as FormikActions } from 'formik'

import * as Yup from 'yup'
import p from 'x/config/platform-specific'
import { logRender } from 'x/utils/util'

import _ from 'lodash'
import HStack from 'xui/components/HStack'
import XText from 'xui/components/XText'
import XButton from 'xui/components/XButton'
import VStack from 'xui/components/VStack'
import { COLORS, STYLES } from 'x/config/styles'
import XContainer from 'xui/components/XContainer'
import XCard from 'xui/components/XCard'
import XImage from 'xui/components/XImage'
import Box from 'xui/components/Box'
import BackIcon from 'xui/components/BackIcon'
import * as NavActions from 'x/utils/navigation'
import XScrollView from 'xui/components/XScrollView'
import { IXScreenProps } from 'x/types'
import XStatusBar from '../../components/XStatusBar'
// import { getStatusBarHeight } from '../../utils/iphone-x-helper'

const X_LOGO = require('../../img/logo_1024.png')

// import { Input } from '../../components/XFormik'
// import api from 'x/utils/api'
// import { delay } from 'x/utils/util'
// import { withNextInputAutoFocusForm } from 'react-native-formik'
// const ForminkWithAutoFocus = withNextInputAutoFocusForm(Formik)

type IBaseOnboardingViewProps = IXScreenProps

abstract class BaseOnboardingView<IAdditionProps = {}, IState = {}> extends React.Component<
  IBaseOnboardingViewProps & IAdditionProps & IXScreenProps,
  IState
> {
  static displayName = 'BaseOnboardingView'

  _inProcess?: boolean

  initialValues: { [key: string]: any }

  validationSchema: Yup.ObjectSchema<any>

  styles: StyleSheet.NamedStyles<any>

  txtHeaderTitle: string

  txtBtnFooter: string

  inputRefs: { [key: string]: any }

  inputKeyMapOrder: { [key: string]: number }

  inputSortedByRefKeys: string[]

  abstract renderContent(fProps: FormikProps<any>): React.ReactNode

  abstract handleSubmitForm(values: any, formActions: FormikActions<any>): Promise<void>

  // constructor(props: IBaseOnboardingViewProps) {
  constructor(props) {
    super(props)

    this.inputRefs = {}
    this.inputSortedByRefKeys = []
    this.inputKeyMapOrder = {}

    this.styles = StyleSheet.create({
      header: {
        paddingLeft: 0,
        paddingRight: 0,
        // borderBottomWidth: 0.5,
        // borderBottomColor: COLORS.TEXT_INACTIVE,
        backgroundColor: COLORS.FORM_PRIMARY_BG,
        borderBottomWidth: 0,
        borderBottomColor: 'transparent',
        // backgroundColor: 'transparent',
        // backgroundColor: '#F7F7F7',
        shadowColor: 'transparent',
        shadowOpacity: 0,
        shadowRadius: 0,
        elevation: 0,
        // marginTop: Platform.OS === 'android' ? getStatusBarHeight() : 0,
      },
      headerGrid: {
        flex: 1,
        minHeight: 44,
      },
      container: {
        backgroundColor: COLORS.FORM_PRIMARY_BG,
      },
      content: {
        // backgroundColor: COLORS.FORM_PRIMARY_BG,
        marginTop: 20,
        padding: 10,
        // overflow: 'auto',
        flexWrap: 'nowrap',
      },
      contentCard: {
        marginTop: 38, // 40 คือ 0.5 ของ logo - border width 2
      },
      contentCardItem: {
        paddingTop: 30,
      },
      headerTitle: {
        fontSize: STYLES.FONT_SIZE_LARGER,
        fontWeight: 'bold',
        color: COLORS.TEXT_ACTIVE,
        marginVertical: 8,
      },
      txtLog: {
        fontSize: STYLES.FONT_SIZE_SMALLER,
        color: COLORS.TEXT_INACTIVE,
      },
      label: {
        fontSize: STYLES.FONT_SIZE_NORMAL,
        fontWeight: 'bold',
        color: COLORS.TEXT_INACTIVE,
      },
      input: {
        // backgroundColor: 'white',
        backgroundColor: COLORS.BG_LIGHT_GREY,
        fontSize: STYLES.FONT_SIZE_NORMAL,
      },
      GRID_COL_MIDDLE_CENTER: {
        alignItems: 'center',
        justifyContent: 'center',
      },
      GRID_COL_MIDDLE_LEFT: {
        alignItems: 'flex-start',
        justifyContent: 'center',
      },
      GRID_COL_MIDDLE_RIGHT: {
        alignItems: 'flex-end',
        justifyContent: 'center',
      },
      btnFooter: {
        borderRadius: 8,
        minHeight: 50,
      },
      btnFooterDisabled: {
        backgroundColor: COLORS.BG_LIGHT_GREY,
        borderRadius: 8,
        minHeight: 50,
      },
      txtBtnFooter: {
        fontSize: STYLES.FONT_SIZE_LARGER,
        fontWeight: 'bold',
        color: 'white',
      },
      txtBtnFooterDisabled: {
        fontSize: STYLES.FONT_SIZE_LARGER,
        color: COLORS.TEXT_INACTIVE,
      },
      footer: {
        // backgroundColor: COLORS.FORM_PRIMARY_BG,
        backgroundColor: 'transparent',
        marginBottom: 12,
      },
      footerTab: {
        backgroundColor: 'transparent',
        padding: 8,
        marginBottom: 12,
        // backgroundColor: COLORS.FORM_PRIMARY_BG,
        // marginBottom: 8,
      },
    })

    this.initialValues = {
      email: '',
      password: '',
    }
    this.validationSchema = Yup.object().shape({
      email: Yup.string().required().email(),
      password: Yup.string().required().min(6, 'Too short'),
    })

    this.txtHeaderTitle = 'TXT_HEADER_TITLE'
    this.txtBtnFooter = 'TXT_BTN_FOOTER'

    this.renderContent = this.renderContent.bind(this)
    this.handleSubmitForm = this.handleSubmitForm.bind(this)
  }

  // util with input
  _autoFocusFirstInput = () => {
    if (this.inputSortedByRefKeys.length > 0) {
      const firstInputKey = this.inputSortedByRefKeys[0]
      const firstInput = this.inputRefs[firstInputKey]
      if (firstInput && _.isFunction(firstInput.focus)) {
        this.inputRefs[firstInputKey].focus()
      }
    }
  }

  _doRegisterRef = (name: string, ref: any, order?: number): void => {
    if (name && ref) {
      this.inputRefs[name] = ref
    }
    //  else {
    //   return
    // }
    if (name && order) {
      this.inputKeyMapOrder[name] = order
      const allKeys = Object.keys(this.inputKeyMapOrder)
      this.inputSortedByRefKeys = allKeys.sort((keyA: string, keyB: string) => {
        const a = this.inputKeyMapOrder[keyA] ? this.inputKeyMapOrder[keyA] : 9999
        const b = this.inputKeyMapOrder[keyB] ? this.inputKeyMapOrder[keyB] : 9999
        // Ascending sort
        if (a > b) {
          return 1
        }
        if (a < b) {
          return -1
        }
        return 0
      })

      // console.log('_doRegisterRef  this.inputRefs => ', this.inputRefs)
      // console.log('_doRegisterRef  this.inputSortedByRefKeys => ', this.inputSortedByRefKeys)
      // this.inputSortedByRefKeys.forEach((key) => {
      //   this.inputRefs[key].forceUpdate()
      // })
    }
  }

  _doSubmitEditing = (evt: any, name: string): void => {
    // log('_doSubmitEditing this.inputRefs => ', this.inputRefs)
    // log('_doSubmitEditing this.inputKeyMapOrder => ', this.inputKeyMapOrder)
    // log('_doSubmitEditing this.inputSortedByRefKeys => ', this.inputSortedByRefKeys)
    // log('_doSubmitEditing name => ', name)
    if (name) {
      const lastIndex = this.inputSortedByRefKeys.length - 1
      const currentIndex = this.inputSortedByRefKeys.findIndex((sortedKey) => sortedKey === name)
      // log('_doSubmitEditing currentIndex => ', currentIndex)
      if (currentIndex > -1 && currentIndex < lastIndex) {
        const nextKey = this.inputSortedByRefKeys[currentIndex + 1]
        // log('_doSubmitEditing nextKey => ', nextKey)
        if (this.inputRefs[nextKey] && _.isFunction(this.inputRefs[nextKey].focus)) {
          this.inputRefs[nextKey].focus()
        }
      } else if (currentIndex === lastIndex) {
        // Should submit form
      }
    }
  }

  // _getReturnKeyType = (name: string): ReturnKeyTypeOptions => {
  //   const lastIndex = this.inputSortedByRefKeys.length - 1
  //   const currentIndex = this.inputSortedByRefKeys.findIndex(sortedKey => sortedKey === name)
  //   if (currentIndex < lastIndex) {
  //     return 'next'
  //   } else if (currentIndex === lastIndex) {
  //     return 'done'
  //   }
  //   return 'default'
  // }

  _renderForm = () => (
    <Formik
      onSubmit={this.handleSubmitForm}
      validationSchema={this.validationSchema}
      validateOnBlur
      validateOnChange={false}
      initialValues={this.initialValues}
      render={this._renderFormChildren}
    />
  )

  _renderFormChildren = (fProps: FormikProps<any>) => (
    <XContainer style={this.styles.container}>
      {/* {p.op.isWeb() ? null : this.renderHeader(fProps)} */}

      {p.op.isWeb() ? (
        <XImage
          resizeMode='cover'
          style={{
            flex: 1,
            position: 'absolute',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
          }}
          source={{ uri: 'https://xselly.com/x/bg.jpg' }}
        />
      ) : null}

      {/* <XContent style={this.styles.content}> */}
      <XScrollView
        scrollEnabled={!p.op.isWeb()}
        contentContainerStyle={
          p.op.isWeb() ? { alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' } : undefined
        }>
        <VStack
          // flex={1}
          w='full'
          p='4'
          pt={p.op.isWeb() ? '4' : '12'}
          alignItems='center'
          justifyContent={p.op.isWeb() ? 'center' : 'flex-start'}>
          <XCard
            minW='320px'
            maxW='520px'
            overflow='visible'
            // style={this.styles.contentCard}
          >
            {/* {p.op.isWeb() ? this.renderHeader(fProps) : null} */}
            {this.renderHeader(fProps)}
            <Box position='absolute' ml='auto' mr='auto' top='-40px' left='0' right='0' alignItems='center' justifyContent='center'>
              {this.renderXSellyLogo()}
            </Box>

            <XCard.Body
              // style={this.styles.contentCardItem}
              pt='40px'>
              {this.renderContent(fProps)}
            </XCard.Body>

            {p.op.isWeb() ? this.renderFooter(fProps) : null}
          </XCard>
        </VStack>
        {/* เพื่ออนุญาติให้ iOS Scroll ได้ */}
        {p.op.isIOS() ? <Box h='80px' w='full' /> : null}
      </XScrollView>
      {/* </XContent> */}

      {/* {this.renderFooter(fProps)} */}
      {p.op.isWeb() ? null : this.renderFooter(fProps)}
    </XContainer>
  )

  _onRequestToGoBack = async () => {
    if (this._inProcess) {
      return
    }
    this._inProcess = true
    const isConfirm = await p.op.isUserConfirm(
      'คุณอยู่ระหว่างการลงทะเบียน',
      'ต้องการยกเลิกการลงทะเบียนและเริ่มใหม่หรือไม่',
      'ยกเลิกและเริ่มใหม่',
      'ลงทะเบียนต่อ'
    )
    if (!isConfirm) {
      this._inProcess = false
      return
    }
    await p.op.signOut()

    const { navigation } = this.props
    // console.log('_onRequestToGoBack navigation => ', navigation)
    // this.props.navigation.navigate('AppLauncherViewContainer')
    navigation.dispatch(NavActions.resetToFirstPage)

    this._inProcess = false
  }

  _renderBackButton = (fProps: FormikProps<any>) => (
    <XButton variant='ghost' w='122px' leftIcon={<BackIcon />} onPress={this._onRequestToGoBack} disabled={fProps.isSubmitting}>
      กลับหน้าแรก
    </XButton>
  )

  //   return (
  //   <NavHeaderButton backIcon onPressItem={this._onRequestToGoBack} submitting={fProps.isSubmitting} />
  // )

  _renderHeaderTitle = () => (
    <XText
      // style={this.styles.headerTitle}
      bold
      fontSize='md'>
      {this.txtHeaderTitle}
    </XText>
  )

  renderHeader = (fProps: FormikProps<any>) => (
    <VStack
      w='full'
      minH='9'
      position='absolute'
      zIndex='999'
      top='0'
      left='0'
      right='0'
      bg='transparent'
      // style={this.styles.header}
    >
      <XStatusBar translucent backgroundColor='transparent' animated={false} />
      <VStack
        w='full'
        // style={this.styles.headerGrid}
      >
        <HStack w='full'>
          <HStack
            // pl='12'
            minW='10'
            alignItems='center'
            justifyContent='center'
            // style={[this.styles.GRID_COL_MIDDLE_CENTER, { flex: 0, minWidth: 54 }]}
          >
            {this._renderBackButton(fProps)}
          </HStack>
          <HStack
            flex={1}
            alignItems='center'
            justifyContent='center'
            // size={100} style={this.styles.GRID_COL_MIDDLE_CENTER}
          >
            {/* {this._renderHeaderTitle()} */}
          </HStack>
          <HStack
            minW='10'
            alignItems='center'
            justifyContent='center'
            // style={[this.styles.GRID_COL_MIDDLE_CENTER, { flex: 0, minWidth: 54 }]}
          />
        </HStack>
      </VStack>
    </VStack>
  )

  renderFooter = (fProps: FormikProps<any>) => {
    const { isSubmitting, isValid, handleSubmit } = fProps
    // const { handleSubmit } = fProps
    // const isDisabled = false
    // const isDisabled = isSubmitting || !isValid
    const isDisabled = isSubmitting
    // const btnStyle = isDisabled ? this.styles.btnFooterDisabled : this.styles.btnFooter
    // const txtBtnStyle = isDisabled ? this.styles.txtBtnFooterDisabled : this.styles.txtBtnFooter
    return (
      <HStack
        w='full'
        p='1'
        _light={{
          bg: 'white',
        }}
        // style={this.styles.footer}
      >
        {/* <FooterTab style={this.styles.footerTab}> */}
        <XButton
          // flex={1}
          w='full'
          minH='9'
          // @ts-ignore
          onPress={handleSubmit}
          disabled={isDisabled}
          isDisabled={isDisabled}
          // style={btnStyle}
          // primary
          // full
        >
          {/* <XText style={txtBtnStyle}> */}
          {this.txtBtnFooter}
          {/* </XText> */}
        </XButton>
        {/* </FooterTab> */}
      </HStack>
    )
  }

  renderXSellyLogo = () => (
    <Box
      w='80px'
      h='80px'
      borderTopLeftRadius='md'
      borderTopRightRadius='md'
      _light={{ bg: 'white' }}
      alignItems='center'
      justifyContent='center'>
      <XImage resizeMode='contain' source={X_LOGO} w='76px' h='76px' />
    </Box>
  )

  // renderXSellyLogo = () => (
  //   <View
  //     style={{
  //       flexBasis: 'auto',
  //       // overflow: 'auto',
  //       flexWrap: 'nowrap',
  //       position: 'absolute',
  //       top: -44,
  //       minWidth: 82,
  //       minHeight: 82,
  //       zIndex: 100,
  //       overflow: 'visible',
  //       left: Dimensions.get('window').width / 2 - 50,
  //     }}>
  //     <View
  //       style={{
  //         position: 'absolute',
  //         // top: 67,
  //         top: -1,
  //         left: 0,
  //         // // left: Dimensions.get('window').width / 2 - 40,
  //         // left: Dimensions.get('window').width / 2 - 50,
  //         width: 81,
  //         height: 44,
  //         // zIndex: 101,
  //         borderWidth: 1,
  //         borderColor: '#ccc',
  //         borderBottomWidth: 0,
  //         backgroundColor: 'transparent',
  //         overflow: 'visible',
  //       }}
  //     />
  //     <View
  //       style={{
  //         position: 'absolute',
  //         top: 0,
  //         // paddingTop: 2,
  //         // // left: Dimensions.get('window').width / 2 - 40,
  //         // left: Dimensions.get('window').width / 2 - 50, // 40 คือครึ่งนึงของรูป + 10 padding
  //         width: 80,
  //         height: 80,
  //         // zIndex: 100,
  //         borderWidth: 2,
  //         borderColor: 'white',
  //         overflow: 'visible',
  //       }}>
  //       <Image resizeMode='contain' source={X_LOGO} style={{ flex: 1, width: '100%', overflow: 'visible' }} />
  //     </View>
  //   </View>
  // )

  render() {
    logRender(this)
    return this._renderForm()
  }
}

export default BaseOnboardingView
