import React, { useEffect, useState } from 'react'
import { COLORS } from 'x/config/styles'
import _ from 'lodash'
import p from 'x/config/platform-specific'
import HStack from './HStack'
import XButton from './XButton'
import XIcon from './XIcon'
import XText from './XText'

interface XFooterImportExcelProps {
  fileSize: number
  // fileName: string
  // callBackOnPress  หลังจากกดปุ่มอัพโหลดจะมีการเช็ดเงื่อนไข  แล้วจะบอกกลับไปต้นทางว่า ผ่านหรือไม่ผ่าน
  callBackOnPress: (result: boolean) => void
  isDisabled: boolean

  // setPrefTimeslot: (params: ActionApiParams) => void
  // selectedStore: ISelectedStoreMap
  // callBackUpdateChannel: (channel: IMKPChannelDetail) => void
  // updateNewChannelList: (params: ActionApiParams) => void
}

const XFooterImportExcel: React.FC<XFooterImportExcelProps> = function ({
  fileSize,
  // fileName,
  callBackOnPress,
  isDisabled = false,
  // setPrefTimeslot,
  // selectedStore,
  // callBackUpdateChannel,
  // updateNewChannelList,
}) {
  // const [newChannel, setChannels] = useState(channel)
  // if (_.isNil(newChannel)) {
  //   return null
  // }

  const [isDisabledBtn, setIsDisabledBtn] = useState<boolean>(false)

  useEffect(() => {
    // console.log('useEffect => ')
    const disabledBtn = _.isNil(fileSize) || isDisabled
    setIsDisabledBtn(disabledBtn)
  }, [fileSize, isDisabled])

  const onPressBtn = () => {
    setIsDisabledBtn(false)
    // const fileExtension = fileName.slice(-4)
    // // รองรับแค่ไฟล์ .xlsx เท่านั้น
    // if (fileExtension !== 'xlsx') {
    //   p.op.showConfirmationOkOnly('', p.op.t('e_msg_by_error_code.SupportOnlyXlsxFile.m'))
    //   return
    // }
    if (fileSize > 5242880) {
      p.op.showConfirmationOkOnly('', p.op.t('e_msg_by_error_code.FileSizeTooLarge.m'))
      callBackOnPress(false)
      return
    }
    callBackOnPress(true)
  }

  // console.log('isDisabledBtn => ', isDisabledBtn)
  // console.log('fileSize => ', fileSize)
  // console.log('fileName => ', fileName)
  return (
    <HStack>
      <XButton
        disabled={isDisabledBtn}
        flex={1}
        py='3'
        mx='1'
        onPress={() => onPressBtn()}
        borderColor={isDisabledBtn ? COLORS.TEXT_INACTIVE : COLORS.APP_MAIN}
        bgColor={isDisabledBtn ? COLORS.BG_LIGHT_GREY : COLORS.APP_MAIN}>
        <XIcon name='upload' family='AntDesign' color={isDisabledBtn ? COLORS.TEXT_INACTIVE : COLORS.WHITE} />
        <XText color={isDisabledBtn ? COLORS.TEXT_INACTIVE : COLORS.WHITE} bold pl='2'>
          อัพโหลด
        </XText>
      </XButton>
    </HStack>
  )
}

export default XFooterImportExcel
