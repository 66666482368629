import React from 'react'
import _ from 'lodash'
// import EntypoIcon from 'react-native-vector-icons/Entypo'
import xCONS from 'x/config/constants'

// import BackIcon from '../../components/BackIcon'
// import XTabNavIcon from '../../components/XTabNavIcon'

// import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import XText from 'xui/components/XText'
// import SRowItem from '../../components/renderRowLeftAndRight'
import { COLORS, STYLES } from 'x/config/styles'
import BaseNextPackageDetail from 'x/modules/subscription/BaseNextPackageDetail'
import HStack from 'xui/components/HStack'
import * as util from 'x/utils/util'
import ProfilePackage from 'xui/components/ProfilePackage'
import VStack from 'xui/components/VStack'
import PackageTag from 'xui/components/PackageTag'
import dayjs from 'dayjs'
import XStatusBar from '../../components/XStatusBar'
import XUISubcriptionDetail from './XUISubcriptionDetail'

const BTN_BUY_PACKAGE = require('../../img/btn/btn_buy_package.png')

const { expireWarningInDay, expireDangerInDay, orderDangerInPercentage, orderWarningInPercentage } = xCONS.SUBSCRIPTION_SETTINGS

const SERVER_DATE_FORMAT = 'YYYY-MM-DD'

const { FONT_SIZE_SMALLER } = STYLES
const { APP_MAIN, APP_SECONDARY, TEXT_ACTIVE, TEXT_INACTIVE, TEXT_ACTIVE_DARK, BRAND_Warning, BRAND_Danger, BG_LIGHT_GREY } = COLORS

const LOCAL_DATE_FORMAT = 'D MMM YYYY'

export default abstract class BaseUINextPackageDetail extends BaseNextPackageDetail {
  _renderProfile = () => {
    const { subscription, profile } = this.props
    if (_.isNil(profile) || _.isNil(subscription)) {
      return null
    }
    // console.log('profile => ', profile.toJS())
    if (!profile || !profile.get('user_id')) {
      return null
    }

    const typeId: number = subscription.has('type') ? subscription.get('type') : 0

    return <ProfilePackage profile={profile.toJS()} typeId={typeId} />
  }

  _renderMyPackage = () => {
    const { subscription } = this.props
    const LastBillingCycle = util.getSubscriptionNextLastBillingCycle(subscription)
    if (_.isNil(LastBillingCycle)) {
      return null
    }
    // console.log('LastBillingCycle => ', LastBillingCycle)
    const subscriptionType = util.getSubscriptionPackageTypeByPackageSku(LastBillingCycle.sku)
    const orderCount = LastBillingCycle.data_json.order_count_quota
    const startDate = dayjs(LastBillingCycle.start_date, SERVER_DATE_FORMAT).format(LOCAL_DATE_FORMAT)
    const endDate = dayjs(LastBillingCycle.end_date, SERVER_DATE_FORMAT).format(LOCAL_DATE_FORMAT)
    return (
      <VStack m='2'>
        <XText variant='active' fontSize='lg' bold>
          แพ็กเกจถัดไปของคุณ
        </XText>
        <PackageTag typeId={subscriptionType} width={80} height={32} />
        <HStack mt='2'>
          <HStack flex={1}>
            <XText>จำนวนออเอร์ที่ใช้ได้</XText>
          </HStack>
          <HStack flex={1} flexDirection='flex-end'>
            <XText textAlign='right' color={COLORS.APP_MAIN}>{`${orderCount} ออเดอร์/รอบบิล`}</XText>
          </HStack>
        </HStack>
        <HStack>
          <HStack flex={1}>
            <XText>เริ่มรอบบิล</XText>
          </HStack>
          <HStack flex={1} flexDirection='flex-end'>
            <XText textAlign='right' variant='active'>
              {startDate}
            </XText>
          </HStack>
        </HStack>
        <HStack>
          <HStack flex={1}>
            <XText>สิ้นสุดรอบบิล</XText>
          </HStack>
          <HStack flex={1} flexDirection='flex-end'>
            <XText textAlign='right' variant='active'>
              {endDate}
            </XText>
          </HStack>
        </HStack>
      </VStack>
    )
  }

  _renderPackageDetails = () => {
    const { subscription } = this.props
    const { subscriptionPackageList } = this.state
    // console.log('packageDetails => ', packageDetails)
    if (_.isNil(subscription) || _.isNil(subscriptionPackageList)) {
      return null
    }
    const LastBillingCycle = util.getSubscriptionNextLastBillingCycle(subscription)
    // console.log('LastBillingCycle => ', LastBillingCycle)
    if (_.isNil(LastBillingCycle)) {
      return null
    }
    const subscriptionType = util.getSubscriptionPackageTypeByPackageSku(LastBillingCycle.sku)
    // const subscriptionType = util.getSubscriptionPackageTypeByPackageName(selectedPackage.item_subtype)
    const type = subscriptionType
    const { items } = subscriptionPackageList
    const sku = xCONS.PACKAGE_SKU_CYCLE_12[type - 2]
    let dataJson = null
    items.forEach((item) => {
      if (item.sku === sku) {
        dataJson = item.data_json
      }
    })
    if (_.isNil(dataJson)) {
      return null
    }
    // console.log('type // => ', type)
    return (
      <VStack mt='2'>
        <HStack bgColor={COLORS.BG_GREY_PACKAGE} h='10' alignItems='center' justifyContent='center'>
          <XText bold fontSize='lg'>
            แพ็กเกจถัดไป
          </XText>
        </HStack>
        <VStack p='2'>
          <XUISubcriptionDetail subscription={dataJson} type={type} />
        </VStack>
      </VStack>
    )
  }

  _renderAddOnListView = () => {
    const { nextPackageBillingCycle } = this.state
    if (!_.isNil(nextPackageBillingCycle)) {
      return nextPackageBillingCycle.map((addOn) => {
        if (addOn.item_type !== 'package_main') {
          const { title, dataTimeText, countText } = util.getAddOnDetail(addOn)
          return this._renderAddOnView(title, dataTimeText, countText)
        }
        return null
      })
    }

    return null
  }

  _renderAddOnView = (title: string, dataTimeText: string, countText: string) => (
    <VStack key={title}>
      <HStack p='2'>
        <VStack flex={2}>
          <XText>{title}</XText>
          <XText variant='inactive'>{dataTimeText}</XText>
        </VStack>
        <HStack flex={1} flexDirection='flex-end'>
          <XText textAlign='right' color={COLORS.GREEN}>
            {countText}
          </XText>
        </HStack>
      </HStack>
      <HStack borderBottomWidth='1' borderBottomColor={COLORS.TEXT_INACTIVE} />
    </VStack>
  )

  _renderAddOnPackage = () => (
    <VStack mt='2'>
      <XText px='2' variant='active' bold>
        รายละเอียดแพ็กเสริม สำหรับแพ็กเกจถัดไป
      </XText>
      {this._renderAddOnListView()}
    </VStack>
  )

  _renderPackage = () => {
    const {} = this.props
    return (
      <VStack bgColor={COLORS.WHITE}>
        {this._renderMyPackage()}
        <HStack borderBottomWidth='1' borderBottomColor={COLORS.TEXT_INACTIVE} />
        {this._renderAddOnPackage()}
        {this._renderPackageDetails()}
      </VStack>
    )
  }

  render() {
    const { profile, subscription } = this.props
    const headerTitle = 'รายละเอียดแพ็กเกจถัดไป'

    return (
      <XContainer>
        <XStatusBar backgroundColor='#fff' />
        <XCustomHeader title={headerTitle} headerLeftProps={{ backIcon: true, onPressItem: () => util.navGoBack(this.props) }} />
        <XContent
          // p='1'
          style={{ backgroundColor: BG_LIGHT_GREY }}>
          {this._renderPackage()}
        </XContent>
      </XContainer>
    )
  }
}
