/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react'
// import { VStack } from 'native-base'
import p from 'x/config/platform-specific'
import _ from 'lodash'
import api from 'x/utils/api'
import * as util from 'x/utils/util'
import CONS from 'x/config/constants'
import { IERPChannel, IErpDocOrder, IErpDocTypeOption, IOrderMap, ISelectedStoreMap } from 'x/index'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import XCard from 'xui/components/XCard'
import HStack from 'xui/components/HStack'
import XText from 'xui/components/XText'
import { TouchableOpacity, Clipboard, Linking } from 'react-native'
import { COLORS } from 'x/config/styles'
import XButton from 'xui/components/XButton'
import { List } from 'immutable'
import ErpDocTypeSelector from 'xui/components/erp/ErpDocTypeSelector'
import XSpinner from 'xui/components/XSpinner'
import XIcon from 'xui/components/XIcon'
import VStack from 'xui/components/VStack'
import * as acl from 'x/utils/acl'
import XCustomHeader from '../../components/XCustomHeader'

interface BaseErpOrderViewProps {
  selectedOrder: IOrderMap
  selectedStore: ISelectedStoreMap
  // fetchMarketplaceOrder: (params: ActionApiParams) => void
}

interface BaseErpOrderViewState {
  selectedErpDocTypeIndex: number
  isTaxInvoiceChecked: boolean
  selectedErpDocOption: IErpDocTypeOption
  orderErpDocuments: IErpDocOrder[]
  visibleErpDocTypeIds: number[]
  selectedErpChannel: IERPChannel

  isLoading: boolean
  loadingWidth: number
  loadingHeight: number
}

export default class BaseUIErpOrderView extends Component<BaseErpOrderViewProps, BaseErpOrderViewState> {
  constructor(props) {
    super(props)
    this.state = {
      selectedErpDocTypeIndex: 0,
      isTaxInvoiceChecked: false,
      selectedErpDocOption: null,
      orderErpDocuments: null,
      visibleErpDocTypeIds: [12, 14],

      selectedErpChannel: null,

      isLoading: false,
      loadingWidth: 0,
      loadingHeight: 0,
    }
  }

  async componentDidMount() {
    await this._getErpChannel()
    await this._fetchOrderErp()
  }

  _fetchOrderErp = async () => {
    const { selectedOrder, selectedStore } = this.props
    const { selectedErpChannel } = this.state
    // console.log('selectedErpChannel => ', selectedErpChannel)
    if (_.isNil(selectedOrder) || _.isNil(selectedStore)) {
      return
    }
    await this._setIsLoading()
    // @ts-ignore
    const erp_ch_id = util.getNavParam(this.props, 'erp_ch_id')
    const body: { [key: string]: any } = {
      store_id: selectedStore.get(`id`),
      order_id: selectedOrder.get('id'),
      erp_ch_id,
    }
    const apiOptions = {
      showSpinner: true,
    }
    const res: { order_documents: IErpDocOrder[] } = await api.postV2('erp/document/order/lists', body, apiOptions)
    // console.log('_fetchOrderErp => ', res)
    if (res.order_documents) {
      // let visibleErpDocTypeIds = [12, 14]
      const visibleErpDocTypeIds = []
      // let visibleErpDocTypeIds = [14]
      let type12 = true
      let type14 = true
      let haveType14NoCancel = false
      res.order_documents.forEach((doc) => {
        if (doc.erp_doc_type === 12 && doc.erp_doc_code && _.isNil(doc.cancelled_at)) {
          type12 = false
        }
        if (doc.erp_doc_type === 14 && doc.erp_doc_code && _.isNil(doc.cancelled_at)) {
          type14 = false
          haveType14NoCancel = true
        }
        // const filteredNumbers = visibleErpDocTypeIds.filter((num) => num === doc.erp_doc_type && !_.isNil(doc.cancelled_at))
        // visibleErpDocTypeIds = filteredNumbers
      })

      const { receipt_issue_date, invoice_issue_date } = selectedErpChannel

      // ถ้ามีใบเสร็จรับเงินที่มีเลขอยู่และยังไม่ถูกยกเลิกจะไม่สามารถสร้างใบแจ้งหนี้ได้
      if (!type14 && haveType14NoCancel) {
        type12 = false
      }

      if (type12 && !_.isNil(invoice_issue_date) && invoice_issue_date !== '') {
        visibleErpDocTypeIds.push(12)
      }

      if (type14 && !_.isNil(receipt_issue_date) && receipt_issue_date !== '') {
        visibleErpDocTypeIds.push(14)
      }
      let selectedErpDocTypeIndex = 0
      let selectedErpDocOption = null
      let isTaxInvoiceChecked = false
      if (type14) {
        selectedErpDocTypeIndex = 3
        selectedErpDocOption = { id: 14, key: 'receipt', label: 'ใบเสร็จรับเงิน', is_tax_invoice_available: true }
        isTaxInvoiceChecked = true
      }
      await util.setStatePromise(this, {
        orderErpDocuments: res.order_documents,
        visibleErpDocTypeIds,
        selectedErpDocTypeIndex,
        selectedErpDocOption,
        isTaxInvoiceChecked,
      })
    }
    await this._UnsetIsLoading()
    // this._onErpOptionChange(0, { id: 14, key: 'receipt', label: 'ใบเสร็จรับเงิน', is_tax_invoice_available: true })
  }

  _setIsLoading = async () => {
    await util.setStatePromise(this, {
      isLoading: true,
    })
  }

  _UnsetIsLoading = async () => {
    await util.setStatePromise(this, {
      isLoading: false,
    })
  }

  _getErpChannel = async () => {
    const { selectedStore } = this.props
    // @ts-ignore
    const erp_ch_id = util.getNavParam(this.props, 'erp_ch_id')
    const erpChannels = selectedStore.has('erp_channels') ? selectedStore.get('erp_channels').toJS() : null
    // console.log('erpChannels => ', erpChannels)
    let erpChannel = null
    if (!_.isNil(erpChannels)) {
      erpChannels.forEach((channel) => {
        if (channel.id === erp_ch_id) {
          erpChannel = channel
        }
      })
    }
    await util.setStatePromise(this, {
      selectedErpChannel: erpChannel,
    })
  }

  _submitDocType = async () => {
    const { selectedStore, selectedOrder } = this.props
    const { isTaxInvoiceChecked, selectedErpDocOption, selectedErpChannel } = this.state

    const paymentStatus = selectedOrder.get('payment_state')
    // const orderStatus = selectedOrder.get('order_state')
    const isMkpOrder = selectedOrder.has('mkp_id')
    // console.log('paymentStatus => ', paymentStatus)
    // return
    if (_.isNil(selectedErpDocOption)) {
      p.op.showConfirmationOkOnly('', 'กรุณาเลือกประเภทเอกสาร')
      return
    }

    // @ts-ignore
    const erp_ch_id = util.getNavParam(this.props, 'erp_ch_id')
    let systemName = ''

    // ไม่สามารถสร้างใบกำกับภาษีเนื่องจากไม่มีรายการสินค้า ค่าส่ง หรือค่าอื่นๆ ที่มี VAT
    if (!_.isNil(selectedErpChannel)) {
      systemName = util.getErpChannelSystemName(selectedErpChannel.erp_system_id)
      if (
        selectedErpChannel.vat_type === 'NO_VAT' &&
        selectedErpChannel.erp_other_fee_vat_type === 'NO_VAT' &&
        selectedErpChannel.erp_shipping_fee_vat_type === 'NO_VAT' &&
        isTaxInvoiceChecked
      ) {
        p.op.showConfirmationOkOnly('', p.op.t('e_msg_by_error_code.CannotCreateTaxInvoiceDueToNoProductWithVat.m'))
        return
      }
    }

    // กรุณาบันทึกรับชำระให้ครบถ้วนก่อนสร้างใบเสร็จ สำหรับในการสร้างใบเสร็จรับเงิน
    if (selectedErpDocOption.id === 14 && paymentStatus !== 119 && !isMkpOrder) {
      p.op.showConfirmationOkOnly('', 'กรุณาบันทึกและยืนยันการรับชำระให้ครบถ้วนก่อนสร้างใบเสร็จรับเงิน')
      return
    }

    // if (isMkpOrder && orderStatus !== 109) {
    //   p.op.showConfirmationOkOnly('', 'กรุณารอออเดอร์ได้รับการยืนยันก่อนสร้างใบเสร็จรับเงิน')
    //   return
    // }
    await this._setIsLoading()

    const docName = selectedErpDocOption.label

    const confirm = await new Promise<boolean>((passConfirm) => {
      p.op.showConfirmation(
        `ยืนยันการสร้าง${selectedErpDocOption.label}ไปที่ ${systemName}`,
        `เมื่อยืนยันแล้วระบบจะสร้างคิวคำสั่งงานเพื่อส่งข้อมูลไปที่ ${systemName} เพื่อสร้าง${docName}`,
        () => passConfirm(true),
        () => passConfirm(false),
        'ยืนยัน',
        'ยกเลิก'
      )
    })

    if (!confirm) {
      await this._UnsetIsLoading()
      return
    }

    // @ts-ignore
    // const erp_ch_id = util.getNavParam(this.props, 'erp_ch_id')
    // console.log('erp_ch_id => ', erp_ch_id)
    const body: { [key: string]: any } = {
      store_id: selectedStore.get(`id`),
      order_ids: [selectedOrder.get('id')],
      erp_ch_id,
      erp_doc_type: selectedErpDocOption.id,
    }
    if (isTaxInvoiceChecked) {
      body.is_tax_invoice = isTaxInvoiceChecked
    }
    // return
    const apiOptions = {
      showSpinner: true,
    }
    const res: { bgjob_uuid: string } = await api.postV2('erp/document/create', body, apiOptions)
    // console.log('res => ', res)

    if (res.bgjob_uuid) {
      await this._fetchOrderErp()
      await util.alertWithNavToBgJobListView({
        title: 'สร้าง "คิวคำสั่งงาน" สำเร็จ',
        message:
          'คิวคำสั่งงาน สำหรับการ "สร้างเอกสาร" ได้ถูกสร้างแล้ว ระบบกำลังดำเนินการอยู่เบื้องหลัง และอาจใช้เวลาซักครู่จนกว่าจะครบทุกออเดอร์',
        // @ts-ignore
        dispatch: this.props.navigation.dispatch,
        selectedStoreId: this.props.selectedStore.get('id'),
        onUserConfirm: null,
        initBgJobUUID: res.bgjob_uuid,
      })
      // @ts-ignore
      // const callBackFromErpOrderDetailUpdate = util.getNavParam(this.props, 'callBackFromErpOrderDetailUpdate')
      // if (_.isFunction(callBackFromErpOrderDetailUpdate)) {
      //   callBackFromErpOrderDetailUpdate()
      // }
    }
    await this._UnsetIsLoading()
  }

  _cancelDocument = async (doc: IErpDocOrder) => {
    const { selectedOrder, selectedStore } = this.props
    if (_.isNil(selectedOrder) || _.isNil(selectedStore)) {
      return
    }
    // const { selectedErpDocOption } = this.state

    const docName = CONS.ERP_DOC_TYPE_OPTIONS.find((data) => data.id === doc.erp_doc_type)?.label
    // @ts-ignore
    const erp_ch_id = util.getNavParam(this.props, 'erp_ch_id')
    const systemName = util.getErpChannelSystemName(erp_ch_id)
    const confirm = await new Promise<boolean>((passConfirm) => {
      p.op.showConfirmation(
        `ยืนยันยกเลิกเอกสาร ${systemName}`,
        `เมื่อยืนยันแล้วระบบจะยกเลิกเอกสาร${docName}  ออเดอร์#${selectedOrder.get('id')}`,
        () => passConfirm(true),
        () => passConfirm(false),
        'ยืนยัน',
        'ยกเลิก'
      )
    })

    if (!confirm) {
      return
    }
    await this._setIsLoading()
    const body: { [key: string]: any } = {
      store_id: selectedStore.get(`id`),
      erp_doc: [
        {
          erp_ch_id,
          order_id: selectedOrder.get('id'),
          erp_doc_code: doc.erp_doc_code,
          erp_doc_id: doc.erp_doc_id,
        },
      ],
    }
    const apiOptions = {
      showSpinner: true,
    }
    const res: { order_documents: IErpDocOrder[] } = await api.postV2('erp/document/cancel', body, apiOptions)
    // console.log('_fetchOrderErp => ', res)
    if (res.order_documents) {
      await util.setStatePromise(this, {
        orderErpDocuments: res.order_documents,
      })
    }
    await this._UnsetIsLoading()
    await this._fetchOrderErp()
  }

  _syncDoc = async () => {
    const { selectedStore, selectedOrder } = this.props
    await this._setIsLoading()

    const body: { [key: string]: any } = {
      store_id: selectedStore.get(`id`),
      order_ids: [selectedOrder.get('id')],
    }
    // return
    const apiOptions = {
      showSpinner: true,
    }
    const res: { bgjob_uuid: string } = await api.postV2('erp/document/sync/status', body, apiOptions)
    // console.log('res => ', res)

    if (res.bgjob_uuid) {
      await this._fetchOrderErp()
      await util.alertWithNavToBgJobListView({
        title: 'สร้าง "คิวคำสั่งงาน" สำเร็จ',
        message:
          'คิวคำสั่งงาน สำหรับการ "ซิงค์เอกสาร" ได้ถูกสร้างแล้ว ระบบกำลังดำเนินการอยู่เบื้องหลัง และอาจใช้เวลาซักครู่จนกว่าจะครบทุกออเดอร์',
        // @ts-ignore
        dispatch: this.props.navigation.dispatch,
        selectedStoreId: this.props.selectedStore.get('id'),
        onUserConfirm: null,
        initBgJobUUID: res.bgjob_uuid,
      })
      // @ts-ignore
      // const callBackFromErpOrderDetailUpdate = util.getNavParam(this.props, 'callBackFromErpOrderDetailUpdate')
      // if (_.isFunction(callBackFromErpOrderDetailUpdate)) {
      //   callBackFromErpOrderDetailUpdate()
      // }
    }
    await this._UnsetIsLoading()
  }

  _goBack = () => {
    // @ts-ignore
    const callBackFromErpOrderDetailUpdate = util.getNavParam(this.props, 'callBackFromErpOrderDetailUpdate')
    if (_.isFunction(callBackFromErpOrderDetailUpdate)) {
      callBackFromErpOrderDetailUpdate()
    }
    // @ts-ignore
    util.navGoBack(this.props)
  }

  renderHeader = () => {
    // @ts-ignore
    const params = util.getNavParams(this.props)
    const { order } = params
    if (_.isNil(order)) {
      return null
    }
    const orderType = order.type
    const typeText = orderType === 3 ? 'ออเดอร์ซื้อ' : 'ออเดอร์ขาย'
    const orderIdText = `#${order.id}`
    return <XCustomHeader title={`${typeText} ${orderIdText}`} headerLeftProps={{ backIcon: true, onPressItem: () => this._goBack() }} />
  }

  // renderFooter = () => {
  //   const mkpName = util.getMKPName(1)
  //   return null
  //   return (
  //     <VStack w='full' minH='10' p='1' alignItems='center' justifyContent='center'>
  //       <XButtonWithIcon w='full' onPress={() => null} leftIcon={<XIcon name='sync' family='MaterialIcons' />}>
  //         {`ซิงค์ข้อมูลออเดอร์ล่าสุดกับ ${mkpName}`}
  //       </XButtonWithIcon>
  //     </VStack>
  //   )
  // }

  _renderViewSystemName = () => {
    // @ts-ignore
    const erp_ch_id = util.getNavParam(this.props, 'erp_ch_id')
    // console.log('erp_ch_id => ', erp_ch_id)
    const { selectedErpChannel } = this.state

    let name = ''
    if (!_.isNil(selectedErpChannel)) {
      name = util.getErpChannelSystemName(selectedErpChannel.erp_system_id)
    }

    return (
      <HStack h='6' alignItems='center'>
        <HStack flex={1}>
          <XText>ชื่อระบบบัญชี</XText>
        </HStack>
        <HStack flex={1} justifyContent='flex-end'>
          <XText>{name}</XText>
        </HStack>
      </HStack>
    )
  }

  _renderErpName = () => {
    // @ts-ignore
    const erp_ch_id = util.getNavParam(this.props, 'erp_ch_id')
    const name = util.getErpChannelName(erp_ch_id)
    return (
      <HStack h='6' alignItems='center'>
        <HStack flex={1}>
          <XText>ชื่อบัญชี</XText>
        </HStack>
        <HStack flex={2} textAlign='right' justifyContent='flex-end'>
          <XText>{name}</XText>
        </HStack>
      </HStack>
    )
  }

  _renderSyncDoc = () => {
    const { selectedErpChannel, orderErpDocuments } = this.state
    if (_.isNil(orderErpDocuments) || orderErpDocuments.length < 1) {
      return null
    }
    let name = 'ระบบบัญชี'
    if (!_.isNil(selectedErpChannel)) {
      name = util.getErpChannelSystemName(selectedErpChannel.erp_system_id)
    }

    return (
      <VStack mt='4'>
        <HStack flex={1} alignItems='center' justifyContent='flex-end'>
          <XButton variant='outline' w='250px' onPress={() => this._syncDoc()}>
            <HStack w='full' h='6' alignItems='center' justifyContent='center'>
              <XIcon family='Ionicons' name='sync' />
              <XText numberOfLines={1} color={COLORS.APP_MAIN}>
                {`ซิงค์เอกสารกับระบบบัญชี ${name}`}
              </XText>
            </HStack>
          </XButton>
        </HStack>
        <HStack mt='2' />
      </VStack>
    )
  }

  _renderDocumentView = (doc: IErpDocOrder, index: number) => {
    // console.log('doc => ', doc)
    const { orderErpDocuments } = this.state
    const { selectedStore } = this.props
    // console.log('selectedStore => ', selectedStore.toJS())
    let docTypeName = CONS.ERP_DOC_TYPE_OPTIONS.find((data) => data.id === doc.erp_doc_type)?.label
    const isCancel = doc.cancelled_at && doc.erp_doc_code !== ''
    const createdBy = util.getDisplayNameByUid(doc.created_by_user_id)
    let cancelLable = 'ยกเลิกเอกสาร'
    // let actionBy = util.getDisplayName()
    if (isCancel) {
      const profile = util.getProfile()
      // const helpers = selectedStore.get('helpers').toJS()
      let helpers = selectedStore.get('helpers') || List([])
      helpers = helpers.toJS() || []
      // console.log('profile => ', profile)
      // console.log('helpers => ', helpers)

      if (doc.cancelled_by_user_id) {
        // @ts-ignore
        if (doc.cancelled_by_user_id === profile.id) {
          // @ts-ignore
          cancelLable += `โดย ${profile.display_name} `
        } else if (_.isArray(helpers) && helpers.length > 0) {
          helpers.forEach((helper) => {
            if (helper.uid === doc.cancelled_by_user_id) {
              cancelLable += `โดย ${helper.n} `
            }
          })
        }
        // if (_.isArray(helpers) && helpers.length > 0) {
        //   helpers.forEach((helper) => {
        //     if (helper.uid === doc.cancelled_by_user_id) {
        //       cancelLable += `โดย ${helper.n} `
        //     }
        //   })
        // }
      }
      cancelLable += `เมื่อ ${doc.cancelled_at}`
    }

    let showCancelBtn = true
    // ถ้ามีใบแจ้งหนี้และใบเสร็จรับเงิน จะไม่มีปุ่มยกเลิกใบแจ้งหนี้
    if (doc.erp_doc_type === 12) {
      orderErpDocuments.forEach((document) => {
        if (document.erp_doc_type === 14 && document.erp_doc_code && _.isNil(document.cancelled_at)) {
          showCancelBtn = false
        }
      })
    }

    if (doc.is_tax_invoice) {
      docTypeName += '/ใบกำกับภาษี'
    }
    // console.log('cancelLable => ', cancelLable)

    const onlineLink = doc.online_view_url
    // const onlineLink = 'https://www.google.com/'
    // console.log('doc => ', doc)
    return (
      <XCard
        w='full'
        mt={index > 0 ? '2' : '0'}
        key={`${doc.erp_doc_type}_${index}`}
        bgColor={isCancel ? COLORS.BG_LIGHT_GREY : COLORS.WHITE}>
        {/* // ยกเลิกเอกสาร */}
        {isCancel ? (
          <HStack w='full' px='2' h='10' bgColor={COLORS.BRAND_Danger} alignItems='center'>
            <XText color={COLORS.WHITE}>{cancelLable}</XText>
          </HStack>
        ) : null}

        <VStack p='2' w='full'>
          {/* // ประเภทเอกสาร */}
          <HStack h='6' alignItems='center'>
            <HStack flex={1}>
              <XText>ประเภทเอกสาร</XText>
            </HStack>
            <HStack flex={1} justifyContent='flex-end'>
              <XText bold>{docTypeName}</XText>
            </HStack>
          </HStack>

          {/* // เลขที่เอกสาร */}
          {doc.erp_doc_code ? (
            <HStack h='6' alignItems='center' mt='1'>
              <HStack flex={1}>
                <XText>เลขที่เอกสาร</XText>
              </HStack>
              <HStack>
                {onlineLink ? (
                  <TouchableOpacity
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onPress={() => Linking.openURL(onlineLink)}
                    style={{
                      width: 60,
                      height: 35,
                      backgroundColor: COLORS.BG_LIGHT_GREY,
                      borderRadius: 90,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                    <XIcon name='printer' family='AntDesign' />
                  </TouchableOpacity>
                ) : (
                  <HStack style={{ width: 60, height: 35 }} />
                )}
              </HStack>
              <HStack ml='2'>
                <TouchableOpacity
                  onPress={() => {
                    Clipboard.setString(doc.erp_doc_code)
                    p.op.showToast(`คัดลอก ${doc.erp_doc_code} เรียบร้อยแล้ว`, 'success')
                  }}
                  style={{
                    // flex: 1,
                    justifyContent: 'center',
                    borderColor: COLORS.APP_MAIN,
                    borderRadius: 90,
                    borderWidth: 1,
                    borderTopWidth: 1,
                    borderBottomWidth: 1,
                    borderLeftWidth: 1,
                    borderRightWidth: 1,
                    alignItems: 'center',
                    height: 35,
                  }}>
                  <XText bold px='4' py='1'>
                    {doc.erp_doc_code}
                  </XText>
                </TouchableOpacity>
              </HStack>
            </HStack>
          ) : null}

          {/* // วันที่เอกสาร */}
          {doc.erp_doc_issue_date ? (
            <HStack h='6' alignItems='center' mt='1'>
              <HStack flex={1}>
                <XText>วันที่เอกสาร</XText>
              </HStack>
              <HStack flex={1} justifyContent='flex-end'>
                <XText>{doc.erp_doc_issue_date}</XText>
              </HStack>
            </HStack>
          ) : null}

          {/* // สร้างเอกสารเมื่อ */}
          {doc.create_doc_requested_at ? (
            <HStack h='6' alignItems='center' mt='1'>
              <HStack flex={1}>
                <XText>สร้างเอกสารเมื่อ</XText>
              </HStack>
              <HStack flex={1} justifyContent='flex-end'>
                <XText>{doc.create_doc_requested_at}</XText>
              </HStack>
            </HStack>
          ) : null}

          {/* // สร้างเอกสารโดย */}
          {doc.created_by_user_id ? (
            <HStack h='6' alignItems='center' mt='1'>
              <HStack flex={1}>
                <XText>สร้างเอกสารโดย</XText>
              </HStack>
              <HStack flex={1} justifyContent='flex-end'>
                <XText>{createdBy}</XText>
              </HStack>
            </HStack>
          ) : null}

          {/* // กำลังสร้าง ... */}
          {doc.erp_doc_code === '' ? (
            <HStack h='6' alignItems='center' mt='1'>
              <HStack flex={1}>
                <XText>{`กำลังสร้าง${docTypeName}...`}</XText>
              </HStack>
            </HStack>
          ) : null}

          {/* // ยกเลิกเอกสาร */}
          {/* {doc.cancelled_at && doc.erp_doc_code !== '' ? (
            <HStack h='6' alignItems='center' mt='1'>
              <HStack flex={1}>
                <XText color={COLORS.RED}>ยกเลิกเอกสารเมื่อ</XText>
              </HStack>
              <HStack flex={1} justifyContent='flex-end'>
                <XText color={COLORS.RED}>{doc.cancelled_at}</XText>
              </HStack>
            </HStack>
          ) : null} */}

          {/* // ยกเลิกเอกสาร */}
          {acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.ERP_DOC_CANCEL) &&
          doc.erp_doc_code &&
          _.isNil(doc.cancelled_at) &&
          showCancelBtn ? (
            <HStack h='8' alignItems='center' mt='2'>
              <TouchableOpacity
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onPress={() => this._cancelDocument(doc)}
                style={{
                  // flex: 1,
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  backgroundColor: COLORS.BRAND_Danger,
                  borderRadius: 90,
                  marginTop: 2,
                  marginBottom: 2,
                }}>
                <XText color={COLORS.WHITE} px='2' py='1'>
                  ยกเลิกเอกสาร
                </XText>
              </TouchableOpacity>
            </HStack>
          ) : null}
        </VStack>
      </XCard>
    )
  }

  _renderErpInfo = () => (
    <XCard p='2'>
      <HStack flex={1} alignItems='center' justifyContent='center'>
        <XText bold>ข้อมูลทั่วไป</XText>
      </HStack>
      {this._renderViewSystemName()}
      {this._renderErpName()}
      {this._renderSyncDoc()}
    </XCard>
  )

  _renderSelectedDocumentView = () => {
    const { selectedErpDocTypeIndex, isTaxInvoiceChecked, visibleErpDocTypeIds, orderErpDocuments } = this.state
    // if (_.isNil(visibleErpDocTypeIds) || visibleErpDocTypeIds.length < 1) {
    //   return null
    // }
    if (!acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.ERP_DOC_CREATE)) {
      return null
    }
    // console.log('isTaxInvoiceChecked => ', isTaxInvoiceChecked)
    // console.log('selectedErpDocTypeIndex => ', selectedErpDocTypeIndex)
    // console.log('visibleErpDocTypeIds => ', visibleErpDocTypeIds)
    let textNoDoctypeCanCreate = '*หากต้องการสร้างเอกสารไปที่ PEAK กรุณาตั้งค่าระบบบัญชีโดย เปิดใช้งานประเภทเอกสาร อย่างน้อย 1 ประเภท'
    if (!_.isNil(orderErpDocuments) && orderErpDocuments.length > 1) {
      textNoDoctypeCanCreate = '*คุณสร้างเอกสารที่เปิดใช้งานครบแล้ว หรือ กรุณาตั้งค่าระบบบัญชีโดย เปิดใช้งานประเภทเอกสาร อย่างน้อย 1 ประเภท'
    }
    return (
      <XCard p='2' mt='2' w='full'>
        <HStack flex={1} alignItems='center' justifyContent='center'>
          <XText bold>สร้างเอกสาร</XText>
        </HStack>
        <HStack w='full'>
          <XText>ประเภทเอกสารที่ต้องการส่งออกไปยัง PEAK</XText>
        </HStack>
        {/* <HStack mt='1' />
        <HStack>
          <XIcon family='Ionicons' name='radio-button-on' />
          <XText>ใบเสร็จรับเงิน</XText>
          <TouchableOpacity style={{ marginLeft: 16 }} onPress={() => this.setState({ isTaxInvoiceChecked: !isTaxInvoiceChecked })}>
            <HStack>
              <XIcon family='FontAwesome' name={isTaxInvoiceChecked ? 'check-square-o' : 'square-o'} />
              <XText ml='2'>ออกใบกำกับภาษี</XText>
            </HStack>
          </TouchableOpacity>
        </HStack> */}
        <HStack mt='4' />

        {!_.isNil(visibleErpDocTypeIds) && visibleErpDocTypeIds.length > 0 ? (
          <ErpDocTypeSelector
            visibleErpDocTypeIds={visibleErpDocTypeIds}
            selectedIndex={selectedErpDocTypeIndex}
            isTaxInvoiceChecked={isTaxInvoiceChecked}
            onOptionChange={this._onErpOptionChange}
          />
        ) : null}

        <HStack w='full'>
          {_.isNil(visibleErpDocTypeIds) || visibleErpDocTypeIds.length < 1 ? <XText>{textNoDoctypeCanCreate}</XText> : null}
        </HStack>

        <HStack flex={1} alignItems='center' justifyContent='center' mt='6'>
          <XButton w='40' h='10' onPress={() => this._submitDocType()}>
            <XText color={COLORS.WHITE}>สร้างเอกสาร</XText>
          </XButton>
        </HStack>
        <HStack mt='2' />
      </XCard>
    )
  }

  _onErpOptionChange = (selectedIndex: number, selectedOption: IErpDocTypeOption, isTaxInvoice?: boolean) => {
    // console.log('selectedIndex => ', selectedIndex)
    // console.log('selectedOption => ', selectedOption)
    // console.log('isTaxInvoice => ', isTaxInvoice)
    this.setState({
      selectedErpDocTypeIndex: selectedIndex,
      isTaxInvoiceChecked: !_.isNil(isTaxInvoice) ? isTaxInvoice : false,
      selectedErpDocOption: selectedOption,
    })
  }

  _renderDocumentListView = () => {
    const { orderErpDocuments } = this.state
    if (_.isNil(orderErpDocuments) || orderErpDocuments.length < 1) {
      return null
    }
    return (
      <XCard p='2' mt='2' w='full'>
        <HStack flex={1} alignItems='center' justifyContent='center'>
          <XText bold>เอกสาร</XText>
        </HStack>
        {orderErpDocuments.map((doc, idx) => this._renderDocumentView(doc, idx))}
      </XCard>
    )
  }

  _renderMain = () => {
    const {} = this.state
    return (
      <VStack w='full'>
        {this._renderErpInfo()}
        {this._renderDocumentListView()}
        {this._renderSelectedDocumentView()}
      </VStack>
    )
  }

  _isLoading = () => {
    const { loadingWidth, loadingHeight, isLoading } = this.state
    // console.log('isLoading => ', isLoading)
    if (!isLoading) {
      return null
    }
    return (
      <VStack
        style={{
          // zIndex: 999,
          position: 'absolute',
          width: loadingWidth,
          height: loadingHeight,
          backgroundColor: 'rgba(52, 52, 52, 0.7)',
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0,
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
          marginTop: 0,
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <XSpinner />
      </VStack>
    )
  }

  renderMain = () => (
    <XContainer>
      {this.renderHeader()}
      <XContent>
        <VStack
          w='full'
          p='1'
          onLayout={(event) => {
            const { x, y, width, height } = event.nativeEvent.layout
            util.setStatePromise(this, { loadingWidth: width, loadingHeight: height })
          }}>
          {this._renderMain()}
        </VStack>
        {this._isLoading()}
      </XContent>
      {/* {this.renderFooter()} */}
    </XContainer>
  )

  render() {
    return this.renderMain()
  }
}
