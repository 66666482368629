import React from 'react'
import { View, TouchableOpacity, StyleProp, TextStyle, ViewStyle, StyleSheet } from 'react-native'
import _ from 'lodash'

import { S } from 'x/config/styles'
import * as util from 'x/utils/util'
import { IQuestionsAndAnswers, IXRadioListOption, IXRadioListProps, IXRadioListRenderItemInfo } from 'x/index'

import HStack from 'xui/components/HStack'
import XTitle from './XTitle'
import XIcon from './XIcon'
import XText from './XText'
import HelpButton from './HelpButton'
import XHelpModal from './XHelpModal'

// // ------- Custom Render Example Usage ----------
// const MyAwesomeRadioList = () => (
//   <XRadioList<string>
//     title={'เรียงโดย...'}
//     options={[
//       { label: 'เลขที่ออเดอร์', value: 'id' },
//       { label: 'วันสร้างออเดอร์', value: 'created_at' },
//       { label: 'วันพร้อมส่ง', value: 'ready_to_ship_date' },
//       { label: 'วันกำหนดส่ง', value: 'date_delivery' },
//       { label: 'วันยกเลิก ออเดอร์อัตโนมัติเมื่อไม่ชำระ', value: 'expiration_date' },
//     ]}
//     renderItem={(info) => {
//       return (
//         <TouchableOpacity key={info.index.toString()} onPress={info.onRadioPressHandler}>
//           {info.renderRadioIcon()}
//           {info.renderRadioLabel()}
//           <View>
//             <Text>{'My Custom Render Element'}</Text>
//           </View>
//         </TouchableOpacity>
//       )
//     }}
//     onRadioChange={(pressedOpt, pressedIdx) => console.log(`onRadioChange pressedOpt=${pressedOpt} / ${pressedIdx}`)}
//   />
// )

function XRadioList<T extends IXRadioListOption>(props: IXRadioListProps<T>) {
  // const [selectedIndex, setSelectedIndex] = React.useState(0)

  // React.useEffect(() => {
  //   if (_.isNumber(props.selectedIndex)) {
  //     _onPressRadioItem(props.selectedIndex)
  //   }
  // }, [])

  // React.useEffect(() => {
  //   if (_.isNumber(props.selectedIndex) && props.selectedIndex !== selectedIndex) {
  //     _onPressRadioItem(props.selectedIndex)
  //   }
  // }, [props.selectedIndex])

  const _onPressRadioItem = async (pressedIdx: number) => {
    // setSelectedIndex(pressedIdx)
    // await util.delay(100)
    if (util.isAsyncFunction(props.onRadioChange)) {
      await props.onRadioChange(props.options[pressedIdx], pressedIdx)
    } else {
      props.onRadioChange(props.options[pressedIdx], pressedIdx)
    }
  }

  const _renderItem = (opt: T, itemIndex: number) => {
    const checked = props.selectedIndex === itemIndex

    // render custom item
    if (typeof props.renderItem === 'function') {
      const customItemRenderInfo: IXRadioListRenderItemInfo<T> = {
        index: itemIndex,
        option: opt,
        checked,
        disabled: props.disabled,
        onRadioPressHandler: () => _onPressRadioItem(itemIndex),
        renderRadioIcon: () => <RadioIcon disabled={props.disabled} checked={checked} radioIconStyle={props.radioIconStyle} />,
        renderRadioLabel: () => <RadioLabel disabled={props.disabled} checked={checked} label={opt.label} labelStyle={props.labelStyle} />,
      }
      return props.renderItem(customItemRenderInfo)
    }

    // render internal item
    return (
      <RadioItem
        disabled={props.disabled}
        key={itemIndex.toString()}
        index={itemIndex}
        checked={checked}
        label={opt.label}
        onPress={_onPressRadioItem}
        labelStyle={props.labelStyle}
        radioIconStyle={props.radioIconStyle}
        itemContainerStyle={props.itemContainerStyle}
        help={opt.help}
      />
    )
  }

  function _renderList() {
    return props.options.map(_renderItem)
  }

  return (
    <View style={[S.COLUMN_LEFT_TOP, StyleSheet.flatten(props.listContainerStyle)]}>
      <XTitle value={props.title} style={StyleSheet.flatten(props.titleStyle)} />
      {_renderList()}
    </View>
  )
}

XRadioList.defaultProps = {
  disabled: false,
  title: undefined,
  selectedIndex: 0,
  titleStyle: {},
  listContainerStyle: {},
  itemContainerStyle: {},
  labelStyle: {},
  radioIconStyle: {},
}

//  -----------------------------------------------------------------------------
// RadioItem = RadioIcon + RadioLabel

interface IRadioItemProps extends IRadioIconProps, IRadioLabelProps {
  index: number
  itemContainerStyle?: StyleProp<ViewStyle>
  onPress: (itemIndex: number) => void
}

const RadioItem: React.FC<IRadioItemProps> = (props) => {
  const _onPressRadioItem = () => {
    props.onPress(props.index)
  }
  const _renderAgreement = (onPressOpenOverlay: () => void) => <HelpButton onPress={() => onPressOpenOverlay()} />
  return (
    <TouchableOpacity
      style={[S.ROW_MIDDLE_START, S.MIN_HEIGHT_34, StyleSheet.flatten(props.itemContainerStyle)]}
      onPress={_onPressRadioItem}>
      <HStack alignItems='center' space='1'>
        <RadioIcon {...props} />
        <RadioLabel {...props} />
        {!_.isNil(props.help) ? (
          <XHelpModal
            key={props.label}
            headerTitle=''
            FAQ={props.help}
            initiateOpenIndex={0}
            renderButton={(onPressOpenOverlay: () => void) => _renderAgreement(onPressOpenOverlay)}
          />
        ) : null}
      </HStack>
    </TouchableOpacity>
  )
}

RadioItem.defaultProps = {
  disabled: false,
  checked: false,
  labelStyle: {},
  itemContainerStyle: {},
  radioIconStyle: {},
}

//  -----------------------------------------------------------------------------
// RadioLabel
interface IRadioLabelProps {
  disabled?: boolean
  checked: boolean
  label: string
  labelStyle?: StyleProp<TextStyle>
  help?: IQuestionsAndAnswers[]
}

const RadioLabel: React.FC<IRadioLabelProps> = (props) => {
  const isInactive = !props.checked || props.disabled
  return (
    <XText variant={isInactive ? 'inactive' : 'active'} style={props.labelStyle}>
      {props.label}
    </XText>
  )
  // return (
  //   <Text style={[isInactive ? S.TEXT_INACTIVE : S.TEXT_ACTIVE, { marginLeft: 6 }, StyleSheet.flatten(props.labelStyle)]}>
  //     {props.label}
  //   </Text>
  // )
}

RadioLabel.defaultProps = {
  disabled: false,
  checked: false,
  label: '',
  labelStyle: {},
}

//  -----------------------------------------------------------------------------
// RadioIcon

interface IRadioIconProps {
  disabled?: boolean
  checked: boolean
  radioIconStyle?: StyleProp<TextStyle>
}

const RadioIcon: React.FC<IRadioIconProps> = (props) => {
  const isInactive = props.disabled || !props.checked
  return (
    <XIcon
      variant={isInactive ? 'inactive' : 'primary'}
      // style={[
      //   {
      //     fontSize: STYLES.FONT_ICON_NORMAL,
      //     // height: STYLES.FONT_ICON_NORMAL,
      //     // width: STYLES.FONT_ICON_NORMAL,
      //     color: isInactive ? COLORS.TEXT_INACTIVE : COLORS.APP_MAIN,
      //     // textAlign: 'center',
      //   },
      //   StyleSheet.flatten(props.radioIconStyle),
      // ]}
      // @ts-ignore
      style={props.radioIconStyle}
      name={props.checked ? 'radio-button-on' : 'radio-button-off'}
    />
  )
}

RadioIcon.defaultProps = {
  disabled: false,
  checked: false,
  radioIconStyle: {},
}

export default XRadioList
