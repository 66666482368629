import React from 'react'
import _ from 'lodash'
import { ViewStyle, StyleProp, StyleSheet } from 'react-native'

import * as util from 'x/utils/util'
import CONS from 'x/config/constants'
import { IXSegmentOption, IOrdersSortBy, IOrdersSortType, IOrdersSortOptions } from 'x/index'

import { diff } from 'deep-object-diff'
import VStack from 'xui/components/VStack'
import Box from 'xui/components/Box'
import HStack from 'xui/components/HStack'
import XOverlay from '../XOverlay'
import XCustomHeader from '../XCustomHeader'
import XSegment from '../XSegment'
import XRadioList from '../XRadioList'
import XIconButton from '../XIconButton'
import XIcon from '../XIcon'
import XCard from '../XCard'
import XButton from '../XButton'

interface IOrdersSortIconButtonProps {
  currentSortOptions?: IOrdersSortOptions
  btnStyle?: StyleProp<ViewStyle>
  onApply: (sortOptions: IOrdersSortOptions) => Promise<void>

  preventCloseAfterApplied?: boolean
}

const DEFAULT_SORT_BY_INDEX = 0
const DEFAULT_SORT_TYPE_INDEX = 0
// const DEFAULT_SORT_OPTIONS: IOrdersSortOptions = {
//   sortBy: 'desc',
//   sortType: 'id',
// }

const OrdersSortIconButton: React.FC<IOrdersSortIconButtonProps> = (props) => {
  const [isOverlayVisible, setIsOverlayVisible] = React.useState(false)
  const [editingSortByIndex, setEditingSortByIndex] = React.useState(DEFAULT_SORT_BY_INDEX)
  const [editingSortTypeIndex, setEditingSortTypeIndex] = React.useState(DEFAULT_SORT_TYPE_INDEX)
  const [editingSortOptions, setEditingSortOptions] = React.useState<IOrdersSortOptions>(CONS.DEFAULT_ORDERS_SORT_OPTIONS)
  const [submitting, setSubmitting] = React.useState(false)

  const isDiffSortOptions = !_.isEmpty(diff(props.currentSortOptions, editingSortOptions))
  const isDefaultOptions = _.isEmpty(diff(CONS.DEFAULT_ORDERS_SORT_OPTIONS, editingSortOptions))
  const isResetToDefaultDisabled = isDefaultOptions || submitting
  // const isApplyDisabled = !isDiffSortOptions || submitting
  // ถ้าใช้เงื่อนไขด้านบน !isDiffSortOptions จะทำให้ submit ซ้ำไม่ได้ กรณีเน็ตขาดช่วง
  const isApplyDisabled = submitting

  React.useEffect(() => {
    let isMounted = true

    if (isMounted) {
      _loadEditingSortOptions(props.currentSortOptions)
    }

    return () => {
      isMounted = false
    }
  }, [isOverlayVisible, props.currentSortOptions])

  const _loadEditingSortOptions = async (newSortOptions: IOrdersSortOptions) => {
    if (!_.isObject(newSortOptions) || _.isEmpty(newSortOptions)) {
      console.log('_loadEditingSortOptions newSortOptions => ', newSortOptions)

      // laod default setting if input empty object
      _loadEditingSortOptions(CONS.DEFAULT_ORDERS_SORT_OPTIONS)
      // setEditingSortTypeIndex(DEFAULT_SORT_TYPE_INDEX)
      // setEditingSortByIndex(DEFAULT_SORT_BY_INDEX)
      return
    }

    // const diffSortOptions = diff(editingSortOptions, newSortOptions)
    // // console.log('_loadEditingSortOptions diffSortOptions => ', diffSortOptions)
    // if (_.isEmpty(diffSortOptions)) {
    //   return
    // }

    const newOpts = newSortOptions
    // console.log('_loadEditingSortOptions newOpts => ', newOpts)

    let selectedSortTypeIndex = CONS.ORDERS_SORT_TYPE_OPTIONS.findIndex((opt) => opt.value === newOpts.sortType)
    selectedSortTypeIndex = selectedSortTypeIndex >= 0 ? selectedSortTypeIndex : 0
    // setEditingSortTypeIndex(selectedSortTypeIndex)
    if (selectedSortTypeIndex !== editingSortTypeIndex) {
      _onSortTypeSegmentChange(CONS.ORDERS_SORT_TYPE_OPTIONS[selectedSortTypeIndex], selectedSortTypeIndex)
    }

    let selectedSortByIndex = CONS.ORDERS_SORT_BY_OPTIONS.findIndex((opt) => opt.value === newOpts.sortBy)
    selectedSortByIndex = selectedSortByIndex >= 0 ? selectedSortByIndex : 0
    setEditingSortByIndex(selectedSortByIndex)
    if (selectedSortByIndex !== editingSortByIndex) {
      _onSortByRadioChange(CONS.ORDERS_SORT_BY_OPTIONS[selectedSortByIndex], selectedSortByIndex)
    }
    // console.log('_loadEditingSortOptions selectedSortByIndex => ', selectedSortByIndex)
    // console.log('_loadEditingSortOptions selectedSortTypeIndex => ', selectedSortTypeIndex)
  }

  const _openOverlay = () => {
    setIsOverlayVisible(true)
  }
  const _closeOverlay = () => {
    setIsOverlayVisible(false)
  }
  const _onSortTypeSegmentChange = (opt: IXSegmentOption<IOrdersSortType>, idx: number) => {
    // console.log('_onSortBySegmentChange opt => ', opt)
    setEditingSortTypeIndex(idx)
    setEditingSortOptions({ ...editingSortOptions, sortType: opt.value })
  }

  const _onSortByRadioChange = (opt: IXSegmentOption<IOrdersSortBy>, idx: number) => {
    // console.log('_onSortTypeRadioChange opt => ', opt)
    setEditingSortByIndex(idx)
    setEditingSortOptions({ ...editingSortOptions, sortBy: opt.value })
  }

  async function _onPressResetToDefault() {
    setSubmitting(true)
    await util.delay(100)
    _loadEditingSortOptions(CONS.DEFAULT_ORDERS_SORT_OPTIONS)
    await util.delay(200)
    setSubmitting(false)
  }

  async function _onPressApply() {
    setSubmitting(true)
    await util.delay(100)
    await props.onApply(editingSortOptions)
    await util.delay(200)
    if (!props.preventCloseAfterApplied) {
      _closeOverlay()
    }
    setSubmitting(false)
  }

  return (
    <Box>
      <XIconButton family='MaterialCommunityIcons' name='sort' style={StyleSheet.flatten(props.btnStyle)} onPress={_openOverlay} />
      {!isDefaultOptions ? (
        <XIcon family='MaterialCommunityIcons' name='check-circle' variant='success' position='absolute' bottom='0' right='0' size='12px' />
      ) : null}
      <XOverlay
        // contentStyle={{ padding: 8 }}
        // contentStyle={{ width: 300, height: p.op.isWeb() ? 360 : undefined }}
        visible={isOverlayVisible}
        onRequestClose={_closeOverlay}>
        <XCard w='full'>
          <XCustomHeader
            headerRightProps={{
              closeIcon: true,
              // submitting: isFetchMapping,
              onPressItem: _closeOverlay,
            }}
            title='การเรียงออเดอร์'
          />
          <XCard.Body>
            <VStack w='full' space='1'>
              {/* <Text style={[S.TEXT_BLUE, S.TEXT_BOLD]}>{'yoooooo'}</Text> */}
              <XSegment<IOrdersSortType>
                // initialIndex={editingSortTypeIndex}
                segmentContainerStyle={{ minWidth: 200 }}
                title='เรียงจาก...'
                selectedIndex={editingSortTypeIndex}
                onSegmentChange={_onSortTypeSegmentChange}
                options={CONS.ORDERS_SORT_TYPE_OPTIONS}
                // options={[
                //   { label: 'ใหม่ไปเก่า', value: 'desc' },
                //   { label: 'เก่าไปใหม่', value: 'asc' },
                // ]}
              />

              <XRadioList
                title='เรียงโดย...'
                selectedIndex={editingSortByIndex}
                options={CONS.ORDERS_SORT_BY_OPTIONS}
                // options={[
                //   { label: 'เลขที่ออเดอร์', value: 'id' },
                //   { label: 'วันสร้างออเดอร์', value: 'created_at' },
                //   { label: 'วันพร้อมส่ง', value: 'ready_to_ship_date' },
                //   { label: 'วันกำหนดส่ง', value: 'date_delivery' },
                //   { label: 'วันยกเลิก ออเดอร์อัตโนมัติเมื่อไม่ชำระ', value: 'expiration_date' },
                // ]}
                onRadioChange={_onSortByRadioChange}
              />

              <HStack w='full' alignItems='center' space='2'>
                <XButton flex={1} h='9' disabled={isResetToDefaultDisabled} variant='outline' onPress={_onPressResetToDefault}>
                  คืนค่าเดิม
                </XButton>

                <XButton flex={2} h='9' disabled={isApplyDisabled} onPress={_onPressApply}>
                  นำไปใช้
                </XButton>
              </HStack>
            </VStack>
          </XCard.Body>
        </XCard>
      </XOverlay>
    </Box>
  )
}

OrdersSortIconButton.displayName = 'OrdersSortIconButton'
OrdersSortIconButton.defaultProps = {
  currentSortOptions: CONS.DEFAULT_ORDERS_SORT_OPTIONS,
  btnStyle: {},
  preventCloseAfterApplied: false,
}

export default OrdersSortIconButton
