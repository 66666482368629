import React, { Component } from 'react'
import * as util from 'x/utils/util'
import p from 'x/config/platform-specific'

import { IBaseDateTimePickerProps, IBaseDateTimePickerState } from 'x/types'

export default abstract class BaseDateTimeSinglePicker extends Component<IBaseDateTimePickerProps, IBaseDateTimePickerState> {
  static displayName = 'BaseDateRangePicker'

  static defaultProps = {
    // labels: null,
    // selectedDates: null,
    showTime: true,
    showTimeSelect: false,
    // allowOnlyDaysFromToday: null,
    // minDate: null,
    // maxDate: null,
  }

  // INPUT_KEYS: string[]
  // DATETIME_FORMAT: string
  // DATE_FORMAT: string
  // CURRENT_DATE: Moment
  // MIN_DATE: Moment
  // MAX_DATE: Moment
  datePickerRefs?: any // Ref for app datepicker component

  // Native/Web Resize Image function
  // abstract async _getResizedImage(image: ImgFile, maxWidth: number, maxHeight: number): Promise<ImgFile|null>;
  // abstract async _getBase64fromImageURL(imgURL: string): Promise<string>;

  constructor(props: IBaseDateTimePickerProps) {
    super(props)
    // this.INPUT_KEYS = ['begin', 'end']
    // this.DATETIME_FORMAT = 'DD-MM-YYYY     HH:mm น.'
    // this.DATE_FORMAT = 'DD-MM-YYYY'
    // this.DATETIME_FORMAT = 'lll'
    // this.DATE_FORMAT = 'll'
    // this.DATETIME_FORMAT = 'DD MMM YYYY [  ] HH:mm[น.]'
    // this.DATE_FORMAT = 'DD MMM YYYY'
    // this.CURRENT_DATE = moment()
    this.datePickerRefs = React.createRef()

    // this.MIN_DATE = this.CURRENT_DATE.clone().subtract(20, 'y').startOf('d')
    // this.MAX_DATE = this.CURRENT_DATE.clone().add(20, 'y').endOf('d')

    this.state = {
      isShowTimePicker: false,
      isShowDatePicker: false,
      onPikerSelectedDate: new Date(),
      editingDateTime: new Date(),
      stopDidUpdateEditingDateTime: false,
    }

    // this.getDateRange = this.getDateRange.bind(this)
  }

  componentDidMount() {
    if (p.op.isWeb()) {
      const { selectedDate } = this.props
      util.setStatePromise(this, {
        editingDateTime: selectedDate,
      })
    }
  }

  componentDidUpdate(prevProps) {
    // console.log('prevProps => ', prevProps)
    // console.log('prevState=> ', prevState)
    if (p.op.isWeb() && !this.state.stopDidUpdateEditingDateTime) {
      // const { onChangeDate } = this.props
      if (prevProps.selectedDate !== this.state.editingDateTime) {
        // onChangeDate(prevProps.selectedDate)
        util.setStatePromise(this, {
          editingDateTime: prevProps.selectedDate,
        })
      }
    }
  }

  _onDateChange = (newDate: Date) => {
    const { onChangeDate, showTime } = this.props
    if (!showTime) {
      const newDateFormat = util.getDateFormatYYYYMMDD(newDate)
      // newDateTime = format ISO
      // Look here :: https://www.digi.com/resources/documentation/digidocs/90001437-13/reference/r_iso_8601_date_format.htm
      const newDateTime = new Date(`${newDateFormat}T00:00:00+07:00`)
      util.setStatePromise(this, {
        editingDateTime: newDateTime,
      })
      onChangeDate(newDateTime)
      return
    }
    // console.log('onChangeDate => ', newDate)
    util.setStatePromise(this, {
      editingDateTime: newDate,
    })
    onChangeDate(newDate)
    if (p.op.isWeb()) {
      util.setStatePromise(this, {
        stopDidUpdateEditingDateTime: false,
      })
    }
  }

  // componentDidMount() {
  // this._updateLimitedDateRange()
  // }

  // API can call from ref
  // getDateRange = () => {
  //   return this.state.dates
  // }

  // Internal function
  // async _onDateChange(key: 'begin' | 'end', newDate: Moment): Promise<void> {
  //   // console.log('_onDateChange key=' + key + ' newDate => ', newDate)
  //   const { onChangeDate, allowDateRangeLimitDays } = this.props
  //   const { dates, limit } = this.state

  //   const newDates: IDateRange = _.cloneDeep(dates)
  //   newDates[key] = newDate
  //   // log('_onDateChange newDates.end.diff(newDates.begin) => ', newDates.end.diff(newDates.begin))
  //   // compute allowDateRangeLimitDays
  //   if (allowDateRangeLimitDays && allowDateRangeLimitDays > 0 && newDates.end.diff(newDates.begin, 'd') > allowDateRangeLimitDays) {
  //     if (key === 'begin') {
  //       newDates.end = newDates.begin.clone().add(allowDateRangeLimitDays, 'd')
  //     } else {
  //       newDates.begin = newDates.end.clone().subtract(allowDateRangeLimitDays, 'd')
  //     }

  //     if (newDates.begin.isBefore(limit.begin.min as MomentInput, 'd')) {
  //       newDates.begin = limit.begin.min as Moment
  //     }

  //     if (newDates.end.isAfter(limit.end.max as MomentInput, 'd')) {
  //       newDates.end = limit.end.max as Moment
  //     }
  //   }

  //   //TODO:: เช็ดแค่ web ไปก่อน ตอนนี้ app เหมือนใช้ function นี้ไม่ได้ //ARTID
  //   if (p.op.isWeb()) {
  //     // เช็ค ถ้ามีการเลือกวันที่สิ้นสุด กับเริ่มต้นไม่สัมพันธ์กัน ปรับให้เป็นวันเดียวกัน
  //     if (newDates.begin.isAfter(newDates.end, 'ms')) {
  //       if (key === 'begin') {
  //         newDates.end = newDates.begin
  //       } else {
  //         newDates.begin = newDates.end
  //       }
  //     }
  //   }

  //   if (_.isFunction(onChangeDate)) {
  //     onChangeDate(newDates)
  //   }
  //   await setStatePromise(this, { dates: newDates })
  //   // log('_onDateChange dates => ', { dates: newDates })
  //   await this._updateLimitedDateRange(newDates)
  // }

  // {
  //   begin: { min: Moment|null, max: Moment },
  //   end: { min: Moment, max: Moment|null },
  // }
  // async _updateLimitedDateRange(currentDates?: IDateRange): Promise<void> {
  //   const { minDate, maxDate, allowOnlyDaysFromToday, selectedDates } = this.props
  //   // const { dates } = this.state
  //   let dates = currentDates ? currentDates : this.state.dates
  //   if (selectedDates) {
  //     dates = selectedDates
  //   }

  //   if (allowOnlyDaysFromToday && allowOnlyDaysFromToday > 0) {
  //     const today = moment()
  //     // log('_updateLimitedDateRange allowOnlyDaysFromToday => ' ,{
  //     //   limit: {
  //     //     begin: {min: today.clone().subtract({days: allowOnlyDaysFromToday}), max: dates.end},
  //     //     end: {min: dates.begin, max: today},
  //     //   },
  //     // })
  //     await setStatePromise(this, {
  //       limit: {
  //         begin: { min: today.clone().subtract({ days: allowOnlyDaysFromToday }), max: dates.end },
  //         end: { min: dates.begin, max: today },
  //       },
  //     })
  //     return
  //   }

  //   const limitBegin = minDate && moment.isMoment(minDate) && minDate.isSameOrBefore(this.CURRENT_DATE, 'ms') ? minDate : null
  //   const limitEnd = maxDate && moment.isMoment(maxDate) && maxDate.isSameOrAfter(this.CURRENT_DATE, 'ms') ? maxDate : null

  //   const newLimit: IDateLimitRange = {
  //     begin: { min: this.MIN_DATE, max: dates.end },
  //     end: { min: dates.begin, max: this.MAX_DATE },
  //   }

  //   if (limitBegin) {
  //     newLimit.begin.min = limitBegin
  //   }

  //   if (limitEnd) {
  //     newLimit.end.max = limitEnd
  //   }

  //   await setStatePromise(this, { limit: newLimit })
  //   // log('_updateLimitedDateRange normal limit => ', { limit: { begin, end } })
  // }
}
