import React, { RefObject } from 'react'
import {
  View,
  StyleSheet,
  Keyboard,
  ScrollView,
  ViewStyle,
  TextInput,
  NativeSyntheticEvent,
  TextInputFocusEventData,
  TextInputKeyPressEventData,
  LayoutChangeEvent,
} from 'react-native'
import { FlashList } from '@shopify/flash-list'

// import { connect } from 'react-redux'

import _ from 'lodash'
import * as DeepObjectDiff from 'deep-object-diff'
import KeyboardAccessoryView from 'xui/components/KeyboardAccessoryView'

// import { connect } from 'react-redux'
// import { bindActionCreators } from 'redux'
// import { NavigationRoute, NavigationState } from 'react-navigation'
// import { NavigationStackProp } from 'react-navigation-stack'

import * as util from 'x/utils/util'
import { S, COLORS } from 'x/config/styles'
import CONS from 'x/config/constants'

import p from 'x/config/platform-specific'

import {
  IEditProductRequest,
  IEditProductVariantRequest,
  IEditProductResponse,
  IProductVariantEditorViewProps,
  IProductVariantEditorViewState,
  IVariantListItemExtended,
  IProductDetailItem,
} from 'x/index'
// import api from 'x/utils/api'

import XText from 'xui/components/XText'
import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import XIconButton from 'xui/components/XIconButton'
import XIcon from 'xui/components/XIcon'
import XButtonWithIcon from 'xui/components/XButtonWithIcon'
import XButton from 'xui/components/XButton'
import XScrollView from 'xui/components/XScrollView'
import XImage from 'xui/components/XImage'
import XOverlay from '../../components/XOverlay'
import XCustomHeader from '../../components/XCustomHeader'
import XContainer from '../../components/XContainer'
import XInput from '../../components/XInput'
import XCheckButton from '../../components/XCheckButton'
import Segment from '../../components/Segment'
// import { isIphoneX } from '../../utils/iphone-x-helper'

const V_ATTR = CONS.PRODUCT_ATTR

// const VariantKeys = ['name', 'cost', 'price_1', 'price_2', 'price_3', 'price_4', 'price_5', 'stock_1', 'stock_2', 'stock_3', 'sku', 'upc']

interface IInputRefs {
  [index: number]: RefObject<TextInput>
}

const sss = StyleSheet.create({
  VISIBLE_ITEM: {},
  HIDDEN_ITEM: {
    display: 'none',
  },
})

const SEARCH_DELAY_MS = p.op.isWeb() ? 200 : 600

class ProductVariantEditorView extends React.Component<IProductVariantEditorViewProps, IProductVariantEditorViewState> {
  static displayName = 'ProductVariantEditorView'

  // flatListRef: RefObject<FlatList<any>>
  flatListRef: RefObject<FlashList<any>>

  scrollViewRef: RefObject<ScrollView>

  customEditInputValueOverlayRef: RefObject<CustomEditInputValueOverlay>

  inputRefs: IInputRefs

  doSearchByTextTimer?: NodeJS.Timer

  latestFocusIndex: number

  isNavigatingInput?: boolean

  isListScrolling?: boolean

  isInputFocusing?: boolean

  constructor(props) {
    super(props)

    this.state = {
      baseVariantValueMap: {},
      editingVariantValueMap: {},

      diffSummaryValues: {},
      isDiffSummaryOverlayVisible: false,

      baseVariantItems: [],
      baseVariantCount: 0,
      filteredVariantItems: [],
      filteredVariantCount: 0,

      variantVisibleByKeyMap: {},
      // vSize: 500,
      // disabledFilterCount: 0,
      lastUpdatedFlatList: '0',
      nameSearchText: '',
      // hideList: false,
      showKeyboardHelper: false,

      selectedProduct: null,
      computedVariantKeys: [],

      isDataDirty: false,
      isSubmitting: false,
      latestFocusIndex: -1,

      listItemSize: 100,
    }

    this.flatListRef = React.createRef()
    this.scrollViewRef = React.createRef()
    this.customEditInputValueOverlayRef = React.createRef()
    this.doSearchByTextTimer = null
    this.inputRefs = {}
    this.latestFocusIndex = 0
  }

  async componentDidMount() {
    await this.initSelectedProduct()
    await this.initVariantKeys()
    await this.initVariants()

    const params = util.getNavParams(this.props)
    console.log('ProductVariantEditorView:: componentDidMount params => ', params)
  }

  getHeaderTitleText = () => util.getNavParam(this.props, 'headerTitle', 'แก้ไขตัวเลือกสินค้า')

  getEditableVariantKeys = () => util.getNavParam(this.props, 'editableKeys', [])

  getSelectedProduct = (): IProductDetailItem => {
    const { selectedProduct } = this.props
    // const { selectedProduct, fetchedProductsByKey, navigation } = this.props
    // console.log('getSelectedProduct fetchedProductsByKey => ', fetchedProductsByKey)
    // const productId = util.getNavParam(this.props, 'productId')
    // if (!fetchedProductsByKey) {
    //   return null
    // }
    // return fetchedProductsByKey[productId]
    if (!selectedProduct) {
      return null
    }
    return selectedProduct.toJS()
  }

  initSelectedProduct = async () => {
    const sp = this.getSelectedProduct()
    await util.setStatePromise(this, { selectedProduct: sp })
  }

  initVariantKeys = async () => {
    await util.setStatePromise(this, { computedVariantKeys: this.computeDisplayVariantKeys() })
  }

  computeDisplayVariantKeys = () => {
    const { selectedProduct } = this.state
    if (!selectedProduct || !selectedProduct.id) {
      return []
    }
    const pgIds = selectedProduct.product_group_ids
    const whIds = selectedProduct.warehouse_ids

    const editableVariantKey = this.getEditableVariantKeys()

    // ให้แสดง variant name เสมอ
    const computedVariantKeys = [V_ATTR.NAME]

    for (let i = 0; i < editableVariantKey.length; i++) {
      const editableKey = editableVariantKey[i]
      if (editableKey === V_ATTR.NAME) {
        // ignored name จากด้านบนที่ใส่เข้าไปก่อนแล้ว
        // eslint-disable-next-line no-continue
        continue
      }
      switch (editableKey) {
        case V_ATTR.PRICE:
          for (const pgId of pgIds) {
            computedVariantKeys.push(`${V_ATTR.PRICE}_${pgId}`)
          }
          break
        case V_ATTR.QTY:
          for (const whId of whIds) {
            computedVariantKeys.push(`${V_ATTR.QTY}_${whId}`)
          }
          break

        default:
          computedVariantKeys.push(editableKey)
      }
    }

    return computedVariantKeys
  }

  initVariants = async () => {
    const { computedVariantKeys, selectedProduct } = this.state
    if (!selectedProduct) {
      return null
    }

    const variantVisibleByKeyMap = {}

    for (const vKey of computedVariantKeys) {
      variantVisibleByKeyMap[vKey] = true
    }

    const vItems: IVariantListItemExtended[] = []
    const initvariantValueMap = {}
    for (let i = 0; i < selectedProduct.variants.length; i++) {
      // sv = selectedVariant
      const sv = selectedProduct.variants[i]
      for (let j = 0; j < computedVariantKeys.length; j++) {
        const vKey = computedVariantKeys[j]
        const vID = sv.pp_id
        const getterKey = vKey.includes('_') ? vKey.split('_')[0] : vKey
        let value

        // console.log('initVariants vKey => ', vKey)
        // console.log('initVariants vID => ', vID)
        // console.log('initVariants getterKey => ', getterKey)

        switch (getterKey) {
          case V_ATTR.PRICE:
            const pgId = parseInt(vKey.split('_')[1])
            // console.log('initVariants V_ATTR.PRICE pgId => ', pgId)
            // console.log(
            //   'initVariants V_ATTR.PRICE sv.prices.find((vPgPrice) => vPgPrice.pg_id === pgId) => ',
            //   sv.prices.find((vPgPrice) => vPgPrice.pg_id === pgId)
            // )
            const foundPg = sv.prices.find((vPgPrice) => vPgPrice.pg_id === pgId)
            // value = foundPg && !_.isNil(foundPg.price) ? foundPg.price : undefined
            value = foundPg && !_.isNil(foundPg.price) ? foundPg.price : '0.00'
            break
          case V_ATTR.QTY:
            const whId = parseInt(vKey.split('_')[1])
            // try {
            const foundWh = sv.warehouses.find((vWh) => vWh.wh_id === whId)
            value = foundWh && !_.isNil(foundWh.qty) ? foundWh.qty : 0
            // } catch (err) {
            //   value = 0
            // }
            break
          default:
            value = sv[getterKey]
        }
        // const getterKey = vKey.includes('_') ? vKey.split('_')[0] : vKey
        // const value = sv[getterKey]

        const itemControlKey = this.generateItemControlKey(vID, vKey)
        initvariantValueMap[itemControlKey] = ''

        if (_.isNil(value)) {
          initvariantValueMap[itemControlKey] = ''
        } else {
          initvariantValueMap[itemControlKey] = value.toString()
        }

        const vItem = { ...sv, key: vKey, sectionIndex: i }

        let keyboardType
        if (_.includes([V_ATTR.QTY, V_ATTR.WEIGHT], getterKey)) {
          keyboardType = 'number-pad'
        } else if (_.includes([V_ATTR.PRICE, V_ATTR.COST], getterKey)) {
          keyboardType = 'decimal-pad'
        } else {
          keyboardType = 'default'
        }

        const isFirstVariantKey = j === 0
        let unitLabel = null
        if (getterKey === V_ATTR.WEIGHT) {
          unitLabel = 'กรัม'
        } else if (_.includes([V_ATTR.PRICE, V_ATTR.COST], getterKey)) {
          unitLabel = '฿'
        }

        vItems.push({
          ...vItem,
          label: this.computeVariantItemLabel(vItem),
          keyboardType,
          unitLabel,
          isFirstVariantKey,
          baseKey: getterKey,
          itemControlKey,
          variantIndex: i,
        })
      }
    }

    await this._createNewInputRefs(vItems)
    await util.setStatePromise(this, {
      variantVisibleByKeyMap,
      baseVariantItems: vItems,
      filteredVariantItems: vItems,
      baseVariantCount: selectedProduct.variants.length,
      filteredVariantCount: selectedProduct.variants.length,
      baseVariantValueMap: initvariantValueMap,
      editingVariantValueMap: initvariantValueMap,
      // disabledFilterCount: 0,
    })
  }

  computeVariantItemLabel = (item: Partial<IVariantListItemExtended>) => this.computeVariantLabelByComputedVariantKey(item.key)

  computeVariantLabelByComputedVariantKey = (itemControlKey: string): string => {
    const itemKeySplitted = itemControlKey.split('_')
    const itemKey = itemKeySplitted[0]
    let label

    // console.log('computeVariantLabelByComputedVariantKey itemKeySplitted => ', itemKeySplitted)
    // console.log('computeVariantLabelByComputedVariantKey itemKey => ', itemKey)
    switch (itemKey) {
      case V_ATTR.NAME:
        label = 'ชื่อตัวเลือก'
        break
      case V_ATTR.QTY:
        // label = 'คลัง'
        const whId = parseInt(itemKeySplitted[1])
        label = util.getWarehouseNameTextByWhId(whId)
        // console.log('computeVariantLabelByComputedVariantKey whId => ', whId)
        // console.log('computeVariantLabelByComputedVariantKey label => ', label)

        break
      case V_ATTR.PRICE:
        // label = 'ราคา'
        const pgId = parseInt(itemKeySplitted[1])
        label = util.getPriceNameTextByPgId(pgId)
        break
      case V_ATTR.COST:
        label = 'ต้นทุน'
        break
      case V_ATTR.WEIGHT:
        label = 'น้ำหนัก'
        break
      case V_ATTR.SKU:
        label = 'SKU'
        break
      case V_ATTR.UPC:
        label = 'UPC'
        break
      default:
        label = ''
    }
    return label
  }

  generateItemControlKey = (ppId, key) => `${ppId}_${key}`

  onSubVariantItemPressDecreaseValue = (itemControlKey: string, index: number) => {
    const currentValue = parseInt(this.state.editingVariantValueMap[itemControlKey])
    let newValue = currentValue - 1
    if (newValue < 0) {
      newValue = 0
    }
    const newValueStr = newValue.toString()
    this._handleChangeInput(itemControlKey, newValueStr)
  }

  onSubVariantItemPressIncreaseValue = (itemControlKey: string, index: number) => {
    const currentValue = parseInt(this.state.editingVariantValueMap[itemControlKey])
    const newValue = (currentValue + 1).toString()
    this._handleChangeInput(itemControlKey, newValue)
  }

  onSubVariantItemPressCustomValue = (itemControlKey: string, index: number) => {
    this.openCustomEditInputValueOverlay(itemControlKey, index)
  }

  // _onInputRef = (ref, index) => {
  //   this.inputRefs[index] = ref
  // }

  _keyExtractor = (item, index) => `${this.generateItemControlKey(item.pp_id, item.key)}_${index}`

  _setFocusLatestIndex = (index: number) => {
    // console.log('_setFocusLatestIndex this.latestFocusIndex => ', this.latestFocusIndex)
    // console.log('_setFocusLatestIndex index => ', index)
    // this.latestFocusIndex = index
    // this.setState({ showKeyboardHelper: true, latestFocusIndex: index })
    // if (this.state.latestFocusIndex !== index) {
    this.setState({ showKeyboardHelper: true, latestFocusIndex: index })
    // }
  }

  _resetFocusLatestIndex = (index: number) => {
    if (this.isNavigatingInput) {
      return
    }

    if (this.state.latestFocusIndex === index) {
      // this.latestFocusIndex = -1
      this.setState({ showKeyboardHelper: false, latestFocusIndex: -1 })
    }
    // console.log('_resetFocusLatestIndex this.latestFocusIndex => ', this.latestFocusIndex)
    // console.log('_resetFocusLatestIndex index => ', index)
    // if (this.latestFocusIndex === index) {
    //   this.latestFocusIndex = -1
    //   this.setState({ showKeyboardHelper: false, latestFocusIndex: -1 })
    // }
  }

  _handleChangeInput = (itemControlKey: string, newText: string) => {
    const editingVariantValueMap: { [key: string]: string } = { ...this.state.editingVariantValueMap }
    editingVariantValueMap[itemControlKey] = newText
    this.setState({ editingVariantValueMap, isDataDirty: true })
    // const newState = {}
    // newState[itemControlKey] = newText
    // this.setState(newState)
  }

  // _handlePressSubmit = () => {
  //   const { baseVariantValueMap, editingVariantValueMap } = this.state
  //   const d = DeepObjectDiff.diff(baseVariantValueMap, editingVariantValueMap)
  //   p.op.alert('Diff object', JSON.stringify(d))
  // }

  _handleNameSearchTextChanged = async (newText: string) => {
    // console.log('_handleNameSearchTextChanged newText => ', newText)
    await util.setStatePromise(this, { nameSearchText: newText })
    if (this.doSearchByTextTimer) {
      // console.log('_handleNameSearchTextChanged this.doSearchByTextTimer => ', this.doSearchByTextTimer)
      clearTimeout(this.doSearchByTextTimer)
    }
    // @ts-ignore
    this.doSearchByTextTimer = setTimeout(this._searchByText, SEARCH_DELAY_MS)
  }

  _handleNameSearchTextClear = () => this._handleNameSearchTextChanged('')

  _searchByText = async () => {
    // console.log('_searchByText')
    await this._filterVariantItemsByKey()
    await this._filterVariantItemsBySearchText()
    if (this.doSearchByTextTimer) {
      clearTimeout(this.doSearchByTextTimer)
    }
  }

  // _renderFilterButtons = () => {
  //   // const {  } = this.state

  //   return (
  //     <View style={[S.X_ROW, { minHeight: 100 }]}>
  //       {/*  */}
  //       {this.state.computedVariantKeys.map(this._renderFilterButton)}
  //     </View>
  //   )
  // }

  // _renderFilterButton = (key, index) => {
  //   const checked = this.state.variantVisibleByKeyMap[key]
  //   return (
  //     <XCheckButton
  //       key={key}
  //       label={key}
  //       checked={checked}
  //       onPress={async () => {
  //         // await util.setStatePromise(this, { hideList: true })
  //         // await util.delay(200)

  //         // const variantVisibleByKeyMap = { ...this.state.variantVisibleByKeyMap }
  //         // variantVisibleByKeyMap[key] = !checked
  //         // const disabledFilterCount = Object.keys(variantVisibleByKeyMap).reduce((prevDisabled, kk) => {
  //         //   return !variantVisibleByKeyMap[kk] ? prevDisabled + 1 : prevDisabled
  //         // }, 0)
  //         // this.setState({ variantVisibleByKeyMap, disabledFilterCount })

  //         // const variantVisibleByKeyMap = { ...this.state.variantVisibleByKeyMap }
  //         // variantVisibleByKeyMap[key] = !checked
  //         // const visibleVariantKeys = Object.keys(variantVisibleByKeyMap).filter((vvKey) => variantVisibleByKeyMap[vvKey] === true)
  //         // const filteredVariantItems = this.state.variantItems.filter((vi) => _.includes(visibleVariantKeys, vi.key))
  //         // await util.setStatePromise(this, { variantVisibleByKeyMap, filteredVariantItems })
  //         // await this._applyFilter()

  //         await this._filterVariantItemsByKey(key)
  //         await this._filterVariantItemsBySearchText()

  //         await this._applyFilter()
  //         // await util.delay(200)
  //         // await util.setStatePromise(this, { hideList: false })
  //       }}
  //     />
  //   )
  // }

  _createNewInputRefs = async (filteredVariantItems) => {
    this.inputRefs = {}
    filteredVariantItems.forEach((fvi, idx) => {
      this.inputRefs[idx] = React.createRef()
    })
    await util.delay(200)
  }

  _filterVariantItemsByKey = async (key?: string) => {
    let variantVisibleByKeyMap
    if (key) {
      const checked = this.state.variantVisibleByKeyMap[key]
      variantVisibleByKeyMap = { ...this.state.variantVisibleByKeyMap }
      variantVisibleByKeyMap[key] = !checked
    } else {
      variantVisibleByKeyMap = this.state.variantVisibleByKeyMap
    }
    const visibleVariantKeys = Object.keys(variantVisibleByKeyMap).filter((vvKey) => variantVisibleByKeyMap[vvKey] === true)
    const filteredVariantItems = this.state.baseVariantItems.filter((vi) => _.includes(visibleVariantKeys, vi.key))
    const filteredVariantCount = visibleVariantKeys.length

    await this._createNewInputRefs(filteredVariantItems)

    await util.setStatePromise(this, { variantVisibleByKeyMap, filteredVariantItems, filteredVariantCount })
  }

  _getInputRefBeginIndex = () => this._getNextInputIndex(-1)

  _getInputRefEndIndex = () => {
    const refCount = Object.keys(this.inputRefs).length
    return this._getPrevInputIndex(refCount)
  }

  // _onEndEditingInput = (index) => this._handlePressNextInput(index)
  _getPrevInputIndex = (currentIndex: number) => {
    for (let i = currentIndex - 1; i >= 0; i--) {
      const inputRef = this.inputRefs[i]
      // if (inputRef && inputRef.current && _.isFunction(inputRef.current._focusInput)) {
      if (inputRef && inputRef.current && _.isFunction(inputRef.current.focus)) {
        return i
      }
    }
    return -1
  }

  _getNextInputIndex = (currentIndex: number) => {
    const refCount = Object.keys(this.inputRefs).length
    for (let i = currentIndex + 1; i < refCount; i++) {
      const inputRef = this.inputRefs[i]
      if (inputRef && inputRef.current && _.isFunction(inputRef.current.focus)) {
        return i
      }
    }
    return -1
  }

  _handlePressPrevInput = async (index = this.latestFocusIndex) => {
    if (this.isNavigatingInput) {
      return
    }
    this.isNavigatingInput = true

    if (this.inputRefs) {
      // console.log('_handlePressPrevInput this.inputRefst => ', this.inputRefs)

      const prevIndex = this._getPrevInputIndex(index)
      const prevInputRef = this.inputRefs[prevIndex]
      if (prevInputRef) {
        // if (p.op.isIOS()) {
        //   this._dismissKeyboard()
        //   await util.delay(500)
        // }
        await this.scrollToFilteredVariantIndex(prevIndex)
        await util.delay(200)
        await this.focusToFilteredVariantInputIndex(prevIndex)
      }
    }

    this.isNavigatingInput = false
  }

  _handlePressNextInput = async (index = this.latestFocusIndex) => {
    if (this.isNavigatingInput) {
      return
    }
    this.isNavigatingInput = true

    if (this.inputRefs) {
      // console.log('_handlePressNextInput this.inputRefst => ', this.inputRefs)
      const nextIndex = this._getNextInputIndex(index)
      const nextInputRef = this.inputRefs[nextIndex]
      if (nextInputRef) {
        // if (p.op.isIOS()) {
        //   this._dismissKeyboard()
        //   await util.delay(500)
        // }
        await this.scrollToFilteredVariantIndex(nextIndex)
        await util.delay(200)
        await this.focusToFilteredVariantInputIndex(nextIndex)
      }
    }

    this.isNavigatingInput = false
  }

  scrollToFilteredVariantIndex = async (index: number) => {
    if (this.isListScrolling) {
      return
    }
    this.isListScrolling = true

    try {
      // console.log('scrollToFilteredVariantIndex  this.flatListRef.current => ', this.flatListRef.current)
      this.flatListRef.current.scrollToIndex({ animated: true, index, viewOffset: 64 })

      // this.inputRefs[index].current.measureLayout(
      //   // @ts-ignore
      //   this.scrollViewRef.current,
      //   (x, y, width, height) => {
      //     console.log(`measure ${index} =>`, x, y, width, height)
      //     console.log(`measure ${index} this.scrollViewRef.current =>`, this.scrollViewRef.current)
      //     const yOffset = p.op.isIOS() ? 10 : 20
      //     // if (this.scrollViewRef.current && this.scrollViewRef.current.scrollTo) {
      //     // this.scrollViewRef.current.scrollTo({ animated: true, y: Math.floor(y + yOffset) })
      //     this.scrollViewRef.current.scrollToOffset({ animated: true, offset: Math.floor(y + yOffset) })
      //     // }
      //   },
      //   (err) => {
      //     console.log('scrollToFilteredVariantIndex measureLayout err => ', err)
      //   }
      // )
    } catch (err) {
      // console.log('scrollToFilteredVariantIndex prevInputRef.current err => ', err)
    }

    await util.delay(100)
    this.isListScrolling = false
  }

  focusToFilteredVariantInputIndex = async (index: number) => {
    if (this.isInputFocusing) {
      return
    }
    this.isInputFocusing = true

    try {
      const focusInputRef = this.inputRefs[index]
      // console.log('focusToFilteredVariantInputIndex focusInputRef.current => ', focusInputRef.current)
      // focusInputRef.current._focusInput()
      focusInputRef.current.focus()
    } catch (err) {
      // console.log('focusToFilteredVariantInputIndex  err => ', err)
    }

    this.isInputFocusing = false
  }

  _filterVariantItemsBySearchText = async () => {
    const { nameSearchText = '', editingVariantValueMap, computedVariantKeys, baseVariantCount } = this.state
    // console.log('_filterVariantItemsBySearchText nameSearchText => ', nameSearchText)
    let filteredVariantItems
    let filteredVariantCount = baseVariantCount
    if (!nameSearchText || nameSearchText === '') {
      filteredVariantItems = this.state.filteredVariantItems
    } else {
      filteredVariantItems = [...this.state.filteredVariantItems]

      const firstNameIndex = computedVariantKeys.findIndex((kkk) => kkk === V_ATTR.NAME)
      const nameIndexOffset = computedVariantKeys.length
      const visibleVariantIdMap = {}

      const lowerCaseNameSearchText = nameSearchText.toLowerCase()
      for (let i = firstNameIndex; i < filteredVariantItems.length; i += nameIndexOffset) {
        const vID = filteredVariantItems[i].pp_id
        const itemControlKey = this.generateItemControlKey(vID, V_ATTR.NAME)
        const name = editingVariantValueMap[itemControlKey] || ''
        // console.log('_filterVariantItemsBySearchText name => ', name)

        const lowerCaseName = name.toLowerCase()

        // pO: ใช้ lower-case string ในการ search เสมอ
        if (lowerCaseName.includes(lowerCaseNameSearchText)) {
          // if (name.indexOf(nameSearchText) > -1) {
          // const visiblePrefixKey = this.generateItemControlKey(vID, '')
          visibleVariantIdMap[vID] = true
        }
      }

      // console.log('_filterVariantItemsBySearchText visibleVariantIdMap => ', name)

      filteredVariantItems = filteredVariantItems.filter((vi) => visibleVariantIdMap[vi.pp_id])
      // console.log('_filterVariantItem sBySearchText filtered filteredVariantItems => ', filteredVariantItems)
      filteredVariantCount = Object.keys(visibleVariantIdMap).length
    }
    await this._createNewInputRefs(filteredVariantItems)
    await util.setStatePromise(this, { filteredVariantItems, filteredVariantCount })
    await this._applyFilter()
  }

  _getLastUpdatedString = () => {
    const now = new Date()
    const hh = now.getHours()
    const mm = now.getMinutes()
    const ss = now.getSeconds()
    return `${hh}${mm}${ss}`
  }

  _applyFilter = async () => {
    await util.setStatePromise(this, { lastUpdatedFlatList: this._getLastUpdatedString() })
  }

  renderListItem = (info) => {
    const { selectedProduct } = this.state
    const { item, index } = info
    // renderListItem = (item: IVariantListItemExtended, index: number) => {
    const vKey = item.key
    const itemControlKey = this.generateItemControlKey(item.pp_id, vKey)

    let disabled = false

    const isPulledProduct = selectedProduct && selectedProduct.parent_id
    if (vKey === V_ATTR.COST && isPulledProduct) {
      disabled = true
    }
    return (
      <SubVariantItem
        // key={this._keyExtractor(item, index)}
        disabled={disabled}
        isSubmitting={this.state.isSubmitting}
        oldValue={this.state.baseVariantValueMap[itemControlKey]}
        value={this.state.editingVariantValueMap[itemControlKey]}
        item={item}
        index={index}
        itemControlKey={itemControlKey}
        onChangeTextInput={this._handleChangeInput}
        inputRef={this.inputRefs[index]}
        // onEndEditingInput={this._onEndEditingInput}
        onFocusInput={this._setFocusLatestIndex}
        onBlurInput={this._resetFocusLatestIndex}
        onPressDecreaseValue={this.onSubVariantItemPressDecreaseValue}
        onPressIncreaseValue={this.onSubVariantItemPressIncreaseValue}
        onPressCustomValue={this.onSubVariantItemPressCustomValue}
        onRequestPrevInput={this._handlePressPrevInput}
        onRequestNextInput={this._handlePressNextInput}
        // onInputRef={this._onInputRef}
        onLayout={index === 0 ? this._onFirstItemLayout : undefined}
      />
    )
  }

  _onFirstItemLayout = (event: LayoutChangeEvent) => {
    try {
      const currentSize = event.nativeEvent.layout.height
      if (currentSize && this.state.listItemSize !== currentSize) {
        this.setState({ listItemSize: currentSize })
      }
    } catch (error) {
      //
    }
  }

  renderEmptyItem = () => {
    const { nameSearchText, lastUpdatedFlatList } = this.state
    if (nameSearchText === '') {
      // By pKeng on 1 Mar 2021: MUST NOT return null here; otherwise all XInput becomes uneditable!!
      return <View key={lastUpdatedFlatList} />
    }
    return (
      <View key={lastUpdatedFlatList} style={[S.MIN_HEIGHT_40, S.PADDING_VERTICAL_12, S.ROW_CENTER, { paddingTop: 40 }]}>
        <XText variant='inactive' textAlign='center'>{`ไม่พบรายการที่มีชื่อสินค้า "${nameSearchText}"`}</XText>
      </View>
    )
  }

  renderVariantList = () => {
    const { filteredVariantItems = [], lastUpdatedFlatList, listItemSize } = this.state

    // return filteredVariantItems.map(this.renderListItem)

    return (
      <FlashList
        key={lastUpdatedFlatList}
        // @ts-ignore
        ref={this.flatListRef}
        // ref={this.scrollViewRef}
        keyboardShouldPersistTaps='never'
        extraData={this.state.editingVariantValueMap}
        // removeClippedSubviews
        ListEmptyComponent={this.renderEmptyItem()}
        data={filteredVariantItems}
        estimatedItemSize={listItemSize}
        // // TODO: Remove mock data
        // data={[
        //   ...filteredVariantItems,
        //   ...filteredVariantItems,
        //   ...filteredVariantItems,
        //   ...filteredVariantItems,
        //   ...filteredVariantItems,
        //   ...filteredVariantItems,
        //   ...filteredVariantItems,
        //   ...filteredVariantItems,
        //   ...filteredVariantItems,
        //   ...filteredVariantItems,
        //   ...filteredVariantItems,
        //   ...filteredVariantItems,
        //   ...filteredVariantItems,
        //   ...filteredVariantItems,
        //   ...filteredVariantItems,
        //   ...filteredVariantItems,
        //   ...filteredVariantItems,
        //   ...filteredVariantItems,
        // ]}
        keyExtractor={this._keyExtractor}
        renderItem={this.renderListItem}
      />
    )

    // return (
    //   <FlatList
    //     // style={S.FLEX}
    //     key={lastUpdatedFlatList}
    //     ref={this.flatListRef}
    //     removeClippedSubviews
    //     ListEmptyComponent={this.renderEmptyItem()}
    //     data={filteredVariantItems}
    //     // // TODO: Remove mock data
    //     // data={[
    //     //   ...filteredVariantItems,
    //     //   ...filteredVariantItems,
    //     //   ...filteredVariantItems,
    //     //   ...filteredVariantItems,
    //     //   ...filteredVariantItems,
    //     //   ...filteredVariantItems,
    //     //   ...filteredVariantItems,
    //     //   ...filteredVariantItems,
    //     //   ...filteredVariantItems,
    //     //   ...filteredVariantItems,
    //     //   ...filteredVariantItems,
    //     //   ...filteredVariantItems,
    //     //   ...filteredVariantItems,
    //     //   ...filteredVariantItems,
    //     //   ...filteredVariantItems,
    //     //   ...filteredVariantItems,
    //     //   ...filteredVariantItems,
    //     //   ...filteredVariantItems,
    //     // ]}
    //     keyExtractor={this._keyExtractor}
    //     renderItem={this.renderListItem}
    //   />
    // )
  }

  goBack = () => {
    util.navGoBack(this.props)
  }

  getHeaderLeftProps = () => {
    const { isDataDirty, isSubmitting = false } = this.state
    if (isDataDirty) {
      return { label: 'ตกลง', onPressItem: this.handlePressSubmitEditProductVariants, submitting: isSubmitting }
    }

    return { backIcon: true, onPressItem: this.goBack, submitting: isSubmitting }
  }

  getHeaderRightProps = () => {
    const { isDataDirty, isSubmitting = false } = this.state
    if (isDataDirty) {
      return { label: 'ยกเลิก', onPressItem: this.handlePressCancelEditProductVariants, submitting: isSubmitting }
    }

    return null
  }

  getSelectedStoreId = () => {
    const { navigation } = this.props
    return util.getNavParam(this.props, 'storeId')
  }

  getSelectedProductId = () => {
    const { navigation } = this.props
    return util.getNavParam(this.props, 'productId')
  }

  doSubmitToEditProductVariants = async () => {
    try {
      // const { submitEditProduct, loadProductToProductView } = this.props
      const { baseVariantValueMap = {}, editingVariantValueMap = {} } = this.state
      const diffValuesMap = DeepObjectDiff.diff(baseVariantValueMap, editingVariantValueMap)

      // filter not actual diff item (string diff but same value)
      Object.keys(diffValuesMap).forEach((dvck) => {
        const oldValueText = baseVariantValueMap[dvck]
        const newValueText = editingVariantValueMap[dvck]
        const isInt = util.isIntegerText(oldValueText)
        const isFloat = util.isFloatText(oldValueText)
        let oldValue
        let newValue
        if (isInt) {
          oldValue = parseInt(oldValueText)
          newValue = parseInt(newValueText)
        } else if (isFloat) {
          // float
          oldValue = parseFloat(oldValueText).toFixed(2)
          newValue = parseFloat(newValueText).toFixed(2)
        } else {
          oldValue = oldValueText
          newValue = newValueText
        }
        const isFakeDiff = oldValue === newValue
        if (isFakeDiff) {
          delete diffValuesMap[dvck]
        }
      })

      // console.log('diffValuesMap => ', diffValuesMap)
      if (_.isEmpty(diffValuesMap)) {
        await util.setStatePromise(this, { isDataDirty: false })
        return
      }

      const body: IEditProductRequest = {
        store_id: this.getSelectedStoreId(),
        product_id: this.getSelectedProductId(),
        variants: [],
      }

      const modifiedVariantsMap: {
        [vId: number]: IEditProductVariantRequest
      } = {}

      // Modifed diff editable variants
      const diffValueKeys = Object.keys(diffValuesMap)

      for (let dvkIndex = 0; dvkIndex < diffValueKeys.length; dvkIndex++) {
        const itemControlKey = diffValueKeys[dvkIndex]
        const splitedValues = itemControlKey.split('_')
        // [1] เป็น pp_id
        // [2] เป็น variant key
        // [3] เป็น variant key id เช่น price_112233 --> variant[someindex].prices[someindex].pg_id = 112233 (อาจจะมีหรือไม่มีก็ได้)
        if (splitedValues.length > 1) {
          const ppId = splitedValues[0]
          // const variantKey = splitedValues[1]
          const variantKey = splitedValues[1]
          const variantKeyId = splitedValues[2]
          if (!modifiedVariantsMap[ppId]) {
            modifiedVariantsMap[ppId] = {
              pp_id: ppId,
              action: 'EDIT',
            }
          }

          const newValue = diffValuesMap[itemControlKey]
          switch (variantKey) {
            case V_ATTR.QTY: {
              if (!util.isIntegerText(newValue)) {
                await this._handleOnValidationError(itemControlKey)
                return
              }

              if (!modifiedVariantsMap[ppId].warehouses) {
                modifiedVariantsMap[ppId].warehouses = []
              }

              modifiedVariantsMap[ppId].warehouses.push({ wh_id: variantKeyId, qty: newValue })
              break
            }
            case V_ATTR.PRICE: {
              if (!util.isFloatText(newValue)) {
                await this._handleOnValidationError(itemControlKey)
                return
              }

              if (!modifiedVariantsMap[ppId].prices) {
                modifiedVariantsMap[ppId].prices = []
              }

              modifiedVariantsMap[ppId].prices.push({ pg_id: variantKeyId, price: newValue })
              break
            }
            case V_ATTR.COST: {
              if (!util.isFloatText(newValue)) {
                await this._handleOnValidationError(itemControlKey)
                return
              }

              modifiedVariantsMap[ppId][variantKey] = newValue
              break
            }
            case V_ATTR.WEIGHT: {
              if (!util.isIntegerText(newValue)) {
                await this._handleOnValidationError(itemControlKey)
                return
              }

              modifiedVariantsMap[ppId][variantKey] = newValue
              break
            }
            default: {
              // eg. name / sku / upc
              modifiedVariantsMap[ppId][variantKey] = newValue
            }
          }
        }
      }
      // console.log('doSubmitToEditProductVariants modifiedVariantsMap => ', modifiedVariantsMap)

      const editedVariantKeys = Object.keys(modifiedVariantsMap)
      body.variants = editedVariantKeys.map((vPpId) => modifiedVariantsMap[vPpId])
      // console.log('doSubmitToEditProductVariants body.variants => ', body.variants)

      // @ts-ignore FIXME: @O type ไม่ถูกต้อง
      await util.setStatePromise(this, { preparedRequestBody: body })
      await this._openDiffSummary(diffValuesMap)

      // const apiOptions: IApiOptions = {
      //   axiosOptions: {
      //     retry: 0,
      //     timeout: 120000,
      //   },
      //   messages: {
      //     successMsg: 'แก้ไขคลังสำเร็จ',
      //     errorMsg: 'แก้ไขคลังล้มเหลว',
      //   },
      // }

      // const res = await api.patch<IEditProductRequest, IEditProductResponse>(api.PATCH_PRODUCT, body, apiOptions)

      // const isUserConfirm = await p.op.isUserConfirm('ยืนยันการเปลี่ยนแปลง', '')
      // if (!isUserConfirm) {
      //   return
      // }

      // const res = await new Promise<IEditProductResponse | null>((resolve) => {
      //   submitEditProduct({
      //     body,
      //     successCallback: resolve,
      //     failedCallback: () => null,
      //   })
      // })
      // // console.log('doSubmitToEditProductVariants res => ', res)

      // if (res && res.product) {
      //   // loadProductToProductView(res.product)
      //   await util.delay(200)
      //   this.goBack()
      // }
    } catch (err) {
      //
      // console.log('doSubmitToEditProductVariants err => ', err)
    }
  }

  _doSubmitEditProduct = async () => {
    if (this.state.isSubmitting) {
      return
    }
    await util.setStatePromise(this, { isSubmitting: true, isDiffSummaryOverlayVisible: false })

    const { preparedRequestBody = {} } = this.state
    if (!preparedRequestBody || _.isEmpty(preparedRequestBody)) {
      this.goBack()
      return
    }
    try {
      const res = await new Promise<IEditProductResponse | null>((resolve) => {
        this.props.submitEditProduct({
          body: preparedRequestBody,
          successCallback: resolve,
          failedCallback: () =>
            this.setState({
              isSubmitting: false,
            }),
        })
      })
      // console.log('doSubmitToEditProductVariants res => ', res)

      if (res && res.product) {
        // loadProductToProductView(res.product)
        await util.delay(200)
        this.goBack()
      }
    } catch (error) {
      //
    }
    await util.setStatePromise(this, { isSubmitting: false })
  }

  _handleOnValidationError = async (itemControlKey: string) => {
    // console.log('_handleOnValidationError itemControlKey => ', itemControlKey)
    const { filteredVariantItems, editingVariantValueMap } = this.state
    const errItemIndex = filteredVariantItems.findIndex((fvi) => fvi.itemControlKey === itemControlKey)
    const errItem = filteredVariantItems[errItemIndex]

    // console.log('_handleOnValidationError errItem => ', errItem)

    const { baseKey, label, pp_id } = errItem
    // const variantNameControlKey = `${pp_id}_${V_ATTR.NAME}`
    // const variantName = editingVariantValueMap[variantNameControlKey]
    const variantName = this.getVariantNameByControlKey(itemControlKey)

    const errTitleText = 'ระบุข้อมูลไม่ถูกต้อง'
    let errDescText = `กรุณาระบุค่า "${label}" ของตัวเลือก "${variantName}" `

    if (_.includes([V_ATTR.QTY, V_ATTR.WEIGHT], baseKey)) {
      errDescText += 'เป็นตัวเลขที่ไม่มีจุดทศนิยม'
    } else if (_.includes([V_ATTR.PRICE, V_ATTR.COST], baseKey)) {
      errDescText += 'เป็นตัวเลข'
    } else {
      errDescText += 'ให้ถูกต้อง'
    }

    await p.op.alertPromise(errTitleText, errDescText)
    await util.delay(500)
    await this.scrollToFilteredVariantIndex(errItemIndex)
    await util.delay(200)
    await this.focusToFilteredVariantInputIndex(errItemIndex)
  }

  handlePressSubmitEditProductVariants = async () => {
    if (!p.op.isWeb()) {
      Keyboard.dismiss()
    }
    // if (this.state.isSubmitting) {
    //   return
    // }
    // await util.setStatePromise(this, { isSubmitting: true })
    // handlePressSubmitEditProductVariants
    // p.op.alert('handlePressSubmitEditProductVariants')
    await this.doSubmitToEditProductVariants()

    // await util.setStatePromise(this, { isSubmitting: false })
  }

  handlePressCancelEditProductVariants = async () => {
    // const { baseVariantValueMap } = this.state
    // this.setState({ editingVariantValueMap: baseVariantValueMap, isDataDirty: false })

    const isUserConfirm = await p.op.isUserConfirm(
      'ยกเลิกการแก้ไข',
      'กรุณายืนยันว่าไม่ต้องการบันทึกค่าต่างๆ ที่ถูกเปลี่ยนแปลงไปแล้ว',
      'ยืนยัน',
      'แก้ไขต่อ'
    )
    if (!isUserConfirm) {
      return
    }

    this.goBack()
  }

  _dismissKeyboard = () => {
    const { latestFocusIndex = -1, showKeyboardHelper = false } = this.state
    const newState: Partial<IProductVariantEditorViewState> = {}

    if (latestFocusIndex !== -1) {
      newState.latestFocusIndex = -1
    }

    if (showKeyboardHelper) {
      newState.showKeyboardHelper = false
    }

    if (_.isEmpty(newState)) {
      this.setState(newState as IProductVariantEditorViewState)
    }

    this.latestFocusIndex = -1
    Keyboard.dismiss()
  }

  getVariantNameByControlKey = (itemControlKey: string) => {
    const keySplitted = itemControlKey.split('_')
    const ppId = keySplitted[0]
    const variantNameControlKey = `${ppId}_${V_ATTR.NAME}`
    const variantName = this.state.editingVariantValueMap[variantNameControlKey]
    return variantName
  }

  openCustomEditInputValueOverlay = (itemControlKey: string, index: number) => {
    const { baseVariantValueMap, editingVariantValueMap, filteredVariantItems } = this.state
    const oldValue = baseVariantValueMap[itemControlKey]
    const focusItem = filteredVariantItems[index]
    const ppId = focusItem.pp_id
    const { baseKey } = focusItem
    // const variantNameControlKey = `${ppId}_${V_ATTR.NAME}`
    // const variantName = editingVariantValueMap[variantNameControlKey]
    const variantName = this.getVariantNameByControlKey(itemControlKey) || ''
    let displayName = variantName
    if (baseKey !== V_ATTR.NAME) {
      displayName = focusItem.label ? `${focusItem.label} (${variantName})` : variantName
    }
    this.customEditInputValueOverlayRef.current.open({
      itemControlKey,
      oldValue,
      focusItemDisplayName: displayName,
      // FIXME: ตอนนี้มีแค่ qty อย่างเดียว จึงยังไม่ต้อง compute condition
      inputType: 'integer',
    })
  }

  openCustomBatchEditInputValueOverlay = () => {
    const { baseVariantValueMap, baseVariantItems, filteredVariantItems, computedVariantKeys } = this.state

    // FIXME: ถ้า name เกิด edit ได้ขึ้นมาตรงนี้ต้องเปลี่ยนวิธีคิด
    const editableKeysWithoutName = computedVariantKeys.filter((cvk) => cvk !== V_ATTR.NAME)
    // const editablefilteredVariantItems = filteredVariantItems.filter(fvi => fvi.baseKey !== V_ATTR.NAME)
    let inputType
    try {
      // FIXME: นี่เป็น quick logic ถ้ามีมากกว่าโหมด คลัง / ต้นทุน+ราคา ต้องมาหาวิธีคำนวณกันใหม่
      const firstBaseKey = editableKeysWithoutName[0].split('_')[0]
      // console.log('openCustomBatchEditInputValueOverlay editableKeysWithoutName[0] => ', editableKeysWithoutName[0])
      // console.log('openCustomBatchEditInputValueOverlay firstBaseKey => ', firstBaseKey)
      if (_.includes([V_ATTR.COST, V_ATTR.PRICE], firstBaseKey)) {
        inputType = 'money'
      } else {
        inputType = 'integer'
      }
    } catch (error) {
      inputType = 'integer'
      // return
    }

    const editableDisplayNames = editableKeysWithoutName.map((ek) => this.computeVariantLabelByComputedVariantKey(ek))

    this.customEditInputValueOverlayRef.current.openBatch({
      oldValueMap: baseVariantValueMap,
      editableVariantKeys: editableKeysWithoutName,
      editableDisplayNames,
      baseVariantItems,
      filteredVariantItems,
      inputType,
    })
  }

  closeCustomEditInputValueOverlay = () => this.customEditInputValueOverlayRef.current.close()

  onSubmitCustomEditInputValueOverlay = (itemControlKey: string, computedValueStr: string) => {
    this._handleChangeInput(itemControlKey, computedValueStr)
  }

  onSubmitBatchCustomEditInputValueOverlay = (newVariantValueMap) => {
    // console.log('onSubmitBatchCustomEditInputValueOverlay newVariantValueMap => ', newVariantValueMap)
    const { baseVariantValueMap } = this.state
    this.setState({
      editingVariantValueMap: { ...baseVariantValueMap, ...newVariantValueMap },
      isDataDirty: true,
    })
  }

  renderCustomEditInputValueOverlay = () => (
    <CustomEditInputValueOverlay
      ref={this.customEditInputValueOverlayRef}
      onSubmit={this.onSubmitCustomEditInputValueOverlay}
      onSubmitBatch={this.onSubmitBatchCustomEditInputValueOverlay}
      //
    />
  )

  renderStickyKeyboardHelper = () => {
    // FIXME: ซ่อนออกไปเพราะ
    // 1. หาวิธี reproduce bug ไม่ได้
    // 2. รอดู feedback จากลูกค้าว่าจะมีร้องขอเพื่อต้องการจะใช้งาน feature นี้จริงหรือไม่
    // ref: https://app.clickup.com/t/3bv385k
    return null

    // if (p.op.isWeb() || p.op.isIOS()) {
    //   return null
    // }
    if (p.op.isWeb()) {
      return null
    }

    const { showKeyboardHelper = false, latestFocusIndex = -1 } = this.state
    // if (!showKeyboardHelper) {
    //   return null
    // }

    const isBeginIndex = latestFocusIndex === this._getInputRefBeginIndex()
    const isEndIndex = latestFocusIndex === this._getInputRefEndIndex()

    return (
      <KeyboardAccessoryView
        // key={`KeyboardAccessoryView_PVE_${latestFocusIndex}`}
        animateOn='none'
        hideBorder
        // androidAdjustResize={p.op.isAndroid()}
        ignoreKeyboardHeight
        // avoidKeyboard={true}
        // style={{ marginBottom: p.op.isIOS() && isIphoneX() ? -22 : 0 }}
        // inSafeAreaView={false}
        // inSafeAreaView={p.op.isAndroid()}
        inSafeAreaView
        // heightProperty={50}
        // bumperHeight={100}
        // avoidKeyboard={true}
        // bumperHeight={5}
        // style={{ height: 48 }}
        bumperHeight={15}
        avoidKeyboard>
        {({ isKeyboardVisible }) => {
          if (!isKeyboardVisible) {
            return null
          }
          // if (!isKeyboardVisible) {
          //   return null
          // }
          return (
            <HStack
              _light={{ bg: 'gray.50' }}
              borderTopWidth='1'
              borderTopColor='gray.300'
              w='full'
              // minH='10'
              h='12'
              px='3'
              // py='2'
              space='2'
              alignItems='center'>
              <HStack flex={1} alignItems='center'>
                <XIconButton name='close' type='Ionicons' onPress={this._dismissKeyboard} />
              </HStack>
              {latestFocusIndex >= 0 ? (
                <HStack minW='80px' alignItems='center' justifyContent='flex-end' space='2'>
                  <XIconButton
                    key={`Prev_${latestFocusIndex}`}
                    disabled={isBeginIndex}
                    isDisabled={isBeginIndex}
                    name='chevron-back-outline'
                    type='Ionicons'
                    onPress={() => this._handlePressPrevInput(latestFocusIndex)}>
                    {/* <XText style={[S.TEXT_PRIMARY, S.TEXT_BOLD]}>{'ก่อนหน้า'}</XText> */}
                  </XIconButton>
                  <XIconButton
                    key={`Next_${latestFocusIndex}`}
                    disabled={isEndIndex}
                    isDisabled={isEndIndex}
                    name='chevron-forward-outline'
                    type='Ionicons'
                    onPress={() => this._handlePressNextInput(latestFocusIndex)}>
                    {/* <XText style={[S.TEXT_PRIMARY, S.TEXT_BOLD]}>{'ถัดไป'}</XText> */}
                  </XIconButton>
                </HStack>
              ) : null}
            </HStack>
          )
        }}
      </KeyboardAccessoryView>
    )
  }

  renderHeader = () => (
    <>
      <XCustomHeader
        title={this.getHeaderTitleText()}
        headerLeftProps={this.getHeaderLeftProps()}
        headerRightProps={this.getHeaderRightProps()}
        //
        headerStyle={{ borderBottomWidth: 0 }}
      />
      <VStack
        w='full'
        // py='1'
        pb='1'
        //  style={[S.PADDING_HORIZONTAL_12, S.BG_WHITE, { borderBottomWidth: 0.5, borderBottomColor: COLORS.TEXT_INACTIVE }]}
      >
        {this.renderPulledProductInfo()}
        {this.renderSearchBar()}
        {this.renderControlBar()}
        {this.renderCustomEditInputValueOverlay()}
      </VStack>
    </>
  )

  renderControlBar = () => (
    <HStack w='full' px='2' py='1.5' alignItems='center'>
      {this.renderControlBarSearchInfoText()}
      {this.renderControlBarCustomBatchOperationButton()}
    </HStack>
  )

  renderControlBarSearchInfoText = () => {
    const { nameSearchText, filteredVariantItems, baseVariantCount, filteredVariantCount } = this.state
    let infoText = ''
    if (!nameSearchText || nameSearchText === '') {
      infoText = `ทั้งหมด ${baseVariantCount} ตัวเลือกสินค้า`
    } else {
      infoText = `พบ ${filteredVariantCount} จาก ${baseVariantCount} ตัวเลือกสินค้า ที่มีชื่อ "${nameSearchText}"`
    }

    return (
      <XText flex={1} numberOfLines={2} variant='inactive'>
        {infoText}
      </XText>
    )
  }

  renderControlBarCustomBatchOperationButton = () => {
    const { isSubmitting = false } = this.state
    return (
      <XButtonWithIcon
        w='120px'
        disabled={isSubmitting}
        variant='outline'
        leftIcon={<XIcon name='playlist-edit' family='MaterialCommunityIcons' />}
        onPress={this.openCustomBatchEditInputValueOverlay}>
        แก้เป็นชุด
      </XButtonWithIcon>
    )
  }

  _onFocusSearchXInput = () => {
    const { latestFocusIndex = -1 } = this.state
    if (latestFocusIndex !== -1) {
      this.setState({ latestFocusIndex: -1 })
    }
  }

  renderSearchBar = () => {
    const { nameSearchText = '', isSubmitting = false } = this.state

    if (p.op.isWeb()) {
      return (
        <HStack w='full' px='2' py='1.5'>
          <XInput
            w='full'
            isDisabled={isSubmitting}
            onChangeText={this._handleNameSearchTextChanged}
            placeholder='กรองด้วยชื่อตัวเลือกสินค้า...'
            onFocus={this._onFocusSearchXInput}
            returnKeyType='search'
            value={nameSearchText}
            leftElement={<XIcon m='1' name='search' variant='inactive' />}
            rightElement={this.renderClearSearchButton()}
          />
        </HStack>
      )
    }

    return (
      <HStack w='full' px='2' py='1'>
        <HStack
          w='full'
          _light={{
            bg: 'primary.100',
          }}
          _dark={{
            bg: 'primary.200',
          }}>
          <XIcon m='1' name='search' variant='inactive' />
          <XInput
            flex={1}
            isDisabled={isSubmitting}
            onChangeText={this._handleNameSearchTextChanged}
            placeholder='กรองด้วยชื่อตัวเลือกสินค้า...'
            onFocus={this._onFocusSearchXInput}
            returnKeyType='search'
            value={nameSearchText}
          />
          {this.renderClearSearchButton()}
        </HStack>
      </HStack>
    )
  }

  renderClearSearchButton = () => {
    const { nameSearchText = '' } = this.state
    if (!nameSearchText || nameSearchText === '') {
      return null
    }

    return <XIconButton name='close-circle' colorScheme='muted' onPress={this._handleNameSearchTextClear} />
  }

  _closeDiffSummary = async () => {
    // @ts-ignore FIXME: @O type ไม่ถูกต้อง
    await util.setStatePromise(this, { isDiffSummaryOverlayVisible: false, diffSummaryValues: {}, preparedRequestBody: {} })
  }

  _openDiffSummary = async (diffSummaryValues) => {
    await util.setStatePromise(this, { isDiffSummaryOverlayVisible: true, diffSummaryValues })
  }

  renderDiffSummaryContentDetial = () => {
    const { diffSummaryValues = {} } = this.state
    const diffItemCount = Object.keys(diffSummaryValues).length
    const diffSummaryValuesbySections = {}
    Object.keys(diffSummaryValues).forEach((vck) => {
      const splitedVck = vck.split('_')
      const ppId = splitedVck[0]
      if (!diffSummaryValuesbySections[ppId] || !_.isArray(diffSummaryValuesbySections[ppId])) {
        diffSummaryValuesbySections[ppId] = []
      }
      diffSummaryValuesbySections[ppId].push(vck)
    })

    const sectionsKeys = Object.keys(diffSummaryValuesbySections)

    return (
      <VStack w='full' p='2'>
        <HStack minH='24px' mt='1' alignItems='center'>
          <XText w='90px' textAlign='center' variant='inactive' bold>{`${diffItemCount} รายการ`}</XText>
          <XText flex={1} textAlign='center' variant='inactive' bold>
            ค่าเดิม
          </XText>
          <XText w='74px' textAlign='center' variant='inactive' bold>
            ค่าใหม่
          </XText>
        </HStack>
        <XScrollView minH='100px' maxH='400px'>
          {sectionsKeys.map((sectionKey, sIdx) => {
            const vckArray = diffSummaryValuesbySections[sectionKey]
            const isEven = sIdx % 2 === 0
            const bgStyle = isEven ? S.BG_LIGHT_GREY : S.BG_WHITE
            const firstVck = vckArray[0]
            const variantName = this.getVariantNameByControlKey(firstVck)
            return (
              <VStack key={sIdx.toString()} w='full' py='2' px='1' style={bgStyle}>
                <HStack w='full' py='1' flexWrap='wrap'>
                  <XText variant='inactive' bold>
                    {variantName}
                  </XText>
                </HStack>
                {vckArray.map((vck, iIdx) => {
                  const { baseVariantValueMap, editingVariantValueMap } = this.state
                  const oldValueText = baseVariantValueMap[vck]
                  let newValueText = editingVariantValueMap[vck]
                  const splitedVck = vck.split('_')
                  const computedVariantKey = splitedVck.length > 2 ? `${splitedVck[1]}_${splitedVck[2]}` : splitedVck[1]
                  let labelText = this.computeVariantLabelByComputedVariantKey(computedVariantKey)

                  if (_.isNil(labelText) || labelText === '') {
                    labelText = 'ไม่ระบุชื่อ'
                  }

                  const isInt = util.isIntegerText(oldValueText)
                  // const isFloat = util.isFloatText(oldValueText)
                  let changedValue
                  let oldValue
                  let newValue
                  if (isInt) {
                    oldValue = parseInt(oldValueText)
                    newValue = parseInt(newValueText)
                  } else {
                    // float
                    oldValue = parseFloat(oldValueText).toFixed(2)
                    newValue = parseFloat(newValueText).toFixed(2)
                  }
                  newValueText = newValue.toString()
                  changedValue = newValue - oldValue

                  let changedValueText
                  // let textColorStyle
                  let textVariant
                  const changedValueAbs = Math.abs(changedValue)
                  if (changedValue < 0) {
                    changedValueText = ` -${changedValueAbs}`
                    // textColorStyle = S.TEXT_DANGER
                    textVariant = 'danger'
                  } else if (changedValue > 0) {
                    changedValueText = ` +${changedValueAbs}`
                    // textColorStyle = S.TEXT_SUCCESS
                    textVariant = 'success'
                  } else {
                    changedValueText = ''
                    // textColorStyle = {}
                    textVariant = 'inactive'
                  }

                  if (!changedValueText || changedValueText === '') {
                    return null
                  }

                  return (
                    <HStack key={iIdx.toString()} w='full'>
                      <XText w='90px' pl='1' variant='inactive' numberOfLines={2}>
                        {labelText}
                      </XText>

                      <HStack flex={1} justifyContent='center'>
                        {/* <XText style={[S.TEXT_INACTIVE, S.TEXT_STRIKETHROUGH]}>{oldValueText}</XText> */}
                        {/* <XText variant='inactive'>{'( '}</XText> */}
                        <XText variant='inactive'>{oldValueText}</XText>
                        <XText variant={textVariant} fontSize='xs' bold>
                          {changedValueText}
                        </XText>
                        {/* <XText variant='inactive'>{')'}</XText> */}
                      </HStack>

                      {/* <XText style={[S.TEXT_INACTIVE, S.TEXT_STRIKETHROUGH]}>{oldValueText}</XText> */}

                      <XText w='74px' textAlign='right' variant='primary' bold>
                        {newValueText}
                      </XText>
                    </HStack>
                  )
                })}
              </VStack>
            )
          })}
        </XScrollView>
      </VStack>
    )
  }

  renderDiffSummaryOverlay = () => {
    const { isSubmitting, isDiffSummaryOverlayVisible = false, diffSummaryValues = {} } = this.state
    if (!isDiffSummaryOverlayVisible || !diffSummaryValues || _.isEmpty(diffSummaryValues)) {
      return null
    }

    const injectContainerPaddingStyle = p.op.isWeb() ? S.PADDING_4 : {}

    return (
      <XOverlay
        visible={isDiffSummaryOverlayVisible}
        onRequestClose={this._closeDiffSummary}
        // contentStyle={{ minHeight: 200, maxHeight: p.op.isWeb() ? 700 : 600, width: p.op.isWeb() ? 340 : 318 }}
      >
        <VStack w='full'>
          {/* <View style={[S.WIDTH_FULL, S.BG_WHITE, S.MARGIN_VERTICAL_6, injectContainerPaddingStyle]}> */}
          <XCustomHeader
            title='ยืนยันแก้ไขตัวเลือกสินค้าดังนี้'
            headerRightProps={{ closeIcon: true, onPressItem: this._closeDiffSummary }}
          />
          {/* <View style={[S.HEIGHT_24, S.ROW_MIDDLE_BETWEEN, { marginTop: 4 }]}>
            <View style={{ width: 80 }} />
            <View style={[S.ROW_CENTER, S.PADDING_HORIZONTAL_4]}>
              <XText variant='inactive' bold>{'ค่าเดิม'}</XText>
            </View>
            <View style={[S.ROW_CENTER, S.PADDING_HORIZONTAL_4, { width: 74 }]}>
              <XText variant='inactive' bold>{'ค่าใหม่'}</XText>
            </View>
          </View> */}
          {this.renderDiffSummaryContentDetial()}
          {/* <ScrollView style={S.PADDING_6}> */}
          {/* {this.renderDiffSummaryContentDetial()} */}
          {/* {Object.keys(diffSummaryValues).map((vck, idx) => {
              const { baseVariantValueMap, editingVariantValueMap } = this.state
              const variantName = this.getVariantNameByControlKey(vck)
              const oldValueText = baseVariantValueMap[vck]
              const newValueText = editingVariantValueMap[vck]
              const splitedVck = vck.split('_')
              const computedVariantKey = splitedVck.length > 2 ? `${splitedVck[1]}_${splitedVck[2]}` : splitedVck[1]
              const labelText = this.computeVariantLabelByComputedVariantKey(computedVariantKey)
              return (
                <View key={idx.toString()} style={[S.ROW_MIDDLE_BETWEEN]}>
                  <View>
                    <XText style={[S.TEXT_ACTIVE, S.TEXT_BOLD]}>{variantName}</XText>
                  </View>
                  <View>
                    <XText style={[S.TEXT_ACTIVE, S.TEXT_BOLD]}>{labelText}</XText>
                  </View>
                  <View style={S.ROW_MIDDLE_END}>
                    <XText style={[S.TEXT_INACTIVE, S.TEXT_STRIKETHROUGH]}>{oldValueText}</XText>
                    <XText style={[S.TEXT_PRIMARY, S.TEXT_BOLD]}>{newValueText}</XText>
                  </View>
                </View>
              )
            })} */}
          {/* <XText bold>{JSON.stringify(diffSummaryValues)}</XText> */}
          {/* </ScrollView> */}
          <HStack w='full' p='1' space='2'>
            <XButton variant='outline' w='54px' onPress={this._closeDiffSummary}>
              ปิด
            </XButton>
            <XButton flex={1} disabled={isSubmitting} onPress={this._doSubmitEditProduct}>
              ยืนยันแก้ไข
            </XButton>
          </HStack>
          {/* </View> */}
        </VStack>
      </XOverlay>
    )
  }

  renderPulledProductInfo = () => {
    const { selectedProduct } = this.state

    if (!selectedProduct || !selectedProduct.parent_id || !selectedProduct.parent_store_name) {
      return null
    }

    return (
      <HStack
        w='full'
        px='2'
        py='1.5'
        space='1.5'
        alignItems='center'
        justifyContent='center'
        bg='gray.100'
        borderTopWidth='1'
        borderTopColor='gray.400'
        borderBottomWidth='1'
        borderBottomColor='gray.400'>
        <XImage source={require('xui/img/tb/tb_seller0.png')} width={22} height={22} />
        <XText variant='inactive' numberOfLines={1}>
          {`สินค้าจาก ${selectedProduct.parent_store_name}`}
        </XText>
      </HStack>
    )
  }

  renderMain = () => (
    // By pKeng 25 Feb 2021: Need to use Native-base's Container/Content here in order to
    // have the correct Keyboard avoiding behavior  on real iOS/Android
    // Todo from pKeng to pO: Handle Container/Content for Web
    <XContainer>
      {this.renderHeader()}
      {/* <XContent
        ref={this.scrollViewRef}
        // // @ts-ignore extraScrollHeight work on iOS only
        // extraScrollHeight={164}
        // @ts-ignore
        // keyboardShouldPersistTaps={CONS.KEYBOARD_PERSIST_TAB_MODE}
        // keyboardDismissMode='on-drag'
        // padder={false}
        // enableOnAndroid
        // style={{ flex: 1 }}
      > */}
      <VStack flex={1} w='full'>
        {this.renderVariantList()}
        {/* {p.op.isIOS() ? <Box w='full' h='320px' /> : null} */}
      </VStack>
      {/* </XContent> */}
      {/* Comment out for now as it doesn't work on real iOS/Android devices */}
      {this.renderStickyKeyboardHelper()}
      {this.renderDiffSummaryOverlay()}
    </XContainer>
  )

  render() {
    return this.renderMain()
  }
}

interface ISubVariantItemProps {
  // visible?: boolean
  // visibleMap?: { [key: string]: boolean }
  disabled?: boolean
  item: IVariantListItemExtended
  index: number
  itemControlKey: string
  onChangeTextInput: any
  oldValue?: string
  value: string
  inputRef?: RefObject<TextInput>

  isSubmitting?: boolean

  onPressDecreaseValue?: (itemControlKey: string, index: number) => void | Promise<void>
  onPressIncreaseValue?: (itemControlKey: string, index: number) => void | Promise<void>
  onPressCustomValue?: (itemControlKey: string, index: number) => void | Promise<void>

  // onInputRef?: (inputRef?: RefObject<XInput>) => void
  onEndEditingInput?: (index: number) => void | Promise<void>
  onFocusInput?: (index: number) => void | Promise<void>
  onBlurInput?: (index: number) => void | Promise<void>
  onInputRef?: (ref, index) => void

  onRequestPrevInput?: (index: number) => void
  onRequestNextInput?: (index: number) => void

  onLayout?: (event: LayoutChangeEvent) => void
}

class SubVariantItem extends React.Component<ISubVariantItemProps> {
  _handleChangeText = (newText) => {
    const { onChangeTextInput, itemControlKey } = this.props
    onChangeTextInput(itemControlKey, newText)
  }

  _onEndEditing = () => {
    const { index, onEndEditingInput } = this.props
    if (onEndEditingInput) {
      onEndEditingInput(index)
    }
  }

  _onFocus = (evt: NativeSyntheticEvent<TextInputFocusEventData>) => {
    const { index, value, onFocusInput, inputRef } = this.props

    // try {
    //   const valueLength = value && value.length ? value.length : 0
    //   if (valueLength) {
    //     let selection: any = {}
    //     selection.start = valueLength
    //     if (p.op.isIOS()) {
    //       selection.end = valueLength
    //     }
    //     inputRef.current.setNativeProps({ selection })
    //   }
    // } catch (error) {
    //   //
    // }

    if (onFocusInput) {
      onFocusInput(index)
    }
  }

  _onBlur = () => {
    const { index, onBlurInput } = this.props
    if (onBlurInput) {
      onBlurInput(index)
    }
  }

  // _onInputRef = (ref) => {
  //   const { onInputRef, index } = this.props
  //   if (onInputRef) {
  //     onInputRef(ref, index)
  //   }
  // }

  _getBgColorStyle = (): ViewStyle => {
    const { item } = this.props
    const isEven = item.sectionIndex % 2 === 0
    // @ts-ignore
    const modifiedStyle: ViewStyle = StyleSheet.flatten([
      isEven ? S.BG_WHITE : S.BG_LIGHT_GREY,
      item.isFirstVariantKey ? { borderTopWidth: 0.5, borderTopColor: COLORS.TEXT_INACTIVE } : {},
    ])
    return modifiedStyle
  }

  _handlePressDecreaseValue = () => {
    const { itemControlKey, index, onPressDecreaseValue } = this.props
    if (onPressDecreaseValue) {
      onPressDecreaseValue(itemControlKey, index)
    }
  }

  _handlePressIncreaseValue = () => {
    const { itemControlKey, index, onPressIncreaseValue } = this.props
    if (onPressIncreaseValue) {
      onPressIncreaseValue(itemControlKey, index)
    }
  }

  _handlePressCustomValue = () => {
    const { itemControlKey, index, onPressCustomValue } = this.props
    if (onPressCustomValue) {
      onPressCustomValue(itemControlKey, index)
    }
  }

  _renderLeftColumn = () => {
    const { item } = this.props
    const hasNoLabel = _.isNil(item.label) || item.label === ''
    return (
      <VStack _web={{ w: '100px' }} w='140px' flexWrap='wrap' justifyContent='center'>
        <XText w='full' variant='inactive' bold={!hasNoLabel}>
          {hasNoLabel ? 'ไม่ระบุชื่อ' : item.label}
        </XText>
        {this._renderOldValueText()}
      </VStack>
    )
  }

  _renderOldValueText = () => {
    const { oldValue = null, value } = this.props
    return (
      <HStack w='full' alignItems='center' justifyContent='space-between'>
        <XText fontSize='xs' variant='inactive'>
          {oldValue ? `(เดิม ${oldValue})` : ''}
        </XText>
        {this._renderChangedValueText()}
      </HStack>
    )
  }

  _renderChangedValueText = () => {
    const { oldValue = null, value = '' } = this.props
    if (!oldValue || !value || oldValue === value) {
      return null
    }
    const isInt = util.isIntegerText(value)
    const isFloat = util.isFloatText(value)
    if (!isInt || !isFloat) {
      return null
    }

    let changedValue
    if (isInt) {
      changedValue = parseInt(value) - parseInt(oldValue)
    } else {
      // float
      changedValue = parseFloat(value) - parseFloat(oldValue)
    }

    let changedValueText
    // let textColorStyle
    let textVariant
    const changedValueAbs = Math.abs(changedValue)
    if (changedValue < 0) {
      changedValueText = `-${changedValueAbs}`
      // textColorStyle = S.TEXT_DANGER
      textVariant = 'danger'
    } else if (changedValue > 0) {
      changedValueText = `+${changedValueAbs}`
      // textColorStyle = S.TEXT_SUCCESS
      textVariant = 'success'
    } else {
      changedValueText = ''
      // textColorStyle = {}
      textVariant = 'inactive'
    }

    return (
      <XText mx='1' variant={textVariant} fontSize='xs' bold>
        {changedValueText}
      </XText>
    )
  }

  _renderRightColumn = () => {
    const { item, value, inputRef } = this.props
    return (
      <HStack
        flex={1}
        // w='220px'
        // _web={{ w: '200px' }}
        alignItems='center'
        justifyContent='flex-end'>
        {this._renderMinusButton()}
        {this._renderInput()}
        {this._renderPlusButton()}
        {this._renderCustomOperationButton()}
      </HStack>
    )
  }

  _onKeyPress = (evt: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
    // console.log('_renderInput onKeyPress evt => ', evt)
    // console.log('_renderInput onKeyPress evt.nativeEvent => ', evt.nativeEvent)
    try {
      // @ts-ignore
      if ((evt.nativeEvent.code === 'Tab' || evt.nativeEvent.code === 'Enter') && evt.nativeEvent.type === 'keydown') {
        const { onRequestNextInput, index } = this.props
        onRequestNextInput(index)
      }
    } catch (err) {
      console.log('_onTabKeyPress err => ', err)
    }
  }

  // _onEndEditing = (evt: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
  // _onEndEditing = () => {
  //   try {
  //     // @ts-ignore
  //     const { onEndEditingInput, onRequestNextInput, index } = this.props

  //     if (onEndEditingInput) {
  //       onEndEditingInput(index)
  //     }

  //     onRequestNextInput(index)
  //   } catch (err) {
  //     console.log('_onEndEditing err => ', err)
  //   }
  // }

  _renderInput = () => {
    const { disabled, item, value, inputRef, isSubmitting = false } = this.props
    return (
      <XInput
        flex={1}
        maxW='112px'
        _web={{ maxW: '92px' }}
        disabled={isSubmitting || disabled}
        isDisabled={isSubmitting || disabled}
        ref={inputRef}
        // onRef={this._onInputRef}
        // isNumber={true}
        // isMoney={false}
        // isInteger={true}
        // keyboardType={'number-pad'}
        // keyboardType={'decimal-pad'}
        unitLabel={item.unitLabel}
        keyboardType={item.keyboardType}
        onChangeText={this._handleChangeText}
        value={value}
        onKeyPress={this._onKeyPress}
        onFocus={this._onFocus}
        onBlur={this._onBlur}
        onEndEditing={this._onEndEditing}
        // MUST NOT Set align = right as Android will have problem that the touchable's area of XInput will be over minus button.
        textAlign='right'
        selectTextOnFocus
        // style={[S.BG_SECONDARY]}
      />
    )
  }

  _renderMinusButton = () => {
    const { disabled, item, isSubmitting = false } = this.props
    if (!item || item.baseKey !== V_ATTR.QTY) {
      return null
    }
    return (
      <XIconButton
        disabled={isSubmitting || disabled}
        colorScheme='danger'
        rounded='full'
        name='minus-circle'
        family='FontAwesome'
        m='2'
        onPress={this._handlePressDecreaseValue}
        // offset icon ที่ตัดขอบมาไม่พอดี
        _icon={{ pl: '2px' }}
      />
    )
  }

  _renderPlusButton = () => {
    const { disabled, item, isSubmitting = false } = this.props
    if (!item || item.baseKey !== V_ATTR.QTY) {
      return null
    }
    return (
      <XIconButton
        disabled={isSubmitting || disabled}
        m='2'
        colorScheme='success'
        rounded='full'
        name='plus-circle'
        family='FontAwesome'
        onPress={this._handlePressIncreaseValue}
        // offset icon ที่ตัดขอบมาไม่พอดี
        _icon={{ pl: '2px' }}
      />
    )
  }

  _renderCustomOperationButton = () => {
    const { item, isSubmitting = false } = this.props
    if (!item || item.baseKey !== V_ATTR.QTY) {
      return null
    }
    return (
      <XIconButton
        disabled={isSubmitting}
        m='2'
        rounded='full'
        variant='solid'
        name='plus-minus-variant'
        family='MaterialCommunityIcons'
        onPress={this._handlePressCustomValue}
      />
    )
  }

  _renderReadonlyVariantName = () => {
    const { item, value = '' } = this.props
    let printNameText = `#${item.variantIndex + 1}`
    printNameText = value === '' ? `${printNameText} ` : `${printNameText}: `
    return (
      <HStack w='full' px='3' pt='2' style={this._getBgColorStyle()}>
        <XText w='28px' variant='inactive'>
          {printNameText}
        </XText>
        <VStack flex={1} flexWrap='wrap'>
          <XText w='full'>{value}</XText>
        </VStack>
      </HStack>
    )
  }

  _renderVariantItem = () => (
    <HStack w='full' px='3' py='1' style={this._getBgColorStyle()} onLayout={this.props.onLayout}>
      {this._renderLeftColumn()}
      {this._renderRightColumn()}
      {/* <VariantInput itemControlKey={itemControlKey} onChangeTextInput={this._handleChangeInput} value={this.state[itemControlKey]} /> */}
    </HStack>
  )

  render() {
    const { item } = this.props
    // if (!visibleMap[item.key]) {
    //   return null
    // }
    if (item.key === V_ATTR.NAME) {
      return this._renderReadonlyVariantName()
    }

    return this._renderVariantItem()
  }
}

type CustomEditInputValueOverlayOperator = 'plus' | 'minus' | 'specific'

interface ICustomEditInputValueOverlayProps {
  onSubmit: (itemControlKey: string, computedValue: string) => void | Promise<void>
  onSubmitBatch?: (newVariantValueMap: { [itemControlKey: string]: any }) => void | Promise<void>
}

type ICustomEditInputValueOverlayState = Partial<ICustomSingleEditInputValueParams> &
  Partial<ICustomBatchEditInputValueParams> & {
    isBatch?: boolean
    checkedVariantKeyMap: {
      [itemControlKey: string]: boolean
    }
  } & {
    // Main Properties
    isVisible: boolean
    selectedSegmentIndex: number
    inputValue: string
    operator: CustomEditInputValueOverlayOperator

    // inputType: 'integer' | 'float' | 'money' | 'string'
  }

type ICustomSingleEditInputValueParams = {
  itemControlKey: string
  oldValue: string
  focusItemDisplayName?: string
} & Partial<IWithInputType>

type ICustomBatchEditInputValueParams = {
  oldValueMap: {
    [itemControlKey: string]: any
  }
  editableVariantKeys: string[]
  editableDisplayNames: string[]
  baseVariantItems: IVariantListItemExtended[]
  filteredVariantItems: IVariantListItemExtended[]
} & Partial<IWithInputType>

interface IWithInputType {
  inputType: 'integer' | 'float' | 'money' | 'string'
}

class CustomEditInputValueOverlay extends React.Component<ICustomEditInputValueOverlayProps, ICustomEditInputValueOverlayState> {
  static displayName = 'CustomEditInputValueOverlay'

  constructor(props) {
    super(props)
    this.state = {
      isVisible: false,
      selectedSegmentIndex: 1,
      operator: 'plus',

      inputValue: '',
      inputType: 'integer',

      itemControlKey: null,
      oldValue: null,

      isBatch: false,

      oldValueMap: {},
      editableVariantKeys: [],
      editableDisplayNames: [],
      checkedVariantKeyMap: {},
      baseVariantItems: [],
      filteredVariantItems: [],

      focusItemDisplayName: null,
    }
  }

  _onSubmit = () => {
    const { onSubmit, onSubmitBatch } = this.props
    const { itemControlKey = null, isBatch } = this.state
    if (isBatch) {
      if (_.isFunction(onSubmitBatch)) {
        onSubmitBatch(this._computeSubmitNewVariantValueMaps())
      }
    } else if (_.isFunction(onSubmit)) {
      onSubmit(itemControlKey, this._computeSubmitNewValue())
    }
    this.close()
  }

  open = (params: ICustomSingleEditInputValueParams) => {
    this.setState({ isVisible: true, inputValue: '10', isBatch: false, ...params })
  }

  openBatch = (params: ICustomBatchEditInputValueParams) => {
    if (!params || _.isEmpty(params)) {
      return
    }
    const checkedVariantKeyMap = {}
    params.editableVariantKeys.forEach((evk) => {
      checkedVariantKeyMap[evk] = true
    })
    this.setState({ isVisible: true, inputValue: '10', isBatch: true, ...params, checkedVariantKeyMap })
  }

  close = () => this.setState({ isVisible: false, itemControlKey: null })

  _computeBatchEditableVariantCount = () => {
    const { baseVariantItems, editableVariantKeys } = this.state
    const editableBaseVariantItems = baseVariantItems.filter((bvi) => _.includes(editableVariantKeys, bvi.key))
    return editableBaseVariantItems.length
  }

  _computeBatchUserSelectedEditableVariantCount = () => {
    const { editableVariantKeys, filteredVariantItems, checkedVariantKeyMap } = this.state
    const userSelectedEditableVariantKeys = Object.keys(checkedVariantKeyMap).filter((cvk) => checkedVariantKeyMap[cvk])
    const userSelectedEditableBaseVariantItems = filteredVariantItems.filter((fvi) => _.includes(userSelectedEditableVariantKeys, fvi.key))
    return userSelectedEditableBaseVariantItems.length
  }

  _computeSubmitNewVariantValueMaps = () => {
    const { inputValue = '', oldValueMap = {}, checkedVariantKeyMap, filteredVariantItems } = this.state
    if (!_.isString(inputValue) || inputValue === '') {
      return oldValueMap
    }
    const newValueMap = {}
    const editableKeys = Object.keys(checkedVariantKeyMap).filter((cvk) => checkedVariantKeyMap[cvk])
    // console.log('_computeSubmitNewVariantValueMaps oldValueMap => ', oldValueMap)
    // console.log('_computeSubmitNewVariantValueMaps editableKeys => ', editableKeys)
    // Object.keys(oldValueMap).forEach((vck) => {
    //   const splittedVCK = vck.split('_')
    //   const vk = splittedVCK.length > 2 ? `${splittedVCK[1]}_${splittedVCK[2]}` : splittedVCK[1]
    //   console.log('_computeSubmitNewVariantValueMaps vck => ', vck, ' vk => ', vk)
    //   if (_.includes(editableKeys, vk)) {
    //     const oldValue = oldValueMap[vck]
    //     newValueMap[vck] = this._computeNewValueByOperator(oldValue, inputValue)
    //   }
    // })

    filteredVariantItems.forEach((fvi) => {
      const vk = fvi.key
      if (_.includes(editableKeys, vk)) {
        const vck = fvi.itemControlKey
        const oldValue = oldValueMap[vck]
        newValueMap[vck] = this._computeNewValueByOperator(oldValue, inputValue)
      }
    })
    // console.log('_computeSubmitNewVariantValueMaps newValueMap => ', newValueMap)

    return newValueMap
  }

  _computeSubmitNewValue = () => {
    const { inputValue = '', oldValue = '' } = this.state
    if (!_.isString(inputValue) || inputValue === '') {
      return oldValue
    }
    return this._computeNewValueByOperator(oldValue, inputValue)
  }

  _computeNewValueByOperator = (oldValue: string, inputValue: string) => {
    const { operator, inputType } = this.state
    const currentValue = inputType === 'integer' ? parseInt(oldValue) : parseFloat(oldValue)
    const newInputValueValue = inputType === 'integer' ? parseInt(inputValue) : parseFloat(inputValue)
    let newValue
    switch (operator) {
      case 'plus':
        newValue = currentValue + newInputValueValue
        break
      case 'minus':
        newValue = currentValue - newInputValueValue
        break
      case 'specific':
        newValue = newInputValueValue
        break
      default:
        return oldValue
    }
    if (_.isNaN(newValue) || newValue < 0) {
      newValue = 0
    }
    return newValue.toString()
  }

  _computeNewBatchValues = () => {
    const { inputValue = '', operator, oldValueMap = {}, editableVariantKeys = [], checkedVariantKeyMap = {} } = this.state
  }

  _getheaderRightProps = () => ({ closeIcon: true, onPressItem: this.close })

  _handleChangeText = (newText: string) => this.setState({ inputValue: newText })

  _onSegmentChange = (newIndex: number) => {
    let operator
    switch (newIndex) {
      case 0:
        operator = 'minus'
        break
      case 1:
        operator = 'plus'
        break
      case 2:
        operator = 'specific'
        break
      default:
        return
    }
    this.setState({ operator, selectedSegmentIndex: newIndex })
  }

  _renderHeader = () => {
    const { isBatch } = this.state
    const titleText = isBatch ? 'แก้เป็นชุด' : 'แก้ค่า'
    return <XCustomHeader title={titleText} headerRightProps={this._getheaderRightProps()} />
  }

  _renderSegmentLabelText = () => <XText variant='inactive'>ลักษณะตัวดำเนินการปรับคลัง:</XText>

  _renderInputLabelText = () => {
    const { operator } = this.state
    let labelText = 'ระบุค่า'
    switch (operator) {
      case 'plus':
        labelText += 'ที่ต้องการบวกเพิ่ม:'
        break
      case 'minus':
        labelText += 'ที่ต้องการลดลง:'
        break
      case 'specific':
        labelText += 'ใหม่ที่ต้องการ:'
        break
      default:
        labelText = ''
        break
    }
    return <XText variant='inactive'>{labelText}</XText>
  }

  _renderSegment2 = () => {
    const { selectedSegmentIndex = 0 } = this.state
    return (
      <View style={{ minWidth: p.op.isWeb() ? 104 : 68, maxWidth: 200, marginRight: 4 }}>
        <Segment
          selectedIndex={selectedSegmentIndex}
          options={['-', '+']}
          onSegmentChange={this._onSegmentChange}
          textStyle={[S.TEXT_LARGER_2, S.TEXT_BOLD]}
        />
      </View>
    )
  }

  _renderSegment3 = () => {
    const { selectedSegmentIndex = 0 } = this.state
    return (
      <Segment
        selectedIndex={selectedSegmentIndex}
        options={['-', '+', '=']}
        onSegmentChange={this._onSegmentChange}
        textStyle={[S.TEXT_LARGER_2, S.TEXT_BOLD]}
      />
    )
  }

  _renderBatchEditableVariantInfoText = () => {
    const { editableDisplayNames, editableVariantKeys, checkedVariantKeyMap } = this.state

    const userSelectedVariantNames = editableDisplayNames.filter((vn, idx) => checkedVariantKeyMap[editableVariantKeys[idx]])
    const editableVariantCount = this._computeBatchEditableVariantCount()
    const userSelectedEditableVariantCount = this._computeBatchUserSelectedEditableVariantCount()

    const vNamesText = userSelectedVariantNames.join(', ')
    const displayText = `แก้ไขทุก ${vNamesText} เฉพาะที่เห็นในหน้านี้\n(${userSelectedEditableVariantCount} จากทั้งหมด ${editableVariantCount} รายการ)`
    return (
      <View style={[S.ROW_CENTER, S.PADDING_VERTICAL_8]}>
        <XText variant='inactive' textAlign='center'>
          {displayText}
        </XText>
      </View>
    )
  }

  _renderBatchEditableVariantCheckboxes = () => {
    const { editableVariantKeys } = this.state
    if (editableVariantKeys.length < 2) {
      return null
    }
    return (
      <View style={[S.COLUMN_CENTER]}>
        <XText variant='inactive'>สำหรับ</XText>
        <View style={[S.ROW_MIDDLE_START, S.FLEX_WRAP]}>{editableVariantKeys.map(this._renderEditableVariantCheckbox)}</View>
      </View>
    )
  }

  _renderEditableVariantCheckbox = (editableVariantKey: string, index: number) => {
    const { editableDisplayNames, checkedVariantKeyMap } = this.state

    const label = editableDisplayNames[index]
    return (
      <View key={index.toString()} style={[S.ROW_CENTER, S.PADDING_VERTICAL_12]}>
        <XCheckButton
          label={label}
          index={index}
          checked={checkedVariantKeyMap[editableVariantKey]}
          onPress={this._handlePressEditableVariantCheckbox}
          buttonStyle={{ marginRight: 8 }}
        />
      </View>
    )
  }

  _handlePressEditableVariantCheckbox = (args) => {
    const { index, data } = args
    const { editableVariantKeys, checkedVariantKeyMap } = this.state
    let isManyChecked = false
    let checkedCount = 0

    const newCheckedVariantKeyMap = { ...checkedVariantKeyMap }
    const ek = editableVariantKeys[index]
    newCheckedVariantKeyMap[ek] = !newCheckedVariantKeyMap[ek]

    for (let i = 0; i < editableVariantKeys.length; i++) {
      const evk = editableVariantKeys[i]
      if (newCheckedVariantKeyMap[evk]) {
        checkedCount++
        if (checkedCount >= 1) {
          isManyChecked = true
          break
        }
      }
    }

    if (!isManyChecked) {
      return
    }

    this.setState({ checkedVariantKeyMap: newCheckedVariantKeyMap })
  }

  _computeKeyboardXInputAttubutes = () => {
    const { inputType } = this.state
    return {
      isInteger: inputType === 'integer',
      isMoney: inputType === 'money',
      isNumber: inputType === 'float' || inputType === 'money',
    }

    // const editableKeys = Object.keys(checkedVariantKeyMap).filter((cvk) => checkedVariantKeyMap[cvk])
    // const isInteger = editableKeys
    // // FIXME: ตอนนี้ batch op เฉพาะ qty หรือ cost + price (cost / price_pgId)
    // return 'decimal-pad'
  }

  _computeKeyboardInputKeyboardType = () => {
    const { inputType } = this.state
    switch (inputType) {
      case 'integer':
        return 'number-pad'

      case 'float':
        return 'decimal-pad'

      case 'money':
        return 'decimal-pad'

      default:
        return 'default'
    }
  }

  // This input is used in, for example, แก้ค่า, แก้เป็นชุด
  _renderInput = () => {
    const { inputValue = '' } = this.state
    return (
      <View style={{ width: 112 }}>
        <XInput
          textAlign='right'
          {...this._computeKeyboardXInputAttubutes()}
          keyboardType={this._computeKeyboardInputKeyboardType()}
          onChangeText={this._handleChangeText}
          value={inputValue}
        />
      </View>
    )
  }

  _renderFocusItemDisplayNameText = () => {
    const { focusItemDisplayName = null } = this.state
    if (!focusItemDisplayName) {
      return null
    }
    return (
      <View style={[S.ROW_MIDDLE_BETWEEN, S.MARGIN_VERTICAL_8]}>
        <XText variant='inactive'>สำหรับ</XText>
        <XText variant='inactive'>{focusItemDisplayName}</XText>
      </View>
    )
  }

  _renderOldValueText = () => {
    const { oldValue = '' } = this.state
    // return <XText style={[S.TEXT_INACTIVE, S.TEXT_ALIGN_CENTER]}>{`คลังเดิม: ${oldValue}`}</XText>
    return (
      <View style={[S.ROW_MIDDLE_BETWEEN, S.MARGIN_VERTICAL_8]}>
        <XText variant='inactive'>ค่าเดิม</XText>
        <XText variant='inactive'>{oldValue}</XText>
      </View>
    )
  }

  _renderNewValueText = () => (
    <View style={[S.ROW_MIDDLE_BETWEEN, S.MARGIN_VERTICAL_8]}>
      <XText variant='inactive'>คลังใหม่</XText>
      <XText bold>{this._computeSubmitNewValue()}</XText>
    </View>
  )

  _renderInputWithSegment2 = () => (
    <View style={[S.ROW_MIDDLE_BETWEEN, S.MARGIN_VERTICAL_8]}>
      <XText variant='inactive'>ลด/เพิ่ม</XText>
      <View style={[S.ROW_MIDDLE_END]}>
        {this._renderSegment2()}
        {this._renderInput()}
      </View>
    </View>
  )

  _renderBatchInputWithSegment3 = () => (
    <View style={[S.COLUMN_CENTER, { marginBottom: 16 }]}>
      <View style={[S.ROW_CENTER, S.MARGIN_VERTICAL_4, { marginBottom: 12, minWidth: 120, maxWidth: 200 }]}>{this._renderSegment3()}</View>
      <View style={[S.ROW_CENTER, S.MARGIN_VERTICAL_8]}>{this._renderInput()}</View>
    </View>
  )

  // _renderDisplayChangedValueText = () => {
  //   return (
  //     <View style={[S.MARGIN_VERTICAL_12]}>
  //       {/* {this._renderOldValueText()} */}
  //       <XText style={[S.TEXT_PRIMARY, S.TEXT_BOLD, S.TEXT_ALIGN_CENTER]}>{`คลังใหม่: ${this._computeNewValue()}`}</XText>
  //     </View>
  //   )
  // }

  _renderSubmitButton = () => (
    <XButton w='full' onPress={this._onSubmit}>
      ยืนยัน
    </XButton>
  )

  renderContentByCondition = () => {
    const { isBatch } = this.state
    if (isBatch) {
      return (
        <VStack w='full' p='2' pb='40px'>
          {this._renderBatchEditableVariantCheckboxes()}
          {this._renderBatchEditableVariantInfoText()}
          {this._renderBatchInputWithSegment3()}
        </VStack>
      )
    }
    return (
      <VStack w='full' p='2' pb='40px'>
        {this._renderFocusItemDisplayNameText()}
        {this._renderOldValueText()}
        {this._renderInputWithSegment2()}
        {this._renderNewValueText()}
      </VStack>
    )
  }

  renderMain = () => (
    <VStack w='full' _light={{ bg: 'white' }} space='1'>
      {this._renderHeader()}
      <XScrollView maxH='500px'>
        {/* <View style={{ marginTop: 8 }}>{this._renderSegmentLabelText()}</View> */}
        {/* <View style={[S.MIN_HEIGHT_34]}>{this._renderSegment2()}</View> */}

        {/* <View style={{ marginTop: 8 }}>{this._renderInputLabelText()}</View> */}
        {/* <View style={[S.ROW_CENTER, S.MIN_HEIGHT_34, S.MARGIN_VERTICAL_4]}>{this._renderInput()}</View> */}

        {/* <View>{this._renderDisplayChangedValueText()}</View> */}
        {this.renderContentByCondition()}
      </XScrollView>
      <HStack minH='9' p='2' w='full'>
        {this._renderSubmitButton()}
      </HStack>
    </VStack>
  )

  render() {
    return (
      <XOverlay
        visible={this.state.isVisible}
        onRequestClose={this.close}
        // contentStyle={{ minHeight: 140, maxHeight: 318, width: p.op.isWeb() ? 340 : 280, marginBottom: p.op.isAndroid() ? 44 : 130 }}
      >
        {this.renderMain()}
      </XOverlay>
    )
  }
}

export default ProductVariantEditorView
