// import p from 'x/config/platform-specific'

// const PaymentAccountPicker = p.comp.getPaymentAccountPicker()

// export default PaymentAccountPicker

import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import { IPaymentAccountPickerProps, IPaymentAccount } from 'x/index'
import { fetchPaymentAccounts } from 'x/utils/api'
import * as util from 'x/utils/util'
import NB from 'x/config/nativebase'

import PaymentAccountCard from 'xui/components/PaymentAccountCard'
import BankIcon from 'xui/components/BankIcon'

import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import XModal from './XModal'
import XIcon from './XIcon'
import XCard from './XCard'
import XText from './XText'
import XContent from './XContent'

const PaymentAccountPicker: React.FC<IPaymentAccountPickerProps> = ({
  targetStoreId,
  selectedPaymentAccountId,
  onChangePaymentAccount,
  onPaymentAccountLoaded,
  placeholderText = 'กรุณาเลือกบัญชีชำระ',
  containerStyle = {},
  showSystemAccounts = false,
}) => {
  const [selectOptions, setSelectOptions] = useState<IPaymentAccount[]>([])
  const [selectedPaymentAccount, setSelectedPaymentAccount] = useState<IPaymentAccount>(null)
  const [isPickerVisible, setIsPickerVisible] = useState(false)

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      initPaymentAccounts()
    }

    return () => {
      isMounted = false
    }
  }, [])

  useEffect(() => {
    if (
      selectedPaymentAccountId &&
      selectOptions.length > 0 &&
      selectedPaymentAccount &&
      selectedPaymentAccount.id &&
      selectedPaymentAccount.id !== selectedPaymentAccountId
    ) {
      const foundPA = selectOptions.find((opt) => opt.id === selectedPaymentAccountId)
      if (foundPA && foundPA.id) {
        setSelectedPaymentAccount(foundPA)
      }
    } else if (_.isNull(selectedPaymentAccountId) && selectedPaymentAccount && selectedPaymentAccount.id) {
      setSelectedPaymentAccount(null)
    }
  }, [selectedPaymentAccountId])

  const initPaymentAccounts = async () => {
    const reqBody = { store_id: targetStoreId }
    // const apiOptions: IApiOptions = {
    //   axiosOptions: {
    //     retry: 0,
    //     timeout: 60000,
    //   },
    //   // isErrorAlertDisabled: true,
    // }
    try {
      // const res = await api.post(api.POST_BANKACCOUNTS, reqBody, apiOptions)
      // console.log('Just in PaymentAccountPicker:: initPaymentAccounts')
      // console.log('const pas = await fetchPaymentAccounts(reqBody)', reqBody)
      const pas = await fetchPaymentAccounts(reqBody)
      // console.log('PaymentAccountPicker::initPaymentAccounts pas => ', pas)
      // if (res.payment_accounts) {
      //   const pas = res.payment_accounts as IPaymentAccount[]
      //   if (_.isFunction(onPaymentAccountLoaded)) {
      //     onPaymentAccountLoaded(pas)
      //   }
      //   setSelectOptions(pas)
      //   // setSelectOptions(res.payment_accounts)
      //   // setSelectOptions(res.payment_accounts.filter(util.filterPaymentAccountPickerExcludeDefault))
      //   // setSelectOptions(pas.filter(util.filterPaymentAccountPickerExcludeDefault))
      // }

      if (_.isArray(pas)) {
        if (_.isFunction(onPaymentAccountLoaded)) {
          onPaymentAccountLoaded(pas)
        }
        setSelectOptions(pas)
      }
    } catch (err) {
      console.log('PaymentAccountPicker::initPaymentAccounts err => ', err)
    }
  }

  const _closePicker = () => setIsPickerVisible(false)
  const _openPicker = () => setIsPickerVisible(true)

  // const _getTxtAccountSelector = () => {
  //   return 'xxxxx'
  // }

  const _handleChangePaymentAccount = (newSelectedPaymentAccount) => {
    _closePicker()
    if (_.isFunction(onChangePaymentAccount)) {
      // แบบเดิม
      // const propPaymentAccount = onChangePaymentAccount(newSelectedPaymentAccount)
      // setSelectedPaymentAccount(propPaymentAccount)
      // แก้ไขใหม่ // ARTID
      setSelectedPaymentAccount(newSelectedPaymentAccount)
      onChangePaymentAccount(newSelectedPaymentAccount)
    } else {
      setSelectedPaymentAccount(newSelectedPaymentAccount)
    }
  }

  return (
    <VStack w='full'>
      {selectedPaymentAccount ? (
        <PaymentAccountCard onPress={_openPicker} data={selectedPaymentAccount} showForwardIcon />
      ) : (
        <XCard
          // style={[
          //   S.WIDTH_FULL,
          //   S.CARDLIKE_BORDERS,
          //   S.ROW_MIDDLE_BETWEEN,
          //   S.PADDING_VERTICAL_6,
          //   S.PADDING_HORIZONTAL_6,
          //   // { minHeight: 62 },
          //   containerStyle,
          // ]}
          onPress={_openPicker}
          w='full'
          // minH='62px'
          style={containerStyle}>
          <XCard.Body>
            <HStack w='full' space='1' alignItems='center'>
              <BankIcon
                bankId={0}
                // style={S.MARGIN_HORIZONTAL_6}
              />
              <HStack flex={1} space='1' alignItems='center'>
                <XText variant='primary' bold>
                  {placeholderText}
                </XText>
              </HStack>
              <XIcon name='arrow-forward' />
            </HStack>
          </XCard.Body>
        </XCard>
      )}
      <ModalPicker
        onPick={_handleChangePaymentAccount}
        title={placeholderText}
        options={selectOptions}
        visible={isPickerVisible}
        onRequestClose={_closePicker}
        // isGBPayHidden={isGBPayHidden}
        showSystemAccounts={showSystemAccounts}
      />
    </VStack>
  )
}

interface IModalPickerProps {
  visible: boolean
  options: IPaymentAccount[]
  title: string
  onRequestClose: () => void
  onPick: (selectPaymentAccount: IPaymentAccount) => void

  // isGBPayHidden?: boolean
  showSystemAccounts?: boolean
}
const ModalPicker: React.FC<IModalPickerProps> = ({ visible, options, title, onRequestClose, onPick, showSystemAccounts = false }) => {
  const renderOptions = showSystemAccounts ? options : options.filter(util.filterPaymentAccountPickerExcludeDefault)
  return (
    <XModal visible={visible} title={title} hasCloseButton onRequestClose={onRequestClose}>
      <XContent flex={1} w='full' bg={NB.HC.BG.LIGHT_GREY()}>
        <VStack w='full' p='2' space='2'>
          {/* <XCustomHeader title={title} headerRightProps={{ closeIcon: true, onPressItem: onRequestClose }} /> */}
          {renderOptions.map((pa, idx) => (
            // if (isGBPayHidden && pa.bank_id === CONS.SYSTEM_BANK_IDS.GBPAY) {
            //   return null
            // }
            <PaymentAccountCard key={idx.toString()} onPress={onPick} data={pa} showForwardIcon />
          ))}
        </VStack>
      </XContent>
    </XModal>
  )
}

export default PaymentAccountPicker
