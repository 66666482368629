import { IActionSheetConfiguration } from 'x/index'

const warn = () => console.log("haven't resgiter the ats yet")
const ats: {
  show: (cfg: IActionSheetConfiguration, onSelect: (index: number) => Promise<void> | void) => void
} = {
  show: warn,
  // hide: warn,
}

/**
 * this method is to save the referece of the singleton component
 */
export const registerActionSheet = (ref) => {
  if (ref) {
    console.log('ActionSheet registered')
    ats.show = ref.show
    // ats.hide = ref.hide
  } else {
    console.log('ActionSheet unregistered')
    ats.show = warn
    // ats.hide = warn
  }
}

/**
 * export the ats component
 */
export { default as ActionSheetInstance } from './ActionSheet'

/**
 * export the open and close method
 */
export default ats
