import _ from 'lodash'

import { IBgInfo } from '../types/BackgroundJob'
import * as util from './util'

export function getInfoMessageText(info: IBgInfo): string | null {
  if (_.isNil(info)) {
    return null
  }

  let infoMessageText: string | null = null

  if (!_.isNil(info.msg_key)) {
    infoMessageText = util.getBgTaskErrorText(info.msg_key)
  }
  if (!_.isNil(info.msg_body)) {
    if (!_.isNil(infoMessageText)) {
      infoMessageText += ` ${info.msg_body}`
    } else {
      infoMessageText = info.msg_body
    }
  }
  return infoMessageText
}
