import { SHARED_GUEST_SCREENS } from 'xui/modules/navigator/guest-screens'

// import LoginViewContainer from '../login/LoginViewContainer'

export const GUEST_SCREENS = {
  ...SHARED_GUEST_SCREENS,

  // LoginView: {
  //   screen: LoginViewContainer,
  //   path: 'login',
  // },
}
