import React from 'react'
import { View, ScrollView } from 'react-native'
import * as util from 'x/utils/util'
import BaseUIProductWarehousesPicker from 'xui/modules/product/BaseUIProductWarehousesPicker'

import { COLORS } from 'x/config/styles'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import ThemeSizeContext from '../../context/ThemeSizeContext'

export default class ProductWarehousesPicker extends BaseUIProductWarehousesPicker {
  static displayName = 'ProductWarehousesPicker'

  static navigationOptions = {
    header: null,
  }

  static contextType = ThemeSizeContext

  context!: React.ContextType<typeof ThemeSizeContext>

  goBack = () => {
    util.navGoBack(this.props)
  }

  _renderMain = () => {
    const { contentOneOfThreeColumnWidth, contentOneOfThreeColumnWidthStyle, contentHeightStyle } = this.context

    return (
      <XContainer style={[contentOneOfThreeColumnWidthStyle, contentHeightStyle]}>
        {this.renderCustomHeader()}
        <XContent contentContainerStyle={{ flex: 1 }} style={[contentOneOfThreeColumnWidthStyle, contentHeightStyle]}>
          <View
            style={{ flex: 1, backgroundColor: COLORS.BG_LIGHT_GREY }}
            onLayout={(event) => {
              const { x, y, width, height } = event.nativeEvent.layout
              util.setStatePromise(this, {
                loadingWidth: width,
                loadingHeight: height,
              })
            }}>
            <ScrollView>{this._renderbody()}</ScrollView>
          </View>
          {/* {this._loading()} */}
        </XContent>
      </XContainer>
    )
  }
}
