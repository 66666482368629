import React, { useContext } from 'react'
import HStack from 'xui/components/HStack'
import _ from 'lodash'
import { Platform, StyleSheet } from 'react-native'
import CONS from 'x/config/constants'
import { ICustomHeaderProps } from 'x/index'
// import { SafeAreaConsumer, EdgeInsets } from 'react-native-safe-area-context'
import { NavigationContext } from '@react-navigation/native'
import XText from './XText'
import NavHeaderButton from './NavHeaderButton'
import Box from './Box'

const XCustomHeader: React.FC<ICustomHeaderProps> = (props) => {
  // Navigation
  const navigation = useContext(NavigationContext)

  const _renderBtnColLeft = () => {
    const { renderHeaderLeft, headerLeftProps, headerLeft } = props
    if (props.useDefaultNavBack && navigation) {
      // return <NavHeaderButton backIcon onPressItem={() => navigation.goBack(navigation.state.key)} />
      return <NavHeaderButton backIcon onPressItem={() => navigation.goBack()} />
    }
    if (_.isFunction(renderHeaderLeft)) {
      return renderHeaderLeft()
    }
    if (headerLeft) {
      return headerLeft
    }
    if (headerLeftProps) {
      return <NavHeaderButton {...props.headerLeftProps} />
    }
    return <Box w='12' />
  }

  const _renderBtnColRight = () => {
    const { renderHeaderRight, headerRightProps, headerRight } = props
    if (_.isFunction(renderHeaderRight)) {
      return renderHeaderRight()
    }
    if (headerRight) {
      return headerRight
    }
    if (headerRightProps) {
      return <NavHeaderButton {...props.headerRightProps} />
    }
    return <Box w='12' />
  }

  const _renderCenterTitle = () => {
    const { renderHeaderCenter, title, titleStyle = {} } = props
    if (_.isFunction(renderHeaderCenter)) {
      return renderHeaderCenter()
    }
    return (
      <HStack flex={1} minH='10' h='full' alignItems='center' justifyContent='center'>
        <XText
          bold
          // _android={{
          //   h: 'full',
          // }}
          textAlign='center'
          // textAlignVertical='center'
          // flex={1}
          // maxW='200px'
          // lineHeight='lg'
          // style={[STYLES.TXT_NORMAL_BOLD, titleStyle, { textAlign: 'center', maxWidth: 200 }, titleStyle]}
          // style={[{ maxWidth: 200 }, titleStyle]}
          style={titleStyle}>
          {title}
        </XText>
      </HStack>
    )
  }

  // _renderMain = (insets?: EdgeInsets) => {
  const _renderMain = () => {
    const { headerStyle = {} } = props
    // const safeAreaStyle = insets ? { marginTop: 0, paddingTop: 0 } : {}
    return (
      <HStack
        w='full'
        minH='11'
        px='2'
        _light={{
          bg: 'coolGray.50',
          borderBottomColor: 'coolGray.300',
        }}
        _dark={{
          bg: 'warmGray.800',
          borderBottomColor: 'warmGray.600',
        }}
        // bg={useColorModeValue('coolGray.50', 'warmGray.800')}
        borderBottomWidth='1'
        // borderBottomColor={useColorModeValue('coolGray.300', 'warmGray.600')}
        // bg={useColorModeValue('white', 'dark.200')}
        // _light={{ bg: 'white' }}
        alignItems='center'
        // justifyContent='center'
        // justifyContent='space-between'
        // style={StyleSheet.flatten([s.CUSTOM_HEADER, headerStyle])
        style={StyleSheet.flatten(headerStyle)}>
        {/* {Platform.OS === 'web' ? null : <XStatusBar translucent backgroundColor='transparent' />} */}
        {/* <HStack minW='16' alignItems='center' justifyContent='center'> */}
        {_renderBtnColLeft()}
        {/* </HStack> */}
        {/* <VStack flex={1} alignItems='center' justifyContent='center'> */}
        {/* <Text style={[STYLES.TXT_NORMAL_BOLD, titleStyle]}>{title}</Text> */}
        {_renderCenterTitle()}
        {/* </VStack> */}
        {/* <HStack minW='16' alignItems='center' justifyContent='center'> */}
        {_renderBtnColRight()}
        {/* </HStack> */}
      </HStack>
    )
  }

  return _renderMain()
}

XCustomHeader.defaultProps = {
  title: '',
  titleStyle: {},
}

XCustomHeader.displayName = 'XCustomHeader'

export default XCustomHeader

const s = {
  GRID_CONTAINER: {
    flex: 1,
    minHeight: 44,
  },
  GRID_COL_MIDDLE_CENTER: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  GRID_COL_MIDDLE_RIGHT: {
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  HEADER_COL_ICON: {
    flex: 0,
    minWidth: 54,
  },
  CUSTOM_HEADER: {
    // backgroundColor: '#fff',
    paddingLeft: 2,
    paddingRight: 2,
    height: 54,
    marginTop: 0,
    paddingTop: 0,
    ...Platform.select({
      // android: {
      //   marginTop: getStatusBarHeight(true),
      // },
      // ios: {
      //   marginTop: isIphoneX() ? getStatusBarHeight() - 4 : undefined,
      // },
      web: {
        height: CONS.SPECIFIC_PLATFORM.WEB.HEADER_HEIGHT, // NAV_HEADER_HEIGHT
      },
    }),
  },
}
