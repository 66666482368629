import HStack from 'xui/components/HStack'
import Box from 'xui/components/Box'
import React from 'react'
import { TouchableOpacity } from 'react-native'
import _ from 'lodash'
import XIcon, { XIconFamily } from './XIcon'
import XText from './XText'

interface IXQuickInfoButtonProps {
  onPress: () => void
  labelTop?: string
  iconTopName?: string
  iconTopFamily?: XIconFamily
  label: string
  iconName?: string
  iconFamily?: XIconFamily

  // themeColor ส่งสีเข้ามา แล้วเส้นและอักษรทั้งหมดจะเป้นสีนั้น
  themeColor?: string
}

const XQuickInfoButton: React.FC<IXQuickInfoButtonProps> = ({
  onPress,
  labelTop,
  iconTopName,
  iconTopFamily,
  label,
  iconName,
  iconFamily,
  themeColor = null,
}) => (
  <Box flex={1}>
    <TouchableOpacity onPress={() => onPress()}>
      <Box
        flex={1}
        py='1'
        borderWidth='1px'
        borderColor={_.isNil(themeColor) ? 'gray.800' : themeColor}
        bgColor='gray.100'
        borderRadius='md'
        // h='20'
        justifyContent='center'
        alignItems='center'>
        {_.isNil(labelTop) && _.isNil(iconTopName) ? null : (
          <Box alignItems='center' justifyContent='center'>
            {!_.isNil(labelTop) ? (
              <XText fontSize='md' color={_.isNil(themeColor) ? 'gray.500' : themeColor}>
                {labelTop}
              </XText>
            ) : null}
            {_.isNil(iconTopName) ? null : (
              <XIcon name={iconTopName} family={iconTopFamily} fontSize='md' color={_.isNil(themeColor) ? 'gray.500' : themeColor} />
            )}
          </Box>
        )}
        <HStack alignItems='center' justifyContent='center' py={_.isNil(labelTop) && _.isNil(iconTopName) ? '1.5px' : '0'}>
          {!_.isNil(iconName) ? (
            <XIcon name={iconName} family={iconFamily} size='xs' color={_.isNil(themeColor) ? 'gray.500' : themeColor} />
          ) : null}
          <XText
            color={_.isNil(themeColor) ? 'gray.500' : themeColor}
            pl='2'
            textAlign='center'
            // pb={_.isNil(labelTop) && _.isNil(iconTopName) ? '7px' : '0'}
          >
            {label}
          </XText>
        </HStack>
      </Box>
    </TouchableOpacity>
  </Box>
)

XQuickInfoButton.defaultProps = {
  labelTop: null,
  iconTopName: null,
  iconTopFamily: null,
}

export default XQuickInfoButton
