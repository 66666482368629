import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as StoreActions from 'x/modules/store/StoreState'
import XContainer from 'xui/components/XContainer'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContent from 'xui/components/XContent'
import HStack from 'xui/components/HStack'
import Box from 'xui/components/Box'
import NB from 'x/config/nativebase'
import { getSelectedStore, getEditingStore, getSubscription } from 'x/redux/selectors'
import _ from 'lodash'
import p from 'x/config/platform-specific'
import XCard from 'xui/components/XCard'
import XSettingRow from 'xui/components/XSettingRow'
import { ActionApiParams, ISelectedStoreMap, IXScreenProps } from 'x/types'
import * as util from 'x/utils/util'

interface IStoreSettingVolumeDiscountProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
  submitEditing: ActionApiParams
  subscription: any
  // addNewStoreSetting: (newSetting: any) => void
}

const StoreSettingVolumeDiscount: React.FC<IStoreSettingVolumeDiscountProps> = (props) => {
  const { subscription, submitEditing, selectedStore } = props
  // const [visibleShippingTypes, setVisibleShippingTypes] = useState<number[]>([])

  // eslint-disable-next-line react/no-unstable-nested-components
  const _renderHeader = () => (
    <XCustomHeader title='ส่วนลดขายส่ง' headerLeftProps={{ backIcon: true, onPressItem: () => util.navGoBack(props) }} />
  )

  const _onSubmit = async (value: boolean, stateKey: string) => {
    const store_id = selectedStore.get('id')
    const response = await new Promise((resolve) => {
      // @ts-ignore
      submitEditing({
        body: { store_id, [stateKey]: value },
        successCallback: resolve,
        failedCallback: () => resolve(null),
      })
    })
    // console.log('response => ', response)
  }

  const isSwitchOnChange = async (newValue: { isValue: boolean; stateKey: string }) => {
    // const { subscription } = this.props
    const vd_quota = subscription.get(`vd_quota`)
    let volume_discount = selectedStore.get('s_use_volume_discount')
    if (_.isNil(volume_discount)) {
      volume_discount = false
    }

    const type = subscription.get(`type`)
    // console.log(`volume_discount -> `, volume_discount)
    // ถ้าจะเปิดใช้งาน
    if (!volume_discount) {
      // แล้วคุณไม่มี vd_quota จะใช้งานไม่ได้
      if (_.isNil(vd_quota) || vd_quota < 1) {
        p.op.alert(p.op.t('Subscription.warning.insufficientTypeTitle'), p.op.t('Subscription.warning.insufficientTypeMsg'))
        return
      }
      // ถ้า type คุณ <= 3 เปิดใช้งานไม่ได้
      if (_.isNil(type) || type <= 3) {
        p.op.alert(p.op.t('Subscription.warning.insufficientTypeTitle'), p.op.t('Subscription.warning.insufficientTypeMsg'))
        return
      }
    }
    _onSubmit(newValue.isValue, newValue.stateKey)
  }

  // const boolToInt = (flag) => (flag ? 1 : 0)

  const _getUesVolumeDiscount = () => {
    const s_use_volume_discount = selectedStore.get('s_use_volume_discount')
    if (_.isNil(s_use_volume_discount) || !s_use_volume_discount) {
      return false
    }
    return true
  }

  const boxOne = () => {
    const s_use_volume_discount: boolean = _getUesVolumeDiscount()
    return (
      <HStack py='1.5'>
        <XSettingRow
          title={p.op.t('StoreSetting.MenuStoreUesVolumeDiscount')}
          supTitle={p.op.t('StoreSetting.NoteStoreUseVolumeDiscount')}
          value={s_use_volume_discount}
          onClick={(newValue: { isValue: boolean; stateKey: string }) => isSwitchOnChange(newValue)}
          stateKey='s_use_volume_discount'
        />
      </HStack>
    )
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  const _renderBody = () => (
    <XCard mx='2' mt='2' mb='2'>
      {boxOne()}
    </XCard>
  )

  return (
    <XContainer>
      {_renderHeader()}
      <XContent px='2' pb='2'>
        <Box w='full' mt='2' borderRadius='md' _light={{ bg: 'white' }} _dark={{ bg: NB.C.D.BG.CLEAR }}>
          {_renderBody()}
        </Box>
        {/* {isLoading ? VIEW_LOADING(windowWidth, windowHeight) : null} */}
      </XContent>
    </XContainer>
  )
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// // @ts-ignore
// StoreSettingVolumeDiscount.navigationOptions = {
//   header: null,
// }

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
    editingStore: getEditingStore(state),
    subscription: getSubscription(state),
  }),
  (dispatch) => ({
    submitEditing: bindActionCreators(StoreActions.submitEditingStore, dispatch),
    resetEditingStore: bindActionCreators(StoreActions.resetEditingStore, dispatch),
    // storeActions: bindActionCreators(StoreState, dispatch),
  })
)(StoreSettingVolumeDiscount)
