import React from 'react'
import * as NavActions from 'x/utils/navigation'
import * as util from 'x/utils/util'
import * as acl from 'x/utils/acl'
import BaseAddressListView from 'x/modules/address/BaseAddressListView'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import XAddressCard from 'xui/components/XAddressCard'
import { IAddress } from 'x/types'
import _ from 'lodash'
import XCustomHeader from 'xui/components/XCustomHeader'
import XButton from 'xui/components/XButton'
import XIcon from 'xui/components/XIcon'
import Box from 'xui/components/Box'
import XText from 'xui/components/XText'
import VStack from 'xui/components/VStack'

export default class BaseUIAddressListView extends BaseAddressListView {
  // static navigationOptions = ({ navigation }) => {
  //   const { goBack, dispatch, state } = navigation
  //   const { mode, initAddr } = state.params
  //   const { CUSTOMER_ADD, STORE_ADD } = xCONS.ADDRESS_MODE_KEYS
  //   let title = ''
  //   let editableMode = null
  //   if (_.has(xCONS.ADDRESS_LIST_MODE_KEYS, mode)) {
  //     title = p.op.t(`AddressList.TitleByMode.${mode}`)

  //     if (_.includes([CUSTOMER_EDIT, CUSTOMER_PICK, CUSTOMER_PICK_CHANGE], mode)) {
  //       editableMode = CUSTOMER_ADD
  //     } else if (_.includes([STORE_EDIT, STORE_PICK, STORE_PICK_CHANGE], mode)) {
  //       editableMode = STORE_ADD
  //     } else {
  //       editableMode = null
  //     }
  //   }

  //   const headerRightBtn = APP_CONFIG.my_address_list_view.show_add_btn ? <AddIcon /> : null

  //   return {
  //     headerLeft: (
  //       <Button
  //         transparent
  //         onPress={() => {
  //           goBack(state.key)
  //         }}>
  //         <BackIcon />
  //       </Button>
  //     ),
  //     title,
  //     headerRight:
  //       editableMode && initAddr ? (
  //         <Button
  //           transparent
  //           onPress={() => {
  //             dispatch(
  //               NavActions.navToAddressView({
  //                 mode: editableMode,
  //                 address: initAddr,
  //                 isStorePickMode: _.includes([STORE_PICK_CHANGE, STORE_PICK], mode),
  //               })
  //             )
  //           }}>
  //           {headerRightBtn}
  //         </Button>
  //       ) : null,
  //   }
  // }

  renderCustomHeader = () => {
    // const { navigation } = this.props
    // const { headerTitle } = navigation.state.params
    const headerTitle = util.getNavParam(this.props, 'headerTitle')
    return <XCustomHeader renderHeaderLeft={this._renderHeaderLeft} renderHeaderRight={this._renderHeaderRight} title={headerTitle} />
  }

  _renderHeaderLeft = () => {
    const { navigation } = this.props
    const { goBack } = navigation
    return <XButton variant='ghost' leftIcon={<XIcon name='arrowleft' family='AntDesign' />} onPress={() => goBack()} />
  }

  _renderHeaderRight = () => {
    const { navigation } = this.props
    const params = util.getNavParams(this.props)
    const { profile_id, store_id } = params
    return (
      <XButton
        variant='ghost'
        onPress={() => {
          // navigation.dispatch(
          //   NavActions.navToAddressView({
          //     store_id: _.isNil(store_id) ? null : store_id,
          //     headerTitle: 'สร้างที่อยู่',
          //     type: _.isNil(profile_id) ? 'STORE' : 'PROFILE',
          //     profile_id: _.isNil(profile_id) ? null : profile_id,
          //     succeedCallback: (addresses: IAddress[]) => this._succeedCallback(addresses),
          //   })
          // )

          const type = _.isNil(profile_id) ? 'STORE' : 'PROFILE'
          navigation.dispatch(
            NavActions.navToAddressFormView({
              headerTitle: `สร้างที่อยู่${type === 'STORE' ? 'ร้าน' : ''}`,
              onSubmit: async (addr: IAddress) => {
                const isSuccess = await this._handleSubmitForm(type, addr)
                return { isGoBack: isSuccess }
              },
            })
          )
        }}
        leftIcon={<XIcon name='plus' family='Feather' />}
      />
    )
  }

  _renderMain = () => {
    const { addresses } = this.state
    // console.log('addresses => ', addresses)
    // const { onPressAddress } = this.props.navigation.state.params
    const onPressAddress = util.getNavParam(this.props, 'onPressAddress')
    if (_.isNil(addresses) || addresses.length < 1) {
      return (
        <Box w='full' mt='8'>
          <XText variant='inactive' textAlign='center'>
            ไม่พบที่อยู่
          </XText>
        </Box>
      )
    }
    return (
      <VStack w='full' space='1'>
        {addresses.map((address: IAddress) => (
          <XAddressCard
            address={address}
            // isEditable={acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.)}
            isEditable={acl.isSelectedStoreOwner()}
            isDeletable={acl.isSelectedStoreOwner()}
            onPressDelete={(Address: IAddress) => this._deleteAddress(Address)}
            onPressEdit={(address: IAddress) => {
              const { navigation } = this.props
              const params = util.getNavParams(this.props)
              const { profile_id, store_id } = params
              // navigation.dispatch(
              //   NavActions.navToAddressView({
              //     store_id: _.isNil(store_id) ? null : store_id,
              //     headerTitle: 'สร้างที่อยู่',
              //     type: _.isNil(profile_id) ? 'STORE' : 'PROFILE',
              //     profile_id: _.isNil(profile_id) ? null : profile_id,
              //     succeedCallback: (Addresses: IAddress[]) => this._succeedCallback(Addresses),
              //     address,
              //   })
              // )

              const type = _.isNil(profile_id) ? 'STORE' : 'PROFILE'
              navigation.dispatch(
                NavActions.navToAddressFormView({
                  headerTitle: `แก้ไขที่อยู่${type === 'STORE' ? 'ร้าน' : ''}`,
                  initAddress: address,
                  onSubmit: async (addr: IAddress) => {
                    const isSuccess = await this._handleSubmitForm(type, addr)
                    return { isGoBack: isSuccess }
                  },
                })
              )
            }}
            onPressCard={_.isFunction(onPressAddress) ? (Address: IAddress) => this._onPressCard(Address) : null}
          />
        ))}
      </VStack>
    )
  }

  _onPressCard = (address: IAddress) => {
    // const { navigation } = this.props
    // const { goBack } = navigation
    // const { onPressAddress } = navigation.state.params
    // onPressAddress(address)
    // goBack()

    const onPressAddress = util.getNavParam(this.props, 'onPressAddress')
    if (_.isFunction(onPressAddress)) {
      onPressAddress(address)
    }
    util.navGoBack(this.props)
  }

  render() {
    return (
      <XContainer>
        {this.renderCustomHeader()}
        <XContent px='2' pb='2'>
          {this._renderMain()}
        </XContent>
      </XContainer>
    )
  }
}
