import { connect } from 'react-redux'
import UserGroupListView from './UserGroupListView'
import { bindActionCreators } from 'redux'
import * as StoreActions from 'x/modules/store/StoreState'
import {
  getSelectedStore,
  getSelectedUserGroups,
  getSubscription,
  getShouldFetchUserGroups,
} from 'x/redux/selectors'

export default connect(
  state => ({
    selectedStore: getSelectedStore(state),
    selectedUserGroups: getSelectedUserGroups(state),
    subscription: getSubscription(state),
    shouldFetch: getShouldFetchUserGroups(state),
  }),
  dispatch => ({
    fetchMyStore: bindActionCreators(StoreActions.fetchMyStore, dispatch),
    fetchUserGroups: bindActionCreators(StoreActions.fetchUserGroups, dispatch),
    // createUserGroup: bindActionCreators(StoreActions.createUserGroup, dispatch),
    // updateUserGroup: bindActionCreators(StoreActions.updateUserGroup, dispatch),
    // deleteUserGroup: bindActionCreators(StoreActions.deleteUserGroup, dispatch),
    generateUserGroupInviteLink: bindActionCreators(StoreActions.generateUserGroupInviteLink, dispatch),
  })
)(UserGroupListView)
