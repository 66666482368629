import React from 'react'
import XContent from 'xui/components/XContent'
import XCustomHeader from 'xui/components/XCustomHeader'
import BaseStoreSettingView, { IStoreSettingListSections, IStoreSettingMenuList } from 'x/modules/store/storeSetting/BaseStoreSettingView'
import XContainer from 'xui/components/XContainer'
import HStack from 'xui/components/HStack'
import Box from 'xui/components/Box'
import _ from 'lodash'
import XText from 'xui/components/XText'
import { COLORS } from 'x/config/styles'
import { TouchableOpacity } from 'react-native'
import XIcon from 'xui/components/XIcon'
import XImageIcon from 'xui/components/XImageIcon'
import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'

export default abstract class BaseUIStoreSettingListView extends BaseStoreSettingView {
  // static displayName = 'BaseUIStoreSettingXShippingView'
  // abstract goBack: () => void

  renderCustomHeader = () => {
    const isGoBackButtonHidden = util.getNavParam(this.props, 'isGoBackButtonHidden', false)

    return (
      <XCustomHeader
        headerLeftProps={isGoBackButtonHidden ? undefined : { backIcon: true, onPressItem: () => util.navGoBack(this.props) }}
        title='ตั้งค่า'
      />
    )
  }

  _renderSectionList = () => {
    const { STORE_SETTING_LIST_SECTIONS } = this.state
    if (_.isNil(STORE_SETTING_LIST_SECTIONS)) {
      return null
    }
    // console.log('STORE_SETTING_LIST_SECTIONS => ', STORE_SETTING_LIST_SECTIONS)
    return (
      <Box>
        {STORE_SETTING_LIST_SECTIONS.map((MENU: IStoreSettingListSections) => (
          // console.log('MENU => ', MENU)
          <Box key={MENU.txtTitle}>
            <HStack _light={{ bg: COLORS.FORM_PRIMARY_BG }} _dark={{ bg: 'gray.500' }} p='2'>
              <XText variant='active' bold>
                {MENU.txtTitle}
              </XText>
            </HStack>
            {MENU.data.map((item: IStoreSettingMenuList) => this._renderSectionItem(item))}
          </Box>
        ))}
      </Box>
    )
  }

  _renderSectionItem(item: IStoreSettingMenuList) {
    const { navigation } = this.props
    const { labelKey, navAction, iconImage, icon, renderIcon } = item
    // const lastIndex = section.data.length - 1
    // const isFirstItem = index === 0
    // const isLastItem = index === lastIndex
    // util.log('_renderSectionItem index =>', index)
    // util.log('_renderSectionItem item =>', item)
    // util.log('_renderSectionItem section =>', section)
    // NavActions.navToStoreSettingGeneral
    return (
      <TouchableOpacity
        key={labelKey}
        style={{ flex: 1 }}
        onPress={() => {
          if (p.op.isWeb()) {
            // @ts-ignore
            this.props.navigation.popToTop()
          }
          navigation.dispatch(navAction)
        }}>
        <HStack
          flex={1}
          px='2'
          py={p.op.isWeb() ? '3' : '4'}
          _light={{ bg: 'white' }}
          _dark={{ bg: 'gray.800' }}
          alignItems='center'
          justifyContent='space-between'
          borderBottomColor='gray.200'
          borderBottomWidth='1'>
          <HStack space='2' alignItems='center'>
            {_.isNil(icon) ? null : (
              <Box w='6' h='6' alignItems='center' justifyContent='center'>
                <XIcon name={icon.name} family={icon.family} style={icon.style} variant='inactive' />
              </Box>
            )}

            {iconImage ? (
              <Box w='6' h='6' alignItems='center' justifyContent='center'>
                <XImageIcon source={iconImage.name} variant='active' size='md' />
              </Box>
            ) : null}

            {_.isFunction(renderIcon) ? renderIcon() : null}

            <XText variant='active' t={`StoreSetting.${labelKey}`} />
          </HStack>
          <XIcon name='arrow-forward' />
        </HStack>
      </TouchableOpacity>
    )
  }
  // return (
  //   <SectionList
  //     flex={1}
  //     keyExtractor={(item, index) => index.toString()}
  //     renderItem={({ item, index, section }) => this._renderSectionItem(index, item, section)}
  //     renderSectionHeader={({ section }) => this._renderSectionHeader(section)}
  //     sections={dataSource}
  //     stickySectionHeadersEnabled
  //     // ListFooterComponent={ () => <Separator /> }
  //   />
  // )

  render() {
    return (
      <XContainer>
        {this.renderCustomHeader()}
        <XContent>{this._renderSectionList()}</XContent>
      </XContainer>
    )
  }
}
