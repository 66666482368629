import React, { useState, useEffect, useRef } from 'react'
import { View } from 'react-native'
import DatePicker from 'react-datepicker'
import { IXTimePicker } from 'x/index'
import _ from 'lodash'
// import styled from 'styled-components'
import { Portal } from 'react-overlays'

// Comment out by pO:
// เอาออกเนื่องจากต้องการเลิกใช้ styled-components
// ไม่แน่ใจว่าเลิกใช้แล้วเกิดอะไรขึ้น เท่าที่ทดสอบดูฟังก์ชั่นทุกอย่างยังดูปกติดี
// const EditTimePickker = styled(DatePicker)`
//   element.style {
//     display: none;
//   }
// `

// const EditTimePickker = DatePicker

// Fixed DatePicker inside Modal :: https://github.com/Hacker0x01/react-datepicker/issues/1111
const CalendarContainer = ({ children }) => {
  const el = document.getElementById('calendar-portal')

  return <Portal container={el}>{children}</Portal>
}

const TimePickerWeb: React.FC<IXTimePicker> = ({ onChange, dateTime }) => {
  // const [date, setDate] = useState(new Date())
  const [newDateTime, setNewDateTime] = useState(new Date())
  const [didMount, setDidMount] = useState(false)
  const timePickerRef = useRef()
  const onChangeTime = (selectedDate) => {
    if (_.isNil(selectedDate)) {
      onChange(newDateTime)
    } else {
      const currentDate = selectedDate
      // console.log(`currentDate `, currentDate)
      onChange(currentDate)
    }
  }

  useEffect(() => {
    // console.log('dateTime => ', dateTime)
    // Update the document title using the browser API
    setNewDateTime(dateTime)
    // @ts-ignore
    timePickerRef.current.onInputClick()
  })
  // console.log('didMount => ', didMount)
  // if (!didMount && !_.isNil(timePickerRef.current)) {
  //   setDidMount(true)
  //   // @ts-ignore
  //   timePickerRef.current.onInputClick()
  // }
  // console.log('timePickerRef ', timePickerRef)
  return (
    <View style={{ width: 0, height: 0, zIndex: 999999 }}>
      <DatePicker
        customInput={<View style={{ display: 'none' }} />}
        ref={timePickerRef}
        selected={newDateTime}
        onChange={onChangeTime}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={1}
        onClickOutside={() => onChangeTime(newDateTime)}
        // calendarContainer={CalendarContainer}
        popperModifiers={{
          flip: {
            // เอาไว้บอกตัว popper ว่าเราจะจำกัดขอบเขตไว้แค่ช่วงของใน Browser
            boundariesElement: 'window',
          },
        }}
      />
    </View>
  )
}

export default TimePickerWeb
