import './wdyr' // <--- first import
import './config/platform-specific-configurator'

// import 'react-native-gesture-handler'

import React from 'react'
// import ReactDOM from 'react-dom/client'
import { createRoot } from 'react-dom/client'
import { Clipboard } from 'react-native'
// import './index.css';

// override style
import './global-style.css'

// Import css for alert function
import 'react-confirm-alert/src/react-confirm-alert.css'
// Import for react-image-gallery
import 'react-image-gallery/styles/css/image-gallery.css'
import Modal from 'react-modal'

// import 'normalize.css'

// Import for react-datepicker:: https://github.com/Hacker0x01/react-datepicker
import 'react-datepicker/dist/react-datepicker.css'
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import localeTH from 'date-fns/locale/th'
import time from 'x/utils/time'

import { toast } from 'react-toastify'
import { initializeAppConfiguration } from 'x/config/settings'
import { MODE } from 'x/config/mode'
import AppView from './AppView'
import * as serviceWorker from './serviceWorker'
import 'react-toastify/dist/ReactToastify.css'
import * as fontLoader from './utils/fontLoader'

time.init()

// Modified from example at: https://developers.google.com/web/updates/2018/03/clipboardapi
Clipboard.getString = async (): Promise<string> => {
  try {
    // @ts-ignore
    const text = await navigator.clipboard.readText()
    // console.log('Pasted content: ', text)
    return text
  } catch (err) {
    // console.error('Failed to read clipboard contents: ', err)
    return ''
  }
}

// react-datepicker:: Init locale
registerLocale('th', localeTH)
setDefaultLocale('th')

// react-toastify:: Call it once in your app. At the root of your app is the best place
toast.configure()

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root')

// ตั้งค่า API Target..
initializeAppConfiguration()

// inject font ลง html document
fontLoader.injectFont()

// console.log('MODE => ', MODE)

// https://github.com/facebook/react-devtools/issues/191
const disableReactDevTools = (): void => {
  const noop = (): void => undefined
  const DEV_TOOLS = (window as any).__REACT_DEVTOOLS_GLOBAL_HOOK__

  if (typeof DEV_TOOLS === 'object') {
    for (const [key, value] of Object.entries(DEV_TOOLS)) {
      DEV_TOOLS[key] = typeof value === 'function' ? noop : null
    }
  }
}

if (MODE !== 'dev') {
  disableReactDevTools()
}

// AppRegistry.registerComponent('App', () => AppView)
// AppRegistry.runApplication('App', { rootTag: document.getElementById('root') })

// const rootTag = createRoot(document.getElementById('root') ?? document.getElementById('main'));
// rootTag.render(createElement(App));

// const rootTag = ReactDOM.createRoot(document.getElementById('root'))
// rootTag.render(
//   // <React.StrictMode>
//   <AppView />
//   // </React.StrictMode>
// )

// https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html#updates-to-client-rendering-apis
const container = document.getElementById('root')
const root = createRoot(container) // createRoot(container!) if you use TypeScript
root.render(<AppView />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
serviceWorker.register()
