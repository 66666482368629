import React from 'react'
import { Platform } from 'react-native'
import { Switch, SwitchProps } from 'react-native-switch'
import { COLORS } from 'x/config/styles'

const isWeb = Platform.OS === 'web'
const injectWebSwitchProps = isWeb
  ? {
      circleSize: 20,
      barHeight: 22,
      // activeText: '',
      // inActiveText: '',
      switchLeftPx: 2,
      switchRightPx: 2,
      changeValueImmediately: true,
    }
  : {}

export default class XSwitch extends React.PureComponent<SwitchProps> {
  render() {
    return (
      <Switch
        // disabled={disabled}
        circleSize={18}
        barHeight={19}
        // value={switchValue}
        // onValueChange={onToggle}
        circleBorderWidth={0.5}
        circleBorderActiveColor={COLORS.TEXT_INACTIVE}
        backgroundActive={this.props.disabled ? COLORS.TEXT_INACTIVE : COLORS.APP_MAIN}
        backgroundInactive={COLORS.TEXT_INACTIVE}
        circleActiveColor={COLORS.WHITE}
        circleInActiveColor={COLORS.WHITE}
        changeValueImmediately={true}
        // circleInActiveColor={COLORS.BG_LIGHT_GREY_ALTERNATIVE}
        activeText=''
        inActiveText=''
        {...injectWebSwitchProps}
        {...this.props}
      />
    )
  }
}
