/* eslint-disable @typescript-eslint/no-misused-promises */
import React from 'react'
// import p from 'x/config/platform-specific'
import BaseMultiParcelView, { IParcel } from 'x/modules/order/BaseMultiParcelView'
// import { CHANNEL_PICK } from './BaseUIChannelPickView'
import XCustomHeader from 'xui/components/XCustomHeader'
// import { Input } from 'react-native-elements'
import HelpButton from 'xui/components/HelpButton'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import _ from 'lodash'
import XCard from 'xui/components/XCard'
import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import XText from 'xui/components/XText'
import XButton from 'xui/components/XButton'
import XIcon from 'xui/components/XIcon'
import * as NavActions from 'x/utils/navigation'
import { COLORS } from 'x/config/styles'
import XIconButton from 'xui/components/XIconButton'
import FastImage from 'react-native-fast-image'
import { IXShipping } from 'x/index'
import XShipping from 'xui/components/XShipping'
import p from 'x/config/platform-specific'
import Box from 'xui/components/Box'
import XSpinner from 'xui/components/XSpinner'
import XHelpModal from 'xui/components/XHelpModal'
import FAQ from 'x/config/FAQ'
import XOverlay from 'xui/components/XOverlay'
import { TouchableOpacity, Platform } from 'react-native'
import XInput from 'xui/components/XInput'

const _renderAgreement = (onPressOpenOverlay: () => void) => <HelpButton onPress={() => onPressOpenOverlay()} />

export default abstract class BaseUIMultiParcelView extends BaseMultiParcelView {
  renderCustomHeader = () => {
    let leftBtn = {
      backIcon: true,
      onPressItem: () => this._goBack(),
    }
    let rightBtn = null
    const { order, isHaveChange } = this.state
    let titleHeader = 'จัดการหลายพัสดุ'
    if (!_.isNil(order)) {
      titleHeader = `จัดการหลายพัสดุ ออเดอร์ #${order.id}`
    }
    if (isHaveChange) {
      leftBtn = null
      rightBtn = {
        // @ts-ignore
        label: 'ยกเลิก',
        onPressItem: () =>
          p.op.showConfirmation(
            '',
            'คุณมีรายการพัสดุที่ยังไม่ได้บันทึก ต้องการแก้ไขต่อใช่หรือไม่',
            () => null,
            () => this._goBack(),
            'แก้ไขต่อ',
            'ละทิ้งการแก้ไข'
          ),
      }
    }
    return <XCustomHeader title={titleHeader} headerLeftProps={leftBtn} headerRightProps={rightBtn} />
  }

  _checkMultiParcelCount = () => {
    const { multiParcel } = this.state
    let countTotal = 0
    if (!_.isNil(multiParcel)) {
      multiParcel.forEach((parcel) => {
        parcel.items.forEach((itam) => {
          countTotal += itam.qty
        })
      })
    }
    return countTotal
  }

  _renderCountProductView = () => {
    const { amountQty, multiParcel } = this.state
    if (_.isNil(amountQty) || _.isNil(multiParcel)) {
      return null
    }

    const countTotal = this._checkMultiParcelCount()
    return (
      <VStack>
        <HStack w='full'>
          <XText bold flex={1}>
            จำนวนพัสดุที่ระบุพัสดุแล้ว
          </XText>
          <XText bold flex={1} textAlign='right'>
            {`${multiParcel.length}`}
          </XText>
        </HStack>
        <HStack w='full'>
          <XText bold flex={2}>
            จำนวนสินค้าที่ระบุพัสดุแล้ว
          </XText>
          <XText bold flex={1} textAlign='right'>
            {`${countTotal}/${amountQty}`}
          </XText>
        </HStack>
      </VStack>
    )
  }

  _renderBtnAutoMultiParcel = () => (
    <HStack w='full' justifyContent='flex-end'>
      <XButton variant='outline' onPress={() => this._showOverlayDivideParcels()}>
        แบ่งหลายพัสดุอัตโนมัติ
      </XButton>
      <XHelpModal
        key='แบ่งหลายพัสดุอัตโนมัติ'
        headerTitle='แบ่งหลายพัสดุอัตโนมัติ'
        FAQ={FAQ.MULTI_PARCEL_CANCEL_ARRANGEMENT}
        initiateOpenIndex={[0, 1]}
        renderButton={(onPressOpenOverlay: () => void) => _renderAgreement(onPressOpenOverlay)}
      />
    </HStack>
  )

  _renderRequestXShipping = () => {
    const { order, multiParcel } = this.state
    if (_.isNil(order)) {
      return null
    }
    if (_.isNil(multiParcel) || multiParcel.length < 1) {
      return null
    }
    const xshipping = _.has(order, 'x')
    // console.log('xshipping => ', xshipping)
    let countRequestXShipping = 0
    multiParcel.forEach((parcel) => {
      if (_.has(parcel, 'x')) {
        const xshippingInParcel = parcel.x
        if (xshippingInParcel.r && xshippingInParcel.r === 1) {
          countRequestXShipping += 1
        }
      }
    })
    if (countRequestXShipping === 0) {
      if (xshipping) {
        const orderXShipping = order.x
        if (!_.has(orderXShipping, 'r') || orderXShipping.r !== 1) {
          return null
        }
      } else {
        return null
      }
    }
    let textLabel = 'ขอเลขพัสดุทันที'
    if (countRequestXShipping !== 0) {
      textLabel = `ขอ ${countRequestXShipping} เลขพัสดุทันที`
    }

    // ถ้ายังไม่ complete ไม่ต้องแสดงปุ่ม
    if (!this._completeAll()) {
      return null
    }
    return (
      <HStack w='full'>
        <XButton variant='ghost' bgColor={COLORS.APP_SECONDARY} onPress={() => this._createTackingCode()}>
          {textLabel}
        </XButton>
        {/* <HelpButton style={{ marginLeft: 8 }} onPress={() => console.log('//// => ')} /> */}
      </HStack>
    )
  }

  _renderBtnDeleteParcels = () => {
    const { isShowBtnDeleteParcels } = this.state
    if (!isShowBtnDeleteParcels) {
      return null
    }
    return (
      <HStack w='full'>
        <XButton bgColor={COLORS.RED} onPress={() => this._deleteAllParcel()}>
          ยกเลิกการแบ่งพัสดุทั้งหมด
        </XButton>
        <XHelpModal
          key='ยกเลิกการแบ่งพัสดุทั้งหมด'
          headerTitle='ยกเลิกการแบ่งพัสดุทั้งหมด'
          FAQ={FAQ.MULTI_PARCEL_CANCEL_ARRANGEMENT}
          initiateOpenIndex={[0, 1, 2]}
          renderButton={(onPressOpenOverlay: () => void) => _renderAgreement(onPressOpenOverlay)}
        />
      </HStack>
    )
  }

  _renderEmptyParcel = () => {
    const { order, multiParcel } = this.state
    if ((!_.isNil(multiParcel) && multiParcel.length > 0) || _.isNil(order)) {
      return null
    }
    const text = `ในกรณีที่คุณต้องการแพ็กหลายพัสดุ สำหรับสินค้าที่รอจัดส่งทั้งหมดในออเดอร์ด้วยตัวเอง คุณสามารถระบุ "สินค้า" และ "จำนวน" ของแต่ละพัสดุเพิ้อ "เพิ่มใบปะหน้าพัสดุ" และ "ขอเลขติตามพัสดุ" โดยกดปุ่ม\n"ระบุสินค้าสำหรับพัสดุ #${order.id}" ด้านล่าง`
    return (
      <HStack>
        <XText>{text}</XText>
      </HStack>
    )
  }

  _onPrassFooter = (parcelIndex: number) => {
    this.props.navigation.dispatch(
      NavActions.navToSelectedProductMultiParcelView({
        order: this.state.order,
        parcels: this.state.multiParcel,
        parcelIndex,
        callBack: (parcels: IParcel[]) => this._callBackFormSelectedProduct(parcels),
      })
    )
  }

  // _completeAll หมายถึงว่า multiParcel ตอนนี้เป็นล่าสุดจากหลังบ้าน  หน้าบ้านยังไม่มีการแก้ไขอะไร
  _completeAll = () => {
    const { multiParcel } = this.state
    if (_.isNil(multiParcel)) {
      return false
    }
    let isComplete = true
    multiParcel.forEach((parcel) => {
      if (!_.has(parcel, 'isHaveDeleteBtn')) {
        isComplete = false
      }
    })
    return isComplete && multiParcel.length > 0
  }

  _renderFooter = () => {
    const { order, multiParcel, amountQty } = this.state
    if (_.isNil(order) || _.isNil(multiParcel)) {
      return null
    }
    const countTotal = this._checkMultiParcelCount()
    const isReadyToSubmit = amountQty === countTotal
    const multiParcelNextCount = multiParcel.length + 1
    let text = `ระบุสินค้าสำหรับพัสดุ #${multiParcelNextCount}`
    if (isReadyToSubmit) {
      text = 'ยืนยันการระบุพัสดุทั้งหมด'
    }
    const disable = this._completeAll()
    if (disable) {
      return null
    }
    return (
      <XButton
        disabled={disable}
        // variant={disable ? 'unstyled' : 'solid'}
        bgColor={disable ? COLORS.BG_LIGHT_GREY : COLORS.APP_MAIN}
        p='2'
        mx='2'
        my='1'
        onPress={() => (isReadyToSubmit ? this._submitMultiParcel() : this._onPrassFooter(multiParcelNextCount))}>
        <XText color={disable ? COLORS.TEXT_INACTIVE : COLORS.WHITE}>{text}</XText>
      </XButton>
    )
  }

  _renderIconArrowdown = () => {
    const { order, multiParcel } = this.state
    if (!_.isNil(multiParcel) && multiParcel.length > 0) {
      return null
    }
    return (
      <HStack w='full' justifyContent='center' alignItems='center'>
        <XIcon size='xl' variant='inactive' name='arrow-down' family='Ionicons' />
      </HStack>
    )
  }

  _renderProductListView = (itam: { pp_id: number; qty: number }, idx) => {
    const { products } = this.state
    if (_.isNil(products)) {
      return null
    }
    const product = _.find(products, (pt) => pt.pp_id === itam.pp_id)
    const { name } = product
    const isEven = idx % 2 === 0
    const SKU = product.sku ? product.sku : null
    const UPC = product.upc ? product.upc : null
    const countItam = itam.qty
    return (
      <HStack
        w='full'
        key={product.pp_id}
        py='2'
        borderBottomWidth='1'
        borderBottomColor={COLORS.TEXT_INACTIVE}
        bgColor={isEven ? COLORS.BG_LIGHT_GREY : COLORS.WHITE}>
        {/* <HStack w='10' borderRightWidth='1' borderRightColor={COLORS.TEXT_INACTIVE} justifyContent='center' alignItems='center'>
          <TouchableOpacity>
            <XIcon name='checkbox-blank-circle-outline' family='MaterialCommunityIcons' color={COLORS.TEXT_INACTIVE} />
          </TouchableOpacity>
        </HStack> */}
        <HStack px='2' flex={1}>
          <FastImage style={{ width: 44, height: 44 }} source={{ uri: product.thumbnail_uris[0] }} resizeMode='cover' />
          <VStack px='1' mr='4'>
            <XText numberOfLines={1}>{name}</XText>
            <XText variant='inactive'>{`ตัวเลือก: ${product.variant}`}</XText>
            {!_.isNil(SKU) ? <XText variant='inactive'>{`SKU: ${product.variant}`}</XText> : null}
            {!_.isNil(UPC) ? <XText variant='inactive'>{`UPC: ${product.variant}`}</XText> : null}
          </VStack>
        </HStack>
        <VStack w='12' justifyContent='center' alignItems='center'>
          <XText bold>{`x${countItam}`}</XText>
          {/* <TouchableOpacity onPress={() => null} style={{ marginTop: 4, flex: 1, alignItems: 'center', width: '100%' }}>
            <XIcon name='edit' family='FontAwesome' />
          </TouchableOpacity> */}
        </VStack>
      </HStack>
    )
  }

  _renderXShipping = (x: IXShipping) => {
    const { order } = this.state
    const { selectedStore } = this.props
    if (_.isNil(order) || _.isNil(x)) {
      return null
    }
    const order_id = order.id
    const store_id = selectedStore.get('id')
    const orderState = order.state ? order.state : null
    return (
      <VStack mx='2' pb='2' mt='2'>
        <XShipping data={x} orderId={order_id} storeId={store_id} limitRes={1} orderStatus={orderState} />
      </VStack>
    )
  }

  _rendermultiParcel = () => {
    const { multiParcel, order } = this.state
    if (_.isNil(multiParcel)) {
      return null
    }

    // console.log('multiParcel => // ', multiParcel)
    return (
      <VStack space='2'>
        {multiParcel.map((parcel, index) => {
          // console.log('parcel => ', parcel)
          const { items } = parcel
          const parcelIndex = index + 1
          const isHaveDeleteBtn = _.has(parcel, 'isHaveDeleteBtn') ? parcel.isHaveDeleteBtn : true
          let countTotal = 0
          items.forEach((i) => {
            countTotal += i.qty
          })
          return (
            <XCard key={`parcel${parcelIndex}`} w='full'>
              <HStack mt='2' borderBottomColor={COLORS.TEXT_INACTIVE} borderBottomWidth='1' w='full'>
                <VStack flex={1} px='2'>
                  <XText variant='active' bold fontSize='lg'>{`พัสดุ #${parcelIndex}`}</XText>
                  <XText>{`จำนวนสินค้า: ${countTotal}`}</XText>
                </VStack>
                <HStack alignItems='center' justifyContent='center' h='10' w='10'>
                  {isHaveDeleteBtn ? (
                    <XIconButton
                      onPress={() => this._deleteParcel(index)}
                      family='AntDesign'
                      name='delete'
                      pl='1'
                      pr='1'
                      py='2'
                      colorScheme='danger'
                      variant='outline'
                    />
                  ) : null}
                </HStack>
              </HStack>
              {this._renderXShipping(parcel.x)}
              {items.map((itam, idx) => this._renderProductListView(itam, idx))}
            </XCard>
          )
        })}
      </VStack>
    )
  }

  _renderDescriptionForCreateParcels = () => {
    const { multiParcel, amountQty } = this.state
    if (_.isNil(multiParcel) || multiParcel.length < 1) {
      return null
    }
    const countTotal = this._checkMultiParcelCount()
    const isReadyToSubmit = amountQty === countTotal
    const multiParcelNextCount = multiParcel.length + 1
    if (isReadyToSubmit) {
      return null
    }
    return (
      <HStack>
        <XText>{`กรุณากดปุ่ม "ระบุสินค้าสำหรับพัสดุ #${multiParcelNextCount}" ด้านล่าง เพื่อระบุ "สินค้า" และ "จำนวน"  ให้ครบถ้วน `}</XText>
      </HStack>
    )
  }

  _renderOverlayDivideParcels = () => {
    const { isVisibleOverlayDivideParcels, multiParcel, inputDivideParcels, amountQty } = this.state
    if (_.isNil(multiParcel) || _.isNil(amountQty)) {
      return null
    }
    const countTotal = this._checkMultiParcelCount()

    const maxSelectedProduct = amountQty - countTotal
    const titleOverlay = 'เกลี่ยรายการและจำนวนสินค้าที่ยังไม่ได้ระบุพัสดุให้อัตโนมัติตามจำนวนพัสดุที่ระบุ'

    const _onChangeInputQtyText = (newText: string) => {
      // setInputQtyText(newText)
      // const newSelectedProduct = _.cloneDeep(selectedProduct)
      // newSelectedProduct.qty = +newText
      this.setState({
        // @ts-ignore
        inputDivideParcels: +newText > maxSelectedProduct ? maxSelectedProduct : +newText,
      })
    }
    const inPutValue = `${inputDivideParcels}`

    return (
      <XOverlay
        // contentStyle={{ padding: 8 }}
        contentStyle={{
          width: 300,
          minHeight: 220,
          height: p.op.isWeb() ? 220 : undefined,
          // avoid keyboard style
          ...Platform.select({
            ios: {
              marginBottom: 200,
            },
            // android: {
            //   marginBottom: 35,
            // },
          }),
        }}
        visible={isVisibleOverlayDivideParcels}
        onRequestClose={() => this._closeOverlayDivideParcels()}>
        <XCustomHeader
          headerRightProps={{ closeIcon: true, onPressItem: () => this._closeOverlayDivideParcels() }}
          title='ระบุจำนวนพัสดุที่ต้องการ'
        />
        <VStack justifyContent='center' alignItems='center' mt='2'>
          <XText variant='inactive' textAlign='center' px='2'>
            {titleOverlay}
          </XText>
          <HStack h='9' mt='1'>
            <XInput
              placeholder='ระบุจำนวน..'
              autoFocus
              textAlign='center'
              focusable
              isInteger
              style={{ flex: 1, maxWidth: 120 }}
              value={inPutValue}
              onChangeText={_onChangeInputQtyText}
              // onSubmitEditing={_onSubmitInputQty}
            />
          </HStack>
          <XText variant='inactive' mt='1'>
            {`(ระบุจำนวนระหว่าง 0-${maxSelectedProduct})`}
          </XText>
          <HStack mt='2'>
            <TouchableOpacity
              onPress={() => this._submitDivideParcels()}
              style={{
                backgroundColor: COLORS.APP_MAIN,
                borderRadius: 7,
                height: 34,
                width: 80,
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <XText variant='activeDark' bold>
                ยืนยัน
              </XText>
            </TouchableOpacity>
          </HStack>
        </VStack>
      </XOverlay>
    )
  }

  render() {
    const { amountQty, isSubmitting } = this.state
    const countTotal = this._checkMultiParcelCount()

    let BG_COLOR = COLORS.BG_LIGHT_GREY
    if (amountQty !== 0 && countTotal !== 0 && amountQty === countTotal) {
      BG_COLOR = this._completeAll() ? COLORS.FORM_SUCCESS_BG : COLORS.FORM_WARNING_BG
    }
    return (
      <XContainer>
        {this.renderCustomHeader()}
        {this._renderOverlayDivideParcels()}
        <XContent
          p='2'
          // _light={{
          //   bg: BG_COLOR,
          // }}
        >
          <XCard w='full' p='2' mb='2' bgColor={BG_COLOR}>
            <VStack w='full' space='2'>
              {this._renderCountProductView()}
              {/* {this._renderBtnAutoMultiParcel()} */}
              {this._renderRequestXShipping()}
              {this._renderEmptyParcel()}
              {this._renderIconArrowdown()}
              {this._rendermultiParcel()}
              {this._renderDescriptionForCreateParcels()}
              {this._renderBtnDeleteParcels()}
            </VStack>
          </XCard>
        </XContent>
        {this._renderFooter()}
        {/* {this._loading()} */}
        {isSubmitting ? (
          <Box
            bgColor='gray.500:alpha.50'
            position='absolute'
            top='0'
            left='0'
            right='0'
            bottom='0'
            alignItems='center'
            justifyContent='center'>
            <XSpinner size='lg' />
          </Box>
        ) : null}
      </XContainer>
    )
  }
}
