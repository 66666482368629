import React from 'react'
import { Keyboard } from 'react-native'
// import { delay } from 'x/utils/util'
// import api from 'x/utils/api'
// import * as auth from 'x/utils/authentication'

import Yup, { USERNAME, isRequired } from 'x/utils/validator'
// import { log } from 'x/utils/util'
// import _ from 'lodash'
// import { call } from 'redux-saga/effects'
import api from 'x/utils/api'
import * as NavActions from 'x/utils/navigation'
import { FormikHelpers as FormikActions } from 'formik'
import _ from 'lodash'
import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'

import XFormikInput from 'xui/components/XFormikInput'
import XText from 'xui/components/XText'
import BaseOnboardingView from './BaseOnboardingView'

export default class OnboardingUsernameView extends BaseOnboardingView {
  static displayName = 'OnboardingUsernameView'

  constructor(props) {
    super(props)
    this.initialValues = {
      username: '',
    }

    this.validationSchema = Yup.object().shape({
      username: isRequired(USERNAME),
    })

    this.txtHeaderTitle = 'สร้างชื่อผู้ใช้'
    this.txtBtnFooter = 'ต่อไป'
  }

  componentDidMount() {
    setTimeout(this._autoFocusFirstInput, 1500)
  }

  renderContent(): React.ReactNode {
    return (
      <VStack w='full' space='1'>
        <HStack w='full' p='2' justifyContent='center' alignItems='center'>
          {this._renderHeaderTitle()}
        </HStack>

        <XText variant='inactive'>Username</XText>
        <XFormikInput
          flex={1}
          autoCapitalize='none'
          style={this.styles.input}
          name='username'
          placeholder='Username นี้เปลื่ยนภายหลังไม่ได้'
          order={4}
          handleRegisterRef={this._doRegisterRef}
          // handleOnSubmitEditing={fProps.handleSubmit} // หลีกเลี่ยงการกดพลาด
          returnKeyType='done'
        />
      </VStack>
    )
  }

  async handleSubmitForm(values: any, formActions: FormikActions<any>): Promise<void> {
    Keyboard.dismiss()

    const { navigation } = this.props

    const body = values
    const apiOptions = {
      messages: {
        successMsg: 'การลงทะเบียนชื่อผู้ใช้เสร็จสิ้น',
        errorMsg: 'เกิดข้อผิดพลาดในการลงทะเบียนชื่อผู้ใช้',
      },
      showSpinner: true,
    }
    try {
      const res = await api.patch(api.PATCH_USERNAME, body, apiOptions)
      // log('handleSubmitForm res => ', res)
      // if (res && res.missing !== 'store') {
      //   navigation.dispatch(NavActions.switchToAppLauncher)
      //   // log('No missing store anymore')
      //   return
      // }
      // navigation.navigate('OnboardingFirstStoreNameView')
      if (_.has(res, 'missing') && res.missing === 'store') {
        navigation.navigate('OnboardingFirstStoreNameView')
      } else if (_.has(res, 'missing') && res.missing === 'email') {
        navigation.navigate('OnboardingEmailView')
      } else {
        // navigation.dispatch(NavActions.switchToAuthStackNavigator)
        navigation.dispatch(NavActions.resetToAuthStackNavigator())
      }
    } catch (err) {
      // log('handleSubmitForm err => ', err)
      // navigation.dispatch(NavActions.switchToAppLauncher)
      formActions.resetForm()
    }

    // log(JSON.stringify(values, null, 2))
    // log('formActions => ', formActions)
    // try {
    //   const response = await api.post(api.POST_SIGNUP, values, { showSpinner: true, noToken: true })
    //
    //   log('handleSubmitForm::response => ', response)
    //   log(JSON.stringify(values, null, 2))
    //   if (_.has(response, 'status') && response.status === 'ok') {
    //     if (_.has(response, 'token') && _.isString(response.token)) {
    //       let receivedToken = response.token ? response.token : ''
    //       receivedToken = receivedToken.replace(/Bearer/g, '').trim()
    //       await auth.setAuthenticationToken(receivedToken)
    //       Keyboard.dismiss()
    //       formActions.resetForm()
    //       this.props.navigation.navigate('screen2')
    //     } else {
    //       await delay(1500)
    //       formActions.setSubmitting(false)
    //     }
    //   } else {
    //     throw new Error('Its no have the expecting values.')
    //   }
    // } catch (err) {
    //   log('handleSubmitForm::err')
    //   log(err)
    //   await delay(1500)
    //   formActions.setSubmitting(false)
    // }
  }
}
