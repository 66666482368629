import React from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'
import _ from 'lodash'
import { INavHeaderButtonProps } from 'x/index'
import AddIcon from './AddIcon'
import SettingIcon from './SettingIcon'
import BackIcon from './BackIcon'
import XIcon, { XIconFamily } from './XIcon'
import HStack from './HStack'
import XIconButton from './XIconButton'
import XText from './XText'

// const BTN_DEFAULT_STYLE = {
//   backgroundColor: 'transparent',
//   shadowColor: null,
//   shadowOffset: null,
//   shadowOpacity: null,
//   shadowRadius: null,
//   paddingTop: 0,
//   paddingLeft: 0,
//   paddingRight: 0,
//   paddingBottom: 0,
// }

export default class NavHeaderButton extends React.Component<INavHeaderButtonProps> {
  _onPressItem = () => {
    const { onPressItem } = this.props
    if (_.isFunction(onPressItem)) {
      onPressItem()
    }
  }

  // _renderAddIcon = () => (this.props.addIcon && !this.props.label ? <AddIcon inactive={this.props.submitting} /> : null)

  // _renderSettingIcon = () => {
  //   return this.props.settingIcon && !this.props.label ? <SettingIcon inactive={this.props.submitting} /> : null
  // }

  // _renderBackIcon = () => (this.props.backIcon && !this.props.label ? <BackIcon inactive={this.props.submitting} /> : null)

  // _renderCloseIcon = () => (this.props.closeIcon && !this.props.label ? <Icon name='close-circle' /> : null)

  _renderIcon = () => {
    const { addIcon, backIcon, closeIcon, settingIcon, submitting } = this.props
    if (addIcon) {
      return <AddIcon inactive={submitting} />
    }
    if (backIcon) {
      return <BackIcon variant={submitting ? 'inactive' : 'primary'} />
    }
    if (closeIcon) {
      return <XIcon name='close-circle' />
    }
    if (settingIcon) {
      return <SettingIcon inactive={submitting} />
    }

    return null
  }

  _renderIconButton = () => {
    const { addIcon, backIcon, closeIcon, settingIcon, submitting, style = {}, iconButtonProps = {} } = this.props

    let iconName: string
    const iconFamily: XIconFamily = 'Ionicons'

    if (addIcon) {
      iconName = 'add'
    }

    if (backIcon) {
      iconName = 'arrow-back'
    }

    if (closeIcon) {
      iconName = 'close-circle'
    }

    if (settingIcon) {
      iconName = 'settings'
    }

    return (
      <XIconButton
        disabled={submitting}
        isDisabled={submitting}
        colorScheme={submitting ? 'muted' : 'primary'}
        name={iconName}
        family={iconFamily}
        // variant={submitting ? 'inactive' : 'primary'}
        // iconVariant={submitting ? 'inactive' : 'primary'}
        // _icon={{
        //   variant: submitting ? 'inactive' : 'primary',
        // }}
        onPress={this._onPressItem}
        style={StyleSheet.flatten(style)}
        {...iconButtonProps}
      />
    )
  }

  // _renderLabel = () =>
  //   _.isString(this.props.label) ? (
  //     <Text
  //       style={{
  //         flex: 1,
  //         width: '100%',
  //         minWidth: 44,
  //         paddingHorizontal: 4,
  //         fontSize: STYLES.FONT_SIZE_NORMAL,
  //         fontWeight: 'bold',
  //         color: this.props.submitting ? COLORS.TEXT_INACTIVE : COLORS.APP_MAIN,
  //         textAlign: 'center',
  //       }}>
  //       {this.props.label}
  //     </Text>
  //   ) : null

  renderButton = () => {
    const { submitting, label, addIcon, settingIcon, backIcon, closeIcon, style = {}, children = null, ...restProps } = this.props
    if (_.isEmpty(this.props) || (!label && !addIcon && !backIcon && !closeIcon && !settingIcon && !children)) {
      return null
    }

    if (!label) {
      // return (
      //   <XButton
      //     variant='ghost'
      //     // minW='9'
      //     // maxW='12'
      //     isDisabled={submitting}
      //     disabled={submitting}
      //     onPress={this._onPressItem}
      //     leftIcon={this._renderIcon()}
      //     // borderRadius='full'
      //     // _icon={{
      //     //   color: 'primary.500',
      //     //   size: 'md',
      //     // }}
      //     // _hover={{
      //     //   bg: 'primary.600:alpha.20',
      //     // }}
      //     // _ios={{
      //     //   _icon: {
      //     //     size: '2xl',
      //     //   },
      //     // }}
      //   >
      //     {/* {this._renderIcon()} */}
      //   </XButton>
      // )

      return this._renderIconButton()
    }

    // return (
    //   <XButton
    //     isDisabled={submitting}
    //     disabled={submitting}
    //     // variant='ghost'
    //     // minH='10'
    //     variant='outline'
    //     // h='full'
    //     onPress={this._onPressItem}
    //     // style={StyleSheet.flatten(style)}
    //     //  {...restProps}
    //   >
    //     {label}
    //   </XButton>
    // )

    return (
      <TouchableOpacity
        disabled={submitting}
        onPress={this._onPressItem}
        style={StyleSheet.flatten(style)}
        //  {...restProps}
      >
        <XText px='1' py='1' bold variant={submitting ? 'inactive' : 'primary'}>
          {label}
        </XText>
      </TouchableOpacity>
    )

    // return (
    //   <Button
    //     disabled={submitting}
    //     variant='ghost'
    //     // @ts-ignore
    //     style={StyleSheet.flatten([BTN_DEFAULT_STYLE, style])}
    //     // leftIcon={this._renderIcon()}
    //     onPress={this._onPressItem}>
    //     {/* {this._renderAddIcon()} */}
    //     {/* {this._renderSettingIcon()} */}
    //     {/* {this._renderBackIcon()} */}
    //     {/* {this._renderCloseIcon()} */}
    //     {/* {this._renderLabel()} */}
    //     {this.props.label}
    //     {children}
    //   </Button>
    // )
  }

  render() {
    const { submitting, label, addIcon, settingIcon, backIcon, closeIcon, style = {}, children = null, ...restProps } = this.props
    if (_.isEmpty(this.props) || (!label && !addIcon && !backIcon && !closeIcon && !settingIcon && !children)) {
      return null
    }
    return (
      <HStack minW='12' maxW='120px' minH='10' alignItems='center' justifyContent='center'>
        {this.renderButton()}
      </HStack>
    )
  }
}
