import React, { PureComponent } from 'react'

// import * as util from 'x/utils/util'
import { IProductItemData, IXButtonProps } from 'x/index'
import XButton from 'xui/components/XButton'

interface IAddProductToSalePageButtonProps extends IXButtonProps {
  data: IProductItemData
  // isQuantityAvailable?: boolean
  // isPriceAvailable?: boolean
}

// const DEFAULT_ADD_ITEM_QUANTITY = util.getQuickAddProductQuantity()
// const QUICK_ADD_PRODUCT_TO_ORDER_LABEL = `เพิ่ม ${DEFAULT_ADD_ITEM_QUANTITY} ชิ้นในออเดอร์...`
// const OUT_OF_STOCK_LABEL = 'สินค้าหมด'
// const NOT_NAME_PRICE_YET_LABEL = 'ยังไม่ได้กำหนดราคา'
// const IS_LOADING_LABEL = 'กำลังโหลด...'

class AddProductToSalePageButton extends PureComponent<IAddProductToSalePageButtonProps> {
  render() {
    const { data, ...restProps } = this.props
    // const isMyProduct = data.m || false

    // const _getSumAvailableQty = () => {
    //   const variants = data.v || []
    //   let qty = data.q
    //   if (_.isNull(qty)) {
    //     const sumAvailableQty = variants.reduce((prevSum, vItem) => prevSum + vItem.q, 0)
    //     qty = sumAvailableQty
    //   }

    //   if (qty < 0 && !isMyProduct) {
    //     qty = 0
    //   }
    //   return qty
    // }

    // const priceMin = data.i ? parseFloat(data.i) : null
    // const priceMax = data.x ? parseFloat(data.x) : null

    // const computedAvailableQty = _getSumAvailableQty()
    // const isQuantityAvailable = computedAvailableQty > 0
    // const isPriceAvailable = _.isNumber(priceMin) || _.isNumber(priceMax)

    // // @ts-ignore
    // const isLoading = data.isEmpty ? data.isEmpty : null

    // let isQuickAddAvailable = true
    // let productButtonLabel = QUICK_ADD_PRODUCT_TO_ORDER_LABEL

    // if (!isQuantityAvailable) {
    //   isQuickAddAvailable = false
    //   productButtonLabel = OUT_OF_STOCK_LABEL
    // }
    // if (!isPriceAvailable) {
    //   isQuickAddAvailable = false
    //   productButtonLabel = NOT_NAME_PRICE_YET_LABEL
    // }
    // if (isLoading) {
    //   isQuickAddAvailable = false
    //   productButtonLabel = IS_LOADING_LABEL
    // }

    return (
      <XButton
        w='full'
        _text={{ numberOfLines: 1 }}
        // disabled={!isQuickAddAvailable}
        // isDisabled={!isQuickAddAvailable}
        {...restProps}>
        {/* {productButtonLabel} */}
        เลือก
      </XButton>
    )
  }
}

export default AddProductToSalePageButton
