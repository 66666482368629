import PropTypes from 'prop-types'
import React, { Component } from 'react'
import BaseUISettingEmailView from 'xui/modules/setting/BaseUISettingEmailView'
export default class SettingEmailView extends BaseUISettingEmailView {
  static displayName = 'SettingEmailView'

  static navigationOptions = ({ navigation }) => ({
    header: null,
  })
}
