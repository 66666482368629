import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
// import { NavigationActions } from 'react-navigation'
import * as ProfileActions from 'x/modules/profile/ProfileState'
import { getSubscription, getSelectedStore } from 'x/redux/selectors'
import SettingView from './SettingView'

export default connect(
  (state) => ({
    profile: state.get('profile'),
    subscription: getSubscription(state),
    selectedStore: getSelectedStore(state),
  }),
  (dispatch) => ({
    // navigate: bindActionCreators(NavigationActions.navigate, dispatch),
    dispatch,
    fetchUserProfile: bindActionCreators(ProfileActions.fetchUserProfile, dispatch),
  })
)(SettingView)
