import BaseUIReportProductDailySales from 'xui/modules/report/BaseUIReportProductDailySales'
import * as util from '../../utils/util-web'

export default class ReportProductDailySales extends BaseUIReportProductDailySales {
  _handleOnDownloadFile = async (url: string, fileName: string): Promise<void> => {
    // console.log('fileName // => ', fileName)
    // console.log('url // => ', url)
    // await utilN.openFileFromUrl(url, fileName, 'xlsx')
    await util.downloadFileFromUrl({ url, fileName })
  }
}
