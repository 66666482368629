import React from 'react'
import _ from 'lodash'

import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'

import { IXShippingConfig } from 'x/config/app'
import api from 'x/utils/api'
import { Linking } from 'react-native'
import { IXScreenProps } from 'x/types'

interface IResponse {
  link_account_url: string
}

export interface BaseStoreSettingXShippingFlashViewProps extends IXScreenProps {
  selectedStore: any
  getXShippingConfigFromRedux: IXShippingConfig[]
  setXShippingConfig: (newConfig: IXShippingConfig[]) => void
}

export interface BaseStoreSettingXShippingFlashViewState {
  // Flash X Shipjung
  useFlashxShipjung: boolean
  useAutoXShippingIsFlashxShipjungReadyToShip: boolean
  useFlashxShipjungCOD: boolean
  useAutoXShippingIsFlashxShipjungCODReadyToShip: boolean

  loadingFlashxShipjungWidth: number
  loadingFlashxShipjungHeight: number
  isFlashxShipjungLoading: boolean
  loadingFlashxShipjungCODWidth: number
  loadingFlashxShipjungCODHeight: number
  isFlashxShipjungCODLoading: boolean

  // Flash
  useFlash: boolean
  useAutoXShippingIsFlashReadyToShip: boolean
  useFlashCOD: boolean
  useAutoXShippingIsFlashCODReadyToShip: boolean
  useAutoCreateCODPaymentWhenDelivered: boolean

  loadingFlashWidth: number
  loadingFlashHeight: number
  isFlashLoading: boolean
  loadingFlashCODWidth: number
  loadingFlashCODHeight: number
  isFlashCODLoading: boolean

  isRenderWebView: boolean
  isFlashLinkAccount: string | null
}

// const STATE_KEY_FLASH_X_SHIPJUNG_FOR_UPDATE = {
//   useJAndT: 'useJAndT',
//   useAutoXShippingIsJAndTReadyToShip: 'useAutoXShippingIsJAndTReadyToShip',
//   useJAndTCOD: 'useJAndTCOD',
//   useAutoXShippingIsJAndTCODReadyToShip: 'useAutoXShippingIsJAndTCODReadyToShip',
//   useAutoJAndTCreateCODPaymentWhenDelivered: 'useAutoJAndTCreateCODPaymentWhenDelivered',
// }

const STATE_KEY_FLASH_X_SHIPJUNG_FOR_UPDATE = {
  useFlashxShipjung: 'useFlashxShipjung',
  useAutoXShippingIsFlashxShipjungReadyToShip: 'useAutoXShippingIsFlashxShipjungReadyToShip',
  useFlashxShipjungCOD: 'useFlashxShipjungCOD',
  useAutoXShippingIsFlashxShipjungCODReadyToShip: 'useAutoXShippingIsFlashxShipjungCODReadyToShip',
}

const STATE_KEY_FLASH_FOR_UPDATE = {
  useFlash: 'useFlash',
  useAutoXShippingIsFlashReadyToShip: 'useAutoXShippingIsFlashReadyToShip',
  useFlashCOD: 'useFlashCOD',
  useAutoXShippingIsFlashCODReadyToShip: 'useAutoXShippingIsFlashCODReadyToShip',
  useAutoCreateCODPaymentWhenDelivered: 'useAutoCreateCODPaymentWhenDelivered',
}

export default abstract class BaseStoreSettingXShippingFlashView extends React.Component<
  BaseStoreSettingXShippingFlashViewProps,
  BaseStoreSettingXShippingFlashViewState
> {
  // DISPLAY_TABS: IUserListTab[]
  // _tabRef: React.RefObject<any>
  webview: React.RefObject<any>

  protected constructor(props) {
    super(props)

    this.state = {
      // Flash X Shipjung
      useFlashxShipjung: false,
      useAutoXShippingIsFlashxShipjungReadyToShip: true,
      loadingFlashxShipjungWidth: 0,
      loadingFlashxShipjungHeight: 0,
      isFlashxShipjungLoading: false,
      useFlashxShipjungCOD: false,
      useAutoXShippingIsFlashxShipjungCODReadyToShip: true,
      // useAutoJAndTxShipjungCreateCODPaymentWhenDelivered: true,
      loadingFlashxShipjungCODWidth: 0,
      loadingFlashxShipjungCODHeight: 0,
      isFlashxShipjungCODLoading: false,
      // Flash
      useFlash: false,
      useAutoXShippingIsFlashReadyToShip: false,
      useFlashCOD: false,
      useAutoXShippingIsFlashCODReadyToShip: false,
      useAutoCreateCODPaymentWhenDelivered: false,

      loadingFlashWidth: 0,
      loadingFlashHeight: 0,
      isFlashLoading: false,
      loadingFlashCODWidth: 0,
      loadingFlashCODHeight: 0,
      isFlashCODLoading: false,

      isRenderWebView: false,
      isFlashLinkAccount: null,
    }
    this.webview = React.createRef()
    // this._tabRef = React.createRef()
  }

  async componentDidMount() {
    const { getXShippingConfigFromRedux } = this.props
    // const { state } = navigation
    // const { tabIndex, permissionList, permission_id } = state.params
    await this.setFlashConfig(getXShippingConfigFromRedux)
  }

  setFlashConfig = async (config: IXShippingConfig[]) => {
    if (_.isNil(config)) {
      return
    }
    if (_.isArray(config) && config.length > 0) {
      // Flash x Shipjung
      let useFlashxShipjung = false
      let useAutoXShippingIsFlashxShipjungReadyToShip = false
      let useFlashxShipjungCOD = false
      let useAutoXShippingIsFlashxShipjungCODReadyToShip = false
      // Falsh
      let useFlash = false
      let useAutoXShippingIsFlashReadyToShip = false
      let useFlashCOD = false
      let useAutoXShippingIsFlashCODReadyToShip = false
      let useAutoCreateCODPaymentWhenDelivered = false

      const shipjungSystem = 2
      // const flashSystem = 6

      config.map((config: IXShippingConfig) => {
        if (config.shipping_type_id === 22) {
          if (config.system_id === shipjungSystem) {
            // J&T x Shipjung
            useFlashxShipjung = true
            useAutoXShippingIsFlashxShipjungReadyToShip = config.set_auto_request_xshipping_on_create_order
              ? config.set_auto_request_xshipping_on_create_order
              : false
          } else {
            // Flash
            useFlash = true
            useAutoXShippingIsFlashReadyToShip = config.set_auto_request_xshipping_on_create_order
              ? config.set_auto_request_xshipping_on_create_order
              : false
          }
        } else if (config.shipping_type_id === 30) {
          if (config.system_id === shipjungSystem) {
            // J&T x Shipjung COD
            useFlashxShipjungCOD = true
            useAutoXShippingIsFlashxShipjungCODReadyToShip = config.set_auto_request_xshipping_on_create_order
              ? config.set_auto_request_xshipping_on_create_order
              : false
          } else {
            // Flash
            useFlashCOD = true
            useAutoXShippingIsFlashCODReadyToShip = config.set_auto_request_xshipping_on_create_order
              ? config.set_auto_request_xshipping_on_create_order
              : false
            useAutoCreateCODPaymentWhenDelivered = config.create_cod_payment_when_delivered
              ? config.create_cod_payment_when_delivered
              : false
          }
        }
      })
      await util.setStatePromise(this, {
        // Flash x Shipjung
        useFlashxShipjung,
        useAutoXShippingIsFlashxShipjungReadyToShip,
        useFlashxShipjungCOD,
        useAutoXShippingIsFlashxShipjungCODReadyToShip,
        // Flash
        useFlash,
        useAutoXShippingIsFlashReadyToShip,
        useFlashCOD,
        useAutoXShippingIsFlashCODReadyToShip,
        useAutoCreateCODPaymentWhenDelivered,
      })
    }
  }

  useJAndTOnChange = (newValue: boolean) => {
    util.setStatePromise(this, {
      useJAndT: newValue,
      isJAntTLoading: true,
    })
  }

  // useAutoXShippingIsJAndTReadyToShipOnChange = (newValue: boolean) => {
  //   util.setStatePromise(this, {
  //     useAutoXShippingIsJAndTReadyToShip: newValue,
  //   })
  // }

  // useJAndTCODOnChange = (newValue: boolean) => {
  //   util.setStatePromise(this, {
  //     useJAndTCOD: newValue,
  //   })
  // }

  // useAutoXShippingIsJAndTCODReadyToShipOnChange = (newValue: boolean) => {
  //   util.setStatePromise(this, {
  //     useAutoXShippingIsJAndTCODReadyToShip: newValue,
  //   })
  // }

  _setNewConfig = async (newValue: { isValue: boolean; stateKey: string }) => {
    // console.log('newValue => ', newValue)
    const { selectedStore, navigation, setXShippingConfig } = this.props
    const { useFlash, useFlashCOD, useFlashxShipjung, useFlashxShipjungCOD } = this.state
    const body: { [key: string]: any } = {
      store_id: selectedStore.get('id'),
    }

    let apiMethod = api.patchV2
    const STATE_KEY = newValue.stateKey
    const IS_VALUE = newValue.isValue
    // console.log('STATE_KEY => ', STATE_KEY)
    if (STATE_KEY === STATE_KEY_FLASH_X_SHIPJUNG_FOR_UPDATE.useFlashxShipjung) {
      // กดเปิดใช้งาน Flash x Shipjung
      if (useFlash && IS_VALUE) {
        p.op.showConfirmationOkOnly('', 'หากต้องการใช้งานเชื่อม Flash ผ่าน Shipjung กรุณาปิดการใช้งานเชื่อมตรง Flash')
        await util.setStatePromise(this, {
          [STATE_KEY]: !IS_VALUE,
        })
        return
      }
      if (IS_VALUE) {
        p.op.showConfirmationOkOnly('', p.op.t('xshipping.warning.how_to_use_xshipping'))
        await util.setStatePromise(this, {
          [STATE_KEY]: !IS_VALUE,
        })
      } else {
        p.op.showConfirmationOkOnly('', p.op.t('xshipping.warning.how_to_un_use_xshipping'))
        await util.setStatePromise(this, {
          [STATE_KEY]: !IS_VALUE,
        })
      }
      return
    }
    if (STATE_KEY === STATE_KEY_FLASH_X_SHIPJUNG_FOR_UPDATE.useAutoXShippingIsFlashxShipjungReadyToShip) {
      await util.setStatePromise(this, {
        isFlashxShipjungLoading: true,
      })
      body.shipping_type_id = 22
      body.system_id = 2
      body.set_auto_request_xshipping_on_create_order = IS_VALUE
    } else if (STATE_KEY === STATE_KEY_FLASH_X_SHIPJUNG_FOR_UPDATE.useFlashxShipjungCOD) {
      // กดเปิดใช้งาน Flash COD x ShipjungCOD
      if (useFlashCOD && IS_VALUE) {
        p.op.showConfirmationOkOnly('', 'หากต้องการใช้งานเชื่อม Flash COD ผ่าน Shipjung กรุณาปิดการใช้งานเชื่อมตรง Flash COD')
        await util.setStatePromise(this, {
          [STATE_KEY]: !IS_VALUE,
        })
        return
      }
      if (IS_VALUE) {
        p.op.showConfirmationOkOnly('', p.op.t('xshipping.warning.how_to_use_xshipping'))
        await util.setStatePromise(this, {
          [STATE_KEY]: !IS_VALUE,
        })
      } else {
        p.op.showConfirmationOkOnly('', p.op.t('xshipping.warning.how_to_un_use_xshipping'))
        await util.setStatePromise(this, {
          [STATE_KEY]: !IS_VALUE,
        })
      }
      return
    } else if (STATE_KEY === STATE_KEY_FLASH_X_SHIPJUNG_FOR_UPDATE.useAutoXShippingIsFlashxShipjungCODReadyToShip) {
      await util.setStatePromise(this, {
        isFlashxShipjungCODLoading: true,
      })
      body.shipping_type_id = 30
      body.system_id = 2
      body.set_auto_request_xshipping_on_create_order = IS_VALUE
      // NEXT FOR FLASH DIRECT
    } else if (STATE_KEY === STATE_KEY_FLASH_FOR_UPDATE.useFlash) {
      // ถ้าเปิดใช้งาน Flash
      if (useFlashxShipjung && IS_VALUE) {
        p.op.showConfirmationOkOnly(
          '',
          'หากต้องการเปิดการใช้งานการเชื่อมต่อกับ\n"Flash Express โดยตรง"\n\nกรุณาปิดการใช้งานการเชื่อมต่อ\n"ขอเลขพัสดุจาก Flash Express ผ่าน Shipjung" ก่อน'
        )
        await util.setStatePromise(this, {
          [STATE_KEY]: !IS_VALUE,
        })
        return
      }
      await util.setStatePromise(this, {
        [STATE_KEY]: IS_VALUE,
        isFlashLoading: true,
      })
      body.shipping_type_id = 22
      body.system_id = 6
    } else if (STATE_KEY === STATE_KEY_FLASH_FOR_UPDATE.useAutoXShippingIsFlashReadyToShip) {
      await util.setStatePromise(this, {
        [STATE_KEY]: IS_VALUE,
        isFlashLoading: true,
      })
      body.shipping_type_id = 22
      body.system_id = 6
      body.set_auto_request_xshipping_on_create_order = IS_VALUE
    } else if (STATE_KEY === STATE_KEY_FLASH_FOR_UPDATE.useFlashCOD) {
      // กดเปิดใช้งาน Flash COD
      if (useFlashxShipjungCOD && IS_VALUE) {
        p.op.showConfirmationOkOnly('', 'หากต้องการใช้งานเชื่อม Flash COD โดยตรง กรุณาปิดการใช้งาน ขอเลขพัสดุ Flash COD ผ่าน Shipjung')
        await util.setStatePromise(this, {
          [STATE_KEY]: !IS_VALUE,
        })
        return
      }
      await util.setStatePromise(this, {
        [STATE_KEY]: IS_VALUE,
        isFlashCODLoading: true,
      })
      body.shipping_type_id = 30
      body.system_id = 6
    } else if (STATE_KEY === STATE_KEY_FLASH_FOR_UPDATE.useAutoXShippingIsFlashCODReadyToShip) {
      await util.setStatePromise(this, {
        [STATE_KEY]: IS_VALUE,
        isFlashCODLoading: true,
      })
      body.shipping_type_id = 30
      body.system_id = 6
      body.set_auto_request_xshipping_on_create_order = IS_VALUE
      // OH NOT FOUND
    } else if (STATE_KEY === STATE_KEY_FLASH_FOR_UPDATE.useAutoCreateCODPaymentWhenDelivered) {
      await util.setStatePromise(this, {
        [STATE_KEY]: IS_VALUE,
        isFlashCODLoading: true,
      })
      body.shipping_type_id = 30
      body.system_id = 6
      body.create_cod_payment_when_delivered = IS_VALUE
      // OH NOT FOUND
    } else {
      p.op.showConfirmationOkOnly('เกิดข้อผิดพลาด', 'ไม่พบขนส่ง กรุณาติดต่อทีมงาน')
      return
    }
    const isMainBtn =
      STATE_KEY === STATE_KEY_FLASH_FOR_UPDATE.useFlash ||
      STATE_KEY === STATE_KEY_FLASH_FOR_UPDATE.useFlashCOD ||
      STATE_KEY === STATE_KEY_FLASH_X_SHIPJUNG_FOR_UPDATE.useFlashxShipjung ||
      STATE_KEY === STATE_KEY_FLASH_X_SHIPJUNG_FOR_UPDATE.useFlashxShipjungCOD
    // console.log('isMainBtn => ', isMainBtn)
    // console.log('STATE_KEY => ', STATE_KEY)
    // console.log('IS_VALUE => ', IS_VALUE)
    if (isMainBtn && !IS_VALUE) {
      apiMethod = api.delV2
    } else {
      const { getXShippingConfigFromRedux } = this.props
      let createMode = true
      // ปุ่มที่กดคือ flash โดยตรง
      const flash = STATE_KEY === STATE_KEY_FLASH_FOR_UPDATE.useFlash
      const flashCOD = STATE_KEY === STATE_KEY_FLASH_FOR_UPDATE.useFlashCOD
      // ปุ่มที่กดคือ flash x Shipjung
      const flashxShipjung = STATE_KEY === STATE_KEY_FLASH_X_SHIPJUNG_FOR_UPDATE.useFlashxShipjung
      const flashxShipjungCOD = STATE_KEY === STATE_KEY_FLASH_X_SHIPJUNG_FOR_UPDATE.useFlashxShipjungCOD
      // ตรวจสอบว่าเป็น mode create หรือ edit
      getXShippingConfigFromRedux.map((config: IXShippingConfig) => {
        if (config.system_id === 6 && flash && config.shipping_type_id === 22) {
          createMode = false
        } else if (config.system_id === 6 && flashCOD && config.shipping_type_id === 30) {
          createMode = false
        } else if (config.system_id === 2 && flashxShipjung && config.shipping_type_id === 22) {
          createMode = false
        } else if (config.system_id === 2 && flashxShipjungCOD && config.shipping_type_id === 30) {
          createMode = false
        }
      })
      if (isMainBtn && createMode) {
        apiMethod = api.putV2
        body.set_auto_request_xshipping_on_create_order = true
        body.create_cod_payment_when_delivered = true
      }
    }
    const response: { xshipping_config: IXShippingConfig[] } = await apiMethod(api.XSHIPPING_CONFIG, body)
    if (response.xshipping_config) {
      setXShippingConfig(response.xshipping_config)
      await this.setFlashConfig(response.xshipping_config)
      // const { state } = navigation
      // const { onPressGoBack } = state.params
      // // console.log('onPressGoBack => ', onPressGoBack)
      const onPressGoBack = util.getNavParam(this.props, 'onPressGoBack')
      if (_.isFunction(onPressGoBack)) {
        onPressGoBack()
      }
    }

    if (
      STATE_KEY === STATE_KEY_FLASH_X_SHIPJUNG_FOR_UPDATE.useFlashxShipjung ||
      STATE_KEY === STATE_KEY_FLASH_X_SHIPJUNG_FOR_UPDATE.useAutoXShippingIsFlashxShipjungReadyToShip
    ) {
      await util.setStatePromise(this, {
        isFlashxShipjungLoading: false,
      })
    } else if (
      STATE_KEY === STATE_KEY_FLASH_X_SHIPJUNG_FOR_UPDATE.useFlashxShipjungCOD ||
      STATE_KEY === STATE_KEY_FLASH_X_SHIPJUNG_FOR_UPDATE.useAutoXShippingIsFlashxShipjungCODReadyToShip
    ) {
      await util.setStatePromise(this, {
        isFlashxShipjungCODLoading: false,
      })
    } else if (
      STATE_KEY === STATE_KEY_FLASH_FOR_UPDATE.useFlash ||
      STATE_KEY === STATE_KEY_FLASH_FOR_UPDATE.useAutoXShippingIsFlashReadyToShip
    ) {
      await util.setStatePromise(this, {
        isFlashLoading: false,
      })
    } else if (
      STATE_KEY === STATE_KEY_FLASH_FOR_UPDATE.useFlashCOD ||
      STATE_KEY === STATE_KEY_FLASH_FOR_UPDATE.useAutoXShippingIsFlashCODReadyToShip ||
      STATE_KEY === STATE_KEY_FLASH_FOR_UPDATE.useAutoCreateCODPaymentWhenDelivered
    ) {
      await util.setStatePromise(this, {
        isFlashCODLoading: false,
      })
    }

    // console.log('response => ', response)
  }

  isSwitchOnChange = async (newValue: { isValue: boolean; stateKey: string }) => {
    // console.log('isSwitchOnChange => ', newValue)
    // const STATE_KEY = newValue.stateKey
    // const IS_VALUE = newValue.isValue
    // let alertText = ''
    // if (STATE_KEY === STATE_KEY_FLASH_X_SHIPJUNG_FOR_UPDATE.useJAndT) {
    //   alertText = IS_VALUE ? 'ยืนยันการใช้งานออกเลขพัสดุ Kerry Express ใช่หรือไม่' : 'ยกเลิกการใช้งานออกเลขพัสดุ Kerry Express ใช่หรือไม่'
    // } else if (STATE_KEY === STATE_KEY_FLASH_X_SHIPJUNG_FOR_UPDATE.useAutoXShippingIsJAndTReadyToShip) {
    //   alertText = IS_VALUE ? 'ยืนยันการใช้งาน "ขอเลขพัสดุเมื่อพร้อมจัดส่ง" Kerry Express ใช่หรือไม่' : 'ยกเลิกการใช้งาน "ขอเลขพัสดุเมื่อพร้อมจัดส่ง" Kerry Express ใช่หรือไม่'
    // } else if (STATE_KEY === STATE_KEY_FLASH_X_SHIPJUNG_FOR_UPDATE.useJAndTCOD) {
    //   alertText = IS_VALUE ? 'ยืนยันการใช้งาน "ขอเลขพัสดุเมื่อพร้อมจัดส่ง" Kerry Express [COD] ใช่หรือไม่' : 'ยกเลิกการใช้งาน "ขอเลขพัสดุเมื่อพร้อมจัดส่ง" Kerry Express [COD] ใช่หรือไม่'
    // } else if (STATE_KEY === STATE_KEY_FLASH_X_SHIPJUNG_FOR_UPDATE.useAutoXShippingIsJAndTCODReadyToShip) {
    //   alertText = IS_VALUE ? 'ยืนยันการใช้งาน "ขอเลขพัสดุเมื่อพร้อมจัดส่ง" Kerry Express [COD] ใช่หรือไม่' : 'ยกเลิกการใช้งาน "ขอเลขพัสดุเมื่อพร้อมจัดส่ง" Kerry Express [COD] ใช่หรือไม่'
    // } else if (STATE_KEY === STATE_KEY_FLASH_X_SHIPJUNG_FOR_UPDATE.useAutoJAndTCreateCODPaymentWhenDelivered) {
    //   alertText = IS_VALUE ? 'ยืนยันการใช้งาน "บันทึกรับชำระเมื่อพัสดุถึงผู้รับแล้ว" ใช่หรือไม่' : 'ยกเลิกการใช้งาน "บันทึกรับชำระเมื่อพัสดุถึงผู้รับแล้ว" ใช่หรือไม่'
    // }

    // let isConfirm = false
    // await new Promise(async (resolve) => {
    //   p.op.showConfirmation('', alertText, () => {
    //     isConfirm = true
    //     // @ts-ignore
    //     resolve(null)
    //   }, () => {
    //     // @ts-ignore
    //     resolve(null)
    //   }, 'ตกลง', 'ยกเลิก')
    // })

    // if (!isConfirm) {
    //   await util.setStatePromise(this, {
    //     [newValue.stateKey]: !IS_VALUE,
    //   })
    //   return
    // }
    await this._setNewConfig(newValue)
    // util.setStatePromise(this, {
    //   [newValue.stateKey]: newValue.isValue,
    // })
  }

  getLinkAccount = async () => {
    const { selectedStore } = this.props
    // const body: IAnyObject = { ... }
    const response = await api.get<IResponse>(`${api.GET_FLASH_LINK_ACCOUNT}?store_id=${selectedStore.get('id')}`, { isApiV2: true })
    // console.log('response => ', response)
    if (response.link_account_url) {
      p.op.showConfirmationOkOnly(
        'ผูกเบอร์โทรศัพท์เพื่อเชื่อมต่อ',
        'ในหน้าถัดไป กรุณากรอกเบอร์โทรศัพท์ที่คุณ*ลงทะเบียนไว้กับ Flash* (ซึ่งใช้ได้ทั้งจากบัญชีธุรกิจ หรือ บัญชีที่สร้างผ่านแอป Flash) เพื่อให้ XSelly ใช้รหัสบัญชีนี้ในการขอเลขติดตามพัสดุจาก Flash',
        () => Linking.openURL(response.link_account_url),
        'รับทราบ'
      )
      await util.setStatePromise(this, {
        isFlashLinkAccount: response.link_account_url,
        // isRenderWebView: true,
      })
    }
  }
}
