import { connect } from 'react-redux'
import { getState, getDispatch } from 'x/modules/product/ProductViewConnect'
import { withMkpProductContextPropsAndRef } from 'xui/modules/marketplace/MkpProductContextHOC'
import ProductViewUI from './ProductView'
// import { bindActionCreators } from 'redux'
// // import { NavigationActions } from 'react-navigation'
// import * as ProductStateActions from './ProductState'
// import * as OrderActions from 'x/modules/order/OrderState'
// import * as BackParamsState from '../backparams/BackParamsState'
// import { getSellerStores } from '../../redux/selectors'

// @ts-ignore
const ProductView = withMkpProductContextPropsAndRef(ProductViewUI)
// @ts-ignore
ProductView.navigationOptions = { header: null }

export default connect(
  (state) => getState(state),
  (dispatch) => getDispatch(dispatch)
)(ProductView)
