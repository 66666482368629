import React, { Component } from 'react'

import { TouchableOpacity, View, Image, Clipboard, StyleSheet, Platform } from 'react-native'

import HStack from 'xui/components/HStack'
import Box from 'xui/components/Box'
import VStack from 'xui/components/VStack'

import _ from 'lodash'
import { List, fromJS } from 'immutable'
import { COLORS, STYLES } from 'x/config/styles'

import xCONS from 'x/config/constants'
import * as xUtil from 'x/utils/util'
import { delay, formatDateForDisplay } from 'x/utils/util'
import * as xAcl from 'x/utils/acl'
import moment from 'moment'
import p from 'x/config/platform-specific'
import { IOrderMap, IOrderShipListViewProps, IOrderShipListViewState } from 'x/index'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import XCard from 'xui/components/XCard'
import XText from 'xui/components/XText'
import XButton from 'xui/components/XButton'
import * as NavActions from 'x/utils/navigation'
import XIcon from '../../components/XIcon'
import HelpButton from '../../components/HelpButton'

export interface IShipingStatus {
  l: string
  n: string
  s: number
  t: string
}

abstract class BaseUIOrderShipListView extends Component<IOrderShipListViewProps, IOrderShipListViewState> {
  inProcess: boolean

  static displayName = 'BaseUIOrderShipListView'

  _inProcess?: boolean

  // abstract navToShipView(): void | Promise<void>

  constructor(props) {
    super(props)
    this.state = {
      isInitialized: false,
      shippingStatus: null,
    }
    this._autoCompleteShipping = this._autoCompleteShipping.bind(this)
    this._cancelShipping = this._cancelShipping.bind(this)
    this.inProcess = false
  }

  async componentDidMount() {
    // const { navigation, fetchOrderDetail, selectedOrder } = this.props
    // const { order_id, store_id } = navigation.state.params
    // log('Navigation params { order_id, store_id } => ', { order_id, store_id })
    // xUtil.assert(store_id, 'This page is required my store ID. (store_id)')
    // xUtil.assert(order_id, 'This page is required target order ID. (order_id)')
    //
    // // Use on selectedOrder first !!
    // if (order_id && store_id && !selectedOrder.get('id')) {
    //   fetchOrderDetail({ order_id, store_id })
    // }
    await this._initialize()
  }

  _initialize = async () => {
    const { navigation, selectedOrder, fetchOrderDetail, shouldFetchOrderDetail, shippingStatus } = this.props
    let order = selectedOrder
    let setShippingStatus = null
    if (navigation) {
      const storeId = xUtil.getNavParam(this.props, 'store_id', null)
      const orderId = xUtil.getNavParam(this.props, 'order_id', null)
      if (!selectedOrder.get('id')) {
        const response = await new Promise((isFetched) => {
          fetchOrderDetail({
            body: {
              store_id: storeId,
              order_id: orderId,
            },
            successCallback: (res) => isFetched(res),
            failedCallback: () => isFetched(null),
          })
        })
        if (!response) {
          xUtil.navGoBack(this.props)
        }
        order = fromJS(response)
      }
    }
    if (order.has('x')) {
      const xshipping = order.getIn(['x', 'o'])
      const courier_tracking_codes = []
      xshipping.map((x) => {
        if (x.get('s') > 2010 && x.has('t') && !_.isNil(x.get('t'))) {
          courier_tracking_codes.push(x.get('t'))
        }
      })
      if (courier_tracking_codes.length > 0) {
        await new Promise((isFetched) => {
          shippingStatus({
            body: {
              courier_tracking_codes,
            },
            successCallback: (res) => {
              setShippingStatus = res
              isFetched(null)
            },
            failedCallback: () => {
              // p.op.showToast('พบข้อผิดพลาด กรุณาทำรายการใหม่ภายหลัง', 'danger', 2000)
              isFetched(null)
            },
          })
        })
      }
    }

    await delay(500)
    this.setState({ isInitialized: true, shippingStatus: setShippingStatus })
  }

  _isFocusedParentOrder = () => {
    const { navigation, selectedOrder } = this.props
    if (navigation) {
      const orderId = xUtil.getNavParam(this.props, 'order_id', null)
      const parentOrderId = selectedOrder.get('id')
      if (parentOrderId && orderId && parentOrderId === orderId) {
        return true
      }
    }
    return false
  }

  getHeaderTitleText = () => {
    const { navigation } = this.props
    const txtTitle = xUtil.getNavParam(this.props, 'txtTitle', 'รายการจัดส่ง')
    return txtTitle
  }

  _getFocusedSubOrder = () => {
    const { navigation, selectedOrder } = this.props
    if (navigation) {
      const orderId = xUtil.getNavParam(this.props, 'order_id', null)
      const subOrders = selectedOrder.get('suborders') || List([])
      const foundOrderIndex = subOrders.findIndex((so) => so.get('id') === orderId)
      if (foundOrderIndex > -1) {
        return subOrders.get(foundOrderIndex)
      }
    }
    return null
  }

  navToShipView = async () => {
    if (this._inProcess) {
      return
    }
    this._inProcess = true
    const { navigation, selectedOrder } = this.props
    if (navigation) {
      const orderDetialJS = selectedOrder.toJS()
      // const storeId = xUtil.getNavParam(this.props, 'store_id', null)
      // const orderId = xUtil.getNavParam(this.props, 'order_id', null)
      navigation.dispatch(
        NavActions.navToShippingView({
          fromOrder: orderDetialJS,
          onShippingSuccess: this._onShippingSuccess,
        })
      )
    }
    await delay(500)
    this._inProcess = false
  }

  _onShippingSuccess = async () => {
    const { shouldFetchOrderDetail } = this.props
    shouldFetchOrderDetail()
    await xUtil.delay(100)
    this._handleGoBack()
  }

  // _setNewTitleFromSelectedOrder() {
  //   const { navigation, selectedOrder } = this.props
  //   const order_name = selectedOrder.get('name')
  //   if (order_name) {
  //     navigation.setParams({ txtTitle: `รายการจัดส่ง #${order_name}` })
  //   }
  // }
  //
  // componentDidMount() {
  //   this._setNewTitleFromSelectedOrder()
  // }

  // componentWillReceiveProps(nextProps) {
  //   const { selectedOrder } = nextProps
  //   if (this.props.selectedOrder !== selectedOrder) { // New Order was Load
  //     this._setNewTitleFromSelectedOrder()
  //   }
  // }

  // shouldComponentUpdate(nextProps, nextState) {
  //   // log('__shouldComponentUpdate__: ', nextProps)
  //   const propsDiff = isDiff(this.props, nextProps, ['selectedOrder'])
  //   // const stateDiff = isDiff(this.state, nextState, ['modalVisible'])
  //   // return propsDiff || stateDiff
  //   return propsDiff
  // }

  // componentWillUnmount() {
  //
  // }

  // _fetchShipmentOrder({ order_id, store_id }) {
  //   this.props.navigation.dispatch({ type: actions.SHIPMENT_ORDER_FETCH, payload: { order_id, store_id } })
  // }

  _autoCompleteShipping({ store_id, order_id, return_parent_order, storeName, amountWaitingQty }) {
    p.op.showConfirmation(
      'ปิดการจัดส่งแบบไม่ระบุเลขพัสดุ',
      `แจ้งจัดส่งสินค้าแบบไม่ระบุเลขพัสดุ\nที่รอจัดส่งอยู่ทั้งหมด ${amountWaitingQty} ชิ้น\nโดย${storeName} `,
      () => this.props.autoCompleteShipping({ store_id, order_id, return_parent_order, storeName }),
      () => this.props.autoCompleteShipping({ store_id, order_id, return_parent_order, storeName }),
      'ดำเนินการ',
      'ยกเลิก'
    )
  }

  _cancelShipping({ store_id, order_id, shipping_id }) {
    p.op.showConfirmation(
      'ยกเลิกการจัดส่ง',
      null,
      () => this.props.cancelShipping({ store_id, order_id, shipping_id }),
      () => {
        // no-op
      },
      'ยืนยัน',
      'ยังไม่ยกเลิก'
    )
  }

  _keyExtractor = (item, index) => index.toString()

  _renderPartnerShipment = () => {
    let orders = []
    const { selectedOrder } = this.props
    const suborders = selectedOrder.get('suborders') || List([])
    // // const order_name = selectedOrder.get('name') || []
    // // const partner_name = selectedOrder.get('partner_name') || ''
    // // const order_type = selectedOrder.get('type') || null
    // // const products = selectedOrder.get('products') || List([])
    //
    // const suborderData = suborders.map(suborder => { return { order: suborder, isMine: false } }).toArray() || []
    // orders.push({ order: selectedOrder, isMine: true })
    // orders = _.concat(orders, suborderData)

    const isFocusedParentOrder = this._isFocusedParentOrder()
    if (isFocusedParentOrder) {
      const suborderData = suborders.map((suborder) => ({ order: suborder, isMine: false })).toArray() || []
      orders.push({ order: selectedOrder, isMine: true })
      orders = _.concat(orders, suborderData)
    } else {
      orders.push({ order: this._getFocusedSubOrder(), isMine: false })
    }

    return orders.map(this._renderFlatOrder)
    // return <FlatList data={orders} keyExtractor={this._keyExtractor} renderItem={this._renderFlatOrder} />

    // return <View />
    //
    // if (products.size > 0) {
    //   if (order_type > 0 && order_type < 3) {
    //     sections.push({
    //       title: 'จากร้านฉัน',
    //       order_name: `ใบสั่งซื้อ #${order_name}`,
    //       data: products.map(product => product).toArray(),
    //       isMine: true,
    //     })
    //   } else {
    //     sections.push({
    //       title: `จากร้าน ${partner_name}`,
    //       order_name: `ใบสั่งซื้อ #${order_name}`,
    //       data: products.map(product => product).toArray(),
    //       isMine: false,
    //     })
    //   }
    // }
    // if (suborderSections.length > 0) {
    //   sections = _.concat(sections, suborderSections)
    // }

    // New Heterogeneous rendering
    // return (
    //   <SectionList
    //     keyExtractor={(item, index) => index.toString()}
    //     // renderItem={ ({ item, index, section }) => this._renderPartnerShipmentItem({ item, index, section }) }
    //     renderSectionHeader={({ section }) => this._renderSectionHeader({ section }) }
    //     //SectionSeparatorComponent={(sep) => this._renderSectionSeparator(sep) }
    //     sections={ suborderSections }
    //       // [
    //       //   { data: [...], title: ..., renderItem: ...  }, // ---> renderProducts
    //       //   { data: [...], title: ..., renderItem: ...  }, // ---> renderShipping
    //       //
    //       //   { data: [...], title: ..., renderItem: ...  }, // ---> renderProducts
    //       //   { data: [...], title: ..., renderItem: ...  }, // ---> renderShipping
    //       // ]
    //   />
    // )

    // Old method
    // return (
    //   <SectionList
    //     keyExtractor={(item, index) => index.toString()}
    //     renderItem={ ({ item, index, section }) => this._renderPartnerShipmentItem({ item, index, section }) }
    //     renderSectionHeader={({ section }) => this._renderSectionHeader({ section }) }
    //     //SectionSeparatorComponent={(sep) => this._renderSectionSeparator(sep) }
    //     sections={ sections }
    //   />
    // )
  }

  // _renderFlatOrder = ({ item }) => {
  _renderFlatOrder = (item: { order: IOrderMap; isMine?: boolean }, index: number) => {
    // console.log('_renderFlatOrder item => ', item)
    // log('_renderFlatOrder item.data.toJS() => ', item.order.toJS())
    const { myStores } = this.props
    const { order, isMine } = item // order is Immutable
    if (!item || !item.order) {
      return null
    }
    const order_name = order.get('name') || []
    // const store_id = order.get('store_id')
    const my_store_name = order.get('my_store_name') || ''
    const partner_name = order.get('partner_name') || ''
    const order_type = order.get('type') || null
    const products = order.get('products') || List([])
    const shippings = order.get('shippings') || List([])
    const receiver_name = order.getIn(['receiver', 'name']) || 'ไม่ระบุชื่อผู้รับ'
    const shippingOrderId = order.get('id')
    const orderStoreId = order.get('store_id')
    const orderCreatedAt = order.get('created_at')
    const myStoreIds = myStores.map((s) => s.get('id'))

    const diffDuration = moment.duration(moment().diff(orderCreatedAt))
    const orderCreatedDays = diffDuration.asDays()
    // log('moment() => ', moment())
    // log('moment().diff(orderCreatedAt) => ', moment().diff(orderCreatedAt))
    // log('orderCreatedAt => ', orderCreatedAt)
    // log('diffDuration => ', diffDuration)
    // log('diffDay => ', diffDay)
    // ถ้าเป็นสินค้าที่ฉันต้องจัดส่ง หรือออเดอร์มีอายุ >= 14 วัน ถึงจะยอมให้ปิดจัดส่งอัตโนมัติโดยไม่ระบุรายละเอียด
    const autoCompleteBtnVisibility = myStoreIds.includes(orderStoreId) || Math.floor(orderCreatedDays) >= 14

    // log('BaseUIOrderShipListView _renderFlatOrder myStores', myStores.toJS())
    // log('_renderFlatOrder.order', order.toJS())
    let canMyStoreShip = false
    let fromStoreText = ''
    let return_parent_order = false
    let isMyStoreToShip = false
    if (isMine && order_type > 0 && order_type < 3) {
      isMyStoreToShip = true
      fromStoreText = `ร้านฉัน (${my_store_name})`
      // log('_renderFlatOrder my products.toJS() => ', products.toJS())
      const shipped_qty = products.reduce((prevQty, product) => prevQty + product.get('shipped_qty'), 0)
      const qty = products.reduce((prevQty, product) => prevQty + product.get('qty'), 0)
      if (shipped_qty >= qty) {
        canMyStoreShip = false
      } else {
        canMyStoreShip = true
      }
    } else {
      fromStoreText = `ร้าน ${partner_name}`
      canMyStoreShip = false
      return_parent_order = true
    }

    // FIXME: TED Fund Phase 2: O: ซ่อนปุ่มแจ้งจัดส่ง หากเป็นออเดอร์ของ Market Places (Shopee)
    const isMarketPlacesOrder = xUtil.isMarketPlacesOrder(order)
    // mkpIsSelfDelivery => https://app.clickup.com/t/860pwxhvh
    const mkpIsSelfDelivery = order.has('mkp_is_self_delivery') ? order.get('mkp_is_self_delivery') : false
    if (isMarketPlacesOrder && !mkpIsSelfDelivery) {
      canMyStoreShip = false
    }

    const hasProducts = products.size > 0
    const hasShipping = shippings.size > 0

    let amountShippedQty
    let amountQty
    let amountWaitingQty
    amountShippedQty = amountQty = amountWaitingQty = 0
    products.forEach((product) => {
      amountShippedQty += product.get('shipped_qty')
      amountQty += product.get('qty')
    })
    amountWaitingQty = amountQty - amountShippedQty

    const doRenderProductItem = (product, idx) => this._renderProductItem({ product, index: idx, canMyStoreShip, orderIndex: index })
    // const doRenderShippingRow = (params) => this._renderShippingCard({ isMyStoreToShip, shipping: params.item, index: params.index, products, receiver_name })
    // const doRenderShippingRow = (params) =>
    //   this._renderShippingCard({ isMyStoreToShip, shipping: params.item, index: params.index, receiver_name })
    const doRenderShippingRow = (shipping, idx) =>
      this._renderShippingCard({ isMyStoreToShip, shipping, index: idx, receiver_name, orderIndex: index })

    if (!hasProducts) {
      return null
    }

    return (
      <XCard w='full' key={`OrderShipItem_${index}`}>
        <XCard.Body>
          <VStack w='full'>
            {this._renderHeaderCard({
              storeName: fromStoreText,
              return_parent_order,
              shippingOrderId,
              order_name,
              canMyStoreShip,
              amountWaitingQty,
              autoCompleteBtnVisibility,
            })}
            {this._renderProductsTableHead()}
            {products.map(doRenderProductItem)}
            {this._renderProductsTableTail({ amountShippedQty, amountQty, amountWaitingQty, canMyStoreShip })}
            {hasShipping ? (
              <VStack w='full'>
                <XText bold>ประวัติการจัดส่ง</XText>
                {/* <FlatList
                  style={{ flex: 1, width: '100%' }}
                  keyExtractor={(it, idx) => `${index}_${idx}`}
                  data={shippings.toArray()}
                  renderItem={doRenderShippingRow}
                /> */}
                <VStack w='full' space='2'>
                  {shippings.toArray().map(doRenderShippingRow)}
                </VStack>
              </VStack>
            ) : (
              <View />
            )}
          </VStack>
        </XCard.Body>
      </XCard>
    )
  }

  _renderShippingCard = ({ isMyStoreToShip, shipping, index, receiver_name, orderIndex }) => {
    if (!shipping || !_.isNumber(index)) {
      return null
    }
    // console.log('shipping => ', shipping.toJS())
    // รับ products เข้ามาเช็ค ship_qty เพราะหลังบ้านยังไม่ส่งมา
    // log('_renderShippingCard shipping.toJS() => ', shipping.toJS())
    const tracking_number = shipping.get('tracking_number')
    const shipping_type_id = shipping.get('shipping_type_id')
    const created_at = shipping.get('created_at')
    const shipped = shipping.get('shipped')
    const shipping_id = shipping.get('id')
    const order_id = shipping.get('order_id')
    const cancelled_at = shipping.get('cancelled_at')

    // const { store_id } = this.props.navigation.state.params
    const store_id = xUtil.getNavParam(this.props, 'store_id')

    const txtShippedAt = `ส่งเมื่อ: ${formatDateForDisplay(created_at)}`
    const txtTrackingNo = `เลขติดตามพัสดุ​: ${tracking_number}`
    const shippingTypeItem = xCONS.ORDER_VIEW_SHIPPING_TYPE_ITEMS.find((sti) => sti.value === shipping_type_id)
    const shippingTypeKey = shippingTypeItem ? shippingTypeItem.key : 'unknown'
    const txtShipType = `จัดส่งแบบ: ${p.op.t(`Order.shippingTypeItems.${shippingTypeKey}`)}`
    const txtReceiverName = `ผู้รับ: ${receiver_name}`
    const txtShippedList = '\nรายการสินค้า'
    const txtShipNote = `หมายเหตุ: ${shipping.get('note')}`
    if (_.isNil(shipped)) {
      return null
      // p.op.alert('เกิดข้อผิดพลาด', 'กรุณาออกจากออเดอร์นี้ แล้วและทำรายการนี้อีกครั้ง')
      // return <View></View>
    }
    // log('_renderShippingCard.shipped', shipped.toJS())
    const txtShippedItems = shipped.map(
      (shippedProduct, i) => `${i + 1}. ${shippedProduct.get('name')} x${shippedProduct.get('shipped_qty')}`
    )
    const clipboardText = `${txtShippedAt}\n${txtReceiverName}\n${txtTrackingNo}\n${txtShipType}\n${txtShipNote}\n${txtShippedList}\n${txtShippedItems.join(
      '\n'
    )}`

    const clipboardTrackingAndName = `${tracking_number} ${receiver_name}`

    return (
      <XCard key={`Shipping_${orderIndex}_${index}`}>
        {cancelled_at ? (
          <HStack p='2' w='full' style={{ backgroundColor: COLORS.BRAND_Danger }}>
            <XText variant='activeDark'>{`ยกเลิกโดยผู้ขายส่งเมื่อ ${formatDateForDisplay(cancelled_at)}`}</XText>
          </HStack>
        ) : (
          <HStack p='1' w='full' justifyContent='flex-end'>
            <TouchableOpacity
              style={[STYLES.BTN_MODE_ACTIVE_SECONDARY, { padding: 3, borderWidth: 1, borderRadius: 7, marginRight: 3 }]}
              onPress={() => {
                Clipboard.setString(tracking_number)
                p.op.showToast(`คัดลอก "${tracking_number}" แล้ว`, 'success')
              }}>
              <XText
                bold
                color={COLORS.APP_MAIN}
                // @ts-ignore
                style={StyleSheet.flatten([{ fontSize: STYLES.FONT_SIZE_SMALLER }])}>
                คัดลอกเลขพัสดุ
              </XText>
            </TouchableOpacity>
            <TouchableOpacity
              style={[STYLES.BTN_MODE_ACTIVE_SECONDARY, { padding: 3, borderWidth: 1, borderRadius: 7, marginRight: 3 }]}
              onPress={() => {
                Clipboard.setString(clipboardTrackingAndName)
                p.op.showToast(`คัดลอก "${clipboardTrackingAndName}" แล้ว`, 'success')
              }}>
              <XText
                bold
                color={COLORS.APP_MAIN}
                // @ts-ignore
                style={StyleSheet.flatten([{ fontSize: STYLES.FONT_SIZE_SMALLER }])}>
                คัดลอกเลขพัสดุพร้อมผู้รับ
              </XText>
            </TouchableOpacity>
            <TouchableOpacity
              style={[STYLES.BTN_MODE_ACTIVE_SECONDARY, { padding: 3, borderWidth: 1, borderRadius: 7 }]}
              onPress={() => {
                Clipboard.setString(clipboardText)
                p.op.showToast('คัดลอกรายการจัดส่งนี้แล้ว', 'success')
              }}>
              <XText
                bold
                color={COLORS.APP_MAIN}
                // @ts-ignore
                style={StyleSheet.flatten([{ fontSize: STYLES.FONT_SIZE_SMALLER }])}>
                คัดลอกทั้งส่วน
              </XText>
            </TouchableOpacity>
          </HStack>
        )}
        <VStack p='2' style={{ backgroundColor: cancelled_at ? COLORS.BG_LIGHT_GREY : 'white' }}>
          <XText variant='inactive'>{txtShippedAt}</XText>
          <XText variant='inactive'>{txtReceiverName}</XText>
          <XText selectable>{txtTrackingNo}</XText>
          <XText variant='inactive'>{txtShipType}</XText>
          {_.isNil(shipping.get('note')) ? null : <XText variant='inactive'>{txtShipNote}</XText>}
          <XText variant='inactive'>{txtShippedList}</XText>
          {txtShippedItems.map((item, i) => (
            <XText key={`Shipped_Product_${i}`} variant='inactive'>
              {item}
            </XText>
          ))}
        </VStack>

        {/* <View style={{ paddingBottom: 20, paddingTop: 20 }}> */}
        {this._renderAllShippingStatus(tracking_number)}
        {/* {this._renderShippingStatus(
            (dateTime = '10/10/2019 20:20'),
            (icon = { name: 'error-outline', family: 'MaterialIcons', color: 'red' }),
            (txtStatus = 'ติดปัญหาการนำส่ง รอการแก้ไข'),
            (txtLocation = 'Nan - Thailand'),
            (lastRow = false),
          )}
          {this._renderShippingStatus(
            (dateTime = '10/10/2019 20:20'),
            (icon = { name: 'checkmark-circle-outline', family: 'Ionicons', color: '#000' }),
            (txtStatus = 'พัสดุถึงสาขาปลายทาง'),
            (txtLocation = 'Nan - Thailand'),
            (lastRow = false),
          )}
          {this._renderShippingStatus(
            (dateTime = '10/10/2019 20:20'),
            (icon = { name: 'checkmark-circle-outline', family: 'Ionicons', color: '#000' }),
            (txtStatus = 'พัสดุถึงสาขาปลายทาง'),
            (txtLocation = 'Nan - Thailand'),
            (lastRow = false),
          )}
          {this._renderShippingStatus(
            (dateTime = '10/10/2019 20:20'),
            (icon = { name: 'checkmark-circle-outline', family: 'Ionicons', color: '#000' }),
            (txtStatus = 'พัสดุถึงสาขาปลายทาง'),
            (txtLocation = 'Nan - Thailand'),
            (lastRow = false),
          )}
          {this._renderShippingStatus(
            (dateTime = '10/10/2019 20:20'),
            (icon = { name: 'checkmark-circle-outline', family: 'Ionicons', color: '#000' }),
            (txtStatus = 'พัสดุถึงสาขาปลายทาง'),
            (txtLocation = 'Nan - Thailand'),
            (lastRow = true),
          )} */}
        {/* </View> */}
        {/* <View>GGWP</View> */}

        {isMyStoreToShip && !cancelled_at && xAcl.canDoAtSelectedStore(xCONS.PERM_STORE_HELPER.SHIPPING_DELETE) ? (
          <Box style={STYLES.NO_MARGIN_PADDING}>
            <View
              style={{
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                paddingLeft: 6,
                paddingRight: 2,
                paddingBottom: 6,
              }}>
              <TouchableOpacity
                style={[STYLES.BTN_MODE_ACTIVE_DANGER, { padding: 3, borderWidth: 1, borderRadius: 7, marginRight: 3 }]}
                onPress={() => this._cancelShipping({ store_id, order_id, shipping_id })}>
                <XText bold color='white' fontSize={STYLES.FONT_SIZE_SMALLER}>
                  ยกเลิกการจัดส่งนี้
                </XText>
              </TouchableOpacity>
            </View>
          </Box>
        ) : null}
      </XCard>
    )
  }

  _renderAllShippingStatus = (txtTrackingNo: string) => {
    const { shippingStatus } = this.state

    const status = _.has(shippingStatus, txtTrackingNo) ? shippingStatus[txtTrackingNo] : null
    if (!_.isNil(status) && _.isArray(status)) {
      const maxStatus = status.length
      return status.map((s: IShipingStatus, index: number) => {
        const dateTime = s.t
        const icon = xUtil.getXShippingIconStatus(s.s)
        const txtStatus = xUtil.getXShippingStatusLabel(s.s)
        const txtLocation = s.l
        const txtInfo = s.n
        const lastRow = index + 1 === maxStatus
        return this._renderShippingStatus(dateTime, icon, txtStatus, txtLocation, txtInfo, lastRow, index)
      })
    }
  }

  // concat for additionalStatusText with location and info
  // i.e. - BKK ลูกค้าเปลี่ยนแปลงเวลา2021-05-24 16:50:00, where
  // BKK = txtLocation
  // ลูกค้าเปลี่ยนแปลงเวลา2021-05-24 16:50:00 = txtInfo
  _concatAdditionalShippingStatusText = (txtLocation: string, txtInfo: string) => {
    let additionalStatusText = ''
    if (!xUtil.isStringEmpty(txtLocation)) {
      additionalStatusText = txtLocation
    }
    if (!xUtil.isStringEmpty(txtInfo)) {
      additionalStatusText += ` ${txtInfo}`
      additionalStatusText = additionalStatusText.trim() // remove ' ' if there is no txtLocation
    }
    // add the prefix - if string not empty
    if (!xUtil.isStringEmpty(additionalStatusText)) {
      additionalStatusText = ` - ${additionalStatusText}`
    }
    return additionalStatusText
  }

  _renderShippingStatus = (dateTime, icon, txtStatus, txtLocation, txtInfo, lastRow, index) => {
    const date = moment(dateTime).format('D MMM YYYY')
    const time = moment(dateTime).format('HH:mm')

    const additionalStatusText = this._concatAdditionalShippingStatusText(txtLocation, txtInfo)

    return (
      <HStack w='full' key={`${date}_${index.toString()}`}>
        <VStack w='30%' alignItems='flex-end'>
          <XText style={{ color: icon.color }}>{date}</XText>
          <XText fontSize='xs' style={{ color: icon.color }}>
            {time}
          </XText>
        </VStack>
        <VStack w='20%' alignItems='center'>
          <XIcon active name={icon.name} family={icon.family} style={!_.isNil(icon.color) ? { color: icon.color } : undefined} />
          {lastRow ? <Box minH='20px' /> : <Box borderLeftWidth='1' borderLeftColor='dark.500' minH='20px' flex={1} />}
        </VStack>
        <VStack w='50%' pb='2'>
          <XText style={{ color: icon.color }}>
            {txtStatus}
            {additionalStatusText}
          </XText>
        </VStack>
      </HStack>
    )
  }

  // _renderSectionSeparator({ leadingSection, section }) {
  //   // log('_renderSectionSeparator => ', sep)
  //   if (_.has(leadingSection, 'title') && _.has(section, 'title')) {
  //     if (leadingSection.title.localeCompare(section.title) !== 0) {
  //       return <View style={{ height: 10 }} />
  //     }
  //   }
  //   return <View />
  // }

  _renderProductItem = ({ product, index, canMyStoreShip, orderIndex }) => {
    // log('_renderProductItem index => ', index)
    // log('_renderProductItem item => ', item.toJS())
    // log('_renderProductItem section => ', section)
    // const { data, isMine } = section
    // const { name, variant, shipped_qty, qty, thumbnail_uris } = product.toJS()
    const name = product.get('name')
    const variant = product.get('variant')
    const shipped_qty = product.get('shipped_qty')
    const qty = product.get('qty')
    const thumbnail_uri = product.getIn(['thumbnail_uris', 0])
    const remaining_qty = qty - shipped_qty
    // const rowCount = data.length || 0
    // const isLastRow = index === (rowCount - 1) ? true : false

    let waitingQtyTextColor = COLORS.TEXT_ACTIVE
    if (canMyStoreShip) {
      waitingQtyTextColor = remaining_qty === 0 ? COLORS.FORM_SUCCESS : COLORS.TEXT_PRIMARY
    }
    return (
      <HStack
        key={`ProductItem_${orderIndex}_${index}`}
        w='full'
        py='2'
        bg={remaining_qty === 0 ? COLORS.FORM_SUCCESS_BG : undefined}
        // alignItems='center'
        borderBottomWidth='1'
        borderBottomColor='muted.400'
        flexWrap='wrap'>
        <Box w='16%'>
          <Image resizeMode='contain' style={{ minHeight: 28, minWidth: 4, width: '100%' }} source={{ uri: thumbnail_uri }} />
        </Box>
        <VStack w='36%' pl='1' flexWrap='wrap'>
          <XText w='full'>{`${name}`}</XText>
          <XText w='full' variant='inactive'>{`${variant || ''}`}</XText>
        </VStack>
        <XText w='16%' px='1' textAlign='right' variant='inactive'>{`${qty}`}</XText>
        <XText w='16%' px='1' textAlign='right' variant='inactive'>{`${shipped_qty}`}</XText>
        <XText w='16%' px='1' textAlign='right' color={waitingQtyTextColor}>{`${remaining_qty === 0 ? '-' : remaining_qty}`}</XText>
        {/* { isLastRow ? this._renderTableTail({ section }) : <View /> } */}
      </HStack>
    )
  }

  _renderHeaderCard = ({
    canMyStoreShip,
    return_parent_order,
    storeName,
    order_name,
    shippingOrderId,
    amountWaitingQty,
    autoCompleteBtnVisibility,
  }) => {
    // log('__renderPartnerSectionHeader section => ', section)
    // const { canMyStoreShip, title, order_name } = section
    // log('_renderPartnerSectionHeader { store_id, order_id } => ', { store_id, order_id })
    const store_id = xUtil.getNavParam(this.props, 'store_id', null)

    return (
      <VStack w='full'>
        <HStack w='full'>
          <VStack flex={1} flexWrap='wrap'>
            <XText numberOfLines={1} bold>
              {storeName}
            </XText>
            <XText
              style={{
                fontSize: STYLES.FONT_SIZE_NORMAL,
                fontWeight: '400',
                color: COLORS.TEXT_INACTIVE,
              }}>
              {`ออเดอร์ #${order_name}`}
            </XText>
          </VStack>
          {canMyStoreShip ? <XButton onPress={this.navToShipView}>แจ้งจัดส่ง</XButton> : null}
        </HStack>
        {amountWaitingQty > 0 && autoCompleteBtnVisibility ? (
          <HStack mt='2'>
            <XButton
              variant='outline'
              size='xs'
              onPress={() =>
                this._autoCompleteShipping({ store_id, return_parent_order, order_id: shippingOrderId, storeName, amountWaitingQty })
              }>
              ปิดการจัดส่งแบบไม่ระบุเลขพัสดุ
            </XButton>
            <HelpButton
              style={{ marginLeft: 10, marginTop: 0 }}
              title='ปิดการจัดส่งแบบไม่ระบุเลขพัสดุ'
              message={`แจ้งจัดส่งแบบไม่ระบุเลขพัสดุโดยระบุว่า\n สินค้าที่รอจัดส่งทั้งหมด ${amountWaitingQty} ชิ้น โดย${storeName} ได้รับการจัดส่งแล้ว`}
            />
          </HStack>
        ) : null}
        {/* { this._renderTableHead() } */}
      </VStack>
    )
  }

  _renderProductsTableHead = () => (
    <HStack w='full' mt='2' py='2' borderBottomWidth='1' borderBottomColor='muted.400' alignItems='center' flexWrap='wrap'>
      <Box h='1' w='16%' />
      <HStack w='36%' justifyContent='center' flexWrap='wrap'>
        {this._renderTableHeadText('รายการสินค้า')}
      </HStack>
      <HStack w='16%' justifyContent='flex-end'>
        {this._renderTableHeadText('ทั้งหมด')}
      </HStack>
      <HStack w='16%' justifyContent='flex-end'>
        {this._renderTableHeadText('ส่งแล้ว')}
      </HStack>
      <HStack w='16%' justifyContent='flex-end'>
        {this._renderTableHeadText('รอจัดส่ง')}
      </HStack>
    </HStack>
  )

  _renderProductsTableTail = ({ amountShippedQty, amountQty, amountWaitingQty, canMyStoreShip }) => (
    // log('_renderTableTail => section : ', section)
    <HStack w='full' flexWrap='wrap'>
      <Box minH='1' w='16%' />
      <HStack w='36%' justifyContent='flex-end' flexWrap='wrap'>
        <XText bold textAlign='right'>
          รวม
        </XText>
      </HStack>
      <XText w='16%' px='1' textAlign='right' bold>{`${amountQty}`}</XText>
      <XText w='16%' px='1' textAlign='right' bold>{`${amountShippedQty}`}</XText>
      <XText
        w='16%'
        px='1'
        textAlign='right'
        bold
        style={{
          color: amountWaitingQty > 0 && canMyStoreShip ? COLORS.TEXT_PRIMARY : COLORS.TEXT_ACTIVE,
        }}>
        {`${amountWaitingQty === 0 ? '-' : amountWaitingQty}`}
      </XText>
    </HStack>
  )

  _renderTableHeadText = (text) => (
    <XText variant='inactive' fontSize='xs'>
      {text}
    </XText>
  )

  _handleGoBack = () => {
    xUtil.navGoBack(this.props)
  }

  renderCustomHeader = () => (
    <XCustomHeader
      headerLeftProps={{ backIcon: true, onPressItem: this._handleGoBack }}
      title={this.getHeaderTitleText()}
      // headerStyle={p.op.isAndroid() ? { marginTop: 0 } : {}}
    />
  )

  renderContent = () => (
    <XContent>
      <VStack w='full' p='1' space='2'>
        {this._renderPartnerShipment()}
      </VStack>
    </XContent>
  )

  renderMain = () => {
    if (!this.state.isInitialized) {
      return null
    }
    return (
      <XContainer>
        {this.renderCustomHeader()}
        {this.renderContent()}
      </XContainer>
    )
  }

  render() {
    return this.renderMain()
  }
}

const s = StyleSheet.create({
  webMaxWidthMaxHeight: {
    ...Platform.select({
      web: {
        width: '100%',
        height: '100%',
        flexBasis: 'auto',
      },
    }),
  },
  webRectBorderTable: {
    ...Platform.select({
      web: {
        borderTopWidth: 1,
        borderRightWidth: 1,
        borderBottomWidth: 1,
        borderLeftWidth: 1,
        height: 26,
      },
    }),
  },
  webNoBorderBottomWidth: {
    ...Platform.select({
      web: {
        borderBottomWidth: 0,
      },
    }),
  },
  webNoBorderLeftWidth: {
    ...Platform.select({
      web: {
        borderLeftWidth: 0,
      },
    }),
  },
})

export default BaseUIOrderShipListView
