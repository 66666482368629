import React from 'react'
import _ from 'lodash'

import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'

import { IXShippingConfig } from 'x/config/app'
import api from 'x/utils/api'
import { IXScreenProps } from 'x/types'

export interface BaseStoreSettingXShippingSCGViewProps extends IXScreenProps {
  selectedStore: any
  getXShippingConfigFromRedux: IXShippingConfig[]
  setXShippingConfig: (newConfig: IXShippingConfig[]) => void
}
// isSCGxShipjungCODLoading
export interface BaseStoreSettingXShippingSCGViewState {
  // J&T X Shipjung
  useSCGxShipjung: boolean
  useAutoXShippingIsSCGxShipjungReadyToShip: boolean
  loadingSCGxShipjungWidth: number
  loadingSCGxShipjungHeight: number
  useSCGxShipjungCOD: boolean
  isSCGxShipjungLoading: boolean
  useAutoXShippingIsSCGxShipjungCODReadyToShip: boolean
  // useAutoJAndTxShipjungCreateCODPaymentWhenDelivered: boolean
  loadingSCGxShipjungCODWidth: number
  loadingSCGxShipjungCODHeight: number
  isSCGxShipjungCODLoading: boolean
}

// const STATE_KEY_FLASH_X_SHIPJUNG_FOR_UPDATE = {
//   useJAndT: 'useJAndT',
//   useAutoXShippingIsJAndTReadyToShip: 'useAutoXShippingIsJAndTReadyToShip',
//   useJAndTCOD: 'useJAndTCOD',
//   useAutoXShippingIsJAndTCODReadyToShip: 'useAutoXShippingIsJAndTCODReadyToShip',
//   useAutoJAndTCreateCODPaymentWhenDelivered: 'useAutoJAndTCreateCODPaymentWhenDelivered',
// }

const STATE_KEY_FLASH_X_SHIPJUNG_FOR_UPDATE = {
  useSCGxShipjung: 'useSCGxShipjung',
  useAutoXShippingIsSCGxShipjungReadyToShip: 'useAutoXShippingIsSCGxShipjungReadyToShip',
  useSCGxShipjungCOD: 'useSCGxShipjungCOD',
  useAutoXShippingIsSCGxShipjungCODReadyToShip: 'useAutoXShippingIsSCGxShipjungCODReadyToShip',
}

export default abstract class BaseStoreSettingXShippingSCGView extends React.Component<
  BaseStoreSettingXShippingSCGViewProps,
  BaseStoreSettingXShippingSCGViewState
> {
  // DISPLAY_TABS: IUserListTab[]
  // _tabRef: React.RefObject<any>
  // helperProfileId: number

  protected constructor(props) {
    super(props)

    this.state = {
      // J&T X Shipjung
      useSCGxShipjung: false,
      useAutoXShippingIsSCGxShipjungReadyToShip: true,
      loadingSCGxShipjungWidth: 0,
      loadingSCGxShipjungHeight: 0,
      isSCGxShipjungLoading: false,
      useSCGxShipjungCOD: false,
      useAutoXShippingIsSCGxShipjungCODReadyToShip: true,
      // useAutoJAndTxShipjungCreateCODPaymentWhenDelivered: true,
      loadingSCGxShipjungCODWidth: 0,
      loadingSCGxShipjungCODHeight: 0,
      isSCGxShipjungCODLoading: false,
    }
    // this._tabRef = React.createRef()
  }

  async componentDidMount() {
    const { getXShippingConfigFromRedux } = this.props
    // const { state } = navigation
    // const { tabIndex, permissionList, permission_id } = state.params
    await this.setKerryConfig(getXShippingConfigFromRedux)
  }

  setKerryConfig = async (config: IXShippingConfig[]) => {
    if (_.isNil(config)) {
      return
    }
    if (_.isArray(config) && config.length > 0) {
      // Ninja x Shipjung
      let useSCGxShipjung = false
      let useAutoXShippingIsSCGxShipjungReadyToShip = false
      let useSCGxShipjungCOD = false
      let useAutoXShippingIsSCGxShipjungCODReadyToShip = false
      // let useAutoJAndTxShipjungCreateCODPaymentWhenDelivered = false
      config.map((config: IXShippingConfig) => {
        if (config.shipping_type_id === 13) {
          // Ninja x Shipjung
          useSCGxShipjung = true
          useAutoXShippingIsSCGxShipjungReadyToShip = config.set_auto_request_xshipping_on_create_order
            ? config.set_auto_request_xshipping_on_create_order
            : false
        } else if (config.shipping_type_id === 28) {
          // Ninja x Shipjung COD
          useSCGxShipjungCOD = true
          useAutoXShippingIsSCGxShipjungCODReadyToShip = config.set_auto_request_xshipping_on_create_order
            ? config.set_auto_request_xshipping_on_create_order
            : false
        }
      })
      await util.setStatePromise(this, {
        // Ninja x Shipjung
        useSCGxShipjung,
        useAutoXShippingIsSCGxShipjungReadyToShip,
        useSCGxShipjungCOD,
        useAutoXShippingIsSCGxShipjungCODReadyToShip,
      })
    }
  }

  useJAndTOnChange = (newValue: boolean) => {
    util.setStatePromise(this, {
      useJAndT: newValue,
      isJAntTLoading: true,
    })
  }

  // useAutoXShippingIsJAndTReadyToShipOnChange = (newValue: boolean) => {
  //   util.setStatePromise(this, {
  //     useAutoXShippingIsJAndTReadyToShip: newValue,
  //   })
  // }

  // useJAndTCODOnChange = (newValue: boolean) => {
  //   util.setStatePromise(this, {
  //     useJAndTCOD: newValue,
  //   })
  // }

  // useAutoXShippingIsJAndTCODReadyToShipOnChange = (newValue: boolean) => {
  //   util.setStatePromise(this, {
  //     useAutoXShippingIsJAndTCODReadyToShip: newValue,
  //   })
  // }

  _setNewConfig = async (newValue: { isValue: boolean; stateKey: string }) => {
    // console.log('newValue => ', newValue)
    const { selectedStore, navigation, setXShippingConfig } = this.props
    const body: { [key: string]: any } = {
      store_id: selectedStore.get('id'),
    }

    let apiMethod = api.patchV2
    const STATE_KEY = newValue.stateKey
    const IS_VALUE = newValue.isValue
    // console.log('STATE_KEY => ', STATE_KEY)
    if (STATE_KEY === STATE_KEY_FLASH_X_SHIPJUNG_FOR_UPDATE.useSCGxShipjung) {
      if (IS_VALUE) {
        p.op.showConfirmationOkOnly('', p.op.t('xshipping.warning.how_to_use_xshipping'))
        await util.setStatePromise(this, {
          [STATE_KEY]: !IS_VALUE,
        })
      } else {
        p.op.showConfirmationOkOnly('', p.op.t('xshipping.warning.how_to_un_use_xshipping'))
        await util.setStatePromise(this, {
          [STATE_KEY]: !IS_VALUE,
        })
      }
      return
      // await util.setStatePromise(this, {
      //   isJAntTLoading: true,
      // })
      // body.system_id = 3
      // body.shipping_type_id = 38
      // if (IS_VALUE) {
      //   apiMethod = api.putV2
      //   body.set_auto_request_xshipping_on_create_order = true
      // } else {
      //   apiMethod = api.delV2
      // }
    }
    if (STATE_KEY === STATE_KEY_FLASH_X_SHIPJUNG_FOR_UPDATE.useAutoXShippingIsSCGxShipjungReadyToShip) {
      await util.setStatePromise(this, {
        isSCGxShipjungLoading: true,
      })
      body.shipping_type_id = 13
      body.system_id = 2
      body.set_auto_request_xshipping_on_create_order = IS_VALUE
    } else if (STATE_KEY === STATE_KEY_FLASH_X_SHIPJUNG_FOR_UPDATE.useSCGxShipjungCOD) {
      // console.log('PASS => ', IS_VALUE)
      if (IS_VALUE) {
        p.op.showConfirmationOkOnly('', p.op.t('xshipping.warning.how_to_use_xshipping'))
        await util.setStatePromise(this, {
          [STATE_KEY]: !IS_VALUE,
        })
      } else {
        p.op.showConfirmationOkOnly('', p.op.t('xshipping.warning.how_to_un_use_xshipping'))
        await util.setStatePromise(this, {
          [STATE_KEY]: !IS_VALUE,
        })
      }
      return
      // await util.setStatePromise(this, {
      //   isJAndTCODLoading: true,
      // })
      // body.shipping_type_id = 39
      // body.system_id = 3
      // if (IS_VALUE) {
      //   apiMethod = api.putV2
      //   body.set_auto_request_xshipping_on_create_order = true
      //   body.create_cod_payment_when_delivered = false
      // } else {
      //   apiMethod = api.delV2
      // }
    } else if (STATE_KEY === STATE_KEY_FLASH_X_SHIPJUNG_FOR_UPDATE.useAutoXShippingIsSCGxShipjungCODReadyToShip) {
      await util.setStatePromise(this, {
        isSCGxShipjungCODLoading: true,
      })
      body.shipping_type_id = 28
      body.system_id = 2
      body.set_auto_request_xshipping_on_create_order = IS_VALUE
    }

    const isMainBtn =
      STATE_KEY === STATE_KEY_FLASH_X_SHIPJUNG_FOR_UPDATE.useSCGxShipjung ||
      STATE_KEY === STATE_KEY_FLASH_X_SHIPJUNG_FOR_UPDATE.useSCGxShipjungCOD
    if (isMainBtn && !IS_VALUE) {
      apiMethod = api.delV2
    }

    const response: { xshipping_config: IXShippingConfig[] } = await apiMethod(api.XSHIPPING_CONFIG, body)
    if (response.xshipping_config) {
      setXShippingConfig(response.xshipping_config)
      await this.setKerryConfig(response.xshipping_config)
      // const { state } = navigation
      // const { onPressGoBack } = state.params
      // // console.log('onPressGoBack => ', onPressGoBack)
      const onPressGoBack = util.getNavParam(this.props, 'onPressGoBack')
      if (_.isFunction(onPressGoBack)) {
        onPressGoBack()
      }
    }

    if (
      STATE_KEY === STATE_KEY_FLASH_X_SHIPJUNG_FOR_UPDATE.useSCGxShipjung ||
      STATE_KEY === STATE_KEY_FLASH_X_SHIPJUNG_FOR_UPDATE.useAutoXShippingIsSCGxShipjungReadyToShip
    ) {
      await util.setStatePromise(this, {
        isSCGxShipjungLoading: false,
      })
    } else if (
      STATE_KEY === STATE_KEY_FLASH_X_SHIPJUNG_FOR_UPDATE.useSCGxShipjungCOD ||
      STATE_KEY === STATE_KEY_FLASH_X_SHIPJUNG_FOR_UPDATE.useAutoXShippingIsSCGxShipjungCODReadyToShip
    ) {
      await util.setStatePromise(this, {
        isSCGxShipjungCODLoading: false,
      })
    }

    // console.log('response => ', response)
  }

  isSwitchOnChange = async (newValue: { isValue: boolean; stateKey: string }) => {
    // console.log('isSwitchOnChange => ', newValue)
    // const STATE_KEY = newValue.stateKey
    // const IS_VALUE = newValue.isValue
    // let alertText = ''
    // if (STATE_KEY === STATE_KEY_FLASH_X_SHIPJUNG_FOR_UPDATE.useJAndT) {
    //   alertText = IS_VALUE ? 'ยืนยันการใช้งานออกเลขพัสดุ Kerry Express ใช่หรือไม่' : 'ยกเลิกการใช้งานออกเลขพัสดุ Kerry Express ใช่หรือไม่'
    // } else if (STATE_KEY === STATE_KEY_FLASH_X_SHIPJUNG_FOR_UPDATE.useAutoXShippingIsJAndTReadyToShip) {
    //   alertText = IS_VALUE ? 'ยืนยันการใช้งาน "ขอเลขพัสดุเมื่อพร้อมจัดส่ง" Kerry Express ใช่หรือไม่' : 'ยกเลิกการใช้งาน "ขอเลขพัสดุเมื่อพร้อมจัดส่ง" Kerry Express ใช่หรือไม่'
    // } else if (STATE_KEY === STATE_KEY_FLASH_X_SHIPJUNG_FOR_UPDATE.useJAndTCOD) {
    //   alertText = IS_VALUE ? 'ยืนยันการใช้งาน "ขอเลขพัสดุเมื่อพร้อมจัดส่ง" Kerry Express [COD] ใช่หรือไม่' : 'ยกเลิกการใช้งาน "ขอเลขพัสดุเมื่อพร้อมจัดส่ง" Kerry Express [COD] ใช่หรือไม่'
    // } else if (STATE_KEY === STATE_KEY_FLASH_X_SHIPJUNG_FOR_UPDATE.useAutoXShippingIsJAndTCODReadyToShip) {
    //   alertText = IS_VALUE ? 'ยืนยันการใช้งาน "ขอเลขพัสดุเมื่อพร้อมจัดส่ง" Kerry Express [COD] ใช่หรือไม่' : 'ยกเลิกการใช้งาน "ขอเลขพัสดุเมื่อพร้อมจัดส่ง" Kerry Express [COD] ใช่หรือไม่'
    // } else if (STATE_KEY === STATE_KEY_FLASH_X_SHIPJUNG_FOR_UPDATE.useAutoJAndTCreateCODPaymentWhenDelivered) {
    //   alertText = IS_VALUE ? 'ยืนยันการใช้งาน "บันทึกรับชำระเมื่อพัสดุถึงผู้รับแล้ว" ใช่หรือไม่' : 'ยกเลิกการใช้งาน "บันทึกรับชำระเมื่อพัสดุถึงผู้รับแล้ว" ใช่หรือไม่'
    // }

    // let isConfirm = false
    // await new Promise(async (resolve) => {
    //   p.op.showConfirmation('', alertText, () => {
    //     isConfirm = true
    //     // @ts-ignore
    //     resolve(null)
    //   }, () => {
    //     // @ts-ignore
    //     resolve(null)
    //   }, 'ตกลง', 'ยกเลิก')
    // })

    // if (!isConfirm) {
    //   await util.setStatePromise(this, {
    //     [newValue.stateKey]: !IS_VALUE,
    //   })
    //   return
    // }
    await this._setNewConfig(newValue)
    // util.setStatePromise(this, {
    //   [newValue.stateKey]: newValue.isValue,
    // })
  }
}
