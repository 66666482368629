import { bindActionCreators } from 'redux'
import * as OrderActions from 'x/modules/order/OrderState'
import { getMyStores } from '../../redux/selectors'

export function getState(state) {
  return {
    myStores: getMyStores(state),
    selectedOrder: state.getIn(['order', 'selectedOrder']),
  }
}

export function getDispatch(dispatch) {
  return {
    autoCompleteShipping: bindActionCreators(OrderActions.autoCompleteShipping, dispatch),
    cancelShipping: bindActionCreators(OrderActions.cancelShipping, dispatch),
    fetchOrderDetail: bindActionCreators(OrderActions.fetchOrderDetail, dispatch),
    shouldFetchOrderDetail: bindActionCreators(OrderActions.shouldFetchOrderDetail, dispatch),
    shippingStatus: bindActionCreators(OrderActions.getShippingStatus, dispatch),
  }
}
