import React from 'react'
import { STYLES } from 'x/config/styles'

import BaseReportSalesByChannelView from 'x/modules/report/BaseReportSalesByChannelView'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import Segment from 'xui/components/Segment'
import XCard from 'xui/components/XCard'
import Box from 'xui/components/Box'
import XText from 'xui/components/XText'
import XButton from 'xui/components/XButton'
import HStack from 'xui/components/HStack'
import XIcon from 'xui/components/XIcon'
import * as util from 'x/utils/util'

const { NO_MARGIN_PADDING } = STYLES

export default abstract class BaseUIReportSalesByChannelView extends BaseReportSalesByChannelView {
  static displayName = 'BaseUIReportSalesByChannelView'

  abstract _downloadReportShippingView(url: string, fileName: string): Promise<void>

  abstract _renderShippingDateRange: () => JSX.Element

  _handleOnDownloadFile = async (url: string, fileName: string) => {
    await this._downloadReportShippingView(url, fileName)
  }

  _getParams(): { store_id: number } {
    // return this.props.navigation.state.params
    const params = util.getNavParams(this.props)
    return params
  }

  _renderHeaderView = () => (
    <XCustomHeader title='รายงานยอดขายตามช่องทาง' headerLeftProps={{ backIcon: true, onPressItem: () => util.navGoBack(this.props) }} />
  )

  _renderSegment = (optKey: string) => {
    const txtTitle = this.TXT_TITLES[optKey]
    const options = this.OPTIONS[optKey]
    const selectedValue = this.state.selectedOpts[optKey]
    return (
      <Box w='full' mt={optKey === 'PERIOD' ? '2' : '0'}>
        <Box>
          <XText bold variant='active'>
            {txtTitle}
          </XText>
        </Box>
        <Box>
          <Segment
            onSegmentChange={(newValue: number) => this._onSegmentWithKeyChange(optKey, newValue)}
            options={options}
            selectedIndex={selectedValue}
          />
        </Box>
      </Box>
    )
  }

  _renderFooter = () => {
    const { loading } = this.state
    return (
      <HStack w='full' p='1'>
        <XButton
          leftIcon={<XIcon family='FontAwesome' name='file-excel-o' size='xs' />}
          py='3'
          w='full'
          disabled={loading}
          onPress={() => this._downloadReportRequisitionUrl()}>
          ดาวน์โหลด Excel
        </XButton>
      </HStack>
    )
  }

  render() {
    return (
      <XContainer>
        {this._renderHeaderView()}
        <XContent p='2'>
          <XCard w='full' p='2'>
            {this._renderSegment('REPORT_TYPE')}
            {this._renderSegment('PERIOD')}
            {this._renderShippingDateRange()}
          </XCard>
        </XContent>
        {this._renderFooter()}
      </XContainer>
    )
  }
}
