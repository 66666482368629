/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-unused-class-component-methods */
import React from 'react'
import api, { fetchPaymentAccounts } from 'x/utils/api'
import { IApiOptions, IERPPaymentAccount, IPaymentAccount, ISelectedStoreMap, IXScreenProps } from 'x/types'
import * as util from 'x/utils/util'
import * as NavActions from 'x/utils/navigation'
import _ from 'lodash'

interface IBaseERPPaymentAccountViewProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
}

interface IBaseERPPaymentAccountViewState {
  payments: IPaymentAccount[]
  erpPaymentAccounts: IERPPaymentAccount[]
}

export default abstract class BaseERPPaymentAccountView extends React.Component<
  IBaseERPPaymentAccountViewProps,
  IBaseERPPaymentAccountViewState
> {
  // abstract async handleSubmitForm(response: any): Promise<void>
  // abstract didMountCheckPermissionForPhone?: () => void
  // validationSchema: Yup.ObjectSchema<any>
  // imgManagerIDRef: React.RefObject<any>
  // webview: React.RefObject<any>

  constructor(props) {
    super(props)
    this.state = {
      payments: null,
      erpPaymentAccounts: null,
    }
    // this.webview = React.createRef()
    // this.imgManagerIDRef = React.createRef()
  }

  async componentDidMount() {
    await this._fetchPaymentAccounts()
    await this._fetchErpPaymentAccounts()
  }

  _fetchPaymentAccounts = async () => {
    const { selectedStore } = this.props
    const reqBody = {
      store_id: selectedStore.get('id'),
    }
    const paymentAccounts = await fetchPaymentAccounts(reqBody)
    // console.log('paymentAccounts => ', paymentAccounts)
    await util.setStatePromise(this, {
      payments: paymentAccounts,
    })
  }

  _fetchErpPaymentAccounts = async () => {
    const { selectedStore } = this.props
    const store_id = selectedStore.get('id')
    const params = util.getNavParams(this.props)
    const { erpChannel } = params
    const reqBody = {
      store_id,
      erp_ch_id: erpChannel.id,
    }

    const apiOptions: IApiOptions = {
      showSpinner: true,
    }

    const res = await api.postV2('erp/payment_account/lists', reqBody, apiOptions)
    // console.log('res => ', res)
    await util.setStatePromise(this, {
      erpPaymentAccounts: res.erp_payment_accounts,
    })
  }

  goBack = () => {
    util.navGoBack(this.props)
  }

  _getBindAccount = (paymentAccountId: number) => {
    const { erpPaymentAccounts } = this.state
    if (_.isNil(erpPaymentAccounts) || erpPaymentAccounts.length < 1) {
      return null
    }
    let haveBindingAccount = null
    erpPaymentAccounts.forEach((acc) => {
      if (acc.payment_account_id === paymentAccountId) {
        haveBindingAccount = acc
      }
    })
    return haveBindingAccount
  }

  _updateNewErpPayment = (newErpPaymentAccounts: IERPPaymentAccount[]) => {
    this.setState({
      erpPaymentAccounts: newErpPaymentAccounts,
    })
  }

  _updateNewPaymentList = (newPaymentList: IPaymentAccount[]) => {
    const newPaymentsSort = util.sortPaymentAccounts(newPaymentList)
    this.setState({
      payments: newPaymentsSort,
    })
  }

  _navToERPEditPaymentAccountView = (erpPaymentAccount: IERPPaymentAccount, payment: IPaymentAccount) => {
    const { navigation, selectedStore } = this.props
    const params = util.getNavParams(this.props)
    const { erpChannel } = params
    navigation.dispatch(
      NavActions.navToERPEditPaymentAccountView({
        store_id: selectedStore.get('id'),
        erp_paymen_account: erpPaymentAccount,
        payment,
        erpChannel,
        callBackUpdateErpPayment: (newErpPaymentAccounts) => this._updateNewErpPayment(newErpPaymentAccounts),
        callBackUpdatePaymentList: (newPaymentList) => this._updateNewPaymentList(newPaymentList),
      })
    )
  }
}
