import React from 'react'
import { Text, View, Image } from 'react-native'

import { List } from 'immutable'
import FastImage from 'react-native-fast-image'

import * as util from 'x/utils/util'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContent from 'xui/components/XContent'
import XContainer from 'xui/components/XContainer'
import { STYLES, COLORS, S } from 'x/config/styles'
import XIcon from 'xui/components/XIcon'
import * as NavActions from 'x/utils/navigation'
import { ISelectedStoreMap, ImmutableObject, IProductDetailItem, IMKPChannelDetail, IXScreenProps } from 'x/index'
// import { getXsellyLogo, getShopeeLogo, getLazadaLogo } from 'xui/utils/BaseShareUI'

import { IMkpProductContextProps } from 'x/modules/marketplace/MkpProductContext'
import XSellyLogo from '../../components/XSellyLogo'
import CoreProductInfo from './CoreProductInfo'
import ChannelItemBarButton from '../channels/ChannelItemBarButton'

interface IMkpProductLinkManagerViewProps extends IXScreenProps<{ store_id: number; product_id: number }> {
  selectedStore: ISelectedStoreMap
  selectedProduct: ImmutableObject<IProductDetailItem>
  mkpChannels: List<ImmutableObject<IMKPChannelDetail>>
}

interface IMkpProductLinkManagerViewState {
  isInitialized?: boolean
  inNavProcess?: boolean
}

export default class MkpProductLinkManagerView extends React.Component<
  IMkpProductLinkManagerViewProps & IMkpProductContextProps,
  IMkpProductLinkManagerViewState
> {
  constructor(props) {
    super(props)
    this.state = {
      isInitialized: false,
      inNavProcess: false,
    }
  }

  goBack = () => {
    util.navGoBack(this.props)
  }

  renderCustomHeader = () => {
    const leftBtn = {
      backIcon: true,
      onPressItem: () => this.goBack(),
    }
    return <XCustomHeader title='สลับการผูกสินค้ามาผูกกับสินค้านี้' headerLeftProps={leftBtn} />
  }

  _renderHeaderStoreName = () => (
    <View
      style={{
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: COLORS.FORM_PRIMARY_BG,
        paddingBottom: 4,
        paddingTop: 4,
      }}>
      <XSellyLogo />
      <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE }}>ร้านน้องปัก ของเล่นพัฒนาการเด็ก</Text>
    </View>
  )

  _renderMyStoreName = () => {
    const { selectedStore } = this.props
    const sn = selectedStore.get('name')
    return (
      <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE, fontWeight: 'bold' }} numberOfLines={1}>
        {sn}
      </Text>
    )
  }

  _renderProductName = () => {
    const { selectedProduct } = this.props
    const pn = selectedProduct.get('name')
    return (
      <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE }} numberOfLines={1}>
        {pn}
      </Text>
    )
  }

  _renderProductDesciption = () => {
    const { selectedProduct } = this.props
    const pn = selectedProduct.get('description')
    return (
      <Text style={{ fontSize: STYLES.FONT_SIZE_SMALLER, color: COLORS.TEXT_INACTIVE }} numberOfLines={1}>
        {pn}
      </Text>
    )
  }

  _renderProductThumbnail = () => {
    const { selectedProduct } = this.props
    const tn = selectedProduct.getIn(['thumbnail_uris', '0'])
    return <FastImage style={{ width: 54, height: 54 }} resizeMode='cover' source={{ uri: tn }} />
  }

  _renderProductStoreNameWithIcon = () => {
    const { selectedStore } = this.props
    const sn = selectedStore.get('name')
    return (
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <XSellyLogo width={STYLES.FONT_ICON_SMALLER} height={STYLES.FONT_ICON_SMALLER} />
        <Text style={[S.TEXT_INACTIVE, S.TEXT_SMALL, { marginLeft: 2 }]} numberOfLines={1}>
          {sn}
        </Text>
      </View>
    )
  }

  _renderHeaderMyStore = () => (
    <View style={{ flex: 1, height: 50, borderWidth: 1, borderColor: COLORS.TEXT_INACTIVE, flexDirection: 'row' }}>
      {/* <View style={{ width: 30 }} /> */}
      <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
        <View style={{ width: 60, height: 60, justifyContent: 'center', alignItems: 'center' }}>
          <XSellyLogo />
        </View>
        <View style={{ flex: 1, flexDirection: 'column' }}>
          {this._renderMyStoreName()}
          {/* {this._renderProductName()} */}
        </View>
      </View>
      {/* <View style={{ alignItems: 'center', justifyContent: 'center', width: 50 }}>
          <XIcon
            name={'arrowright'}
            family={'AntDesign'}
            style={{ minWidth: 25, flex: 0, fontSize: STYLES.FONT_ICON_SMALLER, color: COLORS.APP_MAIN }}
          />
        </View> */}
    </View>
  )

  _renderImgAndNameProduct = () => (
    <View style={{ flex: 1, borderColor: COLORS.TEXT_INACTIVE, borderWidth: 1 }}>
      <View style={{ flex: 1, padding: 10, flexDirection: 'row' }}>
        <Image
          resizeMode='contain'
          style={{
            width: 45,
            height: 45,
            // marginRight: 4,
          }}
          source={{
            uri:
              'https://cdn.shopify.com/s/files/1/0094/9963/9868/products/bgvl-3-black_1_49eb67a3-d8d8-4d01-8f80-cc121f213144_1024x1024@2x.jpg?v=1570334768',
          }}
        />

        <View style={{ flex: 1, justifyContent: 'center' }}>
          <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE }}>ถุงผ้าชุด Love Chick</Text>
        </View>
      </View>
    </View>
  )

  _renderTitleWithIcon = (
    iconName: string,
    iconFamily:
      | 'AntDesign'
      | 'Entypo'
      | 'EvilIcons'
      | 'Feather'
      | 'FontAwesome'
      | 'FontAwesome5'
      | 'Foundation'
      | 'Ionicons'
      | 'MaterialCommunityIcons'
      | 'MaterialIcons'
      | 'Octicons'
      | 'SimpleLineIcons'
      | 'Zocial',
    txtLable: string
  ) => (
    <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', marginBottom: 4 }}>
      <XIcon
        name={iconName}
        family={iconFamily}
        style={{ minWidth: 25, flex: 0, fontSize: STYLES.FONT_ICON_SMALLER, color: COLORS.TEXT_ACTIVE }}
      />
      <Text style={S.TEXT_ACTIVE}>{txtLable}</Text>
    </View>
  )

  _getChannelNameByChannelId = (id: number): string | null => {
    const { mkpChannels } = this.props
    const focusChannel = mkpChannels.find((ch) => ch.get('id') === id)
    if (!focusChannel) {
      return null
    }
    return focusChannel.get('name')
  }

  _getConnectedMkpChannelIds = (): number[] => {
    const { selectedProduct } = this.props
    return selectedProduct.get('mkp_channel_ids').toArray()
  }

  _getNotConnectedMkpChannelIds = (): number[] => {
    const connectedMkpChannels = this._getConnectedMkpChannelIds()
    const { mkpChannels } = this.props
    const allMkpIds = mkpChannels.map((ch) => ch.get('id')).toJS()
    return allMkpIds.filter((itemId) => !connectedMkpChannels.includes(itemId))
  }

  // _filterConnectedMkpChannel = (ch) => this._getConnectedMkpChannelIds().includes(ch.get('id'))
  // _renderConnectedMkpChannelItems = () => {
  //   const { mkpChannels } = this.props
  //   return mkpChannels.filter(this._filterConnectedMkpChannel).map(this._renderConnectedMkpChannelItem)
  // }
  // _renderConnectedMkpChannelItem = (mkpCh: ImmutableObject<IMKPChannelDetail>, index: number) => {
  //   const chId = mkpCh.get('id')
  //   const chName = mkpCh.get('name')
  //   const chMkpId = mkpCh.get('mkp_id')
  //   return (
  //     <TouchableOpacity
  //       key={index}
  //       disabled={this.state.inNavProcess}
  //       onPress={() => this._navToProductListViewMkpMapping(chId)}
  //       style={{ flex: 1, height: 50, borderWidth: 1, borderColor: COLORS.TEXT_INACTIVE, flexDirection: 'row' }}>
  //       {/* <TouchableOpacity style={{ backgroundColor: COLORS.BRAND_Danger, width: 30, justifyContent: 'center', alignItems: 'center' }}>
  //         <XIcon
  //           name={'delete'}
  //           family={'AntDesign'}
  //           style={{ minWidth: 25, flex: 0, fontSize: STYLES.FONT_ICON_SMALLER, color: COLORS.WHITE }}
  //         />
  //       </TouchableOpacity> */}
  //       <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
  //         <View style={{ width: 60, height: 60, justifyContent: 'center', alignItems: 'center' }}>
  //           <MkpLogo mkpId={chMkpId} />
  //         </View>

  //         <View style={{ flex: 1, flexDirection: 'column' }}>
  //           <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE, fontWeight: 'bold' }} numberOfLines={1}>
  //             {chName}
  //           </Text>
  //           <Text style={{ fontSize: STYLES.FONT_SIZE_SMALLER, color: COLORS.TEXT_INACTIVE }} numberOfLines={1}>
  //             {`index: ${index}`}
  //           </Text>
  //           {/* <Text style={{ fontSize: STYLES.FONT_SIZE_SMALLER, color: COLORS.TEXT_INACTIVE }} numberOfLines={1}>
  //             {'ผูกกับสินค้า: นวมชกมวยของแท้ สินค้าคุณภาพดี ใช้งานทนทาน ราคาถูก'}
  //           </Text> */}
  //         </View>
  //       </View>
  //       <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: 50 }}>
  //         <ForwardIcon style={{ flex: 0 }} />
  //       </View>
  //     </TouchableOpacity>
  //   )
  // }

  _filterNotConnectedMkpChannel = (ch) => this._getNotConnectedMkpChannelIds().includes(ch.get('id'))

  _renderNotConnectedMkpChannelItems = () => {
    const { mkpChannels } = this.props
    // const availableToLinkMkpChannels = mkpChannels.filter(util.filterMkpChannelMapIsActive).filter(this._filterNotConnectedMkpChannel)
    const availableToLinkMkpChannels = mkpChannels.filter(util.filterMkpChannelMapIsActive)
    if (availableToLinkMkpChannels.size === 0) {
      return (
        <View style={[S.ROW_CENTER, S.WIDTH_FULL]}>
          <Text style={[S.TEXT_ACTIVE, S.TEXT_ALIGN_CENTER]}>ยังไม่มีช่องทางขายอื่นที่พร้อมใช้งาน</Text>
        </View>
      )
    }

    return availableToLinkMkpChannels.map(this._renderNotConnectMkpChannelItem)
  }

  _renderNotConnectMkpChannelItem = (mkpCh: ImmutableObject<IMKPChannelDetail>, index: number) => {
    const chId = mkpCh.get('id')
    const chName = mkpCh.get('name')
    const chMkpId = mkpCh.get('mkp_id')

    return (
      <ChannelItemBarButton key={index} mkpChannel={mkpCh} onPress={this._navToProductListViewMkpMapping} disabledOnStatusDeactivated />
    )
    // return (
    //   <TouchableOpacity
    //     key={index}
    //     disabled={this.state.inNavProcess}
    //     onPress={() => this._navToProductListViewMkpMapping(chId)}
    //     style={{ flex: 1, height: 50, borderWidth: 1, borderColor: COLORS.TEXT_INACTIVE, flexDirection: 'row' }}>
    //     {/* <View style={{ width: 30 }} /> */}
    //     <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
    //       <View style={{ width: 60, height: 60, justifyContent: 'center', alignItems: 'center' }}>
    //         <MkpLogo mkpId={chMkpId} />
    //       </View>

    //       <View style={{ flex: 1, flexDirection: 'column' }}>
    //         <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE, fontWeight: 'bold' }} numberOfLines={1}>
    //           {chName}
    //         </Text>
    //         <Text style={{ fontSize: STYLES.FONT_SIZE_SMALLER, color: COLORS.TEXT_INACTIVE }} numberOfLines={1}>
    //           {`mkp_channel_id: ${chId}`}
    //         </Text>
    //         {/* <Text
    //           style={{ fontSize: STYLES.FONT_SIZE_SMALLER, color: COLORS.TEXT_INACTIVE }}
    //           numberOfLines={1}>{`ยังไม่ได้ผูกสินค้า`}</Text> */}
    //       </View>
    //     </View>
    //     <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: 50 }}>
    //       <ForwardIcon style={{ flex: 0 }} />
    //     </View>
    //   </TouchableOpacity>
    // )
  }

  _navToProductListViewMkpMapping = async (mkp_ch_id: number) => {
    if (this.state.inNavProcess) {
      return
    }
    await util.setStatePromise(this, { inNavProcess: true })
    await util.delay(450)
    const { navigation, mkpChannels } = this.props
    const store_id = util.getNavParam(this.props, 'store_id')
    // const product_id = util.getNavParam(this.props, 'product_id')
    const mkpCh = mkpChannels.find((mc) => mc.get('id') === mkp_ch_id)
    navigation.dispatch(
      // @ts-ignore
      NavActions.navToProductListViewMkpMapping({
        store_id,
        // @ts-ignore
        // product_id,
        // mkp_channel_id,
        mkp_ch_id,
        mkp_id: mkpCh.get('mkp_id'),
      })
    )

    await util.delay(450)
    await util.setStatePromise(this, { inNavProcess: false })
  }

  renderContent = () => {
    const { navigation, mkpProductContext } = this.props
    const ptID = util.getNavParam(this.props, 'product_id')
    const coreProduct = mkpProductContext.getCoreProduct(ptID.toString()) as IProductDetailItem
    return (
      <View style={{ paddingTop: 2, paddingLeft: 4, paddingRight: 4, flex: 1 }}>
        <View style={[S.PADDING_8, S.BG_LIGHT_GREY, { alignItems: 'center', justifyContent: 'center' }]}>
          <Text style={[S.TEXT_INACTIVE]}>
            การดำเนินการสลับการผูกสินค้า คือ การนำสินค้าที่คุณเลือกปัจจุบันไปผูกแทนที่สินค้าที่ถูกผูกอยู่แล้ว เนื่องจากระบบของ XSelly
            จะทำการผูกสินค้าตามช่องทางขายต่างๆ ให้คุณโดยอัตโนมัติ ดังนั้น การใช้การดำเนินการนี้กรุณาใช้อย่างระวัง
            เนื่องจากจะเปลี่ยนการผูกของสินค้ามีผลกระทบต่อราคาสินค้า และคลังของสินค้า
          </Text>
        </View>
        <View style={[S.CARDLIKE_BORDERS, S.CARDLIKE_MARGIN, S.BG_WHITE]}>
          {/* <View style={S.CARDLIKE_BODY}>
            {this._renderTitleWithIcon('home', 'FontAwesome', 'ร้านของฉัน XSelly')}
            {this._renderHeaderMyStore()}
          </View> */}
          <View style={S.CARDLIKE_BODY}>
            {/* {this._renderTitleWithIcon('shopping-cart', 'FontAwesome', 'สินค้าที่กำลังเลือก')} */}
            <View style={[S.PADDING_VERTICAL_4, { flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }]}>
              <Text style={S.TEXT_ACTIVE}>กำลังจะผูกกับสินค้านี้</Text>
            </View>
            {/* <View style={[S.BORDER, { minHeight: 54, width: '100%', borderColor: COLORS.GREY, flexDirection: 'column' }]}>
              <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                <View style={[S.PADDING_4, { justifyContent: 'center', alignItems: 'center' }]}>{this._renderProductThumbnail()}</View>
                <View
                  style={[
                    S.PADDING_4,
                    { height: '100%', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' },
                  ]}>
                  {this._renderProductStoreNameWithIcon()}
                  {this._renderProductName()}
                  {this._renderProductDesciption()}
                </View>
              </View>
            </View> */}
            <CoreProductInfo product={coreProduct} />
          </View>
        </View>
        {/* <View style={[S.CARDLIKE_BORDERS, S.CARDLIKE_MARGIN, S.BG_WHITE]}>
          <View style={S.CARDLIKE_BODY}>
            {this._renderTitleWithIcon('link', 'FontAwesome', 'ช่องทางที่ผูกสินค้าแล้ว')}
            {this._renderConnectedMkpChannelItems()}
          </View>
        </View> */}
        <View style={[S.CARDLIKE_BORDERS, S.CARDLIKE_MARGIN, S.BG_WHITE]}>
          <View style={[S.PADDING_HORIZONTAL_8, { paddingTop: 8 }]}>
            <Text style={[S.TEXT_INACTIVE]}>เลือกช่องทางขายที่ต้องการสลับการผูกสินค้า...</Text>
          </View>
          <View style={S.CARDLIKE_BODY}>
            {/* {this._renderTitleWithIcon('unlink', 'FontAwesome', 'ช่องทางที่ยังไม่ได้ผูกสินค้า')} */}
            {this._renderNotConnectedMkpChannelItems()}
          </View>
        </View>
      </View>
    )
  }

  renderMain = () => (
    <XContainer style={{ backgroundColor: COLORS.BG_LIGHT_GREY }}>
      {this.renderCustomHeader()}
      <XContent>{this.renderContent()}</XContent>
    </XContainer>
  )

  render() {
    return this.renderMain()
  }
}
