import React from 'react'
import _ from 'lodash'
import { StyleSheet, Image } from 'react-native'
import { IImageProps } from 'native-base'

import theme from 'x/config/nativebase/theme'
import { getComputedCustomStyledProps } from 'x/config/nativebase/makeStyledComponent'

import FastImage from 'react-native-fast-image'

const emptyImageSrc = require('../images/empty_image.png')

export type IXImageProps = IImageProps
// export interface IXImageProps extends IImageProps {
//   uri?: string
// }

const XImage = React.forwardRef<Image, IXImageProps>((props, parentRef) => {
  const { source, style = {}, ...restRNProps } = props
  const { styleFromProps, underscoreProps, restProps } = getComputedCustomStyledProps(restRNProps, 'Image')

  if (
    _.isNil(source) ||
    // @ts-ignore
    source === '' ||
    // @ts-ignore
    (_.isObject(source) && !_.isString(source.uri)) ||
    // @ts-ignore
    (_.isObject(source) && _.isString(source.uri) && source.uri === '')
  ) {
    return (
      <Image
        // @ts-ignore
        ref={parentRef}
        source={emptyImageSrc}
        {...restProps}
        style={[styleFromProps, StyleSheet.flatten(style)]}
      />
    )
  }

  return (
    // @ts-ignore
    <FastImage
      // @ts-ignore
      ref={parentRef}
      source={source}
      {...restProps}
      style={[styleFromProps, StyleSheet.flatten(style)]}
    />
  )
})

XImage.displayName = 'XImage'

// @ts-ignore
XImage.defaultProps = { theme }

// @ts-ignore
export default XImage
