import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import StoreSettingAllPrintingShippingLabelView from './StoreSettingAllPrintingShippingLabelView'
import * as StoreActions from 'x/modules/store/StoreState'
import { getSelectedStore, getEditingStore } from 'x/redux/selectors'

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
    editingStore: getEditingStore(state),
  }),
  (dispatch) => {
    return {
      resetEditingStore: bindActionCreators(StoreActions.resetEditingStore, dispatch),
      // storeActions: bindActionCreators(StoreActions, dispatch),
    }
  }
)(StoreSettingAllPrintingShippingLabelView)
