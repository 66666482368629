// import PropTypes from 'prop-types'
import React from 'react'

import { TouchableOpacity, Image } from 'react-native'

import { COLORS } from 'x/config/styles'
import * as util from 'x/utils/util'
// import QRCodeModal from '../../components/QRCodeModal'
// import ForwardIcon from '../../components/ForwardIcon'
import _ from 'lodash'

import BaseUserGroupResellerTabs from 'x/modules/store/BaseUserGroupResellerTabs'
import XContainer from 'xui/components/XContainer'
import XCustomHeader from 'xui/components/XCustomHeader'
import Box from 'xui/components/Box'
import XText from 'xui/components/XText'
import VStack from 'xui/components/VStack'
import XButton from 'xui/components/XButton'

import HStack from 'xui/components/HStack'
import ResellerListPendingTab from 'xui/modules/userGroup/ResellerListPendingTabContainer'
import ResellerListJoinedTab from 'xui/modules/userGroup/ResellerListJoinedTabContainer'
import ResellerAddUserTab from 'xui/modules/userGroup/ResellerAddUserTabContainer'
import BackIcon from '../../components/BackIcon'

export default abstract class BaseUIUserGroupResellerTabs extends BaseUserGroupResellerTabs {
  // abstract renderQRCodeModal: () => void

  _renderCustomHeader = () => {
    const title = 'รายการสมาชิกในกลุ่ม'
    return (
      <XCustomHeader
        title={title}
        // headerLeftProps={{
        //   backIcon: true,
        //   onPressItem: () => goBack(state.key),
        // }}
        renderHeaderLeft={() => this.renderLeftBtn()}
        // renderHeaderRight={() => this.renderRightBtn()}
      />
    )
  }

  renderLeftBtn = () => (
    // const { state, goBack } = this.props.navigation
    <XButton variant='ghost' mr='2' onPress={() => this._goBack()}>
      <BackIcon />
    </XButton>
  )

  // renderRightBtn = () => {
  //   const { state, goBack } = this.props.navigation
  //   return (
  //     <XButton variant='ghost' mr='2' onPress={() => null}>
  //       {'แก้ไข'}
  //     </XButton>
  //   )
  // }

  _renderTitleHeader = () => {
    const { selectedUserGroup } = this.state
    if (_.isNil(selectedUserGroup)) {
      return null
    }
    return (
      <Box flexDirection='row' p='2' bgColor={COLORS.BG_LIGHT_GREY}>
        {_.isNil(selectedUserGroup.img_uri) ? null : (
          <Box alignItems='center' justifyContent='center' w='10'>
            <Image
              resizeMode='cover'
              style={{
                width: 40,
                height: 40,
                borderWidth: 1,
                borderColor: COLORS.TEXT_INACTIVE,
                borderRadius: 90,
              }}
              source={{ uri: selectedUserGroup.img_uri }}
            />
          </Box>
        )}
        {_.isNil(selectedUserGroup.img_uri) ? null : <Box w='2' />}
        <Box flex={1}>
          <XText variant='active' bold>
            {selectedUserGroup.name}
          </XText>
          <XText variant='inactive' numberOfLines={1}>
            {selectedUserGroup.description}
          </XText>
        </Box>
      </Box>
    )
  }

  _goBack = () => {
    const callBack = util.getNavParam(this.props, 'callBack')
    if (_.isFunction(callBack)) {
      callBack()
    }
    util.navGoBack(this.props)
  }

  _renderTab = () => (
    <HStack borderBottomWidth={2}>
      {this.DISPLAY_TABS.map((tab, index) => {
        const { tabIndex } = this.state
        const isActive = tabIndex === index
        return (
          // @ts-ignore
          <TouchableOpacity
            key={tab.label}
            onPress={() => this._onchangeTab(index)}
            style={{
              backgroundColor: isActive ? COLORS.APP_MAIN : COLORS.WHITE,
              flex: 1,
              // height: 40,
              paddingBottom: 12,
              paddingTop: 12,
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <XText bold color={isActive ? COLORS.WHITE : COLORS.TEXT_ACTIVE}>
              {tab.label}
            </XText>
          </TouchableOpacity>
        )
      })}
    </HStack>
  )

  _renderResellerListPendingTab = () => {
    const { selectedStore } = this.props
    const ug_id = util.getNavParam(this.props, 'ug_id')
    const store_id = selectedStore.get('id')
    return (
      <VStack w='full' flex={1}>
        <ResellerListPendingTab navigation={this.props.navigation} selectedStoreId={store_id} selectedUserGroupId={ug_id} />
      </VStack>
    )
  }

  _renderResellerListJoinedTab = () => {
    const { selectedStore } = this.props
    const ug_id = util.getNavParam(this.props, 'ug_id')
    const store_id = selectedStore.get('id')
    return (
      <VStack w='full' flex={1}>
        <ResellerListJoinedTab navigation={this.props.navigation} selectedStoreId={store_id} selectedUserGroupId={ug_id} />
      </VStack>
    )
  }

  _renderResellerAddUserTab = () => {
    const { selectedStore } = this.props
    const ug_id = util.getNavParam(this.props, 'ug_id')
    const store_id = selectedStore.get('id')
    return (
      <VStack w='full' flex={1}>
        <ResellerAddUserTab navigation={this.props.navigation} selectedStoreId={store_id} selectedUserGroupId={ug_id} />
      </VStack>
    )
  }

  _renderBody = () => {
    const { tabIndex } = this.state
    // console.log('tabIndex => ', tabIndex)
    if (tabIndex < 0) {
      return null
    }
    return (
      <VStack w='full' flex={1}>
        {this._renderTab()}
        {tabIndex === 0 ? this._renderResellerListPendingTab() : null}
        {tabIndex === 1 ? this._renderResellerListJoinedTab() : null}
        {tabIndex === 2 ? this._renderResellerAddUserTab() : null}
      </VStack>
    )
  }

  render() {
    return (
      <XContainer>
        {this._renderCustomHeader()}
        <VStack w='full' flex={1}>
          {this._renderTitleHeader()}
          {this._renderBody()}
        </VStack>
      </XContainer>
    )
  }
}
