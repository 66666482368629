import React from 'react'
import * as util from 'x/utils/util'
import p from 'x/config/platform-specific'
import * as NavActions from 'x/utils/navigation'

import { IMkpProductDetailProps, IMkpProductDetailState } from 'x/index'

import { IMkpProductContextProps } from 'x/modules/marketplace/MkpProductContext'
import XDropdown from '../../components/XDropdown'
import DotDotDotVerticalIcon from '../../components/DotDotDotVerticalIcon'
import BaseUIMkpProductDetail from './BaseUIMkpProductDetail'

type IProps = IMkpProductDetailProps & IMkpProductContextProps
type IState = IMkpProductDetailState

export default class MkpProductDetailView extends BaseUIMkpProductDetail<IProps, IState> {
  // renderCustomHeader = () => {
  //   const { navigation } = this.props
  //   const mkpId = navigation.getParam('mkp_id')
  //   const mkpName = util.getMKPName(mkpId)
  //   const leftBtn = {
  //     backIcon: true,
  //     onPressItem: this.goBack,
  //   }
  //   return (
  //     <XCustomHeader
  //       title={`รายละเอียดสินค้าจาก 11 ${mkpName}`}
  //       headerLeftProps={leftBtn}
  //       renderHeaderRight={this._renderHeaderRightDropdown}
  //     />
  //   )
  // }

  _onHeaderRightDropdownSelected = (rowIndex, rowData) => {
    const { navigation } = this.props
    const { editingMkpProduct, coreProduct } = this.state

    switch (rowIndex) {
      // จัดการผูกตัวเลือกใหม่
      case 0: {
        const errorCode = util.checkMkpProductVariantsMappingError(editingMkpProduct, coreProduct)

        // ในหน้านี้ควรจะเจอแค่กรณีเดียว
        if (errorCode || errorCode === 'ONLY_ONE_VARAINT') {
          p.op.alert('สินค้ามีเพียงตัวเลือกเดียว', 'กรุณาเพิ่มตัวเลือกสินค้าใหม่ เพื่อที่จะนำไปใช้สลับกับตัวเลือกเดิม')
          return
        }

        const params = util.getNavParams(this.props)
        // Go to Mapping View
        navigation.dispatch(
          // @ts-ignore
          NavActions.navToMkpProductDetailMapping({
            // ...navigation.state.params,
            ...params,
            isPreventDestroyWhenNavBack: true,
          })
        )
        break
      }
      default: {
        // No-Op
      }
    }
    // p.op.alert(JSON.stringify(rowIndex), JSON.stringify(rowData))
  }

  // _renderHeaderRightDropdownButtonText = (rowData) => rowData.label

  _renderHeaderRightDropdown = () => {
    const { editingMkpProduct, coreProduct } = this.state
    // TODO: ตอนนี้ต้องเข้าผ่าน XSelly Product ถึงจะสามารถสลับ mapping ได้ อนาคตอาจจะทำ custom fetch coreProduct มามาใช้สลับ
    if (!coreProduct) {
      return null
    }
    // const isDisabled = false
    const opts = [{ label: 'สลับการผูกตัวเลือกสินค้า' }]

    if (!opts.length) {
      return null
    }

    return (
      <XDropdown
        options={opts}
        // options={['11111', '22222', '33333', '444444', '555555', '6666666']}
        // defaultIndex={0}
        // renderButtonText={this._renderHeaderRightDropdownButtonText}
        renderRow={(rowData) => rowData.label}
        onSelect={this._onHeaderRightDropdownSelected}
        // dropdownTextStyle={S.TEXT_PRIMARY}
        // dropdownStyle={{
        //   // height: 150,
        //   height: 38,
        //   // This is quick fix for one item
        //   minHeight: 38,
        //   maxHeight: 38,
        //   width: 140,
        // }}
        // scrollEnabled={false}
        // renderSeparator={() => null}
        // renderRow={(option, index, isSelected) => }
      >
        <DotDotDotVerticalIcon />
      </XDropdown>
    )
  }
}
