/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-unused-class-component-methods */
import { Component } from 'react'

import _ from 'lodash'
// import { COLORS, STYLES } from '../../config/styles'
import { ICategory, ActionApiParams, ISelectedStoreMap, IXScreenProps } from 'x/index'
import { COLORS } from '../../../config/styles'
import p from '../../../config/platform-specific'
import * as util from '../../../utils/util'

const { TEXT_INACTIVE } = COLORS

export interface BaseCategoryPropertyViewProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
  addCategory: (params: ActionApiParams) => void
  deleteCategory: (params: ActionApiParams) => void
  fetchCategory: (params: ActionApiParams) => void
  editCategory: (params: ActionApiParams) => void
  changeCategory: (params: ActionApiParams) => void
}

export interface BaseCategoryPropertyViewState {
  mode: 'VIEW' | 'CREATE' | 'EDIT'
  // pageLoading: boolean
  name: string
  category: ICategory // cat ที่เลือกมา
  color: string
  showColorPinker: boolean
  visibleCategoryList: boolean
  allCategory: ICategory[] | [] // ทั้งหมด
  parentId: number
  newCategorys: ICategory[] // เอาไว้ส่งกลับ call back
}

const VIEW = `VIEW`
const CREATE = 'CREATE'

export default abstract class BaseCategoryPropertyView extends Component<BaseCategoryPropertyViewProps, BaseCategoryPropertyViewState> {
  abstract _goBack(): void

  backupData: {
    name: string
    color: string
  }

  protected constructor(props) {
    super(props)
    this.backupData = {
      name: null,
      color: null,
    }
    this.state = {
      mode: null,
      // pageLoading: true,
      name: '',
      category: null,
      color: TEXT_INACTIVE,
      showColorPinker: false,
      visibleCategoryList: false,
      allCategory: [],
      parentId: null,
      newCategorys: null,
    }
  }

  async componentDidMount() {
    const { navigation, selectedStore } = this.props
    if (_.isNil(selectedStore)) {
      p.op.showToast('เกิดข้อผิดพลาด กรุณาติดต่อทีมงาน XSelly', 'warning')
      this._goBack()
      return
    }
    // const { state } = navigation
    const params = util.getNavParams(this.props)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const mode: 'VIEW' | 'CREATE' | 'EDIT' = params ? params.mode : null
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    let category: ICategory = params ? params.category : null
    let parentId: number = null
    if (!_.isNil(category)) {
      parentId = category.id
    }
    if (mode === CREATE) {
      category = null
    }
    const name: string = _.isNil(category) ? '' : category.n
    // eslint-disable-next-line no-nested-ternary
    const color: string = _.isNil(category) ? TEXT_INACTIVE : _.isNil(category.l) ? TEXT_INACTIVE : category.l
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const allCategory: ICategory[] = params ? params.allCategory : []
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const title: string = _.isNil(params.category) ? 'เพิ่มหมวดหมู่' : params.category.n
    await util.setStatePromise(this, {
      pageLoading: false,
      mode,
      name,
      category,
      color,
      allCategory,
      parentId,
    })
    this.backupData = {
      name,
      color,
    }
    navigation.setParams({
      // handleRightBtn: () => this._handleRightBtn(),
      // handleLeftBtn: () => this._handleLeftBtn(),
      title,
    })
  }

  // _handleLeftBtn = async () => {
  //   const { navigation } = this.props
  //   const { mode, name, color } = this.state
  //   if (mode === VIEW) {
  //     await this._goBackCallBack()
  //   } else if (mode === EDIT) {
  //     await this._submitEditCategory()
  //   } else if (mode === CREATE) {
  //     if (_.isNil(name) || name === '') {
  //       p.op.showConfirmationOkOnly('กรุณาระบุชื่อหมวดหมู่', '')
  //       return
  //     }
  //     await this._createCategory()
  //   }
  // }

  // _handleRightBtn = async () => {
  //   const { navigation } = this.props
  //   const { mode } = this.state
  //   if (mode === VIEW) {
  //     await util.setStatePromise(this, { mode: EDIT })
  //     navigation.setParams({
  //       mode: EDIT,
  //     })
  //   } else if (mode === EDIT) {
  //     this._cancleEdit()
  //   } else if (mode === CREATE) {
  //     this._cancleCreate()
  //   }
  // }

  _goBackCallBack = () => {
    const { navigation } = this.props
    const { newCategorys } = this.state
    // const { state } = navigation
    // // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    // const callBackCE: (category: ICategory[]) => void = state.params ? state.params.callBackCE : null
    const callBackCE = util.getNavParam(this.props, 'callBackCE')
    if (_.isNil(callBackCE) || _.isNil(newCategorys)) {
      this._goBack()
      return
    }
    callBackCE(newCategorys)
    this._goBack()
  }

  _cancleCreate = () => {
    const { name, color } = this.state
    if (name !== this.backupData.name || color !== this.backupData.color) {
      p.op.showConfirmation(
        `ยกเลิกการสร้างหมวดหมู่`,
        `ต้องการยกเลิกการสร้างหมวดหมู่ใช่หรือไม่`,
        () => {
          this._goBack()
        },
        () => null,
        'ใช่',
        'ไม่ใช่'
      )
    } else {
      this._goBack()
    }
  }

  _cancleEdit = async () => {
    const { navigation } = this.props
    const { name, color } = this.state
    if (name !== this.backupData.name || color !== this.backupData.color) {
      p.op.showConfirmation(
        `ยกเลิกการแก้ไข`,
        `ต้องการยกเลิกการแก้ไขใช่หรือไม่`,
        () => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          util.setStatePromise(this, { mode: 'VIEW', name: this.backupData.name, color: this.backupData.color })
        },
        () => null,
        'ใช่',
        'ไม่ใช่'
      )
    } else {
      await util.setStatePromise(this, { mode: 'VIEW', name: this.backupData.name, color: this.backupData.color })
      navigation.setParams({
        mode: VIEW,
      })
    }
  }

  _submitEditCategory = async () => {
    const { navigation, selectedStore, editCategory } = this.props
    const { name, color, parentId, category } = this.state
    const store_id = _.isNil(selectedStore.get('id')) ? null : selectedStore.get('id')
    if (_.isNil(store_id)) {
      return
    }
    const body: { [key: string]: any } = {
      store_id,
      id: category.id,
    }
    if (name !== this.backupData.name) {
      body.name = name.trim()
    }
    if (color !== this.backupData.color) {
      body.color = color
    }
    const res: ICategory[] = await new Promise((resolve) => {
      editCategory({
        body,
        successCallback: resolve,
        failedCallback: resolve,
      })
    })
    if (!_.isArray(res)) {
      return
    }
    let categoryRes: ICategory = null
    if (!_.isArray(res)) {
      // p.op.showConfirmationOkOnly(`เกิดข้อผิดพลาด`, ``)
      return
    }
    // eslint-disable-next-line array-callback-return
    res.map((data: ICategory) => {
      if (data.n.trim() === name.trim()) {
        categoryRes = data
      }
    })
    await util.setStatePromise(this, {
      mode: VIEW,
      category: categoryRes,
      parentId: _.isNil(categoryRes) ? parentId : categoryRes.p,
      newCategorys: res,
    })

    // await util.setStatePromise(this, { mode: VIEW })
    this.backupData = {
      name: categoryRes.n,
      color: categoryRes.l,
    }
    navigation.setParams({
      mode: VIEW,
      title: name,
    })
  }

  _createCategory = async () => {
    const { navigation, addCategory, selectedStore } = this.props
    const { color, name, parentId } = this.state
    const store_id = _.isNil(selectedStore.get('id')) ? null : selectedStore.get('id')
    if (_.isNil(store_id)) {
      return
    }
    const body: { [key: string]: any } = {
      store_id,
      color,
      name: name.trim(),
    }
    if (!_.isNil(parentId)) {
      body.parent_category_id = parentId
    }
    const res: ICategory[] = await new Promise((resolve) => {
      addCategory({
        body,
        successCallback: resolve,
        failedCallback: resolve,
      })
    })
    if (!_.isArray(res)) {
      return
    }
    let category: ICategory = null
    // eslint-disable-next-line array-callback-return
    res.map((data: ICategory) => {
      if (data.n.trim() === name.trim()) {
        category = data
      }
    })

    await util.setStatePromise(this, { mode: VIEW, category, parentId: _.isNil(category) ? parentId : category.p, newCategorys: res })
    navigation.setParams({
      mode: VIEW,
      title: category.n,
    })
    this.backupData = {
      name: category.n,
      color: category.l,
    }
  }

  _onChangeColor = async (color: string) => {
    await util.setStatePromise(this, {
      color,
    })
  }

  _openColorPinker = async () => {
    await util.setStatePromise(this, {
      showColorPinker: true,
    })
  }

  _closeColorPinker = async () => {
    await util.setStatePromise(this, {
      showColorPinker: false,
    })
  }

  _openCategoryList = async () => {
    await util.setStatePromise(this, {
      visibleCategoryList: true,
    })
  }

  _closeCategoryList = async () => {
    await util.setStatePromise(this, {
      visibleCategoryList: false,
    })
  }

  _onChangeCategory = async (text: string) => {
    await util.setStatePromise(this, { name: text })
  }

  _confirmedDeleteStepOne = () => {
    const { name } = this.state
    p.op.showConfirmation(
      `ลบหมวดหมู่ ${name}`,
      `คุณต้องการหมวดหมู่นี้ใช่หรือไม่`,
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      () => this._deleteCategoryStepOne(),
      () => null,
      `ลบ`,
      `ยกเลิก`
    )
  }

  _deleteCategoryStepOne = async () => {
    const { deleteCategory, selectedStore, navigation } = this.props
    // const { state } = navigation
    // const callBackDelete = state.params ? state.params.callBackDelete : null
    const callBackDelete = util.getNavParam(this.props, 'callBackDelete')
    const { category, name } = this.state
    const body: { [key: string]: any } = {
      store_id: selectedStore.get(`id`),
      id: category.id,
    }
    const res: {
      to_be_deleted_category_info: {
        product_count: number
        child_category_count: number
      }
    } = await new Promise((resolve) => {
      deleteCategory({
        body,
        successCallback: resolve,
        failedCallback: resolve,
      })
    })
    if (!_.isNil(res.to_be_deleted_category_info)) {
      const { product_count } = res.to_be_deleted_category_info
      const { child_category_count } = res.to_be_deleted_category_info
      this._confirmedDeleteStepTwo(product_count, child_category_count)
    } else {
      const NewCatUpdate = { newCategory: res, deleteCat: category }
      callBackDelete(NewCatUpdate)
      p.op.showConfirmationOkOnly(``, `ลบหมวดหมู่ ${name} เรียบร้อยแล้ว`)
      this._goBack()
    }
  }

  _confirmedDeleteStepTwo = (product_count: number, child_category_count: number) => {
    const { name } = this.state
    let text = ``
    if (!_.isNil(child_category_count) && child_category_count > 0) {
      text += `หมวดหมู่นี้ มีหมวดหมู่ย่อยทั้งหมด ${child_category_count} หมวดหมู่ `
      if (!_.isNil(product_count) && product_count > 0) {
        text += `และมีสินค้าที่ผูกกับหมวดหมู่นี้  ${product_count} ชิ้น`
      }
    } else if (!_.isNil(product_count) && product_count > 0) {
      text += `หมวดหมู่นี้ผูกกับสินค้าทั้งหมด ${product_count} ชิ้น `
    }
    text += ` คุณต้องการลบจริงๆ ใช่หรือไม่`
    p.op.showConfirmation(
      `ยืนยันการลบหมวดหมู่ ${name}`,
      `${text}`,
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      () => this._deleteCategoryStepTwo(),
      () => null,
      `ยืนยันการลบ`,
      `ยกเลิก`
    )
  }

  _deleteCategoryStepTwo = async () => {
    const { deleteCategory, selectedStore, navigation } = this.props
    // const { state } = navigation
    // const callBackDelete = state.params ? state.params.callBackDelete : null
    const callBackDelete = util.getNavParam(this.props, 'callBackDelete')
    const { category, name } = this.state
    const body: { [key: string]: any } = {
      store_id: selectedStore.get(`id`),
      id: category.id,
      confirmed: true,
    }
    const res: ICategory[] = await new Promise((resolve) => {
      deleteCategory({
        body,
        successCallback: resolve,
        failedCallback: resolve,
      })
    })
    if (!_.isArray(res)) {
      return
    }
    const NewCatUpdate = { newCategory: res, deleteCat: category }
    callBackDelete(NewCatUpdate)
    p.op.showConfirmationOkOnly(``, `ลบหมวดหมู่ ${name} เรียบร้อยแล้ว`)
    this._goBack()
  }

  _submitChangeCategory = async (callBackCategory: ICategory) => {
    const { changeCategory, selectedStore, navigation } = this.props
    // const { state } = navigation
    // const callBackChangeCategory = state.params ? state.params.callBackChangeCategory : null
    const callBackChangeCategory = util.getNavParam(this.props, 'callBackChangeCategory')
    const { category, name } = this.state

    if (_.isNil(callBackCategory) && _.isNil(category.p)) {
      p.op.showConfirmationOkOnly(``, `คุณไม่สามารถย้ายหมวดหมู่ไว้ที่เดิมได้`)
      return
    }
    if (!_.isNil(callBackCategory) && callBackCategory.id === category.id) {
      p.op.showConfirmationOkOnly(``, `คุณไม่สามารถย้ายหมวดหมู่ไว้ภายใต้หมวดหมู่ของตนเองได้`)
      return
    }
    if (!_.isNil(callBackCategory) && callBackCategory.id === category.p) {
      p.op.showConfirmationOkOnly(``, `คุณไม่สามารถย้ายหมวดหมู่ไว้ที่เดิมได้`)
      return
    }

    const body: { [key: string]: any } = {
      store_id: selectedStore.get(`id`),
      id: category.id,
    }
    if (!_.isNil(callBackCategory)) {
      body.move_to_parent_category_id = callBackCategory.id
    }
    const res: ICategory[] = await new Promise((resolve) => {
      changeCategory({
        body,
        successCallback: resolve,
        failedCallback: resolve,
      })
    })
    if (!_.isArray(res)) {
      return
    }
    const newCategory = _.find(res, (data: ICategory) => data.id === category.id)
    const NewCatUpdate = { newCategory: res, changeCat: newCategory }
    callBackChangeCategory(NewCatUpdate)
    p.op.showConfirmationOkOnly(``, `ย้ายหมวดหมู่ ${name} เรียบร้อยแล้ว`)
    this._goBack()
  }
}
