import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as StoreState from 'x/modules/store/StoreState'

import LoginView from './LandingView'

export default connect(
  (state) => ({
    //
  }),
  (dispatch) => {
    return {
      fetchInitializeData: bindActionCreators(StoreState.fetchInitializeData, dispatch),
      dispatch,
    }
  }
)(LoginView)
