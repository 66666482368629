import React from 'react'
import _ from 'lodash'
import { TouchableOpacity, View, Platform, RefreshControl, Linking } from 'react-native'
import dayjs from 'dayjs'
// import EntypoIcon from 'react-native-vector-icons/Entypo'

import xCONS from 'x/config/constants'
import moment from 'moment'

// import BackIcon from '../../components/BackIcon'
// import XTabNavIcon from '../../components/XTabNavIcon'

// import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import XCard from 'xui/components/XCard'
import XText from 'xui/components/XText'
import XSpinner from 'xui/components/XSpinner'
// import SRowItem from '../../components/renderRowLeftAndRight'
import { COLORS, STYLES } from 'x/config/styles'
import BaseSubscriptionView from 'x/modules/subscription/BaseSubscriptionView'
import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import XButton from 'xui/components/XButton'
import * as util from 'x/utils/util'
import ProfilePackage from 'xui/components/ProfilePackage'
import XImage from 'xui/components/XImage'
import XStatusBar from '../../components/XStatusBar'

import XUISubcriptionDetail from './XUISubcriptionDetail'

const BTN_BUY_PACKAGE = require('../../img/btn/btn_buy_package.png')

export const RenderBtnCallActionPackage = (text: string, onPressBtn: () => void) => (
  <TouchableOpacity style={{ width: 125, height: 50 }} onPress={() => onPressBtn()}>
    <HStack w='125px' h='50px'>
      <XImage
        // width={width}
        // height={height}
        source={BTN_BUY_PACKAGE}
        w='125px'
        h='50px'
        // style={StyleSheet.flatten(style)}
        // style={{
        //   width: '100%',
        // }}
      />
      <HStack alignItems='center' justifyContent='center' position='absolute' top='0' right='0' bottom='0' left='0'>
        <XText color={COLORS.WHITE} bold fontSize='lg'>
          {text}
        </XText>
      </HStack>
    </HStack>
  </TouchableOpacity>
)

const { expireWarningInDay, expireDangerInDay, orderDangerInPercentage, orderWarningInPercentage } = xCONS.SUBSCRIPTION_SETTINGS

const SERVER_DATE_FORMAT = 'YYYY-MM-DD'

const { FONT_SIZE_SMALLER } = STYLES
const { APP_MAIN, APP_SECONDARY, TEXT_ACTIVE, TEXT_INACTIVE, TEXT_ACTIVE_DARK, BRAND_Warning, BRAND_Danger, BG_LIGHT_GREY } = COLORS

const SRowItem = ({ label = '', desc = '', labelStyle = {}, descStyle = {} }) => (
  <View
    style={{
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingHorizontal: 8,
    }}>
    <XText variant='inactive' style={labelStyle}>
      {label}
    </XText>
    <XText style={descStyle}>{desc}</XText>
  </View>
)

export const renderCurrentBillingCycle = (subscription) => {
  const orderCount = subscription.get('order_count_curr') || 0
  const orderQuota = subscription.get('order_count_quota') || 0
  const startBillingDate = subscription.has('start_billing_date')
    ? subscription.get('start_billing_date')
    : subscription.get('startBillingDate')
  const endBillingDate = subscription.has('end_billing_date') ? subscription.get('end_billing_date') : subscription.get('endBillingDate')
  const txtEndBillingDate = moment(endBillingDate, SERVER_DATE_FORMAT).format(LOCAL_DATE_FORMAT)
  const txtStartBillingDate = moment(startBillingDate, SERVER_DATE_FORMAT).format(LOCAL_DATE_FORMAT)
  return (
    <VStack w='full'>
      <HStack h='10' alignItems='center' justifyContent='center'>
        <XText bold fontSize='lg'>
          รอบบิลปัจจุบัน
        </XText>
      </HStack>
      <VStack px='2' h='12'>
        <HStack alignItems='center' justifyContent='center'>
          <HStack flex={1}>
            <XText fontSize='md' variant='inactive'>
              จำนวนออเดอร์ที่ใช้ไป
            </XText>
          </HStack>
          <HStack flex={1} justifyContent='flex-end'>
            <XText bold fontSize='md'>{`${orderCount} / ${orderQuota}`}</XText>
          </HStack>
        </HStack>
        <HStack>
          <HStack flex={1}>
            <XText fontSize='md' variant='inactive'>
              ช่วงเวลา
            </XText>
          </HStack>
          <HStack flex={3} justifyContent='flex-end'>
            <XText fontSize='md' variant='inactive'>{`${txtStartBillingDate} - ${txtEndBillingDate}`}</XText>
          </HStack>
        </HStack>
      </VStack>
    </VStack>
  )
}

const LOCAL_DATE_FORMAT = 'D MMM YYYY'

export default abstract class BaseUISubscriptionView extends BaseSubscriptionView {
  _renderPackage = () => {
    if (!util.isDevMode()) {
      return null
    }
    return (
      <XCard p='2' w='full'>
        <XButton w='full' onPress={() => this._navToInAppPurchaseView('upgrade')}>
          เปิดแพ็กใหม่
        </XButton>
      </XCard>
    )
  }

  _renderProfile = () => {
    const { subscription, profile } = this.props
    if (_.isNil(profile) || _.isNil(subscription)) {
      return null
    }
    // console.log('profile => ', profile.toJS())
    if (!profile || !profile.get('user_id')) {
      return null
    }

    const typeId: number = subscription.has('type') ? subscription.get('type') : 0

    return <ProfilePackage profile={profile.toJS()} typeId={typeId} />
  }

  // _renderNavToUpgradePakage = () => {
  //   const { subscription } = this.props
  //   if (_.isNil(subscription)) {
  //     return null
  //   }
  //   let isCanRenderBuyPack = false
  //   if (util.isDevMode() || p.op.isIOS()) {
  //     isCanRenderBuyPack = true
  //   }

  //   const AVAILABLE_TYPES = [1, 999]
  //   if (p.op.isIOS() && !_.includes(AVAILABLE_TYPES, subscription.get('type'))) {
  //     return null
  //   }
  //   if (!isCanRenderBuyPack) {
  //     return null
  //   }
  //   return (
  //     <VStack w={'full'} bgColor={COLORS.APP_SECONDARY} space={'1'} alignItems={'center'} justifyContent={'center'} py='2'>
  //       <HStack>
  //         <XText fontSize={'md'} bold>
  //           เพิ่มประสิทธิภาพให้การทำงานไม่สะดุด อย่างต่อเนื่อง
  //         </XText>
  //       </HStack>
  //       <XButton onPress={() => this._navToInAppPurchaseView()}>
  //         <XText color={COLORS.WHITE} fontSize={'md'}>
  //           อัพเกรดแพ็กเกจ
  //         </XText>
  //       </XButton>
  //     </VStack>
  //   )
  // }

  _renderBuyNewPackageButton = () => {
    const { subscription } = this.props
    if (_.isNil(subscription)) {
      return null
    }
    // let isCanRenderBuyPack = false
    // if (util.isDevMode() || p.op.isIOS()) {
    //   isCanRenderBuyPack = true
    // }

    const AVAILABLE_TYPES = [1, 999]
    if (!_.includes(AVAILABLE_TYPES, subscription.get('type'))) {
      return null
    }
    const isCanRenderBuyPack = this._canRenderBuyPackage()
    if (!isCanRenderBuyPack) {
      return null
    }

    let textLabel = 'อัพเกรดแพ็กเกจ'
    const subscriptionType = subscription.get('type')
    if (subscriptionType === 1 || subscriptionType === 999) {
      textLabel = 'ซื้อแพ็กเกจ'
    }
    return (
      <HStack flex={1} justifyContent='flex-end' alignItems='center'>
        <TouchableOpacity
          onPress={() => this._navToInAppPurchaseView('buy_package')}
          style={{
            backgroundColor: COLORS.APP_MAIN,
            borderColor: COLORS.APP_MAIN,
            borderWidth: 1,
            marginTop: 4,
            borderRadius: 90,
            alignItems: 'center',
            justifyContent: 'center',
            width: 180,
          }}>
          <XText my='1' bold fontSize='lg' color={COLORS.WHITE}>
            {textLabel}
          </XText>
        </TouchableOpacity>
      </HStack>
    )
  }

  // _renderExtendPackageButton = () => {
  //   const { subscription } = this.props
  //   const AVAILABLE_TYPES = [2, 3, 4, 5]
  //   if (_.isNil(subscription) || !_.includes(AVAILABLE_TYPES, subscription.get('type'))) {
  //     return null
  //   }
  //   // console.log('subscription => ', subscription.toJS())
  //   let isCanRenderBuyPack = false
  //   if (util.isDevMode() || p.op.isIOS()) {
  //     isCanRenderBuyPack = true
  //   }

  //   const end = dayjs(subscription.get('m_e_date')) // อายุการใช้งานงานวันสุดท้ายของแพ็เกจ
  //   const toDay = dayjs(new Date())
  //   // console.log('end => ', end.format('DD/MM/YYYY'))
  //   // console.log('toDay => ', toDay.format('DD/MM/YYYY'))
  //   const timeLeft = end.clone().diff(toDay.clone(), 'd') // ช่วงเวลาที่เหลือของอายุการใช้งาน ( 0 วันเท่ากับ วันนี้ )
  //   // console.log('timeLeft => ', timeLeft)
  //   if (!isCanRenderBuyPack) {
  //     return null
  //   }
  //   return (
  //     <VStack w={'full'} bgColor={COLORS.APP_SECONDARY} space={'1'} alignItems={'center'} justifyContent={'center'} py='2'>
  //       <HStack>
  //         <XText fontSize={'md'} bold>
  //           เพิ่มประสิทธิภาพให้การทำงานไม่สะดุด อย่างต่อเนื่อง
  //         </XText>
  //       </HStack>
  //       <XButton
  //         onPress={() => {
  //           if (timeLeft < -3) {
  //             p.op.showConfirmationOkOnly('', 'กรุณาติดต่อทีมงาน')
  //           } else {
  //             this._navToInAppPurchasePerPackageView()
  //           }
  //         }}>
  //         <XText color={COLORS.WHITE} fontSize={'md'}>
  //           ต่อแพ็กเกจ
  //         </XText>
  //       </XButton>
  //     </VStack>
  //   )
  // }

  _renderExtendPackageButton = () => {
    const { subscription } = this.props
    const AVAILABLE_TYPES = [2, 3, 4, 5]
    if (_.isNil(subscription) || !_.includes(AVAILABLE_TYPES, subscription.get('type'))) {
      return null
    }

    let isCanRenderBuyPack = true

    const end = dayjs(util.getSubcriptionEndDate(subscription)) // อายุการใช้งานงานวันสุดท้ายของแพ็กเกจ
    const toDay = dayjs(new Date())
    // console.log('end => ', end.format('DD/MM/YYYY'))
    // console.log('toDay => ', toDay.format('DD/MM/YYYY'))
    const timeLeft = end.clone().diff(toDay.clone(), 'd') // ช่วงเวลาที่เหลือของอายุการใช้งาน ( 0 วันเท่ากับ วันนี้ )
    // console.log('timeLeft => ', timeLeft)

    if (timeLeft > 20) {
      isCanRenderBuyPack = false
    }

    if (!isCanRenderBuyPack) {
      return null
    }

    const canRenderPerPackage = this._canRenderPerPackage()
    if (!canRenderPerPackage) {
      return null
    }
    return (
      <HStack flex={1} justifyContent='flex-end' alignItems='center'>
        <TouchableOpacity
          onPress={() => this._navToInAppPurchasePerPackageView()}
          style={{
            backgroundColor: COLORS.APP_MAIN,
            borderColor: COLORS.APP_MAIN,
            borderWidth: 1,
            marginTop: 4,
            borderRadius: 90,
            alignItems: 'center',
            justifyContent: 'center',
            width: 140,
          }}>
          <XText my='1' bold fontSize='lg' color={COLORS.WHITE}>
            ต่อแพ็กเกจ
          </XText>
        </TouchableOpacity>
      </HStack>
    )
  }

  _renderDetailsOfThisBillingCycle = () => {
    const { subscription } = this.props
    const orderCount = subscription.get('order_count_curr') || 0
    const orderQuota = subscription.get('order_count_quota') || 0
    const startBillingDate = subscription.has('start_billing_date')
      ? subscription.get('start_billing_date')
      : subscription.get('startBillingDate')
    const endBillingDate = subscription.has('end_billing_date') ? subscription.get('end_billing_date') : subscription.get('endBillingDate')
    const txtEndBillingDate = moment(endBillingDate, SERVER_DATE_FORMAT).format(LOCAL_DATE_FORMAT)
    const txtStartBillingDate = moment(startBillingDate, SERVER_DATE_FORMAT).format(LOCAL_DATE_FORMAT)
    return (
      <VStack w='full'>
        <HStack justifyContent='center' alignItems='center'>
          <XText fontSize='lg' bold>
            รอบบิลปัจจุบัน
          </XText>
        </HStack>
        <HStack flex={1}>
          <HStack flex={1}>
            <XText fontSize='md' variant='inactive'>
              จำนวนออเดอร์
            </XText>
          </HStack>
          <HStack flex={1} justifyContent='flex-end'>
            <XText bold fontSize='md'>{`${orderCount} / ${orderQuota}`}</XText>
          </HStack>
        </HStack>
        <HStack flex={1}>
          <HStack flex={1}>
            <XText fontSize='md' variant='inactive'>
              เริ่มรอบบิล
            </XText>
          </HStack>
          <HStack flex={1} justifyContent='flex-end'>
            <XText bold fontSize='md'>{`${txtStartBillingDate}`}</XText>
          </HStack>
        </HStack>
        <HStack flex={1}>
          <HStack flex={1}>
            <XText fontSize='md' variant='inactive'>
              สิ้นสุดรอบบิล
            </XText>
          </HStack>
          <HStack flex={1} justifyContent='flex-end'>
            <XText bold fontSize='md'>{`${txtEndBillingDate}`}</XText>
          </HStack>
        </HStack>
        <HStack mt='1' borderBottomColor={COLORS.TEXT_INACTIVE} borderBottomWidth='1' />
      </VStack>
    )
  }

  _renderUpgradePakageUnderline = () => {
    const {} = this.state
    if (!util.isDevMode()) {
      return null
    }
    return (
      <HStack flex={1} justifyContent='flex-end' alignItems='center'>
        <XText
          textDecorationLine='underline'
          my='1'
          fontSize='lg'
          color={COLORS.BLUE_FACEBOOK}
          onPress={() => this._navToInAppPurchaseView('upgrade')}>
          อัพเกรดแพ็กเกจ
        </XText>
      </HStack>
    )
  }

  // _renderMyPackage02 = () => {
  //   const { subscription } = this.props
  //   let txtMainPackageName = ''
  //   try {
  //     if (subscription.get('type') === 999) {
  //       txtMainPackageName = this.mainPackageItems[5].label
  //     } else {
  //       txtMainPackageName = this.mainPackageItems[subscription.get('type') - 1].label
  //     }
  //   } catch (error) {
  //     txtMainPackageName = ''
  //   }
  //   const mEndDate = subscription.has('m_s_date') ? util.getSubcriptionEndDate(subscription) : null
  //   // console.log('mEndDate => ', mEndDate)
  //   // const mEndDate = util.getSubcriptionEndDate(subscription)
  //   const endDate = moment(mEndDate, SERVER_DATE_FORMAT)
  //   const txtMainPackageEnd = endDate.clone().format(LOCAL_DATE_FORMAT)
  //   const txtExpireMainPackageIn = endDate.clone().fromNow()
  //   return (
  //     <VStack w='full'>
  //       <HStack alignItems='center' justifyContent='center'>
  //         <XText fontSize='lg' bold>
  //           แพ็กเกจของฉัน
  //         </XText>
  //       </HStack>
  //       {/* <HStack>
  //         <XText fontSize={'md'} bold>
  //           {`แพ็กเกจ ${txtMainPackageName}`}
  //         </XText>
  //       </HStack> */}
  //       <HStack flex={1}>
  //         <HStack flex={1}>
  //           <XText variant='inactive' fontSize='md'>
  //             แพ็กเกจ
  //           </XText>
  //         </HStack>
  //         <HStack flex={2} justifyContent='flex-end'>
  //           <XText fontSize='md' bold>
  //             {`${txtMainPackageName}`}
  //           </XText>
  //         </HStack>
  //       </HStack>
  //       {_.isNil(mEndDate) ? null : (
  //         <HStack flex={1}>
  //           <HStack flex={1}>
  //             <XText variant='inactive' fontSize='md'>
  //               ใช้งานได้ถึง
  //             </XText>
  //           </HStack>
  //           <HStack flex={2} justifyContent='flex-end'>
  //             <XText fontSize='md' bold>{`${txtMainPackageEnd} (${txtExpireMainPackageIn})`}</XText>
  //           </HStack>
  //         </HStack>
  //       )}

  //       {this._renderBuyNewPackageButton()}
  //       {this._renderExtendPackageButton()}
  //       {/* {this._renderUpgradePakageUnderline()} */}
  //       <HStack mt='3.5' borderBottomColor={COLORS.TEXT_INACTIVE} borderBottomWidth='1' />
  //     </VStack>
  //   )
  // }

  _renderMyPackageDetails = () => {
    const { subscription } = this.props
    // const { packageDetails } = this.state
    // console.log('packageDetails => ', packageDetails)
    if (_.isNil(subscription)) {
      return null
    }
    const type = subscription.get('type')
    // console.log('type // => ', type)
    return <XUISubcriptionDetail subscription={subscription.toJS()} type={type} />
    // const itemPackageDetails = JSON_PACKAGE_DETAIL_DATA.items_package_detail
    // return (
    //   <VStack w='full'>
    //     {itemPackageDetails.map((section, idx) => {
    //       const sectionName = section.section_name
    //       return (
    //         <VStack>
    //           <VStack justifyContent={'flex-start'} w='full' mt='2' key={`section_${idx}`}>
    //             <XText bold fontSize={'md'}>
    //               {sectionName}
    //             </XText>
    //             {section.items.map((item, indexMap) => {
    //               const availableTypes = item?.available_types
    //               // const abc = indexMap === 1 ? '1' : '99,999'
    //               const countPackage = item?.key ? this._getCountPackageByKey(item.key) : null
    //               const packageActive = !_.isNil(availableTypes) && availableTypes.includes(subscription.get('type'))

    //               let RENDER_ICON = (
    //                 <HStack justifyContent={'center'} w='32'>
    //                   <XIcon name='closecircleo' family='AntDesign' color={COLORS.RED} />
    //                 </HStack>
    //               )
    //               if (packageActive) {
    //                 RENDER_ICON = (
    //                   <HStack justifyContent={'center'} w='32'>
    //                     <XIcon name='checkcircleo' family='AntDesign' color={COLORS.GREEN} />
    //                   </HStack>
    //                 )
    //               }
    //               return (
    //                 <HStack w='full' mt='2' space={'1'} key={`item_${indexMap}`}>
    //                   <HStack flex={1}>
    //                     <XText variant='inactive' fontSize={'md'}>
    //                       {item.name}
    //                     </XText>
    //                   </HStack>
    //                   {_.isNil(availableTypes) && !p.op.isIOS() ? (
    //                     <HStack justifyContent={'flex-end'} w='10'>
    //                       <XText
    //                         variant='inactive'
    //                         color={COLORS.BRAND_Info}
    //                         fontSize={'md'}
    //                         onPress={() => p.op.showConfirmationOkOnly('', 'กรุณาติดต่อทีมงาน')}>
    //                         {'เพิ่ม?'}
    //                       </XText>
    //                     </HStack>
    //                   ) : null}
    //                   {_.isNil(availableTypes) ? (
    //                     <HStack justifyContent={'center'} w='32'>
    //                       <XText variant='active' bold fontSize={'md'}>{`${countPackage} ${item.unit}`}</XText>
    //                     </HStack>
    //                   ) : (
    //                     RENDER_ICON
    //                   )}
    //                 </HStack>
    //               )
    //             })}
    //           </VStack>
    //           <HStack borderBottomColor={COLORS.TEXT_INACTIVE} borderBottomWidth={'1'} mt='2' />
    //         </VStack>
    //       )
    //     })}
    //   </VStack>
    // )
  }

  _renderBtnAction = () => {
    const canSeeBtnAction = util.isDevMode()
    if (!canSeeBtnAction) {
      return null
    }
    return (
      <HStack flex={1} alignItems='center' justifyContent='flex-end' px='2' pb='2' mt='1'>
        <TouchableOpacity
          onPress={() => null}
          style={{
            marginRight: 10,
            width: 140,
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 90,
            backgroundColor: '#E4EAEF',
          }}>
          <XText bold fontSize='md' my='2'>
            อัพเกรดแพ็กเกจ
          </XText>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => null}
          style={{
            width: 140,
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 90,
            backgroundColor: '#E7DED9',
          }}>
          <XText bold fontSize='md' my='2'>
            ซื้อแพ็กเสริม
          </XText>
        </TouchableOpacity>
      </HStack>
    )
  }

  _renderCurrentBillingCycle = () => {
    const { subscription } = this.props
    return (
      <XCard m='2'>
        {renderCurrentBillingCycle(subscription)}
        {this._renderBtnAction()}
      </XCard>
    )
  }

  _renderNowPackage = () => {
    const { subscription } = this.props
    // console.log('subscription => ', subscription.toJS())
    let txtMainPackageName = ''
    try {
      if (subscription.get('type') === 999) {
        txtMainPackageName = this.mainPackageItems[5].label
      } else {
        txtMainPackageName = this.mainPackageItems[subscription.get('type') - 1].label
      }
    } catch (error) {
      txtMainPackageName = ''
    }

    const currentPackageBillingCycle = subscription.has('current_package_billing_cycle')
      ? subscription.get('current_package_billing_cycle').toJS()
      : null
    const currentPackageData = this._getCurentPackage(currentPackageBillingCycle)
    let startPackage = null
    let endPackage = null
    if (!_.isNil(currentPackageData)) {
      startPackage = moment(currentPackageData.start_date, SERVER_DATE_FORMAT).clone().format(LOCAL_DATE_FORMAT)
      endPackage = moment(currentPackageData.end_date, SERVER_DATE_FORMAT).clone().format(LOCAL_DATE_FORMAT)
    }

    return (
      <XCard m='2'>
        <HStack bgColor={COLORS.BG_GREY_PACKAGE} h='10' alignItems='center' justifyContent='center' w='full'>
          <XText bold fontSize='lg'>
            แพ็กเกจปัจจุบัน
          </XText>
        </HStack>

        <VStack p='2'>
          <XText bold fontSize='lg'>{`แพ็กเกจ ${txtMainPackageName}`}</XText>

          {!_.isNil(currentPackageData) ? (
            <HStack h='6'>
              <HStack flex={1}>
                <XText fontSize='md' variant='inactive'>
                  {`${currentPackageData.billing_cycle} รอบบิล`}
                </XText>
              </HStack>
              <HStack flex={2} justifyContent='flex-end'>
                <XText fontSize='md' variant='inactive'>{`${startPackage} - ${endPackage}`}</XText>
              </HStack>
            </HStack>
          ) : null}

          <HStack flex={1} alignItems='center' justifyContent='flex-end'>
            <XText color={COLORS.TEXT_INACTIVE} bold textDecorationLine='underline' onPress={() => this._navToActivePackageDetailView()}>
              ดูรายละเอียด
            </XText>
          </HStack>
        </VStack>

        {this._renderCurrentBillingCycle()}
      </XCard>
    )
  }

  _renderBtnBuyPackage = () => {
    const isCanRenderBuyPack = util.showBtnBuyPackage()
    if (!isCanRenderBuyPack) {
      return null
    }

    const textLabel = 'ซื้อแพ็กเกจ'
    return (
      <HStack alignItems='center' justifyContent='flex-end'>
        {RenderBtnCallActionPackage(textLabel, () => this._navToInAppPurchaseView('buy_package'))}
      </HStack>
    )
  }

  _renderBtnPerPackage = () => {
    const canRenderPerPackage = util.showBtnPerPackage()
    if (!canRenderPerPackage) {
      return null
    }

    return (
      <VStack>
        <HStack alignItems='center' justifyContent='flex-end'>
          {RenderBtnCallActionPackage('ต่อแพ็กเกจ', () => this._navToInAppPurchasePerPackageView())}
        </HStack>
      </VStack>
    )
  }

  _renderNextPackage = () => {
    const { subscription } = this.props
    const { subscriptionPackageList } = this.state
    const LastBillingCycle = util.getSubscriptionNextLastBillingCycle(subscription)
    if (_.isNil(LastBillingCycle) || _.isNil(subscriptionPackageList)) {
      return null
    }

    const selectedPackage = util.getSubscriptionPackageJsonBySku(LastBillingCycle.sku, subscriptionPackageList)
    if (_.isNil(selectedPackage)) {
      return null
    }
    const subscriptionType = util.getSubscriptionPackageTypeByPackageName(selectedPackage.item_subtype)
    const txtMainPackageName = util.getSubscriptionTypeFullName(subscriptionType)
    let startPackage = null
    let endPackage = null
    if (!_.isNil(LastBillingCycle)) {
      startPackage = moment(LastBillingCycle.start_date, SERVER_DATE_FORMAT).clone().format(LOCAL_DATE_FORMAT)
      endPackage = moment(LastBillingCycle.end_date, SERVER_DATE_FORMAT).clone().format(LOCAL_DATE_FORMAT)
    }

    return (
      <XCard m='2'>
        <HStack bgColor={COLORS.BG_GREY_PACKAGE} h='10' alignItems='center' justifyContent='center' w='full'>
          <XText bold fontSize='lg'>
            แพ็กเกจถัดไป
          </XText>
        </HStack>

        <VStack p='2' bgColor='#FEF0E9'>
          <XText bold fontSize='lg'>{`แพ็กเกจ ${txtMainPackageName}`}</XText>

          <HStack h='6'>
            <HStack flex={1}>
              <XText fontSize='md' variant='inactive'>
                {`${LastBillingCycle.billing_cycle} รอบบิล`}
              </XText>
            </HStack>
            <HStack flex={2} justifyContent='flex-end'>
              <XText fontSize='md' variant='inactive'>{`${startPackage} - ${endPackage}`}</XText>
            </HStack>
          </HStack>

          <HStack flex={1} alignItems='center' justifyContent='flex-end'>
            <XText color={COLORS.TEXT_INACTIVE} bold textDecorationLine='underline' onPress={() => this._navToNextPackageDetailView()}>
              ดูรายละเอียด
            </XText>
          </HStack>
        </VStack>
      </XCard>
    )
  }

  _renderMyPackage = () => {
    const { subscription } = this.props
    // const startBillingDate = subscription.get('startBillingDate') || moment()
    // const endBillingDate = subscription.get('endBillingDate') || moment()
    // const orderCount = subscription.get('order_count_curr') || 0
    // const orderQuota = subscription.get('order_count_quota') || 0
    // const mainPackageOrderQuota = subscription.get('order_count_quota') || 0
    // const ugGroups = subscription.get('ug_quota')
    // const pgGroups = subscription.get('pg_quota')
    // let colorOrderQuota = TEXT_ACTIVE
    // const orderCountPerQuota = orderCount / orderQuota
    // if (orderCountPerQuota >= orderDangerInPercentage) {
    //   colorOrderQuota = BRAND_Danger
    // } else if (orderCountPerQuota >= orderWarningInPercentage) {
    //   colorOrderQuota = BRAND_Warning
    // }
    // const dEnd = moment(endBillingDate, SERVER_DATE_FORMAT)
    // const txtEndBillingIn = dEnd.clone().fromNow()
    // const txtStartBillingDate = moment(startBillingDate, SERVER_DATE_FORMAT).format(LOCAL_DATE_FORMAT)
    // const txtEndBillingDate = moment(endBillingDate, SERVER_DATE_FORMAT).format(LOCAL_DATE_FORMAT)
    // package detail
    // const packages = subscription.get('packages')

    // let txtMainPackageName = this.mainPackageItems[0].label
    // let txtMainPackageBegin = null
    let txtMainPackageEnd = null
    let txtExpireMainPackageIn = null
    // let colorExpireMainPackageIn = TEXT_ACTIVE

    // const packageId = subscription.get('type') || 1
    // const currentPackage = this.mainPackageItems.find((item) => item.value === packageId)

    // if (currentPackage && currentPackage.label) {
    //   txtMainPackageName = currentPackage.label
    // }
    const mBeginDate = subscription.has('m_s_date') ? subscription.get('m_s_date') : null
    const mEndDate = util.getSubcriptionEndDate(subscription)
    // if (mBeginDate) {
    //   // txtMainPackageBegin = moment(mBeginDate, SERVER_DATE_FORMAT).format(LOCAL_DATE_FORMAT)
    // }
    if (mBeginDate) {
      const endDate = dayjs(mEndDate, SERVER_DATE_FORMAT)
      txtMainPackageEnd = endDate.clone().format(LOCAL_DATE_FORMAT)
      txtExpireMainPackageIn = endDate.clone().fromNow()

      // Check dayToExpire for warning color
      // const dayToExpire = endDate.clone().diff(moment(), 'days')
      // if (dayToExpire > expireDangerInDay && dayToExpire < expireWarningInDay) {
      //   // colorExpireMainPackageIn = BRAND_Warning
      // } else if (dayToExpire <= expireDangerInDay) {
      //   // colorExpireMainPackageIn = BRAND_Danger
      // }
    }

    // const mEndDate = subscription.has('m_s_date') ? subscription.get('m_e_date') : null
    return (
      <XCard m='2'>
        <HStack bgColor={COLORS.BG_GREY_PACKAGE} h='10' alignItems='center' justifyContent='center' w='full'>
          <XText bold fontSize='lg'>
            แพ็กเกจของฉัน
          </XText>
        </HStack>
        <VStack p='2' w='full'>
          {_.isNil(mEndDate) ? null : (
            <HStack h='8'>
              <HStack flex={1}>
                <XText variant='inactive' fontSize='md'>
                  ใช้งานได้ถึง
                </XText>
              </HStack>
              <HStack flex={2} justifyContent='flex-end'>
                <XText fontSize='md' bold>{`${txtMainPackageEnd} (${txtExpireMainPackageIn})`}</XText>
              </HStack>
            </HStack>
          )}
          {this._renderBtnBuyPackage()}
          {this._renderBtnPerPackage()}
        </VStack>
        <VStack w='full'>
          {this._renderNextPackage()}
          {this._renderNowPackage()}
        </VStack>
      </XCard>
    )
  }

  _renderPackageDetails = () => {
    // const isNoobIos = util.isIosNoobCheckByPass()
    // if (isNoobIos) {
    //   return null
    // }
    const { subscription } = this.props
    return (
      <VStack p='2' w='full' bgColor={COLORS.WHITE}>
        <VStack p='2' borderRadius='md' borderWidth='1' borderColor={COLORS.TEXT_INACTIVE}>
          {/* {this._renderMyPackage02()} */}
          {this._renderPackageAllDetails()}
        </VStack>
      </VStack>
    )
  }

  _renderPackageAllDetails = () => (
    // isNoobIos = https://app.clickup.com/t/86cvy20nq
    // const isNoobIos = util.isIosNoobCheckByPass()
    // if (isNoobIos) {
    //   return null
    // }
    <VStack>
      {this._renderDetailsOfThisBillingCycle()}
      {this._renderMyPackageDetails()}
    </VStack>
  )

  _renderOldPackageDetail = () => {
    const { profile, subscription } = this.props
    if (!subscription || !subscription.get('user_id')) {
      return (
        <XSpinner
          style={{
            flex: 1,
            width: '100%',
            paddingVertical: 10,
            backgroundColor: APP_SECONDARY,
          }}
          color={APP_MAIN}
        />
      )
    }
    // subscription detail
    // const billingDate = subscription.get('billing_date') || moment()
    const startBillingDate = subscription.get('startBillingDate') || moment()
    const endBillingDate = subscription.get('endBillingDate') || moment()
    const orderCount = subscription.get('order_count_curr') || 0
    const orderQuota = subscription.get('order_count_quota') || 0
    const mainPackageOrderQuota = subscription.get('order_count_quota') || 0
    const ugGroups = subscription.get('ug_quota')
    const pgGroups = subscription.get('pg_quota')
    let colorOrderQuota = TEXT_ACTIVE
    const orderCountPerQuota = orderCount / orderQuota
    if (orderCountPerQuota >= orderDangerInPercentage) {
      colorOrderQuota = BRAND_Danger
    } else if (orderCountPerQuota >= orderWarningInPercentage) {
      colorOrderQuota = BRAND_Warning
    }
    const dEnd = moment(endBillingDate, SERVER_DATE_FORMAT)
    const txtEndBillingIn = dEnd.clone().fromNow()
    const txtStartBillingDate = moment(startBillingDate, SERVER_DATE_FORMAT).format(LOCAL_DATE_FORMAT)
    const txtEndBillingDate = moment(endBillingDate, SERVER_DATE_FORMAT).format(LOCAL_DATE_FORMAT)
    // package detail
    const packages = subscription.get('packages')

    let txtMainPackageName = this.mainPackageItems[0].label
    let txtMainPackageBegin = null
    let txtMainPackageEnd = null
    let txtExpireMainPackageIn = null
    let colorExpireMainPackageIn = TEXT_ACTIVE

    const packageId = subscription.get('type') || 1
    const currentPackage = this.mainPackageItems.find((item) => item.value === packageId)

    if (currentPackage && currentPackage.label) {
      txtMainPackageName = currentPackage.label
    }
    const mBeginDate = subscription.has('m_s_date') ? subscription.get('m_s_date') : null
    const mEndDate = subscription.has('m_s_date') ? subscription.get('m_e_date') : null

    if (mBeginDate) {
      txtMainPackageBegin = moment(mBeginDate, SERVER_DATE_FORMAT).format(LOCAL_DATE_FORMAT)
    }
    if (mBeginDate) {
      const endDate = moment(mEndDate, SERVER_DATE_FORMAT)
      txtMainPackageEnd = endDate.clone().format(LOCAL_DATE_FORMAT)
      txtExpireMainPackageIn = endDate.clone().fromNow()

      // Check dayToExpire for warning color
      const dayToExpire = endDate.clone().diff(moment(), 'days')
      if (dayToExpire > expireDangerInDay && dayToExpire < expireWarningInDay) {
        colorExpireMainPackageIn = BRAND_Warning
      } else if (dayToExpire <= expireDangerInDay) {
        colorExpireMainPackageIn = BRAND_Danger
      }
    }
    return (
      <VStack>
        {this._hideObject() ? null : (
          <XCard>
            <XCard.Body style={{ justifyContent: 'center', alignItems: 'center' }}>
              <XText fontSize='md' bold>
                รายละเอียดรอบเดือนนี้
              </XText>
            </XCard.Body>
            <XCard.Body>
              <VStack flex={1}>
                {/* <SRowItem */}
                {/* label='แพ็กเกจปัจจุบัน' */}
                {/* desc={ txtMainPackageName } */}
                {/* descStyle={{ color: BRAND_Success }} */}
                {/* /> */}
                <HStack bgColor={APP_SECONDARY} px='2' py='1'>
                  <VStack flex={7}>
                    <XText>ออเดอร์ขาย</XText>
                    <XText variant='inactive'>ที่ฉันเปิดโดยมีสินค้าฉันสต็อก</XText>
                    <XText style={{ flex: 1, textAlign: 'left', paddingLeft: 25, fontWeight: 'bold' }}>+</XText>
                    <XText>ออเดอร์ขาย</XText>
                    <XText variant='inactive'>ที่ตัวแทนเปิดมาให้ฉัน</XText>
                  </VStack>
                  <HStack flex={3} alignItems='center'>
                    <XText
                      style={{
                        flex: 1,
                        textAlign: 'right',
                      }}>
                      {`${orderCount}/${orderQuota}`}
                    </XText>
                  </HStack>
                </HStack>
                <HStack mt='1' />
                <SRowItem label='วันที่เริ่มรอบเดือน' desc={txtStartBillingDate} />
                <SRowItem label='วันที่สิ้นสุดรอบเดือน' desc={txtEndBillingDate} />
                <SRowItem desc={`(${txtEndBillingIn})`} descStyle={{ fontSize: FONT_SIZE_SMALLER, color: TEXT_INACTIVE }} />
              </VStack>
            </XCard.Body>
          </XCard>
        )}
        <XCard mt='2'>
          {this._hideObject() ? null : (
            <XCard.Body style={{ justifyContent: 'center', alignItems: 'center' }}>
              <XText bold>แพ็กเกจของฉัน</XText>
            </XCard.Body>
          )}

          <XCard.Body>
            <View style={{ flex: 1 }}>
              {this._hideObject() ? null : (
                <SRowItem label='ชื่อแพ็กเกจ' desc={txtMainPackageName} descStyle={{ color: TEXT_ACTIVE, fontWeight: 'bold' }} />
              )}
              {/* <SRowItem */}
              {/* label='ได้รับโควต้าออเดอร์ขายต่อเดือน' */}
              {/* desc={ mainPackageOrderQuota } */}
              {/* /> */}
              {/* <SRowItem */}
              {/* label='ที่ตัวแทนฉันเปิดมา + ที่ฉันเปิดโดยมีสินค้าฉันสต็อก' */}
              {/* labelStyle={{ fontSize: FONT_SIZE_SMALLER, color: TEXT_INACTIVE }} */}
              {/* /> */}
              {/* <SRowItem */}
              {/* label='ออเดอร์ซื้อที่ฉันเปิดให้ร้านขายส่ง' */}
              {/* desc={ 'ไม่จำกัด' } */}
              {/* descStyle={{ color: BRAND_Success, fontWeight: 'bold' }} */}
              {/* /> */}
              {/* <SRowItem */}
              {/* label='ออเดอร์ขายที่ไม่มีสินค้าฉันสต็อก' */}
              {/* desc={ 'ไม่จำกัด' } */}
              {/* descStyle={{ color: BRAND_Success, fontWeight: 'bold' }} */}
              {/* /> */}

              <View
                style={{
                  flex: 1,
                  paddingHorizontal: 8,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}>
                <View style={{ flex: 8 }}>
                  <View style={{ flex: 1, flexDirection: 'row' }}>
                    <XText>จำนวนสมาชิก</XText>
                  </View>
                </View>
                <View style={{ flex: 2, flexDirection: 'row', alignItems: 'center' }}>
                  <XText flex={1} variant='success' textAlign='right' fontSize='md' bold>
                    ไม่จำกัด
                  </XText>
                </View>
              </View>

              <View
                style={{
                  flex: 1,
                  paddingHorizontal: 8,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}>
                <View style={{ flex: 8 }}>
                  <View style={{ flex: 1, flexDirection: 'row' }}>
                    <XText>ออเดอร์ซื้อ</XText>
                  </View>
                </View>
                <View style={{ flex: 2, flexDirection: 'row', alignItems: 'center' }}>
                  <XText flex={1} variant='success' bold textAlign='right'>
                    ไม่จำกัด
                  </XText>
                </View>
              </View>

              <HStack w='full' px='2'>
                <VStack flex={8}>
                  <XText>ออเดอร์ขาย</XText>
                  <XText variant='inactive'>ที่ฉันเปิดโดยไม่มีสินค้าฉันสต็อก</XText>
                </VStack>
                <HStack flex={2} alignItems='center'>
                  <XText flex={1} variant='success' bold textAlign='right'>
                    ไม่จำกัด
                  </XText>
                </HStack>
              </HStack>

              {/* <View
                  style={{
                    flex: 1,
                    paddingHorizontal: 8,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}>
                  <View style={{ flex: 8 }}>
                    <XText>ออเดอร์ขาย</XText>
                    <XText variant='inactive'>ที่ฉันเปิดโดยไม่มีสินค้าฉันสต็อก</XText>
                  </View>
                  <View style={{ flex: 2, flexDirection: 'row', alignItems: 'center' }}>
                    <XText flex={1} variant='success' bold textAlign='right'>
                      ไม่จำกัด
                    </XText>
                  </View>
                </View> */}

              {this._hideObject() ? null : (
                <HStack bgColor={APP_SECONDARY} px='2' py='1'>
                  <VStack flex={7}>
                    <XText>ออเดอร์ขาย</XText>
                    <XText variant='inactive'>ที่ฉันเปิดโดยมีสินค้าฉันสต็อก</XText>
                    <XText style={{ flex: 1, textAlign: 'left', paddingLeft: 25, fontWeight: 'bold' }}>+</XText>
                    <XText>ออเดอร์ขาย</XText>
                    <XText variant='inactive'>ที่ตัวแทนเปิดมาให้ฉัน</XText>
                  </VStack>
                  <HStack flex={3} alignItems='center'>
                    <XText
                      style={{
                        flex: 1,
                        textAlign: 'right',
                      }}>
                      {mainPackageOrderQuota}
                      /เดือน
                    </XText>
                  </HStack>
                </HStack>
              )}

              {/* {Platform.OS === 'ios' ? ( */}
              {/* <View */}
              {/* style={{ */}
              {/* flex: 1, */}
              {/* paddingHorizontal: 16, */}
              {/* paddingVertical: 4, */}
              {/* marginVertical: 4, */}
              {/* flexDirection: 'row', */}
              {/* justifyContent: 'space-between', */}
              {/* }}> */}
              {/* <XText style={{ fontSize: FONT_SIZE_NORMAL, color: APP_MAIN }}> */}
              {/* กรุณาเช็กที่เว็ปไซด์ของเราสำหรับรายละเอียดแพ็กเกจเพิ่มเติม */}
              {/* </XText> */}
              {/* </View> */}
              {/* ) : null} */}

              {/* <SRowItem */}
              {/* label='เริ่มใช้งานวันที่' */}
              {/* desc={ txtMainPackageBegin } */}
              {/* /> */}
              {/* <SRowItem */}
              {/* label='ใช้ได้ถึงวันที่' */}
              {/* desc={ txtMainPackageEnd } */}
              {/* /> */}
              {/* <SRowItem */}
              {/* label='จะหมดอายุภายใน' */}
              {/* desc={ txtExpireMainPackageIn } */}
              {/* descStyle={{ color: colorExpireMainPackageIn }} */}
              {/* /> */}
              <HStack mt='2' />
              {txtMainPackageBegin ? <SRowItem label='เริ่มใช้งานวันที่' desc={txtMainPackageBegin} /> : null}
              {txtMainPackageEnd ? <SRowItem label='ใช้ได้ถึงวันที่' desc={txtMainPackageEnd} /> : null}
              {txtExpireMainPackageIn ? (
                <SRowItem label='จะหมดอายุภายใน' desc={txtExpireMainPackageIn} descStyle={{ color: colorExpireMainPackageIn }} />
              ) : null}
              {this._hideObject() ? null : (
                <View
                  style={{
                    flex: 1,
                    paddingHorizontal: 8,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}>
                  {/* ARTID */}
                  <View style={{ flex: 8 }}>
                    <View style={{ flex: 1, flexDirection: 'row' }}>
                      <XText>จำนวนกลุ่มสมาชิก</XText>
                    </View>
                  </View>
                  <View style={{ flex: 2, flexDirection: 'row', alignItems: 'center' }}>
                    <XText flex={1} variant='success' bold textAlign='right'>
                      {ugGroups}
                    </XText>
                  </View>
                </View>
              )}
              {this._hideObject() ? null : (
                <View
                  style={{
                    flex: 1,
                    paddingHorizontal: 8,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}>
                  <View style={{ flex: 8 }}>
                    <View style={{ flex: 1, flexDirection: 'row' }}>
                      <XText>จำนวนรายการราคา</XText>
                    </View>
                  </View>
                  <View style={{ flex: 2, flexDirection: 'row', alignItems: 'center' }}>
                    <XText flex={1} variant='success' bold textAlign='right'>
                      {pgGroups}
                    </XText>
                  </View>
                </View>
              )}
            </View>
          </XCard.Body>
          {Platform.OS === 'ios' ? null : (
            <XCard.Body>
              <TouchableOpacity
                onPress={() => {
                  Linking.canOpenURL('https://www.xselly.com/pricing').then((supported) => {
                    if (supported) {
                      Linking.openURL('https://www.xselly.com/pricing')
                    }
                  })
                }}
                style={{
                  flex: 1,
                  backgroundColor: APP_MAIN,
                  borderRadius: 8,
                  height: 40,
                }}>
                <XText
                  flex={1}
                  textAlign='center'
                  bold
                  py='2'
                  color={TEXT_ACTIVE_DARK}
                  // style={{
                  //   flex: 1,
                  //   textAlign: 'center',
                  //   fontSize: FONT_SIZE_LARGER,
                  //   fontWeight: 'bold',
                  //   paddingVertical: 4,
                  //   color: TEXT_ACTIVE_DARK,
                  // }}
                >
                  ดูรายละเอียดแพ็กเกจทั้งหมด
                </XText>
              </TouchableOpacity>
            </XCard.Body>
          )}
        </XCard>
      </VStack>
    )
  }

  _renderWarningPerPackage = () => {
    const { subscriptionPackangWaring } = this.state
    if (_.isNil(subscriptionPackangWaring)) {
      return null
    }
    const showBtnPerPackage = util.showBtnPerPackage()
    if (_.isNil(showBtnPerPackage)) {
      return null
    }
    return (
      <VStack bgColor={subscriptionPackangWaring.bg} p='2'>
        <XText color={COLORS.WHITE} textAlign='center'>
          {subscriptionPackangWaring.ms}
        </XText>
        <XCard bgColor='#f0f9ea' mt='2' p='2'>
          <HStack>
            <HStack flex={1}>
              <XText>ต่อแพ็กเกจเพื่อการใช้งานงานต่อเนื่องไม่มีสะดุด การต่อแพ็กเกจจะเริ่มแพ็กเกจใหม่ทันทีเมื่อแพ็กเกจเดิมหมดอายุ</XText>
            </HStack>
            <HStack w='125px' justifyContent='flex-start'>
              {RenderBtnCallActionPackage('ต่อแพ็กเกจ', () => this._navToInAppPurchasePerPackageView())}
            </HStack>
          </HStack>
        </XCard>
      </VStack>
    )
  }

  _renderWarningOrderQuota = () => {
    const { subscriptionOrderWaring } = this.state
    if (_.isNil(subscriptionOrderWaring)) {
      return null
    }
    // const showBtnPerPackage = util.isDevMode()
    // // console.log('showBtnPerPackage => ', showBtnPerPackage)
    // if (_.isNil(showBtnPerPackage)) {
    //   return null
    // }
    return (
      <VStack bgColor={subscriptionOrderWaring.bg} p='2'>
        <XText color={COLORS.WHITE} textAlign='center'>
          {subscriptionOrderWaring.ms}
        </XText>

        <XCard bgColor='#f0f9ea' mt='2' p='2'>
          <HStack>
            <HStack flex={1} mr='2'>
              <XText textAlign='left'>อัพเกรดเป็นแพ็กเพื่อเพิ่มออเดอร์/รอบบิล เพียงชำระค่าส่วนต่าง ตามจำนวนรอบบิลคงเหลือใช้งาน</XText>
            </HStack>
            <HStack w='140px' justifyContent='flex-start'>
              <TouchableOpacity
                onPress={() => this._navToUpgradePackage()}
                style={{
                  width: 140,
                  height: 50,
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: 90,
                  backgroundColor: '#E4EAEF',
                  alignSelf: 'flex-start',
                }}>
                <XText bold fontSize='md' my='2'>
                  อัพเกรดแพ็กเกจ
                </XText>
              </TouchableOpacity>
            </HStack>
          </HStack>

          <HStack mt='8'>
            <HStack flex={1} mr='2'>
              <XText textAlign='left'>ซื้อแพ็กเกจเสริมออเดอร์ปรับใช้งาน เฉพาะรอบบิล</XText>
            </HStack>
            <HStack w='140px' justifyContent='flex-end'>
              <TouchableOpacity
                onPress={() => this._navToBuyAddOnPackage()}
                style={{
                  width: 140,
                  height: 50,
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: 90,
                  backgroundColor: '#E7DED9',
                  alignSelf: 'flex-start',
                }}>
                <XText bold fontSize='md' my='2'>
                  ซื้อแพ็กเสริม
                </XText>
              </TouchableOpacity>
            </HStack>
          </HStack>
        </XCard>
      </VStack>
    )
  }

  _renderWarningOverQuota = () => {
    const { subscriptionAllQuotaWaring } = this.state
    if (_.isNil(subscriptionAllQuotaWaring)) {
      return null
    }
    // const showBtnPerPackage = util.isDevMode()
    // // console.log('showBtnPerPackage => ', showBtnPerPackage)
    // if (_.isNil(showBtnPerPackage)) {
    //   return null
    // }
    return (
      <VStack bgColor={subscriptionAllQuotaWaring.bg} p='2'>
        <XText color={COLORS.WHITE} textAlign='center'>
          {subscriptionAllQuotaWaring.ms}
        </XText>
        <XCard bgColor='#f0f9ea' mt='2' p='2'>
          <HStack>
            <HStack flex={1} mr='2'>
              <XText textAlign='left'>อัพเกรดเป็นแพ็กเพื่อเพิ่มโควตาใช้งาน</XText>
            </HStack>
            <HStack w='140px' justifyContent='flex-start'>
              <TouchableOpacity
                onPress={() => this._navToUpgradePackage()}
                style={{
                  width: 140,
                  height: 50,
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: 90,
                  backgroundColor: '#E4EAEF',
                  alignSelf: 'flex-start',
                }}>
                <XText bold fontSize='md' my='2'>
                  อัพเกรดแพ็กเกจ
                </XText>
              </TouchableOpacity>
            </HStack>
          </HStack>

          <HStack mt='8'>
            <HStack flex={1} mr='2'>
              <XText textAlign='left'>ซื้อแพ็กเกจเสริมออเดอร์ปรับใช้งาน เฉพาะรอบบิล</XText>
            </HStack>
            <HStack w='140px' justifyContent='flex-end'>
              <TouchableOpacity
                onPress={() => this._navToBuyAddOnPackage()}
                style={{
                  width: 140,
                  height: 50,
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: 90,
                  backgroundColor: '#E7DED9',
                  alignSelf: 'flex-start',
                }}>
                <XText bold fontSize='md' my='2'>
                  ซื้อแพ็กเสริม
                </XText>
              </TouchableOpacity>
            </HStack>
          </HStack>
        </XCard>
      </VStack>
    )
  }

  render() {
    // util.logRender(this)
    const { profile, subscription } = this.props
    if (!subscription || !subscription.get('user_id')) {
      return (
        <XSpinner
          style={{
            flex: 1,
            width: '100%',
            paddingVertical: 10,
            backgroundColor: APP_SECONDARY,
          }}
          color={APP_MAIN}
        />
      )
    }
    if (!subscription.has('type')) {
      return null
    }
    // subscription detail
    // const billingDate = subscription.get('billing_date') || moment()
    const startBillingDate = subscription.get('startBillingDate') || moment()
    const endBillingDate = subscription.get('endBillingDate') || moment()
    const orderCount = subscription.get('order_count_curr') || 0
    const orderQuota = subscription.get('order_count_quota') || 0
    const mainPackageOrderQuota = subscription.get('order_count_quota') || 0
    const ugGroups = subscription.get('ug_quota')
    const pgGroups = subscription.get('pg_quota')
    let colorOrderQuota = TEXT_ACTIVE
    const orderCountPerQuota = orderCount / orderQuota
    if (orderCountPerQuota >= orderDangerInPercentage) {
      colorOrderQuota = BRAND_Danger
    } else if (orderCountPerQuota >= orderWarningInPercentage) {
      colorOrderQuota = BRAND_Warning
    }
    const dEnd = moment(endBillingDate, SERVER_DATE_FORMAT)
    const txtEndBillingIn = dEnd.clone().fromNow()
    const txtStartBillingDate = moment(startBillingDate, SERVER_DATE_FORMAT).format(LOCAL_DATE_FORMAT)
    const txtEndBillingDate = moment(endBillingDate, SERVER_DATE_FORMAT).format(LOCAL_DATE_FORMAT)
    // package detail
    const packages = subscription.get('packages')

    let txtMainPackageName = this.mainPackageItems[0].label
    let txtMainPackageBegin = null
    let txtMainPackageEnd = null
    let txtExpireMainPackageIn = null
    let colorExpireMainPackageIn = TEXT_ACTIVE

    const packageId = subscription.get('type') || 1
    const currentPackage = this.mainPackageItems.find((item) => item.value === packageId)

    if (currentPackage && currentPackage.label) {
      txtMainPackageName = currentPackage.label
    }
    const mBeginDate = subscription.has('m_s_date') ? subscription.get('m_s_date') : null
    const mEndDate = subscription.has('m_s_date') ? subscription.get('m_e_date') : null

    if (mBeginDate) {
      txtMainPackageBegin = moment(mBeginDate, SERVER_DATE_FORMAT).format(LOCAL_DATE_FORMAT)
    }
    if (mBeginDate) {
      const endDate = moment(mEndDate, SERVER_DATE_FORMAT)
      txtMainPackageEnd = endDate.clone().format(LOCAL_DATE_FORMAT)
      txtExpireMainPackageIn = endDate.clone().fromNow()

      // Check dayToExpire for warning color
      const dayToExpire = endDate.clone().diff(moment(), 'days')
      if (dayToExpire > expireDangerInDay && dayToExpire < expireWarningInDay) {
        colorExpireMainPackageIn = BRAND_Warning
      } else if (dayToExpire <= expireDangerInDay) {
        colorExpireMainPackageIn = BRAND_Danger
      }
    }

    // const headerTitle = this._hideObject() ? 'รายละเอียดการใช้งาน' : p.op.t('NavTitle.package')
    // const headerTitle = 'แพ็กเกจของฉัน'
    const headerTitle = 'รายละเอียดการใช้งาน'

    return (
      <XContainer>
        <XStatusBar backgroundColor='#fff' />
        <XCustomHeader title={headerTitle} headerLeftProps={{ backIcon: true, onPressItem: () => util.navGoBack(this.props) }} />
        <XContent
          // p='1'
          style={{ backgroundColor: BG_LIGHT_GREY }}
          refreshControl={<RefreshControl refreshing={this.state.refreshing} onRefresh={() => this._onRefresh()} />}>
          {this._renderProfile()}
          {this._renderWarningPerPackage()}
          {this._renderWarningOrderQuota()}
          {this._renderWarningOverQuota()}
          {/* {this._renderNavToUpgradePakage()} */}
          {/* {this._renderExtendPackageButton()} */}
          {/* {this._renderPackage()} */}
          {this._renderMyPackage()}
          {/* {this._renderPackageDetails()} */}
          {/* {this._renderOldPackageDetail()} */}

          {/* {!p.op.isIOS() ? (
            <XCard mt='2'>
              <XCard.Body style={{ justifyContent: 'center', alignItems: 'center' }}>
                <XText bold>สนใจสอบถามหรือซื้อแพ็กเกจติดต่อ</XText>
              </XCard.Body>

              <XCard.Body style={{ paddingHorizontal: 17, justifyContent: 'center', alignItems: 'center' }} />

              <XCard.Body>
                <ContactButtons />
              </XCard.Body>
            </XCard>
          ) : null} */}
        </XContent>
      </XContainer>
    )
  }
}
