import React from 'react'
import * as util from 'x/utils/util'
// import BackIcon from '../../components/BackIcon'
// import XTabNavIcon from '../../components/XTabNavIcon'
// import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
// import SRowItem from '../../components/renderRowLeftAndRight'
import BaseSalePageAddItemWebView from 'x/modules/storeWebsite/BaseSalePageAddItemWebView'
import { COLORS } from 'x/config/styles'
import XButton from 'xui/components/XButton'
import _ from 'lodash'
import VStack from 'xui/components/VStack'
import HelpButton from 'xui/components/HelpButton'
import XMenuListView from 'xui/components/XMenuListView'

const _renderAgreement = (onPressOpenOverlay: () => void) => <HelpButton onPress={() => onPressOpenOverlay()} />

export default abstract class BaseUISalePageAddItemWebView extends BaseSalePageAddItemWebView {
  _renderHeader = () => {
    const title = 'เพิ่มไอเทม'
    return (
      <XCustomHeader
        title={title}
        headerLeftProps={{ backIcon: true, onPressItem: () => util.navGoBack(this.props) }}
        // renderHeaderRight={this._renderHeaderRight}
      />
    )
  }

  _renderMainView = () => {
    // const {} = this.state
    if (_.isNil(this.MENU_ITRMS)) {
      return null
    }
    return (
      <VStack w='full'>
        {/* <VStack w='full' space='2'> */}
        <XMenuListView menuItems={this.MENU_ITRMS} />
        {/* {this.ITEM_MENU_LIST.map((item) => (
            <XCard p='2'>
              <HStack flex={1} alignItems='center'>
                <XText>{item.title}</XText>
                <HStack flex={1}>
                  <XFaqModal
                    key='คำถามพบบ่อย'
                    headerTitle='คำถามพบบ่อย'
                    // @ts-ignore
                    FAQ={item.faq}
                    initiateOpenIndex={[0]}
                    renderButton={(onPressOpenOverlay: () => void) => _renderAgreement(onPressOpenOverlay)}
                  />
                </HStack>
                <TouchableOpacity
                  style={{ height: 42, width: 82, alignItems: 'flex-end', justifyContent: 'center' }}
                  onPress={() => this._onPressItem(item)}>
                  <XIcon name='arrowright' family='AntDesign' />
                </TouchableOpacity>
              </HStack>
            </XCard>
          ))} */}
        {/* </VStack> */}
      </VStack>
    )
  }

  _renderFooter = () => (
    // const { selectedPackages } = this.state
    // if (_.isNil(selectedPackages)) {
    //   return null
    // }
    <XButton mb='0.5' mx='0.5' onPress={() => null}>
      ชำระเงิน
    </XButton>
  )

  render() {
    return (
      <XContainer>
        {this._renderHeader()}
        <XContent style={{ backgroundColor: COLORS.BG_LIGHT_GREY }}>{this._renderMainView()}</XContent>
        {/* {this._renderFooter()} */}
      </XContainer>
    )
  }
}
