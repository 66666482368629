import React from 'react'
import _ from 'lodash'

import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'

import { IAnyObject, IApiOptions, IXScreenProps } from 'x/index'
import { IXShippingConfig } from 'x/config/app'
import api from 'x/utils/api'
import * as NavActions from 'x/utils/navigation'

export interface BaseJAntTConnectViewProps extends IXScreenProps {
  selectedStore: any
  getXShippingConfigFromRedux: IXShippingConfig[]
}

export interface BaseJAntTConnectViewState {
  senderAddresses: IXShippingSenderAddresses
  isVIP: boolean | null
}

export interface INewXShippingAddress {
  id: number
  name: string
  phone: string
  address: string
  province: string
  province_id: number
  district: string
  district_id: number
  postcode: string
  sub_district: string
  xref_addr_id: number
  updated_at: string
}

export interface IXShippingSenderAddresses {
  store_id: number
  system_id: number
  tp_address_id: string
  tp_shop_id: string
  tp_shop_name: string
  is_in_use: boolean
  created_at: string
  updated_at: string
  address: INewXShippingAddress
  site?: {
    name: string
    phone: string
    address: string
    code: string
  }
}

export interface IResponXShippingSenderAddresses {
  sender_addresses: IXShippingSenderAddresses[]
}

export default abstract class BaseJAntTConnectView extends React.Component<BaseJAntTConnectViewProps, BaseJAntTConnectViewState> {
  // DISPLAY_TABS: IUserListTab[]
  // _tabRef: React.RefObject<any>
  // helperProfileId: number

  protected constructor(props) {
    super(props)

    this.state = {
      senderAddresses: null,
      isVIP: null,
    }
    // this._tabRef = React.createRef()
  }

  async componentDidMount() {
    await this._fetchSenderAddresses()
  }

  _goBack = () => {
    util.navGoBack(this.props)
  }

  _fetchSenderAddresses = async () => {
    const { selectedStore } = this.props
    const apiOptions: IApiOptions = {
      showSpinner: true,
    }
    const body = {
      store_id: selectedStore.get('id'),
      system_id: 3,
    }
    const res: IResponXShippingSenderAddresses = await api.postV2(api.POST_FETCH_SENDER_ADDRESSES, body, apiOptions)
    // console.log('res => ', res)
    if (res.sender_addresses && res.sender_addresses.length > 0) {
      await util.setStatePromise(this, {
        senderAddresses: res.sender_addresses[0],
      })
    } else {
      p.op.showConfirmationThreeButtons(
        '',
        'คุณมีรหัส VIP แล้วหรือยัง',
        'มีรหัส VIP แล้ว',
        () => {
          util.setStatePromise(this, { isVIP: true })
          this._navToCreateXShippingAddressView()
        },
        'ยังไม่มีรหัส VIP',
        () => {
          util.setStatePromise(this, { isVIP: false })
          this._navToCreateXShippingAddressView()
        },
        'ยกเลิก',
        () => this._goBack()
      )
    }
  }

  _navToCreateXShippingAddressView(address?: INewXShippingAddress) {
    const { navigation, selectedStore } = this.props
    // const { state, dispatch } = navigation
    const newAddress = _.isNil(address) ? null : address
    navigation.dispatch(
      NavActions.navToCreateXShippingAddressView({
        address: newAddress,
        callBackAddressUpdate: (add: INewXShippingAddress) => this._callBackAddressUpdate(add),
        titleDescription: 'กรุณากรอกชื่อและที่อยู่ด้านล่างเพื่อใช้ในการเชื่อมต่อ',
        name: 'ชื่อร้าน/ผู้ส่ง',
      })
    )
  }

  _callBackAddressUpdate = async (address: INewXShippingAddress) => {
    const { selectedStore } = this.props
    const { senderAddresses, isVIP } = this.state
    const apiOptions: IApiOptions = {
      showSpinner: true,
    }
    // console.log('address => ', address)
    // console.log('isVIP => ', isVIP)
    if (!_.isNil(address) && _.isNil(isVIP)) {
      const newSenderAddresses = _.clone(senderAddresses)
      newSenderAddresses.address = address
      util.setStatePromise(this, {
        senderAddresses: newSenderAddresses,
      })
    } else if (!_.isNil(address) && isVIP) {
      const body: IAnyObject = {
        store_id: selectedStore.get('id'),
        system_id: 3,
        address_id: address.id,
        tp_shop_name: address.name,
        is_in_use: true,
      }
      const res: IResponXShippingSenderAddresses = await api.putV2(api.POST_FETCH_SENDER_ADDRESSES, body, apiOptions)
      // console.log('res => ', res)
      if (res.sender_addresses && res.sender_addresses.length > 0) {
        await util.setStatePromise(this, {
          senderAddresses: res.sender_addresses[0],
        })
        this._openJTXShipping()
      }
      // this._goBack()
    } else if (!_.isNil(address) && !isVIP) {
      const addressReplace = address.name.replace(/ /g, '')
      const body: IAnyObject = {
        store_id: selectedStore.get('id'),
        system_id: 3,
        address_id: address.id,
        tp_shop_name: addressReplace,
        // tp_shop_name: address.name,
        is_in_use: true,
      }
      const res: IResponXShippingSenderAddresses = await api.putV2(api.XSHIPPING_JT_NON_VIP_REGISTRATION, body, apiOptions)
      // console.log('res => ', res)
      if (res.sender_addresses && res.sender_addresses.length > 0) {
        await util.setStatePromise(this, {
          senderAddresses: res.sender_addresses[0],
        })
        this._openJTXShipping()
      }
      // this._goBack()
    } else if (_.isNil(address)) {
      if (_.isNil(senderAddresses)) {
        this._goBack()
      }
    }
  }

  _openJTXShipping = async () => {
    const { getXShippingConfigFromRedux, selectedStore } = this.props
    let apiMethod = api.putV2
    let createMode = true
    const body: { [key: string]: any } = {
      store_id: selectedStore.get('id'),
      system_id: 3,
      shipping_type_id: 38,
      set_auto_request_xshipping_on_create_order: true,
    }
    // console.log('getXShippingConfigFromRedux => ', getXShippingConfigFromRedux)
    getXShippingConfigFromRedux.map((config: IXShippingConfig) => {
      if (config.system_id === 3 && config.shipping_type_id === 38) {
        createMode = false
      }
    })
    if (!createMode) {
      apiMethod = api.patchV2
    }
    const response: { xshipping_config: IXShippingConfig[] } = await apiMethod(api.XSHIPPING_CONFIG, body)
    // console.log('response => // ', response)
    if (response.xshipping_config) {
      createMode = true
      apiMethod = api.putV2
      getXShippingConfigFromRedux.map((config: IXShippingConfig) => {
        if (config.system_id === 3 && config.shipping_type_id === 39) {
          createMode = false
        }
      })
      if (!createMode) {
        apiMethod = api.patchV2
      }
      const newBody: { [key: string]: any } = {
        store_id: selectedStore.get('id'),
        system_id: 3,
        shipping_type_id: 39,
        set_auto_request_xshipping_on_create_order: true,
        create_cod_payment_when_delivered: true,
      }
      const response: { xshipping_config: IXShippingConfig[] } = await apiMethod(api.XSHIPPING_CONFIG, newBody)
    }
  }

  _deleteSenderAddress = async () => {
    const { selectedStore } = this.props
    const { senderAddresses } = this.state
    const apiOptions: IApiOptions = {
      showSpinner: true,
    }
    const body: IAnyObject = {
      store_id: selectedStore.get('id'),
      system_id: 3,
      tp_shop_id: senderAddresses.tp_shop_id,
    }
    const res: IResponXShippingSenderAddresses = await api.delV2(api.POST_FETCH_SENDER_ADDRESSES, body, apiOptions)
    if (res.sender_addresses) {
      p.op.showConfirmationOkOnly('', 'การเชื่อมต่อถูกยกเลิกแล้ว')
      // const { state } = this.props.navigation
      // const { callBackSenderAddress } = state.params
      const callBackSenderAddress = util.getNavParam(this.props, 'callBackSenderAddress')
      if (_.isFunction(callBackSenderAddress)) {
        callBackSenderAddress(null)
      }
      this._goBack()
    }
  }

  _updateSite = async () => {
    const { selectedStore } = this.props
    const { senderAddresses } = this.state
    const apiOptions: IApiOptions = {
      showSpinner: true,
    }
    const body: IAnyObject = {
      store_id: selectedStore.get('id'),
      tp_shop_id: senderAddresses.tp_shop_id,
    }
    const res: IResponXShippingSenderAddresses = await api.postV2(api.XSHIPPING_JT_NON_VIP_REGISTRATION, body, apiOptions)
    // console.log('res => ', res)
    if (res.sender_addresses && res.sender_addresses.length > 0) {
      await util.setStatePromise(this, {
        senderAddresses: res.sender_addresses[0],
      })
      p.op.showToast('อัพเดตข้อมูลเรียบร้อย', 'success')
    }
  }
}
