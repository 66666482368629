// import {fromJS, isImmutable} from "immutable";
import { Scope, TranslateOptions } from 'i18n-js'
import { IActionSheetConfiguration } from 'x/index'
import { I18nObjectType } from 'x/types'

export enum E_MSG_TYPE {
  SUCCESS = 'success',
  DANGER = 'danger',
  WARNING = 'warning',
}

export default interface OpInterface {
  /**
   * Reads the app's state tree
   *
   * @returns The current state tree of application.
   */
  getAppState(): any
  getDispatch(): (action: { type: string; [key: string]: any }) => void
  getCurrentUserId(): Promise<number | null>

  getTranslatorObj(locale?: string): I18nObjectType

  /**
   * Translate the given message key
   * @param {string} msgKey
   * @returns {string}
   */
  // t(msgKey: string, locale?: string, params?: any): string
  t(msgKey: Scope, options?: TranslateOptions): string
  // object translate
  // ot(translatedSelector: (translatedObj: I18nObjectType) => string): string
  changeLocale(localeKey: 'en' | 'th'): void

  showActionSheet(configuration: IActionSheetConfiguration, onSelect: (index: number) => Promise<void> | void): void
  /**
   * Simply show alert with tile and message
   * @param {string} title
   * @param {string} msg
   */
  alert(title: string, msg?: string, callback?: () => void): void
  alertPromise(title: string, msg?: string): Promise<void>

  showLoadingIndicator(): void

  hideLoadingIndicator(): void

  showToast(msg: string, type: 'success' | 'warning' | 'danger', duration?: number): void

  /**
   * (Async) Reads state object from async storage
   *
   * @returns {Promise}
   */
  storageGetImmutable(key: string): Promise<any>

  /**
   * (Async) Reads state object from async storage
   *
   * @param {string} key
   * @param {boolean} isJSON
   * @returns {Promise<any>}
   */
  storageGet(key: string, isJSON?: boolean): Promise<string | { [key: string]: any } | null>

  /**
   * (Async) Saves provided state object to async storage
   *
   * @param {string} key
   * @param state Could be either ImmutableJS or plain JS object
   * @returns {Promise<any>}
   */
  storageSet(key: string, state: any, isJSON?: boolean): Promise<void>

  /**
   * (Async) Clear provided state object to async storage
   *
   * @returns {Promise}
   */
  storageClear(key: string): Promise<void>

  getAppDefaultValue(key: string): Promise<any>
  getAppDefaultObject(): Promise<{ [key: string]: any }>

  setAppDefaultValue(key: string, value: any): Promise<void>
  setAppDefaultObject(obj: { [key: string]: any }): Promise<void>

  initAnalytics(): void

  aLogEvent(name: string, params?: { [key: string]: any }): void
  aLogEventWithUserId(name: string, params?: { [key: string]: any }): Promise<void>

  aLogCurrentView(screenName: string, screenClassOverride?: string): void

  // signOut(dispatch: any): void
  signOut(): Promise<void> // async function

  isIOS(): boolean
  isAndroid(): boolean
  isWeb(): boolean

  showConfirmation(
    title: string,
    message: string,
    okCallback: () => void,
    cancelCallback?: () => void,
    txtOK?: string,
    txtCancel?: string,
    cancelable?: boolean
  ): void

  showConfirmationPromise(
    title: string,
    message: string,
    okCallback: () => void,
    cancelCallback?: () => void,
    txtOK?: string,
    txtCancel?: string,
    cancelable?: boolean
  ): Promise<void>

  isUserConfirm(title: string, message?: string, txtOK?: string, txtCancel?: string, cancelable?: boolean): Promise<boolean>

  showConfirmationOkOnly(title: string, message: string, okCallback?: () => void, txtOK?: string): Promise<void>

  showConfirmationThreeButtons(
    title: string,
    message: string,
    btn1Label: string,
    btn1Callback: () => void,
    btn2Label: string,
    btn2Callback: () => void,
    btnCancelLabel?: string,
    btnCancelCallback?: () => void
  ): void

  handleAfterGetNewProfile(profile: { [key: string]: any })
  getBuildTimestamp(): string
  openFileFromUrl(options: { url: string; fileName?: string; fileType: string }): void | Promise<void>
  openPdfFromUrl(options: { url: string; fileName?: string }): void | Promise<void>
  // saga's generator function
  // handleAuthResponseFromSaga(res: Response)
  // handleAuthErrorFromSaga(err: { code?: number })
  playSoundBeepShort(): void
  playSoundRingSuccess(): void
  playSoundRingError(): void
}
