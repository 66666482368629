import React, { Component } from 'react'
import { Spinner, VStack } from 'native-base'
import HStack from 'xui/components/HStack'
import { View, Clipboard } from 'react-native'

import _ from 'lodash'

import * as util from 'x/utils/util'
import { COLORS } from 'x/config/styles'

import p from 'x/config/platform-specific'
import api from 'x/utils/api'
import { IMarketplaceOrderViewProps, IMarketplaceOrderViewState, IApiOptions } from 'x/index'
import XIcon from 'xui/components/XIcon'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import XCard from 'xui/components/XCard'
import XText from 'xui/components/XText'
import XButtonWithIcon from 'xui/components/XButtonWithIcon'
import XButton from 'xui/components/XButton'
import XCustomHeader from '../../components/XCustomHeader'

export default class BaseUIMarketplaceOrderView extends Component<IMarketplaceOrderViewProps, IMarketplaceOrderViewState> {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      mkp: null,
    }
  }

  async componentDidMount() {
    await this._fetchMarketplace()
  }

  _fetchMarketplace = async () => {
    await util.setStatePromise(this, { isLoading: true })
    await util.delay(1500)
    const { selectedOrder, selectedStore } = this.props
    if (_.isNil(selectedOrder) || _.isNil(selectedStore)) {
      await util.setStatePromise(this, { isLoading: false })
      return
    }

    const body: { [key: string]: any } = {
      store_id: selectedStore.get(`id`),
      order_id: selectedOrder.get('id'),
    }
    try {
      const res = await new Promise((submitted) => {
        this.props.fetchMarketplaceOrder({
          body,
          successCallback: submitted,
          failedCallback: submitted,
        })
      })
      // const res = await api.postV2(
      //   api.POST_ORDER_MARKETPLACE,
      //   { store_id: selectedStore.get('id'), order_id: selectedOrder.get('id') },
      //   { isApiV2: true }
      // )
      // console.log('BaseUIMarketplaceOrderView res => ', res)

      // FIXME: MOCK MKP RESPONSE
      // const mkp = {
      //   mkp_channel_id: 8357,
      //   sn: '2005059YG5W4P5',
      //   created_at: '07-05-2020 15:49',
      //   updated_at: '08-05-2020 05:43',
      //   tracking_no: '66858041455742',
      //   logistic: 'p',

      //   // พวกนี้ยังไม่ finalize น่าจะต้องเป็นลักษณะส่งมาเป็น id แล้วหน้าบ้าน switch case แสดง text เอาเอง?
      //   status: 'พร้อมจัดส่ง',
      //   shipping_type: 'BEST Express',
      // }

      // console.log('res => ', res)
      // @ts-ignore
      await util.setStatePromise(this, { mkp: res.mkp_order })
      // console.log('BaseUIMarketplaceOrderView mkp => ', mkp)
    } catch (err) {
      // console.log('BaseUIMarketplaceOrderView err => ', err)
    }
    await util.setStatePromise(this, { isLoading: false })
  }

  _syncOrderWithMKPOrder = async () => {
    if (this.state.isLoading) {
      return null
    }
    await util.setStatePromise(this, { isLoading: true })
    const { selectedStore, navigation } = this.props
    const { mkp } = this.state
    // console.log('mkp => ', mkp)
    if (_.isNil(mkp)) {
      return null
    }
    const apiOptions: IApiOptions = {
      axiosOptions: {
        retry: 0,
        timeout: 60000,
      },
      isErrorAlertDisabled: true,
    }

    const reqBody = {
      store_id: selectedStore.get('id'),
      mkp_ch_id: mkp.mkp_ch_id,
      // channel_id: mkp.channel_id,
      method: 'UPDATE',
      mkp_order_ids: [mkp.mkp_order_id],
    }

    try {
      const res = await api.postV2(api.POST_SYNC_MKP_ORDER, reqBody, apiOptions)
      // console.log('_syncOrderWithMKPOrder res => ', res)
      if (!_.isNil(res.job_uuid)) {
        const mkpName = util.getMKPName(mkp.mkp_id)
        await util.alertWithNavToBgJobListView({
          title: `สร้าง "คิวคำสั่งงาน" สำหรับซิงค์ข้อมูลออเดอร์กับ${mkpName}สำเร็จ`,
          message: `คิวคำสั่งงาน สำหรับการ "สำหรับซิงค์ข้อมูลออเดอร์กับ${mkpName}" ได้ถูกสร้างแล้ว ระบบกำลังดำเนินการอยู่เบื้องหลัง และอาจใช้เวลาซักครู่จนกว่าจะซิงค์ข้อมูลออเดอร์เสร็จสิ้น`,
          dispatch: navigation.dispatch,
          selectedStoreId: selectedStore.get('id'),
          onUserConfirm: () => this._handleGoBack(),
          initBgJobUUID: res.job_uuid,
        })
        return
      }
      await util.setStatePromise(this, { isLoading: false })
    } catch (err) {
      // console.log('fetchOrderListSummary err => ', err)
      if (util.isXSellyErrorV2(err)) {
        // @ts-ignore
        util.alertWithXSellyErrorV2(err.error)
      }
      await util.setStatePromise(this, { isLoading: false })
    }
  }

  _handleGoBack = () => util.navGoBack(this.props)

  getContainerStyle = () => ({})

  getContentStyle = () => ({})

  renderLabelAndContent = (label: string, content: string) => {
    if (_.isNil(content)) {
      return null
    }
    return (
      <HStack w='full' space='1' alignItems='center'>
        <XText w='120px' variant='inactive'>
          {label}
        </XText>
        <XText flex={1}>{content}</XText>
      </HStack>
    )
  }

  renderTouchableOpacityLabelAndContent = (label: string, content: string) => {
    if (_.isNil(content)) {
      return null
    }

    const copyContent = () => {
      Clipboard.setString(content)
      p.op.showToast(`คัดลอก ${content} เรียบร้อยแล้ว`, 'success')
    }

    return (
      <HStack w='full' space='1' alignItems='center'>
        <XText w='120px' variant='inactive'>
          {label}
        </XText>
        <XButton variant='outline' onPress={() => copyContent()}>
          {content}
        </XButton>
      </HStack>
    )
  }

  getOrderNameText = (): string => {
    const { navigation } = this.props
    const order_id = util.getNavParam(this.props, 'order_id')
    const orderType = util.getNavParam(this.props, 'order_type')
    if (!order_id || !orderType) {
      return ''
    }

    const orderContextText = orderType === 3 ? 'ซื้อ' : 'ขาย'
    const orderNameText = `ออเดอร์${orderContextText} #${order_id}`
    return orderNameText
  }

  // renderOrderName = () => {
  //   return (
  //     <Row style={{ alignItems: 'flex-end' }}>
  //       <XText style={[S.INACTIVE_TEXT, S.SMALL_TEXT]}>{this.getOrderNameText()}</XText>
  //     </Row>
  //   )
  // }

  renderContent = () => {
    if (this.state.isLoading) {
      return <Spinner color={COLORS.TEXT_INACTIVE} />
    }

    if (!this.state.mkp) {
      return null
    }

    const {
      created_at,
      updated_at,
      logistic_text,
      synced_at,
      mkp_order_id,
      shipping_method,
      is_pickup_available,
      is_other_available,
      is_dropoff_available,
      tracking_code,
      status,
      mkp_ch_id,
      mkp_id,
      seller_shipping_fee,
      platform_voucher,
      platform_coin,
      buyer_total_amount,
      buyer_user_id,
      buyer_username,
    } = this.state.mkp

    let logisticText = _.isNil(is_pickup_available) && _.isNil(is_dropoff_available) && _.isNil(is_other_available) ? null : ''
    if (!_.isNil(logisticText)) {
      if (is_pickup_available) {
        logisticText = 'นัดรับ'
      }
      if (is_dropoff_available) {
        logisticText = logisticText.length > 0 ? `${logisticText}, Dropoff` : 'Dropoff'
      }
      if (is_other_available) {
        logisticText = logisticText.length > 0 ? `${logisticText}, อื่นๆ` : 'อื่นๆ'
      }
    }

    // หยิบชื่อช่องทางมากจาก selected store -> channels (mkp channels)
    // const { selectedStore } = this.props
    // const mkpChannels = selectedStore.get('channels') || List([])
    // const mkpChannelName = util.getMarketplceChannelNameById(mkpChannels, mkp_channel_id)
    const mkpChannelName = util.getMKPChannelName(mkp_ch_id)
    const mkpName = util.getMKPName(mkp_id)
    return (
      <XCard w='full'>
        <XCard.Body>
          <VStack w='full' space='1'>
            {this.renderLabelAndContent('ช่องทาง', mkpName)}
            {this.renderLabelAndContent('ชื่อช่องทาง', mkpChannelName)}

            <View style={{ height: 20 }} />

            {_.isNil(mkp_order_id) ? null : this.renderTouchableOpacityLabelAndContent('หมายเลขคำสั่งซื้อ', mkp_order_id)}
            {_.isNil(shipping_method) ? null : this.renderLabelAndContent('รูปแบบการจัดส่ง', shipping_method)}
            {this.renderLabelAndContent('สถานะ', status)}
            {this.renderLabelAndContent('สร้างเมื่อ', created_at)}
            {this.renderLabelAndContent('อัพเดทล่าสุด', updated_at)}
            {this.renderLabelAndContent('ซิงค์เมื่อ', synced_at)}

            <View style={{ height: 20 }} />

            {this.renderLabelAndContent('ขนส่ง', logistic_text)}
            {this.renderLabelAndContent('ประเภทจัดส่ง', logisticText)}
            {this.renderTouchableOpacityLabelAndContent('หมายเลขพัสดุ', tracking_code)}

            {seller_shipping_fee ? this.renderLabelAndContent('ค่าจัดส่งที่ลูกค้าชำระ', `${seller_shipping_fee}`) : null}
            {platform_voucher && platform_voucher !== 0 ? this.renderLabelAndContent(`${mkpName} Voucher`, `${platform_voucher}`) : null}
            {platform_coin && platform_coin !== 0 ? this.renderLabelAndContent(`${mkpName} Shopping Coin`, `${platform_coin}`) : null}
            {buyer_total_amount ? this.renderLabelAndContent('ยอดที่ลูกค้าชำระ', `${buyer_total_amount}`) : null}
            {buyer_user_id ? this.renderLabelAndContent('รหัสลูกค้า', `${buyer_user_id}`) : null}
            {buyer_username ? this.renderLabelAndContent('ชื่อผู้ใช้ของลูกค้า', `${buyer_username}`) : null}

            {/* {this.renderLabelAndContent('ชื่อช่องทาง', 'Nong Puck Official Shop')} */}

            {/* <Row
              style={{
                flex: 1,
                flexBasis: 'auto',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: COLORS.FORM_PRIMARY_BG,
                paddingTop: 4,
                paddingBottom: 4,
                borderBottomWidth: 0.5,
                borderBottomColor: COLORS.TEXT_INACTIVE,
                borderRightWidth: 0.5,
                borderRightColor: COLORS.TEXT_INACTIVE,
              }}>
              <View style={{ width: 44, paddingLeft: 8, alignItems: 'center' }}>
                <FastImage source={require('../../images/icon_shopee_round.png')} style={{ width: 32, height: 32 }} />
              </View>
              <View style={{ flex: 1 }}>
                <XText style={{ fontSize: STYLES.FONT_SIZE_NORMAL, fontWeight: 'bold', textAlign: 'left' }}>{'ทดสอบ'}</XText>
              </View>
              <View style={{ width: 44, paddingRight: 8, alignItems: 'center', justifyContent: 'center' }}>
                <ForwardIcon style={{ flex: p.op.isWeb() ? 1 : 0, width: STYLES.FONT_ICON_NORMAL, height: STYLES.FONT_ICON_NORMAL }} />
              </View>
            </Row> */}
          </VStack>
          {/* <XText>{JSON.stringify(this.props.navigation.state)}</XText> */}
        </XCard.Body>
      </XCard>
    )
  }

  renderHeader = () => (
    <XCustomHeader title={this.getOrderNameText()} headerLeftProps={{ backIcon: true, onPressItem: this._handleGoBack }} />
  )

  renderFooter = () => {
    if (_.isNil(this.state.mkp) || this.state.isLoading) {
      return null
    }
    const { mkp_id } = this.state.mkp
    const mkpName = util.getMKPName(mkp_id)
    return (
      <VStack w='full' minH='10' p='1' alignItems='center' justifyContent='center'>
        <XButtonWithIcon w='full' onPress={() => this._syncOrderWithMKPOrder()} leftIcon={<XIcon name='sync' family='MaterialIcons' />}>
          {`ซิงค์ข้อมูลออเดอร์ล่าสุดกับ ${mkpName}`}
        </XButtonWithIcon>
      </VStack>
    )
  }

  renderMain = () => (
    <XContainer style={this.getContainerStyle()}>
      {this.renderHeader()}
      <XContent style={this.getContentStyle()}>
        <VStack w='full' p='1'>
          {this.renderContent()}
        </VStack>
      </XContent>
      {this.renderFooter()}
    </XContainer>
  )

  render() {
    return this.renderMain()
  }
}
