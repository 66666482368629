/* eslint-disable react/no-array-index-key */
import React from 'react'
import { StyleSheet } from 'react-native'

import { COLORS, STYLES } from 'x/config/styles'
import * as xUtil from 'x/utils/util'
import XIcon from 'xui/components/XIcon'
import 'intl/locale-data/jsonp/th.js'
import p from 'x/config/platform-specific'
import CONS from 'x/config/constants'
import _ from 'lodash'
import { IBaseOrderListItemForDisplayProps, IOrderListItem, ISelectedStoreMap } from 'x/index'
import Immutable, { fromJS } from 'immutable'
import MkpLogo from 'xui/components/MkpLogo'
// import ExpireTimer from '../ExpireTimer'
import * as xFmt from 'x/utils/formatter'
import time from 'x/utils/time'
// import { Divider, VStack } from 'native-base'
import HStack from 'xui/components/HStack'
import NB from 'x/config/nativebase'
import XCard from '../XCard'
import XText from '../XText'
import XShipping from '../XShipping'
import VStack from '../VStack'
import Divider from '../Divider'
import XIconButton from '../XIconButton'
import ErpLogo from '../ErpLogo'
// const COL_WIDTH_CHECKER = p.op.isWeb() ? 32 : 48
// const COL_WIDTH_INFO = 36

interface IOrderListItemProps {
  index: number
  data: IOrderListItem
  // onPress: (orderId: number) => void

  isCheckable?: boolean
  checked?: boolean
  onCheckedPress?: (orderId: number) => void

  onPress: (order: IOrderListItem) => void
  // onPressLeftCol: (orderId: number) => void
  // onPressRightCol: Function
  // isSelected: boolean
  // mode: string
  selectedStore: ISelectedStoreMap
  // autoRequestTracking: (params: ActionApiParams) => void
  // createTackingCode: (params: ActionApiParams) => void
  // updateTackingCode: (params: ActionApiParams) => void
  // cancelTackingCode: (params: ActionApiParams) => void
  // setShouldFetchOrderSummary?: Function
}

export default class OrderListItem extends React.PureComponent<IOrderListItemProps> {
  // constructor(props) {
  //   super(props)
  //   // this._handleDimensionsChange = this._handleDimensionsChange.bind(this)
  //   this._renderCheckerCol = this._renderCheckerCol.bind(this)
  // }

  // componentDidMount() {
  //   if (this._isModeSelectPayment()) {
  //     Dimensions.addEventListener('change', this._handleDimensionsChange)
  //   }
  // }

  // _handleDimensionsChange(newDims) {
  //   // console.log('_handleDimensionsChange')
  //   this.mainContentWidth = newDims.width - COL_WIDTH_INFO - COL_WIDTH_CHECKER
  //   this.forceUpdate()
  // }

  // startTimer() {
  //   // console.log('startTime for ', this.props.data)
  //   if (this.refs.ExpireTimer) {
  //     // @ts-ignore call method in js component
  //     this.refs.ExpireTimer.startTimer()
  //   }
  // }

  // stopTimer() {
  //   // console.log('stopTime for ' + this.props.data)
  //   if (this.refs.ExpireTimer) {
  //     // @ts-ignore call method in js component
  //     this.refs.ExpireTimer.stopTimer()
  //   }
  // }

  // ------------------------------------------------------------------------
  // ------------------------------------------------------------------------

  _onPress = () => {
    const { data, onPress } = this.props
    // log('BaseOrderListItem._onPress')
    // log(this.props.data, this.props.data.item.toJS())
    // onPress(data.item.get('id'))
    // onPress(data.id)
    if (typeof onPress === 'function') {
      onPress(data)
    }
    // this.props.navigation.dispatch(NavActions.navToOrderDetail(item.get('id'), 1))
  }

  // _onPressLeftCol() {
  //   const { data, onPressLeftCol } = this.props
  //   // util.log(this.props.data, this.props.data.item.toJS())
  //   if (_.isFunction(onPressLeftCol)) {
  //     onPressLeftCol(data.item.get('id'))
  //   }
  // }

  // _onPressRightCol() {
  //   const { data, onPressRightCol } = this.props
  //   // util.log(this.props.data, this.props.data.item.toJS())
  //   onPressRightCol(data.item.get('id'))
  // }

  // _isModeSelectPayment() {
  //   return CONS.ORDERS_VIEW_MODE_PAYMENT === this.props.mode
  // }

  _getDisplayData = (i: IOrderListItem): IBaseOrderListItemForDisplayProps => {
    const d = {} as IBaseOrderListItemForDisplayProps
    const UNKNOWN_NAME = '-'
    const orderType = i.type
    const partnerName = i.pn || UNKNOWN_NAME
    let receiverName = i.rn || UNKNOWN_NAME

    // limit the length of receivername if too long
    receiverName = receiverName.trim()
    const receiverNameDisplayLen = p.op.isWeb() ? 12 : 30
    if (receiverName && receiverName.length > receiverNameDisplayLen) {
      receiverName = `${receiverName.substr(0, receiverNameDisplayLen)}..`
    }

    const RECEIVER_PREFIX = 'ลูกค้า'
    let SELLER_ME = 'ฉัน'
    const { selectedStore } = this.props
    const hasMkpChannel = i.m
    // console.log('hasMkpChannel => ', hasMkpChannel)
    const helper = i.h ? i.h : null
    const helpers = Immutable.isMap(selectedStore) && selectedStore.has('helpers') ? selectedStore.get('helpers') : null
    if (!_.isNil(helpers) && !_.isNil(helper)) {
      helpers.map((h) => {
        if (h.get('pid') === helper) {
          if (Immutable.isMap(h) && h.has('n')) {
            SELLER_ME = `ผู้ช่วย [${h.get('n')}]`
          } else {
            SELLER_ME = `ผู้ช่วย`
          }
        }
      })
    }
    d.orderId = i.id
    switch (orderType) {
      case 1:
        d.receiverName = partnerName
        d.receiverNameLabel = RECEIVER_PREFIX
        d.partnerName = SELLER_ME
        d.partnerNameLabel = 'ผู้ขาย'
        break
      case 2:
        d.receiverName = receiverName
        d.receiverNameLabel = RECEIVER_PREFIX
        d.partnerName = `${partnerName}`
        d.partnerNameLabel = 'ผู้ซื้อ(ตัวแทน)'
        break
      case 3:
        d.receiverName = receiverName
        d.receiverNameLabel = RECEIVER_PREFIX
        d.partnerName = `${partnerName}`
        d.partnerNameLabel = 'ผู้ขาย'
        break
      default:
        break
    }

    if (i.x) {
      d.x = i.x
    } else {
      d.x = null
    }
    const orderState = i.o
    const paymentState = i.q
    const shippingState = i.s
    if (orderType && _.isNumber(orderState) && _.isNumber(paymentState) && _.isNumber(shippingState)) {
      d.states = {
        orderState,
        paymentState,
        shippingState,
      }
      const orderStateColor = xUtil.getOrderStateColor(orderType, orderState)
      const paymentStateColor = xUtil.getPaymentStateColor(orderType, paymentState)
      const shippingStateColor = xUtil.getShippingStateColor(orderType, shippingState)
      d.colors = {
        orderStateColor,
        paymentStateColor,
        shippingStateColor,
      }

      const orderStateOfParent = i.u
      const paymentStateOfParent = i.v
      const shippingStateOfParent = i.w
      const parentOrderType = i.pt
      if (parentOrderType) {
        d.parentOrderType = parentOrderType
      }
      if (parentOrderType && _.isNumber(orderStateOfParent) && _.isNumber(paymentStateOfParent) && _.isNumber(shippingStateOfParent)) {
        d.hasParent = true
        const orderStateOfParentColor = xUtil.getOrderStateColor(parentOrderType, orderStateOfParent)
        const paymentStateOfParentColor = xUtil.getPaymentStateColor(parentOrderType, paymentStateOfParent)
        const shippingStateOfParentColor = xUtil.getShippingStateColor(parentOrderType, shippingStateOfParent)
        d.states = {
          ...d.states,
          orderStateOfParent,
          paymentStateOfParent,
          shippingStateOfParent,
        }
        d.colors = {
          ...d.colors,
          orderStateOfParentColor,
          paymentStateOfParentColor,
          shippingStateOfParentColor,
        }
      }
    }

    const codAmt = i.f // cod_amount
    const isShipBeforePay = i.a // ship_before_pay
    const isCod = i.b // is_cod
    if (!_.isNil(codAmt)) {
      d.codAmt = xFmt.formatCurrency(codAmt)
    }
    if (!_.isNil(isCod)) {
      d.isCod = isCod
    }
    if (!_.isNil(isShipBeforePay)) {
      d.isShipBeforePay = isShipBeforePay
    }

    d.orderType = orderType
    d.expireTime = i.e || null
    const order_state_for_cancelled = i.l
    switch (order_state_for_cancelled) {
      case CONS.ORDER_STATE.CANCELLED_BY_SELLER:
        d.cancelledDesc = 'ยกเลิกโดยผู้ขาย'
        break
      case CONS.ORDER_STATE.CANCELLED_BY_BUYER:
        d.cancelledDesc = 'ยกเลิกโดยผู้ซื้อ'
        break
      case CONS.ORDER_STATE.CANCELLED_BY_SYSTEM:
        d.cancelledDesc = 'ยกเลิกโดยระบบ'
        break
      case CONS.ORDER_STATE.CANCELLED_BY_EXPIRED:
        d.cancelledDesc = 'ยกเลิก(เลยกำหนดชำระ)'
        break
    }
    d.labelOrderId = i.p ? `ซื้อ#${i.id}` : `ขาย#${i.id}`
    // d.labelOrderIdInfo = i.p ? `(เพื่อ ขาย#${i.p})` : null
    // d.labelOrderIdInfo = i.p ? `เพื่อ ขาย#${i.p}` : null
    d.labelOrderIdInfo = i.p ? `ขาย#${i.p}` : null
    d.totalAmt = xFmt.formatCurrency(i.t)
    // d.createdAgo = moment(i.c' 'YYYY-MM-DD h:mm').fromNow()
    // d.createdAgo = xUtil.formatDateForDisplay(i.c'
    d.createdAgo = time.convertServerDateTimeToReadableDateTimeText(i.c)
    const completedAt = i.d
    if (completedAt) {
      // d.completedAgo = moment(completedAt, 'YYYY-MM-DD h:mm').fromNow()
      // d.completedAgo = xUtil.formatDateForDisplay(completedAt)
      d.completedAgo = time.convertServerDateTimeToReadableDateTimeText(completedAt)
    } else {
      // printedAt is mutualy-exclusive with completedAt (Only one can be displayed)
      const printedAt = i.n
      if (printedAt) {
        // d.printedAgo = moment(printedAt, 'YYYY-MM-DD h:mm').fromNow()
        // d.printedAgo = xUtil.formatDateForDisplay(printedAt)
        d.printedAgo = time.convertServerDateTimeToReadableDateTimeText(printedAt)
      }
    }

    // HAS MKP CHANNEL
    if (!_.isNil(hasMkpChannel)) {
      d.mkpType = i.m
      d.ugId = i.ug
      d.chId = i.ch
    }
    if (i.k) {
      d.mkp_order_id = i.k
    }
    return d
  }

  // ------------------------------------------------------------------------
  // ------------------------------------------------------------------------

  // _renderSelectionIcon() {
  //   if (this.props.isSelected) {
  //     return <XIcon family='FontAwesome' name='check-circle' variant='success' />
  //   }
  //   return <XIcon family='FontAwesome' name='circle-o' variant='success' />
  // }

  // _renderCheckerCol = () => {
  //   const { data, isSelected } = this.props
  //   // const { index } = data
  //   // const isOdd = index % 2 === 0
  //   if (!this._isAvailableLeftChecker()) {
  //     return null
  //   }
  //   // if (this._isModeSelectPayment()) {
  //   return (
  //     <View
  //       style={{
  //         // flex: p.op.isWeb() ? 1 : 0,
  //         flexBasis: 'auto',
  //         width: COL_WIDTH_CHECKER,
  //         // height: 46,
  //         height: '100%',
  //         // flexDirection: 'row',
  //         flexDirection: 'column',
  //         justifyContent: 'center',
  //         alignItems: 'center',
  //         alignSelf: 'center',
  //         backgroundColor: isSelected ? COLORS.FORM_SUCCESS_BG : COLORS.BG_LIGHT_GREY,
  //       }}>
  //       {/* <TouchableOpacity style={s.sideBtnContainer} onPress={this._onPressLeftCol}>
  //         {this._renderSelectionIcon()}
  //       </TouchableOpacity> */}
  //     </View>
  //   )
  //   // } else {
  //   //   return null
  //   // }
  // }

  // _renderMoreInfo() {
  //   if (this._isModeSelectPayment()) {
  //     return (
  //       <TouchableOpacity style={s.sideBtnContainer} onPress={this._onPressRightCol}>
  //         <VStack style={{ width: COL_WIDTH_INFO, height: 46, justifyContent: 'center', alignItems: 'center' }}>
  //           <Icon
  //             name='md-information-circle'
  //             style={{ fontSize: STYLES.FONT_ICON_NORMAL, color: COLORS.FORM_PRIMARY }} />
  //         </VStack>
  //       </TouchableOpacity>
  //     )
  //   } else {
  //     return <View />
  //   }
  // }

  _renderOrderStateIcons = (
    d: IBaseOrderListItemForDisplayProps
    // {
    // orderType: 1 | 2 | 3
    // parentOrderType?: 1 | 2
    // states: {
    //   orderState: number
    //   paymentState: number
    //   shippingState: number
    //   orderStateOfParent?: number
    //   paymentStateOfParent?: number
    //   shippingStateOfParent?: number,
    // },
    // }
  ) => {
    // if (!d || !d.states || !_.isObject(d.states) || _.isEmpty(d.states)) {
    if (!d || !d.states || !_.isObject(d.colors) || _.isEmpty(d.colors)) {
      return null
    }
    // const { orderState, paymentState, shippingState, orderStateOfParent, paymentStateOfParent, shippingStateOfParent } = d.states

    // const orderStateColor = xUtil.getOrderStateColor(d.orderType, orderState)
    // const paymentStateColor = xUtil.getPaymentStateColor(d.orderType, paymentState)
    // const shippingStateColor = xUtil.getShippingStateColor(d.orderType, shippingState)

    // const hasParent = _.isNumber(orderStateOfParent) && _.isNumber(paymentStateOfParent) && _.isNumber(shippingStateOfParent)
    // const orderStateOfParentColor = xUtil.getOrderStateColor(d.parentOrderType, orderStateOfParent)
    // const paymentStateOfParentColor = xUtil.getPaymentStateColor(d.parentOrderType, paymentStateOfParent)
    // const shippingStateOfParentColor = xUtil.getShippingStateColor(d.parentOrderType, shippingStateOfParent)

    // return (
    //   <Row style={s.rowContainerForIcons}>
    //     {/* <XIcon family='MaterialCommunityIcons' name='file-document-outline' /> */}
    //     {this._renderOrderStateIcon(orderStateColor)}
    //     {this._renderPaymentStateIcon(paymentStateColor)}
    //     {this._renderShippingStateIcon(shippingStateColor)}
    //     {hasParent ? <XText>{'('}</XText> : null}
    //     {hasParent ? this._renderOrderStateIcon(orderStateOfParentColor) : null}
    //     {hasParent ? this._renderPaymentStateIcon(paymentStateOfParentColor) : null}
    //     {hasParent ? this._renderShippingStateIcon(shippingStateOfParentColor) : null}
    //     {hasParent ? <XText>{')'}</XText> : null}
    //   </Row>
    // )
    return (
      <HStack alignItems='center' justifyContent='space-between' space='1'>
        <HStack alignItems='center' style={{ paddingLeft: -8 }}>
          {/* <XIcon family='MaterialCommunityIcons' name='file-document-outline' /> */}
          {this._renderOrderStateIcon(d.colors.orderStateColor)}
          {this._renderPaymentStateIcon(d.colors.paymentStateColor)}
          {this._renderShippingStateIcon(d.colors.shippingStateColor)}

          {d.hasParent ? (
            <XText variant='inactive' style={[s.txtBracket, { paddingLeft: 8 }]}>
              (
            </XText>
          ) : null}
          {d.hasParent ? this._renderOrderStateIcon(d.colors.orderStateOfParentColor) : null}
          {d.hasParent ? this._renderPaymentStateIcon(d.colors.paymentStateOfParentColor) : null}
          {d.hasParent ? this._renderShippingStateIcon(d.colors.shippingStateOfParentColor) : null}
          {d.hasParent ? (
            <XText variant='inactive' style={s.txtBracket}>
              )
            </XText>
          ) : null}
        </HStack>

        <HStack alignItems='center' justifyContent='flex-end' space='1'>
          {/* {d.isCod && d.codAmt ? <XText style={s.listItemValueCODTotal}>{`[COD ${d.codAmt}]`}</XText> : null} */}

          {d.isShipBeforePay ? (
            <XIcon family='MaterialCommunityIcons' name='truck-delivery' style={StyleSheet.flatten(s.iconShipBeforePay)} />
          ) : null}
        </HStack>
      </HStack>
    )
  }

  _renderHasMkpChannel = (d: IBaseOrderListItemForDisplayProps) => {
    if (d.mkpType) {
      return (
        <HStack w='full' alignItems='center' justifyContent='space-between' p='1' space='1' style={{ backgroundColor: COLORS.BLUE_TAB }}>
          <HStack style={{ flex: 1, flexDirection: 'row' }}>
            <MkpLogo mkpId={d.mkpType} style={{ width: 20, height: 20, paddingTop: 1 }} />
            <XText>{` ${xUtil.getMKPChannelName(d.chId)}`}</XText>
          </HStack>
          <HStack alignItems='center' justifyContent='flex-end'>
            <XText>{d.mkp_order_id}</XText>
          </HStack>
        </HStack>
      )
    }
    return null
  }

  _renderHasErpChannel = (data: IOrderListItem) => {
    if (data.erp_docs) {
      const erpDosc = data.erp_docs
      const erpName = xUtil.getErpChannelName(erpDosc[0].erp_ch_id)
      // console.log('erpDosc => ', erpDosc)
      const erpLabel = xUtil.getErpCodeLabel(erpDosc)
      return (
        <HStack w='full' justifyContent='space-between' p='1' style={{ backgroundColor: COLORS.BLUE_TAB }}>
          <HStack style={{ flex: 1, justifyContent: 'flex-start', alignItems: 'flex-start' }}>
            <ErpLogo erpSystemId={erpDosc[0].erp_system_id} style={{ width: 20, height: 20, paddingTop: 1 }} />
            <XText ml='2' numberOfLines={1}>
              {erpName}
            </XText>
          </HStack>
          <VStack flex={1} alignItems='flex-end' justifyContent='flex-end'>
            <XText textAlign='right'>{erpLabel}</XText>
            {/* {erpDosc.map((doc, idx) => {
              let code = doc.erp_doc_code
              if (idx === 2 && erpDosc.length > 3) {
                code = `${doc.erp_doc_code}, (${erpDosc.length - 3})`
              }
              return <XText key={`erp_doc_code_${idx}`}>{code}</XText>
            })} */}
            {/* <XText>IV-20240100008</XText>
            <XText>BL-20240100001</XText>
            <XText>RT-2024010005, (2)</XText> */}
          </VStack>
        </HStack>
      )
    }
    return null
  }

  _renderOrderStateIcon = (color: string) => (
    <XIcon size='xs' family='MaterialCommunityIcons' name='file-document' style={StyleSheet.flatten([s.iconOrder, { color }])} />
  )

  _renderPaymentStateIcon = (color: string) => (
    <XIcon size='xs' family='Ionicons' name='cash' style={StyleSheet.flatten([s.iconPayment, { color }])} />
  )

  _renderShippingStateIcon = (color: string) => (
    <XIcon size='xs' family='MaterialIcons' name='local-shipping' style={StyleSheet.flatten([s.iconShipping, { color }])} />
  )

  _onPressToggleCheck = () => {
    const { data, onCheckedPress } = this.props
    onCheckedPress(data.id)
  }

  // _callBackForXShipping = () => {
  //   const { setShouldFetchOrderSummary } = this.props
  //   if (_.isFunction(setShouldFetchOrderSummary)) {
  //     setShouldFetchOrderSummary(true)
  //   }
  // }

  // _isAvailableLeftChecker = () => _.isFunction(this.props.onPressLeftCol)

  render() {
    // const { updateTackingCode, createTackingCode, cancelTackingCode, selectedStore, autoRequestTracking } = this.props
    const { selectedStore, data, index, isCheckable, checked } = this.props
    xUtil.logRender(this)
    // xUtil.log(this.props.data.item.toJS())
    // const { item } = this.props.data
    // console.log(`item `, item.toJS())
    // const d = this._getDisplayData(this.props.data.item)
    const d = this._getDisplayData(data)
    // console.log('D', d)
    // return (<View style={{backgroundColor: 'blue', width: '100%'}}><XText>sdfsdf</XText></View>)
    // { id, c, e, n, t } // c: createdAt, e: expiredAt, n: name, t: total amount
    // console.log('OrderListItem.Render: ' + this.props.orderId)
    // 12 ก.ค. 60, 13:01:43
    /*
                <Row style={{ borderBottomWidth: 0 }}>
              <View style={s.listItemLabelCol}>
                <XText style={s.listItemLabelText}>{p.op.t('Order.customerName')}</XText>
              </View>
              <View style={s.listItemValueCol}>
                <XText numberOfLines={1} ellipsizeMode='tail' style={[s.listItemValueText, s.alignRight] }>{item.get('n')}</XText>
              </View>
            </Row>
             <Row>
              <View style={s.listItemLabelCol}>
                <XText style={s.listItemLabelText}>{p.op.t('Order.toBeCancelledWithIn')}</XText>
              </View>
              <View style={s.listItemValueCol}>
                <XText style={[s.listItemValueText, s.alignRight] }>
                  <ExpireTimer ref='ExpireTimer' test={this.props.test} serverTimeOffset={60} endTime={item.get('e')}/>
               </XText>
              </View>
            </Row>
      <Row>
               <View style={[s.listItemLabelVStack, { width: '100%' }]}>
                <XText style={[s.listItemValueText, s.alignRight, { color: 'grey' }]}>{p.op.t('Order.createdAt')} {moment(item.get('c'), 'YYYY-MM-DD h:mm:ss').fromNow()}</XText>
              </View>
            </Row>
            */

    let moreTextInfoLabel = null
    let moreTextInfoComp = null
    if (d.expireTime) {
      moreTextInfoComp = (
        <XText
          fontSize='xs'
          // variant='primary'
          // variant='inactive'
        >
          {/* <ExpireTimer ref='ExpireTimer' serverTimeOffset={60} endTime={d.expireTime} /> */}
          {`${time.convertServerDateTimeToReadableDateTimeText(d.expireTime)}`}
        </XText>
      )
      moreTextInfoLabel = 'หมดอายุเมื่อ'
    } else if (d.cancelledDesc) {
      // l === 1 if true
      moreTextInfoComp = (
        <XText fontSize='xs' style={s.listItemValueTextDanger}>
          {d.cancelledDesc}
        </XText>
      )
      moreTextInfoLabel = ''
    } else if (d.completedAgo) {
      moreTextInfoComp = (
        <XText fontSize='xs' style={s.listItemValueTextTotal}>
          {d.completedAgo}
        </XText>
      )
      moreTextInfoLabel = 'เสร็จสิ้นเมื่อ'
    } else if (d.printedAgo) {
      moreTextInfoComp = (
        <XText fontSize='xs' style={s.listItemValueTextTotal}>
          {d.printedAgo}
        </XText>
      )
      moreTextInfoLabel = 'พิมพ์เมื่อ'
    }
    // xUtil.log('OrderListItem....')
    // xUtil.log(item.toJS())

    // const colRightPaddingRightStyle = { paddingRight: this._isModeSelectPayment() ? 3 : 6 }

    // if (Platform.OS === 'web') {
    //   // เพราะว่า web จะมี vertical scroll bar เสมอ
    //   colRightPaddingRightStyle.paddingRight = this._isModeSelectPayment() ? 12 : 20
    // }

    // const screenWidth = this._getScreenWidth()
    // const webScreenWidthWithPadding = this._getScreenWidth() - xCONS.SPECIFIC_PLATFORM.WEB.SCROLLBAR_WIDTH
    // const mainScreenWidthStyle = { width: p.op.isWeb() ? webScreenWidthWithPadding : screenWidth }
    // let mainContentWidth = p.op.isWeb() ? webScreenWidthWithPadding : screenWidth
    // if (p.op.isWeb()) {
    //   mainContentWidth = webScreenWidthWithPadding
    // } else {
    //   mainContentWidth = screenWidth
    // }

    // if has LeftColumn checker
    // if (this._isAvailableLeftChecker()) {
    //   mainContentWidth -= COL_WIDTH_CHECKER
    // }

    // style={StyleSheet.flatten([
    //   s.landing,
    //   s.listItemContainer,
    //   {
    //     backgroundColor: d.orderType === xCONS.ORDER_TYPE_3 ? '#ffeedc' : 'white',
    //     // paddingRight: p.op.isWeb() ? xCONS.SPECIFIC_PLATFORM.WEB.SCROLLBAR_WIDTH : undefined,
    //     overflow: 'hidden',
    //     // width: screenWidth,
    //     flexDirection: 'row',
    //   },
    //   mainScreenWidthStyle,
    //   // { backgroundColor: item.get('type') === 3 ? '#DDDDDD' : 'white' },
    //   // { backgroundColor: this.props.data.index % 2 === 0 ? 'white' : '#e9e9e9' },
    // ])}

    const isSellOrder = d.orderType === CONS.ORDER_TYPE_3

    return (
      <XCard
        w='full'
        // bg={isSellOrder ? '#ffeedc' : undefined}
        _light={{ bg: isSellOrder ? '#ffeedc' : NB.C.L.BG.CLEAR }}
        _dark={{ bg: isSellOrder ? '#ffeedc' : NB.C.D.BG.CLEAR }}
        onPress={this._onPress}>
        <HStack w='full' py='1' px='1.5' _web={{ p: '1' }} flexWrap='wrap'>
          {isCheckable ? (
            <HStack w='34px' alignContent='center' justifyContent='center'>
              <XIconButton
                onPress={this._onPressToggleCheck}
                // family='FontAwesome'
                // name={checked ? 'check-circle' : 'circle-o'}
                family={checked ? 'Ionicons' : 'FontAwesome'}
                name={checked ? 'checkmark-circle' : 'circle-o'}
                iconVariant={checked ? 'success' : 'inactive'}
              />
            </HStack>
          ) : null}
          <VStack flex={1} flexWrap='wrap'>
            {/* <XText>index: {index}</XText> */}
            {this._renderHasMkpChannel(d)}
            {this._renderHasErpChannel(data)}
            <HStack w='full' flexWrap='wrap' space='1'>
              <VStack pt='2px' flex={1} flexWrap='wrap'>
                <HStack alignItems='center'>
                  <XText fontSize='sm'>{d.labelOrderId} </XText>
                  {this._renderOrderStateIcon(d.colors.orderStateColor)}
                  {this._renderPaymentStateIcon(d.colors.paymentStateColor)}
                  {this._renderShippingStateIcon(d.colors.shippingStateColor)}
                </HStack>
                {d.labelOrderIdInfo ? (
                  <HStack alignItems='center'>
                    <XText fontSize='xs' variant='inactive'>
                      (
                    </XText>
                    <XText fontSize='xs' variant='inactive'>
                      {d.labelOrderIdInfo}
                    </XText>
                    {d.hasParent ? this._renderOrderStateIcon(d.colors.orderStateOfParentColor) : null}
                    {d.hasParent ? this._renderPaymentStateIcon(d.colors.paymentStateOfParentColor) : null}
                    {d.hasParent ? this._renderShippingStateIcon(d.colors.shippingStateOfParentColor) : null}
                    <XText variant='inactive'>)</XText>
                  </HStack>
                ) : null}
                {d.isShipBeforePay ? (
                  <XIcon size='xs' family='MaterialCommunityIcons' name='truck-delivery' style={StyleSheet.flatten(s.iconShipBeforePay)} />
                ) : null}
              </VStack>
              <VStack minW='16' alignItems='flex-end' justifyContent='space-between'>
                <XText fontSize='md' bold>
                  {d.cancelledDesc ? null : d.totalAmt}
                </XText>
                {/* {d.isCod && d.codAmt ? <XText style={s.listItemValueCODTotal}>{`[COD ${d.codAmt}]`}</XText> : null} */}
                {d.isCod && d.codAmt ? <XText fontSize='md' bold style={s.listItemValueCODTotal}>{`COD ${d.codAmt}`}</XText> : null}
                {/* {d.isShipBeforePay ? (
                  <XIcon family='MaterialCommunityIcons' name='truck-delivery' style={StyleSheet.flatten(s.iconShipBeforePay)} />
                ) : null} */}
              </VStack>
            </HStack>

            <Divider my='0.5' />

            {/* {this._renderOrderStateIcons(d)} */}

            {/* {this._renderCheckerCol()} */}

            <HStack w='full' justifyContent='space-between'>
              <XText fontSize='xs' variant='inactive'>
                {d.partnerNameLabel}
              </XText>
              <XText fontSize='xs' numberOfLines={2}>
                {d.partnerName}
              </XText>
            </HStack>
            <HStack w='full' justifyContent='space-between'>
              <XText fontSize='xs' variant='inactive'>
                {d.receiverNameLabel}
              </XText>
              <XText fontSize='xs' numberOfLines={2}>
                {d.receiverName}
              </XText>
            </HStack>
            <HStack w='full' justifyContent='space-between'>
              <XText fontSize='xs' variant='inactive'>
                {p.op.t('Order.createdAt')}
              </XText>
              <XText fontSize='xs' numberOfLines={2}>
                {d.createdAgo}
              </XText>
            </HStack>
            {moreTextInfoComp ? (
              <HStack w='full' justifyContent='space-between'>
                <XText fontSize='xs' variant='inactive'>
                  {moreTextInfoLabel}
                </XText>
                {moreTextInfoComp}
              </HStack>
            ) : null}
            {/* {this._renderMoreInfo()} */}
            {d.x ? (
              <VStack w='full'>
                <Divider my='1' />
                <HStack w='full'>
                  <XShipping
                    data={fromJS(d.x)}
                    orderId={d.orderId}
                    storeId={selectedStore.get('id')}
                    limitRes={1}
                    // callBack={() => this._callBackForXShipping()}
                    // @ts-ignore
                    orderStatus={data.o}
                  />
                </HStack>
              </VStack>
            ) : null}
          </VStack>
        </HStack>
      </XCard>
    )
  }

  // injectContainerStyle = () => ({})
}

const s = StyleSheet.create({
  rowContainer: {
    flex: 1,
    paddingTop: 0,
    paddingBottom: 1,
  },
  rowContainerForIcons: {
    flex: 1,
    paddingTop: 0,
    // paddingBottom: 1,
    // borderWidth: 1,
    alignItems: 'center',
  },
  sideBtnContainer: {
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  listItemContainer: {
    flex: 1,
    paddingTop: 6,
    paddingBottom: 6,
    // paddingRight: 6,
    // paddingLeft: 4,
    borderBottomWidth: 1,
    borderColor: COLORS.BLACK,
  },
  alignLeft: {
    textAlign: 'left',
  },
  alignRight: {
    textAlign: 'right',
    // backgroundColor: 'transparent',
    paddingRight: 4,
  },
  listItemLabelCol: {
    width: '45%',
  },
  listItemLabelCol2: {
    width: '25%',
  },
  listItemValueCol2: {
    width: '45%',
  },
  listItemValueCol: {
    width: '55%',
    // flexWrap: 'wrap',
    // backgroundColor: 'red'
  },
  listItemLabelOrderIdActive: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_ACTIVE,
    textAlign: 'left',
    paddingTop: 1,
  },
  listItemLabelOrderIdInActive: {
    // fontSize: 12,
    fontSize: p.op.isWeb() ? STYLES.FONT_SIZE_SMALLER : STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_INACTIVE,
    textAlign: 'left',
    paddingTop: 1,
  },
  listItemLabelText: {
    // backgroundColor: 'green',
    // color: 'grey',
    // justifyContent: 'flex-start',
    // alignItems: 'flex-start',
    // paddingTop: 2,
    fontSize: 12,
    color: COLORS.TEXT_INACTIVE,
    // width: '100%',
  },
  listItemLabelOrderIdCol: {
    width: '55%',
  },
  listItemValueTextOrderId: {
    // fontWeight: 'bold'
    paddingTop: 2,
    // paddingRight: 8,
    fontSize: STYLES.FONT_SIZE_NORMAL,
  },
  listItemValueTextTotal: {
    color: COLORS.FORM_SUCCESS,
    // fontWeight: 'bold',
  },
  listItemValueCODTotal: {
    color: COLORS.BRAND_Primary,
  },
  listItemValueText: {
    color: COLORS.TEXT_ACTIVE,
    flex: 1,
    fontSize: STYLES.FONT_SIZE_NORMAL,
  },
  listItemValueTextDanger: {
    color: COLORS.BRAND_Danger,
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  flatList: {
    flex: 1,
    width: '100%',
  },
  landing: { alignItems: 'center', justifyContent: 'center', flex: 1 },
  line: { backgroundColor: '#ccc', height: StyleSheet.hairlineWidth },

  iconShipBeforePay: {
    // fontSize: STYLES.FONT_ICON_SMALLER - 2,
    color: COLORS.SUMMARY_ORDER_DONUT_COLOR_SCALE.myTasks_payOther,
    // height: 14,
    // width: 14,
    // paddingLeft: 2,
    // paddingTop: 1,
    // textAlign: 'left',
    // marginLeft: -2,
  },
  iconOrder: {
    //   fontSize: STYLES.FONT_ICON_SMALLER - 4,
    //   height: 14,
    //   width: 11,
    //   paddingTop: 1,
    //   textAlign: 'left',
    //   marginLeft: -2,
  },
  iconPayment: {
    // fontSize: STYLES.FONT_ICON_SMALLER,
    // height: 14,
    // width: 16,
    // textAlign: 'left',
    // marginLeft: 4,
    // paddingTop: 0.5,
    // ...Platform.select({
    //   web: {
    //     marginTop: -3,
    //   },
    // }),
  },
  iconShipping: {
    // fontSize: STYLES.FONT_ICON_SMALLER,
    // height: 14,
    // width: 16,
    // textAlign: 'left',
    // marginLeft: 3,
  },
  txtBracket: {
    // fontSize: STYLES.FONT_SIZE_NORMAL,
    // color: COLORS.TEXT_INACTIVE,
    // // paddingBottom: 1,
  },
})
