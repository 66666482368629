import React from 'react'

import { View } from 'react-native'
import { Text } from 'native-base'
// import { RNCamera } from 'react-native-camera'
// import Webcam from 'react-webcam'
// import WebcamScanner from '../../components/WebcamScanner'
// import Quagga from 'quagga'
import BaseUIQuickShipView from 'xui/modules/shipment/BaseUIQuickShipView'
// import { BarcodeSound } from '../../utils/sound'
import * as util from 'x/utils/util'
import { delay, setStatePromise, logRender } from 'x/utils/util'
import p from 'x/config/platform-specific'
import CONS from 'x/config/constants'
import _ from 'lodash'
import { COLORS, STYLES } from 'x/config/styles'
import { IQuickShipViewProps, IQuickShipViewState } from 'x/index'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import ThemeSizeContext from '../../context/ThemeSizeContext'
import BarcodeListener from '../../components/BarcodeListener'
import * as NavActions from '../../utils/navigation'

const barcodeSound = require('../../assets/sounds/barcode_sound.mp3')

class QuickShipView extends BaseUIQuickShipView {
  static displayName = 'QuickShipView'

  static navigationOptions = {
    header: null,
    title: null,
    headerLeft: null,
    headerRight: null,
  }

  static contextType = ThemeSizeContext

  context!: React.ContextType<typeof ThemeSizeContext>

  // cameraRef: React.RefObject<Webcam>
  cameraRef: React.RefObject<any>

  // barcodeSound?: { ring: () => void; release: () => void }
  barcodeSound: HTMLAudioElement

  constructor(props) {
    super(props)
    this.cameraRef = React.createRef()
    this.barcodeSound = new Audio(barcodeSound)
  }

  // async componentDidMount() {
  //   this.barcodeSound = new BarcodeSound()
  //   await super.componentDidMount.apply(this)
  // }

  // componentWillUnmount() {
  //   if (this.barcodeSound && _.isFunction(this.barcodeSound.release)) {
  //     this.barcodeSound.release()
  //   }
  //   super.componentDidMount.apply(this)
  // }

  // @ts-ignore
  componentDidUpdate(prevProps: Readonly<IQuickShipViewProps>, prevState: Readonly<IQuickShipViewState>, snapshot?: any): void {
    const { selectedOrder, navigation } = this.props
    if (prevProps.selectedOrder !== selectedOrder && selectedOrder) {
      const prevOrderId = prevProps.selectedOrder.get('id')
      const newOrderId = selectedOrder.get('id')
      const store_id = util.getNavParam(this.props, 'store_id')
      if (newOrderId !== prevOrderId && newOrderId) {
        this.props.navigation.dispatch(
          NavActions.navToOrderDetail({
            store_id,
            order_id: newOrderId,
            isNoInitFetch: true,
            mode: CONS.ORDER_VIEW_MODE.VIEW_EDIT,
          })
        )
      }
    }
  }

  getCameraWidth = () => 0.8 * this.context.contentOneOfThreeColumnWidth

  getCameraBoxStyle = () => ({
    flexBasis: 'auto',
    // ใช้ dynamic height แล้ว content มันจะขยับ ทำให้ UX ดูไม่ดี
    // minHeight: 0.3 * this.context.contentWithNavHeaderHeight,
    minHeight: 220,
    width: 0.8 * this.context.contentOneOfThreeColumnWidth,
  })

  ringBarcodeSound = () => {
    // console.log('No.Impl::ringBarcodeSound')
    try {
      console.log('QuickShipView::ringBarcodeSound this.barcodeSound => ', this.barcodeSound)
      this.barcodeSound.play()
    } catch (err) {
      console.log('QuickShipView::ringBarcodeSound err => ', err)
    }
    // if (_.isFunction(this.barcodeSound.ring)) {
    //   this.barcodeSound.ring()
    // }
  }

  webInjectOrderBarcodeListener = () => <BarcodeListener minLength={3} thresholdHumanTimeMs={350} onScan={this._handleOnScanBarcodeOrder} />

  _handleOnScanBarcodeOrder = async (data: string) => {
    const { navigation, fetchOrderFromBarcode } = this.props
    const store_id = util.getNavParam(this.props, 'store_id')
    // const order_id = isXSellyOrderId(data) ? parseInt(data) : null
    if (!store_id) {
      return
    }
    // if (!order_id) {
    //   p.op.alert('สแกนเลขออเดอร์ไม่ถูกต้อง', 'กรุณาสแกนเลขออเดอร์ที่เป็นตัวเลขเท่านั้น')
    //   return
    // }
    await this._handleOrderInputChange(data)
    // fetchOrderFromBarcode({ store_id, order_id })
    await this._fetchOrderFromBarcode(_.isNumber(data) ? data : parseInt(data))
  }

  webInjectTrackingBarcodeListener = () => (
    <BarcodeListener minLength={3} thresholdHumanTimeMs={350} onScan={this._handleOnScanBarcodeTracking} />
  )

  _handleOnScanBarcodeTracking = async (data: string) => {
    const { navigation, fetchOrderFromBarcode } = this.props
    const store_id = util.getNavParam(this.props, 'store_id')
    const trackingNumber = _.isString(data) && data.length > 0 ? data : null
    if (!store_id || !trackingNumber) {
      return
    }
    await this._handleTrackingInputChange(data)
    this.onShipBarcodeScan({ data: trackingNumber })
  }

  renderCamera = (params) => {
    const { onBarcodeScanned, isShipCamera = false } = params
    if (!_.isFunction(onBarcodeScanned)) {
      return null
    }

    // const { isCameraGranted, isAndroidOldVersion } = this.state
    // if (!isCameraGranted) {
    //   return (
    //     <TouchableOpacity
    //       onPress={() => {
    //         if (isAndroidOldVersion) {
    //           permN.alertCameraPermission(p.op.t('e_perm.DENIED_CAMERA_TITLE'), p.op.t('e_perm.DENIED_OLD_DEVICE'), true)
    //         } else {
    //           permN.alertCameraPermission(p.op.t('e_perm.DENIED_CAMERA_TITLE'), p.op.t('e_perm.DENIED_CAMERA_MSG'), true)
    //         }
    //       }}
    //       style={{
    //         flex: 1,
    //         justifyContent: 'center',
    //         alignItems: 'center',
    //         backgroundColor: COLORS.FORM_ERROR_BG,
    //         zIndex: 100,
    //       }}>
    //       <Text>{'เราไม่สามารถใช้งานกล้องของคุณได้'}</Text>
    //     </TouchableOpacity>
    //   )
    // }

    const onLocalBarcodeDetected = (e) => this._onSingleLocalBarcodeDetected(e, onBarcodeScanned)
    const onGoogleVisionBarcodesDetected = isShipCamera ? (res) => this._onShipGoogleVisionBarcodesDetected(res, onBarcodeScanned) : null

    return (
      <View
        style={{
          flex: p.op.isWeb() ? 1 : 0,
          flexBasis: 'auto',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
        }}>
        {/* <View */}
        {/*  style={{ */}
        {/*    flex: 1, */}
        {/*    position: 'absolute', */}
        {/*    top: 0, */}
        {/*    left: 0, */}
        {/*    bottom: 0, */}
        {/*    right: 0, */}
        {/*    backgroundColor: COLORS.FORM_ERROR_BG, */}
        {/*    // zIndex: 1, */}
        {/*    padding: 10, */}
        {/*    justifyContent: 'flex-start', */}
        {/*    alignItems: 'center', */}
        {/*  }}> */}
        {/*  <Text style={{ fontSize: STYLES.FONT_SIZE_LARGER_2 }}>{p.op.t('e_perm.DENIED_CAMERA_TITLE')}</Text> */}
        {/*  <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL }}>{p.op.t('e_perm.SETTING_GUIDE_CAMERA_ANDROID')}</Text> */}
        {/* </View> */}

        {/* {this._renderWebcam()} */}

        {/* <TouchableOpacity */}
        {/*  onPress={() => log('camera pressed')} */}
        {/*  style={{ */}
        {/*    flex: 1, */}
        {/*    position: 'absolute', */}
        {/*    top: 0, */}
        {/*    left: 0, */}
        {/*    bottom: 0, */}
        {/*    right: 0, */}
        {/*    backgroundColor: 'transparent', */}
        {/*    zIndex: 100, */}
        {/*  }} */}
        {/* /> */}
      </View>
    )
  }

  // _renderWebcam = () => {
  //   // return (
  //   //   <WebcamScanner
  //   //     onScannerReady={this._onCameraReady}
  //   //     onDetected={evt => console.log('WebcamScanner:: onDetected evt => ', evt)}
  //   //     facingMode={'user'}
  //   //     numOfWorkers={1}
  //   //     cameraId={1}
  //   //     scannerRef={this.cameraRef}
  //   //   />
  //   // )
  //
  //   return (
  //     // @ts-ignore
  //     <Webcam
  //       id={'webcam-scanner'}
  //       height={193}
  //       width={272}
  //       ref={this.cameraRef}
  //       audio={false}
  //       // forceScreenshotSourceSize={false}
  //       // mirrored={true}
  //       onUserMedia={() => {
  //         console.log('Webcam onUserMedia this.cameraRef => ', this.cameraRef)
  //         this._onCameraReady()
  //       }}
  //       onUserMediaError={async (error: string) => {
  //         console.log('Webcam onUserMediaError error => ', error)
  //         await p.op.alertPromise('ไม่มีสิทธิ์การใช้งานกล้อง', 'กรุณาอนุญาติให้เราเข้าถึงกล้องก่อนที่จะเปิดใช้งานเมนูนี้')
  //         this._chooseOptionOrder(1) // เปลี่ยนกลับไปพิมพ์แทน
  //       }}
  //       // screenshotFormat={'image/png'}
  //       // style={this.getCameraBoxStyle()}
  //       screenshotFormat='image/png'
  //       videoConstraints={{
  //         width: 1280,
  //         height: 720,
  //         // width: 640,
  //         // height: 480,
  //         facingMode: 'user',
  //       }}
  //       screenshotQuality={0.98}
  //       // // style={s.qsCameraBox}
  //       // // playSoundOnCapture={false}
  //       // // captureAudio={false}
  //       // // style={{
  //       // //   flex: 1,
  //       // //   alignSelf: 'stretch',
  //       // //   backgroundColor: 'transparent',
  //       // //   // zIndex: 50,
  //       // // }}
  //       // onCameraReady={this._onCameraReady}
  //       // onBarCodeRead={onLocalBarcodeDetected}
  //       // onGoogleVisionBarcodesDetected={onGoogleVisionBarcodesDetected}
  //     />
  //   )
  // }

  _onCameraReady = async () => {
    await delay(100)
    // // log('this.cameraRef => ', this.cameraRef)
    // const cameraRef = this.cameraRef.current
    // if (Platform.OS === 'android' && cameraRef && _.isFunction(cameraRef.getSupportedRatiosAsync)) {
    //   // log('cameraRef x => ', cameraRef)
    //   // cameraRef.getSupportedRatiosAsync().then(this._chooseAndroidCameraRatio)
    //   try {
    //     const ratioArr = await cameraRef.getSupportedRatiosAsync()
    //     // log('_onCameraReady ratioArr => ', ratioArr)
    //     await this._chooseAndroidCameraRatio(ratioArr)
    //   } catch (err) {
    //     // log('_onCameraReady err => ', err)
    //   }
    // }
    // Quagga.init(
    //   {
    //     inputStream: {
    //       type: 'LiveStream',
    //       constraints: {
    //         width: 1280,
    //         height: 720,
    //         // width: 640,
    //         // height: 480,
    //         facingMode: 'user', // or user
    //         // facing: 'environment', // or user
    //       },
    //       target: document.querySelector(`#webcam-scanner`),
    //     },
    //     locator: {
    //       patchSize: 'medium',
    //       halfSample: true,
    //     },
    //     numOfWorkers: 2,
    //     decoder: {
    //       readers: ['code_128_reader'],
    //     },
    //     locate: true,
    //   },
    //   function(err) {
    //     if (err) {
    //       return console.log(err)
    //     }
    //     Quagga.start()
    //   }
    // )
    // Quagga.onDetected(this._onDetected)

    await setStatePromise(this, { isCameraReady: true })
    this.isReadingBarcode = false
  }

  // componentWillUnmount() {
  //   Quagga.offDetected(this._onDetected)
  // }

  // _onDetected(result) {
  //   // this.props.onDetected(result)
  //   console.log('_onDetected result => ', result)
  // }

  // _chooseAndroidCameraRatio = async (ratioArr: string[]) => {
  //   if (!ratioArr.length || ratioArr.length < 1) {
  //     return
  //   }
  //   // log('_chooseAndroidCameraRatio ratioArr => ', ratioArr)
  //   const fallbackRatio = ratioArr[0]
  //   const recommendedRatio = '4:3'
  //   // const recommendedRatio = '16:9'
  //   // const recommendedRatio = '3:2'
  //   const hasRecommendedRatio = ratioArr.find(ratio => ratio === recommendedRatio)
  //   await setStatePromise(this, { ratio: hasRecommendedRatio ? hasRecommendedRatio : fallbackRatio })
  // }

  _handleOnScanBarcodeOrderIdToAutoPressShip = async (data: string) => {
    const { selectedOrder } = this.props

    const scanedOrderId = data
    const selectedOrderId = JSON.stringify(selectedOrder.get('id'))

    /* Expected Returns:
       0:  exact match
      -1:  string_a < string_b
       1:  string_a > string_b
     */
    const matchValue = scanedOrderId.localeCompare(selectedOrderId)
    if (matchValue === 0) {
      await this.onPressSubmitShipping()
    }
  }

  _renderAvailableToShipInfo = () => {
    const isAvailableToShip = this._isAvailableToShip()
    if (!isAvailableToShip) {
      return null
    }

    return (
      <View
        style={{
          width: '100%',
          minHeight: 50,
          flexBasis: 'auto',
          alignItems: 'center',
          justifyContent: 'center',
          paddingLeft: 8,
          paddingRight: 8,
          paddingTop: 6,
          paddingBottom: 6,
          // backgroundColor: COLORS.APP_SECONDARY,
        }}>
        <BarcodeListener minLength={3} thresholdHumanTimeMs={350} onScan={this._handleOnScanBarcodeOrderIdToAutoPressShip} />
        <Text
          style={{
            // fontSize: STYLES.FONT_SIZE_NORMAL,
            fontSize: STYLES.FONT_SIZE_SMALLER,
            // fontWeight: 'bold',
            color: COLORS.TEXT_INACTIVE,
          }}>
          หมายเหตุ: คุณสามารถยืนยันการจัดส่งด้วยเลขพัสดุนี้โดยการกดปุ่ม"จัดส่ง" ด้านล่าง หรือ ยิงบาร์โค้ดไปที่เลขออเดอร์อีกครั้ง
          (ทำให้ไม่ต้องกลับมาใช้เมาส์ในระหว่างการยิงจัดส่ง)
        </Text>
      </View>
    )
  }

  render() {
    logRender(this)
    const { contentHeightStyle, contentOneOfThreeColumnWidthStyle } = this.context
    const { orderCameraState, shipCameraState } = this.props
    return (
      <XContainer style={[contentHeightStyle, contentOneOfThreeColumnWidthStyle]}>
        {this._renderCustomHeader()}
        <XContent
          style={[
            contentHeightStyle,
            contentOneOfThreeColumnWidthStyle,
            {
              margin: 0,
              // paddingLeft: 5,
              // paddingRight: 5
              backgroundColor: 'white',
              // marginRight: CONS.SPECIFIC_PLATFORM.WEB.SCROLLBAR_WIDTH,
              flexWrap: 'wrap',
              // @ts-ignore
              overflowY: 'scroll',
            },
          ]}>
          {this._renderOrderCameraBigBox({ state: orderCameraState })}
          {this._renderShipCameraBigBox({ state: shipCameraState })}
          {this._renderAvailableToShipInfo()}
          {/*
            this._renderWrapCard({
              txtHeader: 'สแกนใบสั่งซื้อ',
              bodyComponent: this._renderOrderCamera({ state: orderCameraState }),
            })
          */}
          {/*
            this._renderWrapCard({
              txtHeader: 'สแกนพัสดุ',
              bodyComponent: this._renderShipCamera({ state: shipCameraState }),
            })
          */}
        </XContent>
        {this._renderFooter()}
      </XContainer>
    )
  }
}
export default QuickShipView
