import React from 'react'
import { Clipboard, Text, View, TouchableOpacity } from 'react-native'
import _ from 'lodash'
import p from 'x/config/platform-specific'
import { COLORS, STYLES, S } from 'x/config/styles'
import XCustomHeader from 'xui/components/XCustomHeader'
import BasePaymentPTTTransactions from 'x/modules/payment/BasePaymentPTTTransactions'
import XIcon from 'xui/components/XIcon'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import { IPaymentPartnerTransactions } from './PaymentPTTComponent'

export default abstract class BaseUIPaymentPTTTransactions extends BasePaymentPTTTransactions {
  static displayName = 'BaseUIPaymentPTTTransactions'
  // abstract goBack: () => void

  renderTransactions = (transaction: IPaymentPartnerTransactions, index: number) => {
    const { orders } = transaction
    const paymentCompleted = transaction.status === 'completed'
    const paymentTextStatus = paymentCompleted ? 'ชำระแล้ว' : 'ยังไม่ชำระ'
    const paymentTextColor = paymentCompleted ? COLORS.GREEN : COLORS.RED
    const partnerName = transaction.pps_id === 2 ? 'Beam' : 'อื่นๆ'
    const mainColor = transaction.pps_id === 2 ? COLORS.BEAM_MAIN : COLORS.TEXT_ACTIVE
    const copyPaymentUrl = (url: string) => {
      Clipboard.setString(url)
      p.op.showToast(`คัดลอกลิงก์ ${url} เรียบร้อยแล้ว`, 'success')
    }
    return (
      <View
        style={[S.CARDLIKE_BORDERS, { backgroundColor: COLORS.WHITE, marginTop: index > 0 ? 10 : 0 }]}
        key={`renderTransactions${index}`}>
        {/* // PARTNER PAYMENT */}
        <View style={{ height: 25, flexDirection: 'row' }}>
          <View style={{ flex: 1, paddingLeft: 4, justifyContent: 'center' }}>
            <Text style={{ fontSize: STYLES.FONT_SIZE_LARGER_3, color: mainColor, fontWeight: 'bold' }}>{partnerName}</Text>
          </View>
          <View style={{ flex: 1, justifyContent: 'center' }}>
            <Text
              style={{
                fontSize: STYLES.FONT_SIZE_LARGER_3,
                paddingRight: 4,
                color: COLORS.GREEN,
                textAlign: 'right',
                fontWeight: 'bold',
              }}>
              {`฿${transaction.total_amount}`}
            </Text>
          </View>
        </View>
        <View style={{ flex: 1, borderBottomWidth: 0.5, borderBottomColor: COLORS.TEXT_ACTIVE }} />
        {/* // STATUS */}
        <View style={{ height: 25, flexDirection: 'row' }}>
          <View style={{ flex: 1, paddingLeft: 4, justifyContent: 'center' }}>
            <Text style={{ fontSize: STYLES.FONT_SIZE_LARGER, color: COLORS.TEXT_INACTIVE }}>สถานะ</Text>
          </View>
          <View style={{ flex: 1, justifyContent: 'center' }}>
            <Text
              style={{
                fontSize: STYLES.FONT_SIZE_LARGER,
                paddingRight: 4,
                color: paymentTextColor,
                textAlign: 'right',
                fontWeight: 'bold',
              }}>
              {paymentTextStatus}
            </Text>
          </View>
        </View>
        {/* // */}
        <View style={{ height: 25, flexDirection: 'row' }}>
          <View style={{ flex: 1, paddingLeft: 4, justifyContent: 'center' }}>
            <Text style={{ fontSize: STYLES.FONT_SIZE_LARGER, color: COLORS.TEXT_INACTIVE }}>{`ใบสั่งซื้อ (${orders.length})`}</Text>
          </View>
          <View style={{ flex: 1, justifyContent: 'center' }}>
            <Text
              style={{
                fontSize: STYLES.FONT_SIZE_LARGER,
                paddingRight: 4,
                color: COLORS.TEXT_INACTIVE,
                textAlign: 'right',
              }}>
              ยอดชำระ
            </Text>
          </View>
        </View>
        {/* // ORDER */}
        {orders.map((order: { order_id: number; amount: number }) => (
          <View style={{ height: 25, flexDirection: 'row' }} key={`od${order.order_id}`}>
            <View style={{ flex: 1, paddingLeft: 4, justifyContent: 'center' }}>
              <Text style={{ fontSize: STYLES.FONT_SIZE_LARGER, color: COLORS.TEXT_ACTIVE }}>{`#${order.order_id}`}</Text>
            </View>
            <View style={{ flex: 1, justifyContent: 'center' }}>
              <Text
                style={{
                  fontSize: STYLES.FONT_SIZE_LARGER,
                  paddingRight: 4,
                  color: COLORS.TEXT_ACTIVE,
                  textAlign: 'right',
                }}>
                {`฿${order.amount}`}
              </Text>
            </View>
          </View>
        ))}
        <View style={{ flex: 1, borderBottomWidth: 0.5, borderBottomColor: COLORS.TEXT_ACTIVE }} />
        {/* // CREATE AT */}
        <View style={{ height: 25, flexDirection: 'row' }}>
          <View style={{ flex: 1, paddingLeft: 4, justifyContent: 'center' }}>
            <Text style={{ fontSize: STYLES.FONT_SIZE_LARGER, color: COLORS.TEXT_INACTIVE }}>สร้าง</Text>
          </View>
          <View style={{ flex: 1, justifyContent: 'center' }}>
            <Text
              style={{
                fontSize: STYLES.FONT_SIZE_LARGER,
                paddingRight: 4,
                color: COLORS.TEXT_ACTIVE,
                textAlign: 'right',
              }}>
              {transaction.created_at}
            </Text>
          </View>
        </View>
        <View style={{ flex: 1, borderBottomWidth: 0.5, borderBottomColor: COLORS.TEXT_ACTIVE }} />
        <View style={{ height: 4 }} />
        <View style={{ height: 38, flexDirection: 'row' }}>
          <View style={{ width: 6 }} />
          <TouchableOpacity
            onPress={() => this.getBeamTransactionStatus(transaction.id)}
            style={{
              width: 40,
              borderWidth: 1,
              borderColor: COLORS.APP_MAIN,
              borderRadius: 7,
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <XIcon
              style={{ flex: 0, minWidth: 25, width: 25, fontWeight: 'bold', color: COLORS.APP_MAIN, fontSize: STYLES.FONT_ICON_NORMAL }}
              name='refresh'
              family='Ionicons'
            />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => copyPaymentUrl(transaction.ref_url)}
            style={{
              flex: 1,
              marginLeft: 8,
              marginRight: 8,
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 7,
              borderWidth: 1,
              borderColor: mainColor,
              backgroundColor: mainColor,
              flexDirection: 'row',
            }}>
            <XIcon
              style={{ flex: 0, minWidth: 25, width: 25, color: COLORS.WHITE, fontSize: STYLES.FONT_ICON_NORMAL }}
              name='copy1'
              family='AntDesign'
            />
            <View style={{ width: 6 }} />
            <Text style={{ fontSize: STYLES.FONT_SIZE_LARGER, color: COLORS.WHITE, fontWeight: 'bold' }}>คัดลอกลิงก์</Text>
          </TouchableOpacity>
        </View>
        <View style={{ height: 4 }} />
      </View>
    )
  }

  render() {
    const { ptts } = this.state
    if (_.isNil(ptts) || ptts.length < 1) {
      return null
    }
    return (
      <XContainer>
        <XContent>
          <XCustomHeader
            headerLeftProps={{
              backIcon: true,
              onPressItem: () => this.navBack(),
            }}
            title='รายการชำระ'
          />
          <View style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 8, paddingRight: 8 }}>
            {ptts.map((transaction: IPaymentPartnerTransactions, index: number) => this.renderTransactions(transaction, index))}
          </View>
        </XContent>
      </XContainer>
    )
  }
}
