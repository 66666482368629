import { Map, List, fromJS } from 'immutable'
import actions from 'x/config/actions'
import // getFBAccessToken,
// setFBAccessTokenInProfile,
'../../redux/selectors'
import * as util from '../../utils/util'
import { ActionApiParams } from '../../index'

// Initial state
const initialState = Map({
  // profile_thumbnail_uri: null,
  // first_name: null,
  // last_name: null,
  // phone1: null,
  // phone2: null,
  // line_id: null,
  // fb_id: null,
  // ig_id: null,
  // display_name: null,
  // contact_email: null,
  // profile_img_uri: null,
  fbAccessToken: null,

  id: null,
  user_id: null,
  first_name: null,
  last_name: null,
  gender: null,
  birth_date: null,
  birth_month: null,
  birth_year: null,
  phone1: null,
  phone2: null,
  img_uri: null,
  thumbnail_uri: null,
  contact_email: null,
  display_name: null,
  created_at: null,
  updated_at: null,
  username: null,
  addresses: List([]),
})

// Actions
export const INITIALIZE_STATE = 'ProfileState/INITIALIZE'
export const SAVE_STATE = 'ProfileState/SAVE'
export const CLEAR_STATE = 'ProfileState/CLEAR'
export const LOAD_PROFILE = 'ProfileState/LOAD_PROFILE'
export const ERROR_LOAD_PROFILE = 'ProfileState/ERROR_LOAD_PROFILE'

export function fetchUserProfile(successCallback, failedCallback) {
  return {
    type: actions.USER_PROFILE_FETCH,
    successCallback,
    failedCallback,
  }
}

export function fetchProfileList(params: ActionApiParams) {
  return {
    type: actions.LOAD_PROFILE_LIST,
    payload: params,
  }
}

// importProfiles ยังใช้งานไม่ได้ หาสาเหตไม่เจอ
export function importProfiles(params: ActionApiParams) {
  return {
    type: actions.IMPORT_PROFILES,
    payload: params,
  }
}

export function approvalsReseller(params: ActionApiParams) {
  return {
    type: actions.APPROVALS_RESELLER,
    payload: params,
  }
}

export function fetchProfileDetails(params: ActionApiParams) {
  return {
    type: actions.FETCH_PROFILE_DETAILS,
    payload: params,
  }
}

export function updateOrgMemberProfileDetail(params: ActionApiParams) {
  return {
    type: actions.UPDATE_ORG_MEMBER_PROFILE_DETAILE,
    payload: params,
  }
}

export function updateLoginEmail(params: ActionApiParams) {
  return {
    type: actions.UPDATE_LOGIN_EMAIL,
    payload: params,
  }
}

export function changeParent(params: ActionApiParams) {
  return {
    type: actions.CHANGE_PARENT,
    payload: params,
  }
}

export function changeResellerType(params: ActionApiParams) {
  return {
    type: actions.CHANGE_RESELLER_TYPE,
    payload: params,
  }
}

export function orgMemberSignup(params: ActionApiParams) {
  return {
    type: actions.ORG_MEMBER_SIGNUP,
    payload: params,
  }
}

/**
 * ส่งข้อมูล profile ทั้งหมดเข้ามาใหม่ เพื่อ set ค่าลง (merge)
 * !!!! validate ข้อมูลเข้ามาก่อน ไม่งั้นค่าใหม่จะทับค่าเก่าทั้งหมด !!!!
 * @export
 * @param {object} profile
 */
export function save(profile) {
  util.log('profile ', profile)
  return {
    type: SAVE_STATE,
    payload: profile,
  }
}

/**
 * เคลียร์ว่างทั้งหมด
 * @export
 */
export function clear() {
  return {
    type: CLEAR_STATE,
  }
}

// Reducer
export default function ProfileStateReducer(state = initialState, action: ActionApiParams = {}) {
  const { type, payload } = action
  switch (type) {
    case INITIALIZE_STATE:
    case CLEAR_STATE:
      return initialState

    case SAVE_STATE:
      // return state.merge({
      //   first_name: payload.profile.first_name,
      //   last_name: payload.profile.last_name,
      // })
      return state.mergeDeep(payload.profile)

    // case LOAD_PROFILE:
    //   return state.merge({ ...payload.profile })

    case LOAD_PROFILE:
      return state.mergeDeep(fromJS(payload))
    // case ON_CHANGE_TEXT:
    //   return state

    default:
      return state
  }
}
