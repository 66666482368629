/* eslint-disable no-nested-ternary */
/* eslint-disable indent */
import React from 'react'
import XContent from 'xui/components/XContent'
import XCustomHeader from 'xui/components/XCustomHeader'
import BaseCategoryListView from 'x/modules/store/Category/BaseCategoryListView'
import XContainer from 'xui/components/XContainer'
import _ from 'lodash'
import XText from 'xui/components/XText'
import Box from 'xui/components/Box'
import { ScrollView, TouchableOpacity } from 'react-native'
import BackIcon from 'xui/components/BackIcon'
import p from 'x/config/platform-specific'
import XIcon from 'xui/components/XIcon'
import { COLORS } from 'x/config/styles'
import { ICategory } from 'x/types'
import * as NavActions from 'x/utils/navigation'
import XButton from 'xui/components/XButton'
import * as util from 'x/utils/util'

const MODE = {
  VIEW: 'VIEW',
  CREATE: 'CREATE',
  EDIT: 'EDIT',
}

export default abstract class BaseUICategoryListView extends BaseCategoryListView {
  // abstract goBack: () => void

  _renderHeader = () => {
    const { mode } = this.state
    // const { navigation } = this.props
    // const { state } = navigation
    // // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    // let title: string = state.params ? state.params.title : 'หมวดหมู่สินค้า'
    let title = util.getNavParam(this.props, 'title', 'หมวดหมู่สินค้า')
    // let leftText = 'บันทึก'
    if (_.isNil(title) && mode === MODE.CREATE) {
      title = 'หมวดหมู่สินค้า'
      // leftText = 'สร้าง'
    } else if (mode === MODE.VIEW) {
      title = 'หมวดหมู่สินค้า'
    }
    return (
      <XCustomHeader
        title={title}
        renderHeaderLeft={() => this._renderHeaderLeft()}
        // renderHeaderRight={this._renderHeaderRight}
      />
    )
  }

  _renderHeaderRight = () => {
    // const { navigation } = this.props
    // const { state } = navigation
    const { mode } = this.state

    // const cancelBtn = state.params ? state.params.cancelBtn : null
    // const saveBtn = state.params ? state.params.saveBtn : null
    // const onChange = state.params ? state.params.onChange : null
    // const handleRightBtn = state.params ? state.params.handleRightBtn : null
    return _.isNil(mode) || mode === MODE.VIEW ? null : (
      <TouchableOpacity
        style={{ width: 50, height: 50, justifyContent: 'center', paddingRight: 4 }}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onPress={() => null}>
        <XText variant='primary'>{p.op.t('Order.cancelled')}</XText>
      </TouchableOpacity>
    )
  }

  _renderHeaderLeft = () => {
    // const { mode } = this.state
    const { navigation } = this.props
    return (
      <TouchableOpacity
        style={{ width: 50, height: 50, justifyContent: 'center', paddingLeft: 10 }}
        onPress={() => util.navGoBack(this.props)}>
        <BackIcon />
      </TouchableOpacity>
    )
  }

  _renderHeaderCategory = () => {
    const { breadCrumbs } = this.state
    const haveCat = !_.isNil(breadCrumbs)
    const sortBreadCrumbs = _.orderBy(breadCrumbs, ['indexBreadCrumbs'], ['asc'])
    return (
      <Box h='11' borderBottomWidth='1px' borderBottomColor='black' alignItems='center' justifyContent='center'>
        <Box flexDirection='row' w='full'>
          <ScrollView horizontal showsHorizontalScrollIndicator={false}>
            <TouchableOpacity
              // key={data.id}
              onPress={() => this._onPressBreadCrumbsHome()}
              style={{
                width: 44,
                height: 44,
                borderRadius: 7,
                marginRight: 4,
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <XIcon name='home' family='FontAwesome' color='black' />
            </TouchableOpacity>
            <XText color='black' fontSize='lg' textAlign='center' alignSelf='center'>
              /
            </XText>
            {haveCat
              ? sortBreadCrumbs.map((data: ICategory) => (
                  <Box flexDirection='row' alignItems='center' key={data.id}>
                    <TouchableOpacity
                      onPress={() => this._seletedBreadCrumbs(data)}
                      style={{
                        minWidth: 50,
                        // width: 50,
                        maxWidth: 120,
                        height: 30,
                        borderColor: _.isNil(data.l) ? COLORS.TEXT_INACTIVE : data.l,
                        borderWidth: 1,
                        borderRadius: 7,
                        marginLeft: 4,
                        marginRight: 4,
                        // marginTop: 6,
                        justifyContent: 'center',
                      }}>
                      <XText color={_.isNil(data.l) ? COLORS.TEXT_INACTIVE : data.l} textAlign='center' py='0.5' px='0.5' numberOfLines={1}>
                        {data.n}
                      </XText>
                    </TouchableOpacity>
                    <XText textAlign='center' color='black' fontSize='lg' alignSelf='center'>
                      /
                    </XText>
                  </Box>
                ))
              : null}
          </ScrollView>
        </Box>
      </Box>
    )
  }

  _renderBodySectionProperty = () => {
    const { selectedCategory, category } = this.state
    // console.log('selectedCategory => ', selectedCategory)
    if (_.isNil(selectedCategory)) {
      return null
    }
    const txtColor = _.isNil(selectedCategory.l) ? COLORS.TEXT_INACTIVE : selectedCategory.l
    return (
      <Box p='1' ml='2'>
        <Box flexDirection='row' h='10' alignItems='center'>
          <Box style={{ flex: 1, flexDirection: 'row' }}>
            <XText color='gray.500' bold>{`หมวดหมู่: `}</XText>
            <XText color={txtColor} bold>
              {selectedCategory.n}
            </XText>
          </Box>
          <Box w='10'>
            <TouchableOpacity
              style={{ height: 40, width: 40, alignItems: 'center', justifyContent: 'center' }}
              onPress={() =>
                this.props.navigation.dispatch(
                  NavActions.navToCategoryPropertyViewContainer({
                    mode: 'VIEW',
                    category: selectedCategory,
                    allCategory: category,
                    callBackCE: (newCat: ICategory[]) => this._callBackForCreateAndEdit(newCat),
                    callBackDelete: (NewCatUpdate: { newCategory: ICategory[]; deleteCat: ICategory }) =>
                      this._callBackForDeleteCategory(NewCatUpdate),
                    callBackChangeCategory: (NewCatUpdate: { newCategory: ICategory[]; changeCat: ICategory }) =>
                      this._callBackChangeCategory(NewCatUpdate),
                  })
                )
              }>
              <XIcon name='settings' family='Ionicons' variant='primary' />
            </TouchableOpacity>
          </Box>
        </Box>
      </Box>
    )
  }

  _renderAddCategory = () => {
    const { selectedCategory, category } = this.state
    const text = _.isNil(selectedCategory) ? `สร้างหมวดหมู่` : `สร้างหมวดหมู่ย่อย`
    const haveCat = _.isNil(selectedCategory) ? null : selectedCategory
    return (
      <Box
        style={{
          height: 45,
          width: `100%`,
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          flexDirection: 'row',
          paddingRight: 10,
        }}>
        <XButton
          leftIcon={<XIcon name='plus' family='AntDesign' variant='primary' />}
          onPress={() =>
            this.props.navigation.dispatch(
              NavActions.navToCategoryPropertyViewContainer({
                mode: 'CREATE',
                category: haveCat,
                allCategory: category,
                callBackCE: (newCat: ICategory[]) => this._callBackForCreateAndEdit(newCat),
                callBackDelete: (NewCatUpdate: { newCategory: ICategory[]; deleteCat: ICategory }) =>
                  this._callBackForDeleteCategory(NewCatUpdate),
                callBackChangeCategory: (NewCatUpdate: { newCategory: ICategory[]; changeCat: ICategory }) =>
                  this._callBackChangeCategory(NewCatUpdate),
              })
            )
          }>
          <XText variant='primary' textAlign='center' py='0.5' color='white'>
            {text}
          </XText>
        </XButton>
      </Box>
    )
  }

  _goBack = () => {
    util.navGoBack(this.props)
  }

  _renderBodySectionSubCategory = () => {
    const { subCategory, mode } = this.state
    const EDIT_MODE = mode === MODE.EDIT
    const sortSupCategory = _.orderBy(subCategory, ['index'], ['asc'])
    return (
      <Box flexDirection='column'>
        {this._renderAddCategory()}
        {_.isNil(sortSupCategory) || sortSupCategory.length < 2 ? null : (
          <Box h='11' w='full' justifyContent='flex-end' alignItems='flex-end' flexDirection='row' pr='2.5'>
            {EDIT_MODE ? (
              <XButton mr='2' leftIcon={<XIcon name='save' family='FontAwesome' variant='primary' />} onPress={() => this._sortCategory()}>
                <XText variant='primary' textAlign='center' py='0.5' color='white'>
                  บันทึก
                </XText>
              </XButton>
            ) : (
              <Box w='90px' ml='1' mt='2.5' />
            )}
            {EDIT_MODE ? (
              <XButton
                variant='outline'
                leftIcon={<XIcon name='cancel' family='MaterialCommunityIcons' variant='primary' />}
                onPress={() => this._changeToViewMode()}>
                <XText variant='primary' py='0.5' textAlign='center'>
                  ยกเลิก
                </XText>
              </XButton>
            ) : (
              <XButton
                leftIcon={<XIcon name='sort' family='MaterialCommunityIcons' variant='primary' />}
                onPress={() => this._changeToEditMode()}>
                <XText variant='primary' textAlign='center' py='0.5' color='white'>
                  ลำดับหมวดหมู่
                </XText>
              </XButton>
            )}
          </Box>
        )}

        {sortSupCategory.map((data: ICategory, index: number) => {
          // const marginTop = index === 0 ? 0 : 10
          const color = _.isNil(data.l) ? COLORS.TEXT_INACTIVE : data.l
          // const haveChildren = this._chackChildren(data)
          // const count = this._chackChildrenCount(data)
          const count = data.c.length
          return (
            <TouchableOpacity
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              // disabled={EDIT_MODE}
              onPress={() => this._seletedSupCategory(data)}
              style={{
                // minWidth: 50,
                // width: 50,
                // maxWidth: 120,
                flex: 1,
                height: 40,
                borderColor: color,
                borderLeftWidth: 1,
                borderRightWidth: 1,
                borderTopWidth: 1,
                borderBottomWidth: 1,
                borderRadius: 7,
                marginLeft: 10,
                marginRight: 10,
                marginTop: 10,
                justifyContent: 'center',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <XText fontSize='lg' pl='2' flex={1} textAlign='left' py='2' color={color} numberOfLines={1}>
                {data.n}
              </XText>
              {mode === MODE.EDIT ? (
                <Box flexDirection='row'>
                  {index === 0 ? null : (
                    <TouchableOpacity
                      style={{ width: 40, height: 40, justifyContent: 'center' }}
                      onPress={() => this._sortUp(sortSupCategory, index)}>
                      <XIcon name='arrow-circle-o-up' family='FontAwesome' variant='primary' />
                    </TouchableOpacity>
                  )}
                  {index + 1 === subCategory.length ? null : (
                    <TouchableOpacity
                      style={{ width: 40, height: 40, justifyContent: 'center' }}
                      onPress={() => this._sortDown(sortSupCategory, index)}>
                      <XIcon
                        name='arrow-circle-o-down'
                        family='FontAwesome'
                        style={{ flex: 0, fontSize: 23, color: COLORS.APP_MAIN, width: 40 }}
                      />
                    </TouchableOpacity>
                  )}
                </Box>
              ) : (
                <Box flexDirection='row' justifyContent='center' alignItems='center'>
                  <Box style={{ width: 30, height: 20, borderRadius: 7, backgroundColor: COLORS.TEXT_INACTIVE }}>
                    <XText textAlign='center' color='white'>
                      {count}
                    </XText>
                  </Box>
                  <XIcon name='right' family='AntDesign' variant='inactive' />
                </Box>
              )}
            </TouchableOpacity>
          )
        })}
      </Box>
    )
  }

  render() {
    const { mode } = this.state
    if (_.isNil(mode)) {
      return null
    }
    return (
      <XContainer>
        {this._renderHeader()}
        <XContent _light={{ bg: 'white' }} _dark={{ bg: 'gray.200' }}>
          {this._renderHeaderCategory()}
          {this._renderBodySectionProperty()}
          {this._renderBodySectionSubCategory()}
        </XContent>
        {/* {loading} */}
      </XContainer>
    )
  }
}
