import React from 'react'

import _ from 'lodash'
import { log, setStatePromise } from 'x/utils/util'
import { Map } from 'immutable'
import xCONS from 'x/config/constants'
import dayjs, { Dayjs } from 'dayjs'
import { IXScreenProps } from 'x/types'
import p from '../../config/platform-specific'
import * as util from '../../utils/util'

export interface BaseReportResellerViewProps extends IXScreenProps {
  selectedStore: Map<string, any>
  subscription: any
}

export interface BaseReportResellerViewState {
  loading: boolean
  // selectedOptListBy: string;
  // selectedOptDateRange: string;
  selectedOpts: {
    REPORT_TYPE: string
    TIME: string
  }
  selectedDateRange: { begin: Dayjs; end: Dayjs }
  WAIT_TO_SHIP_ORDERS: boolean
  WAIT_TO_REFUND_ORDERS: boolean
  COST: boolean
  selectedOptionTimePeriod: string
  limitDay: number
  dateRangeDescription: string
}

export default abstract class BaseReportResellerView extends React.Component<BaseReportResellerViewProps, BaseReportResellerViewState> {
  inProcess: boolean

  TXT_TITLES: {
    REPORT_TYPE: string
    TIME: string
  }

  TXT_WARNING_DATE_RANGE: string

  OPTIONS: {
    REPORT_TYPE: string[]
    TIME: string[]
  }

  OPTIONS_TIME_PERIOD: string[]
  // OPTIONS_LIST_BY: string[];
  // OPTIONS_DATE_RANGE: string[];

  // abstract _getParams(): { store_id: number } | null
  abstract _handleOnDownloadFile(url: string, fileName: string): Promise<void>

  protected constructor(props) {
    super(props)
    this.TXT_TITLES = {
      REPORT_TYPE: 'ประเภทรายงาน',
      TIME: 'ช่วงเวลาที่ต้องการ',
    }
    this.initSettings()

    this.OPTIONS = {
      REPORT_TYPE: ['สรุปรวมทุกวัน', 'แยกรายวัน'],
      TIME: ['7 วัน', '30 วัน', 'ระบุ'],
    }

    this.TXT_WARNING_DATE_RANGE = 'แพ็กเกจปัจจุบันของคุณไม่สามารถเลือกช่วงเวลาเพื่อดูรายการจัดส่งย้อนหลังได้'

    this.OPTIONS_TIME_PERIOD = ['7 วัน', '30 วัน', 'ระบุ']

    this.state = {
      loading: false,
      selectedOpts: {
        REPORT_TYPE: this.OPTIONS.REPORT_TYPE[0],
        TIME: this.OPTIONS.TIME[0],
      },
      selectedDateRange: { begin: dayjs(), end: dayjs() },
      WAIT_TO_SHIP_ORDERS: false,
      WAIT_TO_REFUND_ORDERS: false,
      COST: false,
      selectedOptionTimePeriod: this.OPTIONS_TIME_PERIOD[0],
      limitDay: 93,
      dateRangeDescription: '',
    }

    this.inProcess = false

    this._onChangeSegment = this._onChangeSegment.bind(this)
    this._onChangeDateRange = this._onChangeDateRange.bind(this)
    this._downloadReportRequisitionUrl = this._downloadReportRequisitionUrl.bind(this)
    this._computeAllowDays = this._computeAllowDays.bind(this)
    // this._getTxtDateRangeDescription = this._getTxtDateRangeDescription.bind(this)
  }

  initSettings = async () => {
    if (!this._canRequestMoreColumns()) {
      // set to default
      this.setState({
        WAIT_TO_SHIP_ORDERS: true,
        WAIT_TO_REFUND_ORDERS: false,
        COST: false,
      })
    } else {
      const { STORAGE_KEYS } = xCONS
      p.op.storageGet(STORAGE_KEYS.REPORT_REQUIS_WAIT_TO_SHIP_ORDERS).then((val) => {
        if (val != null) {
          // @ts-ignore FIXME: @Artid type ไม่ถูกต้อง
          util.setStatePromise(this, {
            WAIT_TO_SHIP_ORDERS: val,
          })
        } else {
          // default to true
          this.setState({
            WAIT_TO_SHIP_ORDERS: true,
          })
        }
      })
      p.op.storageGet(STORAGE_KEYS.REPORT_REQUIS_WAIT_TO_REFUND_ORDERS).then((val) => {
        if (val != null) {
          // @ts-ignore FIXME: @Artid type ไม่ถูกต้อง
          util.setStatePromise(this, {
            WAIT_TO_REFUND_ORDERS: val,
          })
        }
      })
      p.op.storageGet(STORAGE_KEYS.REPORT_REQUIS_SHOW_COST).then((val) => {
        if (val != null) {
          // @ts-ignore FIXME: @Artid type ไม่ถูกต้อง
          util.setStatePromise(this, {
            COST: val,
          })
        }
      })
    }
  }

  _onChangeSegment(optKey: string, newValue: string): void {
    const newSelectedOpts = _.cloneDeep(this.state.selectedOpts)
    newSelectedOpts[optKey] = newValue
    let trialPackage = false
    if (this.props.subscription.get('type') === 999) {
      trialPackage = true
    }
    // แพ็กเกจ (Free)
    if (this.props.subscription.get('type') === xCONS.SUBSCRIPTION_PACKAGE.FREE) {
      if (newSelectedOpts[optKey] === this.OPTIONS_TIME_PERIOD[1] || newSelectedOpts[optKey] === this.OPTIONS_TIME_PERIOD[2]) {
        p.op.alert(p.op.t('Subscription.warning.insufficientTypeTitle'), p.op.t('Subscription.warning.insufficientTypeMsg'))
        return
      }
    }
    // แพ็กเกจ (Bronze)
    if (this.props.subscription.get('type') === xCONS.SUBSCRIPTION_PACKAGE.BRONZE) {
      const num = +p.op.t('Subscription.packageReportSeller.2')
      const txtDateRangeDescription = `${p.op.t('Subscription.warning.tooManyDaysInPastTitle', { numOfDays: num })}\n${p.op.t(
        'Subscription.warning.tooManyDaysInPastMsg'
      )}`
      this.setState({ limitDay: num, dateRangeDescription: txtDateRangeDescription })
    }
    // แพ็กเกจ Silver
    if (this.props.subscription.get('type') === xCONS.SUBSCRIPTION_PACKAGE.SILVER) {
      const num = +p.op.t('Subscription.packageReportSeller.3')
      const txtDateRangeDescription = `${p.op.t('Subscription.warning.tooManyDaysInPastTitle', { numOfDays: num })}\n${p.op.t(
        'Subscription.warning.tooManyDaysInPastMsg'
      )}`
      this.setState({ limitDay: num, dateRangeDescription: txtDateRangeDescription })
    }
    // แพ็กเกจ Gold
    if (this.props.subscription.get('type') === xCONS.SUBSCRIPTION_PACKAGE.GOLD) {
      const num = +p.op.t('Subscription.packageReportSeller.4')
      const txtDateRangeDescription = `${p.op.t('Subscription.warning.tooManyDaysInPastTitle', { numOfDays: num })}\n${p.op.t(
        'Subscription.warning.tooManyDaysInPastMsg'
      )}`
      this.setState({ limitDay: num, dateRangeDescription: txtDateRangeDescription })
    }
    // แพ็กเกจ (Platinum)
    if (this.props.subscription.get('type') === xCONS.SUBSCRIPTION_PACKAGE.PLATINUM || trialPackage) {
      const num = +p.op.t('Subscription.packageReportSeller.5')
      const txtDateRangeDescription = `${p.op.t(
        'Subscription.warning.dateRangePlatinumTypeTitle'
      )}\n${p.op.t('Subscription.warning.numOfDaysLimitInfoMsg', { numOfDays: num })}`
      this.setState({ limitDay: num, dateRangeDescription: txtDateRangeDescription })
    }
    this.setState({ selectedOpts: newSelectedOpts })
  }

  _onChangeDateRange(newDates: { begin: Dayjs; end: Dayjs }): void {
    if (this.props.subscription.get('type') != 1 && this.props.subscription.get('type') != 5) {
      const { begin } = newDates
      const now = dayjs()
      if (begin.isBefore(now) && Math.abs(begin.diff(now, 'd')) > +this.state.limitDay) {
        newDates.begin = dayjs(now).subtract(+this.state.limitDay, 'day')
        newDates.end = dayjs(now)
        this.setState({ selectedDateRange: newDates })
        return
      }
    }
    this.setState({ selectedDateRange: newDates })
  }

  _isVisibleDateRangePicker(): boolean {
    return this.state.selectedOpts.TIME === this.OPTIONS.TIME[2]
  }

  _computeAllowDays(): number {
    const { subscription } = this.props
    const allowDays = subscription.has('r_shpg_day') && _.isNumber(subscription.get('r_shpg_day')) ? subscription.get('r_shpg_day') : 1
    // log('_computeAllowDays => ', _.isNumber(allowDays) ? allowDays : parseInt(allowDays))
    return _.isNumber(allowDays) ? allowDays : parseInt(allowDays)
  }

  _canRequestMoreColumns = (): boolean => util.canRequestExtraExcelColumns(this.props.subscription.get('type'))

  _getParams() {
    // return this.props.navigation.state.params
    const params = util.getNavParams(this.props)
    return params
  }

  async _downloadReportRequisitionUrl(): Promise<void> {
    // ถ้าเป็น IOS และเข้าเงื่อนไขก็ปล่อยผ่าน // https://app.clickup.com/t/86cvy21qt
    const isNoobIos = util.isIosNoobCheckByPass()
    // if (isNoobIos) {
    //   p.op.showConfirmationOkOnly('', p.op.t('Subscription.warning.iosNoobCheckMsg'))
    //   return
    // }
    if (!isNoobIos && util.isPackFreeAndSelectedStoreOwner()) {
      p.op.alert(p.op.t('Subscription.warning.insufficientTypeTitle'), p.op.t('Subscription.warning.insufficientTypeMsg'))
      // this.inProcess = false
      return
    }

    // TODO: Check for perm on BaseReportProductDailySales line 335?
    // const isHlper = util.isHelper()
    // // if (!isHlper && util.isStoreOwner() && this.props.subscription.get('type') === xCONS.SUBSCRIPTION_PACKAGE.FREE) {
    // if (!isHlper && util.isStoreOwner()) {
    //   p.op.alert(p.op.t('Subscription.warning.insufficientTypeTitle'), p.op.t('Subscription.warning.insufficientTypeMsg'))
    //   return
    // }
    if (this.inProcess || this.state.loading) {
      return
    }
    this.inProcess = true
    await setStatePromise(this, { loading: true })

    const { selectedOpts, selectedDateRange } = this.state
    const { store_id } = this._getParams()

    let startDate: string = dayjs().format('DD-MM-YYYY')
    let endDate: string = dayjs().format('DD-MM-YYYY')
    let resultReportType = '1'
    let daysInPast = ''

    // ถ้า user เลือก 7 วัน
    if (selectedOpts.TIME === this.OPTIONS.TIME[0]) {
      daysInPast = '7'
      startDate = ''
      endDate = ''
    }

    // ถ้า user เลือก 30 วัน
    if (selectedOpts.TIME === this.OPTIONS.TIME[1]) {
      daysInPast = '30'
      startDate = ''
      endDate = ''
    }

    // ถ้า user เลือก custom time
    if (selectedOpts.TIME === this.OPTIONS.TIME[2]) {
      startDate = selectedDateRange.begin.format('DD-MM-YYYY')
      endDate = selectedDateRange.end.format('DD-MM-YYYY')
    }

    // ถ้า user เลือก แยกรายวัน
    if (selectedOpts.REPORT_TYPE === this.OPTIONS.REPORT_TYPE[1]) {
      resultReportType = '2'
    }

    // const startDateTime = '19-03-2018'
    const url = util.getReportResellerExcelUrl({
      store_id,
      daysInPast,
      resultReportType,
      startDate,
      endDate,
    })
    const startDateTime = dayjs().format('DD-MM-YYYY_H-mm-ss')
    const fileName = `XSelly_ToShip_${startDateTime}.xlsx`

    // fileName = `${fileName}.xlsx`

    log('_downloadReportRequisitionUrl url => ', url)
    log('_downloadReportRequisitionUrl fileName => ', fileName)
    await this._handleOnDownloadFile(url, fileName)
    await setStatePromise(this, { loading: false })
    await setTimeout(() => {
      this.inProcess = false
    }, 500)
  }
}
