import { Map } from 'immutable'

import xActions from 'x/config/actions'
import { ActionApiParams } from '../../index'

export const RESET_STATE = 'SessionState/RESET'
export const INITIALIZE_STATE = 'SessionState/INITIALIZE'

const { SESSION_LOADING, SESSION_READY, SESSION_SET_ACTIVE_ROUTE_NAME } = xActions

// Initial state
const initialSessionState = Map({
  isReady: true,
  activeRouteName: '',
})

export function resetSessionStateFromSnapshot(state) {
  return {
    type: RESET_STATE,
    payload: state,
  }
}

export function initializeSessionState() {
  return {
    type: INITIALIZE_STATE,
  }
}

export function showIndicator() {
  return {
    type: SESSION_LOADING,
  }
}

export function hideIndicator() {
  return {
    type: SESSION_READY,
  }
}

export function setActiveRouteName(currentRouteName: string) {
  return {
    type: SESSION_SET_ACTIVE_ROUTE_NAME,
    payload: currentRouteName,
  }
}

// Reducer
export default function SessionStateReducer(state = initialSessionState, action: ActionApiParams<any> = {}) {
  switch (action.type) {
    case INITIALIZE_STATE:
    case RESET_STATE:
      return state.set('isReady', true)
    case SESSION_LOADING:
      return state.set('isReady', false)
    case SESSION_READY:
      return state.set('isReady', true)
    case SESSION_SET_ACTIVE_ROUTE_NAME:
      return state.set('activeRouteName', action.payload)

    default:
      return state
  }
}
