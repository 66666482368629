import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as AddressActions from 'x/modules/address/AddressState'

import DevMenuSubscriptionView from './DevMenuSubscriptionView'
import { getSelectedStore, getMyProfile, getSubscription } from 'x/redux/selectors'

// import * as AddressActions from '../address/AddressState'

export default connect(
  (state) => {
    return {
      selectedStore: getSelectedStore(state),
      profile: getMyProfile(state),
      subscription: getSubscription(state),
    }
  },
  (dispatch) => {
    return {
      // customer: bindActionCreators(AddressActions.getCustomer, dispatch),
    }
  }
)(DevMenuSubscriptionView)
