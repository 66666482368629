import { connect } from 'react-redux'
import { getStoreSelected, getSubscriptionType } from 'x/redux/selectors'
import ReportPeakView from './ReportPeakView'

export default connect(
  (state) => ({
    selectedStore: getStoreSelected(state),
    subscriptionType: getSubscriptionType(state),
  }),
  (dispatch) => {
    return {
      // navigate: bindActionCreators(NavigationActions.navigate, dispatch),
    }
  }
  // @ts-ignore
)(ReportPeakView)
