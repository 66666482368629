import React, { Component } from 'react'
import { Spinner } from 'native-base'

import { View, VirtualizedList, Dimensions, Platform } from 'react-native'

import { Map, List } from 'immutable'
import { log, logRender, setStatePromise, isDiff } from 'x/utils/util'
import p from 'x/config/platform-specific'
import _ from 'lodash'

import { BaseResellerListTabProps, BaseResellerListTabState, UserInUserGroupsMap, UserInUserGroups } from 'x/index'
// import FastImage from 'react-native-fast-image'
import UserItem from 'xui/modules/userGroup/UserItem'
import XText from 'xui/components/XText'
import { COLORS } from '../../config/styles'

interface ViewableItem {
  key: string
  index: string
  isViewable: boolean
  item?: UserInUserGroups
}

const INTL_STYLES: { [key: string]: any } = {
  flatList: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
  },
  userImage: {
    height: 50,
    width: 50,
    borderRadius: 25,
  },
  userEmptyImageIcon: {
    height: 50,
    width: 50,
    fontSize: 50,
  },
  middleCenterColumn: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  topLeftColumn: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
}

// // const FACEBOOK_IMAGE = require('../../img/about/FB-f-Logo__blue_100.png')
// const MESSENGER_IMAGE = require('../../img/about/Messenger_Icon.png')
// const LINE_IMAGE = require('../../img/about/LINE_Icon.png')

export default abstract class BaseResellerListTab extends Component<BaseResellerListTabProps, BaseResellerListTabState> {
  static displayName = 'BaseResellerListTab'

  SCREEN_WIDTH: number

  SCREEN_HEIGHT: number

  _inProcess: boolean

  _viewabilityConfig: { [key: string]: any }

  _rowHeight: number

  _rowNumInScreen: number

  _offset: number

  _limit: number

  _maxToRenderPerBatch: number

  _windowSize: number

  _listType: 'p' | 'j' | 'b' // p = pending , j = joined, b = blocked

  _isFetchingOffsets: Map<number | string, boolean>

  abstract constructorExtended(that: any): void

  abstract getListType(): 'p' | 'j' | 'b'

  abstract renderUsersRightComponent(arg: { index: number; data: UserInUserGroupsMap }): JSX.Element

  abstract doAfterRefresh?(): void

  protected constructor(props) {
    super(props)
    this.state = {
      refreshing: false,
    }

    // this._onEndReached = this._onEndReached.bind(this)
    this.constructorExtended = this.constructorExtended.bind(this)
    this.getListType = this.getListType.bind(this)
    this.renderUsersRightComponent = this.renderUsersRightComponent.bind(this)

    this._renderItem = this._renderItem.bind(this)
    this._renderEmptyList = this._renderEmptyList.bind(this)
    this._renderFooter = this._renderFooter.bind(this)
    this._getItemCount = this._getItemCount.bind(this)
    this._getItem = this._getItem.bind(this)
    this._getOffsetByItemIndex = this._getOffsetByItemIndex.bind(this)
    this._canPullRefresh = this._canPullRefresh.bind(this)
    // this._fetchUserList = this._fetchUserList.bind(this)
    this._keyExtractor = this._keyExtractor.bind(this)
    this._onRefresh = this._onRefresh.bind(this)
    this._onScrollToIndexFailed = this._onScrollToIndexFailed.bind(this)
    this._onViewableItemsChanged = this._onViewableItemsChanged.bind(this)
    this._onPressEmptyItem = this._onPressEmptyItem.bind(this)
    this._handleOnScrollStopped = this._handleOnScrollStopped.bind(this)

    this.SCREEN_WIDTH = Dimensions.get('window').width
    this.SCREEN_HEIGHT = Dimensions.get('window').height

    this._rowHeight = Platform.OS === 'android' ? 80 : 60
    this._offset = 0
    this._limit = 40
    this._isFetchingOffsets = Map({})
    this._listType = this.getListType()
    this._viewabilityConfig = {
      waitForInteraction: false,
      viewAreaCoveragePercentThreshold: 0, // 0 percent หมายถึง เริ่มเห็น 1 pixel จะเริ่ม render
      // waitForInteraction: true,
      // viewAreaCoveragePercentThreshold: 95,
      // viewAreaPercentThreshold: 20,
      // viewAreaCoveragePercentThreshold: 10,
      // viewAreaPercentThreshold: 80,
    }
    // this._maxToRenderPerBatch = Math.round(this._limit * 1.5)
    // this._windowSize = Math.round(this._maxToRenderPerBatch) + 1
    this._rowNumInScreen = Math.floor((this.SCREEN_HEIGHT - 165) / this._rowHeight) // เผื่อไว้ 165 คือพื้นที่ header
    // this._initialNumToRender = this._rowNumInScreen * 2
    // this._maxToRenderPerBatch = this._limit * 3
    this._maxToRenderPerBatch = Math.round(this._limit * 1.5)
    // this._windowSize = Math.round(this._maxToRenderPerBatch) + 1
    // this._windowSize = Math.round(this._limit * 2) + 1
    this._windowSize = 21

    this.constructorExtended(this)

    this._inProcess = false
  }
  // abstract async _fetchUserList(): Promise<void>

  componentDidMount() {
    // this._fetchUserList({ customOffset: 0, isRefresh: true })
    this._onRefresh(true)
  }

  _onScrollToIndexFailed(info: { index: number; highestMeasuredFrameIndex?: number; averageItemLength?: number }): void {
    const { isAllFetched, count } = this.props
    const { index = 0 } = info
    log('_onScrollToIndexFailed index => ', index)
    const customOffset = this._getOffsetByItemIndex(index)
    if (!_.isNumber(customOffset)) {
      return
    }

    // ถ้ามีข้อมูลไม่ต้อง fetch
    if (this._isFetchingOffsets.has(customOffset)) {
      return
    }

    log('_onScrollToIndexFailed customOffset => ', customOffset)
    log('_onScrollToIndexFailed this._isFetchingOffsets => ', this._isFetchingOffsets.toJS())
    this._fetchUserList({ customOffset })
  }

  _getOffsetByItemIndex(index = 0): number {
    const itemNumber = index + 1
    const improperFraction = itemNumber > this._limit ? itemNumber % this._limit : itemNumber
    return itemNumber - improperFraction
  }

  // async _onEndReached(evt): Promise<void> {
  //   log('_onEndReached evt => ', evt)
  //   await this._fetchUserList()
  // }

  _onViewableItemsChanged(evt: {
    viewableItems: ViewableItem[]
    changed: ViewableItem[]
    viewabilityConfig: any // doesn't care
  }): void {
    log('_onViewableItemsChanged evt => ', evt)
  }

  _handleOnScrollStopped(evt): void {
    const { users, count } = this.props
    const { contentOffset } = evt.nativeEvent
    if (!contentOffset || !List.isList(users)) {
      return
    }

    const { x, y } = contentOffset
    if (y <= 0) {
      return
    }

    const topItemIndex = Math.floor(y / this._rowHeight)
    const isFetchedTopData = !!users.get(topItemIndex)
    // log('_handleOnScrollStopped topItemIndex => ', topItemIndex)
    if (!isFetchedTopData) {
      this._onScrollToIndexFailed({ index: topItemIndex })
    }

    // let bottomItemIndex = topItemIndex + this._limit
    let bottomItemIndex = topItemIndex + this._rowNumInScreen
    if (count > 0 && bottomItemIndex >= count) {
      bottomItemIndex = count - 1
    }

    const isFetchedBottomData = !!users.get(bottomItemIndex)
    if (!isFetchedBottomData) {
      this._onScrollToIndexFailed({ index: bottomItemIndex })
    }
  }

  _fetchUserList = async (
    params: {
      customOffset?: number
      isRefresh?: boolean
      isInit?: boolean
    } = {
      customOffset: 0,
      isRefresh: false,
      isInit: false,
    }
  ): Promise<void> => {
    const { customOffset, isRefresh, isInit } = params

    const { selectedStoreId, selectedUserGroupId, fetchUserList, isAllFetched } = this.props
    // if (this._inProcess) {
    //   return
    // }
    // if (isAllFetched) {
    //   log('_fetchUserList isAllFetched => ', isAllFetched)
    //   return
    // }
    // this._inProcess = true

    if (!selectedStoreId || !selectedUserGroupId) {
      throw 'No selectedUserGroupId or selectedUserGroupId.'
    }
    const body = {
      store_id: selectedStoreId,
      ug_id: selectedUserGroupId,
      offset: _.isNumber(customOffset) ? customOffset : this._offset,
      limit: this._limit,
      type: this._listType,
      isRefresh,
    }

    if (this._isFetchingOffsets.has(body.offset)) {
      return
    }
    this._isFetchingOffsets = this._isFetchingOffsets.set(body.offset, true)
    const isSuccess = await new Promise<boolean>((isFetched) => {
      const successCallback = () => isFetched(true)
      const failCallback = () => isFetched(false)
      fetchUserList({ body, successCallback, failCallback })
      // setTimeout(failCallback, 15000) // timeout 15 sec
    })
    if (isSuccess && isRefresh && !isInit) {
      p.op.showToast('โหลดรายการใหม่เสร็จสิ้น', 'success')
    }

    this._isFetchingOffsets = this._isFetchingOffsets.delete(body.offset)
    // this._inProcess = false
  }

  componentDidUpdate(prevProps: BaseResellerListTabProps, prevState: BaseResellerListTabState) {
    const { users, shouldFetch } = this.props

    if (prevProps.users !== users) {
      this._offset = users.size
    }

    if (!prevProps.shouldFetch && shouldFetch) {
      this._offset = 0
      this._isFetchingOffsets = Map({})
      this._onRefresh(true)
    }
  }

  shouldComponentUpdate(nextProps: BaseResellerListTabProps, nextState: BaseResellerListTabState) {
    if (isDiff(this.props, nextProps, ['users', 'count'])) {
      return true
    }
    return isDiff(this.state, nextState, ['refreshing'])
  }

  _onPressEmptyItem(index?: number): void {
    if (_.isNumber(index)) {
      this._onScrollToIndexFailed({ index })
    }
  }

  // _onPressUserItem(index: number, data: UserInUserGroupsMap): void {
  //   log(`_onPressUserItem index = ${index} data => `, data)
  // }

  _onPressUserItem: null | Function = null

  _renderItem(data: { item: UserInUserGroupsMap; index: number }): JSX.Element {
    const { item = null, index } = data
    // if (!Map.isMap(item)) {
    //   return (
    //     <EmptyItem
    //       index={index}
    //       onPressItem={this._onPressEmptyItem}
    //       rowHeight={this._rowHeight} />
    //   )
    // }
    return (
      <UserItem
        index={index}
        showIndex
        data={item}
        // onPressItem={() => log('index => ', index)}
        onPressItem={_.isFunction(this._onPressUserItem) ? this._onPressUserItem : () => null}
        onPressEmpty={this._onPressEmptyItem}
        renderRightComponent={this.renderUsersRightComponent}
        rowHeight={this._rowHeight}
      />
    )
  }

  _keyExtractor(item, index): string {
    return index.toString()
  }

  async _onRefresh(isInit = false): Promise<void> {
    if (this.state.refreshing || this._inProcess || !this._canPullRefresh()) {
      p.op.showToast('ยังไม่สามารถโหลดข้อมูลใหม่ได้ขณะนี้ เนื่องจากมีข้อมูลถูกดาวน์โหลดอยู่', 'warning')
      return
    }
    this._inProcess = true
    await setStatePromise(this, { refreshing: true })
    this._inProcess = false
    this._offset = 0
    await this._fetchUserList({ customOffset: 0, isRefresh: true, isInit })
    if (_.isFunction(this.doAfterRefresh)) {
      await this.doAfterRefresh() // do any thing after refresh in child
    }
    await setStatePromise(this, { refreshing: false })
  }

  _renderEmptyList(): JSX.Element {
    if (!this.props.isAllFetched) {
      return null
    }

    return (
      <View style={{ marginTop: 50 }}>
        <XText variant='inactive' textAlign='center'>
          รายการว่างเปล่า
        </XText>
      </View>
    )
  }

  _getItemCount(): number {
    const { users, isAllFetched, isFetching, count } = this.props
    if (isAllFetched) {
      return count
    }
    if (count > 0 && users.size < count) {
      const newCount = users.size + this._maxToRenderPerBatch
      if (newCount > count) {
        return count
      }
      return newCount
    }
    return 0
    // return (isAllFetched || count > 0) ? count : 0
  }

  _getItem(data, index: number): any {
    if (!List.isList(data)) {
      return null
    }
    return data.get(index)
  }

  _canPullRefresh() {
    if (this.props.isFetching) {
      return false
    }

    return this._isFetchingOffsets.isEmpty()
  }

  _renderFooter(): JSX.Element {
    const { count, isAllFetched, isFetching } = this.props
    if (count === 0) {
      return null
    }

    if (isAllFetched) {
      return (
        <View style={{ paddingVertical: 8 }}>
          <XText variant='inactive' textAlign='center'>
            โหลดข้อมูลครบถ้วนแล้ว
          </XText>
        </View>
      )
    }

    return (
      <View style={{ alignItems: 'center', justifyContent: 'center' }}>
        <Spinner color={COLORS.APP_MAIN} />
      </View>
    )
  }

  _renderRoot = () => (
    <VirtualizedList
      // extraData={refreshing} // re-render if changed
      style={INTL_STYLES.flatList}
      initialNumToRender={this._limit}
      maxToRenderPerBatch={this._maxToRenderPerBatch}
      // updateCellsBatchingPeriod={1}
      // updateCellsBatchingPeriod={this._limit * 3}
      windowSize={this._windowSize}
      // maxToRenderPerBatch={this._limit * 2}
      initialScrollIndex={0}
      getItemCount={this._getItemCount}
      getItem={this._getItem}
      data={this.props.users}
      keyExtractor={this._keyExtractor}
      refreshing={this.state.refreshing}
      onRefresh={this._onRefresh}
      renderItem={this._renderItem}
      ListEmptyComponent={this._renderEmptyList}
      ListFooterComponent={this._renderFooter}
      // ListFooterComponent={this._renderFooter}
      // onEndReached={this._onEndReached}
      // onEndReachedThreshold={10}
      onScrollToIndexFailed={this._onScrollToIndexFailed}
      onScrollEndDrag={this._handleOnScrollStopped}
      onMomentumScrollEnd={this._handleOnScrollStopped}
      viewabilityConfig={this._viewabilityConfig}
      removeClippedSubviews={false}
      // onViewableItemsChanged={this._onViewableItemsChanged}
    />
  )

  render() {
    logRender(this)
    return this._renderRoot()
  }
}

// Internal Component
// const UserImage = ({ imgUri, thumbUri }) => imgUri || thumbUri ?
//   (
//     <FastImage
//       style={INTL_STYLES.userImage}
//       source={{
//         uri: thumbUri ? thumbUri :imgUri,
//         priority: FastImage.priority.low,
//       }}
//       resizeMode={FastImage.resizeMode.cover}
//     />
//   ) : (
//     <Icon
//       name={'contact'}
//       style={INTL_STYLES.userEmptyImageIcon} />
//   )

// class UserItem extends Component {
//   shouldComponentUpdate(props, nextProps) {
//     return isDiffAccuracy(this.props, nextProps, ['index', 'data'])
//     // log(`UserItem:: shouldComponentUpdate ${props.index} props => `, props)
//     // return false
//     // if ((!props.data && !!nextProps.data) || (!!props.data && !nextProps.data) || (!!props.data && !!nextProps.data && props.data !== nextProps.data)) {
//     //   return true
//     // }
//     // return (!props.index && nextProps.index) || (props.index && !nextProps.index) || (props.index !== nextProps.index)
//   }
//   // _onPressItem = () => {
//   //   const { onPressItem, index, data } = this.props
//   //   if (_.isFunction(onPressItem) && Map.isMap(data)) {
//   //     onPressItem(index, data)
//   //   }
//   // }
//   _onPressEmpty = () => {
//     const { onPressEmpty, index } = this.props
//     if (_.isFunction(onPressEmpty)) {
//       onPressEmpty(index)
//     }
//   }
//   _renderRightComponent = () => {
//     const { index, data, renderRightComponent } = this.props
//     if (!_.isFunction(renderRightComponent) && Map.isMap(data)) {
//       return null
//     }
//     return renderRightComponent(index, data)
//   }
//   _openLinkURL = (link: string) => {
//     Linking.canOpenURL(link).then(supported => {
//       if (supported) {
//         Linking.openURL(link)
//       } else {
//         Clipboard.setString(link)
//         p.op.showToast(`คัดลอก ${link} แล้ว`, 'success')
//       }
//     })
//   }
//   _handleOpenMessenger = () => {
//     const { data } = this.props
//     if (!data || !Map.isMap(data) || !data.has('f')) {
//       return
//     }
//     const facebook_id = data.get('f')
//     this._openLinkURL(`http://m.me/${facebook_id}`)
//   }
//   _handleOpenLINE = () => {
//     const { data } = this.props
//     if (!data || !Map.isMap(data) || !data.has('l')) {
//       return
//     }
//     const line_id = data.get('l')
//     this._openLinkURL(`http://line.me/ti/p/${line_id}`)
//   }
//   _handleOnPressUserItem = () => {
//     const { index, data, onPressItem } = this.props
//     if (_.isFunction(onPressItem)) {
//       onPressItem(index, data)
//     }
//   }
//   render() {
//     const { data , rowHeight, index, selected, onPressItem, renderRightComponent } = this.props
//     const textColor = selected ? 'red' : 'black'
//
//     if (!data || !Map.isMap(data)) {
//       return (
//         <EmptyItem index={index} onPressItem={this._onPressEmpty} />
//       )
//     }
//
//     const id = data.get('i')
//     const display_name = data.get('d')
//     // const image_uri = data.get('iu')
//     const thumbnail_uri = data.get('t')
//     const facebook_id = data.get('f')
//     const line_id = data.get('l')
//     // const register_at = data.get('ra')
//
//     return (
//       <TouchableOpacity
//         disabled={!_.isFunction(onPressItem)}
//         onPress={this._handleOnPressUserItem}
//         style={{
//         height: rowHeight,
//         backgroundColor: index % 2 === 0 ? COLORS.BG_LIGHT_GREY: 'white',
//       }}>
//         <Grid>
//           <Row>
//             <Col size={2} style={INTL_STYLES.middleCenterColumn}>
//               <UserImage
//                 // imgUri={image_uri}
//                 thumbUri={thumbnail_uri} />
//             </Col>
//             <Col size={4} style={INTL_STYLES.topLeftColumn}>
//               <Row>
//                 <Col style={{ alignItems: 'flex-start', justifyContent: 'center' }}>
//                   <Text numberOfLines={1} style={{ color: textColor }}>{display_name}</Text>
//                 </Col>
//               </Row>
//               <Row>
//                 {
//                   facebook_id ? (
//                     <Col style={INTL_STYLES.middleCenterColumn}>
//                       <TouchableOpacity onPress={this._handleOpenMessenger}>
//                         <Image
//                           resizeMode='cover'
//                           style={{ width: STYLES.FONT_ICON_LARGER, height: STYLES.FONT_ICON_LARGER }}
//                           source={MESSENGER_IMAGE}/>
//                       </TouchableOpacity>
//                     </Col>
//                   ) : null
//                 }
//                 {
//                   line_id ? (
//                     <Col style={INTL_STYLES.middleCenterColumn}>
//                       <TouchableOpacity onPress={this._handleOpenLINE}>
//                         <Image
//                           resizeMode='cover'
//                           style={{ width: STYLES.FONT_ICON_LARGER, height: STYLES.FONT_ICON_LARGER }}
//                           source={LINE_IMAGE}/>
//                       </TouchableOpacity>
//                     </Col>
//                   ) : null
//                 }
//                 <Col />
//               </Row>
//               {/*<Row>*/}
//               {/*<Text style={{ color: textColor }}>DEBUG ID: {id}</Text>*/}
//               {/*</Row>*/}
//             </Col>
//             <Col size={4} style={INTL_STYLES.middleCenterColumn}>
//               { this._renderRightComponent() }
//             </Col>
//           </Row>
//         </Grid>
//         <View style={{ position: 'absolute', top: 0, left: 0 }}>
//           <Text style={{
//             fontSize: 8,
//             color: COLORS.TEXT_INACTIVE,
//           }}>{ index + 1 }</Text>
//         </View>
//       </TouchableOpacity>
//     )
//   }
// }

// <TouchableOpacity onPress={this._onPress}>
// </TouchableOpacity>

// class EmptyItem extends PureComponent {
//   _onPress = () => {
//     const { index, onPressItem } = this.props
//     onPressItem(index)
//   }
//   render() {
//     const { rowHeight, index } = this.props
//     return (
//       <TouchableOpacity onPress={this._onPress}>
//         <View style={{
//           height: rowHeight,
//           backgroundColor: index % 2 === 0 ? COLORS.BG_LIGHT_GREY: 'white',
//         }}>
//           <Spinner color={COLORS.TEXT_INACTIVE}/>
//         </View>
//         <View style={{ position: 'absolute', top: 0, left: 0 }}>
//           <Text style={{
//             fontSize: 8,
//             color: COLORS.TEXT_INACTIVE,
//           }}>{ index + 1 }</Text>
//         </View>
//       </TouchableOpacity>
//     )
//   }
// }
