import React from 'react'
import p from 'x/config/platform-specific'

import { connect } from 'react-redux'
import { getSelectedStore, getEditingStore } from 'x/redux/selectors'

import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'

import { fetchVerifySlipCreditHistory } from 'x/utils/api'
import * as util from 'x/utils/util'
import { logRender } from 'x/utils/util'
import * as xFmt from 'x/utils/formatter'
import CONS from 'x/config/constants'
import * as NavActions from 'x/utils/navigation'

import XText from 'xui/components/XText'
import XCustomHeader from 'xui/components/XCustomHeader'
import { ISelectedStoreMap, IXScreenProps, IVerifySlipCreditHistory, IVerifySlipCreditHistoryRequestBody } from 'x/types'
import XSpinner from 'xui/components/XSpinner'
import time from 'x/utils/time'
import _ from 'lodash'
import VerifySlipIcon from 'xui/components/VerifySlipIcon'
import { FlatList, ListRenderItemInfo, RefreshControl, TouchableOpacity } from 'react-native'
import { COLORS } from 'x/config/styles'
import XIcon from 'xui/components/XIcon'
import XContainer from '../../components/XContainer'

// Credit History Action
const H_ACTION = CONS.VERIFY_SLIP_CREDIT_HISTORY_ACTION

interface IProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
}

interface IState {
  isInitialized?: boolean
  isRefreshing?: boolean
  isFetching?: boolean
  isReachEnd?: boolean
  isViewForASpecificVerifySlipCreditId?: boolean

  remainingHistoryItems: IVerifySlipCreditHistory[]
}

class VerifySlipCreditHistoryView extends React.Component<IProps, IState> {
  static displayName = 'VerifySlipCreditHistoryView'

  isFetching?: boolean

  constructor(props) {
    super(props)

    this.state = {
      isInitialized: false,
      isRefreshing: false,
      isFetching: false,
      isReachEnd: false,
      isViewForASpecificVerifySlipCreditId: false,

      remainingHistoryItems: [],
    }
  }

  async componentDidMount() {
    this._onRefresh()
    await util.setStatePromise(this, {
      isInitialized: true,
      isViewForASpecificVerifySlipCreditId: !!util.getNavParam(this.props, 'verify_slip_credit_id'),
    })
  }

  doFetch = async (offset = 0, limit = 20) => {
    const { navigation, selectedStore } = this.props
    const { remainingHistoryItems } = this.state
    const store_id = selectedStore.get('id')
    const verify_slip_credit_id = util.getNavParam(this.props, 'verify_slip_credit_id')

    const reqBody: IVerifySlipCreditHistoryRequestBody = { store_id, offset, limit }

    if (verify_slip_credit_id) {
      reqBody.verify_slip_credit_id = verify_slip_credit_id
    }

    try {
      const rhis = await fetchVerifySlipCreditHistory(reqBody)
      // console.log('doFetch offset => ', offset)
      // console.log('doFetch limit => ', limit)
      // console.log('doFetch rhis => ', rhis)

      const newState: Partial<IState> = {}

      if (_.isArray(rhis) && offset === 0) {
        newState.remainingHistoryItems = rhis
      }

      if (_.isArray(rhis) && offset > 0) {
        const newRemainingHistoryItems = remainingHistoryItems.concat(rhis)
        newState.remainingHistoryItems = newRemainingHistoryItems
      }

      if (_.isArray(rhis) && (rhis.length === 0 || rhis.length < limit)) {
        newState.isReachEnd = true
      }

      if (!_.isEmpty(newState)) {
        await util.setStatePromise(this, newState)
        await util.delay(50)
      }
    } catch (err) {
      console.log('doFetch err', err)
    }
  }

  _onRefresh = async () => {
    await this.loadInit()
  }

  loadInit = async () => {
    if (this.isFetching) {
      return
    }
    this.isFetching = true
    await util.setStatePromise(this, { isRefreshing: true, isReachEnd: false })
    await this.doFetch(0)
    await util.setStatePromise(this, { isRefreshing: false })
    this.isFetching = false
  }

  loadMore = async () => {
    if (this.isFetching) {
      return
    }

    await util.setStatePromise(this, { isFetching: true })

    const { remainingHistoryItems, isReachEnd } = this.state

    if (isReachEnd) {
      await util.setStatePromise(this, { isFetching: false })
      this.isFetching = false
      return
    }

    const offset = remainingHistoryItems.length
    await this.doFetch(offset)

    await util.setStatePromise(this, { isFetching: false })
    this.isFetching = false
  }

  _goBack = () => {
    util.navGoBack(this.props)
  }

  renderCustomHeader = () => {
    // return null
    const { navigation } = this.props

    return (
      <XCustomHeader
        // headerStyle={fixedAndroidMarginTopStyle}
        headerLeftProps={{ backIcon: true, onPressItem: this._goBack }}
        title='ประวัติเครดิต'
      />
    )
  }

  renderCreditHistoryFlatListItem = (itemInfo: ListRenderItemInfo<IVerifySlipCreditHistory>) => {
    const { item, index } = itemInfo
    return this.renderCreditHistoryListItem(item, index)
  }

  renderCreditHistoryListItem = (item: IVerifySlipCreditHistory, index: number) => {
    // const { remaining_credit, original_credit, created_at, expiration_date, updated_at } = item
    const isOdd = index % 2 !== 0
    const { isViewForASpecificVerifySlipCreditId } = this.state
    const { id, action_id, created_at, verify_slip_id, amount, post_remaining_credit, post_remaining_total_credit } = item

    return (
      <VStack
        key={`credit_history_${index}_${id}`}
        w='full'
        // h='250px'
        p='2'
        space='1'
        borderBottomWidth='1'
        borderBottomColor='muted.400'
        bg={isOdd ? 'white' : 'muted.100'}>
        {_.includes([H_ACTION.CAMPAIGN_DEPOSIT, H_ACTION.SYSTEM_DEPOSIT, H_ACTION.USER_DEPOSIT], action_id) ? (
          <HStack w='full' alignItems='center' justifyContent='flex-end'>
            <XText flex={1} fontSize='lg' bold color='#60BA60'>
              {`เติม ${xFmt.formatDecimal(amount)} เครดิต`}
            </XText>

            {action_id === H_ACTION.SYSTEM_DEPOSIT ? (
              <HStack h='34px' alignItems='center' space='1.5'>
                <XText variant='inactive' textAlign='right'>
                  โดยระบบ
                </XText>
              </HStack>
            ) : null}

            {action_id === H_ACTION.CAMPAIGN_DEPOSIT ? (
              <HStack h='34px' alignItems='center' space='1.5'>
                <XText variant='inactive' textAlign='right'>
                  โดยโปรโมชั่น
                </XText>
              </HStack>
            ) : null}
          </HStack>
        ) : null}
        {_.includes([H_ACTION.SYSTEM_WITHDRAW, H_ACTION.USER_WITHDRAW], action_id) ? (
          <HStack w='full' alignItems='center' justifyContent='flex-end'>
            <XText flex={1} fontSize='lg' bold color={COLORS.BRAND_Danger}>
              {`ถอน ${xFmt.formatDecimal(amount)} เครดิต`}
            </XText>

            {action_id === H_ACTION.SYSTEM_WITHDRAW ? (
              <HStack h='34px' alignItems='center' space='1.5'>
                <XText variant='inactive' textAlign='right'>
                  โดยระบบ
                </XText>
              </HStack>
            ) : null}
          </HStack>
        ) : null}

        {
          // @ts-ignore
          CONS.VERIFY_SLIP_USAGE_ACTION_IDS.includes(action_id) ? (
            <VStack w='full' minH={15} paddingBottom={0}>
              <HStack w='full' alignItems='center' justifyContent='flex-start' space='1.5'>
                <XText variant='inactive' bold flex={1}>
                  {p.op.t(`VerifySlip.UsageActionId._${action_id}`)}
                </XText>
              </HStack>

              <HStack w='full' alignItems='center' justifyContent='flex-end' space='1.5'>
                {verify_slip_id ? (
                  <TouchableOpacity onPress={() => this._navToPaymentListView(verify_slip_id)}>
                    <HStack h='34px' alignItems='center' space='1.5'>
                      <VerifySlipIcon success />
                      <XText color='#60BA60'>ดูการชำระ</XText>
                    </HStack>
                  </TouchableOpacity>
                ) : null}

                {verify_slip_id ? (
                  <TouchableOpacity onPress={() => this._navToVerifySlipDetailView(verify_slip_id)}>
                    <HStack h='34px' alignItems='center' space='1.5'>
                      <XIcon name='text' family='MaterialCommunityIcons' />
                      <XText variant='primary'>ดูข้อมูลสลิป</XText>
                    </HStack>
                  </TouchableOpacity>
                ) : null}
              </HStack>
            </VStack>
          ) : null
        }
        {isViewForASpecificVerifySlipCreditId ? (
          <HStack w='full' alignItems='center'>
            <XText variant='inactive' flex={1}>
              ยอดเครดิตคงเหลือ
            </XText>
            <XText w='150px' textAlign='right'>
              {xFmt.formatDecimal(post_remaining_credit)}
            </XText>
          </HStack>
        ) : null}
        {isViewForASpecificVerifySlipCreditId ? null : (
          <HStack w='full' alignItems='center'>
            <XText variant='inactive' flex={1}>
              ยอดเครดิตคงเหลือทั้งหมด
            </XText>
            <XText w='150px' textAlign='right'>
              {xFmt.formatDecimal(post_remaining_total_credit)}
            </XText>
          </HStack>
        )}
        <HStack w='full' alignItems='center'>
          <XText variant='inactive' flex={1}>
            เมื่อ
          </XText>
          <XText w='150px' textAlign='right'>
            {time.convertServerDateTimeToReadableDateTimeText(created_at)}
          </XText>
        </HStack>
        {/* <HStack w='full' alignItems='center'>
            <XText variant='inactive' flex={1}>
              หมดอายุภายใน
            </XText>
            <XText w='150px' textAlign='right'>
              {time.convertServerDateTimeToReadableDateTimeText(expiration_date)}
            </XText>
          </HStack> */}
        {/* {remaining_credit !== original_credit ? (
            <HStack w='full' alignItems='center' justifyContent='flex-end'>
              <XButton
                w='160px'
                h='34px'
                bg='gray.100'
                variant='ghost'
                leftIcon={<XIcon name='text' family='MaterialCommunityIcons' />}
                onPress={() => p.op.alert('เร็วๆ นี้')}>
                ดูประวัติการใช้งาน
              </XButton>
            </HStack>
          ) : null} */}
      </VStack>
    )
  }

  _navToVerifySlipDetailView = (verify_slip_id: number) => {
    const { selectedStore, navigation } = this.props
    const store_id = selectedStore.get('id')
    navigation.dispatch(NavActions.navToVerifySlipDetailView({ store_id, verify_slip_id }))
  }

  _navToPaymentListView = (verify_slip_id: number) => {
    const { selectedStore, navigation } = this.props
    const store_id = selectedStore.get('id')
    navigation.dispatch(
      NavActions.navToPaymentListView({ store_id, verify_slip_id, onSuccessAcceptOrRejectPayment: this._onSuccessAcceptOrRejectPayment })
    )
  }

  _onSuccessAcceptOrRejectPayment = async () => {
    await this._onRefresh()
  }

  // renderCreditHistoryListItems = () => {
  //   const { remainingHistoryItems, isRefreshing } = this.state

  //   if (isRefreshing) {
  //     return <XSpinner />
  //   }

  //   return remainingHistoryItems.map(this.renderCreditHistoryListItem)
  // }

  _keyExtractor = (item, index) => `credit_history_${index}_${item.id}`

  _onEndReached = (info: { distanceFromEnd: number }) => {
    // console.log('onEndReached ddd', info)

    const { isInitialized, isRefreshing, remainingHistoryItems } = this.state
    if (!isInitialized || isRefreshing || remainingHistoryItems.length === 0) {
      return
    }

    if (!info) {
      this.loadMore()
      return
    }

    if (info.distanceFromEnd <= 0) {
      this.loadMore()
    }
  }

  renderListFooter = () => {
    const { isRefreshing, isFetching, isReachEnd } = this.state

    if (isReachEnd) {
      return (
        <HStack w='full' p='2' alignItems='center' justifyContent='center'>
          <XText>สิ้นสุดรายการ</XText>
        </HStack>
      )
    }

    if (isRefreshing || isFetching) {
      return (
        <HStack w='full' p='2' alignItems='center' justifyContent='center'>
          <XSpinner />
        </HStack>
      )
    }

    return null
  }

  // onLayout = (evt: LayoutChangeEvent) => {
  //   console.log('onLayout evt.nativeEvent.layout', evt.nativeEvent.layout)
  // }

  renderMain = () => {
    const { isInitialized, isRefreshing, remainingHistoryItems } = this.state
    if (!isInitialized) {
      return null
    }

    return (
      <XContainer>
        {this.renderCustomHeader()}
        {/* <XContent> */}
        <VStack flex={1} bg='white'>
          {/* <VStack w='full' p='2' space='2'> */}
          {/* {this.renderCreditHistoryListItems()} */}
          {/* </VStack> */}
          <FlatList<IVerifySlipCreditHistory>
            keyExtractor={this._keyExtractor}
            refreshControl={<RefreshControl onRefresh={this._onRefresh} refreshing={isRefreshing} />}
            scrollEnabled
            // style={{ flex: 1 }}
            data={remainingHistoryItems}
            renderItem={this.renderCreditHistoryFlatListItem}
            ListFooterComponent={this.renderListFooter()}
            // onEndReachedThreshold={0}
            onEndReached={this._onEndReached}
          />
        </VStack>
        {/* </XContent> */}
      </XContainer>
    )
  }

  render() {
    logRender(this)
    return this.renderMain()
  }
}

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
    editingStore: getEditingStore(state),
    // subscription: getSubscription(state),
  }),
  (dispatch) => ({
    dispatch,
    // submitEditing: bindActionCreators(StoreActions.submitEditingStore, dispatch),
    // resetEditingStore: bindActionCreators(StoreActions.resetEditingStore, dispatch),
    // storeActions: bindActionCreators(StoreState, dispatch),
  })
)(VerifySlipCreditHistoryView)
