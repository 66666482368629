import { connect } from 'react-redux'
import ProductListViewMkpMapping from './ProductListViewMkpMapping'
import { getState, getDispatch } from 'x/modules/productlist/ProductListViewConnect'
import { getMkpChannels, getSelectedProduct } from 'x/redux/selectors'

export default connect(
  (state) => {
    return {
      ...getState(state),
      selectedProduct: getSelectedProduct(state),
      mkpChannels: getMkpChannels(state),
    }
  },
  (dispatch) => getDispatch(dispatch)
)(ProductListViewMkpMapping)
