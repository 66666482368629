import React, { forwardRef } from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'

import { getComputedCustomStyledProps } from 'x/config/nativebase/makeStyledComponent'
import theme from 'x/config/nativebase/theme'
import { IXButtonProps } from 'x/index'

import XText from './XText'
import VStack from './VStack'
import HStack from './HStack'
// import theme from 'x/config/nativebase/theme'
// import { mergeWith } from 'lodash'
// import styled from 'styled-components/native'
// import { system } from 'styled-system'
// import { propConfig } from 'x/config/nativebase/styled-system'

// const nbBox = system(propConfig)
// const StyledTouchableOpacity = styled.TouchableOpacity(nbBox)

// import { VariantType } from 'native-base/lib/typescript/components/types'

// export type IXButtonType = typeof Button
export type IXButtonType = typeof TouchableOpacity

// const XButton = React.forwardRef<IXButtonType, IXButtonProps>((props, fwdRef) => (
//   <Button
//     // _hover={{
//     //   bg: 'primary.600:alpha.20',
//     // }}
//     {...props}
//     // @ts-ignore
//     ref={fwdRef}
//   />
// ))

// class ButtonBase extends React.PureComponent {
//   render() {
//     const { children, ...restProps } = this.props
//     return <TouchableOpacity {...restProps} />
//   }
// }

// const StyledTouchableOpacity = makeStyledComponent(TouchableOpacity, 'Button')
// const StyledTouchableOpacity = makeStyledComponent(TouchableOpacity)

// const getInternalThemeTextProps = (variant = 'solid', props) => theme.components.Button.variants[variant](props)

const XButton = forwardRef((props, fwdRef) => {
  const { styleFromProps, underscoreProps, restProps, style = {} } = getComputedCustomStyledProps(props, 'Button')
  const { _text = {}, _icon = {}, _stack = {} } = underscoreProps

  // console.log('XButton props => ', props)
  // @ts-ignore
  // const { children, variant = 'solid', ...restProps } = props
  const { children, startIcon: si, endIcon: ei, leftIcon, rightIcon, ...restRNProps } = restProps
  // console.log('XButton restProps => ', restProps)

  let startIcon = si || leftIcon
  let endIcon = ei || rightIcon

  if (endIcon && React.isValidElement(endIcon)) {
    endIcon = React.Children.map(endIcon, (child: JSX.Element, index: number) =>
      React.cloneElement(child, {
        key: `button-end-icon-${index}`,
        ..._icon,
        ...child.props,
        // ...defaultThemeIconProps,
      })
    )
  }

  if (startIcon && React.isValidElement(startIcon)) {
    startIcon = React.Children.map(startIcon, (child: JSX.Element, index: number) =>
      React.cloneElement(child, {
        key: `button-start-icon-${index}`,
        ..._icon,
        ...child.props,
        // ...defaultThemeIconProps,
      })
    )
  }

  // @ts-ignore
  // const internalThemeTextProps = mergeWith(getInternalThemeTextProps(variant, props), props._text)
  // const childElementToStyledElement = useCallback(
  //   (leafChild, lcIdx) => {
  //     if (typeof leafChild === 'string' || typeof leafChild === 'number') {
  //       return childStringToTextCompoent(leafChild, lcIdx)
  //     }
  //     return React.cloneElement(leafChild, {
  //       key: `ChildElementGen_${lcIdx.toString()}`,
  //       _text,
  //       _icon,
  //       _stack,
  //       ...leafChild.props,
  //     })
  //   },
  //   [_text, _icon, _stack]
  // )

  const childStringToTextCompoent = React.useCallback(
    (child: React.ReactElement, cIdx) => {
      const key = `XButtonChildGen_Text_${cIdx.toString()}`

      if (typeof child === 'string' || typeof child === 'number') {
        return (
          <XText
            key={key}
            // {...internalThemeTextProps}
            {..._text}>
            {child}
          </XText>
        )
      }

      // @ts-ignore
      if (child && child.type && child.type.displayName === 'XText') {
        // console.log(`childStringToTextCompoent child-${cIdx} -> `, child)
        return React.cloneElement(child, {
          key: `XButtonChild_XText_${cIdx.toString()}`,
          ..._text,
          ...child.props,
        })
      }

      // @ts-ignore
      if (child && child.type && (child.type.displayName === 'Icon' || child.type.displayName === 'XIcon')) {
        // console.log(`childStringToTextCompoent child-${cIdx} -> `, child)
        return React.cloneElement(child, {
          key: `XButtonChild_XIcon_${cIdx.toString()}`,
          ..._icon,
          ...child.props,
        })
      }

      if (
        child &&
        child.type &&
        // @ts-ignore
        (child.type.displayName === 'XBox' || child.type.displayName === 'XHStack' || child.type.displayName === 'XVStack')
      ) {
        // console.log(`childStringToTextCompoent child-${cIdx} -> `, child)
        return React.cloneElement(child, {
          key: `XButtonChild_XStack_${cIdx.toString()}`,
          ..._stack,
          ...child.props,
        })
      }

      // if (_.isArray(child)) {
      return React.Children.map(child, (leafChild, lcIdx) =>
        React.cloneElement(leafChild, {
          key: `${key}_${lcIdx.toString()}`,
          _text,
          _icon,
          _stack,
          ...leafChild.props,
          // children: React.Children.map(leafChild.props.children, (cd, cdIdx) => childStringToTextCompoent(cd, cdIdx + 10)),
        })
      )
      // }
      // return React.Children.map(child, childElementToStyledElement)
      // return childStringToTextCompoent(child, `LeaveChildGen_${Math.floor(Math.random() * 10000)}`)
      // return React.Children.map(child, (leafChild, lcIdx) =>
      //   React.cloneElement(leafChild, {
      //     key: `${key}_${lcIdx.toString()}`,
      //     _text,
      //     _icon,
      //     _stack,
      //     ...leafChild.props,
      //   })
      // )
      // }
    },
    // @ts-ignore
    // [props._text, variant]
    // [children, _text, _icon, _stack]
    [children, props.variant, props._text, props._icon, props._stack]
  )

  return (
    // @ts-ignore
    <TouchableOpacity ref={fwdRef} {...restRNProps} style={[styleFromProps, StyleSheet.flatten(style)]}>
      <HStack
        _web={{ w: 'full' }}
        flexDirection='row'
        space='1'
        justifyContent='center'
        alignContent='center'
        alignItems='center'
        flexWrap='wrap'
        {..._stack}>
        {startIcon}
        {(startIcon || endIcon) && typeof children === 'string' ? (
          <VStack flex={1} flexWrap='wrap' alignItems='center' alignContent='center' justifyContent='center'>
            {
              // @ts-ignore
              React.Children.map(children, childStringToTextCompoent)
            }
          </VStack>
        ) : (
          React.Children.map(children, childStringToTextCompoent)
        )}
        {endIcon}
        {/* {React.Children.map(children, (cc, ii) => {})} */}
      </HStack>
    </TouchableOpacity>
  )
})

// XButton.displayName = 'XButton'
// XButton.defaultProps = getComponentDefaultProps('Button')

// const XButton = makeStyledComponent(XButtonBase, 'Button')
XButton.displayName = 'XButton'
// @ts-ignore
XButton.defaultProps = { theme }

// console.log('XButton.defaultProps => ', XButton.defaultProps)

export default XButton as React.FC<IXButtonProps>
