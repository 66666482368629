import React from 'react'
import { TouchableOpacity } from 'react-native'
import HStack from 'xui/components/HStack'
import Box from 'xui/components/Box'
import XIcon from './XIcon'

const DEFAULT_COLORS = [
  '#b79ec7',
  '#7b539d',
  '#593482',

  '#57bfc1',
  '#219aa0',
  '#0e5c68',

  '#34b3e4',
  '#008cc9',
  '#0b4971',

  '#92c749',
  '#4c792d',
  '#3f652d',

  '#f0c23b',
  '#d3a02e',
  '#856a1d',

  '#ec9f48',
  '#d1711b',
  '#933304',

  '#f289b7',
  '#dc4b89',
  '#951343',

  '#f38a78',
  '#dd5143',
  '#98041b',
]

export type XColorPaletteProps = {
  onChange: (color: string) => void
  value: string
  colors?: string[]
}

// const XColorPalette: React.FC<XColorPaletteProps> = ({ onChange, colors = DEFAULT_COLORS, value }) => (
//   <HStack alignItems='center' justifyContent='center' flexWrap='wrap' pb='2' space='2'>
//     {colors.map((color: string, index: number) => (
//       <TouchableOpacity
//         key={`color_${index}_${value}`}
//         // style={{ width: 44, height: 44, marginLeft: 12, marginTop: 12 }}
//         onPress={() => onChange(color)}>
//         <Box bgColor={color} mt='2' borderRadius='80px' w='11' h='11' alignItems='center' justifyContent='center'>
//           {value === color ? <XIcon name='check-circle-o' family='FontAwesome' variant='activeDark' size='md' /> : null}
//         </Box>
//       </TouchableOpacity>
//     ))}
//   </HStack>
// )

class XColorPalette extends React.Component<XColorPaletteProps> {
  _onChange = (color: string) => {
    const { onChange } = this.props
    if (onChange) {
      onChange(color)
    }
  }

  renderColorItem = (color: string, index: number) => {
    const { value } = this.props
    const isSelectedValue = value === color

    return (
      <TouchableOpacity
        key={`color_${index}_${color}`}
        // style={{ width: 44, height: 44, marginLeft: 12, marginTop: 12 }}
        onPress={() => this._onChange(color)}>
        <Box bgColor={color} mt='2' borderRadius='80px' w='11' h='11' alignItems='center' justifyContent='center'>
          {isSelectedValue ? <XIcon name='check-circle-o' family='FontAwesome' variant='activeDark' size='md' /> : null}
        </Box>
      </TouchableOpacity>
    )
  }

  renderColorItems = () => {
    const { colors = DEFAULT_COLORS } = this.props
    return colors.map(this.renderColorItem)
  }

  render() {
    return (
      <HStack w='full' alignItems='center' justifyContent='center' flexWrap='wrap' pb='2' space='2'>
        {this.renderColorItems()}
      </HStack>
    )
  }
}

export default XColorPalette
