import React from 'react'
import _ from 'lodash'
import { Map, List, isImmutable } from 'immutable'
import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import FastImage from 'react-native-fast-image'
import * as util from 'x/utils/util'
import * as xFmt from 'x/utils/formatter'
import p from 'x/config/platform-specific'

import XText from '../XText'
import XInput from '../XInput'
import XNumericInput from '../XNumericInput'
import XCard from '../XCard'
import XIconButton from '../XIconButton'
import XOverlay from '../XOverlay'
import XCustomHeader from '../XCustomHeader'
import XButton from '../XButton'
import Box from '../Box'

const emptyImageSrc = require('../../images/empty_image.png')

interface IOrderProductItemProps {
  store_id: number
  index: number
  product: Map<string, any>
  focusedOrderType: number
  order: Map<string, any>

  isCreateMode?: boolean
  isEditable?: boolean
  isRemovable?: boolean
  canShowProfit?: boolean
  showProfit?: boolean
  isCanceledOrder?: boolean
  isLastRow?: boolean

  onChangeStoreProductByKey: (params: { pp_id: number; key: string; value: any }) => void
  onDeleteProduct: (params: { store_id: number; pp_id: number }) => void
}

const OrderProductItem: React.FC<IOrderProductItemProps> = (props) => {
  const {
    store_id,
    index,
    product,
    isCreateMode = false,
    isEditable = false,
    isRemovable = false,
    canShowProfit = false,
    showProfit = false,
    isCanceledOrder = false,
    focusedOrderType,
    isLastRow = false,
    order = Map({}),

    onChangeStoreProductByKey,
    onDeleteProduct,
  } = props

  // ---------------------------------------------------------------------------------------------------------------------------

  // log('renderProduct idx, index => ', JSON.stringify(idx), JSON.stringify(index))
  if (_.isNull(product) || _.isUndefined(product) || !isImmutable(product)) {
    return null
  }

  // const {
  //   onChangeStoreProductByKey,
  //   editingOrder,
  //   // onChangeOrderProductVariantByKey,
  //   // onChangeSuborderProductVariantByKey,
  // } = this.props

  // log('_renderProduct product.toJS()', product.toJS())

  const name = product.get('name') ? product.get('name') : ''
  const variant = product.get('variant') ? product.get('variant') : ''
  const thumbnail_uri = product.getIn(['thumbnail_uris', 0]) ? product.getIn(['thumbnail_uris', 0]) : undefined

  let price = null
  if (focusedOrderType === 3) {
    // ถ้า focusedOrder = 3 ราตาต้องเป็นราตา ฉัน ซื้อ ผู้ขายส่ง
    price = product.get('price_buy') ? product.get('price_buy') || '0' : 0
  } else {
    price = product.get('price') ? product.get('price') || '0' : 0
  }
  // log('product',product.toJS())
  // log('price!',price)

  const qty = product.get('qty') ? parseInt(product.get('qty') || 0) : 0 // TextInput
  const shipped_qty = product.get('shipped_qty') ? parseFloat(product.get('shipped_qty') || 0) : 0
  const priceToNumber = _.isString(price) ? +price : price
  const subtotal = priceToNumber * qty
  const seller_unit_discount = product.get('seller_unit_discount') || undefined
  const price_after_discount = product.get('price_after_discount') || undefined

  const pp_id = product.get('pp_id')
  // const parent_store_id = product.get('parent_store_id')
  // const isIOS = p.op.isIOS()
  // const textSize = FONT_SIZE_SMALLER
  // const textContainerHeight = 36

  const isFreezeProduct = product.get('isFreeze') ? product.get('isFreeze') : false

  // const cost = product.get('cost') ? parseFloat(product.get('cost')) : 0
  // const profit = product.get('profit')
  // // const profit = price - cost
  // let txtProfit = null
  // let bgProfit = COLORS.TEXT_INACTIVE
  // if (profit > 0) {
  //   txtProfit = `กำไร\n${xFmt.formatCurrency(profit)}`
  //   bgProfit = COLORS.BRAND_Success
  // } else if (profit < 0) {
  //   txtProfit = `ขาดทุน\n${xFmt.formatCurrency(profit)}`
  //   bgProfit = COLORS.BRAND_Danger
  // } else {
  //   // === 0
  //   txtProfit = `กำไร\n${xFmt.formatCurrency(0)}`
  //   bgProfit = COLORS.BRAND_Success
  // }

  const vdDiscounts = order.get('vd_discounts') || List([])
  // @ts-ignore
  const hasVdDiscount = vdDiscounts.size > 0

  // const onChangeTextFunction = null
  // if (isCreateMode) {
  //   onChangeTextFunction = onChangeStoreProductByKey
  // } else {
  //   onChangeTextFunction = isChild ? onChangeSuborderProductVariantByKey : onChangeOrderProductVariantByKey
  // }
  // onChangeTextFunction = onChangeStoreProductByKey

  // TODO: Read me again and implement me while using VDs
  // const my_vds = List.isList(product.get('my_vds')) ? product.get('my_vds') : null
  // const myVdList = List.isList(editingOrder.get('myVdList')) ? editingOrder.get('myVdList') : null
  //
  // if (my_vds && myVdList && my_vds.size >= 2 && myVdList.size > 0) {
  //   const foundCustomerDiscountIndex = myVdList.findIndex(vd => Map.isMap(vd) && vd.get('pp_id') === pp_id)
  //   const foundCustomerDiscount = foundCustomerDiscountIndex > - 1
  //     ? myVdList.get(foundCustomerDiscountIndex) : null
  //   if (Map.isMap(foundCustomerDiscount) && foundCustomerDiscount.get('discountAmount')) {
  //     const discountAmount = foundCustomerDiscount.get('discountAmount')
  //     const discount = foundCustomerDiscount.get('discount')
  //     const vdsType = foundCustomerDiscount.get('vds_type')
  //     const priceIncDiscount = foundCustomerDiscount.get('priceIncDiscount')
  //
  //     let txtDiscount
  //     if (vdsType === 1) {
  //       txtDiscount = `${discount}%`
  //     } else if (vdsType === 2) {
  //       txtDiscount = `฿${discount}`
  //     } else {
  //       txtDiscount = null // should be impossible
  //     }
  //
  //     return (
  //       <Fragment>
  //         {
  //           this._renderProductCartItem({
  //             idx, index, isRemovable, isFreezeProduct, pp_id, name, variant,
  //             thumbnail_uri, isCreateMode, canShowProfit, textSize, bgProfit,
  //             txtProfit, textContainerHeight, price, onChangeTextFunction,
  //             isEditable, isCanceledOrder, orderType, qty, subtotal,
  //           })
  //         }
  //         {
  //           this._renderProductCartItem({
  //             idx,
  //             index: index + discountAmount + Math.round(Math.random() * 10000),
  //             // name: `ส่วนลดขายส่ง ${txtDiscount} ต่อชิ้น สำหรับ "${name}"`,
  //             name: '',
  //             // variant: `ส่วนลดขายส่ง ${txtDiscount} ต่อชิ้น สำหรับ "${name}"`,
  //             variant: `ส่วนลดขายส่ง ${txtDiscount} ต่อชิ้น`,
  //             price: priceIncDiscount * -1,
  //             qty,
  //             subtotal: discountAmount * -1,
  //             thumbnail_uri: null,
  //             isRemovable,
  //             isCreateMode: false,
  //             isEditable: false,
  //             canShowProfit: false,
  //             isFreezeProduct: true,
  //             pp_id,
  //             textSize, bgProfit, txtProfit,
  //             textContainerHeight, onChangeTextFunction,
  //             isCanceledOrder, orderType,
  //             isSubRow: true,
  //           })
  //         }
  //       </Fragment>
  //     )
  //   }
  // }

  // TODO: MOCK!!! Remove me
  // const ugpgPurchaseDiscountAmount = product.get('ugpgPurchaseDiscountAmount') ? parseInt(product.get('ugpgPurchaseDiscountAmount') || 0) : 0
  // const ugpgPurchaseDiscountPercent = product.get('ugpgPurchaseDiscountPercent') ? parseInt(product.get('ugpgPurchaseDiscountPercent') || 0) : 0
  // const ugpgSaleDiscountAmount = product.get('ugpgSaleDiscountAmount') ? parseInt(product.get('ugpgSaleDiscountAmount') || 0) : 0
  // const ugpgSaleDiscountPercent = product.get('ugpgSaleDiscountPercent') ? parseInt(product.get('ugpgSaleDiscountPercent') || 0) : 0

  // let txtPurchaseDiscount = null
  // let txtSaleDiscount = null
  // let txtPurchaseDiscountForOrderType3 = null
  // const purchaseDiscount = product.get('purchaseDiscount')
  // const purchaseDiscountPerEach = product.get('purchaseDiscountPerEach')
  // const saleDiscount = product.get('saleDiscount')
  // const saleDiscountPerEach = product.get('saleDiscountPerEach')
  // if ((isCreateMode || _.includes([1, 2], focusedOrderType)) && saleDiscountPerEach && saleDiscountPerEach > 0 && saleDiscount) {
  //   txtSaleDiscount = 'ส่วนลดสมาชิกให้ลูกค้า'
  // }
  // if ((isCreateMode || (_.includes([1, 2], focusedOrderType) && canShowProfit && showProfit)) && purchaseDiscountPerEach) {
  //   txtPurchaseDiscount = `กำไรเพิ่ม ฿${purchaseDiscountPerEach} ต่อชิ้น จากส่วนลดต้นทุนที่ผู้ขายส่งให้ฉัน`
  // }
  // if ((isCreateMode || _.includes([3], focusedOrderType)) && purchaseDiscountPerEach) {
  //   txtPurchaseDiscountForOrderType3 = `ส่วนลดต้นทุนที่ผู้ขายส่งให้ฉัน`
  // }

  // log(this, '_renderProduct isCreateMode => ', isCreateMode)
  // log(this, '_renderProduct txtPurchaseDiscount => ', txtPurchaseDiscount)
  // log(this, '_renderProduct canShowProfit => ', canShowProfit)
  // log(this, '_renderProduct this.state.showProfit => ', this.state.showProfit)

  // ---------------------------------------------------------------------------------------------------------------------------

  // const {
  //   idx,
  //   index,
  //   isRemovable,
  //   isFreezeProduct,
  //   pp_id,
  //   name,
  //   variant,
  //   thumbnail_uri,
  //   isCreateMode,
  //   canShowProfit,
  //   textSize,
  //   bgProfit,
  //   txtProfit,
  //   textContainerHeight,
  //   price,
  //   onChangeTextFunction,
  //   isEditable,
  //   isCanceledOrder,
  //   orderType,
  //   qty,
  //   subtotal,
  //   isSubRow = false, // add for volume discount row
  // } = params
  // console.log('canShowProfit :', canShowProfit)
  // console.log('txtProfit :', txtProfit)
  // console.log('price:',price)
  // log('params',params)

  // ---------------------------------------------------------------------------------------------------------------------------

  const isQtyEditable = isCreateMode || (isEditable && !isCanceledOrder && _.includes([1, 2], focusedOrderType))
  // console.log('store_id => ', store_id)
  // console.log('pp_id => ', pp_id)
  // console.log('isQtyEditable => ', isQtyEditable)
  // console.log('isCreateMode => ', isCreateMode)
  // console.log('isEditable => ', isEditable)
  // console.log('isCanceledOrder => ', isCanceledOrder)
  // console.log('focusedOrderType => ', focusedOrderType)

  // React.useCallback(
  const _onQtyChange = async (newText: string) => {
    if (newText === '0' && isCreateMode) {
      const isUserConfirm = await p.op.isUserConfirm('ต้องการลบสินค้า?')
      if (isUserConfirm) {
        _onPressDeleteProduct()
        return
      }
      return
    }

    onChangeStoreProductByKey({
      // store_id,
      // index,
      value: newText,
      key: 'qty',
      pp_id,
    })
  }
  const _onPriceChange = (newText: string) => {
    onChangeStoreProductByKey({
      // idx, index,
      value: util.validateInputCurrency(newText),
      key: 'price',
      pp_id,
    })
  }

  const _onSellerUnitDiscountChange = (newText: string) => {
    onChangeStoreProductByKey({
      // idx, index,
      value: util.validateInputCurrency(newText),
      key: 'seller_unit_discount',
      pp_id,
    })
  }

  const _onPriceAfterDiscountChange = (newText: string) => {
    onChangeStoreProductByKey({
      // idx, index,
      value: util.validateInputCurrency(newText),
      key: 'price_after_discount',
      pp_id,
    })
  }
  // ,[onChangeStoreProductByKey, index, pp_id] )

  // React.useCallback(
  const _onPressDeleteProduct = async () => {
    if (isCreateMode) {
      onDeleteProduct({ store_id, pp_id })
      return
    }
    if (!isCreateMode && isQtyEditable) {
      // const isUserConfirm = p.op.isUserConfirm('ต้องการลบสินค้า?', 'เมื่อยืนยันจะเป็นการปรับให้จำนวนสินค้าเป็น 0')
      _onQtyChange('0')
    }
  }
  // , [store_id, pp_id])
  // ---------------------------------------------------------------------------------------------------------------------------

  const shouldShowSalePrice =
    seller_unit_discount && parseFloat(seller_unit_discount) > 0 && (isCreateMode || _.includes([1, 2], focusedOrderType))

  return (
    <XCard w='full' _light={{ bg: qty === 0 ? 'muted.200' : 'white' }}>
      <XCard.Body w='full'>
        <HStack w='full' space='2'>
          <VStack w='68px' space='1'>
            <HStack w='68px' minH='19'>
              <FastImage
                resizeMode={FastImage.resizeMode.cover}
                style={{
                  // minWidth: 86,
                  // maxWidth: 112,
                  // height: 80,
                  width: 64,
                  height: 64,
                  borderTopWidth: 1,
                  borderLeftWidth: 1,
                  borderBottomWidth: 1,
                  borderRightWidth: 1,
                  borderColor: '#f8f8f8',
                  borderRadius: 8,
                }}
                source={thumbnail_uri ? { uri: thumbnail_uri } : emptyImageSrc}
              />
            </HStack>

            <HStack w='68px' minH={isCreateMode || isQtyEditable ? '9' : undefined}>
              {/* {isRemovable && !isFreezeProduct ? (
                <XIconButton size='sm' name='trash' variant='subtle' iconVariant='inactive' onPress={_onPressDeleteProduct} />
              ) : null} */}
              {isCreateMode || (isQtyEditable && qty > 0) ? (
                <XIconButton size='sm' name='trash' variant='subtle' iconVariant='inactive' onPress={_onPressDeleteProduct} />
              ) : null}
            </HStack>
          </VStack>

          <VStack minH='12' flex={1} space='1'>
            {/* Row 1: Product's name + Variant's name --- Delete Button */}
            <HStack w='full' space='1'>
              <VStack minH='9' flex={1} flexWrap='wrap' space='1'>
                {/* Row 1-1: Product's name --- Delete Button */}
                <XText minH='9' w='full'>
                  {name}
                </XText>
                {/* Row 1-2: Product's variant name */}
                {!_.isNull(variant) && _.isString(variant) && variant.length > 0 ? (
                  <XText variant='inactive' w='full'>
                    {variant}
                  </XText>
                ) : null}
              </VStack>
            </HStack>

            {/* Row 2: Product's price  */}
            <HStack minH='6px' w='full' space='1' alignItems='center'>
              <XText
                minH='6px'
                bold={!shouldShowSalePrice}
                style={shouldShowSalePrice ? { textDecorationLine: 'line-through', textDecorationStyle: 'solid' } : null}>
                {xFmt.formatCurrency(price)}
              </XText>

              {shouldShowSalePrice ? (
                <XText minH='6px' bold>
                  {xFmt.formatCurrency(parseFloat(price) - parseFloat(seller_unit_discount))}
                </XText>
              ) : null}

              {/* {isQtyEditable ? <XIconButton family='MaterialIcons' name='edit' /> : null} */}
              {/* {isCreateMode ? ( */}
              {isCreateMode || (isEditable && !isCanceledOrder && _.includes([1, 2], focusedOrderType)) ? (
                <EditPriceButton
                  productName={name}
                  variantName={variant}
                  price={price}
                  seller_unit_discount={seller_unit_discount}
                  price_after_discount={price_after_discount}
                  onChangePrice={_onPriceChange}
                  onSellerUnitDiscountChange={_onSellerUnitDiscountChange}
                  onPriceAfterDiscountChange={_onPriceAfterDiscountChange}
                />
              ) : null}
            </HStack>

            {/* Row 3: Product's  qty */}
            <HStack w='full' alignItems='flex-end' space='1' pb='0' h='8' mb='-5'>
              {isQtyEditable ? (
                // pKeng 8 Dec 2022, if h is less than 10, the card will be blank!
                // Workaround is to set XNumbericInput.tsx minH to 10.
                <XNumericInput
                  // isDisabled={!isQtyEditable}
                  w='32'
                  minH='9'
                  // h='9'
                  // keyboardType='numeric'
                  // value={`${qty.toString()}` || '0'}
                  value={qty}
                  // minValue={1}
                  minValue={0}
                  maxValue={99999}
                  maxLength={5}
                  onChangeText={_onQtyChange}
                />
              ) : (
                <XText minH='9' bold>{`x${qty}`}</XText>
              )}
            </HStack>
          </VStack>
        </HStack>
      </XCard.Body>
      <AdditionInfo
        product={product}
        canShowProfit={canShowProfit}
        showProfit={showProfit}
        isCreateMode={isCreateMode}
        focusedOrderType={focusedOrderType}
      />
    </XCard>
  )
}

const AdditionInfo = ({ canShowProfit, showProfit, product, isCreateMode, focusedOrderType }) => {
  let isVisible = false

  // const purchaseDiscount = product.get('purchaseDiscount')
  // const purchaseDiscountPerEach = product.get('purchaseDiscountPerEach')
  // const saleDiscount = product.get('saleDiscount')
  // const saleDiscountPerEach = product.get('saleDiscountPerEach')

  const isProfitVisible = canShowProfit && showProfit
  // const isPurchaseDiscountVisible =
  //   (isCreateMode || (_.includes([1, 2], focusedOrderType) && canShowProfit && showProfit)) && purchaseDiscountPerEach
  // const isSaleDiscountVisible =
  //   (isCreateMode || _.includes([1, 2], focusedOrderType)) && saleDiscountPerEach && saleDiscountPerEach > 0 && saleDiscount
  // const isPurchaseDiscountForOrderType3Visible = (isCreateMode || _.includes([3], focusedOrderType)) && purchaseDiscountPerEach

  if (isProfitVisible) {
    isVisible = true
  }
  // if (isProfitVisible || isPurchaseDiscountVisible || isSaleDiscountVisible || isPurchaseDiscountForOrderType3Visible) {
  //   isVisible = true
  // }

  if (!isVisible) {
    return null
  }

  return isVisible ? (
    <XCard.Footer w='full' pt='0' pb='0'>
      {/* <VStack w='full' space='1' backgroundColor='yellow.300'> */}
      <VStack w='full' space='1'>
        {isProfitVisible ? <InfoProfit product={product} /> : null}
        {/* {isPurchaseDiscountVisible ? <InfoPurchaseDiscount product={product} /> : null} */}
        {/* {isSaleDiscountVisible ? <InfoSaleDiscount product={product} /> : null} */}
        {/* {isPurchaseDiscountForOrderType3Visible ? <InfoPurchaseDiscountForOrderType3 product={product} /> : null} */}
      </VStack>
    </XCard.Footer>
  ) : null
}

export const EditPriceButton = ({
  productName,
  variantName,
  onChangePrice,
  onSellerUnitDiscountChange,
  onPriceAfterDiscountChange,
  price,
  seller_unit_discount = '0',
  price_after_discount = null,
  onSubmitNewPriceAndDiscount = null,
}) => {
  const [isEditting, setIsEditing] = React.useState(false)
  const [editablePrice, setEditablePrice] = React.useState(price)
  const [sellerUnitDiscount, setsellerUnitDiscount] = React.useState(seller_unit_discount)
  const inputRef = React.useRef(null)

  const _closeEditing = () => setIsEditing(false)
  const _openEditing = () => {
    setEditablePrice(price)
    setIsEditing(true)
    setTimeout(() => {
      // console.log('inputRef => ', inputRef)
      inputRef.current.focus()
    }, 100)
  }
  const _changeEditingPrice = (newText: string) => {
    setEditablePrice(newText)
  }
  const _onSubmitPrice = () => {
    onChangePrice(editablePrice)
    onSellerUnitDiscountChange(sellerUnitDiscount)
    onPriceAfterDiscountChange(parseFloat(editablePrice) - parseFloat(sellerUnitDiscount))
    if (_.isFunction(onSubmitNewPriceAndDiscount)) {
      onSubmitNewPriceAndDiscount(editablePrice, sellerUnitDiscount)
    }
    _closeEditing()
  }

  const _computePriceAfterDiscount = () => {
    // if (price_after_discount) {
    //   return price_after_discount
    // }

    let computedPriceAfterDiscount = parseFloat(editablePrice) - parseFloat(sellerUnitDiscount)

    if (computedPriceAfterDiscount < 0) {
      // ส่วนลดคือส่วนลด ไม่น่าจะมีส่วนในการให้เงินคืนไปที่ลูกค้า
      computedPriceAfterDiscount = 0
    }
    return xFmt.formatCurrency(computedPriceAfterDiscount)
  }

  return (
    <Box minH='36px'>
      <XIconButton family='MaterialIcons' name='edit' onPress={_openEditing} />
      <XOverlay
        visible={isEditting}
        contentStyle={{ width: 300, minHeight: 250 }}
        // onRequestClose={_closeEditing}
      >
        <XCard
          // w='3/4'
          h='full'
          // minW='xs'
          flexWrap='wrap'
          overflow='hidden'
          borderRadius='lg'
          w='full'
          // @ts-ignore
          // _web={{ w: 'full' }}
          // style={{ maxHeight: Math.floor(Dimensions.get(!p.op.isWeb() ? 'screen' : 'window').height - 180) }}
        >
          <VStack w='full' h='full'>
            <XCustomHeader
              title='แก้ไขราคา'
              // headerRightProps={{ closeIcon: true, onPressItem: _closeEditing }}
            />
            <VStack flex={1} w='full' px='3' py='2' space='2.5' alignItems='center'>
              <HStack w='full' minH='9' alignItems='center' justifyContent='space-between'>
                <XText variant='inactive'>สินค้า: </XText>
                <XText numberOfLines={2} textAlign='right' w='150px' pr='1'>
                  {productName}
                </XText>
              </HStack>
              {variantName && variantName !== '' ? (
                <HStack w='full' minH='9' alignItems='center' justifyContent='space-between'>
                  <XText variant='inactive' w='80px'>
                    ตัวเลือก:
                  </XText>
                  <XText variant='inactive' textAlign='right' w='150px' numberOfLines={1} pr='1'>
                    {variantName}
                  </XText>
                </HStack>
              ) : null}
              <HStack w='full' minH='9' alignItems='center' justifyContent='space-between'>
                <XText variant='inactive' w='80px'>
                  ราคาเดิม:
                </XText>
                <XText bold textAlign='right' w='150px' pr='1'>
                  {xFmt.formatCurrency(price)}
                </XText>
              </HStack>

              <HStack w='full' alignItems='center' justifyContent='space-between'>
                <XText variant='inactive' w='80px'>
                  ราคาใหม่
                </XText>
                <XInput
                  ref={inputRef}
                  isMoney
                  textAlign='right'
                  w='150px'
                  value={editablePrice}
                  maxLength={12}
                  onChangeText={_changeEditingPrice}
                />
              </HStack>

              <HStack w='full' alignItems='center' justifyContent='space-between'>
                <XText variant='inactive'>ส่วนลดต่อชิ้น</XText>
                <XInput
                  ref={inputRef}
                  isMoney
                  textAlign='right'
                  w='150px'
                  value={sellerUnitDiscount}
                  maxLength={12}
                  onChangeText={setsellerUnitDiscount}
                />
              </HStack>

              <HStack w='full' minH='9' alignItems='center' justifyContent='space-between'>
                <XText variant='inactive'>ราคาหลังส่วนลด</XText>
                <XText bold textAlign='right' w='150px' pr='1'>
                  {_computePriceAfterDiscount()}
                </XText>
              </HStack>
            </VStack>
            <HStack p='1' space='2'>
              <XButton variant='outline' w='80px' onPress={_closeEditing}>
                ปิด
              </XButton>

              <XButton flex={1} onPress={_onSubmitPrice}>
                ยืนยัน
              </XButton>
            </HStack>
          </VStack>
        </XCard>
      </XOverlay>
    </Box>
  )
}

const InfoPurchaseDiscount = ({ product }) => {
  const purchaseDiscountPerEach = product.get('purchaseDiscountPerEach')
  const txtPurchaseDiscount = `กำไรเพิ่ม ฿${purchaseDiscountPerEach} ต่อชิ้น จากส่วนลดต้นทุนที่ผู้ขายส่งให้ฉัน`

  return <XText variant='success'>{txtPurchaseDiscount}</XText>
}

const InfoPurchaseDiscountForOrderType3 = ({ product }) => {
  const purchaseDiscountPerEach = product.get('purchaseDiscountPerEach')
  const txtPurchaseDiscountForOrderType3 = `ส่วนลดต้นทุนที่ผู้ขายส่งให้ฉัน ฿${purchaseDiscountPerEach}`

  return <XText variant='danger'>{txtPurchaseDiscountForOrderType3}</XText>
}

const InfoSaleDiscount = ({ product }) => {
  // const saleDiscount = product.get('saleDiscount')
  const saleDiscountPerEach = product.get('saleDiscountPerEach')
  const txtSaleDiscount = `ส่วนลดสมาชิกให้ลูกค้า  ฿${saleDiscountPerEach}`

  return <XText variant='danger'>{txtSaleDiscount}</XText>
}

const InfoProfit = ({ product }) => {
  const profit = product.get('profit')
  // const profit = price - cost
  // let txtProfit = null
  // let bgProfit = COLORS.TEXT_INACTIVE
  // if (profit > 0) {
  //   txtProfit = `กำไร ${xFmt.formatCurrency(profit)}`
  //   bgProfit = COLORS.BRAND_Success
  // } else if (profit < 0) {
  //   txtProfit = `ขาดทุน ${xFmt.formatCurrency(profit)}`
  //   bgProfit = COLORS.BRAND_Danger
  // } else {
  //   // === 0
  //   txtProfit = `กำไร ${xFmt.formatCurrency(0)}`
  //   bgProfit = COLORS.BRAND_Success
  //   // return null
  // }
  const txtProfit = profit >= 0 ? `กำไร ${xFmt.formatCurrency(profit)}` : `ขาดทุน ${xFmt.formatCurrency(profit)}`
  return (
    <XText
      minH='5'
      variant={profit >= 0 ? 'success' : 'danger'}
      //  style={{ color: bgProfit }}
    >
      {txtProfit}
    </XText>
  )
}

export default OrderProductItem
