import React, { useEffect, useRef } from 'react'
import { COLORS, STYLES } from 'x/config/styles'
import { TouchableOpacity, Animated, Easing } from 'react-native'
import _ from 'lodash'

import XIcon from './XIcon'

interface ISyncBtn {
  disabled?: boolean
  onPress: () => void
  isLoading: boolean
}

// export function useRotation(isLoading: boolean) {
//   const animation = useRef(new Animated.Value(0)).current

//   useEffect(() => {
//     console.log('isLoading 02 => ', isLoading)
//     if (isLoading) {
//       startAnimation()
//     } else {
//       stopAnimation()
//     }
//   })

//   function startAnimation() {
//     Animated.sequence(
//       [
//         Animated.timing(animation, {
//           toValue: 100,
//           duration: 75000,
//           useNativeDriver: true,
//         }),
//         Animated.delay(300),
//       ],
//       // @ts-ignore
//       { useNativeDriver: true }
//     ).start(() => {
//       animation.setValue(0)
//       startAnimation()
//     })
//   }

//   function stopAnimation() {
//     Animated.sequence(
//       [
//         Animated.timing(animation, {
//           toValue: 0,
//           duration: 350,
//           useNativeDriver: true,
//         }),
//         Animated.delay(300),
//       ],
//       // @ts-ignore
//       { useNativeDriver: true }
//     ).start(() => {
//       animation.setValue(0)
//       startAnimation()
//     })
//   }

//   //interpolate
//   const rotateInterpolation = animation.interpolate({
//     inputRange: [0, 1],
//     outputRange: ['0deg', '360deg'],
//   })

//   return rotateInterpolation
// }

// let syncLoadingValue = new Animated.Value(0)

export function useRotation(isLoading: boolean) {
  const animation = useRef(new Animated.Value(0)).current
  // console.log('isLoading => 02 ', isLoading)
  useEffect(() => {
    function startAnimation() {
      Animated.sequence(
        [
          Animated.timing(animation, {
            toValue: isLoading ? 30000 : 0,
            duration: isLoading ? 100000 : 0,
            useNativeDriver: true,
            easing: Easing.linear,
          }),
          Animated.delay(500),
        ],
        // @ts-ignore
        { useNativeDriver: true }
      ).start()
    }
    startAnimation()
  })

  const rotateInterpolate = animation.interpolate({
    inputRange: [0, 360],
    outputRange: ['0deg', '360deg'],
  })

  return rotateInterpolate
}

const SyncBtn: React.FC<ISyncBtn> = ({ disabled = false, onPress, isLoading }) => {
  // const [syncLoadingValue, setSyncLoadingValue] = useState(new Animated.Value(0))
  // const [isRefresh, setRefresh] = useState(false)
  useEffect(() => {
    // setRefresh(isRefresh)
  }, [isLoading])
  const loading = _.isNil(isLoading) ? false : isLoading
  // console.log('loading => ', loading)
  const rotation = useRotation(loading)
  const transformStyle = { transform: [{ rotate: rotation }] }

  const BTN_SYNC_COLOR = _.isNil(isLoading) || isLoading ? COLORS.TEXT_INACTIVE : COLORS.GB_PAY_MAIN
  return (
    <TouchableOpacity
      disabled={_.isNil(disabled) ? true : disabled}
      onPress={() => onPress()}
      style={[
        STYLES.BTN_ICON_STYLE,
        { borderWidth: 1, borderColor: BTN_SYNC_COLOR, borderRadius: 7, justifyContent: 'center', alignItems: 'center' },
      ]}>
      <Animated.View style={transformStyle}>
        <XIcon
          name='sync'
          family='MaterialIcons'
          style={{ fontSize: STYLES.FONT_ICON_SMALLER, color: BTN_SYNC_COLOR }}
          _dark={{
            color: COLORS.WHITE,
          }}
        />
      </Animated.View>
    </TouchableOpacity>
  )
}

export default SyncBtn
