import React from 'react'
import * as NavActions from 'x/utils/navigation'
import p from 'x/config/platform-specific'
import { ISelectedStoreMap, IProfileMap, IXScreenProps } from 'x/index'
import CONS from 'x/config/constants'

const { FBDL_INIT, RNDL_INIT, FBDL_LATEST, RNDL_LATEST } = CONS.STORAGE_KEYS

const dataHosts = [
  { label: 'prod', host: 'api.xselly.com', go_host: 'api2.xselly.com', https: true },
  { label: 'dev', host: 'superdev.xselly.com', go_host: 'superdevgo.xselly.com', https: true },
  { label: 'staging', host: 'stagingapi.xselly.com', go_host: 'stagingapi2.xselly.com', https: true },
  { label: 'web1', host: '167.71.206.142', go_host: '167.71.206.142' },
  { label: 'web8', host: '128.199.206.168', go_host: '128.199.206.168' },
  { label: 'web9', host: '104.248.154.95', go_host: '104.248.154.95' },
  { label: '192.168.0.100', host: '192.168.0.100', go_host: '192.168.0.100' },
  { label: '192.168.0.101', host: '192.168.0.101', go_host: '192.168.0.101' },
  { label: '192.168.0.102', host: '192.168.0.102', go_host: '192.168.0.102' },
  { label: '192.168.0.103', host: '192.168.0.103', go_host: '192.168.0.103' },
  { label: '192.168.1.40', host: '192.168.1.40', go_host: '192.168.1.40' },
  { label: '192.168.1.41', host: '192.168.1.41', go_host: '192.168.1.41' },
  { label: '192.168.1.42', host: '192.168.1.42', go_host: '192.168.1.42' },
  { label: '192.168.1.43', host: '192.168.1.43', go_host: '192.168.1.43' },
  { label: '192.168.1.44', host: '192.168.1.44', go_host: '192.168.1.44' },
  { label: '192.168.1.45', host: '192.168.1.45', go_host: '192.168.1.45' },
  { label: '192.168.1.46', host: '192.168.1.46', go_host: '192.168.1.46' },
  { label: '192.168.1.47', host: '192.168.1.47', go_host: '192.168.1.47' },
  { label: '192.168.1.48', host: '192.168.1.48', go_host: '192.168.1.48' },
  { label: '192.168.1.49', host: '192.168.1.49', go_host: '192.168.1.49' },
  { label: '192.168.1.50', host: '192.168.1.50', go_host: '192.168.1.50' },
  { label: '192.168.1.100', host: '192.168.1.100', go_host: '192.168.1.100' },
  { label: '192.168.1.101', host: '192.168.1.101', go_host: '192.168.1.101' },
  { label: '192.168.1.102', host: '192.168.1.102', go_host: '192.168.1.102' },
  { label: '192.168.1.103', host: '192.168.1.103', go_host: '192.168.1.103' },
  { label: '192.168.1.104', host: '192.168.1.104', go_host: '192.168.1.104' },
  { label: '192.168.1.105', host: '192.168.1.105', go_host: '192.168.1.105' },
  { label: '192.168.1.106', host: '192.168.1.106', go_host: '192.168.1.106' },
  { label: '192.168.1.107', host: '192.168.1.107', go_host: '192.168.1.107' },
  { label: '192.168.1.108', host: '192.168.1.108', go_host: '192.168.1.108' },
  { label: '192.168.1.109', host: '192.168.1.109', go_host: '192.168.1.109' },
  { label: '192.168.1.110', host: '192.168.1.110', go_host: '192.168.1.110' },
  { label: '192.168.1.111', host: '192.168.1.111', go_host: '192.168.1.111' },
  { label: '192.168.1.112', host: '192.168.1.112', go_host: '192.168.1.112' },
  { label: '192.168.1.113', host: '192.168.1.113', go_host: '192.168.1.113' },
  { label: '192.168.1.114', host: '192.168.1.114', go_host: '192.168.1.114' },
  { label: '192.168.1.115', host: '192.168.1.115', go_host: '192.168.1.115' },
  { label: '192.168.1.116', host: '192.168.1.116', go_host: '192.168.1.116' },
  { label: '192.168.1.117', host: '192.168.1.117', go_host: '192.168.1.117' },
  { label: '192.168.1.118', host: '192.168.1.118', go_host: '192.168.1.118' },
  { label: '192.168.1.119', host: '192.168.1.119', go_host: '192.168.1.119' },
  { label: '192.168.1.120', host: '192.168.1.120', go_host: '192.168.1.120' },
  { label: '192.168.1.121', host: '192.168.1.121', go_host: '192.168.1.121' },
  { label: '192.168.1.122', host: '192.168.1.122', go_host: '192.168.1.122' },
  { label: '192.168.1.123', host: '192.168.1.123', go_host: '192.168.1.122' },
  { label: '192.168.1.124', host: '192.168.1.124', go_host: '192.168.1.123' },
  { label: '192.168.1.125', host: '192.168.1.125', go_host: '192.168.1.124' },
  { label: '192.168.1.126', host: '192.168.1.126', go_host: '192.168.1.125' },
  { label: '192.168.1.127', host: '192.168.1.127', go_host: '192.168.1.126' },
  { label: '192.168.1.128', host: '192.168.1.128', go_host: '192.168.1.127' },
  { label: '192.168.1.129', host: '192.168.1.129', go_host: '192.168.1.128' },
  { label: '172.20.10.0', host: '172.20.10.0', go_host: '172.20.10.0' },
  { label: '172.20.10.1', host: '172.20.10.1', go_host: '172.20.10.1' },
  { label: '172.20.10.2', host: '172.20.10.2', go_host: '172.20.10.2' },
  { label: '172.20.10.3', host: '172.20.10.3', go_host: '172.20.10.3' },
  { label: '172.20.10.4', host: '172.20.10.4', go_host: '172.20.10.4' },
  { label: '172.20.10.5', host: '172.20.10.5', go_host: '172.20.10.5' },
  { label: '172.20.10.6', host: '172.20.10.6', go_host: '172.20.10.6' },
  { label: '172.20.10.7', host: '172.20.10.7', go_host: '172.20.10.7' },
  { label: '172.20.10.8', host: '172.20.10.8', go_host: '172.20.10.8' },
  { label: '172.20.10.9', host: '172.20.10.9', go_host: '172.20.10.9' },
  { label: '172.20.10.10', host: '172.20.10.10', go_host: '172.20.10.10' },
  { label: '192.168.0.0', host: '192.168.0.0', go_host: '192.168.0.0' },
  { label: '192.168.0.1', host: '192.168.0.1', go_host: '192.168.0.1' },
  { label: '192.168.0.2', host: '192.168.0.2', go_host: '192.168.0.2' },
  { label: '192.168.0.3', host: '192.168.0.3', go_host: '192.168.0.3' },
  { label: '192.168.0.4', host: '192.168.0.4', go_host: '192.168.0.4' },
  { label: '192.168.0.5', host: '192.168.0.5', go_host: '192.168.0.5' },
  { label: '192.168.0.6', host: '192.168.0.6', go_host: '192.168.0.6' },
  { label: '192.168.0.7', host: '192.168.0.7', go_host: '192.168.0.7' },
  { label: '192.168.0.8', host: '192.168.0.8', go_host: '192.168.0.8' },
  { label: '192.168.0.9', host: '192.168.0.9', go_host: '192.168.0.9' },
  { label: '192.168.0.10', host: '192.168.0.10', go_host: '192.168.0.10' },
  { label: '192.168.0.11', host: '192.168.0.11', go_host: '192.168.0.11' },
  { label: '192.168.0.12', host: '192.168.0.12', go_host: '192.168.0.12' },
  { label: '192.168.1.0', host: '192.168.1.0', go_host: '192.168.1.0' },
  { label: '192.168.1.1', host: '192.168.1.1', go_host: '192.168.1.1' },
  { label: '192.168.1.2', host: '192.168.1.2', go_host: '192.168.1.2' },
  { label: '192.168.1.3', host: '192.168.1.3', go_host: '192.168.1.3' },
  { label: '192.168.1.4', host: '192.168.1.4', go_host: '192.168.1.4' },
  { label: '192.168.1.5', host: '192.168.1.5', go_host: '192.168.1.5' },
  { label: '192.168.1.6', host: '192.168.1.6', go_host: '192.168.1.6' },
  { label: '192.168.1.7', host: '192.168.1.7', go_host: '192.168.1.7' },
  { label: '192.168.1.8', host: '192.168.1.8', go_host: '192.168.1.8' },
  { label: '192.168.1.9', host: '192.168.1.9', go_host: '192.168.1.9' },
  { label: '192.168.1.10', host: '192.168.1.10', go_host: '192.168.1.10' },
  { label: '192.168.1.11', host: '192.168.1.11', go_host: '192.168.1.11' },
  { label: '192.168.1.12', host: '192.168.1.12', go_host: '192.168.1.12' },
  { label: '192.168.2.0', host: '192.168.2.0', go_host: '192.168.2.0' },
  { label: '192.168.2.1', host: '192.168.2.1', go_host: '192.168.2.1' },
  { label: '192.168.2.2', host: '192.168.2.2', go_host: '192.168.2.2' },
  { label: '192.168.2.3', host: '192.168.2.3', go_host: '192.168.2.3' },
  { label: '192.168.2.4', host: '192.168.2.4', go_host: '192.168.2.4' },
  { label: '192.168.2.5', host: '192.168.2.5', go_host: '192.168.2.5' },
  { label: '192.168.2.6', host: '192.168.2.6', go_host: '192.168.2.6' },
  { label: '192.168.2.7', host: '192.168.2.7', go_host: '192.168.2.7' },
  { label: '192.168.2.8', host: '192.168.2.8', go_host: '192.168.2.8' },
  { label: '192.168.2.9', host: '192.168.2.9', go_host: '192.168.2.9' },
  { label: '192.168.2.10', host: '192.168.2.10', go_host: '192.168.2.10' },
  { label: '192.168.2.11', host: '192.168.2.11', go_host: '192.168.2.11' },
  { label: '192.168.2.12', host: '192.168.2.12', go_host: '192.168.2.12' },
  { label: 'SS prod', host: 'api.somsai.co.th', go_host: 'api2.somsai.co.th', https: true },
  { label: 'SS dev', host: 'superdev.somsai.co.th', go_host: 'superdevgo.somsai.co.th', https: true },
]

interface IBaseAboutViewProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
  profile: IProfileMap
  aboutStateActions: any
  dispatch?: (param?: any) => void
}

interface IBaseAboutViewState {
  deviceInfo: any
}

export default abstract class BaseAboutView extends React.Component<IBaseAboutViewProps, IBaseAboutViewState> {
  // abstract async handleSubmitForm(response: any): Promise<void>
  // abstract didMountCheckPermissionForPhone?: () => void
  // validationSchema: Yup.ObjectSchema<any>
  // imgManagerIDRef: React.RefObject<any>
  inProcess: boolean

  constructor(props) {
    super(props)
    this.state = {
      deviceInfo: null,
    }
    this.inProcess = false
  }

  // async componentDidMount() {}

  _changeHost() {
    const cfg = {
      key: 'selectedHost',
      title: 'เลือก Host ใช้งาน',
      viewType: CONS.PAGEPICKER_VIEWTYPE.ICON,
      items: dataHosts,
    }
    this.props.navigation.dispatch(NavActions.navToPagePicker(cfg))
  }

  _debugDeepLink = async () => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    const FBDL_INIT_LOG = await p.op.storageGet(FBDL_INIT, false)
    // @ts-ignore
    await p.op.alertPromise('FBDL_INIT_LOG', FBDL_INIT_LOG)
    const RNDL_INIT_LOG = await p.op.storageGet(RNDL_INIT, false)
    // @ts-ignore
    await p.op.alertPromise('RNDL_INIT_LOG', RNDL_INIT_LOG)
    const LATEST_FBDL_LOG = await p.op.storageGet(FBDL_LATEST, false)
    // @ts-ignore
    await p.op.alertPromise('LATEST_FBDL_LOG', LATEST_FBDL_LOG)
    const LATEST_RNDL_LOG = await p.op.storageGet(RNDL_LATEST, false)
    // @ts-ignore
    await p.op.alertPromise('LATEST_RNDL_LOG', LATEST_RNDL_LOG)
    setTimeout(() => {
      this.inProcess = false
    }, 500)
  }
}
