import React from 'react'
import _ from 'lodash'
import { TouchableOpacity, View, Text, StyleSheet } from 'react-native'

import * as util from 'x/utils/util'
import api from 'x/utils/api'
import { S, STYLES, COLORS } from 'x/config/styles'
// import CONS from 'x/config/constants'

import XOverlay from '../XOverlay'
import XCustomHeader from '../XCustomHeader'
// import XSegment from '../XSegment'
// import XRadioList from '../XRadioList'
// import { diff } from 'deep-object-diff'
// import XIcon from '../XIcon'
import XInput from '../XInput'
import XText from '../XText'
import VStack from '../VStack'

interface IDeleteAccountButtonProps {
  // after delete success
  onDeleted?: () => void

  // simple close without delete
  onClose?: () => void

  onCloseAfterDeleted?: (isAccountDeleted: boolean) => void
}

interface IDeleteAccountButtonState {
  isOverlayVisible: boolean
  confirmDeleteText: string

  dialogStep: number // start with 1

  // use in step 3 only
  isAccountDeleted: boolean
  hasAppleId: boolean
  hasFbId: boolean

  isSubmitting: boolean
}

interface IDeleteAccountResponse {
  is_account_deleted: boolean
  has_apple_id: false
  has_fb_id: true
}

class DeleteAccountButton extends React.Component<IDeleteAccountButtonProps, IDeleteAccountButtonState> {
  static displayName = 'DeleteAccountButton'

  inProcess?: boolean

  constructor(props) {
    super(props)
    this.state = {
      isOverlayVisible: false,
      confirmDeleteText: '',

      dialogStep: 1,

      isAccountDeleted: false,
      hasAppleId: false,
      hasFbId: false,

      isSubmitting: false,
    }

    this.inProcess = false
  }

  openOverlay = async () => {
    // console.log('openOverlay')
    await util.setStatePromise(this, { isOverlayVisible: true })
  }

  closeOverlay = async () => {
    // console.log('closeOverlay')
    const { dialogStep = 1, isAccountDeleted = false } = this.state

    const { onClose, onCloseAfterDeleted } = this.props
    if (_.isFunction(onClose)) {
      onClose()
    }

    if (_.isFunction(onCloseAfterDeleted) && dialogStep === 3) {
      onCloseAfterDeleted(isAccountDeleted)
    }

    await util.setStatePromise(this, {
      isOverlayVisible: false,
      dialogStep: 1,
      isAccountDeleted: false,
      hasAppleId: false,
      hasFbId: false,
      confirmDeleteText: '',
    })
  }

  _onPressDelete = async () => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    await util.setStatePromise(this, { isSubmitting: true })

    console.log('_onPressDelete')

    const { onDeleted } = this.props
    const isDeleteApiSuccess = this.doDeleteAccount()

    if (isDeleteApiSuccess) {
      // await this.closeOverlay()
      await util.setStatePromise(this, { dialogStep: 3 })
    }

    if (isDeleteApiSuccess && _.isFunction(onDeleted)) {
      onDeleted()
    }

    await util.setStatePromise(this, { isSubmitting: false })

    this.inProcess = false
  }

  doDeleteAccount = async (): Promise<boolean> => {
    let isDeleteApiSuccess = false
    try {
      // const apiOptions: IApiOptions = {
      //   fetchOptions: {
      //     timeout: 180000,
      //   },
      // }

      const res = await api.del<{}, IDeleteAccountResponse>('privacy_policy/delete_account', {})
      // console.log('doDeleteAccount res => ', res)

      isDeleteApiSuccess = true

      const newState: Partial<IDeleteAccountButtonState> = {}
      if (res && _.has(res, 'is_account_deleted')) {
        newState.isAccountDeleted = res.is_account_deleted
      }
      if (res && _.has(res, 'has_apple_id')) {
        newState.hasAppleId = res.has_apple_id
      }
      if (res && _.has(res, 'has_fb_id')) {
        newState.hasFbId = res.has_fb_id
      }

      if (!_.isEmpty(newState)) {
        await util.setStatePromise(this, newState)
      }
    } catch (err) {
      // console.log('doDeleteAccount err => ', err)
      if (util.isXSellyErrorV2(err)) {
        // @ts-ignore
        util.alertWithXSellyErrorV2(err.error)
      }
    }

    return isDeleteApiSuccess
  }

  _onPressContinueToStepTwo = () => {
    this.setState({ dialogStep: 2 })
  }

  renderHeader = () => {
    const { dialogStep = 1, isAccountDeleted = false } = this.state
    let titleText = 'ยืนยันการลบบัญชี'

    if (dialogStep === 3) {
      titleText = isAccountDeleted ? 'ลบบัญชี XSelly สำเร็จ' : 'เกิดข้อผิดพลาดในการลบบัญชี'
    }

    return <XCustomHeader title={titleText} headerRightProps={{ closeIcon: !this.state.isSubmitting, onPressItem: this.closeOverlay }} />
  }

  renderStepOneConfirmationButtons = () => (
    <View
      style={{
        flex: 1,
        width: '100%',
        minHeight: 44,
        padding: 4,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
      }}>
      <TouchableOpacity onPress={this._onPressContinueToStepTwo} style={[S.FLEX, S.BUTTON_DANGER, S.HEIGHT_34]}>
        <XText bold color='white'>
          ดำเนินการต่อ
        </XText>
      </TouchableOpacity>
      <View style={{ width: 8 }} />
      <TouchableOpacity onPress={this.closeOverlay} style={[S.FLEX, S.ROW_CENTER, S.BUTTON_OUTLINE_PRIMARY, S.HEIGHT_34]}>
        <XText bold color={COLORS.APP_MAIN}>
          {'ยกเลิก '}
        </XText>
      </TouchableOpacity>
    </View>
  )

  renderContentStepOne = () => (
    <VStack>
      <VStack w='full' bgColor='amber.100'>
        <View style={{ width: '100%', padding: 8, flexDirection: 'row', flexWrap: 'wrap' }}>
          <XText variant='active'>
            {
              'หากดำเนินการลบบัญชี ข้อมูลเกี่ยวกับการเข้าถึงบัญชี XSelly ของคุณจะถูกลบในทันที ซึ่งจะทำให้คุณไม่สามารถเข้าสู่บัญชีเดิมนี้ได้อีก โดยข้อมูลทางธุรกรรมและข้อมูลอื่นๆที่บริษัทมีหน้าที่เก็บรักษาตามกฏหมาย เช่น ข้อมูลการซื้อขาย จะถูกลบภายใน 7 ปี จากนี้\n\nคุณต้องการดำเนินการลบบัญชี XSelly นี้หรือไม่'
            }
          </XText>
        </View>

        <View style={{ height: 16 }} />
      </VStack>
      {this.renderStepOneConfirmationButtons()}
    </VStack>
  )

  isConfirmDeleteTextValid = () => {
    const { confirmDeleteText = '' } = this.state
    return confirmDeleteText === 'DELETE'
  }

  renderStepTwoConfirmationButtons = () => {
    const isDelBtnDisabled = !this.isConfirmDeleteTextValid()
    return (
      <View style={{ width: '100%', minHeight: 44, padding: 4, alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
        <TouchableOpacity
          disabled={isDelBtnDisabled}
          onPress={isDelBtnDisabled ? undefined : this._onPressDelete}
          style={[S.FLEX, isDelBtnDisabled ? S.BUTTON_INACTIVE : S.BUTTON_DANGER, S.HEIGHT_34]}>
          <XText bold style={[isDelBtnDisabled ? S.TEXT_ACTIVE : S.TEXT_ACTIVE_DARK, S.TEXT_LARGER, S.TEXT_ALIGN_CENTER]}>
            ลบบัญชี
          </XText>
        </TouchableOpacity>
        <View style={{ width: 8 }} />
        <TouchableOpacity onPress={this.closeOverlay} style={[S.FLEX, S.ROW_CENTER, S.BUTTON_OUTLINE_PRIMARY, S.HEIGHT_34]}>
          <XText bold style={[S.TEXT_PRIMARY, S.TEXT_ALIGN_CENTER, S.TEXT_LARGER]}>
            {'ยกเลิก '}
          </XText>
        </TouchableOpacity>
      </View>
    )
  }

  _onConfirmDeleteTextChange = (newText: string) => {
    this.setState({ confirmDeleteText: newText })
  }

  renderDeleteTextInput = () => {
    const { confirmDeleteText = '' } = this.state
    return (
      <XInput
        style={{ minHeight: 34, padding: 8, fontSize: STYLES.FONT_SIZE_LARGER }}
        flex={1}
        textAlign='center'
        textAlignVertical='center'
        placeholder='พิมพ์ DELETE เพื่อลบบัญชี'
        value={confirmDeleteText}
        onChangeText={this._onConfirmDeleteTextChange}
      />
    )
  }

  renderContentStepTwo = () => (
    <>
      <View style={{ width: '100%', padding: 8, flexDirection: 'row', flexWrap: 'wrap' }}>
        <XText style={[S.TEXT_ACTIVE, S.TEXT_LARGER]}>
          เพื่อยืนยันการลบบัญชี กรุณาพิมพ์คำว่า DELETE (ตัวพิมพ์ใหญ่ทุกตัว) ในช่องด้านล่างและกดปุ่ม ลบบัญชี
        </XText>
      </View>
      <View style={{ width: '100%', padding: 8, flexDirection: 'row', flexWrap: 'wrap' }}>{this.renderDeleteTextInput()}</View>

      <View style={{ height: 16 }} />

      {this.renderStepTwoConfirmationButtons()}
    </>
  )

  renderStepThreeConfirmationButton = () => (
    <View style={{ width: '100%', minHeight: 44, padding: 4, alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
      <TouchableOpacity onPress={this.closeOverlay} style={[S.FLEX, S.ROW_CENTER, S.BUTTON_OUTLINE_PRIMARY, S.HEIGHT_34]}>
        <Text style={[S.TEXT_PRIMARY, S.TEXT_BOLD, S.TEXT_ALIGN_CENTER]}>{'ปิด '}</Text>
      </TouchableOpacity>
    </View>
  )

  renderContentStepThree = () => {
    const { isAccountDeleted = false, hasAppleId = false, hasFbId = false } = this.state

    let infoText = isAccountDeleted ? 'ลบบัญชี XSelly สำเร็จ' : 'เกิดข้อผิดพลาดในการลบบัญชี'

    const appleIdText = hasAppleId
      ? 'ข้อมูลบัญชี XSelly ของคุณถูกลบเรียบร้อย หากคุณต้องการลบการเชื่อมต่อกับ Apple ID ด้วยสามารถดำเนินการเพิ่มได้โดยการ “เปิด Settings > กดด้านบนที่มีชื่อของคุณ > กด Password & Security > กด Apps Using Apple ID > กด XSelly > กด Stop Using Apple ID”'
      : null

    const fbIdText = hasFbId
      ? 'ข้อมูลบัญชี XSelly ของคุณถูกลบเรียบร้อย หากคุณต้องการลบการเชื่อมต่อกับ Facebook ID ด้วยสามารถดำเนินการเพิ่มได้โดยการ “เปิด Facebook App > กด การตั้งค่าและความเป็นส่วนตัว > กด การตั้งค่า > กด แอพและเว็บไซต์ > กด XSelly > กด ลบออก”'
      : null

    infoText = appleIdText ? `${infoText}\n\n${appleIdText}` : infoText
    infoText = fbIdText ? `${infoText}\n\n${fbIdText}` : infoText
    infoText = `${infoText}\n\nXSelly ขอขอบคุณที่ได้ใช้บริการ หากมีข้อแนะนำประการใด ทางทีมงานยินดีน้อมรับ โดยสามารถแจ้งมาได้ที่ LINE: @XSelly ตลอดเวลา ไม่มีวันหยุด`

    return (
      <>
        <View style={{ width: '100%', padding: 8, flexDirection: 'row', flexWrap: 'wrap' }}>
          <Text style={[S.TEXT_ACTIVE, S.TEXT_LARGER]}>{infoText}</Text>
        </View>

        <View style={{ height: 16 }} />

        {this.renderStepThreeConfirmationButton()}
      </>
    )
  }

  renderContent = () => {
    const { dialogStep = 1 } = this.state
    return (
      <View style={{ width: '100%' }}>
        {this.renderHeader()}

        {dialogStep === 1 ? this.renderContentStepOne() : null}
        {dialogStep === 2 ? this.renderContentStepTwo() : null}
        {dialogStep === 3 ? this.renderContentStepThree() : null}
      </View>
    )
  }

  renderMain = () => {
    const { isOverlayVisible = false, isSubmitting = false, dialogStep } = this.state
    const heightDialog = 290
    return (
      <>
        <TouchableOpacity
          style={[S.BUTTON_OUTLINE, S.BORDERS_DANGER, S.BG_LIGHT_GREY, { height: 34, width: 120 }]}
          onPress={this.openOverlay}>
          <XText color={COLORS.BRAND_Danger} bold>
            ลบบัญชี
          </XText>
        </TouchableOpacity>

        {isOverlayVisible ? (
          <XOverlay
            contentStyle={StyleSheet.flatten([
              {
                minHeight: 150,
                marginBottom: 130,
                width: 310,
                height: heightDialog,
                alignSelf: 'center',
                flexDirection: 'column',
                backgroundColor: COLORS.WHITE,
              },
              // p.op.isWeb() ? { height: dialogStep === 1 ? 290 : 260 } : {},
            ])}
            visible={isOverlayVisible}
            onRequestClose={isSubmitting ? undefined : this.closeOverlay}>
            {this.renderContent()}
          </XOverlay>
        ) : null}
      </>
    )
  }

  render() {
    return this.renderMain()
  }
}

export default DeleteAccountButton
