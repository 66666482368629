import React, { useState } from 'react'
import { ITimeSlotsProps, IMKPChannelDetail, IOnSubmitOverlayEditTime } from 'x/index'
import CONS from 'x/config/constants'
import _ from 'lodash'
import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'

import OverlayEditTimeSlot from 'xui/modules/channels/OverlayEditTimeSlot'
import VStack from 'xui/components/VStack'
import Box from 'xui/components/Box'
import XIcon from './XIcon'
import XButton from './XButton'
import XText from './XText'

const XTimeSlots: React.FC<ITimeSlotsProps> = function ({
  channel,
  setPrefTimeslot,
  selectedStore,
  callBackUpdateChannel,
  updateNewChannelList,
}) {
  const [newChannel, setChannels] = useState(channel)
  if (_.isNil(newChannel)) {
    return null
  }

  const _confirmChange = async (data: IOnSubmitOverlayEditTime) => {
    // if (submitLoading) {
    //   return
    // }
    // SubmitLoading(true)
    // console.log('data => ', data)
    const store_id = selectedStore.get('id')
    // const address = _.find(channel.addresses, (addr) => addr.is_primary)
    // console.log(`address_mapping_uuid `, address)
    // if (_.isNil(address)) {
    //   return
    // }
    const body: { [key: string]: any } = {
      store_id,
      shipping_type_id: data.shippingTypeId,
      start_time: util.getTimeFormathhmm(data.time.start),
      end_time: util.getTimeFormathhmm(data.time.end),
    }
    if (data.mkpChannel && data.mkpChannel.id) {
      body.mkp_ch_id = data.mkpChannel.id
    }
    if (data.addressId && !_.isNil(data.addressId)) {
      body.address_mapping_uuid = data.addressId
    }
    const response: { channel: IMKPChannelDetail } = await new Promise((resolveSave) => {
      setPrefTimeslot({
        body,
        successCallback: resolveSave,
        failedCallback: () => resolveSave(null),
      })
    })
    // console.log('response // => ', response)
    if (!_.isNil(response) && response.channel) {
      setChannels(response.channel)
      callBackUpdateChannel(response.channel)
      const newChannels = await util.updateMKPChannelsRedux(response.channel)
      if (!_.isNil(newChannels)) {
        updateNewChannelList(newChannels)
      }
      // _closeOverlay()
      p.op.showConfirmationOkOnly('แก้ไขเรียบร้อยแล้ว', 'ระบบได้ทำการบันทึกการแก้ไขเรียบร้อยแล้ว')
    }
    // SubmitLoading(false)
  }

  let shippinTypeIds = newChannel.shipping_type_ids ? newChannel.shipping_type_ids : null
  if (_.isNil(shippinTypeIds)) {
    // 20/09/2022 (ลงมติ: เก่ง, ไนท์, อาทิตย์)
    // ตอนนี้หลังบ้านจะไม่ส่ง shipping_type_ids มาให้แล้ว ให้หน้าบ้าน Headcode 5 = อื่นๆ ในฝั่งหน้าบ้านเอง
    shippinTypeIds = [5]
    // CONS.ORDER_VIEW_SHIPPING_TYPE_ITEMS.forEach((shipping) => {
    //   shippinTypeIds.push(shipping.value)
    // })
  }

  // const updateChannelFromCallBack = (newChannelCallBack: IMKPChannelDetail) => {
  //   setChannels(newChannelCallBack)
  //   callBackUpdateChannel(newChannelCallBack)
  // }

  return (
    <VStack space='2'>
      {shippinTypeIds.map((shippingId: number, index: number) => {
        // index เริ่มจาก 0 เสมอ
        const ListTimeSlost = newChannel.timeslot_prefs
        const shipping = _.find(CONS.ORDER_VIEW_SHIPPING_TYPE_ITEMS, (sh) => sh.value === shippingId)
        const timeSlot = _.find(ListTimeSlost, (data) => data.shipping_type_id === shippingId)
        if (_.isNil(shipping)) {
          return null
        }

        const { addresses } = newChannel
        const marginTop = index > 0 ? 6 : 0

        let address = null
        // @ts-ignore
        let addressLable = `ยังไม่ได้ระบุ`

        const getTextTime = (time: Date) => {
          if (_.isNil(time)) {
            return `--`
          }
          let hours = time.getHours().toString()
          if (Number(hours) < 10) {
            hours = `0${hours}`
          }
          let minutes = time.getMinutes().toString()
          if (Number(minutes) < 10) {
            minutes = `0${minutes}`
          }
          return `${hours}:${minutes}`
        }

        let START_TIME = null
        let END_TIME = null

        const newDate = new Date()
        const year = newDate.getFullYear()
        let month = `${newDate.getMonth() + 1}`
        if (+month < 10) {
          month = `0${month}`
        }
        const day = newDate.getDay()

        if (!_.isNil(timeSlot)) {
          address = _.find(addresses, (addr) => addr.address_mapping_uuid === timeSlot.address_mapping_uuid)
          addressLable = util.getAddresFormatLable(address)
          START_TIME = timeSlot.start_time
          END_TIME = timeSlot.end_time
        }
        const textStartTime = START_TIME
        const textEndTime = END_TIME
        // eslint-disable-next-line react/no-unstable-nested-components
        const _renderFooterButton = (onPressOpenOverlay: () => void) => (
          <XButton onPress={() => onPressOpenOverlay()} leftIcon={<XIcon name='edit' family='FontAwesome5' />}>
            เปลี่ยนเวลาและที่อยู่เข้ารับ
          </XButton>
        )

        let headerTitleForOverlay = `จัดการ ช่องทางการจัดส่ง ${p.op.t(`Order.shippingTypeItems.${shipping.key}`)}`
        if (shippingId === 5) {
          headerTitleForOverlay = 'จัดการ ช่องทางการจัดส่ง'
        }

        return (
          <Box
            borderWidth='1'
            borderRadius='md'
            p='2'
            borderColor='gray.400'
            // style={{
            //   borderColor: COLORS.TEXT_INACTIVE,
            //   borderWidth: 1,
            //   paddingLeft: 4,
            //   paddingTop: 4,
            //   paddingBottom: 4,
            //   paddingRight: 4,
            //   marginTop,
            // }}
            // eslint-disable-next-line react/no-array-index-key
            key={index}>
            {/* // ขนส่ง */}
            <Box mt='2' flex={1} justifyContent='center' alignItems='center' flexDirection='row'>
              <Box flex={1} justifyContent='center'>
                <XText variant='active' bold>
                  {/* // ถ้า shippingId === 5 (อื่นๆ) ไม่ต้องแสดงชื่อขนส่ง 20/09/2022 */}
                  {shippingId === 5 ? '' : p.op.t(`Order.shippingTypeItems.${shipping.key}`)}
                </XText>
              </Box>
              {/* // EDIT BTN */}
              <OverlayEditTimeSlot
                renderButton={(onPressOpenOverlay: () => void) => _renderFooterButton(onPressOpenOverlay)}
                channel={newChannel}
                shippingTypeId={shippingId}
                key={newChannel.id}
                isSingleMkpChannel
                // setPrefTimeslot={setPrefTimeslot}
                showSectionSetTime
                onSubmit={(data: IOnSubmitOverlayEditTime) => _confirmChange(data)}
                headertitle={headerTitleForOverlay}
                footerText='ยืนยัน'
              />
            </Box>
            {/* // เวลาที่สะดวกให้เข้ารับ */}
            <Box mt='4' flex={1} flexDirection='row'>
              <Box flex={1} alignItems='flex-start'>
                <XText variant='inactive'>เวลาที่สะดวกให้เข้ารับ:</XText>
              </Box>
              {/* // ด้านขวา */}
              <Box style={{ flex: 1, alignItems: 'center', flexDirection: 'row', justifyContent: 'flex-end' }}>
                <Box justifyContent='center' alignItems='center' w='10'>
                  <XText variant='active'>{textStartTime}</XText>
                </Box>
                <Box style={{ width: 10, justifyContent: 'center', alignItems: 'center' }}>
                  <XText variant='active'>-</XText>
                </Box>
                <Box justifyContent='center' alignItems='center' w='10'>
                  <XText variant='active'>{textEndTime}</XText>
                </Box>
              </Box>
            </Box>
            {/* // ที่อยู่ในการเข้ารับ */}
            <Box flex={1}>
              <XText variant='inactive'>ที่อยู่ในการเข้ารับ:</XText>
            </Box>
            <Box>
              <XText variant='active'>{addressLable}</XText>
            </Box>
          </Box>
        )
      })}
    </VStack>
  )
}

export default XTimeSlots
