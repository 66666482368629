// import PropTypes from 'prop-types'
import { Component } from 'react'
import p from 'x/config/platform-specific'
import _ from 'lodash'
import { isImmutable } from 'immutable'
import { UGListViewProps, UserGroupResellerTabsProps, UserGroupResellerTabsState } from 'x/index'
import CONS from 'x/config/constants'
import * as util from 'x/utils/util'

interface IDISPLAY_TABS {
  label: string
  perm: string[]
}

const { MEMBER_ADD, MEMBER_LIST } = CONS.PERM_STORE_HELPER

export default class BaseUserGroupResellerTabs extends Component<UserGroupResellerTabsProps, UserGroupResellerTabsState> {
  inProcess: boolean

  DISPLAY_TABS: IDISPLAY_TABS[]

  constructor(props: UGListViewProps) {
    // @ts-ignore
    super(props)
    this.inProcess = false
    this.DISPLAY_TABS = [
      {
        label: 'ขอเข้าร่วม',
        perm: [MEMBER_LIST, MEMBER_ADD],
      },
      {
        label: 'สมาชิก',
        perm: [MEMBER_LIST, MEMBER_ADD],
      },
      {
        label: 'เพิ่มตัวแทน',
        perm: [MEMBER_LIST, MEMBER_ADD],
      },
    ]

    this.state = {
      selectedUserGroup: null,
      tabIndex: -1,
      initialPage: 0,
      isInitialized: false,
      submitting: false,
    }
  }

  async componentDidMount() {
    await this._firstInitialize()
  }

  _firstInitialize = async () => {
    const { selectedUserGroups, navigation } = this.props
    // const { state } = navigation
    const params = util.getNavParams(this.props)
    const { ug_id, initTabIndex = 0 } = params
    // console.log('selectedUserGroups => ', selectedUserGroups)
    // console.log('selectedUserGroups // => ', selectedUserGroups.toJS())
    const fetchedUG = selectedUserGroups.find((ug) => ug.get('id') === ug_id)
    // console.log('fetchedUG => ', fetchedUG)
    if (_.isNil(fetchedUG)) {
      this._cannotInitialize()
      return
    }
    const userGroup = isImmutable(fetchedUG) ? fetchedUG.toJS() : fetchedUG
    await util.setStatePromise(this, {
      selectedUserGroup: userGroup,
      tabIndex: initTabIndex,
    })
  }

  _cannotInitialize() {
    p.op.alert('เกิดข้อผิดพลาด', 'ไม่สามารถโหลดกลุ่มสมาชิกได้ กรุณากลับไปเลือกร้านค้าใหม่อีกครั้ง')
    util.navGoBack(this.props)
  }

  _onchangeTab = async (index: number) => {
    await util.setStatePromise(this, {
      tabIndex: index,
    })
  }
}
