import { Map, fromJS, List } from 'immutable'
// import { setUpdateCategoryList } from 'x/redux/selectors'
import { ActionApiParams, ICategory } from 'x/index'

const MockCategory = [
  {
    id: 1,
    p: [],
    c: [2, 3, 4, 5, 6, 7],
    n: 'เสื้อผ้า',
    l: '#343234',
  },
  {
    id: 2,
    p: [1],
    c: [6, 7],
    n: 'เสื้อผ้าเด็ก',
    l: `#989eff`,
  },
  {
    id: 3,
    p: [1],
    c: [],
    n: 'เสื้อผ้าผู้ชาย',
    l: '#123456',
  },
  {
    id: 4,
    p: [1],
    c: [],
    n: 'เสื้อผ้าผู้หญิง',
    l: `#85c1e9`,
  },
  {
    id: 5,
    p: [1],
    c: [],
    n: 'เสื้อผ้าออกกำลังกาย',
    l: `#ff51ad`,
  },
  {
    id: 6,
    p: [1, 2],
    c: [],
    n: 'ชุดนอนเด็ก',
  },
  {
    id: 7,
    p: [1, 2],
    c: [],
    n: 'ชุดลำลองเด็ก',
  },
]

interface categoryByStore {
  store_id: number
  category: List<ICategory>
}
// Map<string, string | number>
const category: List<categoryByStore> = List([])

// const category: ICategory[] = null
// Initial state
const initialState = Map({
  category,
})

// Actions
// CUSTOM ORDER
export const REQUEST_CATEGORY_LIST = 'CetegoryState/REQUEST_CATEGORY_LIST'
export const ADD_CATEGORY = 'CetegoryState/ADD_CATEGORY'
export const EDIT_CATEGORY = 'CetegoryState/EDIT_CATEGORY'
export const UPDATE_CATEGORY = 'CetegoryState/UPDATE_CATEGORY'
export const DELETE_CATEGORY = 'CetegoryState/DELETE_CATEGORY'
export const CHANGE_CATEGORY = 'CetegoryState/CHANGE_CATEGORY'
export const SORT_CATEGORY = 'CetegoryState/SORT_CATEGORY'

// export function fetchOrders(payload) {
//   util.log(payload)
//   return { type: actions.ORDERS_FETCH, payload }
// }

export function requestCategoryList(params: ActionApiParams) {
  return {
    type: REQUEST_CATEGORY_LIST,
    ...params,
    // payload: store,
  }
}

export function addCategory(params: ActionApiParams) {
  return {
    type: ADD_CATEGORY,
    ...params,
    // payload: store,
  }
}

export function editCategory(params: ActionApiParams) {
  return {
    type: EDIT_CATEGORY,
    ...params,
    // payload: store,
  }
}

export function deleteCategory(params: ActionApiParams) {
  return {
    type: DELETE_CATEGORY,
    ...params,
  }
}

export function changeCategory(params: ActionApiParams) {
  return {
    type: CHANGE_CATEGORY,
    ...params,
    // payload: store,
  }
}

export function sortCategory(params: ActionApiParams) {
  return {
    type: SORT_CATEGORY,
    ...params,
    // payload: store,
  }
}

// Reducer
export default function PreferenceStateReducer(state = initialState, action: ActionApiParams = {}) {
  const { type, payload } = action
  let newState = state
  switch (type) {
    // case GET_CATEGORY_LIST:
    //   return newState
    case UPDATE_CATEGORY:
      const oldCategory = state.get('category').toJS()
      const newUpdateCategory = {
        store_id: payload.store_id,
        category: payload.category,
      }
      const newCategory = []
      if (oldCategory.length < 1) {
        newCategory.push(newUpdateCategory)
      } else {
        let pushNew = false
        oldCategory.map((data: categoryByStore) => {
          if (data.store_id === newUpdateCategory.store_id) {
            pushNew = true
            newCategory.push(newUpdateCategory)
          } else {
            newCategory.push(data)
          }
        })
        if (!pushNew) {
          newCategory.push(newUpdateCategory)
        }
      }
      // newState = newState.removeIn(['category'])
      newState = newState.setIn(['category'], fromJS(newCategory))
      return newState

    default:
      return state
  }
}
