import React from 'react'

import BaseUIResellerListView from 'xui/modules/reseller/BaseUIResellerListView'
import { IOrgPendingList } from 'x/modules/reseller/BaseResellerApprovalListView'
import ThemeSizeContext from '../../context/ThemeSizeContext'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'

export default class ResellerListView extends BaseUIResellerListView {
  static contextType = ThemeSizeContext

  context!: React.ContextType<typeof ThemeSizeContext>

  navToCustomer = (item: IOrgPendingList) => {
    const { navigation } = this.props
    navigation.push('CustomerViewContainer', {
      profile: item,
      key: item.i,
      isApproved: true,
      // x: Math.random(),
    })
  }

  renderMain = () => {
    const { contentOneOfThreeColumnWidth } = this.context
    return (
      <XContainer style={{ width: contentOneOfThreeColumnWidth }}>
        {this._renderHeader()}
        {this._renderSearchView()}
        <XContent scrollEnabled contentContainerStyle={{ width: contentOneOfThreeColumnWidth }}>
          {this._renderBody()}
        </XContent>
      </XContainer>
    )
  }
}
