import BaseUIERPListView from 'xui/modules/erp/BaseUIERPListView'
// import { getConfig } from 'x/config/mode'
// import { WebView } from 'react-native-webview'
// import OrdersSummaryDonut from '../../components/OrdersSummaryDonut'

export default class ERPListView extends BaseUIERPListView {
  static displayName = 'ERPListView'

  static navigationOptions = {
    header: null,
  }
}
