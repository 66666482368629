import React, { forwardRef, memo } from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'
import { getComputedCustomStyledProps } from 'x/config/nativebase/makeStyledComponent'
import type { IPressableProps } from 'native-base'
import theme from 'x/config/nativebase/theme'
import XText from './XText'

export type IXPressableType = typeof TouchableOpacity
export type IXPressableProps = IPressableProps

// const StyledPressable = makeStyledComponent(TouchableOpacity, 'Pressable')

const XPressable = forwardRef((props, fwdRef) => {
  // console.log('XPressable props => ', props)
  // @ts-ignore
  // const { children, _text = {}, _icon = {}, _stack = {}, ...restProps } = props
  const { children, style } = props
  const { styleFromProps, underscoreProps, restProps } = getComputedCustomStyledProps(props, 'Pressable')
  const { _text = {}, _icon = {} } = underscoreProps

  // const childStringToTextCompoent = (child, cIdx) => {
  //   const key = `XPressableTextGen_${cIdx.toString()}`
  //   if (typeof child === 'string') {
  //     return (
  //       <XText key={key} {..._text}>
  //         {child}
  //       </XText>
  //     )
  //   }
  //   return React.Children.map(child, (leafChild, lcIdx) =>
  //     React.cloneElement(leafChild, {
  //       key: `${key}_${lcIdx.toString()}`,
  //       _text,
  //       _icon,
  //       ...child.props,
  //     })
  //   )
  // }

  const childStringToTextCompoent = (child: React.ReactElement, cIdx) => {
    const key = `XPressableTextGen_${cIdx.toString()}`

    if (typeof child === 'string' || typeof child === 'number') {
      return (
        <XText key={key} {..._text}>
          {child}
        </XText>
      )
    }

    // @ts-ignore
    if (child && child.type && child.type.displayName === 'XText') {
      // console.log(`childStringToTextCompoent child-${cIdx} -> `, child)
      return React.cloneElement(child, {
        key: `XPressableChild_XText_${cIdx.toString()}`,
        ..._text,
        ...child.props,
      })
    }

    // @ts-ignore
    if (child && child.type && (child.type.displayName === 'Icon' || child.type.displayName === 'XIcon')) {
      // console.log(`childStringToTextCompoent child-${cIdx} -> `, child)
      return React.cloneElement(child, {
        key: `XPressableChild_XIcon_${cIdx.toString()}`,
        ..._icon,
        ...child.props,
      })
    }

    // if (_.isArray(child)) {
    return React.Children.map(child, (leafChild, lcIdx) =>
      React.cloneElement(leafChild, {
        key: `${key}_${lcIdx.toString()}`,
        _text,
        _icon,
        ...leafChild.props,
        // children: React.Children.map(leafChild.props.children, childStringToTextCompoent),
        // children: React.Children.map(leafChild.props.children, childStringToTextCompoent),
      })
    )
  }

  return (
    // @ts-ignore
    <TouchableOpacity ref={fwdRef} {...restProps} style={[styleFromProps, StyleSheet.flatten(style)]}>
      {
        // @ts-ignore
        React.Children.map(children, childStringToTextCompoent)
      }
    </TouchableOpacity>
  )
})

XPressable.displayName = 'XPressable'
// @ts-ignore
XPressable.defaultProps = { theme }
// XPressable.defaultProps = getComponentDefaultProps('Pressable')

// const StyledXPressable = makeStyledComponent(XPressable, 'Pressable')

export default memo(XPressable) as React.FC<IXPressableProps>
