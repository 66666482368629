import BaseUIERPEditPaymentAccountView from 'xui/modules/erp/BaseUIERPEditPaymentAccountView'
// import { getConfig } from 'x/config/mode'
// import { WebView } from 'react-native-webview'
// import OrdersSummaryDonut from '../../components/OrdersSummaryDonut'

export default class ERPEditPaymentAccountView extends BaseUIERPEditPaymentAccountView {
  static displayName = 'ERPEditPaymentAccountView'

  static navigationOptions = {
    header: null,
  }
}
