import React, { useState } from 'react'
import { View, ViewStyle } from 'react-native'
import _ from 'lodash'

// import p from 'x/config/platform-specific'
// import { PrintOptionKey } from 'x/index'
import { delay, useStateWithPromise } from 'x/utils/util'
import { PrintOptionKey } from 'x/index'
import xCONS from 'x/config/constants'
import p from 'x/config/platform-specific'

import { ICON_FAMILY } from 'xui/components/XIcon'
import { Spinner } from 'native-base'
import PrintingOptionUI, { IPrintingOptionUIProps } from './PrintingOptionUI'
import XOverlay from './XOverlay'
import XIconButton from './XIconButton'
import VStack from './VStack'

interface IPrintingOptionOverlayButton extends IPrintingOptionUIProps {
  renderButton?: (onPressOpenOverlay: () => Promise<void>) => JSX.Element
  buttonStyle?: ViewStyle
  disabled?: boolean
}

interface IPrinterButtonProps {
  onPress: () => void | Promise<void>
  buttonStyle?: ViewStyle
  isDisabled?: boolean
  isSubmitting?: boolean
}

const PrinterButton: React.FC<IPrinterButtonProps> = ({ onPress, buttonStyle = {}, isDisabled = false, isSubmitting = false }) =>
  isSubmitting ? (
    <Spinner size='sm' />
  ) : (
    <XIconButton
      disabled={isDisabled}
      isDisabled={isDisabled}
      onPress={onPress}
      name='printer'
      family={ICON_FAMILY.AntDesign}
      variant={isSubmitting || isDisabled ? 'inactive' : 'primary'}
      style={buttonStyle}
    />
  )

// const PrinterButton: React.FC<IPrinterButtonProps> = ({ onPress, buttonStyle = {}, isDisabled = false, isSubmitting = false }) => (
//   <TouchableOpacity style={buttonStyle} onPress={onPress}>
//     {isSubmitting ? (
//       <Spinner size='sm' />
//     ) : (
//       <XIcon
//         name='printer'
//         family={ICON_FAMILY.AntDesign}
//         variant={isDisabled ? 'inactive' : 'primary'}
//         // style={{
//         //   alignSelf: 'center',
//         //   textAlign: 'center',
//         //   color: isDisabled ? COLORS.TEXT_INACTIVE : COLORS.TEXT_PRIMARY,
//         //   fontSize: 23,
//         //   // minWidth: 80,
//         //   // width: 50,
//         //   // height: 50,
//         //   ...Platform.select({
//         //     ios: { flex: 0 },
//         //     android: { flex: 0 },
//         //   }),
//         //   flexBasis: 'auto',
//         //   paddingTop: 0,
//         //   // จริงๆ 0 มันกลางแล้ว แต่ปรับขึ้นเป็น 2 เพราะสายตาคนมองเทียบกับอย่างอื่นแล้วมันจึงดูไม่กลาง
//         //   paddingBottom: Platform.OS === 'android' ? 2 : 0,
//         //   paddingLeft: 0,
//         //   paddingRight: 0,
//         //   marginTop: Platform.OS === 'android' ? 5 : 4, // <<< PRINTER MARGIN
//         // }}
//       />
//     )}
//   </TouchableOpacity>
// )

const PrintingOptionOverlayButton: React.FC<IPrintingOptionOverlayButton> = function ({
  renderButton,
  selectedStore,
  onSettings,
  onSubmit,
  afterSubmit,
  buttonStyle = {},
  disabled = false,
  disabledSettings,
  disabledBill,
  enabledReport = true,
}) {
  const [isOverlayVisible, setIsOverlayVisible] = useState<boolean>(false)
  const [submitting, setSubmitting] = useStateWithPromise<boolean>(false)

  const _onSubmitingStateChange = async (newSubmiting: boolean) => {
    await setSubmitting(newSubmiting)
  }

  const _openOverlay = async () => setIsOverlayVisible(true)
  const _closeOverlay = () => {
    if (submitting) {
      return
    }
    setIsOverlayVisible(false)
  }
  const _handleOnSetting = _.isFunction(onSettings)
    ? async () => {
        _closeOverlay()
        await delay(200)
        onSettings()
      }
    : null
  // const _handleOnSubmit = async (printKey: PrintOptionKey) => {
  //   if (isAsyncFunction(onSubmit)) {
  //     await onSubmit(printKey)
  //   } else {
  //     onSubmit(printKey)
  //   }
  //   await delay(200)
  //   _closeOverlay()
  // }

  // const _handleOnSubmit = async (printKey: PrintOptionKey) => {
  //   await setSubmitting(true)
  //   await delay(100)
  //   await onSubmit(printKey)
  //   await delay(100)
  //   await setSubmitting(false)
  // }

  const _handleAfterSubmit = async (printKey: PrintOptionKey) => {
    // BEGIN WORKAROUND FOR iOS loading PDF doc on Order View By pKeng on 27 Dec 2020 (originally on 13 Dec 2020)
    // if (xCONS.APP_NAME == xCONS.APP_NAMES.somsai && p.op.isIOS()) {

    // Can hide for Paperang View
    if (p.op.isIOS() && !(printKey === xCONS.PRINT_OPTIONS.BILL_PAPERANG || printKey === xCONS.PRINT_OPTIONS.PAPERANG)) {
      // Do nothing here (no code).
      //
      // There is an issue on iOS (only) that loading PDF dialog (from OrderView)
      // will be closed together with this print menu dialog IF the PDF can be loadded very quickly
      // i.e. small file like "ไม่มีออเดอร์ที่รอฉันจัดส่ง" but the regular content in PDF is fine
      // (the doc view is not closed).
      //
      // This behavior is wrong as closing the print menu dialog shall
      // happen behind the doc viewer dialog and not also closing the doc viewer dialog.
      //
      // Don't know why the same code and lib version doesn't happen on XSelly
      //
      // The work around here is to not "automatically" close the print menu dialog
      // on iOS.
    } else {
      _closeOverlay()
    }
    // END WORKAROUND FOR SOMSAI

    await delay(200)
    if (_.isFunction(afterSubmit)) {
      afterSubmit(printKey)
    }
  }

  const _renderControlButton = () => {
    if (_.isFunction(renderButton)) {
      return renderButton(_openOverlay)
    }
    return (
      <PrinterButton onPress={_openOverlay} isDisabled={isOverlayVisible || disabled} buttonStyle={buttonStyle} isSubmitting={submitting} />
    )
  }
  const _renderOverlay = () => (
    <XOverlay
      // contentStyle={{ height: 410, width: 310, alignSelf: 'center' }}
      visible={isOverlayVisible}
      // webNumColumns={1}
      onRequestClose={_closeOverlay}>
      {/* <View style={{ flex: 1, backgroundColor: '#fff' }}> */}
      <PrintingOptionUI
        selectedStore={selectedStore}
        // onSubmit={_handleOnSubmit}
        onSubmit={onSubmit}
        onSubmitingStateChange={_onSubmitingStateChange}
        afterSubmit={_handleAfterSubmit}
        onSettings={_handleOnSetting}
        disabledSettings={disabledSettings}
        disabledBill={disabledBill}
        enabledReport={enabledReport}
      />
      {/* </View> */}
    </XOverlay>
  )

  // Main render
  return (
    <>
      {_renderControlButton()}
      {_renderOverlay()}
    </>
  )
}

export default PrintingOptionOverlayButton
