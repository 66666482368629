import React, { useState } from 'react'
import { Text, View, SectionList } from 'react-native'

import { Button } from 'native-base'

import * as NavActions from 'x/utils/navigation'
// import s from './_styles'

import { COLORS, STYLES } from 'x/config/styles'
import p from 'x/config/platform-specific'

import MatIcon from 'react-native-vector-icons/MaterialIcons'
import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContent from 'xui/components/XContent'
import XContainer from 'xui/components/XContainer'
import Icon from 'xui/components/Icon'
// import EntypoIcon from 'react-native-vector-icons/Entypo'
import { IXScreenProps } from 'x/types'
import * as util from 'x/utils/util'

interface IMenu {
  txtTitle: string
  data: {
    label: string
    navAction: any
  }[]
}

type IPaymentAccountPickerProps = IXScreenProps

const DEFAULT_STORE_SETTING_LIST_SECTIONS: IMenu[] = [
  {
    txtTitle: `${p.op.t('ShippingLabel')}`,
    // iconName: 'store',
    // iconFamily: 'MaterialCommunityIcons',
    data: [{ label: 'ทดสอบ', navAction: NavActions.navToImportAddProductView() }],
  },
]

// interface IState {
//   STORE_SETTING_LIST_SECTIONS: any[]
// }

const StoreStoreSettingMenuShipping: React.FC<IPaymentAccountPickerProps> = (props) => {
  const { navigation } = props
  const [STORE_SETTING_LIST_SECTIONS, SET_STORE_SETTING_LIST_SECTIONS] = useState<IMenu[]>(DEFAULT_STORE_SETTING_LIST_SECTIONS)
  // const [selectedPaymentAccount, setSelectedPaymentAccount] = useState(null)
  // const [isPickerVisible, setIsPickerVisible] = useState(false)

  // useEffect(() => {
  // }, [])

  const _renderHeader = () => {
    // const { navigation } = this.props
    const leftBtn = { backIcon: true, onPressItem: () => util.navGoBack(props) }
    return <XCustomHeader title='ทดสอบเมนูใหม่' headerLeftProps={leftBtn} />
  }

  const _renderSectionList = (dataSource: IMenu[]) => (
    <SectionList
      keyExtractor={(item, index) => index.toString()}
      renderItem={({ item, index, section }) => _renderSectionItem(index, item, section)}
      renderSectionHeader={({ section }) => _renderSectionHeader(section)}
      sections={dataSource}
      stickySectionHeadersEnabled
      // ListFooterComponent={ () => <Separator /> }
    />
  )

  const _renderSectionItem = (index, item, section) => {
    // const { navigation } = this.props
    const { label, navAction } = item
    const lastIndex = section.data.length - 1
    const isFirstItem = index === 0
    const isLastItem = index === lastIndex
    // util.log('_renderSectionItem index =>', index)
    // util.log('_renderSectionItem item =>', item)
    // util.log('_renderSectionItem section =>', section)
    return (
      // <View style={isLastItem ? { marginBottom: 0 } : {}}>
      <View style={{ backgroundColor: 'white' }}>
        <Button
          rightIcon={
            <Icon
              name='arrow-forward'
              style={{
                alignSelf: 'flex-end',
                fontSize: STYLES.FONT_ICON_NORMAL,
                color: COLORS.TEXT_ACTIVE,
                // backgroundColor: 'red',
              }}
            />
          }
          style={{ justifyContent: 'space-between' }}
          onPress={() => navigation.dispatch(navAction)}>
          <Text
            style={{
              fontSize: STYLES.FONT_SIZE_NORMAL,
              color: COLORS.TEXT_ACTIVE,
              // backgroundColor: 'green',
            }}>
            {label}
          </Text>
        </Button>
      </View>
    )
  }

  const _renderSectionHeader = (section) => {
    // util.log('_renderSectionHeader section =>', section)
    const { iconName, iconFamily, txtTitle } = section

    const iconStyle = {
      fontSize: STYLES.FONT_ICON_NORMAL,
      color: COLORS.TEXT_ACTIVE,
      paddingRight: 5,
    }
    let usageIcon
    if (iconName) {
      switch (
        iconFamily // default is Ionicons
      ) {
        case 'MaterialCommunityIcons':
          usageIcon = <MCIcon name={iconName} style={iconStyle} />
          break
        case 'MaterialIcons':
          usageIcon = <MatIcon name={iconName} style={iconStyle} />
          break
        default:
          usageIcon = <Icon name={iconName} style={iconStyle} />
          break
      }
    } else {
      usageIcon = <View />
    }

    return (
      <Button leftIcon={usageIcon} style={{ backgroundColor: COLORS.FORM_PRIMARY_BG, paddingTop: 8, paddingBottom: 8 }}>
        <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, fontWeight: 'bold', color: COLORS.TEXT_ACTIVE }}>{txtTitle}</Text>
      </Button>
    )
  }

  return (
    <XContainer>
      <XContent>
        {_renderHeader()}
        {_renderSectionList(STORE_SETTING_LIST_SECTIONS)}
      </XContent>
    </XContainer>
  )
}

// // @ts-ignore disable react-navigation header
// StoreStoreSettingMenuShipping.navigationOptions = {
//   header: null,
// }

export default StoreStoreSettingMenuShipping
