import React from 'react'
import api from 'x/utils/api'
import { IApiOptions, ISelectedStoreMap, IXScreenProps } from 'x/types'
import * as util from 'x/utils/util'
import _ from 'lodash'

interface IBaseERPCreatePeakViewProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
}

interface IBaseERPCreatePeakViewState {
  userToken: string
}

export default abstract class BaseERPCreatePeakView extends React.Component<IBaseERPCreatePeakViewProps, IBaseERPCreatePeakViewState> {
  // abstract async handleSubmitForm(response: any): Promise<void>
  // abstract didMountCheckPermissionForPhone?: () => void
  // validationSchema: Yup.ObjectSchema<any>
  // imgManagerIDRef: React.RefObject<any>
  // webview: React.RefObject<any>

  constructor(props) {
    super(props)
    this.state = {
      userToken: '',
    }
    // this.webview = React.createRef()
    // this.imgManagerIDRef = React.createRef()
  }

  async componentDidMount() {}

  goBack = () => {
    util.navGoBack(this.props)
  }

  _saveBtn = async () => {
    const params = util.getNavParams(this.props)
    const { callBack } = params
    const { selectedStore } = this.props
    const { userToken } = this.state
    const store_id = selectedStore.get('id')
    const reqBody = {
      store_id,
      erp_system_id: 1,
      user_token: userToken,
    }

    const apiOptions: IApiOptions = {
      showSpinner: true,
    }

    const res = await api.postV2('erp/channel/create', reqBody, apiOptions)
    // console.log('res create // => ', res)
    if (_.isFunction(callBack)) {
      callBack(res.erp_channel_detail)
    }
    this.goBack()
  }
}
