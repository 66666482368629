// import PropTypes from 'prop-types'
import React from 'react'
import {
  Image,
  Platform,
  RefreshControl,
  TouchableOpacity,
  View,
  Clipboard,
  Linking,
  BackHandler,
  NativeEventSubscription,
  StyleSheet,
  ViewStyle,
} from 'react-native'

import { Pressable } from 'native-base'
import Box from 'xui/components/Box'
import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import { Button as EButton } from 'react-native-elements'

import { COLORS, STYLES } from 'x/config/styles'
import _ from 'lodash'
// import { COLORS as xCOLORS } from 'x/config/styles'
import { fromJS, isImmutable, List, Map } from 'immutable'

// import ModalDropdown from 'react-native-modal-dropdown'
// import { flowDone, isInFlow } from 'x/utils/global'
import { MODE, APP_CONFIG } from 'x/config/mode'

import p from 'x/config/platform-specific'
import * as xFmt from 'x/utils/formatter'
import * as xUtil from 'x/utils/util'
import * as xAcl from 'x/utils/acl'
import xCONS from 'x/config/constants'
import BaseOrderView from 'x/modules/order/BaseOrderView'
import * as NavActions from 'x/utils/navigation'

import { log, setStatePromise, delay, getLineUrlByLineId } from 'x/utils/util'
import FastImage from 'react-native-fast-image'
import {
  IBaseOrderViewProps,
  IBaseOrderViewState,
  IQuickAddress,
  ISelectedAddresses,
  IOrderMap,
  PrintOptionKey,
  ImmutableObject,
  IMKPChannelDetail,
  IBarcodeScannerOutput,
  INavHeaderButtonProps,
  IPaymentListViewNavParams,
  NavigationEventSubscription,
  ISearchableProductListViewNavParams,
  ISearchProductItem,
  IProductAfterAddStoreProductSelectorScannedOutput,
  IAddStoreProductSelectorPredefinedNavParams,
  IAddStoreProductSelectorOptions,
  IERPChannel,
  IAddressFormViewNavParams,
} from 'x/index'
import moment from 'moment'
import XOverlay from 'xui/components/XOverlay'
import XDateTimeSinglePicker from 'xui/components/XDateTimeSinglePicker'
import XHelpModal from 'xui/components/XHelpModal'
import FAQ from 'x/config/FAQ'
import XPercentDiscount from 'xui/components/XPercentDiscount'
import XAddressesListOverlay, { IAddressList } from 'xui/components/XAddressesListOverlay'
import BarcodeScannerModalButton from 'xui/components/BarcodeScannerModalButton'
import XIcon, { ICON_FAMILY } from 'xui/components/XIcon'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContainer from 'xui/components/XContainer'
import XCard from 'xui/components/XCard'
import XButton from 'xui/components/XButton'
import XButtonWithIcon from 'xui/components/XButtonWithIcon'
import XIconButton from 'xui/components/XIconButton'
import XBlankButton from 'xui/components/XBlankButton'
import XHorizontalScrollView from 'xui/components/XHorizontalScrollView'
import XContent from 'xui/components/XContent'
import MkpLogo from 'xui/components/MkpLogo'
import XSpinnerOverlay from 'xui/components/XSpinnerOverlay'
import XFaqModal from 'xui/components/XFaqModal'
import FAQ_CONSTANTS from 'x/config/FAQ_CONSTANTS'
import AddStoreProductSelector from 'xui/components/order/AddStoreProductSelector'
import ErpLogo from 'xui/components/ErpLogo'
import api from 'x/utils/api'
import XDropdown from '../../components/XDropdown'
import PrintingOptionOverlayButton from '../../components/PrintingOptionOverlayButton'
import XModal from '../../components/XModal'
import HelpButton from '../../components/HelpButton'
import XSwitch from '../../components/XSwitch'
import SwitchCard from '../../components/SwitchCard'
// @ts-ignore
import XFlatPicker from '../../components/XFlatPicker'

import XInput from '../../components/XInput'
import XText from '../../components/XText'

import XShipping from '../../components/XShipping'
import Segment from '../../components/Segment'
import ForwardIcon from '../../components/ForwardIcon'
import OrderRadioGroupItem from '../../components/OrderRadioGroupItem'
import OrderStatesTable from '../../components/order/OrderStatesTable'
// import OrderProductItem from '../../components/order/OrderProductItem'
import OrderStoreProductsCard from '../../components/order/OrderStoreProductsCard'
import OrderVolumeDiscountList from '../../components/order/OrderVolumeDiscountList'

import Icon from '../../components/Icon'
import PaymentPTTComponent, { IPaymentPartnerTransactions } from '../payment/PaymentPTTComponent'

// const tb_my0 = require('../../img/tb/tb_my0.png')
// const tb_seller0 = require('../../img/tb/tb_seller0.png')
const Messenger_Icon = require('../../img/about/Messenger_Icon.png')
const LINE_Icon = require('../../img/about/LINE_Icon.png')
// const emptyImageSrc = require('../../images/empty_image.png')

const isWeb = Platform.OS === 'web'

const { Fragment } = React
// const NO_ACTION = { text: '-', iconDisabled: true }
const imgIconCOD = require('../../images/icon_cod_blue.png')

const RECEIVER_ADDR_ACTIONS = {
  ADD_NEW: {
    TITLE: 'เลือกที่อยู่ลูกค้าจาก...',
    BUTTONS: ['บัญชีรายชื่อ (บันทึกไว้ใช้ซ้ำ)', 'กรอกใช้ครั้งเดียว', 'วางชื่อที่อยู่ทั้งก้อน (เช่น จากแชท หรือ SHOPEE)', 'ยกเลิก'],
    ACTION_INDEXES: {
      CHOOSE: 0,
      QUICK: 1,
      PASTE_ONE: 2,
      // PASTE_SHOPEE: 3,
      CANCEL: -1,
      // COPY: 3,
    },
  },
  EDIT: {
    TITLE: 'แก้ไขที่อยู่ลูกค้าจาก...',
    BUTTONS: ['บัญชีรายชื่อ', 'แก้ไขใช้ครั้งเดียว', 'วางชื่อที่อยู่ทั้งก้อน (เช่น จากแชท หรือ Shopee)', 'คัดลอก', 'ยกเลิก'],
    ACTION_INDEXES: {
      CHOOSE: 0,
      QUICK: 1,
      PASTE_ONE: 2,
      // PASTE_SHOPEE: 3,
      COPY: 3,
      CANCEL: 4,
    },
  },
}

const AS_PICK_ADDRESS_TITLES = {
  CLONE_ADDRESS_FROM_RECEIVER: 'ใช้ที่อยู่เดียวกับผู้รับ',
  CHOOSE_STORE_CONTACT_ADDRESS: 'รายชื่อลูกค้า',
  CHOOSE_CUSTOMER_ADDRESS: 'บัญชีรายชื่อ (แบบเก่า)',
  USE_QUICK_ADDRESS: 'กรอกใช้ครั้งเดียว',
  CLIPBOARD_PASTE: 'วางชื่อที่อยู่ทั้งก้อน (เช่น จากแชท หรือ Shopee)',
  COPY: 'คัดลอก',
  CANCEL: 'ยกเลิก',
}

const AS_PICK_ADDRESS_KEY_SEQUENCES = [
  'CLONE_ADDRESS_FROM_RECEIVER',
  'CHOOSE_STORE_CONTACT_ADDRESS',
  'CHOOSE_CUSTOMER_ADDRESS',
  'USE_QUICK_ADDRESS',
  'CLIPBOARD_PASTE',
  'COPY',
  'CANCEL',
]

const BILLING_ADDR_ACTIONS = {
  ADD_NEW: {
    TITLE: 'เลือกที่อยู่ลูกค้าจาก...',
    BUTTONS: [
      'ใช้ที่อยู่เดียวกับผู้รับ',
      'บัญชีรายชื่อ (บันทึกไว้ใช้ซ้ำ)',
      'กรอกใช้ครั้งเดียว',
      'วางชื่อที่อยู่ทั้งก้อน (เช่น จากแชท หรือ SHOPEE)',
      'ยกเลิก',
    ],
    ACTION_INDEXES: {
      CLONE_FROM_RECEIVER: 0,
      CHOOSE: 1,
      QUICK: 2,
      PASTE_ONE: 3,
      // PASTE_SHOPEE: 3,
      CANCEL: -1,
      // COPY: 3,
    },
  },
  EDIT: {
    TITLE: 'แก้ไขที่อยู่ลูกค้าจาก...',
    BUTTONS: [
      'ใช้ที่อยู่เดียวกับผู้รับ',
      'บัญชีรายชื่อ',
      'แก้ไขใช้ครั้งเดียว',
      'วางชื่อที่อยู่ทั้งก้อน (เช่น จากแชท หรือ Shopee)',
      'คัดลอก',
      'ยกเลิก',
    ],
    ACTION_INDEXES: {
      CLONE_FROM_RECEIVER: 0,
      CHOOSE: 1,
      QUICK: 2,
      PASTE_ONE: 3,
      // PASTE_SHOPEE: 3,
      COPY: 4,
      CANCEL: 5,
    },
  },
}

const RE_CALCULATE = 'คำนวณ'
const SPECIFY = 'ระบุ'
const PERCENT_CALCULATE = 'คำนวณเปอร์เซ็นต์'

const {
  CARD_COMMON,
  FONT_SIZE_LARGER,
  FONT_SIZE_NORMAL,
  FONT_SIZE_SMALLER,
  FONT_SIZE_SMALLEST,
  FONT_ICON_NORMAL,
  FONT_ICON_SMALLER,
  FONT_ICON_SMALLEST,
  FONT_ICON_LARGEST,
  NO_MARGIN_PADDING,
  NO_MARGIN,
  NUMBER_PADDING_RIGHT_PLUS_1,
  COLOR_UNCOMMON,
  COLOR_COMMON,
  COLOR_DONE,
  BTN_BASE,
  BTN_MODE_ACTIVE_PRIMARY,
  BTN_TEXT_MODE_ACTIVE_PRIMARY,
  NUMBER_PADDING_RIGHT,
  FOOTER_BG,
  TXT_NORMAL,
  TXT_LABEL,
  TXT_LABEL_BOLD,
  TXT_NORMAL_BOLD,
  TXT_SMALLER_BOLD,
  TXT_LABEL_SMALLER,
  TXT_LABEL_LARGER,
} = STYLES

const {
  APP_MAIN,
  TEXT_INACTIVE,
  FORM_ERROR,
  TEXT_ACTIVE,
  TEXT_PRIMARY,
  FORM_INACTIVE,
  FORM_PRIMARY_BG,
  FORM_SUCCESS,
  FORM_SUCCESS_BG,
  BG_LIGHT_GREY,
  BG_LIGHT_GREY_ALTERNATIVE,
  TEXT_ACTIVE_DARK,
} = COLORS

const { ORDER_VIEW_MODE, PERM_STORE_HELPER, STORE_SETTINGS } = xCONS
const { CREATE, VIEW_EDIT, VIEW_ONLY, EDIT } = ORDER_VIEW_MODE
const { COMPUTED_USE_PRODUCT_UPC, COMPUTED_USE_PRODUCT_SKU } = STORE_SETTINGS

export const LOCAL_STYLES = StyleSheet.create({
  iconInButtonWithBottomLabel: {
    marginLeft: 0,
    marginRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
    textAlign: 'center',
    color: 'white',
  },
  labelActiveDarkBottomIconButton: {
    position: 'absolute',
    width: '100%',
    textAlign: 'center',
    bottom: 3,
    color: 'white',
    fontWeight: 'bold',
    fontSize: STYLES.FONT_SIZE_SMALLEST,
  },
})

export const INTL_STYLES = StyleSheet.create({
  colMiddleCenter: {
    flexBasis: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
  },
  colMiddleLeft: {
    flexBasis: 'auto',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  btnPrimaryActive: {
    flex: p.op.isWeb() ? 0 : 1,
    height: p.op.isWeb() ? 44 : '100%',
    flexBasis: 'auto',
    flexGrow: p.op.isWeb() ? 0 : 1,
    flexShrink: 0,
    minHeight: 40,
    width: '100%',
    // margin: 2,
    paddingVertical: 4,
    paddingHorizontal: 2,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: COLORS.APP_MAIN,
    backgroundColor: COLORS.APP_MAIN,
    alignItems: 'center',
  },
  txtBtnPrimaryActive: {
    fontSize: FONT_SIZE_NORMAL,
    // color: TEXT_ACTIVE_DARK,
    color: 'white',
    fontWeight: 'bold',
  },
  btnSecondaryActive: {
    flex: 1,
    height: '100%',
    width: '100%',
    margin: 2,
    paddingVertical: 4,
    paddingHorizontal: 2,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: COLORS.APP_MAIN,
    backgroundColor: 'white',
  },
  txtBtnSecondaryActive: {
    fontSize: FONT_SIZE_NORMAL,
    // color: TEXT_ACTIVE_DARK,
    color: COLORS.APP_MAIN,
    fontWeight: 'bold',
  },
  txtTitle: {
    // fontSize: FONT_SIZE_NORMAL,
    fontSize: FONT_SIZE_LARGER,
    color: TEXT_ACTIVE,
    fontWeight: 'bold',
  },
  txtLabel: {
    fontSize: FONT_SIZE_NORMAL,
    color: TEXT_INACTIVE,
  },
  txtActive: {
    fontSize: FONT_SIZE_NORMAL,
    color: TEXT_ACTIVE,
  },
  // @ts-ignore
  rowGroupAreaBordered: {
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderWidth: 0.5,
    borderColor: COLORS.TEXT_INACTIVE,
    flexBasis: 'auto',
    ...Platform.select({
      web: {
        justifyContent: 'center',
        alignItems: 'flex-start',
      },
    }),
  },
  hamburgerDropdownWrapper: {
    flex: 1,
    // height: 40,
    // width: 40,
    // paddingLeft: 5,
    // paddingRight: 5,
    // marginRight: 5,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tabStyle: {
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    // paddingLeft: 20,
    // paddingRight: 20,
    // paddingLeft: 16,
    // paddingRight: 24,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    // borderRightWidth: 0.5,
    // borderRightColor: COLORS.BG_LIGHT_GREY,
    // borderBottomWidth: 0.5,
    // borderBottomColor: COLORS.BG_LIGHT_GREY,
    marginRight: 4,
    ...Platform.select({
      ios: {
        paddingLeft: 12,
        paddingRight: 20,
      },
      android: {
        paddingLeft: 16,
        paddingRight: 24,
      },
      web: {
        height: 40,
        paddingLeft: 12,
        paddingRight: 20,
        // justifyContent: 'flex-end',
      },
    }),
  },
  tabUnderline: {
    width: '100%',
    borderBottomWidth: 2,
  },
  tabTriangle: {
    position: 'absolute',
    // top: 0,
    right: 0,
    zIndex: 10,

    width: 0,
    height: 0,
    borderStyle: 'solid',
    backgroundColor: 'transparent',
    lineHeight: 0,
    // Triangle
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderTopColor: 'transparent',
    borderRightColor: COLORS.BG_LIGHT_GREY_ALTERNATIVE,
    // borderRightColor: 'red',
    borderBottomColor: 'transparent',
    borderLeftColor: 'transparent',
    ...Platform.select({
      ios: {
        top: -12,
        borderRightWidth: 25,
        borderBottomWidth: 25,
      },
      android: {
        top: 0,
        borderRightWidth: 15,
        borderBottomWidth: 15,
      },
      web: {
        top: 0,
        borderRightWidth: 15,
        borderBottomWidth: 15,
      },
    }),
  },
  tabTriangleBg: {
    position: 'absolute',
    // top: 0,
    right: 0,
    zIndex: 5,

    width: 0,
    height: 0,
    borderStyle: 'solid',
    backgroundColor: 'transparent',
    // Triangle
    borderTopWidth: 0,
    borderRightWidth: 16,
    borderBottomWidth: 16,
    borderLeftWidth: 0,
    borderTopColor: 'transparent',
    borderRightColor: COLORS.BG_LIGHT_GREY,
    borderBottomColor: 'transparent',
    borderLeftColor: 'transparent',
    ...Platform.select({
      ios: {
        top: -12,
        borderRightWidth: 26,
        borderBottomWidth: 26,
      },
      android: {
        top: 0,
        borderRightWidth: 16,
        borderBottomWidth: 16,
      },
      web: {
        top: 0,
        borderRightWidth: 16,
        borderBottomWidth: 16,
      },
    }),
  },
  tabRightBorderLine: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    zIndex: 5,

    width: 1,
    height: 28,
    backgroundColor: COLORS.BG_LIGHT_GREY,
  },
})

export const CheckedIcon = (
  <Icon
    name='checkmark-circle'
    style={{
      // height: 15,
      // width: 15,
      fontSize: FONT_ICON_SMALLEST,
      color: FORM_SUCCESS,
      // fontWeight: 'bold',
      paddingLeft: 3,
    }}
  />
)

export const UncheckedIcon = (
  <Icon
    name='radio-button-off'
    style={{
      height: 15,
      width: 15,
      fontSize: FONT_ICON_SMALLEST,
      color: TEXT_INACTIVE,
      fontWeight: 'bold',
      paddingLeft: 3,
    }}
  />
)

const _renderAgreement = (onPressOpenOverlay: () => void) => <HelpButton onPress={() => onPressOpenOverlay()} />

abstract class BaseUIOrderView extends BaseOrderView {
  static displayName = 'OrderView'

  // เอาไว้สำหรับ debug render
  // static whyDidYouRender = true

  // static navigationOptions = {
  //   header: null,
  //   title: null,
  //   headerLeft: null,
  //   headerRight: null,
  // }

  // _orderTabsRef?: React.RefObject<ScrollableTabView>
  _backHandler?: NativeEventSubscription

  // _receiverCardRef: React.RefObject<any>

  // _senderCardRef: React.RefObject<any>

  navWillFocusSubscription?: NavigationEventSubscription

  navWillBlurSubscription?: NavigationEventSubscription

  // กดเพื่อเรียก Picker ของผู้รับ หรือ ผู้ส่ง
  abstract _pickAddress(key: 'sender' | 'receiver' | 'billing_address'): void
  // abstract onPressShippingTypePicker(): void

  abstract _handleErrMissingReceiverAddressRequresTel(name: string): void

  abstract _handleMissingTelReceiver(): Promise<void>

  abstract _handleMissingTelSender(): Promise<void>

  abstract _handleAfterSuccessFetchOrderDetail(): Promise<void>

  abstract _handleAfterSuccessConfirmOrder(res: any): Promise<void>

  abstract _handleOnOpenShippingLabelFromUrl(url: string, fileName: string): Promise<void>

  // abstract _copyLinkOrderToClipboard(res: any, showToast?: boolean)
  abstract _shareLinkOrderToSocial(res: any)

  abstract _openLinkOrderToWebView(res: any)

  // abstract _renderNavOrderTypeOne(): Promise<void>
  // abstract _renderNavOrderTypeTwo(): Promise<void>
  // abstract _settingCreateOrder(): void

  abstract _settingOrderView(): void

  // abstract navToPaymentListView(params: {
  //   store_id: number
  //   order_id: number
  //   order: Map<string, any>
  //   fromOrderDetail?: boolean
  // }): Promise<void> | void

  // abstract navToPaymentView(params: {
  //   orderId: string
  //   myStoreId: number
  //   orderStoreId: number
  //   orderType: number
  //   partnerName?: string
  //   partnerStoreId?: number
  //   fromPaymentList?: boolean
  //   fromOrderDetail?: boolean
  //   isCOD?: boolean
  //   isShipBeforePay?: boolean

  //   initialOrdersToBePaid: IOrdersToBePaid

  //   onSuccessPayment?: () => void
  // }): Promise<void> | void

  abstract navToShipView(params: { store_id: number; order_id: number }): Promise<void> | void

  abstract navToOrderShipList(params: { store_id: number; order_id: number }): Promise<void> | void

  // abstract navToAddProductListView(
  //   navParams: { store_id: number; ug_id?: number; pg_ids?: number[]; seller_store_id?: number },
  //   isMineProducts: boolean
  // ): Promise<void> | void
  // abstract navToQuickAddressView(params: {
  //   initAddress: IQuickAddress
  //   mode: string
  //   store_id: number
  //   shippingTypeIndex: number
  //   type: 'SENDER' | 'RECEIVER' | 'BILLING_ADDRESS'
  // }): Promise<void> | void

  // abstract navToOrderPrivateAndPublicNotes(params: {
  //   store_id: number
  //   mode: string
  //   orderTabIndex: number
  //   key: 'PUBLIC' | 'PRIVATE'
  //   notes: any
  // }): Promise<void> | void

  // FIXME: render method ที่ยังไม่รู้จะ share กับ web ยังไง
  // abstract _renderVolumeDiscountSummary(order: Map<string, any> | any): JSX.Element
  // abstract _renderXModelCODFeeCalculator(amountTotal: number): JSX.Element
  // abstract _renderButtonCameraUPCAndSKU(isStoreUseSKU: boolean, isStoreUseUPC: boolean): JSX.Element

  // only web usage
  abstract getInjectScrollableTabViewContentPropsStyle(): ViewStyle

  abstract navToStoreSettingAllPrintingShipping(): void

  // constructor(props) {
  //   super(props)
  //   // this._orderTabsRef = React.createRef()
  //   // this._receiverCardRef = React.createRef()
  //   // this._senderCardRef = React.createRef()
  // }

  async componentDidMount(): Promise<void> {
    await super.componentDidMount.apply(this)
    this._subscribeNavigationEvents()
  }

  componentWillUnmount() {
    super.componentWillUnmount.apply(this)
    this._unsubsribeNavigationEvents()
  }

  async componentDidUpdate(prevProps: IBaseOrderViewProps, prevState: IBaseOrderViewState): Promise<void> {
    await super.componentDidUpdate.apply(this, [prevProps, prevState])
  }

  navToOrderPrivateAndPublicNotes = (params: {
    store_id: number
    mode: string
    orderTabIndex: number
    key: 'PUBLIC' | 'PRIVATE'
    notes: any
  }) => {
    this.props.navigation.dispatch(NavActions.navToOrderPrivateAndPublicNotes(params))
  }

  navToPaymentView = (order: IOrderMap) => {
    // FOR LINE SHOPPEE :: https://app.clickup.com/t/860qqb9p7
    const mkp_id = order.has('mkp_id') ? order.get('mkp_id') : 0
    if (mkp_id === 4 && order.get('payment_state') === 114) {
      this._alertConfirmPaymentBtnForLineMkp()
      return
    }
    // แจ้งเตือนสำหรับ LINE SHIPPING payment เมื่อเข้าเงื่อนไขนี้ https://app.clickup.com/t/860qr49vc
    if (mkp_id === 4 && order.get('ship_before_pay') && order.get('shipping_state') !== 129 && order.get('payment_state') === 111) {
      this._alertConfirmPaymentBtnForLineMkpIfShipBeforePay()
      return
    }
    const { navigation } = this.props
    const orderId = order.get('id')
    const orderType = order.get('type')

    // const selectedStoreID = navigation.state.params.store_id || null
    const selectedStoreID = xUtil.getNavParam(this.props, 'store_id')
    const myStoreId = selectedStoreID

    const orderStoreId = order.get('store_id')
    const partnerName = order.get('partner_name')
    const partnerStoreId = order.get('partner_store_id')

    const isShipBeforePay = order.get('ship_before_pay') || false
    const isCOD = order.get('is_cod') || false
    const remainingForecastAmount = order.get('remaining_forecast_amount') || 0

    const orderBeforeConvert = order.toJS()
    const orderToBePaid = xUtil.converOrderDetialToOrderListItem(orderBeforeConvert)

    const isRefund = parseFloat(remainingForecastAmount as string) < 0
    orderToBePaid.t = remainingForecastAmount

    navigation.dispatch(
      NavActions.navToPaymentView({
        orderId,
        myStoreId,
        orderStoreId,
        orderType,
        partnerName,
        partnerStoreId,
        fromOrderDetail: true,
        isShipBeforePay,
        isCOD,
        isRefund,

        initialOrdersToBePaid: {
          // [orderId]: {
          //   remaining_forecast_amount: order.get('remaining_forecast_amount'),
          //   cod_amount: order.get('cod_amount'),
          // },
          [orderId]: orderToBePaid,
        },
        onPaymentSuccess: this._onPaymentSuccessCallback,
      })
    )
  }

  _onPaymentSuccessCallback = async () => {
    this._onRefresh()
    // try {
    //   const { navigation } = this.props
    //   // @ts-ignore
    //   navigation.pop()
    // } catch (err) {
    //   console.log('_onSuccessPaymentCallback err => ', err)
    // }
  }

  getPaymentListAction = () => {
    const order = this._getFocusedOrder()
    // const { navigation, initOrdersToBePaid } = this.props
    // const selectedStoreID = navigation.state.params.store_id || null
    // const orderId = order.get('id')

    // MKP Order ไม่ให้กดชำระเองได้ : https://app.clickup.com/t/860pwxhvh
    if (xUtil.isMarketPlacesOrder(order)) {
      return undefined
    }

    const paymentState = order.get('payment_state')
    const orderType = order.get('type')
    let statusPayBtn = xCONS.ORDER_OPERATION_AVAILABILITY.PAY[`_${orderType}${paymentState}`] || 0
    const remainingAmt = parseFloat(order.get('remaining_forecast_amount'))
    if (remainingAmt > 0 && orderType === 3) {
      statusPayBtn = 1 // แจ้งชำระ
    }

    if (
      _.includes(
        [
          xCONS.ORDER_OPERATION_AVAILABILITY.PAY.DISABLED,
          xCONS.ORDER_OPERATION_AVAILABILITY.PAY.CONFIRM_GETTING_PAID,
          xCONS.ORDER_OPERATION_AVAILABILITY.PAY.CONFIRM_GETTING_REFUND,
        ],
        statusPayBtn
      )
    ) {
      // return {}
      return undefined
    }

    // // FIXME: ตอนนี้เป็นเลข forecast ก่อน Nav ไปที่ PaymentList จะถูกต้องแค่การจ่ายเงินครั้งแรก
    // // initOrdersToBePaid(orderId, order.get('remaining_forecast_amount'))
    // initOrdersToBePaid({ orderId, remaining_forecast_amount: order.get('remaining_forecast_amount'), cod_amount: order.get('cod_amount') })
    // const isShipBeforePay = order.get('ship_before_pay') || false
    // const isCOD = order.get('is_cod') || false

    return {
      // text: p.op.t(`Order.OrderPayButtonText._${statusPayBtn}`),
      onPress: () => this.navToPaymentView(order as IOrderMap),
    }
  }

  _alertConfirmPaymentBtnForLineMkp = () => {
    p.op.showConfirmationOkOnly('', 'กรุณาไปที่ LINE SHOPPING Seller เพื่อยืนยันรับชำระ')
  }

  _alertConfirmPaymentBtnForLineMkpIfShipBeforePay = () => {
    p.op.showConfirmationOkOnly('', 'กรุณาบันทึกการจัดส่งก่อน ก่อนที่จะบันทึกรับชำระจากลูกค้า')
  }

  navToPaymentListView = async (params: IPaymentListViewNavParams) => {
    // FOR LINE SHIPPING :: https://app.clickup.com/t/860qqb9p7
    const order = this._getFocusedOrder()
    const mkp_id = order.has('mkp_id') ? order.get('mkp_id') : 0
    if (mkp_id === 4 && order.get('payment_state') === 114) {
      this._alertConfirmPaymentBtnForLineMkp()
      return
    }
    // แจ้งเตือนสำหรับ LINE SHIPPING payment เมื่อเข้าเงื่อนไขนี้ https://app.clickup.com/t/860qr49vc
    if (mkp_id === 4 && order.get('ship_before_pay') && order.get('shipping_state') !== 129 && order.get('payment_state') === 111) {
      this._alertConfirmPaymentBtnForLineMkpIfShipBeforePay()
      return
    }
    const { navigation } = this.props
    const selectedStoreID = xUtil.getNavParam(this.props, 'store_id')
    const isFromPaymentListView = xUtil.getNavParam(this.props, 'fromPaymentListView', false)
    // if (p.op.isWeb()) {
    //   navigation.popToTop()
    //   await delay(200)
    // }

    if (isFromPaymentListView) {
      const isUserConfirm = await p.op.isUserConfirm('กลับไปที่รายการรับชำระ', 'ยืนยันว่าจะกลับไปรายการรับชำระหรือไม่?')
      if (!isUserConfirm) {
        return
      }
      this._goBack()
      return
    }

    navigation.dispatch(
      NavActions.navToPaymentListView({
        store_id: selectedStoreID,
        fromOrderDetail: true,
        onSuccessAcceptOrRejectPayment: p.op.isWeb() ? this._onRefresh : undefined,
        btnRight: this.getPaymentListAction(),
        ...params,
      })
    )
  }

  _handleErrMissingPostalCode = (addrKey: string, quickAddress: IQuickAddress) => {
    p.op.alert('กรุณาระบุรหัสไปรษณีย์ผู้รับ', null, () => {
      this.navToQuickAddressView(addrKey, quickAddress)
    })
  }

  _handleErrMissingTelephone = (addrKey: string, quickAddress: IQuickAddress) => {
    p.op.alert('กรุณาระบุโทรศัพท์ผู้รับ', null, () => {
      this.navToQuickAddressView(addrKey, quickAddress)
    })
  }

  navToQuickAddressView = async (addrKey: string, initAddress: IQuickAddress) => {
    const { navigation } = this.props
    const thisOrder = this._getFocusedOrder()
    const shipping_type_id = thisOrder.get('shipping_type_id')
    const _onSubmit = async (newAddr: IQuickAddress) => {
      const isSuccess = await this._onSaveQuickAddress(addrKey, newAddr)
      return { isGoBack: isSuccess }
    }
    const navParams: IAddressFormViewNavParams = {
      // headerTitle: 'แก้ไขที่อยู่',
      submitButtonText: 'บันทึก',
      initAddress,
      overrideConfig: {},
      onSubmit: _onSubmit,
    }

    // หากอยู่ในกลุ่มรูปแบบจัดส่งที่ต้องการข้อมูลครบ ต้องระบุให้ครบ
    if (addrKey !== 'billing_address' && _.includes(xCONS.TEL_NUM_REQUIRED_FOR_SHIPPING_TYPE_IDS, shipping_type_id)) {
      navParams.overrideConfig = { ...navParams.overrideConfig, telephone: { required: true } }
    }

    if (addrKey === 'receiver') {
      navParams.type = 'quick_receiver'
      navParams.headerTitle = 'แก้ไขที่อยู่ผู้รับ'

      if (thisOrder.has(`x`)) {
        await this._warningChangeReceiverAddressHasXShipping(
          'คุณเปลี่ยนที่อยู่',
          'ระบบจะทำการยกเลิกเลขติดตามพัสดุเดิม และขอใหม่ เพื่อให้ที่อยู่ผู้รับเปลี่ยนตามที่แก้ไขไว้ หากระบบขอใหม่ไม่สำเร็จ กรุณากดยกเลิกเลขติดตามพัสดุและขอใหม่เอง'
        )
      }
    }

    // if (addrKey === 'sender') {
    //   navParams.type = 'quick_receiver'
    //   navParams.headerTitle = 'แก้ไขที่อยู่ผู้รับ'
    // }

    if (addrKey === 'billing_address') {
      navParams.type = 'quick_billing'
      navParams.headerTitle = 'แก้ไขที่อยู่ใบกํากับภาษี'
    }

    navigation.dispatch(NavActions.navToAddressFormView(navParams))
  }

  _onSaveQuickAddress = async (addrKey: string, addr: IQuickAddress): Promise<boolean> => {
    const { mode, customCreateOrder } = this.state
    // const { CREATE, VIEW_EDIT } = CONS.ORDER_VIEW_MODE
    const { editingOrder, onChangeAddress, editReceiverAddress, selectedStore } = this.props
    const selectedStoreID = selectedStore.get('id')
    const thisOrder = this._getFocusedOrder()

    const { postal_code, name, address3, telephone } = addr

    /// // START ตรวจสอบที่อยู่ผู้รับซ้ำ สำหรับ VIEW EDIT MODE เท่านั้น /////
    const checkDupReceiverAddr = _.has(customCreateOrder, 'check_dup_receiver_addr') ? customCreateOrder.check_dup_receiver_addr : true
    // console.log('checkDupReceiverAddr \\ => ', checkDupReceiverAddr)
    let comfirmChangeReceiverAddress = true
    if (checkDupReceiverAddr) {
      if (addrKey === 'receiver' && mode === VIEW_EDIT) {
        const order_id = editingOrder.get('id')
        try {
          const reqBody = {
            store_id: selectedStoreID,
            postal_code,
            name,
            telephone,
            within_days: 31,
            exclude_order_id: order_id,
          }
          const res: { order_address: IAddressList[] } = await api.postV2(api.POST_FIND_DUP_BY_RECEIVER_ADDRESS, reqBody)
          // console.log('_findDupByReceiverAddr => ', res)

          if (res.order_address && res.order_address.length > 0) {
            if (thisOrder.has(`x`)) {
              this._afterNavToQuickAddressViewsuccessCallback()
            }

            await xUtil.setStatePromise(this, {
              addressesListOverlay: res.order_address,
              // isVisibleAddressesListOverlay: true,
            })

            await new Promise((resovleComfirm) => {
              // const oldTime = xUtil.getTimeFormatYYYYMMDDhhmm(selectedDateRangeSetUpDateDeliveryOrder)
              // const newTime = xUtil.getTimeFormatYYYYMMDDhhmm(newDate)
              p.op.showConfirmationThreeButtons(
                'พบที่อยู่ผู้รับซ้ำ!',
                'พบที่อยู่ผู้รับซ้ำกับออเดอร์ที่สร้างภายใน 30 วันที่ผ่านมา',
                'ยืนยันการเปลี่ยนที่อยู่ผู้รับ',
                () => {
                  // @ts-ignore
                  resovleComfirm()
                },
                'ดูออเดอร์ที่มีที่อยู่ผู้รับซ้ำ',
                () => {
                  // @ts-ignore
                  resovleComfirm()
                  comfirmChangeReceiverAddress = false
                  xUtil.setStatePromise(this, {
                    isVisibleAddressesListOverlay: true,
                  })
                },
                'ปิดหน้าต่างนี้',
                () => {
                  // @ts-ignore
                  resovleComfirm()
                  comfirmChangeReceiverAddress = false
                }
              )
            })
          } else {
            await xUtil.setStatePromise(this, {
              addressesListOverlay: null,
            })
          }
        } catch (err) {
          console.log('_findDupByReceiverAddr => ', err)
        }
      }
    }

    if (!comfirmChangeReceiverAddress) {
      // this.inProcess = false
      return false
    }
    /// // END ตรวจสอบที่อยู่ผู้รับซ้ำ /////

    // if (shippingTypeIndex < 6 && shippingTypeIndex != -1) {
    //   if (telephone === null || telephone === '') {
    //     p.op.alert('คำเตือน', 'กรุณาระบุ เบอร์โทรศัพท์')
    //     return
    //   }

    //   if (telephone && telephone.length < 9) {
    //     p.op.alert('รูปแบบไม่ถูกต้อง', 'กรุณาระบุ เบอร์โทรศัพท์ ในรูปแบบที่ถูกต้องซึ่งมีเลขหมายมากกว่าหรือเท่ากับ 9 หลัก')
    //     return
    //   }
    // }
    // if (telephone && !telephone.match(regPhone)) {
    //   p.op.alert('รูปแบบไม่ถูกต้อง', 'กรุณาระบุ เบอร์โทรศัพท์ ในรูปแบบที่ถูกต้อง')
    //   return
    // }
    const order_id = editingOrder.get('id')

    // let addrKey = 'receiver'
    // if (type === 'SENDER') {
    //   addrKey = 'sender'
    // }
    // if (type === 'BILLING_ADDRESS') {
    //   addrKey = 'billing_address'
    // }
    const newAddr = { ...addr }
    delete newAddr.id

    if (mode === CREATE) {
      const changedAddr = {}
      changedAddr[addrKey] = newAddr
      onChangeAddress(changedAddr)
      this._onReceiveNewAddress(addrKey, newAddr)
      // xUtil.navGoBack(this.props)
      // } else if (mode === VIEW_EDIT) {
    } else {
      let type = 'RECEIVER'
      if (addrKey === 'sender') {
        type = 'SENDER'
      }
      if (addrKey === 'billing_address') {
        type = 'BILLING_ADDRESS'
      }

      if (selectedStoreID && order_id && type) {
        const reqBody = {
          store_id: selectedStoreID,
          order_id,
          new_address: [newAddr],
          type,
        }

        if (type === 'BILLING_ADDRESS') {
          // @ts-ignore
          reqBody.type = 'BILLING'
        }

        const isSuccess = await new Promise<boolean>((resolve) => {
          editReceiverAddress({
            body: reqBody,
            // successCallback: () => {
            //   // @ts-ignore
            //   const callback = xUtil.getNavParam(this.props, 'callBack')
            //   xUtil.navGoBack(this.props)
            //   if (_.isFunction(callback)) {
            //     callback()
            //   }
            // },
            successCallback: () => resolve(true),
            failedCallback: () => resolve(false),
          })
        })

        if (!isSuccess) {
          return false
        }
      } else {
        p.op.alert('ไม่พบร้านค้า', 'กรุณากลับไปเลือกร้านค้าแล้วลองใหม่อีกครั้ง ขออภัยในความไม่สะดวก')
        // xUtil.navGoBack(this.props)
        return false
      }
    }

    return true
  }

  _renderNavOrderTypeOne = async () => {
    let newMode = this.state.mode
    const focusedOrder = this._getFocusedOrder()
    const orderType = focusedOrder.get('type')
    const isLockedOrder = focusedOrder.get('prevent_mod') || false
    // const canPrintShippingLabel = this._canPrintShippingLabel(focusedOrder)
    const pending_shp_lbl_img = focusedOrder.get('pending_shp_lbl_img')
    if (orderType === 1 || orderType === 2) {
      if (!xAcl.canDoAtSelectedStore(xCONS.PERM_STORE_HELPER.ORDER_EDIT) || isLockedOrder) {
        newMode = 'READ_ONLY_MODE'
      }
    } else if (orderType === 3) {
      if (isLockedOrder) {
        newMode = 'READ_ONLY_ORDER_LOCK_MODE'
      }
    }
    this.props.navigation.dispatch(
      NavActions.navToAddCoverSheet({
        store_id: this.state.store_id,
        mode: newMode,
        orderTabIndex: this.state.orderTabIndex,
        pending_shp_lbl_img,
        onSuccessEditNoteCallback: () => this.setState({ orderTabsVisibility: false }),
      })
    )
  }

  _renderNavOrderTypeTwo = async () => {
    let newMode = this.state.mode
    const focusedOrder = this._getFocusedOrder()
    const orderType = focusedOrder.get('type')
    const isLockedOrder = focusedOrder.get('prevent_mod') || false
    // const canPrintShippingLabel = this._canPrintShippingLabel(focusedOrder)
    const pending_shp_lbl_note = focusedOrder.get('pending_shp_lbl_note')
    if (orderType === 1 || orderType === 2) {
      if (!xAcl.canDoAtSelectedStore(xCONS.PERM_STORE_HELPER.ORDER_EDIT) || isLockedOrder) {
        newMode = 'READ_ONLY_MODE'
      }
    } else if (orderType === 3) {
      if (isLockedOrder) {
        newMode = 'READ_ONLY_ORDER_LOCK_MODE'
      }
    }
    this.props.navigation.dispatch(
      NavActions.navToAddCoverSheetNote({
        store_id: this.state.store_id,
        mode: newMode,
        orderTabIndex: this.state.orderTabIndex,
        pending_shp_lbl_note,
        onSuccessEditNoteCallback: () => this.setState({ orderTabsVisibility: false }),
      })
    )
  }

  _copyLinkOrderToClipboard = (shippingLink: string, showToast?: boolean) => {
    if (_.isNil(this.state.copyLinkOrder)) {
      this.setState({ copyLinkOrder: shippingLink })
    }
    this.inProcess = false
    const order = this._getFocusedOrder()
    const orderType = order.get('type')
    p.op.aLogEventWithUserId(xCONS.EVENT_NAME.ORDER_PUBLIC_LINK_COPY, { orderType })
    Clipboard.setString(shippingLink)
    if (!_.isNil(showToast) || showToast) {
      p.op.showToast(`คัดลอกลิงก์ ${shippingLink} เรียบร้อยแล้ว`, 'success')
    }
  }

  _copyOrderLinkWithDetailToClipboard = async (shippingLink: string, txtClipboard: string) => {
    if (_.isNil(this.state.copyLinkOrder)) {
      await setStatePromise(this, { copyLinkOrder: shippingLink })
    }
    Clipboard.setString(txtClipboard)
    p.op.showToast(`คัดลอกรายละเอียดออเดอร์ต่อไปนี้เรียบร้อยแล้ว\n\n${txtClipboard}`, 'success')
  }

  // async _consturctorExtended(that: any, props: IBaseOrderViewProps): Promise<void> {}

  // Abstract method
  // @ts-ignore
  _getParams = () => {
    const params = xUtil.getNavParams(this.props)
    return params
  }

  beginSubmitting = async (): Promise<void> => {
    this.inProcess = true
    await setStatePromise(this, { submitting: true })
    await new Promise((stateDelay) => setTimeout(stateDelay, 50))
  }

  endSubmitting = async (): Promise<void> => {
    await setStatePromise(this, { submitting: false })
    await new Promise((stateDelay) => setTimeout(stateDelay, 50))
    this.inProcess = false
  }

  _subscribeNavigationEvents = () => {
    const { navigation } = this.props
    this.navWillFocusSubscription = navigation.addListener('focus', this._setDirtyHandler)
    this.navWillBlurSubscription = navigation.addListener('blur', this._unsetBackHandler)
  }

  _unsubsribeNavigationEvents = () => {
    if (this.navWillFocusSubscription) {
      this.navWillFocusSubscription()
    }
    if (this.navWillBlurSubscription) {
      this.navWillBlurSubscription()
    }
  }

  _setDirtyHandler = () => {
    if (this.props.isDirty) {
      // แจ้งว่าข้อมูล dirty
      // @ts-ignore จริงๆ bind method type ควรเป็น callback ธรรมดา แต่เราใช้ async
      this._backHandler = BackHandler.addEventListener('hardwareBackPress', this._onPressBackWhileDirtyData)
    }
  }

  _unsetBackHandler = () => {
    if (this._backHandler) {
      this._backHandler.remove()
    }
  }

  // _handleDataIsDirty = () => {
  //   if (_.isNil(this._backHandler)) {
  //     this._backHandler = BackHandler.addEventListener('hardwareBackPress', this._onPressBackWhileDirtyData)
  //   }
  // }

  _onPressBackWhileDirtyData = async () => {
    const { navigation } = this.props
    if (!navigation.isFocused()) {
      // ถ้ามี Android PressBack มา แต่ OrderView ไม่ถูก focus อยู่ เช่นไปหน้า ProductView
      this.inProcess = true
      // @ts-ignore บังคับ pop view ออกไปเฉยๆ
      navigation.pop(1)
      await delay(500)
      return true
    }
    await this._goBack()
    // this.goBack() // works best when the goBack is async
    return true
  }

  // async _forceNativeChangeTabByProgrammatically(): Promise<void> {
  //   if (this._orderTabsRef && _.isFunction(this._orderTabsRef.goToPage)) {
  //     // log(this, '_forceNativeChangeTabByProgrammatically this._orderTabsRef => ', this._orderTabsRef)
  //     if (this._orderTabsRef.state.currentPage !== this.state.orderTabIndex) {
  //       this._orderTabsRef.goToPage(this.state.orderTabIndex)
  //     }
  //     await new Promise(delay => setTimeout(delay, 10))
  //   }
  // }

  // async _componentDidUpdateExtended(prevProps: IBaseOrderViewProps, prevState: IBaseOrderViewState): Promise<void> {
  //   // FIXME: Check me again (These logic is quick refactor from P`Keng logic in componentWillReceiveProps)
  //   // Nav to shipping list view if the "m" (nested nav mode) equals to "s"
  //   // log('Check for nested nav')
  //   const { navigation, editingOrder, pp_shippingTypeIndex } = this.props
  //   const { order_id, store_id, m = false, lastShippingCost = null } = this.state
  //   const nestedNavMode = m
  //   // util.log(nestedNavMode)
  //   if (nestedNavMode) {
  //     if (nestedNavMode === 's') {
  //       // log('trying to nav to ship list')
  //       navigation.setParams({ m: null }) // reset it or it'll be infinite nav back and forth
  //       navigation.dispatch(NavActions.navToOrderShipList({ store_id, order_id }))
  //     }
  //   }

  //   // reset calculated shipping cost if presented, and either shipping type or products is changed
  //   // TODO: handle both create and non-create mode (right now only handle create mode)
  //   if (lastShippingCost) {
  //     if (
  //       prevProps.editingOrder &&
  //       editingOrder &&
  //       (prevProps.pp_shippingTypeIndex !== pp_shippingTypeIndex ||
  //         prevProps.editingOrder.get('store_products') !== editingOrder.get('store_products'))
  //     ) {
  //       this.setState({ lastShippingCost: null })
  //     }
  //   }

  //   if (prevState.refreshing && !this.state.refreshing && this._orderTabsRef && _.isFunction(this._orderTabsRef.goToPage)) {
  //     // Make sure after refresh order tab should go to order's page
  //     this._orderTabsRef.goToPage(this.state.orderTabIndex)
  //   }
  // }

  // _setParams(params: { [key: string]: any }, callback?: () => void): void {
  //   const { navigation } = this.props
  //   navigation.setParams(params)
  //   if (_.isFunction(callback)) {
  //     callback()
  //   }
  // }

  // _copyLinkOrderToClipboard(shipping_link, showToast?: boolean) {
  //   // override in app
  // }

  // _shareLinkOrderToSocial(shipping_link) {
  //   // override in app
  // }

  // _openLinkOrderToWebView(shipping_link) {
  //   // override in app
  // }

  // async _renderNavOrderTypeOne() {
  //   // override in app
  // }

  // async _renderNavOrderTypeTwo() {
  //   // override in app
  // }

  // _settingCreateOrder = () => {
  //   // override in app
  // }

  // _settingOrderView = () => {
  //   // override in app
  // }

  _cleanupForUnmount = () => {
    if (this._backHandler && _.isFunction(this._backHandler.remove)) {
      this._backHandler.remove()
    }
    // flowDone(this.flowName) // IMPORTANT as the other flowDone call on componentDidUpdate might not get called if user switch too quickly
    this.props.pickerClear()
  }

  // _handleErrMissingShippingType() {
  //   // Override in app

  //   // Alert.alert('กรุณาเลือกรูปแบบจัดส่ง', null, [
  //   //   {
  //   //     text: 'ตกลง',
  //   //     onPress: () => {
  //   //       this.props.navigation.dispatch(
  //   //         NavActions.navToPagePicker({
  //   //           key: 'shippingType',
  //   //           label: p.op.t('Order.shippingType'),
  //   //           title: `${p.op.t('choose')}${p.op.t('Order.shippingType')}`,
  //   //           items: this.shippingTypeItems,
  //   //           viewType: xCONS.PAGEPICKER_VIEWTYPE.ICON,
  //   //         }),
  //   //       )
  //   //     },
  //   //   },
  //   // ])
  // }

  // _handleErrMissingReceiverAddress() {
  //   // Override me in app

  //   // p.op.showConfirmation(
  //   //   'กรุณาระบุที่อยู่ผู้รับสินค้า',
  //   //   'หากกด"ระบุภายหลัง"ออเดอร์จะถูกสร้างทันที คุณสามารถระบุที่อยู่ในภายหลังได้',
  //   //   () => this._createOrder(true),
  //   //   () => this._renderReceiverActionSheet(),
  //   //   'ระบุภายหลัง',
  //   //   'ระบุตอนนี้',
  //   // )
  // }

  // _handleErrMissingReceiverAddressRequresTel = (name: string) => {
  //   // Override me in app

  //   // const { rAddr } = this.props
  //   // let err = ''
  //   // const name0 = _.isNil(rAddr.get('name')) ? true : false
  //   // const address = _.isNil(rAddr.get('address1')) && _.isNil(rAddr.get('address2')) && _.isNil(rAddr.get('address3')) ? true : false
  //   // const postalCode = _.isNil(rAddr.get('postal_code')) ? true : false
  //   // const phon = _.isNil(rAddr.get('telephone')) ? true : false
  //   // if (name0) {
  //   //   err = 'ชื่อ'
  //   // } else if (address) {
  //   //   err = 'ที่อยู่'
  //   // } else if (postalCode) {
  //   //   err = 'รหัสไปรษณีย์'
  //   // } else if (phon) {
  //   //   err = 'เบอร์โทร'
  //   // }
  //   // p.op.showConfirmation(
  //   //   `กรุณาระบุ ${err} ผู้รับสินค้า`,
  //   //   'รูปแบบขนส่งที่คุณเลือก ต้องการชื่อ ที่อยู่และเบอร์โทรศัพท์ หากคุณต้องการระบุภายหลังในกด "ระบุภายหลัง"',
  //   //   () => this._createOrder(true),
  //   //   () => {
  //   //     const { customCreateOrder } = this.state
  //   //     // const receiver = customCreateOrder.receiver
  //   //     const { onChangeAddress } = this.props
  //   //     onChangeAddress({
  //   //       receiver: {
  //   //         name: _.isNil(name) ? '' : name,
  //   //       },
  //   //     })
  //   //     const newCustomCreateOrder = customCreateOrder
  //   //     const newReceiver = {} as any
  //   //     newReceiver.v = customCreateOrder.receiver.v
  //   //     newReceiver.t = 2
  //   //     newReceiver.a = 0
  //   //     newCustomCreateOrder.receiver = newReceiver
  //   //     xUtil.setStatePromise(this, {
  //   //       customCreateOrder: newCustomCreateOrder,
  //   //       txtReceiverName: _.isNil(name) ? rAddr.get('name') || '' : name,
  //   //     })
  //   //     this._renderReceiverActionSheet()
  //   //   },
  //   //   'ระบุภายหลัง',
  //   //   'ระบุตอนนี้',
  //   // )
  // }

  _handleErrNotEnoughQty = (productName, storeId, productIdx, availableQty) => {
    p.op.alert('สินค้าในคลังไม่เพียงพอ', `กรุณาปรับจำนวนสินค้า ${productName} (สินค้าในคลังเหลือ ${availableQty} ชิ้น)`)
    // Alert.alert(
    //   'สินค้าในคลังไม่เพียงพอ',
    //   `กรุณาปรับจำนวนสินค้า ${productName} (สินค้าในคลังเหลือ ${availableQty} ชิ้น)`,
    //   // Alert.alert('สินค้าในคลังไม่เพียงพอ', `กรุณาปรับจำนวนสินค้า ${display_name} ให้อยู่ในช่วงจำนวนสินค้าที่เหลือในคลัง (สินค้าในคลังเหลือ ${available_qty} ชิ้น)`,
    //   [
    //     {
    //       text: 'ตกลง',
    //       onPress: () => {
    //         // util.focusKeyRefIfExisted(this.inputRefs, `mProduct_qty_${storeId}_${productIdx}`, this.scrollViewRef)
    //       },
    //     },
    //   ],
    // )
  }

  _handleErrHasNullProductQty = (productName, storeId, productIdx, availableQty) => {
    let txtWarningMessage = `กรุณาระบุจำนวนสินค้าของ ${productName}`
    if (_.isNumber(availableQty)) {
      txtWarningMessage += `(สินค้าในคลังเหลือ ${availableQty} ชิ้น)`
    }
    p.op.alert('ระบุข้อมูลไม่ครบถ้วน', txtWarningMessage)
    // Alert.alert('ระบุข้อมูลไม่ครบถ้วน', txtWarningMessage, [
    //   {
    //     text: 'ตกลง',
    //     onPress: () => {
    //       // util.focusKeyRefIfExisted(this.inputRefs, `mProduct_qty_${storeId}_${productIdx}`, this.scrollViewRef)
    //     },
    //   },
    // ])
  }

  _handleErrProductQtyIsZero = (productName, productIdx, availableQty) => {
    p.op.alert('มีสินค้าที่ระบุขายจำนวน 0 ชิ้น', `กรุณาลบ หรือปรับจำนวนสินค้า ${productName} (สินค้าในคลังเหลือ ${availableQty} ชิ้น)`)
    // Alert.alert(
    //   'มีสินค้าที่ระบุขายจำนวน 0 ชิ้น',
    //   `กรุณาลบ หรือปรับจำนวนสินค้า ${productName} (สินค้าในคลังเหลือ ${availableQty} ชิ้น)`,
    //   // Alert.alert('สินค้าในคลังไม่เพียงพอ', `กรุณาปรับจำนวนสินค้า ${display_name} ให้อยู่ในช่วงจำนวนสินค้าที่เหลือในคลัง (สินค้าในคลังเหลือ ${available_qty} ชิ้น)`,
    //   [
    //     {
    //       text: 'ตกลง',
    //       onPress: () => {
    //         // util.focusKeyRefIfExisted(this.inputRefs, `mProduct_qty_0_${productIdx}`, this.scrollViewRef)
    //       },
    //     },
    //   ],
    // )
  }

  _handleErrMissingProductId = () => {
    const { navigation } = this.props
    p.op.alert('เกิดข้อผิดพลาด', 'ไม่พบรหัสของสินค้า กรุณากลับไปสร้างออเดอร์และเลือกสินค้าใหม่ ขออภัยในความไม่สะดวก')
    xUtil.navGoBack(this.props)
  }

  // _popChildOut = () => {
  //   const { navigation } = this.props
  //   // @ts-ignore
  //   // const childNav = navigation.getChildNavigation()
  //   // const childNav = navigation.getChildNavigation(navigation.state.key)
  //   // console.log('OrderView:: childNav => ', childNav)
  //   console.log('OrderView:: navigation.isFocused => ', navigation.isFocused())
  //
  //   // if (childNav && _.isFunction(childNav.goBack)) {
  //   if (navigation && _.isFunction(navigation.isFocused) && !navigation.isFocused()) {
  //     // childNav.goBack(childNav.state.key)
  //     // @ts-ignore
  //     navigation.pop(1)
  //   }
  // }

  // FIXME res should be IOrder interface
  _handleAfterSuccessCreateOrder = async (res: any): Promise<void> => {
    // log('OrderView:: _handleAfterSuccessCreateOrder res => ', res)

    if (res.order && res.order.id) {
      if (p.op.isWeb()) {
        // this.props.navigation.goBack('ProductList')
        // xUtil.navGoBack(this.props, 'ProductList')
        xUtil.navPopToRouteName(this.props, 'OrderView')
        await delay(200)
      }

      const { order } = res
      // let order_selected_sender_address_id = null
      // let order_selected_txtSenderName = null
      // await this._changeModeTo(VIEW_EDIT)
      await setStatePromise(this, { mode: VIEW_EDIT, order_id: order.id })

      // const defaultObj = await p.op.getAppDefaultObject()
      // // log('OrderView:: _handleAfterSuccessCreateOrder defaultObj => ', defaultObj)
      //
      // let newDefaultObj = _.cloneDeep(defaultObj)
      // if (order.sender && order.sender.id) {
      //   // Save Local Value
      //   // log('### Save Local Value order_selected_sender_address_id => ', order.sender.id)
      //   // p.op.setAppDefaultValue('order_selected_sender_address_id', order.sender.id.toString())
      //   newDefaultObj.order_selected_sender_address_id = order.sender.id
      // } else {
      //   newDefaultObj.order_selected_sender_address_id = null
      // }
      //
      // // Save Local Value
      // if (order.sender_name) {
      //   // log('### Save Local Value order_selected_txtSenderName => ', order.sender_name)
      //   // p.op.setAppDefaultValue('order_selected_txtSenderName', order.sender_name)
      //   newDefaultObj.order_selected_txtSenderName = order.sender_name
      // }
      //
      // // const { ORDER_OPENED_COUNT, ORDER_OPENED_NEXT_TRIGGER } = xCONS.DEFAULT_KEYS
      // // if (ORDER_OPENED_COUNT in defaultObj && ORDER_OPENED_NEXT_TRIGGER in defaultObj) {
      // //   const currOrderCount = parseInt(defaultObj[ORDER_OPENED_COUNT])
      // //   const nextAskCount = parseInt(defaultObj[ORDER_OPENED_NEXT_TRIGGER])
      // //
      // //   newDefaultObj[ORDER_OPENED_COUNT] = currOrderCount + 1
      // //   if (currOrderCount >= nextAskCount && _.isFunction(appGlobalMethods.askRateApp)) {
      // //     newDefaultObj[ORDER_OPENED_NEXT_TRIGGER] = nextAskCount + (nextAskCount * 2)
      // //     const txtTitle = p.op.t('RateAsk.title')
      // //     const txtMessage = p.op.t('RateAsk.message')
      // //     const txtRate = p.op.t('RateAsk.rate')
      // //     const txtLater = p.op.t('RateAsk.later')
      // //     const txtNever = p.op.t('RateAsk.never')
      // //     p.op.showConfirmationThreeButtons(
      // //       txtTitle,
      // //       txtMessage,
      // //       txtRate, () => appGlobalMethods.askRateApp(),
      // //       txtLater, null,
      // //       txtNever, null
      // //     )
      // //   }
      // // } else {
      // //   newDefaultObj[ORDER_OPENED_COUNT] = 1
      // //   newDefaultObj[ORDER_OPENED_NEXT_TRIGGER] = xCONS.RATE_ORDER_COUNT_BEGIN
      // // }
      //
      // // log('OrderView:: _handleAfterSuccessCreateOrder newDefaultObj => ', newDefaultObj)
      //
      // log('newDefaultObj => ', newDefaultObj)
      // await p.op.setAppDefaultObject(newDefaultObj)
      // console.log('_handleAfterSuccessCreateOrder => ', order)
      await this._autoSetOrderTabIndex()
      if (order.type === 1 && !(await this._skipOrderDetailAutoCopy())) {
        let setInProcess = false
        if (this.inProcess) {
          this.inProcess = false
          setInProcess = true
        }
        // this.inProcess = false เพราะว่า createSaleOrderLinkWithDetail ต้องการ
        await this.createSaleOrderLinkWithDetail(fromJS(order))
        if (setInProcess) {
          this.inProcess = true
        }
      }
      this._setDateTimeDateDeliverViewEditMode()
      this._setDateTimeAutoCancelOrder()
      // setTimeout(this._askForRateAfterOpenOrder, 1000)
      setTimeout(this._handleAskRateOverrideAdapter, 1000)
    }
  }

  _handleAskRateOverrideAdapter = () => {
    // do nothing for override in app
  }

  // _handleOnTabChanged = async (): Promise<void> => {
  //   await delay(50)

  //   const orderTabsRef = this._orderTabsRef.current
  //   if (orderTabsRef && _.isFunction(orderTabsRef.goToPage)) {
  //     // ตัว ScrollableTabView state.currentPage ถูกใช้ใน iOS แต่ใน android กลับใช้ props.page ???
  //     // O: ปัญหาของ iOS คือ currentPage ไม่ได้เริ่มที่ 0 แต่เริ่มต้นที่ 1 ไม่เหมือนกับ arrayIndex [Updated 11-02-2020]
  //     const currentPageIndex = p.op.isIOS() ? orderTabsRef.state.currentPage - 1 : orderTabsRef.state.currentPage
  //     if (currentPageIndex !== this.state.orderTabIndex) {
  //       // if (orderTabsRef.props.page !== this.state.orderTabIndex) {
  //       orderTabsRef.goToPage(this.state.orderTabIndex)
  //     }
  //   }
  // }

  // // Cannot use arrow function on this method ??
  // async _editProductFromBuyOrderFlow(): Promise<void> {
  //   if (this.inProcess) {
  //     return
  //   }
  //   this.inProcess = true
  //   const focusedOrder = this._getFocusedOrder()
  //   const orderParentId = Map.isMap(focusedOrder) ? focusedOrder.get('parent_id') : null
  //   const orderParentName = Map.isMap(focusedOrder) ? focusedOrder.get('parent_name') : null
  //   const orderPreventMod = Map.isMap(focusedOrder) ? focusedOrder.get('prevent_mod') : false
  //   const orderType = Map.isMap(focusedOrder) ? focusedOrder.get('type') : 0
  //
  //   // log(this, '_editProductFromBuyOrderFlow focusedOrder.toJS() => ', focusedOrder.toJS())
  //   if (this._isLockedOrderShowAlert(focusedOrder)) {
  //     this.inProcess = false
  //     return
  //   }
  //
  //   if (!orderParentId || orderType !== 3) {
  //     this.inProcess = false
  //     return
  //   }
  //
  //   if (orderPreventMod) {
  //     p.op.alert('ไม่สามารถแก้ไขรายการสินค้า', 'เนื่องจากผู้ขายส่งทำการล็อกออเดอร์แล้ว\n\nหากต้องการทำการแก้ไขกรุณาติดต่อผู้ขายส่ง')
  //     this.inProcess = false
  //     return
  //   }
  //
  //   const isUserConfirmed = await new Promise<boolean>(passConfirm => {
  //     p.op.showConfirmation(
  //       'แก้ไขรายการสินค้า',
  //       `ระบบจะทำการเปิดออเดอร์ขาย #${orderParentId} เพื่อทำการแก้ไขรายการสินค้า\nคุณต้องการดำเนินการ ใช่หรือไม่`,
  //       () => passConfirm(true),
  //       () => passConfirm(false),
  //       'ใช่',
  //       'ไม่ใช่',
  //     )
  //   })
  //
  //   if (!isUserConfirmed) {
  //     this.inProcess = false
  //     return
  //   }
  //
  //   await this._handleOnPressParentOrderId(orderParentId)
  //   await new Promise(transitionDelay => setTimeout(transitionDelay, 500))
  //   let isLoadedOrder = false
  //   // log(this, '_editProductFromBuyOrderFlow after changedTab isLoadedOrder => ', JSON.stringify(isLoadedOrder))
  //   for (let i = 1; i <= 6; i++) {
  //     isLoadedOrder = !!this.props.editingOrder.get('updated_at')
  //     if (!isLoadedOrder) {
  //       // not necessary because onRefresh is real synchronous now
  //       // log(this, 'Waiting for 3 times:: wait 500ms i => ', i)
  //       await new Promise(delayForLoadOrder => setTimeout(delayForLoadOrder, 500))
  //     } else {
  //       break
  //     }
  //   }
  //   if (isLoadedOrder) {
  //     const newOrder = this._getFocusedOrder()
  //     const newOrderType = Map.isMap(newOrder) ? newOrder.get('type') : 0
  //     const newOrderState = Map.isMap(newOrder) ? newOrder.get('state') : 0
  //
  //     // if (_.includes([1, 2], newOrderType) && this._canEditOrder(newOrder, VIEW_EDIT)) {
  //     //   if (newOrderState === 102) {
  //     //     p.op.alert('คุณสามารถดำเนินการแก้ไข แล้วกดปุ่ม "ยืนยัน" (สีส้มด้านซ้ายล่าง) เมื่อเสร็จสิ้น')
  //     //     this.inProcess = false
  //     //     return
  //     //   }
  //     //   await setStatePromise(this, { orderTabsVisibility: false, mode: EDIT })
  //     //   await new Promise(delay => setTimeout(delay, 500))
  //     //   // await this._changeModeTo(EDIT)
  //     // } else {
  //     //   const txtOrderStatus =
  //     //     newOrderType > 0 && newOrderState > 180
  //     //       ? p.op.t(`Order.OrderStatesText._${newOrderType}${newOrderState}`)
  //     //       : null
  //     //   const txtMessage = txtOrderStatus
  //     //     ? `เนื่องจากออเดอร์ #${orderParentName} อยู่ในสถานะ ${txtOrderStatus}`
  //     //     : 'ระบบไม่ทราบสถานะของออเดอร์'
  //     //   p.op.alert('ไม่สามารถแก้ไขรายการสินค้า', txtMessage)
  //     // }
  //     if (this._canEditOrder(newOrder, VIEW_EDIT)) {
  //       if (_.includes([2, 3], newOrderType) && newOrderState === 101) {
  //         p.op.alert('คุณสามารถดำเนินการแก้ไข แล้วกดปุ่ม "ยืนยัน" (สีส้มด้านซ้ายล่าง) เมื่อเสร็จสิ้น')
  //         this.inProcess = false
  //         return
  //       }
  //       if (_.includes([1, 2], newOrderType) && newOrderState === 102) {
  //         p.op.alert('คุณสามารถดำเนินการแก้ไข แล้วกดปุ่ม "ยืนยัน" (สีส้มด้านซ้ายล่าง) เมื่อเสร็จสิ้น')
  //         this.inProcess = false
  //         return
  //       }
  //       await setStatePromise(this, { orderTabsVisibility: false, mode: EDIT })
  //       await new Promise(delay => setTimeout(delay, 500))
  //       // await this._changeModeTo(EDIT)
  //     }
  //   }
  //   this.inProcess = false
  // }

  // End Abstract Method

  _isEditingOrder = () => {
    const { selectedOrder, editingOrder } = this.props
    const { mode } = this.state
    const selectedOrderFocus = this._getFocusedOrder(selectedOrder)
    const editingOrderFocus = this._getFocusedOrder(editingOrder)
    const oldData: any = {}
    const newData: any = {}
    if (mode !== CREATE) {
      oldData.is_cod = selectedOrderFocus.get('is_cod')
      oldData.ship_before_pay = selectedOrderFocus.get('ship_before_pay')
    }
    oldData.cod_fee = +selectedOrderFocus.get('cod_fee') || +'0'
    oldData.cod_amount = +selectedOrderFocus.get('cod_amount') || +'0'
    oldData.shipping_cost = +selectedOrderFocus.get('shipping_cost')
    oldData.etc_cost = +selectedOrderFocus.get('etc_cost')
    oldData.discount = +selectedOrderFocus.get('discount')

    if (mode !== CREATE) {
      newData.is_cod = editingOrderFocus.get('is_cod')
      newData.ship_before_pay = editingOrderFocus.get('ship_before_pay')
    }
    newData.cod_fee = +editingOrderFocus.get('cod_fee') || +'0'
    newData.cod_amount = +editingOrderFocus.get('cod_amount') || +'0'
    newData.shipping_cost = +editingOrderFocus.get('shipping_cost')
    newData.etc_cost = +editingOrderFocus.get('etc_cost')
    newData.discount = +editingOrderFocus.get('discount')

    return xUtil.checkOnchangeData(oldData, newData)
  }

  _goBack = async (): Promise<void> => {
    // if (this.isNavBackAble && !isInFlow(this.flowName)) {
    if (this.isNavBackAble) {
      const { navigation, isDirty, selectedOrder, editingOrder } = this.props
      const { mode } = this.state
      // const selectedOrderFocus = this._getFocusedOrder(selectedOrder)
      // const editingOrderFocus = this._getFocusedOrder(editingOrder)
      // const oldData: any = {}
      // const newData: any = {}
      // if (mode !== CREATE) {
      //   oldData.is_cod = selectedOrderFocus.get('is_cod')
      //   oldData.ship_before_pay = selectedOrderFocus.get('ship_before_pay')
      // }
      // oldData.cod_fee = selectedOrderFocus.get('cod_fee') || '0'
      // oldData.cod_amount = selectedOrderFocus.get('cod_amount') || '0'
      // oldData.shipping_cost = selectedOrderFocus.get('shipping_cost')
      // oldData.etc_cost = selectedOrderFocus.get('etc_cost')
      // oldData.discount = selectedOrderFocus.get('discount')
      //
      // if (mode !== CREATE) {
      //   newData.is_cod = editingOrderFocus.get('is_cod')
      //   newData.ship_before_pay = editingOrderFocus.get('ship_before_pay')
      // }
      // newData.cod_fee = editingOrderFocus.get('cod_fee') || '0'
      // newData.cod_amount = editingOrderFocus.get('cod_amount') || '0'
      // newData.shipping_cost = editingOrderFocus.get('shipping_cost')
      // newData.etc_cost = editingOrderFocus.get('etc_cost')
      // newData.discount = editingOrderFocus.get('discount')
      //
      // const editing = xUtil.checkOnchangeData(oldData, newData)
      // if (editing || isDirty) {
      const isEditing = this._isEditingOrder()
      if (isEditing || isDirty) {
        const isUserConfirm = await p.op.isUserConfirm(
          'ยังไม่ได้บันทึกการแก้ไขออเดอร์',
          'คุณต้องการละทิ้งการแก้ไขหรือไม่',
          'ละทิ้งการแก้ไข',
          'ปิด'
        )
        if (!isUserConfirm) {
          return
        }
      }

      this.props.resetOrder()
      xUtil.navGoBack(this.props)
      this.isNavBackAble = false
    }
  }

  _renderCoverSheet = () => {
    // log(this.props.editingOrder.toJS())
    const { orderTabIndex, mode, customCreateOrder, customOrderView } = this.state
    const customNotes = mode === CREATE ? customCreateOrder.notes : customOrderView.shipping_label
    const coverSheet = customNotes.n_1
    const coverSheetNote = customNotes.n_2

    const focusedOrder = this._getFocusedOrder()
    // const notesOrder = focusedOrder.has('notes') ? focusedOrder.get('notes') : null
    let note1 = false
    let note2 = false

    // if (!_.isNil(notesOrder) && notesOrder.size > 0) {
    //   note1 = notesOrder.get('type') === 1
    //   note2 = notesOrder.get('type') === 2
    // }
    const notesOrder = focusedOrder.has('notes') ? focusedOrder.get('notes') : null
    // let noteOne = false
    // let noteTwo = false
    if (!_.isNil(notesOrder)) {
      notesOrder.map((n) => {
        if (n.get('type') === 1) {
          note1 = true
        } else if (n.get('type') === 2) {
          note2 = true
        }
      })
    }
    let showCoverSheet = false
    let showCoverSheetNote = false
    showCoverSheet = coverSheet.v === 0
    showCoverSheetNote = coverSheetNote.v === 0
    if (coverSheet.v === 0 && coverSheetNote.v === 0 && mode === CREATE) {
      return null
    }
    const notCreateMode = !!(mode === VIEW_EDIT || mode === EDIT)
    if (showCoverSheet && showCoverSheetNote && !note1 && !note2 && notCreateMode) {
      return null
    }
    // const isLockedOrder = focusedOrder.get('prevent_mod') || false
    // const notesOrder = editingOrder.get('notes')
    const pending_shp_lbl_img = focusedOrder.get('pending_shp_lbl_img')
    const pending_shp_lbl_note = focusedOrder.get('pending_shp_lbl_note')
    // const ignoreShpLblNote = focusedOrder.get('ignore_shp_lbl_note')
    // const ignoreShpLblImg = focusedOrder.get('pending_shp_lbl_img')
    // const partner_name = focusedOrder.get('partner_name')
    let TxtCoverSheetImage = 'แนบรูปใบปะหน้าจากระบบอื่น\n(เช่น Shopee, Lazada)'
    let TxtNoteCoverSheet = 'หมายเหตุบนใบปะหน้า'
    let img
    let noteCoverSheet
    // let newMode = mode

    if (!_.isNil(notesOrder)) {
      notesOrder.map((val) => {
        if (val.get('type') === 1) {
          if (mode === CREATE) {
            img = val.get('value')
          } else if (val.get('selected')) {
            img = val.get('value')
          }
        }
      })
      if (!_.isNil(img)) {
        TxtCoverSheetImage = 'ดู / แก้ไข รูปแทนใบปะหน้า'
      }
    }

    if (!_.isNil(notesOrder)) {
      notesOrder.map((val) => {
        if (val.get('type') === 2) {
          if (mode === CREATE) {
            noteCoverSheet = val.get('value')
          } else if (val.get('selected')) {
            noteCoverSheet = val.get('value')
          }
          // noteCoverSheet = val.get('value')
        }
      })
      if (!_.isNil(noteCoverSheet)) {
        TxtNoteCoverSheet = `หมายเหตุบนใบปะหน้า\n${noteCoverSheet}`
      }
    }

    if (pending_shp_lbl_img) {
      //
      TxtCoverSheetImage = `มีรูปแทนใบปะหน้าที่รอผู้ซื้อยืนยัน`
      img = null
    }

    if (pending_shp_lbl_note) {
      //
      TxtNoteCoverSheet = `มีหมายเหตุและรูปแทนใบปะหน้าที่รอผู้ซื้อยืนยัน`
    }

    // let noteSelected = false
    // if (!_.isNil(notesOrder)) {
    //   notesOrder.map(note => {
    //     if (!_.isNil(note.get('selected'))) {
    //       noteSelected = true
    //     }
    //   })
    // }

    // const orderType = focusedOrder.get('type')
    // const canPrintShippingLabel = this._canPrintShippingLabel(focusedOrder)
    // if (orderType === 1 || orderType === 2) {
    //   if (!canPrintShippingLabel) {
    //     newMode = 'READ_ONLY_MODE'
    //   }
    // } else if (orderType === 3) {
    //   if (isLockedOrder) {
    //     newMode = 'READ_ONLY_ORDER_LOCK_MODE'
    //   }
    // }

    // console.log(this._canPrintShippingLabel(focusedOrder))
    // let type = focusedOrder.get('type')
    // let txtPendingCoverSheet = 'มีรูปแทนใบปะหน้าและหมายเหตุจากผู้ซื้ออยู่\nกำลังรอผู้ซื้อยืนยัน'
    // console.log(ignoreShpLblImg === false)
    // console.log(!noteSelected)
    // console.log(!canPrintShippingLabel)
    let haveNotes = false
    if (mode === CREATE) {
      haveNotes = true
    } else if (notCreateMode) {
      if (note1) {
        showCoverSheet = true
      }
      if (note2) {
        showCoverSheetNote = true
      }
      // haveNotes = !_.isNil(note1) && !_.isNil(note1) ? true : false
    }
    // const orderNotes = focusedOrder.has('notes') ? focusedOrder.get('notes') : null
    // let noteOne = false
    // let noteTwo = false
    // if (!_.isNil(orderNotes)) {
    //   orderNotes.map(n => {
    //     if (n.get('type') === 1) {
    //       noteOne = true
    //     } else if (n.get('type') === 1) {
    //       noteTwo = true
    //     }
    //   })
    // }
    // console.log('img => ', img)
    // showCoverNoteSheet = coverNoteSheet.v === 0 && _.isNil(img)
    // showCoverNote = coverNote.v === 0 && _.isNil(noteCoverSheet)

    let showMultiParcelBtn = false
    // const products = focusedOrder.get('products')
    // console.log('products => ', products)
    const orderType = focusedOrder.get('type')
    if (mode === VIEW_EDIT && orderType !== 3) {
      showMultiParcelBtn = true
    }
    return (
      <XCard w='full' mt='1'>
        <XCard.Body>
          <VStack w='full' space='1'>
            <HStack py='2' alignItems='center' justifyContent='center'>
              <XText fontSize='md' bold>
                ใบปะหน้าพัสดุ
              </XText>
            </HStack>
            {showCoverSheet && !note1
              ? null
              : this._renderLineButtonItem({
                  iconName: 'photo',
                  iconFamily: 'FontAwesome',
                  txtLabel: 'รูปแทนใบปะหน้าพัสดุ',
                  txtMessage: TxtCoverSheetImage,
                  txtMessageStyle: null,
                  backgroundColor: pending_shp_lbl_img ? '#FFACAC' : null,
                  image: _.isNil(img) ? null : img,
                  handlePress: async () => {
                    await this._renderNavOrderTypeOne()
                  },
                })}
            {showCoverSheetNote && !note2
              ? null
              : this._renderLineButtonItem({
                  iconName: 'edit',
                  iconFamily: 'FontAwesome',
                  txtLabel: 'หมายเหตุบนใบปะหน้าพัสดุ',
                  txtMessage: TxtNoteCoverSheet,
                  backgroundColor: pending_shp_lbl_note ? '#FFACAC' : null,
                  txtMessageStyle: null,
                  handlePress: async () => {
                    await this._renderNavOrderTypeTwo()
                    // if (isLockedOrder) {
                    //   p.op.showConfirmationOkOnly(null, 'ออเดอร์นี้ถูกล็อกอยู่ กรุณาปลดล็อกออเดอร์ก่อนทำการแก้ไข')
                    //   return
                    // } else {
                    // this.props.navigation.dispatch(
                    //   NavActions.navToAddCoverSheetNote({
                    //     store_id: this.state.store_id,
                    //     mode: newMode,
                    //     orderTabIndex,
                    //     pending_shp_lbl_note,
                    //     onSuccessEditNoteCallback: () => this.setState({ orderTabsVisibility: false })
                    //   }),
                    // )
                    // }
                  },
                  numberOfLines: 2,
                })}
            {/* {showCoverSheet ? null : <View style={{ height: 15 }} />} */}
            {showMultiParcelBtn
              ? this._renderLineButtonItem({
                  iconName: 'boxes',
                  iconFamily: 'FontAwesome5',
                  txtLabel: 'จัดการหลายพัสดุ',
                  txtMessage: 'จัดการหลายพัสดุ',
                  txtMessageStyle: null,
                  // backgroundColor: pending_shp_lbl_img ? '#FFACAC' : null,
                  // image: _.isNil(img) ? null : img,
                  handlePress: () => this.navToMultiParcelView(),
                  faq: FAQ_CONSTANTS.FAQ_MULTI_PARCELS,
                })
              : null}
          </VStack>
        </XCard.Body>
      </XCard>
    )
  }

  navToMultiParcelView = () => {
    const focusedOrder = this._getFocusedOrder()
    const products = focusedOrder.get('products')
    let amountQty = 0
    products.forEach((product) => {
      amountQty += product.get('qty')
    })

    if (amountQty === 1) {
      p.op.showConfirmationOkOnly(
        'ไม่สามารถตั้งค่าจัดการหลายพัสดุได้',
        'ต้องมีอย่างน้อยพัสดุ 2 ชิ้น ที่คุณเป็นคุณเป็นคนจัดส่ง  จึงสามารถใช้งานตัวเลือกนี้ได้'
      )
      return
    }
    const shipping = focusedOrder.get('shipping_type_id')
    if (_.includes(xCONS.COD_SHIPPING_TYPE_IDS, shipping)) {
      p.op.showConfirmationOkOnly('', 'ไม่รองรับรูปแบบการจัดส่งแบบ COD')
      return
    }
    this.props.navigation.dispatch(
      NavActions.navToMultiParcelView({
        order: isImmutable(focusedOrder) ? focusedOrder.toJS() : focusedOrder,
        callBackRerender: (isRerander) => {
          if (isRerander) {
            this._fetchOrderDetail()
          }
        },
      })
    )
  }

  _renderTextSuccess = (text) => <XText style={[TXT_LABEL_SMALLER, { color: COLORS.FORM_SUCCESS }]}>{text}</XText>

  _renderTextLabel = (text) => <XText style={[TXT_LABEL_SMALLER]}>{text}</XText>

  _renderTextError = (text) => <XText style={[TXT_LABEL_SMALLER, { color: COLORS.FORM_ERROR }]}>{text}</XText>

  _handleOrderOptionChangeValueOnly = async (order, key, newValue, orderTabIndex) => {
    const { onEditingOrderChange } = this.props
    const { mode } = this.state
    const isEditableInConfirmationMode = this._calculateEditableInConfirmationMode(order)
    const { isEditableQty, isEditableEtc } = isEditableInConfirmationMode
    if (mode !== CREATE) {
      if (mode === VIEW_EDIT && !isEditableEtc) {
        p.op.showConfirmationOkOnly(null, 'กรุณากด แก้ไขค่าส่ง/ส่วนลด/ค่าอื่นๆ ก่อนทำการแก้ไข')
        return
      }
    }
    if (key === 'is_cod' && !newValue) {
      onEditingOrderChange({ key: 'cod_fee', value: '0', orderTabIndex })
      onEditingOrderChange({ key: 'cod_amount', value: '0', orderTabIndex })
    }
    onEditingOrderChange({ key, value: newValue, orderTabIndex })
  }

  getWebOrderNoteMaxWidth = () => undefined

  getWebTextOrderNodeMaxWidthMagicNumber = () => undefined

  _renderLineButtonItem = ({
    iconName,
    iconFamily = ICON_FAMILY.Ionicons,
    txtLabel,
    txtMessage,
    txtMessageStyle,
    handlePress,
    noTopBorder = false,
    numberOfLines = 10,
    backgroundColor = null,
    image = null,
    faq = null,
  }) => (
    // console.log('image => ', image)
    // let usageIcon
    // const iconStyle = { fontSize: FONT_ICON_NORMAL, color: TEXT_INACTIVE }
    // if (iconName) {
    //   switch (iconFamily) {
    //     case 'FontAwesome':
    //       usageIcon = <XIcon family='FontAwesome' name={iconName} style={iconStyle} />
    //       break
    //     case 'MaterialIcons':
    //       usageIcon = <XIcon family='MaterialIcons' name={iconName} style={iconStyle} />
    //       break
    //     default:
    //       usageIcon = <Icon name={iconName} style={iconStyle} />
    //   }
    //   // <XIcon family={iconFamily} name={iconName} style={iconStyle} />
    // }

    <XCard
      w='full'
      borderTopWidth={noTopBorder ? '0' : undefined}
      disabled={!handlePress}
      onPress={handlePress || null}
      style={backgroundColor ? { backgroundColor } : undefined}>
      <XCard.Body>
        <HStack w='full' flexWrap='wrap' alignItems='center' space='1'>
          <HStack flex={1} space='1'>
            {iconName ? <XIcon variant='inactive' family={iconFamily} name={iconName} /> : null}
            <HStack flex={1}>
              <VStack>
                {/* {txtLabel ? (
                  <XText variant='inactive' numberOfLines={numberOfLines} style={txtMessageStyle}>
                    {txtLabel}
                  </XText>
                ) : null} */}
                {txtMessage ? (
                  <XText
                    variant='inactive'
                    numberOfLines={numberOfLines}
                    style={txtMessageStyle}
                    // style={
                    //   txtMessageStyle || {
                    //     fontSize: FONT_SIZE_NORMAL,
                    //     color: TEXT_INACTIVE,
                    //     paddingTop: image != null ? 8 : 0,
                    //     maxWidth: this.getWebTextOrderNodeMaxWidthMagicNumber(),
                    //   }}
                  >
                    {txtMessage}
                  </XText>
                ) : null}
              </VStack>

              {!_.isNil(faq) ? (
                <XFaqModal
                  key='FAQ'
                  headerTitle='FAQ'
                  // @ts-ignore
                  FAQ={faq}
                  initiateOpenIndex={[0]}
                  renderButton={(onPressOpenOverlay: () => void) => _renderAgreement(onPressOpenOverlay)}
                />
              ) : null}
            </HStack>
          </HStack>
          <HStack alignItems='center' justifyContent='flex-end' space='1'>
            {image != null ? <Image style={{ width: 30, height: 30 }} source={{ uri: image }} /> : null}
            {handlePress ? <XIcon name='arrow-forward' /> : null}
          </HStack>
        </HStack>
      </XCard.Body>
    </XCard>
  )

  _renderSenderNameInputItem = ({ iconName, txtLabel, txtMessage }) => (
    <XCard w='full' borderBottomWidth='0' borderBottomLeftRadius='0' borderBottomRightRadius='0'>
      <XCard.Body>
        <HStack w='full' flexWrap='wrap' alignItems='center' space='1'>
          <XIcon name={iconName} variant='inactive' />
          <XInput
            flex={1}
            // ref={r => xUtil.pushNativeBaseRef(this.inputRefs, 'sender_name', r)}
            // onFocus={() => this.setState({ currentRef: 'sender_name' })}
            // selectTextOnFocus
            // @ts-ignore
            // style={{
            //   paddingLeft: 5,
            //   // height: 46,
            //   // paddingTop: 1,
            //   // paddingBottom: 1,
            //   // fontSize: FONT_SIZE_NORMAL,
            //   // color: TEXT_PRIMARY,
            //   // backgroundColor: FORM_PRIMARY_BG,
            //   ...Platform.select({
            //     android: { height: 48 },
            //     ios: {
            //       marginTop: 0,
            //       textAlignVertical: 'center',
            //       paddingTop: 6,
            //       paddingBottom: 6,
            //     },
            //     web: { flexBasis: 'auto', width: '100%' },
            //   }),
            // }}
            value={txtMessage}
            maxLength={300}
            placeholder='ระบุชื่อผู้จัดส่ง (ชื่อฉัน/ชื่อร้านฉัน)'
            placeholderTextColor={TEXT_INACTIVE}
            onChangeText={(text) => this.setState({ txtSenderName: text })}
            multiline
            blurOnSubmit={false}
          />
          {/* </Item> */}
        </HStack>
      </XCard.Body>
    </XCard>
  )

  _renderSenderAddressPickerButtonItem = ({
    iconName,
    iconFamily = ICON_FAMILY.Ionicons,
    txtLabel,
    txtMessage,
    txtMessageStyle,
    handlePress,
    noTopBorder = false,
    numberOfLines = 10,
    backgroundColor = null,
    image = null,
  }) => (
    // let usageIcon
    // const iconStyle = { fontSize: FONT_ICON_NORMAL, color: TEXT_INACTIVE }
    // if (iconName) {
    //   switch (iconFamily) {
    //     case 'FontAwesome':
    //       usageIcon = <XIcon family='FontAwesome' name={iconName} style={iconStyle} />
    //       break
    //     case 'MaterialIcons':
    //       usageIcon = <XIcon family='MaterialIcons' name={iconName} style={iconStyle} />
    //       break
    //     default:
    //       usageIcon = <Icon name={iconName} style={iconStyle} />
    //   }
    //   // <XIcon family={iconFamily} name={iconName} style={iconStyle} />
    // }

    <XCard
      w='full'
      borderTopWidth={noTopBorder ? '0' : undefined}
      borderTopLeftRadius='0'
      borderTopRightRadius='0'
      disabled={!handlePress}
      onPress={handlePress || null}
      style={backgroundColor ? { backgroundColor } : undefined}>
      <XCard.Body>
        <HStack w='full' flexWrap='wrap' alignItems='center' space='1'>
          <HStack flex={1} space='1'>
            {iconName ? <XIcon variant='inactive' family={iconFamily} name={iconName} /> : null}
            <VStack flex={1}>
              {/* {txtLabel ? (
                <XText variant='inactive' numberOfLines={numberOfLines} style={txtMessageStyle}>
                  {txtLabel}
                </XText>
              ) : null} */}
              {txtMessage ? (
                <XText
                  variant='inactive'
                  numberOfLines={numberOfLines}
                  style={txtMessageStyle}
                  // style={
                  //   txtMessageStyle || {
                  //     fontSize: FONT_SIZE_NORMAL,
                  //     color: TEXT_INACTIVE,
                  //     paddingTop: image != null ? 8 : 0,
                  //     maxWidth: this.getWebTextOrderNodeMaxWidthMagicNumber(),
                  //   }}
                >
                  {txtMessage}
                </XText>
              ) : null}
            </VStack>
          </HStack>
          <HStack alignItems='center' justifyContent='flex-end' space='1'>
            {image != null ? <Image style={{ width: 30, height: 30 }} source={image} /> : null}
            {handlePress ? <XIcon name='arrow-forward' /> : null}
          </HStack>
        </HStack>
      </XCard.Body>
    </XCard>
  )

  _renderReceiverNameOnly = () => {
    const { customCreateOrder } = this.state
    const textReceiverName = customCreateOrder.receiver.vs
    return this._renderInputItem({
      iconName: 'person',
      txtMessage: _.isNil(textReceiverName) ? '' : textReceiverName,
      txtPlaceholder: 'ระบุชื่อผู้รับ',
      onChangeText: this.onChangeReceiverName,
    })
  }

  _renderReceiverNameAndPhon = () => {
    const { customCreateOrder } = this.state
    const { receiver } = customCreateOrder
    return (
      <>
        {this._renderInputItem({
          iconName: 'person',
          txtMessage: _.isNil(receiver.vs) ? '' : receiver.vs,
          txtPlaceholder: 'ระบุชื่อผู้รับ',
          onChangeText: this.onChangeReceiverName,
        })}
        {/* <View style={{ paddingBottom: 3 }} /> */}
        {this._renderInputItem({
          iconName: 'call',
          txtMessage: _.isNil(receiver.p) ? '' : receiver.p,
          txtPlaceholder: 'ระบุเบอร์โทรผู้รับ',
          onChangeText: this._onChangePhone,
        })}
      </>
    )
  }

  _renderInputItem = ({ iconName, txtMessage, txtPlaceholder, onChangeText }) => (
    <View
      style={[
        s.webBorderInactive,
        {
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderWidth: 1,
          borderColor: COLORS.FORM_INACTIVE,
        },
      ]}>
      <View
        style={{
          flex: 9,
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        }}>
        <View
          style={{
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingTop: 2,
            paddingBottom: 2,
            paddingLeft: 5,
            ...Platform.select({
              web: {
                flexBasis: 'auto',
                width: '100%',
              },
            }),
          }}>
          <View
            style={[
              NO_MARGIN_PADDING,
              {
                flex: p.op.isWeb() ? 1 : 2,
                paddingLeft: 5,
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
              },
            ]}>
            <View
              style={[
                NO_MARGIN_PADDING,
                {
                  flex: 1,
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                },
              ]}>
              <Icon name={iconName} style={{ fontSize: FONT_ICON_NORMAL, color: TEXT_INACTIVE, paddingLeft: 3 }} />
            </View>
          </View>

          <View
            style={[
              NO_MARGIN_PADDING,
              {
                flex: p.op.isWeb() ? 8 : 15,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                paddingRight: 5,
              },
            ]}>
            <View
              style={[
                NO_MARGIN_PADDING,
                {
                  flex: 1,
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                },
              ]}>
              {/* <Item inlineLabel={true} style={[STYLES.NO_MARGIN_PADDING, { height: 30, borderBottomWidth: 0 }]}> */}
              {/* <XText style={s.txtNormal}>ชื่อผู้จัดส่ง</XText> */}
              <XInput
                // ref={r => xUtil.pushNativeBaseRef(this.inputRefs, 'sender_name', r)}
                // onFocus={() => this.setState({ currentRef: 'sender_name' })}
                selectTextOnFocus
                // @ts-ignore
                style={{
                  // height: 28,
                  // paddingTop: -1,
                  // paddingBottom: 1,
                  // fontSize: FONT_SIZE_NORMAL,
                  // color: TEXT_PRIMARY,
                  // backgroundColor: COLORS.FORM_PRIMARY_BG,
                  ...Platform.select({
                    android: { height: 30 },
                    ios: {
                      marginTop: 0,
                      textAlignVertical: 'center',
                      paddingTop: 6,
                      paddingBottom: 6,
                    },
                    web: { flexBasis: 'auto', width: '100%' },
                  }),
                }}
                value={txtMessage}
                maxLength={300}
                placeholder={_.isNil(txtPlaceholder) ? '' : txtPlaceholder}
                placeholderTextColor={TEXT_INACTIVE}
                onChangeText={(text) => onChangeText(text)}
                multiline
                blurOnSubmit={false}
                isDisabled={false}
              />
              {/* </Item> */}
            </View>
          </View>
        </View>
      </View>
    </View>
  )

  // _onPickNewReceiverAddress(id) {
  //   const { editingOrder, editReceiverAddress, orderTabIndex, selectedStore } = this.props
  //   let selectedStoreID = Map.isMap(selectedStore) ? selectedStore.get('id') : null
  //   const params = this.state
  //   if (!selectedStoreID) {
  //     selectedStoreID = params.store_id ? params.store_id : null
  //   }
  //
  //   const order = this._getFocusedOrder(editingOrder, orderTabIndex)
  //   if (selectedStoreID && selectedStoreID && order.get('id')) {
  //     let body = {
  //       store_id: selectedStoreID,
  //       receiver_address_id: id,
  //       order_id: order.get('id'),
  //     }
  //     editReceiverAddress(body)
  //   }
  // }

  // calculateEditButtonVisibleState({ type, state, payment_state, shipping_state }) {
  //   if (type && state && payment_state && shipping_state) {
  //     if (state < 109 || state >= 180) { // ถ้าสถานะรอยืนยัน หรือ ยกเลิกไปแล้วจะแก้ไขไม่ได้
  //       return false
  //     } else {
  //       return true
  //     }
  //   }
  //   return false
  // }

  // TODO: Refactor me to BASE async function
  _onPressAutoOperations = () => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true

    const order = this._getFocusedOrder()
    const type = parseInt(order.get('type')) || 0
    // const state = parseInt(order.get('state')) || 0
    // const payment_state = parseInt(order.get('payment_state')) || 0
    // const shipping_state = parseInt(order.get('shipping_state')) || 0
    // const shippings = order.get('shippings') || List([])
    const products = order.get('products') || List([])
    const remaining_forecast_amount = parseFloat(order.get('remaining_forecast_amount')) || 0
    const buttonLabels = []
    const autoMethods = []

    const canAutoPay = this._canAutoFinishPayment(type, remaining_forecast_amount)
    const canAutoShip = this._canAutoFinishShipping(type, products)

    if (canAutoPay) {
      buttonLabels.push('บันทึกรับชำระ')
      // autoMethods.push(() =>
      //   p.op.showConfirmation(
      //     'บันทึกรับชำระอัตโนมัติ',
      //     `คุณต้องการบันทึกรับชำระจากลูกค้าแบบอัตโนมัติ หรือไม่?\n\nหากยืนยัน ระบบจะบันทึกว่า คุณได้รับชำระจากลูกค้าเต็มจำนวน\nณ ${nowDateTime.format(
      //       'DD/MM/YYYY hh:mm น.',
      //     )} โดยไม่แนบสลิป`,
      //     () => this._onPressAutoFinishPayment(order, nowDateTime),
      //     null,
      //     'ยืนยัน',
      //   ),
      // )
      // autoMethods.push(() => this._onPressAutoFinishPayment(order))
      autoMethods.push(this._onPressAutoFinishPayment)
    }

    if (canAutoShip) {
      buttonLabels.push('จัดส่ง')
      // autoMethods.push(() =>
      //   p.op.showConfirmation(
      //     'บันทึกจัดส่งอัตโนมัติ',
      //     'คุณต้องการบันทึกจัดส่งแบบอัตโนมัติ หรือไม่?\n\nหากยืนยัน ระบบจะบันทึกว่า ออเดอร์นี้จัดส่งครบถ้วนแล้ว โดยไม่ระบุเลขติดตามพัสดุ',
      //     () => this._onPressAutoFinishShipping(order),
      //     null,
      //     'ยืนยัน',
      //   ),
      // )
      // autoMethods.push(() => this._onPressAutoFinishShipping(order))
      autoMethods.push(this._onPressAutoFinishShipping)
    }

    if (canAutoPay && canAutoShip) {
      const nowDateTime = moment()
      buttonLabels.push('บันทึกรับชำระ และ จัดส่ง')
      autoMethods.push(() => {
        p.op.showConfirmation(
          'บันทึกรับชำระ และ จัดส่ง',
          `คุณต้องการบันทึกรับชำระ และ จัดส่ง แบบอัตโนมัติ หรือไม่?\n\nหากยืนยัน ระบบจะบันทึกว่า\nคุณได้รับชำระจากลูกค้าเต็มจำนวน\nณ ${nowDateTime.format(
            'DD/MM/YYY HH:mm น.'
          )} โดยไม่แนบสลิป\nและ\nออเดอร์นี้จัดส่งครบถ้วนแล้ว โดยไม่ระบุเลขติดตามพัสดุ`,
          () => {
            this._onPressAutoFinishPayment(true)
            this._onPressAutoFinishShipping(true)
          },
          null,
          'ยืนยัน'
        )
      })
    }

    buttonLabels.push('ยกเลิก')
    p.op.showActionSheet(
      {
        options: buttonLabels,
        cancelButtonIndex: buttonLabels.length - 1,
        title: 'ดำเนินการอัตโนมัติ...',
      },
      (buttonIndex) => {
        const idx = _.isString(buttonIndex) ? parseInt(buttonIndex) : buttonIndex
        const selectedMethod = autoMethods[idx]
        if (_.isFunction(selectedMethod)) {
          selectedMethod()
        }
      }
    )

    setTimeout(() => {
      this.inProcess = false
    }, 200)
  }
  //
  // _onPressAutoFinishPayment = async (
  //   order: Map<string, any>,
  //   nowDateTime: Moment = moment(),
  //   isConfirmed: boolean,
  // ): Promise<void> => {
  //   const { navigation } = this.props
  //   const { store_id } = navigation.state.params
  //   if (!Map.isMap(order) || !order.get('id')) {
  //     p.op.alert('ข้อมูลออเดอร์ไม่ถูกต้อง', 'กรุณาโหลดออเดอร์ใหม่แล้วทำรายการใหม่อีกครั้ง')
  //     return
  //   }
  //   const order_id = order.get('id') || null
  //   const total_amount = order.get('remaining_forecast_amount') || 0
  //
  //   if (!isConfirmed) {
  //     const isUserConfirm = await p.op.isUserConfirm(
  //       'บันทึกรับชำระอัตโนมัติ',
  //       `คุณต้องการบันทึกรับชำระจากลูกค้าแบบอัตโนมัติ หรือไม่?\n\nหากยืนยัน ระบบจะบันทึกว่า คุณได้รับชำระจากลูกค้าเต็มจำนวน\nณ ${nowDateTime.format(
  //         'DD/MM/YYYY hh:mm น.',
  //       )} โดยไม่แนบสลิป`,
  //       'ยืนยัน',
  //       'ยกเลิก',
  //     )
  //
  //     if (!isUserConfirm) {
  //       return
  //     }
  //   }
  //   // Alert.alert('ปิดออเดอร์ซื้อแล้ว')
  //   if (total_amount > 0) {
  //     navigation.dispatch({
  //       type: actions.AUTOMATIC_SUBMIT_PAYMENT,
  //       payload: {
  //         note: 'บันทึกรับชำระจากลูกค้าแบบอัตโนมัติ',
  //         total_amount,
  //         post_date: xFmt.formatToServerDateTime(nowDateTime),
  //         store_id,
  //         // payment_account_id: 76, // <---- ต้องไป fetch เอามา
  //         pay_to_store_id: store_id,
  //         orders: [{ order_id, amount: total_amount }],
  //       },
  //     })
  //   }
  // }
  //
  // _onPressAutoFinishShipping = async (order, isConfirmed: boolean): Promise<void> => {
  //   // Pass condition products.size > 0
  //   const { navigation } = this.props
  //   const { store_id } = navigation.state.params
  //
  //   if (!Map.isMap(order) || !order.get('id')) {
  //     p.op.alert('ข้อมูลออเดอร์ไม่ถูกต้อง', 'กรุณาโหลดออเดอร์ใหม่แล้วทำรายการใหม่อีกครั้ง')
  //     return
  //   }
  //
  //   const order_id = order.get('id') || null
  //   const shipping_type_id = parseInt(order.get('shipping_type_id')) || 0
  //   const transaction_cost = parseFloat(order.get('transaction_cost')) || 0
  //   const tracking_number = '-'
  //   const note = 'บันทึกจัดส่งอัตโนมัติ'
  //   let products = []
  //   let totalQty = 0
  //   const orderProducts = order.get('products') || List([])
  //
  //   orderProducts.forEach((product: Map<string, any>) => {
  //     if (isImmutable(product)) {
  //       const pp_id = product.get('pp_id')
  //       const qty = product.get('qty') - product.get('shipped_qty')
  //       if (pp_id && qty > 0) {
  //         totalQty += qty
  //         products.push({ pp_id, qty })
  //       }
  //     }
  //   })
  //
  //   if (!isConfirmed) {
  //     const isUserConfirm = await p.op.isUserConfirm(
  //       'บันทึกจัดส่งอัตโนมัติ',
  //       'คุณต้องการบันทึกจัดส่งแบบอัตโนมัติ หรือไม่?\n\nหากยืนยัน ระบบจะบันทึกว่า ออเดอร์นี้จัดส่งครบถ้วนแล้ว โดยไม่ระบุเลขติดตามพัสดุ',
  //       'ยืนยัน',
  //       'ยกเลิก',
  //     )
  //
  //     if (!isUserConfirm) {
  //       return
  //     }
  //   }
  //
  //   if (products.length > 0) {
  //     const body = { store_id, order_id, shipping_type_id, tracking_number, note, products }
  //     navigation.dispatch({ type: actions.SHIPMENT_ORDER_OPERATE_SHIP, payload: body })
  //   }
  //   p.op.aLogEvent('BUTTON_autoFinishShipping', { amt: transaction_cost, ok: true, qty: totalQty })
  //
  //   // Alert.alert('บันทึกว่าจัดส่งครบถ้วนแล้ว')
  // }

  // calculateTopMenuOptions({ order, mode }) {
  //   const state = parseInt(order.get('state') || 0)
  //   const type = parseInt(order.get('type') || 0)
  //   const payment_state = parseInt(order.get('payment_state') || 0 )
  //   const shipping_state = parseInt(order.get('shipping_state') || 0)
  //   const shippings = order.get('shippings') || List([])
  //   const products = order.get('products') || List([])
  //   const remaining_forecast_amount = parseFloat(order.get('remaining_forecast_amount') || 0)
  //   const prevent_mod = order.get('prevent_mod') || false
  //
  //   this.hamburgerMenuOptions = []
  //   log('calculateTopMenuOptions: ' + shipping_state)
  //   // this.topMenuItems is the list of menu and action
  //   // let availableMenus = [false, false, false, true]
  //   let availableMenus = _.cloneDeep(this.topMenuDefaults)
  //   availableMenus[this.INDEX_AUTO_OPERATIONS] = !xUtil.isOrderInCancelledState(state) && this._canAutoOperations(type, products, remaining_forecast_amount)
  //   // availableMenus[this.INDEX_AUTO_SHIPPINGS] = this._canAutoFinishShipping(type, products, remaining_forecast_amount)
  //   availableMenus[this.INDEX_PRINT_SHIPPING] = this._canPrintShippingLabel(order)
  //   availableMenus[this.INDEX_EDIT_ORDER] = this._canEditOrder(mode, type, state)
  //   availableMenus[this.INDEX_SHOW_PROFIT] = !this.state.showProfit && _.includes([1, 2], type)
  //   availableMenus[this.INDEX_HIDE_PROFIT] = this.state.showProfit && _.includes([1, 2], type)
  //   availableMenus[this.INDEX_LOCK_ORDER] = !prevent_mod && _.includes([1, 2], type)
  //   availableMenus[this.INDEX_UNLOCK_ORDER] = prevent_mod && _.includes([1, 2], type)
  //   availableMenus[this.INDEX_CANCEL_ORDER] = this._canCancelOrder(mode, state, shippings)
  //
  //   const { VIEW_EDIT, EDIT } = CONS.ORDER_VIEW_MODE
  //   if (_.includes([VIEW_EDIT, EDIT], mode)) {
  //     if (state) {
  //       // check with xselly states sheet
  //       if (!(type === 2 && state === 101) &&
  //         !((type === 1 || type === 2) && state === 102) &&
  //         !(type === 3 && state === 103) &&
  //         !(state > 180)) {
  //         availableMenus[this.INDEX_EDIT_ORDER] = true
  //       }
  //       if (shippings.length > 0 || state >= 180) { // ยกเลิกได้ต่อเมื่อยังไม่ได้จัดส่ง และ ยังไม่ถูกยกเลิกมาก่อน
  //         availableMenus[this.INDEX_CANCEL_ORDER] = false
  //       }
  //     }
  //   }
  //
  //   return this.topMenuItems.reduce((prevArr, menu, index) => {
  //     if (availableMenus[index]) {
  //       if (index === this.INDEX_EDIT_ORDER && type === 3) {
  //         let modifiedMenu = _.clone(menu)
  //         modifiedMenu.label = 'แก้ไขราคา'
  //         prevArr.push(modifiedMenu)
  //       } else {
  //         prevArr.push(menu)
  //       }
  //       // prevArr.push(menu.label)
  //       this.hamburgerMenuOptions.push(menu)
  //     }
  //     return prevArr
  //   }, [])
  // }

  _getStatusTextColor(state, payment_state, shipping_state, isCancelState) {
    if (isCancelState) {
      return FORM_ERROR
    }
    if (state === 109 && payment_state === 119 && shipping_state === 129) {
      return FORM_SUCCESS
    }
    return APP_MAIN
  }

  // _handleOnPressParentOrderId = async (parentOrderId: number): Promise<boolean> => {
  _handleOnPressParentOrderId = async (): Promise<boolean> => {
    const { editingOrder } = this.props
    const parentOrderId: number = editingOrder.get('id')
    // if (isInFlow(this.flowName) || !!editingOrder.get('updated_at') || this.state.refreshing) {
    if (!!editingOrder.get('updated_at') || this.state.refreshing) {
      if (editingOrder.get('updated_at')) {
        if (!this.state.orderTabsVisibility) {
          await setStatePromise(this, { orderTabsVisibility: true })
        }
        await this.changeOrderTabIndexTo(0)
      }
      // log(this, '_handleOnPressParentOrderId cannot fetch.')
      return false
    }

    if (!this.state.orderTabsVisibility) {
      await setStatePromise(this, { orderTabsVisibility: true, order_id: parentOrderId })
      // await navigation.setParams({order_id: parent_id})
    }

    // await navigation.setParams({ order_id: parent_id })
    await this.changeOrderTabIndexTo(0)
    await this._onRefresh()
    // log(this, '_handleOnPressParentOrderId is fetched new order.')
    return true
  }

  _handleOnPressChildOrderId = async (childOrderId: number): Promise<boolean> => {
    // log('_handleOnPressChildOrderId childOrderId => ', childOrderId)
    // if (this.inProcess || isInFlow(this.flowName) || !_.isNumber(childOrderId) || this.state.refreshing) {
    if (this.inProcess || !_.isNumber(childOrderId) || this.state.refreshing) {
      return
    }
    const { editingOrder } = this.props
    const { orderTabsVisibility } = this.state
    const subOrders = editingOrder.get('suborders') || List([])
    const foundOrderTabIndex = subOrders.findIndex((so) => so.get('id') === childOrderId)

    if (foundOrderTabIndex > -1) {
      if (!orderTabsVisibility) {
        await setStatePromise(this, { orderTabsVisibility: true })
        await this.changeOrderTabIndexTo(foundOrderTabIndex + 1)
      } else {
        await this.changeOrderTabIndexTo(foundOrderTabIndex + 1)
      }

      // const orderTabsRef = this._orderTabsRef.current
      // if (orderTabsRef && _.isFunction(orderTabsRef.goToPage)) {
      //   // log('_handleOnPressChildOrderId this._orderTabsRef => ', this._orderTabsRef)
      //   orderTabsRef.goToPage(foundOrderTabIndex + 1)
      // }
    }
  }

  _computeExpiringDateInfo = ({ order }) => {
    const expDate = {
      shouldBeVisible: false,
      text: '',
      style: {},
    }
    if (order.get('remaining_forecast_amount') <= 0) {
      // already paid (regardless of payment confirmation).
      // Returns default value (no display)
      return expDate
    }
    if (order.get('expiration_date')) {
      const endTime = moment(order.get('expiration_date'))
      const diff = endTime.diff(moment(), 'second', false)
      if (diff < 0) {
        // Already expired. Display overdue text in red
        expDate.shouldBeVisible = true
        expDate.text = p.op.t('Order.overdue')
        expDate.style = COLOR_UNCOMMON
      } else {
        // Not yet expired. Display normally
        expDate.shouldBeVisible = true
        expDate.text = endTime.fromNow()
        expDate.style = COLOR_COMMON
      }
    } else {
      expDate.shouldBeVisible = true
      expDate.text = 'ไม่มีกำหนดหมดอายุ'
      expDate.style = COLOR_COMMON
    }

    return expDate
  }

  _getStyleForPaymentNum(paidAmount, totalAmount) {
    const paidNum = parseFloat(paidAmount)
    const totalNum = parseFloat(totalAmount)
    if (paidNum === totalNum) {
      return COLOR_DONE
    }
    if (paidNum < totalNum) {
      return COLOR_COMMON
    }
    return COLOR_UNCOMMON
  }

  _getStyleForNum(remainingCost) {
    const num = parseFloat(remainingCost)
    if (num === 0) {
      return COLOR_DONE
    }
    if (remainingCost > 0) {
      return COLOR_COMMON
    }
    // remainingCost < 0
    return COLOR_UNCOMMON
  }

  // _renderStateBoxes = (order) => {
  //   const boxStateColumns = this.getOrderStateBoxes(order)
  //   // const isBuyerCompleted = boxStateColumns[0].isCompleted
  //   // const isSellerCompleted = boxStateColumns[1].isCompleted
  //   // console.log(boxStateColumns)
  //   return (
  //     <View style={{ flex: 1, flexBasis: 'auto', flexDirection: 'row', marginTop: 5, marginRight: 4 }}>
  //       {this._renderLabelTableColumn(order)}
  //       {boxStateColumns.map((col) =>
  //         this._renderStateTableColumn({
  //           key: col.key,
  //           headerText: col.headerText,
  //           headerTextSuffix: col.headerTextSuffix,
  //           subHeaderText: col.subHeaderText,
  //           data: col.data,
  //           isLastColumn: col.isLastColumn || false,
  //           isCompleted: col.isCompleted || false,
  //         })
  //       )}
  //     </View>
  //   )
  // }

  // _renderLabelTableColumn = (order) => {
  //   const orderState = order.get('state')
  //   const paymentState = order.get('payment_state')
  //   const shippingState = order.get('shipping_state')
  //   const orderType = order.get('type')
  //   const orderStateColor = getOrderStateColor(orderType, orderState)
  //   const paymentStateColor = getPaymentStateColor(orderType, paymentState)
  //   const shippingStateColor = getShippingStateColor(orderType, shippingState)
  //   const shipBeforePay = order.get('ship_before_pay')
  //   const cod = order.get('is_cod')

  //   const isCOD = !!(!_.isNil(cod) && cod)
  //   const isShipBeforePay = !!(!_.isNil(shipBeforePay) && shipBeforePay)
  //   return (
  //     <View style={s.statusTableColumnContainer}>
  //       <View style={s.statusTableLabelColumnViewBlank}>
  //         <XText />
  //       </View>
  //       <View style={s.statusTableLabelColumnViewBlank}>
  //         <XText />
  //       </View>
  //       <View style={s.statusTableLabelColumnView}>
  //         <Icon
  //           type='MaterialCommunityIcons'
  //           name='file-document'
  //           style={StyleSheet.flatten([s.orderStateIcon, { color: orderStateColor }])}
  //         />
  //         <XText allowFontScaling={false} style={s.statusTableLabelColumnTxt}>
  //           {/* {p.op.t('Order.confirmOrder')} */}
  //           {p.op.t('Order.order')}
  //         </XText>
  //       </View>
  //       <View style={s.statusTableLabelColumnView}>
  //         <Icon type='Ionicons' name='cash' style={StyleSheet.flatten([s.paymentStateIcon, { color: paymentStateColor }])} />
  //         <XText
  //           allowFontScaling={false}
  //           style={[s.statusTableLabelColumnTxt, isCOD ? { color: COLORS.COD_COLOR } : s.statusTableLabelColumnTxt]}>
  //           {isCOD ? p.op.t('Order.is_cod') : p.op.t('Order.pay')}
  //         </XText>
  //       </View>
  //       <View style={s.statusTableLabelColumnViewLastRow}>
  //         <Icon
  //           type='MaterialIcons'
  //           name='local-shipping'
  //           style={StyleSheet.flatten([s.shippingStateIcon, { color: shippingStateColor }])}
  //         />
  //         <XText
  //           allowFontScaling={false}
  //           style={[
  //             s.statusTableLabelColumnTxt,
  //             isShipBeforePay ? { color: COLORS.SUMMARY_ORDER_DONUT_COLOR_SCALE.myTasks_payOther } : s.statusTableLabelColumnTxt,
  //           ]}>
  //           {isShipBeforePay ? p.op.t('Order.is_ship_bofore_pay') : p.op.t('Order.ship')}
  //         </XText>
  //       </View>
  //     </View>
  //   )
  // }

  // _renderStateTableColumn({ key, headerText, headerTextSuffix, subHeaderText, data, isLastColumn, isCompleted }) {
  //   // data = [{ text: 'xxx', status: true }, { text: 'yyy', status: false }]
  //   // const order = this._getFocusedOrder()
  //   return (
  //     <View
  //       style={[
  //         s.statusTableColumnContainer,
  //         {
  //           borderTopWidth: p.op.isWeb() ? 1 : 0,
  //           borderLeftWidth: p.op.isWeb() ? 1 : 0,
  //           backgroundColor: isCompleted ? COLORS.FORM_SUCCESS_BG : 'transparent',
  //         },
  //       ]}
  //       key={`col_${key}`}>
  //       <View
  //         style={{
  //           width: '100%',
  //           height: 20,
  //           flex: 1,
  //           flexDirection: 'row',
  //           justifyContent: 'flex-start',
  //           alignItems: 'center',
  //           borderWidth: 1,
  //           borderBottomWidth: 0,
  //           borderRightWidth: isLastColumn ? 1 : 0,
  //           borderColor: TEXT_INACTIVE,
  //           paddingLeft: 3,
  //         }}>
  //         <XText
  //           allowFontScaling={false}
  //           style={{
  //             fontSize: FONT_SIZE_SMALLER,
  //             color: TEXT_ACTIVE,
  //             fontWeight: 'bold',
  //           }}>
  //           {headerText || ''}
  //         </XText>
  //         <XText
  //           numberOfLines={1}
  //           allowFontScaling={false}
  //           style={{
  //             fontSize: FONT_SIZE_SMALLER,
  //             color: TEXT_INACTIVE,
  //           }}>
  //           {headerTextSuffix}
  //         </XText>
  //       </View>
  //       <View
  //         style={{
  //           width: '100%',
  //           height: 20,
  //           flex: 1,
  //           flexDirection: 'row',
  //           justifyContent: 'flex-start',
  //           alignItems: 'center',
  //           borderWidth: 1,
  //           borderTopWidth: 0,
  //           borderBottomWidth: p.op.isWeb() ? 1 : 0,
  //           borderRightWidth: isLastColumn ? 1 : 0,
  //           borderColor: TEXT_INACTIVE,
  //           paddingLeft: 3,
  //         }}>
  //         <XText
  //           numberOfLines={1}
  //           allowFontScaling={false}
  //           style={{
  //             fontSize: FONT_SIZE_SMALLER,
  //             color: TEXT_INACTIVE,
  //           }}>
  //           {subHeaderText || ''}
  //         </XText>
  //       </View>
  //       {data.map((item, index) => {
  //         const { text, status, iconDisabled } = item
  //         // const regx = /[()]/
  //         // checkTxt เอาไว้ดูว่าถ้ามี วงเล็บ จะให้เป็น ture แล้วทำให้ตัวหนังสือเป็นสีม่วง
  //         // ในที่นี้คือมันจะเจอคำว่า (จัดส่งก่อนชำระ) ซึ่งมีวงเล็บอยู่
  //         // const checkTxt = regx.test(text)
  //         const isLastRow = index === data.length - 1
  //         const icon = status ? CheckedIcon : UncheckedIcon
  //         return (
  //           <View
  //             key={`data_${key}_${index}`}
  //             style={{
  //               width: '100%',
  //               height: 20,
  //               borderWidth: 1,
  //               borderBottomWidth: isLastRow || p.op.isWeb() ? 1 : 0,
  //               borderRightWidth: isLastColumn ? 1 : 0,
  //               borderColor: TEXT_INACTIVE,
  //               flex: 1,
  //               flexDirection: 'row',
  //               justifyContent: 'flex-start',
  //               alignItems: 'center',
  //             }}>
  //             {/* ใช้เป็น padding-left ของตัวหนังสือ */}
  //             {/* <View style={{ backgroundColor: 'red', flex: 3, height: '100%' }} /> */}
  //             {/* <View style={{ flex: 7, flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', height: '100%' }}> */}
  //             {iconDisabled ? null : icon}
  //             <XText
  //               allowFontScaling={false}
  //               style={{
  //                 marginTop: 0,
  //                 paddingLeft: iconDisabled ? 6 : 3,
  //                 height: 15,
  //                 fontSize: FONT_SIZE_SMALLER,
  //                 fontWeight: 'bold',
  //                 color: TEXT_INACTIVE,
  //                 // color: textColor,
  //               }}>
  //               {text || ''}
  //             </XText>
  //             {/* <XText
  //               allowFontScaling={false}
  //               style={{
  //                 marginTop: 2,
  //                 paddingLeft: 2,
  //                 height: 15,
  //                 fontSize: 9,
  //                 fontWeight: 'bold',
  //                 color: xCOLORS.SUMMARY_ORDER_DONUT_COLOR_SCALE.myTasks_payOther,
  //                 // color: textColor,
  //               }}>
  //               {shipBeforePay || ''}
  //             </XText> */}
  //             {/* </View> */}
  //           </View>
  //         )
  //       })}
  //     </View>
  //   )
  // }

  _renderAvailableContacts = (order) => {
    const orderType = order.get('type')
    const contacts = order.get('contract') || Map({})

    if (!orderType || orderType === 1 || !contacts) {
      return null
    }

    const availableLineId = contacts.get('l') || null
    const availableFacebookId = contacts.get('f') || null

    if (!availableLineId && !availableFacebookId) {
      return null
    }

    const txtPartnerName = order.get('partner_name') || ''
    let txtContactPartner = `ติดต่อ${orderType === 2 ? 'ผู้ซื้อ' : 'ผู้ขาย'}`
    if (txtPartnerName) {
      txtContactPartner += `: ${txtPartnerName}`
    }

    // WORKS for only sending text message. User then choose whom to share in LINE app --> onPress={() => Linking.openURL(`line://msg/text/?#456465`)}>*/}
    // WORKS--> onPress={() => Linking.openURL(`line://oaMessage/@xselly/?#456465`)}>
    // NOT WORKS as oaMessage can send only to LINE@ account -> onPress={() => Linking.openURL(`line://oaMessage/~${availableLineId}/?#456465`)}>
    return (
      <HStack alignItems='center' justifyContent='space-between'>
        <XText variant='inactive'>{txtContactPartner}</XText>
        <HStack space='1' alignItems='center' justifyContent='flex-end'>
          {/* <XText style={{ fontSize: FONT_SIZE_NORMAL }}> */}
          {/* { txtContactPartner } */}
          {/* </XText> */}
          {availableFacebookId ? (
            <Pressable onPress={() => Linking.openURL(`http://m.me/${availableFacebookId}`)}>
              <Image
                resizeMode='cover'
                style={{
                  width: FONT_ICON_LARGEST,
                  height: FONT_ICON_LARGEST,
                }}
                source={Messenger_Icon}
              />
            </Pressable>
          ) : null}

          {availableLineId ? (
            <Pressable onPress={() => Linking.openURL(getLineUrlByLineId(availableLineId))}>
              <Image
                resizeMode='cover'
                style={{
                  width: FONT_ICON_LARGEST,
                  height: FONT_ICON_LARGEST,
                }}
                source={LINE_Icon}
              />
            </Pressable>
          ) : null}
        </HStack>
      </HStack>
    )
  }

  _renderCopyLinkShareOrder = () => {
    const order = this._getFocusedOrder()
    const parent_name = `ลิงก์`
    // let parent_name = `ลิงก์ออเดอร์ ขาย#${order.get('name')}`
    // if (order.get('type') === 3) {
    //   parent_name = `ลิงก์ออเดอร์ ขาย#${order.get('parent_name')}`
    // }

    return (
      <HStack w='full' flexWrap='wrap' alignItems='center' justifyContent='space-between'>
        <HStack flex={6} alignItems='center' space='1'>
          <XIcon name='link' family={ICON_FAMILY.Ionicons} variant='inactive' />
          <XText variant='inactive'>{parent_name}</XText>
          <HelpButton title={null} message='ลิงก์ออเดอร์สำหรับให้ลูกค้าใช้ในการตรวจสอบรายละเอียดออเดอร์ และ ติดตามสถานะการจัดส่ง' />
        </HStack>
        <HStack flex={4} alignItems='center' justifyContent='flex-end' space='1'>
          <XIconButton
            variant='outline'
            name='copy'
            family={ICON_FAMILY.FontAwesome}
            onPress={() => this.createCopyLinkOrder(order, xCONS.COPY_LINK_ORDER.COPY_LINK)}
          />

          {p.op.isWeb() ? null : (
            <XIconButton
              variant='outline'
              name='share-outline'
              family={ICON_FAMILY.Ionicons}
              onPress={() => this.createCopyLinkOrder(order, xCONS.COPY_LINK_ORDER.SHARE_LINK)}
            />
          )}

          <XIconButton
            variant='outline'
            name='browsers-sharp'
            family={ICON_FAMILY.Ionicons}
            onPress={() => this.createCopyLinkOrder(order, xCONS.COPY_LINK_ORDER.OPEN_LINK)}
          />
        </HStack>
      </HStack>
    )
  }

  _renderCopyOrderLinkWithDetail = (order) => {
    // const order = this._getFocusedOrder()
    // const parentOrderName = this.props.editingOrder.get('name')
    // const orderType = order.get('type')
    // const orderName = _.includes([1, 2], orderType) ? order.get('name') : parentOrderName
    // const txtLabel = `รายละเอียดออเดอร์ #${orderName}`
    const txtLabel = `รายละเอียด`
    const helpBtn = (onPressOpenOverlay: () => void) => <HelpButton onPress={() => onPressOpenOverlay()} />
    return (
      <HStack w='full' flexWrap='wrap' alignItems='center' justifyContent='space-between'>
        <HStack flex={6} alignItems='center' space='1'>
          <XIcon name='text' family={ICON_FAMILY.MaterialCommunityIcons} variant='inactive' />
          <XText variant='inactive'>{txtLabel}</XText>
          <XHelpModal
            key={txtLabel}
            headerTitle='วิธีปรับแต่งลิงก์ออเดอร์'
            FAQ={FAQ.COPY_LINK_ORDER_FAQ}
            initiateOpenIndex={0}
            renderButton={(onPressOpenOverlay: () => void) => helpBtn(onPressOpenOverlay)}
          />
          {/* <HelpButton title={null} message='รายละเอียดของออเดอร์ขาย สำหรับให้ลูกค้าใช้ในการตรวจสอบรายละเอียดออเดอร์' /> */}
        </HStack>
        <HStack flex={4} alignItems='center' justifyContent='flex-end' space='1'>
          <XButton minH='8' variant='outline' onPress={this.navToOrderCopyTemplateView}>
            ปรับแต่ง
          </XButton>
          <XIconButton
            variant='outline'
            name='copy'
            family={ICON_FAMILY.FontAwesome}
            onPress={() => this.createSaleOrderLinkWithDetail(order)}
          />
        </HStack>
      </HStack>
    )
  }

  _renderXShippingFromOrderView = (x: any, orderState: number) => {
    // TODO :: HARD CODE ถ้า o ไม่มีก็ไม่ต้อง render
    // const haveX = x.has('o') ? x.get('o') : fromJS([])
    // if (haveX.size === 0) {
    //   return null
    // }
    const { order_id, store_id } = this.state
    return (
      <XShipping
        data={x}
        orderId={order_id}
        storeId={store_id}
        // shipping={shipping}
        // autoRequest={autoRequest}
        // eligible={eligible}
        // requestable={requestable}
        // updateTracking={this.props.updateTackingCode}
        // createTracking={this.props.createTackingCode}
        // cancelTracking={this.props.cancelTackingCode}
        // requestTracking={() => this._requestTracking()}
        // autoRequestTracking={this.props.autoRequestTracking}
        orderStatus={orderState}
      />
    )
  }

  _renderOrderRefreshControl = (hasOrderRefresh?: boolean) =>
    hasOrderRefresh ? <RefreshControl refreshing={this.state.refreshing} onRefresh={this._onRefresh} /> : null

  // _onOrderScrollViewRef = (r) => r && (this.scrollViewRef = r._root)

  _onOrderContentSizeChange = (contentWidth, contentHeight) => {
    this.scrollToBottomY = contentHeight
  }

  _renderEmptyProductSummary = () => (
    <Box
      style={{
        marginTop: 4,
        borderStyle: 'dotted',
        borderTopWidth: 1,
        borderRightWidth: 1,
        borderBottomWidth: 1,
        borderLeftWidth: 1,
        borderColor: TEXT_INACTIVE,
        justifyContent: 'center',
        alignItems: 'center',
      }}
      // button
      // onPress={() => this.onAddProducts()}
    >
      <View
        // @ts-ignore
        style={StyleSheet.flatten([s.gridColumnContainer, { height: 80 }])}>
        <XText style={[s.txtLabel, { textAlign: 'center' }]}>กรุณาเพิ่มสินค้าเข้าสู่ออเดอร์</XText>
      </View>
    </Box>
  )

  _renderUGPGDiscountDetails = (discount, titleTxt, orderType) => (
    <XCard w='full'>
      <XCard.Body>
        <XText>{titleTxt}</XText>
        <VStack w='full' space='1'>
          {discount.map((product, idx) => (
            <HStack justifyContent='space-between' key={`UGPG_Detial_${idx.toString()}`}>
              <XText style={{ fontSize: STYLES.FONT_SIZE_NORMAL, marginLeft: 8 }}>{`${product.qty} x ${product.name}`}</XText>
              <VStack alignItems='flex-end'>
                <XText bold variant={orderType === 2 ? 'danger' : 'success'}>
                  {xFmt.formatCurrency(product.discount_amount)}
                </XText>
                <XText fontSize='xs'>
                  {product.type === 1 ? `(${xFmt.formatCurrency(product.discount_amount)} ต่อชิ้น)` : `(${product.val}% ต่อชิ้น)`}
                </XText>
              </VStack>
            </HStack>
          ))}
        </VStack>
      </XCard.Body>
    </XCard>
  )

  _handleOpenCameraUPC = () => this._handlePressOpenCameraByKey('UPC')

  _handleCloseCameraUPC = () => this._handlePressCloseCameraByKey('UPC')

  _handleOpenCameraSKU = () => this._handlePressOpenCameraByKey('SKU')

  _handleCloseCameraSKU = () => this._handlePressCloseCameraByKey('SKU')

  _handlePressOpenCameraByKey = async (key: 'SKU' | 'UPC') => {
    const newVisibleCameraState = {}
    newVisibleCameraState[`isVisibleCamera${key}`] = true
    await setStatePromise(this, newVisibleCameraState)
  }

  _handlePressCloseCameraByKey = async (key: 'SKU' | 'UPC') => {
    const newVisibleCameraState = {}
    newVisibleCameraState[`isVisibleCamera${key}`] = false
    await setStatePromise(this, newVisibleCameraState)
  }

  // _handleBarcodeScanedUPC = (data) => this._handleOrderBarcodeScaned({ data, isUPC: true })
  // _handleBarcodeScanedSKU = (data) => this._handleOrderBarcodeScaned({ data, isSKU: true })
  _handleBarcodeScanedUPC = async (output: IBarcodeScannerOutput) => this._handleOrderBarcodeScaned({ data: output.data, isUPC: true })

  _handleBarcodeScanedSKU = async (output: IBarcodeScannerOutput) => this._handleOrderBarcodeScaned({ data: output.data, isSKU: true })

  _handleOrderBarcodeScaned = async (params: { data: string; isUPC?: boolean; isSKU?: boolean }): Promise<void> => {
    if (!params || !params.data || !_.isString(params.data)) {
      return
    }

    if (!params.isUPC && !params.isSKU) {
      return
    }

    const { selectedStore, fetchProducts, sellerStores, userGroups, productGroups } = this.props
    const { optionSelectedSellerStoreIndex, optionChooseProductsFromIndex } = this.state
    if (!Map.isMap(selectedStore) || !selectedStore.get('id') || !_.isFunction(fetchProducts)) {
      return
    }
    const { data, isSKU, isUPC } = params

    const body: { [key: string]: any } = {
      store_id: selectedStore.get('id'),
      queryTxt: data,
      v: 1, // เอา variants มาคิดด้วย
      offset: 0,
      limit: xCONS.PRODUCTS_FETCH_LIMIT_INIT,
      // returnDetailIfMatchOne: true,
    }
    const sellerStoresId = sellerStores.getIn([optionSelectedSellerStoreIndex, 'id'])
    if (List.isList(sellerStores) && sellerStores.size > 0 && optionChooseProductsFromIndex === 0) {
      // Change store_id to seller_store_id
      body.store_id = sellerStoresId
    }

    const userRoleId = selectedStore.get('role_id') || null
    if (userRoleId === 2) {
      // ถ้าเป็นการ fetch สินค้าของร้านฉันให้ส่ง role_id ของ selectedStore ของฉันไป
      body.role_id = userRoleId
    }

    if (isUPC) {
      body.isUPC = true
    }

    if (isSKU) {
      body.isSKU = true
    }

    if (body.store_id) {
      const additionParams = this._getProductFilterParams()
      if (_.has(additionParams, 'pg_ids')) {
        body.product_group_ids = additionParams.pg_ids
      }
      if (_.has(additionParams, 'ug_id')) {
        body.user_group_id = additionParams.ug_id
      }
      log('additionParams => ')
      const response: any = await new Promise((resolveFetch) => {
        fetchProducts({
          body,
          isNewSearch: true,
          showSpinner: true,
          successCallback: resolveFetch,
          failedCallback: () => resolveFetch(null),
        })
      })

      // Maybe do something
      if (response && _.has(response, 'products')) {
        // if (response.product && returnDetailIfMatchOne) {
        log('_handleBarcodeScaned multiple products response => ', response)
        if (!_.isArray(response.products) || response.products.length === 0) {
          let txtTitle = 'ไม่พบสินค้า '
          let txtCode = ''
          if (isSKU) {
            txtCode = 'SKU '
          } else if (isUPC) {
            txtCode = 'UPC '
          }
          txtTitle += `${txtCode}${data}`
          let txtErr = ' กรุณาตรวจสอบรหัสสินค้า\n\nไม่พบสินค้า รหัส '
          txtErr += `${txtCode}${data}`
          if (_.has(additionParams, 'pg_ids') && additionParams.pg_ids[0]) {
            const foundPg = userGroups.find((pg) => pg.get('id') === additionParams.pg_ids[0])
            if (Map.isMap(foundPg) && _.isString(foundPg.get('name'))) {
              txtErr += ` ที่สามารถเข้าถึงได้ด้วยกลุ่มสมาชิก "${foundPg.get('name')}"`
            }
          } else if (_.has(additionParams, 'ug_id') && additionParams.ug_id) {
            const foundUg = userGroups.find((ug) => ug.get('id') === additionParams.ug_id)
            if (Map.isMap(foundUg) && _.isString(foundUg.get('name'))) {
              txtErr += ` อยู่ภายในกลุ่มสมาชิก "${foundUg.get('name')}"`
            }
          }

          p.op.alert(txtTitle, txtErr)
          return
        }
        await this._doAfterBarcodeSearchMatchProducts(response, sellerStoresId)
      }
    }
  }

  _doAfterBarcodeSearchMatchProducts = async (response, sellerStoresId): Promise<void> => {
    log('_handleBarcodeScaned multiple response => ', response.products)
    const { products } = response
    if (!products || !_.isArray(products)) {
      return
    }
    // เอา product ซ้ำออก
    const newProduct = _.uniqBy(products, 'id')
    const { storeIdFromRequest } = response

    const { optionChooseProductsFromIndex, optionChooseProductsFollowingIndex } = this.state
    let chosenProductIndex = -1
    if (newProduct.length === 1) {
      // Has One Product
      chosenProductIndex = 0
    } else {
      // Has Many Products
      chosenProductIndex = await this._showActionSheetToSelectProductIndex(newProduct)
    }

    if (chosenProductIndex < -1 || chosenProductIndex >= newProduct.length) {
      // User Cancelled
      log('User Cancelled on product selection')
      return
    }

    const chosenProduct = newProduct[chosenProductIndex]
    if (!chosenProduct || !_.has(chosenProduct, 'v') || !_.isArray(chosenProduct.v)) {
      // Invalid Product Variants key
      log('Invalid Product Variants key')
      return
    }

    let chosenVariantIndex = -1
    if (chosenProduct.v.length > 1) {
      chosenVariantIndex = await this._showActionSheetToSelectVariantIndex(chosenProduct)
    } else {
      chosenVariantIndex = 0
    }

    if (chosenVariantIndex === -1 || chosenVariantIndex >= chosenProduct.v.length) {
      // User Cancelled
      log('User Cancelled on variant selection')
      return
    }
    const chosenVariant = chosenProduct.v[chosenVariantIndex]

    log('chosenProduct => ', chosenProduct)
    log('chosenVariant => ', chosenVariant)

    const { selectedStore, quickAddProductToOrder } = this.props
    const { ug_id, pg_ids } = this._getProductFilterParams()
    const store_id = selectedStore.get('id')
    const selectedUserGroups = selectedStore.get('user_groups') || List([])
    const selectedProductGroups = selectedStore.get('product_groups') || List([])

    const selectedProduct = fromJS(chosenProduct)
    const selectedVariant = fromJS(chosenVariant)

    if (!Map.isMap(selectedProduct) || !Map.isMap(selectedVariant)) {
      return
    }

    // Begin prepare action to dispatch quick add/merge
    const parent_store_id = selectedProduct.get('s') ? parseInt(selectedProduct.get('s')) : null
    let cost = selectedVariant.has('c') ? parseFloat(selectedVariant.get('c')) : null
    const product_id = selectedProduct.get('id')
    const name = selectedProduct.get('n')
    const variant = selectedVariant.get('n') // variant name
    // const qty = 1 // +1 จากของเดิม
    const available_qty = selectedVariant.get('q') || 0

    if (!available_qty || available_qty <= 0) {
      p.op.showToast('สินค้าหมดสต๊อกแล้ว กรุณาเติมสินค้าก่อนเปิดออเดอร์', 'warning')
      return
    }

    const pp_id = parseFloat(selectedVariant.get('i'))
    const thumbnail_uris = selectedProduct.get('t')
    const h = parseInt(selectedVariant.get('h')) || null

    // const price = parseFloat(selectedVariant.get('p'))
    // const price_rate_value = parseFloat(selectedVariant.get('p'))
    // const price_rate_type = 1

    // FIXME: Implement VDs
    const my_vds = selectedProduct.get('my_vds')
    const my_vds_type = selectedProduct.get('my_vds_type')
    const seller_vds = selectedProduct.get('seller_vds')
    const seller_vds_type = selectedProduct.get('seller_vds_type')

    let price = null // to be compute
    let pg_id = null // to be compute
    let ugpgSaleDiscountAmount = null // to be compute
    let ugpgSaleDiscountPercent = null // to be compute

    const productDiscount = selectedProduct.get('pdc') || Map({})
    const ugpgPurchaseDiscountAmount = productDiscount.get('ua') ? parseFloat(productDiscount.get('ua')) : null
    const ugpgPurchaseDiscountPercent = productDiscount.get('up') ? parseFloat(productDiscount.get('up')) : null

    let hasEmptyPrice = false

    // ปิดด้วยสินค้าของร้านฉันเท่านั้น !!
    let prices = selectedVariant.get('s')
    if (!prices || !List.isList(prices) || prices.size < 1) {
      // should be impossible case
      prices = List([])
    }

    switch (optionChooseProductsFromIndex) {
      case 0:
        price = selectedVariant.get('p') ? parseFloat(selectedVariant.get('p')) : null
        // log('optionChooseProductsFromIndex case 0:: price => ', price)
        break
      case 1:
        price = prices.hasIn([0, 'p']) ? parseFloat(prices.getIn([0, 'p'])) : null
        // log('optionChooseProductsFromIndex case 1:: price => ', price)
        break
      default:
        price = null
      // log('optionChooseProductsFromIndex case default:: optionChooseProductsFromIndex => ', optionChooseProductsFromIndex)
    }
    // price = prices.hasIn([0, 'p']) ? parseInt(prices.getIn([0, 'p'])) : null
    pg_id = prices.hasIn([0, 'g']) ? parseInt(prices.getIn([0, 'g'])) : null
    if (_.isNull(price) || _.isUndefined(price) || (!pg_id && optionChooseProductsFollowingIndex === 1)) {
      // p.op.alert('ไม่พบราคาสินค้าชิ้นนี้', 'กรุณาระบุราคาของสินค้าชิ้นนี้ก่อนใช้เปิดออเดอร์')
      // return
      // const isUserConfirmed = await p.op.isUserConfirm('ไม่พบราคาสินค้าชิ้นนี้', 'กรุณายืนยันว่าจะให้จะหยิบสินค้าเข้าออเดอร์แล้วกำหนดราคาไว้ ฿0 โดยคุณสามารถปรับราคาได้ภายหลัง')
      const txtProductName = variant ? `${name} ${variant}` : name
      const isUserConfirmed = await p.op.isUserConfirm(
        'ไม่พบราคาสินค้า',
        `กรุณายืนยันว่าจะให้จะหยิบสินค้า "${txtProductName}" เข้าสู่ออเดอร์แล้วกำหนดราคาไว้ ฿0 โดยคุณสามารถปรับราคาได้ภายหลัง`
      )
      if (!isUserConfirmed) {
        return
      }
      price = 0
      hasEmptyPrice = true
    }

    if (ug_id && pg_id) {
      // ถ้ามีการเลือกเปิดโดย UG มา และมี pg_id สำหรับคิดส่วนลด
      const focusedUserGroup = selectedUserGroups.find((ug) => Map.isMap(ug) && ug.get('id') === ug_id)
      if (!Map.isMap(focusedUserGroup) || !focusedUserGroup.get('id')) {
        p.op.alert('ไม่พบข้อมูลกลุ่มสมาชิก', 'กรุณาลองใหม่อีกครั้ง') // should be impossible case
        return
      }
      const discountRelations = focusedUserGroup.get('pgs') // discount relation
      const focusedDiscount = discountRelations.find((pgd) => Map.isMap(pgd) && pgd.get('id') === pg_id)
      if (Map.isMap(focusedDiscount)) {
        ugpgSaleDiscountAmount = focusedDiscount.get('discount_amount') ? parseFloat(focusedDiscount.get('discount_amount')) : null
        ugpgSaleDiscountPercent = focusedDiscount.get('discount_percent') ? parseFloat(focusedDiscount.get('discount_percent')) : null
      }
    } else if (_.isArray(pg_ids) && pg_ids.length > 0) {
      // TODO: ตอนนี้ยังไม่มีส่วนลดของ PG โดดๆ อนาคตมีก็มา compute ตรงนี้
    }
    // ถ้าไม่มี h แปลว่าสินค้าที่ดึงมา มาจากร้านขายส่ง ปรับ cost = price เพื่อไม่ให้คำนวณกำไรผิด
    if (_.isNil(h) && _.isNil(cost)) {
      cost = price
    }
    // ตรวจสอบว่า store id ของ ฉันตรงหับ store id ที่ส่งไปหรือเปล่า
    let newSellerStoresId = sellerStoresId
    if (storeIdFromRequest === store_id) {
      newSellerStoresId = undefined
    }

    const pulledOrderProduct = {
      variant,
      cost,
      product_id,
      name,
      qty: 1, // +1 จากของเดิม
      available_qty,
      pp_id,
      price,
      price_rate_value: hasEmptyPrice ? -1.33 : price, // default price ถ้าถูก user เปลี่ยน จะทำให้หน้าบ้านส่ง custom price ไป
      price_rate_type: 1, // default mode
      thumbnail_uris,
      h,
      parent_store_id,

      // FIXME: Maybe use in when Enable Vds in version 2.1
      my_vds,
      my_vds_type,
      seller_vds,
      seller_vds_type,

      // for create api body with pg_id
      ug_id,
      pg_id,

      // for compute real-time discount
      ugpgPurchaseDiscountAmount, // discount per each
      ugpgPurchaseDiscountPercent, // discount per each
      ugpgSaleDiscountAmount, // discount per each
      ugpgSaleDiscountPercent, // discount per each
    }

    quickAddProductToOrder({
      store_id,
      seller_store_id: newSellerStoresId,
      orderProducts: [pulledOrderProduct],
      // callback,
      callback: this._handleOnQuickAddProductToOrderWithBarcodeSuccess,

      // for order create validation
      ug_id,
      pg_ids,
    })
  }

  _handleOnQuickAddProductToOrderWithBarcodeSuccess = (params) => {
    log('_handleOnQuickAddProductToOrderWithBarcodeSuccess params => ', params)
  }

  // Refactor to re-use method
  _showActionSheetToSelectProductIndex = async (products: any[]): Promise<number> => {
    // const BUTTON_LABELS = products.map(pt => pt.n).concat('ยกเลิก')
    const notFoundProductPrices = '(ไม่พบราคาสินค้า)'
    const BUTTON_LABELS = products
      .map((pt) => {
        const productVariants = pt.v

        const productName = pt.n
        let txtAdditionProductInfo = ''
        if (productVariants.length === 1) {
          const vi = productVariants[0]
          // TODO for p'O ตรวจสอบให้หน่อย
          let price
          if (!_.isNil(vi.s)) {
            price = _.isArray(vi.s) && vi.s.length === 1 && _.has(vi.s[0], 'p') ? `(฿${parseFloat(vi.s[0].p)})` : notFoundProductPrices
          } else {
            price = !_.isNil(vi.p) ? vi.p : notFoundProductPrices
          }
          txtAdditionProductInfo = price
        } else if (productVariants.length > 1) {
          const variantNames = productVariants.map((vi) => vi.n)
          txtAdditionProductInfo = `- ${variantNames.join(',')}`
        }
        const txtProductItem = `${productName} ${txtAdditionProductInfo}`
        let hasAvailableQty = false
        for (const vi of productVariants) {
          const vAvailableQty = _.has(vi, 'q') && vi.q ? _.has(vi, 'q') && vi.q > 0 : false
          if (vAvailableQty) {
            hasAvailableQty = true
            break
          }
        }
        return hasAvailableQty ? txtProductItem : `(หมดสต๊อก) ${txtProductItem}`
      })
      .concat('ยกเลิก')

    const CANCEL_INDEX = BUTTON_LABELS.length - 1
    // log('BUTTON_LABELS => ', BUTTON_LABELS)
    const selectedIndex = await new Promise<number>((chooseAtIndex) => {
      p.op.showActionSheet(
        {
          options: BUTTON_LABELS,
          cancelButtonIndex: CANCEL_INDEX,
          // destructiveButtonIndex: -1,
          title: 'เลือกสินค้าเพื่อเพิ่ม 1 ชิ้นในออเดอร์...',
        },
        (idx: string | number) => {
          // log('ActionSheet.show idx => ', idx)

          const intIdx = _.isString(idx) ? parseInt(idx) : idx
          chooseAtIndex(intIdx)
        }
      )
    })

    // log('selectedIndex => ', selectedIndex)

    if (selectedIndex >= 0 && selectedIndex < CANCEL_INDEX && products.length > selectedIndex) {
      // log('products.length => ', products.length)
      // log('selectedIndex => ', selectedIndex)
      return selectedIndex
    }
    return -1
  }

  _showActionSheetToSelectVariantIndex = async (product: { [key: string]: any }): Promise<number> => {
    if (!product || !product.v || product.v.length < 1) {
      return -1
    }
    const { optionChooseProductsFromIndex } = this.state
    const variants: any[] = product.v
    const BUTTON_LABELS = variants
      .map((vi) => {
        const productName = product.n
        const variantName = vi.n
        let price
        switch (optionChooseProductsFromIndex) {
          case 0:
            price = !_.isNil(vi.p) ? `(฿${parseFloat(vi.p)})` : '(ไม่พบราคาสินค้า)'
            break
          case 1:
            price = _.isArray(vi.s) && vi.s.length === 1 && _.has(vi.s[0], 'p') ? `(฿${parseFloat(vi.s[0].p)})` : '(ไม่พบราคาสินค้า)'
            break
          default:
            price = '(ไม่พบราคาสินค้า)'
        }
        const txtProductItem = `${productName} - ${variantName} ${price}`

        const hasAvailableQty = _.has(vi, 'q') && vi.q > 0
        return hasAvailableQty ? txtProductItem : `(หมดสต๊อก) ${txtProductItem}`
      })
      .concat('ยกเลิก')
    const CANCEL_INDEX = BUTTON_LABELS.length - 1
    const selectedIndex = await new Promise<number>((chooseAtIndex) => {
      p.op.showActionSheet(
        {
          options: BUTTON_LABELS,
          cancelButtonIndex: CANCEL_INDEX,
          // destructiveButtonIndex: -1,
          title: 'เลือกตัวเลือกสินค้าเพื่อเพิ่ม 1 ชิ้นในออเดอร์...',
        },
        (idx: string | number) => {
          const intIdx = _.isString(idx) ? parseInt(idx) : idx
          chooseAtIndex(intIdx)
        }
      )
    })
    if (selectedIndex >= 0 && selectedIndex < CANCEL_INDEX && variants.length > selectedIndex) {
      return selectedIndex
    }
    return null
  }

  _renderSellerStoreSelectorListItem = ({ item, index }) => {
    const { optionSelectedSellerStoreIndex } = this.state
    const isChecked = optionSelectedSellerStoreIndex === index
    const coverImgUri = item.get('cover_thumbnail_uri')
    const storeName = item.get('name')
    const storeDesc = item.get('description')
    // const handlePress = () => this._handleChangeSellerStore(index)
    return this._renderSellerStoreSelectorItem({ coverImgUri, storeName, storeDesc, isChecked, isSelector: !isChecked })
    // return (
    //   <View style={{ borderBottomWidth: p.op.isWeb() ? 0 : 0.5, borderColor: TEXT_INACTIVE }}>
    //     {this._renderSellerStoreSelectorItem({ handlePress, coverImgUri, storeName, storeDesc, isChecked })}
    //   </View>
    // )
  }

  _renderSellerStoreSelectorItem = ({ coverImgUri, storeName, storeDesc, isChecked = false, isSelector = false }) => (
    <XCard>
      <XCard.Body>
        <HStack alignItems='center' justifyContent='space-between' space='1'>
          <HStack alignItems='center' space='1'>
            <FastImage
              style={{ height: 50, width: 50 }}
              source={{
                uri: coverImgUri || 'https://s3-ap-southeast-1.amazonaws.com/xselly/c/1.jpg',
                priority: FastImage.priority.low,
              }}
              // resizeMode='cover'
              resizeMode={FastImage.resizeMode.cover}
            />
            <VStack>
              <XText>{storeName}</XText>
              {storeDesc ? (
                <XText variant='inactive' numberOfLines={3}>
                  {storeDesc}
                </XText>
              ) : null}
            </VStack>
          </HStack>
          <HStack alignItems='center' justifyContent='flex-end' space='1'>
            {isChecked ? <XIcon name='check' family={ICON_FAMILY.FontAwesome} /> : null}
            {isSelector ? <ForwardIcon inactive /> : null}
          </HStack>
        </HStack>
      </XCard.Body>
    </XCard>
  )

  // _renderOrderStoreProductsHeader = ({ order, products, store_id, isFirstStore, isRemovable, isCreateMode }) => {
  //   // log('_renderOrderStoreProductsHeader store_id', store_id)
  //   // log('_renderOrderStoreProductsHeader products.toJS()', products.toJS())

  //   if (_.isNull(store_id) || parseInt(store_id) <= 0) {
  //     return null
  //   }

  //   const { mode } = this.state
  //   const { name, isMyStore, isNotFound } = this._getStoreNameFromStoreId(store_id, true)
  //   const txtStoreName = isNotFound ? 'ไม่พบชื่อร้านค้า' : name
  //   const orderStoreId = order.get('store_id') || null
  //   const parentOrderType = order.get('type')
  //   let orderType
  //   let orderId
  //   let txtOrderName = '-'
  //   let txtShippingType = '-'
  //   if (orderStoreId === store_id) {
  //     orderType = order.get('type')
  //     txtOrderName = `#${order.get('name')}` || null
  //     orderId = order.get('id') || null
  //     txtShippingType = this._getShippingTypeLabel(order)
  //   } else {
  //     const targetOrderIndex = order.get('suborders') ? order.get('suborders').findIndex((so) => so.get('store_id') === store_id) : null

  //     if (targetOrderIndex > -1 && order.getIn(['suborders', targetOrderIndex, 'id'])) {
  //       const subOrder = order.getIn(['suborders', targetOrderIndex]) || Map({})
  //       orderType = subOrder.get('type')
  //       txtOrderName = `#${subOrder.get('name')}` || null
  //       orderId = subOrder.get('id') || null
  //       txtShippingType = this._getShippingTypeLabel(subOrder)
  //     }
  //   }

  //   const txtFromOrderName = txtOrderName ? `${orderType === 3 ? 'ซื้อ' : 'ขาย'}${txtOrderName}` : null

  //   return (
  //     <XCard>
  //       <XCard.Header>
  //         <HStack justifyContent='space-between' space='1'>
  //           <HStack flex={1} space='1'>
  //             <XImageIcon variant='inactive' source={isMyStore ? tb_my0 : tb_seller0} />
  //             <XText fontSize='xs'>{`สินค้าจาก${txtStoreName}`}</XText>
  //           </HStack>
  //           {!isCreateMode && txtFromOrderName && parentOrderType !== 3 && orderType === 3 ? (
  //             <XButton variant={mode !== EDIT ? 'primary' : 'outline'} onPress={() => this._handleOnPressChildOrderId(orderId)}>
  //               {txtFromOrderName}
  //             </XButton>
  //           ) : null}
  //         </HStack>
  //       </XCard.Header>
  //     </XCard>
  //   )

  //   return (
  //     <View>
  //       {isFirstStore ? <HStack>{this._renderProductHeader({ isRemovable, orderType })}</HStack> : null}
  //       <View
  //         style={{
  //           borderColor: TEXT_INACTIVE,
  //           borderWidth: 1,
  //           borderTopWidth: 0,
  //           backgroundColor: BG_LIGHT_GREY_ALTERNATIVE,
  //           paddingLeft: 4,
  //           paddingRight: 4,
  //           paddingBottom: 4,
  //           ...Platform.select({
  //             web: {
  //               borderTopWidth: 1,
  //               borderLeftWidth: 1,
  //               borderRightWidth: 1,
  //             },
  //           }),
  //         }}>
  //         <HStack>
  //           <Image
  //             resizeMode='contain'
  //             style={{
  //               width: 24,
  //               height: 24,
  //             }}
  //             source={isMyStore ? tb_my0 : tb_seller0}
  //           />
  //           <XText
  //             numberOfLines={1}
  //             style={{
  //               flex: 1,
  //               textAlign: 'left',
  //               fontSize: FONT_SIZE_SMALLER,
  //               fontWeight: 'bold',
  //               color: TEXT_ACTIVE,
  //               marginTop: 0,
  //               alignSelf: 'center',
  //             }}>{`สินค้าจาก${txtStoreName}`}</XText>
  //         </HStack>
  //         <HStack>
  //           {!isCreateMode ? (
  //             <VStack style={{ flex: 1, flexDirection: 'row' }}>
  //               <XText
  //                 numberOfLines={1}
  //                 style={{
  //                   flex: 1,
  //                   textAlign: 'left',
  //                   fontSize: FONT_SIZE_SMALLER,
  //                   color: TEXT_ACTIVE,
  //                   marginTop: 0,
  //                   alignSelf: 'center',
  //                   paddingLeft: 4,
  //                 }}>{`รูปแบบจัดส่ง: ${txtShippingType}`}</XText>
  //             </VStack>
  //           ) : null}
  //           {!isCreateMode && txtFromOrderName && parentOrderType !== 3 && orderType === 3 ? (
  //             <VStack style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-end' }}>
  //               <XButton
  //                 bgColor={mode !== EDIT ? COLORS.APP_MAIN : COLORS.FORM_INACTIVE}
  //                 onPress={() => this._handleOnPressChildOrderId(orderId)}
  //                 style={{
  //                   paddingTop: 0,
  //                   paddingBottom: 0,
  //                   height: 22,
  //                   backgroundColor: FORM_PRIMARY_BG,
  //                   borderWidth: 1,
  //                   borderRadius: 7,
  //                 }}>
  //                 <XText
  //                   style={{
  //                     fontSize: FONT_SIZE_NORMAL,
  //                     paddingHorizontal: 12,
  //                     color: mode === EDIT ? TEXT_INACTIVE : APP_MAIN,
  //                     fontWeight: 'bold',
  //                   }}>
  //                   {txtFromOrderName}
  //                 </XText>
  //               </XButton>
  //             </VStack>
  //           ) : null}
  //         </HStack>
  //         {/* <XText numberOfLines={1} style={{ */}
  //         {/* flex: 1, */}
  //         {/* textAlign: 'left', */}
  //         {/* fontSize: FONT_SIZE_SMALLER, */}
  //         {/* fontWeight: 'bold', */}
  //         {/* marginTop: 0, */}
  //         {/* paddingLeft: 4, */}
  //         {/* color: TEXT_ACTIVE, */}
  //         {/* }}>{ txtFromOrderName }</XText> */}
  //       </View>
  //     </View>
  //   )
  // }

  // _renderProductHeader({ isRemovable, orderType }) {
  //   return (
  //     <View
  //       key='Products_Header'
  //       style={{
  //         // backgroundColor: 'red',
  //         // backgroundColor: isChild ? FORM_INACTIVE : null,
  //         width: '100%',
  //         flexDirection: 'row',
  //         justifyContent: 'center',
  //         // alignItems: 'stretch',
  //         borderWidth: 1,
  //         // borderTopWidth: (isFirstGroup && index === 0) ? 1 : 0,  // render Top Border เฉพาะ row แรก
  //         borderColor: TEXT_INACTIVE,
  //         ...Platform.select({
  //           web: {
  //             borderTopWidth: 1,
  //             borderLeftWidth: 1,
  //             borderRightWidth: 1,
  //           },
  //         }),
  //       }}>
  //       {isRemovable ? (
  //         <View
  //           style={{
  //             flex: 1,
  //             flexDirection: 'column',
  //             justifyContent: 'center',
  //             // alignItems: 'stretch',
  //             // backgroundColor: FORM_ERROR,
  //             // borderRightWidth: 1,
  //             borderColor: TEXT_INACTIVE,
  //           }}
  //         />
  //       ) : null}
  //       <View style={{ flex: 7, padding: 3 }}>
  //         <View style={s.productSummaryTableHeader}>
  //           <XText allowFontScaling={false} style={s.productSummaryTableHeaderText}>
  //             รายการ
  //           </XText>
  //         </View>
  //       </View>
  //       {/* price qty subtotal */}
  //       <View
  //         style={{
  //           flex: 5,
  //           flexDirection: 'row',
  //           justifyContent: 'center',
  //           alignItems: 'center',
  //           borderLeftWidth: 1,
  //           borderColor: TEXT_INACTIVE,
  //           height: 34,
  //         }}>
  //         <View>
  //           <XText allowFontScaling={false} numberOfLines={2} style={[s.productSummaryTableHeaderText, { marginTop: 2 }]}>
  //             {this._getColNameProductSummaryPrice(orderType)}
  //           </XText>
  //         </View>
  //       </View>
  //       <View
  //         style={{
  //           flex: 3,
  //           flexDirection: 'row',
  //           justifyContent: 'center',
  //           alignItems: 'center',
  //           borderLeftWidth: 1,
  //           borderColor: TEXT_INACTIVE,
  //         }}>
  //         <View style={s.productSummaryTableHeader}>
  //           <XText allowFontScaling={false} style={s.productSummaryTableHeaderText}>
  //             จน.
  //           </XText>
  //         </View>
  //       </View>
  //       <View
  //         style={{
  //           flex: 5,
  //           flexDirection: 'row',
  //           justifyContent: 'center',
  //           alignItems: 'center',
  //           borderLeftWidth: 1,
  //           borderColor: TEXT_INACTIVE,
  //         }}>
  //         <View style={s.productSummaryTableHeader}>
  //           <XText allowFontScaling={false} style={s.productSummaryTableHeaderText}>
  //             รวม
  //           </XText>
  //         </View>
  //       </View>
  //     </View>
  //   )
  // }

  _renderStoreProductsFooter({ isRemovable, canShowProfit, amountQty, amountProducts, amountProfit, hasVdDiscount }) {
    return (
      <View
        key='Products_Footer'
        style={{
          // backgroundColor: 'red',
          // backgroundColor: isChild ? FORM_INACTIVE : null,
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'center',
          // alignItems: 'stretch',
          borderTopWidth: 0,
          borderLeftWidth: 0,
          borderRightWidth: 1,
          borderBottomWidth: 0,
          // borderBottomWidth: 0,
          // borderLeftWidth: 0,
          // borderTopWidth: (isFirstGroup && index === 0) ? 1 : 0,  // render Top Border เฉพาะ row แรก
          borderColor: TEXT_INACTIVE,
          ...Platform.select({
            web: {
              borderTopWidth: 1,
            },
          }),
        }}>
        {isRemovable ? (
          <View
            style={{
              // flex: p.op.isWeb() ? 2 : 1,
              flex: 2,
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          />
        ) : null}
        <View
          style={{
            flex: 12,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            borderLeftWidth: 1,
            // borderRightWidth is MAGICK NUMBER
            borderRightWidth: 7,
            // ...Platform.select({
            //   ios: { borderRightWidth: isRemovable ? 7 : 6 },
            //   android: { borderRightWidth: 7 }
            // }),
            // borderLeftWidth: 1,
            // borderRightWidth: 1,
            borderColor: 'transparent',
          }}>
          <XText
            style={{
              fontSize: STYLES.FONT_SIZE_NORMAL,
              color: amountProfit >= 0 ? COLORS.BRAND_Success : COLORS.BRAND_Danger,
              paddingLeft: 4,
              flex: 1,
              textAlign: 'left',
            }}
            allowFontScaling={false}
            numberOfLines={1}>
            {canShowProfit && this.state.showProfit
              ? `รวม${amountProfit >= 0 ? 'กำไร' : 'ขาดทุน'}ค่าสินค้า ${hasVdDiscount ? '(ก่อนส่วนลดขายส่ง)' : ''} ${xFmt.formatCurrency(
                  amountProfit
                )}`
              : null}
          </XText>
        </View>
        <View
          style={{
            flex: 3,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            borderLeftWidth: 1,
            borderBottomWidth: 1,
            borderColor: TEXT_INACTIVE,
          }}>
          <XText
            // @ts-ignore
            style={[TXT_SMALLER_BOLD, { flex: 1, textAlign: 'center' }]}
            allowFontScaling={false}
            numberOfLines={1}>
            {amountQty}
          </XText>
        </View>
        <View
          style={{
            flex: 5,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            borderLeftWidth: 1,
            borderBottomWidth: 1,
            borderColor: TEXT_INACTIVE,
          }}>
          <XText
            // @ts-ignore
            style={[TXT_SMALLER_BOLD, { flex: 1, textAlign: 'right', paddingRight: 2 }]}
            allowFontScaling={false}
            numberOfLines={1}>
            {xFmt.formatCurrency(amountProducts)}
          </XText>
        </View>
      </View>
    )
  }

  _renderShippingCostRow = ({ order, rightComponent }) => {
    // TODO: Refine some logic here to the base class
    const orderType = order.get('type')
    const isCreateMode = this.state.mode === CREATE
    let label
    if (isCreateMode || orderType === 1) {
      label = 'ค่าจัดส่งที่คิดลูกค้า'
    } else if (orderType === 2) {
      label = 'ค่าจัดส่งที่คิดตัวแทน'
    } else {
      label = 'ค่าจัดส่ง'
    }
    const { lastShippingCost } = this.state
    const shippingCostLabel = lastShippingCost ? `${label} (คำนวณล่าสุด: ฿${lastShippingCost})` : label
    // log('_renderShippingCostRow.shippingCostLabel: ' + shippingCostLabel)
    // const sShippingInfo = {
    //   flexDirection: 'row',
    //   justifyContent: 'flex-start',
    //   alignItems: 'center',
    //   height: 24,
    // }
    return (
      <HStack w='full' space='1'>
        <VStack flex={1}>
          <XText variant='inactive'>{shippingCostLabel}</XText>
          {isCreateMode ? (
            <HStack
              alignItems='center'
              space='1'
              // @ts-ignore
              // style={sShippingInfo}
            >
              <XButton
                size='xs'
                variant='outline'
                // style={[STYLES.BTN_MODE_ACTIVE_SECONDARY, { padding: 3, borderWidth: 1, borderRadius: 7, marginRight: 3 }]}
                onPress={() => {
                  // ---- Deprecated pp_shippingTypeIndex ----
                  // // TODO: Nav to shipping type if
                  // // _handleShippingTypeMissing KENGG
                  // // log('this.state', this.state)
                  // const isCreatingOrder = this.state.mode === CREATE
                  // // log('isCreatingOrder: ' + isCreatingOrder)
                  // const { pp_shippingTypeIndex } = this.props
                  // const shippingTypeItem = this.shippingTypeItems.find(sti => sti.index === pp_shippingTypeIndex)
                  // const shipping_type_id = shippingTypeItem && shippingTypeItem.value ? shippingTypeItem.value : 0
                  // // log ('pp_shippingTypeIndex' + pp_shippingTypeIndex)
                  // // log('shippingTypeItems', this.shippingTypeItems)
                  // // log('shippingTypeItem', shippingTypeItem)
                  // // log('shipping_type_id: ' + shipping_type_id)
                  // this._calcShippingCost(order, isCreatingOrder, shipping_type_id)
                  // // this.props.onShippingCostCalc(isCreatingOrder)
                  // // util.showSuccessToast(`คำนวนอยู่`)
                  const isCreatingOrder = this.state.mode === CREATE
                  const shipping_type_id = this._getFocusedOrder().get('shipping_type_id')
                  this._calcShippingCost(order, isCreatingOrder, shipping_type_id)
                }}>
                {/* <XText
                  // @ts-ignore
                  style={StyleSheet.flatten([
                    STYLES.BTN_TEXT_MODE_ACTIVE_SECONDARY,
                    {
                      fontSize: STYLES.FONT_SIZE_SMALLER,
                    },
                  ])}> */}
                คำนวณค่าจัดส่ง
                {/* </XText> */}
              </XButton>
              <HelpButton
                title='คำนวณค่าจัดส่งอัตโนมัติ'
                message={'กดปุ่ม "คำนวณค่าจัดส่ง" เพื่อดูค่าจัดส่งจาก รูปแบบจัดส่ง รายการและจำนวนสินค้าที่แสดงอยู่ในออเดอร์นี้'}
              />
            </HStack>
          ) : null}
          {
            // KENGGG
            !isCreateMode && order && order.get('shipping_cost_cal') === 1 ? (
              <HStack alignItems='center'>
                <XIcon name='checkmark-circle' variant='success' fontSize='xs' />
                <XText variant='success'>ถูกคำนวณโดยระบบ</XText>
              </HStack>
            ) : null
          }
        </VStack>
        <HStack flex={1} justifyContent='flex-end'>
          <Box w='32'>{rightComponent || null}</Box>
        </HStack>
      </HStack>
    )
  }

  renderFooterButton = ({ text, mode, onPress, btnStyle = null, perm }) => {
    let colorScheme = 'primary'
    let variant = 'solid'
    if (mode === xCONS.BTN_MODE.ACTIVE_BLUE_SECONDARY) {
      colorScheme = 'blue'
    }
    if (mode === xCONS.BTN_MODE.ACTIVE_SECONDARY) {
      variant = 'outline'
    }

    return mode !== xCONS.BTN_MODE.INACTIVE && xAcl.canDoAtSelectedStore(perm) ? (
      <XButton
        flex={1}
        h='full'
        minH='10'
        // size='md'
        variant={variant}
        colorScheme={colorScheme}
        // style={[btnStyle, btnOnMode(mode), { paddingLeft: 4, paddingRight: 4, height: 50, flexDirection: 'row' }]}
        disabled={xCONS.BTN_MODE.INACTIVE === mode}
        onPress={onPress}>
        {text}
      </XButton>
    ) : (
      <Box key={perm} flex={1} />
    )
  }

  // renderFooterButton = ({ text, mode, onPress, btnStyle = null, perm }) =>
  //   mode !== xCONS.BTN_MODE.INACTIVE && xAcl.canDoAtSelectedStore(perm) ? (
  //     <XButton
  //       style={[btnStyle, btnOnMode(mode), { paddingLeft: 4, paddingRight: 4, height: 50, flexDirection: 'row' }]}
  //       disabled={xCONS.BTN_MODE.INACTIVE === mode}
  //       onPress={onPress}>
  //       <XText style={[textOnBtnMode(mode), { fontWeight: 'bold' }]}>{text}</XText>
  //     </XButton>
  //   ) : (
  //     <View key={perm} style={{ flex: 1, backgroundColor: 'transparent' }} />
  //   )

  _handleChangeTab = (e) => {
    const { from, i, ref } = e
    const { mode, orderTabIndex } = this.state
    if (i >= 0 && i !== orderTabIndex) {
      // if (i === 0) {
      //   this._handleOnPressParentOrderId()
      // } else {
      //   this.changeOrderTabIndexTo(i)
      // }
      this.changeOrderTabIndexTo(i)
    }
  }

  _renderHeaderTabButton = (order, idx) => {
    if (!order || !isImmutable(order)) {
      return null
    }
    const isEmptyOrder = !(order && order.get('updated_at'))

    let orderType
    if (isEmptyOrder) {
      orderType = idx === 0 ? 1 : 3 // ถ้าไม่มีข้อมูล ให้ assume ว่า idx 0 เป็นออเดอร์ขาย
    } else {
      orderType = order.get('type')
    }

    const orderId = order.get('id')
    const txtOrderContext = orderType === 3 ? 'ซื้อ' : 'ขาย'
    const txtOrderName = order.get('name') ? `${txtOrderContext}#${order.get('name')}` : null

    const activeTextColor = COLORS.TEXT_ACTIVE
    const inactiveTextColor = COLORS.TEXT_INACTIVE
    const isTabActive = this.state.orderTabIndex === idx
    const textColor = isTabActive ? activeTextColor : inactiveTextColor
    // const fontWeight = isTabActive ? 'bold' : 'normal'
    // const textDecorationLine = isTabActive ? 'underline' : 'none'
    const backgroundColor = idx > 0 ? COLORS.APP_SECONDARY : COLORS.WHITE
    // log('_renderCustomHeaderScrollableChildTab name => ', name)
    // log('_renderCustomHeaderScrollableChildTab page => ', page)
    // log('_renderCustomHeaderScrollableChildTab isTabActive => ', isTabActive)

    const onPressTab = async () => {
      if (idx === 0) {
        await this._handleOnPressParentOrderId()
      }
      {
        await this._handleOnPressChildOrderId(orderId)
      }
    }

    return (
      <TouchableOpacity
        key={`OrderTabBtn_${txtOrderName}_${idx}`}
        // @ts-ignore
        accessible
        // @ts-ignore
        accessibilityLabel={txtOrderName}
        // @ts-ignore
        accessibilityTraits='button'
        style={idx === 0 ? {} : { marginLeft: 4 }}
        onPress={onPressTab}>
        <Box
          h='10'
          px='3'
          borderTopRightRadius='xl'
          alignItems='center'
          justifyContent='center'
          bg={backgroundColor}
          // // @ts-ignore
          // style={StyleSheet.flatten([
          //   INTL_STYLES.tabStyle,
          //   {
          //     backgroundColor,
          //   },
          // ])}
        >
          <XText bold={isTabActive} style={[{ color: textColor }]}>
            {txtOrderName}
          </XText>
          {this._renderTabUnderLine(isTabActive)}
          {/* {this._renderTabTriangleBg()} */}
          {/* {this._renderTabTriangle()} */}
          {/* {this._renderTabRightBorderLine()} */}
        </Box>
      </TouchableOpacity>
    )
  }

  _renderTabUnderLine = (isTabActive: boolean) => (isTabActive ? <View pointerEvents='none' style={INTL_STYLES.tabUnderline} /> : null)

  _renderTabTriangle = () => <View pointerEvents='none' style={INTL_STYLES.tabTriangle} />

  _renderTabTriangleBg = () => <View pointerEvents='none' style={INTL_STYLES.tabTriangleBg} />

  _renderTabRightBorderLine = () => <View pointerEvents='none' style={INTL_STYLES.tabRightBorderLine} />

  _renderFooterCreateOrder = () => {
    const { isInitialized } = this.state
    const { editingOrder } = this.props
    if (!editingOrder || !Map.isMap(editingOrder) || !isInitialized) {
      return null
    }

    return (
      <HStack p='2' bgColor={COLORS.APP_SECONDARY}>
        <XButton py='2' w='full' minH='10' onPress={() => this._createOrder()}>
          ยืนยันการสร้างออเดอร์
        </XButton>
      </HStack>
    )
    // return (
    //   <HStack>
    //     <HStack style={STYLES.FOOTER_BG}>
    //       <XButton style={btnOnMode(xCONS.BTN_MODE.ACTIVE_PRIMARY)} onPress={() => this._createOrder()}>
    //         <XText style={textOnBtnMode(xCONS.BTN_MODE.ACTIVE_PRIMARY)}>ยืนยันการสร้างออเดอร์</XText>
    //       </XButton>
    //     </HStack>
    //   </HStack>
    // )
    // return (
    //   <Footer>
    //     <FooterTab style={{ borderRadius: 7, marginHorizontal: 3, marginBottom: 2 }}>
    //       <XButton onPress={this._createOrder}>
    //         <XText style={{ fontSize: FONT_SIZE_LARGER, color: '#fff' }}>{'ยืนยันการสร้างออเดอร์'}</XText>
    //       </XButton>
    //     </FooterTab>
    //   </Footer>
    // )
  }

  // _onHamburgerWillShow = async (): Promise<void> => {
  //   if (this.inProcess) {
  //     return
  //   }
  //   this.inProcess = true
  //   await setStatePromise(this, { isHamburgerMenuOpened: true })
  //   // if (this.hamburgerMenuRef && _.isFunction(this.hamburgerMenuRef.select)) {
  //   //   this.hamburgerMenuRef.select(-1)
  //   // }
  //   this.inProcess = false
  // }

  // _onHamburgerWillHide = async (): Promise<void> => {
  //   await setStatePromise(this, { isHamburgerMenuOpened: false })
  // }

  _onHamburgerMenuSelect = async (index: number): Promise<void> => {
    if (this.inSelectHamburger) {
      return
    }
    this.inSelectHamburger = true
    // log('ModalDropdown:: onSelect index => ', index)
    // log('_onHamburgerMenuSelect ModalDropdown:: onSelect index => ', index)
    const selectedItem = this.state.hamburgerMenuOptions[index]
    // log('ModalDropdown:: onSelect selectedItem => ', selectedItem)
    if (selectedItem && _.isFunction(selectedItem.onPress)) {
      // MUST!! delay for finished render dropdown before call method
      // await new Promise((delayOnPress) => {
      //   setTimeout(delayOnPress, 200)
      // })
      await xUtil.delay(200)
      selectedItem.onPress()
      // this._afterHamburgerMenuSelectedSafetyClose()
    }
    this.inSelectHamburger = false
    // await setStatePromise(this, { isHamburgerMenuOpened: false })
  }

  // _afterHamburgerMenuSelectedSafetyClose = () => {
  //   setTimeout(() => {
  //     if (this.state.isHamburgerMenuOpened) {
  //       this.setState({ isHamburgerMenuOpened: false })
  //     }
  //   }, 200)
  // }

  _renderCustomHeader = () => {
    const { editingOrder } = this.props
    const { mode, orderTabsVisibility, submitting, isHamburgerMenuOpened, isInitialized } = this.state
    let title = null
    let headerLeftProps: INavHeaderButtonProps = {
      submitting: submitting || isHamburgerMenuOpened || !isInitialized,
    }
    const headerRightProps: INavHeaderButtonProps = {
      submitting: submitting || isHamburgerMenuOpened || !isInitialized,
    }
    let headerRightCustomRender = null
    const order = mode === CREATE ? editingOrder : this._getFocusedOrder()
    const type = order.get('type')
    const order_name = order.has('name') ? order.get('name') : 'ไม่พบชื่อออเดอร์'
    const typeText = type && type === 3 ? 'ซื้อ' : 'ขาย'
    // ORDER VIRE HEADER
    switch (mode) {
      case CREATE:
        title = p.op.t('Order.createOrder')
        headerLeftProps.backIcon = true
        headerLeftProps.onPressItem = this._goBack
        // headerRightProps.settingIcon = true
        // headerRightProps.onPressItem = this._settingCreateOrder
        headerRightCustomRender = this._renderHamburgerButton
        break
      case EDIT:
        title = p.op.t('Order.orderEditTitle', { typeText, num: order_name })
        headerLeftProps.label = p.op.t('done')
        headerLeftProps.onPressItem = this._handleConfirmEditOrder
        headerRightProps.label = p.op.t('cancel')
        headerRightProps.onPressItem = this._handleCancelConfirmEditOrder
        break
      case VIEW_EDIT:
        headerLeftProps.backIcon = true
        headerLeftProps.onPressItem = this._goBack
        title = order_name ? p.op.t('Order.orderNumCard', { typeText, num: order_name }) : ''
        headerRightCustomRender = this._renderHamburgerButton
        break
      case VIEW_ONLY:
        headerLeftProps.backIcon = true
        headerLeftProps.onPressItem = this._goBack
        title = order_name ? p.op.t('Order.orderNumCard', { typeText, num: order_name }) : ''
        headerRightCustomRender = this._renderHamburgerButton
        break

      default:
        title = ''
        headerLeftProps.backIcon = true
        headerLeftProps.onPressItem = this._goBack
    }

    if (this.isGoBackButtonHidden() && headerLeftProps.backIcon) {
      headerLeftProps = undefined
    }

    return (
      <XCustomHeader
        headerLeftProps={headerLeftProps}
        title={title}
        headerRightProps={headerRightProps}
        headerRight={_.isFunction(headerRightCustomRender) ? headerRightCustomRender() : null}
      />
    )
  }

  _renderHamburgerButton = (): JSX.Element | null => {
    // const hamburgerMenuOptions = this._calculateHamburgerMenuOptions()
    const { hamburgerMenuOptions, isInitialized, submitting, refreshing, mode } = this.state
    const isDisabled = !isInitialized || submitting || refreshing
    const order = this._getFocusedOrder()
    const type = order.get('type')
    // const permission = acl.canDoAtSelectedStore(PERM_STORE_HELPER.ORDER_PRINT_SHIPPING)
    // const showPrinter = mode === VIEW_EDIT && permission && this._canPrintShippingLabel(order)
    let showPrinter = type === 1 || type === 2
    // const marginRightFromMode = showPrinter ? 25 : 5
    // const showAllShipping = permission && this._canPrintShippingLabel(order)
    // log('_renderHamburgerButton:: hamburgerMenuOptions => ', hamburgerMenuOptions)
    if (!xUtil.canHelperPrintShippingLabel()) {
      showPrinter = false
    }

    return (
      // MUST specify height here as there is iOS bug that if no view has height (in this case the printer icon is hidden),
      // the ... button will DISAPPEARED
      // <View style={{ flexDirection: 'row', marginRight: marginRightFromMode, height: 36 }}>
      <HStack alignItems='center' justifyContent='flex-end'>
        {/* <TouchableOpacity onPress={() => this._selectedShippingLabel()}> */}
        {/* FIXME: เป็น temporary layout เพราะว่าไม่ได้ design มาก่อนว่า header จะมีหลายปุ่ม */}
        {/* {showPrinter ? (
          <TouchableOpacity
            style={{
              flex: 1,
              position: 'absolute',
              width: 34,
              // height: 36,
              height: '100%',
              left: -44,
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onPress={this._selectedShippingLabel}>
            <XIcon
              name='printer'
              family={'AntDesign'}
              style={{
                alignSelf: 'center',
                textAlign: 'center',
                color: isDisabled ? TEXT_INACTIVE : TEXT_PRIMARY,
                fontSize: 23,
                // minWidth: 80,
                // width: 50,
                // height: 50,
                flex: 0,
                paddingTop: 0,
                // จริงๆ 0 มันกลางแล้ว แต่ปรับขึ้นเป็น 2 เพราะสายตาคนมองเทียบกับอย่างอื่นแล้วมันจึงดูไม่กลาง
                paddingBottom: Platform.OS === 'android' ? 2 : 0,
                paddingLeft: 0,
                paddingRight: 0,
                // marginTop: Platform.OS === 'android' ? 5 : 4, // <<< PRINTER MARGIN
              }}
            />
          </TouchableOpacity>
        ) : null} */}

        {/* <Box position='absolute' left={-34}> */}
        {showPrinter ? (
          <PrintingOptionOverlayButton
            // buttonStyle={{ marginLeft: -30, marginTop: -4 }}
            selectedStore={this.props.selectedStore}
            disabled={isDisabled}
            onSubmit={this.summitDownloadPrinting}
            // onSettings={this.navToStoreSettingAllPrintingShipping}
          />
        ) : null}
        {/* </Box> */}

        <XDropdown
          key={`Hamburger_${isDisabled}`}
          disabled={isDisabled}
          // ref={(r) => r && (this.hamburgerMenuRef = r)}
          // style={{
          //   // flex: 0,
          //   flex: 1,
          //   width: 34,
          //   // backgroundColor: 'red',
          //   height: '100%',
          //   alignItems: 'center',
          //   justifyContent: 'center',
          //   flexDirection: 'row',
          //   display: hamburgerMenuOptions && hamburgerMenuOptions.length > 0 ? 'flex' : 'none',
          // }}
          // dropdownStyle={{
          //   width: 180,
          //   height: 40 * hamburgerMenuOptions.length,
          // }}
          // showsVerticalScrollIndicator={false}
          // onDropdownWillShow={this._onHamburgerWillShow}
          // onDropdownWillHide={this._onHamburgerWillHide}
          renderRow={this._renderModalDropdownRow}
          onSelect={this._onHamburgerMenuSelect}
          options={hamburgerMenuOptions}>
          {/* // @ts-ignore  */}
          <XIcon
            family='MaterialCommunityIcons'
            name='dots-vertical'
            variant={isDisabled ? 'inactive' : 'primary'}
            // inactive={isDisabled}
            // pb='1'
            _android={{ pb: '1' }}
            _ios={{ pb: '1' }}
            // minW='8'
            // style={{
            //   // alignSelf: 'center',
            //   // textAlign: 'center',
            //   // color: isDisabled ? TEXT_INACTIVE : TEXT_PRIMARY,
            //   // fontSize: FONT_ICON_LARGEST,
            //   // minWidth: 34,
            //   // flex: 0,
            //   // paddingTop: 0,
            //   // // จริงๆ 0 มันกลางแล้ว แต่ปรับขึ้นเป็น 2 เพราะสายตาคนมองเทียบกับอย่างอื่นแล้วมันจึงดูไม่กลาง
            //   // paddingBottom: Platform.OS === 'android' ? 2 : 0,
            //   // paddingLeft: 0,
            //   // paddingRight: 5,
            // }}
          />
        </XDropdown>
      </HStack>
    )
  }

  _renderModalDropdownRow = (data: {
    index?: number
    label: string
    isDanger?: boolean
    onPress: () => void
    customRander: () => JSX.Element
  }): JSX.Element => {
    // console.log('_renderModalDropdownRow renderRow::data => ', data)
    const isDanger = _.has(data, 'isDanger') ? data.isDanger : false
    // @ts-ignore
    const icon = _.has(data, 'icon') ? data.icon : null

    const customRanderView = data.customRander ? data.customRander : null
    if (!_.isNil(customRanderView)) {
      return customRanderView()
    }

    return (
      <HStack px='1' py='2' space='1' alignItems='center'>
        {_.isNil(icon) ? null : <XIcon name={icon.name} family={icon.family} variant={isDanger ? 'danger' : 'active'} />}
        <XText variant={isDanger ? 'danger' : 'active'}>{data.label}</XText>
      </HStack>
    )
  }

  _renderTabWrapperByMode = () => {
    const { mode, isInitialized } = this.state
    const { editingOrder } = this.props
    if (!isInitialized) {
      return null
    }
    // Bypass for force normal render CREATE and EDIT mode
    if (mode === CREATE) {
      return this._renderOrderByMode(editingOrder)
    }
    return this._renderOrderTabs()
  }

  _renderOrderTabs = () => {
    const { editingOrder } = this.props
    const { mode, orderTabIndex, orderTabsVisibility, submitting, refreshing, isInitialized } = this.state
    if (!editingOrder || !Map.isMap(editingOrder) || !isInitialized) {
      return null
    }

    const parentOrder = editingOrder
    const subOrders = editingOrder.get('suborders') ? editingOrder.get('suborders') : List([])
    // const isLocked = _.includes([CREATE, EDIT], mode) || !orderTabsVisibility || submitting || refreshing
    // // log('_renderOrderTabs orderTabsVisibility => ', orderTabsVisibility)
    // const parentOrderId = parentOrder.get('id')
    // const contentProps: any = {
    //   style: {
    //     width: '100%',
    //     // เป็น Quick Fix ไม่รู้ว่าทำไม ViewPager ของ Android ใช้งาน 100% แล้วล้น screen height ไปได้ ทั้งๆ ที่ Parent เป็น flex: 1
    //     height: p.op.isAndroid() && orderTabsVisibility ? '95%' : '100%',
    //   },
    // }

    const allOrders = List([parentOrder]).concat(subOrders)

    // if (p.op.isWeb()) {
    //   contentProps.style = {
    //     ...contentProps.style,
    //     flex: 1,
    //     flexBasis: 'auto',
    //     // overflowY: 'hidden',
    //   }
    //   contentProps.containerStyle = {
    //     flex: 1,
    //     flexBasis: 'auto',
    //     backgroundColor: 'transparent',
    //     ...this.getInjectScrollableTabViewContentPropsStyle(),
    //   }
    // }

    return (
      <>
        {orderTabsVisibility ? (
          <XHorizontalScrollView w='full' h='11' contentContainerStyle={{ alignItems: 'flex-end' }} bg='gray.300'>
            {/* To render tab header's buttons */}
            {allOrders.map(this._renderHeaderTabButton)}
          </XHorizontalScrollView>
        ) : null}
        {this._renderOrderSingleTab(this._getFocusedOrder(orderTabIndex), orderTabIndex)}
      </>
    )
  }

  // _renderOrderSingleTab = ({ order, idx }) => {
  _renderOrderSingleTab = (order, index) => {
    const idx = index + 1
    const { orderTabIndex, orderTabsVisibility } = this.state
    if (!order || !isImmutable(order)) {
      return <View key={`EmptyTab_${idx}`} />
    }

    const isEmptyOrder = !(order && order.get('updated_at'))

    let orderType
    if (isEmptyOrder) {
      orderType = idx === 0 ? 1 : 3 // ถ้าไม่มีข้อมูล ให้ assume ว่า idx 0 เป็นออเดอร์ขาย
    } else {
      orderType = order.get('type')
    }

    const txtOrderContext = orderType === 3 ? 'ซื้อ' : 'ขาย'
    const txtOrderName = order.get('name') ? `${txtOrderContext}#${order.get('name')}` : null
    const orderId = order.get('id') ? order.get('id') : 'none'

    if (!txtOrderName) {
      return <View key={`NoNameTab_${idx}`} />
    }

    // const isFocusedTab = orderTabIndex === idx
    // const WrapperView = p.op.isWeb() ? ScrollView : View
    return (
      // @ts-ignore
      <VStack
        key={`OrderTab_${idx}`}
        w='full'
        flex={1}
        // @ts-ignore
        tabLabel={txtOrderName} // ต้องมี!! เพราะถ้าไม่มี จะไม่มี order name บน Tabs
        style={{
          // flex: 1,
          // // width: '100%',
          // height: '100%',
          backgroundColor: orderType === xCONS.ORDER_TYPE_3 ? COLORS.FORM_PRIMARY_BG : COLORS.BG_LIGHT_GREY,
        }}
        // heading={txtOrderName} // ถ้าใช้ View มันไม่มี heading
      >
        {isEmptyOrder ? (
          <View
            // Spinner
            style={{
              flex: 1,
              flexBasis: 'auto',
              width: '100%',
              height: '100%',
              position: 'absolute',
              alignSelf: 'center',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: 'rgba(0,0,0,0.3)',
            }}
            // color={ COLORS.APP_MAIN }
          />
        ) : null}
        {!isEmptyOrder ? this._renderOrderByMode(order) : null}
        {/* {!p.op.isWeb() && !isEmptyOrder ? this._renderFooter(order) : null} */}
        {!isEmptyOrder ? this._renderFooter(order) : null}
      </VStack>
    )
  }

  _renderOrderByMode = (order) => {
    const { mode, isInitialized } = this.state
    if (!order || !Map.isMap(order) || !isInitialized) {
      return null
    }

    // const order_id = order.get('id') || null
    // const type = parseInt(order.get('type') || 0)
    // const state = parseInt(order.get('state') || 0)
    // const payment_state = parseInt(order.get('payment_state') || 0)
    // const shipping_state = parseInt(order.get('shipping_state') || 0)

    // const stateObj = { type, state, payment_state, shipping_state }
    const isEditableInConfirmationMode = this._calculateEditableInConfirmationMode(order)
    const { isEditableQty, isEditableEtc } = isEditableInConfirmationMode

    const isCreateMode = mode === CREATE
    const orderId = order.get('id')
    const compKey = isCreateMode ? 'oWrap_CREATE' : `oWrap_${orderId}`
    if (!isCreateMode && !orderId) {
      return (
        <XText key={compKey} w='full' mt='50px' fontSize='xs' variant='inactive'>
          กำลังโหลดออเดอร์...
        </XText>
      )
    }

    // let orderReactNode = null
    // let hasOrderRefresh = false
    // switch (mode) {
    //   case CREATE:
    //     orderReactNode = (
    //       <View>
    //         {/*/!* TODO: Remove me and uncomment underline of me *!/*/}
    //         {/*{ this._renderStoreProducts({ order, isCreateMode: true, isEditable: true, isEditableEtc: true }) }*/}
    //         {this._renderCreateModeShipmentCard()}
    //         {/*{ this._renderProductCart({ mode, isEditableTask: true }) }*/}
    //         {/*{ this._renderSummaryCard({ isEditableTask: true }) }*/}
    //         {this._renderStoreProducts({ order, isCreateMode: true, isEditable: true, isEditableEtc: true })}
    //         {/* { this._renderCoverSheet() } */}
    //         {this._renderOptionsCard(order)}
    //       </View>
    //     )
    //     break
    //   case EDIT:
    //     orderReactNode = (
    //       <View>
    //         {this._renderOrderDetailCard(order)}
    //         {this._renderStoreProducts({ order, isCreateMode: false, isEditable: true, isEditableEtc: true })}
    //         {/*{this._renderProductSummary({ order, isEditable: type !== 3, isEditableEtc: true })}*/}
    //         {/*{this._renderCancelButton({ state })}*/}
    //         {/* { this._renderCoverSheet() } */}
    //         {this._renderOptionsCard(order)}
    //       </View>
    //     )
    //     break
    //   case VIEW_EDIT:
    //     orderReactNode = (
    //       <View>
    //         {this._renderOrderDetailCard(order)}
    //         {/* { this._renderSummaryCard({ isEditableTask }) } */}
    //         {/* { this._renderOrderProductsCard({ products, suborders, isEditableTask, mode, type }) } */}
    //         {this._renderStoreProducts({ order, isCreateMode: false, isEditable: isEditableQty, isEditableEtc })}
    //         {/*{this._renderCancelButton({ state })}*/}
    //         {/* { this._renderCoverSheet() } */}
    //         {this._renderOptionsCard(order)}
    //       </View>
    //     )
    //     hasOrderRefresh = true
    //     break
    //   case VIEW_ONLY:
    //     orderReactNode = (
    //       <View>
    //         {this._renderOrderDetailCard(order)}
    //         {this._renderStoreProducts({ order, isCreateMode: false, isEditable: false, isEditableEtc: false })}
    //         {/* { this._renderCoverSheet() } */}
    //         {this._renderOptionsCard(order)}
    //       </View>
    //     )
    //     hasOrderRefresh = true
    //     break
    //   default:
    //     return null
    // }

    return (
      <XContent
        key={compKey}
        w='full'
        // style: StyleSheet.flatten([NO_MARGIN_PADDING, { display: this.state.isInitialized ? null : 'none' }]),
        // @ts-ignore
        keyboardShouldPersistTaps={xCONS.KEYBOARD_PERSIST_TAB_MODE}
        // keyboardDismissMode='on-drag,
        // ref={this._onOrderScrollViewRef}
        extraScrollHeight={50}
        refreshControl={this._renderOrderRefreshControl(_.includes([VIEW_EDIT, VIEW_ONLY], mode) && !p.op.isWeb())}
        onContentSizeChange={this._onOrderContentSizeChange}
        disableKBDismissScroll={false}
        enableResetScrollToCoords={false}>
        <VStack w='full' p='1'>
          {_.includes([CREATE], mode) ? this._renderCreateModeShipmentCard(order) : null}
          {!_.includes([CREATE], mode) ? this._renderOrderDetailCard(order) : null}

          {this._renderStoreProducts({
            order,
            isCreateMode: _.includes([CREATE], mode),
            isEditable: _.includes([CREATE, EDIT], mode) || (_.includes([VIEW_EDIT], mode) && isEditableQty),
            isEditableEtc: _.includes([VIEW_EDIT], mode) ? isEditableEtc : _.includes([CREATE, EDIT], mode),
          })}

          {/* การชำระ */}
          {/* {this._renderShipBeforePay()} */}
          {this._renderCoverSheet()}
          {this._renderOptionsCard(order)}
        </VStack>
      </XContent>
    )
  }

  _renderStoreProducts = ({ order, isCreateMode, isEditable, isEditableEtc }) => {
    const { mode, discountDetails } = this.state
    // const order_name = order.get('name') || ''
    // const my_store_name = order.get('my_store_name') || ''
    // const partner_name = order.get('partner_name') || ''
    // const suborders = order.get('suborders') || List([])
    // const order_type = order.get('type') || null
    // const order_state = order.get('state') || null
    // const sender_name = order.get('sender_name') || ''
    // const sender = order.get('sender') || Map({})
    // const use_store_addr = order.get('use_store_addr') || null
    // const is_addr_forced = order.get('is_addr_forced') || null
    // const store_products = order.get('store_products') || List([])
    const orderId = order.get('id') || 0
    const orderType = order.get('type') || 0
    const vdDiscounts = order.get('vd_discounts') || List([])
    const hasVdDiscount = vdDiscounts.size > 0
    const store_products = order.get('store_products') || Map({})
    // const s_use_retail_price = this.props.selectedStore.get('s_use_retail_price') || false
    // const order_use_retail_price = order.get('order_use_retail_price') || 0

    const amountQty = order.get('amountQty') || 0
    // const amountProducts = orderType === 3 ? order.get('amountProductsBuy') || 0 : order.get('amountProducts') || 0
    let amountProducts =
      orderType === 3 ? order.get('amountProductsBuyIncludeDiscount') || 0 : order.get('amountProductsIncludeDiscount') || 0
    if (hasVdDiscount) {
      // for hiding amount include ugpg discount
      amountProducts = orderType === 3 ? order.get('amountProductsBuy') || 0 : order.get('amountProducts') || 0
    }
    const amountProfit = order.get('amountProfit') || 0
    const storeKeys = store_products.keySeq().toArray() || []
    const firstStoreId = storeKeys[0] ? storeKeys[0] : null
    const lastStoreId = storeKeys[storeKeys.length] ? storeKeys[0] : null
    const lastStoreProductIndex = lastStoreId ? store_products.get(lastStoreId).size - 1 : null

    const isCanceledOrder = this._isOrderCancelled(order)
    const canShowProfit = (isCreateMode || _.includes([1, 2], orderType)) && xAcl.canDoAtSelectedStore(PERM_STORE_HELPER.PRODUCT_COST)

    // if (hasVdDiscount) {
    //   // for hiding all profits because vd make the computed was wrong
    //   canShowProfit = false
    // }

    const canAddProduct = isCreateMode || (isEditable && orderType === 1)
    const isRemovable = canAddProduct
    // const renderMethodForStoreProduct = (product, store_id, index) => (
    //   <Row style={{ flexDirection: 'column' }}>
    //     {this._renderProduct({
    //       idx: store_id,
    //       index,
    //       product,
    //       isCreateMode,
    //       isEditable,
    //       isRemovable,
    //       canShowProfit,
    //       isCanceledOrder,
    //       orderType,
    //       isLastRow: store_id === lastStoreId && _.isNumber(lastStoreProductIndex),
    //       order,
    //     })}
    //   </Row>
    // )

    // const renderStoreProductItem = (args) => {
    //   // console.log('renderStoreProductItem item => ', item)

    //   // const storeIdKeys = item.keys()
    //   // const store_id = storeIdKeys[0]
    //   // const product = item.get(store_id)
    //   const [store_id, products] = args.item

    //   // console.log('renderStoreProductItem store_id => ', store_id)
    //   // console.log('renderStoreProductItem products => ', products.toJS())

    //   if (_.isNull(store_id) || parseInt(store_id) <= 0) {
    //     return null
    //   }

    //   const { mode } = this.state
    //   const { name, isMyStore, isNotFound } = this._getStoreNameFromStoreId(store_id, true)
    //   const txtStoreName = isNotFound ? 'ไม่พบชื่อร้านค้า' : name
    //   const orderStoreId = order.get('store_id') || null
    //   const parentOrderType = order.get('type')
    //   let orderType
    //   let orderId
    //   let txtOrderName = '-'
    //   let txtShippingType = '-'
    //   if (orderStoreId === store_id) {
    //     orderType = order.get('type')
    //     txtOrderName = `#${order.get('name')}` || null
    //     orderId = order.get('id') || null
    //     txtShippingType = this._getShippingTypeLabel(order)
    //   } else {
    //     const targetOrderIndex = order.get('suborders') ? order.get('suborders').findIndex((so) => so.get('store_id') === store_id) : null

    //     if (targetOrderIndex > -1 && order.getIn(['suborders', targetOrderIndex, 'id'])) {
    //       const subOrder = order.getIn(['suborders', targetOrderIndex]) || Map({})
    //       orderType = subOrder.get('type')
    //       txtOrderName = `#${subOrder.get('name')}` || null
    //       orderId = subOrder.get('id') || null
    //       txtShippingType = this._getShippingTypeLabel(subOrder)
    //     }
    //   }

    //   const txtFromOrderName = txtOrderName ? `${orderType === 3 ? 'ซื้อ' : 'ขาย'}${txtOrderName}` : null

    //   return (
    //     <XCard w='full' key={store_id.toString()}>
    //     <XCard.Header>
    //       <HStack justifyContent='space-between' space='1'>
    //         <HStack flex={1} space='1'>
    //               <XImageIcon variant='inactive' source={isMyStore ? tb_my0 : tb_seller0} />
    //           <XText fontSize='xs'>{`สินค้าจาก${txtStoreName}`}</XText>
    //         </HStack>
    //         {!isCreateMode && txtFromOrderName && parentOrderType !== 3 && orderType === 3 ? (
    //           <XButton variant={mode !== EDIT ? 'primary' : 'outline'} onPress={() => this._handleOnPressChildOrderId(orderId)}>
    //             {txtFromOrderName}
    //           </XButton>
    //         ) : null}
    //       </HStack>
    //     </XCard.Header>
    //     <XCard.Body>
    //     <VStack w='full' space='2'>
    //         {products.map((...args) =>
    //           this._renderProduct({
    //             idx: store_id,
    //             index: args[1],
    //             product: args[0],
    //             isCreateMode,
    //             isEditable,
    //             isRemovable,
    //             canShowProfit,
    //             isCanceledOrder,
    //             orderType,
    //             isLastRow: store_id === lastStoreId && _.isNumber(lastStoreProductIndex),
    //             order,
    //           })
    //         )}
    //       </VStack>
    //     </XCard.Body>
    //   </XCard>
    //   )

    //   return (
    //     <Fragment key={store_id}>
    //       {this._renderOrderStoreProductsHeader({
    //         order,
    //         products,
    //         store_id,
    //         isFirstStore: store_id === firstStoreId,
    //         isRemovable,
    //         isCreateMode,
    //       })}
    //       <VStack w='full' space='2'>
    //         {products.map((...args) =>
    //           this._renderProduct({
    //             idx: store_id,
    //             index: args[1],
    //             product: args[0],
    //             isCreateMode,
    //             isEditable,
    //             isRemovable,
    //             canShowProfit,
    //             isCanceledOrder,
    //             orderType,
    //             isLastRow: store_id === lastStoreId && _.isNumber(lastStoreProductIndex),
    //             order,
    //           })
    //         )}
    //       </VStack>
    //     </Fragment>
    //   )
    // }
    // const discountDetails = xUtil.getDiscountDetails(order)
    // let sumUGPGDiscount = 0
    // let sumUGPGCount = 0
    // discountDetails.ugpg.map(product => {
    //   sumUGPGDiscount = sumUGPGDiscount + product.discount_amount
    //   sumUGPGCount = sumUGPGCount + product.qty
    // })
    // const UGPGDiscoint = {
    //   sumUGPGDiscount,
    //   sumUGPGCount,
    // }
    // const ORDER_DISCOUNT = xCONS.ORDER_DISCOUNT_KEY
    const titleUGPGTxt = 'สรุปส่วนลดสำหรับสมาชิก'
    // console.log('store_products => ', store_products.toJS())
    return (
      <XCard w='full' mt='1' key={`${orderId}`}>
        <XCard.Body>
          <VStack w='full' space='1'>
            <HStack w='full' py='2' alignItems='center' justifyContent='center'>
              <XText fontSize='md' bold>
                รายการสินค้า
              </XText>
            </HStack>
            {/* { */}
            {/* s_use_retail_price */}
            {/* && isCreateMode // FIXME: เลิกใช้ flag นี้ ถ้า edit ราคาได้ */}
            {/* // && canAddProduct // FIXME: เปิด Flag นี้หลังจากหลังบ้าน supported edit product's price */}
            {/* ? ( */}
            {/* <Box style={{ paddingTop: 0, paddingBottom: 0 }}> */}
            {/* { */}
            {/* this._renderSegmentOptions({ */}
            {/* key: 'order_use_retail_price', */}
            {/* selectedIndex: order_use_retail_price, */}
            {/* info: 'เมื่อทำการเลือกสินค้าเข้าสู่ออเดอร์', */}
            {/* options: [ */}
            {/* { label: 'ใช้ราคาส่ง' }, */}
            {/* { label: 'ใช้ราคาปลีก' }, */}
            {/* ], */}
            {/* }) */}
            {/* } */}
            {/* </Box> */}
            {/* ) */}
            {/*: null */}
            {/* } */}
            {canAddProduct ? this._renderNavToPickProductToOrderButton() : null}
            {/* <Box
              style={StyleSheet.flatten([s.webFlexBasis, { padding: 4 }])}>
              <Stack style={[s.webFlexBasis, s.webWidth100Percent]}>
                <FlatList
                  key={`${mode}${this.state.showProfit}`}
                  // immutableData={store_products}
                  data={store_products.toArray()}
                  // keyExtractor={(item, index) => `StoreProduct_${index}`}
                  keyExtractor={this._keyExtractor}
                  renderItem={renderStoreProductItem}
                  ListEmptyComponent={this._renderEmptyProductSummary}
                />
              </Stack>
            </Box> */}

            {/* <FlatList
              // key={`${mode}${this.state.showProfit}`}
              // immutableData={store_products}
              data={store_products.toArray()}
              // keyExtractor={(item, index) => `StoreProduct_${index}`}
              keyExtractor={this._keyExtractor}
              // renderItem={renderStoreProductItem}
              renderItem={this.renderStoreProductItem}
              ListEmptyComponent={this._renderEmptyProductSummary}
            /> */}
            <VStack w='full' mb='2' space='2'>
              {canAddProduct && storeKeys.length === 0 ? (
                <Box
                  w='full'
                  p='2'
                  borderWidth='1'
                  borderColor='muted.300'
                  borderStyle='dotted'
                  borderRadius='lg'
                  alignItems='center'
                  alignContent='center'>
                  <XText>กดปุ่มด้านบนเพื่อเลือกเพิ่มสินค้าในออเดอร์</XText>
                </Box>
              ) : null}

              {/* {store_products.toArray().map((sp, sIdx) =>
                this.renderStoreProductItem({
                  store_id: sp[0],
                  products: sp[1],
                  order,
                  isCreateMode,
                  isEditable,
                  isRemovable,
                  canShowProfit,
                  isCanceledOrder,
                  lastStoreId,
                  lastStoreProductIndex,
                })
              )} */}
              {store_products.toArray().map((sp, sIdx) => (
                <OrderStoreProductsCard
                  key={`OSPC_${orderId}_${sp[0]}_${sIdx.toString()}`}
                  store_id={sp[0]}
                  products={sp[1]}
                  order={order}
                  focusedOrderType={orderType}
                  isCreateMode={isCreateMode}
                  isEditable={isEditable}
                  isRemovable={isRemovable}
                  canShowProfit={canShowProfit}
                  showProfit={this.state.showProfit}
                  isCanceledOrder={isCanceledOrder}
                  lastStoreId={lastStoreId}
                  lastStoreProductIndex={lastStoreProductIndex}
                  //
                  mode={mode}
                  //
                  myStores={this.props.myStores}
                  sellerStores={this.props.sellerStores}
                  //
                  onPressBuyOrderButton={this._handleOnPressChildOrderId}
                  onDeleteProduct={this._onDeleteProduct}
                  onChangeStoreProductByKey={this.props.onChangeStoreProductByKey}
                />
              ))}
            </VStack>

            {/* {storeKeys.length > 0 // ถ้าไม่มีสินค้าให้ซ่อน
              ? this._renderStoreProductsFooter({ isRemovable, canShowProfit, amountQty, amountProducts, amountProfit, hasVdDiscount })
              : null} */}

            {/* {storeKeys.length > 0 ? ( // ถ้าไม่มีสินค้าให้ซ่อน
              <Box style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: p.op.isWeb() ? 0 : 4, paddingRight: p.op.isWeb() ? 0 : 4 }}>
                {this._renderStoreProductsFooter({ isRemovable, canShowProfit, amountQty, amountProducts, amountProfit, hasVdDiscount })}
              </Box>
            ) : null} */}
            {/* {canShowProfit && this.state.showProfit ? (
              <Box>
                <XText
                  bold
                  variant={amountProfit >= 0 ? 'success' : 'danger'}
                  // style={[
                  //   // TXT_SMALLER_BOLD,
                  //   {
                  //     // fontSize: STYLES.FONT_SIZE_SMALLER,
                  //     color: amountProfit >= 0 ? COLORS.BRAND_Success : COLORS.BRAND_Danger,
                  //     // paddingRight: 3,
                  //   },
                  // ]}
                >
                  {`รวมกำไรค่าสินค้า ${xFmt.formatCurrency(amountProfit)}`}
                </XText>
              </Box>
            ) : null} */}

            {this._renderResultProductsFooter(order)}

            {this._renderVolumeDiscountSummary(order)}

            {!_.isNil(discountDetails) && discountDetails.ugpg.length > 0
              ? this._renderUGPGDiscountDetails(discountDetails.ugpg, titleUGPGTxt, orderType)
              : null}

            <Box pt='2' />
            {this._renderOrderSummary({ order, isCreateMode, isEditable, isEditableEtc })}
          </VStack>
        </XCard.Body>
      </XCard>
    )
  }

  _renderResultProductsFooter = (order: Map<string, any>) => {
    if (!Map.isMap(order)) {
      return null
    }
    const { mode, showProfit } = this.state
    const amountQty = order.get('amountQty') || 0
    const orderType = order.get('type') || 0
    const vdDiscounts = order.get('vd_discounts') || List([])
    const amountProfit = order.get('amountProfit') || 0
    const isCreateMode = _.includes([CREATE], mode)
    const canShowProfit = (isCreateMode || _.includes([1, 2], orderType)) && xAcl.canDoAtSelectedStore(PERM_STORE_HELPER.PRODUCT_COST)
    const hasVdDiscount = vdDiscounts.size > 0
    let amountProducts =
      orderType === 3 ? order.get('amountProductsBuyIncludeDiscount') || 0 : order.get('amountProductsIncludeDiscount') || 0
    if (hasVdDiscount) {
      // for hiding amount include ugpg discount
      amountProducts = orderType === 3 ? order.get('amountProductsBuy') || 0 : order.get('amountProducts') || 0
    }
    return (
      <Box w='full'>
        {/* // รวมจำนวนสินค้า */}
        <HStack w='full'>
          <Box flex={1}>
            <XText variant='inactive'>รวมจำนวนสินค้า</XText>
          </Box>
          <XText bold variant='active'>
            {amountQty}
          </XText>
        </HStack>

        {/* // ยอดรวมสินค้า(ก่อนส่วนลด) */}
        <HStack w='full'>
          <Box flex={1}>
            <XText variant='inactive'>ยอดรวมสินค้า(หักเฉพาะส่วนลดต่อชิ้น ถ้ามี)</XText>
          </Box>
          <XText bold variant='active'>
            {xFmt.formatCurrency(amountProducts)}
          </XText>
        </HStack>

        {/* // รวมกำไรค่าสินค้า(ก่อนส่วนลด) แสดงก็ต่อเมื่อ สามารถดูกำไรได้ และเลือกดูกำไร */}
        {canShowProfit && showProfit ? (
          <HStack w='full'>
            <Box flex={1}>
              <XText variant={amountProfit >= 0 ? 'success' : 'danger'}>รวมกำไรค่าสินค้า(ก่อนส่วนลด)</XText>
            </Box>
            <XText bold variant={amountProfit >= 0 ? 'success' : 'danger'}>
              {xFmt.formatCurrency(amountProfit)}
            </XText>
          </HStack>
        ) : null}
      </Box>
    )
  }

  // renderStoreProductItem = ({
  //   store_id,
  //   products,
  //   order,
  //   isCreateMode,
  //   isEditable,
  //   isRemovable,
  //   canShowProfit,
  //   isCanceledOrder,
  //   lastStoreId,
  //   lastStoreProductIndex,
  // }) => {
  //   // console.log('renderStoreProductItem item => ', item)

  //   // const storeIdKeys = item.keys()
  //   // const store_id = storeIdKeys[0]
  //   // const product = item.get(store_id)
  //   // const [store_id, products] = args.item

  //   // console.log('renderStoreProductItem store_id => ', store_id)
  //   // console.log('renderStoreProductItem products => ', products.toJS())

  //   if (_.isNull(store_id) || parseInt(store_id) <= 0) {
  //     return null
  //   }

  //   const { mode } = this.state
  //   const { name, isMyStore, isNotFound } = this._getStoreNameFromStoreId(store_id, true)
  //   const txtStoreName = isNotFound ? 'ไม่พบชื่อร้านค้า' : name
  //   const orderStoreId = order.get('store_id') || null
  //   const parentOrderType = order.get('type')
  //   let orderType
  //   let orderId
  //   let txtOrderName = '-'
  //   let txtShippingType = '-'
  //   if (orderStoreId === store_id) {
  //     orderType = order.get('type')
  //     txtOrderName = `#${order.get('name')}` || null
  //     orderId = order.get('id') || null
  //     txtShippingType = this._getShippingTypeLabel(order)
  //   } else {
  //     const targetOrderIndex = order.get('suborders') ? order.get('suborders').findIndex((so) => so.get('store_id') === store_id) : null

  //     if (targetOrderIndex > -1 && order.getIn(['suborders', targetOrderIndex, 'id'])) {
  //       const subOrder = order.getIn(['suborders', targetOrderIndex]) || Map({})
  //       orderType = subOrder.get('type')
  //       txtOrderName = `#${subOrder.get('name')}` || null
  //       orderId = subOrder.get('id') || null
  //       txtShippingType = this._getShippingTypeLabel(subOrder)
  //     }
  //   }

  //   const txtFromOrderName = txtOrderName ? `${orderType === 3 ? 'ซื้อ' : 'ขาย'}${txtOrderName}` : null

  //   return (
  //     <XCard w='full' key={store_id.toString()}>
  //       <XCard.Header
  //         // @ts-ignore
  //         _light={{ bg: NB.C.L.BG.LIGHT_GREY }}
  //         // @ts-ignore
  //         _dark={{ bg: NB.C.D.BG.LIGHT_GREY }}>
  //         <HStack justifyContent='space-between' space='1'>
  //           <HStack flex={1} space='1'>
  //             <XImageIcon variant='inactive' source={isMyStore ? tb_my0 : tb_seller0} />
  //             <XText fontSize='xs'>{`สินค้าจาก${txtStoreName}`}</XText>
  //           </HStack>
  //           {!isCreateMode && txtFromOrderName && parentOrderType !== 3 && orderType === 3 ? (
  //             <XButton variant={mode !== EDIT ? 'primary' : 'outline'} onPress={() => this._handleOnPressChildOrderId(orderId)}>
  //               {txtFromOrderName}
  //             </XButton>
  //           ) : null}
  //         </HStack>
  //       </XCard.Header>
  //       <XCard.Body>
  //         <VStack w='full' space='2'>
  //           {products.map((...args) => {
  //             const productItemProps = {
  //               store_id,
  //               index: args[1] as number,
  //               product: args[0],
  //               orderType,
  //               order,

  //               isCreateMode,
  //               isEditable,
  //               isRemovable,
  //               canShowProfit,
  //               showProfit: this.state.showProfit,
  //               isCanceledOrder,
  //               isLastRow: store_id === lastStoreId && _.isNumber(lastStoreProductIndex),

  //               onDeleteProduct: this._onDeleteProduct,
  //               onChangeStoreProductByKey: this.props.onChangeStoreProductByKey,
  //             }
  //             // @ts-ignore
  //             return <OrderProductItem {...productItemProps} />
  //             // this._renderProduct({
  //             //   store_id,
  //             //   index: args[1],
  //             //   product: args[0],
  //             //   isCreateMode,
  //             //   isEditable,
  //             //   isRemovable,
  //             //   canShowProfit,
  //             //   isCanceledOrder,
  //             //   orderType,
  //             //   isLastRow: store_id === lastStoreId && _.isNumber(lastStoreProductIndex),
  //             //   order,
  //             // })
  //           })}
  //         </VStack>
  //       </XCard.Body>
  //     </XCard>
  //   )
  // }

  _renderVolumeDiscountSummary = (order: Map<string, any>): JSX.Element => {
    if (!Map.isMap(order)) {
      return null
    }
    return <OrderVolumeDiscountList order={order} />
  }

  _keyExtractor = (item, index) => index.toString()

  _getShippingTypeItem = (shippingTypeId) => this.ALL_SHIPPING_TYPE_ITEMS.find((sti) => sti.value === shippingTypeId)

  _renderOrderSummary = ({ order, isCreateMode, isEditable, isEditableEtc }) => {
    // const products = editingOrder.get('products') || List([])
    // const suborders = editingOrder.get('suborders') || List([])
    const { mode, showProfit } = this.state
    // const { pp_shippingTypeIndex, onEditingOrderChange } = this.props
    const { onEditingOrderChange } = this.props
    const discount = order.get('discount') || 0
    const shipping_cost = order.get('shipping_cost') || 0
    const etc_cost = order.get('etc_cost') || 0
    // const amountProducts = order.get('amountProducts') || 0
    // const amountProfit = order.get('amountProfit') || 0
    const orderType = order.get('type') || 0
    const orderIndex = order.get('index') || 0
    // const amountTotal = orderType === 3 ? order.get('amountTotalBuy') || 0 : order.get('amountTotal') || 0

    const isCod = order.get('is_cod') || false
    const codFee = order.get('cod_fee') || 0
    const codAmount = order.get('cod_amount') || 0
    const isMkpOrder = xUtil.isMarketPlacesOrder(order)
    // const discountDetails = xUtil.getDiscountDetails(order)
    // let sumdDscountUGPG = 0
    // discountDetails.ugpg.map(product => {
    //   sumdDscountUGPG = sumdDscountUGPG + product.discount_amount
    // })
    let amountTotal
    // if (_.includes([1, 2, 3], orderType) && !isEditable && false) {
    //   amountTotal = +order.get('transaction_cost')
    // } else if (_.includes([1, 2, 3], orderType) && isEditable && false) {
    //   amountTotal = orderType === 3 ? order.get('amountTotalBuy') || 0 : order.get('amountTotal') || 0
    //   amountTotal = amountTotal + +codFee
    // } else {
    //   // log(this, '_renderOrderSummary amountTotal = manual computing')
    //   amountTotal = order.get('transaction_cost')
    // }
    // ARTID 01
    // const remainingAmount = parseFloat(order.get('remaining_forecast_amount'))
    // console.log('remainingAmount', remainingAmount)
    // console.log('order', order.toJS())
    // console.log('shipping_cost', +shipping_cost)
    // console.log('mode', mode)
    // console.log('isEditable', isEditable)
    // console.log('orderType', orderType)
    // console.log('amountProductsBuy', order.get('amountProductsBuy'))
    /// /////////////////// SAVE ME ///////////////////////////////////
    if (mode === EDIT || (mode === VIEW_EDIT && isEditable)) {
      // amountTotal = order.get('amountTotal') || 0
      if (orderType === 3) {
        amountTotal = order.get('amountTotalBuy') || 0
        //  console.log("order.get('amountTotalBuy')", order.get('amountTotalBuy'))
      } else {
        amountTotal = order.get('amountTotal')
        //  console.log("order.get('amountTotal')", order.get('amountTotal'))
      }

      // amountTotal = amountTotal + +shipping_cost
      // amountTotal = amountTotal - sumdDscountUGPG
    } else if (mode === VIEW_EDIT && !isEditable) {
      amountTotal = order.get('transaction_cost')
      //  console.log("order.get('transaction_cost')", order.get('transaction_cost'))
    } else {
      amountTotal = order.get('amountTotal') || 0
      // console.log("last else amountTotal", amountTotal)
    }
    if (mode === CREATE || mode === EDIT || (mode === VIEW_EDIT && isEditable)) {
      amountTotal += parseFloat(codFee)
      // console.log("if block amountTotal", amountTotal)
    }
    /// //////////////////////////////////////////////////////
    // const amountTotal = amountProducts - discount + shipping_cost + etc_cost
    // orderActions.onEditingOrderChange('discount', amountTotal)

    // คำนวณราคารวมสินค้า
    // const sumOfProducts = products.reduce((prevAmount, product) => {
    //   return prevAmount + ((parseInt(product.get('qty')) * parseFloat(product.get('price'))) || 0)
    // }, 0)
    // const sumOfSubProducts = suborders.reduce((prevSubAmount, suborder) => {
    //   const p = suborder.get('products') || List([])
    //   const sop = p.reduce((prevAmount, product) => {
    //     if (product) {
    //       return prevAmount + ((parseInt(product.get('qty')) * parseFloat(product.get('price'))) || 0)
    //     } else {
    //       return prevAmount
    //     }
    //   }, 0) || 0
    //   return prevSubAmount + sop
    // }, 0)
    //
    // const amountProducts = sumOfProducts + sumOfSubProducts
    // const usageTotal = isEditable || isEditableEtc
    //   ? editingOrder.get('amountTotal') : editingOrder.get('transaction_cost')
    // const amountTotal = usageTotal || 0

    const vdDiscounts = order.get('vd_discounts')
    let vdDiscountsTxt = ''
    // let totalVdDiscountAmount = 0
    // // Calculate total VD discount and subtract it out of amountTotal, so users get more accurate total of amount
    // // TODO by Keng 28 May 2021: However, this logic is NOT yet implemented for UGPG discount as there is no use case from a customer yet
    // // TODO So we have to come back here if a customer requested.
    if (!_.isNil(vdDiscounts) && vdDiscounts.size > 0 && (mode === EDIT || (mode === VIEW_EDIT && isEditable))) {
      // console.log('vdDiscounts', vdDiscounts.toJS())
      vdDiscountsTxt = ' (หักส่วนลดขายส่งที่คำนวณไว้ก่อนแก้ไขแล้ว)' // TODO Keng: Change text here
      // let vdDiscount
      // for (let i = 0; i < vdDiscounts.size; i++) {
      //   vdDiscount = vdDiscounts.get(i)
      //   totalVdDiscountAmount += parseFloat(vdDiscount.get('ta')) // Important! Must parseFloat!
      // }
      // // rounding to 2 digits
      // totalVdDiscountAmount = xUtil.roundToTwoDecimal(totalVdDiscountAmount)
      // // totalVdDiscountAmount = Math.round(totalVdDiscountAmount * 100) / 100
    }
    // // console.log('totalVdDiscountAmount', totalVdDiscountAmount)
    // // console.log('amountTotal1', amountTotal)
    // amountTotal -= totalVdDiscountAmount
    // // console.log('amountTotal2', amountTotal)

    amountTotal = xUtil.roundToTwoDecimal(amountTotal)

    // console.log('vdDiscounts', vdDiscounts.toJS());

    let totalAmountLabel = `ยอดรวม${vdDiscountsTxt}`
    let discountLabel

    if (mode === CREATE || orderType === 1) {
      totalAmountLabel += order.has('mkp_id') ? '\n(ที่ช่องทางขายต้องชำระให้ฉัน)' : '\n(ที่ลูกค้าต้องชำระให้ฉัน)'
      discountLabel = order.has('mkp_id') ? 'ส่วนลด/ค่าธรรมเนียม\nจากช่องทางขาย' : 'ส่วนลดให้ลูกค้า'
    } else if (orderType === 2) {
      totalAmountLabel += '\n(ที่ตัวแทนต้องชำระให้ฉัน)'
      discountLabel = 'ส่วนลดให้ตัวแทน'
    } else if (orderType === 3) {
      totalAmountLabel += '\n(ที่ฉันต้องชำระผู้ขายส่ง)'
      discountLabel = 'ส่วนลดที่ฉันได้'
    }

    // สำหรับ COD & จัดส่งก่อนชำะ
    const shipping_type_id = order.get('shipping_type_id') || -1
    // let getShippingTypeId = pp_shippingTypeIndex // deprecated pp_shippingTypeIndex
    // console.log('shipping_type_id: ' + shipping_type_id)

    let shippingTypeItem = this._getShippingTypeItem(shipping_type_id)
    let middleButtonCod = {
      text: `ระบุเท่า\nยอดรวม`,
      key: SPECIFY,
      value: amountTotal,
    }
    let middleIconShipBeforePay = {
      key: RE_CALCULATE,
      name: 'calculator',
      family: 'MaterialCommunityIcons',
    }
    let middleIconDiscount = {
      key: PERCENT_CALCULATE,
      name: 'percent-outline',
      family: 'MaterialCommunityIcons',
    }
    if (mode !== CREATE) {
      // getShippingTypeId = shipping_type_id
      shippingTypeItem = this._getShippingTypeItem(shipping_type_id)
    }
    if (mode === VIEW_EDIT && !isEditableEtc) {
      middleButtonCod = null
      middleIconShipBeforePay = null
      middleIconDiscount = null
    }

    // showShippingCod จะให้แสดง คำนวณ cod หรือเปล่า
    let showShippingCod = false
    // console.log(this.shippingTypeItems)
    // console.log("shippingTypeItem.value: " + shippingTypeItem)
    if (!_.isNil(shippingTypeItem)) {
      if (_.includes(xCONS.COD_SHIPPING_TYPE_IDS, shippingTypeItem.value) || isCod) {
        showShippingCod = true
      }
      // else {
      //   // ล้างค่า cod_fee และ cod_amount ถ้าไม่ใช้งาน cod แล้ว
      //   onEditingOrderChange({ key: 'cod_fee', value: '0', orderTabIndex: orderIndex })
      //   onEditingOrderChange({ key: 'cod_amount', value: '0', orderTabIndex: orderIndex })
      // }
    }

    // >>>>>>> แสดง ค่าธรรมเนียม cod คิดเป็ xx % หรือไม่ >>>>>>>
    // การเติม + หน้าพารามิเตอร์ที่เป็น string จะทำให้เป็น number
    let showCODFeePercent = false
    let codFeePercent = 0
    if (showProfit && isCod && +codFee !== 0 && +codAmount !== 0) {
      showCODFeePercent = true
      codFeePercent = (+codFee / +codAmount) * 100
      codFeePercent = +codFeePercent.toFixed(2)
    }
    // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
    // const canShowProfit = isCreateMode || (_.includes([1,2], orderType))
    return (
      <VStack space='1'>
        {/* { */}
        {/* this._renderOrderSummaryRow({ */}
        {/* label: 'รวมราคาค่าสินค้า', */}
        {/* middleText: null, */}
        {/* style: { paddingBottom: 0, marginBottom: 0, height: 24 }, */}
        {/* rightComponent: this._renderSummaryCurrencyInputOrText({ */}
        {/* key: 'amountProducts', */}
        {/* value: amountProducts, */}
        {/* isEditable: false, */}
        {/* }), */}
        {/* }) */}
        {/* } */}
        {/* { */}
        {/* canShowProfit && this.state.showProfit */}
        {/* ? this._renderOrderSummaryRow({ */}
        {/* label: 'รวมกำไรค่าสินค้า', */}
        {/* middleText: null, */}
        {/* style: { paddingTop: 0, marginTop: 0, height: 16 }, */}
        {/* labelStyle: { */}
        {/* fontSize: STYLES.FONT_SIZE_SMALLER, */}
        {/* color: amountProfit >= 0 ? COLORS.BRAND_Success: COLORS.BRAND_Danger, */}
        {/* }, */}
        {/* rightComponent: this._renderSummaryCurrencyInputOrText({ */}
        {/* key: 'amountProfit', */}
        {/* value: amountProfit, */}
        {/* isEditable: false, */}
        {/* style: { */}
        {/* color: amountProfit >= 0 ? COLORS.BRAND_Success: COLORS.BRAND_Danger, */}
        {/* fontSize: STYLES.FONT_SIZE_SMALLER, */}
        {/* }, */}
        {/* }), */}
        {/* }) : null */}
        {/* } */}
        {/* { this._renderVolumeDiscountSummary('my', order) } */}
        {/* { this._renderVolumeDiscountSummary('seller', order) } */}
        {this._renderShippingCostRow({
          order,
          rightComponent: this._renderSummaryCurrencyInputOrText({
            orderIndex,
            key: 'shipping_cost',
            value: shipping_cost,
            isEditable: isEditableEtc,
          }),
        })}
        {this._renderOrderSummaryRow({
          label: discountLabel,
          middleText: null,
          rightComponent: this._renderSummaryCurrencyInputOrText({
            orderIndex,
            key: 'discount',
            value: discount,
            isEditable: isEditableEtc,
          }),
          middleIcon: middleIconDiscount,
        })}
        {this._renderModelPercentDiscount()}
        {this._renderOrderSummaryRow({
          label: 'ค่าอื่นๆ',
          middleText: null,
          rightComponent: this._renderSummaryCurrencyInputOrText({
            orderIndex,
            key: 'etc_cost',
            value: etc_cost,
            isEditable: isEditableEtc,
          }),
        })}

        {order.get('platform_fee') && parseFloat(order.get('platform_fee')) > 0
          ? this._renderOrderSummaryRow({
              label: 'ค่าธรรมเนียมจากช่องทางขาย',
              middleText: null,
              rightComponent: this._renderSummaryCurrencyInputOrText({
                orderIndex,
                key: 'platform_fee',
                value: order.get('platform_fee'),
                isEditable: false,
              }),
            })
          : null}

        {order.get('platform_subsidy') && parseFloat(order.get('platform_subsidy')) > 0
          ? this._renderOrderSummaryRow({
              label: 'ยอดสนับสนุนจากช่องทางขายเพิ่ม ',
              middleText: null,
              rightComponent: this._renderSummaryCurrencyInputOrText({
                orderIndex,
                key: 'platform_subsidy',
                value: order.get('platform_subsidy'),
                isEditable: false,
              }),
            })
          : null}

        {order.get('rounding_discount') && parseFloat(order.get('rounding_discount')) !== 0
          ? this._renderOrderSummaryRow({
              label: 'ปัดเศษ',
              middleText: null,
              rightComponent: this._renderSummaryCurrencyInputOrText({
                orderIndex,
                key: 'rounding_discount',
                value: order.get('rounding_discount'),
                isEditable: false,
              }),
            })
          : null}

        {showShippingCod && isCod
          ? this._renderOrderSummaryRow({
              label: 'ค่าธรรมเนียม\nเก็บเงินปลายทาง',
              middleText: null,
              rightComponent: this._renderSummaryCurrencyInputOrText({
                orderIndex,
                key: 'cod_fee',
                value: codFee,
                // isEditable: canEdit,
                isEditable: isEditableEtc,
                style: null,
              }),
              labelStyle: { color: COLORS.COD_COLOR },
              middleTextStyle: null,
              style: null,
              middleIcon: middleIconShipBeforePay,
              helpButton: {
                title: 'ค่าธรรมเนียมเก็บเงินปลายทาง',
                message:
                  `หากระบุ ค่าธรรมเนียมจะถูกนำไปรวมกับ”ยอดรวม” ของออเดอร์\n\n` +
                  `ในกรณีที่มีออเดอร์ซื้อคู่กัน 1 ออเดอร์ ยอดนี้จะถูกระบุต่อไปที่ออเดอร์ซื้อด้วย หากร้านผู้ขายเปิดการตั้งค่าร้าน` +
                  `"อนุญาตให้ผู้ซื้อ เปิดการใช้งาน เก็บเงินปลายทาง ได้โดยอัตโนมัติขณะสร้างออเดอร์"\n\n` +
                  `ในกรณีที่มีออเดอร์ซื้อคู่กันมากกว่า 1 ออเดอร์ (นั่นคือในออเดอร์นี้มีสินค้ามาจากสต๊อกของหลายผู้ขาย)` +
                  `ฉันต้องระบุค่าธรรมเนียมในแต่ละออเดอร์ซื้อเอง (ระบบจะไม่ระบุให้)`,
              },
            })
          : null}

        {this._renderOrderSummaryRow({
          label: totalAmountLabel,
          middleText: null,
          rightComponent: (
            <XText
              bold
              fontSize='lg'
              textAlign='right'
              // style={{
              //   flex: 1,
              //   // height: 15,
              //   // paddingTop: 5,
              //   // paddingBottom: 5,
              //   paddingRight: 3,
              //   textAlign: 'right',
              //   marginRight: 0,
              //   // marginRight: NUMBER_PADDING_RIGHT_PLUS_1,
              //   fontSize: FONT_SIZE_NORMAL,
              //   color: TEXT_ACTIVE,
              //   fontWeight: 'bold',
              // }}
            >
              {xFmt.formatCurrency(amountTotal)}
            </XText>
          ),
        })}

        {showShippingCod && isCod
          ? this._renderOrderSummaryRow({
              label: 'ยอดเก็บเงินปลายทาง\n(พิมพ์บนใบปะหน้าพัสดุ)',
              middleText: null,
              rightComponent: this._renderSummaryCurrencyInputOrText({
                orderIndex,
                key: 'cod_amount',
                value: codAmount,
                isEditable: isEditableEtc,
                style: { color: COLORS.COD_COLOR },
              }),
              labelStyle: { color: COLORS.COD_COLOR },
              middleTextStyle: null,
              style: null,
              helpButton: {
                title: 'ยอดเก็บเงินปลายทาง',
                message:
                  `ยอดนี้จะแสดงบนใบปะหน้าพัสดุ โดยสามารถตั้งได้อิสระจากยอดรวม(ออเดอร์)` +
                  `และ*ไม่ถือ*เป็นยอดการชำระของออเดอร์ในระบบ (ให้ใช้ “ยอดรวม” เป็นยอดการชำระของออเดอร์)\n\n` +
                  `ในกรณีที่มีออเดอร์ซื้อคู่กัน 1 ออเดอร์ ยอดนี้จะถูกระบุต่อไปที่ออเดอร์ซื้อด้วย หากร้านผู้ขายเปิดการตั้งค่าร้าน` +
                  `"อนุญาตให้ผู้ซื้อ เปิดการใช้งาน เก็บเงินปลายทาง ได้โดยอัตโนมัติขณะสร้างออเดอร์"\n\n` +
                  `ในกรณีที่มีออเดอร์ซื้อคู่กันมากกว่า 1 ออเดอร์ (นั่นคือในออเดอร์นี้มีสินค้ามาจากสต๊อกของหลายผู้ขาย)` +
                  `ฉันต้องระบุยอดเก็บเงินปลายทางในแต่ละออเดอร์ซื้อเอง (ระบบจะไม่ระบุให้)`,
              },
              middleButton: middleButtonCod,
            })
          : null}

        {isCod && xFmt.formatCurrency(codAmount) !== xFmt.formatCurrency(amountTotal) && !isMkpOrder ? (
          <HStack w='full' bgColor={COLORS.BRAND_Warning} mt='2' py='1' alignItems='center' justifyContent='center'>
            <XIcon name='warning' color='white' family='Entypo' />
            <XText color='white' pl='2'>
              {`ยอดเก็บเงินปลายทาง (${xFmt.formatCurrency(codAmount)})" ไม่เท่ากับ "ยอดรวม (${xFmt.formatCurrency(amountTotal)})`}
            </XText>
          </HStack>
        ) : null}

        {this.state.visibleModelCod ? this._renderXModelCODFeeCalculator(amountTotal) : null}

        {showCODFeePercent ? (
          <Box style={{ justifyContent: 'flex-end', paddingRight: 2, paddingBottom: 0, paddingTop: 0 }}>
            <XText
              fontSize='xs'
              style={{
                // fontSize: STYLES.FONT_SIZE_SMALLER,
                color: COLORS.COD_COLOR,
              }}>{`ค่าธรรมเนียม COD ฿${codFee} = ${codFeePercent}%`}</XText>
          </Box>
        ) : null}
      </VStack>
    )
  }

  _renderXModelCODFeeCalculator = (amountTotal: number) => {
    // if (this.inProcess) {
    //   return
    // }
    // this.inProcess = true

    const {
      visibleModelCod,
      optionChooseCodFeeCalculationMethodIndex,
      optionChooseRound,
      orderTabIndex,
      optionChooseMinimumFeeFromIndex,
      mode,
      storageGetCod,
    } = this.state
    const { onEditingOrderChange, pp_shippingTypeIndex, selectedStore } = this.props
    const focusedOrder = this._getFocusedOrder()
    const isEditableInConfirmationMode = this._calculateEditableInConfirmationMode(focusedOrder)
    const { isEditableQty, isEditableEtc } = isEditableInConfirmationMode

    const shipping_type_id = focusedOrder.get('shipping_type_id') || -1
    // ----- Deprecated pp_shippingTypeIndex ----
    // let getShippingTypeId = pp_shippingTypeIndex
    // let shippingTypeItem = this.shippingTypeItems.find(sti => sti.index === getShippingTypeId)

    // if (mode !== CREATE) {
    //   getShippingTypeId = shipping_type_id
    //   shippingTypeItem = this.shippingTypeItems.find(sti => sti.value === getShippingTypeId)
    // }
    const getShippingTypeId = shipping_type_id
    const shippingTypeItem = this.ALL_SHIPPING_TYPE_ITEMS.find((sti) => sti.value === getShippingTypeId)

    if (_.isNil(shippingTypeItem)) {
      this.inProcess = false
      this._handleErrMissingShippingType()
      return
    }

    const myStoreId = selectedStore.get('id')
    let mySetting = null
    const myAllSetting = storageGetCod
    // ถ้ามีข้อมูลตั้งค่าให้หยิบมาใช้ใส่ mySetting
    if (!_.isNil(myAllSetting)) {
      // mySetting = myAllSetting[myStoreId][shippingTypeItem.key]
      mySetting = null
      storageGetCod.map((val) => {
        let x = false
        if (x) {
        } else if (val.key === myStoreId) {
          x = true
          val.data.map((xData) => {
            let y = false
            if (y) {
            } else if (xData.shippingKey === shippingTypeItem.key) {
              y = true
              mySetting = xData
            }
          })
        }
      })
    }
    // ถ้าการตั้งค่าเดิมไม่มีให้ไปใช้ค่าเริ่มต้น
    let selectedCodFeeCalculationMethodIndex = optionChooseCodFeeCalculationMethodIndex
    let chooseMinimumFeeFromIndex = optionChooseMinimumFeeFromIndex

    if (!_.isNil(mySetting)) {
      if (!this.moneyType) {
        selectedCodFeeCalculationMethodIndex = mySetting.moneyType
      }
      if (!this.minimumFee) {
        chooseMinimumFeeFromIndex = mySetting.minimumFeeChoose
      }
    }

    let newAmountTotal = amountTotal
    let codFeeFormOrderView = focusedOrder.get('cod_fee')
    if (_.isNil(codFeeFormOrderView)) {
      codFeeFormOrderView = 0
    } else if (_.isString(codFeeFormOrderView)) {
      codFeeFormOrderView = +codFeeFormOrderView
    }
    // เอา totalAmount - ค่าธรรมเนียมออก จะได้ราคาก่อนคิดคาธรรมเนียม
    newAmountTotal -= codFeeFormOrderView
    // codFee
    let minimumFee = _.isNil(focusedOrder.get('minimumFee'))
      ? _.isNil(mySetting)
        ? '30'
        : _.isNil(mySetting.minimumFee)
        ? '30'
        : mySetting.minimumFee
      : focusedOrder.get('minimumFee')
    // initialCODFee เป็นค่าเริ่มต้นสำหรับช่อง บาท หรือ % หากอนาคตเราจะจำค่าคำนวณจากผู้ใช้งาน ตอนนี้เป็น 0 เสมอ
    const initialCODFee = _.isNil(focusedOrder.get('initialCODFee'))
      ? _.isNil(mySetting)
        ? '0'
        : mySetting.initialCODFee
      : focusedOrder.get('initialCODFee')

    let calculatedCODFee = +initialCODFee

    // คิดเป็น บาท หรือ ร้อยละ
    let isMoneyType = true
    let isNumberType = false
    // ถ้าเลือกเป็น %
    if (selectedCodFeeCalculationMethodIndex === 1) {
      isMoneyType = false
      isNumberType = true
      calculatedCODFee = (newAmountTotal / 100) * +initialCODFee
    }

    const codFee = newAmountTotal + calculatedCODFee
    const isInteger = Number.isInteger(codFee)

    // เลือกปัดเศษ
    if (optionChooseRound === 0) {
      // calculatedCODFee = Math.round(calculatedCODFee * 100) / 100
      calculatedCODFee = Math.ceil(calculatedCODFee)
    } else if (optionChooseRound === 1) {
      // const fullCount = Math.round(calculatedCODFee * 100) / 100 CPYWY BQDTJ A14GU
      calculatedCODFee = Math.floor(calculatedCODFee)
    } else if (optionChooseRound === 2) {
      calculatedCODFee = Math.round(calculatedCODFee * 100) / 100
    }

    if (chooseMinimumFeeFromIndex === 0) {
      // ถ้าไม่มีขั้นต่ำ
      minimumFee = '0'
    }
    // ถ้าค่าธรรมเนียมขั้นต่ำ มีค่ามากกว่า ค่าธรรมเนียมที่คำนวณได้ ให้ใช้ขั้นต่ำแทน
    if (calculatedCODFee <= +minimumFee) {
      calculatedCODFee = +minimumFee
    }
    // ก้อนข้อมูลใหม่ สำหรับการจำครั้งแรก
    const newSetting = [
      {
        key: myStoreId,
        data: [
          {
            shippingKey: shippingTypeItem.key,
            moneyType: selectedCodFeeCalculationMethodIndex,
            initialCODFee,
            minimumFeeChoose: chooseMinimumFeeFromIndex,
            minimumFee,
          },
        ],
      },
    ]

    const shippingKey = shippingTypeItem.key
    const moneyType = selectedCodFeeCalculationMethodIndex
    const minimumFeeChoose = chooseMinimumFeeFromIndex
    // ก้อนข้อมูลสำหรับไว้จำ ข้อมูลใหม่
    const chooseData = { shippingKey, moneyType, initialCODFee, minimumFeeChoose, minimumFee }

    // eslint-disable-next-line consistent-return
    return (
      <XModal
        // title={shippingTypeItem.label}
        webNumColumns={1}
        title='คำนวณค่าธรรมเนียมและยอด COD'
        // contentStyle={{ backgroundColor: COLORS.WHITE }}
        onRequestClose={() => this.setState({ visibleModelCod: false })}
        visible={visibleModelCod}
        hasCloseButton>
        <VStack flex={1} w='full' p='2' space='2'>
          {/* // ค่าธรรมเนียมเก็บเงินปลายทาง */}
          <HStack w='full'>
            <XText flex={1} variant='inactive'>
              ค่าธรรมเนียมเก็บเงินปลายทาง
            </XText>
            <XText minW='150px' maxW='200px'>
              {shippingTypeItem.label}
            </XText>
          </HStack>

          {/* // คิดเป็น */}
          <VStack w='full'>
            <XText variant='inactive'>คิดเป็น...</XText>

            <HStack w='full' alignItems='center' space='1'>
              <Box flex={1}>
                <Segment
                  options={this.OPTIONS_CHOOSE_SHIPPING_COD}
                  selectedIndex={selectedCodFeeCalculationMethodIndex}
                  onSegmentChange={this._onSegmentChangeChooseCodFeeCalculationMethod}
                />
              </Box>
              <XInput
                w='140px'
                // onRef={(r) => xUtil.pushNativeBaseRef(this.inputRefs, 'initialCODFee', r)}
                // onFocus={() => this.setState({ currentRef: 'initialCODFee' })}
                isMoney={isMoneyType}
                isNumber={isNumberType}
                textAlign='right'
                value={initialCODFee}
                // maxLength={10}
                onChangeText={(newValue) =>
                  onEditingOrderChange({ key: 'initialCODFee', value: xUtil.validateInputCurrency(newValue), orderTabIndex })
                }
              />
            </HStack>
          </VStack>

          {/* // ขั้นต่ำ */}
          <HStack w='full' alignItems='center' space='1'>
            <Box flex={1}>
              <Segment
                options={this.OPTIONS_CHOOSE_MINIMUM_FEE}
                selectedIndex={chooseMinimumFeeFromIndex}
                onSegmentChange={this._onSegmentChangeMinimumFee}
              />
            </Box>
            {chooseMinimumFeeFromIndex === 1 ? (
              <XInput
                w='140px'
                isMoney
                keyboardType='numeric'
                value={minimumFee}
                textAlign='right'
                // maxLength={10}
                onChangeText={(newValue) =>
                  onEditingOrderChange({ key: 'minimumFee', value: xUtil.validateInputCurrency(newValue), orderTabIndex })
                }
              />
            ) : (
              <Box w='140px' />
            )}
          </HStack>

          {/* // เศษสตางค์ */}
          {!isInteger ? (
            <VStack w='full'>
              <XText variant='inactive'>เศษสตางค์</XText>
              <Segment
                options={this.OPTIONS_CHOOSE_DCIMAL}
                selectedIndex={optionChooseRound}
                onSegmentChange={this._onSegmentChangeRound}
              />
            </VStack>
          ) : null}

          {/* // ยอดรวม */}
          {this._renderOrderSummaryRow({
            label: 'ยอดรวม\n(ยังไม่รวมค่าธรรมเนียม COD)',
            middleText: null,
            rightComponent: this._renderSummaryCurrencyInputOrText({
              orderIndex: orderTabIndex,
              key: 'amountTotal',
              value: newAmountTotal,
              isEditable: false,
              style: null,
            }),
            labelStyle: null,
            middleTextStyle: null,
            style: null,
          })}
        </VStack>

        {/* <XCard style={{ alignItems: 'flex-start', paddingTop: 5, marginLeft: 10, marginRight: 10, flex: 0, paddingBottom: 5 }}>
          <View style={{ flexDirection: 'row', flex: 1, backgroundColor: COLORS.GREEN_SOFT }}>
            <View style={{ alignItems: 'flex-start', flex: 1, backgroundColor: COLORS.ORANGE_SOFT }}>
              <XText style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE }}>ค่าธรรมเนียมเก็บเงินปลายทาง</XText>
            </View>
            <View style={{ alignItems: 'flex-end', flex: 1, backgroundColor: COLORS.RED_SOFT }}>
              <XText style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE }}>{shippingTypeItem.label}</XText>
            </View>
          </View>
          <Box>
            <View style={{ width: p.op.isWeb() ? 40 : 80, flex: 0, alignItems: 'flex-start' }}>
              <XText style={{ fontSize: FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE }}>คิดเป็น</XText>
            </View>
            <View style={{ flex: 1, alignItems: 'center', paddingRight: 8 }}>
              <Segment
                options={this.OPTIONS_CHOOSE_SHIPPING_COD}
                selectedIndex={selectedCodFeeCalculationMethodIndex}
                onSegmentChange={this._onSegmentChangeChooseCodFeeCalculationMethod}
              />
            </View>
            <View style={{ width: p.op.isWeb() ? 80 : 140, flex: 0, alignItems: 'flex-end' }}>
              <XInput
                onRef={(r) => xUtil.pushNativeBaseRef(this.inputRefs, 'initialCODFee', r)}
                // onFocus={() => this.setState({ currentRef: 'initialCODFee' })}
                isMoney={isMoneyType}
                isNumber={isNumberType}
                style={{
                  flex: 0,
                  width: '100%',
                  textAlign: 'right',
                  // paddingBottom: isIOS ? 2 : 5,
                  // height: textContainerHeight,
                  fontSize: STYLES.FONT_SIZE_NORMAL,
                  // borderBottomWidth: 0,
                  // color: TEXT_PRIMARY,
                  height: 20,
                }}
                keyboardType='numeric'
                // returnKeyType='done'
                value={initialCODFee}
                // maxLength={10}
                onChangeText={(newValue) =>
                  onEditingOrderChange({ key: 'initialCODFee', value: xUtil.validateInputCurrency(newValue), orderTabIndex })
                }
              />
            </View>
          </Box>

          <Box>
            <View style={{ width: p.op.isWeb() ? 40 : 80, flex: 0, alignItems: 'flex-start' }} />
            <View style={{ flex: 1, alignItems: 'center', paddingRight: 8 }}>
              <Segment
                options={this.OPTIONS_CHOOSE_MINIMUM_FEE}
                selectedIndex={chooseMinimumFeeFromIndex}
                onSegmentChange={this._onSegmentChangeMinimumFee}
              />
            </View>
            {chooseMinimumFeeFromIndex === 1 ? (
              <View style={{ width: p.op.isWeb() ? 80 : 140, flex: 0, alignItems: 'flex-end' }}>
                <XInput
                  // onRef={r => { }}
                  // onFocus={() => this.setState({})}
                  isMoney={true}
                  style={{
                    flex: 0,
                    width: '100%',
                    textAlign: 'right',
                    // paddingBottom: isIOS ? 2 : 5,
                    // height: textContainerHeight,
                    fontSize: STYLES.FONT_SIZE_NORMAL,
                    // borderBottomWidth: 0,
                    // color: TEXT_PRIMARY,
                    height: 20,
                  }}
                  keyboardType='numeric'
                  // returnKeyType='done'
                  value={minimumFee}
                  // maxLength={10}
                  onChangeText={(newValue) =>
                    onEditingOrderChange({ key: 'minimumFee', value: xUtil.validateInputCurrency(newValue), orderTabIndex })
                  }
                />
              </View>
            ) : (
              <View style={{ width: p.op.isWeb() ? 80 : 140, flex: 0, alignItems: 'flex-end' }} />
            )}
          </Box>

          {!isInteger ? (
            <Box style={{}}>
              <View style={{ width: p.op.isWeb() ? 40 : 80, flex: 0 }}>
                <XText style={{ fontSize: FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE }}>เศษสตางค์</XText>
              </View>
              <Segment
                options={this.OPTIONS_CHOOSE_DCIMAL}
                selectedIndex={optionChooseRound}
                onSegmentChange={this._onSegmentChangeRound}
              />
            </Box>
          ) : null}

          <Box style={{ paddingBottom: 0 }}>
            {this._renderOrderSummaryRow({
              label: 'ยอดรวม\n(ยังไม่รวมค่าธรรมเนียม COD)',
              middleText: null,
              rightComponent: this._renderSummaryCurrencyInputOrText({
                orderIndex: orderTabIndex,
                key: 'amountTotal',
                value: newAmountTotal,
                isEditable: false,
                style: null,
              }),
              labelStyle: null,
              middleTextStyle: null,
              style: null,
            })}
          </Box>
        </XCard> */}
        <HStack w='full' p='2'>
          <XButton
            w='full'
            // disabled={false}
            // style={btnOnMode(xCONS.BTN_MODE.ACTIVE_PRIMARY)}
            // style={{
            //   backgroundColor: COLORS.APP_MAIN,
            //   borderRadius: 7,
            //   borderColor: COLORS.APP_MAIN,
            //   marginHorizontal: 10,
            //   justifyContent: 'center',
            //   flexDirection: 'column',
            //   height: 60,
            // }}
            onPress={() => {
              onEditingOrderChange({
                key: 'cod_amount',
                value: xUtil.validateInputCurrency(newAmountTotal + calculatedCODFee),
                orderTabIndex,
              })
              onEditingOrderChange({ key: 'cod_fee', value: xUtil.validateInputCurrency(calculatedCODFee), orderTabIndex })
              setStatePromise(this, { visibleModelCod: false })
              this.storageSetCod(newSetting, myStoreId, shippingTypeItem, chooseData)
            }}>
            <VStack w='full' p='1' alignItems='flex-start' space='1'>
              <HStack w='full' space='1'>
                <XText w='38px' variant='activeDark' bold>
                  ระบุ
                </XText>
                <XText flex={1} variant='activeDark' bold>
                  ค่าธรรมเนียมเก็บเงินปลายทาง
                </XText>
                <XText w='80px' textAlign='right' variant='activeDark' bold>
                  {`${xFmt.formatCurrency(calculatedCODFee)}`}
                </XText>
              </HStack>

              <HStack w='full' space='1'>
                <XText w='38px' variant='activeDark' bold>
                  ระบุ
                </XText>
                <XText flex={1} variant='activeDark' bold>
                  ยอดเก็บเงินปลายทาง
                </XText>
                <XText w='80px' textAlign='right' variant='activeDark' bold>
                  {`${xFmt.formatCurrency(newAmountTotal + calculatedCODFee)}`}
                </XText>
              </HStack>
            </VStack>
          </XButton>
        </HStack>
        {/* </FooterTab>
          </Footer> */}
      </XModal>
    )
  }

  _renderOrderSummaryRow = ({
    label,
    middleText,
    middleTextStyle = null,
    rightComponent,
    style = {},
    labelStyle = null,
    middleButton = null,
    helpButton = null,
    middleIcon = null,
  }) => (
    // โปรดใช้ helpButton, middleButton, middleText พร้อมกันอย่างระมัดระวัง ยังไม่ได้ทดสอบ [ArTiD]
    <HStack w='full' space='1'>
      <HStack flex={7} alignItems='center' space='1' flexWrap='wrap'>
        {/* <HStack alignItems='center' space='1'> */}
        <XText variant='inactive' style={labelStyle}>
          {label || ''}
        </XText>
        {!_.isNil(helpButton) ? <HelpButton title={helpButton.title} message={helpButton.message} /> : null}
        {/* </HStack> */}

        {middleText ? (
          // <HStack alignItems='center'>
          <XText variant='inactive' style={middleTextStyle}>
            {middleText || ''}
          </XText>
        ) : // </HStack>
        null}

        {!_.isNil(middleButton) ? (
          // <HStack
          //   style={{
          //     flex: 1,
          //     flexDirection: 'row',
          //     justifyContent: 'flex-end',
          //     alignItems: 'center',
          //     maxWidth: 50,
          //     // ...Platform.select({
          //     //   web: { maxWidth: 50 },
          //     // }),
          //   }}
          //   >
          <XButton
            size='xs'
            // maxW='64px'
            minW='50px'
            px='0.5'
            py='0.5'
            variant='outline'
            onPress={() => {
              this._mindelButtonOrderSummaryRow(middleButton)
            }}>
            <XText variant='primary' fontSize='xs'>
              {middleButton.text}
            </XText>
          </XButton>
        ) : // </HStack>
        null}
      </HStack>

      <HStack flex={3} h='full' alignItems='center' justifyContent='flex-end' space='1'>
        {!_.isNil(middleIcon) ? (
          // <View
          //   style={{
          //     flex: 1,
          //     flexDirection: 'row',
          //     justifyContent: 'flex-end',
          //     alignItems: 'center',
          //     maxWidth: 50,
          //     // ...Platform.select({
          //     //   web: { maxWidth: 50 },
          //     // }),
          //   }}
          //   >
          // <Box h='full'>
          <XIconButton
            variant='outline'
            name={middleIcon.name}
            family={middleIcon.family}
            onPress={() => {
              this._mindelButtonOrderSummaryRow(middleIcon)
            }}
          />
        ) : // </Box>
        null}
        <Box
          w='32'
          // overflow='hidden'
          // justifyContent='flex-end'
          // alignItems='center'
          // style={{
          //   flex: !_.isNil(middleButton) || !_.isNil(middleIcon) ? 2 : 4, // หากแก้ layout ตรงนี้ เช็ดปุ่มคำนวณในหน้าออเดอร์ แถวช่องชำระ
          //   flexDirection: 'row',
          //   justifyContent: 'flex-end',
          //   alignItems: 'center',
          // }}
        >
          {rightComponent || null}
        </Box>
      </HStack>
    </HStack>
  )

  _mindelButtonOrderSummaryRow = async (obj: any) => {
    const { onEditingOrderChange, editingOrder } = this.props
    const { mode, orderTabIndex } = this.state
    const focusedOrder = this._getFocusedOrder(editingOrder, orderTabIndex)
    const isEditableInConfirmationMode = this._calculateEditableInConfirmationMode(focusedOrder)
    const { isEditableQty, isEditableEtc } = isEditableInConfirmationMode
    if (!isEditableEtc && _.includes([VIEW_EDIT], mode)) {
      p.op.showConfirmationOkOnly(null, 'กรุณากดแก้ไขออเดอร์ก่อนแก้ไขข้อมูล')
      return
    }
    // const amountTotal = editingOrder.get('amountTotal') || focusedOrder.get('amountTotal')
    // let amountTotal = focusedOrder.get('amountTotal')
    // const cod_fee = focusedOrder.get('cod_fee') || 0
    // const orderType = focusedOrder.get('type')
    // if (_.includes([1, 2, 3], orderType) && !isEditableQty) {
    //   amountTotal = focusedOrder.get('transaction_cost')
    // } else if (_.includes([1, 2, 3], orderType) && isEditableQty) {
    //   amountTotal = orderType === 3 ? focusedOrder.get('amountTotalBuy') || 0 : focusedOrder.get('amountTotal') || 0
    // } else {
    //   amountTotal = focusedOrder.get('amountTotal')
    // }
    if (obj.key === RE_CALCULATE) {
      await xUtil.setStatePromise(this, { visibleModelCod: true })
    } else if (obj.key === SPECIFY) {
      let newValue = obj.value
      if (_.isNil(newValue)) {
        newValue = 0
      }
      onEditingOrderChange({ key: 'cod_amount', value: xUtil.validateInputCurrency(obj.value), orderTabIndex })
    } else if (obj.key === PERCENT_CALCULATE) {
      await xUtil.setStatePromise(this, { isVisibleSetModelPercentDiscount: true })
    }
  }

  _renderSummaryCurrencyInputOrText = ({ orderIndex, key, value, isEditable = true, style = {} }) => {
    const { onEditingOrderChange } = this.props
    // const { orderTabIndex } = this.state
    const inputKey = `${key}_${orderIndex}`
    // log(`_renderSummaryCurrencyInputOrText orderId=${orderId} this._orderTabsRef => `, this._orderTabsRef)
    return (
      <XInput
        // w='full'
        h='10'
        key={inputKey}
        // key={orderIndex}
        isDisabled={!isEditable}
        textAlign='right'
        selectTextOnFocus
        // editable={isEditable}
        // onRef={(r) => xUtil.pushNativeBaseRef(this.inputRefs, inputKey, r)}
        // onFocus={() => this.setState({ currentRef: inputKey })}
        // style={{
        //   // flex: 1,
        //   // height: 40,
        //   flex: 0,
        //   // width: p.op.isWeb() ? 140 : undefined, // Fixed width
        //   ...Platform.select({
        //     web: {
        //       flex: 1,
        //       width: '100%',
        //     },
        //   }),
        //   paddingRight: NUMBER_PADDING_RIGHT_PLUS_1,
        //   textAlign: 'right',
        //   fontSize: FONT_SIZE_NORMAL,
        //   // color: TEXT_PRIMARY,
        //   // backgroundColor: FORM_PRIMARY_BG,
        //   // ...Platform.select({
        //   //   // ios: { paddingBottom: 0 },
        //   //   android: { paddingBottom: 10, height: 46 },
        //   // }),
        //   ...style,
        // }}
        // keyboardType='numeric'
        // value={xFmt.formatCurrency(value)}
        value={value.toString()}
        isMoney
        // isNumber
        maxLength={11}
        // returnKeyType='done'
        onChangeText={(newValue) => onEditingOrderChange({ key, value: newValue, orderTabIndex: orderIndex })}
        // selectTextOnFocus={false}
      />
    )
  }

  // _renderProduct = (productItems: {
  //   store_id: number
  //   index: number
  //   product: Map<string, any>
  //   orderType: number
  //   isCreateMode?: boolean
  //   isEditable?: boolean
  //   isRemovable?: boolean
  //   canShowProfit?: boolean
  //   isCanceledOrder?: boolean
  //   isLastRow?: boolean
  //   order: Map<string, any>
  // }) => {
  //   const {
  //     store_id,
  //     index,
  //     product,
  //     isCreateMode = false,
  //     isEditable = false,
  //     isRemovable = false,
  //     canShowProfit = false,
  //     isCanceledOrder = false,
  //     orderType,
  //     isLastRow = false,
  //     order = Map({}),
  //   } = productItems
  //   // log('renderProduct idx, index => ', JSON.stringify(idx), JSON.stringify(index))
  //   if (_.isNull(product) || _.isUndefined(product) || !isImmutable(product)) {
  //     return null
  //   }

  //   const {
  //     onChangeStoreProductByKey,
  //     editingOrder,
  //     // onChangeOrderProductVariantByKey,
  //     // onChangeSuborderProductVariantByKey,
  //   } = this.props

  //   // log('_renderProduct product.toJS()', product.toJS())

  //   const name = product.get('name') ? product.get('name') : ''
  //   const variant = product.get('variant') ? product.get('variant') : ''
  //   const thumbnail_uri = product.getIn(['thumbnail_uris', 0]) ? product.getIn(['thumbnail_uris', 0]) : ''

  //   let price = null
  //   if (orderType === 3) {
  //     // ถ้า focusedOrder = 3 ราตาต้องเป็นราตา ฉัน ซื้อ ผู้ขายส่ง
  //     price = product.get('price_buy') ? product.get('price_buy') || '0' : 0
  //   } else {
  //     price = product.get('price') ? product.get('price') || '0' : 0
  //   }
  //   // log('product',product.toJS())
  //   // log('price!',price)

  //   const qty = product.get('qty') ? parseInt(product.get('qty') || 0) : 0 // TextInput
  //   const shipped_qty = product.get('shipped_qty') ? parseFloat(product.get('shipped_qty') || 0) : 0
  //   const priceToNumber = _.isString(price) ? +price : price
  //   const subtotal = priceToNumber * qty

  //   const pp_id = product.get('pp_id')
  //   // const parent_store_id = product.get('parent_store_id')
  //   // const isIOS = p.op.isIOS()
  //   const textSize = FONT_SIZE_SMALLER
  //   const textContainerHeight = 36

  //   const isFreezeProduct = product.get('isFreeze') ? product.get('isFreeze') : false

  //   // const cost = product.get('cost') ? parseFloat(product.get('cost')) : 0
  //   const profit = product.get('profit')
  //   // const profit = price - cost
  //   let txtProfit = null
  //   let bgProfit = TEXT_INACTIVE
  //   if (profit > 0) {
  //     txtProfit = `กำไร\n${xFmt.formatCurrency(profit)}`
  //     bgProfit = COLORS.BRAND_Success
  //   } else if (profit < 0) {
  //     txtProfit = `ขาดทุน\n${xFmt.formatCurrency(profit)}`
  //     bgProfit = COLORS.BRAND_Danger
  //   } else {
  //     // === 0
  //     txtProfit = `กำไร\n${xFmt.formatCurrency(0)}`
  //     bgProfit = COLORS.BRAND_Success
  //   }

  //   const vdDiscounts = order.get('vd_discounts') || List([])
  //   // @ts-ignore
  //   const hasVdDiscount = vdDiscounts.size > 0

  //   const onChangeTextFunction = null
  //   // if (isCreateMode) {
  //   //   onChangeTextFunction = onChangeStoreProductByKey
  //   // } else {
  //   //   onChangeTextFunction = isChild ? onChangeSuborderProductVariantByKey : onChangeOrderProductVariantByKey
  //   // }
  //   // onChangeTextFunction = onChangeStoreProductByKey

  //   // TODO: Read me again and implement me while using VDs
  //   // const my_vds = List.isList(product.get('my_vds')) ? product.get('my_vds') : null
  //   // const myVdList = List.isList(editingOrder.get('myVdList')) ? editingOrder.get('myVdList') : null
  //   //
  //   // if (my_vds && myVdList && my_vds.size >= 2 && myVdList.size > 0) {
  //   //   const foundCustomerDiscountIndex = myVdList.findIndex(vd => Map.isMap(vd) && vd.get('pp_id') === pp_id)
  //   //   const foundCustomerDiscount = foundCustomerDiscountIndex > - 1
  //   //     ? myVdList.get(foundCustomerDiscountIndex) : null
  //   //   if (Map.isMap(foundCustomerDiscount) && foundCustomerDiscount.get('discountAmount')) {
  //   //     const discountAmount = foundCustomerDiscount.get('discountAmount')
  //   //     const discount = foundCustomerDiscount.get('discount')
  //   //     const vdsType = foundCustomerDiscount.get('vds_type')
  //   //     const priceIncDiscount = foundCustomerDiscount.get('priceIncDiscount')
  //   //
  //   //     let txtDiscount
  //   //     if (vdsType === 1) {
  //   //       txtDiscount = `${discount}%`
  //   //     } else if (vdsType === 2) {
  //   //       txtDiscount = `฿${discount}`
  //   //     } else {
  //   //       txtDiscount = null // should be impossible
  //   //     }
  //   //
  //   //     return (
  //   //       <Fragment>
  //   //         {
  //   //           this._renderProductCartItem({
  //   //             idx, index, isRemovable, isFreezeProduct, pp_id, name, variant,
  //   //             thumbnail_uri, isCreateMode, canShowProfit, textSize, bgProfit,
  //   //             txtProfit, textContainerHeight, price, onChangeTextFunction,
  //   //             isEditable, isCanceledOrder, orderType, qty, subtotal,
  //   //           })
  //   //         }
  //   //         {
  //   //           this._renderProductCartItem({
  //   //             idx,
  //   //             index: index + discountAmount + Math.round(Math.random() * 10000),
  //   //             // name: `ส่วนลดขายส่ง ${txtDiscount} ต่อชิ้น สำหรับ "${name}"`,
  //   //             name: '',
  //   //             // variant: `ส่วนลดขายส่ง ${txtDiscount} ต่อชิ้น สำหรับ "${name}"`,
  //   //             variant: `ส่วนลดขายส่ง ${txtDiscount} ต่อชิ้น`,
  //   //             price: priceIncDiscount * -1,
  //   //             qty,
  //   //             subtotal: discountAmount * -1,
  //   //             thumbnail_uri: null,
  //   //             isRemovable,
  //   //             isCreateMode: false,
  //   //             isEditable: false,
  //   //             canShowProfit: false,
  //   //             isFreezeProduct: true,
  //   //             pp_id,
  //   //             textSize, bgProfit, txtProfit,
  //   //             textContainerHeight, onChangeTextFunction,
  //   //             isCanceledOrder, orderType,
  //   //             isSubRow: true,
  //   //           })
  //   //         }
  //   //       </Fragment>
  //   //     )
  //   //   }
  //   // }

  //   // TODO: MOCK!!! Remove me
  //   // const ugpgPurchaseDiscountAmount = product.get('ugpgPurchaseDiscountAmount') ? parseInt(product.get('ugpgPurchaseDiscountAmount') || 0) : 0
  //   // const ugpgPurchaseDiscountPercent = product.get('ugpgPurchaseDiscountPercent') ? parseInt(product.get('ugpgPurchaseDiscountPercent') || 0) : 0
  //   // const ugpgSaleDiscountAmount = product.get('ugpgSaleDiscountAmount') ? parseInt(product.get('ugpgSaleDiscountAmount') || 0) : 0
  //   // const ugpgSaleDiscountPercent = product.get('ugpgSaleDiscountPercent') ? parseInt(product.get('ugpgSaleDiscountPercent') || 0) : 0

  //   let txtPurchaseDiscount = null
  //   let txtSaleDiscount = null
  //   let txtPurchaseDiscountForOrderType3 = null
  //   const purchaseDiscount = product.get('purchaseDiscount')
  //   const purchaseDiscountPerEach = product.get('purchaseDiscountPerEach')
  //   const saleDiscount = product.get('saleDiscount')
  //   const saleDiscountPerEach = product.get('saleDiscountPerEach')
  //   if ((isCreateMode || _.includes([1, 2], orderType)) && saleDiscountPerEach && saleDiscountPerEach > 0 && saleDiscount) {
  //     txtSaleDiscount = 'ส่วนลดสมาชิกให้ลูกค้า'
  //   }
  //   if ((isCreateMode || (_.includes([1, 2], orderType) && canShowProfit && this.state.showProfit)) && purchaseDiscountPerEach) {
  //     txtPurchaseDiscount = `กำไรเพิ่ม ฿${purchaseDiscountPerEach} ต่อชิ้น จากส่วนลดต้นทุนที่ผู้ขายส่งให้ฉัน`
  //   }
  //   if ((isCreateMode || _.includes([3], orderType)) && purchaseDiscountPerEach) {
  //     txtPurchaseDiscountForOrderType3 = `ส่วนลดต้นทุนที่ผู้ขายส่งให้ฉัน`
  //   }

  //   // log(this, '_renderProduct isCreateMode => ', isCreateMode)
  //   // log(this, '_renderProduct txtPurchaseDiscount => ', txtPurchaseDiscount)
  //   // log(this, '_renderProduct canShowProfit => ', canShowProfit)
  //   // log(this, '_renderProduct this.state.showProfit => ', this.state.showProfit)
  //   return (
  //     <>
  //       {this._renderProductCartItem({
  //         store_id,
  //         index,
  //         isRemovable,
  //         isFreezeProduct,
  //         pp_id,
  //         name,
  //         variant,
  //         thumbnail_uri,
  //         isCreateMode,
  //         canShowProfit,
  //         textSize,
  //         bgProfit,
  //         txtProfit,
  //         textContainerHeight,
  //         price,
  //         onChangeTextFunction: onChangeStoreProductByKey,
  //         isEditable,
  //         isCanceledOrder,
  //         orderType,
  //         qty,
  //         subtotal,
  //       })}
  //       {/* {hasVdDiscount
  //         ? null
  //         : this._renderProductCartAdditionInfoRow({
  //             idx,
  //             index,
  //             uniqueKey: 'sdc',
  //             isRemovable,
  //             description: txtSaleDiscount,
  //             txtPrice: `฿${saleDiscountPerEach}`,
  //             // txtQty: '',
  //             txtQty: qty,
  //             txtSubtotal: `-฿${saleDiscount}`,
  //             textContainerHeight,
  //             textSize,
  //             txtColor: COLORS.BRAND_Danger,
  //             // bgColor: COLORS.FORM_ERROR_BG,
  //           })} */}
  //       {/* {hasVdDiscount
  //         ? null
  //         : this._renderProductCartAdditionInfoRow({
  //             idx,
  //             index,
  //             uniqueKey: 'pdc',
  //             isRemovable,
  //             description: txtPurchaseDiscount,
  //             descOnly: true,
  //             textContainerHeight,
  //             textSize,
  //             txtColor: COLORS.BRAND_Success,
  //             // bgColor: COLORS.FORM_SUCCESS_BG,
  //           })} */}
  //       {/* {hasVdDiscount
  //         ? null
  //         : this._renderProductCartAdditionInfoRow({
  //             idx,
  //             index,
  //             uniqueKey: 'pdct3',
  //             isRemovable,
  //             description: txtPurchaseDiscountForOrderType3,
  //             txtPrice: `฿${purchaseDiscountPerEach}`,
  //             // txtQty: '',
  //             txtQty: qty,
  //             txtSubtotal: `-฿${purchaseDiscount}`,
  //             textContainerHeight,
  //             textSize,
  //             txtColor: COLORS.BRAND_Danger,
  //             // bgColor: COLORS.FORM_ERROR_BG,
  //           })} */}
  //     </>
  //   )
  // }

  // _renderProductCartAdditionInfoRow = (params: { [key: string]: any }) => {
  //   const {
  //     idx,
  //     index,
  //     uniqueKey = '0',
  //     isRemovable,
  //     description = null,
  //     descOnly = false,
  //     txtPrice = '',
  //     txtQty = '',
  //     txtSubtotal = '',
  //     textContainerHeight,
  //     textSize,
  //     txtColor = COLORS.TEXT_INACTIVE,
  //     bgColor = 'white',
  //   } = params

  //   if (!description || description.length === 0) {
  //     return null
  //   }

  //   return (
  //     <View
  //       key={`ProductAdditionInfo_${idx}${index}${uniqueKey}`}
  //       style={{
  //         // backgroundColor: 'red',
  //         width: '100%',
  //         flexDirection: 'row',
  //         justifyContent: 'center',
  //         alignItems: 'stretch',
  //         borderWidth: 1,
  //         borderTopWidth: 0,
  //         borderColor: TEXT_INACTIVE,
  //         backgroundColor: bgColor,
  //         ...Platform.select({
  //           web: {
  //             borderTopWidth: 1,
  //             borderLeftWidth: 1,
  //             borderRightWidth: 1,
  //           },
  //         }),
  //       }}>
  //       {isRemovable ? (
  //         <View
  //           style={{
  //             // flex: p.op.isWeb() ? 2 : 1,
  //             flex: 2,
  //             flexDirection: 'column',
  //             justifyContent: 'center',
  //             alignItems: 'stretch',
  //             backgroundColor: 'transparent',
  //             borderRightWidth: 1,
  //             borderColor: TEXT_INACTIVE,
  //           }}
  //         />
  //       ) : null}
  //       <View style={{ flex: descOnly ? 20 : 7, padding: 3 }}>
  //         <Stack style={[s.webFlexBasis, s.webWidth100Percent]}>
  //           <HStack>
  //             <VStack size={100} style={{ justifyContent: 'center' }}>
  //               <XText
  //                 allowFontScaling={false}
  //                 style={{
  //                   marginTop: 0,
  //                   paddingTop: 0,
  //                   fontSize: FONT_SIZE_SMALLER,
  //                   color: txtColor,
  //                 }}>
  //                 {description}
  //               </XText>
  //             </VStack>
  //           </HStack>
  //         </Stack>
  //       </View>

  //       {/* price qty subtotal */}
  //       {descOnly ? null : (
  //         <View
  //           style={{
  //             flex: 5,
  //             flexDirection: 'row',
  //             justifyContent: 'center',
  //             alignItems: 'center',
  //             borderLeftWidth: 1,
  //             borderColor: TEXT_INACTIVE,
  //           }}>
  //           <XText
  //             style={{
  //               flex: 0,
  //               textAlign: 'center',
  //               fontSize: textSize,
  //               color: txtColor,
  //               marginTop: 0,
  //             }}>
  //             {txtPrice}
  //           </XText>
  //         </View>
  //       )}
  //       {descOnly ? null : (
  //         <View
  //           style={{
  //             flex: 3,
  //             flexDirection: 'row',
  //             justifyContent: 'center',
  //             alignItems: 'center',
  //             borderLeftWidth: 1,
  //             borderColor: TEXT_INACTIVE,
  //           }}>
  //           <View
  //             style={{
  //               flex: 1,
  //               height: textContainerHeight,
  //               // marginTop: 0,
  //               flexDirection: 'column',
  //               justifyContent: 'center',
  //               alignItems: 'center',
  //             }}>
  //             <XText
  //               style={{
  //                 textAlign: 'center',
  //                 fontSize: textSize,
  //                 color: txtColor,
  //                 marginTop: 0,
  //               }}>
  //               {txtQty}
  //             </XText>
  //           </View>
  //         </View>
  //       )}
  //       {descOnly ? null : (
  //         <View
  //           style={{
  //             flex: 5,
  //             flexDirection: 'row',
  //             justifyContent: 'center',
  //             alignItems: 'center',
  //             borderLeftWidth: 1,
  //             borderColor: TEXT_INACTIVE,
  //           }}>
  //           <View
  //             style={{
  //               flex: 1,
  //               height: textContainerHeight,
  //               flexDirection: 'column',
  //               justifyContent: 'center',
  //               alignItems: 'flex-end',
  //             }}>
  //             <XText
  //               style={{
  //                 textAlign: 'center',
  //                 fontSize: textSize,
  //                 color: txtColor,
  //                 marginRight: NUMBER_PADDING_RIGHT,
  //                 marginTop: 0,
  //               }}>
  //               {txtSubtotal}
  //             </XText>
  //           </View>
  //         </View>
  //       )}
  //     </View>
  //   )
  // }

  // _renderProductCartItem = (params: { [key: string]: any }) => {
  //   const {
  //     store_id,
  //     index,
  //     isRemovable,
  //     isFreezeProduct,
  //     pp_id,
  //     name,
  //     variant,
  //     thumbnail_uri,
  //     isCreateMode,
  //     canShowProfit,
  //     textSize,
  //     bgProfit,
  //     txtProfit,
  //     textContainerHeight,
  //     price,
  //     onChangeTextFunction,
  //     isEditable,
  //     isCanceledOrder,
  //     orderType,
  //     qty,
  //     subtotal,
  //     isSubRow = false, // add for volume discount row
  //   } = params
  //   // console.log('canShowProfit :', canShowProfit)
  //   // console.log('txtProfit :', txtProfit)
  //   // console.log('price:',price)
  //   // log('params',params)
  //   return (
  //     <XCard key={`Product_${store_id}_${index}`}>
  //       <XCard.Body>
  //         <HStack space='2'>
  //           <VStack space='1'>
  //             <Box flex={1}>
  //               <FastImage
  //                 resizeMode={FastImage.resizeMode.cover}
  //                 style={{
  //                   // minWidth: 86,
  //                   // maxWidth: 112,
  //                   // height: 80,
  //                   width: 64,
  //                   height: 64,
  //                   borderRadius: 8,
  //                 }}
  //                 source={thumbnail_uri || emptyImageSrc}
  //               />
  //             </Box>

  //             <HStack>
  //               {isRemovable && !isFreezeProduct ? (
  //                 <XIconButton
  //                   size='sm'
  //                   name='trash'
  //                   variant='subtle'
  //                   iconVariant='inactive'
  //                   onPress={() => this._onDeleteProduct({ store_id, pp_id })}
  //                 />
  //               ) : null}
  //             </HStack>
  //           </VStack>

  //           <VStack flex={1} space='1'>
  //             {/* Row 1: Product's name + Variant's name --- Delete Button */}
  //             <HStack space='1'>
  //               <VStack flex={1} flexWrap='wrap' space='1'>
  //                 {/* Row 1-1: Product's name --- Delete Button */}
  //                 <XText>{name}</XText>
  //                 {/* Row 1-2: Product's variant name */}
  //                 {!_.isNull(variant) && _.isString(variant) && variant.length > 0 ? (
  //                   <XText variant='inactive'>{variant}</XText>
  //                 ) : // </Row>
  //                 null}
  //               </VStack>
  //             </HStack>

  //             {/* Row 2: Product's price  */}
  //             <HStack space='1'>
  //               <XText bold>{xFmt.formatCurrency(price)}</XText>
  //             </HStack>

  //             {/* Row 3: Product's  qty */}
  //             <HStack space='1'>
  //               <RInput
  //                 w='32'
  //                 // h='9'
  //                 // keyboardType='numeric'
  //                 // value={`${qty.toString()}` || '0'}
  //                 value={qty}
  //                 minValue={1}
  //                 maxValue={99999}
  //                 maxLength={5}
  //                 onChangeText={(text) => onChangeTextFunction({ store_id, index, value: text, key: 'qty', pp_id })}
  //               />
  //             </HStack>
  //           </VStack>
  //         </HStack>
  //       </XCard.Body>
  //     </XCard>
  //   )

  //   // return (
  //   //   <View
  //   //     key={`Product_${idx}_${index}`}
  //   //     style={{
  //   //       // backgroundColor: 'red',
  //   //       width: '100%',
  //   //       flexDirection: 'row',
  //   //       justifyContent: 'center',
  //   //       alignItems: 'stretch',
  //   //       borderWidth: 1,
  //   //       borderTopWidth: 0,
  //   //       borderColor: TEXT_INACTIVE,
  //   //       backgroundColor: isSubRow ? BG_LIGHT_GREY : 'white',
  //   //       ...Platform.select({
  //   //         web: {
  //   //           borderTopWidth: 1,
  //   //           borderLeftWidth: 1,
  //   //           borderRightWidth: 1,
  //   //         },
  //   //       }),
  //   //     }}>
  //   //     {isRemovable ? (
  //   //       <View
  //   //         style={{
  //   //           // flex: p.op.isWeb() ? 2 : 1,
  //   //           flex: 2,
  //   //           flexDirection: 'column',
  //   //           justifyContent: 'center',
  //   //           alignItems: 'stretch',
  //   //           backgroundColor: !isFreezeProduct ? FORM_ERROR : 'transparent',
  //   //           borderRightWidth: 1,
  //   //           borderColor: TEXT_INACTIVE,
  //   //         }}>
  //   //         {!isFreezeProduct ? (
  //   //           <TouchableOpacity
  //   //             onPress={() => this._onDeleteProduct({ store_id: idx, pp_id })}
  //   //             style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
  //   //             <Icon
  //   //               name='trash'
  //   //               style={StyleSheet.flatten([
  //   //                 s.webFlexBasis,
  //   //                 {
  //   //                   flex: 0,
  //   //                   marginRight: 0,
  //   //                   paddingRight: 0,
  //   //                   textAlign: 'center',
  //   //                   fontSize: FONT_ICON_SMALLER,
  //   //                   width: FONT_ICON_SMALLER,
  //   //                   // marginLeft: 2,
  //   //                   // marginRight: 2,
  //   //                   color: TEXT_ACTIVE_DARK,
  //   //                 },
  //   //               ])}
  //   //             />
  //   //           </TouchableOpacity>
  //   //         ) : null}
  //   //       </View>
  //   //     ) : null}
  //   //     <View style={{ flex: 7, padding: 3, paddingTop: 0, flexWrap: 'wrap' }}>
  //   //       <Stack>
  //   //         {!_.isNull(name) && _.isString(name) && name.length > 0 ? (
  //   //           <HStack
  //   //             // @ts-ignore
  //   //             style={{
  //   //               flexDirection: 'column',
  //   //               justifyContent: 'center',
  //   //               alignItems: 'flex-start',
  //   //               // flexWrap: 'warp',
  //   //               // overflow: 'hidden',
  //   //               ...Platform.select({
  //   //                 web: {
  //   //                   flexWrap: 'warp',
  //   //                   overflow: 'hidden',
  //   //                 },
  //   //               }),
  //   //             }}>
  //   //             <XText
  //   //               numberOfLines={1}
  //   //               style={{
  //   //                 // FIXME: THIS IS MAGICK NUMBER
  //   //                 maxWidth: p.op.isWeb() ? 145 : undefined,
  //   //                 marginTop: 0,
  //   //                 paddingTop: 0,
  //   //                 paddingBottom: 2,
  //   //                 fontSize: FONT_SIZE_SMALLER,
  //   //                 color: TEXT_ACTIVE,
  //   //               }}>
  //   //               {name}
  //   //             </XText>
  //   //           </HStack>
  //   //         ) : null}
  //   //         {!_.isNull(variant) && _.isString(variant) && variant.length > 0 ? (
  //   //           // <Row
  //   //           //   // @ts-ignore
  //   //           //   style={{
  //   //           //     flexDirection: 'column',
  //   //           //     justifyContent: 'center',
  //   //           //     alignItems: 'flex-start',
  //   //           // backgroundColor: COLORS.GREEN_SOFT,
  //   //           // flexWrap: 'warp',
  //   //           // overflow: 'hidden',
  //   //           // ...Platform.select({
  //   //           // web: {
  //   //           //    flexWrap: 'warp',
  //   //           //    overflow: 'hidden',
  //   //           //  },
  //   //           // }),
  //   //           // }}>
  //   //           <XText
  //   //             numberOfLines={1}
  //   //             style={{
  //   //               // FIXME: THIS IS MAGICK NUMBER
  //   //               maxWidth: p.op.isWeb() ? 145 : undefined,
  //   //               marginTop: 0,
  //   //               paddingTop: 0,
  //   //               fontSize: FONT_SIZE_SMALLER,
  //   //               color: TEXT_INACTIVE,
  //   //             }}>
  //   //             {variant}
  //   //           </XText>
  //   //         ) : // </Row>
  //   //         null}
  //   //         {thumbnail_uri ? (
  //   //           // <Row
  //   //           //   style={{
  //   //           //     height: 80,
  //   //           //     flexDirection: 'column',
  //   //           //     // justifyContent: 'center',
  //   //           //     backgroundColor: COLORS.ORANGE_SOFT,
  //   //           //     justifyContent: 'flex-start',
  //   //           //     alignItems: 'stretch',
  //   //           //   }}>
  //   //           <FastImage
  //   //             // resizeMode={FastImage.resizeMode.contain}
  //   //             resizeMode={FastImage.resizeMode.cover}
  //   //             style={{
  //   //               // height: '100%',
  //   //               minWidth: 86,
  //   //               maxWidth: 112,
  //   //               height: 80,
  //   //             }}
  //   //             source={thumbnail_uri}
  //   //           />
  //   //         ) : // </Row>
  //   //         null}
  //   //         {/* <Image resizeMode='contain' style={{ flex: 1 }} source={{ uri: thumbnail_uri }}/> */}
  //   //       </Stack>
  //   //     </View>

  //   //     {/* price qty subtotal */}
  //   //     <View
  //   //       // @ts-ignore
  //   //       style={{
  //   //         flex: 5,
  //   //         flexDirection: 'column',
  //   //         justifyContent: 'flex-end',
  //   //         alignItems: 'flex-end',
  //   //         borderLeftWidth: 1,
  //   //         borderColor: TEXT_INACTIVE,
  //   //         ...Platform.select({
  //   //           web: {
  //   //             overflow: 'hidden',
  //   //             flexGrow: 1,
  //   //             flexShrink: 1,
  //   //           },
  //   //         }),
  //   //       }}>
  //   //       {isCreateMode || (isEditable && !isCanceledOrder && _.includes([1], orderType) && !isFreezeProduct) ? (
  //   //         <>
  //   //           {canShowProfit && this.state.showProfit ? (
  //   //             <View
  //   //               style={{
  //   //                 width: '100%',
  //   //                 minHeight: textContainerHeight,
  //   //                 // backgroundColor: 'green',
  //   //                 flexDirection: 'column',
  //   //                 justifyContent: 'center',
  //   //                 alignItems: 'center',
  //   //                 flexWrap: 'wrap',
  //   //               }}>
  //   //               <XText
  //   //                 allowFontScaling={false}
  //   //                 numberOfLines={2}
  //   //                 style={{
  //   //                   width: '100%',
  //   //                   textAlign: 'center',
  //   //                   fontSize: textSize,
  //   //                   color: bgProfit,
  //   //                   marginTop: 0,
  //   //                   marginRight: NUMBER_PADDING_RIGHT,
  //   //                   // backgroundColor: 'orange',
  //   //                 }}>
  //   //                 {txtProfit}
  //   //               </XText>
  //   //             </View>
  //   //           ) : null}
  //   //           <View
  //   //             style={{
  //   //               width: '100%',
  //   //               minHeight: textContainerHeight,
  //   //               // backgroundColor: 'skyblue',
  //   //               flexDirection: 'column',
  //   //               justifyContent: 'center',
  //   //               alignItems: 'center',
  //   //               flexWrap: 'wrap',
  //   //             }}>
  //   //             <XInput
  //   //               // unitLabelWidth={18}
  //   //               // onRef={(r) => xUtil.pushNativeBaseRef(this.inputRefs, `mProduct_price_${idx}_${index}`, r)}
  //   //               // onFocus={() => this.setState({ currentRef: `mProduct_price_${idx}_${index}` })}
  //   //               style={{
  //   //                 flex: 1,
  //   //                 // flex: 0,
  //   //                 // flexBasis: 'auto',
  //   //                 // width: '100%',
  //   //                 // flex: 1,
  //   //                 // flexBasis: 0,
  //   //                 // width: '100%',
  //   //                 textAlign: 'center',
  //   //                 // paddingBottom: isIOS ? 2 : 5,
  //   //                 height: textContainerHeight,
  //   //                 fontSize: textSize,
  //   //                 // borderBottomWidth: 0,
  //   //                 // color: TEXT_PRIMARY,
  //   //               }}
  //   //               keyboardType='numeric'
  //   //               // returnKeyType='done'
  //   //               // value={xFmt.formatCurrency(price)}
  //   //               value={price.toString()}
  //   //               isMoney
  //   //               maxLength={11}
  //   //               onChangeText={(text) =>
  //   //                 onChangeTextFunction({ idx, index, value: xUtil.validateInputCurrency(text), key: 'price', pp_id })
  //   //               }
  //   //             />
  //   //           </View>
  //   //         </>
  //   //       ) : (
  //   //         <>
  //   //           {canShowProfit && this.state.showProfit ? (
  //   //             <View
  //   //               style={{
  //   //                 width: '100%',
  //   //                 minHeight: textContainerHeight,
  //   //                 // backgroundColor: 'pink',
  //   //                 flexDirection: 'column',
  //   //                 justifyContent: 'center',
  //   //                 alignItems: 'center',
  //   //                 flexWrap: 'wrap',
  //   //               }}>
  //   //               <XText
  //   //                 style={{
  //   //                   width: '100%',
  //   //                   textAlign: 'center',
  //   //                   fontSize: textSize,
  //   //                   color: bgProfit,
  //   //                 }}>
  //   //                 {txtProfit}
  //   //               </XText>
  //   //             </View>
  //   //           ) : null}
  //   //           <View
  //   //             style={{
  //   //               width: '100%',
  //   //               minHeight: textContainerHeight,
  //   //               // backgroundColor: 'purple',
  //   //               flexDirection: 'column',
  //   //               justifyContent: 'center',
  //   //               alignItems: 'center',
  //   //               flexWrap: 'wrap',
  //   //             }}>
  //   //             <XText
  //   //               style={{
  //   //                 width: '100%',
  //   //                 textAlign: 'center',
  //   //                 fontSize: textSize,
  //   //                 color: TEXT_INACTIVE,
  //   //               }}>
  //   //               {xFmt.formatCurrency(price)}
  //   //             </XText>
  //   //           </View>
  //   //         </>
  //   //       )}
  //   //     </View>

  //   //     <View
  //   //       // @ts-ignore
  //   //       style={{
  //   //         flex: 3,
  //   //         flexDirection: 'column',
  //   //         justifyContent: 'flex-end',
  //   //         alignItems: 'flex-end',
  //   //         borderLeftWidth: 1,
  //   //         borderColor: TEXT_INACTIVE,
  //   //         ...Platform.select({
  //   //           web: {
  //   //             overflow: 'hidden',
  //   //           },
  //   //         }),
  //   //       }}>
  //   //       <View
  //   //         style={{
  //   //           width: '100%',
  //   //           minHeight: textContainerHeight,
  //   //           // backgroundColor: 'blue',
  //   //           flexDirection: 'column',
  //   //           justifyContent: 'center',
  //   //           alignItems: 'center',
  //   //           flexWrap: 'wrap',
  //   //         }}>
  //   //         {
  //   //           // ตอนนี้ หลังบ้านให้ edit qty ได้แต่ type 1
  //   //           isCreateMode || (isEditable && !isCanceledOrder && _.includes([1, 2], orderType)) ? (
  //   //             // isEditable && !isCanceledOrder ? (
  //   //             <XInput
  //   //               // onRef={(r) => xUtil.pushNativeBaseRef(this.inputRefs, `mProduct_qty_${idx}_${index}`, r)}
  //   //               // onFocus={() => this.setState({ currentRef: `mProduct_qty_${idx}_${index}` })}
  //   //               style={{
  //   //                 // flex: 1,
  //   //                 flex: 1,
  //   //                 flexBasis: 0,
  //   //                 // width: '100%',
  //   //                 textAlign: 'center',
  //   //                 // paddingBottom: isIOS ? 2 : 5,
  //   //                 height: textContainerHeight,
  //   //                 fontSize: textSize,
  //   //                 // borderBottomWidth: 0,
  //   //                 color: TEXT_PRIMARY,
  //   //               }}
  //   //               keyboardType='numeric'
  //   //               // returnKeyType='done'
  //   //               value={`${qty.toString()}` || '0'}
  //   //               maxLength={4}
  //   //               onChangeText={(text) => onChangeTextFunction({ idx, index, value: text, key: 'qty', pp_id })}
  //   //             />
  //   //           ) : (
  //   //             <XText
  //   //               style={{
  //   //                 width: '100%',
  //   //                 textAlign: 'center',
  //   //                 fontSize: textSize,
  //   //                 color: TEXT_INACTIVE,
  //   //                 // marginRight: NUMBER_PADDING_RIGHT,
  //   //                 // marginTop: 0,
  //   //                 // paddingBottom: 2,
  //   //               }}>
  //   //               {`${qty}` || '0'}
  //   //             </XText>
  //   //           )
  //   //         }
  //   //       </View>
  //   //     </View>
  //   //     <View
  //   //       style={{
  //   //         flex: 5,
  //   //         flexDirection: 'column',
  //   //         // justifyContent: 'center',
  //   //         // justifyContent: 'flex-end',
  //   //         // alignItems: 'flex-end',
  //   //         borderLeftWidth: 1,
  //   //         borderColor: TEXT_INACTIVE,
  //   //         flexWrap: 'wrap',
  //   //         justifyContent: 'flex-end',
  //   //         alignItems: 'flex-end',
  //   //       }}>
  //   //       <View
  //   //         style={{
  //   //           width: '100%',
  //   //           minHeight: textContainerHeight,
  //   //           // backgroundColor: 'red',
  //   //           flexDirection: 'column',
  //   //           justifyContent: 'center',
  //   //           alignItems: 'center',
  //   //           flexWrap: 'wrap',
  //   //         }}>
  //   //         <XText
  //   //           style={[
  //   //             s.webFlexBasis,
  //   //             {
  //   //               width: '100%',
  //   //               // textAlign: 'center',
  //   //               textAlign: 'right',
  //   //               fontSize: textSize,
  //   //               color: TEXT_INACTIVE,
  //   //               paddingRight: NUMBER_PADDING_RIGHT,
  //   //               // marginTop: 0,
  //   //             },
  //   //           ]}>
  //   //           {xFmt.formatCurrency(subtotal)}
  //   //         </XText>
  //   //       </View>
  //   //       {/* <View */}
  //   //       {/*  style={[ */}
  //   //       {/*    s.webFlexBasis, */}
  //   //       {/*    // s.webWidth100Percent, */}
  //   //       {/*    { */}
  //   //       {/*      flex: 0, */}
  //   //       {/*      height: textContainerHeight, */}
  //   //       {/*      paddingBottom: 5, */}
  //   //       {/*      flexDirection: 'column', */}
  //   //       {/*      justifyContent: 'flex-end', */}
  //   //       {/*      alignItems: 'flex-end', */}
  //   //       {/*      flexWrap: 'wrap', */}
  //   //       {/*    }, */}
  //   //       {/*  ]}> */}
  //   //       {/*  <XText */}
  //   //       {/*    style={[ */}
  //   //       {/*      s.webFlexBasis, */}
  //   //       {/*      // s.webWidth100Percent, */}
  //   //       {/*      { */}
  //   //       {/*        // textAlign: 'center', */}
  //   //       {/*        textAlign: 'right', */}
  //   //       {/*        fontSize: textSize, */}
  //   //       {/*        color: TEXT_INACTIVE, */}
  //   //       {/*        marginRight: NUMBER_PADDING_RIGHT, */}
  //   //       {/*        marginTop: 0, */}
  //   //       {/*      }, */}
  //   //       {/*    ]}> */}
  //   //       {/*    {xFmt.formatCurrency(subtotal)} */}
  //   //       {/*  </XText> */}
  //   //       {/* </View> */}
  //   //     </View>
  //   //   </View>
  //   // )
  // }

  _renderNavToPickProductToOrderButton = () => {
    const { selectedOrder, selectedStore } = this.props
    if (!Map.isMap(selectedStore)) {
      return null
    }

    const { mode } = this.state
    const orderId = selectedOrder.get('id')
    const ug_id = selectedOrder.get('user_group_id')

    const user_groups = selectedStore.get('user_groups') || List([])
    const product_groups = selectedStore.get('product_groups') || List([])
    const hasNoUg = user_groups.size === 0
    const hasOnlyOneUg = user_groups.size === 1
    const haveOnlyOnePg = product_groups.size === 1
    const hasSellerStores = this._hasSellerStores()
    // if (!hasSellerStores && hasOnlyOneUg && haveOnlyOnePg) {
    //   return this._renderAddProductButton()
    // }

    const forceUseOnlyUg =
      (_.includes([CREATE], mode) && (hasNoUg || hasOnlyOneUg) && haveOnlyOnePg) ||
      (_.includes([VIEW_EDIT, EDIT], mode) && _.isNumber(orderId) && _.isNumber(ug_id))
    const forceUseOnlyPg = _.includes([VIEW_EDIT, EDIT], mode) && _.isNumber(orderId) && _.isNil(ug_id)

    const {
      optionChooseProductsByGroupIndex,
      optionChooseProductsFollowingIndex,
      optionChooseProductsFromIndex,
      optionSelectedSellerStoreIndex,
    } = this.state

    return (
      <VStack
        w='full'
        borderWidth='1'
        borderColor='warmGray.300'
        p='1'
        // style={INTL_STYLES.rowGroupAreaBordered}
      >
        {/* Begin Wrapper */}

        <AddStoreProductSelector
          saveOptionSelectedSellerStoreIndexKey='order_selected_seller_store_index'
          saveOptionChooseProductsFromIndexKey={xCONS.STORAGE_KEYS.CREATE_ORDER_LAST_SELECTED_STORE}
          saveOptionChooseProductsFollowingIndexKey={xCONS.STORAGE_KEYS.CREATE_ORDER_LAST_SELECTED_PG_UG}
          saveOptionChooseProductsByGroupIndexKey={xCONS.STORAGE_KEYS.CREATE_ORDER_LAST_SELECTED_PG_UG_INDEX}
          forceUseOnlyUg={forceUseOnlyUg}
          forceUseOnlyPg={forceUseOnlyPg}
          // chooseProductMode={this._getCurrentOrderChooseProductMode()}
          onChangeOptions={this._onChangeAddStoreProductSelectorOptions}
          optionChooseProductsByGroupIndex={optionChooseProductsByGroupIndex}
          optionChooseProductsFollowingIndex={optionChooseProductsFollowingIndex}
          optionChooseProductsFromIndex={optionChooseProductsFromIndex}
          optionSelectedSellerStoreIndex={optionSelectedSellerStoreIndex}
          onChooseProductButtonPressed={this.onAddStoreProductSelectorChooseProductButtonPressed}
          onSearchProductButtonPressed={this.onAddStoreProductSelectorSearchProductButtonPressed}
          onChooseProductAfterBarcodeScanned={this.onChooseProductAfterBarcodeScanned}
        />

        {/* เปลี่ยนใช้งาน AddStoreProductSelector แทน */}
        {/* {this._renderChooseProductFrom()} */}
        {/* {this._renderAddMyProductBySelectedGroup()} */}
        {/* <VStack w='full' mt='2' space='1'>
          <XText variant='inactive'>กดปุ่มด้านล่างเพื่อเลือกสินค้าเข้าออเดอร์...</XText>
          {this._renderAddProductButton()}
        </VStack> */}

        {/* End Wrapper */}
      </VStack>
    )
  }

  // _onChangeAddStoreProductSelectorOptions = async (newOpts: IAddStoreProductSelectorOptions) => {
  //   if (p.op.isWeb()) {
  //     xUtil.navPopToCurrent(this.props)
  //   }

  //   const { selectedOrder } = this.props
  //   const {
  //     optionChooseProductsByGroupIndex,
  //     optionChooseProductsFollowingIndex,
  //     optionChooseProductsFromIndex,
  //     optionSelectedSellerStoreIndex,
  //   } = this.state
  //   const chooseProductMode = this._getCurrentOrderChooseProductMode()
  //   let txtTargetMode = null

  //   const isOptionChooseProductsFollowingIndexChanged =
  //     _.isNumber(newOpts.optionChooseProductsFollowingIndex) &&
  //     optionChooseProductsFollowingIndex !== newOpts.optionChooseProductsFollowingIndex
  //   const isChangeUgToPgAfterAddProduct =
  //     isOptionChooseProductsFollowingIndexChanged && chooseProductMode === 'UG' && newOpts.optionChooseProductsFollowingIndex !== 0
  //   const isChangePgToUgAfterAddProduct =
  //     isOptionChooseProductsFollowingIndexChanged && chooseProductMode === 'PG' && newOpts.optionChooseProductsFollowingIndex !== 1

  //   if (isChangeUgToPgAfterAddProduct) {
  //     txtTargetMode = 'รายการราคา'
  //   } else if (isChangePgToUgAfterAddProduct) {
  //     txtTargetMode = 'กลุ่มสินค้า'
  //   }

  //   const isOptionChooseProductsByGroupIndexChanged =
  //     _.isNumber(newOpts.optionChooseProductsByGroupIndex) && optionChooseProductsByGroupIndex !== newOpts.optionChooseProductsByGroupIndex
  //   const orderId = selectedOrder.get('id')
  //   const ug_id = selectedOrder.get('user_group_id')
  //   if (isOptionChooseProductsByGroupIndexChanged && orderId && ug_id) {
  //     p.op.alert('ไม่สามารถเปลี่ยนกลุ่มผู้ใช้ได้', 'เนื่องจากมีการกำหนดกลุ่มสมาชิกขณะเปิดออเดอร์ไปแล้ว')
  //     return
  //   }

  //   const isChangeUgAfterAddProduct =
  //     chooseProductMode === 'UG' && isOptionChooseProductsByGroupIndexChanged && optionChooseProductsFollowingIndex === 0
  //   if (isChangeUgToPgAfterAddProduct || isChangePgToUgAfterAddProduct || isChangeUgAfterAddProduct) {
  //     const isUserConfirmed = await new Promise<boolean>((passConfirm) => {
  //       p.op.showConfirmation(
  //         'เปลี่ยนแปลงลักษณะราคา',
  //         `กรุณายืนยันว่าคุณต้องการจะเลือกสินค้าใหม่ โดยคิดราคาออเดอร์ตาม${txtTargetMode} และสินค้าที่ถูกเลือกมาแล้วจะลบออกโดยอัตโนมัติ`,
  //         () => passConfirm(true),
  //         () => passConfirm(false)
  //       )
  //     })

  //     if (!isUserConfirmed) {
  //       return
  //     }

  //     await this._initializeDefaultValueByMode()
  //   }

  //   // console.log('_onChangeAddStoreProductSelectorOptions newOptions => ', newOpts)
  //   this.setState(newOpts)
  // }

  onChooseProductAfterBarcodeScanned = (output: IProductAfterAddStoreProductSelectorScannedOutput) => {
    this._onChooseProductAfterBarcodeScanned(output)
  }

  _onChooseProductAfterBarcodeScanned = async (output: IProductAfterAddStoreProductSelectorScannedOutput) => {
    // console.log('_onChangeAddStoreProductSelectorOptions output => ', output)
    const { selectedStore, quickAddProductToOrder } = this.props
    const { optionChooseProductsFromIndex, optionChooseProductsFollowingIndex } = this.state
    const { store_id, seller_store_id, ug_id, pg_ids, product: chooseProduct, variantIndex: chooseVariantIndex } = output
    // const { ug_id, pg_ids } = this._getProductFilterParams()

    // const store_id = selectedStore.get('id')
    const selectedUserGroups = selectedStore.get('user_groups') || List([])
    const selectedProductGroups = selectedStore.get('product_groups') || List([])

    const selectedProduct = fromJS(chooseProduct)
    // const selectedProduct = fromJS(chosenProduct)
    const selectedVariant = fromJS(chooseProduct.v[chooseVariantIndex])
    // const selectedVariant = fromJS(chosenVariant)

    if (!Map.isMap(selectedProduct) || !Map.isMap(selectedVariant)) {
      return
    }

    // Begin prepare action to dispatch quick add/merge
    const parent_store_id = selectedProduct.get('s') ? parseInt(selectedProduct.get('s')) : null
    let cost = selectedVariant.has('c') ? parseFloat(selectedVariant.get('c')) : null
    const product_id = selectedProduct.get('id')
    const name = selectedProduct.get('n')
    const variant = selectedVariant.get('n') // variant name
    // const qty = 1 // +1 จากของเดิม
    const available_qty = selectedVariant.get('q') || 0

    if (!available_qty || available_qty <= 0) {
      p.op.showToast('สินค้าหมดสต๊อกแล้ว กรุณาเติมสินค้าก่อนเปิดออเดอร์', 'warning')
      return
    }

    const pp_id = parseFloat(selectedVariant.get('i'))
    const thumbnail_uris = selectedProduct.get('t')
    const h = parseInt(selectedVariant.get('h')) || null

    // const price = parseFloat(selectedVariant.get('p'))
    // const price_rate_value = parseFloat(selectedVariant.get('p'))
    // const price_rate_type = 1

    // FIXME: Implement VDs
    const my_vds = selectedProduct.get('my_vds')
    const my_vds_type = selectedProduct.get('my_vds_type')
    const seller_vds = selectedProduct.get('seller_vds')
    const seller_vds_type = selectedProduct.get('seller_vds_type')

    let price = null // to be compute
    let pg_id = null // to be compute
    let ugpgSaleDiscountAmount = null // to be compute
    let ugpgSaleDiscountPercent = null // to be compute

    const productDiscount = selectedProduct.get('pdc') || Map({})
    const ugpgPurchaseDiscountAmount = productDiscount.get('ua') ? parseFloat(productDiscount.get('ua')) : null
    const ugpgPurchaseDiscountPercent = productDiscount.get('up') ? parseFloat(productDiscount.get('up')) : null

    let hasEmptyPrice = false

    // ปิดด้วยสินค้าของร้านฉันเท่านั้น !!
    let prices = selectedVariant.get('s')
    if (!prices || !List.isList(prices) || prices.size < 1) {
      // should be impossible case
      prices = List([])
    }

    switch (optionChooseProductsFromIndex) {
      case 0:
        price = selectedVariant.get('p') ? parseFloat(selectedVariant.get('p')) : null
        // log('optionChooseProductsFromIndex case 0:: price => ', price)
        break
      case 1:
        price = prices.hasIn([0, 'p']) ? parseFloat(prices.getIn([0, 'p'])) : null
        // log('optionChooseProductsFromIndex case 1:: price => ', price)
        break
      default:
        price = null
      // log('optionChooseProductsFromIndex case default:: optionChooseProductsFromIndex => ', optionChooseProductsFromIndex)
    }
    // price = prices.hasIn([0, 'p']) ? parseInt(prices.getIn([0, 'p'])) : null
    pg_id = prices.hasIn([0, 'g']) ? parseInt(prices.getIn([0, 'g'])) : null
    if (_.isNull(price) || _.isUndefined(price) || (!pg_id && optionChooseProductsFollowingIndex === 1)) {
      // p.op.alert('ไม่พบราคาสินค้าชิ้นนี้', 'กรุณาระบุราคาของสินค้าชิ้นนี้ก่อนใช้เปิดออเดอร์')
      // return
      // const isUserConfirmed = await p.op.isUserConfirm('ไม่พบราคาสินค้าชิ้นนี้', 'กรุณายืนยันว่าจะให้จะหยิบสินค้าเข้าออเดอร์แล้วกำหนดราคาไว้ ฿0 โดยคุณสามารถปรับราคาได้ภายหลัง')
      const txtProductName = variant ? `${name} ${variant}` : name
      const isUserConfirmed = await p.op.isUserConfirm(
        'ไม่พบราคาสินค้า',
        `กรุณายืนยันว่าจะให้จะหยิบสินค้า "${txtProductName}" เข้าสู่ออเดอร์แล้วกำหนดราคาไว้ ฿0 โดยคุณสามารถปรับราคาได้ภายหลัง`
      )
      if (!isUserConfirmed) {
        return
      }
      price = 0
      hasEmptyPrice = true
    }

    if (ug_id && pg_id) {
      // ถ้ามีการเลือกเปิดโดย UG มา และมี pg_id สำหรับคิดส่วนลด
      const focusedUserGroup = selectedUserGroups.find((ug) => Map.isMap(ug) && ug.get('id') === ug_id)
      if (!Map.isMap(focusedUserGroup) || !focusedUserGroup.get('id')) {
        p.op.alert('ไม่พบข้อมูลกลุ่มสมาชิก', 'กรุณาลองใหม่อีกครั้ง') // should be impossible case
        return
      }
      const discountRelations = focusedUserGroup.get('pgs') // discount relation
      const focusedDiscount = discountRelations.find((pgd) => Map.isMap(pgd) && pgd.get('id') === pg_id)
      if (Map.isMap(focusedDiscount)) {
        ugpgSaleDiscountAmount = focusedDiscount.get('discount_amount') ? parseFloat(focusedDiscount.get('discount_amount')) : null
        ugpgSaleDiscountPercent = focusedDiscount.get('discount_percent') ? parseFloat(focusedDiscount.get('discount_percent')) : null
      }
    } else if (_.isArray(pg_ids) && pg_ids.length > 0) {
      // TODO: ตอนนี้ยังไม่มีส่วนลดของ PG โดดๆ อนาคตมีก็มา compute ตรงนี้
    }
    // ถ้าไม่มี h แปลว่าสินค้าที่ดึงมา มาจากร้านขายส่ง ปรับ cost = price เพื่อไม่ให้คำนวณกำไรผิด
    if (_.isNil(h) && _.isNil(cost)) {
      cost = price
    }

    // // ตรวจสอบว่า store id ของ ฉันตรงหับ store id ที่ส่งไปหรือเปล่า
    // let newSellerStoresId = sellerStoresId
    // if (storeIdFromRequest === store_id) {
    //   newSellerStoresId = undefined
    // }

    const pulledOrderProduct = {
      variant,
      cost,
      product_id,
      name,
      qty: 1, // +1 จากของเดิม
      available_qty,
      pp_id,
      price,
      price_rate_value: hasEmptyPrice ? -1.33 : price, // default price ถ้าถูก user เปลี่ยน จะทำให้หน้าบ้านส่ง custom price ไป
      price_rate_type: 1, // default mode
      thumbnail_uris,
      h,
      parent_store_id,

      // FIXME: Maybe use in when Enable Vds in version 2.1
      my_vds,
      my_vds_type,
      seller_vds,
      seller_vds_type,

      // for create api body with pg_id
      ug_id,
      pg_id,

      // for compute real-time discount
      ugpgPurchaseDiscountAmount, // discount per each
      ugpgPurchaseDiscountPercent, // discount per each
      ugpgSaleDiscountAmount, // discount per each
      ugpgSaleDiscountPercent, // discount per each
    }

    quickAddProductToOrder({
      store_id,
      seller_store_id,
      orderProducts: [pulledOrderProduct],
      // callback,
      // callback: this._handleOnQuickAddProductToOrderWithBarcodeSuccess,
      callback: this._handleOnPullProductToOrderSuccess,

      // for order create validation
      ug_id,
      pg_ids,
    })
  }

  _renderChooseProductFrom = () => {
    const hasSellerStores = this._hasSellerStores()
    if (!hasSellerStores) {
      return null
    }
    // ผู้ช่วยร้านจะถูกเลือก 'ร้านฉัน' อัตโนมัติ และจะไม่เห็นฟีเจอร์เลือกร้าน
    // หรือถูก config ไว้ mode
    const selectMyStore = 1
    const roleId = this.props.selectedStore.get('role_id')
    // FIXME: เงื่อนไขตัวนี้วางใน render function ไม่น่าดี เพราะมันมีโอกาส change state ก่อให้เกิด infinite loop ได้
    if (roleId === 2 || APP_CONFIG.order.create_order.show_select_products_from_my_store_only_btn) {
      this._onSegmentChangeChooseProductFrom(selectMyStore)
      return
    }
    return (
      <VStack w='full' space='1'>
        <XText variant='inactive'>เลือกสินค้าจากร้าน...</XText>
        <Segment
          options={this.OPTIONS_CHOOSE_PRODUCTS_FROM}
          selectedIndex={this.state.optionChooseProductsFromIndex}
          onSegmentChange={this._onSegmentChangeChooseProductFrom}
        />
        {this._renderSellerStoreSelector()}
      </VStack>
    )
  }

  _getCurrentOrderChooseProductMode = (): 'UG' | 'PG' | 'None' => {
    const { editingOrder } = this.props
    const chooseProductParams = editingOrder.get('chooseProductParams')
    if (chooseProductParams) {
      const ug_id = chooseProductParams.get('ug_id')
      const pg_ids = chooseProductParams.get('pg_ids')
      if (ug_id && ug_id > 0) {
        return 'UG'
      }
      if (List.isList(pg_ids) && pg_ids.size > 0) {
        return 'PG'
      }
    }
    return 'None'
  }

  _renderAddMyProductBySelectedGroup = () => {
    const { selectedStore } = this.props
    const {
      mode,
      optionChooseProductsFromIndex = 0,
      optionChooseProductsFollowingIndex = 0,
      optionChooseProductsByGroupIndex = 0,
    } = this.state

    const hasSellerStores = this._hasSellerStores()
    if (optionChooseProductsFromIndex === 0 && hasSellerStores) {
      // ถ้าเลือกสินค้าจากร้านผู้ขายส่งก็ไม่ต้อง set
      return null
    }

    // log(this, '_renderAddMyProductBySelectedGroup optionChooseProductsFollowingIndex => ', optionChooseProductsFollowingIndex)
    // log(this, '_renderAddMyProductBySelectedGroup optionChooseProductsByGroupIndex => ', optionChooseProductsByGroupIndex)
    let groupItems = List([])
    const user_groups = selectedStore.get('user_groups') || List([])
    const product_groups = selectedStore.get('product_groups') || List([])

    const hasOnlyOneUg = user_groups.size === 1
    const haveOnlyOnePg = product_groups.size === 1
    if (hasOnlyOneUg && haveOnlyOnePg) {
      // ถ้ามี ug และ pg แค่อย่างละ 1 ตัว
      return null
    }
    if (hasOnlyOneUg || optionChooseProductsFollowingIndex === 1) {
      // ถ้ามี ug แค่ 1 ตัว
      groupItems = product_groups
    } else if (optionChooseProductsFollowingIndex === 0) {
      groupItems = user_groups
    } else {
      return null
    }

    const { selectedOrder } = this.props
    const orderId = selectedOrder.get('id')
    const ug_id = selectedOrder.get('user_group_id')
    const forceUseOnlyUg = _.includes([VIEW_EDIT, EDIT], mode) && orderId && ug_id
    const forceUseOnlyPg = _.includes([VIEW_EDIT, EDIT], mode) && orderId && !ug_id

    let txtChooseProductFollowing = null
    if ((_.includes([CREATE], mode) && hasOnlyOneUg) || forceUseOnlyPg) {
      txtChooseProductFollowing = 'เลือกสินค้าตามรายการราคา'
    } else if (forceUseOnlyUg) {
      txtChooseProductFollowing = 'เลือกสินค้าตามกลุ่มสมาชิก'
    } else {
      txtChooseProductFollowing = 'เลือกสินค้าตาม...'
    }

    // ถ้า config แสดงเฉพาะร้านเรา ไม่ต้องแสดง และเลือกจากรายการราคาอันดับแรกเสมอ
    if (APP_CONFIG.order.create_order.show_select_products_from_my_store_only_btn) {
      this._onSegmentChangeChooseProductFollowing(1)
      return null
    }

    return (
      <VStack w='full' mt='2'>
        <XText variant='inactive'>{txtChooseProductFollowing}</XText>
        {hasOnlyOneUg || forceUseOnlyUg || forceUseOnlyPg ? null : (
          <Segment
            options={this.OPTIONS_CHOOSE_PRODUCTS_FOLLOWING}
            selectedIndex={optionChooseProductsFollowingIndex}
            onSegmentChange={this._onSegmentChangeChooseProductFollowing}
          />
        )}
        <VStack mt='2' space='1'>
          {groupItems.map((groupItem, groupItemIndex) => {
            const isActive = groupItemIndex === optionChooseProductsByGroupIndex
            return (
              <OrderRadioGroupItem
                key={groupItemIndex.toString()}
                index={groupItemIndex}
                data={groupItem}
                active={isActive}
                onPressItem={this._onRadioChangeChooseProductsByGroup}
              />
            )
          })}
        </VStack>

        {/* { */}
        {/* groupItems.map((groupItem, groupItemIndex) => { */}
        {/* return ( */}
        {/* <HStack key={groupItemIndex}> */}
        {/* <VStack style={[INTL_STYLES.colMiddleCenter, { flex: 0, width: 24 }]}> */}
        {/* <XRadio */}
        {/* index={groupItemIndex} */}
        {/* data={groupItem} */}
        {/* // onPress={({ index }) => this.setState({ optionChooseProductsByGroupIndex: groupItemIndex })} */}
        {/* onPressAtIndex={this._onRadioChangeChooseProductsByGroup} */}
        {/* checked={groupItemIndex === optionChooseProductsByGroupIndex} /> */}
        {/* </VStack> */}
        {/* <VStack style={INTL_STYLES.colMiddleLeft}> */}
        {/* <XText style={INTL_STYLES.txtActive}> */}
        {/* { groupItem.get('name') } */}
        {/* </XText> */}
        {/* </VStack> */}
        {/* </HStack> */}
        {/* ) */}
        {/* }) */}
        {/* } */}
      </VStack>
    )
  }

  _renderSellerStoreSelector = () => {
    const { isVisibleSellerStoreSelector, optionChooseProductsFromIndex, optionSelectedSellerStoreIndex } = this.state
    const { sellerStores } = this.props
    if (optionChooseProductsFromIndex !== 0 || !List.isList(sellerStores) || sellerStores.size === 0) {
      return null
    }
    const coverImgUri = sellerStores.getIn([optionSelectedSellerStoreIndex, 'cover_thumbnail_uri'])
    const storeName = sellerStores.getIn([optionSelectedSellerStoreIndex, 'name'])
    const storeDesc = sellerStores.getIn([optionSelectedSellerStoreIndex, 'description'])
    // const handlePress = () => this.setState({ isVisibleSellerStoreSelector: true })
    const onPickSellerStore = (newItem) => this._handleChangeSellerStore(newItem.index)
    if (!storeName) {
      // should be impossible
      return null
    }

    const renderSelectedValue = ({ item, index }) =>
      this._renderSellerStoreSelectorItem({ coverImgUri, storeName, storeDesc, isSelector: true })
    //  (
    // <View style={{ borderStyle: 'dotted', borderWidth: 0.5, borderColor: TEXT_INACTIVE }}>
    //  {this._renderSellerStoreSelectorItem({ coverImgUri, storeName, storeDesc, isSelector: true })}
    // </View>
    // )

    return (
      <XFlatPicker
        key='Add_Product_From_Store'
        title='เพิ่มสินค้าจากร้าน...'
        selectedIndex={optionSelectedSellerStoreIndex}
        options={sellerStores.toArray()}
        onPick={onPickSellerStore}
        // renderSelectedValue={this._renderDetailSaleChannelCardButton}
        renderSelectedValue={renderSelectedValue}
        renderItem={this._renderSellerStoreSelectorListItem}
      />
    )

    // return (
    //   <View>
    //     {/* <View>
    //       <XText style={INTL_STYLES.txtLabel} />
    //     </View> */}
    //     {/* <View style={{ borderStyle: 'dotted', borderWidth: 0.5, borderColor: TEXT_INACTIVE }}>
    //       {this._renderSellerStoreSelectorItem({ handlePress, coverImgUri, storeName, storeDesc, isSelector: true })}
    //     </View> */}
    //     {/* <XModal
    //       title={'เพิ่มสินค้าจากร้าน...'}
    //       onRequestClose={this._onRequestCloseSellerStoreSelector}
    //       visible={isVisibleSellerStoreSelector}
    //       hasBackButton={true}>
    //       <View>
    //         <FlatList data={sellerStores.toArray()} renderItem={this._renderSellerStoreSelectorListItem} />
    //       </View>
    //     </XModal> */}
    //     <XFlatPicker
    //       key='Add_Product_From_Store'
    //       title='เพิ่มสินค้าจากร้าน...'
    //       selectedIndex={optionSelectedSellerStoreIndex}
    //       options={sellerStores.toArray()}
    //       onPick={onPickSellerStore}
    //       // renderSelectedValue={this._renderDetailSaleChannelCardButton}
    //       renderSelectedValue={renderSelectedValue}
    //       renderItem={this._renderSellerStoreSelectorListItem}
    //     />
    //   </View>
    // )
  }

  _renderButtonCameraUPCAndSKU = (isStoreUseSKU, isStoreUseUPC) => {
    const { optionSelectedSellerStoreIndex, optionChooseProductsFromIndex } = this.state
    const { sellerStores } = this.props
    const storeName = sellerStores.getIn([optionSelectedSellerStoreIndex, 'name'])
    return (
      <HStack alignItems='center' space='2' mt='0.5'>
        {/* <XButtonWithIcon
          h='full'
          flex={1}
          flexWrap='wrap'
          _text={{ numberOfLines: 1 }}
          rightIcon={<XIcon inactive name='search' />}
          onPress={this._navToSearchableProductListView}>
          {optionChooseProductsFromIndex === 0 && !APP_CONFIG.order.create_order.show_select_products_from_my_store_only_btn
            ? `เพิ่มสินค้าจากร้าน ${storeName}`
            : 'เพิ่มสินค้า'}
        </XButtonWithIcon> */}
        {/* <XButtonWithIcon
          h='full'
          flex={1}
          flexWrap='wrap'
          _text={{ numberOfLines: 1 }}
          rightIcon={<ForwardIcon />}
          onPress={this.handlePrepareToAddProductsToCart}>
          {optionChooseProductsFromIndex === 0 && !APP_CONFIG.order.create_order.show_select_products_from_my_store_only_btn
            ? `เพิ่มสินค้าจากร้าน ${storeName}`
            : 'เพิ่มสินค้า'}
        </XButtonWithIcon> */}
        <XButtonWithIcon
          h='full'
          flex={1}
          flexWrap='wrap'
          _text={{ numberOfLines: 1 }}
          leftIcon={<XIcon name='cart-sharp' family='Ionicons' />}
          onPress={this.handlePrepareToAddProductsToCart}>
          เลือก
        </XButtonWithIcon>

        <XButtonWithIcon
          h='full'
          flex={1}
          flexWrap='wrap'
          _text={{ numberOfLines: 1 }}
          leftIcon={<XIcon name='search' family='Ionicons' />}
          onPress={this._navToSearchableProductListView}>
          ค้นหา
        </XButtonWithIcon>

        {/* <XIconButton h='11' w='11' name='md-cart-sharp' family='Ionicons' variant='solid' onPress={this.handlePrepareToAddProductsToCart} /> */}

        {/* <XIconButton h='11' w='11' name='search' family='Ionicons' variant='solid' onPress={this._navToSearchableProductListView} /> */}
        {/* <XButton
          _text={{ fontSize: 'xs' }}
          w='11'
          h='11'
          flexDir='column'
          // flexDirection='column'
          alignContent='center'
          justifyContent='center'
          startIcon={<XIcon name='search' family='Ionicons' variant='activeDark' w='6' />}
          onPress={this._navToSearchableProductListView}>
          ค้นหา
        </XButton> */}

        {/* <XBlankButton
          h='full'
          flex={1}
          flexWrap='wrap'
          // rightIcon={<ForwardIcon variant='activeDark' />}
          onPress={this.handlePrepareToAddProductsToCart}>
          <HStack flex={1} space='1' alignItems='center' justifyContent='space-between'>
            <HStack flex={1} px='1' alignItems='center' justifyContent='center'>
              <XText numberOfLines={1} variant='activeDark'>
                {optionChooseProductsFromIndex === 0 && !APP_CONFIG.order.create_order.show_select_products_from_my_store_only_btn
                  ? `เพิ่มสินค้าจากร้าน ${storeName}`
                  : 'เพิ่มสินค้า'}
              </XText>
            </HStack>
            <ForwardIcon variant='activeDark' />
          </HStack>
        </XBlankButton> */}
        {/* {isStoreUseUPC && isStoreUseSKU ? <VStack style={{ flex: 0, width: 10 }} /> : null} */}
        {isStoreUseSKU && APP_CONFIG.order.create_order.show_add_product_by_sku_btn ? (
          <BarcodeScannerModalButton
            saveStateKey={xCONS.STATE_SAVE_KEYS.BARCODE_SCANNER_MODAL}
            label='SKU'
            headerTitle='สแกนบาร์โค้ดสินค้า (SKU)'
            onBarcodeScanned={this._handleBarcodeScanedSKU}
          />
        ) : null}
        {APP_CONFIG.order.create_order.show_add_product_by_upc_btn ? (
          <BarcodeScannerModalButton
            saveStateKey={xCONS.STATE_SAVE_KEYS.BARCODE_SCANNER_MODAL}
            label='UPC'
            headerTitle='สแกนบาร์โค้ดสินค้า (UPC)'
            onBarcodeScanned={this._handleBarcodeScanedUPC}
          />
        ) : null}
      </HStack>
    )
  }

  _renderAddProductButton = () => {
    const { selectedStore, sellerStores } = this.props
    const { optionChooseProductsFromIndex, optionSelectedSellerStoreIndex } = this.state
    let isStoreUseUPC = selectedStore.get(COMPUTED_USE_PRODUCT_UPC) || false
    let isStoreUseSKU = selectedStore.get(COMPUTED_USE_PRODUCT_SKU) || false
    const isMyStoreChoose = optionChooseProductsFromIndex === 1
    let isAvailableBarcodeSearch = (isStoreUseUPC || isStoreUseSKU) && isMyStoreChoose
    if (!isMyStoreChoose) {
      isStoreUseUPC = true
      isStoreUseSKU = false
      isAvailableBarcodeSearch = true
    }
    return this._renderButtonCameraUPCAndSKU(isStoreUseSKU, isStoreUseUPC)
    // const storeName = sellerStores.getIn([optionSelectedSellerStoreIndex, 'name'])
    // return (
    //   <Stack style={s.webFlexBasis}>
    //     {/* <XButton onPress={() => this._handleOrderBarcodeScaned({ data: 'T112233', isUPC: true })} style={{ width: 30, height: 30 }} /> */}
    //     {isAvailableBarcodeSearch ? (
    //       this._renderButtonCameraUPCAndSKU(isStoreUseSKU, isStoreUseUPC)
    //     ) : (
    //       <HStack style={[{ marginTop: 4, marginBottom: 4 }, s.webFlexBasis]}>
    //         <VStack size={100} style={s.webFlexBasis}>
    //           <TouchableOpacity
    //             style={INTL_STYLES.btnPrimaryActive}
    //             // onPress={this.onAddProducts}>
    //             onPress={this.handlePrepareToAddProductsToCart}>
    //             <HStack style={{ flexBasis: 'auto', width: '100%' }}>
    //               <VStack style={[INTL_STYLES.colMiddleCenter, { flex: p.op.isWeb() ? 1 : 0, width: 28 }]} />
    //               <VStack size={100} style={[INTL_STYLES.colMiddleCenter, { width: '100%' }]}>
    //                 {optionChooseProductsFromIndex === 0 && !APP_CONFIG.order.create_order.show_select_products_from_my_store_only_btn ? (
    //                   <XText style={INTL_STYLES.txtBtnPrimaryActive} numberOfLines={1}>
    //                     {`เพิ่มสินค้าจากร้าน ${storeName}`}
    //                   </XText>
    //                 ) : (
    //                   <XText style={INTL_STYLES.txtBtnPrimaryActive}>เพิ่มสินค้า</XText>
    //                 )}
    //               </VStack>
    //               <VStack
    //                 style={[INTL_STYLES.colMiddleCenter, { flex: p.op.isWeb() ? 1 : 0, width: 28, height: '100%', flexDirection: 'row' }]}>
    //                 <ForwardIcon activeDark />
    //               </VStack>
    //             </HStack>
    //           </TouchableOpacity>
    //         </VStack>
    //       </HStack>
    //     )}
    //   </Stack>
    // )
  }

  onAddStoreProductSelectorSearchProductButtonPressed = async (
    predefinedNavParams: IAddStoreProductSelectorPredefinedNavParams,
    selectedOptions: IAddStoreProductSelectorOptions
  ) => {
    if (p.op.isWeb()) {
      await this.popToCurrentView()
    }

    const { navigation } = this.props

    const navParams: ISearchableProductListViewNavParams = {
      ...predefinedNavParams,
      onProductItemPressed: this.onPressSearchableProductListItem,
    }

    // @ts-ignore
    navigation.dispatch(NavActions.navToSearchableProductListView(navParams))
  }

  _navToSearchableProductListView = async () => {
    // if (this.inProcess) {
    //   return
    // }
    // this.inProcess = true
    const { navigation, selectedStore, sellerStores } = this.props
    if (!navigation) {
      return null
    }

    if (!navigation || !_.isFunction(navigation.dispatch) || !Map.isMap(selectedStore)) {
      return
    }

    if (p.op.isWeb()) {
      await this.popToCurrentView()
    }

    const {
      store_id,
      optionSelectedSellerStoreIndex = 0,
      optionChooseProductsFromIndex = 0, // seller or my
      optionChooseProductsFollowingIndex = 0, // ug or pg
      optionChooseProductsByGroupIndex = 0, // which group ?
    } = this.state
    if (!store_id) {
      p.op.alert('ไม่พบข้อมูลร้านค้า', 'กรุณากล้าไปเลือกร้านค้าใหม่อีกครั้ง')
      return
    }

    const hasSellerStores = this._hasSellerStores()
    let isMineProducts = optionChooseProductsFromIndex === 1
    if (!isMineProducts && !hasSellerStores) {
      isMineProducts = true
    }

    const additionParams = this._getProductFilterParams()

    const navParams: ISearchableProductListViewNavParams = {
      store_id,
      onProductItemPressed: this.onPressSearchableProductListItem,
      ...additionParams,
    }

    if (!isMineProducts) {
      navParams.seller_store_id = sellerStores.getIn([optionSelectedSellerStoreIndex, 'id'])
    }

    // @ts-ignore
    this.props.navigation.dispatch(NavActions.navToSearchableProductListView(navParams))
  }

  onPressSearchableProductListItem = (item: ISearchProductItem) => {
    const { sellerStores } = this.props
    const { optionChooseProductsFromIndex = 0, optionSelectedSellerStoreIndex } = this.state

    try {
      const aItem = _.cloneDeep(item)
      // @ts-ignore
      aItem.v[0].q = 9999999

      // @ts-ignore
      if (_.isNil(aItem.v[0].p)) {
        // @ts-ignore
        aItem.v[0].p = aItem.v[0].s[0].p
      }

      // this._handlePullProductToOrder(aItem)

      // const seller_store_id = util.getNavParam(this.props, 'seller_store_id')
      // const product_group_ids = util.getNavParam(this.props, 'pg_ids')
      // const user_group_id = util.getNavParam(this.props, 'ug_id')
      const { pg_ids: product_group_ids, ug_id: user_group_id } = this._getProductFilterParams()

      const hasSellerStores = this._hasSellerStores()
      let isMineProducts = optionChooseProductsFromIndex === 1
      if (!isMineProducts && !hasSellerStores) {
        isMineProducts = true
      }

      let seller_store_id

      if (!isMineProducts) {
        seller_store_id = sellerStores.getIn([optionSelectedSellerStoreIndex, 'id'])
      }

      this._handlePullProductToOrder({
        // pp_id: aItem.v[0].i,
        // @ts-ignore
        product: aItem,
        qty: 1,
        seller_store_id,
        product_group_ids,
        user_group_id,
      })

      // console.log('onPressListItem item => ', item)

      // console.log('onPressListItem res => ', res)
    } catch (err) {
      console.log('onPressListItem err => ', err)
    }
  }

  onAddStoreProductSelectorChooseProductButtonPressed = async (
    predefinedNavParams: IAddStoreProductSelectorPredefinedNavParams,
    selectedOptions: IAddStoreProductSelectorOptions
  ) => {
    if (p.op.isWeb()) {
      await this.popToCurrentView()
    }

    this.navToAddProductListView(predefinedNavParams, _.isNil(predefinedNavParams.seller_store_id))
  }

  handlePrepareToAddProductsToCart = async () => {
    // if (this.inProcess) {
    //   return
    // }
    // this.inProcess = true
    const { navigation, selectedStore, sellerStores } = this.props
    if (!navigation) {
      return null
    }

    if (!navigation || !_.isFunction(navigation.dispatch) || !Map.isMap(selectedStore)) {
      return
    }

    if (p.op.isWeb()) {
      await this.popToCurrentView()
    }

    const {
      store_id,
      optionSelectedSellerStoreIndex = 0,
      optionChooseProductsFromIndex = 0, // seller or my
      optionChooseProductsFollowingIndex = 0, // ug or pg
      optionChooseProductsByGroupIndex = 0, // which group ?
    } = this.state
    if (!store_id) {
      p.op.alert('ไม่พบข้อมูลร้านค้า', 'กรุณากล้าไปเลือกร้านค้าใหม่อีกครั้ง')
      return
    }

    const hasSellerStores = this._hasSellerStores()
    let isMineProducts = optionChooseProductsFromIndex === 1
    if (!isMineProducts && !hasSellerStores) {
      isMineProducts = true
    }

    const additionParams = this._getProductFilterParams()

    // const generateNavAction = isMineProducts
    //   ? NavActions.navToProductListPullMyProductToOrder
    //   : // : NavActions.navToProductListPulSellerProductToOrder
    //     NavActions.navToProductListPullSellerProductToOrder
    let navParams: {
      store_id: number
      ug_id?: number
      pg_ids?: number[]
      seller_store_id?: number
      // order_use_retail_price?: boolean // deprecated ?
    }

    navParams = { store_id, ...additionParams }
    if (!isMineProducts) {
      navParams.seller_store_id = sellerStores.getIn([optionSelectedSellerStoreIndex, 'id'])
    }
    // // @ts-ignore navToProductListPullMyProductToOrder or navToProductListPullSellerProductToOrder why error ????
    // const navAction = generateNavAction(navParams)
    // dispatch(navAction)

    // setTimeout(() => {
    //   this.inProcess = false
    // }, 500)

    this.navToAddProductListView(navParams, isMineProducts)
  }

  navToAddProductListView = (navParams, isMineProducts) => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true

    const generateNavAction = isMineProducts
      ? NavActions.navToProductListViewPullMyProductsToOrder
      : NavActions.navToProductListViewPullSellersProductsToOrder
    const navAction = generateNavAction(navParams)
    this.props.navigation.dispatch(navAction)

    setTimeout(() => {
      this.inProcess = false
    }, 500)
  }

  _renderOrderMarketplaceButton = (order: IOrderMap) => {
    // FIXME: O: HIDE MKP ORDER Feature for now
    // return null
    // console.log('order => ', order.toJS())
    const isMkpOrder = xUtil.isMarketPlacesOrder(order)
    if (!isMkpOrder) {
      return null
    }
    const mkpChId = order.has('mkp_ch_id') ? order.get('mkp_ch_id') : null
    if (_.isNil(mkpChId)) {
      return null
    }

    const { selectedStore } = this.props
    const mkpChannels = selectedStore.get('channels') || List([])
    const mkpChannelName = xUtil.getMarketplceChannelNameById(mkpChannels, mkpChId) || '-'
    const mkpChannel = _.find(mkpChannels.toJS(), (ch: IMKPChannelDetail) => mkpChId === ch.id)
    const shippingId = order.get('shipping_type_id')
    const mkp_order_id = order.has('mkp_order_id') ? order.get('mkp_order_id') : ''
    // console.log('mkpChannel => ', mkpChannel)
    // console.log('mkpChId => ', mkpChId)
    return (
      <HStack w='full' py='1' px='1' alignItems='center' bgColor={COLORS.BLUE_TAB}>
        {/* <FastImage source={require('../../images/icon_shopee_round.png')} style={{ width: 25, height: 25 }} /> */}
        {_.isNil(mkpChannel) ? null : <MkpLogo mkpId={mkpChannel.mkp_id} height={25} width={25} />}
        <XText ml='2' flex={1} numberOfLines={1} bold>
          {mkpChannelName}
        </XText>
        {/* <OverlayEditTimeSlot
          renderButton={(onPressOpenOverlay: () => void) => this._renderIconTimer(onPressOpenOverlay)}
          channel={mkpChannel}
          shippingTypeId={7}
          // key={mkpChannel.id}
          isSingleMkpChannel={true}
          // setPrefTimeslot={setPrefTimeslot}
          // selectedStore={selectedStore}
          onSubmit={(data: IOnSubmitOverlayEditTime) => () => p.op.showConfirmationOkOnly('', 'SUNMIT')}
          headertitle={'TEST HEADER TEXT'}
          footerText={'ยืนยัน'}
        /> */}
        <XButton
          variant='outline'
          w='140px'
          // maxW='42'
          // minW='40'
          // _light={{ bg: NB.C.L.BG.CLEAR }}
          // _dark={{ bg: NB.C.D.BG.CLEAR }}
          // leftIcon={<XIcon name='md-document-text-outline' family='Ionicons' />}
          onPress={() => {
            this.props.navigation.navigate('MarketplaceOrderView', {
              store_id: selectedStore.get('id'),
              order_id: order.get('id'),
              order_type: order.get('type'),
            })
          }}>
          <HStack w='full' alignItems='center' justifyContent='center'>
            {/* <XIcon name='md-document-text-outline' family='Ionicons' /> */}
            <XText numberOfLines={1} color={COLORS.APP_MAIN}>
              {mkp_order_id}
            </XText>
            {/* <ForwardIcon /> */}
          </HStack>
        </XButton>
      </HStack>
    )
  }

  _renderOrderErpButton = (order: IOrderMap) => {
    const { selectedStore } = this.props
    // console.log('order => ', order.toJS())
    const erpDocs = order.has('erp_docs') ? order.get('erp_docs').toJS() : null
    const erpChannels = selectedStore.has('erp_channels') ? selectedStore.get('erp_channels').toJS() : null
    const orderType = order.get('type')
    // console.log('orderType => ', orderType)
    if (_.isNil(erpChannels) || orderType === 3) {
      return null
    }
    const erpDocsText = xUtil.getErpCodeLabel(erpDocs)
    return (
      <VStack>
        {erpChannels.map((erpChannel: IERPChannel) => {
          const erpId = erpChannel.id
          const erpName = xUtil.getErpChannelName(erpId)
          // const erpDocsText = this._getErpCode(erpDocs)

          const BG_COLOR = _.isNil(erpDocs) ? COLORS.WHITE : COLORS.BLUE_TAB
          const BTN_ERP = !_.isNil(erpDocs) ? (
            <XButton
              variant='outline'
              w='140px'
              onPress={() => {
                this.props.navigation.dispatch(
                  NavActions.navToERPOrderView({
                    store_id: selectedStore.get('id'),
                    order: order.toJS(),
                    erp_ch_id: erpId,
                    callBackFromErpOrderDetailUpdate: () => this._fetchOrderDetail(),
                  })
                )
              }}>
              <HStack w='full' alignItems='center' justifyContent='center'>
                <XText numberOfLines={1} color={COLORS.APP_MAIN}>
                  ดูรายละเอียด
                </XText>
              </HStack>
            </XButton>
          ) : (
            <XButton
              variant='outline'
              w='140px'
              onPress={() => {
                this.props.navigation.dispatch(
                  NavActions.navToERPOrderView({
                    store_id: selectedStore.get('id'),
                    order: order.toJS(),
                    erp_ch_id: erpId,
                    callBackFromErpOrderDetailUpdate: () => this._fetchOrderDetail(),
                  })
                )
              }}>
              <HStack w='full' alignItems='center' justifyContent='center'>
                <XIcon family='Feather' name='upload' />
                <XText numberOfLines={1} color={COLORS.APP_MAIN}>
                  สร้างเอกสาร
                </XText>
              </HStack>
            </XButton>
          )
          return (
            <VStack bgColor={BG_COLOR} p='1' key={`${erpChannel.name}`}>
              <HStack w='full' alignItems='center'>
                <ErpLogo erpSystemId={erpChannel.erp_system_id} height={25} width={25} />
                <XText ml='2' flex={1} numberOfLines={1} bold>
                  {erpName}
                </XText>
                {BTN_ERP}
              </HStack>
              <HStack flex={1} alignItems='center' justifyContent='flex-end'>
                <XText>{erpDocsText}</XText>
              </HStack>
            </VStack>
          )
        })}
      </VStack>
    )
  }

  _renderIconTimer = (onPressOpenOverlay: () => void) => (
    <TouchableOpacity
      onPress={() => onPressOpenOverlay()}
      style={[
        {
          marginRight: 4,
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'row',
          borderRadius: 7,
          borderColor: COLORS.APP_MAIN,
          backgroundColor: COLORS.BG_LIGHT_GREY,
        },
        STYLES.BTN_ICON_STYLE,
      ]}>
      <XIcon name='calendar-edit' family='MaterialCommunityIcons' style={{ flex: 0, minWidth: 25 }} />
    </TouchableOpacity>
  )

  _renderOrderDetailCard = (order) => {
    const { selectedStore } = this.props
    const { mode, customOrderView } = this.state

    const shipBeforePay = _.has(customOrderView, 'ship_before_pay') ? customOrderView.ship_before_pay.v : 1
    const receiverVisibel = _.has(customOrderView, 'receiver') ? customOrderView.receiver.v : 1
    const billingAddressVisible = _.has(customOrderView, 'billing_address') ? customOrderView.receiver.v : 1
    const shippingVisibel = _.has(customOrderView, 'shipping') ? customOrderView.shipping.v : 1
    const linkOrderVisibel = _.has(customOrderView, 'link_order') ? customOrderView.link_order.v : 1
    const billOnlineVisibel = _.has(customOrderView, 'show_price_and_transaction_cost')
      ? customOrderView.show_price_and_transaction_cost.v
      : 1
    // const seleChannel = _.has(customOrderView, 'sales_channel') ? customOrderView.sales_channel.v : 1
    const senderVisibel = _.has(customOrderView, 'sender') ? customOrderView.sender.v : 1

    // const { pp_shippingTypeIndex } = this.props
    const order_id = order.get('id') || null
    const type = parseInt(order.get('type') || 0)
    const state = parseInt(order.get('state') || 0)
    const payment_state = parseInt(order.get('payment_state') || 0)
    const shipping_state = parseInt(order.get('shipping_state') || 0)
    // const isCancelState = xUtil.isOrderInCancelledState(state)
    const isCancelState = this._isOrderCancelled(order)
    const orderStateText = this.getOrderStatusText({ type, state, payment_state, shipping_state })
    const displayMyTask = this.getDisplayMyTaskByOrderType(type)
    const orderTypeText = this.getOrderTypeText(type)
    let paymentStateTextForCancel = null
    const orderParentId = order.get('parent_id')
    const orderParentName = order.get('parent_name')
    const readyToShipDate = order.get('ready_to_ship_date')
    const printedAt = order.get('printed_at')
    if (isCancelState) {
      const paymentDisplayStateKey = this.calculatePaymentDisplayState({ type, payment_state })
      if (paymentDisplayStateKey !== '_0000') {
        paymentStateTextForCancel = `(${p.op.t(`Order.OrderStatesText.${paymentDisplayStateKey}`)})`
      }
    }

    // const lblOrderContext = type === 3 ? 'ซื้อ' : 'ขาย'
    // const lblOrderName = order.get('name') ? `${lblOrderContext}#${order.get('name')}` : 'ไม่พบชื่อออเดอร์'
    const lblOrderName = order.get('name') ? `#${order.get('name')}` : 'ไม่พบชื่อออเดอร์'

    const isLockedOrder = order.get('prevent_mod') || false

    let txtIsLockedOrderFrom = null
    if (isLockedOrder) {
      const txtIsLockBy = type === 3 ? 'ผู้ขายส่ง' : 'ฉัน'
      txtIsLockedOrderFrom = `ออเดอร์นี้ถูกล็อกสินค้าไว้โดย ${txtIsLockBy}\nงดแก้ไขรายการและจำนวนสินค้า หรือยกเลิกออเดอร์`
    }

    // สำหรับ COD & จัดส่งก่อนชำะ
    const shipping_type_id = order.get('shipping_type_id') || -1
    const isCod = order.get('is_cod') || false
    // let getShippingTypeId = pp_shippingTypeIndex
    // let getShippingTypeId = shipping_type_id // order.get('shipping_type_id') || -1
    // if (mode !== CREATE) {
    //   getShippingTypeId = shipping_type_id
    // }
    let showShipBeforePayCod = false
    const shippingTypeItem = this._getShippingTypeItem(shipping_type_id)

    if (!_.isNil(shippingTypeItem)) {
      if (_.includes(xCONS.COD_SHIPPING_TYPE_IDS, shippingTypeItem.value) || isCod) {
        showShipBeforePayCod = true
      }
    }
    // const focusedOrder = this._getFocusedOrder()
    // const showSwitchDeliveryBeforePayment = focusedOrder.get('ship_before_pay')

    // NOTES ORDER
    const note3 = _.has(customOrderView, 'notes') ? customOrderView.notes.n_03.v : 1
    const note4 = _.has(customOrderView, 'notes') ? customOrderView.notes.n_04.v : 1
    const notesOrder = order.has('notes') ? order.get('notes') : null
    let privateNote = false
    let publicNote = false
    if (!_.isNil(notesOrder)) {
      notesOrder.map((n) => {
        if (n.get('type') === 3) {
          privateNote = true
        } else if (n.get('type') === 4) {
          publicNote = true
        }
      })
    }
    const x = order.has('x') ? order.get('x') : null
    const orderState = order.has('state') ? order.get('state') : null
    return (
      <VStack w='full' space='1'>
        <XCard w='full'>
          <XCard.Body>
            <VStack pb='1' space='1'>
              {/* <HStack justifyContent='flex-end'>
                <XText variant='inactive' fontSize='xs'>
                  {lblOrderName}
                </XText>
              </HStack> */}
              {this._renderOrderMarketplaceButton(order)}
              {this._renderOrderErpButton(order)}
              {isLockedOrder && txtIsLockedOrderFrom ? (
                <View
                  style={{
                    flex: 1,
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: FORM_SUCCESS_BG,
                    flexBasis: 'auto',
                  }}>
                  <View style={{ width: 40, alignItems: 'center' }}>
                    <Icon type='MaterialIcons' name='lock' style={{ color: FORM_SUCCESS }} />
                  </View>
                  <View style={{ flex: 1 }}>
                    <XText style={[TXT_LABEL_SMALLER, { textAlign: 'center' }]}>{txtIsLockedOrderFrom}</XText>
                  </View>
                </View>
              ) : null}
              {/* {this._renderOrderMarketplaceButton(order)} */}
              <HStack alignItems='center' justifyContent='space-between'>
                <XText variant='inactive'>{p.op.t('Order.status')}</XText>
                <XText
                  variant='primary'
                  bold
                  fontSize='md'
                  style={{ color: this._getStatusTextColor(state, payment_state, shipping_state, isCancelState) }}>
                  {`${orderStateText}`}
                </XText>
              </HStack>
              {paymentStateTextForCancel ? (
                <HStack alignItems='center' justifyContent='flex-end'>
                  <XText
                    variant='primary'
                    fontSize='md'
                    style={{ color: this._getStatusTextColor(state, payment_state, shipping_state, isCancelState) }}>
                    {paymentStateTextForCancel}
                  </XText>
                </HStack>
              ) : null}
              <OrderStatesTable selectedStore={selectedStore} order={order} />
              {this._renderAvailableContacts(order)}
            </VStack>
          </XCard.Body>
        </XCard>
        {/* //--// */}
        <XCard w='full'>
          <XCard.Body>
            <VStack w='full' space='1'>
              <HStack py='2' alignItems='center' justifyContent='center'>
                <XText fontSize='md' bold>
                  ข้อมูลทั่วไป
                </XText>
              </HStack>
              <HStack alignItems='center' justifyContent='space-between'>
                <XText variant='inactive'>{`${orderTypeText} (${displayMyTask})`}</XText>
                <TouchableOpacity
                  onPress={() => {
                    Clipboard.setString(order.get('name'))
                    p.op.showToast(`คัดลอก ${order.get('name')} สำเร็จ`, 'success')
                  }}>
                  <XText variant='active' bold>
                    {lblOrderName}
                  </XText>
                </TouchableOpacity>
              </HStack>
              {order.has('mkp_order_id') && !_.isNil(order.get('mkp_order_id')) ? (
                <HStack alignItems='center' justifyContent='space-between'>
                  <XText variant='inactive'>ออเดอร์จากช่องทางขาย</XText>
                  <TouchableOpacity
                    onPress={() => {
                      Clipboard.setString(order.get('mkp_order_id'))
                      p.op.showToast(`คัดลอก ${order.get('mkp_order_id')} สำเร็จ`, 'success')
                    }}>
                    <XText variant='active' bold>
                      {order.get('mkp_order_id')}
                    </XText>
                  </TouchableOpacity>
                </HStack>
              ) : null}
              {/* <HStack alignItems='center' justifyContent='space-between'>
                <XText variant='inactive'>ออเดอร์</XText>
                <XText bold>{lblOrderName}</XText>
              </HStack> */}
              {/* <HStack alignItems='center' justifyContent='space-between'>
                <XText variant='inactive'>ประเภท</XText>
                <XText>
                  {orderTypeText} ({displayMyTask})
                </XText>
              </HStack> */}
              {type === 3 && orderParentName ? (
                <HStack alignItems='center' justifyContent='space-between'>
                  <XText variant='inactive'>เพื่อออเดอร์ขาย</XText>
                  <XButton
                    variant='outline'
                    // bgColor={mode !== EDIT ? COLORS.APP_MAIN : COLORS.FORM_INACTIVE}
                    // style={{
                    //   paddingTop: 0,
                    //   paddingBottom: 0,
                    //   height: 22,
                    //   borderWidth: 1,
                    //   borderRadius: 7,
                    //   marginRight: p.op.isWeb() ? 4 : undefined,
                    // }}
                    // onPress={() => this._handleOnPressParentOrderId(orderParentId)}>
                    onPress={this._handleOnPressParentOrderId}>
                    {`ขาย#${orderParentName}`}
                  </XButton>
                </HStack>
              ) : null}
              {/* {this._renderStateBoxes(order)} */}
              <HStack alignItems='center' justifyContent='space-between'>
                <XText variant='inactive'>{p.op.t('Order.createdAt')}</XText>
                <XText>{xUtil.formatDateForDisplay(order.get('created_at'))}</XText>
              </HStack>
              {order.has('cancelled_at') && !_.isNil(order.get('cancelled_at')) ? (
                <HStack alignItems='center' justifyContent='space-between'>
                  <XText variant='danger'>{p.op.t('Order.cancelledAt')}</XText>
                  <XText variant='danger'>{xUtil.formatDateForDisplay(order.get('cancelled_at'))}</XText>
                </HStack>
              ) : null}
              {order.has('completed_at') && !_.isNil(order.get('completed_at')) ? (
                <HStack alignItems='center' justifyContent='space-between'>
                  <XText variant='inactive'>{p.op.t('Order.completedAt')}</XText>
                  <XText>{xUtil.formatDateForDisplay(order.get('completed_at'))}</XText>
                </HStack>
              ) : null}
              {order.has('payment_completed_at') && !_.isNil(order.get('payment_completed_at')) ? (
                <HStack alignItems='center' justifyContent='space-between'>
                  <XText variant='inactive'>{p.op.t('Order.paymentCompletedAt')}</XText>
                  <XText>{xUtil.formatDateForDisplay(order.get('payment_completed_at'))}</XText>
                </HStack>
              ) : null}
              {order.has('shipping_completed_at') && !_.isNil(order.get('shipping_completed_at')) ? (
                <HStack alignItems='center' justifyContent='space-between'>
                  <XText variant='inactive'>{p.op.t('Order.shippingCompletedAt')}</XText>
                  <XText>{xUtil.formatDateForDisplay(order.get('shipping_completed_at'))}</XText>
                </HStack>
              ) : null}
              {readyToShipDate ? (
                <HStack alignItems='center' justifyContent='space-between'>
                  <XText variant='inactive'>{p.op.t('Order.readyToShipAt')}</XText>
                  <XText>{xUtil.formatDateForDisplay(readyToShipDate)}</XText>
                </HStack>
              ) : null}
              {printedAt ? (
                <HStack alignItems='center' justifyContent='space-between'>
                  <XText variant='inactive'>{p.op.t('Order.printedAt')}</XText>
                  <XText>{xUtil.formatDateForDisplay(printedAt)}</XText>
                </HStack>
              ) : null}

              {/* {linkOrderVisibel === 0 && billOnlineVisibel === 0 ? null : ( */}
              {/* <View
                style={{
                  borderBottomColor: '#E1E1E1',
                  borderBottomWidth: 1,
                  borderTopColor: '#E1E1E1',
                  borderTopWidth: 1,
                  paddingBottom: 4,
                  paddingTop: 4,
                }}> */}

              {/* </View> */}
              {/* )} */}
            </VStack>
          </XCard.Body>
          <XCard.Footer>
            <VStack w='full' space='1'>
              {linkOrderVisibel === 0 ? null : this._renderCopyOrderLinkWithDetail(order)}
              {linkOrderVisibel === 0 ? null : this._renderCopyLinkShareOrder()}
              {billOnlineVisibel === 0 ? null : this._renderBillOnlineShowAmoint()}
            </VStack>
          </XCard.Footer>
          <XCard.Footer>
            <VStack w='full' space='1'>
              {/* บันทึกช่วยจำ */}
              {note3 === 0 && !privateNote ? null : this._renderPrivateNote()}
              {note4 === 0 && !publicNote ? null : this._renderPublicNote()}
            </VStack>
          </XCard.Footer>
        </XCard>
        {/* //--// */}
        <XCard w='full'>
          <XCard.Body>
            <VStack w='full' space='1'>
              <HStack py='2' alignItems='center' justifyContent='center'>
                <XText fontSize='md' bold>
                  การชำระ
                </XText>
              </HStack>

              {this._renderPaymentInfoButton(order)}
              {this._renderPayments()}
            </VStack>
          </XCard.Body>
        </XCard>
        {/* //--// */}
        <XCard w='full'>
          <XCard.Body>
            <VStack w='full' space='1'>
              <HStack py='2' alignItems='center' justifyContent='center'>
                <XText fontSize='md' bold>
                  {p.op.t('Order.shipmentInfo')}
                </XText>
              </HStack>

              {this._renderMKPOrderPickUp()}
              {this._renderShippingInfoButton({ order, type, isCancelState })}

              {/* เลขติดตามพัสดุ */}
              {_.isNil(x) || x.size === 0 ? null : (
                <View>
                  {/* <Box style={{ paddingBottom: 0, paddingLeft: 4, paddingTop: 10 }}>
                <XText style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: TEXT_INACTIVE }}>
                  เลขติดตามพัสดุจากระบบ
                </XText>
              </Box> */}
                  {this._renderXShippingFromOrderView(fromJS(x), orderState)}
                </View>
              )}

              {receiverVisibel === 0 ? null : this._renderDetailReceiverAddressCard(order)}
              {billingAddressVisible === 0 ? null : this._renderDetailBillingAddressCard(order)}
              {senderVisibel === 0 ? null : this._renderDetailSenderAddressCard(order)}
              {shippingVisibel === 0 ? null : this._renderDetailShippingTypeCard(order)}
              {/* จัดส่งก่อนชำระ && COD */}
              <View style={{ marginTop: 3 }} />
              {shipBeforePay === 1 ? this._renderShippingBeforePaySetting(order) : null}
              {showShipBeforePayCod ? this._renderShipBeforePayCodSetting(order) : null}

              {/* {_.isNil(seleChannel) ? this._renderDetailOrderChannelCard(order) : seleChannel === 0 ? null : this._renderDetailOrderChannelCard(order)} */}
              {MODE === 'dev' ? (
                <XText style={{ fontSize: 8, color: 'gray' }}>
                  {`Debug: partnerStoreId:${order.get('partner_store_id')} orderStoreId:${order.get(
                    'store_id'
                  )} T${type} C${state} P${payment_state} S${shipping_state}`}
                </XText>
              ) : null}
            </VStack>
          </XCard.Body>
        </XCard>
      </VStack>
    )
  }

  _renderFooter = (order) => {
    if (!order || !Map.isMap(order)) {
      return null
    }

    // const { navigation, pp_shippingTypeIndex } = this.props
    const { navigation } = this.props
    const { store_id, mode, isInitialized, orderTabsVisibility } = this.state
    const order_id = order.get('id') || null

    if (!isInitialized) {
      return null
    }

    const type = parseInt(order.get('type'))
    const state = parseInt(order.get('state'))
    const payment_state = parseInt(order.get('payment_state'))
    const shipping_state = parseInt(order.get('shipping_state'))

    const { CONFIRM, PAY, SHIP } = xCONS.ORDER_OPERATION_AVAILABILITY
    const statusConfirmBtn = CONFIRM[`_${type}${state}`] || 0
    let statusPayBtn = PAY[`_${type}${payment_state}`] || 0
    // log('...statusPayBtn statusPayBtn =>', statusPayBtn)
    // log('...statusPayBtn type =>', type)
    // log('...statusPayBtn payment_state =>', payment_state)
    // log('...statusPayBtn PAY => ', PAY[`_${type}${payment_state}`])

    // Additional logic for pay btn as the remaining amount does have effect
    const remainingAmt = parseFloat(order.get('remaining_forecast_amount'))
    // log('...statusPayBtn remainingAmt => ', remainingAmt)
    if (remainingAmt > 0 && type === 3) {
      statusPayBtn = 1 // แจ้งชำระ
    }
    let statusShipBtn
    // if (xUtil.isOrderInShippableState(state, shipping_state)) {
    if (this._canShip(state, shipping_state)) {
      statusShipBtn = SHIP[`_${type}${shipping_state}`] || 0
    } else {
      statusShipBtn = 0
    }

    // const isCancelState = xUtil.isOrderInCancelledState(state)
    // if (isCancelState) {
    //   statusShipBtn = 0
    // } else {
    //   statusShipBtn = SHIP[`_${type}${shipping_state}`] || 0
    // }

    // เงื่อนไขพิเศษ!!! ถ้ามีการจ่ายเงินเข้ามาแล้ว ในระหว่างที่ state ยังไม่จบ (payment state = 119 สำหรับ type 1,2)
    // แต่มี payments เข้ามา ให้แสดงปุ่ม "ยืนยันรับชำระก่อน"
    if (xAcl.canDoAtSelectedStore(PERM_STORE_HELPER.PAYMENT_EDIT)) {
      // check if the user has permission to confirm payment
      if (payment_state !== 119 && (type === 1 || type === 2)) {
        const payments = order.get('payments') || List([])
        for (let i = 0; i < payments.size; i++) {
          const payment = payments.get(i)
          // log('payments', payments.toJS())
          const status = payment.get('status')
          const isRefund = payment.get('isRefund')
          // log('special case index=' + i + ' status=' + status + ' isRefund=' + isRefund)
          if (status === 0 && isRefund === false) {
            statusPayBtn = xCONS.ORDER_OPERATION_AVAILABILITY.PAY.CONFIRM_GETTING_PAID // ยืนยันรับชำระ
            break
          }
        }
      } else if (payment_state !== 119 && type === 3) {
        // สำหรับ type 3 ได้รับเงินคืน ก็ต้องมีปุ่มยืนยันรับเงินคืนด้วย
        const payments = order.get('payments') || List([])
        for (let i = 0; i < payments.size; i++) {
          const payment = payments.get(i)
          const status = payment.get('status')
          const isRefund = payment.get('isRefund')
          if (status === 0 && isRefund === true) {
            statusPayBtn = xCONS.ORDER_OPERATION_AVAILABILITY.PAY.CONFIRM_GETTING_REFUND // ยืนยันรับเงินคืน
            break
          }
        }
      }
    }

    // log('_renderFooter statusConfirmBtn : ' + statusConfirmBtn)
    // log('_renderFooter statusPayBtn : ' + statusPayBtn)
    // log('_renderFooter statusShipBtn : ' + statusShipBtn)
    const txtConfirmBtn =
      statusConfirmBtn > 0 ? p.op.t(`Order.OrderConfirmButtonText._${statusConfirmBtn}`) : p.op.t(`Order.OrderStatesText._${type}${state}`)
    const txtPayBtn =
      statusPayBtn > 0 ? p.op.t(`Order.OrderPayButtonText._${statusPayBtn}`) : p.op.t(`Order.OrderStatesText._${type}${payment_state}`)
    const txtShipBtn =
      statusShipBtn > 0 ? p.op.t(`Order.OrderShipButtonText._${statusShipBtn}`) : p.op.t(`Order.OrderStatesText._${type}${shipping_state}`)
    // log('txtPayBtn: ' + txtPayBtn)
    if (_.includes([CREATE, EDIT], mode) || !mode || (!type && !state && !payment_state && !shipping_state)) {
      return null
    }

    const btnModeForConfirm = statusConfirmBtn > 0 ? xCONS.BTN_MODE.ACTIVE_PRIMARY : xCONS.BTN_MODE.INACTIVE // use 1, 2, 3
    let btnModeForPayment = statusPayBtn > 0 ? xCONS.BTN_MODE.ACTIVE_PRIMARY : xCONS.BTN_MODE.INACTIVE
    if (xCONS.BTN_MODE.ACTIVE_PRIMARY === btnModeForPayment && xCONS.BTN_MODE.ACTIVE_PRIMARY === btnModeForConfirm) {
      btnModeForPayment = xCONS.BTN_MODE.ACTIVE_SECONDARY
    }

    let btnModeForShipping
    if (statusShipBtn <= 0) {
      btnModeForShipping = xCONS.BTN_MODE.INACTIVE
    } else if (btnModeForConfirm !== xCONS.BTN_MODE.INACTIVE || btnModeForPayment !== xCONS.BTN_MODE.INACTIVE) {
      btnModeForShipping = xCONS.BTN_MODE.ACTIVE_SECONDARY
    } else {
      btnModeForShipping = xCONS.BTN_MODE.ACTIVE_PRIMARY
    }

    // const isMarketPlacesOrder = xUtil.isMarketPlacesOrder(order)
    // // ถ้าเป็น marketplace's order
    // if (isMarketPlacesOrder) {
    //   // ซ่อนปุ่มชำระ เพราะพวก Shopee จัดการให้แล้ว
    //   btnModeForPayment = xCONS.BTN_MODE.INACTIVE

    //   // ซ่อนปุ่มจัดส่งเพราะเรายังไม่พร้อม
    //   // FIXME: TED Fund Phase 2: ซ่อนปุ่ม จัดส่งชั่วคราวหากเป็น order จาก shopee [REMOVE ME]
    //   btnModeForShipping = xCONS.BTN_MODE.INACTIVE
    // }

    // let paymentFunc = statusPayBtn > 2
    const IS_TO_CONFIRM_PAYMENT =
      statusPayBtn === xCONS.ORDER_OPERATION_AVAILABILITY.PAY.CONFIRM_GETTING_PAID ||
      statusPayBtn === xCONS.ORDER_OPERATION_AVAILABILITY.PAY.CONFIRM_GETTING_REFUND
    const PAYMENT_PERM_REQUIRED = IS_TO_CONFIRM_PAYMENT ? PERM_STORE_HELPER.PAYMENT_EDIT : PERM_STORE_HELPER.PAYMENT_ADD
    // // const PAYMENT_PERM_REQUIRED = IS_TO_CONFIRM_PAYMENT ? PERM_STORE_HELPER.PAYMENT_ADD : PERM_STORE_HELPER.PAYMENT_ADD

    // // log('IS_TO_CONFIRM_PAYMENT: ' + IS_TO_CONFIRM_PAYMENT)
    // // log('PAYMENT_PERM_REQUIRED: ' + PAYMENT_PERM_REQUIRED)
    // let paymentFunc = IS_TO_CONFIRM_PAYMENT
    //   ? () =>
    //       navigation.dispatch(
    //         NavActions.navToPaymentListView({
    //           store_id,
    //           order_id,
    //           btnRight: this._getPaymentListAction(order),
    //           fromOrderDetail: true,
    //         }),
    //       ) // confirm payment
    //   : () => this.goToPayment({ order }) // to pay

    const orderId = order.get('id')
    const orderType = order.get('type')

    // const selectedStoreID = navigation.state.params.store_id || null
    // const myStoreId = selectedStoreID

    const orderStoreId = order.get('store_id')
    const partnerName = order.get('partner_name')
    const partnerStoreId = order.get('partner_store_id')

    const isShipBeforePay = order.get('ship_before_pay') || false
    const isCOD = order.get('is_cod') || false

    let paymentFunc = IS_TO_CONFIRM_PAYMENT
      ? // ? () => this.navToPaymentListView({ store_id, order_id, order, fromOrderDetail: true })
        () => this.navToPaymentListView({ store_id, order_id, fromOrderDetail: true })
      : async () => {
          // this.prepareBeforeGoToPaymentView({
          //   orderId,
          //   remaining_forecast_amount: order.get('remaining_forecast_amount'),
          //   cod_amount: order.get('cod_amount'),
          // })
          // await delay(200)
          // const orderBeforeConvert = order.toJS() as IOrder
          // const orderToBePaid = xUtil.converOrderDetialToOrderListItem(orderBeforeConvert)
          // orderToBePaid.t = orderBeforeConvert.remaining_forecast_amount
          // this.navToPaymentView({
          //   orderId,
          //   myStoreId,
          //   orderStoreId,
          //   orderType,
          //   partnerName,
          //   partnerStoreId,
          //   fromOrderDetail: true,
          //   isShipBeforePay,
          //   isCOD,

          //   initialOrdersToBePaid: {
          //     // [orderId]: {
          //     //   remaining_forecast_amount: order.get('remaining_forecast_amount'),
          //     //   cod_amount: order.get('cod_amount'),
          //     // },
          //     [orderId]: orderToBePaid,
          //   },
          // })
          this.navToPaymentView(order as IOrderMap)
        }
    // Hide the whole footer bar if hasNoOneButton === true
    const hasNoOneButton =
      (xCONS.BTN_MODE.INACTIVE === btnModeForConfirm &&
        xCONS.BTN_MODE.INACTIVE === btnModeForPayment &&
        xCONS.BTN_MODE.INACTIVE === btnModeForShipping) ||
      (!xAcl.canDoAtSelectedStore(PERM_STORE_HELPER.ORDER_EDIT) &&
        !xAcl.canDoAtSelectedStore(PAYMENT_PERM_REQUIRED) &&
        !xAcl.canDoAtSelectedStore(PERM_STORE_HELPER.SHIPPING_ADD))
    if (state === 109 && xUtil.doesOrderWaitsToBeShippedThenPaid(order)) {
      // set the primary button to Shipping if it's shipBeforePay
      btnModeForShipping = statusShipBtn === 0 ? xCONS.BTN_MODE.INACTIVE : xCONS.BTN_MODE.ACTIVE_PRIMARY
      btnModeForPayment = xCONS.BTN_MODE.ACTIVE_SECONDARY
      // txtShipBtn = p.op.t(`Order.OrderShipButtonText._${statusShipBtn}`)
    }
    // TODO: ปุ่ม ดำเนินการชำระ *freeze (ชื่อปุ่มใหม่ บันทึกรับชำระ\nแทนผู้ซื้อ)
    if (statusPayBtn === 7) {
      btnModeForPayment = xCONS.BTN_MODE.ACTIVE_BLUE_SECONDARY
      // paymentFunc = () => this.goToPayment({ order }) // to pay
      paymentFunc = async () => {
        // this.prepareBeforeGoToPaymentView({
        //   orderId,
        //   remaining_forecast_amount: order.get('remaining_forecast_amount'),
        //   cod_amount: order.get('cod_amount'),
        // })
        // await delay(200)
        // const orderBeforeConvert = order.toJS() as IOrder
        // const orderToBePaid = xUtil.converOrderDetialToOrderListItem(orderBeforeConvert)
        // orderToBePaid.t = orderBeforeConvert.remaining_forecast_amount
        // this.navToPaymentView({
        //   orderId,
        //   myStoreId,
        //   orderStoreId,
        //   orderType,
        //   partnerName,
        //   partnerStoreId,
        //   fromOrderDetail: true,
        //   isShipBeforePay,
        //   isCOD,

        //   initialOrdersToBePaid: {
        //     // [orderId]: {
        //     //   remaining_forecast_amount: order.get('remaining_forecast_amount'),
        //     //   cod_amount: order.get('cod_amount'),
        //     // },
        //     [orderId]: orderToBePaid,
        //   },
        // })
        this.navToPaymentView(order as IOrderMap)
      } // to pay
    }
    // <Footer style={{ height: 50, bottom: this.state.bottomOffset }} >
    // if (xUtil.checkShipBeForePayStatus(order) && btnModeForShipping === 2) {
    //   btnModeForShipping = xCONS.BTN_MODE.ACTIVE_PRIMARY
    // }

    // web:: เอาไว้ทำให้ lock footer ไว้ที่ตอนท้ายของ column
    // const webStyle = !p.op.isWeb()
    //   ? {}
    //   : {
    //       flex: 1,
    //       flexBasis: 'auto',
    //       height: 54,
    //       width: '100%',
    //       position: 'absolute',
    //       bottom: 0,
    //       // bottom: orderTabsVisibility ? 144 : 94,
    //     }

    // ---- BEGIN MKP status overwrite
    // Hide btn "บันทึกรับชำระจากลูกค้า" and "จัดส่ง" if it's a MKP Order due to the fact that
    // 1) Both actions should be done from Shopee, not XSelly
    // 2) Simplify UI. Reduce user confusion (เหตุเกิดกับคุณกุ้ง ที่บันทึกรับชำระเอง แต่ยอดไม่ตรง เลยต้องกดคืนเงินเองทีหลัง)
    // Note1: UI ไม่ได้ซ่อน ปุ่มสำหรับ การแจ้งคืนเงิน เผื่อไว้ในกรณีที่มีข้อผิดพลาด จะได้กดเองได้ (แต่เคสนี้ก็ไม่น่าเกิดอยู่ดี)
    // Note2: เหตุที่โค้ดนี้อยู่ตรงนี้เพราะว่าเป็นจุดสุดท้ายก่อน hasNoOneButton (ไม่ถึง 10 line จากนี้) จะ compute
    // ซึ่งจะเป็นการ overwrite การแสดงผลปุ่มที่ไม่มี logic อื่นมากวนได้อีก
    if (xUtil.isMarketPlacesOrder(order)) {
      const paymentState = order.get('payment_state')
      // console.log('ORDER PAYMENT => ', order.get('payment_state'))
      if (paymentState === 111) {
        btnModeForPayment = xCONS.BTN_MODE.INACTIVE
      }
      // mkpIsSelfDelivery => https://app.clickup.com/t/860pwxhvh
      const mkpIsSelfDelivery = order.has('mkp_is_self_delivery') ? order.get('mkp_is_self_delivery') : false
      if (order.get('shipping_state') === 121 && !mkpIsSelfDelivery) {
        btnModeForShipping = xCONS.BTN_MODE.INACTIVE
      }

      // FOR LINE MKP // https://app.clickup.com/t/860qr1qtj
      const MKP_ID = order.get('mkp_id')
      const IS_COD = order.has('is_cod') ? order.get('is_cod') : false
      if (mkpIsSelfDelivery && MKP_ID === 4 && IS_COD && paymentState !== 119) {
        btnModeForPayment = xCONS.BTN_MODE.ACTIVE_PRIMARY
      }
      // เปลี่ยนสีปุ่ม payment เมื่อเข้าเงื่อนไขนี้ https://app.clickup.com/t/860qr49vc
      if (MKP_ID === 4 && order.get('ship_before_pay') && order.get('shipping_state') !== 129 && paymentState === 111) {
        btnModeForPayment = xCONS.BTN_MODE.ACTIVE_SECONDARY
      }

      // ซ่อนปุ่ม จัดส่ง ของ LINE SHIPPING https://app.clickup.com/t/860qr5rwg
      const LINE_CONDITION_SHIPPING =
        MKP_ID === 4 && order.get('mkp_is_self_delivery') && !order.get('ship_before_pay') && paymentState !== 119
      if (LINE_CONDITION_SHIPPING) {
        btnModeForShipping = xCONS.BTN_MODE.INACTIVE
      }
    }
    // ---- END MKP status overwrite

    return hasNoOneButton ? null : (
      <HStack p='2' space='2' bg='gray.100' key={this.state.orderTabIndex}>
        {this.renderFooterButton({
          text: txtConfirmBtn,
          mode: btnModeForConfirm,
          onPress: this._handleConfirmOrder,
          perm: PERM_STORE_HELPER.ORDER_EDIT,
        })}

        {this.renderFooterButton({
          text: txtPayBtn,
          mode: btnModeForPayment,
          onPress: paymentFunc,
          perm: PAYMENT_PERM_REQUIRED,
        })}

        {this.renderFooterButton({
          text: txtShipBtn,
          mode: btnModeForShipping,
          // onPress: () => this.navToShipView({ order_id, store_id }),
          onPress: this.navToShippingView,
          // btnStyle: { paddingLeft: 0, marginLeft: 0 },
          perm: PERM_STORE_HELPER.SHIPPING_ADD,
        })}
      </HStack>
    )

    // return hasNoOneButton ? null : (
    //   <HStack
    //     key={this.state.orderTabIndex}
    //     // @ts-ignore
    //     // style={StyleSheet.flatten([FOOTER_BG, webStyle])}>
    //     style={StyleSheet.flatten(FOOTER_BG)}>
    //     <HStack
    //       // @ts-ignore
    //       style={StyleSheet.flatten([FOOTER_BG, { alignSelf: 'flex-start' }])}>
    //       {this.renderFooterButton({
    //         text: txtConfirmBtn,
    //         mode: btnModeForConfirm,
    //         onPress: this._handleConfirmOrder,
    //         perm: PERM_STORE_HELPER.ORDER_EDIT,
    //       })}

    //       {this.renderFooterButton({
    //         text: txtPayBtn,
    //         mode: btnModeForPayment,
    //         onPress: paymentFunc,
    //         perm: PAYMENT_PERM_REQUIRED,
    //       })}

    //       {this.renderFooterButton({
    //         text: txtShipBtn,
    //         mode: btnModeForShipping,
    //         // onPress: () => this.navToShipView({ order_id, store_id }),
    //         onPress: this.navToShippingView,
    //         // btnStyle: { paddingLeft: 0, marginLeft: 0 },
    //         perm: PERM_STORE_HELPER.SHIPPING_ADD,
    //       })}
    //     </HStack>
    //   </HStack>
    // )
  }

  navToShippingView = () => {
    const orderDetialJS = this.props.selectedOrder.toJS()
    this.props.navigation.dispatch(
      NavActions.navToShippingView({
        fromOrder: orderDetialJS,
        onShippingSuccess: this._onRefresh,
      })
    )
  }

  _renderAddressesForOrganization = () => {
    // {/* ที่อยู่หลัก และ ที่อยู่รอง */}
    if (!APP_CONFIG.order.create_order.show_receiver_address_index_segment_btn) {
      return
    }
    const { widthAndHeightForAddressesOrganization, isLoadingForAddressesOrganization, addressesForOrganization } = this.state
    if (_.isNil(addressesForOrganization)) {
      return null
    }
    // console.log(`widthAndHeightForAddressesOrganization`, widthAndHeightForAddressesOrganization)
    return (
      <View
        onLayout={(event) => {
          const { x, y, width, height } = event.nativeEvent.layout
          const newwidthAndHeightForAddressesOrganization = {
            width,
            height,
          }
          xUtil.setStatePromise(this, { widthAndHeightForAddressesOrganization: newwidthAndHeightForAddressesOrganization })
        }}>
        {addressesForOrganization.length < 2 ? null : (
          <Box style={{ marginTop: 0, paddingTop: 8, flex: 1, paddingLeft: 4, paddingRight: 4, paddingBottom: 4 }}>
            <View style={{ width: 80, alignItems: 'flex-start' }}>
              <XText
                style={{
                  fontSize: FONT_SIZE_NORMAL,
                  color: TEXT_ACTIVE,
                  textAlign: 'center',
                }}>
                ที่อยู่ผู้รับ
              </XText>
            </View>
            <View style={{ flex: 1 }}>
              <Segment
                options={this.OPTIONS_CHOOSE_ADDRESSES}
                selectedIndex={this.state.optionChooseAddressesIndex}
                onSegmentChange={this._onSegmentChangeOpenAddresses}
              />
            </View>
          </Box>
        )}

        {addressesForOrganization.map((address: ISelectedAddresses, index: number) =>
          this._renderReceiverAddressForOrganization(address, index)
        )}
        {isLoadingForAddressesOrganization ? (
          <View
            style={{
              position: 'absolute',
              width: _.isNil(widthAndHeightForAddressesOrganization) ? 0 : widthAndHeightForAddressesOrganization.width,
              height: _.isNil(widthAndHeightForAddressesOrganization) ? 0 : widthAndHeightForAddressesOrganization.height,
              backgroundColor: 'rgba(52, 52, 52, 0.7)',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <EButton
              // title="Loading button"
              loading
              disabled
              type='clear'
            />
          </View>
        ) : null}
      </View>
    )
  }

  _renderReceiverAddressForOrganization = (address: ISelectedAddresses, index: number) => {
    const { optionChooseAddressesIndex, addressesForOrganization } = this.state
    const addressName = _.isNil(address.name) ? '' : address.name
    const addressTxt = this._getAddresText(address)
    const { is_primary } = address
    const textAddress = `ที่อยู่ผู้รับ`
    const textLable = is_primary ? `${textAddress} (ที่อยู่หลัก)` : `${textAddress} (ที่อยู่รอง)`
    // console.log(`this.state.optionChooseAddressesInde`, this.state.optionChooseAddressesIndex)
    const borderColor = optionChooseAddressesIndex === index || addressesForOrganization.length < 2 ? COLORS.APP_MAIN : FORM_INACTIVE
    return (
      <View key={index} style={{ paddingTop: 10 }}>
        <View style={{ width: 180, alignItems: 'flex-start', paddingBottom: 4, paddingLeft: 4 }}>
          <XText
            style={{
              fontSize: FONT_SIZE_NORMAL,
              color: TEXT_ACTIVE,
              textAlign: 'center',
            }}>
            {textLable}
          </XText>
        </View>
        <TouchableOpacity
          disabled
          style={[
            s.webFlexBasis,
            // s.webBorderInactive,
            {
              borderTopWidth: 1,
              borderLeftWidth: 1,
              borderBottomWidth: 1,
              borderRightWidth: 1,
              borderTopColor: borderColor,
              borderLeftColor: borderColor,
              borderBottomColor: borderColor,
              borderRightColor: borderColor,
              flex: 1,
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              // borderWidth: 1,
              // borderColor: optionChooseAddressesIndex === index || addressesForOrganization.length < 2 ? COLORS.APP_MAIN : FORM_INACTIVE,
            },
          ]}>
          <View
            style={[
              s.webFlexBasis,
              s.webWidth100Percent,
              {
                flex: 9,
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                // ...Platform.select({
                //   web: {
                //     maxWidth: Math.floor(xCONS.SPECIFIC_PLATFORM.WEB.MAIN_CONTENT_WIDTH / 3) - 40,
                //   },
                // }),
              },
            ]}>
            <View
              style={[
                s.webFlexBasis,
                {
                  flex: 1,
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  paddingTop: 5,
                  paddingBottom: 5,
                  paddingLeft: 5,
                  ...Platform.select({
                    web: { width: '100%' },
                  }),
                },
              ]}>
              <View style={[NO_MARGIN_PADDING, s.webFlexBasis, { flex: p.op.isWeb() ? 1 : 2, paddingLeft: 5 }]}>
                <Icon name='person' style={{ fontSize: FONT_ICON_NORMAL, color: TEXT_INACTIVE }} />
              </View>

              <View
                style={[
                  s.webFlexBasis,
                  {
                    flex: p.op.isWeb() ? 8 : 15,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  },
                ]}>
                <XText style={{ fontSize: FONT_SIZE_NORMAL, color: TEXT_INACTIVE }}>{addressName || ''}</XText>
              </View>
            </View>

            <View
              style={[
                s.webFlexBasis,
                s.webWidth100Percent,
                {
                  flex: 1,
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  paddingTop: 5,
                  paddingBottom: 5,
                  paddingLeft: 5,
                },
              ]}>
              <View
                style={[
                  s.webFlexBasis,
                  NO_MARGIN_PADDING,
                  {
                    flex: p.op.isWeb() ? 1 : 2,
                    paddingLeft: 5,
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                  },
                ]}>
                <View
                  style={[
                    s.webFlexBasis,
                    NO_MARGIN_PADDING,
                    {
                      flex: 1,
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                    },
                  ]}>
                  <Icon name='pin' style={{ fontSize: FONT_ICON_NORMAL, color: TEXT_INACTIVE }} />
                </View>
              </View>

              <View
                style={[
                  s.webFlexBasis,
                  NO_MARGIN_PADDING,
                  {
                    flex: p.op.isWeb() ? 8 : 15,
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                  },
                ]}>
                <View
                  style={[
                    s.webFlexBasis,
                    NO_MARGIN_PADDING,
                    {
                      flex: 1,
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                    },
                  ]}>
                  <XText style={{ fontSize: FONT_SIZE_NORMAL, color: TEXT_INACTIVE }}>{addressTxt || '-'}</XText>
                </View>
              </View>
            </View>
          </View>
          {/* <View style={[s.webFlexBasis, { flex: 1, flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end' }]}>
          <Icon
            name='arrow-forward'
            style={{
              width: 30,
              paddingRight: 7,
              fontSize: FONT_ICON_NORMAL,
              color: TEXT_PRIMARY,
              textAlign: 'right',
            }}
          />
        </View> */}
        </TouchableOpacity>
      </View>
    )
  }

  _renderCreateModeShipmentCard = (order) => {
    // const { pp_shippingTypeIndex, selectedStore, editingOrder, onEditingOrderChange, orderTabIndex } = this.props
    const { selectedStore, editingOrder, onEditingOrderChange, orderTabIndex } = this.props
    const { txtSenderName, txtSenderAddress, mode, customCreateOrder, inputOpenForStaffMemberText, isBillingAddressUsed } = this.state
    const orderIdKey = order.get('id') || 'noid'

    // // const shipping_type_id = order.get('shipping_type_id')
    // const shippingTypeLabel = (pp_shippingTypeIndex > -1 && this.shippingTypeItems[pp_shippingTypeIndex].label) || 'กรุณาเลือกรูปแบบจัดส่ง'

    const selectedShippingTypeId = order.get('shipping_type_id') || -1
    // console.log('selectedShippingTypeId => ', selectedShippingTypeId)
    const selectedShippingTypeIndex =
      selectedShippingTypeId > 0 ? _.findIndex(this.ALL_SHIPPING_TYPE_ITEMS, (sItem) => sItem.value === selectedShippingTypeId) : -1
    // let shippingTypeLabel = null
    // if (shipping_index > -1) {
    //   shippingTypeLabel = this.shippingTypeItems[shipping_index].label
    // } else if (shipping_index === -1 && shipping_type_id > 0) {
    //   shippingTypeLabel = 'กรุณาดาวน์โหลดแอปเวอร์ชั่นใหม่ เพื่อแสดงผลข้อมูลนี้'
    // } else {
    //   shippingTypeLabel = 'กรุณาเลือกรูปแบบจัดส่ง'
    // }
    const handleChangeOrderShippingType = (itemInfo) => {
      const { item, index } = itemInfo
      if (!item || !item.value) {
        return
      }
      const newShippingTypeId = item.value
      onEditingOrderChange({ key: 'shipping_type_id', value: newShippingTypeId, orderTabIndex })
      const isCOD = _.includes(xCONS.COD_SHIPPING_TYPE_IDS, newShippingTypeId)

      if (isCOD) {
        // สำหรับ "เก็บเงินปลายทาง" ยังไงก็ต้อง true ถ้าส่งแบบ COD
        onEditingOrderChange({ key: 'is_cod', value: true, orderTabIndex })
      } else {
        onEditingOrderChange({ key: 'is_cod', value: false, orderTabIndex })
        onEditingOrderChange({ key: 'cod_fee', value: '0', orderTabIndex })
        onEditingOrderChange({ key: 'cod_amount', value: '0', orderTabIndex })
      }

      // ปรับ flag "จัดส่งก่อนชำระ" ถ้าใช้ custom order settings
      const { customCreateOrder } = this.state
      if (_.has(customCreateOrder, 'ship_before_pay')) {
        const { ship_before_pay } = customCreateOrder
        const shipBeforePaySettingValue = isCOD ? ship_before_pay.is_cod : ship_before_pay.n_cod
        onEditingOrderChange({ key: 'ship_before_pay', value: shipBeforePaySettingValue, orderTabIndex })
      }
    }

    const useXShipping = this._useXShippingForCreateOrder()
    // รูปแบบจัดส่ง
    const channel_id = xUtil.parseSmartInt(editingOrder.get('channel_id'))
    const channel_index = _.findIndex(this.channelItems, (sItem) => sItem.value === channel_id)
    let txtChannelLabel = null

    if (channel_index > -1) {
      txtChannelLabel = this.channelItems[channel_index].label
    }
    // สำหรับ COD & จัดส่งก่อนชำะ
    // let shipping_type_id = order.get('shipping_type_id') || -1
    // const getShippingTypeId = pp_shippingTypeIndex
    const shipping_type_id = order.get('shipping_type_id') || -1
    // const getShippingTypeId = order.get('shipping_type_id') || -1
    // if (mode !== CREATE) {
    //   getShippingTypeId = shipping_type_id
    // }
    let showShippingCod = false
    const shippingTypeItem = this._getShippingTypeItem(shipping_type_id)
    if (!_.isNil(shippingTypeItem)) {
      // ถ้าเลือกขนส่งที่มี cod สวิต cod จะเปิด
      if (_.includes(xCONS.COD_SHIPPING_TYPE_IDS, shippingTypeItem.value)) {
        showShippingCod = true
      }
      // else {
      //   // ถ้าเลือกขนส่งที่ไม่มี cod สวิต cod จะปิด
      //   if (this.shipBeforePayAndCodForCreateOrder) {
      //     onEditingOrderChange({ key: 'ship_before_pay', value: false, orderTabIndex })
      //     onEditingOrderChange({ key: 'is_cod', value: false, orderTabIndex })
      //     this.shipBeforePayAndCodForCreateOrder = false
      //   }
      // }
    }

    // if (showShippingCod && !this.shipBeforePayAndCodForCreateOrder) {
    //   onEditingOrderChange({ key: 'ship_before_pay', value: true, orderTabIndex })
    //   onEditingOrderChange({ key: 'is_cod', value: true, orderTabIndex })
    //   this.shipBeforePayAndCodForCreateOrder = true
    // }

    // console.log('customCreateOrder => ', customCreateOrder)
    // เช็ดตั้งค่า custom create order
    const salesChannel = customCreateOrder.sales_channel
    const { shipping } = customCreateOrder
    const { receiver } = customCreateOrder
    const { sender } = customCreateOrder
    const shippingBeforePay = customCreateOrder.ship_before_pay
    let useCOD = false
    if (_.has(shippingTypeItem, 'value')) {
      useCOD = _.includes(xCONS.COD_SHIPPING_TYPE_IDS, shippingTypeItem.value)
    }
    const showShippingCard = shipping.v === 1 || receiver.v === 1 || sender.v === 1 || shippingBeforePay.v === 1
    // showShippingCard : จะแสดง Card นี้ไหม custom ปิดหมดก็ไม่ต้องแสดง
    // useCOD : custom รูปแบบจัดส่งมี COD
    return showShippingCard || useCOD || useXShipping ? (
      <XCard w='full'>
        <XCard.Body>
          <VStack space='1'>
            {/* <XButton
          onPress={() => {
            this.props.navigation.dispatch(
              NavActions.navToCustomCreateOrderView({
                mode: this.state.mode,
                store_id: this.state.store_id,
              }),
            )
          }}>
          <XText>Open Setting Order</XText>
        </XButton> */}
            {/* <CustomCreateOrder  ARTID TO IT
          title={'ตั้งค่าออเดอร์'}
          onRequestClose={() => this.setState({ visibleModelCustomCreateOrder: false })}
          visible={this.state.visibleModelCustomCreateOrder}
          hasCloseButton={true}
        /> */}
            <HStack py='2' alignItems='center' justifyContent='center'>
              <XText bold fontSize='md'>
                {p.op.t('Order.shipmentInfo')}
              </XText>
            </HStack>
            {/* เปิดให้ลูกทีม */}
            {APP_CONFIG.order.create_order.show_reseller_code_text_input ? (
              <Box
                style={{ flexDirection: 'row', marginTop: 0, paddingTop: 8, flex: 1, paddingLeft: 4, paddingRight: 4, paddingBottom: 4 }}>
                <View style={{ width: 80, alignItems: 'flex-start' }}>
                  <XText
                    style={{
                      fontSize: FONT_SIZE_NORMAL,
                      color: TEXT_ACTIVE,
                      textAlign: 'center',
                    }}>
                    เปิดให้ลูกทีม
                  </XText>
                </View>
                <View style={{ flex: 1 }}>
                  <XInput
                    value={inputOpenForStaffMemberText}
                    placeholder='รหัสตัวแทนลูกทีม เช่น SS01-000XX'
                    style={{ flex: 1 }}
                    autoCapitalize='none'
                    onChangeText={(text: string) => this._onChangeTextOpenForStaffMember(text)}
                  />
                </View>
              </Box>
            ) : null}
            {this._renderAddressesForOrganization()}

            {receiver.v === 1 ? (
              <VStack>
                <XText>ผู้รับ</XText>
                {receiver.t === 0 ? this._renderReceiverNameOnly() : null}
                {receiver.t === 1 ? this._renderReceiverNameAndPhon() : null}
                {receiver.t === 2 ? this._renderReceiverAddress() : null}
                {this._renderFindDupByReceiverAddr()}
              </VStack>
            ) : null}

            {receiver.v === 1 ? <VStack>{this._renderToggleBillingAddressCheckbox()}</VStack> : null}

            {receiver.v === 1 && isBillingAddressUsed ? (
              <VStack>
                <XText>ที่อยู่ผู้รับสำหรับใบกำกับภาษี</XText>
                {/* {receiver.t === 0 ? this._renderReceiverNameOnly() : null}
                {receiver.t === 1 ? this._renderReceiverNameAndPhon() : null}
                {receiver.t === 2 ? this._renderReceiverAddress() : null}
                {this._renderFindDupByReceiverAddr()} */}
                {this._renderCreateBillingAddressCard()}
              </VStack>
            ) : null}

            {sender.v === 1 ? (
              <VStack>
                <XText>ผู้จัดส่ง</XText>
                {this._renderSenderNameInputItem({
                  iconName: 'person',
                  txtLabel: 'ผู้ส่ง',
                  txtMessage: txtSenderName,
                  // handlePress: () => Alert.alert('Hello Sender Name'),
                })}
                {this._renderSenderAddressPickerButtonItem({
                  iconName: 'pin',
                  txtLabel: 'ที่อยู่',
                  txtMessage: txtSenderAddress || '<< ใช้ที่อยู่ของผู้ขายส่ง >>',
                  txtMessageStyle: null,
                  handlePress: () => this._pickAddress('sender'),
                  noTopBorder: true,
                })}
              </VStack>
            ) : null}

            {shipping.v === 0 ? null : (
              <VStack mt='1'>
                {/* {!sender.v ? <View style={{ height: 15 }} /> : null} */}
                {/* {
                  shippingBeforePay.v === 1  && !showShippingCod ? <View>

                  </View>
                } */}
                <XText>รูปแบบจัดส่ง</XText>
                <XFlatPicker
                  // for CREATE mode only
                  key={`ShippingTypePicker_${orderIdKey}`}
                  title='เลือกรูปแบบจัดส่ง'
                  placeholder='กรุณาเลือกรูปแบบจัดส่ง'
                  placeholderStyle={{ marginLeft: 6 }}
                  ref={this._shippingTypeCreateModePickerRef}
                  selectedIndex={selectedShippingTypeIndex}
                  options={this.state.shippingTypeItems}
                  onPick={handleChangeOrderShippingType}
                  renderSelectedValue={this._renderDetailShippingTypeCardButton}
                  renderItem={this._renderCommonFlatPickerItemForShipping}
                  // renderLeftHeaderButton={() => {
                  //   if (xAcl.isSelectedStoreOwner()) {
                  //     return <XIcon family='Ionicons' name='settings-sharp' style={{ flex: 0, minWidth: 20, color: COLORS.APP_MAIN }} />
                  //   }
                  // }}
                  // onPressLeftHeaderButton={() => this.navToStoreSettingShippingType()}
                  renderTitleBar={() => this._renderTotleBarForShipping()}
                />
                {/* {this._renderLineButtonItem({
                  iconName: 'local-shipping',
                  iconFamily: 'MaterialIcons',
                  txtLabel: 'รูปแบบจัดส่ง',
                  txtMessage: shippingTypeLabel,
                  txtMessageStyle: null,
                  handlePress: this.onPressShippingTypePicker,
                  // handlePress: () =>
                  //   this.props.navigation.dispatch(
                  //     NavActions.navToPagePicker({
                  //       key: 'shippingType',
                  //       label: p.op.t('Order.shippingType'),
                  //       title: `${p.op.t('choose')}${p.op.t('Order.shippingType')}`,
                  //       items: this.shippingTypeItems,
                  //       viewType: xCONS.PAGEPICKER_VIEWTYPE.ICON,
                  //       callBackAfterSelected: this._navToPagePicker(),
                  //       // onEditingOrderChange({ key: 'shipping_type_id', value: callBack.data.value.value, orderTabIndex })
                  //     }),
                  //   ),


                  // TO DO ตัวนี้ใช้ก่อนใส่ Custom Create Order
                  // this._navToPagePicker(),

                  // TO DO
                  // handlePress: this._navToPagePicker,
                  // handlePress: () => navigation.dispatch(
                  //   NavActions.navToPagePicker({
                  //     key: 'shippingType', label: p.op.t('Order.shippingType'),
                  //     title: `${p.op.t('choose')}${p.op.t('Order.shippingType')}`,
                  //     items: this.shippingTypeItems,
                  //     viewType: xCONS.PAGEPICKER_VIEWTYPE.ICON,
                  //   })),
                })} */}
              </VStack>
            )}
            {/* { XShipping } */}
            {/* {useXShipping ? this._renderXShipping(order) : null} */}

            {/* <View style={{ marginTop: 3 }} /> */}
            {shippingBeforePay.v === 0 ? null : this._renderShippingBeforePaySetting(order)}
            {showShippingCod ? this._renderShipBeforePayCodSetting(order) : null}

            {/* {
              salesChannel.v === 0 ? null :
              <View>
                <View style={{ height: 15 }} />
                <View style={{ padding: 7 }}>
                  <XText style={{ fontSize: FONT_SIZE_NORMAL, color: TEXT_ACTIVE }}>ช่องทางการขาย</XText>
                </View>
                {
                  this._renderLineButtonItem({
                  iconFamily: 'Ionicons',
                  iconName: 'locate',
                  txtLabel: 'ช่องทางการขาย',
                  txtMessage: txtChannelLabel,
                  txtMessageStyle: null,
                  handlePress: () =>
                    this._navToCreateOrderChannelPagePicker(bpData => {
                      // log('callback _navToPagePicker', bpData)
                      if (!bpData || !bpData.data || !bpData.data.value) {
                        return
                      }
                      // log('bpData => ', bpData)
                      const newOrderChannelId = bpData.data.value.value
                      onEditingOrderChange({ key: 'channel_id', value: newOrderChannelId, orderTabIndex })
                      p.op.setAppDefaultValue('channel_id', newOrderChannelId)
                    }),
                })
                }
              </View>
              } */}
            {/* { this._renderOrderChannelCard(editingOrder) } */}
          </VStack>
        </XCard.Body>
      </XCard>
    ) : null
  }

  _renderTotleBarForShipping = () => {
    if (!xAcl.isSelectedStoreOwner()) {
      return
    }
    return (
      <HStack w='full' px='1' py='1' space='2' alignItems='center'>
        <XBlankButton
          flex={1}
          // h='full'
          h='55px'
          // minH='10'
          variant='outline'
          alignContent='center'
          justifyContent='center'
          colorScheme='gray'
          borderColor={COLORS.APP_MAIN}
          onPress={() => this.navToStoreSettingShippingType()}>
          {/* <XIcon name={'local-shipping'} family={'MaterialIcons'} style={{ flex: 0, minWidth: 25, width: 25 }} /> */}
          <XIcon color={COLORS.APP_MAIN} name='local-shipping' family='MaterialIcons' />
          <XText color={COLORS.APP_MAIN} pl='2' fontSize='xs' textAlign='center' textVerticalAlign='center'>
            {p.op.t('ButtonLabel.ShowHideShippingTypes')}
          </XText>
        </XBlankButton>
        <XBlankButton
          flex={1}
          // h='full'
          h='55px'
          // minH='10'
          variant='outline'
          alignContent='center'
          justifyContent='center'
          colorScheme='gray'
          borderColor={COLORS.APP_MAIN}
          onPress={() => this.navToStoreSettingXSgipping()}>
          {/* <XIcon name={'barcode'} family={'MaterialCommunityIcons'} style={{ flex: 0, minWidth: 25, width: 25 }} /> */}
          <XIcon color={COLORS.APP_MAIN} name='settings' family='Ionicons' />
          <XText color={COLORS.APP_MAIN} pl='2' fontSize='xs' textAlign='center' textVerticalAlign='center'>
            {p.op.t('ButtonLabel.NavToXShippingSettings')}
          </XText>
        </XBlankButton>
      </HStack>
    )
  }

  _isCREATEOrEDITMode = (): boolean => {
    const { mode } = this.state
    // const focusedOrder = this._getFocusedOrder()
    // const isEditableInConfirmationMode = this._calculateEditableInConfirmationMode(focusedOrder)
    // const { isEditableQty, isEditableEtc } = isEditableInConfirmationMode
    // console.log("BaseUIOrderView._isCREATEOrEDITMode mode: " + mode + " isEditableEtc: " + isEditableEtc)
    //    if (mode === VIEW_EDIT && isEditableEtc) {
    if (mode === CREATE || mode === EDIT) {
      // p.op.showConfirmationOkOnly(null, 'กรุณากด แก้ไขค่าส่ง/ส่วนลด/ค่าอื่นๆ ก่อนทำการแก้ไข')
      return true
    }
    // p.op.showConfirmationOkOnly(null, 'false')
    return false
  }

  _renderShippingBeforePaySetting = (order) => {
    const { mode } = this.state
    const focusedOrder = this._getFocusedOrder()
    const showSwitchDeliveryBeforePayment = focusedOrder.get('ship_before_pay') || false
    let iconStyle
    let textStype = COLORS.SUMMARY_ORDER_DONUT_COLOR_SCALE.myTasks_payOther
    if (!showSwitchDeliveryBeforePayment) {
      // iconStyle = {
      //   flex: 0,
      //   color: null,
      //   marginRight: 10,
      //   minWidth: 20,
      //   marginLeft: 6,
      //   fontSize: 18,
      // }
      textStype = TEXT_INACTIVE
    }
    if (mode === CREATE) {
      iconStyle = {
        // flex: 0,
        // marginRight: 13,
        // minWidth: 20,
        // marginLeft: -2,
        color: showSwitchDeliveryBeforePayment ? COLORS.SUMMARY_ORDER_DONUT_COLOR_SCALE.myTasks_payOther : TEXT_INACTIVE,
      }
    } else {
      iconStyle = {
        // fontSize: 18,
        // flex: 0,
        color: showSwitchDeliveryBeforePayment ? COLORS.SUMMARY_ORDER_DONUT_COLOR_SCALE.myTasks_payOther : COLORS.TEXT_INACTIVE,
        // marginRight: -5,
        // // minWidth: 20,
        // marginLeft: -6,
      }
    }

    // const disabledSwitch = !this._isCREATEOrEDITMode()
    let disabledSwitch = !this._isCREATEOrEDITMode()
    if (mode === VIEW_EDIT) {
      disabledSwitch = false
    }

    return this._renderSwitchCard({
      order,
      key: 'ship_before_pay',
      value: showSwitchDeliveryBeforePayment,
      txtLabel: 'จัดส่งก่อนชำระ',
      txtLabelStyle: {
        color: textStype,
        // fontSize: FONT_SIZE_NORMAL
      },
      txtHelp: {
        title: 'จัดส่งก่อนชำระ',
        message:
          `หากเปิดการตั้งค่านี้ ออเดอร์ขายนี้จะเข้าสู่สถานะพร้อมจัดส่ง ทันทีที่ผู้ซื้อและผู้ขายยืนยันออเดอร์` +
          `โดยไม่คำนึงว่ามีการชำระแล้วหรือยัง ซึ่งเหมาะกับกรณีที่ต้องการรับชำระ หลังจากที่จัดส่งสินค้าแล้ว` +
          `เช่น การเก็บเงินปลายทาง หรือ การวางบิลเก็บเงินทีหลัง\n\n` +
          `ในกรณีที่มีออเดอร์ซื้อคู่กัน (นั่นคือในออเดอร์นี้มีสินค้าที่ฉันไม่ได้สต๊อกและให้ผู้ขายจัดส่งแทน) ` +
          `การตั้งค่านี้จะเปิดต่อไปถึงออเดอร์ซื้อด้วย หากร้านผู้ขายเปิดการตั้งค่าร้าน “อนุญาตให้ผู้ซื้อ เปิดการใช้งาน จัดส่งก่อนชำระ ได้โดยอัตโนมัติขณะสร้างออเดอร์\n\n` +
          `หมายเหตุ: ออเดอร์ที่เปิดการตั้งค่านี้ จะ”ไม่”ถูกยกเลิกอัตโนมัติ จากการเลยเวลาครบกำหนดชำระ (แต่อาจถูกยกเลิกได้ หากออเดอร์ซื้อ/ขายที่เกี่ยวข้องถูกยกเลิก`,
      },
      prefixIcon: {
        iconName: 'truck-delivery',
        iconStyle,
      },
      changeValueOnly: true,
      mode,
      disabled: disabledSwitch,
      // additionalComponent: this._renderTextLabel('ใช้งานไม่ได้เนื่องจากออเดอร์เสร็จสิ้นแล้ว'),
    })
  }

  _renderShipBeforePayCodSetting = (order) => {
    const { mode } = this.state
    // console.log('mode => ', mode)
    const focusedOrder = this._getFocusedOrder()
    const showSwitchDeliveryBeforePaymentCod = focusedOrder.get('is_cod') || false
    let disabledSwitch = !this._isCREATEOrEDITMode()
    if (mode === VIEW_EDIT) {
      disabledSwitch = false
    }

    // console.log('disabledSwitch => ', disabledSwitch)
    return this._renderSwitchCard({
      order,
      key: 'is_cod',
      value: showSwitchDeliveryBeforePaymentCod,
      txtLabel: 'เก็บเงินปลายทาง',
      txtLabelStyle: {
        color: COLORS.COD_COLOR,
        // fontSize: FONT_SIZE_NORMAL
      },
      txtHelp: {
        title: 'เก็บเงินปลายทาง',
        message:
          `หากเปิดใช้งาน คุณจะสามารถระบุ ยอดและค่าธรรมเนียมเก็บเงินปลายทางได้ ` +
          `และหากมีการขอเลขติดตามพัสดุจากขนส่งก็จะเป็นการแจ้งให้พนักงานจัดส่งเก็บเงินตามยอดเก็บเงินปลายทางที่ระบุไว้\n\n` +
          `ในช่องค่าธรรมเนียมปลายทาง หากระบุ ค่าธรรมเนียมก็จะถูกนำไปรวมกับ”ยอดรวม” ของออเดอร์\n\n` +
          `ในกรณีที่มีออเดอร์ซื้อคู่กัน 1 ออเดอร์ ยอดนี้จะถูกระบุต่อไปที่ออเดอร์ซื้อด้วย หากร้านผู้ขายเปิดการตั้งค่าร้าน` +
          `"อนุญาตให้ผู้ซื้อ เปิดการใช้งาน เก็บเงินปลายทาง ได้โดยอัตโนมัติขณะสร้างออเดอร์"\n\n` +
          `ในกรณีที่มีออเดอร์ซื้อคู่กันมากกว่า 1 ออเดอร์ (นั่นคือในออเดอร์นี้มีสินค้ามาจากสต๊อกของหลายผู้ขาย)` +
          `ฉันต้องระบุค่าธรรมเนียมในแต่ละออเดอร์ซื้อเอง (ระบบจะไม่ระบุให้)`,
      },
      prefixIconImg: {
        iconImgName: imgIconCOD,
      },
      changeValueOnly: true,
      mode,
      disabled: disabledSwitch,
      // additionalComponent: this._renderTextLabel('ใช้งานไม่ได้เนื่องจากออเดอร์เสร็จสิ้นแล้ว'),
    })
  }

  _renderSwitchCard = ({
    order,
    key,
    value,
    txtLabel,
    txtLabelStyle = null,
    txtHelp = null,
    disabled,
    additionalComponent = null,
    prefixIcon = null,
    prefixIconImg = null,
    changeValueOnly = false,
    mode = null,
  }) => {
    // const { onEditingOrderChange } = this.props
    const { orderTabIndex } = this.state
    return (
      <SwitchCard
        disabled={disabled}
        txtLabel={txtLabel}
        txtHelp={txtHelp}
        switchValue={value || false}
        onToggle={(newValue) => {
          changeValueOnly
            ? this._handleOrderOptionChangeValueOnly(order, key, newValue, orderTabIndex)
            : this._handleOrderOptionChange(order, key, newValue, txtLabel)
        }}
        additionalComponent={additionalComponent}
        prefixIcon={prefixIcon}
        prefixIconImg={prefixIconImg}
        mode={mode}
        txtLabelStyle={txtLabelStyle}
      />
    )
  }

  _renderReceiverAddress = () => {
    const { txtReceiverAddress, txtReceiverName } = this.state
    return (
      <XCard
        w='full'
        // onPress={ () => this._pickAddress('receiver') }
        onPress={() => this._renderReceiverActionSheet()}>
        <XCard.Body>
          <HStack justifyContent='space-between' alignItems='center' space='1'>
            <VStack flex={1} space='2'>
              <HStack space='1'>
                <Icon name='person' style={{ fontSize: FONT_ICON_NORMAL, color: TEXT_INACTIVE }} />
                <XText variant={txtReceiverName ? 'active' : 'inactive'}>{txtReceiverName || 'กรุณาเลือกลูกค้า'}</XText>
              </HStack>
              <HStack space='1'>
                <Icon name='pin' style={{ fontSize: FONT_ICON_NORMAL, color: TEXT_INACTIVE }} />
                <XText variant={txtReceiverAddress ? 'active' : 'inactive'}>{txtReceiverAddress || '-'}</XText>
              </HStack>
            </VStack>
            <XIcon name='arrow-forward' />
          </HStack>
        </XCard.Body>
      </XCard>
    )
  }

  _renderCreateBillingAddressCard = () => {
    const { txtBillingAddress, txtBillingName } = this.state
    return (
      <XCard w='full' onPress={() => this._renderBillingAddressActionSheet()}>
        <XCard.Body>
          <HStack justifyContent='space-between' alignItems='center' space='1'>
            <VStack flex={1} space='2'>
              <HStack space='1'>
                <Icon name='person' style={{ fontSize: FONT_ICON_NORMAL, color: TEXT_INACTIVE }} />
                <XText variant={txtBillingName ? 'active' : 'inactive'}>{txtBillingName || 'กรุณาเลือกลูกค้า'}</XText>
              </HStack>
              <HStack space='1'>
                <Icon name='pin' style={{ fontSize: FONT_ICON_NORMAL, color: TEXT_INACTIVE }} />
                <XText variant={txtBillingAddress ? 'active' : 'inactive'}>{txtBillingAddress || '-'}</XText>
              </HStack>
            </VStack>
            <XIcon name='arrow-forward' />
          </HStack>
        </XCard.Body>
      </XCard>
    )
  }

  _renderFindDupByReceiverAddr = () => {
    const { txtReceiverName, addressesListOverlay, shallCheckForDuplicateReceiverAddr, customCreateOrder } = this.state
    if (_.isNil(txtReceiverName) || txtReceiverName === '' || shallCheckForDuplicateReceiverAddr) {
      return null
    }
    // console.log('customCreateOrder / => ', customCreateOrder)
    const checkDupReceiverAddr = _.has(customCreateOrder, 'check_dup_receiver_addr') ? customCreateOrder.check_dup_receiver_addr : true
    // console.log('checkDupReceiverAddr / => ', checkDupReceiverAddr)
    if (!checkDupReceiverAddr) {
      return null
    }

    if (_.isNil(addressesListOverlay)) {
      return (
        <HStack alignItems='center' justifyContent='flex-end' space='1' mt='2'>
          <XIcon variant='success' size='xs' name='checkcircle' family='AntDesign' />
          <XText variant='success'>ที่อยู่ผู้รับไม่ซ้ำ</XText>
          <HelpButton message='ไม่พบออเดอร์ที่ถูกสร้างใน 30 วันที่ผ่านมา ที่ผู้รับมี ชื่อ รหัสไปรษณีย์ หรือ เบอร์โทร ซ้ำ' />
        </HStack>
      )
    }
    return (
      <HStack alignItems='center' justifyContent='flex-end' space='1' mt='2'>
        <XIcon variant='danger' size='xs' name='warning' family='FontAwesome' />
        <XText variant='danger'>ที่อยู่ผู้รับซ้ำ</XText>

        <HelpButton message='พบออเดอร์ที่ถูกสร้างใน 30 วันที่ผ่านมา ที่ผู้รับมี ชื่อ รหัสไปรษณีย์ หรือ เบอร์โทร ซ้ำ' />
        <XButton
          variant='outline'
          size='xs'
          onPress={() => xUtil.setStatePromise(this, { isVisibleAddressesListOverlay: true })}
          style={{
            // height: 25,
            // width: 80,
            backgroundColor: COLORS.BG_LIGHT_GREY,
            borderColor: COLORS.APP_MAIN,
            borderRadius: 7,
            borderWidth: 1,
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          ดูออเดอร์ที่ซ้ำ
        </XButton>
      </HStack>
    )
  }

  _renderReceiverActionSheet = async (focusedOrder?: ImmutableObject<any>) => {
    const { selectedStore, selectedAddress, editingOrder } = this.props
    // const selectedStoreID = navigation.state.params.store_id || null
    const { mode, store_id } = this.state
    const selectedStoreID = store_id || null
    const order = focusedOrder || editingOrder

    let rAddr = order.get('receiver') ? order.get('receiver') : Map({})
    if (mode === CREATE) {
      rAddr = selectedAddress.get('receiver') ? selectedAddress.get('receiver') : Map({})
    }

    const address_id = rAddr.get('id') || null
    // FIXME: should be Address interface
    let initAddress: any = {}

    if (!address_id) {
      initAddress.name = rAddr.get('name') || ''
      initAddress.address3 = rAddr.get('address3') || ''
      initAddress.address2 = rAddr.get('address2') || ''
      initAddress.sub_district = rAddr.get('sub_district') || ''
      initAddress.district = rAddr.get('district') || ''
      initAddress.province = rAddr.get('province') || ''
      // initAddress.postal_code = rAddr.get('postal_code') || ''
      // initAddress.telephone = rAddr.get('telephone') || ''
      // initAddress.email = rAddr.get('email') || ''
      initAddress.postal_code = rAddr.get('postal_code') || ''
      initAddress.postal_code = initAddress.postal_code.toString()
      initAddress.telephone = rAddr.get('telephone') || ''
      initAddress.email = rAddr.get('email') || ''
      initAddress.legal_entity_type = rAddr.get('legal_entity_type') || 0
      initAddress.legal_entity_id = rAddr.get('legal_entity_id') || ''
      initAddress.branch_type = rAddr.get('branch_type') || null
      initAddress.branch_number = rAddr.get('branch_number') || null
    } else if (address_id) {
      // const order = focusedOrder || editingOrder
      // const recObj = order.get('receiver')
      // initAddress.name = rAddr.get('name') || ''
      // initAddress.address3 = rAddr.get('address3') || ''
      // if (rAddr.get('address1') && rAddr.get('address3') === null) {
      //   const address1 = rAddr.get('address1')
      //   const address2 = rAddr.get('address2')
      //   const sub_district = rAddr.get('sub_district')
      //   const district = rAddr.get('district')
      //   const province = rAddr.get('province')
      //   let txtFullAddress = ''
      //   txtFullAddress = address1
      //   txtFullAddress = address2 ? `${txtFullAddress} ${address2}` : txtFullAddress
      //   txtFullAddress = sub_district ? `${txtFullAddress} ${sub_district}` : txtFullAddress
      //   txtFullAddress = district ? `${txtFullAddress} ${district}` : txtFullAddress
      //   txtFullAddress = province ? `${txtFullAddress} ${province}` : txtFullAddress
      //   initAddress.address3 = txtFullAddress
      initAddress = xUtil.convertNormalAddressToQuickAddress(rAddr)
      // }

      // initAddress.postal_code = rAddr.get('postal_code') || ''
      // initAddress.postal_code = initAddress.postal_code.toString()
      // initAddress.telephone = rAddr.get('telephone') || ''
      // initAddress.email = rAddr.get('email') || ''
      // initAddress.legal_entity_type = rAddr.get('legal_entity_type') || 0
      // initAddress.legal_entity_id = rAddr.get('legal_entity_id') || ''

      // initAddress['postal_code'] = initAddress['postal_code'].toString() // Fixed Numeric type of postal code
    }

    // const BUTTONS = [
    //   { text: 'จากบัญชีรายชื่อ', icon: null },
    //   { text: 'กรอกใหม่ (ใช้ครั้งเดียว ไม่บันทึก)', icon: null },
    //   { text: 'ยกเลิก', icon: null },
    // ]

    // const BUTTONS1 = ['บัญชีรายชื่อ (บันทึกไว้ใช้ซ้ำ)', 'กรอกใช้ครั้งเดียว', 'วางที่อยู่ก้อนเดียว (บรรทัดแรกเป็นชื่อ)', 'วางที่อยู่จาก SHOPEE', 'ยกเลิก']
    // const BUTTONS2 = ['บัญชีรายชื่อ', 'แก้ไขใช้ครั้งเดียว', 'วางที่อยู่ด่วน (บรรทัดแรกเป็นชื่อ)', 'วางที่อยู่จาก SHOPEE', 'คัดลอก', 'ยกเลิก']
    // const TITLE1 = 'เลือกที่อยู่ลูกค้าจาก...'
    // const TITLE2 = 'แก้ไขที่อยู่ลูกค้าจาก...'
    // let sheetTitle
    // let buttonLabels
    // const receiver = editingOrder.get('receiver') || null
    // if (!this.state.txtReceiverName
    //   && !this.state.txtReceiverAddress
    //   && (_.isEmpty(initAddress) || !initAddress.name.length === 0)) {
    //   buttonLabels = BUTTONS1
    //   sheetTitle = TITLE1
    // } else {
    //   buttonLabels = BUTTONS2
    //   sheetTitle = TITLE2
    // }
    //
    let actionProps
    if (!_.isEmpty(initAddress) && initAddress.name.length !== 0) {
      // buttonLabels = BUTTONS2
      // sheetTitle = TITLE2
      actionProps = RECEIVER_ADDR_ACTIONS.EDIT
    } else {
      // buttonLabels = BUTTONS1
      // sheetTitle = TITLE1
      actionProps = RECEIVER_ADDR_ACTIONS.ADD_NEW
    }

    const isProfilleAddressHide = !xUtil.isProfileAddressInUsed()
    const isEditMode = !_.isEmpty(initAddress) && initAddress.name.length !== 0
    // const {
    //   CLONE_ADDRESS_FROM_RECEIVER,
    //   CHOOSE_STORE_CONTACT_ADDRESS,
    //   CHOOSE_CUSTOMER_ADDRESS,
    //   USE_QUICK_ADDRESS,
    //   CLIPBOARD_PASTE,
    //   COPY,
    //   CANCEL,
    // } = AS_PICK_ADDRESS_TITLES

    let title: string
    const asOptions: string[] = []
    // @ts-ignore
    const optKeyToIndex: { [optKey in keyof typeof AS_PICK_ADDRESS_TITLES]: number } = {}
    let i = 0
    let j = 1

    if (isEditMode) {
      title = 'แก้ไขที่อยู่ลูกค้าจาก...'
      //   asOptions = [CHOOSE_STORE_CONTACT_ADDRESS, CHOOSE_CUSTOMER_ADDRESS, USE_QUICK_ADDRESS, CLIPBOARD_PASTE, COPY, CANCEL]
      //   optKeyToIndex = {
      //     CHOOSE_STORE_CONTACT_ADDRESS: 0,
      //     CHOOSE_CUSTOMER_ADDRESS: 1,
      //     USE_QUICK_ADDRESS: 2,
      //     CLIPBOARD_PASTE: 3,
      //     COPY: 4,
      //     CANCEL: 5,

      //     // unused
      //     CLONE_ADDRESS_FROM_RECEIVER: 100,
      //   }

      for (const asKey of AS_PICK_ADDRESS_KEY_SEQUENCES) {
        if (_.includes(['CLONE_ADDRESS_FROM_RECEIVER'], asKey)) {
          optKeyToIndex[asKey] = 100 + j++
          continue
        }

        if (isProfilleAddressHide && asKey === 'CHOOSE_CUSTOMER_ADDRESS') {
          optKeyToIndex[asKey] = 100 + j++
          continue
        }

        if (_.includes(['CHOOSE_STORE_CONTACT_ADDRESS'], asKey) && !xAcl.canDoAtSelectedStore(xCONS.PERM_STORE_HELPER.CONTACT_LIST)) {
          optKeyToIndex[asKey] = 100 + j++
          continue
        }

        asOptions.push(AS_PICK_ADDRESS_TITLES[asKey])
        optKeyToIndex[asKey] = i++
      }
    } else {
      title = 'เลือกที่อยู่ลูกค้าจาก...'
      // asOptions = [CHOOSE_STORE_CONTACT_ADDRESS, CHOOSE_CUSTOMER_ADDRESS, USE_QUICK_ADDRESS, CLIPBOARD_PASTE, CANCEL]
      // optKeyToIndex = {
      //   CHOOSE_STORE_CONTACT_ADDRESS: 0,
      //   CHOOSE_CUSTOMER_ADDRESS: 1,
      //   USE_QUICK_ADDRESS: 2,
      //   CLIPBOARD_PASTE: 3,
      //   CANCEL: 4,

      //   // unused
      //   COPY: 100,
      //   CLONE_ADDRESS_FROM_RECEIVER: 101,
      // }

      let i = 0
      for (const asKey of AS_PICK_ADDRESS_KEY_SEQUENCES) {
        if (_.includes(['CLONE_ADDRESS_FROM_RECEIVER', 'COPY'], asKey)) {
          continue
        }

        if (isProfilleAddressHide && asKey === 'CHOOSE_CUSTOMER_ADDRESS') {
          continue
        }

        if (_.includes(['CHOOSE_STORE_CONTACT_ADDRESS'], asKey) && !xAcl.canDoAtSelectedStore(xCONS.PERM_STORE_HELPER.CONTACT_LIST)) {
          optKeyToIndex[asKey] = 100 + j++
          continue
        }

        asOptions.push(AS_PICK_ADDRESS_TITLES[asKey])
        optKeyToIndex[asKey] = i++
      }
    }

    p.op.showActionSheet(
      {
        // options: actionProps.BUTTONS,
        // cancelButtonIndex: actionProps.ACTION_INDEXES.CANCEL,
        // // destructiveButtonIndex: null,
        // title: actionProps.TITLE,
        options: asOptions,
        cancelButtonIndex: optKeyToIndex.CANCEL,
        // destructiveButtonIndex: null,
        title,
      },
      async (buttonIndex: string | number) => {
        await delay(200)
        const btnIdx = _.isString(buttonIndex) ? parseInt(buttonIndex) : buttonIndex
        // log('buttonIndex => ', buttonIndex.toString())
        switch (btnIdx) {
          case optKeyToIndex.CHOOSE_STORE_CONTACT_ADDRESS:
            if (mode !== CREATE) {
              const thisOrder = this._getFocusedOrder()
              if (this._isLockedOrderShowAlert(thisOrder)) {
                break
              }
            }
            this._pickStoreContactAddress('receiver')
            break

          // case actionProps.ACTION_INDEXES.CHOOSE:
          case optKeyToIndex.CHOOSE_CUSTOMER_ADDRESS:
            if (mode !== CREATE) {
              const thisOrder = this._getFocusedOrder()
              if (this._isLockedOrderShowAlert(thisOrder)) {
                break
              }
            }
            this._pickAddress('receiver')
            break
          // case actionProps.ACTION_INDEXES.QUICK:
          case optKeyToIndex.USE_QUICK_ADDRESS:
            const thisOrder = this._getFocusedOrder()
            if (mode !== CREATE) {
              if (this._isLockedOrderShowAlert(thisOrder)) {
                break
              }
            }
            // const pp_shippingTypeIndex = xCONS.ORDER_VIEW_SHIPPING_TYPE_ITEMS.findIndex(
            //   (shippingTypeId) => shippingTypeId.value === thisOrder.toJS().shipping_type_id
            // )
            // this.setState({ pickingKey: 'receiver' }, () => {
            //   // navigation.dispatch(
            //   //   NavActions.navToOrderQuickAddress({
            //   //     initAddress,
            //   //     mode,
            //   //     store_id: selectedStoreID,
            //   //     shippingTypeIndex: pp_shippingTypeIndex,
            //   //     type: 'RECEIVER', // hardcode
            //   //   }),
            //   // )
            //   this.navToQuickAddressView({
            //     initAddress,
            //     mode,
            //     store_id: selectedStoreID,
            //     shippingTypeIndex: pp_shippingTypeIndex,
            //     type: 'RECEIVER', // hardcode
            //   })
            // })
            this.navToQuickAddressView('receiver', initAddress)
            break
          // case actionProps.ACTION_INDEXES.PASTE_ONE:
          case optKeyToIndex.CLIPBOARD_PASTE:
            // console.log('PASTE_ONE =>')
            const text = await Clipboard.getString()
            const txtAddr = text.trim()
            const quickAddress = this._parseFirstLineNameAddressToQuickAddress(txtAddr)
            const requiredFields = ['name', 'address3']
            const requiredDisplays = ['ชื่อ', 'ที่อยู่']
            const checked = xUtil.checkRequireFields(requiredFields, requiredDisplays, quickAddress)
            // console.log('quickAddress => ', quickAddress)
            // console.log('checked => ', checked)
            // await this._findDupByReceiverAddr({
            //   store_id: this.props.selectedStore.get('id'),
            //   name: quickAddress.name,
            //   postal_code: quickAddress.postal_code,
            //   telephone: quickAddress.telephone,
            // })
            if (checked.status) {
              if (mode === CREATE) {
                // FIXME: should be IAddress interface
                const newAddress: any = {}
                newAddress.receiver = quickAddress

                if (!quickAddress.postal_code) {
                  // separately check for the 'required' postal_code as the regex could miss it.
                  // simply tell user and nav to quick address to correct it
                  // this._handleErrMissingPostalCode(quickAddress)
                  this._handleErrMissingPostalCode('receiver', quickAddress)
                  break
                }

                const thisOrder = this._getFocusedOrder()
                const shipping_type_id = thisOrder.get('shipping_type_id')
                if (!quickAddress.telephone && _.includes(xCONS.TEL_NUM_REQUIRED_FOR_SHIPPING_TYPE_IDS, shipping_type_id)) {
                  this._handleErrMissingTelephone('receiver', quickAddress)
                  break
                }

                this.props.onChangeAddress({ ...newAddress })
                await delay(100) // waiting for redux setter
                this._onReceiveNewAddress('receiver', this.props.selectedAddress.get('receiver'))
              } else {
                const thisOrder = this._getFocusedOrder()

                if (this._isLockedOrderShowAlert(thisOrder)) {
                  break
                }

                // const pp_shippingTypeIndex = xCONS.ORDER_VIEW_SHIPPING_TYPE_ITEMS.findIndex(
                //   (shippingTypeId) => shippingTypeId.value === thisOrder.toJS().shipping_type_id
                // )
                // // navigation.dispatch(
                // //   NavActions.navToOrderQuickAddress({
                // //     initAddress: quickAddress,
                // //     mode,
                // //     store_id: selectedStoreID,
                // //     shippingTypeIndex: pp_shippingTypeIndex,
                // //     type: 'RECEIVER', // hardcode
                // //   }),
                // // )
                // this.navToQuickAddressView({
                //   initAddress: quickAddress,
                //   mode,
                //   store_id: selectedStoreID,
                //   shippingTypeIndex: pp_shippingTypeIndex,
                //   type: 'RECEIVER', // hardcode
                // })
                this.navToQuickAddressView('receiver', initAddress)
              }
            } else {
              p.op.alert(
                'ไม่มีที่อยู่ หรือ รูปแบบไม่ถูกต้อง',
                'กรุณาคัดลอกที่อยู่ ในรูปแบบเช่น\n\n ' +
                  'แม่น้องแอร์รี่ (083)565-8745\n' +
                  '123 ถ.พระราม 5 เขตบึงกุ่ม กทม 12346\n\nหรือ\n\n' +
                  'คุณประเสริฐ ผลิตภัณฑ์การชั่ง\n' +
                  '456 ถนนสาทรใต้ แขวงทุ่งมหาเมฆ\n' +
                  'เขตสาทร กรุงเทพ 10120 (089-1234756)'
              )
            }
            // log('parseFirstLineNameAddressToQuickAddress => ', quickAddress)
            break
          // case actionProps.ACTION_INDEXES.PASTE_SHOPEE:
          //   Clipboard.getString().then(text => {
          //     const txtAddr = text.trim()
          //     let quickAddress = xUtil.parseShopeeAddressToQuickAddress(txtAddr)
          //     const requiredFields = ['name', 'address3', 'telephone', 'postal_code']
          //     const requiredDisplays = ['ชื่อ', 'ที่อยู่', 'เบอร์โทรศัพท์', 'รหัสไปรษณีย์']
          //     const checked = xUtil.checkRequireFields(requiredFields, requiredDisplays, quickAddress)
          //     if (checked.status) {
          //       if (mode === CREATE) {
          //         let newAddress = {}
          //         newAddress['receiver'] = quickAddress
          //         this.props.onChangeAddress({ ...newAddress })
          //         this._onReceiveNewAddress('receiver', this.props.selectedAddress.get('receiver'))
          //       } else {
          //         navigation.dispatch(
          //           NavActions.navToOrderQuickAddress({
          //             initAddress: quickAddress,
          //             mode,
          //             store_id: selectedStoreID,
          //             type: 'RECEIVER', // hardcode
          //           })
          //         )
          //       }
          //     } else {
          //       Alert.alert('ไม่มีที่อยู่ หรือ รูปแบบไม่ถูกต้อง', 'กรุณากดปุ่ม "คัดลอก" ในช่อง "ที่อยู่ในการจัดส่ง" จาก SHOPEE แล้วมากดปุ่มนี้อีกครั้ง')
          //     }
          //     log('parseShopeeAddressToQuickAddress => ', quickAddress)
          //
          //   })
          //   break
          // case actionProps.ACTION_INDEXES.COPY:
          case optKeyToIndex.COPY:
            // const order = focusedOrder || editingOrder
            const receiver = order.getIn(['selectedAddress', 'receiver']) || null
            const txtReceiver = this._receiverParseToTextDetail(receiver, null)
            if (txtReceiver) {
              Clipboard.setString(txtReceiver)
              p.op.showToast('คัดลอกชื่อพร้อมที่อยู่ของผู้รับแล้ว', 'success')
            }
            break
          default:
            console.log('Cancel button was pressed ')
        }
        // this.setState({ clicked: BUTTONS[buttonIndex] })
      }
    )
  }

  _pickStoreContactAddress = (key: 'receiver' | 'billing_address') => {
    const { navigation, onChangeAddress, editReceiverAddress } = this.props
    const { store_id, mode } = this.state

    if (key === 'receiver') {
      this.setState({ pickingKey: key }, () => {
        navigation.dispatch(
          NavActions.navToStoreContactListView({
            store_id,
            title: 'เลือกที่อยู่ผู้รับ',
            contactDetailTitle: 'เลือกที่อยู่ผู้รับ',
            onAddressCardPress: this._succeedPickReceiverAddress,
          })
        )
      })
    } else if (key === 'billing_address') {
      this.setState({ pickingKey: key }, () => {
        navigation.dispatch(
          NavActions.navToStoreContactListView({
            store_id,
            title: 'เลือกที่อยู่ออกใบกำกับภาษี',
            contactDetailTitle: 'เลือกที่อยู่ออกใบกำกับภาษี',
            onAddressCardPress: this._succeedPickBillingAddress,
          })
        )
      })
    } else {
      console.log('_pickStoreContactAddress:: No match key.')
    }
  }

  _renderBillingAddressActionSheet = async (focusedOrder?: ImmutableObject<any>) => {
    const { selectedStore, selectedAddress, editingOrder, onChangeAddress } = this.props
    // const selectedStoreID = navigation.state.params.store_id || null
    const { mode, store_id } = this.state
    const order = focusedOrder || editingOrder
    const selectedStoreID = store_id || null
    let bAddr = order && order.get('billing_address') ? order.get('billing_address') : Map({})

    if (mode === CREATE) {
      bAddr = selectedAddress.get('billing_address') || Map({})
    }

    // console.log('bAddr.toJS() => ', bAddr.toJS())

    const address_id = bAddr.get('id') || null
    // FIXME: should be Address interface
    let initAddress: any = {}

    if (!address_id) {
      initAddress.name = bAddr.get('name') || ''
      initAddress.address3 = bAddr.get('address3') || ''
      initAddress.address2 = bAddr.get('address2') || ''
      initAddress.sub_district = bAddr.get('sub_district') || ''
      initAddress.district = bAddr.get('district') || ''
      initAddress.province = bAddr.get('province') || ''
      // initAddress.postal_code = bAddr.get('postal_code') || ''
      // initAddress.telephone = bAddr.get('telephone') || ''
      // initAddress.email = bAddr.get('email') || ''
      initAddress.postal_code = bAddr.get('postal_code') || ''
      initAddress.postal_code = initAddress.postal_code.toString()
      initAddress.telephone = bAddr.get('telephone') || ''
      initAddress.email = bAddr.get('email') || ''
      initAddress.legal_entity_type = bAddr.get('legal_entity_type') || 0
      initAddress.legal_entity_id = bAddr.get('legal_entity_id') || ''
      initAddress.branch_type = bAddr.get('branch_type') || null
      initAddress.branch_number = bAddr.get('branch_number') || null
    } else if (address_id) {
      // initAddress.name = bAddr.get('name') || ''
      // initAddress.address3 = bAddr.get('address3') || ''
      // if (bAddr.get('address1') && bAddr.get('address3') === null) {
      //   const address1 = bAddr.get('address1')
      //   const address2 = bAddr.get('address2')
      //   const sub_district = bAddr.get('sub_district')
      //   const district = bAddr.get('district')
      //   const province = bAddr.get('province')
      //   let txtFullAddress = ''
      //   txtFullAddress = address1
      //   txtFullAddress = address2 ? `${txtFullAddress} ${address2}` : txtFullAddress
      //   txtFullAddress = sub_district ? `${txtFullAddress} ${sub_district}` : txtFullAddress
      //   txtFullAddress = district ? `${txtFullAddress} ${district}` : txtFullAddress
      //   txtFullAddress = province ? `${txtFullAddress} ${province}` : txtFullAddress
      //   initAddress.address3 = txtFullAddress
      // }

      // initAddress.postal_code = bAddr.get('postal_code') || ''
      // initAddress.postal_code = initAddress.postal_code.toString()
      // initAddress.telephone = bAddr.get('telephone') || ''
      // initAddress.email = bAddr.get('email') || ''
      // initAddress.legal_entity_type = bAddr.get('legal_entity_type') || 0
      // initAddress.legal_entity_id = bAddr.get('legal_entity_id') || ''
      // // initAddress['postal_code'] = initAddress['postal_code'].toString() // Fixed Numeric type of postal code

      initAddress = xUtil.convertNormalAddressToQuickAddress(bAddr)
    }

    // console.log('initAddress => ', initAddress)

    let actionProps
    if (!_.isEmpty(initAddress) && initAddress.name.length !== 0) {
      // buttonLabels = BUTTONS2
      // sheetTitle = TITLE2
      actionProps = BILLING_ADDR_ACTIONS.EDIT
    } else {
      // buttonLabels = BUTTONS1
      // sheetTitle = TITLE1
      actionProps = BILLING_ADDR_ACTIONS.ADD_NEW
    }

    const isProfilleAddressHide = !xUtil.isProfileAddressInUsed()
    const isEditMode = !_.isEmpty(initAddress) && initAddress.name.length !== 0
    let title: string
    const asOptions: string[] = []
    // @ts-ignore
    const optKeyToIndex: { [optKey in keyof typeof AS_PICK_ADDRESS_TITLES]: number } = {}
    let i = 0
    let j = 1

    if (isEditMode) {
      title = 'แก้ไขที่อยู่ใบกำกับภาษีจาก...'
      for (const asKey of AS_PICK_ADDRESS_KEY_SEQUENCES) {
        // if (_.includes(['CLONE_ADDRESS_FROM_RECEIVER'], asKey)) {
        //   continue
        // }

        if (isProfilleAddressHide && asKey === 'CHOOSE_CUSTOMER_ADDRESS') {
          optKeyToIndex[asKey] = 100 + j++
          continue
        }

        if (_.includes(['CHOOSE_STORE_CONTACT_ADDRESS'], asKey) && !xAcl.canDoAtSelectedStore(xCONS.PERM_STORE_HELPER.CONTACT_LIST)) {
          optKeyToIndex[asKey] = 100 + j++
          continue
        }

        asOptions.push(AS_PICK_ADDRESS_TITLES[asKey])
        optKeyToIndex[asKey] = i++
      }
    } else {
      title = 'เลือกที่อยู่ใบกำกับภาษีจาก...'
      let i = 0
      for (const asKey of AS_PICK_ADDRESS_KEY_SEQUENCES) {
        if (_.includes(['COPY'], asKey)) {
          optKeyToIndex[asKey] = 100 + j++
          continue
        }

        if (isProfilleAddressHide && asKey === 'CHOOSE_CUSTOMER_ADDRESS') {
          optKeyToIndex[asKey] = 100 + j++
          continue
        }

        if (_.includes(['CHOOSE_STORE_CONTACT_ADDRESS'], asKey) && !xAcl.canDoAtSelectedStore(xCONS.PERM_STORE_HELPER.CONTACT_LIST)) {
          optKeyToIndex[asKey] = 100 + j++
          continue
        }

        asOptions.push(AS_PICK_ADDRESS_TITLES[asKey])
        optKeyToIndex[asKey] = i++
      }
    }

    p.op.showActionSheet(
      {
        // options: actionProps.BUTTONS,
        // cancelButtonIndex: actionProps.ACTION_INDEXES.CANCEL,
        // // destructiveButtonIndex: null,
        // title: actionProps.TITLE,
        options: asOptions,
        cancelButtonIndex: optKeyToIndex.CANCEL,
        // destructiveButtonIndex: null,
        title,
      },
      async (buttonIndex: string | number) => {
        await delay(200)
        const btnIdx = _.isString(buttonIndex) ? parseInt(buttonIndex) : buttonIndex
        // log('buttonIndex => ', buttonIndex.toString())
        switch (btnIdx) {
          // case actionProps.ACTION_INDEXES.CLONE_FROM_RECEIVER: {
          case optKeyToIndex.CLONE_ADDRESS_FROM_RECEIVER: {
            const rAddr = (selectedAddress && selectedAddress.get('receiver')) || Map({})
            const newAddress: any = {}
            newAddress.billing_address = rAddr
            if (mode === CREATE) {
              p.op.showToast('คัดลอกที่อยู่สำหรับใบกำกับภาษีแล้ว', 'success')
              onChangeAddress({ ...newAddress })
              this._onReceiveNewAddress('billing_address', rAddr)
            } else {
              const thisOrder = this._getFocusedOrder()

              if (this._isLockedOrderShowAlert(thisOrder)) {
                break
              }

              // const pp_shippingTypeIndex = xCONS.ORDER_VIEW_SHIPPING_TYPE_ITEMS.findIndex(
              //   (shippingTypeId) => shippingTypeId.value === thisOrder.toJS().shipping_type_id
              // )

              // // const modInitAddress = thisOrder.get('receiver').toJS()
              // // if (modInitAddress.address1 && !modInitAddress.address3) {
              // //   modInitAddress.address3 = modInitAddress.address1
              // //   modInitAddress.address1 = null
              // // }

              // this.navToQuickAddressView({
              //   initAddress: xUtil.convertNormalAddressToQuickAddress(thisOrder.get('receiver')),
              //   mode,
              //   store_id: selectedStoreID,
              //   shippingTypeIndex: pp_shippingTypeIndex,
              //   type: 'BILLING_ADDRESS', // hardcode
              // })
              this.navToQuickAddressView('billing_address', xUtil.convertNormalAddressToQuickAddress(thisOrder.get('receiver')))
            }

            break
          }
          case optKeyToIndex.CHOOSE_STORE_CONTACT_ADDRESS: {
            if (mode !== CREATE) {
              const thisOrder = this._getFocusedOrder()
              if (this._isLockedOrderShowAlert(thisOrder)) {
                break
              }
            }

            this._pickStoreContactAddress('billing_address')
            break
          }
          // case actionProps.ACTION_INDEXES.CHOOSE:
          case optKeyToIndex.CHOOSE_CUSTOMER_ADDRESS: {
            if (mode !== CREATE) {
              const thisOrder = this._getFocusedOrder()
              if (this._isLockedOrderShowAlert(thisOrder)) {
                break
              }
            }

            this._pickAddress('billing_address')
            break
          }
          // case actionProps.ACTION_INDEXES.QUICK:
          case optKeyToIndex.USE_QUICK_ADDRESS:
            const thisOrder = this._getFocusedOrder()
            if (mode !== CREATE) {
              if (this._isLockedOrderShowAlert(thisOrder)) {
                break
              }
            }
            // const pp_shippingTypeIndex = xCONS.ORDER_VIEW_SHIPPING_TYPE_ITEMS.findIndex(
            //   (shippingTypeId) => shippingTypeId.value === thisOrder.toJS().shipping_type_id
            // )
            // this.setState({ pickingKey: 'billing_address' }, () => {
            //   this.navToQuickAddressView({
            //     initAddress,
            //     mode,
            //     store_id: selectedStoreID,
            //     shippingTypeIndex: pp_shippingTypeIndex,
            //     type: 'BILLING_ADDRESS', // hardcode
            //   })
            // })
            this.navToQuickAddressView('billing_address', initAddress)
            break
          // case actionProps.ACTION_INDEXES.PASTE_ONE:
          case optKeyToIndex.CLIPBOARD_PASTE:
            if (mode !== CREATE) {
              const thisOrder = this._getFocusedOrder()
              if (this._isLockedOrderShowAlert(thisOrder)) {
                break
              }
            }

            // console.log('PASTE_ONE =>')
            const text = await Clipboard.getString()
            const txtAddr = text.trim()
            const quickAddress = this._parseFirstLineNameAddressToQuickAddress(txtAddr)
            const requiredFields = ['name', 'address3']
            const requiredDisplays = ['ชื่อ', 'ที่อยู่']
            const checked = xUtil.checkRequireFields(requiredFields, requiredDisplays, quickAddress)
            // console.log('quickAddress => ', quickAddress)
            // console.log('checked => ', checked)
            // await this._findDupByReceiverAddr({
            //   store_id: this.props.selectedStore.get('id'),
            //   name: quickAddress.name,
            //   postal_code: quickAddress.postal_code,
            //   telephone: quickAddress.telephone,
            // })
            if (checked.status) {
              if (mode === CREATE) {
                // FIXME: should be IAddress interface
                const newAddress: any = {}

                if (!quickAddress.postal_code) {
                  // separately check for the 'required' postal_code as the regex could miss it.
                  // simply tell user and nav to quick address to correct it
                  this._handleErrMissingPostalCode('billing_address', quickAddress)
                  break
                }

                newAddress.billing_address = quickAddress
                this.props.onChangeAddress({ ...newAddress })
                await delay(100) // waiting for redux setter
                this._onReceiveNewAddress('billing_address', this.props.selectedAddress.get('billing_address'))
              } else {
                // const thisOrder = this._getFocusedOrder()
                // const pp_shippingTypeIndex = xCONS.ORDER_VIEW_SHIPPING_TYPE_ITEMS.findIndex(
                //   (shippingTypeId) => shippingTypeId.value === thisOrder.toJS().shipping_type_id
                // )
                // // navigation.dispatch(
                // //   NavActions.navToOrderQuickAddress({
                // //     initAddress: quickAddress,
                // //     mode,
                // //     store_id: selectedStoreID,
                // //     shippingTypeIndex: pp_shippingTypeIndex,
                // //     type: 'RECEIVER', // hardcode
                // //   }),
                // // )
                // this.navToQuickAddressView({
                //   initAddress: quickAddress,
                //   mode,
                //   store_id: selectedStoreID,
                //   shippingTypeIndex: pp_shippingTypeIndex,
                //   type: 'BILLING_ADDRESS', // hardcode
                // })

                this.navToQuickAddressView('billing_address', quickAddress)
              }
            } else {
              p.op.alert(
                'ไม่มีที่อยู่ หรือ รูปแบบไม่ถูกต้อง',
                'กรุณาคัดลอกที่อยู่ ในรูปแบบเช่น\n\n ' +
                  'แม่น้องแอร์รี่ (083)565-8745\n' +
                  '123 ถ.พระราม 5 เขตบึงกุ่ม กทม 12346\n\nหรือ\n\n' +
                  'คุณประเสริฐ ผลิตภัณฑ์การชั่ง\n' +
                  '456 ถนนสาทรใต้ แขวงทุ่งมหาเมฆ\n' +
                  'เขตสาทร กรุงเทพ 10120 (089-1234756)'
              )
            }
            break
          // case actionProps.ACTION_INDEXES.COPY:
          case optKeyToIndex.COPY:
            // const order = focusedOrder || editingOrder
            // const billing_address = mode === CREATE ?  order.getIn(['selectedAddress', 'billing_address']) : order.getIn(['selectedAddress', 'billing_address'])
            const txtBillingAddress = this._receiverParseToTextDetail(bAddr, null)
            if (txtBillingAddress) {
              Clipboard.setString(txtBillingAddress)
              p.op.showToast('คัดลอกชื่อพร้อมที่อยู่ของผู้รับแล้ว', 'success')
            }
            break
          default:
            log('Cancel button was pressed ')
        }
        // this.setState({ clicked: BUTTONS[buttonIndex] })
      }
    )
  }

  _renderBillOnlineShowAmoint = () => {
    const { selectedStore, editingOrder } = this.props
    const order = this._getFocusedOrder(editingOrder)
    const parent_name = 'แสดงราคาและยอด'
    const SHOW_AMOUNT_KEY = 'show_amount'
    const showAmount = order.has(SHOW_AMOUNT_KEY) ? order.get(SHOW_AMOUNT_KEY) : selectedStore.get('s_bill_online_show_amount')
    return (
      <HStack flexWrap='wrap' alignItems='center' justifyContent='space-between'>
        <HStack flex={7} alignItems='center' space='1'>
          <XIcon name='pricetags' family={ICON_FAMILY.Ionicons} variant='inactive' />
          <XText variant='inactive'>{parent_name}</XText>
          <HelpButton
            title='แสดงราคาและยอด'
            message={`หากเปิดการตั้งค่านี้ ราคาและยอดจะถูกแสดงในลิงก์ออเดอร์ มีผลเฉพาะออเดอร์นี้\n\nหากต้องการตั้งค่าเริ่มต้นสำหรับทุกออเดอร์ สามารถทำได้ที่\nการขาย (หน้าแรก) > ตั้งค่าร้าน (รูปเฟื่องด้านขวาบน) > ลิงก์ออเดอร์`}
          />
        </HStack>
        <HStack flex={3} alignItems='center' justifyContent='flex-end' space='1'>
          {/* <Switch
            trackColor={Platform.OS === 'ios' ? COLORS.APP_MAIN : COLORS.APP_SECONDARY}
            thumbColor={Platform.OS === 'ios' ? null : COLORS.APP_MAIN}
            value={showAmount}
            onValueChange={val =>
              this.props.getCopyLinkOrder({
                body: {
                  store_id: selectedStore.get('id'),
                  order_id: order.get('id'),
                  show_amount: val,
                },
                successCallback: res => this.successOnOffShowAmountBillOnline(SHOW_AMOUNT_KEY, res, val),
                failedCallback: err => p.op.showToast(`เกิดข้อผิดพลาด กรุณาทำรายการใหม่ภายหลัง ${err}`, 'warning'),
              })
            }
          /> */}
          <XSwitch
            // disabled={disabled}
            value={showAmount}
            onValueChange={(val) =>
              this.props.getCopyLinkOrder({
                body: {
                  store_id: selectedStore.get('id'),
                  order_id: order.get('id'),
                  show_amount: val,
                },
                successCallback: (res) => this.successOnOffShowAmountBillOnline(SHOW_AMOUNT_KEY, res, val),
                failedCallback: (err) => p.op.showToast(`เกิดข้อผิดพลาด กรุณาทำรายการใหม่ภายหลัง ${err}`, 'warning'),
              })
            }
          />
        </HStack>
      </HStack>
    )
  }

  _renderPaymentInfoButton = (order) => {
    const { navigation } = this.props
    // const { store_id } = navigation.state.params
    // const order_id = order.get('id') || navigation.state.params.order_id || null
    const store_id = xUtil.getNavParam(this.props, 'store_id')
    const orderId = xUtil.getNavParam(this.props, 'order_id')
    const order_id = order.get('id') || orderId
    const orderType = order.get('type')
    const expDate = this._computeExpiringDateInfo({ order })
    const remainingAmount = parseFloat(order.get('remaining_forecast_amount'))
    let label

    if (remainingAmount === 0) {
      label = ' ไม่มียอดค้างชำระ'
    } else if (remainingAmount > 0) {
      // buyer to pay
      switch (orderType) {
        case 1:
          label = 'ลูกค้า'
          break
        case 2:
          label = 'ตัวแทน'
          break
        case 3:
          label = 'ฉัน'
          break
        default:
          label = ''
          break
      }
      label += 'ต้องชำระ'
    } else if (remainingAmount < 0) {
      // seller to refund
      switch (orderType) {
        case 1:
        case 2:
          label = 'ฉัน'
          break
        case 3:
          label = 'ผู้ขายส่ง'
          break
        default:
          label = ''
          break
      }
      label += 'ต้องคืนเงิน'
    }
    // let amountTotal = order.get('amountTotal') || 0
    // let paidAmount = order.get('remaining_forecast_amount') - amountTotal
    // let tranCost = order.get('transaction_cost')
    // let paidCost = tranCost - paidAmount
    return (
      <XCard
        style={{
          // borderBottomWidth: 0,
          backgroundColor: remainingAmount === 0 ? FORM_SUCCESS_BG : 'transparent',
        }}
        onPress={() => {
          // navigation.dispatch(
          //   NavActions.navToPaymentListView({
          //     store_id,
          //     order_id,
          //     btnRight: this._getPaymentListAction(order),
          //     fromOrderDetail: true,
          //   }),
          //   )
          // this.navToPaymentListView({ store_id, order_id, order, fromOrderDetail: true })
          this.navToPaymentListView({ store_id, order_id, fromOrderDetail: true })
        }}>
        <XCard.Body>
          <HStack space='1'>
            <XIcon name='cash' variant={remainingAmount != 0 ? 'primary' : 'success'} />

            <VStack flex={4}>
              <HStack alignItems='center' space='1'>
                {remainingAmount === 0 ? CheckedIcon : null}
                <VStack>
                  <XText variant='inactive'>{label}</XText>
                  {remainingAmount === 0 ? (
                    <XText variant='inactive' fontSize='xs'>
                      (กดเพื่อดู ประวัติการชำระ)
                    </XText>
                  ) : null}
                </VStack>
              </HStack>
              {expDate.shouldBeVisible ? <XText variant='inactive'>{p.op.t('Order.toBeCancelledWithIn')}</XText> : null}
            </VStack>

            <VStack flex={6} alignItems='flex-end' justifyContent='center'>
              {remainingAmount !== 0 ? (
                <XText
                  style={[
                    // s.txtNormal,
                    // { paddingRight: 6, paddingBottom: 1, paddingTop: 0, marginTop: 0 },
                    // // this._getStyleForPaymentNum(paidAmount, amountTotal),
                    this._getStyleForNum(remainingAmount),
                  ]}>
                  {xFmt.formatCurrency(Math.abs(remainingAmount))}
                </XText>
              ) : null}
              {expDate.shouldBeVisible ? (
                <XText
                  style={[
                    // s.txtNormal, { paddingRight: 6, paddingBottom: 1, paddingTop: 0, marginTop: 0 },
                    expDate.style,
                  ]}>
                  {expDate.text}
                </XText>
              ) : null}
            </VStack>

            <HStack alignItems='center'>
              <XIcon
                name='arrow-forward'
                variant={remainingAmount != 0 ? 'primary' : 'success'}
                // style={{
                //   paddingBottom: expDate.shouldBeVisible ? 3 : 0,
                //   color: remainingAmount != 0 ? COLORS.APP_MAIN : FORM_SUCCESS,
                //   fontSize: FONT_ICON_NORMAL,
                // }}
              />
            </HStack>
          </HStack>
        </XCard.Body>
      </XCard>
    )
  }

  _renderPayments = () => {
    const { selectedOrder, selectedStore, navigation } = this.props
    if (selectedOrder.has('has_ppt') && selectedOrder.get('has_ppt')) {
      const onNav = (pptsSnSelected: IPaymentPartnerTransactions[]) => {
        const store_id = selectedStore.get(`id`)
        navigation.dispatch(
          NavActions.navToPaymentPTTTransactions({
            store_id,
            ptts: pptsSnSelected,
            navBackAction: () => {
              this._onRefresh()
            },
          })
        )
      }
      return (
        <PaymentPTTComponent
          order_id={selectedOrder.get('id')}
          onSelected={(pptsSnSelected: IPaymentPartnerTransactions[]) => {
            onNav(pptsSnSelected)
          }}
        />
      )
    }
    return null
  }

  _renderPrivateNote = () => {
    const order = this._getFocusedOrder()
    const { orderTabIndex } = this.state
    const notes = order.get('notes')
    let showNote = false
    let note
    if (!_.isNil(notes)) {
      note = _.find(notes.toJS(), (myNote) => myNote.type === 3)
    }
    if (!_.isNil(note)) {
      showNote = true
    }
    const parent_name = 'บันทึกช่วยจำส่วนตัว'
    const isLockedOrder = order.get('prevent_mod') || false

    if (!showNote) {
      return (
        <HStack space='1' alignItems='center' justifyContent='space-between'>
          <HStack flex={1} space='1' flexWrap='wrap' alignItems='center'>
            <XText variant='inactive'>{parent_name}</XText>
            <XText variant='inactive' fontSize='xs'>
              (ฉันเห็นคนเดียว)
            </XText>
          </HStack>
          <XIconButton
            name='notebook'
            variant='outline'
            family={ICON_FAMILY.MaterialCommunityIcons}
            onPress={() => {
              // if (isLockedOrder) {
              //   p.op.showToast('ออเดอร์นี้ถูกล็อกอยู่ กรุณาปลดล็อกออเดอร์ก่อนทำรายการนี้ต่อ', 'warning', 2000)
              //   return
              // }

              // this.props.navigation.dispatch(
              //   NavActions.navToOrderPrivateAndPublicNotes({
              //     store_id: this.state.store_id,
              //     mode: this.state.mode,
              //     orderTabIndex,
              //     key: 'PRIVATE',
              //     notes: order.get('notes'),
              //   }),
              // )
              this.navToOrderPrivateAndPublicNotes({
                store_id: this.state.store_id,
                mode: this.state.mode,
                orderTabIndex,
                key: 'PRIVATE',
                notes: order.get('notes'),
              })
            }}
          />
        </HStack>
      )
    }

    return (
      <XCard
        onPress={() => {
          // this.props.navigation.dispatch(
          //   NavActions.navToOrderPrivateAndPublicNotes({
          //     store_id: this.state.store_id,
          //     mode: this.state.mode,
          //     orderTabIndex,
          //     key: 'PRIVATE',
          //     notes: order.get('notes'),
          //   }),
          // )
          this.navToOrderPrivateAndPublicNotes({
            store_id: this.state.store_id,
            mode: this.state.mode,
            orderTabIndex,
            key: 'PRIVATE',
            notes: order.get('notes'),
          })
        }}>
        <XCard.Body>
          <HStack space='1' alignItems='center' justifyContent='space-between'>
            <HStack flex={1} space='1'>
              <XIcon family='MaterialCommunityIcons' name='notebook' />
              <VStack flex={1}>
                <XText variant='inactive'>บันทึกช่วยจำส่วนตัว (ฉันเห็นคนเดียว)</XText>

                <XText numberOfLines={10}>{note.value}</XText>
              </VStack>
            </HStack>

            <XIcon name='arrow-forward' />
          </HStack>
        </XCard.Body>
      </XCard>
    )
  }

  _renderPublicNote = () => {
    const order = this._getFocusedOrder()
    const orderType = order.get('type')
    if (orderType === 1) {
      return null
    }
    const { orderTabIndex } = this.state
    const partner_name = _.isNil(order.get('partner_name')) ? '' : order.get('partner_name')
    const notes = order.get('notes')
    let who = 'ผู้ซื้อ'
    let talker = 'r'

    let showNote = false
    let showMyNote = false
    let showOtherNote = false
    let otherNote
    let myNote

    if (orderType === 3) {
      who = 'ผู้ขาย'
      talker = 's'
    }
    let dateTimeOtherNote
    let dateTimeMyNote
    let updated_at_other
    let updated_at_mynote

    if (!_.isNil(notes)) {
      otherNote = _.find(notes.toJS(), (val) => val.created_by === talker && val.type === 4)
      if (!_.isNil(otherNote)) {
        showOtherNote = true
        updated_at_other = otherNote.updated_at
      }
      myNote = _.find(notes.toJS(), (myNote) => myNote.created_by === 'm' && myNote.type === 4)
      if (!_.isNil(myNote)) {
        showMyNote = true
        updated_at_mynote = myNote.updated_at
      }
    }

    if (!_.isNil(updated_at_other)) {
      dateTimeOtherNote = ` (เมื่อ ${moment(updated_at_other).fromNow()})`
    }
    if (!_.isNil(updated_at_mynote)) {
      dateTimeMyNote = ` (เมื่อ ${moment(updated_at_mynote).fromNow()})`
    }

    const parent_name = `ข้อความกับ${who} ${partner_name}`
    if (showOtherNote || showMyNote) {
      showNote = true
    }
    let miniNoteTxt = `(${partner_name} และ ฉันเห็น)`

    if (partner_name === '') {
      miniNoteTxt = `(${who} และ ฉันเห็น)`
    }

    const isLockedOrder = order.get('prevent_mod') || false

    if (!showNote) {
      return (
        <HStack space='1' alignItems='center' justifyContent='space-between'>
          <HStack flex={1} space='1' flexWrap='wrap' alignItems='center'>
            <XText variant='inactive'>{`ข้อความกับ${who}`}</XText>
            <XText variant='inactive' fontSize='xs'>
              {miniNoteTxt}
            </XText>
          </HStack>
          <XIconButton
            name='mail-outline'
            variant='outline'
            family={ICON_FAMILY.MaterialIcons}
            onPress={() => {
              if (isLockedOrder) {
                p.op.showToast('ออเดอร์นี้ถูกล็อกอยู่ กรุณาปลดล็อกออเดอร์ก่อนทำรายการนี้ต่อ', 'warning', 2000)
                return
              }

              // this.props.navigation.dispatch(
              //   NavActions.navToOrderPrivateAndPublicNotes({
              //     store_id: this.state.store_id,
              //     mode: this.state.mode,
              //     orderTabIndex,
              //     key: 'PUBLIC',
              //     notes: order.get('notes'),
              //   }),
              // )
              this.navToOrderPrivateAndPublicNotes({
                store_id: this.state.store_id,
                mode: this.state.mode,
                orderTabIndex,
                key: 'PUBLIC',
                notes: order.get('notes'),
              })
            }}
          />
        </HStack>
      )
    }

    return (
      <XCard
        onPress={() => {
          // this.props.navigation.dispatch(
          //   NavActions.navToOrderPrivateAndPublicNotes({
          //     store_id: this.state.store_id,
          //     mode: this.state.mode,
          //     orderTabIndex,
          //     key: 'PUBLIC',
          //     notes: order.get('notes'),
          //   }),
          // )
          this.navToOrderPrivateAndPublicNotes({
            store_id: this.state.store_id,
            mode: this.state.mode,
            orderTabIndex,
            key: 'PUBLIC',
            notes: order.get('notes'),
          })
        }}>
        <XCard.Body>
          <HStack space='1' alignItems='center' justifyContent='space-between'>
            <HStack flex={1} space='1'>
              <XIcon family='MaterialIcons' name='mail-outline' />
              <VStack flex={1}>
                {showOtherNote ? (
                  <VStack>
                    <HStack>
                      <XText variant='inactive'>จาก {who} ถึง ฉัน</XText>
                      <XText variant='inactive' fontSize='xs'>
                        {_.isNil(dateTimeOtherNote) ? null : dateTimeOtherNote}
                      </XText>
                    </HStack>
                    <XText numberOfLines={10}>{otherNote.value}</XText>
                  </VStack>
                ) : null}
                {showMyNote ? (
                  <VStack>
                    <HStack>
                      <XText variant='inactive'>จาก ฉัน ถึง {who}</XText>
                      <XText variant='inactive' fontSize='xs'>
                        {_.isNil(dateTimeMyNote) ? null : dateTimeMyNote}
                      </XText>
                    </HStack>
                    <XText numberOfLines={10}>{myNote.value}</XText>
                  </VStack>
                ) : null}
              </VStack>
            </HStack>
            <XIcon name='arrow-forward' />
          </HStack>
        </XCard.Body>
      </XCard>
    )
  }

  _renderShippingInfoButton = ({ order, type, isCancelState }) => {
    // console.log(`_renderShippingInfoButton: ${isCancelState}`)
    const shippings = order.has('shippings') ? order.get('shippings') : null
    if (_.isNil(shippings)) {
      return (
        <HStack>
          <XIcon family='MaterialIcons' name='local-shipping' />
          <XText variant='inactive'>ไม่มีประวัติการจัดส่ง</XText>
        </HStack>
      )
    }
    // 4124325
    // if (isCancelState) {
    //   return (
    //     <HStack>
    //       <XIcon family='MaterialIcons' name='local-shipping' />
    //       <XText variant='inactive'>ยกเลิกการจัดส่ง</XText>
    //     </HStack>
    //   )
    // }
    // console.log('ORDER => ', order.toJS())
    const { navigation } = this.props
    // const { store_id } = navigation.state.params
    const orderId = order.get('id')
    const storeId = xUtil.getNavParam(this.props, 'store_id')

    // const doNavToOrderShipList = () => { p.op.alert('No-Impl _navToOrderShipList orderId => ', orderId) }
    // const doNavToOrderShipList = () => this._navToOrderShipList(orderId)
    const doNavToOrderShipList = () => this.navToOrderShipList({ store_id: storeId, order_id: orderId })
    const shippingInfo = this.getOrderShippingInfo(order)

    if (_.isNull(shippingInfo)) {
      return null
    }

    const { myTotalShippedQtyCount, myTotalOrderedQtyCount, sellerTotalShippedQtyCount, sellerTotalOrderedQtyCount } = shippingInfo

    let myShipingCompleted = false
    let sellerShipingCompleted = false
    let shippingCompleted = false
    let marginLeftForSeller = 24
    if (myTotalShippedQtyCount === myTotalOrderedQtyCount) {
      myShipingCompleted = true
    }
    if (sellerTotalShippedQtyCount === sellerTotalOrderedQtyCount) {
      sellerShipingCompleted = true
      marginLeftForSeller = 27
    }
    if (myShipingCompleted && sellerShipingCompleted) {
      shippingCompleted = true
    }
    return (
      <XCard
        onPress={doNavToOrderShipList}
        // style={{
        //   backgroundColor: shippingCompleted ? FORM_SUCCESS_BG : 'transparent',
        // }}
        style={
          shippingCompleted
            ? {
                backgroundColor: FORM_SUCCESS_BG,
              }
            : {}
        }>
        <XCard.Body>
          <HStack alignItems='center' space='1'>
            <VStack flex={6} justifyContent='center'>
              {myTotalOrderedQtyCount > 0 ? (
                <HStack alignItems='center' space='1'>
                  <XIcon family='MaterialIcons' name='local-shipping' variant={shippingCompleted ? 'success' : 'primary'} />
                  {/* <IconMC name='barcode-scan' style={[s.shippingIcon, { color: shippingCompleted ? FORM_SUCCESS : APP_MAIN }]} /> */}
                  {myShipingCompleted ? CheckedIcon : null}
                  <XText variant='inactive'>ฉันจัดส่งแล้ว</XText>
                </HStack>
              ) : null}
              {sellerTotalOrderedQtyCount > 0 ? (
                <HStack alignItems='center' space='1'>
                  {/* <Icon type='MaterialIcons'
                    name="local-shipping"
                    style={[s.shippingIcon, { color: shippingCompleted ? FORM_SUCCESS : APP_MAIN }]}
                  /> */}
                  {/* {myTotalOrderedQtyCount > 0 ? null : (
                    <IconMC name='barcode-scan' style={[s.shippingIcon, { color: shippingCompleted ? FORM_SUCCESS : APP_MAIN }]} />
                  )} */}
                  {myTotalOrderedQtyCount > 0 ? null : (
                    <XIcon
                      family='MaterialIcons'
                      name='local-shipping'
                      variant={shippingCompleted ? 'success' : 'primary'}
                      // style={StyleSheet.flatten([s.shippingIcon, { color: shippingCompleted ? FORM_SUCCESS : APP_MAIN }])}
                    />
                  )}

                  <XText
                    variant='inactive'
                    // style={[s.txtLabel, { marginLeft: myTotalOrderedQtyCount > 0 ? marginLeftForSeller : -1 }]}
                  >
                    {sellerShipingCompleted ? CheckedIcon : null} ผู้ขายส่งจัดส่งแล้ว
                  </XText>
                </HStack>
              ) : null}
              {myTotalOrderedQtyCount === 0 && sellerTotalOrderedQtyCount === 0 ? (
                <HStack alignItems='center' space='1'>
                  <XIcon
                    family='MaterialIcons'
                    name='local-shipping'
                    variant={shippingCompleted ? 'success' : 'primary'}
                    // style={[s.shippingIcon, { color: shippingCompleted ? FORM_SUCCESS : APP_MAIN }]}
                  />
                  <XText style={s.txtLabel}>ไม่มีสินค้าที่ต้องจัดส่ง</XText>
                </HStack>
              ) : null}
              {myTotalShippedQtyCount > 0 || sellerTotalShippedQtyCount > 0 ? (
                <XText variant='inactive' fontSize='xs'>
                  (กดเพื่อดู เลขติดตามพัสดุ)
                </XText>
              ) : null}
            </VStack>
            <VStack flex={4} justifyContent='center' alignItems='flex-end'>
              {myTotalOrderedQtyCount > 0 ? (
                <XText
                  style={[
                    // s.txtNormal,
                    // { paddingRight: 6, paddingBottom: 1 },
                    this._getStyleForNum(myTotalOrderedQtyCount - myTotalShippedQtyCount),
                  ]}>
                  {myTotalShippedQtyCount} / {myTotalOrderedQtyCount}
                </XText>
              ) : null}
              {sellerTotalOrderedQtyCount > 0 ? (
                <XText
                  style={[
                    // s.txtNormal,
                    // { paddingRight: 6, paddingBottom: 1 },
                    this._getStyleForNum(sellerTotalOrderedQtyCount - sellerTotalShippedQtyCount),
                  ]}>
                  {sellerTotalShippedQtyCount} / {sellerTotalOrderedQtyCount}
                </XText>
              ) : null}
            </VStack>
            <XIcon name='arrow-forward' variant={shippingCompleted ? 'success' : 'primary'} />
          </HStack>
        </XCard.Body>
      </XCard>
    )
  }

  _renderDetailReceiverAddressCard = (order) => {
    // const { mode } = this.state
    // const order_type = order.get('type') || null
    const receiver = order.get('receiver') || null
    // const shipping_state = order.get('shipping_state') || null
    const txtReceiver = this._receiverParseToTextDetail(receiver, null)
    // log('_renderDetailReceiverAddressCard txtReceiver => ', txtReceiver)
    // const editable = order.get(xCONS.ORDER_EDITABLE_KEY)

    // const isEditableAddressState = shipping_state !== 129 && order_type === 1
    // const isEditableAddressState = editable && editable.includes(xCONS.ORDER_EDITABLE.RECEIVER_ADDR)
    // const disabled = this._isOrderCancelled(order)
    // const asOptions = ['คัดลอก', 'แก้ไข', 'ยกเลิก']
    // if (disabled) {
    //   // remove 'แก้ไข' option if the order has been cancelled
    //   asOptions.splice(1, 1)
    // }

    // const cancelIndex = asOptions.length - 1

    // const btnAction = () => {
    //   p.op.showActionSheet(
    //     {
    //       options: asOptions,
    //       cancelButtonIndex: asOptions.length - 1, // Cause to crash
    //       // cancelButtonIndex: cancelIndex,
    //       title: 'ที่อยู่ผู้รับ',
    //
    //       buttonRef: this._receiverCardRef,
    //     },
    //      (buttonIndex) => {
    //       // log('buttonIndex => ', buttonIndex.toString())
    //       const btnIdx = _.isString(buttonIndex) ? parseInt(buttonIndex) : buttonIndex
    //       switch (btnIdx) {
    //         case 0:
    //           if (txtReceiver) {
    //             Clipboard.setString(txtReceiver)
    //             p.op.showToast('คัดลอก ชื่อ ที่อยู่ แล้ว', 'success')
    //           }
    //           break
    //         case 1:
    //           if (mode === VIEW_EDIT) {
    //             this._showConfirmationToEditAddress(order, 'receiver', () => this._renderReceiverActionSheet(order))
    //           } else if (mode === EDIT) {
    //             p.op.alert('กรุณาดำเนินการแก้ไขราคาและสินค้าให้เสร็จสิ้นก่อน')
    //           }
    //           break
    //       }
    //     }
    //   )
    // }

    return (
      <XCard
        w='full'
        onPress={() => this._showActionSheetReceiverAddress(order)}
        // //  @ts-ignore
        // ref={this._receiverCardRef}
      >
        <XCard.Body>
          <HStack w='full' alignItems='center' justifyContent='space-between' space='1'>
            <HStack flex={1} space='1'>
              <XIcon variant={receiver ? 'inactive' : 'primary'} family='FontAwesome' name='home' />
              <VStack flex={1} flexWrap='wrap'>
                <XText variant='inactive'>ผู้รับสินค้า</XText>
                <XText w='full'>{txtReceiver}</XText>
              </VStack>
            </HStack>
            <XIcon
              variant={receiver ? 'inactive' : 'primary'}
              style={{
                fontSize: FONT_ICON_NORMAL,
                color: receiver ? TEXT_INACTIVE : TEXT_PRIMARY,
              }}
              name='arrow-forward'
            />
          </HStack>
        </XCard.Body>
      </XCard>
    )
  }

  _toggleBillingAdressCheckbox = () => {
    const { isBillingAddressUsed = false } = this.state
    this.setState({ isBillingAddressUsed: !isBillingAddressUsed })
  }

  _renderToggleBillingAddressCheckbox = () => {
    const { isBillingAddressUsed = false } = this.state

    return (
      <HStack alignItems='center' space='1'>
        <TouchableOpacity onPress={this._toggleBillingAdressCheckbox}>
          <XIcon name={isBillingAddressUsed ? 'check-square-o' : 'square-o'} family='FontAwesome' />
        </TouchableOpacity>
        <XText onPress={this._toggleBillingAdressCheckbox}>ออกใบกำกับภาษี</XText>
      </HStack>
    )
  }

  _renderDetailBillingAddressCard = (order) => {
    // const { mode } = this.state
    // const order_type = order.get('type') || null
    const receiverAddress = order.get('receiver') || null
    const billing_address = order.get('billing_address') || null

    // if (!billing_address) {
    //   // VIEW MODE ยังไม่ support การเพิ่มทีหลัง
    //   return null
    // }
    if (!receiverAddress && !billing_address) {
      return null
    }

    // const shipping_state = order.get('shipping_state') || null
    const txtReceiver = this._receiverParseToTextDetail(billing_address, null)

    return (
      <XCard w='full' onPress={() => this._renderBillingAddressActionSheet(order)}>
        <XCard.Body>
          <HStack w='full' alignItems='center' justifyContent='space-between' space='1'>
            <HStack flex={1} space='1'>
              <XIcon variant={billing_address ? 'inactive' : 'primary'} family='FontAwesome' name='home' />
              <VStack flex={1} flexWrap='wrap'>
                <XText variant='inactive'>ที่อยู่สำหรับใบกำกับภาษี</XText>
                <XText w='full'>{txtReceiver}</XText>
              </VStack>
            </HStack>
            <XIcon
              variant={billing_address ? 'inactive' : 'primary'}
              style={{
                fontSize: FONT_ICON_NORMAL,
                color: billing_address ? TEXT_INACTIVE : TEXT_PRIMARY,
              }}
              name='arrow-forward'
            />
          </HStack>
        </XCard.Body>
      </XCard>
    )
  }

  _showActionSheetReceiverAddress = (order) => {
    const { mode } = this.state
    const receiver = order.get('receiver') || null
    const txtReceiver = this._receiverParseToTextDetail(receiver, null)
    const disabled = this._isOrderCancelled(order)
    const asOptions = ['คัดลอก', 'แก้ไข', 'ยกเลิก']
    if (disabled) {
      // remove 'แก้ไข' option if the order has been cancelled
      asOptions.splice(1, 1)
    }

    // FIXME: TED Fund Phase 2 // Bypass ActionSheet ให้คักลอกได้อย่างเดียว ถ้าเป็น Maketplaces Order [REMOVE ME]
    const isMarketPlacesOrder = xUtil.isMarketPlacesOrder(order)
    const mkpIsSelfDelivery = order.has('mkp_is_self_delivery') ? order.get('mkp_is_self_delivery') : false
    if (isMarketPlacesOrder && !mkpIsSelfDelivery) {
      if (txtReceiver) {
        Clipboard.setString(txtReceiver)
        p.op.showToast('คัดลอก ชื่อ ที่อยู่ แล้ว', 'success')
      }
      return
    }
    // End Bypass

    p.op.showActionSheet(
      {
        options: asOptions,
        cancelButtonIndex: asOptions.length - 1,
        title: 'ที่อยู่ผู้รับ',

        // buttonRef: this._receiverCardRef,
      },
      (buttonIndex) => {
        // log('buttonIndex => ', buttonIndex.toString())
        const btnIdx = _.isString(buttonIndex) ? parseInt(buttonIndex) : buttonIndex
        switch (btnIdx) {
          case 0:
            if (txtReceiver) {
              Clipboard.setString(txtReceiver)
              p.op.showToast('คัดลอก ชื่อ ที่อยู่ แล้ว', 'success')
            }
            break
          case 1:
            if (!APP_CONFIG.order.view_order.allow_edit_address_receiver) {
              p.op.showConfirmationOkOnly(
                `ไม่สามารถแก้ไขที่อยู่ผู้รับได้`,
                `หากต้องการแก้ไขที่อยู่ผู้รับ กรุณายกเลิกออเดอร์แล้วเปิดออเดอร์ใหม่`
              )
              return
            }
            if (mode === VIEW_EDIT) {
              this._showConfirmationToEditAddress(order, 'receiver', () => this._renderReceiverActionSheet(order))
            } else if (mode === EDIT) {
              p.op.alert('กรุณาดำเนินการแก้ไขราคาและสินค้าให้เสร็จสิ้นก่อน')
            }
            break
        }
      }
    )
  }

  _renderDetailSenderAddressCard = (order: Map<string, any>) => {
    const { mode } = this.state
    if (!order || !isImmutable(order)) {
      return null
    }

    const disabled = this._isOrderCancelled(order)
    const asOptions = ['คัดลอก', 'แก้ไข', 'ยกเลิก']
    if (disabled) {
      // remove 'แก้ไข' option if the order has been cancelled
      asOptions.splice(1, 1)
    }
    // รูปแบบจัดส่ง
    const sender_name = order.get('sender_name') || ''
    // log(sender_name)
    const use_store_addr = order.has('use_store_addr') ? order.get('use_store_addr') : false
    const is_addr_forced = order.has('is_addr_forced') ? order.get('is_addr_forced') : false
    const type = order.get('type') || 1
    const sender = order.get('sender') || null
    const txtFullAddress = this._senderParseToTextDetail(sender, use_store_addr, is_addr_forced, type)

    let btnAction = () => {
      p.op.showActionSheet(
        {
          options: asOptions,
          cancelButtonIndex: asOptions.length - 1,
          title: 'ที่อยู่ผู้ส่ง',

          // buttonRef: this._senderCardRef,
        },
        (buttonIndex) => {
          // log('buttonIndex => ', buttonIndex.toString())
          const btnIdx = _.isString(buttonIndex) ? parseInt(buttonIndex) : buttonIndex
          switch (btnIdx) {
            case 0:
              const txtAddr = this._receiverParseToTextDetail(sender, sender_name)
              if (txtAddr) {
                Clipboard.setString(txtAddr)
                p.op.showToast('คัดลอก ชื่อ ที่อยู่ แล้ว', 'success')
              }
              break
            case 1:
              if (!APP_CONFIG.order.view_order.allow_edit_address_sender) {
                p.op.showConfirmationOkOnly(
                  `ไม่สามารถแก้ไขที่อยู่ผู้ส่งได้`,
                  `หากต้องการแก้ไขที่อยู่ผู้ส่ง กรุณายกเลิกออเดอร์แล้วเปิดออเดอร์ใหม่`
                )
                return
              }
              if (mode === VIEW_EDIT) {
                this._showConfirmationToEditAddress(order, 'sender', () => this._pickAddress('sender'))
              } else if (mode === EDIT) {
                p.op.alert(
                  'กรุณาดำเนินการแก้ไขราคาและสินค้าให้เสร็จสิ้นก่อน แล้วกดปุ่ม ตกลง หรือ ยกเลิก ด้านบน แล้วจึงมาแก้ไขที่อยู่อีกครั้ง'
                )
              }
              break
          }
        }
      )
    }
    // FIXME: TED Fund Phase 2 // Bypass ActionSheet ให้คักลอกได้อย่างเดียว ถ้าเป็น Maketplaces Order [REMOVE ME]
    const isMarketPlacesOrder = xUtil.isMarketPlacesOrder(order)
    const mkpIsSelfDelivery = order.has('mkp_is_self_delivery') ? order.get('mkp_is_self_delivery') : false
    if (isMarketPlacesOrder && !mkpIsSelfDelivery) {
      btnAction = () => {
        const txtAddr = this._receiverParseToTextDetail(sender, sender_name)
        if (txtAddr) {
          Clipboard.setString(txtAddr)
          p.op.showToast('คัดลอก ชื่อ ที่อยู่ แล้ว', 'success')
        }
      }
    }

    // log(txtFullAddress)
    return (
      <XCard
        // // @ts-ignore
        // ref={this._senderCardRef}
        onPress={btnAction}>
        <XCard.Body>
          <HStack alignItems='center' justifyContent='space-between' space='1'>
            <HStack flex={1} space='1'>
              <XIcon variant='inactive' family='MaterialIcons' name='store' />
              <VStack flex={1} space='1'>
                <XText variant='inactive'>จัดส่งในนาม</XText>
                <XText>{sender_name}</XText>
                <XText variant='inactive'>ที่อยู่ผู้ส่ง</XText>
                <XText>{txtFullAddress}</XText>
              </VStack>
            </HStack>
            {disabled ? null : <XIcon variant='inactive' name='arrow-forward' />}
          </HStack>
        </XCard.Body>
      </XCard>
    )
  }

  // _renderDetailShippingTypeCard = (order) => {
  //   const { mode } = this.state
  //   // รูปแบบจัดส่ง
  //   const shipping_type_id = order.get('shipping_type_id') || -1
  //   const shipping_index = _.findIndex(this.shippingTypeItems, sItem => sItem.value === shipping_type_id)
  //   let txtShippingType = null
  //   if (shipping_index > -1) {
  //     txtShippingType = this.shippingTypeItems[shipping_index].label
  //   } else if (shipping_index === -1 && shipping_type_id > 0) {
  //     txtShippingType = 'กรุณาดาวน์โหลดแอปเวอร์ชั่นใหม่ เพื่อแสดงผลข้อมูลนี้'
  //   } else {
  //     txtShippingType = 'ยังไม่ได้เลือก'
  //   }
  //   // log('_renderDetailShippingTypeCard')
  //   // log('shipping_type_id: ' + shipping_type_id)
  //   // log('shipping_index' + shipping_index)
  //   // log('txtShippingType: ' + txtShippingType)

  //   // FIXME: เผื่อเงิ่อนไขเอาไว้สำหรับถ้าหากกดเปลี่ยน รูปแบบจัดส่ง ของ order ได้
  //   const disabled = this._isOrderCancelled(order)

  //   return (
  //     <TouchableOpacity
  //       disabled={disabled}
  //       onPress={() => {
  //         if (mode === VIEW_EDIT) {

  //           // FIXME: Implement me
  //           p.op.alert('No-Impl this._navToPagePicker shipping_type_id => ', shipping_type_id)
  //           // this._navToPagePicker(bpData => {
  //           //   // log('callback _navToPagePicker', bpData)
  //           //   if (!bpData || !bpData.data || !bpData.data.value) {
  //           //     return
  //           //   }
  //           //   const newShippingTypeId = bpData.data.value.value
  //           //   const newShippingTypeLabel = bpData.data.value.label
  //           //   const newShippingTypeIndex = bpData.data.value.index
  //           //   this._handleChangeShippingType(
  //           //     order,
  //           //     this.props.navigation.state.params.store_id,
  //           //     newShippingTypeId,
  //           //     newShippingTypeLabel,
  //           //     newShippingTypeIndex,
  //           //   )
  //           // })

  //         } else if (mode === EDIT) {
  //           p.op.alert('กรุณาดำเนินการแก้ไขราคาและสินค้าให้เสร็จสิ้นก่อน')
  //         }
  //       }}>
  //       <XCard
  //         // @ts-ignore
  //         style={[CARD_COMMON, { marginTop: 10 }]}>
  //         <Box
  //           cardBody={true}
  //           style={[
  //             NO_MARGIN_PADDING,
  //             {
  //               paddingTop: 9,
  //               paddingBottom: 9,
  //               borderBottomWidth: 0,
  //             },
  //           ]}>
  //           <Left
  //             style={[
  //               NO_MARGIN_PADDING,
  //               {
  //                 flex: 1,
  //                 // backgroundColor: 'red',
  //                 flexDirection: 'column',
  //                 justifyContent: 'flex-start',
  //                 alignItems: 'flex-start',
  //                 borderBottomWidth: 0,
  //               },
  //             ]}>
  //             <View
  //               style={{
  //                 flex: 1,
  //                 paddingTop: 3,
  //                 paddingBottom: 3,
  //                 flexDirection: 'row',
  //                 // backgroundColor: 'green',
  //               }}>
  //               <Icon
  //                 type='MaterialIcons'
  //                 name='local-shipping'
  //                 style={[
  //                   s.shippingIcon,
  //                   {
  //                     paddingLeft: 18,
  //                     color: TEXT_INACTIVE,
  //                   },
  //                 ]}
  //               />
  //               <XText
  //                 style={{
  //                   fontSize: FONT_SIZE_NORMAL,
  //                   color: TEXT_INACTIVE,
  //                   textAlign: 'left',
  //                   paddingLeft: 3,
  //                 }}>
  //                 {'รูปแบบจัดส่ง'}
  //               </XText>
  //             </View>
  //           </Left>
  //           <Right
  //             style={{
  //               borderBottomWidth: 0,
  //               flex: 2,
  //               flexDirection: 'row',
  //               alignItems: 'center',
  //               justifyContent: 'flex-end',
  //               paddingRight: 15,
  //             }}>
  //             <XText
  //               style={{
  //                 fontSize: FONT_SIZE_NORMAL,
  //                 color: TEXT_ACTIVE,
  //                 textAlign: 'right',
  //                 paddingRight: 8,
  //                 // paddingLeft: 12,
  //               }}>
  //               {txtShippingType}
  //             </XText>
  //             {disabled ? null : (
  //               <Icon
  //                 style={{
  //                   fontSize: FONT_ICON_NORMAL,
  //                   color: TEXT_INACTIVE,
  //                 }}
  //                 name='arrow-forward'
  //               />
  //             )}
  //           </Right>
  //         </Box>
  //       </XCard>
  //     </TouchableOpacity>
  //   )
  // }

  _renderDetailShippingTypeCardButton = (data: any) => {
    const { mode } = this.state
    const { item, index, disabled } = data
    let txtShippingType = null
    if (index > -1) {
      txtShippingType = this.ALL_SHIPPING_TYPE_ITEMS[index].label
    } else if (index === -1 && item && item.value > 0) {
      txtShippingType = 'กรุณาดาวน์โหลดแอปเวอร์ชั่นใหม่ เพื่อแสดงผลข้อมูลนี้'
    } else {
      txtShippingType = 'ยังไม่ได้เลือก'
    }
    return (
      <XCard w='full'>
        <XCard.Body>
          <HStack w='full' alignItems='center' space='1'>
            <HStack alignItems='center' space='1'>
              <XIcon family='MaterialIcons' name='local-shipping' variant='inactive' />
              <XText variant='inactive'>รูปแบบจัดส่ง</XText>
            </HStack>

            <HStack flex={1} alignItems='center' justifyContent='flex-end' space='1'>
              <XText flex={1} textAlign='right'>
                {txtShippingType}
              </XText>
              {disabled ? null : <XIcon variant={mode === CREATE ? 'primary' : 'inactive'} name='arrow-forward' />}
            </HStack>
          </HStack>
        </XCard.Body>
      </XCard>
    )
  }

  _renderDetailShippingTypeCard = (order) => {
    const { mode } = this.state
    // รูปแบบจัดส่ง
    const selectedShippingTypeId = order.get('shipping_type_id') || -1
    // console.log('selectedShippingTypeId => ', selectedShippingTypeId)
    const selectedShippingTypeIndex =
      selectedShippingTypeId > 0 ? _.findIndex(this.ALL_SHIPPING_TYPE_ITEMS, (sItem) => sItem.value === selectedShippingTypeId) : -1
    // let txtShippingType = null
    // if (shipping_index > -1) {
    //   txtShippingType = this.shippingTypeItems[shipping_index].label
    // } else if (shipping_index === -1 && shipping_type_id > 0) {
    //   txtShippingType = 'กรุณาดาวน์โหลดแอปเวอร์ชั่นใหม่ เพื่อแสดงผลข้อมูลนี้'
    // } else {
    //   txtShippingType = 'ยังไม่ได้เลือก'
    // }
    // log('_renderDetailShippingTypeCard')
    // log('shipping_type_id: ' + shipping_type_id)
    // log('shipping_index' + shipping_index)
    // log('txtShippingType: ' + txtShippingType)

    // FIXME: เผื่อเงิ่อนไขเอาไว้สำหรับถ้าหากกดเปลี่ยน รูปแบบจัดส่ง ของ order ได้
    const disabled = mode !== CREATE && this._isOrderCancelled(order)

    const handleChangeOrderShippingType = (itemInfo) => {
      const mkpIsSelfDelivery = order.has('mkp_is_self_delivery') ? order.get('mkp_is_self_delivery') : false
      if (xUtil.isMarketPlacesOrder(order) && !mkpIsSelfDelivery) {
        p.op.showConfirmationOkOnly(
          '',
          `ไม่สามารถเปลี่ยนรูปแบบขนส่งได้ เนื่องจากเป็นออเดอร์จากช่องทางขาย ${xUtil.getMKPName(order.get('mkp_id'))}`
        )
        return
      }
      const { item, index } = itemInfo
      if (!item || !item.value) {
        return
      }
      const newShippingTypeId = item.value
      const newShippingTypeLabel = item.label
      const newShippingTypeIndex = item.index
      const store_id = xUtil.getNavParam(this.props, 'store_id')
      this._handleChangeShippingType(
        order,
        // this.props.navigation.state.params.store_id,
        store_id,
        newShippingTypeId,
        newShippingTypeLabel,
        newShippingTypeIndex
      )
    }

    return (
      <XFlatPicker
        title='เลือกรูปแบบจัดส่ง'
        ref={this._shippingTypeViewModePickerRef}
        disabled={disabled || mode === EDIT}
        selectedIndex={selectedShippingTypeIndex}
        options={this.state.shippingTypeItems}
        onPick={handleChangeOrderShippingType}
        renderSelectedValue={this._renderDetailShippingTypeCardButton}
        renderItem={this._renderCommonFlatPickerItemForShipping}
        renderTitleBar={() => this._renderTotleBarForShipping()}
      />
    )
  }

  _renderCommonFlatPickerItemForShipping = (data) => {
    const { item, index, disabled } = data
    // console.log('DATA => ', data)
    const shippingState = xUtil.getShippingHasXShippingLabel(item.value)
    // console.log('shippingState => ', shippingState)
    const UNDER_LINE = p.op.isWeb() ? {} : { borderBottomColor: COLORS.TEXT_INACTIVE, borderBottomWidth: 1 }
    // return (
    //   <View style={[{ flex: 1, flexBasis: 'auto', height: 60, flexDirection: 'row' }, UNDER_LINE]}>
    //     <View style={{ flex: 1, flexBasis: 'auto', alignItems: 'flex-start', justifyContent: 'center', paddingLeft: 15 }}>
    //       <XText style={{ fontSize: STYLES.FONT_SIZE_LARGER, color: COLORS.TEXT_ACTIVE }}>{`${item.label}`}</XText>
    //       {_.isNil(shippingState) ? null : (
    //         <XText style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: shippingState.color }}>{`(${shippingState.label})`}</XText>
    //       )}
    //     </View>
    //     <View style={{ width: 50, alignItems: 'center', justifyContent: 'center' }}>
    //       <XIcon name='arrowright' family='AntDesign' style={{ flex: 0, minWidth: 25, width: 25 }} />
    //     </View>
    //   </View>
    // )

    return (
      <XCard w='full'>
        <XCard.Body>
          <HStack w='full' minH='10' alignItems='center' space='1'>
            <VStack flex={1}>
              <XText style={{ fontSize: STYLES.FONT_SIZE_LARGER, color: COLORS.TEXT_ACTIVE }}>{`${item.label}`}</XText>
              {_.isNil(shippingState) ? null : (
                <XText style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: shippingState.color }}>{`(${shippingState.label})`}</XText>
              )}
            </VStack>
            <XIcon name='arrowright' family='AntDesign' />
          </HStack>
        </XCard.Body>
      </XCard>
    )
  }

  _renderCommonFlatPickerItem = (data) => {
    const { item, index, disabled } = data
    return (
      <XCard key={index.toString()}>
        <HStack w='full' py='4' px='2' alignItems='center'>
          <XText flex={1} variant='active'>{`${item.label}`}</XText>
          <XIcon name='arrowright' family='AntDesign' variant='primary' />
        </HStack>
      </XCard>
    )
    // return (
    //   <XCard w='full' key={index.toString()}>
    //     <XCard.Body>
    //       <HStack w='full' justifyContent='space-between'>
    //         <XText>{`${item.label}`}</XText>
    //         <ForwardIcon />
    //       </HStack>
    //     </XCard.Body>
    //   </XCard>
    // )
  }

  _renderOptionsCard = (order) => {
    const disabledRender = [false]
    const { mode, customCreateOrder, customOrderView } = this.state
    // let autoComplete = customCreateOrder.auto_complete.v
    let autoComplete = _.has(customCreateOrder, 'auto_complete') ? customCreateOrder.auto_complete.v : 1
    const orderType = order.get('type') ? parseInt(order.get('type')) : 0
    let seleChannel = _.has(customCreateOrder, 'sales_channel') ? customCreateOrder.sales_channel.v : 1
    if (mode !== CREATE) {
      if (orderType === 1) {
        seleChannel = _.has(customOrderView, 'sales_channel') ? customOrderView.sales_channel.v : 1
        autoComplete = _.has(customOrderView, 'auto_complete') ? customOrderView.auto_complete.v : 1
      } else {
        seleChannel = 0
      }
      // const type = parseInt(order.get('type')) || 0
      if (orderType !== 1) {
        disabledRender[0] = true
      }
      // console.log('orderType => ', orderType)
      // const state = selectedOrder.get('state') || 0
      // const payment_state = selectedOrder.get('payment_state') || 0
      // const shipping_state = selectedOrder.get('shipping_state') || 0

      // // ถ้า order เสร็จสิ้น หรือ ไม่ใช่ order type 1 จะไม่ render option auto complete
      // if (type !== 1 || (state === 109 && payment_state === 119 && shipping_state === 129)) {
      //   disabledRender[0] = true
      // }
      // ถ้า order เสร็จสิ้น หรือ ไม่ใช่ order type 1 จะไม่ render option auto complete
    }

    const countRenderOptions = disabledRender.reduce((prevCount, fact) => (!fact ? prevCount + 1 : prevCount), 0)

    let showSeleChannel = false
    if (mode === CREATE) {
      showSeleChannel = seleChannel === 1 || autoComplete === 1
    } else {
      showSeleChannel = seleChannel === 1 || (autoComplete === 1 && countRenderOptions > 0)
    }

    return (
      <XCard w='full' mt='1'>
        <XCard.Body>
          <VStack w='full' space='1'>
            <HStack py='2' alignItems='center' justifyContent='center'>
              <XText fontSize='md' bold>
                {p.op.t('others')}
              </XText>
            </HStack>
            {showSeleChannel ? (
              <>
                {seleChannel === 0
                  ? null
                  : _.isNil(seleChannel)
                  ? this._salesChannel(order)
                  : seleChannel === 0
                  ? null
                  : this._salesChannel(order)}
              </>
            ) : null}
            {this._renderSetUpDateDelivery()}
            {this._renderSetUpAutoCancalOrder()}
            {countRenderOptions > 0 && autoComplete === 1 ? this._renderOptionAutoCompleteOrder(order, disabledRender[0]) : null}
          </VStack>
        </XCard.Body>
      </XCard>
    )
  }

  _renderSetUpDateDelivery = () => {
    const {
      optionChooseSetupDateDeliveryOrderIndex,
      selectedDateRangeSetUpDateDeliveryOrder,
      isShowWraningDateDelivery,
      customCreateOrder,
      customOrderView,
      mode,
    } = this.state
    // SET Custom Create Order View
    const hasDateDelivery = _.has(customCreateOrder, 'date_delivery')
    if (mode === CREATE && hasDateDelivery && customCreateOrder.date_delivery.v === 0) {
      return null
    }
    // SET Custon OrderView // ถ้าไม่มี date_delivery ให้ซ่อน เพราะค่าเริ่มต้นคือซ่อน
    const hasDateDeliveryOnViewMoode = _.has(customOrderView, 'date_delivery')
    // if (mode !== CREATE && !hasDateDeliveryOnViewMoode) {
    //   return null
    // }

    // ถ้า mode view ตั้งค่าให้ซ่อนก็จะซ่อน
    if (hasDateDeliveryOnViewMoode && customOrderView.date_delivery.v === 0) {
      return null
    }

    let dateDeliverDateTimeStyle = optionChooseSetupDateDeliveryOrderIndex === 0 ? { width: 0, height: 0 } : {}
    if (p.op.isWeb()) {
      // @ts-ignore
      dateDeliverDateTimeStyle = optionChooseSetupDateDeliveryOrderIndex === 0 ? { width: 0, height: 0, overflow: 'hidden' } : {}
    }
    const helpBtn = (onPressOpenOverlay: () => void) => <HelpButton onPress={() => onPressOpenOverlay()} />
    return (
      <XCard>
        <XCard.Body>
          <HStack alignItems='center' justifyContent='space-between' space='1'>
            <HStack alignItems='center' space='1'>
              <XIcon
                name='back-in-time'
                family='Entypo'
                style={{ fontSize: STYLES.FONT_ICON_SMALLER, flex: 0, minWidth: 25, width: 25, color: COLORS.TEXT_INACTIVE }}
              />
              <XText variant='inactive'>วันกำหนดส่ง</XText>
              <XHelpModal
                key='XHelpModalDateDelivery'
                headerTitle='ใช้งานอย่างไร'
                FAQ={FAQ.DATE_DELIVERY_FAQ}
                initiateOpenIndex={0}
                renderButton={(onPressOpenOverlay: () => void) => helpBtn(onPressOpenOverlay)}
              />
            </HStack>
          </HStack>
          <Box>
            <Segment
              options={this.OPTIONS_CHOOSE_SETUP_DEADLINE_ORDER}
              selectedIndex={optionChooseSetupDateDeliveryOrderIndex}
              onSegmentChange={this._onSegmentChangeSetUpDateDelivery}
            />
          </Box>
          {isShowWraningDateDelivery ? (
            <HStack alignItems='center' justifyContent='flex-end'>
              <XText variant='primary'>กรุณาระบุวันกำหนดส่งด้านล่าง</XText>
            </HStack>
          ) : null}
          {/* {p.op.isWeb() ? (
            <View key='xxx' style={[{ paddingTop: 4, paddingRight: 4, paddingLeft: 4 }, dateDeliverDateTimeStyle]}>
              <XDateTimeSinglePicker
                ref={this.dateTimePickerDateDeliveryRef}
                validatorOnPress={() => this.validatorOnPressDateDelivery()}
                selectedDate={selectedDateRangeSetUpDateDeliveryOrder}
                onChangeDate={(newDate: Date) => this._onChangeDateRangeSetUpDateDeliveryOrder(newDate)}
              />
            </View>
          ) : ( */}
          <Box style={[{ paddingLeft: p.op.isIOS ? 0 : 4, paddingRight: p.op.isIOS ? 0 : 4, paddingTop: 4 }, dateDeliverDateTimeStyle]}>
            <XDateTimeSinglePicker
              ref={this.dateTimePickerDateDeliveryRef}
              // validatorOnPress={() => this.validatorOnPressDateDelivery()}
              selectedDate={selectedDateRangeSetUpDateDeliveryOrder}
              onChangeDate={(newDate: Date) => this._onChangeDateRangeSetUpDateDeliveryOrder(newDate)}
            />
          </Box>
          {/* )} */}
        </XCard.Body>
      </XCard>
    )
  }

  _renderSetUpAutoCancalOrder = () => {
    const {
      optionChooseSetupAutoCancelOrderIndex,
      selectedDateRangeSetUpAutoCancelOrder,
      descriptionAutoCancelOrder,
      customCreateOrder,
      customOrderView,
      mode,
    } = this.state
    // CREATE MODE
    const hasCustomAutoCancelOrder = _.has(customCreateOrder, 'auto_cancel_order')
    if (mode === CREATE && hasCustomAutoCancelOrder && customCreateOrder.auto_cancel_order.v === 0) {
      return null
    }

    // SET Custon OrderView
    const hasDateAutoCancelOrderOrderView = _.has(customOrderView, 'auto_cancel_order')
    if (mode !== CREATE && hasDateAutoCancelOrderOrderView && customOrderView.auto_cancel_order.v === 0) {
      return null
    }
    // if (hasDateAutoCancelOrderOrderView && customOrderView.auto_cancel_order.v === 0) {
    //   return null
    // }
    let autoCancalOrderDateTimeStyle = optionChooseSetupAutoCancelOrderIndex === 0 ? { width: 0, height: 0 } : {}
    if (p.op.isWeb()) {
      // @ts-ignore
      autoCancalOrderDateTimeStyle =
        // @ts-ignore
        optionChooseSetupAutoCancelOrderIndex === 0 ? { width: 0, height: 0, overflow: 'hidden' } : { display: undefined }
    }
    const helpBtn = (onPressOpenOverlay: () => void) => <HelpButton onPress={() => onPressOpenOverlay()} />
    return (
      <XCard>
        <XCard.Body>
          <HStack flexWrap='wrap' alignItems='center' space='1'>
            <HStack flex={1} minW='40' alignItems='center' space='1'>
              <XIcon name='cancel' family='MaterialCommunityIcons' variant='inactive' />
              <XText variant='inactive'>{p.op.t('StoreSetting.MenuStoreCancelOrderAutomation')}</XText>
              <XHelpModal
                key='XHelpModalAutoCancalOrder'
                headerTitle='ตั้งค่าใช้งานอย่างไร'
                FAQ={FAQ.AUTO_CANCAL_ORDER}
                initiateOpenIndex={0}
                renderButton={(onPressOpenOverlay: () => void) => helpBtn(onPressOpenOverlay)}
              />
            </HStack>
          </HStack>
          <Box flex={1} minW='40'>
            <Segment
              options={this.OPTIONS_CHOOSE_SETUP_AUTO_CANCEL_ORDER}
              selectedIndex={optionChooseSetupAutoCancelOrderIndex}
              onSegmentChange={this._onSegmentChangeSetUpAutoCancelOrder}
            />
          </Box>
          {optionChooseSetupAutoCancelOrderIndex === 2 && !_.isNil(descriptionAutoCancelOrder) ? (
            <HStack alignItems='center' justifyContent='flex-end'>
              <XText variant='inactive'>{descriptionAutoCancelOrder}</XText>
            </HStack>
          ) : null}
          {/* <XDateTimeSinglePicker selectedDate={selectedDateTime} onChangeDate={(newDate: Date) => this._onChangeDateTime(newDate)} /> */}
          {/* {p.op.isWeb() ? (
          <View style={[{ paddingLeft: 4, paddingRight: 4, paddingTop: 4 }, autoCancalOrderDateTimeStyle]}>
            <XDateTimeSinglePicker
              ref={this.dateTimePickerAutoCancelOrderRef}
              validatorOnPress={() => this.validatorOnPressDateDelivery()}
              selectedDate={selectedDateRangeSetUpAutoCancelOrder}
              onChangeDate={(newDate: Date) => this._onChangeDateRangeSetUpAutoCancelOrder(newDate)}
            />
          </View>
        ) : ( */}
          <Box style={[{ paddingLeft: p.op.isIOS ? 0 : 4, paddingRight: p.op.isIOS ? 0 : 4, paddingTop: 4 }, autoCancalOrderDateTimeStyle]}>
            <XDateTimeSinglePicker
              ref={this.dateTimePickerAutoCancelOrderRef}
              // validatorOnPress={() => this.validatorOnPressDateDelivery()}
              selectedDate={selectedDateRangeSetUpAutoCancelOrder}
              onChangeDate={(newDate: Date) => this._onChangeDateRangeSetUpAutoCancelOrder(newDate)}
            />
          </Box>
          {/* )} */}
        </XCard.Body>
      </XCard>
    )
  }

  _salesChannel = (order) => {
    const { mode, orderTabIndex } = this.state
    const { onEditingOrderChange, editingOrder } = this.props
    const channel_id = _.isNumber(editingOrder.get('channel_id')) ? editingOrder.get('channel_id') : 0
    const channel_index = _.findIndex(this.channelItems, (sItem) => sItem.value === channel_id)
    let txtChannelLabel = null
    if (channel_index > -1) {
      txtChannelLabel = this.channelItems[channel_index].label
    }
    return this._renderDetailOrderChannelCard(order)
    // // แบบ false คือแบบเก่าที่เราเคยใช้ในหน้า OrderView
    // if (false) {
    //   return (
    //     <View style={{ paddingHorizontal: 1, paddingBottom: 4, paddingTop: 0 }}>
    //       <View style={{ padding: 7 }}>
    //         <XText style={{ fontSize: FONT_SIZE_NORMAL, color: TEXT_ACTIVE }}>ช่องทางการขาย</XText>
    //       </View>
    //       {this._renderLineButtonItem({
    //         iconFamily: 'Ionicons',
    //         iconName: 'locate',
    //         txtLabel: 'ช่องทางการขาย',
    //         txtMessage: txtChannelLabel,
    //         txtMessageStyle: null,
    //         handlePress: () => {
    //           this._navToCreateOrderChannelPagePicker(bpData => {
    //             // log('callback _navToPagePicker', bpData)
    //             if (!bpData || !bpData.data || !bpData.data.value) {
    //               return
    //             }
    //             // log('bpData => ', bpData)
    //             const newOrderChannelId = bpData.data.value.value
    //             onEditingOrderChange({ key: 'channel_id', value: newOrderChannelId, orderTabIndex })
    //             p.op.setAppDefaultValue('channel_id', newOrderChannelId)
    //           }),
    //         },
    //       })}
    //     </View>
    //   )
    // } else {
    //   return this._renderDetailOrderChannelCard(order)
    // }
  }

  _renderOptionAutoCompleteOrder(order, shouldNotRender) {
    if (shouldNotRender) {
      return null
    }
    const OPTION_KEY = 'auto_complete'
    const { mode } = this.state

    const currentValue = order.get(OPTION_KEY)

    let disabled = !this._isAutoCompleteOrderOpAllowed(mode)
    let additionalComponent = null

    if (disabled) {
      const myProducts = order.get('products') || List([])
      const state = order.get('state') || 0
      const payment_state = order.get('payment_state') || 0
      const shipping_state = order.get('shipping_state') || 0
      if (!currentValue && state === 109 && payment_state === 119 && shipping_state === 129) {
        additionalComponent = this._renderTextLabel('ใช้งานไม่ได้เนื่องจากออเดอร์เสร็จสิ้นแล้ว')
      } else if (currentValue && state === 109 && payment_state === 119 && shipping_state === 129) {
        additionalComponent = this._renderTextSuccess('ออเดอร์ถูกปิดอัตโนมัติแล้ว')
      } else if (state >= 181) {
        additionalComponent = this._renderTextError('ใช้งานไม่ได้เนื่องจากออเดอร์ถูกยกเลิกแล้ว')
      } else if (myProducts && myProducts.size > 0) {
        additionalComponent = this._renderTextError('ใช้งานไม่ได้เนื่องจากมีสินค้าที่ฉันต้องจัดส่ง')
      } else {
        disabled = false
      }
    }
    // let iconStyle = { minWidth: 26, width: 26, maxWidth: 26, marginRight: 16, marginLeft: -4 }
    // if (mode !== CREATE) {
    //   iconStyle = { minWidth: 26, width: 26, maxWidth: 26, marginRight: 8, marginLeft: 4 }
    // }

    return this._renderSwitchCard({
      order,
      key: OPTION_KEY,
      value: currentValue,
      txtLabel: 'ปิดออเดอร์ขายอัตโนมัติ',
      txtHelp: {
        title: 'ปิดออเดอร์อัตโนมัติ',
        message:
          'หากเปิดการตั้งค่านี้ ออเดอร์ขายจะถูกปิดอัตโนมัติ' +
          'เมื่อทุกๆออเดอร์ซื้อที่เกิดจากออเดอร์ขายใบนี้เสร็จสิ้น\n\n' +
          'เพื่อให้ระบบสามารถคำนวณกำไรของออเดอร์ได้โดยไม่ต้องคำนึงถึงต้นทุนค่าจัดส่ง ' +
          'ระบบจะนำยอดรวมค่าจัดส่งจากออเดอร์ซื้อมาใส่ในออเดอร์ขายให้อัตโนมัติในกรณีที่ไม่ได้มีการแก้ค่าจัดส่งเองในออเดอร์ขาย\n\n' +
          'การเปิดดูออเดอร์ขายที่ถูกตั้งค่าให้ปิดอัตโนมัติ สามารถเปิดดูได้ในหน้า\n"ออเดอร์"ภายใต้ 3 แท็ปนี้ \n' +
          '1) ลูกค้า > ออเดอร์ขายที่รอปิดอัตโนมัติ\n' +
          '2) เสร็จสิ้น > ออเดอร์ขายที่ปิดอัตโนมัติ\n' +
          '3) ยกเลิก (แสดงรวมกับออเดอร์ประเภทอื่นๆ)\n\n' +
          'ท่านสามารถทำการเปิดหรือปิดการตั้งค่านี้ได้หลังจากที่ออเดอร์สร้างแล้ว\n\n' +
          'หมายเหตุ: การปิดออเดอร์ขายอัตโนมัตินี้ใช้ได้ในกรณีที่ไม่มีสินค้าที่ฉันต้องจัดส่งเองในออเดอร์นี้เท่านั้น',
      },
      prefixIcon: {
        iconName: 'clipboard-check-outline',
        // iconStyle,
      },
      disabled,
      additionalComponent,
      txtLabelStyle: {
        // color: TEXT_INACTIVE,
        // fontSize: FONT_SIZE_NORMAL,
        // marginLeft: -4
      },
    })
  }

  // มีเฉพาะ orderType 1 เท่านั้น
  _renderDetailOrderChannelCard = (order) => {
    const { mode, customCreateOrder } = this.state
    const salesChannel = customCreateOrder.sales_channel.vi
    const orderIdKey = order.get('id') || 'noid'
    const orderType = order.get('type') ? parseInt(order.get('type')) : 0
    if (orderType !== 1 && mode !== CREATE) {
      return null
    }

    // ช่องทางขาย
    let channel_id = _.isNumber(order.get('channel_id')) ? parseInt(order.get('channel_id')) : 0
    if (mode === CREATE) {
      channel_id = _.isNil(salesChannel) ? 0 : salesChannel
    }
    const channel_index = _.findIndex(this.channelItems, (sItem) => sItem.value === channel_id)
    let txtChannelLabel = null

    if (channel_index > -1) {
      txtChannelLabel = this.channelItems[channel_index].label
    } else if (channel_index === -1 && channel_id > 0) {
      txtChannelLabel = 'กรุณาดาวน์โหลดแอปเวอร์ชั่นใหม่ เพื่อแสดงผลข้อมูลนี้'
    } else {
      txtChannelLabel = 'ยังไม่ได้เลือก'
    }

    // FIXME: เผื่อเงิ่อนไขเอาไว้สำหรับถ้าหากกดเปลี่ยน รูปแบบจัดส่ง ของ order ได้
    const disabled = this._isOrderCancelled(order)

    return (
      <XFlatPicker
        key={`SaleChannelPicker_${orderIdKey}`}
        title={p.op.t('Order.orderChannel')}
        disabled={disabled || mode === EDIT}
        selectedIndex={channel_index}
        options={this.channelItems}
        onPick={({ item }) => {
          const newOrderChannelId = item.value
          this._handleChangeOrderChannel(newOrderChannelId)
        }}
        renderSelectedValue={this._renderDetailSaleChannelCardButton}
        renderItem={this._renderCommonFlatPickerItem}
      />
    )

    // return (
    //   <TouchableOpacity
    //     disabled={disabled}
    //     onPress={() => {
    //       if (mode === VIEW_EDIT || mode === CREATE) {
    //         this.props.navigation.dispatch(
    //           NavActions.navToPagePicker({
    //             key: 'channelId',
    //             label: p.op.t('Order.orderChannel'),
    //             title: `${p.op.t('choose')}${p.op.t('Order.orderChannel')}`,
    //             items: this.channelItems,
    //             viewType: xCONS.PAGEPICKER_VIEWTYPE.ICON,
    //             callBackAfterSelected: bpData => {
    //               // log('callback _navToPagePicker', bpData)
    //               if (!bpData || !bpData.data || !bpData.data.value) {
    //                 return
    //               }
    //               const newOrderChannelId = bpData.data.value.value
    //               this._handleChangeOrderChannel(newOrderChannelId)
    //             },
    //           }),
    //         )
    //       } else if (mode === EDIT) {
    //         p.op.alert('กรุณาดำเนินการแก้ไขราคาและสินค้าให้เสร็จสิ้นก่อน')
    //       }
    //     }}>
    //     <XCard
    //       // @ts-ignore
    //       style={[CARD_COMMON, { marginTop: 10 }]}>
    //       <Box
    //         cardBody={true}
    //         style={[
    //           NO_MARGIN_PADDING,
    //           {
    //             paddingTop: 9,
    //             paddingBottom: 9,
    //             borderBottomWidth: 0,
    //           },
    //         ]}>
    //         <Left
    //           style={[
    //             NO_MARGIN_PADDING,
    //             {
    //               flex: 1,
    //               // backgroundColor: 'red',
    //               flexDirection: 'column',
    //               justifyContent: 'flex-start',
    //               alignItems: 'flex-start',
    //               borderBottomWidth: 0,
    //             },
    //           ]}>
    //           <View
    //             style={{
    //               flex: 1,
    //               paddingTop: 3,
    //               paddingBottom: 3,
    //               flexDirection: 'row',
    //               // backgroundColor: 'green',
    //             }}>
    //             <XIcon
    //               name='locate'
    //               family='Ionicons'
    //               style={{ paddingLeft: mode === CREATE ? 0 : 12, color: TEXT_INACTIVE, fontSize: 20 }}
    //             />
    //             <XText
    //               style={{
    //                 fontSize: FONT_SIZE_NORMAL,
    //                 color: TEXT_INACTIVE,
    //                 textAlign: 'left',
    //                 paddingLeft: 0,
    //                 paddingTop: 2,
    //               }}>
    //               {p.op.t('Order.orderChannel')}
    //             </XText>
    //           </View>
    //         </Left>
    //         {/* <Body
    //           style={{
    //             borderBottomWidth: 0,
    //             flexDirection: 'column',
    //             justifyContent: 'flex-start',
    //             alignItems: 'flex-start',
    //             flex: 10,
    //           }}>
    //           <View
    //             style={{
    //               flex: 1,
    //               paddingTop: 3,
    //               paddingBottom: 3,
    //             }}>
    //             <XText
    //               style={{
    //                 fontSize: FONT_SIZE_NORMAL,
    //                 color: TEXT_ACTIVE,
    //                 textAlign: 'right',
    //                 paddingLeft: 12,
    //               }}>
    //               {txtChannelLabel}
    //             </XText>
    //           </View>
    //         </Body> */}
    //         <Right
    //           style={{
    //             borderBottomWidth: 0,
    //             flex: 2,
    //             flexDirection: 'row',
    //             alignItems: 'center',
    //             justifyContent: 'flex-end',
    //             paddingRight: 15,
    //           }}>
    //           <XText
    //             style={{
    //               fontSize: FONT_SIZE_NORMAL,
    //               color: TEXT_ACTIVE,
    //               textAlign: 'right',
    //               paddingRight: 8,
    //               // paddingLeft: 12,
    //             }}>
    //             {txtChannelLabel}
    //           </XText>
    //           {disabled ? null : (
    //             <Icon
    //               style={{
    //                 fontSize: FONT_ICON_NORMAL,
    //                 color: TEXT_INACTIVE,
    //               }}
    //               name='arrow-forward'
    //             />
    //           )}
    //         </Right>
    //       </Box>
    //     </XCard>
    //   </TouchableOpacity>
    // )
  }

  _renderDetailSaleChannelCardButton = ({ item, index, disabled }) => {
    const { mode } = this.state
    const channel_id = item.value
    let txtChannelLabel = null
    if (index > -1) {
      // txtChannelLabel = this.channelItems[index].label
      txtChannelLabel = item.label
    } else if (index === -1 && channel_id > 0) {
      txtChannelLabel = 'กรุณาดาวน์โหลดแอปเวอร์ชั่นใหม่ เพื่อแสดงผลข้อมูลนี้'
    } else {
      txtChannelLabel = 'ยังไม่ได้เลือก'
    }

    return (
      <XCard w='full'>
        <XCard.Body>
          <HStack alignItems='center' justifyContent='space-between' space='1'>
            <HStack alignItems='center' space='1'>
              <XIcon name='locate' family='Ionicons' variant='inactive' />
              <XText variant='inactive'>{p.op.t('Order.orderChannel')}</XText>
            </HStack>
            <HStack alignItems='center' justifyContent='flex-end' space='1'>
              <XText>{txtChannelLabel}</XText>
              {disabled ? null : <XIcon name='arrow-forward' />}
            </HStack>
          </HStack>
        </XCard.Body>
      </XCard>
    )
  }

  prepareBeforeGoToPaymentView = (params: { orderId: number; remaining_forecast_amount: number; cod_amount: number }) => {
    this.props.initOrdersToBePaid(params)
  }

  hasPermissionToPrintDocumentsWithAlert = () => {
    const hasPermissionToPrint = xAcl.canDoAtSelectedStore(xCONS.PERM_STORE_HELPER.ORDER_PRINT_SHIPPING)
    if (!hasPermissionToPrint) {
      p.op.alert('ไม่มีสิทธิ์ในการพิมพ์', 'กรุณาติดต่อเจ้าของร้านหากคุณต้องการพิมพ์เอกสารเหล่านี้')
    }
    return xAcl.canDoAtSelectedStore(xCONS.PERM_STORE_HELPER.ORDER_PRINT_SHIPPING)
  }

  // FIXME: Please Override me to cross native/web
  summitDownloadPrinting = async (key: PrintOptionKey) => {
    const hasPermissionToPrintDocuments = this.hasPermissionToPrintDocumentsWithAlert()
    if (!hasPermissionToPrintDocuments) {
      return
    }

    const { selectedStore } = this.props
    const order = this._getFocusedOrder()
    const store_id = selectedStore.get('id')
    const order_id = order.get('id')

    xUtil.submitPrintingDocuments({
      printKey: key,
      store_id,
      order_ids: [order_id],
      handleOpenPaperang: p.op.isWeb() ? null : (url) => this._onOpenPaperangFromUrl(url, key),
    })
    // // await p.op.storageSet(xCONS.STORAGE_KEYS.PRINTING_XOVERLAY_OPTION_KEY, key)
    // this.closePrintOptionModal()
    // await xUtil.delay(200)
    // // if (key === xCONS.PRINT_OPTIONS.PAPERANG) {
    // //   this._openShippingLabelPDF(`PAPERANG`)
    // // } else if (key === xCONS.PRINT_OPTIONS.A4_PDF) {
    // //   this._openShippingLabelPDF()
    // // } else if (key === xCONS.PRINT_OPTIONS.STICKER_PDF) {
    // //   this._openShippingLabelPDF(`STICKER`)
    // // } else if (key === xCONS.PRINT_OPTIONS.BILL_PAPERANG) {
    // //   this._openShippingLabelBill()
    // // }
    // if (key === xCONS.PRINT_OPTIONS.BILL_PAPERANG) {
    //   this._openShippingLabelBill()
    // } else {
    //   this._openShippingLabelPDF(key)
    // }
  }

  // _openShippingLabelBill = async (): Promise<void> => {
  //   const { selectedStore } = this.props
  //   const order = this._getFocusedOrder()
  //   const store_id = selectedStore.get('id')
  //   const order_id = order.get('id')
  //   const url = xUtil.getShippingLabelBillUrl({
  //     store_id,
  //     order_ids: [order_id],
  //     printing_type: 2,
  //     size: 2,
  //   })
  //   await this._onOpenPaperangFromUrl(url, xCONS.PRINT_OPTIONS.BILL_PAPERANG)
  //   p.op.aLogEvent(xCONS.EVENT_NAME.RECEIPT_PPR, { s: store_id })
  // }

  // renderModelPrintOptionBody = () => {
  //   return (
  //     <View
  //       style={{
  //         flex: 1,
  //         backgroundColor: '#fff',
  //       }}>
  //       <PrintingOptionUI
  //         selectedStore={this.props.selectedStore}
  //         onSubmit={this.summitDownloadPrinting}
  //         onSettings={this.navToStoreSettingAllPrintingShipping}
  //       />
  //     </View>
  //   )
  // }

  // renderModelPrintOption = () => {
  //   const { isOpenPrintOptionModel } = this.state
  //   return (
  //     <XOverlay
  //       contentStyle={{ height: 390, width: 310, alignSelf: 'center' }}
  //       visible={isOpenPrintOptionModel}
  //       webNumColumns={1}
  //       onRequestClose={this.closePrintOptionModal}>
  //       <>{this.renderModelPrintOptionBody()}</>
  //     </XOverlay>
  //   )
  // }

  _renderMKPOrderPickUp = () => {
    const thisOrder = this._getFocusedOrder()
    const hasMKP = thisOrder.has('mkp_id')

    const hasChannelId = thisOrder.has('mkp_ch_id')
    const channels = xUtil.getMKPChannels()
    if (!hasChannelId && !hasMKP && _.isNil(channels)) {
      return null
    }
    // ถ้า mkp_is_self_delivery = true ให้ซ่อน UI อ้างอิง :: https://app.clickup.com/t/860qek8n8
    const mkpIsSelfDelivery = thisOrder.has('mkp_is_self_delivery') ? thisOrder.get('mkp_is_self_delivery') : false
    if (mkpIsSelfDelivery) {
      return null
    }
    // mkp ที่ไม่มีให้เลือก นัดรับ และ drop off จะไม่สามารถมองเห็นตัวเลือกได้
    if (!_.includes(xCONS.MKP_PICKUP_AND_DROPOFF, thisOrder.get('mkp_id'))) {
      return null
    }
    const channelId = thisOrder.get('mkp_ch_id')
    const channel = _.find(channels, (ch: IMKPChannelDetail) => ch.id === channelId)
    if (_.isNil(channel)) {
      return null
    }
    // console.log('channel => ', channel)
    const type = parseInt(thisOrder.get('type'))
    const state = parseInt(thisOrder.get('state'))
    const { CONFIRM } = xCONS.ORDER_OPERATION_AVAILABILITY
    const statusConfirmBtn = CONFIRM[`_${type}${state}`] || 0
    const isOrderConfirm = statusConfirmBtn === 0
    if (isOrderConfirm) {
      return null
    }
    const { optionChooseShippingMKPTypeFromIndex, textAddressMKPSelected, textTimeMKPSelected } = this.state
    const MKP_ID = thisOrder.get('mkp_id')
    const shippingId = thisOrder.get('shipping_type_id')
    return (
      <View>
        <XText style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE, paddingBottom: 2, paddingLeft: 2 }}>
          รูปแบบจัดส่งตามช่องทางขาย
        </XText>
        <XCard p='2'>
          <View style={{ flexDirection: 'row', width: '100%' }}>
            <View style={{ width: 40, justifyContent: 'center', alignItems: 'flex-end', paddingBottom: 6, paddingRight: 8 }}>
              {MKP_ID ? <MkpLogo mkpId={MKP_ID} style={{ width: 20, height: 20 }} /> : null}
            </View>
            <View style={{ width: 80 }}>
              <XText style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE }}>รูปแบบจัดส่ง</XText>
            </View>
          </View>
          <VStack px='3'>
            <Segment
              options={this.OPTIONS_CHOOSE_SHIPPING_MKP_TYPE}
              selectedIndex={optionChooseShippingMKPTypeFromIndex}
              onSegmentChange={this._onSegmentChangeShippingMKPType}
            />
          </VStack>

          {/* {optionChooseShippingMKPTypeFromIndex === 0 ? (
            <View style={{ flexDirection: 'row' }}>
              <View style={{ flex: 1 }}>
                <View>
                  <View style={{ flexDirection: 'row', paddingTop: 4 }}>
                    <View style={{ width: 40 }} />
                    <View style={{ width: 80 }}>
                      <XText style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE }}>ที่อยู่นัดรับ</XText>
                    </View>
                    <View style={{ flex: 1 }}>
                      <XText style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE }}>{textAddressMKPSelected}</XText>
                    </View>
                  </View>
                </View>
                <View>
                  <View style={{ flexDirection: 'row', paddingTop: 4 }}>
                    <View style={{ width: 40 }} />
                    <View style={{ width: 80 }}>
                      <XText style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE }}>เวลานัดรับ</XText>
                    </View>
                    <View style={{ flex: 1 }}>
                      <XText style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE }}>{textTimeMKPSelected}</XText>
                    </View>
                  </View>
                </View>
              </View>
              <View style={{ width: 60, justifyContent: 'center', alignItems: 'center' }}>
                <OverlayEditTimeSlot
                  renderButton={(onPressOpenOverlay: () => void) => this._renderIconTimer(onPressOpenOverlay)}
                  channel={channel}
                  shippingTypeId={shippingId}
                  // key={mkpChannel.id}
                  isSingleMkpChannel
                  // setPrefTimeslot={setPrefTimeslot}
                  // selectedStore={selectedStore}
                  onSubmit={(data: IOnSubmitOverlayEditTime) => this.onSubmitOverlayEditTimeSlot(data)}
                  headertitle=''
                  footerText='ยืนยัน'
                  showSectionSetTime
                  showsectionSetAddress={!!channel.addresses}
                />
              </View>
            </View>
          ) : null} */}
        </XCard>
      </View>
    )
  }

  _renderModelPercentDiscount = () => {
    const { onEditingOrderChange, editingOrder } = this.props
    const { isVisibleSetModelPercentDiscount, orderTabIndex } = this.state
    // const amountProducts = editingOrder.get('amountProductsIncludeDiscount')
    const order = this._getFocusedOrder()
    const orderType = order.get('type')
    // console.log('orderType => ', orderType)
    let amountTotal = 0
    if (orderType === 3) {
      amountTotal = order.get('amountTotalBuy') || 0
    } else {
      amountTotal = editingOrder.get('amountTotal') || 0
    }
    const discount: number | string = _.isString(editingOrder.get('discount'))
      ? +editingOrder.get('discount')
      : editingOrder.get('discount')
    // @ts-ignore
    amountTotal += discount
    // console.log('amountTotal => ', amountTotal)
    return (
      <XPercentDiscount
        isVisible={isVisibleSetModelPercentDiscount}
        onClose={() => xUtil.setStatePromise(this, { isVisibleSetModelPercentDiscount: false })}
        vulueAmountTotal={amountTotal}
        onSubmit={(discount: number) => {
          onEditingOrderChange({ key: 'discount', value: discount.toString(), orderTabIndex })
        }}
      />
    )
  }

  renderAddressListOverlay = () => {
    const { isVisibleAddressesListOverlay, addressesListOverlay } = this.state
    if (_.isNil(addressesListOverlay)) {
      return null
    }
    return (
      <XAddressesListOverlay
        isVisible={isVisibleAddressesListOverlay}
        onClose={() =>
          xUtil.setStatePromise(this, {
            isVisibleAddressesListOverlay: false,
          })
        }
        orderAddress={addressesListOverlay}
      />
    )
  }

  renderOverInputcodAmount = () => {
    const { onEditingOrderChange } = this.props
    const { isOpenCODAmountOverlay, orderTabIndex, mode } = this.state
    const closeOverlay = () => {
      xUtil.setStatePromise(this, {
        isOpenCODAmountOverlay: false,
      })
    }

    const order = this._getFocusedOrder(orderTabIndex)
    const isEditableInConfirmationMode = this._calculateEditableInConfirmationMode(order)
    const { isEditableQty, isEditableEtc } = isEditableInConfirmationMode
    const isEditable = _.includes([CREATE, EDIT], mode) || (_.includes([VIEW_EDIT], mode) && isEditableQty)
    const orderType = order.get('type')
    const codFee = order.get('cod_fee') || 0
    // const orderIndex = order.get('index') || 0
    let codAmount = order.get('cod_amount_for_change_shipping_type_to_cod') || '0'
    let amountTotal
    if (mode === EDIT || (mode === VIEW_EDIT && isEditable)) {
      // amountTotal = order.get('amountTotal') || 0
      if (orderType === 3) {
        amountTotal = order.get('amountTotalBuy') || 0
        //  console.log("order.get('amountTotalBuy')", order.get('amountTotalBuy'))
      } else {
        amountTotal = order.get('amountTotal')
        //  console.log("order.get('amountTotal')", order.get('amountTotal'))
      }

      // amountTotal = amountTotal + +shipping_cost
      // amountTotal = amountTotal - sumdDscountUGPG
    } else if (mode === VIEW_EDIT && !isEditable) {
      amountTotal = order.get('transaction_cost')
      //  console.log("order.get('transaction_cost')", order.get('transaction_cost'))
    } else {
      amountTotal = order.get('amountTotal') || 0
      // console.log("last else amountTotal", amountTotal)
    }
    if (mode === CREATE || mode === EDIT || (mode === VIEW_EDIT && isEditable)) {
      amountTotal += parseFloat(codFee)
      // console.log("if block amountTotal", amountTotal)
    }
    const vdDiscounts = order.get('vd_discounts')
    let vdDiscountsTxt = ''
    let totalVdDiscountAmount = 0
    // Calculate total VD discount and subtract it out of amountTotal, so users get more accurate total of amount
    // TODO by Keng 28 May 2021: However, this logic is NOT yet implemented for UGPG discount as there is no use case from a customer yet
    // TODO So we have to come back here if a customer requested.
    if (!_.isNil(vdDiscounts) && vdDiscounts.size > 0 && (mode === EDIT || (mode === VIEW_EDIT && isEditable))) {
      // console.log('vdDiscounts', vdDiscounts.toJS())
      vdDiscountsTxt = ' (หักส่วนลดขายส่งที่คำนวณไว้ก่อนแก้ไขแล้ว)' // TODO Keng: Change text here
      let vdDiscount
      for (let i = 0; i < vdDiscounts.size; i++) {
        vdDiscount = vdDiscounts.get(i)
        totalVdDiscountAmount += parseFloat(vdDiscount.get('ta')) // Important! Must parseFloat!
      }
      // rounding to 2 digits
      totalVdDiscountAmount = xUtil.roundToTwoDecimal(totalVdDiscountAmount)
      // totalVdDiscountAmount = Math.round(totalVdDiscountAmount * 100) / 100
    }
    amountTotal -= totalVdDiscountAmount
    amountTotal = xUtil.roundToTwoDecimal(amountTotal)

    const onChangeCodAmount = (text: string) => {
      onEditingOrderChange({ key: 'cod_amount_for_change_shipping_type_to_cod', value: text, orderTabIndex })
    }
    if (_.isNumber(amountTotal)) {
      // @ts-ignore
      amountTotal += ''
    }
    if (codAmount === '0') {
      codAmount = amountTotal
    }
    return (
      <XOverlay
        contentStyle={{ height: 175, width: 310, alignSelf: 'center' }}
        visible={isOpenCODAmountOverlay}
        // webNumColumns={1}
        onRequestClose={closeOverlay}>
        <VStack p='1' w='full' bgColor='white'>
          {/* // Title */}
          <View style={{ height: 30, justifyContent: 'center' }}>
            <XText style={{ color: COLORS.TEXT_INACTIVE, fontSize: STYLES.FONT_SIZE_LARGER }}>ยืนยันยอดเก็บเงินปลายทาง</XText>
          </View>
          {/* // ยอดรวมออเดอร์ */}
          <View style={{ flexDirection: 'row', height: 40, alignItems: 'center' }}>
            <View style={{ flex: 1 }}>
              <XText style={{ color: COLORS.TEXT_INACTIVE, fontSize: STYLES.FONT_SIZE_NORMAL }}>ยอดรวมออเดอร์</XText>
            </View>
            <View style={{ width: 100 }}>
              <XInput value={amountTotal} isMoney style={{ textAlign: 'right' }} isDisabled />
            </View>
          </View>
          {/* // ยอดเก็บเงินปลายทาง */}
          <View style={{ flexDirection: 'row', height: 40, alignItems: 'center' }}>
            <View style={{ flex: 1 }}>
              <XText
                style={{
                  color: COLORS.COD_COLOR,
                  fontSize: STYLES.FONT_SIZE_NORMAL,
                }}>{`ยอดเก็บเงินปลายทาง\n(พิมพ์บนใบปะหน้าพัสดุ)`}</XText>
            </View>
            <View style={{ width: 100 }}>
              <XInput
                value={codAmount}
                isMoney
                style={{ textAlign: 'right' }}
                onChangeText={(newText: string) => onChangeCodAmount(newText)}
              />
            </View>
          </View>
          <View style={{ height: 10 }} />
          {/* // FOOTER */}
          <View style={{ flexDirection: 'row', height: 40, alignItems: 'center' }}>
            <TouchableOpacity
              onPress={() => {
                this.onChangeShippingNormalCODType(codAmount)
                xUtil.setStatePromise(this, {
                  isOpenCODAmountOverlay: false,
                })
              }}
              style={{
                flex: 1,
                height: 40,
                borderRadius: 7,
                backgroundColor: COLORS.APP_MAIN,
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <XText color='white' bold>
                ยืนยัน
              </XText>
            </TouchableOpacity>
            <View style={{ width: 4 }} />
            <TouchableOpacity
              onPress={() =>
                xUtil.setStatePromise(this, {
                  isOpenCODAmountOverlay: false,
                })
              }
              style={{
                width: 50,
                borderRadius: 7,
                borderWidth: 1,
                borderColor: COLORS.TEXT_INACTIVE,
                justifyContent: 'center',
                alignItems: 'center',
                height: 40,
              }}>
              <XText variant='inactive' bold>
                ยกเลิก
              </XText>
            </TouchableOpacity>
          </View>
        </VStack>
      </XOverlay>
    )
  }

  render() {
    xUtil.logRender(this)
    const { mode, isInitialized } = this.state
    // this.state.isInitialized : <Spinner color={COLORS.APP_MAIN} size='small' />
    return (
      <XContainer>
        {/* {this.renderModelPrintOption()} */}
        {this._renderCustomHeader()}
        {this._renderTabWrapperByMode()}
        {/* <StickyKeyboardTab */}
        {/*  arrayOfRefs={this.inputRefs} */}
        {/*  currentRef={this.state.currentRef} */}
        {/*  scrollRef={this.scrollViewRef} */}
        {/*  disabledJumpButton={true} */}
        {/* /> */}
        {this.renderAddressListOverlay()}
        {this.renderOverInputcodAmount()}
        {mode === CREATE ? this._renderFooterCreateOrder() : null}
        <XSpinnerOverlay visible={!isInitialized} />
      </XContainer>
    )
  }
}

export const s = StyleSheet.create({
  webFlexBasis: {
    ...Platform.select({
      web: {
        flexBasis: 'auto',
      },
    }),
  },
  webWidth100Percent: {
    ...Platform.select({
      web: {
        width: '100%',
      },
    }),
  },
  webBorderInactive: {
    ...Platform.select({
      web: {
        borderTopWidth: 1,
        borderLeftWidth: 1,
        borderBottomWidth: 1,
        borderRightWidth: 1,
        borderTopColor: COLORS.FORM_INACTIVE,
        borderLeftColor: COLORS.FORM_INACTIVE,
        borderBottomColor: COLORS.FORM_INACTIVE,
        borderRightColor: COLORS.FORM_INACTIVE,
      },
    }),
  },
  justifyCenter: {
    justifyContent: 'center',
  },
  noFlexFixedWidth34: {
    flex: p.op.isWeb() ? 1 : 0,
    width: 34,
  },
  marginBottom12: {
    marginBottom: 12,
  },
  container: {
    flex: 1,
    // justifyContent: 'space-between',
  },
  content: {
    flex: 1,
    paddingLeft: 5,
    paddingRight: 5,
  },
  gridColumnContainer: {
    flex: 1,
    flexBasis: 'auto',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  halfColumnContainer: {
    width: '50%',
    padding: 5,
  },
  pCardImg: { width: '100%', height: 100 },
  pCardForm: {
    // backgoundColor: 'rgba(45,45,45,0.3)',
  },
  pCardInput: {},
  pCardLabel: {},
  // @ts-ignore
  formInCard: {
    alignSelf: 'stretch',
    margin: 0,
    padding: 0,
    flex: 1,
    ...Platform.select({
      web: {
        width: '100%',
        flexBasis: 'auto',
        overflow: 'hidden',
      },
    }),
  },
  borderPrimary: {
    borderColor: COLORS.FORM_PRIMARY,
  },
  borderError: {
    borderColor: COLORS.FORM_ERROR,
  },
  borderSuccess: {
    borderColor: COLORS.FORM_SUCCESS,
  },
  separator: {
    height: 5,
  },
  cardStyle: {
    // elevation: 1,
    // width: '100%',
    flex: 1,
    marginLeft: 5,
    marginRight: 5,
    // marginLeft: 0,
    // paddingLeft: 0,
  },
  cardBody: {
    width: '100%',
    height: 220,
    flex: 1,
  },
  cardImg: {
    alignSelf: 'center',
    width: '100%',
    height: 200,
  },
  alignCenter: {
    alignSelf: 'center',
  },
  alignRight: {
    alignSelf: 'flex-end',
  },
  txtHeader: {
    fontSize: STYLES.FONT_SIZE_ORDERS_HEADER_ROW2,
    color: COLORS.TEXT_ACTIVE,
    fontWeight: 'bold',
  },
  txtNormal: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_ACTIVE,
  },
  txtMultiple: {
    width: '100%',
    height: 100,
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_ACTIVE,
  },
  txtLabel: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_INACTIVE,
    // color: 'rgba(15,15,15,0.6)',
  },
  txtHLRight: {
    fontSize: STYLES.FONT_SIZE_LARGER,
    fontWeight: 'bold',
    color: COLORS.TEXT_PRIMARY,
    textAlign: 'right',
  },
  labelRight: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_INACTIVE,
    textAlign: 'right',
  },
  txtRight: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_ACTIVE,
    textAlign: 'right',
  },
  iconHL: {
    fontSize: STYLES.FONT_SIZE_NORMAL + 4,
    color: COLORS.TEXT_PRIMARY,
    textAlign: 'right',
  },
  rowBox: {
    borderWidth: 1,
    borderColor: 'lightgrey',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 10,
    paddingBottom: 10,
  },
  colBox50: {
    width: '50%',
    borderWidth: 1,
    borderColor: 'lightgrey',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingTop: 10,
    paddingLeft: 5,
    paddingRight: 5,
    paddingBottom: 10,
  },
  statusTableColumnContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  statusTableLabelColumnViewBlank: {
    width: '100%',
    height: 20,
    borderWidth: 0,
  },
  statusTableLabelColumnView: {
    width: '100%',
    height: 20,
    borderWidth: 1,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderColor: COLORS.TEXT_INACTIVE,
    flexDirection: 'row',
    alignItems: 'center',
    borderTopWidth: p.op.isWeb() ? 1 : 0,
    borderLeftWidth: p.op.isWeb() ? 1 : 0,
  },
  statusTableLabelColumnViewLastRow: {
    width: '100%',
    height: p.op.isWeb() ? 21 : 20,
    borderWidth: 1,
    borderRightWidth: 0,
    borderColor: COLORS.TEXT_INACTIVE,
    flexDirection: 'row',
    alignItems: 'center',
    borderTopWidth: p.op.isWeb() ? 1 : 0,
    borderLeftWidth: p.op.isWeb() ? 1 : 0,
    borderBottomWidth: p.op.isWeb() ? 1 : 0,
  },
  statusTableLabelColumnTxt: {
    fontSize: STYLES.FONT_SIZE_SMALLER,
    color: COLORS.TEXT_INACTIVE,
    paddingLeft: 0,
  },
  itemLabel: {
    borderBottomWidth: 0,
    marginTop: 5,
  },
  shippingIcon: {
    fontSize: STYLES.FONT_ICON_SMALLER,
    color: COLORS.APP_MAIN,
    paddingRight: 6,
    paddingTop: 1,
  },
  productSummaryTableHeader: {
    flex: 1,
    height: 25,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  productSummaryTableHeaderText: {
    flex: 1,
    textAlign: 'center',
    fontSize: STYLES.FONT_SIZE_SMALLER,
    color: COLORS.TEXT_ACTIVE,
  },
  orderStateIcon: {
    width: 22,
    paddingTop: 1,
    fontSize: STYLES.FONT_ICON_SMALLER - 2,
    paddingRight: 1,
    textAlign: 'center',
  },
  paymentStateIcon: {
    width: 22,
    paddingTop: 1,
    fontSize: STYLES.FONT_ICON_SMALLER,
    textAlign: 'center',
  },
  shippingStateIcon: {
    width: 22,
    paddingTop: 1,
    fontSize: STYLES.FONT_ICON_SMALLER,
    textAlign: 'center',
  },
  shippingTypePickerItemContainer: {
    borderBottomWidth: isWeb ? 0 : 1,
    borderBottomColor: COLORS.TEXT_INACTIVE,
    paddingLeft: 12,
    paddingVertical: 8,
  },
  colFixedWidth50: {
    flex: 0,
    width: 50,
  },
  justifyContentCenter: {
    justifyContent: 'center',
  },
})

export default BaseUIOrderView
