import { CommonActions as NavigationActions } from '@react-navigation/native'

// Common NavActions here
export * from 'x/utils/navigation'

export const navToProfile = NavigationActions.navigate({
  name: 'ProfileView',
  params: { mode: 'VIEW' },
})

export const navToLangindView = (callbackAfterSaveSuccess) =>
  NavigationActions.navigate({
    name: 'LandingView',
    params: { mode: 'EDIT', focusOnKey: 'fb_id', goBackAfterSave: true, callbackAfterSaveSuccess },
  })

// // MainSwitchNavigator.tsx
// export const switchToLandingView = SwitchActions.jumpTo({
//   name: 'LandingView',
// })
export const switchToLandingView = NavigationActions.reset({
  // key: null,
  index: 0,
  // actions: [NavigationActions.navigate({ name: 'LandingView' })],
  routes: [{ name: 'LandingView' }],
})

// export const switchToLoginView = SwitchActions.jumpTo({
//   name: 'LoginView',
// })
export const switchToLoginView = NavigationActions.reset({
  // key: null,
  index: 0,
  // actions: [NavigationActions.navigate({ name: 'LoginView' })],
  routes: [{ name: 'LoginView' }],
})

export const switchToAuthSwitchNavigator = (params: { store_id: number }) =>
  // SwitchActions.jumpTo({
  //   name: 'AuthSwitchNavigator',
  //   // params,
  // })
  NavigationActions.reset({
    key: null,
    index: 0,
    routes: [{ name: 'AuthSwitchNavigator', params }],
    // actions: [NavigationActions.navigate({ name: 'AuthSwitchNavigator', params })],
  })

// AuthSwitchNavigator.tsx (อาจจะเอามา Customize ขึ้นอยู่กับสถานะการณ์ src/modules/stores/StoreMyView.tsx:178)
// export const switchToAuthSwitchNavigator = (params: { store_id: number }) => NavigationActions.navigate(route.name,  { store_id }, {
//   type: 'Navigation/NAVIGATE',
//   name: 'StoreOrderListView',
//   params: { store_id },
// })
