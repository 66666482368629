import { fromJS } from 'immutable'
import { take, takeLatest, put, call, fork, all } from 'redux-saga/effects'
import _ from 'lodash'
import * as util from '../../utils/util'
import api from '../../utils/api'
import * as ContactActions from '../../modules/contact/ContactState'

export default function* watchAllContacts() {
  yield all([fork(watchCustomerListFetch), fork(watchCustomerAdd), fork(watchCustomerEdit), fork(watchCustomerDelete)])
}
// fork(watchContactAdd),
// fork(watchContactListFetch),

// function* watchContactListFetch() {
//   util.log('In watchContactList')
//   while (true) {
//     const { payload } = yield take(actions.CONTACT_LIST_FETCH)
//     util.log('In watchContactList get Action', payload)
//     yield call(fetchContactList, payload)
//   }
//   // yield takeLatest(actions.CONTACT_LIST_FETCH, fetchContactList)
// }
//
// // FIXME: สำหรับ fetch contact list
// function* fetchContactList(payload) {
//   util.log('fetchContactList')
//   let apiToFetch = null
//   switch (payload) {
//     case CONS.CONTACT_ACTOR_KEYS.CUSTOMER:
//       apiToFetch = api.GET_CUSTOMERS
//       break
//     default:
//       apiToFetch = null
//   }
//
//   if (apiToFetch) {
//     try {
//       const res = yield call(api.get, apiToFetch)
//       if (_.has(res, 'profiles')) {
//         let contactList = sortByFirstName(res.profiles)
//         let contactListGroup = generateAlphabetContactGroup(contactList)
//       // util.log('_SAGA fetchContactList__contactListGroup_: ', contactListGroup)
//         yield put({
//           type: ContactActions.ACTION_CONTACT_LIST_LOAD,
//           payload: { contactList, contactListGroup, keys: CONS.CONTACT_LIST_STATE_KEYS[payload] },
//         })
//       }
//     // yield console.log('This is my contact list response: ', res)
//     } catch (error) {
//     // TODO: Handle this error action type
//       yield put({ type: actions.CONTACT_LIST_FETCH_FAILED, error })
//     }
//   }
// }

// สำหรับ add customer
// function* watchContactAdd() {
//   util.log('In watchContactAdd')
//   /* eslint-disable no-constant-condition */
//   while (true) {
//     const { payload } = yield take(actions.CONTACT_ADD_FETCH)
//     if ('actor' in payload) {
//       const { CUSTOMER, RESELLER } = CONS.CONTACT_ACTOR_KEYS
//
//       if (_.includes([CUSTOMER, RESELLER], payload.actor)) {
//         const profile = yield select(getContactEditing)
//         let body = cleanNull(profile.toJS())
//
//         if (_.has(body, 'phone1')) {
//           body.phone1 = parseInt(body.phone1)
//         }
//
//         if (_.has(body, 'addresses')) { // Clean null in addresses
//           body.addresses.forEach((addr, index) => {
//             body.addresses[index] = cleanNull(addr)
//             if (_.has(addr, 'postal_code')) {
//               body.addresses[index].postal_code = parseInt(addr.postal_code)
//             }
//             if (_.has(addr, 'telephone')) {
//               body.addresses[index].telephone = parseInt(addr.telephone)
//             }
//           })
//         }
//         util.log('_PUT_CUSTOMER__body_: ', cleanNull(body))
//         switch (payload.actor) {
//           case CUSTOMER:
//             yield call(addCustomer, body)
//             break
//           default:
//             util.log('__Do not match actor__')
//         }
//
//       }
//     }
//     //  yield call(fetchContactAdd, body) // FIXME: ยังไม่ได้ Validate
//     // const res = yield call(createStore, body)
//   }
// }

// Refactor Version
function* watchCustomerListFetch() {
  // console.log('In watchCustomerListFetch')
  yield takeLatest(ContactActions.ACTION_CUSTOMER_LIST_FETCH, fetchCustomerList)
}

function* fetchCustomerList() {
  // console.log('In fetchContactList')
  try {
    const res = yield call(api.get, api.GET_CUSTOMERS)
    yield call(handleResponseFromProfiles, res)
  } catch (error) {
    // TODO: Handle this error action type
    yield put({ type: ContactActions.ACTION_CUSTOMER_LIST_FETCH_FAILED, error })
  }
}

function* handleResponseFromProfiles(res) {
  if (_.has(res, 'profiles')) {
    const customerList = sortByFirstName(res.profiles)
    const customerListGroup = generateAlphabetContactGroup(customerList)
    // util.log('_SAGA fetchContactList__contactListGroup_: ', contactListGroup)
    yield put({
      type: ContactActions.ACTION_CUSTOMER_LIST_LOAD,
      payload: { customerList, customerListGroup },
    })
  }
}

function* watchCustomerAdd() {
  util.log('In watchCustomerAdd')
  while (true) {
    const { body, successCallback, failedCallback } = yield take(ContactActions.ACTION_CUSTOMER_ADD)
    yield call(addCustomer, body, successCallback, failedCallback)
  }
}

function* addCustomer(body, successCallback, failedCallback) {
  // console.log('In addCustomer ', body)
  const apiOptions = {
    messages: {
      successMsg: 'สร้างลูกค้าสำเร็จ',
      errorMsg: 'สร้างลูกค้าล้มเหลว',
    },
    showSpinner: true,
  }
  try {
    const res = yield call(api.put, api.PUT_CUSTOMER, body, apiOptions)
    // console.log('_PUT_CUSTOMER__res_: ', res)
    if ('profile' in res) {
      // FIXME: Manual Fetch List and GoBack
      yield fetchCustomerList()
      // yield put(NavActions.pop(1))
    }
    if (_.isFunction(successCallback)) {
      successCallback(res)
    }
  } catch (error) {
    // TODO: Handle this error action type
    if (_.isFunction(failedCallback)) {
      failedCallback(error)
    }
    // Alert.alert('บันทึกไม่สำเร็จ','กรุณาตรวจสอบความถูกต้องของข้อมูล และลองใหม่อีกครั้ง')
    // util.log('_PUT_CUSTOMER__error_: ', error)
    // yield put({ type: ContactActions.ACTION_CUSTOMER_ADD_FAILED, error })
  }
}

function* watchCustomerEdit() {
  util.log('In watchCustomerEdit')
  while (true) {
    const { payload } = yield take(ContactActions.ACTION_CUSTOMER_EDIT)
    yield call(editCustomer, payload)
  }
}

function* editCustomer(body) {
  util.log('In editCustomer')
  const apiOptions = {
    messages: {
      successMsg: 'แก้ไขข้อมูลลูกค้าสำเร็จ',
      errorMsg: 'แก้ไขข้อมูลลูกค้าล้มเหลว',
    },
    showSpinner: true,
  }
  try {
    const res = yield call(api.patch, api.PUT_CUSTOMER, body, apiOptions)
    util.log('_PATCH_CUSTOMER__res_: ', res)
    if ('profile' in res) {
      // FIXME: Manual Fetch List and GoBack
      // yield fetchCustomerList()
      yield put({ type: ContactActions.ACTION_CONTACT_SELECTED, payload: fromJS(res.profile) })
      // yield put(NavActions.pop(1))
    }
  } catch (error) {
    // TODO: Handle this error action type
    util.log('_PATCH_CUSTOMER__error_: ', error)
    yield put({ type: ContactActions.ACTION_CUSTOMER_EDIT_FAILED, error })
  }
}

function* watchCustomerDelete() {
  util.log('In watchCustomerDelete')
  while (true) {
    const { body, successCallback, failedCallback } = yield take(ContactActions.ACTION_CUSTOMER_DELETE)
    yield call(deleteCustomer, body, successCallback, failedCallback)
  }
}

function* deleteCustomer(body, successCallback, failedCallback) {
  // { profile_id }
  util.log('In deleteCustomer')
  const apiOptions = {
    messages: {
      successMsg: 'ลบลูกค้าสำเร็จ',
      errorMsg: 'ลบลูกค้าล้มเหลว',
    },
    showSpinner: true,
  }
  try {
    const res = yield call(api.del, api.DELETE_CUSTOMER, body, apiOptions)
    util.log('_DELETE_CUSTOMER__res_: ', res)
    // response as the same as fetch customer list
    yield call(handleResponseFromProfiles, res)
    if (_.isFunction(successCallback)) {
      successCallback(res)
    }
  } catch (error) {
    // TODO: Handle this error action type
    util.log('_DELETE_CUSTOMER__error_: ', error)
    yield put({ type: ContactActions.ACTION_CUSTOMER_DELETE_FAILED, error })
    if (_.isFunction(failedCallback)) {
      failedCallback(error)
    }
  }
}

// Data Management function
// Sort Contact Profiles
function sortByFirstName(profiles) {
  return _.sortBy(profiles, 'first_name')
}

// Group Contact for Section List
function generateAlphabetContactGroup(sortedProfile) {
  const group = 'กขฃคฅฆงจฉชซฌญฎฏฐฑฒณดตถทธนบปผฝพฟภมยรลวศษสหฬอฮABCDEFGHIJKLMNOPQRSTUVWXYZ'
  // util.log('_generateAlphabetContactGroup__group_: ', group)
  const tempProfile = _.clone(sortedProfile)
  const data = []
  for (let round = 0; round < 10; round++) {
    util.log('__start round__: ', round)
    for (let index = 0; index < group.length; index++) {
      const char = group[index]
      // @ts-ignore
      const cutByLength = _.remove(tempProfile, (profile) => profile.first_name.length <= round)
      if (cutByLength.length > 0) {
        const indexOfKey = _.findIndex(data, (d) => d.key === '#')
        if (indexOfKey < 0) {
          data.push({ key: '#', data: cutByLength })
        } else {
          data[indexOfKey].data.push.apply(data[indexOfKey].data, cutByLength)
        }
      }
      // @ts-ignore
      const filtered = _.remove(tempProfile, (profile) => profile.first_name[round].toUpperCase() === char.toUpperCase())
      if (filtered.length > 0) {
        const indexOfKey = _.findIndex(data, (d) => d.key === char)
        if (indexOfKey < 0) {
          data.push({ key: char, data: filtered })
        } else {
          data[indexOfKey].data.push.apply(data[indexOfKey].data, filtered)
        }
      }
    }
    util.log('__end round__: ', round)
    util.log('__tempProfile__: ', tempProfile)
  }
  if (tempProfile.length > 0) {
    const indexOfKey = _.findIndex(data, (d) => d.key === '#')
    if (indexOfKey < 0) {
      data.push({ key: '#', data: tempProfile })
    } else {
      data[indexOfKey].data.push.apply(data[indexOfKey].data, tempProfile)
    }
  }
  util.log('__data__: ', data)
  return data
}

function cleanNull(obj) {
  for (const propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName]
    }
  }
  return obj
}
