import React from 'react'
import CommonLogo, { ICommonLogoOptionalProps } from './CommonLogo'

const LOGO = require('../img/logo/peak.png')

const PeakLogo = (props: ICommonLogoOptionalProps) => <CommonLogo source={LOGO} {...props} />

interface IErpLogoProps extends ICommonLogoOptionalProps {
  erpSystemId: number
}
const ErpLogo = (props: IErpLogoProps) => {
  switch (props.erpSystemId) {
    case 1:
      return <PeakLogo {...props} />
  }

  return null
}

export default ErpLogo
