import React from 'react'
import _ from 'lodash'

import * as util from 'x/utils/util'

import { IXShippingConfig } from 'x/config/app'
import api from 'x/utils/api'
import { IXScreenProps } from 'x/types'

export interface BaseStoreAssistantMemberProps extends IXScreenProps {
  selectedStore: any
  getXShippingConfigFromRedux: IXShippingConfig[]
  setXShippingConfig: (newConfig: IXShippingConfig[]) => void
}

export interface BaseStoreAssistantMemberState {
  useKerry: boolean
  useAutoXShippingIsKerryReadyToShip: boolean
  loadingKerryWidth: number
  loadingKerryHeight: number
  useKerryCOD: boolean
  isKerryLoading: boolean

  useAutoXShippingIsKerryCODReadyToShip: boolean
  useAutoCreateCODPaymentWhenDelivered: boolean
  loadingKerryCODWidth: number
  loadingKerryCODHeight: number
  isKerryCODLoading: boolean
}

const STATE_KEY_FOR_UPDATE = {
  useKerry: 'useKerry',
  useAutoXShippingIsKerryReadyToShip: 'useAutoXShippingIsKerryReadyToShip',

  useKerryCOD: 'useKerryCOD',
  useAutoXShippingIsKerryCODReadyToShip: 'useAutoXShippingIsKerryCODReadyToShip',
  useAutoCreateCODPaymentWhenDelivered: 'useAutoCreateCODPaymentWhenDelivered',
}

export default abstract class BaseStoreAssistantMember extends React.Component<
  BaseStoreAssistantMemberProps,
  BaseStoreAssistantMemberState
> {
  // DISPLAY_TABS: IUserListTab[]
  // _tabRef: React.RefObject<any>
  // helperProfileId: number

  protected constructor(props) {
    super(props)

    this.state = {
      useKerry: false,
      useAutoXShippingIsKerryReadyToShip: true,
      loadingKerryWidth: 0,
      loadingKerryHeight: 0,
      isKerryLoading: false,
      useKerryCOD: false,
      useAutoXShippingIsKerryCODReadyToShip: true,
      useAutoCreateCODPaymentWhenDelivered: true,
      loadingKerryCODWidth: 0,
      loadingKerryCODHeight: 0,
      isKerryCODLoading: false,
    }
    // this._tabRef = React.createRef()
  }

  async componentDidMount() {
    const { getXShippingConfigFromRedux } = this.props
    // const { state } = navigation
    // const { tabIndex, permissionList, permission_id } = state.params
    await this.setKerryConfig(getXShippingConfigFromRedux)
  }

  setKerryConfig = async (config: IXShippingConfig[]) => {
    if (_.isNil(config)) {
      return
    }
    if (_.isArray(config) && config.length > 0) {
      let useKerry = false
      let useAutoXShippingIsKerryReadyToShip = false
      let useKerryCOD = false
      let useAutoXShippingIsKerryCODReadyToShip = false
      let useAutoCreateCODPaymentWhenDelivered = false
      config.map((config: IXShippingConfig) => {
        // if Kerry
        if (config.shipping_type_id === 4) {
          useKerry = true
          useAutoXShippingIsKerryReadyToShip = config.set_auto_request_xshipping_on_create_order
            ? config.set_auto_request_xshipping_on_create_order
            : false
          // if Kerry COD
        } else if (config.shipping_type_id === 24) {
          useKerryCOD = true
          useAutoXShippingIsKerryCODReadyToShip = config.set_auto_request_xshipping_on_create_order
            ? config.set_auto_request_xshipping_on_create_order
            : false
          useAutoCreateCODPaymentWhenDelivered = config.create_cod_payment_when_delivered ? config.create_cod_payment_when_delivered : false
        }
      })
      await util.setStatePromise(this, {
        useKerry,
        useAutoXShippingIsKerryReadyToShip,
        useKerryCOD,
        useAutoXShippingIsKerryCODReadyToShip,
        useAutoCreateCODPaymentWhenDelivered,
      })
    }
  }

  useKerryOnChange = (newValue: boolean) => {
    util.setStatePromise(this, {
      useKerry: newValue,
      isKerryLoading: true,
    })
  }

  useAutoXShippingIsKerryReadyToShipOnChange = (newValue: boolean) => {
    util.setStatePromise(this, {
      useAutoXShippingIsKerryReadyToShip: newValue,
    })
  }

  useKerryCODOnChange = (newValue: boolean) => {
    util.setStatePromise(this, {
      useKerryCOD: newValue,
    })
  }

  useAutoXShippingIsKerryCODReadyToShipOnChange = (newValue: boolean) => {
    util.setStatePromise(this, {
      useAutoXShippingIsKerryCODReadyToShip: newValue,
    })
  }

  _setNewConfig = async (newValue: { isValue: boolean; stateKey: string }) => {
    const { selectedStore, navigation, setXShippingConfig } = this.props
    const body: { [key: string]: any } = {
      store_id: selectedStore.get('id'),
      system_id: 4,
    }
    let apiMethod = api.patchV2
    const STATE_KEY = newValue.stateKey
    const IS_VALUE = newValue.isValue
    if (STATE_KEY === STATE_KEY_FOR_UPDATE.useKerry) {
      await util.setStatePromise(this, {
        isKerryLoading: true,
      })
      body.shipping_type_id = 4
      if (IS_VALUE) {
        apiMethod = api.putV2
        body.set_auto_request_xshipping_on_create_order = true
      } else {
        apiMethod = api.delV2
      }
    } else if (STATE_KEY === STATE_KEY_FOR_UPDATE.useAutoXShippingIsKerryReadyToShip) {
      await util.setStatePromise(this, {
        isKerryLoading: true,
      })
      body.shipping_type_id = 4
      body.set_auto_request_xshipping_on_create_order = IS_VALUE
    } else if (STATE_KEY === STATE_KEY_FOR_UPDATE.useKerryCOD) {
      await util.setStatePromise(this, {
        isKerryCODLoading: true,
      })
      body.shipping_type_id = 24
      if (IS_VALUE) {
        apiMethod = api.putV2
        body.set_auto_request_xshipping_on_create_order = true
        body.create_cod_payment_when_delivered = true
      } else {
        apiMethod = api.delV2
      }
    } else if (STATE_KEY === STATE_KEY_FOR_UPDATE.useAutoXShippingIsKerryCODReadyToShip) {
      await util.setStatePromise(this, {
        isKerryCODLoading: true,
      })
      body.shipping_type_id = 24
      body.set_auto_request_xshipping_on_create_order = IS_VALUE
    } else if (STATE_KEY === STATE_KEY_FOR_UPDATE.useAutoCreateCODPaymentWhenDelivered) {
      await util.setStatePromise(this, {
        isKerryCODLoading: true,
      })
      body.shipping_type_id = 24
      body.create_cod_payment_when_delivered = IS_VALUE
    }

    const isMainBtn = STATE_KEY === STATE_KEY_FOR_UPDATE.useKerry || STATE_KEY === STATE_KEY_FOR_UPDATE.useKerryCOD
    if (isMainBtn && !IS_VALUE) {
      apiMethod = api.delV2
    }

    const response: { xshipping_config: IXShippingConfig[] } = await apiMethod(api.XSHIPPING_CONFIG, body)
    if (response.xshipping_config) {
      setXShippingConfig(response.xshipping_config)
      await this.setKerryConfig(response.xshipping_config)
      // const { state } = navigation
      // const { onPressGoBack } = state.params
      // console.log('onPressGoBack => ', onPressGoBack)
      const onPressGoBack = util.getNavParam(this.props, 'onPressGoBack')
      if (_.isFunction(onPressGoBack)) {
        onPressGoBack()
      }
    }

    if (STATE_KEY === STATE_KEY_FOR_UPDATE.useKerry || STATE_KEY === STATE_KEY_FOR_UPDATE.useAutoXShippingIsKerryReadyToShip) {
      await util.setStatePromise(this, {
        isKerryLoading: false,
      })
    } else {
      await util.setStatePromise(this, {
        isKerryCODLoading: false,
      })
    }

    // console.log('response => ', response)
  }

  isSwitchOnChange = async (newValue: { isValue: boolean; stateKey: string }) => {
    // console.log('isSwitchOnChange => ', newValue)
    const STATE_KEY = newValue.stateKey
    const IS_VALUE = newValue.isValue
    let alertText = ''
    if (STATE_KEY === STATE_KEY_FOR_UPDATE.useKerry) {
      alertText = IS_VALUE ? 'ยืนยันการใช้งานออกเลขพัสดุ Kerry Express ใช่หรือไม่' : 'ยกเลิกการใช้งานออกเลขพัสดุ Kerry Express ใช่หรือไม่'
    } else if (STATE_KEY === STATE_KEY_FOR_UPDATE.useAutoXShippingIsKerryReadyToShip) {
      alertText = IS_VALUE
        ? 'ยืนยันการใช้งาน "ขอเลขพัสดุเมื่อพร้อมจัดส่ง" Kerry Express ใช่หรือไม่'
        : 'ยกเลิกการใช้งาน "ขอเลขพัสดุเมื่อพร้อมจัดส่ง" Kerry Express ใช่หรือไม่'
    } else if (STATE_KEY === STATE_KEY_FOR_UPDATE.useKerryCOD) {
      alertText = IS_VALUE
        ? 'ยืนยันการใช้งาน "ขอเลขพัสดุเมื่อพร้อมจัดส่ง" Kerry Express [COD] ใช่หรือไม่'
        : 'ยกเลิกการใช้งาน "ขอเลขพัสดุเมื่อพร้อมจัดส่ง" Kerry Express [COD] ใช่หรือไม่'
    } else if (STATE_KEY === STATE_KEY_FOR_UPDATE.useAutoXShippingIsKerryCODReadyToShip) {
      alertText = IS_VALUE
        ? 'ยืนยันการใช้งาน "ขอเลขพัสดุเมื่อพร้อมจัดส่ง" Kerry Express [COD] ใช่หรือไม่'
        : 'ยกเลิกการใช้งาน "ขอเลขพัสดุเมื่อพร้อมจัดส่ง" Kerry Express [COD] ใช่หรือไม่'
    } else if (STATE_KEY === STATE_KEY_FOR_UPDATE.useAutoCreateCODPaymentWhenDelivered) {
      alertText = IS_VALUE
        ? 'ยืนยันการใช้งาน "บันทึกรับชำระเมื่อพัสดุถึงผู้รับแล้ว" ใช่หรือไม่'
        : 'ยกเลิกการใช้งาน "บันทึกรับชำระเมื่อพัสดุถึงผู้รับแล้ว" ใช่หรือไม่'
    }

    // let isConfirm = false
    // await new Promise(async (resolve) => {
    //   p.op.showConfirmation('', alertText, () => {
    //     isConfirm = true
    //     // @ts-ignore
    //     resolve(null)
    //   }, () => {
    //     // @ts-ignore
    //     resolve(null)
    //   }, 'ตกลง', 'ยกเลิก')
    // })

    // if (!isConfirm) {
    //   await util.setStatePromise(this, {
    //     [newValue.stateKey]: !IS_VALUE,
    //   })
    //   return
    // }
    await this._setNewConfig(newValue)
    // util.setStatePromise(this, {
    //   [newValue.stateKey]: newValue.isValue,
    // })
  }
}
