import { connect } from 'react-redux'
import { getStoreSelected, getSubscription, getOrder } from 'x/redux/selectors'
import { getState, getDispatch } from 'x/modules/order/BaseOrderViewConnect'
import MultiParcelView from './MultiParcelView'

export default connect(
  state => ({
    selectedStore: getStoreSelected(state),
    subscription: getSubscription(state),
    order: getOrder(state),
    ...getState(state),
  }),
  dispatch => ({
    ...getDispatch(dispatch),
    // navigate: bindActionCreators(NavigationActions.navigate, dispatch),
  })
)(MultiParcelView)
