import { bindActionCreators } from 'redux'
import * as OrderActions from '../order/OrderState'
import * as StoreActions from '../store/StoreState'
import * as CategoryActions from '../category/categoryState'

import { getStoreSelected, getSelectedProductGroups, getSelectedUserGroups, getSellerStores, getCategoryList } from '../../redux/selectors'

export function getState(state) {
  return {
    selectedStore: getStoreSelected(state),
    sellerStores: getSellerStores(state),
    selectedProductGroups: getSelectedProductGroups(state), // PGs
    selectedUserGroups: getSelectedUserGroups(state), // UGs

    categoryList: getCategoryList(state),
  }
}

export function getDispatch(dispatch) {
  return {
    fetchMyStore: bindActionCreators(StoreActions.fetchMyStore, dispatch),

    // For quick add
    addProductToOrder: bindActionCreators(OrderActions.addProductToOrder, dispatch),
    quickAddProductToOrder: bindActionCreators(OrderActions.quickAddProductToOrder, dispatch),
    requestCategoryList: bindActionCreators(CategoryActions.requestCategoryList, dispatch),
  }
}
