import React from 'react'
import { View, TouchableOpacity, ScrollView } from 'react-native'
import _ from 'lodash'

// Extended
import BaseCategoryListOverlay from 'x/modules/store/Category/BaseCategoryListOverlay'
import { COLORS } from 'x/config/styles'
import { ICategory } from 'x/index'
// import HelpButton from '../../../components/HelpButton'
// import xCONS from 'x/config/constants'
// import XInput from '../../../components/XInput'
import XOverlay from 'xui/components/XOverlay'
import XText from 'xui/components/XText'
import Box from 'xui/components/Box'
import XIcon from 'xui/components/XIcon'
import XButton from 'xui/components/XButton'
import XSpinner from 'xui/components/XSpinner'
import VStack from 'xui/components/VStack'
import XScrollView from 'xui/components/XScrollView'
import HStack from 'xui/components/HStack'
// import XOverlay from '../../../components/XOverlay'

export default class BaseUICategoryListOverlay extends BaseCategoryListOverlay {
  static displayName = 'BaseUICategoryListOverlay'

  _pageLoading = () => {
    const { pageLoading } = this.state
    if (!pageLoading) {
      return null
    }
    return (
      <View
        style={{
          flex: 1,
          position: 'absolute',
          top: 0,
          left: 0,
          backgroundColor: 'rgba(52, 52, 52, 0.7)',
          width: '110%',
          height: '110%',
          zIndex: 999,
          justifyContent: 'center',
        }}>
        <XSpinner
          // color={COLORS.APP_MAIN}
          size='lg'
          // style={{
          //   alignSelf: 'center',
          //   justifyContent: 'center',
          // }}
        />
      </View>
    )
  }

  _renderHeaderCategory = () => {
    const { breadCrumbs } = this.state
    const haveCat = !_.isNil(breadCrumbs)
    return (
      <Box h='11' borderBottomWidth='1px' borderBottomColor='black' alignItems='center' justifyContent='center'>
        <Box flexDirection='row' w='full'>
          <ScrollView horizontal showsHorizontalScrollIndicator={false}>
            <TouchableOpacity
              // key={data.id}
              onPress={() => this._onPressBreadCrumbsHome()}
              style={{
                width: 44,
                height: 44,
                borderRadius: 7,
                marginRight: 4,
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <XIcon name='home' family='FontAwesome' color='black' />
            </TouchableOpacity>
            <XText color='black' fontSize='lg' textAlign='center' alignSelf='center'>
              /
            </XText>
            {haveCat
              ? breadCrumbs.map((data: ICategory) => (
                  <Box flexDirection='row' alignItems='center' key={data.id}>
                    <TouchableOpacity
                      onPress={() => this._seletedBreadCrumbs(data)}
                      style={{
                        minWidth: 50,
                        // width: 50,
                        maxWidth: 120,
                        height: 30,
                        borderColor: _.isNil(data.l) ? COLORS.TEXT_INACTIVE : data.l,
                        borderWidth: 1,
                        borderRadius: 7,
                        marginLeft: 4,
                        marginRight: 4,
                        // marginTop: 6,
                        justifyContent: 'center',
                      }}>
                      <XText color={_.isNil(data.l) ? COLORS.TEXT_INACTIVE : data.l} textAlign='center' py='0.5' px='0.5' numberOfLines={1}>
                        {data.n}
                      </XText>
                    </TouchableOpacity>
                    <XText textAlign='center' color='black' fontSize='lg' alignSelf='center'>
                      /
                    </XText>
                  </Box>
                ))
              : null}
          </ScrollView>
        </Box>
      </Box>
    )
  }

  _renderNoSubCategory = () => {
    const { selectedCategory } = this.state
    const text = _.isNil(selectedCategory)
      ? 'ยังไม่มีหมวดหมู่ คุณสามารถเพิ่มหมวดหมู่ได้ในหน้า\n "ตั้งค่า > หมวดหมู่ "'
      : `ไม่พบหมวดหมู่ย่อย`
    return (
      <View style={{ padding: 4 }}>
        <XText
          variant='inactive'
          // style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE }}
        >
          {text}
        </XText>
      </View>
    )
  }

  _renderBodySectionSubCategory = () => {
    const { mode, selectedCategoryProps } = this.props
    const { subCategory } = this.state
    const showSeletedCat = mode === 'SELETED_CATEGORY'
    const onPressSeleted = mode === 'SELETED_CATEGORY' ? this._seletedCat : this._seletedSupCategory
    if (_.isNil(subCategory) || subCategory.length < 1) {
      return this._renderNoSubCategory()
    }
    const sortSupCategory = _.orderBy(subCategory, ['index'], ['asc'])
    // console.log('sortSupCategory => ', sortSupCategory)
    return (
      <VStack w='full' px='3' py='2' space='2'>
        {sortSupCategory.map((data: ICategory, index: number) => {
          // const marginTop = index === 0 ? 0 : 10
          const haveChildren = this._chackChildren(data)
          const count = data.c.length
          if (mode === 'ON_CHANGE_GROUP') {
            if (!_.isNil(selectedCategoryProps) && selectedCategoryProps.id === data.id) {
              return null
            }
          }
          // console.log('data => ', data.n)
          return (
            <HStack w='full' key={index} alignItems='center' space='2'>
              {/* {showSeletedCat ? (
                <TouchableOpacity style={{ height: 40, width: 40, justifyContent: 'center' }} onPress={() => this._seletedCat(data)}>
                  <XIcon
                    name={'caretright'}
                    family={'AntDesign'}
                    style={{ color: data.selected ? COLORS.APP_MAIN : COLORS.BG_SOFT_GREY, flex: 0, minWidth: 30 }}
                  />
                </TouchableOpacity>
              ) : null} */}
              <TouchableOpacity
                // disabled={EDIT_MODE}
                onPress={() => onPressSeleted(data)}
                style={{
                  // minWidth: 50,
                  // width: 50,
                  // maxWidth: 120,
                  flex: 1,
                  // height: 40,
                  height: 54,
                  borderColor: data.l,
                  borderLeftWidth: 1,
                  borderRightWidth: 1,
                  borderTopWidth: 1,
                  borderBottomWidth: 1,
                  borderRadius: 7,
                  // marginLeft: 10,
                  // marginRight: 10,
                  // marginTop: 5,
                  // marginBottom: 5,
                  // justifyContent: 'center',
                  flexDirection: 'row',
                  alignItems: 'center',
                  backgroundColor: 'white',
                }}>
                {showSeletedCat ? (
                  <XIcon
                    name='caretright'
                    family='AntDesign'
                    style={{ color: data.selected ? COLORS.APP_MAIN : COLORS.BG_SOFT_GREY, flex: 0, minWidth: 30 }}
                  />
                ) : null}

                <XText
                  style={{
                    // fontSize: STYLES.FONT_SIZE_NORMAL,
                    color: _.isNil(data.l) ? COLORS.TEXT_INACTIVE : data.l,
                    textAlign: 'left',
                    paddingHorizontal: 10,
                    flex: 1,
                  }}
                  numberOfLines={1}>
                  {data.n}
                </XText>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingRight: !showSeletedCat ? 0 : 5,
                  }}>
                  <View
                    style={{
                      width: 30,
                      height: 20,
                      borderRadius: 7,
                      backgroundColor: COLORS.TEXT_INACTIVE,
                    }}>
                    <XText
                      style={{
                        color: COLORS.WHITE,
                        // fontSize: STYLES.FONT_SIZE_NORMAL,
                        textAlign: 'center',
                      }}>
                      {count}
                    </XText>
                  </View>
                  {!showSeletedCat ? (
                    <XIcon name='right' family='AntDesign' style={{ color: COLORS.TEXT_INACTIVE, flex: 0, minWidth: 30 }} />
                  ) : null}
                </View>
              </TouchableOpacity>
              {haveChildren && showSeletedCat ? (
                <XButton
                  colorScheme='black'
                  _light={{ bg: 'white' }}
                  variant='outline'
                  w='85px'
                  h='54px'
                  size='xs'
                  rightIcon={<XIcon name='right' family='AntDesign' variant='inactive' />}
                  // style={{
                  //   height: 40,
                  //   width: 85,
                  //   justifyContent: 'center',
                  //   flexDirection: 'row',
                  //   alignItems: 'center',
                  //   borderWidth: 0.8,
                  //   borderRadius: 7,
                  //   borderColor: COLORS.TEXT_INACTIVE,
                  //   marginTop: 5,
                  //   marginBottom: 5,
                  // }}
                  onPress={() => this._seletedSupCategory(data)}>
                  {/* <XText
                    style={{
                      // fontSize: STYLES.FONT_SIZE_NORMAL,
                      color: COLORS.TEXT_INACTIVE,
                      textAlign: 'center',
                      // paddingHorizontal: 10,
                      // flex: 1,
                    }}> */}
                  {/* {`หมวดหมู่` + '\n' + `ย่อย`} */}
                  หมวดหมู่ย่อย
                  {/* </XText> */}
                  {/* <XIcon name='right' family='AntDesign' style={{ color: COLORS.TEXT_INACTIVE, flex: 0, minWidth: 30 }} /> */}
                </XButton>
              ) : showSeletedCat ? (
                <View
                  style={{
                    // height: 40,
                    width: 85,
                    justifyContent: 'center',
                  }}
                />
              ) : null}
            </HStack>
          )
        })}
      </VStack>
    )
  }

  _renderFooter = () => {
    const { mode } = this.props
    const text = mode === 'SELETED_CATEGORY' ? 'ตกลง' : 'ย้ายลงตรงนี้'
    return (
      <VStack w='full' p='1'>
        <XButton
          h='10'
          // title={text}
          // type='solid'
          onPress={() => this._submitCategory()}
          // style={{ marginTop: 10 }}
          // buttonStyle={{ padding: 0, height: 35, width: '100%' }}
          // titleStyle={{ fontSize: STYLES.FONT_SIZE_LARGER }}
        >
          {text}
        </XButton>
      </VStack>
    )
  }

  renderMain = () => (
    <VStack
      w='full'
      _light={{ bg: 'muted.100' }}
      _dark={{ bg: 'gray.300' }}
      h='100%'
      // style={{ flex: 1, flexDirection: 'column', padding: 4 }}
    >
      <VStack
        w='full'
        style={{
          // height: 45,
          borderBottomWidth: 0.8,
          borderBottomColor: COLORS.BLACK,
        }}>
        {this._renderHeaderCategory()}
      </VStack>
      <XScrollView w='full'>{this._renderBodySectionSubCategory()}</XScrollView>
      {/* <View style={{ flex: 0, height: 35 }}>{this._renderFooter()}</View> */}
      {this._renderFooter()}
    </VStack>
  )

  renderMainApp = () => {
    const { visible } = this.props
    return (
      <XOverlay
        // contentStyle={{ height: 410, width: 300, alignSelf: 'center' }}
        visible={visible}
        onRequestClose={this._onRequestClose}>
        {this.renderMain()}
        {/* {this._pageLoading()} */}
      </XOverlay>
    )
  }

  // renderMainWeb = () => {
  //   const { visible } = this.props
  //   return (
  //     <XModal
  //       // contentStyle={{ height: 410, width: 300, alignSelf: 'center' }}
  //       visible={visible}
  //       webNumColumns={1}
  //       webWrapperContainerStyle={{ backgroundColor: 'white' }}
  //       onRequestClose={this._onRequestClose}>
  //       {this.renderMain()}
  //     </XModal>
  //   )
  // }

  render() {
    // return Platform.OS === 'web' ? this.renderMainWeb() : this.renderMainApp()
    return this.renderMainApp()
  }

  // render() {
  //   const { visible } = this.props
  //   return (
  //     <XOverlay
  //       contentStyle={{ height: 410, width: 300, alignSelf: 'center' }}
  //       visible={visible}
  //       onRequestClose={async () => await this._onRequestClose()}>
  //       { this.renderMain() }
  //     </XOverlay>
  //   )
  // }
}
