import React from 'react'
import * as util from 'x/utils/util'
import * as NavActions from 'x/utils/navigation'
import p from 'x/config/platform-specific'
import * as xFmt from 'x/utils/formatter'

// import BackIcon from '../../components/BackIcon'
// import XTabNavIcon from '../../components/XTabNavIcon'
// import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
// import SRowItem from '../../components/renderRowLeftAndRight'
import BaseSalePageSelectProductView from 'x/modules/storeWebsite/BaseSalePageSelectProductView'
import { COLORS } from 'x/config/styles'
import XCard from 'xui/components/XCard'
import HelpButton from 'xui/components/HelpButton'
import XButton from 'xui/components/XButton'
import HStack from 'xui/components/HStack'
import XText from 'xui/components/XText'
import AddStoreProductSelector from 'xui/components/order/AddStoreProductSelector'

import {
  IAddStoreProductSelectorOptions,
  IAddStoreProductSelectorPredefinedNavParams,
  IProductAfterAddStoreProductSelectorScannedOutput,
  IProductItemData,
  ISearchProductItem,
} from 'x/types'
import _ from 'lodash'
import VStack from 'xui/components/VStack'
import XIconButton from 'xui/components/XIconButton'
import XImage from 'xui/components/XImage'
import XNumericInput from 'xui/components/XNumericInput'
import XInput from 'xui/components/XInput'

const _renderAgreement = (onPressOpenOverlay: () => void) => <HelpButton onPress={() => onPressOpenOverlay()} />

export default abstract class BaseUISalePageSelectProductView extends BaseSalePageSelectProductView {
  _renderHeader = () => {
    const { isEdit, title } = this.state
    let leftProps = { backIcon: true, onPressItem: () => util.navGoBack(this.props) }
    let rightProps = null
    if (isEdit) {
      // @ts-ignore
      leftProps = { label: 'ตกลง', onPressItem: () => this._saveBtn() }
      rightProps = {
        label: 'ยกเลิก',
        onPressItem: () => {
          p.op.showConfirmation(
            '',
            'คุณต้องการกยเลิกการแก้ไขตัวเลือกสินค้านี้ใช่หรือไม่',
            () => util.navGoBack(this.props),
            () => null,
            'ยกเลิกและออกจากหน้านี้',
            'แก้ไขต่อ'
          )
        },
      }
    }
    return (
      <XCustomHeader
        title={title}
        headerLeftProps={leftProps}
        headerRightProps={rightProps}
        // renderHeaderRight={this._renderHeaderRight}
      />
    )
  }

  _renderInputName = () => {
    const { productName } = this.state
    return (
      <HStack w='full'>
        <XText variant='active'>ชื่อ:</XText>
        <XInput
          multiline
          maxFontSizeMultiplier={2}
          style={{ marginRight: 4, flex: 1 }}
          ml='2'
          h='16'
          placeholder='ระบุชื่อตัวเลือกตัวเลือก'
          value={productName}
          onChangeText={(newText: string) => this._onChangeProductName(newText)}
        />
      </HStack>
    )
  }

  _renderTitle = () => {
    const { productIndex } = this.state
    return (
      <HStack w='full'>
        <HStack>
          {/* <TouchableOpacity
            style={{
              height: 42,
              width: 42,
              backgroundColor: COLORS.BG_LIGHT_GREY,
              borderRadius: 90,
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <XIcon name='delete' family='AntDesign' />
          </TouchableOpacity> */}
        </HStack>
        <HStack flex={1} alignItems='center' justifyContent='center'>
          <XText variant='active' fontSize='md'>
            {`สินค้า #${productIndex + 1}`}
          </XText>
        </HStack>
      </HStack>
    )
  }

  _renderMainView = () => {
    const {} = this.state
    return (
      <XCard w='full' p='2'>
        {/* {this._renderTitle()} */}
        {/* {this._renderInputName()} */}
        <HStack mt='2' />
        {this._renderProductSelector()}
        {/* {this._renderSelectedProducts()} */}
      </XCard>
    )
  }

  _renderProductSelector = () => {
    const {
      optionChooseProductsByGroupIndex,
      optionChooseProductsFollowingIndex,
      optionChooseProductsFromIndex,
      optionSelectedSellerStoreIndex,
    } = this.state
    return (
      <AddStoreProductSelector
        saveOptionSelectedSellerStoreIndexKey='SALEPAGE_ASPS_SELECTED_SELLER_STORE'
        saveOptionChooseProductsFromIndexKey='SALEPAGE_ASPS_CHOOSE_PRODUCT_FROM'
        saveOptionChooseProductsFollowingIndexKey='SALEPAGE_ASPS_CHOOSE_PRODUCT_FOLLOWING'
        saveOptionChooseProductsByGroupIndexKey='SALEPAGE_ASPS_CHOOSE_PRODUCT_GROUP'
        // forceUseOnlyUg={forceUseOnlyUg}
        // forceUseOnlyPg={forceUseOnlyPg}
        onChangeOptions={this._onChangeAddStoreProductSelectorOptions}
        optionChooseProductsByGroupIndex={optionChooseProductsByGroupIndex}
        optionChooseProductsFollowingIndex={optionChooseProductsFollowingIndex}
        optionChooseProductsFromIndex={optionChooseProductsFromIndex}
        optionSelectedSellerStoreIndex={optionSelectedSellerStoreIndex}
        onChooseProductButtonPressed={this._onAddStoreProductSelectorChooseProductButtonPressed}
        onSearchProductButtonPressed={this._onAddStoreProductSelectorSearchProductButtonPressed}
        onChooseProductAfterBarcodeScanned={this._onChooseProductAfterBarcodeScanned}
      />
    )
  }

  _onChangeAddStoreProductSelectorOptions = (newOpts: IAddStoreProductSelectorOptions) => {
    // console.log('_onChangeAddStoreProductSelectorOptions newOpts => ', newOpts)
    this.setState(newOpts)
  }

  _onAddStoreProductSelectorChooseProductButtonPressed = (
    predefinedNavParams: IAddStoreProductSelectorPredefinedNavParams,
    selectedOptions: IAddStoreProductSelectorOptions
  ) => {
    // console.log('_onAddStoreProductSelectorChooseProductButtonPressed predefinedNavParams => ', predefinedNavParams)
    // console.log('_onAddStoreProductSelectorChooseProductButtonPressed selectedOptions => ', selectedOptions)

    const { navigation } = this.props
    const isMineProducts = _.isNil(predefinedNavParams.seller_store_id)
    const { mode } = this.state
    const generateNavAction = isMineProducts
      ? NavActions.navToProductListViewPullMyProductsToSalePage
      : NavActions.navToProductListViewPullSellersProductsToSalePage

    // @ts-ignore
    const navAction = generateNavAction({
      ...predefinedNavParams,
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onProductListItemButtonPressed: this._onProductListItemButtonPressed,
      mode,
      // goBackSalePage: () => {
      //   console.log('goBackSalePage => ')
      //   this._onSeletedProduct()
      // },
    })
    navigation.dispatch(navAction)
  }

  _onProductListItemButtonPressed = async (product: IProductItemData) => {
    const { selectedProducts, mode } = this.state
    const existProductAtIndex = selectedProducts.findIndex((sp) => sp.v[0].i === product.v[0].i)
    // console.log('product // => ', product)
    if (existProductAtIndex !== -1) {
      p.op.showToast('เพิ่มสินค้านี้แล้ว', 'warning')
      return
    }
    const name = product.n === '' ? product.v[0].n : product.n
    p.op.showToast(`เพิ่มสินค้า ${name} เรียบร้อยแล้ว`, 'success')
    const newProduct = _.clone(product)
    // const variantName = product.v[0].n === '' ? '' : `${product.n} - ${product.v[0].n}`
    // const newName = product.n === '' ? variantName : product.n
    // newProduct.n = newName
    await util.setStatePromise(this, {
      selectedProducts: selectedProducts.concat(newProduct),
    })
    // this.setState({ selectedProducts: selectedProducts.concat(newProduct) })
    this._setIsEdit()
    if (mode === 'SINGLE_PRODUCT_MODE') {
      this._onSeletedProduct(product)
    } else {
      this._onSeletedProduct(product)
    }
  }

  onPressSearchableProductListItem = (item: ISearchProductItem) => {
    // console.log('item item => ', item)
    const { selectedProducts } = this.state

    const existProductAtIndex = selectedProducts.findIndex((sp) => sp.v[0].i === item.v[0].i)

    if (existProductAtIndex !== -1) {
      p.op.showToast('เพิ่มสินค้านี้แล้ว', 'warning')
      return
    }

    const variantName = `${item.n} - ${item.v[0].n}`
    // @ts-ignore
    const newProduct: IProductItemData = {
      i: item.v[0].s[0].p,
      id: item.id,
      n: variantName,
      v: [
        {
          i: item.v[0].i,
          n: item.v[0].n,
          p: item.v[0].s[0].p,
          q: 0,
          c: item.v[0].s[0].p,
        },
      ],
      q: 0,
      t: item.t,
    }
    const newSelectedProducts = _.clone(selectedProducts)
    newSelectedProducts.push(newProduct)
    // console.log(' newSelectedProducts.push(newProduct) => ', newSelectedProducts)
    // return
    this.setState({ selectedProducts: newSelectedProducts })
    this._setIsEdit()
    p.op.showToast('เพิ่มสินค้าเรียบร้อย', 'success')
  }

  _onAddStoreProductSelectorSearchProductButtonPressed = (
    predefinedNavParams: IAddStoreProductSelectorPredefinedNavParams,
    selectedOptions: IAddStoreProductSelectorOptions
  ) => {
    const { navigation } = this.props

    // console.log('_onAddStoreProductSelectorSearchProductButtonPressed predefinedNavParams => ', predefinedNavParams)
    // console.log('_onAddStoreProductSelectorSearchProductButtonPressed selectedOptions => ', selectedOptions)
    // onSuccessPullMyProductToOrderCallback: this.onPressSearchableProductListItem,
    // navigation.dispatch(NavActions.navToSearchableProductListView({ ...predefinedNavParams, title: '-' }))
    // @ts-ignore
    navigation.dispatch(
      NavActions.navToSearchableProductListView({
        ...predefinedNavParams,
        // @ts-ignore
        title: '-',
        onProductItemPressed: this.onPressSearchableProductListItem,
      })
    )
  }

  _onChooseProductAfterBarcodeScanned = (output: IProductAfterAddStoreProductSelectorScannedOutput) => {
    console.log('_onAddStoreProductSelectorSearchProductButtonPressed output => ', output)
  }

  _renderSelectedProductItem = (pd: IProductItemData, index: number) => {
    // console.log('pd => ', pd)
    const { selectedProducts } = this.state
    const _onQtyChange = (newVal: string) => {
      // console.log('newVal => ', newVal)

      // Make a copy of selectedProducts array
      const newSelectedProducts = [...selectedProducts]
      // console.log('newSelectedProducts => ', newSelectedProducts)
      // Check if the item at the specified index exists
      if (newSelectedProducts[index]) {
        // Update the count_number property of the specified item
        newSelectedProducts[index] = {
          ...newSelectedProducts[index],
          count_number: +newVal,
        }
        // console.log('newSelectedProducts // 02 => ', newSelectedProducts)
        // Update the state with the modified array
        this.setState({ selectedProducts: newSelectedProducts })
        this._setIsEdit()
      }
    }

    const ProductName = pd.n
    // const variant = pd.v[0]
    // const productQty = pd.v[0].q
    let productPrice = pd.v[0].p
    if (_.isNil(productPrice)) {
      productPrice = pd.v[0].s[0].p
    }
    // console.log('pd // => ', pd)
    // return null
    return (
      <VStack w='full' key={index.toString()}>
        <HStack>
          <XText>{`ตัวเลือกสินค้าที่ #${index + 1}`}</XText>
        </HStack>
        <XCard>
          <HStack w='full' p='2' space='1.5'>
            <XImage w='68px' h='68px' source={{ uri: pd.t[0] }} />

            <VStack flex={1}>
              <XText variant='inactive' bold>
                {ProductName}
              </XText>

              {/* <HStack w='full' alignItems='center' space='1'>
              <XText variant='inactive'>จำนวน</XText>
              <XText variant='active'>{productQty}</XText>
            </HStack> */}

              {/* <HStack>
              <XText variant='inactive'>จำนวน</XText>
              <XText variant='active'>{pd.q}</XText>
            </HStack> */}

              <HStack>
                <XText variant='inactive'>{`ราคา `}</XText>
                <XText variant='active'>
                  {xFmt.formatCurrency(productPrice)}
                  {/* {pd.x === pd.i ? xFmt.formatCurrency(pd.x) : `${xFmt.formatCurrency(pd.i)} - ${xFmt.formatCurrency(pd.x)}`} */}
                </XText>
              </HStack>

              <HStack>
                {/* <XText variant='inactive'>ราคา</XText> */}
                <XNumericInput
                  // isDisabled={!isQtyEditable}
                  w='32'
                  minH='9'
                  // h='9'
                  // keyboardType='numeric'
                  // value={`${qty.toString()}` || '0'}
                  value={pd.count_number || 1}
                  minValue={1}
                  maxValue={99999}
                  maxLength={5}
                  onChangeText={_onQtyChange}
                />
              </HStack>
            </VStack>

            <HStack w='80px' alignItems='center' justifyContent='center'>
              <XIconButton
                variant='solid'
                iconVariant='danger'
                name='trash'
                family='FontAwesome'
                onPress={() => this._removeSelectedProduct(pd)}
              />
            </HStack>
          </HStack>
        </XCard>
      </VStack>
    )
  }

  _removeSelectedProduct = (pd: IProductItemData) => {
    const { selectedProducts } = this.state
    const newSelectedProducts = selectedProducts.filter((sp) => sp.v[0].i !== pd.v[0].i)
    this.setState({ selectedProducts: newSelectedProducts })
  }

  _renderSelectedProducts = () => {
    const { selectedProducts } = this.state
    if (_.isNil(selectedProducts)) {
      return null
    }
    // console.log('_renderSelectedProducts => ', selectedProducts)
    return (
      <VStack w='full' mt='1'>
        <HStack>
          <XText variant='inactive'>ตัวเลือกสินค้า</XText>
        </HStack>
        {selectedProducts.length > 1 ? (
          <HStack>
            <XText color={COLORS.BRAND_Warning}>
              หากตัวเลือกสินค้าของคุณมีหลายตัวเลือก เช่น หลายไซส์ หลายสี และสามารถเลือกได้เพียงตัวเลือกเดียวเท่านั้น
            </XText>
          </HStack>
        ) : null}
        <VStack w='full' space='1.5'>
          {selectedProducts.map(this._renderSelectedProductItem)}
        </VStack>
      </VStack>
    )
  }

  _renderFooter = () => (
    // const { selectedPackages } = this.state
    // if (_.isNil(selectedPackages)) {
    //   return null
    // }
    <XButton mb='0.5' mx='0.5' onPress={() => null}>
      ชำระเงิน
    </XButton>
  )

  render() {
    return (
      <XContainer>
        {this._renderHeader()}
        <XContent p='2' style={{ backgroundColor: COLORS.BG_LIGHT_GREY }}>
          {this._renderMainView()}
        </XContent>
        {/* {this._renderFooter()} */}
      </XContainer>
    )
  }
}
