import React from 'react'
import _ from 'lodash'

// import api from 'x/utils/api'
import * as util from 'x/utils/util'
import CONS from 'x/config/constants'
import { COLORS } from 'x/config/styles'

import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import XText from 'xui/components/XText'
import XInput from 'xui/components/XInput'
import XIconButton from 'xui/components/XIconButton'
import { IApiOptions, ISearchableProductListViewProps, ISearchableProductListViewState, ISearchProductItem } from 'x/types'
import { FlatList, ListRenderItemInfo, TextInput } from 'react-native'
import XCard from 'xui/components/XCard'
import api from 'x/utils/api'
import XImage from 'xui/components/XImage'
import Box from 'xui/components/Box'
import XSpinner from 'xui/components/XSpinner'
import ErrorListItem from 'xui/components/ErrorListItem'
import XIcon from 'xui/components/XIcon'

const { NAME, COST, PRICE, PRICE_RETAIL, QTY, AVAILABLE_QTY, WEIGHT, H, SKU, UPC, CATEGORY } = CONS.PRODUCT_ATTR

export default class SearchableProductListView extends React.Component<ISearchableProductListViewProps, ISearchableProductListViewState> {
  static displayName = 'SearchableProductListView'

  // NodeJs.Timeout
  searchProductTimeoutId?: string | number | any

  inProcess?: boolean

  isFetchingMore?: boolean

  searchInputRef: React.RefObject<TextInput>

  constructor(props) {
    super(props)

    this.state = {
      searchText: '',
      isInitFetched: false,

      items: [],
      itemCount: 0,
      fetchErrorMessage: null,

      isFetchingInit: false,
      isFetchingMore: false,
    }

    this.searchProductTimeoutId = null

    this.searchInputRef = React.createRef()
  }

  // componentDidMount() {
  //   util.delay(200)
  //   // setTimeout(this._autoFocusFirstInput, 1500)
  //   console.log('SearchableProductListView::componentDidMount this.props => ', this.props)
  //   const navParams = util.getNavParams(this.props)
  //   console.log('SearchableProductListView::componentDidMount navParams => ', navParams)
  // }

  _onSearchChangeText = (searchText: string) => {
    this.setState({ searchText, isInitFetched: false }, () => {
      if (searchText === '') {
        this._clearSearch()
        return
      }

      if (this.searchProductTimeoutId) {
        clearTimeout(this.searchProductTimeoutId)
      }

      this.searchProductTimeoutId = setTimeout(this.fetchInit, 600)
    })
  }

  // doSearchProduct = () => {
  //   this.fetchProducts()
  // }

  isHelper = () => {
    const { selectedStore } = this.props
    return selectedStore.has('role_id') ? selectedStore.get('role_id') === CONS.STORE_ROLE.HELPER : false
  }

  fetchInit = async () => {
    await util.setStatePromise(this, { isFetchingInit: true })

    await util.setStatePromise(this, { itemCount: 0, items: [], fetchErrorMessage: null })
    await this.fetchProducts({ offset: 0 })
    await util.delay(100)

    await util.setStatePromise(this, { isInitFetched: true, isFetchingInit: false })
  }

  fetchMore = async () => {
    if (this.isFetchingMore || this.state.isFetchingMore) {
      return
    }
    this.isFetchingMore = true

    if (!this.state.isInitFetched || this.state.isFetchingInit) {
      this.isFetchingMore = false
      return
    }

    if (!this.hasLoadMore()) {
      this.isFetchingMore = false
      return
    }

    await util.setStatePromise(this, { isFetchingMore: true })

    const { items } = this.state
    // console.log('SearchableProductListView::fetchMore items.length => ', items.length)
    await this.fetchProducts({ offset: items.length })
    await util.delay(100)

    await util.setStatePromise(this, { isFetchingMore: false })
    this.isFetchingMore = false
  }

  hasLoadMore = () => {
    const { items = [], itemCount = 0 } = this.state
    const currentItemCount = items.length
    if (currentItemCount >= itemCount) {
      return false
    }
    return true
  }

  getOnPressListItem = (item: ISearchProductItem) => () => {
    // this.onPressListItem(item)

    const onProductItemPressed = util.getNavParam(this.props, 'onProductItemPressed')

    if (_.isNil(onProductItemPressed) || !_.isFunction(onProductItemPressed)) {
      throw new Error('onProductItemPressed is not a function')
    }

    onProductItemPressed(item)
  }

  // onPressListItem = async (item: ISearchProductItem) => {
  //   try {
  //     const aItem = _.cloneDeep(item)
  //     // @ts-ignore
  //     aItem.v[0].q = 9999999

  //     // @ts-ignore
  //     if (_.isNil(aItem.v[0].p)) {
  //       // @ts-ignore
  //       aItem.v[0].p = aItem.v[0].s[0].p
  //     }

  //     // this._handlePullProductToOrder(aItem)

  //     const seller_store_id = util.getNavParam(this.props, 'seller_store_id')
  //     const product_group_ids = util.getNavParam(this.props, 'pg_ids')
  //     const user_group_id = util.getNavParam(this.props, 'ug_id')

  //     this._handlePullProductToOrder({
  //       // pp_id: aItem.v[0].i,
  //       // @ts-ignore
  //       product: aItem,
  //       qty: 1,
  //       seller_store_id,
  //       product_group_ids,
  //       user_group_id,
  //     })

  //     // console.log('onPressListItem item => ', item)

  //     // console.log('onPressListItem res => ', res)
  //   } catch (err) {
  //     console.log('onPressListItem err => ', err)
  //   }
  // }

  fetchProducts = async (extraParams: any) => {
    // const res = await api.post()
    const { searchText } = this.state
    // console.log('fetchProducts extraParams => ', extraParams)
    // console.log('fetchProducts searchText => ', searchText)

    const store_id = util.getNavParam(this.props, 'store_id')
    const seller_store_id = util.getNavParam(this.props, 'seller_store_id')
    const ug_id = util.getNavParam(this.props, 'ug_id')
    const pg_ids = util.getNavParam(this.props, 'pg_ids')

    const apiOpt: IApiOptions = {
      axiosOptions: {
        timeout: 30000,
      },
      showSpinner: false,
      isApiV2: true,
    }

    const queryTextArrayFiltered = searchText.split(/(\s+)/g).filter((str) => str.trim().length > 0)
    const queryTextArray = queryTextArrayFiltered.slice(0, 12)

    const reqBody = {
      store_id: seller_store_id || store_id,
      // product_id: item.pt_id,
      offset: 0,
      // limit: PAGINATION_LIMIT,
      limit: CONS.PRODUCTS_FETCH_LIMIT_MORE,
      // c: 1,
      // query_text_and: [searchText],
      query_text_and: queryTextArray,
    }

    if (_.isNumber(extraParams.offset)) {
      reqBody.offset = extraParams.offset
    }

    if (reqBody.offset === 0) {
      // @ts-ignore c คือขอ total count
      reqBody.c = 1
    }

    if (ug_id) {
      // @ts-ignore
      reqBody.user_group_id = ug_id
    }

    if (pg_ids && pg_ids.length > 0) {
      // @ts-ignore
      reqBody.product_group_ids = pg_ids
    }

    if (this.isHelper() && !seller_store_id) {
      // @ts-ignore
      reqBody.is_helper = true
    }

    try {
      const res = await api.post('products/search', reqBody, apiOpt)

      console.log('fetchProducts res => ', res)
      // this.isFetching = false
      // return res

      // // this.setState({ items: mockItems })
      if (res.c) {
        await util.setStatePromise(this, { itemCount: res.c })
      }

      if (res.count) {
        await util.setStatePromise(this, { itemCount: res.count })
      }

      if (res.products) {
        const { items } = this.state
        await util.setStatePromise(this, { items: items.concat(res.products) })
      }
    } catch (err) {
      console.log('fetchProducts err => ', err)
      if (reqBody.offset === 0) {
        await util.setStatePromise(this, { fetchErrorMessage: util.getErrorMessageFromErrorResponse(err) })
      }
    }
  }

  _goBack = () => {
    util.navGoBack(this.props)
  }

  // _handleOnPullProductToOrderSuccess = (newState: Map<string, any>): void => {
  //   // log('success callback of quick add => newState.toJS()', newState.toJS())
  //   const store_products = newState.getIn(['editingOrder', 'store_products']) || null
  //   const store_ids = store_products.keySeq().toArray() || []
  //   // log('success callback of quick add => store_ids', store_ids)
  //   if (store_products && store_ids.length > 0) {
  //     let products = List([])

  //     for (let sIdx = 0; sIdx < store_ids.length; sIdx++) {
  //       const sId = store_ids[sIdx]
  //       const sProds = store_products.get(sId) || List([])
  //       products = products.concat(sProds)
  //     }

  //     let txtSummary = ''
  //     for (let i = 0; i < products.size; i++) {
  //       if (i === 0) {
  //         txtSummary = 'สินค้าในออเดอร์ทั้งหมด\n'
  //       }
  //       const product = products.get(i)
  //       const name = product.get('name')
  //       const variant = product.get('variant')
  //       const qty = product.get('qty')
  //       const txtProductName = variant ? `${name} (${variant})` : name
  //       txtSummary += `\n${txtProductName} x${qty}`
  //     }
  //     // showSuccessToast(`เพิ่ม ${txtProductName} แล้ว`)
  //     if (txtSummary) {
  //       p.op.showToast(txtSummary, 'success')
  //     }
  //   }
  // }

  // _handlePullProductToOrder = async (params: {
  //   product: IProductItemData
  //   // pp_id: number
  //   qty: number
  //   seller_store_id?: number
  //   user_group_id?: number
  //   product_group_ids?: number[]
  // }): Promise<void> => {
  //   const { product, seller_store_id, user_group_id, product_group_ids, qty } = params
  //   const { navigation, selectedUserGroups, quickAddProductToOrder } = this.props
  //   const store_id = util.getNavParam(this.props, 'store_id')
  //   // const seller_store_id = util.getNavParam(this.props, 'seller_store_id')
  //   // const selectedVariantIndex = await util.getActionSheetAddOneProductToCartSelectedVariantIndex({
  //   //   selectedProductListItem: product,
  //   //   store_id,
  //   //   seller_store_id,
  //   // })

  //   // const selectedVariantIndex = product.variants.findIndex((v) => v.pp_id === selectedPpId)

  //   // // console.log('_handlePullProductToOrder:: selectedVariantIndex => ', selectedVariantIndex)

  //   // if (_.isNil(selectedVariantIndex) || selectedVariantIndex === -1) {
  //   //   return
  //   // }

  //   const willPullProductParams = await util.getPullProductParamsFromProductListItem({
  //     selectedProductListItem: product,
  //     selectedVariantIndex: 0,
  //     store_id,
  //     seller_store_id,
  //     selectedUserGroups,
  //     ug_id: user_group_id,
  //     pg_ids: product_group_ids,
  //     qty,
  //   })

  //   // console.log('_handlePullProductToOrder:: willPullProductParams => ', willPullProductParams)

  //   if (!willPullProductParams) {
  //     return
  //   }

  //   quickAddProductToOrder({
  //     store_id,
  //     seller_store_id,
  //     orderProducts: [willPullProductParams],
  //     ug_id: user_group_id,
  //     pg_ids: product_group_ids,
  //     callback: this._handleOnPullProductToOrderSuccess,
  //   })
  // }

  _keyExtractor = (item: ISearchProductItem, index: number) => `p_${index}_${item.id}`

  _renderListItem = (info: ListRenderItemInfo<ISearchProductItem>) => {
    const { item, index } = info
    // const { n, vn, t } = item
    const { n, v: vs, t } = item
    const v = vs[0]
    const productName = n || ''
    const variantName = v.n || ''
    const priceText = v.p ? v.p : v.s[0].p
    const sku = v.sku || null
    const upc = v.upc || null
    let imgUri = t[0]
    if (vs[0].img_url) {
      imgUri = vs[0].img_url
    }
    return (
      <XCard w='full' onPress={this.getOnPressListItem(info.item)}>
        <XCard.Body>
          <HStack w='full' space='1'>
            <XImage source={{ uri: imgUri }} h='64px' w='64px' borderRadius='lg' />
            <VStack p='1' flex={1}>
              <XText variant='inactive' numberOfLines={2}>
                {productName}
              </XText>

              <XText numberOfLines={2}>{variantName}</XText>

              {sku ? <XText variant='inactive'>{`SKU: ${sku}`}</XText> : null}
              {upc ? <XText variant='inactive'>{`UPC: ${upc}`}</XText> : null}

              <XText>{`฿${priceText}`}</XText>
              {/* <XText>{JSON.stringify(info)}</XText> */}
            </VStack>
          </HStack>
        </XCard.Body>
      </XCard>
    )
  }

  _renderEmptyList = () => {
    const { isInitFetched, searchText, isFetchingInit, fetchErrorMessage } = this.state

    if (isFetchingInit) {
      return (
        <VStack w='full' p='2' py='4' alignItems='center' justifyContent='center'>
          <XText>กำลังค้นหา...</XText>
        </VStack>
      )
    }

    if (searchText === '' && !isInitFetched) {
      return (
        <VStack w='full' p='2' pt='4' alignItems='center' justifyContent='center'>
          <XText>พิมพ์เพื่อค้นหาสินค้าจากข้อมูลเหล่านี้</XText>
          <VStack w='full' p='2' px='6'>
            <XText>- ชื่อสินค้า</XText>
            <XText>- ชื่อตัวเลือกสินค้า</XText>
            <XText>- SKU</XText>
            <XText>- UPC</XText>
          </VStack>
          <VStack w='full' p='2' px='4'>
            <XText>คุณสามารถ*เคาะวรรค*เพื่อกรองสินค้าที่มีทุกคำที่ระบุมาได้เช่น "กางเกงทะเล แดง 36"</XText>
            <XText pt='1.5'>
              ก็จะแสดงผลการค้นหาสินค้าที่มีทั้ง 3 คำนี้ "กางเกงทะเล" "แดง" และ "36" อยู่ใน ชื่อสินค้า ชื่อตัวเลือกสินค้า SKU หรือ UPC
              เป็นต้น
            </XText>
          </VStack>
        </VStack>
      )
    }

    if (!isInitFetched) {
      return null
    }

    if (_.isObject(fetchErrorMessage) && !_.isEmpty(fetchErrorMessage)) {
      return this._renderFetchErrorMessage()
    }

    const emptyWarningText = `ไม่พบรายการค้นหา "${searchText}"`

    return (
      <HStack w='full' p='2' py='4' alignItems='center' justifyContent='center' flexWrap='wrap'>
        <XText w='full' textAlign='center' textAlignVertical='center'>
          {emptyWarningText}
        </XText>
      </HStack>
    )
  }

  _renderListFooter = () => {
    const { isFetchingMore, isInitFetched, itemCount } = this.state

    if (!isInitFetched) {
      return null
    }

    if (isInitFetched && itemCount === 0) {
      return null
    }

    if (isFetchingMore) {
      return (
        <VStack w='full' p='2' alignItems='center' justifyContent='center'>
          <XSpinner size='sm' color={COLORS.TEXT_INACTIVE} />
        </VStack>
      )
    }

    return (
      <VStack w='full' p='2' alignItems='center' justifyContent='center'>
        <XText variant='inactive'>-- สิ้นสุดรายการ --</XText>
      </VStack>
    )
  }

  _renderListItemSeparator = () => <Box h='8px' w='full' />

  _renderListItems = () => {
    const { items = [] } = this.state
    return (
      <FlatList
        data={items}
        keyExtractor={this._keyExtractor}
        renderItem={this._renderListItem}
        // style={{ backgroundColor: 'rgba(0,188,0,0.5)' }}
        contentContainerStyle={{
          padding: 8,
          // backgroundColor: 'rgba(188,0,0,0.5)',
        }}
        ItemSeparatorComponent={this._renderListItemSeparator}
        ListFooterComponent={this._renderListFooter()}
        ListEmptyComponent={this._renderEmptyList()}
        onEndReached={this.fetchMore}
      />
    )
  }

  _clearSearch = () => {
    this.setState({ itemCount: 0, items: [], searchText: '', isInitFetched: false }, () => {
      if (this.searchProductTimeoutId) {
        clearTimeout(this.searchProductTimeoutId)
      }

      try {
        this.searchInputRef.current.focus()
      } catch (err) {
        console.log('_clearSearch err => ', err)
      }
    })
  }

  _renderCloseSearchButton = () => {
    const { searchText, isFetchingInit } = this.state
    if (searchText.length === 0) {
      return null
    }

    if (isFetchingInit) {
      return (
        <HStack h='9' w='32px' alignItems='center' justifyContent='center' position='absolute' right='0' top='0' bottom='0'>
          <XSpinner size='sm' />
        </HStack>
      )
    }

    return (
      <HStack h='9' w='32px' alignItems='center' justifyContent='center' position='absolute' right='0' top='0' bottom='0'>
        <XIconButton name='close' onPress={this._clearSearch} />
      </HStack>
    )
  }

  // แสดง error message จากการ fetch
  _renderFetchErrorMessage = () => {
    const { fetchErrorMessage } = this.state
    const handleshowAlertFetchError = () => util.showAlertFetchError(fetchErrorMessage)

    return <ErrorListItem onPressShowAlertInfo={handleshowAlertFetchError} onPressDoRefresh={this.fetchInit} />
  }

  _renderSearchInput = () => {
    const { searchText } = this.state
    return (
      <XInput
        ref={this.searchInputRef}
        flex={1}
        h='9'
        w='full'
        // px='50px'
        autoFocus
        placeholder='พิมพ์เพื่อค้นหาสินค้า...'
        value={searchText}
        maxLength={200}
        onChangeText={this._onSearchChangeText}
        style={{ paddingLeft: 18, paddingRight: 18 }}
      />
    )
  }

  _renderTitleText = () => {
    // @ts-ignore
    const { selectedStore, sellerStores, selectedUserGroups, selectedProductGroups } = this.props
    let targetStoreName = selectedStore.get('name')

    const seller_store_id = util.getNavParam(this.props, 'seller_store_id')
    const titleFromNavParam = util.getNavParam(this.props, 'title')

    const isSellerStore = _.isNumber(seller_store_id)
    if (isSellerStore) {
      const foundStore = sellerStores.find((ss) => ss.get('id') === seller_store_id)
      if (foundStore.get('name')) {
        targetStoreName = foundStore.get('name')
      } else {
        // should be impossible case
        targetStoreName = 'ร้านขายส่ง'
      }
    }

    let titleText = 'เพิ่มสินค้าเข้าออเดอร์จากร้าน'
    if (isSellerStore) {
      titleText = `${titleText}\n${targetStoreName}`
    } else {
      titleText = `${titleText}ฉัน`
    }

    const ug_id = util.getNavParam(this.props, 'ug_id')
    if (!isSellerStore && ug_id) {
      const foundUg = selectedUserGroups.find((ug) => ug.get('id') === ug_id)
      if (foundUg.get('name')) {
        titleText = `${titleText}\n(กลุ่มสมาชิก: ${foundUg.get('name')})`
      }
    }

    const pg_ids = util.getNavParam(this.props, 'pg_ids')
    if (!isSellerStore && pg_ids && pg_ids.length === 1) {
      const pg_id = pg_ids[0]
      const foundPg = selectedProductGroups.find((pg) => pg.get('id') === pg_id)
      if (foundPg.get('name')) {
        titleText = `${titleText}\n(ราคา: ${foundPg.get('name')})`
      }
    }

    if (_.isString(titleFromNavParam)) {
      titleText = titleFromNavParam
    }

    return (
      <XText bold textAlign='center' textAlignVertical='center' numberOfLines={2}>
        {titleText}
      </XText>
    )
  }

  _renderSearchInfoBar = () => {
    const { searchText = '', itemCount, isInitFetched, isFetchingInit, fetchErrorMessage } = this.state
    if (searchText === '') {
      return null
    }

    if (fetchErrorMessage && !_.isEmpty(fetchErrorMessage)) {
      return null
    }

    let txtSearchResult = ''

    if (isFetchingInit) {
      txtSearchResult = `กำลังค้นหาสินค้าที่มีชื่อประกอบด้วย "${searchText}" ...`
    }

    if (isInitFetched && itemCount === 0) {
      txtSearchResult = `ไม่พบสินค้าที่มีชื่อประกอบด้วย "${searchText}"`
    }

    if (isInitFetched && itemCount > 0) {
      txtSearchResult = `${itemCount} รายการตรงกับการค้นหา "${searchText}"`
    }

    return (
      <HStack w='full' h='9' p='2' alignItems='center' bg='white'>
        <XText fontSize='xs' variant='inactive' numberOfLines={2}>
          {txtSearchResult}
        </XText>
      </HStack>
    )
  }

  render() {
    return (
      <VStack w='full' flex={1}>
        <HStack w='full' px='2' py='1' space='2' bg='white'>
          <XIconButton name='arrow-back' onPress={this._goBack} />
          <HStack flex={1} alignItems='center' justifyContent='center'>
            {this._renderTitleText()}
          </HStack>
        </HStack>
        <HStack w='full' px='2' py='1' space='2' bg='white'>
          <HStack flex={1}>
            {this._renderSearchInput()}
            <HStack h='9' w='32px' alignItems='center' justifyContent='center' position='absolute' left='0' top='0' bottom='0'>
              <XIcon inactive name='search' />
            </HStack>
            {this._renderCloseSearchButton()}
          </HStack>
        </HStack>
        {this._renderSearchInfoBar()}
        {/* <HStack w='full' p='1' bg='white'>
          <XText>Search Text: {searchText}</XText>
        </HStack> */}
        <VStack w='full' flex={1} bg='muted.200'>
          {this._renderListItems()}
        </VStack>
      </VStack>
    )
  }
}
