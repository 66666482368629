import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as StoreActions from 'x/modules//store/StoreState'
import { getSelectedStore } from 'x/redux/selectors'
import ERPListView from './ERPListView'

export default connect(
  (state) => ({
    // ...getState(state),
    selectedStore: getSelectedStore(state),
    // subscription: getMySubscription(state),
    // loadingInProgress: getLoadingInProgress(state),
  }),
  (dispatch) => ({
    getErpChannels: bindActionCreators(StoreActions.getErpChannels, dispatch),
    // ...getDispatch(dispatch),
  })
)(ERPListView)
