import React from 'react'
import { Platform, StyleSheet } from 'react-native'
import * as util from 'x/utils/util'
// import Modal from 'react-modal'

import { IXWebViewMessage, IXWebViewProps, IXWebViewState } from 'x/types'
import _ from 'lodash'

export default class XWebView extends React.Component<IXWebViewProps, IXWebViewState> {
  static displayName = 'XWebView'

  _iframeRef: React.RefObject<HTMLIFrameElement>

  _iframeListener?: any

  constructor(props) {
    super(props)

    this.state = {
      isInitialized: false,
    }

    this._iframeRef = React.createRef<HTMLIFrameElement>()
  }

  componentDidMount(): void {
    window.addEventListener('message', this._onMessageReceived)

    this.setState({ isInitialized: true })
  }

  componentWillUnmount(): void {
    try {
      window.removeEventListener('message', this._onMessageReceived)
    } catch (error) {
      console.log('XWebView componentWillUnmount error => ', error)
    }
  }

  _doHandshake = () => {
    this.postMessage({ action: 'handshake' })
  }

  // _doInit = () => {
  //   this.postMessage({ action: 'init', token: '12345', hash: 'abcde' })
  // }

  _onIframeLoad = async () => {
    const { onLoad } = this.props

    await util.delay(100)
    this._doHandshake()
    // await util.delay(100)
    // this._doInit()

    if (_.isFunction(onLoad)) {
      await util.delay(500)
      // @ts-ignore
      onLoad(this)
    }
  }

  _onMessageReceived = (event) => {
    // console.log('_onMessageReceived event => ', event)

    let parsedData: any
    try {
      const { data } = event
      // console.log('_onMessage parse data data => ', data)
      parsedData = JSON.parse(data)
      // console.log('_onMessage parse data parsedData => ', parsedData)
    } catch (error) {
      // console.log('_onMessage parse data error => ', error)
      return
    }

    if (!parsedData.isForParent) {
      // console.log('_onMessage data is not for XSelly', parsedData)
      return
    }

    const { onMessageReceived, onHandshaked } = this.props
    if (parsedData.action === 'handshake' && _.isFunction(onHandshaked)) {
      onHandshaked()
    }

    if (_.isFunction(onMessageReceived)) {
      onMessageReceived(parsedData)
    }
  }

  // postMessage = (message: string) => {
  //   if (this._iframeRef.current) {
  //     this._iframeRef.current.contentWindow.postMessage(message, '*')
  //   }
  // }

  postMessage = (message: IXWebViewMessage) => {
    if (!_.isObject(message)) {
      throw new Error('XWebView postMessage message must be an object')
    }

    try {
      const sendingMessage = {
        platform: Platform.OS,
        isForChild: true,
        ...message,
      }

      this._iframeRef.current.contentWindow.postMessage(JSON.stringify(sendingMessage), '*')
    } catch (error) {
      console.log('XWebView postMessage error => ', error)
    }
  }

  render() {
    const { isInitialized } = this.state

    if (!isInitialized) {
      return null
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { uri, title = 'XWebView', style = {}, onLoad, ...restProps } = this.props

    return (
      <iframe
        ref={this._iframeRef}
        title={title}
        // @ts-ignore
        style={StyleSheet.flatten([{ flex: 1 }, style])}
        src={uri}
        onLoad={this._onIframeLoad}
        {...restProps}
      />
    )
  }
}
