import BaseUIDailySalesReportView from 'xui/modules/report/BaseUIDailySalesReportView'
import * as util from '../../utils/util-web'

export default abstract class DailySalesReportView extends BaseUIDailySalesReportView {
  // static navigationOptions = ({ navigation }) => ({
  //   title: 'รายงาน',
  //   // headerTitleStyle: TXT_LARGER_BOLD,
  //   headerLeft: (
  //     <Button onPress={() => navigation.goBack(navigation.state.key)}>
  //       <BackIcon />
  //     </Button>
  //   ),
  // })
  static navigationOptions = {
    header: null,
  }

  _handleOnDownloadFileByPlatform = async (url: string, fileName: string) => {
    await util.downloadFileFromUrl({ url, fileName })
  }
}
