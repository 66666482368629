import createSagaMiddleware from 'redux-saga'
import Reactotron from '../utils/reactotronConfig'

// @ts-ignore
const sagaMonitor = Reactotron ? Reactotron.createSagaMonitor() : null
export const sagaMiddleware = sagaMonitor ? createSagaMiddleware({ sagaMonitor }) : createSagaMiddleware()

const middlewareArray = [sagaMiddleware]

export default middlewareArray
