import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as StoreState from 'x/modules/store/StoreState'
import { getSelectedStore, getSelectedCustomOrderView } from 'x/redux/selectors'
import ReauthShopifyView from './ReauthShopifyView'

// import * as AddressActions from '../address/AddressState'

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
    selectedCustomOrderView: getSelectedCustomOrderView(state),
  }),
  (dispatch) => ({
    setPrefTimeslot: bindActionCreators(StoreState.setPrefTimeslot, dispatch),
    updateNewChannelList: bindActionCreators(StoreState.updateNewChannelList, dispatch),
    // pickerSelect: bindActionCreators(bpActions.pickerSelect, dispatch),
    // addCustomOrder: bindActionCreators(PreferenceState.addCustomOrder, dispatch),
    // loadPrefUIOrderView: bindActionCreators(PreferenceState.loadPrefUIOrderView, dispatch),
  })
)(ReauthShopifyView)
