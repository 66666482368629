// Extended
import BaseUICategoryPropertyView from 'xui/modules/store/Category/BaseUICategoryPropertyView'

export default class CategoryPropertyView extends BaseUICategoryPropertyView {
  static displayName = 'CategoryListView'

  static navigationOptions = {
    header: null,
  }
}
