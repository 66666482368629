import { connect } from 'react-redux'
// import { getState, getDispatch } from 'x/modules/order/BaseOrderViewConnect'
import { getSelectedStore } from 'x/redux/selectors'
import ERPChannelContact from './ERPChannelContact'

export default connect(
  (state) => ({
    // ...getState(state),
    selectedStore: getSelectedStore(state),
    // subscription: getMySubscription(state),
    // loadingInProgress: getLoadingInProgress(state),
  }),
  (dispatch) => ({
    // updateNewChannelList: bindActionCreators(StoreActions.updateNewChannelList, dispatch),
    // getChannels: bindActionCreators(StoreActions.getChannels, dispatch),
    // getShopeeAuthUrl: bindActionCreators(StoreActions.shopeeAuthUrl, dispatch),
    // getMkpReAuthUrl: bindActionCreators(StoreActions.mkpReAuthUrl, dispatch),
    // deleteMkpChannel: bindActionCreators(StoreActions.mkpReAuthUrl, dispatch),
    // ...getDispatch(dispatch),
  })
)(ERPChannelContact)
