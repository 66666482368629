import React from 'react'
import { ViewStyle, StyleProp, TextStyle, StyleSheet } from 'react-native'

import { IBarcodeScannerModalProps } from 'x/index'

import BarcodeScannerModal from 'xui/components/BarcodeScannerModal'
import XIcon from './XIcon'
import XButton from './XButton'

type IBarcodeScannerModalPropsExcluded = Pick<
  IBarcodeScannerModalProps,
  Exclude<keyof IBarcodeScannerModalProps, 'visible' | 'onRequestClose'>
>

interface IBarcodeScannerModalButtonProps extends IBarcodeScannerModalPropsExcluded {
  disabled?: boolean
  label?: string
  onRequestClose?: () => Promise<boolean> // return true will close modal
  btnStyle?: StyleProp<ViewStyle>
  iconStyle?: StyleProp<TextStyle>
  labelStyle?: StyleProp<TextStyle>
}

const BarcodeScannerModalButton: React.FC<IBarcodeScannerModalButtonProps> = (props) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false)

  async function _closeModal() {
    setIsModalVisible(false)
  }

  function _openBarcodeModal() {
    setIsModalVisible(true)
  }

  async function _onRequestClose() {
    if (props.onRequestClose) {
      const isUserConfirmClose = await props.onRequestClose()
      if (!isUserConfirmClose) {
        return
      }
    }
    _closeModal()
  }

  return (
    <XButton
      isDisabled={props.disabled}
      disabled={props.disabled}
      // _stack={{ flexDirection: 'column' }}
      _text={{ fontSize: 'xs' }}
      w='11'
      h='11'
      flexDir='column'
      // flexDirection='column'
      alignContent='center'
      justifyContent='center'
      startIcon={
        <XIcon
          name='barcode-scan'
          family='MaterialCommunityIcons'
          variant='activeDark'
          style={StyleSheet.flatten(props.iconStyle)}
          w='6'
          // style={StyleSheet.flatten([
          //   S.TEXT_ICON_ACTIVE_DARK,
          //   { width: STYLES.FONT_ICON_NORMAL, height: STYLES.FONT_ICON_NORMAL },
          //   StyleSheet.flatten(props.iconStyle),
          // ])}
        />
      }
      style={props.btnStyle}
      // style={[
      //   S.HEIGHT_44,
      //   S.WIDTH_44,
      //   S.BORDER,
      //   S.BORDERS_PRIMARY,
      //   S.BG_PRIMARY,
      //   S.BORDER_RADIUS_6,
      //   S.COLUMN_CENTER,
      //   StyleSheet.flatten(props.btnStyle),
      // ]}
      onPress={_openBarcodeModal}>
      {/* <Icon
        name='barcode-scan'
        type='MaterialCommunityIcons'
        style={StyleSheet.flatten([
          S.TEXT_ICON_ACTIVE_DARK,
          { width: STYLES.FONT_ICON_NORMAL, height: STYLES.FONT_ICON_NORMAL },
          StyleSheet.flatten(props.iconStyle),
        ])}
      /> */}
      {/* {props.label ? (
        <Text style={[S.TEXT_ACTIVE_DARK, S.TEXT_SMALL, S.TEXT_BOLD, S.TEXT_ALIGN_RIGHT, StyleSheet.flatten(props.labelStyle)]}>
          {props.label}
        </Text>
      ) : null} */}
      {/* <VStack space='1'> */}
      {/* <XText variant='activeDark' fontSize='xs'> */}
      {props.label}
      {/* </XText> */}
      <BarcodeScannerModal {...props} visible={isModalVisible} onRequestClose={_onRequestClose} headerTitle={props.headerTitle} />
      {/* </VStack> */}
    </XButton>
  )
}

BarcodeScannerModalButton.displayName = 'BarcodeScannerModalButton'
BarcodeScannerModalButton.defaultProps = {
  btnStyle: {},
  iconStyle: {},
  labelStyle: {},
}

export default BarcodeScannerModalButton
