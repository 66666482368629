import BaseUIPGSelectorView from 'xui/modules/product/BaseUIPGSelectorView'

export default class PGSelectorView extends BaseUIPGSelectorView {
  static displayName = 'PGSelectorView'

  static navigationOptions = {
    header: null,
    title: null,
    headerLeft: null,
    headerRight: null,
  }

  // handleNavToVolumeDiscountListView = (pg_id) => {
  //   console.log('No.Impl::PGSelector::handleNavToVolumeDiscountListView')
  //   // const { editingProduct, navigation, selectedStore } = this.props
  //   // const mode = navigation.getParam('mode')
  //   // const { dispatch } = navigation
  //   // const store_id = selectedStore.get('id')
  //   // const pt_id = editingProduct.get('id')
  //   // let pg_vd_ids = editingProduct.get('pg_vd_ids')
  //   // if (_.isNil(pg_vd_ids)) {
  //   //   pg_vd_ids = null
  //   // }
  //   // this.onSubmitEditing()
  //   // const product_group_ids = editingProduct.get('product_group_ids')
  //   // console.log('----------------> ' + this.props.mode)
  //   // this.setState({ isVisibleSelector: false })
  //   // dispatch(
  //   //   NavActions.navToVolumeDiscountListView({
  //   //     store_id,
  //   //     pg_id,
  //   //     pt_id,
  //   //     pg_vd_ids,
  //   //     mode: 'PULL_MODE',
  //   //     productMode: mode,
  //   //     product_group_ids,
  //   //   })
  //   // )
  // }

  // // FIXME: O: อันนี้ Override เพื่อซ่อนปุ่มส่วนลดขายส่งเฉพาะใน Web
  // _renderCheckboxHaveVolumeDiscountList = (
  //   usePrefixOrSuffix,
  //   prefix,
  //   suffix,
  //   type,
  //   variants,
  //   productGroupId,
  //   productGroupName,
  //   checked,
  //   VIEW_VOLUME_DISCOUNT
  // ) => {
  //   const s_use_volume_discount = this.props.selectedStore.get('s_use_volume_discount')
  //   return (
  //     <View key={productGroupId}>
  //       <Pressable
  //         onPress={() => this._toggleCheckboxAtId(productGroupId, checked)}
  //         style={[
  //           STYLES.NO_MARGIN,
  //           {
  //             paddingLeft: 14,
  //             paddingRight: 14,
  //             paddingTop: 8,
  //             paddingBottom: 8,
  //             // paddingRight: 14,
  //             // borderBottomWidth: 0,
  //             // borderColor: COLORS.APP_SECONDARY,
  //             backgroundColor: 'white',
  //             borderColor: '#cacaca',
  //           },
  //         ]}>
  //         <HStack
  //           style={{
  //             flex: 1,
  //             borderBottomWidth: 0,
  //             justifyContent: 'center',
  //             alignItems: 'center',
  //           }}>
  //           <XIcon
  //             family='FontAwesome'
  //             style={{
  //               flex: 1,
  //               fontSize: STYLES.FONT_ICON_NORMAL,
  //               color: COLORS.APP_MAIN,
  //             }}
  //             name={checked ? 'check-square-o' : 'square-o'}
  //           />
  //         </HStack>
  //         <HStack style={{ flex: 5, borderBottomWidth: 0, justifyContent: 'center' }}>
  //           <Text
  //             allowFontScaling={false}
  //             style={{
  //               textAlign: 'left',
  //               fontSize: STYLES.FONT_SIZE_NORMAL,
  //               color: checked ? COLORS.TEXT_ACTIVE : COLORS.TEXT_INACTIVE,
  //             }}>
  //             {productGroupName}
  //           </Text>
  //         </HStack>
  //         {/* {s_use_volume_discount ? ( */}
  //         {/*  <Right style={{ flex: 3, alignItems: 'flex-end' }}> */}
  //         {/*    {checked && !VIEW_VOLUME_DISCOUNT ? ( */}
  //         {/*      <Button */}
  //         {/*        onPress={() => { */}
  //         {/*          this.handleNavToVolumeDiscountListView(productGroupId) */}
  //         {/*        }} */}
  //         {/*        style={{ */}
  //         {/*          backgroundColor: COLORS.BG_LIGHT_GREY, */}
  //         {/*          height: 35, */}
  //         {/*          width: 130, */}
  //         {/*          borderColor: COLORS.APP_MAIN, */}
  //         {/*          borderWidth: 1, */}
  //         {/*          borderRadius: 7, */}
  //         {/*          paddingLeft: 2, */}
  //         {/*          paddingRight: 2, */}
  //         {/*        }}> */}
  //         {/*        <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, paddingLeft: 12, paddingRight: 0, color: COLORS.APP_MAIN }}> */}
  //         {/*          {'แก้ไขส่วนลดขายส่ง'} */}
  //         {/*        </Text> */}
  //         {/*      </Button> */}
  //         {/*    ) : null} */}
  //         {/*    {VIEW_VOLUME_DISCOUNT ? ( */}
  //         {/*      <Button */}
  //         {/*        onPress={() => { */}
  //         {/*          this._unpairVolumeDiscount(productGroupId, productGroupName) */}
  //         {/*        }} */}
  //         {/*        style={{ */}
  //         {/*          backgroundColor: COLORS.BG_LIGHT_GREY, */}
  //         {/*          height: 35, */}
  //         {/*          width: 130, */}
  //         {/*          borderColor: COLORS.APP_MAIN, */}
  //         {/*          borderWidth: 1, */}
  //         {/*          borderRadius: 7, */}
  //         {/*          paddingLeft: 2, */}
  //         {/*          paddingRight: 2, */}
  //         {/*        }}> */}
  //         {/*        <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, paddingLeft: 12, paddingRight: 0, color: COLORS.APP_MAIN }}> */}
  //         {/*          {'ยกเลิกส่วนลดขายส่ง'} */}
  //         {/*        </Text> */}
  //         {/*      </Button> */}
  //         {/*    ) : null} */}
  //         {/*  </Right> */}
  //         {/* ) : null} */}
  //       </Pressable>
  //       {checked ? (
  //         <Box>
  //           <RangeAdjuster
  //             disabled
  //             metadata={[
  //               {
  //                 // key: discountKey,
  //                 prefix,
  //                 suffix,
  //                 usePrefixOrSuffix,
  //                 key: 'val',
  //                 label: type === 1 ? 'ส่วนลด (บาท)' : 'ส่วนลด (%)',
  //                 span: 2,
  //                 defaultValue: '',
  //                 keyboardType: 'numeric',
  //               },
  //             ]}
  //             data={variants}
  //             labelAddButton='เพิ่มช่วงส่วนลด'
  //             labelLastEnd='ขึ้นไป'
  //           />
  //         </Box>
  //       ) : null}
  //     </View>
  //   )
  // }

  // // FIXME: O: อันนี้ Override เพื่อซ่อนปุ่มส่วนลดขายส่งเฉพาะใน Web
  // _renderCheckbox = (productGroupID, productGroupName, checked, VIEW_VOLUME_DISCOUNT) => {
  //   const s_use_volume_discount = this.props.selectedStore.get('s_use_volume_discount')
  //   return (
  //     <Pressable
  //       key={productGroupID}
  //       // button={!checked}
  //       onPress={() => this._toggleCheckboxAtId(productGroupID, checked)}
  //       style={[
  //         STYLES.NO_MARGIN,
  //         {
  //           paddingLeft: 14,
  //           paddingRight: 14,
  //           paddingTop: 8,
  //           paddingBottom: 8,
  //           // paddingRight: 14,
  //           // borderBottomWidth: 0,
  //           // borderColor: COLORS.APP_SECONDARY,
  //           backgroundColor: 'white',
  //           borderColor: '#cacaca',
  //           height: 40,
  //           maxHeight: 40,
  //         },
  //       ]}>
  //       <HStack
  //         style={{
  //           flex: 1,
  //           borderBottomWidth: 0,
  //           justifyContent: 'center',
  //           alignItems: 'center',
  //         }}>
  //         <XIcon
  //           family='FontAwesome'
  //           style={{
  //             flex: 1,
  //             fontSize: STYLES.FONT_ICON_NORMAL,
  //             color: COLORS.APP_MAIN,
  //           }}
  //           name={checked ? 'check-square-o' : 'square-o'}
  //         />
  //       </HStack>
  //       <HStack style={{ flex: 5, borderBottomWidth: 0, justifyContent: 'center' }}>
  //         <Text
  //           allowFontScaling={false}
  //           style={{
  //             textAlign: 'left',
  //             fontSize: STYLES.FONT_SIZE_NORMAL,
  //             color: checked ? COLORS.TEXT_ACTIVE : COLORS.TEXT_INACTIVE,
  //           }}>
  //           {productGroupName}
  //         </Text>
  //       </HStack>
  //       {/* {s_use_volume_discount ? ( */}
  //       {/*  <Right style={{ flex: 3, alignItems: 'flex-end' }}> */}
  //       {/*    {checked && !VIEW_VOLUME_DISCOUNT ? ( */}
  //       {/*      <Button */}
  //       {/*        onPress={() => { */}
  //       {/*          this.handleNavToVolumeDiscountListView(productGroupID) */}
  //       {/*        }} */}
  //       {/*        style={{ */}
  //       {/*          backgroundColor: COLORS.APP_MAIN, */}
  //       {/*          height: 35, */}
  //       {/*          width: 130, */}
  //       {/*          borderRadius: 7, */}
  //       {/*          paddingLeft: 2, */}
  //       {/*          paddingRight: 2, */}
  //       {/*        }}> */}
  //       {/*        <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, paddingLeft: 12, paddingRight: 0, flex: 0 }}>{'เพิ่มส่วนลดขายส่ง'}</Text> */}
  //       {/*      </Button> */}
  //       {/*    ) : null} */}
  //       {/*  </Right> */}
  //       {/* ) : null} */}
  //     </Pressable>
  //   )
  // }
}
