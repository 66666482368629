import { Map, List } from 'immutable'
import { setDailySalesInReports } from '../../redux/selectors' // Change path

// import {loop, Effects} from 'redux-loop-symbol-ponyfill'
// import {generateRandomNumber} from '../../services/randomNumberService'
import actions from '../../config/actions'
import { ActionApiParams } from '../../index'
// import { createStore } from 'redux'

// Initial state
const initialState = Map({
  dailySales: Map({
    date: new Date(),
    today: Map({}),
    past: List([]),
  }),
})

// export function fetchDailySalesReport(body: { store_id: number, daysInPast?: number, showToday?: boolean }) {
export function fetchDailySalesReport(params: ActionApiParams) {
  return {
    type: actions.FETCH_REPORT_DAILY_SALES,
    // payload: body,
    ...params,
  }
}

// Reducer
export default function ReportStateReducer(state = initialState, action = {}) {
  // @ts-ignore
  const { type, payload } = action
  const s = state
  switch (type) {
    case actions.SET_REPORT_DAILY_SALES: {
      return setDailySalesInReports(s, payload)
    }
    default:
      return s
  }
}
