import { Component } from 'react'

import * as util from 'x/utils/util'
import { log, setStatePromise, isSystemBankID } from 'x/utils/util'
import { fromJS, List, Map } from 'immutable'
import _ from 'lodash'
import moment from 'moment'
import { fetchPaymentAccounts } from 'x/utils/api'
import p from '../../config/platform-specific'
import {
  IBasePaymentListViewProps,
  IBasePaymentListViewState,
  IPaymentListItemMap,
  IPaymentListItem,
  IPaymentListItemOrderToAmountMap,
  NavigationEventSubscription,
} from '../../index'
import CONS from '../../config/constants'
import actions from '../../config/actions'
import { SHOULD_FETCH_ORDER_DETAIL } from '../order/OrderState'

import * as fmt from '../../utils/formatter'
import * as acl from '../../utils/acl'

const { OPENED, CONFIRMED, REJECTED_OR_CANCELED } = CONS.PAYMENT_STATUS

export default abstract class BasePaymentListView extends Component<IBasePaymentListViewProps, IBasePaymentListViewState> {
  static displayName = 'PaymentListView'

  inProcess: boolean

  isRefreshing?: boolean

  isUnmounting?: boolean

  hasConfirmPaymentPermission: boolean

  inProcessPaymentId: {
    [key: string]: boolean
  }

  isReVerifySlipButtonPressedMap?: {
    [key: string | number]: boolean
  }

  navWillFocusSubscription?: NavigationEventSubscription

  // navWillBlurSubscription?: NavigationEventSubscription

  constructor(props) {
    super(props)
    this.state = {
      refreshing: false,
      previewVisible: false,
      // previewImageURI: '',
      previewImageUris: [],
      isInitialized: false,

      myPaymentAccounts: [],
    }

    this.inProcess = false
    this.isRefreshing = false
    this.inProcessPaymentId = {}
    this.isReVerifySlipButtonPressedMap = {}
    this.hasConfirmPaymentPermission = acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.PAYMENT_EDIT)
  }

  async componentDidMount() {
    const { onRef, shouldFetchPaymentList } = this.props
    // Set Ref if have onRef function
    if (_.isFunction(onRef)) {
      onRef(this)
    }
    // shouldFetchPaymentList()
    await this.fetchMyPaymentAccounts()
    await this.onRefresh()
    await setStatePromise(this, { isInitialized: true })

    this._subscribeNavigationEvents()
  }

  _subscribeNavigationEvents = () => {
    const { navigation } = this.props
    this.navWillFocusSubscription = navigation.addListener('focus', this._setShoudRefreshPaymentList)
    // this.navWillBlurSubscription = navigation.addListener('willBlur', this._setShoudRefreshPaymentList)
  }

  _setShoudRefreshPaymentList = () => {
    const { shouldFetchPaymentList } = this.props
    shouldFetchPaymentList()
  }

  _unsubsribeNavigationEvents = () => {
    if (this.navWillFocusSubscription) {
      this.navWillFocusSubscription()
    }
    // if (this.navWillBlurSubscription) {
    //   this.navWillBlurSubscription.remove()
    // }
  }

  // componentDidUpdate(prevProps, prevState): void {
  //   // if (prevProps.shouldFetchList !== this.props.shouldFetchList && this.props.shouldFetchList) {
  //
  // }

  // componentWillReceiveProps(nextProps) {
  //   const { shouldFetchList } = nextProps
  //
  //   log('BasePaymentListView componentWillReceiveProps shouldFetchList => ', shouldFetchList)
  //   if (shouldFetchList && !this.state.refreshing) {
  //     this._fetchPaymentList()
  //   }
  // }

  async componentDidUpdate(prevProps) {
    const { shouldFetchList } = this.props
    log(this, 'componentDidUpdate shouldFetchList => ', shouldFetchList)
    if (!prevProps.shouldFetchList && shouldFetchList) {
      // await this._fetchPaymentList()
      await this.onRefresh()
    }
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   const isStateChanged = xUtil.isDiff(this.state, nextState, ['previewVisible', 'previewImageURI', 'refreshing'])
  //   const isPropsChanged = xUtil.isDiff(this.props, nextProps, ['selectedPaymentList', 'shouldFetchList'])
  //   return isPropsChanged || isStateChanged
  // }

  componentWillUnmount() {
    const { onRef } = this.props
    // Destroy Ref if have onRef function
    if (_.isFunction(onRef)) {
      onRef(undefined)
    }

    if (!this.isUnmounting) {
      this.isUnmounting = true
      this.props.clearPayment()
      this._unsubsribeNavigationEvents()
    }
  }

  fetchMyPaymentAccounts = async () => {
    if (this.isRefreshing) {
      return
    }
    this.isRefreshing = true

    await setStatePromise(this, { refreshing: true })

    const store_id = util.getNavParam(this.props, 'store_id')

    try {
      const myPaymentAccounts = await fetchPaymentAccounts({ store_id })
      await util.setStatePromise(this, { myPaymentAccounts })
    } catch (err) {
      console.log('fetchMyPaymentAccounts err', err)
    }

    await setStatePromise(this, { refreshing: false })
    this.isRefreshing = false
  }

  onRefresh = async (): Promise<void> => {
    if (this.isRefreshing) {
      return
    }
    this.isRefreshing = true

    await setStatePromise(this, { refreshing: true })
    const isFinishBackgroundTasks = this.isFinishBackgroundTasks()
    if (isFinishBackgroundTasks) {
      await this._fetchPaymentList()
    }
    await setStatePromise(this, { refreshing: false })
    this.isRefreshing = false
  }

  _fetchPaymentList = async (): Promise<void> => {
    await setStatePromise(this, { refreshing: true })
    const { navigation } = this.props
    const storeId = util.getNavParam(this.props, 'store_id')
    const paymentAccountId = util.getNavParam(this.props, 'payment_account_id')
    const orderId = util.getNavParam(this.props, 'order_id')
    const verifySlipId = util.getNavParam(this.props, 'verify_slip_id')

    const res = await new Promise((resolve) => {
      if (storeId) {
        let body
        body = { store_id: storeId }
        if (orderId) {
          body.order_id = orderId
        }
        if (paymentAccountId) {
          body.payment_account_id = paymentAccountId
        }
        if (verifySlipId) {
          body.verify_slip_ids = [verifySlipId]
        }
        this.props.fetchPaymentList({
          body,
          successCallback: resolve,
          failedCallback: resolve,
        })
      }
    })

    if (res) {
      // -- do something after success ?? --
      // await setStatePromise(this, { refreshing: false })
    }
  }

  _getMyStoreId = () => {
    const { navigation } = this.props
    return util.getNavParam(this.props, 'store_id')
  }

  getIsGoBackButtonHidden = () => {
    const { navigation } = this.props
    return util.getNavParam(this.props, 'isGoBackButtonHidden', false)
  }

  confirmPayment = async (paymentId: number): Promise<void> => {
    if (this.inProcessPaymentId[paymentId]) {
      return
    }
    this.inProcessPaymentId[paymentId] = true

    const { navigation } = this.props
    const storeId = util.getNavParam(this.props, 'store_id')
    if (storeId && paymentId) {
      // this.props.confirmPayment({ store_id, payment_id, refreshOrderDetail })

      await this._setSubmittingPaymentId(paymentId)
      const res: any = await new Promise((resolve) => {
        this.props.confirmPayment({
          body: {
            store_id: storeId,
            payment_id: paymentId,
          },
          successCallback: resolve,
          failedCallback: () => resolve(null),
        })
      })
      await this._unsetSubmittingPaymentId(paymentId)

      if (res && res.payment) {
        await this._handleAfterSuccessMakePaymentDecision(res)
      } else {
        await this._handleAfterFailedMakePaymentDecision(paymentId)
      }
    }
    this.inProcessPaymentId[paymentId] = false
    // delete this.inProcessPaymentId[paymentId]
  }

  _getSubbmittingKeyByPaymentId = (paymentId): string => `submitting_${paymentId}`

  _getSubmittingByPaymentId = (paymentId: number): boolean =>
    _.has(this.state, this._getSubbmittingKeyByPaymentId(paymentId)) ? this.state[this._getSubbmittingKeyByPaymentId(paymentId)] : false

  _setSubmittingPaymentId = async (paymentId) => {
    const stateObj = {}
    stateObj[this._getSubbmittingKeyByPaymentId(paymentId)] = true
    await setStatePromise(this, stateObj)
  }

  _unsetSubmittingPaymentId = async (paymentId) => {
    const stateObj = {}
    stateObj[this._getSubbmittingKeyByPaymentId(paymentId)] = false
    await setStatePromise(this, stateObj)
  }

  rejectPayment = async (paymentId: number): Promise<void> => {
    if (this.inProcessPaymentId[paymentId]) {
      return
    }
    this.inProcessPaymentId[paymentId] = true

    const { navigation } = this.props
    const storeId = util.getNavParam(this.props, 'store_id')
    if (storeId && paymentId) {
      const isUserConfirm = await p.op.isUserConfirm('ปฏิเสธการชำระ', 'คุณต้องการปฏิเสธการแจ้งชำระนี้ใช่หรือไม่')
      if (!isUserConfirm) {
        this.inProcessPaymentId[paymentId] = false
        // delete this.inProcessPaymentId[paymentId]
        return
      }

      await this._setSubmittingPaymentId(paymentId)
      const res: any = await new Promise((resolve) => {
        this.props.rejectPayment({
          body: {
            store_id: storeId,
            payment_id: paymentId,
          },
          successCallback: resolve,
          failedCallback: () => resolve(null),
        })
      })
      await this._unsetSubmittingPaymentId(paymentId)

      if (res && res.payment) {
        await this._handleAfterSuccessMakePaymentDecision(res)
      } else {
        await this._handleAfterFailedMakePaymentDecision(paymentId)
      }
    }
    this.inProcessPaymentId[paymentId] = false
    // delete this.inProcessPaymentId[paymentId]
  }

  cancelPayment = async (paymentId: number): Promise<void> => {
    if (this.inProcessPaymentId[paymentId]) {
      return
    }
    this.inProcessPaymentId[paymentId] = true

    const { navigation } = this.props
    const storeId = util.getNavParam(this.props, 'store_id')
    if (storeId && paymentId) {
      const isUserConfirm = await p.op.isUserConfirm('ยกเลิกการชำระ', 'คุณต้องการยกเลิกรายการชำระนี้ใช่หรือไม่')
      if (!isUserConfirm) {
        this.inProcessPaymentId[paymentId] = false
        // delete this.inProcessPaymentId[paymentId]
        return
      }

      await this._setSubmittingPaymentId(paymentId)
      const res: any = await new Promise((resolve) => {
        this.props.cancelPayment({
          body: {
            store_id: storeId,
            payment_id: paymentId,
          },
          successCallback: resolve,
          failedCallback: () => resolve(null),
        })
      })
      await this._unsetSubmittingPaymentId(paymentId)

      if (res && res.payment) {
        await this._handleAfterSuccessMakePaymentDecision(res)
      } else {
        await this._handleAfterFailedMakePaymentDecision(paymentId)
      }
    }
    this.inProcessPaymentId[paymentId] = false
    // delete this.inProcessPaymentId[paymentId]
  }

  dispatchRelatedShouldFetch = () => {
    const { dispatch } = this.props
    dispatch({ type: actions.STORE_ORDERS_SHOULD_FETCH_ALL }) // Refresh Order List
    dispatch({ type: actions.STORE_ORDERS_SHOULD_FETCH_SINGLE_TAB_KEY, payload: 'myTasks_confirmGettingPaid' })
    dispatch({ type: actions.SELECTED_STORE_SHOULD_FETCH }) // StoreMyView + Donut
    dispatch({ type: SHOULD_FETCH_ORDER_DETAIL }) // StoreMyView + Donut
  }

  _handleAfterSuccessMakePaymentDecision = async (res: { payment: IPaymentListItem }): Promise<void> => {
    // console.log('_handleAfterConfirmPayment res => ', res)
    // const {  } = res.payment
    // if (res) {
    //   yield put(paymentActions.shouldFetchPaymentList())
    //   if (refreshOrderDetail) {
    //     yield put(orderActions.shouldFetchOrderDetail())
    //   }
    // }

    const { navigation } = this.props
    const onSuccessAcceptOrRejectPayment = util.getNavParam(this.props, 'onSuccessAcceptOrRejectPayment')

    if (_.isFunction(onSuccessAcceptOrRejectPayment)) {
      await onSuccessAcceptOrRejectPayment()
    }
  }

  _handleAfterFailedMakePaymentDecision = async (failedPaymentId: number): Promise<void> => {
    // console.log('_handleAfterConfirmPayment failedPaymentId => ', failedPaymentId)
  }

  _getOrderCountFromPaymentListItemOrders = (orders: List<IPaymentListItemOrderToAmountMap>) => {
    let orderCount = 0
    if (List.isList(orders) && orders.size) {
      orderCount = orders.size
    } else if (_.isArray(orders) && orders.length) {
      orderCount = orders.length
    }
    return orderCount
  }

  _getOrderToAmountItemsFromPaymentListItemOrders = (orders: List<IPaymentListItemOrderToAmountMap>) => {
    const ods = _.isArray(orders) ? fromJS(orders) : orders
    return ods.map((od) =>
      Map({ id: od.get('id'), txtOrderName: `#${od.get('id')}`, txtOrderAmount: fmt.formatCurrency(od.get('amount')) })
    )
  }

  _getPaymentInfoFromPaymentListItem = (index: number, item: IPaymentListItemMap) => {
    // Original data
    const paymentId = item.get('id')
    const paymentAccountId = item.get('payment_account_id')
    const orders = item.get('orders')
    const postDate = item.get('post_date')
    const createdAt = item.get('created_at')
    const confirmedAt = item.get('confirmed_at')
    const cancelledAt = item.get('cancelled_at')
    const rejectedAt = item.get('rejected_at')
    const isReadOnly = item.get('isReadOnly')
    const isRefund = item.get('isRefund')
    const totalAmountString = item.get('total_amount')
    const status = item.get('status')
    const imgUrisList = item.get('img_uris') || List([])
    const imgUris = List.isList(imgUrisList) ? imgUrisList.toArray() : imgUrisList
    const note = item.get('note')
    // const bankId = item.get('bank_id')
    const payerStoreId = item.get('payer_store_id')
    const payeeStoreId = item.get('payee_store_id')
    const verify_slip_error_status = item.get('verify_slip_error_status')
    const verify_slip_id = item.get('verify_slip_id')
    const verify_slip_transaction_ref = item.get('verify_slip_transaction_ref')

    // Compute data
    const bankId = item.get('bank_id')
    const isGBPay = this._isGBPay(bankId)
    const totalAmount = totalAmountString && isRefund ? parseFloat(totalAmountString) * -1 : parseFloat(totalAmountString)
    const myStoreId = this._getMyStoreId()
    const payerIsMe = myStoreId === payerStoreId
    const orderCount = this._getOrderCountFromPaymentListItemOrders(orders)
    const orderToAmountItems = this._getOrderToAmountItemsFromPaymentListItemOrders(orders)
    const txtTableHeaderOrder = `ใบสั่งซื้อ (${orderCount})`
    const txtTableHeaderAmount = 'ยอดชำระ'

    const txtBankName = this._getTxtBankNameFromBankId(bankId)
    const txtTotalAmount = this._getTxtTotalAmountFromPaymentListItemAmount(totalAmount)
    const txtPostDateTime = this._getTxtDateTime(postDate)
    const txtCreatedAt = `สร้างเมื่อ: ${this._getTxtDateTime(createdAt)}`
    const txtConfirmedAt = this._getTxtDateTime(confirmedAt)
    const txtCancelledAt = this._getTxtDateTime(cancelledAt)
    const txtRejectedAt = this._getTxtDateTime(rejectedAt)
    let txtPaymentStatus = this._getTxtPaymentStatus(status, isReadOnly, payerIsMe, rejectedAt, isRefund)

    const hasVerifySlip = verify_slip_id || !_.isNil(verify_slip_error_status) || _.isString(verify_slip_transaction_ref)
    const isVerifySlipSuccess = hasVerifySlip && _.isNil(verify_slip_error_status)
    const isVerifySlipDangerError =
      hasVerifySlip && _.includes(['InvalidQRCode', 'RemoteServerError', 'TransactionRejected'], verify_slip_error_status)

    if (isVerifySlipSuccess) {
      txtPaymentStatus = 'ยืนยันแล้วจากการตรวจสอบสลิปอัตโนมัติ'
    }

    if (hasVerifySlip && _.isString(verify_slip_error_status)) {
      txtPaymentStatus = p.op.t(`verifySlipStatus.${verify_slip_error_status}`)
    }

    let txtDoneAt = ''
    switch (status) {
      case CONFIRMED:
        txtDoneAt = txtConfirmedAt
        break
      case REJECTED_OR_CANCELED:
        if (txtRejectedAt) {
          txtDoneAt = txtRejectedAt
        } else if (txtCancelledAt) {
          txtDoneAt = txtCancelledAt
        }
        break
    }

    return {
      paymentId,
      paymentAccountId,
      payerStoreId,
      payeeStoreId,
      isReadOnly,
      isRefund,
      orders,
      status,
      note,
      postDate,
      confirmedAt,
      cancelledAt,
      rejectedAt,
      totalAmountString,
      totalAmount,
      imgUris,

      // prepared to display
      bankId,
      isGBPay,
      payerIsMe,
      orderCount,
      orderToAmountItems,

      txtBankName,
      txtTotalAmount,
      txtPostDateTime,
      txtCreatedAt,
      txtConfirmedAt,
      txtCancelledAt,
      txtRejectedAt,
      txtDoneAt,
      txtPaymentStatus,

      txtTableHeaderOrder,
      txtTableHeaderAmount,

      hasVerifySlip,
      isVerifySlipSuccess,
      isVerifySlipDangerError,
    }
  }

  _getTxtTotalAmountFromPaymentListItemAmount = (totalAmount: number | string): string => fmt.formatCurrency(totalAmount)

  _getTxtBankNameFromBankId = (bankId: number): string => {
    if (_.isNil(bankId)) {
      return '(ไม่พบประเภทบัญชี)'
    }
    let bankNameKey
    const foundBankIndex = CONS.BANK_INFO.findIndex((sBank) => sBank.id === bankId)
    if (foundBankIndex > -1) {
      bankNameKey = CONS.BANK_INFO[foundBankIndex].key
    } else {
      bankNameKey = CONS.BANK_INFO[0].key
    }
    return p.op.t(`Bank.${bankNameKey}`)
  }

  _getTxtDateTime = (date?: string): string | null => (_.isString(date) ? `${moment(date).format('D MMM  HH:mm')}` : null)

  _isGBPay = (bankId: number): boolean => bankId === CONS.SYSTEM_BANK_IDS.GBPAY

  _isGatewayPaymentDontHaveSlip = (bank_id: number) => {
    const foundBankIndex = CONS.BANK_INFO.findIndex((sBank) => sBank.id === bank_id)
    if (_.isNil(foundBankIndex)) {
      return null
    }
    const bankNameKey = foundBankIndex > -1 ? CONS.BANK_INFO[foundBankIndex].key : CONS.BANK_INFO[0].key
    if (_.isNil(bankNameKey)) {
      return null
    }
    if (isSystemBankID(bank_id)) {
      return p.op.t(`Bank.${bankNameKey}`)
    }
    return null
  }

  _getTxtPaymentStatus = (
    paymentStatus: number,
    isReadOnly: boolean,
    payerIsMe: boolean,
    rejectedAt?: string,
    isRefund?: boolean
  ): string => {
    let txtPaymentStatus
    switch (paymentStatus) {
      case OPENED: {
        let txtDecisionMaker
        if (isReadOnly) {
          txtDecisionMaker = payerIsMe ? 'ผู้ขาย' : 'ผู้ซื้อ'
        } else {
          txtDecisionMaker = isRefund ? 'คู่ค้า' : 'ฉัน' // ใช้คำว่าคู่ค้าเพราะจุดๆ นี้ ไม่รู้จะคืนเงินให้ตัวแทน หรือ ร้านขายส่ง
        }
        txtPaymentStatus = `รอ${txtDecisionMaker}ตรวจสอบ`
        break
      }
      case CONFIRMED: {
        txtPaymentStatus = 'ยืนยันแล้ว'
        break
      }
      case REJECTED_OR_CANCELED: {
        if (!_.isNil(rejectedAt)) {
          txtPaymentStatus = 'ปฏิเสธแล้ว'
        } else {
          // has canceledAt
          const txtWhoDecided = payerIsMe ? 'ฉัน' : 'ผู้ซื้อ'
          txtPaymentStatus = `${txtWhoDecided}ยกเลิกการ${isRefund ? 'คืนเงิน' : 'ชำระ'}`
        }
        break
      }
      // case CANCELED: {
      //   const txtWhoDecided = payerIsMe ? 'ฉัน' : 'ผู้ซื้อ'
      //   txtPaymentStatus = `${txtWhoDecided}ยกเลิกการชำระ`
      //   break
      // }
      default:
        txtPaymentStatus = '(ไม่พบสถานะ)'
    }
    return txtPaymentStatus
  }

  _getTxtHeaderTitle = () => {
    const { navigation, storePaymentAccounts } = this.props
    let txtTitle = 'รายการรับชำระ'
    if (navigation) {
      const storeId = util.getNavParam(this.props, 'store_id')
      const paymentAccountId = util.getNavParam(this.props, 'payment_account_id')
      const orderId = util.getNavParam(this.props, 'order_id')
      if (orderId) {
        txtTitle = `รายการรับชำระ #${orderId}`
      } else if (storeId && paymentAccountId) {
        const focusPAs = storePaymentAccounts.get(storeId)
        if (List.isList(focusPAs)) {
          const focusPA = focusPAs.find((pa) => pa.get('id') === paymentAccountId)
          if (focusPA && Map.isMap(focusPA) && focusPA.get('bank_id')) {
            const bankName = this._getTxtBankNameFromBankId(focusPA.get('bank_id'))
            txtTitle = `รายการรับชำระ (${bankName})`
          }
        }
      }
    }
    return txtTitle
  }

  isFinishBackgroundTasks = (): boolean => {
    if (!_.isEmpty(this.inProcessPaymentId)) {
      for (const key of Object.keys(this.inProcessPaymentId)) {
        const isSubmitting = this.inProcessPaymentId[key]
        if (isSubmitting) {
          return false
        }
      }
      this.dispatchRelatedShouldFetch() // เพื่อบอก orderlist / order detail ให้ refresh
    }
    return true
  }

  // เพื่อบอกว่า nav มาจาก OrderView รึเปล่า
  isFromOrderDetial = (): boolean => util.getNavParam(this.props, 'fromOrderDetail', false)

  // _getPaymentItem = (pIndex: number, payment: Map<string, any>): { [key: string]: any } => {
  //   // log('___payment.toJS()_: ', item.toJS())
  //   const paymentId = payment.get('id')
  //   const orders = payment.get('orders')
  //   const total_amount = payment.get('total_amount')
  //   const date = payment.get('post_date')
  //   const isReadOnly = payment.get('isReadOnly')
  //   const isRefund = payment.get('isRefund')
  //   const status: E_PAYMENT_STATUS = payment.get('status')
  //   const img_uris = payment.get('img_uris') || List([])
  //   const img_uri = img_uris.size > 0 ? img_uris.get(0) : null
  //   const note = payment.get('note') || null
  //   const bank_id = payment.has('bank_id') ? payment.get('bank_id') : 0
  //   // const bank_id = 25
  //
  //   const orderNameArray = orders.reduce((prevArr: Array<any>, order: Map<string, any>) => {
  //     prevArr.push(`#${order.get('id')}`)
  //     return prevArr
  //   }, [])
  //   const isOddRow = pIndex % 2 === 1
  //
  //   let bankNameKey
  //   const foundBankIndex = CONS.BANK_INFO.findIndex(sBank => sBank.id === bank_id)
  //   if (foundBankIndex > -1) {
  //     bankNameKey = CONS.BANK_INFO[foundBankIndex].key
  //   } else {
  //     bankNameKey = CONS.BANK_INFO[0].key
  //   }
  //   const txtBankName = p.op.t(`Bank.${bankNameKey}`)
  //   let txtTotalAmount = total_amount && isRefund ? parseFloat(total_amount) * -1 : parseFloat(total_amount)
  //
  //   return {
  //     isOddRow,
  //     paymentId,
  //     status,
  //     img_uri, // image 0
  //     img_uris, // all images
  //     date,
  //     orderNameArray,
  //     isRefund,
  //     total_amount,
  //     isReadOnly,
  //     note,
  //     bank_id,
  //     txtBankName,
  //     txtTotalAmount,
  //   }
  // }
}
