import React from 'react'

import { View, Modal, StyleSheet, Platform, TouchableWithoutFeedback, KeyboardAvoidingView } from 'react-native'

import _ from 'lodash'
import { IXModalProps } from 'x/index'
import CONS from 'x/config/constants'
import XCustomHeader from './XCustomHeader'
import XStatusBar from './XStatusBar'
// import p from 'x/config/platform-specific'
// import XSellySafeArea from './XSellySafeArea'

const s = StyleSheet.create({
  // FLEX: {
  //   flex: 1,
  // },
  // GRID_COL_MIDDLE_CENTER: {
  //   alignItems: 'center',
  //   justifyContent: 'center',
  // },
  // GRID_COL_MIDDLE_LEFT: {
  //   alignItems: 'flex-start',
  //   justifyContent: 'center',
  // },
  // GRID_COL_MIDDLE_RIGHT: {
  //   alignItems: 'flex-end',
  //   justifyContent: 'center',
  // },
  content: {
    // width: '100%',
    flex: 1,
    backgroundColor: '#fff',
  },
  container: {
    // width: '100%',
    flex: 1,
    backgroundColor: 'transparent',
    ...Platform.select({
      web: {
        alignItems: 'center',
        justifyContent: 'center',
      },
      ios: {
        paddingTop: 20,
      },
    }),
  },
  closableBg: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: 'red',
    backgroundColor: 'rgba(0,0,0,0.7)',
  },
  webSubContainer: {
    maxWidth: CONS.SPECIFIC_PLATFORM.WEB.MAIN_CONTENT_WIDTH,
    minWidth: 100,

    minHeight: CONS.SPECIFIC_PLATFORM.WEB.MIN_WEB_HEIGHT,
    maxHeight: '90%',

    width: CONS.SPECIFIC_PLATFORM.WEB.MAIN_CONTENT_ONE_COLUMN_WIDTH,
    height: '90%',
    overflow: 'hidden',
    borderRadius: 8,
  },
})

class XModal extends React.PureComponent<IXModalProps> {
  static displayName = 'XModal'

  static defaultProps = {
    transparent: true,
    // animationType: 'slide',
    animationType: 'fade',
    supportedOrientations: ['portrait'],
    containerStyle: {},
    contentStyle: {},
    iosBarStyle: 'dark-content',
    children: null,
    hasBackButton: false,
    hasCloseButton: true,
  }

  _onRequestToCloseModal = () => {
    const { onRequestClose } = this.props
    if (_.isFunction(onRequestClose)) {
      onRequestClose()
    }
  }

  _getHeaderLeftProps = () => {
    const { headerLeft, hasBackButton } = this.props
    return _.isFunction(headerLeft) ? undefined : { backIcon: hasBackButton, onPressItem: this._onRequestToCloseModal }
  }

  _getHeaderRightProps = () => {
    const { headerRight, hasCloseButton } = this.props
    return _.isFunction(headerRight) ? undefined : { closeIcon: hasCloseButton, onPressItem: this._onRequestToCloseModal }
  }

  // const _renderHeader = (insets?: EdgeInsets) => {
  _renderHeader = () => {
    const { renderHeader, title, headerLeft, headerRight } = this.props

    // let injectedSafeHeaderMargin = {}
    // if (safeHeaderMargin) {
    //   injectedSafeHeaderMargin = {
    //     marginTop: getStatusBarHeight(),
    //   }
    // }
    if (_.isFunction(renderHeader)) {
      return renderHeader()
    }

    return (
      <XCustomHeader
        title={title}
        headerLeft={_.isFunction(headerLeft) ? headerLeft() : null}
        headerRight={_.isFunction(headerRight) ? headerRight() : null}
        headerLeftProps={this._getHeaderLeftProps()}
        headerRightProps={this._getHeaderRightProps()}
      />
    )
  }

  _renderModalContent = () => {
    const {
      visible,
      containerStyle,
      contentStyle,
      children,
      // iosBarStyle,
      // safeHeaderMargin,
    } = this.props

    if (!visible) {
      return null
    }

    if (Platform.OS === 'web') {
      return (
        <View style={[s.container]}>
          <TouchableWithoutFeedback onPress={this._onRequestToCloseModal}>
            <View style={s.closableBg} />
          </TouchableWithoutFeedback>
          <View style={[s.webSubContainer, containerStyle]}>
            {this._renderHeader()}
            <View style={[s.content, contentStyle]}>{children}</View>
          </View>
        </View>
      )
    }

    return (
      <View style={[s.container, containerStyle]}>
        {this._renderHeader()}
        <View style={[s.content, contentStyle]}>{children}</View>
      </View>
    )
  }

  render() {
    const {
      visible = false,
      onRequestClose,
      containerStyle,
      contentStyle,
      children,
      renderHeader,
      // iosBarStyle,
      headerLeft,
      headerRight,
      // safeHeaderMargin,
      hasCloseButton,
      hasBackButton,
      ...restProps
    } = this.props

    // const _renderBackButton = () =>
    //   props.hasBackButton ? <NavHeaderButton backIcon onPressItem={_onRequestToCloseModal} submitting={props.submitting} /> : null

    // const _renderHeaderTitle = () =>
    //   _.isString(props.title) ? (
    //     <Text
    //       // @ts-ignore Actually should have this props :(
    //       numberOfLines={1}
    //       style={{
    //         fontSize: STYLES.FONT_SIZE_LARGER,
    //         fontWeight: 'bold',
    //         color: COLORS.TEXT_ACTIVE,
    //         textAlign: 'center',
    //       }}>
    //       {props.title}
    //     </Text>
    //   ) : null

    // const _renderCloseButton = () =>
    //   props.hasCloseButton ? (
    //     <NavHeaderButton onPressItem={_onRequestToCloseModal} submitting={props.submitting}>
    //       <XIcon family='Ionicons' name='close-circle' inactive={props.submitting} />
    //     </NavHeaderButton>
    //   ) : null

    // _getStatusBarPaddingTop = () => {
    //   const { disabledStatusBarPadding = false } = this.props
    //   if (Platform.OS !== 'ios' || disabledStatusBarPadding) {
    //     return 0
    //   }
    //   return getStatusBarHeight(true)
    // }

    // const { visible = false, onRequestClose, containerStyle, contentStyle, children, ...restProps } = props

    return (
      <Modal visible={visible} onRequestClose={onRequestClose} {...restProps}>
        <KeyboardAvoidingView style={{ flex: 1 }} behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
          <XStatusBar translucent backgroundColor='#fff' />
          {/* <NativeBaseProvider> */}
          {/* <XSellySafeArea disabledTop={p.op.isAndroid()} disabledBottom={p.op.isAndroid()}> */}
          {this._renderModalContent()}
          {/* </XSellySafeArea> */}
          {/* </NativeBaseProvider> */}
        </KeyboardAvoidingView>
      </Modal>
    )
  }
}

// XModal.displayName = 'XModal'
// XModal.defaultProps = {
//   // transparent: true,
//   // animationType: 'slide',
//   // supportedOrientations: ['portrait'],
//   // containerStyle: {},
//   // contentStyle: {},
//   // iosBarStyle: 'dark-content',
//   // children: null,
// }

export default XModal

// export default class XModal extends PureComponent<IXModalProps> {
//   static displayName = 'XModal'
//   static defaultProps = {
//     transparent: true,
//     animationType: 'slide',
//     supportedOrientations: ['portrait'],
//     containerStyle: {},
//     contentStyle: {},
//     iosBarStyle: 'dark-content',
//     children: null,
//   }

//   _onRequestToCloseModal = () => {
//     const { onRequestClose } = this.props
//     if (_.isFunction(onRequestClose)) {
//       onRequestClose()
//     }
//   }

//   _renderAppStatusBar = () => {
//     if (Platform.OS === 'web') {
//       return
//     }
//     return <XStatusBar translucent={true} backgroundColor={'#fff'} />
//   }

//   _renderHeader = (insets?: EdgeInsets) => {
//     const { renderHeader, iosBarStyle, headerLeft, headerRight, safeHeaderMargin } = this.props
//     // let injectedSafeHeaderMargin = {}
//     // if (safeHeaderMargin) {
//     //   injectedSafeHeaderMargin = {
//     //     marginTop: getStatusBarHeight(),
//     //   }
//     // }
//     if (_.isFunction(renderHeader)) {
//       return renderHeader()
//     }

//     // <Header iosBarStyle={iosBarStyle} style={{ minHeight: 44, backgroundColor: '#F7F7F7' }}>
//     //   <Header iosBarStyle={iosBarStyle} style={[{ backgroundColor: '#fff' }, injectedSafeHeaderMargin]}>
//     return (
//       <Header iosBarStyle={iosBarStyle} style={{ height: 44, backgroundColor: '#fff' }}>
//         {this._renderAppStatusBar()}
//         <Grid style={{ flex: 1, minHeight: 44 }}>
//           <Row>
//             <Col style={[s.GRID_COL_MIDDLE_CENTER, { flex: 0, minWidth: 54 }]}>
//               {this._renderBackButton()}
//               {headerLeft ? headerLeft() : null}
//             </Col>
//             <Col size={100} style={s.GRID_COL_MIDDLE_CENTER}>
//               {this._renderHeaderTitle()}
//             </Col>
//             <Col style={[s.GRID_COL_MIDDLE_CENTER, { flex: 0, minWidth: 54 }]}>
//               {this._renderCloseButton()}
//               {headerRight ? headerRight() : null}
//             </Col>
//           </Row>
//         </Grid>
//       </Header>
//     )
//   }

//   _renderBackButton = () =>
//     this.props.hasBackButton ? (
//       <NavHeaderButton backIcon={true} onPressItem={this._onRequestToCloseModal} submitting={this.props.submitting} />
//     ) : null

//   _renderHeaderTitle = () =>
//     _.isString(this.props.title) ? (
//       <Text
//         // @ts-ignore Actually should have this props :(
//         numberOfLines={1}
//         style={{
//           fontSize: STYLES.FONT_SIZE_LARGER,
//           fontWeight: 'bold',
//           color: COLORS.TEXT_ACTIVE,
//           textAlign: 'center',
//         }}>
//         {this.props.title}
//       </Text>
//     ) : null

//   _renderCloseButton = () =>
//     this.props.hasCloseButton ? (
//       <NavHeaderButton onPressItem={this._onRequestToCloseModal} submitting={this.props.submitting}>
//         <XIcon family='Ionicons' name='close-circle' inactive={this.props.submitting} />
//       </NavHeaderButton>
//     ) : null

//   // _getStatusBarPaddingTop = () => {
//   //   const { disabledStatusBarPadding = false } = this.props
//   //   if (Platform.OS !== 'ios' || disabledStatusBarPadding) {
//   //     return 0
//   //   }
//   //   return getStatusBarHeight(true)
//   // }

//   render() {
//     const { containerStyle, contentStyle, children } = this.props

//     //  <View style={[s.DEFAULT_CONTAINER, { paddingTop: this._getStatusBarPaddingTop() }, containerStyle]}>
//     return (
//       <Modal {...this.props}>
//         <StyleProvider style={getTheme(material)}>
//           <XSellySafeArea
//             disabledTop={p.op.isAndroid()}
//             disabledBottom={p.op.isAndroid()}
//             // disabledTop={this._isDisabledTopSafeArea()} disabledBottom={this._isDisabledBottomSafeArea()}
//           >
//             <View style={[s.DEFAULT_CONTAINER, containerStyle]}>
//               {this._renderHeader()}
//               <View style={[s.DEFAULT_CONTENT, contentStyle]}>{children}</View>
//             </View>
//           </XSellySafeArea>
//         </StyleProvider>
//       </Modal>
//     )
//   }
// }
