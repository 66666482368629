import React, { forwardRef } from 'react'
import { Text } from 'react-native'
import { ITextProps as INBTextProps } from 'native-base'
import { ILocaleTranslateKey } from 'x/utils/i18n'
import p from 'x/config/platform-specific'
// import styled from 'styled-components/native'
// import { system } from 'styled-system'
import makeStyledComponent from 'x/config/nativebase/makeStyledComponent'
import { IThemeType } from 'x/config/nativebase/theme'

// const nbBox = system(propConfig)
// const StyledText = styled(Text)(nbBox)
const StyledText = makeStyledComponent(Text, 'Text')

// export type ITextProps = NBTextProps & { t?: ILocaleTranslateKey } // add localization
export interface IXTextProps extends INBTextProps {
  // add localization
  t?: ILocaleTranslateKey
  variant?: keyof IThemeType['components']['Text']['variants']

  // [key: string]: any

  /**  textAlignVertical - nativebase ไม่มี แต่เราเพิ่มเองใน styled-system */
  textAlignVertical?: 'auto' | 'top' | 'bottom' | 'center'
}

// const XText: React.FC<IXTextProps> = (props) => {
const XText = forwardRef((props, ref) => {
  // console.log('XText props => ', props)
  // @ts-ignore
  const { t, children, ...restProps } = props

  const content = t ? p.op.t(t) : children
  return (
    // @ts-ignore
    <StyledText
      // @ts-ignore
      ref={ref}
      // @ts-ignore
      alt={content}
      // style={[defaultStyle.regular, style]}
      {...restProps}>
      {content}
    </StyledText>
  )
})

// const defaultStyle = StyleSheet.create({
//   regular: {
//     fontFamily: 'Prompt-Regular',
//   },
//   bold: {
//     fontFamily: 'Prompt-Bold',
//   },
// })
// XText.defaultProps = getComponentDefaultProps('Text')

// const XText = makeStyledComponent(XText, 'Text')
XText.displayName = 'XText'
// XText.displayName = 'XText'

export default XText as React.FC<IXTextProps>
// export default memo(XText) as React.FC<ITextProps>
