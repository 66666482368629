import BaseUIStoreSettingXShippingEcoPostView from 'xui/modules/xshipping/BaseUIStoreSettingXShippingEcoPostView'

class StoreSettingXShippingEcoPostView extends BaseUIStoreSettingXShippingEcoPostView {
  static displayName = 'StoreSettingXShippingEcoPostView'

  static navigationOptions = {
    header: null,
    title: null,
    headerLeft: null,
    headerRight: null,
  }
}

export default StoreSettingXShippingEcoPostView
