import _ from 'lodash'

import { IAnyObject, IProductDetailItem, IProductGroup, UserGroup } from 'x/types'
import CONS from 'x/config/constants'
import p from 'x/config/platform-specific'
import { getSelectedUserGroups, getSelectedProductGroups } from 'x/redux/selectors'

import api from './api'

const { VIEW_SELF, PULL_MY_PRODUCT_TO_ORDER, PULL_PRODUCT } = CONS.PRODUCT_VIEW_MODE

type IFetchProductDetailParams = {
  store_id: number
  product_id: number
  role_id?: number

  mode?: string

  user_group_id?: number
  product_group_ids?: number[]
}

export async function fetchProductDetail(params: IFetchProductDetailParams): Promise<IProductDetailItem> {
  const { store_id, product_id, role_id, mode = VIEW_SELF } = params

  if (!store_id) {
    throw new Error('fetchProductDetail:: Need store_id')
  }

  if (!product_id) {
    throw new Error('fetchProductDetail:: Need product_id')
  }

  if (!mode) {
    throw new Error('fetchProductDetail:: Need mode')
  }

  const reqBody: IAnyObject = {
    store_id,
    product_id,
  }

  // backend require if role_id === 2 (helper)
  if (role_id === 2) {
    // ถ้าเป็นการ fetch สินค้าของร้านฉันให้ส่ง role_id ของ selectedStore ของฉันไป
    reqBody.role_id = role_id
  }

  const apiOptions = {
    // messages: {
    //   successMsg: 'การสร้างสินค้าสำเร็จ',
    //   errorMsg: 'การสร้างสินค้าล้มเหลว',
    // },
    axiosOptions: {
      timeout: 60000,
    },
    showSpinner: true,
  }

  try {
    // console.log('In fetchProductDetail:: reqBody => ', reqBody)
    // const res = yield call(api.post, api.POST_PRODUCT, reqBody, apiOptions)
    const res = await api.post(api.POST_PRODUCT, reqBody, apiOptions)
    // console.log('In fetchProductDetail:: response => ', res)

    if (!res.product) {
      throw new Error('fetchProductDetail:: No "product" in response')
    }

    const computedProduct = getComputedProductFromResponseProduct(res.product, params)
    return computedProduct
  } catch (err) {
    console.log('In fetchProductDetail:: err => ', err)
    throw err
  }
}

function getComputedProductFromResponseProduct(rpd: IProductDetailItem, params: IFetchProductDetailParams): IProductDetailItem {
  const { mode = VIEW_SELF, user_group_id = null, product_group_ids = null } = params

  if (!rpd) {
    return null
  }

  // Sorting variants by id from response
  let newPd = _.cloneDeep(rpd)

  // ป้องกัน image url ที่เป็น null หรือ empty string
  newPd.img_uris = newPd.img_uris.filter((iuri) => _.isString(iuri) && iuri.length > 0)
  newPd.thumbnail_uris = newPd.thumbnail_uris.filter((iuri) => _.isString(iuri) && iuri.length > 0)
  newPd.tiny_img_uris = newPd.tiny_img_uris.filter((iuri) => _.isString(iuri) && iuri.length > 0)

  // Filter ในการ Remove unused PG ของราคา ที่ไม่ได้ใช้ออก (สำหรับเวลา PULL PRODUCTS TO ORDER โดยเฉพาะ)
  const appState = p.op.getAppState()
  const userGroupsMap = getSelectedUserGroups(appState)
  const productGroupsMap = getSelectedProductGroups(appState)
  const ugs: UserGroup[] = userGroupsMap.toJS()
  const pgs: IProductGroup[] = productGroupsMap.toJS()

  if (_.isArray(newPd.product_group_ids) && ugs && ugs.length > 0) {
    // ทำการ sort product_group_ids by priority
    // log('before sort product_group_ids in API RESPONSE => ', newPd.product_group_ids)
    newPd.product_group_ids.sort((pgA, pgB) => {
      const foundPgA = pgs.find((pg) => pg.id === pgA)
      const foundPgB = pgs.find((pg) => pg.id === pgB)
      const priorityA = _.isNumber(foundPgA) ? foundPgA.priority : 0
      const priorityB = _.isNumber(foundPgB) ? foundPgB.priority : 0
      if (priorityA > priorityB) {
        // for desc
        return -1
      }
      if (priorityA < priorityB) {
        return 1
      }
      // Should be impossible
      return 0
    })
    // log('Sorted product_group_ids in API RESPONSE => ', newPd.product_group_ids)
  }

  // ทำการ sort warehouse_ids by ids asc
  if (_.isArray(newPd.warehouse_ids)) {
    newPd.product_group_ids.sort((idA, idB) => (idA > idB ? 1 : -1))
    // forEach อันนี้เป็นของเก่า warehouses ไม่มี code จะไม่รันต่อ
    // newPd.variants.forEach((v, i) => {
    //   newPd.variants[i].warehouses.sort((whA, whB) => (whA.wh_id > whB.wh_id ? 1 : -1))
    // })
    for (let i = 0; i < newPd.variants.length; i++) {
      if (_.isArray(newPd.variants[i].warehouses)) {
        newPd.variants[i].warehouses.sort((whA, whB) => (whA.wh_id > whB.wh_id ? 1 : -1))
      }
    }
  }

  if (appState && _.isArray(newPd.product_group_ids) && _.isArray(newPd.variants) && _.isArray(ugs) && _.isArray(pgs)) {
    // log('XXX loadProductFromResponse ug_id => ', ug_id)
    // log('XXX loadProductFromResponse pg_ids => ', pg_ids)

    if (user_group_id && user_group_id > 0) {
      // ถ้าเป็น UG Mode
      const foundUG = ugs.find((ug) => ug.id === user_group_id)

      if (foundUG) {
        const UgPgRelations = foundUG.pgs || []

        const allowedPgs = UgPgRelations.map((pgr) => {
          // const allowedPgId = pgr.id
          const allowedPgId = pgr.pg_id
          const foundPG = pgs.find((pg) => pg.id === allowedPgId)
          if (foundPG) {
            return foundPG
          }
          return null
        }).filter((pg) => pg && pg.id)

        // log('XXX allowedPgs.toJS() => ', allowedPgs.toJS())
        const sortedAllowedPgs = allowedPgs.sort((pgA, pgB) => {
          const priorityA = pgA.priority
          const priorityB = pgB.priority
          if (priorityA > priorityB) {
            // for desc
            return -1
          }
          if (priorityA < priorityB) {
            return 1
          }

          const idA = pgA.id
          const idB = pgB.id
          if (idA < idB) {
            // for asc
            return -1
          }
          if (priorityA < priorityB) {
            return 1
          }

          // Should be impossible
          return 0
        })

        // log('XXX sortedAllowedPgs.toJS() => ', sortedAllowedPgs.toJS())
        // const mostPriorityPgId = sortedAllowedPgs.getIn([0, 'id'])
        for (let i = 0; i < sortedAllowedPgs.length; i++) {
          const mostPriorityPgId = sortedAllowedPgs[i].id

          if (mostPriorityPgId && mostPriorityPgId > 0) {
            let isSetUsagePrice = false
            // const foundAllowPgIdAtIndex = newPd.product_group_ids.find(ptPgId => ptPgId === mostPriorityPgId)
            for (let variantIndex = 0; variantIndex < newPd.variants.length; variantIndex++) {
              const { prices = [] } = newPd.variants[variantIndex]
              for (let priceIndex = 0; priceIndex < prices.length; priceIndex++) {
                const pricePgId = _.has(prices[priceIndex], 'pg_id') ? prices[priceIndex].pg_id : null
                const priceByPgId = _.has(prices[priceIndex], 'price') ? prices[priceIndex].price : 0
                if (pricePgId && pricePgId > 0 && pricePgId === mostPriorityPgId) {
                  // @ts-ignore FIXME: ควรใช้หรือเปล่า?
                  newPd.variants[variantIndex].price = priceByPgId
                  newPd.variants[variantIndex].pg_id = pricePgId
                  isSetUsagePrice = true
                  // log('XXX mostPriorityPgId => ', mostPriorityPgId)
                  // log('XXX priceByPgId => ', priceByPgId)
                }
              }
            }

            if (isSetUsagePrice) {
              break
            }
          }
        }
      }
    } else if (_.isArray(product_group_ids) && product_group_ids.length > 0) {
      // ถ้าเป็น PG Mode
      // let visiblePgIds = []
      // newPd.product_group_ids.forEach(ptPgId => {
      //   const foundInAllowedIndex = pg_ids.findIndex(allowedPgId => allowedPgId === ptPgId)
      //   if (foundInAllowedIndex > -1) {
      //     visiblePgIds.push(ptPgId)
      //   }
      // })
      // newPd.product_group_ids = visiblePgIds
      const allowedPgs: IProductGroup[] = []

      product_group_ids.forEach((allowedPgId) => {
        const foundPG = pgs.find((pg) => pg.id === allowedPgId)
        if (foundPG) {
          allowedPgs.push(foundPG)
        }

        return null
      })

      // log('XXX allowedPgs.toJS() => ', allowedPgs.toJS())
      const sortedAllowedPgs = allowedPgs.sort((pgA, pgB) => {
        const priorityA = pgA.priority
        const priorityB = pgB.priority
        if (priorityA > priorityB) {
          // for desc
          return -1
        }
        if (priorityA < priorityB) {
          return 1
        }

        const idA = pgA.id
        const idB = pgB.id
        if (idA < idB) {
          // for asc
          return -1
        }
        if (priorityA < priorityB) {
          return 1
        }
        // Should be impossible
        return 0
      })

      const mostPriorityPgId = sortedAllowedPgs.length > 0 ? sortedAllowedPgs[0].id : null

      // log('XXX mostPriorityPgId => ', mostPriorityPgId)
      if (mostPriorityPgId && mostPriorityPgId > 0) {
        for (let variantIndex = 0; variantIndex < newPd.variants.length; variantIndex++) {
          const focusedVariant = newPd.variants[variantIndex]
          const newPrices = _.has(focusedVariant, 'prices') && _.isArray(focusedVariant.prices) ? focusedVariant.prices.slice() : []
          // let isSetUsagePrice = false
          let priceIndex = 0
          let priceCount = newPrices.length

          while (priceIndex < priceCount) {
            const pricePgId = _.has(newPrices[priceIndex], 'pg_id') ? newPrices[priceIndex].pg_id : null
            const priceByPgId = _.has(newPrices[priceIndex], 'price') ? newPrices[priceIndex].price : '0'

            newPrices[priceIndex].pg_id = pricePgId

            if (mostPriorityPgId && pricePgId === mostPriorityPgId && pricePgId && pricePgId > 0) {
              // กรณีจอราคา ที่ filter แล้ว ให้ใช้เป็นราคาหลักราคาเดียวใน variant
              newPd.variants[variantIndex].pg_id = pricePgId
              newPd.variants[variantIndex].price = priceByPgId
              // log('XXX mostPriorityPgId => ', mostPriorityPgId)
              // log('XXX variantIndex => ', variantIndex)
              // log('XXX priceByPgId => ', priceByPgId)
              priceIndex++
            } else {
              // กรณีไม่ได้อยู่ใน pg_ids ให้ remove ออกจาก prices ไปเลย
              // newPd.variants[variantIndex].price = 0
              // log(`XXX removing priceIndex=${priceIndex} => `, newPrices[priceIndex])
              newPrices.splice(priceIndex, 1)
              priceCount = newPrices.length
            }
          }

          if (newPrices.length > 0) {
            // คำนวณ newPrices ได้ แสดงว่าเจอราคาตรงกับ PgIds ให้ replace prices ไปและ price จะถูกเซ็ตตามนั้น
            newPd.variants[variantIndex].prices = newPrices
          } else {
            // ถ้าไม่มี newPrices หรือ newPrices.length === 0 แสดงว่าไม่มีราคา ให้เซ็ตราคาเป็น 0
            newPd.variants[variantIndex].pg_id = null
            newPd.variants[variantIndex].price = '0'
          }
          // if (isSetUsagePrice) {
          //   break
          // }
        }
      } else {
        // ถ้าไม่เจอ most priority id ให้บังคับ price = 0
        for (let variantIndex = 0; variantIndex < newPd.variants.length; variantIndex++) {
          newPd.variants[variantIndex].price = '0'
        }
      }
    }
  }

  // FIXME: MOCKING vds
  // newPd.seller_vds = [
  //     { min_qty: 1, max_qty: 100, discount_percent: 0 },
  //     { min_qty: 101, max_qty: 200, discount_percent: 3 },
  //     { min_qty: 201, max_qty: 300, discount_percent: 5 },
  // ]

  // newPd.my_vds = [
  //     { min_qty: 1, max_qty: 5, discount_amount: 0 },
  //     { min_qty: 6, max_qty: 10,  discount_amount: 5 },
  // ]

  let variants = _.cloneDeep(newPd.variants)
  // log('loadProductFromResponse variants before sort => ', variants)
  variants = _.sortBy(variants, [(v) => v.pp_id])
  // log('loadProductFromResponse variants after sort => ', variants)
  newPd.variants = variants

  // โหมดดึงสินค้าเข้าออเดอร์ ทำให้ qty ทั้งหมดเป็น empty string เพื่อให้ user กรอก
  if (mode === PULL_MY_PRODUCT_TO_ORDER) {
    const stockSize = newPd.variants.length

    for (let i = 0; i < stockSize; i++) {
      // @ts-ignore
      newPd.variants[i].qty = ''
    }
  }

  // โหมดดึงสินค้าเข้า "ร้าน" ทำให้ ราคาที่ขายส่งให้มา เป็น "ทุน" ของฉัน และทำให้ ราคา ทั้งหมดเป็น empty string เพื่อให้ user กรอก
  if (mode === PULL_PRODUCT) {
    const stockSize = newPd.variants.length
    for (let i = 0; i < stockSize; i++) {
      newPd.variants[i].cost = newPd.variants[i].price
      newPd.variants[i].price = ''
    }
  }

  if (newPd.shipping_rates) {
    // console.log(' newPd.shipping_rates => ', newPd.shipping_rates)
    const shippingRateStatuses = {}
    // let PLACEHOLDER_SHIPPING_TYPE_2 ={ shipping_type_id: CONS.SHIPPING_TYPE_IDS.REGISTER, init_qty: 1, init_price: '30', next_price: '10' }
    // let PLACEHOLDER_SHIPPING_TYPE_3 = { shipping_type_id: CONS.SHIPPING_TYPE_IDS.EMS, init_qty: 1, init_price: '60', next_price: '15' }
    let PLACEHOLDER_SHIPPING_TYPE_2 = null
    let PLACEHOLDER_SHIPPING_TYPE_3 = null

    newPd.shipping_rates.forEach((rate) => {
      shippingRateStatuses[rate.shipping_type_id.toString()] = true
      if (rate.shipping_type_id === CONS.SHIPPING_TYPE_IDS.REGISTER) {
        PLACEHOLDER_SHIPPING_TYPE_2 = null
      } else if (rate.shipping_type_id === CONS.SHIPPING_TYPE_IDS.EMS) {
        PLACEHOLDER_SHIPPING_TYPE_3 = null
      }
      // log(rate)
    })

    // Add the placeholder if not already there
    if (PLACEHOLDER_SHIPPING_TYPE_2) {
      newPd.shipping_rates.push(PLACEHOLDER_SHIPPING_TYPE_2)
    }

    if (PLACEHOLDER_SHIPPING_TYPE_3) {
      newPd.shipping_rates.push(PLACEHOLDER_SHIPPING_TYPE_3)
    }

    // newProduct = newProduct.setIn(['shipping_rates'], fromJS(newPd.shipping_rates))
    // newProduct = newProduct.setIn(['shippingRateStatuses'], fromJS(shippingRateStatuses))

    // @ts-ignore
    newPd.shippingRateStatuses = shippingRateStatuses
  }

  // Adapter สำหรับแปลงข้อมูล prices แปลงโครงสร้างให้หน้าบ้าน render + validation ได้ง่ายขึ้น
  // "prices": [
  //   {
  //     "id": 10,
  //     "price": "33",
  //     "pg_id": 1,
  //     "hidden": false
  //   }
  // ],
  if (variants[0] && _.has(variants[0], 'prices')) {
    // const immuVariants = newPd.variants
    // log('product saga immuVariants.toJS() => ', immuVariants.toJS())
    newPd.variants.forEach((v, vIdx) => {
      // const prices = v.prices
      // log('product saga vIdx => ', vIdx)
      // log('product saga prices.toJS() => ', prices.toJS())
      if (_.isArray(v.prices) && v.prices.length > 0) {
        v.prices.forEach((vPrice) => {
          // log('product saga vPrice.toJS() => ', vPrice.toJS())
          const { pg_id } = vPrice
          const { price } = vPrice
          // newPd.variants[vIdx][`price_${pg_id}`] = parseFloat(price)
          const inputPriceKey = `price_${pg_id}`
          // newProduct = newProduct.setIn(['variants', vIdx, inputPriceKey], price)

          newPd.variants[vIdx][inputPriceKey] = price
        })
      }
    })
  }

  if (variants[0] && _.has(variants[0], 'warehouses')) {
    // const immuVariants = newPd.variants
    newPd.variants.forEach((v, vIdx) => {
      const vWarehouses = v.warehouses
      if (_.isArray(vWarehouses) && vWarehouses.length > 0) {
        vWarehouses.forEach((vPrice) => {
          const { wh_id, qty } = vPrice
          const inputQtyKey = `qty_${wh_id}`
          // newProduct = newProduct.setIn(['variants', vIdx, inputQtyKey], qty)
          newPd.variants[vIdx][inputQtyKey] = qty
        })
      }
    })
  }

  // Adapter for vds
  //   { min_qty: 1, max_qty: 5, discount_amount: 0, discount_percent: 0 },
  //   { min_qty: 6, max_qty: 10, discount_amount: 10, discount_percent: 10 },
  newPd = parseVolumeDiscountOf('my', newPd)
  newPd = parseVolumeDiscountOf('seller', newPd)
  // yield put(ProductActions.loadProduct(newProduct, shouldFetchList, shouldUpdateLocalList))

  console.log('newPd => ', newPd)
  return newPd
}

// internal function
function parseVolumeDiscountOf(discountOf: 'my' | 'seller', pd: IProductDetailItem): IProductDetailItem {
  const vdKey = _.includes(['my', 'seller'], discountOf) ? `${discountOf}_vds` : null
  const vdTypeKey = _.includes(['my', 'seller'], discountOf) ? `${discountOf}_vds_type` : null

  const newPd = _.cloneDeep(pd)

  if (vdKey && vdTypeKey) {
    const focusedVds = _.cloneDeep(pd[vdKey])
    if (_.has(pd, vdKey) && focusedVds.length >= 2) {
      focusedVds.sort((vdA, vdB) => vdA.min_qty - vdB.min_qty)
      const lastMyVdIndex = focusedVds.length - 1

      // decision of type (percent/baht)
      let vdsType
      if (_.has(focusedVds[lastMyVdIndex], 'discount_percent') && _.isNumber(focusedVds[lastMyVdIndex].discount_percent)) {
        vdsType = 1
      } else if (_.has(focusedVds[lastMyVdIndex], 'discount_amount') && _.isNumber(focusedVds[lastMyVdIndex].discount_amount)) {
        vdsType = 2
      } else {
        vdsType = 0
      }

      const newVds = []
      focusedVds.forEach((volDiscount, vdIdx) => {
        const { min_qty, max_qty, discount_amount, discount_percent } = volDiscount
        // const modifiedMaxQty = vdIdx === lastMyVdIndex ? 9999999 : max_qty
        const modifiedMaxQty = vdIdx === lastMyVdIndex && !max_qty ? min_qty + 1 : max_qty
        let discountAmount
        if (vdsType === 1) {
          discountAmount = discount_percent || 0
        } else if (vdsType === 2) {
          discountAmount = discount_amount || 0
        } else {
          discountAmount = 0
        }
        newVds.push({ begin: min_qty, end: modifiedMaxQty, discount: discountAmount })
      })
      newPd[vdTypeKey] = vdsType
      newPd[vdKey] = newVds
    }
  }

  return newPd
}
