// Extended
import BaseUIAssistantGroupView from 'xui/modules/store/storeSetting/BaseUIAssistantGroupView'
import * as util from 'x/utils/util'

export default class AssistantGroupView extends BaseUIAssistantGroupView {
  // static displayName = 'AssistantGroupView'

  static navigationOptions = {
    header: null,
  }

  // static navigationOptions = ({ navigation }) => {
  //   const { state } = navigation
  //   // const { handleBtn } = state.params
  //   const handleRightBtn = state.params ? state.params.handleRightBtn : null
  //   const handleLeftBtn = state.params ? state.params.handleLeftBtn : null
  //   const mode = state.params ? state.params.mode : null
  //   let title = state.params ? state.params.title : 'สร้างสิทธิ์ผู้ช่วย'
  //   let ledtText = 'บันทึก'
  //   if (_.isNil(title) && mode === MODE.CREATE) {
  //     title = 'สร้างสิทธิ์ผู้ช่วย'
  //     ledtText = 'สร้าง'
  //   } else if (mode === MODE.VIEW) {
  //     title = 'สิทธิ์การใช้งาน'
  //   }
  //   return {
  //     headerLeft:
  //       _.isNil(mode) || mode === MODE.VIEW ? (
  //         <Button onPress={() => (!_.isNil(handleLeftBtn) ? handleLeftBtn() : {})}>
  //           <BackIcon />
  //         </Button>
  //       ) : (
  //         <Button onPress={() => (!_.isNil(handleLeftBtn) ? handleLeftBtn() : {})}>
  //           <Text style={{ color: COLORS.TEXT_PRIMARY, fontSize: STYLES.FONT_SIZE_NORMAL }}>{ledtText}</Text>
  //         </Button>
  //       ),
  //     title,
  //     headerRight:
  //       _.isNil(mode) || mode === MODE.VIEW ? (
  //         <Button onPress={() => (!_.isNil(handleRightBtn) ? handleRightBtn() : {})}>
  //           <Text style={{ color: COLORS.TEXT_PRIMARY, fontSize: STYLES.FONT_SIZE_NORMAL }}>แก้ไข</Text>
  //         </Button>
  //       ) : (
  //         <Button onPress={() => (!_.isNil(handleRightBtn) ? handleRightBtn() : {})}>
  //           <Text style={{ color: COLORS.TEXT_PRIMARY, fontSize: STYLES.FONT_SIZE_NORMAL }}>ยกเลิก</Text>
  //         </Button>
  //       ),
  //   }
  // }

  _goBack = () => {
    util.navGoBack(this.props)
  }

  // _renderTitleName = () => (
  //   <CardItem style={[STYLES.NO_MARGIN_BOTTOM, STYLES.NO_PADDING_BOTTOM]}>
  //     <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE }}>ชื่อสิทธิ์การใช้งาน</Text>
  //   </CardItem>
  // )

  // _renderTitleDescription = () => (
  //   <CardItem style={[STYLES.NO_MARGIN_BOTTOM, STYLES.NO_PADDING_BOTTOM]}>
  //     <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE }}>คำอธิบาย</Text>
  //   </CardItem>
  // )

  // _fineMinLengthGroup = (key: string) => {
  //   const { canDo } = this.state
  //   if (key === HEADER_CONSTANT.REPORT) {
  //     let i = 0
  //     xCONS.REPORT_GROUP_KEY.REPORT_ORDER.map((report: string) => {
  //       if ((canDo.report_order & xCONS.REPORT_SET_BIT_BINARY[report]) === xCONS.REPORT_SET_BIT_BINARY[report]) {
  //         i += 1
  //       }
  //     })
  //     xCONS.REPORT_GROUP_KEY.REPORT_INVENTORY.map((report: string) => {
  //       if ((canDo.report_inventory & xCONS.REPORT_SET_BIT_BINARY[report]) === xCONS.REPORT_SET_BIT_BINARY[report]) {
  //         i += 1
  //       }
  //     })
  //     xCONS.REPORT_GROUP_KEY.REPORT_SHIPPING.map((report: string) => {
  //       if ((canDo.report_shipping & xCONS.REPORT_SET_BIT_BINARY[report]) === xCONS.REPORT_SET_BIT_BINARY[report]) {
  //         i += 1
  //       }
  //     })
  //     xCONS.REPORT_GROUP_KEY.REPORT_RESELLER.map((report: string) => {
  //       if ((canDo.report_reseller & xCONS.REPORT_SET_BIT_BINARY[report]) === xCONS.REPORT_SET_BIT_BINARY[report]) {
  //         i += 1
  //       }
  //     })
  //     xCONS.REPORT_GROUP_KEY.REPORT_HELPER.map((report: string) => {
  //       if ((canDo.report_helper & xCONS.REPORT_SET_BIT_BINARY[report]) === xCONS.REPORT_SET_BIT_BINARY[report]) {
  //         i += 1
  //       }
  //     })
  //     xCONS.REPORT_GROUP_KEY.REPORT_PAYMENT.map((report: string) => {
  //       if ((canDo.report_payment & xCONS.REPORT_SET_BIT_BINARY[report]) === xCONS.REPORT_SET_BIT_BINARY[report]) {
  //         i += 1
  //       }
  //     })
  //     return i
  //   }
  //   let i = 0
  //   GROUP_ITEM[key].map((data: string) => {
  //     if (canDo[data]) {
  //       i += 1
  //     }
  //   })
  //   return i
  // }

  // _renderViewMode = () => {
  //   const { departmentName, departmentNameDescription } = this.state
  //   return (
  //     <Card style={{ flex: 1 }}>
  //       {this._renderTitleName()}
  //       <CardItem style={{ paddingBottom: 0 }}>
  //         <Text style={{ fontSize: FONT_SIZE_NORMAL, color: TEXT_ACTIVE, marginLeft: 10 }}>{departmentName}</Text>
  //       </CardItem>
  //       {this._renderTitleDescription()}
  //       <CardItem style={{ paddingBottom: 0 }}>
  //         <Text style={{ fontSize: FONT_SIZE_NORMAL, color: TEXT_ACTIVE, marginLeft: 10 }}>{departmentNameDescription}</Text>
  //       </CardItem>
  //       {this._renderRightsText()}
  //       {this._renderCheckBoxOption()}
  //     </Card>
  //   )
  // }

  // _renderTitleGroupName = (text: string, key: string) => {
  //   const { selectedAllCheckBox, arrowFunctionShowAndHide, mode } = this.state
  //   const onShow = arrowFunctionShowAndHide[key] // แสดง ไอคอนเลือกทั้งหมด && ไอคอนสำหรับพับเก็บข้อมูล
  //   const onSelect = this._fineMinLengthGroup(key) // คำนวณจากเท่าไร / ทั้งหมด
  //   let fullGroup = GROUP_ITEM[key].length // / ทั้งหมด
  //   if (key === HEADER_CONSTANT.REPORT) {
  //     fullGroup = GROUP_ITEM.report[0].length
  //   }

  //   return (
  //     <CardItem style={{ paddingBottom: 8 }}>
  //       <Grid style={{ flex: 1 }}>
  //         <Row style={{ borderTopColor: APP_MAIN, borderTopWidth: 2 }}>
  //           <Col size={100}>
  //             <View
  //               style={{
  //                 height: Platform.OS === 'ios' ? 40 : 40,
  //                 width: 200,
  //                 position: 'absolute',
  //                 top: 0,
  //                 left: 0,
  //                 // top: -32,
  //                 // right: 10,
  //                 backgroundColor: 'transparent',
  //                 borderStyle: 'solid',
  //                 borderRightWidth: 21,
  //                 borderTopWidth: 21,
  //                 borderRightColor: 'transparent',
  //                 borderTopColor: COLORS.APP_MAIN,
  //               }}
  //             />
  //             <TouchableOpacity
  //               onPress={() => this._onPressSeletedAll(key, selectedAllCheckBox[key])}
  //               style={{ flexDirection: 'row', paddingLeft: 2, width: 150 }}>
  //               {onShow && mode !== MODE.VIEW ? (
  //                 <FAIcon
  //                   style={{
  //                     flex: 0,
  //                     minWidth: 30,
  //                     fontSize: STYLES.FONT_ICON_NORMAL,
  //                     color: COLORS.WHITE,
  //                   }}
  //                   name={_.isNil(selectedAllCheckBox[key]) || !selectedAllCheckBox[key] ? 'square-o' : 'check-square-o'}
  //                 />
  //               ) : null}
  //               <Text
  //                 style={{
  //                   // marginTop: 2,
  //                   fontSize: STYLES.FONT_SIZE_NORMAL,
  //                   color: COLORS.WHITE,
  //                   justifyContent: 'center',
  //                   alignItems: 'center',
  //                   alignContent: 'center',
  //                   alignSelf: 'center',
  //                   fontWeight: 'bold',
  //                   paddingBottom: 2,
  //                   paddingLeft: this.state[key] ? 0 : 4,
  //                 }}>
  //                 {`${text} (${onSelect}/${fullGroup})`}
  //               </Text>
  //             </TouchableOpacity>
  //           </Col>
  //           <Col style={{ width: 60 }}>
  //             <TouchableOpacity onPress={() => this._onPressArrow(key)} style={{ width: 60, height: 25, alignSelf: 'flex-end' }}>
  //               <AntDesign
  //                 name={onShow ? 'caretdown' : 'caretup'}
  //                 style={{ flex: 0, alignSelf: 'flex-end', minWidth: 30, fontSize: 22, color: APP_MAIN }}
  //               />
  //             </TouchableOpacity>
  //           </Col>
  //         </Row>
  //       </Grid>
  //     </CardItem>
  //   )
  // }

  // _renderRightsText = () => (
  //   <CardItem style={{ paddingBottom: 4 }}>
  //     <Text style={{ fontSize: FONT_SIZE_NORMAL, color: TEXT_INACTIVE }}>สิทธิ์ในการใช้งาน</Text>
  //   </CardItem>
  // )

  // _renderCheckRow = (key: string, FAQ?: IQuestionsAndAnswers[]) => {
  //   const { canDo, mode } = this.state
  //   let checkSquare = canDo[key]
  //   let value = null
  //   if (key === xCONS.PERM_STORE_HELPER.PRODUCT_CODE_EDIT_UPC || key === xCONS.PERM_STORE_HELPER.PRODUCT_CODE_EDIT_SKU) {
  //     checkSquare = this._findSKUAndUPCCheckBox(key)
  //   } else if (_.includes(xCONS.REPORT_GROUP_KEY.ALL, key)) {
  //     checkSquare = this._findReportCheckBox(key)
  //   }
  //   value = !checkSquare

  //   const renderHelpBtn = (onPressOpenOverlay: () => void) => (
  //     <TouchableOpacity onPress={onPressOpenOverlay} style={{ width: 30, alignItems: 'center', justifyContent: 'center' }}>
  //       <XIcon name='ios-help-circle-outline' family='Ionicons' style={{ flex: 0, width: 25, minWidth: 25, color: COLORS.TEXT_INACTIVE }} />
  //     </TouchableOpacity>
  //   )
  //   // return (
  //   //   <View style={{ marginBottom: 16 }}>
  //   //     <HelpButton
  //   //       onPress={onPressOpenOverlay}
  //   //       style={{ width: 30, height: 20, marginTop: 0, marginBottom: 0, paddingBottom: 0, paddingTop: 0 }}
  //   //     />
  //   //   </View>
  //   // )

  //   return (
  //     <CardItem style={[STYLES.NO_PADDING_TOP]}>
  //       <TouchableOpacity
  //         onPress={() => this._seleteCheckBoxBtn(key, value)}
  //         style={{ flexDirection: 'row', paddingLeft: 2 }}
  //         disabled={mode === MODE.VIEW}>
  //         <FAIcon
  //           style={{
  //             flex: 0,
  //             minWidth: 30,
  //             fontSize: STYLES.FONT_ICON_NORMAL,
  //             color: mode !== MODE.VIEW ? COLORS.APP_MAIN : COLORS.TEXT_INACTIVE,
  //           }}
  //           name={_.isNil(checkSquare) || !checkSquare ? 'square-o' : 'check-square-o'}
  //         />
  //         <Text
  //           style={{
  //             fontSize: STYLES.FONT_SIZE_NORMAL,
  //             color: COLORS.TEXT_ACTIVE,
  //             justifyContent: 'center',
  //             alignItems: 'center',
  //             alignContent: 'center',
  //             alignSelf: 'center',
  //             paddingBottom: 2,
  //           }}>
  //           {p.op.t(`perm_store_helper.${key}`)}
  //         </Text>
  //       </TouchableOpacity>
  //       {_.isNil(FAQ) ? null : (
  //         <View>
  //           <XHelpModal headerTitle='FAQ' FAQ={FAQ} renderButton={renderHelpBtn} initiateOpenIndex={0} />
  //         </View>
  //       )}
  //     </CardItem>
  //   )
  // }

  // _renderCheckBoxOption = () => {
  //   const { arrowFunctionShowAndHide } = this.state
  //   return (
  //     <View>
  //       {/* // ORDER */}
  //       <Card style={MARGIN_RIGHT_AND_MARGIN_LEFT_4}>
  //         {this._renderTitleGroupName(p.op.t('StoreSetting.HeaderOrder'), HEADER_CONSTANT.ORDER)}
  //         {arrowFunctionShowAndHide.order ? (
  //           <View>
  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.ORDER_ADD)}
  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.ORDER_LIST)}
  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.ORDER_EDIT)}
  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.ORDER_CANCEL)}
  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.ORDER_PRINT_SHIPPING)}
  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.ORDER_VISIBLE_ONLY_PROFILE_ID, FAQ.ORDER_VISIBLE_ONLY_PROFILE_ID)}
  //           </View>
  //         ) : null}
  //       </Card>

  //       {/* // PRODUCT */}
  //       <Card style={MARGIN_RIGHT_AND_MARGIN_LEFT_4}>
  //         {this._renderTitleGroupName(p.op.t('StoreSetting.HeaderProduct'), HEADER_CONSTANT.PRODUCT)}
  //         {arrowFunctionShowAndHide.product ? (
  //           <View>
  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.PRODUCT_LIST)}
  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.PRODUCT_ADD)}
  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.PRODUCT_EDIT)}
  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.PRODUCT_DELETE)}
  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.PRODUCT_PULL)}
  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.PRODUCT_STOCK_VIEW)}
  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.PRODUCT_STOCK_EDIT)}
  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.PRODUCT_COST)}
  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.PRODUCT_PRICE)}
  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.PRODUCT_CODE_EDIT_UPC)}
  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.PRODUCT_CODE_EDIT_SKU)}
  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.PRODUCT_WEIGHT)}
  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.PRODUCT_SHIPPING_QTY_RATE)}
  //           </View>
  //         ) : null}
  //       </Card>

  //       {/* // PAYMENT */}
  //       <Card style={MARGIN_RIGHT_AND_MARGIN_LEFT_4}>
  //         {this._renderTitleGroupName(p.op.t('e_modules._11'), HEADER_CONSTANT.PAYMENT)}
  //         {arrowFunctionShowAndHide.payment ? (
  //           <View>
  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.PAYMENT_LIST)}
  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.PAYMENT_ADD)}
  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.PAYMENT_EDIT)}
  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.PAYMENT_DELETE)}
  //           </View>
  //         ) : null}
  //       </Card>

  //       {/* // SHIPPING */}
  //       <Card style={MARGIN_RIGHT_AND_MARGIN_LEFT_4}>
  //         {this._renderTitleGroupName(p.op.t('e_modules._12'), HEADER_CONSTANT.SHIPPING)}
  //         {arrowFunctionShowAndHide.shipping ? (
  //           <View>
  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.SHIPPING_LIST)}
  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.SHIPPING_ADD)}
  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.SHIPPING_EDIT)}
  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.SHIPPING_DELETE)}
  //           </View>
  //         ) : null}
  //       </Card>

  //       {/* // REPORT */}
  //       <Card style={MARGIN_RIGHT_AND_MARGIN_LEFT_4}>
  //         {this._renderTitleGroupName(p.op.t('e_modules._16'), HEADER_CONSTANT.REPORT)}
  //         {arrowFunctionShowAndHide.report ? (
  //           <View>
  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.REPORT_DAILY_SALES)}
  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.REPORT_COMPLETE_SALES)}
  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.REPORT_HELPER)}
  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.REPORT_RESELLER)}
  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.REPORT_SALES_BY_CHANNELS)}

  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.REPORT_STOCK)}
  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.REPORT_PRODUCT_DAILY_SALES)}

  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.REPORT_PAYMENT)}

  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.REPORT_SHIPPED)}
  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.REPORT_REQUISITION)}

  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.REPROT_STOCK_CARD)}

  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.REPORT_PEAK_ACCOUNT)}
  //           </View>
  //         ) : null}
  //       </Card>

  //       {/* // UG */}
  //       <Card style={MARGIN_RIGHT_AND_MARGIN_LEFT_4}>
  //         {this._renderTitleGroupName(p.op.t('e_modules._20'), HEADER_CONSTANT.UG)}
  //         {arrowFunctionShowAndHide.ug ? (
  //           <View>
  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.UG_LIST)}
  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.UG_ADD)}
  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.UG_EDIT)}
  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.UG_DELETE)}
  //           </View>
  //         ) : null}
  //       </Card>

  //       {/* // MEMBER */}
  //       <Card style={MARGIN_RIGHT_AND_MARGIN_LEFT_4}>
  //         {this._renderTitleGroupName(p.op.t('e_modules._19'), HEADER_CONSTANT.MEMBER)}
  //         {arrowFunctionShowAndHide.member ? (
  //           <View>
  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.MEMBER_LIST)}
  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.MEMBER_ADD)}
  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.MEMBER_EDIT)}
  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.MEMBER_DELETE)}
  //           </View>
  //         ) : null}
  //       </Card>

  //       {/* // PG */}
  //       <Card style={MARGIN_RIGHT_AND_MARGIN_LEFT_4}>
  //         {this._renderTitleGroupName(p.op.t('e_modules._21'), HEADER_CONSTANT.PG)}
  //         {arrowFunctionShowAndHide.pg ? (
  //           <View>
  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.PG_LIST)}
  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.PG_ADD)}
  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.PG_EDIT)}
  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.PG_DELETE)}
  //           </View>
  //         ) : null}
  //       </Card>

  //       {/* // WAREHOUSE */}
  //       <Card style={MARGIN_RIGHT_AND_MARGIN_LEFT_4}>
  //         {this._renderTitleGroupName(`คลัง`, HEADER_CONSTANT.WH)}
  //         {arrowFunctionShowAndHide.wh ? (
  //           <View>
  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.WH_LIST)}
  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.WH_ADD)}
  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.WH_EDIT)}
  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.WH_DELETE)}
  //           </View>
  //         ) : null}
  //       </Card>

  //       {/* // MKP */}
  //       <Card style={MARGIN_RIGHT_AND_MARGIN_LEFT_4}>
  //         {this._renderTitleGroupName(`ช่องทางขาย`, HEADER_CONSTANT.MKP)}
  //         {arrowFunctionShowAndHide.mkp ? (
  //           <View>
  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.MKP_LIST)}
  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.MKP_ADD)}
  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.MKP_EDIT)}
  //             {this._renderCheckRow(xCONS.PERM_STORE_HELPER.MKP_DELETE)}
  //           </View>
  //         ) : null}
  //       </Card>
  //     </View>
  //   )
  // }

  // _renderEditAndCreateMode = () => {
  //   const { departmentName, departmentNameDescription, mode } = this.state
  //   return (
  //     <Card style={{ flex: 1 }}>
  //       {this._renderTitleName()}
  //       <CardItem>
  //         <XInput
  //           // onRef={r => xUtil.pushNativeBaseRef(this.inputRefs, `mProduct_price_${idx}_${index}`, r)}
  //           // onFocus={() => this.setState({ currentRef: `mProduct_price_${idx}_${index}` })}
  //           style={{
  //             flex: 0,
  //             // width: '100%',
  //             textAlign: 'left',
  //             // paddingBottom: isIOS ? 2 : 5,
  //             height: 30,
  //             fontSize: STYLES.FONT_SIZE_NORMAL,
  //             // borderBottomWidth: 0,
  //             // color: TEXT_PRIMARY,
  //           }}
  //           keyboardType='default'
  //           // returnKeyType='done'
  //           placeholder='เช่น แผนกจัดส่ง'
  //           value={departmentName}
  //           maxLength={250}
  //           onChangeText={(text) => this._onChangeTextDepartmentName(text)}
  //         />
  //       </CardItem>
  //       {this._renderTitleDescription()}
  //       <CardItem>
  //         <XInput
  //           // onRef={r => xUtil.pushNativeBaseRef(this.inputRefs, `mProduct_price_${idx}_${index}`, r)}
  //           // onFocus={() => this.setState({ currentRef: `mProduct_price_${idx}_${index}` })}
  //           style={{
  //             flex: 0,
  //             // width: '100%',
  //             textAlign: 'left',
  //             // paddingBottom: isIOS ? 2 : 5,
  //             height: 30,
  //             fontSize: STYLES.FONT_SIZE_NORMAL,
  //             // borderBottomWidth: 0,
  //             // color: TEXT_PRIMARY,
  //           }}
  //           keyboardType='default'
  //           // returnKeyType='done'
  //           // placeholder={'เช่น แผนกจัดส่ง'}
  //           value={departmentNameDescription}
  //           maxLength={250}
  //           onChangeText={(text) => this._onChangeTexteDescriptione(text)}
  //         />
  //       </CardItem>
  //       {this._renderRightsText()}
  //       {this._renderCheckBoxOption()}
  //       {mode === MODE.EDIT ? this._renderDeleteByn() : null}
  //     </Card>
  //   )
  // }

  // _renderDeleteByn = () => (
  //   <CardItem style={{ justifyContent: 'center' }}>
  //     <TouchableOpacity
  //       style={{ backgroundColor: COLORS.BRAND_Danger, height: 30, width: 150, justifyContent: 'center' }}
  //       onPress={() => this._deletePermission()}>
  //       <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.WHITE, textAlign: 'center' }}>ลบสิทธิ์การใช้งานนี้</Text>
  //     </TouchableOpacity>
  //   </CardItem>
  // )

  // render() {
  //   // TODO ซับซ้อนประมาณนึง มีอะไรถาม ARTID ได้เลย
  //   const { mode, loading } = this.state
  //   if (_.isNil(mode)) {
  //     return null
  //   }
  //   return (
  //     <XContainer>
  //       <ScrollView>{mode === MODE.VIEW ? this._renderViewMode() : this._renderEditAndCreateMode()}</ScrollView>
  //       {loading ? (
  //         <View
  //           style={{
  //             flex: 1,
  //             position: 'absolute',
  //             top: 0,
  //             left: 0,
  //             backgroundColor: 'rgba(52, 52, 52, 0.7)',
  //             width: '110%',
  //             height: '110%',
  //             zIndex: 999,
  //             justifyContent: 'center',
  //           }}>
  //           <Spinner
  //             color={COLORS.APP_MAIN}
  //             size={'large'}
  //             style={{
  //               alignSelf: 'center',
  //               justifyContent: 'center',
  //             }}
  //           />
  //         </View>
  //       ) : null}
  //     </XContainer>
  //   )
  // }
}
