import { Map, List, fromJS } from 'immutable'
import _ from 'lodash'
// eslint-disable-next-line import/no-duplicates
import xActions from 'x/config/actions'
import { log, deleteImmutableListAtIndex } from '../../utils/util'
// eslint-disable-next-line import/no-duplicates
import actions from '../../config/actions'
import { ActionApiParams } from '../../index'

import {
  getUsersInGroupInResellerByKey,
  setUsersInGroupInResellerByKey,
  setUserInGroupInResellerByKeyAndIndex,
  setIsFetchingUsersInGroupInResellerByKey,
  setIsAllFetchedUsersInGroupInResellerByKey,
  getCountOfUsersInGroupInResellerByKey,
  setCountOfUsersInGroupInResellerByKey,
  setShouldFetchOfUsersInGroupInResellerByKey,
} from '../../redux/selectors'

const { RESELLER_ADD, RESELLER_DELETE, RESELLER_DELETE_FROM_LIST } = xActions

// import settings from '../../config/settings'
// Initial state
const initMemberObject = {
  members: [],
  isLoaded: true,
  shouldFetch: false,
}

// let mockingUsers = List([])
// for (let mIdx = 1; mIdx <= 10; mIdx++) {
//   mockingUsers = mockingUsers.push(Map({
//     ui: mIdx,
//     dn: `Leeching Achariyakul ${mIdx}`,
//     iu: 'https://xselly.s3-ap-southeast-1.amazonaws.com/p%2Ff%2F201802221913puYVrGL64mK.jpg',
//     tu: 'https://xselly.s3-ap-southeast-1.amazonaws.com/p%2Ff%2F201802221913tuYVrGL64mK.jpg',
//     fi: null,
//     li: null,
//     ra: '2018-07-11 16:55:12',
//   }))
// }

const initMemberMap = Map({
  users: List([]),
  count: 0,
  isFetching: false,
  isAllFetched: false,
  shouldFetch: false,
  // users: mockingUsers,
  // count: 20,
  // isFetching: false,
  // isAllFetched: true,
})

export const initialState = Map({
  // tabStatus: Map({
  //   selectedMainTabKey: CONS.ORDER_KEY_MY_TASKS,
  //   selectedMyTasksSubTabKey: CONS.ORDER_KEY_CONFIRM_ORDER,
  //   selectedResellerTasksSubTabKey: CONS.ORDER_KEY_CONFIRM_ORDER,
  //   selectedSellerTasksSubTabKey: CONS.ORDER_KEY_CONFIRM_ORDER,
  //   selectedDoneTasksSubTabKey: CONS.ORDER_KEY_SHIPPED,
  // }),
  // fetchStatus: Map({
  // }),
  // orders: Map(),
  // summary: Map(),
  // ordersToBePaid: Map(),
  // preMembers: [],
  // isPreMembersLoaded: false,
  //
  // joinedMembers: [],
  // isJoinedMembersLoaded: false,
  //
  // blockedMembers: [],
  // isBlockedMembersLoaded: false,
  pre: initMemberObject,
  joined: initMemberObject,
  blocked: initMemberObject,

  pending_users: initMemberMap,
  joined_users: initMemberMap,
  blocked_users: initMemberMap,
})

// Actions
export const INIT = 'ResellerState/INIT'
export const RESET = 'ResellerState/RESET'
export const PREAPPROVE_FB_MEMBERS = 'ResellerState/PREAPPROVE_FB_MEMBERS'
export const PREAPPROVE_FB_MEMBERS_IMPORT_FAILED = 'ResellerState/PREAPPROVE_FB_MEMBERS_IMPORT_FAILED'

export const PRE_FETCH_MEMBERS = 'ResellerState/PRE_FETCH_MEMBERS'
export const LOAD_MEMBERS = 'ResellerState/LOAD_MEMBERS'
export const LOAD_MEMBERS_NO_DATA = 'ResellerState/LOAD_MEMBERS_NO_DATA'
export const SHOULD_FETCH_MEMBERS = 'ResellerState/SHOULD_FETCH_MEMBERS'
export const CLEAR_ALL_USER_LIST = 'ResellerState/CLEAR_ALL_USER_LIST'

// Action creators
export function init() {
  return { type: INIT }
}

export function clearAllUserList() {
  return { type: CLEAR_ALL_USER_LIST }
}

export function preApproveFBMembers(payload) {
  return { type: PREAPPROVE_FB_MEMBERS, payload }
}

export function addReseller({ body, successCallback, failedCallback }) {
  return {
    type: RESELLER_ADD,
    payload: body,
    successCallback,
    failedCallback,
  }
}

export function deleteReseller({ body, successCallback, failedCallback }) {
  return {
    type: RESELLER_DELETE,
    payload: body, // { store_id, profile_id }
    successCallback,
    failedCallback,
  }
}

export function fetchResellerPendingList(params: ActionApiParams) {
  return {
    type: actions.RESELLER_PENDING_FETCH,
    ...params,
  }
}

export function fetchResellerJoinedList(params: ActionApiParams) {
  return {
    type: actions.RESELLER_JOINED_FETCH,
    ...params,
  }
}

export function kickJoinedUserFromStore(params: ActionApiParams) {
  return {
    type: actions.KICK_JOINED_USER_FROM_STORE,
    ...params,
  }
}

export function changeUserGroupInJoinedUsers(params: ActionApiParams) {
  return {
    type: actions.CHANGE_USER_GROUP_IN_JOINED_USERS,
    ...params,
  }
}

export function loadResellerGenericList(response: { [key: string]: any }, action: ActionApiParams) {
  return {
    type: actions.RESELLER_LIST_LOAD,
    payload: {
      response,
      action,
    },
  }
}

export function setResellerListIsFetching(action: ActionApiParams) {
  return {
    type: actions.RESELLER_IS_FETCHING,
    payload: action,
  }
}

export function changeUsersPendingStatus(params: ActionApiParams) {
  return {
    type: actions.CHANGE_PENDING_USERS_STATUS,
    ...params,
  }
}

export function setSubmittingBeforeChangeUserPendingStatus(profile_id: number) {
  return {
    type: actions.PREPARE_CHANGE_PENDING_USER_STATUS,
    payload: profile_id,
  }
}

export function setApprovedOrRejectedUserStatusToList(action: ActionApiParams) {
  return {
    type: actions.SET_APPROVED_OR_REJECED_USER_STATUS_IN_PENDING_LIST,
    payload: action,
  }
}

export function setIsKickedUser(action: ActionApiParams) {
  return {
    type: actions.SET_IS_KICKED_USER_IN_JOINED_LIST,
    payload: action,
  }
}

export function setIsChangedGroupUser(action: ActionApiParams) {
  return {
    type: actions.SET_IS_CHANGED_GROUP_USER_IN_JOINED_LIST,
    payload: action,
  }
}

export function addNewUser(params: ActionApiParams) {
  return {
    type: actions.ADD_NEW_USER_TO_UG,
    ...params,
  }
}

export function acceptAllPendingUsers(params: ActionApiParams) {
  return {
    type: actions.ACCEPT_ALL_PENDING_USERS,
    ...params,
  }
}

export function doneAcceptedAllPendingUsers() {
  return {
    type: actions.DONE_ACCEPT_ALL_PENDING_USERS,
  }
}

export function shouldFetchUsersByPrefixKey(key: 'pending' | 'joined') {
  return {
    type: actions.SHOULD_FETCH_USERS_BY_PREFIX_KEY,
    payload: key,
  }
}

// Reducer
export default function ResellerStateReducer(state = initialState, action = { type: null, payload: null }) {
  let newState = state
  const { type, payload } = action

  switch (type) {
    case INIT:
    case RESET:
      return initialState
    case CLEAR_ALL_USER_LIST:
      newState = newState.set('pending_users', initMemberMap)
      newState = newState.set('joined_users', initMemberMap)
      newState = newState.set('blocked_users', initMemberMap)
      return newState

    case PRE_FETCH_MEMBERS:
      if (!payload) {
        return state
      }
      return newState.setIn([payload, 'isLoaded'], false) // payload is key

    case LOAD_MEMBERS:
      if (!payload.key || !payload.members) {
        return state
      }
      newState = newState.setIn([payload.key, 'members'], payload.members) // FIXME: Members is Array !!?
      newState = newState.setIn([payload.key, 'shouldFetch'], false)
      return newState.setIn([payload.key, 'isLoaded'], true)

    case LOAD_MEMBERS_NO_DATA:
      if (!payload.key) {
        return state
      }
      return newState.setIn([payload.key, 'isLoaded'], true)

    case SHOULD_FETCH_MEMBERS:
      if (!payload.key) {
        return state
      }
      return newState.setIn([payload.key, 'shouldFetch'], true)

    case RESELLER_DELETE_FROM_LIST: {
      if (!payload.profile_id || !payload.key) {
        return state
      }
      const selectedMemberList = _.cloneDeep(newState.getIn([payload.key, 'members'])) // FIXME: Members is Array !!?
      // log('ResellerState payload.profile_id => ', payload.profile_id)
      // log('ResellerState selectedMemberList => ', selectedMemberList)
      const foundIndex = selectedMemberList.findIndex((r) => r && r.i === payload.profile_id)
      // log('ResellerState foundIndex => ', foundIndex)
      if (foundIndex < 0) {
        return state
      }
      selectedMemberList.splice(foundIndex, 1)
      // log('ResellerState spliced selectedMemberList => ', selectedMemberList)
      return newState.setIn([payload.key, 'members'], selectedMemberList)
    }

    case actions.RESELLER_LIST_LOAD:
      return loadResellerList(newState, payload)

    case actions.RESELLER_IS_FETCHING:
      return setIsFetchingResellerList(newState, payload)

    case actions.SET_APPROVED_OR_REJECED_USER_STATUS_IN_PENDING_LIST:
      return doSetApprovedOrRejectedUserStatusInList(newState, payload)

    case actions.SET_IS_KICKED_USER_IN_JOINED_LIST:
      return doSetIsKickedUserInList(newState, payload)

    case actions.SET_IS_CHANGED_GROUP_USER_IN_JOINED_LIST:
      return doSetIsChangedGroupUserInList(newState, payload)

    case actions.PREPARE_CHANGE_PENDING_USER_STATUS:
      return setSubmittingBeforeChangePendingUserStatus(newState, payload)

    case actions.DONE_ACCEPT_ALL_PENDING_USERS:
      return doAfterDoneAcceptedAllPendingUsers(newState)

    case actions.SHOULD_FETCH_USERS_BY_PREFIX_KEY:
      return setShouldFetchOfUsersInGroupInResellerByKey(newState, payload, true)

    default:
      return state
  }
}

// internal compute
function loadResellerList(
  state: Map<string, any>,
  payload: { action: ActionApiParams; response: { [key: string]: any } }
): Map<string, any> {
  const { action = null, response = null } = payload
  if (!action || !action.type || !action.body || !response || _.isEmpty(response)) {
    return state
  }

  const prefixKey = getPrefixUserKeyFromActionType(action.type)
  if (!prefixKey) {
    return state
  }

  // const users = response[`${prefixKey}_users`]
  const { users } = response
  if (!users || !_.isArray(users)) {
    return state
  }

  let newState = state
  const { offset = 0, isRefresh = false } = action.body
  const allCount = _.has(response, 'count') ? response.count : getCountOfUsersInGroupInResellerByKey(newState, prefixKey)
  newState = setCountOfUsersInGroupInResellerByKey(newState, prefixKey, allCount)

  if (isRefresh) {
    newState = setUsersInGroupInResellerByKey(newState, prefixKey, List([]))
    newState = setIsAllFetchedUsersInGroupInResellerByKey(newState, prefixKey, false)
  }

  if (users.length === 0) {
    newState = setIsAllFetchedUsersInGroupInResellerByKey(newState, prefixKey, true)
  } else {
    // let newUsers = getUsersInGroupInResellerByKey(newState, prefixKey)
    // newUsers = newUsers.concat(fromJS(users))
    // newState = setUsersInGroupInResellerByKey(newState, prefixKey, newUsers)
    let setterIdx = offset
    for (let i = 0; i < users.length; i++) {
      // newUsers = newUsers.set(i, fromJS(users))
      newState = setUserInGroupInResellerByKeyAndIndex(newState, prefixKey, setterIdx++, fromJS(users[i]))
    }

    // if (newUsers.size >= allCount) {
    //   newState = setIsAllFetchedUsersInGroupInResellerByKey(newState, prefixKey, true)
    // }

    const newUsers = getUsersInGroupInResellerByKey(newState, prefixKey)
    let allFetched = true
    if (allCount === newUsers.size) {
      for (let i = 0; i < allCount; i++) {
        if (!newUsers.get(i)) {
          log('loadResellerList !newUsers.get(i) => ', i)
          allFetched = false
          break
        }
      }
    } else {
      allFetched = false
    }
    log('loadResellerList allFetched => ', allFetched)
    if (allFetched) {
      newState = setIsAllFetchedUsersInGroupInResellerByKey(newState, prefixKey, true)
    }
  }

  newState = setIsFetchingUsersInGroupInResellerByKey(newState, prefixKey, false)
  return newState
}

function setIsFetchingResellerList(state: Map<string, any>, action: ActionApiParams): Map<string, any> {
  const { type = null, body = null } = action

  if (!type || !body || _.isEmpty(body)) {
    return state
  }

  const prefixKey = getPrefixUserKeyFromActionType(action.type)
  if (!prefixKey) {
    return state
  }

  let newState = state
  newState = setIsFetchingUsersInGroupInResellerByKey(newState, prefixKey, true)
  return newState
}

function getPrefixUserKeyFromActionType(actionType: string): string | null {
  let prefixKey = null
  if (actionType === actions.RESELLER_PENDING_FETCH) {
    prefixKey = 'pending'
  } else if (actionType === actions.RESELLER_JOINED_FETCH) {
    prefixKey = 'joined'
  } else if (actionType === actions.RESELLER_BLOCKED_FETCH) {
    prefixKey = 'blocked'
  }
  return prefixKey
}

function doSetApprovedOrRejectedUserStatusInList(state: Map<string, any>, action: ActionApiParams): Map<string, any> {
  const { body = null } = action

  if (!body || _.isEmpty(body) || !body.profile_id || !body.newStatus) {
    return state
  }

  const prefixKey = 'pending'
  let newState = state
  const { profile_id, newStatus } = body
  let newUsers = getUsersInGroupInResellerByKey(newState, prefixKey)
  let newUserStatusKey = null

  if (newStatus === 'a') {
    newUserStatusKey = 'approved'
  } else if (newStatus === 'r') {
    newUserStatusKey = 'rejected'
  } else if (newStatus === 'b') {
    newUserStatusKey = 'blocked'
  } else {
    return state
  }

  const foundUserIndex = newUsers.findIndex((userMap) => Map.isMap(userMap) && userMap.get('i') === profile_id)
  if (foundUserIndex === -1) {
    return state
  }

  // newUsers = newUsers.setIn([foundUserIndex, newUserStatusKey], true)
  // newUsers = newUsers.setIn([foundUserIndex, 'submitting'], false)
  newUsers = deleteImmutableListAtIndex(newUsers, foundUserIndex)
  const newPendingCount = getCountOfUsersInGroupInResellerByKey(newState, prefixKey)
  if (newPendingCount > 0) {
    newState = setCountOfUsersInGroupInResellerByKey(newState, prefixKey, newPendingCount - 1)
  }

  if (newUserStatusKey === 'approved') {
    const newJoinedCount = getCountOfUsersInGroupInResellerByKey(newState, 'joined')
    if (newJoinedCount > 0) {
      newState = setCountOfUsersInGroupInResellerByKey(newState, 'joined', newJoinedCount + 1)
      newState = setIsAllFetchedUsersInGroupInResellerByKey(newState, 'joined', false)
    }
  }

  newState = setUsersInGroupInResellerByKey(newState, prefixKey, newUsers)
  return newState
}

function doSetIsKickedUserInList(state: Map<string, any>, action: ActionApiParams): Map<string, any> {
  const { body = null } = action
  if (!body || _.isEmpty(body) || !body.profile_id) {
    return state
  }
  const prefixKey = 'joined'
  let newState = state
  const { profile_id } = body
  let newUsers = getUsersInGroupInResellerByKey(newState, prefixKey)
  const foundUserIndex = newUsers.findIndex((userMap) => Map.isMap(userMap) && userMap.get('i') === profile_id)
  if (foundUserIndex === -1) {
    return state
  }
  // newUsers = newUsers.setIn([foundUserIndex, 'kicked'], true)
  // newUsers = newUsers.setIn([foundUserIndex, 'submitting'], false)
  newUsers = deleteImmutableListAtIndex(newUsers, foundUserIndex)
  const newJoinedCount = getCountOfUsersInGroupInResellerByKey(newState, prefixKey)
  if (newJoinedCount > 0) {
    newState = setCountOfUsersInGroupInResellerByKey(newState, prefixKey, newJoinedCount - 1)
  }

  newState = setUsersInGroupInResellerByKey(newState, prefixKey, newUsers)
  return newState
}

function doSetIsChangedGroupUserInList(state: Map<string, any>, action: ActionApiParams): Map<string, any> {
  const { body = null } = action
  if (!body || _.isEmpty(body) || !body.profile_id) {
    return state
  }
  const prefixKey = 'joined'
  let newState = state
  const { profile_id } = body
  let newUsers = getUsersInGroupInResellerByKey(newState, prefixKey)
  const foundUserIndex = newUsers.findIndex((userMap) => Map.isMap(userMap) && userMap.get('i') === profile_id)
  if (foundUserIndex === -1) {
    return state
  }
  // newUsers = newUsers.setIn([foundUserIndex, 'changedGroup'], true)
  // newUsers = newUsers.setIn([foundUserIndex, 'submitting'], false)
  newUsers = deleteImmutableListAtIndex(newUsers, foundUserIndex)
  const newJoinedCount = getCountOfUsersInGroupInResellerByKey(newState, prefixKey)
  if (newJoinedCount > 0) {
    newState = setCountOfUsersInGroupInResellerByKey(newState, prefixKey, newJoinedCount - 1)
  }

  newState = setUsersInGroupInResellerByKey(newState, prefixKey, newUsers)
  return newState
}

function setSubmittingBeforeChangePendingUserStatus(state: Map<string, any>, profile_id: number): Map<string, any> {
  log('setSubmittingBeforeChangePendingUserStatus profile_id => ', profile_id)
  if (!profile_id) {
    return state
  }
  const prefixKey = 'pending'
  let newState = state
  let newUsers = getUsersInGroupInResellerByKey(newState, prefixKey)

  const foundUserIndex = newUsers.findIndex((userMap) => Map.isMap(userMap) && userMap.get('i') === profile_id)
  if (_.isNumber(foundUserIndex)) {
    newUsers = newUsers.setIn([foundUserIndex, 'submitting'], true)
  } else {
    return state
  }

  newState = setUsersInGroupInResellerByKey(newState, prefixKey, newUsers)
  return newState
}

function doAfterDoneAcceptedAllPendingUsers(state: Map<string, any>): Map<string, any> {
  let newState = state
  newState = setUsersInGroupInResellerByKey(newState, 'pending', List([]))
  newState = setCountOfUsersInGroupInResellerByKey(newState, 'pending', 0)
  newState = setIsAllFetchedUsersInGroupInResellerByKey(newState, 'pending', true)
  return newState
}
