import React from 'react'
import { connect } from 'react-redux'

import { getState, getDispatch } from 'x/modules/orderlist/StoreOrderSingleTabListViewConnect'
import {
  BackgroundJobTypeType,
  IApiOptions,
  IAutoCompleteBackgroundJobResponse,
  IBackgroundJobListResponse,
  IFetchOrdersRequestBody,
  IOrderListItem,
  IOrderListViewProps,
  IOrderListViewState,
  IPaymentAccount,
  IPaymentAccountMap,
} from 'x/index'
import VStack from 'xui/components/VStack'
import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'
import * as NavActions from 'x/utils/navigation'
import CONS from 'x/config/constants'

import api from 'x/utils/api'
import PaymentAccountPicker from 'xui/components/PaymentAccountPicker'
import { fromJS } from 'immutable'
import XText from '../../components/XText'

import BaseUIOrderListView from './BaseUIOrderListView'

interface IAutoPaymentOrderListView extends IOrderListViewState {
  selectedBankAccount?: IPaymentAccountMap
}

class AutoPaymentOrderListView extends BaseUIOrderListView<IOrderListViewProps, IAutoPaymentOrderListView> {
  static displayName = 'AutoPaymentOrderListView'

  onPressOrderListItem = (od: IOrderListItem) => {
    const { navigation } = this.props

    navigation.dispatch(
      NavActions.navToOrderDetail({
        order_id: od.id,
        store_id: this.getStoreId(),
      })
    )
    // navigation.dispatch(
    //   NavActions.navToOrderListView({
    //     // order_id: od.id,
    //     store_id: this.getStoreId(),
    //     onPressOrderListItem: this._mockOnPressOrderListItem,
    //     onPressSubmitCheckedOrders: this._mockOnPressSubmitCheckedOrders,
    //   })
    // )
    // this.toggleCheckOrderListItem(od.id)
  }

  getOverridePreSetupInitialState = (): Partial<IOrderListViewState> => ({
    isCheckable: true,
    availableTabKeys: [
      'myTasks_confirmOrder',
      'myTasks_pay',
      'myTasks_confirmGettingPaid',
      'myTasks_ship',
      'custTasks_pay',
      'custTasks_autoComplete',
      'resellerTasks_confirmOrder',
      'resellerTasks_pay',
      'resellerTasks_confirmGettingPaid',
      'sellerTasks_confirmOrder',
      'sellerTasks_confirmGettingPaid',
      'sellerTasks_ship',
    ],

    // ordersFilterSetting: {
    //   order_segment: 'my_unshipped_only_and_not_mkp',
    // },
    displayMode: 'page',
    displayPageLimit: 30,
  })

  getOverrideRequestBody = (): Partial<IFetchOrdersRequestBody> => ({
    order_segment: 'cust_and_reseller_to_pay_me_and_not_mkp',
  })

  getHeaderTitle = () => {
    const defaultTitle = this.getTabLabelWithCount(this.state.tabName)
    return `บันทึกรับชำระอัตโนมัติ\n${defaultTitle}`
  }

  getSubmitCheckOrdersButtonText = () => {
    const checkedOrders = this.getCheckedOrderIds()
    const checkedCount = checkedOrders.length
    return checkedCount > 0 ? 'บันทึกรับชำระอัตโนมัติ' : 'กรุณาเลือกออเดอร์ที่ต้องการบันทึกรับชำระอัตโนมัติ\nโดยแตะปุ่มกลมทางซ้าย'
  }

  isSubmitCheckOrdersButtonDisabled = () => {
    const checkedOrders = this.getCheckedOrderIds()
    const checkedCount = checkedOrders.length
    return checkedCount === 0
  }

  // onPressSubmitCheckedOrders = async (order_ids: number[]) => {
  onPressSubmitCheckedOrders = async (checkedOrders: IOrderListItem[]) => {
    const { selectedBankAccount = null } = this.state
    const selectedStoreId = this.getStoreId()
    // const checkedOrderCount = order_ids.length
    const checkedOrderCount = checkedOrders.length
    if (!checkedOrderCount) {
      return
    }

    const order_ids = checkedOrders.map((codr) => codr.id)

    const bgJobType = CONS.BACKGROUND_JOB_TYPES.PAYMENT_CREATE
    const { isAvailable, bgJobResponse } = await util.checkAvailableBackgroundJobType({ bgJobType, store_id: selectedStoreId })
    if (!isAvailable) {
      this._navToBackgroundJobInProcessByTypeView({
        bgJobType,
        initBgJobResponse: bgJobResponse,
      })
      return
    }

    const isUserConfirm = await p.op.isUserConfirm(
      `มี ${checkedOrderCount} ออเดอร์ออเดอร์ที่ถูกเลือก`,
      'กดปุ่ม ดำเนินการ เพื่อยืนยันสร้างคิวคำสั่งงานบันทึกรับชำระอัตโนมัติทันที',
      'ดำเนินการ',
      'ยกเลิก'
    )
    if (!isUserConfirm) {
      return
    }

    if (!selectedBankAccount || !selectedBankAccount.get('id')) {
      await p.op.alertPromise('กรุณาเลือกบัญชีรับชำระ', 'กรุณาเลือกบัญชีรับชำระเพื่อใช้ในการบันทึกรับชำระให้แก่ของออเดอร์ที่ถูกเลือก')
      this._onNavToSellerStorePaymentAccount()
      return
    }

    const paymentAccountId = selectedBankAccount.get('id')
    const reqBody = {
      store_id: selectedStoreId,
      payment_account_id: paymentAccountId,
      order_ids,
    }
    const apiOptions: IApiOptions = {
      axiosOptions: {
        retry: 0,
        timeout: 60000,
      },
      // isErrorAlertDisabled: true,
      isApiV2: true,
    }

    try {
      const res = await api.post<any, IAutoCompleteBackgroundJobResponse>(api.POST_AUTOCOMPLETE_PAYMENT, reqBody, apiOptions)
      if (res.bgjob_uuid) {
        // this._navToBackgroundJobInProcessByTypeView({
        //   bgJobType,
        //   initBgJobResponse: { jobs: [res.bgjob_detail], count: 1 },
        //   warningText: 'สร้างคำสั่งงานสำเร็จ กรุณารอให้ระบบดำเนินงานให้เสร็จสิ้นก่อนเพิ่มคำสั่งถัดไป',
        //   warningTextBgColor: COLORS.FORM_SUCCESS_BG,
        // })
        // this._goBackToMyStore()
        // p.op.showToast('สร้างคิวคำสั่งงาน "บันทึกรับชำระอัตโนมัติ" สำเร็จ', 'success', 10000)
        await util.alertWithNavToBgJobListView({
          title: 'สร้าง "คิวคำสั่งงาน" สำเร็จ',
          message:
            'คิวคำสั่งงาน สำหรับการ "บันทึกรับชำระอัตโนมัติ" ได้ถูกสร้างแล้ว ระบบกำลังดำเนินการอยู่เบื้องหลัง และอาจใช้เวลาซักครู่จนกว่าจะครบทุกออเดอร์',
          dispatch: this.props.navigation.dispatch,
          selectedStoreId,
          initBgJobUUID: res.bgjob_uuid,
          onUserConfirm: this._quitAutoPaymentOrderListView,
        })
      }
      // console.log('_handlePressMultipleAutoCompletePayment res => ', res)
    } catch (err) {
      // console.log('_handlePressMultipleAutoCompletePayment err => ', err)
      // if (util.isXSellyErrorV2(err)) {
      //   util.alertWithXSellyErrorV2(err.error)
      // }
    }
  }

  _onNavToSellerStorePaymentAccount = () => {
    // // console.log('_onNavToSellerStorePaymentAccount * => ')
    // this.props.navigation.dispatch(
    //   NavActions.navToSellerStorePaymentAccountListView({
    //     store_id: this.getStoreId(),
    //     title: 'เลือกบัญชีรับชำระ',
    //     selectAccountCallback: (acc: IPaymentAccountMap) => this.setState({ selectedBankAccount: acc }),
    //   })
    //   // NavActions.navToStorePaymentAccountListView({
    //   //   targetStoreId: this.getStoreId(),
    //   //   headerTitle: 'เลือกบัญชีรับชำระ',
    //   //   onPressPaymentAccountCard: async (acc: IPaymentAccountMap) => {
    //   //     this.setState({ selectedBankAccount: acc })
    //   //     return true
    //   //   },
    //   // })
    // )
  }

  _quitAutoPaymentOrderListView = () => {
    this.goBack()
  }

  _navToBackgroundJobInProcessByTypeView = (params: {
    bgJobType: BackgroundJobTypeType
    initBgJobResponse: IBackgroundJobListResponse
    warningText?: string
    warningTextBgColor?: string
  }) => {
    const { navigation } = this.props
    const selectedStoreId = this.getStoreId()
    navigation.dispatch(
      NavActions.navToBackgroundJobInProcessByTypeView({
        storeId: selectedStoreId,
        // bgJobType: CONS.BACKGROUND_JOB_TYPES.PAYMENT_CREATE,
        // initBgJobResponse,
        // warningText: '',
        ...params,
      })
    )
  }

  _onChangePaymentAccount = (selectedPaymentAccount: IPaymentAccount) => {
    this.setState({ selectedBankAccount: fromJS(selectedPaymentAccount) })
    return selectedPaymentAccount
  }

  renderExtraHeader = () => {
    const { isInitialized } = this.state
    if (!isInitialized) {
      return null
    }
    return (
      <VStack w='full' p='2'>
        <XText variant='inactive'>บันทึกรับชำระเข้าบัญชี:</XText>
        <PaymentAccountPicker
          targetStoreId={this.getStoreId()}
          onChangePaymentAccount={this._onChangePaymentAccount}
          placeholderText='เลือกบัญชีบันทึกรับชำระ'
        />
      </VStack>
    )
  }
}

// export default OrderListView

export default connect(
  (state) => ({
    ...getState(state),
  }),
  (dispatch) => ({
    ...getDispatch(dispatch),
  })
)(AutoPaymentOrderListView)
