import React, { Component } from 'react'
import _ from 'lodash'
import * as util from 'x/utils/util'
import { ISelectedStoreMap, IProfile, IXScreenProps } from 'x/index'
import p from 'x/config/platform-specific'
import api from 'x/utils/api'

interface IBaseCustomerEditingViewProps extends IXScreenProps {
  dispatch: (params: any) => void
  selectedStore: ISelectedStoreMap
  editCustomer: (params: any) => void
}

interface IBaseCustomerEditingViewState {
  profile: IProfile
  name: string
  lastName: string
  phone: string
  email: string
}

export default class BaseCustomerEditingView extends Component<IBaseCustomerEditingViewProps, IBaseCustomerEditingViewState> {
  imgManagerRef: React.RefObject<any>

  protected constructor(props: IBaseCustomerEditingViewProps) {
    super(props)
    // this.imgManagerRef = React.createRef()
    this.state = {
      profile: null,
      name: '',
      lastName: '',
      phone: '',
      email: '',
    }
  }

  async componentDidMount() {
    // const { navigation } = this.props
    // const { state } = navigation
    // const { profile } = state.params
    const profile = util.getNavParam(this.props, 'profile')
    const newProfile: IProfile = profile
    const name = !_.isNil(newProfile) ? newProfile.first_name : ''
    const lastName = !_.isNil(newProfile) ? newProfile.last_name : ''
    const phone = !_.isNil(newProfile) ? newProfile.phone1 : ''
    const email = !_.isNil(newProfile) ? newProfile.contact_email : ''

    await util.setStatePromise(this, {
      profile: newProfile,
      name,
      lastName,
      phone,
      email,
    })
  }

  _onChangeText = (key: string, newText: string) => {
    // @ts-ignore
    this.setState({ [key]: newText })
  }

  _summit = async () => {
    // const { editCustomer } = this.props
    const { profile, name, lastName, email, phone } = this.state
    if (_.isNil(profile)) {
      p.op.showConfirmationOkOnly('', 'ไม่พบ profile')
      return
    }
    if (name.length < 3) {
      p.op.showConfirmationOkOnly('', 'กรุณาระบุชื่อ หรืออย่างน้อยชื่อต้องมี 3 ตัวอักษร')
      return
    }
    const body: any = {}
    body.profile_id = profile.id
    body.first_name = name
    if (!_.isNil(lastName) && lastName.length > 0) {
      body.last_name = lastName
    }
    if (!_.isNil(phone) && phone.length > 0) {
      body.phone1 = phone
    }
    if (!_.isNil(email) && email.length > 0) {
      body.contact_email = email
    }

    // console.log('body => ', body)
    const response = await api.patch(api.PATCH_CUSTOMER, body, { showSpinner: true })
    // const response = await new Promise((resolveEdit) => {
    //   editCustomer({
    //     body,
    //     successCallback: resolveEdit,
    //     failedCallback: () => resolveEdit(null),
    //   })
    // })
    // console.log('response => ', response)
    if (response) {
      p.op.showToast('แก้ไขข้อมูลเสร็จสิ้นแล้ว', 'success')
      const succeedCallback = util.getNavParam(this.props, 'succeedCallback')
      if (_.isFunction(succeedCallback)) {
        // @ts-ignore
        succeedCallback(response.profile)
      }
      util.navGoBack(this.props)
    }
  }
}
