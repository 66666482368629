import { all, fork } from 'redux-saga/effects'

import xWatchAllPayments from 'x/redux/sagas/payments'
import xWatchAllProducts from 'x/redux/sagas/products'
import xWatchAllStores from 'x/redux/sagas/stores'
import xWatchAllOrders from 'x/redux/sagas/orders'
import xWatchAllReports from 'x/redux/sagas/reports'
import xWatchAllShipments from 'x/redux/sagas/shipments'
import xWatchAllUserProfile from 'x/redux/sagas/profile'
import xWatchAllSubscription from 'x/redux/sagas/subscription'
import xWatchAllAuthentication from 'x/redux/sagas/auth'
import xWatchAllBanks from 'x/redux/sagas/banks'

import xWatchAllProductGroups from 'x/redux/sagas/product_groups'
import xWatchAllUserGroups from 'x/redux/sagas/user_groups'
import xwatchAllVolumeDiscounts from 'x/redux/sagas/volume_discounts'

import xWatchAllPreference from 'x/redux/sagas/preference'
import xWatchAllCategory from 'x/redux/sagas/category'
import xwatchAllAddress from 'x/redux/sagas/addresses'
import watchAllContacts from 'x/redux/sagas/contacts'

export default function* commonRootSaga() {
  yield all([
    fork(xWatchAllPayments),
    fork(xWatchAllProducts),
    fork(xWatchAllStores),
    fork(xWatchAllOrders),
    fork(xWatchAllReports),
    fork(xWatchAllShipments),
    fork(xWatchAllUserProfile),
    fork(xWatchAllSubscription),
    fork(xWatchAllAuthentication),
    fork(xWatchAllBanks),

    fork(xWatchAllProductGroups),
    fork(xWatchAllUserGroups),
    fork(xwatchAllVolumeDiscounts),

    fork(xWatchAllPreference),
    fork(xWatchAllCategory),

    fork(xwatchAllAddress),
    fork(watchAllContacts),
  ])
}
