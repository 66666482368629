import React, { Component } from 'react'

import XCustomHeader from 'xui/components/XCustomHeader'
import { View, Text, TouchableOpacity, Linking } from 'react-native'
import { COLORS, STYLES } from 'x/config/styles'
import Dropzone from 'react-dropzone'
import XIcon from 'xui/components/XIcon'
import { ISelectedStoreMap, ActionApiParams, IXScreenProps } from 'x/index'
import * as xUtil from 'x/utils/util'
import _ from 'lodash'
import p from 'x/config/platform-specific'
import Modal from 'react-modal'
import axios from 'axios'
// import { handleResponse } from 'x/utils/api'
import { Button } from 'react-native-elements'
import { getConfig, APP_CONFIG } from 'x/config/mode'
import CONS from 'x/config/constants'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import ThemeSizeContext from '../../context/ThemeSizeContext'

interface IResellerImportViewProps extends IXScreenProps {
  dispatch: (params: any) => void
  selectedStore: ISelectedStoreMap
  importProfiles: (params: ActionApiParams) => void
}

interface IResellerImportViewState {
  fileUpload: IFileUpload
  isModelUploading: boolean
  isUpload: boolean
  isError: boolean
  errLable: string
}

interface IFileUpload {
  name: string
  lastModified: number
  webkitRelativePath: string
  size: number
  type: string
  path: string
}

export default class ResellerImportView extends Component<IResellerImportViewProps, IResellerImportViewState> {
  protected constructor(props: IResellerImportViewProps) {
    super(props)
    this.state = {
      fileUpload: null,
      isModelUploading: false,
      isUpload: false,
      isError: false,
      errLable: '',
    }
  }

  static contextType = ThemeSizeContext

  context!: React.ContextType<typeof ThemeSizeContext>

  _renderDownloadTemplateView = () => {
    const linkDownload = APP_CONFIG.templateImportResellerUrl
    return (
      <View style={{ width: '100%', height: 120, justifyContent: 'center', alignItems: 'center', backgroundColor: COLORS.WHITE }}>
        <TouchableOpacity
          onPress={() => Linking.openURL(linkDownload)}
          style={{
            width: 180,
            flexDirection: 'row',
            height: 30,
            borderColor: COLORS.APP_MAIN,
            borderRadius: 7,
            borderWidth: 0.8,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <XIcon
            name='exclefile1'
            family='AntDesign'
            style={{ flex: 0, minWidth: 25, width: 25, color: COLORS.APP_MAIN, fontSize: STYLES.FONT_ICON_SMALLER }}
          />
          <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE }}>ดาวน์โหลด Excel เทมเพลต</Text>
        </TouchableOpacity>
        <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE, paddingTop: 4 }}>
          กดปุ่ม ดาวน์โหลด Excel เทมเพลต ด้านบน เพื่อนำไปกรองข้อมูล
        </Text>
        <View style={{ height: 20 }} />
        <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE, paddingLeft: 4, paddingRight: 4 }}>
          เมื่อกรอกเสร็จแล้ว สามารถนำไฟล์ที่กรอกข้อมูลมาวางในบริเวณด้านล่าง แล้วกดปุ่มอัพโหลด เพื่อนำเข้าข้อมูลบัญชีตัวแทนรายใหม่
        </Text>
      </View>
    )
  }

  _setFileUpload = (acceptedFiles: IFileUpload[]) => {
    // console.log(acceptedFiles)
    xUtil.setStatePromise(this, {
      fileUpload: acceptedFiles[0],
    })
  }

  _renderDropzone = () => {
    const { fileUpload } = this.state
    const lableDropZone = _.isNil(fileUpload) ? `วาง Excel ไฟล์ไว้ตรงนี้ หรือกดตรงนี้เพื่อเลือกไฟล์` : `ไฟล์ที่เลือก\n"${fileUpload.name}"`
    // const lableDropZone = `TEST`
    const borderStyle = _.isNil(fileUpload) ? 'dashed' : 'solid'
    return (
      <View style={{ width: '100%', height: 150, justifyContent: 'center', alignItems: 'center', backgroundColor: COLORS.WHITE }}>
        <Dropzone
          onDrop={(acceptedFiles) => {
            // @ts-ignore
            this._setFileUpload(acceptedFiles)
          }}
          multiple={false}>
          {({ getRootProps, getInputProps }) => (
            <TouchableOpacity style={{ width: `100%`, paddingLeft: 4, paddingRight: 4 }}>
              <div style={{ backgroundColor: COLORS.BG_LIGHT_GREY, borderRadius: 10 }} {...getRootProps()}>
                <input {...getInputProps()} />
                <View
                  style={{
                    height: 150,
                    width: '100%',
                    borderWidth: 0.8,
                    borderColor: COLORS.APP_MAIN,
                    borderStyle,
                    borderRadius: 10,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE }}>{lableDropZone}</Text>
                  {_.isNil(fileUpload) ? null : (
                    <XIcon
                      name='checkcircle'
                      family='AntDesign'
                      style={{ flex: 0, minWidth: 25, width: 40, color: COLORS.GREEN, fontSize: STYLES.FONT_ICON_LARGER }}
                    />
                  )}
                </View>
              </div>
            </TouchableOpacity>
          )}
        </Dropzone>
      </View>
    )
  }

  _onPressUpload = async () => {
    // const { importProfiles } = this.props
    const { fileUpload } = this.state
    await xUtil.setStatePromise(this, {
      isModelUploading: true,
      isUpload: true,
      isError: false,
      errLable: '',
    })

    const formData = new FormData()

    // Array.from(this.state.fileUpload).forEach(image => {
    // // @ts-ignore
    //   formData.append('file', image)
    // })
    // @ts-ignore
    formData.append('file', fileUpload)
    formData.append('org_id', '1')
    // console.log(`formData`, formData)
    const token = await p.op.storageGet(CONS.AUTHENTICATION_STORAGE_KEY, false)
    // console.log(`TOKEN => `, `Bearer ${token}`)
    // return
    const path = `wrapper/import/org_members_create`
    const url = getConfig()
    axios
      .post(`${url.app_goApiRoot}/${path}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
          // 'Access-Control-Allow-Origin': '*',
          // 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
        },
      })
      .then(async (res) => {
        // if (res.status === 500) {
        //   p.op.showConfirmationOkOnly(`ไม่สามารถติดต่อกับเซิฟเวอร์ได้`, 'กรุณาติดต่อทีมงาน')
        //   await xUtil.setStatePromise(this, {
        //     isModelUploading: false,
        //     isUpload: false,
        //     fileUpload: null,
        //   })
        //   return
        // } else if (res.status > 400) {
        //   p.op.showConfirmationOkOnly(`เกิดข้อผิดพลาด`, `กรุณาติดต่อทีมงาน (${res.status})`)
        //   await xUtil.setStatePromise(this, {
        //     isModelUploading: false,
        //     isUpload: false,
        //     fileUpload: null,
        //   })
        //   return
        // }
        // await xUtil.setStatePromise(this, {
        //   isModelUploading: false,
        //   isUpload: false,
        //   fileUpload: null,
        // })
        if (res.data.status === 'ok') {
          p.op.showConfirmationOkOnly(``, `นำเข้าตัวแทนเรียบร้อยแล้ว`)
          xUtil.setStatePromise(this, {
            isModelUploading: false,
            isUpload: false,
            fileUpload: null,
            isError: false,
            errLable: '',
          })
        } else {
          // const toastMessages = { errorMsg: `เกิดข้อผิดพลาด ${res.status}` }
          // FIXME: ยังไม่ได้ review code แต่เท่าที่อ่านดูแล้ว err ควรจะถูก handle ใน catcg ได้ถูกต้องแล้ว
          // await handleResponse(path, res)
        }
      })
      .catch(async (err) => {
        const errorData = !_.isNil(err.error) ? err.error : null
        if (!_.isNil(errorData)) {
          let errLable = ''
          let isError = true
          if (!_.isNil(err.failed_at_row) && err.failed_at_row > 0) {
            errLable = `ผิดแถวที่ ${err.failed_at_row}`
          }
          if (!_.isNil(err.num_success_records) && err.num_success_records > 0) {
            errLable += ` (สำเร็จไปแล้ว ${err.num_success_records} แถว)`
          }
          if (!_.isNil(errorData.message)) {
            errLable += `\nผิดที่ ${errorData.message}"`
          }
          if (errLable === '') {
            isError = false
          }
          xUtil.setStatePromise(this, {
            isError,
            errLable,
            isModelUploading: false,
            isUpload: false,
            fileUpload: null,
          })
        } else {
          xUtil.setStatePromise(this, {
            isError: false,
            errLable: '',
            isModelUploading: false,
            isUpload: false,
            fileUpload: null,
          })
        }
      })

    //   const res = await new Promise(resolve => {
    //     const body : { [key: string]: any } = {
    //       org_id: 1,
    //       file: fileUpload,
    //     }
    //     console.log(`BODY _onPressUpload `, body)
    //     importProfiles({
    //       body,
    //       successCallback: resolve,
    //       failedCallback: resolve,
    //     })
    //  })
    //  console.log(`RESSSSS => `, res)
  }

  _renderBtnUpload = () => {
    const { fileUpload } = this.state
    const btnBGColor = _.isNil(fileUpload) ? COLORS.BG_LIGHT_GREY : COLORS.APP_MAIN
    const btnBorderColor = _.isNil(fileUpload) ? COLORS.BG_LIGHT_GREY : COLORS.APP_MAIN
    const textBtnColor = _.isNil(fileUpload) ? COLORS.TEXT_INACTIVE : COLORS.WHITE
    return (
      <View style={{ width: '100%', height: 100, justifyContent: 'center', alignItems: 'center', backgroundColor: COLORS.WHITE }}>
        <TouchableOpacity
          onPress={async () => await this._onPressUpload()}
          disabled={_.isNil(fileUpload)}
          style={{
            width: 90,
            flexDirection: 'row',
            height: 30,
            borderColor: btnBorderColor,
            backgroundColor: btnBGColor,
            borderRadius: 7,
            borderWidth: 0.8,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <XIcon
            name='upload'
            family='AntDesign'
            style={{ flex: 0, minWidth: 25, width: 25, color: textBtnColor, fontSize: STYLES.FONT_ICON_SMALLER }}
          />
          <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: textBtnColor, paddingLeft: 4 }}>อัพโหลด</Text>
        </TouchableOpacity>
        <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE, paddingLeft: 4, paddingRight: 4, paddingTop: 6 }}>
          (ระบบอาจใช้เวลาประมาณ 40 วินาที ต่อ 1 บัญชี ดังนั้นเมื่อกดปุ่มอัพโหลด กรุณาเปิดหน้าต่างนี้ทิ้งไว้จนกว่าจะเสร็จ)
        </Text>
      </View>
    )
  }

  _closeModalUploading = async () => {
    if (this.state.isUpload) {
      return
    }
    await xUtil.setStatePromise(this, {
      isModelUploading: false,
    })
  }

  _openModelUploading = () => {
    const customStyles = {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: 340,
      height: 150,
    }
    return (
      <Modal
        isOpen={this.state.isModelUploading}
        // onAfterOpen={this.afterOpenModal}
        onRequestClose={this._closeModalUploading}
        style={{ content: customStyles }}
        contentLabel='Example Modal'>
        <View style={{ width: '100%', justifyContent: 'center', alignItems: 'center', height: 150 }}>
          <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE }}>กำลังอัพโหลด... กรุณาอย่าปิดหน้าต่างนี้</Text>
          <Text style={{ fontSize: STYLES.FONT_SIZE_SMALLER, color: COLORS.TEXT_ACTIVE }}>
            (ระบบอาจใช้เวลาประมาณ 40 วินาที ต่อ 1 บัญชี)
          </Text>
          <Button
            // title="Loading button"
            loading
            disabled
            type='clear'
          />
        </View>
      </Modal>
    )
  }

  _renderErrorView = () => {
    const { isError, errLable } = this.state
    if (!isError) {
      return null
    }
    return (
      <View style={{ width: '100%', height: 80, justifyContent: 'center', alignItems: 'center', backgroundColor: COLORS.WHITE }}>
        <View
          style={{
            width: '95%',
            height: 75,
            padding: 8,
            backgroundColor: COLORS.RED_SOFT,
            borderColor: COLORS.BRAND_Danger,
            borderWidth: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE }}>{errLable}</Text>
        </View>
      </View>
    )
  }

  render() {
    // @ts-ignore: FIXME: อย่าลืมประกาศ types
    const { navigation } = this.props

    return (
      <XContainer style={this.context.contentOneOfThreeColumnWidthStyle}>
        <XCustomHeader title='นำเข้าตัวแทน' />
        <XContent contentContainerStyle={this.context.contentOneOfThreeColumnWidthStyle} style={{ backgroundColor: 'white' }}>
          {this._openModelUploading()}
          {this._renderDownloadTemplateView()}
          {this._renderDropzone()}
          {this._renderBtnUpload()}
          {this._renderErrorView()}
        </XContent>
      </XContainer>
    )
  }
}
