import React from 'react'
// import keydown, { ALL_KEYS } from 'react-keydown'
import _ from 'lodash'
import { setStatePromise } from 'x/utils/util'
// import { log } from 'x/utils/util'
// import { isXSellyOrderId } from 'x/utils/util'

// const HUMAN_THRESHOLD_TIME_MS = 700
const REGEX_SINGLE_CHAR_BARCODE_ALLOW = /^[a-zA-Z0-9._\- ]$/
const SHIFT = 'Shift'
const ENTER = 'Enter'

// const INITIAL_STATE = {}
// const BarcodeListener = React.createContext(INITIAL_STATE)
// // เอาไว้ provide ให้ children components
// export default BarcodeListener

interface BarcodeListenerProps {
  // latestSequenceKeys: string
  // latestSequenceStamp: string
  isDisabled?: boolean
  onScan: (data: string) => void | Promise<void>
  onError?: (error: Error) => void | Promise<void>
  minLength?: number // default = 3
  thresholdHumanTimeMs?: number // เวลาที่มนุษย์ไม่น่าจะสามารถพิมพ์ได้ จำนวน minLength ตัวอักษร ต่อ thresholdHumanTimeMs
  clearDataAfterMs?: number // default = 0 (0 is mean do not clear)
}

interface BarcodeListenerState {
  currentData: string
}

export default class BarcodeListener extends React.PureComponent<BarcodeListenerProps, BarcodeListenerState> {
  txtKeyDowns: string

  lastKeyIsAllowed: boolean

  lastestKeyDownTime: number

  timer: number // number of timeout promise

  static defaultProps = {
    minLength: 3,
    thresholdHumanTimeMs: 700,
    // clearDataAfterMs: 2000,
    clearDataAfterMs: 0,
    isDisabled: false,
  }

  constructor(props) {
    super(props)
    this.state = {
      currentData: '',
    }
    this.txtKeyDowns = ''
    this.lastKeyIsAllowed = false
    this.lastestKeyDownTime = new Date().getTime()
    this.timer = undefined
  }

  componentDidMount() {
    document.addEventListener('keydown', this._onReceiveKeyDown, false)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this._onReceiveKeyDown, false)
  }

  // _onKeyDown = evt => {
  //   console.log('_onKeyDown evt => ', evt)
  // }

  // @ts-ignore
  // @keydown(ALL_KEYS) async _onReceiveKeyDown(keyEvt: any): Promise<void> {
  _onReceiveKeyDown = async (keyEvt: any): Promise<void> => {
    const { onScan, onError, minLength, thresholdHumanTimeMs, isDisabled } = this.props
    if (isDisabled) {
      return
    }
    try {
      const currentTimeKeyDown: number = new Date().getTime()
      const diffTime: number = currentTimeKeyDown - this.lastestKeyDownTime
      // console.log('handleKeys keyEvt => ', keyEvt)

      // if (diffTime > HUMAN_THRESHOLD_TIME_MS) {
      if (diffTime > thresholdHumanTimeMs) {
        this.txtKeyDowns = ''
        this.lastKeyIsAllowed = false
        this.lastestKeyDownTime = currentTimeKeyDown
      }

      const keyInput = keyEvt && keyEvt.key ? keyEvt.key : ''
      if (keyInput === SHIFT) {
        // MUST NOT USE keyEvt.shiftKey as it'll skip the next character
        // if (keyInput === SHIFT || keyEvt.shiftKey) {
        // Do nothing to ignore shift key
        // console.log('Found2 shift: ' + keyInput + ' - ' + keyEvt)
        this.lastKeyIsAllowed = true
      } else if (REGEX_SINGLE_CHAR_BARCODE_ALLOW.test(keyInput)) {
        this.txtKeyDowns += keyInput
        this.lastKeyIsAllowed = true
      } else if ((keyInput === ENTER || keyEvt.returnValue) && this.txtKeyDowns.length >= minLength) {
        // const isOrderId = isXSellyOrderId(this.txtKeyDowns)
        // console.log('isOrderId => ', isOrderId)
        this._clearTimer()
        // this.props.onScan({ sequenceKeys: this.txtKeyDowns, isOrderId })
        if (_.isFunction(onScan) && !isDisabled) {
          await setStatePromise(this, { currentData: this.txtKeyDowns })
          this.props.onScan(this.txtKeyDowns)
          this._clearDataAfterMs()
        }
        // console.log('_onReceiveKeyDown this.txtKeyDowns: ', this.txtKeyDowns)
        return
      }

      if (!this.lastKeyIsAllowed) {
        this.lastestKeyDownTime = currentTimeKeyDown
      }
      // console.log('keyEvt => ', keyEvt)
      // // console.log('this.txtKeyDowns => ', this.txtKeyDowns)
      // // console.log('diffTime => ', diffTime)
      this._clearTimer()

      // @ts-ignore should be number for sure
      // this.timer = setTimeout(this._handleCheckLastKeyTimer, HUMAN_THRESHOLD_TIME_MS)
      this.timer = setTimeout(this._handleCheckLastKeyTimer, thresholdHumanTimeMs)
      // console.log('keyEvt this.timer => ', this.timer)
    } catch (err) {
      // console.log('BarcodeListener:: handleKeys ERROR => ', err)
      if (_.isFunction(onError)) {
        onError(err as Error)
      }
    }
  }

  _clearTimer = () => {
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = null
    }
  }

  _clearDataAfterMs = () => {
    const { clearDataAfterMs } = this.props
    if (clearDataAfterMs === 0) {
      return
    }
    setTimeout(() => {
      if (this.timer) {
        return
      }
      this.setState({ currentData: '' })
    }, clearDataAfterMs)
  }

  _handleCheckLastKeyTimer = async () => {
    if (!this.timer) {
      return
    }
    this._clearTimer()
    const { onScan, minLength, isDisabled } = this.props
    // console.log('_handleCheckLastKeyTimer this.timer: ', this.timer)
    // console.log('_handleCheckLastKeyTimer this.txtKeyDowns: ', this.txtKeyDowns)
    if (_.isFunction(onScan) && this.txtKeyDowns.length > minLength && !isDisabled) {
      await setStatePromise(this, { currentData: this.txtKeyDowns })
      onScan(this.txtKeyDowns)
      this._clearDataAfterMs()
    }
  }

  // // for ref usage
  getCurrentData = () => {
    return this.state.currentData
  }

  // _randomKeyName = () => {
  //   return Math.floor(Math.random() * 10000).toString()
  // }

  render() {
    return null
    // const { currentData } = this.state
    // if (this.props.children) {
    //   return <div key={currentData}>{this.props.children}</div>
    // }
    // return (
    //   <div key={currentData} style={{ display: 'none' }}>
    //     {currentData}
    //   </div>
    // )
  }
}
