import React from 'react'
import BaseProfileView from 'x/modules/profile/BaseProfileView'
import { Image, View, TouchableOpacity, Linking } from 'react-native'

import { COLORS, STYLES } from 'x/config/styles'
import _ from 'lodash'
import CONS from 'x/config/constants'
import XCustomHeader from 'xui/components/XCustomHeader'
import { IFormProfileInputs, INavHeaderButtonProps, ISocialProfile } from 'x/index'
import XInput from 'xui/components/XInput'
import * as util from 'x/utils/util'
import XIcon from 'xui/components/XIcon'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import p from 'x/config/platform-specific'
import settings from 'x/config/settings'
import XCard from 'xui/components/XCard'
import { HStack, VStack } from 'native-base'
import ImgManager from 'xui/components/ImgManager'
import { getConfig } from 'x/config/mode'
import XText from 'xui/components/XText'
import XRadio from 'xui/components/XRadio'
import Box from 'xui/components/Box'

const config = getConfig()

const { VIEW, EDIT } = CONS.MODE
const inputType = 'input'
const radioType = 'radio'

const formInputs = [
  {
    key: 'codeId',
    txtLabel: 'รหัสตัวแทน',
    inputType,
    validationFunction: null,
    canEdit: false,
  },
  {
    key: 'nickName',
    txtLabel: 'ชื่อเล่น',
    inputType,
    txtPlaceholder: 'ระบุชื่อเล่น',
    keyboardType: 'default',
    inputMaxLength: 50,
    validationFunction: null,
    canEdit: true,
  },
  {
    key: 'firstName',
    txtLabel: 'ชื่อ',
    inputType,
    txtPlaceholder: 'ระบุชื่อ',
    keyboardType: 'default',
    inputMaxLength: 50,
    validationFunction: null,
    canEdit: true,
  },
  {
    key: 'lastName',
    txtLabel: 'นามสกุล',
    inputType,
    txtPlaceholder: 'ระบุนามสกุล',
    keyboardType: 'default',
    inputMaxLength: 50,
    validationFunction: null,
    canEdit: true,
  },
  {
    key: 'gender',
    txtLabel: 'เพศ',
    inputType: radioType,
    validationFunction: null,
    options: [
      { label: 'ชาย', value: 'male' },
      { label: 'หญิง', value: 'female' },
      { label: 'ไม่ระบุ', value: 'n/a' },
    ],
  },
  {
    key: 'phone',
    txtLabel: 'เบอร์โทรศัพท์',
    inputType,
    txtPlaceholder: 'ระบุเบอร์โทรศัพท์',
    keyboardType: 'phone-pad',
    inputMaxLength: 50,
    validationFunction: null,
    canEdit: true,
  },
  {
    key: 'phone2',
    txtLabel: 'เบอร์โทรศัพท์สำรอง',
    inputType,
    txtPlaceholder: 'ระบุเบอร์โทรศัพท์สำรอง',
    keyboardType: 'phone-pad',
    inputMaxLength: 50,
    validationFunction: null,
    canEdit: true,
  },
  {
    key: 'email',
    txtLabel: 'อีเมล',
    inputType,
    txtPlaceholder: 'ระบุนอีเมล',
    keyboardType: 'default',
    inputMaxLength: 50,
    validationFunction: null,
    canEdit: true,
  },
]

const socialProfile = [
  {
    key: 'facebook',
    txtLabel: `Facebook ID`,
    txtPlaceholder: 'ระบุ Facebook id',
    txtBtnTest: 'ทดสอบ\nความถูกต้อง',
    icon: (
      <Image
        resizeMode='cover'
        style={{
          width: STYLES.FONT_ICON_NORMAL,
          height: STYLES.FONT_ICON_NORMAL,
          marginRight: 4,
        }}
        source={require('../../img/about/Messenger_Icon.png')}
      />
    ),
    btnHelpFunction: () => Linking.openURL(settings.XSELLY_URLS.HOW_TO_FIND_FACEBOOK_ID),
    btnTestFunction: (facebookId: string) => Linking.openURL(`http://m.me/${facebookId}`),
    validationFunction: null,
  },
  {
    key: 'line',
    txtLabel: `LINE ID`,
    txtPlaceholder: 'ระบุ Line id',
    txtBtnTest: 'ทดสอบ\nความถูกต้อง',
    icon: (
      <Image
        resizeMode='cover'
        style={{
          width: STYLES.FONT_ICON_NORMAL,
          height: STYLES.FONT_ICON_NORMAL,
          marginRight: 4,
        }}
        source={require('../../img/about/LINE_Icon.png')}
      />
    ),
    btnHelpFunction: () => Linking.openURL(settings.XSELLY_URLS.HOW_TO_FIND_LINE_ID),
    btnTestFunction: (lineId: string) => Linking.openURL(util.getLineUrlByLineId(lineId)),
    validationFunction: null,
  },
  {
    key: 'instagram',
    txtLabel: `Instagram ID`,
    txtPlaceholder: 'ระบุ Instagram id',
    txtBtnTest: 'ทดสอบ\nความถูกต้อง',
    icon: (
      <Image
        resizeMode='cover'
        style={{
          width: STYLES.FONT_ICON_NORMAL,
          height: STYLES.FONT_ICON_NORMAL,
          marginRight: 4,
        }}
        source={require('../../img/about/instagram-logo.png')}
      />
    ),
    btnHelpFunction: () => {},
    btnTestFunction: (id: string) => Linking.openURL(util.getInstagramUrlByInstagramId(id)),
    validationFunction: null,
  },
]

export default abstract class BaseUIProfileView extends BaseProfileView {
  static displayName = 'BaseUIPaymentListView'

  // abstract goBack: () => void

  // abstract renderViewImgManager(): JSX.Element

  goBack = () => {
    util.navGoBack(this.props)
  }

  renderCustomHeader = () => {
    // const { navigation } = this.props
    const { mode } = this.state
    const isEditMode = mode === EDIT
    let leftBtn: INavHeaderButtonProps = { backIcon: true, onPressItem: () => this.goBack() }
    let rightBtn: INavHeaderButtonProps = {
      label: 'แก้ไข',
      onPressItem: async () => {
        await util.setStatePromise(this, { mode: EDIT })
      },
    }

    if (isEditMode) {
      leftBtn = {
        label: 'บันทึก',
        onPressItem: () => {
          this._submitData()
        },
      }
      rightBtn = {
        label: 'ยกเลิก',
        onPressItem: async () => {
          await this._cancelEdit()
        },
      }
    }
    return <XCustomHeader title='ข้อมูลส่วนตัว' headerLeftProps={leftBtn} headerRightProps={rightBtn} />
  }

  _cancelEdit = async () => {
    p.op.showConfirmation(
      '',
      'ยกเลิกการแก้ไข',
      async () => {
        await util.setStatePromise(this, { mode: VIEW, profile: this.props.profile.toJS() })
      },
      () => null,
      'ตกลง',
      'แก้ไขต่อ'
    )
  }

  renderViewImgManager = () => {
    const { profile, mode, isUploading } = this.state
    // const thumbnail_uri = profile.thumbnail_uri
    const isEditMode = mode === EDIT
    if (_.isNil(this.imgManagerRef)) {
      return null
    }
    return (
      <HStack w='full' alignItems='center' justifyContent='center'>
        <ImgManager
          ref={this.imgManagerRef}
          fileNamePrefix={config.s3_prefix_type_profile}
          metadata={[
            { key: 'p', width: 384, height: 384 },
            { key: 't', width: 128, height: 128 },
          ]}
          uploading={isUploading}
          maxLength={1}
          disabledRemoveSingleImage
          shape='circle'
          readonly={!isEditMode}
        />
      </HStack>
    )
  }

  _renderRadioView = (data: IFormProfileInputs) => {
    const { mode } = this.state
    const isViewMode = mode === VIEW
    return (
      <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
        {data.options.map((val: { label: string; value: string }, index: number) => {
          const selected = this.state[data.key] === index
          return (
            <TouchableOpacity style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }} disabled={isViewMode}>
              <XIcon family='Ionicons' style={{ flex: 0, minWidth: 25 }} name={selected ? 'radio-button-on' : 'radio-button-off'} />
              <XText style={{ color: COLORS.TEXT_ACTIVE, textAlign: 'center' }}>{val.label}</XText>
            </TouchableOpacity>
          )
        })}
      </View>
    )
  }

  _renderInputType = (data: IFormProfileInputs) => {
    const { mode } = this.state
    const isViewMode = mode === VIEW
    if (data.inputType === inputType) {
      if (isViewMode || !data.canEdit) {
        return <XText style={{ color: COLORS.TEXT_ACTIVE }}>{this.state[data.key]}</XText>
      }
      return (
        <XInput
          flex={1}
          value={this.state[data.key]}
          placeholder={data.txtPlaceholder}
          maxLength={data.inputMaxLength}
          keyboardType={data.keyboardType}
          onChangeText={(text: string) => {
            const newText = _.isNil(data.validationFunction) ? text : data.validationFunction(text)
            util.setStatePromise(this, { [data.key]: newText })
          }}
        />
      )
    }
    if (data.inputType === radioType) {
      return this._renderRadioView(data)
    }
    return null
  }

  _renderInputView = (data: IFormProfileInputs) => (
    // const { mode } = this.state
    // const isViewMode = mode === VIEW
    <HStack w='full' space='1'>
      <XText variant='inactive'>{data.txtLabel}</XText>
      {this._renderInputType(data)}
    </HStack>
  )

  _renderInputs = () => (
    <View style={{ width: '100%', backgroundColor: COLORS.WHITE }}>
      {
        // test
        // @ts-ignore
        formInputs.map((input: IFormProfileInputs) => this._renderInputView(input))
      }
    </View>
  )

  _renderJoinAtView = () => {
    const profile = util.getNavParam(this.props, 'profile')
    let joined = _.isNil(profile.j) ? null : profile.j
    if (_.isNil(joined)) {
      return null
    }
    joined = `เข้ารวมเมื่อ ${joined}`
    return (
      <View
        style={{
          width: '100%',
          backgroundColor: COLORS.WHITE,
          alignItems: 'flex-end',
          justifyContent: 'center',
          height: 20,
          paddingRight: 4,
        }}>
        <XText style={{ fontSize: STYLES.FONT_SIZE_SMALLER, color: COLORS.TEXT_INACTIVE }}>{joined}</XText>
      </View>
    )
  }

  _renderSocialContact = () => {
    const { mode } = this.state
    const isViewMode = mode === VIEW
    return (
      <View style={{ width: '100%', backgroundColor: COLORS.WHITE }}>
        {socialProfile.map((social: ISocialProfile) => {
          const socialName = _.isNil(this.state[social.key]) || this.state[social.key] === '' ? 'ไม่ได้ระบุ' : this.state[social.key]
          return (
            <View
              style={{
                flex: 1,
                flexBasis: 'auto',
                flexDirection: 'row',
                alignItems: 'center',
                height: 40,
                paddingLeft: 4,
                paddingRight: 4,
              }}>
              <View style={{ width: 30 }}>{social.icon}</View>
              <View style={{ width: 70 }}>
                <XText style={{ color: COLORS.TEXT_INACTIVE }}>{social.txtLabel}</XText>
              </View>
              <View style={{ flex: 1, paddingRight: 4 }}>
                {isViewMode ? (
                  <XText style={{ color: COLORS.TEXT_ACTIVE }}>{socialName}</XText>
                ) : (
                  <XInput
                    value={this.state[social.key]}
                    placeholder={social.txtPlaceholder}
                    maxLength={50}
                    onChangeText={(text: string) => {
                      const newText = _.isNil(social.validationFunction) ? text : social.validationFunction(text)
                      util.setStatePromise(this, { [social.key]: newText })
                    }}
                  />
                )}
              </View>
              {isViewMode ? (
                <TouchableOpacity
                  onPress={() => {
                    if (!_.isNil(this.state[social.key]) && this.state[social.key] !== '') {
                      social.btnTestFunction(this.state[social.key])
                    }
                  }}
                  style={{
                    width: 80,
                    backgroundColor: COLORS.BG_LIGHT_GREY,
                    borderWidth: 0.8,
                    borderColor: COLORS.APP_MAIN,
                    borderRadius: 7,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <XText style={{ color: COLORS.TEXT_ACTIVE, textAlign: 'center' }}>{social.txtBtnTest}</XText>
                </TouchableOpacity>
              ) : (
                <TouchableOpacity
                  onPress={() => social.btnHelpFunction()}
                  style={{
                    width: 80,
                    backgroundColor: COLORS.BG_LIGHT_GREY,
                    borderWidth: 0.8,
                    borderColor: COLORS.APP_MAIN,
                    borderRadius: 7,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <XText
                    style={{
                      color: COLORS.TEXT_ACTIVE,
                      textAlign: 'center',
                    }}>{`ดูวิธีการ\nหาไอดี`}</XText>
                </TouchableOpacity>
              )}
            </View>
          )
        })}
      </View>
    )
  }

  _renderDealerDataView = () => (
    <View style={{ flex: 1, paddingTop: 4, paddingBottom: 4, backgroundColor: COLORS.WHITE, height: 40 }}>
      <TouchableOpacity
        style={{
          flex: 1,
          flexBasis: 'auto',
          justifyContent: 'center',
          borderWidth: 0.8,
          borderColor: COLORS.APP_MAIN,
          borderRadius: 7,
          backgroundColor: COLORS.BG_LIGHT_GREY,
          marginLeft: 4,
          marginRight: 4,
        }}>
        <XText style={{ color: COLORS.TEXT_ACTIVE, paddingLeft: 4 }}>{`แม่ทีม : `}</XText>
        <XText style={{ color: COLORS.TEXT_ACTIVE, paddingLeft: 4 }}>{`รหัสตัวแทนแม่ทีม : `}</XText>
      </TouchableOpacity>
    </View>
  )

  _renderAddressesBtn = () => {
    const { navigation } = this.props
    return (
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          paddingTop: 4,
          paddingBottom: 4,
          backgroundColor: COLORS.WHITE,
          justifyContent: 'flex-start',
          alignItems: 'center',
          height: 40,
        }}>
        <XText style={{ color: COLORS.TEXT_INACTIVE, paddingLeft: 4 }}>ที่อยู่</XText>
        <View style={{ width: 10 }} />
        <TouchableOpacity
          onPress={() => {
            navigation.push('ResellerApprovalListView', {
              isModal: true,
              // x: Math.random(),
            })
          }}
          style={{
            width: 60,
            justifyContent: 'center',
            alignItems: 'center',
            borderWidth: 0.8,
            borderColor: COLORS.APP_MAIN,
            borderRadius: 7,
            backgroundColor: COLORS.BG_LIGHT_GREY,
            // marginLeft: 4,
            // marginRight: 4,
            height: 30,
          }}>
          <XText style={{ color: COLORS.TEXT_ACTIVE }}>ดูที่อยู่</XText>
        </TouchableOpacity>
      </View>
    )
  }

  _renderFirstName = () => {
    const { profile, mode } = this.state
    if (_.isNil(profile)) {
      return null
    }
    return (
      <HStack w='full' space='2' alignItems='center'>
        <XText w='124px' variant='inactive' textAlign='right'>
          ชื่อ
        </XText>

        {mode === 'EDIT' ? (
          <XInput
            flex={1}
            value={profile.first_name}
            onChangeText={(text: string) => this._onChangeNameText(text)}
            placeholder='กรอกชื่อของคุณ'
            // placeholderTextColor={COLORS.TEXT_INACTIVE}
            // multiline={false}
          />
        ) : (
          <XText variant='active'>{profile.first_name}</XText>
        )}
      </HStack>
    )
  }

  _renderLastName = () => {
    const { profile, mode } = this.state
    if (_.isNil(profile)) {
      return null
    }
    return (
      <HStack w='full' space='2' alignItems='center'>
        <XText w='124px' variant='inactive' textAlign='right'>
          นามสกุล
        </XText>

        {mode === 'EDIT' ? (
          <XInput
            flex={1}
            value={profile.last_name}
            onChangeText={(text: string) => this._onChangeLastNameText(text)}
            placeholder='กรอกนามสกุลของคุณ'
          />
        ) : (
          <XText variant='active'>{profile.last_name}</XText>
        )}
      </HStack>
    )
  }

  _renderGender = () => {
    const { profile, mode } = this.state
    if (_.isNil(profile)) {
      return null
    }
    // "male"|"famale"
    const RADIO_GENDER = (label: 'ชาย' | 'หญิง' | 'ไม่ระบุ', value: boolean) => (
      <TouchableOpacity
        onPress={() => this._onChangeGander(label)}
        style={{
          flexDirection: 'row',
        }}>
        <XRadio checked={value} />
        <XText>{label}</XText>
      </TouchableOpacity>
    )
    const genderLabel = _.isNil(profile.gender) || profile.gender === 'n/a' ? 'ไม่ระบุ' : profile.gender === 'male' ? 'ชาย' : 'หญิง'
    return (
      <HStack w='full' space='2' alignItems='center'>
        <XText w='124px' variant='inactive' textAlign='right'>
          เพศ
        </XText>

        {mode === 'EDIT' ? (
          <HStack space='6'>
            {RADIO_GENDER('ชาย', profile.gender && profile.gender === 'male')}
            {RADIO_GENDER('หญิง', profile.gender && profile.gender === 'famale')}
            {RADIO_GENDER('ไม่ระบุ', _.isNil(profile.gender) || profile.gender === 'n/a')}
          </HStack>
        ) : (
          <XText variant='active'>{genderLabel}</XText>
        )}
      </HStack>
    )
  }

  _renderPhoneNumber = () => {
    const { profile, mode } = this.state
    if (_.isNil(profile)) {
      return null
    }
    const phonelabel = profile.phone1 ? profile.phone1 : 'ยังไม่ได้ระบุ'
    return (
      <HStack w='full' space='2' alignItems='center'>
        <XText w='124px' variant='inactive' textAlign='right'>
          เบอร์โทรศัพท์
        </XText>

        {mode === 'EDIT' ? (
          <XInput
            flex={1}
            value={profile.phone1}
            onChangeText={(text: string) => this._onChangePhoneNumberText(text)}
            placeholder='กรอกเบอร์โทรศัพท์ของคุณ'
          />
        ) : (
          <XText variant='active'>{phonelabel}</XText>
        )}
      </HStack>
    )
  }

  _renderEmail = () => {
    const { profile, mode } = this.state
    if (_.isNil(profile)) {
      return null
    }
    return (
      <HStack w='full' space='2' alignItems='center'>
        <XText w='124px' variant='inactive' textAlign='right'>
          อีเมลติดต่อ
        </XText>

        {mode === 'EDIT' ? (
          <XInput
            flex={1}
            value={profile.contact_email}
            onChangeText={(text: string) => this._onChangeEmailText(text)}
            placeholder='กรอกอีเมลของคุณ'
          />
        ) : (
          <XText variant='active'>{profile.contact_email}</XText>
        )}
      </HStack>
    )
  }

  _renderFacebook = () => {
    const { profile, mode } = this.state
    if (_.isNil(profile)) {
      return null
    }
    const facebook = profile.fb_id ? profile.fb_id : 'ยังไม่ระบุ'
    const facebookId = profile.fb_id
    return (
      <HStack w='full' space='2' alignItems='center'>
        <HStack w='124px' justifyContent='flex-end'>
          <Image
            resizeMode='cover'
            style={{
              width: STYLES.FONT_ICON_NORMAL,
              height: STYLES.FONT_ICON_NORMAL,
              marginRight: 4,
            }}
            source={require('../../img/about/Messenger_Icon.png')}
          />
          <XText variant='inactive'>Facebook ID</XText>
        </HStack>
        {mode === 'EDIT' ? (
          <XInput
            flex={1}
            value={profile.fb_id}
            onChangeText={(text: string) => this._onChangeFacebookText(text)}
            placeholder='ระบุเพื่อให้คู่ค้าติดต่อฉันได้'
          />
        ) : (
          <XText flex={1} variant='active'>
            {facebook}
          </XText>
        )}
        {profile.fb_id && mode === 'VIEW' ? (
          <TouchableOpacity
            onPress={() => Linking.openURL(`http://m.me/${facebookId}`)}
            style={{
              // marginLeft: 8,
              borderRadius: 7,
              borderTopWidth: 1,
              borderTopColor: COLORS.APP_MAIN,
              borderBottomColor: COLORS.APP_MAIN,
              borderBottomWidth: 1,
              borderLeftColor: COLORS.APP_MAIN,
              borderLeftWidth: 1,
              borderRightColor: COLORS.APP_MAIN,
              borderRightWidth: 1,
              backgroundColor: COLORS.BG_LIGHT_GREY,
              alignItems: 'center',
              justifyContent: 'center',
              paddingLeft: 4,
              paddingRight: 4,
              paddingBottom: 2,
            }}>
            <XText color='black' fontSize='sm' textAlign='center'>
              {'ทดสอบ\nความถูกต้อง'}
            </XText>
          </TouchableOpacity>
        ) : null}
      </HStack>
    )
  }

  _renderLine = () => {
    const { profile, mode } = this.state
    if (_.isNil(profile)) {
      return null
    }
    const line = profile.line_id ? profile.line_id : 'ยังไม่ระบุ'
    const lineId = profile.line_id
    return (
      <HStack w='full' space='2' alignItems='center'>
        <HStack w='124px' justifyContent='flex-end'>
          <Image
            resizeMode='cover'
            style={{
              width: STYLES.FONT_ICON_NORMAL,
              height: STYLES.FONT_ICON_NORMAL,
              marginRight: 4,
            }}
            source={require('../../img/about/LINE_Icon.png')}
          />
          <XText variant='inactive'>LINE ID</XText>
        </HStack>

        {mode === 'EDIT' ? (
          <XInput
            flex={1}
            value={profile.line_id}
            onChangeText={(text: string) => this._onChangeLineText(text)}
            placeholder='ระบุเพื่อให้คู่ค้าติดต่อฉันได้'
          />
        ) : (
          <XText flex={1} variant='active'>
            {line}
          </XText>
        )}
        {profile.line_id && mode === 'VIEW' ? (
          <TouchableOpacity
            onPress={() => Linking.openURL(util.getLineUrlByLineId(lineId))}
            style={{
              // marginLeft: 8,
              borderRadius: 7,
              borderTopWidth: 1,
              borderTopColor: COLORS.APP_MAIN,
              borderBottomColor: COLORS.APP_MAIN,
              borderBottomWidth: 1,
              borderLeftColor: COLORS.APP_MAIN,
              borderLeftWidth: 1,
              borderRightColor: COLORS.APP_MAIN,
              borderRightWidth: 1,
              backgroundColor: COLORS.BG_LIGHT_GREY,
              alignItems: 'center',
              justifyContent: 'center',
              paddingLeft: 4,
              paddingRight: 4,
              paddingBottom: 2,
            }}>
            <XText color='black' fontSize='sm' textAlign='center'>
              {'ทดสอบ\nความถูกต้อง'}
            </XText>
          </TouchableOpacity>
        ) : null}
      </HStack>
    )
  }

  _renderMain() {
    return (
      <XContainer>
        {this.renderCustomHeader()}
        <XContent>
          <VStack w='full' p='2'>
            <XCard>
              <XCard.Body>
                <VStack w='full' pt='2' space='2'>
                  {this.renderViewImgManager()}

                  <Box />

                  {this._renderFirstName()}
                  {this._renderLastName()}
                  {this._renderGender()}
                  {this._renderPhoneNumber()}
                  {this._renderEmail()}

                  <Box />

                  {this._renderFacebook()}
                  {this._renderLine()}
                </VStack>
                {/* {this._renderJoinAtView()} */}
                {/* {this._renderInputs()} */}
                {/* {this._renderSocialContact()} */}
              </XCard.Body>
            </XCard>

            {/* {this._renderAddressesBtn()} */}

            {/* {this._renderDealerDataView()} */}
          </VStack>
        </XContent>
      </XContainer>
    )
  }

  render() {
    return this._renderMain()
  }
}
