import React, { useState, useEffect } from 'react'
import HStack from 'xui/components/HStack'
import XText from 'xui/components/XText'
import XButton from 'xui/components/XButton'
import { TouchableOpacity } from 'react-native'
import XIcon from 'xui/components/XIcon'
import Box from 'xui/components/Box'
import p from 'x/config/platform-specific'
import dayjs from 'dayjs'
import CONS from 'x/config/constants'
import * as util from 'x/utils/util'
import AutoCons from './config/autoupdateConstants'
// import packageJson from "../package.json";

// const buildDateGreaterThan = (latestDate, currentDate) => {
//   if (latestDate > currentDate) {
//     return true
//   }
//   return false
// }

const LAST_FORCE_REFRESH_TIMESTAMP_KEY = 'LAST_FORCE_REFRESH_TIMESTAMP'

const myHeaders = new Headers()
myHeaders.append('pragma', 'no-cache')
myHeaders.append('cache-control', 'no-cache')

const myInit = {
  method: 'GET',
  headers: myHeaders,
}

const myRequest = new Request('/meta.json')

function withClearCache(Component) {
  function ClearCacheComponent(props) {
    const [isLatestBuildDate, setIsLatestBuildDate] = useState(true)
    const [isChooseToClose, setIsChooseToClose] = useState(false)
    const [isLasyRefreshWithinDay, setIsLasyRefreshWithinDay] = useState(false)

    const checkBuildVersion = async () => {
      const lastForceRefreshString = await p.op.storageGet(LAST_FORCE_REFRESH_TIMESTAMP_KEY, false)

      if (!lastForceRefreshString || !lastForceRefreshString.length) {
        setIsLasyRefreshWithinDay(false)
      } else {
        const lastForceRefresh = dayjs(lastForceRefreshString as string, CONS.SERVER_DATETIME_FORMAT)
        const now = dayjs()
        const diff = now.diff(lastForceRefresh, 'day')
        // console.log('withClearCache checkBuildVersion diff => ', diff)
        if (diff < 1) {
          setIsLasyRefreshWithinDay(true)
        } else {
          setIsLasyRefreshWithinDay(false)
        }
      }

      try {
        fetch(myRequest, myInit)
          .then((response) => response.json())
          .then((meta) => {
            const latestVersionDate = meta.WEB_BUILD_TIMESTAMP
            const currentVersionDate = AutoCons.WEB_BUILD_TIMESTAMP

            console.log('check web version latest Version => ', latestVersionDate)
            console.log('check web version current Version => ', currentVersionDate)
            // const shouldForceRefresh = latestVersionDate !== currentVersionDate
            // // const shouldForceRefresh = buildDateGreaterThan(latestVersionDate, currentVersionDate)
            // // if (shouldForceRefresh) {
            // //   setIsLatestBuildDate(false)
            // //   refreshCacheAndReload()
            // // } else {
            // //   setIsLatestBuildDate(true)
            // // }

            // if (shouldForceRefresh) {
            //   setIsLatestBuildDate(false)
            // }

            const latestVer = util.replaceOutUnknowString(latestVersionDate).trim()
            const currentVer = util.replaceOutUnknowString(currentVersionDate).trim()
            const isVersionMatch = latestVer === currentVer
            if (isVersionMatch) {
              setIsLatestBuildDate(true)
            } else {
              setIsLatestBuildDate(false)
            }
          })
      } catch (error) {
        console.log('withClearCache fetch meta error => ', error)
      }
    }

    // const tick = () => {
    //   // get the mins of the current time
    //   // const mins = new Date().getMinutes()
    //   const mins = new Date().getSeconds()
    //   if (String(mins) === '0') {
    //     // if (String(mins) === '00') {
    //     // alert('Do stuff');
    //     checkBuildVersion()
    //   }
    //   console.log(`Tick ${mins}`)
    // }

    useEffect(() => {
      // setInterval(tick, 1000)
      checkBuildVersion()
      setInterval(checkBuildVersion, 1800000)
    }, [])

    const refreshCacheAndReload = async () => {
      setIsLasyRefreshWithinDay(true)
      await p.op.storageSet(LAST_FORCE_REFRESH_TIMESTAMP_KEY, dayjs().format(CONS.SERVER_DATETIME_FORMAT).toString(), false)

      if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then((names) => {
          for (const name of names) {
            caches.delete(name)
          }
        })
      }
      // delete browser cache and hard reload
      // @ts-ignore
      window.location.reload(true)

      // https://stackoverflow.com/questions/55127650/location-reloadtrue-is-deprecated
      // @ts-ignore
      // window.location.href = window.location.href

      // p.op.showToast('โหลดเวอร์ชั่นใหม่เสร็จสิ้นแล้ว', 'success')
    }

    const chooseToClose = () => {
      setIsChooseToClose(true)
    }

    // @ts-ignore
    return (
      <>
        {/* {isLatestBuildDate ? <Component {...props} shouldRerender /> : null} */}
        <Component {...props} shouldRerender />
        {!isLatestBuildDate && !isChooseToClose && !isLasyRefreshWithinDay && (
          <HStack
            // @ts-ignore
            style={{
              position: 'fixed',
              bottom: 20,
              left: 20,
              height: 50,
              width: 250,
            }}
            py='1.5'
            px='2.5'
            space='1'
            alignItems='center'
            bg='white'
            borderWidth='2'
            borderTopColor='gray.300'
            borderLeftColor='gray.300'
            borderBottomColor='gray.400'
            borderRightColor='gray.400'
            borderRadius='lg'
            // overflow='hidden'
          >
            <HStack flex={1}>
              <XText>มีอัพเดทเวอร์ชั่นใหม่</XText>
            </HStack>

            <HStack w='100px' justifyContent='flex-end'>
              <XButton h='34px' w='90px' variant='outline' onPress={refreshCacheAndReload}>
                <XText>โหลดใหม่</XText>
              </XButton>
            </HStack>

            <Box style={{ position: 'absolute', top: -8, right: -8 }}>
              <TouchableOpacity onPress={chooseToClose}>
                <XIcon variant='inactive' name='close-circle' />
              </TouchableOpacity>
            </Box>
          </HStack>
        )}
      </>
    )
  }

  return ClearCacheComponent
}

export default withClearCache
