import React from 'react'
import { View } from 'react-native'
import { IStackProps } from 'native-base'
import Box from './Box'
import getSpacedChildren from './getSpacedChildren'

// @ts-ignore
const HStack = React.forwardRef<View, IStackProps>((props, ref) => {
  const { children, space, reversed, divider, ...restProps } = props
  return (
    <Box
      // @ts-ignore
      ref={ref}
      // style={[
      //   s.defaultHorizontalBox,
      //   style,
      //   // @ts-ignore
      //   // _.isNil(flex) ? {} : { flex },
      // ]}
      flexDirection='row'
      {...restProps}>
      {
        // @ts-ignore
        getSpacedChildren(children, space, 'X', reversed ? 'reverse' : 'normal', divider)
      }
    </Box>
  )
})

HStack.displayName = 'XHStack'

// const s = StyleSheet.create({
//   defaultHorizontalBox: {
//     // flex: 1,
//     // width: '100%',
//     flexDirection: 'row',
//   },
// })

export default HStack as React.FC<IStackProps>
