import api from 'x/utils/api'

import { IMkpProductRequestBody, CallApiFunction, IMkpProductDetailResponse, IXSellyErrorResponse } from 'x/index'

export const fetchMkpProductDetail: CallApiFunction<IMkpProductRequestBody, IMkpProductDetailResponse> = async ({
  body,
  options = {},
  successCallback,
  failedCallback,
}) => {
  try {
    // let endpoint
    // const { product_template_id, item_uuid } = body
    // if (product_template_id && _.isNumber(product_template_id)) {
    //   endpoint = api.POST_FETCH_MKP_PRODUCT_BY_PT
    // } else if (item_uuid && _.isString(item_uuid)) {
    //   endpoint = api.POST_FETCH_MKP_PRODUCT_BY_ITEM_UUID
    // } else {
    //   throw new Error('product_template_id or item_uuid is required')
    // }

    // const reqBody = {
    //   store_id,
    //   item_uuid,
    //   mkp_ch_id: mkp_channel_id,
    // }

    // console.log('endpoint => ', endpoint)
    const res = await api.post<IMkpProductRequestBody, IMkpProductDetailResponse>('mkp/v2/product', body, { isApiV2: true, ...options })
    // const res = await api.post<IMkpProductRequestBody, IMkpProductDetailResponse>(endpoint, reqBody, { isApiV2: true, ...options })
    // console.log('res => ', res)
    if (successCallback) {
      await successCallback(res)
    }
    return res
  } catch (err) {
    if (failedCallback) {
      await failedCallback(err)
    }
    return err as IXSellyErrorResponse
  }
}
