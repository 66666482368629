import BaseUIOrderPrivateAndPublicNotes from 'xui/modules/order/BaseUIOrderPrivateAndPublicNotes'
// import ThemeSizeContext from '../../context/ThemeSizeContext'
// import XContainer from 'xui/components/XContainer'
// import XContent from 'xui/components/XContent'

export default class OrderPrivateAndPublicNotes extends BaseUIOrderPrivateAndPublicNotes {
  static displayName = 'OrderPrivateAndPublicNotes'

  // static contextType = ThemeSizeContext

  // context!: React.ContextType<typeof ThemeSizeContext>

  // // Override
  // renderMain = () => {
  //   const { contentOneOfThreeColumnWidthStyle, contentHeightStyle } = this.context

  //   // return (
  //   //   <div>
  //   //     <div>{JSON.stringify(contentOneOfThreeColumnWidthStyle)}</div>
  //   //     <div>{JSON.stringify(contentHeightStyle)}</div>
  //   //     <div>yo</div>
  //   //   </div>
  //   // )
  //   return (
  //     <XContainer style={[contentOneOfThreeColumnWidthStyle, contentHeightStyle]}>
  //       {this.renderHeader()}
  //       <XContent style={[contentOneOfThreeColumnWidthStyle, contentHeightStyle]}>{this.renderContent()}</XContent>
  //     </XContainer>
  //   )
  // }

  // getFixedWebLayoutTextLimitWidth = () => {
  //   return this.context.contentOneOfThreeColumnWidth - 120
  // }
}
