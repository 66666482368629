import _ from 'lodash'
import { fromJS } from 'immutable'
import api from 'x/utils/api'
import { take, put, call, fork, all } from 'redux-saga/effects'
import * as PrefernceActions from 'x/modules/preference/PreferenceState'
import actions from '../../config/actions'
import { ActionApiParams } from '../../index'

// import { setCrashlyticsUserId } from './../../utils/analytics'

export default function* xWatchAllPreference() {
  yield all([fork(watchCustomOrderOnChange)])
}

function* watchCustomOrderOnChange() {
  while (true) {
    const action = yield take(actions.ADD_CUSTOM_ORDER)
    yield call(customOrderOnChange, action)
  }
}

function* customOrderOnChange(action: ActionApiParams) {
  const apiOptions = {
    showSpinner: true,
  }
  // console.log('action ActionApiParams', action)
  try {
    const res = yield call(api.postV2, api.POST_ADD_CUSTOM_ORDER, action.body, apiOptions)
    // console.log('In CustomOrderOnChange res: ', res)
    // if (res && res.status === 'ok') {
    //   yield call(p.op.showToast, 'เปลี่ยนอีเมลสำเร็จแล้ว', 'success', 5000)
    //   yield call(fetchUserProfile, {})
    // }
    if (_.isFunction(action.successCallback)) {
      action.successCallback(res)
    }
  } catch (error) {
    // yield put({ type: actions.USER_PROFILE_CHANGE_EMAIL_INVALID })
    if (_.isFunction(action.failedCallback)) {
      action.failedCallback(error)
    }
  }
}

export function* handleCustomOrderFromResponse(res) {
  // const ss = res.ss
  // if (ss && ss.pref_ui_order_create) {
  //   const customCreateOrder = ss.pref_ui_order_create
  //   yield put({ type: PrefernceActions.LOAD_CUSTOM_CREATE_ORDER, payload: fromJS(customCreateOrder) })
  // }
  // if (ss && ss.pref_ui_order_view) {
  //   const customOrderView = ss.pref_ui_order_view
  //   yield put({ type: PrefernceActions.LOAD_CUSTOM_ORDER_VIEW, payload: fromJS(customOrderView) })
  // }

  const isStoreInResponse = res && (_.isObject(res.ss) || _.isObject(res.store))
  if (isStoreInResponse) {
    const store = res.ss || res.store
    const customCreateOrder = store.pref_ui_order_create || {}
    yield put({ type: PrefernceActions.LOAD_CUSTOM_CREATE_ORDER, payload: fromJS(customCreateOrder) })

    const customOrderView = store.pref_ui_order_view || {}
    yield put({ type: PrefernceActions.LOAD_CUSTOM_ORDER_VIEW, payload: fromJS(customOrderView) })
  }
}
