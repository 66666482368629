import React from 'react'
import { Image, View } from 'react-native'

import * as util from 'x/utils/util'
import p from 'x/config/platform-specific'
import { COLORS, STYLES } from 'x/config/styles'
import XCustomHeader from 'xui/components/XCustomHeader'
import NB from 'x/config/nativebase'
import BaseStoreSettingPeakView from 'x/modules/store/BaseStoreSettingPeakView'
import XIcon from 'xui/components/XIcon'
import _ from 'lodash'
import XRadioList from 'xui/components/XRadioList'
import XSwitch from 'xui/components/XSwitch'
import Segment from 'xui/components/Segment'
import XInput from 'xui/components/XInput'
import { VIEW_LOADING } from 'xui/utils/BaseShareUI'
import XHelpModal from 'xui/components/XHelpModal'
import HelpButton from 'xui/components/HelpButton'
import XContent from 'xui/components/XContent'
import XContainer from 'xui/components/XContainer'
import FAQ from 'x/config/FAQ'
import XButton from 'xui/components/XButton'
import Box from 'xui/components/Box'
import XText from 'xui/components/XText'
import HStack from 'xui/components/HStack'

const FONT_SIZE = STYLES.FONT_SIZE_LARGER

export default abstract class BaseUIStoreSettingPeakView extends BaseStoreSettingPeakView {
  static displayName = 'BaseUIStoreSettingPeakView'
  // abstract goBack: () => void

  renderCustomHeader = () => (
    <XCustomHeader
      renderHeaderLeft={this._renderHeaderLeft}
      renderHeaderRight={this._renderHeaderRight}
      title='รายงาน Excel PEAK Account'
    />
  )

  _renderHeaderRight = () => {
    const { mode } = this.state
    if (mode === 'START') {
      return null
    }
    return (
      <View>
        {mode === 'VIEW' ? (
          <XButton
            variant='ghost'
            onPress={() =>
              util.setStatePromise(this, {
                mode: 'EDIT',
              })
            }>
            {p.op.t('edit')}
          </XButton>
        ) : (
          <XButton variant='ghost' onPress={() => this._cancelEdit()}>
            {p.op.t('Order.cancelled')}
          </XButton>
        )}
      </View>
    )
  }

  _renderHeaderLeft = () => {
    const { mode } = this.state
    // console.log('mode => ', mode)
    if (mode === 'START') {
      return <XButton variant='ghost' leftIcon={<XIcon name='arrowleft' family='AntDesign' />} onPress={() => util.navGoBack(this.props)} />
    }
    return (
      <View>
        {mode === 'VIEW' ? (
          <XButton variant='ghost' leftIcon={<XIcon name='arrowleft' family='AntDesign' />} onPress={() => util.navGoBack(this.props)} />
        ) : (
          <XButton variant='ghost' onPress={() => this._saveBtn()}>
            {p.op.t('save')}
          </XButton>
        )}
      </View>
    )
  }

  _cancelEdit = () => {
    p.op.showConfirmation(
      '',
      'ยกเลิกการแก้ไข',
      () => this.setStartMode(),
      () => null,
      'ตกลง',
      'แก้ไขต่อ'
    )

    // util.setStatePromise(this, {
    //   mode: 'VIEW',
    // })
  }

  _renderProductGroups = () => {
    // const { selectedStore } = this.props
    const { productGroups, mode, selectedProductGroup, selectedProductGroupIndex } = this.state
    if (_.isNil(productGroups)) {
      return null
    }
    // console.log('selectedProductGroup => ', selectedProductGroup)
    const selectedProductGroupName = _.isNil(selectedProductGroup) ? 'ไม่ระบุ' : selectedProductGroup.name
    // console.log('selectedProductGroupName => ', selectedProductGroupName)
    const renderHelpBtn = (onPressOpenOverlay: () => void) => (
      <View style={{ width: 30 }}>
        <HelpButton style={{ minWidth: 10, width: 10 }} onPress={() => onPressOpenOverlay()} />
      </View>
    )
    return (
      <Box _light={{ bg: 'white' }} _dark={{ bg: NB.C.D.BG.DARK_GREY }} p='2' borderRadius='md'>
        {mode === 'VIEW' ? (
          <View style={{ flex: 1, flexDirection: 'row' }}>
            <View style={{ flexDirection: 'row', alignItems: 'center', flex: 1 }}>
              <XText variant='active' bold>
                ราคาก่อนส่วนลด
              </XText>
              <XHelpModal
                key='XHelpModalFromXForceCreateShipping'
                headerTitle='PEAK Account'
                FAQ={FAQ.PEAK_FAQ}
                initiateOpenIndex={0}
                renderButton={(onPressOpenOverlay: () => void) => renderHelpBtn(onPressOpenOverlay)}
              />
            </View>
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'flex-end' }}>
              <XText pr='1' variant='active'>
                {selectedProductGroupName}
              </XText>
            </View>
          </View>
        ) : (
          <Box>
            <View style={{ flexDirection: 'row', alignItems: 'center', flex: 1 }}>
              <XText variant='active' bold>
                ราคาก่อนส่วนลด
              </XText>
              <XHelpModal
                key='XHelpModalFromXForceCreateShipping'
                headerTitle='PEAK Account'
                FAQ={FAQ.PEAK_FAQ}
                initiateOpenIndex={0}
                renderButton={(onPressOpenOverlay: () => void) => renderHelpBtn(onPressOpenOverlay)}
              />
            </View>
            <XRadioList
              // title={'ราคาก่อนส่วนลด'}
              selectedIndex={selectedProductGroupIndex}
              options={productGroups}
              onRadioChange={this._selectedProductGroup}
              // labelStyle={{ color: COLORS.TEXT_ACTIVE }}
            />
          </Box>
        )}
      </Box>
    )
  }

  _renderInvoiceView = () => {
    const { issueReceipt, mode } = this.state
    const statusLable = !issueReceipt ? 'ปิดใช้งาน' : 'เปิดใช้งาน'
    return (
      <Box _light={{ bg: 'white' }} _dark={{ bg: NB.C.D.BG.DARK_GREY }} p='2' mt='2' flexDirection='row' borderRadius='md'>
        <View style={{ flex: 1 }}>
          <XText variant='active' bold>
            ออกใบกำกับ
          </XText>
        </View>

        {mode === 'VIEW' ? (
          <XText variant='active'>{statusLable}</XText>
        ) : (
          <View style={{ width: 40, alignItems: 'center', justifyContent: 'center' }}>
            <XSwitch
              value={issueReceipt}
              onValueChange={(newValue) => {
                if (this._isViewMode()) {
                  return
                }
                util.setStatePromise(this, {
                  issueReceipt: newValue,
                })
              }}
            />
          </View>
        )}
      </Box>
    )
  }

  _renderVatView = () => {
    const { vatTypeIndex, vatValue, mode } = this.state
    const varTypeLable = vatTypeIndex === 0 ? 'รวม VAT' : vatTypeIndex === 1 ? 'ไม่รวม VAT' : 'ไม่มี VAT'
    return (
      <Box _light={{ bg: 'white' }} _dark={{ bg: NB.C.D.BG.DARK_GREY }} p='2' mt='2' borderRadius='md'>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <View style={{ flex: 1 }}>
            <XText variant='active' bold>
              ราคาสินค้า
            </XText>
          </View>
          {mode === 'VIEW' ? (
            <XText variant='active'>{varTypeLable}</XText>
          ) : (
            <View style={{ width: 250, alignItems: 'center', justifyContent: 'center', height: 40 }}>
              <Segment
                options={['รวม VAT', 'ไม่รวม VAT', 'ไม่มี VAT']}
                selectedIndex={vatTypeIndex}
                onSegmentChange={(val: number) => {
                  if (this._isViewMode()) {
                    return
                  }
                  util.setStatePromise(this, {
                    vatTypeIndex: val,
                  })
                }}
              />
            </View>
          )}
        </View>
        <View style={{ height: p.op.isWeb() ? 8 : 4 }} />
        {vatTypeIndex === 2 ? null : (
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <View style={{ flex: 1 }}>
              <XText variant='active' bold>
                %VAT
              </XText>
            </View>
            <View style={{ width: 20, alignItems: 'center', justifyContent: 'center', marginRight: 4 }}>
              <XText variant='active' bold>
                %
              </XText>
            </View>

            <View style={{ width: 60, alignItems: 'center', justifyContent: 'center' }}>
              <XInput
                disabled={this._isViewMode()}
                style={{ textAlign: 'right', fontSize: FONT_SIZE }}
                value={vatValue}
                onChangeText={(text: string) => {
                  util.setStatePromise(this, {
                    vatValue: text,
                  })
                }}
                placeholder=''
                placeholderTextColor={COLORS.TEXT_INACTIVE}
                keyboardType='numeric'
                // isInteger={true}
                onBlur={() => {
                  // console.log('onBlur => ')
                  let toNumber = +vatValue
                  if (_.isNaN(toNumber)) {
                    toNumber = 0
                  }
                  const vat = toNumber.toFixed(2)
                  util.setStatePromise(this, {
                    vatValue: vat,
                  })
                }}
              />
            </View>
          </View>
        )}
      </Box>
    )
  }

  _renderAccountView = () => {
    const { inputAccountShippingText, inputOtherAccountText } = this.state
    return (
      <Box _light={{ bg: 'white' }} _dark={{ bg: NB.C.D.BG.DARK_GREY }} p='2' mt='2' borderRadius='md'>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <View style={{ flex: 1 }}>
            <XText variant='active' bold>
              รหัสบัญชี สำหรับค่าส่ง
            </XText>
          </View>
          <View style={{ width: 200, alignItems: 'center', justifyContent: 'center' }}>
            <XInput
              disabled={this._isViewMode()}
              style={{ textAlign: 'right', fontSize: FONT_SIZE }}
              value={inputAccountShippingText}
              onChangeText={(text) => util.setStatePromise(this, { inputAccountShippingText: text })}
              placeholder=''
              placeholderTextColor={COLORS.TEXT_INACTIVE}
            />
          </View>
        </View>
        <View style={{ height: 6 }} />
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <View style={{ flex: 1 }}>
            <XText variant='active' bold>
              รหัสบัญชี สำหรับค่าอื่นๆ
            </XText>
          </View>
          <View style={{ width: 200, alignItems: 'center', justifyContent: 'center' }}>
            <XInput
              disabled={this._isViewMode()}
              style={{ textAlign: 'right', fontSize: FONT_SIZE }}
              value={inputOtherAccountText}
              onChangeText={(text) => util.setStatePromise(this, { inputOtherAccountText: text })}
              placeholder=''
              placeholderTextColor={COLORS.TEXT_INACTIVE}
            />
          </View>
        </View>
      </Box>
    )
  }

  renderStarter = () => (
    <Box
      _dark={{ bg: 'gray.300' }}
      borderRadius='md'
      style={{
        flex: 1,
        height: 500,
        justifyContent: 'center',
        alignItems: 'center',
        // backgroundColor: COLORS.WHITE,
        marginTop: 4,
        marginBottom: 4,
        marginLeft: 4,
        marginRight: 4,
        // borderRadius: 7,
      }}>
      <Image style={{ width: 150, height: 150 }} source={require('../../img/peak.png')} />
      <View style={{ height: 20 }} />
      <XText variant='active'>โปรแกรมบัญชีออนไลน์</XText>
      <View style={{ height: 20 }} />
      <XButton
        onPress={() =>
          util.setStatePromise(this, {
            mode: 'EDIT',
          })
        }
        w='72'
        h='16'>
        <XText textAlign='center' color='white' bold>
          {'ตั้งค่าการนำออกข้อมูลเพื่อไปใช้กับระบบ\nPEAK Account'}
        </XText>
      </XButton>
    </Box>
  )

  renderMain = () => {
    const { loadingHight, loadingWidth, isLoading } = this.state
    return (
      <Box mx='2' style={{ marginTop: 6, paddingLeft: 4, paddingRight: 4 }}>
        <Box
          onLayout={(event) => {
            const { x, y, width, height } = event.nativeEvent.layout
            util.setStatePromise(this, { loadingWidth: width, loadingHight: height })
          }}>
          {this._renderProductGroups()}
          {this._renderInvoiceView()}
          {this._renderAccountView()}
          {this._renderVatView()}
        </Box>
        {isLoading ? VIEW_LOADING(loadingWidth, loadingHight) : null}
      </Box>
    )
  }

  render() {
    const { mode } = this.state
    return (
      <XContainer>
        <XContent>
          {this.renderCustomHeader()}
          <HStack mx='4' mt='2'>
            <XText variant='inactive'>{'เป็นการตั้งค่าสำหรับการโหลด Excel ที่เมนู "รายงาน > PEAK Account" เท่านั้น'}</XText>
          </HStack>
          {mode === 'START' ? this.renderStarter() : this.renderMain()}
        </XContent>
      </XContainer>
    )
  }
}
