import { IAddress, IApiOptions, IGetStoreAddressListRequestBody, ILookupSubDistrictData, ISubscriberAddress } from 'x/types'

import _ from 'lodash'
import api from './api'

interface ILookupLegalEntityByIdRequestBody {
  legal_entity_id: string
}

export async function lookUpLegalEntityById(params: ILookupLegalEntityByIdRequestBody): Promise<ISubscriberAddress> {
  const { legal_entity_id } = params

  if (!legal_entity_id) {
    throw new Error('lookUpLegalEntityById:: Need legal_entity_id')
  }

  const apiOptions: IApiOptions = {
    // messages: {
    //   successMsg: 'สำเร็จ',
    //   errorMsg: 'ล้มเหลว',
    // },
    showSpinner: false,
    isApiV2: true,
    axiosOptions: {
      timeout: 5000,
    },
  }

  try {
    // console.log('In lookUpLegalEntityById:: reqBody => ', params)
    const res = await api.post<ILookupLegalEntityByIdRequestBody, ISubscriberAddress>('address/lookup_legal_entity', params, apiOptions)
    // console.log('In lookUpLegalEntityById:: response => ', res)

    if (_.isNil(res.postal_code)) {
      throw new Error('lookUpLegalEntityById:: No "contacts" in response')
    }

    return res
  } catch (err) {
    console.log('In lookUpLegalEntityById:: err => ', err)
    throw err
  }
}

interface ILookupSubDistrictResponse {
  data: ILookupSubDistrictData[]
}
// public api
export async function lookUpForSubDistrict(postal_code: string): Promise<ILookupSubDistrictData[]> {
  if (_.isNil(postal_code)) {
    throw new Error('lookUpForSubDistrict:: Need postal_code')
  }

  const apiOptions: IApiOptions = {
    // messages: {
    //   successMsg: 'สำเร็จ',
    //   errorMsg: 'ล้มเหลว',
    // },
    showSpinner: false,
    isApiV2: true,
    axiosOptions: {
      timeout: 5000,
    },
    noToken: true,
  }

  try {
    const basseEndpoint = 'address/lookup_for_subdistrict'

    const endpoint = `${basseEndpoint}?postal_code=${postal_code}`
    // console.log('In lookUpForSubDistrict:: reqBody => ', params)
    const res = await api.get<ILookupSubDistrictResponse>(endpoint, apiOptions)
    // console.log('In lookUpForSubDistrict:: response => ', res)

    if (_.isNil(res.data)) {
      throw new Error('lookUpForSubDistrict:: No "data" in response')
    }

    return res.data
  } catch (err) {
    console.log('In lookUpForSubDistrict:: err => ', err)
    throw err
  }
}

// ----------------------------------------------

interface IAddStoreAddress extends IAddress {
  store_id: number
}

export async function addStoreAddress(params: IAddStoreAddress): Promise<any> {
  const { store_id } = params

  if (!store_id) {
    throw new Error('addStoreAddress:: Need store_id')
  }

  const apiOptions: IApiOptions = {
    messages: {
      successMsg: 'บันทึกที่อยู่สำเร็จ',
      errorMsg: 'บันทึกที่อยู่ล้มเหลว',
    },
    showSpinner: false,
    isApiV2: true,
    axiosOptions: {
      timeout: 18000,
    },
  }

  try {
    // @ts-ignore
    // const reqBody: IEditStoreAddress = convertNormalAddressToStoreAddress(params)
    const reqBody: IAddStoreAddress = _.cloneDeep(params)

    if ('id' in reqBody) {
      delete reqBody.id
    }

    if (reqBody.postal_code) {
      // @ts-ignore
      reqBody.postal_code = parseInt(reqBody.postal_code)
    }

    // console.log('In addStoreAddress:: reqBody => ', params)
    const res = await api.post<IAddStoreAddress, any>('address/create', reqBody, apiOptions)
    // console.log('In addStoreAddress:: response => ', res)

    if ('id' in res) {
      return res
    }

    throw new Error('addStoreAddress:: No contact "id" in response')
  } catch (err) {
    console.log('In addStoreAddress:: err => ', err)
    throw err
  }
}

interface IEditStoreAddress extends IAddress {
  store_id: number
}

export async function editStoreAddress(params: IEditStoreAddress): Promise<any> {
  const { store_id } = params

  if (!store_id) {
    throw new Error('editStoreAddress:: Need store_id')
  }

  const apiOptions: IApiOptions = {
    messages: {
      successMsg: 'บันทึกที่อยู่สำเร็จ',
      errorMsg: 'บันทึกที่อยู่ล้มเหลว',
    },
    showSpinner: false,
    isApiV2: true,
    axiosOptions: {
      timeout: 18000,
    },
  }

  try {
    // @ts-ignore
    const reqBody: IEditStoreAddress = _.cloneDeep(params)

    if (_.isString(reqBody.postal_code)) {
      // @ts-ignore
      reqBody.postal_code = parseInt(reqBody.postal_code)
    }

    // @ts-ignore
    if (reqBody.type) {
      // @ts-ignore
      delete reqBody.type
    }

    if (_.isNil(reqBody.branch_type)) {
      delete reqBody.branch_type
    }

    if (_.isNil(reqBody.branch_number)) {
      delete reqBody.branch_number
    }

    if (_.isNil(reqBody.legal_entity_id)) {
      delete reqBody.legal_entity_id
    }

    if (_.isNil(reqBody.branch_number)) {
      delete reqBody.branch_number
    }

    // console.log('In editStoreAddress:: reqBody => ', params)
    const res = await api.post<IEditStoreAddress, any>('address/edit', reqBody, apiOptions)
    // console.log('In editStoreAddress:: response => ', res)

    if ('id' in res) {
      return res
    }

    throw new Error('editStoreAddress:: No contact "id" in response')
  } catch (err) {
    console.log('In editStore:: err => ', err)
    throw err
  }
}

interface IDeleteStoreAddress {
  store_id: number

  // address_id
  id: number
}

export async function deleteStoreAddress(params: IDeleteStoreAddress): Promise<void> {
  const { store_id } = params

  if (!store_id) {
    throw new Error('deleteStoreAddress:: Need store_id')
  }

  const apiOptions: IApiOptions = {
    messages: {
      successMsg: 'บันทึกที่อยู่สำเร็จ',
      errorMsg: 'บันทึกที่อยู่ล้มเหลว',
    },
    showSpinner: false,
    isApiV2: true,
    axiosOptions: {
      timeout: 18000,
    },
  }

  try {
    const reqBody: IDeleteStoreAddress = _.cloneDeep(params)

    // console.log('In deleteStoreAddress:: reqBody => ', params)
    const res = await api.post<IDeleteStoreAddress, any>('address/delete', reqBody, apiOptions)
    console.log('In deleteStoreAddress:: response => ', res)

    return
  } catch (err) {
    console.log('In deleteStoreAddress:: err => ', err)
    throw err
  }
}

type IGetStoreAddressListResponse = {
  addresses: IAddress[]
}

export async function getStoreAddressList(params: IGetStoreAddressListRequestBody): Promise<IAddress[]> {
  const { store_id } = params

  if (!store_id) {
    throw new Error('getStoreAddressList:: Need store_id')
  }

  const apiOptions: IApiOptions = {
    // messages: {
    //   successMsg: 'แก้ไขการตั้งค่าร้านค้าสำเร็จ',
    //   errorMsg: 'ล้มเหลวในการแก้ไขการตั้งค่า',
    // },
    showSpinner: false,
    isApiV2: true,
    axiosOptions: {
      timeout: 18000,
    },
  }

  try {
    // console.log('In getStoreAddressList:: reqBody => ', params)
    const res = await api.post<IGetStoreAddressListRequestBody, IGetStoreAddressListResponse>('address/list', params, apiOptions)
    // console.log('In getStoreAddressList:: response => ', res)

    if (_.isNil(res.addresses)) {
      throw new Error('getStoreAddressList:: No "addresses" in response')
    }

    return res.addresses
  } catch (err) {
    console.log('In getStoreAddressList:: err => ', err)
    throw err
  }
}
