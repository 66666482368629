import React from 'react'
import { Spinner, ISpinnerProps } from 'native-base'
import NB from 'x/config/nativebase'

export interface IXSpinnerProps extends ISpinnerProps {
  variant?: 'active' | 'inactive' | 'activeDark' | 'success' | 'danger'
}

const XSpinner: React.FC<IXSpinnerProps> = ({ variant = 'primary', size = 'sm', ...restProps }) => {
  // default primary
  let colorByVariant
  switch (variant) {
    case 'active':
      // colorByVariant = NB.HC.TEXT.ACTIVE()
      colorByVariant = NB.C.L.TEXT.ACTIVE
      break
    case 'inactive':
      // colorByVariant = NB.HC.TEXT.INACTIVE()
      colorByVariant = NB.C.L.TEXT.INACTIVE
      break
    case 'activeDark':
      // colorByVariant = NB.HC.TEXT.ACTIVE_DARK()
      colorByVariant = NB.C.L.TEXT.ACTIVE_DARK
      break
    case 'success':
      // colorByVariant = NB.HC.TEXT.SUCCESS()
      colorByVariant = NB.C.L.TEXT.SUCCESS
      break
    case 'danger':
      // colorByVariant = NB.HC.TEXT.DANGER()
      colorByVariant = NB.C.L.TEXT.DANGER
      break
    default:
      // colorByVariant = NB.HC.TEXT.PRIMARY()
      colorByVariant = NB.C.L.TEXT.PRIMARY
  }

  return <Spinner color={colorByVariant} size={size} {...restProps} />
}

XSpinner.displayName = 'XSpinner'

export default XSpinner
