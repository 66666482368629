import { Component } from 'react'
import _ from 'lodash'
import { isImmutable } from 'immutable'
// import settings from '../config/settings'
import { IProductGroup, IXScreenProps } from 'x/index'
import * as util from 'x/utils/util'
import p from '../../config/platform-specific'

interface IPGSelectorOnlyViewProps extends IXScreenProps {
  selectedStore: any
}

export interface IPGSelectorOnlyViewState {
  callBack: (ids: number[], apiKey?: string) => void
  productGroupLable: string[]
  selectedIds: number[]
  mkp_id?: number
  apiKey: string

  shopifyApiAccessToken: string
  shopifyApiKey: string
  shopifyApiSecret: string
  shopifyShopDomainName: string
}

export default class BasePGSelectorOnlyView extends Component<IPGSelectorOnlyViewProps, IPGSelectorOnlyViewState> {
  static defaultProps = {}

  inProcess?: boolean

  constructor(props: IPGSelectorOnlyViewProps) {
    super(props)
    this.state = {
      callBack: null,
      productGroupLable: [],
      selectedIds: [],
      apiKey: '',
      shopifyApiAccessToken: '',
      shopifyApiKey: '',
      shopifyApiSecret: '',
      shopifyShopDomainName: '',
      mkp_id: null,
    }
  }

  componentDidMount() {
    // const { navigation } = this.props
    // const { state } = navigation
    // const { params } = state
    // console.log('params => ', params)
    const callBack = util.getNavParam(this.props, 'callBack')
    const mkp_id = util.getNavParam(this.props, 'mkp_id')
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, react/no-unused-state
    this.setState({ callBack, mkp_id })
    this._configProductGroupsLabel()
  }

  _configProductGroupsLabel = () => {
    const { selectedStore } = this.props
    const productGroups: IProductGroup[] = isImmutable(selectedStore.get('product_groups'))
      ? selectedStore.get('product_groups').toJS()
      : selectedStore.get('product_groups')

    const productGroupLable = []
    // const productGroupSelectedIndex = []
    productGroups.forEach((pg: IProductGroup, index: number) => {
      productGroupLable.push(pg.name)
      // if (mode !== 'CREATE' && _.includes(channel.pg_ids, pg.id)) {
      //   productGroupSelectedIndex.push(index)
      // }
    })
    // console.log('productGroups => ', productGroups)
    // console.log('channel => ', channel)
    this.setState({ productGroupLable })
  }

  _saveBtn = () => {
    const {
      callBack,
      selectedIds,
      apiKey,
      mkp_id,
      shopifyApiAccessToken,
      shopifyApiKey,
      shopifyApiSecret,
      shopifyShopDomainName,
    } = this.state
    if (_.isFunction(callBack)) {
      // IF IS LINE MKP
      if (!_.isNil(mkp_id) && mkp_id === 4 && apiKey.length < 1) {
        p.op.showConfirmationOkOnly('', 'กรุณาระบุ Line API Key')
        return
      }
      // IF IS Shopify MKP
      if (!_.isNil(mkp_id) && mkp_id === 5) {
        if (shopifyApiAccessToken.length < 1) {
          p.op.showConfirmationOkOnly('', 'กรุณาระบุ Shopify API Access Token')
          return
        }
        if (shopifyApiKey.length < 1) {
          p.op.showConfirmationOkOnly('', 'กรุณาระบุ Shopify API Key')
          return
        }
        if (shopifyApiSecret.length < 1) {
          p.op.showConfirmationOkOnly('', 'กรุณาระบุ Shopify API Secret')
          return
        }
        if (shopifyShopDomainName.length < 1) {
          p.op.showConfirmationOkOnly('', 'กรุณาระบุ Shopify Shop Domain Name')
          return
        }
      }
      const pattern = /^[a-zA-Z0-9]+$/
      const isValid = pattern.test(apiKey)
      if ((!isValid || apiKey.length < 20) && mkp_id === 4) {
        p.op.showConfirmationOkOnly(
          'API Key ไม่ถูกต้อง',
          'กรุณาปิดหน้าต่างนี้ แล้วกดปุ่ม "ดูวิธีการเชื่อม" เพื่อดูคลิปแนะนำวิธีการลงทะเบียนเพื่อรับ API Key',
          () => null,
          'ปิด'
        )
        return
      }
      if (selectedIds.length < 1) {
        p.op.showConfirmationOkOnly('', 'กรุณาเลือกอย่างน้อย 1 รายการราคา')
        return
      }
      let setApiKey = apiKey
      // IF Shopify
      if (mkp_id === 5) {
        let shopName = shopifyShopDomainName
        if (!shopName.includes('.myshopify.com')) {
          shopName += '.myshopify.com'
        }
        // console.log('shopName => ', shopName)
        // @ts-ignore
        setApiKey = {
          access_token: shopifyApiAccessToken,
          api_key: shopifyApiKey,
          api_secret: shopifyApiSecret,
          shop_name: shopName,
        }
      }
      callBack(selectedIds, setApiKey)
      util.navGoBack(this.props)
      return
    }
    p.op.showConfirmationOkOnly('', 'callBack is null')
  }

  _cancelBtn = () => {
    p.op.showConfirmation(
      'ยังไม่ได้บันทึก',
      'ต้องการปิดหน้านี้ใช่หรือไม่',
      () => util.navGoBack(this.props),
      () => null,
      'ออกจากหน้านี้',
      'ทำรายการต่อ'
    )
  }
}
