import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as StoreState from 'x/modules/store/StoreState'
import { getSelectedStore, getSelectedCustomOrderView } from 'x/redux/selectors'
import TimeSlotsView from './TimeSlotsView'

// import * as AddressActions from '../address/AddressState'

export default connect(
  (state) => {
    return {
      selectedStore: getSelectedStore(state),
      selectedCustomOrderView: getSelectedCustomOrderView(state),
    }
  },
  (dispatch) => {
    return {
      setPrefTimeslot: bindActionCreators(StoreState.setPrefTimeslot, dispatch),
      updateNewChannelList: bindActionCreators(StoreState.updateNewChannelList, dispatch),
      // pickerSelect: bindActionCreators(bpActions.pickerSelect, dispatch),
      // addCustomOrder: bindActionCreators(PreferenceState.addCustomOrder, dispatch),
      // loadPrefUIOrderView: bindActionCreators(PreferenceState.loadPrefUIOrderView, dispatch),
    }
  }
)(TimeSlotsView)
