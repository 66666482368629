import React from 'react'
import { TouchableOpacity } from 'react-native'
import HStack from './HStack'
import XIcon from './XIcon'
import VStack from './VStack'

interface IFilterButtonProps {
  onPress: () => Promise<void> | void
  isFiltered?: boolean
}

const FilterButton: React.FC<IFilterButtonProps> = (props) => {
  const { isFiltered = false, onPress } = props

  return (
    <TouchableOpacity onPress={onPress}>
      <VStack w='44px' h='44px' alignItems='center' justifyContent='center'>
        <HStack alignItems='center' justifyContent='center'>
          <XIcon family='MaterialCommunityIcons' name='filter' />
        </HStack>
        {isFiltered && (
          <XIcon
            family='MaterialCommunityIcons'
            variant='success'
            name='check-circle'
            position='absolute'
            bottom='9px'
            right='9px'
            size='16px'
          />
        )}
      </VStack>
    </TouchableOpacity>
  )
}

export default FilterButton
