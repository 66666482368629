import { Component } from 'react'

import _ from 'lodash'
// import { COLORS, STYLES } from '../../config/styles'
import { Map, fromJS } from 'immutable'
import xCONS from 'x/config/constants'
// import { RowDataMap } from '../../../components/BaseRangeAdjuster'
import { IXScreenProps } from 'x/types'
import * as util from '../../../utils/util'
import p from '../../../config/platform-specific'
import { IPermission, ResponesPermissionList } from './BaseAssistantGroupListView'

const moment = require('moment')
require('moment/locale/th')
require('moment-timezone')

export interface BaseBaseAssistantGroupViewProps extends IXScreenProps {
  selectedStore: Map<string, any>
  addPermisson: Function
  editPermisson: Function
  deletePermission: Function
}

export interface BaseBaseAssistantGroupViewState {
  mode: string
  departmentName: string
  departmentNameDescription: string
  canDo: IHelperCanDo
  store_id: number | null
  arrowFunctionShowAndHide: IArrowFunctionShowAndHide
  selectedAllCheckBox: IArrowFunctionShowAndHide
  permissionID: number
  // arrowOrder: boolean
  // arrowProduct: boolean
  loading: boolean
  reRenderForCallBack: boolean
}

export interface IArrowFunctionShowAndHide {
  order: boolean
  product: boolean
  payment: boolean
  shipping: boolean
  bin_dashboard: boolean
  report: boolean
  ug: boolean
  contact: boolean
  pg: boolean
  member: boolean
  wh: boolean
  mkp: boolean
  erp: boolean
}

const defaultArrowFunctionShowAndHide: IArrowFunctionShowAndHide = {
  order: true,
  product: true,
  payment: true,
  shipping: true,
  bin_dashboard: true,
  report: true,
  ug: true,
  contact: true,
  pg: true,
  member: true,
  wh: true,
  mkp: true,
  erp: true,
}

const defaultselectedAllCheckBox: IArrowFunctionShowAndHide = {
  order: false,
  product: false,
  payment: false,
  shipping: false,
  bin_dashboard: false,
  report: false,
  ug: false,
  contact: false,
  pg: false,
  member: false,
  wh: false,
  mkp: false,
  erp: false,
}

export interface IHelperCanDo {
  order_list: boolean
  order_add: boolean
  order_edit: boolean
  custom_order_view: boolean
  order_cancel: boolean
  order_print_shipping: boolean
  order_visible_only_profile_id: boolean
  product_list: boolean
  product_add: boolean
  product_pull: boolean
  product_edit: boolean
  product_delete: boolean
  product_cost: boolean
  product_price: boolean
  product_stock_view: boolean
  product_stock_edit: boolean
  product_shipping_qty_rate: boolean
  product_weight: boolean
  member_list: boolean
  member_add: boolean
  member_edit: boolean
  member_delete: boolean
  ug_list: boolean
  ug_add: boolean
  ug_edit: boolean
  ug_delete: boolean
  pg_list: boolean
  pg_add: boolean
  pg_edit: boolean
  pg_delete: boolean
  vd_list: boolean
  payment_list: boolean
  payment_add: boolean
  payment_edit: boolean
  payment_delete: boolean
  shipping_list: boolean
  shipping_add: boolean
  shipping_edit: boolean
  shipping_delete: boolean
  shopping_assistant_add: boolean
  shopping_assistant_edit: boolean
  shopping_assistant_delete: boolean
  shopping_assistant_list: boolean
  // TODO: Binary code [2 = sku / 1 = upc] get from 'product_code_edit'
  product_code_edit: number
  // TODO REPORT
  report_order: number
  report_inventory: number
  report_shipping: number
  report_reseller: number
  report_helper: number
  report_payment: number
  report_peak_account: number

  wh_list: boolean
  wh_add: boolean
  wh_edit: boolean
  wh_delete: boolean
  mkp_list: boolean
  mkp_add: boolean
  mkp_edit: boolean
  mkp_delete: boolean

  bin_dashboard: number

  erp_channel_add: boolean
  erp_channel_delete: boolean
  erp_channel_edit: boolean
  erp_channel_list: boolean
  erp_channel_set_active: boolean
  erp_doc_create: boolean
  erp_doc_cancel: boolean

  contact_list: boolean
  contact_add: boolean
  contact_edit: boolean
  contact_delete: boolean
}

export interface IHelperRespone {
  n: string
  d: string
  p: IHelperCanDo
}

export const HEADER_CONSTANT = {
  ORDER: 'order',
  PRODUCT: 'product',
  PAYMENT: 'payment',
  SHIPPING: 'shipping',
  BIN_DASHBOARD: 'bin_dashboard',
  REPORT: 'report',
  UG: 'ug',
  CONTACT: 'contact',
  PG: 'pg',
  MEMBER: 'member',
  WH: `wh`,
  MKP: `mkp`,
  ERP: `erp`,
}

export const GROUP_ITEM = {
  order: [
    xCONS.PERM_STORE_HELPER.ORDER_ADD,
    xCONS.PERM_STORE_HELPER.ORDER_LIST,
    xCONS.PERM_STORE_HELPER.ORDER_EDIT,
    xCONS.PERM_STORE_HELPER.ORDER_CANCEL,
    xCONS.PERM_STORE_HELPER.ORDER_PRINT_SHIPPING,
    xCONS.PERM_STORE_HELPER.ORDER_VISIBLE_ONLY_PROFILE_ID,
  ],
  product: [
    xCONS.PERM_STORE_HELPER.PRODUCT_LIST,
    xCONS.PERM_STORE_HELPER.PRODUCT_ADD,
    xCONS.PERM_STORE_HELPER.PRODUCT_EDIT,
    xCONS.PERM_STORE_HELPER.PRODUCT_DELETE,
    xCONS.PERM_STORE_HELPER.PRODUCT_PULL,
    xCONS.PERM_STORE_HELPER.PRODUCT_STOCK_VIEW,
    xCONS.PERM_STORE_HELPER.PRODUCT_STOCK_EDIT,
    xCONS.PERM_STORE_HELPER.PRODUCT_COST,
    xCONS.PERM_STORE_HELPER.PRODUCT_PRICE,
    xCONS.PERM_STORE_HELPER.PRODUCT_CODE_EDIT,
    // xCONS.PERM_STORE_HELPER.PRODUCT_CODE_EDIT_UPC,
    // xCONS.PERM_STORE_HELPER.PRODUCT_CODE_EDIT_SKU,
    xCONS.PERM_STORE_HELPER.PRODUCT_WEIGHT,
    xCONS.PERM_STORE_HELPER.PRODUCT_SHIPPING_QTY_RATE,
  ],
  payment: [
    xCONS.PERM_STORE_HELPER.PAYMENT_ADD,
    xCONS.PERM_STORE_HELPER.PAYMENT_DELETE,
    xCONS.PERM_STORE_HELPER.PAYMENT_EDIT,
    xCONS.PERM_STORE_HELPER.PAYMENT_LIST,
  ],
  shipping: [
    xCONS.PERM_STORE_HELPER.SHIPPING_ADD,
    xCONS.PERM_STORE_HELPER.SHIPPING_LIST,
    xCONS.PERM_STORE_HELPER.SHIPPING_EDIT,
    xCONS.PERM_STORE_HELPER.SHIPPING_DELETE,
  ],
  report: [xCONS.REPORT_GROUP_KEY.ALL],
  ug: [xCONS.PERM_STORE_HELPER.UG_ADD, xCONS.PERM_STORE_HELPER.UG_LIST, xCONS.PERM_STORE_HELPER.UG_EDIT, xCONS.PERM_STORE_HELPER.UG_DELETE],
  contact: [
    xCONS.PERM_STORE_HELPER.CONTACT_ADD,
    xCONS.PERM_STORE_HELPER.CONTACT_LIST,
    xCONS.PERM_STORE_HELPER.CONTACT_EDIT,
    xCONS.PERM_STORE_HELPER.CONTACT_DELETE,
  ],
  member: [
    xCONS.PERM_STORE_HELPER.MEMBER_LIST,
    xCONS.PERM_STORE_HELPER.MEMBER_ADD,
    xCONS.PERM_STORE_HELPER.MEMBER_EDIT,
    xCONS.PERM_STORE_HELPER.MEMBER_DELETE,
  ],
  pg: [xCONS.PERM_STORE_HELPER.PG_LIST, xCONS.PERM_STORE_HELPER.PG_ADD, xCONS.PERM_STORE_HELPER.PG_EDIT, xCONS.PERM_STORE_HELPER.PG_DELETE],
  wh: [xCONS.PERM_STORE_HELPER.WH_LIST, xCONS.PERM_STORE_HELPER.WH_ADD, xCONS.PERM_STORE_HELPER.WH_EDIT, xCONS.PERM_STORE_HELPER.WH_DELETE],
  mkp: [
    xCONS.PERM_STORE_HELPER.MKP_LIST,
    xCONS.PERM_STORE_HELPER.MKP_ADD,
    xCONS.PERM_STORE_HELPER.MKP_EDIT,
    xCONS.PERM_STORE_HELPER.MKP_DELETE,
  ],
  erp: [
    xCONS.PERM_STORE_HELPER.ERP_CHANNEL_ADD,
    xCONS.PERM_STORE_HELPER.ERP_CHANNEL_DELETE,
    xCONS.PERM_STORE_HELPER.ERP_CHANNEL_EDIT,
    xCONS.PERM_STORE_HELPER.ERP_CHANNEL_LIST,
    xCONS.PERM_STORE_HELPER.ERP_CHANNEL_SET_ACTIVE,
    xCONS.PERM_STORE_HELPER.ERP_DOC_CREATE,
    xCONS.PERM_STORE_HELPER.ERP_DOC_CANCEL,
  ],
  bin_dashboard: [xCONS.DASHBOARD_PERM_REPORTS.bin_dashboard],
}

const MODE = {
  VIEW: 'VIEW',
  CREATE: 'CREATE',
  EDIT: 'EDIT',
}

const MOCK_DATA: IHelperRespone = {
  n: 'คนแพ็กของ',
  d: 'สำหรับคนแพ็กของเท่านั้น',
  p: {
    order_list: false,
    order_add: false,
    order_edit: false,
    custom_order_view: false,
    order_cancel: false,
    order_print_shipping: false,
    order_visible_only_profile_id: false,
    product_list: false,
    product_add: false,
    product_pull: false,
    product_edit: false,
    product_delete: false,
    product_cost: false,
    product_price: false,
    product_stock_view: false,
    product_stock_edit: false,
    product_shipping_qty_rate: false,
    product_weight: false,
    member_list: false,
    member_add: false,
    member_edit: false,
    member_delete: false,
    ug_list: false,
    ug_add: false,
    ug_edit: false,
    ug_delete: false,
    pg_list: false,
    pg_add: false,
    pg_edit: false,
    pg_delete: false,
    vd_list: false,
    payment_list: false,
    payment_add: false,
    payment_edit: false,
    payment_delete: false,
    shipping_list: false,
    shipping_add: false,
    shipping_edit: false,
    shipping_delete: false,
    shopping_assistant_add: false,
    shopping_assistant_edit: false,
    shopping_assistant_delete: false,
    shopping_assistant_list: false,
    product_code_edit: 0,
    report_order: 0,
    report_inventory: 0,
    report_shipping: 0,
    report_reseller: 0,
    report_helper: 0,
    report_payment: 0,
    report_peak_account: 0,
    wh_list: false,
    wh_add: false,
    wh_edit: false,
    wh_delete: false,
    mkp_list: false,
    mkp_add: false,
    mkp_edit: false,
    mkp_delete: false,
    bin_dashboard: 0,

    erp_channel_add: false,
    erp_channel_delete: false,
    erp_channel_edit: false,
    erp_channel_list: false,
    erp_channel_set_active: false,
    erp_doc_cancel: false,
    erp_doc_create: false,

    contact_list: false,
    contact_add: false,
    contact_edit: false,
    contact_delete: false,
  },
}

export default abstract class BaseBaseAssistantGroupView extends Component<
  BaseBaseAssistantGroupViewProps,
  BaseBaseAssistantGroupViewState
> {
  abstract _goBack(): void

  backup: {
    name: string
    description: string
    canDo: IHelperCanDo
  }

  protected constructor(props) {
    super(props)

    this.state = {
      mode: null,
      departmentName: '',
      departmentNameDescription: '',
      canDo: MOCK_DATA.p,
      store_id: null,
      arrowFunctionShowAndHide: defaultArrowFunctionShowAndHide,
      selectedAllCheckBox: defaultselectedAllCheckBox,
      permissionID: null,
      // arrowOrder: true,
      // arrowProduct: true,
      loading: true,
      reRenderForCallBack: false,
    }
  }

  async componentDidMount() {
    const { navigation, selectedStore } = this.props
    if (_.isNil(selectedStore)) {
      p.op.showToast('เกิดข้อผิดพลาด กรุณาติดต่อทีมงาน XSelly', 'warning')
      this._goBack()
      return
    }
    // const { state } = navigation
    const params = util.getNavParams(this.props)
    // console.log('state => ', state)
    const mode = params ? params.mode : null
    // console.log('MODE => ', mode)
    const permission = params ? params.permission : null
    let canDo = MOCK_DATA.p
    let departmentName = ''
    let departmentNameDescription = ''
    let permissionID = null
    if (mode === MODE.VIEW) {
      // console.log('permission => ', permission.perf)
      canDo = permission.perf
      departmentName = permission.name
      departmentNameDescription = permission.description
      permissionID = permission.id
    } else if (mode === MODE.CREATE) {
      canDo = await this._setCanDoDefault()
    }
    // console.log('canDo => ', canDo)
    const store_id = selectedStore.get('id')
    const selectedAllCheckBox = await this._calculateSelectedAllCheckBox(canDo)
    await util.setStatePromise(this, {
      mode,
      canDo,
      store_id,
      selectedAllCheckBox,
      departmentName,
      departmentNameDescription,
      permissionID,
      loading: false,
    })
    navigation.setParams({
      handleRightBtn: () => this._handleRightBtn(),
      handleLeftBtn: () => this._handleLeftBtn(),
    })
  }

  _setCanDoDefault = async () => {
    const canDo = {
      order_list: false,
      order_add: false,
      order_edit: false,
      custom_order_view: false,
      order_cancel: false,
      order_print_shipping: false,
      order_visible_only_profile_id: false,
      product_list: false,
      product_add: false,
      product_pull: false,
      product_edit: false,
      product_delete: false,
      product_cost: false,
      product_price: false,
      product_stock_view: false,
      product_stock_edit: false,
      product_shipping_qty_rate: false,
      product_weight: false,
      member_list: false,
      member_add: false,
      member_edit: false,
      member_delete: false,
      ug_list: false,
      ug_add: false,
      ug_edit: false,
      ug_delete: false,
      pg_list: false,
      pg_add: false,
      pg_edit: false,
      pg_delete: false,
      vd_list: false,
      payment_list: false,
      payment_add: false,
      payment_edit: false,
      payment_delete: false,
      shipping_list: false,
      shipping_add: false,
      shipping_edit: false,
      shipping_delete: false,
      shopping_assistant_add: false,
      shopping_assistant_edit: false,
      shopping_assistant_delete: false,
      shopping_assistant_list: false,
      product_code_edit: 0,
      report_order: 0,
      report_inventory: 0,
      report_shipping: 0,
      report_reseller: 0,
      report_helper: 0,
      report_payment: 0,
      report_peak_account: 0,
      wh_list: false,
      wh_add: false,
      wh_edit: false,
      wh_delete: false,
      mkp_list: false,
      mkp_add: false,
      mkp_edit: false,
      mkp_delete: false,
      bin_dashboard: 0,
      erp_channel_add: false,
      erp_channel_delete: false,
      erp_channel_edit: false,
      erp_channel_list: false,
      erp_channel_set_active: false,
      erp_doc_cancel: false,
      erp_doc_create: false,
      contact_list: false,
      contact_add: false,
      contact_edit: false,
      contact_delete: false,
    }
    return canDo
  }

  _callBack = () => {}

  _handleLeftBtn = async () => {
    const { navigation } = this.props
    const { mode, loading, reRenderForCallBack } = this.state
    if (loading) {
      return
    }
    if (_.isNil(mode)) {
      this._goBack()
    }
    if (mode === MODE.VIEW) {
      if (reRenderForCallBack) {
        this._callBackToAssistanGroupList(false)
      }
      this._goBack()
    } else if (mode === MODE.EDIT) {
      const respone = await this._editPermission()
      if (_.isNil(respone)) {
        return
      }
      const departmentName = respone.name
      const departmentNameDescription = respone.description
      const canDo = respone.perf
      const permissionID = respone.id
      navigation.setParams({
        mode: MODE.VIEW,
      })
      await util.setStatePromise(this, {
        canDo,
        departmentName,
        departmentNameDescription,
        permissionID,
        mode: MODE.VIEW,
        reRenderForCallBack: true,
      })
      p.op.showToast('บันทึกเรียบร้อยแล้ว', 'success')
    } else if (mode === MODE.CREATE) {
      const respone = await this._addPermission()
      if (_.isNil(respone)) {
        return
      }
      const departmentName = respone.name
      const departmentNameDescription = respone.description
      const canDo = respone.perf
      const permissionID = respone.id
      navigation.setParams({
        mode: MODE.VIEW,
      })
      await util.setStatePromise(this, {
        canDo,
        departmentName,
        departmentNameDescription,
        permissionID,
        mode: MODE.VIEW,
        reRenderForCallBack: true,
      })
      p.op.showConfirmationOkOnly(``, `สร้างสิทธิ์การใช้งานสำเร็จแล้ว`)
      this._callBackToAssistanGroupList()
    }
  }

  _handleRightBtn = () => {
    const { navigation } = this.props
    const { mode, departmentName, departmentNameDescription, canDo } = this.state
    if (_.isNil(mode)) {
      p.op.showToast('เกิดข้อผิดพลาด กรุณาติดต่อทีมงาน XSelly', 'warning')
      this._goBack()
    }
    if (mode === MODE.CREATE) {
      p.op.showConfirmation(
        'ออกจากหน้านี้',
        'ยกเลิกการสร้างใช่หรือไม่',
        () => this._goBack(),
        () => {},
        'ใช่',
        'ไม่ใช่'
      )
    } else if (mode === MODE.VIEW) {
      this.backup = {
        name: departmentName,
        description: departmentNameDescription,
        canDo,
      }
      util.setStatePromise(this, { mode: MODE.EDIT })
      navigation.setParams({
        mode: MODE.EDIT,
        title: 'แก้ไขสิทธิ์',
      })
    } else if (mode === MODE.EDIT) {
      p.op.showConfirmation(
        'ยกเลิกการแก้ไข',
        'ระบบยังไม่ได้บันทึกข้อมูล ต้องการยกเลิกการแก้ไขใช่หรือไม่',
        () => {
          const { canDo } = this.backup
          const departmentName = this.backup.name
          const departmentNameDescription = this.backup.description
          util.setStatePromise(this, { mode: MODE.VIEW, canDo, departmentName, departmentNameDescription })
          navigation.setParams({
            mode: MODE.VIEW,
            title: 'สิทธิ์การใช้งาน',
          })
        },
        () => {},
        'ใช่',
        'ไม่ใช่'
      )
    }
  }

  returnDataFromReport = (newCanDo: IHelperCanDo) => {
    const checkAll =
      // newCanDo.report_order === 7 &&
      newCanDo.report_order === 15 &&
      newCanDo.report_inventory === 15 &&
      newCanDo.report_shipping === 3 &&
      newCanDo.report_reseller === 1 &&
      newCanDo.report_helper === 1 &&
      newCanDo.report_payment === 1
    const res = {
      value: checkAll,
      xkey: 'report',
    }
    return res
  }

  _checkSelectedAllCheckBox = async (key: string, newCanDo: IHelperCanDo) => {
    // const { canDo, selectedAllCheckBox } = this.state
    const returnData = (thisGroup, key: string) => {
      let i = true
      thisGroup.forEach((data: string) => {
        if (_.isString(data)) {
          if (!newCanDo[data]) {
            i = false
          }
        }
      })
      if (thisGroup === GROUP_ITEM.product && newCanDo.product_code_edit !== 3) {
        i = false
      }
      const res = {
        value: i,
        xkey: key,
      }
      return res
    }

    if (_.includes(xCONS.DASHBOARD_PERM_REPORTS.bin_dashboard, key)) {
      return {
        value: newCanDo.bin_dashboard === 1,
        xkey: key,
      }
    }

    if (_.includes(xCONS.REPORT_GROUP_KEY.ALL, key)) {
      return this.returnDataFromReport(newCanDo)
    }
    let group: any = _.find(GROUP_ITEM.order, (data) => data === key)
    if (!_.isNil(group)) {
      return returnData(GROUP_ITEM.order, HEADER_CONSTANT.ORDER)
    }
    const UPC = xCONS.PERM_STORE_HELPER.PRODUCT_CODE_EDIT_UPC
    const SKU = xCONS.PERM_STORE_HELPER.PRODUCT_CODE_EDIT_SKU
    group = _.find(GROUP_ITEM.product, (data) => data === key)
    if (key === UPC || key === SKU) {
      group = 'OK'
    }
    if (!_.isNil(group)) {
      return returnData(GROUP_ITEM.product, HEADER_CONSTANT.PRODUCT)
    }
    group = _.find(GROUP_ITEM.payment, (data) => data === key)
    if (!_.isNil(group)) {
      return returnData(GROUP_ITEM.payment, HEADER_CONSTANT.PAYMENT)
    }
    group = _.find(GROUP_ITEM.shipping, (data) => data === key)
    if (!_.isNil(group)) {
      return returnData(GROUP_ITEM.shipping, HEADER_CONSTANT.SHIPPING)
    }
    group = _.find(GROUP_ITEM.ug, (data) => data === key)
    if (!_.isNil(group)) {
      return returnData(GROUP_ITEM.ug, HEADER_CONSTANT.UG)
    }
    group = _.find(GROUP_ITEM.pg, (data) => data === key)
    if (!_.isNil(group)) {
      return returnData(GROUP_ITEM.pg, HEADER_CONSTANT.PG)
    }
    group = _.find(GROUP_ITEM.contact, (data) => data === key)
    if (!_.isNil(group)) {
      return returnData(GROUP_ITEM.contact, HEADER_CONSTANT.CONTACT)
    }
    group = _.find(GROUP_ITEM.member, (data) => data === key)
    if (!_.isNil(group)) {
      return returnData(GROUP_ITEM.member, HEADER_CONSTANT.MEMBER)
    }
    group = _.find(GROUP_ITEM.wh, (data) => data === key)
    if (!_.isNil(group)) {
      return returnData(GROUP_ITEM.wh, HEADER_CONSTANT.WH)
    }
    group = _.find(GROUP_ITEM.mkp, (data) => data === key)
    if (!_.isNil(group)) {
      return returnData(GROUP_ITEM.mkp, HEADER_CONSTANT.MKP)
    }
    group = _.find(GROUP_ITEM.erp, (data) => data === key)
    if (!_.isNil(group)) {
      return returnData(GROUP_ITEM.erp, HEADER_CONSTANT.ERP)
    }
  }

  _calculateSelectedAllCheckBox = async (liveCando?: IHelperCanDo) => {
    const { selectedAllCheckBox } = this.state
    const canDo = _.isNil(liveCando) ? this.state.canDo : liveCando
    const selectedAllCheckBoxX = defaultselectedAllCheckBox
    let order = true
    let product = true
    let payment = true
    let shipping = true
    const report = this.returnDataFromReport(canDo)
    let ug = true
    let pg = true
    let member = true
    let bin_dashboard = true
    GROUP_ITEM.order.map((data: string) => {
      if (!canDo[data]) {
        order = false
      }
    })
    GROUP_ITEM.product.map((data: string) => {
      if (!canDo[data]) {
        product = false
      }
    })
    GROUP_ITEM.payment.map((data: string) => {
      if (!canDo[data]) {
        payment = false
      }
    })
    GROUP_ITEM.shipping.map((data: string) => {
      if (!canDo[data]) {
        shipping = false
      }
    })
    // GROUP_ITEM.report.map((data: string) => {
    //   if (!canDo[data]) {
    //     report = false
    //   }
    // })
    GROUP_ITEM.ug.map((data: string) => {
      if (!canDo[data]) {
        ug = false
      }
    })
    GROUP_ITEM.member.map((data: string) => {
      if (!canDo[data]) {
        member = false
      }
    })
    GROUP_ITEM.pg.map((data: string) => {
      if (!canDo[data]) {
        pg = false
      }
    })
    GROUP_ITEM.bin_dashboard.map((data: string) => {
      if (!canDo[data]) {
        bin_dashboard = false
      }
    })
    selectedAllCheckBox.order = order
    selectedAllCheckBox.product = product
    selectedAllCheckBox.payment = payment
    selectedAllCheckBox.shipping = shipping
    selectedAllCheckBox.pg = pg
    selectedAllCheckBox.ug = ug
    selectedAllCheckBox.member = member
    selectedAllCheckBox.report = report.value
    selectedAllCheckBox.bin_dashboard = bin_dashboard
    return selectedAllCheckBoxX
  }

  _onChangeTextDepartmentName = (text: string) => {
    util.setStatePromise(this, {
      departmentName: text,
    })
  }

  _onChangeTexteDescriptione = (text: string) => {
    util.setStatePromise(this, {
      departmentNameDescription: text,
    })
  }

  _setStateFromSKUorUPC = (key: string, value: boolean) => {
    const { canDo } = this.state
    const productCodeEdit = canDo.product_code_edit

    if (key === xCONS.PERM_STORE_HELPER.PRODUCT_CODE_EDIT_UPC) {
      const numberOfBit = value ? 1 : 2
      if (value) {
        return numberOfBit | productCodeEdit
      }
      return numberOfBit & productCodeEdit
    }
    const numberOfBit = value ? 2 : 1
    if (value) {
      return numberOfBit | productCodeEdit
    }
    return numberOfBit & productCodeEdit
  }

  _setStateForDashboard = async (key: string, value: boolean, newCanDo: IHelperCanDo) => {
    const { canDo } = this.state
    // const productCodeEdit = canDo.product_code_edit

    if (key === xCONS.DASHBOARD_PERM_REPORTS.bin_dashboard) {
      const numberOfBit = value ? 1 : 0
      // console.log('numberOfBit => ', numberOfBit)
      // console.log('util.orBit(numberOfBit, canDo.bin_dashboard) => ', util.orBit(numberOfBit, canDo.bin_dashboard))
      // eslint-disable-next-line no-param-reassign
      newCanDo.bin_dashboard = value ? util.orBit(numberOfBit, canDo.bin_dashboard) : util.andBit(numberOfBit, canDo.bin_dashboard)
      return newCanDo
    }
    return newCanDo
  }

  _setStateFromReport = (key: string, value: boolean, newCanDo: IHelperCanDo) => {
    const { canDo } = this.state
    // console.log('canDo => ', canDo)
    if (_.includes(xCONS.REPORT_GROUP_KEY.REPORT_ORDER, key)) {
      // now 4 bit 1111 = 8 + 4 + 2 + 1
      const reportOrder = canDo.report_order
      switch (key) {
        case xCONS.PERM_STORE_HELPER.REPORT_DAILY_SALES:
          const numberOfBitx01 = value ? 1 : 14
          newCanDo.report_order = value ? util.orBit(numberOfBitx01, reportOrder) : util.andBit(numberOfBitx01, reportOrder)
          return newCanDo
        case xCONS.PERM_STORE_HELPER.REPORT_COMPLETE_SALES:
          const numberOfBitx02 = value ? 2 : 13
          newCanDo.report_order = value ? util.orBit(numberOfBitx02, reportOrder) : util.andBit(numberOfBitx02, reportOrder)
          return newCanDo
        case xCONS.PERM_STORE_HELPER.REPORT_SALES_BY_CHANNELS:
          const numberOfBitx03 = value ? 4 : 11
          newCanDo.report_order = value ? util.orBit(numberOfBitx03, reportOrder) : util.andBit(numberOfBitx03, reportOrder)
          return newCanDo
        case xCONS.PERM_STORE_HELPER.REPORT_PEAK_ACCOUNT:
          const numberOfBitx04 = value ? 8 : 7
          newCanDo.report_order = value ? util.orBit(numberOfBitx04, reportOrder) : util.andBit(numberOfBitx04, reportOrder)
          return newCanDo
        default:
          return newCanDo
      }
    } else if (_.includes(xCONS.REPORT_GROUP_KEY.REPORT_INVENTORY, key)) {
      const reportInvertoryr = canDo.report_inventory
      let numberOfBitx = value ? 1 : 14
      switch (key) {
        case xCONS.PERM_STORE_HELPER.REPORT_STOCK:
          newCanDo.report_inventory = value ? util.orBit(numberOfBitx, reportInvertoryr) : util.andBit(numberOfBitx, reportInvertoryr)
          return newCanDo
        case xCONS.PERM_STORE_HELPER.REPORT_PRODUCT_DAILY_SALES:
          numberOfBitx = value ? 2 : 13
          newCanDo.report_inventory = value ? util.orBit(numberOfBitx, reportInvertoryr) : util.andBit(numberOfBitx, reportInvertoryr)
          return newCanDo
        case xCONS.PERM_STORE_HELPER.REPROT_STOCK_CARD:
          numberOfBitx = value ? 4 : 11
          newCanDo.report_inventory = value ? util.orBit(numberOfBitx, reportInvertoryr) : util.andBit(numberOfBitx, reportInvertoryr)
          return newCanDo
        case xCONS.PERM_STORE_HELPER.REPORT_STOCK_HISTORY:
          numberOfBitx = value ? 8 : 7
          newCanDo.report_inventory = value ? util.orBit(numberOfBitx, reportInvertoryr) : util.andBit(numberOfBitx, reportInvertoryr)
          return newCanDo
        default:
          return newCanDo
      }
    } else if (_.includes(xCONS.REPORT_GROUP_KEY.REPORT_SHIPPING, key)) {
      const reportShiping = canDo.report_shipping
      let numberOfBitx = value ? 1 : 2
      switch (key) {
        case xCONS.PERM_STORE_HELPER.REPORT_SHIPPED:
          newCanDo.report_shipping = value ? util.orBit(numberOfBitx, reportShiping) : util.andBit(numberOfBitx, reportShiping)
          return newCanDo
        case xCONS.PERM_STORE_HELPER.REPORT_REQUISITION:
          numberOfBitx = value ? 2 : 1
          newCanDo.report_shipping = value ? util.orBit(numberOfBitx, reportShiping) : util.andBit(numberOfBitx, reportShiping)
          return newCanDo
        default:
          return newCanDo
      }
    } else if (_.includes(xCONS.REPORT_GROUP_KEY.REPORT_RESELLER, key)) {
      const reportReseller = canDo.report_reseller
      const numberOfBitx = value ? 1 : 0
      switch (key) {
        case xCONS.PERM_STORE_HELPER.REPORT_RESELLER:
          newCanDo.report_reseller = value ? util.orBit(numberOfBitx, reportReseller) : util.andBit(numberOfBitx, reportReseller)
          return newCanDo
        default:
          return newCanDo
      }
    } else if (_.includes(xCONS.REPORT_GROUP_KEY.REPORT_HELPER, key)) {
      const reportHelper = canDo.report_helper
      switch (key) {
        case xCONS.PERM_STORE_HELPER.REPORT_HELPER:
          const numberOfBitx = value ? 1 : 0
          newCanDo.report_helper = value ? util.orBit(numberOfBitx, reportHelper) : util.andBit(numberOfBitx, reportHelper)
          return newCanDo
        default:
          return newCanDo
      }
    } else if (_.includes(xCONS.REPORT_GROUP_KEY.REPORT_PAYMENT, key)) {
      const reportPayment = canDo.report_payment
      switch (key) {
        case xCONS.PERM_STORE_HELPER.REPORT_PAYMENT:
          const numberOfBitx = value ? 1 : 0
          newCanDo.report_payment = value ? util.orBit(numberOfBitx, reportPayment) : util.andBit(numberOfBitx, reportPayment)
          return newCanDo
        default:
          return newCanDo
      }
    }
  }

  _seleteCheckBoxBtn = async (key: string, value: boolean) => {
    const { canDo, selectedAllCheckBox, mode } = this.state
    if (mode === MODE.VIEW) {
      return
    }
    let newCanDo = _.clone(canDo)
    const newSelectedAllCheckBox = _.clone(selectedAllCheckBox)

    if (key === xCONS.PERM_STORE_HELPER.PRODUCT_CODE_EDIT_UPC || key === xCONS.PERM_STORE_HELPER.PRODUCT_CODE_EDIT_SKU) {
      newCanDo[xCONS.PERM_STORE_HELPER.PRODUCT_CODE_EDIT] = this._setStateFromSKUorUPC(key, value)
    } else if (_.includes(xCONS.REPORT_GROUP_KEY.ALL, key)) {
      newCanDo = this._setStateFromReport(key, value, newCanDo)
    } else if (xCONS.DASHBOARD_PERM_REPORTS.bin_dashboard === key) {
      newCanDo = await this._setStateForDashboard(key, value, newCanDo)
      // console.log('newCanDo => ', newCanDo)
    } else {
      newCanDo[key] = value
    }
    const res = await this._checkSelectedAllCheckBox(key, newCanDo)
    // console.log('newSelectedAllCheckBox => ', newSelectedAllCheckBox)
    // console.log('res => ', res)
    newSelectedAllCheckBox[res.xkey] = res.value
    util.setStatePromise(this, { canDo: newCanDo, selectedAllCheckBox: newSelectedAllCheckBox })
  }

  _findSKUAndUPCCheckBox = (key: string) => {
    const { canDo } = this.state
    const productCost = canDo.product_code_edit
    switch (key) {
      case xCONS.PERM_STORE_HELPER.PRODUCT_CODE_EDIT_UPC:
        return util.checkBitBinaries(productCost, 1)
      case xCONS.PERM_STORE_HELPER.PRODUCT_CODE_EDIT_SKU:
        return util.checkBitBinaries(productCost, 2)
      default:
        return 3
    }
  }

  _findDashboardCheckBox = (key: string) => {
    const { canDo } = this.state
    const dashboard = canDo.bin_dashboard
    switch (key) {
      case xCONS.DASHBOARD_PERM_REPORTS.bin_dashboard:
        return util.checkBitBinaries(dashboard, 1)
      // case xCONS.PERM_STORE_HELPER.PRODUCT_CODE_EDIT_SKU:
      //   return util.checkBitBinaries(dashboard, 1)
      default:
        return 0
    }
  }

  _findReportCheckBox = (key: string) => {
    const { canDo } = this.state
    if (_.includes(xCONS.REPORT_GROUP_KEY.REPORT_ORDER, key)) {
      const reportOrder = canDo.report_order
      switch (key) {
        case xCONS.PERM_STORE_HELPER.REPORT_DAILY_SALES:
          return util.checkBitBinaries(reportOrder, xCONS.REPORT_SET_BIT_BINARY.report_daily_sales)
        case xCONS.PERM_STORE_HELPER.REPORT_COMPLETE_SALES:
          return util.checkBitBinaries(reportOrder, xCONS.REPORT_SET_BIT_BINARY.report_complete_sales)
        case xCONS.PERM_STORE_HELPER.REPORT_SALES_BY_CHANNELS:
          return util.checkBitBinaries(reportOrder, xCONS.REPORT_SET_BIT_BINARY.report_sales_by_channels)
        case xCONS.PERM_STORE_HELPER.REPORT_PEAK_ACCOUNT:
          return util.checkBitBinaries(reportOrder, xCONS.REPORT_SET_BIT_BINARY.report_peak_account)
        default:
          return 7
      }
    } else if (_.includes(xCONS.REPORT_GROUP_KEY.REPORT_INVENTORY, key)) {
      const reportInvertory = canDo.report_inventory
      switch (key) {
        case xCONS.PERM_STORE_HELPER.REPORT_STOCK:
          return util.checkBitBinaries(reportInvertory, xCONS.REPORT_SET_BIT_BINARY.report_stock)
        case xCONS.PERM_STORE_HELPER.REPORT_PRODUCT_DAILY_SALES:
          return util.checkBitBinaries(reportInvertory, xCONS.REPORT_SET_BIT_BINARY.report_product_daily_sales)
        case xCONS.PERM_STORE_HELPER.REPROT_STOCK_CARD:
          return util.checkBitBinaries(reportInvertory, xCONS.REPORT_SET_BIT_BINARY.report_stock_card)
        case xCONS.PERM_STORE_HELPER.REPORT_STOCK_HISTORY:
          return util.checkBitBinaries(reportInvertory, xCONS.REPORT_SET_BIT_BINARY.report_stock_history)
        default:
          return 7
      }
    } else if (_.includes(xCONS.REPORT_GROUP_KEY.REPORT_SHIPPING, key)) {
      const reportShipping = canDo.report_shipping
      switch (key) {
        case xCONS.PERM_STORE_HELPER.REPORT_SHIPPED:
          return util.checkBitBinaries(reportShipping, xCONS.REPORT_SET_BIT_BINARY.report_stock)
        case xCONS.PERM_STORE_HELPER.REPORT_REQUISITION:
          return util.checkBitBinaries(reportShipping, xCONS.REPORT_SET_BIT_BINARY.report_product_daily_sales)
        default:
          return 3
      }
    } else if (_.includes(xCONS.REPORT_GROUP_KEY.REPORT_RESELLER, key)) {
      const reportReseller = canDo.report_reseller
      switch (key) {
        case xCONS.PERM_STORE_HELPER.REPORT_RESELLER:
          return util.checkBitBinaries(reportReseller, xCONS.REPORT_SET_BIT_BINARY.report_reseller)
        default:
          return 3
      }
    } else if (_.includes(xCONS.REPORT_GROUP_KEY.REPORT_HELPER, key)) {
      const reportHelper = canDo.report_helper
      switch (key) {
        case xCONS.PERM_STORE_HELPER.REPORT_HELPER:
          return util.checkBitBinaries(reportHelper, xCONS.REPORT_SET_BIT_BINARY.report_helper)
        default:
          return 3
      }
    } else if (_.includes(xCONS.REPORT_GROUP_KEY.REPORT_PAYMENT, key)) {
      const reportPayment = canDo.report_payment
      switch (key) {
        case xCONS.PERM_STORE_HELPER.REPORT_PAYMENT:
          return util.checkBitBinaries(reportPayment, xCONS.REPORT_SET_BIT_BINARY.report_payment)
        default:
          return 3
      }
    }
  }

  _onPressArrow = (key: string) => {
    const { arrowFunctionShowAndHide } = this.state
    const newData = _.clone(arrowFunctionShowAndHide)
    newData[key] = !arrowFunctionShowAndHide[key]
    util.setStatePromise(this, { arrowFunctionShowAndHide: newData })
  }

  _onPressSeletedAll = (key: string, value: boolean) => {
    const { selectedAllCheckBox, canDo, mode } = this.state
    if (mode === MODE.VIEW) {
      return
    }
    const newSelectedAllCheckBox = selectedAllCheckBox
    const newCanDo = canDo
    if (key === HEADER_CONSTANT.PRODUCT) {
      newSelectedAllCheckBox[key] = _.isNil(value) ? false : !value
      GROUP_ITEM[key].map((data: string) => {
        if (data === 'product_code_edit') {
          const newValue = _.isNil(value) ? false : !value
          newCanDo.product_code_edit = newValue ? 3 : 0
        } else {
          newCanDo[data] = _.isNil(value) ? false : !value
        }
      })
    } else if (key === HEADER_CONSTANT.REPORT) {
      newSelectedAllCheckBox[key] = _.isNil(value) ? false : !value
      const newValue = _.isNil(value) ? false : !value
      newCanDo.report_order = newValue ? 15 : 0
      newCanDo.report_inventory = newValue ? 15 : 0
      newCanDo.report_shipping = newValue ? 3 : 0
      newCanDo.report_reseller = newValue ? 1 : 0
      newCanDo.report_helper = newValue ? 1 : 0
      newCanDo.report_payment = newValue ? 1 : 0
    } else if (key === HEADER_CONSTANT.BIN_DASHBOARD) {
      newSelectedAllCheckBox[key] = _.isNil(value) ? false : !value
      const newValue = _.isNil(value) ? false : !value
      newCanDo.bin_dashboard = newValue ? 1 : 0
    } else {
      newSelectedAllCheckBox[key] = _.isNil(value) ? false : !value
      GROUP_ITEM[key].map((data: string) => {
        newCanDo[data] = _.isNil(value) ? false : !value
      })
    }

    util.setStatePromise(this, { selectedAllCheckBox: newSelectedAllCheckBox, canDo: newCanDo })
  }

  _addPermission = async () => {
    const { addPermisson, selectedStore } = this.props
    const { departmentName, departmentNameDescription, canDo } = this.state
    const store_id = selectedStore.get('id')
    if (_.isNil(store_id)) {
      p.op.showConfirmationOkOnly('เกิดข้อผิดพลาด', 'กรุณาทำรายการใหม่ภายหลัง')
      return null
    }
    if (_.isNil(departmentName) || departmentName === '') {
      p.op.showConfirmationOkOnly('ข้อมูลไม่ครบถ้วน', 'กรุณาระบุ "ชื่อสิทธิ์การใช้งาน" ก่อนทำการบันทึก')
      return null
    }
    await util.setStatePromise(this, { loading: true })
    const res: ResponesPermissionList = await new Promise((resolve) => {
      addPermisson({
        body: {
          store_id,
          name: departmentName,
          description: departmentNameDescription,
          perf: canDo,
        },
        successCallback: resolve,
        failedCallback: resolve,
      })
    })

    // const resFromJS = fromJS(res)
    // const response = resFromJS.size > 0 ? (_.isNil(resFromJS.get('permissions')) ? null : resFromJS.get('permissions').toJS()) : null
    let response = null
    if (_.isArray(res.permissions)) {
      response = res.permissions[0]
    }
    this._callBackToAssistanGroupList()
    await util.setStatePromise(this, { loading: false })
    return response
  }

  _editPermission = async () => {
    const { editPermisson, selectedStore } = this.props
    const { departmentName, departmentNameDescription, canDo, permissionID } = this.state
    // console.log('canDo // => ', canDo)
    // return
    const store_id = selectedStore.get('id')
    if (_.isNil(store_id) || _.isNil(permissionID)) {
      p.op.showConfirmationOkOnly('เกิดข้อผิดพลาด', 'กรุณาทำรายการใหม่ภายหลัง')
      return null
    }
    if (_.isNil(departmentName) || departmentName === '') {
      p.op.showConfirmationOkOnly('ข้อมูลไม่ครบถ้วน', 'กรุณาระบุ "ชื่อสิทธิ์การใช้งาน" ก่อนทำการบันทึก')
      return null
    }
    const res: IPermission[] = await new Promise((resolve) => {
      editPermisson({
        body: {
          store_id,
          permission_id: permissionID,
          name: departmentName,
          description: departmentNameDescription,
          perf: canDo,
        },
        successCallback: resolve,
        failedCallback: resolve,
      })
    })
    const resFromJS = fromJS(res)
    // console.log('resFromJS => ', resFromJS)
    const response = resFromJS.size > 0 ? (_.isNil(resFromJS.get('permissions')) ? null : resFromJS.get('permissions').toJS()) : null
    this._callBackToAssistanGroupList()
    return response[0]
  }

  _callBackToAssistanGroupList = (outView?: boolean) => {
    // // README ::
    // // outView = null => call back เมื่อไรที่หน้า list permission มีการ render ใหม่ จะทำการ โหลดข้อมูลอีกครั้ง
    // // outView = true => สั้ง call back ให้ re render หน้า list permission ทันที
    // // outView = false => สั้ง call back ให้ โหลดข้อูลใหม่ หน้า list permission ทันที (ใช้ตอนลบ permission เพราะว่าต้องปิดหน้าตัวเองทิ้ง)
    // const { state } = this.props.navigation
    // const { callBack } = state.params
    const callBack = util.getNavParam(this.props, 'callBack')
    if (_.isFunction(callBack)) {
      if (_.isNil(outView)) {
        callBack()
      } else {
        callBack(outView)
      }
    }
  }

  _deletePermissionConfirm = async () => {
    const { deletePermission, selectedStore, navigation } = this.props
    const { permissionID } = this.state
    const store_id = selectedStore.get('id')
    if (_.isNil(store_id) || _.isNil(permissionID)) {
      p.op.showConfirmationOkOnly('เกิดข้อผิดพลาด', 'กรุณาทำรายการใหม่ภายหลัง')
      return null
    }
    const res: IPermission[] = await new Promise((resolve) => {
      deletePermission({
        body: {
          store_id,
          permission_id: permissionID,
        },
        successCallback: resolve,
        failedCallback: resolve,
      })
    })
    this._callBackToAssistanGroupList(false)
    this._goBack()
  }

  _deletePermission = async () => {
    // let deleted = false
    const { departmentName } = this.state
    p.op.showConfirmation(`ลบ ${departmentName}`, 'คุณต้องการลบใช่หรือไม่', () => {
      this._deletePermissionConfirm()
    }),
      () => {},
      'ใช่',
      'ยกเลิก'
  }
}
