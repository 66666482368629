import { connect } from 'react-redux'
import { getState, getDispatch } from 'x/modules/order/MarketplaceOrderViewConnect'
import ErpOrderView from './ErpOrderView'

export default connect(
  (state) => ({
    ...getState(state),
  }),
  (dispatch) => ({
    ...getDispatch(dispatch),
  })
)(ErpOrderView)
