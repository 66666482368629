import React from 'react'

import _ from 'lodash'
// import { COLORS, STYLES } from '../../config/styles'
import { COLORS } from '../../config/styles'
import p from '../../config/platform-specific'
import * as util from '../../utils/util'

import { BaseStoreSettingPaperangProps, BaseStoreSettingPaperangState } from '../../index'

const {
  BG_LIGHT_GREY_ALTERNATIVE,
  TEXT_PRIMARY,
  TEXT_ACTIVE,
  TEXT_INACTIVE,
  APP_MAIN,
  APP_SECONDARY,
  FORM_SUCCESS,
  FORM_ERROR,
  TEXT_ACTIVE_DARK,
  BRAND_Info,
  BRAND_Danger,
  BRAND_Primary,
  BRAND_Success,
  BRAND_Warning,
} = COLORS

const checkBox = 'checkBox'
const defaultPaperrangType = [
  {
    id: 57,
    url: 'https://bit.ly/3h7vrUS',
    txt: `กว้าง 57 mm (Paperang รุ่น P1 P1s P2 และ P2s)`,
  },
  {
    id: 1121,
    url: 'https://bit.ly/3h913th',
    txt: `กว้าง 112mm (Paperang รุ่น C1 Max)`,
  },
  {
    id: 1122,
    url: 'https://bit.ly/2B7nsrO',
    txt: `กว้าง 112mm (Paperang รุ่น C1 Max) โดยหมุนรูปแนบใบปะหน้าเป็นแนวนอน เพื่อประหยัดกระดาษ`,
  },
]

const DEFAULT_PAPERANG_TYPE_ID = 57

export default abstract class BaseStoreSettingPaperang extends React.Component<
  BaseStoreSettingPaperangProps,
  BaseStoreSettingPaperangState
> {
  inProcess: boolean

  OPTIONS_LIST_COVER_SHEET: any[]

  PAPERRANG_TYPE: { id: number; url: string; txt: string; help?: JSX.Element }[]

  abstract _goBack(): any

  protected constructor(props) {
    super(props)

    this.state = {
      printSetting: null,
      isActive: false,
      showSenderNameAndAddress: false,
      showProductListWhenNoShippingLabelImage: false,
      showProductListWhenHasShippingLabelImage: false,
      autoSavePaperang: false,
      printingTypeId: DEFAULT_PAPERANG_TYPE_ID,
    }

    this.inProcess = false
    this.OPTIONS_LIST_COVER_SHEET = [
      { type: checkBox, text: 'ชื่อและที่อยู่ผู้ส่ง', value: false, key: 0 },
      { type: checkBox, text: 'รายการสินค้า', value: false, key: 1 },
    ]
    this.PAPERRANG_TYPE = defaultPaperrangType
    // this._goBack = this._goBack.bind(this)
    // this._onChangeDateRange = this._onChangeDateRange.bind(this)
    // this._isVisibleDateRangePicker = this._isVisibleDateRangePicker.bind(this)
    // this._downloadReportRequisitionUrl = this._downloadReportRequisitionUrl.bind(this)
    // this._computeAllowDays = this._computeAllowDays.bind(this)
    // this._getTxtDateRangeDescription = this._getTxtDateRangeDescription.bind(this)
  }

  async componentDidMount() {
    const { selectedStore } = this.props
    const paperang = selectedStore.get('printing_paperang')
    await this.config(paperang)
  }

  config = async (paperangData) => {
    if (_.isNil(paperangData)) {
      p.op.showConfirmationOkOnly('เกิดข้อผิดพลาด', 'กรุณาติดต่อทีมงาน Xselly')
      this._goBack()
      return
    }
    const paperang = paperangData.toJS()
    const isActive = paperang.a
    const showSenderNameAndAddress = paperang.p.s !== 0
    const showProductListWhenNoShippingLabelImage = !(_.isNil(paperang.p.p) || paperang.p.p === 0)
    const showProductListWhenHasShippingLabelImage = !(_.isNil(paperang.p.q) || paperang.p.q === 0)
    let autoSavePaperang = paperang.p.i !== 0
    let printingTypeId = DEFAULT_PAPERANG_TYPE_ID
    // ถ้ารูปแบบใบปะหน้ามีค่ามากว่า 1 แปลว่า เป็น i ตัวใหม่ ที่ไม่ได้มีเอาไว้เซต autoSavePaperang
    if (!_.isNil(paperang.p.i) && paperang.p.i > 1) {
      autoSavePaperang = paperang.p.m !== 0
      printingTypeId = paperang.p.i
    }

    this.props.navigation.setParams({
      cancelBtn: () => this.cancelButton(),
      onChange: false,
      saveBtn: () => this.saveButton(),
    })

    util.setStatePromise(this, {
      isActive,
      showSenderNameAndAddress,
      showProductListWhenNoShippingLabelImage,
      showProductListWhenHasShippingLabelImage,
      printSetting: paperang,
      autoSavePaperang,
      printingTypeId,
    })
  }

  onChangeValue = (key, value) => {
    this.props.navigation.setParams({
      onChange: true,
    })
    util.setStatePromise(this, {
      [key]: value,
    })
  }

  onChangeSelectedValue = (paperangType: { id: number; url: string; txt: string }) => {
    const { printSetting } = this.state
    const newPrintSetting = printSetting
    newPrintSetting.p.i = paperangType.id
    util.setStatePromise(this, {
      printSetting: newPrintSetting,
      printingTypeId: paperangType.id,
    })
    this.props.navigation.setParams({
      onChange: true,
    })
  }

  cancelButton = () => {
    // const { navigation } = this.props
    // const { state } = navigation
    // const { onChange } = state.params
    const onChange = util.getNavParam(this.props, 'onChange')
    if (onChange) {
      p.op.showConfirmation(
        'ยกเลิกการแก้ไข',
        'ยกเลิกการแก้ไขใช่หรือไม่',
        () => this._goBack(),
        () => {},
        'ใช่',
        'แก้ไขต่อ'
      )
    } else {
      this._goBack()
    }
  }

  saveButton = () => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    const { addPrinting, selectedStore, editPrinting } = this.props
    // log('BaseStoreSettingPaperang', this.state)
    const {
      showSenderNameAndAddress,
      isActive,
      showProductListWhenNoShippingLabelImage,
      showProductListWhenHasShippingLabelImage,
      printSetting,
      autoSavePaperang,
      printingTypeId,
    } = this.state
    const store_id = selectedStore.get('id')
    // IMPORTANT MUST handle case true and 1 below as the value could be either one
    const isShowSenderNameAndAddressSelected = util.isTrueOr1(showSenderNameAndAddress)
    const isProductListSelected = util.isTrueOr1(showProductListWhenNoShippingLabelImage)
    let isShowProductListWhenNoShippingLabelImage = util.isTrueOr1(showProductListWhenHasShippingLabelImage)
    // ถ้า size 57 q จะไม่ถูกเปิดใช้งานเสมอ
    if (printingTypeId === 57) {
      isShowProductListWhenNoShippingLabelImage = false
    }
    const isActiveA4 = selectedStore.has('s_use_printing_pdf') ? selectedStore.get('s_use_printing_pdf') : true
    const isActiveSticker = selectedStore.has('s_use_printing_sticker_pdf') ? selectedStore.get('s_use_printing_sticker_pdf') : true

    if (!isActiveA4 && !isActiveSticker && !isActive) {
      p.op.showConfirmationOkOnly(
        p.op.t('printing.warning.WARNING_CANT_CLOSS_LAST_PRINTING.title'),
        p.op.t('printing.warning.WARNING_CANT_CLOSS_LAST_PRINTING.message')
      )
      return
    }

    const payload = {
      body: {
        store_id,
        printing_type_id: 1,
        size: 2,
        is_active: isActive,
        pref: {
          s: isShowSenderNameAndAddressSelected ? 1 : 0,
          p: isProductListSelected ? 1 : 0,
          q: isShowProductListWhenNoShippingLabelImage ? 1 : 0,
          l: 'th',
          i: printingTypeId,
          m: autoSavePaperang ? 1 : 0,
        },
      },
      successCallback: (res) => this.successCallback(res),
      failedCallback: (err) => this.failedCallback(),
    }

    if (_.isNil(printSetting.id)) {
      addPrinting(payload)
    } else {
      // @ts-ignore
      payload.body.printing_id = printSetting.id
      editPrinting(payload)
    }
  }

  successCallback = (res) => {
    this.props.navigation.setParams({
      onChange: false,
    })
    const printing = res.store_printing
    util.setStatePromise(this, {
      printSetting: printing,
      isActive: printing.a,
      showSenderNameAndAddress: printing.p.s,
      showProductList: printing.p.p,
      autoSavePaperang: printing.p.m,
      printingTypeId: printing.p.i,
    })
    p.op.showToast('บันทึกข้อมูลเรียบร้อยแล้ว', 'success')
    this.inProcess = false
  }

  failedCallback = () => {
    p.op.showToast('เกิดข้อผิดพลาดกรุณาทำรายการใหม่ภายหลัง', 'warning')
    this.props.navigation.setParams({
      onChange: false,
    })
    this.inProcess = false
  }
}
