import React from 'react'

import BaseUIAddCoverSheet from 'xui/modules/order/BaseUIAddCoverSheet'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import ThemeSizeContext from '../../context/ThemeSizeContext'
import ImgManager from '../../components/ImgManager'

export default class AddCoverSheet extends BaseUIAddCoverSheet {
  static displayName = 'AddCoverSheet'

  static contextType = ThemeSizeContext

  context!: React.ContextType<typeof ThemeSizeContext>

  // Override
  renderMain = () => {
    const { contentOneOfThreeColumnWidthStyle, contentHeightStyle } = this.context

    // return (
    //   <div>
    //     <div>{JSON.stringify(contentOneOfThreeColumnWidthStyle)}</div>
    //     <div>{JSON.stringify(contentHeightStyle)}</div>
    //     <div>yo</div>
    //   </div>
    // )
    return (
      <XContainer>
        {this.renderHeader()}
        <XContent>{this.renderContent()}</XContent>
      </XContainer>
    )
  }

  getFixedWebLayoutTextLimitWidth = () => this.context.contentOneOfThreeColumnWidth - 60

  getImgManagerComponent = () => ImgManager
}
