import React from 'react'
import { IInputProps, IBoxProps, themeTools } from 'native-base'
import Box from 'xui/components/Box'
import {
  Platform,
  TextInput,
  NativeSyntheticEvent,
  TextInputFocusEventData,
  KeyboardTypeOptions,
  StyleSheet,
  TextInputProps,
} from 'react-native'
import { ResponsiveValue } from 'native-base/lib/typescript/components/types'
import { ISizes } from 'x/config/nativebase/base/sizes'
import { getComputedCustomStyledProps } from 'x/config/nativebase/makeStyledComponent'
import theme from 'x/config/nativebase/theme'
import _, { merge } from 'lodash'
import { getColor } from 'x/config/nativebase/styled-system'
import XText from './XText'
import HStack from './HStack'
import VStack from './VStack'

const { stylingProps, extractInObject } = themeTools

// const MONEY_REGEX = /^[0-9]+([.][0-9]{0,2})?$/
const FLOAT_REGEX = /^[0-9]+([.][0-9]*)?$/
const INTEGER_REGEX = /^\d+$/

// import { Input, Item } from 'native-base'
// import { COLORS, STYLES } from 'x/config/styles'
// import { isDiffAccuracy, isDiffObject, setStatePromise, delay } from 'x/utils/util'

type INBInputProps = TextInputProps & IBoxProps & IInputProps
export interface XInputBaseProps extends INBInputProps {
  // style?: { [key: string]: any }
  // value?: string // actually string|number
  // ref?: (ref?: any) => void
  // align?: 'left' | 'center' | 'right'
  isDisabled?: boolean
  disabled?: boolean
  isMoney?: boolean
  isInteger?: boolean
  isNumber?: boolean
  // validationType?: 'integer' | 'float' | 'money'

  InputLeftElement?: JSX.Element | React.ReactNode
  InputRightElement?: JSX.Element | React.ReactNode

  _input?: IBoxProps
}

export interface IXInputProps extends XInputBaseProps {
  unitLabel?: string
  unitLabelWidth?: ResponsiveValue<ISizes>
  unitPosition?: 'left' | 'right'
  unitContainerProps?: IBoxProps
  // wrapperProps?: IInputGroupProps

  /**
   * Vertically align text when `multiline` is set to true
   */
  textAlignVertical?: 'auto' | 'top' | 'bottom' | 'center'
}

// const StyledTextInput = makeStyledComponent(TextInput, 'Box')

const XInputBase = React.forwardRef<TextInput, XInputBaseProps>((props, parentRef) => {
  // console.log('XInputBase props => ', props)
  const {
    isInteger,
    isNumber,
    isMoney,
    value,
    textAlign,
    multiline = false,
    // variant,
    onChangeText,
    onBlur,
    onFocus,
    keyboardType,
    _input = {},
    // _focus = {},
    disabled = false,
    isDisabled = false,
    editable = true,
    placeholderTextColor: ptc = 'text.400',
    selectionColor: sc = 'primary.600',
    ...restProps
  } = props

  const placeholderTextColor = getColor(ptc, theme.colors, theme)
  const selectionColor = getColor(sc, theme.colors, theme)

  const hasValidation = isMoney || isNumber || isInteger
  const fixedAndroidCannotScrollWhenRightAligned = textAlign === 'right' && Platform.OS === 'android'

  const _onValidationChangeText = React.useCallback(
    (newText: string) => {
      // console.log('parentRef => ', parentRef)
      // console.log('childRef => ', childRef)
      // console.log(`FLOAT_REGEX.test(${newText}) => `, FLOAT_REGEX.test(newText))
      // console.log(`MONEY_REGEX.test(${newText}) => `, MONEY_REGEX.test(newText))
      // console.log(`INTEGER_REGEX.test(${newText}) => `, INTEGER_REGEX.test(newText))

      if ((isMoney || isNumber || isInteger) && newText === '') {
        onChangeText('0')
        return
      }

      if ((isNumber || isMoney) && !FLOAT_REGEX.test(newText)) {
        onChangeText(value)
        return
      }

      // if (isMoney && !MONEY_REGEX.test(newText)) {
      //   onChangeText(value)
      //   return
      // }

      if (isInteger && !INTEGER_REGEX.test(newText)) {
        onChangeText(value)
        return
      }

      if (typeof onChangeText === 'function') {
        onChangeText(newText)
      }
    },
    [isInteger, isNumber, isMoney, value, onChangeText]
  )

  const _onValidationBlur = React.useCallback(
    (evt: NativeSyntheticEvent<TextInputFocusEventData>) => {
      if (disabled) {
        return
      }

      if ((isNumber || isMoney) && FLOAT_REGEX.test(value)) {
        let normalizedNumber: string | number = parseFloat(value)
        normalizedNumber = isMoney ? normalizedNumber.toFixed(2) : normalizedNumber
        // normalizedNumber = normalizedNumber.toString()
        // @ts-ignore
        normalizedNumber = parseFloat(normalizedNumber).toString()
        onChangeText(normalizedNumber)
      }

      if (isInteger && FLOAT_REGEX.test(value)) {
        const normalizedNumber = parseInt(value).toString()
        onChangeText(normalizedNumber)
      }

      if ((isMoney || isNumber || isInteger) && value === '') {
        onChangeText('0')
      }

      // if (typeof onBlur === 'function') {
      //   onBlur(evt)
      // }
    },
    [isInteger, isNumber, isMoney, value, onChangeText, onBlur, disabled]
  )

  const _onFocus = React.useCallback(
    (evt: any) => {
      // setIsFocused(true)

      if (_.isFunction(onFocus)) {
        onFocus(evt)
      }
    },
    [onFocus]
  )

  // const _onBlur = React.useCallback(
  const _onBlur = (evt: NativeSyntheticEvent<TextInputFocusEventData>) => {
    // setIsFocused(false)

    if (_.isFunction(onBlur)) {
      onBlur(evt)
    }
    if (hasValidation) {
      _onValidationBlur(evt)
    }
  }
  //   [onBlur, hasValidation]
  // )

  const _getValidationKeyboardType = (): KeyboardTypeOptions => {
    if (isNumber || isMoney) {
      return 'decimal-pad'
    }

    if (isInteger) {
      return 'number-pad'
    }

    return keyboardType
  }

  // let localStyledProps = _input
  // if (isFocused) {
  //   localStyledProps = _.merge(localStyledProps, _focus)
  // }

  return (
    // @ts-ignore
    <TextInput
      // @ts-ignore
      ref={parentRef}
      editable={!disabled && !isDisabled && editable}
      // ref={mergeRefs([_ref, parentRef])}
      // _disabled={{
      //   color: useColorModeValue('coolGray.800', 'warmGray.50'),
      // }}
      // bg={useColorModeValue('primary.100', 'primary.700')}
      // selectionColor={useColorModeValue('primary.200', 'primary.800')}
      // @ts-ignore
      multiline={fixedAndroidCannotScrollWhenRightAligned || multiline}
      // textAlign={textAlign}
      // variant={variant || 'filled'}
      // variant='filled'
      keyboardType={_getValidationKeyboardType()}
      placeholderTextColor={placeholderTextColor}
      selectionColor={selectionColor}
      value={value || ''}
      selectTextOnFocus={hasValidation}
      onChangeText={hasValidation && onChangeText ? _onValidationChangeText : onChangeText}
      onFocus={_onFocus}
      onBlur={_onBlur}
      // onBlur={hasValidation ? _onValidationBlur : onBlur}
      {..._input}
      // {...localStyledProps}
      {...restProps}
    />
  )
})

XInputBase.displayName = 'XInputBase'

// const DELIMETER = '.'

type IUnitElement = IBoxProps & {
  label: string
}

const UnitElement: React.FC<IUnitElement> = ({ label, w = '7', style = {}, ...restProps }) =>
  label ? (
    <Box
      // position='absolute'
      bg='transparent'
      w={w}
      justifyItems='center'
      alignItems='center'
      // mx='1'
      {...restProps}
      style={StyleSheet.flatten(style)}>
      <XText variant='inactive' _dark={{ color: 'muted.500' }}>
        {label}
      </XText>
    </Box>
  ) : null

function mergeRefs<T = any>(refs: Array<React.MutableRefObject<T> | React.LegacyRef<T>>): React.RefCallback<T> {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value)
      } else if (ref != null) {
        ;(ref as React.MutableRefObject<T | null>).current = value
      }
    })
  }
}

const XInput = React.forwardRef<TextInput, IXInputProps>((baseProps, ref) => {
  // const _ref = React.createRef<TextInput>()
  const [isFocused, setIsFocused] = React.useState(false)
  // const { children, style } = props

  const { computedStyledProps, underscoreProps, restProps, style } = getComputedCustomStyledProps(baseProps, 'Input')
  // console.log('XInput computedStyledProps => ', computedStyledProps)
  // console.log('XInput underscoreProps => ', underscoreProps)
  // console.log('XInput restProps => ', restProps)

  const [layoutProps, nonLayoutProps] = extractInObject(computedStyledProps, [
    ...stylingProps.margin,
    ...stylingProps.border,
    ...stylingProps.layout,
    ...stylingProps.flexbox,
    ...stylingProps.position,
    ...stylingProps.background,
    'shadow',
    'opacity',
  ])

  const { _input = {}, _focus = {}, _disabled = {} } = underscoreProps
  let mergeNonLayoutProps = merge(nonLayoutProps, _input)

  if (nonLayoutProps.disabled || nonLayoutProps.isDisabled || nonLayoutProps.editable === false) {
    mergeNonLayoutProps = merge(mergeNonLayoutProps, _disabled)
  }

  const mergedInputProps = merge(restProps, mergeNonLayoutProps)
  const { styleFromProps, restProps: restRNProps } = getComputedCustomStyledProps(mergedInputProps, 'Box')

  // const { _input = {}, _icon = {} } = underscoreProps

  // const themeProps = useThemeProps('Input', props)
  const {
    isMoney,
    unitLabel,
    // wrapperProps = {},
    // unitLabelWidth = 'xs',
    unitLabelWidth = '7',
    unitPosition = 'left',
    unitContainerProps = {},
    InputLeftElement,
    InputRightElement,
    // style = {},
    //   ...restProps
    // } = props

    onFocus,
    onBlur,

    ...restInputProps
  } = restRNProps

  const _onFocus = React.useCallback(
    (evt: any) => {
      setIsFocused(true)

      if (_.isFunction(onFocus)) {
        onFocus(evt)
      }
    },
    [onFocus]
  )

  const _onBlur = React.useCallback(
    (evt: NativeSyntheticEvent<TextInputFocusEventData>) => {
      setIsFocused(false)

      if (_.isFunction(onBlur)) {
        onBlur(evt)
      }
    },
    [onBlur]
  )

  // // label container's layout
  // const [lcLayout, setLcLayout] = React.useState<LayoutRectangle>()
  // // label's layout
  // const [ulbLayout, setUlbLayout] = React.useState<LayoutRectangle>()

  let inputLabel = unitLabel
  if (typeof unitLabel === 'undefined' && isMoney) {
    inputLabel = '฿'
  }

  // const _onLabelContainerLayout = (evt: LayoutChangeEvent) => {
  //   setLcLayout(evt.nativeEvent.layout)
  // }
  // const _onUnitLabelLayout = (evt: LayoutChangeEvent) => {
  //   setUlbLayout(evt.nativeEvent.layout)
  // }

  // if (false) {
  //   return (
  //     <InputGroup
  //       // w={{
  //       //   base: '70%',
  //       //   md: '285',
  //       // }}
  //       {...wrapperProps}>
  //       {unitPosition === 'left' ? (
  //         <InputLeftAddon w={unitLabelWidth} {...unitContainerProps}>
  //           {inputLabel}
  //         </InputLeftAddon>
  //       ) : null}
  //       <XInputBase
  //         ref={ref}
  //         // w={{
  //         //   base: "70%",
  //         //   md: "100%",
  //         // }}
  //         isMoney={isMoney}
  //         {...restProps}
  //       />
  //       {unitPosition === 'right' ? (
  //         <InputRightAddon w={unitLabelWidth} {...unitContainerProps}>
  //           {inputLabel}
  //         </InputRightAddon>
  //       ) : null}
  //     </InputGroup>
  //   )
  // }

  // if (typeof inputLabel === 'string' && inputLabel !== '') {
  // const [layoutProps, nonLayoutProps] = extractInObject(themeProps, [
  // const [layoutProps, nonLayoutProps] = extractInObject(restProps, [
  // const [layoutProps, nonLayoutProps] = extractInObject(computedStyledProps, [
  //   ...stylingProps.margin,
  //   ...stylingProps.border,
  //   ...stylingProps.layout,
  //   ...stylingProps.flexbox,
  //   ...stylingProps.position,
  //   ...stylingProps.background,
  //   'shadow',
  //   'opacity',
  // ])
  // const injectLabelPaddingProps: any = {}
  // if (ulbLayout && ulbLayout.width) {
  //   if (unitPosition === 'left') {
  //     injectLabelPaddingProps.pl = `${ulbLayout.width + 4}px`
  //   }
  //   if (unitPosition === 'right') {
  //     injectLabelPaddingProps.pr = `${ulbLayout.width + 4}px`
  //   }
  // }
  // console.log('layoutProps =>  ', layoutProps)

  return (
    <HStack
      // space='1'
      alignItems='center'
      // borderRadius='lg'
      // overflow='hidden'
      // overflowY='auto'
      // minH='9'
      {...layoutProps}
      {...(isFocused && !baseProps.disabled && !baseProps.isDisabled ? _focus : {})}
      // onLayout={_onLabelContainerLayout}
      style={StyleSheet.flatten(style)}>
      {typeof inputLabel === 'string' && inputLabel !== '' && unitPosition === 'left' ? (
        <UnitElement
          // onLayout={_onUnitLabelLayout}
          label={inputLabel}
          w={unitLabelWidth}
          // left='0'
          // h={lcLayout && lcLayout.height ? lcLayout.height : undefined}
          {...unitContainerProps}
          // style={lcLayout && lcLayout.height ? { height: lcLayout.height } : undefined}
        />
      ) : (
        InputLeftElement
      )}

      <VStack flex={1} h='full'>
        <XInputBase
          ref={ref}
          // ref={mergeRefs([_ref, ref])}
          // w={{
          //   base: "70%",
          //   md: "100%",
          // }}
          // InputLeftElement={unitPosition === 'left' ? <UnitElement label={inputLabel} {...unitContainerProps} /> : undefined}
          // InputRightElement={unitPosition === 'right' ? <UnitElement label={inputLabel} {...unitContainerProps} /> : undefined}
          isMoney={isMoney}
          // {...injectLabelPaddingProps}
          // {...nonLayoutProps}
          style={[
            styleFromProps,
            // {
            //   flex: 1,
            //   h: '100%',
            //   padding: 4,
            //   borderWidth: 0,
            //   fontFamily: 'Prompt-Regular',
            //   backgroundColor: 'transparent',
            //   outlineWidth: 0,
            //   outline: 'none',
            // },
            StyleSheet.flatten(style),
          ]}
          {...restInputProps}
          onFocus={_onFocus}
          onBlur={_onBlur}
        />
      </VStack>

      {typeof inputLabel === 'string' && inputLabel !== '' && unitPosition === 'right' ? (
        <UnitElement
          // onLayout={_onUnitLabelLayout}
          label={inputLabel}
          // right='0'
          // h={lcLayout && lcLayout.height ? lcLayout.height : undefined}
          {...unitContainerProps}
          // style={lcLayout && lcLayout.height ? { height: lcLayout.height } : undefined}
        />
      ) : (
        InputRightElement
      )}
    </HStack>
  )
  // }

  // return <XInputBase ref={ref} {...restProps} />
})

// XInput.displayName = 'XInput'
// XInput.defaultProps = getComponentDefaultProps('Input')
// export default XInput as React.FC<IXInputProps>

// const TextInputMock = React.forwardRef<TextInput, IXInputProps>((props, ref) => {
//   const { style = {}, ...restProps } = props

//   return <TextInput ref={ref} style={[{ height: 44 }, style]} {...restProps} />
// })

// const StyledXInput = makeStyledComponent(XInput, 'Input')
XInput.displayName = 'XInput'

// @ts-ignore
XInput.defaultProps = { theme }

// @ts-ignore
// export default XInput as React.FC<IXInputProps>

export default XInput as React.ComponentClass<TextInputProps & IXInputProps>
