import { connect } from 'react-redux'
import ProfileView from './ProfileView'
// import { NavigationActions } from 'react-navigation'

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
export default connect(
  (state) => ({
    profile: state.get('profile'),
    img_uris: state.getIn(['imgBlockTasks', 'img_uris']),
    thumbnail_uris: state.getIn(['imgBlockTasks', 'thumbnail_uris']),
    img_status: state.getIn(['imgBlockTasks', 'taskStatus']),
    // imgBlockTasks: state.get('imgBlockTasks').toJS(),
  }),
  (dispatch) => ({
    // navigate: bindActionCreators(NavigationActions.navigate, dispatch),
    dispatch,
    // ProfileActions: bindActionCreators(ProfileActions, dispatch),
  })
)(ProfileView)
