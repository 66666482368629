import { IApiOptions, IStoreWebsite } from 'x/types'
// import CONS from 'x/config/constants'

import api from './api'

interface ICreateStoreWebsiteRequestBody {
  store_id: number

  name: string
  type: 'sale_page ' | 'catalog_page' | 'storefront' | string
}

// https://dd.xselly.com/doc/api-create-website-x9oztmSR0D
export async function createStoreWebsite(params: ICreateStoreWebsiteRequestBody): Promise<IStoreWebsite> {
  const { store_id, name, type } = params
  if (!store_id) {
    throw new Error('createStoreWebsite:: Need store_id')
  }

  if (!name) {
    throw new Error('createStoreWebsite:: Need name')
  }

  if (!type) {
    throw new Error('createStoreWebsite:: Need type')
  }

  const apiOptions: IApiOptions = {
    showSpinner: true,
    messages: {
      successMsg: 'สร้างเว็บไซต์สำเร็จ',
      errorMsg: 'สร้างเว็บไซต์ล้มเหลว กรุณาลองกดใหม่อีกครั้ง',
    },
    axiosOptions: {
      retry: 0,
      timeout: 60000,
    },
    isApiV2: true,
  }

  const reqBody: ICreateStoreWebsiteRequestBody = { ...params }

  try {
    const res = await api.post<ICreateStoreWebsiteRequestBody, IStoreWebsite>('store/website/create', reqBody, apiOptions)
    return res
  } catch (err) {
    console.log('err => ', err)
    throw err
  }
}

type IDeleteStoreWebsiteRequestBody = {
  store_id: number
  website_id: number
}

type IDeleteStoreWebsiteResponse = {
  status: 'ok'
}

// https://dd.xselly.com/doc/api-delete-store-website-Wcj9N3DM0W
export async function deleteStoreWebsite(params: IDeleteStoreWebsiteRequestBody): Promise<boolean> {
  const { store_id, website_id } = params
  if (!store_id) {
    throw new Error('deleteStoreWebsite:: Need store_id')
  }

  if (!website_id) {
    throw new Error('deleteStoreWebsite:: Need website_id')
  }

  const apiOptions: IApiOptions = {
    showSpinner: true,
    messages: {
      successMsg: 'ลบเว็บไซต์สำเร็จ',
      errorMsg: 'ลบเว็บไซต์ล้มเหลว กรุณาลองกดใหม่อีกครั้ง',
    },
    axiosOptions: {
      retry: 0,
      timeout: 60000,
    },
    isApiV2: true,
  }

  const reqBody: IDeleteStoreWebsiteRequestBody = { ...params }

  try {
    const res = await api.post<IDeleteStoreWebsiteRequestBody, IDeleteStoreWebsiteResponse>('store/website/delete', reqBody, apiOptions)

    if (res.status === 'ok') {
      return true
    }
  } catch (err) {
    console.log('err => ', err)
    // throw err
  }

  return false
}

interface IUpdateStoreWebsiteRequestBody {
  store_id: number
  website_id: number

  // FIXME: เดี๋ยวต้องเปลี่ยน type any ออก
  config_json?: any

  name?: string
  img_url?: string

  /**
   * เช่น เปลื่ยนจาก https://xsalepage.com เป็น https://xshoppingforyou.com
      โดยใน UI จะมี dropdown list of available domains ให้เลือก
   */
  website_domain?: string

  /**
   * ถ้าส่งมาคือให้หลังบ้าน reset hash และ website_url ให้ใหม่
   */
  reset_hash?: boolean
}

// https://dd.xselly.com/doc/api-update-store-website-LfhDQ86wTY
export async function updateStoreWebsite(params: IUpdateStoreWebsiteRequestBody): Promise<IStoreWebsite> {
  const { store_id, website_id } = params
  if (!store_id) {
    throw new Error('updateStoreWebsite:: Need store_id')
  }

  if (!website_id) {
    throw new Error('updateStoreWebsite:: Need website_id')
  }

  const apiOptions: IApiOptions = {
    showSpinner: true,
    messages: {
      successMsg: 'อัพเดทเว็บไซต์สำเร็จ',
      errorMsg: 'อัพเดทเว็บไซต์ล้มเหลว กรุณาลองกดใหม่อีกครั้ง',
    },
    axiosOptions: {
      retry: 0,
      timeout: 60000,
    },
    isApiV2: true,
  }

  const reqBody: IUpdateStoreWebsiteRequestBody = { ...params }

  try {
    const res = await api.post<IUpdateStoreWebsiteRequestBody, IStoreWebsite>('store/website/update', reqBody, apiOptions)
    return res
  } catch (err) {
    console.log('err => ', err)
    throw err
  }
}

type IGetStoreWebsiteRequestBody = {
  store_id: number
  website_id: number
}

// https://dd.xselly.com/doc/api-delete-store-website-Wcj9N3DM0W
export async function getStoreWebsite(params: IGetStoreWebsiteRequestBody): Promise<IStoreWebsite> {
  const { store_id, website_id } = params
  if (!store_id) {
    throw new Error('getStoreWebsite:: Need store_id')
  }

  if (!website_id) {
    throw new Error('getStoreWebsite:: Need website_id')
  }

  const apiOptions: IApiOptions = {
    showSpinner: true,
    // messages: {
    //   successMsg: 'ลบเว็บไซต์สำเร็จ',
    //   errorMsg: 'ลบเว็บไซต์ล้มเหลว กรุณาลองกดใหม่อีกครั้ง',
    // },
    axiosOptions: {
      retry: 0,
      timeout: 60000,
    },
    isApiV2: true,
  }

  const reqBody: IGetStoreWebsiteRequestBody = {
    store_id,
    website_id,
  }

  try {
    const res = await api.post<IGetStoreWebsiteRequestBody, IStoreWebsite>('store/website/get', reqBody, apiOptions)

    return res
  } catch (err) {
    console.log('err => ', err)
    throw err
  }
}

type IPublishStoreWebsiteRequestBody = {
  store_id: number
  website_id: number
}

// https://dd.xselly.com/doc/api-publish-store-website-EXtvZcJjHW
export async function publistStoreWebsite(params: IPublishStoreWebsiteRequestBody): Promise<IStoreWebsite> {
  const { store_id, website_id } = params
  if (!store_id) {
    throw new Error('publistStoreWebsite:: Need store_id')
  }

  if (!website_id) {
    throw new Error('publistStoreWebsite:: Need website_id')
  }

  const apiOptions: IApiOptions = {
    showSpinner: true,
    messages: {
      successMsg: 'เผยแพร่เว็บไซต์สำเร็จ',
      errorMsg: 'เผยแพร่เว็บไซต์ล้มเหลว กรุณาลองกดใหม่อีกครั้ง',
    },
    axiosOptions: {
      retry: 0,
      timeout: 60000,
    },
    isApiV2: true,
  }

  const reqBody: IPublishStoreWebsiteRequestBody = { ...params }

  try {
    const res = await api.post<IPublishStoreWebsiteRequestBody, IStoreWebsite>('store/website/delete', reqBody, apiOptions)
    return res
  } catch (err) {
    console.log('err => ', err)
    throw err
  }
}
