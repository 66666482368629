import React, { Component } from 'react'
import _ from 'lodash'
import { ICategory, BaseCategoryFilterViewProps, BaseCategoryFilterViewState } from 'x/index'
import * as util from 'x/utils/util'
import { Drawer } from 'native-base'
import { setStatePromise } from '../utils/util'

export default abstract class BaseCategoryFilterView extends Component<BaseCategoryFilterViewProps, BaseCategoryFilterViewState> {
  // drawerRef?: React.RefObject<Drawer>
  loadProps: boolean

  drawerCatRef?: React.RefObject<typeof Drawer>

  constructor(props: BaseCategoryFilterViewProps) {
    super(props)
    // this.drawerRef = React.createRef()
    this.state = {
      allChildren: true,
      categoryList: null,
      levelCategoryList: null,

      webIsVisibleFilterModal: false,
      channels: util.getMKPChannels(),
      ug_ids: [],
    }

    this.loadProps = true
    this.drawerCatRef = React.createRef()
  }

  // componentDidMount = async () => {
  // const { selectedCategory, categoryList, levelCategoryList } = this.props
  // const lvCat = levelCategoryList
  // console.log(`lvCat`, lvCat)
  // await setStatePromise(this, {
  //   selectedCategory,
  //   categoryList,
  //   levelCategoryList: lvCat,
  // })
  // }

  // UNSAFE_componentWillReceiveProps = async (nextprops: BaseCategoryFilterViewProps) => {
  //   // if (this.loadProps) {
  //   if (!_.isNil(nextprops.levelCategoryList)) {
  //     const newLevelCategoryList = nextprops.levelCategoryList
  //     await setStatePromise(this, {
  //       // selectedCategory: nextprops.selectedCategory,
  //       // categoryList: nextprops.categoryList,
  //       levelCategoryList: newLevelCategoryList,
  //     })
  //     //   this.loadProps = false
  //     // }
  //   }
  // }

  static getDerivedStateFromProps(nextProps: BaseCategoryFilterViewProps, prevState) {
    // do things with nextProps.someProp and prevState.cachedSomeProp
    if (!_.isNil(nextProps.levelCategoryList)) {
      const newLevelCategoryList = nextProps.levelCategoryList
      return {
        levelCategoryList: newLevelCategoryList,
        // ... other derived state properties
      }
    }
    return {}
  }

  _seletedCategory = async (category: ICategory) => {
    const { levelCategoryList } = this.state
    const newLvlelCat = []
    levelCategoryList.map((data: ICategory) => {
      if (data.id === category.id) {
        const newCat = data
        newCat.selected = _.isNil(data.selected) ? true : !data.selected
        newLvlelCat.push(newCat)
      } else {
        const newCat = data
        newCat.selected = false
        newLvlelCat.push(newCat)
      }
    })
    await setStatePromise(this, {
      levelCategoryList: newLvlelCat,
    })
  }

  _onPressSubmitBtn = async () => {
    const { levelCategoryList, allChildren, ug_ids } = this.state
    const { onSubmit, onRequestCloseView } = this.props
    let selectedCat = null
    levelCategoryList.map((data: ICategory) => {
      if (data.selected) {
        selectedCat = data
      }
    })
    if (_.isFunction(onSubmit)) {
      const callBack = {
        category: selectedCat,
        allChildrenCategory: allChildren,
      }

      if (ug_ids.length > 0) {
        // @ts-ignore
        callBack.ug_ids = ug_ids
      }

      await onSubmit(callBack)
      this.loadProps = true
    }

    if (_.isFunction(onRequestCloseView)) {
      onRequestCloseView()
    }
    this.close()
  }

  _clearAllSelected = async () => {
    const { levelCategoryList } = this.state
    const newLevelCategoryList = []
    levelCategoryList.map((data: ICategory) => {
      const xData = data
      xData.selected = false
      newLevelCategoryList.push(xData)
    })
    await setStatePromise(this, {
      levelCategoryList: newLevelCategoryList,
      channels: util.getMKPChannels(),
      ug_ids: [],
    })
  }

  _setAllChildrenCategory = () => {
    const { allChildren } = this.state
    setStatePromise(this, { allChildren: !allChildren })
  }

  openFilterModal = () => {
    this.setState({ webIsVisibleFilterModal: true })
  }

  closeFilterModal = () => {
    this.setState({ webIsVisibleFilterModal: false })
  }

  close = () => {
    // if (p.op.isWeb()) {
    this.closeFilterModal()
    // } else {
    //   const drawerCatRef = this.drawerCatRef.current
    //   // @ts-ignore ignore property of native-base
    //   if (drawerCatRef && drawerCatRef._root && _.isFunction(drawerCatRef._root.close)) {
    //     // @ts-ignore ignore property of native-base
    //     drawerCatRef._root.close()
    //   }
    // }

    // const { onCloseFilter } = this.props
    // if (_.isFunction(onCloseFilter)) {
    //   onCloseFilter()
    // }
  }

  open = async () => {
    // if (p.op.isWeb()) {
    this.openFilterModal()
    // } else {
    //   const drawerCatRef = this.drawerCatRef.current
    //   // @ts-ignore ignore property of native-base
    //   if (drawerCatRef && drawerCatRef._root && _.isFunction(drawerCatRef._root.open)) {
    //     // @ts-ignore ignore property of native-base
    //     drawerCatRef._root.open()
    //   }
    // }
  }
}
