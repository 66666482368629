import React from 'react'
import _ from 'lodash'

import * as util from 'x/utils/util'

import { IXShippingConfig } from 'x/config/app'
import api from 'x/utils/api'
import { IXScreenProps } from 'x/types'

export interface BaseStoreSettingXShippingEcoPostViewProps extends IXScreenProps {
  selectedStore: any
  getXShippingConfigFromRedux: IXShippingConfig[]
  setXShippingConfig: (newConfig: IXShippingConfig[]) => void
}

export interface BaseStoreSettingXShippingEcoPostViewState {
  // eCo-Post
  useEcoPost: boolean
  useAutoShippingIsEcoPostReadyToShip: boolean
  loadingEcoPostWidth: number
  loadingEcoPostHeight: number
  isEcoPostLoading: boolean

  // eCo-Post COD
  useEcoPostCod: boolean
  useAutoShippingIsEcoPostCodReadyToShip: boolean
  loadingEcoPostCodWidth: number
  loadingEcoPostCodHeight: number
  isEcoPostCodLoading: boolean
  useAutoCreateCODPaymentWhenDelivered: boolean
}

const STATE_KEY_ECOPOST_FOR_UPDATE = {
  useEcoPost: 'useEcoPost',
  useAutoShippingIsEcoPostReadyToShip: 'useAutoShippingIsEcoPostReadyToShip',
}

const STATE_KEY_ECOPOST_COD_FOR_UPDATE = {
  useEcoPostCod: 'useEcoPostCod',
  useAutoShippingIsEcoPostCodReadyToShip: 'useAutoShippingIsEcoPostCodReadyToShip',
  useAutoCreateCODPaymentWhenDelivered: 'useAutoCreateCODPaymentWhenDelivered',
}

export default abstract class BaseStoreSettingXShippingEcoPostView extends React.Component<
  BaseStoreSettingXShippingEcoPostViewProps,
  BaseStoreSettingXShippingEcoPostViewState
> {
  // DISPLAY_TABS: IUserListTab[]
  // _tabRef: React.RefObject<any>
  // helperProfileId: number

  protected constructor(props) {
    super(props)

    this.state = {
      // eCo-Post
      useEcoPost: false,
      useAutoShippingIsEcoPostReadyToShip: true,
      isEcoPostLoading: false,
      loadingEcoPostWidth: 0,
      loadingEcoPostHeight: 0,

      // eCo-Post COD
      useEcoPostCod: false,
      useAutoShippingIsEcoPostCodReadyToShip: true,
      isEcoPostCodLoading: false,
      loadingEcoPostCodHeight: 0,
      loadingEcoPostCodWidth: 0,
      useAutoCreateCODPaymentWhenDelivered: true,
    }
    // this._tabRef = React.createRef()
  }

  async componentDidMount() {
    const { getXShippingConfigFromRedux } = this.props
    this._setConfig(getXShippingConfigFromRedux)
  }

  _setConfig = async (config: IXShippingConfig[]) => {
    await this.setEcoPostConfig(config)
    await this.setEcoPostCodConfig(config)
  }

  setEcoPostConfig = async (config: IXShippingConfig[]) => {
    if (_.isNil(config)) {
      return
    }
    if (_.isArray(config) && config.length > 0) {
      // eCo-Post
      let useEcoPost = false
      let useAutoShippingIsEcoPostReadyToShip = false
      config.forEach((configData: IXShippingConfig) => {
        if (configData.shipping_type_id === 71 && configData.system_id === 8) {
          // eCo-Post
          useEcoPost = true
          useAutoShippingIsEcoPostReadyToShip = configData.set_auto_request_xshipping_on_create_order
            ? configData.set_auto_request_xshipping_on_create_order
            : false
        }
      })
      await util.setStatePromise(this, {
        // eCo-Post
        useEcoPost,
        useAutoShippingIsEcoPostReadyToShip,
      })
    }
  }

  setEcoPostCodConfig = async (config: IXShippingConfig[]) => {
    if (_.isNil(config)) {
      return
    }
    // console.log('config => ', config)
    if (_.isArray(config) && config.length > 0) {
      // eCo-Post COD
      let useEcoPostCod = false
      let useAutoShippingIsEcoPostCodReadyToShip = false
      let useAutoCreateCODPaymentWhenDelivered = false
      config.forEach((configData: IXShippingConfig) => {
        if (configData.shipping_type_id === 72 && configData.system_id === 8) {
          // eCo-Post COD
          useEcoPostCod = true
          useAutoShippingIsEcoPostCodReadyToShip = configData.set_auto_request_xshipping_on_create_order
            ? configData.set_auto_request_xshipping_on_create_order
            : false
          useAutoCreateCODPaymentWhenDelivered = configData.create_cod_payment_when_delivered
            ? configData.create_cod_payment_when_delivered
            : false
        }
      })
      await util.setStatePromise(this, {
        // eCo-Post COD
        useEcoPostCod,
        useAutoShippingIsEcoPostCodReadyToShip,
        useAutoCreateCODPaymentWhenDelivered,
      })
    }
  }

  _setNewConfig = async (newValue: { isValue: boolean; stateKey: string }) => {
    // console.log('newValue => ', newValue)
    const { selectedStore, navigation, setXShippingConfig } = this.props
    const body: { [key: string]: any } = {
      store_id: selectedStore.get('id'),
    }

    let apiMethod = api.patchV2
    const STATE_KEY = newValue.stateKey
    const IS_VALUE = newValue.isValue

    /// //////////////////////// START eCo-Post ///////////////////////////
    if (STATE_KEY === STATE_KEY_ECOPOST_FOR_UPDATE.useEcoPost) {
      await util.setStatePromise(this, {
        isEcoPostLoading: true,
      })
      body.shipping_type_id = 71
      body.system_id = 8
      // body.set_auto_request_xshipping_on_create_order = IS_VALUE
    }
    if (STATE_KEY === STATE_KEY_ECOPOST_FOR_UPDATE.useAutoShippingIsEcoPostReadyToShip) {
      await util.setStatePromise(this, {
        isEMSLoading: true,
      })
      body.shipping_type_id = 71
      body.system_id = 8
      body.set_auto_request_xshipping_on_create_order = IS_VALUE
    }
    /// //////////////////////// STOP eCo-Post ///////////////////////////

    /// //////////////////////// START eCo-Post COD ///////////////////////////
    if (STATE_KEY === STATE_KEY_ECOPOST_COD_FOR_UPDATE.useEcoPostCod) {
      await util.setStatePromise(this, {
        isEMSCodLoading: true,
      })
      body.shipping_type_id = 72
      body.system_id = 8
      // body.set_auto_request_xshipping_on_create_order = IS_VALUE
    }
    if (STATE_KEY === STATE_KEY_ECOPOST_COD_FOR_UPDATE.useAutoShippingIsEcoPostCodReadyToShip) {
      await util.setStatePromise(this, {
        isEMSCodLoading: true,
      })
      body.shipping_type_id = 72
      body.system_id = 8
      body.set_auto_request_xshipping_on_create_order = IS_VALUE
    }
    if (STATE_KEY === STATE_KEY_ECOPOST_COD_FOR_UPDATE.useAutoCreateCODPaymentWhenDelivered) {
      await util.setStatePromise(this, {
        isEMSCodLoading: true,
      })
      body.shipping_type_id = 72
      body.system_id = 8
      body.create_cod_payment_when_delivered = IS_VALUE
    }
    /// //////////////////////// STOP eCo-Post COD ///////////////////////////

    const isMainBtn = STATE_KEY === STATE_KEY_ECOPOST_FOR_UPDATE.useEcoPost || STATE_KEY === STATE_KEY_ECOPOST_COD_FOR_UPDATE.useEcoPostCod
    // console.log('isMainBtn => ', isMainBtn)
    // isMainBtn ถ้าเป้นปุ่มหลัก แล้วกดปุ่มปิดใช้งาน ให้ยิง api ไป delV2 เพื่อลบ
    if (isMainBtn && !IS_VALUE) {
      apiMethod = api.delV2
    }
    // isMainBtn ถ้าเป้นปุ่มหลัก แล้วกดปุ่มเปิดใช้งาน ให้ยิง api ไป putV2 เพื่อสร้าง
    if (isMainBtn && IS_VALUE) {
      body.set_auto_request_xshipping_on_create_order = true
      apiMethod = api.putV2
    }

    const response: { xshipping_config: IXShippingConfig[] } = await apiMethod(api.XSHIPPING_CONFIG, body)
    if (response.xshipping_config) {
      setXShippingConfig(response.xshipping_config)
      await this._setConfig(response.xshipping_config)
      // const { state } = navigation
      // const { onPressGoBack } = state.params
      // // console.log('onPressGoBack => ', onPressGoBack)
      const onPressGoBack = util.getNavParam(this.props, 'onPressGoBack')
      if (_.isFunction(onPressGoBack)) {
        onPressGoBack()
      }
    }

    // UN SET LOADING //
    if (
      STATE_KEY === STATE_KEY_ECOPOST_FOR_UPDATE.useEcoPost ||
      STATE_KEY === STATE_KEY_ECOPOST_FOR_UPDATE.useAutoShippingIsEcoPostReadyToShip
    ) {
      await util.setStatePromise(this, {
        isEcoPostLoading: false,
      })
    }

    if (
      STATE_KEY === STATE_KEY_ECOPOST_COD_FOR_UPDATE.useEcoPostCod ||
      STATE_KEY === STATE_KEY_ECOPOST_COD_FOR_UPDATE.useAutoShippingIsEcoPostCodReadyToShip ||
      STATE_KEY === STATE_KEY_ECOPOST_COD_FOR_UPDATE.useAutoCreateCODPaymentWhenDelivered
    ) {
      await util.setStatePromise(this, {
        isEcoPostCodLoading: false,
      })
    }

    // console.log('response => ', response)
  }

  isSwitchOnChange = async (newValue: { isValue: boolean; stateKey: string }) => {
    await this._setNewConfig(newValue)
  }
}
