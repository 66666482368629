import NB from '../../nativebase'

const baseStyle = (props: Record<string, any>) => ({
  // color: mode('muted.800', 'muted.100')(props),
  // _light: {
  //   color: NB.C.L.TEXT.ACTIVE,
  // },
  // _dark: {
  //   color: NB.C.D.TEXT.ACTIVE,
  // },
})

const variantPrimary = (props) => ({
  _light: {
    color: NB.C.L.TEXT.PRIMARY,
  },
  _dark: {
    color: NB.C.D.TEXT.PRIMARY,
  },
})

const variantActive = (props) => ({
  _light: {
    color: NB.C.L.TEXT.ACTIVE,
  },
  _dark: {
    color: NB.C.D.TEXT.ACTIVE,
  },
})

const variantInactive = (props) => ({
  _light: {
    color: NB.C.L.TEXT.INACTIVE,
  },
  _dark: {
    color: NB.C.D.TEXT.INACTIVE,
  },
})

const variantActiveDark = (props) => ({
  _light: {
    color: NB.C.L.TEXT.ACTIVE_DARK,
  },
  _dark: {
    color: NB.C.D.TEXT.ACTIVE_DARK,
  },
})

const variantDanger = (props) => ({
  _light: {
    color: NB.C.L.TEXT.DANGER,
  },
  _dark: {
    color: NB.C.D.TEXT.DANGER,
  },
})

const variantWarning = (props) => ({
  _light: {
    color: NB.C.L.TEXT.WARNING,
  },
  _dark: {
    color: NB.C.D.TEXT.WARNING,
  },
})

const variantSuccess = (props) => ({
  _light: {
    color: NB.C.L.TEXT.SUCCESS,
  },
  _dark: {
    color: NB.C.D.TEXT.SUCCESS,
  },
})

const variants = {
  primary: variantPrimary,
  active: variantActive,
  inactive: variantInactive,
  activeDark: variantActiveDark,
  danger: variantDanger,
  warning: variantWarning,
  success: variantSuccess,
}

const sizes = {
  xxs: 2,
  xs: 4,
  sm: 6,
  md: 8,
  lg: 10,
  xl: 12,
  '2xl': 16,
  '3xl': 20,
  '4xl': 24,
  '5xl': 32,
  '6xl': 64,
}

const defaultProps = {
  size: 'sm',
  variant: 'active',
}

export default {
  baseStyle,
  defaultProps,
  variants,
  sizes,
}
