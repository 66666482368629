// common usage
// import * as StoreActions from '../../modules/report/ReportState'
import { Map, fromJS } from 'immutable'
import { takeLatest, put, call, fork, all } from 'redux-saga/effects'
// import _ from 'lodash'
import p from 'x/config/platform-specific'
import _ from 'lodash'
import moment from 'moment'
import actions from '../../config/actions'
import api from '../../utils/api'
import { log } from '../../utils/util'
import xCONS from '../../config/constants'
import { ActionApiParams } from '../../index'

require('moment-timezone')

export default function* watchAllReports() {
  yield all([fork(watchDailySalesReport)])
}

function* watchDailySalesReport() {
  log('In watchDailySalesReport')
  yield takeLatest(actions.FETCH_REPORT_DAILY_SALES, fetchDailySalesReport)
}

// function* fetchDailySalesReport(action: { type: string; payload: any }) {
function* fetchDailySalesReport(action: ActionApiParams) {
  const apiOptions = {
    showSpinner: true,
  }
  try {
    // @ts-ignore
    // const res = yield call(api.post, api.POST_REPORT_DAILY_SALES, action.payload, apiOptions)
    const res = yield call(api.post, api.POST_REPORT_DAILY_SALES, action.body, apiOptions)
    if (_.has(res, 'today') || _.has(res, 'past')) {
      // Load all response to redux
      // yield put({ type: actions.SET_REPORT_DAILY_SALES, payload: fromJS(res) })
      // yield call(log, 'before response from saga => ', res)
      yield call(handleResponseFromDailySalesReport, res, action.body)
      // yield call(log, 'after response from saga => ', res)
      if (_.isFunction(action.successCallback)) {
        action.successCallback(res)
      }
    } else {
      p.op.alert('เกิดข้อผิดพลาด', 'ไม่พบข้อมูลรายการจากเซิร์ฟเวอร์ กรุณาลองใหม่อีกครั้ง')
      throw new Error('No valid daily sales data.')
    }
  } catch (error) {
    // TODO: Handle this error action type
    log('fetchDailySalesReport error => ', error)
    yield put({ type: actions.MY_STORES_REQUEST_FAILED, error })
    if (_.isFunction(action.failedCallback)) {
      action.failedCallback(error)
    }
  }
}

// FIXME: O ประกาศ response types ด้วย
function* handleResponseFromDailySalesReport(res: any, reqBody: { [key: string]: any }) {
  // log('handleResponseFromDailySalesReport reqBody => ', reqBody)
  // log('handleResponseFromDailySalesReport before res => ', res)
  const { date, past, today } = res // default past.length = 7

  // generate empty data if backend doesn't return
  // log('reqBody =>', reqBody)
  // log('res =>', res)
  // if (_.isNil(reqBody.endDate) && _.isNil(reqBody.daysInPast)) {
  //   return
  // }
  // console.log(reqBody)
  let todayDate = date ? moment(date, xCONS.REPORT_DAILY_DATE_FORMAT) : moment()
  let daysInPast = reqBody.daysInPast && reqBody.daysInPast > 0 ? reqBody.daysInPast : 7
  let beginDate = todayDate.clone().subtract(daysInPast, 'd')

  if (!_.isNil(reqBody.endDate)) {
    todayDate = moment(reqBody.startDate, xCONS.REPORT_DAILY_DATE_FORMAT)
    daysInPast = moment(reqBody.endDate).diff(reqBody.startDate, 'day') + 1
    beginDate = moment(reqBody.startDate, xCONS.REPORT_DAILY_DATE_FORMAT)
  }

  // console.log('!!! todayDate => ', todayDate)
  // console.log('!!! daysInPast => ', daysInPast)
  // console.log('!!! beginDate => ', beginDate)
  // console.log('past.length => ', past.length)
  const preparedPastData = []
  let dateToIndexMap: Map<string, number> = Map({})
  const modifiedResponse = _.cloneDeep(res)

  // Generated empty report data and Modify old res before load if necessary
  if (past && past.length < daysInPast) {
    for (let i = 0; i < daysInPast; i++) {
      const txtFocusedDate = beginDate.clone().add(i, 'day').format(xCONS.REPORT_DAILY_DATE_FORMAT)
      // console.log('loop index i =>', i, 'txtFocusedDate => ', txtFocusedDate)
      const emptyData = { p: 0, f: 0, o: 0, d: txtFocusedDate }
      dateToIndexMap = dateToIndexMap.set(txtFocusedDate, i)
      preparedPastData.push(emptyData)
    }

    // log('dateToIndexMap => ', dateToIndexMap.toJS())

    past.forEach((pData) => {
      const preparedDataIndex = dateToIndexMap.get(pData.d)
      preparedPastData[preparedDataIndex] = pData
      preparedPastData[preparedDataIndex].p = _.isString(pData.p) ? parseFloat(pData.p.replace(/,/g, '')) : pData.p
      preparedPastData[preparedDataIndex].f = _.isString(pData.f) ? parseFloat(pData.f.replace(/,/g, '')) : pData.f
      preparedPastData[preparedDataIndex].o = _.isString(pData.o) ? parseFloat(pData.o.replace(/,/g, '')) : pData.o
    })

    // log('preparedPastData before sort => ', preparedPastData)
    // preparedPastData.sort((d1, d2) => {
    //   const date1 = moment(d1.d, xCONS.REPORT_DAILY_DATE_FORMAT)
    //   const date2 = moment(d2.d, xCONS.REPORT_DAILY_DATE_FORMAT)
    //   if (date1.isAfter(date2)) {
    //     return 1
    //   }
    //   if (date1.isBefore(date2)) {
    //     return -1
    //   }
    //   return 0
    // })
    // log('preparedPastData after sort => ', preparedPastData)

    // res.past = preparedPastData
    modifiedResponse.past = preparedPastData
    if (_.isObject(modifiedResponse.today)) {
      const { p, f, o } = modifiedResponse.today
      modifiedResponse.today.p = _.isString(p) ? parseFloat(p.replace(/,/g, '')) : p
      modifiedResponse.today.f = _.isString(f) ? parseFloat(f.replace(/,/g, '')) : f
      modifiedResponse.today.o = _.isString(o) ? parseFloat(o.replace(/,/g, '')) : o
    }
  }
  // log('modifiedResponse => ', modifiedResponse)
  // log('preparedPastData => ', preparedPastData)
  // log('handleResponseFromDailySalesReport after res => ', res)
  yield put({ type: actions.SET_REPORT_DAILY_SALES, payload: fromJS(modifiedResponse) })
}
