import { Component } from 'react'

import * as util from 'x/utils/util'

import { ISelectedStoreMap, IXScreenProps } from 'x/index'

interface IFileUpload {
  name: string
  lastModified: number
  webkitRelativePath: string
  size: number
  type: string
  path: string
}

export interface BaseImportReduceProductWarehouseBySKUProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
}

export interface BaseImportReduceProductWarehouseBySKUState {
  fileUpload: IFileUpload
  disabledDownloadTemplate: boolean
  isLoading: boolean
}

// interface IImportExcelMenuItem {
//   label: string
//   iconName?: string
//   iconFamily?: 'Ionicons' | 'FontAwesome' | 'MaterialIcons' | 'MaterialCommunityIcons' | 'AntDesign' | 'Foundation'
//   iconStyle?: Object
//   nav?: () => void
//   perm?: string | string[]
//   isHeader?: boolean
//   permBitIndex?: number
// }

export default abstract class BaseImportReduceProductWarehouseBySKU extends Component<
  BaseImportReduceProductWarehouseBySKUProps,
  BaseImportReduceProductWarehouseBySKUState
> {
  // abstract async handleOnDownloadFile(url: string, fileName: string): Promise<void>
  inProcess: boolean

  constructor(props) {
    super(props)
    this.state = {
      fileUpload: null,
      disabledDownloadTemplate: false,
      isLoading: false,
    }
    this.inProcess = false
  }

  _goBack = () => {
    util.navGoBack(this.props)
  }

  _setFileUpload = (acceptedFiles: IFileUpload[]) => {
    // console.log(acceptedFiles)
    util.setStatePromise(this, {
      fileUpload: acceptedFiles[0],
    })
  }

  _startLoading = async () => {
    this.inProcess = true
    await util.setStatePromise(this, {
      isLoading: true,
    })
  }

  _stopLoading = async () => {
    this.inProcess = false
    await util.setStatePromise(this, {
      isLoading: false,
    })
  }
}
