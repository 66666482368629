import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as StoreState from 'x/modules/store/StoreState'

import StoreSettingXShippingNinjaVanView from './StoreSettingXShippingNinjaVanView'
import { getSelectedStore, getXShippingConfigFromRedux } from 'x/redux/selectors'
import * as XShippingActions from 'x/modules/xshipping/XShippingState'

// import * as AddressActions from '../address/AddressState'

export default connect(
  (state) => {
    return {
      selectedStore: getSelectedStore(state),
      getXShippingConfigFromRedux: getXShippingConfigFromRedux(state)
    }
  },
  (dispatch) => {
    return {
      addNewStoreSetting: bindActionCreators(StoreState.addNewStoreSetting, dispatch),
      setXShippingConfig: bindActionCreators(XShippingActions.setXShippingConfig, dispatch),
      // addCustomOrder: bindActionCreators(PreferenceState.addCustomOrder, dispatch),
      // loadPrefUIOrderView: bindActionCreators(PreferenceState.loadPrefUIOrderView, dispatch),
    }
  }
  // @ts-ignore
)(StoreSettingXShippingNinjaVanView)
