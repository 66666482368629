import React from 'react'
import _ from 'lodash'

import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'

import { IXShippingConfig } from 'x/config/app'
import api from 'x/utils/api'
import { IXScreenProps } from 'x/types'

export interface BaseStoreSettingXShippingBestViewProps extends IXScreenProps {
  selectedStore: any
  getXShippingConfigFromRedux: IXShippingConfig[]
  setXShippingConfig: (newConfig: IXShippingConfig[]) => void
}

export interface BaseStoreSettingXShippingBestViewState {
  useBest: boolean
  useAutoXShippingIsBestReadyToShip: boolean
  loadingBestWidth: number
  loadingBestHeight: number
  useBestCOD: boolean
  isBestLoading: boolean

  useAutoXShippingIsBestCODReadyToShip: boolean
  useAutoCreateCODPaymentWhenDelivered: boolean
  loadingBestCODWidth: number
  loadingBestCODHeight: number
  isBestCODLoading: boolean
}

const STATE_KEY_FOR_UPDATE = {
  useBest: 'useBest',
  useAutoXShippingIsBestReadyToShip: 'useAutoXShippingIsBestReadyToShip',

  useBestCOD: 'useBestCOD',
  useAutoXShippingIsBestCODReadyToShip: 'useAutoXShippingIsBestCODReadyToShip',
  useAutoCreateCODPaymentWhenDelivered: 'useAutoCreateCODPaymentWhenDelivered',
}

export default abstract class BaseStoreSettingXShippingBestView extends React.Component<
  BaseStoreSettingXShippingBestViewProps,
  BaseStoreSettingXShippingBestViewState
> {
  // DISPLAY_TABS: IUserListTab[]
  // _tabRef: React.RefObject<any>
  // helperProfileId: number

  protected constructor(props) {
    super(props)

    this.state = {
      useBest: false,
      useAutoXShippingIsBestReadyToShip: true,
      loadingBestWidth: 0,
      loadingBestHeight: 0,
      isBestLoading: false,
      useBestCOD: false,
      useAutoXShippingIsBestCODReadyToShip: true,
      useAutoCreateCODPaymentWhenDelivered: true,
      loadingBestCODWidth: 0,
      loadingBestCODHeight: 0,
      isBestCODLoading: false,
    }
    // this._tabRef = React.createRef()
  }

  componentDidMount() {
    const { getXShippingConfigFromRedux } = this.props
    // const { state } = navigation
    // const { tabIndex, permissionList, permission_id } = state.params
    this.setBestConfig(getXShippingConfigFromRedux)
  }

  setBestConfig = async (config: IXShippingConfig[]) => {
    if (_.isNil(config)) {
      return
    }
    if (_.isArray(config) && config.length > 0) {
      let useBest = false
      let useAutoXShippingIsBestReadyToShip = false
      let useBestCOD = false
      let useAutoXShippingIsBestCODReadyToShip = false
      let useAutoCreateCODPaymentWhenDelivered = false
      config.map((config: IXShippingConfig) => {
        // if Best
        if (config.shipping_type_id === 35) {
          useBest = true
          useAutoXShippingIsBestReadyToShip = config.set_auto_request_xshipping_on_create_order
            ? config.set_auto_request_xshipping_on_create_order
            : false
          // if Best COD
        } else if (config.shipping_type_id === 36) {
          useBestCOD = true
          useAutoXShippingIsBestCODReadyToShip = config.set_auto_request_xshipping_on_create_order
            ? config.set_auto_request_xshipping_on_create_order
            : false
          useAutoCreateCODPaymentWhenDelivered = config.create_cod_payment_when_delivered ? config.create_cod_payment_when_delivered : false
        }
      })
      await util.setStatePromise(this, {
        useBest,
        useAutoXShippingIsBestReadyToShip,
        useBestCOD,
        useAutoXShippingIsBestCODReadyToShip,
        useAutoCreateCODPaymentWhenDelivered,
      })
    }
  }

  useBestOnChange = (newValue: boolean) => {
    util.setStatePromise(this, {
      useBest: newValue,
      isBestLoading: true,
    })
  }

  useAutoXShippingIsBestReadyToShipOnChange = (newValue: boolean) => {
    util.setStatePromise(this, {
      useAutoXShippingIsBestReadyToShip: newValue,
    })
  }

  useBestCODOnChange = (newValue: boolean) => {
    util.setStatePromise(this, {
      useBestCOD: newValue,
    })
  }

  useAutoXShippingIsBestCODReadyToShipOnChange = (newValue: boolean) => {
    util.setStatePromise(this, {
      useAutoXShippingIsBestCODReadyToShip: newValue,
    })
  }

  _setNewConfig = async (newValue: { isValue: boolean; stateKey: string }) => {
    const { selectedStore, navigation, setXShippingConfig } = this.props
    const body: { [key: string]: any } = {
      store_id: selectedStore.get('id'),
      system_id: 7,
    }
    let apiMethod = api.patchV2
    const STATE_KEY = newValue.stateKey
    const IS_VALUE = newValue.isValue
    if (STATE_KEY === STATE_KEY_FOR_UPDATE.useBest) {
      await util.setStatePromise(this, {
        isBestLoading: true,
      })
      body.shipping_type_id = 35
      if (IS_VALUE) {
        apiMethod = api.putV2
        body.set_auto_request_xshipping_on_create_order = true
      } else {
        apiMethod = api.delV2
      }
    } else if (STATE_KEY === STATE_KEY_FOR_UPDATE.useAutoXShippingIsBestReadyToShip) {
      await util.setStatePromise(this, {
        isBestLoading: true,
      })
      body.shipping_type_id = 35
      body.set_auto_request_xshipping_on_create_order = IS_VALUE
    } else if (STATE_KEY === STATE_KEY_FOR_UPDATE.useBestCOD) {
      // TODO: Remove this logic later when users can specify bank info for COD in app
      p.op.showConfirmationOkOnly('', 'หากต้องการ "เปิด" หรือ "ปิด" การออกเลขพัสดุ BEST Express [COD] กรุณาติดต่อทีมงานที่ LINE: @XSelly')
      // await util.setStatePromise(this, {
      //     useBestCOD: !newValue, // toggle the value back
      //   })
      return

      // if (IS_VALUE) { // show warning when users try to turn on the switch
      //   p.op.showConfirmationOkOnly('', 'หากต้องการเปิดใช้งาน BEST Express [COD] กรุณาติดต่อทีมงานที่ LINE: @XSelly เพื่อตั้งค่าบัญชีรับยอด COD จาก BEST โดยตรง')
      //   await util.setStatePromise(this, {
      //     useBestCOD: false,
      //   })
      // } else {
      //   await util.setStatePromise(this, {
      //     isBestCODLoading: true,
      //     useBestCOD: false,
      //   })
      //   apiMethod = api.delV2
      //   body.shipping_type_id = 36
      // }
      // return

      // await util.setStatePromise(this, {
      //   isBestCODLoading: true,
      // })
      // body.shipping_type_id = 36
      // if (IS_VALUE) {
      //   apiMethod = api.putV2
      //   body.set_auto_request_xshipping_on_create_order = true
      //   body.create_cod_payment_when_delivered = true
      // } else {
      //   apiMethod = api.delV2
      // }
    } else if (STATE_KEY === STATE_KEY_FOR_UPDATE.useAutoXShippingIsBestCODReadyToShip) {
      await util.setStatePromise(this, {
        isBestCODLoading: true,
      })
      body.shipping_type_id = 36
      body.set_auto_request_xshipping_on_create_order = IS_VALUE
    } else if (STATE_KEY === STATE_KEY_FOR_UPDATE.useAutoCreateCODPaymentWhenDelivered) {
      await util.setStatePromise(this, {
        isBestCODLoading: true,
      })
      body.shipping_type_id = 36
      body.create_cod_payment_when_delivered = IS_VALUE
    }

    const isMainBtn = STATE_KEY === STATE_KEY_FOR_UPDATE.useBest || STATE_KEY === STATE_KEY_FOR_UPDATE.useBestCOD
    if (isMainBtn && !IS_VALUE) {
      apiMethod = api.delV2
    }

    const response: { xshipping_config: IXShippingConfig[] } = await apiMethod(api.XSHIPPING_CONFIG, body)
    if (response.xshipping_config) {
      setXShippingConfig(response.xshipping_config)
      await this.setBestConfig(response.xshipping_config)
      // const { state } = navigation
      // const { onPressGoBack } = state.params
      // // console.log('onPressGoBack => ', onPressGoBack)
      const onPressGoBack = util.getNavParam(this.props, 'onPressGoBack')
      if (_.isFunction(onPressGoBack)) {
        onPressGoBack()
      }
    }

    if (STATE_KEY === STATE_KEY_FOR_UPDATE.useBest || STATE_KEY === STATE_KEY_FOR_UPDATE.useAutoXShippingIsBestReadyToShip) {
      await util.setStatePromise(this, {
        isBestLoading: false,
      })
    } else {
      await util.setStatePromise(this, {
        isBestCODLoading: false,
      })
    }

    // console.log('response => ', response)
  }

  isSwitchOnChange = async (newValue: { isValue: boolean; stateKey: string }) => {
    // console.log('isSwitchOnChange => ', newValue)

    // const STATE_KEY = newValue.stateKey
    // const IS_VALUE = newValue.isValue
    // let alertText = ''
    // if (STATE_KEY === STATE_KEY_FOR_UPDATE.useBest) {
    //   alertText = IS_VALUE ? 'ยืนยันการใช้งานออกเลขพัสดุ Best Express ใช่หรือไม่' : 'ยกเลิกการใช้งานออกเลขพัสดุ Best Express ใช่หรือไม่'
    // } else if (STATE_KEY === STATE_KEY_FOR_UPDATE.useAutoXShippingIsBestReadyToShip) {
    //   alertText = IS_VALUE
    //     ? 'ยืนยันการใช้งาน "ขอเลขพัสดุเมื่อพร้อมจัดส่ง" Best Express ใช่หรือไม่'
    //     : 'ยกเลิกการใช้งาน "ขอเลขพัสดุเมื่อพร้อมจัดส่ง" Best Express ใช่หรือไม่'
    // } else if (STATE_KEY === STATE_KEY_FOR_UPDATE.useBestCOD) {
    //   alertText = IS_VALUE
    //     ? 'ยืนยันการใช้งาน "ขอเลขพัสดุเมื่อพร้อมจัดส่ง" Best Express [COD] ใช่หรือไม่'
    //     : 'ยกเลิกการใช้งาน "ขอเลขพัสดุเมื่อพร้อมจัดส่ง" Best Express [COD] ใช่หรือไม่'
    // } else if (STATE_KEY === STATE_KEY_FOR_UPDATE.useAutoXShippingIsBestCODReadyToShip) {
    //   alertText = IS_VALUE
    //     ? 'ยืนยันการใช้งาน "ขอเลขพัสดุเมื่อพร้อมจัดส่ง" Best Express [COD] ใช่หรือไม่'
    //     : 'ยกเลิกการใช้งาน "ขอเลขพัสดุเมื่อพร้อมจัดส่ง" Best Express [COD] ใช่หรือไม่'
    // } else if (STATE_KEY === STATE_KEY_FOR_UPDATE.useAutoCreateCODPaymentWhenDelivered) {
    //   alertText = IS_VALUE
    //     ? 'ยืนยันการใช้งาน "บันทึกรับชำระเมื่อพัสดุถึงผู้รับแล้ว" ใช่หรือไม่'
    //     : 'ยกเลิกการใช้งาน "บันทึกรับชำระเมื่อพัสดุถึงผู้รับแล้ว" ใช่หรือไม่'
    // }

    // let isConfirm = false
    // await new Promise(async (resolve) => {
    //   p.op.showConfirmation('', alertText, () => {
    //     isConfirm = true
    //     // @ts-ignore
    //     resolve(null)
    //   }, () => {
    //     // @ts-ignore
    //     resolve(null)
    //   }, 'ตกลง', 'ยกเลิก')
    // })

    // if (!isConfirm) {
    //   await util.setStatePromise(this, {
    //     [newValue.stateKey]: !IS_VALUE,
    //   })
    //   return
    // }
    await this._setNewConfig(newValue)
    // util.setStatePromise(this, {
    //   [newValue.stateKey]: newValue.isValue,
    // })
  }
}
