import { connect } from 'react-redux'
import CategoryPropertyView from './CategoryPropertyView'
import { bindActionCreators } from 'redux'
import * as StoreActions from 'x/modules/store/StoreState'
import * as CategoryActions from 'x/modules/category/categoryState'
import {
  getSelectedStore,
  getSelectedProductGroups,
  getProductGroupsUpdatedAt,
  getSelectedUserGroups,
  getSubscription,
} from 'x/redux/selectors'
// import * as OrderActions from 'x/modules/order/OrderState'
// import { getSearchedProducts, getSelectedProductsFetchStatus, getSearchedProductsFetchStatus } from '../../redux/selectors'

export default connect(
  state => ({
    selectedStore: getSelectedStore(state),
    selectedProductGroups: getSelectedProductGroups(state),
    pgs_updated_at: getProductGroupsUpdatedAt(state),
    subscription: getSubscription(state),
    selectedUserGroups: getSelectedUserGroups(state),
  }),
  dispatch => ({
    fetchMyStore: bindActionCreators(StoreActions.fetchMyStore, dispatch),
    fetchProductGroups: bindActionCreators(StoreActions.fetchProductGroups, dispatch),
    sortPrioritiesProductGroups: bindActionCreators(StoreActions.sortPrioritiesProductGroups, dispatch),
    saveSyncProductGroupsToUserGroup: bindActionCreators(StoreActions.saveSyncProductGroupsToUserGroup, dispatch),
    addPermisson: bindActionCreators(StoreActions.addPermisson, dispatch),
    editPermisson: bindActionCreators(StoreActions.editPermisson, dispatch),
    deletePermission: bindActionCreators(StoreActions.deletePermission, dispatch),
    addCategory: bindActionCreators(CategoryActions.addCategory, dispatch),
    editCategory: bindActionCreators(CategoryActions.editCategory, dispatch),
    deleteCategory: bindActionCreators(CategoryActions.deleteCategory, dispatch),
    fetchCategory: bindActionCreators(CategoryActions.requestCategoryList, dispatch),
    changeCategory: bindActionCreators(CategoryActions.changeCategory, dispatch),
  })
  // @ts-ignore
)(CategoryPropertyView)
