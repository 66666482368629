/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/no-unused-state */
import React from 'react'
import { ISelectedStoreMap, IXScreenProps, IOrderPicker, IStoreWebsite } from 'x/index'
import * as NavActions from 'x/utils/navigation'
import * as util from 'x/utils/util'
import _ from 'lodash'
import * as api from 'x/utils/api'
import dayjs from 'dayjs'
import CONS from 'x/config/constants'
// https://stackoverflow.com/questions/47542928/lodash-refers-to-a-umd-global-and-lodash-js-is-not-a-module-errors

interface IBaseStoreWebsiteSettingOptionsOrderViewProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
  subscription: any
}

interface IBaseStoreWebsiteSettingOptionsOrderViewState {
  storeWebsite: IStoreWebsite
  ordersPicker: IOrderPicker[]
  haveEditing: boolean
  isLoading: boolean
}

export default abstract class BaseStoreWebsiteSettingOptionsOrderView extends React.Component<
  IBaseStoreWebsiteSettingOptionsOrderViewProps,
  IBaseStoreWebsiteSettingOptionsOrderViewState
> {
  // abstract async handleSubmitForm(response: any): Promise<void>
  // abstract didMountCheckPermissionForPhone?: () => void
  // validationSchema: Yup.ObjectSchema<any>
  // imgManagerIDRef: React.RefObject<any>
  // webview: React.RefObject<any>
  // mainPackageItems: any

  constructor(props) {
    super(props)
    this.state = {
      storeWebsite: null,
      ordersPicker: null,
      haveEditing: false,
      isLoading: false,
    }
    // this.webview = React.createRef()
    // this.imgManagerIDRef = React.createRef()
    // @ts-ignore
    // eslint-disable-next-line react/no-unused-class-component-methods
    // this.mainPackageItems = xCONS.SUBSCRIPTION_MAIN_PACKAGE_ITEMS.map((item) => ({
    //   ...item,
    //   label: p.op.t(`Subscription.main.${item.key}`),
    // }))
  }

  componentDidMount() {
    const params = util.getNavParams(this.props)
    const { storeWebsite } = params
    // console.log('storeWebsite => /// ', storeWebsite)
    const draftConfigJson = _.has(storeWebsite, 'draft_config_json') ? storeWebsite.draft_config_json : null
    // eslint-disable-next-line no-nested-ternary
    const orderPicker = _.isNil(draftConfigJson) ? null : _.has(draftConfigJson, 'order_pickers') ? draftConfigJson.order_pickers : null
    this.setState({
      storeWebsite,
      ordersPicker: _.has(orderPicker, 'order') ? orderPicker.order : null,
    })
  }

  _callBackFromCreateOptionsOrder = (orderPicker: IOrderPicker, index: number) => {
    // console.log('orderPicker => ', orderPicker)
    const { ordersPicker, storeWebsite } = this.state

    const newOrdersPicker = _.isNil(ordersPicker) ? [] : [...ordersPicker]
    if (newOrdersPicker.length < 1) {
      newOrdersPicker.push(orderPicker)
    } else {
      newOrdersPicker[index] = orderPicker
    }

    // console.log('newOrdersPicker => ', newOrdersPicker)

    const newSalePage = { ...storeWebsite, order_pickers: newOrdersPicker }
    this.setState({ ordersPicker: newOrdersPicker, storeWebsite: newSalePage, haveEditing: true })
  }

  // _callBackFromCreateOptionsOrder = (orderPicker: IOrderPicker, index: number) => {
  //   console.log('orderPicker => ', orderPicker)
  //   const { ordersPicker, salePage } = this.state
  //   const newOrdersPicker = _.isNil(ordersPicker) ? [] : _.clone(ordersPicker)
  //   if (newOrdersPicker.length < 1) {
  //     newOrdersPicker.push(orderPicker)
  //   } else {
  //     newOrdersPicker[index] = orderPicker
  //   }
  //   console.log('newOrdersPicker => ', newOrdersPicker)
  //   const newSalePage = _.clone(salePage)
  //   newSalePage.order_pickers = newOrdersPicker
  //   this.setState({ ordersPicker: newOrdersPicker, salePage: newSalePage })
  // }

  _navToSalePageSelectedCheckoutOptionModeView = (ordersPickerIndex: number) => {
    const { navigation } = this.props
    // const { ordersPicker, salePage } = this.state
    navigation.dispatch(
      NavActions.navToStoreWebsiteSelectedOptionModeView({
        index: ordersPickerIndex,
        callBack: (mode: 'SINGLE_PRODUCT_MODE' | 'PRODUCT_MULTI_OPTIONS_MODE' | 'PRODUCT_SET_MODE', index: number) =>
          this.navToStoreWebsiteCreateOptionsOrderView(mode, index),
      })
    )
  }

  navToStoreWebsiteCreateOptionsOrderView = (
    mode: 'SINGLE_PRODUCT_MODE' | 'PRODUCT_MULTI_OPTIONS_MODE' | 'PRODUCT_SET_MODE',
    ordersPickerIndex: number
  ) => {
    const { navigation } = this.props
    const { ordersPicker, storeWebsite } = this.state
    // console.log('ordersPicker Nav => ', ordersPicker)
    const selectedPicker = _.isNil(ordersPicker) ? null : ordersPicker[ordersPickerIndex]
    navigation.dispatch(
      NavActions.navToStoreWebsiteCreateOptionsOrderView({
        storeWebsite,
        orderPicker: selectedPicker,
        index: ordersPickerIndex,
        mode,
        callBack: (newOrderPicker: IOrderPicker, index: number) => this._callBackFromCreateOptionsOrder(newOrderPicker, index),
      })
    )
  }

  _saveBtn = async () => {
    const { selectedStore } = this.props
    const { storeWebsite, ordersPicker } = this.state
    await util.setStatePromise(this, { isLoading: true })
    await util.delay(200)
    const newJson = _.has(storeWebsite, 'draft_config_json') ? _.cloneDeep(storeWebsite.draft_config_json) : { draft_config_json: null }
    // @ts-ignore
    const newOrderPicker = _.has(newJson, 'order_pickers') ? _.cloneDeep(newJson.order_pickers) : { order: null, updated_at: null }
    // console.log('newJson => ', newJson)
    newOrderPicker.order = ordersPicker
    newOrderPicker.updated_at = dayjs().format(CONS.SERVER_DATETIME_FORMAT).toString()
    // console.log('newOrderPicker => ', newOrderPicker)
    // @ts-ignore
    newJson.order_pickers = newOrderPicker

    // // @ts-ignore
    // delete newJson.order_pickers
    // // @ts-ignore
    // delete newJson.order_picker
    // console.log('newJson // => ', newJson)
    // return
    try {
      const res = await api.updateStoreWebsite({
        store_id: selectedStore.get('id'),
        website_id: storeWebsite.id,
        config_json: newJson,
      })
      // console.log('res => ', res)
      // this._comfirmCancelEdit()
      const params = util.getNavParams(this.props)
      const { callBackSucceedUpdateApi } = params
      if (_.isFunction(callBackSucceedUpdateApi)) {
        callBackSucceedUpdateApi(res)
        // p.op.showToast('บันทึกข้อมูลเรียบร้อยแล้ว', 'success')
        util.navGoBack(this.props)
      }
    } catch (error) {
      // console.log('error => ', error)
    }
    await util.delay(200)
    await util.setStatePromise(this, { isLoading: false, haveEditing: false })
  }
}
