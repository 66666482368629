import React from 'react'
import _ from 'lodash'
import { ListRenderItemInfo } from 'react-native'

// import CONS from 'x/config/constants'
import * as util from 'x/utils/util'
import p from 'x/config/platform-specific'
import { S, COLORS } from 'x/config/styles'
import { IShippingType, IXFlatPickerItem } from 'x/index'

import XFlatPicker from '../XFlatPicker'
import XCard from '../XCard'
import ForwardIcon from '../ForwardIcon'
import HStack from '../HStack'
import XText from '../XText'
import VStack from '../VStack'
import XIcon from '../XIcon'

// interface IBaseShippingTypePickerButtonProps {
//   onChange: (selectedShippingTypeItem: IShippingType) => void
//   selectedShippingTypeId: number
//   selectedShippingTypeIndex: number
// }

// type IShippingTypePickerButtonProps =
//   | Pick<IBaseShippingTypePickerButtonProps, 'selectedShippingTypeId' | 'onChange'>
//   | Pick<IBaseShippingTypePickerButtonProps, 'selectedShippingTypeIndex' | 'onChange'>

type IShippingTypePickerButtonProps = {
  disabled?: boolean
  onChange: (selectedShippingTypeItem: IShippingType) => void

  // required อย่างใดอย่างหนึ่ง
  selectedShippingTypeIndex?: number
  selectedShippingTypeId?: number
}

type IShippingTypePickerButtonInternalProps = IShippingTypePickerButtonProps & {
  visibleShippingTypeItems: IShippingType[]
}

/**
 * WARNING:: ShippingTypePickerButton เป็น component ที่ต้องใช้ร่วมกับ Redux ในการ access store settings (util.getVisibleShippingTypes)
 */
class ShippingTypePickerButton extends React.PureComponent<IShippingTypePickerButtonInternalProps> {
  static displayName = 'ShippingTypePickerButton'
  // constructor(props) {
  //   super(props)
  //   this.state = {
  //     selectedPickerIndex: 0,
  //   }
  // }

  // componentDidMount(): void {
  //   this._loadSelectedPickerIndexFromSelectedShippingTypeIndexProps()
  //   this._loadSelectedPickerIndexFromSelectedShippingTypeIdProps()
  // }

  // setSelectedPickerIndex = (selectedPickerIndex: number) => {
  //   this.setState({ selectedPickerIndex })
  // }

  // _loadSelectedPickerIndexFromSelectedShippingTypeIdProps = () => {
  //   const {
  //     // @ts-ignore อย่างใดอย่างหนึ่ง
  //     selectedShippingTypeIndex,
  //     // @ts-ignore อย่างใดอย่างหนึ่ง
  //     selectedShippingTypeId,
  //     disabled,
  //     onChange,
  //     visibleShippingTypeItems,
  //   } = this.props

  //   // console.log('_loadSelectedPickerIndexFromSelectedShippingTypeIdProps selectedShippingTypeId => ', selectedShippingTypeId)
  //   if (_.isNumber(selectedShippingTypeId)) {
  //     const selectedIdx = visibleShippingTypeItems.findIndex((sti) => sti.value === selectedShippingTypeId)
  //     // console.log('_loadSelectedPickerIndexFromSelectedShippingTypeIdProps selectedIdx => ', selectedIdx)
  //     if (selectedIdx > -1 && selectedIdx !== selectedPickerIndex) {
  //       this.setSelectedPickerIndex(selectedIdx)
  //     } else {
  //       this.setSelectedPickerIndex(0)
  //     }
  //   }
  // }

  // _loadSelectedPickerIndexFromSelectedShippingTypeIndexProps = () => {
  //   const {
  //     // @ts-ignore อย่างใดอย่างหนึ่ง
  //     selectedShippingTypeIndex,
  //     // @ts-ignore อย่างใดอย่างหนึ่ง
  //     selectedShippingTypeId,
  //     disabled,
  //     onChange,
  //     visibleShippingTypeItems,
  //   } = this.props

  //   if (_.isNumber(selectedShippingTypeIndex)) {
  //     if (selectedShippingTypeIndex !== selectedPickerIndex) {
  //       this.setSelectedPickerIndex(selectedShippingTypeIndex)
  //     }
  //   }
  // }

  _renderSelectedValueItem = (data: IXFlatPickerItem<IShippingType>) => {
    const { visibleShippingTypeItems = [] } = this.props
    const { item, index, disabled } = data
    let txtShippingType = null
    if (index > -1) {
      txtShippingType = visibleShippingTypeItems[index].label
    } else if (index === -1 && item && item.value > 0) {
      txtShippingType = 'กรุณาดาวน์โหลดแอปเวอร์ชั่นใหม่ เพื่อแสดงผลข้อมูลนี้'
    } else {
      txtShippingType = 'ยังไม่ได้เลือก'
    }
    // if (p.op.isWeb()) {
    //   return (
    //     <View style={[S.FLEX, S.ROW_MIDDLE_BETWEEN]}>
    //       <View style={[S.FLEX, S.ROW_MIDDLE_START]}>
    //         <Icon
    //           type='MaterialIcons'
    //           name='local-shipping'
    //           style={{ paddingLeft: 6, color: COLORS.TEXT_INACTIVE, fontSize: STYLES.FONT_ICON_NORMAL }}
    //         />
    //         <XText style={[S.TEXT_INACTIVE, { paddingLeft: 3 }]}>{'รูปแบบจัดส่ง'}</XText>
    //       </View>
    //       <XText
    //         style={[
    //           S.TEXT_INACTIVE,
    //           {
    //             textAlign: 'right',
    //             paddingTop: 4,
    //           },
    //         ]}>
    //         {txtShippingType}
    //       </XText>
    //     </View>
    //   )
    // }

    return (
      <XCard w='full'>
        <XCard.Body
          style={[
            // p.op.isWeb() ? {} : S.CARDLIKE_BORDERS,
            // p.op.isWeb() ? { paddingLeft: 6 } : S.CARDLIKE_BODY,
            // S.FLEX,
            // S.MIN_HEIGHT_34,
            // S.ROW_MIDDLE_BETWEEN,
            disabled ? S.BG_LIGHT_GREY : {},
          ]}>
          <HStack w='full' p='1' alignItems='center' space='1'>
            <XIcon
              variant='inactive'
              family='MaterialIcons'
              name='local-shipping'
              // style={{ paddingTop: 2, marginRight: 6, fontSize: STYLES.FONT_ICON_NORMAL, color: COLORS.TEXT_INACTIVE }}
            />
            <XText flex={1} variant='inactive'>
              รูปแบบจัดส่ง
            </XText>
            <XText w='134px' textAlign='right' variant={disabled ? 'inactive' : 'primary'}>
              {txtShippingType}
            </XText>
            {disabled ? null : <ForwardIcon />}
          </HStack>
        </XCard.Body>
      </XCard>
    )
  }

  _renderPickerItem = (data: IXFlatPickerItem<IShippingType>) => {
    const { item, index, disabled } = data
    // if (item.hidden) {
    //   return <View key={`${index.toString()}_hidden`} />
    // }
    const UNDER_LINE = p.op.isWeb() ? {} : { borderBottomColor: COLORS.TEXT_INACTIVE, borderBottomWidth: 1 }
    const shippingState = util.getShippingHasXShippingLabel(item.value)

    return (
      <XCard w='full' mb='1' key={index.toString()}>
        {/* style={[
          p.op.isWeb() ? {} : S.CARDLIKE_BORDERS,
          S.CARDLIKE_BODY,
          S.ROW_MIDDLE_BETWEEN,
          p.op.isWeb() ? {} : UNDER_LINE,
          p.op.isWeb() ? {} : S.BG_WHITE,
        ]} */}
        <XCard.Body>
          <HStack w='full' alignItems='center' space='1'>
            <VStack flex={1} p='1'>
              <XText>{`${item.label}`}</XText>
              {_.isNil(shippingState) ? null : (
                <XText variant='inactive' style={[{ color: shippingState.color }]}>{`(${shippingState.label})`}</XText>
              )}
            </VStack>
            <ForwardIcon />
          </HStack>
        </XCard.Body>
      </XCard>
    )
  }

  _onPick = (info: ListRenderItemInfo<IShippingType>) => {
    const { onChange } = this.props
    const { index, item } = info

    if (_.isFunction(onChange)) {
      onChange(item)
    }
  }

  getShippingTypeIndexFromId = (shippingTypeId) => {
    const { visibleShippingTypeItems = [] } = this.props
    return visibleShippingTypeItems.findIndex((vsti) => vsti.value === shippingTypeId)
  }

  render() {
    const {
      // // @ts-ignore อย่างใดอย่างหนึ่ง
      selectedShippingTypeIndex,
      // @ts-ignore อย่างใดอย่างหนึ่ง
      selectedShippingTypeId,
      disabled,
      onChange,
      visibleShippingTypeItems,
    } = this.props

    return (
      <XFlatPicker<IShippingType>
        disabled={disabled}
        title='เลือกรูปแบบจัดส่ง'
        placeholder='กรุณาเลือกรูปแบบจัดส่ง'
        // ref={this._shippingTypeCreateModePickerRef}
        // selectedIndex={selectedPickerIndex}
        selectedIndex={
          _.isNumber(selectedShippingTypeIndex) ? selectedShippingTypeIndex : this.getShippingTypeIndexFromId(selectedShippingTypeId)
        }
        options={visibleShippingTypeItems}
        onPick={this._onPick}
        renderSelectedValue={this._renderSelectedValueItem}
        btnStyle={[S.FLEX]}
        renderItem={this._renderPickerItem}
      />
    )
  }
}

interface ShippingTypePickerButtonWrapperState {
  isInitialized: boolean
  visibleShippingTypeItems: IShippingType[]
}

class ShippingTypePickerButtonWrapper extends React.Component<IShippingTypePickerButtonProps, ShippingTypePickerButtonWrapperState> {
  static displayName = 'ShippingTypePickerButton'

  constructor(props) {
    super(props)
    this.state = {
      isInitialized: false,
      visibleShippingTypeItems: [],
    }
  }
  componentDidMount(): void {
    this.setState({
      visibleShippingTypeItems: util.generateShippingTypeItems(),
      isInitialized: true,
    })
  }

  render() {
    const { visibleShippingTypeItems = [], isInitialized = false } = this.state
    if (!isInitialized) {
      return null
    }
    return <ShippingTypePickerButton {...this.props} visibleShippingTypeItems={visibleShippingTypeItems} />
  }
}

export default ShippingTypePickerButtonWrapper
