import React from 'react'

import { connect } from 'react-redux'
import { getSelectedStore, getEditingStore } from 'x/redux/selectors'

import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import * as xFmt from 'x/utils/formatter'

import { fetchVerifySlipCreditSummary } from 'x/utils/api'
import * as util from 'x/utils/util'
import { logRender } from 'x/utils/util'
import _ from 'lodash'

import p from 'x/config/platform-specific'
import * as NavActions from 'x/utils/navigation'
import XText from 'xui/components/XText'
import XCard from 'xui/components/XCard'
import XCustomHeader from 'xui/components/XCustomHeader'
import XButton from 'xui/components/XButton'
import XIcon from 'xui/components/XIcon'
import { ISelectedStoreMap, IXScreenProps, IVerifySlipCreditSummaryItem } from 'x/types'
import Box from 'xui/components/Box'
import XSpinner from 'xui/components/XSpinner'
import time from 'x/utils/time'
import HelpButton from 'xui/components/HelpButton'
import XHelpModal from 'xui/components/XHelpModal'
import FAQ from 'x/config/FAQ'
import XDropdown from 'xui/components/XDropdown'
import DotDotDotVerticalIcon from 'xui/components/DotDotDotVerticalIcon'
import XContainer from '../../components/XContainer'
import XContent from '../../components/XContent'

interface IProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
}

interface IState {
  isInitialized?: boolean
  isRefreshing?: boolean

  remainingCredit?: number
  remainingCreditItems: IVerifySlipCreditSummaryItem[]
}

class VerifySlipCreditSummaryView extends React.Component<IProps, IState> {
  static displayName = 'VerifySlipCreditSummaryView'

  inSelectHamburger?: boolean

  constructor(props) {
    super(props)

    this.state = {
      isInitialized: false,
      isRefreshing: false,
      remainingCredit: null,
      remainingCreditItems: [],
    }
  }

  async componentDidMount() {
    await this._onRefresh()
    await util.delay(150)
    await util.setStatePromise(this, { isInitialized: true })
  }

  _onRefresh = async () => {
    await util.setStatePromise(this, { isRefreshing: true })

    const { navigation, selectedStore } = this.props
    const store_id = selectedStore.get('id')

    try {
      const res = await fetchVerifySlipCreditSummary({ store_id, show_remaining_credits: true, offset: 0, limit: 20 })
      console.log('_onRefresh res', res)

      await util.setStatePromise(this, { remainingCredit: res.total_remaining_credit, remainingCreditItems: res.remaining_credits })
    } catch (err) {
      console.log('_onRefresh err', err)
    }

    await util.setStatePromise(this, { isRefreshing: false })
  }

  _goBack = () => {
    util.navGoBack(this.props)
  }

  _renderModalDropdownRow = (data: { label: string; isDanger?: boolean; icon?: any; onPress: () => void }): JSX.Element => {
    const isDanger = _.has(data, 'isDanger') ? data.isDanger : false
    const icon = _.has(data, 'icon') ? data.icon : null
    return (
      <HStack px='1' py='2' space='1' alignItems='center'>
        {_.isNil(icon) ? null : <XIcon name={icon.name} family={icon.family} variant={isDanger ? 'danger' : 'active'} />}
        <XText variant={isDanger ? 'danger' : 'active'}>{data.label}</XText>
      </HStack>
    )
  }

  navToVerifySlipCreditSummaryUnusableView = () => {
    console.log('navToVerifySlipCreditSummaryUnusableView')
    const { navigation } = this.props
    navigation.dispatch(NavActions.navToVerifySlipCreditSummaryUnusableView)
  }

  _onHamburgerMenuSelect = async (index: number, rowData: any) => {
    console.log('_onHamburgerMenuSelect index => ', index)
    console.log('_onHamburgerMenuSelect rowData => ', rowData)
    if (this.inSelectHamburger) {
      return
    }
    this.inSelectHamburger = true
    await util.delay(100)

    if (index === 0) {
      this.navToVerifySlipCreditSummaryUnusableView()
    }

    await util.delay(100)
    this.inSelectHamburger = false
    // await setStatePromise(this, { isHamburgerMenuOpened: false })
  }

  renderHeaderRight = () => {
    const helpBtn = (onPressOpenOverlay: () => void) => <HelpButton onPress={() => onPressOpenOverlay()} />

    return (
      <HStack alignItems='center'>
        <XHelpModal
          key='XHelpModalVerifySlipCreditSummary'
          headerTitle='คำถามที่พบบ่อย'
          FAQ={FAQ.VERIFY_SLIP_CREDIT_SUMMARY}
          initiateOpenIndex={0}
          renderButton={(onPressOpenOverlay: () => void) => helpBtn(onPressOpenOverlay)}
        />

        <XDropdown
          options={[{ label: 'ประวัติเครดิตที่หมดแล้ว', icon: { name: 'back-in-time', family: 'Entypo' } }]}
          renderRow={this._renderModalDropdownRow}
          onSelect={this._onHamburgerMenuSelect}>
          <Box pb='2px'>
            <DotDotDotVerticalIcon />
          </Box>
        </XDropdown>
      </HStack>
    )
  }

  renderCustomHeader = () => {
    // return null
    const { navigation } = this.props

    return (
      <XCustomHeader
        // headerStyle={fixedAndroidMarginTopStyle}
        headerLeftProps={{ backIcon: true, onPressItem: this._goBack }}
        // headerRightProps={headerRightProps}
        headerRight={this.renderHeaderRight()}
        title='เครดิตตรวจสลิปอัตโนมัติ'
      />
    )
  }

  renderCreditListItem = (item: IVerifySlipCreditSummaryItem, index: number) => {
    const { id, remaining_credit, original_credit, created_at, expiration_date, updated_at } = item

    return (
      <XCard>
        <VStack w='full' p='2'>
          <HStack w='full' alignItems='center'>
            <XText variant='inactive' flex={1}>
              ยอดเครดิตคงเหลือ
            </XText>
            <XText w='150px' textAlign='right' color='#60BA60'>
              {xFmt.formatDecimal(remaining_credit)}
            </XText>
          </HStack>

          <HStack w='full' alignItems='center'>
            <XText variant='inactive' flex={1}>
              ยอดเครดิตที่เติม
            </XText>
            <XText w='150px' textAlign='right'>
              {xFmt.formatDecimal(original_credit)}
            </XText>
          </HStack>

          <HStack w='full' alignItems='center'>
            <XText variant='inactive' flex={1}>
              เติมเมื่อ
            </XText>
            <XText w='150px' textAlign='right'>
              {time.convertServerDateTimeToReadableDateTimeText(created_at)}
            </XText>
          </HStack>

          <HStack w='full' alignItems='center'>
            <XText variant='inactive' flex={1}>
              หมดอายุภายใน
            </XText>
            <XText w='150px' textAlign='right'>
              {time.convertServerDateTimeToReadableDateTimeText(expiration_date)}
            </XText>
          </HStack>

          {remaining_credit !== original_credit ? (
            <HStack w='full' alignItems='center' justifyContent='flex-end'>
              <XButton
                w='160px'
                h='34px'
                bg='gray.100'
                variant='ghost'
                leftIcon={<XIcon name='text' family='MaterialCommunityIcons' />}
                onPress={() => this._navToVerifySlipCreditHistoryView(id)}>
                ดูประวัติการใช้งาน
              </XButton>
            </HStack>
          ) : null}
        </VStack>
      </XCard>
    )
  }

  _navToVerifySlipCreditHistoryView = (verify_slip_credit_id: number) => {
    const { navigation, selectedStore } = this.props
    const selectedStoreId = selectedStore.get('id')
    navigation.dispatch(NavActions.navToVerifySlipCreditHistoryView({ store_id: selectedStoreId, verify_slip_credit_id }))
  }

  renderCreditListItems = () => {
    const { remainingCreditItems, isRefreshing } = this.state

    if (isRefreshing) {
      return <XSpinner />
    }

    return remainingCreditItems.map(this.renderCreditListItem)
  }

  onPressToRefillCredit = () => {
    if (p.op.isIOS()) {
      p.op.alert('กรุณาติดต่อทีมงาน')
      return
    }

    p.op.alert('กรุณาติดต่อทีมงาน', 'ติดต่อทีมงานได้ทุกวัน ไม่มีวันหยุด ที่\nLINE: @XSelly\nโทร: 097-167-1650')
  }

  renderMain = () => {
    const { isInitialized, isRefreshing, remainingCredit, remainingCreditItems } = this.state
    if (!isInitialized) {
      return null
    }

    let latestRemaingingCredit = null
    let latestRemaingingCreditExpireationDate = null

    if (remainingCreditItems && remainingCreditItems.length > 0) {
      latestRemaingingCredit = remainingCreditItems[0].remaining_credit
      latestRemaingingCreditExpireationDate = remainingCreditItems[0].expiration_date
    }

    return (
      <XContainer>
        {this.renderCustomHeader()}
        <XContent>
          <VStack w='full' bg='white'>
            <VStack w='full' p='2'>
              <VStack w='full' p='2' bg='#C7EFCF' borderRadius='lg' space='1.5'>
                <HStack w='full' space='1.5' alignItems='center'>
                  <XText variant='inactive' fontSize='lg' flex={1}>
                    ยอดเครดิตคงเหลือทั้งหมด
                  </XText>
                  <XText color='#60BA60' fontSize='3xl' bold>
                    {xFmt.formatDecimal(remainingCredit)}
                  </XText>
                </HStack>

                <HStack w='full' space='1.5' justifyContent='flex-end'>
                  {latestRemaingingCredit && latestRemaingingCreditExpireationDate ? (
                    <XText variant='inactive' flex={1}>
                      {`${xFmt.formatDecimal(
                        latestRemaingingCredit
                      )} เครดิต จะหมดอายุภายใน ${time.convertServerDateTimeToReadableDateTimeText(latestRemaingingCreditExpireationDate)}`}
                    </XText>
                  ) : null}

                  <XButton
                    bg='white'
                    w='118px'
                    h='34px'
                    variant='ghost'
                    leftIcon={
                      <Box>
                        <XIcon success name='plus-circle' family='FontAwesome' />
                      </Box>
                    }
                    onPress={this.onPressToRefillCredit}>
                    <XText color='#60BA60' fontSize='md'>
                      เติมเครดิต
                    </XText>
                  </XButton>
                </HStack>
              </VStack>
            </VStack>

            <VStack w='full' p='2' space='2'>
              {this.renderCreditListItems()}
            </VStack>
          </VStack>
        </XContent>
      </XContainer>
    )
  }

  render() {
    logRender(this)
    return this.renderMain()
  }
}

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
    editingStore: getEditingStore(state),
    // subscription: getSubscription(state),
  }),
  (dispatch) => ({
    dispatch,
    // submitEditing: bindActionCreators(StoreActions.submitEditingStore, dispatch),
    // resetEditingStore: bindActionCreators(StoreActions.resetEditingStore, dispatch),
    // storeActions: bindActionCreators(StoreState, dispatch),
  })
)(VerifySlipCreditSummaryView)
