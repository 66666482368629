import React from 'react'
import { COLORS, STYLES } from 'x/config/styles'
import _ from 'lodash'
import * as util from 'x/utils/util'
// import * as NavActions from '../services/navigation'

import { View } from 'react-native'

import HStack from 'xui/components/HStack'
import VStack from 'xui/components/VStack'
import Button from 'xui/components/XButton'

import { List } from 'immutable'
import p from 'x/config/platform-specific'
import { IPG_VD_IDS, IProductGroup, IVolumeDiscountMap } from 'x/index'

// import xCONS from 'x/config/constants'
import BasePGSelectorView from 'x/modules/product/BasePGSelectorView'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import XText from 'xui/components/XText'
import * as NavActions from 'x/utils/navigation'
import XCard from 'xui/components/XCard'
import NavHeaderButton from '../../components/NavHeaderButton'
import XIcon from '../../components/XIcon'
// import XInput from '../../components/XInput'
// import BackIcon from '../../components/BackIcon'
// import XStatusBar from '../../components/XStatusBar'
// import XModal from './XModal'
import RangeAdjuster from '../../components/RangeAdjuster'

const { APP_SECONDARY, TEXT_INACTIVE, TEXT_ACTIVE } = COLORS

const PGModalText = (props) => (
  <XText
    {...props}
    style={{
      textAlign: 'center',
      fontSize: STYLES.FONT_SIZE_NORMAL,
      color: TEXT_INACTIVE,
      fontWeight: 'normal',
      marginTop: 0,
    }}
  />
)

const PGModalSelectedText = (props) => (
  <View
    style={{
      padding: 4,
      marginRight: 4,
      marginBottom: 2,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: APP_SECONDARY,
      // borderColor: BG_LIGHT_GREY,

      borderRadius: 4,
      // backgroundColor: APP_SECONDARY,
    }}>
    <XText
      {...props}
      style={{
        // color: TEXT_INACTIVE,
        color: TEXT_ACTIVE,
        textAlign: 'center',
        fontSize: STYLES.FONT_SIZE_NORMAL,
        fontWeight: 'bold',
        marginTop: 0,
      }}
    />
  </View>
)

// const VDModalSelectedText = props => (
//   <View
//     style={{
//       padding: 4,
//       marginRight: 4,
//       marginBottom: 2,
//       borderWidth: 1,
//       borderStyle: 'solid',
//       borderColor: APP_SECONDARY,
//       // borderColor: BG_LIGHT_GREY,
//       marginTop: 4,
//       borderRadius: 4,
//       flex: 1,
//       // backgroundColor: APP_SECONDARY,
//     }}>
//     <XText
//       {...props}
//       style={{
//         // color: TEXT_INACTIVE,
//         color: TEXT_ACTIVE,
//         textAlign: 'left',
//         fontSize: STYLES.FONT_SIZE_NORMAL,
//         fontWeight: 'bold',
//         marginTop: 0,
//         paddingLeft: 4,
//       }}
//     />
//   </View>
// )

// const VOLUME_DISCOUNT = 'เพิ่มส่วนลดขายส่ง'
// const EDIT_VOLUME_DISCOUNT = 'แก้ไขส่วนลดขายส่ง'
// const UNPAIR_VOLUME_DISCOUNT = 'ยกเลิกส่วนลดขายส่ง'

// const Checkbox = ({ label, onPress, checked = false }) => (
//   <Box
//     // button={!checked}
//     button={true}
//     onPress={() => {
//       if (_.isFunction(onPress)) {
//         onPress()
//       }
//     }}
//     style={[
//       STYLES.NO_MARGIN,
//       {
//         paddingLeft: 14,
//         paddingRight: 14,
//         paddingTop: 8,
//         paddingBottom: 8,
//         // paddingRight: 14,
//         // borderBottomWidth: 0,
//         // borderColor: COLORS.APP_SECONDARY,
//         backgroundColor: 'white',
//         borderColor: '#cacaca',
//         height: 40,
//         maxHeight: 40,
//       },
//     ]}>
//     <Left
//       style={{
//         flex: 1,
//         borderBottomWidth: 0,
//         justifyContent: 'center',
//         alignItems: 'center',
//       }}>
//       <XIcon
//         family={'FontAwesome'}
//         style={{
//           flex: 1,
//           fontSize: STYLES.FONT_ICON_NORMAL,
//           color: COLORS.APP_MAIN,
//         }}
//         name={checked ? 'check-square-o' : 'square-o'}
//       />
//     </Left>
//     <Body style={{ flex: 5, borderBottomWidth: 0 }}>
//       <XText
//         allowFontScaling={false}
//         style={{
//           textAlign: 'left',
//           fontSize: STYLES.FONT_SIZE_NORMAL,
//           color: checked ? COLORS.TEXT_ACTIVE : COLORS.TEXT_INACTIVE,
//         }}>
//         {label}
//       </XText>
//     </Body>
//     <Right style={{ flex: 3, alignItems: 'flex-end' }}>
//       {checked ? (
//         <Button
//           onPress={() => {
//             if (_.isFunction(onPress)) {
//               onPress()
//             }
//           }}
//           style={{ backgroundColor: COLORS.APP_MAIN, height: 30, width: 70, borderRadius: 7 }}>
//           <XText style={{ fontSize: STYLES.FONT_SIZE_NORMAL }}>HI</XText>
//         </Button>
//       ) : null}
//     </Right>
//   </Box>
// )

// const CheckboxHaveVD = ({ usePrefixOrSuffix, prefix, suffix, type, label, vds, onPress, checked = false }) => (
//   <View>
//     <Box
//       button={true}
//       onPress={() => {
//         if (_.isFunction(onPress)) {
//           onPress()
//         }
//       }}
//       style={[
//         STYLES.NO_MARGIN,
//         {
//           paddingLeft: 14,
//           paddingRight: 14,
//           paddingTop: 8,
//           paddingBottom: 8,
//           // paddingRight: 14,
//           // borderBottomWidth: 0,
//           // borderColor: COLORS.APP_SECONDARY,
//           backgroundColor: 'white',
//           borderColor: '#cacaca',
//         },
//       ]}>
//       <Left
//         style={{
//           flex: 1,
//           borderBottomWidth: 0,
//           justifyContent: 'center',
//           alignItems: 'center',
//         }}>
//         <XIcon
//           family={'FontAwesome'}
//           style={{
//             flex: 1,
//             fontSize: STYLES.FONT_ICON_NORMAL,
//             color: COLORS.APP_MAIN,
//           }}
//           name={checked ? 'check-square-o' : 'square-o'}
//         />
//       </Left>
//       <Body style={{ flex: 5, borderBottomWidth: 0 }}>
//         <XText
//           allowFontScaling={false}
//           style={{
//             textAlign: 'left',
//             fontSize: STYLES.FONT_SIZE_NORMAL,
//             color: checked ? COLORS.TEXT_ACTIVE : COLORS.TEXT_INACTIVE,
//           }}>
//           {label}
//         </XText>
//       </Body>
//       <Right style={{ flex: 3, alignItems: 'flex-end' }}>
//         {checked ? (
//           <Button
//             onPress={() => {
//               this._renderNavActions()
//             }}
//             style={{ backgroundColor: COLORS.APP_MAIN, height: 30, width: 70, borderRadius: 7 }}>
//             <XText style={{ fontSize: STYLES.FONT_SIZE_NORMAL }}>HI</XText>
//           </Button>
//         ) : null}
//       </Right>
//     </Box>
//     {checked ? (
//       <Box>
//         <RangeAdjuster
//           disabled={true}
//           metadata={[
//             {
//               // key: discountKey,
//               prefix,
//               suffix,
//               usePrefixOrSuffix,
//               key: 'val',
//               label: type === 1 ? 'ส่วนลด (บาทต่อชิ้น)' : 'ส่วนลด (%)',
//               span: 2,
//               defaultValue: '',
//               keyboardType: 'numeric',
//             },
//           ]}
//           data={vds}
//           labelAddButton={'เพิ่มช่วงส่วนลด'}
//           labelLastEnd={'ขึ้นไป'}
//         />
//       </Box>
//     ) : null}
//   </View>
// )

export default abstract class BaseUIPGSelectorView extends BasePGSelectorView {
  static displayName = 'BaseUIPGSelectorView'

  // abstract handleNavToVolumeDiscountListView(pg_id: number): void | Promise<void>
  handleNavToVolumeDiscountListView = (pg_id) => {
    const { editingProduct, navigation, selectedStore } = this.props
    const mode = util.getNavParam(this.props, 'mode')
    const { dispatch } = navigation
    const store_id = selectedStore.get('id')
    const pt_id = editingProduct.get('id')
    let pg_vd_ids = editingProduct.get('pg_vd_ids')
    if (_.isNil(pg_vd_ids)) {
      pg_vd_ids = null
    }
    this.onSubmitEditing()
    const product_group_ids = editingProduct.get('product_group_ids')
    // console.log('----------------> ' + this.props.mode)
    // this.setState({ isVisibleSelector: false })
    dispatch(
      NavActions.navToVolumeDiscountListView({
        store_id,
        pg_id,
        pt_id,
        pg_vd_ids,
        mode: 'PULL_MODE',
        productMode: mode,
        product_group_ids,
      })
    )
  }

  _successCallbackUnpairVolumeDiscount = (params, productGroupId) => {
    // const { navigation } = this.props
    // const { state, goBack } = navigation
    // const resVD_id = res.volume_discount.id
    const pg_id = productGroupId
    // let newVolumeDiscounts = []
    const newPG_VD_IDS = []
    this.props.editingProduct
      .get('pg_vd_ids')
      .toJS()
      .map((val) => {
        if (pg_id !== val.pg_id) {
          newPG_VD_IDS.push(val)
        }
      })
    this.props.updateDataVolumeDiscountAndProductGroup(newPG_VD_IDS)
    // this.props.selectedStore.get('vds').toJS().map(val => {
    //   if (params.volumeDiscountID === val.id) {
    //     newVolumeDiscounts.push(res.volume_discount)
    //   } else {
    //     newVolumeDiscounts.push(val)
    //   }
    // })
    // this.props.updateContInVolumeDiscounts(newVolumeDiscounts)

    // if (_.isNil(params.pg_vd_ids)) {
    //   this.props.updateDataVolumeDiscountAndProductGroup([{ vd_id: resVD_id, pg_id }])
    //   p.op.showToast('ยกเลิกส่วนลดขายส่งเรียบร้อยแล้ว', 'success', 2000)
    //   // goBack(state.key)
    //   return
    // } else {
    //   params.pg_vd_ids.map(val => {
    //     if (pg_id === val.pg_id) {
    //       return
    //     } else {
    //       newPG_VD_IDS.push({ vd_id: val.vd_id, pg_id: val.pg_id })
    //     }
    //   })
    // }
    // console.log(this.props.navigation.state.params.store_id)
    // this.props.updateDataVolumeDiscountAndProductGroup(newPG_VD_IDS)
    // this.setState({ isVisibleSelector: false })
    // p.op.showToast('ยกเลิกส่วนลดขายส่งเรียบร้อยแล้ว', 'success', 2000)
    // this.props.loadListVolumeDiscounts({store_id: this.props.navigation.state.params.store_id})
    // goBack(state.key)
  }

  _unpairVolumeDiscount = (productGroupId, productGroupName) => {
    const params = util.getNavParams(this.props)
    // console.log(productGroupId)
    // console.log(productGroupName)
    // console.log(editingProduct.get('id'))
    // console.log(params.volumeDiscountID)
    p.op.showConfirmation(
      null,
      `คุณแน่ใจที่จะลบส่วนลดขายส่งนี้ออกจาก ${productGroupName}`,
      () => {
        this._successCallbackUnpairVolumeDiscount(params, productGroupId)
      },
      null,
      'ตกลง',
      'ยกเลิก'
    )

    // p.op.showConfirmation(null, `คุณแน่ใจที่จะลบส่วนลดขายส่งนี้ออกจาก ${productGroupName}`,
    //   () => {
    //     this.props.unpairVolumeDiscount({
    //       body: {
    //         store_id: params.store_id,
    //         vd_id: params.volumeDiscountID,
    //         pt_id: editingProduct.get('id'),
    //         pg_id: productGroupId
    //       },
    //       successCallback: (res) => this._successCallbackUnpairVolumeDiscount(res, params, productGroupId),
    //       // failedCallback: (err) => {},
    //     })
    //   },
    //   () => { },
    //   'ตกลง',
    //   'ยกเลิก')
  }

  _renderCheckboxHaveVolumeDiscountList = (
    usePrefixOrSuffix,
    prefix,
    suffix,
    type,
    variants,
    productGroupId,
    productGroupName,
    checked,
    VIEW_VOLUME_DISCOUNT
  ) => {
    // console.log('_renderCheckboxHaveVolumeDiscountList')
    const s_use_volume_discount = this.props.selectedStore.get('s_use_volume_discount')
    return (
      <XCard key={productGroupId} w='full' onPress={() => this._toggleCheckboxAtId(productGroupId, checked)}>
        <XCard.Body>
          <VStack w='full'>
            {/* <TouchableOpacity onPress={() => this._toggleCheckboxAtId(productGroupId, checked)}> */}
            <HStack w='full' space='1'>
              <XIcon family='FontAwesome' name={checked ? 'check-square-o' : 'square-o'} />
              <XText
                flex={1}
                variant={checked ? 'active' : 'inactive'}
                // allowFontScaling={false}
                // style={{
                //   textAlign: 'left',
                //   fontSize: STYLES.FONT_SIZE_NORMAL,
                //   color: checked ? COLORS.TEXT_ACTIVE : COLORS.TEXT_INACTIVE,
                // }}
              >
                {productGroupName}
              </XText>

              {s_use_volume_discount && checked && !VIEW_VOLUME_DISCOUNT ? (
                <Button
                  onPress={() => {
                    this.handleNavToVolumeDiscountListView(productGroupId)
                  }}
                  variant='outline'
                  style={{
                    height: 35,
                    // width: 130,
                    // backgroundColor: COLORS.BG_LIGHT_GREY,
                    // borderColor: COLORS.APP_MAIN,
                    // borderWidth: 1,
                    // borderRadius: 7,
                    // paddingLeft: 2,
                    // paddingRight: 2,
                  }}>
                  แก้ไขส่วนลดขายส่ง
                </Button>
              ) : null}
              {s_use_volume_discount && VIEW_VOLUME_DISCOUNT ? (
                <Button
                  onPress={() => {
                    this._unpairVolumeDiscount(productGroupId, productGroupName)
                  }}
                  variant='outline'
                  style={{
                    // backgroundColor: COLORS.BG_LIGHT_GREY,
                    height: 35,
                    width: 130,
                    // borderColor: COLORS.APP_MAIN,
                    // borderWidth: 1,
                    // borderRadius: 7,
                    // paddingLeft: 2,
                    // paddingRight: 2,
                  }}>
                  ยกเลิกส่วนลดขายส่ง
                </Button>
              ) : null}
            </HStack>
            {/* </TouchableOpacity> */}
            {checked ? (
              <HStack w='full' pt='2'>
                <RangeAdjuster
                  mode='free'
                  disabled
                  metadata={[
                    {
                      // key: discountKey,
                      prefix,
                      suffix,
                      usePrefixOrSuffix,
                      key: 'val',
                      label: type === 1 ? 'ส่วนลด (บาทต่อชิ้น)' : 'ส่วนลด (%)',
                      span: 2,
                      defaultValue: '',
                      keyboardType: 'numeric',
                    },
                  ]}
                  data={variants}
                  labelAddButton='เพิ่มช่วงส่วนลด'
                  labelLastEnd='ขึ้นไป'
                />
              </HStack>
            ) : null}
          </VStack>
        </XCard.Body>
      </XCard>
    )
  }

  _renderCheckbox = (productGroupID, productGroupName, checked, VIEW_VOLUME_DISCOUNT) => {
    const s_use_volume_discount = this.props.selectedStore.get('s_use_volume_discount')
    return (
      <XCard key={productGroupID} w='full' onPress={() => this._toggleCheckboxAtId(productGroupID, checked)}>
        <XCard.Body>
          <HStack
            w='full'
            // px='2'
            // py='2'
            space='1'
            // borderBottomColor='warmGray.200'
            // borderBottomWidth='1'
            alignItems='center'
            // justifyContent='space-between'
          >
            {/* <TouchableOpacity
              key={productGroupID}
              style={S.FLEX}
              // button={!checked}
              onPress={() => this._toggleCheckboxAtId(productGroupID, checked)}> */}
            <HStack space='1' flex={1} alignItems='center'>
              <XIcon
                family='FontAwesome'
                // style={{
                //   flex: 1,
                //   fontSize: STYLES.FONT_ICON_NORMAL,
                //   color: COLORS.APP_MAIN,
                // }}
                name={checked ? 'check-square-o' : 'square-o'}
              />
              <XText
                flex={1}
                variant={checked ? 'active' : 'inactive'}
                // allowFontScaling={false}
                // style={{
                //   textAlign: 'left',
                //   fontSize: STYLES.FONT_SIZE_NORMAL,
                //   color: checked ? COLORS.TEXT_ACTIVE : COLORS.TEXT_INACTIVE,
                // }}
              >
                {productGroupName}
              </XText>
            </HStack>
            {/* </TouchableOpacity> */}
            {s_use_volume_discount && checked && !VIEW_VOLUME_DISCOUNT ? (
              <Button
                onPress={() => {
                  this.handleNavToVolumeDiscountListView(productGroupID)
                }}
                style={{
                  // backgroundColor: COLORS.APP_MAIN,
                  height: 35,
                  width: 130,
                  // borderRadius: 7,
                  // paddingLeft: 2,
                  // paddingRight: 2,
                }}>
                เพิ่มส่วนลดขายส่ง
              </Button>
            ) : null}
          </HStack>
        </XCard.Body>
      </XCard>
    )
  }

  // _renderSelectedProductGroups(productGroups: List<ProductGroupMap> | ProductGroup[], selectedIds: number[]): JSX.Element | JSX.Element[] {
  //   const pgs = List.isList(productGroups) ? productGroups.toJS() : productGroups
  //   if (!pgs || pgs.length < 1) {
  //     return <PGModalText>{'ยังไม่มีรายการราคา'}</PGModalText>
  //   }
  //
  //   if (!selectedIds || selectedIds.length < 1) {
  //     return <PGModalText>{'ยังไม่ได้เลือกรายการราคา'}</PGModalText>
  //   }
  //
  //   return selectedIds.map((pg_id, idx) => {
  //     const selectedGroup = pgs.find(pg => pg.id === pg_id)
  //     if (!selectedGroup || !selectedGroup.id || !selectedGroup.name) {
  //       return null
  //     }
  //     return (
  //       <PGModalSelectedText key={idx}>
  //         {selectedGroup.name}
  //       </PGModalSelectedText>
  //     )
  //   })
  // }

  // _renderCheckboxProductGroups = (productGroups: List<ProductGroupMap> | ProductGroup[], selectedIds: number[]): JSX.Element | JSX.Element[] => {
  _renderCheckboxProductGroups = (): JSX.Element | JSX.Element[] => {
    const { productGroups, editingProduct, vds, selectedStore } = this.props
    // const selectedIds = util.getNavParam(this.props, 'selectedIds')
    const { selectedIds } = this.state

    const pgs = List.isList(productGroups) ? productGroups.toJS() : productGroups
    return pgs.map((productGroup: IProductGroup, idx: number) => {
      const isChecked = selectedIds.findIndex((pg_id) => pg_id === productGroup.id) > -1
      const pg_vd_ids = editingProduct.get('pg_vd_ids')
      let renderVolumeDiscount = false
      if (pg_vd_ids) {
        renderVolumeDiscount = true
      }
      let vd_data = null
      // console.log(productGroup.id)
      if (renderVolumeDiscount) {
        const PG_VD_IDS_JS = pg_vd_ids.toJS()
        PG_VD_IDS_JS.map((vd: IPG_VD_IDS) => {
          if (vd.pg_id === productGroup.id) {
            vds.forEach((valDiscount: IVolumeDiscountMap) => {
              if (valDiscount.get('id') === vd.vd_id) {
                // return (vd_data = valDiscount)
                vd_data = valDiscount
              }
            })
            // return valDiscount
          }
        })
      }
      let VIEW_VOLUME_DISCOUNT = false
      // @ts-ignore
      const volumeDiscountID = util.getNavParam(this.props, 'volumeDiscountID')
      if (!_.isNil(volumeDiscountID)) {
        VIEW_VOLUME_DISCOUNT = true
      }
      const s_use_volume_discount = selectedStore.get('s_use_volume_discount')
      if (s_use_volume_discount !== true) {
        return this._renderCheckbox(productGroup.id, productGroup.name, isChecked, VIEW_VOLUME_DISCOUNT)
      }
      if (!_.isNil(vd_data)) {
        let usePrefixOrSuffix
        const prefix = '฿'
        const suffix = '%'
        // console.log(vd_data.get('type'))
        if (vd_data.get('type') === 1) {
          usePrefixOrSuffix = prefix
        } else {
          usePrefixOrSuffix = suffix
        }

        return this._renderCheckboxHaveVolumeDiscountList(
          usePrefixOrSuffix,
          prefix,
          suffix,
          vd_data.get('type'),
          vd_data.get('variants'),
          productGroup.id,
          productGroup.name,
          isChecked,
          VIEW_VOLUME_DISCOUNT
        )
      }
      return this._renderCheckbox(productGroup.id, productGroup.name, isChecked, VIEW_VOLUME_DISCOUNT)
    })
  }

  // _renderHeaderLeft = () => <NavHeaderButton backIcon={true} onPressItem={this._onCancelEditing} />
  _renderHeaderLeft = () => <NavHeaderButton backIcon onPressItem={this.onPressGoBack} />

  _renderHeaderRight = () => <NavHeaderButton label='ตกลง' onPressItem={this.onSubmitEditing} />

  _getTxtvalueDiscount = (valDiscount) => {
    const variants = valDiscount.get('variants')
    const minVal = variants.getIn([0, 'val'])
    const maxVal = variants.getIn([variants.size - 1, 'val'])
    const min = variants.getIn([0, 'min'])
    const max = variants.getIn([variants.size - 1, 'min'])
    const name = valDiscount.get('name')
    if (valDiscount.get('type') === 1) {
      return `${name}\nลด ฿${minVal}-฿${maxVal} สำหรับจำนวน ${min}-${max} ขึ้นไป (${variants.size} ขั้น)`
    }
    return `${name}\nลด ${minVal}%-${maxVal}% สำหรับจำนวน ${min}-${max} ขึ้นไป (${variants.size} ขั้น)`
  }

  // _renderPGVDList = (productGroups: List<ProductGroupMap> | ProductGroup[], selectedIds: number[]): JSX.Element | JSX.Element[] => {
  //   const pgs = List.isList(productGroups) ? productGroups.toJS() : productGroups
  //   const { editingProduct, vds, mode } = this.props
  //   let textWerning = `กรุณากดปุ่ม แก้ไข (ด้านขวาบน)\nและแตะปุ่มนี้ เพื่อผูกราคาขาย สำหรับสินค้านี้`
  //   if (mode !== 'VIEW_SELF') {
  //     textWerning = `กรุณาแตะเพื่อ ผูกราคาขาย สำหรับสินค้านี้\n(แล้วจึงระบุราคาด้านล่าง)`
  //   }
  //   const pg_vd_ids = editingProduct.get('pg_vd_ids')
  //   let renderVolumeDiscount = false
  //   if (pg_vd_ids) {
  //     renderVolumeDiscount = true
  //   }
  //   if (selectedIds.length > 0) {
  //     return selectedIds.map((pg_id, idx) => {
  //       const selectedGroup = pgs.find(pg => pg.id === pg_id)
  //       if (!selectedGroup || !selectedGroup.id || !selectedGroup.name) {
  //         return null
  //       }
  //       const s_use_volume_discount = this.props.selectedStore.get('s_use_volume_discount')
  //       let txt
  //       let selectedVD
  //       if (renderVolumeDiscount) {
  //         selectedVD = pg_vd_ids.map(vd => {
  //           if (vd.get('pg_id') === pg_id) {
  //             vds.map(valDiscount => {
  //               if (valDiscount.get('id') === vd.get('vd_id')) {
  //                 return (txt = getTxtvalueDiscount(valDiscount))
  //               }
  //             })
  //             return txt
  //           }
  //         })
  //       }
  //       return (
  //         <Row size={7} key={idx}>
  //           <View
  //             style={{
  //               padding: 4,
  //               marginRight: 4,
  //               marginBottom: 2,
  //               borderWidth: 1,
  //               borderStyle: 'solid',
  //               borderColor: APP_SECONDARY,
  //               // borderColor: BG_LIGHT_GREY,
  //               marginTop: 4,
  //               borderRadius: 4,
  //               flex: 1,
  //               // backgroundColor: APP_SECONDARY,
  //             }}>
  //             <XText
  //               style={{
  //                 // color: TEXT_INACTIVE,
  //                 color: TEXT_ACTIVE,
  //                 textAlign: 'left',
  //                 fontSize: STYLES.FONT_SIZE_NORMAL,
  //                 fontWeight: 'bold',
  //                 marginTop: 0,
  //                 paddingLeft: 4,
  //               }}>
  //               {selectedGroup.name}
  //             </XText>
  //             {s_use_volume_discount ? (
  //               <View>
  //                 {renderVolumeDiscount ? (
  //                   <XText
  //                     style={{
  //                       // color: TEXT_INACTIVE,
  //                       color: TEXT_INACTIVE,
  //                       textAlign: 'left',
  //                       fontSize: STYLES.FONT_SIZE_NORMAL,
  //                       fontWeight: 'bold',
  //                       marginTop: 0,
  //                       paddingLeft: 4,
  //                     }}>
  //                     {selectedVD}
  //                   </XText>
  //                 ) : null}
  //               </View>
  //             ) : null}
  //           </View>
  //           {/* <VDModalSelectedText>{selectedGroup.name} BBCCTOME</VDModalSelectedText> */}
  //         </Row>
  //       )
  //     })
  //   } else if (pgs.length > 0) {
  //     return (
  //       <Row size={7}>
  //         <View
  //           style={{
  //             padding: 4,
  //             marginRight: 4,
  //             marginBottom: 2,
  //             borderStyle: 'solid',
  //             marginTop: 4,
  //             flex: 1,
  //             paddingLeft: 0,
  //           }}>
  //           <XText
  //             style={{
  //               // color: TEXT_INACTIVE,
  //               color: 'red',
  //               textAlign: 'left',
  //               fontSize: STYLES.FONT_SIZE_NORMAL,
  //               fontWeight: 'bold',
  //               marginTop: 0,
  //               paddingLeft: 0,
  //             }}>
  //             {textWerning}
  //           </XText>
  //         </View>
  //       </Row>
  //     )
  //   }
  //   // KEEP_ME :: อาจได้ใช้ ถ้าไม่ติดปัญหาอะไร จะมาลบ artid
  //   // else if (pgs.length > 0) {
  //   //   return pgs.map((pg_id, idx) => {
  //   //     console.log('pg_id', pg_id)
  //   //     return (
  //   //       <Row size={7} key={idx}>
  //   //         <View
  //   //           style={{
  //   //             padding: 4,
  //   //             marginRight: 4,
  //   //             marginBottom: 2,
  //   //             borderWidth: 1,
  //   //             borderStyle: 'solid',
  //   //             borderColor: APP_SECONDARY,
  //   //             // borderColor: BG_LIGHT_GREY,
  //   //             marginTop: 4,
  //   //             borderRadius: 4,
  //   //             flex: 1,
  //   //             // backgroundColor: APP_SECONDARY,
  //   //           }}>
  //   //           <XText
  //   //             style={{
  //   //               // color: TEXT_INACTIVE,
  //   //               color: TEXT_ACTIVE,
  //   //               textAlign: 'left',
  //   //               fontSize: STYLES.FONT_SIZE_NORMAL,
  //   //               fontWeight: 'bold',
  //   //               marginTop: 0,
  //   //               paddingLeft: 4,
  //   //             }}>
  //   //             {pg_id.name}
  //   //           </XText>
  //   //         </View>
  //   //         {/* <VDModalSelectedText>{selectedGroup.name} BBCCTOME</VDModalSelectedText> */}
  //   //       </Row>
  //   //     )
  //   //   })
  //   // }
  // }

  // _checkRenderModel = () => {
  //   const { productGroups, editingProduct, navigation } = this.props
  //   const { dispatch } = navigation
  //   const store_id = editingProduct.get('store_id')
  //   const pg_id = editingProduct.getIn('product_group_ids', 0)
  //   const pt_id = editingProduct.get('id')
  //   // console.log('pg_id ' + pg_id )
  //   // console.log('pt_id ' + pt_id )
  //   if (!List.isList(productGroups) || productGroups.size <= 1) {
  //     // dispatch(
  //     //   NavActions.navToVolumeDiscountListView({
  //     //   store_id,
  //     //   pg_id,
  //     //   pt_id,
  //     //   mode: 'PULL_MODE'
  //     // }),
  //     // )
  //   } else {
  //     this._openSelector()
  //   }
  // }

  render() {
    // const { disabled = false, productGroups, style = {}, mode } = this.props
    // const { isVisibleSelector, selectedIds } = this.state

    return (
      <XContainer>
        <XCustomHeader title='เลือกรายการราคา' headerLeft={this._renderHeaderLeft()} headerRight={this._renderHeaderRight()} />
        <XContent
        // _light={{ _contentContainerStyle: { bg: 'white' } }}
        >
          <VStack w='full' p='1' space='1'>
            {/* <View style={{ flex: 1 }}>{this._renderCheckboxProductGroups(productGroups, selectedIds)}</View> */}
            {this._renderCheckboxProductGroups()}
          </VStack>
        </XContent>
      </XContainer>
    )

    // return (
    //   <Card style={style}>
    //     <XModal
    //       onRequestClose={this._onCancelEditing}
    //       // contentStyle={{ alignItems: 'center', backgroundColor: 'white' }}
    //       title={'เลือกรายการราคา'}
    //       headerLeft={this._renderHeaderLeft}
    //       headerRight={this._renderHeaderRight}
    //       visible={isVisibleSelector}>
    //       <XContent style={{ backgroundColor: BG_LIGHT_GREY }}>
    //         <View style={{ flex: 1 }}>{this._renderCheckboxProductGroups(productGroups, selectedIds)}</View>
    //       </XContent>
    //     </XModal>
    //     <Box
    //       cardBody={true}
    //       button={!disabled}
    //       style={{ paddingTop: 5, paddingBottom: 5 }}
    //       onPress={disabled ? null : this._openSelector}>
    //       <Stack style={{ marginLeft: 12, marginRight: 12, flex: 10 }}>
    //         <VStack size={9}>
    //           <Row size={2}>
    //             <XText style={{ color: COLORS.TEXT_INACTIVE, fontSize: STYLES.FONT_SIZE_NORMAL }}>อยู่ในรายการราคา</XText>
    //           </Row>
    //           {this._renderPGVDList(productGroups, selectedIds)}
    //           {/* <Row size={6}>
    //             <VDModalSelectedText key={1}>BBBBBBBBBBBBBBBBBBBBBBB</VDModalSelectedText>
    //           </Row> */}
    //         </VStack>
    //         {this.props.mode === 'EDIT_SELF' ? (
    //           <VStack size={1} style={{ justifyContent: 'center' }}>
    //             <ForwardIcon style={{ flex: 0 }} />
    //           </VStack>
    //         ) : null}
    //       </Stack>
    //       {/* <Stack>
    //         <Row>
    //           <VStack size={17} style={{ paddingHorizontal: 8 }}>
    //             <Row>
    //               <VStack
    //                 size={1}
    //                 style={{
    //                   justifyContent: 'flex-start',
    //                   alignItems: 'flex-start',
    //                   paddingHorizontal: 8,
    //                   paddingVertical: 4,
    //                 }}>
    //                 <XText
    //                   style={{
    //                     flex: 1,
    //                     textAlign: 'left',
    //                     fontSize: STYLES.FONT_SIZE_NORMAL,
    //                     // color: TEXT_ACTIVE,
    //                     color: TEXT_INACTIVE,
    //                     fontWeight: 'bold',
    //                     marginTop: 0,
    //                   }}>
    //                   {'อยู่ในรายการราคา'}
    //                 </XText>
    //               </VStack>
    //             </Row>
    //             <Row
    //               style={{
    //                 flexDirection: 'row',
    //                 flexWrap: 'wrap',
    //                 // justifyContent: 'center',
    //                 justifyContent: 'flex-start',
    //                 alignItems: 'flex-start',
    //                 paddingHorizontal: 8,
    //               }}>
    //               {this._renderSelectedProductGroups(productGroups, selectedIds)}
    //             </Row>
    //           </VStack>
    //           <VStack
    //             size={3}
    //             style={{
    //               justifyContent: 'center',
    //               alignItems: 'flex-end',
    //               paddingHorizontal: 8,
    //             }}>
    //             {disabled ? null : <Icon name="arrow-forward" style={{ color: COLORS.TEXT_PRIMARY }} />}
    //           </VStack>
    //         </Row>
    //       </Stack> */}
    //     </Box>
    //   </Card>
    // )
  }
}
