import React from 'react'
import p from 'x/config/platform-specific'
import * as NavActions from 'x/utils/navigation'
import * as util from 'x/utils/util'
import { ISelectedStoreMap, IXScreenProps } from 'x/types'
import { IIconType } from 'xui/components/Icon'

// const ICON_MY_STORE = require('../../../../../xui/x/img/tb/tb_my0.png')

export interface IStoreSettingListSections {
  txtTitle: string
  data: IStoreSettingMenuList[]
}
// icon: { name: 'view-grid', family: 'MaterialCommunityIcons', style: { paddingTop: 2 } },
export interface IStoreSettingMenuList {
  labelKey: string
  navAction: any
  iconImage?: {
    name: any
    style: any
  }
  icon?: {
    name: string
    family: IIconType
    style?: any
  }
}

export interface BaseStoreSettingAllPrintingShippingLabelViewProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
  resetEditingStore: () => void
}

export interface BaseStoreSettingAllPrintingShippingLabelViewState {
  STORE_SETTING_LIST_SECTIONS: IStoreSettingListSections[]
}

export default abstract class BaseStoreSettingAllPrintingShippingLabelView extends React.Component<
  BaseStoreSettingAllPrintingShippingLabelViewProps,
  BaseStoreSettingAllPrintingShippingLabelViewState
> {
  // DISPLAY_TABS: IUserListTab[]
  // _tabRef: React.RefObject<any>
  // helperProfileId: number

  protected constructor(props) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    super(props)
    this.state = {
      // eslint-disable-next-line react/no-unused-state
      STORE_SETTING_LIST_SECTIONS: null,
    }
    // this._tabRef = React.createRef()
  }

  async componentDidMount() {
    // const { navigation, selectedStore } = this.props
    // const { state } = navigation
    // const { tabIndex, permissionList, permission_id } = state.params
    const { selectedStore } = this.props
    if (!selectedStore.get('id')) {
      p.op.showConfirmationOkOnly('การเข้าถึงที่ไม่ถูกต้อง', 'ยังไม่มีการเลือกร้านค้าที่จะตั้งค่า')
      util.navGoBack(this.props)
    } else {
      // resetEditingStore()
      await this._loadStoreMenu()
    }
  }

  _loadStoreMenu = async () => {
    // const { selectedStore } = this.props
    // const store_id = nextStore ? nextStore.get('id') : selectedStore.get('id')
    // const store_name = nextStore ? nextStore.get('name') : selectedStore.get('name')
    const MENU = [
      {
        txtTitle: `${p.op.t('ShippingLabel')}`,
        // iconName: 'store',
        // iconFamily: 'MaterialCommunityIcons',
        data: [
          { labelKey: 'MenuPaperangShippingLabel', navAction: NavActions.navToStoreSettingPaperang },
          { labelKey: 'MenuPDFA4ShippingLabel', navAction: NavActions.navToStoreSettingPDFShippingLabel },
          { labelKey: 'MenuPDFStickerShippingLabel', navAction: NavActions.navToStoreSettingPDFStickerShippingLabel },
        ],
      },
      {
        txtTitle: p.op.t('receipt'),
        data: [{ labelKey: 'MenuPaperangShippingLabel', navAction: NavActions.navToStoreSettingBillView }],
      },
      {
        txtTitle: p.op.t('product_barcode'),
        data: [{ labelKey: 'productLabel', navAction: NavActions.navToStoreSettingPrintingBarcode }],
      },
    ]
    this.setState({
      // eslint-disable-next-line react/no-unused-state
      STORE_SETTING_LIST_SECTIONS: MENU,
    })
  }

  // _loadStoreMenu = async () => {
  // const { selectedStore } = this.props
  // const store_id = nextStore ? nextStore.get('id') : selectedStore.get('id')
  // const store_name = nextStore ? nextStore.get('name') : selectedStore.get('name')
  // const MENU = []
  // await util.setStatePromise(this, {
  //   STORE_SETTING_LIST_SECTIONS: MENU,
  // })
  // this.setState({
  //   STORE_SETTING_LIST_SECTIONS: MENU,
  // })
  // }
}
