// import { Platform } from 'react-native'

// const disabledTextColor = () => mode('muted.500', 'muted.300')

function baseStyle(props: any) {
  const { colorScheme: c = 'primary' } = props
  // const { primary, error } = props.theme.colors

  return {
    // fontFamily: 'body',
    // fontFamily: 'Prompt-Regular',
    // py: '2',
    // px: '3',
    p: '1',
    // borderRadius: 'sm',
    borderRadius: 'lg',
    // overflow: 'hidden',
    // overflowY: 'auto',
    minH: '9',

    // minH: '44px',
    _disabled: {
      opacity: '0.8',
      _web: {
        disabled: true,
        // cursor: 'not-allowed',
        cursor: 'auto',
      },
    },

    _web: {
      outlineWidth: '0',
      overflow: 'auto',
      lineHeight: 'lg', // Todo: Move to _web inside size so that sm and xs don't have this much height
      style: { outline: 'none' },
      cursor: 'auto',
    },

    _input: {
      fontFamily: 'Prompt-Regular',
      bg: 'transparent',
      flex: 1,
      w: '100%',
      h: '100%',
      // top: '0',
      // bottom: '0',
      // py: '2',
      // px: '3',
      // minH: '9',
    },
    _light: {
      placeholderTextColor: 'text.400',
      color: 'text.900',
      borderColor: 'muted.300',
      _hover: {
        borderColor: `${c}.600`,
      },
      _focus: {
        borderColor: `${c}.600`,
        _hover: { borderColor: `${c}.600` },
        _stack: {
          style: {
            outlineWidth: '1px',
            // outlineColor: `${props.focusOutlineColor || primary[600]}`,
            outlineColor: props.inValidOutlineColor || `${c}.600`,
            outlineStyle: 'solid',
          },
        },
      },
      _invalid: {
        borderColor: 'error.600',
        _hover: { borderColor: 'error.600' },
        _stack: {
          style: {
            outlineWidth: '1px',
            // outlineColor: `${props.inValidOutlineColor || error[600]}`,
            outlineColor: props.inValidOutlineColor || 'error.600',
            outlineStyle: 'solid',
          },
        },
      },
      _ios: {
        // selectionColor: 'coolGray.800',
        selectionColor: `${c}.600`,
      },
      _android: {
        // selectionColor: 'coolGray.800',
        selectionColor: `${c}.600`,
      },
      _disabled: {
        // placeholderTextColor: 'text.700',
        placeholderTextColor: 'muted.700',
        _hover: {
          borderColor: 'muted.300',
        },
      },
      _stack: {
        flexDirection: 'row',
        alignItems: 'center',
        // justifyContent: 'space-between',
        overflow: 'hidden',
      },
    },
    _dark: {
      placeholderTextColor: 'text.600',
      color: 'text.50',
      borderColor: 'muted.700',
      _hover: {
        borderColor: `${c}.500`,
      },
      _focus: {
        borderColor: `${c}.500`,
        _hover: { borderColor: `${c}.500` },
        _stack: {
          style: {
            outlineWidth: '1px',
            // outlineColor: `${props.focusOutlineColor || primary[500]}`,
            outlineColor: props.inValidOutlineColor || `${c}.500`,
            outlineStyle: 'solid',
          },
        },
      },
      _invalid: {
        borderColor: 'error.500',
        _stack: {
          style: {
            outlineWidth: '1px',
            // outlineColor: `${props.inValidOutlineColor || error[500]}`,
            outlineColor: props.inValidOutlineColor || 'error.500',
            outlineStyle: 'solid',
          },
        },
        _hover: { borderColor: 'error.500' },
      },
      _ios: {
        // selectionColor: 'warmGray.50',
        selectionColor: `${c}.600`,
      },
      _android: {
        // selectionColor: 'warmGray.50',
        selectionColor: `${c}.600`,
      },
      _disabled: {
        placeholderTextColor: 'text.50',
        _hover: {
          borderColor: 'muted.700',
        },
      },
      _stack: {
        flexDirection: 'row',
        alignItems: 'center',
        // justifyContent: 'space-between',
        overflow: 'hidden',
      },
    },
  }
}

// function getSelectionColor(props: Record<string, any>) {
//   // if (Platform.OS === 'ios') {
//   //   return mode('coolGray.800', 'warmGray.50')
//   // }
//   // if (Platform.OS === 'android') {
//   //   return mode('coolGray.800', 'warmGray.50')
//   // }
//   return mode('coolGray.800', 'warmGray.100')
// }

// const baseStyle = (props: Record<string, any>) => ({
//   // flex: 1,
//   minW: '12',
//   h: '10',
//   selectionColor: getSelectionColor(props),
//   fontFamily: 'body',
//   p: '2',
//   // px: '2',
//   // py: '1',
//   borderRadius: 'sm',
//   // color: mode('coolGray.800', 'warmGray.50'),
//   // color: mode('primary.500', 'primary.400'),
//   color: 'primary.500',
//   placeholderTextColor: 'muted.400',
//   bg: 'transparent',
//   // background: 'transparent',
//   borderColor: mode('muted.200', 'gray.500'),
//   _disabled: {
//     opacity: '80',
//     // color: disabledTextColor(),
//     color: mode('coolGray.800', 'warmGray.50'),
//     bg: mode('muted.100', 'muted.700'),
//   },
//   _invalid: {
//     borderColor: mode('danger.600', 'danger.300'),
//   },
//   _focus: {
//     borderColor: mode('primary.400', 'primary.500'),
//   },
//   _web: {
//     outlineWidth: '0',
//     lineHeight: 'lg', // Todo: Move to _web inside size so that sm and xs don't have this much height
//   },
//   // FIXME: ความจริงไม่ควรใส่แบบนี้ defaultProps มี variant = 'filled' ควรจะเพียงพอแล้ว แต่มันไม่ work
//   ...filledStyle(props),
// })

// function roundedStyle(props: Record<string, any>) {
//   return {
//     borderRadius: '25',
//     borderWidth: '1',
//     _hover: {
//       // bg: mode('gray.100', 'gray.700'),
//       bg: mode('primary.100', 'primary.700'),
//     },
//   }
// }
// function outlineStyle(props: Record<string, any>) {
//   return {
//     borderWidth: '1',
//     _hover: {
//       // bg: mode('gray.100', 'gray.700'),
//       bg: mode('primary.100', 'primary.700'),
//     },
//   }
// }

function filledStyle(props: Record<string, any>) {
  const { theme, colorScheme: c = 'primary' } = props
  return {
    borderWidth: '1',
    // _disabled: {
    //   // bg: 'tranparent',
    //   bg: 'muted.100',
    // },
    _focus: {
      // bg: tt.transparentize('primary.600', 0.1)(theme),
      // bg: tt.transparentize(`${c}.500`, 0.1)(theme),
      // bg: `${c}.500:alpha.10`,
      bg: `${c}.200`,
    },
    _hover: {
      borderWidth: '1',
      _disabled: {
        borderWidth: 0,
      },
    },
    _light: {
      bg: `${c}.100`,
      // bg: 'muted.100',
      borderColor: 'muted.200',
      // borderColor: `${c}.200`,
      _disabled: {
        bg: 'muted.100',
      },
    },
    _dark: {
      bg: `${c}.200`,
      // bg: 'muted.800',
      borderColor: 'muted.800',
      _disabled: {
        bg: 'muted.700',
      },
    },
  }
}

// function filledStyle(props: Record<string, any>) {
//   return {
//     // bg: props.bg || mode('muted.200', 'muted.600'),
//     bg: props.bg || mode('primary.100', 'primary.200'),
//     borderWidth: '1',
//     borderColor: 'transparent',
//     _hover: {
//       // bg: mode('muted.300', 'muted.700'),
//       bg: mode('primary.300', 'primary.700'),
//     },
//   }
// }

// function unstyledStyle() {
//   return {
//     borderWidth: '0',
//   }
// }

// function underlinedStyle() {
//   return {
//     borderRadius: '0',
//     borderBottomWidth: '1',
//   }
// }

const variants = {
  // outline: outlineStyle,
  // underlined: underlinedStyle,
  // rounded: roundedStyle,
  filled: filledStyle,
  // unstyled: unstyledStyle,
}

const sizes = {
  '2xl': { fontSize: '2xl' },
  xl: { fontSize: 'xl' },
  lg: { fontSize: 'lg' },
  md: { fontSize: 'md' },
  sm: { fontSize: 'sm' },
  xs: { fontSize: 'xs' },
  '2xs': { fontSize: '2xs' },
}

const defaultProps = {
  size: 'sm',
  variant: 'filled',
  colorScheme: 'primary',
}

export default {
  baseStyle,
  defaultProps,
  variants,
  sizes,
}
