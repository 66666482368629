/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react'

import XOverlay from 'xui/components/XOverlay'
import HStack from 'xui/components/HStack'
import Box from 'xui/components/Box'
import XText from 'xui/components/XText'
import XButton from 'xui/components/XButton'
import XRadioList from 'xui/components/XRadioList'
import _ from 'lodash'
import * as util from 'x/utils/util'
import { IMKPChannelDetail } from 'x/index'
import HelpButton from 'xui/components/HelpButton'

interface IOverlaySyncMKPProductProps {
  channel: IMKPChannelDetail
  onClose: () => void
  isOverlayVisible: boolean
  onSubmit: (forceSync: boolean) => void
}

interface IDataList {
  label: string
  index: number
}

const dataList: IDataList[] = [
  {
    label: 'จากอัพเดทล่าสุด (แนะนำ)',
    index: 0,
  },
  {
    label: 'ทั้งหมด (ใช้เวลานานกว่า)',
    index: 1,
  },
]

const _renderAgreement = (onPressOpenOverlay: () => void) => <HelpButton onPress={() => onPressOpenOverlay()} />

const OverlaySyncMKPProduct: React.FC<IOverlaySyncMKPProductProps> = ({ channel, onClose, isOverlayVisible, onSubmit }) => {
  const [selectedIndex, updateSelectedIndex] = useState(0)

  if (_.isNil(channel)) {
    return null
  }

  const onRadioChange = (item) => {
    // console.log('item => ', item)
    updateSelectedIndex(item.index)
  }
  const submitBtn = () => {
    if (isLineMkp) {
      onSubmit(true)
    } else {
      onSubmit(selectedIndex === 1)
    }
  }

  const channelMKPName = util.getMKPName(channel.mkp_id)
  const isLineMkp = channel.mkp_id === 4
  const _renderOverlay = () => (
    <XOverlay
      contentStyle={{ width: 310, alignSelf: 'center' }}
      visible={isOverlayVisible}
      // webNumColumns={1}
      onRequestClose={() => onClose()}>
      <Box w='full' p='2' bgColor='white' borderRadius='md'>
        <HStack alignItems='center'>
          <XText variant='active' bold>
            {`ซิงค์ข้อมูลสินค้าจาก ${channelMKPName}`}
          </XText>
          {/* <XHelpModal
            key='sync_sync_product'
            headerTitle='ซิงค์ข้อมูลสินค้าจากช่องทางขาย'
            FAQ={FAQ.MKP_SYNC_PRODUCT}
            initiateOpenIndex={3}
            renderButton={(onPressOpenOverlay: () => void) => _renderAgreement(onPressOpenOverlay)}
          /> */}
        </HStack>
        {isLineMkp ? (
          <XText>ข้อมูลของสินค้าทุกรายการบนร้านใน LINE SHOPPING จะถูกซิงค์และประมวลผล (อาจใช้เวลาซักครู่ถ้าสินค้ามีจำนวนมากรายการ)</XText>
        ) : (
          <XRadioList options={dataList} onRadioChange={onRadioChange} selectedIndex={selectedIndex} />
        )}
        <XButton mt='2' onPress={() => submitBtn()}>
          ดำเนินการซิงค์
        </XButton>
      </Box>
    </XOverlay>
  )

  // Main render
  return (
    <>
      {/* {_renderControlButton()} */}
      {_renderOverlay()}
    </>
  )
}

export default OverlaySyncMKPProduct
