/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/no-unused-state */
import React from 'react'
import {
  IProductItemData,
  IStoreWebstieOneOptionProduct,
  ISalePageOneOptionVariants,
  ISelectedStoreMap,
  IStoreWebsite,
  IXScreenProps,
} from 'x/index'
import * as NavActions from 'x/utils/navigation'
import * as util from 'x/utils/util'
import _ from 'lodash'

interface ISalePageTemplate {
  template: string
  title: string
  description: string
}

interface IBaseSalePageSelectProductViewProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
  subscription: any
  sellerStores: any
}

interface IBaseSalePageSelectProductViewState {
  salePage: IStoreWebsite

  optionChooseProductsByGroupIndex: number
  optionChooseProductsFollowingIndex: number
  optionChooseProductsFromIndex: number
  optionSelectedSellerStoreIndex: number

  selectedProducts: IProductItemData[]

  product: IStoreWebstieOneOptionProduct
  productIndex: number

  isEdit: boolean
  productName: string

  title: string
  mode: 'SINGLE_PRODUCT_MODE' | 'PRODUCT_MULTI_OPTIONS_MODE' | 'PRODUCT_SET_MODE'
}

export default abstract class BaseSalePageSelectProductView extends React.Component<
  IBaseSalePageSelectProductViewProps,
  IBaseSalePageSelectProductViewState
> {
  // abstract async handleSubmitForm(response: any): Promise<void>

  TEMPLATE_LIST: ISalePageTemplate[]

  constructor(props) {
    super(props)
    this.state = {
      salePage: null,

      optionChooseProductsByGroupIndex: 0,
      optionChooseProductsFollowingIndex: 0,
      optionChooseProductsFromIndex: 0,
      optionSelectedSellerStoreIndex: 0,

      selectedProducts: [],
      productIndex: 0,
      product: null,
      isEdit: false,
      productName: '',
      title: '',
      mode: 'PRODUCT_MULTI_OPTIONS_MODE',
    }
    // this.webview = React.createRef()
  }

  componentDidMount() {
    const params = util.getNavParams(this.props)
    const { product, productIndex, title, mode } = params
    // console.log('product -> ', product)
    // console.log('productIndex -> ', productIndex)
    this.setState({
      product: _.isNil(product) ? null : product,
      productIndex,
      productName: product && product.name ? product.name : '',
      title,
      mode,
    })
  }

  _navToSalePageSettingWebView = () => {
    const { salePage } = this.state
    // @ts-ignore
    const { navigation } = this.props
    navigation.dispatch(
      NavActions.navToSalePageSetttingWebView({
        storeWebsite: salePage,
      })
    )
  }

  _onChangeProductName = (newName: string) => {
    this.setState({ productName: newName, isEdit: true })
  }

  _setIsEdit = () => {
    this.setState({
      isEdit: true,
    })
  }

  _saveBtn = () => {
    const { product, selectedProducts, productIndex } = this.state
    // if (productName.length < 1) {
    //   p.op.showConfirmationOkOnly('', 'กรุณาระบุชื่อตัวเลือกสินค้า')
    //   return
    // }
    // if (_.isNil(selectedProducts) || selectedProducts.length < 1) {
    //   p.op.showConfirmationOkOnly('', 'กรุณาระบุตัวเลือกสินค้าอย่างน้อย 1 ตัวเลือก')
    //   util.navGoBack(this.props)
    //   return
    // }
    // @ts-ignore
    const newProduct: IStoreWebstieOneOptionProduct = !_.isNil(product) ? _.clone(product) : {}
    const newVariants = []
    // console.log('selectedProducts ** => ', selectedProducts)
    if (!_.isNil(selectedProducts) && selectedProducts.length < 1) {
      return
    }
    selectedProducts.forEach((pt) => {
      // console.log('PT => ', pt)
      // const name = productName
      const newPt: ISalePageOneOptionVariants = {
        name: pt.n,
        variantName: pt.v[0].n,
        pp_id: pt.v[0].i,
        price: pt.v[0].p ? pt.v[0].p : pt.i,
        qty: pt.count_number ? pt.count_number : 1,
        img: pt.t,
      }
      newVariants.push(newPt)
    })
    newProduct.variants = newVariants
    // console.log('newProduct => ', newProduct)
    const params = util.getNavParams(this.props)
    const { callBack } = params
    if (_.isFunction(callBack)) {
      callBack(selectedProducts, productIndex)
      // p.op.showToast('เพิ่มคำสั่งซื้อเรียบร้อยแล้ว', 'success')
      util.navGoBack(this.props)
    }
  }

  _onSeletedProduct = (product: IProductItemData) => {
    // console.log('_onSeletedProduct => product ', product)
    const { sellerStores } = this.props
    const sellerStoresJS = sellerStores.toJS()
    const { productIndex, mode, optionSelectedSellerStoreIndex, optionChooseProductsFromIndex } = this.state
    const sellerStoreOnPick = sellerStoresJS[optionSelectedSellerStoreIndex]
    // @ts-ignore
    const newProduct: IStoreWebstieOneOptionProduct = {}
    const newVariants = []
    const newPt: ISalePageOneOptionVariants = {
      name: product.n,
      variantName: product.v[0].n,
      pp_id: product.v[0].i,
      price: product.v[0].p ? product.v[0].p : product.i,
      qty: product.count_number ? product.count_number : 1,
      img: product.t,
    }
    if (optionChooseProductsFromIndex === 0 && sellerStoreOnPick) {
      newPt.seller_store_id = sellerStoreOnPick.id
    }
    newVariants.push(newPt)
    newProduct.variants = newVariants
    // console.log('newProduct => 111 ', newProduct)
    const params = util.getNavParams(this.props)
    const { callBack } = params
    if (_.isFunction(callBack)) {
      callBack(newProduct, productIndex)
      // p.op.showToast('เพิ่มคำสั่งซื้อเรียบร้อยแล้ว', 'success')
      // util.navGoBack(this.props)
    }
    if (mode === 'SINGLE_PRODUCT_MODE') {
      util.navGoBack(this.props)
    }
  }
}
