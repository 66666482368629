// import PropTypes from 'prop-types'
import React from 'react'
import { HStack } from 'native-base'

import {
  TouchableOpacity,
  Image,
  // TouchableHighlight,
  // TouchableWithoutFeedback,
} from 'react-native'

import { STYLES, COLORS } from 'x/config/styles'
import * as util from 'x/utils/util'
// import QRCodeModal from '../../components/QRCodeModal'
// import ForwardIcon from '../../components/ForwardIcon'
import p from 'x/config/platform-specific'
import _ from 'lodash'

// import SortableListView from 'react-native-sortable-listview'
import { ProductGroup } from 'x/index'
// import XGalleryModal from '../../components/XGalleryModal'
// import QRCode from '../../components/QRCode'
// import UGDisplayItem from '../../components/UGDisplayItem'
import XContent from 'xui/components/XContent'
import XCard from 'xui/components/XCard'
import BaseProductGroupSettingForUserGroup from 'x/modules/store/BaseProductGroupSettingForUserGroup'
import XContainer from 'xui/components/XContainer'
import XCustomHeader from 'xui/components/XCustomHeader'
import Box from 'xui/components/Box'
import XText from 'xui/components/XText'
import VStack from 'xui/components/VStack'
import XIcon from 'xui/components/XIcon'
import XInput from 'xui/components/XInput'

const Checkbox = ({ disabled = false, checked = null }) => (
  <XIcon
    style={{
      fontSize: STYLES.FONT_ICON_NORMAL,
      color: disabled ? COLORS.TEXT_INACTIVE : COLORS.APP_MAIN,
      textAlign: 'center',
    }}
    family='FontAwesome'
    name={checked ? 'check-square-o' : 'square-o'}
  />
)

const Radio = ({ disabled = false, checked = null }) => (
  <XIcon
    style={{
      fontSize: STYLES.FONT_ICON_NORMAL,
      color: disabled ? COLORS.TEXT_INACTIVE : COLORS.APP_MAIN,
      textAlign: 'center',
    }}
    name={checked ? 'radio-button-on' : 'radio-button-off'}
  />
)

const DISCOUNT_TYPES = [
  { label: 'ไม่มีส่วนลด' },
  {
    label: 'ให้ส่วนลดเป็นเปอร์เซ็น (%)',
    placeholder: 'ระบุส่วนลด เปอร์เซ็นต่อชิ้น',
  },
  {
    label: 'ให้ส่วนลดเป็นบาท (฿)',
    placeholder: 'ระบุส่วนลด บาทต่อชิ้น',
  },
]

export default abstract class BaseUIProductGroupSettingForUserGroup extends BaseProductGroupSettingForUserGroup {
  // abstract renderQRCodeModal: () => void

  _renderCustomHeader = () => {
    const { isEditing } = this.state
    let leftBtn = {
      backIcon: true,
      onPressItem: () => this._goBack(),
    }
    let rightBtn = null
    if (isEditing) {
      // @ts-ignore
      leftBtn = {
        // @ts-ignore
        label: 'บันทึก',
        // @ts-ignore
        onPressItem: () => this._saveSyncProductGroups(),
      }

      rightBtn = {
        label: 'ยกเลิก',
        onPressItem: () => {
          p.op.showConfirmation(
            'ไม่บันทึกการเปลี่ยนแแปลงรายการราคา',
            'กรุณายืนยันว่าข้อมูลการเปลี่ยนแปลงรายการราคาจะสูญหาย',
            () => util.navGoBack(this.props),
            () => null,
            'ตกลง',
            'ยกเลิก'
          )
        },
      }
    }
    return (
      <XCustomHeader
        title='เลือกรายการราคา'
        headerLeftProps={leftBtn}
        headerRightProps={rightBtn}
        // renderHeaderLeft={() => this.renderLeftBtn()}
        // renderHeaderRight={() => this.renderRightBtn()}
      />
    )
  }

  // renderLeftBtn = () => {
  //   const { isEditing } = this.state
  //   if (isEditing) {
  //     return (
  //       <XButton variant='ghost' mr='2' mb={p.op.isWeb() ? '0' : '1.5'} onPress={() => this._saveSyncProductGroups()}>
  //         บันทึก
  //       </XButton>
  //     )
  //   }
  //   const { state, goBack } = this.props.navigation
  //   return (
  //     <XButton variant='ghost' mr='2' mb={p.op.isWeb() ? '0' : '1.5'} onPress={() => this._goBack()}>
  //       <BackIcon />
  //     </XButton>
  //   )
  // }

  _goBack = () => {
    const callBack = util.getNavParam(this.props, 'callBack')
    if (_.isFunction(callBack)) {
      callBack()
    }
    util.navGoBack(this.props)
  }

  // renderRightBtn = () => {
  //   const { isEditing } = this.state
  //   const { state, goBack } = this.props.navigation
  //   if (isEditing) {
  //     return (
  //       <XButton
  //         variant='ghost'
  //         mr='2'
  //         mb={p.op.isWeb() ? '0' : '1.5'}
  //         onPress={() => {
  //           p.op.showConfirmation(
  //             'ไม่บันทึกการเปลี่ยนแแปลงรายการราคา',
  //             'กรุณายืนยันว่าข้อมูลการเปลี่ยนแปลงรายการราคาจะสูญหาย',
  //             () => goBack(state.key),
  //             () => null,
  //             'ตกลง',
  //             'ยกเลิก'
  //           )
  //         }}>
  //         ยกเลิก
  //       </XButton>
  //     )
  //   }
  //   return null
  // }

  _renderHeaderCard = () => {
    const { userGroup } = this.state
    if (_.isNil(userGroup)) {
      return null
    }

    const pgAssignedCount = this._getPgAssignedCount()
    let txtAssignPgsInfo = null
    if (pgAssignedCount > 1) {
      txtAssignPgsInfo =
        `\n${pgAssignedCount} ราคาที่คุณเลือกไว้นี้ ` +
        'จะทำให้สมาชิกเห็นรายการสินค้าทั้งหมดที่มีการระบุราคาเหล่านี้เอาไว้' +
        'อย่างน้อย 1 ราคา โดยหากสินค้าหนึ่งๆ มีการระบุไว้มากกว่า 1 ราคา สมาชิกจะเห็นเฉพาะราคาลำดับบนที่สุด “เพียงราคาเดียว”' +
        '\n\n* คุณสามารถระบุราคาของสินค้าได้ในหน้า การขาย > สินค้า > กดเลือกสินค้าเพื่อเปิดดูรายละเอียด' +
        '\n** คุณสามารถเรียงลำดับรายการราคา ได้ในหน้า การขาย > รายการราคา)'
    }
    return (
      <XCard flex={1} p='2'>
        <HStack w='full'>
          {_.isNil(userGroup.img_uri) ? null : (
            <Box alignItems='center' justifyContent='center' w='10'>
              <Image
                resizeMode='cover'
                style={{
                  width: 40,
                  height: 40,
                  borderWidth: 1,
                  borderColor: COLORS.TEXT_INACTIVE,
                  borderRadius: 90,
                }}
                source={{ uri: userGroup.img_uri }}
              />
            </Box>
          )}
          {_.isNil(userGroup.img_uri) ? null : <Box w='2' />}
          <Box flex={1}>
            <XText variant='active' bold>
              {userGroup.name}
            </XText>
            <XText variant='inactive' numberOfLines={1}>
              {userGroup.description}
            </XText>
          </Box>
        </HStack>
        <VStack w='full' pt='2'>
          <XText variant='inactive'>ผู้ใช้กลุ่มนี้จะมองเห็นรายการราคาที่ถูกเลือก และได้รับส่วนลดต่อชิ้นตามที่คุณตั้งค่า</XText>
          <XText variant='active'>{txtAssignPgsInfo}</XText>
        </VStack>
      </XCard>
    )
  }

  _renderPGList = () => {
    const { selectedProductGroups } = this.state
    if (_.isNil(selectedProductGroups)) {
      return null
    }
    return selectedProductGroups.map((pg: ProductGroup, idx: number) => this._renderProductGroup(pg, idx))
  }

  _renderProductGroup = (productGroup: ProductGroup, idx: number) => {
    const { isAssigned } = productGroup
    return (
      <XCard mt='2' p='2' key={productGroup.name}>
        <HStack>
          <TouchableOpacity disabled={false} style={{ width: 40 }} onPress={() => this._toggleProductGroupCheckbox(idx, productGroup)}>
            <Checkbox disabled={false} checked={isAssigned} />
          </TouchableOpacity>
          <VStack>
            <XText variant='active'>{productGroup.name}</XText>
            {!_.isNil(productGroup.description) ? <XText variant='inactive'>{productGroup.description}</XText> : null}
          </VStack>
        </HStack>
        {isAssigned ? this._renderDiscountType(productGroup, idx) : null}
      </XCard>
    )
  }

  _renderDiscountType = (productGroup: ProductGroup, idx: number) =>
    // console.log('productGroup // 00 // => ', productGroup)
    DISCOUNT_TYPES.map((dType, index) => {
      if (_.isNil(dType.label)) {
        return null
      }
      const isSelected = index === productGroup.discount_type
      let discountValue = 0
      const { discount_percent = 0, discount_amount = 0, discount_type = 0 } = productGroup
      if (discount_type === 1) {
        discountValue = discount_percent
      } else if (discount_type === 2) {
        discountValue = discount_amount
      }
      return (
        <HStack w='full' alignItems='center' pr='2' pl='8' key={productGroup.id + dType.label}>
          <TouchableOpacity
            style={{
              flex: 1,
              // alignItems: 'center',
              justifyContent: 'center',
              height: 44,
            }}
            disabled={false}
            onPress={() => this._onDiscountTypeChange(idx, productGroup, index)}>
            <HStack>
              <Radio disabled={false} checked={isSelected} />
              <XText variant='active' pl='2'>
                {dType.label}
              </XText>
            </HStack>
          </TouchableOpacity>
          {p.op.isWeb() ? null : <Box w='2' />}
          {isSelected && index > 0 ? (
            <XInput
              disabled={false}
              style={{ height: 34, width: 100 }}
              align='right'
              value={_.isNumber(discountValue) ? discountValue.toString() : discountValue}
              placeholder={dType.placeholder}
              keyboardType='numeric'
              autoCorrect={false}
              isMoney={index === 2}
              isNumber
              onChangeText={(text: string) => this._onDiscountAmountChange(idx, productGroup, index, text)}
              maxLength={10}
              flex={1}
            />
          ) : null}
        </HStack>
      )
    })

  render() {
    return (
      <XContainer>
        {this._renderCustomHeader()}
        <XContent p='2'>
          {this._renderHeaderCard()}
          {this._renderPGList()}
          {p.op.isWeb() ? null : <Box h='4' />}
        </XContent>
      </XContainer>
    )
  }
}
