import React, { Component } from 'react'
import _ from 'lodash'
import * as util from 'x/utils/util'
import { ISelectedStoreMap, ActionApiParams, IProfileMap, IProfile, IXScreenProps } from 'x/index'
import p from 'x/config/platform-specific'
import ImgManager from 'xui/components/ImgManager'

interface IBaseProfileViewProps extends IXScreenProps {
  dispatch: (params: any) => void
  selectedStore: ISelectedStoreMap
  searchMumber: (params: ActionApiParams) => void
  profile: IProfileMap
}

interface IBaseProfileViewState {
  isUploading: boolean
  mode: 'VIEW' | 'EDIT'
  profile: IProfile

  uploaded_img_uri?: string
  uploaded_thumbnail_uri?: string
}

export default class BaseProfileView extends Component<IBaseProfileViewProps, IBaseProfileViewState> {
  // @ts-ignore
  imgManagerRef: React.RefObject<ImgManager>

  protected constructor(props: IBaseProfileViewProps) {
    super(props)
    this.imgManagerRef = React.createRef()
    this.state = {
      isUploading: false,
      mode: 'VIEW',
      // codeId: '',
      // nickName: '',
      // firstName: '',
      // lastName: '',
      // gender: 0,
      // phone: '',
      // phone2: '',
      // email: '',
      // facebook: '',
      // line: '',
      // instagram: '',
      profile: null,
    }
  }

  async componentDidMount() {
    await this._configProfileData()
  }

  _configProfileData = async () => {
    const { navigation, profile } = this.props
    // await util.delay(500)
    // @ts-ignore
    // console.log(`selectedStore`, this.props.selectedStore.toJS())
    // console.log(`profile`, profile.toJS())
    // const { profile } = params
    // const profileImg = profile.get('img_uri')
    // const mode = _.isNil(params.mode) ? VIEW : params.mode
    // await util.delay(500)
    // console.log('this.imgManagerRef => ', this.imgManagerRef)
    // if (this.imgManagerRef && this.imgManagerRef.current) {
    //   setTimeout(
    //     () =>
    //       this.imgManagerRef.current.loadImageUrls({
    //         p: [profileImg],
    //       }),
    //     200
    //   )
    // }
    await util.setStatePromise(this, {
      profile: profile.toJS(),
    })

    await this._loadImagesToImgMgr()
  }

  _loadImagesToImgMgr = async () => {
    const { profile } = this.props
    const img_uri = profile.get('img_uri') || null // use for initial image
    const thumbnail_uri = profile.get('thumbnail_uri') || null // use for initial image

    if (this.imgManagerRef && this.imgManagerRef.current.loadImageUrls) {
      // const { selectedProduct } = this.props
      const img_uris = [img_uri]
      const thumbnail_uris = [thumbnail_uri]
      await this.imgManagerRef.current.loadImageUrls({
        p: img_uris,
        t: thumbnail_uris,
      })
    }
  }

  _onChangeNameText = (newText: string) => {
    const { profile } = this.state
    const newProfile = _.clone(profile)
    newProfile.first_name = newText
    this.setState({ profile: newProfile })
  }

  _onChangeLastNameText = (newText: string) => {
    const { profile } = this.state
    const newProfile = _.clone(profile)
    newProfile.last_name = newText
    this.setState({ profile: newProfile })
  }

  _onChangeGander = (label: 'ชาย' | 'หญิง' | 'ไม่ระบุ') => {
    const { profile } = this.state
    const newProfile = _.clone(profile)
    newProfile.gender = label === 'ชาย' ? 'male' : label === 'หญิง' ? 'famale' : 'n/a'
    this.setState({ profile: newProfile })
  }

  _onChangePhoneNumberText = (newText: string) => {
    const { profile } = this.state
    const newProfile = _.clone(profile)
    newProfile.phone1 = newText
    this.setState({ profile: newProfile })
  }

  _onChangeEmailText = (newText: string) => {
    const { profile } = this.state
    const newProfile = _.clone(profile)
    newProfile.contact_email = newText
    this.setState({ profile: newProfile })
  }

  _onChangeFacebookText = (newText: string) => {
    const { profile } = this.state
    const newProfile = _.clone(profile)
    newProfile.fb_id = newText
    this.setState({ profile: newProfile })
  }

  _onChangeLineText = (newText: string) => {
    const { profile } = this.state
    const newProfile = _.clone(profile)
    newProfile.line_id = newText
    this.setState({ profile: newProfile })
  }

  _submitData = async () => {
    const { profile } = this.state
    const body: any = {}
    if (profile.first_name && profile.first_name.length < 3) {
      p.op.showConfirmationOkOnly('', 'กรุณาระบุชื่อ หรือตั้งต้องตั้งชื่ออย่างน้อย 3 ตัวอักษร')
      return
    }
    body.first_name = profile.first_name

    if (profile.last_name && profile.last_name.length < 3) {
      p.op.showConfirmationOkOnly('', 'กรุณาระบุนามสกุล หรือตั้งต้องตั้งชื่ออย่างน้อย 3 ตัวอักษร')
      return
    }
    body.last_name = profile.last_name

    if (profile.gender) {
      body.gender = profile.gender
    }

    if (profile.phone1) {
      body.phone1 = profile.phone1
    }

    if (profile.email && !util.isEmailValidFormat(profile.email)) {
      p.op.showConfirmationOkOnly('', 'กรุณาระบุอีเมลในรูปแบบที่ถูกต้อง')
      return
    }
    body.contact_email = profile.contact_email

    if (profile.fb_id) {
      body.fb_id = profile.fb_id
    }
    if (profile.line_id) {
      body.line_id = profile.line_id
    }

    // start upload profile image
    if (this.imgManagerRef && this.imgManagerRef.current.submitUploadImages) {
      await util.setStatePromise(this, { isUploading: true })
      const { p: img_uris, t: thumbnail_uris, allUploaded } = await this.imgManagerRef.current.submitUploadImages()
      await util.setStatePromise(this, { isUploading: false })
      if (allUploaded) {
        // onChangeEditingStore({ img_uri: p[0] })
        // onChangeEditingStore({ img_thumbnail_uri: t[0] })
        await util.setStatePromise(this, {
          uploaded_img_uri: img_uris[0],
          uploaded_thumbnail_uri: thumbnail_uris[0],
        })
        await util.delay(1000) // ทดไว้สำหรับยิง action เปลี่ยนรูปภาพ
      } else {
        await util.setStatePromise(this, { isUploading: false })
        p.op.showConfirmationOkOnly('เกิดข้อผิดพลาด', 'กรุณาลองอัพโหลดรูปใหม่อีกครั้ง')
        return
      }
    }

    const { uploaded_img_uri, uploaded_thumbnail_uri } = this.state
    if (uploaded_img_uri && uploaded_thumbnail_uri) {
      body.img_uri = uploaded_img_uri
      body.thumbnail_uri = uploaded_thumbnail_uri
    }
    // end upload profile image

    const response: {
      profile: IProfile
    } = await new Promise((submitted) => {
      this.props.dispatch({
        type: 'PROFILE/USER_PROFILE_EDIT',
        payload: body,
        successCallback: (res) => submitted(res),
        failedCallback: () => submitted(null),
      })
    })
    // console.log('response => ', response)
    if (!_.isNil(response) && response.profile) {
      await util.setStatePromise(this, {
        profile: response.profile,
        mode: 'VIEW',
      })
    } else {
      await p.op.showConfirmationOkOnly('', `เกิดข้อผิดพลาด ${response}`)
    }
  }
}
