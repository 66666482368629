import { Component } from 'react'

import * as util from 'x/utils/util'

import { ISelectedStoreMap, IXScreenProps } from 'x/types'

interface IFileUpload {
  name: string
  lastModified: number
  webkitRelativePath: string
  size: number
  type: string
  path: string
}

interface IResponeUploadAddProduct {
  success: number
  import_error: {
    error_code: string
    column: string
    index: number
  }
}

export interface BaseImportAddProductViewProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
  subscription: Map<string, any>
}

export interface BaseImportAddProductViewState {
  fileUpload: IFileUpload
  isLoading: boolean
  successAddProduct: number
  importErrorColumn: string
  importErrorRow: number
  importErrorCode: string
  disabledDownloadTemplate: boolean
}

export default abstract class BaseImportAddProductView extends Component<BaseImportAddProductViewProps, BaseImportAddProductViewState> {
  // abstract async handleOnDownloadFile(url: string, fileName: string): Promise<void>
  inProcess: boolean

  constructor(props) {
    super(props)
    this.state = {
      fileUpload: null,
      isLoading: false,
      successAddProduct: null,
      importErrorColumn: null,
      importErrorRow: null,
      importErrorCode: null,
      disabledDownloadTemplate: false,
    }
    this.inProcess = false
  }

  _goBack = () => {
    util.navGoBack(this.props)
  }

  _setFileUpload = (acceptedFiles: IFileUpload[]) => {
    // console.log(acceptedFiles)
    util.setStatePromise(this, {
      fileUpload: acceptedFiles[0],
    })
  }

  _startLoading = () => {
    this.inProcess = true
    this.setState({
      isLoading: true,
    })
  }

  _stopLoading = () => {
    this.inProcess = false
    this.setState({
      isLoading: false,
    })
  }

  // onPressUpload = async () => {
  //   const { selectedStore, subscription } = this.props
  //   if (subscription.get('type') === CONS.SUBSCRIPTION_PACKAGE.FREE) {
  //     p.op.alert(p.op.t('Subscription.warning.insufficientTypeTitle'), p.op.t('Subscription.warning.insufficientTypeMsg'))
  //     return
  //   }
  //   const { fileUpload } = this.state
  //   if (this.inProcess) {
  //     return
  //   }
  //   this.inProcess = true
  //   await util.setStatePromise(this, {
  //     isLoading: true,
  //   })

  //   const formData = new FormData()

  //   // Array.from(this.state.fileUpload).forEach(image => {
  //   // // @ts-ignore
  //   //   formData.append('file', image)
  //   // })
  //   // @ts-ignore
  //   formData.append('file', fileUpload)
  //   formData.append('store_id', `${selectedStore.get('id')}`)
  //   // formData.append('store_id', `99999999`)
  //   // console.log(`formData`, formData)
  //   const token = await p.op.storageGet(CONS.AUTHENTICATION_STORAGE_KEY, false)
  //   // console.log(`TOKEN => `, `Bearer ${token}`)
  //   // return
  //   const path = `excel/import/product_add`
  //   const url = getConfig()
  //   axios
  //     .post(`${url.app_goApiRoot}/${path}`, formData, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //         Authorization: `Bearer ${token}`,
  //         // 'Access-Control-Allow-Origin': '*',
  //         // 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
  //       },
  //       timeout: 60000,
  //     })
  //     .then(async res => {
  //       // @ts-ignore res
  //       // console.log('REF => ', res)
  //       if (res.data) {
  //         this.inProcess = false
  //         const errorText = res.data.import_error.error_code === '' ? null : res.data.import_error.error_code
  //         if (errorText !== null) {
  //           const textErrorCode = util.getBgTaskErrorText(errorText)
  //           p.op.showConfirmationOkOnly('', textErrorCode)
  //         }

  //         await util.setStatePromise(this, {
  //           isLoading: false,
  //           successAddProduct: res.data.success,
  //           importErrorColumn: res.data.import_error.column === '' ? null : res.data.import_error.column,
  //           importErrorCode: errorText,
  //           importErrorRow: res.data.import_error.index,
  //         })
  //         return
  //       }
  //       this.inProcess = false
  //       p.op.showConfirmationOkOnly('', `เกิดข้อผิดพลาด ${res}`)
  //       await util.setStatePromise(this, {
  //         isLoading: false,
  //       })

  //       this.inProcess = false
  //       await util.setStatePromise(this, {
  //         isLoading: false,
  //       })
  //     })
  //     .catch(async err => {
  //       // console.log('err => ', err)
  //       const errorData = !_.isNil(err.error_code) ? err.error_code : null
  //       if (!_.isNil(errorData)) {
  //         p.op.showConfirmationOkOnly('', `เกิดข้อผิดพลาด ${errorData}`)
  //         this.inProcess = false
  //         await util.setStatePromise(this, {
  //           isLoading: false,
  //         })
  //         return
  //       }
  //       p.op.showConfirmationOkOnly('', `เกิดข้อผิดพลาด ${err}`)
  //       this.inProcess = false
  //       await util.setStatePromise(this, {
  //         isLoading: false,
  //       })
  //     })
  // }
}
