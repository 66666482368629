import React from 'react'
import _ from 'lodash'
import { Map } from 'immutable'

import * as util from 'x/utils/util'
import {
  IProductDetailItemMap,
  IVariantMkpInfo,
  ISelectedStoreMap,
  IProductDetailVariant,
  IComputedVariantMkpInfo,
  IAnyObject,
} from 'x/index'

import XText from '../XText'
import XCard from '../XCard'
import VStack from '../VStack'
import HStack from '../HStack'
import MkpLogo from '../MkpLogo'
import ForwardIcon from '../ForwardIcon'
import Box from '../Box'

interface IProps {
  // mkpChannels: IMKPChannelDetail[]
  selectedStore: ISelectedStoreMap
  product: IProductDetailItemMap
  onPress: () => void

  /** specific focus on single variant */
  ppId?: number
}

// interface IState {
//   isOverlayVisible?: boolean
// }

class ProductDetailMkpInfoButton extends React.Component<IProps> {
  static displayName = 'ProductDetailMkpInfoButton'

  // constructor(props) {
  //   super(props)
  //   this.state = {
  //     isOverlayVisible: false,
  //   }
  // }

  // getComputedMkpInfo = (v: IProductDetailVariant, idx: number): IComputedVariantMkpInfo | null => {
  //   const { mkp } = v
  getComputedMkpInfo = (mkp: IVariantMkpInfo, variantIndex: number): IComputedVariantMkpInfo | null => {
    if (!_.isObject(mkp) || !mkp.mkp_ch_id) {
      return null
    }

    const { mkp_ch_id } = mkp
    const mkpChannel = util.getMKPChannelByUGID(mkp_ch_id)

    if (!_.isObject(mkpChannel) || !mkpChannel.mkp_id) {
      return null
    }

    const { mkp_id, name: mkpName } = mkpChannel

    return {
      ...mkp,
      mkpName,
      mkp_id,
      variantIndex,
    }
  }

  filterByIsNotNullObject = (obj: IAnyObject) => _.isObject(obj)

  sortByMkpId = (vMkpA: IComputedVariantMkpInfo, vMkpB: IComputedVariantMkpInfo) => vMkpA.mkp_id - vMkpB.mkp_id

  getComputedProductMkpInfos = (): IComputedVariantMkpInfo[] => {
    const variants = this.getProductVariants()
    // console.log('ProductDetailMkpInfoButton getComputedProductMkpInfos variants => ', variants)

    const variantMkpInfoConcated = []

    for (let variantIndex = 0; variantIndex < variants.length; variantIndex++) {
      const v = variants[variantIndex]
      const { mkp = [] } = v
      for (let mkpIndex = 0; mkpIndex < mkp.length; mkpIndex++) {
        const mkpFocus = mkp[mkpIndex]
        variantMkpInfoConcated.push(this.getComputedMkpInfo(mkpFocus, variantIndex))
      }
    }

    // return variants.map(this.getComputedMkpInfo).filter(this.filterByIsNotNullObject).sort(this.sortByMkpId)
    return variantMkpInfoConcated.filter(this.filterByIsNotNullObject).sort(this.sortByMkpId)
  }

  getComputedVariantMkpInfos = (): IComputedVariantMkpInfo[] => {
    const { ppId } = this.props
    if (!ppId) {
      return null
    }

    const variants = this.getProductVariants().filter((v) => v.pp_id === ppId)
    // console.log('ProductDetailMkpInfoButton getComputedProductMkpInfos variants => ', variants)

    const variantMkpInfoConcated = []

    for (let variantIndex = 0; variantIndex < variants.length; variantIndex++) {
      const v = variants[variantIndex]
      const { mkp = [] } = v
      for (let mkpIndex = 0; mkpIndex < mkp.length; mkpIndex++) {
        const mkpFocus = mkp[mkpIndex]
        variantMkpInfoConcated.push(this.getComputedMkpInfo(mkpFocus, variantIndex))
      }
    }

    // return variants.map(this.getComputedMkpInfo).filter(this.filterByIsNotNullObject).sort(this.sortByMkpId)
    return variantMkpInfoConcated.filter(this.filterByIsNotNullObject).sort(this.sortByMkpId)
  }

  // getMkps = () => {
  //   const { product } = this.props
  //   product.get('mkp')
  // }

  getProductVariants = (): IProductDetailVariant[] => {
    const { product, ppId } = this.props
    let variants: IProductDetailVariant[]
    try {
      variants = Map.isMap(product) ? product.get('variants').toJS() : []
    } catch (error) {
      variants = []
    }

    // if (ppId) {
    //   return variants.filter((v) => v.pp_id === ppId)
    // }

    return variants || []
  }

  hasMkpPaired = (): boolean => {
    const { ppId } = this.props

    const mkpInfos = ppId ? this.getComputedVariantMkpInfos() : this.getComputedProductMkpInfos()
    // console.log('ProductDetailMkpInfoButton hasMkpPaired mkpInfos => ', mkpInfos)

    return mkpInfos.length > 0
  }

  getNonDuplicatedVariantMkpInfos = () => {
    const mkpInfos = this.getComputedVariantMkpInfos()
    return mkpInfos.filter((value, i, a) => {
      const isNotDuplicated = a.findIndex((value2) => value2.mkp_id === value.mkp_id && value2.mkp_ch_id === value.mkp_ch_id) === i
      return isNotDuplicated
    })
  }

  getNonDuplicatedMkpIdMkpInfos = () => {
    const mkpInfos = this.getComputedProductMkpInfos()
    return mkpInfos.filter((value, i, a) => {
      const isNotDuplicated = a.findIndex((value2) => value2.mkp_id === value.mkp_id && value2.mkp_ch_id === value.mkp_ch_id) === i
      return isNotDuplicated
    })
  }

  getMkpInfoCount = () => {
    const { ppId } = this.props
    const mkpInfos = ppId ? this.getNonDuplicatedVariantMkpInfos() : this.getNonDuplicatedMkpIdMkpInfos()
    return mkpInfos.length
  }

  getMkpCount = () => {
    const { ppId } = this.props
    const mkpInfos = ppId ? this.getComputedVariantMkpInfos() : this.getComputedProductMkpInfos()
    return mkpInfos.length
  }

  renderMkpInfos = () => {
    const { ppId } = this.props
    // const mkpInfos = this.getComputedProductMkpInfos()
    const mkpInfos = ppId ? this.getNonDuplicatedVariantMkpInfos() : this.getNonDuplicatedMkpIdMkpInfos()
    // console.log('ProductDetailMkpInfoButton renderMkpInfos mkpInfos => ', mkpInfos)
    const mkpInfoCount = this.getMkpInfoCount()

    if (mkpInfoCount > 2) {
      return mkpInfos.slice(0, 2).map(this.renderMkpInfoItem).concat(this.renderMkpInfoExceededMessage())
    }

    return mkpInfos.map(this.renderMkpInfoItem)
  }

  renderMkpInfoExceededMessage = () => {
    // const mkpInfos = this.getNonDuplicatedMkpIdMkpInfos()
    // console.log('ProductDetailMkpInfoButton renderMkpInfos mkpInfos => ', mkpInfos)

    const mkpInfoCount = this.getMkpInfoCount()
    const restMkpCount = mkpInfoCount - 2
    return (
      <HStack
        px='2'
        py='1'
        mb='1'
        alignItems='center'
        // borderWidth='1'
        // borderColor='primary.200'
        // borderRadius='xs'
        space='2'>
        <XText>{`และอีก ${restMkpCount} ช่องทางขาย`}</XText>
      </HStack>
    )
  }

  renderMkpInfoItem = (cMkp: IComputedVariantMkpInfo, idx: number) => {
    const { mkp_id, mkpName } = cMkp
    return (
      <HStack
        key={`MkpInfoItem_${idx}_${mkp_id}`}
        px='2'
        py='1'
        mb='1'
        alignItems='center'
        borderWidth='1'
        borderColor='primary.200'
        borderRadius='xs'
        bg='white'
        space='2'>
        <MkpLogo width={24} height={24} mkpId={mkp_id} />
        <XText bold numberOfLines={1} maxW='250px'>
          {mkpName}
        </XText>
      </HStack>
    )
  }

  // renderOverlay = () => {
  //   const { isOverlayVisible = false } = this.state
  //   if (!isOverlayVisible) {
  //     return null
  //   }
  //   const variants = this.getProductVariants() || []
  //   if (!variants || variants.length === 0) {
  //     return null
  //   }
  //   return (
  //     <XModal
  //       visible
  //       title='รายละเอียดสินค้าที่ถูกผูกกับช่องทางขาย'
  //       hasCloseButton
  //       onRequestClose={() => this.setState({ isOverlayVisible: false })}>
  //       <XScrollView>
  //         <VStack w='full' p='2'>
  //           {variants.map((v, i) => (
  //             <VStack w='full' key={i} p='2' bg={i % 2 === 0 ? 'amber.100' : 'white'}>
  //               <HStack w='full'>
  //                 <XText variant='inactive'>{`ตัวเลือกที่ ${i + 1}: `}</XText>
  //                 <XText>{v.name}</XText>
  //               </HStack>
  //               {v.mkp.map((mkp, j) => {
  //                 const computedMkp = this.getComputedMkpInfo(mkp, i)
  //                 return (
  //                   <XCard key={j}>
  //                     <XCard.Body>
  //                       <HStack w='full' space='2' alignItems='center'>
  //                         <MkpLogo mkpId={computedMkp.mkp_id} width={24} height={24} />
  //                         <VStack flex={1}>
  //                           <XText flex={1}>{JSON.stringify(computedMkp)}</XText>
  //                         </VStack>
  //                         <ForwardIcon />
  //                       </HStack>
  //                     </XCard.Body>
  //                   </XCard>
  //                 )
  //               })}
  //             </VStack>
  //           ))}
  //         </VStack>
  //       </XScrollView>
  //     </XModal>
  //   )
  // }

  render() {
    // console.log('ProductDetailMkpInfoButton props => ', this.props)
    // console.log('ProductDetailMkpInfoButton this.props.product.toJS() => ', this.props.product.toJS())
    const isVisible = this.hasMkpPaired()
    if (!isVisible) {
      return null
    }
    const { onPress, ppId } = this.props
    const mkpInfoCount = this.getMkpInfoCount()
    let labelText = `${mkpInfoCount} ช่องทางขาย ที่สินค้านี้ผูกอยู่:`

    if (ppId) {
      const variantMkpCount = this.getMkpCount()
      labelText = `${variantMkpCount} ตัวเลือกสินค้า (จาก ${mkpInfoCount} ช่องทางขาย) ที่ตัวเลือกสินค้านี้ผูกอยู่:`
    }

    return (
      <XCard onPress={onPress}>
        <HStack w='full' px='3' py='2' space='2' alignItems='center' bg='primary.100'>
          <VStack flex={1} space='1'>
            {/* <XText variant='inactive'>อยู่ในช่องทางขาย:</XText> */}
            <XText variant='inactive'>{labelText}</XText>
            <HStack w='full' flexWrap='wrap'>
              {this.renderMkpInfos()}
            </HStack>
          </VStack>
          <Box w='30px' h='30px' alignItems='center' justifyContent='center'>
            <ForwardIcon />
          </Box>
        </HStack>
      </XCard>
    )
  }
}

export default ProductDetailMkpInfoButton
