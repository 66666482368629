import React from 'react'
import { IXSegmentPropsExcludedOptions, IXSegmentOption } from 'x/index'
import XSegment from '../XSegment'

type OptionValueType = undefined | number[]
type ISegmentOrderShippingStatusProps = IXSegmentPropsExcludedOptions<OptionValueType>

const OPTIONS: Array<IXSegmentOption<OptionValueType>> = [
  // { label: 'ทั้งหมด', value: [129, 121, 122] },
  { label: 'ทั้งหมด', value: undefined },
  { label: 'ครบถ้วน', value: [129] },
  { label: 'ไม่ครบถ้วน', value: [121, 122] },
]

const SegmentOrderShippingStatus: React.FC<ISegmentOrderShippingStatusProps> = (props) => {
  return <XSegment options={OPTIONS} title='สถานะการจัดส่งของออเดอร์' {...props} />
}

SegmentOrderShippingStatus.defaultProps = {
  initialIndex: 0,
}

export default SegmentOrderShippingStatus
