// import PropTypes from 'prop-types'
import { Component } from 'react'

import {
  Clipboard,
  // TouchableHighlight,
  // TouchableWithoutFeedback,
} from 'react-native'

import { delay, setStatePromise } from 'x/utils/util'
import * as util from 'x/utils/util'

import xCONS from 'x/config/constants'
// import QRCodeModal from '../../components/QRCodeModal'
// import ForwardIcon from '../../components/ForwardIcon'
import p from 'x/config/platform-specific'
import _ from 'lodash'
import { Map, List } from 'immutable'

import * as NavActions from 'x/utils/navigation'
// import SortableListView from 'react-native-sortable-listview'
import { UserGroup, UserGroupMap, UGListViewProps, UGListViewState } from 'x/index'
// import XGalleryModal from '../../components/XGalleryModal'
// import QRCode from '../../components/QRCode'
// import UGDisplayItem from '../../components/UGDisplayItem'
import { canDoAtSelectedStore } from 'x/utils/acl'

export default class BaseUserGroupListView extends Component<UGListViewProps, UGListViewState> {
  inProcess: boolean

  isGoback: boolean

  qrModalRef: any

  constructor(props: UGListViewProps) {
    super(props)
    this.inProcess = false
    this.isGoback = false

    this.state = {
      editingUserGroups: [],
      isVisibleQRCode: false,
      selectedQRCodeUri: null,
      selectedInviteCode: null,
      isRefreshing: false,
    }

    // this._renderUGList = this._renderUGList.bind(this)
    // this._firstInitialize = this._firstInitialize.bind(this)
    // this._cannotInitialize = this._cannotInitialize.bind(this)
    // this._addUserGroup = this._addUserGroup.bind(this)
    // this._viewUserGroup = this._viewUserGroup.bind(this)

    // this._handleOnPressUserGroupSetting = this._handleOnPressUserGroupSetting.bind(this)
    // this._handleOnPressAskToJoin = this._handleOnPressAskToJoin.bind(this)
    // this._handleOnPressMembers = this._handleOnPressMembers.bind(this)
    // this._handleOnPressProductGroups = this._handleOnPressProductGroups.bind(this)
    // this._handleOnPressOpenQRCode = this._handleOnPressOpenQRCode.bind(this)
  }

  componentDidMount() {
    const { selectedStore, fetchMyStore, navigation } = this.props
    // const { state, setParams } = navigation
    // const { store_id } = state.params
    const store_id = selectedStore.get('id')
    const body = { store_id }
    fetchMyStore({ body, successCallback: this._firstInitialize, failedCallback: this._cannotInitialize })
    // console.log('data => ', this.props.data)
    // if (!state.params || !state.params.store_id) {
    //   this._cannotInitialize()
    // } else if (!isAlreadySelectedStore()) {
    //   const { store_id } = state.params
    //   const body = { store_id }
    //   fetchMyStore({ body, successCallback: this._firstInitialize, failedCallback: this._cannotInitialize })
    // } else {
    //   this._firstInitialize()
    // }
  }

  componentDidUpdate(prevProps: UGListViewProps, prevState: UGListViewState) {
    const { selectedUserGroups, shouldFetch } = this.props
    const { editingUserGroups, isRefreshing } = this.state
    // console.log('componentDidUpdate update editingUserGroups begin => ', prevProps.selectedUserGroups)
    // console.log('componentDidUpdate update editingUserGroups begin State => ', selectedUserGroups)
    if (
      prevProps.selectedUserGroups !== selectedUserGroups ||
      (!prevProps.selectedUserGroups && List.isList(selectedUserGroups)) ||
      (List.isList(selectedUserGroups) && !_.isArray(editingUserGroups))
    ) {
      // console.log('componentDidUpdate update editingUserGroups found //  => ', selectedUserGroups.toJS())
      this.setState({ editingUserGroups: selectedUserGroups.toJS() })
    }

    if (!prevProps.shouldFetch && shouldFetch && !isRefreshing) {
      this._handleRefreshUserGroups()
    }
  }

  // eslint-disable-next-line react/sort-comp
  _firstInitialize = async () => {
    const { selectedUserGroups } = this.props
    // const { setParams } = navigation
    // console.log('selectedUserGroups => ', selectedUserGroups)
    if (List.isList(selectedUserGroups)) {
      await setStatePromise(this, {
        editingUserGroups: selectedUserGroups.toJS(),
      })
    }

    // setParams({
    //   addUserGroup: this._addUserGroup,
    // })
  }

  _fetchMyStore = async () => {
    const { fetchMyStore, selectedStore } = this.props
    const store_id = selectedStore.get('id')
    const body = { store_id }
    fetchMyStore({ body, successCallback: this._firstInitialize, failedCallback: this._cannotInitialize })
  }

  _cannotInitialize() {
    if (this.isGoback) {
      return
    }
    this.isGoback = true

    p.op.alert('เกิดข้อผิดพลาด', 'ไม่สามารถโหลดรายชื่อรายการราคาได้ กรุณากลับไปเลือกร้านค้าใหม่อีกครั้ง')
    util.navGoBack(this.props)
  }

  _handleRefreshUserGroups = async () => {
    const { fetchUserGroups, navigation } = this.props
    // const { state } = navigation

    const params = util.getNavParams(this.props)

    if (!params || !params.store_id) {
      throw new Error('UserGroupListView:: No store_id.')
    }

    if (!_.isFunction(fetchUserGroups)) {
      throw new Error('UserGroupListView:: No fetchUserGroups function.')
    }

    await setStatePromise(this, { isRefreshing: true })
    const afterFetched = () => this.setState({ isRefreshing: false })
    this.props.fetchUserGroups({
      body: { store_id: params.store_id },
      successCallback: afterFetched,
      failedCallback: afterFetched,
    })
  }

  async _addUserGroup() {
    // console.log('_addUserGroup => ')
    if (!canDoAtSelectedStore(xCONS.PERM_STORE_HELPER.UG_ADD)) {
      p.op.alert('คุณไม่มีสิทธิ์ในการเพิ่มกลุ่มสมาชิก')
      return
    }

    // ถ้าเป็น IOS และเข้าเงื่อนไขก็ปล่อยผ่าน // https://app.clickup.com/t/86cvy1xnd
    const isNoobIos = util.isIosNoobCheckByPass()
    if (!isNoobIos) {
      // Start ตรวจสอบ Quota User Groups
      const ugGroupsNumber = this.props.selectedStore.get('user_groups').size
      const subscription = this.props.subscription.toJS()
      if (ugGroupsNumber >= subscription.ug_quota) {
        p.op.alert('แพ็กเกจของคุณไม่สามารถเพิ่มกลุ่มสมาชิกได้อีก', p.op.t('Subscription.warning.insufficientTypeMsg'))
        return
      }
      // End ตรวจสอบ Quota User Groups
    }

    const { navigation } = this.props
    // const { state, dispatch } = this.props.navigation
    const params = util.getNavParams(this.props)
    if (!params || !params.store_id) {
      return
    }
    const { store_id } = params
    if (p.op.isWeb()) {
      // @ts-ignore
      navigation.popToTop()
      await delay(200)
    }
    navigation.dispatch(
      NavActions.navToUserGroupView({
        store_id,
        mode: xCONS.USER_GROUP_VIEW_MODE.ADD,
        ug_id: null,
      })
    )
  }

  async _viewUserGroup(ug_id) {
    const { selectedStore } = this.props
    const { dispatch } = this.props.navigation
    const { store_id } = selectedStore.get('id')
    if (p.op.isWeb()) {
      // @ts-ignore
      this.props.navigation.popToTop()
      await delay(200)
    }
    dispatch(
      NavActions.navToUserGroupView({
        store_id,
        ug_id,
        mode: xCONS.USER_GROUP_VIEW_MODE.VIEW,
      })
    )
  }

  _handlePressCopyTextToClipboard = (text: string) => {
    if (!text) {
      return
    }
    Clipboard.setString(text)
    p.op.showToast(`คัดลอก ${text} แล้ว`, 'success')
  }

  _handleOnPressCopyInviteLink = async (index: number, data: UserGroupMap): Promise<void> => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    const { selectedUserGroups, generateUserGroupInviteLink, navigation } = this.props
    const params = util.getNavParams(this.props)
    if (!navigation || !params || !params.store_id || !data || !Map.isMap(data)) {
      this.inProcess = false
      return
    }
    const ug_id = data.get('id')

    const invite_code = data.get('invite_code')
    const invite_link = data.get('invite_link')
    if (invite_link) {
      this._handlePressCopyTextToClipboard(invite_link)
      this.inProcess = false
      return
    }

    if (!invite_code || !this.qrModalRef || !_.isFunction(this.qrModalRef.openQRCode)) {
      this.inProcess = false
      return
    }

    const response = await new Promise((resolveRes) => {
      generateUserGroupInviteLink({
        body: {
          store_id: params.store_id,
          ug_id,
        },
        successCallback: (res) => resolveRes(res),
        failedCallback: () => resolveRes(null),
      })
    })

    // log('_onPressGenerateInviteLink response => ', response)
    // @ts-ignore
    if (response && response.user_group && response.user_group.id && response.user_group.invite_link) {
      // p.op.showToast('การสร้างลิงก์สำเร็จแล้ว', 'success')
      // @ts-ignore
      this._handlePressCopyTextToClipboard(response.user_group.invite_link)
    } else {
      p.op.showToast('การสร้างลิงก์ล้มเหลว', 'danger')
    }
    this.inProcess = false
    // this.qrModalRef.openQRCode(invite_code)
  }

  _handleOnPressUserGroupSetting(index: number, data: UserGroupMap): void {
    const { navigation } = this.props
    // const { dispatch, state } = navigation
    const params = util.getNavParams(this.props)
    if (!params || !params.store_id) {
      return
    }

    const ug_id = data.get('id')
    navigation.dispatch(
      NavActions.navToUserGroupView({
        store_id: params.store_id,
        ug_id,
        mode: xCONS.USER_GROUP_VIEW_MODE.VIEW,
      })
    )
  }

  _handleOnPressAskToJoin(data: UserGroup): void {
    const ug_id = data.id
    this._navToUserGroupTabs(ug_id, 0)
  }

  _handleOnPressMembers(data: UserGroup): void {
    const ug_id = data.id
    this._navToUserGroupTabs(ug_id, 1)
  }

  _navToUserGroupTabs = async (ug_id: number, initTabIndex = 0) => {
    const { navigation, selectedStore } = this.props
    const { dispatch } = navigation
    if (p.op.isWeb()) {
      // @ts-ignore
      this.props.navigation.popToTop()
      await delay(200)
    }
    dispatch(
      NavActions.navToUserGroupResellerTabs({
        store_id: selectedStore.get('id'),
        ug_id,
        initTabIndex,
        callBack: () => this._fetchMyStore(),
      })
    )
  }

  // _handleOnPressProductGroups = (data: UserGroup) => {
  //   const { navigation } = this.props
  //   const { dispatch, state } = navigation

  //   if (!state.params || !state.params.store_id) {
  //     return
  //   }

  //   const ug_id = data.id
  //   dispatch(
  //     NavActions.navToUserGroupsAssignProductGroups({
  //       store_id: state.params.store_id,
  //       ug_id,
  //     })
  //   )
  // }

  _navToProductGroupSettingForUserGroup = async (userGroup: UserGroup) => {
    const { navigation } = this.props
    if (p.op.isWeb()) {
      // @ts-ignore
      navigation.popToTop()
      await delay(200)
    }

    const store_id = util.getNavParam(this.props, 'store_id')
    navigation.dispatch(
      NavActions.navToProductGroupSettingForUserGroup({
        store_id,
        userGroup,
        callBack: () => this._fetchMyStore(),
      })
    )
  }
}
