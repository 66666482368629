import { Map, List, fromJS } from 'immutable'
import { ActionApiParams } from 'x/index'
import { setStorePAInPaymentAccountsForKey } from '../../redux/selectors'

const initAccount = Map({
  account_number: null,
  bank_id: null,
  // id: null,
  name: null,
})

// Initial state
const initialState = Map({
  stores: Map({}),
  fetchAccounts: List([]),
  editingAccounts: List([]),
  editingAccount: initAccount,
  selectedAccount: initAccount,
})

// Actions
export const INITIALIZE_STATE = 'BankState/INITIALIZE'

export const BANK_ACCOUNTS_LOAD = 'BankState/BANK_ACCOUNTS_LOAD'
export const BANK_FETCH_MY_ACCOUNTS = 'BankState/BANK_FETCH_MY_ACCOUNTS'
export const BANK_FETCH_STORE_PAYMENT_ACCOUNTS = 'BankState/BANK_FETCH_STORE_PAYMENT_ACCOUNTS'

export const BANK_EDITING_ACCOUNT_RESET = 'BankState/BANK_EDITING_ACCOUNT_RESET'
export const BANK_EDITING_ACCOUNT_CHANGE = 'BankState/BANK_EDITING_ACCOUNT_CHANGE'
export const BANK_EDITING_ACCOUNT_NEW = 'BankState/BANK_EDITING_ACCOUNT_NEW'
export const BANK_EDITING_ACCOUNT_SAVE = 'BankState/BANK_EDITING_ACCOUNT_SAVE'
export const LOAD_EDITING_ACCOUNT_FROM_STORE_ACCOUNTS = 'BankState/LOAD_EDITING_ACCOUNT_FROM_STORE_ACCOUNTS'
export const BANK_EDITING_ACCOUNT_DELETE = 'BankState/BANK_EDITING_ACCOUNT_DELETE'

export const STORE_PAYMENT_ACCOUNTS_FETCHED = 'BankState/STORE_PAYMENT_ACCOUNTS_FETCHED'

export function initializeBankAccountsState() {
  return {
    type: INITIALIZE_STATE,
  }
}

// Add Account
export function resetEditingAccount() {
  return {
    type: BANK_EDITING_ACCOUNT_RESET,
  }
}

export function editingAccountChange({ key, value }) {
  return {
    type: BANK_EDITING_ACCOUNT_CHANGE,
    payload: { key, value },
  }
}

export function editingAccountAddNew(body, callback) {
  return {
    type: BANK_EDITING_ACCOUNT_NEW,
    payload: { body, callback },
  }
}

export function editingAccountSave(body, callback) {
  return {
    type: BANK_EDITING_ACCOUNT_SAVE,
    payload: { body, callback },
  }
}

export function editingAccountDelete(body, callback) {
  return {
    type: BANK_EDITING_ACCOUNT_DELETE,
    payload: { body, callback },
  }
}

// generic Bank actions
export function loadBankAccounts(accounts) {
  return {
    type: BANK_ACCOUNTS_LOAD,
    payload: accounts,
  }
}

export function loadEditingAccountFromStore({ store_id, account_index }) {
  return {
    type: LOAD_EDITING_ACCOUNT_FROM_STORE_ACCOUNTS,
    payload: { store_id, account_index },
  }
}

export function fetchMyBankAccounts() {
  return {
    type: BANK_FETCH_MY_ACCOUNTS,
  }
}

export function fetchStorePaymentAccounts(
  body: { [key: string]: any },
  successCallback?: (res: Response) => void,
  failedCallback?: (err: Error) => void
): Object {
  return {
    type: BANK_FETCH_STORE_PAYMENT_ACCOUNTS,
    payload: body,
    successCallback,
    failedCallback,
  }
}

// TODO: เดี๋ยวต้องแปลงเป็น bank account ของ store
// export function fetchStoreBankAccounts({ store_id }) {
//   return {
//     type: BANK_ACCOUNTS_FETCH,
//     payload: { store_id },
//   }
// }

// TODO: เดี๋ยวต้องมาทำการ fetch bank account ของผู้ขายด้วย
// export function fetchSellerBankAccounts({ store_id }) {
//   return {
//     type: BANK_ACCOUNTS_FETCH,
//     payload: { store_id },
//   }
// }

// Reducer
export default function BankStateReducer(state = initialState, action: ActionApiParams = {}) {
  const { type, payload } = action
  let s = state
  switch (type) {
    case INITIALIZE_STATE:
    case BANK_ACCOUNTS_LOAD:
      s = s.set('fetchAccounts', fromJS(payload))
      return s.set('editingAccounts', s.get('fetchAccounts'))

    case STORE_PAYMENT_ACCOUNTS_FETCHED:
      return setStorePAInPaymentAccountsForKey(s, payload.store_id, fromJS(payload.payment_accounts))
    // s = s.set('fetchAccounts', fromJS(payload))
    // return s.set('editingAccounts', s.get('fetchAccounts'))

    case BANK_EDITING_ACCOUNT_RESET:
      return s.set('editingAccount', initAccount)
    case BANK_EDITING_ACCOUNT_CHANGE:
      return s.setIn(['editingAccount', payload.key], payload.value)

    case LOAD_EDITING_ACCOUNT_FROM_STORE_ACCOUNTS:
      s = s.set('selectedAccount', s.getIn(['stores', payload.store_id, payload.account_index]))
      return s.set('editingAccount', s.get('selectedAccount'))

    default:
      return state
  }
}
