import { Component } from 'react'

import _ from 'lodash'
import { ICategory } from 'x/index'
import * as util from '../../../utils/util'

export interface BaseCategoryListOverlayProps {
  visible: boolean
  categoetProps: ICategory[]
  onRequestClose: Function
  fetchCategory: Function
  store_id: number
  mode: 'ON_CHANGE_GROUP' | 'SELETED_CATEGORY'
  submit: Function
  category_id?: number // use only mode SELETED_CATEGORY
  selectedCategoryProps?: ICategory // use only mode ON_CHANGE_GROUP
}

export interface BaseCategoryListOverlayState {
  category: ICategory[] // ALL CATEGORY
  subCategory: ICategory[]
  selectedCategory: ICategory
  pageLoading: boolean
  breadCrumbs: ICategory[]
  category_id?: number
}

export default abstract class BaseCategoryListOverlay extends Component<BaseCategoryListOverlayProps, BaseCategoryListOverlayState> {
  protected constructor(props) {
    super(props)

    this.state = {
      category: [],
      pageLoading: true,
      subCategory: [],
      breadCrumbs: null,
      selectedCategory: null,
      category_id: null,
    }
  }

  async componentDidMount() {
    const { categoetProps, fetchCategory, store_id, category_id = null } = this.props
    let categoryList = categoetProps
    const subCategory = []
    if (_.isNil(categoetProps) || categoetProps.length < 1) {
      const res: ICategory[] = await new Promise((resolve) => {
        fetchCategory({
          body: { store_id },
          successCallback: resolve,
          failedCallback: resolve,
        })
      })
      if (!_.isNil(res)) {
        categoryList = _.isArray(res) ? res : []
      } else {
        categoryList = []
      }
    }
    if (!_.isArray(categoryList)) {
      categoryList = []
    }
    categoryList.map((cat: ICategory, index: number) => {
      if (_.isNil(cat.p)) {
        const newCat = cat
        newCat.index = index
        if (!_.isNil(category_id) && category_id === cat.id) {
          newCat.selected = true
        }
        subCategory.push(cat)
      }
    })
    util.setStatePromise(this, {
      category: categoryList,
      subCategory,
      category_id,
    })
  }

  _seletedSupCategory = async (seletedCategory: ICategory) => {
    const { breadCrumbs } = this.state
    await util.setStatePromise(this, { pageLoading: true })
    const newBreadCrumbs = _.isNil(breadCrumbs) ? [] : breadCrumbs
    const subCategory = await this._fineSupCat(seletedCategory)
    const newSeletedCategory = seletedCategory
    newSeletedCategory.indexBreadCrumbs = newBreadCrumbs.length
    newBreadCrumbs.push(newSeletedCategory)
    // await util.delay(500)
    await util.setStatePromise(this, { subCategory, selectedCategory: seletedCategory, breadCrumbs: newBreadCrumbs })
    await util.setStatePromise(this, { pageLoading: false })
  }

  _seletedBreadCrumbs = async (seletedCategory: ICategory) => {
    await util.setStatePromise(this, { pageLoading: true })
    const { breadCrumbs } = this.state
    const newBreadCrumbs = []
    breadCrumbs.map((data: ICategory) => {
      if (data.indexBreadCrumbs <= seletedCategory.indexBreadCrumbs) {
        newBreadCrumbs.push(data)
      }
    })
    const subCategory = await this._fineSupCat(seletedCategory)
    await util.setStatePromise(this, { subCategory, selectedCategory: seletedCategory, breadCrumbs: newBreadCrumbs })
    await util.setStatePromise(this, { pageLoading: false })
  }

  _seletedCat = async (category: ICategory) => {
    const { subCategory } = this.state
    const newSubCategory = []
    subCategory.map((data: ICategory) => {
      if (category.id === data.id) {
        data.selected = true
      } else {
        data.selected = false
      }
      newSubCategory.push(data)
    })
    await util.setStatePromise(this, {
      subCategory: newSubCategory,
    })
  }

  _fineSupCat = async (seletedCategory: ICategory) => {
    const { category } = this.state
    const subCategory = []
    if (!_.isNil(seletedCategory.c) && seletedCategory.c.length > 0) {
      seletedCategory.c.map((id: number, index: number) => {
        const cat = _.find(category, (data) => data.id === id)
        if (!_.isNil(cat)) {
          const newCat = cat
          newCat.index = index
          subCategory.push(cat)
        }
      })
    }
    return subCategory
  }

  _onPressBreadCrumbsHome = async () => {
    const { category } = this.state
    await util.setStatePromise(this, { pageLoading: true })
    const subCategory = []
    category.map((cat: ICategory) => {
      if (_.isNil(cat.p)) {
        subCategory.push(cat)
      }
    })
    await util.setStatePromise(this, { subCategory, selectedCategory: null, breadCrumbs: null })
    await util.setStatePromise(this, { pageLoading: false })
  }

  _sortUp = async (category: ICategory[], index: number) => {
    const indexToChange = index - 1
    const supCategory = []
    category.map((cat: ICategory) => {
      const newCat = cat
      if (cat.index === indexToChange) {
        newCat.index = cat.index + 1
        supCategory.push(newCat)
      } else if (cat.index === index) {
        newCat.index = index - 1
        supCategory.push(newCat)
      } else {
        supCategory.push(newCat)
      }
    })
    await util.setStatePromise(this, { supCategory })
  }

  _sortDown = async (category: ICategory[], index: number) => {
    const indexToChange = index + 1
    const supCategory = []
    category.map((cat: ICategory) => {
      const newCat = cat
      if (cat.index === indexToChange) {
        newCat.index = cat.index - 1
        supCategory.push(newCat)
      } else if (cat.index === index) {
        newCat.index = index + 1
        supCategory.push(newCat)
      } else {
        supCategory.push(newCat)
      }
    })
    await util.setStatePromise(this, { supCategory })
  }

  _submitCategory = async () => {
    const { subCategory, selectedCategory } = this.state
    const { submit, mode } = this.props
    // console.log('mode => ', mode)
    if (mode === 'SELETED_CATEGORY') {
      let seletedCat = null
      subCategory.forEach((data: ICategory) => {
        if (data.selected) {
          seletedCat = data
        }
      })
      if (!_.isNil(seletedCat) && _.isFunction(submit)) {
        await util.setStatePromise(this, { pageLoading: true })
        await this._onRequestClose()
        await util.delay(300)
        await util.setStatePromise(this, { pageLoading: false })
        await submit(seletedCat)
      } else {
        await this._onRequestClose()
        await util.delay(300)
        await util.setStatePromise(this, { pageLoading: false })
      }
    } else {
      await util.setStatePromise(this, { pageLoading: true })
      await this._onRequestClose()
      await util.delay(300)
      await util.setStatePromise(this, { pageLoading: false })
      await submit(selectedCategory)
    }
  }

  _chackChildren = (categoryIn: ICategory) => {
    const { category } = this.state
    let haveChildren = false
    category.map((data: ICategory) => {
      if (data.p === categoryIn.id) {
        haveChildren = true
      }
    })
    return haveChildren
  }

  _onRequestClose = async () => {
    const { onRequestClose, category_id } = this.props
    const { subCategory, category } = this.state
    const newSubCat = []
    if (!_.isNil(category_id)) {
      subCategory.map((data: ICategory) => {
        const newData = data
        if (category_id === data.id) {
          newData.selected = true
        } else {
          newData.selected = false
        }
        newSubCat.push(newData)
      })
      const newCat = []
      category.map((data: ICategory) => {
        const newData = data
        if (category_id === data.id) {
          newData.selected = true
        } else {
          newData.selected = false
        }
        newCat.push(newData)
      })
      await util.setStatePromise(this, { subCategory: newSubCat, category: newCat })
      onRequestClose()
    } else {
      onRequestClose()
    }
  }
}
