import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IProductDetailItem } from 'x/index'

interface IState {
  fetchedProductsByKey: {
    [productId: number]: IProductDetailItem
  }
}

const initialState: IState = {
  fetchedProductsByKey: {},
}

const ProductSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setFetchedProduct: (state, action: PayloadAction<{ product: IProductDetailItem }>) => {
      if (!action.payload) {
        return
      }
      const { product = null } = action.payload
      if (!product || !product.id) {
        return
      }
      state.fetchedProductsByKey[product.id] = product
    },
    unsetFetchedProduct: (state, action: PayloadAction<{ product_id: number }>) => {
      if (!action.payload || !action.payload.product_id) {
        return
      }
      delete state.fetchedProductsByKey[action.payload.product_id]
    },
  },
})

export default ProductSlice
