import React from 'react'
import { View } from 'react-native'
import { IStackProps } from 'native-base'
import Box from './Box'
import getSpacedChildren from './getSpacedChildren'

const VStack = React.forwardRef<View, IStackProps>((props, ref) => {
  const { children, space, reversed, divider, ...restProps } = props
  return (
    // @ts-ignore
    // const { flex, style = {}, ...restProps } = props
    // const [style, restProps] = useStyledSystemPropsResolver(props)
    <Box
      // @ts-ignore
      ref={ref}
      // style={[
      //   s.defaultVerticalBox,
      //   style,
      //   // @ts-ignore
      //   // _.isNil(flex) ? {} : { flex },
      // ]}
      flexDirection='column'
      {...restProps}>
      {
        // @ts-ignore
        getSpacedChildren(children, space, 'Y', reversed ? 'reverse' : 'normal', divider)
      }
    </Box>
  )
})
VStack.displayName = 'XVStack'

// const s = StyleSheet.create({
//   defaultVerticalBox: {
//     // flex: 1,
//     // height: '100%',
//     flexDirection: 'column',
//   },
// })

export default VStack as React.FC<IStackProps>
