import React from 'react'

import _ from 'lodash'
import { setStatePromise } from 'x/utils/util'
import * as util from 'x/utils/util'
import { Map } from 'immutable'
import xCONS from 'x/config/constants'
import dayjs, { Dayjs } from 'dayjs'
import { APP_CONFIG, MODE } from 'x/config/mode'
import { IMKPChannelDetail, IXScreenProps } from 'x/types'
import p from '../../config/platform-specific'

export interface BaseCompleteSalesReportViewProps extends IXScreenProps<{ store_id: number }> {
  selectedStore: Map<string, any>
  subscription: any
}

export interface BaseCompleteSalesReportViewState {
  loading: boolean
  // selectedOptListBy: string;
  // selectedOptDateRange: string;
  selectedOpts: {
    DATE_RANGE_ORDER: string
    DATE_RANGE: string
    PAYMENT: string
    SHIPPING: string
  }
  selectedDateRange: { begin: Dayjs; end: Dayjs }
  limitDay: number
  allowDays: number
  showOrderOptions: boolean
  dateRangeDescription: string
  PAYMENT_STATUS: boolean
  ORDER_STATUS: boolean
  isLoading: boolean
  loadingWidth: number
  loadingHeight: number
  optionsPaymentNumber: number
  optionsShippingNumber: number

  segmentOrderOriginIndex: number
  segmentOrderChannelsIndex: number
  orderOrigin?: 'xselly_only' | 'mkp_only'
  mkpChannelActiveMap: {
    [mkpChId: number]: boolean
  }
  orderChannelActiveMap: {
    [orderChId: number]: boolean
  }
}

export default abstract class BaseCompleteSalesReportView extends React.Component<
  BaseCompleteSalesReportViewProps,
  BaseCompleteSalesReportViewState
> {
  inProcess: boolean

  TXT_TITLES: {
    DATE_RANGE_ORDER: string
    DATE_RANGE: string
    PAYMENT: string
    SHIPPING: string
  }

  TXT_WARNING_DATE_RANGE: string

  OPTIONS: {
    DATE_RANGE_ORDER: string[]
    DATE_RANGE: string[]
    PAYMENT: string[]
    SHIPPING: string[]
    MKP_CHANNELS: string[]
    ORDER_CHANNELS: string[]
  }

  TXT_CHECKBOX: {
    TITLE_PAYMENT_STATUS: string
    PAYMENT_STATUS: string
    TITLE_ORDER_STATUS: string
    ORDER_STATUS: string
  }

  OrderChannelItems: Array<{
    id: number
    label: string
    index: number
    right?: string
    value: number
    key: string
  }>
  // OPTIONS_PAYMENT: string[]
  // OPTIONS_SHIPPING: string[]
  // OPTIONS_LIST_BY: string[]
  // OPTIONS_DATE_RANGE: string[]

  abstract _getParams(): { store_id: number } | null

  abstract _handleOnDownloadFile(url: string, fileName: string): Promise<void>

  protected constructor(props) {
    super(props)
    this.TXT_TITLES = {
      DATE_RANGE_ORDER: 'สถานะออเดอร์ที่...',
      DATE_RANGE: 'ภายใน...',
      PAYMENT: 'สถานะการยืนยันรับชำระ',
      SHIPPING: `สถานะการจัดส่งของออเดอร์`,
    }
    this.OPTIONS = {
      DATE_RANGE_ORDER: ['เสร็จสิ้น', 'สร้าง', 'จัดส่งครบถ้วน'],
      DATE_RANGE: ['วันนี้', '3 วัน', 'ระบุช่วงเวลา'],
      PAYMENT: ['ทั้งหมด', 'ครบถ้วน', 'ไม่ครบถ้วน'],
      SHIPPING: ['ทั้งหมด', 'ครบถ้วน', 'ไม่ครบถ้วน'],
      MKP_CHANNELS: ['ทั้งหมด', 'XSelly', 'ช่องทางขาย'],
      ORDER_CHANNELS: ['ทั้งหมด', 'ระบุ'],
    }

    this.TXT_CHECKBOX = {
      TITLE_PAYMENT_STATUS: 'สถานะออเดอร์การชำระ',
      PAYMENT_STATUS: 'ยืนยันรับชำระมาพอดีแล้ว',
      TITLE_ORDER_STATUS: 'สถานะออเดอร์การจัดส่ง',
      ORDER_STATUS: 'จัดส่งครบถ้วน ',
    }
    this.TXT_WARNING_DATE_RANGE = 'แพ็กเกจปัจจุบันของคุณไม่สามารถเลือกช่วงเวลาเพื่อดูรายการจัดส่งย้อนหลังได้'

    this.OrderChannelItems = xCONS.ORDER_VIEW_CHANNEL_ITEMS.map((item) => ({
      ...item,
      id: item.value,
      label: p.op.t(`Order.channelItems.${item.key}`),
    }))

    // this.OPTIONS_PAYMENT = [`ครบถ้วน`, `ไม่ครบถ้วน`, `ทั้งหมด`]
    // this.OPTIONS_SHIPPING =

    this.state = {
      loading: false,
      selectedOpts: {
        DATE_RANGE_ORDER: this.OPTIONS.DATE_RANGE_ORDER[0],
        DATE_RANGE: this.OPTIONS.DATE_RANGE[0],
        PAYMENT: this.OPTIONS.PAYMENT[0],
        SHIPPING: this.OPTIONS.SHIPPING[0],
      },
      selectedDateRange: { begin: dayjs(), end: dayjs() },
      limitDay: 93,
      allowDays: 5,
      showOrderOptions: false,
      dateRangeDescription: '',
      ORDER_STATUS: false,
      PAYMENT_STATUS: false,
      isLoading: false,
      loadingWidth: 0,
      loadingHeight: 0,
      optionsPaymentNumber: 0,
      optionsShippingNumber: 0,

      segmentOrderOriginIndex: 0,
      segmentOrderChannelsIndex: 0,
      mkpChannelActiveMap: {},
      orderChannelActiveMap: {},
    }

    this.inProcess = false

    this._onChangeSegment = this._onChangeSegment.bind(this)
    this._onChangeDateRange = this._onChangeDateRange.bind(this)
    this._isVisibleDateRangePicker = this._isVisibleDateRangePicker.bind(this)
    this._downloadReportShippingUrl = this._downloadReportShippingUrl.bind(this)
    this._computeAllowDays = this._computeAllowDays.bind(this)
    // this._getTxtDateRangeDescription = this._getTxtDateRangeDescription.bind(this)
  }

  _onChangeSegment(optKey: string, newValue: string): void {
    const newSelectedOpts = _.cloneDeep(this.state.selectedOpts)
    newSelectedOpts[optKey] = newValue
    // IS DEV CAN DOWNLOAD 99 DAY
    // IS PRODUTION UES MODE TS
    const LIMIT_NUM_OF_DAYS_PER_DOWNLOAD = MODE === 'dev' ? 99 : APP_CONFIG.report.complete_sales_report.limit_num_of_days_per_download
    const LIMIT_NUM_OF_DAYS_FOR_STATE = LIMIT_NUM_OF_DAYS_PER_DOWNLOAD
    if (newValue === this.OPTIONS.DATE_RANGE_ORDER[0]) {
      this.setState({ showOrderOptions: false })
    } else if (newValue === this.OPTIONS.DATE_RANGE_ORDER[1]) {
      this.setState({ showOrderOptions: true })
    } else if (newValue === this.OPTIONS.DATE_RANGE_ORDER[2]) {
      this.setState({ showOrderOptions: null })
    }
    let trialPackage = false
    if (this.props.subscription.get('type') === 999) {
      trialPackage = true
    }
    // // แพ็กเกจ (Free)
    // if (this.props.subscription.get('type') === xCONS.SUBSCRIPTION_PACKAGE.FREE) {
    //   if (newSelectedOpts[optKey] === this.OPTIONS.DATE_RANGE_ORDER[1]) {
    //     p.op.alert(p.op.t('Subscription.warning.insufficientTypeTitle'), p.op.t('Subscription.warning.insufficientTypeMsg'))
    //     return
    //   }
    // }

    // แพ็กเกจ (Bronze)
    if (this.props.subscription.get('type') === xCONS.SUBSCRIPTION_PACKAGE.BRONZE) {
      const num = +p.op.t('Subscription.packageReportSeller.2')
      const txtDateRangeDescription = `${p.op.t('Subscription.warning.tooManyDaysInPastTitle', {
        numOfDays: num,
      })}\n${p.op.t('Subscription.warning.numOfDaysLimitInfoMsg', { numOfDays: LIMIT_NUM_OF_DAYS_PER_DOWNLOAD })}\n${p.op.t(
        'Subscription.warning.tooManyDaysInPastMsg'
      )}`
      this.setState({
        allowDays: num,
        limitDay: LIMIT_NUM_OF_DAYS_FOR_STATE,
        dateRangeDescription: txtDateRangeDescription,
      })
    }
    // แพ็กเกจ Silver
    if (this.props.subscription.get('type') === xCONS.SUBSCRIPTION_PACKAGE.SILVER) {
      const num = +p.op.t('Subscription.packageReportSeller.3')
      const txtDateRangeDescription = `${p.op.t('Subscription.warning.tooManyDaysInPastTitle', {
        numOfDays: num,
      })}\n${p.op.t('Subscription.warning.numOfDaysLimitInfoMsg', { numOfDays: LIMIT_NUM_OF_DAYS_PER_DOWNLOAD })}\n${p.op.t(
        'Subscription.warning.tooManyDaysInPastMsg'
      )}`
      this.setState({
        allowDays: num,
        limitDay: LIMIT_NUM_OF_DAYS_FOR_STATE,
        dateRangeDescription: txtDateRangeDescription,
      })
    }
    // แพ็กเกจ Gold
    if (this.props.subscription.get('type') === xCONS.SUBSCRIPTION_PACKAGE.GOLD) {
      const num = +p.op.t('Subscription.packageReportSeller.4')
      const txtDateRangeDescription = `${p.op.t('Subscription.warning.tooManyDaysInPastTitle', {
        numOfDays: num,
      })}\n${p.op.t('Subscription.warning.numOfDaysLimitInfoMsg', { numOfDays: LIMIT_NUM_OF_DAYS_PER_DOWNLOAD })}\n${p.op.t(
        'Subscription.warning.tooManyDaysInPastMsg'
      )}`
      this.setState({
        allowDays: num,
        limitDay: LIMIT_NUM_OF_DAYS_FOR_STATE,
        dateRangeDescription: txtDateRangeDescription,
      })
    }
    // แพ็กเกจ (Platinum)
    if (this.props.subscription.get('type') === xCONS.SUBSCRIPTION_PACKAGE.PLATINUM || trialPackage) {
      // const num = +p.op.t('Subscription.packageReportSeller.5')
      const txtDateRangeDescription = `${p.op.t(
        'Subscription.warning.dateRangePlatinumTypeTitle'
      )}\n${p.op.t('Subscription.warning.numOfDaysLimitInfoMsg', { numOfDays: LIMIT_NUM_OF_DAYS_PER_DOWNLOAD })}`
      this.setState({
        allowDays: 9999, // TODO: Artid fix Subscription.packageReportSeller by creating more constants for each cases
        limitDay: LIMIT_NUM_OF_DAYS_FOR_STATE,
        dateRangeDescription: txtDateRangeDescription,
      })
    }

    this.setState({ selectedOpts: newSelectedOpts })
  }

  _onChangeDateRange(newDates: { begin: Dayjs; end: Dayjs }): void {
    // if (this.props.subscription.get('type') != 1 && this.props.subscription.get('type') != 5) {
    //   let { begin } = newDates
    //   const now = dayjs()
    //   if (begin.isBefore(now) && Math.abs(begin.diff(now, 'd')) > +this.state.limitDay) {
    //     newDates.begin = dayjs(now).subtract(+this.state.limitDay, 'day')
    //     newDates.end = dayjs(now)
    //     this.setState({ selectedDateRange: newDates })
    //     return
    //   }
    // }
    this.setState({ selectedDateRange: newDates })
  }

  _isVisibleDateRangePicker(): boolean {
    return this.state.selectedOpts.DATE_RANGE === this.OPTIONS.DATE_RANGE[2]
  }

  _computeAllowDays(): number {
    const { subscription } = this.props
    const allowDays = subscription.has('r_dsc_day') && _.isNumber(subscription.get('r_dsc_day')) ? subscription.get('r_dsc_day') : 1
    // log('_computeAllowDays => ', _.isNumber(allowDays) ? allowDays : parseInt(allowDays))
    return _.isNumber(allowDays) ? allowDays : parseInt(allowDays)
  }

  _canRequestMoreColumns = (): boolean => util.canRequestExtraExcelColumns(this.props.subscription.get('type'))

  // _getTxtDateRangeDescription(): string {
  //   const allowDays = this._computeAllowDays()
  //   let txtDesc = 'สามารถดูรายงานย้อนหลังได้'

  //   if (allowDays === 9999) { //
  //     txtDesc += `ตั้งแต่เปิดร้าน`
  //   } else {
  //     txtDesc += ` ${allowDays} วัน`
  //   }

  //   if (allowDays > 93) {
  //     txtDesc += '\nโดยสามารถดาวน์โหลดรายงานได้สูงสุด 93 วันต่อครั้ง'
  //   }

  //   return txtDesc
  // }

  _onChangeSegmentPayment = async (newIndex: number) => {
    await util.setStatePromise(this, { optionsPaymentNumber: newIndex })
  }

  _onChangeSegmentShipping = async (newIndex: number) => {
    await util.setStatePromise(this, { optionsShippingNumber: newIndex })
  }

  onChangeSegmentOrderOriginAtIndex = async (newIndex: number) => {
    const newState: Partial<BaseCompleteSalesReportViewState> = {}

    newState.segmentOrderOriginIndex = newIndex

    switch (newIndex) {
      case 0: // ทั้งหมด (default)
        delete newState.orderOrigin
        newState.mkpChannelActiveMap = {}
        break
      case 1: // XSelly
        newState.orderOrigin = 'xselly_only'
        newState.mkpChannelActiveMap = {}
        break
      case 2: // ช่องทางขาย
        newState.orderOrigin = 'mkp_only'
        const mkpChannels = util.getMKPChannels()
        newState.mkpChannelActiveMap = {}
        for (let i = 0; i < mkpChannels.length; i++) {
          newState.mkpChannelActiveMap[mkpChannels[i].id] = true
        }
        break
      default:
        return
    }

    await util.setStatePromise(this, newState)
  }

  _onMkpChannelSelectorItemPress = async (ch: IMKPChannelDetail, index: number) => {
    const { mkpChannelActiveMap: oneOne = {} } = this.state
    const newState: Partial<BaseCompleteSalesReportViewState> = {}

    const mkpChannelActiveMap = { ...oneOne }
    const mkpChId = ch.id

    if (_.isNil(mkpChannelActiveMap[mkpChId])) {
      mkpChannelActiveMap[mkpChId] = true
    } else if (_.isBoolean(mkpChannelActiveMap[mkpChId])) {
      mkpChannelActiveMap[mkpChId] = !mkpChannelActiveMap[mkpChId]
    }

    newState.mkpChannelActiveMap = mkpChannelActiveMap

    let isAllFalse = true
    const mChIds = Object.keys(newState.mkpChannelActiveMap).map((mChId) => parseInt(mChId))
    for (let i = 0; i < mChIds.length; i++) {
      const mChId = mChIds[i]
      if (newState.mkpChannelActiveMap[mChId]) {
        isAllFalse = false
        break
      }
    }

    if (isAllFalse) {
      return
    }

    await util.setStatePromise(this, newState)
  }

  onChangeSegmentOrderChannelsAtIndex = async (newIndex: number) => {
    const newState: Partial<BaseCompleteSalesReportViewState> = {}

    newState.segmentOrderChannelsIndex = newIndex

    switch (newIndex) {
      case 0: // all
        newState.orderChannelActiveMap = {}
        break
      case 1: // ระบุ
        newState.orderChannelActiveMap = {}
        newState.orderChannelActiveMap[0] = true
        break
      default:
        return
    }

    await util.setStatePromise(this, newState)
  }

  _onOrderChannelSelectorItemPress = async (ch, index: number) => {
    const { orderChannelActiveMap: oneOne = {} } = this.state
    const newState: Partial<BaseCompleteSalesReportViewState> = {}

    const orderChannelActiveMap = { ...oneOne }
    const orderChId = ch.id

    if (_.isNil(orderChannelActiveMap[orderChId])) {
      orderChannelActiveMap[orderChId] = true
    } else if (_.isBoolean(orderChannelActiveMap[orderChId])) {
      orderChannelActiveMap[orderChId] = !orderChannelActiveMap[orderChId]
    }

    newState.orderChannelActiveMap = orderChannelActiveMap

    let isAllFalse = true
    const mChIds = Object.keys(newState.orderChannelActiveMap).map((mChId) => parseInt(mChId))
    for (let i = 0; i < mChIds.length; i++) {
      const mChId = mChIds[i]
      if (newState.orderChannelActiveMap[mChId]) {
        isAllFalse = false
        break
      }
    }

    if (isAllFalse) {
      return
    }

    await util.setStatePromise(this, newState)
  }

  async _downloadReportShippingUrl(): Promise<void> {
    const { selectedStore, subscription } = this.props

    const isNoobIos = util.isIosNoobCheckByPass()
    // if (isNoobIos) {
    //   p.op.showConfirmationOkOnly('', p.op.t('Subscription.warning.iosNoobCheckMsg'))
    //   return
    // }
    // ถ้าเป็น IOS และเข้าเงื่อนไขก็ปล่อยผ่าน // https://app.clickup.com/t/86cvy21qt
    if (!isNoobIos && util.isPackFreeAndSelectedStoreOwner()) {
      p.op.alert(p.op.t('Subscription.warning.insufficientTypeTitle'), p.op.t('Subscription.warning.insufficientTypeMsg'))
      this.inProcess = false
      await util.setStatePromise(this, { downloadingReport: false })
      return
    }

    // TODO: Check for perm on BaseReportProductDailySales line 335?
    // const isHlper = util.isHelper()
    // // if (!isHlper && util.isStoreOwner() && subscription.get('type') === xCONS.SUBSCRIPTION_PACKAGE.FREE) {
    // if (!isHlper && util.isStoreOwner()) {
    //   p.op.alert(p.op.t('Subscription.warning.insufficientTypeTitle'), p.op.t('Subscription.warning.insufficientTypeMsg'))
    //   return
    // }

    if (this.inProcess || this.state.loading) {
      return
    }
    this.inProcess = true
    await setStatePromise(this, { loading: true })

    const {
      selectedOpts,
      selectedDateRange,
      showOrderOptions,
      segmentOrderChannelsIndex,
      segmentOrderOriginIndex,
      orderChannelActiveMap,
      orderOrigin,
      mkpChannelActiveMap,
    } = this.state

    let { store_id } = this._getParams()
    if (_.isNil(store_id)) {
      store_id = selectedStore.get(`id`)
      if (_.isNil(store_id)) {
        p.op.showConfirmationOkOnly(`เกิดข้อผิดพลาด`, `กรุณาปิดหน้าต่างนี้แล้วลองใหม่อีกครั้ง`)
        return
      }
    }

    let startDate: Dayjs | string = dayjs()
    let endDate: Dayjs | string = dayjs()
    const now = dayjs()

    // let completed_at_from = ''
    // let completed_at_to = ''
    // let created_at_from = ''
    // let created_at_to = ''
    // let all_shipped_at_from
    // let all_shipped_at_to

    // ถ้า user เลือก custom date range
    if (selectedOpts.DATE_RANGE === this.OPTIONS.DATE_RANGE[2]) {
      startDate = selectedDateRange.begin
      endDate = selectedDateRange.end
    } else if (selectedOpts.DATE_RANGE === this.OPTIONS.DATE_RANGE[1]) {
      startDate = now.subtract(2, 'days')
    }

    // log('/////////////////////////////////////////////////////////')
    // log(this.state.PAYMENT_STATUS)
    // log(this.state.ORDER_STATUS)

    // ถ้า user เลือก ผู้ขายส่ง
    // if (selectedOpts.LIST_BY === this.OPTIONS.LIST_BY[1]) {
    //   asReseller = true
    // }

    startDate = startDate.format('YYYY-MM-DD%2000:00:00')
    endDate = endDate.format('YYYY-MM-DD%2023:59:59')

    // log(startDate + ' --- ' + endDate)
    // const startDate = '19-03-2018'

    // let payment_states = this.state.optionsPaymentNumber
    // let shipping_states = this.state.optionsShippingNumber

    // if (selectedOpts.DATE_RANGE_ORDER === this.OPTIONS.DATE_RANGE_ORDER[1]) {
    //   created_at_from = startDate
    //   created_at_to = endDate
    //   completed_at_from = null
    //   completed_at_to = null
    //   all_shipped_at_from = null
    //   all_shipped_at_to = null
    // } else if (selectedOpts.DATE_RANGE_ORDER === this.OPTIONS.DATE_RANGE_ORDER[0]) {
    //   created_at_from = null
    //   created_at_to = null
    //   completed_at_from = startDate
    //   completed_at_to = endDate
    //   payment_states = null
    //   shipping_states = null
    //   all_shipped_at_from = null
    //   all_shipped_at_to = null
    // } else if (selectedOpts.DATE_RANGE_ORDER === this.OPTIONS.DATE_RANGE_ORDER[2]) {
    //   created_at_from = null
    //   created_at_to = null
    //   completed_at_from = null
    //   completed_at_to = null
    //   payment_states = null
    //   shipping_states = null
    //   all_shipped_at_from = startDate
    //   all_shipped_at_to = endDate
    // }

    let completed_at_from
    let completed_at_to
    let created_at_from
    let created_at_to
    let all_shipped_at_from
    let all_shipped_at_to
    let payment_states
    let shipping_states

    switch (selectedOpts.DATE_RANGE_ORDER) {
      case this.OPTIONS.DATE_RANGE_ORDER[0]:
        completed_at_from = startDate
        completed_at_to = endDate
        break
      case this.OPTIONS.DATE_RANGE_ORDER[1]:
        created_at_from = startDate
        created_at_to = endDate
        break
      case this.OPTIONS.DATE_RANGE_ORDER[2]:
        all_shipped_at_from = startDate
        all_shipped_at_to = endDate
        break
    }

    // Clone and adapted from: src/xshare/x/utils/util.ts:1026
    if (!_.isNil(all_shipped_at_to)) {
      shipping_states = [129]
    } else {
      switch (this.state.optionsPaymentNumber) {
        // 'ทั้งหมด'
        case 0:
          payment_states = [119, 111, 112, 113, 114, 115]
          break
        // 'ครบถ้วน'
        case 1:
          payment_states = [119]
          break
        // 'ไม่ครบถ้วน'
        case 2:
          payment_states = [111, 112, 113, 114, 115]
          break
      }

      switch (this.state.optionsShippingNumber) {
        // 'ทั้งหมด'
        case 0:
          shipping_states = [129, 121, 122]
          break
        // 'ครบถ้วน'
        case 1:
          shipping_states = [129]
          break
        // 'ไม่ครบถ้วน'
        case 2:
          shipping_states = [121, 122]
          break
      }
    }

    let url = util.getCompleteSalesReportViewExcelUrl({
      store_id,
      // created_at_from,
      // created_at_to,
      // completed_at_from,
      // completed_at_to,
      // payment_states,
      // shipping_states,
      // all_shipped_at_from,
      // all_shipped_at_to,
    })

    const queryParams: any = {
      completed_at_from,
      completed_at_to,
      created_at_from,
      created_at_to,
      all_shipped_at_from,
      all_shipped_at_to,
      payment_states,
      shipping_states,
    }

    if (_.isString(orderOrigin)) {
      queryParams.order_origin = orderOrigin
    }

    if (segmentOrderOriginIndex === 2 && _.isObject(mkpChannelActiveMap) && !_.isEmpty(mkpChannelActiveMap)) {
      queryParams.mkp_ch_ids = Object.keys(mkpChannelActiveMap)
        .filter((key) => mkpChannelActiveMap[key])
        .map((key) => parseInt(key))
    }

    if (segmentOrderChannelsIndex === 1 && _.isObject(orderChannelActiveMap) && !_.isEmpty(orderChannelActiveMap)) {
      queryParams.channel_ids = Object.keys(orderChannelActiveMap)
        .filter((key) => orderChannelActiveMap[key])
        .map((key) => parseInt(key))
    }

    const serialzedQueryParamsString = util.serializeObjectToQueryParamsString(queryParams)
    if (serialzedQueryParamsString) {
      url = `${url}&${serialzedQueryParamsString}`
    }

    // console.log('url => ', url)
    let fileName = `completesales${startDate}-${endDate}`

    fileName = `${fileName}.xlsx`
    // log('_downloadReportShippingUrl url => ', url)
    // log('_downloadReportShippingUrl fileName => ', fileName)
    await this._handleOnDownloadFile(url, fileName)
    p.op.aLogEvent(xCONS.EVENT_NAME.REPORT_COMPLETE_SALES, { s: store_id })
    await setStatePromise(this, { loading: false })
    await setTimeout(() => {
      this.inProcess = false
    }, 500)
  }
}
