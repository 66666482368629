/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
import React from 'react'

// import * as NavActions from '../../services/navigation'
// import FAIcon from 'react-native-vector-icons/FontAwesome'

import { COLORS, STYLES } from 'x/config/styles'

// import BackIcon from '../../components/BackIcon'
import Segment from 'xui/components/Segment'
import DateRangePicker from 'xui/components/XDateRangePicker'

import BaseReportResellerView from 'x/modules/report/BaseReportResellerView'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import XCard from 'xui/components/XCard'
import Box from 'xui/components/Box'
import XText from 'xui/components/XText'
import XButton from 'xui/components/XButton'
import HStack from 'xui/components/HStack'
import XIcon from 'xui/components/XIcon'
import * as util from 'x/utils/util'

const { FONT_SIZE_NORMAL, FONT_SIZE_SMALLER } = STYLES

const { BG_LIGHT_GREY_ALTERNATIVE, TEXT_ACTIVE, TEXT_INACTIVE, APP_MAIN } = COLORS

// @ts-ignore
export default abstract class BaseUIReportResellerView extends BaseReportResellerView {
  static displayName = 'BaseUIReportResellerView'

  abstract _handleOnDownloadFileByPlatform(url: string, fileName: string): Promise<void>

  async _handleOnDownloadFile(url: string, fileName: string): Promise<void> {
    await this._handleOnDownloadFileByPlatform(url, fileName)
  }

  _renderHeaderView = () => (
    <XCustomHeader title='ยอดขายตัวแทน' headerLeftProps={{ backIcon: true, onPressItem: () => util.navGoBack(this.props) }} />
  )

  _renderSegmentCard(optKey: string) {
    const txtTitle = this.TXT_TITLES[optKey]
    const options = this.OPTIONS[optKey]
    const selectedValue = this.state.selectedOpts[optKey]
    return (
      <Box w='full' mt={optKey === 'TIME' ? '2' : '0'}>
        <XText allowFontScaling={false} bold variant='active'>
          {txtTitle}
        </XText>
        <Segment onChange={(newValue) => this._onChangeSegment(optKey, newValue)} options={options} selectedValue={selectedValue} />
      </Box>
    )
  }

  _renderShippingDateRange = () => {
    const isVisible = this._isVisibleDateRangePicker()
    const isDisabledDateRange = this._computeAllowDays() <= 1
    return isVisible ? (
      <Box h='32' mt='2'>
        <DateRangePicker
          disabled={isDisabledDateRange}
          allowOnlyDaysFromToday={this._computeAllowDays()}
          allowDateRangeLimitDays={this.state.limitDay}
          selectedDates={this.state.selectedDateRange}
          onChangeDate={this._onChangeDateRange}
        />
        {isDisabledDateRange ? (
          <XText variant='inactive' textAlign='center' fontSize='sm'>
            {this.TXT_WARNING_DATE_RANGE}
          </XText>
        ) : (
          <XText variant='primary' textAlign='center' fontSize='sm'>
            {this.state.dateRangeDescription}
          </XText>
        )}
      </Box>
    ) : (
      <Box />
    )
  }

  _renderFooter = () => {
    const { loading } = this.state
    return (
      <HStack w='full' p='1'>
        <XButton
          py='3'
          w='full'
          disabled={loading}
          leftIcon={<XIcon family='FontAwesome' name='file-excel-o' size='xs' />}
          onPress={() => this._downloadReportRequisitionUrl()}
          // style={{
          //   height: 30,
          //   backgroundColor: COLORS.APP_MAIN,
          //   borderRadius: 7,
          //   alignItems: 'center',
          //   justifyContent: 'center',
          // }}
        >
          <XText color='white'>ดาวน์โหลด Excel</XText>
        </XButton>
      </HStack>
    )
  }

  _renderMain = () => (
    <XCard w='full' p='2'>
      {this._renderSegmentCard('REPORT_TYPE')}
      {this._renderSegmentCard('TIME')}
      {this._renderShippingDateRange()}
    </XCard>
  )

  render() {
    return (
      <XContainer>
        {this._renderHeaderView()}
        <XContent p='2'>{this._renderMain()}</XContent>
        {this._renderFooter()}
      </XContainer>
    )
  }
}
