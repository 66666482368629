import { getSelectedProduct, getSelectedStore } from 'x/redux/selectors'

export function getState(state) {
  return {
    selectedStore: getSelectedStore(state),
    selectedProduct: getSelectedProduct(state),
  }
}

export function getDispatch(dispatch) {
  return {
    dispatch,
  }
}
