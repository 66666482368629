import { extendTheme } from 'native-base'

import config from './config'
import components from './components'

import xTheme from './base/index'
// import colors from './base/colors'
// import sizes from './base/sizes'
// import { spacing } from './base/space'
// import typography from './base/typography'

// extend the theme
const theme = extendTheme({
  // Theme Config
  config,
  // Theme Colors
  // colors,

  // ...typography,
  // sizes,
  // space: spacing,

  ...xTheme,
  // Customize color/styles/variants NativeBase components
  components,

  increaseFontSizes: () => {
    // wait for binding
  },
  decreaseFontSizes: () => {
    // wait for binding
  },
})

console.log('XSelly theme => ', theme)

// https://docs.nativebase.io/typescript
export type IThemeType = typeof theme
export default theme
