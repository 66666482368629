import React from 'react'

import _ from 'lodash'
import * as util from 'x/utils/util'
import p from '../../config/platform-specific'

import { BaseStoreSettingBillProps, BaseStoreSettingBilState } from '../../index'

const HEADER = 'HEADER'
const FOOTER = 'FOOTER'
const SAVE_MODE = 'SAVE_MODE'
const EDIT_MODE = 'EDIT_MODE'

export default abstract class BaseStoreSettingBillView extends React.Component<BaseStoreSettingBillProps, BaseStoreSettingBilState> {
  inProcess: boolean

  printingAllId: number[]

  thisImgManagerRef: React.RefObject<any>

  thisImgManagerFooterRef: React.RefObject<any>
  // OPTIONS_LIST_BY: string[]
  // OPTIONS_DATE_RANGE: string[]

  // abstract _getParams(): { store_id: number } | null
  abstract _goBack(): any

  // abstract _getImg(key: string, url: string): Promise<void>

  abstract _saveImg(key: string): Promise<string>

  protected constructor(props) {
    super(props)

    this.state = {
      showHeaderImgUploaded: false,
      uploading: false,
      showFooterImgUploaded: false,
      uploadingFooter: false,
      printingBillPaperang: null,
      usePrintingBillPaperang: true,
      footerText: null,
      headerText: null,

      headerImgActive: true,
      headerTextActive: false,
      footerImgActive: true,
      footerTextActive: false,

      headerGalleryChoose: true,
      footerGalleryChoose: true,
      autoSaveImgPaperang: false,
      isShowVAT: false,

      isShowName: false,
      isShowAddress: false,
      isShowPhone: false,
      documentNaneLabel: 'ใบเสร็จรับเงิน',
    }

    this.inProcess = false

    this.thisImgManagerFooterRef = React.createRef()
    this.thisImgManagerRef = React.createRef()
  }

  async componentDidMount() {
    const { selectedStore } = this.props
    const billPaperang = selectedStore.get('printing_bill_paperang')
    await this.config(billPaperang ? billPaperang.toJS() : null)
  }

  config = async (billPaperang) => {
    if (_.isNil(billPaperang)) {
      p.op.showConfirmationOkOnly('เกิดข้อผิดพลาด', 'กรุณาติดต่อทีมงาน Xselly')
      this._goBack()
      return
    }
    // const { selectedStore } = this.props
    const pref = billPaperang.p
    // console.log('pref => ', pref)
    let headerText = null
    let footerText = null
    // console.log('pref => ', pref)
    let headerImgActive = false
    let headerTextActive = false
    let footerImgActive = false
    let footerTextActive = false
    let autoSaveImgPaperang = true
    let isShowVAT = false

    let isShowName = false
    let isShowAddress = false
    let isShowPhone = false
    let documentNaneLabel = 'ใบเสร็จรับเงิน'
    // console.log('pref', pref)
    // ถ้ามีข้อความบนหัวกระดาษ
    if (!_.isNil(pref)) {
      if (!_.isNil(pref.h)) {
        headerTextActive = true
        headerText = pref.h
      }
      // ถ้ามีรูปบนหัวกระดาษ
      if (!_.isNil(pref.i)) {
        headerImgActive = true
        await this._getImg(HEADER, pref.i)
      }
      // ถ้ามีข้อความท้ายกระดาษ
      if (!_.isNil(pref.f)) {
        footerText = pref.f
        footerTextActive = true
      }
      // ถ้ามีรูปท้ายกระดาษ
      if (!_.isNil(pref.g)) {
        footerImgActive = true
        await this._getImg(FOOTER, pref.g)
      }
      // บันทึกรูปอัตโนมัติ ?
      if (!_.isNil(pref.d)) {
        autoSaveImgPaperang = pref.d !== 0
      }
      // แสดงยอด VAT ก่อนและหลัง
      if (pref.v && pref.v === 1) {
        // isShowVAT = pref.d !== 0
        isShowVAT = pref.v
      }
      // show customer name
      if (pref.cn && pref.cn === 1) {
        isShowName = true
      }
      // show customer address
      if (pref.ca && pref.ca === 1) {
        isShowAddress = true
      }
      // show cusromer phone
      if (pref.ct && pref.ct === 1) {
        isShowPhone = true
      }
      // ชื่อเอกสาร
      if (pref.t) {
        documentNaneLabel = pref.t
      }
    }
    const usePrintingBillPaperang = billPaperang.a
    util.setStatePromise(this, {
      printingBillPaperang: billPaperang,
      usePrintingBillPaperang,
      headerText,
      footerText,

      headerImgActive,
      headerTextActive,
      footerImgActive,
      footerTextActive,

      autoSaveImgPaperang,
      isShowVAT,
      isShowName,
      isShowAddress,
      isShowPhone,
      documentNaneLabel,
    })

    this.props.navigation.setParams({
      cancelBtn: () => this.cancelButton(),
      onChange: false,
      saveBtn: () => this.saveButton(),
    })
  }

  onChangeEditMessage = (key: string, onPressMode: string) => {
    if (key === HEADER) {
      if (onPressMode === EDIT_MODE) {
        util.setStatePromise(this, {
          headerEditText: true,
        })
      } else if (onPressMode === SAVE_MODE) {
        util.setStatePromise(this, {
          headerEditText: false,
          headerActive: 1,
        })
      }
    } else if (key === FOOTER) {
      if (onPressMode === EDIT_MODE) {
        util.setStatePromise(this, {
          footerEditText: true,
        })
      } else if (onPressMode === SAVE_MODE) {
        util.setStatePromise(this, {
          footerEditText: false,
          footerActive: 1,
        })
      }
    }

    this.props.navigation.setParams({
      onChange: true,
    })
  }

  onChangeInput = (key: string, value: string) => {
    if (key === HEADER) {
      util.setStatePromise(this, {
        headerText: value,
      })
    } else if (key === FOOTER) {
      util.setStatePromise(this, {
        footerText: value,
      })
    }

    this.props.navigation.setParams({
      onChange: true,
    })
  }

  onChangeValue = async (key: string, value: any) => {
    this.props.navigation.setParams({
      onChange: true,
    })
    util.setStatePromise(this, {
      [key]: value,
    })
    const { printingBillPaperang } = this.state
    if (key === 'usePrintingBillPaperang' && value) {
      if (!_.isNil(printingBillPaperang) && !_.isNil(printingBillPaperang.p)) {
        const pref = printingBillPaperang.p
        if (!_.isNil(pref.i)) {
          await util.delay(300)
          await this._getImg(HEADER, pref.i)
        }
        if (!_.isNil(pref.g)) {
          await util.delay(300)
          await this._getImg(FOOTER, pref.g)
        }
      }
    }
  }

  selectedMessageRedio = (key: string) => {
    const { headerText, footerText } = this.state
    if (key === HEADER) {
      if (_.isNil(headerText) || headerText === '') {
        util.setStatePromise(this, { headerEditText: true, headerActive: 1 })
      } else {
        util.setStatePromise(this, { headerActive: 1 })
      }
    } else if (key === FOOTER) {
      if (_.isNil(footerText) || footerText === '') {
        util.setStatePromise(this, { footerEditText: true, footerActive: 1 })
      } else {
        util.setStatePromise(this, { footerActive: 1 })
      }
    }

    this.props.navigation.setParams({
      onChange: true,
    })
  }

  cancelButton = () => {
    // const { navigation } = this.props
    // const { state } = navigation
    // const { onChange } = state.params
    const onChange = util.getNavParam(this.props, 'onChange')
    if (onChange) {
      p.op.showConfirmation(
        'ยกเลิกการแก้ไข',
        'ยกเลิกการแก้ไขใช่หรือไม่',
        () => this._goBack(),
        () => {},
        'ใช่',
        'แก้ไขต่อ'
      )
    } else {
      this._goBack()
    }
  }

  saveButton = async () => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    const { addPrinting, selectedStore, editPrinting } = this.props
    const {
      usePrintingBillPaperang,
      printingBillPaperang,
      headerText,
      footerText,
      headerImgActive,
      headerTextActive,
      footerImgActive,
      footerTextActive,
      autoSaveImgPaperang,
      isShowVAT,
      isShowName,
      isShowAddress,
      isShowPhone,
      documentNaneLabel,
    } = this.state
    const store_id = selectedStore.get('id')
    const usePaperang = usePrintingBillPaperang
    const body: { [key: string]: any } = {
      store_id,
      printing_type_id: 2,
      size: 2,
      is_active: usePaperang,
    }

    if (!usePaperang) {
      body.pref = {}
    } else {
      body.pref = {}
      const pref = printingBillPaperang.p
      // console.log('pref', pref)
      // if (!_.isNil(pref)) {
      // ถ้าใช้ข้อความบนหัวใบเสร็จ
      if (headerTextActive) {
        if (_.isNil(headerText) || headerText === '') {
          p.op.showConfirmationOkOnly('โปรดระบุข้อความหัวใบเสร็จ', 'กรุณาระบุข้อความหัวใบเสร็จก่อนบันทึกข้อมูล')
          this.inProcess = false
          return
        }
        body.pref.h = headerText
      }
      // ถ้าใช้รูปหัวใบเสร็จ
      if (headerImgActive) {
        const headerImgRef = this.thisImgManagerRef.current
        if (headerImgRef && headerImgRef.submitUploadImages) {
          const output = await headerImgRef.submitUploadImages()
          if (!_.isNil(output.isEmpty) && output.isEmpty) {
            p.op.showConfirmationOkOnly('โปรดระบุรูปหัวใบเสร็จ', 'กรุณาเลือกรูปหัวใบเสร็จก่อนบันทึกข้อมูล')
            this.inProcess = false
            return
          }
        } else {
          p.op.showConfirmationOkOnly('เกิดข้อผิดพลาด', 'กรุณาทำรายการใหม่ภายหลัง')
          this.inProcess = false
          return
        }
        let url = null
        if (_.isNil(pref) || _.isNil(pref.i)) {
          url = await this._saveImg(HEADER)
        } else {
          url = pref.i
        }
        if (_.isNil(url)) {
          p.op.showConfirmationOkOnly('โปรดระบุรูปหัวใบเสร็จ', 'กรุณาเลือกรูปหัวใบเสร็จก่อนบันทึกข้อมูล')
          this.inProcess = false
          return
        }
        body.pref.i = url
      }
      // ถ้าใช้ข้อความท้ายใบเสร็จ
      if (footerTextActive) {
        if (_.isNil(footerText) || footerText === '') {
          p.op.showConfirmationOkOnly('โปรดระบุข้อความท้ายใบเสร็จ', 'กรุณาระบุข้อความท้ายใบเสร็จก่อนบันทึกข้อมูล')
          this.inProcess = false
          return
        }
        body.pref.f = footerText
      }
      // ถ้าใช้รูปท้ายใบเสร็จ
      if (footerImgActive) {
        const footerImgRef = this.thisImgManagerFooterRef.current
        if (footerImgRef && footerImgRef.submitUploadImages) {
          const output = await footerImgRef.submitUploadImages()
          if (!_.isNil(output.isEmpty) && output.isEmpty) {
            p.op.showConfirmationOkOnly('โปรดระบุรูปท้ายใบเสร็จ', 'กรุณาเลือกรูปท้ายใบเสร็จก่อนบันทึกข้อมูล')
            this.inProcess = false
            return
          }
        } else {
          p.op.showConfirmationOkOnly('เกิดข้อผิดพลาด', 'กรุณาทำรายการใหม่ภายหลัง')
          this.inProcess = false
          return
        }
        let url = null
        if (_.isNil(pref) || _.isNil(pref.g)) {
          url = await this._saveImg(FOOTER)
        } else {
          url = pref.g
        }
        if (_.isNil(url)) {
          p.op.showConfirmationOkOnly('โปรดระบุรูปท้ายใบเสร็จ', 'กรุณาเลือกรูปท้ายใบเสร็จก่อนบันทึกข้อมูล')
          this.inProcess = false
          return
        }
        body.pref.g = url
      }
      // }
      body.pref.d = autoSaveImgPaperang ? 1 : 0
      // isShowVAT ถ้า show VAT ให้ส่ง v = 1 ไป // ไม่ใช่ไม่ต้องส่งไป
      // if (isShowVAT) {
      body.pref.v = isShowVAT ? 1 : 0
      // }
      body.pref.cn = isShowName ? 1 : 0
      body.pref.ca = isShowAddress ? 1 : 0
      body.pref.ct = isShowPhone ? 1 : 0
      body.pref.t = documentNaneLabel
    }

    if (_.isNil(printingBillPaperang.id)) {
      addPrinting({
        body,
        successCallback: (res) => this.successCallback(res),
        failedCallback: (err) => this.failedCallback(),
      })
    } else {
      body.printing_id = printingBillPaperang.id
      editPrinting({
        body,
        successCallback: (res) => this.successCallback(res),
        failedCallback: (err) => this.failedCallback(),
      })
    }
  }

  successCallback = (res) => {
    this.props.navigation.setParams({
      onChange: false,
    })
    const printing = res.store_printing
    this.config(printing)
    // util.setStatePromise(this, {
    //   printingBillPaperang: printing,
    //   usePrintingBillPaperang: printing.a,
    // })
    p.op.showToast('บันทึกข้อมูลเรียบร้อยแล้ว', 'success')
    this.inProcess = false
  }

  failedCallback = () => {
    p.op.showToast('เกิดข้อผิดพลาดกรุณาทำรายการใหม่ภายหลัง', 'warning')
    this.props.navigation.setParams({
      onChange: false,
    })
    this.inProcess = false
  }

  imgChooseSuccess = (key: string) => {
    const { printingBillPaperang } = this.state
    const newPrintingBillPaperang = printingBillPaperang
    if (key === HEADER) {
      // newPrintingBillPaperang.p.i = null เพื่อว่าตอนแก้ไขรูปภาพจะได้ไม่เจอรูปเก่า เพื่ออัพโหลดรูปใหม่
      newPrintingBillPaperang.p.i = null
      util.setStatePromise(this, {
        // headerGalleryChoose: false,
        headerImgActive: true,
      })
    } else if (key === FOOTER) {
      // newPrintingBillPaperang.p.g = null เพื่อว่าตอนแก้ไขรูปภาพจะได้ไม่เจอรูปเก่า เพื่ออัพโหลดรูปใหม่
      newPrintingBillPaperang.p.g = null
      util.setStatePromise(this, {
        // footerGalleryChoose: false,
        footerImgActive: true,
      })
    }
    util.setStatePromise(this, { printingBillPaperang: newPrintingBillPaperang })
    this.props.navigation.setParams({
      cancelBtn: () => this.cancelButton(),
      onChange: true,
      saveBtn: () => this.saveButton(),
    })
  }

  _getImg = async (key: string, url: string) => {
    if (key === HEADER) {
      if (this.thisImgManagerRef && this.thisImgManagerRef.current.loadImageUrls) {
        this.thisImgManagerRef.current.loadImageUrls({
          allUploaded: true,
          p: [url],
        })
      }
    } else if (key === FOOTER) {
      if (this.thisImgManagerFooterRef && this.thisImgManagerFooterRef.current.loadImageUrls) {
        this.thisImgManagerFooterRef.current.loadImageUrls({
          allUploaded: true,
          p: [url],
        })
      }
    }
  }
}
