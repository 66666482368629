// Ref: https://gist.github.com/micimize/bf64ecbb6a32c236534a3431e76b27bb
// https://stackoverflow.com/questions/40143098/why-does-this-slow-network-detected-log-appear-in-chrome
import fontAntDesign from 'react-native-vector-icons/Fonts/AntDesign.ttf'
import fontEntypo from 'react-native-vector-icons/Fonts/Entypo.ttf'
import fontEvilIcons from 'react-native-vector-icons/Fonts/EvilIcons.ttf'
import fontFeather from 'react-native-vector-icons/Fonts/Feather.ttf'
import fontFontAwesome from 'react-native-vector-icons/Fonts/FontAwesome.ttf'
import FontAwesome5_Brands from 'react-native-vector-icons/Fonts/FontAwesome5_Brands.ttf'
import FontAwesome5_Regular from 'react-native-vector-icons/Fonts/FontAwesome5_Regular.ttf'
import FontAwesome5_Solid from 'react-native-vector-icons/Fonts/FontAwesome5_Solid.ttf'
import fontFoundation from 'react-native-vector-icons/Fonts/Foundation.ttf'
import fontIonicons from 'react-native-vector-icons/Fonts/Ionicons.ttf'
import fontMaterialCommunityIcons from 'react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf'
import fontMaterialIcons from 'react-native-vector-icons/Fonts/MaterialIcons.ttf'
import fontOcticons from 'react-native-vector-icons/Fonts/Octicons.ttf'
import fontSimpleLineIcons from 'react-native-vector-icons/Fonts/SimpleLineIcons.ttf'
import fontZocial from 'react-native-vector-icons/Fonts/Zocial.ttf'

import fontPromptBlack from 'xui/fonts/Prompt-Black.ttf'
import fontPromptBlackItalic from 'xui/fonts/Prompt-BlackItalic.ttf'
import fontPromptBold from 'xui/fonts/Prompt-Bold.ttf'
import fontPromptBoldItalic from 'xui/fonts/Prompt-BoldItalic.ttf'
import fontPromptExtraBold from 'xui/fonts/Prompt-ExtraBold.ttf'
import fontPromptExtraBoldItalic from 'xui/fonts/Prompt-ExtraBoldItalic.ttf'
import fontPromptExtraLight from 'xui/fonts/Prompt-ExtraLight.ttf'
import fontPromptExtraLightItalic from 'xui/fonts/Prompt-ExtraLightItalic.ttf'
import fontPromptItalic from 'xui/fonts/Prompt-Italic.ttf'
import fontPromptLight from 'xui/fonts/Prompt-Light.ttf'
import fontPromptLightItalic from 'xui/fonts/Prompt-LightItalic.ttf'
import fontPromptMedium from 'xui/fonts/Prompt-Medium.ttf'
import fontPromptMediumItalic from 'xui/fonts/Prompt-MediumItalic.ttf'
import fontPromptRegular from 'xui/fonts/Prompt-Regular.ttf'
import fontPromptSemiBold from 'xui/fonts/Prompt-SemiBold.ttf'
import fontPromptSemiBoldItalic from 'xui/fonts/Prompt-SemiBoldItalic.ttf'
import fontPromptThin from 'xui/fonts/Prompt-Thin.ttf'
import fontPromptThinItalic from 'xui/fonts/Prompt-ThinItalic.ttf'

const fontStylesAntDesign = `@font-face { src: url(${fontAntDesign}); font-family: AntDesign; font-display: block; }`
const fontStylesFeather = `@font-face { src: url(${fontFeather}); font-family: Feather; font-display: block;}`
const fontStylesMaterialIcons = `@font-face { src: url(${fontMaterialIcons}); font-family: MaterialIcons; font-display: block; }`
const fontStylesIonicons = `@font-face { src: url(${fontIonicons}); font-family: Ionicons; font-display: block; }`
const fontStylesEntypo = `@font-face { src: url(${fontEntypo}); font-family: Entypo; font-display: block; }`
const fontStylesEvilIcons = `@font-face { src: url(${fontEvilIcons}); font-family: EvilIcons; font-display: block; }`
const fontStylesSimpleLineIcons = `@font-face { src: url(${fontSimpleLineIcons}); font-family: SimpleLineIcons; font-display: block; }`
const fontStylesFoundation = `@font-face { src: url(${fontFoundation}); font-family: Foundation; font-display: block; }`
const fontStylesMaterialCommunityIcons = `@font-face { src: url(${fontMaterialCommunityIcons}); font-family: MaterialCommunityIcons; font-display: block; }`
const fontStylesOcticons = `@font-face { src: url(${fontOcticons}); font-family: Octicons; font-display: block; }`
const fontStylesZocial = `@font-face { src: url(${fontZocial}); font-family: Zocial; font-display: block; }`
const fontStylesFontAwesome = `@font-face { src: url(${fontFontAwesome}); font-family: FontAwesome; font-display: block; }`
const fontStylesFontAwesome5B = `@font-face { src: url(${FontAwesome5_Brands}); font-family: FontAwesome5_Brands; font-display: block; }`
const fontStylesFontAwesome5R = `@font-face { src: url(${FontAwesome5_Regular}); font-family: FontAwesome5_Regular; font-display: block; }`
const fontStylesFontAwesome5S = `@font-face { src: url(${FontAwesome5_Solid}); font-family: FontAwesome5_Solid; font-display: block; }`

const fontStylesPromptBlack = `@font-face { src: url(${fontPromptBlack}); font-family: Prompt-Black; font-display: block; }`
const fontStylesPromptBlackItalic = `@font-face { src: url(${fontPromptBlackItalic}); font-family: Prompt-BlackItalic; font-display: block; }`
const fontStylesPromptBold = `@font-face { src: url(${fontPromptBold}); font-family: Prompt-Bold; font-display: block; }`
const fontStylesPromptBoldItalic = `@font-face { src: url(${fontPromptBoldItalic}); font-family: Prompt-BoldItalic; font-display: block; }`
const fontStylesPromptExtraBold = `@font-face { src: url(${fontPromptExtraBold}); font-family: Prompt-ExtraBold; font-display: block; }`
const fontStylesPromptExtraBoldItalic = `@font-face { src: url(${fontPromptExtraBoldItalic}); font-family: Prompt-ExtraBoldItalic; font-display: block; }`
const fontStylesPromptExtraLight = `@font-face { src: url(${fontPromptExtraLight}); font-family: Prompt-ExtraLight; font-display: block; }`
const fontStylesPromptExtraLightItalic = `@font-face { src: url(${fontPromptExtraLightItalic}); font-family: Prompt-ExtraLightItalic; font-display: block; }`
const fontStylesPromptItalic = `@font-face { src: url(${fontPromptItalic}); font-family: Prompt-Italic; font-display: block; }`
const fontStylesPromptLight = `@font-face { src: url(${fontPromptLight}); font-family: Prompt-Light; font-display: block; }`
const fontStylesPromptLightItalic = `@font-face { src: url(${fontPromptLightItalic}); font-family: Prompt-LightItalic; font-display: block; }`
const fontStylesPromptMedium = `@font-face { src: url(${fontPromptMedium}); font-family: Prompt-Medium; font-display: block; }`
const fontStylesPromptMediumItalic = `@font-face { src: url(${fontPromptMediumItalic}); font-family: Prompt-MediumItalic; font-display: block; }`
const fontStylesPromptRegular = `@font-face { src: url(${fontPromptRegular}); font-family: Prompt-Regular; font-display: block; }`
const fontStylesPromptSemiBold = `@font-face { src: url(${fontPromptSemiBold}); font-family: Prompt-SemiBold; font-display: block; }`
const fontStylesPromptSemiBoldItalic = `@font-face { src: url(${fontPromptSemiBoldItalic}); font-family: Prompt-SemiBoldItalic; font-display: block; }`
const fontStylesPromptThin = `@font-face { src: url(${fontPromptThin}); font-family: Prompt-Thin; font-display: block; }`
const fontStylesPromptThinItalic = `@font-face { src: url(${fontPromptThinItalic}); font-family: Prompt-ThinItalic; font-display: block; }`

const fonts = [
  fontStylesAntDesign,
  fontStylesMaterialIcons,
  fontStylesFontAwesome,
  fontStylesFontAwesome5B,
  fontStylesFontAwesome5R,
  fontStylesFontAwesome5S,
  fontStylesZocial,
  fontStylesOcticons,
  fontStylesMaterialCommunityIcons,
  fontStylesFoundation,
  fontStylesSimpleLineIcons,
  fontStylesEvilIcons,
  fontStylesEntypo,
  fontStylesIonicons,
  fontStylesFeather,

  fontStylesPromptBlack,
  fontStylesPromptBlackItalic,
  fontStylesPromptBold,
  fontStylesPromptBoldItalic,
  fontStylesPromptExtraBold,
  fontStylesPromptExtraBoldItalic,
  fontStylesPromptExtraLight,
  fontStylesPromptExtraLightItalic,
  fontStylesPromptItalic,
  fontStylesPromptLight,
  fontStylesPromptLightItalic,
  fontStylesPromptMedium,
  fontStylesPromptMediumItalic,
  fontStylesPromptRegular,
  fontStylesPromptSemiBold,
  fontStylesPromptSemiBoldItalic,
  fontStylesPromptThin,
  fontStylesPromptThinItalic,
]

// const preloadFontUrls = [`${fontAntDesign}`, `${fontFeather}`, `${fontMaterialIcons}`, `${fontIonicons}`, `${fontEntypo}`, `${fontEvilIcons}`,`${fontSimpleLineIcons}`, `${fontFoundation}`, `${fontMaterialCommunityIcons}`, `${fontOcticons}`, `${fontZocial}`, `${fontFontAwesome}`, `${FontAwesome5_Brands}`, `${FontAwesome5_Regular}`, `${FontAwesome5_Solid}`]
const preloadFontUrls = [
  `${fontAntDesign}`,
  `${fontMaterialIcons}`,
  `${fontIonicons}`,
  `${fontMaterialCommunityIcons}`,
  `${fontFontAwesome}`,

  `${fontPromptBold}`,
  `${fontPromptBoldItalic}`,
  `${fontPromptExtraBold}`,
  `${fontPromptExtraBoldItalic}`,
  `${fontPromptExtraLight}`,
  `${fontPromptExtraLightItalic}`,
  `${fontPromptItalic}`,
  `${fontPromptLight}`,
  `${fontPromptLightItalic}`,
  `${fontPromptMedium}`,
  `${fontPromptMediumItalic}`,
  `${fontPromptRegular}`,
  `${fontPromptSemiBold}`,
  `${fontPromptSemiBoldItalic}`,
  `${fontPromptThin}`,
  `${fontPromptThinItalic}`,
]

export function injectFont() {
  const style = document.createElement('style')
  fonts.forEach((font) => {
    // Create stylesheet
    style.type = 'text/css'
    style.appendChild(document.createTextNode(font))
    // Inject stylesheet
  })
  document.head.appendChild(style)

  // preload all the fonts (Do we need them all?)
  preloadFontUrls.forEach((fontUrl) => {
    const link = document.createElement('link')
    link.rel = 'preload'
    link.as = 'font'
    link.type = 'font/ttf'
    link.href = fontUrl
    link.crossOrigin = ''
    document.head.appendChild(link)
  })

  //
  // link = document.createElement('link');
  // link.rel = 'preload';
  // link.as = 'font';
  // link.type = 'font/ttf';
  // link.crossOrigin = '';
  // link.href = `${fontMaterialCommunityIcons}`;
  // document.head.appendChild(link);
  //
  // link = document.createElement('link');
  // link.rel = 'preload';
  // link.as = 'font';
  // link.type = 'font/ttf';
  // link.crossOrigin = '';
  // link.href = `${fontAntDesign}`;
  // document.head.appendChild(link);
  //
  // link = document.createElement('link');
  // link.rel = 'preload';
  // link.as = 'font';
  // link.type = 'font/ttf';
  // link.crossOrigin = '';
  // link.href = `${fontFontAwesome}`;
  // document.head.appendChild(link);
  //
  // link = document.createElement('link');
  // link.rel = 'preload';
  // link.as = 'font';
  // link.type = 'font/ttf';
  // link.crossOrigin = '';
  // link.href = `${fontIonicons}`;
  // document.head.appendChild(link);
}
