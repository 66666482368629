import React, { memo } from 'react'
import { StyleProp, ViewStyle, ViewProps } from 'react-native'
import { IBoxProps } from 'native-base'
import Box from 'xui/components/Box'

// interface IXSellyContainerProps {
//   style?: StyleProp<ViewStyle>
// }
// class XContainer extends React.Component<IXSellyContainerProps> {
//   // defaultProps = {
//   //   style: {},
//   // }
//   renderApp = () => {
//     return <Box {...this.props} style={[{ flex: 1 }, StyleSheet.flatten(this.props.style)]} />
//   }

//   renderWeb = () => {
//     return <View {...this.props} style={[S.HEIGHT_FULL, S.WIDTH_FULL, { overflow: 'hidden' }, StyleSheet.flatten(this.props.style)]} />
//   }

//   render() {
//     return p.op.isWeb() ? this.renderWeb() : this.renderApp()
//   }
// }

type IXSellyContainerProps = Omit<IBoxProps<ViewProps>, 'style'> & {
  style?: StyleProp<ViewStyle>
}

const XContainer = React.forwardRef((props: IXSellyContainerProps, ref?) => (
  <Box
    // @ts-ignore
    ref={ref}
    flex={1}
    w='full'
    // bg='red.500'
    _light={{
      bg: 'white',
    }}
    _dark={{
      bg: 'dark.50',
    }}
    style={{ flex: 1 }}
    //  bg={useColorModeValue('white', 'dark.50')}
    {...props}
  />
))

XContainer.displayName = 'XContainer'

export default memo(XContainer)
