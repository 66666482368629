import React from 'react'
import { ImageStyle, ImageSourcePropType, StyleSheet } from 'react-native'
import XImage from './XImage'

interface ICommonLogoRequiredProps {
  source: ImageSourcePropType
}

export interface ICommonLogoOptionalProps {
  width?: number
  height?: number
  style?: ImageStyle
}

type CommonLogoProps = ICommonLogoRequiredProps & ICommonLogoOptionalProps

const CommonLogo = (props: CommonLogoProps) => {
  // const { source, width = 40, height = 40, style = {} } = props
  const { source, width = 40, height = 40, style = {}, ...restProps } = props
  return (
    <XImage
      // width={width}
      // height={height}
      source={source}
      w={`${width}px`}
      h={`${height}px`}
      {...restProps}
      style={StyleSheet.flatten(style)}
      // style={{
      //   // width,
      //   // height,
      //   ...style,
      // }}
    />
  )
}

export default CommonLogo
