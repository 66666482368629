import React from 'react'
import _ from 'lodash'
// import XTabNavIcon from '../../components/XTabNavIcon'
// import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
// import SRowItem from '../../components/renderRowLeftAndRight'
import BaseTextEditorView from 'x/modules/textEditor/BaseTextEditorView'
import { COLORS } from 'x/config/styles'
import XCard from 'xui/components/XCard'
// import ReactQuill from 'react-quill'
// import 'react-quill/dist/quill.snow.css'
import XTextEditor from 'xui/components/XTextEditor'

// const _renderAgreement = (onPressOpenOverlay: () => void) => <HelpButton onPress={() => onPressOpenOverlay()} />

// const quillConfig = {
//   theme: 'snow', // or 'bubble' or your custom theme
//   modules: {
//     toolbar: [
//       ['bold', 'italic', 'underline', 'strike'],
//       ['blockquote', 'code-block'],
//       [{ list: 'ordered' }, { list: 'bullet' }],
//       [{ indent: '-1' }, { indent: '+1' }],
//       ['link'],
//       ['clean'],
//     ],
//   },
// }

export default abstract class BaseUITextEditorView extends BaseTextEditorView {
  _renderHeader = () => {
    const title = 'ตั้งค่าตัวอักษร'
    return (
      <XCustomHeader
        title={title}
        // @ts-ignore
        headerLeftProps={{ label: 'บันทึก', onPressItem: () => this._saveBtnAction() }}
        headerRightProps={{ label: 'ยกเลิก', onPressItem: () => this._cancelBtnAction() }}
        // renderHeaderRight={this._renderHeaderRight}
      />
    )
  }

  _renderTextEditor = () => {
    const { textValue } = this.state
    if (_.isNil(textValue)) {
      return null
    }
    return <XTextEditor htmlValue={textValue} onChange={(text) => this.setTextValue(text)} id='xxx' />
  }

  render() {
    return (
      <XContainer>
        {this._renderHeader()}
        <XContent style={{ backgroundColor: COLORS.BG_LIGHT_GREY }}>
          <XCard m='2'>{this._renderTextEditor()}</XCard>
        </XContent>
      </XContainer>
    )
  }
}

// const styles = StyleSheet.create({
//   title: {
//     fontWeight: 'bold',
//     alignSelf: 'center',
//     paddingVertical: 10,
//   },
//   root: {
//     flex: 1,
//     marginTop: StatusBar.currentHeight || 0,
//     backgroundColor: '#eaeaea',
//   },
//   editor: {
//     flex: 1,
//     padding: 0,
//     borderColor: 'gray',
//     borderWidth: 1,
//     marginHorizontal: 30,
//     marginVertical: 5,
//     backgroundColor: 'white',
//   },
// })
