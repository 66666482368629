import { connect } from 'react-redux'
import { getState, getDispatch } from 'x/modules/productlist/ProductListViewConnect'
import { getMkpChannels } from 'x/redux/selectors'
import ProductListViewMkpToXSellyMapping from './ProductListViewMkpToXSellyMapping'

export default connect(
  (state) => ({
    ...getState(state),
    mkpChannels: getMkpChannels(state),
  }),
  (dispatch) => getDispatch(dispatch)
)(ProductListViewMkpToXSellyMapping)
