import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ResellerChangeGroupView from './ResellerChangeGroupView'

import * as StoreActions from 'x/modules/store/StoreState'
import * as ResellerActions from 'x/modules/reseller/ResellerState'

import {
  getSelectedStore,
  getSelectedUserGroups,
} from 'x/redux/selectors'

export default connect(
  state => ({
    selectedStore: getSelectedStore(state),
    selectedUserGroups: getSelectedUserGroups(state),
  }),
  dispatch => {
    return {
      // fetchMyStore: bindActionCreators(StoreActions.fetchMyStore, dispatch),
      // clearAllUserList: bindActionCreators(ResellerActions.clearAllUserList, dispatch),
      changeUserGroup: bindActionCreators(ResellerActions.changeUserGroupInJoinedUsers, dispatch),
    }
  }
)(ResellerChangeGroupView)
