import React from 'react'
import BaseUIReportListView from 'xui/modules/importExcel/BaseUIImportExcelListView'
// import { View } from 'react-native'

export default class importExcelListView extends BaseUIReportListView {
  // openFileFromUrl = async (url: string, fileName: string, type: string) => {
  //   await downloadFileFromUrl({ url, fileName })
  // }
  renderCustomImgCOD = () => <img src={require('../../xui/x/images/cod.png')} style={{ flex: 0, width: 26, height: 18 }} />

  renderCustomImgShipjung = () => <img src={require('../../xui/x/images/shipjung_logo.png')} style={{ flex: 0, width: 20, height: 20 }} />
}
