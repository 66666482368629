import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as StoreActions from 'x/modules/store/StoreState'
import { getSelectedStore, getEditingStore } from 'x/redux/selectors'

// Extended
import BaseUIStoreSettingGeneral from 'xui/modules/store/BaseUIStoreSettingGeneral'

class StoreSettingGeneral extends BaseUIStoreSettingGeneral {
  static displayName = 'StoreSettingGeneral'

  static navigationOptions = {
    // To hide the ActionBar/NavigationBar For App
    header: null,
  }
}

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
    editingStore: getEditingStore(state),
    // img_uris: state.getIn(['imgBlockTasks', 'img_uris']),
    // thumbnail_uris: state.getIn(['imgBlockTasks', 'thumbnail_uris']),
    // img_status: state.getIn(['imgBlockTasks', 'taskStatus']),
  }),
  (dispatch) => ({
    // storeActions: bindActionCreators(StoreState, dispatch),
    submitEditing: bindActionCreators(StoreActions.submitEditingStore, dispatch),
    onChangeEditingStore: bindActionCreators(StoreActions.onChangeEditingStore, dispatch),
    resetEditingStore: bindActionCreators(StoreActions.resetEditingStore, dispatch),
  })
)(StoreSettingGeneral)
