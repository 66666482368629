import React from 'react'
import { Keyboard, KeyboardAvoidingView, Platform, TextInput, TouchableOpacity } from 'react-native'

import _ from 'lodash'
import HStack from 'xui/components/HStack'
import VStack from 'xui/components/VStack'

import {
  ActionApiParams,
  IAnyObject,
  IBarcodeScannerOutput,
  IFetchOrdersRequestBody,
  IXRadioListRenderItemInfo,
  IXScreenProps,
} from 'x/index'
import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'
import * as NavActions from 'x/utils/navigation'
import { flowDone } from 'x/utils/global'
import actions from 'x/config/actions'

import XContainer from 'xui/components/XContainer'
import XCustomHeader from 'xui/components/XCustomHeader'
import XText from 'xui/components/XText'
import XCard from 'xui/components/XCard'
import XRadioList from 'xui/components/XRadioList'
import XButton from 'xui/components/XButton'
import XInput from 'xui/components/XInput'
import BarcodeScannerModalButton from 'xui/components/BarcodeScannerModalButton'
import XContent from 'xui/components/XContent'
import CONS from 'x/config/constants'
import dayjs from 'dayjs'
import XDataProtectionPolicyShopee from 'xui/components/XDataProtectionPolicyShopee'
// import ThemeSizeContext from '../../context/ThemeSizeContext'
// import SearchOrderComponentView from './SearchOrderComponentView'

export interface IProps
  extends IXScreenProps<{
    store_id: number
  }> {
  // navigation: any
  selectedStore: any
  fetchOrderFromBarcode: (action: ActionApiParams<{ store_id: number; order_id: number }>) => void
  fetchOrdersForSearch: (params: { mode: string; payload: any }) => void
  fetchOrderDetail: (params: ActionApiParams) => void
}

const SEARCH_OPTION_BY_ORDER_NUMBER = 'by_order_no'
const SEARCH_OPTION_BY_ERP_DOC_NUMBER = 'erp_doc_code'
const SEARCH_OPTION_BY_TRACKING_NUMBER = 'by_tracking_no'
const SEARCH_OPTION_BY_CONDITION = 'by_condition'
type ISearchOrderValue = 'by_order_no' | 'by_tracking_no' | 'by_condition' | 'erp_doc_code'
type ISearchOrderOption = {
  label: string
  subLabel?: string
  value: ISearchOrderValue
}
const SEARCH_ORDERS_OPTIONS: ISearchOrderOption[] = [
  {
    label: 'เลขออเดอร์',
    subLabel: '(ของ XSelly หรือ ช่องทางขาย)',
    // value: 0,
    value: SEARCH_OPTION_BY_ORDER_NUMBER,
  },
  {
    label: 'เลขที่เอกสารจากระบบบัญชี',
    subLabel: '(ของ PEAK)',
    // value: 0,
    value: SEARCH_OPTION_BY_ERP_DOC_NUMBER,
  },
  {
    label: 'เลขติดตามพัสดุ',
    // value: 1,
    value: SEARCH_OPTION_BY_TRACKING_NUMBER,
  },
  {
    label: 'ตามเงื่อนไข',
    // value: 2,
    value: SEARCH_OPTION_BY_CONDITION,
  },
]

interface IState {
  selectedSearchOrdersOptionIndex: number

  isSearching?: boolean

  orderNumberText?: string
  erpDocNumberText?: string
  trackingNumberText?: string
  receiverNameText?: string
  receiverTelephoneText?: string
  senderNameText?: string
}

export default class SearchOrderView extends React.Component<IProps, IState> {
  static displayName = 'SearchOrderView'

  // static contextType = ThemeSizeContext

  // context!: React.ContextType<typeof ThemeSizeContext>

  // render() {
  //   const { navigation, selectedStore, fetchOrderFromBarcode } = this.props
  //   console.log(`fetchOrderFromBarcode `, fetchOrderFromBarcode)
  //   return <SearchOrderComponentView navigation={navigation} selectedStore={selectedStore} fetchOrderFromBarcode={fetchOrderFromBarcode} />
  // }

  // getFixedWebLayoutTextLimitWidth = () => {
  //   return this.context.contentOneOfThreeColumnWidth - 120
  // }

  inputOrderNumberRef: React.RefObject<TextInput>

  inputTrackingNumberRef: React.RefObject<TextInput>

  inputReceiverNameRef: React.RefObject<TextInput>

  constructor(props) {
    super(props)
    this.state = {
      selectedSearchOrdersOptionIndex: 0,

      trackingNumberText: '',
      receiverNameText: '',
      receiverTelephoneText: '',
      senderNameText: '',
    }

    this.inputOrderNumberRef = React.createRef()
    this.inputTrackingNumberRef = React.createRef()
    this.inputReceiverNameRef = React.createRef()
  }

  getStoreId = () => {
    const { navigation, selectedStore } = this.props
    return util.getNavParam(this.props, 'store_id', selectedStore.get('id'))
  }

  goBack = () => {
    util.navGoBack(this.props)
  }

  autoFocusAfterSelectOption = async (opt: ISearchOrderOption) => {
    try {
      Keyboard.dismiss()
      await util.delay(200)
      switch (opt.value) {
        case SEARCH_OPTION_BY_ORDER_NUMBER:
          this.inputOrderNumberRef.current.focus()
          break

        case SEARCH_OPTION_BY_TRACKING_NUMBER:
          this.inputTrackingNumberRef.current.focus()
          break

        case SEARCH_OPTION_BY_CONDITION:
          this.inputReceiverNameRef.current.focus()
          break

        default:
        // no-op
      }
    } catch (error) {
      // no-op
    }
  }

  onSearchOrdersOptionsRadioChange = async (opt: ISearchOrderOption, idx: number) => {
    await util.setStatePromise(this, { selectedSearchOrdersOptionIndex: idx })
    await this.autoFocusAfterSelectOption(opt)
  }

  _onOrderNumberTextScanned = async (barcodeOutput: IBarcodeScannerOutput) => {
    const { data } = barcodeOutput
    if (data) {
      this.onChangeOrderNumberText(data)
    }
  }

  _onErpDocNumberTextScanned = async (barcodeOutput: IBarcodeScannerOutput) => {
    const { data } = barcodeOutput
    if (data) {
      this.onChangeErpDocNumberText(data)
    }
  }

  _onTrackingNumberTextScanned = async (barcodeOutput: IBarcodeScannerOutput) => {
    const { data } = barcodeOutput
    if (data) {
      this.onChangeTrackingNumberText(data)
    }
  }

  renderSearchOrdersOptionItem = (itemRenderInfo: IXRadioListRenderItemInfo<ISearchOrderOption>) => {
    const { index, checked, option, onRadioPressHandler, renderRadioIcon, renderRadioLabel } = itemRenderInfo

    const isVerticalLayout = option.value === SEARCH_OPTION_BY_CONDITION

    const renderChecker = () => (
      <TouchableOpacity onPress={onRadioPressHandler}>
        <HStack
          w='full'
          flex={isVerticalLayout ? undefined : 1}
          minW='24'
          // _web={{ maxW: '32' }}
          space='1'
          alignItems='center'
          flexWrap='wrap'>
          {renderRadioIcon()}
          {/* {renderRadioLabel()} */}
          <VStack flex={1} justifyContent='center' flexWrap='wrap'>
            <XText w='full' variant={checked ? 'active' : 'inactive'} bold>
              {option.label}
            </XText>
            {option.subLabel ? (
              <XText w='full' variant='inactive' fontSize='xs'>
                {option.subLabel}
              </XText>
            ) : null}
          </VStack>
        </HStack>
      </TouchableOpacity>
    )

    if (isVerticalLayout) {
      return (
        <VStack key={index.toString()} w='full' pt='2' pb='4' flexWrap='wrap'>
          {renderChecker()}
          {this.renderSearchOptionForm(option, checked)}
        </VStack>
      )
    }

    return (
      <HStack key={index.toString()} w='full' pt='2' pb='4' alignItems='center' flexWrap='wrap'>
        <VStack flex={1}>{renderChecker()}</VStack>
        {this.renderSearchOptionForm(option, checked)}
      </HStack>
    )
  }

  renderSearchOptionForm = (opt: ISearchOrderOption, checked: boolean) => {
    if (opt.value === SEARCH_OPTION_BY_ORDER_NUMBER) {
      return this.renderSearchByOrderNumberForm(checked)
    }

    if (opt.value === SEARCH_OPTION_BY_ERP_DOC_NUMBER) {
      return this.renderSearchByErpDocNumberForm(checked)
    }

    if (opt.value === SEARCH_OPTION_BY_TRACKING_NUMBER) {
      return this.renderSearchByTrackingNumberForm(checked)
    }

    if (opt.value === SEARCH_OPTION_BY_CONDITION) {
      return this.renderSearchByConditionForm(checked)
    }

    return null
  }

  onChangeOrderNumberText = (orderNumberText: string) => {
    this.setState({ orderNumberText })
  }

  onChangeErpDocNumberText = (erpDocNumberText: string) => {
    this.setState({ erpDocNumberText })
  }

  onChangeTrackingNumberText = (trackingNumberText: string) => {
    this.setState({ trackingNumberText })
  }

  onChangeReceiverNameText = (receiverNameText: string) => {
    this.setState({ receiverNameText })
  }

  onChangeReceiverTelephoneText = (newReceiverTelephoneText: string) => {
    this.setState({ receiverTelephoneText: newReceiverTelephoneText.replace(/[^0-9]/g, '') })
  }

  onChangeSenderNameText = (senderNameText: string) => {
    this.setState({ senderNameText })
  }

  renderSearchByOrderNumberForm = (checked: boolean) => {
    const { orderNumberText } = this.state

    return (
      <HStack w='180px' alignItems='center' space='1' justifyContent='flex-end'>
        <XInput
          ref={this.inputOrderNumberRef}
          isDisabled={!checked}
          disabled={!checked}
          autoFocus
          // w='40'
          h='11'
          flex={1}
          placeholder='1234567'
          value={orderNumberText}
          onChangeText={this.onChangeOrderNumberText}
          onSubmitEditing={this.onSubmitSearch}
          // InputRightElement={<BarcodeScannerModalButton disabled={!checked} onBarcodeScanned={this._onOrderNumberTextScanned} />}
        />
        <BarcodeScannerModalButton headerTitle='สแกนเลขออเดอร์' disabled={!checked} onBarcodeScanned={this._onOrderNumberTextScanned} />
      </HStack>
    )
  }

  renderSearchByErpDocNumberForm = (checked: boolean) => {
    const { erpDocNumberText } = this.state
    // IV-20240600024
    return (
      <HStack w='180px' alignItems='center' space='1' justifyContent='flex-end'>
        <XInput
          ref={this.inputOrderNumberRef}
          isDisabled={!checked}
          disabled={!checked}
          autoFocus
          // w='40'
          h='11'
          flex={1}
          placeholder='IV-00000000000'
          value={erpDocNumberText}
          onChangeText={this.onChangeErpDocNumberText}
          onSubmitEditing={this.onSubmitSearch}
          // InputRightElement={<BarcodeScannerModalButton disabled={!checked} onBarcodeScanned={this._onOrderNumberTextScanned} />}
        />
        <BarcodeScannerModalButton headerTitle='สแกนเลขออเดอร์' disabled={!checked} onBarcodeScanned={this._onErpDocNumberTextScanned} />
      </HStack>
    )
  }

  renderSearchByTrackingNumberForm = (checked: boolean) => {
    const { trackingNumberText } = this.state

    return (
      <HStack w='180px' alignItems='center' space='1' justifyContent='flex-end'>
        <XInput
          ref={this.inputTrackingNumberRef}
          isDisabled={!checked}
          disabled={!checked}
          autoFocus
          // w='40'
          flex={1}
          h='11'
          placeholder='RG453678925TH'
          value={trackingNumberText}
          onChangeText={this.onChangeTrackingNumberText}
          onSubmitEditing={this.onSubmitSearch}
          // InputRightElement={<BarcodeScannerModalButton disabled={!checked} onBarcodeScanned={this._onTrackingNumberTextScanned} />}
        />
        <BarcodeScannerModalButton headerTitle='สแกนเลขพัสดุ' disabled={!checked} onBarcodeScanned={this._onTrackingNumberTextScanned} />
      </HStack>
    )
  }

  renderSearchByConditionForm = (checked: boolean) => {
    const { receiverNameText, receiverTelephoneText, senderNameText } = this.state

    return (
      <VStack w='full' mt='2' space='2' pl='7'>
        <HStack w='full' space='1' alignItems='center' justifyContent='flex-end'>
          <XText flex={1} variant='inactive'>
            ชื่อผู้รับ
          </XText>
          <XInput
            ref={this.inputReceiverNameRef}
            isDisabled={!checked}
            disabled={!checked}
            w='180px'
            h='11'
            placeholder='สมหมาย'
            value={receiverNameText}
            onChangeText={this.onChangeReceiverNameText}
            onSubmitEditing={this.onSubmitSearch}
          />
        </HStack>
        <HStack w='full' space='1' alignItems='center' justifyContent='flex-end'>
          <XText flex={1} variant='inactive'>
            เบอร์โทรผู้รับ
          </XText>
          <XInput
            isDisabled={!checked}
            disabled={!checked}
            w='180px'
            h='11'
            placeholder='0861234567'
            value={receiverTelephoneText}
            maxLength={15}
            keyboardType='name-phone-pad'
            onChangeText={this.onChangeReceiverTelephoneText}
            onSubmitEditing={this.onSubmitSearch}
          />
        </HStack>
        <HStack w='full' space='1' alignItems='center' justifyContent='flex-end'>
          <XText flex={1} variant='inactive'>
            ชื่อผู้ส่ง
          </XText>

          <XInput
            isDisabled={!checked}
            disabled={!checked}
            w='180px'
            h='11'
            placeholder='สมปอง'
            value={senderNameText}
            onChangeText={this.onChangeSenderNameText}
            onSubmitEditing={this.onSubmitSearch}
          />
        </HStack>
      </VStack>
    )
  }

  doSearchByOrderNumber = async () => {
    const { fetchOrderDetail, navigation } = this.props
    const { orderNumberText } = this.state

    const searchOrderId = orderNumberText.trim()
    const storeId = this.getStoreId()

    const res: IAnyObject = await new Promise((resolve) => {
      fetchOrderDetail({
        // body: { store_id: storeId, order_id: orderId }, แบบเดิม
        body: {
          store_id: storeId,
          search_order_id: searchOrderId,
        },
        // successCallback: successFetchHandler,
        // failedCallback: failedFetchHandler,
        successCallback: resolve,
        failedCallback: resolve,
      })
    })

    if (res && !_.isEmpty(res.order)) {
      // แสดงว่ามีออเดอร์
      // FIXME :: สั่ง Nav ธรรมดา โดยไม่ delay แล้ว navigation ไม่ไป
      // this._closeSearchModal()
      // orderListStateActions.navToOrderDetail(orderId, storeId, true)
      // orderListStateActions.navToOrderDetail(orderId, storeId, true)
      // orderListStateActions.navToOrderDetail(orderId, storeId, true)
      // setTimeout(() => { orderListStateActions.navToOrderDetail(orderId, storeId, true) }, 1000)
      // setTimeout(() => { orderListStateActions.navToOrderDetail(orderId, storeId, true) }, 2000)
      // setTimeout(() => {
      flowDone(actions.ORDER_FETCH)
      // orderListStateActions.navToOrderDetail(orderId, storeId, true)
      // this.props.navigation.dispatch(NavActions.navToOrderDetail(orderId, storeId, true))
      navigation.dispatch(NavActions.navToOrderDetail({ order_id: res.order.id, store_id: storeId, isNoInitFetch: true }))
      // this._closeSearchModal()
      // navToOrderDetail(orderId, storeId, true)
      // }, 3000)
    }
  }

  doSearchByErpDocNumber = async () => {
    const { navigation } = this.props
    const { erpDocNumberText } = this.state
    // const store_id = this.getStoreId()
    // const offset = 0
    // const limit = 20
    // const sortBy = 'id'
    // const sortType = 'desc'
    // const POST = {
    //   store_id,
    //   offset,
    //   limit,
    //   sortBy,
    //   sortType,
    //   tracking_no: trackingNumberText.trim(),
    //   order_states: [101, 102, 103, 109, 181, 182, 183, 184],
    //   payment_states: [111, 112, 113, 114, 115, 119],
    //   shipping_states: [121, 122, 129],
    // }
    // fetchOrdersForSearch({ mode: CONS.ORDERS_FETCH_MODE_INIT, payload: POST })

    const overrideRequestBody: Partial<IFetchOrdersRequestBody> = {
      erp_doc_code: erpDocNumberText.trim(),
      // order_states: [101, 102, 103, 109, 181, 182, 183, 184],
      // payment_states: [111, 112, 113, 114, 115, 119],
      // shipping_states: [121, 122, 129],
    }

    navigation.dispatch(
      NavActions.navToSearchOrderListView({
        // order_id: od.id,
        store_id: this.getStoreId(),
        // availableTabKeys: ['search'],
        overrideRequestBody,

        // onPressOrderListItem: this._mockOnPressOrderListItem,
        // onPressSubmitCheckedOrders: this._mockOnPressSubmitCheckedOrders,
      })
    )
  }

  doSearchByTrackingNumber = () => {
    const { fetchOrdersForSearch, navigation } = this.props
    const { trackingNumberText } = this.state
    // const store_id = this.getStoreId()
    // const offset = 0
    // const limit = 20
    // const sortBy = 'id'
    // const sortType = 'desc'
    // const POST = {
    //   store_id,
    //   offset,
    //   limit,
    //   sortBy,
    //   sortType,
    //   tracking_no: trackingNumberText.trim(),
    //   order_states: [101, 102, 103, 109, 181, 182, 183, 184],
    //   payment_states: [111, 112, 113, 114, 115, 119],
    //   shipping_states: [121, 122, 129],
    // }
    // fetchOrdersForSearch({ mode: CONS.ORDERS_FETCH_MODE_INIT, payload: POST })

    const overrideRequestBody: Partial<IFetchOrdersRequestBody> = {
      tracking_no: trackingNumberText.trim(),
      // order_states: [101, 102, 103, 109, 181, 182, 183, 184],
      // payment_states: [111, 112, 113, 114, 115, 119],
      // shipping_states: [121, 122, 129],
    }

    navigation.dispatch(
      NavActions.navToSearchOrderListView({
        // order_id: od.id,
        store_id: this.getStoreId(),
        // availableTabKeys: ['search'],
        overrideRequestBody,

        // onPressOrderListItem: this._mockOnPressOrderListItem,
        // onPressSubmitCheckedOrders: this._mockOnPressSubmitCheckedOrders,
      })
    )
  }

  isSubmitButtonDisabled = () => {
    const {
      isSearching,
      selectedSearchOrdersOptionIndex,
      orderNumberText,
      trackingNumberText,
      receiverNameText,
      receiverTelephoneText,
      senderNameText,
      erpDocNumberText,
    } = this.state
    if (isSearching) {
      return true
    }
    const opt = SEARCH_ORDERS_OPTIONS[selectedSearchOrdersOptionIndex]
    if (opt.value === SEARCH_OPTION_BY_ORDER_NUMBER) {
      return !_.isString(orderNumberText) || orderNumberText.length === 0
    }

    if (opt.value === SEARCH_OPTION_BY_ERP_DOC_NUMBER) {
      return !_.isString(erpDocNumberText) || erpDocNumberText.length === 0
    }

    if (opt.value === SEARCH_OPTION_BY_TRACKING_NUMBER) {
      return !_.isString(trackingNumberText) || trackingNumberText.length === 0
    }

    if (opt.value === SEARCH_OPTION_BY_CONDITION) {
      const isReceiverNameValid = _.isString(receiverNameText) && receiverNameText.length > 0
      const isReceiverTelephoneValid =
        _.isString(receiverTelephoneText) && receiverTelephoneText.length > 0 && receiverTelephoneText.length <= 15
      const isSenderNameValid = _.isString(senderNameText) && senderNameText.length > 0
      return !isReceiverNameValid && !isReceiverTelephoneValid && !isSenderNameValid
    }

    return false
  }

  onSubmitSearch = async () => {
    const { selectedSearchOrdersOptionIndex, isSearching } = this.state
    if (isSearching) {
      return
    }
    await util.setStatePromise(this, { isSearching: true })
    const opt = SEARCH_ORDERS_OPTIONS[selectedSearchOrdersOptionIndex]
    if (opt.value === SEARCH_OPTION_BY_ORDER_NUMBER) {
      await this.doSearchByOrderNumber()
    }

    if (opt.value === SEARCH_OPTION_BY_ERP_DOC_NUMBER) {
      await this.doSearchByErpDocNumber()
    }

    if (opt.value === SEARCH_OPTION_BY_TRACKING_NUMBER) {
      this.doSearchByTrackingNumber()
    }

    if (opt.value === SEARCH_OPTION_BY_CONDITION) {
      this.doSearchByCondition()
    }
    await util.setStatePromise(this, { isSearching: false })
  }

  renderSubmitSearchButton = () => {
    const { isSearching } = this.state
    const isBtnDisabled = this.isSubmitButtonDisabled()
    return (
      <VStack w='full' p='1' minH='9'>
        <XButton isDisabled={isBtnDisabled} isLoading={isSearching} disabled={isBtnDisabled} onPress={this.onSubmitSearch} minH='11'>
          ค้นหาออเดอร์
        </XButton>
      </VStack>
    )
  }

  doSearchByCondition = () => {
    const { navigation } = this.props
    const { senderNameText, receiverTelephoneText, receiverNameText } = this.state
    const overrideRequestBody: Partial<IFetchOrdersRequestBody> = {}
    if (senderNameText && senderNameText.length > 0) {
      overrideRequestBody.sender_name = senderNameText
    }
    if (receiverTelephoneText && receiverTelephoneText.length > 0) {
      overrideRequestBody.receiver_tel = receiverTelephoneText
    }
    if (receiverNameText && receiverNameText.length > 0) {
      overrideRequestBody.receiver_name = receiverNameText
    }

    navigation.dispatch(
      NavActions.navToSearchOrderListView({
        // order_id: od.id,
        store_id: this.getStoreId(),
        // availableTabKeys: ['search'],
        overrideRequestBody,

        // เนื่องจาก default ใหม่ของเรามัน 2 เดือน การ search เราเลยต้องปรับใหม่เป็น 5 ปีเพื่อให้มันค้นได้ทั่วถึงมากขึ้น
        ordersDefaultFilterSetting: {
          createOrderDateRangeOptionKey: CONS.DATERANGE_SELECTOR.OPTION.Custom,
          createOrderFrom: dayjs().subtract(5, 'years').startOf('day'),
          createOrderTo: dayjs().endOf('day'),
        },
        // onPressOrderListItem: this._mockOnPressOrderListItem,
        // onPressSubmitCheckedOrders: this._mockOnPressSubmitCheckedOrders,
      })
    )
  }

  renderMain = () => {
    const { selectedSearchOrdersOptionIndex } = this.state
    return (
      <XContainer>
        <XCustomHeader title='ค้นหาออเดอร์' headerLeftProps={p.op.isWeb() ? null : { backIcon: true, onPressItem: this.goBack }} />
        <XContent>
          <VStack w='full' p='1'>
            <XCard w='full'>
              <XCard.Body>
                <VStack w='full'>
                  <XText variant='inactive'>ค้นหาโดย...</XText>

                  <XRadioList
                    selectedIndex={selectedSearchOrdersOptionIndex}
                    options={SEARCH_ORDERS_OPTIONS}
                    renderItem={this.renderSearchOrdersOptionItem}
                    onRadioChange={this.onSearchOrdersOptionsRadioChange}
                  />
                  <XDataProtectionPolicyShopee />
                </VStack>
              </XCard.Body>
            </XCard>
          </VStack>
        </XContent>
        {this.renderSubmitSearchButton()}
      </XContainer>
    )
  }

  render() {
    if (p.op.isAndroid()) {
      return (
        <KeyboardAvoidingView
          keyboardVerticalOffset={p.op.isAndroid() ? 30 : undefined}
          style={{ flex: 1 }}
          behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
          {this.renderMain()}
        </KeyboardAvoidingView>
      )
    }

    return this.renderMain()
  }
}
