import React from 'react'
import { Modal } from 'native-base'
import _ from 'lodash'
// import Modal from 'react-modal'

import { STYLES } from 'x/config/styles'
import { IXOverlayProps } from 'x/index'

import XIconButton from 'xui/components/XIconButton'
import VStack from 'xui/components/VStack'
import ThemeSizeContext from '../context/ThemeSizeContext'

// interface IXOverlay {
//   visible: boolean
//   contentStyle?: ViewStyle
//   onRequestClose?: () => void
//   webDisabledRequestClose?: boolean
//   // numColumns?: 1 | 2 | 3 // default = 3
//   // style?: ViewStyle
//   // zIndex?: number
// }

const customModalStyles = {
  overlay: {
    // backgroundColor: 'rgba(0,0,0,0.7)',
    backgroundColor: 'transparent',
  },
  content: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    // backgroundColor: 'rgba(0,0,0,0.7)',
    backgroundColor: 'transparent',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    padding: 0,
    overflow: 'hidden',
    borderWidth: 0,
    // backgroundColor: 'red',
    // marginRight           : '-50%',
    // transform             : 'translate(-50%, -50%)'
  },
}

export default class XOverlay extends React.Component<IXOverlayProps> {
  static contextType = ThemeSizeContext

  context!: React.ContextType<typeof ThemeSizeContext>

  _onRequestClose = () => {
    const { onRequestClose, webDisabledRequestClose = false } = this.props
    if (_.isFunction(onRequestClose) && !webDisabledRequestClose) {
      onRequestClose()
    }
  }

  render() {
    const { children, onRequestClose, webDisabledRequestClose = false, style = {}, contentStyle, visible = false } = this.props

    const {
      contentHeight,
      contentHeightStyle,
      contentWidthStyle,
      contentOneOfThreeColumnWidthStyle,
      contentTwoOfThreeColumnWidthStyle,
      contentWidth,
      browserWidth,
    } = this.context

    // if (!visible) {
    //   return null
    // }
    // const numColumns = 1
    const contentContainerWidthStyle = contentOneOfThreeColumnWidthStyle
    // let contentContainerWidthStyle = contentWidthStyle
    // if (numColumns === 2) {
    //   contentContainerWidthStyle = contentTwoOfThreeColumnWidthStyle
    // } else if (numColumns === 1) {
    //   contentContainerWidthStyle = contentOneOfThreeColumnWidthStyle
    // }

    const isShowCloseButton = browserWidth > contentWidth + STYLES.FONT_ICON_NORMAL * 3

    return (
      <Modal
        // shouldCloseOnEsc={false}
        isOpen={visible}
        // onAfterOpen={this.afterOpenModal}
        onClose={this._onRequestClose}
        // onRequestClose={this._onRequestClose}
        style={[{ alignItems: 'center', justifyContent: 'center' }, style]}
        // style={{
        //   overlay: customModalStyles.overlay,
        //   content: { ...customModalStyles.content, ...style },
        // }}
        // @ts-ignore
        // style={customModalStyles}
      >
        {/* <TouchableOpacity
          onPress={this._onRequestClose}
          disabled={!_.isFunction(onRequestClose) || webDisabledRequestClose}
          // @ts-ignore
          style={[s.btnRequestCloseArea, s.tranparentBg]}
        /> */}
        <VStack style={[contentContainerWidthStyle, { maxHeight: contentHeight }, contentStyle]}>{children}</VStack>
        {isShowCloseButton ? (
          <XIconButton
            name='close-circle-outline'
            rounded='full'
            position='absolute'
            top='1'
            right='1'
            // _icon={{ size: 'md' }}
            // w='10'
            // h='10'
            onPress={this._onRequestClose}
            disabled={!_.isFunction(onRequestClose) || webDisabledRequestClose}
            // @ts-ignore
            // style={s.btnRequestCloseIcon}
          />
        ) : null}
      </Modal>
    )
  }
}

const s = {
  btnRequestCloseArea: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'defulat',
  },
  // btnRequestCloseIcon: {
  //   position: 'absolute',
  //   top: -1 * (STYLES.FONT_ICON_NORMAL / 2),
  //   right: -1 * (STYLES.FONT_ICON_NORMAL / 2),
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   width: STYLES.FONT_ICON_NORMAL,
  //   height: STYLES.FONT_ICON_NORMAL,
  //   // zIndex: 101,
  // },
  btnRequestCloseIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
    // top: STYLES.FONT_ICON_NORMAL / 2,
    // right: STYLES.FONT_ICON_NORMAL / 2,
    // alignItems: 'center',
    // justifyContent: 'center',
    // width: STYLES.FONT_ICON_NORMAL,
    // height: STYLES.FONT_ICON_NORMAL,
    // zIndex: 101,
  },
  tranparentBg: {
    // backgroundColor: 'rgba(0,0,0,0.7)',
  },
}
