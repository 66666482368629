import { connect } from 'react-redux'

import { getState, getDispatch } from 'x/modules/orderlist/StoreOrderSingleTabListViewConnect'
import * as NavActions from 'x/utils/navigation'
import { IOrderListItem, IOrderListViewProps, IOrderListViewState } from 'x/index'

import BaseUIOrderListView from './BaseUIOrderListView'

class SearchOrderListView extends BaseUIOrderListView<IOrderListViewProps, IOrderListViewState> {
  static displayName = 'SearchOrderListView'

  // getOverridePreSetupInitialState = () => {
  //   const { selectedStore, sellerStores } = this.props
  //   const hasReseller = selectedStore.get('has_reseller') || false
  //   const hasSeller = List.isList(sellerStores) && sellerStores.size > 0

  //   // ไม่มีทั้ง seller / reseller
  //   let availableTabKeys = CONS.ORDER_SUMMARY_NO_SELLER_AND_RESELLER

  //   if (hasSeller) {
  //     availableTabKeys = CONS.ORDER_SUMMARY_NO_RESELLER
  //   }

  //   if (hasReseller) {
  //     availableTabKeys = CONS.ORDER_SUMMARY_NO_SELLER
  //   }

  //   if (hasSeller && hasReseller) {
  //     availableTabKeys = CONS.ORDER_SUMMARY_ALL
  //   }

  //   return {
  //     availableTabKeys,
  //   }
  // }

  // getOverridePreSetupInitialState = (): Partial<IOrderListViewState> => ({
  //   // isCheckable: true,
  //   availableTabKeys: [],
  //   // ordersFilterSetting: {
  //   //   order_segment: 'my_unshipped_only_and_not_mkp',
  //   // },
  //   tabName: 'search',
  //   // displayMode: 'page',
  //   // displayPageLimit: 30,
  // })

  // getOverrideRequestBody = () => {
  //   const { navigation } = this.props
  //   const df = navigation.getParam('ordersDefaultFilterSetting', {})
  //   return df
  // }

  getTabLabel = () => 'ค้นหาออเดอร์'

  onPressOrderListItem = (od: IOrderListItem) => {
    const { navigation } = this.props

    navigation.dispatch(
      NavActions.navToOrderDetail({
        order_id: od.id,
        store_id: this.getStoreId(),
      })
    )
  }
}

// export default OrderListView

export default connect(
  (state) => ({
    ...getState(state),
  }),
  (dispatch) => ({
    ...getDispatch(dispatch),
  })
)(SearchOrderListView)
