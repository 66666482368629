import { connect } from 'react-redux'
import { getSelectedStore, getSubscription } from 'x/redux/selectors'
import * as StoreActions from 'x/modules/store/StoreState'
import { bindActionCreators } from 'redux'
import ReportHelperSales from './ReportHelperSales'

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
    subscription: getSubscription(state),
    // myStores: getMyStores(state),
  }),
  (dispatch) => ({
    // searchMumber: bindActionCreators(profileActions.fetchProfileList, dispatch),
    // fetchInitializeData: bindActionCreators(StoreState.fetchInitializeData, dispatch),
    getPermissionMemberList: bindActionCreators(StoreActions.getPermissionMemberList, dispatch),
    dispatch,
  })
)(ReportHelperSales)
