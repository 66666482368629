import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import _ from 'lodash'

// import p from 'x/config/platform-specific'
import { STYLES, COLORS } from 'x/config/styles'
// import { PrintOptionKey } from 'x/index'

import XIcon from 'xui/components/XIcon'
import XOverlay from 'xui/components/XOverlay'
import CONS from 'x/config/constants'
import p from 'x/config/platform-specific'
import Box from 'xui/components/Box'
import * as util from 'x/utils/util'
import XText from './XText'
import XButton from './XButton'
import HStack from './HStack'
import XScrollView from './XScrollView'

// interface IPrintingOptionOverlayButton extends IPrintingOptionUIProps {
//   renderButton?: (onPressOpenOverlay: () => void) => JSX.Element
//   buttonStyle?: ViewStyle
//   disabled?: boolean
// }

// interface IPrinterButtonProps {
//   onPress: () => void | Promise<void>
//   buttonStyle?: ViewStyle
//   isDisabled?: boolean
// }

interface IShippingTypeSelectorOverlayViewProps {
  // renderButton: (onPressOpenOverlay: () => void) => void
  headertitle: string
  footerLable: string
  visibleShippingTypes: number[]
  selectedShippingTypeIds: number[]
  onRequestClose: () => void
  onSubmit: (activeShippingTypeIds: number[]) => void
  isVisible: boolean
}

interface IShippingTypeSelectorOverlayViewState {
  isDidMount: boolean
  backUpActiveShippingTypeIds: number[]
  isOverlayVisible: boolean
  isSelectedAllShipping: boolean
  overlayVisibleShippingTypes: number[]
  overlayActiveShippingTypeIds: number[]
}

const defaultVisibleShippingTypes = []
CONS.ORDER_VIEW_SHIPPING_TYPE_ITEMS.map((shipping) => {
  defaultVisibleShippingTypes.push(shipping.value)
})

// const ShippingTypeSelectorOverlayView: React.FC<IShippingTypeSelectorOverlayViewProps> = function ({
//   // renderButton,
//   headertitle,
//   footerLable,
//   visibleShippingTypes,
//   selectedShippingTypeIds,
//   onRequestClose,
//   onSubmit,
//   isVisible,
// }) {
//   const [isDidMount, setIsDidMount] = useState<boolean>(false)
//   const [backUpActiveShippingTypeIds, setActiveShippingTypeIds] = useState<number[]>(selectedShippingTypeIds)
//   const [isOverlayVisible, setIsOverlayVisible] = useState<boolean>(false)
//   const [isSelectedAllShipping, setSelectedAllShipping] = useState<boolean>(false)
//   const [overlayVisibleShippingTypes, setOverlayVisibleShippingTypes] = useState<number[]>(visibleShippingTypes)
//   const [overlayActiveShippingTypeIds, setOverlayActiveShippingTypeIds] = useState<number[]>(selectedShippingTypeIds)
//   // isUseEffect ใช้เผื่อควบคุม useEffect ถ่สมีข้อมูล channel เข้ามาแล้ว ให้หยุดใช้งาน useEffect
//   // const [isUseEffect, setIsUseEffect] = useState(true)
//   // console.log('overlayVisibleShippingTypes => ', overlayVisibleShippingTypes)
//   // console.log('overlayActiveShippingTypeIds => ', overlayActiveShippingTypeIds)

//   useEffect(() => {
//     // setOverlayVisibleShippingTypes(visibleShippingTypes)
//     // setOverlayActiveShippingTypeIds(activeShippingTypeIds)
//     setIsOverlayVisible(isVisible)
//     if (!isDidMount && !_.isNil(visibleShippingTypes) && visibleShippingTypes.length > 0) {
//       // console.log('useEffect !!! ')
//       setIsDidMount(true)
//       setOverlayVisibleShippingTypes(visibleShippingTypes)
//       setOverlayActiveShippingTypeIds(selectedShippingTypeIds)
//       setActiveShippingTypeIds(selectedShippingTypeIds)

//       if (defaultVisibleShippingTypes.length === selectedShippingTypeIds.length) {
//         setSelectedAllShipping(true)
//       } else {
//         setSelectedAllShipping(false)
//       }
//     }
//   })
//   // const addresses = mkpChannel.addresses

//   // const _onSubmitingStateChange = async (newSubmiting: boolean) => {
//   //   await setSubmitting(newSubmiting)
//   // }
//   // console.log('channel 01', channel)
//   // console.log('mkpChannel => ', mkpChannel)
//   // const _openOverlay = () => setIsOverlayVisible(true)
//   // const _closeOverlay = () => setIsOverlayVisible(false)

//   const onCloseOverlay = () => {
//     if (backUpActiveShippingTypeIds !== overlayActiveShippingTypeIds) {
//       p.op.showConfirmation(
//         'ยกเลิกการแก้ไข',
//         'คุณต้องการละทิ้งการแก้ไขใช่หรือไม่',
//         () => {
//           onRequestClose()
//         },
//         () => null,
//         'ใช่',
//         'แก้ไขต่อ'
//       )
//     } else {
//       onRequestClose()
//     }
//   }

//   const _unSetAllShippingType = () => {
//     setOverlayActiveShippingTypeIds([])
//     setSelectedAllShipping(false)
//   }

//   const _setAllShippingType = () => {
//     setOverlayActiveShippingTypeIds(overlayVisibleShippingTypes)
//     setSelectedAllShipping(true)
//   }

//   const _selectedAllShipping = () => {
//     if (_.isNil(visibleShippingTypes) || visibleShippingTypes.length < 1) {
//       return
//     }
//     if (isSelectedAllShipping) {
//       _unSetAllShippingType()
//       // p.op.showConfirmation('', 'ยกเลิกการเลือกรูปแบบจัดส่งทั้งหมดใช่หรือไม่', () => _unSetAllShippingType())
//     } else {
//       _setAllShippingType()
//       // p.op.showConfirmation('', 'เลือกรูปแบบจัดส่งทั้งหมดใช่หรือไม่', () => _setAllShippingType())
//     }
//   }

//   const _onPressSelectedShipping = (shipping: { index: number; right: string; value: number; key: string; tel_required: boolean }) => {
//     const newOverlayActiveShippingTypeIds = _.isNil(overlayActiveShippingTypeIds) ? [] : overlayActiveShippingTypeIds
//     const newActiveShipping = []
//     if (newOverlayActiveShippingTypeIds.length < 1) {
//       newActiveShipping.push(shipping.value)
//       setOverlayActiveShippingTypeIds(newActiveShipping)
//       return
//     }
//     // console.log('newOverlayActiveShippingTypeIds => ', newOverlayActiveShippingTypeIds)
//     let isHasShipping = false
//     newOverlayActiveShippingTypeIds.map((arrIds) => {
//       if (arrIds === shipping.value) {
//         isHasShipping = true
//       } else {
//         newActiveShipping.push(arrIds)
//       }
//     })
//     if (!isHasShipping) {
//       newActiveShipping.push(shipping.value)
//     }
//     if (newActiveShipping === overlayVisibleShippingTypes) {
//       setSelectedAllShipping(true)
//     } else {
//       setSelectedAllShipping(false)
//     }
//     setOverlayActiveShippingTypeIds(newActiveShipping)

//     if (defaultVisibleShippingTypes.length === newActiveShipping.length) {
//       setSelectedAllShipping(true)
//     } else {
//       setSelectedAllShipping(false)
//     }
//   }

//   // const _renderControlButton = () => {
//   //   if (_.isFunction(renderButton)) {
//   //     return renderButton(_openOverlay)
//   //   }
//   //   return (
//   //     <TouchableOpacity
//   //       onPress={() => _openOverlay()}
//   //       style={{
//   //         width: 60,
//   //         height: 25,
//   //         borderWidth: 0.7,
//   //         borderColor: COLORS.APP_MAIN,
//   //         borderRadius: 7,
//   //         backgroundColor: COLORS.BG_LIGHT_GREY,
//   //         justifyContent: 'center',
//   //         alignItems: 'center',
//   //         flexDirection: 'row',
//   //       }}>
//   //       <XIcon style={{ flex: 0, minWidth: 20, width: 20, fontSize: STYLES.FONT_ICON_SMALLEST }} name={`edit`} family={`FontAwesome5`} />
//   //       <Text style={{ color: COLORS.TEXT_INACTIVE, fontSize: STYLES.FONT_SIZE_NORMAL }}>{`แก้ไข`}</Text>
//   //     </TouchableOpacity>
//   //   )
//   // }

//   // eslint-disable-next-line react/no-unstable-nested-components
//   const _renderShippingTypeList = () => {
//     if (_.isNil(visibleShippingTypes) || visibleShippingTypes.length < 1) {
//       return (
//         <View style={{ alignItems: 'center', height: 35, paddingTop: 10 }}>
//           <XText style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE }}>ไม่พบรายการ</XText>
//         </View>
//       )
//     }
//     return (
//       <ScrollView style={{ height: 470 }}>
//         {visibleShippingTypes.map((shippingID: number) => {
//           const SHIPPING = _.find(CONS.ORDER_VIEW_SHIPPING_TYPE_ITEMS, (shipping) => shipping.value === shippingID)
//           if (!_.isNil(SHIPPING)) {
//             const isActive = _.find(overlayActiveShippingTypeIds, (activeID) => activeID === shippingID)
//             return (
//               <TouchableOpacity
//                 onPress={() => _onPressSelectedShipping(SHIPPING)}
//                 key={SHIPPING.value}
//                 style={{
//                   alignItems: 'center',
//                   flexDirection: 'row',
//                   height: 35,
//                   borderBottomColor: COLORS.TEXT_INACTIVE,
//                   borderBottomWidth: 0.5,
//                   paddingLeft: 4,
//                 }}>
//                 <View style={{ width: 40 }}>
//                   {_.isNil(isActive) ? (
//                     <XIcon
//                       family='FontAwesome'
//                       style={{
//                         flex: 0,
//                         minWidth: 30,
//                         fontSize: STYLES.FONT_ICON_NORMAL,
//                         color: !_.isNil(isActive) ? COLORS.APP_MAIN : COLORS.TEXT_INACTIVE,
//                       }}
//                       name='square-o'
//                     />
//                   ) : (
//                     <XIcon
//                       family='FontAwesome'
//                       style={{
//                         flex: 0,
//                         minWidth: 30,
//                         fontSize: STYLES.FONT_ICON_NORMAL,
//                         color: !_.isNil(isActive) ? COLORS.APP_MAIN : COLORS.TEXT_INACTIVE,
//                       }}
//                       name='check-square-o'
//                     />
//                   )}
//                 </View>
//                 <XText variant='active'>{p.op.t(`Order.shippingTypeItems.${SHIPPING.key}`)}</XText>
//               </TouchableOpacity>
//             )
//           }
//         })}
//       </ScrollView>
//     )
//   }

//   // eslint-disable-next-line react/no-unstable-nested-components
//   const _renderOverlay = () => (
//     <XOverlay
//       contentStyle={{ height: 600, width: 310, alignSelf: 'center' }}
//       visible={isOverlayVisible}
//       // webNumColumns={1}
//       onRequestClose={() => onCloseOverlay()}>
//       <Box _light={{ bg: 'white' }} _dark={{ bg: NB.C.D.BG.CLEAR }} p='1' flex={1} borderRadius='md'>
//         <View style={{ flex: 1, height: 500 }}>
//           {/* HEADER */}
//           <Box h='6' flexDirection='row' alignItems='center'>
//             <Box style={{ flex: 1 }}>
//               <XText bold variant='active' pl='2'>
//                 {headertitle}
//               </XText>
//             </Box>
//             <TouchableOpacity style={{ width: 25 }} onPress={() => onCloseOverlay()}>
//               <XIcon name='closecircleo' family='AntDesign' />
//             </TouchableOpacity>
//           </Box>
//           <Box h='1' />
//           <Box _light={{ bg: COLORS.APP_SECONDARY }} _dark={{ bg: 'gray.600' }}>
//             <TouchableOpacity
//               onPress={() => _selectedAllShipping()}
//               style={{
//                 alignItems: 'center',
//                 flexDirection: 'row',
//                 height: 35,
//                 borderTopWidth: 1,
//                 borderTopColor: COLORS.TEXT_INACTIVE,
//                 borderBottomColor: COLORS.TEXT_INACTIVE,
//                 borderBottomWidth: 1,
//                 // backgroundColor: COLORS.APP_SECONDARY,
//                 paddingLeft: 4,
//               }}>
//               <View style={{ width: 40 }}>
//                 {isSelectedAllShipping ? (
//                   <XIcon
//                     family='FontAwesome'
//                     style={{
//                       flex: 0,
//                       minWidth: 30,
//                       fontSize: STYLES.FONT_ICON_NORMAL,
//                       color: COLORS.APP_MAIN,
//                     }}
//                     name='check-square-o'
//                   />
//                 ) : (
//                   <XIcon
//                     family='FontAwesome'
//                     style={{
//                       flex: 0,
//                       minWidth: 30,
//                       fontSize: STYLES.FONT_ICON_NORMAL,
//                       color: COLORS.TEXT_INACTIVE,
//                     }}
//                     name='square-o'
//                   />
//                 )}
//               </View>
//               <XText variant='active'>เลือกทั้งหมด</XText>
//             </TouchableOpacity>
//           </Box>
//           {_renderShippingTypeList()}
//         </View>

//         <XButton
//           onPress={() => {
//             if (overlayActiveShippingTypeIds.length < 1) {
//               p.op.showConfirmationOkOnly('', 'กรุณาเลือกอย่างน้อย 1 รูปแบบจัดส่ง')
//               return
//             }
//             onSubmit(overlayActiveShippingTypeIds)
//             // onCloseOverlay()
//           }}>
//           <XText color='white' fontSize='xl' bold>
//             {footerLable}
//           </XText>
//         </XButton>
//       </Box>
//     </XOverlay>
//   )

//   // Main render
//   return (
//     <>
//       {/* {_renderControlButton()} */}
//       {_renderOverlay()}
//     </>
//   )
// }

class ShippingTypeSelectorOverlayView extends React.Component<
  IShippingTypeSelectorOverlayViewProps,
  IShippingTypeSelectorOverlayViewState
> {
  static displayName = ''

  constructor(props) {
    super(props)

    const { selectedShippingTypeIds = [], visibleShippingTypes = [] } = props

    this.state = {
      isDidMount: false,
      backUpActiveShippingTypeIds: selectedShippingTypeIds,
      isOverlayVisible: false,
      isSelectedAllShipping: false,
      overlayVisibleShippingTypes: visibleShippingTypes,
      overlayActiveShippingTypeIds: selectedShippingTypeIds,
    }
  }

  setIsDidMount = async (isDidMount: boolean) => {
    await util.setStatePromise(this, { isDidMount })
  }

  setActiveShippingTypeIds = async (backUpActiveShippingTypeIds: number[]) => {
    await util.setStatePromise(this, { backUpActiveShippingTypeIds })
  }

  setIsOverlayVisible = async (isOverlayVisible: boolean) => {
    await util.setStatePromise(this, { isOverlayVisible })
  }

  setSelectedAllShipping = async (isSelectedAllShipping: boolean) => {
    await util.setStatePromise(this, { isSelectedAllShipping })
  }

  setOverlayVisibleShippingTypes = async (overlayVisibleShippingTypes: number[]) => {
    await util.setStatePromise(this, { overlayVisibleShippingTypes })
  }

  setOverlayActiveShippingTypeIds = async (overlayActiveShippingTypeIds: number[]) => {
    await util.setStatePromise(this, { overlayActiveShippingTypeIds })
  }

  componentDidUpdate(
    prevProps: Readonly<IShippingTypeSelectorOverlayViewProps>,
    prevState: Readonly<IShippingTypeSelectorOverlayViewState>,
    snapshot?: any
  ): void {
    const { isDidMount } = this.state
    const { visibleShippingTypes, selectedShippingTypeIds } = this.props
    if (!isDidMount && !_.isNil(visibleShippingTypes) && visibleShippingTypes.length > 0) {
      // console.log('useEffect !!! ')
      this.setState({
        isDidMount: true,
        overlayVisibleShippingTypes: visibleShippingTypes,
        overlayActiveShippingTypeIds: selectedShippingTypeIds,
        isSelectedAllShipping: defaultVisibleShippingTypes.length === selectedShippingTypeIds.length,
      })
    }
  }

  onCloseOverlay = async () => {
    const { onRequestClose, visibleShippingTypes, selectedShippingTypeIds } = this.props
    // const { backUpActiveShippingTypeIds, overlayActiveShippingTypeIds } = this.state

    // if (backUpActiveShippingTypeIds !== overlayActiveShippingTypeIds) {
    //   const isUserConfirm = await await p.op.isUserConfirm('ยกเลิกการแก้ไข', 'คุณต้องการละทิ้งการแก้ไขใช่หรือไม่', 'ใช่', 'แก้ไขต่อ')

    //   if (isUserConfirm) {
    //     onRequestClose()
    //   }
    //   // p.op.showConfirmation(
    //   //   'ยกเลิกการแก้ไข',
    //   //   'คุณต้องการละทิ้งการแก้ไขใช่หรือไม่',
    //   //   () => {
    //   //     onRequestClose()
    //   //   },
    //   //   () => null,
    //   //   'ใช่',
    //   //   'แก้ไขต่อ'
    //   // )
    // } else {
    //   onRequestClose()
    // }
    onRequestClose()
  }

  _unSetAllShippingType = async () => {
    await this.setOverlayActiveShippingTypeIds([])
    await this.setSelectedAllShipping(false)
  }

  _setAllShippingType = async () => {
    const { overlayVisibleShippingTypes } = this.state

    await this.setOverlayActiveShippingTypeIds(overlayVisibleShippingTypes)
    await this.setSelectedAllShipping(true)
  }

  _selectedAllShipping = async () => {
    const { isSelectedAllShipping } = this.state
    const { visibleShippingTypes } = this.props

    if (_.isNil(visibleShippingTypes) || visibleShippingTypes.length < 1) {
      return
    }
    if (isSelectedAllShipping) {
      await this._unSetAllShippingType()
      // p.op.showConfirmation('', 'ยกเลิกการเลือกรูปแบบจัดส่งทั้งหมดใช่หรือไม่', () => _unSetAllShippingType())
    } else {
      await this._setAllShippingType()
      // p.op.showConfirmation('', 'เลือกรูปแบบจัดส่งทั้งหมดใช่หรือไม่', () => _setAllShippingType())
    }
  }

  _onPressSelectedShipping = async (shipping: { index: number; right: string; value: number; key: string; tel_required: boolean }) => {
    const { overlayVisibleShippingTypes, overlayActiveShippingTypeIds } = this.state

    const newOverlayActiveShippingTypeIds = _.isNil(overlayActiveShippingTypeIds) ? [] : overlayActiveShippingTypeIds
    const newActiveShipping = []
    if (newOverlayActiveShippingTypeIds.length < 1) {
      newActiveShipping.push(shipping.value)
      await this.setOverlayActiveShippingTypeIds(newActiveShipping)
      return
    }
    // console.log('newOverlayActiveShippingTypeIds => ', newOverlayActiveShippingTypeIds)
    let isHasShipping = false
    newOverlayActiveShippingTypeIds.map((arrIds) => {
      if (arrIds === shipping.value) {
        isHasShipping = true
      } else {
        newActiveShipping.push(arrIds)
      }
    })
    if (!isHasShipping) {
      newActiveShipping.push(shipping.value)
    }
    if (newActiveShipping === overlayVisibleShippingTypes) {
      await this.setSelectedAllShipping(true)
    } else {
      await this.setSelectedAllShipping(false)
    }
    await this.setOverlayActiveShippingTypeIds(newActiveShipping)

    if (defaultVisibleShippingTypes.length === newActiveShipping.length) {
      await this.setSelectedAllShipping(true)
    } else {
      await this.setSelectedAllShipping(false)
    }
  }

  // const _renderControlButton = () => {
  //   if (_.isFunction(renderButton)) {
  //     return renderButton(_openOverlay)
  //   }
  //   return (
  //     <TouchableOpacity
  //       onPress={() => _openOverlay()}
  //       style={{
  //         width: 60,
  //         height: 25,
  //         borderWidth: 0.7,
  //         borderColor: COLORS.APP_MAIN,
  //         borderRadius: 7,
  //         backgroundColor: COLORS.BG_LIGHT_GREY,
  //         justifyContent: 'center',
  //         alignItems: 'center',
  //         flexDirection: 'row',
  //       }}>
  //       <XIcon style={{ flex: 0, minWidth: 20, width: 20, fontSize: STYLES.FONT_ICON_SMALLEST }} name={`edit`} family={`FontAwesome5`} />
  //       <Text style={{ color: COLORS.TEXT_INACTIVE, fontSize: STYLES.FONT_SIZE_NORMAL }}>{`แก้ไข`}</Text>
  //     </TouchableOpacity>
  //   )
  // }

  _renderVisibleShippingItem = (shippingID: number, index: number) => {
    const SHIPPING = _.find(CONS.ORDER_VIEW_SHIPPING_TYPE_ITEMS, (shipping) => shipping.value === shippingID)
    if (_.isNil(SHIPPING)) {
      return null
    }

    const { overlayActiveShippingTypeIds } = this.state
    const isActive = _.find(overlayActiveShippingTypeIds, (activeID) => activeID === shippingID)

    return (
      <TouchableOpacity
        key={`${index.toString()}_${SHIPPING.value}`}
        onPress={() => this._onPressSelectedShipping(SHIPPING)}
        // key={SHIPPING.value}
        style={{
          // alignItems: 'center',
          // flexDirection: 'row',
          // height: 35,
          borderBottomColor: COLORS.TEXT_INACTIVE,
          borderBottomWidth: 1,
          // borderBottomWidth: 0.5,
          // paddingLeft: 4,
        }}>
        <HStack w='full' px='2' py='1' alignItems='center' space={isActive ? '2' : '2.5'}>
          <XIcon family='FontAwesome' variant={isActive ? 'primary' : 'inactive'} name={isActive ? 'check-square-o' : 'square-o'} />
          <XText variant='active'>{p.op.t(`Order.shippingTypeItems.${SHIPPING.key}`)}</XText>
        </HStack>
      </TouchableOpacity>
    )
  }

  _renderShippingTypeList = () => {
    const { overlayActiveShippingTypeIds } = this.state
    const { visibleShippingTypes = [] } = this.props

    if (_.isNil(visibleShippingTypes) || visibleShippingTypes.length < 1) {
      return (
        <View style={{ alignItems: 'center', height: 35, paddingTop: 10 }}>
          <XText style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE }}>ไม่พบรายการ</XText>
        </View>
      )
    }
    return <XScrollView _web={{ flex: 1 }}>{visibleShippingTypes.map(this._renderVisibleShippingItem)}</XScrollView>
  }

  _onPressSubmitButton = () => {
    const { overlayActiveShippingTypeIds } = this.state
    if (overlayActiveShippingTypeIds.length < 1) {
      p.op.showConfirmationOkOnly('', 'กรุณาเลือกอย่างน้อย 1 รูปแบบจัดส่ง')
      return
    }

    const { onSubmit } = this.props
    onSubmit(overlayActiveShippingTypeIds)
    // onCloseOverlay()
  }

  _renderOverlay = () => {
    const {
      // renderButton,
      headertitle,
      footerLable,
      visibleShippingTypes,
      selectedShippingTypeIds,
      onRequestClose,
      onSubmit,
      isVisible,
    } = this.props

    const { isSelectedAllShipping, isOverlayVisible } = this.state

    if (!isVisible) {
      return null
    }

    return (
      <XOverlay
        // contentStyle={{ height: 600, width: 310, alignSelf: 'center' }}
        // visible={isOverlayVisible}
        visible={isVisible}
        // webNumColumns={1}
        isBackdropCloseDisabled
        onRequestClose={this.onCloseOverlay}>
        {/* HEADER */}
        <HStack p='2' alignItems='center' justifyContent='center'>
          <XText bold variant='active'>
            {headertitle}
          </XText>
          {/* <TouchableOpacity style={{ width: 25 }} onPress={this.onCloseOverlay}>
              <XIcon name='closecircleo' family='AntDesign' />
            </TouchableOpacity> */}
        </HStack>

        <Box _light={{ bg: COLORS.APP_SECONDARY }} _dark={{ bg: 'gray.600' }}>
          <TouchableOpacity
            onPress={this._selectedAllShipping}
            style={{
              // alignItems: 'center',
              // flexDirection: 'row',
              // height: 35,
              borderTopWidth: 1,
              borderTopColor: COLORS.TEXT_INACTIVE,
              borderBottomColor: COLORS.TEXT_INACTIVE,
              borderBottomWidth: 1,
              // backgroundColor: COLORS.APP_SECONDARY,
              // paddingLeft: 4,
            }}>
            <HStack w='full' px='2' py='1' alignItems='center' space={isSelectedAllShipping ? '2' : '2.5'}>
              <XIcon
                family='FontAwesome'
                variant={isSelectedAllShipping ? 'primary' : 'inactive'}
                name={isSelectedAllShipping ? 'check-square-o' : 'square-o'}
              />
              <XText variant='active'>เลือกทั้งหมด</XText>
            </HStack>
          </TouchableOpacity>
        </Box>
        {this._renderShippingTypeList()}
        <HStack w='full' p='1' space='1'>
          <XButton flex={1} variant='outline' onPress={this.onCloseOverlay}>
            ยกเลิก
          </XButton>

          <XButton flex={2} onPress={this._onPressSubmitButton}>
            {footerLable}
          </XButton>
        </HStack>
      </XOverlay>
    )
  }

  // Main render
  render() {
    return this._renderOverlay()
  }
}

export default ShippingTypeSelectorOverlayView
