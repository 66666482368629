import BaseUIStoreSettingXShippingJAndTView from 'xui/modules/xshipping/BaseUIStoreSettingXShippingJAndTView'

class StoreSettingXShippingJAndTView extends BaseUIStoreSettingXShippingJAndTView {
  static displayName = 'StoreSettingXShippingJAndTView'

  static navigationOptions = {
    header: null,
    title: null,
    headerLeft: null,
    headerRight: null,
  }
}

export default StoreSettingXShippingJAndTView
