import React, { Component } from 'react'
import _ from 'lodash'
import * as util from 'x/utils/util'
import { ISelectedStoreMap, IAddress, IProfile, IXScreenProps, ActionApiParams, IAnyObject } from 'x/index'
import p from 'x/config/platform-specific'
import * as NavActions from 'x/utils/navigation'
import api from 'x/utils/api'

interface IBaseContactViewProps extends IXScreenProps {
  dispatch: (params: any) => void
  selectedStore: ISelectedStoreMap
  contactActions: any
  deleteCustomer: (params: any) => void
  deleteAddress: (params: any) => void

  saveNew: any
  saveEditing: (params: ActionApiParams) => void
  createAddress: (params: ActionApiParams) => void
  editOrgAddress: (params: ActionApiParams) => void
  addCustomer: (params: ActionApiParams) => void
}

interface IBaseContactViewState {
  profile: IProfile
  mode: 'VIEW_EDIT' | 'PICK_ADDRESS'
}

export default class BaseContactView extends Component<IBaseContactViewProps, IBaseContactViewState> {
  imgManagerRef: React.RefObject<any>

  protected constructor(props: IBaseContactViewProps) {
    super(props)
    // this.imgManagerRef = React.createRef()
    this.state = {
      profile: null,
      mode: 'VIEW_EDIT',
    }
  }

  async componentDidMount() {
    await this._manualFetch()
  }

  _manualFetch = async () => {
    const { navigation, contactActions } = this.props
    // const { state, goBack, setParams } = navigation
    const params = util.getNavParams(this.props)
    const { contact_id, actor, mode } = params
    // let parameter = {
    //   contact_id, actor, mode,
    //   handleAdd: contactActions.fetchContactAdd,
    // }
    // setParams(parameter)
    // CONS.CONTACT_LIST_MODE_KEYS.PICK_ADDRESS

    this.setState({ mode })

    if (contact_id) {
      api
        .post(api.POST_CUSTOMER, { profile_id: contact_id })
        .then(async (res) => {
          // console.log('_manual fetch contact__res_: ', res)
          // contactActions.loadContact(res.profile)
          const { profile } = res
          await util.setStatePromise(this, { profile })
        })
        .catch((error) => {
          util.log('_POST_get_customer__error_: ', error)
        })
    } else {
      // util.hideAppLoadingScreen()
      contactActions.resetContact()
    }
  }

  _deletedCustomer = () => {
    const { deleteCustomer } = this.props
    const { profile } = this.state
    p.op.showConfirmation(
      'คำเตือน',
      'กรุณายืนยันการลบข้อมูลลูกค้า และที่อยู่ทั้งหมดนี้\n\nข้อมูลลูกค้าและที่อยู่ที่ถูกลบไปแล้วจะไม่สามารถกู้คืนมาได้อีก คุณยืนยันหรือไม่',
      () => {
        const successCallback = () => util.navGoBack(this.props)
        deleteCustomer({ body: { profile_id: profile.id }, successCallback })
      },
      null,
      'ยืนยัน',
      'ยกเลิก'
    )
  }

  _deletedAddress = (address: IAddress) => {
    const { navigation, deleteAddress } = this.props
    const { profile } = this.state
    const { addresses } = profile
    if (addresses.length === 1) {
      p.op.showConfirmationOkOnly('ไม่สามารถลบที่อยู่ได้', 'ลูกค้าต้องมีอย่างน้อย 1 ที่อยู่')
      return
    }
    // const successCallback = () => navigation.goBack(navigation.state.key)
    deleteAddress({
      body: {
        address_id: address.id,
        type: 'PROFILE',
      },
      successCallback: (res: { addresses: IAddress[] }) => this.successCallbackUpdateAddress(res.addresses),
    })
  }

  _editAddress = (address: IAddress) => {
    // // console.log('address => ', address)
    // const { profile } = this.state
    // this.props.navigation.dispatch(
    //   NavActions.navToAddressView({
    //     profile_id: profile.id,
    //     address,
    //     headerTitle: 'แก้ไขที่อยู่ลูกค้า',
    //     type: 'PROFILE',
    //     succeedCallback: (addresses: IAddress[]) => this.successCallbackUpdateAddress(addresses),
    //   })
    // )

    this.props.navigation.dispatch(
      NavActions.navToAddressFormView({
        type: 'customer_address',
        headerTitle: 'แก้ไขที่อยู่ลูกค้า',
        initAddress: address,
        onSubmit: async (addr: IAddress) => {
          const isSuccess = await this._handleSubmitForm('PROFILE', addr)
          return { isGoBack: isSuccess }
        },
      })
    )
  }

  _addAddress = () => {
    // // console.log('address => ', address)
    const { profile } = this.state
    // this.props.navigation.dispatch(
    //   NavActions.navToAddressView({
    //     profile_id: profile.id,
    //     address: null,
    //     headerTitle: 'เพิ่มที่อยู่ลูกค้า',
    //     type: 'PROFILE',
    //     succeedCallback: (addresses: IAddress[]) => this.successCallbackUpdateAddress(addresses),
    //   })
    // )

    const initAddress: any = { name: profile.first_name }
    if (profile.last_name) {
      initAddress.name = `${profile.first_name} ${profile.last_name}`
    }
    if (profile.phone1) {
      initAddress.telephone = profile.phone1
    }
    if (profile.contact_email) {
      initAddress.email = profile.contact_email
    }

    this.props.navigation.dispatch(
      NavActions.navToAddressFormView({
        // @ts-ignore
        initAddress,
        type: 'customer_address',
        headerTitle: 'เพิ่มที่อยู่ลูกค้า',
        onSubmit: async (address: IAddress) => {
          const isSuccess = await this._handleSubmitForm('PROFILE', address)
          return { isGoBack: isSuccess }
        },
      })
    )
  }

  successCallbackUpdateAddress = (addresses: IAddress[]) => {
    const { profile } = this.state
    const newProfile = _.clone(profile)
    newProfile.addresses = addresses
    this.setState({
      profile: newProfile,
    })
  }

  _onSaveCustomerProfile = async (addr: IProfile) => {
    // const { editCustomer } = this.props
    const { profile } = this.state
    // const { profile, name, lastName, email, phone } = this.state
    // if (_.isNil(profile)) {
    //   p.op.showConfirmationOkOnly('', 'ไม่พบ profile')
    //   return
    // }
    // if (name.length < 3) {
    //   p.op.showConfirmationOkOnly('', 'กรุณาระบุชื่อ หรืออย่างน้อยชื่อต้องมี 3 ตัวอักษร')
    //   return
    // }
    const { first_name, last_name, contact_email, phone1 } = addr
    const body: any = {}
    body.profile_id = profile.id
    body.first_name = first_name

    if (_.isString(last_name)) {
      body.last_name = last_name
    }

    if (_.isString(phone1)) {
      body.phone1 = phone1
    }

    if (_.isString(contact_email)) {
      body.contact_email = contact_email
    }

    // console.log('body => ', body)
    try {
      const response = await api.patch(api.PATCH_CUSTOMER, body, { showSpinner: true })
      if (response && response.profile) {
        p.op.showToast('แก้ไขข้อมูลเสร็จสิ้นแล้ว', 'success')
        // const succeedCallback = util.getNavParam(this.props, 'succeedCallback')
        // if (_.isFunction(succeedCallback)) {
        //   // @ts-ignore
        //   succeedCallback(response.profile)
        // }
        // util.navGoBack(this.props)
        this.setState({ profile: response.profile })

        return { isGoBack: true }
      }
    } catch (error) {
      console.log('error => ', error)
    }
    return { isGoBack: false }
  }

  _navToEditingCustomerView = () => {
    // console.log('address => ', address)
    const { profile } = this.state
    // this.props.navigation.dispatch(
    //   NavActions.navToCCustomerEditingView({
    //     profile,
    //     succeedCallback: (profile: IProfile) => this.setState({ profile }),
    //   })
    // )

    this.props.navigation.dispatch(
      NavActions.navToAddressFormView({
        headerTitle: 'แก้ไขข้อมูลลูกค้า',
        type: 'customer_profile',
        initAddress: profile,
        onSubmit: this._onSaveCustomerProfile,
      })
    )
  }

  _handleSubmitForm = async (type: string, addr: IAddress): Promise<boolean> => {
    const { profile } = this.state
    const store_id = util.getNavParam(this.props, 'store_id')
    // const profile_id = util.getNavParam(this.props, 'profile_id')
    const body: IAnyObject = { ...addr }
    delete body.id

    if (!_.isEmpty(body)) {
      const address_id = addr.id ? addr.id : null
      // console.log('address => // ', address)
      // console.log('address_id => ', address_id)
      if (address_id) {
        body.address_id = address_id
      } else if (type === 'PROFILE') {
        // body.addressable_id = profile_id
        body.addressable_id = profile.id
      } else if (type === 'STORE') {
        body.addressable_id = store_id
      }

      if (type !== 'CUSTOMER') {
        body.type = type
      }

      try {
        let isSuccess = false
        if (type !== 'CUSTOMER') {
          isSuccess = await this._doSaveNewAddress(body)
        } else {
          isSuccess = await this._dosaveCustomer(body)
        }
        return isSuccess
      } catch (err) {
        console.log('_handleSubmitForm err => ', err)
      }
    } else {
      p.op.showToast('ยังไม่มีการแก้ไขข้อมูลเกิดขึ้น', 'warning')
    }

    return false
  }

  _doSaveNewAddress = async (body): Promise<boolean> => {
    const apiOptions = {
      messages: {
        successMsg: 'สร้างที่อยู่สำเร็จ',
        // errorMsg: 'สร้างที่อยู่ล้มเหลว',
      },
      showSpinner: true,
    }
    const API_TO_GO = body.address_id ? api.patchV2 : api.putV2

    const reqBody = { ...body }

    // API นี้หลังบ้านต้องการ zipcode เป็น int ไม่งั้น error
    if (reqBody.postal_code && reqBody.postal_code.length === 5) {
      reqBody.postal_code = parseInt(reqBody.postal_code)
    }

    const res: { addresses: IAddress[] } = await API_TO_GO(api.PUT_ADDRESS, reqBody, apiOptions)

    if (res.addresses) {
      this.successCallbackUpdateAddress(res.addresses)
      return true
    }
  }

  _doSaveEditingAddress = async (body): Promise<boolean> => {
    const { navigation, saveEditing, editOrgAddress, selectedStore } = this.props
    // @ts-ignore
    // const { isStorePickMode, callbackResponse } = navigation.state && navigation.state.params ? navigation.state.params : false
    const isStorePickMode = util.getNavParam(this.props, 'isStorePickMode')
    const callbackResponse = util.getNavParam(this.props, 'callbackResponse')
    const newBody = body
    const orgz = selectedStore.get(`org`)
    if (!_.isNil(orgz) && orgz.size > 0) {
      const orgzToJS = orgz.toJS()
      newBody.org_id = orgzToJS[0].id
      delete newBody.type
      const response: any = await new Promise((resolveSave) => {
        editOrgAddress({
          body: newBody,
          isStorePickMode,
          successCallback: resolveSave,
          failedCallback: () => resolveSave(null),
        })
      })
      if (response && response.addresses) {
        // this._succeedCallback(response.addresses)
        this.successCallbackUpdateAddress(response.addresses)

        return true
      }
    } else {
      const response: any = await new Promise((resolveSave) => {
        saveEditing({
          body,
          isStorePickMode,
          successCallback: resolveSave,
          failedCallback: () => resolveSave(null),
        })
      })
      if (response) {
        // this._succeedCallback(response.addresses)
        this.successCallbackUpdateAddress(response.addresses)

        return true
      }
    }

    return false
  }

  _dosaveCustomer = async (body): Promise<boolean> => {
    // console.log('_dosaveCustomer => ')
    const { addCustomer, navigation } = this.props
    const data = {
      first_name: body.name,
      last_name: '',
      phone1: body.telephone,
      addresses: [body],
    }
    if (body.address2) {
      // @ts-ignore
      data.address2 = body.address2
    }

    try {
      const isSuccess = await new Promise<boolean>((resolveEdit) => {
        addCustomer({
          body: data,
          successCallback: () => resolveEdit(true),
          failedCallback: () => resolveEdit(false),
        })
      })

      return isSuccess
    } catch (error) {
      return false
    }
  }
}
