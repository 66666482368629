import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as ResellerStateActions from 'x/modules/reseller/ResellerState'

import {
  getUsersInGroupByKey,
  getIsFetchingUsersInGroupByKey,
  getIsAllFetchedUsersInGroupByKey,
  getCountOfUsersInGroupByKey,
  getShouldFetchOfUsersInGroupByKey,
} from 'x/redux/selectors'
import ResellerListJoinedTab from './ResellerListJoinedTab'

const prefixKey = 'joined'
export default connect(
  (state) => ({
    users: getUsersInGroupByKey(state, prefixKey),
    isFetching: getIsFetchingUsersInGroupByKey(state, prefixKey),
    isAllFetched: getIsAllFetchedUsersInGroupByKey(state, prefixKey),
    count: getCountOfUsersInGroupByKey(state, prefixKey),
    shouldFetch: getShouldFetchOfUsersInGroupByKey(state, prefixKey),
  }),
  (dispatch) => ({
    fetchUserList: bindActionCreators(ResellerStateActions.fetchResellerJoinedList, dispatch),
    kickUser: bindActionCreators(ResellerStateActions.kickJoinedUserFromStore, dispatch),
  })
)(ResellerListJoinedTab)
