import React from 'react'
import { View, Dimensions, StyleSheet, TouchableOpacity, Platform, LayoutChangeEvent } from 'react-native'
import VStack from 'xui/components/VStack'
import Box from 'xui/components/Box'
import HStack from 'xui/components/HStack'
import _ from 'lodash'
import p from 'x/config/platform-specific'
import { IMap, ISummaryItem, IOrderSummary } from 'x/index'
// import { STYLES } from '../config/styles'
import { STYLES, COLORS } from 'x/config/styles'
import { getDonutData } from 'x/utils/util'
import DonutChart from './DonutChart'
import XText from './XText'

const { SUMMARY_ORDER_DONUT_COLOR_SCALE } = COLORS

// {/*<VictoryPie*/}
// {/*colorScale={["tomato", "orange", "gold", "cyan", "navy" ]}*/}
// {/*data={[*/}
// {/*{ x: 'Ant', y: 5 },*/}
// {/*{ x: 'Cats', y: 35 },*/}
// {/*{ x: 'Dogs', y: 40 },*/}
// {/*{ x: 'Birds', y: 55 }*/}
// {/*]}*/}
// {/*labels={() => null}*/}
// {/*radius={20}*/}
// {/*innerRadius={10}*/}
// {/*// labels={(d) => d.y}*/}
// {/*// labelComponent={<VictoryLabel angle={45}/>}*/}
// {/*/>*/}

interface IOrdersSummaryDonutProps {
  // circleRadius: number
  // data: ISummaryItem[] | List<ISummaryItemMap>
  orderCount: number // ออเดอร์ที่เปิดอยู่
  orderSummary: IMap<IOrderSummary> | IOrderSummary
  onPress?: (donutData: ISummaryItem[]) => void
}

interface IOrdersSummaryDonutState {
  containerWidth?: number
}

const DEVICE_WIDTH = Dimensions.get('window').width
const IS_SMALL_DEVICE = DEVICE_WIDTH <= 320
const s = StyleSheet.create({
  container: {
    flex: 1,
    // backgroundColor: COLORS.BG_LIGHT_GREY,
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: COLORS.TEXT_INACTIVE,
    // paddingVertical: 4,
    // paddingTop: 4,
    // paddingBottom: 4,
    ...Platform.select({
      ios: {
        borderRadius: 8,
      },
      android: {
        borderRadius: 8,
      },
    }),
  },
  containerGrid: {
    // backgroundColor: 'white',
    // // borderWidth: 0.5,
    // // borderColor: COLORS.TEXT_INACTIVE,
    // borderWidth: 1,
    // borderColor: COLORS.TEXT_INACTIVE,
    // // padding: 4,
    // paddingVertical: 4,
    ...Platform.select({
      web: {
        flexDirection: 'row',
      },
    }),
  },
  containerColLeft: {
    // width: 60,
    // padding: 2,
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerColRight: {
    // paddingHorizontal: 4,
    // padding: 8,
    ...Platform.select({
      web: {
        flexDirection: 'column',
      },
    }),

    marginLeft: p.op.isWeb() ? 4 : 8,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  detailItemColRight: {
    flex: p.op.isWeb() ? 1 : 0,
    flexBasis: 'auto',
    width: IS_SMALL_DEVICE ? 24 : 28,
    alignItems: 'flex-end',
    // paddingRight: 4,
    ...Platform.select({
      web: {
        width: 18,
      },
    }),
  },
  colSeparator: {
    flex: p.op.isWeb() ? 1 : 0,
    flexBasis: 'auto',
    width: p.op.isWeb() ? 5 : 10,
  },
  detailItemsColLeft: {
    marginRight: p.op.isWeb() ? 4 : 8,
  },
  detailItemsColRight: {
    marginLeft: p.op.isWeb() ? 4 : 8,
    paddingRight: 2,
  },
  txtActive: {
    fontSize: IS_SMALL_DEVICE ? STYLES.FONT_SIZE_SMALLER : STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_ACTIVE,
  },
  txtInactive: {
    fontSize: IS_SMALL_DEVICE ? STYLES.FONT_SIZE_SMALLER : STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_INACTIVE,
  },
  txtActiveSmall: {
    fontSize: IS_SMALL_DEVICE ? STYLES.FONT_SIZE_SMALLEST : STYLES.FONT_SIZE_SMALLER,
    color: COLORS.TEXT_ACTIVE,
  },
  txtInactiveSmall: {
    fontSize: IS_SMALL_DEVICE ? STYLES.FONT_SIZE_SMALLEST : STYLES.FONT_SIZE_SMALLER,
    color: COLORS.TEXT_INACTIVE,
  },
  txtShadow: {
    // textShadowColor: 'rgba(0, 0, 0, 0.75)',
    textShadowColor: COLORS.TEXT_INACTIVE,
    textShadowOffset: { width: 0.25, height: 0.25 },
    textShadowRadius: 0.25,
  },
  noFlex: {
    flex: p.op.isWeb() ? 1 : 0,
    flexBasis: 'auto',
  },
  fullWidth: {
    width: '100%',
  },
  paddingLeft10: {
    paddingLeft: 10,
  },
  paddingBottom4: {
    paddingBottom: 4,
  },
  paddingTop2: {
    paddingTop: 2,
  },
  bgHighlight: {
    // backgroundColor: COLORS.APP_SECONDARY,
    backgroundColor: COLORS.FORM_PRIMARY_BG,
    // backgroundColor: SUMMARY_ORDER_DONUT_COLOR_SCALE.isHighlight,
  },
  underline: {
    // borderBottomWidth: 0.5,
    borderBottomWidth: 1,
    // borderBottomColor: COLORS.TEXT_INACTIVE,
    borderBottomColor: COLORS.BG_LIGHT_GREY_ALTERNATIVE,
  },
})

// const SUMMARY_ORDER_DONUT_COLOR_SCALE = {
//   myTasks_confirmOrder: '#09385c',
//   myTasks_pay: '#393f7c',
//   myTasks_confirmGettingPaid: '#773a8b',
//   myTasks_ship: '#b52181',
//
//   myTasks_paySalesOrdersPaid: '#ff2929',
//   myTasks_payOther: '#e6005f',
// }

export default class OrdersSummaryDonut extends React.PureComponent<IOrdersSummaryDonutProps, IOrdersSummaryDonutState> {
  static displayName = 'OrdersSummaryDonut'

  constructor(props) {
    super(props)

    this.state = {
      containerWidth: 320,
    }
  }
  // _getDonutData = (): ISummaryItem[] => {
  //   const { orderSummary } = this.props
  //   let summary: IMap<IOrderSummary> | null = null
  //   if (Map.isMap(orderSummary)) {
  //     summary = orderSummary
  //   } else if (_.isObject(orderSummary)) {
  //     summary = fromJS(orderSummary)
  //   } else {
  //     return []
  //   }
  //   let dataArr: ISummaryItem[]
  //   dataArr = []

  //   // Case 1
  //   // layout 1:
  //   // ยืนยันราคา     4    ชำระ    5
  //   // ยืนยันรับชำระ 2
  //   const myTasksConfirmOrder = summary.get('myTasks_confirmOrder')
  //   const myTasksPay = summary.get('myTasks_pay')
  //   const myTasksConfirmGettingPaid = summary.get('myTasks_confirmGettingPaid')
  //   dataArr.push({ x: 'myTasks_confirmOrder', y: myTasksConfirmOrder, color: SUMMARY_ORDER_DONUT_COLOR_SCALE.myTasks_confirmOrder })
  //   dataArr.push({ x: 'myTasks_pay', y: myTasksPay, color: SUMMARY_ORDER_DONUT_COLOR_SCALE.myTasks_pay })
  //   dataArr.push({
  //     x: 'myTasks_confirmGettingPaid',
  //     y: myTasksConfirmGettingPaid,
  //     color: SUMMARY_ORDER_DONUT_COLOR_SCALE.myTasks_confirmGettingPaid,
  //   })

  //   // Case 2
  //   // layout 2: ร้านฉันมี สินค้าอย่างน้อง 1 รายการ
  //   // ยืนยันราคา     4    ชำระ    5
  //   // ยืนยันรับชำระ 2    จัดส่ง   0
  //   if (summary.has('myTasks_ship')) {
  //     const myTasksShip = summary.get('myTasks_ship')
  //     dataArr.push({ x: 'myTasks_ship', y: myTasksShip, color: SUMMARY_ORDER_DONUT_COLOR_SCALE.myTasks_ship })
  //   }

  //   // Case 3
  //   // layout 3: myTasks_paySalesOrdersPaid กลับมาด้วย ใน summary
  //   // ยืนยันราคา     4     ชำระ
  //   // ยืนยันรับชำระ 2        พร้อมชำระต่อ   3
  //   // จัดส่ง   0                  อื่นๆ                 2
  //   if (summary.has('myTasks_paySalesOrdersPaid')) {
  //     const myTasksPaySalesOrdersPaid = summary.get('myTasks_paySalesOrdersPaid')
  //     const myTasksPayOther = myTasksPay && myTasksPaySalesOrdersPaid ? myTasksPay - myTasksPaySalesOrdersPaid : myTasksPay
  //     dataArr.push({
  //       x: 'myTasks_paySalesOrdersPaid',
  //       y: myTasksPaySalesOrdersPaid,
  //       isSubItem: true,
  //       isHighlight: myTasksPaySalesOrdersPaid > 0,
  //       color: SUMMARY_ORDER_DONUT_COLOR_SCALE.myTasks_paySalesOrdersPaid,
  //     })
  //     dataArr.push({ x: 'myTasks_payOther', y: myTasksPayOther, isSubItem: true, color: SUMMARY_ORDER_DONUT_COLOR_SCALE.myTasks_payOther })

  //     // Modifiy myTasks_pay invisible in donut
  //     dataArr[1].isHeaderItem = true
  //     dataArr[1].y = 0
  //     dataArr[1].y = 0
  //     dataArr[1].color = COLORS.TEXT_INACTIVE
  //   }
  //   return dataArr
  // }
  _getDonutData = () => getDonutData(this.props.orderSummary)

  _getColorScale = (data: ISummaryItem[]): string[] | null => {
    if (!_.isArray(data) || data.length === 0) {
      return null
    }
    return data.map(({ color }) => color)
  }

  _getOrderWaitingMeCount = (data: ISummaryItem[]): number | null => {
    if (!data || !_.isArray(data)) {
      return null
    }
    return data.reduce((prevValue, currentItem) => {
      if (currentItem.isHeaderItem) {
        return prevValue
      }
      return prevValue + currentItem.y
    }, 0)
  }

  _renderDetailHeaderText = (data: ISummaryItem[], orderWaitingMeCount) => {
    const { containerWidth } = this.state
    const { orderCount } = this.props
    if (_.isNil(orderCount)) {
      return null
    }
    const txtHeader = `${orderWaitingMeCount} ออเดอร์`
    const txtSubHeader = orderCount > 0 && orderCount <= 9999 && orderWaitingMeCount !== orderCount ? ` (จาก ${orderCount}) ` : null

    return (
      <HStack w='full'>
        <XText fontSize={containerWidth <= 320 ? 'xs' : 'sm'}>
          {txtHeader}
          <XText variant='inactive'>{txtSubHeader}</XText>
          ที่รอฉันดำเนินการ
        </XText>
      </HStack>
    )
  }

  _renderDetailItems = (data: ISummaryItem[]) => {
    const dataMap = this._getDataMapFromArr(data)

    // layout 3
    if (_.has(dataMap, 'myTasks_paySalesOrdersPaid')) {
      return (
        <HStack w='full'>
          <VStack flex={1}>
            {this._renderDetailItem(dataMap.myTasks_confirmOrder)}
            {this._renderDetailItem(dataMap.myTasks_confirmGettingPaid)}
            {this._renderDetailItem(dataMap.myTasks_ship)}
          </VStack>

          <Box h='full' w='1px' mx='3' bg='muted.100' _dark={{ bg: 'muted.900' }} />

          <VStack flex={1}>
            {this._renderDetailItem(dataMap.myTasks_pay)}
            {this._renderDetailItem(dataMap.myTasks_paySalesOrdersPaid)}
            {this._renderDetailItem(dataMap.myTasks_payOther)}
          </VStack>
        </HStack>
      )
    }

    // layout 2
    if (_.has(dataMap, 'myTasks_ship')) {
      return (
        <HStack w='full'>
          <VStack flex={1}>
            {this._renderDetailItem(dataMap.myTasks_confirmOrder)}
            {this._renderDetailItem(dataMap.myTasks_confirmGettingPaid)}
          </VStack>

          <Box h='full' w='1px' mx='3' bg='muted.100' _dark={{ bg: 'muted.900' }} />

          <VStack flex={1}>
            {this._renderDetailItem(dataMap.myTasks_pay)}
            {this._renderDetailItem(dataMap.myTasks_ship)}
          </VStack>
        </HStack>
      )
    }

    return (
      <HStack w='full'>
        <VStack flex={1} space='0.5'>
          {this._renderDetailItem(dataMap.myTasks_confirmOrder)}
          {this._renderDetailItem(dataMap.myTasks_confirmGettingPaid)}
        </VStack>

        <Box h='full' w='1px' mx='3' bg='muted.100' _dark={{ bg: 'muted.900' }} />

        <VStack flex={1} space='0.5'>
          {this._renderDetailItem(dataMap.myTasks_pay)}
        </VStack>
      </HStack>
    )
  }

  _getDataMapFromArr = (
    data: ISummaryItem[]
  ): {
    myTasks_confirmOrder: ISummaryItem
    myTasks_pay: ISummaryItem
    myTasks_confirmGettingPaid: ISummaryItem
    myTasks_ship?: ISummaryItem

    myTasks_paySalesOrdersPaid?: ISummaryItem // ถ้ามีจะเป็นโหมด 3
    myTasks_payOther?: ISummaryItem // ถ้ามีจะเป็นโหมด 3
  } => {
    let dataMap
    dataMap = {}
    data.forEach((item) => {
      dataMap[item.x] = { ...item }
    })
    return dataMap
  }

  _renderDetailItem = (data: ISummaryItem) => {
    if (!data || !data.x) {
      return null
    }
    const { containerWidth } = this.state
    const { x, y, color, isHighlight, isSubItem, isHeaderItem } = data
    const txtLabel = p.op.t(`OrdersSummaryDonut.${x}`)
    let txtValue: number | string = isHeaderItem ? ' ' : y
    if (txtValue > 999) {
      txtValue = '999+'
    }

    const txtColorStyle = { color }
    // const colLeftStyle = isSubItem ? s.paddingLeft10 : null
    // const highlightStyle = isHighlight ? s.bgHighlight : null
    return (
      <HStack>
        <XText flex={1} fontSize={containerWidth <= 320 ? 'xs' : 'sm'} style={[s.txtShadow, txtColorStyle]}>
          {txtLabel}
        </XText>
        <XText
          bold
          fontSize={containerWidth <= 320 ? 'xs' : 'sm'}
          minW='20px'
          maxW='40px'
          textAlign='right'
          style={[s.txtShadow, txtColorStyle]}>
          {txtValue}
        </XText>
      </HStack>
    )
  }

  _onLayout = (evt: LayoutChangeEvent) => {
    try {
      this.setState({ containerWidth: evt.nativeEvent.layout.width })
    } catch (err) {
      // console.log('err => ', err)
    }
  }

  _renderMain = () => {
    const { containerWidth } = this.state
    const donutData = this._getDonutData()
    const orderWaitingMeCount: number | null = this._getOrderWaitingMeCount(donutData)
    if (orderWaitingMeCount === 0) {
      donutData.push({ x: 'isSuccess', y: 1, color: COLORS.BRAND_Success })
    }
    const donutColorScale = this._getColorScale(donutData)

    return (
      <HStack w='full' p='1' onLayout={this._onLayout}>
        <VStack p='1' alignItems='center' justifyContent='center'>
          <DonutChart
            innerText={orderWaitingMeCount > 999 ? '999+' : orderWaitingMeCount.toString()}
            data={donutData}
            diameter={containerWidth <= 320 ? 54 : 68}
            width={containerWidth <= 320 ? 10 : 12}
            innerTextFontSize={containerWidth <= 320 ? STYLES.FONT_SIZE_NORMAL : STYLES.FONT_SIZE_LARGER}
            // innerTextFontColor={COLORS.TEXT_INACTIVE}
            colorScale={donutColorScale}
          />
        </VStack>
        <VStack flex={1} p='1' flexWrap='wrap'>
          {this._renderDetailHeaderText(donutData, orderWaitingMeCount)}
          {this._renderDetailItems(donutData)}
        </VStack>
      </HStack>
    )
  }

  _onPressDonut = () => {
    const donutData = this._getDonutData()
    this.props.onPress(donutData)
  }

  _isButton = () => _.isFunction(this.props.onPress)

  render() {
    if (this._isButton()) {
      return (
        <TouchableOpacity style={s.container} onPress={this._onPressDonut}>
          {this._renderMain()}
        </TouchableOpacity>
      )
    }

    return <View style={s.container}>{this._renderMain()}</View>
  }

  // render() {
  //   const donutData = this._getDonutData()
  //   const orderWaitingMeCount: number | null = this._getOrderWaitingMeCount(donutData)
  //   if (orderWaitingMeCount === 0) {
  //     donutData.push({ x: 'isSuccess', y: 1, color: COLORS.BRAND_Success })
  //   }
  //   const donutColorScale = this._getColorScale(donutData)
  //
  //   return (
  //     <Stack style={s.containerGrid}>
  //       <VStack size={1} style={s.containerColLeft}>
  //         <DonutChart
  //           innerText={orderWaitingMeCount > 999 ? '999+' : orderWaitingMeCount.toString()}
  //           data={donutData}
  //           diameter={IS_SMALL_DEVICE ? 54 : 68}
  //           width={IS_SMALL_DEVICE ? 10 : 12}
  //           innerTextFontSize={IS_SMALL_DEVICE ? STYLES.FONT_SIZE_NORMAL : STYLES.FONT_SIZE_LARGER}
  //           // innerTextFontColor={COLORS.TEXT_INACTIVE}
  //           colorScale={donutColorScale}
  //         />
  //       </VStack>
  //       {/*<VStack style={{ flex: 0, width: 8 }} />*/}
  //       <VStack size={2} style={s.containerColRight}>
  //         {this._renderDetailHeaderText(donutData, orderWaitingMeCount)}
  //         {this._renderDetailItems(donutData)}
  //       </VStack>
  //     </Stack>
  //   )
  // }
}
