import React, { useState, useEffect } from 'react'
import { TouchableOpacity, View, Platform } from 'react-native'
import { COLORS } from 'x/config/styles'
import XIcon from 'xui/components/XIcon'
import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'
import CONS from 'x/config/constants'
import _ from 'lodash'
import { ISelectedStoreMap, PrintOptionKey } from 'x/index'
import VStack from './VStack'
import HStack from './HStack'
import XIconButton from './XIconButton'
import XText from './XText'
import XButton from './XButton'
import XSpinner from './XSpinner'

export interface IPrintingOptionUIProps {
  selectedStore: ISelectedStoreMap
  afterSubmit?: (printKey: PrintOptionKey) => void | Promise<void>
  onSubmit: (printKey: PrintOptionKey) => Promise<void>
  onSubmitingStateChange?: (newSubmitting: boolean) => Promise<void>
  onSettings?: () => void | Promise<void>
  disabledSettings?: boolean
  disabledBill?: boolean // default false
  enabledReport?: boolean // default true
}

const PrintOptionFooter = function (props: {
  onSubmit: (printKey: PrintOptionKey) => Promise<void>
  onSubmitingStateChange?: (newSubmitting: boolean) => Promise<void>
  afterSubmit?: (printKey: PrintOptionKey) => void | Promise<void>
  printKey: PrintOptionKey
}) {
  const { onSubmit, onSubmitingStateChange, afterSubmit, printKey } = props
  const [submitting, setSubmitting] = util.useStateWithPromise<boolean>(false)

  const _setSubmitting = async () => {
    await setSubmitting(true)
    if (_.isFunction(onSubmitingStateChange)) {
      await onSubmitingStateChange(true)
    }
  }
  const _unsetSubmitting = async () => {
    await setSubmitting(false)
    if (_.isFunction(onSubmitingStateChange)) {
      await onSubmitingStateChange(false)
    }
  }
  const handleOnSubmitByKey = async () => {
    await _setSubmitting()
    await util.delay(200)
    await onSubmit(printKey)
    await util.delay(200)
    await _unsetSubmitting()
    if (_.isFunction(afterSubmit)) {
      afterSubmit(printKey)
    }
  }
  return (
    <XButton w='full' disabled={submitting} isDisabled={submitting} onPress={handleOnSubmitByKey}>
      {submitting ? <XSpinner size='sm' color='white' /> : 'ดาวน์โหลด'}
    </XButton>
  )
}

const PRINTING_DATAS = [
  // Shipping Label
  {
    key: CONS.PRINT_OPTIONS.PAPERANG,
    lable: 'Paperang',
  },
  {
    key: CONS.PRINT_OPTIONS.A4_PDF,
    lable: 'PDF A4',
  },
  {
    key: CONS.PRINT_OPTIONS.STICKER_PDF,
    lable: 'PDF Sticker',
  },
  // Bill
  {
    key: CONS.PRINT_OPTIONS.BILL_PAPERANG,
    lable: 'Paperang',
  },
  // Reports
  {
    key: CONS.PRINT_OPTIONS.REPORT_PRODUCT_DAILY_SALES,
    lable: 'การขายสินค้ารายวัน',
  },
]

export const PrintingOptionUI: React.FC<IPrintingOptionUIProps> = (props) => {
  const {
    selectedStore,
    onSubmit,
    onSubmitingStateChange,
    afterSubmit,
    onSettings,
    disabledSettings = false,
    disabledBill = false,
    enabledReport = true,
  } = props
  const [selectedPrintingKey, setSelectedPrintingKey] = useState<PrintOptionKey>(null)

  const usePaperang = util.isUsePaperangPrinting(selectedStore)
  const useA4PDF = util.isUseA4PDFPrinting(selectedStore)
  const useStickerPDF = util.isUseStickerPDFPrinting(selectedStore)

  const isUseBillPrinting = util.isUseBillPrinting(selectedStore)

  // FIXME: This is mock store setting
  const isUseReportPrinting = true
  // let isBillSectionActive = true
  // isStopSetDefaultSelectedPrintingKey เอาไว้สำหรับเลือกค่าเริ่มต้น เวลาเปิดหน้าต่าง XOverlay
  // let isStopSetDefaultSelectedPrintingKey = false
  // if (_.isNil(selectedPrintingKey)) {
  //   p.op.storageGet(CONS.STORAGE_KEYS.PRINTING_XOVERLAY_OPTION_KEY).then((val: any) => {
  //     if (!_.isNil(val)) {
  //       setSelectedPrintingKey(val)
  //       isStopSetDefaultSelectedPrintingKey = true
  //     }
  //   })
  // }

  // ถ้าไม่มีการเปิดใช้งานบิล ก็ซ่อน UI
  // if (!isUseBillPrinting) {
  //   isBillSectionActive = false
  // }

  const PRINTING_LABLE_KEY: PrintOptionKey[] = []
  const PRINTING_BILL: PrintOptionKey[] = []
  const PRINTING_REPORTS: PrintOptionKey[] = []
  if (usePaperang) {
    PRINTING_LABLE_KEY.push(CONS.PRINT_OPTIONS.PAPERANG)
    // if (_.isNil(selectedPrintingKey) && !isStopSetDefaultSelectedPrintingKey) {
    //   setSelectedPrintingKey(CONS.PRINT_OPTIONS.PAPERANG)
    //   isStopSetDefaultSelectedPrintingKey = true
    // }
  }
  if (useA4PDF) {
    PRINTING_LABLE_KEY.push(CONS.PRINT_OPTIONS.A4_PDF)
    // if (_.isNil(selectedPrintingKey) && !isStopSetDefaultSelectedPrintingKey) {
    //   setSelectedPrintingKey(CONS.PRINT_OPTIONS.A4_PDF)
    //   isStopSetDefaultSelectedPrintingKey = true
    // }
  }
  if (useStickerPDF) {
    PRINTING_LABLE_KEY.push(CONS.PRINT_OPTIONS.STICKER_PDF)
    // if (_.isNil(selectedPrintingKey) && !isStopSetDefaultSelectedPrintingKey) {
    //   setSelectedPrintingKey(CONS.PRINT_OPTIONS.STICKER_PDF)
    //   isStopSetDefaultSelectedPrintingKey = true
    // }
  }

  if (isUseBillPrinting && !disabledBill) {
    PRINTING_BILL.push(CONS.PRINT_OPTIONS.BILL_PAPERANG)
  }

  if (isUseReportPrinting && enabledReport) {
    PRINTING_REPORTS.push(CONS.PRINT_OPTIONS.REPORT_PRODUCT_DAILY_SALES)
    // PRINTING_REPORTS.push(CONS.PRINT_OPTIONS.ANOTHER_REPORT)
  }

  const _setPrintingKey = async (pk: PrintOptionKey) => {
    await p.op.storageSet(CONS.STORAGE_KEYS.PRINTING_XOVERLAY_OPTION_KEY, pk)
    setSelectedPrintingKey(pk)
  }

  const _loadDefaultOptionFromAsyncStorage = async () => {
    const defaultPrintingKey = await p.op.storageGet(CONS.STORAGE_KEYS.PRINTING_XOVERLAY_OPTION_KEY)
    if (_.includes(PRINTING_LABLE_KEY, defaultPrintingKey) || _.includes(PRINTING_BILL, defaultPrintingKey)) {
      await _setPrintingKey(defaultPrintingKey as PrintOptionKey)
    } else if (_.includes(PRINTING_LABLE_KEY, defaultPrintingKey) || _.includes(PRINTING_REPORTS, defaultPrintingKey)) {
      await _setPrintingKey(defaultPrintingKey as PrintOptionKey)
    } else {
      // เลือก option แรกเป็น default (มีการล็อคไว้ว่าอย่างน้อยต้องมี 1 ตัวแน่นอน)
      await _setPrintingKey(PRINTING_LABLE_KEY[0])
    }
  }

  useEffect(() => {
    _loadDefaultOptionFromAsyncStorage()
  }, [])

  const PrintOptionHeader = () => (
    <HStack w='full' minH='9' py='2' px='3' borderBottomWidth='1' borderColor='muted.300'>
      <HStack flex='1' alignItems='center' justifyContent='center'>
        <XText bold>โหลดเอกสาร</XText>
      </HStack>
      {!_.isFunction(onSettings) || disabledSettings ? null : <XIconButton name='settings' family='Ionicons' onPress={onSettings} />}
    </HStack>
  )

  const _renderPrintItem = (key: PrintOptionKey, onPick: (key: PrintOptionKey) => void | Promise<void>) => {
    const printing = _.find(PRINTING_DATAS, (parting) => parting.key === key)
    const isActive = key === selectedPrintingKey
    return (
      <TouchableOpacity
        key={key}
        onPress={() => onPick(key)}
        style={{
          ...Platform.select({
            ios: { flex: 0 },
            android: { flex: 0 },
          }),
          flexBasis: 'auto',
          borderColor: isActive ? COLORS.APP_MAIN : COLORS.TEXT_INACTIVE,
          // borderWidth: 1,
          borderLeftWidth: 1,
          borderRightWidth: 1,
          borderTopWidth: 1,
          borderBottomWidth: 1,
          borderRadius: 7,
          paddingLeft: 4,
          paddingBottom: 4,
          paddingRight: 4,
          paddingTop: 4,
          height: 35,
          marginTop: 10,
          flexDirection: 'row',
          alignItems: 'center',
        }}>
        {isActive ? (
          <XIcon
            // style={{ flex: 0, flexBasis: 'auto', width: 30, minWidth: 30, color: COLORS.APP_MAIN }}
            name='caretright'
            family='AntDesign'
            minW='30px'
          />
        ) : (
          <View style={{ width: 30 }} />
        )}
        <XText pl='2' variant={isActive ? 'active' : 'inactive'}>{`${printing.lable}`}</XText>
      </TouchableOpacity>
    )
  }

  return (
    <VStack w='full' space='1'>
      <PrintOptionHeader />
      <VStack w='full' px='3' py='1' space='3'>
        <VStack w='full'>
          <XText bold>ใบปะหน้าพัสดุ</XText>
          {PRINTING_LABLE_KEY.map((key: PrintOptionKey) => _renderPrintItem(key, _setPrintingKey))}
        </VStack>

        <VStack w='full'>
          {!disabledBill ? <XText bold>ใบเสร็จรับเงิน</XText> : null}
          {!disabledBill ? PRINTING_BILL.map((key: PrintOptionKey) => _renderPrintItem(key, _setPrintingKey)) : null}
        </VStack>

        <VStack w='full'>
          {enabledReport ? <XText bold>รายงาน</XText> : null}
          {enabledReport ? PRINTING_REPORTS.map((key: PrintOptionKey) => _renderPrintItem(key, _setPrintingKey)) : null}
        </VStack>
      </VStack>
      <HStack w='full' p='2'>
        <PrintOptionFooter
          onSubmit={onSubmit}
          onSubmitingStateChange={onSubmitingStateChange}
          afterSubmit={afterSubmit}
          printKey={selectedPrintingKey}
        />
      </HStack>
    </VStack>
  )
}

export default PrintingOptionUI
