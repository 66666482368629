import BaseUIERPDetailView from 'xui/modules/erp/BaseUIERPDetailView'
// import { getConfig } from 'x/config/mode'
// import { WebView } from 'react-native-webview'
// import OrdersSummaryDonut from '../../components/OrdersSummaryDonut'

export default class ERPDetailView extends BaseUIERPDetailView {
  static displayName = 'ERPDetailView'

  static navigationOptions = {
    header: null,
  }
}
