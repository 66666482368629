import { connect } from 'react-redux'

import { getSelectedStore, getMyProfile } from 'x/redux/selectors'
import CardInfoView from './CardInfoView'

// import * as AddressActions from '../address/AddressState'

export default connect(
  (state) => {
    return {
      selectedStore: getSelectedStore(state),
      profile: getMyProfile(state),
    }
  },
  (dispatch) => {
    return {
      // customer: bindActionCreators(AddressActions.getCustomer, dispatch),
    }
  }
)(CardInfoView)
