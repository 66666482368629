/* eslint-disable react/require-default-props */
import React from 'react'
import { COLORS } from 'x/config/styles'
import XImage from './XImage'
import VStack from './VStack'
import XText from './XText'

const IMG_DISCOUNT = require('../img/discount/discount_red.png')

interface IXRedDiscount {
  width?: number
  height?: number
  number: number
}

const XRedDiscount = (props: IXRedDiscount) => {
  const IMG_WIDTH = props?.width ? props.width : 90
  const IMG_HEIGHT = props?.height ? props.height : 90
  const NUMBER = props?.number ? props.number : 0

  const DISCOUNT_BG_VIEW = () => (
    <VStack style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, transform: [{ rotate: '30deg' }] }}>
      <XImage source={IMG_DISCOUNT} w={IMG_WIDTH} h={IMG_HEIGHT} />
    </VStack>
  )
  const DISCOUNT_NUMBER_VIEW = () => (
    <VStack alignItems={'center'} justifyContent={'center'} w={IMG_WIDTH} h={IMG_HEIGHT} style={{ transform: [{ rotate: '25deg' }] }}>
      <XText color={COLORS.WHITE} bold fontSize={'md'}>
        ลด
      </XText>
      <XText color={COLORS.WHITE} bold fontSize={'lg'}>{`${NUMBER}%`}</XText>
    </VStack>
  )
  return (
    <VStack justifyContent={'cenetr'} alignItems={'center'} w={IMG_WIDTH} h={IMG_HEIGHT}>
      {DISCOUNT_BG_VIEW()}
      {DISCOUNT_NUMBER_VIEW()}
    </VStack>
  )
}

export default XRedDiscount
