import React from 'react'

// import EntypoIcon from 'react-native-vector-icons/Entypo'

// import BackIcon from '../../components/BackIcon'
// import XTabNavIcon from '../../components/XTabNavIcon'
import _ from 'lodash'
// import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
// import SRowItem from '../../components/renderRowLeftAndRight'
import BaseSelectAddOnPackageView from 'x/modules/subscription/BaseSelectAddOnPackageView'
import { COLORS } from 'x/config/styles'
import XCard from 'xui/components/XCard'
import XText from 'xui/components/XText'
import HStack from 'xui/components/HStack'
import XIcon from 'xui/components/XIcon'
import XButton from 'xui/components/XButton'
import VStack from 'xui/components/VStack'
import { TouchableOpacity } from 'react-native'
import * as xFmt from 'x/utils/formatter'
import dayjs from 'dayjs'
import CONS from 'x/config/constants'

export default abstract class BaseUISelectAddOnPackageView extends BaseSelectAddOnPackageView {
  _renderHeader = () => {
    const title = 'เลือกแพ็กเสริม'
    return (
      <XCustomHeader
        title={title}
        headerLeftProps={{ backIcon: true, onPressItem: () => this.goBack() }}
        // renderHeaderRight={this._renderHeaderRight}
      />
    )
  }

  // _onSegmentSelectedMonthChange = (newIndex: number) => {
  //   this.setState({
  //     selectedMonthIndex: newIndex,
  //   })
  // }

  _renderFooter = () => (
    <XButton mb='1' mx='1' onPress={() => this._onPressFooter()}>
      ยืนยัน
    </XButton>
  )

  _renderAddOnPackage = () => {
    const { tskuItems, sumAddOnPrice, label } = this.state
    if (_.isNil(tskuItems)) {
      return null
    }
    // console.log('tskuItems => ', tskuItems)
    let sumTotal = 0
    return (
      <VStack w='full'>
        <XText bold fontSize='md'>
          {`แพ็กเสริมเพิ่ม${label}`}
        </XText>
        <VStack w='full'>
          {tskuItems.map((item, index) => {
            const { name } = item
            const selectedCount = _.has(item, 'ui_selected_count') ? item.ui_selected_count : 0
            const isMinus = selectedCount !== 0
            const isPlus = true
            const sum = this._getCountSumTotal(item)
            const priceWithVat = item.price_with_vat
            const countItem = selectedCount * priceWithVat
            sumTotal += sum
            const isEven = index % 2 === 0
            const BG_PRODUCT_COLOR = !isEven ? COLORS.BG_LIGHT_GREY : COLORS.WHITE
            return (
              <VStack justifyContent='center' bgColor={BG_PRODUCT_COLOR} px='1' key={item.name}>
                <HStack w='full' alignItems='center' borderBottomWidth='1' borderBottomColor={COLORS.TEXT_INACTIVE} pb='2'>
                  <VStack flex={3}>
                    <XText variant='active' fontSize='md'>{`${name}`}</XText>
                    <XText variant='inactive'>{`(${xFmt.formatCurrency(priceWithVat)}/รอบบิล)`}</XText>
                    <HStack mt='2' alignItems='center'>
                      <TouchableOpacity
                        disabled={!isMinus}
                        onPress={() => {
                          this._deleteItem(item)
                        }}>
                        <XIcon name='minuscircle' family='AntDesign' opacity={0.3} color={isMinus ? COLORS.RED : COLORS.TEXT_INACTIVE} />
                      </TouchableOpacity>
                      <XText w='20' textAlign='center'>
                        {`${sum.toLocaleString()}`}
                      </XText>
                      <TouchableOpacity
                        onPress={() => {
                          this._addItem(item)
                        }}>
                        <XIcon name='pluscircle' family='AntDesign' opacity={0.3} color={isPlus ? COLORS.GREEN : COLORS.TEXT_INACTIVE} />
                      </TouchableOpacity>
                    </HStack>
                  </VStack>

                  <HStack flex={1} justifyContent='flex-end'>
                    {countItem === 0 ? null : (
                      <XText variant='inactive' bold>
                        {xFmt.formatCurrency(countItem)}
                      </XText>
                    )}
                  </HStack>
                </HStack>
              </VStack>
            )
          })}
          <HStack w='full' mt='2'>
            <HStack flex={3}>
              <XText variant='inactive'>{`รวมเพิ่ม ${sumTotal.toLocaleString()} ${label}/รอบบิล`}</XText>
            </HStack>
            <HStack flex={1} justifyContent='flex-end'>
              <XText bold fontSize='md'>
                {xFmt.formatCurrency(sumAddOnPrice)}
              </XText>
            </HStack>
          </HStack>
        </VStack>
      </VStack>
    )
  }

  _renderResult = () => {
    const { sumAddOnPrice, selectedBillingCycle, tskuItems, label } = this.state
    let sumTotal = 0
    tskuItems.forEach((item) => {
      const sum = this._getCountSumTotal(item)
      sumTotal += sum
    })
    const total = sumAddOnPrice * selectedBillingCycle
    return (
      <VStack w='full' mt='2' borderTopColor={COLORS.TEXT_INACTIVE} borderTopWidth='1' bgColor={COLORS.BG_LIGHT_GREY} px='1'>
        <XText mt='2' bold fontSize='2xl'>
          สรุป
        </XText>
        <VStack w='full'>
          <HStack w='full' mt='2'>
            <VStack flex={2}>
              <XText variant='active' bold>{`เพิ่ม ${sumTotal.toLocaleString()} ${label}`}</XText>
              <XText variant='active' bold>{`จำนวน ${selectedBillingCycle} รอบบิล`}</XText>
            </VStack>
            <VStack flex={2} justifyContent='flex-end'>
              <XText bold fontSize='2xl' textAlign='right'>
                {xFmt.formatCurrency(total)}
              </XText>
              <HStack borderTopColor={COLORS.TEXT_ACTIVE} borderTopWidth='1' />
              <HStack borderTopColor={COLORS.TEXT_ACTIVE} borderTopWidth='3' mt='1' />
            </VStack>
          </HStack>
        </VStack>
      </VStack>
    )
  }

  _renderBill = () => {
    const { selectedBillingCycle, selectedPackages, subscriptionPackageList, itamTypeMode } = this.state
    if (_.isNil(selectedPackages)) {
      return null
    }
    // return null
    // console.log('selectedPackages.billing_cycle =<> ', selectedPackages.billing_cycle)
    // console.log('---> ', selectedBillingCycle)
    const isCanMinus = selectedBillingCycle > 1 && itamTypeMode === 'addon_onetime'
    const isCanPlus = selectedBillingCycle < selectedPackages.billing_cycle && itamTypeMode === 'addon_onetime'
    const futureBillingDates = subscriptionPackageList.future_billing_dates
    // console.log('futureBillingDates => ', futureBillingDates)
    const numberSelectedBillingCycle = selectedBillingCycle === 0 ? 0 : 1
    // console.log('/// => ', futureBillingDates[selectedBillingCycle - numberSelectedBillingCycle])
    const startBill = dayjs(futureBillingDates[0].start).format(CONS.SERVER_DATE_FORMAT)
    const endBill = dayjs(futureBillingDates[selectedBillingCycle - numberSelectedBillingCycle].end).format(CONS.SERVER_DATE_FORMAT)
    const isLifeTime = itamTypeMode === 'addon_lifetime'
    return (
      <VStack w='full' px='1' bgColor='#D9EAF3'>
        <XText mt='4' bold fontSize='md'>
          จำนวนรอบบิล
        </XText>
        <VStack mt='2'>
          <HStack flex={1} justifyContent='flex-start' alignItems='center'>
            {isLifeTime ? null : (
              <TouchableOpacity
                disabled={!isCanMinus}
                onPress={() => {
                  this._editBillBillingCycle('MINUS')
                  // if (selectedBillingCycle - 1 === 0) {
                  //   return
                  // }
                  // this.setState({
                  //   selectedBillingCycle: selectedBillingCycle - 1,
                  // })
                }}>
                <XIcon name='minuscircle' family='AntDesign' opacity={0.3} color={isCanMinus ? COLORS.RED : COLORS.TEXT_INACTIVE} />
              </TouchableOpacity>
            )}

            <XText w='20' textAlign={isLifeTime ? 'left' : 'center'} bold>
              {`x ${selectedBillingCycle}`}
            </XText>
            {isLifeTime ? null : (
              <TouchableOpacity
                disabled={selectedBillingCycle === selectedPackages.billing_cycle}
                onPress={() => {
                  this._editBillBillingCycle('PLUS')
                  // if (selectedBillingCycle + 1 > selectedPackages.billing_cycle) {
                  //   return
                  // }
                  // this.setState({
                  //   selectedBillingCycle: selectedBillingCycle + 1,
                  // })
                }}>
                <XIcon name='pluscircle' family='AntDesign' opacity={0.3} color={isCanPlus ? COLORS.GREEN : COLORS.TEXT_INACTIVE} />
              </TouchableOpacity>
            )}
          </HStack>
          <VStack mt='1'>
            {/* <XText variant='inactive'>{`จำนวน ${selectedBillingCycle} รอบบิล`}</XText> */}
            <XText variant='inactive'>{`ตั้งแต่วันที่ (${startBill} ถึง ${endBill})`}</XText>
          </VStack>
        </VStack>
      </VStack>
    )
  }

  _renderBody = () => {
    const { tskuItems } = this.state
    if (_.isNil(tskuItems)) {
      return null
    }
    return (
      <XCard p='2' w='full'>
        <VStack w='full'>
          {this._renderAddOnPackage()}
          {this._renderBill()}
          {this._renderResult()}
        </VStack>
      </XCard>
    )
  }

  render() {
    return (
      <XContainer>
        {this._renderHeader()}
        <XContent p='1' style={{ backgroundColor: COLORS.BG_LIGHT_GREY }}>
          {this._renderBody()}
          {/* {this._renderAddOnPackage()}
          {this._renderBillingCycle()}
          {this._renderResult()} */}
        </XContent>
        {this._renderFooter()}
      </XContainer>
    )
  }
}
