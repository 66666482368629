import React from 'react'
import { TouchableOpacity, View, StyleSheet } from 'react-native'
import { S, STYLES, COLORS } from 'x/config/styles'
// import CONS from 'x/config/constants'

import { IERPChannel, ISelectedStoreMap } from 'x/index'
import XCustomHeader from 'xui/components/XCustomHeader'
import XText from 'xui/components/XText'
import VStack from 'xui/components/VStack'
import XInput from 'xui/components/XInput'
import XOverlay from 'xui/components/XOverlay'

interface IDeleteErpChannelProps {
  // after delete success
  onDeleted: () => void
  erpChannel: IERPChannel
  selectedStore: ISelectedStoreMap
  isOverlayVisible: boolean
  closeOverlay: () => void
}

interface IDeleteErpChannelState {
  // isOverlayVisible: boolean
  confirmDeleteText: string

  dialogStep: number // start with 1

  // use in step 3 only
  // isAccountDeleted: boolean
  // hasAppleId: boolean
  // hasFbId: boolean

  isSubmitting: boolean
}

class DeleteErpChannel extends React.Component<IDeleteErpChannelProps, IDeleteErpChannelState> {
  static displayName = 'DeleteErpChannel'

  // inProcess?: boolean

  constructor(props) {
    super(props)
    this.state = {
      // isOverlayVisible: false,
      confirmDeleteText: '',

      dialogStep: 1,

      // isAccountDeleted: false,

      isSubmitting: false,
    }

    // this.inProcess = false
  }

  // openOverlay = async () => {
  //   // console.log('openOverlay')
  //   await util.setStatePromise(this, { isOverlayVisible: true })
  // }

  closeOverlay = () => {
    this.props.closeOverlay()
  }

  _onPressDelete = () => {
    const { onDeleted } = this.props
    onDeleted()
    this.closeOverlay()
  }

  _onPressContinueToStepTwo = () => {
    this.setState({ dialogStep: 2 })
  }

  renderHeader = () => {
    // const { dialogStep = 1 } = this.state
    const titleText = 'ยืนยันการยกเลิกการเชื่อมต่อระบบบัญชี'

    // if (dialogStep === 3) {
    //   titleText = isAccountDeleted ? 'ลบบัญชี XSelly สำเร็จ' : 'เกิดข้อผิดพลาดในการลบบัญชี'
    // }

    return <XCustomHeader title={titleText} headerRightProps={{ closeIcon: !this.state.isSubmitting, onPressItem: this.closeOverlay }} />
  }

  renderStepOneConfirmationButtons = () => (
    <View
      style={{
        flex: 1,
        width: '100%',
        minHeight: 44,
        padding: 4,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
      }}>
      <TouchableOpacity onPress={this._onPressContinueToStepTwo} style={[S.FLEX, S.BUTTON_DANGER, S.HEIGHT_34]}>
        <XText bold color='white'>
          ดำเนินการต่อ
        </XText>
      </TouchableOpacity>
      <View style={{ width: 8 }} />
      <TouchableOpacity onPress={this.closeOverlay} style={[S.FLEX, S.ROW_CENTER, S.BUTTON_OUTLINE_PRIMARY, S.HEIGHT_34]}>
        <XText bold color={COLORS.APP_MAIN}>
          {'ยกเลิก '}
        </XText>
      </TouchableOpacity>
    </View>
  )

  renderContentStepOne = () => (
    <VStack>
      <VStack w='full' bgColor='amber.100'>
        <View style={{ width: '100%', padding: 8, flexDirection: 'row', flexWrap: 'wrap' }}>
          <XText variant='active'>
            หากดำเนินการลบการเชื่อมต่อระบบบัญชี ข้อมูลเอกสารต่างๆที่สร้างไว้บน PEAK ก็จะยังคงอยู่ที่ PEAK เหมือนเเดิม แต่ข้อมูลเอกสารของ
            PEAK ใน XSelly จะถูกลบไป ทำให้ไม่สามารถดูได้ว่าออเดอร์ XSelly ใดที่ถูกส่งไปสร้างเป็นเอกสารเลขที่ใดใน PEAK
            เมื่อลบการเชื่อมต่อระบบบัญชีแล้ว และมีการเชื่อมต่อใหม่อีกครั้ง XSelly ก็จะเริ่มบันทึกข้อมูลเอกสารใหม่
            โดยไม่แสดงข้อมูลเอกสารเดิมเพราะจะถูกลบไปแล้ว
          </XText>
        </View>

        <View style={{ height: 16 }} />
      </VStack>
      {this.renderStepOneConfirmationButtons()}
    </VStack>
  )

  isConfirmDeleteTextValid = () => {
    const { confirmDeleteText = '' } = this.state
    return confirmDeleteText === 'DELETE'
  }

  renderStepTwoConfirmationButtons = () => {
    const isDelBtnDisabled = !this.isConfirmDeleteTextValid()
    return (
      <View style={{ width: '100%', minHeight: 44, padding: 4, alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
        <TouchableOpacity
          disabled={isDelBtnDisabled}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onPress={isDelBtnDisabled ? undefined : this._onPressDelete}
          style={[S.FLEX, isDelBtnDisabled ? S.BUTTON_INACTIVE : S.BUTTON_DANGER, S.HEIGHT_34]}>
          <XText bold style={[isDelBtnDisabled ? S.TEXT_ACTIVE : S.TEXT_ACTIVE_DARK, S.TEXT_LARGER, S.TEXT_ALIGN_CENTER]}>
            ลบระบบบัญชี
          </XText>
        </TouchableOpacity>
        <View style={{ width: 8 }} />
        <TouchableOpacity onPress={this.closeOverlay} style={[S.FLEX, S.ROW_CENTER, S.BUTTON_OUTLINE_PRIMARY, S.HEIGHT_34]}>
          <XText bold style={[S.TEXT_PRIMARY, S.TEXT_ALIGN_CENTER, S.TEXT_LARGER]}>
            {'ยกเลิก '}
          </XText>
        </TouchableOpacity>
      </View>
    )
  }

  _onConfirmDeleteTextChange = (newText: string) => {
    this.setState({ confirmDeleteText: newText })
  }

  renderDeleteTextInput = () => {
    const { confirmDeleteText = '' } = this.state
    return (
      <XInput
        style={{ minHeight: 34, padding: 8, fontSize: STYLES.FONT_SIZE_LARGER }}
        flex={1}
        textAlign='center'
        textAlignVertical='center'
        placeholder='พิมพ์ DELETE เพื่อลบบัญชี'
        value={confirmDeleteText}
        onChangeText={this._onConfirmDeleteTextChange}
      />
    )
  }

  renderContentStepTwo = () => (
    <>
      <View style={{ width: '100%', padding: 8, flexDirection: 'row', flexWrap: 'wrap' }}>
        <XText style={[S.TEXT_ACTIVE, S.TEXT_LARGER]}>
          เพื่อยืนยันการลบระบบบัญชี กรุณาพิมพ์คำว่า DELETE (ตัวพิมพ์ใหญ่ทุกตัว) ในช่องด้านล่างและกด
        </XText>
      </View>
      <View style={{ width: '100%', padding: 8, flexDirection: 'row', flexWrap: 'wrap' }}>{this.renderDeleteTextInput()}</View>

      <View style={{ height: 16 }} />

      {this.renderStepTwoConfirmationButtons()}
    </>
  )

  // renderStepThreeConfirmationButton = () => (
  //   <View style={{ width: '100%', minHeight: 44, padding: 4, alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
  //     <TouchableOpacity onPress={this.closeOverlay} style={[S.FLEX, S.ROW_CENTER, S.BUTTON_OUTLINE_PRIMARY, S.HEIGHT_34]}>
  //       <Text style={[S.TEXT_PRIMARY, S.TEXT_BOLD, S.TEXT_ALIGN_CENTER]}>{'ปิด '}</Text>
  //     </TouchableOpacity>
  //   </View>
  // )

  // renderContentStepThree = () => (
  //   <>
  //     <View style={{ width: '100%', padding: 8, flexDirection: 'row', flexWrap: 'wrap' }}>
  //       <Text style={[S.TEXT_ACTIVE, S.TEXT_LARGER]}>{infoText}</Text>
  //     </View>

  //     <View style={{ height: 16 }} />

  //     {this.renderStepThreeConfirmationButton()}
  //   </>
  // )

  renderContent = () => {
    const { dialogStep = 1 } = this.state
    return (
      <View style={{ width: '100%' }}>
        {this.renderHeader()}

        {dialogStep === 1 ? this.renderContentStepOne() : null}
        {dialogStep === 2 ? this.renderContentStepTwo() : null}
        {/* {dialogStep === 3 ? this.renderContentStepThree() : null} */}
      </View>
    )
  }

  renderMain = () => {
    const { isSubmitting = false } = this.state
    const { isOverlayVisible } = this.props
    const heightDialog = 310
    return (
      <XOverlay
        contentStyle={StyleSheet.flatten([
          {
            minHeight: 150,
            marginBottom: 130,
            width: 310,
            height: heightDialog,
            alignSelf: 'center',
            flexDirection: 'column',
            backgroundColor: COLORS.WHITE,
          },
          // p.op.isWeb() ? { height: dialogStep === 1 ? 290 : 260 } : {},
        ])}
        visible={isOverlayVisible}
        onRequestClose={isSubmitting ? undefined : this.closeOverlay}>
        {this.renderContent()}
      </XOverlay>
    )
  }

  render() {
    return this.renderMain()
  }
}

export default DeleteErpChannel
