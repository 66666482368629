import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Map } from 'immutable'

import { getSelectedStore } from 'x/redux/selectors'
import * as StoreState from 'x/modules/store/StoreState'

import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'
import CONS from 'x/config/constants'

import XCustomHeader from 'xui/components/XCustomHeader'
// import { Input } from 'react-native-elements'
import XIcon from 'xui/components/XIcon'
import HStack from 'xui/components/HStack'
import XButton from 'xui/components/XButton'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import VStack from 'xui/components/VStack'
import XText from 'xui/components/XText'
import ErpLogo from 'xui/components/ErpLogo'
import { COLORS } from 'x/config/styles'
import * as NavActions from 'x/utils/navigation'
import {
  IERPChannel,
  IERPSubmitDocumentOrderListViewNavParams,
  IErpDocTypeOption,
  IOrderListFilterTemplate,
  IOrdersFilter,
  IXScreenProps,
  ISelectedStoreMap,
  IOrderListFilterPreference,
} from 'x/index'
import XCard from 'xui/components/XCard'
import ErpDocTypeSelector from 'xui/components/erp/ErpDocTypeSelector'
import _ from 'lodash'
import OrderListFilterTemplateSelector from 'xui/components/orders/OrderListFilterTemplateSelector'
import dayjs from 'dayjs'

// const VIEW_HEIGHT_TEN = <View style={{ height: 10 }} />

interface IERPSubmitDocumentViewParams {
  isGoBackButtonHidden?: boolean
  store_id: number
  erpChannel: IERPChannel
}

interface IProps extends IXScreenProps<IERPSubmitDocumentViewParams> {
  selectedStore: ISelectedStoreMap
}

interface IState {
  isInitialized: boolean

  // docTypeIndex: number
  // // isTaxInvoice: boolean
  // docTypeTaxInvoiceChecked: {
  //   [key: number]: boolean
  // }

  selectedDoctypeIndex: number
  selectedDoctypeOption: IErpDocTypeOption
  isTaxInvoiceDoctype?: boolean

  paymentStatusIndex: number
  shippingStatusIndex: number

  selectedFilterTemplate?: IOrderListFilterTemplate
}

// interface ICheckboxProps {
//   disabled?: boolean
//   onPress: () => void
//   isChecked: boolean
// }

// const Radio: React.FC<ICheckboxProps> = ({ disabled, onPress, isChecked }) => (
//   <TouchableOpacity onPress={onPress}>
//     <XIcon variant={disabled ? 'inactive' : 'primary'} name={isChecked ? 'radio-button-on' : 'radio-button-off'} />
//   </TouchableOpacity>
// )
// const Checkbox: React.FC<ICheckboxProps> = ({ disabled, onPress, isChecked }) => (
//   <TouchableOpacity onPress={onPress}>
//     <XIcon family='FontAwesome' variant={disabled ? 'inactive' : 'primary'} name={isChecked ? 'check-square-o' : 'square-o'} />
//   </TouchableOpacity>
// )

const SAVE_STATE_KEY = 'ERPSubmitDocumentViewState'
const VISIBLE_ERP_DOCTYPE_IDS = [12, 14]

class ERPSubmitDocumentView extends React.Component<IProps, IState> {
  static displayName = 'ERPSubmitDocumentView'

  inProcess?: boolean

  constructor(props) {
    super(props)

    this.state = {
      isInitialized: false,

      // docTypeIndex: 0,
      // // isTaxInvoice: false,
      // docTypeTaxInvoiceChecked: {
      //   0: false,
      //   1: false,
      // },

      selectedDoctypeIndex: CONS.ERP_DOC_TYPE_OPTIONS.findIndex(({ id }) => id === 12),
      selectedDoctypeOption: CONS.ERP_DOC_TYPE_OPTIONS.find(({ id }) => id === 12),
      isTaxInvoiceDoctype: false,

      paymentStatusIndex: 0,
      shippingStatusIndex: 0,

      selectedFilterTemplate: null,
    }
  }

  async componentDidMount(): Promise<void> {
    // const erpChannel = util.getNavParam(this.props, 'erpChannel')
    // console.log('erpChannel => ', erpChannel)
    const saveState = await this.getSaveState()
    let initState = { ...this.state, ...saveState }

    if (_.isEmpty(saveState)) {
      await this._initializeSaveStateIfNotInitialized()
    }

    // apply default/save state by doc type
    const savedStateByDocType = await this.getSaveStateByErpDocTypeId(initState.selectedDoctypeOption.id)
    initState = { ...initState, ...savedStateByDocType }

    this.setState({ ...initState, isInitialized: true })
  }

  _initializeSaveStateIfNotInitialized = async () => {
    // ใบแจ้งหนี้
    // ใบแจ้งหนี้ default: ชำระไม่ครบถ้วน + จัดส่งไม่ครบถ้วน
    const initState12: Partial<IState> = { paymentStatusIndex: 2, shippingStatusIndex: 0, selectedFilterTemplate: null }
    await p.op.storageSet(this.getSaveStateKeyByErpDocTypeId(12), initState12, true)

    // ใบเสร็จรับเงิน
    // ใบเสร็จรับเงิน default: ชำระครบถ้วน + จัดส่งทั้งหมด
    const initState14: Partial<IState> = {
      paymentStatusIndex: 1,
      shippingStatusIndex: 0,
      isTaxInvoiceDoctype: true,
      selectedFilterTemplate: null,
    }
    await p.op.storageSet(this.getSaveStateKeyByErpDocTypeId(14), initState14, true)
  }

  goBack = () => {
    util.navGoBack(this.props)
  }

  renderCustomHeader = () => {
    const isGoBackButtonHidden = util.getNavParam(this.props, 'isGoBackButtonHidden', false)
    return (
      <XCustomHeader
        title='สร้างใบเสร็จรับเงิน/ใบกำกับภาษี'
        headerLeftProps={{ backIcon: isGoBackButtonHidden ? null : true, onPressItem: this.goBack }}
      />
    )
  }

  getSaveStateKeyByErpDocTypeId = (erpDocTypeId: number) => `${SAVE_STATE_KEY}_${erpDocTypeId}`

  saveStateByErpDocTypeId = async (erpDocTypeId: number) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { isInitialized, ...restState } = this.state
    await p.op.storageSet(this.getSaveStateKeyByErpDocTypeId(erpDocTypeId), restState, true)
  }

  _getOrderListFilterPref = (): IOrderListFilterPreference => {
    const { selectedStore } = this.props
    const pref = selectedStore.get('pref_ui_order_list_filter')

    if (!pref) {
      return {
        templates: [],
        dashboardItems: [],
        createdAt: dayjs().format(CONS.SERVER_DATETIME_FORMAT).toString(),
      }
    }

    return Map.isMap(pref) ? pref.toJS() : pref
  }

  _getOrderListFilterTemplateById = (id: string) => {
    const { templates = [] } = this._getOrderListFilterPref()
    const foundTemplate = templates.find((template) => template.id === id)
    return foundTemplate && foundTemplate.id ? foundTemplate : null
  }

  getSaveStateByErpDocTypeId = async (erpDocTypeId: number): Promise<Partial<IState>> => {
    const savedState = (await p.op.storageGet(this.getSaveStateKeyByErpDocTypeId(erpDocTypeId), true)) as Partial<IState>

    if (savedState && !_.isEmpty(savedState)) {
      if (savedState.selectedFilterTemplate && savedState.selectedFilterTemplate.id) {
        savedState.selectedFilterTemplate = this._getOrderListFilterTemplateById(savedState.selectedFilterTemplate.id)
      }

      // @ts-ignore
      return savedState
    }

    return {}
  }

  saveState = async () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { isInitialized, ...restState } = this.state
    await p.op.storageSet(SAVE_STATE_KEY, restState, true)
  }

  getSaveState = async (): Promise<Partial<IState>> => {
    const savedState = (await p.op.storageGet(SAVE_STATE_KEY, true)) as Partial<IState>

    if (savedState && !_.isEmpty(savedState)) {
      if (savedState.selectedFilterTemplate && savedState.selectedFilterTemplate.id) {
        savedState.selectedFilterTemplate = this._getOrderListFilterTemplateById(savedState.selectedFilterTemplate.id)
      }
      // @ts-ignore
      return savedState
    }

    return {}
  }

  _navToERPSubmitDocumentOrderListView = async () => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true

    const { navigation } = this.props
    // const { docTypeIndex, docTypeTaxInvoiceChecked, paymentStatusIndex, shippingStatusIndex } = this.state
    const { selectedDoctypeOption, isTaxInvoiceDoctype, paymentStatusIndex, shippingStatusIndex, selectedFilterTemplate: sft } = this.state
    const store_id = util.getNavParam(this.props, 'store_id')
    const erpChannel = util.getNavParam(this.props, 'erpChannel')

    const pref = this._getOrderListFilterPref()
    const { templates = [] } = pref

    // เพื่อความสดใหม่ เนื่องจากมีโอกาสที่หน้า OrderList แล้ว template มีโอกาสโดน manipulate
    const selectedFilterTemplate = templates.find((tmpl) => tmpl.id === sft.id)

    // const ordersDefaultFilterSetting: IOrdersFilter = {}
    const ordersDefaultFilterSetting: IOrdersFilter = {}
    const ordersFilterSetting: IOrdersFilter | IOrderListFilterTemplate = _.isObject(selectedFilterTemplate)
      ? _.cloneDeep(selectedFilterTemplate)
      : {}

    // // ใบแจ้งหนี้
    // let erp_doc_type = 12
    // if (docTypeIndex === 1) {
    //   // ใบเสร็จรับเงิน
    //   erp_doc_type = 14
    // }

    const erp_doc_type = selectedDoctypeOption.id
    await this.saveStateByErpDocTypeId(erp_doc_type)
    await this.saveState()

    let is_tax_invoice = false
    if (selectedDoctypeOption.is_tax_invoice_available && isTaxInvoiceDoctype) {
      is_tax_invoice = true
    }

    // if (paymentStatusIndex === 1) {
    //   // ชําระเงินแล้ว
    //   ordersDefaultFilterSetting.hasPaymentCompletedAt = true
    // }

    // if (paymentStatusIndex === 2) {
    //   // ยังไม่ชําระเงิน
    //   ordersDefaultFilterSetting.hasPaymentCompletedAt = false
    // }

    // if (shippingStatusIndex === 1) {
    //   // จัดส่งแล้ว
    //   ordersDefaultFilterSetting.hasShippingCompletedAt = true
    // }

    // if (shippingStatusIndex === 2) {
    //   // ยังไม่จัดส่ง
    //   ordersDefaultFilterSetting.hasShippingCompletedAt = false
    // }

    // if (erp_doc_type === 14) {
    //   // ใบเสร็จรับเงิน
    //   // pKeng on 1 May 2024: Do nothing in this block to allow filtering by payment status
    //   // ordersDefaultFilterSetting.hasPaymentCompletedAt = true
    //   // ordersDefaultFilterSetting.isFilterPaymentCompletedAtDisabled = true
    // }

    // ordersDefaultFilterSetting.isTaxInvoiceRequested = is_tax_invoice || false
    // ordersFilterSetting.isTaxInvoiceRequested = is_tax_invoice || false
    // const erp_doc_types_not_in = CONS.ERP_DOC_TYPE_OPTIONS.filter(({ id }) => id !== erp_doc_type).map(({ id }) => id)
    const erp_doc_types_not_in = [erp_doc_type]
    const erp_doc_types = VISIBLE_ERP_DOCTYPE_IDS.filter((erpDtId) => erpDtId !== erp_doc_type)

    // let payment_states = []
    // if (paymentStatusIndex === 1) {
    //   // ชําระเงินแล้ว
    //   payment_states = [119]
    // } else if (paymentStatusIndex === 2) {
    //   // ยังไม่ชําระเงิน
    //   payment_states = [111, 112, 113, 114, 115]
    // }

    // let shipping_states = []
    // if (shippingStatusIndex === 1) {
    //   // จัดส่งแล้ว
    //   shipping_states = [129]
    // } else if (shippingStatusIndex === 2) {
    //   // ยังไม่จัดส่ง
    //   shipping_states = [121, 122]
    // }

    const segmentErpDocTypeWhichInIndex = 1
    const erpDocTypeActiveMap = {}
    const segmentErpDocTypeWhichNotInIndex = 1
    const erpDocTypeNotInActiveMap = {}

    for (let i = 0; i < erp_doc_types_not_in.length; i++) {
      const erpDocTypesId = erp_doc_types_not_in[i]
      erpDocTypeNotInActiveMap[erpDocTypesId] = true
      erpDocTypeActiveMap[erpDocTypesId] = false
    }

    for (let i = 0; i < erp_doc_types.length; i++) {
      const erpDocTypesId = erp_doc_types[i]
      erpDocTypeNotInActiveMap[erpDocTypesId] = false
      erpDocTypeActiveMap[erpDocTypesId] = true
    }

    // ordersDefaultFilterSetting.segmentErpDocTypeWhichInIndex = segmentErpDocTypeWhichInIndex
    // ordersDefaultFilterSetting.erpDocTypeActiveMap = erpDocTypeActiveMap

    ordersDefaultFilterSetting.segmentErpDocTypeWhichNotInIndex = segmentErpDocTypeWhichNotInIndex
    ordersDefaultFilterSetting.erpDocTypeNotInActiveMap = erpDocTypeNotInActiveMap

    // ป้องกันค่า conflict กันกับตัว Not In
    if (ordersFilterSetting.segmentErpDocTypeWhichInIndex === 1) {
      ordersFilterSetting.segmentErpDocTypeWhichInIndex = segmentErpDocTypeWhichInIndex
      ordersFilterSetting.erpDocTypeActiveMap = erpDocTypeActiveMap
    }

    ordersFilterSetting.segmentErpDocTypeWhichNotInIndex = segmentErpDocTypeWhichNotInIndex
    ordersFilterSetting.erpDocTypeNotInActiveMap = erpDocTypeNotInActiveMap

    const navParams: IERPSubmitDocumentOrderListViewNavParams = {
      store_id,
      erpChannel,
      ordersDefaultFilterSetting,
      ordersFilterSetting,

      overrideRequestBody: {
        // states: [101, 102, 103, 109],
        order_states: [101, 102, 103, 109],
        // erp_doc_types_not_in,
        order_types: [1, 2],
      },
      erp_doc_type,
      is_tax_invoice,
    }

    // if (selectedFilterTemplate) {
    //   navParams.selectedOrderListFilterTemplate = selectedFilterTemplate
    // }

    // if (payment_states.length > 0) {
    //   navParams.overrideRequestBody.payment_states = payment_states
    // }

    // if (shipping_states.length > 0) {
    //   navParams.overrideRequestBody.shipping_states = shipping_states
    // }

    navigation.dispatch(NavActions.navToERPSubmitDocumentOrderListView(navParams))

    this.inProcess = false
  }

  // _onDocTypeRadioPress = (index: number) => {
  //   this.setState({ docTypeIndex: index })
  // }

  // _onTaxInvoiceCheckboxToggle = (idx: number) => {
  //   const { docTypeTaxInvoiceChecked: oldDocTypeTaxInvoiceChecked } = this.state
  //   const docTypeTaxInvoiceChecked = { ...oldDocTypeTaxInvoiceChecked }
  //   docTypeTaxInvoiceChecked[idx] = !docTypeTaxInvoiceChecked[idx]
  //   this.setState({ docTypeTaxInvoiceChecked })
  // }

  _renderErpChannel = () => {
    const erpChannel = util.getNavParam(this.props, 'erpChannel')

    return (
      <HStack w='full' alignItems='center' space='1'>
        <ErpLogo erpSystemId={erpChannel.erp_system_id} />
        <XText bold>{erpChannel.name}</XText>
      </HStack>
    )
  }

  _onErpDocTypeOptionChange = async (
    selectedDoctypeIndex: number,
    selectedDoctypeOption: IErpDocTypeOption,
    isTaxInvoiceDoctype: boolean
  ) => {
    const newState: Partial<IState> = { selectedDoctypeIndex, selectedDoctypeOption }
    if (_.isBoolean(isTaxInvoiceDoctype)) {
      newState.isTaxInvoiceDoctype = isTaxInvoiceDoctype
    }

    const savedState = await this.getSaveStateByErpDocTypeId(selectedDoctypeOption.id)

    // @ts-ignore
    this.setState({ ...savedState, ...newState })
  }

  _renderContent = () => {
    // const { docTypeIndex, docTypeTaxInvoiceChecked, paymentStatusIndex, shippingStatusIndex } = this.state
    const { selectedDoctypeIndex, isTaxInvoiceDoctype, paymentStatusIndex, shippingStatusIndex, selectedDoctypeOption } = this.state

    // const isForcedPaymentSuccessStatus = selectedDoctypeOption && selectedDoctypeOption.id === 14
    const isForcedPaymentSuccessStatus = false // && selectedDoctypeOption.id === 14
    return (
      <VStack w='full' p='1'>
        <XCard>
          <VStack w='full' py='3' px='2.5' space='2'>
            {this._renderErpChannel()}

            <VStack w='full'>
              <XText variant='inactive'>ประเภทเอกสาร</XText>
              {/* <HStack w='full' alignItems='center' space='2'>
                <HStack alignItems='center' space='1'>
                  <Radio isChecked={docTypeIndex === 0} onPress={() => this._onDocTypeRadioPress(0)} />
                  <XText>ใบเสร็จรับเงิน</XText>
                </HStack>

                {docTypeIndex === 0 && (
                  <HStack alignItems='center' space='1'>
                    <Checkbox isChecked={docTypeTaxInvoiceChecked[0]} onPress={() => this._onTaxInvoiceCheckboxToggle(0)} />
                    <XText>ออกใบกำกับภาษี</XText>
                  </HStack>
                )}
              </HStack> */}

              {/* <HStack w='full' alignItems='center' space='2'>
                <HStack alignItems='center' space='1'>
                  <Radio isChecked={docTypeIndex === 1} onPress={() => this._onDocTypeRadioPress(1)} />
                  <XText>ใบแจ้งหนี้</XText>
                </HStack>

                {docTypeIndex === 1 && (
                  <HStack alignItems='center' space='1'>
                    <Checkbox isChecked={docTypeTaxInvoiceChecked[1]} onPress={() => this._onTaxInvoiceCheckboxToggle(1)} />
                    <XText>ออกใบกำกับภาษี</XText>
                  </HStack>
                )}
              </HStack> */}

              <ErpDocTypeSelector
                visibleErpDocTypeIds={VISIBLE_ERP_DOCTYPE_IDS}
                selectedIndex={selectedDoctypeIndex}
                isTaxInvoiceChecked={isTaxInvoiceDoctype}
                onOptionChange={this._onErpDocTypeOptionChange}
              />
            </VStack>

            {/* <VStack w='full'>
              <HStack w='full' space='1.5'>
                <XText>{`สถานะการชำระ${isForcedPaymentSuccessStatus ? ':' : ''}`}</XText>
                {isForcedPaymentSuccessStatus && (
                  <XText variant='success' bold>
                    ชำระครบถ้วน
                  </XText>
                )}
              </HStack>

              {!isForcedPaymentSuccessStatus && (
                <XSegment
                  options={[
                    { label: 'ทั้งหมด', value: 0 },
                    { label: 'ชำระครบถ้วน', value: 1 },
                    { label: 'ชำระไม่ครบถ้วน', value: 2 },
                  ]}
                  selectedIndex={paymentStatusIndex}
                  onSegmentChange={(opt, idx) => this.setState({ paymentStatusIndex: idx })}
                />
              )}
            </VStack> */}

            {/* <VStack w='full'>
              <XText>สถานะการจัดส่ง</XText>
              <XSegment
                options={[
                  { label: 'ทั้งหมด', value: 0 },
                  { label: 'จัดส่งครบถ้วน', value: 1 },
                  { label: 'จัดส่งไม่ครบถ้วน', value: 2 },
                ]}
                selectedIndex={shippingStatusIndex}
                onSegmentChange={(opt, idx) => this.setState({ shippingStatusIndex: idx })}
              />
            </VStack> */}

            <VStack w='full'>
              <XText variant='inactive'>เทมเพลตตัวกรองออเดอร์</XText>
              {this._renderOrderListFilterTemplateSelector()}
            </VStack>
          </VStack>
        </XCard>
      </VStack>
    )
  }

  _onSelectFilterTemplate = async (nTmpl?: IOrderListFilterTemplate) => {
    if (_.isNil(nTmpl)) {
      await util.setStatePromise(this, { selectedFilterTemplate: null })
      p.op.showToast('เลิกใช้งานนเทมเพลตตัวกรองออเดอร์', 'success')
      return
    }

    await util.setStatePromise(this, { selectedFilterTemplate: nTmpl })
    p.op.showToast('ใช้งานเทมเพลตตัวกรองออเดอร์แล้ว', 'success')
  }

  _renderOrderListFilterTemplateSelector = () => {
    const { navigation } = this.props
    const { selectedFilterTemplate } = this.state

    return (
      <VStack w='full' p='1' space='1' bg='white'>
        <OrderListFilterTemplateSelector
          type='button'
          navigation={navigation}
          selectedTemplate={selectedFilterTemplate}
          onSelect={this._onSelectFilterTemplate}
        />
      </VStack>
    )
  }

  _renderFooter = () => {
    const { selectedDoctypeOption, isTaxInvoiceDoctype } = this.state
    return (
      <HStack w='full' px='2' py='1' alignItems='center' justifyContent='center'>
        <XButton w='full' h='9' onPress={this._navToERPSubmitDocumentOrderListView}>
          <XIcon name='upload' family='AntDesign' color={COLORS.WHITE} />
          <XText color={COLORS.WHITE}>{`เลือกออเดอร์เพื่อสร้าง ${selectedDoctypeOption.label}${
            isTaxInvoiceDoctype ? '/ใบกำกับภาษี' : ''
          }`}</XText>
        </XButton>
      </HStack>
    )
  }

  render() {
    const { isInitialized } = this.state

    if (!isInitialized) {
      return null
    }

    return (
      <XContainer>
        {this.renderCustomHeader()}
        <XContent>{this._renderContent()}</XContent>
        {this._renderFooter()}
      </XContainer>
    )
  }
}

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
  }),
  (dispatch) => ({
    dispatch,
    updateSelectedStoreByKey: bindActionCreators(StoreState.updateSelectedStoreByKey, dispatch),
  })
)(ERPSubmitDocumentView)
