/* eslint-disable react/no-unused-state */
import React from 'react'
// import { NavigationState } from 'react-navigation'
// import { NavigationStackProp } from 'react-navigation-stack'
import { IStorePaymentAccountListViewProps, IStorePaymentAccountListViewState } from 'x/index'
import { fetchPaymentAccounts } from 'x/utils/api'
import * as util from 'x/utils/util'
// const ICON_MY_STORE = require('../../../../../xui/x/img/tb/tb_my0.png')
// const PULL_VOLUMN_DISCOUNT_ICON_IMAGE = require('../../../../../xui/x/img/volumn_discount.png')

export default class BaseStorePaymentAccountListView extends React.Component<
  IStorePaymentAccountListViewProps,
  IStorePaymentAccountListViewState
> {
  // DISPLAY_TABS: IUserListTab[]
  // _tabRef: React.RefObject<any>
  // helperProfileId: number

  isRefreshing?: boolean

  constructor(props) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    super(props)
    this.state = {
      paymentAccounts: [],

      isInitialized: false,
      isRefreshing: false,
    }
    // this._tabRef = React.createRef()
  }

  async componentDidMount() {
    await this._initBankAccounts()
  }

  getTargetStoreId = (): number => {
    const { navigation, selectedStore } = this.props
    return util.getNavParam(this.props, 'targetStoreId', selectedStore.get('id'))
  }

  getHeaderTitle = (): string => {
    const { navigation } = this.props
    return util.getNavParam(this.props, 'headerTitle', 'บัญชีรับชำระ')
  }

  isGoBackButtonHidden = (): boolean => {
    const { navigation } = this.props
    return util.getNavParam(this.props, 'isGoBackButtonHidden', false)
  }

  isAddButtonHidden = (): boolean => {
    const { navigation } = this.props
    let isAddButtonHidden = util.getNavParam(this.props, 'isAddButtonHidden', false)

    // ถ้าตั้งใจซ่อนอยู่แล้ว ก็ซ่อนเลย
    if (isAddButtonHidden) {
      return true
    }

    // ถ้าเป็นร้านฉัน ไม่ต้องซ่อนก็ได้ แต่ถ้าไม่ใช่ ต้องซ่อน
    const canAddPaymentAccount = this.isTargetedMyStore()
    isAddButtonHidden = !canAddPaymentAccount

    return isAddButtonHidden
  }

  isTargetedMyStore = () => {
    const { selectedStore } = this.props
    return this.getTargetStoreId() === selectedStore.get('id')
  }

  goBack = () => {
    util.navGoBack(this.props)
  }

  fetchPaymentAccounts = async () => {
    if (this.isRefreshing) {
      return
    }
    this.isRefreshing = true
    await util.setStatePromise(this, { isRefreshing: true })

    // const apiOptions: IApiOptions = {
    //   showSpinner: true,
    //   // @ts-ignore :: goback แบบไม่สนใน error object
    //   onUserCloseAlert: this.goBack,
    // }

    const reqBody = {
      store_id: this.getTargetStoreId(),
    }

    try {
      // const res: { payment_accounts: IPaymentAccount[] } = await api.post(api.POST_BANKACCOUNTS, reqBody, apiOptions)
      // if (res.payment_accounts) {
      //   await util.setStatePromise(this, { paymentAccounts: res.payment_accounts })
      // }

      const paymentAccounts = await fetchPaymentAccounts(reqBody)
      await util.setStatePromise(this, { paymentAccounts })
    } catch (err) {
      console.log('err => ', err)
    }

    await util.setStatePromise(this, { isRefreshing: false })
    this.isRefreshing = false
  }

  _initBankAccounts = async () => {
    await this.fetchPaymentAccounts()
    await util.setStatePromise(this, { isInitialized: true })
  }
}
