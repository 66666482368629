import produce, { Draft } from 'immer'
import { IMkpProductDetail, IXSellyErrorResponse, IProductDetailItem } from 'x/index'

export enum ACTIONS {
  INIT = 'INIT',
  DESTROY = 'DESTROY',
  SET_MKP_PRODUCT = 'SET_MKP_PRODUCT',
  SET_CORE_PRODUCT = 'SET_CORE_PRODUCT',
  SET_IS_LOADING = 'SET_IS_LOADING',
  SET_ERROR = 'SET_ERROR',
}

export type ActionType =
  | { type: ACTIONS.INIT; key: string; options?: Partial<IMkpProductStateByKey> }
  | { type: ACTIONS.DESTROY; key: string }
  | { type: ACTIONS.SET_MKP_PRODUCT; key: string; newMkpProduct: IMkpProductDetail }
  | { type: ACTIONS.SET_CORE_PRODUCT; key: string; newCoreProduct: IProductDetailItem }
  | { type: ACTIONS.SET_IS_LOADING; key: string; newValue: boolean }
  | { type: ACTIONS.SET_ERROR; key: string; newError: IXSellyErrorResponse }

export interface IMkpProductStateByKey {
  mkpProduct?: IMkpProductDetail
  isLoading: boolean
  error?: IXSellyErrorResponse

  // Addition info for core product handling
  coreProduct?: IProductDetailItem
  onSuccesssMapping?: () => void
}

export interface IMkpProductState {
  [key: string]: IMkpProductStateByKey
}

const emptyMkpProductByKey: IMkpProductStateByKey = {
  mkpProduct: null,
  isLoading: false,
  error: null,
}

// Define the initial state of our app
export const initialState: IMkpProductState = {}

// Define a pure function reducer
export const reducer = produce((draft: Draft<IMkpProductState>, action: ActionType) => {
  switch (action.type) {
    case ACTIONS.INIT: {
      const { key, options = {} } = action
      if (key) {
        // @ts-ignore
        draft[action.key] = { ...emptyMkpProductByKey, ...options }
      }
      return
    }
    case ACTIONS.DESTROY: {
      const { key } = action
      if (key && draft[key]) {
        delete draft[key]
      }
      return
    }

    case ACTIONS.SET_MKP_PRODUCT: {
      const { key, newMkpProduct } = action
      if (key && draft[key]) {
        draft[key].mkpProduct = newMkpProduct
      }
      return
    }

    case ACTIONS.SET_CORE_PRODUCT: {
      const { key, newCoreProduct } = action
      if (key && draft[key]) {
        draft[key].coreProduct = newCoreProduct
      }
      return
    }
    case ACTIONS.SET_IS_LOADING: {
      const { key, newValue } = action
      if (key && draft[key]) {
        draft[key].isLoading = newValue
      }
      return
    }
    case ACTIONS.SET_ERROR: {
      const { key, newError } = action
      if (key && draft[key]) {
        draft[key].error = newError
      }
      return
    }

    default: {
      throw new Error('No action case on InfiniteList Reducer')
    }
  }
})
