import React from 'react'
import { View, LayoutChangeEvent } from 'react-native'

import { S } from 'x/config/styles'
import { IBarcodeScannerModalProps, IBarcodeScannerOutput } from 'x/index'
import p from 'x/config/platform-specific'

import * as util from 'x/utils/util'
import XModal from './XModal'
import XContent from './XContent'
import BarcodeScannerWithSegment from './BarcodeScannerWithSegment'
import XIconButton from './XIconButton'

const BarcodeScannerModal: React.FC<IBarcodeScannerModalProps> = (props) => {
  const [isCameraReady, setIsCameraReady] = React.useState(false)
  const [contentContainerSize, setContentContainerSize] = React.useState<{ width: number; height: number }>(null)

  React.useEffect(() => {
    if (!props.visible) {
      setIsCameraReady(false)
      setContentContainerSize(null)
    }
  }, [props.visible])

  const _onContentLayout = (evt: LayoutChangeEvent) => {
    // console.log('_onContentLayout evt => ', evt)
    setContentContainerSize(evt.nativeEvent.layout)
  }
  const _onBarcodeScanned = async (output: IBarcodeScannerOutput) => {
    await props.onBarcodeScanned(output)
    await util.delay(100)
    props.onRequestClose()
  }

  const _renderCloseButton = () => (
    <XIconButton
      name='close-circle-outline'
      size='lg'
      // style={[S.WIDTH_44, S.HEIGHT_44, S.ROW_CENTER]}
      onPress={props.onRequestClose}>
      {/* <XIcon
        style={{
          fontSize: Math.round(STYLES.FONT_ICON_LARGEST * 1.5),
          // width: Math.round(STYLES.FONT_ICON_LARGEST * 2) + 4,
          // height: Math.round(STYLES.FONT_ICON_LARGEST * 2) + 4,
          color: COLORS.APP_MAIN,
          fontWeight: 'bold',
          textAlign: 'center',
          textAlignVertical: 'center',
        }}
      /> */}
    </XIconButton>
  )

  if (!props.visible) {
    return null
  }

  return (
    <XModal
      // style={{ backgroundColor: 'green' }}
      // contentStyle={{}}
      webWrapperContainerStyle={{ backgroundColor: 'white' }}
      visible={props.visible}
      webNumColumns={1}
      // hasCloseButton={true}
      title={props.headerTitle ? props.headerTitle : 'สแกนบาร์โค้ด'}
      onRequestClose={props.onRequestClose}>
      <XContent
        scrollEnabled={false}
        // style={[p.op.isWeb() ? S.HEIGHT_FULL : {}]}
        contentContainerStyle={[S.PADDING_8]}
        onLayout={_onContentLayout}>
        {props.visible && contentContainerSize ? (
          <BarcodeScannerWithSegment
            {...props}
            onCameraReady={() => setIsCameraReady(true)}
            onBarcodeScanned={_onBarcodeScanned}
            cameraContainerHeight={Math.floor(contentContainerSize.height - 120 - 16)}
            cameraContainerWidth={Math.floor(contentContainerSize.width - 16)}
          />
        ) : null}
        {!p.op.isWeb() && contentContainerSize && contentContainerSize.height && isCameraReady ? (
          <View style={[S.WIDTH_FULL, S.ROW_CENTER, S.PADDING_12]}>{_renderCloseButton()}</View>
        ) : null}
      </XContent>
      {!p.op.isWeb() && (!isCameraReady || !contentContainerSize || !contentContainerSize.height) ? (
        <View
          style={[
            S.WIDTH_FULL,
            S.ROW_CENTER,
            {
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              height: 122,
              //  backgroundColor: 'rgba(22,22,22,0.5)',
            },
          ]}>
          {_renderCloseButton()}
        </View>
      ) : null}
    </XModal>
  )
}

export default BarcodeScannerModal
