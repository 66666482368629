import React from 'react'
import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'
import { ISelectedStoreMap, IProfileMap, IXScreenProps } from 'x/index'

interface IBaseSettingEmailProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
  profile: IProfileMap
  dispatch?: (param?: any) => void
}

interface IBaseSettingEmailState {
  refreshing: boolean
  txtNewEmail: string
}
export default abstract class BaseSettingEmail extends React.Component<IBaseSettingEmailProps, IBaseSettingEmailState> {
  // abstract async handleSubmitForm(response: any): Promise<void>
  // abstract didMountCheckPermissionForPhone?: () => void
  // validationSchema: Yup.ObjectSchema<any>
  txtNewEmailRef: React.RefObject<any>

  constructor(props) {
    super(props)
    this.state = {
      refreshing: false,
      txtNewEmail: '',
    }
    this.txtNewEmailRef = React.createRef()
  }

  componentDidMount() {
    this.configTxtNewEmailRef()
    // await this._onRefresh()
  }

  configTxtNewEmailRef = () => {
    if (this.txtNewEmailRef && this.txtNewEmailRef.current) {
      setTimeout(() => this.txtNewEmailRef.current.focus(), 200)
    }
  }

  onChangeNewEmailText = (newText: string) => {
    this.setState({ txtNewEmail: newText.trim() })
  }

  _setRefreshing = async () => {
    await util.setStatePromise(this, {
      refreshing: true,
    })
  }

  _unSetRefreshing = async () => {
    await util.setStatePromise(this, {
      refreshing: false,
    })
  }

  _submitFunction = async () => {
    const { navigation, dispatch } = this.props
    const { txtNewEmail, refreshing } = this.state
    if (refreshing) {
      return
    }
    await this._setRefreshing()
    if (!util.isEmailValidFormat(txtNewEmail)) {
      p.op.showConfirmationOkOnly('', 'รูปแบบอีเมลไม่ถูกต้อง')
      return
    }
    const body = {
      email: txtNewEmail,
    }
    const successCallback = () => {
      util.navGoBack(this.props)
    }

    dispatch({
      type: 'PROFILE/USER_PROFILE_CHANGE_EMAIL',
      payload: body,
      successCallback,
    })
    await this._unSetRefreshing()
  }
}
