import React from 'react'
import { Clipboard, ViewStyle } from 'react-native'
// import p from 'x/config/platform-specific'
import { IPaymentAccount, ISelectedStoreMap } from 'x/index'
import { connect } from 'react-redux'
import { getSelectedStore } from 'x/redux/selectors'

import CONS from 'x/config/constants'
import p from 'x/config/platform-specific'
import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import { COLORS } from 'x/config/styles'
import BankIcon from './BankIcon'
import XCard from './XCard'
import XText from './XText'
import XIcon from './XIcon'
import XButton from './XButton'
import VerifySlipIcon from './VerifySlipIcon'

interface IPaymentAccountCardProps {
  containerStyle?: ViewStyle
  data: IPaymentAccount
  showForwardIcon?: boolean
  onPress?: (pressedPaymentAccount: IPaymentAccount) => void

  selectedStore: ISelectedStoreMap
}

export function getBankNameFromBankID(bankId) {
  const foundBankIndex = CONS.BANK_INFO.findIndex((bi) => bi.id === bankId)
  const bankNameKey = foundBankIndex > -1 ? CONS.BANK_INFO[foundBankIndex].key : CONS.BANK_INFO[0].key
  return p.op.t(`Bank.${bankNameKey}`)
}

export const PaymentAccountCard: React.FC<IPaymentAccountCardProps> = ({
  data,
  containerStyle = {},
  showForwardIcon = false,
  onPress,
  selectedStore,
}) => {
  if (!data || !data.bank_id) {
    return null
  }

  // const s_use_verify_slip = selectedStore.get('s_use_verify_slip') || false

  const bankName = getBankNameFromBankID(data.bank_id)
  const bankNumber = data.account_number || null
  // const isVerifySlipEnabled = data.is_for_verify_slip || false
  const isVerifySlipEnabled = data.is_for_verify_slip || false
  const isVerifySlipActive = data.is_verify_slip_active || false

  const _onPressCopyBankAccountNumber = () => {
    Clipboard.setString(bankNumber)
    p.op.showToast(`คัดลอกเลขบัญชี ${bankNumber} เรียบร้อยแล้ว`, 'success', 5000)
  }

  const _onPressCard = () => {
    onPress(data)
  }

  return (
    <XCard
      onPress={typeof onPress === 'function' ? _onPressCard : undefined}
      w='full'
      minH='62px'
      style={containerStyle}
      borderTopColor={isVerifySlipEnabled ? '#004071' : 'gray.300'}
      borderLeftColor={isVerifySlipEnabled ? '#004071' : 'gray.300'}
      borderBottomColor={isVerifySlipEnabled ? COLORS.GB_PAY_MAIN : 'gray.400'}
      borderRightColor={isVerifySlipEnabled ? COLORS.GB_PAY_MAIN : 'gray.400'}
      // borderColor={isVerifySlipEnabled ? COLORS.GB_PAY_MAIN : undefined}
    >
      <HStack w='full' p='1.5' space='1'>
        <VStack flex={1} space='1'>
          <HStack w='full' space='1.5'>
            {/* <XText>{data.bank_id}</XText> */}

            <VStack w='42px' alignItems='center'>
              <BankIcon
                bankId={data.bank_id}
                // style={S.MARGIN_HORIZONTAL_6}
              />
            </VStack>

            <VStack flex={1} minH='9' space='1' flexWrap='wrap'>
              {/* // ธนาคาร // */}
              <HStack>
                <XText numberOfLines={1} variant='inactive'>
                  {bankName}
                </XText>
              </HStack>

              <HStack>
                <XText numberOfLines={1}>{data.name}</XText>
              </HStack>

              {bankNumber ? (
                <XButton
                  variant='outline'
                  minW='160px'
                  maxW='180px'
                  borderColor={isVerifySlipEnabled ? COLORS.GB_PAY_MAIN : undefined}
                  onPress={_onPressCopyBankAccountNumber}
                  // style={{
                  //   borderColor: COLORS.APP_MAIN,
                  //   borderRadius: 4,
                  //   borderWidth: 1,
                  //   paddingLeft: 8,
                  //   paddingRight: 8,
                  //   backgroundColor: COLORS.BG_LIGHT_GREY,
                  //   minWidth: 80,
                  // }}
                >
                  {/* // เลขบัญชี // */}
                  <XText numberOfLines={1} variant='primary' color={isVerifySlipEnabled ? COLORS.GB_PAY_MAIN : undefined}>
                    {bankNumber}
                  </XText>
                </XButton>
              ) : (
                <XText>-</XText>
              )}
            </VStack>
          </HStack>

          {isVerifySlipEnabled ? (
            <HStack w='full' space='1.5' alignItems='center'>
              <VerifySlipIcon blue={isVerifySlipActive} inactive={!isVerifySlipActive} />

              <VStack flex={1}>
                <XText fontSize='xs' bold color={isVerifySlipEnabled && isVerifySlipActive ? COLORS.GB_PAY_MAIN : COLORS.TEXT_INACTIVE}>
                  รองรับการตรวจสลิปอัตโนมัติ
                </XText>
                {!isVerifySlipActive ? (
                  <XText fontSize='xs' bold color={COLORS.TEXT_INACTIVE}>
                    แต่ยังไม่ได้เปิดใช้งาน
                  </XText>
                ) : null}
              </VStack>
            </HStack>
          ) : null}
        </VStack>

        {showForwardIcon ? (
          <VStack w='32px' justifyContent='center' alignItems='center'>
            <XIcon name='arrow-forward' color={isVerifySlipEnabled ? COLORS.GB_PAY_MAIN : undefined} />
          </VStack>
        ) : null}
      </HStack>
    </XCard>
  )
}

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
  }),
  (dispatch) => ({
    dispatch,
  })
)(PaymentAccountCard)
