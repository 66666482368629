import { bindActionCreators } from 'redux'
import * as shipActions from './ShipmentState'
import { getSelectedStore } from '../../redux/selectors'

export function getState(state) {
  return {
    selectedStore: getSelectedStore(state),
    selectedOrder: state.getIn(['shipment', 'quickship', 'selectedOrder']),
    shipmentData: state.getIn(['shipment', 'quickship', 'shipmentData']),
    orderCameraState: state.getIn(['shipment', 'quickship', 'cameraStates', 'order']),
    shipCameraState: state.getIn(['shipment', 'quickship', 'cameraStates', 'ship']),
  }
}

export function getDispatch(dispatch) {
  return {
    fetchOrderFromBarcode: bindActionCreators(shipActions.fetchOrderFromBarcode, dispatch),
    qsTrackingNumberLoad: bindActionCreators(shipActions.qsTrackingNumberLoad, dispatch),
    qsCameraStateChange: bindActionCreators(shipActions.qsCameraStateChange, dispatch),
    qsShip: bindActionCreators(shipActions.qsShip, dispatch),
    qsReset: bindActionCreators(shipActions.qsReset, dispatch),
  }
}
