import React from 'react'
import _ from 'lodash'
import { List } from 'immutable'

import * as NavActions from 'x/utils/navigation'
import { STYLES } from 'x/config/styles'

import {
  IProductListViewProps,
  IProductListViewState,
  IProductListViewMkpViewParams,
  ImmutableObject,
  IMKPChannelDetail,
  // IMKPProductItemData,
  IMkpProductListItem,
} from 'x/index'

import VStack from 'xui/components/VStack'
import Box from 'xui/components/Box'
import HStack from 'xui/components/HStack'
import XText from 'xui/components/XText'
import XIconButton from 'xui/components/XIconButton'
import * as util from 'x/utils/util'
import BaseUIProductListView from './BaseUIProductListView'
import MkpLogo from '../../components/MkpLogo'

// import { withMkpProductContextPropsAndRef } from '../marketplace/MkpProductContextHOC'
// import { IMkpProductContextProps } from '../marketplace/MkpProductContext'
// import { connect } from 'react-redux'
// import { getSelectedStore, getSelectedProduct } from 'x/redux/selectors'

interface IProductListViewMkpViewProps extends IProductListViewProps<IProductListViewMkpViewParams> {
  mkpChannels: List<ImmutableObject<IMKPChannelDetail>>
}

class ProductListViewMkpView extends BaseUIProductListView<IProductListViewMkpViewProps, IProductListViewState, IMkpProductListItem> {
  static displayName = 'ProductListViewMkpView'

  // override
  getApiEndpoint = () => 'mkp/v2/products'

  // true
  isGoApi = () => true

  isOmitStockQty = () => true

  // // override
  // isProductListItemPressDisabled = () => true

  // override
  isProductListItemSellerIconDisabled = () => true

  // override
  isProductListItemPriceDisabled = () => true

  // override
  isProductListItemQtyDisabled = () => true

  // override
  isRequiredProductVariants = () => false

  // override
  isAddProductButtonVisible = () => false

  // override
  isUpcSearchAvailable = () => false

  // override
  isSkuSearchAvailable = () => false

  // override
  onPressProductItem = (item: { index: number; data: IMkpProductListItem }) => {
    this._navToMkpProductDetailView(item.data.uuid)
  }

  // override
  getModifiedRequestBody = (body) => {
    const { navigation, selectedStore } = this.props
    const myStoreId = util.getNavParam(this.props, 'store_id')
    // const mkpChannelId = util.getNavParam(this.props, 'mkp_channel_id')
    const mkpChannelId = util.getNavParam(this.props, 'mkp_ch_id')
    const mkpId = util.getNavParam(this.props, 'mkp_id')
    const newBody = _.cloneDeep(body)
    newBody.store_id = myStoreId
    // newBody.mkp_channel_id = mkpChannelId
    newBody.mkp_ch_id = mkpChannelId
    newBody.mkp_id = mkpId
    delete newBody.sortBy
    delete newBody.sortType
    if (mkpId === 4) {
      newBody.sort_by = 'item_id'
    } else {
      newBody.sort_by = 'updated_at'
    }
    newBody.sort_type = 'desc'

    if (newBody.c) {
      delete newBody.c
      newBody.count = true
    }

    return newBody
  }

  // override
  renderFilterProductsButton = () => null

  // override
  getHaveNoProductItemText = () => 'ไม่พบรายการสินค้า'

  // override
  renderProductListItemIcon = () => {
    const mkpId = util.getNavParam(this.props, 'mkp_id')
    return <MkpLogo mkpId={mkpId} width={24} height={24} />
  }

  // override
  fetchSearchProducts = async ({ offset, limit }) => {
    type FetchedData = {
      count?: number
      items: IMkpProductListItem[]
    }
    let requestBody = this.getCommonRequestBody({ offset, limit })
    requestBody.query_text = this.state.searchText
    requestBody = this.getModifiedRequestBody(requestBody)
    const fetchedData: FetchedData = await this.doFetchProducts(requestBody)

    await this.onAfterFetchSeachProducts(fetchedData, requestBody)

    return fetchedData
  }

  _navToMkpProductDetailView = async (item_uuid: string) => {
    // console.log('_navToMkpProductDetailView item_uuid => ', item_uuid)
    const { navigation } = this.props
    const storeId = util.getNavParam(this.props, 'store_id')
    // const mkpChannelId = util.getNavParam(this.props, 'mkp_channel_id')
    const mkpChannelId = util.getNavParam(this.props, 'mkp_ch_id')
    const mkpId = util.getNavParam(this.props, 'mkp_id')
    navigation.dispatch(
      NavActions.navToMkpProductDetailView({
        store_id: storeId,
        // mkp_channel_id: mkpChannelId,
        mkp_ch_id: mkpChannelId,
        item_uuid,
        mkp_id: mkpId,
      })
    )
  }

  _renderSelectedMkpStoreBar = () => {
    const { navigation, mkpChannels } = this.props
    // const mkpChannelId = util.getNavParam(this.props, 'mkp_channel_id')
    // const mkpChannel = mkpChannels.find((mch) => mch.get('id') === mkpChannelId)
    // const mkpChaneelName = mkpChannel.get('name')
    // const mkpId = mkpChannel.get('mkp_id')
    // return (
    //   <View style={[S.CARDLIKE_BORDERS, S.BG_WHITE, { borderTopWidth: 0, alignItems: 'center', justifyContent: 'center', width: '100%' }]}>
    //     {/* <View style={[S.CARDLIKE_BODY]}>
    //       <View style={[S.PADDING_VERTICAL_4, { flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }]}>
    //         <MkpLogo mkpId={mkpId} width={STYLES.FONT_ICON_NORMAL} height={STYLES.FONT_ICON_NORMAL} />
    //         <Text style={[S.TEXT_ACTIVE, { paddingLeft: 4 }]}>{mkpChaneelName}</Text>
    //       </View>
    //     </View> */}
    //     <View style={[S.CARDLIKE_BODY, S.ROW_CENTER, { height: 54, paddingBottom: 4 }]}>
    //       {/* <View style={[S.ROW_CENTER, { width: 44 }]}>
    //         <XIcon
    //           inactive
    //           name='search'
    //           style={{ flex: 0, flexBasis: 'auto', width: STYLES.FONT_ICON_NORMAL, height: STYLES.FONT_ICON_NORMAL }}
    //         />
    //       </View> */}
    //       {this.renderSearchBar()}
    //     </View>
    //   </View>
    // )
    return (
      <HStack w='full' p='2'>
        {this.renderSearchBar()}
      </HStack>
    )
  }

  _renderHeaderTitleBar = () => {
    const { navigation, mkpChannels } = this.props
    // const mkpChannelId = util.getNavParam(this.props, 'mkp_channel_id')
    const mkpChannelId = util.getNavParam(this.props, 'mkp_ch_id')
    const mkpChannel = mkpChannels.find((mch) => mch.get('id') === mkpChannelId)
    // const mkpChaneelName = mkpChannel.get('name')
    const mkpId = mkpChannel.get('mkp_id')

    if (!mkpId) {
      return null
    }

    const mkpChannelName = util.getMKPChannelName(mkpChannelId)
    // return (
    //   <View style={[S.CARDLIKE_BODY, { flexDirection: 'row' }]}>
    //     <View style={[S.PADDING_4, { alignItems: 'center', justifyContent: 'center' }]}>
    //       <MkpLogo mkpId={mkpId} width={STYLES.FONT_ICON_LARGEST} height={STYLES.FONT_ICON_LARGEST} />
    //     </View>
    //     <View style={[S.PADDING_4, { height: '100%', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }]}>
    //       <Text style={S.TEXT_INACTIVE}>{'เลือกสินค้าจากรายการของช่องทางขาย'}</Text>
    //       <Text style={S.TEXT_ACTIVE}>{mkpChaneelName}</Text>
    //     </View>
    //   </View>
    // )
    return (
      <HStack w='full' minH='10' p='2' space='1'>
        <XIconButton name='arrow-back' onPress={this.goBack} />
        <HStack flex={1} space='2' alignItems='center'>
          <MkpLogo mkpId={mkpId} width={STYLES.FONT_ICON_LARGER} height={STYLES.FONT_ICON_LARGER} />
          <XText flex={1} bold numberOfLines={2}>
            {`รายการสินค้าจาก ${mkpChannelName}`}
          </XText>
        </HStack>

        <Box w='10'>
          {/* <TouchableOpacity style={S.BUTTON_ICON}>
            <XIcon name='search' family='FontAwesome' size={STYLES.FONT_ICON_NORMAL} />
          </TouchableOpacity> */}
        </Box>
      </HStack>
    )
  }

  // overrdie
  getSearchTextWithCountPlaceholder = (mainProductCount: number) => {
    const { navigation, mkpChannels } = this.props
    // const mkpChannelId = util.getNavParam(this.props, 'mkp_channel_id')
    const mkpChannelId = util.getNavParam(this.props, 'mkp_ch_id')
    const mkpChannel = mkpChannels.find((mch) => mch.get('id') === mkpChannelId)
    const mkpChaneelName = mkpChannel.get('name')
    // const mkpId = mkpChannel.get('mkp_id')
    return `${mainProductCount} สินค้า ที่พร้อมค้นหาใน ${mkpChaneelName}`
  }

  // override
  getSearchTextWithoutCountPlaceholder = () => {
    const { navigation, mkpChannels } = this.props
    // const mkpChannelId = util.getNavParam(this.props, 'mkp_channel_id')
    const mkpChannelId = util.getNavParam(this.props, 'mkp_ch_id')
    const mkpChannel = mkpChannels.find((mch) => mch.get('id') === mkpChannelId)
    const mkpChannelName = mkpChannel.get('name')
    // const mkpId = mkpChannel.get('mkp_id')
    return `ไม่พบรายการสินค้าพร้อมใช้งานใน ${mkpChannelName}`
  }

  renderContent = () => {
    const { navigation, mkpChannels } = this.props
    // const mkpChannelId = util.getNavParam(this.props, 'mkp_channel_id')
    // const mkpChannel = mkpChannels.find((mch) => mch.get('id') === mkpChannelId)
    // const mkpChaneelName = mkpChannel.get('name')
    // const mkpId = mkpChannel.get('mkp_id')

    return (
      <VStack flex={1} w='full'>
        {this._renderHeaderTitleBar()}
        {/* {this.renderCustomHeader()} */}
        {this._renderSelectedMkpStoreBar()}
        {this.renderWatingSearchTypingInfo()}
        <VStack w='full' flex={1} _light={{ bg: 'coolGray.200' }} _dark={{ bg: 'warmGray.700' }}>
          {this.renderMainList()}
          {this.renderSearchList()}
        </VStack>
      </VStack>
    )
  }
}

export default ProductListViewMkpView
