import { List, Map, fromJS } from 'immutable'
import _ from 'lodash'
import dayjs, { Dayjs } from 'dayjs'
import { IBaseFetchOrdersParams } from 'x/index'
import CONS from '../../config/constants'
// import { getOrdersToBePaidInPayment, setOrdersToBePaidInPayment } from '../../redux/selectors'
// import { getOrdersToBePaidInOrders, setOrdersToBePaidInOrders } from '../../redux/selectors'
// import SETTINGS from '../../config/settings'
import * as util from '../../utils/util'
import { log } from '../../utils/util'
// import { getPrefFetchOrders } from '../../redux/selectors'
import p from '../../config/platform-specific'
import * as acl from '../../utils/acl'

// import settings from '../../config/settings'
// Initial state
export const initialState = Map({
  tabStatus: Map({
    selectedMainTabKey: CONS.ORDER_KEY_MY_TASKS,
    selectedMyTasksSubTabKey: CONS.ORDER_KEY_CONFIRM_ORDER,
    selectedCustTasksSubTabKey: CONS.ORDER_KEY_PAY,
    selectedResellerTasksSubTabKey: CONS.ORDER_KEY_CONFIRM_ORDER,
    selectedSellerTasksSubTabKey: CONS.ORDER_KEY_CONFIRM_ORDER,
    selectedDoneTasksSubTabKey: CONS.ORDER_KEY_SHIPPED,
  }),
  fetchStatus: Map({}),
  orders: Map(),
  // queriedOrders: Map(),
  summary: Map(),
  shouldFetchAll: false,
  filterSetting: Map({}),
  // ordersToBePaid: Map(),
})

// Actions
export const INIT = 'BaseOrderListState/INIT'
// const SELECT_MAIN_TAB_FOR_KEY = 'OrderListState/SELECT_MAIN_TAB_FOR_KEY'
// export const SELECT_SUB_TAB_FOR_KEY = 'BaseOrderListState/SELECT_SUB_TAB_FOR_KEY'

// Action creators
export function init() {
  return { type: INIT }
}

// export function selectMainTabForKey(mainTabKey) {
//   return { type: SELECT_MAIN_TAB_FOR_KEY, payload: { mainTabKey: mainTabKey } }
// }

export function selectSubTabForKey(mainTabKey, subTabKey, type) {
  return {
    type,
    payload: {
      mainTabKey,
      subTabKey,
    },
  }
}

export function navToOrderDetail(orderId, storeId, isNoInitFetch) {
  return p.nav.navToOrderDetail(orderId, storeId, isNoInitFetch)
  // log('navToOrderDetail', orderId, storeId)
  // return NavActions.navToOrderDetail({ order_id: orderId, store_id: storeId })
}

export function resetTabStatus(type) {
  return { type }
}

export function baseFetchOrders(params: IBaseFetchOrdersParams) {
  const {
    storeId,
    orderStoreId,
    orderType,
    partnerStoreId,

    mode,
    tabName,
    type,
    getOrdersFetchStatusForTabName,
    isRefund,
    filterSetting,
    isAlreadyHasCount = false,
    shouldFetchOnlySingleTabKey = false,
    successCallback,
    failedCallback,

    forceOffset,
    forceLimit,
    sortBy,
    sortType,
  } = params

  // log('BaseOrderListState.fetchOrders')
  // log(getOrdersFetchStatusForTabName)
  log(`baseFetchOrders.fetchOrders.storeId: ${storeId} orderStoreId: ${orderStoreId} mode: ${mode} tabName: ${tabName} type: ${type}`)

  const payload: { [key: string]: any } = {
    store_id: storeId,
  }

  // console.log('baseFetchOrders.fetchOrders params => ', params)
  if (orderStoreId && orderType) {
    // payload.showOnlyPayableToStoreId = orderStoreId
    switch (orderType) {
      case 1: {
        // ออเดอร์ขายลูกค้า -- คืนเงินลูกค้า (ไม่มีบัญชีลูกค้า) / บันทึกรับชำระแทนลูกค้า
        payload.showOnlyPayableToStoreId = orderStoreId
        break
      }
      case 2: {
        // ออเดอร์ขายตัวแทน -- คืนเงินตัวแทน / บันทึกรับชำระแทนตัวแทน
        if (!isRefund) {
          payload.partner_store_id = partnerStoreId
        } else {
          payload.showOnlyPayableToStoreId = isRefund ? partnerStoreId : orderStoreId
        }
        break
      }
      case 3: {
        // ออเดอร์ซื้อ -- คืนเงินทีั่เกิน  / แจ้งชำระ
        payload.showOnlyPayableToStoreId = isRefund ? partnerStoreId : orderStoreId
        break
      }
    }
    // payload.showOnlyPayableToStoreId = isRefund && orderType !== 1 ? partnerStoreId : orderStoreId
  }
  if (isRefund) {
    payload.isRefund = isRefund
  }
  if (Map.isMap(filterSetting)) {
    if (_.isBoolean(filterSetting.get('isCOD'))) {
      payload.isCOD = filterSetting.get('isCOD')
    }
    if (_.isBoolean(filterSetting.get('isShipBeforePay'))) {
      payload.isShipBeforePay = filterSetting.get('isShipBeforePay')
    }
    if (_.isBoolean(filterSetting.get('hasPrintedAt'))) {
      const hasPrintedAt = filterSetting.get('hasPrintedAt')
      // const segmentHasPrintedAtIndex = filterSetting.get('segmentHasPrintedAtIndex') || 0

      // เริ่ม apply filter has_printed_at
      payload.has_printed_at = hasPrintedAt

      // ถ้าหากมีการกรองที่ระบุเวลาเกิดขึ้น
      if (hasPrintedAt) {
        const printedAtFrom = filterSetting.get('printedAtFrom')
        const printedAtTo = filterSetting.get('printedAtTo')
        if (printedAtFrom instanceof dayjs && printedAtTo instanceof dayjs) {
          // apply filter by datetime
          // @ts-ignore
          payload.printed_at_from = printedAtFrom.format(CONS.SERVER_DATETIME_FORMAT)
          // @ts-ignore
          payload.printed_at_to = printedAtTo.format(CONS.SERVER_DATETIME_FORMAT)
        }
      }
    }

    if (_.isString(filterSetting.get('orderSegment'))) {
      payload.order_segment = filterSetting.get('orderSegment')
    }

    // สำหรับ mkp channels
    if (filterSetting.has('mkpChannelIds') && !_.isNil(filterSetting.get('mkpChannelIds'))) {
      payload.ug_ids = filterSetting.get('mkpChannelIds')
    }
    if (filterSetting.has('orderOrigin') && _.isString(filterSetting.get('orderOrigin'))) {
      payload.order_origin = filterSetting.get('orderOrigin')
    }

    // create order
    if (!_.isNil(filterSetting.get('createOrderFrom')) && !_.isNil(filterSetting.get('createOrderTo'))) {
      // @ts-ignore
      payload.created_at_from = filterSetting.get('createOrderFrom').format(CONS.SERVER_DATETIME_FORMAT)
      // @ts-ignore
      payload.created_at_to = filterSetting.get('createOrderTo').format(CONS.SERVER_DATETIME_FORMAT)
    }

    // Selector Shipping Type
    const selectorShippingTypeIds = _.isNil(filterSetting.get('selectorShippingTypeIds'))
      ? null
      : filterSetting.get('selectorShippingTypeIds').toJS()
    if (!_.isNil(selectorShippingTypeIds) && _.isArray(selectorShippingTypeIds) && selectorShippingTypeIds.length > 0) {
      payload.shipping_type_ids = filterSetting.get('selectorShippingTypeIds').toJS()
    }
    // FILTER CREATE BODY
    // สภานะพร้อมส่ง
    // console.log('ABC => ', filterSetting.get('hasReadyToShipDate'))
    if (filterSetting.get('hasReadyToShipDate') === false) {
      payload.has_ready_to_ship_date = false
    } else if (filterSetting.get('hasReadyToShipDate')) {
      const segmentReadyToShipForDateTimeIndex = _.isNil(filterSetting.get('segmentReadyToShipForDateTimeIndex'))
        ? 0
        : filterSetting.get('segmentReadyToShipForDateTimeIndex')
      if (segmentReadyToShipForDateTimeIndex === 0) {
        payload.has_ready_to_ship_date = true
      } else {
        // @ts-ignore
        payload.ready_to_ship_date_from = filterSetting.get('readyToShipDateFrom').format(CONS.SERVER_DATETIME_FORMAT)
        // @ts-ignore
        payload.ready_to_ship_date_to = filterSetting.get('readyToShipDateTo').format(CONS.SERVER_DATETIME_FORMAT)
      }
    }
    // กำหนดส่ง
    if (filterSetting.get('hasDateDelivery') === false) {
      payload.has_date_delivery = false
    } else if (filterSetting.get('hasDateDelivery')) {
      const segmentDateDeliveryIndex = _.isNil(filterSetting.get('segmentDateDeliveryIndex'))
        ? 0
        : filterSetting.get('segmentDateDeliveryIndex')
      if (segmentDateDeliveryIndex === 0) {
        payload.has_date_delivery = true
      } else {
        // @ts-ignore
        payload.date_delivery_from = filterSetting.get('dateDeliveryFrom').format(CONS.SERVER_DATETIME_FORMAT)
        // @ts-ignore
        payload.date_delivery_to = filterSetting.get('dateDeliveryTo').format(CONS.SERVER_DATETIME_FORMAT)
      }
    }
    //  ยกเลิกออเดอร์อัตโนมัติ
    if (filterSetting.get('hasExpirationDate') === false) {
      payload.has_expiration_date = false
    } else if (filterSetting.get('hasExpirationDate')) {
      const segmentAutoCancelOrderIndex = _.isNil(filterSetting.get('segmentAutoCancelOrderIndex'))
        ? 0
        : filterSetting.get('segmentAutoCancelOrderIndex')
      if (segmentAutoCancelOrderIndex === 0) {
        payload.has_expiration_date = true
      } else {
        // @ts-ignore
        payload.expiration_date_from = filterSetting.get('AutoCancelOrderFrom').format(CONS.SERVER_DATETIME_FORMAT)
        // @ts-ignore
        payload.expiration_date_to = filterSetting.get('AutoCancelOrderTo').format(CONS.SERVER_DATETIME_FORMAT)
      }
    }
  }

  // Mock filter
  // payload.order_states = [101, 102, 103, 109]
  // payload.shipping_states = [122]
  // payload.queryTxt = 'order.printed IS NOT NULL'
  // payload.has_printed_at = true
  // payload.has_printed_at = false
  // payload.printed_at_from = '2020-08-03 00:00:00'
  // payload.printed_at_to = '2020-08-03 23:59:59'

  switch (mode) {
    case CONS.ORDERS_FETCH_MODE_INIT: {
      // payload.summary = true
      payload.types_to_count = acl.authorizedOrderListTabs()
      // payload.types = CONS.ORDER_SUMMARY_ALL
      payload.types = acl.authorizedOrderListTabs()
      // payload.types = ['myTasks_confirmOrder']
      // payload.types = _.isString(tabName) ? [tabName] : ['myTasks_confirmOrder']
      payload.offset = 0
      payload.shouldResetTabStatus = true
      break
    }
    case CONS.ORDERS_FETCH_MODE_SUBTAB_INIT: {
      // payload.summary = true
      payload.types = _.isString(tabName) ? [tabName] : ['myTasks_confirmOrder']
      payload.offset = 0
      // if (!isAlreadyHasCount ) {
      payload.types_to_count = acl.authorizedOrderListTabs()
      // }
      break
    }
    case CONS.ORDERS_FETCH_MODE_REFRESH: {
      // payload.summary = true
      payload.types_to_count = acl.authorizedOrderListTabs()
      // payload.types = CONS.ORDER_SUMMARY_ALL
      // payload.types = acl.authorizedOrderListTabs()
      payload.types = _.isString(tabName) ? [tabName] : acl.authorizedOrderListTabs()
      payload.offset = 0
      // TODO: Add flag for refreshing
      break
    }
    case CONS.ORDERS_FETCH_MODE_MORE: {
      if (!tabName) {
        log('Error: ORDERS_FETCH_MODE_MORE: Tab Name is required.')
        // Error. Should never happen. do nothing
        return {}
      }

      const status = getOrdersFetchStatusForTabName(p.op.getAppState(), tabName)
      if (!status) {
        log(`Error: ORDERS_FETCH_MODE_MORE: tab status${tabName} is undefined.`)
        return {}
      }
      log('ORDERS_FETCH_MODE_MORE')
      log(status)
      const { offset } = status // status.get('offset')
      payload.summary = false
      payload.types = [tabName]
      payload.offset = offset || 0
      break
    }
    case CONS.ORDERS_FETCH_MODE_INIT_SINGLE: {
      if (!tabName) {
        log('Error: ORDERS_FETCH_MODE_INIT_SINGLE: Tab Name is required.')
        // Error. Should never happen. do nothing
        return {}
      }
      // payload.summary = true
      payload.types_to_count = acl.authorizedOrderListTabs()
      payload.types = [tabName]
      payload.offset = 0
      payload.shouldResetTabStatus = true
      break
    }
    default:
      return {}
  }

  if (shouldFetchOnlySingleTabKey) {
    payload.types_to_count = [tabName]
  }

  if (forceOffset) {
    payload.offset = forceOffset
  }
  if (forceLimit) {
    payload.limit = forceLimit
  }
  if (sortBy) {
    payload.sortBy = sortBy
  }
  if (sortType) {
    payload.sortType = sortType
  }

  log(`fetchOrder for mode: ${mode}`)
  // console.log('payload => ', payload)
  return { type, payload, mode, successCallback, failedCallback }
}

// // อยู่ในแต่ละฝั่งของ Payment/Store OrderList (น่าจะใช้แค่ Payment)
// export function toggleOrderPaymentSelection(orderId, total) {
//   return {
//     type: actions.ORDERS_TOGGLE_PAYMENT_SELECTION,
//     payload: {
//       orderId: orderId,
//       total: total,
//     },
//   }
// }

function getCurrCountForSubTab(orderState, tabName, getOrdersInOrdersForTabName) {
  const orders = getOrdersInOrdersForTabName(orderState, tabName)
  log(`getCurrCountForSubTab: ${tabName}`)
  // log(orders.toJS())
  if (orders) {
    return orders.count()
  }
  return 0

  // let orders = getOrders(state)
  // if (orders && orders.has(tabName)) {
  //   return orders[tabName].length
  // } else {
  //   return 0
  // }
}

export const reducer = {
  setFilterSetting: (state, newSetting) => {
    return state.set('filterSetting', fromJS(newSetting))
  },
  resetFilterSetting: (state) => {
    return state.set('filterSetting', Map({}))
  },
  setShouldFetchAll: (state) => {
    return state.set('shouldFetchAll', true)
  },
  setShouldFetchSingleTabKey: (state, tabKey) => {
    return state.setIn(['fetchStatus', tabKey, 'shouldFetch'], true)
  },
  setIsInitedTabKey: (state, { tabKey, value = true }) => {
    return state.setIn(['fetchStatus', tabKey, 'isInited'], value)
  },
  resetOrdersTabStatus: (state, action, setOrdersTabStatusInOrders, getOrders) => {
    // find the first tab with available number and preselect it
    const orders = getOrders(state)
    const s = state
    // log('BaseOrderListState.resetOrdersTabStatus')
    // log(state.toJS())
    // log(orders.toJS())
    const T = CONS.ORDER_SUMMARY_ALL
    // @ts-ignore
    const tabStatus = initialState.get('tabStatus').toJS()
    let isSetForMyTasks = false
    let isSetForCustTasks = false
    let isSetForResellerTasks = false
    let isSetForSellerTasks = false
    let isSetForDoneTasks = false
    // let isSetForTasks = {}
    // isSetForTasks[CONS.ORDER_KEY_MY_TASKS] = false
    // isSetForTasks[CONS.ORDER_KEY_CUST_TASKS] = false
    // isSetForTasks[CONS.ORDER_KEY_RESELLER_TASKS] = false
    // isSetForTasks[CONS.ORDER_KEY_SELLER_TASKS] = false
    // isSetForTasks[CONS.ORDER_KEY_DONE_TASKS] = false
    let subTabKey = ''
    // for each tab, set the selected tab to the one with number
    for (let i = 0; i < T.length; i++) {
      const fullTaskName = T[i]
      // log('fullTaskName: ' + fullTaskName)
      if (orders.has(fullTaskName) && orders.get(fullTaskName).count() > 0) {
        const mainTabKey = fullTaskName.split('_')[0]
        // log('mainTabKey: ' + mainTabKey)
        switch (mainTabKey) {
          case CONS.ORDER_KEY_MY_TASKS: {
            if (!isSetForMyTasks) {
              subTabKey = fullTaskName.split('_')[1]
              tabStatus.selectedMyTasksSubTabKey = subTabKey
              isSetForMyTasks = true
            }
            break
          }
          case CONS.ORDER_KEY_CUST_TASKS: {
            if (!isSetForCustTasks) {
              tabStatus.selectedCustTasksSubTabKey = fullTaskName.split('_')[1]
              isSetForCustTasks = true
            }
            break
          }
          case CONS.ORDER_KEY_RESELLER_TASKS: {
            if (!isSetForResellerTasks) {
              tabStatus.selectedResellerTasksSubTabKey = fullTaskName.split('_')[1]
              isSetForResellerTasks = true
            }
            break
          }
          case CONS.ORDER_KEY_SELLER_TASKS: {
            if (!isSetForSellerTasks) {
              tabStatus.selectedSellerTasksSubTabKey = fullTaskName.split('_')[1]
              isSetForSellerTasks = true
            }
            break
          }
          case CONS.ORDER_KEY_DONE_TASKS: {
            if (!isSetForDoneTasks) {
              tabStatus.selectedDoneTasksSubTabKey = fullTaskName.split('_')[1]
              isSetForDoneTasks = true
            }
            break
          }
          default:
            break
        }
      }
    }
    // set the first available main tab
    if (!isSetForMyTasks) {
      if (isSetForCustTasks && tabStatus.selectedCustTasksSubTabKey !== CONS.ORDER_KEY_AUTO_COMPLETE) {
        tabStatus.selectedMainTabKey = CONS.ORDER_KEY_CUST_TASKS
      } else if (isSetForResellerTasks) {
        tabStatus.selectedMainTabKey = CONS.ORDER_KEY_RESELLER_TASKS
      } else if (isSetForSellerTasks) {
        tabStatus.selectedMainTabKey = CONS.ORDER_KEY_SELLER_TASKS
      }
    }
    // log('Updated tabStatus: ', tabStatus)
    return setOrdersTabStatusInOrders(s, fromJS(tabStatus))
  },
  selectSubTabForKey: (state, action, setOrdersTabStatusInOrdersForKey) => {
    const { mainTabKey, subTabKey } = action.payload
    const s = setOrdersTabStatusInOrdersForKey(state, 'selectedMainTabKey', mainTabKey)
    switch (mainTabKey) {
      case CONS.ORDER_KEY_MY_TASKS:
        return setOrdersTabStatusInOrdersForKey(s, 'selectedMyTasksSubTabKey', subTabKey)
      case CONS.ORDER_KEY_CUST_TASKS:
        return setOrdersTabStatusInOrdersForKey(s, 'selectedCustTasksSubTabKey', subTabKey)
      case CONS.ORDER_KEY_RESELLER_TASKS:
        return setOrdersTabStatusInOrdersForKey(s, 'selectedResellerTasksSubTabKey', subTabKey)
      case CONS.ORDER_KEY_SELLER_TASKS:
        return setOrdersTabStatusInOrdersForKey(s, 'selectedSellerTasksSubTabKey', subTabKey)
      case CONS.ORDER_KEY_DONE_TASKS:
        return setOrdersTabStatusInOrdersForKey(s, 'selectedDoneTasksSubTabKey', subTabKey)
      default:
        return state
    }
  },
  handleFetchOrders: (state, action, getOrdersFetchStatusInOrders, getOrdersFetchStatusForTabNameInOrders) => {
    // log('actions.ORDERS_FETCH in reducer!!')
    // set 'loading' flag for each tab to false
    const { types } = action.payload
    // log(types)

    let s = state
    // log('state')
    // log(s.toJS())
    const currFetchStatus = getOrdersFetchStatusInOrders(s)
    // log('currFetchStatus')
    // log(currFetchStatus.toJS())
    if (!currFetchStatus) {
      // no fetch status yet. do nothing
      return s
    }
    types.map((taskType) => {
      // log('setting loading to true for tab ' + taskType)
      const fetchStatusForThisTab = getOrdersFetchStatusForTabNameInOrders(s, taskType)
      // log(fetchStatusForThisTab)
      if (fetchStatusForThisTab) {
        fetchStatusForThisTab.loading = true
        s = s.setIn(['fetchStatus', taskType], fetchStatusForThisTab)
        // log('DONE setting fetchStatus')
      }
      // s = setOrdersLoadingInOrdersForTab(s, taskType, true)
      // log(s.toJS())
    })
    return s
  },
  setOrdersState: (
    state,
    action,
    setOrdersInOrders,
    setBackendTimeInServers,
    setOrdersSummaryInOrders,
    getOrdersSummaryInOrders,
    getOrdersInOrdersForTabName,
    setOrderFetchStatusInOrdersForTab,
    getOrdersFetchStatusInOrders
  ) => {
    const { payload } = action
    // log('action => ', action)
    // log('SET_ORDERS_STATE', state.toJS(), payload.toJS(), payload.get('orders').toJS())
    const orders = payload.get('orders')

    let s = state
    if (setBackendTimeInServers) {
      s = setBackendTimeInServers(state, util.getAllTimes(payload.get('server_time')))
    }

    // if (
    //   _.includes(
    //     [
    //       CONS.ORDERS_FETCH_MODE_INIT,
    //       // CONS.ORDERS_FETCH_MODE_SUBTAB_INIT,
    //       CONS.ORDERS_FETCH_MODE_INIT_SINGLE,
    //       CONS.ORDERS_FETCH_MODE_REFRESH,
    //     ],
    //     action.mode,
    //   )
    // ) {
    //   s = setOrdersInOrders(s, orders)
    // } else
    if (orders.count() === 1) {
      // log('count is 1 (only orders for one subtab is returned here (below))', orders.toJS())
      const tabName = orders.keySeq().first()
      // orders.mapKeys(tabName => {
      // log('tabname: ' + tabName)
      // log('orders.tabName')
      // log(orders.get(tabName).toJS())
      const currOrders = s.getIn(['orders', tabName]) || List([])
      let appendedOrders
      if (
        _.includes(
          [
            CONS.ORDERS_FETCH_MODE_INIT,
            CONS.ORDERS_FETCH_MODE_SUBTAB_INIT,
            CONS.ORDERS_FETCH_MODE_INIT_SINGLE,
            // CONS.ORDERS_FETCH_MODE_REFRESH,
          ],
          action.mode
        )
      ) {
        appendedOrders = orders.get(tabName)
      } else if (_.includes([CONS.ORDERS_FETCH_MODE_REFRESH], action.mode)) {
        s = setOrdersInOrders(s, Map({})) // clear all tabs orders
        s = s.set('fetchStatus', Map({})) // clear all fetchStatus
        appendedOrders = orders.get(tabName)
      } else {
        appendedOrders = currOrders.concat(orders.get(tabName))
      }
      s = s.setIn(['orders', tabName], appendedOrders)

      // s = s.updateIn(['orders', tabName], list =>
      // {
      // log('current list...')
      // log(list.toJS())
      // log('new list...')
      // log(orders.get(tabName).toJS())
      // list = list.concat(orders.get(tabName))
      // list.concat(orders.get(tabName))
      // list.push(orders.get(tabName))
      // log('****appended list')
      // log(list.toJS())
      // }
      // )
      // TODO: Make sure that
      // 1) Only visible tab is calling load more
      // 2) DO NOT Push if undefined
      // 3) Other fetchs are not cancelled for the ones that are not supposed to
      // log('newMap')
      // log(s.toJS())

      // let currOrdersForTab = getOrdersInOrdersForTabName(state, tabName)
      // let appendedOrders = currOrdersForTab.push(orders.tabName)
      // s = setOrdersInOrdersForTabName(s, tabName, appendedOrders)
      // log('curr')
      // log(currOrdersForTab.toJS())
      // log('merged')
      // log(appendedOrders.toJS())
      // })
      // it's the load more. just append

      // log(orders.findEntry())
      // let tabName = orders.findEntry()[0]
      //  let currOrdersForTab = getOrdersInOrdersForTabName(state, tabName)
    } else {
      // it's the loading for all tabs. Replace the whole thing
      s = setOrdersInOrders(s, orders)
    }

    let summary
    if (payload.has('summary')) {
      // log('payload HAS summary')
      summary = payload.get('summary')
      // log(summary)
      s = setOrdersSummaryInOrders(s, summary)
    } else {
      // log('payload does NOT have summary')
      summary = getOrdersSummaryInOrders(s)
    }

    // let summary: Map<string, any> = getOrdersSummaryInOrders(s) || Map({})
    // if (payload.has('summary')) {
    //   summary = summary.mergeDeep(payload.get('summary'))
    //   // log(summary)
    //   s = setOrdersSummaryInOrders(s, summary)
    // }

    // TODO: comment out เพราะมัน set shouldFetch ทำให้ fetch บ่อย รอ P`Keng review // 22 Aug 2019
    // const oldFetchStatus = getOrdersFetchStatusInOrders(s)
    // let oldFetchStatusTabNames = []
    // if (Map.isMap(oldFetchStatus) && _.includes([CONS.ORDERS_FETCH_MODE_INIT, CONS.ORDERS_FETCH_MODE_REFRESH], action.mode)) {
    //   oldFetchStatusTabNames = oldFetchStatus.keySeq().toArray()
    // }

    // log('summary is as followed...')
    // log(summary)
    // update fetchStatus
    // log('mapEntries')
    const now = new Date()
    let aFetchStatus
    let tabName
    let tabData
    let allFetched
    // let fetchOrdersLimit = getPrefFetchOrders(p.op.getAppState()).get('limit')
    const fetchOrdersLimit = payload.get('fetchOrdersLimit') ? payload.get('fetchOrdersLimit') : 20
    // const fetchOrdersOffset = typeof payload.get('fetchOrdersOffset') === 'number' ? payload.get('fetchOrdersOffset') : undefined
    let offset

    const newFetchStatusTabNames = []
    orders.mapEntries((entry) => {
      // log('entry => ', entry)
      tabName = entry[0] // key
      tabData = entry[1] // val
      newFetchStatusTabNames.push(tabName)
      // offset = tabData ? tabData.count() + getCurrCountForSubTab(s, tabName, getOrdersInOrdersForTabName) : 0
      // offset = tabData ? tabData.count() + getCurrCountForSubTab(s, tabName, getOrdersInOrdersForTabName) : 0
      offset = getCurrCountForSubTab(s, tabName, getOrdersInOrdersForTabName)

      // if (typeof fetchOrdersOffset === 'number') {
      //   offset = fetchOrdersOffset
      // }
      // log('BaseOrderListState.setOrderState')
      // log('tabName: ' + tabName + ' offset: ' + offset + ' count: ' + tabData.count())
      // log(tabData.count())
      if (
        tabData.count() < fetchOrdersLimit &&
        (tabName === 'doneTasks_shipped' ||
          tabName === 'doneTasks_cancelled' ||
          tabName === 'custTasks_autoComplete' ||
          tabName === 'doneTasks_autoCompleted')
      ) {
        allFetched = true
      } else if (orders.get(tabName) && orders.get(tabName).size === 0) {
        allFetched = true
      } else {
        allFetched = offset >= summary.get(entry[0])
      }
      // log('current offset for tab: ' + tabName + ' is ' + getCurrCountForSubTab(s, tabName))
      // aFetchStatus = {}
      const oldFetchStatus = s.getIn(['fetchStatus', tabName]) || {}
      aFetchStatus = Map.isMap(oldFetchStatus) ? oldFetchStatus.toJS() : oldFetchStatus
      aFetchStatus.loading = false
      aFetchStatus.isInited = true // FIXME: O: should be temporary set
      aFetchStatus.shouldFetch = false
      aFetchStatus.offset = offset
      aFetchStatus.lastUpdate = now
      aFetchStatus.allFetched = allFetched
      s = setOrderFetchStatusInOrdersForTab(s, tabName, aFetchStatus)
      // log(entry)
    })

    // TODO: comment out เพราะมัน set shouldFetch ทำให้ fetch บ่อย รอ P`Keng review // 22 Aug 2019
    // for (const oldTabKey of oldFetchStatusTabNames) {
    //   if (!_.includes(newFetchStatusTabNames, oldTabKey)) {
    //     // log('oldTabKey => ', oldTabKey)
    //     const oldFetchStatusByTabName = oldFetchStatus.get(oldTabKey)
    //     // log('oldFetchStatusByTabName => ', oldFetchStatusByTabName)
    //     if (_.isObject(oldFetchStatusByTabName) && !_.isNil(oldFetchStatusByTabName)) {
    //       // @ts-ignore
    //       oldFetchStatusByTabName.shouldFetch = true
    //       s = setOrderFetchStatusInOrdersForTab(s, oldTabKey, oldFetchStatusByTabName)
    //     }
    //     // log('oldFetchStatusByTabName => ', oldFetchStatusByTabName)
    //   }
    // }

    s = s.set('shouldFetchAll', false) // Reset should fetch after loaded
    return s
  },
}
