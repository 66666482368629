import { connect } from 'react-redux'
import { getDispatch, getState } from 'x/modules/shipment/QuickShipViewConnect'
import QuickShipView from './QuickShipView'
// import { bindActionCreators } from 'redux'
// import { NavigationActions } from 'react-navigation'
// import * as shipActions from 'x/modules/shipment/ShipmentState'

export default connect(
  (state: any) => ({
    ...getState(state),
  }),
  (dispatch) => {
    return {
      ...getDispatch(dispatch),
    }
  }
)(QuickShipView)
