import { bindActionCreators } from 'redux'
import * as ReportActions from 'x/modules/report/ReportState'
import { getDailySalesReport, getSelectedStore } from 'x/redux/selectors'

export function getState(state) {
  return {
    selectedStore: getSelectedStore(state),
    chartData: getDailySalesReport(state),
  }
}

export function getDispatch(dispatch) {
  return {
    fetchDailySalesReport: bindActionCreators(ReportActions.fetchDailySalesReport, dispatch),
  }
}
