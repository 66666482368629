import { getEditingOrder, getSelectedOrder, getSelectedStore } from 'x/redux/selectors'
import { bindActionCreators } from 'redux'
import * as bpActions from 'x/modules/order/OrderState'

export function getState(state) {
  return {
    editingOrder: getEditingOrder(state),
    selectedOrder: getSelectedOrder(state),
    selectedStore: getSelectedStore(state),
  }
}

export function getDispatch(dispatch) {
  return {
    fetchMarketplaceOrder: bindActionCreators(bpActions.fetchMarketplaceOrder, dispatch),

    dispatch,
  }
}
