import React from 'react'
import { View } from 'react-native'
import { IBoxProps } from 'native-base'
import Box from './Box'

// @ts-ignore
const Center = React.forwardRef<View, IBoxProps>((props, ref) => (
  <Box
    // @ts-ignore
    ref={ref}
    // flexDirection='row'
    display='flex'
    alignItems='center'
    justifyContent='center'
    {...props}
  />
))

Center.displayName = 'XCenter'

export default Center as React.FC<IBoxProps>
