import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import CustomOrderView from './CustomOrderView'
import { getStoreSelected, getSubscription, getOrder } from 'x/redux/selectors'
import { getState, getDispatch } from 'x/modules/order/BaseOrderViewConnect'

export default connect(
  state => ({
    selectedStore: getStoreSelected(state),
    subscription: getSubscription(state),
    order: getOrder(state),
    ...getState(state),
  }),
  dispatch => {
    return {
      ...getDispatch(dispatch),
      // navigate: bindActionCreators(NavigationActions.navigate, dispatch),
    }
  },
)(CustomOrderView)
