import { connect } from 'react-redux'
import { getSubscription, getSelectedStore } from 'x/redux/selectors'
import ImportExcelCreateOrderView from './ImportExcelCreateOrderView'

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
    // sellerStores: getSellerStores(state),
    // sellerStoresShouldFetch: getSellerStoresShouldFetch(state),
    subscription: getSubscription(state),
  }),
  (dispatch) => ({
    // navigate: bindActionCreators(NavigationActions.navigate, dispatch),
    // fetchSellerStores: bindActionCreators(StoreActions.fetchSellerStores, dispatch),
    // fetchAccounts: bindActionCreators(BankActions.fetchStorePaymentAccounts, dispatch),
    // getPermissionMemberList: bindActionCreators(StoreActions.getPermissionMemberList, dispatch),
  })
)(ImportExcelCreateOrderView)
