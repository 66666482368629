import React from 'react'
import * as util from 'x/utils/util'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
// import SRowItem from '../../components/renderRowLeftAndRight'
import BaseStoreWebsiteSettingPaymentView from 'x/modules/storeWebsite/BaseStoreWebsiteSettingPaymentView'
import { STYLES, COLORS } from 'x/config/styles'
import XText from 'xui/components/XText'
import XCard from 'xui/components/XCard'
import HStack from 'xui/components/HStack'
import p from 'x/config/platform-specific'
import XSwitch from 'xui/components/XSwitch'
import XFlatPicker from 'xui/components/XFlatPicker'
import XIcon from 'xui/components/XIcon'
import VStack from 'xui/components/VStack'
import _ from 'lodash'
import XInput from 'xui/components/XInput'

// const _renderAgreement = (onPressOpenOverlay: () => void) => <HelpButton onPress={() => onPressOpenOverlay()} />

export default abstract class BaseUIStoreWebsiteSettingPaymentView extends BaseStoreWebsiteSettingPaymentView {
  _renderHeader = () => {
    const { isEdit } = this.state
    const title = 'ตั้งค่าการจัดส่งและชำระเงิน'
    let leftProps = { backIcon: true, onPressItem: () => util.navGoBack(this.props) }
    let rightProps = null
    if (isEdit) {
      // @ts-ignore
      leftProps = { label: 'บันทึก', onPressItem: () => this._saveBtn() }
      rightProps = {
        label: 'ยกเลิก',
        onPressItem: () => {
          p.op.showConfirmation(
            '',
            'คุณต้องการกยเลิกการแก้ไขใช่หรือไม่',
            () => util.navGoBack(this.props),
            () => null,
            'ยกเลิกและออกจากหน้านี้',
            'แก้ไขต่อ'
          )
        },
      }
    }
    return (
      <XCustomHeader
        title={title}
        headerLeftProps={leftProps}
        headerRightProps={rightProps}
        // renderHeaderRight={this._renderHeaderRight}
      />
    )
  }

  _renderTitle = () => (
    <HStack alignItems='center' justifyContent='center'>
      <XText variant='active' fontSize='md'>
        {p.op.t('Order.shippingType')}
      </XText>
    </HStack>
  )

  _renderCommonFlatPickerItemForShipping = (data) => {
    const { item, index, disabled } = data
    // console.log('DATA => ', data)
    const shippingState = util.getShippingHasXShippingLabel(item.value)
    // console.log('shippingState => ', shippingState)
    // const UNDER_LINE = p.op.isWeb() ? {} : { borderBottomColor: COLORS.TEXT_INACTIVE, borderBottomWidth: 1 }

    return (
      <XCard w='full'>
        <XCard.Body>
          <HStack w='full' minH='10' alignItems='center' space='1'>
            <VStack flex={1}>
              <XText style={{ fontSize: STYLES.FONT_SIZE_LARGER, color: COLORS.TEXT_ACTIVE }}>{`${item.label}`}</XText>
              {_.isNil(shippingState) ? null : (
                <XText style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: shippingState.color }}>{`(${shippingState.label})`}</XText>
              )}
            </VStack>
            <XIcon name='arrowright' family='AntDesign' />
          </HStack>
        </XCard.Body>
      </XCard>
    )
  }

  _renderShippingPayment = (key: string, shippingCost: string) => (
    // const {} = this.state
    <VStack w='full' mt='2' key={key}>
      <HStack alignItems='center' justifyContent='center' w='full'>
        <HStack flex={7}>
          <XText>ค่าส่ง</XText>
        </HStack>
        <HStack>
          <XInput
            // w='full'
            w='24'
            h='10'
            // key={inputKey}
            // key={orderIndex}
            // isDisabled={!isEditable}
            textAlign='right'
            selectTextOnFocus
            value={shippingCost}
            isMoney
            maxLength={11}
            // @ts-ignore
            onChangeText={(newValue) => this.setState({ [key]: newValue, isEdit: true })}
          />
        </HStack>
      </HStack>
    </VStack>
  )

  _renderShippingNonCod = () => {
    const { isShipping, selectedShippingTypeIndex, shippingCost } = this.state

    const isValueChange = (newValue: boolean) => {
      this.setState({ isShipping: newValue, isEdit: true })
    }

    const handleChangeShippingNotCodType = (itemInfo) => {
      const { item, index } = itemInfo
      // console.log('item => ', item)
      if (!_.isNil(item)) {
        this.setState({
          selectedShippingTypeIndex: index,
          isEdit: true,
        })
      }
    }

    const _renderDetailShippingNotCodTypeCardButton = (data: any) => {
      const { item, index, disabled } = data
      // console.log('index // => ', index)
      let txtShippingType = null
      if (index > -1) {
        txtShippingType = this.state.shippingTypeNotCodItems[index].label
        // txtShippingType = 'PASS'
      } else if (index === -1 && item && item.value > 0) {
        txtShippingType = 'กรุณาดาวน์โหลดแอปเวอร์ชั่นใหม่ เพื่อแสดงผลข้อมูลนี้'
      } else {
        txtShippingType = 'ไม่ระบุ'
      }
      return (
        <XCard w='full'>
          <XCard.Body>
            <HStack w='full' alignItems='center' space='1'>
              <HStack alignItems='center' space='1'>
                <XIcon family='MaterialIcons' name='local-shipping' variant='inactive' />
                <XText variant='inactive'>รูปแบบจัดส่ง</XText>
              </HStack>

              <HStack flex={1} alignItems='center' justifyContent='flex-end' space='1'>
                <XText flex={1} textAlign='right'>
                  {txtShippingType}
                </XText>
                {disabled ? null : <XIcon variant={disabled ? 'inactive' : 'primary'} name='arrow-forward' />}
              </HStack>
            </HStack>
          </XCard.Body>
        </XCard>
      )
    }

    return (
      <XCard w='full' p='2' mt='2'>
        <HStack flex={1}>
          <HStack flex={1}>
            <XText>แบบธรรมดา (ไม่เก็บเงินปลายทาง)</XText>
          </HStack>
          <XSwitch value={isShipping} onValueChange={(newValue: boolean) => isValueChange(newValue)} />
        </HStack>
        {isShipping ? (
          <VStack mt='2'>
            <XFlatPicker
              key='ShippingTypePicker_not_cod'
              title='เลือกรูปแบบจัดส่ง'
              placeholder='กรุณาเลือกรูปแบบจัดส่ง'
              placeholderStyle={{ marginLeft: 6 }}
              // ref={this._shippingTypeCreateModePickerRef}
              selectedIndex={selectedShippingTypeIndex}
              options={this.state.shippingTypeNotCodItems}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onPick={handleChangeShippingNotCodType}
              renderSelectedValue={_renderDetailShippingNotCodTypeCardButton}
              renderItem={this._renderCommonFlatPickerItemForShipping}
              // renderTitleBar={() => this._renderTotleBarForShipping()}
            />
            {this._renderShippingPayment('shippingCost', shippingCost)}
          </VStack>
        ) : null}
      </XCard>
    )
  }

  _renderShippingCod = () => {
    const { isShippingCod, selectedShippingTypeCodIndex, shippingCodCost } = this.state

    const isValueChange = (newValue: boolean) => {
      // console.log('newValue => ', newValue)
      this.setState({ isShippingCod: newValue, isEdit: true })
    }

    const handleChangeShippingNotCodType = (itemInfo) => {
      const { item, index } = itemInfo
      // console.log('item => ', item)
      if (!_.isNil(item)) {
        this.setState({
          selectedShippingTypeCodIndex: index,
          isEdit: true,
        })
      }
    }

    const _renderDetailShippingNotCodTypeCardButton = (data: any) => {
      const { item, index, disabled } = data
      let txtShippingType = null
      if (index > -1) {
        txtShippingType = this.state.shippingTypIsCodItems[index].label
        // txtShippingType = 'PASS'
      } else if (index === -1 && item && item.value > 0) {
        txtShippingType = 'กรุณาดาวน์โหลดแอปเวอร์ชั่นใหม่ เพื่อแสดงผลข้อมูลนี้'
      } else {
        txtShippingType = 'ไม่ระบุ'
      }
      return (
        <XCard w='full'>
          <XCard.Body>
            <HStack w='full' alignItems='center' space='1'>
              <HStack alignItems='center' space='1'>
                <XIcon family='MaterialIcons' name='local-shipping' variant='inactive' />
                <XText variant='inactive'>รูปแบบจัดส่ง</XText>
              </HStack>
              <HStack flex={1} alignItems='center' justifyContent='flex-end' space='1'>
                <XText flex={1} textAlign='right'>
                  {txtShippingType}
                </XText>
                {disabled ? null : <XIcon variant={disabled ? 'inactive' : 'primary'} name='arrow-forward' />}
              </HStack>
            </HStack>
          </XCard.Body>
        </XCard>
      )
    }
    // console.log('selectedPaymentAccountId => ', selectedPaymentAccountId)
    return (
      <XCard w='full' p='2' mt='2'>
        <HStack flex={1}>
          <HStack flex={1}>
            <XText>แบบ COD (เก็บเงินปลายทาง)</XText>
          </HStack>
          <XSwitch value={isShippingCod} onValueChange={(newValue: boolean) => isValueChange(newValue)} />
        </HStack>
        {isShippingCod ? (
          <VStack mt='2'>
            <XFlatPicker
              key='ShippingTypePicker_is_cod'
              title='เลือกรูปแบบจัดส่ง'
              placeholder='กรุณาเลือกรูปแบบจัดส่ง'
              placeholderStyle={{ marginLeft: 6 }}
              // ref={this._shippingTypeCreateModePickerRef}
              selectedIndex={selectedShippingTypeCodIndex}
              options={this.state.shippingTypIsCodItems}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onPick={handleChangeShippingNotCodType}
              renderSelectedValue={_renderDetailShippingNotCodTypeCardButton}
              renderItem={this._renderCommonFlatPickerItemForShipping}
              // renderTitleBar={() => this._renderTotleBarForShipping()}
            />
            {/* <VStack mt='2'>
              <PaymentAccountPicker
                targetStoreId={this.props.selectedStore.get('id')}
                selectedPaymentAccountId={selectedPaymentAccountId}
                // @ts-ignore
                onChangePaymentAccount={this._onChangePaymentAccount}
                // onPaymentAccountLoaded={this._onPaymentAccountsLoaded}
                placeholderText='กรุณาเลือกบัญชีชำระ'
              />
            </VStack> */}
            {this._renderShippingPayment('shippingCodCost', shippingCodCost)}
          </VStack>
        ) : null}
      </XCard>
    )
  }

  _renderShippingPaymentView = () => {
    const { storeWebsite } = this.state
    return (
      <XCard p='2' m='2'>
        {this._renderTitle()}
        {this._renderShippingNonCod()}
        {this._renderShippingCod()}
      </XCard>
    )
  }

  render() {
    return (
      <XContainer>
        {this._renderHeader()}
        <XContent style={{ backgroundColor: COLORS.BG_LIGHT_GREY }}>{this._renderShippingPaymentView()}</XContent>
      </XContainer>
    )
  }
}
