import React from 'react'
import { COLORS, STYLES } from 'x/config/styles'
import { View, TouchableOpacity, Clipboard, ScrollView, Alert } from 'react-native'
import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'
import _ from 'lodash'
// import OverlayEditTimeSlot from 'xui/modules/channels/OverlayEditTimeSlot'
import XOverlay from 'xui/components/XOverlay'
import moment from 'moment'
import XIcon from './XIcon'
import XText from './XText'

export interface IXAddressesListOverlayProps {
  isVisible: boolean
  onClose: () => void
  orderAddress: IAddressList[]
}

export interface IAddressList {
  order_id: number
  order_created_at: string
  name: string
  address3: string
  address1: string
  address2: string
  telephone: string
  postal_code: string
  helper_profile_id?: number
}

// const ROUND_UP = 'ปัดขึ้น'
// const ROUND_DOWN = 'ปัดลง'
// const NO_ROUND = 'ไม่ปัด'

const underline = () => (
  <View
    style={{
      paddingTop: 12,
      borderBottomColor: COLORS.TEXT_INACTIVE,
      borderStyle: 'dashed',
      borderBottomWidth: 1,
    }}
  />
)

// const order_address: IAddressList[] = [
//   {
//     order_id: 1850896,
//     order_created_at: '2021-09-21 21:37:58',
//     name: 'นายทดสอบ จากXSelly',
//     address3: '100/200 ถนนจักรวรรดิ อ.สัมพันธวงศ์ กทม',
//     telephone: '0971671651',
//     postal_code: '11200',
//   },
//   {
//     order_id: 1850897,
//     order_created_at: '2021-09-24 09:33:45',
//     name: 'นายทดสอบ จากXSelly2',
//     address3: '100/200 ถนนจักรวรรดิ อ.สัมพันธวงศ์ กทม',
//     telephone: '0971671651',
//     postal_code: '11200',
//   },
//   {
//     order_id: 1850897,
//     order_created_at: '2021-09-24 09:33:45',
//     name: 'นายทดสอบ จากXSelly2',
//     address3: '100/200 ถนนจักรวรรดิ อ.สัมพันธวงศ์ กทม',
//     telephone: '0971671651',
//     postal_code: '11200',
//   },
//   {
//     order_id: 1850897,
//     order_created_at: '2021-09-24 09:33:45',
//     name: 'นายทดสอบ จากXSelly2',
//     address3: '100/200 ถนนจักรวรรดิ อ.สัมพันธวงศ์ กทม',
//     telephone: '0971671651',
//     postal_code: '11200',
//   },
// ]

const XAddressesListOverlay: React.FC<IXAddressesListOverlayProps> = ({ isVisible, onClose, orderAddress }) => {
  // const [SEGMENT_PERCENT_DISCOINT_VALUE, SET_SEGMENT_PERCENT_DISCOINT_VALUE] = useState<number[]>([5, 10, 15, 20, 25])
  // const [SEGMENT_PERCENT_DISCOINT_VALUE_BACKUP, SET_SEGMENT_PERCENT_DISCOINT_VALUE_BACKUP] = useState<string[]>(
  //   DEFAULT_SEGMENT_PERCENT_DISCOINT_VALUE_BACKUP
  // )
  // const [SEGMENT_PERCENT_DISCOINT_VALUE_BACKUP_FOR_CANCEL, SET_SEGMENT_PERCENT_DISCOINT_VALUE_BACKUP_FOR_CANCEL] = useState<string[]>(
  //   DEFAULT_SEGMENT_PERCENT_DISCOINT_VALUE_BACKUP
  // )
  // const [selectedPercentDiscointRoundIndex, setSelectedPercentDiscointRoundIndex] = useState<number>(0)
  // const [selectedPercentDiscointIndex, setSelectedPercentDiscointIndex] = useState<number>(0)
  // // const [inputSetPercentDiscointValue, setInputSetPercentDiscointValue] = useState<string[] | null>(null)
  // const [inputPercentCustomDiscount, setInputPercentCustomDiscount] = useState<string>('0')
  // const [isEditMode, setEditMode] = useState<boolean>(false)

  const renderAddresses = (addr: IAddressList, index: number) => {
    const time = moment(addr.order_created_at).fromNow()
    const sellerType = util.getHelperName(addr.helper_profile_id)
    const diffNow = moment().diff(moment(addr.order_created_at))
    // console.log('diffNow => ', diffNow)
    // console.log('TEST => ', moment().diff(moment().subtract(5, 'day')))
    const textCreatedColor = diffNow < 432000000 ? COLORS.RED : COLORS.TEXT_ACTIVE
    return (
      <View style={{ paddingTop: 10, paddingRight: 4, paddingLeft: 4 }}>
        {/* // ออเดอร์ */}
        <View style={{ flexDirection: 'row' }}>
          <View style={{ flex: 1 }}>
            <XText variant='inactive'>{`${index + 1}. ออเดอร์: `}</XText>
          </View>
          <View style={{ flex: 1, alignItems: 'flex-end' }}>
            <TouchableOpacity
              onPress={() => {
                Clipboard.setString(addr.order_id.toString())
                p.op.showToast(`คัดลอกเลขออเดอร์ ${addr.order_id} แล้ว`, 'success')
              }}
              style={{
                // borderWidth: 1,
                borderTopWidth: 1,
                borderBottomWidth: 1,
                borderRightWidth: 1,
                borderLeftWidth: 1,
                borderColor: COLORS.APP_MAIN,
                borderRadius: 7,
                backgroundColor: COLORS.BG_LIGHT_GREY,
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: 4,
                width: 100,
              }}>
              <XIcon
                family='FontAwesome'
                name='copy'
                style={{ flex: 0, minWidth: 25, width: 25, fontSize: STYLES.FONT_ICON_SMALLEST, paddingRight: 4, color: COLORS.APP_MAIN }}
              />
              <XText
                style={{
                  // fontSize: STYLES.FONT_SIZE_LARGER,
                  color: COLORS.APP_MAIN,
                  // paddingLeft: 4,
                  // paddingRight: 4,
                }}>{`#${addr.order_id}`}</XText>
            </TouchableOpacity>
          </View>
        </View>
        {/* // สร้างโดย */}
        <View style={{ flexDirection: 'row', paddingTop: 2 }}>
          <View style={{ flex: 1 }}>
            <XText variant='inactive'>สร้างโดย:</XText>
          </View>
          <View style={{ flex: 1, alignItems: 'flex-end' }}>
            <XText variant='active'>{sellerType}</XText>
          </View>
        </View>
        {/* // สร้างเมื่อ */}
        <View style={{ flexDirection: 'row', paddingTop: 2 }}>
          <View style={{ flex: 1 }}>
            <XText variant='inactive'>สร้างเมื่อ:</XText>
          </View>
          <View style={{ flex: 1, alignItems: 'flex-end' }}>
            <XText color={textCreatedColor} bold>
              {time}
            </XText>
          </View>
        </View>
        {/* // สร้างเมื่อ */}
        <View style={{ flexDirection: 'row' }}>
          <View style={{ flex: 1, alignItems: 'flex-end' }}>
            <XText variant='inactive'>{`(${addr.order_created_at})`}</XText>
          </View>
        </View>

        <View>
          <XText variant='active'>{addr.name}</XText>
        </View>
        {_.isNil(addr.address1) && _.isNil(addr.address2) ? null : (
          <View>
            <XText variant='active'>{`${_.isNil(addr.address1) ? '' : addr.address1} ${
              _.isNil(addr.address2) ? '' : addr.address2
            }`}</XText>
          </View>
        )}
        <View>
          <XText variant='active'>{`${_.isNil(addr.address3) ? '' : `${addr.address3} `}${addr.postal_code}`}</XText>
        </View>
        <View>
          <XText variant='active'>{addr.telephone}</XText>
        </View>
        <View style={{ borderBottomWidth: 1, borderBottomColor: COLORS.TEXT_INACTIVE, borderStyle: 'solid' }} />
      </View>
    )
  }

  const TITLE_LABEL = `ที่อยู่ผู้รับซ้ำ ${orderAddress.length} ออเดอร์`

  return (
    <XOverlay
      contentStyle={{ height: 470, width: 310, alignSelf: 'center', flexDirection: 'column', backgroundColor: COLORS.WHITE }}
      visible={isVisible}
      // onTouchCancel={() => console.log('*************')}
      onRequestClose={() => onClose()}>
      <View style={{ justifyContent: 'flex-start', flexDirection: 'row' }}>
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', paddingLeft: 40 }}>
          <XText variant='inactive' bold>
            {TITLE_LABEL}
          </XText>
        </View>
        <TouchableOpacity
          onPress={() =>
            p.op.isIOS() // iOS will freeze due to double modal; hence, use native Alert for iOS
              ? Alert.alert('', 'เป็นรายการออเดอร์ที่ถูกสร้างภายใน 30 วัน ที่มี "ชื่อ" หรือ "เบอร์โทร" ซ้ำกับออเดอร์ที่สร้างหรือแก้ไขอยู่')
              : p.op.showConfirmationOkOnly(
                  '',
                  'เป็นรายการออเดอร์ที่ถูกสร้างภายใน 30 วัน ที่มี "ชื่อ" หรือ "เบอร์โทร" ซ้ำกับออเดอร์ที่สร้างหรือแก้ไขอยู่'
                )
          }
          style={{ width: 40, height: 40, justifyContent: 'center', alignItems: 'center' }}>
          <XIcon
            family='Ionicons'
            name='help-circle-outline'
            style={{ flex: 0, minWidth: 25, width: 25, fontSize: STYLES.FONT_ICON_NORMAL, paddingRight: 4, color: COLORS.TEXT_INACTIVE }}
          />
        </TouchableOpacity>
      </View>
      <View
        style={{
          // borderWidth: 1,
          borderTopWidth: 1,
          borderBottomWidth: 1,
          borderRightWidth: 1,
          borderLeftWidth: 1,
          borderColor: COLORS.TEXT_INACTIVE,
          borderRadius: 7,
          flex: 1,
          paddingLeft: 2,
          paddingRight: 2,
          marginLeft: 4,
          marginRight: 4,
        }}>
        <ScrollView>{orderAddress.map((addr: IAddressList, idx: number) => renderAddresses(addr, idx))}</ScrollView>
      </View>
      <View style={{ height: 8 }} />
      <View style={{ justifyContent: 'flex-end', flexDirection: 'row' }}>
        <TouchableOpacity
          onPress={() => onClose()}
          style={{
            // borderWidth: 1,
            borderTopWidth: 1,
            borderBottomWidth: 1,
            borderRightWidth: 1,
            borderLeftWidth: 1,
            borderColor: COLORS.APP_MAIN,
            borderRadius: 7,
            backgroundColor: COLORS.BG_LIGHT_GREY,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            paddingRight: 4,
            // marginBottom: 4,
            marginTop: 4,
            flex: 1,
            height: 35,
            marginRight: 4,
            marginLeft: 4,
            // marginBottom: 4,
          }}>
          <XIcon
            family='AntDesign'
            name='closecircle'
            style={{ flex: 0, minWidth: 25, width: 25, fontSize: STYLES.FONT_ICON_SMALLEST, paddingRight: 4, color: COLORS.APP_MAIN }}
          />
          <XText color={COLORS.APP_MAIN}>ปิด</XText>
        </TouchableOpacity>
      </View>
      <View style={{ height: 4 }} />
    </XOverlay>
  )
}

export default XAddressesListOverlay
