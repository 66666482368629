/* eslint-disable react/no-unused-class-component-methods */
import React from 'react'
import { IAddress, IShippingType, IXScreenProps } from 'x/types'
import _ from 'lodash'

import * as util from 'x/utils/util'

import p from 'x/config/platform-specific'
import * as xUtil from 'x/utils/util'
import * as NavActions from 'x/utils/navigation'
import xCONS from 'x/config/constants'
import { Map } from 'immutable'
import { ICustomCreateOrder } from 'x/index'
import CONS from '../../config/constants'

export interface Props extends IXScreenProps {
  editingOrder: any
  selectedStore: any
  editingCustomCreateOrder: any
  selectedCustomCreateOrder: any
  customCreateOrder: Function
  addressLists: Map<string, any>
  loadAddress: Function
  findAddresses: Function
  addCustomOrder: Function
}

interface State {
  showAndHideRecieverAdresses: number
  optionsChooseRecieverAdresses: number
  optionsChooseRecieverAdressesType: number
  showAndHideSenderAdresses: number
  optionsChooseSenderAdresses: number
  showAndHideShipping: number
  optionsChooseShipping: number
  showAndHideShippingBeforePay: number
  showAndHideShippingBeforePayCOD: number
  showAndHideSalesChannel: number
  showAndHideCoverSheet: number
  showAndHideCoverSheetNote: number
  showAndHideAutoCloseSellOrder: number
  currentRef?: any
  txtSenderName: string
  txtSenderAdresses: string
  txtReceiverName: string
  txtReceiverAddress: string
  mode: string
  customCreateOrderData: ICustomCreateOrder
  store_id: number

  onChangeData: boolean
  shippingTypeItems: IShippingType[]
  shippingName: string
}

const { ORDER_VIEW_MODE, PERM_STORE_HELPER, STORE_SETTINGS } = xCONS
const { CREATE, VIEW_EDIT, VIEW_ONLY, EDIT } = ORDER_VIEW_MODE

export default class BaseCustomCreateOrder extends React.Component<Props, State> {
  static displayName = 'CustomCreateOrder'

  OPTIONS_CHOOSE_HIDE_AND_SHOW: string[]

  OPTIONS_CHOOSE_RECIEVER_AND_SENDER_ADRESSES: string[]

  OPTIONS_CHOOSE_RECIEVER_ADRESSES_TYPE: string[]

  OPTIONS_CHOOSE_AUTO_RECIEVER_ADRESSES: string[]

  OPTIONS_CHOOSE_USE_OR_NOUSE_SENDER_ADRESSES: string[]

  // OPTIONS_CHOOSE_SIPPING: string[]
  inputRefs: any[]

  // editingSenderName เอาไว้ตรวจสอบสำหรับต้องการเปลี่ยนชื่อผู้ส่ง
  editingSenderName: boolean

  shippingTypeItems: { [key: string]: any }

  // channelItems: { [key: string]: any }

  inProcess: boolean

  channelItems: Array<{
    index: number
    right: string // right icon ?
    value: number
    key: string
    label: string
  }>

  constructor(props) {
    super(props)
    this.state = {
      mode: CREATE,
      showAndHideRecieverAdresses: 1,
      optionsChooseRecieverAdresses: 2,
      optionsChooseRecieverAdressesType: 1,

      showAndHideSenderAdresses: 1,
      optionsChooseSenderAdresses: 2,
      txtSenderName: null,
      txtSenderAdresses: null,

      showAndHideShipping: 1,
      optionsChooseShipping: 1,

      showAndHideShippingBeforePay: 1,
      showAndHideShippingBeforePayCOD: 1,

      showAndHideSalesChannel: 1,

      showAndHideCoverSheet: 1,
      showAndHideCoverSheetNote: 1,

      showAndHideAutoCloseSellOrder: 1,

      currentRef: null,

      customCreateOrderData: this.props.editingCustomCreateOrder.toJS(),
      store_id: null,
      txtReceiverAddress: null,
      txtReceiverName: null,

      onChangeData: false,
      shippingTypeItems: [],
      shippingName: 'กรุณาเลือกรูปแบบจัดส่ง',
    }

    this.OPTIONS_CHOOSE_HIDE_AND_SHOW = ['ซ่อน', 'แสดง']
    this.OPTIONS_CHOOSE_RECIEVER_AND_SENDER_ADRESSES = ['ไม่ระบุ', 'ระบุ']
    this.OPTIONS_CHOOSE_RECIEVER_ADRESSES_TYPE = ['ชื่อ', 'ชื่อ โทร', 'ชื่อ ที่อยู่\nและโทร']
    this.OPTIONS_CHOOSE_AUTO_RECIEVER_ADRESSES = ['ไม่กรอก', 'กรอก']
    // this.OPTIONS_CHOOSE_SIPPING = ['ไม่เลือก', 'เลือก']
    this.OPTIONS_CHOOSE_USE_OR_NOUSE_SENDER_ADRESSES = ['ไม่ใช้งาน', 'ใช้งาน']
    this.inputRefs = []
    this.editingSenderName = false
    this.shippingTypeItems = CONS.ORDER_VIEW_SHIPPING_TYPE_ITEMS.map((item) => ({
      key: item.index,
      ...item,
      label: p.op.t(`Order.shippingTypeItems.${item.key}`),
    }))
    this.channelItems = CONS.ORDER_VIEW_CHANNEL_ITEMS.map((item) => ({
      key: item.index,
      ...item,
      label: p.op.t(`Order.channelItems.${item.key}`),
    }))
    this.inProcess = false
  }

  async componentDidMount() {
    await this._setConfig()
    this._setShippingTypeItems()
    const { navigation } = this.props

    // navigation.setParams({
    //   saveBtn: this._saveBtn,
    //   onChangeData: false,
    // })
  }

  _setConfig = async () => {
    const { navigation, findAddresses } = this.props
    const { customCreateOrderData } = this.state
    // const { store_id } = navigation.state.params
    const store_id = util.getNavParam(this.props, 'store_id')
    const { sender } = customCreateOrderData
    const { receiver } = customCreateOrderData
    const customerAddresses = _.isNil(receiver.vi) ? null : [receiver.vi]
    const storeAddresses = _.isNil(sender.vi) ? null : [sender.vi]
    // const addresses = await this._loadAddress(storeAddresses, customerAddresses)
    const body = {}
    // console.log('--------------------------------')
    // console.log(storeAddresses, customerAddresses)
    if (!_.isNil(storeAddresses)) {
      // @ts-ignore
      body.store = {
        id: store_id,
        address_ids: storeAddresses,
      }
    }
    if (!_.isNil(customerAddresses)) {
      // @ts-ignore
      body.customer_addr_ids = customerAddresses
    }
    let response = null
    if (!_.isNil(customerAddresses) || !_.isNil(storeAddresses)) {
      await new Promise((resolve) => {
        findAddresses({
          body,
          successCallback: (res) => {
            response = res
            setTimeout(resolve, 200)
          },
          failedCallback: (err) => {
            response = err
            setTimeout(resolve, 200)
          },
        })
      })
    }

    // console.log('response', response)
    const addresses = _.has(response, 'addresses') ? response.addresses : null
    const errorRespose = _.has(response, 'error') ? response.error : null
    let txtSenderName = null
    let txtSenderAdresses = null
    let txtReceiverName = null
    let txtReceiverAddress = null
    // console.log('addresses', addresses)
    // console.log('errorResposeId', errorResposeId)
    if (_.isArray(addresses) && addresses.length > 0) {
      addresses.map((address) => {
        if (!_.isNil(sender.vi) && address.id === sender.vi) {
          txtSenderName = address.name
          txtSenderAdresses = address.address1
          txtSenderAdresses = address.address2 ? `${txtSenderAdresses} ${address.address2}` : txtSenderAdresses
          txtSenderAdresses = address.sub_district ? `${txtSenderAdresses} ${address.sub_district}` : txtSenderAdresses
          txtSenderAdresses = address.district ? `${txtSenderAdresses} ${address.district}` : txtSenderAdresses
          txtSenderAdresses = address.province ? `${txtSenderAdresses} ${address.province}` : txtSenderAdresses
          txtSenderAdresses = address.postal_code ? `${txtSenderAdresses}\n${address.postal_code}` : txtSenderAdresses
          txtSenderAdresses = address.telephone ? `${txtSenderAdresses}\nโทร. ${address.telephone}` : txtSenderAdresses
        }
        if (!_.isNil(receiver.vi) && address.id === receiver.vi) {
          txtReceiverName = address.name
          txtReceiverAddress = address.address1
          txtReceiverAddress = address.address2 ? `${txtReceiverAddress} ${address.address2}` : txtReceiverAddress
          txtReceiverAddress = address.sub_district ? `${txtReceiverAddress} ${address.sub_district}` : txtReceiverAddress
          txtReceiverAddress = address.district ? `${txtReceiverAddress} ${address.district}` : txtReceiverAddress
          txtReceiverAddress = address.province ? `${txtReceiverAddress} ${address.province}` : txtReceiverAddress
          txtReceiverAddress = address.postal_code ? `${txtReceiverAddress}\n${address.postal_code}` : txtReceiverAddress
          txtReceiverAddress = address.telephone ? `${txtReceiverAddress}\nโทร. ${address.telephone}` : txtReceiverAddress
        }
      })
    } else if (!_.isNil(errorRespose) && _.isNumber(errorRespose)) {
      // @ts-ignore
      const errorId = errorRespose.id
      // console.log(errorId)
      if (errorId === sender.vi) {
        p.op.showConfirmationOkOnly(
          'กรุณาตั้งค่าที่อยู่ผู้ส่งสำหรับการระบุอัตโนมัติใหม่',
          'ที่อยู่ผู้ส่งสำหรับการระบุอัดโนมัติไม่ถูกต้อง กรุณาระบุใหม่'
        )
      } else if (errorId === receiver.vi) {
        p.op.showConfirmationOkOnly(
          'กรุณาตั้งค่าที่อยู่ผู้รับสำหรับการระบุอัตโนมัติใหม่',
          'ที่อยู่ผู้รับสำหรับการระบุอัดโนมัติไม่ถูกต้อง กรุณาระบุใหม่'
        )
      }
    }
    // const newSenderAdresses = null
    // const allSenderAddresses = selectedStore.get('addresses').toJS()
    // if (sender.v === 1 && sender.a === 2 && !this.editingSenderName) {
    //   if (_.isNil(sender.vi)) {
    //     senderAdresses = null
    //   } else {
    //     allSenderAddresses.map(obj => {
    //       if (obj.id === sender.vi) {
    //         newSenderAdresses = obj.address1 + obj.address2 + obj.address3
    //         // this.editingSenderName = true
    //       }
    //     })
    //     senderAdresses = newSenderAdresses
    //   }
    // }

    // set custom create order date_delivery
    const newCustomCreateOrder = _.clone(customCreateOrderData)
    if (!_.has(customCreateOrderData, 'date_delivery')) {
      newCustomCreateOrder.date_delivery = { v: 1 }
    }

    // set custom create order auto_cancel_order
    if (!_.has(customCreateOrderData, 'auto_cancel_order')) {
      newCustomCreateOrder.auto_cancel_order = { v: 1, m: 'd' }
    }

    // set custom create order check_dup_receiver_addr
    if (!_.has(customCreateOrderData, 'check_dup_receiver_addr')) {
      newCustomCreateOrder.check_dup_receiver_addr = true
    }

    xUtil.setStatePromise(this, {
      // customCreateOrderData: mock,
      store_id,
      txtSenderName,
      txtSenderAdresses,
      txtReceiverName,
      txtReceiverAddress,
      customCreateOrderData: newCustomCreateOrder,
    })
  }

  _setShippingTypeItems = async () => {
    const VISIBLE_SHIPPING_TYPES_IDS = util.getVisibleShippingTypes()
    const shippingTypeItems = VISIBLE_SHIPPING_TYPES_IDS.map((item) => ({
      key: item.index,
      ...item,
      label: p.op.t(`Order.shippingTypeItems.${item.key}`),
    }))
    // Shipping
    const { customCreateOrderData } = this.state
    const shippping = customCreateOrderData.shipping
    // console.log(this.shippingTypeItems)
    let shippingName
    const shipppingId = _.isNil(shippping.vi) ? null : shippping.vi
    if (!_.isNil(shipppingId)) {
      this.shippingTypeItems.map((data) => {
        if (shipppingId === data.value) {
          shippingName = data.label
        }
      })
    } else {
      shippingName = 'กรุณาเลือกรูปแบบจัดส่ง'
    }
    await util.setStatePromise(this, {
      shippingTypeItems,
      shippingName,
    })
  }

  _saveBtn = () => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    const { customCreateOrder, addCustomOrder, navigation } = this.props
    const { customCreateOrderData, store_id } = this.state
    // const callBack = navigation.state.params.callBackFromCustomOrder
    const params = util.getNavParams(this.props)
    const { callBackFromCustomOrder } = params
    const callBack = callBackFromCustomOrder
    const newCustomCreateOrderData = customCreateOrderData
    const { sender } = customCreateOrderData
    const { receiver } = customCreateOrderData
    const { shipping } = customCreateOrderData
    const newSender = {}
    const newReceiver = {}
    const newShipping = {}
    if (sender.v === 0) {
      const senderVI = _.isNil(sender.vi)
      const senderName = _.isNil(sender.sender_name) || sender.sender_name === ''
      if (senderVI && senderName) {
        p.op.showConfirmationOkOnly(
          'ชื่อผู้จัดส่ง จำเป็นต้องระบุ หากซ่อน',
          'หากต้องการซ่อนผู้ส่งในหน้าสร้างออเดอร์ กรุณาเลือก "ระบุ" และกรอก "ชื่อผู้ส่ง"'
        )
        this.inProcess = false
        return
      }
    }
    if (sender.a === 0) {
      // @ts-ignore
      newSender.a = sender.a
      // @ts-ignore
      newSender.v = sender.v
      // @ts-ignore
      newCustomCreateOrderData.sender = newSender
    } else if (_.isNil(sender.vi)) {
      if (_.isNil(sender.sender_name) || sender.sender_name === '') {
        p.op.showConfirmationOkOnly('ระบุชื่อผู้ส่ง', 'กรุณาระบุชื่อและที่อยู่ผู้ส่ง')
        this.inProcess = false
        return
      }
    }

    if (receiver.a === 0) {
      // @ts-ignore
      newReceiver.a = receiver.a
      // @ts-ignore
      newReceiver.t = receiver.t
      // @ts-ignore
      newReceiver.v = receiver.v
      // @ts-ignore
      newCustomCreateOrderData.receiver = newReceiver
    } else if (receiver.t === 0 || receiver.t === 1) {
      if (_.isNil(receiver.vs) || receiver.vs === '') {
        p.op.showConfirmationOkOnly('ระบุชื่อผู้รับ', 'กรุณาระบุชื่อผู้รับ')
        this.inProcess = false
        return
      }
    } else if (receiver.t === 2) {
      if (_.isNil(receiver.vi) || !_.isNumber(receiver.vi)) {
        p.op.showConfirmationOkOnly('ระบุชื่อผู้รับ', 'กรุณาระบุชื่อและที่อยู่ผู้รับ หรือเลือกตัวเลือกไม่ระบุ')
        this.inProcess = false
        return
      }
    }

    if (receiver.t === 0) {
      if (receiver.a !== 0) {
        const _newReceiverd: { [key: string]: any } = {}
        _newReceiverd.v = receiver.v
        _newReceiverd.a = receiver.a
        _newReceiverd.t = receiver.t
        _newReceiverd.vs = receiver.vs
        // @ts-ignore
        newCustomCreateOrderData.receiver = _newReceiverd
      }
    } else if (receiver.t === 1) {
      if (receiver.a !== 0) {
        const _newReceiverd: { [key: string]: any } = {}
        _newReceiverd.v = receiver.v
        _newReceiverd.a = receiver.a
        _newReceiverd.t = receiver.t
        _newReceiverd.p = receiver.p
        _newReceiverd.vs = receiver.vs
        // @ts-ignore
        newCustomCreateOrderData.receiver = _newReceiverd
      }
    } else if (receiver.t === 2) {
      if (receiver.a !== 0) {
        const _newReceiverd: { [key: string]: any } = {}
        _newReceiverd.v = receiver.v
        _newReceiverd.a = receiver.a
        _newReceiverd.t = receiver.t
        // @ts-ignore
        _newReceiverd.vi = receiver.vi
        // @ts-ignore
        newCustomCreateOrderData.receiver = _newReceiverd
      }
    }

    if (shipping.v === 0 && _.isNil(shipping.vi)) {
      p.op.showConfirmationOkOnly(
        'รูปแบบจัดส่ง จำเป็นต้องระบุ หากซ่อน',
        'หากต้องการซ่อน"รูปแบบจัดส่ง"ในหน้าสร้างออเดอร์ กรุณาเลือก "ระบุ" และ "รูปแบบจัดส่ง"'
      )
      this.inProcess = false
      return
    }
    if (shipping.a === 0) {
      // @ts-ignore
      newShipping.a = shipping.a
      // @ts-ignore
      newShipping.v = shipping.v
      // @ts-ignore
      newCustomCreateOrderData.shipping = newShipping
    }

    // date_delivery
    const dateDelivery = customCreateOrderData.date_delivery
    if (dateDelivery.v === 0) {
      delete newCustomCreateOrderData.date_delivery
    }

    // auto_cancel_order
    // const autoCancelOrder = customCreateOrderData.auto_cancel_order
    // if (autoCancelOrder.v === 0 && autoCancelOrder.m !== 'd') {
    //   delete newCustomCreateOrderData.auto_cancel_order
    // } else if (autoCancelOrder.m === 'd') {
    //   delete newCustomCreateOrderData.auto_cancel_order.m
    // }

    addCustomOrder({
      body: {
        store_id,
        pref_ui_order_create: newCustomCreateOrderData,
      },
      successCallback: () => {
        customCreateOrder(newCustomCreateOrderData)
        if (_.isFunction(callBack)) {
          callBack(newCustomCreateOrderData)
        }
        // navigation.setParams({
        //   onChangeData: false,
        // })
        p.op.showToast('บันทึกตั้งค่าเรียบร้อยแล้ว', 'success')
        // @ts-ignore
        // const { navigation } = this.props
        // const { goBack } = navigation
        // goBack()
        util.navGoBack(this.props)
        this.inProcess = false
      },
      failedCallback: () => {
        this.inProcess = false
        p.op.showToast('เกิดข้อผิดพลาด กรุณาทำรายการใหม่ภายหลัง', 'warning')
      },
    })
  }

  _onChangeData = () => {
    // const { navigation } = this.props
    this.setState({ onChangeData: true })
    // navigation.setParams({
    //   onChangeData: true,
    // })
    // const onChange = navigation.state.params.onChangeData
    // if (!_.isNil(onChange) && !onChange) {
    //   navigation.setParams({
    //     onChangeData: true,
    //   })
    // } else {
    //   return
    // }
  }

  // Reciever
  _onSegmentChangeChooseShowAndHideRecieverAdresses = (number: number) => {
    const { customCreateOrderData } = this.state
    if (customCreateOrderData.receiver.v === number) {
      return
    }
    const newCustomCreateOrderData = customCreateOrderData
    newCustomCreateOrderData.receiver.v = number
    this.setState({ customCreateOrderData: newCustomCreateOrderData })
    this._onChangeData()
  }

  _onSegmentChangeChooseRecieverAdresses = (number: number) => {
    const { customCreateOrderData } = this.state
    if (customCreateOrderData.receiver.a === number) {
      return
    }
    const newCustomCreateOrderData = customCreateOrderData
    newCustomCreateOrderData.receiver.a = number
    this.setState({ customCreateOrderData: newCustomCreateOrderData })
    this._onChangeData()
  }

  _onSegmentChangeChooseRecieverAdressesType = (number: number) => {
    const { customCreateOrderData } = this.state
    if (customCreateOrderData.receiver.t === number) {
      return
    }
    const newCustomCreateOrderData = customCreateOrderData
    newCustomCreateOrderData.receiver.t = number
    this.setState({ customCreateOrderData: newCustomCreateOrderData })
    this._onChangeData()
  }

  _onChangePhone = (text: string) => {
    const { customCreateOrderData } = this.state
    const newCustomCreateOrderData = customCreateOrderData
    newCustomCreateOrderData.receiver.p = text
    this.setState({ customCreateOrderData: newCustomCreateOrderData })
    this._onChangeData()
  }

  _onChangeReciever = (text: string) => {
    const { customCreateOrderData } = this.state
    const newCustomCreateOrderData = customCreateOrderData
    newCustomCreateOrderData.receiver.vs = text
    this.setState({ customCreateOrderData: newCustomCreateOrderData })
    this._onChangeData()
  }

  _addressStorePick = (address: any) => {
    const { customCreateOrderData } = this.state
    if (_.isNil(address) || _.isNil(address.id)) {
      return
    }
    const newCustomCreateOrderData = customCreateOrderData
    const { receiver } = customCreateOrderData
    const txtReceiverName = address.name
    let txtReceiverAddress = ''
    txtReceiverAddress = address.address1
    txtReceiverAddress = address.address2 ? `${txtReceiverAddress} ${address.address2}` : txtReceiverAddress
    txtReceiverAddress = address.sub_district ? `${txtReceiverAddress} ${address.sub_district}` : txtReceiverAddress
    txtReceiverAddress = address.district ? `${txtReceiverAddress} ${address.district}` : txtReceiverAddress
    txtReceiverAddress = address.province ? `${txtReceiverAddress} ${address.province}` : txtReceiverAddress
    txtReceiverAddress = address.postal_code ? `${txtReceiverAddress}\n${address.postal_code}` : txtReceiverAddress
    txtReceiverAddress = address.telephone ? `${txtReceiverAddress}\nโทร. ${address.telephone}` : txtReceiverAddress
    const newReceiver = {
      a: receiver.a,
      vi: address.id,
      v: receiver.v,
      t: receiver.t,
    }
    newCustomCreateOrderData.receiver = newReceiver
    this.setState({ customCreateOrderData: newCustomCreateOrderData, txtReceiverName, txtReceiverAddress })
    this._onChangeData()
  }

  _onChangeTextRecieverNameOnly = (text: string) => {
    const { customCreateOrderData } = this.state
    const newCustomCreateOrderData = customCreateOrderData
    const { receiver } = customCreateOrderData
    const newReceiver = {
      a: receiver.a,
      v: receiver.v,
      t: receiver.t,
      vs: text,
    }
    newCustomCreateOrderData.receiver = newReceiver
    // newCustomCreateOrderData.receiver.vs = text
    this.setState({ customCreateOrderData: newCustomCreateOrderData })
    this._onChangeData()
  }
  // Sender
  // _onSegmentChangeChooseUseOrNoUseSenderAdresses = (number: number) => {
  //   const { customCreateOrderData } = this.state
  //   if (customCreateOrderData.sender.u === number) {
  //     return
  //   }
  //   const newCustomCreateOrderData = customCreateOrderData
  //   newCustomCreateOrderData.sender.u = number
  //   this.setState({ customCreateOrderData: newCustomCreateOrderData })
  // }

  _onSegmentChangeChooseShowAndHideSenderAdresses = (number: number) => {
    const { customCreateOrderData } = this.state
    if (customCreateOrderData.sender.v === number) {
      return
    }
    const newCustomCreateOrderData = customCreateOrderData
    if (number === 0) {
      newCustomCreateOrderData.sender.a = 1
    }
    newCustomCreateOrderData.sender.v = number
    this.setState({ customCreateOrderData: newCustomCreateOrderData })
    this._onChangeData()
  }

  _onSegmentChangeChooseSenderAdresses = (number: number) => {
    const { customCreateOrderData } = this.state
    if (customCreateOrderData.sender.a === number) {
      return
    }
    const newCustomCreateOrderData = customCreateOrderData
    const { sender } = customCreateOrderData
    if (sender.v === 0 && number === 0) {
      p.op.showConfirmationOkOnly('โปรดระบุชื่อผู้จัดส่ง', 'หากท่านต้องการซ่อนผู้ส่งในหน้าสร้างออเดอร์ กรุณาระบุชื่อผู้ส่ง')
      return
    }
    newCustomCreateOrderData.sender.a = number
    this.setState({ customCreateOrderData: newCustomCreateOrderData })
    this._onChangeData()
  }

  _addressStorePickFromSender = (address: IAddress) => {
    // console.log('_addressStorePickFromSender', address)
    const { customCreateOrderData } = this.state
    const { sender } = customCreateOrderData
    const newCustomCreateOrderData = _.clone(customCreateOrderData)
    // newCustomCreateOrderData.sender.vi = address.id
    let newSender = {}
    if (_.isNil(address)) {
      return
    }
    if (_.isNil(address.id) && !_.isNil(address.address1)) {
      newSender = {
        a: sender.a,
        v: sender.v,
      }
    } else {
      newSender = {
        a: sender.a,
        vi: address.id,
        v: sender.v,
      }
    }
    // @ts-ignore
    newCustomCreateOrderData.sender = newSender
    newCustomCreateOrderData.sender.sender_name = address.name
    let txtSenderAdresses = ''
    txtSenderAdresses = address.address1
    txtSenderAdresses = address.address2 ? `${txtSenderAdresses} ${address.address2}` : txtSenderAdresses
    txtSenderAdresses = address.sub_district ? `${txtSenderAdresses} ${address.sub_district}` : txtSenderAdresses
    txtSenderAdresses = address.district ? `${txtSenderAdresses} ${address.district}` : txtSenderAdresses
    txtSenderAdresses = address.province ? `${txtSenderAdresses} ${address.province}` : txtSenderAdresses
    txtSenderAdresses = address.postal_code ? `${txtSenderAdresses}\n${address.postal_code}` : txtSenderAdresses
    txtSenderAdresses = address.telephone ? `${txtSenderAdresses}\nโทร. ${address.telephone}` : txtSenderAdresses
    // this.editingSenderName = false
    // console.log(address)
    this.setState({ customCreateOrderData: newCustomCreateOrderData, txtSenderName: address.name, txtSenderAdresses })
    this._onChangeData()
  }

  _onChangeTextSenderNameOnly = (text: string) => {
    const { customCreateOrderData } = this.state
    const { sender } = customCreateOrderData
    const newCustomCreateOrderData = customCreateOrderData
    // if (sender.v === 1 && sender.a === 1) {
    // this.editingSenderName = true
    const newSender = {
      a: sender.a,
      sender_name: text,
      v: sender.v,
    }
    if (!_.isNil(sender.vi)) {
      // @ts-ignore
      newSender.vi = sender.vi
    }
    newCustomCreateOrderData.sender = newSender
    this.setState({ customCreateOrderData: newCustomCreateOrderData })
    this._onChangeData()
    // }
    // else if (sender.v === 1 && sender.a === 1) {
    //   this.editingSenderName = true
    //   this.setState({ txtSenderName: text })
    // }
  }

  // Shipping
  _onSegmentChangeChooseShowAndHideShipping = (number: number) => {
    const { customCreateOrderData } = this.state
    if (customCreateOrderData.shipping.v === number) {
      return
    }
    const newCustomCreateOrderData = customCreateOrderData
    if (number === 0) {
      newCustomCreateOrderData.shipping.a = 1
    }
    newCustomCreateOrderData.shipping.v = number
    this.setState({ customCreateOrderData: newCustomCreateOrderData })
    this._onChangeData()
  }

  _onSegmentChangeChooseShipping = (number: number) => {
    const { customCreateOrderData } = this.state
    if (customCreateOrderData.shipping.a === number) {
      return
    }
    const { shipping } = customCreateOrderData
    if (shipping.v === 0 && number === 0) {
      p.op.showConfirmationOkOnly('โปรดระบุรูปแบบจัดส่ง', 'หากท่านต้องการซ่อนรูปแบบจัดส่งในหน้าสร้างออเดอร์')
      return
    }
    const newCustomCreateOrderData = customCreateOrderData
    newCustomCreateOrderData.shipping.a = number
    this.setState({ customCreateOrderData: newCustomCreateOrderData })
    this._onChangeData()
  }

  _onSegmentChangeShipping = (itemInfo) => {
    const { item, index } = itemInfo
    if (!item || !item.value) {
      return
    }
    // console.log('item => ', item)
    const newShippingTypeId = item.value
    const { customCreateOrderData } = this.state
    const shippingId = newShippingTypeId
    if (customCreateOrderData.shipping.vi === shippingId) {
      return
    }
    const newCustomCreateOrderData = _.clone(customCreateOrderData)
    newCustomCreateOrderData.shipping.vi = shippingId

    let shippingName
    const shipppingId = _.isNil(shippingId) ? null : shippingId
    if (!_.isNil(shipppingId)) {
      this.shippingTypeItems.map((data) => {
        if (shipppingId === data.value) {
          shippingName = data.label
        }
      })
    } else {
      shippingName = 'กรุณาเลือกรูปแบบจัดส่ง'
    }

    this.setState({ customCreateOrderData: newCustomCreateOrderData, shippingName })
    this._onChangeData()
  }

  // Ship Before Pay
  _onSegmentChangeChooseShowAndHideShippingBeforePay = (number: number) => {
    const { customCreateOrderData } = this.state
    if (customCreateOrderData.ship_before_pay.v === number) {
      return
    }
    const newCustomCreateOrderData = customCreateOrderData
    newCustomCreateOrderData.ship_before_pay.v = number
    this.setState({ customCreateOrderData: newCustomCreateOrderData })
    this._onChangeData()
  }

  _shipBeforePayIfNotCOD = (val: boolean) => {
    const { customCreateOrderData } = this.state
    const newCustomCreateOrderData = customCreateOrderData
    newCustomCreateOrderData.ship_before_pay.n_cod = val
    this.setState({ customCreateOrderData: newCustomCreateOrderData })
    this._onChangeData()
  }

  _shipBeforePayIfIsCOD = (val: boolean) => {
    const { customCreateOrderData } = this.state
    const newCustomCreateOrderData = customCreateOrderData
    newCustomCreateOrderData.ship_before_pay.is_cod = val
    this.setState({ customCreateOrderData: newCustomCreateOrderData })
    this._onChangeData()
  }

  // COD (now not use)
  _onSegmentChangeChooseShowAndHideShippingBeforePayCOD = (number: number) => {
    const { showAndHideShippingBeforePayCOD } = this.state
    if (showAndHideShippingBeforePayCOD === number) {
      return
    }
    this.setState({ showAndHideShippingBeforePayCOD: number })
    this._onChangeData()
  }

  // Sales Channel
  _onSegmentChangeChooseShowAndHideSalesChannel = (number: number) => {
    const { customCreateOrderData } = this.state
    if (customCreateOrderData.sales_channel.v === number) {
      return
    }
    const newCustomCreateOrderData = customCreateOrderData
    newCustomCreateOrderData.sales_channel.v = number
    this.setState({ customCreateOrderData: newCustomCreateOrderData })
    this._onChangeData()
  }

  _onSegmentChangeSalesChannel = (item: { key: string; index: number; label: string; value: number }) => {
    const { customCreateOrderData } = this.state
    const salesChannelId = item.value
    if (customCreateOrderData.sales_channel.vi === salesChannelId) {
      return
    }
    const newCustomCreateOrderData = customCreateOrderData
    newCustomCreateOrderData.sales_channel.vi = salesChannelId
    this.setState({ customCreateOrderData: newCustomCreateOrderData })
    this._onChangeData()
  }

  // Notes (ใบปะหน้าและหมายเหตุใบปะหน้าพัสดุ)
  _onSegmentChangeChooseShowAndHideCoverSheet = (number: number) => {
    const { customCreateOrderData } = this.state
    if (customCreateOrderData.notes.n_1.v === number) {
      return
    }
    const newCustomCreateOrderData = customCreateOrderData
    newCustomCreateOrderData.notes.n_1.v = number
    this.setState({ customCreateOrderData: newCustomCreateOrderData })
    this._onChangeData()
  }

  _onSegmentChangeChooseShowAndHideCoverSheetNote = (number: number) => {
    const { customCreateOrderData } = this.state
    if (customCreateOrderData.notes.n_2.v === number) {
      return
    }
    const newCustomCreateOrderData = customCreateOrderData
    newCustomCreateOrderData.notes.n_2.v = number
    this.setState({ customCreateOrderData: newCustomCreateOrderData })
    this._onChangeData()
  }

  // Auto Close SellOrder
  _onSegmentChangeChooseShowAndHideAutoCloseSellOrder = (number: number) => {
    const { customCreateOrderData } = this.state
    if (customCreateOrderData.auto_complete.v === number) {
      return
    }
    const newCustomCreateOrderData = customCreateOrderData
    newCustomCreateOrderData.auto_complete.v = number
    this.setState({ customCreateOrderData: newCustomCreateOrderData })
    this._onChangeData()
  }

  _autoCloseSellOrderValDefault = (val: boolean) => {
    const { customCreateOrderData } = this.state
    const newCustomCreateOrderData = customCreateOrderData
    newCustomCreateOrderData.auto_complete.d = val
    this.setState({ customCreateOrderData: newCustomCreateOrderData })
    this._onChangeData()
  }

  _onChangeSkipOrderDetailAutoCopy = (val: boolean) => {
    const { customCreateOrderData } = this.state
    const newCustomCreateOrderData = customCreateOrderData
    newCustomCreateOrderData.skip_order_detail_auto_copy.v = val ? 1 : 0
    this.setState({ customCreateOrderData: newCustomCreateOrderData })
    this._onChangeData()
  }

  _onChangeCheckDupReceiverAddr = (val: boolean) => {
    const { customCreateOrderData } = this.state
    const newCustomCreateOrderData = customCreateOrderData
    newCustomCreateOrderData.check_dup_receiver_addr = val
    this.setState({ customCreateOrderData: newCustomCreateOrderData })
    this._onChangeData()
  }

  _getFocusedOrder = () => {
    const { editingOrder, navigation } = this.props
    const sourceOrder = editingOrder
    const targetIndex = util.getNavParam(this.props, 'orderTabIndex', 0)
    return util.getFocusedOrder(sourceOrder, targetIndex)
  }

  _onSegmentChangeChooseShowAndHideDateDelivery = (number: number) => {
    const { customCreateOrderData } = this.state
    const newCustomCreateOrderData = _.clone(customCreateOrderData)
    newCustomCreateOrderData.date_delivery.v = number
    this.setState({ customCreateOrderData: newCustomCreateOrderData })
    this._onChangeData()
  }

  _onSegmentChangeChooseShowAndHideAutoCancelOrder = (number: number) => {
    const { customCreateOrderData } = this.state
    const newCustomCreateOrderData = _.clone(customCreateOrderData)
    newCustomCreateOrderData.auto_cancel_order.v = number
    this.setState({ customCreateOrderData: newCustomCreateOrderData })
    this._onChangeData()
  }

  _onSegmentChangeChooseAutoCancelOrder = (number: number) => {
    const { customCreateOrderData } = this.state
    const newCustomCreateOrderData = _.clone(customCreateOrderData)
    if (number === 1) {
      newCustomCreateOrderData.auto_cancel_order.m = 'd'
    } else {
      newCustomCreateOrderData.auto_cancel_order = {
        v: newCustomCreateOrderData.auto_cancel_order.v,
      }
    }

    this.setState({ customCreateOrderData: newCustomCreateOrderData })
    this._onChangeData()
  }

  navToCustomerList = () => {
    // if (p.op.isWeb()) {
    //   // @ts-ignore
    //   this.props.navigation.popToTop()
    // }
    this.props.navigation.dispatch(
      NavActions.navToCustomerList({
        mode: 'PICK_ADDRESS',
        callBackSelectedAddress: (address: IAddress) => this._addressStorePick(address),
      })
    )
  }
}
