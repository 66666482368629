/**
 * Created by sohobloo on 16/9/13.
 * from:: https://github.com/sohobloo/react-native-modal-dropdown/blob/master/components/XDropdown.js
 */
import React, { Component } from 'react'

// import { ViewStyle } from 'react-native'
import { Menu } from 'native-base'

import _ from 'lodash'
import XButton from './XButton'

// import * as util from 'x/utils/util'

// import XOverlay from './XOverlay'
// import PropTypes from 'prop-types'

// const TOUCHABLE_ELEMENTS = ['TouchableHighlight', 'TouchableOpacity', 'TouchableWithoutFeedback', 'TouchableNativeFeedback']

// interface IDropdownStyle extends ViewStyle {
//   height?: number // บังคับให้รู้กรอบ height
//   width: number // บังคับให้รู้กรอบ width
// }

interface IXDropdownProps<OptionItemType = string> {
  disabled?: boolean
  // scrollEnabled?: boolean
  // defaultIndex?: number
  // defaultValue?: OptionItemType | string
  options: OptionItemType[]

  // accessible?: boolean
  // animated?: boolean
  // showsVerticalScrollIndicator?: boolean
  // keyboardShouldPersistTaps?: boolean | 'always' | 'never' | 'handled'
  // disabledHilighted?: boolean

  // textStyle?: TextStyle
  // dropdownStyle: IDropdownStyle
  // dropdownTextStyle?: TextStyle
  // dropdownTextHighlightStyle?: TextStyle

  // adjustFrame?: (currentPosition: IAdjustPosition) => IAdjustPosition
  // renderRow?: (option: OptionItemType, index: number, isSelected: boolean) => JSX.Element
  renderRow?: (option: OptionItemType, index: number) => JSX.Element | string
  renderSeparator?: React.ComponentType<any> | null
  renderButtonText?: (rowData: OptionItemType) => string
  // renderButton?: (selectedRowID: number, selectedRowData: OptionItemType) => JSX.Element

  // onDropdownWillShow?: () => Promise<void>
  // onDropdownWillHide?: () => Promise<void>
  onSelect?: (rowID: number, rowData: OptionItemType) => void | Promise<void>
}

interface IXDropdownState {
  // accessible: boolean
  // loading: boolean
  // showDropdown: boolean
  // buttonText?: string
  // selectedIndex: number
  isOpen: boolean
}

// interface IAdjustPosition {
//   height?: number
//   width?: number
//   top?: number
//   right?: number
//   bottom?: number
//   left?: number
// }

export default class XDropdown<OptionItemType = string> extends Component<IXDropdownProps<OptionItemType>, IXDropdownState> {
  static displayName = 'XDropdown'
  // static propTypes = {
  //   disabled: PropTypes.bool,
  //   scrollEnabled: PropTypes.bool,
  //   defaultIndex: PropTypes.number,
  //   defaultValue: PropTypes.string,
  //   options: PropTypes.array,

  //   accessible: PropTypes.bool,
  //   animated: PropTypes.bool,
  //   showsVerticalScrollIndicator: PropTypes.bool,
  //   keyboardShouldPersistTaps: PropTypes.string,

  //   style: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.array]),
  //   textStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.array]),
  //   dropdownStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.array]),
  //   dropdownTextStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.array]),
  //   dropdownTextHighlightStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.array]),

  //   adjustFrame: PropTypes.func,
  //   renderRow: PropTypes.func,
  //   renderSeparator: PropTypes.func,
  //   renderButtonText: PropTypes.func,

  //   onDropdownWillShow: PropTypes.func,
  //   onDropdownWillHide: PropTypes.func,
  //   onSelect: PropTypes.func,
  // }

  static defaultProps = {
    disabled: false,
    // scrollEnabled: true,
    // defaultIndex: -1,
    // defaultValue: 'กรุณาเลือก...',
    options: null,
    // animated: true,
    // showsVerticalScrollIndicator: true,
    // keyboardShouldPersistTaps: 'never',
  }

  // _button: any

  // _buttonFrame: any

  // _nextValue: any

  // _nextIndex: any

  randomKey: string

  constructor(props: IXDropdownProps<OptionItemType>) {
    super(props)

    // this._button = null
    // this._buttonFrame = null
    // this._nextValue = null
    // this._nextIndex = null

    // const defaultOption: OptionItemType | string =
    //   _.isArray(props.options) && _.isNumber(props.defaultIndex) && props.options[props.defaultIndex]
    //     ? props.options[props.defaultIndex]
    //     : props.defaultValue

    // @ts-ignore
    // const buttonText: string = _.isFunction(props.renderButtonText) ? props.renderButtonText(defaultOption) : defaultOption

    this.randomKey = `${Math.floor(Math.random() * 10000)}`
    this.state = {
      // accessible: !!props.accessible,
      // loading: !props.options,
      // showDropdown: false,
      // buttonText,
      // selectedIndex: props.defaultIndex,
      isOpen: false,
    }
  }

  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   let { buttonText, selectedIndex } = this.state
  //   const { defaultIndex, defaultValue, options } = nextProps
  //   buttonText = this._nextValue == null ? buttonText : this._nextValue
  //   selectedIndex = this._nextIndex == null ? selectedIndex : this._nextIndex
  //   if (selectedIndex < 0) {
  //     selectedIndex = defaultIndex
  //     if (selectedIndex < 0) {
  //       buttonText = defaultValue
  //     }
  //   }
  //   this._nextValue = null
  //   this._nextIndex = null

  //   this.setState({
  //     loading: !options,
  //     buttonText,
  //     selectedIndex,
  //   })
  // }

  _renderDropdownItem = (opt: OptionItemType, index: number) => {
    // const { selectedIndex } = this.state
    const { renderRow } = this.props
    return (
      <Menu.Item
        key={`MenuItem_${this.randomKey}_${index.toString()}`}
        borderTopWidth={index !== 0 ? '1' : '0'}
        borderTopColor='gray.200'
        onPress={() => this.select(index)}>
        {/* @ts-ignore */}
        {_.isFunction(renderRow) ? renderRow(opt, index) : opt}
        {/* {_.isFunction(renderRow) ? renderRow(opt, index, selectedIndex === index) : opt} */}
      </Menu.Item>
    )
  }

  _renderDropdownItems = () => {
    const { options } = this.props
    return options.map(this._renderDropdownItem)
  }

  open = () => this.setState({ isOpen: true })

  close = () => this.setState({ isOpen: false })

  render() {
    const { isOpen = false } = this.state
    return (
      <Menu
        key={`Menu_${this.randomKey}`}
        isOpen={isOpen}
        onOpen={this.open}
        onClose={this.close}
        closeOnSelect
        trigger={this._renderTriggerButton}>
        {this._renderDropdownItems()}
      </Menu>
    )

    // return (
    //   <View {...this.props}>
    //     {this._renderButton()}
    //     {this._renderOverlay()}
    //   </View>
    // )
  }

  // _updatePosition = (callback) => {
  //   if (this._button && this._button.measure) {
  //     this._button.measure((fx, fy, width, height, px, py) => {
  //       this._buttonFrame = { x: px, y: py, w: width, h: height }
  //       callback && callback()
  //     })
  //   }
  // }

  // show() {
  //   this._updatePosition(() => {
  //     this.setState({
  //       showDropdown: true,
  //     })
  //   })
  // }

  // hide() {
  //   this.setState({
  //     showDropdown: false,
  //   })
  // }

  select = (idx) => {
    // console.log('select idx=', idx)
    const { options, onSelect } = this.props
    // const { defaultValue, options, defaultIndex, renderButtonText, onSelect } = this.props

    // const value = defaultValue
    // if (idx == null || !options || idx >= options.length) {
    //   idx = defaultIndex
    // }

    // if (idx >= 0) {
    //   value = renderButtonText ? renderButtonText(options[idx]) : options[idx].toString()
    // }

    // this._nextValue = value
    // this._nextIndex = idx

    if (onSelect && options[idx]) {
      onSelect(idx, options[idx] ? options[idx] : undefined)
    }

    this.setState({
      // buttonText: value as string,
      // selectedIndex: idx,
      isOpen: false,
    })
  }

  // _renderButton() {
  //   const { disabled, accessible, children, textStyle, options } = this.props
  //   const { buttonText, selectedIndex } = this.state

  //   return (
  //     <TouchableOpacity ref={(button) => (this._button = button)} disabled={disabled} accessible={accessible} onPress={this._onButtonPress}>
  //       {/* {_.isFunction(renderButton) ? renderButton(selectedIndex, options[selectedIndex]) : null} */}
  //       {children || (
  //         <View style={styles.button}>
  //           <Text style={[styles.buttonText, textStyle]} numberOfLines={1}>
  //             {buttonText}
  //           </Text>
  //         </View>
  //       )}
  //     </TouchableOpacity>
  //   )
  // }

  _renderTriggerButton = (triggerProps) => {
    // const { disabled = false, accessible, children, textStyle, options } = this.props
    // const { buttonText, selectedIndex } = this.state
    const { disabled = false, children } = this.props
    // const { buttonText } = this.state

    return (
      <XButton variant='ghost' isDisabled={disabled} {...triggerProps}>
        {children}
      </XButton>
    )

    // return (
    //   <Pressable isDisabled={disabled} {...triggerProps}>
    //     {children || (
    //       <View style={styles.button}>
    //         <Text style={[styles.buttonText, textStyle]} numberOfLines={1}>
    //           {buttonText}
    //         </Text>
    //       </View>
    //     )}
    //   </Pressable>
    // )
  }

  // _onButtonPress = async () => {
  //   const { onDropdownWillShow } = this.props
  //   if (_.isFunction(onDropdownWillShow)) {
  //     await onDropdownWillShow()
  //   }
  //   this.show()
  // }

  // _renderOverlay() {
  //   const { animated, accessible, dropdownStyle } = this.props
  //   const { showDropdown, loading } = this.state
  //   if (showDropdown && this._buttonFrame) {
  //     const frameStyle = this._calcPosition()
  //     const animationType = animated ? 'fade' : 'none'
  //     return (
  //       <XOverlay
  //         animationType={animationType}
  //         visible
  //         onRequestClose={this._onRequestClose}
  //         // @ts-ignore
  //         contentStyle={StyleSheet.flatten([styles.dropdown, dropdownStyle, frameStyle])}
  //         supportedOrientations={['portrait', 'portrait-upside-down', 'landscape', 'landscape-left', 'landscape-right']}>
  //         <View style={[styles.dropdown, dropdownStyle]}>{loading ? this._renderLoading() : this._renderDropdown()}</View>
  //       </XOverlay>
  //     )
  //   }
  // }

  // _calcPosition() {
  //   return Platform.OS === 'web' ? this._calcPositionWeb() : this._calcPositionNative()
  // }

  // _calcPositionNative() {
  //   const { dropdownStyle, adjustFrame } = this.props

  //   const dimensions = Dimensions.get('window')
  //   const windowWidth = dimensions.width
  //   const windowHeight = dimensions.height

  //   const dropdownHeight = (dropdownStyle && StyleSheet.flatten(dropdownStyle).height) || StyleSheet.flatten(styles.dropdown).height

  //   const bottomSpace = windowHeight - this._buttonFrame.y - this._buttonFrame.h
  //   const rightSpace = windowWidth - this._buttonFrame.x
  //   const showInBottom = bottomSpace >= dropdownHeight || bottomSpace >= this._buttonFrame.y
  //   const showInLeft = rightSpace >= this._buttonFrame.x

  //   const positionStyle: IAdjustPosition = {
  //     height: dropdownHeight,
  //     top: showInBottom ? this._buttonFrame.y + this._buttonFrame.h : Math.max(0, this._buttonFrame.y - dropdownHeight),
  //   }

  //   if (showInLeft) {
  //     positionStyle.left = this._buttonFrame.x
  //   } else {
  //     const dropdownWidth = (dropdownStyle && StyleSheet.flatten(dropdownStyle).width) || -1
  //     if (dropdownWidth !== -1) {
  //       positionStyle.width = dropdownWidth
  //     }
  //     positionStyle.right = rightSpace - this._buttonFrame.w
  //   }

  //   return adjustFrame ? adjustFrame(positionStyle) : positionStyle
  // }

  // _calcPositionWeb = () => {
  //   const { dropdownStyle, adjustFrame } = this.props

  //   const w = window
  //   const d = document
  //   const { documentElement } = d
  //   const body = d.getElementsByTagName('body')[0]
  //   const windowWidth = w.innerWidth || documentElement.clientWidth || body.clientWidth
  //   const windowHeight = w.innerHeight || documentElement.clientHeight || body.clientHeight

  //   const ddHeight = (dropdownStyle && StyleSheet.flatten(dropdownStyle).height) || StyleSheet.flatten(styles.dropdown).height
  //   const dropdownHeight = _.isString(ddHeight) ? parseInt(ddHeight) : ddHeight

  //   const bottomSpace = windowHeight - this._buttonFrame.y - this._buttonFrame.h
  //   const rightSpace = windowWidth - this._buttonFrame.x
  //   const showInBottom = bottomSpace >= dropdownHeight || bottomSpace >= this._buttonFrame.y
  //   const showInLeft = rightSpace >= this._buttonFrame.x

  //   const positionStyle: IAdjustPosition = {
  //     height: dropdownHeight,
  //     top: showInBottom ? this._buttonFrame.y + this._buttonFrame.h : Math.max(0, this._buttonFrame.y - dropdownHeight),
  //   }

  //   if (showInLeft) {
  //     positionStyle.left = this._buttonFrame.x
  //   } else {
  //     const dropdownWidth = (dropdownStyle && StyleSheet.flatten(dropdownStyle).width) || -1
  //     if (dropdownWidth !== -1) {
  //       positionStyle.width = _.isString(dropdownWidth) ? parseInt(dropdownWidth) : dropdownWidth
  //     }
  //     positionStyle.right = rightSpace - this._buttonFrame.w
  //   }

  //   return adjustFrame ? adjustFrame(positionStyle) : positionStyle
  // }

  // _onRequestClose = async () => {
  //   const { onDropdownWillHide } = this.props
  //   if (_.isFunction(onDropdownWillHide)) {
  //     await onDropdownWillHide()
  //   }
  //   this.hide()
  // }

  // _renderLoading() {
  //   return <ActivityIndicator size='small' />
  // }

  // _renderDropdown() {
  //   const { options, scrollEnabled, renderSeparator, showsVerticalScrollIndicator, keyboardShouldPersistTaps } = this.props
  //   return (
  //     <FlatList
  //       scrollEnabled={scrollEnabled}
  //       style={styles.list}
  //       data={options}
  //       renderItem={this._renderItem}
  //       // renderSeparator={renderSeparator || this._renderSeparator}
  //       ItemSeparatorComponent={renderSeparator || this._renderSeparator}
  //       keyExtractor={this._keyExtractor}
  //       automaticallyAdjustContentInsets={false}
  //       showsVerticalScrollIndicator={showsVerticalScrollIndicator}
  //       keyboardShouldPersistTaps={keyboardShouldPersistTaps}
  //     />
  //   )
  // }

  // _keyExtractor = (item, index) => index.toString()

  // // get _dataSource() {
  // //   const { options } = this.props
  // //   const ds = new ListView.DataSource({
  // //     rowHasChanged: (r1, r2) => r1 !== r2,
  // //   })
  // //   return ds.cloneWithRows(options)
  // // }

  // _renderItem = (itemInfo: ListRenderItemInfo<OptionItemType>) => {
  //   const { renderRow, dropdownTextStyle, dropdownTextHighlightStyle, accessible, renderButtonText } = this.props
  //   const { selectedIndex } = this.state

  //   const rowID = itemInfo.index
  //   const rowData = itemInfo.item
  //   const highlightRow = itemInfo.separators.highlight

  //   const key = `row_${rowID}`
  //   const highlighted = rowID === selectedIndex

  //   const rowText = renderButtonText ? renderButtonText(rowData) : rowData
  //   const row = !renderRow ? (
  //     <Text
  //       style={[styles.rowText, dropdownTextStyle, highlighted && styles.highlightedRowText, highlighted && dropdownTextHighlightStyle]}>
  //       {rowText}
  //     </Text>
  //   ) : (
  //     renderRow(rowData, rowID, highlighted)
  //   )
  //   const preservedProps = {
  //     key,
  //     accessible,
  //     onPress: () => this._onRowPress(rowData, rowID, highlightRow),
  //   }
  //   if (TOUCHABLE_ELEMENTS.find((name) => name === row.type.displayName)) {
  //     const props = { ...row.props }
  //     props.key = preservedProps.key
  //     props.onPress = preservedProps.onPress
  //     const { children } = row.props
  //     switch (row.type.displayName) {
  //       case 'TouchableOpacity': {
  //         return <TouchableOpacity {...props}>{children}</TouchableOpacity>
  //       }
  //       case 'TouchableHighlight': {
  //         return <TouchableHighlight {...props}>{children}</TouchableHighlight>
  //       }
  //       case 'TouchableWithoutFeedback': {
  //         return <TouchableWithoutFeedback {...props}>{children}</TouchableWithoutFeedback>
  //       }
  //       case 'TouchableNativeFeedback': {
  //         return <TouchableNativeFeedback {...props}>{children}</TouchableNativeFeedback>
  //       }
  //       default:
  //         break
  //     }
  //   }
  //   return <TouchableOpacity {...preservedProps}>{row}</TouchableOpacity>
  // }

  // async _onRowPress(rowData, rowID, highlightRow) {
  //   const { onSelect, renderButtonText, onDropdownWillHide, disabledHilighted = false } = this.props
  //   await this._onRequestClose()
  //   await util.setStatePromise(this, { showDropdown: false })
  //   await util.delay(100)
  //   if (!disabledHilighted) {
  //     highlightRow()
  //   }
  //   if (_.isFunction(onSelect)) {
  //     onSelect(rowID, rowData)
  //   }
  //   const value = (renderButtonText && renderButtonText(rowData)) || rowData.toString()
  //   this._nextValue = value
  //   this._nextIndex = rowID
  //   await util.setStatePromise(this, { buttonText: value, selectedIndex: rowID })
  // }

  // _renderSeparator = () => <View style={styles.separator} />
}

// const styles = StyleSheet.create({
//   button: {
//     justifyContent: 'center',
//   },
//   buttonText: {
//     fontSize: 12,
//   },
//   modal: {
//     flexGrow: 1,
//   },
//   dropdown: {
//     position: 'absolute',
//     height: (33 + StyleSheet.hairlineWidth) * 5,
//     borderWidth: StyleSheet.hairlineWidth,
//     borderColor: 'lightgray',
//     borderRadius: 2,
//     backgroundColor: 'white',
//     justifyContent: 'center',
//   },
//   loading: {
//     alignSelf: 'center',
//   },
//   list: {
//     flexGrow: 1,
//   },
//   rowText: {
//     paddingHorizontal: 6,
//     paddingVertical: 10,
//     fontSize: 11,
//     color: 'gray',
//     backgroundColor: 'white',
//     textAlignVertical: 'center',
//   },
//   highlightedRowText: {
//     color: COLORS.APP_MAIN,
//   },
//   separator: {
//     height: StyleSheet.hairlineWidth,
//     backgroundColor: 'lightgray',
//   },
// })
