import React from 'react'
import { View, TouchableOpacity, Linking, Clipboard, Image, Platform } from 'react-native'

import _ from 'lodash'

import { STYLES, COLORS } from 'x/config/styles'
import SETTINGS from 'x/config/settings'
import p from 'x/config/platform-specific'
import XIcon from './XIcon'
import VStack from './VStack'
import HStack from './HStack'
import XText from './XText'
import Box from './Box'

const {
  XSELLY_CONTACT_TEL,
  XSELLY_CONTACT_TEL_DISPLAY,
  XSELLY_CONTACT_EMAIL,
  XSELLY_CONTACT_FB_FANPAGE_DEEPLINK,
  XSELLY_CONTACT_FB_FANPAGE_URL,
  XSELLY_CONTACT_FB_LINE_URL,
  XSELLY_CONTACT_FB_MESSENGER_URL,
  XSELLY_CONTACT_FB_LINE_ID,
} = SETTINGS

interface ContactRowProps {
  iconComponent: JSX.Element
  label: string
  style?: { [key: string]: any }
  targetURL?: string
  onPress?: () => void
  txtClipboard?: string
  txtOnClipboardSuccess?: string
}

function getPhoneUrl() {
  const options = { number: XSELLY_CONTACT_TEL, prompt: true }
  return `${Platform.OS === 'ios' && options.prompt ? 'telprompt:' : 'tel:'}${options.number}`
}

const ContactButtons = () => {
  if (Platform.OS === 'ios') {
    return null
  }
  // style={{ flex: 1, alignSelf: 'stretch' }}
  return (
    <VStack w='full' space='2'>
      <ContactRow
        key='call'
        targetURL={getPhoneUrl()}
        txtClipboard={XSELLY_CONTACT_TEL}
        txtOnClipboardSuccess={`คัดลอกเบอร์ติดต่อ ${XSELLY_CONTACT_TEL_DISPLAY} แล้ว`}
        label={XSELLY_CONTACT_TEL_DISPLAY}
        iconComponent={
          <XIcon
            name='call'
            family='Ionicons'
            style={{
              color: COLORS.BRAND_Info,
              fontSize: STYLES.FONT_ICON_LARGER,
            }}
          />
        }
        // style={{ paddingVertical: 1 }}
      />
      <ContactRow
        key='line'
        targetURL={XSELLY_CONTACT_FB_LINE_URL}
        txtClipboard={XSELLY_CONTACT_FB_LINE_ID}
        txtOnClipboardSuccess={`คัดลอก ${XSELLY_CONTACT_FB_LINE_ID} แล้ว`}
        label='แชทผ่าน @XSelly'
        iconComponent={
          <Image
            resizeMode='cover'
            style={{
              width: STYLES.FONT_ICON_LARGER,
              height: STYLES.FONT_ICON_LARGER,
            }}
            source={require('../img/about/LINE_Icon.png')}
          />
        }
      />
      <ContactRow
        key='messenger'
        onPress={() => {
          Linking.canOpenURL(XSELLY_CONTACT_FB_MESSENGER_URL).then((supported) => {
            if (supported) {
              Linking.openURL(XSELLY_CONTACT_FB_MESSENGER_URL)
            } else {
              Clipboard.setString(XSELLY_CONTACT_FB_MESSENGER_URL)
              p.op.showToast(`คัดลอก ${XSELLY_CONTACT_FB_MESSENGER_URL} แล้ว`, 'success')
            }
          })
        }}
        label='แชทผ่าน Messenger'
        iconComponent={
          <Image
            resizeMode='cover'
            style={{
              width: STYLES.FONT_ICON_LARGER,
              height: STYLES.FONT_ICON_LARGER,
            }}
            source={require('../img/about/Messenger_Icon.png')}
          />
        }
      />
      <ContactRow
        key='facebook'
        onPress={() => {
          if (p.op.isWeb()) {
            Linking.canOpenURL(XSELLY_CONTACT_FB_FANPAGE_URL).then((supported) => {
              if (supported) {
                Linking.openURL(XSELLY_CONTACT_FB_FANPAGE_URL)
              } else {
                Clipboard.setString(XSELLY_CONTACT_FB_FANPAGE_URL)
                p.op.showToast(`คัดลอก ${XSELLY_CONTACT_FB_FANPAGE_URL} แล้ว`, 'success')
              }
            })
          } else {
            Linking.canOpenURL(XSELLY_CONTACT_FB_FANPAGE_DEEPLINK).then((supportedNative) => {
              if (supportedNative) {
                Linking.openURL(XSELLY_CONTACT_FB_FANPAGE_DEEPLINK)
              } else {
                Linking.canOpenURL(XSELLY_CONTACT_FB_FANPAGE_URL).then((supported) => {
                  if (supported) {
                    Linking.openURL(XSELLY_CONTACT_FB_FANPAGE_URL)
                  } else {
                    Clipboard.setString(XSELLY_CONTACT_FB_FANPAGE_URL)
                    p.op.showToast(`คัดลอก ${XSELLY_CONTACT_FB_FANPAGE_URL} แล้ว`, 'success')
                  }
                })
              }
            })
          }
        }}
        label='แฟนเพจ XSelly'
        iconComponent={
          <Image
            resizeMode='cover'
            style={{
              width: STYLES.FONT_ICON_LARGER,
              height: STYLES.FONT_ICON_LARGER,
            }}
            source={require('../img/about/FB-f-Logo__blue_100.png')}
          />
        }
      />
      <ContactRow
        key='email'
        targetURL={`mailto:${XSELLY_CONTACT_EMAIL}`}
        txtClipboard={XSELLY_CONTACT_EMAIL}
        txtOnClipboardSuccess={`คัดลอกอีเมล์ ${XSELLY_CONTACT_EMAIL} แล้ว`}
        label={XSELLY_CONTACT_EMAIL}
        iconComponent={
          <XIcon
            name='mail'
            family='Ionicons'
            style={{
              color: COLORS.BRAND_Warning,
              fontSize: STYLES.FONT_ICON_LARGEST,
            }}
          />
        }
        style={{ paddingVertical: 1 }}
      />
    </VStack>
  )
}

const ContactRow = ({
  iconComponent,
  label,
  style = {},
  targetURL,
  onPress = null,
  txtClipboard = null,
  txtOnClipboardSuccess = null,
}: ContactRowProps) => (
  <TouchableOpacity
    onPress={() => {
      if (_.isFunction(onPress)) {
        onPress()
      } else if (_.isString(targetURL)) {
        Linking.canOpenURL(targetURL).then((supported) => {
          if (supported) {
            Linking.openURL(targetURL)
          } else {
            if (txtClipboard) {
              Clipboard.setString(txtClipboard)
            }
            if (txtOnClipboardSuccess) {
              p.op.showToast(txtOnClipboardSuccess, 'success')
            }
          }
        })
      } else {
        // nothing to do
      }
    }}
    style={{
      flex: 1,
      // height: 45,
      // backgroundColor: COLORS.APP_SECONDARY,
      // backgroundColor: COLORS.BG_LIGHT_GREY,
      backgroundColor: 'white',
      // borderWidth: 1,
      borderTopWidth: 1,
      borderBottomWidth: 1,
      borderLeftWidth: 1,
      borderRightWidth: 1,
      borderColor: COLORS.APP_MAIN,
      borderRadius: 4,
      // paddingVertical: 4,
      // paddingLeft: 10,
      // paddingRight: 0,
      // marginBottom: 10,
      ...style,
    }}>
    <HStack w='full' py='2' px='2'>
      <Box w='9'>{iconComponent}</Box>
      <View
        style={{
          flex: 1,
          alignItems: 'flex-start',
          justifyContent: 'center',
          paddingLeft: 4,
        }}>
        <XText allowFontScaling={false}>{label}</XText>
      </View>
      <View
        style={{
          marginRight: 6,
          width: 24,
          justifyContent: 'center',
          alignItems: 'flex-end',
        }}>
        <XIcon
          name='arrow-forward'
          family='Ionicons'
          style={{
            color: COLORS.APP_MAIN,
          }}
        />
      </View>
    </HStack>
  </TouchableOpacity>
)

export default ContactButtons
