import BaseUIStoreSettingXShippingView from 'xui/modules/xshipping/BaseUIStoreSettingXShippingView'

class StoreSettingXShippingView extends BaseUIStoreSettingXShippingView {
  static displayName = 'StoreSettingXShippingView'

  static navigationOptions = {
    header: null,
    title: null,
    headerLeft: null,
    headerRight: null,
  }
}

export default StoreSettingXShippingView
