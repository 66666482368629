import React from 'react'
import { View, Text, ScrollView } from 'react-native'
import _ from 'lodash'
import HStack from 'xui/components/HStack'
import Spinner from 'xui/components/XSpinner'
import { fromJS } from 'immutable'

import { STYLES, S, COLORS } from 'x/config/styles'
import CONS from 'x/config/constants'
import * as util from 'x/utils/util'
import p from 'x/config/platform-specific'
import {
  IAutoCompleteOrderTabItemsViewNavParams,
  IBaseBasicOrdersFilter,
  IPaymentAccountMap,
  ISelectedStoreMap,
  ActionApiParams,
  IPaymentAccount,
  IXScreenProps,
} from 'x/index'

import BaseBasicOrderFilterView from 'xui/components/BaseBasicOrderFilterView'

import dayjs from 'dayjs'
import PaymentAccountPicker from 'xui/components/PaymentAccountPicker'
import XButton from 'xui/components/XButton'
import XText from 'xui/components/XText'
import VStack from 'xui/components/VStack'
import XCustomHeader from '../../components/XCustomHeader'
import XContainer from '../../components/XContainer'

// const IS_WEB = Platform.OS === 'web'

const initSettingBaseBasicOrderFilter: IBaseBasicOrdersFilter = {
  isCOD: null,
  isShipBeforePay: null,
  hasPrintedAt: null,
  segmentHasPrintedAtIndex: 0,
  printedAtFrom: null,
  printedAtTo: null,

  segmentCreateAtIndex: 0,
  createOrderFrom: null,
  createOrderTo: null,

  segmentDateDeliveryIndex: 0,
  hasDateDelivery: null,
  dateDeliveryFrom: null,
  dateDeliveryTo: null,

  segmentAutoCancelOrderIndex: 0,
  hasExpirationDate: null,
  AutoCancelOrderFrom: null,
  AutoCancelOrderTo: null,

  segmentReadyToShipIndex: 0,
  hasReadyToShipDate: null,
  segmentReadyToShipForDateTimeIndex: 0,
  readyToShipDateFrom: null,
  readyToShipDateTo: null,
}
interface IProps extends IXScreenProps<IAutoCompleteOrderTabItemsViewNavParams> {
  selectedStore: ISelectedStoreMap
  orderCount: (params: ActionApiParams) => void
  autoCompleteBySegment: (params: ActionApiParams) => void
}

interface IState {
  isInitialized: boolean
  callBackFromBaseBasicOrderFilter: IBaseBasicOrdersFilter
  bankAccount: IPaymentAccountMap
}

export default class AutoCompletePaymentBySegmentView extends React.Component<IProps, IState> {
  static displayName = 'AutoCompletePaymentBySegmentView'

  baseBasicOrderFilterRef: any

  inProcess: boolean

  constructor(props) {
    super(props)
    this.state = {
      isInitialized: false,
      callBackFromBaseBasicOrderFilter: initSettingBaseBasicOrderFilter,
      bankAccount: null,
    }
    this.baseBasicOrderFilterRef = React.createRef()
    this.inProcess = false
  }

  componentDidMount() {
    // this.fetchOrderListSummary()
  }

  setInProcess = () => {
    this.inProcess = true
  }

  unSetInProcess = () => {
    this.inProcess = false
  }

  _goBack = () => {
    util.navGoBack(this.props)
  }

  callBackFromBaseBasicOrderFilterView = (setting: Partial<IBaseBasicOrdersFilter>) => {
    // console.log('setting = > !! ', setting)
    // @ts-ignore FIXME: @Artid type ไม่ถูกต้อง
    util.setStatePromise(this, {
      callBackFromBaseBasicOrderFilter: setting,
    })
  }

  _onNavToSellerStorePaymentAccount = () => {
    // const { selectedStore } = this.props
    // // console.log('_onNavToSellerStorePaymentAccount * => ')
    // this.props.navigation.dispatch(
    //   NavActions.navToSellerStorePaymentAccountListView({
    //     store_id: selectedStore.get('id'),
    //     title: 'เลือกบัญชีเพื่อรับชำระ',
    //     selectAccountCallback: (acc: IPaymentAccountMap) => util.setStatePromise(this, { bankAccount: acc }),
    //   })
    // )
  }

  renderBankAccountCardView = () => {
    const { selectedStore } = this.props
    // const { bankAccount } = this.state
    return (
      <VStack>
        <View style={{ height: 24 }}>
          <Text style={{ fontSize: STYLES.FONT_SIZE_LARGER, color: COLORS.TEXT_ACTIVE }}>เลือกบัญชีเพื่อรับชำระ</Text>
        </View>
        {/* <BankAccountCard
          onPress={() => this._onNavToSellerStorePaymentAccount()}
          emptyBankAccountText={'เลือกบัญชีบันทึกรับชำระ'}
          bankAccount={bankAccount}
        /> */}
        <HStack w='full'>
          <PaymentAccountPicker
            targetStoreId={selectedStore.get('id')}
            onChangePaymentAccount={this._onChangePaymentAccount}
            placeholderText='เลือกบัญชีบันทึกรับชำระ'
          />
        </HStack>
      </VStack>
    )
  }

  _onChangePaymentAccount = (selectedPaymentAccount: IPaymentAccount) => {
    this.setState({ bankAccount: fromJS(selectedPaymentAccount) })
    return selectedPaymentAccount
  }

  renderConditionTitle = () => {
    const { bankAccount } = this.state
    return (
      <View style={{ height: 20, marginTop: 10 }}>
        <XText style={{ fontSize: STYLES.FONT_SIZE_LARGER, color: COLORS.TEXT_ACTIVE }}>เงื่อนไขออเดอร์</XText>
        {_.isNil(bankAccount) ? (
          <XText style={{ fontSize: STYLES.FONT_SIZE_SMALLER, color: COLORS.TEXT_INACTIVE }}>
            กรุณาเลือกบัญชีเพื่อเปิดใช้งานเงื่อนไขการรับชำระ
          </XText>
        ) : null}
      </View>
    )
  }

  renderBaseBasicOrderFilterView = () => (
    <BaseBasicOrderFilterView
      ref={this.baseBasicOrderFilterRef}
      onChange={(setting: Partial<IBaseBasicOrdersFilter>) => this.callBackFromBaseBasicOrderFilterView(setting)}
    />
  )

  _renderContent = () => {
    const { isInitialized, bankAccount } = this.state
    if (isInitialized) {
      return <Spinner style={{ flex: 1, alignSelf: 'center', width: '100%' }} color={COLORS.TEXT_INACTIVE} />
    }
    return (
      <View style={[S.PADDING_8]}>
        {this.renderBankAccountCardView()}
        {this.renderConditionTitle()}
        {_.isNil(bankAccount) ? null : this.renderBaseBasicOrderFilterView()}
      </View>
    )
  }

  renderFooter = () => (
    <HStack w='full' p='1'>
      <XButton w='full' onPress={() => this.checkOrderCount()}>
        ดูจำนวนออเดอร์ที่เข้าเงื่อนไข
      </XButton>
    </HStack>
  )

  checkOrderCount = async () => {
    if (this.inProcess) {
      return
    }
    this.setInProcess()
    const { selectedStore, orderCount } = this.props
    const { bankAccount, callBackFromBaseBasicOrderFilter } = this.state

    if (_.isNil(bankAccount)) {
      p.op.showConfirmationOkOnly('เลือกบัญชีรับชำระ', 'กรุณาเลือกบัญชีรับชำระ', () => this._onNavToSellerStorePaymentAccount())
      this.unSetInProcess()
      return
    }

    if (_.isNil(callBackFromBaseBasicOrderFilter)) {
      p.op.showConfirmationOkOnly('เกิดข้อผิดพลาด', 'กรุณาเลือกเงื่อนไขใหม่ แล้วทำรายการใหม่อีกครั้ง')
      this.unSetInProcess()
      return
    }

    let createAtfrom = null
    let createAtTo = null
    if (!_.isNil(callBackFromBaseBasicOrderFilter.createOrderFrom)) {
      createAtfrom = util.changeSecondTime(callBackFromBaseBasicOrderFilter.createOrderFrom, 0)
      createAtfrom = util.changeMillisecondsTime(createAtfrom, 0)
      createAtTo = util.changeSecondTime(callBackFromBaseBasicOrderFilter.createOrderTo, 59)
      createAtTo = util.changeMillisecondsTime(createAtTo, 999)
    }

    // const createAtFrom = moment(callBackFromBaseBasicOrderFilter.createOrderFrom).format(CONS.SERVER_DATETIME_FORMAT_WITH_MILLISECONDS)

    const body: { [key: string]: any } = {
      store_id: selectedStore.get('id'),
      order_segment: 'cust_and_reseller_to_pay_me_and_not_mkp',
    }

    if (!_.isNil(createAtfrom)) {
      body.created_at_from = dayjs(createAtfrom).format(CONS.SERVER_DATETIME_FORMAT_WITH_MILLISECONDS)
      body.created_at_to = dayjs(createAtTo).format(CONS.SERVER_DATETIME_FORMAT_WITH_MILLISECONDS)
    }

    if (!_.isNil(callBackFromBaseBasicOrderFilter.isCOD)) {
      body.isCOD = callBackFromBaseBasicOrderFilter.isCOD
    }

    if (!_.isNil(callBackFromBaseBasicOrderFilter.isShipBeforePay)) {
      body.isShipBeforePay = callBackFromBaseBasicOrderFilter.isShipBeforePay
    }

    if (!_.isNil(callBackFromBaseBasicOrderFilter.segmentHasPrintedAtIndex) && !_.isNil(callBackFromBaseBasicOrderFilter.hasPrintedAt)) {
      const { hasPrintedAt } = callBackFromBaseBasicOrderFilter
      body.has_printed_at = hasPrintedAt

      if (hasPrintedAt && callBackFromBaseBasicOrderFilter.segmentHasPrintedAtIndex !== 0) {
        let printedAtfrom = util.changeSecondTime(callBackFromBaseBasicOrderFilter.printedAtFrom, 0)
        printedAtfrom = util.changeMillisecondsTime(printedAtfrom, 0)
        let printedAtTo = util.changeSecondTime(callBackFromBaseBasicOrderFilter.printedAtTo, 59)
        printedAtTo = util.changeMillisecondsTime(printedAtTo, 999)
        body.printed_at_from = dayjs(printedAtfrom).format(CONS.SERVER_DATETIME_FORMAT_WITH_MILLISECONDS)
        body.printed_at_to = dayjs(printedAtTo).format(CONS.SERVER_DATETIME_FORMAT_WITH_MILLISECONDS)
      }
    }

    // สภานะพร้อมส่ง
    if (callBackFromBaseBasicOrderFilter.hasReadyToShipDate === false) {
      body.has_ready_to_ship_date = false
    } else if (callBackFromBaseBasicOrderFilter.hasReadyToShipDate) {
      const segmentReadyToShipForDateTimeIndex = _.isNil(callBackFromBaseBasicOrderFilter.segmentReadyToShipForDateTimeIndex)
        ? 0
        : callBackFromBaseBasicOrderFilter.segmentReadyToShipForDateTimeIndex
      if (segmentReadyToShipForDateTimeIndex === 0) {
        body.has_ready_to_ship_date = true
      } else {
        body.ready_to_ship_date_from = callBackFromBaseBasicOrderFilter.readyToShipDateFrom.format(CONS.SERVER_DATETIME_FORMAT)
        body.ready_to_ship_date_to = callBackFromBaseBasicOrderFilter.readyToShipDateTo.format(CONS.SERVER_DATETIME_FORMAT)
      }
    }
    // กำหนดส่ง
    if (callBackFromBaseBasicOrderFilter.hasDateDelivery === false) {
      body.has_date_delivery = false
    } else if (callBackFromBaseBasicOrderFilter.hasDateDelivery) {
      const segmentDateDeliveryIndex = _.isNil(callBackFromBaseBasicOrderFilter.segmentDateDeliveryIndex)
        ? 0
        : callBackFromBaseBasicOrderFilter.segmentDateDeliveryIndex
      if (segmentDateDeliveryIndex === 0) {
        body.has_date_delivery = true
      } else {
        body.date_delivery_from = callBackFromBaseBasicOrderFilter.dateDeliveryFrom.format(CONS.SERVER_DATETIME_FORMAT)
        body.date_delivery_to = callBackFromBaseBasicOrderFilter.dateDeliveryTo.format(CONS.SERVER_DATETIME_FORMAT)
      }
    }

    //  ยกเลิกออเดอร์อัตโนมัติ
    if (callBackFromBaseBasicOrderFilter.hasExpirationDate === false) {
      body.has_expiration_date = false
    } else if (callBackFromBaseBasicOrderFilter.hasExpirationDate) {
      const segmentAutoCancelOrderIndex = _.isNil(callBackFromBaseBasicOrderFilter.segmentAutoCancelOrderIndex)
        ? 0
        : callBackFromBaseBasicOrderFilter.segmentAutoCancelOrderIndex
      if (segmentAutoCancelOrderIndex === 0) {
        body.has_expiration_date = true
      } else {
        body.expiration_date_from = callBackFromBaseBasicOrderFilter.AutoCancelOrderFrom.format(CONS.SERVER_DATETIME_FORMAT)
        body.expiration_date_to = callBackFromBaseBasicOrderFilter.AutoCancelOrderTo.format(CONS.SERVER_DATETIME_FORMAT)
      }
    }

    const res: { count: number; limit: number } = await new Promise((submitted) => {
      orderCount({
        body,
        successCallback: submitted,
        failedCallback: submitted,
      })
    })

    if (!_.isNil(res.count)) {
      if (res.count < 1) {
        p.op.showConfirmationOkOnly('', 'ไม่พบออเดอร์ตามเงื่อนไขที่ระบุ')
        this.unSetInProcess()
        return
      }
      if (res.count > res.limit) {
        p.op.showConfirmationOkOnly(
          'ไม่สามารถบันทึกรับชำระอัตโนมัติได้',
          `พบ ${res.count} ออเดอร์ที่เข้าเงื่อนไขบันทึกรับชำระอัตโนมัติ ซึ่งมากกว่าที่ระบบจะรองรับได้ กรุณาปรับเงื่อนไขการบันทึกรับชำระแล้วลองใหม่อีกครั้ง\n
          (จำนวนออเดอร์ที่ระบบรองรับได้ตอนนี้คือ ${res.limit} ออเดอร์)`
        )
        this.unSetInProcess()
        return
      }
      p.op.showConfirmation(
        `พบ ${res.count} ออเดอร์ตามเงื่อนไข`,
        'กดปุ่ม ดำเนินการต่อ เพื่อยืนยันทำรายการบันทึกรับชำระอัตโนมัติทันที',
        () => this.autoCompleteBySegment(body),
        () => this.unSetInProcess(),
        'ดำเนินการต่อ',
        'ยกเลิก'
      )
    }
  }

  autoCompleteBySegment = async (body: { [key: string]: any }) => {
    const { autoCompleteBySegment } = this.props
    const { bankAccount } = this.state
    // console.log('callBackFromBaseBasicOrderFilter => ', callBackFromBaseBasicOrderFilter)
    if (_.isNil(bankAccount)) {
      p.op.showConfirmationOkOnly('ไม่พบบัญชีรับชำระ', 'กรุณาเลือกบัญชีรับชำระก่อน แล้วทำรายการใหม่อีกครั้ง')
      this.unSetInProcess()
      return
    }

    body.payment_account_id = bankAccount.get('id')

    const res: { bgjob_uuid: string } = await new Promise((submitted) => {
      autoCompleteBySegment({
        body,
        successCallback: submitted,
        failedCallback: submitted,
      })
    })

    // console.log('res autoCompleteBySegment => ', res)
    if (!_.isNil(res.bgjob_uuid)) {
      // this._goBack()
      // p.op.showConfirmationOkOnly('กำลังดำเนินการรับชำระอัตโนมัติ', 'สามารถตรวจสอบความคืบหน้าได้ที่ปุ่ม คิวคำสั่งงาน')
      await util.alertWithNavToBgJobListView({
        title: 'สร้าง "คิวคำสั่งงาน" สำเร็จ',
        message:
          'คิวคำสั่งงาน สำหรับการ "บันทึกจัดส่งอัตโนมัติ" ได้ถูกสร้างแล้ว ระบบกำลังดำเนินการอยู่เบื้องหลัง และอาจใช้เวลาซักครู่จนกว่าจะครบทุกออเดอร์',
        dispatch: this.props.navigation.dispatch,
        selectedStoreId: this.props.selectedStore.get('id'),
        onUserConfirm: () => this._goBack(),
        initBgJobUUID: res.bgjob_uuid,
      })
      return
    }
    this.unSetInProcess()
  }

  render() {
    return (
      <XContainer>
        <XCustomHeader
          title={'บันทึกรับชำระอัตโนมัติ\n(เลือกตามเงื่อนไข)'}
          headerLeftProps={{ backIcon: true, onPressItem: this._goBack }}
        />
        <ScrollView>
          <View style={{ flex: 1, backgroundColor: COLORS.GREEN_SOFT }}>
            <View style={{ backgroundColor: COLORS.WHITE, paddingBottom: 30 }}>{this._renderContent()}</View>
          </View>
        </ScrollView>
        {this.renderFooter()}
      </XContainer>
    )
  }
}
