import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
// import { NavigationActions } from 'react-navigation'
import * as ShipmentState from 'x/modules/shipment/ShipmentState'
import ShipView from './ShipView'

export default connect(
  (state: any) => ({
    stores: state.getIn(['shipment', 'stores']),
    selectedOrderShip: state.getIn(['shipment', 'selectedOrderShip']),
    editingOrderShip: state.getIn(['shipment', 'editingOrderShip']),
    curQty: state.getIn(['shipment', 'curQty']),
    maxQty: state.getIn(['shipment', 'maxQty']),

    // pagePicker Selector
    pp_shippingTypeIndex: state.getIn(['bp', 'pagePicker', 'shippingType', 'index']),
    navigatorStateIndex: state.getIn(['navigatorState', 'index']),
  }),
  (dispatch) => {
    return {
      // navigate: bindActionCreators(NavigationActions.navigate, dispatch),
      dispatch,
      // shipmentActions: bindActionCreators(ShipmentState, dispatch),
      shipmentActions: {
        fetchShipmentOrder: bindActionCreators(ShipmentState.fetchShipmentOrder, dispatch),
        reset: bindActionCreators(ShipmentState.reset, dispatch),
        operateShip: bindActionCreators(ShipmentState.operateShip, dispatch),
        shipmentChange: bindActionCreators(ShipmentState.shipmentChange, dispatch),
        decreaseQty: bindActionCreators(ShipmentState.decreaseQty, dispatch),
        increaseQty: bindActionCreators(ShipmentState.increaseQty, dispatch),
        clearQty: bindActionCreators(ShipmentState.clearQty, dispatch),
        maxQty: bindActionCreators(ShipmentState.maxQty, dispatch),
      },
    }
  }
)(ShipView)
