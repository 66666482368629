import { IApiOptions, CreateErpDocumentResponse } from 'x/types'
// import CONS from 'x/config/constants'

import api from './api'

type ICreateErpDocumentRequestBody = {
  store_id: number
  erp_ch_id: number
  order_ids: number[]
  erp_doc_type: number
  is_tax_invoice?: boolean
}

// https://dd.xselly.com/doc/create-document-api-JkqfVcekHX
export async function fetchCreateErpDocument(params: ICreateErpDocumentRequestBody): Promise<CreateErpDocumentResponse> {
  const { store_id, erp_ch_id, order_ids, erp_doc_type, is_tax_invoice } = params
  if (!store_id) {
    throw new Error('fetchCreateErpDocument:: Need store_id')
  }

  if (!erp_ch_id) {
    throw new Error('fetchCreateErpDocument:: Need erp_ch_id')
  }

  if (!order_ids) {
    throw new Error('fetchCreateErpDocument:: Need order_ids')
  }

  if (!erp_doc_type) {
    throw new Error('fetchCreateErpDocument:: Need erp_doc_type')
  }

  const apiOptions: IApiOptions = {
    showSpinner: true,
    messages: {
      successMsg: 'สร้างเอกสารสำเร็จ',
      errorMsg: 'สร้างเอกสารใช้ล้มเหลว กรุณาลองกดใหม่อีกครั้ง',
    },
    axiosOptions: {
      timeout: 60000,
    },
    isApiV2: true,
  }

  const reqBody: ICreateErpDocumentRequestBody = {
    store_id,
    erp_ch_id,
    order_ids,
    erp_doc_type,
  }

  if (is_tax_invoice) {
    reqBody.is_tax_invoice = is_tax_invoice
  }

  try {
    const res = await api.post<ICreateErpDocumentRequestBody, CreateErpDocumentResponse>('erp/document/create', reqBody, apiOptions)
    return res
  } catch (err) {
    console.log('err => ', err)
    throw err
  }
}
