import React from 'react'
import * as util from 'x/utils/util'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
// import SRowItem from '../../components/renderRowLeftAndRight'
import BaseStoreWebsiteCreateOptionsOrderView from 'x/modules/storeWebsite/BaseStoreWebsiteCreateOptionsOrderView'
import { COLORS, STYLES } from 'x/config/styles'
import VStack from 'xui/components/VStack'
import XButton from 'xui/components/XButton'
import XIcon from 'xui/components/XIcon'
import HStack from 'xui/components/HStack'
import XCard from 'xui/components/XCard'
import XText from 'xui/components/XText'
import XInput from 'xui/components/XInput'
import XSwitch from 'xui/components/XSwitch'
import XFlatPicker from 'xui/components/XFlatPicker'
import _ from 'lodash'
import p from 'x/config/platform-specific'
import Segment from 'xui/components/Segment'
import XFaqModal from 'xui/components/XFaqModal'
import FAQ_CONSTANTS from 'x/config/FAQ_CONSTANTS'
import HelpButton from 'xui/components/HelpButton'
// @ts-ignore
import { ISalePageOneOptionProduct, ISalePageOneOptionVariants } from 'x/index'
import Box from 'xui/components/Box'
import NB from 'x/config/nativebase'
import XImageIcon from 'xui/components/XImageIcon'
import FastImage from 'react-native-fast-image'
import XIconButton from 'xui/components/XIconButton'
import * as xFmt from 'x/utils/formatter'
import XNumericInput from 'xui/components/XNumericInput'
import { EditPriceButton } from 'xui/components/order/OrderProductItem'

const tb_my0 = require('../../img/tb/tb_my0.png')
const tb_seller0 = require('../../img/tb/tb_seller0.png')
const emptyImageSrc = require('../../images/empty_image.png')

const _renderAgreement = (onPressOpenOverlay: () => void) => <HelpButton onPress={() => onPressOpenOverlay()} />

export default abstract class BaseUIStoreWebsiteCreateOptionsOrderView extends BaseStoreWebsiteCreateOptionsOrderView {
  _renderHeader = () => {
    const title = 'สร้างคำสั่งซื้อ'
    const { isEdit } = this.state
    let leftProps = { backIcon: true, onPressItem: () => util.navGoBack(this.props) }
    let rightProps = null
    if (isEdit) {
      // @ts-ignore
      leftProps = { label: 'บันทึก', onPressItem: () => this._saveBtn() }
      rightProps = {
        label: 'ยกเลิก',
        onPressItem: () => {
          p.op.showConfirmation(
            '',
            'คุณต้องการกยเลิกการแก้ไขคำสั่งซื้อนี้ใช่หรือไม่',
            () => util.navGoBack(this.props),
            () => null,
            'ยกเลิกและออกจากหน้านี้',
            'แก้ไขต่อ'
          )
        },
      }
    }
    return (
      <XCustomHeader
        title={title}
        headerLeftProps={leftProps}
        headerRightProps={rightProps}
        // headerRightProps={{ label: 'ยกเลิก', onPressItem: () => util.navGoBack(this.props) }}
        // renderHeaderRight={this._renderHeaderRight}
      />
    )
  }

  _renderBtnAddOption = () => {
    const { optionPicker, mode } = this.state
    // console.log('optionPicker => ', optionPicker)
    const { products } = optionPicker
    if (mode === 'SINGLE_PRODUCT_MODE' && !_.isNil(products) && products.length > 0 && products[0].variants.length > 0) {
      return null
    }
    return (
      <HStack w='full' alignItems='center' justifyContent='center' mt='1'>
        <XButton
          w='48'
          onPress={() => {
            // const { optionPicker } = this.state
            const product = optionPicker.products
            const productIndex = _.isNil(product) ? 0 : product.length
            this._navToSalePageSelectProductView(productIndex)
          }}>
          <XIcon name='add' family='Ionicons' />
          เพิ่มสินค้า
        </XButton>
      </HStack>
    )
  }

  // _rendervariants = (variants: ISalePageOneOptionVariants[]) => (
  //   // console.log('variants => ', variants)
  //   <VStack>
  //     {variants.map((variant, index) => (
  //       <HStack key={index}>
  //         <XText>{variant.name}</XText>
  //         <HStack w='6' />
  //         <XText>{`x ${variant.qty}`}</XText>
  //       </HStack>
  //     ))}
  //   </VStack>
  // )

  _renderProductInfo = (variant: ISalePageOneOptionVariants, productIndex: number, indexVariants: number) => {
    // console.log('variant => ', variant)
    const { name, img, price, qty, variantName, discount, pp_id } = variant
    const shouldShowSalePrice = true

    const _onPriceChange = (newText: string) => {
      // console.log('_onPriceChange => ', newText)
    }

    const _onSellerUnitDiscountChange = (newText: string) => {
      // console.log('_onSellerUnitDiscountChange => ', newText)
    }

    const _onPriceAfterDiscountChange = (newText: string) => {
      // console.log('_onPriceAfterDiscountChange => ', newText)
    }

    const _onQtyChange = (newVal: string) => {
      const { optionPicker } = this.state
      // console.log('_onQtyChange optionPicker => ', optionPicker)
      const newOptionPicker = _.clone(optionPicker)
      newOptionPicker.products[productIndex].variants[indexVariants].qty = +newVal
      this.setState({ optionPicker: newOptionPicker })
    }

    const _onSubmitNewPriceAndDiscount = (newPrice: string, newDiscount: string) => {
      const { optionPicker } = this.state
      const newOptionPicker = _.clone(optionPicker)
      newOptionPicker.products[productIndex].variants[indexVariants].price = newPrice
      newOptionPicker.products[productIndex].variants[indexVariants].discount = newDiscount
      this.setState({ optionPicker: newOptionPicker })
    }

    const finalPrice = discount ? +price + -+discount : +price

    return (
      <VStack w='full' key={`${indexVariants}`}>
        <HStack w='full' space='2'>
          <VStack w='68px' space='1'>
            <HStack w='68px' minH='19'>
              <FastImage
                resizeMode={FastImage.resizeMode.cover}
                style={{
                  // minWidth: 86,
                  // maxWidth: 112,
                  // height: 80,
                  width: 64,
                  height: 64,
                  borderTopWidth: 1,
                  borderLeftWidth: 1,
                  borderBottomWidth: 1,
                  borderRightWidth: 1,
                  borderColor: '#f8f8f8',
                  borderRadius: 8,
                }}
                source={img ? { uri: img[0] } : emptyImageSrc}
              />
            </HStack>
            <HStack w='68px' minH='9'>
              <XIconButton
                size='sm'
                name='trash'
                variant='subtle'
                iconVariant='inactive'
                onPress={() => this._deleteVariant(productIndex)}
              />
            </HStack>
          </VStack>
          {/* // ฝั่งขวา */}
          <VStack minH='12' flex={1} space='1'>
            {/* Row 1: Product's name + Variant's name --- Delete Button */}
            <HStack w='full' space='1'>
              <VStack minH='9' flex={1} flexWrap='wrap' space='1'>
                {/* Row 1-1: Product's name --- Delete Button */}
                <XText minH='9' w='full'>
                  {name}
                </XText>
                {variantName ? (
                  <XText variant='inactive' minH='9' w='full'>
                    {variantName}
                  </XText>
                ) : null}
                <HStack minH='6px' w='full' space='1' alignItems='center'>
                  {discount ? (
                    <XText
                      minH='6px'
                      // bold
                      style={shouldShowSalePrice ? { textDecorationLine: 'line-through', textDecorationStyle: 'solid' } : null}>
                      {xFmt.formatCurrency(price || 0)}
                    </XText>
                  ) : null}
                  <XText minH='6px' bold>
                    {xFmt.formatCurrency(finalPrice || 0)}
                  </XText>
                  <EditPriceButton
                    productName={name}
                    variantName={variantName}
                    price={price}
                    seller_unit_discount={discount || '0'}
                    // price_after_discount={50}
                    onChangePrice={_onPriceChange}
                    onSellerUnitDiscountChange={_onSellerUnitDiscountChange}
                    onPriceAfterDiscountChange={_onPriceAfterDiscountChange}
                    onSubmitNewPriceAndDiscount={_onSubmitNewPriceAndDiscount}
                  />
                  {/* {shouldShowSalePrice ? (
                    <XText minH='6px' bold>
                      {xFmt.formatCurrency(parseFloat(price) - parseFloat(seller_unit_discount))}
                    </XText>
                  ) : null} */}
                </HStack>
                <HStack>
                  <XNumericInput
                    // isDisabled={!isQtyEditable}
                    w='32'
                    minH='9'
                    value={qty || 1}
                    minValue={1}
                    maxValue={99999}
                    maxLength={5}
                    onChangeText={_onQtyChange}
                  />
                </HStack>
              </VStack>
            </HStack>
          </VStack>
        </HStack>
      </VStack>
    )
  }

  _renderVariant = (variant: ISalePageOneOptionVariants, indexVariant: number, productIndex: number) => {
    const isMyStore = true
    const txtStoreName = this._getSellerStoreName(variant.seller_store_id)
    return (
      <XCard w='full' overflow='visible' mt={indexVariant > 0 ? '2' : '0'} key={`ProductCard_${indexVariant}`}>
        <XCard.Header w='full' {...NB.U.BG_L_LIGHT_GREY}>
          <HStack w='full' space='2'>
            <VStack flex={1} space='1'>
              <HStack w='full' space='2'>
                <Box w='6' h='6'>
                  <Box
                    // top={0} left={0}
                    // bg='amber.100'
                    w='8'
                    h='8'
                    top={-6}
                    left={-6}
                    position='absolute'>
                    <XImageIcon size='md' variant='inactive' source={isMyStore ? tb_my0 : tb_seller0} />
                  </Box>
                </Box>
                <XText pt={1} fontSize='xs'>{`สินค้าจากร้าน ${txtStoreName}`}</XText>
              </HStack>
            </VStack>
          </HStack>
        </XCard.Header>
        <XCard.Body w='full'>
          <VStack w='full' space='2'>
            {this._renderProductInfo(variant, productIndex, indexVariant)}
          </VStack>
        </XCard.Body>
      </XCard>
    )
  }

  _renderProductCard = (product: ISalePageOneOptionProduct, idx: number) => {
    // console.log('_renderProductCard product => ', product)
    const { mode } = this.state
    const isMyStore = true
    const txtStoreName = this._getSellerStoreName(product.variants[0].seller_store_id)
    const { variants } = product
    // console.log('variants => ', variants)
    return (
      <VStack key={`renderProductCard_${idx}`} space='2'>
        {variants.map((variant, index) => this._renderVariant(variant, index, idx))}
      </VStack>
    )
    // return (
    //   <XCard w='full' overflow='visible' mt={idx > 0 ? '2' : '0'} key={`ProductCard_${idx}`}>
    //     <XCard.Header w='full' {...NB.U.BG_L_LIGHT_GREY}>
    //       <HStack w='full' space='2'>
    //         <VStack flex={1} space='1'>
    //           <HStack w='full' space='2'>
    //             <Box w='6' h='6'>
    //               <Box
    //                 // top={0} left={0}
    //                 // bg='amber.100'
    //                 w='8'
    //                 h='8'
    //                 top={-6}
    //                 left={-6}
    //                 position='absolute'>
    //                 <XImageIcon size='md' variant='inactive' source={isMyStore ? tb_my0 : tb_seller0} />
    //               </Box>
    //             </Box>
    //             <XText pt={1} fontSize='xs'>{`สินค้าจากร้าน ${txtStoreName}`}</XText>
    //           </HStack>
    //         </VStack>
    //       </HStack>
    //     </XCard.Header>
    //     <XCard.Body w='full'>
    //       <VStack w='full' space='2'>
    //         {variants.map((variant, indexVariants) => this._renderProductInfo(variant, idx, indexVariants))}
    //       </VStack>
    //     </XCard.Body>
    //   </XCard>
    // )
  }

  _renderSingleProductMode = () => {
    const { optionPicker } = this.state
    // console.log('_renderSingleProductMode => ', optionPicker)
    if (_.isNil(optionPicker)) {
      return null
    }
    const { products } = optionPicker
    if (_.isNil(products)) {
      return null
    }
    return (
      <VStack>
        {products.map((product, idx) =>
          // console.log('product => ', product)
          // const { variants } = product
          this._renderProductCard(product, idx)
        )}
      </VStack>
    )
  }

  _renderProductSetMode = () => {
    const { optionPicker } = this.state
    // console.log('_renderProductSetMode RD => ', optionPicker)
    if (_.isNil(optionPicker)) {
      return null
    }
    const { products } = optionPicker
    if (_.isNil(products)) {
      return null
    }
    // console.log('products RD => ', products)
    return (
      <VStack>
        {products.map((product, idx) =>
          // console.log('product => ', product)
          // const { variants } = product
          this._renderProductCard(product, idx)
        )}
      </VStack>
    )
  }

  _renderProduct = () => {
    const { optionPicker, mode } = this.state
    // console.log('_renderProduct => ', optionPicker)
    if (_.isNil(optionPicker)) {
      return null
    }
    const { products } = optionPicker
    if (_.isNil(products) || products.length === 0) {
      return null
    }

    if (mode === 'SINGLE_PRODUCT_MODE') {
      return this._renderSingleProductMode()
    }
    if (mode === 'PRODUCT_MULTI_OPTIONS_MODE') {
      return this._renderSingleProductMode()
    }
    if (mode === 'PRODUCT_SET_MODE') {
      return this._renderProductSetMode()
    }
    return null
  }

  _renderOtherValueView = () => {
    const { otherPrice } = this.state
    return (
      <VStack w='full' mt='2'>
        <HStack alignItems='center' justifyContent='center' w='full'>
          <HStack flex={7}>
            <XText>ค่าอื่นๆ</XText>
          </HStack>
          <HStack>
            <XInput
              // w='full'
              w='24'
              h='10'
              // key={inputKey}
              // key={orderIndex}
              // isDisabled={!isEditable}
              textAlign='right'
              selectTextOnFocus
              value={otherPrice}
              isMoney
              maxLength={11}
              onChangeText={(newValue) => this.setState({ otherPrice: newValue })}
            />
          </HStack>
        </HStack>
      </VStack>
    )
  }

  _renderdiscountValueView = () => {
    const { discountPrice } = this.state
    return (
      <VStack w='full' mt='2'>
        <HStack alignItems='center' justifyContent='center' w='full'>
          <HStack flex={7}>
            <XText>ส่วนลดให้ลูกค้า</XText>
          </HStack>
          <HStack>
            <XInput
              // w='full'
              w='24'
              h='10'
              // key={inputKey}
              // key={orderIndex}
              // isDisabled={!isEditable}
              textAlign='right'
              selectTextOnFocus
              value={discountPrice}
              isMoney
              maxLength={11}
              onChangeText={(newValue) => this.setState({ discountPrice: newValue })}
            />
          </HStack>
        </HStack>
      </VStack>
    )
  }

  _renderSumTotalView = () => {
    // const { discountPrice, otherPrice } = this.state
    const sumTotal = this._getSumTotalProductPrice()
    console.log('sumTotal => ', sumTotal)
    return (
      <VStack w='full' mt='2'>
        <HStack alignItems='center' justifyContent='center' w='full'>
          <VStack flex={7}>
            <XText bold>ยอดรวม</XText>
            <XText variant='inactive' fontSize='sm'>
              (ไม่รวมค่าจัดส่ง)
            </XText>
          </VStack>
          <HStack>
            <XText bold>{sumTotal}</XText>
          </HStack>
        </HStack>
      </VStack>
    )
  }

  _renderProductPrice = () => {
    // const {} = this.state
    const price = this._getProductPrice()
    return (
      <HStack alignItems='center' w='full' mt='1'>
        <HStack flex={1}>
          <XText bold>ราคา</XText>
        </HStack>
        <HStack flex={1} justifyContent='flex-end'>
          <XText bold>{price}</XText>
        </HStack>
      </HStack>
    )
  }

  _renderProductSetModeView = (product: ISalePageOneOptionProduct, index: number) => {
    // console.log('_renderProductSetModeView => product ', product)
    const { mode } = this.state
    let title = 'สินค้า'
    const optionName = product.name ? product.name : ''

    let BD_COLOR = COLORS.TEXT_INACTIVE
    if (mode === 'PRODUCT_MULTI_OPTIONS_MODE') {
      BD_COLOR = COLORS.WEBSITE_PRODUCT_MULTI_OPTIONS_MODE
      title = `รายการตัวเลือก`
    } else if (mode === 'PRODUCT_SET_MODE') {
      BD_COLOR = COLORS.WEBSITE_PRODUCT_SET_MODE
      title = `รายการสินค้า#${index + 1}`
    }
    // console.log('index => ', index)
    return (
      <XCard
        key={`renderProductSetModeView_${index}`}
        p='2'
        mt={index > 0 ? '2' : '0'}
        w='full'
        borderWidth='1'
        borderBottomColor={BD_COLOR}
        borderTopColor={BD_COLOR}
        borderRightColor={BD_COLOR}
        borderLeftColor={BD_COLOR}>
        <HStack mx={-8} alignItems='center' justifyContent='center' borderBottomColor={BD_COLOR} borderBottomWidth='1' pb='1'>
          <XText variant='active' fontSize='md'>
            {title}
          </XText>
        </HStack>
        <HStack mt='2' />

        {mode === 'PRODUCT_SET_MODE' ? (
          <VStack>
            <XText variant='active'>ชื่อตัวเลือก</XText>
            <HStack w='full'>
              <XInput
                multiline
                maxFontSizeMultiplier={2}
                style={{ marginRight: 4, flex: 1 }}
                // ml='2'
                // h='16'
                placeholder='ชื่อตัวเลือก'
                value={optionName}
                onChangeText={(newText: string) => this._onChangeOptionName(newText, index)}
              />
            </HStack>
          </VStack>
        ) : null}

        <HStack h='2' />
        {/* {this._renderProduct()} */}
        {this._renderProductCard(product, index)}
        {/* {this._renderBtnAddOption()} */}

        <HStack flex={1} mt='2'>
          <HStack flex={3} alignItems='center'>
            <XText>จำนวนตัวเลือกในรายการสินค้า</XText>
            <XFaqModal
              key='จำนวนตัวเลือกในรายการสินค้า'
              headerTitle='TEST'
              // @ts-ignore
              FAQ={FAQ_CONSTANTS.FAQ_CONNECT_MKP_CHANNELS}
              initiateOpenIndex={[0]}
              renderButton={(onPressOpenOverlay: () => void) => _renderAgreement(onPressOpenOverlay)}
            />
          </HStack>
          <HStack flex={1} justifyContent='flex-end' alignItems='center'>
            <XText>{product.variants.length}</XText>
          </HStack>
        </HStack>

        {/* // ADD BTN */}
        {mode === 'SINGLE_PRODUCT_MODE' && product.variants.length > 0 ? null : (
          <XButton
            onPress={() => this._navToSalePageSelectProductView(index)}
            variant='outline'
            w='40'
            mt='2'
            alignItems='center'
            justifyContent='center'
            alignSelf='center'
            borderRadius={90}>
            <HStack>
              <XIcon name='add-outline' family='Ionicons' />
              <XText color={COLORS.APP_MAIN}>{`เพิ่มตัวเลือก#${product.variants.length + 1}`}</XText>
            </HStack>
          </XButton>
        )}
      </XCard>
    )
  }

  _renderProductListForProductSetMode = () => {
    const { optionPicker } = this.state
    if (_.isNil(optionPicker)) {
      return null
    }
    const { products } = optionPicker
    if (_.isNil(products)) {
      return null
    }
    // console.log('products 404 => ', products)
    return <VStack>{products.map((product, idx) => this._renderProductSetModeView(product, idx))}</VStack>
  }

  _renderProductList = () => {
    const { optionPicker, mode } = this.state
    return this._renderProductListForProductSetMode()

    // if (mode === 'PRODUCT_SET_MODE') {
    //   return this._renderProductListForProductSetMode()
    // }
    // if (mode === 'PRODUCT_MULTI_OPTIONS_MODE') {
    //   return this._renderProductListForProductSetMode()
    // }
    // if (mode === 'SINGLE_PRODUCT_MODE') {
    //   return this._renderProductListForProductSetMode()
    // }
    // return null
  }

  _renderInputName = () => {
    const { optionName, optionIndex, mode } = this.state
    // console.log('mode => ', mode)
    if (_.isNil(mode)) {
      return null
    }
    let BD_COLOR = COLORS.TEXT_INACTIVE
    let TITLE_TEXT = 'สินค้าชิ้นเดียว'
    let placeholder = 'เช่น ครีมหน้าเด้ง 2 กระปุก'
    let icon = 'box'
    if (mode === 'PRODUCT_MULTI_OPTIONS_MODE') {
      placeholder = 'เช่น กางเกงเอว 32 34 36 และ 40'
      BD_COLOR = COLORS.WEBSITE_PRODUCT_MULTI_OPTIONS_MODE
      icon = 'box-open'
      TITLE_TEXT = 'สินค้าจัดเซ็ท'
    } else if (mode === 'PRODUCT_SET_MODE') {
      placeholder =
        'เช่น ชุดเซ็ตต้อนรับเปิดเทอม ประกอบด้วย เสื้อ 2 ตัว + กางเกง 2 ตัว + รองเท้า 1 คู โดยลูกค้าสามารถเลือก ขนาดของ เสื้อ กางเกง และรองเท้าเองได้'
      BD_COLOR = COLORS.WEBSITE_PRODUCT_SET_MODE
      icon = 'boxes'
      TITLE_TEXT = 'สินค้าจัดเซ็ท'
    }

    return (
      <XCard
        w='full'
        p='2'
        borderWidth='1'
        borderBottomColor={BD_COLOR}
        borderTopColor={BD_COLOR}
        borderRightColor={BD_COLOR}
        borderLeftColor={BD_COLOR}>
        <HStack mx={-8} pb='1' alignItems='center' justifyContent='flex-start' borderBottomColor={BD_COLOR} borderBottomWidth='1'>
          <HStack ml='8'>
            <XIcon name={icon} family='FontAwesome5' color={BD_COLOR} />
            <XText variant='active' fontSize='md' pl='2' color={BD_COLOR}>
              {TITLE_TEXT}
            </XText>
          </HStack>
        </HStack>
        <HStack alignItems='center' justifyContent='flex-end' mt='1'>
          <XText variant='inactive'>{`คำสั่งซื้อ #${optionIndex + 1}`}</XText>
        </HStack>
        <XText variant='active'>ชื่อคำสั่งซื้อ</XText>
        <HStack w='full'>
          <XInput
            multiline
            maxFontSizeMultiplier={2}
            style={{ marginRight: 4, flex: 1 }}
            // ml='2'
            h='16'
            placeholder={placeholder}
            value={optionName}
            onChangeText={(newText: string) => this._onChangeSalePageName(newText)}
          />
        </HStack>
      </XCard>
    )
  }

  _renderTitle = () => (
    <HStack alignItems='center' justifyContent='center'>
      <XText variant='active' fontSize='md'>
        การจัดส่ง
      </XText>
    </HStack>
  )

  _renderCommonFlatPickerItemForShipping = (data) => {
    const { item, index, disabled } = data
    // console.log('DATA => ', data)
    const shippingState = util.getShippingHasXShippingLabel(item.value)
    // console.log('shippingState => ', shippingState)
    // const UNDER_LINE = p.op.isWeb() ? {} : { borderBottomColor: COLORS.TEXT_INACTIVE, borderBottomWidth: 1 }

    return (
      <XCard w='full'>
        <XCard.Body>
          <HStack w='full' minH='10' alignItems='center' space='1'>
            <VStack flex={1}>
              <XText style={{ fontSize: STYLES.FONT_SIZE_LARGER, color: COLORS.TEXT_ACTIVE }}>{`${item.label}`}</XText>
              {_.isNil(shippingState) ? null : (
                <XText style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: shippingState.color }}>{`(${shippingState.label})`}</XText>
              )}
            </VStack>
            <XIcon name='arrowright' family='AntDesign' />
          </HStack>
        </XCard.Body>
      </XCard>
    )
  }

  _renderShippingPayment = (key: string, shippingCost: string) => (
    // const {} = this.state
    <VStack w='full' mt='2' key={key}>
      <HStack alignItems='center' justifyContent='center' w='full'>
        <HStack flex={7}>
          <XText>ค่าส่ง</XText>
        </HStack>
        <HStack>
          <XInput
            // w='full'
            w='24'
            h='10'
            // key={inputKey}
            // key={orderIndex}
            // isDisabled={!isEditable}
            textAlign='right'
            selectTextOnFocus
            value={shippingCost}
            isMoney
            maxLength={11}
            // @ts-ignore
            onChangeText={(newValue) => this.setState({ [key]: newValue, isEdit: true })}
          />
        </HStack>
      </HStack>
    </VStack>
  )

  _renderShippingNonCod = () => {
    const { isShipping, selectedShippingTypeIndex, shippingCost, shippingSettingIndex } = this.state
    if (shippingSettingIndex === 0) {
      return null
    }
    const isValueChange = (newValue: boolean) => {
      this.setState({ isShipping: newValue })
    }

    const handleChangeShippingNotCodType = (itemInfo) => {
      const { item, index } = itemInfo
      // console.log('item => ', item)
      if (!_.isNil(item)) {
        this.setState({
          selectedShippingTypeIndex: index,
        })
      }
    }

    const _renderDetailShippingNotCodTypeCardButton = (data: any) => {
      const { item, index, disabled } = data
      // console.log('index // => ', index)
      let txtShippingType = null
      if (index > -1) {
        txtShippingType = this.state.shippingTypeNotCodItems[index].label
        // txtShippingType = 'PASS'
      } else if (index === -1 && item && item.value > 0) {
        txtShippingType = 'กรุณาดาวน์โหลดแอปเวอร์ชั่นใหม่ เพื่อแสดงผลข้อมูลนี้'
      } else {
        txtShippingType = 'ไม่ระบุ'
      }
      return (
        <XCard w='full'>
          <XCard.Body>
            <HStack w='full' alignItems='center' space='1'>
              <HStack alignItems='center' space='1'>
                <XIcon family='MaterialIcons' name='local-shipping' variant='inactive' />
                <XText variant='inactive'>รูปแบบจัดส่ง</XText>
              </HStack>

              <HStack flex={1} alignItems='center' justifyContent='flex-end' space='1'>
                <XText flex={1} textAlign='right'>
                  {txtShippingType}
                </XText>
                {disabled ? null : <XIcon variant={disabled ? 'inactive' : 'primary'} name='arrow-forward' />}
              </HStack>
            </HStack>
          </XCard.Body>
        </XCard>
      )
    }

    return (
      <XCard w='full' p='2' mt='2'>
        <HStack flex={1}>
          <HStack flex={1}>
            <XText>แบบธรรมดา (ไม่เก็บเงินปลายทาง)</XText>
          </HStack>
          <XSwitch value={isShipping} onValueChange={(newValue: boolean) => isValueChange(newValue)} />
        </HStack>
        {isShipping ? (
          <VStack mt='2'>
            <XFlatPicker
              key='ShippingTypePicker_not_cod'
              title='เลือกรูปแบบจัดส่ง'
              placeholder='กรุณาเลือกรูปแบบจัดส่ง'
              placeholderStyle={{ marginLeft: 6 }}
              // ref={this._shippingTypeCreateModePickerRef}
              selectedIndex={selectedShippingTypeIndex}
              options={this.state.shippingTypeNotCodItems}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onPick={handleChangeShippingNotCodType}
              renderSelectedValue={_renderDetailShippingNotCodTypeCardButton}
              renderItem={this._renderCommonFlatPickerItemForShipping}
              // renderTitleBar={() => this._renderTotleBarForShipping()}
            />
            {this._renderShippingPayment('shippingCost', shippingCost)}
          </VStack>
        ) : null}
      </XCard>
    )
  }

  _renderShippingCod = () => {
    const { isShippingCod, selectedShippingTypeCodIndex, shippingCodCost, shippingSettingIndex } = this.state
    if (shippingSettingIndex === 0) {
      return null
    }
    const isValueChange = (newValue: boolean) => {
      // console.log('newValue => ', newValue)
      this.setState({ isShippingCod: newValue })
    }

    const handleChangeShippingNotCodType = (itemInfo) => {
      const { item, index } = itemInfo
      // console.log('item => ', item)
      if (!_.isNil(item)) {
        this.setState({
          selectedShippingTypeCodIndex: index,
        })
      }
    }

    const _renderDetailShippingNotCodTypeCardButton = (data: any) => {
      const { item, index, disabled } = data
      let txtShippingType = null
      if (index > -1) {
        txtShippingType = this.state.shippingTypIsCodItems[index].label
        // txtShippingType = 'PASS'
      } else if (index === -1 && item && item.value > 0) {
        txtShippingType = 'กรุณาดาวน์โหลดแอปเวอร์ชั่นใหม่ เพื่อแสดงผลข้อมูลนี้'
      } else {
        txtShippingType = 'ไม่ระบุ'
      }
      return (
        <XCard w='full'>
          <XCard.Body>
            <HStack w='full' alignItems='center' space='1'>
              <HStack alignItems='center' space='1'>
                <XIcon family='MaterialIcons' name='local-shipping' variant='inactive' />
                <XText variant='inactive'>รูปแบบจัดส่ง</XText>
              </HStack>
              <HStack flex={1} alignItems='center' justifyContent='flex-end' space='1'>
                <XText flex={1} textAlign='right'>
                  {txtShippingType}
                </XText>
                {disabled ? null : <XIcon variant={disabled ? 'inactive' : 'primary'} name='arrow-forward' />}
              </HStack>
            </HStack>
          </XCard.Body>
        </XCard>
      )
    }
    // console.log('selectedPaymentAccountId => ', selectedPaymentAccountId)
    return (
      <XCard w='full' p='2' mt='2'>
        <HStack flex={1}>
          <HStack flex={1}>
            <XText>แบบ COD (เก็บเงินปลายทาง)</XText>
          </HStack>
          <XSwitch value={isShippingCod} onValueChange={(newValue: boolean) => isValueChange(newValue)} />
        </HStack>
        {isShippingCod ? (
          <VStack mt='2'>
            <XFlatPicker
              key='ShippingTypePicker_is_cod'
              title='เลือกรูปแบบจัดส่ง'
              placeholder='กรุณาเลือกรูปแบบจัดส่ง'
              placeholderStyle={{ marginLeft: 6 }}
              // ref={this._shippingTypeCreateModePickerRef}
              selectedIndex={selectedShippingTypeCodIndex}
              options={this.state.shippingTypIsCodItems}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onPick={handleChangeShippingNotCodType}
              renderSelectedValue={_renderDetailShippingNotCodTypeCardButton}
              renderItem={this._renderCommonFlatPickerItemForShipping}
              // renderTitleBar={() => this._renderTotleBarForShipping()}
            />
            {this._renderShippingPayment('shippingCodCost', shippingCodCost)}
            {/* <VStack mt='2'>
              <PaymentAccountPicker
                targetStoreId={this.props.selectedStore.get('id')}
                selectedPaymentAccountId={selectedPaymentAccountId}
                // @ts-ignore
                onChangePaymentAccount={this._onChangePaymentAccount}
                // onPaymentAccountLoaded={this._onPaymentAccountsLoaded}
                placeholderText='กรุณาเลือกบัญชีชำระ'
              />
            </VStack> */}
          </VStack>
        ) : null}
      </XCard>
    )
  }

  _renderShippingSettingOption = () => {
    const { shippingSettingIndex } = this.state
    return (
      <VStack w='full'>
        <HStack alignItems='center' justifyContent='flex-start'>
          <XText>ค่าส่งรูปแบบจัดส่งและค่าส่งาำหรับคำสั่งซื้อนี้</XText>
          <XFaqModal
            key='วิธีเชื่อมช่องทางขาย'
            headerTitle='TEST'
            // @ts-ignore
            FAQ={FAQ_CONSTANTS.FAQ_CONNECT_MKP_CHANNELS}
            initiateOpenIndex={[0, 1]}
            renderButton={(onPressOpenOverlay: () => void) => _renderAgreement(onPressOpenOverlay)}
          />
        </HStack>
        <HStack>
          <Segment
            options={['ใช้ตามเซลล์เพจ', 'ระบุเฉพาะคำสั่งซื้อนี้']}
            selectedIndex={shippingSettingIndex}
            onSegmentChange={(newIndex: number) => this._onChangeSettingShippingIndex(newIndex)}
          />
        </HStack>
      </VStack>
    )
  }

  _renderShippingView = () => {
    const {} = this.state
    return (
      <XCard p='2'>
        {this._renderTitle()}
        {this._renderShippingSettingOption()}
        {this._renderShippingNonCod()}
        {this._renderShippingCod()}
      </XCard>
    )
  }

  _renderPrices = () => {
    const { optionPicker, mode } = this.state
    if (_.isNil(optionPicker)) {
      return null
    }
    const { products } = optionPicker
    return (
      <XCard p='2'>
        {_.isNil(products) ? null : this._renderProductPrice()}
        {this._renderOtherValueView()}
        {this._renderdiscountValueView()}
        {this._renderSumTotalView()}
      </XCard>
    )
  }

  _renderAddBtn = () => {
    const { mode, optionPicker } = this.state
    if (mode === 'PRODUCT_SET_MODE') {
      // const numberOfProduct = _.isNil(optionPicker) ? 1 : optionPicker.products ? optionPicker.products.length + 1 : 1
      const numberOfProduct = _.isNil(optionPicker) ? 1 : _.get(optionPicker, 'products.length', 0) + 1
      return (
        <VStack>
          <HStack w='full' alignItems='center' justifyContent='center' mt='1'>
            <XButton
              w='48'
              onPress={() => {
                const product = _.isNil(optionPicker) ? null : optionPicker.products
                const setIndex = _.isNil(product) ? 0 : product.length
                this._navToSalePageSelectProductView(setIndex)
              }}>
              <XIcon name='add' family='Ionicons' />
              {`เพิ่มรายการสินค้า#${numberOfProduct}`}
            </XButton>
          </HStack>
        </VStack>
      )
    }
    if (mode === 'PRODUCT_MULTI_OPTIONS_MODE') {
      const numberOfProduct = _.isNil(optionPicker) ? 1 : _.get(optionPicker, 'products.length', 0) + 1
      // const hasItem = optionPicker && optionPicker.products
      if (numberOfProduct > 1) {
        return null
      }
      return (
        <VStack>
          <HStack w='full' alignItems='center' justifyContent='center' mt='1'>
            <XButton
              w='48'
              onPress={() => {
                const product = _.isNil(optionPicker) ? null : optionPicker.products
                const setIndex = _.isNil(product) ? 0 : product.length
                this._navToSalePageSelectProductView(setIndex)
              }}>
              <XIcon name='add' family='Ionicons' />
              {`เพิ่มตัวเลือก#${numberOfProduct}`}
            </XButton>
          </HStack>
        </VStack>
      )
    }
    if (mode === 'SINGLE_PRODUCT_MODE') {
      const numberOfProduct = _.isNil(optionPicker) ? 1 : _.get(optionPicker, 'products.length', 0) + 1
      if (numberOfProduct > 1) {
        return null
      }
      return (
        <VStack>
          <HStack w='full' alignItems='center' justifyContent='center' mt='1'>
            <XButton
              w='48'
              onPress={() => {
                const product = _.isNil(optionPicker) ? null : optionPicker.products
                const setIndex = _.isNil(product) ? 0 : product.length
                this._navToSalePageSelectProductView(setIndex)
              }}>
              <XIcon name='add' family='Ionicons' />
              เพิ่มสินค้า
            </XButton>
          </HStack>
        </VStack>
      )
    }

    return null
  }

  _renderMain = () => (
    <VStack w='full' space='2'>
      {this._renderInputName()}
      {this._renderProductList()}
      {this._renderAddBtn()}
      {this._renderPrices()}
      {this._renderShippingView()}
    </VStack>
  )

  render() {
    return (
      <XContainer>
        {this._renderHeader()}
        <XContent p='2' style={{ backgroundColor: COLORS.BG_LIGHT_GREY }}>
          {this._renderMain()}
        </XContent>
      </XContainer>
    )
  }
}
