// import { bindActionCreators } from 'redux'
// import * as PaymentActions from 'x/modules/payment/PaymentState'
// import { getOrdersToBePaid } from '../../redux/selectors'
import { connect } from 'react-redux'
import { getState, getDispatch } from 'x/modules/payment/PaymentViewConnect'
import PaymentView from './PaymentView'

export default connect(
  (state) => ({
    ...getState(state),
  }),
  (dispatch) => {
    return {
      ...getDispatch(dispatch),
    }
  }
)(PaymentView)
