import React from 'react'
import { Keyboard, Linking, TouchableOpacity, StyleSheet } from 'react-native'
import { FormikProps, FormikHelpers as FormikActions } from 'formik'
// import { delay } from 'x/utils/util'
import api from 'x/utils/api'
import * as util from 'x/utils/util'
import * as auth from 'x/utils/authentication'

import { S } from 'x/config/styles'
import Yup, { EMAIL, isRequired } from 'x/utils/validator'
import _ from 'lodash'
import XIcon from 'xui/components/XIcon'
import * as NavActions from 'x/utils/navigation'
import XButton from 'xui/components/XButton'
import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import XText from 'xui/components/XText'
import XFormikInput from 'xui/components/XFormikInput'
import BaseOnboardingView from './BaseOnboardingView'
// import { log } from 'x/utils/util'

export default class OnboardingEmailView extends BaseOnboardingView {
  static displayName = 'OnboardingEmailView'

  constructor(props) {
    super(props)
    this.initialValues = {
      email: util.getNavParam(props, 'email', ''),
    }

    this.state = {}

    // const defaultEmail = this.props.navigation.getParam('email', '')
    // if (defaultEmail !== '') {
    //   this.initialValues.em
    // }

    this.validationSchema = Yup.object().shape({
      email: isRequired(EMAIL),
    })

    this.txtHeaderTitle = 'สร้างบัญชีผู้ใช้งานใหม่'
    this.txtBtnFooter = 'ต่อไป'
  }

  componentDidMount() {
    setTimeout(this._autoFocusFirstInput, 1500)
  }

  renderContent(fProps: FormikProps<any>): JSX.Element {
    return (
      <VStack w='full' space='1'>
        <HStack w='full' p='2' justifyContent='center' alignItems='center'>
          {this._renderHeaderTitle()}
        </HStack>

        <XText variant='inactive'>อีเมล</XText>

        <XFormikInput
          flex={1}
          autoCapitalize='none'
          keyboardType='email-address'
          style={this.styles.input}
          name='email'
          placeholder='อีเมลใดก็ได้ที่คุณใช้ประจำ เปลี่ยนภายหลังได้'
          order={3}
          handleRegisterRef={this._doRegisterRef}
          handleOnSubmitEditing={fProps.handleSubmit}
          returnKeyType='done'
          // getReturnKeyType={this._getReturnKeyType}
        />

        <HStack w='full' space='2'>
          {this.renderConsentCheckbox()}
          <XText>
            {'ข้าพเจ้าได้อ่าน เข้าใจ และตกลงตามข้อกำหนดในนโยบายคุ้มครองข้อมูลส่วนบุคคลของ XSelly ซึ่งสามารถ '}
            <XText color='blue.500' onPress={() => Linking.openURL('https://xselly.com/pdpa')}>
              กดดูได้ที่นี่
            </XText>
          </XText>
        </HStack>
      </VStack>
    )
  }

  _toggleConsentCheckbox = () => {
    // @ts-ignore
    const { isConsentChecked = false } = this.state
    this.setState({ isConsentChecked: !isConsentChecked })
  }

  renderConsentCheckbox = () => {
    // @ts-ignore
    const { isConsentChecked = false } = this.state
    return (
      <TouchableOpacity style={[S.HEIGHT_32, S.HEIGHT_32]} onPress={this._toggleConsentCheckbox}>
        <XIcon
          // family='FontAwesome'
          // name={checked ? 'check-circle' : 'circle-o'}
          family={isConsentChecked ? 'Ionicons' : 'FontAwesome'}
          name={isConsentChecked ? 'checkmark-circle' : 'circle-o'}
          style={StyleSheet.flatten([S.MIN_WIDTH_24, isConsentChecked ? S.TEXT_ICON_SUCCESS : S.TEXT_ICON_PRIMARY, S.TEXT_ICON_LARGER])}
          // variant={checked ? 'success' : 'inactive'}
        />
      </TouchableOpacity>
    )
  }

  renderFooter = (fProps: FormikProps<any>) => {
    // @ts-ignore
    const { isConsentChecked = false } = this.state

    // const { isSubmitting, isValid, handleSubmit } = fProps
    const { handleSubmit, values } = fProps
    const isDisabled = !isConsentChecked || (values && (!values.email || values.email === ''))
    // const isDisabled = isSubmitting || !isValid
    // const btnStyle = isDisabled ? this.styles.btnFooterDisabled : this.styles.btnFooter
    // const txtBtnStyle = isDisabled ? this.styles.txtBtnFooterDisabled : this.styles.txtBtnFooter

    return (
      <HStack w='full' p='1'>
        <XButton
          flex={1}
          // @ts-ignore
          onPress={handleSubmit}
          isDisabled={isDisabled}
          disabled={isDisabled}>
          {this.txtBtnFooter}
        </XButton>
      </HStack>
    )

    // return (
    //   <Footer style={this.styles.footer}>
    //     <FooterTab style={this.styles.footerTab}>
    //       <XButton onPress={handleSubmit} disabled={isDisabled} style={btnStyle} primary full>
    //         <Text style={txtBtnStyle}>{this.txtBtnFooter}</Text>
    //       </XButton>
    //     </FooterTab>
    //   </Footer>
    // )
  }

  async handleSubmitForm(values: any, formActions: FormikActions<any>): Promise<void> {
    Keyboard.dismiss()

    const { navigation } = this.props
    let body
    body = _.cloneDeep(values)
    body.accessToken = await auth.getFBAccessToken()

    // ถ้า submit ได้ควรจะยินยอม PDPA แล้ว
    body.is_consented = true

    const apiOptions = {
      messages: {
        // successMsg: 'ดึงสินค้าสำเร็จแล้ว',
        errorMsg: 'เกิดข้อผิดพลาดในการลงทะเบียนอีเมล',
      },
      showSpinner: true,
      noToken: true,
    }
    try {
      // log('handleSubmitForm body => ', body)
      const res = await api.post(api.POST_FB_SIGNUP, body, apiOptions)
      if (!res) {
        throw new Error('Empty response')
      }
      if (res.token) {
        await auth.setAuthenticationToken(res.token)
      }
      // if (res.missing !== 'username') {
      //   navigation.dispatch(NavActions.switchToAppLauncher)
      //   // log('No missing store anymore')
      //   return
      // }
      // navigation.navigate('OnboardingUsernameView')
      if (_.has(res, 'missing') && res.missing === 'username') {
        // navigation.navigate('OnboardingUsernameView')
        navigation.dispatch(NavActions.switchToOnboardingUsername)
      } else if (_.has(res, 'missing') && res.missing === 'store') {
        // navigation.navigate('OnboardingFirstStoreNameView')
        navigation.dispatch(NavActions.switchToOnboardingFirstStore)
      } else {
        // navigation.dispatch(NavActions.switchToAuthStackNavigator)
        navigation.dispatch(NavActions.resetToAuthStackNavigator())
      }
    } catch (err) {
      // log('handleSubmitForm err => ', err)
      // navigation.dispatch(NavActions.switchToAppLauncher)
      // navigation.dispatch(NavActions.switchToAppLauncher)
      formActions.resetForm()
    }
    // log(JSON.stringify(values, null, 2))
    // log('formActions => ', formActions)
    // try {
    //   const response = await api.post(api.POST_SIGNUP, values, { showSpinner: true, noToken: true })
    //
    //   log('handleSubmitForm::response => ', response)
    //   log(JSON.stringify(values, null, 2))
    //   if (_.has(response, 'status') && response.status === 'ok') {
    //     if (_.has(response, 'token') && _.isString(response.token)) {
    //       let receivedToken = response.token ? response.token : ''
    //       receivedToken = receivedToken.replace(/Bearer/g, '').trim()
    //       await auth.setAuthenticationToken(receivedToken)
    //       Keyboard.dismiss()
    //       formActions.resetForm()
    //       this.props.navigation.navigate('screen2')
    //     } else {
    //       await delay(1500)
    //       formActions.setSubmitting(false)
    //     }
    //   } else {
    //     throw new Error('Its no have the expecting values.')
    //   }
    // } catch (err) {
    //   log('handleSubmitForm::err')
    //   log(err)
    //   await delay(1500)
    //   formActions.setSubmitting(false)
    // }
  }
}
