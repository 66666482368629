import { take, put, call, fork, all } from 'redux-saga/effects'
import _ from 'lodash'
import * as util from '../../utils/util'
import api from '../../utils/api'
import * as bankActions from '../../modules/bank/BankState'

export default function* watchAllBanks() {
  yield all([
    fork(watchFetchStorePaymentAccounts),
    // fork(watchFetchMyBankAccounts),
    fork(watchAddBankAccount),
    fork(watchEditBankAccount),
    fork(watchDeleteBankAccount),
  ])
}

function* watchFetchStorePaymentAccounts() {
  util.log('In watchFetchStorePaymentAccounts')
  while (true) {
    const { payload, successCallback, failedCallback } = yield take(bankActions.BANK_FETCH_STORE_PAYMENT_ACCOUNTS)
    yield call(fetchStorePaymentAccounts, { payload, successCallback, failedCallback })
  }
}

// Fetch fetchStorePaymentAccounts and prepare for Store Payment Account List View
function* fetchStorePaymentAccounts({ payload, successCallback, failedCallback }) {
  // util.log('Before api fetch fetchStorePaymentAccounts payload: ', payload)
  try {
    const apiOptions = {
      showSpinner: true,
      axiosOptions: {
        timeout: 600000, // 10 mins
        retry: 0,
      },
    }
    const res = yield call(api.post, api.POST_BANKACCOUNTS, payload, apiOptions)
    // util.log('After api fetch fetchStorePaymentAccounts for store_id: ' + store_id + ' Response: ', res)

    // if (_.has(res, 'payment_accounts')) {
    //   const { payment_accounts } = res
    //   let storePaymentAccts = { store_id: payload.store_id, payment_accounts }
    //   // storePaymentAccts[store_id] = accounts
    //   yield put({ type: bankActions.STORE_PAYMENT_ACCOUNTS_FETCHED, payload: storePaymentAccts })
    // }
    yield call(loadNewPaymentAccountsFromResponse, res, payload.store_id)
    const missSuccessCallback = payload.successCallback
    if (_.isFunction(successCallback)) {
      successCallback(res)
    } else if (!_.isNil(missSuccessCallback) && _.isFunction(missSuccessCallback)) {
      missSuccessCallback(res)
    }
  } catch (error) {
    util.log('After api fetch fetchStorePaymentAccounts error: ', error)
    const missFailedCallback = payload.failedCallback
    if (_.isFunction(failedCallback)) {
      failedCallback(error)
    } else if (!_.isNil(missFailedCallback) && _.isFunction(missFailedCallback)) {
      missFailedCallback(error)
    }
  }
}

// function* watchFetchMyBankAccounts() {
//   util.log('In watchShipmentOrderFetch')
//   while (true) {
//     const { payload } = yield take(bankActions.BANK_FETCH_MY_ACCOUNTS)
//     yield call(fetchMyBankAccounts, payload)
//   }
// }

// // Fetch fetchMyBankAccounts and prepare for Bank Account List View
// function* fetchMyBankAccounts(payload) {
//   util.log('Before api fetch fetchMyBankAccounts payload: ', payload)
//   try {
//     const res = yield call(api.post, api.POST_BANKACCOUNTS, { store_id: 1 })
//     util.log('After api fetch fetchMyBankAccounts Response: ', res)
//     if (_.has(res, 'accounts')) {
//       const { accounts } = res
//       // yield put({ type: bankActions.BANK_ACCOUNTS_LOAD, payload: accounts })
//       yield put({ type: bankActions.BANK_ACCOUNTS_LOAD, payload: accounts })
//     }
//   } catch (error) {
//     util.log('After api fetch fetchMyBankAccounts error: ', error)
//   }
// }

function* watchAddBankAccount() {
  util.log('In watchAddBankAccount')
  while (true) {
    const { payload } = yield take(bankActions.BANK_EDITING_ACCOUNT_NEW)
    yield call(addBankAccount, payload)
  }
}

function* addBankAccount({ body, callback }) {
  util.log('Before api fetch addBankAccount payload: ', body)
  try {
    const apiOptions = {
      messages: {
        successMsg: 'สร้างบัญชีรับชำระสำเร็จ',
        errorMsg: 'เกิดข้อผิดพลาดในดำเนินการสร้างบัญชีรับชำระ',
      },
      showSpinner: true,
    }
    const res = yield call(api.put, api.PUT_BANKACCOUNTS, body, apiOptions)
    util.log('After api fetch addBankAccount Response: ', res)
    yield call(loadNewPaymentAccountsFromResponse, res, body.store_id)
    if (callback) {
      callback()
    }
  } catch (error) {
    util.log('After api fetch addBankAccount error: ', error)
  }
}

function* watchEditBankAccount() {
  util.log('In watchEditBankAccount')
  while (true) {
    const { payload } = yield take(bankActions.BANK_EDITING_ACCOUNT_SAVE)
    yield call(editBankAccount, payload)
  }
}

function* editBankAccount({ body, callback }) {
  util.log('Before api fetch editBankAccount payload: ', body)
  try {
    const apiOptions = {
      messages: {
        successMsg: 'แก้ไขบัญชีรับชำระสำเร็จ',
        errorMsg: 'เกิดข้อผิดพลาดในการแก้ไขบัญชีรับชำระ',
      },
      showSpinner: true,
    }
    const res = yield call(api.patch, api.PATCH_BANKACCOUNTS, body, apiOptions)
    util.log('After api fetch editBankAccount Response: ', res)
    yield call(loadNewPaymentAccountsFromResponse, res, body.store_id)
    if (callback) {
      callback()
    }
  } catch (error) {
    util.log('After api fetch editBankAccount error: ', error)
  }
}

function* watchDeleteBankAccount() {
  util.log('In watchDeleteBankAccount')
  while (true) {
    const { payload } = yield take(bankActions.BANK_EDITING_ACCOUNT_DELETE)
    yield call(deleteBankAccount, payload)
  }
}

function* deleteBankAccount({ body, callback }) {
  util.log('Before api fetch deleteBankAccount payload: ', body)
  try {
    const apiOptions = {
      messages: {
        successMsg: 'ลบบัญชีสำเร็จ',
        // errorMsg: 'เกิดข้อผิดพลาดในการแก้ไขบัญชีรับชำระ',
      },
      showSpinner: true,
    }
    const res = yield call(api.del, api.DELETE_BANKACCOUNT, body, apiOptions)
    util.log('After api fetch deleteBankAccount Response: ', res)
    yield call(loadNewPaymentAccountsFromResponse, res, body.store_id)
    if (callback) {
      callback()
    }
  } catch (error) {
    util.log('After api fetch deleteBankAccount error: ', error)
  }
}

function* loadNewPaymentAccountsFromResponse(res, currentStoreID) {
  if (_.has(res, 'payment_accounts')) {
    const { payment_accounts } = res
    const storePaymentAccts = { store_id: currentStoreID, payment_accounts }
    yield put({ type: bankActions.STORE_PAYMENT_ACCOUNTS_FETCHED, payload: storePaymentAccts })
  }
}
