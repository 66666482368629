import React, { forwardRef } from 'react'
import { StyleProp, StyleSheet, TextStyle, ViewStyle } from 'react-native'
import { IBoxProps } from 'native-base'
import AntDesign from 'react-native-vector-icons/AntDesign'
import Entypo from 'react-native-vector-icons/Entypo'
import EvilIcons from 'react-native-vector-icons/EvilIcons'
import Feather from 'react-native-vector-icons/Feather'
import FontAwesome from 'react-native-vector-icons/FontAwesome'
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5'
import Foundation from 'react-native-vector-icons/Foundation'
import Ionicons from 'react-native-vector-icons/Ionicons'
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons'
import MaterialIcons from 'react-native-vector-icons/MaterialIcons'
import Octicons from 'react-native-vector-icons/Octicons'
import SimpleLineIcons from 'react-native-vector-icons/SimpleLineIcons'
import Zocial from 'react-native-vector-icons/Zocial'
import { VariantType } from 'native-base/lib/typescript/components/types'

// import styled from 'styled-components/native'
// import { system } from 'styled-system'
// import { propConfig } from 'x/config/nativebase/styled-system'
import theme from 'x/config/nativebase/theme'
import { getComputedCustomStyledProps } from 'x/config/nativebase/makeStyledComponent'
import { merge } from 'lodash'
// import theme from 'x/config/nativebase/theme'

// const nbBox = system(propConfig)
// const StyledAntDesign = styled(AntDesign)(nbBox)
// const StyledEntypo = styled(Entypo)(nbBox)
// const StyledEvilIcons = styled(EvilIcons)(nbBox)
// const StyledFeather = styled(Feather)(nbBox)
// const StyledFontAwesome = styled(FontAwesome)(nbBox)
// const StyledFontAwesome5 = styled(FontAwesome5)(nbBox)
// const StyledFoundation = styled(Foundation)(nbBox)
// const StyledIonicons = styled(Ionicons)(nbBox)
// const StyledMaterialCommunityIcons = styled(MaterialCommunityIcons)(nbBox)
// const StyledMaterialIcons = styled(MaterialIcons)(nbBox)
// const StyledOcticons = styled(Octicons)(nbBox)
// const StyledSimpleLineIcons = styled(SimpleLineIcons)(nbBox)
// const StyledZocial = styled(Zocial)(nbBox)

// const StyledAntDesign = makeStyledComponent(AntDesign, 'Icon')
// const StyledEntypo = makeStyledComponent(Entypo, 'Icon')
// const StyledEvilIcons = makeStyledComponent(EvilIcons, 'Icon')
// const StyledFeather = makeStyledComponent(Feather, 'Icon')
// const StyledFontAwesome = makeStyledComponent(FontAwesome, 'Icon')
// const StyledFontAwesome5 = makeStyledComponent(FontAwesome5, 'Icon')
// const StyledFoundation = makeStyledComponent(Foundation, 'Icon')
// const StyledIonicons = makeStyledComponent(Ionicons, 'Icon')
// const StyledMaterialCommunityIcons = makeStyledComponent(MaterialCommunityIcons, 'Icon')
// const StyledMaterialIcons = makeStyledComponent(MaterialIcons, 'Icon')
// const StyledOcticons = makeStyledComponent(Octicons, 'Icon')
// const StyledSimpleLineIcons = makeStyledComponent(SimpleLineIcons, 'Icon')
// const StyledZocial = makeStyledComponent(Zocial, 'Icon')

export type IIconType =
  | 'AntDesign'
  | 'Entypo'
  | 'EvilIcons'
  | 'Feather'
  | 'FontAwesome'
  | 'FontAwesome5'
  | 'Foundation'
  | 'Ionicons'
  | 'MaterialCommunityIcons'
  | 'MaterialIcons'
  | 'Octicons'
  | 'SimpleLineIcons'
  | 'Zocial' // Icon family

// const IconMap = {
//   AntDesign,
//   Entypo,
//   EvilIcons,
//   Feather,
//   FontAwesome,
//   FontAwesome5,
//   Foundation,
//   Ionicons,
//   MaterialCommunityIcons,
//   MaterialIcons,
//   Octicons,
//   SimpleLineIcons,
//   Zocial, // Icon family
// }

// export type IIconProps = Pick<INativeBaseIconProps, Exclude<keyof INativeBaseIconProps, 'as' | 'style'>> & {
export interface IIconProps extends IBoxProps {
  name: string
  type?: IIconType
  style?: StyleProp<ViewStyle | TextStyle>
  variant?: VariantType<'Icon'>
  children?: JSX.Element[] | JSX.Element
}

export const getIconSpaceSize = (fontSizeToken) => {
  if (!fontSizeToken) {
    return 10
  }
  const comp: any = theme.components.Icon
  if (!comp || !comp.sizes || !comp.sizes[fontSizeToken]) {
    return 10
  }

  const sizeToken = comp.sizes[fontSizeToken]
  return theme.sizes[sizeToken] || 16
}

const getIconComponentByType = (type) => {
  switch (type) {
    case 'AntDesign':
      return AntDesign
    case 'Entypo':
      return Entypo
    case 'EvilIcons':
      return EvilIcons
    case 'Feather':
      return Feather
    case 'FontAwesome':
      return FontAwesome
    case 'FontAwesome5':
      return FontAwesome5
    case 'Foundation':
      return Foundation
    case 'MaterialCommunityIcons':
      return MaterialCommunityIcons
    case 'MaterialIcons':
      return MaterialIcons
    case 'Octicons':
      return Octicons
    case 'SimpleLineIcons':
      return SimpleLineIcons
    case 'Zocial':
      return Zocial
    default:
      return Ionicons
  }
}

// const Icon: React.FC<IIconProps> = ({ type = 'Ionicons', style = {}, ...restProps }) => {

// @ts-ignore
const Icon = forwardRef((props, ref) => {
  // const injectProps: INativeBaseIconProps = {
  //   as: IconMap[type],
  // }
  // const combineStyle: IAnyObject = StyleSheet.flatten(style)
  // if (combineStyle && combineStyle.color) {
  //   injectProps.color = combineStyle.color
  // }

  // @ts-ignore
  const { size = 'sm', type = 'Ionicons', style = {}, ...restNonFocusProps } = props

  const IconComp = getIconComponentByType(type)
  const fontSize = getIconSpaceSize(size)

  const { styleFromProps, underscoreProps, restProps } = getComputedCustomStyledProps(
    merge(restNonFocusProps, { fontSize, lineHeight: fontSize }),
    'Icon'
  )
  // @ts-ignore
  // const { _text = {}, _icon = {}, _stack = {} } = underscoreProps

  // @ts-ignore
  return (
    <IconComp
      ref={ref}
      //  fontSize={fontSize} lineHeight={fontSize}
      style={[styleFromProps, StyleSheet.flatten(style)]}
      {...restProps}
    />
  )
  // return <NativeBaseIcon size='sm' {...injectProps} {...restProps} />
})

// getBaseStyle, getVariantProps

Icon.displayName = 'Icon'
// @ts-ignore
Icon.defaultProps = { theme }
// Icon.defaultProps = getComponentDefaultProps('Icon')
// console.log('Icon.defaultProps => ', Icon.defaultProps)

export default Icon as React.FC<IIconProps>
