import React from 'react'
import { TouchableOpacity, View } from 'react-native'

import _ from 'lodash'
import * as util from 'x/utils/util'
import { COLORS, STYLES, S } from 'x/config/styles'
import * as xUtil from 'x/utils/util'
import { getConfig } from 'x/config/mode'
import { fromJS, isImmutable } from 'immutable'

import p from 'x/config/platform-specific'
import CONS from 'x/config/constants'
import XContainer from 'xui/components/XContainer'
import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import XCard from 'xui/components/XCard'
import XText from 'xui/components/XText'
import XIcon from 'xui/components/XIcon'
import XContent from 'xui/components/XContent'
import XCustomHeader from 'xui/components/XCustomHeader'
import ImgManager from 'xui/components/ImgManager'
import { IXScreenProps } from 'x/types'

export interface Props extends IXScreenProps {
  addNotesOrder
  subscription
  order
  editingOrder
  fetchOrderNotes
  selectedStore
  addOrderNote
  selectOrderNote
  editOrderNote
}

interface State {
  mode: string
  uploadingThisOrderNote: boolean
  notes: any

  showOrderReceiverNote: boolean
  showSelectOrderSellNote: boolean
  showThisOrderNote: boolean
  showNotChoose: boolean

  changeImgBtn: boolean

  showMyImgUploaded: boolean
}

const { TXT_LABEL_SMALLER, FONT_SIZE_NORMAL } = STYLES

const READ_ONLY_MODE = 'READ_ONLY_MODE'
const READ_ONLY_ORDER_LOCK_MODE = 'READ_ONLY_ORDER_LOCK_MODE'
const VIEW_EDIT = 'VIEW_EDIT'
const CREATE = 'CREATE'

const TXT_DETAIL = 'คุณสามารถแนบรูป เพื่อใช้เป็นใบปะหน้าพัสดุสำหรับออเดอร์นี้ แทนการใช้ใบปะหน้าที่สร้างจาก XSelly'
const TXT_IMG_ORIGINAL = 'ผู้ซื้อฉันระบุมา'
const TXT_SELLER_IMG = 'ฉันระบุไว้ในออเดอร์ขาย '
const TXT_SEND_TO_SELLER_IMG = 'ระบุรูปแทนใบปะหน้าให้กับขายส่ง'
const TXT_NEW_IMG = 'ใช้รูปแทนใบปะหน้าของฉัน'

const RECEIVER_IMG = 'RECEIVER_IMG'
const SELLER_IMG = 'SELLER_IMG'
const THIS_IMG = 'THIS_IMG'

const BLACK_COLOR = '#000'
const BORDER_COLOR = '#C9C9C9'

const Radio = ({ disabled = false, checked = null }) => (
  <XIcon
    // style={{
    //   fontSize: STYLES.FONT_ICON_NORMAL,
    //   color: disabled ? COLORS.TEXT_INACTIVE : COLORS.APP_MAIN,
    //   textAlign: 'center',
    // }}
    name={checked ? 'radio-button-on' : 'radio-button-off'}
    variant={!checked || disabled ? 'inactive' : 'primary'}
  />
)

export default abstract class BaseUIAddCoverSheet extends React.Component<Props, State> {
  static displayName = 'BaseUIAddCoverSheet'

  // @ts-ignore
  receiverimgManagerRef?: React.RefObject<ImgManager>

  // @ts-ignore
  orderSellImgManagerRef?: React.RefObject<ImgManager>

  // @ts-ignore
  thisImgManagerRef?: React.RefObject<ImgManager>

  inProcess: boolean

  // static navigationOptions = ({ navigation }) => {
  //   const { state, goBack } = navigation
  //   // let inProcess = state.params ? state.params.inProcess : false
  //   // let handleSaveBtn = state.params ? state.params.handleSaveBtn : null
  //   return {
  //     headerLeft:
  //       state && state.params && state.params.inProcess ? null : (
  //         <Button onPress={() => util.navGoBack(this.props)}>
  //           <BackIcon />
  //         </Button>
  //       ),

  //     headerRight: null,
  //     title: 'รูปแทนใบปะหน้าจากระบบอื่น',
  //   }
  // }

  constructor(props) {
    super(props)
    this.state = {
      mode: CREATE,
      uploadingThisOrderNote: false,
      notes: null,

      showOrderReceiverNote: false,
      showSelectOrderSellNote: false,
      showThisOrderNote: false,
      showNotChoose: false,

      changeImgBtn: false,

      showMyImgUploaded: false,
    }
    this.updateView = this.updateView.bind(this)
    this._selectNoteOption = this._selectNoteOption.bind(this)

    this.receiverimgManagerRef = React.createRef()
    this.orderSellImgManagerRef = React.createRef()
    this.thisImgManagerRef = React.createRef()
  }

  // abstract getImgManagerComponent(): React.ComponentClass<IImgManagerProps, IImgManagerState>

  async componentDidMount() {
    const { navigation, selectedStore } = this.props
    const params = util.getNavParams(this.props)
    this.inProcess = false
    let respond
    navigation.setParams({ inProcess: true })
    const focusedOrder = this._getFocusedOrder()
    const orderType = focusedOrder.get('type')
    // console.log(focusedOrder.toJS())
    // console.log('MODE => ', params.mode)
    if (params.mode !== CREATE) {
      await new Promise(async (resolve) => {
        this.props.fetchOrderNotes({
          body: {
            store_id: selectedStore.get('id'),
            order_id: focusedOrder.get('id'),
            type: 1,
          },
          successCallback: (res) => {
            respond = res.note_options
            setTimeout(resolve, 200)
            // this.setState({ notes: res.notes },
            //   () => setTimeout(resolve, 200))
          },
          failedCallback: () => {
            p.op.showToast('พบข้อผิดพลาด กรุณาทำรายการใหม่ภายหลัง', 'danger', 2000)
            util.navGoBack(this.props)
          },
        })
      })
      // const { notes } = this.state
      // console.log('respond => ', respond)
      // console.log(focusedOrder.get('type'))
      // console.log(params.mode)
      let showOrderReceiverNote = false
      let showSelectOrderSellNote = false
      let showThisOrderNote = false
      let showNotChoose = false

      let uploadingThisOrderNote = false
      let changeImgBtn = false

      let showMyImgUploaded = false
      const myNote = _.find(respond, (note) => note.created_by === 'm' && note.type === 1)
      const p = _.find(respond, (note) => note.created_by === 'p' && note.type === 1)
      const r = _.find(respond, (note) => note.created_by === 'r' && note.type === 1)
      if (_.isNil(myNote)) {
        showThisOrderNote = true
        showMyImgUploaded = true
      } else {
        // this._renderImgThisOrder(myNote)
        showThisOrderNote = true
        uploadingThisOrderNote = true
        showNotChoose = true
        changeImgBtn = true
        showMyImgUploaded = false
      }
      if (!_.isNil(p)) {
        // this._renderImgSelectOrderNote(p)
        showSelectOrderSellNote = true
        showNotChoose = true
      }
      if (!_.isNil(r)) {
        // this._renderImgReceiver(r)
        showOrderReceiverNote = true
        showNotChoose = true
      }

      if (orderType === 3 && params.mode !== CREATE) {
        showNotChoose = true
      }
      await util.setStatePromise(this, {
        showOrderReceiverNote,
        showSelectOrderSellNote,
        showThisOrderNote,
        showNotChoose,
        mode: params.mode,
        notes: respond,
        uploadingThisOrderNote,
        changeImgBtn,
        showMyImgUploaded,
      })
      navigation.setParams({ inProcess: false })
    } else if (params.mode === CREATE) {
      let notesOrder = focusedOrder.get('notes')
      if (_.isNil(notesOrder)) {
        notesOrder = fromJS([])
      }
      const note = _.find(notesOrder.toJS(), (thisNote) => thisNote.type === 1)
      if (!_.isNil(note)) {
        // if (this.thisImgManagerRef && this.thisImgManagerRef.loadImageUrls) {
        //   this.thisImgManagerRef.loadImageUrls({
        //     allUploaded: true,
        //     p: [note.value],
        //   })
        await util.setStatePromise(this, {
          uploadingThisOrderNote: false,
          showNotChoose: true,
          showOrderReceiverNote: false,
          showSelectOrderSellNote: false,
          mode: params.mode,
          changeImgBtn: true,
          showThisOrderNote: true,
          notes: [note],
        })
        navigation.setParams({ inProcess: false })
        // }
      } else {
        this.setState({
          showNotChoose: false,
          showOrderReceiverNote: false,
          showSelectOrderSellNote: false,
          mode: params.mode,
          changeImgBtn: false,
          showThisOrderNote: true,
          notes: null,
          showMyImgUploaded: true,
        })
        navigation.setParams({ inProcess: false })
      }
    }

    this.updateView()
  }

  updateView = () => {
    const { notes, mode } = this.state
    // console.log('componentDidUpdate')
    // console.log('notesssss => ', notes)
    let note = _.find(notes, (thisNote: { created_by: string; type: number }) => thisNote.created_by === 'm' && thisNote.type === 1)
    if (mode === CREATE) {
      note = _.find(notes, (thisNote) => thisNote.type === 1)
    }
    // console.log('note !! => ', note)
    if (!_.isNil(note)) {
      this._renderImgThisOrder(note)
    }

    const r = _.find(notes, (thisNote) => thisNote.created_by === 'r' && thisNote.type === 1)
    if (!_.isNil(r)) {
      this._renderImgReceiver(r)
    }

    const pParametor = _.find(notes, (thisNote) => thisNote.created_by === 'p' && thisNote.type === 1)
    if (!_.isNil(pParametor)) {
      this._renderImgSelectOrderNote(pParametor)
    }
  }

  shouldComponentUpdate() {
    return true
  }

  _renderImgThisOrder = (note) => {
    if (this.thisImgManagerRef.current && this.thisImgManagerRef.current.loadImageUrls) {
      this.thisImgManagerRef.current.loadImageUrls({
        allUploaded: true,
        p: [note.value],
      })
    }
  }

  _renderImgReceiver = (note) => {
    if (this.receiverimgManagerRef.current && this.receiverimgManagerRef.current.loadImageUrls) {
      this.receiverimgManagerRef.current.loadImageUrls({
        allUploaded: true,
        i: [note.value],
      })
    }
  }

  _renderImgSelectOrderNote = (note) => {
    if (this.orderSellImgManagerRef.current && this.orderSellImgManagerRef.current.loadImageUrls) {
      this.orderSellImgManagerRef.current.loadImageUrls({
        allUploaded: true,
        i: [note.value],
      })
    }
  }

  saveSuccess = (notes) => {
    const { navigation } = this.props
    this.setState({
      uploadingThisOrderNote: false,
      changeImgBtn: true,
      notes,
      showNotChoose: true,
    })
    // p.op.showToast('เพิ่มรูปแทนใบปะหน้าสำเร็จแล้ว', 'success', 2000)
    navigation.setParams({ inProcess: false })
    this.inProcess = false
    // util.navGoBack(this.props)
  }

  _getFocusedOrder = () => {
    const { editingOrder, navigation } = this.props
    const sourceOrder = editingOrder
    const targetIndex = util.getNavParam(this.props, 'orderTabIndex', 0)
    return util.getFocusedOrder(sourceOrder, targetIndex)
  }

  _deleteImageNote = async (key) => {
    // await new Promise(async resolve => {
    //   Alert.alert(
    //     null,
    //     'คุณต้องการลบรูปบนใบปะหน้าพัสดุ ใช่หรือไม่',
    //     [
    //       {
    //         text: 'ใช่',
    //         onPress: () => {
    //           setTimeout(resolve, 200)
    //         },
    //       },
    //       {
    //         text: 'ยกเลิก',
    //         onPress: () => {
    //           return
    //         },
    //         style: 'cancel',
    //       },
    //     ],
    //     { cancelable: false },
    //   )
    // })
    const isUserConfirm = await p.op.isUserConfirm(null, 'คุณต้องการลบรูปบนใบปะหน้าพัสดุ ใช่หรือไม่', 'ใช่', 'ยกเลิก')
    if (!isUserConfirm) {
      return
    }

    const { mode } = this.state
    if (mode === CREATE) {
      this.deleteNoteOrder()
    }

    // if (key === RECEIVER_IMG) {
    // } else if (key === SELLER_IMG) {
    // } else if (key === THIS_IMG) {
    // }
  }

  deleteNoteOrder = () => {
    // const { state, goBack } = this.props.navigation
    const focusedOrder = this._getFocusedOrder()
    let notesOrder = focusedOrder.get('notes')
    if (_.isNil(notesOrder)) {
      notesOrder = fromJS([])
    }
    const note = _.find(notesOrder.toJS(), (thisNote) => thisNote.type === 1)
    if (_.isNil(note)) {
      p.op.showToast('เกิดข้อผิดพลาดกรุณาทำรายการใหม่ภายหลัง', 'warning', 2000)
      return
    }
    let data = xUtil.deleteNotesOrder(notesOrder.toJS(), 1)
    if (_.isEmpty(data)) {
      data = null
    }
    this.props.addNotesOrder(data)
    this.setState({ uploadingThisOrderNote: false, showNotChoose: false, changeImgBtn: false, notes: data })
    // p.op.showToast('ลบรูปใบปะหน้าเรียบร้อยแล้ว', 'success', 2000)
    // console.log(this.thisImgManagerRef)
    this.thisImgManagerRef.current.deleteImageAtIndex(0)
    // this.thisImgManagerRef.clearImages()
    // util.navGoBack(this.props)
  }

  // _checkStateCanUpload = key => {
  //   const { showSelectOrderSellNote, showOrderReceiverNote, showThisOrderNote } = this.state
  //   if (key === RECEIVER_IMG) {
  //     if (showOrderReceiverNote) {
  //       return false
  //     } else {
  //       return true
  //     }
  //   }
  //   if (key === SELLER_IMG) {
  //     if (showSelectOrderSellNote) {
  //       return false
  //     } else {
  //       return true
  //     }
  //   }
  //   if (key === THIS_IMG) {
  //     if (showThisOrderNote) {
  //       return false
  //     } else {
  //       return true
  //     }
  //   }
  // }

  _saveImg = async (key) => {
    const { mode, notes } = this.state
    const focusedOrder = this._getFocusedOrder()
    const { selectedStore, navigation } = this.props
    const orderType = focusedOrder.get('type')
    const myNote = _.find(notes, (note) => note.created_by === 'm' && note.type === 1)

    if (mode === READ_ONLY_MODE && orderType !== 1) {
      await p.op.showConfirmationOkOnly(
        null,
        'อยู่โหมดป้องกันการแก้ไข เนื่องจากออเดอร์นี้ไม่มีสินค้าที่คุณต้องจัดส่งหากต้องการแก้ไข กรุณาแก้ที่ออเดอร์ซื้อ ที่เกี่ยวข้อง'
      )
      return
    }
    if (mode === READ_ONLY_ORDER_LOCK_MODE && orderType !== 1) {
      await p.op.showConfirmationOkOnly(
        null,
        'อยู่โหมดป้องกันการแก้ไข เนื่องจากออเดอร์นี้ถูกล็อกอยู่หากต้องการแก้ไข กรุณาแจ้งให้ผู้ขายปลดล็อกก่อน'
      )
      return
    }
    // // await util.setStatePromise(this, { uploadingThisOrderNote: true, changeImgBtn: true, })
    // // await util.delay(5200)
    // await this.thisImgManagerRef.deleteImageAtIndex(0)
    // // await util.delay(3200)
    // // let outputX = await this.thisImgManagerRef.submitUploadImages()
    // // console.log(outputX)
    // return

    navigation.setParams({ inProcess: true })

    if (mode === CREATE) {
      await util.setStatePromise(this, { uploadingThisOrderNote: true, showMyImgUploaded: true })
      await util.delay(3200)
      if (this.thisImgManagerRef.current && this.thisImgManagerRef.current.submitUploadImages) {
        let output = await this.thisImgManagerRef.current.submitUploadImages()
        let notesOrder = focusedOrder.get('notes')
        if (_.isNil(notesOrder)) {
          notesOrder = fromJS([])
        }
        // let note = _.find(notesOrder.toJS(), note => note.type === 1)
        if (_.isNil(output.p[0])) {
          await util.delay(3200)
          output = await this.thisImgManagerRef.current.submitUploadImages()
        }
        if (_.isNil(output.p[0])) {
          await p.op.showConfirmationOkOnly(null, 'ไม่สามารถแนบรูปใบปะหน้าพัสดุได้ กรุณาทำรายการนี้ใหม่อีกครั้ง')
          util.navGoBack(this.props)
          return
        }
        if (notesOrder.toJS().length === 0) {
          const data = [
            {
              value: output.p[0],
              type: 1,
            },
          ]
          this.props.addNotesOrder([
            {
              value: output.p[0],
              type: 1,
            },
          ])
          this.saveSuccess(data)
        } else {
          const noteOj = { value: output.p[0], type: 1 }
          const data = xUtil.requiredNewArrayNoteOrder(notesOrder.toJS(), 1, noteOj)
          this.props.addNotesOrder(data)
          this.saveSuccess(data)
        }
      }
    } else if (key === THIS_IMG) {
      if (!_.isNil(myNote)) {
        await this._editNote(myNote)
        // return
      } else {
        const onSuccessEditNoteCallback = util.getNavParam(this.props, 'onSuccessEditNoteCallback', () => {})
        let purchase
        const orderType = focusedOrder.get('type')
        let suborders = focusedOrder.get('suborders')
        // let orderTypeCheck = false
        let supTitle = 'คุณต้อง เลือกหมายเหตุนี้ที่ออเดอร์ซื้อเองหลังจากยืนยันการเพิ่ม หากต้องการให้ผู้ขายพิมพ์หมายเหตุนี้บนใบปะหน้า'
        if (orderType === 1 || orderType === 2) {
          if (suborders.size === 0) {
            suborders = null
          }
          if (_.isNil(suborders)) {
            supTitle = null
            // orderTypeCheck = true
          }
        }
        if (orderType !== 3 && orderType !== 4) {
          // await new Promise(async resolve => {
          //   Alert.alert(
          //     'ยืนยันการเพิ่มรูปแทนใบปะหน้าพัสดุ',
          //     supTitle,
          //     [
          //       {
          //         text: 'ยกเลิก',
          //         onPress: () => {
          //           setParams({ inProcess: false })
          //           this.thisImgManagerRef.deleteImageAtIndex(0)
          //           setTimeout(resolve, 200)
          //         },
          //         style: 'cancel',
          //       },
          //       {
          //         text: 'ยืนยัน',
          //         onPress: () => {
          //           purchase = true
          //           setTimeout(resolve, 200)
          //         },
          //       },
          //     ],
          //     { cancelable: false },
          //   )
          // })
          const isUserConfirm = await p.op.isUserConfirm(null, 'ยืนยันการเพิ่มรูปแทนใบปะหน้าพัสดุ', 'ยืนยัน', 'ยกเลิก')
          if (!isUserConfirm) {
            navigation.setParams({ inProcess: false })
            this.thisImgManagerRef.current.deleteImageAtIndex(0)
            return
          }
          purchase = true
        }

        if (_.isNil(purchase)) {
          this.setState({ uploadingThisOrderNote: true, showMyImgUploaded: true })
          if (this.thisImgManagerRef.current && this.thisImgManagerRef.current.submitUploadImages) {
            let output = await this.thisImgManagerRef.current.submitUploadImages()
            if (_.isNil(output.p[0])) {
              await util.delay(3200)
              output = await this.thisImgManagerRef.current.submitUploadImages()
            }
            if (_.isNil(output.p[0])) {
              await p.op.showConfirmationOkOnly(null, 'ไม่สามารถแนบรูปใบปะหน้าพัสดุได้ กรุณาทำรายการนี้ใหม่อีกครั้ง')
              util.navGoBack(this.props)
              return
            }
            this.thisImgManagerRef.current.loadImageUrls({
              allUploaded: true,
              p: [output.p[0]],
            })
            this.props.addOrderNote({
              body: {
                store_id: selectedStore.get('id'),
                order_id: focusedOrder.get('id'),
                type: 1,
                value: output.p[0],
              },
              successCallback: (res) => {
                let newNotes = res.note_options
                if (newNotes.length === 0) {
                  newNotes = null
                }
                this.setState({
                  notes: newNotes,
                  showNotChoose: true,
                  uploadingThisOrderNote: true,
                  changeImgBtn: true,
                })
                this.inProcess = false
                onSuccessEditNoteCallback()
                // await setStatePromise(this, { orderTabsVisibility: false })
              },
              failedCallback: () => {
                // this.setState({
                //   notes: this.state.notes,
                // })
                // this.updateView()
                util.navGoBack(this.props)
                p.op.showToast('พบข้อผิดพลาด กรุณาทำรายการใหม่ภายหลัง', 'danger', 2000)
              },
            })
          }
          navigation.setParams({ inProcess: false })
        } else {
          this.setState({ uploadingThisOrderNote: true, showMyImgUploaded: true })
          if (this.thisImgManagerRef.current && this.thisImgManagerRef.current.submitUploadImages) {
            let output = await this.thisImgManagerRef.current.submitUploadImages()
            if (_.isNil(output.p[0])) {
              await util.delay(3200)
              output = await this.thisImgManagerRef.current.submitUploadImages()
            }
            if (_.isNil(output.p[0])) {
              await p.op.showConfirmationOkOnly(null, 'ไม่สามารถแนบรูปใบปะหน้าพัสดุได้ กรุณาทำรายการนี้ใหม่อีกครั้ง')
              util.navGoBack(this.props)
              return
            }
            this.thisImgManagerRef.current.loadImageUrls({
              allUploaded: true,
              p: [output.p[0]],
            })
            this.props.addOrderNote({
              body: {
                store_id: selectedStore.get('id'),
                order_id: focusedOrder.get('id'),
                type: 1,
                value: output.p[0],
                // update_purchase_orders: purchase,
              },
              successCallback: (res) => {
                let newNotes = res.note_options
                if (newNotes.length === 0) {
                  newNotes = null
                }
                this.setState({
                  notes: newNotes,
                  showNotChoose: true,
                  uploadingThisOrderNote: true,
                  changeImgBtn: true,
                })
                this.inProcess = false
                onSuccessEditNoteCallback()
              },
              failedCallback: () => {
                util.navGoBack(this.props)
                p.op.showToast('พบข้อผิดพลาด กรุณาทำรายการใหม่ภายหลัง', 'danger', 2000)
              },
            })
          }
          navigation.setParams({ inProcess: false })
        }
      }
    }
  }

  _editNote = async (note) => {
    const { editOrderNote, navigation, selectedStore } = this.props
    const { updated_at } = note
    // const updated_at = utilNative.getTimeFormatYYYYMMDDhhmmss()
    const focusedOrder = this._getFocusedOrder()
    const orderType = focusedOrder.get('type')
    const onSuccessEditNoteCallback = util.getNavParam(this.props, 'onSuccessEditNoteCallback', () => {})
    const supOrders = focusedOrder.get('suborders')
    const supOrdersMap = isImmutable(supOrders) ? supOrders : fromJS(supOrders)
    let ordertypeOneDontHaveSupOrder = true
    if ((_.isNil(supOrders) || supOrdersMap.size === 0) && orderType === 1) {
      ordertypeOneDontHaveSupOrder = false
    }

    if (ordertypeOneDontHaveSupOrder || orderType === 2) {
      const checkMyNote = _.find(this.state.notes, (thisNote) => thisNote.created_by === 'm' && thisNote.type === 1)
      if (!_.isNil(checkMyNote)) {
        await new Promise(async (resolve) => {
          p.op.showConfirmation(
            null,
            'หากคุณเปลี่ยนรูปแทนใบปะหน้านี้ ในออเดอร์ซื้อที่คุณระบุด้วยรูปนี้จะถูกเปลี่ยนด้วย คุณต้องการเปลี่ยนใช่หรือไม่',
            () => {
              setTimeout(resolve, 200)
            },
            async () => {
              await this.updateView()
              navigation.setParams({ inProcess: false })
            },
            'ตกลง',
            'ยกเลิก'
          )
        })
      }
    }
    if (this.thisImgManagerRef.current && this.thisImgManagerRef.current.submitUploadImages) {
      // await this.thisImgManagerRef.current.deleteImageAtIndex(0)
      // return

      await util.setStatePromise(this, {
        uploadingThisOrderNote: true,
        showMyImgUploaded: true,
      })
      // await util.delay(3200)
      // setStatePro({ uploadingThisOrderNote: true })
      let output = await this.thisImgManagerRef.current.submitUploadImages()
      // await util.delay(3200)
      if (_.isNil(output.p[0])) {
        // await util.delay(3200)
        output = await this.thisImgManagerRef.current.submitUploadImages()
      }
      if (_.isNil(output.p[0])) {
        p.op.showConfirmationOkOnly(null, 'ไม่สามารถแนบรูปใบปะหน้าพัสดุได้ กรุณาทำรายการนี้ใหม่อีกครั้ง')
        util.navGoBack(this.props)
        return
      }
      // console.log(output.p[0])
      // console.log('/////////////////////////////////')
      this.thisImgManagerRef.current.loadImageUrls({
        allUploaded: true,
        p: [output.p[0]],
      })
      editOrderNote({
        body: {
          store_id: selectedStore.get('id'),
          order_id: focusedOrder.get('id'),
          note_id: note.id,
          value: output.p[0],
          updated_at,
        },
        successCallback: async (res) => {
          // await util.setStatePromise(this, {
          //   notes: res.note_options,
          //   showNotChoose: true,
          //   uploadingThisOrderNote: true,
          // }
          this.setState({
            notes: res.note_options,
            showNotChoose: true,
            uploadingThisOrderNote: true,
          })
          onSuccessEditNoteCallback()
          await this.updateView()
          navigation.setParams({ inProcess: false })
          this.inProcess = false
        },
        failedCallback: async (err) => {
          this.setState({
            notes: err.error.attach.note_options,
          })
          await this.updateView()
          onSuccessEditNoteCallback()
          navigation.setParams({ inProcess: false })
          this.inProcess = false
          // navigation.goBack(navigation.state.key)
          // p.op.showToast('พบข้อผิดพลาด กรุณาทำรายการใหม่ภายหลัง', 'danger', 2000)
        },
      })
    }
  }

  _openGallery = () => {
    const { mode } = this.state
    const focusedOrder = this._getFocusedOrder()
    const orderType = focusedOrder.get('type')
    if (mode === READ_ONLY_MODE && orderType !== 1) {
      p.op.showConfirmationOkOnly(
        null,
        'อยู่โหมดป้องกันการแก้ไข เนื่องจากออเดอร์นี้ไม่มีสินค้าที่คุณต้องจัดส่งหากต้องการแก้ไข กรุณาแก้ที่ออเดอร์ซื้อ ที่เกี่ยวข้อง'
      )
      return
    }
    if (mode === READ_ONLY_ORDER_LOCK_MODE && orderType !== 1) {
      p.op.showConfirmationOkOnly(
        null,
        'อยู่โหมดป้องกันการแก้ไข เนื่องจากออเดอร์นี้ถูกล็อกอยู่หากต้องการแก้ไข กรุณาแจ้งให้ผู้ขายปลดล็อกก่อน'
      )
      return
    }
    const myNote = _.find(this.state.notes, (note) => note.created_by === 'm' && note.type === 1)
    if (_.isNil(myNote)) {
      this.thisImgManagerRef.current._galleryChoosePressed(0, false)
    }
  }

  async _selectNoteOption(selected: any, option: number) {
    const { selectedStore } = this.props
    const params = util.getNavParams(this.props)

    const focusedOrder = this._getFocusedOrder()
    const { mode } = this.state
    const orderType = focusedOrder.get('type')
    if (mode === READ_ONLY_MODE && orderType !== 1) {
      p.op.showConfirmationOkOnly(
        null,
        'อยู่โหมดป้องกันการแก้ไข เนื่องจากออเดอร์นี้ไม่มีสินค้าที่คุณต้องจัดส่งหากต้องการแก้ไข กรุณาแก้ที่ออเดอร์ซื้อ ที่เกี่ยวข้อง'
      )
      return
    }
    if (mode === READ_ONLY_ORDER_LOCK_MODE && orderType !== 1) {
      p.op.showConfirmationOkOnly(
        null,
        'อยู่โหมดป้องกันการแก้ไข เนื่องจากออเดอร์นี้ถูกล็อกอยู่หากต้องการแก้ไข กรุณาแจ้งให้ผู้ขายปลดล็อกก่อน'
      )
      return
    }
    if (mode === CREATE) {
      this.deleteNoteOrder()
      return
    }

    if (option === 0) {
      return
    }
    if (selected) {
      return
    }
    const oldNote = _.find(this.state.notes, (note) => note.created_by === 'm' && note.type === 1)
    const checkMyNote = _.find(this.state.notes, (thisNote) => thisNote.created_by === 'm' && thisNote.type === 1)
    if (!_.isNil(checkMyNote)) {
      await new Promise(async (resolve) => {
        p.op.showConfirmation(
          null,
          'หากคุณเปลี่ยนรูปแทนบนใบปะหน้า รูปแทนบนใบปะหน้าที่คุณเพิ่มในออเดอร์นี้จะหายไป ต้องการทำรายการหรือไม่',
          () => {
            setTimeout(resolve, 200)
          },
          () => {},
          'ใช่',
          'ยกเลิก'
        )
      })
    }

    const res: any = await new Promise((resolveResponse) => {
      this.props.selectOrderNote({
        body: {
          store_id: selectedStore.get('id'),
          order_id: focusedOrder.get('id'),
          option,
          type: 1,
        },
        successCallback: resolveResponse,
        failedCallback: () => resolveResponse(null),
      })
    })

    if (res) {
      let newNotes = res.note_options
      let uploadingThisOrderNote = true
      // let note = _.find(res.note_options, note => note.created_by === 'm' && note.type === 1)
      if (newNotes.length === 0) {
        newNotes = null
        if (!_.isNil(oldNote)) {
          // await util.delay(3200)
          await this.thisImgManagerRef.current.deleteImageAtIndex(0)
          uploadingThisOrderNote = false
        }
        await util.setStatePromise(this, {
          notes: newNotes,
          showNotChoose: false,
          showThisOrderNote: true,
          uploadingThisOrderNote,
          changeImgBtn: false,
        })
      } else {
        if (!_.isNil(oldNote)) {
          // await util.delay(3200)
          await this.thisImgManagerRef.current.deleteImageAtIndex(0)
          // await util.delay(3200)
        }

        let showOrderReceiverNote = false
        let showSelectOrderSellNote = false
        let showThisOrderNote = false
        let showNotChoose = false

        let uploadingThisOrderNote = false
        let changeImgBtn = false

        let showMyImgUploaded = false
        const myNote = _.find(newNotes, (note) => note.created_by === 'm' && note.type === 1)
        const p = _.find(newNotes, (note) => note.created_by === 'p' && note.type === 1)
        const r = _.find(newNotes, (note) => note.created_by === 'r' && note.type === 1)
        if (_.isNil(myNote)) {
          showThisOrderNote = true
          showMyImgUploaded = true
        } else {
          // this._renderImgThisOrder(myNote)
          showThisOrderNote = true
          uploadingThisOrderNote = true
          showNotChoose = true
          changeImgBtn = true
          showMyImgUploaded = false
        }
        if (!_.isNil(p)) {
          // this._renderImgSelectOrderNote(p)
          showSelectOrderSellNote = true
          showNotChoose = true
        }
        if (!_.isNil(r)) {
          // this._renderImgReceiver(r)
          showOrderReceiverNote = true
          showNotChoose = true
        }
        this.setState({
          notes: newNotes,
          // showNotChoose: true,
          uploadingThisOrderNote,
          // changeImgBtn: false,
          showOrderReceiverNote,
          showSelectOrderSellNote,
          showThisOrderNote,
          showNotChoose,
          mode: params.mode,
          // notes: respond,
          // uploadingThisOrderNote,
          changeImgBtn,
          // showMyImgUploaded,
        })
        await this.updateView()
      }
    } else {
      util.navGoBack(this.props)
      p.op.showToast('พบข้อผิดพลาด กรุณาทำรายการใหม่ภายหลัง', 'danger', 2000)
    }
  }

  _changeImgBtn = async () => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    const { mode } = this.state
    const focusedOrder = this._getFocusedOrder()
    const orderType = focusedOrder.get('type')
    if (mode === READ_ONLY_MODE && orderType !== 1) {
      await p.op.showConfirmationOkOnly(
        null,
        'อยู่โหมดป้องกันการแก้ไข เนื่องจากออเดอร์นี้ไม่มีสินค้าที่คุณต้องจัดส่งหากต้องการแก้ไข กรุณาแก้ที่ออเดอร์ซื้อ ที่เกี่ยวข้อง'
      )
      return
    }
    if (mode === READ_ONLY_ORDER_LOCK_MODE && orderType !== 1) {
      await p.op.showConfirmationOkOnly(
        null,
        'อยู่โหมดป้องกันการแก้ไข เนื่องจากออเดอร์นี้ถูกล็อกอยู่หากต้องการแก้ไข กรุณาแจ้งให้ผู้ขายปลดล็อกก่อน'
      )
      return
    }
    this.thisImgManagerRef.current._galleryChoosePressed(0, false)
  }

  _renderOrderReceiverNote = (txtPrefix) => {
    // const ImgManager = this.getImgManagerComponent()
    const { notes } = this.state
    let note = _.find(notes, (thisNote) => thisNote.created_by === 'r' && thisNote.type === 1)
    if (_.isNil(note)) {
      note = { selected: false }
    }
    // const pendingShpLblImg = this.props.navigation.getParam('pending_shp_lbl_img', false)
    // if (pendingShpLblImg) {
    //   return (
    //     <Box style={{ flexDirection: 'column', backgroundColor: COLORS.BRAND_Danger }}>
    //       <XText style={{ fontSize: FONT_SIZE_LARGER, color: '#fff' }}>มีรูปแทนใบปะหน้าที่รอผู้ซื้อยืนยัน</XText>

    //       <XText style={{ fontSize: FONT_SIZE_LARGER, color: '#fff' }}>กรุณาติดต่อผู้ซื้อเพื่อให้ยืนยันออเดอร์</XText>
    //     </Box>
    //   )
    // }

    return (
      <View style={[S.ROW_MIDDLE_START, S.WIDTH_FULL]}>
        <HStack minW='50px' alignItems='center' justifyContent='center'>
          <TouchableOpacity
            onPress={() => {
              this._selectNoteOption(note.selected, 1)
            }}
            // style={{ width: 50, height: 50, justifyContent: 'center', alignItems: 'center' }}
          >
            <Radio disabled={false} checked={!!note.selected} />
          </TouchableOpacity>
        </HStack>
        <View
          style={[
            S.FLEX,
            S.COLUMN_LEFT_TOP,
            S.CARDLIKE_BODY,
            S.CARDLIKE_MARGIN,
            S.BORDER,
            { borderColor: note.selected ? COLORS.APP_MAIN : BORDER_COLOR },
          ]}>
          <View style={[S.CARDLIKE_BODY, { paddingTop: 15 }]}>
            <XText variant='inactive'>{true ? TXT_IMG_ORIGINAL : TXT_DETAIL}</XText>
          </View>
          <View
            style={[
              S.CARDLIKE_BODY,
              { justifyContent: 'flex-start', alignItems: 'flex-start', marginLeft: 0, paddingLeft: 0, paddingTop: 0 },
            ]}>
            <View style={[S.CARDLIKE_BODY, { width: 150, height: 100, marginLeft: 0, paddingLeft: 0, paddingTop: 0 }]}>
              <ImgManager
                fileNamePrefix={txtPrefix}
                metadata={[{ key: 'i', ...CONS.IMG_MANAGER_SIZES.COVER_SHEET, cropping: true }]}
                // ref={(r) => r && (this.receiverimgManagerRef = r)}
                ref={this.receiverimgManagerRef}
                showUploaded={false}
                // readonly={true}
                maxLength={1}
              />
            </View>
            {/* <Box style={{ flex: 1 }} /> */}
            {/* </Box> */}
          </View>
        </View>
        {/* <View style={[S.ROW_CENTER, { width: 50 }]} /> */}
      </View>
      // <View><Box style={{ marginTop: 10, paddingBottom: 0 }}>
      //   <XText variant='inactive'>
      //     {
      //       true ? TXT_IMG_ORIGINAL : TXT_DETAIL
      //     }
      //   </XText>
      // </Box>

      //   <Box style={{ flexDirection: 'row', flex: 1 }}>
      //     <View style={{ justifyContent: 'center', alignItems: 'center', flex: p.op.isWeb() ? 1 : 0, width: 100 }}>
      //       {
      //         true ? <TouchableOpacity onPress={() => { this._selectNoteOption(note.selected, 1) }} style={{ width: 50, height: 50, justifyContent: 'center', alignItems: 'center' }}>
      //           <Radio disabled={false} checked={note.selected ? true : false} />
      //         </TouchableOpacity> : null
      //       }
      //     </View>
      //     <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}>
      //       <ImgManager
      //         fileNamePrefix={txtPrefix}
      //         metadata={[{ key: 'i', width: 1200, height: 1900, cropping: true }]}
      //         ref={r => r && (this.receiverimgManagerRef = r)}
      //         showUploaded={true}
      //         readonly={true}
      //         maxLength={1}
      //       // onPressChooseSuccess={() => { this._saveImg(RECEIVER_IMG) }}
      //       // onPressChooseFailed={() => {  }}
      //       />
      //     </View>
      //     <View style={{ justifyContent: 'center', alignItems: 'center', flex: p.op.isWeb() ? 1 : 0, width: 100 }}>
      //       {
      //         false ? <TouchableOpacity onPress={() => this._deleteImageNote(RECEIVER_IMG)} style={{ width: 50, height: 50, justifyContent: 'center', alignItems: 'center' }}>
      //           <XIcon name={'delete-forever'} family={'MaterialCommunityIcons'}
      //             style={{
      //               fontSize: STYLES.FONT_ICON_NORMAL,
      //               color: COLORS.APP_MAIN,
      //               textAlign: 'center',
      //               paddingTop: 12
      //             }} />
      //         </TouchableOpacity> : null
      //       }

      //     </View>
      //   </Box></View>
    )
  }

  _renderSelectOrderSellNote = (txtPrefix) => {
    // const ImgManager = this.getImgManagerComponent()
    const focusedOrder = this._getFocusedOrder()
    const orderType = focusedOrder.get('type')
    const { notes } = this.state
    let note = _.find(notes, (thisNote) => thisNote.created_by === 'p' && thisNote.type === 1)
    if (_.isNil(note)) {
      note = { selected: false }
    }
    // const pendingShpLblImg = this.props.navigation.getParam('pending_shp_lbl_img', false)
    // if (pendingShpLblImg) {
    //   return (
    //     <Box style={{ flexDirection: 'column', backgroundColor: COLORS.BRAND_Danger }}>
    //       <XText style={{ fontSize: FONT_SIZE_LARGER, color: '#fff' }}>มีรูปแทนใบปะหน้าที่รอผู้ซื้อยืนยัน</XText>

    //       <XText style={{ fontSize: FONT_SIZE_LARGER, color: '#fff' }}>กรุณาติดต่อผู้ซื้อเพื่อให้ยืนยันออเดอร์</XText>
    //     </Box>
    //   )
    // }
    return (
      <View style={[S.ROW_MIDDLE_START, S.WIDTH_FULL]}>
        <HStack minW='50px' alignItems='center' justifyContent='center'>
          <TouchableOpacity
            onPress={() => {
              this._selectNoteOption(note.selected, 2)
            }}
            // style={{ width: 50, height: 50, justifyContent: 'center', alignItems: 'center' }}
          >
            <Radio disabled={false} checked={!!note.selected} />
          </TouchableOpacity>
        </HStack>
        <View
          style={[
            S.FLEX,
            S.COLUMN_LEFT_TOP,
            S.CARDLIKE_MARGIN,
            S.CARDLIKE_BODY,
            S.BORDER,
            { borderColor: note.selected ? COLORS.APP_MAIN : BORDER_COLOR },
          ]}>
          <View style={[S.CARDLIKE_BODY, { paddingTop: 15 }]}>
            <XText variant='inactive'>{orderType === 2 ? TXT_SEND_TO_SELLER_IMG : TXT_SELLER_IMG}</XText>
          </View>
          <View
            style={[
              S.CARDLIKE_BODY,
              { justifyContent: 'flex-start', alignItems: 'flex-start', marginLeft: 0, paddingLeft: 0, paddingTop: 0 },
            ]}>
            <View style={[S.CARDLIKE_BODY, { width: 150, height: 100, marginLeft: 0, paddingLeft: 0, paddingTop: 0 }]}>
              <ImgManager
                fileNamePrefix={txtPrefix}
                metadata={[{ key: 'i', ...CONS.IMG_MANAGER_SIZES.COVER_SHEET, cropping: true }]}
                // ref={(r) => r && (this.orderSellImgManagerRef = r)}
                ref={this.orderSellImgManagerRef}
                showUploaded={false}
                // readonly={true}
                maxLength={1}
              />
            </View>
            {/* <Box style={{ flex: 1 }} /> */}
            {/* </Box> */}
          </View>
        </View>
        {/* <View style={[S.ROW_CENTER, { width: 50 }]} /> */}
      </View>
      // <View>
      //   <Box style={{ marginTop: 10, paddingBottom: 0 }}>
      //     <XText variant='inactive'>
      //       {orderType === 2 ? TXT_SEND_TO_SELLER_IMG : TXT_SELLER_IMG}
      //     </XText>
      //   </Box>
      //   <Box style={{ flexDirection: 'row', flex: 1 }}>
      //     <View style={{ justifyContent: 'center', alignItems: 'center', flex: p.op.isWeb() ? 1 : 0, width: 100 }}>
      //       {
      //         true ? <TouchableOpacity onPress={() => { this._selectNoteOption(note.selected, 2) }} style={{ width: 50, height: 50, justifyContent: 'center', alignItems: 'center' }}>
      //           <Radio disabled={false} checked={note.selected ? true : false} />
      //         </TouchableOpacity> : null
      //       }
      //     </View>
      //     <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}>
      //       <ImgManager
      //         fileNamePrefix={txtPrefix}
      //         metadata={[{ key: 'i', width: 1200, height: 1900, cropping: true }]}
      //         ref={r => r && (this.orderSellImgManagerRef = r)}
      //         showUploaded={true}
      //         readonly={true}
      //         maxLength={1}
      //       />
      //     </View>
      //     <View style={{ justifyContent: 'center', alignItems: 'center', flex: p.op.isWeb() ? 1 : 0, width: 100 }}>
      //       {
      //         false ? <TouchableOpacity onPress={() => { }} style={{ width: 50, height: 50, justifyContent: 'center', alignItems: 'center', paddingTop: 12 }}>
      //           <XIcon name={'delete-forever'} family={'MaterialCommunityIcons'}
      //             style={{
      //               fontSize: STYLES.FONT_ICON_NORMAL,
      //               color: COLORS.APP_MAIN,
      //               textAlign: 'center',
      //             }} />
      //         </TouchableOpacity> : null
      //       }
      //     </View>
      //   </Box>
      // </View>
    )
  }

  _renderThisOrderNote = (txtPrefix) => {
    // const ImgManager = this.getImgManagerComponent()
    const { uploadingThisOrderNote, mode, notes, changeImgBtn, showMyImgUploaded } = this.state
    let note = _.find(notes, (thisNote) => thisNote.created_by === 'm' && thisNote.type === 1)
    if (_.isNil(note)) {
      if (mode === READ_ONLY_MODE) {
        return null
      }
      if (mode === READ_ONLY_ORDER_LOCK_MODE) {
        return null
      }
      note = { selected: false }
    } else {
      // if (mode === READ_ONLY_MODE) {
      //   return null
      // }
      // if (mode === READ_ONLY_ORDER_LOCK_MODE) {
      //   return null
      // }
    }
    if (mode === CREATE) {
      note = _.find(notes, (note) => note.type === 1)

      // @ts-ignore
      if (_.isNil(note) || note === []) {
        note = { selected: false }
      } else {
        note.selected = true
      }
    }
    const headerTxt = 'ฉันระบุในออเดอร์นี้'
    // console.log('this.thisImgManagerRef => ', this.thisImgManagerRef)
    // console.log(note)
    return (
      <HStack w='full' alignItems='center'>
        <HStack minW='50px' alignItems='center' justifyContent='center'>
          <TouchableOpacity
            onPress={() => {
              this._openGallery()
            }}
            // style={{ width: 50, height: 50, justifyContent: 'center', alignItems: 'center' }}
          >
            <Radio disabled={false} checked={!!note.selected} />
          </TouchableOpacity>
        </HStack>
        <View
          style={[
            S.FLEX,
            S.COLUMN_LEFT_TOP,
            S.CARDLIKE_MARGIN,
            S.CARDLIKE_BODY,
            S.BORDER,
            { borderColor: note.selected ? COLORS.APP_MAIN : BORDER_COLOR },
          ]}>
          <View style={[S.CARDLIKE_BODY, { paddingTop: 15 }]}>
            <XText variant='inactive'>{headerTxt}</XText>
          </View>
          <HStack w='full' alignItems='center'>
            <View style={[S.CARDLIKE_BODY, { width: 150, height: 100, marginLeft: 0, paddingLeft: 0, paddingTop: 0 }]}>
              <ImgManager
                fileNamePrefix={txtPrefix}
                metadata={[{ key: 'p', ...CONS.IMG_MANAGER_SIZES.COVER_SHEET, cropping: true }]}
                // ref={(r) => r && (this.thisImgManagerRef = r)}
                ref={this.thisImgManagerRef}
                uploading={uploadingThisOrderNote}
                showUploaded={showMyImgUploaded}
                openGalleryChoose
                maxLength={1}
                onPressChooseSuccess={() => {
                  this._saveImg(THIS_IMG)
                }}
                onPressChooseFailed={() => {
                  this.updateView()
                  this.inProcess = false
                }}
                // readonly={false}
              />
            </View>
            {/* <Box style={{ flex: 1, marginTop: 75 }}> */}
            {
              changeImgBtn ? (
                <TouchableOpacity
                  // w='90px'
                  // variant='outline'
                  style={{
                    width: 70,
                    padding: 2,
                    borderWidth: 1,
                    borderRadius: 7,
                    borderColor: COLORS.APP_MAIN,
                    backgroundColor: COLORS.BG_LIGHT_GREY,
                    // marginLeft: -20,
                    alignItems: 'center',
                    marginTop: 75,
                  }}
                  onPress={() => {
                    this._changeImgBtn()
                  }}>
                  <XText variant='primary'>เปลี่ยน</XText>
                </TouchableOpacity>
              ) : null

              // (
              //   <TouchableOpacity
              //     style={{
              //       width: 70,
              //       padding: 2,
              //       // borderWidth: 1,
              //       // borderRadius: 7,
              //       // borderColor: COLORS.APP_MAIN,
              //       // backgroundColor: COLORS.BG_LIGHT_GREY,
              //       marginLeft: 4,
              //       alignItems: 'center',
              //       marginTop: 73,
              //     }}
              //     onPress={() => {
              //       // this._changeImgBtn()
              //     }}>
              //     {/* <XText variant='primary'>เปลี่ยน</XText> */}
              //   </TouchableOpacity>
              // )
            }
            {/* </Box> */}
          </HStack>
        </View>
        {/* <View style={[S.ROW_CENTER, { width: 50 }]} /> */}
      </HStack>
      // <View>
      //   <Box style={{ marginTop: 10, paddingBottom: 0 }}>
      //     <XText variant='inactive'>
      //       {headerTxt}
      //     </XText>
      //   </Box>
      //   <Box style={{ flexDirection: 'row', flex: 1 }}>
      //     <View style={{ justifyContent: 'center', alignItems: 'center', flex: p.op.isWeb() ? 1 : 0, width: 100 }}>
      //       {
      //         showNotChoose ?
      //           <TouchableOpacity onPress={() => { this._selectNoteOption(null, 0) }} style={{ width: 50, height: 50, justifyContent: 'center', alignItems: 'center' }}>
      //             <Radio disabled={false} checked={note.selected ? true : false} />
      //           </TouchableOpacity> : null
      //       }
      //     </View>
      //     <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}>
      //       <ImgManager
      //         fileNamePrefix={txtPrefix}
      //         metadata={[{ key: 'p', width: 1200, height: 1900, cropping: true }]}
      //         ref={r => r && (this.thisImgManagerRef = r)}
      //         uploading={uploadingThisOrderNote}
      //         showUploaded={true}
      //         openGalleryChoose={true}
      //         maxLength={1}
      //         onPressChooseSuccess={() => { this._saveImg(THIS_IMG) }}
      //         onPressChooseFailed={() => { }}
      //         readonly={false}
      //       />
      //     </View>
      //     <View style={{ justifyContent: 'center', alignItems: 'center', flex: p.op.isWeb() ? 1 : 0, width: 100 }}>
      //       {
      //         changeImgBtn ? <TouchableOpacity
      //           style={{
      //             width: 70,
      //             padding: 2,
      //             borderWidth: 1,
      //             borderRadius: 7,
      //             borderColor: COLORS.APP_MAIN,
      //             backgroundColor: COLORS.BG_LIGHT_GREY,
      //             marginLeft: 4,
      //             alignItems: 'center',
      //           }}
      //           onPress={() => {
      //             this._changeImgBtn()
      //           }}>
      //           <XText variant='primary'>เปลี่ยน</XText>
      //         </TouchableOpacity> : null
      //       }
      //       {/* {
      //         uploadingThisOrderNote && mode === CREATE ? <TouchableOpacity onPress={() => { this._deleteImageNote(THIS_IMG) }} style={{ width: 50, height: 50, justifyContent: 'center', alignItems: 'center', paddingTop: 12 }}>
      //           <XIcon name={'delete-forever'} family={'MaterialCommunityIcons'}
      //             style={{
      //               fontSize: STYLES.FONT_ICON_NORMAL,
      //               color: COLORS.APP_MAIN,
      //               textAlign: 'center',
      //             }} />
      //         </TouchableOpacity> : null
      //       } */}
      //     </View>
      //   </Box>
      // </View>
    )
  }

  _renderNotChoose = () => {
    const focusedOrder = this._getFocusedOrder()
    const ignoreShpLblImg = focusedOrder.get('ignore_shp_lbl_img')
    const pendingShpLblImg = focusedOrder.get('pending_shp_lbl_img')
    const orderType = focusedOrder.get('type')
    let notChoose = false
    const { notes, mode } = this.state
    const checker = true
    if (orderType === 3) {
      // if (!_.isNil(ignoreShpLblImg) && ignoreShpLblImg) {
      //   notChoose = true
      //   checker = false
      // }
      if (checker) {
        if (!_.isNil(notes)) {
          notChoose = true
          notes.map((note) => {
            if (!_.isNil(note.selected)) {
              notChoose = false
            }
          })
        }
      }
    } else {
      if (!_.isNil(ignoreShpLblImg) && ignoreShpLblImg) {
        notChoose = true
      } else {
        notChoose = false
      }
      // console.log(notes)
      // if (!_.isNil(notes)) {
      //   notes.map(note => {
      //     // console.log(note.selected)
      //     if (!_.isNil(note.selected)) {
      //       notChoose = true
      //     }
      //   })
      // }
    }

    if (mode === CREATE) {
      notChoose = false
    }
    // console.log(notChoose)
    return (
      <HStack w='full' alignItems='center'>
        <HStack minW='50px' alignItems='center' justifyContent='center'>
          <TouchableOpacity
            onPress={() => {
              this._selectNoteOption(null, 3)
            }}
            // style={{ width: 50, height: 50, justifyContent: 'center', alignItems: 'center' }}
          >
            <Radio disabled={false} checked={!!notChoose} />
          </TouchableOpacity>
        </HStack>
        <View
          style={[
            S.FLEX,
            S.COLUMN_LEFT_TOP,
            S.BORDER,
            S.CARDLIKE_BODY,
            S.CARDLIKE_MARGIN,
            {
              // flex: 1,
              // flexDirection: 'column',
              // borderWidth: 1,
              borderColor: notChoose ? COLORS.APP_MAIN : BORDER_COLOR,
              // flexWrap: 'wrap',
            },
          ]}>
          <VStack>
            <XText>ไม่ใช้</XText>
            <XText variant='inactive'>(หากเลือกตัวเลือกนี้ รูปใบปะหน้าที่ฉันระบุสำหรับออเดอร์นี้ ก็จะถูกลบโดยอัตโนมัติ)</XText>
          </VStack>
        </View>
        {/* <View style={[S.ROW_CENTER, { width: 50 }]} /> */}
      </HStack>

      // <View>
      //   <Box style={{ marginTop: 10, paddingBottom: 0 }}>
      //   </Box>
      //   <Box style={{ flexDirection: 'row', flex: 1 }}>
      //     <View style={{ justifyContent: 'center', alignItems: 'center', flex: p.op.isWeb() ? 1 : 0, width: 100 }}>
      //       {
      //         true ? <TouchableOpacity onPress={() => { this._selectNoteOption(null, 3) }} style={{ width: 50, height: 50, justifyContent: 'center', alignItems: 'center' }}>
      //           <Radio disabled={false} checked={notChoose ? true : false} />
      //         </TouchableOpacity> : null
      //       }
      //     </View>
      //     <View style={{ justifyContent: 'center', alignItems: 'flex-start', flex: 1 }}>
      //       <XText >
      //         ไม่ใช้ (หากเลือกตัวเลือกนี้ รูปแทนใบปะหน้าที่คุณระบุในออเดอร์นี้จะหายไปด้วย)
      //       </XText>
      //     </View>
      //   </Box>
      // </View>
    )
  }

  // FIXME: O => Artid นี่เป็นการเดา magic number ในฝั่งเว็บไม่ให้ layout พัง ถ้ามีโอกาสให้กลับมาแก้ด้วยครับ
  getFixedWebLayoutTextLimitWidth = () => undefined

  titleNote = () => {
    const { mode } = this.state
    const focusedOrder = this._getFocusedOrder()
    const pendingShpLblImg = focusedOrder.get('pending_shp_lbl_img')
    const textReadOnlyMode =
      'อยู่โหมดป้องกันการแก้ไข เนื่องจากออเดอร์นี้ไม่มีสินค้าที่คุณต้องจัดส่งหากต้องการแก้ไข กรุณาแก้ที่ออเดอร์ซื้อ ที่เกี่ยวข้อง'
    const textReadOnlyModeLockMode = 'อยู่โหมดป้องกันการแก้ไข เนื่องจากออเดอร์นี้ถูกล็อกอยู่หากต้องการแก้ไข กรุณาแจ้งให้ผู้ขายปลดล็อกก่อน'
    const defaultText = 'รูปที่เลือกไว้ จะถูกพิมพ์ออกมาบนใบปะหน้าพัสดุ แทนที่ชื่อและที่อยู่ของ ผู้รับและผู้ส่ง'
    // let text = defaultText
    // console.log(mode)
    let modeText
    if (mode === READ_ONLY_MODE) {
      modeText = textReadOnlyMode
    } else if (mode === READ_ONLY_ORDER_LOCK_MODE) {
      modeText = textReadOnlyModeLockMode
    }
    return (
      <View>
        {/* <View
          style={{
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: COLORS.FORM_SUCCESS_BG,
          }}>
          <View style={{ width: 40, alignItems: 'center' }}>
            <Icon name='lock' style={{ color: COLORS.FORM_SUCCESS }} />
          </View>
          <View style={{ flex: 1 }}>
            <XText variant='inactive' fontSize='xs' textAlign='center'>{textReadOnlyMode}</XText>
          </View>
        </View> */}
        {pendingShpLblImg ? (
          <HStack
            style={{
              // flex: 1,
              // flexDirection: 'row',
              // justifyContent: 'center',
              // alignItems: 'center',
              backgroundColor: COLORS.FORM_SUCCESS_BG,
              // marginTop: 4,
              // marginBottom: 4,
              // paddingTop: 2,
              // paddingBottom: 2,
            }}>
            {/* <View style={{ width: 40, alignItems: 'center' }}>
                <Icon name='lock' style={{ color: COLORS.FORM_SUCCESS }} />
              </View> */}
            <View style={{ flex: 1 }}>
              <XText variant='inactive' fontSize='xs' textAlign='center'>
                มีรูปแทนใบปะหน้าที่รอผู้ซื้อยืนยัน
              </XText>
            </View>
          </HStack>
        ) : null}
        {!_.isNil(modeText) ? (
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: COLORS.FORM_SUCCESS_BG,
              marginTop: 2,
              marginBottom: 4,
            }}>
            <View style={{ width: 40, alignItems: 'center' }}>
              <XIcon success family='MaterialIcons' name='lock' />
            </View>
            <View style={{ flex: 1 }}>
              <XText variant='inactive' fontSize='xs' textAlign='center'>
                {modeText}
              </XText>
            </View>
          </View>
        ) : null}
        <View style={S.CARDLIKE_BODY}>
          <XText variant='inactive'>{defaultText}</XText>
        </View>
      </View>
    )
  }

  renderHeader = () => {
    const inProcess = util.getNavParam(this.props, 'inProcess', false)
    const navBack = () => util.navGoBack(this.props)
    return (
      <XCustomHeader title='รูปแทนใบปะหน้าจากระบบอื่น' headerLeftProps={{ backIcon: true, onPressItem: navBack, submitting: inProcess }} />
    )
  }

  renderContent = () => {
    const config = getConfig()
    const txtPrefix =
      config.s3_prefix_type_shipping_label + xUtil._getPrefixForImageSlip() + xUtil.getHHMMSSInString() + xUtil.generateRandomString(4)
    // console.log(txtPrefix)
    const { mode, uploadingThisOrderNote, showSelectOrderSellNote, showOrderReceiverNote, showThisOrderNote, showNotChoose } = this.state
    // const focusedOrder = this._getFocusedOrder()
    return (
      <VStack w='full' px='2' py='1'>
        <XCard w='full'>
          <VStack w='full'>{this.titleNote()}</VStack>
          <XCard.Body>
            <VStack w='full'>
              {showOrderReceiverNote ? this._renderOrderReceiverNote(txtPrefix) : null}
              {showSelectOrderSellNote ? this._renderSelectOrderSellNote(txtPrefix) : null}
              {showThisOrderNote ? this._renderThisOrderNote(txtPrefix) : null}
              {showNotChoose ? this._renderNotChoose() : null}
            </VStack>
          </XCard.Body>
        </XCard>
      </VStack>
    )
  }

  renderMain = () => (
    <XContainer>
      {this.renderHeader()}
      <XContent>{this.renderContent()}</XContent>
    </XContainer>
  )

  render() {
    return this.renderMain()
  }
}

// const btnStyle = {
//   ...btnOnMode(xCONS.BTN_MODE.ACTIVE_SECONDARY),
//   borderColor: COLORS.BRAND_Danger,
//   borderRadius: 4,
// }

// const txtStyle = {
//   ...textOnBtnMode(xCONS.BTN_MODE.ACTIVE_SECONDARY),
//   color: COLORS.BRAND_Danger,
// }
