import { IApiOptions, IProductDetailItem } from 'x/types'
// import CONS from 'x/config/constants'

import api from './api'

type IPostStoreUserRequestBody = {
  store_id: number

  [keyInStore: string]: any
}

type IPostStoreUserResponse = {
  status: 'ok'
}

export async function postStoreUser(params: IPostStoreUserRequestBody): Promise<boolean> {
  const { store_id } = params

  if (!store_id) {
    throw new Error('postStoreUser:: Need store_id')
  }

  const apiOptions: IApiOptions = {
    // messages: {
    //   successMsg: 'แก้ไขการตั้งค่าร้านค้าสำเร็จ',
    //   errorMsg: 'ล้มเหลวในการแก้ไขการตั้งค่า',
    // },
    showSpinner: true,
    isApiV2: true,
    axiosOptions: {
      timeout: 18000,
    },
  }

  try {
    console.log('In postStoreUser:: reqBody => ', params)
    const res = await api.post<IPostStoreUserRequestBody, IPostStoreUserResponse>(api.POST_STORE_USER, params, apiOptions)
    console.log('In postStoreUser:: response => ', res)

    if (res.status === 'ok') {
      return true
    }
  } catch (err) {
    console.log('In fetchPatchStore:: err => ', err)
    throw err
  }

  return false
}

type IPatchStoreRequestBody = {
  store_id: number

  [flagKey: string]: any
}

export async function fetchPatchStore(params: IPatchStoreRequestBody): Promise<IProductDetailItem> {
  const { store_id } = params

  if (!store_id) {
    throw new Error('fetchPatchStore:: Need store_id')
  }

  const apiOptions: IApiOptions = {
    messages: {
      successMsg: 'แก้ไขการตั้งค่าร้านค้าสำเร็จ',
      errorMsg: 'ล้มเหลวในการแก้ไขการตั้งค่า',
    },
    showSpinner: true,
  }

  try {
    console.log('In fetchPatchStore:: reqBody => ', params)
    const res = await api.patch(api.PATCH_MY_STORE, params, apiOptions)
    console.log('In fetchPatchStore:: response => ', res)

    // if (!res.product) {
    //   throw new Error('fetchPatchStore:: No "product" in response')
    // }

    return res
  } catch (err) {
    console.log('In fetchPatchStore:: err => ', err)
    throw err
  }
}
