// import PropTypes from 'prop-types'
import BaseUIUserGroup from 'xui/modules/store/BaseUIUserGroup'
// import QRCodeModal from '../../components/QRCodeModal'

export default class UserGroupView extends BaseUIUserGroup {
  static displayName = 'UserGroupView'

  // static navigationOptions = ({ navigation }) => ({
  //   header: null,
  // })

  renderQRCodeModal = () => null
}
