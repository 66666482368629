import React, { useState, useEffect } from 'react'
import { View, Text } from 'react-native'

import { ISelectedStoreMap, IProfileMap } from 'x/index'
import { useNavigation } from 'x/contexts/useNavigation'
import CONS from 'x/config/constants'
import { STYLES, COLORS } from 'x/config/styles'
import p from 'x/config/platform-specific'

import { MODE } from 'x/config/mode'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import DevSubscriptionPackageListView from './DevSubscriptionPackageListView'
import XCustomHeader from '../../components/XCustomHeader'

const PageLayout: React.FC<{
  onSave: () => void
  doGoBack: () => void
  isLoading?: boolean
  isDirty?: boolean
  fixedContainerWidth?: number
}> = ({ children, onSave, doGoBack, isLoading = false, isDirty = false, fixedContainerWidth }) => (
  // const handleWarningDirty = async () => {
  //   const isUserConfirm = await p.op.isUserConfirm(
  //     'ละทิ้งการแก้ไข',
  //     'กรุณายืนยันว่าฉันต้องการละทิ้งการแก้ไขทั้งหมด',
  //     'ละทิ้ง',
  //     'กลับไปแก้ไข'
  //   )
  //   if (isUserConfirm) {
  //     doGoBack()
  //   }
  // }
  <XContainer style={fixedContainerWidth ? { width: fixedContainerWidth } : {}}>
    <XCustomHeader
      // headerStyle={{ marginTop: 0 }}
      headerLeftProps={{
        backIcon: true,
        onPressItem: doGoBack,
        submitting: isLoading,
      }}
      title='Dev: Subscription'
    />
    <XContent style={fixedContainerWidth ? { width: fixedContainerWidth } : {}}>{children}</XContent>
  </XContainer>
)

// const getGoBackFunction = navigation => () => navigation.goBack(navigation.state.key)
const getGoBackFunction = (navigation) => () => navigation.goBack()

const getPackageList = async () => {
  console.log('Just in getPackageList...')

  const authToken = await p.op.storageGet(CONS.AUTHENTICATION_STORAGE_KEY, false)
  const url = 'https://superdevgo.xselly.com/api/subscription/package/list'
  const data = {
    lang: 'th',
    purchase_channel_id: 1,
  }
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  })
  console.log('getPackageList.response', response.json)
  return response.json() // parses JSON response into native JavaScript objects
}

interface IDevMenuSubscriptionView {
  // navigation: NavigationStackProp<NavigationRoute, ITimeSlotsProps>
  // webMaxScreenWidth?: number // web only
  // webNavigation?: NavigationStackProp<NavigationRoute, null> // web only
  // setPrefTimeslot: (params: ActionApiParams) => void
  selectedStore: ISelectedStoreMap
  profile: IProfileMap
  subscription: any
}

const ListItem = (icon: JSX.Element, text: string, index: number) => (
  <View style={{ flexDirection: 'row', marginTop: 10 }} key={index}>
    <View style={{ width: 25, height: 30 }}>{icon}</View>
    <Text
      style={{
        fontSize: STYLES.FONT_SIZE_LARGER,
        color: COLORS.TEXT_ACTIVE,
        paddingLeft: 10,
        fontWeight: 'bold',
        alignSelf: 'center',
      }}>
      {text}
    </Text>
  </View>
)

const DevMenuSubscriptionView: React.FC<IDevMenuSubscriptionView> = ({
  // webMaxScreenWidth,
  // webNavigation,
  profile,
  selectedStore,
  subscription,
}) => {
  const userId = subscription.get('user_id')
  const nativeNavigation = useNavigation()
  // const navBack = p.op.isWeb() ? getGoBackFunction(webNavigation) : getGoBackFunction(nativeNavigation)
  const navBack = getGoBackFunction(nativeNavigation)

  const [packageList, setPackageList] = useState({})
  const [selectedPackages, setSelectedPackages] = useState([])
  // let isPackageListLoaded = false
  useEffect(() => {
    // Update the document title using the browser API
    console.log('DevMenuSubscriptionView.useEffect.packageList ', packageList)
    // https://bobbyhadz.com/blog/javascript-check-if-object-is-empty
    // TODO: Find the better way to load the package list only once i.e. handle error
    if (packageList === undefined || Object.keys(packageList).length === 0) {
      // isPackageListLoaded = true
      getPackageList().then((packageList) => {
        console.log('packageList', packageList)
        prepareJsonForUI(packageList)
        setPackageList(packageList)
      })
    }
  })

  const renderMain = () => (
    <View
      style={{
        flex: 1,
        width: '100%',
        // height: 528,
        // paddingTop: 10,
        paddingLeft: 4,
        // paddingRight: 4,
        // paddingBottom: 10,
        backgroundColor: COLORS.APP_SECONDARY,
        borderRadius: 15,
        marginTop: 4,
        marginBottom: 20,
        alignSelf: 'center',
        opacity: 50,
      }}>
      {renderSubscriptionInfo(subscription)}
    </View>
  )

  // const renderPackageList = () => {
  //   console.log('Just in renderPackageList', packageList)
  //   if (packageList !== undefined && packageList.items !== undefined) {
  //     return (
  //       <View>
  //         {packageList.items.map((item) =>
  //           // return <Text>{item.sku}</Text>
  //           renderPackageItem(item)
  //         )}
  //       </View>
  //     )
  //   }
  //   return <Text>items is null</Text>
  // }

  // const renderPackageItem = (pkgItem) => (
  //   <TouchableOpacity
  //     onPress={(event) => {
  //       console.log('selected: ', pkgItem.sku)
  //       // add to the selected packages
  //       selectedPackages.push(pkgItem.sku)
  //       console.log('selectedPackages: ', selectedPackages)
  //       setSelectedPackages(selectedPackages)
  //     }}
  //     style={{}}>
  //     <Text key={pkgItem.sku} style={selectedPackages.includes(pkgItem.sku) ? s.selectedPackageName : s.notSelectedPackageName}>
  //       {pkgItem.sku} - {pkgItem.name}
  //       {/* {selectedPackages.length > 0 ? selectedPackages[0] : '::'} */}
  //     </Text>
  //   </TouchableOpacity>
  // )

  // Safety view. To make sure that mode prod will not be able to see this view.
  if (MODE !== 'dev' && MODE !== 'demo') {
    return (
      <View>
        <Text>กรุณาติดต่อทีมงานที่ LINE: @XSelly</Text>
      </View>
    )
  }

  return (
    <PageLayout
      // webMaxScreenWidth / fixedContainerWidth ใช้เพื่อระบุ width สำหรับให้ View react-native-web สามารถรู้ขอบเขตของ flex
      // fixedContainerWidth={p.op.isWeb() && _.isNumber(webMaxScreenWidth) ? webMaxScreenWidth : null}
      isLoading={false}
      onSave={null}
      doGoBack={navBack}
      isDirty={false}>
      <DevSubscriptionPackageListView packageList={packageList} onSelectPackage={handleOnSelectPackage} />

      {renderMain()}
      {/* {renderPackageList()} */}
    </PageLayout>
  )
}

const handleOnSelectPackage = (selectedPackages) => {
  console.log('handleOnSelectPackage', selectedPackages)
}

const prepareJsonForUI = (packageListJson: any) => {
  console.log('Just in prepareJsonForUI...')
  const uiJson = {}

  console.log('uiJson... ', uiJson)
}

const renderOneTextField = (immuObj, fieldName) => {
  const data = `${fieldName}: ${immuObj.get(fieldName)}`
  return (
    <Text style={{ fontSize: 18 }} key={fieldName}>
      {data}
    </Text>
  )
}

const renderSubscriptionInfo = (subs) => {
  const keys = [
    'm_e_date',
    'm_s_date',
    'user_id',
    'type',
    'startBillingDate',
    'endBillingDate',
    'order_count_today',
    'order_count_quota',
    'm_o_count',
    'store_count',
    'helper_count',
    'pg_quota',
    'ug_quota',
    'vd_quota',
    'mkp_quota',
  ]

  const today = new Date()
  const dd = String(today.getDate()).padStart(2, '0')
  const mm = String(today.getMonth() + 1).padStart(2, '0') // January is 0!
  const yyyy = today.getFullYear()
  const formattedToday = `${yyyy}-${mm}-${dd}`

  return (
    <View>
      <Text style={s.textField}>TODAY: {formattedToday}</Text>
      {renderTextEndSubscriptionDate(subs)}
      {renderTextEndBillingDate(subs)}
      {keys.map((key) => renderOneTextField(subs, key))}
    </View>
  )
}

const renderTextEndSubscriptionDate = (subs) => {
  const m_e_date = subs.get('m_e_date')
  const subEndDate = new Date(Date.parse(m_e_date))
  // const subEndDate =  new Date(Date.parse('2022-03-19'))
  // To calculate the time difference of two dates
  const diffInSec = subEndDate.getTime() - new Date().getTime()
  // To calculate the no. of days between two dates
  const diffInDay = Math.ceil(diffInSec / (1000 * 3600 * 24))
  // let diffText
  if (diffInDay > 0) {
    return <Text style={{ fontSize: 18, color: 'green' }}>แพ็กเหลืออีก {diffInDay} วัน</Text>
  }
  if (diffInDay === 0) {
    return <Text style={{ fontSize: 18, color: 'orange' }}>แพ็กหมดวันนี้!</Text>
  }
  return <Text style={{ fontSize: 18, color: 'red' }}>แพ็กหมดไปแล้ว {diffInDay} วันที่ผ่านมา</Text>
}

const renderTextEndBillingDate = (subs) => {
  const end_billing_date = subs.get('endBillingDate')
  const endBillingDate = new Date(Date.parse(end_billing_date))
  // To calculate the time difference of two dates
  const diffInSec = endBillingDate.getTime() - new Date().getTime()
  // To calculate the no. of days between two dates
  const diffInDay = Math.ceil(diffInSec / (1000 * 3600 * 24))

  if (diffInDay > 0) {
    return <Text style={{ fontSize: 18, color: 'green' }}>รอบบิลหมดในอีก {diffInDay} วัน</Text>
  }
  if (diffInDay === 0) {
    return <Text style={{ fontSize: 18, color: 'orange' }}>รอบบิลหมดวันนี้!</Text>
  }
  return <Text style={{ fontSize: 18, color: 'red' }}>ไม่ควรเป็นไปได้ รอบบิลหมดไปแล้ว {diffInDay} วัน</Text>
}

// // @ts-ignore disable react-navigation header
// DevMenuSubscriptionView.navigationOptions = {
//   header: null,
// }

const s = {
  textField: { fontSize: 18 },
  packageName: {
    paddingTop: 4,
    paddingLeft: 4,
  },
  selectedPackageName: {
    color: 'orange',
  },
  notSelectedPackageName: {
    color: 'grey',
  },
}
export default DevMenuSubscriptionView
