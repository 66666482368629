import React from 'react'
import { View, Text, TouchableOpacity, LayoutChangeEvent, ViewStyle, StyleSheet, Dimensions } from 'react-native'
import _ from 'lodash'

import CONS from 'x/config/constants'
import p from 'x/config/platform-specific'
import { S, COLORS } from 'x/config/styles'
import {
  IBarcodeScannerOutput,
  IXSegmentOption,
  IBarcodeScannerWithSegmentProps,
  ICustomBarcodeRenderOption,
  ICustomBarcodeRenderProps,
} from 'x/index'
import * as util from 'x/utils/util'

import XInput from './XInput'
import XSegment from './XSegment'
import BarcodeScanner from './BarcodeScanner'
import XText from './XText'

const MAX_SCREEN_WIDTH = p.op.isWeb() ? Math.floor(CONS.SPECIFIC_PLATFORM.WEB.MAIN_CONTENT_WIDTH / 3) : Dimensions.get('screen').width
const DEFAULT_CAMERA_WIDTH = Math.floor(0.9 * MAX_SCREEN_WIDTH)

const DEFAULT_SEGMENT_OPTIONS: ICustomBarcodeRenderOption[] = p.op.isWeb()
  ? [
      {
        key: 'camera',
        label: 'สแกน/พิมพ์',
        customRender: (cProps: ICustomBarcodeRenderProps) => <DefaultBarcodeScanner {...cProps} />,
      },
    ]
  : [
      {
        key: 'camera',
        label: 'สแกน',
        customRender: (cProps: ICustomBarcodeRenderProps) => <DefaultBarcodeScanner {...cProps} />,
      },
      {
        key: 'text',
        label: 'พิมพ์',
        customRender: (cProps: ICustomBarcodeRenderProps) => <BarcodeTextInput {...cProps} />,
      },
    ]

const BarcodeScannerWithSegment: React.FC<IBarcodeScannerWithSegmentProps> = (props) => {
  const [options, setOptions] = React.useState<ICustomBarcodeRenderOption[]>(DEFAULT_SEGMENT_OPTIONS)
  const [isInitialized, setIsInitialized] = React.useState<boolean>(false)
  const [cameraContainerStyle, setCameraContainerStyle] = React.useState<ViewStyle>({ minHeight: 120, height: 200 })

  // FIXME: แก้ปัญหา iOS ตอน init index 0 แล้วกล้องไม่ขึ้น
  const [selectedSegmentIndex, setSelectedSegmentIndex] = React.useState<number>(
    p.op.isIOS() && props.initialIndex === 0 && !props.selectedIndex ? 1 : 0
  )
  // const [selectedSegmentIndex, setSelectedSegmentIndex] = React.useState<number>(0)
  const [isBarcodeRead, setIsBarcodeRead] = React.useState<boolean>(false)

  const [isSegmentTransitioning, setIsSegmentTransitioning] = React.useState<boolean>(false)
  // console.log('BarcodeScannerWithSegment  isInitialized => ', isInitialized)
  // console.log('BarcodeScannerWithSegment  props.initialIndex => ', props.initialIndex)
  // React.useEffect(() => {
  //   if (isInitialized && _.isNumber(props.initialIndex) && !props.selectedIndex) {
  //     if (p.op.isIOS() && props.initialIndex === 0) {
  //       // FIXME: แก้ปัญหา iOS ตอน init index 0 แล้วกล้องไม่ขึ้น
  //       setTimeout(() => {
  //         setSelectedSegmentIndex(props.initialIndex)
  //       }, 300)
  //     } else {
  //       setSelectedSegmentIndex(props.initialIndex)
  //     }
  //   }
  // }, [isInitialized])

  // React.useEffect(() => {
  //   if (_.isNumber(props.selectedIndex) && selectedSegmentIndex !== props.selectedIndex) {
  //     setSelectedSegmentIndex(props.selectedIndex)
  //   }
  // }, [props.selectedIndex])

  React.useEffect(() => {
    if (_.isArray(props.extraSegmentOptions) && _.isNil(props.overrideSegmentOptions)) {
      const newOptions = [...DEFAULT_SEGMENT_OPTIONS]
      for (const newOpt of props.extraSegmentOptions) {
        const duplicatedKeyIdx = options.findIndex((opt) => opt.key === newOpt.key)
        if (duplicatedKeyIdx > -1) {
          newOptions[duplicatedKeyIdx] = newOpt
        } else {
          newOptions.push(newOpt)
        }
      }
      setOptions(newOptions)
    }
  }, [props.extraSegmentOptions])

  React.useEffect(() => {
    if (_.isArray(props.overrideSegmentOptions)) {
      const newOptions = [...props.overrideSegmentOptions]
      for (const newOpt of props.overrideSegmentOptions) {
        const defaultKeyIdx = DEFAULT_SEGMENT_OPTIONS.findIndex((opt) => opt.key === newOpt.key)
        if (defaultKeyIdx > -1) {
          const defaultKey = newOptions[defaultKeyIdx].key
          const defaultOption = DEFAULT_SEGMENT_OPTIONS.find((opt) => opt.key === defaultKey)
          newOptions[defaultKeyIdx] = defaultOption
        } else {
          newOptions.push(newOpt)
        }
      }
      setOptions(newOptions)
    }
  }, [props.overrideSegmentOptions])

  async function _onBarcodeScanned(barcodeOutput: IBarcodeScannerOutput) {
    setIsBarcodeRead(true)
    await util.delay(100)
    await props.onBarcodeScanned(barcodeOutput)
    // if (util.isAsyncFunction(props.onBarcodeScanned)) {
    //   await props.onBarcodeScanned(barcodeOutput)
    // } else {
    //   props.onBarcodeScanned(barcodeOutput)
    // }
    await util.delay(200)
    setIsBarcodeRead(false)
  }

  function _getSegmentOptions(): IXSegmentOption[] {
    return options.map((opt) => ({
      label: opt.label,
      value: opt.key,
    }))
  }

  async function _onSegmentChange(opt, idx) {
    if (isSegmentTransitioning) {
      return
    }
    setIsSegmentTransitioning(true)
    if (_.isFunction(props.onChangeSegment)) {
      await props.onChangeSegment(idx)
      // if (util.isAsyncFunction(props.onChangeSegment)) {
      //   await props.onChangeSegment(idx)
      // } else {
      //   props.onChangeSegment(idx)
      // }

      setIsSegmentTransitioning(false)
      return
    }
    setSelectedSegmentIndex(idx)
    await util.delay(500)
    setIsSegmentTransitioning(false)
  }

  const _renderCustomBarcodeScanner = () => {
    const opt = options[selectedSegmentIndex]
    if (_.isNil(opt)) {
      return null // error
    }

    const extraContainerStyle: ViewStyle = {}
    if (props.cameraContainerWidth) {
      extraContainerStyle.width = props.cameraContainerWidth
    }
    if (props.cameraContainerHeight) {
      extraContainerStyle.height = props.cameraContainerHeight
    }

    const containerStyle: ViewStyle = StyleSheet.flatten([cameraContainerStyle, extraContainerStyle])
    const disabled = isBarcodeRead || props.disabled
    if (opt.key === 'camera') {
      // wrapped container แก้ไขกล้องล้น
      return (
        <View style={[S.COLUMN_CENTER, containerStyle, { overflow: 'hidden' }]}>
          {opt.customRender({
            ...props,
            disabled,
            barcodeReadHandler: _onBarcodeScanned,
            containerStyle,
          })}
        </View>
      )
    }

    if (opt.customRender) {
      return opt.customRender({
        ...props,
        disabled,
        barcodeReadHandler: _onBarcodeScanned,
        containerStyle,
      })
    }

    return null
  }

  async function _onLayout(evt: LayoutChangeEvent) {
    // console.log('BarcodeScannerWithSegment _onLayout evt => ', evt)
    const { width } = evt.nativeEvent.layout
    setCameraContainerStyle({ ...cameraContainerStyle, width })
    await util.delay(200)
    setIsInitialized(true)
  }

  return (
    <View style={[S.COLUMN_CENTER_TOP]} onLayout={_onLayout}>
      <SegmentPropsManager
        isInitialized={isInitialized}
        initialIndex={props.initialIndex}
        selectedIndex={props.selectedIndex}
        stateSegmentIndex={selectedSegmentIndex}
        setSelectedSegmentIndex={setSelectedSegmentIndex}
      />
      {isInitialized && options.length > 1 ? (
        <View
          style={[
            // S.HEIGHT_28,
            {
              width: cameraContainerStyle.width,
            },
            props.cameraContainerWidth ? { width: props.cameraContainerWidth } : {},
          ]}>
          <XSegment
            segmentTextStyle={[S.TEXT_LARGER, props.segmentTextStyle]}
            options={_getSegmentOptions()}
            selectedIndex={selectedSegmentIndex}
            onSegmentChange={_onSegmentChange}
            segmentContainerStyle={props.segmentContainerStyle}
          />
        </View>
      ) : null}

      {/* {isInitialized ? <View style={[cameraContainerStyle]}>{_renderCustomBarcodeScanner()}</View> : null} */}
      {p.op.isWeb() ? <View style={{ height: 1 }} /> : null}
      {isInitialized ? _renderCustomBarcodeScanner() : null}
    </View>
  )
}

// create this seperate to avoid useEffect memory leaked
const SegmentPropsManager = ({ isInitialized, initialIndex, selectedIndex, stateSegmentIndex, setSelectedSegmentIndex }) => {
  React.useEffect(() => {
    let isActive = true
    if (isInitialized && _.isNumber(initialIndex) && !selectedIndex) {
      if (p.op.isIOS()) {
        // FIXME: แก้ปัญหา iOS ตอน init index 0 แล้วกล้องไม่ขึ้น
        setTimeout(() => {
          // console.log('do tricky here!!!')
          if (isActive) {
            setSelectedSegmentIndex(initialIndex)
          }
        }, 300)
      } else {
        setSelectedSegmentIndex(initialIndex)
      }
    } else if (isInitialized && selectedIndex) {
      setSelectedSegmentIndex(selectedIndex)
    }
    return () => {
      isActive = false
    }
  }, [isInitialized])

  React.useEffect(() => {
    if (isInitialized && stateSegmentIndex !== selectedIndex) {
      setSelectedSegmentIndex(selectedIndex)
    }
  }, [selectedIndex])

  return null
}

const DefaultBarcodeScanner = (props: ICustomBarcodeRenderProps) => (
  <BarcodeScanner {...props} onBarcodeScanned={props.barcodeReadHandler} />
)

const BarcodeTextInput = (props: ICustomBarcodeRenderProps) => {
  const [barcodeText, setBarcodeText] = React.useState('')
  const [isBarcodeRead, setIsBarcodeRead] = React.useState(false)

  const isBarcodeScannerDisabled = isBarcodeRead || props.disabled

  function _onChangeText(newText) {
    setBarcodeText(newText)
  }

  async function _onSubmitBarcode() {
    setIsBarcodeRead(true)
    await util.delay(100)
    await props.barcodeReadHandler({ data: barcodeText, dataRaw: barcodeText })
    await util.delay(500)
    setBarcodeText('')
    setIsBarcodeRead(false)
  }

  const maxWidth = props.containerStyle.width as number

  return (
    <View style={[S.COLUMN_CENTER_TOP, S.BG_LIGHT_GREY, props.containerStyle]}>
      {/* <View style={[S.COLUMN_CENTER_TOP, S.MARGIN_TOP_12]}>
        <Text style={[S.TEXT_INACTIVE, S.TEXT_LARGER]}>{'ระบุหมายเลขบาร์โค้ด'}</Text>
      </View> */}
      {props.inputLabel && props.inputLabel !== '' ? (
        <View style={[S.COLUMN_CENTER_TOP, S.WIDTH_FULL, S.MARGIN_TOP_6]}>
          <Text style={[S.TEXT_INACTIVE, S.TEXT_LARGER]}>{props.inputLabel}</Text>
        </View>
      ) : null}
      <View style={[S.COLUMN_CENTER_TOP, S.HEIGHT_34, S.MARGIN_TOP_6]}>
        <XInput
          isDisabled={isBarcodeScannerDisabled}
          placeholder={props.inputPlaceholder && props.inputPlaceholder !== '' ? props.inputPlaceholder : 'พิมพ์เลขบาร์โค้ด...'}
          // ref={this._barcodeInputRef}
          autoFocus
          textAlign='center'
          // onBlur={this._remainFocus}
          style={{ width: Math.floor(maxWidth * 0.8) }}
          value={barcodeText}
          onChangeText={_onChangeText}
          onSubmitEditing={_onSubmitBarcode}
        />
      </View>
      <View style={[S.ROW_CENTER, S.MARGIN_TOP_6, S.MARGIN_BOTTOM_12]}>
        <TouchableOpacity
          onPress={_onSubmitBarcode}
          disabled={isBarcodeScannerDisabled}
          style={{
            backgroundColor: isBarcodeRead ? COLORS.TEXT_INACTIVE : COLORS.APP_MAIN,
            borderRadius: 7,
            height: 28,
            width: 80,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <XText bold style={[isBarcodeRead ? S.TEXT_ACTIVE : S.TEXT_ACTIVE_DARK, S.TEXT_LARGER]}>
            ยืนยัน
          </XText>
        </TouchableOpacity>
      </View>
    </View>
  )
}

BarcodeScannerWithSegment.defaultProps = {
  initialIndex: 0,
  disabled: false,
  cameraContainerHeight: 200,
  // cameraContainerWidth: 300,
  cameraContainerWidth: DEFAULT_CAMERA_WIDTH,
  segmentContainerStyle: {},
  segmentTextStyle: {},
}

export default BarcodeScannerWithSegment
