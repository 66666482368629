import React from 'react'
import { TouchableOpacity } from 'react-native'
import _ from 'lodash'

import { IXAddressCard } from 'x/index'
import * as util from 'x/utils/util'

import XIcon from './XIcon'
import XCard from './XCard'
import XText from './XText'
import VStack from './VStack'
import HStack from './HStack'
import ForwardIcon from './ForwardIcon'

export default class XAddressCard extends React.Component<IXAddressCard> {
  canPressOnCard = () => {
    const { onPressCard, disabled } = this.props
    return !_.isNil(onPressCard) && !disabled
  }

  renderAddressCard = () => {
    const { address, isEditable, isDeletable, onPressDelete, onPressEdit } = this.props
    const canEdit = isEditable && address.id
    const canDelete = isDeletable && address.id

    const addrText = util.convertAddressToInlineAddress(address)
    return (
      <VStack w='full' p='2'>
        <HStack w='full' space='1.5'>
          <VStack w='40px' pt='2.5' alignItems='center'>
            <XIcon size='sm' variant='inactive' name='address-book-o' family='FontAwesome' />
            {/* {address.id ? <XIcon size='sm' variant='inactive' name='address-book-o' family='FontAwesome' /> : null} */}
          </VStack>
          <VStack flex={1} pt='1' minH='34px'>
            {address.is_primary ? <XText variant='primary'>ใช้เป็นที่อยู่หลัก</XText> : null}
            <XText variant='active'>{addrText}</XText>
            {/* <XText variant='active'>{address.name}</XText> */}
            {/* <XText variant='active'>{`${address.address1} ${address.address2 || ''}`}</XText> */}
            {/* {address.sub_district && address.district && address.province ? (
              <XText variant='active'>{`${address.sub_district} ${address.district} ${address.province}`}</XText>
            ) : null} */}
            {/* {address.postal_code ? <XText variant='active'>{`${address.postal_code}`}</XText> : null} */}
          </VStack>
          <HStack w='100px' justifyContent='flex-end' space='1'>
            <TouchableOpacity
              disabled={!canEdit}
              style={{ width: 38, height: 38, justifyContent: 'center', alignItems: 'center', opacity: canEdit ? 1 : 0 }}
              onPress={() => {
                if (_.isFunction(onPressEdit)) {
                  onPressEdit(address)
                }
              }}>
              <XIcon size='sm' name='edit' family='FontAwesome5' />
            </TouchableOpacity>

            <TouchableOpacity
              disabled={!canDelete}
              style={{ width: 38, height: 38, justifyContent: 'center', alignItems: 'center', opacity: canDelete ? 1 : 0 }}
              onPress={() => {
                if (_.isFunction(onPressDelete)) {
                  onPressDelete(address)
                }
              }}>
              <XIcon size='sm' danger name='delete' family='AntDesign' />
            </TouchableOpacity>
          </HStack>
        </HStack>
        {/* {address.telephone ? (
          <HStack w='full' pt='1.5' space='1.5' alignItems='center'>
            <VStack w='40px' alignItems='center'>
              <XIcon size='sm' variant='inactive' name='phone' family='FontAwesome' />
            </VStack>
            <HStack flex={1}>
              <XText variant='active'>{address.telephone}</XText>
            </HStack>
            <HStack w='100px' />
          </HStack>
        ) : null} */}
      </VStack>
    )
  }

  renderEmptyCard = () => {
    const { disabled, onPressCard, isHideWhenEmpty, emptyLabel = 'ระบุที่อยู่' } = this.props

    if (isHideWhenEmpty) {
      return null
    }

    return (
      <HStack w='full' alignItems='center' p='2' space='1.5'>
        <HStack w='40px' alignItems='center' justifyContent='center'>
          <XIcon size='sm' variant='inactive' name='address-book-o' family='FontAwesome' />
        </HStack>
        <HStack flex={1} minH='34px' alignItems='center'>
          <XText variant='active'>{emptyLabel}</XText>
        </HStack>
        <HStack w='100px' alignItems='center' justifyContent='flex-end' space='1'>
          {this.canPressOnCard() && <ForwardIcon />}
        </HStack>
      </HStack>
    )
  }

  _onCardPressed = () => {
    const { address, onPressCard = null } = this.props
    if (_.isFunction(onPressCard)) {
      onPressCard(address)
    }
  }

  renderMain = () => {
    const { disabled, address, onPressCard = null } = this.props
    return (
      <XCard w='full' onPress={this.canPressOnCard() ? this._onCardPressed : undefined}>
        {_.isNil(address) ? this.renderEmptyCard() : this.renderAddressCard()}
      </XCard>
    )
  }

  render() {
    return this.renderMain()

    // const { address, onPressCard = null } = this.props
    // if (_.isNil(address)) {
    //   return null
    // }
    // // const canEdit = !!(_.isNil(isEditable) || isEditable)
    // // if (_.isFunction(onPressCard)) {
    // //   return (
    // //     <XCard onPress={() => onPressCard(address)} w='full' mt='2' key={`${address.id}${address.updated_at}`}>
    // //       <Box w='full'>{this.renderPrimaryView()}</Box>
    // //     </XCard>
    // //   )
    // // }
    // return (
    //   <XCard w='full' key={`${address.id}${address.updated_at}`} onPress={_.isFunction(onPressCard) ? this._onCardPressed : undefined}>
    //     {this.renderMain()}
    //   </XCard>
    // )
    // // return (
    // //   <Box w='full' pt='2' key={`${address.id}${address.updated_at}`}>
    // //     <XCard flex={1}>
    // //       <TouchableOpacity
    // //         style={{ flex: 1, backgroundColor: COLORS.RED_SOFT }}
    // //         onPress={() => {
    // //           if (_.isFunction(onPressCard)) {
    // //             onPressCard()
    // //           }
    // //         }}>
    // //         <Box flexDirection='row' bgColor='red.400'>
    // //           <Box flex={2}>
    // //             <XIcon style={{ color: COLORS.TEXT_INACTIVE }} name='address-card-o' family='FontAwesome' />
    // //           </Box>
    // //           <View style={{ flex: 8, flexDirection: 'row' }}>
    // //             <View style={{ flex: 7, flexDirection: 'column' }}>
    // //               {address.is_primary ? (
    // //                 <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.APP_MAIN }}>ใช้เป็นที่อยู่หลัก</Text>
    // //               ) : null}
    // //               <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE }}>{address.name || ''}</Text>
    // //               <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE }}>
    // //                 {`${address.address1} ${address.address2 || ''}`}
    // //               </Text>
    // //               {address.sub_district && address.district && address.province ? (
    // //                 <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE }}>
    // //                   {`${address.sub_district} ${address.district} ${address.province}`}
    // //                 </Text>
    // //               ) : null}
    // //               {address.postal_code ? (
    // //                 <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE }}>{`${address.postal_code}`}</Text>
    // //               ) : null}
    // //             </View>
    // //             {canEdit ? (
    // //               <View style={{ flex: 3, flexDirection: 'row', justifyContent: 'flex-end' }}>
    // //                 <TouchableOpacity
    // //                   onPress={() => {
    // //                     if (_.isFunction(onPressEdit)) {
    // //                       onPressEdit()
    // //                     }
    // //                   }}
    // //                   style={{
    // //                     width: 25,
    // //                     height: 25,
    // //                     justifyContent: 'center',
    // //                     alignItems: 'center',
    // //                   }}>
    // //                   <XIcon
    // //                     style={{ flex: 0, minWidth: 20, width: 20, fontSize: STYLES.FONT_ICON_SMALLEST }}
    // //                     name='edit'
    // //                     family='FontAwesome5'
    // //                   />
    // //                 </TouchableOpacity>

    // //                 <TouchableOpacity
    // //                   onPress={() => {
    // //                     if (_.isFunction(onPressDelete)) {
    // //                       onPressDelete()
    // //                     }
    // //                   }}
    // //                   style={{
    // //                     width: 25,
    // //                     height: 25,
    // //                     justifyContent: 'center',
    // //                     alignItems: 'center',
    // //                   }}>
    // //                   <XIcon
    // //                     style={{ flex: 0, minWidth: 20, width: 20, fontSize: STYLES.FONT_ICON_SMALLEST }}
    // //                     name='delete'
    // //                     family='AntDesign'
    // //                   />
    // //                 </TouchableOpacity>
    // //               </View>
    // //             ) : (
    // //               <View style={{ flex: 3, flexDirection: 'row', justifyContent: 'flex-end' }} />
    // //             )}
    // //           </View>
    // //         </Box>

    // //         <View style={{ height: 10 }} />

    // //         {/* // PHONE */}
    // //         {address.telephone ? (
    // //           <View style={{ flexDirection: 'row', paddingLeft: 4 }}>
    // //             <View style={{ flex: 2 }}>
    // //               <XIcon
    // //                 style={{ flex: 0, minWidth: 20, width: 20, fontSize: STYLES.FONT_ICON_SMALLER, color: COLORS.TEXT_INACTIVE }}
    // //                 name='phone'
    // //                 family='FontAwesome'
    // //               />
    // //             </View>
    // //             <View style={{ flex: 8 }}>
    // //               <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE }}>{address.telephone}</Text>
    // //             </View>
    // //           </View>
    // //         ) : null}
    // //       </TouchableOpacity>
    // //     </XCard>
    // //   </Box>
    // // )
  }
}
