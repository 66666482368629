import axios from 'axios'
import FileSaver from 'file-saver'
import _ from 'lodash'

import p from 'x/config/platform-specific'
import mime from 'x/utils/mime-types'
import xCONS from 'x/config/constants'
import { log } from 'x/utils/util'

export async function downloadFileFromUrl(options: {
  url: string
  fileName: string
  autoSave?: boolean
  onDownloadProgress?: (percent: number) => void
  timeout?: number
}): Promise<Blob | null> {
  const { url, fileName, onDownloadProgress = null, autoSave = true, timeout = null } = options
  if (!url) {
    throw new Error('downloadFileFromUrl no url.')
  }

  try {
    const fileMime = mime.lookup(fileName) || null

    if (!fileMime) {
      throw new Error('downloadFileFromUrl no mime.')
    }

    // @ts-ignore
    const token: string = await p.op.storageGet(xCONS.AUTHENTICATION_STORAGE_KEY, false)
    const fetchToken: string = token.substring(0, 6) === 'Bearer' ? token : `Bearer ${token}`

    log('downloadRequisition fetchToken => ', fetchToken)
    let headers: { [key: string]: any } = {
      Accept: fileMime,
      'Content-Type': fileMime,
    }

    if (fetchToken) {
      headers = {
        ...headers,
        Authorization: fetchToken,
      }
    }

    const reqParams: { [key: string]: any } = {
      headers,
      // responseType: 'blob',
      responseType: 'arraybuffer',
      ...options,
    }

    if (_.isNumber(timeout)) {
      reqParams.timeout = timeout
    }

    if (_.isFunction(onDownloadProgress)) {
      reqParams.onDownloadProgress = withPercentage((percent: number) => {
        onDownloadProgress(percent)
      })
    }

    log('sendRequest axios reqParams => ', reqParams)
    const res = await axios.get(url, reqParams)

    log('downloadFileFromUrl res => ', res)
    if (res && res.status > 201) {
      throw new Error(res.statusText)
    }

    if (res && res.data) {
      // let file = new File([res.data], { type: fileMime })
      // FileSaver.saveAs(file)
      const blob = new Blob([res.data], { type: fileMime })
      if (autoSave) {
        FileSaver.saveAs(blob, fileName)
      }
      // const base64image = await this.readBlobToBase64(blob)
      return blob
    }
  } catch (err) {
    log('error downloading file err =>', err)
    // throw new Error(e)
    p.op.showConfirmationOkOnly(p.op.t('e_msg_by_error_code.has_error.m'), p.op.t('e_msg_by_error_code.warning_contact_to_line_xselly.m'))
    // p.op.showConfirmationOkOnly('เกิดข้อผิดพลาด', p.op.t(`warning_contact_to.${APP_CONFIG.app_name}`, { err: err }))
  }
  return null
}

// axios convert progress event to percentage
function withPercentage(fn: (percent: number) => void) {
  return function (progressEvent: any) {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
    fn(percentCompleted)
  }
}

// https://stackoverflow.com/questions/18650168/convert-blob-to-base64
export async function readBlobToBase64(blob: Blob): Promise<string | null> {
  return new Promise<string | null>((resolve) => {
    try {
      const reader = new FileReader()
      reader.onloadend = function () {
        if (reader.result) {
          // @ts-ignore
          resolve(reader.result)
        } else {
          resolve(null)
        }
      }

      reader.readAsDataURL(blob)
    } catch (err) {
      log('readBlobToBase64 error => ', err)
      resolve(null)
    }
  })
}

export function isChromium() {
  let isBrowserChromium = false

  try {
    // @ts-ignore https://stackoverflow.com/questions/57660234/how-can-i-check-if-a-browser-is-chromium-based
    isBrowserChromium = !!window.chrome
  } catch (error) {
    //
  }

  let ua = 'browser agent'

  try {
    ua = window.navigator.userAgent.toLocaleLowerCase()
  } catch (error) {
    //
  }

  const isEdge = ua.indexOf('edg') !== -1
  const isChrome = ua.indexOf('chrome') !== -1
  const isOpera = ua.indexOf('opr') !== -1
  const isBrave = ua.indexOf('brave') !== -1
  // https://stackoverflow.com/questions/13807810/ios-chrome-detection
  const isChromeOnIOS = ua.indexOf('crios') !== -1

  // alert(ua)
  return isBrowserChromium || isEdge || isChrome || isOpera || isBrave || isChromeOnIOS
}
