import React from 'react'
import { Circle, Svg, Text as SvgText } from 'react-native-svg'
import { VictoryPie } from 'victory-native'
import { COLORS } from 'x/config/styles'

interface IDonutDataItem {
  x: string
  y: number
}

interface IDonutProps {
  data: IDonutDataItem[]

  diameter: number // donut diameter
  width: number // donut width

  colorScale?: string[]

  innerText?: string | number
  innerTextFontSize?: number
  innerTextFontColor?: string
  innerBgColor?: string
}

export default class DonutChart extends React.PureComponent<IDonutProps> {
  static defaultProps = {
    data: [{ x: 'empty', y: 1 }],
    diameter: 50,
    width: 5,
    innerText: null,
    innerTextFontSize: 14,
    innerTextFontColor: 'black',
    innerBgColor: null,
    colorScale: [COLORS.BG_LIGHT_GREY],
  }

  _ignoreLabel = () => null

  render() {
    const { data, diameter, width, innerBgColor, innerTextFontSize, innerTextFontColor, innerText, colorScale } = this.props
    const radius = diameter / 2
    const innerRadius = radius - width

    return (
      <Svg width={diameter} height={diameter}>
        {innerBgColor ? <Circle cx={radius} cy={radius} r={innerRadius} fill={innerBgColor} /> : null}
        {innerText ? (
          <SvgText
            fill={innerTextFontColor}
            // stroke={innerTextFontColor}
            fontSize={innerTextFontSize}
            // fontWeight='bold'
            x={radius}
            y={radius + innerTextFontSize / 3}
            textAnchor='middle'>
            {innerText}
          </SvgText>
        ) : null}
        <VictoryPie
          standalone={false}
          labels={this._ignoreLabel}
          // labelRadius={0}
          // colorScale={['tomato', 'orange', 'gold', 'cyan', 'navy']}
          colorScale={colorScale}
          width={diameter}
          height={diameter}
          radius={radius}
          innerRadius={innerRadius}
          // innerRadius={width}
          data={data}
        />
      </Svg>
    )
  }
}
