import React, { Component, PureComponent } from 'react'
import { TouchableOpacity, View, StyleSheet } from 'react-native'

// import { ScrollView } from 'native-base'

import { setStatePromise, delay } from 'x/utils/util'
import _ from 'lodash'
import { List } from 'immutable'
import { IXFlatPickerProps, IXFlatPickerState } from 'x/index'
// import CONS from 'x/config/constants'

import XOverlay from './XOverlay'
import XIconButton from './XIconButton'
import XText from './XText'
import VStack from './VStack'
import HStack from './HStack'
import XScrollView from './XScrollView'

export default class XFlatPicker<T> extends Component<IXFlatPickerProps<T>, IXFlatPickerState> {
  static displayName = 'XFlatPicker'

  randomKey: string

  constructor(props: IXFlatPickerProps<T>) {
    super(props)

    this.randomKey = `${Math.floor(Math.random() * 10000)}`
    this.state = {
      isVisiblePicker: false,
    }
  }

  getCurrentIndex = (): number => this.props.selectedIndex

  getCurrentOption = (): T | null => {
    const { selectedIndex, options } = this.props
    if (selectedIndex < 0) {
      return null
    }

    if (List.isList(options)) {
      return options.get(selectedIndex)
    }

    return options[selectedIndex]
  }

  getCurrentItem = () => {
    const { selectedIndex, options, disabled = false } = this.props
    return {
      index: this.getCurrentIndex(),
      item: this.getCurrentOption(),
      disabled,
    }
  }

  openPicker = (): void => {
    this.setState({ isVisiblePicker: true })
  }

  _onPick = async (index: number): Promise<void> => {
    // log('BaseModalPicker _onPick index => ', index)
    await setStatePromise(this, { isVisiblePicker: false })
    await delay(200)

    const { onPick, options } = this.props
    const optionsArr = List.isList(options) ? options.toArray() : options

    if (_.isFunction(onPick)) {
      onPick({ item: optionsArr[index], index })
    }
  }

  _onClosePicker = async () => {
    await setStatePromise(this, { isVisiblePicker: false })
  }

  _renderPickerItems = () => {
    const { options, renderItem } = this.props
    if (!options || (!List.isList(options) && !_.isArray(options))) {
      throw new Error('Missing XFlatPicker options')
      // return null
    }

    if (!_.isFunction(renderItem)) {
      throw new Error('Missing XFlatPicker renderItem method')
      // return null
    }

    const optionsArr = List.isList(options) ? options.toArray() : options
    // const isCustomRenderItem = _.isFunction(renderItem)

    // return (
    //   <FlatList<T>
    //     keyExtractor={this._keyExtractor}
    //     data={optionsArr}
    //     // renderItem={isCustomRenderItem ? renderItem : this._renderPickerItem}
    //     // renderItem={renderItem}
    //     renderItem={this._renderPickerItem}
    //   />
    // )
    return optionsArr.map(this._renderPickerItem)
  }

  // _keyExtractor = (item, index) => `p-${index}`
  // _renderPickerItem = itemInfo => {
  _renderPickerItem = (item, index: number) => {
    const { disabled, renderItem } = this.props
    if (item && item.hidden) {
      return <View key={`p-${index}-hidden`} />
    }
    return (
      <PickerItemWrapper key={`PickerItem_${this.randomKey}_${index}`} index={index} onPick={this._onPick}>
        {/* {this.props.renderItem(itemInfo)} */}
        {renderItem({ item, index, disabled })}
      </PickerItemWrapper>
    )
  }

  // _renderPickerItem = ({ item, index }) => {
  //   if (!item) {
  //     return null
  //   }
  //   const focusedItem = Map.isMap(item) ? item : fromJS(item)
  //   const isHidden = focusedItem.get('isHidden')
  //   const name = focusedItem.get('name')
  //   if (isHidden || !name) {
  //     return null
  //   }

  //   return <PickerItem key={index} index={index} name={name} onPick={this._onPick} />
  // }

  onPressLeftItem = () => {
    const { onPressLeftHeaderButton } = this.props
    if (_.isFunction(onPressLeftHeaderButton)) {
      onPressLeftHeaderButton()
    }
    this._onClosePicker()
  }

  _renderModal = () => {
    const { title, renderLeftHeaderButton, renderTitleBar } = this.props
    // let injectedSafeHeaderMargin = {}
    // injectedSafeHeaderMargin = {
    //   marginTop: getStatusBarHeight(),
    // }
    // const maxHeight = Math.floor(Dimensions.get('screen').height - 180)

    return (
      <XOverlay
        key={`Picker_${this.randomKey}`}
        // contentStyle={Platform.OS === 'web' ? {} : { width: '94%', height: '90%' }}
        onRequestClose={this._onClosePicker}
        visible={this.state.isVisiblePicker}>
        {/* <VStack
        // w='full'
        // _web={{
        //   // h: 'full',
        //   w: CONS.SPECIFIC_PLATFORM.WEB.MAIN_CONTENT_ONE_COLUMN_WIDTH,
        // }}
        // h='full'
        // minW='xs'
        // flexWrap='wrap'
        // overflow='hidden'
        // borderRadius='lg'
        // style={{ maxHeight: Math.floor(Dimensions.get(!p.op.isWeb() ? 'screen' : 'window').height - 180) }}
        > */}
        <HStack w='full' minH='11' alignItems='center'>
          <HStack minW='11' alignItems='center' justifyContent='center'>
            {renderLeftHeaderButton ? (
              <TouchableOpacity onPress={() => this.onPressLeftItem()}>{renderLeftHeaderButton()}</TouchableOpacity>
            ) : null}
          </HStack>
          <HStack flex={1} alignItems='center' justifyContent='center'>
            <XText bold>{title}</XText>
          </HStack>
          <HStack minW='11' alignItems='center' justifyContent='center'>
            {/* <NavHeaderButton onPressItem={this._onClosePicker}>
                      <XIcon family='Ionicons' name='close-circle' style={{ color: COLORS.TEXT_INACTIVE }} />
                    </NavHeaderButton> */}
            <XIconButton family='Ionicons' name='close-circle' onPress={this._onClosePicker} />
          </HStack>
        </HStack>
        <VStack w='full'>{_.isNil(renderTitleBar) ? null : renderTitleBar()}</VStack>
        <XScrollView
          w='full'
          // flex={1}
          _web={{ flex: 1 }}
          // style={{ backgroundColor: 'blue' }}
          // contentContainerStyle={{ padding: 4 }}
          // flex={1}
          // _contentContainerStyle={{ p: '2' }}
          //  _web={{ flex: 1 }}
          _light={{ bg: 'coolGray.200' }}
          _dark={{ bg: 'warmGray.700' }}>
          <VStack w='full' p='2'>
            {this._renderPickerItems()}
          </VStack>
        </XScrollView>
        {/* </VStack> */}
      </XOverlay>
    )
  }

  _renderSelectedValue = () => {
    const { disabled = false, renderSelectedValue, btnStyle = {} } = this.props
    if (!_.isFunction(renderSelectedValue)) {
      throw new Error('Missing XFlatPicker renderSelectedValue method')
    }

    return (
      <TouchableOpacity disabled={disabled} onPress={this.openPicker} style={StyleSheet.flatten([{ width: '100%' }, btnStyle])}>
        {renderSelectedValue(this.getCurrentItem())}
      </TouchableOpacity>
    )

    // return (
    //   <Card style={{ marginLeft: 0, marginRight: 0 }}>
    //     <Box
    //       cardBody={true}
    //       button={!disabled}
    //       style={{ paddingTop: 5, paddingBottom: 5 }}
    //       onPress={disabled ? null : this.openPicker}>
    //       <Stack style={{ flex: 1, minHeight: 44 }}>
    //         <HStack>
    //           <VStack size={85} style={{ paddingHorizontal: 8 }}>
    //             {/* Begin Child HStack*/}
    //             <HStack>
    //               <VStack
    //                 size={40}
    //                 style={{
    //                   flex: 0,
    //                   width: 88,
    //                   justifyContent: 'center',
    //                   alignItems: 'flex-start',
    //                   paddingHorizontal: 8,
    //                   paddingVertical: 4,
    //                 }}>
    //                 <Text
    //                   style={{
    //                     textAlign: 'left',
    //                     fontSize: STYLES.FONT_SIZE_NORMAL,
    //                     color: TEXT_INACTIVE,
    //                     // color: TEXT_ACTIVE,
    //                     fontWeight: 'bold',
    //                     marginTop: 0,
    //                   }}>
    //                   {label}
    //                 </Text>
    //               </VStack>
    //               <VStack
    //                 size={60}
    //                 style={{
    //                   justifyContent: 'center',
    //                   alignItems: 'flex-start',
    //                   paddingHorizontal: 8,
    //                   paddingVertical: 4,
    //                 }}>
    //                 <Text
    //                   allowFontScaling={false}
    //                   numberOfLines={2}
    //                   style={{
    //                     textAlign: 'left',
    //                     fontSize: STYLES.FONT_SIZE_NORMAL,
    //                     color: disabled ? TEXT_ACTIVE : APP_MAIN,
    //                     fontWeight: 'bold',
    //                     marginTop: 0,
    //                   }}>
    //                   {/*{ this._renderSelectedLabel() }*/}
    //                   {this._getSelectedLabel()}
    //                 </Text>
    //               </VStack>
    //             </HStack>
    //             {/* End Child HStack*/}
    //           </VStack>
    //           <VStack
    //             // size={15}
    //             style={{
    //               flex: 0,
    //               justifyContent: 'center',
    //               alignItems: 'flex-end',
    //               // paddingHorizontal: 8,
    //               paddingRight: 8,
    //               width: 44,
    //             }}>
    //             {disabled ? null : <ForwardIcon style={{ flex: 0 }} />}
    //           </VStack>
    //         </HStack>
    //       </Stack>
    //     </Box>
    //   </Card>
    // )
  }

  // _renderMain = () => {
  //   return (
  //     <View>
  //       {this._renderSelectedValue()}
  //       {this._renderModal()}
  //     </View>
  //   )
  // }

  _renderMain = () => (
    <VStack w='full'>
      {this._renderSelectedValue()}
      {this._renderModal()}
    </VStack>
  )

  render() {
    return this._renderMain()
  }
}

class PickerItemWrapper extends PureComponent<{
  index: number
  onPick: (index: number) => void | Promise<void>
}> {
  _onPickItem = () => {
    const { index, onPick } = this.props
    if (_.isFunction(onPick)) {
      onPick(index)
    }
  }

  render() {
    const { children } = this.props
    return <TouchableOpacity onPress={this._onPickItem}>{children}</TouchableOpacity>
  }
}

// class PickerItem extends PureComponent<{
//   index: number
//   name: string
//   onPick: (index: number) => void | Promise<void>,
// }> {
//   _onPickItem = () => {
//     const { index, onPick } = this.props
//     if (_.isFunction(onPick)) {
//       onPick(index)
//     }
//   }
//   render() {
//     return (
//       <Card style={styles.cardPickerItem}>
//         <Box button={true} onPress={this._onPickItem}>
//           <Stack>
//             <HStack>
//               <VStack size={100} style={styles.colMiddleLeft}>
//                 <Text style={styles.txtPickerItem}>{this.props.name}</Text>
//               </VStack>
//               <VStack style={{ flex: 0, width: 22 }}>
//                 <ForwardIcon inactive={true} />
//               </VStack>
//             </HStack>
//           </Stack>
//         </Box>
//       </Card>
//     )
//   }
// }

// const styles = StyleSheet.create({
//   cardPickerItem: {
//     marginLeft: 8,
//     marginRight: 8,
//     marginTop: 5,
//     marginBottom: 2,
//   },
//   txtPickerItem: {
//     fontSize: FONT_SIZE_NORMAL,
//     color: TEXT_ACTIVE,
//   },
//   colMiddleLeft: {
//     justifyContent: 'center',
//     alignItems: 'flex-start',
//   },
//   FLEX: {
//     flex: 1,
//   },
//   GRID_COL_MIDDLE_CENTER: {
//     alignItems: 'center',
//     justifyContent: 'center',
//   },
//   GRID_COL_MIDDLE_LEFT: {
//     alignItems: 'flex-start',
//     justifyContent: 'center',
//   },
//   GRID_COL_MIDDLE_RIGHT: {
//     alignItems: 'flex-end',
//     justifyContent: 'center',
//   },
//   DEFAULT_CONTENT: {
//     flex: 1,
//     backgroundColor: COLORS.BG_SOFT_GREY,
//   },
//   DEFAULT_CONTAINER: {
//     flex: 1,
//     backgroundColor: 'transparent',
//   },
// })
