import React, { Component } from 'react'

import {
  TouchableOpacity,
  View,
  Image,
  StyleSheet,
  TextStyle,
  Platform,
  Dimensions,
  ViewStyle,
  BackHandler,
  NativeEventSubscription,
} from 'react-native'
import HStack from 'xui/components/HStack'

import CONS from 'x/config/constants'
import _ from 'lodash'
import { Map, List } from 'immutable'
import { COLORS, STYLES } from 'x/config/styles'
import p from 'x/config/platform-specific'
import { log, logRender, isXSellyOrderId, setStatePromise, delay, isJSON, getNavParam } from 'x/utils/util'
import * as util from 'x/utils/util'
import { IQuickShipViewProps, IQuickShipViewState, NavigationEventSubscription } from 'x/index'
import moment, { Moment } from 'moment'
import XButton from 'xui/components/XButton'
import XCard from 'xui/components/XCard'
import VStack from 'xui/components/VStack'
import XText from 'xui/components/XText'
import XSpinner from 'xui/components/XSpinner'
import Segment from '../../components/Segment'
import XInput from '../../components/XInput'
import XIcon from '../../components/XIcon'
import XCustomHeader from '../../components/XCustomHeader'
import XContent from '../../components/XContent'
import XContainer from '../../components/XContainer'
// import xCONS from 'x/config/constants'
// import * as permN from '../../utils/permission-native'
// import BackIcon from '../../components/BackIcon'

const barcodeBorder = require('../../images/barcode-marker.png')

const KEY_OPTION_MY_ORDER = 'KEY_OPTION_MY_ORDER'
const KEY_OPTION_TRACKING = 'KEY_OPTION_TRACKING'
const OPTION_TRACKING_MODE = p.op.isWeb()
  ? {
      SCAN: 0,
      TYPING: 0,
      XSHIPPING: 1,
      NOT_SPECIFIED: 2,
    }
  : {
      SCAN: 0,
      TYPING: 1,
      XSHIPPING: 2,
      NOT_SPECIFIED: 3,
    }

// const XCamera = Platform.OS === 'ios' ? RNCamera : RCTCamera
// const XCamera = RNCamera // Move both ios and android to RNCamera

export default abstract class BaseUIQuickShipView extends Component<IQuickShipViewProps, IQuickShipViewState> {
  static displayName = 'BaseUIQuickShipView'

  OPTION_MY_ORDER: string[]

  OPTION_TRACKING: string[]

  // inputRefs: any[]
  _inProcess?: boolean

  _inProcessOrderBarcode?: Moment

  _inProcessShipBarcode?: boolean

  isReadingBarcode?: boolean

  isUnmounting?: boolean

  _backHandleSubscription?: NativeEventSubscription

  _navWillFocusSubscription?: NavigationEventSubscription

  _navWillBlurSubscription?: NavigationEventSubscription

  abstract renderCamera(params: { onBarcodeScanned: (e: any, isDisabledSound?: boolean) => void; isShipCamera?: boolean }): JSX.Element

  abstract ringBarcodeSound(): void

  abstract getCameraWidth(): number

  abstract getCameraBoxStyle(): ViewStyle

  abstract webInjectOrderBarcodeListener(): JSX.Element | null

  abstract webInjectTrackingBarcodeListener(): JSX.Element | null

  constructor(props) {
    super(props)

    this.state = {
      // isCameraGranted: false,
      // isAndroidOldVersion: false,
      // androidAspectRatio: '4:3',
      isCameraReady: false,
      optionOrder: 0,
      optionTracking: 0,

      textOptionOrder: '',
      textOptionTracking: '',
      autoFocusInputOrder: false,
      autoFocusInputTracking: false,
    }
    this._inProcessOrderBarcode = null
    this._inProcessShipBarcode = false
    this.isReadingBarcode = false
    this.OPTION_MY_ORDER = ['สแกน', 'พิมพ์']
    this.OPTION_TRACKING = p.op.isWeb() ? ['สแกน/พิมพ์', 'XShipping', 'ไม่ระบุ'] : ['สแกน', 'พิมพ์', 'XShipping', 'ไม่ระบุ']
    // this.inputRefs = []
  }

  async componentDidMount() {
    const { navigation } = this.props
    this._navWillFocusSubscription = navigation.addListener('focus', this._setDirtyHandler)
    this._navWillBlurSubscription = navigation.addListener('blur', this._unsetBackHandler)

    log('_BaseUIQuickShipView componentDidMount_')
    // this.barcodeSound = new BarcodeSound()
    let storageGetOrtonOrder = null
    let storageGetOrtionTracking = null
    let autoFocusInputOrder = false
    let autoFocusInputTracking = false
    await p.op.storageGet(CONS.STORAGE_KEYS.SCAN_ORTION_ORDER).then((val: any) => {
      storageGetOrtonOrder = val
    })
    await p.op.storageGet(CONS.STORAGE_KEYS.SCAN_ORTION_TRACKING).then((val: any) => {
      storageGetOrtionTracking = val
    })
    if (_.isNil(storageGetOrtonOrder)) {
      storageGetOrtonOrder = 0
    } else if (storageGetOrtonOrder === 1) {
      autoFocusInputOrder = true
    }
    if (_.isNil(storageGetOrtionTracking)) {
      storageGetOrtionTracking = 0
    } else if (storageGetOrtionTracking === 1) {
      autoFocusInputTracking = true
    }

    // บังคับไม่ให้เลือก segment option ไปที่กล้อง
    if (storageGetOrtonOrder !== 1) {
      storageGetOrtonOrder = p.op.isWeb() ? 1 : storageGetOrtonOrder
    }
    // if (storageGetOrtionTracking !== 1) {
    //   storageGetOrtionTracking = p.op.isWeb() ? 1 : storageGetOrtionTracking
    // }
    await setStatePromise(this, {
      optionOrder: storageGetOrtonOrder,
      optionTracking: storageGetOrtionTracking,
      autoFocusInputOrder,
      autoFocusInputTracking,
    })
    // this.props.navigation.setParams({
    //   backBtn: this._handlePressBackBtn,
    // })
  }

  componentDidUpdate() {
    if (this._isShippable()) {
      this._setDirtyHandler()
    }
  }

  componentWillUnmount() {
    if (!this.isUnmounting) {
      this.isUnmounting = true

      this._unsetBackHandler()

      if (this._navWillFocusSubscription) {
        this._navWillFocusSubscription()
      }

      if (this._navWillBlurSubscription) {
        this._navWillBlurSubscription()
      }

      // this.barcodeSound.release()
      this.props.qsReset()
    }
  }

  _setDirtyHandler = () => {
    if (this._isShippable() && !this._backHandleSubscription) {
      // แจ้งว่าข้อมูล dirty
      this._backHandleSubscription = BackHandler.addEventListener('hardwareBackPress', this.onPressGoBackAction)
    }
  }

  _unsetBackHandler = () => {
    if (this._backHandleSubscription) {
      this._backHandleSubscription.remove()
    }
  }

  /**
   * Returns true if the scanned order can be shipped (at least one product to-be-shipped)
   */
  _isShippable = () => {
    const { shipmentData, selectedOrder } = this.props

    if (!selectedOrder || !Map.isMap(selectedOrder) || !selectedOrder.get('id')) {
      return false
    }

    const orderType = selectedOrder.get('type')
    const products = shipmentData.get('products')
    const tracking_number = shipmentData.get('tracking_number')
    let isShippable = false
    if (products.size > 0 && tracking_number.length > 0 && orderType !== 3) {
      const productQty = products.reduce((prevQty, product) => prevQty + product.get('qty'), 0)
      if (productQty > 0) {
        isShippable = true
      }
    }
    return isShippable
  }

  // https://reactnative.dev/docs/backhandler#pattern
  _handlePressBackBtn = (): boolean => {
    if (this._isShippable()) {
      p.op.showConfirmation(
        'คุณยังไม่ได้แจ้งจัดส่ง',
        `หากต้องการบันทึกการจัดส่งนี้ กรุณากด "ปิด" แล้วกด "จัดส่ง" (ด้านขวาล่าง)`,
        () => {
          // do nothing
        },
        () => {
          util.navGoBack(this.props)
        },
        'ปิด',
        'ออกจากหน้านี้'
      )
      return true
    }

    util.navGoBack(this.props)
    return false
  }

  _fetchOrderFromBarcode = async (order_id: number) => {
    const { fetchOrderFromBarcode, selectedStore } = this.props
    const store_id = selectedStore.get('id')
    const res = await new Promise((resolve) => {
      fetchOrderFromBarcode({
        body: { store_id, order_id },
        successCallback: resolve,
        failedCallback: () => resolve(null),
      })
    })

    if (res) {
      await delay(50)
      const availableTrackingToShipList = this._getXShippingAvailableToShipTrackingNumbers()
      if (availableTrackingToShipList.size > 0) {
        await setStatePromise(this, { optionTracking: OPTION_TRACKING_MODE.XSHIPPING })
      }
      await delay(50)
      await this._processOptionTrackingAfterLoadOrder()
    }

    return res
  }

  _processOptionTrackingAfterLoadOrder = async (newIndex: number = null) => {
    const { SCAN, TYPING, XSHIPPING, NOT_SPECIFIED } = OPTION_TRACKING_MODE
    const orderTrackingIndex = _.isNumber(newIndex) ? newIndex : this.state.optionTracking
    if (_.includes([SCAN, TYPING], orderTrackingIndex)) {
      this.props.qsTrackingNumberLoad({ tracking_number: '' })
      await delay(50)
      this.props.qsCameraStateChange({ order: 8, ship: 1 })
    } else if (orderTrackingIndex === XSHIPPING) {
      const availableTrackingToShipList = this._getXShippingAvailableToShipTrackingNumbers()
      if (availableTrackingToShipList.size > 0) {
        if (availableTrackingToShipList.size > 1) {
          this.props.qsTrackingNumberLoad({ tracking_numbers: availableTrackingToShipList.toArray() })
        } else {
          this.props.qsTrackingNumberLoad({ tracking_number: availableTrackingToShipList.get(0) })
        }
        await delay(50)
        this.props.qsCameraStateChange({ order: 8, ship: 9 })
      } else {
        this.props.qsTrackingNumberLoad({ tracking_number: '' })
        await delay(50)
        this.props.qsCameraStateChange({ order: 8, ship: -1 })
      }
    } else if (orderTrackingIndex === NOT_SPECIFIED) {
      this.props.qsTrackingNumberLoad({ tracking_number: '-' })
      await delay(50)
      this.props.qsCameraStateChange({ order: 8, ship: 9 })
    }
  }

  onOrderBarcodeScan = async (e, isDisabledSound = false) => {
    // console.log('onOrderBarcodeScan!!!__e_: ', e)
    // Alert.alert('onOrderBarcodeScan', JSON.stringify(e))
    if (_.has(e, 'data') && _.isString(e.data)) {
      const parseEnterData = e.data.replace(/\n/g, '')
      // log('parseEnterData => ', parseEnterData)
      setStatePromise(this, {
        textOptionOrder: parseEnterData,
      })
      // // เอา validation ออกเพราะเผื่อว่าอนาคตจะ customize order_id pattern ได้
      // if (!isXSellyOrderId(parseEnterData)) {
      //   // ถ้าไม่เป็น number only
      //   this.isReadingBarcode = false
      //   return
      // }

      if (!isDisabledSound) {
        this.ringBarcodeSound()
      }

      const { qsCameraStateChange, navigation } = this.props
      qsCameraStateChange({ order: 2, ship: 2 })

      const order_id = parseEnterData
      if (isXSellyOrderId(order_id)) {
        // Set for delay ship camera
        this._inProcessOrderBarcode = moment()

        const store_id = getNavParam(this.props, 'store_id')
        if (order_id && store_id) {
          // fetchOrderFromBarcode({ store_id, order_id: parseInt(e.data) })
          // fetchOrderFromBarcode({ store_id, order_id: parseEnterData })
          await this._fetchOrderFromBarcode(order_id)
        } else {
          log('_fetchOrder parameter is not fulfilled')
          qsCameraStateChange({ order: -1, ship: -1 })
        }
      } else {
        qsCameraStateChange({ order: -1, ship: -1 })
      }
      // if (typeof order_id === 'number' || /^\d+$/.test(order_id)) {
      //   const { store_id } = navigation.state.params
      //   if (order_id && store_id) {
      //     fetchOrderFromBarcode({ store_id, order_id: parseInt(e.data) })
      //   } else {
      //     log('_fetchOrder parameter is not fulfilled')
      //     qsCameraStateChange({ order: -1, ship: -1 })
      //   }
      // } else {
      //   qsCameraStateChange({ order: -1, ship: -1 })
      // }
    }
    this.isReadingBarcode = false
  }

  onShipBarcodeScan = async (e, isDisabledSound = false) => {
    if (moment.isMoment(this._inProcessOrderBarcode)) {
      const diffMsDuration = moment().diff(this._inProcessOrderBarcode, 'millisecond', true)
      // log('onShipBarcodeScan!!! diffMsDuration: ' + JSON.stringify(diffMsDuration))
      if (diffMsDuration <= 1000) {
        this.isReadingBarcode = false
        return
      }
    }
    this._inProcessOrderBarcode = null // reset inProcess
    if (this._inProcessShipBarcode) {
      this.isReadingBarcode = false
      return
    }
    this._inProcessShipBarcode = true

    // log('onShipBarcodeScan!!!__e_: ', e)
    if (_.has(e, 'data') && _.isString(e.data)) {
      const parseEnterData = e.data.replace(/\n/g, '')
      // log('onShipBarcodeScan!!!__parseEnterData_: ', parseEnterData)
      // log('onShipBarcodeScan!!!isXSellyOrderId: ' + isXSellyOrderId(parseEnterData))
      // if (xUtil.isXSellyOrderId(parseEnterData)) { // ถ้าไม่เป็น number only
      //   return
      // }

      if (!isDisabledSound) {
        this.ringBarcodeSound()
      }

      const { selectedOrder } = this.props
      if (Map.isMap(selectedOrder) && parseEnterData === selectedOrder.get('name')) {
        const isUserConfirm = await p.op.isUserConfirm(
          'เลขจัดส่งซ้ำกับเลขออเดอร์',
          `กรุณายืนยันว่าจะใช้งานเลขจัดส่ง "${parseEnterData}" ซึ่งซ้ำกับเลขของออเดอร์`
        )
        if (!isUserConfirm) {
          this._inProcessShipBarcode = false
          this.isReadingBarcode = false
          return
        }
      }

      const { qsTrackingNumberLoad, qsCameraStateChange } = this.props
      qsCameraStateChange({ ship: 2 })

      if (parseEnterData.length > 0) {
        qsTrackingNumberLoad({ tracking_number: parseEnterData })
        qsCameraStateChange({ order: 9, ship: 9 })
      } else {
        p.op.alert('คำเตือน', 'เกิดข้อผิดพลาดในระหว่างสแกนเลขพัสดุ กรุณาสแกนใหม่อีกครั้ง')
      }
    }
    this._inProcessShipBarcode = false
    this.isReadingBarcode = false
  }

  _onSingleLocalBarcodeDetected = async (e, onBarcodeScanned) => {
    if (!this.state.isCameraReady || this.isReadingBarcode) {
      return
    }
    this.isReadingBarcode = true
    // FIXME: O: Temporary ignored barcode
    // https://github.com/react-native-community/react-native-camera/issues/2875
    if (isJSON(e.data)) {
      return
    }
    onBarcodeScanned(e)
  }

  _onShipGoogleVisionBarcodesDetected = async (response, onBarcodeScanned) => {
    if (!this.state.isCameraReady || this.isReadingBarcode) {
      return
    }
    this.isReadingBarcode = true
    if (response && response.barcodes && response.barcodes.length > 0) {
      if (response.barcodes.length > 1) {
        this._onMultipleBarcodesDetected(response.barcodes, onBarcodeScanned)
      } else {
        const barcodeData = response.barcodes[0]
        if (isJSON(barcodeData)) {
          // FIXME: Temporary ignored barcode
          // https://github.com/react-native-community/react-native-camera/issues/2875
          this.isReadingBarcode = false
          return
        }
        onBarcodeScanned(barcodeData)
      }
    } else {
      this.isReadingBarcode = false
    }
  }

  _onMultipleBarcodesDetected = async (barcodes, onBarcodeScanned) => {
    this.ringBarcodeSound()

    const codeArr = barcodes.map((barcode) => barcode.data)
    const actionConfig = {
      title: 'เลือกใช้ข้อมูลที่สแกนพบ...',
      cancelButtonIndex: codeArr.length,
      options: codeArr.concat('ยกเลิก'),
    }
    p.op.showActionSheet(actionConfig, (buttonIndex) => {
      this._inProcess = false
      const idx = _.isString(buttonIndex) ? parseInt(buttonIndex) : buttonIndex
      if (idx >= 0 && idx < barcodes.length) {
        onBarcodeScanned(barcodes[idx], true)
      } else {
        this.isReadingBarcode = false
      }
    })
  }

  // _renderWaitingBarcodeBox({ text, customIconName, isLoading }) {
  //   const renderIcon = customIconName
  //     ? <XIcon type='MaterialCommunityIcons' name={customIconName} style={{ fontSize: STYLES.FONT_ICON_LARGEST, color: COLORS.TEXT_INACTIVE }} />
  //     : <XIcon type='MaterialCommunityIcons' name='barcode-scan' style={{ fontSize: STYLES.FONT_ICON_LARGEST, color: COLORS.TEXT_INACTIVE }} />
  //
  //   return (
  //     <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
  //       <View style={[this.getCameraBoxStyle(), {
  //         flexDirection: 'column',
  //         justifyContent: 'center',
  //         alignItems: 'center',
  //         borderWidth: 1,
  //         borderColor: COLORS.TEXT_INACTIVE,
  //         backgroundColor: COLORS.FORM_INACTIVE,
  //       }]}>
  //         <XText style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE }}>
  //           { text }
  //         </XText>
  //         { isLoading ? <Spinner color={COLORS.TEXT_INACTIVE} /> : renderIcon }
  //       </View>
  //     </View>
  //   )
  // }
  //
  // // key just for classify component for react-native
  // _renderInfoBox({ key, beforeComponent, textContentArray, afterComponent }) {
  //   return (
  //     <View key={`Info_${key}`}
  //       style={{
  //         flex: 1,
  //         flexDirection: 'column',
  //         justifyContent: 'flex-start',
  //         // backgroundColor: 'red',
  //         borderWidth: 1,
  //         borderColor: COLORS.TEXT_INACTIVE,
  //         backgroundColor: COLORS.FORM_INACTIVE,
  //       }}>
  //       { beforeComponent ? beforeComponent : <View /> }
  //       {
  //         textContentArray.length > 0 ? (
  //           <View style={[STYLES.NO_MARGIN, STYLES.NO_PADDING]}>
  //             { textContentArray.map((content, index) => this._renderInfoText({ key, index, ...content })) }
  //           </View>
  //         ) : <View />
  //       }
  //       { afterComponent ? afterComponent : <View /> }
  //     </View>
  //   )
  // }

  // _renderInfoText({ key, index, label, text, labelStyle, textStyle }) {
  //   return (
  //     <View key={`Row_${key}_${index}`}
  //       style={[STYLES.NO_MARGIN, STYLES.NO_PADDING, {
  //         height: 25,
  //         flexDirection: 'row',
  //         justifyContent: 'flex-start',
  //         alignItems: 'flex-start',
  //         // backgroundColor: 'pink',
  //       }]}>
  //       <View style={{ flex: 6, flexDirection: 'column', justifyContent: 'center' }}>
  //         <XText style={[{
  //           paddingLeft: 5,
  //           //backgroundColor: 'skyblue',
  //           fontSize: STYLES.FONT_SIZE_NORMAL,
  //           color: COLORS.TEXT_INACTIVE }, labelStyle || {}]}>
  //           { label }
  //         </XText>
  //       </View>
  //       <View style={{ flex: 4, flexDirection: 'column', justifyContent: 'center' }}>
  //         <XText style={[{
  //           paddingRight: 5,
  //           //backgroundColor: 'salmon',
  //           textAlign: 'right',
  //           fontSize: STYLES.FONT_SIZE_NORMAL,
  //           color: COLORS.TEXT_INACTIVE }, textStyle || {}]}>
  //           { text }
  //         </XText>
  //       </View>
  //     </View>
  //   )
  // }
  //
  // _renderWrapCard({ txtHeader, bodyComponent }) {
  //   return (
  //     <Card style={CARD_COMMON}>
  //       <Box cardBody>
  //         <View
  //           style={{
  //             flex: 1,
  //             flexDirection: 'column',
  //             justifyContent: 'flex-start',
  //             // borderWidth: 1,
  //             // borderColor: COLORS.FORM_INACTIVE,
  //           }}>
  //           <View style={{ flex: 1, alignSelf: 'center', paddingTop: 5, paddingBottom: 5 }}>
  //             <XText
  //               style={{
  //                 fontSize: STYLES.FONT_SIZE_LARGER,
  //                 fontWeight: 'bold',
  //                 textAlign: 'center',
  //               }}>
  //               { txtHeader }
  //             </XText>
  //           </View>
  //           <View
  //             style={{
  //               flex: 1,
  //               justifyContent: 'flex-start',
  //               alignItems: 'center',
  //               marginBottom: 10,
  //             }}>
  //             { bodyComponent ? bodyComponent : <View /> }
  //           </View>
  //         </View>
  //       </Box>
  //     </Card>
  //   )
  // }
  _isAvailableToShip = (): boolean => {
    const { optionTracking } = this.state
    const { shipmentData, selectedOrder } = this.props
    const orderType = selectedOrder.get('type')
    const products = shipmentData.get('products')
    const tracking_number = shipmentData.get('tracking_number') || ''

    // const currentShippingTypeId = shipmentData.get('shipping_type_id')
    // const isEnableXShippingFeature = selectedStore.get('s_use_xshipping')
    const availableTrackingToShipList = this._getXShippingAvailableToShipTrackingNumbers()

    let isAvailableToShip = false

    if (products.size > 0 && tracking_number.length > 0 && orderType !== 3) {
      const productQty = products.reduce((prevQty, product) => prevQty + product.get('qty'), 0)
      if (productQty > 0) {
        isAvailableToShip = true
      }
    } else if (optionTracking === OPTION_TRACKING_MODE.XSHIPPING && availableTrackingToShipList.size > 0) {
      return true
    }

    return isAvailableToShip
  }

  _renderFooter = () => {
    // const { shipmentData, selectedOrder } = this.props
    // // log('_renderFooter')
    // // log(selectedOrder)
    // // TODO: Disable footer if camera for scanning order id is open
    // const orderType = selectedOrder.get('type')
    // const products = shipmentData.get('products')
    // const tracking_number = shipmentData.get('tracking_number')
    // let disabledShipping = true
    // if (products.size > 0 && tracking_number.length > 0 && orderType !== 3) {
    //   const productQty = products.reduce((prevQty, product) => prevQty + product.get('qty'), 0)
    //   if (productQty > 0) {
    //     disabledShipping = false
    //   }
    // }
    const isAvailableToShip = this._isAvailableToShip()
    return (
      <HStack w='full' py='1' px='2' _light={{ bg: 'white' }} style={StyleSheet.flatten(STYLES.FOOTER_BG)}>
        <XButton
          flex={1}
          variant='outline'
          // style={StyleSheet.flatten([btnOnMode(CONS.BTN_MODE.ACTIVE_SECONDARY), s.webResetBtn])}
          onPress={this.onPressResetCamera}>
          {/* <XText
              style={{
                fontSize: STYLES.FONT_SIZE_LARGER,
                color: COLORS.TEXT_PRIMARY,
              }}> */}
          สแกนใหม่
          {/* </XText> */}
        </XButton>
        {/* Add a filler view to add a gap b/w the buttons สแกนใหม่ และ จัดส่ง. A Must Have to prevent mispress */}
        <View style={{ flex: 1 }} />
        {isAvailableToShip ? (
          <XButton
            flex={1}
            // bordered={true}
            // disabled={isAvailableToShip}
            // @ts-ignore
            // style={StyleSheet.flatten([btnOnMode(CONS.BTN_MODE.ACTIVE_PRIMARY), s.webShipBtn])}
            onPress={this.onPressSubmitShipping}>
            {/* <XText style={textOnBtnMode(CONS.BTN_MODE.ACTIVE_PRIMARY)}> */}
            จัดส่ง
            {/* </XText> */}
          </XButton>
        ) : (
          <View style={{ flex: 1 }} />
        )}
      </HStack>
    )
  }

  onPressResetCamera = async () => {
    // const tracking_number = this.props.shipmentData.get('tracking_number')
    // console.log('tracking_number', tracking_number)
    if (this._isShippable()) {
      p.op.showConfirmation(
        'คุณยังไม่ได้แจ้งจัดส่ง',
        `หากต้องการบันทึกการจัดส่งนี้ กรุณากด "ปิด" แล้วกด "จัดส่ง" (ด้านขวาล่าง)`,
        () => {
          //
        },
        async () => {
          await this.doResetCamera()
        },
        'ปิด',
        'สแกนใหม่'
      )
    } else {
      await this.doResetCamera()
    }
  }

  doResetCamera = async () => {
    log('รีเซ็ตกล้อง')
    this.props.qsReset()
    await setStatePromise(this, {
      textOptionOrder: '',
      textOptionTracking: '',
    })

    if (p.op.isWeb()) {
      this.props.navigation.popToTop()
    }
  }

  onPressSubmitShipping = async () => {
    log('จัดส่ง')
    const { shipmentData, qsShip } = this.props
    const { optionOrder, optionTracking } = this.state
    const body: any = {
      order_id: shipmentData.get('order_id'),
      store_id: shipmentData.get('store_id'),
      shipping_type_id: parseInt(shipmentData.get('shipping_type_id')),
      note: shipmentData.get('note'),
    }

    if (optionTracking !== OPTION_TRACKING_MODE.XSHIPPING) {
      body.products = shipmentData.get('products').toJS()
      body.tracking_number = shipmentData.get('tracking_number')
    } else {
      const availableTrackingToShipList = this._getXShippingAvailableToShipTrackingNumbers()
      if (!availableTrackingToShipList.size) {
        p.op.showToast('เกิดข้อผิดพลาด ไม่มีหมายเลขพัสดุที่จัดส่งได้', 'danger')
        return
      }
      if (availableTrackingToShipList.size > 1) {
        body.tracking_numbers = availableTrackingToShipList.toArray()
      } else {
        body.tracking_number = availableTrackingToShipList.get(0)
        body.products = shipmentData.get('products').toJS()
      }
    }

    qsShip(body)
    await p.op.storageSet(CONS.STORAGE_KEYS.SCAN_ORTION_ORDER, optionOrder)
    await p.op.storageSet(CONS.STORAGE_KEYS.SCAN_ORTION_TRACKING, optionTracking)
    await setStatePromise(this, {
      textOptionOrder: '',
      textOptionTracking: '',
    })
    // Alert.alert('จัดส่งแล้ว')
    if (p.op.isWeb()) {
      this.props.navigation.popToTop()
    }
  }

  // NEW ORDER BOX
  _renderOrderCameraBigBox = ({ state }) => {
    log('_renderOrderCameraBigBox state: ', state)
    const { optionOrder, textOptionOrder } = this.state
    if (state < 0) {
      return this._renderWrapBox({
        txtHeader: 'ข้อมูลใบสั่งซื้อเกิดข้อผิดพลาด',
        txtTop: 'ไม่พบออเดอร์เลขที่นี้\nหรือท่านอาจจะไม่มีสิทธิในการดูออเดอร์',
        textTopStyle: { paddingLeft: 4, paddingRight: 4, fontSize: STYLES.FONT_SIZE_LARGER, textAlign: 'center' },
        jumbotronComponent: (
          <XIcon
            family='MaterialCommunityIcons'
            name='close-circle'
            size='4xl'
            variant='activeDark'
            // @ts-ignore
            // style={StyleSheet.flatten([s.jumbotronText, { color: COLORS.TEXT_ACTIVE_DARK }])}
          />
        ),
        bgType: 'danger',
        txtBottom: textOptionOrder,
        textBottomStyle: { fontSize: STYLES.FONT_SIZE_LARGER_3, color: COLORS.WHITE, paddingLeft: 4, paddingRight: 4, fontWeight: 'bold' },
      })
    }
    if (state === 1) {
      return this._renderWrapBox({
        txtHeader: 'สแกนเลขที่ใบสั่งซื้อ',
        SegmentComponent: (
          <View
            style={{
              // width: '80%',
              width: this.getCameraWidth(),
              paddingTop: 5,
              paddingBottom: 5,
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'center',
            }}>
            {p.op.isWeb() ? null : (
              <Segment options={this.OPTION_MY_ORDER} selectedIndex={optionOrder} onSegmentChange={(val) => this._chooseOptionOrder(val)} />
            )}
          </View>
        ),
        customComponent:
          optionOrder === 1 ? (
            this._renderComponent(KEY_OPTION_MY_ORDER, textOptionOrder)
          ) : (
            <View style={{ flex: 1, justifyContent: 'flex-start', alignItems: 'center' }}>
              <View style={this.getCameraBoxStyle()}>
                {this.renderCamera({ onBarcodeScanned: this.onOrderBarcodeScan })}
                <Image
                  source={barcodeBorder}
                  resizeMode='stretch'
                  style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'transparent',
                    zIndex: 70,
                  }}
                />
                <View
                  style={{
                    position: 'absolute',
                    top: '48%',
                    width: '100%',
                    height: 1,
                    backgroundColor: COLORS.FORM_ERROR,
                    zIndex: 70,
                  }}
                />
                <View
                  style={{
                    position: 'absolute',
                    top: '52%',
                    width: '100%',
                    height: 1,
                    backgroundColor: COLORS.FORM_ERROR,
                    zIndex: 70,
                  }}
                />
              </View>
            </View>
          ),
      })
    }
    if (state === 2) {
      return this._renderWrapBox({
        txtHeader: 'โหลดใบสั่งซื้อ',
        txtTop: 'กำลังโหลดข้อมูลใบสั่งซื้อ กรุณารอสักครู่',
        jumbotronComponent: <XSpinner color={COLORS.FORM_PRIMARY} />,
        // bgType: 'danger',
      })
    }
    if (state === 3) {
      const { selectedOrder } = this.props
      const orderName = selectedOrder.get('name') || 'ไม่มี'
      return this._renderWrapBox({
        txtHeader: `ใบสั่งซื้อ #${orderName}`,
        txtTop: 'ไม่มีสินค้าที่คุณต้องจัดส่ง',
        txtJumbotron: '0',
        txtBottom: '(กรุณาสแกนใหม่อีกครั้ง)',
        bgType: 'danger',
      })
    }
    if (state === 8 || state === 9) {
      const { selectedOrder } = this.props
      const orderName = selectedOrder.get('name') || 'ไม่มี'
      const orderType = selectedOrder.get('type') || null

      let myShippedProducts
      let myQtyProducts
      let myWaitingShipProducts
      // Ny Shipping Products
      const products = selectedOrder.get('products') || List([])
      if (products.size > 0 && orderType !== 3) {
        myShippedProducts = products.reduce((prevQty, product) => prevQty + product.get('shipped_qty'), 0)
        myQtyProducts = products.reduce((prevQty, product) => prevQty + product.get('qty'), 0)
        myWaitingShipProducts = myQtyProducts - myShippedProducts
      } else {
        myWaitingShipProducts = 0
      }

      let bgType
      if (myWaitingShipProducts > 0) {
        bgType = state === 9 ? 'success' : 'warning'
      } else {
        bgType = 'danger'
      }

      return this._renderWrapBox({
        txtHeader: `ใบสั่งซื้อ #${orderName}`,
        txtTop: 'จำนวนที่ต้องจัดส่ง',
        txtJumbotron: myWaitingShipProducts.toString() || '0',
        txtBottom: state === 8 ? '(กรุณาสแกนเลขติดตามพัสดุด้านล่าง)' : '',
        bgType,
      })
    }
    if (state >= 11 && state <= 13) {
      // 1 คือยกเลิกโดยฉัน / 2 คือยกเลิกโดยตัวทน / 3 คือยกเลิกโดยลูกค้า
      let txtError
      switch (state) {
        case 11:
          txtError = 'ใบสั่งซื้อนี้ถูกยกเลิกโดยฉัน'
          break
        case 22:
          txtError = 'ใบสั่งซื้อนี้ถูกยกเลิกโดยตัวแทน'
          break
        case 33:
          txtError = 'ใบสั่งซื้อนี้ถูกยกเลิกโดยลูกค้า'
          break
        default:
          txtError = 'ใบสั่งซื้อนี้ถูกยกเลิก'
      }
      return this._renderWrapBox({
        txtHeader: 'ไม่สามารถจัดส่ง',
        txtTop: txtError,
        jumbotronComponent: (
          <XIcon
            family='MaterialCommunityIcons'
            name='close-circle'
            size='4xl'
            variant='activeDark'
            // @ts-ignore
            // style={StyleSheet.flatten([s.jumbotronText, { color: COLORS.TEXT_ACTIVE_DARK }])}
          />
        ),
        bgType: 'danger',
      })
    }

    // Should be impossible case because we force them scan Order first!!!
    return this._renderWrapBox({
      txtHeader: 'สแกนใบสั่งซื้อ',
      txtTop: 'รอสแกนใบสั่งซื้อ',
      jumbotronComponent: (
        <XIcon
          family='MaterialCommunityIcons'
          name='barcode-scan'
          variant='inactive'
          size='4xl'
          // @ts-ignore
          // style={StyleSheet.flatten([s.jumbotronText, { color: COLORS.TEXT_INACTIVE }])}
        />
      ),
      bgType: 'warning',
    })
  }

  // NEW SHIPMENT BOX
  _renderShipCameraBigBox({ state }) {
    log('_renderShipCameraBigBox state: ', state)
    const { optionTracking, textOptionTracking } = this.state
    // let selectedOptionTracking = optionTracking
    // if (p.op.isWeb()) {
    //   selectedOptionTracking = optionTracking + 1
    // }
    if (state < 0) {
      if (optionTracking === OPTION_TRACKING_MODE.XSHIPPING) {
        return this._renderXShippingOrderTracking()
      }
      return this._renderWrapBox({
        txtHeader: 'การจัดส่ง',
        txtTop: 'ไม่มีสินค้าที่ท่านต้องจัดส่ง',
        jumbotronComponent: (
          <XIcon
            family='MaterialCommunityIcons'
            name='close-circle'
            size='4xl'
            variant='activeDark'
            // @ts-ignore
            // style={StyleSheet.flatten([s.jumbotronText, { color: COLORS.TEXT_ACTIVE_DARK }])}
          />
        ),
        bgType: 'danger',
      })
    }
    if (state === 1) {
      return this._renderWrapBox({
        txtHeader: 'สแกนเลขติดตามพัสดุ',
        SegmentComponent: (
          <View
            style={{
              width: this.getCameraWidth(),
              paddingTop: 5,
              paddingBottom: 5,
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'center',
            }}>
            <Segment
              options={this.OPTION_TRACKING}
              // selectedIndex={optionTracking}
              selectedIndex={optionTracking}
              onSegmentChange={(val) => this._chooseOptionTracking(val)}
            />
          </View>
        ),
        customComponent:
          optionTracking === OPTION_TRACKING_MODE.SCAN && !p.op.isWeb() ? (
            <View style={{ flex: 1, justifyContent: 'flex-start', alignItems: 'center' }}>
              <View style={this.getCameraBoxStyle()}>
                {this.renderCamera({ onBarcodeScanned: this.onShipBarcodeScan, isShipCamera: true })}
                <Image
                  source={barcodeBorder}
                  resizeMode='stretch'
                  style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'transparent',
                    zIndex: 70,
                  }}
                />
                <View
                  style={{
                    position: 'absolute',
                    top: '48%',
                    width: '100%',
                    height: 1,
                    backgroundColor: COLORS.FORM_ERROR,
                    zIndex: 70,
                  }}
                />
                <View
                  style={{
                    position: 'absolute',
                    top: '52%',
                    width: '100%',
                    height: 1,
                    backgroundColor: COLORS.FORM_ERROR,
                    zIndex: 70,
                  }}
                />
              </View>
            </View>
          ) : _.includes([OPTION_TRACKING_MODE.TYPING, OPTION_TRACKING_MODE.SCAN], optionTracking) ? (
            this._renderComponent(KEY_OPTION_TRACKING, textOptionTracking)
          ) : // ) : optionTracking === OPTION_TRACKING_MODE.XSHIPPING ? (
          //   this._renderXShippingOrderTracking()
          // ) : optionTracking === OPTION_TRACKING_MODE.NOT_SPECIFIED ? (
          //   this._renderNotChoose()
          null,
      })
    }

    if (state === 2) {
      return this._renderWrapBox({
        txtHeader: 'สแกนเลขติดตามพัสดุ',
        txtTop: 'กำลังโหลดข้อมูลใบสั่งซื้อ กรุณารอสักครู่',
        jumbotronComponent: <XSpinner color={COLORS.FORM_PRIMARY} />,
      })
    }

    if (state === 8) {
      return this._renderWrapBox({
        txtHeader: 'การจัดส่ง',
        txtTop: 'ไม่มีสินค้าที่คุณต้องจัดส่ง',
        // txtJumbotron: '0',
        jumbotronComponent: (
          <XIcon
            family='MaterialCommunityIcons'
            name='close-circle'
            size='4xl'
            variant='activeDark'
            // @ts-ignore
            // style={StyleSheet.flatten([s.jumbotronText, { color: COLORS.TEXT_ACTIVE_DARK }])}
          />
        ),
        txtBottom: '(กรุณาสแกนใหม่อีกครั้ง)',
        bgType: 'danger',
      })
    }

    if (state === 9) {
      const { shipmentData, selectedOrder } = this.props
      const orderType = selectedOrder.get('type')
      const products = shipmentData.get('products')
      const tracking_number = shipmentData.get('tracking_number') || 'ไม่มีเลขพัสดุ'
      if (optionTracking === OPTION_TRACKING_MODE.XSHIPPING) {
        return this._renderXShippingOrderTracking()
      }
      let productCanShipQty = 0

      if (orderType >= 1 && orderType < 3) {
        // ถ้าเป็น type 1, 2
        productCanShipQty = products.reduce((prevQty, product) => prevQty + product.get('qty'), 0)
      }

      if (productCanShipQty <= 0) {
        return this._renderWrapBox({
          txtHeader: 'การจัดส่ง',
          txtTop: 'ไม่มีสินค้าที่ท่านต้องจัดส่ง',
          jumbotronComponent: (
            <XIcon
              family='MaterialCommunityIcons'
              name='close-circle'
              size='4xl'
              variant='activeDark'
              // @ts-ignore
              // style={StyleSheet.flatten([s.jumbotronText, { color: COLORS.TEXT_ACTIVE_DARK }])}
            />
          ),
          bgType: 'danger',
        })
      }

      return this._renderWrapBox({
        key: this.state.optionTracking,
        txtHeader: 'จัดส่งสินค้า',
        txtTop: 'เลขติดตามพัสดุเลขที่',
        txtJumbotron: tracking_number.toString(),
        textJumbotronStyle: { fontSize: STYLES.FONT_ICON_LARGEST },
        txtBottom: '(กรุณากดจัดส่งเพื่อส่งของทั้งหมด)',
        bgType: 'success',
        SegmentComponent: (
          <View
            style={{
              width: this.getCameraWidth(),
              paddingTop: 5,
              paddingBottom: 5,
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'center',
            }}>
            <Segment
              options={this.OPTION_TRACKING}
              selectedIndex={optionTracking}
              onSegmentChange={(val) => this._chooseOptionTracking(val)}
            />
          </View>
        ),
      })
    }

    // Should be impossible case because we force them scan Order first!!!
    return this._renderWrapBox({
      key: this.state.optionTracking,
      txtHeader: 'สแกนเลขติดตามพัสดุ',
      txtTop: 'รอสแกนเลขติดตามพัสดุ',
      jumbotronComponent: (
        <XIcon
          family='MaterialCommunityIcons'
          name='barcode-scan'
          variant='inactive'
          size='4xl'
          // // @ts-ignore
          // style={StyleSheet.flatten([s.jumbotronText, { color: COLORS.TEXT_INACTIVE }])}
        />
      ),
      txtBottom: '(กรุณาสแกนใบสั่งซื้อก่อน)',
      bgType: 'warning',
      // customComponent:
      //   optionTracking === 0 ? null : optionTracking === 1 ? this._renderComponent(KEY_OPTION_TRACKING, textOptionTracking) : <View />,
    })
  }

  _isShippingTypeIdEligibleToXShipping = (shippingTypeId: number) => {
    const { selectedStore } = this.props
    const isEnableXShipping = selectedStore.get('s_use_xshipping')
    if (!isEnableXShipping) {
      return false
    }
    const xshipping = selectedStore.get('xshipping')
    if (!List.isList(xshipping) || xshipping.size === 0) {
      return false
    }
    for (let i = 0; i < xshipping.size; i++) {
      const xShippingTypeId = xshipping.getIn([i, 'shipping_type_id'])
      if (xShippingTypeId === shippingTypeId) {
        return true
      }
    }
    return false
  }

  _renderXShippingOrderTracking = () => {
    const { selectedStore, shipmentData } = this.props
    const currentShippingTypeId = shipmentData.get('shipping_type_id')

    const isEnableXShippingFeature = selectedStore.get('s_use_xshipping')
    const availableTrackingToShipList = this._getXShippingAvailableToShipTrackingNumbers()

    let bgType = null
    let txtBottom = null
    let txtTop = null
    let txtJumbotron = null
    let textJumbotronStyle = {}
    const trackingSize = availableTrackingToShipList.size
    if (trackingSize > 5) {
      bgType = 'success'
      txtTop = `พบ ${trackingSize} เลขติดตามพัสดุจาก XShipping`
      txtBottom = '(กรุณากดจัดส่งเพื่อส่งของทั้งหมด)'
      txtJumbotron = ''
      for (let i = 0; i < 3; i++) {
        txtJumbotron += `${i + 1}) ${availableTrackingToShipList.get(i)}`
        txtJumbotron += '\n'
      }
      txtJumbotron += `...\nและเลขอื่นๆ อีก ${trackingSize - 3} เลข`
      textJumbotronStyle = { fontSize: STYLES.FONT_SIZE_HUGE, textAlign: 'left' }
    } else if (trackingSize > 1 && trackingSize <= 5) {
      bgType = 'success'
      txtTop = `พบ ${trackingSize} เลขติดตามพัสดุจาก XShipping`
      txtBottom = '(กรุณากดจัดส่งเพื่อส่งของทั้งหมด)'
      txtJumbotron = ''
      availableTrackingToShipList.toArray().forEach((trackingNumber, index) => {
        txtJumbotron += `${index + 1}) ${trackingNumber}`
        if (index < trackingSize) {
          txtJumbotron += '\n'
        }
      })
      textJumbotronStyle = { fontSize: STYLES.FONT_SIZE_HUGE, textAlign: 'left' }
    } else if (trackingSize === 1) {
      bgType = 'success'
      txtTop = 'พบ 1 เลขติดตามพัสดุจาก XShipping'
      txtBottom = '(กรุณากดจัดส่งเพื่อส่งของทั้งหมด)'
      txtJumbotron = availableTrackingToShipList.get(0)
      textJumbotronStyle = { fontSize: STYLES.FONT_SIZE_HUGE }
    } else if (trackingSize === 0 && !isEnableXShippingFeature) {
      // bgType = 'warning'
      txtTop = 'ร้านนี้ไม่ได้เปิดใช้ XShipping'
      txtBottom = '(หากสนใจฟีเจอร์ XShipping เพื่อออกเลขพัสดุอัตโนมัติ กรุณาติดต่อทีมงาน)'
    } else if (trackingSize === 0 && isEnableXShippingFeature && this._isShippingTypeIdEligibleToXShipping(currentShippingTypeId)) {
      txtTop = 'ร้านนี้ใช้งาน XShipping'
      // bgType = 'warning'
      txtBottom = 'ยังไม่มีเลขพัสดุพร้อมส่งที่มาจากระบบ'
    } else if (trackingSize === 0 && isEnableXShippingFeature && !this._isShippingTypeIdEligibleToXShipping(currentShippingTypeId)) {
      txtTop = 'ร้านนี้ใช้งาน XShipping แต่ออเดอร์นี้ไม่ใช้'
      // bgType = 'warning'
      txtBottom = 'ประเภทจัดส่งที่ระบุในออเดอร์นี้\nไม่ได้เปิดการใช้งาน XShipping ไว้'
    } else {
      // bgType = 'warning'
      txtBottom = '(ยังไม่มีเลขพัสดุพร้อมส่งที่มาจากระบบ)'
    }

    return this._renderWrapBox({
      txtHeader: 'จัดส่งสินค้า',
      txtTop,
      txtJumbotron,
      txtBottom,
      bgType,
      textTopStyle: {},
      textJumbotronStyle,
      textBottomStyle: {},
      jumbotronComponent: null,
      SegmentComponent: (
        <View
          style={{
            width: this.getCameraWidth(),
            paddingTop: 5,
            paddingBottom: 5,
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf: 'center',
          }}>
          <Segment
            options={this.OPTION_TRACKING}
            selectedIndex={this.state.optionTracking}
            onSegmentChange={(val) => this._chooseOptionTracking(val)}
          />
        </View>
      ),
    })

    // return (
    //   <View
    //     style={{
    //       flex: 1,
    //       justifyContent: 'flex-start',
    //       alignItems: 'center',
    //       marginBottom: 10,
    //     }}>
    //     {this._renderBigBoxInfo({
    //       txtTop,
    //       txtJumbotron,
    //       txtBottom,
    //       bgType,
    //       textTopStyle: {},
    //       textJumbotronStyle,
    //       textBottomStyle: {},
    //       jumbotronComponent: null,
    //     })}
    //   </View>
    // )
  }

  // New Wrap Card
  _renderWrapBox = (params: {
    key?: any
    txtHeader: string
    txtTop?: string
    txtJumbotron?: string
    txtBottom?: string
    bgType?: 'success' | 'warning' | 'danger'
    textTopStyle?: TextStyle
    textJumbotronStyle?: TextStyle
    jumbotronContainerStyle?: ViewStyle
    textBottomStyle?: TextStyle
    customComponent?: JSX.Element
    jumbotronComponent?: JSX.Element
    SegmentComponent?: JSX.Element
  }) => {
    const {
      key,
      txtHeader,
      customComponent,
      txtTop,
      txtJumbotron,
      txtBottom,
      bgType,
      textTopStyle,
      textJumbotronStyle,
      jumbotronContainerStyle,
      textBottomStyle,
      jumbotronComponent,
      SegmentComponent,
    } = params
    return (
      <XCard key={key}>
        <XCard.Body>
          <View
            style={{
              flex: 1,
              flexBasis: 'auto',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              ...Platform.select({
                web: {
                  // minHeight: 230,
                  minHeight: 267,
                },
              }),
            }}>
            <View style={{ flex: 0, flexBasis: 'auto', alignSelf: 'center', paddingTop: 5, paddingBottom: 5 }}>
              <XText
                style={{
                  fontSize: STYLES.FONT_SIZE_LARGER,
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}>
                {txtHeader}
              </XText>
            </View>
            {SegmentComponent || null}
            <View
              style={{
                flex: 1,
                flexBasis: 'auto',
                justifyContent: 'flex-start',
                alignItems: 'center',
                marginBottom: 10,
              }}>
              {customComponent ||
                this._renderBigBoxInfo({
                  txtTop,
                  txtJumbotron,
                  txtBottom,
                  bgType,
                  textTopStyle,
                  textJumbotronStyle,
                  jumbotronContainerStyle,
                  textBottomStyle,
                  jumbotronComponent,
                })}
            </View>
          </View>
        </XCard.Body>
      </XCard>
    )
  }

  // fontSize 80 รับได้ 4 digit ที่จอ iphone 5s
  _renderBigBoxInfo({
    txtTop,
    txtJumbotron,
    txtBottom,
    bgType,
    textTopStyle,
    textJumbotronStyle,
    textBottomStyle,
    jumbotronComponent,
    jumbotronContainerStyle = {},
  }) {
    const flexCenterStyle: ViewStyle = { flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }
    let boxBackgroundColor
    let txtInfoColor
    switch (bgType) {
      case 'success':
        boxBackgroundColor = COLORS.BOX_SUCCESS
        txtInfoColor = COLORS.TEXT_INACTIVE
        break
      case 'warning':
        boxBackgroundColor = COLORS.FORM_WARNING_BG
        txtInfoColor = COLORS.TEXT_INACTIVE
        break
      case 'danger':
        boxBackgroundColor = COLORS.FORM_ERROR
        txtInfoColor = COLORS.TEXT_ACTIVE_DARK
        break
      default:
        boxBackgroundColor = COLORS.FORM_INACTIVE
    }
    return (
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          flexBasis: 'auto',
        }}>
        <View
          style={[
            this.getCameraBoxStyle(),
            {
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              borderWidth: 1,
              borderColor: COLORS.TEXT_INACTIVE,
              backgroundColor: boxBackgroundColor,
            },
          ]}>
          <View style={[{ flex: 2 }, flexCenterStyle, STYLES.NO_MARGIN_PADDING]}>
            <XText style={[{ fontSize: STYLES.FONT_SIZE_NORMAL, color: txtInfoColor }, STYLES.NO_MARGIN_PADDING, textTopStyle || {}]}>
              {txtTop || ''}
            </XText>
          </View>

          <View style={[{ flex: 4 }, flexCenterStyle, STYLES.NO_MARGIN_PADDING, jumbotronContainerStyle]}>
            {jumbotronComponent || (
              <XText
                style={StyleSheet.flatten([
                  s.jumbotronText,
                  STYLES.NO_MARGIN_PADDING,
                  {
                    color: COLORS.TEXT_PRIMARY,
                  },
                  textJumbotronStyle || {},
                ])}>
                {txtJumbotron || ''}
              </XText>
            )}
          </View>

          <View style={[{ flex: 2 }, flexCenterStyle, STYLES.NO_MARGIN_PADDING]}>
            <XText style={[{ fontSize: STYLES.FONT_SIZE_SMALLER, color: txtInfoColor }, STYLES.NO_MARGIN_PADDING, textBottomStyle || {}]}>
              {txtBottom || ''}
            </XText>
          </View>
        </View>
      </View>
    )
  }

  _chooseOptionOrder = async (val: number) => {
    let autoFocusInputOrder = false
    if (val === 1) {
      autoFocusInputOrder = true
    }
    setStatePromise(this, {
      optionOrder: val,
      autoFocusInputOrder,
    })
  }

  _chooseOptionTracking = async (val: number) => {
    const newIndex = val
    // if (p.op.isWeb()) {
    //   newIndex = val + 1
    // }

    let autoFocusInputTracking = false

    if (newIndex === OPTION_TRACKING_MODE.TYPING) {
      autoFocusInputTracking = true
    }
    await this._processOptionTrackingAfterLoadOrder(newIndex)
    await setStatePromise(this, {
      optionTracking: newIndex,
      autoFocusInputTracking,
    })
  }

  _getXShippingAvailableToShipTrackingNumbers = () => {
    const { selectedOrder = Map({}) } = this.props
    // const x = selectedOrder.get('x')
    const orderGenList = selectedOrder.getIn(['x', 'o']) || List([])
    let availableToShipList = List([])
    orderGenList.forEach((orderGenItem) => {
      const trackingState = orderGenItem.get('s')
      // const cancellable = orderGenItem.get('c')
      // if (!_.isNil(cancellable) && cancellable) {
      //   availableToShipList = availableToShipList.push(orderGenItem)
      // }
      if (this._isXShippingTrackingShipable(trackingState)) {
        availableToShipList = availableToShipList.push(orderGenItem)
      }
    })
    return availableToShipList.map((availableItem) => availableItem.get('t'))
  }

  // Returns true if the provided state is valid for creating order's shipping i.e. already have tracking code and not cancelled nor error.
  _isXShippingTrackingShipable = (trackingState: number) =>
    // https://docs.google.com/spreadsheets/d/1F2ebfuCyFB4a3Z6g0MUZRocMvjNvnoyVkrKwS6Am-xw/edit?usp=sharing
    // อันนี้เป็น ชีท Courier Order State ตัว tracking code ที่ให้ยิงเลขพัสดุได้ ให้ดูที่ column "TrackingCodePrintable?" นะครับ
    // ได้ทั้ง YES และ YES if DB has it
    // สรุป ก็จะมีเลข
    // 1809
    // 2010 - 2xxx
    // 9001 - 9xxx แต่ไม่เท่ากับ 9099 (unknown error)
    trackingState === 1809 ||
    (trackingState >= 2010 && trackingState <= 2999) ||
    (trackingState >= 9001 && trackingState <= 9299 && trackingState != 9099)

  _renderComponent = (key: string, value?: string | number) => {
    const { autoFocusInputOrder, autoFocusInputTracking } = this.state
    const { navigation } = this.props
    if (!navigation) {
      return null
    }
    const store_id = getNavParam(this.props, 'store_id')
    if (key === KEY_OPTION_MY_ORDER) {
      return (
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 5,
            flexDirection: 'column',
            minHeight: 260,
            ...Platform.select({
              web: {
                width: '100%',
                minHeight: 160,
              },
            }),
          }}>
          <View
            // @ts-ignore
            style={{
              width: this.getCameraWidth(),
              alignItems: 'center',
              justifyContent: 'center',
              // flexDirection: 'row',
              flexDirection: 'column',
              ...Platform.select({
                web: {
                  flex: 1,
                  // padding: 12,
                  flexWrap: 'wrap',
                },
              }),
            }}>
            <View style={[s.webFlexBasis, { flex: 1, flexDirection: 'column', width: '100%' }]}>
              <View style={[s.webFlexBasis, { minHeight: 30, flex: 1, flexDirection: 'column', width: '100%' }]}>
                {p.op.isWeb() ? this.webInjectOrderBarcodeListener() : null}
                <XInput
                  style={{
                    width: '100%',
                    ...Platform.select({
                      web: {
                        flex: 0,
                        height: 54,
                      },
                    }),
                  }}
                  // maxLength={ inputMaxLength || null }
                  // autoCorrect={false}
                  // autoCapitalize={'none'}
                  placeholder='พิมพ์เลขออเดอร์ที่ต้องการแจ้งจัดส่ง เช่น 654123'
                  // placeholderTextColor={COLORS.TEXT_INACTIVE}
                  value={_.isString(value) ? value : value.toString()}
                  keyboardType='numeric'
                  // onSubmitEditing={alert('WOwwwwwwwww')}
                  // selectTextOnFocus={false}
                  onSubmitEditing={(event) => {
                    if (_.isNil(value) || value === '') {
                      p.op.showConfirmationOkOnly(null, 'กรุณาระบบเลขที่ใบสั่งซื้อ')
                      return
                    }
                    const orderId = _.isString(value) && !_.isNaN(value) ? parseInt(value) : value
                    // @ts-ignore orderId should be number
                    // this.props.fetchOrderFromBarcode({ store_id, order_id: orderId })
                    this._fetchOrderFromBarcode(orderId)
                  }}
                  returnKeyType='next'
                  // blurOnSubmit={false}
                  // onRef={r => util.pushNativeBaseRef(this.inputRefs, KEY_OPTION_MY_ORDER, r)}
                  autoFocus={autoFocusInputOrder}
                  onChangeText={this._handleOrderInputChange}
                />
                {_.isNil(value) || value === '' ? null : (
                  <View style={{ position: 'absolute', width: 30, top: 0, right: 6 }}>
                    <TouchableOpacity onPress={() => this._resetText(KEY_OPTION_MY_ORDER)}>
                      <XIcon
                        family='Ionicons'
                        style={{
                          paddingTop: 6,
                          color: COLORS.TEXT_INACTIVE,
                          fontSize: STYLES.FONT_ICON_NORMAL,
                        }}
                        name='close-circle'
                      />
                    </TouchableOpacity>
                  </View>
                )}
                <XText style={{ fontSize: STYLES.FONT_SIZE_SMALLEST, color: COLORS.TEXT_INACTIVE, marginTop: 0, textAlign: 'center' }}>
                  (หากระบุเลขออเดอร์เสร็จแล้ว กรุณากดปุ่ม "ยืนยัน")
                </XText>
              </View>
            </View>
            <TouchableOpacity
              onPress={() => {
                if (_.isNil(value) || value === '') {
                  p.op.showConfirmationOkOnly(null, 'กรุณาระบบเลขที่ใบสั่งซื้อ')
                  return
                }
                const orderId = _.isString(value) && !_.isNaN(value) ? parseInt(value) : value
                // @ts-ignore orderId should be number
                // this.props.fetchOrderFromBarcode({ store_id, order_id: orderId })
                this._fetchOrderFromBarcode(orderId)
              }}
              style={{
                width: 120,
                height: 20,
                borderColor: COLORS.APP_MAIN,
                backgroundColor: COLORS.APP_MAIN,
                borderRadius: 7,
                borderWidth: 0.8,
                marginTop: 20,
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <XText style={{ fontSize: STYLES.FONT_SIZE_NORMAL, justifyContent: 'center', marginTop: 0, color: '#fff' }}>ยืนยัน</XText>
            </TouchableOpacity>
            {p.op.isWeb() ? (
              <View
                style={[
                  s.webFlexBasis,
                  {
                    flex: 1,
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    width: '100%',
                    paddingTop: 80,
                  },
                ]}>
                <XText
                  style={{
                    fontSize: STYLES.FONT_SIZE_SMALLER,
                    color: COLORS.TEXT_INACTIVE,
                  }}>
                  หมายเหตุ: คุณสามารถต่อปีนยิงบาร์โค้ดกับ PC/Mac แล้วยิงได้เลย โดยหน้าต่างเว็ปนี้ต้องถูกเลือกอยู่บนสุด (เช่น
                  คลิ๊กบริเวณใดบริเวณนึงในหน้าต่างนี้)
                </XText>
              </View>
            ) : null}
          </View>
        </View>
      )
    }
    if (key === KEY_OPTION_TRACKING && !_.isNil(value)) {
      return (
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 5,
            minHeight: 260,
            ...Platform.select({
              web: {
                width: '100%',
                minHeight: 160,
              },
            }),
          }}>
          <View
            style={{
              width: this.getCameraWidth(),
              alignItems: 'center',
              ...Platform.select({
                web: {
                  flex: 1,
                  width: '100%',
                  padding: 12,
                },
              }),
            }}>
            <View style={[s.webWidth100Percent, s.webFlexBasis, { flex: 1, flexDirection: 'row' }]}>
              <View style={[s.webWidth100Percent, s.webFlexBasis, { height: 30, flex: 1 }]}>
                {p.op.isWeb() ? this.webInjectTrackingBarcodeListener() : null}
                <XInput
                  // style={{flex: 1, }}
                  // maxLength={ inputMaxLength || null }
                  // autoCorrect={false}
                  // autoCapitalize={'none'}
                  placeholder='พิมพ์เลขพัสดุเพื่อบันทึกในระบบ'
                  // placeholderTextColor={COLORS.TEXT_INACTIVE}
                  value={_.isString(value) ? value : value.toString()}
                  keyboardType='default'
                  onSubmitEditing={(event) => {
                    if (_.isNil(value) || value === '') {
                      p.op.showConfirmationOkOnly(null, 'กรุณาระบบเลขพัสดุ หากท่านไม่ต้องการระบุ กรุณาเลือกตัวเลือก "ไม่ระบุ"')
                      return
                    }
                    this.onShipBarcodeScan({ data: value })
                  }}
                  returnKeyType='next'
                  // selectTextOnFocus={false}
                  // returnKeyType={'done'}
                  // blurOnSubmit={false}
                  // onRef={r => util.pushNativeBaseRef(this.inputRefs, KEY_OPTION_TRACKING, r)}
                  autoFocus={autoFocusInputTracking}
                  onChangeText={this._handleTrackingInputChange}
                />
                <XText style={{ fontSize: STYLES.FONT_SIZE_SMALLEST, color: COLORS.TEXT_INACTIVE, marginTop: 0, textAlign: 'center' }}>
                  (หากระบุเลขพัสดุเสร็จแล้ว กรุณากดปุ่ม "ยืนยัน")
                </XText>
              </View>

              {_.isNil(value) || value === '' ? null : (
                <View style={{ width: 30, marginLeft: -35 }}>
                  <TouchableOpacity onPress={() => this._resetText(KEY_OPTION_TRACKING)}>
                    <XIcon
                      family='Ionicons'
                      style={{
                        paddingTop: 6,
                        color: COLORS.TEXT_INACTIVE,
                        fontSize: STYLES.FONT_ICON_NORMAL,
                      }}
                      name='close-circle'
                    />
                  </TouchableOpacity>
                </View>
              )}
            </View>
            <TouchableOpacity
              onPress={() => {
                if (_.isNil(value) || value === '') {
                  p.op.showConfirmationOkOnly(null, 'กรุณาระบบเลขพัสดุ หากท่านไม่ต้องการระบุ กรุณาเลือกตัวเลือก "ไม่ระบุ"')
                  return
                }
                this.onShipBarcodeScan({ data: value })
              }}
              style={{
                width: 120,
                height: 20,
                borderColor: COLORS.APP_MAIN,
                backgroundColor: COLORS.APP_MAIN,
                borderRadius: 7,
                borderWidth: 0.8,
                marginTop: 20,
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <XText style={{ fontSize: STYLES.FONT_SIZE_NORMAL, justifyContent: 'center', marginTop: 0, color: '#fff' }}>ยืนยัน</XText>
            </TouchableOpacity>
          </View>
        </View>
      )
    }
  }

  _resetText = (key: string) => {
    if (key === KEY_OPTION_MY_ORDER) {
      setStatePromise(this, {
        textOptionOrder: '',
      })
    } else if (key === KEY_OPTION_TRACKING) {
      setStatePromise(this, {
        textOptionTracking: '',
      })
    }
  }

  // _renderNotChoose = () => {
  //   this.onShipBarcodeScan({ data: '-' })
  //   return null
  // }

  onPressGoBackAction = () => this._handlePressBackBtn()

  _renderCustomHeader = () => (
    <XCustomHeader
      title='สแกนใบปะหน้าพัสดุเพื่อแจ้งจัดส่ง'
      headerLeftProps={p.op.isWeb() ? undefined : { backIcon: true, onPressItem: this._handlePressBackBtn }}
    />
  )

  _handleOrderInputChange = async (newText) => {
    await setStatePromise(this, { textOptionOrder: newText })
  }

  _handleTrackingInputChange = async (newText) => {
    await setStatePromise(this, { textOptionTracking: newText })
  }

  renderMain = () => {
    logRender(this)
    const { optionOrder, optionTracking } = this.state
    const { orderCameraState, shipCameraState } = this.props
    return (
      <XContainer>
        {this._renderCustomHeader()}
        <XContent
          key={`${optionOrder}${optionTracking}`}
          // style={{
          //   flexBasis: 'auto',
          //   margin: 0,
          //   // paddingLeft: 5,
          //   // paddingRight: 5
          // }}
        >
          <VStack w='full' p='2' space='2'>
            {this._renderOrderCameraBigBox({ state: orderCameraState })}
            {this._renderShipCameraBigBox({ state: shipCameraState })}
            {/*
            this._renderWrapCard({
              txtHeader: 'สแกนใบสั่งซื้อ',
              bodyComponent: this._renderOrderCamera({ state: orderCameraState }),
            })
          */}
            {/*
            this._renderWrapCard({
              txtHeader: 'สแกนพัสดุ',
              bodyComponent: this._renderShipCamera({ state: shipCameraState }),
            })
          */}
          </VStack>
        </XContent>
        {this._renderFooter()}
      </XContainer>
    )
  }
}

export const s = StyleSheet.create({
  webFlexBasis: {
    ...Platform.select({
      web: {
        flexBasis: 'auto',
      },
    }),
  },
  webWidth100Percent: {
    ...Platform.select({
      web: {
        width: '100%',
      },
    }),
  },
  container: {
    flex: 1,
    justifyContent: 'space-between',
  },
  content: {
    flex: 1,
    paddingLeft: 10,
    paddingRight: 10,
  },
  gridColumnContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  halfColumnContainer: {
    width: '50%',
    padding: 5,
  },
  pCardImg: { width: '100%', height: 100 },
  productListItem: {
    margin: 0,
    paddingLeft: 5,
    paddingRight: 5,
    marginLeft: 0,
    marginRight: 0,
  },
  formInCard: {
    alignSelf: 'stretch',
    margin: 0,
    padding: 0,
    flex: 1,
  },
  borderPrimary: {
    borderColor: COLORS.FORM_PRIMARY,
  },
  borderError: {
    borderColor: COLORS.FORM_ERROR,
  },
  borderSuccess: {
    borderColor: COLORS.FORM_SUCCESS,
  },
  separator: {
    height: 5,
  },
  cardStyle: {
    elevation: 3,
    width: '100%',
    flex: 1,
  },
  cardBody: {
    width: '100%',
    height: 220,
    flex: 1,
  },
  cardImg: {
    alignSelf: 'center',
    width: '100%',
    height: 200,
  },
  alignCenter: {
    alignSelf: 'center',
  },
  alignRight: {
    alignSelf: 'flex-end',
  },
  txtHeader: {
    fontSize: STYLES.FONT_SIZE_ORDERS_HEADER_ROW2,
    color: COLORS.TEXT_ACTIVE,
    fontWeight: 'bold',
  },
  txtNormal: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_ACTIVE,
  },
  txtMultiple: {
    paddingTop: 20,
    width: '100%',
    height: 100,
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_ACTIVE,
  },
  txtMultipleHL: {
    marginTop: 7,
    width: '100%',
    height: 100,
    fontSize: STYLES.FONT_SIZE_NORMAL,
    backgroundColor: COLORS.FORM_PRIMARY_BG,
    color: COLORS.FORM_PRIMARY,
  },
  txtLabel: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_INACTIVE,
    // color: 'rgba(15,15,15,0.6)',
  },
  txtHLRight: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_PRIMARY,
    backgroundColor: COLORS.FORM_PRIMARY_BG,
    textAlign: 'right',
  },
  labelRight: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_INACTIVE,
    textAlign: 'right',
  },
  txtRight: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_ACTIVE,
    textAlign: 'right',
  },
  iconHL: {
    fontSize: STYLES.FONT_SIZE_NORMAL + 4,
    color: COLORS.TEXT_PRIMARY,
    textAlign: 'right',
  },
  rowBox: {
    borderWidth: 1,
    borderColor: 'lightgrey',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 10,
    paddingBottom: 10,
  },
  colBox50: {
    width: '50%',
    borderWidth: 1,
    borderColor: 'lightgrey',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingTop: 10,
    paddingLeft: 5,
    paddingRight: 5,
    paddingBottom: 10,
  },
  camContainer: {
    width: 250,
    height: 200,
    paddingTop: 5,
  },
  preview: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'red',
  },
  capture: {
    flex: 0,
    backgroundColor: '#fff',
    borderRadius: 5,
    color: '#000',
    padding: 10,
    margin: 40,
  },
  barcodeCutLine1: {
    position: 'absolute',
    top: 96,
    width: '100%',
    height: 2,
    backgroundColor: COLORS.FORM_ERROR_OPACITY,
  },
  barcodeCutLine2: {
    position: 'absolute',
    top: 104,
    width: '100%',
    height: 2,
    backgroundColor: COLORS.FORM_ERROR_OPACITY,
  },
  qsCameraBox: {
    // height: 0.30 * CONS.SCREEN_H,
    // width: 0.8 * CONS.SCREEN_W,
    height: Math.floor(0.3 * Dimensions.get('screen').height),
    width: Math.floor(0.9 * Dimensions.get('screen').width),
    // height: 170,
    // width: 240,
  },
  jumbotronText: {
    fontSize: STYLES.FONT_SIZE_JUMBOTRON,
    minWidth: STYLES.FONT_SIZE_JUMBOTRON,
    textAlign: 'center',
  },
  webResetBtn: {
    ...Platform.select({
      web: {
        marginRight: 1,
        backgroundColor: 'white',
        borderTopWidth: 1,
        borderLeftWidth: 1,
        borderBottomWidth: 1,
        borderRightWidth: 1,
        borderTopColor: COLORS.TEXT_INACTIVE,
        borderLeftColor: COLORS.TEXT_INACTIVE,
        borderBottomColor: COLORS.TEXT_INACTIVE,
        borderRightColor: COLORS.TEXT_INACTIVE,
      },
    }),
  },
  webShipBtn: {
    ...Platform.select({
      web: {
        marginLeft: 1,
        backgroundColor: COLORS.APP_MAIN,
        borderTopWidth: 1,
        borderLeftWidth: 1,
        borderBottomWidth: 1,
        borderRightWidth: 1,
        borderTopColor: COLORS.APP_MAIN,
        borderLeftColor: COLORS.APP_MAIN,
        borderBottomColor: COLORS.APP_MAIN,
        borderRightColor: COLORS.APP_MAIN,
      },
    }),
  },
})
