import React from 'react'
import { TouchableOpacity, Clipboard } from 'react-native'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import * as OrderState from 'x/modules/order/OrderState'
import { STYLES, COLORS } from 'x/config/styles'
// import { isDiff, isDiffAccuracy } from 'x/utils/util'
import { IXShipping, ActionApiParams } from 'x/index'
import p from 'x/config/platform-specific'
import CONS from 'x/config/constants'
import moment from 'moment'
import * as xUtil from 'x/utils/util'
import HelpButton from 'xui/components/HelpButton'
import { fromJS } from 'immutable'
import XSwitch from './XSwitch'
import HStack from './HStack'
import XCard from './XCard'
import XText from './XText'
import XButton from './XButton'
import Box from './Box'
import XSpinner from './XSpinner'

// export declare interface IXShipping {
//   r: number
//   c: number
//   s: number
//   t: string
//   e: string
//   u: string
//   i: number
//   a: number
// }

const SPINNER = {
  fontSize: STYLES.FONT_SIZE_SMALLER,
  width: STYLES.FONT_SIZE_SMALLER,
  height: 34,
  alignSelf: 'center',
  textAlign: 'center',
}

interface XShippingProps {
  data: IXShipping
  orderId: any
  storeId: any
  updateTracking?: (params: ActionApiParams) => void
  cancelTracking?: (params: ActionApiParams) => void
  createTracking?: (params: ActionApiParams) => void
  autoRequestTracking?: (params: ActionApiParams) => void
  // limitRes ไว้บอกว่าจะให้ส่ง o ใน x กลับมากี่ตัว
  limitRes?: number
  callBack?: Function
  orderStatus: number
}

interface XShippingState {
  inProcess: boolean
  loadingWidth: number
  loadingHeight: number
  xshipping: IXShipping

  isExpand: boolean
}

class XShipping extends React.Component<XShippingProps, XShippingState> {
  shippingTypeItems: { [key: string]: any }

  OPTION_REQUEST_TRACKING: string[]

  constructor(props: XShippingProps) {
    super(props)
    this.state = {
      inProcess: false,
      loadingWidth: 0,
      loadingHeight: 0,
      xshipping: null,
      isExpand: false,
    }
    // this._updateNewStyle(props)
    this.shippingTypeItems = CONS.ORDER_VIEW_SHIPPING_TYPE_ITEMS.map((item) => ({
      key: item.index,
      ...item,
      label: p.op.t(`Order.shippingTypeItems.${item.key}`),
    }))
    this.OPTION_REQUEST_TRACKING = ['ขออัตโนมัติ', 'ขอเองภายหลัง', 'ขอทันที']
  }

  // _updateNewStyle = (props: XShippingProps) => {}
  async componentDidMount() {
    const { data } = this.props
    await this.setconfig(data)
  }

  setconfig = async (xshipping: any) => {
    xUtil.setStatePromise(this, { xshipping: fromJS(xshipping) })
  }

  _copyToClipboard(text: string) {
    Clipboard.setString(text)
    p.op.showToast(`คัดลอกเลขพัสดุ ${text} เรียบร้อยแล้ว`, 'success')
  }

  _onPressExpand = (newVal: boolean) => {
    this.setState({
      isExpand: newVal,
    })
  }

  _renderExpand = () => {
    const { isExpand, xshipping } = this.state
    const xshippingFromJS = _.isNil(xshipping) ? null : fromJS(xshipping)
    const xList = xshippingFromJS.has('o') ? xshippingFromJS.get('o') : null
    // console.log('xList => ', xList)
    if (_.isNil(xList) || xList.size < 2) {
      return null
    }
    const label = isExpand ? 'ย่อลง' : 'ดูเพิ่มเติม'
    return (
      <HStack w='full' mt='2' alignItems='center' justifyContent='center'>
        <TouchableOpacity
          onPress={() => this._onPressExpand(!isExpand)}
          style={{ width: 80, alignItems: 'center', justifyContent: 'center' }}>
          <XText variant='inactive' textDecorationLine='underline'>
            {label}
          </XText>
        </TouchableOpacity>
      </HStack>
    )
  }

  _renderXShippingCard = () => {
    const { orderId, storeId, orderStatus } = this.props
    if (_.isNil(orderId) || _.isNil(storeId)) {
      return null
    }
    const { loadingHeight, loadingWidth, xshipping } = this.state
    const xshippingFromJS = _.isNil(xshipping) ? null : fromJS(xshipping)
    if (_.isNil(xshippingFromJS) || xshippingFromJS.size < 1) {
      return null
    }
    const oInXshipping = xshippingFromJS.has('o') ? xshippingFromJS.get('o') : null
    const rInXshipping = xshippingFromJS.has('r') ? xshippingFromJS.get('r') : null
    const aInXshipping = xshippingFromJS.has('r') ? xshippingFromJS.get('r') : null

    if ((_.isNil(oInXshipping) || oInXshipping.size === 0) && _.isNil(rInXshipping) && _.isNil(aInXshipping)) {
      return null
    }
    // const shipping = data.has('p') ? data.get('p') : -1
    const autoRequest = xshippingFromJS.has('a') ? xshippingFromJS.get('a') : null
    const requestable = xshippingFromJS.has('r') ? xshippingFromJS.get('r') : null

    const xList = xshippingFromJS.has('o') ? xshippingFromJS.get('o') : null

    // const switchStype =
    //   Platform.OS === 'ios' ? { transform: [{ scaleX: 0.7 }, { scaleY: 0.6 }], paddingTop: -1, marginTop: -6, marginRight: -5 } : {}

    // const shippingHeaderName = (shipping > -1 && this.shippingTypeItems[shipping].label) || ''

    const a = _.isNil(autoRequest) ? false : autoRequest === 1
    const r = _.isNil(requestable) ? null : requestable === 1
    // const optionRequestTracking = a === true ? 0 : 1

    // const nullAutoRequest = _.isNil(autoRequest) ? true : false
    const bgCard = COLORS.WHITE
    const isSubmitting = this._getSubmittingByOrderId(orderId)

    let shipping = ''
    this.shippingTypeItems.map((sp) => {
      if (sp.value === xshippingFromJS.get('p')) {
        shipping = sp.label
      }
    })

    return (
      <XCard w='full' p='2' pt='1'>
        {!_.isNil(r) && r ? (
          <HStack w='full'>
            <HStack flex={1} alignItems='center'>
              <XText fontSize='xs' variant='inactive'>
                ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง
              </XText>
              <HelpButton
                title='ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง'
                message='ระบบจะขอเลขพัสดุจากขนส่งให้อัตโนมัติเมื่อออเดอร์พร้อมจัดส่ง'
              />
            </HStack>
            <HStack alignItems='center'>
              <XSwitch
                // style={switchStype}
                // disabled={disabled}
                // circleSize={18}
                // barHeight={19}
                value={a}
                onValueChange={(val) => this._autoRequestTracking(val)}
                // circleBorderWidth={1}
                // backgroundActive={COLORS.APP_MAIN}
                // backgroundInactive={COLORS.TEXT_INACTIVE}
                // circleActiveColor={COLORS.WHITE}
                // circleInActiveColor={COLORS.BG_LIGHT_GREY_ALTERNATIVE}
              />
            </HStack>
          </HStack>
        ) : null}
        {!_.isNil(r) && r ? (
          <HStack w='full'>
            <HStack flex={4}>
              <XButton onPress={() => this._createTackingCode()} variant='ghost' bgColor={COLORS.APP_SECONDARY}>
                <XText fontSize='xs' pt='1' color={COLORS.APP_MAIN}>
                  ขอเลขพัสดุทันที
                </XText>
              </XButton>
            </HStack>
            <HStack flex={6} alignItems='center' justifyContent='flex-end'>
              <XText fontSize='xs' variant='active' textAlign='right'>{`${shipping}`}</XText>
            </HStack>
          </HStack>
        ) : null}
        {_.isNil(xList) ? null : xList.map((x: any, index: number) => this._renderXList(x, index))}
        {this._renderExpand()}
        {isSubmitting ? (
          <Box
            bgColor='gray.500:alpha.50'
            position='absolute'
            top='0'
            left='0'
            right='0'
            bottom='0'
            alignItems='center'
            justifyContent='center'>
            <XSpinner size='lg' />
          </Box>
        ) : null}
      </XCard>
    )
  }

  _renderXList = (x: any, index: number) => {
    if (_.isNil(x)) {
      return null
    }
    const { isExpand } = this.state
    if (index > 0 && !isExpand) {
      return null
    }
    // console.log('LB ', this.shippingTypeItems)
    // const { cancelTracking, orderId } = this.props
    // const shippingName = (x.get('p') > -1 && this.shippingTypeItems[x.get('p')].label) || ''
    let shippingName = ''
    this.shippingTypeItems.map((sp) => {
      if (sp.value === x.get('p')) {
        shippingName = sp.label
      }
    })
    const trackingCode = _.isNil(x.get('t')) ? null : x.get('t')
    const updateAt = moment(x.get('u')).fromNow()
    const status = xUtil.getXShippingStatusLabel(x.get('s'))
    const errStatus = _.isNil(x.get('e')) ? null : xUtil.getXShippingStatusLabel(x.get('e'))
    const cancelable = _.isNil(x.get('c')) ? false : x.get('c') === 1
    const note = x.has('j') ? x.getIn(['j', 'msg']) : null

    let bgCard = COLORS.WHITE
    if (!_.isNil(x.get('e'))) {
      bgCard = COLORS.FORM_ERROR_BG
    } else if (_.includes(CONS.XSHIPPING_STATUS_IN_PROCESS, x.get('s'))) {
    } else if (_.includes(CONS.XSHIPPING_STATUS_HAS_TRACKING_NO, x.get('s'))) {
      bgCard = COLORS.FORM_SUCCESS_BG
    } else if (_.includes(CONS.XSHIPPING_STATUS_ERR, x.get('s'))) {
      bgCard = COLORS.FORM_ERROR_BG
    }
    let showRefresh = true
    if (_.includes(CONS.XSHIPPING_STATUS_ERR, x.get('s'))) {
      showRefresh = false
    }

    return (
      <XCard p='1' key={x.get('u') + index.toString()} bgColor={bgCard} w='full' mt='1'>
        {/* // ROW 1 TRACKING CODE */}
        <HStack flex={1} w='full'>
          <TouchableOpacity
            onPress={() => {
              this._copyToClipboard(trackingCode)
            }}
            style={{ flex: 1 }}>
            <XText fontSize='xs' variant='active' bold>
              {trackingCode}
            </XText>
          </TouchableOpacity>
          <HStack flex={1} alignItems='center' justifyContent='flex-end'>
            <XText variant='active' textAlign='right' fontSize='xs' bold>{`${shippingName}`}</XText>
          </HStack>
        </HStack>
        {/* // ROW 2 UPDATE AT */}
        <HStack flex={1} w='full'>
          <HStack flex={1} justifyContent='flex-end'>
            <XText variant='inactive' textAlign='right' fontSize='xs'>{`อัปเดตเมื่อ ${updateAt}`}</XText>
          </HStack>
        </HStack>
        {/* // ROW 3 CANCLE AND REFRESH XSHIPPING */}
        <HStack w='full' space='1' mb='1'>
          <HStack flex={1}>
            {cancelable ? (
              <XButton
                onPress={() => {
                  p.op.showConfirmation(
                    'ยกเลิกเลขพัสดุ',
                    'ต้องการยกเลิกเลขพัสดุใช่หรือไม่',
                    () => {
                      this._cancelTackingCode(x.get('i'))
                    },
                    () => {},
                    `ใช่`,
                    `ไม่ใช่`
                  )
                }}
                variant='ghost'
                bgColor={COLORS.BG_LIGHT_GREY}>
                <XText pt='1' color={COLORS.RED} fontSize='xs'>
                  ยกเลิกเลขพัสดุนี้
                </XText>
              </XButton>
            ) : null}
          </HStack>
          <HStack flex={1} justifyContent='flex-end'>
            {showRefresh && index === 0 ? (
              <XButton
                px='2'
                onPress={() => {
                  this._updateTracking(x)
                }}
                variant='ghost'
                bgColor={COLORS.BG_LIGHT_GREY}>
                <XText pt='1' color={COLORS.BLUE_FACEBOOK} fontSize='xs'>
                  อัปเดตข้อมูล
                </XText>
              </XButton>
            ) : null}
          </HStack>
        </HStack>

        {/* // ROW 4 ERROR STATUS */}
        {_.isNil(x.get('e')) ? null : (
          <HStack w='full' mt='1'>
            <XText fontSize='xs' bold color='red.500'>
              {errStatus}
            </XText>
          </HStack>
        )}
        {/* // ROW 5 NOTE STATUS */}
        {!_.isNil(note) ? (
          <HStack>
            <XText fontSize='xs' color='#0b59db'>{`หมายเหตุ: ${note}`}</XText>
          </HStack>
        ) : null}

        {/* // ROW 6 STATUS */}
        <HStack flex={1} w='full'>
          <HStack flex={1} justifyContent='flex-start'>
            <XText fontSize='xs' variant='active' textAlign='left'>
              {status}
            </XText>
          </HStack>
        </HStack>
      </XCard>
    )
  }

  _updateTracking = async (x: any): Promise<void> => {
    const { updateTracking, storeId, orderId, limitRes } = this.props
    await this._setSubmittingOrderId(orderId)
    const body: { [key: string]: any } = {
      store_id: storeId,
      order_id: orderId,
    }
    if (x.has('i') && !_.isNil(x.get('i'))) {
      body.courier_order_id = x.get('i')
    }
    if (!_.isNil(limitRes)) {
      body.xslmt = limitRes
    }
    const res: any = await new Promise((resolve) => {
      updateTracking({
        body,
        successCallback: resolve,
        failedCallback: resolve,
      })
    })
    await this._unsetSubmittingOrderId(orderId)
    if (res && res.x) {
      this.setconfig(res.x)
    } else {
      p.op.showToast('ไม่สามารถอัปเดตเลขติดตามพัสดุได้ในขณะนี้', 'warning')
    }
  }

  _createTackingCode = async () => {
    const { storeId, orderId, createTracking, limitRes, callBack = null } = this.props
    await this._setSubmittingOrderId(orderId)
    const body: { [key: string]: any } = {
      store_id: storeId,
      order_id: orderId,
    }
    if (!_.isNil(limitRes)) {
      body.xslmt = limitRes
    }
    const res: any = await new Promise((resolve) => {
      createTracking({
        body,
        successCallback: resolve,
        failedCallback: resolve,
      })
    })
    await this._unsetSubmittingOrderId(orderId)
    if (res && res.x) {
      this.setconfig(res.x)
    } else {
      p.op.showToast('ไม่สามารถสร้างเลขติดตามพัสดุได้ในขณะนี้', 'warning')
    }
    if (!_.isNil(callBack)) {
      callBack()
    }
  }

  _cancelTackingCode = async (i: any) => {
    // if (_.isNil(i)) {
    //   p.op.showConfirmationOkOnly('เกิดข้อผิดพลาด', 'ยังไม่สามารถยกเลิกเลขพัสดุได้ในขณะนี้')
    //   return
    // }
    const { storeId, orderId, cancelTracking, limitRes, callBack = null } = this.props
    await this._setSubmittingOrderId(orderId)
    const body: { [key: string]: any } = {
      store_id: storeId,
      order_id: orderId,
      // courier_order_id: i,
    }
    if (!_.isNil(limitRes)) {
      body.xslmt = limitRes
    }
    if (!_.isNil(i)) {
      body.courier_order_id = i
    }
    const res: any = await new Promise((resolve) => {
      cancelTracking({
        body,
        successCallback: resolve,
        failedCallback: resolve,
      })
    })
    await this._unsetSubmittingOrderId(orderId)
    if (res && res.x) {
      this.setconfig(res.x)
    } else {
      p.op.showToast('ไม่สามารถยกเลิกเลขติดตามพัสดุได้ในขณะนี้', 'warning')
    }
    if (!_.isNil(callBack)) {
      callBack()
    }
  }

  _autoRequestTracking = async (a: any) => {
    const { storeId, orderId, autoRequestTracking, limitRes, callBack = null } = this.props
    const auto = _.isNil(a) ? null : a
    if (_.isNil(a)) {
      p.op.showToast(`ไม่สามารถทำรายการได้ในขนะนี้ กรุณาติดต่อทีมงาน ${CONS.APP_NAME}`, 'warning')
      return
    }
    await this._setSubmittingOrderId(orderId)
    const body: { [key: string]: any } = {
      store_id: storeId,
      order_id: orderId,
      auto_request_xshipping: auto,
    }
    if (!_.isNil(limitRes)) {
      body.xslmt = limitRes
    }
    const res: any = await new Promise((resolve) => {
      autoRequestTracking({
        body,
        successCallback: resolve,
        failedCallback: resolve,
      })
    })
    await this._unsetSubmittingOrderId(orderId)
    if (res && res.x) {
      this.setconfig(res.x)
    } else {
      p.op.showToast('ไม่สามารถอัปเดตเลขติดตามพัสดุได้ในขณะนี้', 'warning')
    }
    if (!_.isNil(callBack)) {
      callBack()
    }
  }

  _getSubbmittingKeyByOrderId = (OrderId): string => `submitting_${OrderId}`

  _getSubmittingByOrderId = (OrderId: number): boolean =>
    _.has(this.state, this._getSubbmittingKeyByOrderId(OrderId)) ? this.state[this._getSubbmittingKeyByOrderId(OrderId)] : false

  _setSubmittingOrderId = async (OrderId) => {
    const stateObj = {}
    stateObj[this._getSubbmittingKeyByOrderId(OrderId)] = true
    await xUtil.setStatePromise(this, stateObj)
  }

  _unsetSubmittingOrderId = async (OrderId) => {
    const stateObj = {}
    stateObj[this._getSubbmittingKeyByOrderId(OrderId)] = false
    await xUtil.setStatePromise(this, stateObj)
  }

  render() {
    return this._renderXShippingCard()
  }
}

const XShippingX = connect(
  (state) => ({
    // selectedUserGroups: getSelectedUserGroups(state),
  }),
  (dispatch) => ({
    dispatch,
    cancelTracking: bindActionCreators(OrderState.cancelTackingCode, dispatch),
    createTracking: bindActionCreators(OrderState.createTackingCode, dispatch),
    updateTracking: bindActionCreators(OrderState.updateTackingCode, dispatch),
    autoRequestTracking: bindActionCreators(OrderState.autoRequestTracking, dispatch),
  })
  // @ts-ignore
)(XShipping)
// @ts-ignore
export default XShippingX as React.FC<XShippingProps>
