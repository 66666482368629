import * as util from 'x/utils/util'
import api from 'x/utils/api'
import { take, put, call, fork, all } from 'redux-saga/effects'
// import CONS from '../../config/constants'
// import actions from '../../config/actions'
import _ from 'lodash'
// import * as NavActions from '../../services/navigation'
import * as xAddrActions from 'x/modules/address/AddressState'
import * as storeActions from 'x/modules/store/StoreState'
import actions from 'x/config/actions'
import * as contactActions from '../../modules/contact/ContactState'
import * as addrActions from '../../modules/address/AddressState'

export default function* watchAllAddresses() {
  yield all([
    fork(watchAddressAdd),
    fork(watchAddressEdit),
    fork(watchAddressDelete),
    fork(watchLoadAddressProfile),
    fork(watchFindAddresses),
    fork(watchGetCustomer),
    fork(watchOrgAddressAdd),
    fork(watchOrgAddressEdit),
    fork(watchOrgAddressDelete),
    fork(watchGetResellerAddresses),
  ])
}

function* watchOrgAddressAdd() {
  util.log('In watchAddressAdd')
  while (true) {
    const action = yield take(addrActions.ACTION_ORG_ADDRESS_CREATE)
    yield call(createOrgAddress, action)
  }
}

function* createOrgAddress(action) {
  const apiOptions = {
    messages: {
      successMsg: 'สร้างที่อยู่สำเร็จ',
      // errorMsg: 'สร้างที่อยู่ล้มเหลว',
    },
    showSpinner: true,
  }
  try {
    // util.log('_createAddress__body_: ', action.payload)
    const res = yield call(api.putV2, api.PUT_ORG_ADDRESS, action.payload, apiOptions)
    // util.log('_createAddress__res_: ', res)
    if ('addresses' in res) {
      yield call(loadAddressesFromResponse, res, action.isStorePickMode)
      // yield put(NavActions.pop(1))
    }
    if (_.isFunction(action.successCallback)) {
      action.successCallback(res)
    }
  } catch (error) {
    // TODO: Handle this error action type
    util.log('_PUT_ADDRESS__error_: ', error)
    // yield put({ type: actions.CONTACT_ADD_FETCH_FAILED, error })
    if (_.isFunction(action.failedCallback)) {
      action.failedCallback(error)
    }
  }
}

function* watchAddressAdd() {
  util.log('In watchAddressAdd')
  while (true) {
    const action = yield take(addrActions.ACTION_ADDRESS_SAVE_NEW)
    yield call(createAddress, action)
  }
}

function* createAddress(action) {
  const apiOptions = {
    messages: {
      successMsg: 'สร้างที่อยู่สำเร็จ',
      // errorMsg: 'สร้างที่อยู่ล้มเหลว',
    },
    showSpinner: true,
  }
  try {
    // util.log('_createAddress__body_: ', action.payload)
    const res = yield call(api.putV2, api.PUT_ADDRESS, action.payload, apiOptions)
    // util.log('_createAddress__res_: ', res)
    if ('addresses' in res) {
      yield call(loadAddressesFromResponse, res, action.isStorePickMode)
      // yield put(NavActions.pop(1))
    }
    if (_.isFunction(action.successCallback)) {
      action.successCallback(res)
    }
  } catch (error) {
    // TODO: Handle this error action type
    util.log('_PUT_ADDRESS__error_: ', error)
    // yield put({ type: actions.CONTACT_ADD_FETCH_FAILED, error })
    if (_.isFunction(action.failedCallback)) {
      action.failedCallback(error)
    }
  }
}

function* watchOrgAddressEdit() {
  util.log('In watchAddressEdit')
  while (true) {
    const action = yield take(addrActions.ACTION_ORG_ADDRESS_EDIT)
    yield call(editOrgAddress, action)
  }
}

function* editOrgAddress(action) {
  const apiOptions = {
    messages: {
      successMsg: 'แก้ไขที่อยู่สำเร็จ',
      // errorMsg: 'แก้ไขที่อยู่ล้มเหลว',
    },
    showSpinner: true,
  }
  try {
    // util.log('_editAddress__body_: ', action.payload)
    const res = yield call(api.patchV2, api.PATCH_EDIT_ADDRESS, action.payload, apiOptions)
    // util.log('_editAddress__res_: ', res)
    if ('addresses' in res) {
      yield call(loadAddressesFromResponse, res, action.isStorePickMode)
      // yield put(NavActions.pop(1))
    }
    if (_.isFunction(action.successCallback)) {
      action.successCallback(res)
    }
  } catch (error) {
    // TODO: Handle this error action type
    util.log('_PUT_ADDRESS__error_: ', error)
    // yield put({ type: actions.CONTACT_ADD_FETCH_FAILED, error })
    if (_.isFunction(action.failedCallback)) {
      action.failedCallback(error)
    }
  }
}

function* watchAddressEdit() {
  util.log('In watchAddressEdit')
  while (true) {
    const action = yield take(addrActions.ACTION_ADDRESS_SAVE_EDITING)
    yield call(editAddress, action)
  }
}

function* editAddress(action) {
  const apiOptions = {
    messages: {
      successMsg: 'แก้ไขที่อยู่สำเร็จ',
      // errorMsg: 'แก้ไขที่อยู่ล้มเหลว',
    },
    showSpinner: true,
  }
  try {
    // util.log('_editAddress__body_: ', action.payload)
    const res = yield call(api.patchV2, api.PATCH_ADDRESS, action.payload, apiOptions)
    // util.log('_editAddress__res_: ', res)
    if ('addresses' in res) {
      yield call(loadAddressesFromResponse, res, action.isStorePickMode)
      // yield put(NavActions.pop(1))
    }
    if (_.isFunction(action.successCallback)) {
      action.successCallback(res)
    }
  } catch (error) {
    // TODO: Handle this error action type
    util.log('_PUT_ADDRESS__error_: ', error)
    // yield put({ type: actions.CONTACT_ADD_FETCH_FAILED, error })
    if (_.isFunction(action.failedCallback)) {
      action.failedCallback(error)
    }
  }
}

function* watchOrgAddressDelete() {
  while (true) {
    const action = yield take(addrActions.ACTION_ORG_ADDRESS_DELETE)
    yield call(deleteOrgAddress, action)
  }
}

function* deleteOrgAddress(action) {
  const apiOptions = {
    messages: {
      successMsg: 'ลบที่อยู่สำเร็จ',
      // errorMsg: 'แก้ไขที่อยู่ล้มเหลว',
    },
    showSpinner: true,
  }
  try {
    // console.log('_deleteAddress__body_: ', action.payload)
    const res = yield call(api.delV2, api.DELETE_ORG_ADDRESS, action.payload, apiOptions)
    // console.log('_deleteAddress__res_: ', res)
    if ('addresses' in res) {
      yield call(loadAddressesFromResponse, res, action.isStorePickMode)
    }
    if (_.isFunction(action.successCallback)) {
      action.successCallback(res)
    }
  } catch (error) {
    // TODO: Handle this error action type
    util.log('_PUT_ADDRESS__error_: ', error)
    yield put({ type: addrActions.ACTION_ADDRESS_DELETE_FAILED, error })
    if (_.isFunction(action.failedCallback)) {
      action.failedCallback(error)
    }
  }
}

function* watchAddressDelete() {
  util.log('In watchAddressDelete')
  while (true) {
    const action = yield take(addrActions.ACTION_ADDRESS_DELETE)
    yield call(deleteAddress, action)
  }
}

function* deleteAddress(action) {
  const apiOptions = {
    messages: {
      successMsg: 'ลบที่อยู่สำเร็จ',
      // errorMsg: 'แก้ไขที่อยู่ล้มเหลว',
    },
    showSpinner: true,
  }
  try {
    // console.log('_deleteAddress__body_: ', action)
    const res = yield call(api.del, api.DELETE_ADDRESS, action.payload, apiOptions)
    // console.log('_deleteAddress__res_: ', res)
    if ('addresses' in res) {
      yield call(loadAddressesFromResponse, res, action.isStorePickMode)
    }
    if (_.isFunction(action.successCallback)) {
      action.successCallback(res)
    }
  } catch (error) {
    // TODO: Handle this error action type
    util.log('_PUT_ADDRESS__error_: ', error)
    yield put({ type: addrActions.ACTION_ADDRESS_DELETE_FAILED, error })
    if (_.isFunction(action.failedCallback)) {
      action.failedCallback(error)
    }
  }
}

function* loadAddressesFromResponse(res, isStorePickMode) {
  if ('addresses' in res) {
    const { addresses } = res
    let newAddresses = _.sortBy(addresses, [(v) => v.id])
    if (isStorePickMode) {
      const useSellerAddress = { id: null, name: null, address1: '<< ใช้ที่อยู่ผู้ขายส่ง >>' }
      newAddresses = newAddresses.reverse()
      newAddresses.push(useSellerAddress)
      newAddresses = newAddresses.reverse()
    }

    yield put(addrActions.loadList(newAddresses))
    yield put(contactActions.loadNewContactAddresses(newAddresses))
    if (isStorePickMode) {
      yield put(storeActions.addNewContactAddressesToMyStore(newAddresses))
    }
  }
}

function* watchLoadAddressProfile() {
  util.log('In watchLoadAddressProfile')
  while (true) {
    const action = yield take(addrActions.ACTION_LOAD_ADDRESS_PROFILE)
    yield call(loadAddressesProfile, action)
  }
}

function* loadAddressesProfile(action) {
  try {
    const res = yield call(api.post, api.POST_CUSTOMER, action.payload.body)
    if (_.isFunction(action.payload.successCallback)) {
      action.payload.successCallback(res)
    }
  } catch (error) {
    if (_.isFunction(action.payload.failedCallback)) {
      action.payload.failedCallback(error)
    }
  }
}

function* watchFindAddresses() {
  util.log('In watchFindAddresses')
  while (true) {
    const action = yield take(xAddrActions.ACTION_FIND_ADDRESSES)
    yield call(findAddresses, action)
  }
}

function* findAddresses(action) {
  const apiOptions = {
    showSpinner: true,
  }
  try {
    const bodyAction = action.body
    const res = yield call(api.postV2, api.POST_FIND_ADDRESSES, bodyAction, apiOptions)
    yield put(addrActions.loadList(res.addresses))
    if (_.isFunction(action.successCallback)) {
      action.successCallback(res)
    }
  } catch (error) {
    // yield put({ type: addrActions.ACTION_ADDRESS_DELETE_FAILED, error })
    if (_.isFunction(action.failedCallback)) {
      action.failedCallback(error)
    }
  }
}

function* watchGetCustomer() {
  util.log('In watchFindAddresses')
  while (true) {
    const action = yield take(xAddrActions.GET_CUSTOMER)
    yield call(getAddresses, action)
  }
}

function* getAddresses(action) {
  const apiOptions = {
    showSpinner: true,
  }
  try {
    const res = yield call(api.post, api.POST_GET_CUSTOMER, action.body)
    // yield put(addrActions.loadList(res.addresses))
    if (_.isFunction(action.successCallback)) {
      action.successCallback(res)
    }
  } catch (error) {
    // yield put({ type: addrActions.ACTION_ADDRESS_DELETE_FAILED, error })
    if (_.isFunction(action.failedCallback)) {
      action.failedCallback(error)
    }
  }
}

function* watchGetResellerAddresses() {
  while (true) {
    const action = yield take(actions.GET_RESELLER_ADDRESSES)
    yield call(getResellerAddresses, action)
  }
}

function* getResellerAddresses(action) {
  // const apiOptions = {
  //   showSpinner: true,
  // }
  try {
    const res = yield call(api.postV2, api.POST_GET_RESEELER_ADDRESSES, action.body)
    // yield put(addrActions.loadList(res.addresses))
    if (_.isFunction(action.successCallback)) {
      action.successCallback(res)
    }
  } catch (error) {
    // yield put({ type: addrActions.ACTION_ADDRESS_DELETE_FAILED, error })
    if (_.isFunction(action.failedCallback)) {
      action.failedCallback(error)
    }
  }
}
