import React from 'react'
import { Image, View, ImageSourcePropType } from 'react-native'
import * as util from 'x/utils/util'
import p from 'x/config/platform-specific'
import { COLORS, STYLES } from 'x/config/styles'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContent from 'xui/components/XContent'
import XContainer from 'xui/components/XContainer'
import BaseStoreSettingXShippingFlashView from 'x/modules/xshipping/BaseStoreSettingXShippingFlashView'
import { VIEW_LOADING } from 'xui/utils/BaseShareUI'
import XSettingRow from 'xui/components/XSettingRow'
import XHelpModal from 'xui/components/XHelpModal'
import FAQ from 'x/config/FAQ'
import { IQuestionsAndAnswers } from 'x/index'
import Box from 'xui/components/Box'
import XText from 'xui/components/XText'
import XButton from 'xui/components/XButton'

const IMG_FLASH: ImageSourcePropType = require('../../images/courier/flash.png')
const IMG_SHIPJUNG: ImageSourcePropType = require('../../images/courier/shipjung.png')

export default abstract class BaseUIStoreSettingXShippingFlashView extends BaseStoreSettingXShippingFlashView {
  static displayName = 'BaseUIStoreSettingXShippingFlashView'

  // abstract goBack: () => void
  abstract renderWebView: () => void

  _renderCustomHeader = () => (
    <XCustomHeader
      headerLeftProps={{
        backIcon: true,
        onPressItem: () => {
          util.navGoBack(this.props)
        },
      }}
      title={p.op.t('Order.shippingTypeItems.flash')}
    />
  )

  _renderUseFlashXShipjung = () => {
    const {
      useFlashxShipjung,
      useAutoXShippingIsFlashxShipjungReadyToShip,
      isFlashxShipjungLoading,
      loadingFlashxShipjungHeight,
      loadingFlashxShipjungWidth,
    } = this.state
    const textTitle = 'ใช้งานการขอเลขพัสดุ\n"แบบไม่เก็บเงินปลายทาง"'
    // console.log('useAutoXShippingIsJAndTReadyToShip => ', useAutoXShippingIsJAndTReadyToShip)
    return (
      <View
        onLayout={(event) => {
          const { x, y, width, height } = event.nativeEvent.layout
          util.setStatePromise(this, { loadingFlashxShipjungWidth: width, loadingFlashxShipjungHeight: height })
        }}>
        <XSettingRow
          title={textTitle}
          value={useFlashxShipjung}
          onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
          stateKey='useFlashxShipjung'
          helper={{
            title: 'Flash ออกเลขพัสดุ',
            FAQ: FAQ.XSHIPPING_NOT_COD,
            initiateOpenIndex: 0,
          }}
          backgroundColor={COLORS.APP_SECONDARY}
          // disabled={useJAndT}
        />

        {useFlashxShipjung ? (
          <XSettingRow
            title='ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง'
            value={useAutoXShippingIsFlashxShipjungReadyToShip}
            onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
            stateKey='useAutoXShippingIsFlashxShipjungReadyToShip'
            helper={{
              title: 'ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง',
              FAQ: FAQ.XSHIPPING_READY_TO_SHIP,
              initiateOpenIndex: 0,
            }}
            backgroundColor={COLORS.APP_SECONDARY}
          />
        ) : null}

        {isFlashxShipjungLoading ? VIEW_LOADING(loadingFlashxShipjungWidth, loadingFlashxShipjungHeight) : null}
      </View>
    )
  }

  _renderFlashXShipjungCOD = () => {
    const {
      useFlashxShipjungCOD,
      useAutoXShippingIsFlashxShipjungCODReadyToShip,
      loadingFlashxShipjungCODHeight,
      loadingFlashxShipjungCODWidth,
      isFlashxShipjungCODLoading,
    } = this.state
    const textTitle = 'ใช้งานการขอเลขพัสดุ\n"แบบเก็บเงินปลายทาง"'
    // const isDisabled = useJAndTCOD
    return (
      <View
        onLayout={(event) => {
          const { x, y, width, height } = event.nativeEvent.layout
          util.setStatePromise(this, { loadingFlashxShipjungCODWidth: width, loadingFlashxShipjungCODHeight: height })
        }}>
        <XSettingRow
          title={textTitle}
          value={useFlashxShipjungCOD}
          onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
          stateKey='useFlashxShipjungCOD'
          helper={{
            title: 'Flash ออกเลขพัสดุ',
            FAQ: FAQ.XSHIPPING_COD,
            initiateOpenIndex: 0,
          }}
          backgroundColor={COLORS.APP_SECONDARY}
          // disabled={useJAndTCOD}
        />

        {useFlashxShipjungCOD ? (
          <XSettingRow
            title='ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง'
            value={useAutoXShippingIsFlashxShipjungCODReadyToShip}
            onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
            stateKey='useAutoXShippingIsFlashxShipjungCODReadyToShip'
            helper={{
              title: 'ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง',
              FAQ: FAQ.XSHIPPING_READY_TO_SHIP,
              initiateOpenIndex: 0,
            }}
            backgroundColor={COLORS.APP_SECONDARY}
          />
        ) : null}

        {isFlashxShipjungCODLoading ? VIEW_LOADING(loadingFlashxShipjungCODWidth, loadingFlashxShipjungCODHeight) : null}
      </View>
    )
  }

  _renderAgreement = (onPressOpenOverlay: () => void) => (
    <XButton onPress={() => onPressOpenOverlay()} w='48' variant='outline' alignSelf='center'>
      <XText variant='primary'>ดูข้อตกลงและวิธีการใช้งาน</XText>
    </XButton>
  )

  _getFAQAgreementForFlashxShipjung = (): IQuestionsAndAnswers[] => {
    const newFQA = []
    FAQ.XSHIPPING_AGREEMENT_FLASH_X_SHIPJUNG.map((faq: IQuestionsAndAnswers) => {
      newFQA.push(faq)
    })
    FAQ.XSHIPPING_AGREEMENT_SHIPJUNG_COMMON.map((faq: IQuestionsAndAnswers) => {
      newFQA.push(faq)
    })
    return newFQA
  }

  _renderFlashxShipjung = () => {
    const { isRenderWebView } = this.state
    if (isRenderWebView) {
      return null
    }
    return (
      <Box
        _light={{ bg: 'white' }}
        _dark={{ bg: 'gray.500' }}
        borderRadius='md'
        style={{
          // backgroundColor: COLORS.WHITE,
          marginTop: 10,
          marginRight: 6,
          marginLeft: 6,
          paddingLeft: 10,
          paddingRight: 10,
          paddingTop: 6,
          paddingBottom: 15,
        }}>
        <Box style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
          <Image
            source={IMG_FLASH}
            resizeMode='stretch'
            style={{
              // position: 'absolute',
              width: 154,
              height: 58,
              backgroundColor: 'transparent',
              zIndex: 70,
              marginLeft: -30,
            }}
          />
          <XText style={{ fontSize: STYLES.FONT_SIZE_LARGER, color: COLORS.TEXT_ACTIVE, paddingRight: 20 }}>X</XText>
          <Image
            source={IMG_SHIPJUNG}
            resizeMode='stretch'
            style={{
              // position: 'absolute',
              width: 90,
              height: 58,
              backgroundColor: 'transparent',
              zIndex: 70,
            }}
          />
        </Box>
        <Box style={{ justifyContent: 'center', alignItems: 'center' }}>
          <XText variant='active'>ขอเลขพัสดุจาก Flash Express ผ่าน Shipjung</XText>
        </Box>

        <XHelpModal
          key='XHelpModalFlash'
          headerTitle='ข้อตกลงและวิธีการใช้งาน'
          FAQ={this._getFAQAgreementForFlashxShipjung()}
          initiateOpenIndex={4}
          renderButton={(onPressOpenOverlay: () => void) => this._renderAgreement(onPressOpenOverlay)}
        />

        <Box style={{ height: 10 }} />
        <Box style={{ paddingBottom: 2, flexDirection: 'row' }}>
          <XText variant='inactive'>{'รูปแบบจัดส่ง: '}</XText>
          <XText variant='active' bold>{`${p.op.t('Order.shippingTypeItems.flash')}`}</XText>
        </Box>
        {this._renderUseFlashXShipjung()}
        <Box style={{ height: 10 }} />
        <Box style={{ paddingBottom: 2, flexDirection: 'row' }}>
          <XText variant='inactive'>{'รูปแบบจัดส่ง: '}</XText>
          <XText variant='active' bold>{`${p.op.t('Order.shippingTypeItems.flash_cod')}`}</XText>
        </Box>
        {this._renderFlashXShipjungCOD()}
      </Box>
    )
  }

  _renderUseFlashDirect = () => {
    const { useFlash, useAutoXShippingIsFlashReadyToShip, isFlashLoading, loadingFlashHeight, loadingFlashWidth } = this.state
    const textTitle = 'ใช้งานการขอเลขพัสดุ\n"แบบไม่เก็บเงินปลายทาง"'
    // console.log('useAutoXShippingIsJAndTReadyToShip => ', useAutoXShippingIsJAndTReadyToShip)
    return (
      <View
        onLayout={(event) => {
          const { x, y, width, height } = event.nativeEvent.layout
          util.setStatePromise(this, { loadingFlashWidth: width, loadingFlashHeight: height })
        }}>
        <XSettingRow
          title={textTitle}
          value={useFlash}
          onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
          stateKey='useFlash'
          helper={{
            title: 'Flash ออกเลขพัสดุ',
            FAQ: FAQ.XSHIPPING_NOT_COD,
            initiateOpenIndex: 0,
          }}
          backgroundColor={COLORS.APP_SECONDARY}
          // disabled={useJAndT}
        />

        {useFlash ? (
          <XSettingRow
            title='ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง'
            value={useAutoXShippingIsFlashReadyToShip}
            onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
            stateKey='useAutoXShippingIsFlashReadyToShip'
            helper={{
              title: 'ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง',
              FAQ: FAQ.XSHIPPING_READY_TO_SHIP,
              initiateOpenIndex: 0,
            }}
            backgroundColor={COLORS.APP_SECONDARY}
          />
        ) : null}

        {isFlashLoading ? VIEW_LOADING(loadingFlashWidth, loadingFlashHeight) : null}
      </View>
    )
  }

  _renderFlashCODDirect = () => {
    const {
      useFlashCOD,
      useAutoCreateCODPaymentWhenDelivered,
      useAutoXShippingIsFlashCODReadyToShip,
      loadingFlashCODHeight,
      loadingFlashCODWidth,
      isFlashCODLoading,
    } = this.state
    const textTitle = 'ใช้งานการขอเลขพัสดุ\n"แบบเก็บเงินปลายทาง"'
    // const isDisabled = useJAndTCOD
    return (
      <View
        onLayout={(event) => {
          const { x, y, width, height } = event.nativeEvent.layout
          util.setStatePromise(this, { loadingFlashCODWidth: width, loadingFlashCODHeight: height })
        }}>
        <XSettingRow
          title={textTitle}
          value={useFlashCOD}
          onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
          stateKey='useFlashCOD'
          helper={{
            title: 'Flash ออกเลขพัสดุ',
            FAQ: FAQ.XSHIPPING_COD,
            initiateOpenIndex: 0,
          }}
          backgroundColor={COLORS.APP_SECONDARY}
          // disabled={useJAndTCOD}
        />

        {useFlashCOD ? (
          <XSettingRow
            title='ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง'
            value={useAutoXShippingIsFlashCODReadyToShip}
            onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
            stateKey='useAutoXShippingIsFlashCODReadyToShip'
            helper={{
              title: 'ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง',
              FAQ: FAQ.XSHIPPING_READY_TO_SHIP,
              initiateOpenIndex: 0,
            }}
            backgroundColor={COLORS.APP_SECONDARY}
          />
        ) : null}

        {useFlashCOD ? (
          <XSettingRow
            title='บันทึกรับชำระอัตโนมัติเมื่อส่งพัสดุถึงผู้รับแล้ว'
            value={useAutoCreateCODPaymentWhenDelivered}
            onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
            stateKey='useAutoCreateCODPaymentWhenDelivered'
            helper={{
              title: 'บันทึกรับชำระอัตโนมัติเมื่อส่งพัสดุถึงผู้รับแล้ว',
              FAQ: FAQ.XSHIPPING_AUTO_CREATE_COD_PAYMENT_WHEN_DELIVERED,
              initiateOpenIndex: 0,
            }}
            backgroundColor={COLORS.APP_SECONDARY}
          />
        ) : null}

        {isFlashCODLoading ? VIEW_LOADING(loadingFlashCODWidth, loadingFlashCODHeight) : null}
      </View>
    )
  }

  _renderFlashDirect = () => {
    const { isRenderWebView } = this.state
    if (isRenderWebView) {
      return null
    }
    return (
      <Box
        _light={{ bg: 'white' }}
        _dark={{ bg: 'gray.500' }}
        borderRadius='md'
        style={{
          // backgroundColor: COLORS.WHITE,
          marginTop: 10,
          marginRight: 6,
          marginLeft: 6,
          paddingLeft: 10,
          paddingRight: 10,
          paddingTop: 6,
          paddingBottom: 15,
        }}>
        <Box style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
          <Image
            source={IMG_FLASH}
            resizeMode='stretch'
            style={{
              // position: 'absolute',
              width: 154,
              height: 58,
              backgroundColor: 'transparent',
              zIndex: 70,
              // marginLeft: -30,
            }}
          />
        </Box>
        <Box style={{ justifyContent: 'center', alignItems: 'center' }}>
          <XText variant='active'>ขอเลขพัสดุจาก Flash Express โดยตรง</XText>
        </Box>
        <XHelpModal
          key='XHelpModalFlash'
          headerTitle='ข้อตกลงและวิธีการใช้งาน'
          FAQ={FAQ.XSHIPPING_FLASH_AGREEMENT}
          initiateOpenIndex={0}
          renderButton={(onPressOpenOverlay: () => void) => this._renderAgreement(onPressOpenOverlay)}
        />
        <Box h='2' />

        <Box justifyContent='center' alignItems='center'>
          <XButton onPress={() => this.getLinkAccount()} w='40'>
            <XText color='white' bold alignSelf='center'>
              ดำเนินการเชื่อมต่อ
            </XText>
          </XButton>
        </Box>

        <XText variant='inactive' textAlign='center'>
          (หากเคย "ดำเนินการเชื่อมต่อ" แล้ว ไม่จำเป็นต้องเชื่อมต่อซ้ำ)
        </XText>
        <Box style={{ height: 10 }} />
        <Box style={{ paddingBottom: 2, flexDirection: 'row' }}>
          <XText variant='inactive'>{'รูปแบบจัดส่ง: '}</XText>
          <XText variant='active' bold>{`${p.op.t('Order.shippingTypeItems.flash')}`}</XText>
        </Box>
        {this._renderUseFlashDirect()}
        <Box style={{ height: 10 }} />
        <Box style={{ paddingBottom: 2, flexDirection: 'row' }}>
          <XText variant='inactive'>{'รูปแบบจัดส่ง: '}</XText>
          <XText variant='active' bold>{`${p.op.t('Order.shippingTypeItems.flash_cod')}`}</XText>
        </Box>
        {this._renderFlashCODDirect()}
      </Box>
    )
  }

  render() {
    return (
      <XContainer>
        {this._renderCustomHeader()}
        <XContent>
          <Box w='full'>
            {this._renderFlashDirect()}
            {this._renderFlashxShipjung()}
          </Box>
        </XContent>
      </XContainer>
    )
  }
}
