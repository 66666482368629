import { Map, fromJS } from 'immutable'

export const INITIALIZE_STATE = 'BackParamsState/INITIALIZE'
export const RESET_STATE = 'BackParamsState/RESET'
export const SET_STATE = 'BackParamsState/SET'
export const REMOVE_STATE = 'BackParamsState/REMOVE'

export const PICKER_SELECT = 'BackParamsState/PICKER_SELECTED'
export const PICKER_DELETE = 'BackParamsState/PICKER_DELETED'
export const PICKER_CLEAR = 'BackParamsState/PICKER_CLEARED'

const initPagePicker = Map({
  shippingType: Map({
    index: -1,
  }),
})

// Initial state
const initialState = Map({
  selectedBank: null,
  pagePicker: initPagePicker,
})

export function initializeBackParamsState() {
  return {
    type: INITIALIZE_STATE,
  }
}

/**
 * ใช้ clear ค่าทั้งหมดใน BackParams
 * @export
 */
export function reset() {
  return {
    type: RESET_STATE,
  }
}

/**
 *  ใช้เซ็ตค่าใน BackParams เช่น {profileImageURI: 'http://xxx1234.jpg'}
 *  อัพเดทก็ใช้ตัวนี้เหมือนกัน (เพราะเป็น merge ของ Immutable)
 * @export
 * @param {object} params
 */
export function set(params) {
  return {
    type: SET_STATE,
    payload: fromJS(params),
  }
}

/**
 * ใช้สำหรับเอา key ของ object ของ BackParams ออกเป็นตัวๆ ไป
 * เช่น remove('partner') คือเอา {partner: 'John'} ออกทั้งก้อน
 * @export
 * @param {string} params
 */
export function remove(params) {
  return {
    type: REMOVE_STATE,
    payload: fromJS(params),
  }
}

export function pickerSelect(item) {
  return {
    type: PICKER_SELECT,
    payload: {
      key: item.key,
      data: item.data,
    },
  }
}

export function pickerDelete(keyString) {
  return {
    type: PICKER_DELETE,
    payload: { key: keyString },
  }
}

export function pickerClear() {
  return {
    type: PICKER_CLEAR,
  }
}

// Reducer
export default function BackParamsReducer(state = initialState, action: any = {}) {
  switch (action.type) {
    case INITIALIZE_STATE:
    case RESET_STATE:
      return initialState
    case SET_STATE:
      return state.mergeDeep(action.payload)
    case REMOVE_STATE:
      return state.delete(action.payload)

    case PICKER_SELECT:
      return state.setIn(['pagePicker', action.payload.key], fromJS(action.payload.data))
    case PICKER_DELETE:
      return state.deleteIn(['pagePicker', action.payload.key])
    case PICKER_CLEAR:
      return state.setIn(['pagePicker'], initPagePicker)

    default:
      return state
  }
}
