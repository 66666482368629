import { connect } from 'react-redux'
import { getState, getDispatch } from 'x/modules/order/MarketplaceOrderViewConnect'
import MarketplaceOrderView from './MarketplaceOrderView'

export default connect(
  (state) => ({
    ...getState(state),
  }),
  (dispatch) => {
    return {
      ...getDispatch(dispatch),
    }
  }
)(MarketplaceOrderView)
