import React from 'react'
import { Image, StyleSheet, StyleProp, ImageStyle } from 'react-native'

import { getBankIconFromBankID } from '../utils/bank-icons'

interface IBankIconProps {
  bankId: number
  size?: number
  style?: StyleProp<ImageStyle>
}

const BankIcon: React.FC<IBankIconProps> = ({ bankId, size = 38, style = {} }) => {
  const borderRadius = Math.floor(size * 0.1)
  const bankIcon = getBankIconFromBankID(bankId)
  return <Image source={bankIcon} style={StyleSheet.flatten([{ width: size, height: size, borderRadius }, style])} />
}

export default BankIcon
