import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as ContactActions from 'x/modules/contact/ContactState'
import CCustomerEditingView from './CCustomerEditingView'
// import * as xContactActions from 'x/modules/contact/ContactActions'

export default connect(
  (state) => ({
    selectedContact: state.getIn(['contacts', 'selectedContact']),
    editingContact: state.getIn(['contacts', 'editingContact']),
  }),
  (dispatch) => ({
    load: bindActionCreators(ContactActions.loadContact, dispatch),
    // clear: bindActionCreators(ContactActions.clearContact, dispatch),
    revert: bindActionCreators(ContactActions.revertContact, dispatch),
    onChange: bindActionCreators(ContactActions.onChangeContact, dispatch),
    // addCustomer: bindActionCreators(ContactActions.addCustomer, dispatch),
    // saveCustomer: bindActionCreators(ContactActions.saveCustomer, dispatch),
    editCustomer: bindActionCreators(ContactActions.editCustomer, dispatch),
  })
)(CCustomerEditingView)
