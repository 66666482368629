import React from 'react'
import _ from 'lodash'
import { StyleSheet } from 'react-native'
import { Map } from 'immutable'
import * as util from 'x/utils/util'
import p from 'x/config/platform-specific'
import { COLORS, STYLES } from 'x/config/styles'
import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import { ISelectedStoreMap } from 'x/index'
import NB from 'x/config/nativebase'
import XIcon from '../XIcon'
import XText from '../XText'
import Box from '../Box'

interface IOrderStatesTableProps {
  selectedStore: ISelectedStoreMap
  order: Map<string, any>
  hasBuyerOrSellerName?: boolean
}

const OrderStatesTable: React.FC<IOrderStatesTableProps> = ({ selectedStore, order }) => {
  const boxStateColumns = getOrderStateBoxes(order, selectedStore)

  // const isBuyerCompleted = boxStateColumns[0].isCompleted
  // const isSellerCompleted = boxStateColumns[1].isCompleted
  // console.log(boxStateColumns)

  const txtBuyer = boxStateColumns[0].headerText
  const txtSeller = boxStateColumns[1].headerText
  const txtBuyerName = boxStateColumns[0].subHeaderText
  const txtSellerName = boxStateColumns[1].subHeaderText
  const hasBuyerName = txtBuyerName && txtBuyerName !== ''
  const hasSellerName = txtSellerName && txtSellerName !== ''
  const hasBuyerOrSellerName = hasBuyerName || hasSellerName
  return (
    <VStack
      w='full'
      space='1'
      // mt='1'
      mb='2'>
      {/* <XCard w='full'> */}
      {/* <XCard.Body> */}
      <HStack w='full' alignItems='flex-end'>
        <LabelTableColumn selectedStore={selectedStore} order={order} hasBuyerOrSellerName={hasBuyerOrSellerName} />
        {boxStateColumns.map((colData, index) => (
          <StateTableColumn key={`col_${index.toString()}`} hasBuyerOrSellerName={hasBuyerOrSellerName} {...colData} />
        ))}
      </HStack>
      <VStack alignItems='flex-end'>
        {hasBuyerName ? <XText variant='inactive' fontSize='xs'>{`${txtBuyer}: ${txtBuyerName}`}</XText> : null}
        {hasSellerName ? <XText variant='inactive' fontSize='xs'>{`${txtSeller}: ${txtSellerName}`}</XText> : null}
      </VStack>
      {/* </XCard.Body> */}
      {/* </XCard> */}
    </VStack>
  )
}

const StateTableColumn: React.FC<any> = ({
  // key,
  headerText,
  headerTextSuffix,
  subHeaderText,
  data,
  isLastColumn,
  isCompleted,
  hasBuyerOrSellerName = false,
}) => (
  // data = [{ text: 'xxx', status: true }, { text: 'yyy', status: false }]
  // const order = this._getFocusedOrder()
  <VStack
    flex={1}
    minW='80px'
    // maxW='200px'
    flexWrap='wrap'
    // style={[
    //   // s.statusTableColumnContainer,
    //   {
    //     borderTopWidth: p.op.isWeb() ? 1 : 0,
    //     borderLeftWidth: p.op.isWeb() ? 1 : 0,
    //     backgroundColor: isCompleted ? COLORS.FORM_SUCCESS_BG : 'transparent',
    //   },
    // ]}
    // key={`col_${key}`}
  >
    <HStack
      w='full'
      h='12'
      pr='2'
      alignItems='center'
      justifyContent='center'
      // bg={NB.HC.BG.LIGHT_GREY()}
      bg={NB.C.L.BG.LIGHT_GREY}
      // {...NB.U.UPPERLINE}
      // {...NB.U.UNDERLINE}
      // style={{
      //   width: '100%',
      //   height: 20,
      //   flex: 1,
      //   flexDirection: 'row',
      //   justifyContent: 'flex-start',
      //   alignItems: 'center',
      //   borderWidth: 1,
      //   borderBottomWidth: 0,
      //   borderRightWidth: isLastColumn ? 1 : 0,
      //   borderColor: COLORS.TEXT_INACTIVE,
      //   paddingLeft: 3,
      // }}
    >
      <XText w='full' textAlign='left' variant='inactive' numberOfLines={2}>
        <XText bold variant='inactive'>
          {headerText || ''}
        </XText>
        <XText fontSize='xs'>{headerTextSuffix}</XText>
      </XText>
    </HStack>
    {/* <HStack
      h='11'
      alignItems='center'
      justifyContent='center'
      // style={{
      //   width: '100%',
      //   height: 20,
      //   flex: 1,
      //   flexDirection: 'row',
      //   justifyContent: 'flex-start',
      //   alignItems: 'center',
      //   borderWidth: 1,
      //   borderTopWidth: 0,
      //   borderBottomWidth: p.op.isWeb() ? 1 : 0,
      //   borderRightWidth: isLastColumn ? 1 : 0,
      //   borderColor: COLORS.TEXT_INACTIVE,
      //   paddingLeft: 3,
      // }}
      {...NB.U.UNDERLINE}>
      <XText variant='inactive'>{subHeaderText || ''}</XText>
    </HStack> */}
    {data.map((item, rowIndex) => {
      const { text, status, iconDisabled } = item
      // const regx = /[()]/
      // checkTxt เอาไว้ดูว่าถ้ามี วงเล็บ จะให้เป็น ture แล้วทำให้ตัวหนังสือเป็นสีม่วง
      // ในที่นี้คือมันจะเจอคำว่า (จัดส่งก่อนชำระ) ซึ่งมีวงเล็บอยู่
      // const checkTxt = regx.test(text)
      const isLastRow = rowIndex === data.length - 1
      // const idOdd = rowIndex % 2 === 0
      const icon = status ? CheckedIcon : UncheckedIcon
      const injectUnderline = !isLastRow || hasBuyerOrSellerName ? NB.U.UNDERLINE : {}
      return (
        <HStack
          h='11'
          space='1'
          alignItems='center'
          // justifyContent='center'
          // bgColor={idOdd ? NB.HC.BG.LIGHT_GREY() : undefined}
          key={`data_${rowIndex.toString()}`}
          // key={`data_${key}_${rowIndex}`}
          // style={{
          //   width: '100%',
          //   height: 20,
          //   borderWidth: 1,
          //   borderBottomWidth: isLastRow || p.op.isWeb() ? 1 : 0,
          //   borderRightWidth: isLastColumn ? 1 : 0,
          //   borderColor: COLORS.TEXT_INACTIVE,
          //   flex: 1,
          //   flexDirection: 'row',
          //   justifyContent: 'flex-start',
          //   alignItems: 'center',
          // }}
          {...injectUnderline}>
          {iconDisabled ? <Box w='7' h='7' /> : icon}
          <XText variant='inactive' bold>
            {text || ''}
          </XText>
        </HStack>
      )
    })}
  </VStack>
)

const LabelTableColumn: React.FC<IOrderStatesTableProps> = ({ selectedStore, order, hasBuyerOrSellerName = false }) => {
  const orderState = order.get('state')
  const paymentState = order.get('payment_state')
  const shippingState = order.get('shipping_state')
  const orderType = order.get('type')
  const orderStateColor = util.getOrderStateColor(orderType, orderState)
  const paymentStateColor = util.getPaymentStateColor(orderType, paymentState)
  const shippingStateColor = util.getShippingStateColor(orderType, shippingState)
  const shipBeforePay = order.get('ship_before_pay')
  const cod = order.get('is_cod')

  const isCOD = !!(!_.isNil(cod) && cod)
  const isShipBeforePay = !!(!_.isNil(shipBeforePay) && shipBeforePay)
  const injectBottomUnderline = hasBuyerOrSellerName ? NB.U.UNDERLINE : {}
  return (
    <VStack w='100px'>
      <HStack
        h='12'
        // {...NB.U.UPPERLINE}
        // {...NB.U.UNDERLINE}
        // bg={NB.HC.BG.LIGHT_GREY()}
        bg={NB.C.L.BG.LIGHT_GREY}
      />
      {/* <HStack h='11' {...NB.U.UNDERLINE} /> */}
      <HStack
        h='11'
        space='1'
        alignItems='center'
        {...NB.U.UNDERLINE}
        // bg={NB.HC.BG.LIGHT_GREY()}
      >
        <XIcon family='MaterialCommunityIcons' name='file-document' style={{ color: orderStateColor }} />
        <XText
          variant='inactive'
          bold
          // allowFontScaling={false} style={s.statusTableLabelColumnTxt}
        >
          {/* {p.op.t('Order.confirmOrder')} */}
          {p.op.t('Order.order')}
        </XText>
      </HStack>
      <HStack h='11' space='1' alignItems='center' {...NB.U.UNDERLINE}>
        <XIcon
          family='Ionicons'
          name='cash'
          color={paymentStateColor}
          //  style={StyleSheet.flatten([s.paymentStateIcon, { color: paymentStateColor }])}
        />
        <XText
          variant='inactive'
          bold
          color={isCOD ? COLORS.COD_COLOR : undefined}
          // allowFontScaling={false}
          // style={[s.statusTableLabelColumnTxt, isCOD ? { color: COLORS.COD_COLOR } : s.statusTableLabelColumnTxt]}
        >
          {isCOD ? p.op.t('Order.is_cod') : p.op.t('Order.pay')}
        </XText>
      </HStack>
      <HStack
        h='11'
        space='1'
        alignItems='center'
        // {...NB.U.UNDERLINE}
        // bg={NB.HC.BG.LIGHT_GREY()}
        {...injectBottomUnderline}>
        <XIcon
          family='MaterialIcons'
          name='local-shipping'
          color={shippingStateColor}
          // style={StyleSheet.flatten([s.shippingStateIcon, { color: shippingStateColor }])}
        />
        <XText
          // allowFontScaling={false}
          variant='inactive'
          bold
          color={isShipBeforePay ? COLORS.SUMMARY_ORDER_DONUT_COLOR_SCALE.myTasks_payOther : undefined}
          // style={[
          //   s.statusTableLabelColumnTxt,
          //   isShipBeforePay ? { color: COLORS.SUMMARY_ORDER_DONUT_COLOR_SCALE.myTasks_payOther } : s.statusTableLabelColumnTxt,
          // ]}
        >
          {isShipBeforePay ? p.op.t('Order.is_ship_bofore_pay') : p.op.t('Order.ship')}
        </XText>
      </HStack>
    </VStack>
  )
}

export const CheckedIcon = <XIcon name='checkmark-circle' variant='success' />

export const UncheckedIcon = <XIcon name='radio-button-off' variant='inactive' />

function getOrderStateBoxes(
  order: Map<string, any>,
  selectedStore: ISelectedStoreMap
): Array<{
  key: number
  headerText: string
  headerTextSuffix: string
  subHeaderText: string
  data: Array<{
    text: string
    iconDisabled?: boolean
    status: boolean
  }>
  isCompleted?: boolean
  isLastColumn?: boolean
}> {
  const type = order.get('type')
  const state = order.get('state')
  // const payment_state = order.get('payment_state')
  const shipping_state = order.get('shipping_state')
  const isCancelState = isOrderInCancelled(order)

  const NO_ACTION = { text: '-', iconDisabled: true }

  const payment = computeOrderPaymentInfo(order)
  // default all flags to false
  const isShippingDoneSeller = shipping_state === 129
  let buyerHeaderText
  let buyerSubHeaderText
  let sellerHeaderText
  let sellerSubHeaderText

  const isConfirmDoneBuyer = state !== 101 && state !== 103
  const isConfirmDoneSeller = state !== 101 && state !== 102

  const isPaymentDoneBuyer = payment.isDoneBuyer
  const isPaymentDoneSeller = payment.isDoneSeller
  // const txtShipBeforePay = util.checkShipBeForePayStatus(order) ? ' (จัดส่งก่อนชำระ)' : ''
  // let remainingAmt = order.get('remaining_forecast_amount')

  switch (type) {
    case 1:
    case 2:
      buyerHeaderText = 'ตัวแทน'
      buyerSubHeaderText = order.get('partner_name')
      sellerHeaderText = 'ฉัน'
      sellerSubHeaderText = ''
      break
    case 3:
      buyerHeaderText = 'ฉัน'
      buyerSubHeaderText = ''
      sellerHeaderText = 'ผู้ขายส่ง'
      sellerSubHeaderText = order.get('partner_name')
      // isShippingDoneSeller = shipping_state === 129
      break
    default:
      break
  }
  if (type === 1) {
    buyerHeaderText = 'ลูกค้า'
    buyerSubHeaderText = order.get('partner_name')
  }
  let buyerData
  let sellerData
  if (isCancelState) {
    const payments: { [key: string]: any } = order.get('payments')
    const hasPayment = payments && payments.count() > 0
    buyerData = [
      NO_ACTION,
      hasPayment ? { text: payment.buyerStatusText, status: isPaymentDoneBuyer } : NO_ACTION,
      // hasPayment ? { text: payment.buyerStatusText, status: false } : NO_ACTION,
      NO_ACTION,
    ]
    sellerData = [NO_ACTION, hasPayment ? { text: payment.sellerStatusText, status: isPaymentDoneSeller } : NO_ACTION, NO_ACTION]
  } else {
    buyerData = []
    if (type === 1) {
      buyerData.push(NO_ACTION)
    } else {
      buyerData.push({ text: 'ยืนยัน', status: isConfirmDoneBuyer })
    }
    buyerData.push({ text: payment.buyerStatusText, status: isPaymentDoneBuyer })
    buyerData.push(NO_ACTION)

    sellerData = [
      { text: 'ยืนยัน', status: isConfirmDoneSeller },
      { text: payment.sellerStatusText, status: isPaymentDoneSeller },
      { text: 'จัดส่ง', status: isShippingDoneSeller },
    ]
  }

  // เช็คว่าการดำเนินการเสร็จสิ้นทั้งหมดแล้วหรือไม่
  const isCompletedBuyer = buyerData.reduce((prevFact, bd) => {
    if (bd.iconDisabled) {
      return prevFact
    }
    return prevFact && bd.status
  }, true)

  const isCompletedSeller = sellerData.reduce((prevFact, sd) => {
    if (sd.iconDisabled) {
      return prevFact
    }
    return prevFact && sd.status
  }, true)

  // for(let i = 0; i < buyerData.length; i++) {
  //   buyerData[i]['isCompleted'] = isCompletedBuyer
  //   sellerData[i]['isCompleted'] = isCompletedSeller
  // }

  // log('XXX buyerData => ', buyerData)
  // log('XXX sellerData => ', sellerData)
  let sellerType = ' (ผู้ขาย)'
  if (order.has('helper_profile_id')) {
    const helper = order.get('helper_profile_id')
    // const { selectedStore } = this.props
    const helpers = selectedStore.has('helpers') ? selectedStore.get('helpers') : null
    if (!_.isNil(helpers)) {
      helpers.map((h) => {
        if (h.get('pid') === helper) {
          if (h.has('n')) {
            sellerType = ` [${h.get('n')}]`
          } else {
            sellerType = ''
          }
          sellerHeaderText = 'ผู้ช่วย'
        }
      })
    }
  }
  return [
    {
      key: 0,
      headerText: buyerHeaderText,
      headerTextSuffix: ' (ผู้ซื้อ)',
      subHeaderText: buyerSubHeaderText,
      data: buyerData,
      isCompleted: isCompletedBuyer,
    },
    {
      key: 1,
      headerText: sellerHeaderText,
      headerTextSuffix: sellerType,
      subHeaderText: sellerSubHeaderText,
      data: sellerData,
      isLastColumn: true,
      isCompleted: isCompletedSeller,
    },
  ]
}

function computeOrderPaymentInfo(order: Map<string, any>): { [key: string]: boolean | string } {
  const payment_state = order.get('payment_state')
  const ps = payment_state
  const payment = {
    isDoneBuyer: false,
    isDoneSeller: false,
    buyerStatusText: 'ชำระ',
    sellerStatusText: 'ยืนยัน',
  }

  const remainingAmt = order.get('remaining_forecast_amount')
  if (ps === 119) {
    payment.isDoneBuyer = true
    payment.isDoneSeller = true
  } else {
    if (remainingAmt <= 0 && ps !== 111 && ps !== 113 && ps !== 115) {
      payment.isDoneBuyer = true
    }

    if (remainingAmt >= 0 && ps !== 111 && ps !== 112 && ps !== 113 && ps !== 114) {
      payment.isDoneSeller = true
    }

    if (remainingAmt < 0 && ps === 112) {
      // refund case
      // display the following text for the case that seller has confirmed for the overpay
      payment.isDoneBuyer = false
      // payment.isDoneSeller = false
      payment.buyerStatusText = 'ยืนยัน'
      payment.sellerStatusText = 'คืนเงิน'
    }
    if (ps === 115) {
      payment.buyerStatusText = 'ยืนยัน'
      payment.sellerStatusText = 'คืนเงิน'
    } else if (ps === 113) {
      payment.buyerStatusText = 'ยืนยัน'
      payment.sellerStatusText = 'ยืนยัน'
    }
  }

  return payment
}

function isOrderInCancelled(order: Map<string, any>): boolean {
  const state: number = order.get('state')
  return state >= 181 && state <= 184
}

export const s = StyleSheet.create({
  statusTableColumnContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  statusTableLabelColumnViewBlank: {
    width: '100%',
    height: 20,
    borderWidth: 0,
  },
  statusTableLabelColumnView: {
    width: '100%',
    height: 20,
    borderWidth: 1,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderColor: COLORS.TEXT_INACTIVE,
    flexDirection: 'row',
    alignItems: 'center',
    borderTopWidth: p.op.isWeb() ? 1 : 0,
    borderLeftWidth: p.op.isWeb() ? 1 : 0,
  },
  statusTableLabelColumnViewLastRow: {
    width: '100%',
    height: p.op.isWeb() ? 21 : 20,
    borderWidth: 1,
    borderRightWidth: 0,
    borderColor: COLORS.TEXT_INACTIVE,
    flexDirection: 'row',
    alignItems: 'center',
    borderTopWidth: p.op.isWeb() ? 1 : 0,
    borderLeftWidth: p.op.isWeb() ? 1 : 0,
    borderBottomWidth: p.op.isWeb() ? 1 : 0,
  },
  statusTableLabelColumnTxt: {
    fontSize: STYLES.FONT_SIZE_SMALLER,
    color: COLORS.TEXT_INACTIVE,
    paddingLeft: 0,
  },
  itemLabel: {
    borderBottomWidth: 0,
    marginTop: 5,
  },
  shippingIcon: {
    fontSize: STYLES.FONT_ICON_SMALLER,
    color: COLORS.APP_MAIN,
    paddingRight: 6,
    paddingTop: 1,
  },
  productSummaryTableHeader: {
    flex: 1,
    height: 25,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  productSummaryTableHeaderText: {
    flex: 1,
    textAlign: 'center',
    fontSize: STYLES.FONT_SIZE_SMALLER,
    color: COLORS.TEXT_ACTIVE,
  },
  orderStateIcon: {
    width: 22,
    paddingTop: 1,
    fontSize: STYLES.FONT_ICON_SMALLER - 2,
    paddingRight: 1,
    textAlign: 'center',
  },
  paymentStateIcon: {
    width: 22,
    paddingTop: 1,
    fontSize: STYLES.FONT_ICON_SMALLER,
    textAlign: 'center',
  },
  shippingStateIcon: {
    width: 22,
    paddingTop: 1,
    fontSize: STYLES.FONT_ICON_SMALLER,
    textAlign: 'center',
  },
  shippingTypePickerItemContainer: {
    borderBottomWidth: p.op.isWeb() ? 0 : 1,
    borderBottomColor: COLORS.TEXT_INACTIVE,
    paddingLeft: 12,
    paddingVertical: 8,
  },
  colFixedWidth50: {
    flex: 0,
    width: 50,
  },
  justifyContentCenter: {
    justifyContent: 'center',
  },
})

export default OrderStatesTable
