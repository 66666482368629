import React from 'react'

import { View, Text } from 'react-native'

import { STYLES } from 'x/config/styles'

// import XInput from 'xui/components/XInput'

import BaseUIProductView from 'xui/modules/product/BaseUIProductView'

import xCONS from 'x/config/constants'
import { getConfig } from 'x/config/mode'
// import ProductVariantItem from './ProductVariantItem'
import { CommonActions } from '@react-navigation/native'
import { List } from 'immutable'
import { downloadFileFromUrl } from '../../utils/util-web'
import ThemeSizeContext from '../../context/ThemeSizeContext'

const config = getConfig()
const {
  ADD,
  VIEW_SELF,
  VIEW_SELLER,
  EDIT_SELF,
  EDIT_SELLER,
  PULL_MY_PRODUCT_TO_ORDER,
  PULL_SELLER_PRODUCT_TO_ORDER,
  PULL_PRODUCT,
  VIEW_VOLUME_DISCOUNT,
} = xCONS.PRODUCT_VIEW_MODE

const { CARD_COMMON, NO_MARGIN } = STYLES

const { Fragment } = React

export default class ProductView extends BaseUIProductView {
  static displayName = 'ProductView'

  static inProcess = false // MUST DO at static level since doing at the "this" base's instance level is NOT fast enough to block double click

  static contextType = ThemeSizeContext

  context!: React.ContextType<typeof ThemeSizeContext>

  static navigationOptions = {
    header: null,
    title: null,
    headerLeft: null,
    headerRight: null,
  }

  handleOnDownloadFile = async (url: string, fileName: string): Promise<void> => {
    // await utilN.openFileFromUrl(url, fileName, 'xlsx')
    // console.log('No.Impl ProductView::handleOnDownloadFile')
    await downloadFileFromUrl({ url, fileName })
  }

  openPDFFromUrl = async (url: string, fileName: string): Promise<void> => {
    // utilN.openPDFFromUrl(url, fileName)
    // console.log('No.Impl ProductView::openPDFFromUrl url => ', url)
    // console.log('No.Impl ProductView::openPDFFromUrl fileName => ', fileName)
    await downloadFileFromUrl({ url, fileName })
  }

  navToStoreSettingStoreProductSN = () => {
    // this.props.navigation.dispatch(NavActions.navToStoreSettingStoreProductSN)
    console.log('No.Impl ProductView::navToStoreSettingStoreProductSN')
  }

  navToVolumeDiscountListView = (params) => {
    // this.props.navigation.dispatch(NavActions.navToVolumeDiscountListView(params))
    console.log('No.Impl ProductView::navToVolumeDiscountListView')
  }

  // renderProductImages = (): JSX.Element => {
  //   const { submitting, uploading, showUploaded } = this.state
  //   const { txtShareContent, shareClipboardToastMessage } = this._getShareClipboardProperties()
  //   let isEditable = this.isEditableProductMap.get('img_uris')
  //   isEditable = isEditable && !submitting && !uploading
  //   // 32 is padding buffer
  //   const maxWidth = this.context.contentOneOfThreeColumnWidth - xCONS.SPECIFIC_PLATFORM.WEB.SCROLLBAR_WIDTH - 32
  //   return (
  //     <View style={{ maxWidth }}>
  //       <ImgManager
  //         fileNamePrefix={config.s3_prefix_type_product}
  //         // metadata={[
  //         // { key: 'image', width: 200, height: 120 },
  //         // { key: 'thumbnail', width: 150, height: 80 },
  //         // ]}
  //         ref={(r) => r && (this.imgManagerRef = r)}
  //         uploading={uploading}
  //         showUploaded={showUploaded}
  //         shareClipboardText={txtShareContent}
  //         shareClipboardToastMessage={shareClipboardToastMessage}
  //         readonly={!isEditable}
  //       />
  //     </View>
  //   )
  // }

  // renderProductVariantItem = (vProps) => <ProductVariantItem {...vProps} />

  // Override max width for web only
  getVariantContainerMaxWidth = () => this.context.contentOneOfThreeColumnWidth - 16

  // _renderPGVDList = (): JSX.Element | JSX.Element[] => {
  //   // const { vds, productGroups, selectedIds } = params
  //   // const pgs = List.isList(productGroups) ? productGroups.toJS() : productGroups
  //   const { editingProduct, selectedProductGroups, selectedStore } = this.props
  //   const pgs = List.isList(selectedProductGroups) ? selectedProductGroups.toJS() : selectedProductGroups
  //   const vds = selectedStore.get('vds')
  //   const selectedPgIds = editingProduct.get('product_group_ids') || List([])
  //   const selectedIds = List.isList(selectedPgIds) ? selectedPgIds.toJS() : selectedPgIds
  //   const { mode } = this.state
  //   let textWarning = `กรุณากดปุ่ม แก้ไข (ด้านขวาบน)\nและแตะปุ่มนี้ เพื่อผูกราคาขาย สำหรับสินค้านี้`
  //   if (mode !== 'VIEW_SELF') {
  //     textWarning = `กรุณาแตะเพื่อ ผูกราคาขาย สำหรับสินค้านี้\n(แล้วจึงระบุราคาด้านล่าง)`
  //   }
  //   const pg_vd_ids = editingProduct.get('pg_vd_ids')

  //   let renderVolumeDiscount = false
  //   if (pg_vd_ids) {
  //     renderVolumeDiscount = true
  //   }
  //   if (selectedIds.length > 0) {
  //     return selectedIds.map((pg_id, idx) => {
  //       const selectedGroup = pgs.find((pg) => pg.id === pg_id)
  //       if (!selectedGroup || !selectedGroup.id || !selectedGroup.name) {
  //         return null
  //       }
  //       const s_use_volume_discount = this.props.selectedStore.get('s_use_volume_discount')
  //       let txt
  //       let selectedVD
  //       if (renderVolumeDiscount) {
  //         selectedVD = pg_vd_ids.map((vd) => {
  //           // @ts-ignore
  //           if (vd.pg_id === pg_id) {
  //             vds.map((valDiscount) => {
  //               // @ts-ignore
  //               if (valDiscount.get('id') === vd.get('vd_id')) {
  //                 // @ts-ignore
  //                 return (txt = getTxtvalueDiscount(valDiscount))
  //               }
  //             })
  //             return txt
  //           }
  //         })
  //       }
  //       return (
  //         <Row size={7} key={idx}>
  //           <View
  //             style={{
  //               padding: 4,
  //               marginRight: 4,
  //               marginBottom: 2,
  //               borderWidth: 1,
  //               borderStyle: 'solid',
  //               borderColor: COLORS.APP_SECONDARY,
  //               // borderColor: BG_LIGHT_GREY,
  //               marginTop: 4,
  //               borderRadius: 4,
  //               flex: 1,
  //               // backgroundColor: APP_SECONDARY,
  //             }}>
  //             <Text
  //               style={{
  //                 // color: TEXT_INACTIVE,
  //                 color: COLORS.TEXT_ACTIVE,
  //                 textAlign: 'left',
  //                 fontSize: STYLES.FONT_SIZE_NORMAL,
  //                 fontWeight: 'bold',
  //                 marginTop: 0,
  //                 paddingLeft: 4,
  //               }}>
  //               {selectedGroup.name}
  //             </Text>
  //             {s_use_volume_discount ? (
  //               <View>
  //                 {renderVolumeDiscount ? (
  //                   <Text
  //                     style={{
  //                       // color: TEXT_INACTIVE,
  //                       color: COLORS.TEXT_INACTIVE,
  //                       textAlign: 'left',
  //                       fontSize: STYLES.FONT_SIZE_NORMAL,
  //                       fontWeight: 'bold',
  //                       marginTop: 0,
  //                       paddingLeft: 4,
  //                     }}>
  //                     {selectedVD}
  //                   </Text>
  //                 ) : null}
  //               </View>
  //             ) : null}
  //           </View>
  //           {/* <VDModalSelectedText>{selectedGroup.name} BBCCTOME</VDModalSelectedText> */}
  //         </Row>
  //       )
  //     })
  //   }
  //   if (pgs.length > 0) {
  //     return (
  //       <Row size={7}>
  //         <View
  //           style={{
  //             padding: 4,
  //             marginRight: 4,
  //             marginBottom: 2,
  //             borderStyle: 'solid',
  //             marginTop: 4,
  //             flex: 1,
  //             paddingLeft: 0,
  //           }}>
  //           <Text
  //             style={{
  //               // color: TEXT_INACTIVE,
  //               color: 'red',
  //               textAlign: 'left',
  //               fontSize: STYLES.FONT_SIZE_NORMAL,
  //               fontWeight: 'bold',
  //               marginTop: 0,
  //               paddingLeft: 0,
  //             }}>
  //             {textWarning}
  //           </Text>
  //         </View>
  //       </Row>
  //     )
  //   }
  //   // KEEP_ME :: อาจได้ใช้ ถ้าไม่ติดปัญหาอะไร จะมาลบ artid
  //   // else if (pgs.length > 0) {
  //   //   return pgs.map((pg_id, idx) => {
  //   //     console.log('pg_id', pg_id)
  //   //     return (
  //   //       <Row size={7} key={idx}>
  //   //         <View
  //   //           style={{
  //   //             padding: 4,
  //   //             marginRight: 4,
  //   //             marginBottom: 2,
  //   //             borderWidth: 1,
  //   //             borderStyle: 'solid',
  //   //             borderColor: APP_SECONDARY,
  //   //             // borderColor: BG_LIGHT_GREY,
  //   //             marginTop: 4,
  //   //             borderRadius: 4,
  //   //             flex: 1,
  //   //             // backgroundColor: APP_SECONDARY,
  //   //           }}>
  //   //           <Text
  //   //             style={{
  //   //               // color: TEXT_INACTIVE,
  //   //               color: TEXT_ACTIVE,
  //   //               textAlign: 'left',
  //   //               fontSize: STYLES.FONT_SIZE_NORMAL,
  //   //               fontWeight: 'bold',
  //   //               marginTop: 0,
  //   //               paddingLeft: 4,
  //   //             }}>
  //   //             {pg_id.name}
  //   //           </Text>
  //   //         </View>
  //   //         {/* <VDModalSelectedText>{selectedGroup.name} BBCCTOME</VDModalSelectedText> */}
  //   //       </Row>
  //   //     )
  //   //   })
  //   // }
  // }

  _openPGSelector = () => {
    const { mode } = this.state
    const product_group_ids = this.props.editingProduct.get('product_group_ids') || List([])

    // this.props.navigation.navigate({
    //   routeName: 'PGSelectorView',
    //   params: {
    //     selectedIds: product_group_ids,
    //     mode,
    //     onSubmitEditing: this._onChangeProductGroups,
    //     // onEmptySubmitEditing: this._onChangeProductGroups,
    //   },
    // })

    const { navigation } = this.props
    navigation.dispatch(
      CommonActions.navigate({
        name: 'PGSelectorView',
        params: {
          selectedIds: product_group_ids,
          mode,
          onSubmitEditing: this._onChangeProductGroups,
          // onEmptySubmitEditing: this._onChangeProductGroups,
        },
      })
    )
  }

  // renderProductGroupCard = ({ mode, vds, editingProduct, isEditable, product_groups, product_group_ids, s_use_volume_discount }) => {
  //   const disabled = !isEditable
  //   return (
  //     <Card
  //       style={{ flex: 1, flexBasis: 'auto' }}
  //       // style={style}>
  //     >
  //       <Pressable
  //         style={{ flex: 1, paddingTop: 5, paddingBottom: 5, paddingLeft: 5, paddingRight: 5, backgroundColor: 'transparent' }}
  //         onPress={disabled ? null : this._openPGSelector}>
  //         <Stack style={{ marginLeft: 12, marginRight: 12, flex: 1 }}>
  //           <VStack size={85}>
  //             <Row size={2} style={{ paddingBottom: 4 }}>
  //               <Text style={{ color: COLORS.TEXT_INACTIVE, fontSize: STYLES.FONT_SIZE_NORMAL }}>อยู่ในรายการราคา</Text>
  //             </Row>
  //             {this._renderPGVDList()}
  //             {/* <Row size={6}>
  //               <VDModalSelectedText key={1}>BBBBBBBBBBBBBBBBBBBBBBB</VDModalSelectedText>
  //             </Row> */}
  //           </VStack>
  //           {isEditable ? (
  //             <VStack size={15} style={{ justifyContent: 'center', alignItems: 'flex-end' }}>
  //               <ForwardIcon style={{ flex: 0 }} />
  //             </VStack>
  //           ) : null}
  //         </Stack>
  //         {/* <Stack>
  //           <Row>
  //             <VStack size={17} style={{ paddingHorizontal: 8 }}>
  //               <Row>
  //                 <VStack
  //                   size={1}
  //                   style={{
  //                     justifyContent: 'flex-start',
  //                     alignItems: 'flex-start',
  //                     paddingHorizontal: 8,
  //                     paddingVertical: 4,
  //                   }}>
  //                   <Text
  //                     style={{
  //                       flex: 1,
  //                       textAlign: 'left',
  //                       fontSize: STYLES.FONT_SIZE_NORMAL,
  //                       // color: TEXT_ACTIVE,
  //                       color: TEXT_INACTIVE,
  //                       fontWeight: 'bold',
  //                       marginTop: 0,
  //                     }}>
  //                     {'อยู่ในรายการราคา'}
  //                   </Text>
  //                 </VStack>
  //               </Row>
  //               <Row
  //                 style={{
  //                   flexDirection: 'row',
  //                   flexWrap: 'wrap',
  //                   // justifyContent: 'center',
  //                   justifyContent: 'flex-start',
  //                   alignItems: 'flex-start',
  //                   paddingHorizontal: 8,
  //                 }}>
  //                 {this._renderSelectedProductGroups(productGroups, selectedIds)}
  //               </Row>
  //             </VStack>
  //             <VStack
  //               size={3}
  //               style={{
  //                 justifyContent: 'center',
  //                 alignItems: 'flex-end',
  //                 paddingHorizontal: 8,
  //               }}>
  //               {disabled ? null : <Icon name="arrow-forward" style={{ color: COLORS.TEXT_PRIMARY }} />}
  //             </VStack>
  //           </Row>
  //         </Stack> */}
  //       </Pressable>
  //     </Card>
  //   )

  //   // const {
  //   //   navigation,
  //   //   selectedStore,
  //   //   updateContInVolumeDiscounts,
  //   //   updateDataVolumeDiscountAndProductGroup,
  //   //   loadListVolumeDiscounts,
  //   // } = this.props
  //   // return (
  //   //   <PGModalSelector
  //   //     // style={[CARD_COMMON, { marginLeft: 10 }]}
  //   //     // selectedIds={ List([1, 2, 3]) }
  //   //     navigation={navigation}
  //   //     vds={vds}
  //   //     editingProduct={editingProduct}
  //   //     disabled={!isEditable}
  //   //     style={{ marginLeft: 0, marginRight: 0 }}
  //   //     productGroups={product_groups}
  //   //     selectedIds={product_group_ids}
  //   //     allowedEmptySelected={false}
  //   //     onSubmitEditing={this._onChangeProductGroups}
  //   //     unpairVolumeDiscount={this.props.unpairVolumeDiscount}
  //   //     selectedStore={selectedStore}
  //   //     updateContInVolumeDiscounts={updateContInVolumeDiscounts}
  //   //     updateDataVolumeDiscountAndProductGroup={updateDataVolumeDiscountAndProductGroup}
  //   //     mode={mode}
  //   //     s_use_volume_discount={s_use_volume_discount}
  //   //     loadListVolumeDiscounts={loadListVolumeDiscounts}
  //   //     // onSubmitEditing={ (newPgIds) => onChangeProduct({
  //   //     //   key: 'product_group_ids',
  //   //     //   value: newPgIds,
  //   //     // }) }
  //   //     // onEmptySubmitEditing={ () => {
  //   //     //   p.op.alert('ไม่สามารถบันทึก', 'กรุณาเลือกอย่างน้อย 1 รายการราคาให้กับสินค้าชิ้นนี้')
  //   //     // }}
  //   //     // onEmptySubmitEditing={this._onChangeProductGroups}
  //   //   />
  //   // )
  // }

  renderVolumeDiscountCard = (discountOf: 'my' | 'seller'): JSX.Element => (
    <View>
      <Text>No.Impl ProductView::renderVolumeDiscountCard (RangeAdjuster)</Text>
    </View>
  )
  // const isEnableVdMocking = _.has(this.state, 'isEnableVdMocking') ? this.state.isEnableVdMocking : false
  // if (!isEnableVdMocking) {
  //   return null
  // }
  //
  // if (!discountOf) {
  //   return null
  // }
  //
  // const { editingProduct, onChangeProduct } = this.props
  // // const { mode } = this._getParams()
  // const { mode } = this.state
  // const vdsKey = `${discountOf}_vds`
  // const vdsTypeKey = `${vdsKey}_type`
  // const vds = editingProduct.get(vdsKey)
  // const vds_type = editingProduct.get(vdsTypeKey)
  //
  // if (
  //   (!vds && discountOf === 'seller') ||
  //   (!_.includes([ADD, EDIT_SELF, PULL_PRODUCT], mode) && (!vds || !vds_type || vds.size < 2)) ||
  //   (mode === ADD && discountOf === 'seller')
  // ) {
  //   // log('ProductView _renderVolumeDiscountCard no vds of ', discountOf)
  //   // ถ้าไม่มี vds เลย ให้ซ่อนไป
  //   return null
  // }
  //
  // const txtVdTitle = this.OPTIONS_VOLUME_DISCOUNT_TITLES[discountOf]
  // const txtVdSubtitle = this.OPTIONS_VOLUME_DISCOUNT_SUBTITLES[discountOf]
  // // const txtVdType = this.OPTIONS_VOLUME_DISCOUNT_MENU_TYPES[vds_type]
  // const txtVdColumn = this.OPTIONS_VOLUME_DISCOUNT_COLUMN_TYPES[vds_type]
  // const isEditable = discountOf === 'my' && _.includes([ADD, EDIT_SELF, PULL_PRODUCT], mode)
  // const isVisible =
  //   (discountOf === 'seller' && vds_type > 0) ||
  //   (discountOf === 'my' && (vds_type > 0 || _.includes([ADD, EDIT_SELF, PULL_PRODUCT], mode)))
  //
  // let usePrefixOrSuffix
  // const prefix = '฿'
  // const suffix = '%'
  // if (vds_type === 1) {
  //   usePrefixOrSuffix = prefix
  // } else if (vds_type === 2) {
  //   usePrefixOrSuffix = suffix
  // }
  // return isVisible ? (
  //   <Card
  //     // @ts-ignore CARD_COMMON
  //     style={[CARD_COMMON, NO_MARGIN, { marginTop: 5, paddingTop: 5, paddingBottom: 5 }]}>
  //     <Box cardBody={true} style={{ paddingHorizontal: 3, paddingVertical: 5 }}>
  //       <Stack>
  //         {/* Title */}
  //         <Row>
  //           <VDColTitle size={1}>
  //             <VDTitle>{txtVdTitle}</VDTitle>
  //             <VDSubtitle>{txtVdSubtitle}</VDSubtitle>
  //           </VDColTitle>
  //         </Row>
  //         {isEditable ? (
  //           <Row>
  //             <VDColTitle size={1}>
  //               <Segment
  //                 onSegmentChange={this._onChangeVolumeDiscountType}
  //                 selectedIndex={vds_type}
  //                 // onChange={ newVdType => {
  //                 //   const newVdTypeIndex = this.OPTIONS_VOLUME_DISCOUNT_MENU_TYPES
  //                 //     .findIndex(type => type === newVdType)
  //                 //   onChangeProduct({ key: vdsTypeKey, value: newVdTypeIndex })
  //                 //
  //                 //   if (_.includes([1, 2], newVdTypeIndex) && _.includes([ADD, EDIT_SELF], mode)) {
  //                 //     // Set To Default Value
  //                 //     onChangeProduct({ key: vdsKey, value: this.VOLUME_DISCOUNT_TEMPLATE })
  //                 //   }
  //                 // }}
  //                 options={this.OPTIONS_VOLUME_DISCOUNT_MENU_TYPES}
  //                 // selectedValue={txtVdType}
  //               />
  //             </VDColTitle>
  //           </Row>
  //         ) : null}
  //       </Stack>
  //     </Box>
  //     {vds_type > 0 ? (
  //       <Box cardBody={true} style={{ paddingHorizontal: 12, paddingVertical: 8 }}>
  //         <RangeAdjuster
  //           disabled={!isEditable}
  //           metadata={[
  //             {
  //               // key: discountKey,
  //               prefix,
  //               suffix,
  //               usePrefixOrSuffix,
  //               key: 'discount',
  //               label: vds_type > 0 ? `ส่วนลด (${txtVdColumn})` : 'ส่วนลด',
  //               span: 2,
  //               defaultValue: '',
  //               keyboardType: 'numeric',
  //               filteredMethod: (newValue: string) => {
  //                 const newDiscount = !_.isNaN(newValue) && !_.isNull(newValue) && !_.isUndefined(newValue) ? parseFloat(newValue) : null
  //                 log('onChangeMetaField vds_type => ', vds_type)
  //                 log('onChangeMetaField newDiscount => ', newDiscount)
  //                 if (vds_type === 1 && _.isNumber(newDiscount)) {
  //                   if (newDiscount >= 0 && newDiscount <= 100) {
  //                     return newDiscount
  //                   } else if (newDiscount > 100) {
  //                     return 100
  //                   } else {
  //                     return 0
  //                   }
  //                 } else if (vds_type === 2 && _.isNumber(newDiscount)) {
  //                   if (newDiscount >= 0) {
  //                     return newDiscount
  //                   } else {
  //                     return 0
  //                   }
  //                 }
  //                 return 0
  //               },
  //             },
  //           ]}
  //           data={vds}
  //           // onChange={newVds => {
  //           //   onChangeProduct({ key: vdsKey, value: newVds })
  //           // }}
  //           onChange={this._onChangeVolumeDiscount}
  //           labelAddButton={'เพิ่มช่วงส่วนลด'}
  //           labelLastEnd={'ขึ้นไป'}
  //         />
  //       </Box>
  //     ) : null}
  //   </Card>
  // ) : null

  // _renderCategoryListOverlay = () => {
  //   const { visibleCategoryList } = this.state
  //   const { fetchCategory, selectedStore, editingProduct } = this.props
  //   const allCategory = []
  //   const categoryInProduct = _.isNil(editingProduct.get(`category`)) ? null : editingProduct.get(`category`).toJS()
  //   // const visibleCategoryList = false
  //   const categoryId = _.isNil(categoryInProduct) ? null : categoryInProduct.id
  //   return (
  //     <CategoryListOverlay
  //       submit={(Cat: ICategory) => this._submitSeletedCategory(Cat)}
  //       mode='SELETED_CATEGORY'
  //       store_id={selectedStore.get(`id`)}
  //       fetchCategory={fetchCategory}
  //       categoetProps={allCategory}
  //       visible={visibleCategoryList}
  //       onRequestClose={() => this._closeCategoryList()}
  //       category_id={categoryId}
  //     />
  //   )
  // }

  // renderMain = () => {
  //   logRender(this)
  //   const { editingProduct } = this.props
  //   const { mode, isInitialized } = this.state
  //   const { contentOneOfThreeColumnWidth, contentOneOfThreeColumnWidthStyle, contentHeightStyle } = this.context

  //   if (!isInitialized) {
  //     return (
  //       <Text
  //         style={{
  //           flex: 1,
  //           marginTop: 40,
  //           fontSize: STYLES.FONT_SIZE_NORMAL,
  //           color: COLORS.TEXT_INACTIVE,
  //           textAlign: 'center',
  //         }}>
  //         กำลังโหลดข้อมูล...
  //       </Text>
  //     )
  //     // return (
  //     //   <Spinner color={COLORS.APP_MAIN} size='small' />
  //     // )
  //   }

  //   // // TODO: refactor me out
  //   // const {
  //   //   shallRenderShippingRatesSeller,
  //   //   shallRenderShippingRatesMy,
  //   //   shippingRatesMy,
  //   //   shippingRatesSeller,
  //   //   shippingRateStatusesMy,
  //   //   shippingRateStatusesSeller,
  //   //   editableShippingRatesMy,
  //   // } = this._getMainProductRenderProperties(editingProduct, mode)

  //   const maxWidthContainer = contentOneOfThreeColumnWidth - xCONS.SPECIFIC_PLATFORM.WEB.SCROLLBAR_WIDTH
  //   const maxProductImageContainerWidth = this.context.contentOneOfThreeColumnWidth - xCONS.SPECIFIC_PLATFORM.WEB.SCROLLBAR_WIDTH - 32

  //   return (
  //     <XContainer style={[contentOneOfThreeColumnWidthStyle, contentHeightStyle]}>
  //       {this._renderCategoryListOverlay()}
  //       {this._renderCustomHeader()}

  //       {this._renderReportHistory()}
  //       {this._renderReportProductDailySales()}

  //       <XContent
  //         // refreshControl={
  //         //   !_.includes([ADD, EDIT_SELF, EDIT_SELLER], mode) ? (
  //         //     <RefreshControl refreshing={this.state.refreshing} onRefresh={this._onRefresh} />
  //         //   ) : null
  //         // }
  //         // extraScrollHeight={80}
  //         // keyboardShouldPersistTaps={xCONS.KEYBOARD_PERSIST_TAB_MODE}
  //         // keyboardDismissMode='on-drag'
  //         // enableResetScrollToCoords={false}
  //         // padder={false}
  //         // enableOnAndroid={true}
  //         // @ts-ignore
  //         ref={(r) => r && (this.scrollViewRef = r._root)}
  //         // onContentSizeChange={(contentWidth, contentHeight) => {
  //         //   // @ts-ignore
  //         //   this.scrollToBottomY = contentHeight
  //         // }}
  //         style={[contentOneOfThreeColumnWidthStyle, contentHeightStyle]}>
  //         <Card
  //           style={{
  //             marginLeft: 3,
  //             marginRight: 3,
  //             flexBasis: 'auto',
  //             flexWrap: 'wrap',
  //             maxWidth: maxWidthContainer,
  //           }}>
  //           {/* { this._renderParentStoreName() } */}
  //           {/* <Box>{this._renderProductImages()}</Box> */}
  //           <Box style={{ flexWrap: 'wrap', maxWidth: maxWidthContainer }}>
  //             <View style={{ width: maxProductImageContainerWidth }}>{this.renderProductImages()}</View>
  //           </Box>
  //           {/* <Box cardBody={true}>{this._renderCopyClipboardButton()}</Box> */}
  //           <Box style={{ flexBasis: 'auto', flexWrap: 'wrap' }}>
  //             <Stack space={5} style={{ flex: 1, alignSelf: 'stretch', paddingHorizontal: 12, flexBasis: 'auto', flexWrap: 'wrap' }}>
  //               {this._renderProductName()}
  //               {this._renderProductDescription()}
  //               {/* {this.renderVolumeDiscountCard('seller')} */}
  //               {/* {this.renderVolumeDiscountCard('my')} */}
  //               {this._renderProductGroupCard()}
  //               {this._renderProductWarehouse()}
  //               {/* <Button title={'Hello TempView'} onPress={() => { */}
  //               {/*  this.props.navigation.push('TempView', { */}
  //               {/*      x: Math.random(), */}
  //               {/*  }) */}
  //               {/* }} /> */}
  //               {this._renderPulledProductStoreName()}

  //               {/* TODO: Refactor to dynamic component */}

  //               {this._renderAllShippingRates()}
  //               {/* {shallRenderShippingRatesSeller */}
  //               {/* ? this._renderShippingRatesSeller({ */}
  //               {/* shippingTypeId: xCONS.SHIPPING_TYPE_IDS.REGISTER, */}
  //               {/* shippingRates: shippingRatesSeller, */}
  //               {/* shippingRateStatuses: shippingRateStatusesSeller, */}
  //               {/* }) */}
  //               {/*: null} */}
  //               {/* {shallRenderShippingRatesSeller */}
  //               {/* ? this._renderShippingRatesSeller({ */}
  //               {/* shippingTypeId: xCONS.SHIPPING_TYPE_IDS.EMS, */}
  //               {/* shippingRates: shippingRatesSeller, */}
  //               {/* shippingRateStatuses: shippingRateStatusesSeller, */}
  //               {/* }) */}
  //               {/*: null} */}
  //               {/* {shallRenderShippingRatesMy */}
  //               {/* ? this._renderShippingRatesMy({ */}
  //               {/* shippingTypeId: xCONS.SHIPPING_TYPE_IDS.REGISTER, */}
  //               {/* shippingRates: shippingRatesMy, */}
  //               {/* shippingRateStatuses: shippingRateStatusesMy, */}
  //               {/* editable: editableShippingRatesMy, */}
  //               {/* }) */}
  //               {/*: null} */}
  //               {/* {shallRenderShippingRatesMy */}
  //               {/* ? this._renderShippingRatesMy({ */}
  //               {/* shippingTypeId: xCONS.SHIPPING_TYPE_IDS.EMS, */}
  //               {/* shippingRates: shippingRatesMy, */}
  //               {/* shippingRateStatuses: shippingRateStatusesMy, */}
  //               {/* editable: editableShippingRatesMy, */}
  //               {/* }) */}
  //               {/*: null} */}

  //               {/* {this._renderCategory()} */}
  //               {this._renderCategoryNewVersion()}

  //               {/* { navigation.state.params.mode === 'add' ? this._renderAddMode() : <View /> } */}
  //             </Stack>
  //           </Box>
  //           <Box>{this._renderProductVariants()}</Box>
  //         </Card>
  //         {this._renderDeleteProductButton()}
  //         {/* {this._renderEnableMockingButton()} */}
  //       </XContent>
  //     </XContainer>
  //   )
  // }

  // _renderReportProductDailySales = () => {
  //   const { subscription, selectedProduct, selectedStore } = this.props
  //   const { isReportProductDailySales } = this.state
  //   const r_shpg_day = subscription.get('r_shpg_day')
  //   const variants = selectedProduct.get('variants')
  //   const store_id = selectedStore.get('id')
  //   // console.log('variants', variants.toJS())
  //   return (
  //     <ReportProductDailySales
  //       onRequestClose={() => this._handleCloseReportProductDailySales}
  //       visible={isReportProductDailySales}
  //       allowOnlyDaysFromToday={r_shpg_day}
  //       store_id={store_id}
  //       variants={variants}
  //     />
  //   )
  // }

  // _renderReportProductDailySales = () => {
  //   return (
  //     <View>
  //       <Text>No.Impl ProductView::_renderReportProductDailySales (ReportProductDailySales Component)</Text>
  //     </View>
  //   )
  // }
}
