// import * as nbUtil from 'x/utils/nativebase'

// // แก้ปัญหา Web ไม่สามารถใช้ borderWidth={'1'} ได้
// const baseStyle = (props: Record<string, any>) => {
//   const injectBorderProps = nbUtil.getInjectBorderUtilityProps(props)
//   return {
//     ...injectBorderProps,
//     // ...props,
//   }
// }

export default {
  // baseStyle,
}
