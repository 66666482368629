import React from 'react'
import { TouchableOpacity, Linking } from 'react-native'
import _ from 'lodash'

import XCustomHeader from 'xui/components/XCustomHeader'

import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'

import BaseStoreSettingPDFShippingLabel from 'x/modules/store/BaseStoreSettingPDFShippingLabel'
import XContent from 'xui/components/XContent'
import XContainer from 'xui/components/XContainer'
import XText from 'xui/components/XText'
import XCard from 'xui/components/XCard'
import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import XSwitch from 'xui/components/XSwitch'
import XIcon from 'xui/components/XIcon'
import XButton from 'xui/components/XButton'
import Box from 'xui/components/Box'
import BackIcon from '../../components/BackIcon'

const p11 = 'https://xsf.sgp1.cdn.digitaloceanspaces.com/x/samples/sl/11_eco.pdf'
const p31 = 'https://xsf.sgp1.cdn.digitaloceanspaces.com/x/samples/sl/31_nopic.pdf'
const p32 = 'https://xsf.sgp1.cdn.digitaloceanspaces.com/x/samples/sl/32_nopic_price.pdf'
const p33 = 'https://xsf.sgp1.cdn.digitaloceanspaces.com/x/samples/sl/33_nopic_price_total.pdf'
const p21 = 'https://xsf.sgp1.cdn.digitaloceanspaces.com/x/samples/sl/21_pic.pdf'
const p22 = 'https://xsf.sgp1.cdn.digitaloceanspaces.com/x/samples/sl/22_pic_price.pdf'
const p23 = 'https://xsf.sgp1.cdn.digitaloceanspaces.com/x/samples/sl/23_pic_price_total.pdf'

export default abstract class BaseUIStoreSettingPaperangView extends BaseStoreSettingPDFShippingLabel {
  static displayName = 'BaseUIStoreSettingPaperangView'

  // abstract renderMain?(): JSX.Element

  renderHeader = () => (
    <XCustomHeader
      title={p.op.t('StoreSetting.HeaderPDFA4ShippingLabel')}
      renderHeaderLeft={this._renderHeaderLeft}
      renderHeaderRight={this._renderHeaderRight}
    />
  )

  _renderHeaderRight = () => {
    const params = util.getNavParams(this.props)
    const cancelBtn = params ? params.cancelBtn : null
    // const saveBtn = state.params ? state.params.saveBtn : null
    const onChange = params ? params.onChange : null
    return !_.isNil(cancelBtn) && !_.isNil(onChange) && onChange ? (
      <TouchableOpacity style={{ width: 50, height: 50, justifyContent: 'center', paddingRight: 4 }} onPress={() => cancelBtn()}>
        <XText variant='primary'>{p.op.t('Order.cancelled')}</XText>
      </TouchableOpacity>
    ) : null
  }

  _renderHeaderLeft = () => {
    const params = util.getNavParams(this.props)
    // const cancelBtn = state.params ? state.params.cancelBtn : null
    const saveBtn = params ? params.saveBtn : null
    const onChange = params ? params.onChange : null
    return !_.isNil(saveBtn) && !_.isNil(onChange) && onChange ? (
      <TouchableOpacity style={{ width: 50, height: 50, justifyContent: 'center', paddingLeft: 10 }} onPress={() => saveBtn()}>
        <XText variant='primary'>{p.op.t('save')}</XText>
      </TouchableOpacity>
    ) : (
      <TouchableOpacity style={{ width: 50, height: 40, alignItems: 'center', justifyContent: 'center' }} onPress={this._goBack}>
        <BackIcon />
      </TouchableOpacity>
    )
  }

  _getParams() {
    // return this.props.navigation.state.params
    const params = util.getNavParams(this.props)
    return params
  }

  _goBack = () => {
    util.navGoBack(this.props)
  }

  _renderTextAndSwitch = (textLabel: string, value: boolean) => (
    <HStack alignItems='center' justifyContent='space-between'>
      <XText>{textLabel}</XText>
      <XSwitch value={value} onValueChange={(val) => this.onChangeValue('usePDF', val)} />
    </HStack>

    // <View style={{ flexDirection: 'row', padding: 5 }}>
    //   <View style={{ flex: 1, justifyContent: 'center' }}>
    //     <Text style={{ fontSize: FONT_SIZE_NORMAL }}>{textLabel}</Text>
    //   </View>
    //   <View style={{ flex: 0, width: 60, justifyContent: 'center' }}>
    //     <Switch
    //       trackColor={Platform.OS === 'ios' ? COLORS.APP_MAIN : COLORS.APP_SECONDARY}
    //       thumbColor={Platform.OS === 'ios' ? null : COLORS.APP_MAIN}
    //       value={value}
    //       onValueChange={(val) => this.onChangeValue('usePDF', val)}
    //     />
    //   </View>
    // </View>
  )

  _renderListOptions = (type: string, text: string, key: number, value: boolean, url?: string) => {
    let typeIcon = null
    if (type === 'checkBox') {
      typeIcon = <XIcon name={value ? 'check-square-o' : 'square-o'} family='FontAwesome' />
    } else if (type === 'radio') {
      // const Radio = ({ checked = null }) => <XIcon name={checked ? 'radio-button-on' : 'radio-button-off'} family='Ionicons' />
      typeIcon = <XIcon name={value ? 'radio-button-on' : 'radio-button-off'} family='Ionicons' />
    }
    return (
      <HStack py='2'>
        <TouchableOpacity onPress={() => this.onChangeSelectedValue(key)} style={{ flex: 1, flexDirection: 'row' }}>
          {typeIcon}
          <XText variant='active' pl='1'>
            {text}
          </XText>
        </TouchableOpacity>
        <XButton onPress={() => Linking.openURL(url)} size='sm' variant='outline'>
          ดูตัวอย่าง
        </XButton>
      </HStack>
    )
    // return (
    //   <View style={{ flexDirection: 'row', flex: 1, padding: 2, justifyContent: 'center' }}>
    //     <View>
    //       <TouchableOpacity style={{ width: 30, height: 35 }} onPress={() => this.onChangeSelectedValue(key)}>
    //         {typeIcon}
    //       </TouchableOpacity>
    //     </View>
    //     <View style={{ flex: 1 }}>
    //       <XText variant='active'>{text}</XText>
    //     </View>
    //     {_.isNil(url) ? null : (
    //       <View style={{ flex: 0 }}>
    //         <TouchableOpacity
    //           style={{
    //             width: 60,
    //             height: 25,
    //             borderColor: APP_MAIN,
    //             borderWidth: 0.8,
    //             borderRadius: 7,
    //             backgroundColor: COLORS.BG_LIGHT_GREY,
    //             justifyContent: 'center',
    //           }}
    //           onPress={() => Linking.openURL(url)}>
    //           <Text
    //             style={{
    //               fontSize: FONT_SIZE_NORMAL,
    //               color: COLORS.TEXT_ACTIVE,
    //               justifyContent: 'center',
    //               paddingLeft: 6,
    //             }}>
    //             ดูตัวอย่าง
    //           </Text>
    //         </TouchableOpacity>
    //       </View>
    //     )}
    //   </View>
    // )
  }

  _renderInOffSetting = () => {
    const { usePDF } = this.state
    return (
      <XCard p='4' borderRadius='xl' w='full'>
        {this._renderTextAndSwitch(p.op.t('enabled'), usePDF)}
      </XCard>
    )
  }

  _renderShippingLableSetting = () => {
    const { usePDF, printingId } = this.state
    const havePrintting = _.includes(this.printingAllId, printingId)
    if (!usePDF) {
      return null
    }
    return (
      <XCard p='3' borderRadius='xl' w='full'>
        <Box p='1' w='full'>
          <XText variant='active' bold>
            {p.op.t('ShippingLabel')}
          </XText>
          {/* <View style={{ borderBottomWidth: 1, borderBottomColor: COLORS.TEXT_INACTIVE }} /> */}
          <XText variant='active'>{p.op.t('detailInShippingLabelPDF')}</XText>
          {this._renderListOptions('radio', p.op.t('shippingLabelStype.PaperSaving'), 11, printingId === 11, p11)}
          {this._renderListOptions('radio', p.op.t('shippingLabelStype.PaperProductList'), 31, printingId === 31, p31)}
          {this._renderListOptions('radio', p.op.t('shippingLabelStype.SeparateListOfProductsWithPrices'), 32, printingId === 32, p32)}
          {this._renderListOptions(
            'radio',
            p.op.t('shippingLabelStype.SeparateListOfProductsWithPricesAndTatal'),
            33,
            printingId === 33,
            p33
          )}
          {this._renderListOptions('radio', p.op.t('shippingLabelStype.PaPerProductImage'), 21, printingId === 21, p21)}
          {this._renderListOptions('radio', p.op.t('shippingLabelStype.PaPerProductImageAndPrices'), 22, printingId === 22, p22)}
          {this._renderListOptions('radio', p.op.t('shippingLabelStype.PaPerProductImageAndPricesAndTotal'), 23, printingId === 23, p23)}
          {havePrintting ? null : this._renderListOptions('radio', p.op.t('OrdersSummaryDonut.myTasks_payOther'), 9999, true)}
        </Box>
      </XCard>
    )
  }

  render() {
    return (
      <XContainer>
        {this.renderHeader()}
        <XContent>
          <VStack w='full' p='2' space='2'>
            {this._renderInOffSetting()}
            {this._renderShippingLableSetting()}
          </VStack>
        </XContent>
      </XContainer>
    )
  }
}
