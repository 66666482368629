import React from 'react'

// import p from 'x/config/platform-specific'
import BaseERPEditPaymentAccountView from 'x/modules/erp/BaseERPEditPaymentAccountView'
import XCustomHeader from 'xui/components/XCustomHeader'
// import { Input } from 'react-native-elements'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import XCard from 'xui/components/XCard'
import CONS from 'x/config/constants'
import HelpButton from 'xui/components/HelpButton'
import XText from 'xui/components/XText'
import HStack from 'xui/components/HStack'
import XFaqModal from 'xui/components/XFaqModal'
import FAQ_CONSTANTS from 'x/config/FAQ_CONSTANTS'
import BankIcon from 'xui/components/BankIcon'
import VStack from 'xui/components/VStack'
import XSwitch from 'xui/components/XSwitch'
import XInput from 'xui/components/XInput'
import p from 'x/config/platform-specific'
import _ from 'lodash'
import Box from 'xui/components/Box'
import XBankAccountTypeModel from 'xui/components/XBankAccountTypeModel'
import { TouchableOpacity } from 'react-native'
import XIcon from 'xui/components/XIcon'

const _renderAgreement = (onPressOpenOverlay: () => void) => <HelpButton onPress={() => onPressOpenOverlay()} />
// const VIEW_WIDTH_TEN = <View style={{ width: 10 }} />

export default abstract class BaseUIERPEditPaymentAccountView extends BaseERPEditPaymentAccountView {
  renderCustomHeader = () => {
    const { isEdit } = this.state
    const rightBtn = isEdit ? { label: 'บันทึก', onPressItem: () => this._submitErpPayment() } : null
    return (
      <XCustomHeader
        title='บัญชีรับชำระ'
        headerLeftProps={{ backIcon: true, onPressItem: () => this._onPressGoBack() }}
        headerRightProps={rightBtn}
      />
    )
  }

  _renderTitle = () => {
    const {} = this.state
    return (
      <XCard p='2' w='full'>
        <HStack alignItems='center' w='full'>
          <HStack flex={1}>
            <XText>ระบุบัญชีรับชำระและรหัสบัญชีที่ต้องการให้ XSelly ใช้เชื่อมข้อมูลไปยัง PEAK</XText>
          </HStack>
          <HStack>
            <XFaqModal
              key='บัญชีรับชำระ'
              headerTitle='บัญชีรับชำระ'
              // @ts-ignore
              FAQ={FAQ_CONSTANTS.FAQ_ERP_PAYMENT_ACCOUNT}
              initiateOpenIndex={[0]}
              renderButton={(onPressOpenOverlay: () => void) => _renderAgreement(onPressOpenOverlay)}
            />
          </HStack>
        </HStack>
      </XCard>
    )
  }

  _renderPaymentAccount = () => {
    const { isBindAccount, erpPaymentAccountCode, payment } = this.state
    if (_.isNil(payment)) {
      return null
    }
    let bankName = null
    let bankNameKey
    const foundBankIndex = CONS.BANK_INFO.findIndex((sBank) => sBank.id === payment.bank_id)
    if (foundBankIndex > -1) {
      bankNameKey = CONS.BANK_INFO[foundBankIndex].key
    } else {
      bankNameKey = CONS.BANK_INFO[0].key
    }
    bankName = p.op.t(`Bank.${bankNameKey}`)
    return (
      <XCard p='2' mt='2'>
        <HStack>
          <HStack flex={1} alignItems='flex-start'>
            <BankIcon bankId={payment.bank_id} />
            <XText ml='2'>{bankName}</XText>
          </HStack>
          <VStack flex={1} justifyContent='center' alignItems='flex-end'>
            <XText ml='2'>{payment.name}</XText>
            <XText ml='2'>{payment.account_number}</XText>
            {/* <XText ml='2' color={COLORS.RED}>
              ยังไม่ได้ผูกรหัสบัญชั PEAK
            </XText> */}
          </VStack>
          {/* <HStack alignItems='center' justifyContent='center' w='10'>
            <XIcon name='arrow-forward' family='Ionicons' />
          </HStack> */}
        </HStack>
        {this._renderSwitchBindAccount()}
        {this._renderSelectedBankAccountType()}
        {isBindAccount ? (
          <HStack w='100%' mt='2'>
            <HStack flex={1} alignItems='center'>
              <XText>เลขที่ช่องทางการเงิน</XText>
            </HStack>
            <HStack flex={1} alignItems='center' justifyContent='flex-end'>
              <XInput
                value={erpPaymentAccountCode}
                placeholder='เช่น CSH000'
                w='40'
                // maxLength={20}
                textAlign='right'
                onChangeText={(newText) =>
                  this.setState({
                    erpPaymentAccountCode: newText.trim(),
                    isEdit: true,
                  })
                }
              />
            </HStack>
          </HStack>
        ) : null}
      </XCard>
    )
  }

  _renderSelectedBankAccountType = () => {
    const { payment, isBindAccount } = this.state
    if (!isBindAccount) {
      return null
    }
    let hasAccountype = false
    CONS.BANK_HAVE_ACCOUNT_TYPE.forEach((bank_id) => {
      if (payment.bank_id === bank_id) {
        hasAccountype = true
      }
    })
    // console.log('payment => ', payment)
    if (!hasAccountype) {
      return null
    }
    return (
      <HStack space='2' alignItems='center' mt='4'>
        <Box w='20' alignItems='flex-start'>
          <XText textAlign='right' variant='inactive'>
            ประเภทบัญชี
          </XText>
        </Box>
        <Box flex={1}>
          <Box borderWidth={1} py='2' borderRadius='md' borderColor='gray.200'>
            <XBankAccountTypeModel
              headerTitle='เลือกประเภทบัญชี'
              renderButton={(onPressOpenOverlay: () => void) => this._renderBtnOpenBankTypeModel(onPressOpenOverlay)}
              onSelected={(bank_type: string) => this._bankTypeOnChange(bank_type)}
            />
          </Box>
        </Box>
      </HStack>
    )
  }

  _renderBtnOpenBankTypeModel = (onPressOpenOverlay: () => void) => {
    const { bankAccountType } = this.state
    let name = 'ยังไม่ระบุ'
    if (bankAccountType === 'CurrentAccount') {
      name = 'บัญชีกระแสรายวัน'
    }
    if (bankAccountType === 'SavingAccount') {
      name = 'บัญชีออมทรัพย์'
    }
    if (bankAccountType === 'FixedDeposit') {
      name = 'บัญชีเงินฝากประจำ'
    }
    return (
      <TouchableOpacity onPress={onPressOpenOverlay} style={{ flex: 1, flexDirection: 'row' }}>
        <Box flex={1} alignItems='center' justifyContent='center'>
          <XText>{name}</XText>
        </Box>
        <Box alignItems='center' justifyContent='center'>
          <XIcon name='arrowright' family='AntDesign' mr='2' />
        </Box>
      </TouchableOpacity>
    )
  }

  _renderSwitchBindAccount = () => {
    const { isBindAccount } = this.state
    return (
      <HStack w='100%' mt='2'>
        <VStack flex={1} alignItems='flex-start'>
          <XText>PEAK</XText>
          <XText variant='inactive'>ผูกรหัสบัญชี</XText>
        </VStack>
        <HStack flex={1} alignItems='center' justifyContent='flex-end'>
          <XSwitch
            value={isBindAccount}
            onValueChange={(switchVal) =>
              this.setState({
                isBindAccount: switchVal,
                isEdit: true,
              })
            }
          />
        </HStack>
      </HStack>
    )
  }

  render() {
    return (
      <XContainer>
        {this.renderCustomHeader()}
        <XContent p='2'>
          {this._renderTitle()}
          {this._renderPaymentAccount()}
        </XContent>
      </XContainer>
    )
  }
}
