// common usage
import dayjs from 'dayjs'
import { Map, fromJS } from 'immutable'
import { take, takeLatest, put, call, fork, all } from 'redux-saga/effects'
import _ from 'lodash'
import p from 'x/config/platform-specific'
import { ActionApiParams, IApiOptions } from 'x/index'
import * as StoreActions from '../../modules/store/StoreState'
import * as SubscriptionActions from '../../modules/subscription/SubscriptionState'
import * as MySubscriptionState from '../../modules/subscription/MySubscriptionState'
import actions from '../../config/actions'
import CONS from '../../config/constants'
import api from '../../utils/api'
import { log, getIsSetBoolFromBinaryAtIndex, getSelectedStoreOrdersDates } from '../../utils/util'
import * as acl from '../../utils/acl'

import { handleCustomOrderFromResponse } from './preference'
import * as LoadingInProgressActions from '../../modules/loadinginprogress/LoadingInProgressState'

export default function* watchAllStores() {
  yield all([
    fork(watchMyStores),
    fork(watchMyStore),
    fork(watchFetchMyStoreInfo),
    fork(watchSellerStores),
    fork(watchCreateStore),
    fork(watchLeaveFromStore),
    fork(watchSubmitEditingStore),

    fork(watchApplyInviteCode),
    fork(watchFindStore),
    fork(watchAddPrinting),
    fork(watchEditPrinting),
    fork(watchUpdateXShippingBalance),
    fork(watchGetPermissionMemberList),
    fork(watchGetPermissionList),
    fork(watchChangePermission),
    fork(watchDeleteHelperFromPermission),
    fork(watchAddHelper),
    fork(watchAddPermission),
    fork(watchEditPermission),
    fork(watchDeletePermission),

    fork(watchShopeeAuthUrl),
    fork(watchCountResellers),
    fork(watchGetChannel),
    fork(watchGetChannels),
    fork(watchGetWarehouse),
    fork(watchCreateWarehouse),
    fork(watchUpdateWarehouse),
    fork(watchDeleteWarehouse),
    fork(watchSetPrefTimeslot),

    fork(watchSyncStoreBGJob),
    fork(watchSyncMKPBackgroungTasks),

    fork(watchSyncMKPInfo),
    fork(watchSyncMKPProduct),
    fork(watchSyncMKPOrder),

    fork(watchOrderCount),
    fork(watchAutoCompleteBySegment),

    fork(watchsetEditingStoreGoApi),
    fork(watchAddStoreSettingGoApi),

    fork(watchMkpReAuthUrl),

    fork(watchGetErpChannels),

    fork(watchGetProductCount),
  ])
}

function* watchMyStores() {
  log('In watchMyStores')
  // @ts-ignore
  yield takeLatest(actions.MY_STORES_FETCH, fetchMyStores)
}

function* fetchMyStores({ successCallback, failedCallback }) {
  const storesApiOptions = {
    showSpinner: true,
  }
  try {
    yield put({ type: actions.SET_MY_STORES_IS_FETCHING, payload: true })
    const res = yield call(api.get, api.GET_STORES, storesApiOptions)
    yield call(handleMyStoresFromResponse, res)
    if (_.isFunction(successCallback)) {
      successCallback(res)
    }
    // FIXME: Remove me Mock DATA
    // let mockStores = []
    // for (let i = 0; i <= 10; i++) {
    //   res.stores.map(s => {
    //     mockStores.push(s)
    //   })
    // }
    // yield put({ type: actions.SET_MY_STORES_STATE, payload: fromJS(mockStores) })
  } catch (error) {
    // TODO: Handle this error action type
    yield put({ type: actions.MY_STORES_REQUEST_FAILED, error })
    if (_.isFunction(failedCallback)) {
      failedCallback(error)
    }
  }
}

function* watchAddPrinting() {
  // @ts-ignore
  yield takeLatest(actions.ADD_PRINTING, addPrinting)
}

function* addPrinting({ body }) {
  const storesApiOptions = {
    showSpinner: true,
  }
  try {
    // yield put({ type: actions.SET_MY_STORES_IS_FETCHING, payload: true })
    const res = yield call(api.putV2, api.PUT_ADD_PRINTING, body.body, storesApiOptions)
    // yield call(handleMyStoresFromResponse, res)
    yield call(updatePrinting, [res.store_printing])
    if (_.isFunction(body.successCallback)) {
      body.successCallback(res)
    }
  } catch (error) {
    // yield put({ type: actions.MY_STORES_REQUEST_FAILED, error })
    if (_.isFunction(body.failedCallback)) {
      body.failedCallback(error)
    }
  }
}

function* watchEditPrinting() {
  log('In watchMyStores')
  // @ts-ignore
  yield takeLatest(actions.EDIT_PRINTING, editPrinting)
}

function* editPrinting({ body }) {
  const storesApiOptions = {
    showSpinner: true,
  }
  // console.log('body', body)
  try {
    // yield put({ type: actions.SET_MY_STORES_IS_FETCHING, payload: true })
    const res = yield call(api.patchV2, api.PATCH_EDIT_PRINTING, body.body, storesApiOptions)
    yield call(updatePrinting, [res.store_printing])
    if (_.isFunction(body.successCallback)) {
      body.successCallback(res)
    }
  } catch (error) {
    // yield put({ type: actions.MY_STORES_REQUEST_FAILED, error })
    if (_.isFunction(body.failedCallback)) {
      body.failedCallback(error)
    }
  }
}

function* watchMyStore() {
  log('In watchMyStore')
  // @ts-ignore
  yield takeLatest(actions.MY_STORE_FETCH, fetchMyStore)
}

function* fetchMyStore({ payload, successCallback, failedCallback }) {
  try {
    log('In saga fetchMyStore')
    const storesApiOptions = {
      showSpinner: true,
    }

    const body = _.cloneDeep(payload)
    body.skip_count = true

    const selectedStoreOrdersDates = getSelectedStoreOrdersDates()
    if (body && !_.isString(body.created_at_from) && selectedStoreOrdersDates && selectedStoreOrdersDates.begin) {
      body.created_at_from = selectedStoreOrdersDates.begin.format(CONS.SERVER_DATETIME_FORMAT)
    }

    if (body && !_.isString(body.created_at_to) && selectedStoreOrdersDates && selectedStoreOrdersDates.end) {
      body.created_at_to = selectedStoreOrdersDates.end.format(CONS.SERVER_DATETIME_FORMAT)
    }

    const res = yield call(api.post, api.POST_STORE, body, storesApiOptions)
    log('In saga fetchMyStore res => ', res)
    // if (!res || !_.has(res, 'store')) {
    //   throw 'No store from fetched.'
    // }
    yield call(loadStoreFromResponse, res, body)
    yield call(handleCustomOrderFromResponse, res)
    if (_.isFunction(successCallback)) {
      successCallback(res)
    }
  } catch (error) {
    log('In saga fetchMyStore error => ', error)
    // TODO: Handle this error action type
    yield put({ type: actions.MY_STORES_REQUEST_FAILED, error })
    if (_.isFunction(failedCallback)) {
      failedCallback(error)
    }
  }
}

function* watchFetchMyStoreInfo() {
  log('In watchMyStore')
  // @ts-ignore
  yield takeLatest(actions.MY_STORE_INFO_FETCH, fetchMyStoreInfo)
}

function* fetchMyStoreInfo(action: ActionApiParams) {
  const { body, successCallback, failedCallback } = action
  try {
    log('In saga fetchMyStoreInfo')
    const storesApiOptions = {
      showSpinner: true,
    }

    // https://effily.atlassian.net/browse/XSELLY-845
    // Sample body
    // body = { 'info_fields': ['order_summary','order_count', 'dailysales', 'product_count']}
    const res = yield call(api.post, api.POST_STORE_INFO, body, storesApiOptions)
    log('In saga fetchMyStoreInfo res => ', res)
    yield call(loadStoreInfoFromResponse, res)
    if (_.isFunction(successCallback)) {
      successCallback(res)
    }
  } catch (error) {
    log('In saga fetchMyStoreInfo error => ', error)
    // TODO: Handle this error action type
    yield put({ type: actions.MY_STORES_INFO_REQUEST_FAILED, error })
    if (_.isFunction(failedCallback)) {
      failedCallback(error)
    }
  }
}

function* fetchSellerStores({ successCallback, failedCallback }) {
  log('fetchSellerStores')
  const storesApiOptions = {
    showSpinner: true,
  }
  try {
    yield put({ type: actions.SET_SELLER_STORES_IS_FETCHING, payload: true })
    const res = yield call(api.get, api.GET_SELLER_STORES, storesApiOptions)
    yield call(handleSellerStoresFromResponse, res)
    if (_.isFunction(successCallback)) {
      successCallback(res)
    }
  } catch (error) {
    // TODO: Handle this error action type
    yield put({ type: actions.SELLER_STORES_REQUEST_FAILED, error })
    if (_.isFunction(failedCallback)) {
      failedCallback(error)
    }
  }
}

function* watchSellerStores() {
  log('In watchSellerStores')
  // @ts-ignore
  yield takeLatest(actions.SELLER_STORES_FETCH, fetchSellerStores)
}

function* createStore(body) {
  const apiOptions = {
    messages: {
      successMsg: p.op.t('Store.StoreCreateView.ttOK'),
      errorMsg: p.op.t('Store.StoreCreateView.ttFail'),
    },
    showSpinner: true,
  }
  try {
    // yield put({ type: actions.SET_SELLER_STORES_IS_FETCHING, payload: true })
    yield call(api.put, api.PUT_CREATE_STORE, body, apiOptions)
    // TODO:: Refactor NavAction out from saga
    // yield put(NavActions.pop(1)) // go back to my store list
    // yield put({ type: actions.MY_STORES_FETCH }) // refresh my store list
  } catch (error) {
    // TODO: Handle this error action type
    // yield put({ type: actions.SELLER_STORES_REQUEST_FAILED, error })
  }
}

function* watchCreateStore() {
  log('In watchCreateStore')
  while (true) {
    const { payload } = yield take(actions.MY_STORE_CREATE)
    yield call(createStore, payload)
    // const res = yield call(createStore, body)
  }
}

function* watchSubmitEditingStore() {
  log('In watchMyStore')
  while (true) {
    const action = yield take(StoreActions.MY_STORE_SUBMIT_EDITING)
    yield call(editMyStore, action)
  }
}

function* editMyStore(action: ActionApiParams) {
  const { body, successCallback, failedCallback } = action
  const apiOptions = {
    messages: {
      successMsg: 'แก้ไขการตั้งค่าร้านค้าสำเร็จ',
      errorMsg: 'ล้มเหลวในการแก้ไขการตั้งค่า',
    },
    showSpinner: true,
  }
  try {
    const res = yield call(api.patch, api.PATCH_MY_STORE, body, apiOptions)
    yield call(loadStoreFromResponse, res, body)
    if (_.isFunction(successCallback)) {
      successCallback(res)
    }
  } catch (error) {
    // TODO: Handle this error action type
    yield put({ type: actions.MY_STORE_SUBMIT_EDITING_FAILED, error })
    if (_.isFunction(failedCallback)) {
      failedCallback(error)
    }
  }
}

function* watchLeaveFromStore() {
  log('In watchLeaveFromStore')
  // @ts-ignore
  yield takeLatest(actions.SELLER_STORES_LEAVE, leaveFromSellerStore)
}

function* leaveFromSellerStore({ payload, successCallback, failedCallback }) {
  const apiOptions = {
    messages: {
      // successMsg: 'ออกจากร้านนี้แล้ว',
    },
    showSpinner: true,
  }
  try {
    const res = yield call(api.post, api.POST_STORE_LEAVE, payload, apiOptions)
    if (_.isFunction(failedCallback)) {
      successCallback(res)
    }
    yield put(StoreActions.sellerStoreShouldFetch())
  } catch (error) {
    if (_.isFunction(failedCallback)) {
      failedCallback(error)
    }
  }
}

function* watchApplyInviteCode() {
  log('In watchApplyInviteCode')
  while (true) {
    const action = yield take(actions.APPLY_INVITE_CODE)
    yield call(applyInviteCode, action)
  }
}

function* applyInviteCode(action: ActionApiParams) {
  const apiOptions = {
    // messages: {
    // successMsg: 'การสมัครขอเข้าร่วมร้านค้าสำเร็จ',
    // errorMsg: 'การสมัครขอเข้าร่วมร้านค้าล้มเหลว',
    // },
    showSpinner: true,
  }
  try {
    log('In applyInviteCode body => ', action.body)
    const res = yield call(api.post, api.POST_APPLY_INVITE_CODE, action.body, apiOptions)
    log('In applyInviteCode response => ', res)
    // yield call(loadUserGroupListFromResponse, res)
    if (_.isFunction(action.successCallback)) {
      action.successCallback(res)
    }
    // yield put(NavActions.goBack) // go back to StoreMyView
  } catch (error) {
    log('In applyInviteCode error => ', error)
    yield put({ type: actions.ERROR_APPLY_INVITE_CODE, error })
    // @ts-ignore
    if (error && error.error && error.error.attach) {
      // yield call (loadUserGroupListFromResponse, error.error.attach)
    }
    if (_.isFunction(action.failedCallback)) {
      action.failedCallback(error)
    }
  }
}

export function* updatePrinting(printing) {
  let paperang = null
  let printedPDF = null
  let billPaperang = null
  let sticker = null
  let barcode = null
  printing.map((data) => {
    if (data.t === 1 && data.s === 1) {
      printedPDF = data
    } else if (data.t === 1 && data.s === 2) {
      paperang = data
    } else if (data.t === 2 && data.s === 2) {
      billPaperang = data
    } else if (data.t === 1 && data.s === 3) {
      sticker = data
    } else if (data.t === 3) {
      barcode = data
    }
  })
  if (!_.isNil(printedPDF)) {
    yield put(StoreActions.updatePrintingPDF(printedPDF))
  }
  if (!_.isNil(paperang)) {
    yield put(StoreActions.updatePrintingPaperang(paperang))
  }
  if (!_.isNil(billPaperang)) {
    yield put(StoreActions.updatePrintingBillPaperang(billPaperang))
  }
  if (!_.isNil(sticker)) {
    yield put(StoreActions.updatePrintingStickerPDF(sticker))
  }
  if (!_.isNil(barcode)) {
    yield put(StoreActions.updatePrintingBarcode(barcode))
  }
}

// export function* asyncFunctionLoading(store) {
function asyncFunctionLoading(store) {
  const reduxDispatch = p.op.getDispatch()
  // Get Product Count
  if (acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.PRODUCT_LIST)) {
    reduxDispatch(
      StoreActions.getProductCount({
        body: { store_id: store.id, c: 1, hide_product_list: true, role_id: store.role_id },
        successCallback: () => null,
      })
    )
  }

  // Get Go Subscription Detail
  reduxDispatch(MySubscriptionState.fetchSubscription())

  // Get Channels
  if (store.has_mkp && acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.MKP_LIST)) {
    // yield put(StoreActions.getChannels({ body: { store_id: store.id }, successCallback: () => null }))
    reduxDispatch(StoreActions.getChannels({ body: { store_id: store.id }, successCallback: () => null }))
  }

  // โหลด ERP Channel
  // yield put(StoreActions.getErpChannels({ body: { store_id: store.id }, successCallback: () => null }))
  if (store.has_erp) {
    reduxDispatch(StoreActions.getErpChannels({ body: { store_id: store.id }, successCallback: () => null }))
  }
}

// using for load SelectedStore
export function* loadStoreFromResponse(res, reqBody) {
  const reduxDispatch = p.op.getDispatch()

  // console.log('ARTID => ', res)
  if (_.has(res, ['ss', 'id']) || _.has(res, ['store', 'id'])) {
    // const { store } = res

    const store = _.has(res, 'store') ? _.cloneDeep(res.store) : _.cloneDeep(res.ss)

    // // FIXME: MOCK
    // // Add mocking data
    // store.product_groups = [
    //   { id: 1, name: 'ขายปลีก', priority: 200, is_default: true },
    //   { id: 2, name: 'ตัวแทน Bronze', priority: 10 },
    //   { id: 3, name: 'ตัวแทน Silver', priority: 20 },
    //   { id: 4, name: 'ตัวแทน Gold', priority: 30 },
    // ]

    if (_.has(store, 'product_groups')) {
      // sorting product_groups by priority
      store.product_groups.sort((pgA, pgB) => {
        try {
          const pA = pgA.priority
          const pB = pgB.priority
          const diffPriority = pB - pA
          if (diffPriority !== 0) {
            return diffPriority
          }
          const idA = _.isString(pgA.id) ? parseInt(pgA.id) : pgA.id
          const idB = _.isString(pgB.id) ? parseInt(pgB.id) : pgB.id
          const diffId = idA - idB
          return diffId // Id must be unique then it will not equal
        } catch (err) {
          log('store.product_groups.sort error => ', err)
          return 0
        }
      })
    }

    /// / Mock
    // store.s_use_product_barcode = 2
    // store.s_computed_use_product_sku = true
    // store.s_computed_use_product_upc = true

    // FIXME: MOCK
    // store.order_summary = {
    //   myTasks_confirmOrder: 3,
    //   myTasks_pay: 5,
    //   myTasks_paySalesOrdersPaid: 3, // Optional
    //   myTasks_confirmGettingPaid: 1,
    //   myTasks_ship: 5,
    // }
    // store.order_summary = {
    //   myTasks_confirmOrder: 0,
    //   myTasks_pay: 0,
    //   myTasks_paySalesOrdersPaid: 0,
    //   myTasks_confirmGettingPaid: 0,
    //   myTasks_ship: 0,
    // }
    // store.order_summary = {
    //   myTasks_confirmOrder: 3,
    //   myTasks_pay: 2,
    //   myTasks_confirmGettingPaid: 2,
    //   myTasks_ship: 1,
    // }
    // store.order_summary = {
    //   myTasks_confirmOrder: 1388,
    //   myTasks_pay: 399,
    //   myTasks_confirmGettingPaid: 333,
    //   myTasks_ship: 11,
    //   myTasks_paySalesOrdersPaid: 222,
    // }

    // store.order_summary = {
    //   myTasks_confirmOrder: 7,
    //   myTasks_pay: 2,
    //   myTasks_confirmGettingPaid: 5,
    //   myTasks_ship: 2,
    //   myTasks_paySalesOrdersPaid: 1, // Optional
    // }

    // store.s_auto_calc_shipping_type_ids = [
    //   2, // register
    //   3, // ems
    //   22, // flash
    //   4, // kerry
    //   13, // scg
    //   14, // dhl
    //   7, // ninja
    //   8, // nim
    //   6, // alpha
    // ]

    // แปลง store setting int-binary flag ในการใช้ Barcode SKU/UPC/...
    if (_.has(store, 's_use_product_barcode')) {
      const { s_use_product_barcode = 0 } = store
      const useBarcodeIntBinary = _.isString(s_use_product_barcode) ? parseInt(s_use_product_barcode) : s_use_product_barcode
      store[CONS.STORE_SETTINGS.COMPUTED_USE_PRODUCT_UPC] = getIsSetBoolFromBinaryAtIndex(useBarcodeIntBinary, 0) // upc is bit 0
      store[CONS.STORE_SETTINGS.COMPUTED_USE_PRODUCT_SKU] = getIsSetBoolFromBinaryAtIndex(useBarcodeIntBinary, 1) // sku is bit 1
    }

    // แปลง store_helper_permissions int-binary flag
    if (_.has(store, 'store_helper_permissions')) {
      const { store_helper_permissions } = store
      let pCodeEditIntBinary
      if (store_helper_permissions && _.has(store_helper_permissions, 'nc_product_code_edit')) {
        pCodeEditIntBinary = store_helper_permissions.nc_product_code_edit
      } else if (store_helper_permissions && _.has(store_helper_permissions, 'product_code_edit')) {
        pCodeEditIntBinary = store_helper_permissions.product_code_edit
      } else {
        pCodeEditIntBinary = 0
      }

      store.store_helper_permissions[CONS.PERM_STORE_HELPER.PRODUCT_CODE_EDIT_UPC] = getIsSetBoolFromBinaryAtIndex(pCodeEditIntBinary, 0) // upc is bit 0

      store.store_helper_permissions[CONS.PERM_STORE_HELPER.PRODUCT_CODE_EDIT_SKU] = getIsSetBoolFromBinaryAtIndex(pCodeEditIntBinary, 1) // sku is bit 1
    }

    if (!_.has(store, 'lastFetchedAt')) {
      store.lastFetchedAt = dayjs()
    }

    // เปลี่ยนค่าตัวแปรจาก api ให้สามารถใช้งานได้ในคอมโพเนน
    // if (_.has(store, 'vds')) {
    //   // sorting product_groups by priority
    //   store.vds.forEach((vdsItem, index) => {
    //     vdsItem.variants.forEach((variant, idx) =>{
    //       store.vds[index].variants[idx].begin = variant.min
    //       store.vds[index].variants[idx].end = variant.max
    //       store.vds[index].variants[idx].discount = variant.val
    //     })
    //   })
    // }

    // // FIXME: MOCK DATA
    // if (_.has(store, 'user_groups')) {
    //   // sorting product_groups by priority
    //   store.user_groups.sort((ugA, ugB) => {
    //     try {
    //       const idA = _.isString(ugA.id) ? parseInt(ugA.id) : ugA.id
    //       const idB = _.isString(ugB.id) ? parseInt(ugB.id) : ugB.id
    //       const diffId = idA - idB
    //       return diffId // Id must be unique then it will not equal
    //     } catch (err) {
    //       log('store.user_groups.sort error => ', err)
    //       return 0
    //     }
    //   })
    //
    //   store.user_groups.forEach((ug, idx) => {
    //     store.user_groups[idx]['invite_code'] = 'AXE87'
    //     store.user_groups[idx]['img_uri'] = 'https://upload.wikimedia.org/wikipedia/commons/f/f9/Sapphire_Gem.jpg'
    //     store.user_groups[idx]['thumbnail_uri'] = 'https://upload.wikimedia.org/wikipedia/commons/f/f9/Sapphire_Gem.jpg'
    //   })
    // }

    let newStore = Map({})
    newStore = newStore.set(store.id, fromJS(store))
    yield put(StoreActions.setMyStore(newStore))
    yield put(StoreActions.setSelectedStore(store))
    // ARTID PRINTINGS
    if (_.has(store, 'printings')) {
      yield call(updatePrinting, store.printings)
      updatePrinting(store.printings)
    }

    // ถ้าฉันเป็นผู้ช่วยร้าน จะโหลด store owner's subscription เข้าไปใช้
    if (_.has(store, 'store_owner_subscription')) {
      // yield put(SubscriptionActions.loadSubscription({ ...store.store_owner_subscription }))
      reduxDispatch(SubscriptionActions.loadSubscription({ ...store.store_owner_subscription }))
    }

    // ถ้ามีการส่งค่า skip_count ให้โหลด API orders เพื่อขอ order_summary
    // console.log('reqBody => ', reqBody)
    if (!_.isNil(reqBody.skip_count) && acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.ORDER_LIST)) {
      try {
        const types_to_count = [...CONS.ORDER_SUMMARY_ALL]
        for (let ti = 0; ti < CONS.ORDER_SUMMARY_SPECIAL_TAB_KEYS.length; ti++) {
          const typeKey = CONS.ORDER_SUMMARY_SPECIAL_TAB_KEYS[ti]
          if (!_.includes(types_to_count, typeKey)) {
            types_to_count.push(typeKey)
          }
        }

        const reqOrderBody: any = {
          types: types_to_count,
          types_to_count,
          offset: 0,
          limit: 0,
        }

        if (reqBody.store_id) {
          reqOrderBody.store_id = reqBody.store_id
        }

        // เช็ค params ว่าเป็น req ของ api init หรือไม่
        // ss_id = selected store id ?
        // v = version
        // t = type of build ?
        if (reqBody.ss_id || reqBody.v || reqBody.t) {
          reqOrderBody.store_id = res.ss.id
        }

        if (reqBody.created_at_from && reqBody.created_at_to) {
          reqOrderBody.created_at_from = reqBody.created_at_from
          reqOrderBody.created_at_to = reqBody.created_at_to
        }

        // console.log('reqOrderBody => ', reqOrderBody)
        api
          .post(api.POST_ORDERS, reqOrderBody, {
            showSpinner: false,
            axiosOptions: { retry: 0, timeout: 180000 },
            isErrorAlertDisabled: true,
          })
          .then(async (response) => {
            const oSummary = response.summary
            // console.log('response => ', response)
            if (oSummary) {
              let odCount = 0
              for (let yy = 0; yy < CONS.ORDER_SUMMARY_ALL.length; yy++) {
                const oTypeKey = CONS.ORDER_SUMMARY_ALL[yy]
                // console.log('oTypeKey => ', oTypeKey)
                // console.log('oSummary[oTypeKey] => ', oSummary[oTypeKey])

                if (oSummary[oTypeKey]) {
                  odCount += oSummary[oTypeKey]
                }
              }
              reduxDispatch(StoreActions.updateSelectedStoreByKey('order_count', odCount))
              reduxDispatch(StoreActions.updateSelectedStoreByKey('order_summary', oSummary))
            }
          })
      } catch (error) {
        console.log('error => ', error)
      }
    }

    // โหลด API อื่นๆ หลังจากโหลด init เสร็จ
    // yield call(asyncFunctionLoading, store)
    asyncFunctionLoading(store)
  }
}

// using for load new StoreInfo to SelectedStore and EditingStore
export function* loadStoreInfoFromResponse(res) {
  if (_.has(res, 'store')) {
    yield put(StoreActions.mergeMyStoreInfo(res.store))
  }
}

export function* handleMyStoresFromResponse(res) {
  // console.log('xxxxXXXXxxxx', res)
  if (res && _.isArray(res.stores)) {
    // yield put({ type: actions.SET_MY_STORES_STATE, payload: fromJS(res.stores) })
    yield put(StoreActions.setMyStores(res.stores))
  } else if (res && _.isArray(res.myStores)) {
    // yield put({ type: actions.SET_MY_STORES_STATE, payload: fromJS(res.myStores) })
    yield put(StoreActions.setMyStores(res.myStores))
  }
}

export function* handleSellerStoresFromResponse(res) {
  if ((res && _.isArray(res.stores)) || (res && _.isArray(res.sellerStores))) {
    const newSellerStores = res.stores ? res.stores : res.sellerStores
    // // MOCK
    // if (newSellerStores.length > 0) {
    //   newSellerStores[0].s_u = 1
    // }
    yield put(StoreActions.setSellerStores(newSellerStores))
  }

  if ('myPendingStores' in res || 'pending_stores' in res) {
    const newPendingStores = res.myPendingStores ? res.myPendingStores : res.pending_stores
    yield put(StoreActions.setPendingStores(newPendingStores))
  }
}

function* watchFindStore() {
  log('In watchFindStore')
  // yield takeLatest(actions.FIND_STORE, fetchMyStores)
  const action = yield take(actions.FIND_STORE)
  yield call(findStore, action)
}

function* findStore(action) {
  // const storesApiOptions = {
  //   showSpinner: true,
  // }
  try {
    // console.log('action', action)
    yield put({ type: actions.SET_MY_STORES_IS_FETCHING, payload: true })
    // @ts-ignore
    const res = yield call(api.post, api.POST_STORE, action.payload.body)
    if (_.isFunction(action.payload.successCallback)) {
      action.payload.successCallback(res)
    }
  } catch (error) {
    // yield put({ type: actions.MY_STORES_REQUEST_FAILED, error })
    if (_.isFunction(action.payload.failedCallback)) {
      action.payload.failedCallback(error)
    }
  }
}

function* watchUpdateXShippingBalance() {
  // @ts-ignore
  yield takeLatest(actions.UPDATE_XSHIPPING_BALANCE, updateXShippingBalance)
}

function* updateXShippingBalance({ body, successCallback, failedCallback }) {
  try {
    const res = yield call(api.postV2, api.POST_UPDATE_XSHIPPING_BALANCE, body, {
      axiosOptions: {
        retry: 0,
        timeout: 10000,
      },
      isErrorAlertDisabled: true,
      // showSpinner: true,
    })
    if (_.isFunction(successCallback)) {
      successCallback(res)
    }
  } catch (error) {
    if (_.isFunction(failedCallback)) {
      failedCallback(error)
    }
  }
}

function* watchGetPermissionMemberList() {
  // @ts-ignore
  yield takeLatest(actions.GET_PERMISSION_MEMBER_LUST, getPermissionMemberList)
}

function* getPermissionMemberList({ body, successCallback, failedCallback }) {
  try {
    const res = yield call(api.postV2, api.POST_GET_PERMISSION_MEMBER_LUST, body)
    if (_.isFunction(successCallback)) {
      successCallback(res)
    }
  } catch (error) {
    if (_.isFunction(failedCallback)) {
      failedCallback(error)
    }
  }
}

function* watchGetPermissionList() {
  // @ts-ignore
  yield takeLatest(actions.GET_PERMISSION_LIST, getPermissionList)
}

function* getPermissionList({ body, successCallback, failedCallback }) {
  try {
    const res = yield call(api.postV2, api.POST_GET_PERMISSION_LIST, body)
    if (_.isFunction(successCallback)) {
      successCallback(res)
    }
  } catch (error) {
    if (_.isFunction(failedCallback)) {
      failedCallback(error)
    }
  }
}

function* watchChangePermission() {
  // @ts-ignore
  yield takeLatest(actions.CHANGE_PERMISSION, changePermission)
}

function* changePermission({ body, successCallback, failedCallback }) {
  try {
    const res = yield call(api.patchV2, api.PATCH_CHANGE_PERMISSION, body)
    if (_.isFunction(successCallback)) {
      successCallback(res)
    }
  } catch (error) {
    if (_.isFunction(failedCallback)) {
      failedCallback(error)
    }
  }
}

function* watchDeleteHelperFromPermission() {
  // @ts-ignore
  yield takeLatest(actions.DELETE_HELPER_FROM_PERMISSION, deleteHelperFromPermission)
}

function* deleteHelperFromPermission({ body, successCallback, failedCallback }) {
  try {
    const res = yield call(api.delV2, api.DELETE_HELPER_FROM_PERMISSION, body)
    if (_.isFunction(successCallback)) {
      successCallback(res)
    }
  } catch (error) {
    if (_.isFunction(failedCallback)) {
      failedCallback(error)
    }
  }
}

function* watchAddHelper() {
  // @ts-ignore
  yield takeLatest(actions.ADD_HELPER, addHelper)
}

function* addHelper({ body, successCallback, failedCallback }) {
  try {
    const res = yield call(api.putV2, api.PUT_ADD_HELPER, body)
    if (_.isFunction(successCallback)) {
      successCallback(res)
    }
  } catch (error) {
    if (_.isFunction(failedCallback)) {
      failedCallback(error)
    }
  }
}

function* watchAddPermission() {
  // @ts-ignore
  yield takeLatest(actions.ADD_PERMISSION, addPermission)
}

function* addPermission({ body, successCallback, failedCallback }) {
  try {
    const res = yield call(api.putV2, api.PUT_ADD_PERMISSION, body)
    if (_.isFunction(successCallback)) {
      successCallback(res)
    }
  } catch (error) {
    if (_.isFunction(failedCallback)) {
      failedCallback(error)
    }
  }
}

function* watchEditPermission() {
  // @ts-ignore
  yield takeLatest(actions.EDIT_PERMISSION, editPermission)
}

function* editPermission({ body, successCallback, failedCallback }) {
  try {
    const res = yield call(api.patchV2, api.PATCH_EDIT_PERMISSION, body)
    if (_.isFunction(successCallback)) {
      successCallback(res)
    }
  } catch (error) {
    if (_.isFunction(failedCallback)) {
      failedCallback(error)
    }
  }
}

function* watchDeletePermission() {
  // @ts-ignore
  yield takeLatest(actions.DELETE_PERMISSION, deletePermission)
}

function* deletePermission({ body, successCallback, failedCallback }) {
  try {
    const res = yield call(api.delV2, api.DELETE_PERMISSION, body)
    if (_.isFunction(successCallback)) {
      successCallback(res)
    }
  } catch (error) {
    if (_.isFunction(failedCallback)) {
      failedCallback(error)
    }
  }
}

function* watchShopeeAuthUrl() {
  while (true) {
    const { payload } = yield take(actions.SHOPEE_AUTH_URL)
    yield call(shopeeAuthUrl, payload)
  }
}

function* shopeeAuthUrl(payload) {
  const { body } = payload
  try {
    // @ts-ignore
    const res = yield call(api.postV2, api.POST_SHOPEE_AUTH_URL, body)
    if (payload.successCallback) {
      payload.successCallback(res)
    }
  } catch (error) {
    if (payload.failedCallback) {
      payload.failedCallback(error)
    }
  }
}

function* watchMkpReAuthUrl() {
  while (true) {
    const { payload } = yield take(actions.MKP_RE_AUTH_URL)
    yield call(mkpReAuthUrl, payload)
  }
}

function* mkpReAuthUrl(payload) {
  const { body } = payload
  try {
    // @ts-ignore
    const res = yield call(api.postV2, api.POST_MKP_RE_AUTH_URL, body)
    if (payload.successCallback) {
      payload.successCallback(res)
    }
  } catch (error) {
    if (payload.failedCallback) {
      payload.failedCallback(error)
    }
  }
}

function* watchCountResellers() {
  while (true) {
    const { payload } = yield take(actions.COUNT_RESELLERS)
    yield call(countResellers, payload)
  }
}

function* countResellers(payload) {
  const { body } = payload
  try {
    // @ts-ignore
    const res = yield call(api.postV2, api.POST_COUNT_RESELLERS, body, {
      axiosOptions: {
        retry: 0,
        timeout: 147600000, // long fetch 1 day with no retry
      },
      // showSpinner: true,
    })
    if (payload.successCallback) {
      payload.successCallback(res)
    }
  } catch (error) {
    if (payload.failedCallback) {
      payload.failedCallback(error)
    }
  }
}

function* watchGetChannel() {
  while (true) {
    const { payload } = yield take(actions.GET_CHANNEL)
    yield call(getChannel, payload)
  }
}

function* getChannel(payload) {
  // yield put(LoadingInProgressActions.setLoadingChannels())
  console.log('payload =? ', payload)
  try {
    // @ts-ignore
    const res = yield call(api.postV2, api.POST_GET_CHANNEL, payload.body)
    if (payload.successCallback) {
      payload.successCallback(res)
      // yield put(StoreActions.updateNewChannelList(res.channels))
      // yield put(LoadingInProgressActions.unSetLoadingChannels())
      // p.op.showToast(`อัพเดตช่องทางขายเรียบร้อยแล้ว`, `success`)
    }
  } catch (error) {
    // yield put(LoadingInProgressActions.unSetLoadingChannels())
    if (payload.failedCallback) {
      payload.failedCallback(error)
    }
  }
}

function* watchGetChannels() {
  while (true) {
    const { payload } = yield take(actions.GET_CHANNELS)
    yield call(getChannels, payload)
  }
}

function* getChannels(payload) {
  yield put(LoadingInProgressActions.setLoadingChannels())
  try {
    // @ts-ignore
    const res = yield call(api.postV2, api.POST_GET_CHANNELS, payload.body, {
      showSpinner: false,
      axiosOptions: { retry: 0, timeout: 180000 },
      isErrorAlertDisabled: true,
    })
    if (payload.successCallback) {
      payload.successCallback(res)
      yield put(StoreActions.updateNewChannelList(res.channels))
      yield put(LoadingInProgressActions.unSetLoadingChannels())
      // p.op.showToast(`อัพเดตช่องทางขายเรียบร้อยแล้ว`, `success`)
    }
  } catch (error) {
    yield put(LoadingInProgressActions.unSetLoadingChannels())
    if (payload.failedCallback) {
      payload.failedCallback(error)
    }
  }
}

function* watchSetPrefTimeslot() {
  while (true) {
    const { payload } = yield take(actions.SET_PREF_TIMESLOT)
    yield call(setPrefTimeslot, payload)
  }
}

function* setPrefTimeslot(payload) {
  // yield put(LoadingInProgressActions.setLoadingChannels())
  const { body } = payload
  try {
    // @ts-ignore
    const res = yield call(api.postV2, api.POST_SET_PREF_TIMESLOT_, body)
    if (payload.successCallback) {
      payload.successCallback(res)
      // yield put(StoreActions.updateNewChannelList(res.channel))
      // yield put(LoadingInProgressActions.unSetLoadingChannels())
      // p.op.showToast(`อัพเดตช่องทางขายเรียบร้อยแล้ว`, `success`)
    }
  } catch (error) {
    // yield put(LoadingInProgressActions.unSetLoadingChannels())
    if (payload.failedCallback) {
      payload.failedCallback(error)
    }
  }
}

function* watchGetWarehouse() {
  while (true) {
    const { payload } = yield take(actions.GET_WAREHOOUSE)
    yield call(getWarehouse, payload)
  }
}

function* getWarehouse(payload) {
  const { body } = payload
  try {
    // @ts-ignore
    const res = yield call(api.postV2, api.POST_GET_WAREHOUSE, body)
    if (payload.successCallback) {
      payload.successCallback(res)
    }
  } catch (error) {
    if (payload.failedCallback) {
      payload.failedCallback(error)
    }
  }
}

function* watchCreateWarehouse() {
  while (true) {
    const { payload } = yield take(actions.CREATE_WAREHOOUSE)
    yield call(createWarehouse, payload)
  }
}

function* createWarehouse(payload) {
  const { body } = payload
  try {
    // @ts-ignore
    const res = yield call(api.putV2, api.PUT_CREATE_WAREHOUSE, body)
    if (payload.successCallback) {
      payload.successCallback(res)
    }
  } catch (error) {
    if (payload.failedCallback) {
      payload.failedCallback(error)
    }
  }
}

function* watchUpdateWarehouse() {
  while (true) {
    const { payload } = yield take(actions.UPDATE_WAREHOOUSE)
    yield call(updateWarehouse, payload)
  }
}

function* updateWarehouse(payload) {
  const { body } = payload
  try {
    // @ts-ignore
    const res = yield call(api.patchV2, api.PATCH_UPDATE_WAREHOUSE, body)
    if (payload.successCallback) {
      payload.successCallback(res)
    }
  } catch (error) {
    if (payload.failedCallback) {
      payload.failedCallback(error)
    }
  }
}

function* watchDeleteWarehouse() {
  while (true) {
    const { payload } = yield take(actions.DELETE_WAREHOOUSE)
    yield call(deleteWarehouse, payload)
  }
}

function* deleteWarehouse(payload) {
  const { body } = payload
  try {
    // @ts-ignore
    const res = yield call(api.delV2, api.DELETE_WATEHOUSE, body)
    if (payload.successCallback) {
      payload.successCallback(res)
    }
  } catch (error) {
    if (payload.failedCallback) {
      payload.failedCallback(error)
    }
  }
}

function* watchSyncStoreBGJob() {
  while (true) {
    const { payload } = yield take(actions.ACTION_SYNC_MKP_BG_JOB)
    yield call(syncStoreBGJob, payload)
  }
}

function* syncStoreBGJob(payload) {
  const { body } = payload
  try {
    // @ts-ignore
    const res = yield call(api.postV2, api.POST_BACKGROUND_JOB_LIST, body)
    if (payload.successCallback) {
      payload.successCallback(res)
    }
  } catch (error) {
    if (payload.failedCallback) {
      payload.failedCallback(error)
    }
  }
}

function* watchSyncMKPBackgroungTasks() {
  while (true) {
    const { payload } = yield take(actions.ACTION_SYNC_MKP_BACKGROUNG_TASKS)
    yield call(syncMKPBackgroungTasks, payload)
  }
}

function* syncMKPBackgroungTasks(payload) {
  const { body } = payload
  try {
    // @ts-ignore
    const res = yield call(api.postV2, api.POST_BACKGROUND_TASK_LIST, body)
    if (payload.successCallback) {
      payload.successCallback(res)
    }
  } catch (error) {
    if (payload.failedCallback) {
      payload.failedCallback(error)
    }
  }
}

function* watchSyncMKPInfo() {
  while (true) {
    const { payload } = yield take(actions.ACTION_SYNC_SHOP_INFO)
    yield call(syncMKPInfo, payload)
  }
}

function* syncMKPInfo(payload) {
  const { body } = payload
  try {
    // @ts-ignore
    const res = yield call(api.postV2, api.POST_SYNC_SHOP_INFO, body)
    if (payload.successCallback) {
      payload.successCallback(res)
      // yield put(StoreActions.updateNewChannelList(res.channels))
    }
  } catch (error) {
    if (payload.failedCallback) {
      payload.failedCallback(error)
    }
  }
}

function* watchSyncMKPProduct() {
  while (true) {
    const { payload } = yield take(actions.ACTION_SYNC_MKP_PRODUCT)
    yield call(syncMKPProduct, payload)
  }
}

function* syncMKPProduct(payload) {
  const { body } = payload
  try {
    // @ts-ignore
    const res = yield call(api.postV2, api.POST_SYNC_MKP_PRODUCT, body)
    if (payload.successCallback) {
      payload.successCallback(res)
    }
  } catch (error) {
    if (payload.failedCallback) {
      payload.failedCallback(error)
    }
  }
}

function* watchSyncMKPOrder() {
  while (true) {
    const { payload } = yield take(actions.ACTION_SYNC_MKP_ORDER)
    yield call(syncMKPOrder, payload)
  }
}

function* syncMKPOrder(payload) {
  const { body } = payload
  try {
    // @ts-ignore
    const res = yield call(api.postV2, api.POST_SYNC_MKP_ORDER, body)
    if (payload.successCallback) {
      payload.successCallback(res)
    }
  } catch (error) {
    if (payload.failedCallback) {
      payload.failedCallback(error)
    }
  }
}

function* watchOrderCount() {
  while (true) {
    const { payload } = yield take(actions.ACTION_ORDER_COUNT)
    yield call(orderCount, payload)
  }
}

function* orderCount(payload) {
  const { body } = payload
  try {
    // @ts-ignore
    const res = yield call(api.postV2, api.POST_ORDER_COUNT, body)
    if (payload.successCallback) {
      payload.successCallback(res)
    }
  } catch (error) {
    if (payload.failedCallback) {
      payload.failedCallback(error)
    }
  }
}

function* watchAutoCompleteBySegment() {
  while (true) {
    const { payload } = yield take(actions.ACTION_AUTO_COMPLETE_BY_SEGMENT)
    yield call(autoCompleteBySegment, payload)
  }
}

function* autoCompleteBySegment(payload) {
  const { body } = payload
  try {
    // @ts-ignore
    const res = yield call(api.postV2, api.POST_AUTO_COMPLETE_BY_SEGMENT, body)
    if (payload.successCallback) {
      payload.successCallback(res)
    }
  } catch (error) {
    if (payload.failedCallback) {
      payload.failedCallback(error)
    }
  }
}

function* watchsetEditingStoreGoApi() {
  while (true) {
    const { payload } = yield take(actions.SET_EDITING_STORE_GO_API)
    yield call(setEditingStoreGoApi, payload)
  }
}

function* setEditingStoreGoApi(payload) {
  // console.log('payload setEditingStoreGoApi => ', payload)
  const { body } = payload
  try {
    // @ts-ignore
    const res = yield call(api.patchV2, api.PUT_STORE_SETTING, body)
    if (res.store_setting) {
      yield put(StoreActions.setEditingStoreSetting(res.store_setting))
    }
    if (payload.successCallback) {
      payload.successCallback(res)
    }
  } catch (error) {
    if (payload.failedCallback) {
      payload.failedCallback(error)
    }
  }
}

function* watchAddStoreSettingGoApi() {
  while (true) {
    const { payload } = yield take(actions.ADD_STORE_SETTING_GO_API)
    yield call(addStoreSettingGoApi, payload)
  }
}

function* addStoreSettingGoApi(payload) {
  // console.log('payload setEditingStoreGoApi => ', payload)
  const { body } = payload
  try {
    // @ts-ignore
    const res = yield call(api.putV2, api.PUT_STORE_SETTING, body)
    if (res.store_setting) {
      yield put(StoreActions.setEditingStoreSetting(res.store_setting))
    }
    if (payload.successCallback) {
      payload.successCallback(res)
    }
  } catch (error) {
    if (payload.failedCallback) {
      payload.failedCallback(error)
    }
  }
}

function* watchGetErpChannels() {
  while (true) {
    const { payload } = yield take(actions.GET_ERP_CHANNELS)
    yield call(getErpChannels, payload)
  }
}

function* getErpChannels(payload) {
  try {
    const storesApiOptions: IApiOptions = {
      showSpinner: true,
      axiosOptions: { retry: 0, timeout: 180000 },
      isErrorAlertDisabled: true,
    }
    // @ts-ignore
    const res = yield call(api.postV2, api.POST_ERP_CHANNELS, payload.body, storesApiOptions)
    // console.log('res => ', res)
    if (payload.successCallback) {
      payload.successCallback(res)
      yield put(StoreActions.updateNewErpChannelList(res.erp_channels))
    }
  } catch (error) {
    if (payload.failedCallback) {
      payload.failedCallback(error)
    }
  }
}

function* watchGetProductCount() {
  while (true) {
    const { payload } = yield take(actions.GET_PRODUCT_COUNT)
    yield call(getProductCount, payload)
  }
}

function* getProductCount(payload) {
  try {
    // const storesApiOptions: IApiOptions = {
    //   showSpinner: true,
    //   axiosOptions: { retry: 0, timeout: 180000 },
    //   isErrorAlertDisabled: true,
    // }
    // @ts-ignore
    const res = yield call(api.post, 'products', payload.body)
    // console.log('res => ', res)
    if (payload.successCallback) {
      payload.successCallback(res)
      yield put(StoreActions.updateProductCount(res.count))
    }
  } catch (error) {
    if (payload.failedCallback) {
      payload.failedCallback(error)
    }
  }
}
