import BaseUIStoreSettingXShippingKerryView from 'xui/modules/xshipping/BaseUIStoreSettingXShippingKerryView'

class StoreSettingXShippingKerryView extends BaseUIStoreSettingXShippingKerryView {
  static displayName = 'StoreSettingXShippingKerryView'

  static navigationOptions = {
    header: null,
    title: null,
    headerLeft: null,
    headerRight: null,
  }
}

export default StoreSettingXShippingKerryView
