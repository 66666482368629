import React from 'react'
import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import { TouchableOpacity, View, Linking } from 'react-native'
import _ from 'lodash'

import XCustomHeader from 'xui/components/XCustomHeader'

import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'

import BaseStoreSettingSticker from 'x/modules/store/BaseStoreSettingSticker'
// import xCONS from 'x/config/constants'
import XText from 'xui/components/XText'
import XCard from 'xui/components/XCard'
import XIcon from 'xui/components/XIcon'
import BackIcon from 'xui/components/BackIcon'
import XContent from 'xui/components/XContent'
import XContainer from 'xui/components/XContainer'
import XSwitch from 'xui/components/XSwitch'
import XButton from 'xui/components/XButton'

export default class BaseUIStoreSettingStickertView extends BaseStoreSettingSticker {
  static displayName = 'BaseUIStoreSettingStickertView'

  renderHeader = () => (
    <XCustomHeader
      title={p.op.t('StoreSetting.HeaderStickerShippingLabel')}
      renderHeaderLeft={this._renderHeaderLeft}
      renderHeaderRight={this._renderHeaderRight}
    />
  )

  _renderHeaderRight = () => {
    const params = util.getNavParams(this.props)
    const cancelBtn = params ? params.cancelBtn : null
    const onChange = params ? params.onChange : null
    return (
      <View>
        {!_.isNil(cancelBtn) && !_.isNil(onChange) && onChange ? (
          <TouchableOpacity style={{ width: 50, height: 50, justifyContent: 'center', paddingRight: 4 }} onPress={() => cancelBtn()}>
            <XText variant='primary'>{p.op.t('Order.cancelled')}</XText>
          </TouchableOpacity>
        ) : null}
      </View>
    )
  }

  _renderHeaderLeft = () => {
    const params = util.getNavParams(this.props)
    const saveBtn = params ? params.saveBtn : null
    const onChange = params ? params.onChange : null
    return (
      <View>
        {!_.isNil(saveBtn) && !_.isNil(onChange) && onChange ? (
          <TouchableOpacity style={{ width: 50, height: 50, justifyContent: 'center', paddingLeft: 10 }} onPress={() => saveBtn()}>
            <XText variant='primary'>{p.op.t('save')}</XText>
          </TouchableOpacity>
        ) : (
          <TouchableOpacity style={{ width: 50, height: 40, alignItems: 'center', justifyContent: 'center' }} onPress={this._goBack}>
            <BackIcon />
          </TouchableOpacity>
        )}
      </View>
    )
  }

  _renderTextAndSwitch = (textLabel: string, value: boolean) => (
    <HStack alignItems='center' justifyContent='space-between'>
      <XText>{textLabel}</XText>
      <XSwitch value={value} onValueChange={(val) => this.onChangeValue('isActive', val)} />
    </HStack>
  )

  _goBack = () => {
    util.navGoBack(this.props)
  }

  _rendeRadioPaperangTypeList = () => {
    const { printSetting } = this.state
    if (_.isNil(printSetting)) {
      return null
    }
    return this.PAPERRANG_TYPE.map((data: { id: number; url: string; txt: string }, index: number) => {
      const checked = printSetting.p.i === data.id
      return (
        <HStack py='2' key={index}>
          <TouchableOpacity onPress={() => this.onChangeSelectedValue(data)} style={{ flex: 1, flexDirection: 'row' }}>
            <XIcon name={checked ? 'radio-button-on' : 'radio-button-off'} family='Ionicons' />
            <XText variant='active' pl='2'>
              {data.txt}
            </XText>
          </TouchableOpacity>
          <XButton onPress={() => Linking.openURL(data.url)} size='sm' variant='outline'>
            ดูตัวอย่าง
          </XButton>
        </HStack>
      )
      // return (
      //   <TouchableOpacity
      //     style={{ flexDirection: 'row', flex: 1, padding: 2, justifyContent: 'center' }}
      //     key={index}
      //     onPress={() => this.onChangeSelectedValue(data)}>
      //     <View style={{ width: 30, height: 35 }}>
      //       {/* <TouchableOpacity style={{ width: 30, height: 35 }} onPress={() => this.onChangeSelectedValue(data)}> */}
      //       {RADIO(checked)}
      //       {/* </TouchableOpacity> */}
      //     </View>
      //     <View style={{ flex: 1 }}>
      //       <Text style={{ fontSize: FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE, paddingTop: 3 }}>{data.txt}</Text>
      //     </View>
      //     {_.isNil(url) ? null : (
      //       <View style={{ flex: 0 }}>
      //         <TouchableOpacity
      //           style={{
      //             width: 60,
      //             height: 25,
      //             borderColor: APP_MAIN,
      //             borderWidth: 0.8,
      //             borderRadius: 7,
      //             backgroundColor: COLORS.BG_LIGHT_GREY,
      //             justifyContent: 'center',
      //           }}
      //           onPress={() => Linking.openURL(data.url)}>
      //           <Text
      //             style={{
      //               fontSize: FONT_SIZE_NORMAL,
      //               color: COLORS.TEXT_ACTIVE,
      //               justifyContent: 'center',
      //               paddingLeft: 6,
      //             }}>
      //             ดูตัวอย่าง
      //           </Text>
      //         </TouchableOpacity>
      //       </View>
      //     )}
      //   </TouchableOpacity>
      // )
    })
  }

  _renderListOptions = (text: string, key: string, value: boolean) => (
    <TouchableOpacity onPress={() => this.onChangeValue(key, !value)}>
      <HStack py='2'>
        <XIcon name={value ? 'check-square-o' : 'square-o'} family='FontAwesome' />
        <XText variant='active' pl='2'>
          {text}
        </XText>
      </HStack>
    </TouchableOpacity>
  )

  _renderOnOffSetting = () => {
    const { isActive } = this.state
    return (
      <XCard p='4' borderRadius='xl' w='full'>
        {this._renderTextAndSwitch(p.op.t('enabled'), isActive)}
      </XCard>
    )
  }

  _renderOtherData = () => {
    const {
      isActive,
      showSenderNameAndAddress,
      showProductListWhenNoShippingLabelImage,
      showProductListWhenHasShippingLabelImage,
      showXsellyBarcodeSticker,
    } = this.state
    if (!isActive) {
      return null
    }
    return (
      <XCard p='4' borderRadius='xl' w='full'>
        <XText variant='active' bold>
          แสดงข้อมูลเพิ่มเติม
        </XText>
        {/* <Text style={{ fontSize: FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE, paddingTop: 0, paddingBottom: 10 }}>
                    {p.op.t('showMordDetailInCoverSheet')}
                  </Text> */}
        {this._renderListOptions(`ชื่อและที่อยู่ผู้ส่ง`, 'showSenderNameAndAddress', showSenderNameAndAddress)}
        {/* <View style={{ borderBottomWidth: 1, borderBottomColor: COLORS.TEXT_INACTIVE }} /> */}
        {this._renderListOptions(
          p.op.t('printing.showProductListWhenNoShippingLabelImage'),
          'showProductListWhenNoShippingLabelImage',
          showProductListWhenNoShippingLabelImage
        )}
        {this._renderListOptions(
          p.op.t('printing.showProductListWhenHasShippingLabelImage'),
          'showProductListWhenHasShippingLabelImage',
          showProductListWhenHasShippingLabelImage
        )}
        {this._renderListOptions(p.op.t('printing.showBarcodeSticker'), 'showXsellyBarcodeSticker', showXsellyBarcodeSticker)}
      </XCard>
    )
  }

  _renderSettingShippingLable = () => {
    const { isActive } = this.state
    if (!isActive) {
      return null
    }
    return (
      <XCard p='4' borderRadius='xl' w='full'>
        <XText variant='active'>ขนาดกระดาษ Sticker</XText>
        {/* <View style={{ borderBottomWidth: 1, borderBottomColor: COLORS.TEXT_INACTIVE }} /> */}
        <XText variant='inactive'>(รองรับรูปแนบใบปะหน้าทุกแบบ)</XText>
        {this._rendeRadioPaperangTypeList()}
      </XCard>
    )
  }

  render() {
    return (
      <XContainer>
        {this.renderHeader()}
        <XContent>
          <VStack w='full' p='2' space='2'>
            {this._renderOnOffSetting()}
            {this._renderOtherData()}
            {this._renderSettingShippingLable()}
          </VStack>
        </XContent>
        {/* {this._renderFooter()} */}
      </XContainer>
    )
  }
}
