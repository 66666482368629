// import PropTypes from 'prop-types'
import { Component } from 'react'
import { log, setStatePromise, isAlreadySelectedStore, delay } from 'x/utils/util'
import p from 'x/config/platform-specific'
import _ from 'lodash'
import { UGListViewProps, PGListViewProps, PGListViewState, ProductGroup } from 'x/index'
import CONS from 'x/config/constants'
import * as util from 'x/utils/util'
import { canDoAtSelectedStore } from 'x/utils/acl'
import { Map, List } from 'immutable'
import * as NavActions from 'x/utils/navigation'

export default class BaseProductGroupListView extends Component<PGListViewProps, PGListViewState> {
  _inProcess: boolean

  _isGoBack: boolean

  _isSetEdited: boolean

  constructor(props: UGListViewProps) {
    // @ts-ignore
    super(props)
    this._inProcess = false
    this._isGoBack = false
    this._isSetEdited = false

    this.state = {
      editingProductGroups: null,
      selectedUserGroup: null,
      isInitialized: false,
      isEdited: false,
      submitting: false,
      isRefreshing: false,
      mode: 'SORT',
    }
  }

  async componentDidMount() {
    await this._firstInitialize()
    const { mode } = this._getParams()
    await setStatePromise(this, {
      mode: _.isNil(mode) ? 'VIEW' : mode,
    })
  }

  componentDidUpdate(prevProps: PGListViewProps, prevState: PGListViewState) {
    const { selectedProductGroups, navigation } = this.props
    const { editingProductGroups, isInitialized, isEdited } = this.state
    // log('componentDidUpdate update editingProductGroups begin')
    if (
      isInitialized &&
      (prevProps.selectedProductGroups !== selectedProductGroups ||
        (!prevProps.selectedProductGroups && List.isList(selectedProductGroups)) ||
        (List.isList(selectedProductGroups) && !_.isArray(editingProductGroups)))
    ) {
      // log('componentDidUpdate update editingProductGroups found')
      this._updateEditingProductGroups()
    }

    if (!prevProps.isEdited && isEdited) {
      if (!this._isSetEdited) {
        this._isSetEdited = true
        navigation.setParams({ isEdited: true })
      }
    }
  }

  async _firstInitialize(): Promise<void> {
    const isValidSelectedStore = await this._isValidSelectedStore()
    if (!isValidSelectedStore) {
      p.op.alert('ไม่สามารถโหลดรายชื่อรายการราคาได้', 'กรุณากลับไปเลือกร้านค้าใหม่อีกครั้ง')
      this._goback()
      return
    }
    const { selectedProductGroups, selectedUserGroups, navigation, selectedStore } = this.props
    await setStatePromise(this, {
      editingProductGroups: List.isList(selectedProductGroups) ? selectedProductGroups.toJS() : null,
    })
    // const { setParams } = navigation
    // const { store_id, mode, ug_id } = this._getParams()
    // console.log(`ug_id `, ug_id)
    // console.log(`selectedUserGroups `, selectedStore.get(`channels`))
    // if (mode === CONS.PRODUCT_GROUP_LIST_MODE.ASSIGN_TO_UG) {
    //   let foundUserGroup =
    //     ug_id && List.isList(selectedUserGroups) ? selectedUserGroups.find((ug) => Map.isMap(ug) && ug.get('id') === ug_id) : null
    //   if (!ug_id || !Map.isMap(foundUserGroup)) {
    //     foundUserGroup = selectedStore.get(`channels`).find((channel) => Map.isMap(channel) && channel.get('id') === ug_id)
    //     if (!ug_id || !Map.isMap(foundUserGroup)) {
    //       p.op.alert('ไม่พบสมาชิก', 'กรุณาทำการเลือกสมาชิกแล้วทำรายการใหม่อีกครั้ง')
    //       this._goback()
    //       return
    //     }
    //   }
    // }
    // console.log('selectedProductGroups => ', selectedProductGroups.toJS())
    // if (List.isList(selectedProductGroups)) {
    //   await setStatePromise(this, {
    //     // editingProductGroups: selectedProductGroups.toJS(),
    //     editingProductGroups: null,
    //     isSorted: false,
    //   })
    // }

    // setParams({
    //   addProductGroup: this._addProductGroup,
    //   saveNewProductGroupSorted: this._saveNewProductGroupSorted,
    //   cancelSorting: this._cancelSorting,
    //   saveSyncProductGroups: this._saveSyncProductGroups,
    //   cancelSaveSyncProductGroups: this._cancelSaveSyncProductGroups,
    // })

    // await this._updateEditingProductGroups()
  }

  _goback() {
    const { navigation } = this.props
    if (this._isGoBack || !navigation) {
      return
    }
    this._isGoBack = true

    util.navGoBack(this.props)
  }

  _handleRefreshProductGroups = async () => {
    const { fetchProductGroups } = this.props
    if (!_.isFunction(fetchProductGroups)) {
      throw new Error('ProductGroupListView:: No fetchProductGroups function.')
    }

    const { store_id } = this._getParams()
    if (!store_id) {
      throw new Error('ProductGroupListView:: No store_id.')
    }

    await setStatePromise(this, { isRefreshing: true })
    const afterFetched = () => this.setState({ isRefreshing: false })
    this.props.fetchProductGroups({
      body: { store_id },
      successCallback: afterFetched,
      failedCallback: afterFetched,
    })
  }

  _updateEditingProductGroups = async (): Promise<void> => {
    const { selectedProductGroups, selectedUserGroups, selectedStore } = this.props
    const { mode, ug_id } = this._getParams()
    const editingProductGroups = selectedProductGroups.toJS()
    let selectedUserGroup = null
    // console.log(`_updateEditingProductGroups`, selectedUserGroups)
    if (ug_id && mode === CONS.PRODUCT_GROUP_LIST_MODE.ASSIGN_TO_UG) {
      let foundUserGroup =
        ug_id && List.isList(selectedUserGroups) ? selectedUserGroups.find((ug) => Map.isMap(ug) && ug.get('id') === ug_id) : null
      if (!ug_id || !Map.isMap(foundUserGroup)) {
        foundUserGroup = selectedStore.get(`channels`).find((channel) => Map.isMap(channel) && channel.get('id') === ug_id)
        if (!ug_id || !Map.isMap(foundUserGroup)) {
          p.op.alert('ไม่พบสมาชิก', 'กรุณาทำการเลือกสมาชิกแล้วทำรายการใหม่อีกครั้ง')
          this._goback()
          return
        }
      }
      const pgs = foundUserGroup.has('pgs') ? foundUserGroup.get('pgs') : List([])
      log('_updateEditingProductGroups pgs => ', pgs)
      // @ts-ignore
      if (pgs.size > 0) {
        editingProductGroups.forEach((pg, idx) => {
          const pg_id = pg.id
          const foundAssignedPG = pgs.find((assignedPG) => Map.isMap(assignedPG) && assignedPG.get('id') === pg_id)
          // log(`_updateEditingProductGroups pg_id=${pg_id} foundAssignedPG.toJS() => `, foundAssignedPG.toJS())
          if (Map.isMap(foundAssignedPG) && foundAssignedPG.get('id')) {
            const assignedObject = { isAssigned: true }
            if (foundAssignedPG.get('discount_percent')) {
              // @ts-ignore
              assignedObject.discount_type = 1
              // @ts-ignore
              assignedObject.discount_percent = foundAssignedPG.get('discount_percent').toString()
            } else if (foundAssignedPG.get('discount_amount')) {
              // @ts-ignore
              assignedObject.discount_type = 2
              // @ts-ignore
              assignedObject.discount_amount = foundAssignedPG.get('discount_amount').toString()
            } else {
              // @ts-ignore
              assignedObject.discount_type = 0
            }
            editingProductGroups[idx] = {
              ...editingProductGroups[idx],
              ...assignedObject,
            }
          }
        })
      }
      selectedUserGroup = foundUserGroup.toJS()
      log('_updateEditingProductGroups selectedUserGroup => ', selectedUserGroup)
      log('_updateEditingProductGroups editingProductGroups => ', editingProductGroups)
    }

    await setStatePromise(this, { editingProductGroups, isInitialized: true, selectedUserGroup })
  }

  async _isValidSelectedStore(): Promise<boolean> {
    const { fetchMyStore, selectedStore } = this.props
    // const { mode } = this._getParams()
    const store_id = selectedStore.get('id')
    // if (!store_id || !_.includes(CONS.PRODUCT_GROUP_LIST_MODE, mode)) {
    //   p.op.alert('ไม่สามารถโหลดรายชื่อรายการราคาได้', 'กรุณากลับไปเลือกร้านค้าใหม่อีกครั้ง')
    //   this._goback()
    //   return false
    // }
    if (!isAlreadySelectedStore()) {
      const body = { store_id }
      const isFetchedStore = await new Promise<boolean>((fetchedStore) => {
        fetchMyStore({
          body,
          successCallback: () => fetchedStore(true),
          failedCallback: () => fetchedStore(false),
        })
      })
      // eslint-disable-next-line no-promise-executor-return
      await new Promise((loadStoreDelay) => setTimeout(loadStoreDelay, 200))

      if (!isFetchedStore) {
        p.op.alert('ไม่สามารถโหลดรายชื่อรายการราคาได้', 'กรุณากลับไปเลือกร้านค้าใหม่อีกครั้ง')
        this._goback()
        return false
      }
    }

    return true
  }

  _getParams(): { store_id?: number; mode?: string; ug_id?: number; callBackMKP?: () => void } {
    // const { navigation } = this.props
    // const { state = null } = navigation
    const params = util.getNavParams(this.props)
    if (!params) {
      return {}
    }
    return params
  }

  _saveSyncProductGroups = async (): Promise<void> => {
    if (this._inProcess) {
      return
    }
    this._inProcess = true
    await this._beginSubmitting()

    const { editingProductGroups, selectedUserGroup } = this.state
    log('_saveSyncProductGroups editingProductGroups => ', editingProductGroups)

    const body: { [key: string]: any } = {}

    const { store_id } = this._getParams()
    if (store_id) {
      body.store_id = store_id
    } else {
      // should impossible
      p.op.alert('ไม่พบข้อมูลร้านค้า', 'กรุณากลับไปเลือกร้านค้าใหม่อีกครั้ง')
      await this._endSubmitting()
      return
    }

    if (selectedUserGroup && selectedUserGroup.id) {
      body.ug_id = selectedUserGroup.id
    } else {
      // should impossible
      p.op.alert('ไม่พบข้อมูลรายการราคา', 'กรุณากลับไปเลือกรายการราคาใหม่อีกครั้ง')
      await this._endSubmitting()
      return
    }

    body.pgs = []
    for (let i = 0; i < editingProductGroups.length; i++) {
      const focusedPG = editingProductGroups[i]
      const { id, name, isAssigned, discount_type, discount_amount, discount_percent } = focusedPG
      if (id && isAssigned && _.includes([0, 1, 2], discount_type)) {
        if (discount_type === 1 && (!discount_percent || discount_percent <= 0)) {
          p.op.alert('ระบุข้อมูลไม่ครบถ้วน', `กรุณาระบุส่วนลดที่เป็นเปอร์เซ็นของรายการราคา "${name}" ให้ถูกต้อง\n(ส่วนลดต้องมีค่า 1-100%)`)
          await this._endSubmitting()
          return
        }
        if (discount_type === 2 && (!discount_amount || discount_amount <= 0)) {
          p.op.alert('ระบุข้อมูลไม่ครบถ้วน', `กรุณาระบุส่วนลดที่เป็นบาทของรายการราคา "${name}" ให้ถูกต้อง\n(ส่วนลดต้องมีค่ามากกว่า ฿1)`)
          await this._endSubmitting()
          return
        }

        // body.pgs.push({ pg_id: id, discount_percent, discount_amount })
        const pgProperties = { pg_id: id }
        // @ts-ignore
        if (!_.isNaN(parseFloat(discount_percent))) {
          // @ts-ignore
          const discountPercent = parseFloat(discount_percent)
          if (discountPercent < 1 || discountPercent > 100) {
            p.op.alert('ส่วนลดไม่ถูกต้อง', `กรุณาระบุส่วนลดที่เป็นเปอร์เซ็นของรายการราคา "${name}" ให้ถูกต้อง\n(ส่วนลดต้องมีค่า 1-100%)`)
            await this._endSubmitting()
            return
          }
          // @ts-ignore
          pgProperties.discount_percent = Math.abs(discountPercent)
        }
        // @ts-ignore
        if (!_.isNaN(parseFloat(discount_amount))) {
          // @ts-ignore
          const discountAmount = parseFloat(discount_amount)
          if (discountAmount < 1) {
            p.op.alert('ส่วนลดไม่ถูกต้อง', `กรุณาระบุส่วนลดที่เป็นบาทของรายการราคา "${name}" ให้ถูกต้อง\n(ส่วนลดต้องมีค่ามากกว่า ฿1)`)
            await this._endSubmitting()
            return
          }
          // @ts-ignore
          pgProperties.discount_amount = Math.abs(discountAmount)
        }
        body.pgs.push(pgProperties)
      }
    }

    // const { state } = this.props.navigation
    // if (_.isNil(state)) {
    //   return null
    // }
    // const { params } = state
    const params = util.getNavParams(this.props)
    const isMKP = !_.isNil(params.is_mkp) && params.is_mkp ? params.is_mkp : false

    let mag = 'กรุณายืนยันว่าคุณต้องการบันทึกการตั้งค่าใหม่สำหรับสมาชิก (หากยืนยัน การดำเนินการอาจจะใช้เวลานาน กรุณารอสักครู่)'
    let title = 'ยืนยันบันทึกการตั้งค่ารายการราคา'
    if (isMKP) {
      title = ' ยืนยันการผูกรายการราคากับช่องทางขายนี้'
      mag = ''
    }
    const isUserConfirmed = await new Promise((passConfirm) => {
      p.op.showConfirmation(
        title,
        mag,
        () => passConfirm(true),
        () => passConfirm(false)
      )
    })

    if (!isUserConfirmed) {
      await this._endSubmitting()
      return
    }

    if (body.pgs && body.pgs.length === 0) {
      p.op.alert(
        'ไม่สามารถถอดรายการราคาออกจากกลุ่มสมาชิก',
        'กรุณาเลือกรายการราคาอย่างน้อยหนึ่งกลุ่ม เนื่องจากการปลดกลุ่มสินค้าทั้งหมดออกจากกลุ่มสมาชิก สมาชิกจะได้รับผลกระทบคือไม่สามารถมองเห็นสินค้า และเปิดออเดอร์ใดๆ ได้'
      )
      await this._endSubmitting()
      return
    }

    log('_saveSyncProductGroups body => ', body)
    // await new Promise(changeModeDelay => setTimeout(changeModeDelay, 3200))

    const res = await new Promise((submitted) => {
      const failedCallback = () => submitted(null)
      // TODO: sync with backend api again and watch response is correctly before nav back
      this.props.saveSyncProductGroupsToUserGroup({ body, successCallback: submitted, failedCallback })
    })

    log('_saveSyncProductGroups res => ', res)
    if (res) {
      // await this._firstInitialize()
      await new Promise((changeModeDelay) => setTimeout(changeModeDelay, 200))
      p.op.showToast('บันทึกการเลือกรายการราคาสำเร็จ', 'success')
      const params = this._getParams()
      if (_.isFunction(params.callBackMKP)) {
        params.callBackMKP(body.pgs)
      }
      this._goback()
    }

    await this._endSubmitting()
  }

  _beginSubmitting = async (): Promise<void> => {
    this.props.navigation.setParams({ submitting: true })
    await setStatePromise(this, { submitting: true })
    await new Promise((delay) => setTimeout(delay, 200))
  }

  _endSubmitting = async (): Promise<void> => {
    this.props.navigation.setParams({ submitting: false })
    await setStatePromise(this, { submitting: false })
    await new Promise((delay) => setTimeout(delay, 200))
    this._inProcess = false
  }

  _cancelSaveSyncProductGroups = async () => {
    log('_saveNewProductGroupSorted')
    const { setParams } = this.props.navigation
    const isUserConfirmed = await new Promise((passConfirm) => {
      p.op.showConfirmation(
        'ไม่บันทึกการเปลี่ยนแปลงรายการราคา',
        'กรุณายืนยันว่าข้อมูลการเปลี่ยนแปลงรายการราคาจะสูญหาย',
        () => passConfirm(true),
        () => passConfirm(false)
      )
    })

    if (!isUserConfirmed) {
      return
    }

    await setStatePromise(this, { isInitialized: false, isEdited: false })
    setParams({ isEdited: false })
    this._isSetEdited = false
    await new Promise((delay) => setTimeout(delay, 200))

    await this._firstInitialize()
    // await new Promise(delay => setTimeout(delay, 200))
    // ข้างล่างนี้มีไว้ถ้าเป็น channel จะสามารถ goBack ได้ทันที
    const params = util.getNavParams(this.props)
    if (_.isNil(params)) {
      return null
    }
    const isMKP = !_.isNil(params.is_mkp) && params.is_mkp ? params.is_mkp : false
    if (isMKP) {
      util.navGoBack(this.props)
    }
  }

  _viewProductGroup = async (index: number, data: ProductGroup) => {
    // log(`_viewProductGroup index = ${index} :: data => `, data)
    const store_id = this.props.selectedStore.get('id')
    if (!store_id || !data || _.isEmpty(data)) {
      return
    }
    const pg_id = data.id
    if (p.op.isWeb()) {
      this.props.navigation.popToTop()
      await delay(200)
    }
    this.props.navigation.dispatch(
      NavActions.navToProductGroupView({
        store_id,
        pg_id,
        mode: CONS.PRODUCT_GROUP_VIEW_MODE.VIEW,
        callBack: () => this._firstInitialize(),
      })
    )
  }

  _toggleProductGroup = (index: number, data: ProductGroup): void => {
    const { store_id } = this._getParams()
    if (!store_id || !data || !data.id) {
      return
    }
    const editingProductGroups = _.cloneDeep(this.state.editingProductGroups)
    const {
      isAssigned = false,
      // discount_percent = null,
      // discount_amount = null,
      // discount_type = null,
    } = editingProductGroups[index]

    if (isAssigned) {
      editingProductGroups[index].isAssigned = false
    } else {
      editingProductGroups[index].isAssigned = true
      editingProductGroups[index].discount_type = 0
      editingProductGroups[index].discount_percent = null
      editingProductGroups[index].discount_amount = null
    }

    const isAssignedArray = editingProductGroups.map((epg) => _.has(epg, 'isAssigned') && epg.isAssigned)
    let hasAssignedSomePG = false
    for (let i = 0; i < isAssignedArray.length; i++) {
      if (isAssignedArray[i]) {
        hasAssignedSomePG = true
        break
      }
    }

    if (!hasAssignedSomePG) {
      // const { state } = this.props.navigation
      // if (_.isNil(state)) {
      //   return null
      // }
      // const { params } = state
      const params = util.getNavParams(this.props)
      const isMKP = !_.isNil(params.is_mkp) && params.is_mkp ? params.is_mkp : false
      const msg = isMKP ? 'กรุณาเลือกอย่างน้อย 1 ราคา' : 'กรุณาเลือกรายการราคาให้กับกลุ่มสมาชิกอย่างน้อยหนึ่งกลุ่ม'
      p.op.alert('คำแนะนำ', msg)
      return
    }

    this.setState({ editingProductGroups, isEdited: true })
  }

  async _saveNewProductGroupSorted() {
    if (this._inProcess) {
      return
    }
    this._inProcess = true

    log('_saveNewProductGroupSorted')
    const { pgs_updated_at, sortPrioritiesProductGroups, navigation } = this.props
    const { setParams } = navigation
    const { editingProductGroups, isSorted } = this.state
    // if (!isSorted) {
    //   this._inProcess = false
    //   p.op.showToast('ไม่ได้มีการลำดับใหม่', 'warning')
    //   await this._firstInitialize()
    //   return
    // }

    await setStatePromise(this, {
      submitting: true,
    })

    // setParams({ submitting: true })
    await new Promise((beginDelay) => setTimeout(beginDelay, 200))

    const body: { [key: string]: any } = {}
    const priorities = []

    const { store_id } = this._getParams()
    if (store_id) {
      body.store_id = store_id
    }

    if (pgs_updated_at) {
      body.pgs_updated_at = pgs_updated_at
    }

    editingProductGroups.forEach((pg, idx) => {
      const { id, priority } = pg
      priorities.push({ pg_id: id, priority })
    })

    body.priorities = priorities
    if (body.store_id) {
      // console.log('_saveNewProductGroupSorted body => ', body)
      // p.op.alert('submit body', JSON.stringify(body))
      const res = await new Promise((submitted) => {
        const failedCallback = () => submitted(null)
        sortPrioritiesProductGroups({ body, successCallback: submitted, failedCallback })
      })

      // console.log('_saveNewProductGroupSorted res => ', res)
      if (res) {
        await this._firstInitialize()
        // setParams({ isSortable: false })
        // setParams({ mode: CONS.PRODUCT_GROUP_LIST_MODE.VIEW })
        await setStatePromise(this, {
          mode: CONS.PRODUCT_GROUP_LIST_MODE.VIEW,
          submitting: false,
        })
        await new Promise((changeModeDelay) => setTimeout(changeModeDelay, 200))
      }
    }

    await new Promise((finishDelay) => setTimeout(finishDelay, 200))
    setParams({ submitting: false })
    this._inProcess = false
  }

  _cancelSorting = async () => {
    log('_saveNewProductGroupSorted')
    const { setParams } = this.props.navigation
    const { isSorted } = this.state
    if (isSorted) {
      const isUserConfirmed = await new Promise((passConfirm) => {
        p.op.showConfirmation(
          'ไม่บันทึกการลำดับใหม่',
          'กรุณายืนยันว่าข้อมูลการลำดับรายการราคาจะสูญหาย',
          () => passConfirm(true),
          () => passConfirm(false)
        )
      })
      if (!isUserConfirmed) {
        return
      }
    }

    await this._firstInitialize()
    await new Promise((delay) => setTimeout(delay, 200))
    await setStatePromise(this, {
      mode: CONS.PRODUCT_GROUP_LIST_MODE.VIEW,
    })
    // setParams({ mode: CONS.PRODUCT_GROUP_LIST_MODE.VIEW })
  }

  _onDiscountTypeChange = (index: number, data: ProductGroup, optionIndex: number): void => {
    // log('_onDiscountTypeChange index => ', index)
    // log('_onDiscountTypeChange data => ', data)
    // log('_onDiscountTypeChange optionIndex => ', optionIndex)
    const { store_id } = this._getParams()
    if (!store_id || !data || !data.id) {
      return
    }
    const editingProductGroups = _.cloneDeep(this.state.editingProductGroups)
    editingProductGroups[index].discount_type = optionIndex
    editingProductGroups[index].discount_percent = optionIndex === 1 ? 0 : null
    editingProductGroups[index].discount_amount = optionIndex === 2 ? 0 : null
    this.setState({ editingProductGroups, isEdited: true })
  }

  _onDiscountAmountChange = (index: number, data: ProductGroup, optionIndex: number, newDiscount: number | string): void => {
    const { store_id } = this._getParams()
    if (!store_id || !data || !data.id) {
      return
    }

    // log(`_onDiscountAmountChange index=${index} newText => `, newText)
    // log(`_onDiscountAmountChange index=${index} data => `, data)
    // log(`_onDiscountAmountChange index=${index} optionIndex => `, optionIndex)
    const editingProductGroups = _.cloneDeep(this.state.editingProductGroups)
    if (optionIndex === 1) {
      // @ts-ignore
      editingProductGroups[index].discount_percent = !_.isNaN(parseFloat(newDiscount)) ? parseFloat(newDiscount) : 0
      editingProductGroups[index].discount_amount = null
    } else if (optionIndex === 2) {
      editingProductGroups[index].discount_percent = null
      // @ts-ignore
      editingProductGroups[index].discount_amount = !_.isNaN(parseFloat(newDiscount)) ? parseFloat(newDiscount) : 0
    } else {
      editingProductGroups[index].discount_percent = null
      editingProductGroups[index].discount_amount = null
    }
    // log(`_onDiscountAmountChange index=${index} editingProductGroups[index] => `, editingProductGroups[index])
    this.setState({ editingProductGroups, isEdited: true })
  }

  _onPressSortProduct = async () => {
    if (!canDoAtSelectedStore(CONS.PERM_STORE_HELPER.PG_EDIT)) {
      p.op.alert('คุณไม่มีสิทธิ์ในการแก้ไขรายการราคา')
      return
    }
    await setStatePromise(this, {
      mode: CONS.PRODUCT_GROUP_LIST_MODE.SORT,
    })
    // this.props.navigation.setParams({
    //   mode: CONS.PRODUCT_GROUP_LIST_MODE.SORT,
    // })
  }

  _getPgAssignedCount = (): number => {
    const { editingProductGroups } = this.state
    if (!editingProductGroups || !_.isArray(editingProductGroups)) {
      return 0
    }
    let count = 0
    editingProductGroups.forEach((pg) => {
      if (pg && pg.isAssigned) {
        count += 1
      }
    })
    return count
  }

  _sortUp = async (index: number) => {
    const { editingProductGroups } = this.state
    const newProductGroups = _.clone(editingProductGroups)
    newProductGroups[index].priority = editingProductGroups[index].priority + 1
    newProductGroups[index - 1].priority = editingProductGroups[index].priority - 1
    const newProductGroupsSort = newProductGroups.sort((pg, pg2) => pg.priority - pg2.priority)
    const newProductGroupsReverse = newProductGroupsSort.reverse()
    await setStatePromise(this, { editingProductGroups: newProductGroupsReverse })
  }

  _sortDown = async (index: number) => {
    const { editingProductGroups } = this.state
    const newProductGroups = _.clone(editingProductGroups)
    newProductGroups[index].priority = editingProductGroups[index].priority - 1
    newProductGroups[index + 1].priority = editingProductGroups[index].priority + 1
    const newProductGroupsSort = newProductGroups.sort((pg, pg2) => pg.priority - pg2.priority)
    const newProductGroupsReverse = newProductGroupsSort.reverse()
    await setStatePromise(this, { editingProductGroups: newProductGroupsReverse })
  }

  _addProductGroup = async () => {
    if (!canDoAtSelectedStore(CONS.PERM_STORE_HELPER.PG_ADD)) {
      p.op.alert('คุณไม่มีสิทธิ์ในการเพิ่มรายการราคา')
      return
    }
    const { subscription, selectedStore } = this.props

    // ถ้าเป็น IOS และเข้าเงื่อนไขก็ให้ปล่อยผ่าน // https://app.clickup.com/t/86cvy1xnd
    const isNoobIos = util.isIosNoobCheckByPass()
    if (!isNoobIos) {
      // Start ตรวจสอบโควต้า Product Groups
      const pgGroupsNumber = this.props.selectedStore.get('product_groups').size
      if (subscription && pgGroupsNumber >= subscription.get('pg_quota')) {
        p.op.alert('แพ็กเกจของคุณไม่สามารถเพิ่มรายการราคาได้อีก', p.op.t('Subscription.warning.insufficientTypeMsg'))
        return
      }
      // END ตรวจสอบโควต้า Product Groups
    }

    const store_id = selectedStore.get('id')
    if (!store_id) {
      return
    }
    if (p.op.isWeb()) {
      this.props.navigation.popToTop()
      await delay(200)
    }
    this.props.navigation.dispatch(
      NavActions.navToProductGroupView({
        store_id,
        mode: CONS.PRODUCT_GROUP_VIEW_MODE.ADD,
        callBack: () => this._firstInitialize(),
      })
    )
  }
}
