import { setConfiguration } from '../utils/configuration'
import { getConfig } from './mode'

const config = getConfig()
const settings = {
  debugAlert: false,
  app: {
    API_ROOT: config.app_apiRoot,
    GO_API_ROOT: config.app_goApiRoot,
    timezone: 'Asia/Bangkok',
  },
  s3: {
    // host: '192.168.1.115',
    // endpoint: `http://${HOST}/efshop/productTmpls`,
    options: {
      // upload options for RNS3
      // keyPrefix: config.s3_options_keyPrefix,
      keyPrefix: config.s3_prefix_main,
      bucket: 'xsf',
      // region: 'ap-southeast-1',
      // // accessKey: "FAILED_KEY",
      // accessKey: 'AKIAJRMAKR27MJYOP5HQ',
      // secretKey: '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',
      // successActionStatus: 201,
      // awsUrl: 's3-ap-southeast-1.amazonaws.com',
      // // credential From IAM
      // accessKeyId: 'AKIAJTDZA5TYGV7U3LZA',
      // secretAccessKey: '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',
      // identityPoolId: 'ap-southeast-1:2d31af49-6320-4686-adef-844e416c98ad',

      accessKey: '4UORLDD34ADH4BQJ6NUV',
      secretAccessKey: 'VjxTES5aIfcxX0zBpuKZkhme1OSJddXDW6VlHKc22CU',
      region: 'sgp1',
      cdnSubDomain: 's1.xselly.com',
    },
  },
  s3_2nd: {
    options: {
      bucket: 'xs2',
      region: 'sfo2', // San Francisco
      cdnSubDomain: 's2.xselly.com',
    },
  },
  s3_3rd: {
    options: {
      bucket: 'xs3',
      region: 'ams3', // Amsterdam
      cdnSubDomain: 's3.xselly.com',
    },
  },
  // awsAmplifyConfig: {
  //   // 'aws_app_analytics': 'enable',
  //   'aws_cognito_identity_pool_id': 'ap-southeast-1:2d31af49-6320-4686-adef-844e416c98ad',
  //   'aws_cognito_region': 'ap-southeast-1',
  //   // 'aws_mobile_analytics_app_id': 'e90859d74e7245659c66d5dba586cf56',
  //   // 'aws_mobile_analytics_app_region': 'us-east-1',
  //   'aws_project_id': 'e19c515b-f50f-432a-97c7-6494f580d875',
  //   'aws_project_name': 'xselly-2018-04-30',
  //   'aws_project_region': 'ap-southeast-1',
  //   'aws_resource_name_prefix': 'xselly-mobilehub-190031400',
  //   'aws_user_files': 'enable',
  //   // 'aws_user_files_s3_bucket': 'xselly-userfiles-mobilehub-190031400',
  //   'aws_user_files_s3_bucket': config.s3_options_bucket,
  //   'aws_user_files_s3_bucket_region': 'ap-southeast-1',
  // },
  // codepush: {
  //   KEY_STAGING_IOS: '_paLe2C3LMxX3AZyBs2ahNxFUy_w1dad88f6-0803-4f28-887b-19c342985344',
  //   KEY_STAGING_ANDROID: 'uNyKsLmmQxq2Adt4e2zqY9Qus0601dad88f6-0803-4f28-887b-19c342985344',
  //   KEY_PRODUCTION_IOS: '7JwprpgZHlO3B6V7KkFXcxsjiNqU1dad88f6-0803-4f28-887b-19c342985344',
  //   KEY_PRODUCTION_ANDROID: 'Ra49dqQKx38OFmAoN2CqKm7Jcz0X1dad88f6-0803-4f28-887b-19c342985344',
  // },
  // codepush: {
  //   KEY_STAGING_IOS: '_paLe2C3LMxX3AZyBs2ahNxFUy_w1dad88f6-0803-4f28-887b-19c342985344',
  //   KEY_STAGING_ANDROID: 'uNyKsLmmQxq2Adt4e2zqY9Qus0601dad88f6-0803-4f28-887b-19c342985344',
  //   KEY_PRODUCTION_IOS: '7JwprpgZHlO3B6V7KkFXcxsjiNqU1dad88f6-0803-4f28-887b-19c342985344',
  //   KEY_PRODUCTION_ANDROID: 'Ra49dqQKx38OFmAoN2CqKm7Jcz0X1dad88f6-0803-4f28-887b-19c342985344',
  // },
  // codepush: {
  //   KEY_DEV_IOS: 'Bn0E05rlUoP9SADr8jbIrzyn49CW1dad88f6-0803-4f28-887b-19c342985344',
  //   KEY_PRODUCTION_IOS: '0UyQx7SJpgY-KNjC9n_cDCuTUHuu1dad88f6-0803-4f28-887b-19c342985344',
  //   KEY_STAGING_IOS: 'rBKbQ86ZDa5zMGtFYBsFr7JlSLr91dad88f6-0803-4f28-887b-19c342985344',
  //   KEY_DEV_ANDROID: 'jWnx7r8gh9yvKrYFsK0_OrQCeXfD1dad88f6-0803-4f28-887b-19c342985344',
  //   KEY_PRODUCTION_ANDROID: 'Z-HWgLWOY9n5bPpHuV5lESzD2XWf1dad88f6-0803-4f28-887b-19c342985344',
  //   KEY_STAGING_ANDROID: '92_i3ZomkG1DxsY_hlWCkSGRYv2u1dad88f6-0803-4f28-887b-19c342985344',
  // },
  // flurry: {
  //   API_KEY_IOS: '47Q4QKTYJVH238KRW9PK',
  //   API_KEY_ANDROID: '5TGW8GD6PTY84PZTBP5G',
  //   API_DEV_KEY_IOS: 'YT35BCKNMD9YR7VH25H4',
  //   API_DEV_KEY_ANDROID: '5ZQJP5FFGBFS5BFXCW2N',
  // },
  // firebase: {
  //   API_KEY_IOS: 'AIzaSyAUtSZGrKDlNxkbDj1gwoZxs0QEX5RzFFY',
  //   API_KEY_ANDROID: 'AIzaSyASxxHeXrAdEnXSxSbgUCtBke7GkhxdngA',
  //   API_DEV_KEY_IOS: 'AIzaSyCRpbv64VuGrHMpSstf_ecBa0rKZihxgWw',
  //   API_DEV_KEY_ANDROID: 'AIzaSyB0Pi70CgXOC-TLPYRXmOxsB9_qdQNyS7U',
  // },
  XSELLY_CONTACT_TEL: '0971671650',
  XSELLY_CONTACT_TEL_DISPLAY: '097-167-1650',
  XSELLY_CONTACT_EMAIL: 'hello@xselly.com',
  XSELLY_CONTACT_FB_FANPAGE_DEEPLINK: 'fb://page/1496142170464840',
  XSELLY_CONTACT_FB_FANPAGE_URL: 'https://www.facebook.com/xsellyapp',
  XSELLY_CONTACT_FB_MESSENGER_URL: 'http://m.me/xsellyapp',
  XSELLY_CONTACT_FB_LINE_URL: 'http://line.me/ti/p/%40xselly',
  XSELLY_CONTACT_FB_LINE_ID: '@XSelly',
  XSELLY_URLS: {
    HOW_TO_FIND_FACEBOOK_ID: 'https://help.xselly.com/kb/get-messenger-id/',
    HOW_TO_FIND_LINE_ID: 'https://help.xselly.com/kb/get-line-id/',
  },
  NAV_DELAY: 0, // milliseconds
  FLOW_TIMEOUT: 4000,
  ORDER_LOAD_INIT_LIMIT: 15,
  ORDER_LOAD_MORE_LIMIT: 20,
  PRODUCTS_LOAD_INIT_LIMIT: 10,
  PRODUCTS_LOAD_MORE_LIMIT: 20,
}

export default settings

// ใช้สำหรับเซ็ตค่า default ลง configuration แบบ Immutable
// (สำหรับ build-in pepperoni function)
export function initializeAppConfiguration() {
  setConfiguration('API_ROOT', settings.app.API_ROOT)
  setConfiguration('GO_API_ROOT', settings.app.GO_API_ROOT)
}
