import React from 'react'

import _ from 'lodash'
// import { COLORS, STYLES } from '../../config/styles'
import { log, setStatePromise } from 'x/utils/util'
import { Map } from 'immutable'
import dayjs, { Dayjs } from 'dayjs'
import xCONS from 'x/config/constants'
import { IXScreenProps } from 'x/types'
import { COLORS } from '../../config/styles'

import p from '../../config/platform-specific'
import * as util from '../../utils/util'

const {
  BG_LIGHT_GREY_ALTERNATIVE,
  TEXT_PRIMARY,
  TEXT_ACTIVE,
  TEXT_INACTIVE,
  APP_MAIN,
  APP_SECONDARY,
  FORM_SUCCESS,
  FORM_ERROR,
  TEXT_ACTIVE_DARK,
  BRAND_Info,
  BRAND_Danger,
  BRAND_Primary,
  BRAND_Success,
  BRAND_Warning,
} = COLORS

export interface BaseReportShippingViewProps extends IXScreenProps<{ store_id: number }> {
  selectedStore: Map<string, any>
  subscription: any
}

export interface BaseReportShippingViewState {
  loading: boolean
  // selectedOptListBy: string;
  // selectedOptDateRange: string;
  selectedOpts: {
    LIST_BY: string
    DATE_RANGE: string
  }
  selectedDateRange: { begin: Dayjs; end: Dayjs }
  SHOW_SHIPPING_TYPE: boolean
  SHOW_TOTAL_AMOUNT: boolean
  SHOW_TOTAL_QTY: boolean
  SHOW_DATE_TIME: boolean
  limitDay: number
  dateRangeDescription: string
}

export default abstract class BaseReportShippingView extends React.Component<BaseReportShippingViewProps, BaseReportShippingViewState> {
  inProcess: boolean

  TXT_TITLES: {
    LIST_BY: string
    DATE_RANGE: string
  }

  TXT_WARNING_DATE_RANGE: string

  OPTIONS: {
    LIST_BY: string[]
    DATE_RANGE: string[]
  }

  TXT_CHECKBOX: {
    TITLE: string
    SHOW_SHIPPING_TYPE: string
    SHOW_TOTAL_AMOUNT: string
    SHOW_TOTAL_QTY: string
    SHOW_DATE_TIME: string
  }
  // OPTIONS_LIST_BY: string[];
  // OPTIONS_DATE_RANGE: string[];

  abstract _getParams(): { store_id: number } | null

  abstract _handleOnDownloadFile(url: string, fileName: string): Promise<void>

  protected constructor(props) {
    super(props)
    this.TXT_TITLES = {
      LIST_BY: 'ดูรายการที่จัดส่งโดย',
      DATE_RANGE: 'ช่วงเวลาที่ต้องการ',
    }

    this.OPTIONS = {
      LIST_BY: ['ฉัน', 'ผู้ขายส่ง'],
      DATE_RANGE: ['วันนี้', 'ระบุช่วงเวลา'],
    }

    this.TXT_CHECKBOX = {
      TITLE: 'แสดงข้อมูลเพิ่มเติมใน Excel (เลือกที่ต้องการ)',
      SHOW_SHIPPING_TYPE: 'รูปแบบจัดส่ง',
      SHOW_TOTAL_AMOUNT: 'ยอดออเดอร์ (บาท)',
      SHOW_TOTAL_QTY: 'จำนวนสินค้า',
      SHOW_DATE_TIME: 'วันเวลาที่จัดส่ง',
    }
    this.TXT_WARNING_DATE_RANGE = 'แพ็กเกจปัจจุบันของคุณไม่สามารถเลือกช่วงเวลาเพื่อดูรายการจัดส่งย้อนหลังได้'

    this.state = {
      loading: false,
      selectedOpts: {
        LIST_BY: this.OPTIONS.LIST_BY[0],
        DATE_RANGE: this.OPTIONS.DATE_RANGE[0],
      },
      selectedDateRange: { begin: dayjs(), end: dayjs() },
      SHOW_SHIPPING_TYPE: false,
      SHOW_TOTAL_AMOUNT: false,
      SHOW_TOTAL_QTY: false,
      SHOW_DATE_TIME: false,
      limitDay: 93,
      dateRangeDescription: '',
    }

    this.inProcess = false

    this._onChangeSegment = this._onChangeSegment.bind(this)
    this._onChangeDateRange = this._onChangeDateRange.bind(this)
    this._isVisibleDateRangePicker = this._isVisibleDateRangePicker.bind(this)
    this._downloadReportShippingUrl = this._downloadReportShippingUrl.bind(this)
    this._computeAllowDays = this._computeAllowDays.bind(this)
    // this._getTxtDateRangeDescription = this._getTxtDateRangeDescription.bind(this)
  }

  _onChangeSegment(optKey: string, newValue: string): void {
    const newSelectedOpts = _.cloneDeep(this.state.selectedOpts)
    newSelectedOpts[optKey] = newValue

    let trialPackage = false
    if (this.props.subscription.get('type') === 999) {
      trialPackage = true
    }

    // แพ็กเกจ (Free)
    if (this.props.subscription.get('type') === xCONS.SUBSCRIPTION_PACKAGE.FREE) {
      const num = +p.op.t('Subscription.packageReportSeller.1')
      const txtDateRangeDescription = `${p.op.t('Subscription.warning.tooManyDaysInPastTitle', { numOfDays: num })}\n${p.op.t(
        'Subscription.warning.tooManyDaysInPastMsg'
      )}`
      this.setState({
        limitDay: num,
        dateRangeDescription: txtDateRangeDescription,
      })
    }
    // if (this.props.subscription.get('type') === xCONS.SUBSCRIPTION_PACKAGE.FREE) {
    //   if (newSelectedOpts[optKey] === this.OPTIONS.DATE_RANGE[3]) {
    //     p.op.alert(p.op.t('Subscription.warning.freePackageWarningReportOnlyThreeDay'), p.op.t('Subscription.warning.insufficientTypeMsg'))
    //     return
    //   }
    // }

    // แพ็กเกจ (Bronze)
    if (this.props.subscription.get('type') === xCONS.SUBSCRIPTION_PACKAGE.BRONZE) {
      const num = +p.op.t('Subscription.packageReportSeller.2')
      const txtDateRangeDescription = `${p.op.t('Subscription.warning.tooManyDaysInPastTitle', { numOfDays: num })}\n${p.op.t(
        'Subscription.warning.tooManyDaysInPastMsg'
      )}`
      this.setState({
        limitDay: num,
        dateRangeDescription: txtDateRangeDescription,
      })
    }
    // แพ็กเกจ Silver
    if (this.props.subscription.get('type') === xCONS.SUBSCRIPTION_PACKAGE.SILVER) {
      const num = +p.op.t('Subscription.packageReportSeller.3')
      const txtDateRangeDescription = `${p.op.t('Subscription.warning.tooManyDaysInPastTitle', { numOfDays: num })}\n${p.op.t(
        'Subscription.warning.tooManyDaysInPastMsg'
      )}`
      this.setState({
        limitDay: num,
        dateRangeDescription: txtDateRangeDescription,
      })
    }
    // แพ็กเกจ Gold
    if (this.props.subscription.get('type') === xCONS.SUBSCRIPTION_PACKAGE.GOLD) {
      const num = +p.op.t('Subscription.packageReportSeller.4')
      const txtDateRangeDescription = `${p.op.t('Subscription.warning.tooManyDaysInPastTitle', { numOfDays: num })}\n${p.op.t(
        'Subscription.warning.tooManyDaysInPastMsg'
      )}`
      this.setState({
        limitDay: num,
        dateRangeDescription: txtDateRangeDescription,
      })
    }
    // แพ็กเกจ (Platinum)
    if (this.props.subscription.get('type') === xCONS.SUBSCRIPTION_PACKAGE.PLATINUM || trialPackage) {
      const num = +p.op.t('Subscription.packageReportSeller.5')
      const txtDateRangeDescription = `${p.op.t(
        'Subscription.warning.dateRangePlatinumTypeTitle'
      )}\n${p.op.t('Subscription.warning.numOfDaysLimitInfoMsg', { numOfDays: num })}`
      this.setState({
        limitDay: num,
        dateRangeDescription: txtDateRangeDescription,
      })
    }

    this.setState({ selectedOpts: newSelectedOpts })
  }

  _onChangeDateRange(newDates: { begin: Dayjs; end: Dayjs }): void {
    if (this.props.subscription.get('type') != 1 && this.props.subscription.get('type') != 5) {
      const { begin } = newDates
      const now = dayjs()
      if (begin.isBefore(now) && Math.abs(begin.diff(now, 'd')) > +this.state.limitDay) {
        newDates.begin = dayjs(now).subtract(+this.state.limitDay, 'day')
        newDates.end = dayjs(now)
        this.setState({ selectedDateRange: newDates })
        return
      }
    }
    this.setState({ selectedDateRange: newDates })
  }

  _isVisibleDateRangePicker(): boolean {
    return this.state.selectedOpts.DATE_RANGE === this.OPTIONS.DATE_RANGE[1]
  }

  _computeAllowDays(): number {
    const { subscription } = this.props
    const allowDays = subscription.has('r_shpg_day') && _.isNumber(subscription.get('r_shpg_day')) ? subscription.get('r_shpg_day') : 1
    // log('_computeAllowDays => ', _.isNumber(allowDays) ? allowDays : parseInt(allowDays))
    return _.isNumber(allowDays) ? allowDays : parseInt(allowDays)
  }

  _canRequestMoreColumns = (): boolean => util.canRequestExtraExcelColumns(this.props.subscription.get('type'))

  // _getTxtDateRangeDescription(): string {
  //   const allowDays = this._computeAllowDays()
  //   let txtDesc = 'สามารถดูรายงานย้อนหลังได้'

  //   if (allowDays === 9999) { //
  //     txtDesc += `ตั้งแต่เปิดร้าน`
  //   } else {
  //     txtDesc += ` ${allowDays} วัน`
  //   }

  //   if (allowDays > 93) {
  //     txtDesc += '\nโดยสามารถดาวน์โหลดรายงานได้สูงสุด 93 วันต่อครั้ง'
  //   }

  //   return txtDesc
  // }

  async _downloadReportShippingUrl(): Promise<void> {
    if (this.inProcess || this.state.loading) {
      return
    }
    this.inProcess = true
    await setStatePromise(this, { loading: true })
    const { selectedStore } = this.props
    const { selectedOpts, selectedDateRange } = this.state
    const store_id = selectedStore.get('id')
    // const { store_id } = this._getParams()
    // console.log('store_id => ', store_id)
    let startDate: Dayjs | string = dayjs()
    let endDate: Dayjs | string = dayjs()
    let asReseller = false
    // ถ้า user เลือก custom date range
    if (selectedOpts.DATE_RANGE === this.OPTIONS.DATE_RANGE[1]) {
      startDate = selectedDateRange.begin
      endDate = selectedDateRange.end
    }

    // ถ้า user เลือก ผู้ขายส่ง
    if (selectedOpts.LIST_BY === this.OPTIONS.LIST_BY[1]) {
      asReseller = true
    }

    startDate = startDate.format('DD-MM-YYYY%2000:00:00.000')
    endDate = endDate.format('DD-MM-YYYY%2023:59:59.999')

    // const startDate = '19-03-2018'
    // console.log('startDate => ', startDate)
    const url = util.getReportShippingExcelUrl({
      store_id,
      startDate,
      endDate,
      asReseller,
      showShippingType: this.state.SHOW_SHIPPING_TYPE,
      showTotalAmount: this.state.SHOW_TOTAL_AMOUNT,
      showTotalQty: this.state.SHOW_TOTAL_QTY,
      showDateTime: this.state.SHOW_DATE_TIME,
    })

    let fileName = asReseller ? `XSelly_Shipping_${startDate}` : `Shipping_${startDate}`

    if (dayjs(endDate).isAfter(dayjs(startDate), 'd')) {
      fileName = `${fileName}_to_${endDate}`
    }

    fileName = `${fileName}.xlsx`

    log('_downloadReportShippingUrl url => ', url)
    log('_downloadReportShippingUrl fileName => ', fileName)
    await this._handleOnDownloadFile(url, fileName)
    await setStatePromise(this, { loading: false })
    p.op.aLogEvent(xCONS.EVENT_NAME.REPORT_SHIPPED_ORDERS, { s: store_id })
    await setTimeout(() => {
      this.inProcess = false
    }, 500)
  }
}
