import BaseUIERPPaymentAccountView from 'xui/modules/erp/BaseUIERPPaymentAccountView'
// import { getConfig } from 'x/config/mode'
// import { WebView } from 'react-native-webview'
// import OrdersSummaryDonut from '../../components/OrdersSummaryDonut'

export default class ERPPaymentAccountView extends BaseUIERPPaymentAccountView {
  static displayName = 'ERPPaymentAccountView'

  static navigationOptions = {
    header: null,
  }
}
