import React, { Component } from 'react'
import { COLORS, STYLES } from 'x/config/styles'
import { setStatePromise } from 'x/utils/util'
import _ from 'lodash'

import { Image, Dimensions } from 'react-native'

import { getCreateToken } from 'x/utils/file'

import ImageGallery from 'react-image-gallery'
// import XModal from './XModal'
// import XModal from 'xui/components/XModal'
import XOverlay from 'xui/components/XOverlay'
import ThemeSizeContext from '../context/ThemeSizeContext'

export interface XGalleryModalProps {
  isVisible: boolean
  images: string[] | null

  initialIndex?: number
  onRequestClose?: () => void
}

export interface XGalleryModalState {
  selectedIndex: number
  scrollState: 'idle' | 'dragging' | 'settling'
  isVisibleSavedToast: boolean
}

// const GalleryContainer = styled.div`
//   .image-gallery-fullscreen-button::before,
//   .image-gallery-play-button::before,
//   .image-gallery-left-nav::before,
//   .image-gallery-right-nav::before {
//     color: ${COLORS.APP_MAIN};
//   }
//   .image-gallery-fullscreen-button:hover::before,
//   .image-gallery-play-button:hover::before,
//   .image-gallery-left-nav:hover::before,
//   .image-gallery-right-nav:hover::before {
//     color: ${COLORS.APP_SECONDARY};
//   }
//   .image-gallery-index {
//     color: ${COLORS.APP_MAIN};
//     background-color: transparent;
//   }
// `

export interface XGallerySource {
  source: { uri: string }
}

const GALLERY_STYLE = {
  flex: 1,
  backgroundColor: 'rgba(15,15,15,0.85)',
  paddingTop: 34,
  paddingBottom: 54,
}

const PAGE_NUMBER_STYLE: { [key: string]: any } = {
  position: 'absolute',
  top: 17,
  right: 8, // 4 is padding
  fontSize: STYLES.FONT_SIZE_LARGER, // 26
  color: COLORS.APP_MAIN,
  fontWeight: 'bold',
  textAlign: 'right',
}

const BTN_ICON_STYLE = {
  fontSize: STYLES.FONT_ICON_LARGER, // 26
  width: STYLES.FONT_ICON_LARGER + 4,
  height: STYLES.FONT_ICON_LARGER + 4,
  color: COLORS.APP_MAIN,
  fontWeight: 'bold',
  textAlign: 'center',
}

const BTN_PADDING = 8
// const BTN_MARGIN = 16
const BTN_MARGIN = 64
const FROM_DEVICE_BOTTOM_MARGIN = 8

const { Fragment } = React

export default class XGalleryModal extends Component<XGalleryModalProps, XGalleryModalState> {
  static displayName = 'XGalleryModal'

  static contextType = ThemeSizeContext

  context!: React.ContextType<typeof ThemeSizeContext>

  galleryRef: any

  randomKey: string

  inProcess: boolean

  DEVICE_WIDTH: number

  DEVICE_HEIGHT: number

  BTN_CLOSE_STYLE: { [key: string]: any }

  BTN_SHARE_STYLE: { [key: string]: any }

  BTN_SAVE_STYLE: { [key: string]: any }

  constructor(props: XGalleryModalProps) {
    super(props)
    this.inProcess = false
    this.randomKey = ''
    this.DEVICE_WIDTH = Dimensions.get('window').width
    this.DEVICE_HEIGHT = Dimensions.get('window').height

    this.state = {
      selectedIndex: props.initialIndex ? props.initialIndex : 0,
      scrollState: 'settling',
      isVisibleSavedToast: false,
    }

    const ICON_WIDTH = BTN_ICON_STYLE.width
    // const ICON_HEIGHT = BTN_ICON_STYLE.height
    this.BTN_CLOSE_STYLE = {
      position: 'absolute',
      bottom: FROM_DEVICE_BOTTOM_MARGIN,
      left: this.DEVICE_WIDTH / 2 - ICON_WIDTH / 2, // 4 is padding
      padding: BTN_PADDING,
      // zIndex: 8000,
    }

    this.BTN_SAVE_STYLE = {
      ...this.BTN_CLOSE_STYLE,
      left: this.DEVICE_WIDTH / 2 - ICON_WIDTH / 2 - (ICON_WIDTH + BTN_MARGIN),
    }

    this.BTN_SHARE_STYLE = {
      ...this.BTN_CLOSE_STYLE,
      left: this.DEVICE_WIDTH / 2 - ICON_WIDTH / 2 + (ICON_WIDTH + BTN_MARGIN),
    }

    // this._convertImageArrayToImageSource = this._convertImageArrayToImageSource.bind(this)
    // this._handleSaveImage = this._handleSaveImage.bind(this)
    // this._handleShareImage = this._handleShareImage.bind(this)
  }

  componentDidMount() {
    this.randomKey = getCreateToken()
    // this.onScreenChange()
  }

  // componentDidUpdate(prevState, prevProps) {
  //   if (this.props.isVisible !== prevProps.isVisible) {
  //     this.onScreenChange()
  //   }
  // }

  // _convertImageArrayToImageSource = (): XGallerySource[] => {
  //   const { images = null } = this.props

  //   if (!_.isArray(images)) {
  //     return []
  //   }

  //   return images.map(imgURI => {
  //     return { source: { uri: imgURI } }
  //   })
  // }

  _convertImageArrayToImageSource = () => {
    const { images = null } = this.props

    if (!_.isArray(images)) {
      return []
    }

    return images.map((imgURI) => ({ original: imgURI }))
  }

  _handleSaveImage = async (): Promise<void> => {
    console.log('NoImpl._handleSaveImage')

    // if (this.inProcess) {
    //   return
    // }
    // this.inProcess = true
    // // this.forceUpdate()
    // // await new Promise(delay => setTimeout(delay, 200))
    // const { images } = this.props
    // const { selectedIndex } = this.state

    // const img_uri = images[selectedIndex]
    // if (!img_uri) {
    //   this.inProcess = false
    //   return
    // }
    // // const isSuccess = await utilN.savePhotoToGallery(img_uri)
    // const isSuccess = await utilN.savePhotoToGallery(img_uri, null, true)
    // // if (isSuccess) {
    // //   await setStatePromise(this,{ isVisibleToast: true })
    // //   setTimeout(this._hideToast, 3000)
    // // }
    // if (isSuccess) {
    //   await this._showSaveSuccessToast()
    // }
    // this.inProcess = false
  }

  _handleShareImage = async (): Promise<void> => {
    console.log('NoImpl._handleShareImage')

    // if (this.inProcess) {
    //   return
    // }
    // this.inProcess = true
    // const { images } = this.props
    // const { selectedIndex } = this.state

    // const img_uri = images[selectedIndex]
    // if (!img_uri) {
    //   this.inProcess = false
    //   return
    // }

    // await utilN.sharePhotoToShareSheet(img_uri)
    // this.inProcess = false
    // // alert(`_handleShareImage selectedIndex => ${selectedIndex}`)
  }

  // _onPageSelected = (newPageIndex: number) => {
  //   // log('onPageSelected newPageIndex => ', newPageIndex)
  //   if (this.state.selectedIndex !== newPageIndex) {
  //     this.setState({ selectedIndex: newPageIndex })
  //   }
  // }

  // // 'idle': there is no interaction with the page scroller happening at the time.
  // // 'dragging': there is currently an interaction with the page scroller.
  // // 'settling': there was an interaction with the page scroller, and the page scroller is now finishing its closing or
  // _onPageScrollStateChanged = (state: 'idle' | 'dragging' | 'settling') => {
  //   // console.log('_onPageScrollStateChanged new ScrollState => ', state)
  //   // console.log('_onPageScrollStateChanged this.galleryRef => ', this.galleryRef)
  //   const newState = {}
  //   if (this.galleryRef && this.galleryRef.currentPage !== this.state.selectedIndex) {
  //     newState.selectedIndex = this.galleryRef.currentPage
  //   }

  //   if (this.state.scrollState !== state || !_.isEmpty(newState)) {
  //     newState.scrollState = state
  //     this.setState(newState)
  //   }
  // }

  // _galleryImageComponent = (props, dimension) => {
  //   // log('imageComponent props => ', props)
  //   // log('imageComponent dimension => ', dimension)
  //   // if (dimension && dimension.width && dimension.width <= this.DEVICE_WIDTH) {
  //   //   const newProps = { ...props, ...dimension }
  //   //   return <FastImage resizeMode={FastImage.resizeMode.contain} {...newProps} />
  //   // }
  //   // let setProps = null
  //   // if (_.isObject(props) && _.isObject(dimension) && dimension.width && dimension.height && this.DEVICE_WIDTH > dimension.width) {
  //   //   setProps = _.cloneDeep(props)
  //   //   setProps.style.push(dimension)
  //   //   // setProps.style.width = dimension.width
  //   //   // setProps.style.height = dimension.height
  //   // } else {
  //   //   setProps = props
  //   // }
  //   // log('imageComponent setProps => ', setProps)

  //   // return (
  //   //   <FastImage
  //   //     resizeMode={FastImage.resizeMode.contain}
  //   //     //{...setProps}
  //   //     {...props} />
  //   // )

  //   return this._renderImageComponent(props)
  // }

  // _renderImageComponent = props => {
  //   const { source } = props
  //   let isImageURI = false
  //   const imgUri = source && _.isString(source.uri) ? source.uri : null
  //   if (imgUri && _.isString(imgUri) && imgUri.startsWith('http')) {
  //     isImageURI = true
  //   }
  //   return isImageURI ? (
  //     <FastImage
  //       resizeMode={FastImage.resizeMode.contain}
  //       // {...setProps}
  //       {...props}
  //     />
  //   ) : (
  //     <Image
  //       resizeMode={FastImage.resizeMode.contain}
  //       // {...setProps}
  //       {...props}
  //     />
  //   )

  //   // return (
  //   //   <Fragment>
  //   //     {
  //   //       isImageURI ?
  //   //         (
  //   //           <FastImage
  //   //             resizeMode={FastImage.resizeMode.contain}
  //   //             //{...setProps}
  //   //             {...props} />
  //   //         ) : (
  //   //           <Image
  //   //             resizeMode={FastImage.resizeMode.contain}
  //   //             //{...setProps}
  //   //             {...props} />
  //   //         )
  //   //     }
  //   //     {
  //   //       imgUri ? (
  //   //         <TouchableOpacity
  //   //           style={this.BTN_SAVE_STYLE}
  //   //           onPress={() => this._handleShareImage(imgUri)}>
  //   //           <Icon
  //   //             name={'md-cloud-download'}
  //   //             style={BTN_ICON_STYLE}
  //   //           />
  //   //         </TouchableOpacity>
  //   //       ) : null
  //   //     }
  //   //     {
  //   //       imgUri ? (
  //   //         <TouchableOpacity
  //   //           style={this.BTN_SHARE_STYLE}
  //   //           onPress={() => this._handleShareImage(imgUri)}>
  //   //           <Icon
  //   //             name={'md-share'}
  //   //             style={BTN_ICON_STYLE}
  //   //           />
  //   //         </TouchableOpacity>
  //   //       ) : null
  //   //     }
  //   //   </Fragment>
  //   // )
  // }

  _showSaveSuccessToast = async (): Promise<void> => {
    await setStatePromise(this, { isVisibleSavedToast: true })
    await new Promise((delaySetState) => setTimeout(delaySetState, 500))
  }

  _hideSaveSuccessToast = () => {
    this.setState({ isVisibleSavedToast: false })
  }

  _galleryGetItemLayout = (data, index) => ({
    // fixes scroll and pinch behavior
    length: Dimensions.get('screen').width,
    offset: Dimensions.get('screen').width * index,
    index,
  })

  renderImageItem = (item) => (
    <Image
      // resizeMode={'contain'}
      style={{
        flex: 1,
        width: '100%',
        height: '100%',
        resizeMode: 'contain',
        // minWidth: 300,
        // minHeight: 700,
        alignSelf: 'center',
        minWidth: this.context.contentWidth,
        minHeight: this.context.contentHeight,
      }}
      source={{ uri: item.original }}
    />
  )

  // _renderCustomControls = () => {
  //   return (
  //     <Text>I am custom controls</Text>
  //   )
  // }

  // onScreenChange = () => {
  //   let out: any = document.querySelectorAll('.image-gallery-image')
  //   if (out && out.length > 0) {
  //     console.log('onScreenChange out => ', out)
  //     for (let j = 0; j < out.length; j++) {
  //         out[j].style.height = window.innerHeight - 150 + 'px'
  //     }
  //     let outW = document.getElementsByClassName('image-gallery-image')[0].clientWidth
  //     let outH = document.getElementsByClassName('image-gallery-image')[0].clientHeight
  //     let imgOutSize: any = document.querySelectorAll('.image-gallery-slide img')
  //     for (let i = 0; i < imgOutSize.length; i++) {
  //         let w: any = imgOutSize[i].naturalWidth
  //         let h: any = imgOutSize[i].naturalHeight
  //         let outSize = outW / outH
  //         let size = w / h
  //         if (size > outSize) {
  //             if ((w < outW) && (h < outH)) {
  //                 imgOutSize[i].style.width = w + 'px'
  //                 imgOutSize[i].style.height = h + 'px'
  //             } else {
  //                 imgOutSize[i].style.width = outW + 'px'
  //                 imgOutSize[i].style.height = 'auto'
  //             }
  //         } else if (size == outSize) {
  //             if (w >= outW) {
  //                 imgOutSize[i].style.width = outW + 'px'
  //                 imgOutSize[i].style.height = outH + 'px'
  //             } else {
  //                 imgOutSize[i].style.width = w + 'px'
  //                 imgOutSize[i].style.height = h + 'px'
  //             }
  //         } else {
  //             if ((w < outW) && (h < outH)) {
  //                 imgOutSize[i].style.width = w + 'px'
  //                 imgOutSize[i].style.height = h + 'px'
  //             } else {
  //                 imgOutSize[i].style.height = outH + 'px'
  //                 imgOutSize[i].style.width = 'auto'
  //             }
  //         }
  //     }
  //   }
  // }

  render() {
    const { isVisible = false, images = null, onRequestClose = null, initialIndex = 0 } = this.props

    if (!isVisible || !_.isArray(images) || images.length < 1) {
      return null
    }

    const { selectedIndex, scrollState, isVisibleSavedToast } = this.state
    const visibleSideMenus = scrollState === 'idle' && isVisible && selectedIndex >= 0 && _.isArray(images) && !!images[selectedIndex]

    return (
      <XOverlay
        // animationType='slide'
        // animationType='fade'
        // transparent={true}
        // webNumColumns={3}
        visible={isVisible}
        contentStyle={{ maxWidth: '90%', width: '90%' }}
        // containerStyle={{ backgroundColor: 'transparent' }}
        // contentStyle={{ backgroundColor: 'transparent' }}
        // webWrapperContainerStyle={{
        //   // backgroundColor: 'transparent'
        //   borderRadius: 8,
        //   overflow: 'hidden',
        // }}
        onRequestClose={onRequestClose}>
        {/* <GalleryContainer> */}
        <ImageGallery
          startIndex={initialIndex}
          lazyLoad
          useBrowserFullscreen={false}
          showFullscreenButton={false}
          showPlayButton={false}
          showBullets={false}
          showIndex
          disableThumbnailScroll
          showThumbnails={false}
          items={this._convertImageArrayToImageSource()}
          renderItem={this.renderImageItem}
          preventDefaultTouchmoveEvent
          // renderCustomControls={this._renderCustomControls}
        />
        {/* </GalleryContainer> */}
        {/* {visibleSideMenus ? (
          <TouchableOpacity key={`SAVE${this.randomKey}${selectedIndex}`} style={this.BTN_SAVE_STYLE} onPress={this._handleSaveImage}>
            <Icon name={'md-cloud-download'} style={BTN_ICON_STYLE} />
          </TouchableOpacity>
        ) : null} */}
        {/* <TouchableOpacity style={this.BTN_CLOSE_STYLE} onPress={onRequestClose ? onRequestClose : null}>
          <Icon name={'ios-close-circle-outline'} style={BTN_ICON_STYLE} />
        </TouchableOpacity>
        {visibleSideMenus ? (
          <TouchableOpacity key={`SHARE${this.randomKey}${selectedIndex}`} style={this.BTN_SHARE_STYLE} onPress={this._handleShareImage}>
            <Icon name={'md-share'} style={BTN_ICON_STYLE} />
          </TouchableOpacity>
        ) : null} */}
        {/* <ToastContainer
          visible={isVisibleSavedToast}
          backgroundColor={COLORS.BRAND_Success}
          position={positions.BOTTOM}
          duration={durations.SHORT}
          shadow={true}
          animation={true}
          hideOnPress={true}
          onPress={this._hideSaveSuccessToast}
          onHidden={this._hideSaveSuccessToast}>
          {'บันทึกรูปภาพไปยังคลังรูปภาพสำเร็จ'}
        </ToastContainer> */}
        {/* {images.length > 1 ? <Text style={PAGE_NUMBER_STYLE}>{`${selectedIndex + 1}/${images.length}`}</Text> : null} */}
      </XOverlay>
    )
  }
}
