import React from 'react'
import BaseCustomerView from 'x/modules/customer/BaseCustomerView'
import { Text, View, TouchableOpacity, FlatList } from 'react-native'

import { COLORS, STYLES } from 'x/config/styles'
import _ from 'lodash'
import CONS from 'x/config/constants'
import p from 'x/config/platform-specific'
import XCustomHeader from 'xui/components/XCustomHeader'
import { ISelectedAddresses, IProfile, IAddress, IAnyObject } from 'x/index'
import XInput from 'xui/components/XInput'
import Icon from 'xui/components/Icon'
import * as util from 'x/utils/util'
import XIcon from 'xui/components/XIcon'
import { Card, ListItem } from 'react-native-elements'
import XProfile from 'xui/components/XProfile'
import XAddressCard from 'xui/components/XAddressCard'
import { VIEW_HEIGHT_TEN, VIEW_WIDTH_TEN } from 'xui/utils/BaseShareUI'
import { canDoAtSelectedStore } from 'x/utils/acl'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import api from 'x/utils/api'
import VStack from 'xui/components/VStack'
import XCard from 'xui/components/XCard'

const { VIEW, EDIT } = CONS.MODE
const { CUSTOMER_PICK, CUSTOMER_PICK_CHANGE, STORE_PICK, CUSTOMER_EDIT, STORE_EDIT, STORE_PICK_CHANGE } = CONS.ADDRESS_LIST_MODE_KEYS

export default abstract class BaseUICustomerView extends BaseCustomerView {
  static displayName = 'BaseUIPaymentListView'

  abstract goBack: () => void

  abstract renderViewImgManager(): JSX.Element

  abstract navToDealerView(): void

  abstract navToEditProfile(): void

  abstract renderContractImg(): JSX.Element

  abstract openModelChangeDealerLoading(): void

  abstract openModelChangeMemberType(): void

  abstract openFileFromUrl(url: string, fileName: string, type: string): Promise<void>

  renderCustomHeader = () => {
    // const { navigation } = this.props
    const { mode } = this.state
    const isEditMode = mode === EDIT
    let leftBtn = { backIcon: true, onPressItem: () => this.goBack() }
    if (isEditMode) {
      // @ts-ignore
      leftBtn = { label: `บันทึก`, onPressItem: () => util.setStatePromise(this, { mode: VIEW }) }
    }
    return (
      <XCustomHeader
        title='ข้อมูลลูกค้า'
        headerLeftProps={leftBtn}
        // headerRightProps={{ label: `แก้ไข`, onPressItem: () => util.setStatePromise(this, { mode: EDIT }) }}
      />
    )
  }
  // _navToEditProfile = () => {
  //   const { profile } = this.state
  //   this.props.navigation.push('CustomerEditProfileViewContainer', {
  //     isModal: true,
  //     profile: profile,
  //     callBackFromEdit: (profileCallBack: IProfile) => this._callBackFromEdit(profileCallBack),
  //   })
  // }

  _callBackFromEdit = (profileCallBack: IProfile) => {
    // console.log(`YES ! IM BACK FROM EDIT !!`, profileCallBack)
    util.setStatePromise(this, {
      profile: profileCallBack,
    })
    // this._setData()
    // this.goBack()
  }

  _resellerInfo = () => {
    const { selectedStore } = this.props
    const { resellerInfo } = this.state
    if (_.isNil(resellerInfo)) {
      return null
    }
    const isOwner = util.isOrgOwnerStore(selectedStore)
    const isDealerType = resellerInfo.reseller_type === 1
    const type = resellerInfo.reseller_type === 1 ? 'Dealer' : resellerInfo.reseller_type === 2 ? 'VIP' : 'Member'
    const isShowNote = !_.isNil(resellerInfo.note) && resellerInfo.note !== ''
    return (
      <View style={{ height: 90 }}>
        <View style={{ flexDirection: 'row', height: 20, maxHeight: 20 }}>
          <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE, width: 80 }}>{`รหัสตัวแทน: `}</Text>
          <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE }}>{resellerInfo.reseller_code}</Text>
        </View>
        <View style={{ flexDirection: 'row', height: 20, maxHeight: 20 }}>
          <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE, width: 80 }}>{`เข้ารวมเมื่อ: `}</Text>
          <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE }}>{resellerInfo.joined_at}</Text>
        </View>
        <View style={{ flexDirection: 'row', height: 20, maxHeight: 20 }}>
          <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE, width: 80 }}>{`ประเภทตัวแทน: `}</Text>
          <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE }}>{type}</Text>
          {isOwner && !isDealerType ? VIEW_WIDTH_TEN : null}
          {isOwner && !isDealerType ? (
            <TouchableOpacity
              onPress={() => util.setStatePromise(this, { isModelChangeMemberType: true })}
              style={{
                width: 30,
                height: 20,
                justifyContent: 'center',
                alignItems: 'center',
                borderWidth: 1,
                borderColor: COLORS.APP_MAIN,
                borderRadius: 7,

                backgroundColor: COLORS.BG_LIGHT_GREY,
                marginRight: 4,
              }}>
              <XIcon style={{ flex: 0, minWidth: 20, width: 20, fontSize: STYLES.FONT_ICON_SMALLEST }} name='edit' family='FontAwesome5' />
            </TouchableOpacity>
          ) : null}
        </View>
        {isShowNote ? (
          <View style={{ flexDirection: 'row', height: 20, maxHeight: 40 }}>
            <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE, width: 80 }}>{`หมายเหตุ: `}</Text>
            <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE }}>{resellerInfo.note}</Text>
          </View>
        ) : null}
      </View>
    )
  }

  _renderProfileView = () => {
    const { selectedStore } = this.props
    const { profile, mode, isEditable } = this.state
    return (
      <Card>
        <View style={{ height: 40, flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE, fontWeight: 'bold' }}>ข้อมูลทั่วไป</Text>
        </View>
        <View style={{ height: 670 }}>
          {this.renderViewImgManager()}
          {this._resellerInfo()}
          {_.isNil(profile) ? null : (
            <XProfile
              mode={mode}
              isShowEditProfileBtn={isEditable}
              onPressEditProfileBtn={() => this.navToEditProfile()}
              profile={profile}
              selectedStore={selectedStore}
            />
          )}
        </View>
      </Card>
    )
  }

  _renderDealerView = () => {
    const { selectedStore } = this.props
    const { referalReseller, newDealerCodeTxt, isShowInputNewDealerCode, resellerInfo, newResellertypeNumber } = this.state
    const isOrgOwnerStore = util.isOrgOwnerStore(selectedStore)
    // const type = util.getOrgResellerType(selectedStore)
    // if (isOrgOwnerStore || type === 1) {
    //   if (_.isNil(referalReseller)) {
    //     return null
    //   }
    // }
    if (_.isNil(resellerInfo)) {
      return null
    }
    // console.log(`resellerInfo `, resellerInfo)
    const isDealer = resellerInfo.reseller_type === 1
    const newType = newResellertypeNumber === 1 ? 'Dealer' : newResellertypeNumber === 2 ? 'VIP' : 'Member'
    // console.log(`referalReseller `, referalReseller)
    const btnMember = () => {
      // if (resellerInfo.reseller_type === 3) {
      //   return null
      // }
      const STYLE_BTN = { borderColor: COLORS.APP_MAIN, backgroundColor: COLORS.BG_LIGHT_GREY }
      const STYLE_TEXT = { color: COLORS.APP_MAIN }
      // if (newResellertypeNumber === 3) {
      //   STYLE_BTN = { borderColor: COLORS.APP_MAIN, backgroundColor: COLORS.APP_MAIN }
      //   STYLE_TEXT = { color: COLORS.WHITE }
      // }
      return (
        <TouchableOpacity
          onPress={() => {
            p.op.showConfirmation(
              ``,
              `ดำเนินการย้ายสาย และเปลี่ยนประเภทตัวแทนเป็น ${newType}`,
              async () => {
                await util.setStatePromise(this, { newResellertypeNumber: 3 })
                this._checkNewDealerCode()
              },
              () => {},
              'ตกลง',
              'ยกเลิก'
            )
          }}
          style={[
            {
              width: 120,
              height: 30,
              borderRadius: 7,
              // borderColor: COLORS.APP_MAIN,
              // backgroundColor: COLORS.BG_LIGHT_GREY,
              borderWidth: 1,
              justifyContent: 'center',
              alignItems: 'center',
            },
            STYLE_BTN,
          ]}>
          <Text style={[{ fontSize: STYLES.FONT_SIZE_NORMAL, fontWeight: 'bold' }, STYLE_TEXT]}>เปลี่ยนเป็น Member</Text>
        </TouchableOpacity>
      )
    }

    const btnVIP = () => {
      // if (resellerInfo.reseller_type === 1) {
      //   return null
      // }
      // if (resellerInfo.reseller_type === 2) {
      //   return null
      // }
      // if (resellerInfo.reseller_type === 3) {
      //   return null
      // }
      const STYLE_BTN = { borderColor: COLORS.APP_MAIN, backgroundColor: COLORS.BG_LIGHT_GREY }
      const STYLE_TEXT = { color: COLORS.APP_MAIN }
      // if (newResellertypeNumber === 2) {
      //   STYLE_BTN = { borderColor: COLORS.APP_MAIN, backgroundColor: COLORS.APP_MAIN }
      //   STYLE_TEXT = { color: COLORS.WHITE }
      // }
      return (
        <TouchableOpacity
          onPress={() => {
            p.op.showConfirmation(
              ``,
              `ดำเนินการย้ายสาย และเปลี่ยนประเภทตัวแทนเป็น ${newType}`,
              async () => {
                await util.setStatePromise(this, { newResellertypeNumber: 2 })
                this._checkNewDealerCode()
              },
              () => {},
              'ตกลง',
              'ยกเลิก'
            )
          }}
          style={[
            {
              width: 120,
              height: 30,
              borderRadius: 7,
              // borderColor: COLORS.APP_MAIN,
              // backgroundColor: COLORS.BG_LIGHT_GREY,
              borderWidth: 1,
              justifyContent: 'center',
              alignItems: 'center',
            },
            STYLE_BTN,
          ]}>
          <Text style={[{ fontSize: STYLES.FONT_SIZE_NORMAL, fontWeight: 'bold' }, STYLE_TEXT]}>เปลี่ยนเป็น VIP</Text>
        </TouchableOpacity>
      )
    }

    return (
      <Card>
        <View style={{ height: 40, flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE, fontWeight: 'bold' }}>ข้อมูลแม่ทีม</Text>
        </View>
        <TouchableOpacity
          onPress={() => {
            if (_.isNil(referalReseller)) {
              return
            }
            this.navToDealerView()
          }}
          style={{
            flex: 1,
            height: 40,
            borderRadius: 5,
            borderWidth: 0.8,
            borderColor: COLORS.APP_MAIN,
            justifyContent: 'center',
            paddingLeft: 10,
            flexDirection: 'row',
          }}>
          {_.isNil(referalReseller) ? (
            <View style={{ justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 10, flex: 1, flexDirection: 'row' }}>
              <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE }}>บริษัท ส้มใส จำกัด</Text>
            </View>
          ) : (
            <View style={{ justifyContent: 'flex-start', alignItems: 'center', paddingLeft: 10, flex: 1, flexDirection: 'row' }}>
              <View style={{ flex: 1, justifyContent: 'center' }}>
                <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE }}>
                  {`แม่ทีม: ${referalReseller.first_name} ${referalReseller.last_name}`}
                </Text>
                <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE }}>
                  {`รหัสแม่ทีม: ${referalReseller.reseller_code}`}
                </Text>
              </View>
              <View style={{ width: 30, justifyContent: 'center', alignItems: 'center' }}>
                <XIcon family='AntDesign' style={{ flex: 0, minWidth: 25 }} name='arrowright' />
              </View>
            </View>
          )}
        </TouchableOpacity>
        {VIEW_HEIGHT_TEN}
        <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
          <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE }}>{`เข้าร่วมเมื่อ: ${resellerInfo.joined_at}`}</Text>
          <View style={{ width: 10 }} />
          {isOrgOwnerStore && !isShowInputNewDealerCode ? (
            <TouchableOpacity
              onPress={() => util.setStatePromise(this, { isShowInputNewDealerCode: true })}
              style={{
                width: 70,
                height: 30,
                backgroundColor: COLORS.APP_MAIN,
                borderRadius: 7,
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.WHITE }}>เปลี่ยนแม่ทีม</Text>
            </TouchableOpacity>
          ) : null}
        </View>
        {isShowInputNewDealerCode ? (
          <View>
            <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
              <XInput
                style={{ marginRight: 4 }}
                value={newDealerCodeTxt}
                placeholder='กรุณาระบุรหัสแม่ใหม่'
                onChangeText={(text: string) => util.setStatePromise(this, { newDealerCodeTxt: text.trim() })}
              />
              {isDealer ? null : (
                <TouchableOpacity
                  onPress={() => this._checkNewDealerCode()}
                  style={{
                    width: 110,
                    height: 30,
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderWidth: 1,
                    borderColor: COLORS.APP_MAIN,
                    borderRadius: 7,
                    backgroundColor: COLORS.BG_LIGHT_GREY,
                    marginRight: 4,
                    flexDirection: 'row',
                  }}>
                  <XIcon
                    style={{ flex: 0, minWidth: 20, width: 20, fontSize: STYLES.FONT_ICON_SMALLEST }}
                    name='git-branch'
                    family='Ionicons'
                  />
                  <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE }}>ดำเนินการย้าย</Text>
                </TouchableOpacity>
              )}

              <TouchableOpacity
                onPress={() => util.setStatePromise(this, { isShowInputNewDealerCode: false })}
                style={{
                  width: 30,
                  height: 30,
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderWidth: 1,
                  borderColor: COLORS.APP_MAIN,
                  borderRadius: 7,
                  backgroundColor: COLORS.BG_LIGHT_GREY,
                }}>
                <XIcon
                  style={{ flex: 0, minWidth: 20, width: 20, fontSize: STYLES.FONT_ICON_SMALLEST }}
                  name='cancel'
                  family='MaterialIcons'
                />
              </TouchableOpacity>
            </View>
            <View>
              {isDealer ? (
                <View>
                  {VIEW_HEIGHT_TEN}
                  <View style={{ flex: 1, flexDirection: 'row' }}>
                    {VIEW_WIDTH_TEN}
                    {btnVIP()}
                    {VIEW_WIDTH_TEN}
                    {btnMember()}
                  </View>
                </View>
              ) : null}
              {VIEW_HEIGHT_TEN}
              <View>
                <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE, fontWeight: 'bold' }}>หมายเหตุ</Text>
                {/* <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE }}>{`ปุ่ม “ดำเนินการย้าย” เมื่อกดแล้ว ระบบจะทำการย้ายแม่ทีมให้ (อาจใช้เวลา 30 วินาทีต่อ 1 คนในสาย)`}</Text> */}
                <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.BRAND_Warning }}>
                  รหัสของตัวแทนที่ถูกย้าย และ รหัสลูกทีมของตัวแทนที่ถูกย้ายทั้งหมด จะถูกเปลี่ยนในส่วนที่เป็นรหัสของ dealer ใหม่ เช่น
                  SS01-00001234 หากย้ายไป dealer SS07 ก็จะเป็น SS07-00001234 ในกรณีที่ลูกทีมถูกปลื่ยนรหัส
                  ก็จะมีรายงานการเปลี่ยนรหัสของทุกคนด้วย
                </Text>
                {/* <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE }}>{`หมายเหตุ`}</Text> */}
              </View>
            </View>
          </View>
        ) : null}
      </Card>
    )
  }

  _renderAddressesView = () => {
    const { addresses, isEditable } = this.state
    if (_.isNil(addresses)) {
      return null
    }
    const isCanAddAddress = addresses.length < 2
    let btnText = '+ เพิ่มที่อยู่'
    if (!isCanAddAddress) {
      btnText = 'สร้างที่อยู่ได้สูงสุดแค่ 2 ที่อยู่เท่านั้น'
    }

    return (
      <XCard>
        <View style={{ height: 40, flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE, fontWeight: 'bold' }}>ที่อยู่จัดส่ง</Text>
        </View>
        <VStack w='full' space='1'>
          {addresses.map((address: ISelectedAddresses, index: number) => (
            <XAddressCard
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              // @ts-ignore
              address={address}
              onPressEdit={() => this._navToAddress(address)}
              onPressDelete={() => this._showConfirmDeleteAddress(address)}
              isEditable={isEditable}
              isDeletable={isEditable}
            />
          ))}
        </VStack>
        {isEditable ? (
          <TouchableOpacity
            disabled={!isCanAddAddress}
            onPress={() => this._navToCreateAddress()}
            style={{
              flex: 1,
              height: 30,
              borderRadius: 5,
              borderWidth: 0.8,
              borderColor: !isCanAddAddress ? COLORS.BG_LIGHT_GREY : COLORS.APP_MAIN,
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: !isCanAddAddress ? COLORS.BG_LIGHT_GREY : COLORS.APP_MAIN,
            }}>
            <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: !isCanAddAddress ? COLORS.TEXT_INACTIVE : COLORS.WHITE }}>
              {btnText}
            </Text>
          </TouchableOpacity>
        ) : null}
      </XCard>
    )
  }

  _handleSubmitForm = async (type: string, addr: IAddress): Promise<boolean> => {
    const { profile } = this.state
    const store_id = util.getNavParam(this.props, 'store_id')
    // const profile_id = util.getNavParam(this.props, 'profile_id')
    const body: IAnyObject = { ...addr }
    delete body.id

    if (!_.isEmpty(body)) {
      const address_id = addr.id ? addr.id : null
      // console.log('address => // ', address)
      // console.log('address_id => ', address_id)
      if (address_id) {
        body.address_id = address_id
      } else if (type === 'PROFILE') {
        // body.addressable_id = profile_id
        body.addressable_id = profile.id
      } else if (type === 'STORE') {
        body.addressable_id = store_id
      }

      if (type !== 'CUSTOMER') {
        body.type = type
      }

      try {
        let isSuccess = false
        if (type !== 'CUSTOMER') {
          isSuccess = await this._doSaveNewAddress(body)
        } else {
          isSuccess = await this._dosaveCustomer(body)
        }
        return isSuccess
      } catch (err) {
        console.log('_handleSubmitForm err => ', err)
      }
    } else {
      p.op.showToast('ยังไม่มีการแก้ไขข้อมูลเกิดขึ้น', 'warning')
    }

    return false
  }

  _doSaveNewAddress = async (body): Promise<boolean> => {
    const apiOptions = {
      messages: {
        successMsg: 'สร้างที่อยู่สำเร็จ',
        // errorMsg: 'สร้างที่อยู่ล้มเหลว',
      },
      showSpinner: true,
    }
    const API_TO_GO = body.address_id ? api.patchV2 : api.putV2

    const reqBody = { ...body }

    // API นี้หลังบ้านต้องการ zipcode เป็น int ไม่งั้น error
    if (reqBody.postal_code && reqBody.postal_code.length === 5) {
      reqBody.postal_code = parseInt(reqBody.postal_code)
    }

    const res: { addresses: IAddress[] } = await API_TO_GO(api.PUT_ADDRESS, reqBody, apiOptions)

    if (res.addresses) {
      // @ts-ignore
      this._callbackResponseFromAddress(res.addresses)
      return true
    }
  }

  _doSaveEditingAddress = async (body): Promise<boolean> => {
    const { navigation, saveEditing, editOrgAddress, selectedStore } = this.props
    // @ts-ignore
    // const { isStorePickMode, callbackResponse } = navigation.state && navigation.state.params ? navigation.state.params : false
    const isStorePickMode = util.getNavParam(this.props, 'isStorePickMode')
    const callbackResponse = util.getNavParam(this.props, 'callbackResponse')
    const newBody = body
    const orgz = selectedStore.get(`org`)
    if (!_.isNil(orgz) && orgz.size > 0) {
      const orgzToJS = orgz.toJS()
      newBody.org_id = orgzToJS[0].id
      delete newBody.type
      const response: any = await new Promise((resolveSave) => {
        editOrgAddress({
          body: newBody,
          isStorePickMode,
          successCallback: resolveSave,
          failedCallback: () => resolveSave(null),
        })
      })
      if (response && response.addresses) {
        // this._succeedCallback(response.addresses)
        // @ts-ignore
        this._callbackResponseFromAddress(response.addresses)

        return true
      }
    } else {
      const response: any = await new Promise((resolveSave) => {
        saveEditing({
          body,
          isStorePickMode,
          successCallback: resolveSave,
          failedCallback: () => resolveSave(null),
        })
      })
      if (response) {
        // this._succeedCallback(response.addresses)
        // @ts-ignore
        this._callbackResponseFromAddress(response.addresses)
        return true
      }
    }

    return false
  }

  _dosaveCustomer = async (body): Promise<boolean> => {
    // console.log('_dosaveCustomer => ')
    const { addCustomer, navigation } = this.props
    const data = {
      first_name: body.name,
      last_name: '',
      phone1: body.telephone,
      addresses: [body],
    }
    if (body.address2) {
      // @ts-ignore
      data.address2 = body.address2
    }

    try {
      const isSuccess = await new Promise<boolean>((resolveEdit) => {
        addCustomer({
          body: data,
          successCallback: () => resolveEdit(true),
          failedCallback: () => resolveEdit(false),
        })
      })

      return isSuccess
    } catch (error) {
      return false
    }
  }

  _navToCreateAddress = () => {
    const { profile, isEditable } = this.state
    if (!isEditable) {
      return
    }
    const mode = STORE_PICK
    let editableMode = null
    const { CUSTOMER_ADD, STORE_ADD } = CONS.ADDRESS_MODE_KEYS
    if (_.has(CONS.ADDRESS_LIST_MODE_KEYS, mode)) {
      if (_.includes([CUSTOMER_EDIT, CUSTOMER_PICK, CUSTOMER_PICK_CHANGE], mode)) {
        editableMode = CUSTOMER_ADD
      } else if (_.includes([STORE_EDIT, STORE_PICK, STORE_PICK_CHANGE], mode)) {
        editableMode = STORE_ADD
      } else {
        editableMode = null
      }
    }

    const initAddr = {
      addressable_id: profile.id,
      name: `${profile.first_name} ${profile.last_name}`,
    }

    this.props.navigation.push('AddressView', {
      isModal: true,
      mode: editableMode,
      address: initAddr,
      profile,
      isStorePickMode: _.includes([STORE_PICK_CHANGE, STORE_PICK], mode),
      callbackResponse: (adddresses: ISelectedAddresses[]) => this._callbackResponseFromAddress(adddresses),
    })

    // this.props.navigation.dispatch(
    //   NavActions.navToAddressFormView({
    //     // @ts-ignore
    //     initAddress: initAddr,
    //     type: 'customer_address',
    //     onSubmit: async (addr: IAddress) => {
    //       const isSuccess = await this._handleSubmitForm('PROFILE', addr)
    //       return { isGoBack: isSuccess }
    //     },
    //   })
    // )
  }

  _navToAddress = (address: ISelectedAddresses) => {
    const mode = STORE_PICK
    let addressViewMode
    if (_.includes([STORE_EDIT, STORE_PICK, STORE_PICK_CHANGE], mode)) {
      addressViewMode = STORE_EDIT
    } else if (_.includes([CUSTOMER_EDIT, CUSTOMER_PICK, CUSTOMER_PICK_CHANGE], mode)) {
      addressViewMode = CUSTOMER_EDIT
    } else {
      throw new Error('Invalid address list mode')
    }

    this.props.navigation.push('AddressView', {
      isModal: true,
      address,
      mode: addressViewMode,
      isStorePickMode: _.includes([STORE_PICK], mode),
      callbackResponse: (adddresses: ISelectedAddresses[]) => this._callbackResponseFromAddress(adddresses),
    })

    // this.props.navigation.dispatch(
    //   NavActions.navToAddressFormView({
    //     // @ts-ignore
    //     initAddress: address,
    //     type: 'customer_address',
    //     onSubmit: async (addr: IAddress) => {
    //       const isSuccess = await this._handleSubmitForm('PROFILE', addr)
    //       return { isGoBack: isSuccess }
    //     },
    //   })
    // )
  }

  _renderLoginEmail = () => {
    const { loginEmail, isEditLoginEmail, isEditable } = this.state
    if (_.isNil(loginEmail)) {
      return null
    }
    return (
      <Card>
        <View style={{ height: 40, flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: -4 }}>
          <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.BLACK, fontWeight: 'bold' }}>Login Email</Text>
        </View>
        {/* {!p.op.isWeb() ? <View style={{ height: 40 }} /> : null} */}
        {!isEditLoginEmail ? (
          <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', height: 40 }}>
            <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE, flex: 1 }}>{loginEmail}</Text>
            {isEditable ? (
              <TouchableOpacity
                onPress={() => {
                  if (isEditable) {
                    util.setStatePromise(this, { isEditLoginEmail: true })
                  }
                }}
                style={{
                  width: 30,
                  height: 30,
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderWidth: 1,
                  borderColor: COLORS.APP_MAIN,
                  borderRadius: 7,
                  backgroundColor: COLORS.BG_LIGHT_GREY,
                }}>
                <XIcon
                  style={{ flex: 0, minWidth: 20, width: 20, fontSize: STYLES.FONT_ICON_SMALLEST }}
                  name='edit'
                  family='FontAwesome5'
                />
              </TouchableOpacity>
            ) : (
              <View style={{ width: 30 }} />
            )}
          </View>
        ) : (
          <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', height: 40 }}>
            <XInput
              style={{ marginRight: 4 }}
              value={loginEmail}
              placeholder='กรุณาระบุ Email'
              onChangeText={(text: string) => util.setStatePromise(this, { loginEmail: text })}
            />
            <TouchableOpacity
              onPress={() => this._updateLoginEmail()}
              style={{
                width: 30,
                height: 30,
                justifyContent: 'center',
                alignItems: 'center',
                borderWidth: 1,
                borderColor: COLORS.APP_MAIN,
                borderRadius: 7,
                backgroundColor: COLORS.BG_LIGHT_GREY,
                marginRight: 4,
              }}>
              <XIcon style={{ flex: 0, minWidth: 20, width: 20, fontSize: STYLES.FONT_ICON_SMALLEST }} name='save' family='FontAwesome5' />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => util.setStatePromise(this, { isEditLoginEmail: false, loginEmail: this.txtOldLoginEmail })}
              style={{
                width: 30,
                height: 30,
                justifyContent: 'center',
                alignItems: 'center',
                borderWidth: 1,
                borderColor: COLORS.APP_MAIN,
                borderRadius: 7,
                backgroundColor: COLORS.BG_LIGHT_GREY,
              }}>
              <XIcon
                style={{ flex: 0, minWidth: 20, width: 20, fontSize: STYLES.FONT_ICON_SMALLEST }}
                name='cancel'
                family='MaterialIcons'
              />
            </TouchableOpacity>
          </View>
        )}
      </Card>
    )
  }

  _renderNoContractImg = () => (
    <View style={{ justifyContent: 'center', alignItems: 'center' }}>
      <Text
        style={{
          fontSize: STYLES.FONT_SIZE_NORMAL,
          color: COLORS.TEXT_INACTIVE,
          fontWeight: 'bold',
        }}>
        ไม่ได้ระบุรูปเอกสารการสมัคร
      </Text>
    </View>
  )

  _renderContractImg = () => {
    const { isEditable, isHasImgDocument } = this.state
    return (
      <Card>
        <View style={{ height: 40, flex: 1, justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
          <View style={{ flex: 1, paddingLeft: 30, justifyContent: 'center', alignItems: 'center' }}>
            <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE, fontWeight: 'bold' }}>เอกสารการสมัคร</Text>
          </View>
          <View style={{ width: 30 }}>
            {/* <TouchableOpacity
                onPress={() => {
                  if (isEditable) {
                    util.setStatePromise(this, { isEditModeImgDocument: true })
                  }
                }}
                style={{
                  width: 30,
                  height: 30,
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderWidth: 1,
                  borderColor: COLORS.APP_MAIN,
                  borderRadius: 7,
                  backgroundColor: COLORS.BG_LIGHT_GREY,
                }}>
                <XIcon
                  style={{ flex: 0, minWidth: 20, width: 20, fontSize: STYLES.FONT_ICON_SMALLEST }}
                  name={`edit`}
                  family={`FontAwesome5`}
                />
              </TouchableOpacity> */}
          </View>
        </View>
        {this.renderContractImg()}
        {isHasImgDocument ? null : this._renderNoContractImg()}
      </Card>
    )
  }

  _renderBodyModelChangeMemberType = () => {
    const { resellerInfo, profile, newResellertypeNumber } = this.state
    if (_.isNil(resellerInfo) || _.isNil(profile)) {
      return null
    }
    const type = resellerInfo.reseller_type === 1 ? 'Dealer' : resellerInfo.reseller_type === 2 ? 'VIP' : 'Member'
    const newType = newResellertypeNumber === 1 ? 'Dealer' : newResellertypeNumber === 2 ? 'VIP' : 'Member'
    // console.log(`openModelChangeDealerLoading `, this.state.isChangeDealerLoading)

    const btnMember = () => {
      if (resellerInfo.reseller_type === 3) {
        return null
      }
      let STYLE_BTN = { borderColor: COLORS.APP_MAIN, backgroundColor: COLORS.BG_LIGHT_GREY }
      let STYLE_TEXT = { color: COLORS.APP_MAIN }
      if (newResellertypeNumber === 3) {
        STYLE_BTN = { borderColor: COLORS.APP_MAIN, backgroundColor: COLORS.APP_MAIN }
        STYLE_TEXT = { color: COLORS.WHITE }
      }
      return (
        <TouchableOpacity
          onPress={() => this.setState({ newResellertypeNumber: 3 })}
          style={[
            {
              width: 60,
              height: 30,
              borderRadius: 7,
              // borderColor: COLORS.APP_MAIN,
              // backgroundColor: COLORS.BG_LIGHT_GREY,
              borderWidth: 1,
              justifyContent: 'center',
              alignItems: 'center',
            },
            STYLE_BTN,
          ]}>
          <Text style={[{ fontSize: STYLES.FONT_SIZE_NORMAL, fontWeight: 'bold' }, STYLE_TEXT]}>Member</Text>
        </TouchableOpacity>
      )
    }

    const btnDealer = () => {
      if (resellerInfo.reseller_type !== 2) {
        return null
      }
      let STYLE_BTN = { borderColor: COLORS.APP_MAIN, backgroundColor: COLORS.BG_LIGHT_GREY }
      let STYLE_TEXT = { color: COLORS.APP_MAIN }
      if (newResellertypeNumber === 1) {
        STYLE_BTN = { borderColor: COLORS.APP_MAIN, backgroundColor: COLORS.APP_MAIN }
        STYLE_TEXT = { color: COLORS.WHITE }
      }
      return (
        <TouchableOpacity
          onPress={() => this.setState({ newResellertypeNumber: 1 })}
          style={[
            {
              width: 60,
              height: 30,
              borderRadius: 7,
              // borderColor: COLORS.APP_MAIN,
              // backgroundColor: COLORS.BG_LIGHT_GREY,
              borderWidth: 1,
              justifyContent: 'center',
              alignItems: 'center',
            },
            STYLE_BTN,
          ]}>
          <Text style={[{ fontSize: STYLES.FONT_SIZE_NORMAL, fontWeight: 'bold' }, STYLE_TEXT]}>Dealer</Text>
        </TouchableOpacity>
      )
    }

    const btnVIP = () => {
      if (resellerInfo.reseller_type === 1) {
        return null
      }
      if (resellerInfo.reseller_type === 2) {
        return null
      }
      if (resellerInfo.reseller_type === 3) {
        return null
      }
      let STYLE_BTN = { borderColor: COLORS.APP_MAIN, backgroundColor: COLORS.BG_LIGHT_GREY }
      let STYLE_TEXT = { color: COLORS.APP_MAIN }
      if (newResellertypeNumber === 2) {
        STYLE_BTN = { borderColor: COLORS.APP_MAIN, backgroundColor: COLORS.APP_MAIN }
        STYLE_TEXT = { color: COLORS.WHITE }
      }
      return (
        <TouchableOpacity
          onPress={() => this.setState({ newResellertypeNumber: 2 })}
          style={[
            {
              width: 60,
              height: 30,
              borderRadius: 7,
              // borderColor: COLORS.APP_MAIN,
              // backgroundColor: COLORS.BG_LIGHT_GREY,
              borderWidth: 1,
              justifyContent: 'center',
              alignItems: 'center',
            },
            STYLE_BTN,
          ]}>
          <Text style={[{ fontSize: STYLES.FONT_SIZE_NORMAL, fontWeight: 'bold' }, STYLE_TEXT]}>VIP</Text>
        </TouchableOpacity>
      )
    }
    return (
      <View style={{ width: '100%', height: 200 }}>
        <View style={{ flex: 1, height: 170 }}>
          <Text style={{ fontSize: STYLES.FONT_SIZE_LARGER_2, color: COLORS.TEXT_ACTIVE, fontWeight: 'bold' }}>เปลี่ยนประเภทตัวแทน</Text>
          {VIEW_HEIGHT_TEN}
          <View>
            <View style={{ flex: 1, flexDirection: 'row' }}>
              <Text style={{ fontSize: STYLES.FONT_SIZE_LARGER, color: COLORS.TEXT_INACTIVE }}>{`ตัวแทน: `}</Text>
              <Text style={{ fontSize: STYLES.FONT_SIZE_LARGER, color: COLORS.TEXT_ACTIVE }}>
                {`${profile.first_name} ${profile.last_name}`}
              </Text>
            </View>

            <View style={{ flex: 1, flexDirection: 'row' }}>
              <Text style={{ fontSize: STYLES.FONT_SIZE_LARGER, color: COLORS.TEXT_INACTIVE }}>{`ประเภทตัวแทนปัจจุบัน: `}</Text>
              <Text style={{ fontSize: STYLES.FONT_SIZE_LARGER, color: COLORS.TEXT_ACTIVE }}>{`${type}`}</Text>
            </View>
          </View>
          {VIEW_HEIGHT_TEN}
          <View style={{ flex: 1, flexDirection: 'row' }}>
            <Text style={{ fontSize: STYLES.FONT_SIZE_LARGER_2, color: COLORS.TEXT_INACTIVE }}>{`เปลี่ยนประเภทตัวแทนเป็น: `}</Text>
            <Text style={{ fontSize: STYLES.FONT_SIZE_LARGER_2, color: COLORS.APP_MAIN }}>{newType}</Text>
          </View>

          {VIEW_HEIGHT_TEN}
          {resellerInfo.reseller_type === 3 ? (
            <View style={{ flex: 1, flexDirection: 'row' }}>
              <Text style={{ fontSize: STYLES.FONT_SIZE_LARGER, color: COLORS.TEXT_INACTIVE }}>
                หมายเหตุ: ก่อนเปลี่ยนประเภทตัวแทนเป็น VIP ตัวแทนท่านนี้ต้องเป็นลูกทีมชั้นต่อตรงจาก Dealer ก่อนเท่านั้น หากยังไม่เป็น
                กรุณาเปลี่ยนแม่ทีมให้เป็น Dealer ก่อน
              </Text>
            </View>
          ) : (
            <View style={{ flex: 1, flexDirection: 'row' }}>
              {VIEW_WIDTH_TEN}
              {btnMember()}
              {VIEW_WIDTH_TEN}
              {btnVIP()}
              {VIEW_WIDTH_TEN}
              {btnDealer()}
            </View>
          )}
        </View>

        <View style={{ flex: 1, flexDirection: 'row' }}>
          <TouchableOpacity
            onPress={() => this._changeResellerType()}
            style={{
              flex: 1,
              height: 30,
              backgroundColor: COLORS.APP_MAIN,
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 7,
            }}>
            <Text style={{ fontSize: STYLES.FONT_SIZE_LARGER, color: COLORS.WHITE }}>{`เปลี่ยนประเภทตัวแทนเป็น ${newType} `}</Text>
          </TouchableOpacity>
          {VIEW_WIDTH_TEN}
          <TouchableOpacity
            onPress={() => this.setState({ isModelChangeMemberType: false })}
            style={{
              width: 60,
              height: 30,
              backgroundColor: COLORS.BG_LIGHT_GREY,
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 7,
              borderColor: COLORS.APP_MAIN,
              borderWidth: 1,
            }}>
            <Text style={{ fontSize: STYLES.FONT_SIZE_LARGER, color: COLORS.APP_MAIN }}>ยกเลิก</Text>
          </TouchableOpacity>
        </View>
      </View>
    )
  }

  _renderReportItems = ({ index, item }) => {
    const { label, iconName = null, iconFamily = null, nav = null, perm, isHeader = false, iconStyle = null, permBitIndex = null } = item
    const addIconStyle = _.isNil(iconStyle) ? {} : iconStyle
    const iconLeft = (
      <XIcon
        name={iconName}
        family={iconFamily}
        style={{ flex: 0, fontSize: 15, width: 30, minWidth: 30, color: COLORS.TEXT_INACTIVE, ...addIconStyle }}
      />
    )
    const IconRight = nav ? <Icon name='arrow-forward' style={{ fontSize: STYLES.FONT_ICON_NORMAL, color: COLORS.APP_MAIN }} /> : null
    if (!canDoAtSelectedStore(perm, permBitIndex)) {
      return null
    }
    return (
      <ListItem
        key={index}
        // containerStyle={{ backgroundColor: COLORS.WHITE, height: 35, borderBottomWidth: 1, borderBottomColor: COLORS.TEXT_INACTIVE }}
        // title={label}
        // titleStyle={{ fontSize: STYLES.FONT_SIZE_NORMAL, paddingLeft: 10 }}
        // leftIcon={iconLeft}
        // rightIcon={IconRight}
        // bottomDivider
        onPress={() => this._onPressToItem(item)}>
        {iconLeft}
        <ListItem.Content
          style={{ backgroundColor: COLORS.WHITE, height: 35, borderBottomWidth: 1, borderBottomColor: COLORS.TEXT_INACTIVE }}>
          <ListItem.Title style={{ fontSize: STYLES.FONT_SIZE_NORMAL, paddingLeft: 10 }}>{label}</ListItem.Title>
        </ListItem.Content>
        {IconRight}
      </ListItem>
    )
  }

  _renderReportCardView = () => {
    const { selectedStore } = this.props
    const isOrgOwner = util.isOrgOwnerStore(selectedStore)
    if (!isOrgOwner || _.isNil(this.menuItems)) {
      return null
    }
    return (
      <Card>
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE, fontWeight: 'bold' }}>รายงาน</Text>
        </View>
        {VIEW_HEIGHT_TEN}
        <FlatList data={this.menuItems} renderItem={this._renderReportItems} />
      </Card>
    )
  }

  async _handleOnDownloadFile(url: string, fileName: string): Promise<void> {
    await this.openFileFromUrl(url, fileName, 'xlsx')
  }

  _renderMain = () => (
    <XContainer>
      {this.renderCustomHeader()}
      {this.openModelChangeDealerLoading()}
      {this.openModelChangeMemberType()}
      <XContent style={{ backgroundColor: COLORS.WHITE }}>
        {this._renderDealerView()}
        {this._renderProfileView()}
        {this._renderLoginEmail()}
        {this._renderAddressesView()}
        {this._renderContractImg()}
        {/* {this._renderReportCardView()} */}
        {VIEW_HEIGHT_TEN}
      </XContent>
    </XContainer>
  )

  render() {
    return this._renderMain()
  }
}
