import { Component } from 'react'
import _ from 'lodash'

import dayjs, { Dayjs } from 'dayjs'

import { IBaseDateRangePickerProps, IBaseDateRangePickerState, IDateRange, IDateLimitRange } from 'x/index'
import CON from 'x/config/constants'
import { setStatePromise } from '../utils/util'

type DayjsInput = Dayjs | Date | string | number | (number | string)[] | void // null | undefined

// interface DateRange {
//   begin: Moment
//   end: Moment
// }

// interface LabelRange {
//   begin: string
//   end: string
// }

// interface LimitRange {
//   max: Moment | null
//   min: Moment | null
// }

// interface DateLimitRange {
//   begin: LimitRange
//   end: LimitRange
// }

// export interface IBaseDateRangePickerProps {
//   // Constants
//   selectedDates: DateRange
//   labels?: LabelRange
//   allowOnlyDaysFromToday?: number | null
//   allowDateRangeLimitDays?: number | null
//   minDate?: Moment | null // default = null (everyday before today)
//   maxDate?: Moment | null // default = today
//   showTime?: boolean
//   disabled?: boolean
//   isVisibleEndDate?: boolean
//   oneLineDateTime?: boolean
//   // Event
//   onChangeDate: (newDates: DateRange) => void

//   //Test for web only now
//   showTimeSelect?: boolean
// }

// export interface IBaseDateRangePickerState {
//   dates: DateRange
//   txtLabels: LabelRange
//   limit: DateLimitRange

//   // app only use
//   isShowDatePicker: {
//     begin: boolean
//     end: boolean
//   }
//   isShowTimePicker: {
//     begin: boolean
//     end: boolean
//   }
//   datePickerOnPick: {
//     begin: Date
//     end: Date
//   }
//   timePickerOnPick: {
//     begin: Date
//     end: Date
//   }
//   dateTimeKey: 'begin' | 'end'
// }

export default abstract class BaseDateRangePicker extends Component<IBaseDateRangePickerProps, IBaseDateRangePickerState> {
  static displayName = 'BaseDateRangePicker'

  static defaultProps = {
    // labels: null,
    // selectedDates: null,
    showTime: false,
    showTimeSelect: false,
    // allowOnlyDaysFromToday: null,
    // minDate: null,
    // maxDate: null,
    selectedDates: { begin: dayjs().startOf('d'), end: dayjs().endOf('d') },
  }

  INPUT_KEYS: string[]

  DATETIME_FORMAT: string

  DATE_FORMAT: string

  CURRENT_DATE: Dayjs

  MIN_DATE: Dayjs

  MAX_DATE: Dayjs

  datePickerRefs?: any // Ref for app datepicker component

  // Native/Web Resize Image function
  // abstract async _getResizedImage(image: ImgFile, maxWidth: number, maxHeight: number): Promise<ImgFile|null>;
  // abstract async _getBase64fromImageURL(imgURL: string): Promise<string>;

  constructor(props: IBaseDateRangePickerProps) {
    super(props)
    this.INPUT_KEYS = ['begin', 'end']
    // this.DATETIME_FORMAT = 'DD-MM-YYYY     HH:mm น.'
    // this.DATE_FORMAT = 'DD-MM-YYYY'
    // this.DATETIME_FORMAT = 'lll'
    // this.DATE_FORMAT = 'll'
    this.DATETIME_FORMAT = 'DD MMM YYYY [  ] HH:mm[น.]'
    this.DATE_FORMAT = 'DD MMM YYYY'
    // this.CURRENT_DATE = moment()
    this.CURRENT_DATE = dayjs()
    this.datePickerRefs = {}

    // this.MIN_DATE = this.CURRENT_DATE.clone().subtract(20, 'y').startOf('d')
    // this.MAX_DATE = this.CURRENT_DATE.clone().add(20, 'y').endOf('d')
    this.MIN_DATE = this.CURRENT_DATE.clone().subtract(20, 'y').startOf('d')
    this.MAX_DATE = this.CURRENT_DATE.clone().add(20, 'y').endOf('d')

    this.state = {
      // dates: props.selectedDates ? props.selectedDates : { begin: this.CURRENT_DATE, end: this.CURRENT_DATE },
      txtLabels: props.labels ? props.labels : { begin: 'จาก', end: 'จนถึง' },
      limit: {
        begin: { min: this.MIN_DATE, max: this.CURRENT_DATE },
        end: { min: this.CURRENT_DATE, max: this.MAX_DATE },
      },
      isShowDatePicker: {
        begin: false,
        end: false,
      },
      isShowTimePicker: {
        begin: false,
        end: false,
      },
      datePickerOnPick: {
        begin: new Date(),
        end: new Date(),
      },
      timePickerOnPick: {
        begin: new Date(),
        end: new Date(),
      },
      dateTimeKey: 'begin',
    }

    // this.getDateRange = this.getDateRange.bind(this)
  }

  async componentDidMount() {
    await this._updateLimitedDateRange()
  }

  // API can call from ref
  // getDateRange = () => this.state.dates
  getDateRange = () => this.props.selectedDates

  // Internal function
  async _onDateChange(key: 'begin' | 'end', newDate: Dayjs): Promise<void> {
    // console.log('_onDateChange key=' + key + ' newDate => ', newDate)
    const { onChangeDate, allowDateRangeLimitDays, beginDateLength = null, selectedDates: dates } = this.props
    // const { dates, limit } = this.state
    const { limit } = this.state

    const newDates: IDateRange = _.cloneDeep(dates)
    newDates[key] = newDate
    // log('_onDateChange newDates.end.diff(newDates.begin) => ', newDates.end.diff(newDates.begin))

    // console.log('_onDateChange newDates.end.diff(newDates.begin) => ', newDates.end.diff(newDates.begin, 'd'))
    // console.log('_onDateChange beginDateLength => ', beginDateLength)
    // console.log('_onDateChange allowDateRangeLimitDays => ', allowDateRangeLimitDays)

    // console.log('1 newDates.begin => ', newDates.begin.format(CON.SERVER_DATETIME_FORMAT))
    // console.log('1 newDates.end => ', newDates.end.format(CON.SERVER_DATETIME_FORMAT))

    if (limit && limit.begin && limit.begin.min && newDates.begin.isBefore(limit.begin.min, 'd')) {
      newDates.begin = limit.begin.min
    }

    if (limit && limit.begin && limit.begin.max && newDates.begin.isAfter(limit.begin.max, 'd')) {
      newDates.begin = limit.begin.max
    }

    if (limit && limit.end && limit.end.min && newDates.end.isBefore(limit.end.min, 'd')) {
      newDates.end = limit.end.min
    }

    if (limit && limit.end && limit.end.max && newDates.end.isAfter(limit.end.max, 'd')) {
      newDates.end = limit.end.max
    }
    // console.log('2 newDates.begin => ', newDates.begin.format(CON.SERVER_DATETIME_FORMAT))
    // console.log('2 newDates.end => ', newDates.end.format(CON.SERVER_DATETIME_FORMAT))

    // compute allowDateRangeLimitDays
    const adrld = allowDateRangeLimitDays ? allowDateRangeLimitDays - 1 : 0
    // console.log('adrld => ', adrld)
    const dateDiff = Math.abs(newDates.end.diff(newDates.begin, 'd'))
    // console.log('dateDiff => ', dateDiff)

    if (allowDateRangeLimitDays && adrld > 0 && dateDiff > adrld) {
      if (key === 'begin') {
        const newEnd = newDates.begin.clone().add(adrld, 'd')
        // newDates.end = newDates.begin.clone().add(allowDateRangeLimitDays, 'd')
        const YYYYMMDDD = newEnd.format(CON.SERVER_DATE_FORMAT)
        newDates.end = dayjs(`${YYYYMMDDD} 23:59:59`)
      }

      if (key === 'end') {
        const newBegin = newDates.end.clone().subtract(adrld, 'd')
        const YYYYMMDDD = newBegin.format(CON.SERVER_DATE_FORMAT)
        newDates.begin = dayjs(`${YYYYMMDDD} 00:00:00`)
        // newDates.begin = newDates.end.clone().subtract(allowDateRangeLimitDays, 'd')
      }

      console.log('allowDateRangeLimitDays newDates.begin => ', newDates.begin)
      console.log('allowDateRangeLimitDays newDates.end => ', newDates.end)
    }

    // เช็ดแค่ web ไปก่อน ตอนนี้ app เหมือนใช้ function นี้ไม่ได้ //ARTID
    // ตอนนี้เอาออกเพราะเปลี่ยนเป็น dayjs แล้วใช้ฟังก์ชั่นนี้ได้แล้ว //O
    // if (p.op.isWeb()) {
    // เช็ค ถ้ามีการเลือกวันที่สิ้นสุด กับเริ่มต้นไม่สัมพันธ์กัน ปรับให้เป็นวันเดียวกัน
    if (newDates.begin.isAfter(newDates.end, 'ms')) {
      if (key === 'begin') {
        newDates.end = newDates.begin
      } else {
        newDates.begin = newDates.end
      }
      console.log('newDates.begin.isAfter(newDates.end, "ms") newDates.begin => ', newDates.begin)
      console.log('newDates.begin.isAfter(newDates.end, "ms") newDates.end => ', newDates.end)
    }
    // }

    if (key === 'begin' && !_.isNil(beginDateLength)) {
      const diff = newDates.begin.diff(newDates.end)
      if (diff > 0) {
        const YYYYMMDDD = newDates.begin.format(CON.SERVER_DATE_FORMAT)
        newDates.end = dayjs(`${YYYYMMDDD} 23:59:59`)
      }
    }

    // console.log('_onDateChange (computed) newDates => ', newDates)

    if (_.isFunction(onChangeDate)) {
      onChangeDate(newDates)
    }

    // await setStatePromise(this, { dates: newDates })
    // log('_onDateChange dates => ', { dates: newDates })
    await this._updateLimitedDateRange(newDates)
  }

  // {
  //   begin: { min: Moment|null, max: Moment },
  //   end: { min: Moment, max: Moment|null },
  // }
  async _updateLimitedDateRange(currentDates?: IDateRange): Promise<void> {
    const { minDate, maxDate, allowOnlyDaysFromToday, selectedDates, beginDateLength = null } = this.props
    // // const { dates } = this.state
    // let dates = currentDates || this.state.dates
    // if (selectedDates) {
    //   dates = selectedDates
    // }

    const dates = currentDates || selectedDates

    if (allowOnlyDaysFromToday && allowOnlyDaysFromToday > 0) {
      const today = dayjs()
      // log('_updateLimitedDateRange allowOnlyDaysFromToday => ' ,{
      //   limit: {
      //     begin: {min: today.clone().subtract({days: allowOnlyDaysFromToday}), max: dates.end},
      //     end: {min: dates.begin, max: today},
      //   },
      // })
      await setStatePromise(this, {
        limit: {
          begin: { min: today.clone().subtract(allowOnlyDaysFromToday, 'days'), max: dates.end },
          end: { min: dates.begin, max: today },
        },
      })
      return
    }

    const limitBegin =
      minDate && minDate instanceof dayjs && (minDate.isSame(this.CURRENT_DATE, 'ms') || minDate.isBefore(this.CURRENT_DATE, 'ms'))
        ? minDate
        : minDate
    const limitEnd =
      maxDate && minDate instanceof dayjs && (maxDate.isSame(this.CURRENT_DATE, 'ms') || maxDate.isAfter(this.CURRENT_DATE, 'ms'))
        ? maxDate
        : maxDate

    const newLimit: IDateLimitRange = {
      begin: { min: this.MIN_DATE, max: dates.end },
      end: { min: dates.begin, max: this.MAX_DATE },
    }

    if (limitBegin) {
      newLimit.begin.min = limitBegin
    }

    if (limitEnd) {
      newLimit.end.max = limitEnd
    }

    if (!_.isNil(beginDateLength)) {
      newLimit.begin.min = beginDateLength.min
      newLimit.begin.max = beginDateLength.max
    }

    await setStatePromise(this, { limit: newLimit })
    // log('_updateLimitedDateRange normal limit => ', { limit: { begin, end } })
  }
}
