import React from 'react'
import BaseCCustomerListView from 'x/modules/contact/BaseCCustomerListView'
import { SectionList, TouchableOpacity } from 'react-native'

import { COLORS } from 'x/config/styles'
import _ from 'lodash'
import XCustomHeader from 'xui/components/XCustomHeader'
import XInput from 'xui/components/XInput'
import * as util from 'x/utils/util'
import XIcon from 'xui/components/XIcon'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import { Box } from 'native-base'
import { getConfig } from 'x/config/mode'
import XText from 'xui/components/XText'
import XButton from 'xui/components/XButton'
import HStack from 'xui/components/HStack'

const config = getConfig()

export default abstract class BaseUICCustomerListView extends BaseCCustomerListView {
  goBack = () => {
    util.navGoBack(this.props)
  }

  renderCustomHeader = () => {
    const leftBtn = { backIcon: true, onPressItem: () => this.goBack() }
    const { isSearch } = this.state
    if (isSearch) {
      return this._renderSearchHeader()
    }
    return <XCustomHeader title='รายชื่อลูกค้า' headerLeftProps={leftBtn} renderHeaderRight={this.headerRight} />
  }

  headerRight = () => (
    <HStack>
      <XButton onPress={() => this._openSearch()} variant='ghost' leftIcon={<XIcon name='search' color={COLORS.APP_MAIN} />} />
      <Box w='1' />
      <XButton
        onPress={() => this._navToCCustomerCreateView()}
        variant='ghost'
        leftIcon={<XIcon name='person-add' color={COLORS.APP_MAIN} />}
      />
    </HStack>
  )

  _renderSearchHeader = () => {
    const { textSearch } = this.state
    return (
      <HStack alignItems='center' p='2'>
        {/* <XIcon name='search' variant={'inactive'} /> */}
        <HStack flex={1}>
          <XInput
            ref={this.txtNewSearchlRef}
            flex={1}
            px='2'
            placeholder='Search'
            value={textSearch}
            onChangeText={(text: string) => {
              this._onChangeSearchText(text)
            }}
          />
        </HStack>
        <XButton onPress={() => this._closeSearch()} variant='ghost' _text={{ color: COLORS.TEXT_INACTIVE }}>
          ยกเลิก
        </XButton>
      </HStack>
    )
  }

  renderContactHeader = (title) => (
    <HStack py='2'>
      <XText px='2' bold>
        {title}
      </XText>
    </HStack>
  )
  // if (title === this.noResultFoundText) {
  //   return (
  //     <ListItem>
  //       <Body>
  //         <Body>
  //           <Text style={s.txtLabel}>{title}</Text>
  //         </Body>
  //       </Body>
  //     </ListItem>
  //   )
  // } else {
  //   return (
  //     <ListItem itemDivider>
  //       <Text style={s.txtHeader}>{title}</Text>
  //     </ListItem>
  //   )
  // }

  renderContactItem(idx, item) {
    // // console.log('_renderContactItem__item_: ', item)
    // const { navigation } = this.props
    // const { state } = navigation
    // const { params } = state
    // // console.log('params => ', params)
    // const showHomeIcon = _.isNil(params) ? null : params.callBackSelectedAddress
    // // const { callBackSelectedAddress } = params

    const showHomeIcon = util.getNavParam(this.props, 'callBackSelectedAddress')
    return (
      <TouchableOpacity
        style={{ borderBottomColor: COLORS.BG_LIGHT_GREY, borderBottomWidth: 1, flex: 1, flexDirection: 'row' }}
        onPress={() => this._onContactPressed(item.id)}>
        <HStack py='2' bgColor='white' flex={1}>
          <XText px='2'>{item.first_name}</XText>
        </HStack>
        {_.isFunction(showHomeIcon) ? (
          <TouchableOpacity
            style={{ backgroundColor: COLORS.WHITE, width: 60, alignItems: 'center', justifyContent: 'center' }}
            onPress={() => this._fetchCustomerAddresses(item.id)}>
            <XIcon name='home' family='Ionicons' />
          </TouchableOpacity>
        ) : null}
      </TouchableOpacity>
    )
  }

  _renderContactList() {
    const { contactListGroup } = this.props
    // console.log('contactListGroup => ', contactListGroup)
    if (_.isNil(contactListGroup)) {
      return null
    }
    const { isSearch } = this.state
    if (isSearch) {
      return null
    }
    return (
      <SectionList
        keyExtractor={(item, index) => index.toString()}
        renderItem={({ item, index }) => this.renderContactItem(index, item)}
        renderSectionHeader={({ section }) => this.renderContactHeader(section.key)}
        // @ts-ignore
        sections={contactListGroup}
        stickySectionHeadersEnabled
      />
    )
  }

  _renderSearchContactList = () => {
    const { isSearch, searchResult } = this.state
    if (!isSearch) {
      return null
    }
    return (
      <SectionList
        keyExtractor={(item, index) => index.toString()}
        renderItem={({ item, index }) => this.renderContactItem(index, item)}
        renderSectionHeader={({ section }) => this.renderContactHeader(section.key)}
        // @ts-ignore
        sections={searchResult}
        stickySectionHeadersEnabled
      />
    )
  }

  _renderSearchContact = () => {}

  _renderMain() {
    return (
      <XContainer>
        {this.renderCustomHeader()}
        <XContent>
          {this._renderContactList()}
          {this._renderSearchContactList()}
        </XContent>
      </XContainer>
    )
  }

  render() {
    return this._renderMain()
  }
}
