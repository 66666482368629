import React from 'react'
import { STYLES, COLORS } from 'x/config/styles'

import { Text, View, TouchableOpacity } from 'react-native'
import _ from 'lodash'
import BaseReportStockView from 'x/modules/report/BaseReportStockView'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import * as util from 'x/utils/util'
import XCheckboxList from 'xui/components/XCheckboxList'
import XIcon from 'xui/components/XIcon'
import XCard from 'xui/components/XCard'
import XButton from 'xui/components/XButton'
import HStack from 'xui/components/HStack'

export default abstract class BaseUIReportStockView extends BaseReportStockView {
  static displayName = 'BaseUIReportStockView'

  abstract _downloadReportStockView(url: string, fileName: string): Promise<void>

  _handleOnDownloadFile = async (url, fileName) => {
    await this._downloadReportStockView(url, fileName)
  }

  _renderMain = () => {
    const { checkboxListOption, checkboxReportStyleListOption } = this.state
    if (_.isNil(checkboxListOption) || checkboxListOption.length < 1) {
      return null
    }
    if (_.isNil(checkboxReportStyleListOption) || checkboxReportStyleListOption.length < 1) {
      return null
    }
    return (
      <XCard
        p='2'
        w='full'
        onLayout={event => {
          const { x, y, width, height } = event.nativeEvent.layout
          util.setStatePromise(this, { loadingWidth: width, loadingHeight: height })
        }}>
        <XCheckboxList
          title='เลือกติ๊กข้อมูลที่ต้องการเพิ่ม'
          // titleStyle={{ fontWeight: 'bold' }}
          options={checkboxListOption}
          onChange={this.onChangeCheckboxListOption}
        />
        <View style={{ height: 20 }} />
        <XCheckboxList
          title='ลักษณะรายงาน'
          // titleStyle={{ fontWeight: 'bold' }}
          options={checkboxReportStyleListOption}
          onChange={this.onChangeCheckboxReportStyleListOption}
        />
      </XCard>
    )
  }

  renderFooter = () => {
    return (
      <HStack w='full' p='1'>
        <XButton
          py='3'
          w='full'
          onPress={() => {
            this._showConfirmationDownloadReport()
          }}
          leftIcon={<XIcon family='FontAwesome' name='file-excel-o' />}>
          ดาวน์โหลดรายงาน
        </XButton>
      </HStack>
    )

    return (
      <TouchableOpacity
        // disabled={downloadingReport}
        style={{
          // flex: 1,
          width: '100%',
          borderColor: COLORS.APP_MAIN,
          backgroundColor: COLORS.APP_MAIN,
          borderRadius: 7,
          alignSelf: 'center',
          marginRight: 4,
          justifyContent: 'center',
          height: 35,
          borderWidth: 1,
          flexDirection: 'row',
        }}
        onPress={() => {
          this._showConfirmationDownloadReport()
        }}>
        <XIcon
          family='FontAwesome'
          name='file-excel-o'
          style={{
            fontSize: STYLES.FONT_ICON_SMALLER,
            color: COLORS.WHITE,
            flex: 0,
            minWidth: 30,
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            alignSelf: 'center',
          }}
        />
        <Text
          style={{
            fontSize: STYLES.FONT_SIZE_LARGER,
            fontWeight: 'bold',
            color: COLORS.WHITE,
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            alignSelf: 'center',
          }}>
          ดาวน์โหลดรายงาน
        </Text>
      </TouchableOpacity>
    )
    // return (
    //   <Button
    //     onPress={() => this._showConfirmationDownloadReport()}
    //     title='ดาวน์โหลด'
    //     titleStyle={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.WHITE }}
    //     style={{ backgroundColor: COLORS.APP_MAIN }}
    //   />
    // )
  }

  render() {
    const { isLoading, loadingHeight, loadingWidth } = this.state
    return (
      <XContainer>
        <XCustomHeader title='รายงานสินค้าคงคลัง' headerLeftProps={{ backIcon: true, onPressItem: () => util.navGoBack(this.props) }} />
        <XContent p='2'>{this._renderMain()}</XContent>
        {this.renderFooter()}
      </XContainer>
    )
  }
}
