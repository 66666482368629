import dayjs from 'dayjs'
import { IApiOptions, IDailySalesDataItem, IFetchDailySalesDataRequestBody, IFetchDailySalesDataResponse } from 'x/types'
import api from './api'

export async function fetchDailySalesData(params: IFetchDailySalesDataRequestBody) {
  const { store_id } = params

  if (!store_id) {
    throw new Error('fetchDailySalesData:: Need store_id')
  }

  const apiOptions: IApiOptions = {
    // messages: {
    //   successMsg: 'การสร้างสินค้าสำเร็จ',
    //   errorMsg: 'การสร้างสินค้าล้มเหลว',
    // },
    axiosOptions: {
      timeout: 60000,
    },
    showSpinner: false,
    isApiV2: true,
  }

  try {
    // console.log('In fetchDailySalesData:: reqBody => ', reqBody)
    // const res = yield call(api.post, api.POST_PRODUCT, reqBody, apiOptions)
    const res = await api.post<IFetchDailySalesDataRequestBody, IFetchDailySalesDataResponse>('dashboard/dailysales', params, apiOptions)
    // console.log('In fetchDailySalesData:: response => ', res)

    if (!res.data) {
      throw new Error('fetchDailySalesData:: No "data" in response')
    }
    const filledData = fillEmptyData(params.created_at_from, params.created_at_to, res.data)
    // console.log('In fetchDailySalesData:: filledData => ', filledData)
    const parsedData = parseData(filledData)
    // console.log('In fetchDailySalesData:: parsedData => ', parsedData)

    // return res.data
    return parsedData
  } catch (err) {
    console.log('In fetchDailySalesData:: err => ', err)
    throw err
  }
}

function fillEmptyData(begin: string, end: string, data: IDailySalesDataItem[]) {
  try {
    const startDate = dayjs(begin).startOf('day')
    const endDate = dayjs(end).endOf('day')
    const filledData = []

    // console.log('In fillEmptyData:: begin => ', begin)
    // console.log('In fillEmptyData:: end => ', end)
    // console.log('In fillEmptyData:: startDate => ', startDate)
    // console.log('In fillEmptyData:: endDate => ', endDate)

    let currentDate = startDate.clone()
    // console.log('In fillEmptyData:: currentDate => ', currentDate)

    while (currentDate.isBefore(endDate)) {
      // const dateString = currentDate.toISOString().split('T')[0]
      const dateString = currentDate.format('YYYY-MM-DD')
      const existingData = data.find((item) => item.date === dateString)

      if (existingData) {
        filledData.push(existingData)
      } else {
        filledData.push({ date: dateString, amount: 0, qty: 0, order: 0, profit: 0 })
      }

      currentDate = currentDate.add(1, 'day')
    }
    // console.log('In fillEmptyData:: currentDate => ', currentDate)
    // console.log('In fillEmptyData:: filledData => ', filledData)

    return filledData
  } catch (error) {
    return data
  }
}

function parseData(data: IDailySalesDataItem[]) {
  try {
    const parsedData = []
    data.forEach((d) => {
      parsedData.push({
        date: d.date,
        amount: parseFloat(d.amount),
        profit: parseFloat(d.profit),
        qty: parseInt(d.qty),
        order: parseInt(d.order),
      })
    })

    return parsedData
  } catch (error) {
    return data
  }
}
