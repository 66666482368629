import React from 'react'
import { Image, View, ImageSourcePropType } from 'react-native'
import Box from 'xui/components/Box'
import * as util from 'x/utils/util'
import p from 'x/config/platform-specific'
import { COLORS, STYLES } from 'x/config/styles'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContent from 'xui/components/XContent'
import XContainer from 'xui/components/XContainer'
import BaseStoreSettingXShippingNinjaVanView from 'x/modules/xshipping/BaseStoreSettingXShippingNinjaVanView'
import { VIEW_LOADING } from 'xui/utils/BaseShareUI'
import XSettingRow from 'xui/components/XSettingRow'
import XHelpModal from 'xui/components/XHelpModal'
import FAQ from 'x/config/FAQ'
import { IQuestionsAndAnswers } from 'x/index'
import XText from 'xui/components/XText'
import XButton from 'xui/components/XButton'

const IMG_NINJA: ImageSourcePropType = require('../../images/courier/ninja.png')
const IMG_SHIPJUNG: ImageSourcePropType = require('../../images/courier/shipjung.png')

export default abstract class BaseUIStoreSettingXShippingNinjaVanView extends BaseStoreSettingXShippingNinjaVanView {
  static displayName = 'BaseUIStoreSettingXShippingNinjaVanView'
  // abstract goBack: () => void

  _renderCustomHeader = () => (
    <XCustomHeader
      headerLeftProps={{
        backIcon: true,
        onPressItem: () => {
          util.navGoBack(this.props)
        },
      }}
      title={p.op.t('Order.shippingTypeItems.ninja')}
    />
  )

  _renderUseNinjaVanxShipjung = () => {
    const {
      useNinjaVanxShipjung,
      useAutoXShippingIsNinjaVanxShipjungReadyToShip,
      isNinjaVanxShipjungLoading,
      loadingNinjaVanxShipjungHeight,
      loadingNinjaVanxShipjungWidth,
    } = this.state
    const textTitle = 'ใช้งานการขอเลขพัสดุ\n"แบบไม่เก็บเงินปลายทาง"'
    // console.log('useAutoXShippingIsJAndTReadyToShip => ', useAutoXShippingIsJAndTReadyToShip)
    return (
      <View
        onLayout={(event) => {
          const { x, y, width, height } = event.nativeEvent.layout
          util.setStatePromise(this, { loadingNinjaVanxShipjungWidth: width, loadingNinjaVanxShipjungHeight: height })
        }}>
        <XSettingRow
          title={textTitle}
          value={useNinjaVanxShipjung}
          onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
          stateKey='useNinjaVanxShipjung'
          helper={{
            title: 'Ninja Van ออกเลขพัสดุ',
            FAQ: FAQ.XSHIPPING_NOT_COD,
            initiateOpenIndex: 0,
          }}
          backgroundColor={COLORS.APP_SECONDARY}
          // disabled={useJAndT}
        />

        {useNinjaVanxShipjung ? (
          <XSettingRow
            title='ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง'
            value={useAutoXShippingIsNinjaVanxShipjungReadyToShip}
            onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
            stateKey='useAutoXShippingIsNinjaVanxShipjungReadyToShip'
            helper={{
              title: 'ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง',
              FAQ: FAQ.XSHIPPING_READY_TO_SHIP,
              initiateOpenIndex: 0,
            }}
            backgroundColor={COLORS.APP_SECONDARY}
          />
        ) : null}

        {isNinjaVanxShipjungLoading ? VIEW_LOADING(loadingNinjaVanxShipjungWidth, loadingNinjaVanxShipjungHeight) : null}
      </View>
    )
  }

  _renderNinjaVanXShipjungCOD = () => {
    const {
      useNinjaVanxShipjungCOD,
      useAutoXShippingIsNinjaVanxShipjungCODReadyToShip,
      loadingNinjaVanxShipjungCODHeight,
      loadingNinjaVanxShipjungCODWidth,
      isNinjaVanxShipjungCODLoading,
    } = this.state
    const textTitle = 'ใช้งานการขอเลขพัสดุ\n"แบบเก็บเงินปลายทาง"'
    // const isDisabled = useJAndTCOD
    return (
      <View
        onLayout={(event) => {
          const { x, y, width, height } = event.nativeEvent.layout
          util.setStatePromise(this, { loadingNinjaVanxShipjungCODWidth: width, loadingNinjaVanxShipjungCODHeight: height })
        }}>
        <XSettingRow
          title={textTitle}
          value={useNinjaVanxShipjungCOD}
          onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
          stateKey='useNinjaVanxShipjungCOD'
          helper={{
            title: 'Ninja Van ออกเลขพัสดุ',
            FAQ: FAQ.XSHIPPING_COD,
            initiateOpenIndex: 0,
          }}
          backgroundColor={COLORS.APP_SECONDARY}
          // disabled={useJAndTCOD}
        />

        {useNinjaVanxShipjungCOD ? (
          <XSettingRow
            title='ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง'
            value={useAutoXShippingIsNinjaVanxShipjungCODReadyToShip}
            onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
            stateKey='useAutoXShippingIsNinjaVanxShipjungCODReadyToShip'
            helper={{
              title: 'ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง',
              FAQ: FAQ.XSHIPPING_READY_TO_SHIP,
              initiateOpenIndex: 0,
            }}
            backgroundColor={COLORS.APP_SECONDARY}
          />
        ) : null}

        {isNinjaVanxShipjungCODLoading ? VIEW_LOADING(loadingNinjaVanxShipjungCODWidth, loadingNinjaVanxShipjungCODHeight) : null}
      </View>
    )
  }

  _renderAgreement = (onPressOpenOverlay: () => void) => (
    <XButton onPress={() => onPressOpenOverlay()} w='48' variant='outline' alignSelf='center'>
      <XText variant='primary'>ดูข้อตกลงและวิธีการใช้งาน</XText>
    </XButton>
  )

  _getFAQAgreementForNinjaVanxShipjung = (): IQuestionsAndAnswers[] => {
    const newFQA = []
    FAQ.XSHIPPING_AGREEMENT_NINJA_VAN__X_SHIPJUNG.map((faq: IQuestionsAndAnswers) => {
      newFQA.push(faq)
    })
    FAQ.XSHIPPING_AGREEMENT_SHIPJUNG_COMMON.map((faq: IQuestionsAndAnswers) => {
      newFQA.push(faq)
    })
    return newFQA
  }

  _renderNinjaVanxShipjung = () => (
    <Box
      _light={{ bg: 'white' }}
      _dark={{ bg: 'gray.500' }}
      borderRadius='md'
      style={{
        // backgroundColor: COLORS.WHITE,
        marginTop: 10,
        marginRight: 6,
        marginLeft: 6,
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 6,
        paddingBottom: 15,
      }}>
      <Box style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
        <Image
          source={IMG_NINJA}
          resizeMode='stretch'
          style={{
            // position: 'absolute',
            width: 154,
            height: 58,
            backgroundColor: 'transparent',
            zIndex: 70,
            marginLeft: -30,
          }}
        />
        <XText style={{ fontSize: STYLES.FONT_SIZE_LARGER, color: COLORS.TEXT_ACTIVE, paddingRight: 10 }}>X</XText>
        <Image
          source={IMG_SHIPJUNG}
          resizeMode='stretch'
          style={{
            // position: 'absolute',
            width: 90,
            height: 58,
            backgroundColor: 'transparent',
            zIndex: 70,
          }}
        />
      </Box>
      <Box style={{ justifyContent: 'center', alignItems: 'center' }}>
        <XText variant='active'>ขอเลขพัสดุจาก Ninja Van ผ่าน Shipjung</XText>
      </Box>

      <XHelpModal
        key='XHelpModalFlash'
        headerTitle='ข้อตกลงและวิธีการใช้งาน'
        FAQ={this._getFAQAgreementForNinjaVanxShipjung()}
        initiateOpenIndex={4}
        renderButton={(onPressOpenOverlay: () => void) => this._renderAgreement(onPressOpenOverlay)}
      />

      <Box style={{ height: 10 }} />
      <Box style={{ paddingBottom: 2, flexDirection: 'row' }}>
        <XText variant='inactive'>{'รูปแบบจัดส่ง: '}</XText>
        <XText variant='active' bold>{`${p.op.t('Order.shippingTypeItems.ninja')}`}</XText>
      </Box>
      {this._renderUseNinjaVanxShipjung()}
      <Box style={{ height: 10 }} />
      <Box style={{ paddingBottom: 2, flexDirection: 'row' }}>
        <XText variant='inactive'>{'รูปแบบจัดส่ง: '}</XText>
        <XText variant='active' bold>{`${p.op.t('Order.shippingTypeItems.ninja_cod')}`}</XText>
      </Box>
      {this._renderNinjaVanXShipjungCOD()}
    </Box>
  )

  render() {
    return (
      <XContainer>
        {this._renderCustomHeader()}
        <XContent>
          <Box w='full'>{this._renderNinjaVanxShipjung()}</Box>
        </XContent>
      </XContainer>
    )
  }
}
