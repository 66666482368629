// import { COLORS as xCOLORS } from 'x/config/styles'
// import ImgBlock from '../../components/imgblock'

// import BasePaymentView, { OPTION_COD_AMOUNT, OPTION_ORDER_AMOUNT } from 'x/modules/payment/BasePaymentView'
// import SwitchCard from '../../components/SwitchCard'
// import settings from "x/config/settings"

// import BackIcon from '../../components/BackIcon'

// import s from './_style'
// import ImagePreview from '../../components/ImagePreview'
// import XSegment from '../../components/Segment'

import BaseUIStoreSettingXShippingBestView from 'xui/modules/xshipping/BaseUIStoreSettingXShippingBestView'
// import PaymentAccountPicker from 'xui/components/PaymentAccountPicker'

class StoreSettingXShippingBestView extends BaseUIStoreSettingXShippingBestView {
  static displayName = 'StoreSettingXShippingBestView'

  static navigationOptions = {
    header: null,
    title: null,
    headerLeft: null,
    headerRight: null,
  }
}

export default StoreSettingXShippingBestView
