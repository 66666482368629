import BaseUIStoreSettingPeakView from 'xui/modules/store/BaseUIStoreSettingPeakView'

class StoreSettingPeakView extends BaseUIStoreSettingPeakView {
  static displayName = 'StoreSettingPeakView'

  static navigationOptions = {
    header: null,
    title: null,
    headerLeft: null,
    headerRight: null,
  }
}

export default StoreSettingPeakView
