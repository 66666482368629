import React from 'react'
import { Dimensions } from 'react-native'
import _ from 'lodash'

import * as util from 'x/utils/util'
// import CONS from 'x/config/constants'

import XScrollView from 'xui/components/XScrollView'
import p from 'x/config/platform-specific'
import XOverlay from '../../XOverlay'
import VStack from '../../VStack'
import XText from '../../XText'
import HStack from '../../HStack'
import XButton from '../../XButton'

// import XSegment from '../XSegment'
// import XRadioList from '../XRadioList'
// import { diff } from 'deep-object-diff'
// import XIcon from '../XIcon'

type ICustomAlertOptions = {
  cancelable?: boolean
  onDismiss?: () => void
}

type IAlertButtonStyle = 'default' | 'cancel' | 'destructive'
// https://reactnative.dev/docs/alert#alertbuttonstyle-ios
// 'default'	Default button style.
// 'cancel'	Cancel button style.
// 'destructive'	Destructive button style.

export type ICustomAlertButton = {
  text: string
  onPress: () => void
  // style?: StyleProp<ViewStyle>
  style?: IAlertButtonStyle
}

interface IAlertProps {
  // visible?: boolean // ใช้ props ในการ control
}

interface IAlertState {
  visible: boolean

  title: string
  message?: string
  buttons?: ICustomAlertButton[]
  options?: ICustomAlertOptions

  deviceWidth: number
}

class Alert extends React.Component<IAlertProps, IAlertState> {
  static displayName = 'XAlert'

  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      title: '',
      deviceWidth: Dimensions.get('window').width || 300,
    }
  }

  isCancelable = () => {
    const { options } = this.state
    if (!options || _.isEmpty(options)) {
      return true
    }
    return options.cancelable
  }

  _onDismiss = () => {
    const { options } = this.state
    if (!options || _.isEmpty(options)) {
      return
    }

    if (_.isFunction(options.onDismiss)) {
      options.onDismiss()
    }
  }

  _onRequestClose = () => {
    if (this.isCancelable()) {
      this._closeOverlay()
    }
  }

  _openOverlay = async () => {
    await util.setStatePromise(this, { visible: true })
  }

  _closeOverlay = async () => {
    await util.setStatePromise(this, { visible: false })
    await util.delay(100)
    await util.setStatePromise(this, {
      title: '',
      message: null,
      buttons: null,
      options: null,
    })
    this._onDismiss()
  }

  alert = async (title: string, message?: string, buttons?: ICustomAlertButton[], options?: ICustomAlertOptions) => {
    const { visible } = this.state
    if (visible) {
      await this._closeOverlay()
      await util.delay(100)
    }

    await util.setStatePromise(this, { title, message, buttons, options, deviceWidth: Dimensions.get('window').width || 300 })
    await util.delay(100)
    await this._openOverlay()
  }

  isOverlayVisible = () => {
    const { visible } = this.state
    return visible === true
  }

  getOnButtonPressModified = onPressCallback => async () => {
    await this._closeOverlay()
    if (_.isFunction(onPressCallback)) {
      onPressCallback()
    }
  }

  renderCustomAlertButton = (btn: ICustomAlertButton, index: number) => {
    const { text, onPress, style = 'default' } = btn
    if (!text) {
      throw new Error('Alert::renderCustomAlertButton "text" is required')
    }

    let variant
    let colorScheme
    switch (style) {
      case 'cancel':
        variant = 'outline'
        colorScheme = 'primary'
        break
      case 'destructive':
        variant = 'solid'
        colorScheme = 'danger'
        break
      default:
        variant = 'solid'
        colorScheme = 'primary'
    }

    return (
      <XButton
        key={`AlertButton_${index.toString()}`}
        variant={variant}
        colorScheme={colorScheme}
        minH='10'
        minW='12'
        mt='2'
        px='2'
        py='1'
        onPress={this.getOnButtonPressModified(onPress)}
        // style={style}
      >
        {text}
      </XButton>
    )
  }

  renderSimpleOkButton = () => (
    <XButton mt='2' onPress={this._closeOverlay}>
      {p.op.t('done')}
    </XButton>
  )

  renderButtons = () => {
    const { buttons } = this.state
    if (!buttons || !_.isArray(buttons) || buttons.length === 0 || !buttons[0].text) {
      return this.renderSimpleOkButton()
    }
    if (buttons.length <= 2) {
      // Align the buttons horizontally if there are two or less buttons
      return buttons.map(this.renderCustomAlertButton)
    }
    // Align the buttons vertically if there are three or more buttons
    return <VStack>{buttons.map(this.renderCustomAlertButton)}</VStack>
  }

  renderContent = () => {
    const { title = '', message } = this.state
    return (
      <VStack w='full' maxH='full' minH='100px' px='2' space='1'>
        {title === '' ? null : (
          <VStack
            w='full'
            minH='9'
            py='2'
            borderBottomWidth={_.isString(message) && message.length > 0 ? '1' : '0'}
            borderBottomColor='muted.200'
            flexWrap='wrap'>
            <XText w='full' fontSize='lg' bold>
              {title}
            </XText>
          </VStack>
        )}

        {typeof message === 'string' && message !== '' ? (
          <XScrollView w='full'>
            <XText flex={1} p='2' fontSize='md'>
              {message}
            </XText>
          </XScrollView>
        ) : null}

        <HStack
          // bg='amber.100'
          w='full'
          minH='9'
          pb='2'
          space='2'
          alignItems='center'
          justifyContent='flex-end'
          borderTopWidth='1'
          borderTopColor='muted.200'
          flexWrap='wrap'>
          {this.renderButtons()}
        </HStack>
      </VStack>
    )
  }

  render() {
    if (!this.isOverlayVisible()) {
      return null
    }

    const { deviceWidth = 300 } = this.state
    const maxWidth = deviceWidth >= 500 ? 480 : deviceWidth - 32

    return (
      <XOverlay
        visible={this.isOverlayVisible()}
        contentStyle={{ maxWidth }}
        onRequestClose={this._onRequestClose}
        focusable={this.isOverlayVisible()}
        // style={{
        //   // @ts-ignore
        //   // postition: 'absolute',
        //   zIndex: 99999, // works on ios
        //   elevation: 99999, // works on android
        // }}
        // contentStyle={{
        //   // width: '70%',
        //   zIndex: 99999, // works on ios
        //   elevation: 99999, // works on android
        // }}
      >
        {this.renderContent()}
      </XOverlay>
    )
  }
}

export default Alert
