import { take, put, call, fork, all } from 'redux-saga/effects'
import _ from 'lodash'
import api from '../../utils/api'
import { ActionApiParams } from '../../index'
import { log } from '../../utils/util'
import actions from '../../config/actions'
import * as StoreActions from '../../modules/store/StoreState'

export default function* watchAllProductGroups() {
  yield all([
    fork(watchFetchProductGroups),
    fork(watchCreateProductGroup),
    fork(watchUpdateProductGroup),
    fork(watchDeleteProductGroup),
    fork(watchSortProductGroups),
  ])
}

function* watchFetchProductGroups() {
  log('In watchFetchProductGroups')
  while (true) {
    const action = yield take(actions.FETCH_PRODUCT_GROUPS)
    yield call(fetchProductGroups, action)
  }
}

function* fetchProductGroups(action: ActionApiParams) {
  const apiOptions = { showSpinner: true }
  try {
    log('In fetchProductGroups body => ', action.body)
    const res = yield call(api.post, api.POST_FETCH_PRODUCT_GROUPS, action.body, apiOptions)
    log('In fetchProductGroups response => ', res)
    yield call(loadProductGroupListFromResponse, res)
    if (_.isFunction(action.successCallback)) {
      action.successCallback(res)
    }
    // yield put(NavActions.goBack) // go back to StoreMyView
  } catch (error) {
    log('In fetchProductGroups error => ', error)
    yield put({ type: actions.ERROR_FETCH_PRODUCT_GROUPS, error })
    if (_.isFunction(action.failedCallback)) {
      action.failedCallback(error)
    }
  }
}

function* watchCreateProductGroup() {
  log('In watchCreateProductGroup')
  while (true) {
    const action = yield take(actions.CREATE_PRODUCT_GROUP)
    yield call(createProductGroup, action)
  }
}

function* createProductGroup(action: ActionApiParams) {
  const apiOptions = {
    messages: {
      successMsg: 'เพิ่มรายการราคาสำเร็จ',
      errorMsg: 'เพิ่มรายการราคาล้มเหลว',
    },
    showSpinner: true,
  }
  try {
    log('In createProduct body => ', action.body)
    const res = yield call(api.put, api.PUT_CREATE_PRODUCT_GROUP, action.body, apiOptions)
    log('In createProduct response => ', res)
    // yield call(loadProductGroupListFromResponse, res)
    yield call(loadNewProductGroupFromResponse, res)
    if (_.isFunction(action.successCallback)) {
      action.successCallback(res)
    }
    // yield put(NavActions.goBack) // go back to StoreMyView
  } catch (error) {
    log('In createProduct error => ', error)
    yield put({ type: actions.ERROR_CREATE_PRODUCT_GROUP, error })
    if (_.isFunction(action.failedCallback)) {
      action.failedCallback(error)
    }
  }
}

function* watchUpdateProductGroup() {
  log('In watchUpdateProductGroup')
  while (true) {
    const action = yield take(actions.UPDATE_PRODUCT_GROUP)
    yield call(updateProductGroup, action)
  }
}

function* updateProductGroup(action: ActionApiParams) {
  const apiOptions = {
    messages: {
      successMsg: 'แก้ไขรายการราคาสำเร็จ',
      errorMsg: 'แก้ไขรายการราคาล้มเหลว',
    },
    showSpinner: true,
  }
  try {
    log('In updateProduct body => ', action.body)
    const res = yield call(api.patch, api.PATCH_PRODUCT_GROUP, action.body, apiOptions)
    log('In updateProduct response => ', res)
    yield call(loadUpdatedProductGroupFromResponse, res)
    if (_.isFunction(action.successCallback)) {
      action.successCallback(res)
    }
    // yield put(NavActions.goBack) // go back to StoreMyView
  } catch (error) {
    log('In updateProduct error => ', error)
    yield put({ type: actions.ERROR_UPDATE_PRODUCT_GROUP, error })
    if (_.isFunction(action.failedCallback)) {
      action.failedCallback(error)
    }
  }
}

function* watchDeleteProductGroup() {
  log('In watchDeleteProductGroup')
  while (true) {
    const action = yield take(actions.DELETE_PRODUCT_GROUP)
    yield call(deleteProductGroup, action)
  }
}

function* deleteProductGroup(action: ActionApiParams) {
  const { body, successCallback, failedCallback } = action
  try {
    const apiOptions = {
      messages: {
        successMsg: body && body.is_confirm ? 'ลบรายการราคาสำเร็จ' : null,
        errorMsg: body && body.is_confirm ? 'ลบรายการราคาล้มเหลว' : null,
      },
      showSpinner: true,
    }
    log('In deleteProduct body => ', body)
    const res = yield call(api.del, api.DELETE_PRODUCT_GROUP, body, apiOptions)
    log('In deleteProduct response => ', res)
    yield call(clearRemovedProductGroupFromResponse, res, action.body)
    if (_.isFunction(successCallback)) {
      successCallback(res)
    }
    // yield put(NavActions.goBack) // go back to StoreMyView
  } catch (error) {
    log('In deleteProduct error => ', error)
    yield put({ type: actions.ERROR_DELETE_PRODUCT_GROUP, error })
    if (_.isFunction(failedCallback)) {
      failedCallback(error)
    }
  }
}

function* watchSortProductGroups() {
  log('In watchSortProductGroups')
  while (true) {
    const action = yield take(actions.SORT_PRIORITIES_PRODUCT_GROUPS)
    yield call(sortProductGroups, action)
  }
}

function* sortProductGroups(action: ActionApiParams) {
  const apiOptions = {
    messages: {
      successMsg: 'จัดเรียงรายการราคาสำเร็จ',
      errorMsg: 'จัดเรียงรายการราคาล้มเหลว',
    },
    showSpinner: true,
    axiosOptions: {
      retry: 0,
      timeout: 180000, // long fetch 180 seconds with no retry
    },
  }
  try {
    log('In sortProductGroups body => ', action.body)
    const res = yield call(api.patch, api.PATCH_SORT_PRIORITIES_PRODUCT_GROUPS, action.body, apiOptions)
    log('In sortProductGroups response => ', res)
    yield call(loadProductGroupListFromResponse, res)
    if (_.isFunction(action.successCallback)) {
      action.successCallback(res)
    }
    // yield put(NavActions.goBack) // go back to StoreMyView
  } catch (error) {
    log('In sortProductGroups error => ', error)
    yield put({ type: actions.ERROR_DELETE_PRODUCT_GROUP, error })
    // @ts-ignore
    if (error && error.error && error.error.attach) {
      // @ts-ignore
      yield call(loadProductGroupListFromResponse, error.error.attach)
    }
    if (_.isFunction(action.failedCallback)) {
      action.failedCallback(error)
    }
  }
}

function* loadNewProductGroupFromResponse(res) {
  if (_.has(res, 'product_group')) {
    log('loadNewProductGroupFromResponse product_group => ', res.product_group)
    yield put(StoreActions.loadNewProductGroup(res.product_group))
  }
  yield call(loadProductGroupListUpdatedAtFromResponse, res)
}

function* loadUpdatedProductGroupFromResponse(res) {
  if (_.has(res, 'product_group')) {
    log('loadUpdatedProductGroupFromResponse product_group => ', res.product_group)
    yield put(StoreActions.loadUpdatedProductGroup(res.product_group))
  }
  yield call(loadProductGroupListUpdatedAtFromResponse, res)
}

function* clearRemovedProductGroupFromResponse(res, requestBody) {
  if (_.has(res, 'status') && res.status === 'ok') {
    log('clearRemovedProductGroupFromResponse res => ', res)
    log('clearRemovedProductGroupFromResponse requestBody => ', requestBody)
    const { pg_id } = requestBody
    yield put(StoreActions.clearRemovedProductGroupFromListById(pg_id))
  }
  yield call(loadProductGroupListUpdatedAtFromResponse, res)
}

function* loadProductGroupListFromResponse(res) {
  if (_.has(res, 'product_groups')) {
    log('res has product_groups => ', res.product_groups)
    yield put(StoreActions.loadProductGroups(res.product_groups))
  }
  yield call(loadProductGroupListUpdatedAtFromResponse, res)
}

function* loadProductGroupListUpdatedAtFromResponse(res) {
  if (_.has(res, 'pgs_updated_at')) {
    log('loadProductGroupListUpdatedAtFromResponse pgs_updated_at => ', res.pgs_updated_at)
    yield put(StoreActions.loadProductGroupsUpdatedAt(res.pgs_updated_at))
  }
}
