import React from 'react'
import { TextInput } from 'react-native'
import { themeTools, useThemeProps } from 'native-base'
import XInput, { XInputBaseProps } from './XInput'
import XIconButton from './XIconButton'

const { stylingProps, extractInObject } = themeTools
export type IXNumericInputProps = Omit<XInputBaseProps, 'isMoney' | 'isInteger' | 'isNumber' | 'value'> & {
  maxValue?: number
  minValue?: number
  value?: number
  onChangeValue?: (newValue: number) => void
}

type IXOperateButtonProps = {
  // currentValue: number | string
  isDisabled?: boolean
  onPress: () => void
}

const XNumericInput = React.forwardRef<TextInput, IXNumericInputProps>((props, parentRef) => {
  const { isDisabled = false, maxValue, minValue, textAlign = 'center', value = 0, onChangeValue, onChangeText, ...restProps } = props

  const currentValueNumber = typeof value !== 'number' ? parseInt(value, 10) : value
  const isPlusDisabled = isDisabled || (typeof maxValue === 'number' && currentValueNumber >= maxValue)
  const isMinusDisabled = isDisabled || (typeof minValue === 'number' && currentValueNumber <= minValue)

  const themeProps = useThemeProps('Input', props)
  const [layoutProps, nonLayoutProps] = extractInObject(themeProps, [
    ...stylingProps.margin,
    // ...stylingProps.border,
    ...stylingProps.layout,
    ...stylingProps.flexbox,
    ...stylingProps.position,
    ...stylingProps.background,
    'shadow',
    'opacity',
  ])

  const _onPlusBtnPress = React.useCallback(() => {
    // const currentValueNumber = typeof value !== 'number' ? parseInt(value, 10) : value
    if (typeof onChangeText === 'function' && typeof currentValueNumber === 'number') {
      onChangeText(`${currentValueNumber + 1}`)
    }
    if (typeof onChangeValue === 'function' && typeof currentValueNumber === 'number') {
      onChangeValue(currentValueNumber + 1)
    }
  }, [currentValueNumber, onChangeText, onChangeValue])

  const _onMinusBtnPress = React.useCallback(() => {
    // const currentValueNumber = typeof value !== 'number' ? parseInt(value, 10) : value
    if (typeof onChangeText === 'function' && typeof currentValueNumber === 'number') {
      onChangeText(`${currentValueNumber - 1}`)
    }
    if (typeof onChangeValue === 'function' && typeof currentValueNumber === 'number') {
      onChangeValue(currentValueNumber - 1)
    }
  }, [currentValueNumber, onChangeText, onChangeValue])

  const _onChangeText = React.useCallback(
    (newText: string) => {
      const newValue = parseInt(newText, 10)
      if (typeof onChangeText === 'function' && typeof newValue === 'number') {
        onChangeText(newText)
      }
      if (typeof onChangeValue === 'function' && typeof newValue === 'number') {
        onChangeValue(parseInt(newText, 10))
      }
    },
    [currentValueNumber, onChangeText, onChangeValue]
  )

  // return (
  //   <HStack
  //     {...layoutProps}
  //     // w={{
  //     //   base: '70%',
  //     //   md: '285',
  //     // }}
  //   >
  //     {/* <InputLeftAddon m='0' p='0'> */}
  //     <MinusButton isDisabled={isMinusDisabled || isDisabled} onPress={_onMinusBtnPress} />
  //     {/* </InputLeftAddon> */}
  //     <XInput
  //       flex={1}
  //       // w={{
  //       //   base: '70%',
  //       //   md: '100%',
  //       // }}
  //       isDisabled={isDisabled}
  //       borderRadius='none'
  //       // @ts-ignore
  //       ref={parentRef}
  //       textAlign={textAlign}
  //       multiline={false}
  //       // isNumber
  //       isInteger
  //       onChangeText={_onChangeText}
  //       value={value.toString()}
  //       {...restProps}
  //     />
  //     {/* <InputRightAddon m='0' p='0'> */}
  //     <PlusButton isDisabled={isPlusDisabled || isDisabled} onPress={_onPlusBtnPress} />
  //     {/* </InputRightAddon> */}
  //   </HStack>
  // )

  return (
    <XInput
      // @ts-ignore
      ref={parentRef}
      // flex={1}
      // w={{
      //   base: '70%',
      //   md: '100%',
      // }}
      minH='34px'
      isDisabled={isDisabled}
      borderRadius='none'
      textAlign={textAlign}
      multiline={false}
      // isNumber
      isInteger
      onChangeText={_onChangeText}
      value={value.toString()}
      InputLeftElement={<MinusButton isDisabled={isMinusDisabled || isDisabled} onPress={_onMinusBtnPress} />}
      InputRightElement={<PlusButton isDisabled={isPlusDisabled || isDisabled} onPress={_onPlusBtnPress} />}
      {...restProps}
    />
  )
})

const PlusButton: React.FC<IXOperateButtonProps> = ({ isDisabled = false, onPress }) => (
  <XIconButton
    isDisabled={isDisabled}
    disabled={isDisabled}
    iconVariant={isDisabled ? 'inactive' : 'primary'}
    h='full'
    // borderWidth='0'
    // variant='outline'
    name='plus'
    family='MaterialCommunityIcons'
    // name='pluscircleo'
    // family='AntDesign'
    onPress={onPress}
  />
)

const MinusButton: React.FC<IXOperateButtonProps> = ({ isDisabled, onPress }) => (
  <XIconButton
    isDisabled={isDisabled}
    disabled={isDisabled}
    iconVariant={isDisabled ? 'inactive' : 'primary'}
    h='full'
    // borderWidth='0'
    // variant='outline'
    name='minus'
    family='MaterialCommunityIcons'
    // name='minuscircleo'
    // family='AntDesign'
    onPress={onPress}
  />
)

export default XNumericInput as React.FC<IXNumericInputProps>
