import { bindActionCreators } from 'redux'
import * as ProductStateActions from './ProductState'
import * as OrderActions from '../order/OrderState'
import * as StoreActions from '../store/StoreState'
import * as CategoryActions from '../category/categoryState'

import {
  getStoreSelected,
  getSelectedProductGroups,
  getSelectedUserGroups,
  getSearchedProducts,
  getSelectedProductsFetchStatus,
  getSearchedProductsFetchStatus,
  getSellerStores,
  getCategoryList,
} from '../../redux/selectors'

export function getState(state) {
  return {
    selectedStore: getStoreSelected(state),
    sellerStores: getSellerStores(state),
    selectedProductGroups: getSelectedProductGroups(state), // PGs
    selectedUserGroups: getSelectedUserGroups(state), // UGs

    // selectedStoreID: state.getIn(['stores', 'selectedStore', 'id']),
    selectedProducts: state.getIn(['products', 'selectedProducts']),
    // products: state.get('products'),
    shouldFetch: state.getIn(['products', 'shouldFetch']),
    searchedProducts: getSearchedProducts(state),
    selectedFetchStatus: getSelectedProductsFetchStatus(state),
    searchedFetchStatus: getSearchedProductsFetchStatus(state),
    categoryList: getCategoryList(state),
  }
}

export function getDispatch(dispatch) {
  return {
    fetchMyStore: bindActionCreators(StoreActions.fetchMyStore, dispatch),

    // @ts-ignore
    productActions: bindActionCreators(ProductStateActions, dispatch),
    fetchProducts: bindActionCreators(ProductStateActions.fetchProducts, dispatch),
    productListClear: bindActionCreators(ProductStateActions.productListClear, dispatch),
    resetSearchResults: bindActionCreators(ProductStateActions.resetSearchResults, dispatch),
    // For quick add
    addProductToOrder: bindActionCreators(OrderActions.addProductToOrder, dispatch),
    quickAddProductToOrder: bindActionCreators(OrderActions.quickAddProductToOrder, dispatch),
    requestCategoryList: bindActionCreators(CategoryActions.requestCategoryList, dispatch),
  }
}
