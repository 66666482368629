import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { loadProduct, editProduct } from 'x/modules/product/ProductState'
import { getStoreSelected, getSelectedProduct } from 'x/redux/selectors'
import ProductVariantEditorView from './ProductVariantEditorView'

export default connect(
  (state) =>
    // console.log('state => ', state)
    // console.log('state.get(product)  => ', state.get('product'))
    ({
      // @ts-ignore
      // fetchedProductsByKey: state.get('product') ? state.get('product').fetchedProductsByKey : null,
      selectedStore: getStoreSelected(state),
      selectedProduct: getSelectedProduct(state),
    }),
  (dispatch) => ({
    dispatch,
    submitEditProduct: bindActionCreators(editProduct, dispatch),
    loadProductToProductView: bindActionCreators(loadProduct, dispatch),
  })
)(ProductVariantEditorView)
