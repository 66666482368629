import React from 'react'
import _ from 'lodash'
import { log, setStatePromise } from 'x/utils/util'
import { Map } from 'immutable'
import xCONS from 'x/config/constants'
import dayjs, { Dayjs } from 'dayjs'
import XFlatPicker from 'xui/components/XFlatPicker'
import { IXScreenProps } from 'x/types'
import * as util from '../../utils/util'
import p from '../../config/platform-specific'

export interface BaseReportRequisitionViewProps extends IXScreenProps {
  selectedStore: Map<string, any>
  subscription: any
}

export interface BaseReportRequisitionViewState {
  loading: boolean
  // selectedOptListBy: string;
  // selectedOptDateRange: string;
  selectedOpts: {
    LIST_BY: string
    DATE_RANGE: string
  }
  selectedDateRange: { begin: Dayjs; end: Dayjs }
  WAIT_TO_SHIP_ORDERS: boolean
  WAIT_TO_REFUND_ORDERS: boolean
  COST: boolean
  TOTAL_COST: boolean // TOTAL_
  // optKey: boolean,
  BTN_RADIO_STANDARD: boolean
  BTN_RADIO_FLASG_EXPRESS: boolean
  BTN_RADIO_KERRY_EXPRESS_1: boolean
  BTN_RADIO_KERRY_EXPRESS_2: boolean

  // web-v1
  downloadLink?: string
  submitting?: boolean
  downloadProgress?: number
  error?: boolean

  optionDateTimeIndex: number
  selectedShippingTypeId: number[]
  optionShippingTypeIndex: number
}

export default abstract class BaseReportRequisitionView extends React.Component<
  BaseReportRequisitionViewProps,
  BaseReportRequisitionViewState
> {
  inProcess: boolean

  TXT_TITLES: {
    ORDER_STATUS: string
    SHOW_MORE_DATA_EXCEL: string
    FORMAT_EXCEL: string
  }

  TXT_WARNING_DATE_RANGE: string

  OPTIONS: {
    LIST_BY: string[]
    DATE_RANGE: string[]
  }

  TXT_CHECKBOX: {
    WAIT_TO_SHIP_ORDERS: string
    WAIT_TO_REFUND_ORDERS: string
    COST: string
    TOTAL_COST: string // TOTAL_
  }

  TXT_RADIO: {
    BTN_RADIO_STANDARD: string
    BTN_RADIO_FLASG_EXPRESS: string
    BTN_RADIO_KERRY_EXPRESS_1: string
    BTN_RADIO_KERRY_EXPRESS_2: string
  }

  KEY_RADIO: {
    BTN_RADIO_STANDARD: string
    BTN_RADIO_FLASG_EXPRESS: string
    BTN_RADIO_KERRY_EXPRESS_1: string
    BTN_RADIO_KERRY_EXPRESS_2: string
  }

  OPTION_DATE_TIME: string[]

  OPTION_SHIPPING_TYPE: string[]

  _shippingTypeCreateModePickerRef: React.RefObject<XFlatPicker<any>>

  shippingTypeItems: Array<{
    index: number
    right: string // right icon ?
    value: number
    key: string
    tel_required?: boolean
    label: string
  }>

  abstract _handleOnDownloadFile(url: string, fileName: string): Promise<void>

  protected constructor(props) {
    super(props)
    this.TXT_TITLES = {
      ORDER_STATUS: 'ออเดอร์อยู่ในสถานะ (เลือกอย่างน้อย 1 สถานะ)',
      SHOW_MORE_DATA_EXCEL: 'แสดงข้อมูลเพิ่มเติมใน Excel (เลือกที่ต้องการ)',
      FORMAT_EXCEL: 'รูปแบบ Excel',
    }

    this.OPTIONS = {
      LIST_BY: ['ฉัน', 'ผู้ขายส่ง'],
      DATE_RANGE: ['วันนี้', 'ระบุช่วงเวลา'],
    }

    this.TXT_CHECKBOX = {
      WAIT_TO_SHIP_ORDERS: 'รอฉันจัดส่ง (ฉันยืนยันรับชำระพอดี)',
      WAIT_TO_REFUND_ORDERS: 'รอฉันคืนเงิน (ฉันยืนยันรับชำระมาเกินยอดออเดอร์)',
      COST: 'ต้นทุนสินค้าต่อชิ้น',
      TOTAL_COST: 'รวมต้นทุนสินค้าทั้งออเดอร์', // TOTAL_
    }

    this.TXT_RADIO = {
      BTN_RADIO_STANDARD: 'แบบมาตรฐาน',
      BTN_RADIO_FLASG_EXPRESS: 'แบบ Flash Express',
      BTN_RADIO_KERRY_EXPRESS_1: 'แบบ Kerry Express (1)',
      BTN_RADIO_KERRY_EXPRESS_2: 'แบบ Kerry Express (2)',
    }
    this.KEY_RADIO = {
      BTN_RADIO_STANDARD: 'BTN_RADIO_STANDARD',
      BTN_RADIO_FLASG_EXPRESS: 'BTN_RADIO_FLASG_EXPRESS',
      BTN_RADIO_KERRY_EXPRESS_1: 'BTN_RADIO_KERRY_EXPRESS_1',
      BTN_RADIO_KERRY_EXPRESS_2: 'BTN_RADIO_KERRY_EXPRESS_2',
    }

    this.TXT_WARNING_DATE_RANGE = 'แพ็กเกจปัจจุบันของคุณไม่สามารถเลือกช่วงเวลาเพื่อดูรายการจัดส่งย้อนหลังได้'

    this.OPTION_DATE_TIME = [`ทั้งหมด`, `ระบุ`]
    this.OPTION_SHIPPING_TYPE = [`ทั้งหมด`, `ระบุ`]

    this._shippingTypeCreateModePickerRef = React.createRef()

    this.shippingTypeItems = xCONS.ORDER_VIEW_SHIPPING_TYPE_ITEMS.map((item) => ({
      key: item.index,
      ...item,
      label: p.op.t(`Order.shippingTypeItems.${item.key}`),
    }))

    this.state = {
      loading: false,
      selectedOpts: {
        LIST_BY: this.OPTIONS.LIST_BY[0],
        DATE_RANGE: this.OPTIONS.DATE_RANGE[0],
      },
      selectedDateRange: { begin: dayjs(), end: dayjs() },
      WAIT_TO_SHIP_ORDERS: false,
      WAIT_TO_REFUND_ORDERS: false,
      COST: false,
      TOTAL_COST: false, // TOTAL_
      BTN_RADIO_STANDARD: false,
      BTN_RADIO_FLASG_EXPRESS: false,
      BTN_RADIO_KERRY_EXPRESS_1: false,
      BTN_RADIO_KERRY_EXPRESS_2: false,

      // web-v1
      downloadLink: null,
      submitting: false,
      // loaded: 0,
      // total: 100,
      downloadProgress: 0,
      error: false,
      optionDateTimeIndex: 0,
      selectedShippingTypeId: [],
      optionShippingTypeIndex: 0,
    }

    this.inProcess = false

    this._onChangeSegment = this._onChangeSegment.bind(this)
    this._onChangeDateRange = this._onChangeDateRange.bind(this)
    this._isVisibleDateRangePicker = this._isVisibleDateRangePicker.bind(this)
    this._downloadReportRequisitionUrl = this._downloadReportRequisitionUrl.bind(this)
    this._computeAllowDays = this._computeAllowDays.bind(this)
    this._getTxtDateRangeDescription = this._getTxtDateRangeDescription.bind(this)
  }

  async componentDidMount() {
    // ถ้าชน master โปรดใช้ไฟล์นี้
    await this.initSettings()
  }

  initSettings = async () => {
    if (!this._canRequestMoreColumns()) {
      // set to default
      this.setState(
        {
          WAIT_TO_SHIP_ORDERS: true,
          WAIT_TO_REFUND_ORDERS: false,
          COST: false,
          BTN_RADIO_STANDARD: true,
        },
        () => {
          log(this.state.WAIT_TO_SHIP_ORDERS)
        }
      )
    } else {
      const { STORAGE_KEYS } = xCONS
      p.op.storageGet(STORAGE_KEYS.REPORT_REQUIS_WAIT_TO_SHIP_ORDERS).then((val: any) => {
        if (val != null) {
          this.setState({
            WAIT_TO_SHIP_ORDERS: val,
          })
        } else {
          // default to true
          this.setState({
            WAIT_TO_SHIP_ORDERS: true,
          })
        }
      })
      p.op.storageGet(STORAGE_KEYS.REPORT_REQUIS_WAIT_TO_REFUND_ORDERS).then((val: any) => {
        if (val != null) {
          this.setState({
            WAIT_TO_REFUND_ORDERS: val,
          })
        }
      })
      p.op.storageGet(STORAGE_KEYS.REPORT_REQUIS_SHOW_COST).then((val: any) => {
        if (val != null) {
          this.setState({
            COST: val,
          })
        }
      })
      // TOTAL_
      p.op.storageGet(STORAGE_KEYS.REPORT_REQUIS_SHOW_TOTAL_COST).then((val: any) => {
        if (val != null) {
          this.setState({
            TOTAL_COST: val,
          })
        }
      })
      p.op.storageGet(STORAGE_KEYS.REPORT_REQUIS_BTN_RADIO_STANDARD).then((val: any) => {
        if (!_.isNil(val)) {
          this.setState({
            BTN_RADIO_STANDARD: val,
          })
        } else {
          this.setState({
            BTN_RADIO_STANDARD: true,
          })
        }
      })
      p.op.storageGet(STORAGE_KEYS.REPORT_REQUIS_BTN_RADIO_FLASG_EXPRESS).then((val: any) => {
        if (!_.isNil(val)) {
          this.setState({
            BTN_RADIO_FLASG_EXPRESS: val,
          })
        }
      })
      p.op.storageGet(STORAGE_KEYS.REPORT_REQUIS_BTN_RADIO_KERRY_EXPRESS).then((val: any) => {
        if (!_.isNil(val)) {
          this.setState({
            BTN_RADIO_KERRY_EXPRESS_1: val,
          })
        }
      })
      p.op.storageGet(STORAGE_KEYS.REPORT_REQUIS_BTN_RADIO_KERRY_EXPRESS_2).then((val: any) => {
        if (!_.isNil(val)) {
          this.setState({
            BTN_RADIO_KERRY_EXPRESS_2: val,
          })
        }
      })
      p.op.storageGet(STORAGE_KEYS.REPORT_REQUIS_BTN_SEGMENT_DATETIME).then((val: any) => {
        if (!_.isNil(val)) {
          this.setState({
            optionDateTimeIndex: val,
          })
        }
      })
      p.op.storageGet(STORAGE_KEYS.REPORT_REQUIS_BTN_SEGMENT_SHIPPING_TYPE).then((val: any) => {
        if (!_.isNil(val)) {
          this.setState({
            optionShippingTypeIndex: val,
          })
        }
      })
    }
  }

  _getParams() {
    // return this.props.navigation.state.params
    const params = util.getNavParams(this.props)
    return params
  }

  _onChangeSegment(optKey: string, newValue: string): void {
    const newSelectedOpts = _.cloneDeep(this.state.selectedOpts)
    newSelectedOpts[optKey] = newValue
    this.setState({ selectedOpts: newSelectedOpts })
  }

  _onChangeDateRange(newDates: { begin: Dayjs; end: Dayjs }): void {
    this.setState({ selectedDateRange: newDates })
  }

  _isVisibleDateRangePicker(): boolean {
    return this.state.selectedOpts.DATE_RANGE === this.OPTIONS.DATE_RANGE[1]
  }

  _computeAllowDays(): number {
    const { subscription } = this.props
    const allowDays = subscription.has('r_shpg_day') && _.isNumber(subscription.get('r_shpg_day')) ? subscription.get('r_shpg_day') : 1
    // log('_computeAllowDays => ', _.isNumber(allowDays) ? allowDays : parseInt(allowDays))
    return _.isNumber(allowDays) ? allowDays : parseInt(allowDays)
  }

  _canRequestMoreColumns = (): boolean => util.canRequestExtraExcelColumns(this.props.subscription.get('type'))

  _getTxtDateRangeDescription(): string {
    const allowDays = this._computeAllowDays()
    let txtDesc = 'สามารถดูรายงานย้อนหลังได้'

    if (allowDays === 9999) {
      //
      txtDesc += `ตั้งแต่เปิดร้าน`
    } else {
      txtDesc += ` ${allowDays} วัน`
    }

    if (allowDays > 93) {
      txtDesc += '\nโดยสามารถดาวน์โหลดรายงานได้สูงสุด 93 วันต่อครั้ง'
    }

    return txtDesc
  }

  _showSeletedChannel = (index: number) => {
    util.setStatePromise(this, { optionDateTimeIndex: index })
  }

  _onChangeSegmentShippingIndex = (index: number) => {
    util.setStatePromise(this, { optionShippingTypeIndex: index })
  }

  async _downloadReportRequisitionUrl(): Promise<void> {
    // ถ้าเป็น IOS และเข้าเงื่อนไขก็ปล่อยผ่าน // https://app.clickup.com/t/86cvy21qt
    const isNoobIos = util.isIosNoobCheckByPass()
    // if (isNoobIos) {
    //   p.op.showConfirmationOkOnly('', p.op.t('Subscription.warning.iosNoobCheckMsg'))
    //   return
    // }
    if (!isNoobIos && util.isPackFreeAndSelectedStoreOwner()) {
      p.op.alert(p.op.t('Subscription.warning.insufficientTypeTitle'), p.op.t('Subscription.warning.insufficientTypeMsg'))
      // this.inProcess = false
      return
    }

    // TODO: Check for perm on BaseReportProductDailySales line 335?

    // const isHlper = util.isHelper()
    // // if (!isHlper && util.isStoreOwner() && this.props.subscription.get('type') === xCONS.SUBSCRIPTION_PACKAGE.FREE) {
    // if (!isHlper && util.isStoreOwner()) {
    //   p.op.alert(p.op.t('Subscription.warning.insufficientTypeTitle'), p.op.t('Subscription.warning.insufficientTypeMsg'))
    //   return
    // }

    if (this.inProcess || this.state.loading) {
      return
    }
    this.inProcess = true
    await setStatePromise(this, { loading: true })

    await p.op.storageSet(xCONS.STORAGE_KEYS.REPORT_REQUIS_WAIT_TO_SHIP_ORDERS, this.state.WAIT_TO_SHIP_ORDERS)
    await p.op.storageSet(xCONS.STORAGE_KEYS.REPORT_REQUIS_WAIT_TO_REFUND_ORDERS, this.state.WAIT_TO_REFUND_ORDERS)
    await p.op.storageSet(xCONS.STORAGE_KEYS.REPORT_REQUIS_SHOW_COST, this.state.COST)
    await p.op.storageSet(xCONS.STORAGE_KEYS.REPORT_REQUIS_SHOW_TOTAL_COST, this.state.TOTAL_COST) // TOTAL_
    await p.op.storageSet(xCONS.STORAGE_KEYS.REPORT_REQUIS_BTN_RADIO_STANDARD, this.state.BTN_RADIO_STANDARD)
    await p.op.storageSet(xCONS.STORAGE_KEYS.REPORT_REQUIS_BTN_RADIO_FLASG_EXPRESS, this.state.BTN_RADIO_FLASG_EXPRESS)
    await p.op.storageSet(xCONS.STORAGE_KEYS.REPORT_REQUIS_BTN_RADIO_KERRY_EXPRESS, this.state.BTN_RADIO_KERRY_EXPRESS_1)
    await p.op.storageSet(xCONS.STORAGE_KEYS.REPORT_REQUIS_BTN_RADIO_KERRY_EXPRESS_2, this.state.BTN_RADIO_KERRY_EXPRESS_2)
    await p.op.storageSet(xCONS.STORAGE_KEYS.REPORT_REQUIS_BTN_SEGMENT_DATETIME, this.state.optionDateTimeIndex)
    await p.op.storageSet(xCONS.STORAGE_KEYS.REPORT_REQUIS_BTN_SEGMENT_SHIPPING_TYPE, this.state.optionShippingTypeIndex)

    // const { selectedOpts, selectedDateRange } = this.state
    const { store_id } = this._getParams()

    let startDate = null
    let endDate = null

    const {
      selectedDateRange,
      optionDateTimeIndex,
      BTN_RADIO_STANDARD,
      optionShippingTypeIndex,
      selectedShippingTypeId,
      BTN_RADIO_FLASG_EXPRESS,
      BTN_RADIO_KERRY_EXPRESS_1,
      BTN_RADIO_KERRY_EXPRESS_2,
    } = this.state

    if (optionDateTimeIndex === 1) {
      let stime = util.changeSecondTime(selectedDateRange.begin, 0)
      stime = util.changeMillisecondsTime(stime, 0)
      let etime = util.changeSecondTime(selectedDateRange.end, 59)
      etime = util.changeMillisecondsTime(etime, 999)
      startDate = stime.format('YYYY-MM-DD%20HH:mm:ss')
      endDate = etime.format('YYYY-MM-DD%20HH:mm:ss')
      // startDate = selectedDateRange.begin.format('YYYY-MM-DD%20HH:mm:ss')
      // endDate = selectedDateRange.end.format('YYYY-MM-DD%20HH:mm:ss')
    }
    // console.log(`startDate `, startDate)
    // console.log(`endDate `, endDate)
    let shippingTypeIds = null
    if (selectedShippingTypeId.length > 0) {
      shippingTypeIds = ''
      selectedShippingTypeId.map((shiping: number, index: number) => {
        if (selectedShippingTypeId.length === index + 1) {
          shippingTypeIds += `shipping_type_ids[]=${shiping}`
        } else {
          shippingTypeIds += `shipping_type_ids[]=${shiping}&`
        }
      })
    }

    if (!BTN_RADIO_STANDARD && !BTN_RADIO_FLASG_EXPRESS && !BTN_RADIO_KERRY_EXPRESS_1 && !BTN_RADIO_KERRY_EXPRESS_2) {
      p.op.showConfirmationOkOnly(``, `กรุณาเลือกรูปแบบ Excel`)
      this.inProcess = false
      await setStatePromise(this, { loading: false })
      return
    }

    const url = util.getReportRequisitionExcelUrl({
      store_id,
      listReadyToShipOrders: BTN_RADIO_STANDARD ? this.state.WAIT_TO_SHIP_ORDERS : false,
      listConfirmedToRefundOrders: BTN_RADIO_STANDARD ? this.state.WAIT_TO_REFUND_ORDERS : false,
      showCost: BTN_RADIO_STANDARD ? this.state.COST : false,
      showTotalCost: BTN_RADIO_STANDARD ? this.state.TOTAL_COST : false, // TOTAL_
      isFlashExpress: this.state.BTN_RADIO_FLASG_EXPRESS,
      isKerryExpress: this.state.BTN_RADIO_KERRY_EXPRESS_1,
      isKerryExpress2: this.state.BTN_RADIO_KERRY_EXPRESS_2,
      startDateTime: startDate,
      endDateTime: endDate,
      shippingTypeIds: BTN_RADIO_STANDARD ? shippingTypeIds : null,
    })
    const startDateTime = dayjs().format('DD-MM-YYYY_H-mm-ss')
    const fileName = `XSelly_ToShip_${startDateTime}.xlsx`

    // fileName = `${fileName}.xlsx`

    // console.log('_downloadReportRequisitionUrl url => ', url)
    log('_downloadReportRequisitionUrl fileName => ', fileName)
    await this._handleOnDownloadFile(url, fileName)
    await setStatePromise(this, { loading: false })
    await setTimeout(() => {
      this.inProcess = false
    }, 500)
  }
}
