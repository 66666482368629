import React from 'react'
import Popover, { PopoverMode, PopoverPlacement } from 'react-native-popover-view'

import CONS from 'x/config/constants'
import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'
import {
  IXDateRangeSelectorProps,
  IDateRange,
  IXDateRangeSelectorOptionKey,
  IXDateRangeSelectorChangeParams,
  IXDateRangeSelectorOption,
} from 'x/index'

import { LayoutChangeEvent, LayoutRectangle, TouchableOpacity } from 'react-native'
import { IBoxProps } from 'native-base'
import HStack from './HStack'
import XText, { IXTextProps } from './XText'
import XDateRangeSelector from './XDateRangeSelector'
import VStack from './VStack'
import XIcon, { XIconProps } from './XIcon'
import XButton from './XButton'
import XOverlay from './XOverlay'
import XSpinner from './XSpinner'
import Box from './Box'

const CUTOFF_WIDTH = 234

// import * as util from 'x/utils/util'

interface IXDateRangeSelectorButtonProps extends IXDateRangeSelectorProps {
  title?: string
  submitting?: boolean

  containerProps?: IBoxProps
  labelContainerProps?: IBoxProps
  labelTextProps?: IXTextProps

  dateLabelProps?: IXTextProps
  dateTextProps?: IXTextProps
  dropdownIconProps?: Partial<XIconProps>
}

interface IXDateRangeSelectorButtonState {
  selectedDates: IDateRange | null
  selectedOption?: IXDateRangeSelectorOption
  selectedOptionKey: IXDateRangeSelectorOptionKey

  isInitialized: boolean
  showPopover: boolean

  containerLayout?: LayoutRectangle
}

class XDateRangeSelectorButton extends React.Component<IXDateRangeSelectorButtonProps, IXDateRangeSelectorButtonState> {
  static displayName = 'XDateRangeSelectorButton'

  // static defaultProps = {
  //   opacity: 1,
  // }

  inProcess?: boolean

  constructor(props) {
    super(props)
    this.state = {
      selectedDates: null,
      selectedOption: null,
      selectedOptionKey: CONS.DATERANGE_SELECTOR.OPTION.Today,

      isInitialized: false,
      showPopover: false,
    }
  }

  async componentDidMount() {
    await util.delay(100)
    this.setState({ isInitialized: true })
  }

  renderDatesSelector = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { title, selectedDates, selectedOptionKey, onChange, ...restProps } = this.props
    const { selectedDates: selectedDatesState, selectedOptionKey: selectedOptionKeyState, isInitialized } = this.state
    return (
      <VStack
        minW='300px'
        maxW='320px'
        // minW='300px'
        // minH='200px'
        p='1'
        bg='white'
        space='1'
        //  borderWidth='1' borderRadius='lg' overflow='hidden'
      >
        {title ? (
          <XText w='full' py='2' textAlign='center' variant='inactive' bold>
            {title}
          </XText>
        ) : null}
        {/* <HStack position='absolute' top='1' right='1'>
          <XIconButton name='close-circle' onPress={this._hidePopover} />
        </HStack> */}
        <XDateRangeSelector
          {...restProps}
          selectedDates={selectedDatesState}
          selectedOptionKey={selectedOptionKeyState}
          onChange={this._onDateRangeSelectorChange}
        />

        <HStack w='full' py='1' space='1' alignItems='center' justifyContent='flex-end'>
          <XButton variant='outline' w='80px' minH='9' onPress={this._onCancel}>
            ยกเลิก
          </XButton>
          <XButton w='80px' minH='9' onPress={this._onApply}>
            ตกลง
          </XButton>
        </HStack>
      </VStack>
    )
  }

  renderDisplayDatesLabel = () => {
    const { selectedOptionKey, labelContainerProps = {}, labelTextProps = {} } = this.props
    if (selectedOptionKey === CONS.DATERANGE_SELECTOR.OPTION.Custom) {
      return null
    }
    const { containerLayout } = this.state
    if (!containerLayout || !containerLayout.width || containerLayout.width < CUTOFF_WIDTH) {
      // ซ่อน label หาก width น้อยกว่า CUTOFF_WIDTH
      return null
    }

    return (
      <HStack
        maxW='74px'
        p='0.5'
        borderRadius='sm'
        borderWidth='1'
        borderColor='muted.200'
        bg='muted.100'
        alignItems='center'
        justifyContent='center'
        {...labelContainerProps}>
        <XText flex={1} fontSize='xs' textAlign='center' t={`dateRangeSelector.options.${selectedOptionKey}`} {...labelTextProps} />
      </HStack>
    )
  }

  renderDisplayDatesText = () => {
    const { selectedDates, showTime = false, dateLabelProps = {}, dateTextProps = {} } = this.props
    const { begin } = selectedDates
    const { end } = selectedDates

    // const dateTimeFormat = showTime ? 'D/MM/YYYY HH:mm' : 'D/MM/YYYY'
    const dateFormat = 'D/MM/YYYY'
    const timeFormat = 'HH:mm'
    const fromDateText = begin.format(dateFormat)
    const toDateText = end.format(dateFormat)
    const fromTimeText = showTime ? begin.format(timeFormat) : null
    const toTimeText = showTime ? end.format(timeFormat) : null

    const { containerLayout } = this.state
    const isShortWidth = containerLayout && containerLayout.width && containerLayout.width < CUTOFF_WIDTH

    return (
      <VStack flex={1} alignItems={isShortWidth ? 'center' : 'flex-end'} justifyContent='center'>
        <HStack w='full' alignItems='center' justifyContent={isShortWidth ? 'center' : 'flex-end'}>
          <XText fontSize='xs' variant='inactive' {...dateLabelProps}>
            จาก
          </XText>
          <HStack flex={isShortWidth ? '1' : undefined} alignItems='center' justifyContent={isShortWidth ? 'center' : 'flex-end'}>
            <XText variant='primary' w='86px' textAlign='right' {...dateTextProps}>
              {fromDateText}
            </XText>
            {showTime ? (
              <XText variant='primary' w='44px' textAlign='right' {...dateTextProps}>
                {fromTimeText}
              </XText>
            ) : null}
          </HStack>
        </HStack>
        <HStack w='full' alignItems='center' justifyContent={isShortWidth ? 'center' : 'flex-end'}>
          <XText fontSize='xs' variant='inactive' {...dateLabelProps}>
            จนถึง
          </XText>
          <HStack flex={isShortWidth ? '1' : undefined} alignItems='center' justifyContent={isShortWidth ? 'center' : 'flex-end'}>
            <XText variant='primary' w='86px' textAlign='right' {...dateTextProps}>
              {toDateText}
            </XText>
            {showTime ? (
              <XText variant='primary' w='44px' textAlign='right' {...dateTextProps}>
                {toTimeText}
              </XText>
            ) : null}
          </HStack>
        </HStack>
      </VStack>
    )
  }

  _onContainerLayout = (evt: LayoutChangeEvent) => {
    this.setState({ containerLayout: evt.nativeEvent.layout })
  }

  // renderDisplayDates = (sourceRef: React.RefObject<View>, openPopover: () => void) => {
  renderDisplayDates = () => {
    const { selectedDates, selectedOptionKey, disabled, submitting, containerProps = {}, dropdownIconProps = {} } = this.props

    if (!selectedDates || !selectedDates.begin || !selectedDates.end) {
      return null
    }

    const isDisabled = submitting || disabled

    return (
      <TouchableOpacity style={{ flex: 1, width: '100%' }} disabled={isDisabled} onPress={this._showPopover}>
        <HStack
          onLayout={this._onContainerLayout}
          w='full'
          flex={1}
          minH='9'
          px='1'
          py='0.5'
          borderWidth='1'
          borderRadius='lg'
          // borderColor={isDisabled ? 'muted.400' : 'primary.500'}
          // bg={isDisabled ? 'muted.50' : 'primary.50'}
          borderColor={isDisabled ? 'muted.400' : 'primary.100'}
          bg={isDisabled ? 'muted.200' : 'muted.50'}
          alignItems='center'
          space='0.5'
          flexWrap='wrap'
          {...containerProps}>
          {this.renderDisplayDatesLabel()}
          {this.renderDisplayDatesText()}
          {/* <View ref={sourceRef}> */}
          <Box w='7' h='full' alignItems='center' justifyContent='center'>
            {submitting ? <XSpinner size='sm' /> : <XIcon size='md' name='arrow-drop-down' family='MaterialIcons' {...dropdownIconProps} />}
          </Box>
          {/* </View> */}
        </HStack>
      </TouchableOpacity>
    )
  }

  _showPopover = () => {
    const { selectedDates, selectedOptionKey } = this.props
    this.setState({ showPopover: true, selectedDates, selectedOptionKey })
  }

  _hidePopover = () => this.setState({ showPopover: false })

  _onDateRangeSelectorChange = (params: IXDateRangeSelectorChangeParams) => {
    // console.log('_onDateRangeSelectorChange params => ', params)
    this.setState({ selectedDates: params.dates, selectedOptionKey: params.optionKey, selectedOption: params.option })
  }

  _onApply = async () => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true

    const { selectedDates, selectedOption, selectedOptionKey } = this.state
    const { onChange } = this.props

    try {
      onChange({
        dates: selectedDates,
        option: selectedOption,
        optionKey: selectedOptionKey,
      })
    } catch (err) {
      console.log('_onApply err => ', err)
    }
    await util.delay(100)
    this.inProcess = false
    this._hidePopover()
  }

  _onCancel = () => {
    const { selectedDates, selectedOptionKey } = this.props
    this.setState({ showPopover: false, selectedDates, selectedOptionKey })
    // this._hidePopover()
  }

  render() {
    const { isInitialized, showPopover } = this.state
    if (!isInitialized) {
      // return this.renderDatesSelector()
      return null
    }

    if (p.op.isWeb()) {
      return (
        <VStack w='full'>
          {this.renderDisplayDates()}
          <XOverlay visible={showPopover} onRequestClose={this._hidePopover}>
            {this.renderDatesSelector()}
          </XOverlay>
        </VStack>
      )
    }

    return (
      <VStack w='full'>
        <Popover
          mode={PopoverMode.RN_MODAL}
          // mode={PopoverMode.JS_MODAL}
          // mode={PopoverMode.TOOLTIP}
          isVisible={showPopover}
          animationConfig={{ delay: 0, duration: 0 }}
          // placement={PopoverPlacement.BOTTOM}
          placement={[PopoverPlacement.BOTTOM, PopoverPlacement.TOP]}
          popoverStyle={{
            backgroundColor: 'white',
            borderRadius: 12,
            borderWidth: 1,
            borderColor: '#888',
            overflow: 'hidden',
          }}
          // backgroundStyle={{ backfaceVisibility: 'visible', backgroundColor: 'green', opacity: 1 }}
          onRequestClose={this._hidePopover}
          from={this.renderDisplayDates()}>
          {/* <XOverlay visible={showPopover} onRequestClose={this._hidePopover}> */}
          {this.renderDatesSelector()}
          {/* </XOverlay> */}
        </Popover>
      </VStack>
    )
  }
}

export default XDateRangeSelectorButton
