import React, { useState, useEffect } from 'react'
import { View, Text, TouchableOpacity } from 'react-native'
import XIcon from 'xui/components/XIcon'

import { STYLES, COLORS } from 'x/config/styles'

interface IDevSubscriptionMonthlyCarouselView {
  // navigation: NavigationStackProp<NavigationRoute, ITimeSlotsProps>
  // webMaxScreenWidth?: number // web only
  // webNavigation?: NavigationStackProp<NavigationRoute, null> // web only
  // setPrefTimeslot: (params: ActionApiParams) => void
  // selectedStore: ISelectedStoreMap
  // profile: IProfileMap
  packageList: any
  onSelectItem: (any) => void
}

const DevSubscriptionMonthlyCarouselView: React.FC<IDevSubscriptionMonthlyCarouselView> = ({ packageList, onSelectItem }) => {
  // const [packageList, setPackageList] = useState({})
  const PACKAGE_SKU_PREFIX_BRONZE = 'PACKAGE-BRONZE-CYCLE-'
  const PACKAGE_SKU_PREFIX_SILVER = 'PACKAGE-SILVER-CYCLE-'
  const PACKAGE_SKU_PREFIX_GOLD = 'PACKAGE-GOLD-CYCLE-'
  const PACKAGE_SKU_PREFIX_PLATINUM = 'PACKAGE-PLATINUM-CYCLE-'

  const defaultSelectedPackageSkuPrefix = 'PACKAGE-GOLD-CYCLE-'
  // const [selectedPackages, setSelectedPackages] = useState([])
  const [selectedPackageSkuPrefix, setSelectedPackageSkuPrefix] = useState(PACKAGE_SKU_PREFIX_GOLD)
  const [selectedBillingCycleNum, setSelectedBillingCycleNum] = useState(12)
  const [packageName, setPackageName] = useState('')
  const [packagePrice, setPackagePrice] = useState(0.0)
  const [avgMonthlyPrice, setAvgMonthlyPrice] = useState(0.0)
  const [savingAmount, setSavingAmount] = useState(0.0)
  const [packageDataJson, setPackageDataJson] = useState({})

  // let isPackageListLoaded = false
  useEffect(() => {
    // Update the document title using the browser API
    console.log('DevSubscriptionMonthlyCarouselView.useEffect.packageList ', packageList)
    // console.log('DevSubscriptionMonthlyCarouselView.mockjson1: ', mockjson1)
    prepareMainPackageSummary(packageList)
  })

  const renderPackageList = (packageList) => {
    console.log('DevSubscriptionMonthlyCarouselView.Just in renderPackageList', packageList)
    if (packageList !== undefined && packageList !== undefined) {
      return (
        <View style={{}}>
          <Text style={{ paddingLeft: 12, fontSize: 14 }}>เลือกแพ็กเกจ </Text>
          <View
            style={{ flexDirection: 'row' }}
            // horizontal
            // alwaysBounceHorizontal
            // pagingEnabled
            // decelerationRate={0}
            // snapToInterval={200} //your element width
            // snapToAlignment={'center'}
            // style={{ backgroundColor: 'red', flex: 1 }}
          >
            {/* TODO: Change the bronze */}
            {renderPackageItem('Bronze', 'PACKAGE-BRONZE-CYCLE-')}
            {renderPackageItem('Silver', 'PACKAGE-SILVER-CYCLE-')}
            {renderPackageItem('Gold', 'PACKAGE-GOLD-CYCLE-')}
            {renderPackageItem('Platinum', 'PACKAGE-PLATINUM-CYCLE-')}

            {/* {packageList.monthly_info.map((item) => {
              // return <Text>{item.sku}</Text>
              return renderPackageItem(item)
            })} */}
          </View>

          <Text style={{ paddingLeft: 12, fontSize: 14, marginTop: 10 }}>เลือกจำนวนรอบบิล </Text>
          {renderPriceItemsVertical()}

          <View style={{ paddingLeft: 10, paddingBottom: 5 }}>
            {/* <Text style={{ fontSize: 22, fontWeight: 'bold' }}>Gold</Text> */}
            <Text style={{ fontSize: 16 }}>เหมาะกับร้านที่มียอดขายเติบโต มีแอดมินช่วยขาย และขายหลายช่องทาง เช่น Shopee </Text>
          </View>

          {renderPackageDetail()}
          {/* {renderPriceItems()} */}
          {/* <Text style={{ paddingLeft: 12, fontSize: 14 }}>เลือกรอบบิล </Text> */}
          {/* {renderChoosePkgButton('Gold', '12 เดือน (11,988 บาท)')} */}
        </View>
      )
    }
    return <Text>monthly items is null</Text>
  }

  const renderPackageDetail = () => (
    <View
      style={{
        flex: 1,
        // height: 528,
        // paddingTop: 10,
        paddingLeft: 10,
        // paddingRight: 4,
        // paddingBottom: 10,
        // backgroundColor: 'yellow',
        // borderRadius: 15,
        // marginTop: 4,
        // marginBottom: 20,
        // alignSelf: 'center',
        // opacity: 50,
      }}>
      {/* <Text style={{ fontSize: 20 }}>จุดเด่นแพ็ก Gold</Text> */}
      <Text style={{ fontSize: 18 }}>
        • ทุกอย่างที่ แพ็ก Silver ทำได้ {'\n'}• 2,000 ออเดอร์/เดือน{'\n'}• เชื่อม Shopee ได้ 3 shop{'\n'}• 5 ร้าน{'\n'}• 3 กลุ่มสมาชิก{'\n'}
        • 3 ราคา{'\n'}• 5 แอดมิน{'\n'}• ดูรายงานย้อนหลังได้ 69 วัน{'\n'}
      </Text>
    </View>
  )

  const renderPriceItemsVertical = () => (
    <View>
      <View
        style={{
          flexDirection: 'row',
        }}

        // alwaysBounceHorizontal
        // pagingEnabled
        // decelerationRate={0}
        // snapToInterval={200} //your element width
        // snapToAlignment={'center'}
        // style={{ backgroundColor: 'red', flex: 1 }}
      >
        {/* {renderBillingCycleNumBtn(1, ' 1,299 บาท', '', '')}
          {renderBillingCycleNumBtn(3, ' 3,447 บาท', '(เฉลี่ย 1,199 บาท/เดือน)', 'ประหยัด 8%')}
          {renderBillingCycleNumBtn(6, ' 6,594 บาท', '(เฉลี่ย 1,099 บาท/เดือน)', 'ประหยัด 18%')}
          {renderBillingCycleNumBtn(12, ' 11,988 บาท', '(เฉลี่ย 999 บาท/เดือน) ', 'ประหยัด 30%')} */}

        {renderBillingCycleNumBtn(1)}
        {renderBillingCycleNumBtn(3)}
        {renderBillingCycleNumBtn(6)}
        {renderBillingCycleNumBtn(12)}
      </View>
      <View style={{ marginTop: 4, paddingTop: 12, paddingBottom: 16, backgroundColor: '#FDD9C7' }}>
        <View style={{ paddingLeft: 10, marginTop: 4 }}>
          <Text style={{ fontSize: 20, fontWeight: 'bold', textAlignVertical: 'center', textAlign: 'center' }}>{packageName}</Text>
          {/* <Text style={{ fontSize: 20, fontWeight: 'bold', textAlignVertical: 'center', textAlign: 'center' }}>แพ็ก Gold {selectedBillingCycleNum} เดือน</Text> */}
        </View>
        <View style={{ flexDirection: 'row', marginTop: 0, marginLeft: 10, justifyContent: 'center' }}>
          <Text style={{ color: 'black', fontWeight: 'bold', fontSize: 24 }}>{packagePrice.toLocaleString('en-US')} บาท </Text>
        </View>
        {selectedBillingCycleNum > 1 ? (
          <View style={{ flexDirection: 'row', marginTop: -4, marginLeft: 10, justifyContent: 'center' }}>
            {/* https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript */}
            <Text style={{ color: 'grey', marginTop: 4, fontSize: 14 }}>(เฉลี่ย {avgMonthlyPrice.toLocaleString('en-US')} บาท/เดือน)</Text>
            <Text style={{ color: 'red', fontWeight: 'normal', marginTop: 2, fontSize: 16 }}>
              {' '}
              ประหยัด {savingAmount.toLocaleString('en-US')} บาท
            </Text>
          </View>
        ) : null}
        {renderChoosePkgButton()}
      </View>

      <View style={{ marginBottom: 12 }} />
    </View>
  )

  // const renderPriceItems = () => {
  //   return (
  //     <View

  //     // alwaysBounceHorizontal
  //     // pagingEnabled
  //     // decelerationRate={0}
  //     // snapToInterval={200} //your element width
  //     // snapToAlignment={'center'}
  //     // style={{ backgroundColor: 'red', flex: 1 }}
  //     >
  //       {renderPriceItem(' 1 เดือน:', ' 1,299 บาท', '', '')}
  //       {renderPriceItem(' 3 เดือน:', ' 3,447 บาท', '(เฉลี่ย 1,199 บาท/เดือน)', 'ประหยัด 8%')}
  //       {renderPriceItem(' 6 เดือน:', ' 6,594 บาท', '(เฉลี่ย 1,099 บาท/เดือน)', 'ประหยัด 18%')}
  //       {renderPriceItem('12 เดือน:', ' 11,988 บาท', '(เฉลี่ย 999 บาท/เดือน) ', 'ประหยัด 30%')}
  //     </View>
  //   )
  // }

  const renderPackageItem = (pkg, pkgSkuPrefix) => {
    let isActivePackage = false
    if (selectedPackageSkuPrefix === pkgSkuPrefix) {
      isActivePackage = true
    }
    const buttonStyle = isActivePackage ? s.activeButton : s.inactiveButton
    const buttonTextStyle = isActivePackage ? s.activeButtonText : s.inactiveButtonText
    return (
      <View
        style={{
          paddingTop: 4,
          paddingBottom: 4,
          borderRadius: 10,
          borderWidth: 1,
          margin: 8,
          width: '25%',
          height: 52,
          flex: 1,
          ...buttonStyle,
        }}>
        <TouchableOpacity
          onPress={(event) => {
            console.log(`Package ${pkg} is selected`)
            setSelectedPackageSkuPrefix(pkgSkuPrefix)
            prepareMainPackageSummary(packageList)

            // // console.log('renderPackageItem.selected: ', pkgItem.sku)
            // // add to the selected packages
            // const newlySelectedPackages = [...selectedPackages] // clone the array
            // newlySelectedPackages.push(pkgItem.sku)
            // // console.log('renderPackageItem.selectedPackages: ', selectedPackages)
            // setSelectedPackages(newlySelectedPackages)

            // // send the callback
            // onSelectItem(newlySelectedPackages)
          }}
          style={{
            height: '100%',
            justifyContent: 'center',
          }}>
          {/* <Text style={{ textAlignVertical: 'center', textAlign: 'center', fontSize: 14 }}>
            แพ็ก
          </Text> */}
          {/* const l = {
            "back": 'sdf'
          } */}
          <Text
            // @ts-ignore
            style={{
              textAlignVertical: 'center',
              textAlign: 'center',
              flex: 1,
              color: 'black',
              fontSize: 16,
              ...buttonTextStyle,
            }}>
            {pkg}
          </Text>
        </TouchableOpacity>
        {isActivePackage ? (
          <XIcon
            name='checkcircle'
            family='AntDesign'
            style={{
              position: 'absolute',
              // top: 19,
              bottom: 2,
              right: -12,
              justifyContent: 'flex-end',
              // minWidth: 25,
              marginTop: 6,
              paddingTop: 0,
              width: 25,
              // height: 24,
              // width: 150,
              color: COLORS.APP_MAIN,
              fontSize: STYLES.FONT_ICON_SMALLEST,
              backgroundColor: 'rgba(10, 196, 171, 0)',
            }}
          />
        ) : null}
      </View>
    )
  }

  const renderBillingCycleNumBtn = (billingCycleNum) => {
    const isSelectedItem = selectedBillingCycleNum === billingCycleNum
    // const isSelectedItem = billingCycleNum === '12 เดือน' ? true : false
    const buttonStyle = isSelectedItem ? s.activeButton : s.inactiveButton
    const buttonTextStyle = isSelectedItem ? s.activeButtonText : s.inactiveButtonText
    return (
      <View
        style={{
          // paddingLeft: 14,
          // paddingRight: 14,
          paddingTop: 4,
          // paddingBottom: 4,
          margin: 8,
          marginTop: 4,
          // width: '100%',
          flex: 1,
        }}>
        <TouchableOpacity
          onPress={(event) => {
            console.log(`Billing cycle ${billingCycleNum} is touched`)
            setSelectedBillingCycleNum(billingCycleNum)
            prepareMainPackageSummary(packageList)
            // // console.log('renderPackageItem.selected: ', pkgItem.sku)
            // // add to the selected packages
            // const newlySelectedPackages = [...selectedPackages] // clone the array
            // newlySelectedPackages.push(pkgItem.sku)
            // // console.log('renderPackageItem.selectedPackages: ', selectedPackages)
            // setSelectedPackages(newlySelectedPackages)

            // // send the callback
            // onSelectItem(newlySelectedPackages)
          }}
          style={{
            flexDirection: 'row',
            borderRadius: 8,
            borderColor: 'black',
            borderWidth: 1,
            // paddingTop: 8,
            // paddingBottom: 8,
            // paddingLeft: 8,
            height: 52,
            justifyContent: 'center',
            backgroundColor: 'white',
            ...buttonStyle,
          }}>
          <Text
            // @ts-ignore
            style={{ textAlignVertical: 'center', textAlign: 'center', fontSize: 16, color: 'black', ...buttonTextStyle }}>
            {billingCycleNum} เดือน
          </Text>
          {/* <Text style={{ textAlignVertical: 'center', textAlign: 'center', fontSize: 14, marginTop: 0, marginLeft: 4, color: 'black', ...buttonTextStyle }}>
            เดือน
          </Text> */}
          {/* <Text style={{ textAlignVertical: 'center', textAlign: 'center', paddingRight: 4, fontSize: 20 }}>
            {monthlyCostText}
          </Text> */}
          {isSelectedItem ? (
            <XIcon
              name='checkcircle'
              family='AntDesign'
              style={{
                position: 'absolute',
                // top: 19,
                bottom: 2,
                right: -12,
                justifyContent: 'flex-end',
                // minWidth: 25,
                marginTop: 6,
                paddingTop: 0,
                width: 25,
                // height: 24,
                // width: 150,
                color: COLORS.APP_MAIN,
                fontSize: STYLES.FONT_ICON_SMALLEST,
                backgroundColor: 'rgba(10, 196, 171, 0)',
              }}
            />
          ) : null}
        </TouchableOpacity>
        {/* {isSelected ? (
          <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
            <Text style={{ color: 'red', fontWeight: 'bold' }}>{percentSavingText} </Text>
            <Text>{monthlyCostText}</Text>
          </View>
        ) : null} */}
      </View>
    )
  }

  // const renderChoosePkgButton = (billingCycleNum, totalAmountText) => {
  const renderChoosePkgButton = () => (
    <View
      style={{
        // paddingLeft: 14,
        // paddingRight: 14,
        paddingTop: 4,
        // paddingBottom: 4,
        margin: 8,
        marginTop: 4,
        // width: '100%',
        flex: 1,
      }}>
      <TouchableOpacity
        onPress={(event) => {
          console.log('item is touched')
          // // console.log('renderPackageItem.selected: ', pkgItem.sku)
          // // add to the selected packages
          // const newlySelectedPackages = [...selectedPackages] // clone the array
          // newlySelectedPackages.push(pkgItem.sku)
          // // console.log('renderPackageItem.selectedPackages: ', selectedPackages)
          // setSelectedPackages(newlySelectedPackages)

          // // send the callback
          // onSelectItem(newlySelectedPackages)
        }}
        style={{
          flexDirection: 'row',
          borderRadius: 10,
          // borderWidth: 1,
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 8,
          height: 44,
          justifyContent: 'center',
          backgroundColor: '#F36938',
        }}>
        <Text
          style={{ textAlignVertical: 'center', textAlign: 'center', paddingRight: 4, fontSize: 20, fontWeight: 'bold', color: 'white' }}>
          {/* เลือก {billingCycleNum} {totalAmountText} */}
          {/* เลือก แพ็ก {billingCycleNum} 12 เดือน */}
          อัพเกรด
        </Text>
        {/* <Text style={{ textAlignVertical: 'center', textAlign: 'center', paddingRight: 4, fontSize: 20 }}>
            {monthlyCostText}
          </Text> */}
      </TouchableOpacity>
      {/* <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
          <Text style={{ color: 'red', fontWeight: 'bold' }}>{percentSavingText} </Text>
          <Text>{monthlyCostText}</Text>
        </View> */}
    </View>
  )

  const prepareMainPackageSummary = (packageList) => {
    if (packageList === null || typeof packageList === 'undefined') {
      return
    }
    console.log('prepareMainPackageSummary.packageList', packageList)

    const { items } = packageList
    if (items === null || typeof items === 'undefined') {
      return
    }
    console.log('prepareMainPackageSummary.items', items)
    // find the selected item based on the selected package and billingCycleNum
    const oneBillingCycleSku = selectedPackageSkuPrefix + 1
    const selectedSku = selectedPackageSkuPrefix + selectedBillingCycleNum

    // find the base price of one month
    let oneMonthPrice = 0
    let pkgName = ''
    let pkgDataJson = {}
    for (const item of items) {
      if (item.sku === oneBillingCycleSku && item.item_type === 'package_main') {
        oneMonthPrice = item.price_with_vat
        pkgName = item.name
        pkgDataJson = items.data_json
        break
      }
    }
    if (selectedBillingCycleNum === 1) {
      setPackagePrice(oneMonthPrice)
      setAvgMonthlyPrice(0.0)
      setSavingAmount(0)
    } else {
      // 3 6 or 12 months
      for (const item of items) {
        if (item.sku === selectedSku && item.item_type === 'package_main') {
          pkgName = item.name
          pkgDataJson = items.data_json
          setPackagePrice(item.price_with_vat)
          const monthlyRate = Math.round(item.price_with_vat / selectedBillingCycleNum)
          setAvgMonthlyPrice(monthlyRate)
          // const SavingAmountNum = Math.round(((oneMonthPrice - monthlyRate)/oneMonthPrice) * 100)
          const SavingAmountNum = Math.round((oneMonthPrice - monthlyRate) * selectedBillingCycleNum)
          setSavingAmount(SavingAmountNum)
          break
        }
      }
    }
    setPackageName(pkgName)
    setPackageDataJson(pkgDataJson)
  }

  const renderPriceItem = (billingCycleNum, totalAmountText, monthlyCostText, percentSavingText) => (
    <View
      style={{
        // paddingLeft: 14,
        // paddingRight: 14,
        paddingTop: 4,
        // paddingBottom: 4,
        margin: 8,
        marginTop: 4,
        // width: '100%',
        flex: 1,
      }}>
      <TouchableOpacity
        onPress={(event) => {
          console.log('item is touched')
          // // console.log('renderPackageItem.selected: ', pkgItem.sku)
          // // add to the selected packages
          // const newlySelectedPackages = [...selectedPackages] // clone the array
          // newlySelectedPackages.push(pkgItem.sku)
          // // console.log('renderPackageItem.selectedPackages: ', selectedPackages)
          // setSelectedPackages(newlySelectedPackages)

          // // send the callback
          // onSelectItem(newlySelectedPackages)
        }}
        style={{
          flexDirection: 'row',
          borderRadius: 10,
          // borderWidth: 1,
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 8,
          height: 44,
          justifyContent: 'center',
          backgroundColor: '#F36938',
        }}>
        <Text
          style={{ textAlignVertical: 'center', textAlign: 'center', paddingRight: 4, fontSize: 20, fontWeight: 'bold', color: 'white' }}>
          {billingCycleNum} {totalAmountText}
        </Text>
        {/* <Text style={{ textAlignVertical: 'center', textAlign: 'center', paddingRight: 4, fontSize: 20 }}>
            {monthlyCostText}
          </Text> */}
      </TouchableOpacity>
      <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
        <Text style={{ color: 'red', fontWeight: 'bold' }}>{percentSavingText} </Text>
        <Text>{monthlyCostText}</Text>
      </View>
    </View>
  )

  const s = {
    textField: { fontSize: 18 },
    packageName: {
      paddingTop: 4,
      paddingLeft: 4,
    },
    selectedPackageName: {
      color: 'orange',
    },
    notSelectedPackageName: {
      color: 'grey',
    },
    activeButton: {
      // backgroundColor: '#FDD9C8',
      borderColor: '#F36938',
      backgroundColor: 'white',
      borderWidth: 1,
    },
    inactiveButton: {
      backgroundColor: 'white',
    },
    activeButtonText: {
      color: '#F36938',
      fontWeight: 'bold',
    },
    inactiveButtonText: {
      fontWeight: 'normal',
    },
  }

  return renderPackageList(packageList)
}

export default DevSubscriptionMonthlyCarouselView
