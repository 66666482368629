import React from 'react'
import { View, Text } from 'react-native'
import BaseUIResellerApprovalListView from 'xui/modules/reseller/BaseUIResellerApprovalListView'
import Modal from 'react-modal'
import { COLORS, STYLES } from 'x/config/styles'
import { Button } from 'react-native-elements'

import { IOrgPendingList } from 'x/modules/reseller/BaseResellerApprovalListView'
import ThemeSizeContext from '../../context/ThemeSizeContext'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'

export default class ResellerApprovalListView extends BaseUIResellerApprovalListView {
  static contextType = ThemeSizeContext

  context!: React.ContextType<typeof ThemeSizeContext>

  navToCustomer = (item: IOrgPendingList) => {
    const { navigation } = this.props
    navigation.push('CustomerViewContainer', {
      profile: item,
      key: item.i,
      // x: Math.random(),
    })
  }

  openModelUploading = () => {
    const customStyles = {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: 340,
      height: 150,
    }
    return (
      <Modal
        isOpen={this.state.isModelUploading}
        // onAfterOpen={this.afterOpenModal}
        onRequestClose={this._closeModalUploading}
        style={{ content: customStyles }}
        contentLabel='Example Modal'>
        <View style={{ width: '100%', justifyContent: 'center', alignItems: 'center', height: 150 }}>
          <Text
            style={{
              fontSize: STYLES.FONT_SIZE_NORMAL,
              color: COLORS.TEXT_ACTIVE,
            }}>
            กำลังอนุมัติตัวแทน... กรุณาอย่าปิดหน้าต่างนี้
          </Text>
          <Text style={{ fontSize: STYLES.FONT_SIZE_SMALLER, color: COLORS.TEXT_ACTIVE }}>
            (ระบบอาจใช้เวลาประมาณ 40 วินาที ต่อ 1 บัญชี)
          </Text>
          <Button
            // title="Loading button"
            loading
            disabled
            type='clear'
          />
        </View>
      </Modal>
    )
  }

  renderMain = () => {
    // ถ้าจำเป็นต้องใช้ layout constant เอาตรงนี้
    const { contentOneOfThreeColumnWidth } = this.context

    return (
      <XContainer style={{ width: contentOneOfThreeColumnWidth }}>
        {this._renderSearchBody()}
        <XContent scrollEnabled contentContainerStyle={{ width: contentOneOfThreeColumnWidth }} style={{ backgroundColor: 'white' }}>
          {this._renderFlatList()}
        </XContent>
      </XContainer>
    )
  }
}
