// global colors
import { Platform, Dimensions, StyleSheet } from 'react-native'
import CONS from './constants'
// import p from './platform-specific'

export const COLORS = {
  // MY_STORE_MAIN: '#0a73ff',
  // MY_STORE_MAIN_INVERSE: '#65aff6',
  // SELLER_STORE_MAIN: '#efae50',
  // SELLER_STORE_MAIN_INVERSE: '#AD6C12',
  // SUCCESS_MAIN: '#60bc5c',
  // RED_BADGE: '#d74e50',
  // TEXT_DEACTIVE: '#eaeaea',

  // 61. Muted & Antique
  // MY_STORE_MAIN: '#A4CABC',
  // MY_STORE_MAIN_INVERSE: '#65aff6',
  // SELLER_STORE_MAIN: '#EAB364',
  // SELLER_STORE_MAIN_INVERSE: '#AD6C12',
  // SUCCESS_MAIN: '#ACBD78',
  // // RED_BADGE: '#B2473E',
  // // TEXT_DEACTIVE: '#eaeaea',
  // TEXT_ACTIVE: 'white',
  // // TEXT_ACTIVE: '#6b6b6d',
  // TEXT_INACTIVE: '#eaeaea',

  // 81 Warm & Cool
  // MY_STORE_MAIN: '#444c5c',
  // MY_STORE_MAIN_INVERSE: '#65aff6',
  // SELLER_STORE_MAIN: '#e1b16a',
  // SELLER_STORE_MAIN_INVERSE: '#AD6C12',
  // SUCCESS_MAIN: '#78a5a3',
  // RED_BADGE: '#B2473E',
  // TEXT_DEACTIVE: '#eaeaea',

  // 100 Trendy & Modern
  // MY_STORE_MAIN: '#488a99',
  // MY_STORE_MAIN_INVERSE: '#65aff6',
  // SELLER_STORE_MAIN: '#dbae58',
  // SELLER_STORE_MAIN_INVERSE: '#AD6C12',
  // SUCCESS_MAIN: '#b4b4b4',
  // RED_BADGE: '#B2473E',
  // TEXT_DEACTIVE: '#eaeaea',

  // Saffron Avenue
  // MY_STORE_MAIN: '#f6dcce',
  // MY_STORE_MAIN_INVERSE: '#ec828b',
  // SELLER_STORE_MAIN: '#c5beb8',
  // SELLER_STORE_MAIN_INVERSE: '#ec828b',
  // SUCCESS_MAIN: '#a9ccce',
  // // SUCCESS_MAIN: '#BBC5AA',
  // RED_BADGE: '#ec828b',
  // TEXT_ACTIVE: '#29292C',
  // TEXT_INACTIVE: '#89898B',

  APP_MAIN: '#f46938',
  SECONDARY_MAIN: 'rgba(14,74,121,1)',
  // APP_MAIN: 'black',
  APP_SECONDARY: '#fcd9c7',

  ORDER_LIST_ITEM_BG_PURCHASE: '#ffffff',
  ORDER_LIST_ITEM_BG_SALES: '#ffeedc',
  ORDER_LIST_ITEM_BG_HIGHLIGHTED: '#fff6ed',

  // APP_SECONDARY: 'grey',
  NAV_BUTTON_ACTIVE: '#007afa',
  // MY_STORE_MAIN: '#f46938',
  // MY_STORE_MAIN: 'black',
  MY_STORE_MAIN: '#f6dcce',
  MY_STORE_MAIN_INVERSE: '#ec828b',
  // SELLER_STORE_MAIN: '#F4D06F',
  // SELLER_STORE_MAIN: '#79ADDC',
  // RESELLER_STORE_MAIN: '#92AFD7',
  RESELLER_STORE_MAIN: '#ADEEE3',
  // SELLER_STORE_MAIN: '#B0D0D3',
  SELLER_STORE_MAIN: '#FCE694',
  // SELLER_STORE_MAIN: '#F0B67F',

  //  SELLER_STORE_MAIN: '#C08497',
  SELLER_STORE_MAIN_INVERSE: '#ec828b',
  SUCCESS_MAIN: '#BAD9B5',
  // SUCCESS_MAIN: '#96BE8C',

  // SUCCESS_MAIN: '#BBC5AA',
  RED_BADGE: '#ec828b',
  // TEXT_ACTIVE: '#29292C',
  TEXT_ACTIVE: '#000',
  // TEXT_ACTIVE: '#6b6b6d',
  TEXT_INACTIVE: '#89898B',

  TEXT_ACTIVE_DARK: '#eaeaea',
  // TEXT_PRIMARY: 'rgba(21,126,251,1)',
  TEXT_PRIMARY: '#f46938',
  // TEXT_PRIMARY: 'black',

  FORM_PRIMARY: '#f46938', // 'rgba(21,126,251,1)',
  // FORM_PRIMARY: 'black',// 'rgba(21,126,251,1)',
  // FORM_PRIMARY_BG: 'rgba(207,242,255,1)', //cff2ff
  // FORM_PRIMARY_BG: '#ffdaca', // 988
  FORM_PRIMARY_BG: '#ffeedc', // '#ffe9cf',// '#ffedcf', //ffe6cf
  // FORM_PRIMARY_BG: '#DDDDDD',//'#ffe9cf',// '#ffedcf', //ffe6cf
  FORM_ERROR: 'rgba(237,83,85,1)',
  FORM_ERROR_OPACITY: 'rgba(237,83,85,0.3)',
  FORM_SUCCESS: '#63A375', // '#32965D',//'#63A375', //#8AC926 //'rgba(80,150,92,1)',
  FORM_INACTIVE: 'rgba(224,224,225,1)',

  // FORM_ERROR_OPACITY: 'rgba(237,83,85,0.3)',
  // FORM_SUCCESS_OPACITY: 'rgba(80,150,92,0.3)',
  FORM_ERROR_BG: 'rgba(232,179,185,1)',
  FORM_SUCCESS_BG: '#C7EFCF',
  WHITE: '#fff',
  // FORM_SUCCESS_BG: 'rgba(179,201,187,1)',
  // FORM_WARNING_BG: 'rgba(252,230,148,1)',
  FORM_WARNING_BG: '#ffdaca',
  // FORM_WARNING_BG: '#DDDDDD',

  BOX_SUCCESS: 'rgba(109,253,110,1)',
  // TEXT_INACTIVE: '#4D4D4F',
  // TEXT_INACTIVE: '#6b6b6d',

  TIMER_NORMAL_DUE: '#32965D', // '#63A375', //'green',
  TIMER_ALMOST_DUE: 'orange',
  TIMER_OVER_DUE: 'red',

  BG_LIGHT_GREY: '#f6f6f6',
  BG_LIGHT_GREY_ALTERNATIVE: '#F0EFF5',

  // Copied from NativeBase variable
  // BRAND_Primary: (Platform.OS === 'ios') ? '#007aff' : '#3F51B5',
  BRAND_Primary: '#007aff',
  BRAND_Info: '#62B1F6',
  BRAND_Success: '#5cb85c',
  BRAND_Danger: '#d9534f',
  BRAND_Warning: '#f0ad4e',
  BRAND_Sidebar: '#252932',

  YELLOW_TAB: '#feffc8',
  BLUE_TAB: '#c9e6f7',
  // BLUE_FACEBOOK: '#5073bb',
  BLUE_FACEBOOK: 'rgba(59,87,157,1)',
  COD_COLOR: '#1E91D6',
  BLACK: '#000',
  BG_SOFT_GREY: '#F7F7F7',
  GREY: '#ccc',

  GB_PAY_MAIN: '#004071',
  SCB_MAIN: '#462279',
  BEAM_MAIN: '#061145',

  WEBSITE_PRODUCT_SET_MODE: '#a16207',
  WEBSITE_PRODUCT_MULTI_OPTIONS_MODE: '#3b82f7',

  SUMMARY_ORDER_DONUT_COLOR_SCALE: {
    // myTasks_confirmOrder: '#ed4a7b',
    // myTasks_pay: '#2f6497',
    // myTasks_confirmGettingPaid: '#13a4b4',
    // myTasks_ship: '#bf399e',
    //
    // myTasks_paySalesOrdersPaid: '#525df4',
    // myTasks_payOther: '#945ecf',

    myTasks_confirmOrder: '#1E91D6',
    myTasks_pay: '#EF709D',
    // myTasks_confirmGettingPaid: '#FF715B',
    // myTasks_ship: '#FCAB10',
    myTasks_confirmGettingPaid: '#a9611b',
    // myTasks_ship: '#EDC976',
    // myTasks_ship: '#EAC435',
    // myTasks_ship: '#e4c014',
    // myTasks_ship: '#f4b000',
    myTasks_ship: '#F5BB00',

    myTasks_paySalesOrdersPaid: '#F8333C',
    myTasks_payOther: '#801A86',
    isHighlight: '#fcff00',
  },
  GREEN: '#0c9400',
  GREEN_SOFT: '#eaffbf',
  ORANGE: '#ffa20a',
  ORANGE_SOFT: '#fff9c9',
  RED: '#ff1f2c',
  RED_SOFT: '#ffd9d4',
  BROWN: '#b48400',
  BG_GREY_XSHIPPING_BTN: ' #f2f4f4 ',

  BG_WARNING_STORE_WEBSITE: '#ffeecf',

  BG_GREY_PACKAGE: '#e5e5e5',
}

const FONT_SIZE_JUMBOTRON = 80
const FONT_ICON_LARGEST = 30
const FONT_ICON_LARGER = 26
const FONT_ICON_NORMAL = 22
const FONT_ICON_SMALLER = 18
const FONT_ICON_SMALLEST = 14
const FONT_SIZE_HUGE = 20
const FONT_SIZE_LARGER = 14
const FONT_SIZE_LARGER_2 = 16
const FONT_SIZE_LARGER_3 = 18
const FONT_SIZE_NORMAL = 12
const FONT_SIZE_SMALLER = 10
const FONT_SIZE_SMALLEST = 8
const FONT_SIZE_ORDERS_HEADER_ROW2 = 14
const FONT_SIZE_ORDERS_HEADER_ROW3 = 12
// const ORDERS_HEADER_HEIGHT_ROW1 = 40 + 15 // 15 is padding
const ORDERS_HEADER_HEIGHT_ROW1 = 40
const ORDERS_HEADER_HEIGHT_ROW2 = 38
const ORDERS_HEADER_HEIGHT_ROW3 = Platform.OS === 'web' ? 48 : 34
const ORDERS_HEADER_MARGIN_TOP = -7

export const STYLES = {
  FONT_SIZE_JUMBOTRON,
  FONT_ICON_LARGEST,
  FONT_ICON_LARGER,
  FONT_ICON_NORMAL,
  FONT_ICON_SMALLER,
  FONT_ICON_SMALLEST,
  FONT_SIZE_HUGE,
  FONT_SIZE_LARGER,
  FONT_SIZE_LARGER_2,
  FONT_SIZE_LARGER_3,
  FONT_SIZE_NORMAL,
  FONT_SIZE_SMALLER,
  FONT_SIZE_SMALLEST,
  FONT_SIZE_ORDERS_HEADER_ROW2,
  FONT_SIZE_ORDERS_HEADER_ROW3,
  ORDERS_HEADER_HEIGHT_ROW1,
  ORDERS_HEADER_HEIGHT_ROW2,
  ORDERS_HEADER_HEIGHT_ROW3,
  ORDERS_HEADER_MARGIN_TOP,

  BTN_WIDTH_SMALL: 30,
  BTN_HEIGHT_SMALL: 30,

  NUMBER_PADDING_RIGHT: 2,
  NUMBER_PADDING_RIGHT_PLUS_1: 3,
  NUMBER_PADDING_RIGHT_PLUS_2: 4,

  MKP_ICON_SIZE_NORMAL: 30,

  HEADER_BUTTON_TEXT_MAIN: {
    paddingTop: 10,
    height: 38,
    color: COLORS.APP_MAIN,
    // backgroundColor:'red',
  },
  HEADER_BUTTON_TEXT_CANCEL: {
    paddingTop: 10,
    height: 38,
    color: COLORS.TEXT_INACTIVE,
    // backgroundColor:'red',
  },
  NO_PADDING: {
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
  },
  NO_PADDING_TOP: {
    paddingTop: 0,
  },
  NO_PADDING_BOTTOM: {
    paddingBottom: 0,
  },
  NO_MARGIN: {
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
  },
  NO_MARGIN_TOP: {
    marginTop: 0,
  },
  NO_MARGIN_BOTTOM: {
    marginBottom: 0,
  },
  NO_MARGIN_PADDING: {
    flexBasis: 'auto',
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
  },
  COLOR_DONE: {
    color: COLORS.FORM_SUCCESS,
  },
  COLOR_UNCOMMON: {
    color: COLORS.FORM_ERROR,
  },
  COLOR_COMMON: {
    color: COLORS.FORM_PRIMARY,
  },
  BTN_BASE: {
    borderRadius: 10,
    borderWidth: 2,
    height: '90%',
    margin: 2,
    paddingLeft: 2,
    paddingRight: 2,
  },
  BTN_MODE_ACTIVE_PRIMARY: {
    borderColor: COLORS.FORM_PRIMARY,
    backgroundColor: COLORS.FORM_PRIMARY,
  },
  BTN_MODE_ACTIVE_SECONDARY: {
    borderColor: COLORS.FORM_PRIMARY,
    backgroundColor: COLORS.BG_LIGHT_GREY,
  },
  BTN_MODE_ACTIVE_DANGER: {
    borderColor: COLORS.BRAND_Danger,
    backgroundColor: COLORS.BRAND_Danger,
  },
  BTN_MODE_INACTIVE: {
    borderColor: COLORS.FORM_INACTIVE,
    backgroundColor: COLORS.BG_LIGHT_GREY,
  },
  BTN_MODE_ACTIVE_BLUE_SECONDARY: {
    borderColor: COLORS.SECONDARY_MAIN,
    backgroundColor: COLORS.BG_LIGHT_GREY,
  },
  BTN_TEXT_MODE_ACTIVE_PRIMARY: {
    fontWeight: 'bold',
    fontSize: 16,
    color: COLORS.TEXT_ACTIVE_DARK,
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 2,
    width: '100%',
    // height: 35,
    // backgroundColor: 'blue',
    textAlign: 'center',
    // justifyContent: 'center',
    // alignSelf: 'center',
    // alignItems: 'center',

    // ...Platform.select({
    //   android: {
    //     // marginTop: -2,
    //   },
    //   ios: {
    //     paddingTop: 2,
    //     marginTop: 2,
    //   },
    // }),
    // FIXME: ยังหาวิธีใช้ p.op ตรงนี้ไม่เจอ
    // paddingTop: p.op.isIOS() ? 2 : undefined,
    // marginTop: p.op.isAndroid() ? -2 : 2,
  },
  BTN_TEXT_MODE_ACTIVE_BLUE_SECONDARY: {
    fontWeight: 'bold',
    fontSize: FONT_SIZE_LARGER,
    color: COLORS.SECONDARY_MAIN,
    paddingLeft: 0,
    paddingRight: 0,
    width: '100%',
    textAlign: 'center',
  },
  BTN_TEXT_MODE_ACTIVE_SECONDARY: {
    fontWeight: 'bold',
    fontSize: FONT_SIZE_LARGER,
    color: COLORS.FORM_PRIMARY,
    paddingLeft: 0,
    paddingRight: 0,
    width: '100%',
    textAlign: 'center',
  },
  BTN_TEXT_MODE_INACTIVE: {
    fontSize: 10,
    color: 'grey',
    paddingLeft: 0,
    paddingRight: 0,
    width: '100%',
    textAlign: 'center',
  },
  BTN_ICON_STYLE: {
    width: 40,
    height: 25,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
  },
  HEADER_BG: {
    backgroundColor: 'white',
  },
  FOOTER_BG: {
    backgroundColor: COLORS.BG_LIGHT_GREY,
  },
  RIGHT_ARROW_ICON: {
    fontSize: 20,
  },
  CARD_COMMON: {
    flexWrap: 'nowrap',
  },
  FLEX_ROW_CENTER: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  TXT_HUGE_PRIMARY: {
    fontSize: FONT_SIZE_HUGE,
    color: COLORS.TEXT_PRIMARY,
    fontWeight: 'bold',
  },
  TXT_LARGER_2_BOLD: {
    fontSize: FONT_SIZE_LARGER_2,
    color: 'black',
    fontWeight: 'bold',
  },
  TXT_LARGER_3_BOLD: {
    fontSize: FONT_SIZE_LARGER_3,
    color: 'black',
    fontWeight: 'bold',
  },
  TXT_LARGER_BOLD: {
    fontSize: FONT_SIZE_LARGER,
    color: 'black',
    fontWeight: 'bold',
  },
  TXT_LARGER_PRIMARY: {
    fontSize: FONT_SIZE_LARGER,
    color: COLORS.TEXT_PRIMARY,
    fontWeight: 'bold',
  },
  TXT_LARGER: {
    fontSize: FONT_SIZE_LARGER,
    color: 'black',
  },
  TXT_NORMAL_BOLD: {
    fontSize: FONT_SIZE_NORMAL,
    color: 'black',
    fontWeight: 'bold',
  },
  TXT_NORMAL: {
    fontSize: FONT_SIZE_NORMAL,
    color: 'black',
  },
  TXT_NORMAL_PRIMARY: {
    fontSize: FONT_SIZE_NORMAL,
    color: COLORS.TEXT_PRIMARY,
  },
  TXT_SMALLER: {
    fontSize: FONT_SIZE_SMALLER,
    color: 'black',
  },
  TXT_SMALLER_BOLD: {
    fontSize: FONT_SIZE_SMALLER,
    color: 'black',
    fontWeight: 'bold',
  },
  TXT_SMALLEST: {
    fontSize: FONT_SIZE_SMALLEST,
    color: 'black',
  },
  TXT_LABEL_LARGER: {
    fontSize: FONT_SIZE_LARGER,
    color: COLORS.TEXT_INACTIVE,
  },
  TXT_LABEL: {
    fontSize: FONT_SIZE_NORMAL,
    color: COLORS.TEXT_INACTIVE,
  },
  TXT_LABEL_BOLD: {
    fontSize: FONT_SIZE_NORMAL,
    color: COLORS.TEXT_INACTIVE,
    fontWeight: 'bold',
  },
  TXT_LABEL_SMALLER: {
    fontSize: FONT_SIZE_SMALLER,
    color: COLORS.TEXT_INACTIVE,
  },
  TXT_LABEL_SMALLER_BOLD: {
    fontSize: FONT_SIZE_SMALLER,
    color: COLORS.TEXT_INACTIVE,
    fontWeight: 'bold',
  },
  TXT_LABEL_SMALLEST: {
    fontSize: FONT_SIZE_SMALLEST,
    color: COLORS.TEXT_INACTIVE,
  },
}

export function textOnBtnMode(mode) {
  switch (mode) {
    case CONS.BTN_MODE.ACTIVE_PRIMARY: {
      return STYLES.BTN_TEXT_MODE_ACTIVE_PRIMARY
    }
    case CONS.BTN_MODE.ACTIVE_SECONDARY: {
      return STYLES.BTN_TEXT_MODE_ACTIVE_SECONDARY
    }
    case CONS.BTN_MODE.ACTIVE_BLUE_SECONDARY: {
      return STYLES.BTN_TEXT_MODE_ACTIVE_BLUE_SECONDARY
    }
    case CONS.BTN_MODE.INACTIVE: {
      return STYLES.BTN_TEXT_MODE_INACTIVE
    }
    default:
      return {}
  }
}

export function btnOnMode(mode) {
  switch (mode) {
    case CONS.BTN_MODE.ACTIVE_PRIMARY: {
      return { ...STYLES.BTN_BASE, ...STYLES.BTN_MODE_ACTIVE_PRIMARY }
    }
    case CONS.BTN_MODE.ACTIVE_SECONDARY: {
      return { ...STYLES.BTN_BASE, ...STYLES.BTN_MODE_ACTIVE_SECONDARY }
    }
    case CONS.BTN_MODE.INACTIVE: {
      return { ...STYLES.BTN_BASE, ...STYLES.BTN_MODE_INACTIVE }
    }
    case CONS.BTN_MODE.ACTIVE_BLUE_SECONDARY: {
      return { ...STYLES.BTN_BASE, ...STYLES.BTN_MODE_ACTIVE_BLUE_SECONDARY }
    }
    default:
      return {}
  }
}

// react-native-elements theme
export const RNE_THEME = {
  colors: {
    primary: COLORS.APP_MAIN,
    secondary: COLORS.APP_SECONDARY,
    grey0: '#393e42',
    grey1: '#43484d',
    grey2: '#5e6977',
    grey3: '#86939e',
    grey4: '#bdc6cf',
    grey5: COLORS.BG_LIGHT_GREY,
    // grey5: '#e1e8ee',
    greyOutline: '#bbb',
    searchBg: COLORS.APP_MAIN,
    success: COLORS.BRAND_Success,
    error: COLORS.BRAND_Danger,
    warning: COLORS.BRAND_Warning,
    divider: COLORS.APP_MAIN,
    platform: {
      ios: {
        // primary: COLORS.APP_MAIN,
        // secondary: COLORS.APP_SECONDARY,
        // success: COLORS.BRAND_Success,
        // error: COLORS.BRAND_Danger,
        // warning: COLORS.BRAND_Warning,
      },
      android: {
        // Same as ios
      },
    },
  },
}

function computeProductListItemWidthByDeviceWidth() {
  let expectedMinCol = 2 // expectedMinimumColoumn

  if (Platform.OS === 'web') {
    const singleColumnWidth = Math.floor(CONS.SPECIFIC_PLATFORM.WEB.MAIN_CONTENT_WIDTH / 3)
    return Math.floor(singleColumnWidth / expectedMinCol) - CONS.SPECIFIC_PLATFORM.WEB.SCROLLBAR_WIDTH
  }
  const appDeviceWidth = Dimensions.get('screen').width || 320

  if (appDeviceWidth > 1400) {
    return 240
  }

  if (appDeviceWidth <= 400) {
    // expectedMinCol = 2
  } else if (appDeviceWidth > 400 && appDeviceWidth <= 500) {
    // expectedMinCol = 2
  } else if (appDeviceWidth > 600 && appDeviceWidth <= 900) {
    expectedMinCol = 3
  } else if (appDeviceWidth > 900 && appDeviceWidth <= 1100) {
    expectedMinCol = 4
  } else if (appDeviceWidth > 1100 && appDeviceWidth <= 1400) {
    expectedMinCol = 5
  }
  return Math.floor(appDeviceWidth / expectedMinCol)
}

export const PRODUCT_LIST_ITEM_WIDTH = computeProductListItemWidthByDeviceWidth()

export const S = StyleSheet.create({
  // Common Text Styles
  TEXT_ACTIVE: {
    color: COLORS.TEXT_ACTIVE,
    fontSize: STYLES.FONT_SIZE_NORMAL,
  },
  TEXT_ACTIVE_DARK: {
    color: COLORS.TEXT_ACTIVE_DARK,
    fontSize: STYLES.FONT_SIZE_NORMAL,
  },
  TEXT_INACTIVE: {
    color: COLORS.TEXT_INACTIVE,
    fontSize: STYLES.FONT_SIZE_NORMAL,
  },
  TEXT_PRIMARY: {
    color: COLORS.APP_MAIN,
    fontSize: STYLES.FONT_SIZE_NORMAL,
  },
  TEXT_SUCCESS: {
    color: COLORS.BRAND_Success,
    fontSize: STYLES.FONT_SIZE_NORMAL,
  },
  TEXT_BLUE: {
    color: COLORS.SECONDARY_MAIN,
    fontSize: STYLES.FONT_SIZE_NORMAL,
  },
  TEXT_DANGER: {
    color: COLORS.BRAND_Danger,
    fontSize: STYLES.FONT_SIZE_NORMAL,
  },
  TEXT_ICON_ACTIVE: {
    color: COLORS.TEXT_ACTIVE,
    fontSize: STYLES.FONT_ICON_NORMAL,
  },
  TEXT_ICON_ACTIVE_DARK: {
    color: COLORS.TEXT_ACTIVE_DARK,
    fontSize: STYLES.FONT_ICON_NORMAL,
  },
  TEXT_ICON_INACTIVE: {
    color: COLORS.TEXT_INACTIVE,
    fontSize: STYLES.FONT_ICON_NORMAL,
  },
  TEXT_ICON_PRIMARY: {
    color: COLORS.APP_MAIN,
    fontSize: STYLES.FONT_ICON_NORMAL,
  },
  TEXT_ICON_SUCCESS: {
    color: COLORS.BRAND_Success,
    fontSize: STYLES.FONT_ICON_NORMAL,
  },
  TEXT_ICON_DANGER: {
    color: COLORS.BRAND_Danger,
    fontSize: STYLES.FONT_ICON_NORMAL,
  },
  TEXT_STRIKETHROUGH: {
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
  },
  // Common Text Size
  TEXT_SMALL: {
    fontSize: STYLES.FONT_SIZE_SMALLER,
  },
  TEXT_LARGER: {
    fontSize: STYLES.FONT_SIZE_LARGER,
  },
  TEXT_LARGER_2: {
    fontSize: STYLES.FONT_SIZE_LARGER_2,
  },
  TEXT_LARGER_3: {
    fontSize: STYLES.FONT_SIZE_LARGER_3,
  },
  TEXT_ICON_SMALLER: {
    fontSize: STYLES.FONT_ICON_SMALLER,
  },
  TEXT_ICON_SMALLEST: {
    fontSize: STYLES.FONT_ICON_SMALLEST,
  },
  TEXT_ICON_LARGER: {
    fontSize: STYLES.FONT_ICON_LARGER,
  },
  TEXT_ICON_LARGER_2: {
    fontSize: STYLES.FONT_ICON_LARGEST,
  },

  // Common Text Align
  TEXT_ALIGN_CENTER: {
    textAlign: 'center',
  },
  TEXT_ALIGN_VERTICAL_CENTER: {
    textAlignVertical: 'center',
  },
  TEXT_ALIGN_RIGHT: {
    textAlign: 'right',
  },

  // Common Text Weight (font weight)
  TEXT_BOLD: {
    fontWeight: 'bold',
  },

  // Others
  PRODUCT_LIST_ITEM_CONTAINER_LISTMODE: {
    flexBasis: 'auto',
    // height: 300,
    width: '100%',
    marginBottom: 8,
    padding: 8,
    flexDirection: 'column',
    backgroundColor: 'white',
  },
  PRODUCT_LIST_ITEM_CONTAINER_GRIDMODE: {
    // // flex: 1,
    // flexBasis: 'auto',
    maxWidth: PRODUCT_LIST_ITEM_WIDTH,
    // height: 300,
    width: PRODUCT_LIST_ITEM_WIDTH,
    // width: '100%',
    paddingTop: 4,
    // marginLeft: 4,
    paddingBottom: 6,
    // // marginRight: 4,
    // // padding: 8,
    // // borderWidth: 0.5,
    // // borderBottomWidth: 1,
    // // borderRightWidth: 1,
    // flexDirection: 'column',
    // // backgroundColor: 'white',
    // alignSelf: 'flex-start',
  },
  PRODUCT_LIST_ITEM_IMAGE_GRIDMODE: {
    minHeight: PRODUCT_LIST_ITEM_WIDTH - 16,
    width: '100%',
  },
  BG_WHITE: {
    backgroundColor: COLORS.WHITE,
  },
  BG_GREY: {
    backgroundColor: COLORS.GREY,
  },
  BG_LIGHT_GREY: {
    backgroundColor: COLORS.BG_LIGHT_GREY,
  },
  BG_PRIMARY: {
    backgroundColor: COLORS.APP_MAIN,
  },
  BG_SECONDARY: {
    backgroundColor: COLORS.APP_SECONDARY,
  },
  BG_INACTIVE: {
    backgroundColor: COLORS.TEXT_INACTIVE,
  },
  BG_SUCCESS: {
    backgroundColor: COLORS.FORM_SUCCESS_BG,
  },
  BG_WARNING: {
    backgroundColor: COLORS.FORM_WARNING_BG,
  },
  BG_DANGER: {
    backgroundColor: COLORS.FORM_ERROR_BG,
  },
  BORDER: {
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
  },
  BORDER_INACTIVE: {
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
    borderTopColor: COLORS.TEXT_INACTIVE,
    borderLeftColor: COLORS.TEXT_INACTIVE,
    borderBottomColor: COLORS.TEXT_INACTIVE,
    borderRightColor: COLORS.TEXT_INACTIVE,
  },
  CARDLIKE_BORDERS: {
    borderTopColor: COLORS.BG_LIGHT_GREY_ALTERNATIVE,
    // borderLeftColor: COLORS.BG_LIGHT_GREY,
    borderLeftColor: COLORS.BG_LIGHT_GREY_ALTERNATIVE,
    // borderBottomColor: COLORS.TEXT_INACTIVE,
    // borderRightColor: COLORS.TEXT_INACTIVE,
    borderBottomColor: COLORS.GREY,
    borderRightColor: COLORS.GREY,
    // borderWidth: 0.5,
    borderTopWidth: 0.5,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
  },
  NO_BORDERS: {
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderBottomWidth: 0,
    borderRightWidth: 0,
  },
  CARDLIKE_MARGIN: {
    marginTop: 4,
    marginLeft: 2,
    marginBottom: 8,
    marginRight: 2,
  },
  CARDLIKE_BODY: {
    // width: '100%',
    paddingTop: 8,
    paddingLeft: 8,
    paddingBottom: 8,
    paddingRight: 8,
  },
  BUTTON_ICON: {
    paddingTop: 4,
    paddingLeft: 4,
    paddingBottom: 4,
    paddingRight: 4,
    minHeight: 34,
    minWidth: 44,
    alignItems: 'center',
    justifyContent: 'center',
  },
  BUTTON_OUTLINE: {
    borderTopColor: COLORS.TEXT_INACTIVE,
    borderLeftColor: COLORS.TEXT_INACTIVE,
    borderBottomColor: COLORS.TEXT_INACTIVE,
    borderRightColor: COLORS.TEXT_INACTIVE,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
    borderRadius: 8,
    paddingTop: 4,
    paddingLeft: 4,
    paddingBottom: 4,
    paddingRight: 4,
    minHeight: 34,
    alignItems: 'center',
    justifyContent: 'center',
  },
  BUTTON_OUTLINE_NO_PADDING: {
    borderTopColor: COLORS.TEXT_INACTIVE,
    borderLeftColor: COLORS.TEXT_INACTIVE,
    borderBottomColor: COLORS.TEXT_INACTIVE,
    borderRightColor: COLORS.TEXT_INACTIVE,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
    borderRadius: 8,
    paddingTop: 0,
    paddingLeft: 0,
    paddingBottom: 0,
    paddingRight: 0,
    minHeight: 34,
    alignItems: 'center',
    justifyContent: 'center',
  },
  BUTTON_OUTLINE_ACTIVE: {
    borderTopColor: COLORS.TEXT_ACTIVE,
    borderLeftColor: COLORS.TEXT_ACTIVE,
    borderBottomColor: COLORS.TEXT_ACTIVE,
    borderRightColor: COLORS.TEXT_ACTIVE,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
    borderRadius: 8,
    paddingTop: 4,
    paddingLeft: 4,
    paddingBottom: 4,
    paddingRight: 4,
    minHeight: 34,
    alignItems: 'center',
    justifyContent: 'center',
  },
  BUTTON_OUTLINE_PRIMARY: {
    borderTopColor: COLORS.APP_MAIN,
    borderLeftColor: COLORS.APP_MAIN,
    borderBottomColor: COLORS.APP_MAIN,
    borderRightColor: COLORS.APP_MAIN,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
    borderRadius: 8,
    paddingTop: 4,
    paddingLeft: 4,
    paddingBottom: 4,
    paddingRight: 4,
    minHeight: 34,
    alignItems: 'center',
    justifyContent: 'center',
  },
  BUTTON_OUTLINE_BLUE: {
    borderTopColor: COLORS.SECONDARY_MAIN,
    borderLeftColor: COLORS.SECONDARY_MAIN,
    borderBottomColor: COLORS.SECONDARY_MAIN,
    borderRightColor: COLORS.SECONDARY_MAIN,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
    borderRadius: 8,
    paddingTop: 4,
    paddingLeft: 4,
    paddingBottom: 4,
    paddingRight: 4,
    minHeight: 34,
    alignItems: 'center',
    justifyContent: 'center',
  },
  BUTTON_OUTLINE_PRIMARY_NO_PADDING: {
    borderTopColor: COLORS.APP_MAIN,
    borderLeftColor: COLORS.APP_MAIN,
    borderBottomColor: COLORS.APP_MAIN,
    borderRightColor: COLORS.APP_MAIN,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
    borderRadius: 8,
    paddingTop: 0,
    paddingLeft: 0,
    paddingBottom: 0,
    paddingRight: 0,
    minHeight: 34,
    alignItems: 'center',
    justifyContent: 'center',
  },
  BUTTON_OUTLINE_SUCCESS: {
    borderTopColor: COLORS.FORM_SUCCESS,
    borderLeftColor: COLORS.FORM_SUCCESS,
    borderBottomColor: COLORS.FORM_SUCCESS,
    borderRightColor: COLORS.FORM_SUCCESS,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
    borderRadius: 8,
    paddingTop: 4,
    paddingLeft: 4,
    paddingBottom: 4,
    paddingRight: 4,
    minHeight: 34,
    alignItems: 'center',
    justifyContent: 'center',
  },
  BUTTON_PRIMARY: {
    borderRadius: 8,
    paddingTop: 4,
    paddingLeft: 4,
    paddingBottom: 4,
    paddingRight: 4,
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: COLORS.APP_MAIN,
  },
  BUTTON_DANGER: {
    borderRadius: 8,
    paddingTop: 4,
    paddingLeft: 4,
    paddingBottom: 4,
    paddingRight: 4,
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: COLORS.BRAND_Danger,
  },
  BUTTON_INACTIVE: {
    borderRadius: 8,
    paddingTop: 4,
    paddingLeft: 4,
    paddingBottom: 4,
    paddingRight: 4,
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: COLORS.TEXT_INACTIVE,
  },
  FLEX: {
    flex: 1,
    flexBasis: 'auto',
  },
  FLEX_WRAP: {
    flexWrap: 'wrap',
  },
  FLEX_SHRINK: {
    flexShrink: 1,
  },
  FLEX_2: {
    flex: 2,
    flexBasis: 'auto',
    flexWrap: 'wrap',
  },
  FLEX_3: {
    flex: 3,
    flexBasis: 'auto',
    flexWrap: 'wrap',
  },
  FLEX_4: {
    flex: 4,
    flexBasis: 'auto',
    flexWrap: 'wrap',
  },
  NO_FLEX: {
    flex: 0,
    flexBasis: 'auto',
  },
  FLEX_ROW: {
    flexBasis: 'auto',
    flexDirection: 'row',
  },
  FLEX_COLUMN: {
    flexBasis: 'auto',
    flexDirection: 'column',
  },
  ROW_CENTER: {
    flexBasis: 'auto',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  ROW_MIDDLE_START: {
    flexBasis: 'auto',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  ROW_MIDDLE_BETWEEN: {
    flexBasis: 'auto',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  ROW_MIDDLE_END: {
    flexBasis: 'auto',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  ROW_TOP_START: {
    flexBasis: 'auto',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  ROW_TOP_BETWEEN: {
    flexBasis: 'auto',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  ROW_TOP_END: {
    flexBasis: 'auto',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
  },
  ROW_BOTTOM_START: {
    flexBasis: 'auto',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
  },
  ROW_BOTTOM_BETWEEN: {
    flexBasis: 'auto',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  ROW_BOTTOM_END: {
    flexBasis: 'auto',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  COLUMN_CENTER: {
    flexBasis: 'auto',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  COLUMN_CENTER_TOP: {
    flexBasis: 'auto',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  COLUMN_CENTER_BOTTOM: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  COLUMN_LEFT_TOP: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  COLUMN_LEFT_MIDDLE: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  COLUMN_LEFT_BOTTOM: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
  },
  COLUMN_RIGHT_TOP: {
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
  },
  COLUMN_RIGHT_MIDDLE: {
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  COLUMN_RIGHT_BOTTOM: {
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  HEIGHT_16: {
    height: 16,
  },
  HEIGHT_18: {
    height: 18,
  },
  HEIGHT_24: {
    height: 24,
  },
  HEIGHT_28: {
    height: 28,
  },
  HEIGHT_32: {
    height: 32,
  },
  HEIGHT_34: {
    height: 34,
  },
  HEIGHT_38: {
    height: 38,
  },
  HEIGHT_40: {
    height: 40,
  },
  HEIGHT_44: {
    height: 44,
  },
  HEIGHT_FULL: {
    height: '100%',
  },
  WIDTH_16: {
    width: 16,
  },
  WIDTH_18: {
    width: 18,
  },
  WIDTH_24: {
    width: 24,
  },
  WIDTH_28: {
    width: 28,
  },
  WIDTH_30: {
    width: 30,
  },
  WIDTH_32: {
    width: 32,
  },
  WIDTH_34: {
    width: 34,
  },
  WIDTH_40: {
    width: 40,
  },
  WIDTH_44: {
    width: 44,
  },
  WIDTH_FULL: {
    width: '100%',
  },
  MIN_HEIGHT_16: {
    minHeight: 16,
  },
  MIN_HEIGHT_18: {
    minHeight: 18,
  },
  MIN_HEIGHT_24: {
    minHeight: 24,
  },
  MIN_HEIGHT_28: {
    minHeight: 28,
  },
  MIN_HEIGHT_34: {
    minHeight: 34,
  },
  MIN_HEIGHT_38: {
    minHeight: 38,
  },
  MIN_HEIGHT_40: {
    minHeight: 40,
  },
  MIN_HEIGHT_44: {
    minHeight: 44,
  },
  MIN_WIDTH_16: {
    minWidth: 16,
  },
  MIN_WIDTH_18: {
    minWidth: 18,
  },
  MIN_WIDTH_24: {
    minWidth: 24,
  },
  MIN_WIDTH_28: {
    minWidth: 28,
  },
  MIN_WIDTH_30: {
    minWidth: 30,
  },
  MIN_WIDTH_34: {
    minWidth: 34,
  },
  MIN_WIDTH_44: {
    minWidth: 44,
  },
  PADDING_VERTICAL_2: {
    paddingTop: 2,
    paddingBottom: 2,
  },
  PADDING_VERTICAL_4: {
    paddingTop: 4,
    paddingBottom: 4,
  },
  PADDING_VERTICAL_6: {
    paddingTop: 6,
    paddingBottom: 6,
  },
  PADDING_VERTICAL_8: {
    paddingTop: 8,
    paddingBottom: 8,
  },
  PADDING_VERTICAL_12: {
    paddingTop: 12,
    paddingBottom: 12,
  },
  PADDING_HORIZONTAL_4: {
    paddingLeft: 4,
    paddingRight: 4,
  },
  PADDING_HORIZONTAL_6: {
    paddingLeft: 6,
    paddingRight: 6,
  },
  PADDING_HORIZONTAL_8: {
    paddingLeft: 8,
    paddingRight: 8,
  },
  PADDING_HORIZONTAL_12: {
    paddingLeft: 12,
    paddingRight: 12,
  },
  MARGIN_VERTICAL_4: {
    marginTop: 4,
    marginBottom: 4,
  },
  MARGIN_VERTICAL_6: {
    marginTop: 6,
    marginBottom: 6,
  },
  MARGIN_VERTICAL_8: {
    marginTop: 8,
    marginBottom: 8,
  },
  MARGIN_VERTICAL_12: {
    marginTop: 12,
    marginBottom: 12,
  },
  MARGIN_HORIZONTAL_4: {
    marginLeft: 4,
    marginRight: 4,
  },
  MARGIN_HORIZONTAL_6: {
    marginLeft: 6,
    marginRight: 6,
  },
  MARGIN_HORIZONTAL_8: {
    marginLeft: 8,
    marginRight: 8,
  },
  MARGIN_HORIZONTAL_12: {
    marginLeft: 12,
    marginRight: 12,
  },
  MARGIN_TOP_4: {
    marginTop: 4,
  },
  MARGIN_TOP_6: {
    marginTop: 6,
  },
  MARGIN_TOP_8: {
    marginTop: 8,
  },
  MARGIN_TOP_12: {
    marginTop: 12,
  },
  MARGIN_TOP_16: {
    marginTop: 16,
  },
  MARGIN_RIGHT_4: {
    marginRight: 4,
  },
  MARGIN_RIGHT_6: {
    marginRight: 6,
  },
  MARGIN_RIGHT_8: {
    marginRight: 8,
  },
  MARGIN_RIGHT_12: {
    marginRight: 12,
  },
  MARGIN_RIGHT_16: {
    marginRight: 16,
  },
  MARGIN_BOTTOM_4: {
    marginBottom: 4,
  },
  MARGIN_BOTTOM_6: {
    marginBottom: 6,
  },
  MARGIN_BOTTOM_8: {
    marginBottom: 8,
  },
  MARGIN_BOTTOM_12: {
    marginBottom: 12,
  },
  MARGIN_BOTTOM_16: {
    marginBottom: 16,
  },
  MARGIN_LEFT_4: {
    marginLeft: 4,
  },
  MARGIN_LEFT_6: {
    marginLeft: 6,
  },
  MARGIN_LEFT_8: {
    marginLeft: 8,
  },
  MARGIN_LEFT_12: {
    marginLeft: 12,
  },
  MARGIN_LEFT_16: {
    marginLeft: 16,
  },
  PADDING_4: {
    paddingTop: 4,
    paddingLeft: 4,
    paddingBottom: 4,
    paddingRight: 4,
  },
  PADDING_6: {
    paddingTop: 6,
    paddingLeft: 6,
    paddingBottom: 6,
    paddingRight: 6,
  },
  PADDING_8: {
    paddingTop: 8,
    paddingLeft: 8,
    paddingBottom: 8,
    paddingRight: 8,
  },
  PADDING_12: {
    paddingTop: 12,
    paddingLeft: 12,
    paddingBottom: 12,
    paddingRight: 12,
  },
  MARGIN_4: {
    marginTop: 4,
    marginLeft: 4,
    marginBottom: 4,
    marginRight: 4,
  },
  MARGIN_6: {
    marginTop: 6,
    marginLeft: 6,
    marginBottom: 6,
    marginRight: 6,
  },
  MARGIN_8: {
    marginTop: 8,
    marginLeft: 8,
    marginBottom: 8,
    marginRight: 8,
  },
  MARGIN_12: {
    marginTop: 12,
    marginLeft: 12,
    marginBottom: 12,
    marginRight: 12,
  },
  X_GRID: {
    flex: 1,
    flexDirection: 'column',
    flexBasis: 'auto',
    flexWrap: 'wrap',
    width: '100%',
  },
  X_ROW: {
    flex: 1,
    flexDirection: 'row',
    flexBasis: 'auto',
    flexWrap: 'wrap',
    width: '100%',
    flexShrink: 1,
  },
  X_COL: {
    flex: 1,
    flexShrink: 1,
    flexDirection: 'column',
    flexBasis: 'auto',
    flexWrap: 'wrap',
  },
  X_COL_NO_FLEX: {
    flexDirection: 'column',
    flexBasis: 'auto',
    flexWrap: 'wrap',
  },
  HEADER_CONTAINER: {
    backgroundColor: COLORS.WHITE,
    height: 44,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 4,
    paddingRight: 4,
    borderBottomWidth: 0.5,
    borderBottomColor: COLORS.GREY,
  },
  HEADER_BODY_LEFT: {
    minWidth: 54,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  HEADER_BODY_CENTER: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  HEADER_BODY_RIGHT: {
    minWidth: 54,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  BOTTOM_LINE_ACTIVE: {
    borderBottomColor: COLORS.TEXT_ACTIVE,
    borderBottomWidth: Platform.OS === 'web' ? 1 : 0.5,
  },
  BOTTOM_LINE_INACTIVE: {
    borderBottomColor: COLORS.TEXT_INACTIVE,
    borderBottomWidth: Platform.OS === 'web' ? 1 : 0.5,
  },
  BORDER_RADIUS_4: {
    borderRadius: 4,
  },
  BORDER_RADIUS_6: {
    borderRadius: 6,
  },
  BORDER_RADIUS_8: {
    borderRadius: 8,
  },
  BORDER_RADIUS_12: {
    borderRadius: 12,
  },
  BORDER_RADIUS_14: {
    borderRadius: 14,
  },
  BORDER_RADIUS_16: {
    borderRadius: 16,
  },
  BORDER_RADIUS_18: {
    borderRadius: 18,
  },
  BORDERS_PRIMARY: {
    borderTopColor: COLORS.APP_MAIN,
    borderLeftColor: COLORS.APP_MAIN,
    borderBottomColor: COLORS.APP_MAIN,
    borderRightColor: COLORS.APP_MAIN,
  },
  BORDERS_INACTIVE: {
    borderTopColor: COLORS.TEXT_INACTIVE,
    borderLeftColor: COLORS.TEXT_INACTIVE,
    borderBottomColor: COLORS.TEXT_INACTIVE,
    borderRightColor: COLORS.TEXT_INACTIVE,
  },
  BORDERS_ACTIVE: {
    borderTopColor: COLORS.TEXT_ACTIVE,
    borderLeftColor: COLORS.TEXT_ACTIVE,
    borderBottomColor: COLORS.TEXT_ACTIVE,
    borderRightColor: COLORS.TEXT_ACTIVE,
  },
  BORDERS_SUCCESS: {
    borderTopColor: COLORS.BRAND_Success,
    borderLeftColor: COLORS.BRAND_Success,
    borderBottomColor: COLORS.BRAND_Success,
    borderRightColor: COLORS.BRAND_Success,
  },
  BORDERS_DANGER: {
    borderTopColor: COLORS.BRAND_Danger,
    borderLeftColor: COLORS.BRAND_Danger,
    borderBottomColor: COLORS.BRAND_Danger,
    borderRightColor: COLORS.BRAND_Danger,
  },
  BOTTOM_LINE: {
    borderBottomWidth: 0.5,
    borderBottomColor: Platform.OS === 'web' ? COLORS.BG_LIGHT_GREY : COLORS.TEXT_INACTIVE,
  },
})
