import React, { useState, useEffect } from 'react'
import { View, Text, TouchableOpacity } from 'react-native'

import MonthlyCarousel from './DevSubscriptionMonthlyCarouselView'

import mockjson1 from './mockjson/subscription1.json'

interface IDevSubscriptionPackageListView {
  // navigation: NavigationStackProp<NavigationRoute, ITimeSlotsProps>
  // webMaxScreenWidth?: number // web only
  // webNavigation?: NavigationStackProp<NavigationRoute, null> // web only
  // setPrefTimeslot: (params: ActionApiParams) => void
  // selectedStore: ISelectedStoreMap
  // profile: IProfileMap
  packageList: any
  onSelectPackage: (any) => void
}

const DevSubscriptionPackageListView: React.FC<IDevSubscriptionPackageListView> = ({ packageList, onSelectPackage }) => {
  // const [packageList, setPackageList] = useState({})
  const [selectedPackages, setSelectedPackages] = useState([])
  // let isPackageListLoaded = false
  useEffect(() => {
    // Update the document title using the browser API
    console.log('DevSubscriptionPackageListView.useEffect.packageList ', packageList)
    console.log('mockjson1: ', mockjson1)
  })

  const renderPackageList = (packageList) => {
    console.log('Just in renderPackageList', packageList)
    if (packageList !== undefined && packageList.items !== undefined) {
      return (
        <View style={{ paddingTop: 10, backgroundColor: 'white' }}>
          <MonthlyCarousel
            packageList={packageList}
            // packageList={mockjson1.future_billing_period.package_extend}
            onSelectItem={(item) => {
              console.log('MonthlyCarousel.selectedItem', item)
            }}
          />
          {/* {renderPackageDetail()} */}
          {packageList.items.map((item) =>
            // return <Text>{item.sku}</Text>
            renderPackageItem(item)
          )}
        </View>
      )
    }
    return <Text>items is null</Text>
  }

  // const renderPackageDetail = () => (
  //   <View
  //     style={{
  //       flex: 1,
  //       // height: 528,
  //       // paddingTop: 10,
  //       paddingLeft: 10,
  //       // paddingRight: 4,
  //       // paddingBottom: 10,
  //       backgroundColor: 'yellow',
  //       borderRadius: 15,
  //       marginTop: 4,
  //       marginBottom: 20,
  //       // alignSelf: 'center',
  //       opacity: 50,
  //     }}>
  //     {/* <Text style={{ fontSize: 20 }}>จุดเด่นแพ็ก Gold</Text> */}
  //     <Text style={{ fontSize: 16 }}>
  //       • ทุกอย่างที่ แพ็ก Silver ทำได้ {'\n'}• 2,000 ออเดอร์/เดือน{'\n'}• เชื่อม Shopee ได้ 3 shop{'\n'}• 5 ร้าน{'\n'}• 3 กลุ่มสมาชิก{'\n'}
  //       • 3 ราคา{'\n'}• 5 แอดมิน{'\n'}
  //     </Text>
  //   </View>
  // )

  const renderPackageItem = (pkgItem) => (
    <View style={{ padding: 4, borderRadius: 5, borderWidth: 1, margin: 4 }}>
      <TouchableOpacity
        onPress={(event) => {
          // console.log('renderPackageItem.selected: ', pkgItem.sku)
          // add to the selected packages
          const newlySelectedPackages = [...selectedPackages] // clone the array
          newlySelectedPackages.push(pkgItem.sku)
          // console.log('renderPackageItem.selectedPackages: ', selectedPackages)
          setSelectedPackages(newlySelectedPackages)

          // send the callback
          onSelectPackage(newlySelectedPackages)
        }}
        style={{}}>
        <Text key={pkgItem.sku} style={selectedPackages.includes(pkgItem.sku) ? s.selectedPackageName : s.notSelectedPackageName}>
          {pkgItem.sku} - {pkgItem.name}
          {/* {selectedPackages.length > 0 ? selectedPackages[0] : '::'} */}
        </Text>
      </TouchableOpacity>
    </View>
  )

  return renderPackageList(packageList)
}

const s = {
  textField: { fontSize: 18 },
  packageName: {
    paddingTop: 4,
    paddingLeft: 4,
  },
  selectedPackageName: {
    color: 'orange',
  },
  notSelectedPackageName: {
    color: 'grey',
  },
}
export default DevSubscriptionPackageListView
