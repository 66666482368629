import BaseUIERPChannelContact from 'xui/modules/erp/BaseUIERPChannelContact'
// import { getConfig } from 'x/config/mode'
// import { WebView } from 'react-native-webview'
// import OrdersSummaryDonut from '../../components/OrdersSummaryDonut'

export default class ERPChannelContact extends BaseUIERPChannelContact {
  static displayName = 'ERPChannelContact'

  static navigationOptions = {
    header: null,
  }
}
