import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import importEditProductWarehouseBySKU from './importEditProductWarehouseBySKU'
import * as StoreActions from 'x/modules/store/StoreState'
import * as BankActions from 'x/modules/bank/BankState'
import { getSellerStores, getSellerStoresShouldFetch, getSubscription, getSelectedStore } from 'x/redux/selectors'

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
    // sellerStores: getSellerStores(state),
    // sellerStoresShouldFetch: getSellerStoresShouldFetch(state),
    // subscription: getSubscription(state),
  }),
  (dispatch) => {
    return {
      // navigate: bindActionCreators(NavigationActions.navigate, dispatch),
      // fetchSellerStores: bindActionCreators(StoreActions.fetchSellerStores, dispatch),
      // fetchAccounts: bindActionCreators(BankActions.fetchStorePaymentAccounts, dispatch),
      // getPermissionMemberList: bindActionCreators(StoreActions.getPermissionMemberList, dispatch),
    }
  }
)(importEditProductWarehouseBySKU)
