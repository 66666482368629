import React from 'react'
import { Clipboard, Dimensions } from 'react-native'

import _ from 'lodash'
import * as util from 'x/utils/util'
import XCustomHeader from 'xui/components/XCustomHeader'
import { COLORS, S } from 'x/config/styles'
import p from 'x/config/platform-specific'

import {
  IMkpProductDetailProps,
  IMkpProductDetailState,
  IProductDetailVariant,
  IApiOptions,
  IMkpProductDetailVariant,
  INavHeaderButtonProps,
  IProductDetailItem,
  IProductVariantXSellyToMkpMappingViewNavParams,
} from 'x/index'
// import { getXsellyLogo, getShopeeLogo, getLazadaLogo } from 'xui/utils/BaseShareUI'

import { IMkpProductContextProps } from 'x/modules/marketplace/MkpProductContext'
import api from 'x/utils/api'
import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import XImage from 'xui/components/XImage'
import XText from 'xui/components/XText'
import Box from 'xui/components/Box'
import XCard from 'xui/components/XCard'
import XIconButton from 'xui/components/XIconButton'
import XContainer from 'xui/components/XContainer'
import XScrollView from 'xui/components/XScrollView'
import XIcon from 'xui/components/XIcon'
import { connect } from 'react-redux'
import { getState, getDispatch } from 'x/modules/marketplace/MkpProductDetailConnect'
import XContent from 'xui/components/XContent'
import XRadio from 'xui/components/XRadio'
import MkpLogo from 'xui/components/MkpLogo'
import XSellyLogo from '../../components/XSellyLogo'
import ForwardIcon from '../../components/ForwardIcon'
import XOverlay from '../../components/XOverlay'

import { withMkpProductContextPropsAndRef } from './MkpProductContextHOC'

// import MkpProductVariantPicker from './MkpProductVariantPicker'

import BaseUIMkpProductDetail, { IMkpProductVariantItemInfo } from './BaseUIMkpProductDetail'

type IProps = IMkpProductDetailProps<IProductVariantXSellyToMkpMappingViewNavParams> & IMkpProductContextProps

interface IState extends IMkpProductDetailState {
  variantPickerVisibleAtIndex?: number
  isMappingSummaryVisible?: boolean

  /**
   * isOneToOneVariantMapping flag is set to TRUE when there is only one variant on both XSelly's pt and MKP' pt.
   * This flag is used to simplify UI as there is no need for users to choose mappings b/w variant anymore;
   * In other word, we can do auto variant mapping for users and allow users to immediately confirm the new
   * mapping without changing any data in the view.
   */
  isOneToOneVariantMapping: boolean

  // oldPairedMap?: {
  //   [ppv_uuid: string]: number // ppv_uuid to pp_id
  // }
  newPairedMap?: {
    // [ppv_uuid: string]: number // ppv_uuid to pp_id
    [itemId_itemVariantId: string]: number // item_id + item_variant_id to pp_id
  }
  hilightPickerMap?: {
    [variantIndex: number]: boolean // true for highlight
  }
  isFetchMapping?: boolean

  xSellyProduct?: IProductDetailItem

  // xSellyVariantPickerItemsContainerHeight?: number
  webWindowHeight?: number

  checkedVariantIndex?: number
}

const BASE_SUBMIT_BTN_STYLE = [S.ROW_CENTER, S.WIDTH_FULL, { minHeight: 34 }]
const BASE_CLOSE_OVERLAY_BTN_STYLE = [S.ROW_CENTER, S.NO_FLEX, { width: 54, marginRight: 4, minHeight: 44 }]

class ProductVariantXSellyToMkpMappingView extends BaseUIMkpProductDetail<IProps, IState> {
  static displayName = 'ProductVariantXSellyToMkpMappingView'

  isFetchMapping?: boolean

  mkpVariantButtonPositionYMap?: { [btnIndex: number]: number }

  // async componentDidMount() {
  //   await super.componentDidMount.apply(this)

  //   // const { navigation } = this.props
  //   // const { editingMkpProduct } = this.state
  //   const selectedProductVariant = util.getNavParam(this.props, 'selectedProductVariant')
  //   console.log('ProductVariantXSellyToMkpMappingView componentDidMount selectedProductVariant => ', selectedProductVariant)

  // }

  submitNewMapping = async () => {
    if (this.isFetchMapping) {
      return
    }
    this.isFetchMapping = true

    await util.setStatePromise(this, { isFetchMapping: true })

    const { navigation, mkpProductContext } = this.props
    const { editingMkpProduct, newPairedMap = {}, xSellyProduct: targetXSellyProduct, checkedVariantIndex = 0 } = this.state
    // const targetXSellyProduct = this.getXSellyProduct()
    const store_id = util.getNavParam(this.props, 'store_id')
    const mkp_ch_id = util.getNavParam(this.props, 'mkp_ch_id')
    // const mkp_id = util.getNavParam(this.props, 'mkp_id')
    // console.log('submitNewMapping editingMkpProduct => ', editingMkpProduct)
    // console.log('submitNewMapping targetXSellyProduct => ', targetXSellyProduct)

    // // @ts-ignore :: FIXME: แก้ mapping ให้ใช้งานได้
    // const { uuid } = editingMkpProduct
    // // const pt_id = coreProduct.id
    // const pt_id = targetXSellyProduct.id

    try {
      const apiOptions: IApiOptions = {
        messages: {
          successMsg: 'ดำเนินการเสร็จสิ้น',
          errorMsg: 'ดำเนินการล้มเหลว',
        },
        showSpinner: true,
        axiosOptions: {
          retry: 0,
          timeout: 60000,
        },
        isApiV2: true,
      }

      // const new_pp_mappings = []

      // // const ppvUUIDs = Object.keys(newPairedMap)
      // const itemId_ItemVariantIds = Object.keys(newPairedMap)
      // for (const itemId_ItemVariantId of itemId_ItemVariantIds) {
      //   const ppid = newPairedMap[itemId_ItemVariantId]
      //   // const item_id = parseInt(itemId_ItemVariantId.split('_')[0])
      //   const item_id = itemId_ItemVariantId.split('_')[0]

      //   // let item_variant_id: string | number | null = itemId_ItemVariantId.split('_')[1]
      //   let item_variant_id: string | null = itemId_ItemVariantId.split('_')[1]

      //   if (item_variant_id === 'null') {
      //     item_variant_id = null
      //   }

      //   if (_.isString(item_variant_id)) {
      //     // item_variant_id = parseInt(item_variant_id)
      //     item_variant_id = item_variant_id
      //   }

      //   new_pp_mappings.push({
      //     pp_id: ppid,
      //     // ppv_uuid: ppvUUID,
      //     item_id,
      //     item_variant_id,
      //   })
      // }

      const spv = util.getNavParam(this.props, 'selectedProductVariant')
      const smv = editingMkpProduct.variants[checkedVariantIndex]

      const new_pp_mappings = [
        {
          pp_id: spv.pp_id,
          item_id: smv.item_id,
          item_variant_id: smv.item_variant_id === 'null' ? null : smv.item_variant_id,
        },
      ]

      const reqBody = {
        store_id,
        mkp_ch_id,
        // mkp_id,
        // item_uuid: uuid,
        // pt_id,
        new_pp_mappings,
        // new_pp_mappings: [
        //   {
        //     pp_id: targetXSellyProduct.variants[0].pp_id,
        //     ppv_uuid: editingMkpProduct.variants[0].ppv_uuid,
        //   },
        // ],
      }

      // console.log('submitNewMapping reqBody => ', reqBody)

      const res = await api.post('mkp/v2/product_mapping/update', reqBody, apiOptions)

      // console.log('submitNewMapping res => ', res)

      if (res && res.status === 'ok') {
        const onMappingSuccess = util.getNavParam(this.props, 'onMappingSuccess')
        if (onMappingSuccess) {
          await onMappingSuccess()
          this.goBack()
        }

        // await this._closeMappingSummary()
        // const isPreventDestroyWhenNavBack = util.getNavParam(this.props, 'isPreventDestroyWhenNavBack', false)
        // if (isPreventDestroyWhenNavBack) {
        //   await this.doRefresh()
        // }
        // const mkpState = mkpProductContext.getMkpState(this.getContextViewKey())
        // if (_.isFunction(mkpState.onSuccesssMapping)) {
        //   mkpState.onSuccesssMapping()
        //   await util.delay(500)
        // }
        // this._doGoBackAfterSuccessMapping()
      }

      // console.log('_doFetchApiMkpProductMapping res => ', res)
    } catch (err) {
      // console.log('_doFetchApiMkpProductMapping err => ', err)
      console.log('submitNewMapping err => ', err)
    }

    await util.setStatePromise(this, { isFetchMapping: false })
    this.isFetchMapping = false
  }

  // Override
  renderFooter = () => null

  // Override
  getPairedCoreProductLabelText = () => (this._isNewMappingCoreProduct() ? 'สลับไปผูกกับ' : 'ผูกอยู่กับ')

  // override
  renderPairedXSellyProductVariantInfo = (info: IMkpProductVariantItemInfo) => {
    const { index, variant } = info
    const { newPairedMap = {} } = this.state
    // const isPickedNewPair = _.has(newPairedMap, variant.ppv_uuid)
    const isPickedNewPair = _.has(newPairedMap, `${variant.item_id}_${variant.item_variant_id}`)
    const isUnpaired = _.isNil(variant.paired_pp_id)
    return (
      <VStack w='full' key={`PairedInfo_${index}`}>
        <HStack w='full' px='3' pb='1' alignItems='center' space='1'>
          {isUnpaired ? null : <XIcon name={isPickedNewPair ? 'unlink' : 'link'} family='FontAwesome' inactive minW='28px' />}
          {isUnpaired ? null : <XText variant='inactive'>ผูกอยู่กับ:</XText>}
          {/* {isUnpaired ? (
            <XText flex={1} textAlign='center' variant='inactive'>
              ยังไม่ได้ผูกกับสินค้าใดๆ
            </XText>
          ) : null} */}
        </HStack>
        {this.renderPairedXSellyProductVariantInfoCardButton(info)}
      </VStack>
    )
  }

  renderPairedXSellyProductVariantInfoCardButton = (info: IMkpProductVariantItemInfo) => {
    const { index, variant } = info

    if (!variant) {
      return null
    }

    const { paired_pt_name, paired_pp_name, paired_pp_id, paired_pp_thumbnail_uri, paired_pt_thumbnail_uri } = variant

    const isUnpaired = _.isNil(paired_pp_id)

    // const handlePressToPickNewPair = () => this._onOpenVariantPicker(index)

    const pairedPtName = paired_pt_name
    // const pairedPpName = !paired_pp_name || paired_pp_name === '' ? '(ไม่มีชื่อ)' : paired_pp_name
    const pairedPpName = !paired_pp_name || paired_pp_name === '' ? '' : paired_pp_name

    const { newPairedMap = {} } = this.state
    // const isPickedNewPair = _.has(newPairedMap, variant.ppv_uuid)
    const isPickedNewPair = _.has(newPairedMap, `${variant.item_id}_${variant.item_variant_id}`)

    const img_uri = paired_pp_thumbnail_uri || paired_pt_thumbnail_uri

    return (
      <XCard key={`PairedInfoCardButton_${index}`} bg='muted.200'>
        <XCard.Body>
          <HStack w='full' space='1'>
            <VStack w='16' h='16'>
              <XImage w='16' h='16' borderRadius='lg' resizeMode='cover' source={{ uri: img_uri }} />
              <Box position='absolute' top='1' left='1'>
                <XSellyLogo width={24} height={24} />
              </Box>
            </VStack>
            <VStack flex={1}>
              <XText w='full' numberOfLines={2}>
                {pairedPtName}
              </XText>
            </VStack>
            <VStack _web={{ w: '70px' }} w='90px' alignItems='flex-end'>
              <XText w='full' textAlign='right' numberOfLines={3}>
                {pairedPpName}
              </XText>
            </VStack>
          </HStack>
        </XCard.Body>
      </XCard>
    )
  }

  renderNewPairXSellyProductVariantInfo = (info: IMkpProductVariantItemInfo) => {
    const { newPairedMap = {}, xSellyProduct } = this.state
    const { index, variant } = info

    // if (!newPairedMap || !newPairedMap[variant.ppv_uuid] || !xSellyProduct) {
    if (!newPairedMap || !newPairedMap[`${variant.item_id}_${variant.item_variant_id}`] || !xSellyProduct) {
      return null
    }

    return (
      <VStack w='full' key={`NewPairedInfo_${index}`}>
        <HStack w='full' px='3' pb='1' alignItems='center' space='1'>
          <XIcon name='link' family='FontAwesome' inactive minW='28px' />
          <XText variant='inactive'>เปลี่ยนมาผูกกับสินค้า:</XText>
        </HStack>
        {this.renderNewPairdXSellyProductVariantInfoCard(info)}
      </VStack>
    )
  }

  _removeNewPairedAtMkpVariantIndex = (mkpVariantIndex: number) => {
    // console.log('_removeNewPairedAtMkpVariantIndex mkpVariantIndex => ', mkpVariantIndex)
    const { editingMkpProduct, newPairedMap = {} } = this.state

    // const removePpvUuid = editingMkpProduct.variants[mkpVariantIndex].ppv_uuid
    const { item_id, item_variant_id = null } = editingMkpProduct.variants[mkpVariantIndex]
    const removeItemId_ItemVariantId = `${item_id}_${item_variant_id}`
    const computeNewPairedMap = _.cloneDeep(newPairedMap)
    delete computeNewPairedMap[removeItemId_ItemVariantId]

    this.setState({ newPairedMap: computeNewPairedMap })
  }

  renderNewPairdXSellyProductVariantInfoCard = (info: IMkpProductVariantItemInfo) => {
    const { index, variant } = info
    // const { editingMkpProduct } = this.state
    // const { variants } = editingMkpProduct
    // const variant = variants[index]

    if (!variant) {
      return null
    }

    const { newPairedMap = {}, xSellyProduct: xProduct } = this.state
    // const newMapPpId = newPairedMap[variant.ppv_uuid]
    const { item_id, item_variant_id = null } = variant
    const newMapPpId = newPairedMap[`${item_id}_${item_variant_id}`]

    if (!xProduct || !xProduct.variants || !newMapPpId) {
      return null
    }

    const xVariant = xProduct.variants.find((xv) => xv.pp_id === newMapPpId)

    if (!xVariant || !xVariant.pp_id) {
      return null
    }

    // const xVariantName = _.isNil(xVariant.name) || xVariant.name === '' ? '(ไม่มีชื่อ)' : xVariant.name
    const xVariantName = _.isNil(xVariant.name) || xVariant.name === '' ? '' : xVariant.name
    // const { paired_pt_name, paired_pp_name, thumbnail_uri } = variant

    return (
      <XCard key={`NewPairedInfoCard_${index}`} overflow='visible'>
        <XCard.Body>
          <HStack w='full' space='1'>
            {/* <HStack w='11' h='16' alignItems='center'>
              <XIconButton name='trash' variant='outline' onPress={() => this._removeNewPairedAtMkpVariantIndex(index)} />
            </HStack> */}

            <VStack w='16' h='16'>
              <XImage w='16' h='16' borderRadius='lg' resizeMode='cover' source={{ uri: xProduct.thumbnail_uris[0] }} />
              <Box position='absolute' top='1' left='1'>
                <XSellyLogo width={24} height={24} />
              </Box>
            </VStack>
            <VStack flex={1}>
              <XText w='full' numberOfLines={2}>
                {xProduct.name}
              </XText>
            </VStack>
            <VStack _web={{ w: '70px' }} w='90px' alignItems='flex-end'>
              <XText variant='primary' w='full' textAlign='right' bold numberOfLines={3}>
                {xVariantName}
              </XText>
            </VStack>
          </HStack>
        </XCard.Body>
        <Box position='absolute' top='-12px' right='-12px'>
          <XIconButton name='close-circle' colorScheme='danger' onPress={() => this._removeNewPairedAtMkpVariantIndex(index)} />
        </Box>
      </XCard>
    )
  }

  // // Override
  // getContextViewKey = () => {
  //   const { item_uuid, pt_id } = this.props.navigation.state.params
  //   return item_uuid ? `${item_uuid.toString()}-mapping` : `${pt_id.toString()}-mapping`
  // }

  getHeaderLeftProps = (): INavHeaderButtonProps => {
    const { isFetchMapping } = this.state

    return {
      submitting: isFetchMapping,
      label: 'ตกลง',
      onPressItem: this.submitNewMapping,
    }
  }

  getHeaderRightProps = (): INavHeaderButtonProps => {
    const { navigation } = this.props
    const { isFetchMapping } = this.state
    const mkpId = util.getNavParam(this.props, 'mkp_id')

    return {
      label: 'ยกเลิก',
      submitting: isFetchMapping,
      onPressItem: this._handlePressCancelMapping,
      // iconButtonProps: {
      //   _text: {
      //     color: COLORS.TEXT_INACTIVE,
      //   },
      // },
    }
  }

  getHeaderTitle = () => {
    const { navigation } = this.props
    const mkpId = util.getNavParam(this.props, 'mkp_id')
    if (!mkpId) {
      return 'จัดการผูกตัวเลือกสินค้า'
    }
    const mkpName = util.getMKPName(mkpId)
    return `จัดการผูกตัวเลือกสินค้า\nXSelly กับระบบ ${mkpName}`
  }

  // Override
  renderCustomHeader = () => (
    <VStack w='full'>
      <XCustomHeader
        // title={`จัดการผูกตัวเลือกสินค้า\nXSelly กับระบบ ${mkpName}`}
        title={this.getHeaderTitle()}
        headerLeftProps={this.getHeaderLeftProps()}
        headerRightProps={this.getHeaderRightProps()}
      />
      {this.renderXSellyProductInfo()}
      <HStack w='full' px='3' py='1' space='1' alignItems='center' justifyContent='center'>
        <XText textAlign='center'>เลือกตัวเลือกสินค้าที่ต้องการผูกแล้วกด "ตกลง"</XText>
      </HStack>
    </VStack>
  )

  // getXSellyProduct = () => {
  //   const { navigation } = this.props
  //   const xProduct = util.getNavParam(this.props, 'targetXSellyProduct')
  //   return xProduct
  // }

  renderXSellyProductInfo = () => {
    const { xSellyProduct: xProduct } = this.state

    if (!xProduct || !xProduct.name) {
      return null
    }

    const { name, description, thumbnail_uris = [], variants = [] } = xProduct
    const variantCount = variants.length
    const variantNames = variants.map((xv) => xv.name).join(', ')

    return (
      <HStack w='full' px='3' py='2' space='1'>
        <VStack>
          <XImage w='16' h='16' borderRadius='lg' resizeMode='cover' source={{ uri: thumbnail_uris[0] }} />
          <Box position='absolute' top='1' left='1'>
            <XSellyLogo width={24} height={24} />
          </Box>
        </VStack>
        <VStack flex={1} space='1'>
          <XText w='full' numberOfLines={2}>
            {name}
          </XText>
          {/* <XText variant='inactive' fontSize='xs' w='full' numberOfLines={1}>
            {description}
          </XText> */}
          {variantCount > 1 ? (
            <XText fontSize='xs' w='full' numberOfLines={2}>
              <XText variant='inactive' fontSize='xs'>{`มี ${variantCount} ตัวเลือก: `}</XText>
              {variantNames}
            </XText>
          ) : null}
        </VStack>
      </HStack>
    )
  }

  // _onPickVariant = (mkpVariantIdx, coreVariantIdx) => {
  //   this.setState({})
  // }

  _onRequestCloseProductVariantPicker = () => {
    this.setState({ variantPickerVisibleAtIndex: -1 })
  }

  _onOpenVariantPicker = (pickedMkpIdx: number) => {
    this.setState({ variantPickerVisibleAtIndex: pickedMkpIdx })
  }

  _closeMappingSummary = async () => {
    await util.setStatePromise(this, { isMappingSummaryVisible: false })
  }

  _openMappingSummary = async () => {
    await util.setStatePromise(this, { isMappingSummaryVisible: true })
  }

  _handlePressCancelMapping = async () => {
    this.goBack()
  }

  // _doFetchApiMkpProductMapping = async () => {
  //   if (this.isFetchMapping) {
  //     return
  //   }
  //   this.isFetchMapping = true
  //   await util.setStatePromise(this, { isFetchMapping: true })
  //   try {
  //     const { navigation, mkpProductContext } = this.props
  //     const { editingMkpProduct, newPairedMap, coreProduct } = this.state
  //     const store_id = util.getNavParam(this.props, 'store_id')
  //     // @ts-ignore :: FIXME: แก้ mapping ให้ใช้งานได้
  //     const { mkp_channel_id } = editingMkpProduct
  //     const { mkp_id } = editingMkpProduct
  //     const item_uuid = editingMkpProduct.uuid
  //     const pt_id = coreProduct.id

  //     const new_pp_mappings = []
  //     // const ppvUUIDs = Object.keys(newPairedMap)
  //     // for (const ppvUUID of ppvUUIDs) {
  //     //   const ppid = newPairedMap[ppvUUID]
  //     //   new_pp_mappings.push({
  //     //     pp_id: ppid,
  //     //     ppv_uuid: ppvUUID,
  //     //   })
  //     // }

  //     // ส่งเฉพาะ variant ทีเกิด diff เท่านั้น !!!
  //     const updatedDiff = this._computeUpdatedMappingVariants()
  //     const ppvUUIDs = Object.keys(updatedDiff)
  //     for (const ppvUUID of ppvUUIDs) {
  //       const ppid = newPairedMap[ppvUUID]
  //       new_pp_mappings.push({
  //         pp_id: ppid,
  //         ppv_uuid: ppvUUID,
  //       })
  //     }

  //     // console.log('updatedDiff ==> ', this._computeUpdatedMappingVariants())

  //     // test bypass success
  //     // const mkpState = mkpProductContext.getMkpState(this.getContextViewKey())
  //     // if (_.isFunction(mkpState.onSuccesssMapping)) {
  //     //   mkpState.onSuccesssMapping()
  //     //   await util.delay(200)
  //     // }
  //     // this.goBack()
  //     // return

  //     const apiOptions: IApiOptions = {
  //       messages: {
  //         successMsg: 'ดำเนินการเสร็จสิ้น',
  //         errorMsg: 'ดำเนินการล้มเหลว',
  //       },
  //       showSpinner: true,
  //       axiosOptions: {
  //         retry: 0,
  //         timeout: 60000,
  //       },
  //       isApiV2: true,
  //     }

  //     const reqBody = {
  //       store_id,
  //       mkp_channel_id,
  //       mkp_id,
  //       item_uuid,
  //       pt_id,
  //       new_pp_mappings,
  //     }

  //     const res = await api.post('mkp/product_mapping', reqBody, apiOptions)
  //     if (res && res.status === 'ok') {
  //       await this._closeMappingSummary()
  //       const isPreventDestroyWhenNavBack = util.getNavParam(this.props, 'isPreventDestroyWhenNavBack', false)
  //       if (isPreventDestroyWhenNavBack) {
  //         await this.doRefresh()
  //       }
  //       const mkpState = mkpProductContext.getMkpState(this.getContextViewKey())
  //       if (_.isFunction(mkpState.onSuccesssMapping)) {
  //         mkpState.onSuccesssMapping()
  //         await util.delay(500)
  //       }
  //       this._doGoBackAfterSuccessMapping()
  //     }
  //     // console.log('_doFetchApiMkpProductMapping res => ', res)
  //   } catch (err) {
  //     // console.log('_doFetchApiMkpProductMapping err => ', err)
  //   }
  //   await this._closeMappingSummary()
  //   await util.setStatePromise(this, { isFetchMapping: false })
  //   this.isFetchMapping = false
  // }

  _doGoBackAfterSuccessMapping = () => {
    if (this._isNewMappingCoreProduct()) {
      this._goBackToProductView()
      return
    }
    this.goBack()
  }

  _isNewMappingCoreProduct = () => {
    const { editingMkpProduct, coreProduct } = this.state
    // @ts-ignore :: FIXME: แก้ mapping ให้ใช้งานได้
    const currentPairedProductTemplateId = editingMkpProduct.pt_id
    const selectedProductId = coreProduct.id
    return currentPairedProductTemplateId !== selectedProductId
  }

  _goBackToProductView = () => {
    // const { navigation } = this.props
    // const { state, goBack } = navigation
    // goBack('MkpProductLinkManagerView')
    util.navGoBack(this.props)
  }

  // _onXSellyVariantPickerItemsContainerLayout = (evt: LayoutChangeEvent) => {
  //   try {
  //     // console.log('_onXSellyVariantPickerItemsContainerLayout Dimensions.get(window) => ', Dimensions.get('window'))
  //     const { height } = evt.nativeEvent.layout
  //     if (height && this.state.xSellyVariantPickerItemsContainerHeight !== height) {
  //       this.setState({ xSellyVariantPickerItemsContainerHeight: height })
  //     }
  //   } catch (error) {
  //     //
  //   }
  // }

  // _onMkpProductVariantPickerOverlayLayout = (evt: LayoutChangeEvent) => {
  _onMkpProductVariantPickerOverlayLayout = () => {
    try {
      // const { height } = evt.nativeEvent.layout
      const webWindowHeight = Dimensions.get('window').height
      // console.log('_onMkpProductVariantPickerOverlayLayout webWindowHeight => ', webWindowHeight)
      if (p.op.isWeb() && webWindowHeight !== this.state.webWindowHeight) {
        this.setState({ webWindowHeight })
      }
      // }
    } catch (error) {
      //
    }
  }

  _renderMkpProductVariantPickerOverlay = () => {
    const {
      editingMkpProduct,
      variantPickerVisibleAtIndex = -1,
      isFetchMapping,
      // xSellyVariantPickerItemsContainerHeight = 300,
      webWindowHeight = 300,
    } = this.state

    if (!editingMkpProduct || _.isNil(variantPickerVisibleAtIndex) || variantPickerVisibleAtIndex < 0) {
      return null
    }

    const mkpProduct = editingMkpProduct
    const isVisible = variantPickerVisibleAtIndex >= 0
    const currentMkpVariant = mkpProduct.variants[variantPickerVisibleAtIndex]
    if (!currentMkpVariant) {
      return null
    }

    const currentMkpVariantName = currentMkpVariant.name
    // const currentMkpVariantPpvUUID = currentMkpVariant.ppv_uuid
    // const currentMappedCoreProduct = this._findMappeCoreProductFromMkpPpvUUID(currentMkpVariantPpvUUID)
    // const currentMappedCoreProductVariantName = currentMappedCoreProduct ? currentMappedCoreProduct.name : null
    // // if (currentMkpVariantName.length > 40) {
    // //   currentMkpVariantName = currentMkpVariantName.substring(0, 40)
    // // }

    // // const handlePickMapping = (idx: number) => this._onPickVariant(variantPickerVisibleAtIndex, idx)
    const headerTitle = 'ผูกตัวเลือกสินค้า'
    const pickDescText = 'เลือกเพื่อผูกกับ...'
    // if (currentMappedCoreProductVariantName) {
    //   headerTitle = 'เปลี่ยนการผูกตัวเลือกสินค้า'
    //   pickDescText = 'เลือกเพื่อเปลี่ยนไปผูกกับ...'
    // }

    // const complexRenderVariantMappingPickerItem = (coreV: IProductDetailVariant, idx: number) => {
    //   const isCurrentMappedCoreVariantItem = currentMappedCoreProduct && currentMappedCoreProduct.pp_id === coreV.pp_id
    //   if (isCurrentMappedCoreVariantItem) {
    //     return null
    //   }
    //   return this._renderVariantMappingPickerItem(coreV, idx)
    // }

    return (
      <XOverlay visible={isVisible} onRequestClose={this._onRequestCloseProductVariantPicker}>
        <VStack w='full' flexWrap='wrap' onLayout={this._onMkpProductVariantPickerOverlayLayout}>
          <XCustomHeader
            headerRightProps={{
              closeIcon: true,
              submitting: isFetchMapping,
              onPressItem: this._onRequestCloseProductVariantPicker,
            }}
            title={headerTitle}
          />

          <VStack w='full' p='2' space='1'>
            <HStack w='full' pt='2' space='1'>
              {/* <XText variant='inactive'>สำหรับ</XText> */}
              <XText variant='inactive'>{`สำหรับตัวเลือกที่ ${variantPickerVisibleAtIndex + 1}:`}</XText>
              <XText flex={1} textAlign='right' color={COLORS.SECONDARY_MAIN} bold>
                {currentMkpVariantName}
              </XText>
            </HStack>
            {/* {currentMappedCoreProductVariantName ? (
              <View style={[S.ROW_MIDDLE_BETWEEN, S.MARGIN_VERTICAL_4, { paddingRight: 48 }]}>
                <XText variant='inactive'>เดิมผูกกับ</XText>
                <XText variant='primary' bold>
                  {currentMappedCoreProductVariantName}
                </XText>
              </View>
            ) : null} */}

            <VStack w='full'>
              <XText flex={1} variant='inactive'>
                {pickDescText}
              </XText>
            </VStack>

            {this.renderXSellyProductInfo()}

            {/* <View style={{ height: 4 }} /> */}

            {/* <VStack w='full' minH='250px' flex={1} onLayout={this._onXSellyVariantPickerItemsContainerLayout}> */}
            <XScrollView
              // maxHeight={p.op.isWeb() && webWindowHeight < 700 ? '300px' : '500px'}
              maxHeight={p.op.isWeb() && webWindowHeight < 700 ? '300px' : '300px'}
              // style={p.op.isWeb() ? { maxHeight: xSellyVariantPickerItemsContainerHeight } : undefined}
              // style={{ maxHeight: Dimensions.get('window').height - 200 }}
            >
              <VStack w='full' p='1' space='1' borderRadius='lg' _light={{ bg: 'muted.100' }}>
                {this.renderXSellyVariantPickerItems()}
              </VStack>
            </XScrollView>
            {/* </VStack> */}
            {/* <ScrollView style={{ maxHeight: 450 }}>{coreProduct.variants.map(complexRenderVariantMappingPickerItem)}</ScrollView> */}

            {/* <View style={[S.MARGIN_VERTICAL_8]}>
            <XText>{'ยังไม่ถูกเลือก'}</XText>
          </View>
          <View>{coreVariants.map(_renderCoreVariantItem)}</View>
          <View style={[S.MARGIN_VERTICAL_8]}>
            <XText>{'ถูกเลือกแล้ว'}</XText>
          </View>
          <View>{coreVariants.map(_renderCoreVariantItem)}</View> */}
          </VStack>
        </VStack>
      </XOverlay>
    )
  }

  renderXSellyVariantPickerItems = () => {
    const { xSellyProduct: xProduct } = this.state
    if (!xProduct || !xProduct.variants) {
      return null
    }
    return xProduct.variants.map(this.renderXSellyVariantPickerItem)
  }

  onXSellyVariantPickerItem = (pickedXSellyIndex: number) => {
    const { editingMkpProduct, variantPickerVisibleAtIndex, newPairedMap = {}, xSellyProduct: xProduct } = this.state
    const mkpVariantIndex = variantPickerVisibleAtIndex
    // console.log('onXSellyVariantPickerItem mkpVariantIndex => ', mkpVariantIndex)
    // console.log('onXSellyVariantPickerItem pickedXSellyIndex => ', pickedXSellyIndex)

    const focusMkpVariant = editingMkpProduct.variants[mkpVariantIndex]
    const pickedPpId = xProduct.variants[pickedXSellyIndex].pp_id

    const computeNewPairedMap = _.cloneDeep(newPairedMap)

    const pairedKeys = Object.keys(computeNewPairedMap)

    for (let i = 0; i < pairedKeys.length; i++) {
      const pairedPpvUuid = pairedKeys[i]
      const pairedPpId = computeNewPairedMap[pairedPpvUuid]

      if (pairedPpId === pickedPpId) {
        delete computeNewPairedMap[pairedPpvUuid]
      }
    }

    // computeNewPairedMap[focusMkpVariant.ppv_uuid] = pickedPpId
    const { item_id, item_variant_id = null } = focusMkpVariant
    computeNewPairedMap[`${item_id}_${item_variant_id}`] = pickedPpId

    // console.log('onXSellyVariantPickerItem computeNewPairedMap => ', computeNewPairedMap)

    this.setState({ variantPickerVisibleAtIndex: -1, newPairedMap: computeNewPairedMap })
  }

  renderXSellyVariantPickerItem = (variant: IProductDetailVariant, index: number) => {
    const handlePressPickerItem = () => this.onXSellyVariantPickerItem(index)

    // console.log('renderXSellyVariantPickerItem variant => ', variant)
    const { xSellyProduct: xProduct } = this.state
    const hasOnlyOneVariant = xProduct.variants.length === 1

    const isEmptyVariantName = _.isNil(variant.name) || variant.name === ''
    // let variantName = isEmptyVariantName ? '(ไม่มีชื่อ)' : variant.name
    let variantName = isEmptyVariantName ? '' : variant.name

    if (hasOnlyOneVariant) {
      variantName = 'ยืนยัน'
    }

    return (
      <XCard key={`XSellyVariantPickerItem_${index}`} mt='1' onPress={handlePressPickerItem}>
        <XCard.Body>
          <HStack w='full' alignItems='center'>
            <XSellyLogo />
            <VStack flex={1} px='1' alignItems='flex-end'>
              <XText variant='primary' bold>
                {variantName}
              </XText>
            </VStack>
            <ForwardIcon color={COLORS.SECONDARY_MAIN} />
          </HStack>
        </XCard.Body>
      </XCard>
    )
  }

  // _renderMkpProductVariantPickerOverlay = () => {
  //   const { editingMkpProduct, coreProduct, variantPickerVisibleAtIndex = -1, isFetchMapping } = this.state

  //   if (!editingMkpProduct || !coreProduct || !coreProduct.id) {
  //     return null
  //   }

  //   // return (
  //   //   <MkpProductVariantPicker
  //   //     visibleAtIndex={variantPickerVisibleAtIndex}
  //   //     coreProduct={selectedProduct.toJS()}
  //   //     mkpProduct={editingMkpProduct}
  //   //     onPickVariant={this._onPickVariant}
  //   //     onRequestClose={this._onRequestCloseProductVariantPicker}
  //   //   />
  //   // )

  //   const mkpProduct = editingMkpProduct
  //   const isVisible = variantPickerVisibleAtIndex >= 0
  //   const currentMkpVariant = mkpProduct.variants[variantPickerVisibleAtIndex]
  //   if (!currentMkpVariant) {
  //     return null
  //   }

  //   const currentMkpVariantName = currentMkpVariant.name
  //   const currentMkpVariantPpvUUID = currentMkpVariant.ppv_uuid
  //   const currentMappedCoreProduct = this._findMappeCoreProductFromMkpPpvUUID(currentMkpVariantPpvUUID)
  //   const currentMappedCoreProductVariantName = currentMappedCoreProduct ? currentMappedCoreProduct.name : null
  //   // if (currentMkpVariantName.length > 40) {
  //   //   currentMkpVariantName = currentMkpVariantName.substring(0, 40)
  //   // }

  //   // const handlePickMapping = (idx: number) => this._onPickVariant(variantPickerVisibleAtIndex, idx)
  //   let headerTitle = 'ผูกตัวเลือกสินค้า'
  //   let pickDescText = 'เลือกเพื่อผูกกับ...'
  //   if (currentMappedCoreProductVariantName) {
  //     headerTitle = 'เปลี่ยนการผูกตัวเลือกสินค้า'
  //     pickDescText = 'เลือกเพื่อเปลี่ยนไปผูกกับ...'
  //   }

  //   const complexRenderVariantMappingPickerItem = (coreV: IProductDetailVariant, idx: number) => {
  //     const isCurrentMappedCoreVariantItem = currentMappedCoreProduct && currentMappedCoreProduct.pp_id === coreV.pp_id
  //     if (isCurrentMappedCoreVariantItem) {
  //       return null
  //     }
  //     return this._renderVariantMappingPickerItem(coreV, idx)
  //   }
  //   return (
  //     <XOverlay contentStyle={{ padding: 8 }} visible={isVisible} onRequestClose={this._onRequestCloseProductVariantPicker}>
  //       <View style={{ flex: 1, backgroundColor: 'white' }}>
  //         <XCustomHeader
  //           headerRightProps={{
  //             closeIcon: true,
  //             submitting: isFetchMapping,
  //             onPressItem: this._onRequestCloseProductVariantPicker,
  //           }}
  //           title={headerTitle}
  //         />
  //         <View
  //           style={{
  //             height: 1,
  //             width: '100%',
  //             borderTopWidth: 0.5,
  //             borderTopColor: p.op.isWeb() ? COLORS.BG_LIGHT_GREY : COLORS.TEXT_INACTIVE,
  //           }}
  //         />
  //         <View style={[S.WIDTH_FULL]}>
  //           <View style={[S.ROW_MIDDLE_BETWEEN, S.MARGIN_VERTICAL_4, { paddingRight: 48 }]}>
  //             <XText variant='inactive'>สำหรับ</XText>
  //             <XText style={[S.TEXT_BLUE, S.TEXT_BOLD]}>{currentMkpVariantName}</XText>
  //           </View>
  //           {currentMappedCoreProductVariantName ? (
  //             <View style={[S.ROW_MIDDLE_BETWEEN, S.MARGIN_VERTICAL_4, { paddingRight: 48 }]}>
  //               <XText variant='inactive'>เดิมผูกกับ</XText>
  //               <XText variant='primary' bold>
  //                 {currentMappedCoreProductVariantName}
  //               </XText>
  //             </View>
  //           ) : null}

  //           <View style={[S.MARGIN_VERTICAL_4]}>
  //             <XText variant='inactive'>{pickDescText}</XText>
  //           </View>
  //           <CoreProductInfo product={coreProduct} />
  //           <View style={{ height: 4 }} />
  //           {/* <CurrentMkpVariant variant={currentMkpVariant} /> */}
  //           {/* <CoreProductDisplay product={coreProduct} /> */}
  //           {/* <CoreVariantItem variant={coreProduct.variants[0]} idx={0} onPress={handlePickMapping} /> */}
  //           <ScrollView style={{ maxHeight: 450 }}>{coreProduct.variants.map(complexRenderVariantMappingPickerItem)}</ScrollView>
  //           {/* <View style={[S.MARGIN_VERTICAL_8]}>
  //           <XText>{'ยังไม่ถูกเลือก'}</XText>
  //         </View>
  //         <View>{coreVariants.map(_renderCoreVariantItem)}</View>
  //         <View style={[S.MARGIN_VERTICAL_8]}>
  //           <XText>{'ถูกเลือกแล้ว'}</XText>
  //         </View>
  //         <View>{coreVariants.map(_renderCoreVariantItem)}</View> */}
  //         </View>
  //       </View>
  //     </XOverlay>
  //   )
  // }

  _computeIsDirtyMapping = () => {
    const { newPairedMap = {} } = this.state
    return newPairedMap && !_.isEmpty(newPairedMap)
    // const { oldPairedMap = {}, newPairedMap } = this.state
    // const objDiff = DeepObjectDiff.diff(oldPairedMap, newPairedMap)
    // return !_.isEmpty(objDiff)
  }

  // _computeIsMappingValid = () => {
  //   const { editingMkpProduct, newPairedMap } = this.state
  //   const allMappingPpvUUIDs = editingMkpProduct.variants.map((mkpV) => mkpV.ppv_uuid)
  //   let isMappingValid = true
  //   for (const ppvUUID of allMappingPpvUUIDs) {
  //     if (!newPairedMap[ppvUUID]) {
  //       isMappingValid = false
  //     }
  //   }
  //   return isMappingValid
  // }

  // _computeHasMissingVariants = () => {
  //   const deletedDiff = this._computeMissingMappingVariants()
  //   const hasMissingVariants = !_.isEmpty(deletedDiff)
  //   return hasMissingVariants
  // }

  // _computeMissingMappingVariants = () => {
  //   const { oldPairedMap, newPairedMap, editingMkpProduct } = this.state
  //   const idealPairedMap = oldPairedMap
  //   if (_.isEmpty(idealPairedMap)) {
  //     editingMkpProduct.variants.forEach((mkpV) => {
  //       idealPairedMap[mkpV.ppv_uuid] = 1 // 1 คือค่าสำหรับแทน pp_id เพื่อบ่งบอกว่ามีค่าเฉยๆ ไม่ได้นำไปใช้จริง
  //     })
  //   }
  //   const deletedDiff = DeepObjectDiff.deletedDiff(idealPairedMap, newPairedMap)
  //   return deletedDiff
  // }

  // _computeUpdatedMappingVariants = () => {
  //   const { oldPairedMap, newPairedMap } = this.state
  //   let updatedDiff = DeepObjectDiff.updatedDiff(oldPairedMap, newPairedMap)
  //   if (_.isEmpty(oldPairedMap)) {
  //     updatedDiff = DeepObjectDiff.addedDiff(oldPairedMap, newPairedMap)
  //   }
  //   return updatedDiff
  // }

  // _renderSummaryUpdatedMappingVariants = () => {
  //   const updatedDiff = this._computeUpdatedMappingVariants()
  //   const updatedPpvUUIDs = Object.keys(updatedDiff)
  //   return updatedPpvUUIDs.map(this._renderSummaryUpdatedMappingVariantItem)
  // }

  // _renderSummaryUpdatedMappingVariantItem = (focusPPvUUID: string, idx: number) => {
  //   const { editingMkpProduct, coreProduct, oldPairedMap, newPairedMap } = this.state

  //   const oldPPID = oldPairedMap[focusPPvUUID] || null
  //   const newPPID = newPairedMap[focusPPvUUID]

  //   const mkpId = editingMkpProduct.mkp_id
  //   const mkpVariantItem = editingMkpProduct.variants.find((mkpV) => mkpV.ppv_uuid === focusPPvUUID)
  //   const mkpVariantName = mkpVariantItem ? mkpVariantItem.name : null

  //   const oldCoreVariant = oldPPID ? coreProduct.variants.find((coreV) => coreV.pp_id === oldPPID) : null
  //   const oldCoreVariantName = oldCoreVariant ? oldCoreVariant.name : null
  //   const newCoreVariant = coreProduct.variants.find((coreV) => coreV.pp_id === newPPID)
  //   const newCoreVariantName = newCoreVariant.name
  //   // return (
  //   //   <View key={idx} style={[S.ROW_MIDDLE_START, { marginTop: 4 }]}>
  //   //     <View key={idx} style={[S.ROW_MIDDLE_START, { marginTop: 4 }]}>
  //   //       <MkpLogo mkpId={mkpId} width={STYLES.FONT_ICON_NORMAL} height={STYLES.FONT_ICON_NORMAL} />
  //   //       <XText variant='inactive'>{` ${mkpVariantName}`}</XText>
  //   //     </View>
  //   //     <XText variant='inactive'>{' ผูกกับ '}</XText>
  //   //     <View key={idx} style={[S.ROW_MIDDLE_START, { marginTop: 4 }]}>
  //   //       <XSellyLogo width={STYLES.FONT_ICON_NORMAL} height={STYLES.FONT_ICON_NORMAL} />
  //   //       {oldCoreVariantName ? (
  //   //         <XText style={[S.TEXT_INACTIVE, { textDecorationLine: 'line-through', textDecorationStyle: 'solid' }]}>
  //   //           {oldCoreVariantName}
  //   //         </XText>
  //   //       ) : null}
  //   //       <XText style={[S.TEXT_BLUE]}>{newCoreVariantName}</XText>
  //   //     </View>
  //   //   </View>
  //   // )
  //   return (
  //     <View key={idx} style={[S.BUTTON_OUTLINE_BLUE, S.ROW_MIDDLE_BETWEEN, S.MARGIN_VERTICAL_4]}>
  //       <View style={[S.FLEX]}>
  //         {/* LINE 1 - MKP Product Variant Name */}
  //         <MappingMkpVariantNameItem mkpId={mkpId} label='ชื่อ' value={mkpVariantName} />

  //         <PairText label='จะผูกกับ' />

  //         {/* LINE 2 - Core Product Variant Name */}
  //         <LineThroughMappingCoreVariantNameItem label='' oldValue={oldCoreVariantName} newValue={newCoreVariantName} />

  //         {/* --- */}
  //       </View>
  //     </View>
  //   )
  // }

  // _renderSummaryMissingMappingVariants = () => {
  //   const { oldPairedMap, newPairedMap } = this.state
  //   const deletedDiff = DeepObjectDiff.deletedDiff(oldPairedMap, newPairedMap)
  //   const missingPpvUUIDs = Object.keys(deletedDiff)
  //   return missingPpvUUIDs.map(this._renderSummaryMissingMappingVariantItem)
  // }

  // _renderSummaryMissingMappingVariantItem = (missingPPvUUID: string, idx: number) => {
  //   const { editingMkpProduct, coreProduct } = this.state
  //   const mkpId = editingMkpProduct.mkp_id
  //   const mkpVariantItem = editingMkpProduct.variants.find((mkpV) => mkpV.ppv_uuid === missingPPvUUID)
  //   const mkpVariantName = mkpVariantItem.name
  //   return (
  //     <View key={idx} style={[S.ROW_MIDDLE_START, { marginTop: 4 }]}>
  //       <MkpLogo mkpId={mkpId} width={STYLES.FONT_ICON_NORMAL} height={STYLES.FONT_ICON_NORMAL} />
  //       <XText style={[S.TEXT_BLUE]}>{` ${mkpVariantName}`}</XText>
  //     </View>
  //   )
  // }

  // _renderMappingSummaryOverlay = () => {
  //   const {
  //     editingMkpProduct,
  //     coreProduct,
  //     isMappingSummaryVisible = false,
  //     oldPairedMap,
  //     newPairedMap,
  //     isFetchMapping,
  //     isOneToOneVariantMapping,
  //   } = this.state

  //   if (!isMappingSummaryVisible) {
  //     return null
  //   }

  //   const isNewMapping = _.isEmpty(oldPairedMap)
  //   const isMappingValid = this._computeIsMappingValid()
  //   const hasMissingVariants = this._computeHasMissingVariants()
  //   // const hasUpdatedVariants = !_.isEmpty(this._computeUpdatedMappingVariants())

  //   const titleText = hasMissingVariants ? 'ผูกตัวเลือกยังไม่ครบ' : `ยืนยัน${isNewMapping ? '' : 'สลับ'}การผูกสินค้า`
  //   const descText = isNewMapping ? 'กรุณายืนยันการผูกตัวเลือกสินค้า ดังต่อไปนี้' : 'กรุณายืนยันสลับการผูกตัวเลือก ดังต่อไปนี้'

  //   const submitBtnStyle = [...BASE_SUBMIT_BTN_STYLE, isFetchMapping ? S.BUTTON_INACTIVE : S.BUTTON_PRIMARY]
  //   const closeOverlayBtnStyle = [...BASE_CLOSE_OVERLAY_BTN_STYLE, isFetchMapping ? S.BUTTON_OUTLINE : S.BUTTON_OUTLINE_PRIMARY]
  //   const confirmButtonLabel = isOneToOneVariantMapping ? 'ยืนยัน' : 'ตรวจสอบและยืนยัน'
  //   return (
  //     <XOverlay
  //       contentStyle={{ padding: 8 }}
  //       visible={isMappingSummaryVisible}
  //       onRequestClose={isFetchMapping ? null : this._closeMappingSummary}>
  //       <View style={{ flex: 1, backgroundColor: 'white' }}>
  //         <XCustomHeader
  //           headerRightProps={{ closeIcon: true, submitting: isFetchMapping, onPressItem: this._closeMappingSummary }}
  //           title={titleText}
  //         />

  //         {/* line */}
  //         <View
  //           style={{
  //             height: 1,
  //             width: '100%',
  //             borderTopWidth: 0.5,
  //             borderTopColor: p.op.isWeb() ? COLORS.BG_LIGHT_GREY : COLORS.TEXT_INACTIVE,
  //           }}
  //         />
  //         <View style={[S.WIDTH_FULL]}>
  //           {/* desctiption word */}
  //           {!isOneToOneVariantMapping && isMappingValid ? (
  //             <View style={[S.ROW_MIDDLE_BETWEEN, S.MARGIN_VERTICAL_4]}>
  //               <XText variant='inactive'>{descText}</XText>
  //             </View>
  //           ) : null}
  //           {hasMissingVariants ? (
  //             <View style={[S.ROW_MIDDLE_BETWEEN, S.MARGIN_VERTICAL_4]}>
  //               <XText variant='inactive'>ตัวเลือกเหล่านี้ยังไม่ได้รับการผูก</XText>
  //             </View>
  //           ) : null}

  //           {/* diff content */}
  //           <View style={{ height: 4 }} />

  //           {/* Hide variant mapping info if it's only one variant on both XSelly and MKP */}
  //           {isOneToOneVariantMapping ? null : (
  //             <ScrollView style={{ maxHeight: 450 }}>
  //               {hasMissingVariants ? this._renderSummaryMissingMappingVariants() : this._renderSummaryUpdatedMappingVariants()}
  //             </ScrollView>
  //           )}

  //           <View style={{ height: 8 }} />
  //         </View>
  //         <View style={{ height: 4 }} />
  //         {/* buttons */}
  //         {isMappingValid ? (
  //           <View style={[S.WIDTH_FULL, S.ROW_MIDDLE_BETWEEN]}>
  //             <TouchableOpacity
  //               disabled={isFetchMapping}
  //               onPress={this._closeMappingSummary}
  //               style={closeOverlayBtnStyle}
  //               // style={[S.BUTTON_OUTLINE_PRIMARY, S.ROW_CENTER, S.NO_FLEX, { width: 54, marginRight: 4, minHeight: 44 }]}
  //             >
  //               <XText style={[isFetchMapping ? S.TEXT_INACTIVE : S.TEXT_PRIMARY, S.TEXT_BOLD, S.TEXT_LARGER]}>ปิด</XText>
  //             </TouchableOpacity>
  //             <View style={S.FLEX}>
  //               <TouchableOpacity
  //                 disabled={isFetchMapping}
  //                 onPress={this._doFetchApiMkpProductMapping}
  //                 style={submitBtnStyle}
  //                 // style={[S.BUTTON_PRIMARY, S.ROW_CENTER, S.WIDTH_FULL, { minHeight: 34 }]}
  //               >
  //                 {isFetchMapping ? (
  //                   <Spinner size='sm' color={COLORS.TEXT_ACTIVE} />
  //                 ) : (
  //                   <XText style={[S.TEXT_ACTIVE_DARK, S.TEXT_BOLD, S.TEXT_LARGER]}>{confirmButtonLabel}</XText>
  //                 )}
  //               </TouchableOpacity>
  //             </View>
  //           </View>
  //         ) : (
  //           <TouchableOpacity
  //             onPress={this._onPressContinueDoMappinng}
  //             style={[S.BUTTON_OUTLINE_PRIMARY, S.ROW_CENTER, S.WIDTH_FULL, { minHeight: 44 }]}>
  //             <XText style={[S.TEXT_PRIMARY, S.TEXT_BOLD, S.TEXT_LARGER]}>ดำเนินการผูกตัวเลือกต่อ</XText>
  //           </TouchableOpacity>
  //         )}
  //       </View>
  //     </XOverlay>
  //   )
  // }

  // _onPressContinueDoMappinng = async () => {
  //   await this._closeMappingSummary()
  //   const missingVariantMap = this._computeMissingMappingVariants()
  //   const missingPpvUUIDS = Object.keys(missingVariantMap)
  //   if (missingPpvUUIDS.length > 0) {
  //     const missingPpvUUID = missingPpvUUIDS[0]
  //     const foundIndex = this.state.editingMkpProduct.variants.findIndex((mkpV) => mkpV.ppv_uuid === missingPpvUUID)
  //     if (foundIndex >= 0) {
  //       await this._scrollToPickerButtonIndex(foundIndex)
  //     }
  //   }
  // }

  _scrollToPickerButtonIndex = async (idx: number) => {
    const cScrollRef = this.contentScrollViewRef.current

    // @ts-ignore
    if (cScrollRef && _.isFunction(cScrollRef.scrollTo) && this.mkpVariantButtonPositionYMap[idx]) {
      await util.delay(500)
      // @ts-ignore
      cScrollRef.scrollTo({ y: this.mkpVariantButtonPositionYMap[idx], animated: true })
      const hilightPickerMap = {}
      hilightPickerMap[idx] = true
      await util.setStatePromise(this, { hilightPickerMap })
      await util.delay(850)
      await util.setStatePromise(this, { hilightPickerMap: {} })
    }
  }

  // _findMappedMkpProductFromCorePPID = (ppid: number) => {
  //   const { newPairedMap = {}, editingMkpProduct } = this.state
  //   const ppvUUIDs = Object.keys(newPairedMap)
  //   for (const ppvUUID of ppvUUIDs) {
  //     const mappedPPID = newPairedMap[ppvUUID]
  //     if (mappedPPID === ppid) {
  //       return editingMkpProduct.variants.find((mkpV) => mkpV.ppv_uuid === ppvUUID)
  //     }
  //   }
  //   return null
  // }

  // _onPickNewMappingVariant = (newCoreIdx: number) => {
  //   const { newPairedMap, editingMkpProduct, coreProduct, variantPickerVisibleAtIndex } = this.state
  //   const focusMkpIdx = variantPickerVisibleAtIndex
  //   const updatedNewPairedMap = _.cloneDeep(newPairedMap)
  //   const focusMkpPpvUUID = editingMkpProduct.variants[focusMkpIdx].ppv_uuid
  //   const pickedCoreVariantPPID = coreProduct.variants[newCoreIdx].pp_id

  //   // เช็ค pp_id ซ้ำ
  //   const ppvUUIDs = Object.keys(updatedNewPairedMap)
  //   for (const currentPpvUUID of ppvUUIDs) {
  //     const oldMappedPPID = updatedNewPairedMap[currentPpvUUID]
  //     if (oldMappedPPID === pickedCoreVariantPPID) {
  //       delete updatedNewPairedMap[currentPpvUUID]
  //     }
  //   }

  //   // ใส่ pair ตัวใหม่เข้าไป
  //   updatedNewPairedMap[focusMkpPpvUUID] = pickedCoreVariantPPID

  //   this.setState({ newPairedMap: updatedNewPairedMap, variantPickerVisibleAtIndex: -1 })
  // }

  // _renderVariantMappingPickerItem = (coreV: IProductDetailVariant, idx: number) => {
  //   const { editingMkpProduct } = this.state
  //   const currentPPID = coreV.pp_id
  //   const mappedMkpVariant = this._findMappedMkpProductFromCorePPID(currentPPID)
  //   return (
  //     <>
  //       <VariantMappingPicker
  //         key={idx}
  //         index={idx}
  //         coreVariant={coreV}
  //         mkpId={editingMkpProduct.mkp_id}
  //         mkpVariant={mappedMkpVariant}
  //         onPress={this._onPickNewMappingVariant}
  //       />
  //       <View style={{ height: 6 }} />
  //     </>
  //   )
  // }

  _findMappeCoreProductFromMkpPpvUUID = (ppvUUID: string) => {
    const { newPairedMap = {}, coreProduct } = this.state
    if (!coreProduct || !coreProduct.id) {
      return null
    }

    const mappedPPID = newPairedMap[ppvUUID] || null
    if (!mappedPPID) {
      return null
    }

    return coreProduct.variants.find((coreV) => coreV.pp_id === mappedPPID)
  }

  _onVariantMappingButtonMeasurePositionY = (posY: number, idx: number) => {
    if (!this.mkpVariantButtonPositionYMap) {
      this.mkpVariantButtonPositionYMap = {}
    }
    this.mkpVariantButtonPositionYMap[idx] = posY
    // console.log('this.mkpVariantButtonPositionYMap => ', this.mkpVariantButtonPositionYMap)
  }

  renderHeaderStoreName = () => {
    const { selectedStore } = this.props
    const { editingMkpProduct } = this.state
    if (!editingMkpProduct || _.isEmpty(editingMkpProduct)) {
      return null
    }
    // const mkpChId = editingMkpProduct.mkp_channel_id
    const mkpChId = editingMkpProduct.mkp_ch_id
    const channels = selectedStore.get('channels')
    if (!channels || channels.size === 0) {
      return null
    }
    // const updateAt = moment(editingMkpProduct.updated_at).fromNow()
    const channel = channels.find((ch) => ch.get('id') === mkpChId)
    if (!channel) {
      console.log('renderHeaderStoreName editingMkpProduct => ', editingMkpProduct)
      return null
    }

    return (
      <VStack w='full' p='1'>
        <HStack bg='blue.100' w='full' alignItems='center' p='2' mb='1' space='1.5'>
          <MkpLogo mkpId={channel.get('mkp_id')} />
          <VStack flex={1}>
            <XText numberOfLines={1} bold>
              {channel.get('name')}
            </XText>
            <XText variant='inactive'>{`อัพเดตเมื่อ ${editingMkpProduct.updated_at}`}</XText>
          </VStack>
        </HStack>
      </VStack>
    )
  }

  renderContent = () => {
    const { editingMkpProduct, isInitialized, coreProduct } = this.state
    if (!isInitialized || !editingMkpProduct) {
      return null
    }
    return (
      <XCard w='full'>
        <XCard.Body>
          <VStack w='full'>
            {this.renderHeaderStoreName()}
            <VStack w='full' space='2'>
              {this.renderProductImages()}
              {this.renderProductName()}
              {this.renderProductDescription()}
              {/* {this.renderProductPairedProductTemplates()} */}
              {this.renderProductVariants()}
              {/* <XText>{JSON.stringify(editingMkpProduct)}</XText> */}
            </VStack>
          </VStack>
        </XCard.Body>
      </XCard>
    )
  }

  onRadioPress = (vIndex: number) => {
    const { checkedVariantIndex = 0 } = this.state
    if (vIndex !== checkedVariantIndex) {
      this.setState({ checkedVariantIndex: vIndex })
    }
  }

  _renderVariantIdLabel = (variant: IMkpProductDetailVariant, index: number) => {
    const v = variant

    if (!v || !v.item_variant_id) {
      return null
    }

    const id = v.item_variant_id
    return (
      <HStack w='full' position='absolute' left='0' right='0' bottom='0' px='1'>
        <XText
          variant='inactive'
          fontSize='2xs'
          numberOfLines={1}
          onPress={() => {
            // @ts-ignore
            Clipboard.setString(id.toString())
            p.op.showToast(`คัดลอก ${id} แล้ว`, 'success')
          }}>
          {`ID:${id}`}
        </XText>
      </HStack>
    )
  }

  // override
  renderProductVariantItemContainer = (variant: IMkpProductDetailVariant, index: number) => {
    const { checkedVariantIndex = 0, pairedPtVisibleMap, editingMkpProduct } = this.state
    const pairedPtId = variant.paired_pt_id || 0
    const isVisible = pairedPtId === 0 ? true : !!pairedPtVisibleMap[pairedPtId]

    if (!isVisible) {
      return null
    }

    const isVariantChecked = checkedVariantIndex === index

    const isEven = index % 2 === 0
    // let  backgroundColor = isEven ? COLORS.BG_LIGHT_GREY : COLORS.WHITE
    let bg = isEven ? 'muted.100' : 'white'
    if (isVariantChecked) {
      bg = 'primary.100'
    }

    let variantThumbnailUri = editingMkpProduct.thumbnail_uris[0] || null

    if (variant.img_uri && variant.img_uri !== '') {
      variantThumbnailUri = variant.img_uri
    }

    return (
      <XCard
        key={`ProductVariantItem_${index.toString()}`}
        w='full'
        bg={bg}
        // style={{ backgroundColor }}
        onPress={() => this.onRadioPress(index)}>
        <VStack w='full'>
          <HStack w='full' p='1' alignItems='center'>
            <VStack h='32px' w='32px'>
              <XRadio checked={isVariantChecked} onPress={() => this.onRadioPress(index)} />
            </VStack>

            <VStack flex={1} space='1.5'>
              {/* <HStack w='full' p='2' space='1' alignItems='center' justifyContent='center'>
                {this.renderMkpIcon()}
                <XText variant='inactive' bold>{`ตัวเลือกที่ ${index + 1}`}</XText>
                <Box h='24px' w='24px' />
              </HStack> */}
              <HStack w='full' px='2' py='1' space='1' alignItems='center' justifyContent='center'>
                {variantThumbnailUri && variantThumbnailUri !== '' && (
                  <VStack w='16' h='16'>
                    <XImage w='16' h='16' borderRadius='lg' resizeMode='cover' source={{ uri: variantThumbnailUri }} />

                    <Box position='absolute' top='1' left='1'>
                      {this.renderMkpIcon()}
                    </Box>
                  </VStack>
                )}

                <HStack flex={1} space='1' alignItems='center' justifyContent='center'>
                  {this.renderMkpIcon()}
                  <XText variant='inactive' bold>{`ตัวเลือกที่ ${index + 1}`}</XText>
                  <Box h='24px' w='24px' />
                </HStack>

                {variantThumbnailUri && variantThumbnailUri !== '' && <Box w='16' h='16' />}
              </HStack>

              <VStack flex={1} pb='2.5'>
                {this.renderProductVariantItemContent(variant, index)}
              </VStack>
            </VStack>
          </HStack>

          {this._renderVariantIdLabel(variant, index)}
        </VStack>
      </XCard>
    )
  }

  // override
  renderProductVariantItemContent = (variant: IMkpProductDetailVariant, index: number) => {
    const { editingMkpProduct } = this.state
    // const vCount = this.getVariantCount()

    const mkpId = editingMkpProduct.mkp_id
    return (
      <VStack key={`${index}_${mkpId}`} w='full' space='1.5'>
        {this.renderVariantName({ index, variant, mkpId })}
        {/* {this.renderVariantSalePrice({ index, variant, mkpId })} */}
        {/* {this.renderVariantPromotionPrice({ index, variant, mkpId })} */}
        {this.renderPairedXSellyProductVariantInfo({ index, variant, mkpId })}
      </VStack>
    )
  }

  renderMain = () => {
    const renderNavReference = util.getNavParam(this.props, 'renderNavReference')

    return (
      <XContainer>
        {_.isFunction(renderNavReference) ? renderNavReference() : null}
        {this.renderCustomHeader()}
        <XContent ref={this.contentScrollViewRef} refreshControl={this.renderRefreshControl()}>
          <VStack p='1' w='full'>
            {this.renderContent()}
          </VStack>
        </XContent>
        {this.renderFooter()}
      </XContainer>
    )
  }

  render() {
    return this.renderMain()
  }
}

const ProductVariantXSellyToMkpMappingViewContainer = withMkpProductContextPropsAndRef(ProductVariantXSellyToMkpMappingView)
// // @ts-ignore
// ProductVariantXSellyToMkpMappingViewContainer.navigationOptions = { header: null }

export default connect(
  (state) => ({
    ...getState(state),
  }),
  (dispatch) => ({
    ...getDispatch(dispatch),
  })
)(ProductVariantXSellyToMkpMappingViewContainer)
