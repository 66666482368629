import React, { useRef, useEffect, useState } from 'react'

import { Animated, StyleSheet } from 'react-native'
import _ from 'lodash'

import BaseUIShipView from 'xui/modules/shipment/BaseUIShipView'

import { COLORS } from 'x/config/styles'

import { logRender } from 'x/utils/util'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContainer from 'xui/components/XContainer'
import ThemeSizeContext from '../../context/ThemeSizeContext'
// import styled, { keyframes } from 'styled-components'

// import { slideInUp, slideOutDown } from 'react-animations'
import BarcodeListener from '../../components/BarcodeListener'
// const motionInAnimation = keyframes`${slideInUp}`
// const motionOutAnimation = keyframes`${slideOutDown}`

// const barcodeBorder = require('../../../images/barcode-marker.png')
// const AnimateView = styled(View)`
//   animation: 0.2s ${props => (props.isModalExiting ? motionOutAnimation : motionInAnimation)};
//   background-color: white;
// `

const AnimateView = (props) => {
  const { isModalVisible = false, isModalExiting = false, style = {} } = props
  const [isHidden, setIsHidden] = useState(true)
  // fadeAnim will be used as the value for opacity. Initial Value: 0
  const fadeAnim = useRef(new Animated.Value(0)).current
  const slideAnim = useRef(new Animated.Value(600)).current
  // const isHidden = isModalVisible && fadeAnim.addListener()
  const fadeIn = () => {
    // Will change fadeAnim value to 1 in 5 seconds
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 350,
      useNativeDriver: true,
    }).start()
  }

  const fadeOut = () => {
    // Will change fadeAnim value to 0 in 5 seconds
    Animated.timing(fadeAnim, {
      toValue: 0,
      duration: 350,
      useNativeDriver: true,
    }).start()
  }

  const slideIn = () => {
    // Will change slideAnim value to 1 in 5 seconds
    Animated.timing(slideAnim, {
      toValue: 0,
      duration: 450,
      useNativeDriver: true,
    }).start()
  }

  const slideOut = () => {
    // Will change fadeAnim value to 0 in 5 seconds
    Animated.timing(slideAnim, {
      toValue: 600,
      duration: 450,
      useNativeDriver: true,
    }).start()
  }

  useEffect(() => {
    fadeAnim.addListener(({ value }) => {
      if (value === 0) {
        setIsHidden(true)
      } else {
        setIsHidden(false)
      }
    })
  }, [])

  useEffect(() => {
    if (isModalVisible) {
      fadeIn()
      slideIn()
    } else {
      slideOut()
      fadeOut()
    }
  }, [isModalVisible])

  const hiddenStyle = isHidden
    ? {
        display: 'none',
      }
    : {}

  return (
    <Animated.View
      {...props}
      style={[
        StyleSheet.flatten(style),
        {
          opacity: fadeAnim, // Bind opacity to animated value
          backgroundColor: COLORS.WHITE,
          top: slideAnim,
        },
        hiddenStyle,
      ]}
    />
  )
}

class ShipView extends BaseUIShipView {
  static displayName = 'ShipView'

  static contextType = ThemeSizeContext

  context!: React.ContextType<typeof ThemeSizeContext>

  barcodeSound?: { ring: () => void; release: () => void }
  // cameraRef: React.RefObject<RNCamera>

  // constructor(props) {
  //   super(props)
  //   this.cameraRef = React.createRef()
  // }

  async componentDidMount() {
    await super.componentDidMount.apply(this)
    // this.barcodeSound = new BarcodeSound()
    // this._checkCameraPermission()
  }

  componentWillUnmount() {
    super.componentWillUnmount.apply(this)
    if (this.barcodeSound && _.isFunction(this.barcodeSound.release)) {
      // this.barcodeSound.release()
    }
  }

  ringBarcodeSound = () => {
    if (_.isFunction(this.barcodeSound.ring)) {
      // this.barcodeSound.ring()
    }
  }
  // async componentWillReceiveProps(nextProps) {
  //   if (this.isUnmounting) {
  //     return
  //   }
  //   // log('componentWillReceiveProps____', nextProps)
  //   const { navigation, shipmentActions } = this.props
  //   // const { currentNavIndex } = this.state
  //   const { editingOrderShip, curQty, maxQty, navigatorStateIndex, pp_shippingTypeIndex } = nextProps
  //   // log('componentWillReceiveProps____editingOrderShip', editingOrderShip.toJS())
  //   if (editingOrderShip !== this.props.editingOrderShip) {
  //     const orderName = editingOrderShip.get('name')
  //     if (orderName) {
  //       // navigation.setParams({ txtTitle: `จัดส่งใบสั่งซื้อ #${orderName}` })
  //       await setStatePromise(this, { headerTitle: `จัดส่งใบสั่งซื้อ #${orderName}` })
  //     }
  //     const shippingTypeId = editingOrderShip.get('shipping_type_id')
  //     if (shippingTypeId) {
  //       const shippingTypeIndex = CONS.ORDER_VIEW_SHIPPING_TYPE_ITEMS.findIndex(sti => sti.value === shippingTypeId)
  //       if (shippingTypeIndex > -1) {
  //         this.setState({ shippingTypeIndex })
  //       }
  //     }
  //   }
  //
  //   if (isDiff(this.props, nextProps, ['curQty', 'maxQty'])) {
  //     navigation.setParams({ curQty, maxQty })
  //   }
  //
  //   // // ถ้ามีการเลือก shipping type ใหม่ (คาดหวังว่าจะเป้นการ nav กลับจาก page picker)
  //   // if (currentNavIndex && navigatorStateIndex >= currentNavIndex && this.props.navigatorStateIndex !== navigatorStateIndex) {
  //   //   if (navigatorStateIndex < this.props.navigatorStateIndex) { //
  //   //     if (this.shippingTypeItems[pp_shippingTypeIndex]) {
  //   //       const new_shipping_type_id = this.shippingTypeItems[pp_shippingTypeIndex].value
  //   //       if (this.props.editingOrderShip.get('shipping_type_id') !== new_shipping_type_id) {
  //   //         shipmentActions.shipmentChange({ shipping_type_id: new_shipping_type_id })
  //   //       }
  //   //     }
  //   //     this.openModal() // ให้เปิด modal กลับมา
  //   //   }
  //   // }
  // }

  // shouldComponentUpdate(nextProps, nextState) {
  //   if (this.isUnmounting) {
  //     return false
  //   }
  //   // log('__shouldComponentUpdate__: ', nextProps)
  //   const propsDiff = isDiff(this.props, nextProps, ['stores', 'selectedOrderShip',
  //     'editingOrderShip', 'curQty', 'maxQty', 'pp_shippingTypeIndex', 'navigatorStateIndex', 'barcodeScanned'])
  //   const stateDiff = isDiff(this.state, nextState, ['modalVisible'])
  //   return propsDiff || stateDiff
  // }

  // _renderCamera() {
  //   return null
  // }

  appRenderCamera = () => {
    return <BarcodeListener minLength={3} onScan={this._onBarcodeScan} />
  }

  _onBarcodeScan = (tracking_number) => {
    this.props.shipmentActions.shipmentChange({ tracking_number })
  }

  renderModalScanBarcode = () => {
    const { contentHeightStyle, contentOneOfThreeColumnWidthStyle } = this.context

    return (
      <AnimateView
        isModalVisible={this.state.modalVisible}
        isModalExiting={this.state.isModalExiting}
        style={[contentHeightStyle, contentOneOfThreeColumnWidthStyle, { position: 'absolute', left: 0 }]}>
        <XContainer>
          <XCustomHeader title='สแกนบาร์โค้ดเลขพัสดุ' headerRightProps={{ closeIcon: true, onPressItem: this.closeModal }} />
          {this.renderShipmentContent()}
          {this.renderShipmentFooter()}
        </XContainer>
      </AnimateView>
    )
  }

  render() {
    logRender(this)
    return this.renderMain()
  }
}

export default ShipView
