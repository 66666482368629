// import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { log, setStatePromise, isAlreadySelectedStore } from 'x/utils/util'
import * as util from 'x/utils/util'
import p from 'x/config/platform-specific'
import _ from 'lodash'
import { Map } from 'immutable'
import { UGListViewProps, UGViewProps, UGViewState } from 'x/index'
import CONS from 'x/config/constants'
import { canDoAtSelectedStore } from 'x/utils/acl'
import { Clipboard } from 'react-native'

const { ADD, VIEW, EDIT } = CONS.PRODUCT_GROUP_VIEW_MODE

export default class BaseUserGroup extends Component<UGViewProps, UGViewState> {
  inProcess: boolean

  // isGoback: boolean
  qrModalRef: React.RefObject<any>

  ImgMgr?: React.RefObject<any>

  DEFAULT_STATE_TEMPLATE: UGViewState

  constructor(props: UGListViewProps) {
    // @ts-ignore
    super(props)
    this.inProcess = false
    this.ImgMgr = React.createRef()
    this.qrModalRef = React.createRef()
    this.DEFAULT_STATE_TEMPLATE = _.cloneDeep(this.state)
    // this.isGoback = false

    this.state = {
      id: null,
      name: '',
      description: null,
      invite_code: null,
      invite_link: null,
      joined_count: 0,
      pending_count: 0,
      pgs_count: 0,

      isEdited: false,
      uploading: false,
      submitting: false,
      firstUpload: false,
      mode: 'VIEW',
    }
  }

  async componentDidMount() {
    const { selectedStore, fetchMyStore, navigation } = this.props
    // const { state, setParams } = navigation
    const params = util.getNavParams(this.props)

    if (!isAlreadySelectedStore()) {
      const { store_id } = params
      const body = { store_id }
      fetchMyStore({ body, successCallback: this._firstInitialize, failedCallback: this._cannotInitialize })
    } else {
      this._firstInitialize()
    }
  }

  async _firstInitialize(): Promise<void> {
    const { selectedUserGroups, navigation } = this.props
    const params = util.getNavParams(this.props)
    if (params) {
      const { mode, ug_id = null } = params
      // const doSetParams = () => {
      //   setParams({
      //     submitAddUGFunc: this._submitCreateUserGroup,
      //     submitEditUGFunc: this._submitEditUserGroup,
      //     cancelEditUGFunc: this._cancelEditUserGroup,
      //   })
      // }
      if (mode === ADD) {
        this.setState({
          mode,
        })
      } else if (_.includes([EDIT, VIEW], mode) && ug_id) {
        const foundUG = selectedUserGroups.find((ug) => Map.isMap(ug) && ug.get('id') === ug_id)
        if (!foundUG || !Map.isMap(foundUG)) {
          p.op.alert('เกิดข้อผิดพลาด', 'ไม่พบข้อมูลกลุ่มสมาชิก')
          util.navGoBack(this.props)
          return
        }

        await setStatePromise(this, {
          ...this.DEFAULT_STATE_TEMPLATE,
          // ug_id: foundUG.get('id'),
          // isEdited: false,
          ...foundUG.toJS(),
        })

        // doSetParams()
        this._loadImagesToImgMgr()
      } else {
        util.navGoBack(this.props) // should be impossible
      }
    }
  }

  _cannotInitialize() {
    // if (this.isGoback || !navigation) {
    //   return
    // }
    // this.isGoback = true
    p.op.alert('เกิดข้อผิดพลาด', 'ไม่สามารถโหลดกลุ่มสมาชิกได้ กรุณากลับไปเลือกร้านค้าใหม่อีกครั้ง')
    util.navGoBack(this.props)
  }

  async _loadImagesToImgMgr(): Promise<void> {
    // log('In _loadImagesToImgMgr')
    if (this.ImgMgr && !_.isNil(this.ImgMgr.current) && _.isFunction(this.ImgMgr.current.clearImages)) {
      await this.ImgMgr.current.clearImages()
    }

    const { img_uri = null, thumbnail_uri = null } = this.state
    if (
      this.ImgMgr &&
      !_.isNil(this.ImgMgr.current) &&
      _.isFunction(this.ImgMgr.current.loadImageUrls) &&
      (_.isString(img_uri) || _.isString(thumbnail_uri))
    ) {
      // log('In _loadImagesToImgMgr img_uri => ', img_uri)
      // log('In _loadImagesToImgMgr thumbnail_uri => ', thumbnail_uri)
      await this.ImgMgr.current.loadImageUrls({
        p: [img_uri],
        t: thumbnail_uri ? [thumbnail_uri] : null,
      })
    }
  }

  _submitCreateUserGroup = async () => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    await this._beginSubmitting()
    const { navigation, createUserGroup } = this.props
    // const { setParams, state, goBack } = navigation

    // log('_submitCreateUserGroup begin')

    const { name, description } = this.state
    const body: { [key: string]: any } = {}

    if (_.isString(name) && name.length > 4) {
      body.name = name.trim()
    } else if (_.isString(name) && name.length > 0 && name.length <= 4) {
      p.op.alert('ข้อมูลไม่ถูกต้อง', 'กรุณาระบุชื่อกลุ่มที่มีมากกว่า 4 ตัวอักษร')
      await this._endSubmitting()
      return
    } else {
      p.op.alert('ข้อมูลไม่ครบถ้วน', 'กรุณาระบุชื่อกลุ่ม')
      await this._endSubmitting()
      return
    }

    if (_.isString(description)) {
      body.description = description.trim()
    }

    // ถ้าเป็น IOS และเข้าเงื่อนไขก็ให้แจ้งเตือน // https://app.clickup.com/t/86cvy1xnd
    const isNoobIos = util.isIosNoobCheckByPass()
    if (isNoobIos) {
      p.op.showConfirmationOkOnly('', p.op.t('Subscription.warning.iosNoobCheckMsg'))
      return
    }

    const params = util.getNavParams(this.props)
    if (!params || !params.store_id) {
      p.op.alert('ไม่สามารถดึงข้อมูลร้านค้าได้', 'กรุณากลับออกไปเลือกร้านค้าแล้วดำเนินการใหม่อีกครั้ง')
      await this._endSubmitting()
      return
    }
    body.store_id = params.store_id

    // Begin Upload Image flow
    const { img_uri, thumbnail_uri, isError, isEmpty } = await this._getImgMgrSubmittedImages()
    // log('_submitCreateUserGroup img_uri => ', img_uri)
    // log('_submitCreateUserGroup thumbnail_uri => ', thumbnail_uri)
    // log('_submitCreateUserGroup isError => ', isError)
    // log('_submitCreateUserGroup isEmpty => ', isEmpty)

    if (_.isString(img_uri) && _.isString(thumbnail_uri) && !isEmpty) {
      body.img_uri = img_uri
      body.thumbnail_uri = thumbnail_uri
    } else if (isEmpty) {
      p.op.alert('ข้อมูลไม่ครบถ้วน', 'กรุณาเลือกรูปภาพสำหรับกลุ่มสมาชิกนี้')
      await this._endSubmitting()
      return
    } else if (isError) {
      p.op.alert('เกิดข้อผิดพลาด', 'ไม่สามารถแปลง หรืออัพโหลดรูปภาพได้ กรุณาลองใหม่อีกครั้ง')
      await this._endSubmitting()
      return
    }
    // End Upload Image flow

    // log('_submitCreateUserGroup body => ', body)
    if (!_.isEmpty(body) && body.name && body.store_id && body.img_uri && body.thumbnail_uri) {
      const successCallback = () => util.navGoBack(this.props)
      const res = await new Promise((submitted) => {
        createUserGroup({
          body,
          successCallback,
          failedCallback: submitted,
        })
      })
      // console.log('_submitCreateUserGroup res => ', res)
    }

    await new Promise((finishDelay) => setTimeout(finishDelay, 200))
    await this._endSubmitting()
    // log('_submitCreateUserGroup finish')
  }

  async _getImgMgrSubmittedImages(): Promise<{
    img_uri?: string
    thumbnail_uri?: string
    isError?: boolean
    isEmpty?: boolean
  }> {
    // log('begin _getImgMgrSubmittedImages')
    await setStatePromise(this, { uploading: true, firstUpload: true, isEdited: true })
    await new Promise((stateDelay) => setTimeout(stateDelay, 200))
    if (this.ImgMgr && _.isFunction(this.ImgMgr.current.submitUploadImages)) {
      const submitUploadOutput = await this.ImgMgr.current.submitUploadImages()
      const img_uris = _.has(submitUploadOutput, 'p') ? submitUploadOutput.p : null
      const thumbnail_uris = _.has(submitUploadOutput, 't') ? submitUploadOutput.t : null
      const isEmpty = _.has(submitUploadOutput, 'isEmpty') ? submitUploadOutput.isEmpty : false
      const isError = _.has(submitUploadOutput, 'isError') ? submitUploadOutput.isError : false
      if (isError) {
        return { isError: true }
      }
      // log('finished _getImgMgrSubmittedImages submitUploadOutput =>', submitUploadOutput)
      await setStatePromise(this, { uploading: false })
      return {
        img_uri: _.isArray(img_uris) && img_uris.length > 0 ? img_uris[0] : null,
        thumbnail_uri: _.isArray(thumbnail_uris) && thumbnail_uris.length > 0 ? thumbnail_uris[0] : null,
        isEmpty,
      }
    }

    // Must be impossible case
    await setStatePromise(this, { uploading: false })
    return { isError: true }
  }

  _submitEditUserGroup = async () => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    // const { isEdited } = this.state
    const { updateUserGroup, selectedUserGroups, selectedStore } = this.props
    // const { setParams, state, goBack } = navigation

    await this._beginSubmitting()
    // log('_submitEditUserGroup begin')

    const { id, name, description } = this.state
    const body: { [key: string]: any } = {}
    // Begin Upload Image flow
    let isImagesEdited = false
    const { img_uri, thumbnail_uri, isError, isEmpty } = await this._getImgMgrSubmittedImages()
    if (_.isString(img_uri) && _.isString(thumbnail_uri) && !isEmpty) {
      if (this.state.img_uri !== img_uri) {
        body.img_uri = img_uri
        body.thumbnail_uri = thumbnail_uri
        isImagesEdited = true
      }
    } else if (isError) {
      p.op.alert('เกิดข้อผิดพลาด', 'ไม่สามารถแปลง หรืออัพโหลดรูปภาพได้ กรุณาลองใหม่อีกครั้ง')
      await this._endSubmitting()
      return
    }
    // End Upload Image flow

    // if (!isImagesEdited && !isEdited) {
    //   p.op.showToast('ไม่มีการแก้ไขข้อมูลใหม่', 'warning')
    //   await this._endSubmitting()
    //   // setParams({ mode: VIEW })
    //   this.setState({ mode: 'VIEW' })
    //   return
    // }

    const foundUG = selectedUserGroups.find((ug) => Map.isMap(ug) && ug.get('id') === id)
    if (!foundUG || !Map.isMap(foundUG)) {
      p.op.alert('ไม่พบข้อมูลข้อมูลกลุ่มสมาชิก', 'กรุณาเลือกกลุ่มสมาชิกเพื่อแก้ไขใหม่อีกครั้ง')
      // setParams({ mode: VIEW })
      this.setState({ mode: 'VIEW' })
      this.inProcess = false
      return
    }

    const oldName = foundUG.get('name')
    const oldDesc = foundUG.get('description')
    // log('_submitEditUserGroup oldName => ', oldName)
    // log('_submitEditUserGroup oldDesc => ', oldDesc)
    // log('_submitEditUserGroup name => ', name)
    // log('_submitEditUserGroup description => ', description)

    if (_.isString(name) && name !== oldName) {
      body.name = name.trim()
    }

    if (_.isString(description) && description !== oldDesc) {
      body.description = description.trim()
    }

    if (!_.isEmpty(body)) {
      // if (!state.params || !state.params.store_id) {
      //   p.op.alert('ไม่สามารถดึงข้อมูลร้านค้าได้', 'กรุณากลับออกไปเลือกร้านค้าแล้วดำเนินการใหม่อีกครั้ง')
      // } else {
      //   body.store_id = state.params.store_id
      // }
      body.store_id = selectedStore.get('id')
      if (id) {
        body.ug_id = _.isString(id) ? parseInt(id) : id
      } else {
        p.op.alert('ข้อมูลไม่ครบถ้วน', 'ไม่สามารถระบุกลุ่มกลุ่มสมาชิกได้ กรุณากลับไปเลือกกลุ่มสมาชิกใหม่อีกครั้ง')
      }

      if (body.ug_id && body.store_id) {
        const res = await new Promise((submitted) => {
          updateUserGroup({
            body,
            successCallback: async (res) => {
              submitted(res)
              await this._handleAfterSuccessEditUserGroup(res)
              this.setState({ mode: 'VIEW' })
              // setParams({ mode: VIEW })
            },
            failedCallback: submitted,
          })
        })
        log('_submitEditUserGroup res => ', res)
      }
    } else {
      // setParams({ mode: VIEW })
      this.setState({ mode: 'VIEW' })
      p.op.showToast('ไม่มีการแก้ไขข้อมูลใหม่', 'warning')
    }

    await this._endSubmitting()
    this.inProcess = false
    // log('_submitEditUserGroup finish')
  }

  async _cancelEditUserGroup(): Promise<void> {
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    const { navigation } = this.props
    // const { setParams, state, goBack } = navigation
    const { isEdited = false } = this.state

    const doCancel = async () => {
      await this._firstInitialize()
      await this._endSubmitting()
      navigation.setParams({ mode: VIEW })
    }

    if (isEdited) {
      await new Promise((resolve) => {
        p.op.showConfirmation(
          'ยกเลิกแก้ไขกลุ่มสมาชิก',
          'คุณยืนยันที่จะไม่แก้ไขกลุ่มสมาชิกแล้วหรือไม่ ข้อมูลที่ได้ทำการแก้ไขไปแล้วจะสูญหายทั้งหมด',
          async () => {
            await doCancel()
            // @ts-ignore
            resolve()
          },
          // @ts-ignore
          resolve,
          'ยืนยัน',
          'กลับไปแก้ไขข้อมูลต่อ'
        )
      })
    } else {
      await doCancel()
    }

    await this._endSubmitting()
    // log('_cancelEditUserGroup finish')
  }

  _deleteUserGroup = async () => {
    if (!canDoAtSelectedStore(CONS.PERM_STORE_HELPER.UG_DELETE)) {
      p.op.alert('คุณไม่มีสิทธิ์ในการลบกลุ่มสมาชิก')
      return
    }
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    const { navigation, deleteUserGroup, selectedUserGroups, selectedStore } = this.props
    // const { setParams, state, goBack } = navigation
    const { id, name, joined_count } = this.state

    if (selectedUserGroups.size <= 1) {
      p.op.alert(
        'ไม่สามารถลบกลุ่มสมาชิกได้',
        'กรุณาเปลี่ยน ชื่อ/คำอธิบาย/รูปภาพ แทน เนื่องจากการลบกลุ่มสมาชิกทั้งหมดออก จะทำให้ไม่สามารถรับสมาชิกเข้าร้านฉันได้'
      )
      this.inProcess = false
      return
    }

    if (joined_count > 0) {
      p.op.alert(
        'ไม่สามารถลบกลุ่มสมาชิกได้',
        'กรุณาย้ายกลุ่มให้แก่สมาชิก หรือไล่สมาชิกในกลุ่มออกทั้งหมด เพื่อทำให้กลุ่มว่างเปล่าก่อนที่จะทำการลบกลุ่มสมาชิกนี้'
      )
      this.inProcess = false
      return
    }

    const isUserConfirmed = await new Promise((passConfirm) => {
      p.op.showConfirmation(
        'ยืนยันลบกลุ่มสมาชิก',
        `กรุณายืนยันว่าคุณต้องการลบกลุ่มสมาชิก "${name}" รายการสินค้าที่อยู่ภายใต้สินค้ากลุ่มนี้จะถูกลบออก` +
          ' ทำให้ผู้ใช้ในกลุ่มนี้ไม่สามารถสามารถสั่งซื้อสินค้าได้ คุณแน่ใจหรือไม่',
        () => passConfirm(true),
        () => passConfirm(false)
      )
    })

    if (!isUserConfirmed) {
      await this._endSubmitting()
      return
    }

    await this._beginSubmitting()
    // log('_deleteUserGroup begin')

    const body: { [key: string]: any } = {}

    if (id) {
      body.ug_id = _.isString(id) ? parseInt(id) : id
    } else {
      p.op.alert('ข้อมูลไม่ครบถ้วน', 'ไม่สามารถระบุกลุ่มสมาชิกได้ กรุณากลับไปเลือกกลุ่มสมาชิกใหม่อีกครั้ง')
    }

    // if (!state.params || !state.params.store_id) {
    //   p.op.alert('ไม่สามารถดึงข้อมูลร้านค้าได้', 'กรุณากลับออกไปเลือกร้านค้าแล้วดำเนินการใหม่อีกครั้ง')
    // } else {
    //   body.store_id = state.params.store_id
    // }
    const store_id = selectedStore.get('id')
    body.store_id = store_id
    if (!_.isEmpty(body) && body.ug_id && body.store_id) {
      const successCallback = () => {
        util.navGoBack(this.props)
      }

      const res = await new Promise((submitted) => {
        deleteUserGroup({
          body,
          successCallback,
          failedCallback: submitted,
        })
      })
      log('_deleteUserGroup res => ', res)
    }

    await this._endSubmitting()
    this.inProcess = false
    // log('_deleteUserGroup finish')
  }

  _handleChangeName = async (newText: string) => {
    await setStatePromise(this, {
      name: newText,
    })
    // this.setState({ name: newText, isEdited: true })
  }

  _handleChangeDesc = (newText: string) => {
    this.setState({ description: newText })
  }

  async _beginSubmitting(): Promise<void> {
    this.inProcess = true
    await new Promise((endDelay) => setTimeout(endDelay, 200))
    await setStatePromise(this, { submitting: true })
    this.props.navigation.setParams({ submitting: true })
  }

  async _endSubmitting(): Promise<void> {
    await new Promise((endDelay) => setTimeout(endDelay, 200))
    await setStatePromise(this, { submitting: false })
    // this.props.navigation.setParams({ submitting: false })
    this.inProcess = false
  }

  _handleAfterSuccessResetInviteCode = async (res: { [key: string]: any }) => {
    if (res && _.has(res, 'user_group')) {
      const { user_group } = res
      if (!_.isEmpty(user_group)) {
        await setStatePromise(this, {
          // ug_id: user_group.id,
          ...user_group,
          // isEdited: false,
        })
      }
    }
    await this._endSubmitting()
  }

  async _handleAfterSuccessEditUserGroup(res: { [key: string]: any }): Promise<void> {
    // โหลดของใหม่มาใส่ state เหมือนกับการ reset code
    await this._handleAfterSuccessResetInviteCode(res)
  }

  _onPressResetInviteCodeWithConfirmation = async (): Promise<void> => {
    await this._handleResetInviteCode(false)
  }

  _onPressResetInviteCode = async (): Promise<void> => {
    await this._handleResetInviteCode(true)
  }

  _handleResetInviteCode = async (hideConfirmation?: boolean): Promise<void> => {
    if (!canDoAtSelectedStore(CONS.PERM_STORE_HELPER.UG_EDIT)) {
      p.op.alert('คุณไม่มีสิทธิ์ในการแก้ไขกลุ่มสมาชิก')
      return
    }
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    const { id } = this.state
    const { resetUserGroupInviteCode } = this.props
    // const { state, setParams } = navigation
    // if (!id || !state.params || !state.params.store_id) {
    //   // throw new Error('No navigation params with store_id or ug_id')
    //   await this._endSubmitting()
    //   return
    // }

    let isUserConfirmed
    if (hideConfirmation) {
      isUserConfirmed = true
    } else {
      isUserConfirmed = await new Promise((passConfirm) => {
        p.op.showConfirmation(
          'ยืนยันการยกเลิกรหัสและสร้างรหัสใหม่',
          'คุณต้องการยกเลิกรหัสขอเข้ากลุ่มปัจจุบัน (รวมถึงลิงก์ และรูป QR Code) ' +
            'ซึ่งจะทำให้ใช้รหัสเดิมขอเข้ากลุ่มไม่ได้ และสร้างรหัสใหม่มาแทน หรือไม่?',
          () => passConfirm(true),
          () => passConfirm(false)
        )
      })
    }

    if (!isUserConfirmed) {
      await this._endSubmitting()
      return
    }

    await this._beginSubmitting()
    resetUserGroupInviteCode({
      body: {
        store_id: this.props.selectedStore.get('id'),
        ug_id: id,
      },
      successCallback: this._handleAfterSuccessResetInviteCode,
      failedCallback: async () => {
        await this._endSubmitting()
      },
    })
  }

  _onPressGenerateInviteLink = async (): Promise<void> => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    const { id } = this.state
    const { generateUserGroupInviteLink, selectedStore } = this.props
    // const { state, setParams } = navigation
    // if (!id || !state.params || !state.params.store_id) {
    //   await this._endSubmitting()
    //   return
    // }
    await this._beginSubmitting()

    const response: any = await new Promise((resolveRes) => {
      generateUserGroupInviteLink({
        body: {
          store_id: selectedStore.get('id'),
          ug_id: id,
        },
        successCallback: (res) => resolveRes(res),
        failedCallback: () => resolveRes(null),
      })
    })

    // log('_onPressGenerateInviteLink response => ', response)
    if (response && response.user_group && response.user_group.id && response.user_group.invite_link) {
      // TODO: Implement something with response ???
      const { user_group } = response
      await setStatePromise(this, user_group)
      // p.op.showToast('การสร้างลิงก์สำเร็จแล้ว', 'success')
      this._handlePressCopyTextToClipboard(user_group.invite_link)
    } else {
      p.op.showToast('การสร้างลิงก์ล้มเหลว', 'danger')
    }

    await this._endSubmitting()
  }

  _onPressDownloadQRCode = async () => {
    if (_.isNil(this.state.invite_link)) {
      await this._onPressGenerateInviteLink()
    }
    if (this.qrModalRef && _.isFunction(this.qrModalRef.current.openQRCode)) {
      // console.log('_onPressDownloadQRCode this.qrModalRef => ', this.qrModalRef)
      this.qrModalRef.current.openQRCode(this.state.invite_link, this.state.invite_code)
    }
  }

  _handlePressCopyTextToClipboard = (text: string) => {
    if (!text) {
      return
    }
    // @ts-ignore
    Clipboard.setString(text)
    p.op.showToast(`คัดลอก ${text} แล้ว`, 'success')
  }
}
