import React from 'react'
import { View, Text, TouchableOpacity, Platform, RefreshControl, ScrollView, StyleSheet } from 'react-native'

import { S } from 'x/config/styles'
import CONS from 'x/config/constants'
import api from 'x/utils/api'
import * as util from 'x/utils/util'
import p from 'x/config/platform-specific'
import { IAutoCompleteOrderTabItemsViewNavParams, IApiOptions, IXScreenProps } from 'x/index'
import * as NavActions from 'x/utils/navigation'

import XCustomHeader from '../../components/XCustomHeader'
import ForwardIcon from '../../components/ForwardIcon'

const IS_WEB = Platform.OS === 'web'
type IProps = IXScreenProps<IAutoCompleteOrderTabItemsViewNavParams>

interface IState {
  isInitialized: boolean
  isFetching?: boolean
  orderSummary?: {
    [key: string]: number
  }
}

const ORDER_LIST_TAB_KEYS_BY_BGJOB_TYPE = {
  [CONS.BACKGROUND_JOB_TYPES.PAYMENT_CREATE]: [
    'myTasks_confirmOrder',
    'myTasks_pay',
    'myTasks_confirmGettingPaid',
    'myTasks_ship',
    'custTasks_pay',
    'custTasks_autoComplete',
    'resellerTasks_confirmOrder',
    'resellerTasks_pay',
    'resellerTasks_confirmGettingPaid',
    'sellerTasks_confirmOrder',
    'sellerTasks_confirmGettingPaid',
    'sellerTasks_ship',
  ],
  [CONS.BACKGROUND_JOB_TYPES.SHIPPING_CREATE]: [
    'myTasks_confirmOrder',
    'myTasks_pay',
    'myTasks_confirmGettingPaid',
    'myTasks_ship',
    'custTasks_pay',
    'resellerTasks_confirmOrder',
    'resellerTasks_pay',
    'resellerTasks_confirmGettingPaid',
    'sellerTasks_confirmOrder',
    'sellerTasks_confirmGettingPaid',
    'sellerTasks_pay',
  ],
}

export default class AutoCompleteOrderTabItemsView extends React.Component<IProps, IState> {
  static displayName = 'AutoCompleteOrderTabItemsView'

  inProcess?: boolean

  constructor(props) {
    super(props)
    this.state = {
      isInitialized: false,
      isFetching: false,
    }
  }

  componentDidMount() {
    this.fetchOrderListSummary()
  }

  getBgJobType = () => {
    const { navigation } = this.props
    return util.getNavParam(this.props, 'bgJobType')
  }

  fetchOrderListSummary = async () => {
    if (this.inProcess || this.state.isFetching) {
      return
    }
    this.inProcess = true
    await util.setStatePromise(this, { isFetching: true })

    const selectedStoreId = util.getNavParam(this.props, 'storeId')
    const bgJobType = this.getBgJobType()
    let orderSegment
    switch (bgJobType) {
      case CONS.BACKGROUND_JOB_TYPES.PAYMENT_CREATE: {
        orderSegment = 'cust_and_reseller_to_pay_me_and_not_mkp'
        break
      }
      case CONS.BACKGROUND_JOB_TYPES.SHIPPING_CREATE: {
        orderSegment = 'my_unshipped_only_and_not_mkp'
        break
      }
    }

    // console.log('selectedStoreId => ', selectedStoreId)
    // console.log('bgJobType => ', bgJobType)
    const reqBody = {
      store_id: selectedStoreId,
      types_to_count: ORDER_LIST_TAB_KEYS_BY_BGJOB_TYPE[bgJobType],
      order_segment: orderSegment,
    }
    // console.log('reqBody => ', reqBody)

    const apiOptions: IApiOptions = {
      axiosOptions: {
        retry: 0,
        timeout: 60000,
      },
      isErrorAlertDisabled: true,
    }

    try {
      const res = await api.post(api.POST_ORDERS, reqBody, apiOptions)
      // console.log('fetchOrderListSummary res => ', res)
      await util.setStatePromise(this, { orderSummary: res.summary, isInitialized: true })
    } catch (err) {
      // console.log('fetchOrderListSummary err => ', err)
      if (util.isXSellyErrorV2(err)) {
        // @ts-ignore
        util.alertWithXSellyErrorV2(err.error)
      }
    }

    await util.delay(100)
    this.inProcess = false
    await util.setStatePromise(this, { isFetching: false })
  }

  _goBack = () => {
    util.navGoBack(this.props)
  }

  _onPressMenuItem = (orderListTabKey: string) => {
    const { navigation } = this.props
    const orderListTabText = this._getOrderListTabText(orderListTabKey)
    let multiOpMode
    switch (this.getBgJobType()) {
      case CONS.BACKGROUND_JOB_TYPES.PAYMENT_CREATE:
        multiOpMode = CONS.MULTI_OP_MODES.AutoCompletePayment
        break
      case CONS.BACKGROUND_JOB_TYPES.SHIPPING_CREATE:
        multiOpMode = CONS.MULTI_OP_MODES.AutoCompleteShipment
        break
    }
    navigation.dispatch(
      NavActions.navToStoreOrderSingleTabListView({
        tabKey: orderListTabKey,
        multiOpMode,
        title: `${this.getHeaderTitleText()}\n${orderListTabText}`,
      })
    )
  }

  _getOrderListTabText = (orderListTabKey: string) => {
    const mainKey = orderListTabKey.split('_')[0]
    const subKey = orderListTabKey.split('_')[1]
    const orderListTabText = `${p.op.t(`Order.${mainKey}`)} > ${p.op.t(`Order.${subKey}`)}`
    return orderListTabText
  }

  _renderMenuItem = (orderListTabKey: string, index: number) => {
    if (!orderListTabKey) {
      return null
    }
    const { orderSummary = {} } = this.state
    const count = orderSummary[orderListTabKey] || 0
    const orderListTabText = this._getOrderListTabText(orderListTabKey)

    if (!count) {
      return null
    }

    const handlePressItem = () => this._onPressMenuItem(orderListTabKey)

    return (
      <TouchableOpacity
        style={[
          S.CARDLIKE_BODY,
          S.CARDLIKE_BORDERS,
          S.CARDLIKE_MARGIN,
          // S.BUTTON_OUTLINE,
          S.BG_WHITE,
          S.ROW_MIDDLE_BETWEEN,
          // S.MARGIN_HORIZONTAL_6,
          S.PADDING_VERTICAL_12,
        ]}
        onPress={handlePressItem}
        key={index.toString()}>
        <View style={[S.FLEX, S.ROW_MIDDLE_BETWEEN]}>
          <Text style={[S.TEXT_ACTIVE]}>{orderListTabText}</Text>
          <Text style={[S.TEXT_ACTIVE]}>{count}</Text>
        </View>
        <View style={[S.WIDTH_44, S.ROW_CENTER]}>
          <ForwardIcon style={StyleSheet.flatten([S.NO_FLEX, S.WIDTH_44])} />
        </View>
      </TouchableOpacity>
    )
  }

  // _renderSpinner = () => {
  //   return <Spinner style={{ alignSelf: 'center', width: '100%' }} color={COLORS.TEXT_INACTIVE} />
  // }

  _getRefreshControl = () => {
    if (p.op.isWeb()) {
      return null
    }
    return <RefreshControl refreshing={this.state.isFetching} onRefresh={this.fetchOrderListSummary} />
  }

  _renderContainer = () => {
    const { isInitialized } = this.state
    const bgJobType = this.getBgJobType()
    if (!bgJobType) {
      return null
    }

    const willRenderListItems = ORDER_LIST_TAB_KEYS_BY_BGJOB_TYPE[bgJobType].map(this._renderMenuItem)
    let hasAtLeastOneRenderableSection = false
    for (const jsxElement of willRenderListItems) {
      if (jsxElement) {
        hasAtLeastOneRenderableSection = true
        break
      }
    }

    if (isInitialized && !hasAtLeastOneRenderableSection) {
      let typeText = 'ดำเนินการ' // worst-case
      switch (bgJobType) {
        case CONS.BACKGROUND_JOB_TYPES.PAYMENT_CREATE: {
          typeText = 'บันทึกรับชำระ'
          break
        }
        case CONS.BACKGROUND_JOB_TYPES.SHIPPING_CREATE: {
          typeText = 'บันทึกจัดส่ง'
          break
        }
      }
      return (
        <View style={[S.HEIGHT_FULL, S.WIDTH_FULL, S.PADDING_8]}>
          <Text style={[S.TEXT_INACTIVE, S.TEXT_ALIGN_CENTER, S.MARGIN_VERTICAL_12]}>{`ไม่มีออเดอร์ที่รอฉัน${typeText}`}</Text>
        </View>
      )
    }

    return (
      <ScrollView refreshControl={this._getRefreshControl()} style={[S.HEIGHT_FULL, S.WIDTH_FULL, S.PADDING_8]}>
        {isInitialized ? ORDER_LIST_TAB_KEYS_BY_BGJOB_TYPE[bgJobType].map(this._renderMenuItem) : null}
      </ScrollView>
    )
  }

  getHeaderTitleText = () => {
    const bgJobType = this.getBgJobType()
    switch (bgJobType) {
      case CONS.BACKGROUND_JOB_TYPES.PAYMENT_CREATE: {
        return 'บันทึกรับชำระอัตโนมัติ'
      }
      case CONS.BACKGROUND_JOB_TYPES.SHIPPING_CREATE: {
        return 'บันทึกจัดส่งอัตโนมัติ'
      }
    }
    return ''
  }

  render() {
    return (
      <View>
        <XCustomHeader title={this.getHeaderTitleText()} headerLeftProps={{ backIcon: true, onPressItem: this._goBack }} />
        {this._renderContainer()}
      </View>
    )
  }
}
