// import { COLORS as xCOLORS } from 'x/config/styles'
// import ImgBlock from '../../components/imgblock'

// import BasePaymentView, { OPTION_COD_AMOUNT, OPTION_ORDER_AMOUNT } from 'x/modules/payment/BasePaymentView'
// import SwitchCard from '../../components/SwitchCard'
// import settings from "x/config/settings"

// import BackIcon from '../../components/BackIcon'

// import s from './_style'

import BaseUIAddressXShippingView from 'xui/modules/address/BaseUIAddressXShippingView'
// import PaymentAccountPicker from 'xui/components/PaymentAccountPicker'

class JAntTConnectView extends BaseUIAddressXShippingView {
  static displayName = 'JAntTConnectView'

  static navigationOptions = {
    header: null,
    title: null,
    headerLeft: null,
    headerRight: null,
  }
}

export default JAntTConnectView
