import _ from 'lodash'

import * as NavActions from 'x/utils/navigation'
import api from 'x/utils/api'
import * as util from 'x/utils/util'
import {
  IProductListViewPullSellersProductsToMyStoreNavParams,
  IPullProductRequestBody,
  IProductListViewProps,
  IProductListViewState,
} from 'x/index'

import BaseUIProductListView from './BaseUIProductListView'

type IProductListViewPullSellersProductsToMyStoreProps = IProductListViewProps<IProductListViewPullSellersProductsToMyStoreNavParams>

class ProductListViewPullSellersProductsToMyStore extends BaseUIProductListView<
  IProductListViewPullSellersProductsToMyStoreProps,
  IProductListViewState
> {
  static displayName = 'ProductListViewPullSellersProductsToMyStore'

  // override
  getApiEndpoint = () => api.POST_SELLER_PRODUCTS

  // override
  isRequiredProductVariants = () => false

  // // override
  // isSellerStore = () => true

  // override
  isAddProductButtonVisible = () => false

  // override
  isSkuSearchAvailable = () => false

  // override
  getHaveNoProductItemText = () => 'คุณดึงสินค้าจากร้านนี้ไปหมดทุกชนิดแล้ว\nหรือ ร้านค้าไม่มีรายการสินค้าที่คุณสามารถดึงได้'

  // override
  // เปลี่ยน targetStoreId เป็น seller_store_id
  // ใช้ store_id จาก NavParams (ของร้านฉัน) เสริมเข้าไป
  // เพื่อบอกว่า ร้านฉัน ส่องไปที่ ร้านผู้ขายส่ง เพื่อที่จะดูสินค้าที่ยังไม่ถูกดึง
  getModifiedRequestBody = (body) => {
    const { navigation, selectedStore } = this.props
    const myStoreId = util.getNavParam(this.props, 'store_id')
    const sellerStoreId = util.getNavParam(this.props, 'seller_store_id')
    const newBody = _.cloneDeep(body)
    if (sellerStoreId) {
      newBody.store_id = myStoreId
      newBody.seller_store_id = sellerStoreId
    }
    return newBody
  }

  // implement abstract
  onPressProductItem = (item) => {
    // console.log('ProductListViewPullSellersProductsToMyStore onPressProductItem item => ', item)
    this.navToProductViewPullSellersProductToMyStoreMode(item.data.id)
  }

  navToProductViewPullSellersProductToMyStoreMode = (product_id: number) => {
    const { navigation } = this.props
    const storeId = util.getNavParam(this.props, 'store_id')
    const sellerStoreId = util.getNavParam(this.props, 'seller_store_id')
    navigation.dispatch(
      NavActions.navToProductViewPullProduct({
        store_id: storeId,
        seller_store_id: sellerStoreId,
        product_id,
        onSuccessPullSellersProductToMyStoreCallback: this._onPullProductSucccessCallback,
      })
    )
  }

  // ทำการ remove product item ที่ถูก pull สำเร็จออกจาก list
  _onPullProductSucccessCallback = async (requestBody: IPullProductRequestBody) => {
    const searchListRef = this.getSearchProductListRef()
    await searchListRef.removeItemByKey('id', requestBody.product_id)
    const mainListRef = this.getMainProductListRef()
    await mainListRef.removeItemByKey('id', requestBody.product_id)
  }
}

export default ProductListViewPullSellersProductsToMyStore
