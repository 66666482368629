import React, { Component, useState } from 'react'
import { View, StyleSheet, TouchableOpacity } from 'react-native'
import Popover, { PopoverMode, PopoverPlacement } from 'react-native-popover-view'
import Box from 'xui/components/Box'
import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import Spinner from 'xui/components/XSpinner'
import _ from 'lodash'
import { Svg, Path, Text as SvgText } from 'react-native-svg'

import { getAuthenticationToken } from 'x/utils/authentication'
import * as util from 'x/utils/util'
import { delay, setStatePromise } from 'x/utils/util'

import p from 'x/config/platform-specific'
import { WEB_RUNTIME } from 'x/config/app/generated-runtime-web-config'
import { IMKPChannelDetail, ISelectedStoreMap, IXScreenProps } from 'x/index'
import { STYLES, COLORS, S } from 'x/config/styles'
import xCONS from 'x/config/constants'
import XIcon, { XIconFamily } from 'xui/components/XIcon'
import { List } from 'immutable'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import XText from 'xui/components/XText'
import * as NavActions from 'x/utils/navigation'

import { IS_XSELLY_BUILD, MODE } from 'x/config/mode'
import { FLAVOUR_CONFIG } from 'x/config/app/generated-runtime-app-config'

import SETTINGS from 'x/config/settings'
import { setConfiguration } from 'x/utils/configuration'
import Segment from 'xui/components/Segment'
import { IXShippingConfig } from 'x/config/app'
import * as acl from 'x/utils/acl'
import api from 'x/utils/api'
import XCard from 'xui/components/XCard'
import XOverlay from 'xui/components/XOverlay'
import XImage from 'xui/components/XImage'
import { useNavigation, useRoute, CommonActions } from '@react-navigation/native'
import XButton from 'xui/components/XButton'
import { switchToLandingView } from '../../utils/navigation'
import AUTOCONS from '../../config/autoupdateConstants'
import ThemeSizeContext from '../../context/ThemeSizeContext'
import Sidebar from './Sidebar'

// import Popover from 'react-tiny-popover'
// import { NavigationNavigateAction, NavigationPushAction } from 'react-navigation'

type NavigationNavigateAction = any
type NavigationPushAction = any

const isImpersonateMode = (process && process.env && process.env.REACT_APP_IMPERSONATE) || false

const {
  SIDEBAR_COLLAPSED_WIDTH,
  SIDEBAR_WIDTH,
  MAIN_CONTENT_WIDTH,
  MAX_WEB_WIDTH,
  HEADER_HEIGHT,
  MIN_WEB_HEIGHT,
  BOTTOM_SCROLLBAR_HEIGHT,
  RIGHT_SCROLLBAR_WIDTH,
  NAV_HEADER_HEIGHT,
} = xCONS.SPECIFIC_PLATFORM.WEB

const SIDEBAR_RESPONSIVE_WIDTH_POINT = 650

const {
  ORDER_LIST,
  ORDER_ADD,
  ORDER_PRINT_SHIPPING,
  SHIPPING_ADD,
  PAYMENT_EDIT,
  PRODUCT_LIST,
  PRODUCT_ADD,
  UG_LIST,
  PG_LIST,
  REPORT_VIEW,
  PRODUCT_PULL,
  REPORT_ORDER,
} = xCONS.PERM_STORE_HELPER

const logoSetting = require('../../xui/x/img/tb/tb_others0.png')
const logoMkpChannel = require('../../xui/x/img/about/icon_mkp_channel.png')

interface IStoreMyViewProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
  myStores: List<any>

  dispatch: (params: any) => void
  fetchInitializeData: (params: any) => void
  fetchMyStore: (params: { body: object; successCallback?: () => void; failedCallback?: () => void }) => void
  setXShippingConfig: (xshippingCofig: IXShippingConfig[]) => void
  isSubscriptionWarningActive: boolean
  subscriptionWarning: {
    type: 'warning' | 'danger' | 'success'
    message: string
    closeable: boolean
    priority: number
  }
  hideSubscription: () => void

  isSubscriptionWarningQuotaActive: boolean
  subscriptionQuotaWarning: {
    type: 'warning' | 'danger' | 'success'
    message: string
    closeable: boolean
    priority: number
  }
  hideQuotaWarning: () => void

  getWarningReAuthMKP: {
    nextWarningTime: string | null
    warningText: string
    warningBackgroundColor: string
    channel: IMKPChannelDetail
  }
  // setWarningReAuthMKP: (warningReAuthMKP: any) => void
  unSetWarningReAuthMKPState: () => void
}

interface IStoreMyViewState {
  // visibleModal: boolean
  isPopoverOpen?: boolean
  isVisibleStoreSelectorModal?: boolean
  browserWidth?: number
  browserHeight?: number
  sidebarWidth?: number
  contentWidth?: number
  numOfColumns?: number
  isInitialized: boolean
  sidebarButtonActiveIndex: number

  isHamburgerMenuOpen?: boolean
  isCollapsedSidebar?: boolean
  isKeyboardOpen?: boolean

  warningHight: number
}

// const { Fragment } = React
// // const SIDEBAR_WIDTH = 180
// // const MAIN_CONTENT_WIDTH = 960
// // const MAX_WEB_WIDTH = MAIN_CONTENT_WIDTH + SIDEBAR_WIDTH
// // const HEADER_HEIGHT = 44
// // const MIN_WEB_HEIGHT = 500
// // const BOTTOM_SCROLLBAR_HEIGHT = 15
// // const RIGHT_SCROLLBAR_WIDTH = 15

// class SideBarItem extends PureComponent<{
//   index: number
//   activeIndex: number
//   title: string
//   onPress: () => void | Promise<void>
//   // isActive?: boolean
//   icon?: {
//     name: string
//     family?:
//       | 'AntDesign'
//       | 'Entypo'
//       | 'EvilIcons'
//       | 'Feather'
//       | 'FontAwesome'
//       | 'FontAwesome5'
//       | 'Foundation'
//       | 'Ionicons'
//       | 'MaterialCommunityIcons'
//       | 'MaterialIcons'
//       | 'Octicons'
//       | 'SimpleLineIcons'
//       | 'Zocial'
//   }
//   count?: number
//   isSomsaiMenu?: boolean
//   imgge?: JSX.Element
// }> {
//   inProcess?: boolean

//   _handlePress = async () => {
//     if (this.inProcess) {
//       return
//     }
//     this.inProcess = true
//     const { onPress } = this.props
//     if (isAsyncFunction(onPress)) {
//       await onPress()
//     } else {
//       onPress()
//     }
//     await delay(100)
//     this.inProcess = false
//   }

//   _isActive = () => {
//     const { index, activeIndex } = this.props
//     return index === activeIndex
//   }

//   render() {
//     const { title, icon, count = null, isSomsaiMenu = false, imgge } = this.props
//     const isActive = this._isActive()
//     const txtStyle = isActive ? s.btnActiveText : s.btnInactiveText
//     const containerStyle = isActive ? s.btnActiveContainer : s.btnInactiveContainer
//     const isVisibleBadge = _.isNumber(count)
//     let titleWidth = isVisibleBadge ? 98 : 142
//     if (isSomsaiMenu) {
//       titleWidth -= 16 // padding 8
//     }
//     return (
//       <TouchableOpacity style={containerStyle} onPress={this._handlePress}>
//         <HStack
//           w='full'
//           // opacity={opacity}
//           px='1'
//           space='1'>
//           <HStack flex={1} alignItems='center' minW='7' space='1'>
//             {icon ? <XIcon inactive={!isActive} name={icon.name} family={icon.family} /> : null}
//             {imgge || null}
//             <XText flex={1} bold={isActive} adjustsFontSizeToFit style={txtStyle}>
//               {title}
//             </XText>
//           </HStack>
//           {isVisibleBadge ? (
//             <VStack alignItems='center' justifyContent='center'>
//               <Badge
//               // borderWidth={isActive ? '1' : '0'}
//               >
//                 {count}
//               </Badge>
//             </VStack>
//           ) : null}
//         </HStack>
//       </TouchableOpacity>
//     )
//   }
// }

const TrapezoidTitle = (props: { title: string; width?: number | string; height?: number }) => {
  const { title, width = '100%', height = 26 } = props
  return (
    <View
      style={{
        // marginTop: 2,
        // marginBottom: 2,
        height,
        width,
        overflow: 'hidden',
      }}>
      <Svg width={width} height={height} viewBox='0 0 150 40' preserveAspectRatio='xMinYMid'>
        <Path
          d='M 0 0 L 150 0 L 120 40 L 0 40 L 0 0'
          fill={COLORS.APP_MAIN}
          stroke={COLORS.APP_MAIN}
          strokeWidth={1}
          // markerEnd="url(#Triangle)"
        />
        <SvgText
          fill={COLORS.WHITE}
          // stroke={COLORS.WHITE}
          strokeWidth={1}
          fontSize={24}
          // fontWeight={'bold'}
          // fontFamily={'Roboto'}
          fontFamily='Prompt-Bold'
          x={6}
          y={28}
          textAnchor='start'>
          {title}
        </SvgText>
      </Svg>
    </View>
  )
}

type ISidebarMenuItem =
  | {
      type: 'header'
      title: string
    }
  | {
      type: 'menu'
      title: string
      icon: {
        name: string
        family: XIconFamily
      }
      countGetter?: () => number
      onPress: (menuIndex: number) => void | Promise<void>
      imgge?: JSX.Element
      isShowMenu?: () => boolean
    }

class StoreMyView extends Component<IStoreMyViewProps, IStoreMyViewState> {
  static navigationOptions: () => {
    headerBackTitle: null
    title: 'StoreMyView'
  }

  // SIDEBAR_MENUS: ISidebarMenuItem[]

  isFetching?: boolean

  stackContainerRef?: React.RefObject<HTMLDivElement>

  sidebarContainerRef?: React.RefObject<HTMLDivElement>

  // eslint-disable-next-line react/sort-comp
  constructor(props) {
    super(props)
    this.state = {
      browserHeight: 480,
      browserWidth: 768,
      // visibleModal: false,
      isInitialized: false,
      isVisibleStoreSelectorModal: false,
      // sidebarButtonActiveIndex: -1,
      sidebarButtonActiveIndex: 3,

      sidebarWidth: SIDEBAR_WIDTH,
      contentWidth: MAIN_CONTENT_WIDTH,
      numOfColumns: 3,

      warningHight: 0,
    }

    this.stackContainerRef = React.createRef()
    this.sidebarContainerRef = React.createRef()
  }

  // @ts-ignore
  handleNav = () => this.props.navigation.navigate('StoreMyView2')
  // showToast = () => {
  //   Toast.show({
  //     text: 'YOOOO!',
  //     buttonText: 'Okay',
  //     position: 'bottom',
  //     type: 'success',
  //   })
  // }
  // showModal = () => {
  //   this.setState({ visibleModal: true })
  // }
  // _renderItem = ({ index, item }) => {
  //   const isOdd = index % 2
  //   return (
  //     <View style={{ backgroundColor: isOdd ? 'blue' : 'green', height: 100 }}>
  //       <XText>{`index = ${index} / item = ${item}`}</XText>
  //     </View>
  //   )
  // }

  async componentDidMount() {
    window.addEventListener('resize', this._updateDimensions)

    // https://stackoverflow.com/questions/5498934/detect-change-in-orientation-using-javascript
    // จริงๆ แล้วควร deprecated แต่เห็นว่า work on iOS
    window.addEventListener('orientationchange', this._updateDimensions)
    // window.addEventListener('DOMContentLoaded', this._onOrientationChange)

    const isAuth = await this._isAuth()
    if (!isAuth) {
      return
    }

    this._updateDimensions()
    let selectedStoreId = this.props.selectedStore.get('id')
    if (!selectedStoreId) {
      await this._initData()
      await delay(100)
    }

    const { selectedStore, navigation } = this.props
    selectedStoreId = selectedStore.get('id')
    // console.log('StoreMyView::componentDidMount navigation => ', navigation)
    if (!selectedStoreId) {
      this._navToLandingView()
    } else {
      // // Check ว่าปัจจุบันอยู่ที่ menu ไหน (manual static set)
      // const { routes } = navigation.state
      // const currentRouteIndex = navigation.state.index
      // const currentRouteName = routes[currentRouteIndex].routeName
      // let sidebarButtonActiveIndex = -1

      // switch (currentRouteName) {
      //   // case 'StoreOrderListStackNavigator':
      //   //   sidebarButtonActiveIndex = 3
      //   //   break
      //   // case 'ProductListStackNavigator':
      //   //   sidebarButtonActiveIndex = 8
      //   //   break
      //   // case 'QuickShipView':
      //   //   sidebarButtonActiveIndex = 5
      //   //   break
      //   case 'StoreOrdersDonutViewStackNavigator':
      //     sidebarButtonActiveIndex = 2
      //     break
      //   // case 'StoreOrdersDonutViewStackNavigator':
      //   //   sidebarButtonActiveIndex = 4
      //   //   break
      // }
      await this._updateXshippingConfig()
      // // default home
      // await this._navToStoreOrdersDonutViewStackNavigator(3)

      this.setState({ isInitialized: true })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._updateDimensions)
    window.removeEventListener('orientationchange', this._updateDimensions)
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedStore } = this.props
    if (prevProps.selectedStore.get('channels') !== selectedStore.get('channels')) {
      util.checkingMKPChannelExpireTime()
    }
  }

  // _onOrientationChange = () => {
  //   const output = document.getElementById('body')
  //   const displayOrientation = () => {
  //     // @ts-ignore
  //     const screenOrientation = screen.orientation.type
  //     output.innerHTML = `The orientation of the screen is: ${screenOrientation}`
  //     if (screenOrientation === 'landscape-primary') {
  //       console.log('That looks good.')
  //     } else if (screenOrientation === 'landscape-secondary') {
  //       console.log('Mmmh... the screen is upside down!')
  //     } else if (screenOrientation === 'portrait-secondary' || screenOrientation === 'portrait-primary') {
  //       console.log('Mmmh... you should rotate your device to landscape')
  //     } else if (screenOrientation === undefined) {
  //       console.log("The orientation API isn't supported in this browser :(")
  //     }
  //   }

  //   // @ts-ignore
  //   if (screen && screen.orientation !== null) {
  //     try {
  //       window.screen.orientation.onchange = displayOrientation
  //       displayOrientation()
  //     } catch (e) {
  //       output.innerHTML = e.message
  //     }
  //   }

  //   this._updateDimensions()
  // }

  _updateDimensions = () => {
    const { isCollapsedSidebar } = this.state
    const minKeyboardHeight = 300 // px
    const isKeyboardOpen = window.screen.height - minKeyboardHeight > window.visualViewport.height || false
    const w = window
    const d = document
    const { documentElement } = d
    const body = d.getElementsByTagName('body')[0]
    const width = w.innerWidth || documentElement.clientWidth || body.clientWidth
    const height = w.innerHeight || documentElement.clientHeight || body.clientHeight

    let sidebarWidth = isCollapsedSidebar ? SIDEBAR_COLLAPSED_WIDTH : SIDEBAR_WIDTH
    let contentWidth = MAIN_CONTENT_WIDTH
    if (this.sidebarContainerRef.current?.clientWidth) {
      // console.log('_updateDimensions sidebarContainerRef width => ', this.sidebarContainerRef.current)
      sidebarWidth = this.sidebarContainerRef.current?.clientWidth
    }
    if (this.stackContainerRef.current?.clientWidth) {
      // console.log('_updateDimensions stackContainerRef width => ', this.stackContainerRef.current?.clientWidth)
      contentWidth = this.stackContainerRef.current?.clientWidth - sidebarWidth
    }

    this.setState({ browserWidth: width, browserHeight: height, isKeyboardOpen, sidebarWidth, contentWidth })
  }

  // _isHelperUser = () => {
  //   const { selectedStore } = this.props
  //   return selectedStore.has('role_id') ? selectedStore.get('role_id') === xCONS.STORE_ROLE.HELPER : false
  // }

  _getBrowserSize = () => ({
    width: this.state.browserWidth,
    height: this.state.browserHeight,
  })

  _initData = async () => {
    const { fetchInitializeData, navigation } = this.props
    const res: { [key: string]: any } = await new Promise((resolve) => {
      fetchInitializeData({
        successCallback: resolve,
        failedCallback: () => resolve(null),
      })
    })
    // console.log('_initData res => ', res)
    if (res && res.ss && res.ss.store_id) {
      navigation.setParams({ store_id: res.ss.store_id })
    }
  }

  // fetch and selected
  _fetchMyStore = async (): Promise<boolean> => {
    // const { navigation, fetchMyStore } = this.props
    // if (!navigation || !navigation.state || !navigation.state.params) {
    //   this._goBack()
    //   return
    // }
    if (this.isFetching) {
      return false
    }
    this.isFetching = true
    const { fetchMyStore, navigation } = this.props
    // const { store_id } = navigation.state.params
    const selectedStoreId = util.getNavParam(this.props, 'store_id')
    if (!selectedStoreId) {
      this.isFetching = false
      return false
    }
    const body = { store_id: selectedStoreId }
    const isSuccessFetched = await new Promise<boolean>((isFetched) => {
      fetchMyStore({
        body,
        successCallback: () => isFetched(true),
        failedCallback: () => isFetched(false),
      })
    })

    // if (!isSuccessFetched) {
    //     this._goBack()
    // }
    this.isFetching = false
    return isSuccessFetched
  }

  _isAuth = async (): Promise<boolean> => {
    const token = await getAuthenticationToken()
    // console.log('token => ', token)

    if (!token) {
      await this._navToLoginView()
      return false
    }
    // const refreshedToken = await new Promise(resolve => {
    //   this.props.dispatch({
    //     type: actions.LOGIN_REFRESH_APP_TOKEN,
    //     successCallback: resolve,
    //     failedCallback: () => resolve(null),
    //   })
    // })
    // // console.log('refreshedToken => ', refreshedToken)
    // if (!refreshedToken) {
    //   await this._navToLoginView()
    //   return false
    // }
    return true
  }

  _updateXshippingConfig = async () => {
    const { setXShippingConfig, selectedStore } = this.props
    if (!acl.isSelectedStoreOwner()) {
      return
    }
    const body: { [key: string]: any } = {
      store_id: selectedStore.get('id'),
    }

    const response: { xshipping_config: IXShippingConfig[] } = await api.postV2(api.XSHIPPING_CONFIG, body)
    // console.log('_updateXshippingConfig => ', response)
    if (response.xshipping_config && _.isArray(response.xshipping_config)) {
      setXShippingConfig(response.xshipping_config)
    }
    // setXShippingConfig(mockConfig)
  }

  _navToLoginView = async () => {
    // console.log('_navToLoginView nav => ', this.props.navigation)
    await p.op.signOut()
    // this.props.navigation.dangerouslyGetParent().navigate('LoginView')
    // @ts-ignore
    // this.props.navigation.dangerouslyGetParent().dispatch(switchToLoginView)
    // this.props.navigation.dangerouslyGetParent().dispatch(NavActions.resetToFirstPage)
    this.props.navigation.dispatch(NavActions.resetToFirstPage)
  }

  _navToLandingView = async () => {
    console.log('_navToLandingView nav => ', this.props.navigation)
    // console.log('current nav => ', this.props.navigation)
    // this.props.navigation.dangerouslyGetParent().navigate('LandingView')
    // @ts-ignore
    // this.props.navigation.dangerouslyGetParent().dispatch(switchToLandingView)
    // this.props.navigation.dangerouslyGetParent().dispatch(switchToLandingView)
    this.props.navigation.dispatch(switchToLandingView)
    // this.props.navigation.dispatch(switchToLandingView)
  }

  // _renderSideBarItems = () => {
  //   const { navigation } = this.props
  //   return navigation.state.routes.map(this._renderSideBarItem)
  // }
  //
  // _renderSideBarItem = (route: NavigationRoute) => {
  //   // console.log('_renderSideBarItem route => ', route)
  //   const { selectedStore, navigation } = this.props
  //   const store_id = selectedStore.get('id')
  //   // const handleNav = () => navigation.navigate({ routeName: route.routeName, params: { store_id } })
  //   const handleNav = () => navigation.navigate(route.routeName,  { store_id }, {
  //       type: 'Navigation/NAVIGATE',
  //       routeName: 'StoreOrderListStackNavigator',
  //       params: { store_id, mode: 'VIEW_SELF' },
  //   })
  //   // return <XButton key={route.key} title={route.routeName} onPress={handleNav} />
  //   return (
  //     <TouchableOpacity key={route.key} onPress={handleNav}>
  //       <XText style={{ marginVertical: 16, paddingVertical: 4, color: COLORS.APP_MAIN }}>{`- ${route.routeName}`}</XText>
  //     </TouchableOpacity>
  //   )
  // }

  // _renderSideBarItems = () => {
  //   const { sidebarButtonActiveIndex } = this.state
  //   const { navigation, selectedStore } = this.props
  //   const order_count = selectedStore.get('order_count')
  //   const donutData = getDonutData(selectedStore.get('order_summary'))
  //   const donutWaitingMeCount = getDonutOrderWaitingMeCount(donutData)
  //   const product_count = selectedStore.get('product_count')
  //   return (
  //     <View
  //       style={{
  //         flexBasis: 'auto',
  //         width: '100%',
  //         // padding: 4,
  //         paddingTop: 4,
  //         paddingLeft: 4,
  //         paddingBottom: 4,
  //         paddingRight: 4,
  //         backgroundColor: 'white',
  //       }}>
  //       {/*<TouchableOpacity onPress={this._navToStoreOrderListView}>*/}
  //       {/*  <XText style={{ marginVertical: 16, paddingVertical: 4, color: COLORS.APP_MAIN }}>{`รายการออเดอร์`}</XText>*/}
  //       {/*</TouchableOpacity>*/}
  //       {/*<TouchableOpacity onPress={this._navToProductListView}>*/}
  //       {/*  <XText style={{ marginVertical: 16, paddingVertical: 4, color: COLORS.APP_MAIN }}>{`รายการสินค้า`}</XText>*/}
  //       {/*</TouchableOpacity>*/}
  //       {/* <View style={{ height: 40, width: '100%' }}>
  //         <View
  //           // style={[_s.infoTextContainer,{ borderTopColor: infoBgColor }]} />
  //           style={{
  //             height: 50,
  //             width: 90,
  //             // width: '100%',
  //             position: 'absolute',
  //             top: 0,
  //             left: 0,
  //             // top: -32,
  //             // right: 10,
  //             backgroundColor: 'transparent',
  //             borderStyle: 'solid',
  //             borderRightWidth: 21,
  //             borderTopWidth: 21,
  //             borderRightColor: 'transparent',
  //             borderTopColor: COLORS.APP_MAIN,
  //           }}
  //         />
  //         <XText style={S.TEXT_ACTIVE_DARK}>yooooo</XText>
  //       </View> */}
  //       <TrapezoidTitle title={'ออเดอร์'} />
  //       <SideBarItem
  //         index={1}
  //         activeIndex={sidebarButtonActiveIndex}
  //         title={'ค้นหา'}
  //         icon={{ name: 'search1', family: 'AntDesign' }}
  //         onPress={this._navToSearchOrderView}
  //       />
  //       <SideBarItem
  //         index={6}
  //         activeIndex={sidebarButtonActiveIndex}
  //         title={'ที่รอฉันดำเนินการ'}
  //         count={donutWaitingMeCount}
  //         icon={{ name: 'chart-donut', family: 'MaterialCommunityIcons' }}
  //         onPress={this._navToStoreOrdersDonutViewStackNavigator}
  //       />
  //       <SideBarItem
  //         index={0}
  //         activeIndex={sidebarButtonActiveIndex}
  //         count={order_count}
  //         title={'ทั้งหมดที่เปิดอยู่'}
  //         icon={{ name: 'profile', family: 'AntDesign' }}
  //         onPress={this._navToStoreOrderListView}
  //       />
  //       <SideBarItem
  //         index={7}
  //         activeIndex={sidebarButtonActiveIndex}
  //         title={'เลือกหลายออเดอร์'}
  //         // count={donutWaitingMeCount}
  //         icon={{ name: 'checkbox-multiple-marked-circle', family: 'MaterialCommunityIcons' }}
  //         onPress={this._navToMultipleOrdersOperationMenuViewStackNavigator}
  //       />
  //       <SideBarItem
  //         index={3}
  //         activeIndex={sidebarButtonActiveIndex}
  //         title={'สแกนจัดส่ง'}
  //         icon={{ name: 'barcode-scan', family: 'MaterialCommunityIcons' }}
  //         onPress={this._navToQuickShipView}
  //       />
  //       <SideBarItem
  //         index={4}
  //         activeIndex={sidebarButtonActiveIndex}
  //         title={'พิมพ์ใบปะหน้าพัสดุ'}
  //         icon={{ name: 'printer', family: 'AntDesign' }}
  //         onPress={this._navToPrintShippingLabelView}
  //       />

  //       <TrapezoidTitle title={'สินค้า'} />
  //       <SideBarItem
  //         index={2}
  //         activeIndex={sidebarButtonActiveIndex}
  //         count={product_count}
  //         title={'รายการสินค้า'}
  //         icon={{ name: 'shopping-cart', family: 'FontAwesome' }}
  //         onPress={this._navToProductListView}
  //       />
  //       {/* <SideBarItem
  //         index={4}
  //         activeIndex={sidebarButtonActiveIndex}
  //         title={'พิมพ์ใบเบิกของจากสต๊อก'}
  //         icon={{ name: 'printer', family: 'AntDesign' }}
  //         onPress={this._navToReportRequisitionView}
  //       /> */}
  //       {/*<SideBarItem*/}
  //       {/*  index={2}*/}
  //       {/*  activeIndex={sidebarButtonActiveIndex}*/}
  //       {/*  title={'พิมพ์ใบปะหน้าพัสดุ'}*/}
  //       {/*  icon={{ name: 'printer', family: 'AntDesign' }}*/}
  //       {/*  onPress={this._navToMockingMenu} />*/}
  //       <TrapezoidTitle title={'อื่นๆ'} />
  //       <SideBarItem
  //         index={5}
  //         activeIndex={sidebarButtonActiveIndex}
  //         title={'รายงาน'}
  //         icon={{ name: 'chart-bar', family: 'MaterialCommunityIcons' }}
  //         onPress={this._navToReportListViewView}
  //       />
  //       <SideBarItem
  //         index={8}
  //         activeIndex={sidebarButtonActiveIndex}
  //         title={'คิวคำสั่งงาน'}
  //         icon={{ name: 'tasks', family: 'FontAwesome' }}
  //         onPress={this._navToBackgroundJobListView}
  //       />
  //     </View>
  //   )
  // }

  // _renderSomsaiSideBarItems = () => {
  //   const { sidebarButtonActiveIndex } = this.state
  //   const { navigation, selectedStore } = this.props
  //   const order_count = selectedStore.get('order_count')
  //   const product_count = selectedStore.get('product_count')
  //   const org = selectedStore.get(`org`)
  //   // console.log(`selectedStore`, selectedStore.toJS())
  //   // console.log(`ORG `, org)
  //   let isOnwer = false
  //   if (!_.isNil(org) && org.size > 0) {
  //     const orgJS = org.toJS()
  //     if (!_.isNil(orgJS[0].is_onwer) && orgJS[0].is_onwer) {
  //       isOnwer = true
  //     }
  //   }
  //   const type = getOrgResellerType(selectedStore)
  //   // console.log(`TYPE `, type)
  //   if (!isOnwer && type !== 1) {
  //     return null
  //   }

  //   return (
  //     <View
  //       style={{
  //         flexBasis: 'auto',
  //         width: '100%',
  //         marginTop: 50,
  //         // padding: 12,
  //         // backgroundColor: 'blue',
  //       }}>
  //       <View
  //         style={{
  //           flex: 1,
  //           flexBasis: 'auto',
  //           // width: '100%',
  //           margin: 8,
  //           backgroundColor: 'white',
  //         }}>
  //         <XText
  //           style={{
  //             fontSize: STYLES.FONT_SIZE_NORMAL,
  //             fontWeight: 'bold',
  //             color: COLORS.TEXT_INACTIVE,
  //             textAlign: 'center',
  //             paddingTop: 5,
  //             paddingBottom: 5,
  //           }}>
  //           บริหารตัวแทน
  //         </XText>
  //         <SideBarItem
  //           index={81}
  //           activeIndex={sidebarButtonActiveIndex}
  //           // count={order_count}
  //           title='อนุมัติตัวแทน'
  //           icon={{ name: 'profile', family: 'AntDesign' }}
  //           isSomsaiMenu
  //           onPress={this._navToResellerApprovalListView}
  //         />
  //         <SideBarItem
  //           index={82}
  //           activeIndex={sidebarButtonActiveIndex}
  //           // count={product_count}
  //           title='ดูข้อมูลตัวแทน'
  //           icon={{ name: 'people', family: 'Ionicons' }}
  //           isSomsaiMenu
  //           onPress={this._navToResellerListView}
  //         />
  //         {/* <SideBarItem
  //           index={83}
  //           activeIndex={sidebarButtonActiveIndex}
  //           title={'ลงทะเบียนตัวแทน'}
  //           icon={{ name: 'profile', family: 'AntDesign' }}
  //           isSomsaiMenu={true}
  //           onPress={this._navToResellerRegisterView}
  //         /> */}
  //         {isOnwer ? (
  //           <SideBarItem
  //             index={84}
  //             activeIndex={sidebarButtonActiveIndex}
  //             title='นำเข้าตัวแทน'
  //             icon={{ name: 'file-import', family: 'FontAwesome5' }}
  //             isSomsaiMenu
  //             onPress={this._navToResellerImportView}
  //           />
  //         ) : null}
  //       </View>
  //     </View>
  //   )
  // }

  _renderNavChromeWarning = () => {
    if (navigator.userAgent.indexOf('Chrome') === -1) {
      return (
        <View
          style={{
            // flex: 1,
            // marginTop: 50,
            position: 'absolute',
            bottom: IS_XSELLY_BUILD ? 35 : 20,
            justifyContent: 'center',
            alignItems: 'flex-end',
            minHeight: 12,
            width: '100%',
            flexDirection: 'row',
          }}>
          <XText
            style={{
              fontSize: STYLES.FONT_SIZE_SMALLER,
              color: COLORS.TEXT_INACTIVE,
            }}>
            เว็บไซต์จะแสดงผลได้ดีที่สุดบน
          </XText>
          <a href='https://www.google.com/chrome/' target='_blank' style={{ paddingLeft: 4, paddingRight: 4 }} rel='noreferrer'>
            <XText style={{ fontSize: STYLES.FONT_SIZE_SMALLER, color: COLORS.APP_MAIN }}>Chrome</XText>
          </a>
        </View>
      )
    }
    return null
  }

  _renderNavToWebV1 = () => {
    return null // start fading it out
    if (!IS_XSELLY_BUILD) {
      return null
    }
    return (
      <View
        style={{
          // flex: 1,
          // justifyContent: 'flex-end',
          // alignItems: 'flex-end',
          position: 'absolute',
          bottom: 30,
          justifyContent: 'center',
          alignItems: 'flex-end',
          minHeight: 6,
          width: '100%',
          flexDirection: 'row',
        }}>
        <XText style={{ fontSize: STYLES.FONT_SIZE_SMALLER, color: COLORS.TEXT_INACTIVE }}>ยังต้องการใช้งานเว็บแบบเก่า?</XText>
        <a href='https://appv1.xselly.com' target='_self' style={{ paddingLeft: 4, paddingRight: 4 }}>
          <XText style={{ fontSize: STYLES.FONT_SIZE_SMALLER, color: COLORS.APP_MAIN }}>คลิกที่นี่</XText>
        </a>
      </View>
    )
  }

  _renderVersionView = () => {
    const { isCollapsedSidebar = false } = this.state
    let buildVersionText = `Version: ${AUTOCONS.WEB_BUILD_TIMESTAMP}${MODE[0]}`
    if (isCollapsedSidebar) {
      buildVersionText = `${AUTOCONS.WEB_BUILD_TIMESTAMP.substring(
        AUTOCONS.WEB_BUILD_TIMESTAMP.length - 7,
        AUTOCONS.WEB_BUILD_TIMESTAMP.length
      )}${MODE[0]}`
    }

    return (
      <HStack my='1' w='full' justifyContent='center' alignItems='flex-end'>
        <XText fontSize='xs' variant='inactive'>
          {buildVersionText}
        </XText>
      </HStack>
    )
  }

  _renderCurrentSelectedStoreButton = () => {
    const { selectedStore, myStores } = this.props
    const selectedStoreId = selectedStore.get('id')
    const storeName = selectedStore.get('name')
    return (
      <View style={{ width: 200 }}>
        <XText
          onPress={this._initData}
          style={{
            color: 'white',
            fontSize: STYLES.FONT_SIZE_NORMAL,
            fontWeight: 'bold',
            textAlign: 'right',
          }}
          numberOfLines={1}>
          {storeName}
        </XText>
      </View>
    )
  }

  _renderPopoverButton = () => {
    const { isPopoverOpen } = this.state

    // return (
    //   <Popover
    //     isOpen={isPopoverOpen}
    //     // position={['top', 'right', 'left', 'bottom']} // if you'd like, supply an array of preferred positions ordered by priority
    //     position={['bottom']} // if you'd like, supply an array of preferred positions ordered by priority
    //     padding={2} // adjust padding here!
    //     disableReposition // prevents automatic readjustment of content position that keeps your popover content within your window's bounds
    //     onClickOutside={this._hidePopoverMenu} // handle click events outside of the popover/target here!
    //     content={(
    //       { position, nudgedLeft, nudgedTop, targetRect, popoverRect } // you can also provide a render function that injects some useful stuff!
    //     ) => (
    //       <View
    //         style={{
    //           height: 67,
    //           width: 123,
    //         }}>
    //         <View
    //           style={{
    //             height: 64,
    //             width: 120,
    //             borderWidth: 0.5,
    //             borderColor: COLORS.TEXT_INACTIVE,
    //             backgroundColor: 'white',
    //             shadowColor: '#000',
    //             shadowOffset: { width: 2, height: 3 },
    //             shadowOpacity: 0.5,
    //             shadowRadius: 2,
    //             elevation: 3,
    //           }}>
    //           <TouchableOpacity
    //             style={{
    //               height: 32,
    //               width: '100%',
    //               justifyContent: 'center',
    //               borderBottomWidth: 0.5,
    //               borderBottomColor: COLORS.TEXT_INACTIVE,
    //             }}
    //             onPress={this._showStoreSelectorModal}>
    //             <XText
    //               style={{
    //                 color: COLORS.TEXT_ACTIVE,
    //                 fontSize: STYLES.FONT_SIZE_NORMAL,
    //                 textAlign: 'center',
    //               }}
    //               numberOfLines={1}>
    //               เปลี่ยนร้าน
    //             </XText>
    //           </TouchableOpacity>
    //           <TouchableOpacity
    //             style={{ height: 32, width: '100%', justifyContent: 'center' }}
    //             onPress={async () => {
    //               const isUserConfirm = await p.op.isUserConfirm(
    //                 'ออกจากระบบ',
    //                 'กรุณายืนยันว่าจะทำการออกจากระบบ หากมีการดำเนินการใดๆ ค้างอยู่ ข้อมูลอาจจจะยังไม่ถูกบันทึก'
    //               )
    //               if (isUserConfirm) {
    //                 this._navToLoginView()
    //               }
    //             }}>
    //             <XText
    //               style={{
    //                 color: COLORS.TEXT_ACTIVE,
    //                 fontSize: STYLES.FONT_SIZE_NORMAL,
    //                 textAlign: 'center',
    //               }}
    //               numberOfLines={1}>
    //               ออกจากระบบ
    //             </XText>
    //           </TouchableOpacity>
    //         </View>
    //       </View>
    //     )}>
    //     <TouchableOpacity onPress={this._showPopoverMenu}>
    //       <XIcon
    //         inactive={isPopoverOpen}
    //         activeDark
    //         // size='md'
    //         style={{
    //           paddingTop: 2,
    //           fontSize: STYLES.FONT_ICON_NORMAL,
    //           fontWeight: 'bold',
    //         }}
    //         name='arrow-drop-down'
    //         family='MaterialIcons'
    //       />
    //     </TouchableOpacity>
    //   </Popover>
    // )

    return (
      <Popover
        mode={PopoverMode.RN_MODAL}
        // mode={PopoverMode.JS_MODAL}
        // mode={PopoverMode.TOOLTIP}
        isVisible={isPopoverOpen}
        animationConfig={{ delay: 0, duration: 0 }}
        placement={PopoverPlacement.BOTTOM}
        // placement={[PopoverPlacement.BOTTOM, PopoverPlacement.TOP]}
        popoverStyle={{
          backgroundColor: 'white',
          borderRadius: 12,
          borderWidth: 1,
          borderColor: '#888',
          overflow: 'hidden',
        }}
        // backgroundStyle={{ backfaceVisibility: 'visible', backgroundColor: 'green', opacity: 1 }}
        onRequestClose={this._hidePopoverMenu}
        from={
          <TouchableOpacity onPress={this._showPopoverMenu}>
            <XIcon
              inactive={isPopoverOpen}
              activeDark
              size='md'
              // style={{
              //   paddingTop: 2,
              //   fontSize: STYLES.FONT_ICON_NORMAL,
              //   fontWeight: 'bold',
              // }}
              name='arrow-drop-down'
              family='MaterialIcons'
            />
          </TouchableOpacity>
        }>
        <View
          style={{
            height: 67,
            width: 123,
          }}>
          <View
            style={{
              height: 64,
              width: 120,
              borderWidth: 0.5,
              borderColor: COLORS.TEXT_INACTIVE,
              backgroundColor: 'white',
              shadowColor: '#000',
              shadowOffset: { width: 2, height: 3 },
              shadowOpacity: 0.5,
              shadowRadius: 2,
              elevation: 3,
            }}>
            <TouchableOpacity
              style={{
                height: 32,
                width: '100%',
                justifyContent: 'center',
                borderBottomWidth: 0.5,
                borderBottomColor: COLORS.TEXT_INACTIVE,
              }}
              onPress={this._showStoreSelectorModal}>
              <XText
                style={{
                  color: COLORS.TEXT_ACTIVE,
                  fontSize: STYLES.FONT_SIZE_NORMAL,
                  textAlign: 'center',
                }}
                numberOfLines={1}>
                เปลี่ยนร้าน
              </XText>
            </TouchableOpacity>
            <TouchableOpacity
              style={{ height: 32, width: '100%', justifyContent: 'center' }}
              onPress={async () => {
                const isUserConfirm = await p.op.isUserConfirm(
                  'ออกจากระบบ',
                  'กรุณายืนยันว่าจะทำการออกจากระบบ หากมีการดำเนินการใดๆ ค้างอยู่ ข้อมูลอาจจจะยังไม่ถูกบันทึก'
                )
                if (isUserConfirm) {
                  this._navToLoginView()
                }
              }}>
              <XText
                style={{
                  color: COLORS.TEXT_ACTIVE,
                  fontSize: STYLES.FONT_SIZE_NORMAL,
                  textAlign: 'center',
                }}
                numberOfLines={1}>
                ออกจากระบบ
              </XText>
            </TouchableOpacity>
          </View>
        </View>
      </Popover>
    )
  }

  _renderDevTool = () => {
    if (!isImpersonateMode) {
      return null
    }

    return <HostSelector onSuccessChange={this._initData} />
  }

  _showPopoverMenu = async () => {
    await setStatePromise(this, { isPopoverOpen: true })
  }

  _hidePopoverMenu = async () => {
    await setStatePromise(this, { isPopoverOpen: false })
  }

  _renderStoreSelectorModal = () => {
    const { isVisibleStoreSelectorModal, browserWidth, browserHeight } = this.state
    if (!isVisibleStoreSelectorModal) {
      return null
    }
    const { myStores } = this.props

    const isVisibleVerticalScrollbar = browserHeight < MIN_WEB_HEIGHT
    let containerHeight = isVisibleVerticalScrollbar ? MIN_WEB_HEIGHT : browserHeight

    const availableWidth = isVisibleVerticalScrollbar ? browserWidth - RIGHT_SCROLLBAR_WIDTH : browserWidth
    if (availableWidth < MAX_WEB_WIDTH) {
      containerHeight -= BOTTOM_SCROLLBAR_HEIGHT
    }

    const contentHeight = containerHeight - HEADER_HEIGHT

    return (
      <XOverlay
        onRequestClose={this._hideStoreSelectorModal}
        // webWrapperContainerStyle={{ backgroundColor: 'white' }}
        // webNumColumns={3}
        visible={isVisibleStoreSelectorModal}>
        <XCustomHeader
          // title='เลือกร้านฉัน'
          title='เปลี่ยนร้านฉัน'
          headerRightProps={{ closeIcon: true, onPressItem: this._hideStoreSelectorModal }}
        />
        <XContent flex={1}>
          {/* <XText>{'Hello'}</XText> */}
          {/* <XText>{`${JSON.stringify(myStores.toJS())}`}</XText> */}
          {this._renderStoreSelectorCards(myStores)}
        </XContent>
      </XOverlay>
    )
  }

  _renderStoreSelectorCards = (stores) => stores.map((store, index) => this._renderStoreCard({ store, row_index: 0, col_index: index }))
  // const StoresComponent = []

  // if (stores.size > 0) {
  //   const COL_NUM = 3 // จำนวนคอลัมม์ (Manual FIXED)
  //   const ROW_NUM = Math.round(stores.size / COL_NUM) // คำนวณแถว

  //   for (let row = 0; row <= ROW_NUM; row++) {
  //     const startIndex = row * COL_NUM
  //     const endIndex = startIndex + COL_NUM
  //     const rowStores = stores.slice(startIndex, endIndex)
  //     StoresComponent.push(this._generateCardsOneRow({ stores: rowStores, row_index: row }))
  //   }
  // }

  // return StoresComponent

  // _generateCardsOneRow = ({ stores, row_index }) => (
  //   <HStack key={`StoreRow_${row_index}`} style={s.flex}>
  //     {stores.map((store, index) => this._renderStoreCard({ store, row_index, col_index: index }))}
  //   </HStack>
  // )

  _renderStoreCard = ({ store, row_index, col_index }) => {
    const { selectedStore } = this.props
    const selectedStoreId = selectedStore.get('id')
    const limitWidth = Math.floor(MAIN_CONTENT_WIDTH / 3)
    const storeIndex = row_index * 3 + col_index
    const storeId = store.get('id')
    const storeName = store.get('name')
    let storeDesc = store.get('description')
    if (!storeDesc) {
      storeDesc = '(ยังไม่มีคำอธิบายร้านค้า)'
    }
    const isActiveStore = selectedStoreId === storeId

    return (
      <XCard
        key={`Store_${row_index}${col_index}`}
        mx='2'
        mt={row_index === 0 ? '2' : '0'}
        mb='2'
        disabled={isActiveStore}
        onPress={() => this._onSelectNewStore(storeIndex)}>
        <VStack>
          <XImage
            style={{ width: '100%', height: 150, resizeMode: 'cover' }}
            resizeMode='cover'
            source={{ uri: store.get('cover_thumbnail_uri') }}
          />
          {isActiveStore ? (
            <Box bg='primary.100:alpha.50' px='2' py='1' borderRadius='lg' position='absolute' top='1' right='1'>
              <XText variant='primary' bold>
                กำลังใช้งาน
              </XText>
            </Box>
          ) : null}
          <VStack w='full' p='2'>
            <XText w='full' bold numberOfLines={2}>
              {storeName}
            </XText>
            <XText w='full' variant='inactive' fontSize='xs' numberOfLines={3}>
              {storeDesc}
            </XText>
          </VStack>
        </VStack>
      </XCard>
    )

    // return (
    //   <VStack
    //     key={`Store_${row_index}${col_index}`}
    //     // @ts-ignore
    //     style={StyleSheet.flatten([
    //       s.flex,
    //       {
    //         width: limitWidth,

    //         paddingTop: 8,
    //         paddingLeft: 8,
    //         paddingBottom: 8,
    //         paddingRight: 8,
    //         // padding: 8
    //       },
    //     ])}>
    //     {/* <Link to={ AppLinks.OrderList(store.get('id')) }> */}
    //     {/* </Link> */}
    //     <TouchableOpacity disabled={isActiveStore} style={s.flex} onPress={() => this._onSelectNewStore(storeIndex)}>
    //       <XCard
    //         // @ts-ignore
    //         style={StyleSheet.flatten(s.flex)}>
    //         <Box
    //           // @ts-ignore
    //           style={StyleSheet.flatten([
    //             s.flex,
    //             {
    //               width: '100%',
    //               height: 150,
    //               paddingLeft: 0,
    //               paddingBottom: 0,
    //               paddingTop: 0,
    //               paddingRight: 0,
    //               overflow: 'hidden',
    //               alignItems: 'flex-start',
    //             },
    //           ])}>
    //           <Image
    //             style={{ width: '100%', height: 150, resizeMode: 'cover' }}
    //             resizeMode='cover'
    //             source={{ uri: store.get('cover_thumbnail_uri') }}
    //           />
    //           {isActiveStore ? (
    //             <View
    //               style={{
    //                 position: 'absolute',
    //                 top: 4,
    //                 right: 4,
    //                 // backgroundColor: 'white'
    //                 // borderColor: COLORS.TEXT_INACTIVE,
    //                 // borderWidth: 1,
    //               }}>
    //               <XText
    //                 style={{
    //                   marginRight: 4,
    //                   color: COLORS.APP_MAIN,
    //                   fontSize: STYLES.FONT_SIZE_NORMAL,
    //                   fontWeight: 'bold',
    //                   textShadowColor: 'white',
    //                   textShadowOffset: { width: 2, height: 2 },
    //                   textShadowRadius: 5,
    //                 }}>
    //                 กำลังใช้งาน
    //               </XText>
    //             </View>
    //           ) : null}
    //         </Box>
    //         <Box
    //           style={[
    //             s.flex,
    //             {
    //               overflow: 'hidden',
    //               paddingLeft: 8,
    //               paddingBottom: 8,
    //               paddingTop: 4,
    //               paddingRight: 4,
    //               alignItems: 'flex-start',
    //             },
    //           ]}>
    //           <div>
    //             <Dotdotdot clamp={1}>
    //               <p
    //                 style={{
    //                   color: COLORS.TEXT_ACTIVE,
    //                   fontSize: STYLES.FONT_SIZE_NORMAL,
    //                   fontWeight: 'bold',
    //                   // textAlign: 'right',
    //                 }}>
    //                 {storeName}
    //               </p>
    //             </Dotdotdot>
    //             <Dotdotdot clamp={2}>
    //               <p
    //                 style={{
    //                   color: COLORS.TEXT_INACTIVE,
    //                   fontSize: STYLES.FONT_SIZE_SMALLER,
    //                   // fontWeight: 'bold',
    //                   // textAlign: 'right',
    //                 }}>
    //                 {storeDesc}
    //               </p>
    //             </Dotdotdot>
    //           </div>
    //         </Box>
    //       </XCard>
    //     </TouchableOpacity>
    //   </VStack>
    // )
  }

  _showStoreSelectorModal = async () => {
    await this._hidePopoverMenu()
    await setStatePromise(this, { isVisibleStoreSelectorModal: true })
  }

  _hideStoreSelectorModal = () => {
    this.setState({ isVisibleStoreSelectorModal: false })
  }

  _setSidebarButtonActiveIndexAndCloseHamburger = async (index: number) => {
    await this._setSidebarButtonActiveIndex(index)
    this.closeHamburgerMenu()
  }

  _setSidebarButtonActiveIndex = async (index: number) => {
    await setStatePromise(this, { sidebarButtonActiveIndex: index })
  }

  _onSelectNewStore = async (newIndex: number | string): Promise<void> => {
    this._hideStoreSelectorModal()
    const { myStores, selectedStore, navigation } = this.props
    if (!List.isList(myStores) || myStores.size < 1) {
      return
    }
    const selectedIndex = _.isString(newIndex) ? parseInt(newIndex) : newIndex
    const newStoreId = myStores.getIn([selectedIndex, 'id'])
    const newStoreName = myStores.getIn([selectedIndex, 'name'])
    if (!newStoreId) {
      return
    }
    const currentStoreId = selectedStore.get('id')
    if (newStoreId === currentStoreId) {
      p.op.showToast(`ฉันใช้งานร้าน "${newStoreName}" อยู่`, 'warning')
      return
    }
    // await setStatePromise(this, { selectedStoreId: newStoreId })
    navigation.setParams({ store_id: newStoreId })
    await delay(50)
    const isSuccess = await this._fetchMyStore()

    if (isSuccess) {
      await p.op.storageSet(xCONS.STATE_SAVE_KEYS.LATEST_PICKED_STORE_ID, `${newStoreId}`, false)
      p.op.showToast(`เปลี่ยนใช้งานร้าน "${newStoreName}" แล้ว`, 'success')
      navigation.navigate('EmptyView')
      // await delay(50)
      // // ดู index จาก this.SIDEBAR_MENUS
      // // this._navToStoreOrderListView(3)
      // this._navToStoreOrdersDonutViewStackNavigator(3)
      this._navToStoreOrdersDonutViewStackNavigator()
    } else {
      // await setStatePromise(this, { selectedStoreId: currentStoreId })
      p.op.showToast(`เปลี่ยนใช้งานร้าน "${newStoreName}" ไม่สำเร็จ`, 'danger')
      navigation.setParams({ store_id: currentStoreId })
    }
  }

  _navToStoreOrdersDonutViewStackNavigator = () => {
    const { selectedStore, navigation, route } = this.props
    const store_id = selectedStore.get('id')
    const state = navigation.getState()

    navigation.dispatch({
      ...CommonActions.reset({
        index: 0,
        routes: [
          {
            name: 'StoreOrdersDonutListView',
            params: { store_id },
          },
        ],
      }),
      source: route.key,
      target: state.routes[0].state.key,
    })

    this.setState({ sidebarButtonActiveIndex: 3 })
  }

  getThemeSizeValue = () => {
    const { children, selectedStore } = this.props
    const {
      browserWidth,
      browserHeight,
      isInitialized,
      sidebarButtonActiveIndex = 0,
      contentWidth,
      // sidebarWidth,
      isCollapsedSidebar = false,
      numOfColumns = 3,
      warningHight,
    } = this.state
    // console.log('warningHight => ', warningHight)
    const isVisibleVerticalScrollbar = browserHeight < MIN_WEB_HEIGHT
    let containerHeight = isVisibleVerticalScrollbar ? MIN_WEB_HEIGHT : browserHeight

    const availableWidth = isVisibleVerticalScrollbar ? browserWidth - RIGHT_SCROLLBAR_WIDTH : browserWidth
    if (availableWidth < MAX_WEB_WIDTH) {
      containerHeight -= BOTTOM_SCROLLBAR_HEIGHT
    }
    const containerHeightStyle = { height: containerHeight }
    const contentHeight = containerHeight - HEADER_HEIGHT - warningHight
    // const contentHeight = containerHeight - HEADER_HEIGHT
    const contentWithNavHeaderHeight = containerHeight - HEADER_HEIGHT - NAV_HEADER_HEIGHT
    const contentHeightStyle = { height: contentHeight }
    const contentWithNavHeaderHeightStyle = { height: contentWithNavHeaderHeight }
    const browserWidthStyle = { width: browserWidth }
    const browserHeightStyle = { height: browserHeight }
    const sidebarWidth = isCollapsedSidebar ? SIDEBAR_COLLAPSED_WIDTH : SIDEBAR_WIDTH
    return {
      numOfColumns,
      sidebarWidth,
      contentWidth,
      contentWidthStyle: { width: contentWidth },
      columnWidth: Math.floor(MAIN_CONTENT_WIDTH / numOfColumns),
      columnWidthStyle: { width: Math.floor(MAIN_CONTENT_WIDTH / numOfColumns) },

      contentOneOfThreeColumnWidth: Math.floor(contentWidth / 3),
      contentOneOfThreeColumnWidthStyle: { width: Math.floor(contentWidth / 3) },
      contentTwoOfThreeColumnWidth: Math.floor((contentWidth * 2) / 3),
      contentTwoOfThreeColumnWidthStyle: { width: Math.floor((contentWidth * 2) / 3) },
      browserHeight,
      browserWidth,
      browserWidthStyle,
      browserHeightStyle,
      containerHeight,
      containerHeightStyle,
      contentHeight,
      contentHeightStyle,
      contentWithNavHeaderHeight,
      contentWithNavHeaderHeightStyle,
      isVisibleVerticalScrollbar,
    }
  }

  toggleHamburgerMenu = () => {
    const { isHamburgerMenuOpen = false } = this.state
    this.setState({ isHamburgerMenuOpen: !isHamburgerMenuOpen })
  }

  openHamburgerMenu = async () => {
    const { isKeyboardOpen } = this.state
    if (isKeyboardOpen) {
      await delay(200)
    }

    this.setState({ isHamburgerMenuOpen: true })
  }

  closeHamburgerMenu = () => {
    this.setState({ isHamburgerMenuOpen: false })
  }

  renderHamburgerMenu = () => {
    const { isHamburgerMenuOpen = false, browserHeight, browserWidth } = this.state

    // if (browserWidth >= SIDEBAR_RESPONSIVE_WIDTH_POINT) {
    //   return null
    // }
    // // return (
    // //   <XButton variant='outline' onPress={this.toggleHamburgerMenu}>
    // //     <XIcon variant='activeDark' name={isHamburgerMenuOpen ? 'close-sharp' : 'menu'} />
    // //   </XButton>
    // // )

    const shouldHideHamburger = browserWidth >= SIDEBAR_RESPONSIVE_WIDTH_POINT

    return (
      <Popover
        // key={`Hamburger_${isHamburgerMenuOpen}`}
        mode={PopoverMode.RN_MODAL}
        // mode={PopoverMode.JS_MODAL}
        // mode={PopoverMode.TOOLTIP}
        isVisible={isHamburgerMenuOpen}
        animationConfig={{ delay: 0, duration: 0 }}
        placement={PopoverPlacement.BOTTOM}
        // placement={[PopoverPlacement.BOTTOM, PopoverPlacement.TOP]}
        popoverStyle={{
          backgroundColor: 'white',
          borderRadius: 12,
          borderWidth: 1,
          borderColor: '#888',
          overflow: 'hidden',
        }}
        // backgroundStyle={{ backfaceVisibility: 'visible', backgroundColor: 'green', opacity: 1 }}
        onRequestClose={this.closeHamburgerMenu}
        from={this.renderHamburgerButton}>
        <VStack
          // w={`${SIDEBAR_WIDTH + 20}px`}
          w={`${SIDEBAR_WIDTH + 30}px`}
          maxH={`${browserHeight - HEADER_HEIGHT - 24}px`}
          // bg='white'
          // bg='amber.200'
          // borderWidth='1'
          // borderRadius='md'
          p='1'
          // position='absolute'
          // top={`${HEADER_HEIGHT}px`}
          // left='0'
        >
          <Sidebar
            navigation={this.props.navigation}
            route={this.props.route}
            activeIndex={this.state.sidebarButtonActiveIndex}
            onActiveIndexChange={this._setSidebarButtonActiveIndexAndCloseHamburger}
          />
          {this._renderVersionView()}
        </VStack>
      </Popover>
    )
  }

  renderHamburgerButton = (sourceRef) => {
    const { isHamburgerMenuOpen = false, browserHeight, browserWidth } = this.state

    const shouldHideHamburger = browserWidth >= SIDEBAR_RESPONSIVE_WIDTH_POINT

    return (
      <TouchableOpacity
        onPress={this.openHamburgerMenu}
        // @ts-ignore
        style={{ visibility: shouldHideHamburger ? 'hidden' : 'visible' }}>
        {/* <XIcon name={isHamburgerMenuOpen ? 'close-sharp' : 'menu'} /> */}
        <HStack
          // @ts-ignore
          ref={sourceRef}
          p='1'
          borderWidth='1'
          borderRadius='md'
          borderColor='white'
          alignItems='center'
          justifyContent='center'>
          <XIcon variant='activeDark' name='menu' />
        </HStack>
      </TouchableOpacity>
    )
  }

  _toggleCollapseSidebar = () => {
    const { isHamburgerMenuOpen = false, isCollapsedSidebar = false } = this.state
    if (isHamburgerMenuOpen) {
      return
    }

    const newIsCollapsedSidebar = !isCollapsedSidebar
    const sidebarWidth = newIsCollapsedSidebar === true ? SIDEBAR_COLLAPSED_WIDTH : SIDEBAR_WIDTH

    this.setState({ isCollapsedSidebar: newIsCollapsedSidebar, sidebarWidth })
  }

  _renderPackageWarning = () => {
    const { isSubscriptionWarningActive, subscriptionWarning } = this.props
    // console.log('isSubscriptionWarningActive => ', isSubscriptionWarningActive)
    // console.log('subscriptionWarning => ', subscriptionWarning)
    if (!isSubscriptionWarningActive) {
      return null
    }
    const BG_COLOR = util.getWraningBgColor(subscriptionWarning.type)
    // const { closeable } = subscriptionWarning
    // h={`${HEADER_HEIGHT}px`}
    return (
      <HStack w='full' p='2' alignItems='center' justifyContent='center' space='1' bgColor={BG_COLOR}>
        <VStack>
          <XText textAlign='center' color={COLORS.WHITE}>
            {subscriptionWarning.message}
          </XText>
          <HStack alignItems='center' justifyContent='center' mt='2'>
            <XButton variant='outline' w='120px' h='10' alignSelf='center' mr='2' onPress={() => this._navToSubscriptionView()}>
              ดูรายละเอียด
            </XButton>
            <XButton variant='outline' w='80px' h='10' alignSelf='center' onPress={() => this.props.hideSubscription()}>
              ปิด
            </XButton>
          </HStack>
          {/* {closeable ? (
            <XButton variant='outline' w='80px' h='10' mt='2' alignSelf='center' onPress={() => this.props.hideSubscription()}>
              ปิด
            </XButton>
          ) : null} */}
        </VStack>
      </HStack>
    )
  }

  _navToSubscriptionView = () => {
    const { navigation } = this.props
    navigation.dispatch(NavActions.navToSubscriptionView())
  }

  _renderPackageQuotaWarning = () => {
    const { isSubscriptionWarningQuotaActive, subscriptionQuotaWarning } = this.props
    // console.log('isSubscriptionWarningQuotaActive => ', isSubscriptionWarningQuotaActive)
    // console.log('subscriptionQuotaWarning => ', subscriptionQuotaWarning)
    if (!isSubscriptionWarningQuotaActive) {
      return null
    }
    const BG_COLOR = util.getWraningBgColor(subscriptionQuotaWarning.type)
    // const { closeable } = subscriptionQuotaWarning
    // h={`${HEADER_HEIGHT}px`}
    return (
      <HStack w='full' p='2' alignItems='center' justifyContent='center' space='1' bgColor={BG_COLOR}>
        <VStack>
          <XText textAlign='center' color={COLORS.WHITE}>
            {subscriptionQuotaWarning.message}
          </XText>
          <HStack alignItems='center' justifyContent='center' mt='2'>
            <XButton variant='outline' w='120px' h='10' alignSelf='center' mr='2' onPress={() => this._navToSubscriptionView()}>
              ดูรายละเอียด
            </XButton>
            <XButton variant='outline' w='80px' h='10' alignSelf='center' onPress={() => this.props.hideQuotaWarning()}>
              ปิด
            </XButton>
          </HStack>
        </VStack>
      </HStack>
    )
  }

  _renderMkpWarning = () => {
    const { getWarningReAuthMKP, navigation } = this.props
    // console.log('getWarningReAuthMKP => ', getWarningReAuthMKP)
    if (!getWarningReAuthMKP) {
      return null
    }
    const navToChannelListView = () => {
      navigation.dispatch(
        NavActions.navToMkpChannelListView({
          channelReAuth: null,
        })
      )
    }

    // return null
    const BG_COLOR = getWarningReAuthMKP.warningBackgroundColor
    // h={`${HEADER_HEIGHT}px`}
    return (
      <HStack w='full' p='2' alignItems='center' justifyContent='center' space='1' bgColor={BG_COLOR}>
        <VStack>
          <XText textAlign='center' color={COLORS.WHITE}>
            {getWarningReAuthMKP.warningText}
          </XText>
          <HStack alignItems='center' justifyContent='center' mt='2'>
            <XButton variant='outline' w='120px' h='10' alignSelf='center' mr='2' onPress={() => navToChannelListView()}>
              ดูรายละเอียด
            </XButton>
            <XButton variant='outline' w='80px' h='10' alignSelf='center' onPress={() => this.props.unSetWarningReAuthMKPState()}>
              ปิด
            </XButton>
          </HStack>
        </VStack>
      </HStack>
    )
  }

  _renderWarningHeader = () => {
    const {} = this.props
    return (
      <VStack
        onLayout={(event) => {
          const { x, y, width, height } = event.nativeEvent.layout
          util.setStatePromise(this, { warningHight: height })
        }}>
        {this._renderPackageWarning()}
        {this._renderPackageQuotaWarning()}
        {this._renderMkpWarning()}
      </VStack>
    )
  }

  render() {
    const { children, selectedStore } = this.props
    const {
      browserWidth,
      browserHeight,
      isInitialized,
      sidebarButtonActiveIndex = 0,
      sidebarWidth,
      isCollapsedSidebar = false,
    } = this.state
    if (!isInitialized || !selectedStore.get('id')) {
      return (
        <XContainer>
          <XContent contentContainerStyle={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <Spinner color={COLORS.APP_MAIN} />
          </XContent>
        </XContainer>
      )
    }

    // const isVisibleVerticalScrollbar = browserHeight < MIN_WEB_HEIGHT
    // let containerHeight = isVisibleVerticalScrollbar ? MIN_WEB_HEIGHT : browserHeight

    // const availableWidth = isVisibleVerticalScrollbar ? browserWidth - RIGHT_SCROLLBAR_WIDTH : browserWidth
    // if (availableWidth < MAX_WEB_WIDTH) {
    //   containerHeight -= BOTTOM_SCROLLBAR_HEIGHT
    // }
    // const containerHeightStyle = { height: containerHeight }
    // const contentHeight = containerHeight - HEADER_HEIGHT
    // const contentWithNavHeaderHeight = containerHeight - HEADER_HEIGHT - NAV_HEADER_HEIGHT
    // const contentHeightStyle = { height: contentHeight }
    // const contentWithNavHeaderHeightStyle = { height: contentWithNavHeaderHeight }
    // const browserWidthStyle = { width: browserWidth }
    // const browserHeightStyle = { height: browserHeight }

    const themeSizeValue = this.getThemeSizeValue()

    return (
      <ThemeSizeContext.Provider value={themeSizeValue}>
        <VStack
          minW='320px'
          alignItems='center'
          // @ts-ignore
          style={StyleSheet.flatten([s.flex, s.container, themeSizeValue.containerHeightStyle])}>
          <VStack
            w='full'
            // @ts-ignore
            ref={this.stackContainerRef}
            // style={{ maxWidth: MAX_WEB_WIDTH }}
          >
            {this._renderWarningHeader()}
            <VStack>
              <View style={[{ position: 'absolute', top: 0, left: 0, right: 0, height: HEADER_HEIGHT }, s.bgAppMain]} />
              {/* Header */}
              <HStack w='full' h={`${HEADER_HEIGHT}px`} px='2' alignItems='center' space='1'>
                {/* <TouchableWithoutFeedback onPress={() => window.location.reload()}>
                <Image source={WEB_RUNTIME.APP_LOGO_WHITE_TEXT} resizeMode='contain' style={s.imageLogo} />
              </TouchableWithoutFeedback> */}
                {browserWidth >= SIDEBAR_RESPONSIVE_WIDTH_POINT ? (
                  <XImage source={WEB_RUNTIME.APP_LOGO_WHITE_TEXT} resizeMode='contain' style={s.imageLogo} />
                ) : null}

                {this.renderHamburgerMenu()}

                <HStack flex={1} alignItems='center' justifyContent='flex-end'>
                  {this._renderCurrentSelectedStoreButton()}
                  {this._renderPopoverButton()}
                  {this._renderDevTool()}
                  {this._renderStoreSelectorModal()}
                </HStack>
              </HStack>
            </VStack>

            {/* Content */}
            <HStack style={[s.flex, themeSizeValue.contentHeightStyle]}>
              {/* SideBar */}
              {/* <VStack w={`${SIDEBAR_WIDTH}px`}>
                <ScrollView>{this._renderSideBarItems()}</ScrollView>
                {this._renderVersionView()}
              </VStack> */}

              {browserWidth >= SIDEBAR_RESPONSIVE_WIDTH_POINT ? (
                <VStack
                  w={`${sidebarWidth}px`}
                  // @ts-ignore
                  ref={this.sidebarContainerRef}>
                  <Sidebar
                    navigation={this.props.navigation}
                    route={this.props.route}
                    activeIndex={this.state.sidebarButtonActiveIndex}
                    onActiveIndexChange={this._setSidebarButtonActiveIndex}
                    isCollapsed={isCollapsedSidebar}
                  />
                  <XButton
                    variant='ghost'
                    // w='9'
                    // h='9'
                    // borderRadius='full'
                    w='full'
                    // position='absolute'
                    // top='4'
                    // right='0'
                    // style={{ position: 'absolute', right: 0, top: 0, zIndex: 1 }}
                    onPress={this._toggleCollapseSidebar}>
                    <HStack>
                      <XIcon
                        size='xs'
                        name={isCollapsedSidebar ? 'arrow-collapse-right' : 'arrow-collapse-left'}
                        family='MaterialCommunityIcons'
                        variant='inactive'
                      />
                      {!isCollapsedSidebar && (
                        <XText fontSize='xs' variant='inactive' color='gray.500'>
                          ย่อเมนู
                        </XText>
                      )}
                    </HStack>
                  </XButton>
                  {this._renderVersionView()}
                </VStack>
              ) : null}

              <VStack
                key={sidebarButtonActiveIndex.toString()}
                flex={1}
                style={StyleSheet.flatten([themeSizeValue.contentHeightStyle, { minWidth: 320 }])}>
                {children}
              </VStack>
            </HStack>
          </VStack>
          {/* {isHamburgerMenuOpen ? (
            <VStack
              // w={`${SIDEBAR_WIDTH + 20}px`}
              w={`${SIDEBAR_WIDTH + 120}px`}
              h={`${browserHeight - HEADER_HEIGHT}px`}
              bg='white'
              // bg='amber.200'
              borderWidth='1'
              borderRadius='md'
              p='1'
              position='absolute'
              top={`${HEADER_HEIGHT}px`}
              left='0'>
              <Sidebar navigation={this.props.navigation} route={this.props.route} />
            </VStack>
          ) : null} */}
        </VStack>
      </ThemeSizeContext.Provider>
    )

    // return (
    //   <XContainer
    //     // @ts-ignore
    //     style={[s.container, containerHeightStyle]}>
    //     {/* <XContent style={[containerHeightStyle, { backgroundColor: 'red' }]} scrollEnabled={false} contentContainerStyle={[s.contentContainer, containerHeightStyle]}> */}
    //     <XContent style={[containerHeightStyle, s.bgContainer]} scrollEnabled={false} contentContainerStyle={[containerHeightStyle]}>
    //       <ThemeSizeContext.Provider
    //         value={{
    //           contentWidth: MAIN_CONTENT_WIDTH,
    //           contentWidthStyle: { width: MAIN_CONTENT_WIDTH },
    //           contentOneOfThreeColumnWidth: Math.floor(MAIN_CONTENT_WIDTH / 3),
    //           contentOneOfThreeColumnWidthStyle: { width: Math.floor(MAIN_CONTENT_WIDTH / 3) },
    //           contentTwoOfThreeColumnWidth: Math.floor((MAIN_CONTENT_WIDTH * 2) / 3),
    //           contentTwoOfThreeColumnWidthStyle: { width: Math.floor((MAIN_CONTENT_WIDTH * 2) / 3) },

    //           browserHeight,
    //           browserWidth,
    //           browserWidthStyle,
    //           browserHeightStyle,
    //           containerHeight,
    //           containerHeightStyle,
    //           contentHeight,
    //           contentHeightStyle,
    //           contentWithNavHeaderHeight,
    //           contentWithNavHeaderHeightStyle,
    //           isVisibleVerticalScrollbar,
    //         }}>
    //         {/* <Stack style={s.container}> */}
    //         <View
    //           // @ts-ignore
    //           style={[s.fixedHeaderBg, s.bgAppMain]}
    //         />
    //         <VStack
    //           // @ts-ignore
    //           style={[s.flex, s.contentContainer, containerHeightStyle]}>
    //           <HStack style={[s.flex, s.bgAppMain, { height: HEADER_HEIGHT }]}>
    //             {/* Header Logo */}
    //             <VStack
    //               style={[
    //                 s.flex,
    //                 {
    //                   // padding: 8,
    //                   paddingTop: 8,
    //                   paddingLeft: 0,
    //                   paddingBottom: 8,
    //                   paddingRight: 8,
    //                 },
    //               ]}>
    //               <TouchableWithoutFeedback onPress={() => window.location.reload()}>
    //                 <Image source={WEB_RUNTIME.APP_LOGO_WHITE_TEXT} resizeMode='contain' style={s.imageLogo} />
    //               </TouchableWithoutFeedback>
    //             </VStack>
    //             <VStack style={s.flex}>
    //               <View style={[s.flex, { justifyContent: 'flex-end', alignItems: 'center', flexDirection: 'row' }]}>
    //                 {this._renderCurrentSelectedStoreButton()}
    //                 {this._renderPopoverButton()}
    //                 {this._renderDevTool()}
    //                 {this._renderStoreSelectorModal()}
    //               </View>
    //             </VStack>
    //             {/* <VStack> */}
    //             {/*  <XText>Yo some text</XText> */}
    //             {/* </VStack> */}
    //           </HStack>
    //           <HStack style={[s.flex, contentHeightStyle]}>
    //             {/* SideBar */}
    //             <VStack w={`${SIDEBAR_WIDTH}px`}>
    //               <ScrollView>
    //                 {this._renderSideBarItems()}
    //                 {/* {this._renderSomsaiSideBarItems()} */}

    //                 {/* <View style={{ flex: 1, width: '100%' }}>
    //                   {this._renderNavChromeWarning()}
    //                   {this._renderNavToWebV1()}
    //                   {this._renderVersionView()}
    //                 </View> */}

    //                 {/* <Button danger={true} onPress={this._navToLoginView}> */}
    //                 {/*  <XText>Go HOME</XText> */}
    //                 {/* </Button> */}
    //               </ScrollView>
    //               {this._renderVersionView()}
    //             </VStack>
    //             {/* Content */}
    //             {/* <VStack
    //               // @ts-ignore
    //               style={StyleSheet.flatten([s.flex, s.colMainContent, contentHeightStyle])}>
    //               {children}
    //             </VStack> */}
    //             <VStack
    //               key={sidebarButtonActiveIndex.toString()}
    //               // @ts-ignore
    //               style={StyleSheet.flatten([s.flex, s.colMainContent, contentHeightStyle, { width: MAIN_CONTENT_WIDTH }])}>
    //               {children}
    //             </VStack>
    //           </HStack>
    //         </VStack>
    //       </ThemeSizeContext.Provider>
    //     </XContent>
    //   </XContainer>
    // )
  }
}

// Wrap and export with navigation
export default function (props) {
  const navigation = useNavigation()
  const route = useRoute()

  return <StoreMyView {...props} navigation={navigation} route={route} />
}

const DataHosts = [
  { label: 'dev', host: 'superdev.xselly.com', go_host: 'superdevgo.xselly.com', https: true },
  { label: 'prod', host: 'api.xselly.com', go_host: 'api2.xselly.com', https: true },
  { label: 'web1', host: '167.71.206.142', go_host: '167.71.206.142' },
  { label: 'web8', host: '128.199.206.168', go_host: '128.199.206.168' },
  { label: 'web9', host: '104.248.154.95', go_host: '104.248.154.95' },
  { label: 'SS prod', host: 'api.somsai.co.th', go_host: 'api2.somsai.co.th', https: true },
  { label: 'SS dev', host: 'superdev.somsai.co.th', go_host: 'superdevgo.somsai.co.th', https: true },
]

const HostSelector = ({ onSuccessChange }) => {
  const [selectedIndex, setSelectedIndex] = useState(0)

  const _changeHost = (newIndex) => {
    const protocol = DataHosts[newIndex].https ? 'https' : 'http'
    const newHost = `${protocol}://${DataHosts[newIndex].host}/api`
    const newGOHost = `${protocol}://${DataHosts[newIndex].go_host}/api`
    SETTINGS.app.API_ROOT = newHost
    SETTINGS.app.GO_API_ROOT = newGOHost
    // SETTINGS.s3.options.bucket = RUNTIME_ENDPOINT_CONFIG.s3_options_bucket

    if (newHost === FLAVOUR_CONFIG.prod_app_apiRoot) {
      SETTINGS.s3.options.keyPrefix = FLAVOUR_CONFIG.prod_s3_options_keyPrefix
    } else {
      SETTINGS.s3.options.keyPrefix = FLAVOUR_CONFIG.dev_s3_options_keyPrefix
    }
    setConfiguration('API_ROOT', SETTINGS.app.API_ROOT)
    setConfiguration('GO_API_ROOT', SETTINGS.app.GO_API_ROOT)
  }

  const _handleChangeSegment = async (newIndex) => {
    setSelectedIndex(newIndex)
    await delay(200)
    _changeHost(newIndex)
    await delay(200)
    onSuccessChange()
  }

  return (
    <View style={[S.CARDLIKE_BORDERS, S.ROW_CENTER, { width: 500, backgroundColor: COLORS.WHITE }]}>
      <Segment selectedIndex={selectedIndex} options={DataHosts.map((dh) => dh.label)} onSegmentChange={_handleChangeSegment} />
    </View>
  )
}

const s = {
  container: {
    // backgroundColor: COLORS.BG_LIGHT_GREY_ALTERNATIVE,
    // backgroundColor: 'red',
    overflow: 'hidden',
  },
  contentContainer: {
    // flex: 1,
    alignSelf: 'center',
    // paddingTop: 50,
    width: MAX_WEB_WIDTH,
    // overflow: 'scroll',
    // height: '100%',
    // minHeight: 480,
    // backgroundColor: 'purple',
  },
  colSidebar: {
    flex: 1,
    flexBasis: 'auto',
    // height: '100%',
    flexDirection: 'column',
    width: SIDEBAR_WIDTH,
    // backgroundColor: 'white',
    // borderLeftWidth: 0.5,
    // borderRightWidth: 0.5,
    // borderColor: COLORS.TEXT_INACTIVE,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    paddingRight: 4,
  },
  colMainContent: {
    flex: 1,
    flexBasis: 'auto',
    // height: '100%',
    width: MAIN_CONTENT_WIDTH,
    backgroundColor: 'lightgrey',
    borderColor: 'blue',
    overflow: 'hidden',
  },
  flex: {
    flex: 1,
    flexBasis: 'auto',
  },
  noflex: {
    flex: 0,
    flexBasis: 'auto',
  },
  height50: {
    height: 50,
  },
  imageLogo: {
    width: 112,
    height: 34,
  },
  fixedHeaderBg: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: HEADER_HEIGHT,
    // backgroundColor: COLORS.APP_MAIN,
  },
  bgAppMain: {
    backgroundColor: COLORS.APP_MAIN,
  },
  bgContainer: {
    backgroundColor: COLORS.BG_LIGHT_GREY_ALTERNATIVE,
  },
  btnActiveContainer: {
    paddingVertical: 8,
    backgroundColor: COLORS.APP_SECONDARY,
  },
  btnInactiveContainer: {
    paddingVertical: 8,
  },
  btnActiveText: {
    // fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.APP_MAIN,
  },
  btnInactiveText: {
    // fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_INACTIVE,
  },
  btnXIcon: {
    flex: 0,
    flexBasis: 'auto',
    fontSize: 18,
    minWidth: 18,
    textAlign: 'left',
  },
}
