import React, { Component } from 'react'

import { TouchableOpacity, View, FlatList, Platform, StyleSheet, Dimensions, ViewStyle, TextStyle } from 'react-native'

import { Button, Text, Stack, Card, Image } from 'native-base'
import Box from 'xui/components/Box'
import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'

import { log, logRender, confirmForceCreateShipping, delay, isNumberOnly, setStatePromise, isJSON } from 'x/utils/util'
import CONS from 'x/config/constants'
import _ from 'lodash'
import { COLORS, STYLES, btnOnMode, textOnBtnMode } from 'x/config/styles'
// const barcodeBorder = require('../../../images/barcode-marker.png')
// import * as indicator from '../../services/indicator'
import p from 'x/config/platform-specific'
import xCONS from 'x/config/constants'
import * as util from 'x/utils/util'
import { IShipViewProps, IShipViewState } from 'x/index'
import XForceCreateShipping from 'xui/components/XForceCreateShipping'
import XCustomHeader from '../../components/XCustomHeader'
import XFlatPicker from '../../components/XFlatPicker'
import ForwardIcon from '../../components/ForwardIcon'
import XInput from '../../components/XInput'
import XContent from '../../components/XContent'
import XContainer from '../../components/XContainer'
import Icon from '../../components/Icon'
import ActionSheet from '../../components/singleton/ActionSheet'
// const XCamera = Platform.OS === 'ios' ? RNCamera : RCTCamera
// const XCamera = RNCamera // Move both ios and android to RNCamera
abstract class BaseUIShipView extends Component<IShipViewProps, IShipViewState> {
  static displayName = 'BaseUIShipView'

  // static navigationOptions = ({ navigation }) => {
  //   const { goBack, state } = navigation
  //   const { txtTitle, curQty, maxQty } = state.params
  //   let title = txtTitle || ''
  //   return {
  //     headerLeft: (
  //       <Button
  //         transparent
  //         onPress={() => {
  //           goBack(state.key)
  //         }}>
  //         <BackIcon />
  //       </Button>
  //     ),
  //     title,
  //     headerRight: maxQty ? <Text style={s.txtLabel}>{`(${curQty || 0}/${maxQty})  `}</Text> : null,
  //   }
  // }
  isUnmounting?: boolean

  _inProcess?: boolean

  isReadingBarcode?: boolean

  shippingTypeItems: Array<{
    index: number
    name: string // right icon ?
    right: string // right icon ?
    value: number
    key: string
    tel_required?: boolean
    label: string
  }>

  abstract ringBarcodeSound(): void

  abstract renderModalScanBarcode(): JSX.Element

  abstract appRenderCamera(): JSX.Element

  constructor(props) {
    super(props)
    this.state = {
      modalVisible: false,
      transientModalClose: false,
      barcodeScanned: false,
      // currentNavIndex: props.navigatorStateIndex ? props.navigatorStateIndex : null,
      shippingTypeIndex: 0,

      isCameraGranted: false,
      isAndroidOldVersion: false,
      isForceCreateShipping: false,
    }

    // init constant
    const { ORDER_VIEW_SHIPPING_TYPE_ITEMS } = xCONS
    this.shippingTypeItems = ORDER_VIEW_SHIPPING_TYPE_ITEMS.map((item) => ({
      ...item,
      label: p.op.t(`Order.shippingTypeItems.${item.key}`),
      name: p.op.t(`Order.shippingTypeItems.${item.key}`),
    }))
  }

  async componentDidMount() {
    const params = util.getNavParams(this.props)
    const { order_id, store_id } = params

    if (order_id && store_id) {
      // showAppLoadingScreen(() => this._fetchShipmentOrder({ order_id, store_id }))
      await this._fetchShipmentOrder({ order_id, store_id })
    } else {
      p.op.showToast('ไม่พบออเดอร์หรือร้านค้าที่เลือก กรุณาลองใหม่อีกครั้ง', 'danger')
      this._handleGoBack()
    }
  }

  // static getDerivedStateFromProps(nextProps: IShipViewProps, prevState: IShipViewState): Partial<IShipViewState> {
  //   const derivedState: Partial<IShipViewState> = {}
  //   const { navigation, curQty, maxQty, editingOrderShip } = this.props
  //   if (editingOrderShip !== nextProps.editingOrderShip) {
  //     const orderName = editingOrderShip.get('name')
  //     if (orderName) {
  //       derivedState.headerTitle = `จัดส่งใบสั่งซื้อ #${orderName}`
  //     }
  //     const shippingTypeId = editingOrderShip.get('shipping_type_id')
  //     if (shippingTypeId) {
  //       const shippingTypeIndex = xCONS.ORDER_VIEW_SHIPPING_TYPE_ITEMS.findIndex(sti => sti.value === shippingTypeId)
  //       if (shippingTypeIndex > -1) {
  //         // this.setState({ shippingTypeIndex })
  //         derivedState.shippingTypeIndex = shippingTypeIndex
  //       }
  //     }
  //   }
  //
  //   // if (curQty !== nextProps.curQty || maxQty !== nextProps.maxQty) {
  //   //   navigation.setParams({ curQty, maxQty })
  //   // }
  //
  //   return derivedState
  // }

  componentWillUnmount() {
    if (!this.isUnmounting) {
      this.isUnmounting = true
      this.props.shipmentActions.reset()
    }
  }

  _fetchShipmentOrder = async (body: { order_id; store_id }) => {
    // this.props.dispatch({ type: actions.SHIPMENT_ORDER_FETCH, payload: { order_id, store_id } })
    const res: any = await new Promise((resolve) => {
      this.props.shipmentActions.fetchShipmentOrder({
        body,
        successCallback: resolve,
        failedCallback: () => resolve(null),
      })
    })

    if (res && res.order && res.order.shipping_type_id) {
      const orderShippingTypeIndex = _.findIndex(this.shippingTypeItems, (ship) => ship.value === res.order.shipping_type_id)
      if (orderShippingTypeIndex !== this.state.shippingTypeIndex) {
        await setStatePromise(this, { shippingTypeIndex: orderShippingTypeIndex })
      }
    }

    return res
  }

  // /////// For UI focus management /////////
  // pushToRefGroup = (refGroupName, refKey, refValue) => { // No return
  //   if (refKey && refValue) {
  //     if (!_.has(this.inputRefs, refGroupName)) { // ถ้ายังไม่มรี group
  //       this.inputRefs[refGroupName] = []
  //     }
  //     // avoid duplicate
  //     if (!_.find(this.inputRefs[refGroupName], obj => obj.key === refKey)) {
  //       this.inputRefs[refGroupName].push({ key: refKey, value: refValue })
  //     }
  //   }
  // }
  //
  // jumpToNextRefInGroup(refGroupName, refKey) { // Current Ref: will jump until last of group keyboard will dismiss
  //   if (refGroupName && refKey) {
  //     let foundIndex = parseInt(_.findIndex(this.inputRefs[refGroupName], (obj) => obj.key === refKey))
  //     log('foundIndex is : ', foundIndex)
  //     if (foundIndex > -1) {
  //       if (this.inputRefs[refGroupName][foundIndex + 1]) {
  //         this.inputRefs[refGroupName][foundIndex + 1].value.focus()
  //       } else {
  //         Keyboard.dismiss()
  //       }
  //     }
  //   }
  // }

  _renderProductList = (products) => products.map((product, index) => this._renderProductItem({ index, product }))

  _renderProductItem = ({ index, product }) => {
    // console.log('PRODUCT index', index, product.toJS())
    const { shipmentActions } = this.props
    const pName = product.get('name')
    const pVariant = product.get('variant')
    const pp_id = product.get('pp_id')
    const pThumbnail = product.getIn(['thumbnail_uris', 0])
    const pQty = product.get('qty')
    const sQty = product.get('selectedQty')

    const listItemStyle: ViewStyle = { ...s.productListItem }
    const txtQtyStyle: TextStyle = { width: 70, ...s.txtRight }
    const compareQty = this.compareQty(sQty, pQty)
    switch (compareQty) {
      case 1:
        listItemStyle.backgroundColor = COLORS.FORM_SUCCESS_BG
        txtQtyStyle.color = COLORS.FORM_SUCCESS
        break
      case 0:
        txtQtyStyle.color = COLORS.TEXT_ACTIVE
        break
      case -1:
        listItemStyle.backgroundColor = COLORS.FORM_ERROR_BG
        txtQtyStyle.color = COLORS.FORM_ERROR
        break
      default:
        listItemStyle.width = 0
        listItemStyle.height = 0
    }

    if (p.op.isWeb()) {
      return (
        <View style={[listItemStyle, { flexDirection: 'row' }]}>
          <View style={{ width: 64, height: 100, alignItems: 'center', justifyContent: 'center' }}>
            <Image size='80' source={{ uri: pThumbnail }} />
          </View>
          <View style={{ flex: 1, alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
            <View style={{ margin: 0, padding: 0, marginLeft: 5 }}>
              <Text>{pName}</Text>
              <Text>{pVariant}</Text>
            </View>
            <View style={{ width: 130, margin: 0, padding: 0, flexDirection: 'row' }}>
              <View style={{ width: 30, margin: 0, padding: 0, justifyContent: 'center', alignItems: 'center' }}>
                <TouchableOpacity onPress={() => shipmentActions.decreaseQty({ pp_id })}>
                  <Icon name='remove-circle' style={{ color: COLORS.FORM_ERROR, fontSize: STYLES.FONT_ICON_LARGEST }} />
                </TouchableOpacity>
              </View>
              <View style={{ width: 70, justifyContent: 'center', alignItems: 'center' }}>
                {/* <Text style={{ color: COLORS.TEXT_ACTIVE, fontSize: STYLES.FONT_SIZE_LARGER }}>{pQty.toString()}</Text> */}
                <Text
                  // @ts-ignore
                  style={StyleSheet.flatten([txtQtyStyle, { textAlign: 'center' }])}>{`${sQty}/${pQty}`}</Text>
              </View>
              <View style={{ width: 30, margin: 0, padding: 0, justifyContent: 'center', alignItems: 'center' }}>
                <TouchableOpacity onPress={() => shipmentActions.increaseQty({ pp_id })}>
                  <Icon name='add-circle' style={{ color: COLORS.FORM_SUCCESS, fontSize: STYLES.FONT_ICON_LARGEST }} />
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      )
    }

    return (
      <HStack key={`ProductItem_${index}`} style={StyleSheet.flatten(listItemStyle)}>
        <Image size='80' source={{ uri: pThumbnail }} />
        <Stack style={{ justifyContent: 'space-between' }}>
          <VStack style={{ margin: 0, padding: 0, marginLeft: 5 }}>
            <Stack>
              <HStack>
                <Text>{pName}</Text>
              </HStack>
              <HStack>
                <Text>{pVariant}</Text>
              </HStack>
            </Stack>
          </VStack>
          <VStack style={{ width: 130, margin: 0, padding: 0, flexDirection: 'row' }}>
            <View style={{ width: 30, margin: 0, padding: 0, justifyContent: 'center', alignItems: 'center' }}>
              <TouchableOpacity onPress={() => shipmentActions.decreaseQty({ pp_id })}>
                <Icon name='remove-circle' style={{ color: COLORS.FORM_ERROR, fontSize: STYLES.FONT_ICON_LARGEST }} />
              </TouchableOpacity>
            </View>
            <View style={{ width: 70, justifyContent: 'center', alignItems: 'center' }}>
              {/* <Text style={{ color: COLORS.TEXT_ACTIVE, fontSize: STYLES.FONT_SIZE_LARGER }}>{pQty.toString()}</Text> */}
              <Text style={txtQtyStyle}>{`${sQty}/${pQty}`}</Text>
            </View>
            <View style={{ width: 30, margin: 0, padding: 0, justifyContent: 'center', alignItems: 'center' }}>
              <TouchableOpacity onPress={() => shipmentActions.increaseQty({ pp_id })}>
                <Icon name='add-circle' style={{ color: COLORS.FORM_SUCCESS, fontSize: STYLES.FONT_ICON_LARGEST }} />
              </TouchableOpacity>
            </View>
          </VStack>
        </Stack>
      </HStack>
    )
  }

  _renderEmptyProducts = () => (
    <Card>
      <Box>
        <View style={{ ...s.gridColumnContainer, height: 120 }}>
          <Text style={s.txtLabel}>ไม่มีรายการสินค้าสำหรับจัดส่ง</Text>
        </View>
      </Box>
    </Card>
  )

  compareQty = (current, max) => {
    if (current === max) {
      return 1
    }
    if (current > 0 && current < max) {
      return 0
    }
    if (current === 0) {
      return -1
    }
    return false
  }

  openModal = async () => {
    if (p.op.isWeb()) {
      await setStatePromise(this, { isModalExiting: false })
      await delay(200)
    }

    this.setState({ modalVisible: true })
    log('Open Shipping Camera !!!')
  }

  closeModal = async () => {
    if (p.op.isWeb()) {
      await setStatePromise(this, { isModalExiting: true })
      await delay(200)
    }

    this.setState({ modalVisible: false, barcodeScanned: false })
    log('Closed Shipping Camera !!!')
  }

  onBarcodeScan = async (e, isDisableRingSound = false) => {
    log('_BarcodeScan!!!__e_: ', e)
    if (_.has(e, 'data') && _.isString(e.data)) {
      const parseEnterData = e.data.replace(/\n/g, '')
      if (!isDisableRingSound) {
        this.ringBarcodeSound()
      }
      this.props.shipmentActions.shipmentChange({ tracking_number: parseEnterData })
    }
    await delay(2000)
    this.isReadingBarcode = false
  }

  shipButtonCancel = () => {
    this.closeModal()
  }

  shipButtonConfirm = async ({ store_id, order_id, products, tracking_number, shipping_type_id, note }) => {
    if (this._inProcess) {
      return
    }
    this._inProcess = true
    setTimeout(() => {
      this._inProcess = false
    }, 1500)
    const { navigation } = this.props
    if (store_id && order_id) {
      if (products.size > 0 && tracking_number !== '' && shipping_type_id !== '') {
        const sumQty = products.reduce((sum, product) => sum + product.get('selectedQty'), 0)
        if (sumQty > 0) {
          const body: any = { store_id, order_id, products: [] }

          if (shipping_type_id && isNumberOnly(shipping_type_id) && parseInt(shipping_type_id) > 0) {
            body.shipping_type_id = parseInt(shipping_type_id)
          } else {
            p.op.alert('ไม่ทราบรูปแบบจัดส่ง', 'ระบุรูปแบบจัดส่ง')
            return
          }

          if (tracking_number && tracking_number.trim().length > 0) {
            body.tracking_number = tracking_number
          } else {
            p.op.alert('ไม่พบเลขติดตามพัสดุ', 'กรุณาระบุเลขติดตามพัสดุ')
            return
          }

          if (note && note.length > 0) {
            body.note = note
          }

          products.forEach((pItem) => {
            const pp_id = pItem.get('pp_id') || null
            const watingQty = pItem.get('qty')
            const selectedQty = pItem.get('selectedQty')
            if (pp_id && selectedQty > 0 && selectedQty <= watingQty) {
              body.products.push({
                pp_id,
                qty: selectedQty,
              })
            }
          })

          const { isForceCreateShipping } = this.state
          if (isForceCreateShipping) {
            const onfirmForceCreateShipping = await confirmForceCreateShipping()
            if (!onfirmForceCreateShipping) {
              return
            }
            body.force_create = isForceCreateShipping
          }

          if (products.length < 1) {
            p.op.alert('ไม่ระบุสินค้าจัดส่ง', 'กรุณาระบุสินค้าจัดส่งอย่างน้อย 1 ชิ้น')
            return
          }

          // log('shipButtonConfirm products => ', products.toJS())
          // log('shipButtonConfirm body => ', body)
          //
          const msg = `ยืนยันการจัดส่งสินค้า ${sumQty} ชิ้น หรือไม่`
          p.op.showConfirmation(
            'คำเตือน',
            msg,
            async () => {
              // this.props.dispatch({
              //   type: actions.SHIPMENT_ORDER_OPERATE_SHIP,
              //   payload: body,
              //   callback: () => navigation.goBack(navigation.state.key),
              // })
              this.closeModal()
              const res = await new Promise((resolve) => {
                this.props.shipmentActions.operateShip({ body, successCallback: resolve, failedCallback: () => resolve(null) })
              })
              if (!_.isNull(res)) {
                this._handleGoBack()
              }
              log('____shipButtonConfirm ดำเนินการจัดส่งแล้ว____')
            },
            null,
            'ยืนยัน',
            'ยกเลิก'
          )
        } else {
          p.op.alert('ข้อมูลไม่ครบถ้วน', 'กรุณาระบุสินค้าจัดส่งอย่างน้อย 1 ชิ้น')
        }
      } else {
        p.op.alert('ข้อมูลไม่ครบถ้วน', 'กรุณาระบุสินค้าจัดส่ง เลขพัสดุ และประเภทการจัดส่งให้ครบถ้วน')
      }
    } else {
      p.op.alert('เกิดข้อผิดพลาด', 'เกิดข้อผิดพลาดบางอย่างผิดพลาดเกี่ยวกับข้อมูลร้านและใบสั่งซื้อ')
    }
  }

  _onSingleLocalBarcodeDetected = (e) => {
    if (this.isReadingBarcode) {
      return
    }
    this.isReadingBarcode = true
    // FIXME: O: Temporary ignored barcode
    // https://github.com/react-native-community/react-native-camera/issues/2875
    if (isJSON(e.data)) {
      return
    }
    this.onBarcodeScan(e)
  }

  _onGoogleVisionBarcodesDetected = async (response) => {
    if (this.isReadingBarcode) {
      return
    }
    this.isReadingBarcode = true
    if (response && response.barcodes && response.barcodes.length > 0) {
      if (response.barcodes.length > 1) {
        this._onMultipleBarcodesDetected(response.barcodes)
      } else {
        const barcodeData = response.barcodes[0]
        if (isJSON(barcodeData)) {
          // FIXME: O: Temporary ignored barcode
          // https://github.com/react-native-community/react-native-camera/issues/2875
          this.isReadingBarcode = false
          return
        }
        this.onBarcodeScan(barcodeData)
      }
    } else {
      await delay(2000)
      this.isReadingBarcode = false
    }
  }

  _onMultipleBarcodesDetected = async (barcodes) => {
    this.ringBarcodeSound()

    const codeArr = barcodes.map((barcode) => barcode.data)
    const actionConfig = {
      title: 'เลือกใช้ข้อมูลที่สแกนพบ...',
      cancelButtonIndex: codeArr.length,
      options: codeArr.concat('ยกเลิก'),
    }
    ActionSheet.show(actionConfig, async (buttonIndex) => {
      this._inProcess = false
      const idx = _.isString(buttonIndex) ? parseInt(buttonIndex) : buttonIndex
      if (idx >= 0 && idx < barcodes.length) {
        this.onBarcodeScan(barcodes[idx], true)
      } else {
        await delay(2000)
        this.isReadingBarcode = false
      }
    })
  }

  _onChangeShippingType = ({ index }) => {
    this.setState({ shippingTypeIndex: index })
  }

  _handleGoBack = () => {
    util.navGoBack(this.props)
  }

  _renderCustomHeader = () => {
    const { editingOrderShip } = this.props
    const orderName = editingOrderShip.get('name')
    let headerTitle = 'จัดส่งใบสั่งซื้อ ...'
    if (orderName) {
      headerTitle = `จัดส่งใบสั่งซื้อ #${orderName}`
    }
    return (
      <XCustomHeader
        title={headerTitle}
        headerStyle={p.op.isAndroid() ? { marginTop: 0 } : {}}
        headerLeftProps={{ backIcon: true, onPressItem: this._handleGoBack }}
        renderHeaderRight={this._renderHeaderRight}
      />
    )
  }

  _renderHeaderRight = () => {
    const { curQty, maxQty } = this.props
    return maxQty ? <Text style={[s.txtLabel, { marginRight: 10 }]}>{`(${curQty || 0}/${maxQty})`}</Text> : null
  }

  _renderDetailShippingTypeCardButton = (data) => {
    const { item, index, disabled } = data
    let txtShippingType = null
    if (index > -1) {
      txtShippingType = this.shippingTypeItems[index].label
    } else if (index === -1 && item && item.value > 0) {
      txtShippingType = 'กรุณาดาวน์โหลดแอปเวอร์ชั่นใหม่ เพื่อแสดงผลข้อมูลนี้'
    } else {
      txtShippingType = 'ยังไม่ได้เลือก'
    }
    if (p.op.isWeb()) {
      return (
        <Stack>
          <VStack size={50}>
            <View style={{ flex: 1, paddingTop: 3, paddingBottom: 3, flexDirection: 'row' }}>
              <Icon
                type='MaterialIcons'
                name='local-shipping'
                style={StyleSheet.flatten([s.shippingIcon, { paddingLeft: 6, color: COLORS.TEXT_INACTIVE, width: 32 }])}
              />
              <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE, textAlign: 'left', paddingLeft: 3 }}>
                รูปแบบจัดส่ง
              </Text>
            </View>
          </VStack>
          <VStack size={50}>
            <Text
              style={{
                fontSize: STYLES.FONT_SIZE_NORMAL,
                color: COLORS.TEXT_ACTIVE,
                textAlign: 'right',
                paddingTop: 4,
                // paddingRight: 8,
                // paddingLeft: 12,
              }}>
              {txtShippingType}
            </Text>
          </VStack>
        </Stack>
      )
    }

    return (
      <View style={{ height: 68, width: '100%', marginTop: 15, paddingLeft: 15 }}>
        <Card style={{ flex: 0, flexBasis: 'auto' }}>
          <Box
            style={{
              flex: 0,
              flexBasis: 'auto',
              minHeight: 44,
              paddingTop: 9,
              paddingBottom: 9,
              borderBottomWidth: 0,
            }}>
            <HStack
              style={[
                {
                  flex: 1,
                  // backgroundColor: 'red',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  borderBottomWidth: 0,
                },
              ]}>
              <View
                style={{
                  flex: 1,
                  paddingTop: 3,
                  paddingBottom: 3,
                  flexDirection: 'row',
                  // backgroundColor: 'green',
                }}>
                <Icon
                  type='MaterialIcons'
                  name='local-shipping'
                  style={[
                    s.shippingIcon,
                    {
                      paddingLeft: 18,
                      color: COLORS.TEXT_INACTIVE,
                      alignSelf: 'center',
                    },
                  ]}
                />
                <Text
                  style={{
                    fontSize: STYLES.FONT_SIZE_NORMAL,
                    color: COLORS.TEXT_INACTIVE,
                    textAlign: 'left',
                    paddingLeft: 3,
                  }}>
                  รูปแบบจัดส่ง
                </Text>
              </View>
            </HStack>
            <HStack
              style={{
                borderBottomWidth: 0,
                flex: 2,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
                paddingRight: 15,
              }}>
              <Text
                style={{
                  fontSize: STYLES.FONT_SIZE_NORMAL,
                  color: COLORS.TEXT_ACTIVE,
                  textAlign: 'right',
                  paddingRight: 8,
                  // paddingLeft: 12,
                }}>
                {txtShippingType}
              </Text>
              <Icon
                style={{
                  fontSize: STYLES.FONT_ICON_NORMAL,
                  color: COLORS.TEXT_PRIMARY,
                }}
                name='arrow-forward'
              />
            </HStack>
          </Box>
        </Card>
      </View>
    )
  }

  _renderCommonFlatPickerItem = (data) => {
    const { item, index, disabled } = data
    return (
      <Stack style={s.shippingTypePickerItemContainer}>
        <VStack size={100} style={s.justifyContentCenter}>
          <Text style={s.txtNormal}>{`${item.label}`}</Text>
        </VStack>
        {!p.op.isWeb() ? (
          <VStack size={0} style={s.colFixedWidth50}>
            <ForwardIcon />
          </VStack>
        ) : null}
      </Stack>
    )
  }

  renderShipmentContent = () => {
    const { navigation, editingOrderShip, shipmentActions, curQty } = this.props
    const { barcodeScanned, shippingTypeIndex } = this.state
    // const { store_id } = navigation.state.params
    // const { store_id, order_id } = navigation.state.params
    const order_id = editingOrderShip.get('order_id')
    // const store_id = editingOrderShip.get('store_id')
    const products = editingOrderShip.get('products')
    const tracking_number = editingOrderShip.get('tracking_number')
    // const shipping_type_id = editingOrderShip.get('shipping_type_id')
    let shipping_type_id = editingOrderShip.get('shipping_type_id')
    if (!_.isNil(shippingTypeIndex)) {
      const newShippingTypeId = CONS.ORDER_VIEW_SHIPPING_TYPE_ITEMS[this.state.shippingTypeIndex].value
      if (newShippingTypeId) {
        shipping_type_id = newShippingTypeId
      }
    }
    const note = editingOrderShip.get('note')
    // log('Rerender BaseUIShipView products: ', products.toJS())

    const selectedShippingType = editingOrderShip.get('shipping_type_id') || 0
    const indexOfItem = _.findIndex(this.shippingTypeItems, (ship) => ship.value === selectedShippingType)
    let shippingTypeLabel = 'ยังไม่ได้เลือกรูปแบบจัดส่ง'
    if (indexOfItem > -1) {
      shippingTypeLabel = this.shippingTypeItems[indexOfItem].label
    }

    const canShip = curQty > 0

    const handleChangeOrderShippingType = (itemInfo) => {
      const { item, index } = itemInfo
      // if (!item || !item.value) {
      //   return
      // }
      // const newShippingTypeId = item.value
      this._onChangeShippingType({ index })
    }

    return (
      <XContent
        contentContainerStyle={{
          flex: 1,
          justifyContent: 'flex-start',
          alignItems: 'center',
          paddingTop: 10,
          backgroundColor: 'white',
        }}>
        {this.appRenderCamera()}
        {/* { p.op.isWeb() ? null : <View style={s.barcodeCutLine1} /> } */}
        {/* { p.op.isWeb() ? null : <View style={s.barcodeCutLine2} /> } */}
        <View style={{ flex: 1, width: '100%', padding: 8 }}>
          <View style={[s.webFlexBasis, { flexDirection: 'row', marginTop: 12, marginBottom: 16 }]}>
            <View style={[s.webFlexBasis, { flex: p.op.isWeb() ? 1 : 0, width: 54, justifyContent: 'center' }]}>
              <Text style={StyleSheet.flatten(s.txtLabel)}>เลขพัสดุ</Text>
            </View>
            <View style={[s.webFlexBasis, { flex: 7, minWidth: 100 }]}>
              <XInput
                style={StyleSheet.flatten(s.txtHLRight)}
                value={tracking_number}
                onChangeText={(text) => shipmentActions.shipmentChange({ tracking_number: text })}
                placeholder='รหัสสำหรับติดตามพัสดุ'
                placeholderTextColor={COLORS.TEXT_INACTIVE}
              />
            </View>
          </View>

          <XFlatPicker
            // style={{ paddingVertical: 8 }}
            // style={{ minHeight: 54, marginTop: 10, marginLeft: 10, paddingLeft: 0, borderWidth: 1 }}
            // disabled={!isEditable}
            // data={this.shippingTypeItems}
            // label='รูปแบบจัดส่ง'
            // title={`${p.op.t('choose')}${p.op.t('Order.shippingType')}`}
            // selectedIndex={this.state.shippingTypeIndex}
            // onPick={this._onChangeShippingType}

            // ref={this._shippingTypeCreateModePickerRef}
            title='เลือกรูปแบบจัดส่ง'
            placeholder='กรุณาเลือกรูปแบบจัดส่ง'
            selectedIndex={this.state.shippingTypeIndex}
            options={this.shippingTypeItems}
            onPick={handleChangeOrderShippingType}
            renderSelectedValue={this._renderDetailShippingTypeCardButton}
            renderItem={this._renderCommonFlatPickerItem}
          />

          <View style={[s.webFlexBasis, { flexDirection: 'row', marginTop: 12 }]}>
            <Text style={StyleSheet.flatten(s.txtLabel)}>หมายเหตุ</Text>
          </View>
          <View style={[s.webFlexBasis, { paddingLeft: 12 }]}>
            <XInput
              selectTextOnFocus
              value={note}
              onChangeText={(text) => shipmentActions.shipmentChange({ note: text })}
              style={StyleSheet.flatten(s.txtMultipleHL)}
              placeholder='บันทึกข้อความช่วยในการจดจำ'
              placeholderTextColor={COLORS.TEXT_INACTIVE}
              multiline
            />
          </View>
          <View style={{ paddingTop: 40 }}>
            <XForceCreateShipping
              value={this.state.isForceCreateShipping}
              onClick={(newValue: boolean) =>
                setStatePromise(this, {
                  isForceCreateShipping: newValue,
                })
              }
            />
          </View>
        </View>
      </XContent>
    )
  }

  renderShipmentFooter = () => {
    const { navigation, editingOrderShip, shipmentActions, curQty } = this.props
    const { barcodeScanned, shippingTypeIndex } = this.state
    // const { store_id } = navigation.state.params
    const store_id = util.getNavParam(this.props, 'store_id')
    const order_id = editingOrderShip.get('order_id')
    const products = editingOrderShip.get('products')
    const tracking_number = editingOrderShip.get('tracking_number')
    let shipping_type_id = editingOrderShip.get('shipping_type_id')
    if (!_.isNil(shippingTypeIndex)) {
      const newShippingTypeId = CONS.ORDER_VIEW_SHIPPING_TYPE_ITEMS[this.state.shippingTypeIndex].value
      if (newShippingTypeId) {
        shipping_type_id = newShippingTypeId
      }
    }
    const note = editingOrderShip.get('note')
    return (
      <HStack>
        <HStack style={StyleSheet.flatten(STYLES.FOOTER_BG)}>
          <Button style={StyleSheet.flatten(btnOnMode(CONS.BTN_MODE.ACTIVE_SECONDARY))} onPress={this.shipButtonCancel}>
            <Text style={StyleSheet.flatten([textOnBtnMode(CONS.BTN_MODE.ACTIVE_SECONDARY), { fontSize: STYLES.FONT_SIZE_LARGER }])}>
              ยกเลิก
            </Text>
          </Button>
          <Button
            style={StyleSheet.flatten(btnOnMode(CONS.BTN_MODE.ACTIVE_PRIMARY))}
            onPress={() =>
              this.shipButtonConfirm({
                store_id,
                order_id,
                products,
                tracking_number,
                shipping_type_id,
                note,
              })
            }>
            <Text style={StyleSheet.flatten([textOnBtnMode(CONS.BTN_MODE.ACTIVE_PRIMARY), { fontSize: STYLES.FONT_SIZE_LARGER }])}>
              จัดส่ง
            </Text>
          </Button>
        </HStack>
      </HStack>
    )
  }

  _keyExtractor = (item, index) => index.toString()

  renderMain = () => {
    logRender(this)
    const { navigation, editingOrderShip, shipmentActions, curQty } = this.props
    const { barcodeScanned, shippingTypeIndex } = this.state
    // const { store_id } = navigation.state.params
    // const { store_id, order_id } = navigation.state.params
    const order_id = editingOrderShip.get('order_id')
    // const store_id = editingOrderShip.get('store_id')
    const products = editingOrderShip.get('products')
    const tracking_number = editingOrderShip.get('tracking_number')
    // const shipping_type_id = editingOrderShip.get('shipping_type_id')
    let shipping_type_id = editingOrderShip.get('shipping_type_id')
    if (!_.isNil(shippingTypeIndex)) {
      const newShippingTypeId = xCONS.ORDER_VIEW_SHIPPING_TYPE_ITEMS[this.state.shippingTypeIndex].value
      if (newShippingTypeId) {
        shipping_type_id = newShippingTypeId
      }
    }
    const note = editingOrderShip.get('note')
    // log('Rerender BaseUIShipView products: ', products.toJS())

    const selectedShippingType = editingOrderShip.get('shipping_type_id') || 0
    const indexOfItem = _.findIndex(this.shippingTypeItems, (ship) => ship.value === selectedShippingType)
    let shippingTypeLabel = 'ยังไม่ได้เลือกรูปแบบจัดส่ง'
    if (indexOfItem > -1) {
      shippingTypeLabel = this.shippingTypeItems[indexOfItem].label
    }

    const canShip = curQty > 0

    return (
      <XContainer style={s.container}>
        {this._renderCustomHeader()}
        <XContent style={{ margin: 0, paddingLeft: p.op.isWeb() ? 0 : 5, paddingRight: p.op.isWeb() ? 0 : 5 }}>
          <FlatList
            keyExtractor={this._keyExtractor}
            style={{ marginLeft: 0, paddingLeft: 0 }}
            // dataArray={products.toArray()}
            // renderRow={(product, sectionID, index) => this._renderProductItem({ index, product })}>
            data={products.toArray()}
            renderItem={({ item, index }) => this._renderProductItem({ index, product: item })}>
            {/* { this._renderProductList(products) } */}
          </FlatList>
          {products.size > 0 ? <View /> : this._renderEmptyProducts()}
        </XContent>
        <HStack>
          <HStack style={StyleSheet.flatten(STYLES.FOOTER_BG)}>
            <Button
              style={StyleSheet.flatten(btnOnMode(CONS.BTN_MODE.ACTIVE_SECONDARY))}
              onPress={() => {
                shipmentActions.clearQty()
                log('Decrease All !!!')
              }}>
              <Icon name='remove-circle' style={{ fontSize: STYLES.FONT_ICON_NORMAL, color: COLORS.FORM_ERROR }} />
              <Text style={StyleSheet.flatten([textOnBtnMode(CONS.BTN_MODE.ACTIVE_SECONDARY), { fontSize: STYLES.FONT_SIZE_NORMAL }])}>
                ลดจำนวนเป็นศูนย์
              </Text>
            </Button>
            <Button
              disabled={!canShip}
              style={StyleSheet.flatten(canShip ? btnOnMode(CONS.BTN_MODE.ACTIVE_PRIMARY) : btnOnMode(CONS.BTN_MODE.INACTIVE))}
              onPress={this.openModal}>
              <Icon
                name='barcode'
                style={StyleSheet.flatten([
                  canShip ? textOnBtnMode(CONS.BTN_MODE.ACTIVE_PRIMARY) : textOnBtnMode(CONS.BTN_MODE.INACTIVE),
                  { fontSize: STYLES.FONT_ICON_NORMAL },
                ])}
              />
              <Text
                style={StyleSheet.flatten([
                  canShip ? textOnBtnMode(CONS.BTN_MODE.ACTIVE_PRIMARY) : textOnBtnMode(CONS.BTN_MODE.INACTIVE),
                  { fontSize: STYLES.FONT_SIZE_NORMAL },
                ])}>
                ระบุเลขพัสดุ
              </Text>
            </Button>
            <Button
              style={StyleSheet.flatten(btnOnMode(CONS.BTN_MODE.ACTIVE_SECONDARY))}
              onPress={() => {
                shipmentActions.maxQty()
                log('Increase All !!!')
              }}>
              <Icon name='add-circle' style={{ fontSize: STYLES.FONT_ICON_NORMAL, color: COLORS.FORM_SUCCESS }} />
              <Text style={StyleSheet.flatten([textOnBtnMode(CONS.BTN_MODE.ACTIVE_SECONDARY), { fontSize: STYLES.FONT_SIZE_NORMAL }])}>
                ส่งครบจำนวน
              </Text>
            </Button>
          </HStack>
        </HStack>
        {this.renderModalScanBarcode()}
      </XContainer>
    )
  }
}

export default BaseUIShipView

export const s = StyleSheet.create({
  shippingIcon: {
    fontSize: STYLES.FONT_ICON_SMALLER,
    color: COLORS.APP_MAIN,
    paddingRight: 6,
    paddingTop: 1,
  },
  shippingTypePickerItemContainer: {
    borderBottomWidth: p.op.isWeb() ? 0 : 1,
    borderBottomColor: COLORS.TEXT_INACTIVE,
    paddingLeft: 12,
    paddingVertical: 8,
  },
  colFixedWidth50: {
    flex: 0,
    width: 50,
  },
  justifyContentCenter: {
    justifyContent: 'center',
  },
  webFlexBasis: {
    ...Platform.select({
      web: {
        flexBasis: 'auto',
      },
    }),
  },
  webWidth100Percent: {
    ...Platform.select({
      web: {
        width: '100%',
      },
    }),
  },
  container: {
    flex: 1,
    justifyContent: 'space-between',
  },
  content: {
    flex: 1,
    paddingLeft: 10,
    paddingRight: 10,
  },
  gridColumnContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  halfColumnContainer: {
    width: '50%',
    padding: 5,
  },
  pCardImg: { width: '100%', height: 100 },
  productListItem: {
    margin: 0,
    paddingLeft: 5,
    paddingRight: 5,
    marginLeft: 0,
    marginRight: 0,
  },
  formInCard: {
    alignSelf: 'stretch',
    margin: 0,
    padding: 0,
    flex: 1,
  },
  borderPrimary: {
    borderColor: COLORS.FORM_PRIMARY,
  },
  borderError: {
    borderColor: COLORS.FORM_ERROR,
  },
  borderSuccess: {
    borderColor: COLORS.FORM_SUCCESS,
  },
  separator: {
    height: 5,
  },
  cardStyle: {
    elevation: 3,
    width: '100%',
    flex: 1,
  },
  cardBody: {
    width: '100%',
    height: 220,
    flex: 1,
  },
  cardImg: {
    alignSelf: 'center',
    width: '100%',
    height: 200,
  },
  alignCenter: {
    alignSelf: 'center',
  },
  alignRight: {
    alignSelf: 'flex-end',
  },
  txtHeader: {
    fontSize: STYLES.FONT_SIZE_ORDERS_HEADER_ROW2,
    color: COLORS.TEXT_ACTIVE,
    fontWeight: 'bold',
  },
  txtNormal: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_ACTIVE,
  },
  txtMultiple: {
    paddingTop: 20,
    width: '100%',
    height: 100,
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_ACTIVE,
  },
  txtMultipleHL: {
    marginTop: 7,
    width: '100%',
    height: 100,
    fontSize: STYLES.FONT_SIZE_NORMAL,
    backgroundColor: COLORS.FORM_PRIMARY_BG,
    color: COLORS.FORM_PRIMARY,
  },
  txtLabel: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_INACTIVE,
    // color: 'rgba(15,15,15,0.6)',
  },
  txtHLRight: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_PRIMARY,
    backgroundColor: COLORS.FORM_PRIMARY_BG,
    textAlign: 'right',
  },
  labelRight: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_INACTIVE,
    textAlign: 'right',
  },
  txtRight: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_ACTIVE,
    textAlign: 'right',
  },
  iconHL: {
    fontSize: STYLES.FONT_SIZE_NORMAL + 4,
    color: COLORS.TEXT_PRIMARY,
    textAlign: 'right',
  },
  rowBox: {
    borderWidth: 1,
    borderColor: 'lightgrey',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 10,
    paddingBottom: 10,
  },
  colBox50: {
    width: '50%',
    borderWidth: 1,
    borderColor: 'lightgrey',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingTop: 10,
    paddingLeft: 5,
    paddingRight: 5,
    paddingBottom: 10,
  },
  camContainer: {
    width: 250,
    height: 200,
    paddingTop: 5,
  },
  preview: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'red',
  },
  capture: {
    flex: 0,
    backgroundColor: '#fff',
    borderRadius: 5,
    color: '#000',
    padding: 10,
    margin: 40,
  },
  barcodeCutLine1: {
    position: 'absolute',
    top: 96,
    width: '100%',
    height: 2,
    backgroundColor: COLORS.FORM_ERROR_OPACITY,
  },
  barcodeCutLine2: {
    position: 'absolute',
    top: 104,
    width: '100%',
    height: 2,
    backgroundColor: COLORS.FORM_ERROR_OPACITY,
  },
  qsCameraBox: {
    // height: 0.30 * CONS.SCREEN_H,
    // width: 0.8 * CONS.SCREEN_W,
    height: 0.3 * Dimensions.get('screen').height,
    width: 0.8 * Dimensions.get('screen').width,
    // height: 170,
    // width: 240,
  },
  jumbotronText: {
    fontSize: STYLES.FONT_SIZE_JUMBOTRON,
    minWidth: STYLES.FONT_SIZE_JUMBOTRON,
    textAlign: 'center',
  },
  webResetBtn: {
    ...Platform.select({
      web: {
        marginRight: 1,
        backgroundColor: 'white',
        borderTopWidth: 1,
        borderLeftWidth: 1,
        borderBottomWidth: 1,
        borderRightWidth: 1,
        borderTopColor: COLORS.TEXT_INACTIVE,
        borderLeftColor: COLORS.TEXT_INACTIVE,
        borderBottomColor: COLORS.TEXT_INACTIVE,
        borderRightColor: COLORS.TEXT_INACTIVE,
      },
    }),
  },
  webShipBtn: {
    ...Platform.select({
      web: {
        marginLeft: 1,
        backgroundColor: COLORS.APP_MAIN,
        borderTopWidth: 1,
        borderLeftWidth: 1,
        borderBottomWidth: 1,
        borderRightWidth: 1,
        borderTopColor: COLORS.APP_MAIN,
        borderLeftColor: COLORS.APP_MAIN,
        borderBottomColor: COLORS.APP_MAIN,
        borderRightColor: COLORS.APP_MAIN,
      },
    }),
  },
})
