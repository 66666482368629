import { Map, List } from 'immutable'
import { ISelectedStoreMap } from '../index'
// Stores -> My
export const getMyStores = (state) => state.getIn(['stores', 'my', 'stores'])
export const getMyStoresShouldFetch = (state) => state.getIn(['stores', 'my', 'shouldFetch'])
export const getMyStoresIsFetching = (state) => state.getIn(['stores', 'my', 'isFetching'])

export const getMyStoresDetail = (state) => state.getIn(['stores', 'myStoresDetail'])
export const setMyStoresInStores = (storesState, data) => storesState.setIn(['my', 'stores'], data)
export const setMyStoresShouldFetchInStores = (storesState, data) => storesState.setIn(['my', 'shouldFetch'], data)
export const setMyStoresIsFetchingInStores = (storesState, data) => storesState.setIn(['my', 'isFetching'], data)

// Stores -> Seller
export const getSellerStores = (state) => state.getIn(['stores', 'seller', 'stores'])
export const getMyPendingStores = (state) => state.getIn(['stores', 'pending', 'stores'])
export const getSellerStoresShouldFetch = (state) => state.getIn(['stores', 'seller', 'shouldFetch'])
export const getSellerStoresIsFetching = (state) => state.getIn(['stores', 'seller', 'isFetching'])

export const setSellerStoresInStores = (storesState, data) => storesState.setIn(['seller', 'stores'], data)
export const setSellerStoresShouldFetch = (state, data) => state.setIn(['stores', 'seller', 'shouldFetch'], data)
export const setSellerStoresShouldFetchInStores = (storesState, data) => storesState.setIn(['seller', 'shouldFetch'], data)
export const setSellerStoresIsFetchingInStores = (storesState, data) => storesState.setIn(['seller', 'isFetching'], data)

export const setPendingStoresInStores = (storesState, data) => storesState.setIn(['pending', 'stores'], data)
export const getPendingStoresInStores = (storesState) => storesState.getIn(['pending', 'stores'])

// Stores -> selectedStore / editingStore
export const getStoreSelected = (state) => state.getIn(['stores', 'selectedStore'])

export const getShouldFetchOrderSummary = (state) => state.getIn(['stores', 'shouldFetchOrderSummary'])
export const setShouldFetchOrderSummaryInStores = (state: Map<string, any>, value: boolean): Map<string, any> =>
  state.set('shouldFetchOrderSummary', value)

export const getShouldFetchSelectedStore = (state) => state.getIn(['stores', 'shouldFetchSelectedStore'])
export const setShouldFetchSelectedStoreInStores = (state: Map<string, any>, value: boolean): Map<string, any> =>
  state.set('shouldFetchSelectedStore', value)

export const getShouldFetchUserGroups = (state) => state.getIn(['stores', 'shouldFetchUserGroups'])
export const setShouldFetchUserGroupsInStores = (state: Map<string, any>, value: boolean): Map<string, any> =>
  state.set('shouldFetchUserGroups', value)

export const getProductGroupsInStores = (state) => state.getIn(['selectedStore', 'product_groups'])
export const setProductGroupsInStores = (state: Map<string, any>, value: boolean): Map<string, any> =>
  state.setIn(['selectedStore', 'product_groups'], value)

export const getWarehousesInStores = (state) => state.getIn(['selectedStore', 'warehouses'])
export const setWarehousesInStores = (state: Map<string, any>, value: boolean): Map<string, any> =>
  state.setIn(['selectedStore', 'warehouses'], value)

export const getSelectedStoreProductGroups = (state) => state.getIn(['stores', 'selectedStore', 'product_groups'])
export const getSelectedStoreWarehouses = (state) => state.getIn(['stores', 'selectedStore', 'warehouses'])

export const getUserGroupsInStores = (state) => state.getIn(['selectedStore', 'user_groups'])
export const setUserGroupsInStores = (state: Map<string, any>, value: boolean): Map<string, any> =>
  state.setIn(['selectedStore', 'user_groups'], value)

export const getActiveRouteName = (state) => state.getIn(['session', 'activeRouteName'])
export const getSessionIsReady = (state) => state.getIn(['session', 'isReady'])

export const getSelectedStoreOrdersDates = (state) => state.getIn(['stores', 'selectedStoreOrdersDates'])
export const getSelectedStoreOrdersDatesOptionKey = (state) => state.getIn(['stores', 'selectedStoreOrdersDatesOptionKey'])

export const getSelectedStore = (state) => state.getIn(['stores', 'selectedStore'])
export const getSelectedStoreId = (state) => state.getIn(['stores', 'selectedStore', 'id'])
export const getSelectedStoreUpdatedAt = (state) => state.getIn(['stores', 'selectedStore', 'updated_at'])
export const getSelectedStoreInStores = (state) => state.get('selectedStore')
export const setSelectedStoreInStores = (state, newStore) => state.set('selectedStore', newStore)
export const getSelectedStoreName = (state) => state.getIn(['stores', 'selectedStore', 'name'])
export const getSelectedStoreRoleId = (state) => state.getIn(['stores', 'selectedStore', 'role_id'])
export const getSelectedStoreHelperPermissions = (state) => state.getIn(['stores', 'selectedStore', 'store_helper_permissions'])
export const getEditingStore = (state) => state.getIn(['stores', 'editingStore'])
export const setEditingStore = (state, value) => state.setIn(['stores', 'editingStore'], value)
export const getEditingStoreInStores = (state) => state.get('editingStore')
export const setEditingStoreInStores = (state, value) => state.set('editingStore', value)
export const getSelectedProductGroups = (state) => state.getIn(['stores', 'selectedStore', 'product_groups'])
export const getProductGroupsUpdatedAt = (state) => state.getIn(['stores', 'selectedStore', 'pgs_updated_at'])
export const setProductGroupsUpdatedAtInStores = (state, newValue) => state.setIn(['selectedStore', 'pgs_updated_at'], newValue)
export const setOrderSummaryToEdittingStore = (state, value) => state.setIn(['editingStore', 'order_summary'], value)
export const setOrderSummaryToSelectedStore = (state, value) => state.setIn(['selectedStore', 'order_summary'], value)
export const getSelectedStoreErpChannels = (state) => state.getIn(['stores', 'selectedStore', 'erp_channels'])

export const getLocalConfigsInStores = (state: Map<string, any>) => state.getIn(['selectedStore', 'localConfigs']) || Map({})
export const setLocalConfigsInStores = (state: Map<string, any>, newValue: Map<string, any>) =>
  state.setIn(['selectedStore', 'localConfigs'], newValue)
export const getLocalConfigsInSelectedStore = (selectedStore: ISelectedStoreMap, key: string): any =>
  selectedStore.getIn(['localConfigs', key])

export const getSelectedUserGroups = (state) => state.getIn(['stores', 'selectedStore', 'user_groups'])

export const getStoreVolumeDiscounts = (state) => state.getIn(['stores', 'selectedStore', 'vds'])

// Stores -> selectedStore / editingStore -> channels (MKP channels)
export const getMkpChannels = (state) => state.getIn(['stores', 'selectedStore', 'channels']) || List([])

// Contacts
export const getContactList = (state) => state.getIn(['contacts', 'contactList'])
export const getContactListGroup = (state) => state.getIn(['contacts', 'contactListGroup'])
export const getContactEditing = (state) => state.getIn(['contacts', 'editingContact'])

// Address
export const getAddressEditing = (state) => state.getIn(['address', 'editingAddress'])
export const selectedAddresses = (state) => state.getIn(['address', 'selectedAddresses'])
export const shouldFetchList = (state) => state.getIn(['address', 'shouldFetchList'])

// O: เคยประกาศแล้วเป็น getSelectedStore
// export const selectedStore = state => state.getIn(['stores', 'selectedStore'])

// Orders
export const getOrdersInBaseOrders = (state) => state.get('orders')
export const getOrder = (state) => state.get('order')

// ========== BEGIN orders action for store ==========
export const getStoreOrders = (state) => state.getIn(['storeOrders', 'orders'])
// export const getStoreQueriedOrders = (state) => state.getIn(['storeOrders', 'queriedOrders'])
// export const setStoreQueriedOrdersInOrders = (orderState, data) => orderState.setIn(['queriedOrders'], data)
export const getStoreOrdersFilterSetting = (state) => state.getIn(['storeOrders', 'filterSetting'])

export const getStoreOrdersInOrdersForTabName = (orderState, tabName) => orderState.getIn(['orders', tabName])

export const setStoreOrdersInOrders = (ordersState, data) => ordersState.setIn(['orders'], data)
export const setStoreOrdersInOrdersForTabName = (ordersState, tabName, data) => ordersState.setIn(['orders', tabName], data)

export const getStoreOrdersTabStatus = (state) => state.getIn(['storeOrders', 'tabStatus'])
export const setStoreOrdersTabStatusInOrders = (ordersState, val) => ordersState.setIn(['tabStatus'], val)
export const setStoreOrdersTabStatusInOrdersForKey = (ordersState, name, val) => ordersState.setIn(['tabStatus', name], val)

export const getStoreOrdersFetchStatus = (state) => state.getIn(['storeOrders', 'fetchStatus'])
export const getStoreOrdersFetchStatusInOrders = (ordersState) => ordersState.getIn(['fetchStatus'])
export const getStoreOrdersFetchStatusForTabName = (state, tabName) => state.getIn(['storeOrders', 'fetchStatus', tabName])
export const getStoreOrdersFetchStatusForTabNameInOrders = (state, tabName) => state.getIn(['fetchStatus', tabName])

export const setStoreOrderFetchStatusInOrdersForTab = (ordersState, tabName, val) => ordersState.setIn(['fetchStatus', tabName], val)
export const setStoreOrdersLoadingInOrdersForTab = (ordersState, tabName, boolVal) =>
  ordersState.setIn(['fetchStatus', tabName, 'loading'], boolVal)
export const getStoreOrdersSummary = (state) => state.getIn(['storeOrders', 'summary'])
export const getStoreOrdersSummaryInOrders = (ordersState) => ordersState.getIn(['summary'])
export const setStoreOrdersSummaryInOrders = (ordersState, data) => ordersState.setIn(['summary'], data)

export const getStoreOrdersisCheckOrderIdsMapByKey = (state) => state.getIn(['storeOrders', 'isCheckOrderIdsMapByKey'])
export const getStoreOrdersisCheckOrderIdsMapByKeyInOrders = (ordersState) => ordersState.getIn(['isCheckOrderIdsMapByKey'])

export const getStoreOrdersShouldFetchAll = (state) => state.getIn(['storeOrders', 'shouldFetchAll']) // เรียกใช้ shouldFetchAll ของ storeOrders

// ========== BEGIN orders action for Search ==========
// export const getSearchOrdersOrders = (state) => {console.log('console.log:getSearchOrdersOrders:2', state.getIn(['searchOrders', 'orders']));  state.getIn(['searchOrders', 'orders']) }
export const getSearchOrders = (state) => state.getIn(['searchOrders'])
export const getSearchOrdersOrders = (state) => state.getIn(['searchOrders', 'orders'])
export const getSearchOrdersLastSearchRequest = (state) => state.getIn(['searchOrders', 'lastSearchRequest'])
export const getSearchOrdersOrdersInSearchOrders = (state) => state.get('orders')
export const setSearchOrdersOrdersInSearchOrders = (searchOrdersState, val) => searchOrdersState.set('orders', val)
export const getSearchOrdersFetchStatus = (state) => state.getIn(['searchOrders', 'fetchStatus'])
export const getSearchOrdersFetchStatusInSearchOrders = (searchOrdersState) => searchOrdersState.getIn(['fetchStatus'])
export const setSearchOrdersFetchStatusInSearchOrders = (searchOrdersState, val) => searchOrdersState.set('fetchStatus', val)
export const setSearchOrdersFetchStatusLoadingInSearchOrders = (searchOrdersState, val) =>
  searchOrdersState.setIn(['fetchStatus', 'loading'], val)
export const getSearchOrdersLastSearchRequestInSearchOrders = (searchOrdersState) => searchOrdersState.getIn(['lastSearchRequest'])
export const setSearchOrdersLastSearchRequestInSearchOrders = (searchOrdersState, val) => searchOrdersState.set('lastSearchRequest', val)

// Product
export const getSelectedProduct = (state) => state.getIn(['products', 'selectedProduct'])
export const getEditingProduct = (state) => state.getIn(['products', 'editingProduct'])

// Products
export const getSelectedProductsInProducts = (state) => state.getIn(['selectedProducts'])
export const getSearchedProducts = (state) => state.getIn(['products', 'searchedProducts'])
export const getSearchedProductsInProducts = (state) => state.getIn(['searchedProducts'])
export const setSelectedProductsInProducts = (productsState, val) => productsState.set('selectedProducts', val)
export const setSearchedProductsInProducts = (productsState, val) => productsState.set('searchedProducts', val)
export const getSelectedProductsFetchStatus = (state) => state.getIn(['products', 'selectedProductsFetchStatus'])
export const getSearchedProductsFetchStatus = (state) => state.getIn(['products', 'searchedProductsFetchStatus'])
export const getSelectedProductsFetchStatusInProducts = (state) => state.getIn(['selectedProductsFetchStatus'])
export const getSearchedProductsFetchStatusInProducts = (state) => state.getIn(['searchedProductsFetchStatus'])
export const setSelectedProductsFetchStatusInProducts = (productsState, val) => productsState.set('selectedProductsFetchStatus', val)
export const setSearchedProductsFetchStatusInProducts = (productsState, val) => productsState.set('searchedProductsFetchStatus', val)

// Reports
export const getDailySalesInReports = (state) => state.get('dailySales')
export const getDailySalesReport = (state) => state.getIn(['reports', 'dailySales'])
export const setDailySalesInReports = (reportsState, val) => reportsState.set('dailySales', val)

// Server Info
export const getStoreOrdersBackendTimes = (state) => state.getIn(['servers', 'backend', 'times'])
export const setStoreOrdersBackendTimeInServers = (serversState, data) => serversState.setIn(['backend', 'times'], data)
// ========== END orders action for store ==========

// ========== BEGIN Counterpart of orders action for payment ==========
export const getPaymentOrders = (state) => state.getIn(['paymentOrders', 'orders'])
export const getPaymentOrdersInOrdersForTabName = (orderState, tabName) => orderState.getIn(['orders', tabName])
export const getPaymentOrdersFilterSetting = (state) => state.getIn(['paymentOrders', 'filterSetting'])

export const setPaymentOrdersInOrders = (ordersState, data) => ordersState.setIn(['orders'], data)
export const setPaymentOrdersInOrdersForTabName = (ordersState, tabName, data) => ordersState.setIn(['orders', tabName], data)

export const getPaymentOrdersTabStatus = (state) => state.getIn(['paymentOrders', 'tabStatus'])
export const setPaymentOrdersTabStatusInOrders = (ordersState, val) => ordersState.setIn(['tabStatus'], val)
export const setPaymentOrdersTabStatusInOrdersForKey = (ordersState, name, val) => ordersState.setIn(['tabStatus', name], val)

export const getPaymentOrdersFetchStatus = (state) => state.getIn(['paymentOrders', 'fetchStatus'])
export const getPaymentOrdersFetchStatusInOrders = (ordersState) => ordersState.getIn(['fetchStatus'])
export const getPaymentOrdersFetchStatusForTabName = (state, tabName) => state.getIn(['paymentOrders', 'fetchStatus', tabName])
export const getPaymentOrdersFetchStatusForTabNameInOrders = (state, tabName) => state.getIn(['fetchStatus', tabName])

export const setPaymentOrderFetchStatusInOrdersForTab = (ordersState, tabName, val) => ordersState.setIn(['fetchStatus', tabName], val)
export const setPaymentOrdersLoadingInOrdersForTab = (ordersState, tabName, boolVal) =>
  ordersState.setIn(['fetchStatus', tabName, 'loading'], boolVal)
export const getPaymentOrdersSummary = (state) => state.getIn(['paymentOrders', 'summary'])
export const getPaymentOrdersSummaryInOrders = (ordersState) => ordersState.getIn(['summary'])
export const setPaymentOrdersSummaryInOrders = (ordersState, data) => ordersState.setIn(['summary'], data)

// Server Info
export const getPaymentOrdersBackendTimes = (state) => state.getIn(['servers', 'backend', 'times'])
export const setPaymentOrdersBackendTimeInServers = (serversState, data) => serversState.setIn(['backend', 'times'], data)

// ========== END Counterpart of orders action for payment ==========

// export const getOrdersForPayment = (state) => state.getIn(['payment', 'editingPayment', 'ordersForPayment'])
// export const getOrdersForPaymentInPayment = (orderState) => orderState.getIn(['editingPayment', 'ordersForPayment'])
// export const setOrdersForPaymentInPayment = (ordersState, data) => ordersState.setIn(['editingPayment', 'ordersForPayment'], data)

export const getOrdersToBePaid = (state) => state.getIn(['payment', 'editingPayment', 'ordersToBePaid'])
export const getOrdersToBePaidInPayment = (paymentState) => paymentState.getIn(['editingPayment', 'ordersToBePaid'])
export const setOrdersToBePaidInPayment = (paymentState, data) => paymentState.setIn(['editingPayment', 'ordersToBePaid'], data)
export const deleteOrderToBePaidInPayment = (paymentState, toBeDeletedOrderId) =>
  paymentState.deleteIn(['editingPayment', 'ordersToBePaid', toBeDeletedOrderId])
export const setTotalAmountInPayment = (paymentState, data) => paymentState.setIn(['editingPayment', 'total_amount'], data)
export const setSellerPaymentAccountInPayment = (paymentState, data) =>
  paymentState.setIn(['editingPayment', 'seller_payment_account'], data)

export const getOrderStoreIdForPayment = (state) => getFocusedSelectedOrder(state).get('store_id')
export const getOrderPartnerStoreIdForPayment = (state) => getFocusedSelectedOrder(state).get('partner_store_id')

// Order View
export const getEditingOrder = (state) => state.getIn(['order', 'editingOrder'])
export const getEditingOrderIsDirty = (state) => state.getIn(['order', 'editingOrder', 'isDirty'])
export const getSelectedOrder = (state) => state.getIn(['order', 'selectedOrder'])
export const getFocusedSelectedOrder = (state) => {
  const selectedOrder = state.getIn(['order', 'selectedOrder']) || Map({})
  const orderTabIndex = state.getIn(['order', 'orderTabIndex']) || 0

  if (orderTabIndex === 0) {
    return selectedOrder
  }

  return state.getIn(['order', 'selectedOrder', 'suborders', orderTabIndex - 1])
}

// Reseller
// export const getResellerMembers = (state, key) => state.getIn(['reseller', key])['members']
// export const getResellerIsLoaded = (state, key) => state.getIn(['reseller', key])['isLoaded']
// export const getResellerShouldFetch = (state, key) => state.getIn(['reseller', key])['shouldFetch']

// export const getPendingUsersInGroup = (state) => state.getIn(['reseller', 'pending_users', 'users'])
// export const getIsFetchingPendingUsersInGroup = (state) => state.getIn(['reseller', 'pending_users', 'isFetching'])
// export const getIsAllFetchedPendingUsersInGroup = (state) => state.getIn(['reseller', 'pending_users', 'isAllFetched'])

export const getUsersInGroupByKey = (state, key) => state.getIn(['reseller', `${key}_users`, 'users'])
export const getIsFetchingUsersInGroupByKey = (state, key) => state.getIn(['reseller', `${key}_users`, 'isFetching'])
export const getIsAllFetchedUsersInGroupByKey = (state, key) => state.getIn(['reseller', `${key}_users`, 'isAllFetched'])
export const getCountOfUsersInGroupByKey = (state, key) => state.getIn(['reseller', `${key}_users`, 'count'])
export const getShouldFetchOfUsersInGroupByKey = (state, key) => state.getIn(['reseller', `${key}_users`, 'shouldFetch'])

export const getUsersInGroupInResellerByKey = (state, key) => state.getIn([`${key}_users`, 'users'])
export const setUsersInGroupInResellerByKey = (state, key, newValue) => state.setIn([`${key}_users`, 'users'], newValue)
export const setUserInGroupInResellerByKeyAndIndex = (state, key, index, newValue) =>
  state.setIn([`${key}_users`, 'users', index], newValue)
export const setIsFetchingUsersInGroupInResellerByKey = (state, key, newValue) => state.setIn([`${key}_users`, 'isFetching'], newValue)
export const setIsAllFetchedUsersInGroupInResellerByKey = (state, key, newValue) => state.setIn([`${key}_users`, 'isAllFetched'], newValue)
export const getCountOfUsersInGroupInResellerByKey = (state, key) => state.getIn([`${key}_users`, 'count'])
export const setCountOfUsersInGroupInResellerByKey = (state, key, newValue) => state.setIn([`${key}_users`, 'count'], newValue)
export const setShouldFetchOfUsersInGroupInResellerByKey = (state: Map<string, any>, key: string, value: boolean) =>
  state.setIn([`${key}_users`, 'count'], value)

// Shipment View
export const getEditingOrderShip = (state) => state.getIn(['shipment', 'editingOrderShip'])
export const getSelectedOrderShip = (state) => state.getIn(['shipment', 'selectedOrderShip'])

// Bank Account Add
export const getEditingAccount = (state) => state.getIn(['bank', 'editingAccount'])

// Store Payment Account
export const setStorePAInPaymentAccountsForKey = (paState, key, val) => paState.setIn(['stores', key], val)
// export const getStorePAForKey = (state, key) => state.getIn(['bank', 'stores', key])
export const getStorePaymentAccounts = (state) => state.getIn(['bank', 'stores'])

// Payment: payment.selectedPaymentList
export const getSelectedPaymentListInPayment = (pState) => pState.get('selectedPaymentList')
export const setSelectedPaymentListInPayment = (pState, val) => pState.set('selectedPaymentList', val)
export const setEditingPaymentInPayment = (pState, val) => pState.set('editingPayment', val)
export const setPaymentListReqPayloadInPayment = (pState, val) => pState.set('listReqPayload', val)
export const getPaymentListReqPayload = (state) => state.getIn(['payment', 'listReqPayload'])

// User Preferences (for the whole app)
export const getPrefFetchOrders = (state) => state.getIn(['preference', 'fetchOrders'])
// export const setPrefFetchOrdersInPref = (prefState, val) => prefState.setIn(['fetchOrders'], val)
// export const setPrefFetchOrdersInPrefForKey = (prefState, key, val) => prefState.setIn(['fetchOrders', key], val)
// export const getPrefFetchProducts = (state) => state.getIn(['preference', 'fetchProducts'])

// Category
export const getCategoryList = (state) => state.getIn(['category', 'category'])
// export const setUpdateCategoryList = (paState, store_id, category) => paState.setIn(['category', store_id], category)
// export const getCategoryList = state => console.log('staTe => ', state.toJS())

// Code push status info
export const getCodepushInfo = (state) => state.getIn(['about', 'codepush'])
export const getFCMToken = (state) => state.getIn(['about', 'fcmToken'])
export const setCodepushInfoForKey = (codepushState, name, val) => codepushState.setIn(['codepush', name], val)

// NavigatorState
export const getNavigatorCurrentIndex = (state) => state.getIn(['navigatorState', 'index'])
export const getNavigatorRoutes = (state) => state.getIn(['navigatorState', 'routes'])

// Subscription (ที่กำลังเลือกใช้ปัจจุบัน - สำหรับเจ้าของร้าน คือ mySubscription, สำหรับผู้ช่วย คือ store owner's subscription)
export const getSubscription = (state) => state.get('subscription')
// export const getSubscriptionIsActiveWarning = state => state.getIn(['subscription', 'isActiveWarning'])
// export const getSubscriptionWarning = state => state.getIn(['subscription', 'warning'])
// export const getSubscriptionBillingDate = state => state.getIn(['subscription', 'billingDate'])
// export const getSubscriptionStartBillingDate = state => state.getIn(['subscription', 'startBillingDate'])
// export const getSubscriptionEndBillingDate = state => state.getIn(['subscription', 'endBillingDate'])
// export const getSubscriptionOrderQuota = state => state.getIn(['subscription', 'orderQuota'])
// export const getSubscriptionOrderCount = state => state.getIn(['subscription', 'orderCount'])
// export const getSubscriptionPackages = state => state.getIn(['subscription', 'packages'])
export const getSubscriptionType = (state) => state.getIn(['subscription', 'type'])

export const getMySubscription = (state) => state.get('mySubscription') // สำหรับ subscription ส่วนตัว
export const getMySubscriptionType = (state) => state.getIn(['mySubscription', 'type'])
export const getMySubscriptionIsActiveWarning = (state) => state.getIn(['mySubscription', 'isActiveWarning'])
export const getMySubscriptionWarning = (state) => state.getIn(['mySubscription', 'warning'])

export const getMySubscriptionQuotaWarning = (state) => state.getIn(['mySubscription', 'warningQuota'])
export const getMySubscriptionIsActiveQuotaWarning = (state) => state.getIn(['mySubscription', 'isActiveQuotaWarning'])

// Profile
export const getMyProfile = (state) => state.get('profile')

// Custom Order
export const getEditingCustomOrderView = (state) => state.getIn(['preference', 'order', 'customOrderView', 'editingOrder'])
export const getSelectedCustomOrderView = (state) => state.getIn(['preference', 'order', 'customOrderView', 'selectedOrder'])
export const getEditingCustomCreateOrder = (state) => state.getIn(['preference', 'order', 'customCreateOrder', 'editingOrder'])
export const getSelectedCustomCreateOrder = (state) => state.getIn(['preference', 'order', 'customCreateOrder', 'selectedOrder'])

// Loading In Progress
export const getLoadingInProgress = (state) => state.get(`loadingInProgress`)

// Chat
export const getChatChannels = (state) => state.getIn(['chat', 'channels'])
export const getChatConversations = (state) => state.getIn(['chat', 'conversations'])
export const getChatMessages = (state) => state.getIn(['chat', 'messages'])
export const getChatSelectedChannelIndex = (state) => state.getIn(['chat', 'selectedChannelIndex'])
// mkp
// export const setWarningReAuthMKP = (state, data) => state.setIn(['marketplaceChannel', 'warningReAuthMKP'], data)
export const getWarningReAuthMKP = (state) => state.getIn(['marketplaceChannel', 'warningReAuthMKP'])

// xshipping
export const getXShippingConfigFromRedux = (state) => state.getIn(['xshipping', 'xshippingConfig'])
