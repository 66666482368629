/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-unused-class-component-methods */
import React from 'react'
import { Animated } from 'react-native'
import { delay } from 'x/utils/util'
import api from 'x/utils/api'

import CONS from 'x/config/constants'
import _ from 'lodash'
import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'
import {
  ActionApiParams,
  ISelectedStoreMap,
  IMKPChannelDetail,
  IBgSyncStatusManager,
  IOverlaySetupSyncOrderCallBack,
  IBackgroundJobListResponse,
  IProductGroup,
  IQuestionsAndAnswers,
  IShippingType,
  IAddress,
  // IBackgroungJob,
  IXScreenProps,
  ISelectedStore,
} from 'x/types'
import { isImmutable } from 'immutable'
// import { IBackgroungJob } from 'x/types'
import * as NavActions from 'x/utils/navigation'
import { canDoAtSelectedStore } from 'x/utils/acl'

const CREATE_MODE = `CREATE`
const EDIT_MODE = `EDIT`
const VIEW = `VIEW`

interface IBaseChannelViewProps extends IXScreenProps {
  getShopeeAuthUrl: (params: ActionApiParams) => void
  selectedStore: ISelectedStoreMap
  createUserGroup: (params: ActionApiParams) => void
  updateNewChannelList: (newChannelList: IMKPChannelDetail[]) => void
  updateChannel: (params: ActionApiParams) => void
  deleteChannel: (params: ActionApiParams) => void
  syncBackgroundJobs: (params: ActionApiParams) => void
  syncStoreBGTask: (params: ActionApiParams) => void
  getChannel: (params: ActionApiParams) => void
  syncShopInfo: (params: ActionApiParams) => void
  syncMKPProduct: (params: ActionApiParams) => void
  syncMKPOrder: (params: ActionApiParams) => void
  subscription: any
}

interface IHamburgerChannelMenuOptions {
  key: string
  label: string
  onPress: () => void | Promise<void>
  icon?: {
    name: string
    family?:
      | 'AntDesign'
      | 'Entypo'
      | 'EvilIcons'
      | 'Feather'
      | 'FontAwesome'
      | 'FontAwesome5'
      | 'Foundation'
      | 'Ionicons'
      | 'MaterialCommunityIcons'
      | 'MaterialIcons'
      | 'Octicons'
      | 'SimpleLineIcons'
      | 'Zocial'
  }
  perm?: string | string[]
  isValidation: () => boolean
  isDanger?: boolean
}

interface IBaseChannelViewState {
  // pickChannel: IChannelPick
  channalName: string
  channalEmail: string

  channalNameBeforeEdit: string
  isLoading: boolean
  loadingWidth: number
  loadingHeight: number

  channel: IMKPChannelDetail

  mode: `CREATE` | `EDIT` | `VIEW`
  isShowLinkToProductGroup: boolean
  // isGoToAuthMKP ไว้สำหรับไว้บอกว่าหลังจากแก้ไข pg เสร็จแล้วจะไป auth mkp ต่อเลยไหม
  isGoToAuthMKP: boolean
  // TODO :: isEditChannelName ตอนนี้สร้างมาเผื่อให้สามารถแก้ชื่อ channel ได้ อนาคตขั้นตอนการเปลี่ยนชื่อจะไปอิงกับ mode
  isEditChannelName: boolean

  syncLoading: {
    STORE: boolean
    PRODUCT: boolean
    ORDER: boolean
  }

  isBackGroungJobsRuning: boolean

  isOverlaySetupSyncOrderVisible: boolean

  isHamburgerMenuOpened: boolean
  hamburgerMenuOptions: IHamburgerChannelMenuOptions[]

  isForceCreateShipping: boolean

  channelWraning: {
    isShowWarning: boolean
    warningText: string
    warningBackground: string
  } | null

  isOpenProductGroupModal: boolean

  productGroups: IProductGroup[]
  productGroupLable: string[]
  productGroupSelectedIndex: number[]

  selectedSyncModeQtyIndex: number
  selectedAutoConfirmOrderIndex: number
  selectedSyncModeOrderIndex: number
  selectedAutoLoadShippingLable: number
  selectedCreateOrderAtStatus: number

  isOverlayOpenOverlaySyncProduct: boolean

  isCancelShippingWhenCancelOrder: boolean

  // mkpIdSelected ใช้ใน Create Mode
  mkpIdSelected: number

  // is_self_delivery = true คือ ไม่ใช้
  isSelfDelivery: boolean

  selectedShippingNotCodIndex: number
  selectedShippingCodIndex: number
  shippingTypeNotCodItems: IShippingType[]
  shippingTypeCodItems: IShippingType[]
  senderAddressForSelfDelivery: IAddress
  isSelfDeliveryUseXshipping: boolean
  isSelfDeliveryAutoConfirmTrackingNumber: boolean

  lineApiKey: string
}
interface IOptionsRadio {
  label: string
  value: number
  help?: IQuestionsAndAnswers[]
}

const { BACKGROUND_JOB_TYPES } = CONS

export default abstract class BaseChannelView extends React.Component<IBaseChannelViewProps, IBaseChannelViewState> {
  // abstract async handleSubmitForm(response: any): Promise<void>
  abstract goBackFromBase: () => void

  // abstract navToProductGroupListView: () => void

  // validationSchema: Yup.ObjectSchema<any>
  syncLoadingValue: {
    STORE: any
    PRODUCT: any
    ORDER: any
  }

  isStopSyncFunctionLoop: boolean

  optionsRadioSyncModeQty: IOptionsRadio[]

  optionsAutoConfirmOrder: IOptionsRadio[]

  optionsSyncModeOrder: IOptionsRadio[]

  optionsAutoLoadShippingLable: IOptionsRadio[]

  optionsCreateOrderAtStatus: IOptionsRadio[]

  MKP_IDS: number[]

  constructor(props) {
    super(props)
    this.state = {
      // pickChannel: { mkpId: 1, img: getShopeeLogo(30, 30), onPress: () => null },
      channalName: '',
      channalNameBeforeEdit: '',
      channalEmail: '',
      isLoading: false,
      loadingHeight: 0,
      loadingWidth: 0,
      mode: VIEW,
      channel: null,
      isShowLinkToProductGroup: false,
      isGoToAuthMKP: true,
      isEditChannelName: false,

      syncLoading: {
        STORE: false,
        PRODUCT: true,
        ORDER: true,
      },
      isBackGroungJobsRuning: false,
      isOverlaySetupSyncOrderVisible: false,
      isHamburgerMenuOpened: false,
      hamburgerMenuOptions: null,
      isForceCreateShipping: false,
      channelWraning: null,

      isOpenProductGroupModal: false,
      productGroups: null,
      productGroupLable: null,
      productGroupSelectedIndex: null,

      selectedSyncModeQtyIndex: 0,
      selectedAutoConfirmOrderIndex: 0,
      selectedSyncModeOrderIndex: 0,
      selectedAutoLoadShippingLable: 0,
      selectedCreateOrderAtStatus: 0,

      isOverlayOpenOverlaySyncProduct: false,
      isCancelShippingWhenCancelOrder: false,

      mkpIdSelected: 0,

      isSelfDelivery: false,
      selectedShippingNotCodIndex: -1,
      selectedShippingCodIndex: -1,
      shippingTypeNotCodItems: [],
      shippingTypeCodItems: [],
      senderAddressForSelfDelivery: null,
      isSelfDeliveryUseXshipping: true,
      isSelfDeliveryAutoConfirmTrackingNumber: true,
      lineApiKey: null,
    }
    this.syncLoadingValue = {
      ORDER: new Animated.Value(0),
      PRODUCT: new Animated.Value(0),
      STORE: new Animated.Value(0),
    }
    this.MKP_IDS = [1, 2, 3, 4, 5]
    this.isStopSyncFunctionLoop = false
    this.optionsRadioSyncModeQty = [
      {
        label: 'จาก XSelly ไปทับที่ช่องทางขาย',
        value: 0,
      },
      {
        label: 'ไม่อัพเดทระหว่างกัน',
        value: 1,
      },
    ]
    this.optionsAutoConfirmOrder = [
      {
        label: 'ไม่ยืนยัน',
        value: 0,
      },

      {
        label: 'ยืนยันอัตโนมัติด้วยรูปแบบ Pickup',
        value: 1,
      },
      {
        label: 'ยืนยันอัตโนมัติด้วยรูปแบบ Dropoff',
        value: 2,
      },
    ]
    this.optionsSyncModeOrder = [
      {
        label: 'ซิงค์เข้า XSelly',
        value: 0,
      },
      {
        label: 'ไม่ซิงค์เข้า XSelly',
        value: 1,
      },
    ]
    this.optionsAutoLoadShippingLable = [
      {
        label: 'โหลดอัตโนมัติ',
        value: 0,
      },
      {
        label: 'ไม่โหลด',
        value: 1,
      },
    ]
    this.optionsCreateOrderAtStatus = [
      {
        label: 'ก่อนชำระ',
        value: 0,
      },
      {
        label: 'หลังชำระ',
        value: 1,
      },
    ]
  }

  async componentDidMount() {
    // const { navigation } = this.props
    // const { state } = navigation
    // const { params } = state
    const params = util.getNavParams(this.props)
    // console.log(`params `, params)
    const mode = _.isNil(params.mode) ? CREATE_MODE : params.mode
    // NOW VIEW HAS VIEW MODE ONLY
    if (mode === CREATE_MODE) {
      await this._setCreateMode()
    } else if (mode === EDIT_MODE) {
      // DONT HAVE NOW
    } else {
      // this.syncStoreBGTasks()
      // this.syncShopInfoFunction()
      const { channel } = params
      // console.log('channel => ', channel)
      await util.setStatePromise(this, {
        channel,
        channalName: channel.name,
        channelWraning: util.getMKPChannelWraning(channel),
      })

      await this.getIsForceCreateShippingFromChannel()
      await this._configHamburgerMenuItems()
      await this._setMkpSetting(channel)
      if (this._isActivaMKPChannel()) {
        await this.syncMKPBacjgroundJobs()
      }
    }

    await this._setProductGroups()
  }

  componentWillUnmount() {
    this.isStopSyncFunctionLoop = true
  }

  _setMkpSetting = async (channel: IMKPChannelDetail) => {
    // console.log('channel => ', channel)
    await this._setIsSelfDelivery(channel)
    await this._setIsSelfDeliveryAutoConfirmTrackingNumber(channel)
    await this._setIsSelfDeliveryUseXshipping(channel)
    await this._setShippingTypeNotCodItems(channel)
    await this._setShippingTypeCodItems(channel)
    await this._setAutoConfirmOrder(channel)
    await this._setSyncModeQty(channel)
    await this._setSyncModeOrder(channel)
    await this._setAutoLoadShippingLable(channel)
    await this._setCreateOrderStatus(channel)
    await this._setCancelShippingWhenCancelOrder(channel)

    await this._setSenderAddressForSelfDelivery(channel)
  }

  _setIsSelfDelivery = async (channel: IMKPChannelDetail) => {
    const isSelfDelivery = _.has(channel, 'is_self_delivery') && !_.isNil(channel.is_self_delivery) ? channel.is_self_delivery : false
    await util.setStatePromise(this, {
      isSelfDelivery,
    })
  }

  _setIsSelfDeliveryAutoConfirmTrackingNumber = async (channel: IMKPChannelDetail) => {
    const isSelfDeliveryAutoConfirmTrackingNumber =
      _.has(channel, 'self_delivery_auto_confirm_tracking_number') && !_.isNil(channel.self_delivery_auto_confirm_tracking_number)
        ? channel.self_delivery_auto_confirm_tracking_number
        : false
    await util.setStatePromise(this, {
      isSelfDeliveryAutoConfirmTrackingNumber,
    })
  }

  _setIsSelfDeliveryUseXshipping = async (channel: IMKPChannelDetail) => {
    const isSelfDeliveryUseXshipping =
      _.has(channel, 'self_delivery_use_xshipping') && !_.isNil(channel.self_delivery_use_xshipping)
        ? channel.self_delivery_use_xshipping
        : false
    await util.setStatePromise(this, {
      isSelfDeliveryUseXshipping,
    })
  }

  _setSenderAddressForSelfDelivery = async (channel: IMKPChannelDetail) => {
    // const addresses = isImmutable(this.props.selectedStore.get('addresses'))
    //   ? this.props.selectedStore.get('addresses').toJS()
    //   : this.props.selectedStore.get('addresses')
    const apiOptions = {
      showSpinner: true,
    }
    const reqBody = { store_id: this.props.selectedStore.get('id'), skip_count: true }
    const res: {
      store: ISelectedStore
    } = await api.post(api.POST_STORE, reqBody, apiOptions)
    const addresses = res.store ? res.store.addresses : []
    // console.log('addresses => ', addresses)
    if (!_.has(channel, 'self_delivery_sender_address_id') || _.isNil(channel.self_delivery_sender_address_id)) {
      await util.setStatePromise(this, {
        senderAddressForSelfDelivery: null,
      })
    } else {
      let senderAddressForSelfDelivery = null
      // @ts-ignore
      addresses.forEach((address: IAddress) => {
        if (channel.self_delivery_sender_address_id === address.id) {
          senderAddressForSelfDelivery = address
        }
      })
      await util.setStatePromise(this, {
        senderAddressForSelfDelivery,
      })
    }
  }

  _setShippingTypeNotCodItems = async (channel: IMKPChannelDetail) => {
    const shippingTypeNotCodItems = []
    shippingTypeNotCodItems.push({ index: -1, label: 'ไม่ระบุ' })
    CONS.ORDER_VIEW_SHIPPING_TYPE_ITEMS.forEach((shippingData) => {
      // console.log('shippingData => ', shippingData)
      if (!_.includes(CONS.COD_SHIPPING_TYPE_IDS, shippingData.value)) {
        shippingTypeNotCodItems.push({ ...shippingData, label: util.getShippingTypeNameById(shippingData.value) })
      }
      // const shipping = _.find(CONS.COD_SHIPPING_TYPE_IDS, (id) => id !== shippingData.value)
      // console.log('shipping => ', shipping)
      // if (!_.isNil(shipping)) {
      //   shippingTypeNotCodItems.push({ ...shippingData, label: util.getShippingTypeNameById(shippingData.value) })
      // }
    })

    const selfDeliveryDefaultShippingTypeId =
      _.has(channel, 'self_delivery_default_shipping_type_id') && !_.isNil(channel.self_delivery_default_shipping_type_id)
        ? channel.self_delivery_default_shipping_type_id
        : null

    let shipping = -1
    if (!_.isNil(selfDeliveryDefaultShippingTypeId)) {
      shippingTypeNotCodItems.forEach((sp, idx) => {
        if (selfDeliveryDefaultShippingTypeId === sp.value) {
          shipping = idx
        }
      })
    }

    await util.setStatePromise(this, {
      shippingTypeNotCodItems,
      selectedShippingNotCodIndex: shipping,
    })
  }

  _setShippingTypeCodItems = async (channel: IMKPChannelDetail) => {
    const shippingTypeCodItems = []
    shippingTypeCodItems.push({ index: -1, label: 'ไม่ระบุ' })
    CONS.ORDER_VIEW_SHIPPING_TYPE_ITEMS.forEach((shippingData) => {
      if (_.includes(CONS.COD_SHIPPING_TYPE_IDS, shippingData.value)) {
        shippingTypeCodItems.push({ ...shippingData, label: util.getShippingTypeNameById(shippingData.value) })
      }
    })

    const selfDeliveryDefaultShippingTypeId =
      _.has(channel, 'self_delivery_default_cod_shipping_type_id') && !_.isNil(channel.self_delivery_default_cod_shipping_type_id)
        ? channel.self_delivery_default_cod_shipping_type_id
        : null

    let shipping = -1
    if (!_.isNil(selfDeliveryDefaultShippingTypeId)) {
      shippingTypeCodItems.forEach((sp, idx) => {
        if (selfDeliveryDefaultShippingTypeId === sp.value) {
          shipping = idx
        }
      })
    }

    await util.setStatePromise(this, {
      shippingTypeCodItems,
      selectedShippingCodIndex: shipping,
    })
  }

  _setCancelShippingWhenCancelOrder = async (channel: IMKPChannelDetail) => {
    // console.log('channel => ', channel)
    const isCancelShippingWhenCancelOrder = channel.cancel_shipping_when_cancel_order
    // console.log('isCancelShippingWhenCancelOrder => / ', isCancelShippingWhenCancelOrder)
    await util.setStatePromise(this, {
      isCancelShippingWhenCancelOrder: _.isNil(isCancelShippingWhenCancelOrder) ? true : isCancelShippingWhenCancelOrder,
    })
  }

  _setSyncModeQty = async (channel: IMKPChannelDetail) => {
    if (_.has(channel, 'sync_mode_qty')) {
      const selectedSyncModeQtyIndex = channel.sync_mode_qty === 'XSELLY_TO_MKP' ? 0 : 1
      await util.setStatePromise(this, {
        selectedSyncModeQtyIndex,
      })
    }
  }

  _setAutoConfirmOrder = async (channel: IMKPChannelDetail) => {
    if (_.has(channel, 'auto_confirm_order')) {
      let selectedAutoConfirmOrderIndex = 0
      switch (channel.auto_confirm_order) {
        case 'NONE':
          selectedAutoConfirmOrderIndex = 0
          break
        case 'PICKUP':
          selectedAutoConfirmOrderIndex = 1
          break
        case 'DROPOFF':
          selectedAutoConfirmOrderIndex = 2
          break
        default:
          break
      }
      await util.setStatePromise(this, {
        selectedAutoConfirmOrderIndex,
      })
    }
  }

  _setSyncModeOrder = async (channel: IMKPChannelDetail) => {
    if (_.has(channel, 'sync_mode_order')) {
      const selectedSyncModeOrderIndex = channel.sync_mode_order === 'NORMAL' ? 0 : 1
      await util.setStatePromise(this, {
        selectedSyncModeOrderIndex,
      })
    }
  }

  _setAutoLoadShippingLable = async (channel: IMKPChannelDetail) => {
    if (_.has(channel, 'auto_load_shipping_label')) {
      const selectedAutoLoadShippingLable = channel.auto_load_shipping_label ? 0 : 1
      await util.setStatePromise(this, {
        selectedAutoLoadShippingLable,
      })
    }
  }

  _setCreateOrderStatus = async (channel: IMKPChannelDetail) => {
    if (_.has(channel, 'create_order_at_status')) {
      const selectedCreateOrderAtStatus = channel.create_order_at_status === 'UNPAID' ? 0 : 1
      await util.setStatePromise(this, {
        selectedCreateOrderAtStatus,
      })
    }
  }

  _setProductGroups = async () => {
    const { selectedStore } = this.props
    const { mode, channel } = this.state
    // @ts-ignore
    const productGroups: IProductGroup[] = isImmutable(selectedStore.get('product_groups'))
      ? selectedStore.get('product_groups').toJS()
      : selectedStore.get('product_groups')

    const productGroupLable = []
    const productGroupSelectedIndex = []
    productGroups.forEach((pg: IProductGroup, index: number) => {
      productGroupLable.push(pg.name)
      if (mode !== 'CREATE' && _.includes(channel.pg_ids, pg.id)) {
        productGroupSelectedIndex.push(index)
      }
    })
    // console.log('productGroups => ', productGroups)
    // console.log('channel => ', channel)
    await util.setStatePromise(this, {
      productGroups,
      productGroupLable,
      productGroupSelectedIndex,
    })
  }

  getIsForceCreateShippingFromChannel = async () => {
    const { channel } = this.state
    await util.setStatePromise(this, {
      isForceCreateShipping: channel.allow_create_shipping_if_qty_not_enough,
    })
  }

  _configHamburgerMenuItems = async () => {
    const { channel } = this.state
    if (_.isNil(channel)) {
      return
    }
    const menu = []
    const unAuthMKP: IHamburgerChannelMenuOptions = {
      key: 'un_auth_mkp',
      label: 'ยกเลิกการเชื่อมต่อช่องทางขาย',
      onPress: () => this.unAuthMKP(),
      icon: { name: 'disconnect', family: 'AntDesign' },
      isDanger: true,
      isValidation: () => true,
    }
    // const syncProduct: IHamburgerChannelMenuOptions = {
    //   key: 'sync_product',
    //   label: 'ซิงค์ข้อมูลสินค้า (ทั้งหมด)',
    //   onPress: () => this._syncAllProductMKP(),
    //   icon: { name: 'sync', family: 'MaterialCommunityIcons' },
    //   isValidation: () => true,
    // }
    // menu.push(syncProduct)
    let reauthMenuName = 'เชื่อมต่อช่องทางขาย'
    const TIME_LEFT = util.remainingDay(channel.expire_time)
    if (TIME_LEFT > 0) {
      reauthMenuName = 'ต่ออายุการใช้งาน'
    }
    const rnAuthMKP: IHamburgerChannelMenuOptions = {
      key: 're_auth_mkp',
      label: reauthMenuName,
      onPress: () => this._onPressReAuthBtn(channel),
      icon: { name: 'link', family: 'AntDesign' },
      // isDanger: true,
      isValidation: () => true,
    }
    // const canReauth = util.isMkpchannelCanReauth(channel)
    // แค่ LAZADA เท่านั้นที่สามารถ Reauth ตรงนี้ได้
    if (channel.mkp_id === 2) {
      menu.push(rnAuthMKP)
    }
    menu.push(unAuthMKP)
    await util.setStatePromise(this, {
      hamburgerMenuOptions: menu,
    })
  }

  // eslint-disable-next-line class-methods-use-this
  unAuthTiktokShop = () => {
    p.op.showConfirmationOkOnly(p.op.t('mkp_un_auth.title'), p.op.t('mkp_un_auth.tiktok'), null, 'ปิด')
  }

  _onPressReAuthBtn = async (channel: IMKPChannelDetail) => {
    const { mkp_id } = channel
    if (mkp_id !== 2 && mkp_id !== 4) {
      // this._callAuthChannel(channel)
      return
    }
    // IF Lazada MKP
    if (mkp_id === 2) {
      // p.op.showConfirmationOkOnly('', 'กรุณาติดต่อทีมงาน')
      this._navToReauthLazada(channel.id)
      return
    }
    // IF LINE MKP
    if (mkp_id === 4) {
      // await util.setStatePromise(this, { selectedChannel: channel })
      // this._showOverlayInputApiKey()
    }
  }

  _callBackFromLazadaReauth = (channel: IMKPChannelDetail) => {
    const { selectedStore, updateNewChannelList } = this.props
    const params = util.getNavParams(this.props)

    const newChannel = channel
    const channels = isImmutable(selectedStore.get(`channels`)) ? selectedStore.get(`channels`).toJS() : selectedStore.get(`channels`)
    const newChannels = []
    // @ts-ignore
    channels.forEach((ch: IMKPChannelDetail) => {
      if (ch.id === channel.id) {
        newChannels.push(newChannel)
      } else {
        newChannels.push(ch)
      }
    })
    this.setState({ channel: newChannel, isEditChannelName: false, channalNameBeforeEdit: newChannel.name })
    this._setMkpSetting(newChannel)
    // updateNewChannelList => update redex
    updateNewChannelList(newChannels)
    // update channel list view
    if (params && params.callBackUpdateChannelList && _.isFunction(params.callBackUpdateChannelList)) {
      params.callBackUpdateChannelList(newChannels)
    }
  }

  _navToReauthLazada = (mkp_ch_id: number) => {
    const { navigation, selectedStore } = this.props
    navigation.dispatch(
      NavActions.navToReauthLazadaView({
        store_id: selectedStore.get('id'),
        mkp_ch_id,
        // callBack: (channel: IMKPChannelDetail) => this._callBackUpdateChannel(channel),
        callBackFromLazadaReauth: (channel: IMKPChannelDetail) => this._callBackFromLazadaReauth(channel),
      })
    )
  }

  unAuthMKP = async () => {
    const { selectedStore, navigation } = this.props
    const channel = this.getChannelParms()
    if (_.isNil(channel)) {
      return
    }

    if (!canDoAtSelectedStore(CONS.PERM_STORE_HELPER.MKP_DELETE)) {
      p.op.alert('คุณไม่มีสิทธิ์ในการลบช่องทางขาย')
      return
    }

    // IF TIKTOK
    if (channel.mkp_id === 3) {
      this.unAuthTiktokShop()
      return
    }
    const mkpName = util.getMKPName(channel.mkp_id)
    const isConfirmUnAuth = await p.op.isUserConfirm(
      p.op.t('mkp_un_auth.title'),
      `เมื่อยกเลิกการเชื่อมต่อแล้ว ข้อมูลออเดอร์และสต๊อกก็จะไม่ซิงค์กับ ${mkpName} อีก และคุณจะสามารถดำเนินการ*ลบช่องทางขาย*นี้ หรือจะกลับมา*เชื่อมต่ออีกครั้ง*ก็ได้\n\nคุณต้องการดำเนินการต่อหรือไม่`,
      'ดำเนินการยกเลิก',
      'ปิด'
    )
    if (!isConfirmUnAuth) {
      return
    }
    let msComfirmUnAuth = `กรุณาล็อกอินในหน้าถัดไป เพื่อยืนยันการยกเลิกการเชื่อมต่อกับ ${mkpName}`
    // IF LINE
    if (channel.mkp_id === 4) {
      msComfirmUnAuth = `กรุณายืนยันการยกเลิกการเชื่อมต่อกับ LINE SHOPPING`
    }

    let isConfirmUnAuthMore = true
    // if lazada bypass
    if (channel.mkp_id !== 2) {
      isConfirmUnAuthMore = await p.op.isUserConfirm(
        'ยืนยันการยกเลิกการเชื่อมต่อ',
        `${msComfirmUnAuth}`,
        `ยืนยันการยกเลิกเชื่อมต่อกับ ${mkpName}`,
        'ปิด'
      )
    }

    if (!isConfirmUnAuthMore) {
      return
    }
    const body: { [key: string]: any } = {
      store_id: selectedStore.get('id'),
      mkp_ch_id: channel.id,
      // mkp_id: 1,
    }

    let POST_API = api.POST_UN_AUTH_MKP
    // IF LINE
    if (channel.mkp_id === 4) {
      POST_API = api.POST_UN_AUTH_LINE
    }
    // IF SHOPIFY
    if (channel.mkp_id === 5) {
      POST_API = api.POST_UN_AUTH_SHOPIFY_MKP
    }
    // console.log('BODY => UN AUTH ', body)
    const response = await api.postV2(POST_API, body)
    // console.log('response cancel => ', response)
    const params = util.getNavParams(this.props)
    if (response.auth_url) {
      // const { state } = navigation
      // const { params } = state
      if (params && params.callBackURL && _.isFunction(params.callBackURL)) {
        // if (p.op.isWeb()) {
        //   p.op.showConfirmation(
        //     '',
        //     'หากทำการยกเลิกการเชื่อมต่อช่องทางขายเสร็จสิ้นแล้ว กดปุ่ม "โหลดข้อมูลใหม่" เพื่ออัพเดตข้อมูลใหม่',
        //     () => window.location.reload(),
        //     () => null,
        //     'โหลดข้อมูลใหม่',
        //     'ปิด'
        //   )
        // }
        params.callBackURL(response.auth_url, this.state.channel)
        if (!p.op.isWeb()) {
          this.goBackFromBase()
        }
      }
    }
    // IF LINE
    if (response.channels) {
      if (params && params.callBackUpdateChannelList && _.isFunction(params.callBackUpdateChannelList)) {
        params.callBackUpdateChannelList(response.channels)
      }
      this.goBackFromBase()
    }
  }

  _syncAllProductMKP = async () => {
    const { selectedStore } = this.props
    const channel = this.getChannelParms()
    if (_.isNil(channel)) {
      return
    }
    if (!channel.is_active) {
      this._warningChannelNotActive()
      return
    }
    const mkpName = util.getMKPName(channel.mkp_id)
    const isConfirmForceSync = await p.op.isUserConfirm(
      'ซิงค์ข้อมูลสินค้า (ทั้งหมด)',
      `หากกดปุ่ม "ซิงค์ข้อมูลสินค้า" ระบบจะทำการซิงค์ข้อมูลสินค้าทั้งหมดบน ${mkpName} ใหม่อีกครั้ง`,
      `ซิงค์ข้อมูลสินค้า`,
      'ปิด'
    )
    if (!isConfirmForceSync) {
      return
    }

    const newSyncLoading = this.state.syncLoading
    newSyncLoading.PRODUCT = true
    await util.setStatePromise(this, {
      syncLoading: newSyncLoading,
    })

    const body: { [key: string]: any } = {
      store_id: selectedStore.get('id'),
      mkp_ch_id: channel.id,
      force_sync: true,
    }
    const response: { job_uuid: string } = await api.postV2(api.POST_SUNC_ALL_NEW_MKP_PRODUCT, body)
    // console.log('response => ', response)
    if (!_.isNil(response.job_uuid)) {
      await util.alertWithNavToBgJobListView({
        title: 'สร้าง "คิวคำสั่งงาน" สำเร็จ',
        message:
          'คิวคำสั่งงาน สำหรับการ "การซิงค์ข้อมูลสินค้าใหม่ทั้งหมด" ได้ถูกสร้างแล้ว ระบบกำลังดำเนินการอยู่เบื้องหลัง และอาจใช้เวลาซักครู่จนกว่าจะเสร็จสิ้น',
        dispatch: this.props.navigation.dispatch,
        selectedStoreId: this.props.selectedStore.get('id'),
        // onUserConfirm: () => this.goBackFromBase(),
        initBgJobUUID: response.job_uuid,
      })

      await this.syncMKPBacjgroundJobs()
    } else {
      p.op.showConfirmationOkOnly('', `เกิดข้อผิดพลาด ${response}`)
    }
  }

  getChannelParms = (): IMKPChannelDetail => {
    // const { navigation } = this.props
    // const { state } = navigation
    // const { params } = state
    const params = util.getNavParams(this.props)
    return params.channel
  }

  shouldStopLooping = (): boolean => this.isStopSyncFunctionLoop

  syncMKPBacjgroundJobs = async () => {
    if (this.state.isBackGroungJobsRuning) {
      return
    }
    const { selectedStore } = this.props
    const channel = this.getChannelParms()
    if (_.isNil(channel)) {
      return
    }

    const body: { [key: string]: any } = {
      store_id: selectedStore.get('id'),
      ref_id: channel.id,
      offset: 0,
      limit: 10,
      status: 'inprocess',
      return_count: true,
      types: [BACKGROUND_JOB_TYPES.MKP_SYNC_ORDER, BACKGROUND_JOB_TYPES.MKP_SYNC_PRODUCT],
    }

    this.isStopSyncFunctionLoop = false
    const callerObj: IBgSyncStatusManager = {
      successCallback: (response: any) => this.successCallbackBackground(response),
      failCallback: () => (err: any) => this.failCallbackBackground(err),
      shouldStopLooping: () => this.shouldStopLooping(),
    }
    // set isBackGroungJobsRuning จะได้รู้ว่าขณะนี้ กำลัง loop BGJobs อยู่ จะสั่ง api loop เพิ่มไม่ได้
    await util.setStatePromise(this, {
      isBackGroungJobsRuning: true,
    })
    await util.bgSyncStatus(
      callerObj,
      body,
      5000,
      300000,
      (key: 'PRODUCT' | 'ORDER', value: boolean) => this.callbackNotFoundKeyInJobs(key, value),
      () => this.timeOutCallbackBackground()
    )
  }

  callbackNotFoundKeyInJobs = (key: 'PRODUCT' | 'ORDER', value: boolean) => {
    const newSyncLoadingStart = this.state.syncLoading
    newSyncLoadingStart[key] = value
    util.setStatePromise(this, {
      syncLoading: newSyncLoadingStart,
    })
  }

  timeOutCallbackBackground = () => {
    const newSyncLoadingStart = this.state.syncLoading
    newSyncLoadingStart.PRODUCT = false
    newSyncLoadingStart.ORDER = false
    util.setStatePromise(this, {
      syncLoading: newSyncLoadingStart,
      isBackGroungJobsRuning: false,
    })
    p.op.showConfirmationOkOnly('', '(Timeout) คุณสามารถติดตามสถานะการซิงค์ต่อได้ที่ เมนูหลัก > คิวคำสั่งงาน')
  }

  successCallbackBackground = (response: any) => {
    // console.log('response => ', response)
    const newSyncLoadingStart = this.state.syncLoading
    newSyncLoadingStart.PRODUCT = false
    newSyncLoadingStart.ORDER = false
    util.setStatePromise(this, {
      syncLoading: newSyncLoadingStart,
      isBackGroungJobsRuning: false,
    })
  }

  failCallbackBackground = (err: any) => {
    p.op.showConfirmationOkOnly('เกิดข้อผิดพลาด', `กรุณาลองอีกครั้งภายหลัง ${err}`)
    const newSyncLoadingStart = this.state.syncLoading
    newSyncLoadingStart.PRODUCT = false
    newSyncLoadingStart.ORDER = false
    util.setStatePromise(this, {
      syncLoading: newSyncLoadingStart,
      isBackGroungJobsRuning: false,
    })
  }

  syncShopInfoFunction = async () => {
    const { syncShopInfo, selectedStore, updateNewChannelList } = this.props
    const channel = this.getChannelParms()
    const body: { [key: string]: any } = {
      store_id: selectedStore.get('id'),
      mkp_ch_id: channel.id,
    }
    const response: { channel: IMKPChannelDetail } = await new Promise((resolveSave) => {
      syncShopInfo({
        body,
        // @ts-ignore successCallback แดง
        successCallback: resolveSave,
        failedCallback: () => resolveSave(null),
      })
    })
    // console.log('response syncShopInfo => ', response)
    if (!_.isNil(response) && !_.isNil(response.channel)) {
      const newSyncLoading = this.state.syncLoading
      newSyncLoading.STORE = false
      await util.setStatePromise(this, {
        syncLoading: newSyncLoading,
        channel: response.channel,
      })
      const channels: IMKPChannelDetail[] = await util.updateMKPChannelsRedux(response.channel)
      if (!_.isNil(channels)) {
        updateNewChannelList(channels)
      }
      p.op.showToast('ซิงค์ข้อมูลสำเร็จแล้ว', 'success')
    } else {
      const newSyncLoading = this.state.syncLoading
      newSyncLoading.STORE = false
      await util.setStatePromise(this, {
        syncLoading: newSyncLoading,
      })
      p.op.showToast('เกิดข้อผิดพลาด กรุณาลองใหม่ภายหลัง', 'warning')
    }
  }

  syncProductFunction = async () => {
    const syncProduct = await this.syncMKPProductBackgroundJobs()
    if (!syncProduct) {
      return
    }
    if (!this.state.channel.is_active) {
      this._warningChannelNotActive()
      return
    }
    const { syncMKPProduct, selectedStore } = this.props
    const channel = this.getChannelParms()
    const body: { [key: string]: any } = {
      store_id: selectedStore.get('id'),
      mkp_ch_id: channel.id,
    }
    const response: { job_uuid: string } = await new Promise((resolveSave) => {
      syncMKPProduct({
        body,
        // @ts-ignore successCallback แดง
        successCallback: resolveSave,
        failedCallback: () => resolveSave(null),
      })
    })
    // console.log('response syncShopInfo => ', response)
    if (!_.isNil(response) && !_.isNil(response.job_uuid)) {
      await util.alertWithNavToBgJobListView({
        title: 'สร้าง "คิวคำสั่งงาน" สำเร็จ',
        message:
          'คิวคำสั่งงาน สำหรับการ "การซิงค์ข้อมูลสินค้า" ได้ถูกสร้างแล้ว ระบบกำลังดำเนินการอยู่เบื้องหลัง และอาจใช้เวลาซักครู่จนกว่าจะเสร็จสิ้น',
        dispatch: this.props.navigation.dispatch,
        selectedStoreId: this.props.selectedStore.get('id'),
        // onUserConfirm: () => this.goBackFromBase(),
        initBgJobUUID: response.job_uuid,
      })
      // const newSyncLoading = this.state.syncLoading
      // newSyncLoading.PRODUCT = false
      // await util.setStatePromise(this, {
      //   syncLoading: newSyncLoading,
      // })
      await this._getChannel()
      await this.syncMKPBacjgroundJobs()
    } else {
      const newSyncLoading = this.state.syncLoading
      newSyncLoading.PRODUCT = false
      await util.setStatePromise(this, {
        syncLoading: newSyncLoading,
      })
      p.op.showToast('เกิดข้อผิดพลาด กรุณาลองใหม่ภายหลัง', 'warning')
    }
  }

  syncOrderFunction = async (callBack: IOverlaySetupSyncOrderCallBack) => {
    const syncOrder = await this.syncMKPOrderBackgroundJobs()
    if (!syncOrder) {
      return
    }
    const { syncMKPOrder, selectedStore } = this.props

    const newSyncLoading = this.state.syncLoading
    newSyncLoading.ORDER = true
    await util.setStatePromise(this, {
      syncLoading: newSyncLoading,
    })
    this.onCloseOverlaySetupSyncOrder()
    const channel = this.getChannelParms()
    const body: { [key: string]: any } = {
      store_id: selectedStore.get('id'),
      mkp_ch_id: channel.id,
      method: callBack.timePeriod,
      from: callBack.datatime.start,
      to: callBack.datatime.end,
      force_sync: callBack.forceSync,
    }
    const response: { job_uuid: string } = await new Promise((resolveSave) => {
      syncMKPOrder({
        body,
        // @ts-ignore successCallback แดง
        successCallback: resolveSave,
        failedCallback: () => resolveSave(null),
      })
    })
    // console.log('response syncShopInfo => ', response)
    if (!_.isNil(response) && !_.isNil(response.job_uuid)) {
      await util.alertWithNavToBgJobListView({
        title: 'สร้าง "คิวคำสั่งงาน" สำเร็จ',
        message:
          'คิวคำสั่งงาน สำหรับการ "การซิงค์ข้อมูลออเดอร์" ได้ถูกสร้างแล้ว ระบบกำลังดำเนินการอยู่เบื้องหลัง และอาจใช้เวลาซักครู่จนกว่าจะเสร็จสิ้น',
        dispatch: this.props.navigation.dispatch,
        selectedStoreId: this.props.selectedStore.get('id'),
        // onUserConfirm: () => this.goBackFromBase(),
        initBgJobUUID: response.job_uuid,
      })
      // const newSyncLoading = this.state.syncLoading
      // newSyncLoading.ORDER = false
      // await util.setStatePromise(this, {
      //   syncLoading: newSyncLoading,
      // })
      await this._getChannel()
      await this.syncMKPBacjgroundJobs()
    } else {
      const newSyncLoading = this.state.syncLoading
      newSyncLoading.ORDER = false
      await util.setStatePromise(this, {
        syncLoading: newSyncLoading,
      })
      p.op.showToast('เกิดข้อผิดพลาด กรุณาลองใหม่ภายหลัง', 'warning')
    }
  }

  _setCreateMode = async () => {
    await util.setStatePromise(this, { mode: CREATE_MODE })
  }

  _onChangeText = (key: string, text: string) => {
    util.setStatePromise(this, { [key]: text })
  }

  _startLoading = async () => {
    await util.setStatePromise(this, { isLoading: true })
  }

  _stopLoading = async () => {
    await util.setStatePromise(this, { isLoading: false })
  }

  _openProductGroupModel = async () => {
    this.setState({ isOpenProductGroupModal: true })
    await this._stopLoading()
  }

  _createChannel = async () => {
    // console.log(`_createChannel START !`)
    await this._startLoading()
    const { createUserGroup, selectedStore, navigation, updateNewChannelList } = this.props
    // const { state } = navigation
    // const { params } = state
    const params = util.getNavParams(this.props)
    // console.log(`params `, params)
    const store_id = _.isNil(params.store_id) ? selectedStore.get(`id`) : params.store_id
    const { mkpIdSelected, lineApiKey } = this.state
    const body: { [key: string]: any } = {
      store_id,
    }
    // NOTE :: CREATE MODE API V2 เวลาสร้างไม่ต้องส่งชื่อไปแล้ว เดี๋ยวหลังบ้านจัดการเรื่องชื่อให้
    // if (channalName.length < 4) {
    //   p.op.alert('ข้อมูลไม่ถูกต้อง', 'กรุณาระบุชื่อกลุ่มที่มีมากกว่า 4 ตัวอักษร')
    //   await this._stopLoading()
    //   return
    // }

    // console.log(`channalEmail `, util.isEmailValidFormat(channalEmail))
    // if (!util.isEmailValidFormat(channalEmail.trim())) {
    //   p.op.alert('ข้อมูล E-Mail ไม่ถูกต้อง', 'กรุณาระบุ E-Mail ในรูปแบบที่ถูกต้อง')
    //   await this._stopLoading()
    //   return
    // }

    // NOTE :: CREATE MODE API V2 เวลาสร้างไม่ต้องส่งชื่อไปแล้ว เดี๋ยวหลังบ้านจัดการเรื่องชื่อให้
    // body.name = channalName.trim()

    // body.email = channalEmail.trim()

    body.pg_ids = this.state.channel.pg_ids

    const hasErr = (err) => {
      if (err.error_code) {
        this.goBackFromBase()
      }
    }
    const apiOptions = {
      showSpinner: true,
      onUserCloseAlert: (err) => hasErr(err),
    }

    let POST_API = api.POST_REQUEST_AUTH_URL
    // IF LINE MKP
    if (mkpIdSelected === 4) {
      POST_API = api.POST_REQUEST_LINE_AUTH_URL
      body.api_key = lineApiKey
      // IF LINE Lazada
    } else if (mkpIdSelected === 2) {
      POST_API = api.POST_REQUEST_LAZADA_AUTH_URL
      body.code = lineApiKey
      // IF LINE Shopufy
    } else if (mkpIdSelected === 5) {
      POST_API = api.POST_REQUEST_SHOPIFY_AUTH_URL
      // @ts-ignore
      body.shop_name = lineApiKey.shop_name
      // @ts-ignore
      body.access_token = lineApiKey.access_token
      // @ts-ignore
      body.api_key = lineApiKey.api_key
      // @ts-ignore
      body.api_secret = lineApiKey.api_secret
    } else {
      body.mkp_id = mkpIdSelected
    }

    const res = await api.postV2(POST_API, body, apiOptions)
    // console.log('res => ', res)
    if (res.auth_url) {
      // const { navigation } = this.props
      // const { state } = navigation
      // const { params } = state
      const params = util.getNavParams(this.props)
      // console.log(`params `, params)
      if (params && params.callBackURL && _.isFunction(params.callBackURL)) {
        let ms
        switch (mkpIdSelected) {
          case 2:
            ms = p.op.t('mkp_channel.confirmation_create_lazada')
            break
          case 3:
            ms = p.op.t('mkp_channel.confirmation_create_tiktok')
            break
          default:
            ms = p.op.t('mkp_channel.confirmation_create_shopee')
        }
        p.op.showConfirmation(
          '',
          ms,
          () => {
            params.callBackURL(res.auth_url, this.state.channel)
            if (!p.op.isWeb()) {
              this.goBackFromBase()
            }
          },
          () => this.goBackFromBase(),
          'ดำเนินการต่อ',
          'ยกเลิก'
        )
        // if (p.op.isWeb()) {
        //   await p.op.showConfirmationOkOnly(
        //     '',
        //     'กดปุ่ม "โหลดข้อมูลใหม่" เพื่ออัพเดตข้อมูลใหม่',
        //     () => window.location.reload(),
        //     'โหลดข้อมูลใหม่'
        //   )
        // }
        // this.goBackFromBase()
      }
      await this._stopLoading()
      return
    }
    // console.log('res => //// ', res)
    if (res.channels) {
      if (params && params.callBackUpdateChannelList && _.isFunction(params.callBackUpdateChannelList)) {
        params.callBackUpdateChannelList(res.channels)
        this.goBackFromBase()
        p.op.showToast('เชื่อมต่อช่องทางขายเรียบร้อยแล้ว', 'success')
        return
      }
    }
    await this._stopLoading()
    this.goBackFromBase()

    // OLD CODE V1
    // const res: { user_group: IMKPChannelDetail } = await new Promise((submitted) => {
    //   createUserGroup({
    //     body,
    //     // @ts-ignore
    //     successCallback: submitted,
    //     // @ts-ignore
    //     failedCallback: submitted,
    //   })
    // })

    // // console.log(`REF `, res)
    // if (res.user_group) {
    //   const channel = res.user_group
    //   channel.pgs = []
    //   // console.log(`userGroup `, userGroup)
    //   await util.setStatePromise(this, {
    //     channel,
    //     mode: VIEW,
    //   })
    //   let channels = isImmutable(selectedStore.get(`channels`)) ? selectedStore.get(`channels`).toJS() : selectedStore.get(`channels`)
    //   // console.log(`channels !! `, channels)
    //   if (_.isArray(channels)) {
    //     channels.push(channel)
    //   } else {
    //     channels = []
    //     channels.push(channel)
    //   }
    //   // updateNewChannelList => update redex
    //   updateNewChannelList(channels)
    //   // update channel list view
    //   if (params && params.callBackUpdateChannelList && _.isFunction(params.callBackUpdateChannelList)) {
    //     params.callBackUpdateChannelList(channels)
    //   }
    //   this.navToProductGroupListView()

    //   await this._stopLoading()
    // } else {
    //   await this._stopLoading()
    // }
  }

  _getShopeeUrl = async () => {
    await this._startLoading()
    const { getShopeeAuthUrl, selectedStore } = this.props
    const body: { [key: string]: any } = {
      store_id: selectedStore.get('id'),
      ug_id: this.state.channel.id,
    }
    const response: { auth_url: string } = await new Promise((resolveSave) => {
      getShopeeAuthUrl({
        body,
        // @ts-ignore successCallback แดง
        successCallback: resolveSave,
        failedCallback: () => resolveSave(null),
      })
    })
    // console.log(`response `, response)
    if (!_.isNil(response) && !_.isNil(response.auth_url)) {
      const { navigation } = this.props
      // const { state } = navigation
      // const { params } = state
      const params = util.getNavParams(this.props)
      // console.log(`params `, params)
      if (params && params.callBackURL && _.isFunction(params.callBackURL)) {
        params.callBackURL(response.auth_url, this.state.channel)
        this.goBackFromBase()
      }
    } else {
      await util.setStatePromise(this, { isLoading: false, isShowLinkToProductGroup: false, mode: VIEW })
      await this._stopLoading()
    }
  }

  _updateChannelList = (pgs: { pg_id: number }[]) => {
    // console.log(`_updateChannelList OKAY !`, pgs)
    const { selectedStore, updateNewChannelList, navigation } = this.props
    const { channel, isGoToAuthMKP } = this.state
    delay(300)
    const channels = isImmutable(selectedStore.get('channels')) ? selectedStore.get('channels').toJS() : selectedStore.get(`channels`)
    // console.log(`_callBackFromAssignProductGroups `, channels)
    const newChannel = channel
    const newPGs = []
    pgs.map((num: { pg_id: number }) => {
      const newPG = { id: num.pg_id }
      newPGs.push(newPG)
    })
    newChannel.pg_ids = newPGs

    const newChannels = []
    // @ts-ignore
    channels.map((ch: IMKPChannelDetail) => {
      if (ch.id === channel.id) {
        newChannels.push(newChannel)
      } else {
        newChannels.push(ch)
      }
    })
    // const { state } = navigation
    // const { params } = state
    const params = util.getNavParams(this.props)
    // console.log('newChannels => ', newChannels)
    // updateNewChannelList => update redex
    updateNewChannelList(newChannels)
    // update channel list view
    if (params && params.callBackUpdateChannelList && _.isFunction(params.callBackUpdateChannelList)) {
      params.callBackUpdateChannelList(newChannels)
    }
    // isGoToAuthMKP จะถูกเซตเป็น false เมื่อตอนกดแก้ไข pg
    if (isGoToAuthMKP) {
      this._getShopeeUrl()
    } else {
      util.setStatePromise(this, { isGoToAuthMKP: true })
    }
  }

  _updateChannel = async (key: string, value: any) => {
    await this._startLoading()
    const { updateChannel, selectedStore, updateNewChannelList, navigation } = this.props
    const { channel } = this.state
    if (_.isNil(channel) || _.isNil(channel.id)) {
      return null
    }
    const body: { [key: string]: any } = {
      // name: channalName.trim(),
      store_id: selectedStore.get(`id`),
      mkp_ch_id: channel.id,
    }
    body[key] = value
    const res: { channel: IMKPChannelDetail } = await new Promise((submitted) => {
      updateChannel({
        body,
        // @ts-ignore
        successCallback: (res) => submitted(res),
        // @ts-ignore
        failedCallback: submitted,
      })
    })
    if (res.channel) {
      // const { state } = navigation
      // const { params } = state
      const params = util.getNavParams(this.props)

      const newChannel = res.channel
      // newChannel.name = res.channel.name
      // TODO :: ตอนนี้ใช้ api ของ ug ในการ update จะไม่ได้ pgs ของ channel มาด้วย
      // if (_.isNil(newChannel.pg_ids)) {
      //   newChannel.pg_ids = channel.pg_ids
      // }
      const channels = isImmutable(selectedStore.get(`channels`)) ? selectedStore.get(`channels`).toJS() : selectedStore.get(`channels`)
      const newChannels = []
      // @ts-ignore
      channels.forEach((ch: IMKPChannelDetail) => {
        if (ch.id === channel.id) {
          newChannels.push(newChannel)
        } else {
          newChannels.push(ch)
        }
      })
      // updateNewChannelList => update redex
      updateNewChannelList(newChannels)
      // update channel list view
      if (params && params.callBackUpdateChannelList && _.isFunction(params.callBackUpdateChannelList)) {
        params.callBackUpdateChannelList(newChannels)
      }
      await util.setStatePromise(this, {
        channel: newChannel,
        isEditChannelName: false,
        channalNameBeforeEdit: newChannel.name,
      })
      await this._setMkpSetting(newChannel)
      p.op.showToast('แก้ไขข้อมูลเรียบร้อยแล้ว', 'success')
    } else {
      await this._setMkpSetting(this.state.channel)
    }
    await this._stopLoading()
  }

  _updateChannelFromCallBack = (channel: IMKPChannelDetail) => {
    // const { state } = navigation
    // const { params } = state
    const params = util.getNavParams(this.props)
    if (params && params.callBackUpdateChannel && _.isFunction(params.callBackUpdateChannel)) {
      params.callBackUpdateChannel(channel)
    }
    util.setStatePromise(this, {
      channel,
    })
  }

  onOpenOverlaySetupSyncOrder = () => {
    util.setStatePromise(this, {
      isOverlaySetupSyncOrderVisible: true,
    })
  }

  onCloseOverlaySetupSyncOrder = () => {
    util.setStatePromise(this, {
      isOverlaySetupSyncOrderVisible: false,
    })
  }

  onOpenOverlaySyncProduct = () => {
    util.setStatePromise(this, {
      isOverlayOpenOverlaySyncProduct: true,
    })
  }

  onCloseOverlaySyncProduct = () => {
    util.setStatePromise(this, {
      isOverlayOpenOverlaySyncProduct: false,
    })
  }

  _warningChannelNotActive = () => {
    p.op.showConfirmationOkOnly('', 'ปิดใช้งานช่องทางขายอยู่ ไม่สามารถซิงค์ข้อมูลสินค้าได้')
  }

  _deleteChannel = async () => {
    const { deleteChannel, selectedStore } = this.props
    const { channalName, channel } = this.state
    if (_.isNil(channel) || _.isNil(channel.id)) {
      return null
    }
    const body: { [key: string]: any } = {
      store_id: selectedStore.get(`id`),
      ug_id: channel.id,
    }
    const res = await new Promise((submitted) => {
      deleteChannel({
        body,
        successCallback: (res) => submitted(res),
        failedCallback: submitted,
      })
    })
    this.goBackFromBase()
    // console.log(`res => `, res)
  }

  _isActivaMKPChannel = () => {
    const { channel } = this.state
    return channel.is_active
  }

  syncMKPProductBackgroundJobs = async (): Promise<boolean> => {
    const { selectedStore } = this.props
    const channel = this.getChannelParms()
    if (_.isNil(channel)) {
      return
    }

    const newSyncLoadingStart = this.state.syncLoading
    newSyncLoadingStart.PRODUCT = true
    util.setStatePromise(this, {
      syncLoading: newSyncLoadingStart,
    })

    let isSyncMordProduct = true
    await new Promise(async (resolve) => {
      const body: { [key: string]: any } = {
        store_id: selectedStore.get('id'),
        ref_id: channel.id,
        offset: 0,
        limit: 10,
        status: 'inprocess',
        return_count: true,
        types: [BACKGROUND_JOB_TYPES.MKP_SYNC_PRODUCT],
      }
      // BACKGROUND_JOB_TYPES.MKP_SYNC_ORDER,

      const response: IBackgroundJobListResponse = await api.postV2(api.POST_BACKGROUND_JOB_LIST, body)
      if (response.count && response.count > 0) {
        isSyncMordProduct = false
        p.op.showConfirmation(
          `มี ${response.count} คำสั่งงาน ค้างอยู่ในคิว`,
          'กรุณารรอสักครู่จนกว่าคำสั่งงานจะเสร็จสิ้น หรือสามารถดูความคืบหน้าได้ที่ปุ่ม คิวคำสั่งงาน ในหน้าแรก',
          () => {
            // isSyncMordProduct = true
            // @ts-ignore
            resolve(null)
          },
          () => {
            // isSyncMordProduct = false
            // @ts-ignore
            resolve(null)
          },
          'ดูคำสั่งงาน',
          'ปิด'
        )
        // successProductCallbackBackground(response)
      } else {
        isSyncMordProduct = true
        // @ts-ignore
        resolve(null)
        // failProductCallbackBackground(response)
      }
      // util.bgSyncStatus(callerObj, body, 5000, 60000, () => this.timeOutCallbackBackground())
    })
    // console.log('isSyncMordProduct => ', isSyncMordProduct)
    newSyncLoadingStart.PRODUCT = false
    util.setStatePromise(this, {
      syncLoading: newSyncLoadingStart,
    })
    return isSyncMordProduct
  }

  setForceCreateShipping = async (newValue: boolean) => {
    await util.setStatePromise(this, {
      isForceCreateShipping: newValue,
    })

    const canEditPermission = this._checkCanEditPermission()
    if (!canEditPermission) {
      await util.setStatePromise(this, {
        isForceCreateShipping: !newValue,
      })
      return
    }

    let onOffText = 'ปิด'
    if (newValue) {
      onOffText = 'เปิด'
    }
    const isConfirmUnAuth = await p.op.isUserConfirm(
      '',
      `คุณต้องการ "${onOffText}" บันทึกจัดส่งแม้จำนวนคลังสินค้าในคลังไม่พอ`,
      'ยืนยัน',
      'ปิด'
    )
    if (!isConfirmUnAuth) {
      await util.setStatePromise(this, {
        isForceCreateShipping: !newValue,
      })
      return
    }
    // const { selectedStore, updateNewChannelList } = this.props
    // const channel = this.getChannelParms()
    // if (_.isNil(channel)) {
    //   return
    // }
    await this._updateChannel('allow_create_shipping_if_qty_not_enough', newValue)
    // const body: { [key: string]: any } = {
    //   store_id: selectedStore.get('id'),
    //   channel_id: channel.id,
    //   allow_create_shipping_if_qty_not_enough: newValue,
    // }
    // const response: { status: 'ok' } = await api.patchV2(api.PATCH_UPDATE_MKP_CHANNEL_SETTING, body)
    // // console.log('response isForceCreateShipping => ', response)
    // if (response.status === 'ok') {
    //   // console.log('response YESSS ')
    //   const newChannel = channel
    //   newChannel.allow_create_shipping_if_qty_not_enough = newValue
    //   const channels: IMKPChannelDetail[] = await util.updateMKPChannelsRedux(newChannel)
    //   if (!_.isNil(channels)) {
    //     updateNewChannelList(channels)
    //   }
    //   p.op.showToast(`${onOffText}ใช่งานบันทึกจัดส่งแม้จำนวนคลังสินค้าในคลังไม่พอเรียบร้อยแล้ว`, 'success')
    // } else {
    //   p.op.showConfirmationOkOnly('เกิดข้อผิดพลาด', `${response}`)
    // }
  }

  syncMKPOrderBackgroundJobs = async (): Promise<boolean> => {
    const { selectedStore } = this.props
    const channel = this.getChannelParms()
    if (_.isNil(channel)) {
      return
    }

    const newSyncLoadingStart = this.state.syncLoading
    newSyncLoadingStart.ORDER = true
    util.setStatePromise(this, {
      syncLoading: newSyncLoadingStart,
    })

    let isSyncMordOrder = true
    await new Promise(async (resolve) => {
      const body: { [key: string]: any } = {
        store_id: selectedStore.get('id'),
        ref_id: channel.id,
        offset: 0,
        limit: 10,
        status: 'inprocess',
        return_count: true,
        types: [BACKGROUND_JOB_TYPES.MKP_SYNC_ORDER],
      }

      const response: IBackgroundJobListResponse = await api.postV2(api.POST_BACKGROUND_JOB_LIST, body)
      if (response.count && response.count > 0) {
        isSyncMordOrder = false
        p.op.showConfirmation(
          `มี ${response.count} คำสั่งงาน ค้างอยู่ในคิว`,
          'กรุณารรอสักครู่จนกว่าคำสั่งงานจะเสร็จสิ้น หรือสามารถดูความคืบหน้าได้ที่ปุ่ม คิวคำสั่งงาน ในหน้าแรก',
          () => {
            // isSyncMordOrder = true
            // @ts-ignore
            resolve(null)
          },
          () => {
            // isSyncMordOrder = false
            // @ts-ignore
            resolve(null)
          }
        )
        // successProductCallbackBackground(response)
      } else {
        isSyncMordOrder = true
        // @ts-ignore
        resolve(null)
        // failProductCallbackBackground(response)
      }
      // util.bgSyncStatus(callerObj, body, 5000, 60000, () => this.timeOutCallbackBackground())
    })
    // console.log('isSyncMordOrder => ', isSyncMordOrder)
    newSyncLoadingStart.ORDER = false
    util.setStatePromise(this, {
      syncLoading: newSyncLoadingStart,
    })
    return isSyncMordOrder
  }

  _callBackCheckboxListModel = async (pg_ids: number[], lineApiKey?: string) => {
    const { mode, channel, productGroups } = this.state
    // console.log('pg_ids =>', pg_ids)
    if (_.isNil(pg_ids) || pg_ids.length < 1) {
      p.op.showConfirmationOkOnly('', 'กรุณาเลือกอย่างน้อย 1 รายการราคา')
      return
    }
    const newPg_ids: number[] = []
    productGroups.forEach((pg: IProductGroup, index: number) => {
      if (_.includes(pg_ids, index)) {
        newPg_ids.push(pg.id)
      }
    })
    if (mode === 'CREATE') {
      // @ts-ignore
      const newChannel: IMKPChannelDetail = _.isNil(channel) ? { pg_ids: newPg_ids } : _.clone(channel)
      newChannel.pg_ids = newPg_ids
      await util.setStatePromise(this, {
        channel: newChannel,
        lineApiKey,
      })
      await this._createChannel()
    } else if (mode === 'VIEW') {
      this._updateChannel('pg_ids', newPg_ids)
    }
  }

  _syncModeQtyOnChange = async (opt: { label: string; value: number }, idx: number) => {
    if (idx === this.state.selectedSyncModeQtyIndex) {
      return
    }
    const canEditPermission = this._checkCanEditPermission()
    if (!canEditPermission) {
      return
    }
    const confirm = await this._confirmChangeMkpSetting()
    if (!confirm) {
      return
    }
    this.setState({
      selectedSyncModeQtyIndex: idx,
    })
    const mode = idx === 0 ? 'XSELLY_TO_MKP' : 'NO_UPDATE_TO_MKP'
    this._updateChannel('sync_mode_qty', mode)
  }

  _confirmChangeMkpSetting = async () => {
    const confirm = await new Promise<boolean>((passConfirm) => {
      p.op.showConfirmation(
        'เปลี่ยนการตั้งค่าช่องทางขาย?',
        'การเปลี่ยนแปลงนี้จะมีผลทันทีเมื่อยืนยัน คุณต้องการเปลี่ยนการตั้งค่านี้ใช่หรือไม่?',
        () => passConfirm(true),
        () => passConfirm(false),
        'ยืนยัน',
        'ยกเลิก'
      )
    })
    return confirm
  }

  _autoConfirmOrderOnChange = async (opt: { label: string; value: number }, idx: number) => {
    if (idx === this.state.selectedAutoConfirmOrderIndex) {
      return
    }
    const canEditPermission = this._checkCanEditPermission()
    if (!canEditPermission) {
      return
    }
    const confirm = await this._confirmChangeMkpSetting()
    if (!confirm) {
      return
    }
    this.setState({
      selectedAutoConfirmOrderIndex: idx,
    })
    const mode = idx === 0 ? 'NONE' : idx === 1 ? 'PICKUP' : 'DROPOFF'
    this._updateChannel('auto_confirm_order', mode)
  }

  _syncModeOrderOnChange = async (opt: { label: string; value: number }, idx: number) => {
    if (idx === this.state.selectedSyncModeOrderIndex) {
      return
    }
    const canEditPermission = this._checkCanEditPermission()
    if (!canEditPermission) {
      return
    }
    const confirm = await this._confirmChangeMkpSetting()
    if (!confirm) {
      return
    }
    this.setState({
      selectedSyncModeOrderIndex: idx,
    })
    const mode = idx === 0 ? 'NORMAL' : 'NO_NEW_ORDER'
    this._updateChannel('sync_mode_order', mode)
  }

  _autoLoadShippingLableOnChange = async (opt: { label: string; value: number }, idx: number) => {
    if (idx === this.state.selectedAutoLoadShippingLable) {
      return
    }
    const canEditPermission = this._checkCanEditPermission()
    if (!canEditPermission) {
      return
    }
    const confirm = await this._confirmChangeMkpSetting()
    if (!confirm) {
      return
    }
    this.setState({
      selectedAutoLoadShippingLable: idx,
    })
    const mode = idx === 0
    this._updateChannel('auto_load_shipping_label', mode)
  }

  _autoCreateOrderAtStatus = async (opt: { label: string; value: number }, idx: number) => {
    if (idx === this.state.selectedCreateOrderAtStatus) {
      return
    }
    const canEditPermission = this._checkCanEditPermission()
    if (!canEditPermission) {
      return
    }
    const confirm = await this._confirmChangeMkpSetting()
    if (!confirm) {
      return
    }
    this.setState({
      selectedCreateOrderAtStatus: idx,
    })
    const mode = idx === 0 ? 'UNPAID' : 'READY_TO_SHIP'
    this._updateChannel('create_order_at_status', mode)
  }

  _selectedMkp = async (mkpId: number) => {
    const MKP_ID_READY_TO_PROD = [1, 2, 3, 4, 5]
    // const storeId = this.props.selectedStore.get('id')
    // const STORE_ID_BYPASS = storeId === 1 || storeId === 3
    // const BYPASS = (mkpId === 4 && STORE_ID_BYPASS) || util.isDevMode()
    // const MKP_TEST = util.isDevMode() ? [1, 2, 3, 4] : MKP_ID_READY_TO_PROD
    /*
    // BY_PASS_LAZADA_STORE_IDS
    const BY_PASS_LAZADA_STORE_IDS = [36714, 41222, 45767, 2633, 51615, 51361, 53978, 6, 65181, 65325, 65535]
    // console.log('mkpId 00 => ', this.props.selectedStore.get('id'))
    // สำหรับ dev mode และ mkp id ที่พร้อมแล้วสำหรับ prod เท่านั้น
    if (util.isDevMode() || _.includes(MKP_ID_READY_TO_PROD, mkpId)) {
      // console.log('mkpId => ', mkpId)
      const store_id = this.props.selectedStore.get('id')
      // BY_PASS_LAZADA_STORE_IDS ถ้าเลือก lazada เป็น store id เหล่านี้  ก็ปล่อยผ่านได้
      if (!util.isDevMode() && mkpId === 2 && !_.includes(BY_PASS_LAZADA_STORE_IDS, store_id)) {
        p.op.showConfirmationOkOnly('', 'เร็วๆนี้')
        return
      }
    */
    if (util.isDevOrProdTestMode() || _.includes(MKP_ID_READY_TO_PROD, mkpId)) {
      await util.setStatePromise(this, { mkpIdSelected: mkpId })
    } else {
      p.op.showConfirmationOkOnly('', 'เร็วๆนี้')
    }
  }

  _cancelShippingWhenCancelOrder = async (newValue: boolean) => {
    // console.log('newValue => ', newValue)
    const canEditPermission = this._checkCanEditPermission()
    if (!canEditPermission) {
      return
    }
    const confirm = await this._confirmChangeMkpSetting()
    if (!confirm) {
      const setValueBack = !newValue
      // console.log('setValueBack => ', setValueBack)
      await util.setStatePromise(this, {
        isCancelShippingWhenCancelOrder: setValueBack,
      })
      return
    }
    this.setState({
      isCancelShippingWhenCancelOrder: newValue,
    })
    await this._updateChannel('cancel_shipping_when_cancel_order', newValue)
  }

  _getChannel = async () => {
    await this._startLoading()
    const { getChannel, selectedStore, updateNewChannelList, navigation } = this.props
    const { channel } = this.state
    // console.log('channel => ', channel)
    if (_.isNil(channel) || _.isNil(channel.id)) {
      p.op.showConfirmationOkOnly('', 'No MKP Channel ID')
      return null
    }

    const body: { [key: string]: any } = {
      store_id: selectedStore.get('id'),
      mkp_ch_id: channel.id,
    }
    // console.log('body => ', body)
    const response: { channel: IMKPChannelDetail } = await new Promise((resolveSave) => {
      getChannel({
        body,
        // @ts-ignore successCallback แดง
        successCallback: resolveSave,
        failedCallback: () => resolveSave(null),
      })
    })
    // console.log(`response => `, response)
    if (response.channel) {
      // If the "channels" value in "selectedStore" is an Immutable object, convert it to a plain JavaScript object
      const channels = isImmutable(selectedStore.get(`channels`)) ? selectedStore.get(`channels`).toJS() : selectedStore.get(`channels`)
      // Create an empty array to hold the updated list of channels
      const newChannels = []
      // Get the new channel from the "response" object
      const newChannel = response.channel

      // @ts-ignore
      channels.forEach((ch: IMKPChannelDetail) => {
        // If the current channel has the same ID as the new channel,
        // add the new channel to the "newChannels" array
        if (ch.id === channel.id) {
          newChannels.push(newChannel)
        } else {
          newChannels.push(ch)
        }
      })
      // updateNewChannelList => update redex
      updateNewChannelList(newChannels)

      // // update channel list view
      // const { state } = navigation
      // const { params } = state
      const params = util.getNavParams(this.props)
      if (params && params.callBackUpdateChannelList && _.isFunction(params.callBackUpdateChannelList)) {
        params.callBackUpdateChannelList(newChannels)
      }
      await util.setStatePromise(this, {
        channel: newChannel,
        channalName: newChannel.name,
        channalNameBeforeEdit: newChannel.name,
      })
      await this._stopLoading()
    }
  }

  _onSegmentChangeSelfDelivery = async (newIndex: number) => {
    const canEditPermission = this._checkCanEditPermission()
    if (!canEditPermission) {
      return
    }

    if (this.state.isSelfDelivery && newIndex === 1) {
      return
    }

    const isConfirmed = await this._confirmChangeMkpSetting()
    if (!isConfirmed) {
      return
    }
    await util.setStatePromise(this, {
      isSelfDelivery: newIndex !== 0,
    })
    this._updateChannel('is_self_delivery', newIndex !== 0)
  }

  _changeSenderAddress = async (address: IAddress) => {
    const canEditPermission = this._checkCanEditPermission()
    if (!canEditPermission) {
      return
    }
    const isConfirmed = await this._confirmChangeMkpSetting()
    if (!isConfirmed) {
      return
    }
    await util.setStatePromise(this, {
      senderAddressForSelfDelivery: _.isNil(address.id) ? null : address,
    })
    this._updateChannel('self_delivery_sender_address_id', _.isNil(address.id) ? null : address.id)
  }

  _isShopee = () => this.state.channel.mkp_id === 1

  _isLazada = () => this.state.channel.mkp_id === 2

  _checkCanEditPermission = () => {
    if (!canDoAtSelectedStore(CONS.PERM_STORE_HELPER.MKP_EDIT)) {
      p.op.alert('คุณไม่มีสิทธิ์ในการแก้ไขช่องทางขาย')
      return false
    }
    return true
  }
}
