import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { getSelectedStore } from 'x/redux/selectors'
import * as profileActions from 'x/modules/profile/ProfileState'

import ResellerApprovalListView from './ResellerApprovalListView'

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
    // myStores: getMyStores(state),
  }),
  (dispatch) => {
    return {
      fetchProfileList: bindActionCreators(profileActions.fetchProfileList, dispatch),
      approvalsReseller: bindActionCreators(profileActions.approvalsReseller, dispatch),
      dispatch,
    }
  }
)(ResellerApprovalListView)
