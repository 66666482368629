import { Map, fromJS } from 'immutable'
import {} from '../../redux/selectors'
import settings from '../../config/settings'
import { ActionApiParams, ICustomOrderView } from '../../index'
import actions from '../../config/actions'

const defaultCustomCreateOrder = Map({
  receiver: {
    v: 1,
    t: 2,
    a: 0,
    // vi: 16372,
  },
  sender: {
    // u: 1,
    v: 1,
    a: 0,
    // vi: 159140,
  },
  shipping: {
    v: 1,
    a: 0,
    // vi: 20,
  },
  ship_before_pay: {
    v: 0,
    n_cod: false,
    is_cod: true,
  },
  sales_channel: {
    v: 1,
    vi: 0,
  },
  notes: {
    n_1: {
      v: 1,
    },
    n_2: {
      v: 1,
    },
  },
  auto_complete: {
    v: 1,
    d: true,
  },
  skip_order_detail_auto_copy: {
    v: 1,
  },
  date_delivery: {
    v: 1,
  },
  auto_cancel_order: {
    v: 1,
    m: 'd',
  },
})

const defaultCustomOrderView = Map({
  contact: {
    v: 1,
  },
  link_order: {
    v: 1,
  },
  show_price_and_transaction_cost: {
    v: 1,
    d: false,
  },
  notes: {
    n_03: {
      v: 1,
    },
    n_04: {
      v: 1,
    },
  },
  receiver: {
    v: 1,
  },
  shipping: {
    v: 1,
  },
  ship_before_pay: {
    v: 1,
  },
  shipping_label: {
    n_1: {
      v: 1,
    },
    n_2: {
      v: 1,
    },
  },
  sales_channel: {
    v: 1,
  },
  sender: {
    v: 1,
  },
  auto_complete: {
    v: 1,
  },
  // order_copy_templates: null,
  date_delivery: {
    v: 1,
  },
  auto_cancel_order: {
    v: 1,
  },
})

const defaultOrderCopyTemplate = {
  // isDefaultTemplate: true,
  // templateString: null,

  // d is flag is using default app template
  d: true,
  // v is contain template string
  v: null,
}

// Initial state
const initialState = Map({
  fetchOrders: Map({
    offset: 0,
    limit: settings.ORDER_LOAD_INIT_LIMIT,
    // sortBy: 'created_at',
    sortBy: 'id',
    sortType: 'desc',
  }),
  order: Map({
    customCreateOrder: Map({
      selectedOrder: defaultCustomCreateOrder,
      editingOrder: defaultCustomCreateOrder,
    }),
    customOrderView: Map({
      selectedOrder: defaultCustomOrderView,
      editingOrder: defaultCustomOrderView,
    }),
  }),
})

// Actions
// CUSTOM ORDER
export const LOAD_CUSTOM_CREATE_ORDER = 'PreferenceState/LOAD_CUSTOM_CREATE_ORDER'
export const LOAD_CUSTOM_ORDER_VIEW = 'PreferenceState/LOAD_CUSTOM_ORDER_VIEW'
export const SET_CUSTOM_CREATE_ORDER = 'PreferenceState/SET_CUSTOM_CREATE_ORDER'
export const SET_CUSTOM_ORDER_VIEW = 'PreferenceState/SET_CUSTOM_ORDER_VIEW'

// export function fetchOrders(payload) {
//   util.log(payload)
//   return { type: actions.ORDERS_FETCH, payload }
// }

export function setCustomCreateOrder(params: any) {
  return {
    type: SET_CUSTOM_CREATE_ORDER,
    payload: params,
  }
}

export function setCustomOrderView(params: any) {
  return {
    type: SET_CUSTOM_ORDER_VIEW,
    payload: params,
  }
}

export function loadPrefCustomOrderView(params: any) {
  return {
    type: LOAD_CUSTOM_ORDER_VIEW,
    payload: params,
  }
}

export function addCustomOrder(params: any) {
  return {
    type: actions.ADD_CUSTOM_ORDER,
    ...params,
  }
}

export function loadPrefUIOrderView(newCustomOrderView: ICustomOrderView) {
  return {
    type: LOAD_CUSTOM_ORDER_VIEW,
    payload: newCustomOrderView,
  }
}

// Reducer
export default function PreferenceStateReducer(state = initialState, action: ActionApiParams = {}) {
  const { type, payload } = action
  let newState = state
  switch (type) {
    // TODO: Handle for updating pref settings
    // case actions.SET_ORDERS_STATE:
    //     // util.log('SET_ORDERS_STATE', state.toJS(), payload.toJS(), payload.get('orders').toJS())

    //   var s = setOrdersInOrders(state, payload.get('orders'))
    //   s = setBackendTimeInServers(s, util.getAllTimes(payload.get('server_time')))
    //   s = setOrdersSummaryInOrders(s, payload.get('summary'))
    //   return s
    case LOAD_CUSTOM_CREATE_ORDER:
      const newCustom: { [key: string]: any } = {}
      const newPayload = fromJS(payload)
      if (newPayload.has('receiver')) {
        newCustom.receiver = newPayload.get('receiver')
      } else {
        newCustom.receiver = defaultCustomCreateOrder.get('receiver')
      }

      if (newPayload.has('sender')) {
        newCustom.sender = newPayload.get('sender')
      } else {
        newCustom.sender = defaultCustomCreateOrder.get('sender')
      }

      if (newPayload.has('shipping')) {
        newCustom.shipping = newPayload.get('shipping')
      } else {
        newCustom.shipping = defaultCustomCreateOrder.get('shipping')
      }

      if (newPayload.has('ship_before_pay')) {
        newCustom.ship_before_pay = newPayload.get('ship_before_pay')
      } else {
        newCustom.ship_before_pay = defaultCustomCreateOrder.get('ship_before_pay')
      }

      if (newPayload.has('sales_channel')) {
        newCustom.sales_channel = newPayload.get('sales_channel')
      } else {
        newCustom.sales_channel = defaultCustomCreateOrder.get('sales_channel')
      }

      if (newPayload.has('notes')) {
        newCustom.notes = newPayload.get('notes')
      } else {
        newCustom.notes = defaultCustomCreateOrder.get('notes')
      }

      if (newPayload.has('auto_complete')) {
        newCustom.auto_complete = newPayload.get('auto_complete')
      } else {
        newCustom.auto_complete = defaultCustomCreateOrder.get('auto_complete')
      }

      if (newPayload.has('skip_order_detail_auto_copy')) {
        newCustom.skip_order_detail_auto_copy = newPayload.get('skip_order_detail_auto_copy')
      } else {
        newCustom.skip_order_detail_auto_copy = defaultCustomCreateOrder.get('skip_order_detail_auto_copy')
      }

      if (newPayload.has('auto_cancel_order')) {
        newCustom.auto_cancel_order = newPayload.get('auto_cancel_order')
      }

      if (newPayload.has('date_delivery')) {
        newCustom.date_delivery = newPayload.get('date_delivery')
      }

      if (newPayload.has('check_dup_receiver_addr')) {
        newCustom.check_dup_receiver_addr = newPayload.get('check_dup_receiver_addr')
      }

      newState = newState.setIn(['order', 'customCreateOrder', 'selectedOrder'], fromJS(newCustom))
      newState = newState.setIn(['order', 'customCreateOrder', 'editingOrder'], fromJS(newCustom))
      return newState

    case LOAD_CUSTOM_ORDER_VIEW:
      const newCustomView: { [key: string]: any } = {}
      const newPayloadOrderView = fromJS(payload)
      if (newPayloadOrderView.has('contact')) {
        newCustomView.contact = newPayloadOrderView.get('contact')
      } else {
        newCustomView.contact = defaultCustomOrderView.get('contact')
      }

      if (newPayloadOrderView.has('link_order')) {
        newCustomView.link_order = newPayloadOrderView.get('link_order')
      } else {
        newCustomView.link_order = defaultCustomOrderView.get('link_order')
      }

      if (newPayloadOrderView.has('show_price_and_transaction_cost')) {
        newCustomView.show_price_and_transaction_cost = newPayloadOrderView.get('show_price_and_transaction_cost')
      } else {
        newCustomView.show_price_and_transaction_cost = defaultCustomOrderView.get('show_price_and_transaction_cost')
      }

      if (newPayloadOrderView.has('notes')) {
        newCustomView.notes = newPayloadOrderView.get('notes')
      } else {
        newCustomView.notes = defaultCustomOrderView.get('notes')
      }

      if (newPayloadOrderView.has('receiver')) {
        newCustomView.receiver = newPayloadOrderView.get('receiver')
      } else {
        newCustomView.receiver = defaultCustomOrderView.get('receiver')
      }

      if (newPayloadOrderView.has('shipping')) {
        newCustomView.shipping = newPayloadOrderView.get('shipping')
      } else {
        newCustomView.shipping = defaultCustomOrderView.get('shipping')
      }

      if (newPayloadOrderView.has('ship_before_pay')) {
        newCustomView.ship_before_pay = newPayloadOrderView.get('ship_before_pay')
      } else {
        newCustomView.ship_before_pay = defaultCustomOrderView.get('ship_before_pay')
      }

      if (newPayloadOrderView.has('shipping_label')) {
        newCustomView.shipping_label = newPayloadOrderView.get('shipping_label')
      } else {
        newCustomView.shipping_label = defaultCustomOrderView.get('shipping_label')
      }

      if (newPayloadOrderView.has('sales_channel')) {
        newCustomView.sales_channel = newPayloadOrderView.get('sales_channel')
      } else {
        newCustomView.sales_channel = defaultCustomOrderView.get('sales_channel')
      }

      if (newPayloadOrderView.has('sender')) {
        newCustomView.sender = newPayloadOrderView.get('sender')
      } else {
        newCustomView.sender = defaultCustomOrderView.get('sender')
      }

      if (newPayloadOrderView.has('auto_complete')) {
        newCustomView.auto_complete = newPayloadOrderView.get('auto_complete')
      } else {
        newCustomView.auto_complete = defaultCustomOrderView.get('auto_complete')
      }

      if (newPayloadOrderView.has('order_copy_templates')) {
        newCustomView.order_copy_templates = newPayloadOrderView.get('order_copy_templates')
      }

      if (newPayloadOrderView.has('date_delivery')) {
        newCustomView.date_delivery = newPayloadOrderView.get('date_delivery')
      }

      if (newPayloadOrderView.has('auto_cancel_order')) {
        newCustomView.auto_cancel_order = newPayloadOrderView.get('auto_cancel_order')
      }

      newState = newState.setIn(['order', 'customOrderView', 'selectedOrder'], fromJS(newCustomView))
      newState = newState.setIn(['order', 'customOrderView', 'editingOrder'], fromJS(newCustomView))
      return newState

    case SET_CUSTOM_CREATE_ORDER:
      newState = newState.setIn(['order', 'customCreateOrder', 'selectedOrder'], fromJS(payload))
      newState = newState.setIn(['order', 'customCreateOrder', 'editingOrder'], fromJS(payload))
      return newState

    default:
      return state
  }
}
