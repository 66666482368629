import React, { Component } from 'react'
import _ from 'lodash'
import CONS from 'x/config/constants'
import * as util from 'x/utils/util'
import {
  ISelectedStoreMap,
  ActionApiParams,
  ISelectedAddresses,
  IProfile,
  IReferalReseller,
  IResellerInfo,
  IReportMenuItem,
  IXScreenProps,
} from 'x/index'
import p from 'x/config/platform-specific'
import { fromJS } from 'immutable'

const moment = require('moment')
require('moment/locale/th')
require('moment-timezone')

const { REPORT_VIEW } = CONS.PERM_STORE_HELPER

const { VIEW, EDIT } = CONS.MODE
interface IBaseCustomerViewProps extends IXScreenProps {
  dispatch: (params: any) => void
  selectedStore: ISelectedStoreMap
  fetchProfileDetails: (params: ActionApiParams) => void
  deleteOrgAddress: (params: ActionApiParams) => void
  deleteAddress: (params: ActionApiParams) => void
  updateLoginEmail: (params: ActionApiParams) => void
  changeParent: (params: ActionApiParams) => void
  changeResellerType: (params: ActionApiParams) => void

  saveNew: any
  saveEditing: (params: ActionApiParams) => void
  createAddress: (params: ActionApiParams) => void
  editOrgAddress: (params: ActionApiParams) => void
  addCustomer: (params: ActionApiParams) => void
}

interface IBaseCustomerViewState {
  isImgUploading: boolean
  mode: string // ใช้แค่ Address และ XPorfile เท่านั้น
  addresses: ISelectedAddresses[]
  profile: IProfile
  referalReseller: IReferalReseller
  resellerInfo: IResellerInfo
  loginEmail: string
  isEditLoginEmail: boolean
  isEditable: boolean // ควบคุมการแก้ไขทั้งหมดในหน้านี้
  isImgDocumentUploading: boolean
  isHasImgDocument: boolean
  isEditModeImgDocument: boolean
  newDealerCodeTxt: string
  isShowInputNewDealerCode: boolean
  isChangeDealerLoading: boolean
  isModelChangeMemberType: boolean
  newResellertypeNumber: number
  loadingText: string
}

export default abstract class BaseCustomerView extends Component<IBaseCustomerViewProps, IBaseCustomerViewState> {
  abstract _handleOnDownloadFile(url: string, fileName: string): Promise<void>

  imgManagerRef: React.RefObject<any>

  txtOldLoginEmail: string

  imgManagerDocumentRef: React.RefObject<any>

  menuItems: IReportMenuItem[]

  protected constructor(props: IBaseCustomerViewProps) {
    super(props)
    this.imgManagerRef = React.createRef()
    this.state = {
      isImgUploading: false,
      mode: VIEW,
      addresses: null,
      profile: null,
      referalReseller: null,
      resellerInfo: null,
      loginEmail: '',
      isEditLoginEmail: false,
      isEditable: false,
      isImgDocumentUploading: false,
      isHasImgDocument: false,
      isEditModeImgDocument: false,
      newDealerCodeTxt: '',
      isShowInputNewDealerCode: false,
      isChangeDealerLoading: false,
      isModelChangeMemberType: false,
      newResellertypeNumber: 0,
      loadingText: '',
    }
    this.txtOldLoginEmail = ''
    this.imgManagerDocumentRef = React.createRef()
    this.menuItems = [
      // {
      //   label: 'ออเดอร์',
      //   isHeader: true,
      // },
      {
        label: 'จำนวนตัวแทนของ Dealer',
        iconName: 'list-alt',
        iconFamily: 'FontAwesome',
        nav: async () => await this._downloadOwnerCountResellers(),
        // nav: this._alertComingSoon,
        perm: REPORT_VIEW,
        // permBitIndex: CONS.REPORT_SET_BIT_BINARY.report_complete_sales,
      },
    ]
  }

  async componentDidMount() {
    await this._setData()
    await this._setCanEdit()
  }

  _downloadOwnerCountResellers = async () => {
    const { selectedStore } = this.props
    const org_id = util.getOrgId(selectedStore)
    if (_.isNil(org_id) || !org_id) {
      p.op.showConfirmationOkOnly(``, `คุณไม่สามารถใช้งานฟีเจอร์นี้ได้ เนื่องจากไปพบองค์กรที่สังกัด`)
      return
    }
    const url = util.getReportOwnerCountResellersExcelUrl({
      org_id,
    })
    const startDateTime = moment().format('DD-MM-YYYY_H-mm-ss')
    const fileName = `OwnerCountResellers_${startDateTime}.xlsx`
    await this._handleOnDownloadFile(url, fileName)
  }

  _setCanEdit = async () => {
    const { selectedStore } = this.props
    const isOrgOwnerStore = util.isOrgOwnerStore(selectedStore)
    const type = util.getOrgResellerType(selectedStore)
    let isEditable = false
    if (isOrgOwnerStore) {
      isEditable = true
    } else if (type === 1) {
      isEditable = false
    }
    await util.setStatePromise(this, { isEditable })
  }

  _setData = async (newRes = null) => {
    const { fetchProfileDetails, navigation } = this.props
    // const { state } = navigation
    // const { params } = state
    const params = util.getNavParams(this.props)
    const profileId = !_.isNil(params) && !_.isNil(params.key) ? params.key : null
    const isApproved = !_.isNil(params) && !_.isNil(params.isApproved) ? params.isApproved : false
    const res = !_.isNil(newRes)
      ? null
      : await new Promise((resolve) => {
          const body: { [key: string]: any } = {
            org_id: 1,
            profile_id: profileId,
            // is_approved: true,
          }
          if (isApproved) {
            body.is_approved = isApproved
          }
          // console.log(`BODY `, body)
          fetchProfileDetails({
            body,
            // @ts-ignore
            successCallback: resolve,
            // @ts-ignore
            failedCallback: resolve,
          })
        })

    // console.log(`~newRes => `, newRes)
    // @ts-ignore ยังไม่ได้ประกาศ type res
    const responeProfile = _.isNil(newRes) ? res.org_member_info : newRes.org_member_info
    // console.log(`responeProfile `, responeProfile)
    let isHasImgDocument = false
    // console.log(`responeProfile => `, responeProfile)
    if (!_.isNil(responeProfile)) {
      const { profile } = responeProfile
      const profileImg = _.isNil(responeProfile.profile) ? null : responeProfile.profile.thumbnail_uri
      const mode = _.isNil(responeProfile) ? VIEW : VIEW
      const { addresses } = responeProfile.profile
      const loginEmail = _.isNil(responeProfile.login_email) ? null : responeProfile.login_email

      const referalReseller = responeProfile.referal_info
      const resellerInfo = responeProfile.reseller_info
      if (this.imgManagerRef && this.imgManagerRef.current) {
        this.imgManagerRef.current.loadImageUrls({
          p: [profileImg],
        })
      }
      let newResellertypeNumber = 0
      if (resellerInfo.reseller_type === 3) {
        newResellertypeNumber = 2
      }
      const contractImg = _.isNil(responeProfile.reseller_info.contract_img_uris) ? null : responeProfile.reseller_info.contract_img_uris
      const contractThumbnailImg = _.isNil(responeProfile.reseller_info.contract_img_uris)
        ? null
        : responeProfile.reseller_info.contract_img_uris
      if (!_.isNil(contractImg) && contractImg.length > 0) {
        isHasImgDocument = true
        if (this.imgManagerDocumentRef && this.imgManagerDocumentRef.current) {
          this.imgManagerDocumentRef.current.loadImageUrls({
            fc: contractImg,
            tc: contractThumbnailImg,
          })
        }
      }

      // ถ้า loginEmail มีต่าก็เก็บไว้
      if (!_.isNil(loginEmail)) {
        this.txtOldLoginEmail = loginEmail
      }

      await util.setStatePromise(this, {
        mode,
        addresses,
        profile,
        referalReseller,
        resellerInfo,
        loginEmail,
        isHasImgDocument,
        newResellertypeNumber,
      })
    }
  }

  _callbackResponseFromAddress = (adddresses: ISelectedAddresses[]) => {
    util.setStatePromise(this, {
      addresses: adddresses,
    })
  }

  _showConfirmDeleteAddress = (address: ISelectedAddresses) => {
    const { name } = address
    p.op.showConfirmation(
      ``,
      `คุณต้องการลบที่อยู่ ${name} ใช่หรือไม่`,
      () => this._deleteAddress(address),
      () => {},
      `ลบที่อยู่`,
      `ยกเลิก`
    )
  }

  _deleteAddress = async (address: ISelectedAddresses) => {
    const { deleteOrgAddress, selectedStore } = this.props
    const isStorePickMode = false
    const body: { [key: string]: any } = {
      address_id: address.id,
    }
    const orgz = selectedStore.get(`org`)
    const orgzToJS = orgz.toJS()
    body.org_id = orgzToJS[0].id
    const response = await new Promise((resolveSave) => {
      deleteOrgAddress({
        body,
        isStorePickMode,
        successCallback: resolveSave,
        failedCallback: () => resolveSave(null),
      })
    })
    if (response) {
      // @ts-ignore ยังไม่ได้ประกาศ type res
      this._callbackResponseFromAddress(response.addresses)
    }
  }

  _updateLoginEmail = async () => {
    const { selectedStore, updateLoginEmail } = this.props
    const { profile, loginEmail } = this.state

    const loginEmailTrim = loginEmail.trim()
    if (_.isNil(loginEmailTrim) || loginEmailTrim === '') {
      p.op.showConfirmationOkOnly(``, `กรุณากรอก Login Email ให้ครบถ้วน`)
      return
    }

    if (loginEmailTrim === this.txtOldLoginEmail) {
      p.op.showConfirmationOkOnly(``, `ยังไม่มีการแก้ไข`)
      return
    }

    const isConfirm = await new Promise<boolean>((resolve) => {
      p.op.showConfirmation(
        `${loginEmail}`,
        'คุณต้องการเปลี่ยน Login Email เป็นข้างต้นใช่หรือไม่',
        () => resolve(true),
        () => resolve(false)
      )
    })

    if (!isConfirm) {
      return
    }

    const org_id = util.getOrgId(selectedStore)
    const body: { [key: string]: any } = {
      profile_id: profile.id,
      org_id,
      email: loginEmailTrim,
    }

    const res: { login_email: string } = await new Promise((resolve) => {
      updateLoginEmail({
        body,
        // @ts-ignore
        successCallback: resolve,
        // @ts-ignore
        failedCallback: resolve,
      })
    })

    if (res.login_email) {
      await util.setStatePromise(this, {
        isEditLoginEmail: false,
        loginEmail: res.login_email,
      })
      this.txtOldLoginEmail = res.login_email
      p.op.showConfirmationOkOnly(``, `เปลี่ยน Login Email เรียบร้อยแล้ว`)
    }
  }

  _closeModalChangeDealerLoading = () => {
    if (this.state.isChangeDealerLoading) {
      return
    }
    util.setStatePromise(this, { isChangeDealerLoading: false })
  }

  _closeModalChangeMemberType = () => {
    if (this.state.isModelChangeMemberType) {
      return
    }
    util.setStatePromise(this, { isModelChangeMemberType: false })
  }

  _onPressToItem = (item: IReportMenuItem) => {
    if (_.isFunction(item.nav)) {
      item.nav()
    }
    // console.log(`item `, item)
  }

  _showConfirmationchangeParent = (responeFromJS) => {
    //   const respone = {
    //     new_parent_referal_info: {
    //         ResellerCode: "SS01-00000001",
    //         FirstName: "บริษัท แคนดี้ พลัส เทรดดิ้ง จำกัด",
    //         LastName: ""
    //     },
    //     total_reseller_nested_children: 172,
    // }
    const respone = responeFromJS.toJS()
    // console.log(`_showConfirmationchangeParent `, respone)
    const newParentInfo = respone.new_parent_referal_info
    const totalChildren = respone.total_reseller_nested_children
    const headerNote = 'ยืนยันการย้ายสาย'
    const textBody = `กดปุ่ม "ยืนยัน" เพื่อย้ายสายไปยังแม่ทีม ${newParentInfo.ResellerCode}\n
      ${newParentInfo.FirstName}\n
      มีลูกทีมทั้งหมด ${totalChildren} คน\n
      หมายเหตุ* เมื่อกด "ยืนยัน" ระบบจะทำการย้ายแม่ทีมให้ (อาจใช้เวลา 30 วินาทีต่อ 1 คนในสาย)`

    p.op.showConfirmation(
      headerNote,
      textBody,
      () => this._checkNewDealerCode(true),
      () => {},
      `ยืนยัน`,
      'ยกเลิก'
    )
  }

  _checkNewDealerCode = async (isConfirm?: boolean) => {
    const { newDealerCodeTxt, resellerInfo } = this.state
    if (newDealerCodeTxt.length !== 13) {
      p.op.showConfirmationOkOnly(``, `รหัสแม่ทีมไม่ถูกต้อง`)
      return
    }

    const { changeParent } = this.props
    const body: { [key: string]: any } = {
      to_be_transferred_reseller_code: resellerInfo.reseller_code,
      new_parent_reseller_code: newDealerCodeTxt.toLowerCase(),
      org_id: 1,
    }
    const isDealer = resellerInfo.reseller_type === 1
    if (isDealer) {
      body.new_reseller_type = this.state.newResellertypeNumber
    }
    await util.delay(100)
    if (isConfirm) {
      body.is_confirm = true
      await util.setStatePromise(this, { isChangeDealerLoading: true })
      await util.setStatePromise(this, { loadingText: `กำลังย้ายสาย... กรุณาอย่าปิดหน้าต่างนี้` })
      // await util.delay(4000)
      const res = await new Promise((resolve) => {
        changeParent({
          body,
          // @ts-ignore
          successCallback: resolve,
          // @ts-ignore
          failedCallback: resolve,
        })
      })
      // @ts-ignore
      if (res.code === 500 || res.error) {
        await util.setStatePromise(this, { isChangeDealerLoading: false })
        return
      }
      await this._setData(res)
      p.op.showConfirmationOkOnly(``, `ดำเนินการย้ายสายเรียบร้อยแล้ว`)
      await util.setStatePromise(this, {
        isChangeDealerLoading: false,
        newDealerCodeTxt: '',
        isShowInputNewDealerCode: false,
      })
    } else {
      // this._showConfirmationchangeParent(null)
      const res = await new Promise((resolve) => {
        changeParent({
          body,
          // @ts-ignore
          successCallback: resolve,
          // @ts-ignore
          failedCallback: resolve,
        })
      })
      const respone = fromJS(res)
      // console.log(`respone`, res)
      // console.log(`REF => `, respone)
      // @ts-ignore
      if (res.error) {
        return
      }
      // console.log(`RED `, respone.has(`before_change_parent_info`))
      if (respone.has(`before_change_parent_info`)) {
        this._showConfirmationchangeParent(respone.get(`before_change_parent_info`))
      }
    }
  }

  _changeResellerType = async () => {
    const { changeResellerType, selectedStore } = this.props
    const { resellerInfo, newResellertypeNumber } = this.state
    await util.setStatePromise(this, { isModelChangeMemberType: false })
    await util.setStatePromise(this, { loadingText: `กำลังเปลี่ยนประเภทตัวแทน... กรุณาอย่าปิดหน้าต่างนี้` })
    await util.setStatePromise(this, { isChangeDealerLoading: true })
    const body: { [key: string]: any } = {
      reseller_code: resellerInfo.reseller_code,
      new_reseller_type: newResellertypeNumber,
      org_id: util.getOrgId(selectedStore),
    }
    const res = await new Promise((resolve) => {
      changeResellerType({
        body,
        // @ts-ignore
        successCallback: resolve,
        // @ts-ignore
        failedCallback: resolve,
      })
    })
    // console.log(`RES `, res)
    // @ts-ignore
    if (res.code === 500 || res.error) {
      await util.setStatePromise(this, { isChangeDealerLoading: false })
      return
    }
    await this._setData(res)
    p.op.showConfirmationOkOnly(``, `ดำเนินการเปลี่ยนประเภทตัวแทนเรียบร้อยแล้ว`)
    await util.setStatePromise(this, { isChangeDealerLoading: false })
  }
}
