import React from 'react'
import _ from 'lodash'
import { diff } from 'deep-object-diff'
import { ListRenderItemInfo, FlatList } from 'react-native'
import Box from 'xui/components/Box'
import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import Center from 'xui/components/Center'
import Divider from 'xui/components/Divider'
import NB from 'x/config/nativebase'
import XContainer from 'xui/components/XContainer'
import OrderListHeaderTabs from 'xui/components/orders/OrderListHeaderTabs'
import OrderListItem from 'xui/components/orders/OrderListItem'

import {
  IOrderListViewProps,
  IOrderListViewState,
  IOrderListItem,
  IOrdersSortOptions,
  IOrdersFilter,
  IOrderListFilterTemplate,
  IOrderListFilterPreference,
} from 'x/index'

import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'
import * as NavActions from 'x/utils/navigation'
import XText from 'xui/components/XText'

import BaseOrderListView from 'x/modules/orders/BaseOrderListView'
// import OrderListFilterButton from 'xui/components/orders/OrderListFilterButton'

// import DrawerOrderListFilter from 'xui/components/DrawerOrderListFilter'
import XIconButton from 'xui/components/XIconButton'
import VisibilitySensor from 'xui/components/VisibilitySensor'
import XSpinner from 'xui/components/XSpinner'
import OrdersSortIconButton from 'xui/components/orders/OrdersSortIconButton'
import OrderListDisplayModeControl from 'xui/components/orders/OrderListDisplayModeControl'
import OrderListPaginator from 'xui/components/orders/OrderListPaginator'
import XBlankButton from 'xui/components/XBlankButton'
import XIcon from 'xui/components/XIcon'
import XButton from 'xui/components/XButton'
import { COLORS } from 'x/config/styles'
import FilterButton from 'xui/components/FilterButton'
import OrderListFilterTemplateSelector from 'xui/components/orders/OrderListFilterTemplateSelector'
// import XButton from 'xui/components/XButton'

class BaseUIOrderListView<P extends IOrderListViewProps, S extends IOrderListViewState> extends BaseOrderListView<P, S> {
  static displayName = 'BaseUIOrderListView'

  // drawerRef?: React.RefObject<DrawerOrderListFilter>

  // constructor(props) {
  //   super(props)

  //   this.drawerRef = React.createRef()
  // }

  _onPressOrderTabButton = async (newTabKey: string) => {
    // console.log('_onPressOrderTabButton newTabKey => ', newTabKey)
    if (this.isRefreshing) {
      // console.log('_onPressOrderTabButton still refreshing!!')
      return
    }
    const { tabName, displayMode } = this.state
    // console.log('_onPressOrderTabButton tabName => ', tabName)

    let forceRefresh = false

    if (tabName && tabName === newTabKey) {
      forceRefresh = true
    } else {
      await util.setStatePromise(this, {
        tabName: newTabKey,
        ordersCurrentPage: 1,
        displayMode: this.isNoSummaryCountTabKey(newTabKey) ? 'list' : displayMode,
      })

      const orderCount = this.getFetchedOrdersCount(newTabKey)
      const hasMoreOrders = this.getHasMoreOrders(newTabKey)
      forceRefresh = orderCount === 0 && hasMoreOrders
    }
    if (this.isOrdersDisplayPageMode()) {
      forceRefresh = true
    }

    if (forceRefresh) {
      this.isRefreshing = false
      this.isLoadingMore = false
      // const focusOrders = this.getOrders(newTabKey)
      // if (!focusOrders || focusOrders.length === 0) {
      await this.loadInitOrders(newTabKey)
      // }
    }
  }

  getIsGoBackButtonHidden = () => {
    const { navigation } = this.props
    return util.getNavParam(this.props, 'isGoBackButtonHidden', false)
  }

  renderOrderListItem = (listItemInfo: ListRenderItemInfo<IOrderListItem>) => {
    const { selectedStore: ss } = this.props
    const { isCheckable, tabName } = this.state
    const { item: od, index: odIdx } = listItemInfo
    return (
      <OrderListItem
        key={`${tabName}_${odIdx}_${od.id}`}
        isCheckable={isCheckable}
        checked={this.isOrderListItemChecked(od.id)}
        onCheckedPress={isCheckable ? this.toggleCheckOrderListItem : undefined}
        selectedStore={ss}
        data={od}
        index={odIdx}
        onPress={this._onPressOrderListItem}
      />
    )
  }

  renderOrderListFooter = () => {
    const { isOrdersLoadingMore, tabName } = this.state
    const currentOrderCount = this.getFetchedOrdersCount(tabName)
    const maxOrderCount = this.getSummaryOrdersCount(tabName)

    const hasNoMoreOrders = this.getHasNoMoreOrders(tabName)

    if (hasNoMoreOrders && currentOrderCount === 0) {
      const orderLabel = this.getTabLabel(tabName)
      return (
        <Center py='4'>
          <XText variant='inactive'>{`ไม่มีออเดอร์ในรายการ "${orderLabel}"`}</XText>
        </Center>
      )
    }

    if (this.isOrdersDisplayPageMode()) {
      return null
    }

    if (hasNoMoreOrders || (currentOrderCount && maxOrderCount && currentOrderCount >= maxOrderCount)) {
      return (
        <Center py='2'>
          <XText variant='inactive'>สิ้นสุดรายการ</XText>
        </Center>
      )
    }

    const isLoadMoreVisible =
      (currentOrderCount && !maxOrderCount) || (currentOrderCount && maxOrderCount && currentOrderCount < maxOrderCount)

    if (isLoadMoreVisible && !isOrdersLoadingMore) {
      return (
        <VisibilitySensor onChange={this.handleLoadMoreButtonVisibility}>
          <XBlankButton
            my='2'
            variant='outline'
            _light={{ bg: NB.C.L.BG.CLEAR }}
            _dark={{ bg: NB.C.D.BG.CLEAR }}
            onPress={() => this.loadMoreOrders(tabName)}>
            <XIcon
              // my='2'
              family='FontAwesome'
              name='angle-double-down'
              // variant='outline'
              // onPress={() => this.loadMoreOrders(tabName)}
            />

            {/* <XButton mt='2' isDisabled={isOrdersLoadingMore} isLoading={isOrdersLoadingMore} onPress={() => this.loadMoreOrders(tabName)}>
          {isOrdersLoadingMore ? null : 'โหลดเพิ่มเติม'}
        </XButton> */}
          </XBlankButton>
        </VisibilitySensor>
      )
    }

    if (isOrdersLoadingMore) {
      return <XSpinner variant='inactive' my='2' />
    }

    return null
  }

  // onOrderListScroll = (evt) => {
  //   console.log('onOrderListScroll evt.nativeEvent => ', evt.nativeEvent)
  // }

  renderOrderListItems = () => {
    // const { tabName, isOrdersRefreshing } = this.state
    // const { orders, selectedStore } = this.props
    const { tabName, isOrdersRefreshing, isInitialized } = this.state
    if (!isInitialized) {
      return null
    }

    const orders = this.getOrders(tabName)
    // console.log('renderOrderListItems orders => ', orders)
    // if (!orders.has(tabName)) {
    if (
      (!orders || (orders.length === 0 && this.getHasMoreOrders(tabName))) &&
      !isOrdersRefreshing
      // && !this.isOrdersDisplayPageMode()
    ) {
      return (
        <VisibilitySensor
          key={`${tabName}`}
          checkInvisibleAtSecond={1}
          onCheckInvisibleAtSecond={() => {
            this.loadInitOrders(tabName)
          }}
          onChange={(visible) => {
            // console.log('VisibilitySensor visible => ', visible)
            if (visible) {
              // this._loadInitOrders(tabName)
              this.loadInitOrders(tabName)
            }
          }}>
          <XSpinner variant='inactive' my='2' />
        </VisibilitySensor>
      )
    }

    if (!orders) {
      return null
    }

    if (isOrdersRefreshing) {
      return <XSpinner variant='inactive' my='2' />
    }

    return (
      <FlatList<IOrderListItem>
        refreshing={isOrdersRefreshing}
        onRefresh={() => this.loadInitOrders(tabName)}
        // style={{ flex: 1, paddingVertical: 4, paddingHorizontal: 8, backgroundColor: COLORS.BG_LIGHT_GREY }}
        style={{ backgroundColor: COLORS.BG_LIGHT_GREY }}
        contentContainerStyle={{ paddingVertical: 4, paddingHorizontal: 8 }}
        // flex={1}
        // // _web={{ p: '1' }}
        // px='1'
        // py='2'
        // bg='gray.300'
        // // _contentContainerStyle={{
        // //   bg: 'gray.400',
        // // }}
        key={tabName}
        // data={orders.get(tabName).toArray()}
        data={orders}
        renderItem={this.renderOrderListItem}
        onEndReached={this.onOrderListScrollReached}
        onEndReachedThreshold={0.2}
        // onScroll={this.onOrderListScroll}
        // onMomentumScrollEnd={this.onOrderListScroll}
        // scrollEventThrottle={4}
        ListFooterComponent={this.renderOrderListFooter()}
        ItemSeparatorComponent={this.renderItemSeparatorComponent}
        // keyExtractor={(item, idx) => `${tabName}_${item.get('id')}_${idx.toString()}`}
        keyExtractor={this.keyExtractor}
      />
    )
  }

  keyExtractor = (item, idx) => `${this.state.tabName}_${item.id}_${idx.toString()}`

  renderItemSeparatorComponent = () => <Box h='2' />

  // onApplyOrderListFilter = async (newSetting: { isCOD?: boolean; isShipBeforePay?: boolean }) => {
  //   // console.log('_onApplyFilter newSetting => ', newSetting)
  //   // this._closeDrawer()
  //   // const { tabName } = this.state
  //   const { filterSetting, orderListStateActions } = this.props
  //   if (Map.isMap(filterSetting)) {
  //     const oldSetting = filterSetting.toJS()
  //     const diffSetting = diff(oldSetting, newSetting)
  //     if (!_.isEmpty(diffSetting)) {
  //       // orderListStateActions.setOrdersFilterSetting({ ...this.state.defaultFilterSetting, ...newSetting })
  //       // @ts-ignore
  //       orderListStateActions.setOrdersFilterSetting(newSetting)
  //       await util.delay(200)
  //       // orderListStateActions.setIsInitedByTabKey(this.tabName, false)

  //       // // ถ้ามีการใช้ page mode
  //       // if (this._isOrdersDisplayPageMode()) {
  //       //   await this._onSubmitOrdersNewPage(1)
  //       //   return
  //       // }

  //       await this.loadInitOrders(this.state.tabName)
  //     }
  //   }
  // }

  // onApplyOrderListFilter = async (newSetting: Partial<IFetchOrdersRequestBody>) => {
  onApplyOrderListFilter = async (newSetting: IOrdersFilter) => {
    const { tabName, ordersFilterSetting: oldSetting } = this.state
    // console.log('onApplyOrderListFilter newSetting => ', newSetting)

    const diffSetting = diff(oldSetting, newSetting)
    if (!_.isEmpty(diffSetting)) {
      await util.setStatePromise(this, { ordersFilterSetting: newSetting, ordersCurrentPage: 1 })
      await this.clearOrdersAllTabs()
      await util.delay(200)
      await this.loadInitOrders(tabName)
    }

    return true
  }

  handleLoadMoreButtonVisibility = (visible) => {
    // handle visibility change
    // console.log('handleLoadMoreButtonVisibility => ', visible)
    if (!visible || this.isOrdersDisplayPageMode()) {
      return
    }
    const { isOrdersLoadingMore, tabName } = this.state

    if (!isOrdersLoadingMore) {
      this.loadMoreOrders(tabName)
    }
  }

  // _closeDrawer = () => {
  //   const drawerRef = this.drawerRef.current
  //   if (drawerRef && drawerRef && _.isFunction(drawerRef.close)) {
  //     drawerRef.close()
  //   }
  // }

  // _openDrawer = () => {
  //   const drawerRef = this.drawerRef.current
  //   if (drawerRef && drawerRef && _.isFunction(drawerRef.open)) {
  //     drawerRef.open()
  //   }
  // }

  onSortOptionsApply = async (sortOptions: IOrdersSortOptions) => {
    // console.log('OrdersSortIconButton onApply sortOptions => ', sortOptions)
    await util.setStatePromise(this, { sortOptions })
    await util.delay(200)

    // if (this._isOrdersDisplayPageMode() && this.state.ordersCurrentPage > 1) {
    //   await util.setStatePromise(this as any, { ordersCurrentPage: 1 })
    //   await util.delay(200)
    // }

    await this.loadInitOrders(this.state.tabName)
    // await util.delay(200)
  }

  _onSortOptionsApplyWithSaveState = async (sortOptions: IOrdersSortOptions) => {
    await this.onSortOptionsApply(sortOptions)
    // await util.saveDefaultCurrentViewState(this as any, this._getDefaultStateSavedKey(), { sortOptions })
  }

  renderSortByIcon = () => (
    <OrdersSortIconButton
      // btnStyle={{ marginRight: 4 }}
      currentSortOptions={this.state.sortOptions}
      onApply={this._onSortOptionsApplyWithSaveState}
    />
  )

  _navToOrderListFilterView = () => {
    const { navigation } = this.props
    const { ordersDefaultFilterSetting, ordersFilterSetting } = this.state

    navigation.dispatch(
      NavActions.navToOrderListFilterView({
        store_id: util.getNavParam(this.props, 'store_id'),
        defaultSetting: ordersDefaultFilterSetting,
        appliedSetting: ordersFilterSetting,
        onApplyFilter: this.onApplyOrderListFilter,
      })
    )
  }

  isFiltered = () => {
    const { ordersDefaultFilterSetting, ordersFilterSetting } = this.state
    const diffFilter: Partial<IOrderListFilterTemplate> = diff(ordersDefaultFilterSetting, ordersFilterSetting)

    // console.log('isFiltered diffFilter => ', diffFilter)
    if (
      ordersFilterSetting.autoCancelOrderDateRangeOptionKey &&
      !diffFilter.autoCancelOrderDateRangeOptionKey &&
      ordersFilterSetting.autoCancelOrderDateRangeOptionKey !== 'Custom'
    ) {
      delete diffFilter.autoCancelOrderFrom
      delete diffFilter.autoCancelOrderTo
    }

    if (
      ordersFilterSetting.createOrderDateRangeOptionKey &&
      !diffFilter.createOrderDateRangeOptionKey &&
      ordersFilterSetting.createOrderDateRangeOptionKey !== 'Custom'
    ) {
      delete diffFilter.createOrderFrom
      delete diffFilter.createOrderTo
    }

    if (
      ordersFilterSetting.dateDeliveryDateRangeOptionKey &&
      !diffFilter.dateDeliveryDateRangeOptionKey &&
      ordersFilterSetting.dateDeliveryDateRangeOptionKey !== 'Custom'
    ) {
      delete diffFilter.dateDeliveryFrom
      delete diffFilter.dateDeliveryTo
    }

    if (
      ordersFilterSetting.paymentCompletedAtDateRangeOptionKey &&
      !diffFilter.paymentCompletedAtDateRangeOptionKey &&
      ordersFilterSetting.paymentCompletedAtDateRangeOptionKey !== 'Custom'
    ) {
      delete diffFilter.paymentCompletedAtFrom
      delete diffFilter.paymentCompletedAtTo
    }

    if (
      ordersFilterSetting.readyToShipDateRangeOptionKey &&
      !diffFilter.readyToShipDateRangeOptionKey &&
      ordersFilterSetting.readyToShipDateRangeOptionKey !== 'Custom'
    ) {
      delete diffFilter.readyToShipDateFrom
      delete diffFilter.readyToShipDateTo
    }

    if (
      ordersFilterSetting.shippingCompletedAtDateRangeOptionKey &&
      !diffFilter.shippingCompletedAtDateRangeOptionKey &&
      ordersFilterSetting.shippingCompletedAtDateRangeOptionKey !== 'Custom'
    ) {
      delete diffFilter.shippingCompletedAtFrom
      delete diffFilter.shippingCompletedAtTo
    }

    return diffFilter && !_.isEmpty(diffFilter)
  }

  renderFilterIcon = () => {
    // const { ordersDefaultFilterSetting, ordersFilterSetting } = this.state
    // return (
    //   <OrderListFilterButton
    //     // forceMkpOnly={this.isForceMkpOnly()}
    //     defaultSetting={ordersDefaultFilterSetting}
    //     appliedSetting={ordersFilterSetting}
    //     onApplyFilter={this.onApplyOrderListFilter}
    //   />
    // )
    const isFiltered = this.isFiltered()
    return <FilterButton isFiltered={isFiltered} onPress={this._navToOrderListFilterView} />
  }

  // onOrderListFilterTemplateSelect = (nTmpl) => {
  //   const { ordersDefaultFilterSetting } = this.state

  //   if (nTmpl) {
  //     this.onApplyOrderListFilter(nTmpl)
  //     return
  //   }

  //   this.onApplyOrderListFilter(ordersDefaultFilterSetting)
  // }

  onOrderListFilterTemplateSelect = (nTmpl: IOrderListFilterTemplate) => {
    const { ordersDefaultFilterSetting } = this.state
    if (nTmpl && !_.isEmpty(nTmpl)) {
      this.onApplyOrderListFilter(nTmpl)
    } else {
      this.onApplyOrderListFilter(ordersDefaultFilterSetting)
    }
  }

  onOrderListFilterTemplateSaveSuccess = async (newPref: IOrderListFilterPreference, savedTemplate?: IOrderListFilterTemplate) => {
    const { ordersDefaultFilterSetting, ordersFilterSetting } = this.state

    if ('id' in ordersFilterSetting) {
      const { templates = [] } = newPref
      const foundIndex = templates.findIndex((tmpl) => tmpl.id === ordersFilterSetting.id)
      if (foundIndex < 0) {
        this.onApplyOrderListFilter(ordersDefaultFilterSetting)
      }
    }
  }

  renderFilterTemplateIcon = () => {
    const { ordersFilterSetting, isSubmitting, isOrdersRefreshing, isOrdersLoadingMore } = this.state

    return (
      <OrderListFilterTemplateSelector
        navigation={this.props.navigation}
        disabled={isSubmitting || isOrdersRefreshing || isOrdersLoadingMore}
        type='icon'
        selectedTemplate={ordersFilterSetting as IOrderListFilterTemplate}
        onSelect={this.onOrderListFilterTemplateSelect}
        onSaveSuccess={this.onOrderListFilterTemplateSaveSuccess}
      />
    )
  }

  goBack = () => {
    util.navGoBack(this.props)
  }

  renderViewHeader = () => (
    <HStack w='full' minH='10' alignItems='center'>
      <HStack minW='10' px='1'>
        {this.getIsGoBackButtonHidden() ? null : <XIconButton name='arrow-back' onPress={this.goBack} />}
      </HStack>
      <VStack flex={1} justifyContent='center' flexWrap='wrap'>
        <XText>{this.getHeaderTitle()}</XText>
      </VStack>
      <HStack minW='10' px='1' alignItems='center' justifyContent='flex-end' space='1'>
        {/* {this.renderDisplayModeControl()} */}
        {this.renderSortByIcon()}
        {this.renderFilterTemplateIcon()}
        {this.renderFilterIcon()}
      </HStack>
    </HStack>
  )

  renderOrderListTabsHeader = () => {
    const { availableTabKeys, isOrdersRefreshing, ordersCountSummary } = this.state
    if (!availableTabKeys || availableTabKeys.length <= 1) {
      return null
    }
    return (
      <OrderListHeaderTabs
        availableTabKeys={availableTabKeys}
        // disabled={isOrdersRefreshing || isOrdersLoadingMore}
        disabled={isOrdersRefreshing}
        onChangeTab={this._onPressOrderTabButton}
        // orderCountSummaryMap={this.props.summary ? this.props.summary.toJS() : {}}
        orderCountSummaryMap={ordersCountSummary}
      />
    )
  }

  renderDisplayModeControl = () => {
    const { tabName, displayMode = 'list', displayPageLimit } = this.state
    // const idx = displayMode === 'page' ? 1 : 0
    const disabled = this.isNoSummaryCountTabKey(tabName) || false
    return (
      <OrderListDisplayModeControl
        key={displayMode}
        disabled={disabled}
        // initialIndex={idx}
        initialMode={displayMode}
        initialPageLimit={displayPageLimit}
        // onChange={this._onChangeDisplayMode}
        onSubmit={this._onChangeDisplayMode}
      />
    )
  }

  _onChangeDisplayMode = ({ displayMode, pageLimit }) => {
    this.setState({ displayMode, displayPageLimit: pageLimit, ordersCurrentPage: 1 }, () => {
      const { tabName } = this.state
      this.loadInitOrders(tabName)
      // @ts-ignore
      if (displayMode === 'page' && _.isNumber(pageLimit) && _.includes([10, 30, 50], parseInt(pageLimit))) {
        p.op.storageSet('ordersDisplayPageLimit', pageLimit, false)
      }
    })
  }

  // _onChangeDisplayMode = (opt, idx) => {
  //   this.setState({ displayMode: opt.value, ordersCurrentPage: 1 }, () => {
  //     const { tabName } = this.state
  //     this.loadInitOrders(tabName)
  //     // if (idx === 1) {
  //     //   // page mode
  //     // } else {
  //     //   // list mode (idx=0)
  //     // }
  //   })
  // }

  renderOrderPaginator = () => {
    const isPageMode = this.isOrdersDisplayPageMode()
    if (!isPageMode) {
      return null
    }
    const { tabName, isOrdersRefreshing, ordersCurrentPage } = this.state

    return (
      <VStack minW='140px' alignItems='center' justifyContent='center'>
        <OrderListPaginator
          disabled={isOrdersRefreshing}
          currentPage={ordersCurrentPage}
          maxPage={this.getOrdersMaxPage(tabName)}
          onChangePage={this._onSubmitOrdersNewPage}
          onPressPrevious={this._onSubmitOrdersNewPage}
          onPressNext={this._onSubmitOrdersNewPage}
        />
      </VStack>
    )
  }

  renderCheckAllButton = () => {
    const { isCheckable, tabName, isInitialized, isOrdersRefreshing } = this.state
    if (!isCheckable || !isInitialized || isOrdersRefreshing) {
      return <Box w='10' />
    }
    const checked = this.isOrderListItemsAllChecked(tabName)
    return (
      // <XBlankButton h='full' variant='ghost' >
      <XIconButton
        onPress={this.toggleCheckAllOrderListItems}
        // family='FontAwesome'
        // name={checked ? 'check-circle' : 'circle-o'}
        family={checked ? 'Ionicons' : 'FontAwesome'}
        variant={checked ? 'success' : 'inactive'}
        name={checked ? 'checkmark-done-circle' : 'circle-o'}
      />
      // </XBlankButton>
    )
  }

  // renderPaginatorSettings = () => (
  //   <HStack>
  //     <XIconButton name='settings' />
  //     <XOverlay />
  //   </HStack>
  // )

  _onSubmitOrdersNewPage = async (newPage: number) => {
    // this._onPressUncheckAll()
    // await util.delay(100)
    await util.setStatePromise(this, { ordersCurrentPage: newPage })

    await util.delay(100)
    const { tabName } = this.state
    await this.loadInitOrders(tabName)
    // const forceOffset = (newPage - 1) * 30
    // this.loadInitOrders(tabName, { overrideRequestBody: { offset: forceOffset, limit: 30 } })
  }

  renderOrdersCheckInfo = () => {
    const { isCheckable, availableTabKeys, tabName, ordersCheckedMap = {} } = this.state
    if (!isCheckable) {
      return null
    }
    // const isMultipleTabs = availableTabKeys.length > 1
    // const inTabCount = this.getCheckedOrdersInTabCount(tabName)
    // const tabLabel = this.getTabLabel(tabName)
    const allTabCount = this.getCheckedOrdersAllTabCount()
    const isUncheckAllAvailable = this.getCheckedOrderCount() > 0
    return (
      <HStack flex={1} flexWrap='wrap' alignItems='center'>
        {/* <XText>{`เลือกทั้งหมด ${allTabCount}`}</XText> */}
        {/* {isMultipleTabs && allTabCount !== inTabCount ? <XText>{`เลือก ${inTabCount} ใน${tabLabel}`}</XText> : null} */}
        <XText>{`เลือกแล้ว ${allTabCount}`}</XText>
        {isUncheckAllAvailable ? (
          // <HStack>
          <XIconButton
            size='xs'
            name='close-circle'
            iconVariant='danger'
            _icon={{ size: 'xs' }}
            rounded='full'
            onPress={this.uncheckCheckAllOrderListItems}
          />
        ) : //  </HStack>
        null}
      </HStack>
    )
  }

  renderPaginationBar = () => {
    const { isCheckable, isInitialized, isOrdersRefreshing } = this.state
    if (!isInitialized) {
      return null
    }
    return (
      <HStack w='full' minH='9' maxH='16' p='2' alignItems='center'>
        {this.renderCheckAllButton()}
        {isCheckable ? <Divider h='full' mr='1' orientation='vertical' /> : null}
        <HStack flex={1} px='1' space='1' alignItems='center' flexWrap='wrap'>
          {this.renderOrdersCheckInfo()}
          {this.renderOrderPaginator()}
        </HStack>
        <Divider h='full' ml='1' orientation='vertical' />
        {/* {this.renderPaginatorSettings()} */}
        {this.renderDisplayModeControl()}
      </HStack>
    )
  }

  renderSubmitCheckOrdersButton = () => {
    const { isCheckable, isInitialized } = this.state
    if (!isCheckable || !isInitialized) {
      return null
    }
    return (
      <VStack w='full' minH='12' maxH='16' px='1' pb='1'>
        <XButton
          w='full'
          size={this.isSubmitCheckOrdersButtonDisabled() ? 'sm' : 'md'}
          minH='10'
          _stack={{ alignItems: 'center' }}
          _text={{ textAlign: 'center' }}
          colorScheme={this.isSubmitCheckOrdersButtonDisabled() ? 'muted' : 'primary'}
          disabled={this.isSubmitCheckOrdersButtonDisabled()}
          // isDisabled={this.isSubmitCheckOrdersButtonDisabled()}
          onPress={this._onPressSubmitCheckedOrders}>
          {this.getSubmitCheckOrdersButtonText()}
        </XButton>
      </VStack>
    )
  }

  renderExtraHeader = (): JSX.Element => null

  renderMain = () => (
    <XContainer>
      {/* <XButton
        onPress={() =>
          this.props.navigation.push('OrderListView', {
            store_id: 3,
            availableTabKeys: ['myTasks_confirmOrder'],
            onPressOrderListItem: (od) => p.op.alert(JSON.stringify(od)),
          })
        }>
        Test Nav
      </XButton> */}
      {this.renderViewHeader()}
      {this.renderExtraHeader()}
      {/* {this.renderDisplayModeControl()} */}
      {this.renderOrderListTabsHeader()}
      <VStack flex={1}>{this.renderOrderListItems()}</VStack>
      {this.renderPaginationBar()}
      {this.renderSubmitCheckOrdersButton()}
    </XContainer>
  )

  render() {
    const { isInitialized } = this.state
    if (!isInitialized) {
      return null
    }
    return this.renderMain()
  }
}

export default BaseUIOrderListView

// export default connect(
//   (state) => ({
//     ...getState(state),
//   }),
//   (dispatch) => ({
//     ...getDispatch(dispatch),
//   })
// )(BaseUIOrderListView)
