import React from 'react'

import { COLORS } from 'x/config/styles'
import p from 'x/config/platform-specific'
// import ReportProductDailySales from '../report/ReportProductDailySales'
// import ReportPayment from '../report/ReportPayment'
// import ReportHelperSales from '../report/ReportHelperSales'
import BaseImportExcelListView from 'x/modules/importExcel/BaseImportExcelListView'
import XCustomHeader from 'xui/components/XCustomHeader'
import XMenuListView from 'xui/components/XMenuListView'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import * as util from 'x/utils/util'

export default abstract class BaseUIImportExcelListView extends BaseImportExcelListView {
  // abstract renderReportProductDailySales: () => void

  renderCustomHeader = () => {
    // const { navigation } = this.props
    // const { mode } = this.state
    // const isEditMode = mode === EDIT
    const leftBtn = { backIcon: !p.op.isWeb(), onPressItem: () => this._goBack() }
    // if (isEditMode) {
    //   // @ts-ignore
    //   leftBtn = { label: `บันทึก`, onPressItem: () => util.setStatePromise(this, { mode: VIEW }) }
    // }
    return (
      <XCustomHeader
        title='นำเข้าข้อมูล'
        headerLeftProps={leftBtn}
        // headerRightProps={{ label: `แก้ไข`, onPressItem: () => util.setStatePromise(this, { mode: EDIT }) }}
      />
    )
  }

  _goBack = () => {
    util.navGoBack(this.props)
  }

  render() {
    // console.log(` RENDER !! `, this.menuItems)
    return (
      <XContainer>
        {this.renderCustomHeader()}
        <XContent style={{ backgroundColor: COLORS.WHITE }}>
          <XMenuListView menuItems={this.MENU_ITRMS} />
        </XContent>
      </XContainer>
    )
  }
}
