import React from 'react'
import { View } from 'react-native'
import BaseUIPrintShippingLabelView from 'xui/modules/print/BaseUIPrintShippingLabelView'
import XDateRangePicker from 'xui/components/XDateRangePicker'
import ThemeSizeContext from '../../context/ThemeSizeContext'
import { downloadFileFromUrl } from '../../utils/util-web'
// const logoPaperang = require('../../../images/logo_paperang.png')

export default class PrintShippingLabelView extends BaseUIPrintShippingLabelView {
  static displayName = 'PrintShippingLabelView'

  static contextType = ThemeSizeContext

  context!: React.ContextType<typeof ThemeSizeContext>

  onOpenPDFFromUrl = async (url: string, fileName: string): Promise<void> => {
    await downloadFileFromUrl({ url, fileName, timeout: 120000 })
  }

  onOpenPaperangFromUrl = (url: string) => {
    console.log('No.Impl:: onOpenPaperangFromUrl url => ', url)
    // const { selectedStore } = this.props
    // const paperangAutoSave = xUtil.checkPaperangAutoSaveImg(selectedStore)
    // this.props.navigation.dispatch(
    //   NavActions.navToPaperangView({
    //     imageURL: url,
    //     noAutoSaveImgAtHeightBelow: xCONS.NO_AUTO_SAVE_IMG_AT_HEIGHT_BELOW_SHIPING_LABEL.COVER_SHEET,
    //     disabledAutoSave: paperangAutoSave,
    //   })
    // )
  }

  renderDateTimePicker() {
    // return <div>No.Impl:: renderDateTimePicker</div>
    // const date = mode === this.START_DATE ? this.state.startDate : this.state.endDate
    // const tDate = date.format('DD/MM/YYYY')
    // const tTime = date.format('HH:mm')
    const { startDate, endDate } = this.state
    return (
      <View style={{ width: '100%' }}>
        <XDateRangePicker
          // allowOnlyDaysFromToday={31}
          selectedDates={{ begin: startDate, end: endDate }}
          onChangeDate={(newDates) => this._onDateChange(newDates)}
          showTime
        />
      </View>
    )
  }

  render() {
    return this.renderMain()
    // const { contentHeightStyle, contentOneOfThreeColumnWidthStyle } = this.context
    // return (
    //   <View style={{ flex: 1, backgroundColor: 'grey' }}>
    //     <View style={[contentHeightStyle, contentOneOfThreeColumnWidthStyle]}>{this.renderMain()}</View>
    //   </View>
    // )
  }
}
