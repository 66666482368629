import { Component } from 'react'
import _ from 'lodash'
import { Map, List } from 'immutable'
import { Image, Platform } from 'react-native'
import { log, setStatePromise, deleteImmutableListAtIndex, isBase64DataURL, delay, prependHttpsIfNotExists } from '../utils/util'
// import { Storage } from 'aws-amplify'
import { getTimeString, getRandomString } from '../utils/file'
import mimeUtil from '../utils/mime-types'
import p from '../config/platform-specific'
import { uploadImageToSpaces } from '../utils/upload'
import { IImgManagerProps, IImgManagerState, IXImage, IImgFile, IImgOutput, IImgProgress } from '../index'

// const StorageOptions = {
//   // bucket: settings.s3.options.bucket,
//   // region: settings.s3.options.region,
//   contentType: 'image/jpg',
//   // level: 'public',
// }
//
// const ImagePrefix = settings.s3.options.keyPrefix
// Storage.configure({
//   bucket: settings.s3.options.keyPrefix,
//   level: 'public',
//   customPrefix: {
//     public: settings.s3.options.keyPrefix
//   },
// })

// const IMG_SIZE_TINY =  {
//   key: 'y',
//   width: 2000,
//   height: 1000,
// }
//
// const IMG_PRODUCT_SIZES = [
// IMG_SIZE_TINY,
// ]
//
// const IMG_SIZE_KEY_API_MAPPINGS = {
// 'y': 'tiny'
// }
// var metadata = {
//   {
//   key: 'y',
//     width: 2000,
//     height: 1000,
//   },
//   image_uris: []
//   thumbnail_uris: []
//
//   y: {
//
//   }
// }
// imgMgr.urls = {
// image_uris:['https://aws..',]
// y:'https://aws..'
// }

// enum xImgStatus {
//   INIT = 'init',
//   CONVERTING = 'converting',
//   CONVERTED = 'converted', // deprecated
//   UPLOADING = 'uploading',
//   UPLOADED = 'uploaded',
//   FAILED = 'failed',
// }

export default abstract class BaseImgManager extends Component<IImgManagerProps, IImgManagerState> {
  static displayName = 'BaseImgManager'

  static defaultProps = {
    metadata: [
      // { key: 'p', width: 900, height: 1947 }, // *2.164 for iphone X
      { key: 'p', width: 1800, height: 3894 }, // *2.164 for iphone X
      // { key: 't', width: 240, height: 519 },
      { key: 't', width: 400, height: 866 },
      { key: 'y', width: 128, height: 277 },
      // { key: 'y', width: 128, height: 128, fixedSize: true },
    ],
    // metadata: [
    //   { key: 'p', width: 1200, height: 2400 },
    //   { key: 't',  width: 400, height: 800 },
    //   { key: 'y',  width: 128, height: 128 },
    // ],
    readonly: false,
    maxLength: 15,
    uploading: false,
    showUploaded: false,
  }

  _inUploading: boolean

  isImageHoversTemplate?: boolean[]

  INTL_STYLES?: { [key: string]: any }

  // Native/Web Resize Image function
  abstract _getResizedImage(image: IImgFile, newWidth: number, newHeight: number): Promise<IImgFile | null>

  abstract _getBase64fromImageURL(imgURL: string): Promise<string>

  abstract getImageSizeFromUrl(imgUrl: string): Promise<{ width?: number; height?: number }>

  abstract _constructorExtended?(that: any, props: IImgManagerProps): void

  xImageTemplate: IXImage

  constructor(props: IImgManagerProps) {
    super(props)

    const isImageHovers = []
    for (let i = 0; i < props.maxLength; i++) {
      isImageHovers.push(false)
    }
    this._inUploading = false
    this.isImageHoversTemplate = isImageHovers

    this.state = {
      originalImages: List([]),
      xImages: List([]),
      // uploaded: false,
      // uploading: false,
      dropzoneActive: false,
      isImageHovers,
      previewImages: null,
      isVisiblePreview: false,
      isVisibleSortModal: false,
      isMovingSortItem: false,
    }

    // initialize xImgage template with keys
    // let initKeys = ['image', 'thumbnail', 'tiny']
    // if (props.imgKeys && _.isArray(props.imgKeys) && _.every(props.imgKeys, _.isString)) {
    //   initKeys = props.imgKeys
    // }

    // @ts-ignore O: ไม่รู้จะแก้ยัีงไงเหมือนกันครับ
    this.xImageTemplate = Map({
      imgs: Map({}),
      urls: Map({}),
      progresses: Map({}),
      // statuses: Map({}),
      progress: 0,
      commonStampName: null,
      commonRandomName: null,
    })

    for (let i = 0; i < props.metadata.length; i++) {
      const { key } = props.metadata[i]
      this.xImageTemplate = this.xImageTemplate.setIn(['imgs', key], null)
      this.xImageTemplate = this.xImageTemplate.setIn(['urls', key], null)
      this.xImageTemplate = this.xImageTemplate.setIn(['progresses', key], 0)
      // this.xImageTemplate = this.xImageTemplate.setIn(['statuses', key], xImgStatus.INIT)
    }

    const x = this.xImageTemplate.toJS()
    // this.loadImageUrls = this.loadImageUrls.bind(this)
    // this.clearImages = this.clearImages.bind(this)
    // this.addNewImages = this.addNewImages.bind(this)
    // this.replaceNewImageAtIndex = this.replaceNewImageAtIndex.bind(this)
    // this.deleteImageAtIndex = this.deleteImageAtIndex.bind(this)
    // this.submitUploadImages = this.submitUploadImages.bind(this)
    this._getResizedImage = this._getResizedImage.bind(this)
    this._getBase64fromImageURL = this._getBase64fromImageURL.bind(this)
    this.getImageSizeFromUrl = this.getImageSizeFromUrl.bind(this)

    if (_.isFunction(this._constructorExtended)) {
      this._constructorExtended = this._constructorExtended.bind(this)
      this._constructorExtended(this, props)
    }
  }

  async componentDidMount() {
    const { initialImageUrls } = this.props

    if (!_.isNil(initialImageUrls)) {
      await this.loadImageUrls(initialImageUrls)
    }

    await delay(500)
    this.setState({ isInitialized: true })
  }

  public componentDidUpdate(prevProps, prevState) {
    const { onProgressUpdate } = this.props
    if (_.isFunction(onProgressUpdate) && (this.props.uploading || (prevProps.uploading && !this.props.uploading))) {
      const totalProgress = this.getImgTotalProgress()
      onProgressUpdate(totalProgress)
    }

    if (this.state.xImages.size !== this.state.originalImages.size) {
      this._syncOriImageToXImage()
    }

    if (this.props.uploading !== prevProps.uploading && this.props.uploading) {
      this.submitUploadImages()
    }

    // if (_.isFunction(onChangeImages)) {
    //   if (prevState.originalImages !== this.state.originalImages) {
    //     onChangeImages({ originalImages: this.state.originalImages, xImages: this.state.xImages })
    //   }
    // }
  }

  _syncOriImageToXImage = async (): Promise<null> => {
    // await doSomethingAndDelay(() => alert('In _syncOriImageToXImage'), 3000)

    const { originalImages, xImages } = this.state
    if (originalImages.size === xImages.size) {
      return
    }

    if (originalImages.size > xImages.size) {
      let newOriImages = List([])
      for (let i = 0; i < xImages.size; i++) {
        newOriImages = newOriImages.set(i, originalImages.get(i))
      }
      await setStatePromise(this, { originalImages: newOriImages })
      return
    }

    let newxImages = List([])
    for (let i = 0; i < originalImages.size; i++) {
      newxImages = newxImages.set(i, xImages.get(i))
    }
    await setStatePromise(this, { xImages: newxImages })
  }

  // =========================================
  // ===== Begin Internal Getter/Setter ======
  // =========================================

  // _addXImageInit(): void {
  //   let xImages = this.state.xImages
  //   xImages = xImages.push(this.xImageTemplate)
  //   this.setState({ xImages})
  // }

  _setXImageInit = (xImages: List<IXImage>): List<IXImage> => xImages.push(this.xImageTemplate)

  _setXImageInitAtIndex = (idx: number, inputXImage: List<IXImage>): List<IXImage> => {
    const newXImages = inputXImage || this.state.xImages
    return newXImages.set(idx, this.xImageTemplate)
    // let xImages = this.state.xImages
    // xImages = xImages.set(idx, this.xImageTemplate)
    // this.setState({ xImages})
  }

  _getImgURL = (idx: number, key: string): string | null => {
    const url = this.state.xImages.getIn([idx, 'urls', key]) || null
    // @ts-ignore
    return url
  }

  _getOriImgBase64URL = (idx: number): string | null => {
    const { originalImages } = this.state
    let imgBase64Url = null
    const data = originalImages.getIn([idx, 'data'])
    const mime = originalImages.getIn([idx, 'mime'])
    if (data && mime) {
      imgBase64Url = `data:${mime};base64,${data}`
    }
    return imgBase64Url
  }

  _getImgVisibleURLAtIndex = (idx: number, lowPriorityFirst = true): string | null => {
    // log('_getImgVisibleURL => ', idx)
    const { metadata } = this.props
    let imgUrl = null
    let imgTypeKeys = metadata.map((data) => data.key)
    // log('_getImgVisibleURL idx=', idx, ' imgTypeKeys => ', imgTypeKeys)

    if (lowPriorityFirst) {
      imgTypeKeys = imgTypeKeys.reverse()
      // log('_getImgVisibleURL lowPriorityFirst imgTypeKeys => ', imgTypeKeys)
    }

    for (let i = 0; i < imgTypeKeys.length; i++) {
      const imgKey = imgTypeKeys[i]
      const foundURL = this._getImgURL(idx, imgKey)
      if (foundURL) {
        imgUrl = foundURL
        break
      }
    }

    // ถ้าไม่มี url ในรูป xImage ให้เอาจาก original image (base 64)
    if (!imgUrl) {
      imgUrl = this._getOriImgBase64URL(idx)
    }

    return imgUrl
  }

  _getImgManagerOutput = (): IImgOutput => {
    const { metadata } = this.props
    const { xImages, originalImages } = this.state

    if (xImages.size === 0) {
      return { allUploaded: false, isEmpty: true }
    }

    const imgUrls: { [key: string]: string[] } = {}
    let allUploaded = true
    const unfinishedIndexes = []

    metadata.forEach((data) => {
      const imgKey = data.key
      imgUrls[imgKey] = []
      for (let i = 0; i < xImages.size; i++) {
        let url = this._getImgURL(i, imgKey)
        if (!url) {
          if (unfinishedIndexes.findIndex((ufi) => ufi === i) === -1) {
            unfinishedIndexes.push(i)
          }
          allUploaded = false
        }
        url = prependHttpsIfNotExists(url)
        imgUrls[imgKey].push(url)
      }
    })

    // For firebase debugging only
    // กรณีถ้ามีการ upload image และอัพโหลดไม่ขึ้น (เคยคุยกับอาทิตย์แล้วว่าน่าจะเป็นแต่ Android Emulator)
    if (originalImages && originalImages.size > 0 && metadata && metadata.length > 0) {
      const firstImgKey = metadata[0].key
      const outputImgs = imgUrls[firstImgKey]
      if (_.isArray(outputImgs) && outputImgs.length === 0) {
        p.op.aLogEvent('ErrImgMgrSubmitByEmptyImages')
      }
    }

    const output: IImgOutput = { ...imgUrls, allUploaded }
    if (unfinishedIndexes.length > 0) {
      output.unfinishedIndexes = unfinishedIndexes
    }

    return output
  }

  _getImgResized = (idx: number, key: string): IImgFile | null => {
    const img = this.state.xImages.getIn([idx, 'imgs', key]) || null
    // @ts-ignore
    return img
  }

  _getImgResizedProgress = (idx: number, key: string, inputXImages?: List<IXImage>): number => {
    const currentXImages = inputXImages || this.state.xImages
    const progress = currentXImages.getIn([idx, 'progresses', key]) || 0
    // @ts-ignore
    return progress
  }

  _getImgRenderImageUris = (): Array<string | null> => {
    const { displayFullSize } = this.props
    const { originalImages, xImages } = this.state
    const imgCount = xImages.size
    const renderImages = []

    for (let i = 0; i < imgCount; i++) {
      if (originalImages.getIn([i, 'path'])) {
        renderImages.push(originalImages.getIn([i, 'path']))
      } else if (originalImages.getIn([i, 'data'])) {
        // renderImages.push(originalImages.getIn([i, 'data']))
        const data = originalImages.getIn([i, 'data'])
        const mime = originalImages.getIn([i, 'mime'])
        const imgB64 = `data:${mime};base64,${data}`
        renderImages.push(imgB64)
      } else {
        let imgUrl
        if (displayFullSize) {
          imgUrl = this._getImgVisibleURLAtIndex(i, false)
        } else {
          imgUrl = this._getImgVisibleURLAtIndex(i, true)
        }
        renderImages.push(imgUrl) // maybe null
      }
    }

    return renderImages
  }

  _getFullSizeImageBase64Uris = async (): Promise<string[]> => {
    const { originalImages, xImages } = this.state
    const imgCount = xImages.size
    const b64Images = []

    for (let i = 0; i < imgCount; i++) {
      if (originalImages.getIn([i, 'data'])) {
        const data = originalImages.getIn([i, 'data'])
        const mime = originalImages.getIn([i, 'mime'])
        if (data && mime) {
          const imgB64 = `data:${mime};base64,${data}`
          b64Images.push(imgB64)
        }
      } else {
        const imgUrl = this._getImgVisibleURLAtIndex(i, false)
        const imgBase64 = await this._getBase64fromImageURL(imgUrl)
        if (imgBase64) {
          b64Images.push(imgBase64)
        }
      }
    }

    return b64Images
  }

  _getFirstProitySizeImageUrls = async (): Promise<string[]> => {
    const { xImages } = this.state
    const imgCount = xImages.size
    const imgUrls = []

    for (let i = 0; i < imgCount; i++) {
      const imgUrl = this._getImgVisibleURLAtIndex(i, false)
      log('_getFirstProitySizeImageUrls imgUrl => ', imgUrl)
      if (imgUrl && !isBase64DataURL(imgUrl)) {
        imgUrls.push(imgUrl)
      } else if (Platform.OS === 'web' && imgUrl) {
        imgUrls.push(imgUrl)
      }
    }

    return imgUrls
  }

  getImgTotalProgress = (): IImgProgress => {
    const { xImages } = this.state

    const imgCount = xImages.size
    let progress = 0
    const progresses = []

    if (imgCount === 0) {
      return { progress, progresses }
    }

    xImages.forEach((_, idx) => {
      const currProgress = this._getImgXProgress(idx)
      progresses.push(currProgress)
      progress += currProgress
    })

    progress = Math.round(progress / imgCount)
    progress = this._getLimitProgress(progress)

    return { progress, progresses }
  }

  _getImgXProgress = (idx: number): number => {
    const progress = this.state.xImages.getIn([idx, 'progress']) || 0
    // @ts-ignore
    return progress
  }

  // _getImgStatus(idx: number, key: string): xImgStatus {
  //   const url = this.state.xImages.getIn([idx, 'statuses', key]) || xImgStatus.INIT
  //   return url
  // }

  // Returning XImage Immutable.Map
  _setImgURL = (idx: number, key: string, imgURL: string | null, inputXImages?: List<IXImage>): List<IXImage> => {
    const newXimages = inputXImages || this.state.xImages
    return newXimages.setIn([idx, 'urls', key], imgURL)
  }

  _setImgResized = (idx: number, key: string, newImage: IImgFile): List<IXImage> => this.state.xImages.setIn([idx, 'imgs', key], newImage)

  _setImgResizedUploadProgress = (idx: number, key: string, newProgress: number, inputXImages?: List<IXImage>): List<IXImage> => {
    const currentXImages = inputXImages || this.state.xImages
    const limitProgress = this._getLimitProgress(newProgress)
    return currentXImages.setIn([idx, 'progresses', key], limitProgress)
  }

  _setImgComputeXImageProgress = (idx: number, inputXImages?: List<IXImage>): List<IXImage> => {
    const { metadata } = this.props
    const imgTypeCount = metadata.length
    const currentXImages = inputXImages || this.state.xImages
    let sumOfProgress = 0
    for (let i = 0; i < imgTypeCount; i++) {
      const { key } = metadata[i]
      sumOfProgress += this._getImgResizedProgress(idx, key, inputXImages)
    }
    const xIImgProgress = this._getLimitProgress(Math.round(sumOfProgress / imgTypeCount))
    return currentXImages.setIn([idx, 'progress'], xIImgProgress)
  }

  _getLimitProgress = (progress: number): number => {
    let limitProgress = progress
    if (limitProgress >= 100) {
      limitProgress = 100
    } else if (limitProgress < 0 || !_.isNumber(limitProgress)) {
      limitProgress = 0
    }
    return limitProgress
  }

  // _setImgStatus(idx: number, key: string, newStatus: xImgStatus): List<IXImage> {
  //   if (!(newStatus in xImgStatus)) {
  //     newStatus = xImgStatus.INIT
  //   }
  //   return this.state.xImages.getIn([idx, 'statuses', key], newStatus)
  // }

  _getImgSize = (key: string): { width: number; height: number; fixedSize?: boolean } =>
    // return this.props.imgSizes[key]
    // log('_getImgSize this.props.metadata.find(data => data.key === key) => ', this.props.metadata.find(data => data.key === key))
    this.props.metadata.find((data) => data.key === key)

  _isAllowFullSizeUpload = (key: string): boolean => {
    const { allowFullSizeUpload = false } = this.props.metadata.find((data) => data.key === key)
    return allowFullSizeUpload
  }

  _getImgCommonTimestamp = async (idx: number): Promise<string> => {
    const { xImages } = this.state
    // @ts-ignore
    let generatedTimestamp: string | null = xImages.getIn([idx, 'commonStampName']) || null
    if (generatedTimestamp) {
      return generatedTimestamp
    }
    generatedTimestamp = getTimeString()
    const newXImages = xImages.setIn([idx, 'commonStampName'], generatedTimestamp)
    return new Promise<string>((resolve) => {
      this.setState({ xImages: newXImages }, () => {
        resolve(generatedTimestamp)
      })
    })
  }

  _getImgCommonRandomName = async (idx: number): Promise<string> => {
    const { xImages } = this.state
    // @ts-ignore
    let generatedRandomName: string | null = xImages.getIn([idx, 'commonRandomName']) || null
    if (generatedRandomName) {
      return generatedRandomName
    }
    generatedRandomName = getRandomString()
    const newXImages = xImages.setIn([idx, 'commonRandomName'], generatedRandomName)
    return new Promise<string>((resolve) => {
      this.setState({ xImages: newXImages }, () => {
        resolve(generatedRandomName)
      })
    })
  }

  getBase64ImageDimensions = async (base64data: string) =>
    new Promise<{ width?: number; height?: number }>((resolve) => {
      Image.getSize(
        base64data,
        (width, height) => resolve({ width, height }),
        () => resolve({})
      )
    })

  _getUnconvertImage = async (): Promise<{
    index?: number
    key?: string
    allConvert?: boolean
    originalImages: List<IImgFile | null>
  }> => {
    // await doSomethingAndDelay(() => alert('In _getUnconvertImage'), 3000)
    // log(`In _getUnconvertImage`)
    const { metadata } = this.props
    const { xImages, originalImages } = this.state
    for (let i = 0; i < xImages.size; i++) {
      for (let j = 0; j < metadata.length; j++) {
        const { key } = metadata[j]
        const resizedImg = this._getImgResized(i, key)
        const imgUrl = this._getImgURL(i, key)
        const hasOriImage = !!originalImages.get(i)
        if (hasOriImage && !resizedImg && !imgUrl) {
          // ถ้ามี imgUrl แล้วแสดงว่าเสร็จแล้ว
          // log('_getUnconvertImage found unconvert image at index => ', i, ' key => ', key)
          return { index: i, key, originalImages }
        }
        if (!hasOriImage && !resizedImg && !imgUrl) {
          // ถ้าไม่มีรูปใน local แล้วยังไม่มี uploadedLink โหลดเอารูปใหญ่มาแปลง
          const largestImageKey = metadata[0].key
          const largestImageUrl = this._getImgURL(i, largestImageKey)
          // log('_getUnconvertImage largestImageUrl => ', largestImageUrl)
          if (largestImageUrl) {
            const mimeType = mimeUtil.lookup(largestImageUrl) || null
            const base64image = await this._getBase64fromImageURL(largestImageUrl)
            // log('_getUnconvertImage Lookup mimeType => ', mimeType)
            // log('_getUnconvertImage largestImageUrl base64image => ', base64image)
            if (!base64image || !mimeType) {
              return { allConvert: false, originalImages }
            }

            const base64strip = base64image.split(',')
            const data = base64strip.length > 1 ? base64strip[1] : base64image
            const path = base64strip.length > 1 ? base64image : `data:${mimeType};base64,${base64image}`
            const imgDimensions = await this.getBase64ImageDimensions(base64image)
            const largestImage: IImgFile = {
              path,
              data,
              mime: mimeType,
              ...imgDimensions,
            }

            const newOriImages = originalImages.set(i, largestImage)
            // log('_getUnconvertImage largestImage => ', largestImage)
            // log('_getUnconvertImage newOriImages.toJS => ', newOriImages.toJS())
            await setStatePromise(this, { originalImages: newOriImages })
            return { index: i, key, originalImages: newOriImages }
            // await this._convertNextAvailableImage()
            // recursive เรียกอัพโหลดตัวเองอีกรอบนึง เพื่อเอาผลลัพธ์ไปให้ submit
          }
          // WORSE CASE ถ้ายังไม่มี Largest Image อีก ให้ลบรูปนี้ทิ้ง (DESTROY)
          await this.deleteImageAtIndex(i)
        }
      }
    }

    // log('_getUnconvertImage exit with allConvert = true')
    return { allConvert: true, originalImages }
  }

  _getUnuploadImage = async (): Promise<{ index?: number; key?: string; allResizedUploaded?: boolean }> => {
    // await doSomethingAndDelay(() => alert('In _getUnuploadImage'), 3000)
    // log('In _getUnuploadImage')
    const { metadata } = this.props
    const { xImages, originalImages } = this.state
    for (let i = 0; i < xImages.size; i++) {
      for (let j = 0; j < metadata.length; j++) {
        const { key } = metadata[j]
        const resizedImg = this._getImgResized(i, key)
        const uploadedLink = this._getImgURL(i, key)
        const hasOriImage = !!originalImages.get(i)
        // log(`In _getUnuploadImage i=${i} key=${key} resizedImg => `, resizedImg)
        // log(`In _getUnuploadImage i=${i} key=${key} uploadedLink => `, uploadedLink)
        if (hasOriImage && resizedImg && !uploadedLink) {
          // ถ้ามีรูปใน local แล้วมี resize
          return { index: i, key }
        }
        // else if (!hasOriImage && !uploadedLink) {
        // // ถ้าไม่มีรูปใน local แล้วยังไม่มี uploadedLink โหลดเอารูปใหญ่มาแปลง
        //
        // // TODO: ให้แปลงรูปเป็น base64 ?
        // // TODO: หาวิธีอ่านไฟล์ แล้วเอาไฟล์ไปแปลงภาพ เป็น size เล็ก แล้วเอาภาพไปอัพโหลด
        // // TODO: Optional => ใช้รูปจาก size ใหญ่
        // const largestImageKey = metadata[0].key
        // const largestImageUrl = this._getImgURL(i, largestImageKey)
        // log('_getUnuploadImage largestImageUrl => ', largestImageUrl)
        // if (largestImageUrl) {
        //   const mimeType = mime.lookup(largestImageUrl) || null
        //   const base64image = await this._getBase64fromImageURL(largestImageUrl)
        //   log('_getUnuploadImage Lookup mimeType => ', mimeType)
        //   // log('_getUnuploadImage largestImageUrl base64image => ', base64image)
        //   if (!base64image || !mimeType) {
        //     return { allResizedUploaded: false }
        //   }
        //
        //   let resizedImageFile: IImgFile = {
        //     path: base64image,
        //     mime: mimeType,
        //   }
        //
        //   const { width, height } = this._getImgSize(largestImageKey)
        //   resizedImageFile = await this._getResizedImage(resizedImageFile, width, height)
        //   log('_getUnuploadImage has resizedImageFile => ', resizedImageFile)
        //
        //   if (!resizedImageFile.data) { // ถ้าไม่มี base64 data
        //     return { allResizedUploaded: false }
        //   }
        //
        //   const newXImages = this._setImgResized(i, key, resizedImageFile)
        //   await setStatePromise(this, { xImages: newXImages })
        //   // await this._convertNextAvailableImage()
        //   // recursive เรียกอัพโหลดตัวเองอีกรอบนึง เพื่อเอาผลลัพธ์ไปให้ submit
        //   return this._getUnuploadImage()
        // }
      }
    }

    // log('In _getUnuploadImage exit with allResizedUploaded = true')
    return { allResizedUploaded: true }
  }

  _isUploading = (): boolean => {
    const { uploading } = this.props
    return _.isBoolean(uploading) ? uploading : false
  }

  // =========================================
  // =====  End Internal Getter/Setter  ======
  // =========================================

  // _doUploadImageToSpaces = async (imageName: string, imageFile: IImgFile): Promise<{ key: string; url: string }> => {
  //   return new Promise<{ key: string; url: string }>(resolve => {
  //     // const mime = imageFile.mime ? imageFile.mime : null
  //     // if (!mime || mime.split('/').length !== 2) {
  //     //   resolve(null)
  //     //   // resolve(new Error('Invalid mime in imageFile object.'))
  //     // }

  //     // const fileExt = mime.split('/')[1]
  //     // let fileName = '.' + fileExt
  //     // if (this.props.fileNamePrefix) {
  //     //   fileName = this.props.fileNamePrefix + fileName
  //     // }

  //     // const imgOptions = {
  //     //   // ...StorageOptions,
  //     //   bucket: settings.s3.options.bucket,
  //     //   level: 'public',
  //     //   customPrefix: {
  //     //     public: settings.s3.options.keyPrefix,
  //     //   },
  //     //   contentType: mime,
  //     //   ContentEncoding: 'base64',
  //     // }

  //     // // log('_doUploadImageToSpaces imageFile => ', imageFile)
  //     // const splitedBase64Data = imageFile.data.split(',')
  //     // // log('_doUploadImageToSpaces splitedBase64Data => ', splitedBase64Data)
  //     // // log('_doUploadImageToSpaces imageFile.data => ', imageFile.data)
  //     // const imageData = splitedBase64Data.length > 1 ? splitedBase64Data[1] : imageFile.data
  //     // // Ref: https://github.com/aws/aws-amplify/issues/576
  //     // const bufferedImageData = new Buffer(imageData, 'base64')
  //     // // log('_doUploadImageToSpaces bufferedImageData => ', bufferedImageData)

  //     // Storage.put(fileName, bufferedImageData, imgOptions)
  //     //   .then((result: { key: string }) => {
  //     //     const { region, bucket, keyPrefix } = settings.s3.options
  //     //     const url = `https://s3-${region}.amazonaws.com/${bucket}/${keyPrefix}${fileName}`
  //     //     // log('_doUploadImageToSpaces result => ', result)
  //     //     // log('_doUploadImageToSpaces { ...result, url } => ', { ...result, url })
  //     //     resolve({ ...result, url })
  //     //   })
  //     //   .catch(err => {
  //     //     log('_doUploadImageToSpaces err => ', err)
  //     //     resolve(null)
  //     //     // resolve(err)
  //     //   })

  //     // // Uploading manual resolve timeout
  //     // setTimeout(() => {
  //     //   log('uploading to s3 timeout while uploading > 2 minutes!!')
  //     //   resolve(null)
  //     // }, 180000)
  //   })
  // }

  loadImageUrls = async (imgUrls: { [key: string]: string[] }): Promise<void> => {
    // log('In loadImageUrls imgUrls => ', imgUrls)

    if (_.isEmpty(imgUrls)) {
      throw new Error('Expected imgUrls has some value')
    }

    await this.clearImages()

    const { metadata } = this.props
    const expectedKeys = metadata.map((data) => data.key)
    // log('In loadImageUrls expectedKeys => ', expectedKeys)

    const { originalImages, xImages } = this.state
    const keys = Object.keys(imgUrls)

    let newOriImages = originalImages
    let newXImages = xImages

    let maxImageCount = 0

    // เก็บจำนวนภาพสูงสุดเอาไว้ ที่จะเอามา init template
    keys.forEach((key) => {
      if (_.includes(expectedKeys, key)) {
        // log(`In loadImageUrls key=${key} is expected key.`)
        const images = _.isArray(imgUrls[key]) ? imgUrls[key] : []
        if (images.length > maxImageCount) {
          maxImageCount = images.length
        }
      } else {
        // log(`In loadImageUrls key=${key} is not expected key.`)
      }
    })

    // log('In loadImageUrls maxImageCount => ', maxImageCount)

    for (let i = 0; i < maxImageCount; i++) {
      newOriImages = newOriImages.set(i, null) // สร้าง empty oriImage
      newXImages = this._setXImageInitAtIndex(i, newXImages) // สร้าง empty xImage
      // log('In loadImageUrls init index => ', i)

      // for (let idx = 0; idx < keys.length; idx++) {
      //   const currentKey = keys[idx]
      //   const parentKey = keys[0] // nearest full original image
      //   if (_.includes(expectedKeys, currentKey)) {
      //     log('In loadImageUrls add url of index => ', i, ' currentKey => ', currentKey)
      //     const url = !!imgUrls[currentKey][i] ? imgUrls[currentKey][i] : imgUrls[parentKey][i]
      //     newXImages = this._setImgURL(i, currentKey, url, newXImages)
      //     if (url) {
      //       newXImages = this._setImgResizedUploadProgress(i, currentKey, 100, newXImages)
      //     }
      //     newXImages = this._setImgComputeXImageProgress(i, newXImages)
      //   }
      //
      //   // delay 100 ms ให้ react re-render
      //   // await new Promise(resolve => { setTimeout(resolve, 100) })
      //   await setStatePromise(this, { originalImages: newOriImages, xImages: newXImages })
      // }

      for (const key of keys) {
        if (_.includes(expectedKeys, key)) {
          // log('In loadImageUrls add url of index => ', i, ' key => ', key)
          const focusedImgUrls = _.isArray(imgUrls[key]) ? imgUrls[key] : []
          const url = focusedImgUrls.length > i ? focusedImgUrls[i] : null
          newXImages = this._setImgURL(i, key, url, newXImages)
          if (url) {
            newXImages = this._setImgResizedUploadProgress(i, key, 100, newXImages)
          }
          newXImages = this._setImgComputeXImageProgress(i, newXImages)
        }

        // delay 100 ms ให้ react re-render
        // await new Promise(resolve => { setTimeout(resolve, 100) })
        await setStatePromise(this, { originalImages: newOriImages, xImages: newXImages })
      }
    }

    // log('In loadImageUrls newXImages.toJS => ', newXImages.toJS())
    await setStatePromise(this, { originalImages: newOriImages, xImages: newXImages })
  }

  addNewImages = async (addedImages: IImgFile[] | IImgFile): Promise<void> => {
    // log('addNewImages addedImages => ', addedImages)
    const { originalImages, xImages } = this.state
    let newImages: List<IImgFile> = originalImages
    let newXImages: List<IXImage> = xImages
    // log('addNewImages newImages => ', newImages)

    if (_.isArray(addedImages) && addedImages.length > 0) {
      // newImages = newImages.concat(addedImages)
      for (let i = 0; i < addedImages.length; i++) {
        // await new Promise(resolveAddSingleImg => {
        //   newImages = newImages.push(addedImages[i])
        //   newXImages = this._setXImageInit(newXImages)
        //   // log(`addNewImages idx => ${i} newImages.toJS() => `, newImages.toJS())
        //   this.setState({ originalImages: newImages, xImages: newXImages }, () => {
        //     setTimeout(resolveAddSingleImg, 100)
        //   })
        // })
        newImages = newImages.push(addedImages[i])
        newXImages = this._setXImageInit(newXImages)
        await new Promise((setImageDelay) => setTimeout(setImageDelay, 10))
        await setStatePromise(this, { originalImages: newImages, xImages: newXImages })
      }
    } else if (!_.isArray(addedImages) && _.isObject(addedImages) && !_.isEmpty(addedImages)) {
      newImages = newImages.push(addedImages)
      newXImages = this._setXImageInit(newXImages)
      this.setState({ originalImages: newImages, xImages: newXImages })
    } else {
      log('Error: No Selected Images')
      throw new Error('No Selected Images')
    }

    // log('addNewImages newImages.toJS() => ', newImages.toJS())
    // log('addNewImages originalImages.toJS() => ', originalImages.toJS())
  }

  clearImages = async (): Promise<void> => {
    await setStatePromise(this, { originalImages: List([]), xImages: List([]) })
  }

  replaceNewImageAtIndex = async (replaceIndex: number, newImage: IImgFile): Promise<void> => {
    // log('In _ReplaceNewImages replaceIndex => ', replaceIndex, ' newImages => ', newImage)
    const { originalImages, xImages } = this.state
    let newImages: List<IImgFile> = originalImages
    let newXImages: List<IXImage> = xImages

    if (newImage) {
      newImages = newImages.set(replaceIndex, newImage)
      newXImages = this._setXImageInitAtIndex(replaceIndex, newXImages)
      await setStatePromise(this, { originalImages: newImages, xImages: newXImages })
    } else {
      log('Error: No Selected Images')
    }

    // log('_ReplaceNewImages newImages.toJS() => ', newImages.toJS())
    // log('_ReplaceNewImages newXImages.toJS() => ', newXImages.toJS())
  }

  deleteImageAtIndex = async (index: number): Promise<void> => {
    // log('In deleteImageAtIndex index => ', index)
    const { originalImages, xImages } = this.state
    const { onDeleteImgSuccess } = this.props
    let newImages: List<IImgFile> = originalImages
    let newXImages: List<IXImage> = xImages

    if (_.isNumber(index) && xImages.has(index)) {
      newImages = deleteImmutableListAtIndex(newImages, index)
      newXImages = deleteImmutableListAtIndex(newXImages, index)
      // delay(500) เพราะ ลบ รูปไม่หาย ตอนลบรูปในหน้า เพิ่มรูปใบปะหน้าพัสดุ
      await delay(500)
      await setStatePromise(this, { originalImages: newImages, xImages: newXImages })
      if (!_.isNil(onDeleteImgSuccess) && onDeleteImgSuccess) {
        onDeleteImgSuccess()
      }
    } else {
      log('Error: No Selected Images')
      throw new Error('Error: No Selected Images')
    }

    // log('deleteImageAtIndex newImages.toJS() => ', newImages.toJS())
    // log('deleteImageAtIndex newXImages.toJS() => ', newXImages.toJS())
  }

  // Usage ::
  //   this._getResizedSingleImage(0, 'image').then(resizedImage => {
  //      log('after _resizeSingleImage resizedImage => ', resizedImage)
  //   })
  _getResizedSingleImage = async (idx: number, key: string): Promise<IImgFile | null> => {
    // log(`In _getResizedSingleImage idx=${idx} key=${key}`)

    const { originalImages } = this.state
    const oriImage: IImgFile = originalImages.get(idx) || null
    if (!oriImage) {
      log(`No Image request number ${idx}`)
      throw new Error(`No Image request number ${idx}`)
    }
    // console.log(`_getResizedSingleImage ${idx} originalImages => `, originalImages)

    const ImgProps = this._getImgSize(key)
    const allowFullSizeUpload = this._isAllowFullSizeUpload(key)
    if (!oriImage.path || !oriImage.width || !oriImage.height) {
      let computedImgSize = null
      if (oriImage.path) {
        computedImgSize = await this.getImageSizeFromUrl(oriImage.path)
      }
      // console.log(`_getResizedSingleImage ${idx} computedImgSize 1 => `, computedImgSize)

      if (!computedImgSize) {
        const findingImgUrl = this._getImgVisibleURLAtIndex(idx, false)
        // console.log(`_getResizedSingleImage ${idx} findingImgUrl => `, findingImgUrl)
        if (findingImgUrl) {
          computedImgSize = await this.getImageSizeFromUrl(findingImgUrl)
        }
      }

      // console.log(`_getResizedSingleImage ${idx} computedImgSize 2 => `, computedImgSize)
      if (!computedImgSize || !computedImgSize.width || !computedImgSize.height) {
        throw new Error('Something wrong [width,height,path] in oriImage file. :(')
      }
      oriImage.width = computedImgSize.width
      oriImage.height = computedImgSize.height
    }
    const maxHeight = _.has(ImgProps, 'height') ? ImgProps.height : 10000
    const maxWidth = _.has(ImgProps, 'width') ? ImgProps.width : 10000
    const fixedSize = _.has(ImgProps, 'fixedSize') ? ImgProps.fixedSize : false
    const originalWidth = oriImage.width
    const originalHeight = oriImage.height
    let newWidth
    let newHeight

    // console.log(`In _getResizedSingleImage oriImage => `, oriImage)
    // console.log(`In _getResizedSingleImage originalWidth => `, originalWidth)
    // console.log(`In _getResizedSingleImage originalHeight => `, originalHeight)
    // console.log(`In _getResizedSingleImage maxWidth => `, maxWidth)
    // console.log(`In _getResizedSingleImage maxHeight => `, maxHeight)

    if (fixedSize) {
      // log('_getResizedSingleImage resizing by FIXED SIZE')
      newWidth = maxWidth
      newHeight = maxHeight
    } else if (allowFullSizeUpload) {
      return oriImage
    } else if (originalWidth >= maxWidth) {
      // ยึด width เป็นหลักก่อน
      // log('_getResizedSingleImage resizing by WIDTH')
      newWidth = maxWidth
      newHeight = Math.round((originalHeight / originalWidth) * newWidth)
    } else if (originalHeight >= maxHeight) {
      // log('_getResizedSingleImage resizing by HEIGHT')
      newHeight = maxHeight
      newWidth = Math.round(newHeight / (originalHeight / originalWidth))
    } else {
      // log('_getResizedSingleImage no resizing by Just Compress Image')
      newWidth = originalWidth
      newHeight = originalHeight
      // return oriImage
    }

    // console.log(`In _getResizedSingleImage newWidth => `, newWidth)
    // console.log(`In _getResizedSingleImage newHeight => `, newHeight)

    // log('_getResizedSingleImage  maxHeight => ', maxHeight)
    // log('_getResizedSingleImage  maxWidth => ', maxWidth)
    // log('_getResizedSingleImage  originalHeight => ', originalHeight)
    // log('_getResizedSingleImage  originalWidth => ', originalWidth)
    // log('_getResizedSingleImage  newWidth => ', newWidth)
    // log('_getResizedSingleImage  newHeight => ', newHeight)
    if (!newHeight || !newWidth) {
      throw new Error('Cannot resized zero width/height image.')
    }

    // log(`In _getResizedSingleImage width=${width} height=${height} oriImage => `, oriImage)
    // return await this._getResizedImage(oriImage, width, height)
    return this._getResizedImage(oriImage, newHeight, newHeight)
  }

  submitUploadImages = async (): Promise<IImgOutput> => {
    if (this._inUploading) {
      // waiting _inUploading = false and return output
      let waitingCount = 1
      await new Promise(async (finishedUploading) => {
        while (waitingCount < 60 && this._inUploading) {
          // log('submitUploadImages uploading waitingCount => ', waitingCount)
          // log('submitUploadImages uploading this._inUploading => ', this._inUploading.toString())
          await delay(1000)
          waitingCount++
        }
        await delay(1500)
        finishedUploading(null)
      })
      return this._getImgManagerOutput()
    }
    this._inUploading = true

    // await doSomethingAndDelay(() => alert('In submitUploadImages'), 3000)

    const { metadata } = this.props
    const { originalImages } = this.state
    const allCombinationMaxCount = metadata.length * originalImages.size * 2 // 2 times of combination

    // log('submitUploadImages begin')
    // log('submitUploadImages allCombinationMaxCount => ', allCombinationMaxCount)
    // log('submitUploadImages metadata => ', metadata)
    // log('submitUploadImages originalImages.toJS() => ', originalImages.toJS())

    // await setStatePromise(this,{ uploading: true })
    await this._syncOriImageToXImage()

    // await doSomethingAndDelay(() => alert('In submitUploadImages converting/uploading loop'), 3000)
    for (let i = 0; i < allCombinationMaxCount; i++) {
      if (!this._isUploading()) {
        break
      }
      const hasConvertingImage = await this._convertNextAvailableImage()
      // log('submitUploadImages hasConvertingImage => ', hasConvertingImage)
      const hasUploadingImage = await this._uploadNextAvailableImage()
      // log('submitUploadImages hasUploadingImage => ', hasUploadingImage)
      if (!hasConvertingImage && !hasUploadingImage) {
        log('ALL UPLOADED')
        // alert('ALL UPLOADED')
        break
      }

      if ((hasConvertingImage || hasConvertingImage) && i === allCombinationMaxCount - 1) {
        log('Cannot upload some files please try again.')
        // alert('Cannot upload some files please try again.')
      }
    }

    // await setStatePromise(this,{ uploading: false })
    this._inUploading = false
    await this._onFinishUpload()
    return this._getImgManagerOutput()
  }

  async _onFinishUpload() {
    await this._syncOriImageToXImage()
    const { onFinishUpload } = this.props
    if (_.isFunction(onFinishUpload)) {
      const imgOutput = this._getImgManagerOutput()
      onFinishUpload(imgOutput)
    }
  }

  _convertNextAvailableImage = async (): Promise<boolean> => {
    // await doSomethingAndDelay(() => alert('In _convertNextAvailableImage'), 3000)

    // if (!this.props.uploading) {
    //   return false
    // }
    if (!this._isUploading()) {
      return false
    }

    // log('In _convertNextAvailableImage')
    const { index, key, allConvert, originalImages } = await this._getUnconvertImage()
    if (allConvert) {
      return false
    }

    if (_.isNumber(index) && !originalImages.get(index)) {
      log(`Cannot find original image to convert at number ${index}.`)
      return false
      // throw new Error(`Cannot find original image to convert at number ${index}.`)
    }

    const resizedImage = await this._getResizedSingleImage(index, key)
    // console.log(`In _convertNextAvailableImage resizedImage => `, resizedImage)
    if (!resizedImage || (!resizedImage.path && !resizedImage.data)) {
      log(`Cannot resizedImage at number ${index} ${key}.`)
      return false
    }

    const newImages = this._setImgResized(index, key, resizedImage)
    await setStatePromise(this, { xImages: newImages, originalImages })
    return true

    // return new Promise<boolean>((resolve) => {
    //   this._getResizedSingleImage(index, key).then(resizedImage => {
    //     const newImages = this._setImgResized(index, key, resizedImage)
    //     this.setState({ xImages: newImages, originalImages }, () => {
    //       resolve(true) // set รูปใหม่สำเร็จแล้ว
    //     })
    //   }).catch(err => {
    //     log('_getResizedSingleImage error => ', err)
    //     resolve(false)
    //   })
    //
    //   setTimeout(() => {
    //     log('_convertNextAvailableImage resolve because timeout 15 sec.')
    //     resolve(false)
    //   }, 15000)
    // })
  }

  _uploadNextAvailableImage = async (): Promise<boolean> => {
    // await doSomethingAndDelay(() => alert('In _uploadNextAvailableImage'), 3000)

    // if (!this.props.uploading) {
    //   return false
    // }
    if (!this._isUploading()) {
      return false
    }
    // log('In _uploadNextAvailableImage')
    // const { xImages } = this.state
    const { index, key, allResizedUploaded } = await this._getUnuploadImage()
    // log('In _uploadNextAvailableImage after _getUnuploadImage index => ', index)
    // log('In _uploadNextAvailableImage after _getUnuploadImage key => ', key)

    if (allResizedUploaded) {
      log('In _uploadNextAvailableImage allUpload done.')
      return false
    }

    if (_.isNumber(index) && key && !this._getImgResized(index, key)) {
      log(`Cannot find xImages image to upload at number ${index}.`)
      return false
      // throw new Error(`Cannot find xImages image to upload at number ${index}.`)
    }

    const prefixName = await this._getImgCommonTimestamp(index)
    const postfixName = await this._getImgCommonRandomName(index)
    const fileName = `${prefixName}${key}${postfixName}`
    const fileImage = this._getImgResized(index, key)

    // log(`_uploadNextAvailableImage index = ${index}, key = ${key}, fileName = ${fileName}`)
    let fakeProgressImages = this._setImgResizedUploadProgress(index, key, 10)
    fakeProgressImages = this._setImgComputeXImageProgress(index, fakeProgressImages)
    await setStatePromise(this, { xImages: fakeProgressImages })
    // log('_uploadNextAvailableImage to S3 fileImage => ', fileImage)
    // const uploadResponse = await this._doUploadImageToSpaces(fileName, fileImage)
    // // log('_uploadNextAvailableImage to S3 uploadResponse => ', uploadResponse)

    // if (!uploadResponse || !uploadResponse.key || !uploadResponse.url) {
    //   // log(`Uploading index=${index} key=${key} with error failed`)
    //   let clearProgressImages = this._setImgResizedUploadProgress(index, key, 0)
    //   clearProgressImages = this._setImgComputeXImageProgress(index, clearProgressImages)
    //   await setStatePromise(this, { xImages: clearProgressImages })
    //   return false
    //   // throw new Error(`Uploading index=${index} key=${key} with error failed`)
    // }
    try {
      const uploadResponse = await uploadImageToSpaces(fileImage, { imageName: fileName, imageNamePrefix: this.props.fileNamePrefix })
      let newXImages = this._setImgURL(index, key, uploadResponse.url)
      // TODO: Use real progress instead for resized image after aws-amplify update api
      newXImages = this._setImgResizedUploadProgress(index, key, 100, newXImages)
      newXImages = this._setImgComputeXImageProgress(index, newXImages)
      await setStatePromise(this, { xImages: newXImages }) // set รูปใหม่สำเร็จแล้ว
      return true
    } catch (error) {
      let clearProgressImages = this._setImgResizedUploadProgress(index, key, 0)
      clearProgressImages = this._setImgComputeXImageProgress(index, clearProgressImages)
      await setStatePromise(this, { xImages: clearProgressImages })
    }
    return false
  }

  // from/to is index of array
  rearrangeImages = (params: { from: number; to: number }) => {
    const { from, to } = params
    const { originalImages, xImages } = this.state
    let newOriImages: List<IImgFile> = originalImages
    let newXImages: List<IXImage> = xImages

    const tmpOriImage = originalImages.get(from)
    const tmpXImage = xImages.get(from)

    newOriImages = newOriImages.delete(from)
    newXImages = newXImages.delete(from)

    newOriImages = newOriImages.insert(to, tmpOriImage)
    newXImages = newXImages.insert(to, tmpXImage)

    this.setState({ originalImages: newOriImages, xImages: newXImages })
  }
}
