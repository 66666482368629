/* eslint-disable react/no-unused-class-component-methods */
import React from 'react'
import { IOrder, IOrderProductItem, ISelectedStoreMap, IXShipping } from 'x/index'
// import { NavigationScreenProp, NavigationState } from 'react-navigation'
import * as util from 'x/utils/util'
import _ from 'lodash'
import api from 'x/utils/api'
import p from 'x/config/platform-specific'

interface IIMultiParcel {
  order_id: number
  parcels: IParcel[]
}

export interface IParcel {
  items: {
    pp_id: number
    qty: number
  }[]
  x?: IXShipping
  // isHaveDeleteBtn => เอาไว้บอกว่า Parcel อันไหนเป็นอันเก่า  เพราะถ้าเป็นอันเก่าจะไม่สามารถลบออกได้
  isHaveDeleteBtn?: boolean
}

interface BaseMultiParcelViewProps {
  navigation: any
  selectedStore: ISelectedStoreMap
}

interface BaseMultiParcelViewState {
  order: IOrder
  multiParcel: IParcel[]
  amountQty: number
  products: IOrderProductItem[]

  isHaveChange: boolean
  isShowBtnDeleteParcels: boolean
  // isHaveXShipping: boolean
  isSubmitting: boolean

  inputDivideParcels: string
  isVisibleOverlayDivideParcels: boolean
}

export default class BaseMultiParcelView extends React.Component<BaseMultiParcelViewProps, BaseMultiParcelViewState> {
  inProcess: boolean

  constructor(props) {
    super(props)
    this.state = {
      order: null,
      multiParcel: null,
      products: null,
      isHaveChange: false,
      amountQty: 0,
      isShowBtnDeleteParcels: false,
      // isHaveXShipping: false,
      isSubmitting: false,

      inputDivideParcels: '',
      isVisibleOverlayDivideParcels: false,
    }
    this.inProcess = false
  }

  async componentDidMount() {
    // await this._setConfig()
    // const { navigation } = this.props
    // console.log('navigation => ', navigation)
    // console.log('navigation // => ', navigation.state)
    // @ts-ignore
    const params = util.getNavParams(this.props)
    // console.log('params => ', params)
    const { order } = params
    // console.log('order => ', order)
    let amountQty = 0
    const products = _.isNil(order) ? null : order.products
    if (!_.isNil(order)) {
      products.forEach((product) => {
        amountQty += product.qty
        amountQty -= product.shipped_qty
      })
    }
    // console.log('order => ', order)
    // console.log('order => ', order.products)
    this.setState({
      order,
      multiParcel: [],
      amountQty,
      products,
    })

    await this._fetchMultiParcel(order)
  }

  _showOverlayDivideParcels = () => {
    this.setState({
      isVisibleOverlayDivideParcels: true,
    })
  }

  _closeOverlayDivideParcels = () => {
    this.setState({
      isVisibleOverlayDivideParcels: false,
    })
  }

  _submitDivideParcels = () => {
    // const { multiParcel, inputDivideParcels, order } = this.state
    // const newMultiParcel = _.isNil(multiParcel) ? [] : multiParcel
    // const { products } = order
    // console.log('products => ', products)
    // // const doneParcel = false
    // // // @ts-ignore
    // // const newProducts: IOrderProductItem[] = []
    // let useCount = 0
    // const items = []
    // products.forEach((product) => {
    //   console.log('product => ', product)
    //   const productQty = product.qty - product.shipped_qty
    //   console.log('productQty => ', productQty)
    //   // if (productQty < +inputDivideParcels) {
    //   for (let i = 0; i <= productQty; i++) {
    //     console.log('useCount => ', useCount)
    //     if (useCount < +inputDivideParcels) {
    //       useCount += 1
    //       newMultiParcel.push({
    //         items: [
    //           {
    //             pp_id: product.pp_id,
    //             qty: 1,
    //           },
    //         ],
    //       })
    //     } else {
    //       const item = {
    //         pp_id: product.pp_id,
    //         qty: product.qty,
    //       }
    //       items.push(item)
    //     }
    //   }
    //   // }
    //   // @ts-ignore
    //   newMultiParcel.push(items)
    // })
    // this.setState({
    //   multiParcel: newMultiParcel,
    // })
    // this._closeOverlayDivideParcels()
  }

  _goBack = () => {
    // @ts-ignore
    const params = util.getNavParams(this.props)
    const { callBackRerender } = params
    if (_.isFunction(callBackRerender)) {
      callBackRerender(true)
    }
    // @ts-ignore
    util.navGoBack(this.props)
  }

  _startSubmitting = async () => {
    this.inProcess = true
    await util.setStatePromise(this, {
      isSubmitting: true,
    })
  }

  _stopSubmitting = async () => {
    this.inProcess = false
    await util.setStatePromise(this, {
      isSubmitting: false,
    })
  }

  _setIsHaveDeleteBtn = (parcels: IParcel[]) =>
    parcels.map((parcel) => ({
      ...parcel,
      isHaveDeleteBtn: false,
    }))

  _fetchMultiParcel = async (order: IOrder) => {
    const { selectedStore } = this.props
    const apiOptions = {
      showSpinner: true,
    }
    const body: { [key: string]: any } = {
      store_id: selectedStore.get(`id`),
      order_id: order.id,
    }
    if (this.inProcess) {
      return
    }
    await this._startSubmitting()
    const res = await api.postV2(api.POST_MULTIPARCEL_LIST, body, apiOptions)
    // console.log('res => ', res)
    const newParcels = this._setIsHaveDeleteBtn(res.parcels)
    // console.log('newParcels => ', newParcels)
    if (res.order_id) {
      await util.setStatePromise(this, {
        multiParcel: newParcels,
        isShowBtnDeleteParcels: newParcels.length > 0,
        isHaveChange: false,
      })
    }
    await this._stopSubmitting()
  }

  _submitMultiParcel = async () => {
    const { selectedStore } = this.props
    const { order, multiParcel } = this.state
    if (_.isNil(order)) {
      return
    }
    const apiOptions = {
      showSpinner: true,
    }
    const parcels = []
    multiParcel.forEach((parcel) => {
      if (_.isNil(parcel.isHaveDeleteBtn)) {
        parcels.push(parcel)
      }
    })
    const body: { [key: string]: any } = {
      store_id: selectedStore.get('id'),
      order_id: order.id,
      parcels,
    }
    if (this.inProcess) {
      return
    }
    await this._startSubmitting()
    const res: {
      order_id: number
      parcels: IParcel[]
    } = await api.postV2(api.POST_MULTIPARCEL_CREATE, body, apiOptions)
    // console.log('res => ', res)
    if (res.order_id) {
      const newParcels = this._setIsHaveDeleteBtn(res.parcels)
      p.op.showToast('สร้างหลายพัสดุเรียบร้อยแล้ว', 'success')
      await util.setStatePromise(this, {
        multiParcel: newParcels,
        isShowBtnDeleteParcels: newParcels.length > 0,
        isHaveChange: false,
      })
    }
    await this._stopSubmitting()
  }

  _deleteParcel = (index: number) => {
    const { multiParcel } = this.state
    if (_.isNil(multiParcel)) {
      return
    }
    const newParcels = []
    multiParcel.forEach((parcel, idx) => {
      if (idx !== index) {
        newParcels.push(parcel)
      }
    })
    this.setState({ multiParcel: newParcels })
  }

  _deleteAllParcel = async () => {
    const { selectedStore } = this.props
    const { order } = this.state

    const confirm = await new Promise<boolean>((passConfirm) => {
      p.op.showConfirmation(
        'ลบพัสดุทั้งหมด',
        'กดปุ่ม "ยืนยัน" หากต้องการลบพัสดุทั้งหมด\nหากพัสดุมีเลขพัสดุจากขนส่งแล้ว จะใช้เวลาในการลบพัสดุนานกว่าปกติ',
        () => passConfirm(true),
        () => passConfirm(false),
        'ยืนยัน',
        'ยกเลิก'
      )
    })
    if (!confirm) {
      return
    }

    if (_.isNil(order)) {
      return
    }
    if (this.inProcess) {
      return
    }
    await this._startSubmitting()
    const apiOptions = {
      showSpinner: true,
      axiosOptions: {
        retry: 0,
        timeout: 15000, // long fetch 15 วินาที
      },
    }
    const body = {
      store_id: selectedStore.get('id'),
      order_id: order.id,
    }

    try {
      const res: {
        order_id: number
        parcels: IParcel[]
      } = await api.postV2(api.POST_MULTIPARCEL_DELETE, body, apiOptions)
      // console.log('res => ', res)
      if (res.order_id) {
        p.op.showToast('ลบรายการพัสดุทั้งหมดเรียบร้อยแล้ว', 'success')
        const newParcels = this._setIsHaveDeleteBtn(res.parcels)
        await util.setStatePromise(this, {
          multiParcel: newParcels,
          isShowBtnDeleteParcels: newParcels.length > 0,
          isHaveChange: false,
        })
      }
    } catch (error) {
      // console.log('ERR => ', error)
      p.op.showConfirmationOkOnly('ระบบกำลังยกเลิกการแบ่งพัสดุ', 'อาจจะใช้เวลาหลายนาที  กรุณาตรวจสอบอีกครั้งภายหลัง', () => this._goBack())
    }
    await this._stopSubmitting()
  }

  _createTackingCode = async () => {
    const { selectedStore } = this.props
    const { order } = this.state
    const apiOptions = {
      showSpinner: true,
    }
    const body: { [key: string]: any } = {
      store_id: selectedStore.get('id'),
      order_id: order.id,
    }
    const res = await api.post(api.POST_CREATE_TACKING_CODE, body, apiOptions)
    if (res && res.x) {
      this._fetchMultiParcel(order)
    } else {
      p.op.showToast('ไม่สามารถสร้างเลขติดตามพัสดุได้ในขณะนี้', 'warning')
    }
  }

  _callBackFormSelectedProduct = (newParcel: IParcel[]) => {
    this.setState({
      multiParcel: newParcel,
      isHaveChange: true,
    })
  }
}
