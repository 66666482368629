import { Map, List, fromJS } from 'immutable'
// import { loop, Effects } from 'reduxStore-loop-symbol-ponyfill'
import _ from 'lodash'
import { IProductDetailItem } from 'x/types'
import * as util from '../../utils/util'
// import api from '../../utils/api'
// import { Alert } from 'react-native'
// import * as NavActions from '../../services/navigation'
import CONS from '../../config/constants'
import {
  getSelectedProductsInProducts,
  getSearchedProductsInProducts,
  setSelectedProductsInProducts,
  setSearchedProductsInProducts,
  getSelectedProductsFetchStatusInProducts,
  getSearchedProductsFetchStatusInProducts,
  setSelectedProductsFetchStatusInProducts,
  setSearchedProductsFetchStatusInProducts,
} from '../../redux/selectors'

import { log } from '../../utils/util'
import { ActionApiParams } from '../../index'
import actions from '../../config/actions'

// FIXME: store in Web || App path ?
// import store from 'reduxStore/store'
// import { log } from '../../utils/util'

const initVariant = fromJS({
  pp_id: null,
  name: '',
  cost: '0',
  qty: '0',
  weight: '0',
  // price: '',
  // price_retail: null,
})

// const initStock = Map({
//   available_qty: null,
//   cost: null,
//   created_at: null,
//   id: null,
//   name: null,
//   pp_id: null,
//   price: null,
//   qty: null,
//   reseller_cost: null,
//   update_at: null,
// })

const initFetchStatus = Map({
  loading: false,
  count: null,
  offset: 0,
  allFetched: false,
})

export const initProduct = Map({
  // Only Add Mode
  store_id: null,
  name: null,
  description: null,
  // category_id: null,
  category_id: 1, // 1 is no category
  img_uris: List([]),
  thumbnail_uris: List([]),
  tiny_img_uris: List([]),
  variants: List([initVariant]),
  // variants: List([]),
  shipping_rates: List([
    // Map({ shipping_type_id: CONS.SHIPPING_TYPE_IDS.REGISTER, init_qty: 1, init_price: '30', next_price: '10' }),
    // Map({ shipping_type_id: CONS.SHIPPING_TYPE_IDS.EMS, init_qty: 1, init_price: '60', next_price: '15' }),
  ]),
  shippingRateStatuses: Map({}),
  // Extended for View/Edit Mode
  created_at: null,
  update_at: null,
  disabled: null,
  master_id: null,
  max: null,
  min: null,
  parent_id: null,

  // vd_type: 0,
  // vds: List([
  //   { begin: 1, end: 5, discount_amount: 0, discount_percent: 0 },
  //   { begin: 6, end: 10, discount_amount: 10, discount_percent: 10 },
  // ]),

  my_vds_type: 0,
  my_vds: List([Map({ begin: 1, end: 5, discount: 0 }), Map({ begin: 6, end: 10, discount: 3 })]),

  // seller_vds_type: 0,
  // seller_vds: List([
  //   { begin: 1, end: 5, discount: 0 },
  //   { begin: 6, end: 10, discount: 10 },
  // ]),

  // seller_vds_type: 1,
  // seller_vds: List([
  //   { begin: 1, end: 5, discount: 0 },
  //   { begin: 6, end: 10, discount: 10 },
  // ]),

  // Init is empty
  product_group_ids: List([]),
  // stocks: List([]), // Refactor API removed
})

// Initial state
const initialState = Map({
  storeProducts: Map({}), // เก็บ product list โดยขึ้นกับ store
  selectedProducts: List([]), // เก็บ product ที่ใช้ render ที่ UI
  searchedProducts: List([]), // เก็บ search result product ที่ใช้ render ที่ UI
  selectedProductsFetchStatus: initFetchStatus,
  searchedProductsFetchStatus: initFetchStatus,
  selectedProduct: initProduct,
  editingProduct: initProduct,
  shouldFetch: false,
})

// Actions
export const ACTION_INITIALIZE = 'ProductState/INITIALIZE'
export const ACTION_SELECTED_PRODUCT_CLEAR = 'ProductState/SELECTED_PRODUCT_CLEAR'
// export const ACTION_SELECTED_PRODUCT_SET = 'ProductState/SELECTED_PRODUCT_SET'
export const ACTION_SELECTED_PRODUCT_FETCH = 'ProductState/SELECTED_PRODUCT_FETCH'

export const ACTION_VIEW_PRODUCT_REVERT = 'ProductState/VIEW_PRODUCT_REVERT'
export const ACTION_VIEW_PRODUCT_CHANGE = 'ProductState/VIEW_PRODUCT_CHANGE'
export const ACTION_VIEW_PRODUCT_CHANGE_VARIANTS = 'ProductState/VIEW_PRODUCT_CHANGE_VARIANTS'
export const ACTION_VIEW_PRODUCT_CHANGE_PRODUCT_GROUPS = 'ProductState/VIEW_PRODUCT_CHANGE_PRODUCT_GROUPS'
export const ACTION_VIEW_PRODUCT_CHANGE_PRODUCT_WAREHOUSE_IDS = 'ProductState/VIEW_PRODUCT_CHANGE_PRODUCT_WAREHOUSE_IDS'
export const ACTION_VIEW_PRODUCT_ADD_VARAIANTS = 'ProductState/VIEW_PRODUCT_ADD_VARAIANTS'
export const ACTION_VIEW_PRODUCT_REMOVE_VARIANTS = 'ProductState/VIEW_PRODUCT_REMOVE_VARAIANTS'
export const ACTION_VIEW_PRODUCT_UNDO_REMOVE_VARIANTS = 'ProductState/VIEW_PRODUCT_UNDO_REMOVE_VARAIANTS'
export const ACTION_VIEW_PRODUCT_TOGGLE_SHIPPING_RATE = 'ProductState/VIEW_PRODUCT_TOGGLE_SHIPPING_RATE'
export const ACTION_VIEW_PRODUCT_CHANGE_SHIPPING_RATE = 'ProductState/VIEW_PRODUCT_CHANGE_SHIPPING_RATE'
export const ACTION_VIEW_PRODUCT_SAVE = 'ProductState/VIEW_PRODUCT_SAVE'

export const ACTION_LIST_PRODUCT_REFRESH = 'ProductState/LIST_PRODUCT_REFRESH'
export const ACTION_LIST_PRODUCT_LOAD_CACHE = 'ProductState/LIST_PRODUCT_LOAD_CACHE'

// Refactor to Saga
export const PRODUCT_CREATE = 'ProductState/PRODUCT_CREATE'
export const PRODUCT_PULL_TO_MY_STORE = 'ProductState/PRODUCT_PULL_TO_MY_STORE'
export const PRODUCT_DELETE = 'ProductState/PRODUCT_DELETE'
export const PRODUCT_EDIT = 'ProductState/PRODUCT_EDIT'
export const FETCH_PRODUCT = 'ProductState/FETCH_PRODUCT'
export const LOAD_PRODUCT = 'ProductState/LOAD_PRODUCT'
export const LOAD_CLONE_PRODUCT = 'ProductState/LOAD_CLONE_PRODUCT'

export const FETCH_PRODUCT_LIST = 'ProductState/FETCH_PRODUCT_LIST'
export const FETCH_SELLER_PRODUCT_LIST = 'ProductState/FETCH_SELLER_PRODUCT_LIST'
export const NEW_SEARCH_PRODUCT_LIST = 'ProductState/NEW_SEARCH_PRODUCT_LIST'

export const LOAD_PRODUCT_LIST = 'ProductState/LOAD_PRODUCT_LIST'
export const REMOVE_PULLED_PRODUCT_FROM_LIST = 'ProductState/REMOVE_PULLED_PRODUCT_FROM_LIST'
export const CLEAR_PRODUCT_LIST = 'ProductState/CLEAR_PRODUCT_LIST'
export const RESET_SEARCH_RESULTS = 'ProductState/RESET_SEARCH_RESULTS'
// export const REMOVE_PRODUCT_FROM_PRODUCT_LIST = 'ProductState/REMOVE_PRODUCT_FROM_PRODUCT_LIST'

export const SHOULD_FETCH_LIST = 'ProductState/SHOULD_FETCH_LIST'

export const ERROR_PRODUCT_CREATE = 'ProductState/ERROR_PRODUCT_CREATE'
export const ERROR_PRODUCT_DELETE = 'ProductState/ERROR_PRODUCT_DELETE'
export const ERROR_PRODUCT_EDIT = 'ProductState/ERROR_PRODUCT_EDIT'
export const ERROR_FETCH_PRODUCT_LIST = 'ProductState/ERROR_PRODUCT_CREATE'

// Action creators
export function init() {
  return { type: ACTION_INITIALIZE }
}

// export function selectedProductSet(product) {
//   return {
//     type: ACTION_SELECTED_PRODUCT_SET,
//     payload: product,
//   }
// }

export function fetchProduct(params: {
  body: { [key: string]: any }
  mode: string
  user_group_id?: number
  product_group_ids?: number[]
  successCallback?: (res: Response) => void
  failedCallback?: (err: Error) => void
}) {
  return {
    type: FETCH_PRODUCT,
    ...params,
  }
}

export function selectedProductClear() {
  return {
    type: ACTION_SELECTED_PRODUCT_CLEAR,
  }
}

export function revertProduct(callback: () => void) {
  return {
    type: ACTION_VIEW_PRODUCT_REVERT,
    callback,
  }
}

export function onChangeProduct({ key, value }) {
  return {
    type: ACTION_VIEW_PRODUCT_CHANGE,
    payload: { key, value },
  }
}

export function onChangeVariant({ idx, key, value, isMoney, isInteger, isNumber }) {
  const newValue = value
  // if (isMoney) {
  //   // newValue = util.validateInputCurrency(value)
  //   newValue = extractMoneyToNumber(value)
  // } else if (isInteger) {
  //   newValue = util.validateInputInteger(value)
  // } else if (isNumber) {
  //   newValue = parseFloat(value)
  // }

  return {
    type: ACTION_VIEW_PRODUCT_CHANGE_VARIANTS,
    payload: { idx, key, value: newValue },
  }
}

export function onChangeProductGroups(newPgIds: number[]) {
  return {
    type: ACTION_VIEW_PRODUCT_CHANGE_PRODUCT_GROUPS,
    payload: newPgIds,
  }
}

export function onChangeProductWarehouseIds(newWarehouseIds: number[]) {
  return {
    type: ACTION_VIEW_PRODUCT_CHANGE_PRODUCT_WAREHOUSE_IDS,
    payload: newWarehouseIds,
  }
}

export function onToggleShippingRate({ key, val }) {
  return {
    type: ACTION_VIEW_PRODUCT_TOGGLE_SHIPPING_RATE,
    payload: { key, val },
  }
}

export function onChangeShippingRate({ idx, shipping_type_id, key, value, isMoney, isInteger }) {
  let newValue = value
  if (isMoney) {
    newValue = util.validateInputCurrency(value)
  }
  if (isInteger) {
    newValue = util.validateInputInteger(value)
  }
  return {
    type: ACTION_VIEW_PRODUCT_CHANGE_SHIPPING_RATE,
    payload: { idx, shipping_type_id, key, value: newValue },
  }
}

export function addProductVariant() {
  return {
    type: ACTION_VIEW_PRODUCT_ADD_VARAIANTS,
  }
}

export function removeProductVariant(index) {
  return {
    type: ACTION_VIEW_PRODUCT_REMOVE_VARIANTS,
    payload: index,
  }
}

export function undoRemoveProductVariant(index) {
  return {
    type: ACTION_VIEW_PRODUCT_UNDO_REMOVE_VARIANTS,
    payload: index,
  }
}

export function addProduct(params: ActionApiParams) {
  return {
    type: PRODUCT_CREATE,
    ...params,
  }
}

export function pullProductToMyStore(params: ActionApiParams) {
  return {
    type: PRODUCT_PULL_TO_MY_STORE,
    ...params,
  }
}

export function deleteProduct(params: ActionApiParams) {
  return {
    type: PRODUCT_DELETE,
    ...params,
  }
}

export function editProduct(params: ActionApiParams) {
  return {
    type: PRODUCT_EDIT,
    ...params,
  }
}

// export function fetchProductList({ store_id, v }) {
//   return {
//     type: FETCH_PRODUCT_LIST,
//     payload: v ? { store_id, v } : { store_id },
//   }
// }

export function resetSearchResults() {
  return { type: RESET_SEARCH_RESULTS }
}

// export function fetchProducts({ payload, isNewSearch = false }, callback) {
//   if (isNewSearch) {
//     // log('ProductState.isNewSearch')
//     return { type: NEW_SEARCH_PRODUCT_LIST, payload, callback }
//
//   } else {
//     // log('ProductState.is NOT a new search')
//     return { type: FETCH_PRODUCT_LIST, payload, callback }
//   }
// }
export function fetchProducts(params: ActionApiParams) {
  if (_.has(params, 'isNewSearch') && params.isNewSearch) {
    return {
      ...params,
      type: NEW_SEARCH_PRODUCT_LIST,
    }
  }
  return {
    ...params,
    type: FETCH_PRODUCT_LIST,
  }
}

export function shoudFetchProductList() {
  return { type: SHOULD_FETCH_LIST }
}

// export function fetchSellerProductList({ store_id, seller_store_id }) {
//   return {
//     type: FETCH_SELLER_PRODUCT_LIST,
//     payload: { store_id, seller_store_id },
//   }
// }

export function loadProduct(product: IProductDetailItem, shouldFetchList?: boolean, shouldUpdateLocalList = false) {
  // JS Array of Object
  return {
    type: LOAD_PRODUCT,
    payload: { product, shouldFetchList, shouldUpdateLocalList },
  }
}

export function loadCloneProduct() {
  // JS Array of Object
  return {
    type: LOAD_CLONE_PRODUCT,
  }
}

export function loadProductList(payload) {
  // JS Array of Object
  return {
    type: LOAD_PRODUCT_LIST,
    payload,
  }
}

export function removePulledProductFromList(productId) {
  // JS Array of Object
  return {
    type: REMOVE_PULLED_PRODUCT_FROM_LIST,
    payload: productId,
  }
}

// export function loadProductListMore(products) { // JS Array of Object
//   return {
//     type: LOAD_PRODUCT_LIST_MORE,
//     payload: products,
//   }
// }

export function productListClear() {
  return {
    type: CLEAR_PRODUCT_LIST,
  }
}

// //FIXME: This is mock to Edit Product
// export function editProduct(text, callback) {
//   return (dispatch, getState) => {
//     const selectedProductJS = getState().getIn(['products', 'selectedProduct']).toJS()
//     const oldStocks = selectedProductJS.variants
//     let productStateJS = getState().getIn(['products', 'editingProduct']).toJS()
//
//     // แปลงข้อมูล stocks
//     let fetchVariants = []
//
//     const variantsFields = ['name', 'qty', 'cost', 'price']
//     const vKeyToAPI = {
//       name: { key: 'name', type: 'string' },
//       qty: { key: 'qty', type: 'int' },
//       cost: { key: 'cost', type: 'float' },
//       price: { key: 'price', type: 'float' },
//     }
//
//     productStateJS.variants.forEach((v) => {
//       let variantIsChanged = false
//       let initNewVariant = {
//         pp_id: parseInt(v.pp_id),
//         // name: v.name,
//         // qty: parseInt(v.available_qty),
//         // cost: parseFloat(v.c), // Fixed for backend using c
//         // price: parseFloat(v.price),
//       }
//
//       const foundIndex = _.findIndex(oldStocks, (ov) => { return ov.pp_id === v.pp_id })
//       if (foundIndex >= 0) {
//         // log('foundIndex : ', foundIndex)
//         // log('v : ', v)
//         variantIsChanged = variantsFields.reduce((prevBoolean, key) => {
//           const vChanged = v[key] !== oldStocks[foundIndex][key]
//           if (vChanged) {
//             initNewVariant[vKeyToAPI[key].key] = parseByType(v[key], vKeyToAPI[key].type)
//           }
//           return prevBoolean || vChanged
//         }, false)
//       }
//
//       if (!variantIsChanged && foundIndex < 0) {
//         initNewVariant['action'] = 'ADD'
//       } else if (variantIsChanged && foundIndex >= 0) {
//         initNewVariant['action'] = 'EDIT'
//       }
//
//       if ('action' in initNewVariant) {
//         fetchVariants.push(initNewVariant)
//       }
//
//       // if (variantIsChanged) {
//       //   fetchVariants.push(initNewVariant)
//       // }
//
//     })
//
//     const { store_id, id, description } = productStateJS
//     let body = {}
//
//     if (new String(selectedProductJS.description).valueOf() !== new String(description).valueOf()) {
//       body['description'] = description
//     }
//
//     //FIXME: Manual check diff and doesn't care about thumbnail for now
//     const oldImgs = selectedProductJS.img_uris
//     const newImgs = productStateJS.img_uris
//     if (oldImgs.length === newImgs.length) {
//       for (let i = 0; i < productStateJS.img_uris.length; i++) {
//         if (new String(oldImgs[i]).valueOf() !== new String(newImgs[i]).valueOf()) {
//           body['img_uris'] = newImgs
//           break
//         }
//       }
//     } else {
//       body['img_uris'] = newImgs
//     }
//
//     if (fetchVariants.length > 0) {
//       body['variants'] = fetchVariants
//     }
//
//     const apiOptions = {
//       messages: {
//         successMsg: 'แก้ไขสินค้าสำเร็จ',
//         errorMsg: 'เกิดข้อผิดพลาดในดำเนินการแก้ไขสินค้า',
//       },
//       showSpinner: true,
//     }
//     if (!_.isEmpty(body)) {
//       body['store_id'] = store_id
//       body['product_id'] = id
//       log('_THUNK_editProduct__body_: ', body)
//       api.patch(api.PATCH_PRODUCT, body, apiOptions).then(res => {
//         log('_THUNK_editProduct__res_: ', res)
//         const { VIEW_SELF } = CONS.PRODUCT_VIEW_MODE
//         loadProductFromResponse(dispatch, VIEW_SELF, res)
//       }).catch(error => {
//         log('_THUNK_editProduct__res_: ', error.message)
//         let errObj = JSON.parse(error.message)
//         Alert.alert('เกิดข้อผิดพลาด', errObj.error.message)
//       })
//
//       log('editProduct was Trigged ', text)
//       log('editProduct prepared body => ', body)
//       callback(body)
//     } else {
//       Alert.alert('แจ้งเตือน', 'ไม่มีรายการแก้ไข')
//     }
//
//   }
// }

export function useCacheProductList(key) {
  return {
    type: ACTION_LIST_PRODUCT_LOAD_CACHE,
    payload: { key },
  }
}

// export function pullProductsToOrder(test) {
//   return {
//     type: ACTION_LIST_PRODUCT_LOAD_CACHE,
//     payload: { key },
//   }
// }

// Products
export function updateDataVolumeDiscountAndProductGroup(data: Array<{ vd_id: number; pg_id: number }>) {
  return {
    payload: data,
    type: actions.UPDATA_DATA_VOLUME_DISCOUNT_AND_PRODUCT_GOUP,
  }
}

export function londProductsFromVolumeDiscount(params: ActionApiParams) {
  return {
    type: actions.LOND_PRODUCTS_FROM_VOLUME_DISCOUNT,
    ...params,
  }
}

export function setProductsFromVolumeDiscount(productFromVolumeDisccount: any) {
  return {
    type: actions.SET_PRODUCTS_FROM_VOLUME_DISCOUNT,
    payload: productFromVolumeDisccount,
  }
}

// Reducer
export default function ProductStateReducer(state = initialState, action: ActionApiParams = {}) {
  const { type, payload, callback } = action
  let newState = state

  switch (type) {
    case ACTION_INITIALIZE:
    case ACTION_SELECTED_PRODUCT_CLEAR:
      newState = newState.set('selectedProduct', initProduct)
      return newState.set('editingProduct', initProduct)
    // case ACTION_SELECTED_PRODUCT_SET:
    //   newState = newState.set('selectedProduct', action.payload)
    //   return newState.set('editingProduct', action.payload)
    case ACTION_SELECTED_PRODUCT_FETCH:
      newState = newState.set('selectedProduct', payload)
      return newState.set('editingProduct', payload)

    // เกี่ยวกับ product add/view/edit ---
    case ACTION_VIEW_PRODUCT_REVERT:
      if (_.isFunction(callback)) {
        setTimeout(callback, 200)
      }
      return newState.set('editingProduct', newState.get('selectedProduct'))
    case ACTION_VIEW_PRODUCT_CHANGE:
      // return newState.mergeDeepIn(['editingProduct'], payload)
      return newState.setIn(['editingProduct', payload.key], payload.value)
    case ACTION_VIEW_PRODUCT_CHANGE_VARIANTS:
      return handleChangeProductVariant(state, payload)
    // return newState.setIn(['editingProduct', 'variants',
    //   payload.idx, payload.key], payload.val)
    case ACTION_VIEW_PRODUCT_CHANGE_PRODUCT_GROUPS:
      return handleChangeProductGroups(state, payload)
    case ACTION_VIEW_PRODUCT_CHANGE_PRODUCT_WAREHOUSE_IDS:
      return handleChangeProductWarehouses(state, payload)
    case ACTION_VIEW_PRODUCT_ADD_VARAIANTS:
      return newState.updateIn(['editingProduct', 'variants'], (v) => v.push(duplicateVariant(state)))
    case ACTION_VIEW_PRODUCT_REMOVE_VARIANTS: {
      // const variants = newState.getIn(['editingProduct', 'variants'])
      // return newState.setIn(['editingProduct', 'variants'], deleteImmutableListAtIndex(variants, payload))
      return newState.setIn(['editingProduct', 'variants', payload, 'isDeleted'], true)
    }
    case ACTION_VIEW_PRODUCT_UNDO_REMOVE_VARIANTS: {
      return newState.setIn(['editingProduct', 'variants', payload, 'isDeleted'], false)
    }
    // return newState.updateIn(['editingProduct', 'variants'], v => v.delete(payload))
    case ACTION_VIEW_PRODUCT_TOGGLE_SHIPPING_RATE: {
      const shippingTypeId = _.isString(payload.key) ? parseInt(payload.key) : payload.key
      const shipRateIdx = newState
        .getIn(['editingProduct', 'shipping_rates'])
        .findIndex((sr) => sr && sr.get('shipping_type_id') === shippingTypeId)
      if (shipRateIdx === -1) {
        let newShippingRates = newState.getIn(['editingProduct', 'shipping_rates'])
        newShippingRates = newShippingRates.push(Map({ shipping_type_id: shippingTypeId, init_qty: 1, init_price: '30', next_price: '15' }))
        newState = newState.setIn(['editingProduct', 'shipping_rates'], newShippingRates)
      }
      return newState.setIn(['editingProduct', 'shippingRateStatuses', payload.key], payload.val)
    }
    case ACTION_VIEW_PRODUCT_CHANGE_SHIPPING_RATE: {
      const { idx, shipping_type_id, key, value } = payload
      // const shipRateIdx = newState
      //   .getIn(['editingProduct', 'shipping_rates'])
      //   .findIndex(sr => sr && sr.get('shipping_type_id') === shipping_type_id)
      // if (shipRateIdx > -1) {
      //   return newState.setIn(['editingProduct', 'shipping_rates', idx, key], value)
      // }
      // let newShippingRates = newState.getIn(['editingProduct', 'shipping_rates'])
      // newShippingRates = newShippingRates.push(Map({ shipping_type_id, init_qty: 1, init_price: '30', next_price: '15' }))
      // newState = newState.setIn(['editingProduct', 'shipping_rates'], newShippingRates)
      // return newState
      return newState.setIn(['editingProduct', 'shipping_rates', idx, key], value)
    }
    case ACTION_VIEW_PRODUCT_SAVE:
      newState = newState.set('selectedProduct', initProduct)
      return newState.set('editingProduct', initProduct)
    // เกี่ยวกับ product list (storeProducts)
    case ACTION_LIST_PRODUCT_REFRESH:
      newState = newState.setIn(['storeProducts', payload.key], payload.data)
      return setSelectedProductsInProducts(newState, newState.getIn(['storeProducts', payload.key]))
    // return newState.set('selectedProducts', newState.getIn(['storeProducts', payload.key]))
    case ACTION_LIST_PRODUCT_LOAD_CACHE:
      return setSelectedProductsInProducts(newState, newState.getIn(['storeProducts', payload.key]))
    // return newState.set('selectedProducts', newState.getIn(['storeProducts', payload.key]))

    // Refactor
    case LOAD_PRODUCT: {
      const { product } = payload
      newState = newState.set('selectedProduct', product)
      newState = newState.set('editingProduct', product)
      if (payload.shouldFetchList) {
        return newState.set(CONS.PRODUCT_FETCH_STATUS.SHOULD_FETCH, true)
      }
      if (payload.shouldUpdateLocalList) {
        // update the local redux with the new product
        let selectedProducts = getSelectedProductsInProducts(newState)
        log('LOAD_PRODUCT.product', product.toJS())
        log('LOAD_PRODUCT.selectedProducts', selectedProducts)
        const toBeUpdatedProductId = product.get('id')
        log('LOAD_PRODUCT.toBeUpdatedProductId', toBeUpdatedProductId)
        if (!toBeUpdatedProductId || isNaN(toBeUpdatedProductId)) {
          return newState
        }
        const toBeUpdatedIdx = selectedProducts.findIndex((entry, idx) => {
          if (entry.get('id') === toBeUpdatedProductId) {
            // log('Found productId: ' + productId + ' at index ' + idx)
            log(`Found productId: ${entry.get('id')} at index ${idx}`)
            return true
          }
        })
        log('LOAD_PRODUCT.toBeUpdatedIdx', toBeUpdatedIdx)
        if (toBeUpdatedIdx >= 0 && toBeUpdatedIdx < selectedProducts.size) {
          selectedProducts = selectedProducts.set(toBeUpdatedIdx, product)
          return setSelectedProductsInProducts(newState, selectedProducts)
        }
        selectedProducts = List([fromJS(product)]).concat(selectedProducts) // Prepend
        let newFetchStatus = getSelectedProductsFetchStatusInProducts(newState)
        const currentCount = newFetchStatus.has('count') ? newFetchStatus.get('count') : null
        if (!_.isNil(currentCount)) {
          newFetchStatus = newFetchStatus.set('count', currentCount + 1)
          newState = setSelectedProductsFetchStatusInProducts(newState, newFetchStatus)
        }
        return setSelectedProductsInProducts(newState, selectedProducts)
      }
      return newState
    }
    case LOAD_CLONE_PRODUCT: {
      newState = newState.setIn(['selectedProduct', 'id'], null)
      const vs = newState.getIn(['selectedProduct', 'variants'])
      const variantCount = vs.size
      for (let i = 0; i < variantCount; i++) {
        newState = newState.setIn(['selectedProduct', 'variants', i, 'pp_id'], null)
        newState = newState.setIn(['selectedProduct', 'variants', i, 'sku'], null)
        newState = newState.setIn(['selectedProduct', 'variants', i, 'upc'], null)
      }
      newState = newState.set('editingProduct', newState.get('selectedProduct'))
      return newState
    }
    case CLEAR_PRODUCT_LIST:
      // newState = setSelectedProducts(newState, List([]))
      newState = setSelectedProductsInProducts(newState, initialState.get('selectedProducts'))
      newState = setSearchedProductsInProducts(newState, initialState.get('searchedProducts'))
      newState = setSelectedProductsFetchStatusInProducts(newState, initialState.get('selectedProductsFetchStatus'))
      newState = setSearchedProductsFetchStatusInProducts(newState, initialState.get('searchedProductsFetchStatus'))
      // newState = newState.set('selectedProducts', List([]))
      return newState
    case RESET_SEARCH_RESULTS:
      newState = setSearchedProductsInProducts(newState, initialState.get('searchedProducts'))
      newState = setSearchedProductsFetchStatusInProducts(newState, initialState.get('searchedProductsFetchStatus'))
      return newState
    case SHOULD_FETCH_LIST:
      return newState.set(CONS.PRODUCT_FETCH_STATUS.SHOULD_FETCH, true)
    case REMOVE_PULLED_PRODUCT_FROM_LIST: {
      let selectedProducts = getSelectedProductsInProducts(newState)
      // p.op.alert('selectedProducts')
      const productId = payload
      // p.op.alert('productId', ""+productId)

      // log('trying to remove product id: ' + productId + ' from size: ' + selectedProducts.size)
      const toBeRemoveIdx = selectedProducts.findIndex((entry, idx) => {
        if (entry.get('id') === productId) {
          // log('Found productId: ' + productId + ' at index ' + idx )
          return true
        }
      })
      // p.op.alert('toBeRemoveIdx', ''+ toBeRemoveIdx + ' with size before remove: ' + selectedProducts.size)

      if (toBeRemoveIdx >= 0 && toBeRemoveIdx < selectedProducts.size) {
        // 1) remove the pulled product from redux
        // log('removing the pulled product from index: ' + toBeRemoveIdx)
        // selectedProducts = selectedProducts.delete(toBeRemoveIdx)
        // selectedProducts = selectedProducts.deleteIn([toBeRemoveIdx])
        selectedProducts = util.deleteImmutableListAtIndex(selectedProducts, toBeRemoveIdx)
        // 2) set new count to shouldInitLoadWithCount as a dirty flag,
        // so sagas can reload the whole list from the beginning to the current load more index
        const fsJS = getSelectedProductsFetchStatusInProducts(newState).toJS()
        fsJS[CONS.PRODUCT_FETCH_STATUS.SHOULD_INIT_LOAD_WITH_LIMIT] = fsJS.offset + CONS.PRODUCTS_FETCH_LIMIT_MORE
        fsJS.offset = 0
        fsJS.count -= 1
        // p.op.alert('fsJS')
        // p.op.alert('Did remove ', 'At index: ' + toBeRemoveIdx)
        newState = setSelectedProductsFetchStatusInProducts(newState, fromJS(fsJS))
      }
      // log('newState Before saving: ' + newState.toJS())
      // p.op.alert('selectedProducts Final***', 'size: ' + selectedProducts.size)
      return setSelectedProductsInProducts(newState, selectedProducts)
    }
    case LOAD_PRODUCT_LIST:
      let s = newState
      const { products, count } = payload.res
      const { reqOffset, reqLimit, queryTxt } = payload
      const isSearch = queryTxt && queryTxt.length > 0
      let getFetchStatus
      let setProducts
      let getProductsInProducts
      let setFetchStatus

      if (isSearch) {
        getFetchStatus = getSearchedProductsFetchStatusInProducts
        setFetchStatus = setSearchedProductsFetchStatusInProducts

        setProducts = setSearchedProductsInProducts
        getProductsInProducts = getSearchedProductsInProducts
      } else {
        getFetchStatus = getSelectedProductsFetchStatusInProducts
        setFetchStatus = setSelectedProductsFetchStatusInProducts
        setProducts = setSelectedProductsInProducts
        getProductsInProducts = getSelectedProductsInProducts
      }
      // log('products')
      // log(products)
      // update fetchStatus
      // let fetchStatus = isSearch ? getFetchStatus(s) : getFetchStatus(s)
      let fetchStatus = getFetchStatus(s)
      // let fetchStatus = getSelectedProductsFetchStatusInProducts(s)
      if (fetchStatus) {
        fetchStatus = fetchStatus.toJS()
        // NOTE: Intentionally ignore setting CONS.PRODUCT_FETCH_STATUS.SHOULD_INIT_LOAD_WITH_LIMIT
        // as it should be used only once.
        delete fetchStatus[CONS.PRODUCT_FETCH_STATUS.SHOULD_INIT_LOAD_WITH_LIMIT]
      } else {
        fetchStatus = {}
      }
      // fetchStatus.queryTxt = queryTxt
      // fetchStatus.lastUpdate = new Date()
      // log('fetchStatus 1', fetchStatus)
      // log('payload', payload)

      fetchStatus.loading = false
      // if (count) {
      if ('count' in payload.res) {
        // update the count if available
        fetchStatus.count = count
        // log('fetchStatus 1.5 (has count)', fetchStatus)
      }

      // log('reqOffset: ' + reqOffset + ' reqLimit: ' + reqLimit)
      const requestCount = reqOffset + reqLimit
      // TODO: Keng availableCount is NAN FIX IT
      // log('fetchStatus 2', fetchStatus)

      // let availableCount = products.length + fetchStatus.count
      // log('requestCount: ' + requestCount + ' availableCount: ' + availableCount)

      if (products.length === 0) {
        // log('block 2')
        fetchStatus.offset = 0
        s = setProducts(s, fromJS(products))
      } else if (payload.reqOffset === 0) {
        // log('block 3')
        // This is either initial or refresh load; Simply override the existing one if any
        const sortedVariantsProducts = _.cloneDeep(products)
        for (let i = 0; i < sortedVariantsProducts.length; i++) {
          const pt = sortedVariantsProducts[i]
          if (!pt.v || pt.v.length <= 1) {
            // log('sort no variant of product => ', pt)
            continue
          }
          sortedVariantsProducts[i].v.sort(compareProductListItemVarintById)
        }
        // s = setProducts(s, fromJS(products))
        s = setProducts(s, fromJS(sortedVariantsProducts))
        // s = setSelectedProducts(s, fromJS(products))
      } else {
        // log('block 4')

        // more products are available. append to existing list and update fetch status accordingly
        const currProducts = getProductsInProducts(s)
        // let currProducts = getSelectedProductsInProducts(s)
        // log('*****s')
        // log(s)
        // log('currProducts')
        // log(currProducts)
        const sortedVariantsProducts = _.cloneDeep(products)
        for (let i = 0; i < sortedVariantsProducts.length; i++) {
          const pt = sortedVariantsProducts[i]
          if (!pt.v || pt.v.length <= 1) {
            // log('sort no variant of product => ', pt)
            continue
          }
          sortedVariantsProducts[i].v.sort(compareProductListItemVarintById)
        }

        if (currProducts) {
          // let appendedProducts = currProducts.toJS().concat(payload)
          // let appendedProducts = currProducts.concat(products)
          // const appendedProducts = currProducts.concat(fromJS(products))
          const appendedProducts = currProducts.concat(fromJS(sortedVariantsProducts))
          // log('appendedProducts', appendedProducts)
          s = setProducts(s, appendedProducts)
        } else {
          // s = setProducts(s, fromJS(products))
          s = setProducts(s, fromJS(sortedVariantsProducts))
        }
      }
      // if (availableCount <= requestCount) {
      //   log('block 1')
      //   fetchStatus.allFetched = true
      // }
      // Calculate allFetched flag
      // if (fetchStatus.count) {
      if ('count' in fetchStatus) {
        // use in as count could be zero
        // if (availableCount <= requestCount) {
        if (requestCount >= fetchStatus.count) {
          // if (requestCount > availableCount) {
          // available products count is less that request offset + limit
          // set the offset to the availableCount and mark allFetched as done
          // fetchStatus.offset = availableCount
          fetchStatus.offset = fetchStatus.count
          fetchStatus.allFetched = true
          // log('fetchStatus 3')
          // log(fetchStatus)
        } else {
          // could be more products to fetch. Set param accordingly
          fetchStatus.offset = requestCount // update offset to the next idx to load
          fetchStatus.allFetched = false
          // log('fetchStatus 4')
          // log(fetchStatus)
        }
      } else {
        // should never happen as fetchStatus.count should be available at this point
        // (or server wrongly didn't return)
        // try to set reload status again
        fetchStatus.offset = products.length
        fetchStatus.allFetched = false
        // log('fetchStatus 5')
        // log(fetchStatus)
      }
      // log('fetchStatus FINAL', fetchStatus)
      s = setFetchStatus(s, fromJS(fetchStatus))
      // TODO: Think about what to do with shouldFetch
      s = s.set('shouldFetch', false)
      return s

    // case REMOVE_PRODUCT_FROM_PRODUCT_LIST:
    //   let newSelectedProducts = getSelectedProductsInProducts(newState)
    //   const foundRemoveProductId = newSelectedProducts.findIndex(spd => spd.get('id') === payload.id)
    //   if (foundRemoveProductId > -1) {
    //     newSelectedProducts.deleteImmutableListAtIndex(newSelectedProducts, foundRemoveProductId)
    //   }
    //   return setSelectedProductsInProducts(newState, newSelectedProducts)
    // newState = setSelectedProducts(newState, fromJS(payload))
    // return newState.set('shouldFetch', false)

    case actions.UPDATA_DATA_VOLUME_DISCOUNT_AND_PRODUCT_GOUP: {
      // newState = newState.setIn(['selectedProduct', 'pg_vd_ids'], fromJS(payload))
      newState = newState.setIn(['editingProduct', 'pg_vd_ids'], fromJS(payload))
      return newState
    }

    case actions.SET_PRODUCTS_FROM_VOLUME_DISCOUNT: {
      newState = newState.setIn(['selectedProducts'], fromJS(payload.product))
      return newState
    }

    default:
      return state
  }
}

// internal function
function duplicateVariant(currentState) {
  // return newVariant
  const variants = currentState.getIn(['editingProduct', 'variants']) || List([])
  if (variants.size > 0) {
    let lastVariant = variants.get(variants.size - 1) || initVariant // copy all variants or empty if have accidents
    if (lastVariant.get('pp_id')) {
      lastVariant = lastVariant.set('pp_id', null) // set Empty ppd_id if have
    }
    if (lastVariant.get('qty')) {
      lastVariant = lastVariant.set('qty', '0') // set Empty ppd_id if have
    }
    lastVariant = lastVariant.set('name', '') // set Empty name
    return lastVariant
  }
  return initVariant
}

function handleChangeProductVariant(state: Map<string, any>, payload: { idx: number; key: string; value: any }): Map<string, any> {
  let newState = state
  const { idx, key, value } = payload
  if (!_.isNumber(idx) || !_.isString(key)) {
    return state
  }
  const typingCountKey = `typingCount_${key}`
  const oldTypingCount = newState.getIn(['editingProduct', 'variants', idx, typingCountKey]) || 0
  newState = newState.setIn(['editingProduct', 'variants', idx, key], value)
  newState = newState.setIn(['editingProduct', 'variants', idx, typingCountKey], oldTypingCount + 1)
  return newState
}

function extractMoneyToNumber(textValue: string | number): number {
  let text = _.isNull(textValue) || _.isUndefined(textValue) ? '' : textValue
  if (!_.isString(text)) {
    text = text.toString()
  }
  const passText = text ? text.replace(/฿/g, '') : ''
  const isNumber = !_.isNaN(passText)
  if (isNumber) {
    return parseFloat(passText)
  }
  return 0
}

function handleChangeProductGroups(state: Map<string, any>, newPgIds: number[]): Map<string, any> {
  let newState = state
  if (!newPgIds || newPgIds.length === 0) {
    return newState.setIn(['editingProduct', 'product_group_ids'], List([]))
  }
  let variants = newState.getIn(['editingProduct', 'variants']) || List([])
  variants.forEach((v: Map<string, any>, vIndex: number) => {
    newPgIds.forEach((newPgId) => {
      const pgPriceKey = `price_${newPgId}`
      if (!v.has(pgPriceKey)) {
        variants = variants.setIn([vIndex, pgPriceKey], '0')
      }
    })
  })
  newState = newState.setIn(['editingProduct', 'product_group_ids'], List(newPgIds))
  newState = newState.setIn(['editingProduct', 'variants'], variants)
  return newState
}

function handleChangeProductWarehouses(state: Map<string, any>, newWhIds: number[]): Map<string, any> {
  let newState = state
  if (!newWhIds || newWhIds.length === 0) {
    return newState.setIn(['editingProduct', 'warehouse_ids'], List([]))
  }
  let variants = newState.getIn(['editingProduct', 'variants']) || List([])
  variants.forEach((v: Map<string, any>, vIndex: number) => {
    newWhIds.forEach((newWhId) => {
      const whQtyKey = `qty_${newWhId}`
      if (!v.has(whQtyKey)) {
        variants = variants.setIn([vIndex, whQtyKey], '0')
      }
    })
  })
  newState = newState.setIn(['editingProduct', 'warehouse_ids'], List(newWhIds))
  newState = newState.setIn(['editingProduct', 'variants'], variants)
  return newState
}

function compareProductListItemVarintById(variantA, variantB) {
  try {
    if (variantA.i > variantB.i) {
      return 1
    }
    if (variantB.i > variantA.i) {
      return -1
    }
  } catch (err) {
    log('compareProductListItemVarintById error => ', err)
  }
  return 0
}
