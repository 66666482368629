import React from 'react'
import _ from 'lodash'
import { Map } from 'immutable'
import dayjs, { Dayjs } from 'dayjs'
import CONS from 'x/config/constants'
import { IXScreenProps, IXSegmentOption } from 'x/types'
import * as xAcl from 'x/utils/acl'
import * as util from '../../utils/util'
import p from '../../config/platform-specific'

export interface BaseReportPeakViewProps extends IXScreenProps {
  selectedStore: Map<string, any>
  subscription: any
}

export interface BaseReportPeakViewState {
  isLoading: boolean
  loadingWidth: number
  loadingHeight: number
  selectedDateRange: { begin: Dayjs; end: Dayjs }
  latestOrderPaymentStatusOptionIndex: number
  latestOrderShippingStatusOptionIndex: number
  payment_states: number[] | undefined
  shipping_states: number[] | undefined
  date_type: number
}

const startNewDate = dayjs().format('YYYY-MM-DD 00:00:00')
const endNewDate = dayjs().format('YYYY-MM-DD 23:59:59')

export default abstract class BaseReportPeakView extends React.Component<BaseReportPeakViewProps, BaseReportPeakViewState> {
  abstract _handleOnDownloadFile(url: string, fileName: string): Promise<void>

  protected constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      loadingHeight: 0,
      loadingWidth: 0,
      selectedDateRange: { begin: dayjs(startNewDate), end: dayjs(endNewDate) },
      latestOrderPaymentStatusOptionIndex: 0,
      latestOrderShippingStatusOptionIndex: 0,
      payment_states: undefined,
      shipping_states: undefined,
      date_type: 0,
    }
  }

  async componentDidMount() {
    await p.op.storageGet(CONS.STORAGE_KEYS.REPORT_PEAK).then(async (val: any) => {
      // console.log('val => ', val)
      if (!_.isNil(val)) {
        // console.log('PASS => ')
        await util.setStatePromise(this, {
          date_type: val.date_type,
          latestOrderPaymentStatusOptionIndex: val.latestOrderPaymentStatusOptionIndex,
          latestOrderShippingStatusOptionIndex: val.latestOrderShippingStatusOptionIndex,
          payment_states: val.payment_states,
          shipping_states: val.shipping_states,
        })
      }
    })
  }

  _onChangeDateRange = (newDates: { begin: Dayjs; end: Dayjs }) => {
    this.setState({ selectedDateRange: newDates })
  }

  onSegmentOrderPaymentStatusChange = (option: IXSegmentOption<number[] | undefined>, latestOrderPaymentStatusOptionIndex: number) => {
    this.setState({
      latestOrderPaymentStatusOptionIndex,
      payment_states: option.value,
    })
  }

  onSegmentOrderShippingStatusChange = (option: IXSegmentOption<number[] | undefined>, latestOrderShippingStatusOptionIndex: number) => {
    this.setState({
      latestOrderShippingStatusOptionIndex,
      shipping_states: option.value,
    })
  }

  _showConfirmationDownloadReport = async () => {
    const { selectedStore } = this.props
    const {
      shipping_states,
      payment_states,
      selectedDateRange,
      date_type,
      latestOrderPaymentStatusOptionIndex,
      latestOrderShippingStatusOptionIndex,
    } = this.state

    const canDo = xAcl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.REPORT_ORDER, CONS.REPORT_SET_BIT_BINARY.report_peak_account)
    if (!canDo) {
      p.op.showConfirmationOkOnly('', 'คุณไม่ได้รับสิทธิ์ใช้งานรายงาน PEAK Account')
      return
    }
    const STORAGE = {
      date_type,
      shipping_states,
      payment_states,
      latestOrderPaymentStatusOptionIndex,
      latestOrderShippingStatusOptionIndex,
    }

    const store_id = selectedStore.get(`id`)
    const start_date = dayjs(selectedDateRange.begin).format('DD-MM-YYYY%20HH:mm:00')
    const end_date = dayjs(selectedDateRange.end).format('DD-MM-YYYY%20HH:mm:59')
    const dateType = date_type === 0 ? 'r' : date_type === 1 ? 'p' : date_type === 2 ? 's' : 'd'
    const url = util.getReportPeakUrl({ store_id, start_date, end_date, date_type: dateType, payment_states, shipping_states })
    // console.log('url => ', url)
    const date_filename = `${start_date}-${end_date}`
    const fileName = `PEAK_${date_filename}.xlsx`
    // console.log('fileName => ', fileName)
    await p.op.storageSet(CONS.STORAGE_KEYS.REPORT_PEAK, STORAGE)
    this._handleOnDownloadFile(url, fileName)
    // p.op.showConfirmation(
    //   `ดาวน์โหลด`,
    //   `คุณต้องการดาวน์โหลดรายงานคงคลังใช่หรือไม่`,
    //   () => this._handleOnDownloadFile(url, fileName),
    //   () => {},
    //   `ตกลง`,
    //   `ยกเลิก`
    // )
  }
}
