import React from 'react'
import { Text, TouchableOpacity, View, Platform, ScrollView } from 'react-native'
import { Button } from 'react-native-elements'
import _ from 'lodash'
// import NavHeaderButton from './NavHeaderButton'
// import XCheckbox from './XCheckbox'
// import XCheckButton from './XCheckButton'
import { COLORS, STYLES } from 'x/config/styles'
import XIcon from 'xui/components/XIcon'
import { ICategory, IMKPChannelDetail } from 'x/index'
import BaseCategoryFilterView from 'x/components/BaseCategoryFilterView'
import * as util from 'x/utils/util'
import MKPChannelsActive from './MKPChannelsActive'
import XText from './XText'

// export interface IXDrawerCategoryState {
//   // setting: IOrderListFilterSetting
// }

// export interface IXDrawerCategoryProps {
//   onCloseDrawer: Function
//   selectedCategory: ICategory
//   categoryList: ICategory[]
//   levelCategoryList: ICategory[]
// }

export default class CategoryFilterView extends BaseCategoryFilterView {
  // drawerRef?: React.RefObject<Drawer>

  // constructor(props) {
  //   super(props)
  //   this.drawerRef = React.createRef()

  //   // const { currentSetting = {} } = props
  //   // const setting = Map.isMap(currentSetting) ? currentSetting.toJS() : currentSetting
  //   // this.state = {}
  // }

  // close = () => {
  //   const drawerRef = this.drawerRef.current
  //   // @ts-ignore ignore property of native-base
  //   if (drawerRef && drawerRef._root && _.isFunction(drawerRef._root.close)) {
  //     // @ts-ignore ignore property of native-base
  //     drawerRef._root.close()
  //   }
  // }

  _renderHeader = () => {
    const { onRequestCloseView } = this.props
    const onRequestCloseViewFunction = _.isFunction(onRequestCloseView) ? onRequestCloseView : null
    // const isIos = Platform.OS === 'ios'
    return (
      <View
        style={{
          flex: 0,
          flexBasis: 44,
          height: 50,
          width: '100%',
          backgroundColor: '#fff',
          justifyContent: 'center',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
        {/* {isIos ? <View style={{ marginTop: 30 }} /> : null} */}
        <Text style={{ color: COLORS.TEXT_ACTIVE, fontSize: STYLES.FONT_SIZE_LARGER, flex: 1, textAlign: 'center' }}>กรอง</Text>
        {Platform.OS === 'web' ? null : (
          <TouchableOpacity style={{ width: 50, height: 50, justifyContent: 'center' }} onPress={onRequestCloseViewFunction}>
            <XIcon name='cancel' family='MaterialIcons' style={{ flex: 0 }} />
          </TouchableOpacity>
        )}
      </View>
    )
  }

  _renderCategoryCard = () => {
    const { levelCategoryList, allChildren } = this.state
    if (_.isNil(levelCategoryList)) {
      return
    }
    return (
      <View style={{ paddingLeft: 10, paddingTop: 10, flex: 1, paddingRight: 10 }}>
        <View style={{ borderBottomWidth: 1, borderBottomColor: COLORS.TEXT_INACTIVE, paddingBottom: 10 }}>
          <View style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
            <XIcon family='MaterialCommunityIcons' name='view-grid' style={{ flex: 0, minWidth: 30, color: COLORS.TEXT_INACTIVE }} />
            <XText variant='inactive'>หมวดหมู่</XText>
          </View>
          <ScrollView>
            <View style={{ flex: 1, flexDirection: 'row', overflow: 'hidden', flexWrap: 'wrap' }}>
              {levelCategoryList.map((data: ICategory, index: number) => {
                const marginLeft = index === 0 ? 12 : 12
                return (
                  <TouchableOpacity
                    onPress={() => this._seletedCategory(data)}
                    key={data.id}
                    style={{
                      // minWidth: 30,
                      // width: 50,
                      // maxWidth: 120,
                      height: 25,
                      borderColor: data.l,
                      borderWidth: 0.7,
                      borderRadius: 3,
                      marginLeft,
                      marginTop: 10,
                      justifyContent: 'center',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}>
                    {data.selected ? (
                      <XIcon
                        name='check'
                        family='AntDesign'
                        style={{ color: COLORS.APP_MAIN, flex: 0, minWidth: 25, fontSize: 20, width: 25 }}
                      />
                    ) : (
                      <View style={{ width: 25 }} />
                    )}
                    <XText
                      style={{
                        fontSize: STYLES.FONT_SIZE_NORMAL,
                        color: data.l,
                        textAlign: 'center',
                        paddingHorizontal: 2,
                        paddingRight: 6,
                      }}
                      numberOfLines={1}>
                      {data.n}
                    </XText>
                  </TouchableOpacity>
                )
              })}
            </View>
          </ScrollView>
          <View
            style={{
              height: 30,
              paddingTop: 10,
              paddingLeft: 12,
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}>
            <TouchableOpacity
              style={{ width: 130, justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }}
              onPress={this._setAllChildrenCategory}>
              {allChildren ? (
                <XIcon
                  style={{
                    flex: 0,
                    fontSize: STYLES.FONT_ICON_NORMAL,
                    color: COLORS.APP_MAIN,
                    width: 30,
                  }}
                  family='FontAwesome'
                  name='check-square-o'
                />
              ) : (
                <XIcon
                  style={{
                    flex: 0,
                    fontSize: STYLES.FONT_ICON_NORMAL,
                    color: COLORS.APP_MAIN,
                    width: 30,
                  }}
                  family='FontAwesome'
                  name='square-o'
                />
              )}
              <XText
                style={{
                  fontSize: STYLES.FONT_SIZE_NORMAL,
                  color: COLORS.TEXT_INACTIVE,
                  textAlign: 'left',
                }}>
                รวมหมวดหมู่ย่อย
              </XText>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    )
  }

  _renderContent = () => {
    return (
      <View>
        {this._renderCategoryCard()}
        {/* <MKPChannelsActive
          channels={this.state.channels}
          onPressChannel={(channels: IMKPChannelDetail[]) => this._callBackFromMKPChannelsPicker(channels)}
        /> */}
      </View>
    )
  }

  _callBackFromMKPChannelsPicker = async (channels: IMKPChannelDetail[]) => {
    if (_.isNil(channels)) {
      await util.setStatePromise(this, {
        ug_ids: [],
      })
      return
    }
    const ug_ids = []
    channels.map((ch: IMKPChannelDetail) => {
      if (ch.is_active) {
        ug_ids.push(ch.id)
      }
    })
    await util.setStatePromise(this, {
      ug_ids,
    })
  }

  _renderDivider = () => <View style={{ width: 5 }} />

  _renderFooter = () => {
    // const SPEN = <View style={{ width: 5 }} />
    return (
      <View style={{ height: 40, backgroundColor: '#fff', flexDirection: 'row' }}>
        {this._renderDivider()}
        <View style={{ flex: 1 }}>
          <Button
            title='ล้าง'
            type='outline'
            style={{ flex: 1 }}
            buttonStyle={{ padding: 3, height: 35 }}
            titleStyle={{ fontSize: STYLES.FONT_SIZE_LARGER }}
            onPress={this._clearAllSelected}
          />
        </View>
        {this._renderDivider()}
        <View style={{ flex: 1 }}>
          <Button
            title='ค้นหา'
            type='solid'
            style={{ flex: 1 }}
            buttonStyle={{ padding: 3, height: 35 }}
            titleStyle={{ fontSize: STYLES.FONT_SIZE_LARGER }}
            onPress={this._onPressSubmitBtn}
          />
        </View>
        {this._renderDivider()}
      </View>
    )
  }

  render() {
    // const { levelCategoryList } = this.props
    return (
      <View style={{ flex: 1, backgroundColor: COLORS.WHITE }}>
        {this._renderHeader()}
        {this._renderContent()}
        {this._renderFooter()}
      </View>
    )
  }
}
