/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/no-unused-state */
import React from 'react'
import {
  IOrderPicker,
  IStoreWebsite,
  ISelectedStoreMap,
  IShippingType,
  IXScreenProps,
  StoreWebsiteShippingAndPayment,
  // @ts-ignore
  ISalePageOneOptionProduct,
} from 'x/index'
import * as NavActions from 'x/utils/navigation'
import * as util from 'x/utils/util'
import CONS from 'x/config/constants'
import p from 'x/config/platform-specific'
import _ from 'lodash'
import { Map, List } from 'immutable'
import * as xFmt from 'x/utils/formatter'
// https://stackoverflow.com/questions/47542928/lodash-refers-to-a-umd-global-and-lodash-js-is-not-a-module-errors

interface IBaseStoreWebsiteCreateOptionsOrderViewProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
  subscription: any
  sellerStores: List<Map<string, any>>
}

interface IBaseStoreWebsiteCreateOptionsOrderViewState {
  salePage: IStoreWebsite
  optionName: string

  isShipping: boolean
  selectedShippingTypeIndex: number
  shippingCost: string
  selectedShippingTypeCodIndex: number
  isShippingCod: boolean
  shippingCodCost: string
  shippingTypeNotCodItems: IShippingType[]
  shippingTypIsCodItems: IShippingType[]

  selectedPaymentAccountId: number

  shippingSettingIndex: number

  optionPicker: IOrderPicker
  optionIndex: number

  isEdit: boolean

  mode: 'SINGLE_PRODUCT_MODE' | 'PRODUCT_MULTI_OPTIONS_MODE' | 'PRODUCT_SET_MODE'

  otherPrice: string
  discountPrice: string
}

export default abstract class BaseStoreWebsiteCreateOptionsOrderView extends React.Component<
  IBaseStoreWebsiteCreateOptionsOrderViewProps,
  IBaseStoreWebsiteCreateOptionsOrderViewState
> {
  // abstract async handleSubmitForm(response: any): Promise<void>
  // abstract didMountCheckPermissionForPhone?: () => void
  // validationSchema: Yup.ObjectSchema<any>
  // imgManagerIDRef: React.RefObject<any>
  // webview: React.RefObject<any>
  // mainPackageItems: any

  constructor(props) {
    super(props)
    this.state = {
      salePage: null,
      optionName: '',
      isShipping: false,
      isShippingCod: false,
      shippingCost: '0',
      shippingCodCost: '0',
      selectedPaymentAccountId: -1,
      selectedShippingTypeIndex: -1,
      selectedShippingTypeCodIndex: -1,
      shippingTypeNotCodItems: null,
      shippingTypIsCodItems: null,
      shippingSettingIndex: 0,
      optionPicker: null,
      optionIndex: 0,
      isEdit: false,
      mode: null,
      discountPrice: '0',
      otherPrice: '0',
    }
    // this.webview = React.createRef()
    // this.imgManagerIDRef = React.createRef()
    // @ts-ignore
    // eslint-disable-next-line react/no-unused-class-component-methods
    // this.mainPackageItems = xCONS.SUBSCRIPTION_MAIN_PACKAGE_ITEMS.map((item) => ({
    //   ...item,
    //   label: p.op.t(`Subscription.main.${item.key}`),
    // }))
  }

  async componentDidMount() {
    const params = util.getNavParams(this.props)
    const { storeWebsite, orderPicker, mode, index } = params
    // console.log('orderPicker => /// ', orderPicker)
    const name = orderPicker && orderPicker.name ? orderPicker.name : ''
    await util.setStatePromise(this, {
      optionName: name,
      optionPicker: _.isNil(orderPicker) ? null : orderPicker,
      optionIndex: index,
      mode: orderPicker && orderPicker.mode ? orderPicker.mode : mode,
    })
    await this._setShippingTypeNotCodItems()
    await this._setShippingTypeIsCodItems()

    const default_shipping_and_payment: StoreWebsiteShippingAndPayment = storeWebsite.default_shipping_and_payment
      ? storeWebsite.default_shipping_and_payment
      : null
    await this._setShipping(default_shipping_and_payment, orderPicker)
  }

  _setShipping = async (shipping: StoreWebsiteShippingAndPayment, orderPicker: IOrderPicker) => {
    const default_shipping_and_payment = shipping
    let selectedShippingTypeIndex = -1
    let shippingCost = '0'
    let isShipping = false
    let selectedShippingTypeCodIndex = -1
    let shippingCodCost = '0'
    let isShippingCod = false
    let shippingSettingIndex = 1
    if (!_.isNil(default_shipping_and_payment)) {
      selectedShippingTypeIndex = default_shipping_and_payment.is_not_cod ? default_shipping_and_payment.is_not_cod.shipping_type_id : -1
      shippingCost = default_shipping_and_payment.is_not_cod ? default_shipping_and_payment.is_not_cod.shipping_cost : '0'
      isShipping = !!default_shipping_and_payment.is_not_cod
      selectedShippingTypeCodIndex = default_shipping_and_payment.is_cod ? default_shipping_and_payment.is_cod.shipping_type_id : -1
      shippingCodCost = default_shipping_and_payment.is_cod ? default_shipping_and_payment.is_cod.shipping_cost : '0'
      isShippingCod = !!default_shipping_and_payment.is_cod
      shippingSettingIndex = 0
    }
    console.log('// orderPicker 01 // ', orderPicker)
    if (!_.isNil(orderPicker)) {
      shippingSettingIndex = 1
      const Shipping = orderPicker.shipping_and_payment
      selectedShippingTypeIndex = Shipping.is_not_cod === null ? -1 : Shipping.is_not_cod.shipping_type_id
      shippingCost = Shipping.is_not_cod === null ? '0' : Shipping.is_not_cod.shipping_cost
      isShipping = !(_.isNil(Shipping) || Shipping.is_not_cod === null)
      selectedShippingTypeCodIndex = Shipping.is_cod === null ? -1 : Shipping.is_cod.shipping_type_id
      shippingCodCost = Shipping.is_cod === null ? '0' : Shipping.is_cod.shipping_cost
      isShippingCod = !(_.isNil(Shipping) || Shipping.is_cod === null)
    }
    console.log('// orderPicker 02 // ', orderPicker)
    await util.setStatePromise(this, {
      selectedShippingTypeIndex,
      shippingCost,
      selectedShippingTypeCodIndex,
      shippingCodCost,
      isShipping,
      isShippingCod,
      shippingSettingIndex,
    })
  }

  _setIsEdit = () => {
    this.setState({ isEdit: true })
  }

  _saveBtn = () => {
    const {
      optionPicker,
      optionIndex,
      optionName,
      mode,
      shippingCodCost,
      selectedShippingTypeCodIndex,
      shippingCost,
      selectedShippingTypeIndex,
      shippingSettingIndex,
      isShipping,
      isShippingCod,
    } = this.state
    if (_.isNil(optionName) || optionName === '') {
      p.op.showConfirmationOkOnly('', 'กรุณาระบุชื่อคำสั่งซื้อ')
      return
    }
    if (_.isNil(optionPicker) || optionPicker.products.length < 1) {
      p.op.showConfirmationOkOnly('', 'กรุณาระบุตัวเลือกสินค้า')
      return
    }
    if (!isShipping && !isShippingCod) {
      p.op.showConfirmationOkOnly('', 'กรุณาระบุอย่างน้อย 1 รูปแบบจัดส่ง')
      return
    }
    const newOrderPicker = _.cloneDeep(optionPicker)
    newOrderPicker.mode = mode
    // if (mode === 'SINGLE_PRODUCT_MODE') {
    //   newOrderPicker.name = optionName
    // }
    newOrderPicker.name = optionName
    if (shippingSettingIndex === 1) {
      newOrderPicker.shipping_and_payment = {
        is_cod: isShippingCod
          ? {
              shipping_cost: shippingCodCost,
              shipping_type_id: selectedShippingTypeCodIndex,
            }
          : null,
        is_not_cod: isShipping
          ? {
              shipping_cost: shippingCost,
              shipping_type_id: selectedShippingTypeIndex,
            }
          : null,
      }
    } else {
      newOrderPicker.shipping_and_payment = null
    }

    const params = util.getNavParams(this.props)
    const { callBack } = params
    if (_.isFunction(callBack)) {
      callBack(newOrderPicker, optionIndex)
      p.op.showToast('บันทึกข้อมูลเรียบร้อยแล้ว', 'success')
      util.navGoBack(this.props)
    }
  }

  _setShippingTypeNotCodItems = async () => {
    const shippingTypeNotCodItems = []
    shippingTypeNotCodItems.push({ index: -1, label: 'ไม่ระบุ' })
    CONS.ORDER_VIEW_SHIPPING_TYPE_ITEMS.forEach((shippingData) => {
      // console.log('shippingData => ', shippingData)
      if (!_.includes(CONS.COD_SHIPPING_TYPE_IDS, shippingData.value)) {
        shippingTypeNotCodItems.push({ ...shippingData, label: util.getShippingTypeNameById(shippingData.value) })
      }
      // const shipping = _.find(CONS.COD_SHIPPING_TYPE_IDS, (id) => id !== shippingData.value)
      // console.log('shipping => ', shipping)
      // if (!_.isNil(shipping)) {
      //   shippingTypeNotCodItems.push({ ...shippingData, label: util.getShippingTypeNameById(shippingData.value) })
      // }
    })

    await util.setStatePromise(this, {
      shippingTypeNotCodItems,
    })
  }

  _setShippingTypeIsCodItems = async () => {
    const shippingTypIsCodItems = []
    shippingTypIsCodItems.push({ index: -1, label: 'ไม่ระบุ' })
    CONS.ORDER_VIEW_SHIPPING_TYPE_ITEMS.forEach((shippingData) => {
      if (_.includes(CONS.COD_SHIPPING_TYPE_IDS, shippingData.value)) {
        shippingTypIsCodItems.push({ ...shippingData, label: util.getShippingTypeNameById(shippingData.value) })
      }
    })

    await util.setStatePromise(this, {
      shippingTypIsCodItems,
    })
  }

  _onChangeSalePageName = (newText: string) => {
    const { optionPicker } = this.state
    // console.log('INPUT => ', optionPicker)
    // @ts-ignore
    const newOptionPicker: IOrderPicker = !_.isNil(optionPicker) ? _.clone(optionPicker) : {}
    newOptionPicker.name = newText
    // console.log('newOptionPicker => ', newOptionPicker)
    this.setState({ optionName: newText, optionPicker: newOptionPicker, isEdit: true })
  }

  _onChangeOptionName = (newText: string, index: number) => {
    const { optionPicker } = this.state
    // console.log('INPUT => ', optionPicker)
    // @ts-ignore
    const newOptionPicker: IOrderPicker = !_.isNil(optionPicker) ? _.clone(optionPicker) : {}
    newOptionPicker.products[index].name = newText
    this.setState({ optionPicker: newOptionPicker, isEdit: true })
  }

  _onChangeSettingShippingIndex = (newIndex: number) => {
    this.setState({ shippingSettingIndex: newIndex })
  }

  _navToStoreWebsiteView = (storeWebsite: IStoreWebsite) => {
    const { navigation } = this.props
    // console.log('SalePage Nav => ', salePage)
    navigation.dispatch(
      NavActions.navToStoreWebsiteView({
        storeWebsite,
        callBackForSucceedDeleteStoreWebsite: null,
      })
    )
  }

  _setOptionPickerOnSetMode = (newProduct: ISalePageOneOptionProduct, productIndex: number) => {
    const { optionPicker, optionName } = this.state
    let newOptionName = optionName
    if (newProduct.variants[0].variantName === '') {
      newOptionName = newProduct.variants[0].name
    } else {
      newOptionName = `${newProduct.variants[0].name} - ${newProduct.variants[0].variantName}`
    }
    // optionPicker.products[productIndex].variants.push(newProduct.variants[0])
    // console.log('optionPicker [] => ', optionPicker)
    const newOptionPicker = _.isNil(optionPicker) ? { products: [] } : _.clone(optionPicker)
    // @ts-ignore
    const { products } = newOptionPicker
    // console.log('products ===> ', products)
    const newProducts = _.isNil(products) ? [] : _.clone(products)
    // console.log('productIndex => ', productIndex)
    // @ts-ignore
    // console.log('newProducts length => ', newProducts.length)
    // console.log('newProducts => ', newProducts)
    if (newProducts.length < productIndex + 1) {
      newProducts.push(newProduct)
    } else {
      newProducts[productIndex].variants.push(newProduct.variants[0])
    }
    // @ts-ignore
    newOptionPicker.products = newProducts
    // if (newOptionPicker.length < productIndex + 1) {
    //   newOptionPicker.push(newProduct)
    // }
    // console.log('newOptionPicker => ', newOptionPicker)
    // @ts-ignore
    this.setState({ optionPicker: newOptionPicker, isEdit: true, optionName: newOptionName })
    // this.setState({ optionPicker: newOptionPicker, isEdit: true, optionName: newOptionName })
  }

  _callBackFromEditProduct = (newProduct: ISalePageOneOptionProduct, productIndex: number) => {
    // console.log('newProduct / => ', newProduct)
    // console.log('productIndex / => ', productIndex)

    const { optionPicker, optionName, mode } = this.state
    // console.log('optionPicker / => ', optionPicker)
    this._setOptionPickerOnSetMode(newProduct, productIndex)
    return

    if (mode === 'PRODUCT_SET_MODE') {
      this._setOptionPickerOnSetMode(newProduct, productIndex)
      return
    }
    let newOptionName = optionName
    // if (newOptionName === '' && productIndex === 0) {0
    if (newProduct.variants[0].variantName === '') {
      newOptionName = newProduct.variants[0].name
    } else {
      newOptionName = `${newProduct.variants[0].name} - ${newProduct.variants[0].variantName}`
    }
    // }

    const { products } = optionPicker
    // console.log('products [] => ', products)
    const newProducts: ISalePageOneOptionProduct[] = _.isNil(products) ? [] : _.clone(products)

    // if (newProducts.length > 1) {
    //   newProducts[productIndex] = newProduct
    // } else {
    //   newProducts.push(newProduct)
    // }
    newProducts.push(newProduct)

    // console.log('newProducts => ', newProducts)

    const newOptionPicker = _.clone(optionPicker)
    newOptionPicker.products = newProducts // Corrected assignment

    // console.log('newOptionPicker * => ', newOptionPicker)
    this.setState({ optionPicker: newOptionPicker, isEdit: true, optionName: newOptionName })
  }

  // _callBackFromEditProduct = (newProduct: ISalePageOneOptionProduct, productIndex: number) => {
  //   console.log('newProduct / => ', newProduct)
  //   console.log('productIndex / => ', productIndex)
  //   const { optionPicker } = this.state
  //   console.log('optionPicker / => ', optionPicker)
  //   const { products } = optionPicker
  //   const newProducts: ISalePageOneOptionProduct[] = _.isNil(products) ? [] : _.clone(products)
  //   if (newProducts.length > 1) {
  //     newProducts[productIndex] = newProduct
  //   } else {
  //     newProducts.push(newProduct)
  //   }
  //   console.log('newProducts => ', newProducts)
  //   const newOptionPicker = _.clone(optionPicker)

  //   newOptionPicker.products = newOptionPicker
  //   console.log('optionPicker => ', optionPicker)
  //   this.setState({ optionPicker: newOptionPicker })
  // }

  _getSellerStoreName = (seller_store_id: number) => {
    const { sellerStores } = this.props
    const sellerStoresJS = sellerStores.toJS()
    // console.log('sellerStoresJS => ', sellerStoresJS)
    let storeName = ''
    if (!_.isNil(seller_store_id)) {
      sellerStoresJS.forEach((store) => {
        if (store.id === seller_store_id) {
          storeName = store.name
        }
      })
      if (storeName === '') {
        storeName = 'ขายส่ง'
      }
      return storeName
    }
    return 'ฉัน'
  }

  _deleteVariant = (productIndex: number) => {
    const { optionPicker } = this.state
    const newOptionPicker = _.clone(optionPicker)
    const { products } = newOptionPicker
    const newProduct: ISalePageOneOptionProduct[] = []
    products.forEach((product, idx) => {
      if (idx !== productIndex) {
        newProduct.push(product)
      }
    })
    newOptionPicker.products = newProduct
    this.setState({ optionPicker: newOptionPicker })
  }

  _getProductPrice = () => {
    const { optionPicker } = this.state
    const { products } = optionPicker
    let minPrice = 0
    let maxPrice = 0
    let sumPrice = xFmt.formatCurrency(0)
    if (!_.isNil(products) && products.length > 0) {
      products.forEach((product) => {
        // console.log('_getProductPrice product => ', product)
        product.variants.forEach((variant) => {
          // console.log('_getProductPrice variant => ', variant)
          const price: number = variant.discount ? +variant.price - +variant.discount : +variant.price
          if (minPrice > price) {
            minPrice = price
          }
          if (maxPrice < price) {
            maxPrice = price
          }
          if (minPrice === maxPrice) {
            sumPrice = xFmt.formatCurrency(minPrice)
          } else {
            sumPrice =
              minPrice === 0 ? xFmt.formatCurrency(maxPrice) : `${xFmt.formatCurrency(minPrice)} - ${xFmt.formatCurrency(maxPrice)}`
          }
        })
      })
    }
    return sumPrice
  }

  _getSumTotalProductPrice = () => {
    const { optionPicker, discountPrice, otherPrice } = this.state
    const { products } = optionPicker
    let minPrice = 0
    let maxPrice = 0
    let sumPrice = xFmt.formatCurrency(0)
    if (!_.isNil(products) && products.length > 0) {
      products.forEach((product) => {
        // console.log('_getProductPrice product => ', product)
        product.variants.forEach((variant) => {
          // console.log('_getProductPrice variant => ', variant)
          const price: number = variant.discount ? +variant.price - +variant.discount : +variant.price
          if (minPrice > price) {
            minPrice = price
          }
          if (maxPrice < price) {
            maxPrice = price
          }
        })
      })
    }
    let sumMin = 0
    sumMin = minPrice + +otherPrice
    sumMin -= +discountPrice
    let sumMax = 0
    sumMax = maxPrice + +otherPrice
    sumMax -= +discountPrice
    if (minPrice === maxPrice) {
      sumPrice = xFmt.formatCurrency(sumMin)
    } else if (sumMin < 0 && sumMax < 0) {
      sumPrice = xFmt.formatCurrency(0)
    } else {
      sumPrice = minPrice <= 0 ? xFmt.formatCurrency(sumMax) : `${xFmt.formatCurrency(sumMin)} - ${xFmt.formatCurrency(sumMax)}`
    }
    return sumPrice
  }

  _navToSalePageSelectProductView = (productIndex?: number) => {
    const { navigation } = this.props
    const { optionPicker, mode } = this.state
    // const { products } = optionPicker
    // console.log('SalePage Nav => ', salePage)
    // if (_.isNil(optionName) || optionName.length < 1) {
    //   p.op.showConfirmationOkOnly('', 'กรุณาระบุชื่อตัวเลือกคำสั่งซื้อก่อน')
    //   return
    // }
    navigation.dispatch(
      NavActions.navToSalePageSelectProductView({
        // product: _.isNil(products) ? null : products[productIndex],
        productIndex,
        mode,
        callBack: (newProduct: ISalePageOneOptionProduct, ProductIndex: number) => this._callBackFromEditProduct(newProduct, ProductIndex),
      })
    )
  }
}
