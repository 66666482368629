import React, { useState, useEffect } from 'react'
import { TouchableOpacity, Text, ScrollView } from 'react-native'
import _ from 'lodash'

// import p from 'x/config/platform-specific'
import { STYLES, COLORS } from 'x/config/styles'
// import { PrintOptionKey } from 'x/index'
import { useStateWithPromise } from 'x/utils/util'
import { IOnSubmitOverlayEditTime, IAddress, IMKPChannelDetail } from 'x/index'

import XIcon from 'xui/components/XIcon'
import XOverlay from 'xui/components/XOverlay'
import TimePicker from 'xui/components/XTimePicker'
import { VIEW_WIDTH_TEN } from 'xui/utils/BaseShareUI'
import CONS from 'x/config/constants'
import * as util from 'x/utils/util'
import p from 'x/config/platform-specific'
import XContainer from 'xui/components/XContainer'
import Box from 'xui/components/Box'
import XText from 'xui/components/XText'
import XButton from 'xui/components/XButton'

// interface IPrintingOptionOverlayButton extends IPrintingOptionUIProps {
//   renderButton?: (onPressOpenOverlay: () => void) => JSX.Element
//   buttonStyle?: ViewStyle
//   disabled?: boolean
// }

// interface IPrinterButtonProps {
//   onPress: () => void | Promise<void>
//   buttonStyle?: ViewStyle
//   isDisabled?: boolean
// }

interface IOverlayEditTimeSlotProps {
  renderButton: (onPressOpenOverlay: () => void) => void
  onSubmit: (data: IOnSubmitOverlayEditTime) => void
  shippingTypeId: number
  channel: IMKPChannelDetail
  isSingleMkpChannel: boolean
  headertitle: string
  footerText: string

  showSectionSetTime?: boolean // if showSectionSetTime = false ple set showsectionSetAddress = true
  showsectionSetAddress?: boolean // if showsectionSetAddress = false ple set showSectionSetTime = true

  isWarningOverlayClose?: boolean // if true = need to warning on close overlay
  textWarningOverlayClose?: string // yes or no // yes = close overlay
}

const OverlayEditTimeSlot: React.FC<IOverlayEditTimeSlotProps> = function ({
  renderButton,
  onSubmit,
  shippingTypeId,
  channel,
  isSingleMkpChannel,
  headertitle,
  footerText,
  showSectionSetTime,
  showsectionSetAddress,
  isWarningOverlayClose,
  textWarningOverlayClose,
}) {
  const newTimeSlotFormat = (KEY_TIME: 'start_time' | 'end_time'): Date => {
    if (channel && channel.timeslot_prefs && shippingTypeId) {
      const timeSlot = _.find(channel.timeslot_prefs, (data) => data.shipping_type_id === shippingTypeId)
      if (_.isNil(timeSlot)) {
        return new Date()
      }

      const hours = +timeSlot[KEY_TIME].substring(0, 2)
      const minutes = +timeSlot[KEY_TIME].substring(3, 5)

      const today = new Date()
      today.setHours(hours)
      today.setMinutes(minutes)
      return today
    }
    return new Date()
  }

  const startTimeSlot = newTimeSlotFormat('start_time')
  const endTimeSlot = newTimeSlotFormat('end_time')
  const [isOverlayVisible, setIsOverlayVisible] = useState<boolean>(false)
  // const [submitting, setSubmitting] = useStateWithPromise<boolean>(false)

  const [isStartTime, isOpenStartTimePicker] = useStateWithPromise<boolean>(false)
  const [startTimePicker, isStartTimePicker] = useStateWithPromise<Date>(startTimeSlot)

  const [isEndTime, isOpenEndTimePicker] = useStateWithPromise<boolean>(false)
  const [endTimePicker, isEndTimePicker] = useStateWithPromise<Date>(endTimeSlot)

  const [isDidMountTimePicker, didMountTimePicker] = useStateWithPromise<boolean>(false)

  const [mkpChannel, updateChannel] = useState(channel)
  const [addresses, updateAddresses] = useState(channel ? channel.addresses : [])

  const [submitLoading, SubmitLoading] = useState(false)

  // isUseEffect ใช้เผื่อควบคุม useEffect ถ่สมีข้อมูล channel เข้ามาแล้ว ให้หยุดใช้งาน useEffect
  const [isUseEffect, setIsUseEffect] = useState(true)

  useEffect(() => {
    if (isUseEffect && !_.isNil(channel)) {
      updateChannel(channel)
      updateAddresses(channel ? channel.addresses : [])
      isStartTimePicker(startTimeSlot)
      isEndTimePicker(endTimeSlot)
      setIsUseEffect(false)
    }
  })
  // const addresses = mkpChannel.addresses
  // console.log('addresses => ', addresses)
  // const _onSubmitingStateChange = async (newSubmiting: boolean) => {
  //   await setSubmitting(newSubmiting)
  // }
  // console.log('channel 01', channel)
  // console.log('mkpChannel => ', mkpChannel)
  const _openOverlay = () => setIsOverlayVisible(true)
  const _closeOverlay = () => setIsOverlayVisible(false)

  // กดยืนยัน
  const _confirmChange = async () => {
    if (submitLoading) {
      return
    }
    const address = _.find(addresses, (addr) => addr.is_primary)
    // if (_.isNil(address)) {
    //   return
    // }
    // console.log('mkpChannel => ', mkpChannel)
    // console.log('address => ', address)
    const data: IOnSubmitOverlayEditTime = {
      shippingTypeId,
      addressId: !_.isNil(address) ? address.address_mapping_uuid : null,
      mkpChannel: !_.isNil(mkpChannel) ? mkpChannel : null,
    }
    // console.log('showSectionSetTime => ', showSectionSetTime)
    if (!_.isNil(showSectionSetTime) && showSectionSetTime) {
      data.time = {
        start: startTimePicker,
        end: endTimePicker,
      }
    }
    onSubmit(data)
    SubmitLoading(false)
    _closeOverlay()
  }

  const _onChangeAddress = (addr: IAddress) => {
    const newAddress = []
    addresses.map((address: IAddress) => {
      const newAddr = address
      if (addr.address_mapping_uuid === address.address_mapping_uuid) {
        newAddr.is_primary = true
      } else {
        newAddr.is_primary = false
      }
      newAddress.push(newAddr)
    })
    const newChannel = mkpChannel
    newChannel.addresses = newAddress
    updateChannel(newChannel)
    updateAddresses(newAddress)
  }

  const updateStartTimePicker = (newDate: Date) => {
    isOpenStartTimePicker(false)
    if (_.isNil(newDate)) {
      return
    }
    const time = util.checkHasMoreMinutesTime(newDate, endTimePicker)
    // ถ้าเวลาเริ่มมีค่าน้อยกว่าเวลาจบ
    if (!time) {
      isStartTimePicker(newDate)
    } else {
      // isStartTimePicker(endTimePicker)
      isStartTimePicker(newDate)
      isEndTimePicker(newDate)
    }
  }

  const updateEndTimePicker = (newDate: Date) => {
    isOpenEndTimePicker(false)
    if (_.isNil(newDate)) {
      return
    }
    const time = util.checkHasMoreMinutesTime(newDate, startTimePicker)
    // ถ้าเวลาจบมีค่ามากว่าเวลาเริ่ม
    if (time) {
      isEndTimePicker(newDate)
    } else {
      // isEndTimePicker(endTimePicker)
      isStartTimePicker(newDate)
      isEndTimePicker(newDate)
    }
  }

  const onCloseOverlay = () => {
    if (!_.isNil(isWarningOverlayClose) && isWarningOverlayClose) {
      const textWarning = !_.isNil(textWarningOverlayClose)
        ? textWarningOverlayClose
        : 'คุณต้องการยกเลิกการตั้งค่า และปิดหน้าต่างนี้ใช่หรือไม่'
      p.op.showConfirmation(
        '',
        textWarning,
        () => setIsOverlayVisible(false),
        () => {}
      )
    } else {
      _closeOverlay()
    }
  }

  const _renderControlButton = () => {
    if (_.isFunction(renderButton)) {
      return renderButton(_openOverlay)
    }
    return (
      <TouchableOpacity
        onPress={() => _openOverlay()}
        style={{
          width: 60,
          height: 25,
          borderWidth: 0.7,
          borderColor: COLORS.APP_MAIN,
          borderRadius: 7,
          backgroundColor: COLORS.BG_LIGHT_GREY,
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
        }}>
        <XIcon style={{ flex: 0, minWidth: 20, width: 20, fontSize: STYLES.FONT_ICON_SMALLEST }} name='edit' family='FontAwesome5' />
        <Text style={{ color: COLORS.TEXT_INACTIVE, fontSize: STYLES.FONT_SIZE_NORMAL }}>แก้ไข</Text>
      </TouchableOpacity>
    )
  }

  const ListTimeSlost = mkpChannel && mkpChannel.timeslot_prefs ? mkpChannel.timeslot_prefs : []
  // const shippinTypeIds = mkpChannel.shipping_type_ids

  let indexForAddress = 0

  const getTextTime = (time: Date) => {
    if (_.isNil(time)) {
      return util.getTimeFormathhmm(new Date())
    }
    return util.getTimeFormathhmm(time)
  }

  const timeSlot = _.find(ListTimeSlost, (data) => data.shipping_type_id === shippingTypeId)
  const shipping = _.find(CONS.ORDER_VIEW_SHIPPING_TYPE_ITEMS, (sh) => sh.value === shippingTypeId)
  let START_TIME = startTimePicker
  let END_TIME = endTimePicker
  if (!_.isNil(timeSlot) && !isDidMountTimePicker) {
    START_TIME = new Date(timeSlot.start_time)
    END_TIME = new Date(timeSlot.end_time)
    didMountTimePicker(true)
  }

  const textStartTime = getTextTime(START_TIME)
  const textEndTime = getTextTime(END_TIME)

  let overlayHeight = 390
  let isShowSetTime = true
  let isShowSetAddress = true
  if (!_.isNil(showSectionSetTime) && !showSectionSetTime) {
    overlayHeight -= 65
    isShowSetTime = false
  }
  if (!_.isNil(showsectionSetAddress) && !showsectionSetAddress) {
    overlayHeight -= 235
    isShowSetAddress = false
  }

  // console.log('overlayHeight => ', overlayHeight)
  // console.log('showSectionSetTime', showSectionSetTime)
  // console.log('showsectionSetAddress', showsectionSetAddress)
  const _renderOverlay = () => (
    <XOverlay
      contentStyle={{ height: overlayHeight, width: 310, alignSelf: 'center' }}
      visible={isOverlayVisible}
      // webNumColumns={1}
      onRequestClose={() => onCloseOverlay()}>
      <XContainer p='2' borderRadius='md'>
        <Box style={{ flexDirection: 'row' }}>
          <Box style={{ flex: 1 }}>
            <XText variant='active' bold>
              {headertitle}
            </XText>
          </Box>
          <TouchableOpacity style={{ width: 25 }} onPress={() => onCloseOverlay()}>
            <XIcon style={{ flex: 0, minWidth: 25 }} name='closecircleo' family='AntDesign' />
          </TouchableOpacity>
        </Box>

        {isShowSetTime ? (
          <Box style={{ height: 25 }}>
            <XText variant='inactive'>เวลาที่สะดวกให้เข้ารับ</XText>
          </Box>
        ) : null}

        {/* // กรอบเวลา */}
        {isShowSetTime ? (
          <Box p='2' borderWidth='1' borderRadius='md' borderColor='gray.400' flexDirection='row' zIndex={999}>
            <Box flex={1} justifyContent='center' alignItems='center' flexDirection='row'>
              <XText variant='inactive'>{`จาก: `}</XText>
              {VIEW_WIDTH_TEN}
              {isStartTime ? <TimePicker onChange={(newDate: Date) => updateStartTimePicker(newDate)} dateTime={START_TIME} /> : null}
              <XButton onPress={() => isOpenStartTimePicker(true)} variant='solid'>
                {textStartTime}
              </XButton>
            </Box>
            <Box flex={1} justifyContent='center' alignItems='center' flexDirection='row'>
              <XText variant='inactive'>{`ถึง: `}</XText>
              {VIEW_WIDTH_TEN}
              {isEndTime ? <TimePicker onChange={(newDate: Date) => updateEndTimePicker(newDate)} dateTime={END_TIME} /> : null}
              <XButton onPress={() => isOpenEndTimePicker(true)} variant='solid'>
                {textEndTime}
              </XButton>
            </Box>
          </Box>
        ) : null}

        {isShowSetAddress ? (
          <Box mt='2'>
            <XText variant='inactive'>ที่อยู่ในการเข้ารับ</XText>
          </Box>
        ) : null}

        {isShowSetAddress ? (
          <Box flex={1} borderWidth='1' borderRadius='md' borderColor='gray.400'>
            {/* {shippinTypeIds.map((shippingId: number, index: number) => } */}
            <ScrollView>
              {!isSingleMkpChannel ? (
                <XText p='2'>ไม่สามารถระบุที่อยู่เข้ารับได้ เนื่องจากรายการออเดอร์ที่เลือกมีช่องทางขายต่างกันมากกว่า 1 รายการ</XText>
              ) : (
                addresses.map((address: IAddress, index: number) => {
                  indexForAddress += 1
                  // @ts-ignore
                  const addressLable = util.getAddresFormatLable(address)
                  const isPrimary = address.is_primary
                  return (
                    <TouchableOpacity
                      key={index}
                      onPress={() => _onChangeAddress(address)}
                      style={{
                        flex: 1,
                        height: 55,
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // paddingTop,
                        marginTop: 10,
                        paddingRight: 8,
                      }}>
                      {isPrimary ? (
                        <Box w='10' justifyContent='center' alignItems='center'>
                          <XIcon name='caretright' family='AntDesign' style={{ flex: 0, minWidth: 25 }} />
                        </Box>
                      ) : (
                        <Box w='10' justifyContent='center' alignItems='center' />
                      )}

                      <Box
                        flex={1}
                        h='12'
                        borderColor={isPrimary ? COLORS.APP_MAIN : 'gray.400'}
                        borderRadius='md'
                        borderWidth='1'
                        alignItems='center'
                        pt='1'>
                        <XText variant='active' px='2' numberOfLines={3}>
                          {addressLable}
                        </XText>
                      </Box>
                    </TouchableOpacity>
                  )
                })
              )}
            </ScrollView>
          </Box>
        ) : null}

        <TouchableOpacity
          onPress={() => _confirmChange()}
          style={{
            height: 40,
            marginTop: 10,
            backgroundColor: COLORS.APP_MAIN,
            borderRadius: 7,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <XText color='white' bold>
            {footerText}
          </XText>
        </TouchableOpacity>
      </XContainer>
    </XOverlay>
  )

  if (_.isNil(addresses)) {
    return null
  }

  // Main render
  return (
    <>
      {_renderControlButton()}
      {_renderOverlay()}
    </>
  )
}

export default OverlayEditTimeSlot
