import { Map, List, fromJS } from 'immutable'
import actions from '../../config/actions'
import CONS from '../../config/constants'
import { log } from '../../utils/util'
import p from '../../config/platform-specific'

import {
  getSearchOrdersFetchStatus,
  getSearchOrdersFetchStatusInSearchOrders,
  getSearchOrdersLastSearchRequest,
  getSearchOrdersLastSearchRequestInSearchOrders,
  getSearchOrdersOrdersInSearchOrders,
  setSearchOrdersFetchStatusInSearchOrders,
  setSearchOrdersFetchStatusLoadingInSearchOrders,
  setSearchOrdersLastSearchRequestInSearchOrders,
  setSearchOrdersOrdersInSearchOrders,
} from '../../redux/selectors'

const initialState = Map({
  lastSearchRequest: Map({}),
  orders: List(),
  fetchStatus: Map({
    loading: false,
    nextRequestOffset: 0,
    count: 0,
    lastUpdate: null,
    allFetched: true,
  }),
})

// Action creators
export function navToOrderDetail(orderId, storeId, isNoInitFetch) {
  return p.nav.navToOrderDetail(orderId, storeId, isNoInitFetch)
}

// Only mode INIT required payload,
// the other two modes (REFRESH & LOAD MORE) will ignore payload as it reuses the previous request
export function fetchOrders({ mode, payload }) {
  // log('SearchOrderListState.fetchOrders', payload)
  switch (mode) {
    case CONS.ORDERS_FETCH_MODE_INIT: {
      if (!payload) {
        log('ERROR! SearchOrderListState.fetchOrders.INIT has NO payload')
        return
      }
      break
    }
    case CONS.ORDERS_FETCH_MODE_REFRESH: {
      // expect no payload (ignore if provided)
      // simply reusing the last request with offset = 0
      const lastSearchRequest = getSearchOrdersLastSearchRequest(p.op.getAppState())
      // let payload = getSearchOrders(p.op.getAppState()).lastSearchRequest
      // Reset the offset
      if (!lastSearchRequest) {
        log('ERROR! SearchOrderListState.fetchOrders:REFRESH has NO payload')
        return
      }
      payload = lastSearchRequest.set('offset', 0)
      // payload.offset = 0
      break
    }
    case CONS.ORDERS_FETCH_MODE_MORE: {
      // expect no payload (ignore if provided)
      // simply reusing the last request with corresponding offset
      const s = p.op.getAppState()
      // const searchOrders = getSearchOrders(p.op.getAppState())
      // log ('CONS.ORDERS_FETCH_MODE_MORE.state', s)
      const lastSearchRequest = getSearchOrdersLastSearchRequest(s)
      if (!lastSearchRequest) {
        log('ERROR: Load more without last request!!!')
        return
      }
      // log('Search_ORDERS_FETCH_MODE_MORE.payload', payload)
      const fetchStatus = getSearchOrdersFetchStatus(s)
      const offset = fetchStatus.get('nextRequestOffset')
      payload = lastSearchRequest.set('offset', offset)
      break
    }
    default:
      return {}
  }
  return {
    type: actions.SEARCH_ORDERS_FETCH,
    payload,
  }
  /*
  return {
    type: _type.ORDERS_FETCH,
    payload: {
      // store_id: storeId,
      store_id: 1,
      offset: 0,
      limit: 20,
      sortBy: 'id',
      sortType: 'desc',
      order_mode: 'p', // Default to 's' where 's' = sales order
      // created_at_from: '2017-06-30 13:23:45',
      // created_at_to: '2017-07-01 13:23:45',
      // updated_at_from: '2017-06-30 13:23:45',
      // updated_at_to: '2017-07-01 13:23:45',
      // completed_at_from: '2017-06-30 13:23:45',
      // completed_at_to: '2017-07-01 13:23:45',
      // order_states: [102, 109]
      // payment_states: [],
      // shipping_states: [129],
      // shipping_type_ids: [3,4,5],
      // receiver_name: 'กนกว'
      // sender_name: 'xxx',
      // seller_store_ids: [12],
      // expiresInMins: 3600
    },
  }
  */
}

interface IActionType {
  type?: string
  payload?: any
  [key: string]: any
}

// Reducer
export default function SearchOrderListStateReducer(state = initialState, action: IActionType = {}) {
  let s = state
  switch (action.type) {
    case actions.SEARCH_ORDERS_INIT:
      return initialState
    case actions.SEARCH_ORDERS_FETCH: {
      // Besides watchFetchSearchOrders in orders.ts, the reducer here tries to do the following
      // 1) Update the "lastSearchRequest" in redux, so the query condition can be re-display later in the order adv search UI
      // 2) Update the "fetchStatus" in redux, so we can keep track of the fetch status
      // log('actions.SEARCH_ORDERS_FETCH')
      // log(action)
      // Offset and limit are no use for lastSearchRequest and shall not be used, but do not delete them here as the payload shall continue for request
      s = setSearchOrdersLastSearchRequestInSearchOrders(s, fromJS(action.payload))
      // Set the fetchStatus' loading to true
      s = setSearchOrdersFetchStatusLoadingInSearchOrders(s, true)
      return s
    }
    case actions.SEARCH_ORDERS_SET_STATE: {
      // log('action: ', action)
      // log('SEARCH_ORDERS_SET_STATE', s.toJS())
      // log('payload: ', payload)
      const { payload } = action
      if (!payload) return s

      const fetchStatusJS = getSearchOrdersFetchStatusInSearchOrders(s).toJS()
      const lastSearchRequest = getSearchOrdersLastSearchRequestInSearchOrders(s)
      // log ('actions.SEARCH_ORDERS_SET_STATE.fetchStatusJS',fetchStatusJS)
      // log ('actions.SEARCH_ORDERS_SET_STATE.lastSearchRequest',lastSearchRequest)
      //   // TODO: append to the orders if it's not an initial load
      const newOrders = fromJS(payload.orders)
      const lastSearchRequestOffset = lastSearchRequest.get('offset')
      // Set or append the response's orders based on the request offset
      if (lastSearchRequestOffset == 0) {
        s = setSearchOrdersOrdersInSearchOrders(s, newOrders)
      } else {
        const currOrders = getSearchOrdersOrdersInSearchOrders(s)
        const appendedOrders = currOrders.concat(newOrders)
        s = setSearchOrdersOrdersInSearchOrders(s, appendedOrders)
      }
      // Update the fetchStatus accordingly
      if ('count' in payload) {
        // MUST NOT use if (payload.count) check as count could be 0 and be interpreted as false
        // only the initial search response has count, so must set it only if it exists
        fetchStatusJS.count = payload.count
      }
      // log('//////////////////////////////////////////////////')
      // log(lastSearchRequestOffset)
      // log(fetchStatusJS.count)
      if (lastSearchRequestOffset >= fetchStatusJS.count) {
        fetchStatusJS.allFetched = true
      } else {
        fetchStatusJS.allFetched = false
      }

      fetchStatusJS.nextRequestOffset = s.get('orders').size
      fetchStatusJS.loading = false
      fetchStatusJS.lastUpdate = new Date()
      s = setSearchOrdersFetchStatusInSearchOrders(s, fromJS(fetchStatusJS))
      return s
    }
    default:
      return state
  }
}
