/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/no-unused-state */
import React from 'react'
import { IImportExcelMenuItem, ISelectedStoreMap, IStoreWebsite, IXScreenProps } from 'x/index'
import * as NavActions from 'x/utils/navigation'
import * as util from 'x/utils/util'
import _ from 'lodash'
// import api from 'x/utils/api'
import * as api from 'x/utils/api'
import p from 'x/config/platform-specific'
import { StackNavigationProp } from '@react-navigation/stack'

interface IHamburgerChannelMenuOptions {
  key: string
  label: string
  onPress: () => void | Promise<void>
  icon?: {
    name: string
    family?:
      | 'AntDesign'
      | 'Entypo'
      | 'EvilIcons'
      | 'Feather'
      | 'FontAwesome'
      | 'FontAwesome5'
      | 'Foundation'
      | 'Ionicons'
      | 'MaterialCommunityIcons'
      | 'MaterialIcons'
      | 'Octicons'
      | 'SimpleLineIcons'
      | 'Zocial'
  }
  perm?: string | string[]
  isValidation: () => boolean
  isDanger?: boolean
}

// @ts-ignore
interface IBaseStoreWebsiteViewProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
  subscription: any
  navigation: StackNavigationProp<any>
}

interface IBaseStoreWebsiteViewState {
  storeWebsite: IStoreWebsite
  hamburgerMenuOptions: IHamburgerChannelMenuOptions[]
  isHamburgerMenuOpened: boolean
  containerWidth: number

  isInitialized: boolean
}

export default abstract class BaseStoreWebsiteView extends React.Component<IBaseStoreWebsiteViewProps, IBaseStoreWebsiteViewState> {
  // abstract async handleSubmitForm(response: any): Promise<void>
  MENU_ITRMS: IImportExcelMenuItem[]

  constructor(props) {
    super(props)
    this.state = {
      storeWebsite: null,
      hamburgerMenuOptions: null,
      isHamburgerMenuOpened: false,
      containerWidth: 320,
      isInitialized: true,
    }
    // this.webview = React.createRef()
    this.MENU_ITRMS = [
      {
        label: 'ตั้งค่าเว็บไซต์',
        isHeader: true,
        isShowMenu: () => true,
      },
      {
        label: 'การจัดส่งและชำระ',
        // iconName: 'group',
        // iconFamily: 'MaterialIcons',
        onclick: () => this._navToStoreWebsiteSettingPaymentView(),
        isShowMenu: () => true,
      },
      {
        label: 'คำสั่งซื้อ',
        // iconName: 'group',
        // iconFamily: 'MaterialIcons',
        onclick: () => this._navToStoreWebsiteSettingOptionsOrderView(),
        isShowMenu: () => true,
      },
      {
        label: 'Pixel',
        // iconName: 'group',
        // iconFamily: 'MaterialIcons',
        onclick: () => null,
        isShowMenu: () => true,
      },
      {
        label: 'เว็บไซต์',
        // iconName: 'group',
        // iconFamily: 'MaterialIcons',
        onclick: () => this._navToSalePageSettingWebView(),
        isShowMenu: () => true,
      },
    ]
  }

  async componentDidMount() {
    // const params = util.getNavParams(this.props)
    // const { salePage } = params
    // if (_.isNil(salePage)) {
    //   this.setState({
    //     // @ts-ignore
    //     salePage: {
    //       template: 'sale_page_one_option',
    //       name: 'หูฟังคุณภาพ ลดพิเศษสำหรับเทศกาลฤดูลดราคาที่กำลังมาแรงในขณะนี้',
    //       id: 456123,
    //       created_at: '2023-07-16 10:30:27',
    //     },
    //   })
    // } else {
    //   this.setState({ salePage })
    // }

    await this._fetchWebsiteDetail()
    await this._configHamburgerMenuItems()
  }

  _fetchWebsiteDetail = async () => {
    const { selectedStore } = this.props
    // @ts-ignore
    const sp = util.getNavParam(this.props, 'storeWebsite')

    try {
      const storeWebSiteDetail = await api.getStoreWebsite({ store_id: selectedStore.get('id'), website_id: sp.id })
      if (storeWebSiteDetail.id) {
        this.setState({ storeWebsite: storeWebSiteDetail })
      }
    } catch (error) {
      console.log('_fetchWebsiteDetail error => ', error)
    }
  }

  _configHamburgerMenuItems = async () => {
    const menu = []

    const deleteWebsite: IHamburgerChannelMenuOptions = {
      key: 'delete_store_website',
      label: 'ลบเว็บไซต์',
      onPress: () => this._deletedWebsite(),
      icon: { name: 'close-o', family: 'EvilIcons' },
      isDanger: true,
      isValidation: () => true,
    }

    menu.push(deleteWebsite)

    // if (isActive) {
    //   menu.push(unAuthErp)
    // } else {
    //   menu.push(authErp)
    // }

    await util.setStatePromise(this, {
      hamburgerMenuOptions: menu,
    })
  }

  _navToSalePageSettingWebView = () => {
    const { storeWebsite } = this.state
    const { navigation } = this.props
    navigation.dispatch(
      NavActions.navToSalePageSetttingWebView({
        storeWebsite,
      })
    )
  }

  _callBackForUpdateStoreWebsite = (newShippingSetting: IStoreWebsite) => {
    // console.log('newShippingSetting => ', newShippingSetting)
    // const { storeWebsite } = this.state
    // const newSalePage = _.clone(storeWebsite)
    // // @ts-ignore
    // newSalePage.default_shipping_and_payment = newShippingSetting
    this.setState({ storeWebsite: newShippingSetting })
  }

  _navToStoreWebsiteSettingPaymentView = () => {
    const { storeWebsite } = this.state
    const { navigation } = this.props
    navigation.dispatch(
      NavActions.navToStoreWebsiteSettingPaymentView({
        storeWebsite,
        callBackSucceedUpdateApi: (newStoreWebsite: IStoreWebsite) => this._callBackForUpdateStoreWebsite(newStoreWebsite),
      })
    )
  }

  _navToStoreWebsiteSettingOptionsOrderView = () => {
    const { storeWebsite } = this.state
    const { navigation } = this.props
    navigation.dispatch(
      NavActions.navToStoreWebsiteSettingOptionsOrderView({
        storeWebsite,
        callBackSucceedUpdateApi: (newStoreWebsite: IStoreWebsite) => this._callBackForUpdateStoreWebsite(newStoreWebsite),
      })
    )
  }

  _confirmChangeMkpSetting = async () => {
    const confirm = await new Promise<boolean>((passConfirm) => {
      p.op.showConfirmation(
        'ลบเว็บไซต์?',
        'กดปุ่มยืนยันหากคุณต้องการลบเว็บไซต์',
        () => passConfirm(true),
        () => passConfirm(false),
        'ยืนยัน',
        'ยกเลิก'
      )
    })
    return confirm
  }

  _deletedWebsite = async () => {
    const { storeWebsite } = this.state
    const { selectedStore } = this.props

    const confirm = await this._confirmChangeMkpSetting()
    if (!confirm) {
      return
    }

    const store_id = selectedStore.get('id')
    const reqBody = {
      store_id,
      website_id: storeWebsite.id,
    }

    // const apiOptions: IApiOptions = {
    //   showSpinner: true,
    // }

    // const res: {
    //   status: 'ok'
    // } = await api.postV2('store/website/delete', reqBody, apiOptions)

    // console.log('DELETED res! => ', res)

    // if (res.status === 'ok') {
    //   util.navGoBack(this.props)
    //   p.op.showToast('ลบเว็บไซต์เรียบร้อยแล้ว', 'success')
    // }

    const isDeleted = await api.deleteStoreWebsite(reqBody)

    if (isDeleted) {
      // @ts-ignore
      util.navGoBack(this.props)
      // @ts-ignore
      const callBackForSucceedDeleteStoreWebsite = util.getNavParam(this.props, 'callBackForSucceedDeleteStoreWebsite')
      if (_.isFunction(callBackForSucceedDeleteStoreWebsite)) {
        callBackForSucceedDeleteStoreWebsite()
      }
    }
  }
}
