// import PropTypes from 'prop-types'
import BaseUIUserGroupListView from 'xui/modules/store/BaseUIUserGroupListView'

export default class UserGroupListView extends BaseUIUserGroupListView {
  static displayName = 'UserGroupListView'

  // static navigationOptions = ({ navigation }) => ({
  //   header: null,
  // })

  renderQRCodeModal = () => null
}
