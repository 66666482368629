import { connect } from 'react-redux'
import { getState, getDispatch } from 'x/modules/marketplace/MkpProductDetailConnect'
import MkpProductDetailMapping from './MkpProductDetailMapping'
import { withMkpProductContextPropsAndRef } from './MkpProductContextHOC'

const MkpProductDetailMappingContainer = withMkpProductContextPropsAndRef(MkpProductDetailMapping)
// // @ts-ignore
// MkpProductDetailMappingContainer.navigationOptions = { header: null }

export default connect(
  (state) => ({
    ...getState(state),
  }),
  (dispatch) => {
    return {
      ...getDispatch(dispatch),
    }
  }
)(MkpProductDetailMappingContainer)
