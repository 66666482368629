import { connect } from 'react-redux'
// import { getState, getDispatch } from 'x/modules/order/BaseOrderViewConnect'
import { bindActionCreators } from 'redux'
import * as StoreActions from 'x/modules//store/StoreState'
import { getSelectedStore, getMySubscription, getLoadingInProgress } from 'x/redux/selectors'
import ChannelListView from './ChannelListView'

export default connect(
  (state) => ({
    // ...getState(state),
    selectedStore: getSelectedStore(state),
    subscription: getMySubscription(state),
    loadingInProgress: getLoadingInProgress(state),
  }),
  (dispatch) => ({
    updateNewChannelList: bindActionCreators(StoreActions.updateNewChannelList, dispatch),
    getChannels: bindActionCreators(StoreActions.getChannels, dispatch),
    getShopeeAuthUrl: bindActionCreators(StoreActions.shopeeAuthUrl, dispatch),
    getMkpReAuthUrl: bindActionCreators(StoreActions.mkpReAuthUrl, dispatch),
    deleteMkpChannel: bindActionCreators(StoreActions.mkpReAuthUrl, dispatch),
    // ...getDispatch(dispatch),
  })
)(ChannelListView)
