import React from 'react'
import _ from 'lodash'

import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'

import { IXShippingConfig } from 'x/config/app'
import api from 'x/utils/api'
import { IXScreenProps } from 'x/types'

export interface BaseStoreSettingXShippingNinjaVanViewProps extends IXScreenProps {
  selectedStore: any
  getXShippingConfigFromRedux: IXShippingConfig[]
  setXShippingConfig: (newConfig: IXShippingConfig[]) => void
}
// isNinjaVanxShipjungCODLoading
export interface BaseStoreSettingXShippingNinjaVanViewState {
  // J&T X Shipjung
  useNinjaVanxShipjung: boolean
  useAutoXShippingIsNinjaVanxShipjungReadyToShip: boolean
  loadingNinjaVanxShipjungWidth: number
  loadingNinjaVanxShipjungHeight: number
  useNinjaVanxShipjungCOD: boolean
  isNinjaVanxShipjungLoading: boolean
  useAutoXShippingIsNinjaVanxShipjungCODReadyToShip: boolean
  // useAutoJAndTxShipjungCreateCODPaymentWhenDelivered: boolean
  loadingNinjaVanxShipjungCODWidth: number
  loadingNinjaVanxShipjungCODHeight: number
  isNinjaVanxShipjungCODLoading: boolean
}

// const STATE_KEY_FLASH_X_SHIPJUNG_FOR_UPDATE = {
//   useJAndT: 'useJAndT',
//   useAutoXShippingIsJAndTReadyToShip: 'useAutoXShippingIsJAndTReadyToShip',
//   useJAndTCOD: 'useJAndTCOD',
//   useAutoXShippingIsJAndTCODReadyToShip: 'useAutoXShippingIsJAndTCODReadyToShip',
//   useAutoJAndTCreateCODPaymentWhenDelivered: 'useAutoJAndTCreateCODPaymentWhenDelivered',
// }

const STATE_KEY_FLASH_X_SHIPJUNG_FOR_UPDATE = {
  useNinjaVanxShipjung: 'useNinjaVanxShipjung',
  useAutoXShippingIsNinjaVanxShipjungReadyToShip: 'useAutoXShippingIsNinjaVanxShipjungReadyToShip',
  useNinjaVanxShipjungCOD: 'useNinjaVanxShipjungCOD',
  useAutoXShippingIsNinjaVanxShipjungCODReadyToShip: 'useAutoXShippingIsNinjaVanxShipjungCODReadyToShip',
}

export default abstract class BaseStoreSettingXShippingNinjaVanView extends React.Component<
  BaseStoreSettingXShippingNinjaVanViewProps,
  BaseStoreSettingXShippingNinjaVanViewState
> {
  // DISPLAY_TABS: IUserListTab[]
  // _tabRef: React.RefObject<any>
  // helperProfileId: number

  protected constructor(props) {
    super(props)

    this.state = {
      // J&T X Shipjung
      useNinjaVanxShipjung: false,
      useAutoXShippingIsNinjaVanxShipjungReadyToShip: true,
      loadingNinjaVanxShipjungWidth: 0,
      loadingNinjaVanxShipjungHeight: 0,
      isNinjaVanxShipjungLoading: false,
      useNinjaVanxShipjungCOD: false,
      useAutoXShippingIsNinjaVanxShipjungCODReadyToShip: true,
      // useAutoJAndTxShipjungCreateCODPaymentWhenDelivered: true,
      loadingNinjaVanxShipjungCODWidth: 0,
      loadingNinjaVanxShipjungCODHeight: 0,
      isNinjaVanxShipjungCODLoading: false,
    }
    // this._tabRef = React.createRef()
  }

  async componentDidMount() {
    const { getXShippingConfigFromRedux } = this.props
    // const { state } = navigation
    // const { tabIndex, permissionList, permission_id } = state.params
    await this.setKerryConfig(getXShippingConfigFromRedux)
  }

  setKerryConfig = async (config: IXShippingConfig[]) => {
    if (_.isNil(config)) {
      return
    }
    if (_.isArray(config) && config.length > 0) {
      // Ninja x Shipjung
      let useNinjaVanxShipjung = false
      let useAutoXShippingIsNinjaVanxShipjungReadyToShip = false
      let useNinjaVanxShipjungCOD = false
      let useAutoXShippingIsNinjaVanxShipjungCODReadyToShip = false
      // let useAutoJAndTxShipjungCreateCODPaymentWhenDelivered = false
      config.map((config: IXShippingConfig) => {
        if (config.shipping_type_id === 7) {
          // Ninja x Shipjung
          useNinjaVanxShipjung = true
          useAutoXShippingIsNinjaVanxShipjungReadyToShip = config.set_auto_request_xshipping_on_create_order
            ? config.set_auto_request_xshipping_on_create_order
            : false
        } else if (config.shipping_type_id === 26) {
          // Ninja x Shipjung COD
          useNinjaVanxShipjungCOD = true
          useAutoXShippingIsNinjaVanxShipjungCODReadyToShip = config.set_auto_request_xshipping_on_create_order
            ? config.set_auto_request_xshipping_on_create_order
            : false
        }
      })
      await util.setStatePromise(this, {
        // Ninja x Shipjung
        useNinjaVanxShipjung,
        useAutoXShippingIsNinjaVanxShipjungReadyToShip,
        useNinjaVanxShipjungCOD,
        useAutoXShippingIsNinjaVanxShipjungCODReadyToShip,
      })
    }
  }

  useJAndTOnChange = (newValue: boolean) => {
    util.setStatePromise(this, {
      useJAndT: newValue,
      isJAntTLoading: true,
    })
  }

  // useAutoXShippingIsJAndTReadyToShipOnChange = (newValue: boolean) => {
  //   util.setStatePromise(this, {
  //     useAutoXShippingIsJAndTReadyToShip: newValue,
  //   })
  // }

  // useJAndTCODOnChange = (newValue: boolean) => {
  //   util.setStatePromise(this, {
  //     useJAndTCOD: newValue,
  //   })
  // }

  // useAutoXShippingIsJAndTCODReadyToShipOnChange = (newValue: boolean) => {
  //   util.setStatePromise(this, {
  //     useAutoXShippingIsJAndTCODReadyToShip: newValue,
  //   })
  // }

  _setNewConfig = async (newValue: { isValue: boolean; stateKey: string }) => {
    // console.log('newValue => ', newValue)
    const { selectedStore, navigation, setXShippingConfig } = this.props
    const body: { [key: string]: any } = {
      store_id: selectedStore.get('id'),
    }

    let apiMethod = api.patchV2
    const STATE_KEY = newValue.stateKey
    const IS_VALUE = newValue.isValue
    // console.log('STATE_KEY => ', STATE_KEY)
    if (STATE_KEY === STATE_KEY_FLASH_X_SHIPJUNG_FOR_UPDATE.useNinjaVanxShipjung) {
      if (IS_VALUE) {
        p.op.showConfirmationOkOnly('', p.op.t('xshipping.warning.how_to_use_xshipping'))
        await util.setStatePromise(this, {
          [STATE_KEY]: !IS_VALUE,
        })
      } else {
        p.op.showConfirmationOkOnly('', p.op.t('xshipping.warning.how_to_un_use_xshipping'))
        await util.setStatePromise(this, {
          [STATE_KEY]: !IS_VALUE,
        })
      }
      return
      // await util.setStatePromise(this, {
      //   isJAntTLoading: true,
      // })
      // body.system_id = 3
      // body.shipping_type_id = 38
      // if (IS_VALUE) {
      //   apiMethod = api.putV2
      //   body.set_auto_request_xshipping_on_create_order = true
      // } else {
      //   apiMethod = api.delV2
      // }
    }
    if (STATE_KEY === STATE_KEY_FLASH_X_SHIPJUNG_FOR_UPDATE.useAutoXShippingIsNinjaVanxShipjungReadyToShip) {
      await util.setStatePromise(this, {
        isNinjaVanxShipjungLoading: true,
      })
      body.shipping_type_id = 7
      body.system_id = 2
      body.set_auto_request_xshipping_on_create_order = IS_VALUE
    } else if (STATE_KEY === STATE_KEY_FLASH_X_SHIPJUNG_FOR_UPDATE.useNinjaVanxShipjungCOD) {
      // console.log('PASS => ', IS_VALUE)
      if (IS_VALUE) {
        p.op.showConfirmationOkOnly('', p.op.t('xshipping.warning.how_to_use_xshipping'))
        await util.setStatePromise(this, {
          [STATE_KEY]: !IS_VALUE,
        })
      } else {
        p.op.showConfirmationOkOnly('', p.op.t('xshipping.warning.how_to_un_use_xshipping'))
        await util.setStatePromise(this, {
          [STATE_KEY]: !IS_VALUE,
        })
      }
      return
      // await util.setStatePromise(this, {
      //   isJAndTCODLoading: true,
      // })
      // body.shipping_type_id = 39
      // body.system_id = 3
      // if (IS_VALUE) {
      //   apiMethod = api.putV2
      //   body.set_auto_request_xshipping_on_create_order = true
      //   body.create_cod_payment_when_delivered = false
      // } else {
      //   apiMethod = api.delV2
      // }
    } else if (STATE_KEY === STATE_KEY_FLASH_X_SHIPJUNG_FOR_UPDATE.useAutoXShippingIsNinjaVanxShipjungCODReadyToShip) {
      await util.setStatePromise(this, {
        isNinjaVanxShipjungCODLoading: true,
      })
      body.shipping_type_id = 26
      body.system_id = 2
      body.set_auto_request_xshipping_on_create_order = IS_VALUE
    }

    const isMainBtn =
      STATE_KEY === STATE_KEY_FLASH_X_SHIPJUNG_FOR_UPDATE.useNinjaVanxShipjung ||
      STATE_KEY === STATE_KEY_FLASH_X_SHIPJUNG_FOR_UPDATE.useNinjaVanxShipjungCOD
    if (isMainBtn && !IS_VALUE) {
      apiMethod = api.delV2
    }

    const response: { xshipping_config: IXShippingConfig[] } = await apiMethod(api.XSHIPPING_CONFIG, body)
    if (response.xshipping_config) {
      setXShippingConfig(response.xshipping_config)
      await this.setKerryConfig(response.xshipping_config)
      // const { state } = navigation
      // const { onPressGoBack } = state.params
      // // console.log('onPressGoBack => ', onPressGoBack)
      const onPressGoBack = util.getNavParam(this.props, 'onPressGoBack')
      if (_.isFunction(onPressGoBack)) {
        onPressGoBack()
      }
    }

    if (
      STATE_KEY === STATE_KEY_FLASH_X_SHIPJUNG_FOR_UPDATE.useNinjaVanxShipjung ||
      STATE_KEY === STATE_KEY_FLASH_X_SHIPJUNG_FOR_UPDATE.useAutoXShippingIsNinjaVanxShipjungReadyToShip
    ) {
      await util.setStatePromise(this, {
        isNinjaVanxShipjungLoading: false,
      })
    } else if (
      STATE_KEY === STATE_KEY_FLASH_X_SHIPJUNG_FOR_UPDATE.useNinjaVanxShipjungCOD ||
      STATE_KEY === STATE_KEY_FLASH_X_SHIPJUNG_FOR_UPDATE.useAutoXShippingIsNinjaVanxShipjungCODReadyToShip
    ) {
      await util.setStatePromise(this, {
        isNinjaVanxShipjungCODLoading: false,
      })
    }

    // console.log('response => ', response)
  }

  isSwitchOnChange = async (newValue: { isValue: boolean; stateKey: string }) => {
    // console.log('isSwitchOnChange => ', newValue)
    // const STATE_KEY = newValue.stateKey
    // const IS_VALUE = newValue.isValue
    // let alertText = ''
    // if (STATE_KEY === STATE_KEY_FLASH_X_SHIPJUNG_FOR_UPDATE.useJAndT) {
    //   alertText = IS_VALUE ? 'ยืนยันการใช้งานออกเลขพัสดุ Kerry Express ใช่หรือไม่' : 'ยกเลิกการใช้งานออกเลขพัสดุ Kerry Express ใช่หรือไม่'
    // } else if (STATE_KEY === STATE_KEY_FLASH_X_SHIPJUNG_FOR_UPDATE.useAutoXShippingIsJAndTReadyToShip) {
    //   alertText = IS_VALUE ? 'ยืนยันการใช้งาน "ขอเลขพัสดุเมื่อพร้อมจัดส่ง" Kerry Express ใช่หรือไม่' : 'ยกเลิกการใช้งาน "ขอเลขพัสดุเมื่อพร้อมจัดส่ง" Kerry Express ใช่หรือไม่'
    // } else if (STATE_KEY === STATE_KEY_FLASH_X_SHIPJUNG_FOR_UPDATE.useJAndTCOD) {
    //   alertText = IS_VALUE ? 'ยืนยันการใช้งาน "ขอเลขพัสดุเมื่อพร้อมจัดส่ง" Kerry Express [COD] ใช่หรือไม่' : 'ยกเลิกการใช้งาน "ขอเลขพัสดุเมื่อพร้อมจัดส่ง" Kerry Express [COD] ใช่หรือไม่'
    // } else if (STATE_KEY === STATE_KEY_FLASH_X_SHIPJUNG_FOR_UPDATE.useAutoXShippingIsJAndTCODReadyToShip) {
    //   alertText = IS_VALUE ? 'ยืนยันการใช้งาน "ขอเลขพัสดุเมื่อพร้อมจัดส่ง" Kerry Express [COD] ใช่หรือไม่' : 'ยกเลิกการใช้งาน "ขอเลขพัสดุเมื่อพร้อมจัดส่ง" Kerry Express [COD] ใช่หรือไม่'
    // } else if (STATE_KEY === STATE_KEY_FLASH_X_SHIPJUNG_FOR_UPDATE.useAutoJAndTCreateCODPaymentWhenDelivered) {
    //   alertText = IS_VALUE ? 'ยืนยันการใช้งาน "บันทึกรับชำระเมื่อพัสดุถึงผู้รับแล้ว" ใช่หรือไม่' : 'ยกเลิกการใช้งาน "บันทึกรับชำระเมื่อพัสดุถึงผู้รับแล้ว" ใช่หรือไม่'
    // }

    // let isConfirm = false
    // await new Promise(async (resolve) => {
    //   p.op.showConfirmation('', alertText, () => {
    //     isConfirm = true
    //     // @ts-ignore
    //     resolve(null)
    //   }, () => {
    //     // @ts-ignore
    //     resolve(null)
    //   }, 'ตกลง', 'ยกเลิก')
    // })

    // if (!isConfirm) {
    //   await util.setStatePromise(this, {
    //     [newValue.stateKey]: !IS_VALUE,
    //   })
    //   return
    // }
    await this._setNewConfig(newValue)
    // util.setStatePromise(this, {
    //   [newValue.stateKey]: newValue.isValue,
    // })
  }
}
