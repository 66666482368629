import React, { useState } from 'react'

import _ from 'lodash'

import { ITimeSlotsViewProps, IMKPChannelDetail } from 'x/index'
import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'

import XTimeSlots from 'xui/components/XTimeSlots'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import XCard from 'xui/components/XCard'
import Box from 'xui/components/Box'

const PageLayout: React.FC<{
  onSave: () => void
  doGoBack: () => void
  isLoading?: boolean
  isDirty?: boolean
  fixedContainerWidth?: number
}> = ({ children, onSave, doGoBack, isLoading = false, isDirty = false, fixedContainerWidth }) => {
  const handleWarningDirty = async () => {
    const isUserConfirm = await p.op.isUserConfirm(
      'ละทิ้งการแก้ไข',
      'กรุณายืนยันว่าฉันต้องการละทิ้งการแก้ไขทั้งหมด',
      'ละทิ้ง',
      'กลับไปแก้ไข'
    )
    if (isUserConfirm) {
      doGoBack()
    }
  }
  return (
    <XContainer>
      <XCustomHeader
        // headerStyle={{ marginTop: 0 }}
        headerLeftProps={{
          backIcon: true,
          onPressItem: doGoBack,
          submitting: isLoading,
        }}
        title='ช่องทางการจัดส่ง'
      />
      <XContent p='2'>
        <XCard>{children}</XCard>
      </XContent>
    </XContainer>
  )
}

const TimeSlotsView: React.FC<ITimeSlotsViewProps> = props => {
  const { webMaxScreenWidth, setPrefTimeslot, selectedStore, updateNewChannelList } = props
  const channelFromParam = util.getNavParam(props, 'channel')
  const callBackUpdateChannel = util.getNavParam(props, 'callBackUpdateChannel')

  const [channel, updateChannel] = useState(channelFromParam)

  const navBack = () => {
    util.navGoBack(props)
  }

  const updateChannelFromCallBack = (callBackChannel: IMKPChannelDetail) => {
    updateChannel(callBackChannel)
    callBackUpdateChannel(callBackChannel)
  }

  const renderMain = () => (
    <Box style={{ flex: 1, paddingTop: 10, paddingLeft: 4, paddingRight: 4, paddingBottom: 10 }}>
      <XTimeSlots
        channel={channel}
        setPrefTimeslot={setPrefTimeslot}
        selectedStore={selectedStore}
        callBackUpdateChannel={(callBackChannel: IMKPChannelDetail) => updateChannelFromCallBack(callBackChannel)}
        key={channel.id}
        updateNewChannelList={updateNewChannelList}
      />
    </Box>
  )

  return (
    <PageLayout
      // webMaxScreenWidth / fixedContainerWidth ใช้เพื่อระบุ width สำหรับให้ View react-native-web สามารถรู้ขอบเขตของ flex
      fixedContainerWidth={p.op.isWeb() && _.isNumber(webMaxScreenWidth) ? webMaxScreenWidth : null}
      isLoading={false}
      onSave={null}
      doGoBack={navBack}
      isDirty={false}>
      {renderMain()}
    </PageLayout>
  )
}

// // @ts-ignore disable react-navigation header
// TimeSlotsView.navigationOptions = {
//   header: null,
// }

export default TimeSlotsView
