import React from 'react'

import _ from 'lodash'
// import { COLORS, STYLES } from '../../config/styles'
import p from '../../config/platform-specific'
import * as util from '../../utils/util'

import { BaseStoreSettingPDFProps, BaseStoreSettingPDFState } from '../../index'

export default abstract class BaseStoreSettingPDFShippingLabel extends React.Component<BaseStoreSettingPDFProps, BaseStoreSettingPDFState> {
  inProcess: boolean

  printingAllId: number[]
  // OPTIONS_LIST_BY: string[]
  // OPTIONS_DATE_RANGE: string[]

  // abstract _getParams(): { store_id: number } | null
  abstract _goBack(): any

  protected constructor(props) {
    super(props)

    this.state = {
      PDFData: null,
      usePDF: false,
      printingId: null,
    }

    this.inProcess = false
    this.printingAllId = [11, 31, 32, 33, 21, 22, 23]
  }

  async componentDidMount() {
    const { selectedStore } = this.props
    const printingPDF = selectedStore.get('printing_pdf')
    await this.config(printingPDF)
  }

  config = async (PDFData) => {
    if (_.isNil(PDFData)) {
      p.op.showConfirmationOkOnly('เกิดข้อผิดพลาด', 'กรุณาติดต่อทีมงาน Xselly')
      this._goBack()
      return
    }
    const printing = PDFData.toJS()
    const usePDF = printing.a
    const printingId = printing.p.i
    // const printingId = 9999
    //
    this.props.navigation.setParams({
      cancelBtn: () => this.cancelButton(),
      onChange: false,
      saveBtn: () => this.saveButton(),
    })

    util.setStatePromise(this, {
      usePDF,
      PDFData: printing,
      printingId,
    })
  }

  onChangeValue = (key, value) => {
    this.props.navigation.setParams({
      onChange: true,
    })
    util.setStatePromise(this, {
      [key]: value,
    })
  }

  onChangeSelectedValue = async (value: number) => {
    const { printingId } = this.state
    if (printingId === value) {
      return
    }
    const havePrintting = _.includes(this.printingAllId, printingId)
    let confirm = true
    if (!havePrintting) {
      // 9999 คือเลข สำหรับถ้าเป็น id custom
      await new Promise((resolve) => {
        p.op.showConfirmation(
          `คุณกำลังใช้ใบปะหน้าพิเศษอยู่`,
          'หากคุณเปลี่ยนใบปะหน้าใหม่แล้วจะไม่สามารถกลับไปใช้ใบปะหน้าแบบพิเศษได้อีก หากเปลี่ยนไปแล้วแล้วอยากได้ใบปะหน้าแบบพิเศษอีก กรุณาติดต่อทมงาน XSelly',
          () => {
            confirm = true
            setTimeout(resolve, 200)
          },
          () => {
            confirm = false
            setTimeout(resolve, 200)
          },
          'ยืนยันเปลี่ยนใบปะหน้า',
          'ยกเลิก'
        )
      })
    }
    if (!confirm) {
      return
    }
    this.props.navigation.setParams({
      onChange: true,
    })
    util.setStatePromise(this, {
      printingId: value,
    })
  }

  cancelButton = () => {
    // const { navigation } = this.props
    // const { state } = navigation
    // const { onChange } = state.params
    const onChange = util.getNavParam(this.props, 'onChange')
    if (onChange) {
      p.op.showConfirmation(
        'ยกเลิกการแก้ไข',
        'ยกเลิกการแก้ไขใช่หรือไม่',
        () => this._goBack(),
        () => {},
        'ใช่',
        'แก้ไขต่อ'
      )
    } else {
      this._goBack()
    }
  }

  saveButton = () => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    const { addPrinting, selectedStore, editPrinting } = this.props
    const { usePDF, PDFData, printingId } = this.state
    const store_id = selectedStore.get('id')

    const isActivePaperang = selectedStore.has('s_use_printing_paperang') ? selectedStore.get('s_use_printing_paperang') : true
    const isActiveSticker = selectedStore.has('s_use_printing_sticker_pdf') ? selectedStore.get('s_use_printing_sticker_pdf') : true

    if (!isActivePaperang && !isActiveSticker && !usePDF) {
      p.op.showConfirmationOkOnly(
        p.op.t('printing.warning.WARNING_CANT_CLOSS_LAST_PRINTING.title'),
        p.op.t('printing.warning.WARNING_CANT_CLOSS_LAST_PRINTING.message')
      )
      return
    }

    const payload = {
      body: {
        store_id,
        printing_type_id: 1,
        size: 1,
        is_active: usePDF,
        pref: {
          i: printingId,
          l: 'th',
        },
      },
      successCallback: (res) => this.successCallback(res),
      failedCallback: (err) => this.failedCallback(),
    }

    if (_.isNil(PDFData.id)) {
      addPrinting(payload)
    } else {
      // @ts-ignore
      payload.body.printing_id = PDFData.id
      editPrinting(payload)
    }
  }

  successCallback = (res) => {
    this.props.navigation.setParams({
      onChange: false,
    })
    const printing = res.store_printing
    util.setStatePromise(this, {
      PDFData: printing,
      usePDF: printing.a,
      printingId: printing.p.i,
    })
    p.op.showToast('บันทึกข้อมูลเรียบร้อยแล้ว', 'success')
    this.inProcess = false
  }

  failedCallback = () => {
    p.op.showToast('เกิดข้อผิดพลาดกรุณาทำรายการใหม่ภายหลัง', 'warning')
    this.props.navigation.setParams({
      onChange: false,
    })
    this.inProcess = false
  }
}
