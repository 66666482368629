import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as StoreState from 'x/modules/store/StoreState'

import StoreSettingXShippingView from './StoreSettingXShippingView'
import { getSelectedStore, getXShippingConfigFromRedux } from 'x/redux/selectors'
import * as XShippingActions from 'x/modules/xshipping/XShippingState'
import * as StoreActions from 'x/modules/store/StoreState'

export default connect(
  (state) => {
    return {
      selectedStore: getSelectedStore(state),
      getXShippingConfigFromRedux: getXShippingConfigFromRedux(state)
      
    }
  },
  (dispatch) => {
    return {
      addNewStoreSetting: bindActionCreators(StoreState.addNewStoreSetting, dispatch),
      setXShippingConfig: bindActionCreators(XShippingActions.setXShippingConfig, dispatch),
      // setMyStores: bindActionCreators(StoreState.setMyStores, dispatch),
      submitEditingXShipping: bindActionCreators(StoreActions.submitEditingStore, dispatch),
    }
  }
  // @ts-ignore
)(StoreSettingXShippingView)
