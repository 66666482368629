import React from 'react'
import _ from 'lodash'

// import p from 'x/config/platform-specific'
// import { PrintOptionKey } from 'x/index'

import { VIEW_HEIGHT_TEN } from 'xui/utils/BaseShareUI'
import CONS from 'x/config/constants'
import * as util from 'x/utils/util'
import p from 'x/config/platform-specific'
import Segment from 'xui/components/Segment'
import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import ShippingTypeSelectorOverlayView from 'xui/components/ShippingTypeSelectorOverlayView'
import FAQ from 'x/config/FAQ'
import XText from './XText'
import XButton from './XButton'
import XHelpModal from './XHelpModal'
import HelpButton from './HelpButton'

// interface IPrintingOptionOverlayButton extends IPrintingOptionUIProps {
//   renderButton?: (onPressOpenOverlay: () => void) => JSX.Element
//   buttonStyle?: ViewStyle
//   disabled?: boolean
// }

// interface IPrinterButtonProps {
//   onPress: () => void | Promise<void>
//   buttonStyle?: ViewStyle
//   isDisabled?: boolean
// }

interface IShippingTypeSelectorSegmentViewProps {
  disabled?: boolean
  // renderButton: (onPressOpenOverlay: () => void) => void
  headertitle: string
  customHeaderSegment?: () => void
  footerLable: string
  visibleShippingTypes: number[]
  activeShippingTypeIds: number[]
  onSubmit: (selectedShippingTypeIds: number[]) => void
  onRequestClose?: () => void
  // isVisible: boolean
}

interface IShippingTypeSelectorSegmentViewState {
  isVisibleShippingTypeSelectorOverlayView: boolean
  selectedShippingTypeIds: number[]
  selectedOptionOverlaySelectedShippingType: number
}

const defaultVisibleShippingTypes = []
CONS.ORDER_VIEW_SHIPPING_TYPE_ITEMS.map((shipping) => {
  defaultVisibleShippingTypes.push(shipping.value)
})

class ShippingTypeSelectorSegmentView extends React.Component<
  IShippingTypeSelectorSegmentViewProps,
  IShippingTypeSelectorSegmentViewState
> {
  static displayName = 'ShippingTypeSelectorSegmentView'

  constructor(props) {
    super(props)

    this.state = {
      isVisibleShippingTypeSelectorOverlayView: false,
      selectedShippingTypeIds: [], // activeShippingTypeIds ?
      selectedOptionOverlaySelectedShippingType: 0,
    }
  }

  async componentDidMount() {
    const { activeShippingTypeIds } = this.props
    if (activeShippingTypeIds && activeShippingTypeIds.length > 0) {
      await util.setStatePromise(this, { selectedShippingTypeIds: activeShippingTypeIds })
      await util.delay(100)
    }

    const { selectedShippingTypeIds } = this.state
    if (selectedShippingTypeIds.length > 0) {
      this.setSelectedOptionOverlaySelectedShippingType(1)
    }
  }

  setIsVisibleShippingTypeSelectorOverlayView = async (isVisibleShippingTypeSelectorOverlayView: boolean) => {
    await util.setStatePromise(this, { isVisibleShippingTypeSelectorOverlayView })
  }

  setSelectedShippingTypeIds = async (selectedShippingTypeIds: number[]) => {
    await util.setStatePromise(this, { selectedShippingTypeIds })
  }

  setSelectedOptionOverlaySelectedShippingType = async (selectedOptionOverlaySelectedShippingType: number) => {
    await util.setStatePromise(this, { selectedOptionOverlaySelectedShippingType })
  }

  _getShippingNames = () => {
    const { selectedShippingTypeIds = [] } = this.state
    // console.log('selectedShippingTypeIds => ', selectedShippingTypeIds)
    // const { selectedShippingTypeIds } = this.state
    if (selectedShippingTypeIds.length === 0) {
      return ''
    }

    let shippingNames = ''
    selectedShippingTypeIds.forEach((spId: number) => {
      const SHIPPING = _.find(CONS.ORDER_VIEW_SHIPPING_TYPE_ITEMS, (shipping) => shipping.value === spId)
      if (!_.isNil(SHIPPING)) {
        if (shippingNames === '') {
          shippingNames = p.op.t(`Order.shippingTypeItems.${SHIPPING.key}`)
        } else {
          shippingNames += `\n${p.op.t(`Order.shippingTypeItems.${SHIPPING.key}`)}`
        }
      }
    })
    return shippingNames
  }

  _renderHeaderSegment = () => {
    const { customHeaderSegment } = this.props

    if (_.isFunction(customHeaderSegment)) {
      return customHeaderSegment()
    }

    const _renderAgreement = (onPressOpenOverlay: () => void) => <HelpButton onPress={() => onPressOpenOverlay()} />

    return (
      <HStack alignItems='center'>
        <XText variant='active' bold>
          รูปแบบจัดส่ง
        </XText>
        <XHelpModal
          key='ซ่อนแสดงรูปแบบจัดส่ง'
          headerTitle='ซ่อนแสดงรูปแบบจัดส่ง'
          FAQ={FAQ.SHIPPING_TYPE_SELECTOR_FAQ}
          initiateOpenIndex={[0, 1]}
          renderButton={(onPressOpenOverlay: () => void) => _renderAgreement(onPressOpenOverlay)}
        />
      </HStack>
    )
  }

  _onRequestClose = async () => {
    const { onRequestClose } = this.props
    const { selectedShippingTypeIds } = this.state

    if (selectedShippingTypeIds.length < 1) {
      await this.setSelectedOptionOverlaySelectedShippingType(0)
      // xUtil.setStatePromise(this, {
      //   selectedOptionOverlaySelectedShippingType: 0,
      // })
    }

    await this.setIsVisibleShippingTypeSelectorOverlayView(false)

    if (_.isFunction(onRequestClose)) {
      onRequestClose()
    }

    // xUtil.setStatePromise(this, {
    //   isVisibleShippingTypeSelectorOverlayView: false,
    // })
  }

  _onSubmit = async (callBackActiveShippingTypeIds: number[]) => {
    const { onSubmit } = this.props

    if (callBackActiveShippingTypeIds.length < 1) {
      await this.setSelectedOptionOverlaySelectedShippingType(0)
      // xUtil.setStatePromise(this, {
      //   selectedOptionOverlaySelectedShippingType: 0,
      // })
    }
    await this.setIsVisibleShippingTypeSelectorOverlayView(false)
    await this.setSelectedShippingTypeIds(callBackActiveShippingTypeIds)
    onSubmit(callBackActiveShippingTypeIds)
    // xUtil.setStatePromise(this, {
    //   selectedShippingTypeIds: callBackActiveShippingTypeIds,
    //   isVisibleShippingTypeSelectorOverlayView: false,
    // })
  }

  _onSegmentChange = async (newIndex: number) => {
    // const { onSubmit } = this.props
    // await this.setSelectedOptionOverlaySelectedShippingType(0)
    // await this.setSelectedShippingTypeIds([])
    // onSubmit([])
    util.setStatePromise(this, {
      selectedOptionOverlaySelectedShippingType: newIndex,
      isVisibleShippingTypeSelectorOverlayView: newIndex !== 0,
    })

    if (newIndex === 0) {
      // const { onSubmit } = this.props
      // onSubmit([]) // Why ?????
      this._onSubmit([]) // Why ?????
    }
  }

  _onPressEdit = () => {
    this.setIsVisibleShippingTypeSelectorOverlayView(true)
  }

  _renderOverlay = () => {
    const {
      disabled = false,
      // // renderButton,
      // customHeaderSegment,
      headertitle,
      footerLable,
      visibleShippingTypes = defaultVisibleShippingTypes,
      // // activeShippingTypeIds,
      // onSubmit,
      // onRequestClose,
      // // isVisible,
    } = this.props
    const { isVisibleShippingTypeSelectorOverlayView, selectedShippingTypeIds, selectedOptionOverlaySelectedShippingType } = this.state
    const shippingNames = this._getShippingNames()

    return (
      <VStack w='full' py='2'>
        {this._renderHeaderSegment()}
        {/* <XText style={s.segmentedControlTextDesc}>{''}</XText> */}
        <ShippingTypeSelectorOverlayView
          headertitle={headertitle}
          footerLable={footerLable}
          isVisible={isVisibleShippingTypeSelectorOverlayView}
          // renderButton={(onPressOpenOverlay: () => void) => this._renderOverlaySelectedShippingType(onPressOpenOverlay)}
          selectedShippingTypeIds={selectedShippingTypeIds}
          visibleShippingTypes={visibleShippingTypes}
          onRequestClose={this._onRequestClose}
          onSubmit={this._onSubmit}
        />
        <Segment
          disabled={disabled}
          selectedIndex={selectedOptionOverlaySelectedShippingType}
          // selectedValue={this.state.selectedOptionPrintFilter}
          options={['ทั้งหมด', 'ระบุ']}
          onSegmentChange={this._onSegmentChange}
        />
        {VIEW_HEIGHT_TEN}

        <HStack w='full' space='1' alignItems='center'>
          {shippingNames !== '' && selectedShippingTypeIds.length > 0 && selectedOptionOverlaySelectedShippingType === 1 ? (
            <XText variant='inactive'>รูปแบบจัดส่งที่เลือก</XText>
          ) : null}
          {selectedShippingTypeIds.length > 0 && selectedOptionOverlaySelectedShippingType === 1 ? (
            <XButton disabled={disabled} isDisabled={disabled} variant='outline' onPress={this._onPressEdit}>
              แก้ไข
            </XButton>
          ) : null}
        </HStack>

        {selectedShippingTypeIds.length > 0 && selectedOptionOverlaySelectedShippingType === 1 ? <XText bold>{shippingNames}</XText> : null}
      </VStack>
    )
  }

  render() {
    //     const {
    //   disabled = false,
    //   // renderButton,
    //   customHeaderSegment,
    //   headertitle,
    //   footerLable,
    //   visibleShippingTypes,
    //   activeShippingTypeIds,
    //   onSubmit,
    //   onRequestClose,
    //   // isVisible,
    // } = this.props
    // const [isVisibleShippingTypeSelectorOverlayView, setIsVisibleShippingTypeSelectorOverlayView] = useState<boolean>(false)
    // const [selectedShippingTypeIds, setSelectedShippingTypeIds] = useState<number[]>(activeShippingTypeIds)
    // const [selectedOptionOverlaySelectedShippingType, setSelectedOptionOverlaySelectedShippingType] = useState<number>(0)
    // // const [isOverlayVisible, setIsOverlayVisible] = useState<boolean>(false)
    // // const [isSelectedAllShipping, setSelectedAllShipping] = useState<boolean>(false)
    // // const [overlayVisibleShippingTypes, setOverlayVisibleShippingTypes] = useState<number[]>(visibleShippingTypes)
    // // const [overlayActiveShippingTypeIds, setOverlayActiveShippingTypeIds] = useState<number[]>(activeShippingTypeIds)
    // // isUseEffect ใช้เผื่อควบคุม useEffect ถ่สมีข้อมูล channel เข้ามาแล้ว ให้หยุดใช้งาน useEffect
    // // const [isUseEffect, setIsUseEffect] = useState(true)
    // // console.log('overlayVisibleShippingTypes => ', overlayVisibleShippingTypes)
    // // console.log('overlayActiveShippingTypeIds => ', overlayActiveShippingTypeIds)

    // useEffect(() => {
    //   // console.log('selectedShippingTypeIds => 798 => ', selectedShippingTypeIds)
    //   if (selectedShippingTypeIds.length > 0) {
    //     setSelectedOptionOverlaySelectedShippingType(1)
    //   }
    //   // setSelectedShippingTypeIds(activeShippingTypeIds)
    //   // setIsOverlayVisible(isVisible)
    //   // if (!isDidMount && !_.isNil(visibleShippingTypes) && visibleShippingTypes.length > 0) {
    //   //   // console.log('useEffect !!! ')
    //   //   setIsDidMount(true)
    //   //   setOverlayVisibleShippingTypes(visibleShippingTypes)
    //   //   setOverlayActiveShippingTypeIds(activeShippingTypeIds)
    //   //   setActiveShippingTypeIds(activeShippingTypeIds)
    //   // }
    // }, [])
    // // const addresses = mkpChannel.addresses

    // // const _onSubmitingStateChange = async (newSubmiting: boolean) => {
    // //   await setSubmitting(newSubmiting)
    // // }
    // // console.log('channel 01', channel)
    // // console.log('mkpChannel => ', mkpChannel)
    // // const _openOverlay = () => setIsOverlayVisible(true)
    // // const _closeOverlay = () => setIsOverlayVisible(false)

    // const _getShippingNames = () => {
    //   // console.log('selectedShippingTypeIds => ', selectedShippingTypeIds)
    //   // const { selectedShippingTypeIds } = this.state
    //   if (selectedShippingTypeIds.length < 1) {
    //     return ''
    //   }
    //   let shippingNames = ''
    //   selectedShippingTypeIds.map((spId: number) => {
    //     const SHIPPING = _.find(CONS.ORDER_VIEW_SHIPPING_TYPE_ITEMS, (shipping) => shipping.value === spId)
    //     if (!_.isNil(SHIPPING)) {
    //       if (shippingNames === '') {
    //         shippingNames = p.op.t(`Order.shippingTypeItems.${SHIPPING.key}`)
    //       } else {
    //         shippingNames += `\n${p.op.t(`Order.shippingTypeItems.${SHIPPING.key}`)}`
    //       }
    //     }
    //   })
    //   return shippingNames
    // }

    // // const onCloseOverlay = () => {
    // // if (backUpActiveShippingTypeIds !== overlayActiveShippingTypeIds) {
    // //   p.op.showConfirmation(
    // //     'ยกเลิกการแก้ไข',
    // //     'คุณต้องการละทิ้งการแก้ไขใช่หรือไม่',
    // //     () => {
    // //       cancal()
    // //     },
    // //     () => {},
    // //     'ใช่',
    // //     'แก้ไขต่อ'
    // //   )
    // // } else {
    // //   cancal()
    // // }
    // // }

    // const renderHeaderSegment = () => {
    //   if (_.isFunction(customHeaderSegment)) {
    //     return customHeaderSegment()
    //   }
    //   return (
    //     <XText variant='active' bold>
    //       รูปแบบจัดส่ง
    //     </XText>
    //   )
    // }

    // const shippingNames = _getShippingNames()
    // // console.log('selectedShippingTypeIds *=> ', selectedShippingTypeIds)
    // const _renderOverlay = () => (
    //   <VStack w='full'>
    //     {renderHeaderSegment()}
    //     {/* <XText style={s.segmentedControlTextDesc}>{''}</XText> */}
    //     <ShippingTypeSelectorOverlayView
    //       headertitle={headertitle}
    //       footerLable={footerLable}
    //       isVisible={isVisibleShippingTypeSelectorOverlayView}
    //       // renderButton={(onPressOpenOverlay: () => void) => this._renderOverlaySelectedShippingType(onPressOpenOverlay)}
    //       selectedShippingTypeIds={selectedShippingTypeIds}
    //       visibleShippingTypes={visibleShippingTypes}
    //       onRequestClose={() => {
    //         if (selectedShippingTypeIds.length < 1) {
    //           setSelectedOptionOverlaySelectedShippingType(0)
    //           // xUtil.setStatePromise(this, {
    //           //   selectedOptionOverlaySelectedShippingType: 0,
    //           // })
    //         }
    //         setIsVisibleShippingTypeSelectorOverlayView(false)
    //         if (_.isFunction(onRequestClose)) {
    //           onRequestClose()
    //         }

    //         // xUtil.setStatePromise(this, {
    //         //   isVisibleShippingTypeSelectorOverlayView: false,
    //         // })
    //       }}
    //       onSubmit={(callBackActiveShippingTypeIds: number[]) => {
    //         if (callBackActiveShippingTypeIds.length < 1) {
    //           setSelectedOptionOverlaySelectedShippingType(0)
    //           // xUtil.setStatePromise(this, {
    //           //   selectedOptionOverlaySelectedShippingType: 0,
    //           // })
    //         }
    //         setIsVisibleShippingTypeSelectorOverlayView(false)
    //         setSelectedShippingTypeIds(callBackActiveShippingTypeIds)
    //         onSubmit(callBackActiveShippingTypeIds)
    //         // xUtil.setStatePromise(this, {
    //         //   selectedShippingTypeIds: callBackActiveShippingTypeIds,
    //         //   isVisibleShippingTypeSelectorOverlayView: false,
    //         // })
    //       }}
    //     />
    //     <Segment
    //       disabled={disabled}
    //       selectedIndex={selectedOptionOverlaySelectedShippingType}
    //       // selectedValue={this.state.selectedOptionPrintFilter}
    //       options={['ทั้งหมด', 'ระบุ']}
    //       onSegmentChange={(index: number) => {
    //         const visibleShippingTypes = []
    //         CONS.ORDER_VIEW_SHIPPING_TYPE_ITEMS.map((shipping) => {
    //           visibleShippingTypes.push(shipping.value)
    //         })
    //         if (index === 0) {
    //           setSelectedOptionOverlaySelectedShippingType(0)
    //           setSelectedShippingTypeIds([])
    //           onSubmit([])
    //           // xUtil.setStatePromise(this, {
    //           //   selectedOptionOverlaySelectedShippingType: 0,
    //           // })
    //         } else {
    //           setSelectedOptionOverlaySelectedShippingType(1)
    //           setIsVisibleShippingTypeSelectorOverlayView(true)
    //           // xUtil.setStatePromise(this, {
    //           //   selectedOptionOverlaySelectedShippingType: 1,
    //           //   isVisibleShippingTypeSelectorOverlayView: true,
    //           // })
    //           // onPressOpenOverlay()
    //         }
    //       }}
    //     />
    //     {VIEW_HEIGHT_TEN}

    //     <HStack w='full' space='1' alignItems='center'>
    //       {shippingNames !== '' && selectedShippingTypeIds.length > 0 && selectedOptionOverlaySelectedShippingType === 1 ? (
    //         <XText variant='inactive'>รูปแบบจัดส่งที่เลือก</XText>
    //       ) : null}
    //       {selectedShippingTypeIds.length > 0 && selectedOptionOverlaySelectedShippingType === 1 ? (
    //         <XButton
    //           disabled={disabled}
    //           isDisabled={disabled}
    //           variant='outline'
    //           onPress={() => {
    //             setIsVisibleShippingTypeSelectorOverlayView(true)
    //           }}>
    //           แก้ไข
    //         </XButton>
    //       ) : null}
    //     </HStack>

    //     {selectedShippingTypeIds.length > 0 && selectedOptionOverlaySelectedShippingType === 1 ? <XText bold>{shippingNames}</XText> : null}
    //   </VStack>
    // )

    // // Main render
    // return (
    //   <>
    //     {/* {_renderControlButton()} */}
    //     {_renderOverlay()}
    //   </>
    // )

    return this._renderOverlay()
  }
}

export default ShippingTypeSelectorSegmentView
