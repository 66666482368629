import { Map, List, fromJS } from 'immutable'
// import * as NavActions from '../../services/navigation'
// import { NavigationActions } from 'react-navigation'
// import { loop, Effects } from 'redux-loop-symbol-ponyfill'

import actions from '../../config/actions'
import { ActionApiParams } from '../../index'

// const initialProduct = Map({
//   // Only Add Mode
//   category_id: null,
//   created_at: null,
//   description: null,
//   disabled: null,
//   id: null,
//   img_uris: List([]),
//   master_id: null,
//   max: null,
//   min: null,
//   name: null,
//   parent_id: null,
//   qty: null,
//   stocks: List([]),
//   store_id: null,
//   thumbnail_uris: List([]),
//   update_at: null,
// })

// const initialSummary = Map(
//   CONS.ORDER_VIEW_KEY_SUMMARY_ITEMS.reduce((map, obj) => {
//     map[obj.key] = ''
//     map[`${obj.key}_firstTyping`] = true
//     return map
//   }, {})
// )

// const initialOrder = Map({
//   id: null,
//   name: null,
//   payment: null,
//   shipping: null,
//   sender: Map({}),
//   receiver: Map({}),
//   state: null,
//   expiration_date: null,
//   discount: null,
//   shipping_cost: null,
//   etc_cost: null,
//   created_at: null,
//   updated_at: null,
//   store_id: null,
//   total_price: null,
//   total_cost: null,
//   type: null,
//   products: List([]),
// })

const initialShipment = Map({
  order_id: null,
  store_id: null,
  products: List([]),
  tracking_number: '',
  // type: '',
  shipping_type_id: null,
  note: '',
})

// const initOrderProduct = Map({
//   pp_id: null,
//   product_id: null,
//   name: null,
//   img_uris: List([]),
//   thumbnail_uris: List([]),
//   variant: null,
//   qty: null,
//   price: null,
//   cost: null,
// })

// 1 : open / 2: loading / 9 : finished / other : waiting / -1 is error
const initCameraStates = Map({
  order: 1,
  ship: 0,
})

const initQuickShip = Map({
  cameraStates: initCameraStates,
  selectedOrder: Map({}),
  shipmentData: initialShipment,
})

// Initial state
const initialState = Map({
  stores: Map({}),
  selectedOrderShip: initialShipment,
  editingOrderShip: initialShipment,
  curQty: null,
  maxQty: null,
  quickship: initQuickShip,
})

// Actions
export const ACTION_SHIPMENT_INITIALIZE = 'ShipmentState/ACTION_SHIPMENT_INITIALIZE'
export const ACTION_SHIPMENT_RESET = 'ShipmentState/ACTION_SHIPMENT_RESET'
export const ACTION_SHIPMENT_ORDER_LOAD = 'ShipmentState/ACTION_SHIPMENT_ORDER_LOAD'

export const ACTION_SHIPMENT_INCREASE_QTY = 'ShipmentState/ACTION_SHIPMENT_INCREASE_QTY'
export const ACTION_SHIPMENT_DECREASE_QTY = 'ShipmentState/ACTION_SHIPMENT_DECREASE_QTY'

export const ACTION_SHIPMENT_CLEAR_QTY = 'ShipmentState/ACTION_SHIPMENT_CLEAR_QTY'
export const ACTION_SHIPMENT_MAX_QTY = 'ShipmentState/ACTION_SHIPMENT_MAX_QTY'

export const ACTION_SHIPMENT_DATA_CHANGE = 'ShipmentState/ACTION_SHIPMENT_DATA_CHANGE'

export const ACTION_SHIPMENT_SET_MAX_QTY = 'ShipmentState/ACTION_SHIPMENT_SET_MAX_QTY'
export const ACTION_SHIPMENT_SET_CURRENT_QTY = 'ShipmentState/ACTION_SHIPMENT_SET_CURRENT_QTY'

// Quick Shipping Mode (QS)
export const ACTION_QS_RESET = 'ShipmentState/ACTION_QS_RESET'

export const ACTION_QS_BARCODE_ORDER_FETCH = 'ShipmentState/ACTION_QS_BARCODE_ORDER_FETCH'
export const ACTION_QS_ORDER_LOAD = 'ShipmentState/ACTION_QS_ORDER_LOAD'
export const ACTION_QS_ORDER_RESET = 'ShipmentState/ACTION_QS_ORDER_RESET'

export const ACTION_QS_SHIPMENT_LOAD = 'ShipmentState/ACTION_QS_SHIPMENT_LOAD'
export const ACTION_QS_SHIPMENT_RESET = 'ShipmentState/ACTION_QS_SHIPMENT_RESET'

export const ACTION_QS_TRACKING_NUMBER_LOAD = 'ShipmentState/ACTION_QS_TRACKING_NUMBER_LOAD'
export const ACTION_QS_TRACKING_NUMBER_RESET = 'ShipmentState/ACTION_QS_TRACKING_NUMBER_RESET'
export const ACTION_QS_ORDER_AND_SHIPMENT_LOAD = 'ShipmentState/ACTION_QS_ORDER_AND_SHIPMENT_LOAD'

export const ACTION_QS_CAMERA_STATES_CHANGE = 'ShipmentState/ACTION_QS_CAMERA_STATES_CHANGE'
export const ACTION_QS_CAMERA_STATES_RESET = 'ShipmentState/ACTION_QS_CAMERA_STATES_RESET'

export const ACTION_QS_OPERATION_SHIP = 'ShipmentState/ACTION_QS_OPERATION_SHIP'

// Saga Actions
// export function orderShip() {
//   return {
//     type: actions.OPERATION_SHIP,
//   }
// }

export function fetchShipmentOrder(params: ActionApiParams<{ store_id; order_id }>) {
  return {
    type: actions.SHIPMENT_ORDER_FETCH,
    // payload: { store_id, order_id },
    ...params,
  }
}

export function fetchOrderFromBarcode(params: ActionApiParams<{ store_id; order_id }>) {
  return {
    type: ACTION_QS_BARCODE_ORDER_FETCH,
    // payload: { store_id, order_id },
    ...params,
  }
}

// Action creators
export function initialize() {
  return { type: ACTION_SHIPMENT_INITIALIZE }
}

export function reset() {
  return { type: ACTION_SHIPMENT_RESET }
}

export function shipmentOrderLoad(order) {
  return { type: ACTION_SHIPMENT_ORDER_LOAD, payload: order }
}

export function increaseQty({ pp_id }) {
  return {
    type: ACTION_SHIPMENT_INCREASE_QTY,
    payload: { pp_id },
  }
}

export function decreaseQty({ pp_id }) {
  return {
    type: ACTION_SHIPMENT_DECREASE_QTY,
    payload: { pp_id },
  }
}

export function maxQty() {
  return {
    type: ACTION_SHIPMENT_MAX_QTY,
  }
}

export function clearQty() {
  return {
    type: ACTION_SHIPMENT_CLEAR_QTY,
  }
}

export function shipmentChange(data) {
  return {
    type: ACTION_SHIPMENT_DATA_CHANGE,
    payload: { ...data },
  }
}

export function operateShip(
  params: ActionApiParams<
    | {
        order_id: number
        store_id: number
        products: List<any>
        tracking_number: string
        shipping_type_id: number
        note?: string
      }
    | {
        order_id: number
        store_id: number
        products: List<any>
        tracking_numbers: string[]
        shipping_type_id: number
        note?: string
      }
  >
) {
  return {
    type: actions.SHIPMENT_ORDER_OPERATE_SHIP,
    ...params,
  }
}

export function qsOrderReset() {
  return {
    type: ACTION_QS_ORDER_RESET,
  }
}

export function qsOrderLoad(order) {
  return {
    type: ACTION_QS_ORDER_LOAD,
    payload: order,
  }
}

export function qsShipReset() {
  return {
    type: ACTION_QS_SHIPMENT_RESET,
  }
}

export function qsShipLoad(shipment) {
  return {
    type: ACTION_QS_SHIPMENT_LOAD,
    payload: shipment,
  }
}

export function qsOrderAndShipmentLoad({ order, shipment }) {
  return {
    type: ACTION_QS_ORDER_AND_SHIPMENT_LOAD,
    payload: { order, shipment },
  }
}

export function qsTrackingNumberLoad({ tracking_number }) {
  return {
    type: ACTION_QS_TRACKING_NUMBER_LOAD,
    payload: tracking_number,
  }
}

export function qsTrackingNumberReset() {
  return {
    type: ACTION_QS_TRACKING_NUMBER_RESET,
  }
}

export function qsCameraStateChange(willMergeObj: { order?: number; ship?: number }) {
  // state will merge
  return {
    type: ACTION_QS_CAMERA_STATES_CHANGE,
    payload: willMergeObj,
  }
}

export function qsCameraStateReset() {
  return {
    type: ACTION_QS_CAMERA_STATES_RESET,
  }
}

export function qsReset() {
  return {
    type: ACTION_QS_RESET,
  }
}

export function qsShip(body, successCallback, failCallback) {
  return {
    type: ACTION_QS_OPERATION_SHIP,
    payload: body,
    successCallback,
    failCallback,
  }
}
// Reducer
export default function ShipmentStateReducer(state = initialState, action: ActionApiParams = {}) {
  const { payload, type } = action
  let foundIndex
  let newQty
  let pQty
  let sQty
  let newState: Map<string, any> = state
  switch (type) {
    case ACTION_SHIPMENT_INITIALIZE:
    case ACTION_SHIPMENT_RESET:
      return initialState
    case ACTION_SHIPMENT_ORDER_LOAD:
      newState = newState.setIn(['selectedOrderShip'], fromJS(payload))
      return newState.setIn(['editingOrderShip'], fromJS(payload))

    case ACTION_SHIPMENT_INCREASE_QTY:
      foundIndex = newState.getIn(['editingOrderShip', 'products']).findIndex((p) => p.get('pp_id') === payload.pp_id)
      pQty = newState.getIn(['editingOrderShip', 'products', foundIndex, 'qty'])
      sQty = newState.getIn(['editingOrderShip', 'products', foundIndex, 'selectedQty'])
      if (sQty >= 0 && sQty < pQty) {
        newState = newState.set('curQty', newState.get('curQty') + 1)
        return newState.setIn(['editingOrderShip', 'products', foundIndex, 'selectedQty'], sQty + 1)
      }
      return state
    case ACTION_SHIPMENT_DECREASE_QTY:
      foundIndex = newState.getIn(['editingOrderShip', 'products']).findIndex((p) => p.get('pp_id') === payload.pp_id)
      pQty = newState.getIn(['editingOrderShip', 'products', foundIndex, 'qty'])
      sQty = newState.getIn(['editingOrderShip', 'products', foundIndex, 'selectedQty'])
      if (sQty > 0 && sQty <= pQty) {
        newState = newState.set('curQty', newState.get('curQty') - 1)
        return newState.setIn(['editingOrderShip', 'products', foundIndex, 'selectedQty'], sQty - 1)
      }
      return state

    case ACTION_SHIPMENT_CLEAR_QTY:
      newState.getIn(['editingOrderShip', 'products']).forEach((product, index) => {
        newState = newState.setIn(['editingOrderShip', 'products', index, 'selectedQty'], 0)
      })
      newState = newState.set('curQty', 0)
      return newState
    case ACTION_SHIPMENT_MAX_QTY:
      sQty = 0
      newState.getIn(['editingOrderShip', 'products']).forEach((product, index) => {
        pQty = newState.getIn(['editingOrderShip', 'products', index, 'qty'])
        sQty += pQty
        newState = newState.setIn(['editingOrderShip', 'products', index, 'selectedQty'], pQty)
      })
      newState = newState.set('curQty', sQty)
      return newState

    case ACTION_SHIPMENT_SET_MAX_QTY:
      return newState.set('maxQty', payload)
    case ACTION_SHIPMENT_SET_CURRENT_QTY:
      return newState.set('curQty', payload)

    case ACTION_SHIPMENT_DATA_CHANGE:
      return newState.mergeDeepIn(['editingOrderShip'], fromJS(payload))

    // Quick Shipping Mode (QS)
    case ACTION_QS_RESET:
      return newState.set('quickship', initQuickShip)

    case ACTION_QS_ORDER_LOAD:
      return newState.setIn(['quickship', 'selectedOrder'], fromJS(payload))
    case ACTION_QS_ORDER_RESET:
      return newState.setIn(['quickship', 'selectedOrder'], Map({}))

    case ACTION_QS_SHIPMENT_LOAD:
      return newState.mergeDeepIn(['quickship', 'shipmentData'], fromJS(payload))
    case ACTION_QS_SHIPMENT_RESET:
      return newState.setIn(['quickship', 'shipmentData'], initialShipment)

    case ACTION_QS_ORDER_AND_SHIPMENT_LOAD:
      newState = newState.setIn(['quickship', 'selectedOrder'], fromJS(payload.order))
      newState = newState.mergeDeepIn(['quickship', 'shipmentData'], fromJS(payload.shipment))
      return newState

    case ACTION_QS_TRACKING_NUMBER_LOAD:
      return newState.setIn(['quickship', 'shipmentData', 'tracking_number'], payload)
    case ACTION_QS_TRACKING_NUMBER_RESET:
      return newState.setIn(['quickship', 'shipmentData', 'tracking_number'], '')

    case ACTION_QS_CAMERA_STATES_CHANGE:
      return newState.mergeDeepIn(['quickship', 'cameraStates'], fromJS(payload))
    case ACTION_QS_CAMERA_STATES_RESET:
      return newState.setIn(['quickship', 'cameraStates'], initCameraStates)

    default:
      return state
  }
}
