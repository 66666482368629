import React from 'react'

import HStack from 'xui/components/HStack'
import Box from 'xui/components/Box'
import XText from '../XText'
import XIconButton from '../XIconButton'
import OrderListCurrentPageButton from './OrderListCurrentPageButton'

interface IOrderListPaginatorProps {
  disabled?: boolean
  currentPage: number
  maxPage: number
  onChangePage: (submittedPage: number) => void
  onPressPrevious: (newPage: number) => void
  onPressNext: (newPage: number) => void
}

const OrderListPaginator: React.FC<IOrderListPaginatorProps> = ({
  disabled,
  currentPage,
  maxPage,
  onChangePage,
  onPressPrevious,
  onPressNext,
}) => {
  // const [optionOrdersDisplayModeSegmentAtIndex, setOptionOrdersDisplayModeSegmentAtIndex] = React.useState(initialIndex)

  // const _onSegmentChangeOrdersDisplayMode = (opt: IXSegmentOption<IDisplayMode>, idx: number) => {
  //   setOptionOrdersDisplayModeSegmentAtIndex(idx)
  //   onChange(opt, idx)
  // }

  // const isPageMode = this.isOrdersDisplayPageMode()
  // if (!isPageMode) {
  //   return null
  // }

  const _handlePressPrev = () => {
    onPressPrevious(currentPage - 1)
  }
  const _handlePressNext = () => {
    onPressNext(currentPage + 1)
  }
  const _handleSubmitNewPage = async (newPage: number) => {
    onChangePage(newPage)
  }

  const isPrevButtonVisible = currentPage > 1
  const isNextButtonVisible = currentPage < maxPage

  return (
    <HStack space='1'>
      {isPrevButtonVisible ? (
        <XIconButton
          w='8'
          family='MaterialIcons'
          name='navigate-before'
          variant='outline'
          isDisabled={disabled}
          disabled={disabled}
          onPress={_handlePressPrev}
        />
      ) : (
        <Box w='8' h='8' />
      )}

      <HStack alignItems='center'>
        <OrderListCurrentPageButton disabled={disabled} page={currentPage} maxPage={maxPage} onSubmitNewPage={_handleSubmitNewPage} />
        <XText mx='1' variant='inactive'>
          /
        </XText>
        <XText variant='inactive'>{`${maxPage} หน้า`}</XText>
      </HStack>

      {isNextButtonVisible ? (
        <XIconButton
          w='8'
          family='MaterialIcons'
          name='navigate-next'
          variant='outline'
          isDisabled={disabled}
          disabled={disabled}
          onPress={_handlePressNext}
        />
      ) : (
        <Box w='8' h='8' />
      )}
    </HStack>
  )
}

OrderListPaginator.displayName = 'OrderListPaginator'

export default OrderListPaginator
