// import PropTypes from 'prop-types'
import BaseUIProductGroupSettingForUserGroup from 'xui/modules/store/BaseUIProductGroupSettingForUserGroup'

export default class ProductGroupSettingForUserGroup extends BaseUIProductGroupSettingForUserGroup {
  static displayName = 'ProductGroupSettingForUserGroup'

  // static navigationOptions = ({ navigation }) => ({
  //   header: null,
  // })

  // renderQRCodeModal = () => {
  //   return <QRCodeModal ref={(r) => r && (this.qrModalRef = r)} />
  // }
}
