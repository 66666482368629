import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as StoreActions from 'x/modules/store/StoreState'
import XContainer from 'xui/components/XContainer'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContent from 'xui/components/XContent'
import HStack from 'xui/components/HStack'
import Box from 'xui/components/Box'
import NB from 'x/config/nativebase'
import { getSelectedStore, getEditingStore } from 'x/redux/selectors'

import p from 'x/config/platform-specific'
import XCard from 'xui/components/XCard'
import XSettingRow from 'xui/components/XSettingRow'
import { ActionApiParams, ISelectedStoreMap, IXScreenProps } from 'x/types'
import * as util from 'x/utils/util'

interface IStoreSettingOrderNoteContainerProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
  submitEditing: ActionApiParams
  // addNewStoreSetting: (newSetting: any) => void
}

const StoreSettingOrderNoteContainer: React.FC<IStoreSettingOrderNoteContainerProps> = (props) => {
  const { submitEditing, selectedStore } = props
  // const [visibleShippingTypes, setVisibleShippingTypes] = useState<number[]>([])

  // eslint-disable-next-line react/no-unstable-nested-components
  const _renderHeader = () => (
    <XCustomHeader
      title={p.op.t('StoreSetting.MenuOrderNote')}
      headerLeftProps={{ backIcon: true, onPressItem: () => util.navGoBack(props) }}
    />
  )

  const _onSubmit = async (value: boolean, stateKey: string) => {
    const store_id = selectedStore.get('id')
    const response = await new Promise((resolve) => {
      // @ts-ignore
      submitEditing({
        body: { store_id, [stateKey]: value },
        successCallback: resolve,
        failedCallback: () => resolve(null),
      })
    })
    // console.log('response => ', response)
  }

  const isSwitchOnChange = async (newValue: { isValue: boolean; stateKey: string }) => {
    // const isValueToInt = boolToInt(newValue.isValue)
    _onSubmit(newValue.isValue, newValue.stateKey)
  }

  // const boolToInt = (flag) => (flag ? 1 : 0)

  const boxOne = () => {
    const s_passthru_auto_confirmed_suborder_shp_lbl_note: boolean = selectedStore.get('s_passthru_auto_confirmed_suborder_shp_lbl_note')
    let supTitleLable = p.op.t('StoreSetting.NoteFalseOrderNotePassthruAutoConfirmedSuborderShpLblNote')
    if (s_passthru_auto_confirmed_suborder_shp_lbl_note) {
      supTitleLable = p.op.t('StoreSetting.NoteTrueOrderNotePassthruAutoConfirmedSuborderShpLblNote')
    }
    return (
      <HStack py='1.5'>
        <XSettingRow
          title={p.op.t('StoreSetting.MenuOrderNotePassthruAutoConfirmedSuborderShpLblNote')}
          supTitle={supTitleLable}
          value={s_passthru_auto_confirmed_suborder_shp_lbl_note}
          onClick={(newValue: { isValue: boolean; stateKey: string }) => isSwitchOnChange(newValue)}
          stateKey='s_passthru_auto_confirmed_suborder_shp_lbl_note'
        />
      </HStack>
    )
  }

  const boxTwo = () => {
    const s_passthru_auto_confirmed_suborder_shp_lbl_img: boolean = selectedStore.get('s_passthru_auto_confirmed_suborder_shp_lbl_img')
    let supTitleLable = p.op.t('StoreSetting.NoteFalseOrderNotePassthruAutoConfirmedSuborderShpLblImg')
    if (s_passthru_auto_confirmed_suborder_shp_lbl_img) {
      supTitleLable = p.op.t('StoreSetting.NoteTrueOrderNotePassthruAutoConfirmedSuborderShpLblImg')
    }
    return (
      <HStack py='1.5'>
        <XSettingRow
          title={p.op.t('StoreSetting.MenuOrderNotePassthruAutoConfirmedSuborderShpLblImg')}
          supTitle={supTitleLable}
          value={s_passthru_auto_confirmed_suborder_shp_lbl_img}
          onClick={(newValue: { isValue: boolean; stateKey: string }) => isSwitchOnChange(newValue)}
          stateKey='s_passthru_auto_confirmed_suborder_shp_lbl_img'
        />
      </HStack>
    )
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  const _renderBody = () => (
    <XCard mx='2' mt='2' mb='2'>
      {boxOne()}
      <Box _dark={{ borderBottomColor: 'white' }} _light={{ borderBottomColor: 'black' }} borderBottomWidth='1' />
      {boxTwo()}
    </XCard>
  )

  return (
    <XContainer>
      {_renderHeader()}
      <XContent px='2' pb='2'>
        <Box w='full' mt='2' borderRadius='md' _light={{ bg: 'white' }} _dark={{ bg: NB.C.D.BG.CLEAR }}>
          {_renderBody()}
        </Box>
        {/* {isLoading ? VIEW_LOADING(windowWidth, windowHeight) : null} */}
      </XContent>
    </XContainer>
  )
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// // @ts-ignore
// StoreSettingOrderNoteContainer.navigationOptions = {
//   header: null,
// }

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
    editingStore: getEditingStore(state),
  }),
  (dispatch) => ({
    submitEditing: bindActionCreators(StoreActions.submitEditingStore, dispatch),
    resetEditingStore: bindActionCreators(StoreActions.resetEditingStore, dispatch),
    // storeActions: bindActionCreators(StoreState, dispatch),
  })
)(StoreSettingOrderNoteContainer)
