import React from 'react'
import _ from 'lodash'

import {
  IOrderListViewProps,
  IOrderListViewState,
  IOrderListItem,
  IFetchOrdersRequestBody,
  IFetchOrdersParams,
  IFetchOrderResponse,
  OrdersTabKeyType,
  IOrderListViewOrdersStateKey,
  IOrderListViewHasMoreOrdersStateKey,
  IOrdersFilter,
  IApiOptions,
} from 'x/index'

import * as util from 'x/utils/util'
import api from 'x/utils/api'
import p from 'x/config/platform-specific'

const ORDER_CHECKED_PREFIX = 'odc_'
const ORDERS_STATE_PREFIX = 'orders_'
const HAS_NO_MORE_ORDERS_STATE_PREFIX = 'hasNoMoreOrders_'
const SEARCH_TABNAME = 'search'
const DEFAULT_FETCH_INIT_LIMIT = 10
const DEFAULT_FETCH_MORE_LIMIT = 12
const ORDERS_PAGE_LIMIT = 30

// type addPrefix<TKey, TPrefix extends string> = TKey extends string
//   ? `${TPrefix}${TKey}`
//   : never;

// type IOrderListViewOrdersStateKey = addPrefix<OrdersTabKeyType, 'orders_'>
// type IOrderListViewHasMoreOrdersStateKey = addPrefix<OrdersTabKeyType, 'hasNoMoreOrders_'>

// const REFS = {
//   MAINTAB_SCROLLVIEW: 'mainTabScrollView',
//   SUBTAB_SCROLLVIEW_MYTASKS: 'subTabScrollViewMyTasks',
//   SUBTAB_SCROLLVIEW_CUSTTASKS: 'subTabScrollViewCustTasks',
//   SUBTAB_SCROLLVIEW_RESELLERTASKS: 'subTabScrollViewResellerTasks',
//   SUBTAB_SCROLLVIEW_SELLERTASKS: 'subTabScrollViewSellerTasks',
//   SUBTAB_SCROLLVIEW_DONETASKS: 'subTabScrollViewDoneTasks',
// }

// const DONE_TASKS = {
//   subTasks: {
//     shipped: {
//       // count: 13,
//     },
//     autoCompleted: {
//       // count: 1,
//     },
//     cancelled: {
//       // count: 1,
//     },
//   },
// }

// const MAIN_TAB_KEYS = ['myTasks', 'custTasks', 'resellerTasks', 'sellerTasks', 'doneTasks']

// const SUB_TAB_KEYS = {
//   myTasks: ['confirmOrder', 'pay', 'confirmGettingPaid', 'ship'],
//   custTasks: ['pay', 'autoComplete'],
//   resellerTasks: ['confirmOrder', 'pay', 'confirmGettingPaid4Refund'],
//   sellerTasks: ['confirmOrder', 'confirmGettingPaid', 'ship', 'pay4Refund'],
//   doneTasks: ['shipped', 'autoCompleted', 'cancelled'],
// }

// export interface IOrderListViewNavParams {
//   store_id: number

//   paymentOrder?: {
//     isCOD?: boolean
//     orderStoreId: number // PaymentOrders
//     orderType: number
//     partnerStoreId: number
//     isRefund?: boolean
//   }

//   // for Customization only
//   title?: string
//   availableTabKeys?: OrdersTabKeyType[]

//   onPressOrderListItem?: (order: IOrderListItem) => void|Promise<void>
//   onPressSubmitCheckedOrders?: (order_ids: number[]) => void|Promise<void>
//   multiOpMode?: MultiOpMode // เปิดใช้งาน Left column checker
// }

// export interface IOrderListViewProps {

//   // navigate: Function, // Do we need this?
//   orderListStateActions: IOrderListStateActionsProps
//   orders: Map<string, List<IMap<IOrderListItem>>>
//   queriedOrders?: Map<string, List<Map<string, any>>> // only for store orders
//   ordersToBePaid?: Map<string, string | number>
//   tabStatus: Map<string, string>
//   summary: IMap<{ [key: string]: number }>
//   shouldFetchAll?: boolean // Additional Flag
//   orderStoreId?: number
//   partnerStoreId?: number
//   subscriptionType: number
//   // searchOrders?: Map<any> // FIXME: result Map of something???
//   searchOrders?: any // result Map of something???
//   searchResultOrders?: List<any> // result order
//   filterSetting?: IMap<IOrdersFilterSetting>

//   fetchOrdersForSearch: (params: { mode: string; payload: any }) => void
//   fetchOrderDetail: (params: ActionApiParams) => void
//   // app
//   // navigation?: NavigationScreenProp<NavigationState, IBaseOrderListViewNavParams>
//   navigation?: NavigationStackProp<NavigationRoute, IOrderListViewNavParams>
//   selectedStore: ISelectedStoreMap
//   sellerStores: List<Map<string, any>>
// }

// export interface IOrderListViewState {
//   ordersFilterSetting?: Partial<IFetchOrdersRequestBody>
//   sortOptions?: IOrdersSortOptions

//   tabName?: OrdersTabKeyType
//   isOrdersRefreshing: boolean
//   isOrdersLoadingMore: boolean
//   // isNoMoreFetchData: boolean

//   paymentOrder?: {
//     isCOD?: boolean
//     orderStoreId: number
//     orderType: number
//     partnerStoreId: number
//     isRefund?: boolean
//   }

//   availableTabKeys: OrdersTabKeyType[]
//   ordersCountSummary: { [tabKey: string]: number }
//   [ordersTabKey: IOrderListViewOrdersStateKey]: IOrderListItem[]
//   [hasMoreOrdersTabKey: IOrderListViewHasMoreOrdersStateKey]: boolean
//   // filterSetting: IOrdersFilterSetting

//   isCheckable?: boolean
//   ordersCheckedMap: {
//     [orderId: number]: boolean
//   }

//   isInitialized: boolean
//   isGoBackDisabled?: boolean

//   displayMode: IOrderListDisplayMode
//   displayPageLimit: number
//   ordersCurrentPage: number,

//   isSubmitting?: boolean
// }

class BaseOrderListView<P extends IOrderListViewProps, S extends IOrderListViewState> extends React.Component<P, S> {
  static displayName = 'BaseOrderListView'

  isRefreshing: boolean

  isLoadingMore: boolean

  isNavigating: boolean

  isSubmitting: boolean

  onPressOrderListItem?: (order: IOrderListItem) => void | Promise<void>

  // onPressSubmitCheckedOrders?: (order_ids: number[]) => void | Promise<void>
  onPressSubmitCheckedOrders?: (checkedOrders: IOrderListItem[]) => void | Promise<void>

  constructor(props) {
    super(props)

    this.state = this.getBaseInitialState() as S

    this.isRefreshing = false
    this.isLoadingMore = false
    this.isNavigating = false
    this.isSubmitting = false
    // this.onPressOrderListItem = null
    // this.onPressSubmitCheckedOrders = null
  }

  async componentDidMount() {
    await this._setupInitialState()
    await util.delay(100)
    await util.setStatePromise(this, { isInitialized: true })
  }

  getBaseInitialState = (): IOrderListViewState => {
    const { selectedStoreOrdersDates, selectedStoreOrdersDatesOptionKey } = this.props

    const ordersDefaultFilterSetting: IOrdersFilter = {
      createOrderDateRangeOptionKey: selectedStoreOrdersDatesOptionKey,
      createOrderFrom: selectedStoreOrdersDates.begin,
      createOrderTo: selectedStoreOrdersDates.end,
    }

    const initialState: IOrderListViewState = {
      tabName: SEARCH_TABNAME,
      isOrdersRefreshing: false,
      isOrdersLoadingMore: false,
      // isNoMoreFetchData: false,
      // filterSetting: {},
      ordersCountSummary: {},
      // ordersDefaultFilterSetting: {},
      // ordersFilterSetting: {},
      ordersDefaultFilterSetting,
      ordersFilterSetting: ordersDefaultFilterSetting,
      // availableTabKeys: CONS.ORDER_SUMMARY_NO_SELLER,
      availableTabKeys: [],
      sortOptions: undefined,

      isInitialized: false,
      isGoBackDisabled: false,
      paymentOrder: null,

      displayMode: 'list',
      displayPageLimit: ORDERS_PAGE_LIMIT,
      ordersCurrentPage: 1,

      // isCheckable: false,
      isCheckable: false,
      ordersCheckedMap: {},

      isSubmitting: false,
    }
    return initialState
  }

  _setupInitialState = async () => {
    const { navigation } = this.props
    const newState: Partial<S> = this.getOverridePreSetupInitialState()

    // @ts-ignore
    const savedDisplayPageLimit: string = await p.op.storageGet('ordersDisplayPageLimit', false)
    if (newState.displayMode === 'page' && savedDisplayPageLimit && _.includes([10, 30, 50], parseInt(savedDisplayPageLimit))) {
      newState.displayPageLimit = parseInt(savedDisplayPageLimit)
    }

    const availableTabKeys = util.getNavParam(this.props, 'availableTabKeys')
    if (availableTabKeys) {
      newState.availableTabKeys = [...availableTabKeys]
      if (availableTabKeys.length === 1) {
        newState.tabName = availableTabKeys[0]
      }
    }

    const paymentOrder = util.getNavParam(this.props, 'paymentOrder')
    if (paymentOrder) {
      newState.paymentOrder = { ...paymentOrder }
    }

    const onPressOrderListItem = util.getNavParam(this.props, 'onPressOrderListItem')
    if (typeof onPressOrderListItem === 'function') {
      this.onPressOrderListItem = onPressOrderListItem.bind(this)
    }

    const onPressSubmitCheckedOrders = util.getNavParam(this.props, 'onPressSubmitCheckedOrders')
    if (typeof onPressSubmitCheckedOrders === 'function') {
      newState.isCheckable = true
      this.onPressSubmitCheckedOrders = onPressSubmitCheckedOrders.bind(this)
    }

    if (!newState.tabName && _.isArray(newState.availableTabKeys) && newState.availableTabKeys.length > 0) {
      newState.tabName = newState.availableTabKeys[0]
    }

    const ordersDefaultFilterSetting = util.getNavParam(this.props, 'ordersDefaultFilterSetting')
    if (ordersDefaultFilterSetting && !_.isEmpty(ordersDefaultFilterSetting)) {
      newState.ordersDefaultFilterSetting = _.cloneDeep(ordersDefaultFilterSetting)
    }

    const ordersFilterSetting = util.getNavParam(this.props, 'ordersFilterSetting')
    if (ordersFilterSetting && !_.isEmpty(ordersFilterSetting)) {
      newState.ordersFilterSetting = _.cloneDeep(ordersFilterSetting)
    }

    if ((!newState.ordersFilterSetting || _.isEmpty(newState.ordersFilterSetting)) && !_.isEmpty(newState.ordersDefaultFilterSetting)) {
      newState.ordersFilterSetting = _.cloneDeep(newState.ordersDefaultFilterSetting)
    }

    await util.setStatePromise(this, { ...newState, ...this.getOverridePostSetupInitialState() })
  }

  _onPressOrderListItem = async (od: IOrderListItem) => {
    if (this.isNavigating) {
      return
    }
    this.isNavigating = true

    if (typeof this.onPressOrderListItem === 'function') {
      await this.onPressOrderListItem(od)
    }

    this.isNavigating = false
  }

  _onPressSubmitCheckedOrders = async () => {
    if (this.isSubmitting) {
      return
    }
    this.isSubmitting = true
    await util.setStatePromise(this, { isSubmitting: true })

    // await this.onPressSubmitCheckedOrders(this.getCheckedOrderIds())
    await this.onPressSubmitCheckedOrders(this.getCheckedOrders())

    await util.setStatePromise(this, { isSubmitting: false })
    this.isSubmitting = false
  }

  isSubmitCheckOrdersButtonDisabled = (): boolean => {
    const { isOrdersRefreshing = false } = this.state
    return isOrdersRefreshing
  }

  getOverridePreSetupInitialState = (): Partial<S> => ({})

  getOverridePostSetupInitialState = (): Partial<S> => ({})

  // isOrderListFiltered = () => !_.isEmpty(this.props.filterSetting)
  getStoreId = () => {
    const { navigation, selectedStore } = this.props
    return util.getNavParam(this.props, 'store_id', selectedStore.get('id'))
  }

  getHeaderTitle = () => {
    const { navigation } = this.props
    const customTitle = util.getNavParam(this.props, 'title', null)
    if (customTitle) {
      return customTitle
    }
    return this.getTabLabelWithCount(this.state.tabName)
  }

  getSubmitCheckOrdersButtonText = () => {
    const { navigation } = this.props
    const submitCheckOrdersButtonText = util.getNavParam(this.props, 'submitCheckOrdersButtonText', 'ยืนยันออเดอร์')
    return submitCheckOrdersButtonText
  }

  getOrdersStateKey = (tabName: OrdersTabKeyType): IOrderListViewOrdersStateKey => `${ORDERS_STATE_PREFIX}${tabName}`

  getOrders = (tabName: OrdersTabKeyType): IOrderListItem[] => {
    const ordersStateKey = this.getOrdersStateKey(tabName)
    return this.state[ordersStateKey] || []
  }

  getHasNoMoreOrdersStateKey = (tabName: OrdersTabKeyType): IOrderListViewHasMoreOrdersStateKey =>
    `${HAS_NO_MORE_ORDERS_STATE_PREFIX}${tabName}`

  getHasNoMoreOrders = (tabName: OrdersTabKeyType): boolean => this.state[this.getHasNoMoreOrdersStateKey(tabName)]

  getHasMoreOrders = (tabName: OrdersTabKeyType): boolean => !this.getHasNoMoreOrders(tabName)

  getTabLabel = (tabName: OrdersTabKeyType) => p.op.t(`Order.orderStates.${tabName}`)

  getTabLabelWithCount = (tabName: OrdersTabKeyType) => {
    const ordersCount = this.getSummaryOrdersCount(tabName)
    return ordersCount ? `${this.getTabLabel(tabName)} (${ordersCount})` : this.getTabLabel(tabName)
  }

  isOrdersDisplayPageMode = () => this.state.displayMode === 'page'

  getOrdersMaxPage = (tabName: OrdersTabKeyType) => {
    const { displayPageLimit } = this.state
    const totalOrderCount = this.getSummaryOrdersCount(tabName)
    const maxPageNumber = Math.ceil(totalOrderCount / displayPageLimit) || 1
    return maxPageNumber
  }

  setOrderListItemCheckMap = (orderId: number, newValue?: IOrderListItem) => {
    // const { ordersCheckedMap = {} } = this.state
    // const ocm = { ...ordersCheckedMap }
    // if (newValue) {
    //   ocm[orderId] = newValue
    // } else {
    //   delete ocm[orderId]
    // }
    // this.setState({ ordersCheckedMap: ocm })

    const newState = {}
    if (newValue) {
      newState[`${ORDER_CHECKED_PREFIX}${orderId}`] = newValue
    } else {
      newState[`${ORDER_CHECKED_PREFIX}${orderId}`] = null
    }
    this.setState(newState)
  }

  // checkOrderListItem = (orderId: number) => {
  //   this.setOrderListItemCheckMap(orderId, true)
  // }

  // uncheckOrderListItem = (orderId: number) => {
  //   this.setOrderListItemCheckMap(orderId, false)
  // }

  clearOrdersAllTabs = async () => {
    const { tabName, availableTabKeys = [] } = this.state
    const newState: Partial<IOrderListViewState> = {}

    newState.ordersCountSummary = {}

    if (tabName === SEARCH_TABNAME) {
      const hasNoMoreOrdersStateKey = this.getHasNoMoreOrdersStateKey(SEARCH_TABNAME)
      newState[SEARCH_TABNAME] = []
      newState[hasNoMoreOrdersStateKey] = false
    } else {
      for (let i = 0; i < availableTabKeys.length; i++) {
        const fTabName = availableTabKeys[i]
        const ordersStateKey = this.getOrdersStateKey(fTabName)
        newState[ordersStateKey] = []

        const hasNoMoreOrdersStateKey = this.getHasNoMoreOrdersStateKey(fTabName)
        newState[hasNoMoreOrdersStateKey] = false
      }
    }

    await util.setStatePromise(this, newState)
  }

  toggleCheckOrderListItem = (orderId: number) => {
    const isChecked = this.isOrderListItemChecked(orderId)
    if (isChecked) {
      this.setOrderListItemCheckMap(orderId, undefined)
    } else {
      const { tabName, ordersCheckedMap } = this.state
      const orders = this.getOrders(tabName)
      // const order = orders.find((od) => od.id === orderId)
      // const order = ordersCheckedMap[orderId] || orders.find((od) => od.id === orderId)
      const order = ordersCheckedMap[orderId] || this.getOrders(tabName).find((od) => od.id === orderId)
      this.setOrderListItemCheckMap(orderId, order)
    }
  }

  isOrderListItemChecked = (orderId: number) =>
    // const { ordersCheckedMap = {} } = this.state
    // return _.isObject(ordersCheckedMap[orderId]) && !_.isEmpty(ordersCheckedMap[orderId])
    _.isObject(this.state[`${ORDER_CHECKED_PREFIX}${orderId}`]) && !_.isEmpty(`${ORDER_CHECKED_PREFIX}${orderId}`)

  getCheckedOrdersInTabCount = (tabName: OrdersTabKeyType) => {
    const tabOrders = this.getOrders(tabName)
    const tabOrderIds = tabOrders.map((od) => od.id)
    let checkedCount = 0
    for (let i = 0; i < tabOrderIds.length; i++) {
      const orderId = tabOrderIds[i]
      if (this.isOrderListItemChecked(orderId)) {
        checkedCount += 1
      }
    }
    return checkedCount
  }

  getCheckedOrdersAllTabCount = () => {
    // const { ordersCheckedMap = {} } = this.state
    // const checkOrderIds = Object.keys(ordersCheckedMap)
    // let checkedCount = 0
    // for (let i = 0; i < checkOrderIds.length; i++) {
    //   const orderId = parseInt(checkOrderIds[i], 10)
    //   if (this.isOrderListItemChecked(orderId)) {
    //     checkedCount += 1
    //   }
    // }
    // return checkedCount

    const stateKeys = Object.keys(this.state)
    const checkOrderIds = stateKeys.filter((sk) => sk.startsWith(ORDER_CHECKED_PREFIX)).map((sk) => sk.replace(ORDER_CHECKED_PREFIX, ''))
    let checkedCount = 0
    for (let i = 0; i < checkOrderIds.length; i++) {
      const orderId = parseInt(checkOrderIds[i])
      if (this.isOrderListItemChecked(orderId)) {
        checkedCount += 1
      }
    }
    return checkedCount
  }

  getCheckedOrderIds = (): number[] => {
    // const { ordersCheckedMap = {} } = this.state
    // const stringOrderIds = Object.keys(ordersCheckedMap).filter(
    //   (orderId) => _.isObject(ordersCheckedMap[orderId]) && !_.isEmpty(ordersCheckedMap[orderId])
    // )
    // return stringOrderIds.map((stringOrderId) => parseInt(stringOrderId))
    const stateKeys = Object.keys(this.state)
    const checkOrderIds = stateKeys.filter((sk) => sk.startsWith(ORDER_CHECKED_PREFIX)).map((sk) => sk.replace(ORDER_CHECKED_PREFIX, ''))
    return checkOrderIds.map((odIdKey) => parseInt(odIdKey)).filter(this.isOrderListItemChecked)
  }

  getCheckedOrderCount = (): number => {
    const checkedOrderIds = this.getCheckedOrderIds()
    return checkedOrderIds.length || 0
  }

  getCheckedOrders = (): IOrderListItem[] => {
    // const { ordersCheckedMap = {} } = this.state
    // const checkedOrderIds = this.getCheckedOrderIds()
    // const checkedOrders = checkedOrderIds.map((orderId) => ordersCheckedMap[orderId])
    // return checkedOrders
    const { ordersCheckedMap = {} } = this.state
    const checkedOrderIds = this.getCheckedOrderIds()
    const checkedOrders = checkedOrderIds.map((orderId) => this.state[`${ORDER_CHECKED_PREFIX}${orderId}`])
    return checkedOrders
  }

  // isOrderListItemsAllChecked = (tabName: OrdersTabKeyType) => {
  //   const { ordersCheckedMap = {} } = this.state
  //   const orders = this.getOrders(tabName)
  //   const tabOrderIds =  orders.map(od => od.id)
  //   let isAllChecked = true
  //   for (let i = 0; i < tabOrderIds.length; i++) {
  //     const orderId = tabOrderIds[i]
  //     if (!this.isOrderListItemChecked(orderId)) {
  //       isAllChecked = false
  //       break
  //     }
  //   }
  //   return  isAllChecked
  // }

  toggleCheckAllOrderListItems = () => {
    const { ordersCheckedMap = {}, tabName } = this.state

    const isAllChecked = this.isOrderListItemsAllChecked(tabName)
    const orders = this.getOrders(tabName)
    const tabOrderIds = orders.map((od) => od.id)

    const newState = {}
    for (let i = 0; i < tabOrderIds.length; i++) {
      const orderId = tabOrderIds[i]
      // ocm[orderId] = !isAllChecked
      // newState[`${ORDER_CHECKED_PREFIX}${orderId}`] = isAllChecked ? undefined : orders.find((od) => od.id === orderId)
      newState[`${ORDER_CHECKED_PREFIX}${orderId}`] = isAllChecked
        ? undefined
        : ordersCheckedMap[orderId] || orders.find((od) => od.id === orderId)
    }

    // const ocm = { ...ordersCheckedMap }
    // for (let i = 0; i < tabOrderIds.length; i++) {
    //   const orderId = tabOrderIds[i]
    //   // ocm[orderId] = !isAllChecked
    //   ocm[orderId] = isAllChecked ? undefined : orders.find((od) => od.id === orderId)
    // }

    // this.setState({ ordersCheckedMap: ocm })
    this.setState(newState)
  }

  uncheckCheckAllOrderListItems = () => {
    // this.setState({ ordersCheckedMap: {} })
    // console.log('begin uncheckCheckAllOrderListItems')
    const newState = {}
    const orderCheckKeys = Object.keys(this.state).filter((sKey) => sKey.startsWith(ORDER_CHECKED_PREFIX))
    // console.log('process1 uncheckCheckAllOrderListItems orderCheckKeys => ', orderCheckKeys)
    for (let i = 0; i < orderCheckKeys.length; i++) {
      const orderCheckKey = orderCheckKeys[i]
      // console.log('process2 uncheckCheckAllOrderListItems orderCheckKey => ', orderCheckKey)
      if (_.isObject(this.state[orderCheckKey]) && !_.isEmpty(this.state[orderCheckKey])) {
        newState[orderCheckKey] = undefined
      }
    }
    // console.log('process3 uncheckCheckAllOrderListItems newState => ', newState)
    if (!_.isEmpty(newState)) {
      this.setState(newState)
      // console.log('end uncheckCheckAllOrderListItems newState => ', newState)
    }
  }

  isOrderListItemsAllChecked = (tabName: OrdersTabKeyType) => {
    const orders = this.getOrders(tabName)
    const tabOrderIds = orders.map((od) => od.id)
    let isAllChecked = true
    for (let i = 0; i < tabOrderIds.length; i++) {
      const orderId = tabOrderIds[i]
      if (!this.isOrderListItemChecked(orderId)) {
        isAllChecked = false
        break
      }
    }
    return isAllChecked
  }

  // tab ที่ไม่ return count กลับมา
  isNoSummaryCountTabKey = (tabKey: OrdersTabKeyType) =>
    typeof tabKey === 'string' ? _.includes(['custTasks_autoComplete'], tabKey) || tabKey.startsWith('doneTasks') : false

  loadInitOrders = async (tabName: OrdersTabKeyType, extraParams: Partial<IFetchOrdersParams> = {}) => {
    // const { filterSetting } = this.props
    // const { isOrdersLoadingMore, isOrdersRefreshing, tabName } = this.state
    if (this.isRefreshing || this.isLoadingMore) {
      return
    }
    this.isRefreshing = true
    await util.setStatePromise(this, { isOrdersRefreshing: true })
    // await util.delay(200)

    if (this.isOrdersDisplayPageMode()) {
      const { ordersCurrentPage, displayPageLimit } = this.state
      const computedExtraParams = { ...extraParams }
      const computedOffset = (ordersCurrentPage - 1) * displayPageLimit
      if (!computedExtraParams.overrideRequestBody) {
        computedExtraParams.overrideRequestBody = {}
      }
      computedExtraParams.overrideRequestBody.offset = computedOffset
      computedExtraParams.overrideRequestBody.limit = displayPageLimit
      this._loadInitOrdersCount(tabName, computedExtraParams)
      await this._loadInitOrders(tabName, computedExtraParams)
    } else {
      this._loadInitOrdersCount(tabName, extraParams)
      await this._loadInitOrders(tabName, extraParams)
    }

    // await util.delay(200)
    await util.setStatePromise(this, { isOrdersRefreshing: false })
    this.isRefreshing = false
  }

  loadMoreOrders = async (tabName: OrdersTabKeyType, extraParams: Partial<IFetchOrdersParams> = {}) => {
    // const { isOrdersLoadingMore, tabName } = this.state
    if (this.isRefreshing || this.isLoadingMore) {
      return
    }
    this.isLoadingMore = true
    await util.setStatePromise(this, { isOrdersLoadingMore: true })
    // await util.delay(200)
    await this._loadMoreOrders(tabName, extraParams)
    // await util.delay(200)
    await util.setStatePromise(this, { isOrdersLoadingMore: false })
    this.isLoadingMore = false
  }

  _loadInitOrdersCount = async (tabName: OrdersTabKeyType, extraParams: Partial<IFetchOrdersParams> = {}) => {
    if (!tabName) {
      return
    }

    const { ordersFilterSetting = {}, sortOptions, paymentOrder, ordersCountSummary } = this.state
    if (!_.isNil(ordersCountSummary[tabName])) {
      return
    }

    // const { ordersFilterSetting = {}, sortOptions, paymentOrder, ordersCountSummary = {}, availableTabKeys = [] } = this.state
    // const isFirstLoading = _.isEmpty(ordersCountSummary)
    const storeId = this.getStoreId()
    const fetchParams: IFetchOrdersParams = {
      storeId,
      mode: 'FETCH_COUNT',
      tabName,
      filterSetting: ordersFilterSetting,
      sortOptions,
      paymentOrder,
      ...extraParams,
    }
    const req = this.getFetchOrdersRequestBody(fetchParams)
    const newReq = _.cloneDeep(req)
    if (req.erp_doc_code || req.tracking_no) {
      delete newReq.created_at_from
      delete newReq.created_at_to
    }

    const res = await this._fetchOrders(newReq, tabName === SEARCH_TABNAME)
    // console.log('_loadInitOrders response => ', res)

    const newState: Partial<IOrderListViewState> = {}
    newState.ordersCheckedMap = _.cloneDeep(this.state.ordersCheckedMap)

    if (tabName === SEARCH_TABNAME) {
      const { count: newOrderCount, orders: newOrders } = res
      const ordersStateKey = this.getOrdersStateKey(tabName)
      newState[ordersStateKey] = newOrders as IOrderListItem[]
      const hasNoMoreOrdersStateKey = this.getHasNoMoreOrdersStateKey(tabName)
      newState[hasNoMoreOrdersStateKey] = newOrders.length === 0 || newOrders.length < req.limit
      if (newOrderCount) {
        if (!_.isObject(newState.ordersCountSummary)) {
          newState.ordersCountSummary = {}
        }
        newState.ordersCountSummary[SEARCH_TABNAME] = newOrderCount
      }

      // @ts-ignore
      newOrders.forEach((od) => {
        newState.ordersCheckedMap[od.id] = od
      })
    } else {
      // const newOrders: IOrderListItem[] = newOrdersMap[tabName] || []
      // const ordersStateKey = this.getOrdersStateKey(tabName)
      // newState[ordersStateKey] = newOrders

      const { summary: countSummary = {}, orders: newOrdersMap = {} } = res
      const resOrderKeys = Object.keys(newOrdersMap) || []
      for (let i = 0; i < resOrderKeys.length; i++) {
        const otKey = resOrderKeys[i]
        const newOrders: IOrderListItem[] = newOrdersMap[otKey] || []
        const ordersStateKey = this.getOrdersStateKey(otKey)
        const hasNoMoreOrdersStateKey = this.getHasNoMoreOrdersStateKey(otKey)

        newState[hasNoMoreOrdersStateKey] = newOrders.length === 0 || newOrders.length < req.limit
        newState[ordersStateKey] = newOrders

        newOrders.forEach((od) => {
          newState.ordersCheckedMap[od.id] = od
        })
      }

      // ถ้ามี summary count ส่งมา
      newState.ordersCountSummary = {}
      if (countSummary && !_.isEmpty(countSummary)) {
        newState.ordersCountSummary = countSummary
      }
    }

    await util.setStatePromise(this, newState)
  }

  _loadInitOrders = async (tabName: OrdersTabKeyType, extraParams: Partial<IFetchOrdersParams> = {}) => {
    if (!tabName) {
      return
    }
    const { ordersFilterSetting = {}, sortOptions, paymentOrder } = this.state
    // const { ordersFilterSetting = {}, sortOptions, paymentOrder, ordersCountSummary = {}, availableTabKeys = [] } = this.state
    // const isFirstLoading = _.isEmpty(ordersCountSummary)
    const storeId = this.getStoreId()
    const fetchParams: IFetchOrdersParams = {
      storeId,
      mode: 'FETCH_INIT',
      tabName,
      filterSetting: ordersFilterSetting,
      sortOptions,
      paymentOrder,
      ...extraParams,
    }
    const req = this.getFetchOrdersRequestBody(fetchParams)
    const newReq = _.cloneDeep(req)
    if (req.erp_doc_code || req.tracking_no) {
      delete newReq.created_at_from
      delete newReq.created_at_to
    }

    const res = await this._fetchOrders(newReq, tabName === SEARCH_TABNAME)
    // console.log('_loadInitOrders response => ', res)

    const newState: Partial<IOrderListViewState> = {}
    newState.ordersCheckedMap = _.cloneDeep(this.state.ordersCheckedMap)

    if (tabName === SEARCH_TABNAME) {
      const { count: newOrderCount, orders: newOrders } = res
      const ordersStateKey = this.getOrdersStateKey(tabName)
      newState[ordersStateKey] = newOrders as IOrderListItem[]
      const hasNoMoreOrdersStateKey = this.getHasNoMoreOrdersStateKey(tabName)
      newState[hasNoMoreOrdersStateKey] = newOrders.length === 0 || newOrders.length < req.limit
      if (newOrderCount) {
        if (!_.isObject(newState.ordersCountSummary)) {
          newState.ordersCountSummary = {}
        }
        newState.ordersCountSummary[SEARCH_TABNAME] = newOrderCount
      }

      // @ts-ignore
      newOrders.forEach((od) => {
        newState.ordersCheckedMap[od.id] = od
      })
    } else {
      // const newOrders: IOrderListItem[] = newOrdersMap[tabName] || []
      // const ordersStateKey = this.getOrdersStateKey(tabName)
      // newState[ordersStateKey] = newOrders

      const { summary: countSummary = {}, orders: newOrdersMap = {} } = res
      const resOrderKeys = Object.keys(newOrdersMap) || []
      for (let i = 0; i < resOrderKeys.length; i++) {
        const otKey = resOrderKeys[i]
        const newOrders: IOrderListItem[] = newOrdersMap[otKey] || []
        const ordersStateKey = this.getOrdersStateKey(otKey)
        const hasNoMoreOrdersStateKey = this.getHasNoMoreOrdersStateKey(otKey)

        newState[hasNoMoreOrdersStateKey] = newOrders.length === 0 || newOrders.length < req.limit
        newState[ordersStateKey] = newOrders

        newOrders.forEach((od) => {
          newState.ordersCheckedMap[od.id] = od
        })
      }

      // ถ้ามี summary count ส่งมา
      // newState.ordersCountSummary = {}
      if (countSummary && !_.isEmpty(countSummary)) {
        newState.ordersCountSummary = countSummary
      }

      // // auto select next tab if empty orders
      // if (isFirstLoading && !_.isEmpty(newState.ordersCountSummary)) {
      //   const isCurrentTabEmpty = !ordersCountSummary[tabName]
      //   if (isCurrentTabEmpty) {
      //     for (let i = 0; i < availableTabKeys.length; i++) {
      //       const nextTabName = availableTabKeys[i]
      //       if (newState.ordersCountSummary[nextTabName]) {
      //         newState.tabName = nextTabName
      //         break
      //       }
      //     }
      //   }
      // }
    }

    // if (newOrdersMap[tabName].length === 0) {
    //   newState.isNoMoreFetchData = true
    // }

    await util.setStatePromise(this, newState)

    // orderListStateActions.setIsInitedByTabKey(this.tabName, true)

    // if (this.isCheckerAvailable()) {
    //   this.uncheckAllOrders()
    // }
  }

  _loadMoreOrders = async (tabName: OrdersTabKeyType, extraParams: Partial<IFetchOrdersParams> = {}) => {
    if (!tabName) {
      return
    }
    const { ordersFilterSetting, sortOptions, paymentOrder } = this.state
    // console.log('_loadMoreOrders selectedStore => ', selectedStore)
    const storeId = this.getStoreId()
    // console.log('_loadMoreOrders storeId => ', storeId)

    const req = this.getFetchOrdersRequestBody({
      storeId,
      mode: 'FETCH_MORE',
      tabName,
      filterSetting: ordersFilterSetting,
      sortOptions,
      paymentOrder,
      ...extraParams,
    })
    const res = await this._fetchOrders(req, tabName === SEARCH_TABNAME)

    // console.log('_loadMoreOrders response => ', res)

    const newState: Partial<IOrderListViewState> = {}
    const orders = this.getOrders(tabName)

    newState.ordersCheckedMap = _.cloneDeep(this.state.ordersCheckedMap)
    orders.forEach((od) => {
      newState.ordersCheckedMap[od.id] = od
    })

    const ordersStateKey = this.getOrdersStateKey(tabName)
    if (tabName === SEARCH_TABNAME) {
      const { count: newOrderCount, orders: newOrders } = res
      newState[ordersStateKey] = orders.concat(newOrders as IOrderListItem[])
      const hasNoMoreOrdersStateKey = this.getHasNoMoreOrdersStateKey(tabName)
      newState[hasNoMoreOrdersStateKey] = newOrders.length === 0 || newOrders.length < req.limit
      if (newOrderCount) {
        if (!_.isObject(newState.ordersCountSummary)) {
          newState.ordersCountSummary = {}
        }
        newState.ordersCountSummary[SEARCH_TABNAME] = newOrderCount
      }
    } else {
      const { summary: countSummary = {}, orders: newOrdersMap = {} } = res
      const newOrders: IOrderListItem[] = newOrdersMap[tabName] || []

      newState[ordersStateKey] = orders.concat(newOrders)

      const hasNoMoreOrdersStateKey = this.getHasNoMoreOrdersStateKey(tabName)
      newState[hasNoMoreOrdersStateKey] = newOrders.length === 0 || newOrders.length < req.limit

      // ถ้ามี summary count ส่งมา
      if (countSummary && !_.isEmpty(countSummary)) {
        newState.ordersCountSummary = countSummary
      }
    }

    // if (newOrders.length === 0) {
    //   newState.isNoMoreFetchData = true
    // }

    await util.setStatePromise(this, newState)

    // orderListStateActions.setIsInitedByTabKey(this.tabName, true)

    // if (this.isCheckerAvailable()) {
    //   this.uncheckAllOrders()
    // }
  }

  // getOverrideRequestBody = (): Partial<IFetchOrdersRequestBody> => {
  //   const { navigation } = this.props
  //   const overrideRequestBody: Partial<IFetchOrdersRequestBody> = util.getNavParam(this.props, 'overrideRequestBody', {})
  //   return overrideRequestBody
  // }

  getOverrideRequestBody = (): Partial<IFetchOrdersRequestBody> => {
    const { ordersFilterSetting: ofs } = this.state
    const overrideRequestBody: Partial<IFetchOrdersRequestBody> = util.getNavParam(this.props, 'overrideRequestBody', {})

    // let payment_states = []
    // if (_.isBoolean(ofs.hasPaymentCompletedAt) && ofs.hasPaymentCompletedAt === true) {
    //   // ชําระเงินแล้ว
    //   payment_states = [119]
    // }

    // if (_.isBoolean(ofs.hasPaymentCompletedAt) && ofs.hasPaymentCompletedAt === false) {
    //   // ยังไม่ชําระเงิน
    //   payment_states = [111, 112, 113, 114, 115]
    // }

    // let shipping_states = []
    // if (_.isBoolean(ofs.hasShippingCompletedAt) && ofs.hasShippingCompletedAt === true) {
    //   // จัดส่งแล้ว
    //   shipping_states = [129]
    // }

    // if (_.isBoolean(ofs.hasShippingCompletedAt) && ofs.hasShippingCompletedAt === false) {
    //   // ยังไม่จัดส่ง
    //   shipping_states = [121, 122]
    // }

    // if (payment_states.length > 0) {
    //   overrideRequestBody.payment_states = payment_states
    // }

    // if (shipping_states.length > 0) {
    //   overrideRequestBody.shipping_states = shipping_states
    // }

    return overrideRequestBody
  }

  getFetchOrdersRequestBody = (params: IFetchOrdersParams): IFetchOrdersRequestBody => {
    const {
      storeId,
      mode,
      tabName,
      tabNames,
      paymentOrder,

      filterSetting = {},
      sortOptions = {
        sortBy: 'id',
        sortType: 'desc',
      },
      overrideRequestBody = {},
    } = params

    const body: IFetchOrdersRequestBody = {
      store_id: storeId,
    }
    if (mode === 'FETCH_INIT') {
      body.limit = DEFAULT_FETCH_INIT_LIMIT
    }
    if (mode === 'FETCH_MORE') {
      body.limit = DEFAULT_FETCH_MORE_LIMIT
    }

    // console.log('baseFetchOrders.fetchOrders params => ', params)
    if (paymentOrder && paymentOrder.orderStoreId && paymentOrder.orderType) {
      // if (orderStoreId && orderType) {
      const { orderStoreId, orderType, partnerStoreId, isRefund, isCOD } = paymentOrder
      // body.showOnlyPayableToStoreId = orderStoreId
      switch (orderType) {
        case 1: {
          // ออเดอร์ขายลูกค้า -- คืนเงินลูกค้า (ไม่มีบัญชีลูกค้า) / บันทึกรับชำระแทนลูกค้า
          body.showOnlyPayableToStoreId = orderStoreId
          break
        }
        case 2: {
          // ออเดอร์ขายตัวแทน -- คืนเงินตัวแทน / บันทึกรับชำระแทนตัวแทน
          if (!isRefund) {
            body.partner_store_id = partnerStoreId
          } else {
            body.showOnlyPayableToStoreId = isRefund ? partnerStoreId : orderStoreId
          }
          break
        }
        case 3: {
          // ออเดอร์ซื้อ -- คืนเงินทีั่เกิน  / แจ้งชำระ
          body.showOnlyPayableToStoreId = isRefund ? partnerStoreId : orderStoreId
          break
        }
        default:
          // no-op
          throw new Error('Error: paymentOrder must have order_type ')
      }
      // body.showOnlyPayableToStoreId = isRefund && orderType !== 1 ? partnerStoreId : orderStoreId
      if (isRefund) {
        body.isRefund = isRefund
      }
      if (_.isBoolean(isCOD)) {
        body.isCOD = isCOD
        body.is_cod = isCOD
      }
    }

    // if (Map.isMap(filterSetting)) {
    //   if (_.isBoolean(filterSetting.get('isCOD'))) {
    //     body.isCOD = filterSetting.get('isCOD')
    //   }
    //   if (_.isBoolean(filterSetting.get('isShipBeforePay'))) {
    //     body.isShipBeforePay = filterSetting.get('isShipBeforePay')
    //   }
    //   if (_.isBoolean(filterSetting.get('hasPrintedAt'))) {
    //     const hasPrintedAt = filterSetting.get('hasPrintedAt')
    //     // const segmentHasPrintedAtIndex = filterSetting.get('segmentHasPrintedAtIndex') || 0

    //     // เริ่ม apply filter has_printed_at
    //     body.has_printed_at = hasPrintedAt

    //     // ถ้าหากมีการกรองที่ระบุเวลาเกิดขึ้น
    //     if (hasPrintedAt) {
    //       const printedAtFrom = filterSetting.get('printedAtFrom')
    //       const printedAtTo = filterSetting.get('printedAtTo')
    //       if (moment.isMoment(printedAtFrom) && moment.isMoment(printedAtTo)) {
    //         // apply filter by datetime
    //         body.printed_at_from = printedAtFrom.format(CONS.SERVER_DATETIME_FORMAT)
    //         body.printed_at_to = printedAtTo.format(CONS.SERVER_DATETIME_FORMAT)
    //       }
    //     }
    //   }

    //   if (_.isString(filterSetting.get('orderSegment'))) {
    //     body.order_segment = filterSetting.get('orderSegment')
    //   }

    //   // สำหรับ mkp channels
    //   if (filterSetting.has('mkpChannelIds') && !_.isNil(filterSetting.get('mkpChannelIds'))) {
    //     // @ts-ignore
    //     body.ug_ids = filterSetting.get('mkpChannelIds')
    //   }
    //   if (filterSetting.has('orderOrigin') && _.isString(filterSetting.get('orderOrigin'))) {
    //     body.order_origin = filterSetting.get('orderOrigin')
    //   }

    //   // create order
    //   if (!_.isNil(filterSetting.get('createOrderFrom')) && !_.isNil(filterSetting.get('createOrderTo'))) {
    //     // @ts-ignore
    //     body.created_at_from = filterSetting.get('createOrderFrom').format(CONS.SERVER_DATETIME_FORMAT)
    //     // @ts-ignore
    //     body.created_at_to = filterSetting.get('createOrderTo').format(CONS.SERVER_DATETIME_FORMAT)
    //   }

    //   // Selector Shipping Type
    //   const selectorShippingTypeIds = _.isNil(filterSetting.get('selectorShippingTypeIds'))
    //     ? null
    //     : filterSetting.get('selectorShippingTypeIds').toJS()
    //   if (!_.isNil(selectorShippingTypeIds) && _.isArray(selectorShippingTypeIds) && selectorShippingTypeIds.length > 0) {
    //     body.shipping_type_ids = filterSetting.get('selectorShippingTypeIds').toJS()
    //   }
    //   // FILTER CREATE BODY
    //   // สภานะพร้อมส่ง
    //   // console.log('ABC => ', filterSetting.get('hasReadyToShipDate'))
    //   if (filterSetting.get('hasReadyToShipDate') === false) {
    //     body.has_ready_to_ship_date = false
    //   } else if (filterSetting.get('hasReadyToShipDate')) {
    //     const segmentReadyToShipForDateTimeIndex = _.isNil(filterSetting.get('segmentReadyToShipForDateTimeIndex'))
    //       ? 0
    //       : filterSetting.get('segmentReadyToShipForDateTimeIndex')
    //     if (segmentReadyToShipForDateTimeIndex === 0) {
    //       body.has_ready_to_ship_date = true
    //     } else {
    //       // @ts-ignore
    //       body.ready_to_ship_date_from = filterSetting.get('readyToShipDateFrom').format(CONS.SERVER_DATETIME_FORMAT)
    //       // @ts-ignore
    //       body.ready_to_ship_date_to = filterSetting.get('readyToShipDateTo').format(CONS.SERVER_DATETIME_FORMAT)
    //     }
    //   }
    //   // กำหนดส่ง
    //   if (filterSetting.get('hasDateDelivery') === false) {
    //     body.has_date_delivery = false
    //   } else if (filterSetting.get('hasDateDelivery')) {
    //     const segmentDateDeliveryIndex = _.isNil(filterSetting.get('segmentDateDeliveryIndex'))
    //       ? 0
    //       : filterSetting.get('segmentDateDeliveryIndex')
    //     if (segmentDateDeliveryIndex === 0) {
    //       body.has_date_delivery = true
    //     } else {
    //       // @ts-ignore
    //       body.date_delivery_from = filterSetting.get('dateDeliveryFrom').format(CONS.SERVER_DATETIME_FORMAT)
    //       // @ts-ignore
    //       body.date_delivery_to = filterSetting.get('dateDeliveryTo').format(CONS.SERVER_DATETIME_FORMAT)
    //     }
    //   }
    //   //  ยกเลิกออเดอร์อัตโนมัติ
    //   if (filterSetting.get('hasExpirationDate') === false) {
    //     body.has_expiration_date = false
    //   } else if (filterSetting.get('hasExpirationDate')) {
    //     const segmentAutoCancelOrderIndex = _.isNil(filterSetting.get('segmentAutoCancelOrderIndex'))
    //       ? 0
    //       : filterSetting.get('segmentAutoCancelOrderIndex')
    //     if (segmentAutoCancelOrderIndex === 0) {
    //       body.has_expiration_date = true
    //     } else {
    //       // @ts-ignore
    //       body.expiration_date_from = filterSetting.get('AutoCancelOrderFrom').format(CONS.SERVER_DATETIME_FORMAT)
    //       // @ts-ignore
    //       body.expiration_date_to = filterSetting.get('AutoCancelOrderTo').format(CONS.SERVER_DATETIME_FORMAT)
    //     }
    //   }
    // }

    // Mock filter
    // body.order_states = [101, 102, 103, 109]
    // body.shipping_states = [122]
    // body.queryTxt = 'order.printed IS NOT NULL'
    // body.has_printed_at = true
    // body.has_printed_at = false
    // body.printed_at_from = '2020-08-03 00:00:00'
    // body.printed_at_to = '2020-08-03 23:59:59'

    switch (mode) {
      case 'FETCH_COUNT': {
        // body.types =  acl.authorizedOrderListTabs()
        // body.types_to_count = acl.authorizedOrderListTabs()
        const { availableTabKeys } = this.state
        if (availableTabKeys.length > 0 && tabName !== SEARCH_TABNAME) {
          body.types = availableTabKeys
          body.types_to_count = availableTabKeys
        }

        if (tabName && tabName !== SEARCH_TABNAME) {
          body.types = []
        }

        if (tabNames && tabNames.length > 0) {
          body.types = tabNames
          body.types_to_count = tabNames
        }

        body.offset = 0
        body.limit = 0
        break
      }
      case 'FETCH_INIT': {
        // body.types =  acl.authorizedOrderListTabs()
        // body.types_to_count = acl.authorizedOrderListTabs()
        const { availableTabKeys } = this.state
        if (availableTabKeys.length > 0 && tabName !== SEARCH_TABNAME) {
          body.types = availableTabKeys
          // body.types_to_count = availableTabKeys
        }

        if (tabName && tabName !== SEARCH_TABNAME) {
          body.types = [tabName]

          // const typeToCountByTabNameFoundIndex = body.types_to_count.findIndex(ct => ct === tabName)
          // if (typeToCountByTabNameFoundIndex === -1) {
          //   body.types_to_count = [tabName]
          // }

          // const { ordersCountSummary } = this.state
          // if (_.has(ordersCountSummary, tabName) && _.isEmpty(filterSetting)) {
          //   body.types_to_count = [tabName]
          // }
        }

        if (tabNames && tabNames.length > 0) {
          body.types = tabNames
          // body.types_to_count = tabNames
        }

        body.summary = false
        body.offset = 0
        break
      }
      case 'FETCH_MORE': {
        if (!tabName) {
          throw new Error('Error: ORDERS_FETCH_MODE_MORE: Tab Name is required.')
        }
        body.summary = false
        if (tabName !== SEARCH_TABNAME) {
          body.types = [tabName]
        }
        body.offset = this.getFetchedOrdersCount(tabName) || 0
        break
      }
      default:
        // no-op
        throw new Error('Error: fetch MODE is required.')
    }

    // if (shouldFetchOnlySingleTabKey) {
    //   body.types_to_count = [tabName]
    // }

    // if (forceOffset) {
    //   body.offset = forceOffset
    // }
    // if (forceLimit) {
    //   body.limit = forceLimit
    // }
    // if (sortBy) {
    //   body.sortBy = sortBy
    // }
    // if (sortType) {
    //   body.sortType = sortType
    // }

    const translatedFilterSetting = util.translateOrdersFilterToRequestBody(filterSetting)
    return { ...body, ...translatedFilterSetting, ...sortOptions, ...overrideRequestBody, ...this.getOverrideRequestBody() }
  }

  _fetchOrders = async (req: IFetchOrdersRequestBody, isSearch?: boolean) => {
    // const { mode, storeId, sortBy, sortType, paymentOrder, tabName } = fetchParams
    const apiOptions: IApiOptions = {
      axiosOptions: {
        retry: 0,
        timeout: 180000, // long fetch 180 วินาที
      },
    }
    try {
      const endpoint = isSearch ? api.POST_ORDERS_QUERY : api.POST_ORDERS
      const res = await api.post<IFetchOrdersRequestBody, IFetchOrderResponse>(endpoint, req, apiOptions)
      return res
    } catch (err) {
      console.log('_fetchOrders err => ', err)
    }
  }

  // _checkHasNoMoreDataFromResponse = async (response) => {
  //   if (!response || !response.orders) {
  //     return
  //   }
  //   const { tabName } = this.state
  //   const { orders = {} } = response

  //   const newOrdersData = orders[tabName]
  //   if (!newOrdersData) {
  //     return
  //   }
  //   console.log('_checkHasNoMoreDataFromResponse response => ', response)
  //   console.log('_checkHasNoMoreDataFromResponse tabName => ', tabName)
  //   console.log('_checkHasNoMoreDataFromResponse newOrdersData => ', newOrdersData)

  //   if (newOrdersData.length === 0) {
  //     console.log('_checkHasNoMoreDataFromResponse set isNoMoreFetchData=true')
  //     await util.setStatePromise(this, { isNoMoreFetchData: true })
  //   }
  // }

  onOrderListScrollReached = () => {
    if (this.isOrdersDisplayPageMode()) {
      return
    }
    // console.log('onOrderListScrollReached called !!!')
    const { tabName } = this.state
    if (this.getHasMoreOrders(tabName)) {
      this.loadMoreOrders(tabName)
    }
  }

  getFetchedOrdersCount = (tabName: OrdersTabKeyType) => {
    // const { tabName } = this.state
    // const { orders } = this.props
    // return orders.get(tabName).size || 0
    const orders = this.getOrders(tabName)
    return orders.length || 0
  }

  getSummaryOrdersCount = (tabName: OrdersTabKeyType) => {
    // const { tabName } = this.state
    // const { summary } = this.props
    // return summary.get(tabName) || 0
    const { ordersCountSummary } = this.state
    return ordersCountSummary[tabName] || 0
  }

  // isForceMkpOnly = () => this.isConfirmMkpOrdersMode()

  // isConfirmMkpOrdersMode = () => {
  //   const multiOpMode: MultiOpMode = this.getMultiOpMode()
  //   return multiOpMode === CONS.MULTI_OP_MODES.ConfirmMkpOrders
  // }

  // isAutoCompletePaymentMode = () => {
  //   const multiOpMode: MultiOpMode = this.getMultiOpMode()
  //   return multiOpMode === CONS.MULTI_OP_MODES.AutoCompletePayment
  // }

  // isAutoCompleteShipmentMode = () => {
  //   const multiOpMode: MultiOpMode = this.getMultiOpMode()
  //   return multiOpMode === CONS.MULTI_OP_MODES.AutoCompleteShipment
  // }

  // getMultiOpMode = () => this.props.navigation.getParam('multiOpMode', null)
}

export default BaseOrderListView
