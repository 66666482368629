import { useContext } from 'react'
// import { NavigationScreenProp, NavigationRoute, NavigationContext } from 'react-navigation'
import { NavigationContext, NavigationProp } from '@react-navigation/native'
import { IAnyObject } from 'x/types'

// export function useNavigation<Params>(): NavigationScreenProp<NavigationRoute, Params> {
//   // @ts-ignore web typescript unknown error
//   return useContext(NavigationContext) as NavigationScreenProp<NavigationRoute, Params>
// }

export function useNavigation<Params extends object = IAnyObject>() {
  // @ts-ignore web typescript unknown error
  return useContext(NavigationContext) as NavigationProp<Params, string>
}
