import React, { ReactNode, useState, Fragment } from 'react'

import { View, Text, StyleSheet } from 'react-native'

import { COLORS, S } from 'x/config/styles'
import * as NavActions from 'x/utils/navigation'
import * as util from 'x/utils/util'
import p from 'x/config/platform-specific'
import {
  delay,
  filterMkpChannelIsActive,
  getMKPChannels,
  isMkpChannelActiveAtLeastOne,
  checkAvailableBackgroundJobType,
  getBgJobTypeText,
  isXSellyErrorV2,
  alertWithXSellyErrorV2,
  hasHelperPermission,
  hasHelperPermissions,
} from 'x/utils/util'

import CONS from 'x/config/constants'
import { BackgroundJobTypeType, IMultipleOrdersOperationMenuViewNavParams, IXScreenProps } from 'x/index'
import HStack from 'xui/components/HStack'
import VStack from 'xui/components/VStack'
import Spinner from 'xui/components/XSpinner'
import XText from 'xui/components/XText'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import XCard from 'xui/components/XCard'
import XIconButton from 'xui/components/XIconButton'
import XCustomHeader from '../../components/XCustomHeader'
import XIcon, { XIconProps } from '../../components/XIcon'
import ForwardIcon from '../../components/ForwardIcon'

type IMultipleOrdersOperationMenuViewProps = IXScreenProps<IMultipleOrdersOperationMenuViewNavParams>

const OP_NAV_ACTIONS = {
  myTasks_ship: NavActions.navToStoreOrderSingleTabListView({
    tabKey: 'myTasks_ship',
    title: 'พร้อมจัดส่ง',
    multiOpMode: CONS.MULTI_OP_MODES.PrintShippingLabels,
  }),
  myTasks_ship_not_ready: NavActions.navToStoreOrderSingleTabListView({
    tabKey: 'myTasks_ship_not_ready',
    title: 'ไม่พร้อมจัดส่ง',
    multiOpMode: CONS.MULTI_OP_MODES.PrintShippingLabels,
  }),
  myTasks_ship_all: NavActions.navToStoreOrderSingleTabListView({
    tabKey: 'myTasks_ship_all',
    title: 'ทั้งหมดที่ต้องจัดส่ง',
    multiOpMode: CONS.MULTI_OP_MODES.PrintShippingLabels,
  }),
  myTasks_confirmOrder_mkp: NavActions.navToStoreOrderSingleTabListView({
    tabKey: 'myTasks_confirmOrder',
    title: 'ยืนยันออเดอร์ที่มาจากช่องทางขาย',
    multiOpMode: CONS.MULTI_OP_MODES.ConfirmMkpOrders,
  }),
  autoCompleteManualByOrderTabItems: NavActions.navToAutoCompleteOrderTabItemsView,
  autoCompletePaymentBySegment: NavActions.navToAutoCompletePaymentBySegmentView,
  autoCompleteShipmentBySegment: NavActions.navToAutoCompleteShipmentBySegmentView,
}

// const OpItem = ({ title, onPress }) => {
//   return (
//     <TouchableOpacity
//       onPress={onPress}
//       style={{
//         minHeight: 44,
//         flexBasis: 'auto',
//         borderTopWidth: 1,
//         borderLeftWidth: 1,
//         borderBottomWidth: 1,
//         borderRightWidth: 1,
//         borderTopColor: COLORS.TEXT_INACTIVE,
//         borderLeftColor: COLORS.TEXT_INACTIVE,
//         borderBottomColor: COLORS.TEXT_INACTIVE,
//         borderRightColor: COLORS.TEXT_INACTIVE,
//         borderRadius: 6,
//         flexDirection: 'row',
//         justifyContent: 'center',
//         alignItems: 'center',
//         paddingLeft: 8,
//         marginBottom: 8,
//       }}>
//       <View style={{ flex: 1, justifyContent: 'center', alignItems: 'flex-start' }}>
//         <Text style={[S.TEXT_ACTIVE]}>{title}</Text>
//       </View>
//       {p.op.isWeb() ? (
//         <View style={{ width: 44, height: '100%', justifyContent: 'center', alignItems: 'center' }}>
//           <ForwardIcon style={{ flex: 0 }} />
//         </View>
//       ) : null}
//     </TouchableOpacity>
//   )
// }

const SectionIcon = (props: XIconProps) => {
  const propStyle = props.style ? props.style : {}
  const modifiedStyle = StyleSheet.flatten([S.NO_FLEX, S.WIDTH_30, { minWidth: 24 }, propStyle])
  // @ts-ignore modifiedStyle เป็น TextStyle ถูกต้องแล้ว แต่มี flex มาเกี่ยวข้องจึงเกิด type error ซึ่งจริงๆ แล้วใช้ได้
  return <XIcon inactive {...props} style={modifiedStyle} />
}

// const OpItem = ({ title, onPress }) => (
//   <TouchableOpacity onPress={onPress} style={[S.FLEX, S.ROW_MIDDLE_BETWEEN, S.BUTTON_OUTLINE, { marginBottom: 16 }]}>
//     <View style={[S.FLEX, { paddingLeft: 4 }]}>
//       <XText>{title}</XText>
//     </View>
//     <View style={[S.NO_FLEX, S.WIDTH_44, S.HEIGHT_FULL, S.ROW_CENTER]}>
//       <ForwardIcon style={S.NO_FLEX} />
//     </View>
//   </TouchableOpacity>
// )

const OpItem = ({ title, onPress, ...restProps }) => (
  <XCard onPress={onPress} {...restProps}>
    <XCard.Body>
      <HStack space='2' alignItems='center' justifyContent='space-between'>
        <HStack alignItems='center' flexWrap='wrap'>
          <XText>{title}</XText>
        </HStack>
        <ForwardIcon />
      </HStack>
    </XCard.Body>
  </XCard>
)

const InProcessOverlay = () => (
  <View style={[S.WIDTH_FULL, S.HEIGHT_FULL, S.ROW_CENTER, S.BG_GREY, { opacity: 0.7, position: 'absolute' }]}>
    <Spinner color={COLORS.TEXT_INACTIVE} />
  </View>
)

const SectionTitle: React.FC<{
  title: string
  icon: ReactNode
  subTitle?: string
  alert?: {
    title: string
    message: string
  }
}> = ({ title, subTitle, icon, alert }) => (
  <View style={[S.ROW_MIDDLE_START, { marginTop: 12, marginBottom: 4 }]}>
    <View style={[S.NO_FLEX, S.ROW_MIDDLE_START, { minHeight: 26, width: 28, marginRight: 4 }]}>{icon}</View>
    <View style={[S.FLEX, S.ROW_MIDDLE_START]}>
      <XText variant='inactive' bold>
        {title}
      </XText>
      {subTitle ? <XText variant='inactive'>{`   ${subTitle}`}</XText> : null}
    </View>
    {alert ? (
      <XIconButton
        onPress={() => p.op.alert(alert.title, alert.message)}
        iconVariant='inactive'
        inactive
        name='help-circle-outline'
        variant='inactive'
      />
    ) : null}
  </View>
)

const MultipleOrdersOperationMenuView: React.FC<IMultipleOrdersOperationMenuViewProps> = (props) => {
  const { navigation } = props
  const [inProcess, setInProcess] = useState(false)
  const selectedStoreId = util.getNavParam(props, 'selectedStoreId')

  // const _doRequestClose = () => {
  //   if (_.isFunction(onRequestClose)) {
  //     onRequestClose()
  //   }
  // }

  const _goBack = () => {
    util.navGoBack(props)
  }
  const _getHeaderLeftProps = () => {
    if (p.op.isWeb()) {
      return null
    }
    return { backIcon: true, onPressItem: _goBack }
  }

  const _doNavAction = async (navAction) => {
    // _doRequestClose()
    await delay(200)
    navigation.dispatch(navAction)
  }

  // const _navToMyTaskShip = async () => {
  //   await _doNavAction(OP_NAV_ACTIONS.myTasks_ship)
  // }

  // const _navToMyTaskShipNotReady = async () => {
  //   await _doNavAction(OP_NAV_ACTIONS.myTasks_ship_not_ready)
  // }

  // const _navToMyTaskShipAll = async () => {
  //   await _doNavAction(OP_NAV_ACTIONS.myTasks_ship_all)
  // }

  const _navToPrintDocumentOrderListView = async () => {
    await _doNavAction(NavActions.navToPrintDocumentOrderListView({ store_id: selectedStoreId }))
  }

  const _navToMyTaskConfirmOrdersMkp = async () => {
    // await _doNavAction(OP_NAV_ACTIONS.myTasks_confirmOrder_mkp)
    await _doNavAction(NavActions.navToMkpConfirmOrderListView({ store_id: selectedStoreId }))
  }

  const _navToAutoCompletePaymentView = async () => {
    await _navToAutoCompleteCompleteManualByOrderTabItemsWithBgJobCheck(CONS.BACKGROUND_JOB_TYPES.PAYMENT_CREATE)
  }

  const _navToAutoCompleteShipmentView = async () => {
    await _navToAutoCompleteCompleteManualByOrderTabItemsWithBgJobCheck(CONS.BACKGROUND_JOB_TYPES.SHIPPING_CREATE)
  }

  const _navToAutoCompleteCompleteManualByOrderTabItemsWithBgJobCheck = async (bgJobType: BackgroundJobTypeType) => {
    if (inProcess) {
      return
    }
    setInProcess(true)
    await delay(200)
    try {
      const { isAvailable, bgJobResponse } = await checkAvailableBackgroundJobType({ bgJobType, store_id: selectedStoreId, jobLimit: 1 })
      // const bgTypeText = getBgJobTypeText(bgJobType)
      if (!isAvailable) {
        setInProcess(false)
        await delay(200)
        await _doNavAction(
          NavActions.navToBackgroundJobInProcessByTypeView({ storeId: selectedStoreId, bgJobType, initBgJobResponse: bgJobResponse })
        )
        return
      }
    } catch (err) {
      // console.log('_navToAutoCompletePaymentMultiOpWithBgJobCheck err => ', err)
      if (isXSellyErrorV2(err)) {
        // @ts-ignore
        alertWithXSellyErrorV2(err.error)
      }
    }

    setInProcess(false)
    await delay(200)

    // await _doNavAction(OP_NAV_ACTIONS.autoCompleteManualByOrderTabItems({ storeId: selectedStoreId, bgJobType }))
    if (bgJobType === CONS.BACKGROUND_JOB_TYPES.SHIPPING_CREATE) {
      await _doNavAction(NavActions.navToAutoShipmentOrderListView({ store_id: selectedStoreId }))
    }
    if (bgJobType === CONS.BACKGROUND_JOB_TYPES.PAYMENT_CREATE) {
      await _doNavAction(NavActions.navToAutoPaymentOrderListView({ store_id: selectedStoreId }))
    }
  }

  const _navToAutoCompletePaymentBySegmentMultiOpWithBgJobCheck = async (bgJobType: BackgroundJobTypeType) => {
    if (inProcess) {
      return
    }
    setInProcess(true)
    await delay(200)
    try {
      const { isAvailable, bgJobResponse } = await checkAvailableBackgroundJobType({ bgJobType, store_id: selectedStoreId, jobLimit: 1 })
      // const bgTypeText = getBgJobTypeText(bgJobType)
      if (!isAvailable) {
        setInProcess(false)
        await delay(200)
        await _doNavAction(
          NavActions.navToBackgroundJobInProcessByTypeView({ storeId: selectedStoreId, bgJobType, initBgJobResponse: bgJobResponse })
        )
        return
      }
    } catch (err) {
      // console.log('_navToAutoCompletePaymentMultiOpWithBgJobCheck err => ', err)
      if (isXSellyErrorV2(err)) {
        // @ts-ignore
        alertWithXSellyErrorV2(err.error)
      }
    }

    setInProcess(false)
    await delay(200)
    await _doNavAction(OP_NAV_ACTIONS.autoCompletePaymentBySegment())
  }

  const _navToAutoCompleteShipmentBySegmentMultiOpWithBgJobCheck = async (bgJobType: BackgroundJobTypeType) => {
    if (inProcess) {
      return
    }
    setInProcess(true)
    await delay(200)
    try {
      const { isAvailable, bgJobResponse } = await checkAvailableBackgroundJobType({ bgJobType, store_id: selectedStoreId, jobLimit: 1 })
      // const bgTypeText = getBgJobTypeText(bgJobType)
      if (!isAvailable) {
        setInProcess(false)
        await delay(200)
        await _doNavAction(
          NavActions.navToBackgroundJobInProcessByTypeView({ storeId: selectedStoreId, bgJobType, initBgJobResponse: bgJobResponse })
        )
        return
      }
    } catch (err) {
      // console.log('_navToAutoCompletePaymentMultiOpWithBgJobCheck err => ', err)
      if (isXSellyErrorV2(err)) {
        // @ts-ignore
        alertWithXSellyErrorV2(err.error)
      }
    }

    setInProcess(false)
    await delay(200)
    await _doNavAction(OP_NAV_ACTIONS.autoCompleteShipmentBySegment())
  }

  const _navToAutoShiptMultiOpWithBgJobCheck = async () => {
    try {
      const bgJobType = CONS.BACKGROUND_JOB_TYPES.SHIPPING_CREATE
      const { isAvailable, bgJobResponse } = await checkAvailableBackgroundJobType({ bgJobType, store_id: selectedStoreId })
      const bgTypeText = getBgJobTypeText(bgJobType)
      if (!isAvailable) {
        await _doNavAction(
          NavActions.navToBackgroundJobInProcessByTypeView({ storeId: selectedStoreId, bgJobType, initBgJobResponse: bgJobResponse })
        )
        return
      }
    } catch (err) {
      console.log('_navToAutoCompletePaymentMultiOpWithBgJobCheck err => ', err)
    }
    await _doNavAction(OP_NAV_ACTIONS.myTasks_ship_all)
  }

  const _renderMkpTasksSection = () => {
    const hasOrderPerm = hasHelperPermissions([
      CONS.PERM_STORE_HELPER.ORDER_ADD,
      CONS.PERM_STORE_HELPER.ORDER_LIST,
      CONS.PERM_STORE_HELPER.ORDER_EDIT,
    ])
    if (!hasOrderPerm) {
      return null
    }

    const mkpChannals = getMKPChannels() || []
    const activeMkpChannels = mkpChannals.filter(filterMkpChannelIsActive)
    const hasMkpChannelActiveAtLeastOne = isMkpChannelActiveAtLeastOne(activeMkpChannels)
    if (!hasMkpChannelActiveAtLeastOne) {
      return null
    }
    return (
      <VStack w='full' key='_renderMkpTasksSection'>
        <SectionTitle
          title='จัดการออเดอร์ที่มาจากช่องทางขาย...'
          // subTitle={'สำหรับออเดอร์ที่...'}
          icon={<SectionIcon inactive name='file-document' family='MaterialCommunityIcons' style={{ marginLeft: -2, width: 28 }} />}
        />
        <OpItem title='ยืนยันออเดอร์' onPress={_navToMyTaskConfirmOrdersMkp} />
        {/* <OpItem title={'บันทึกจัดส่งอัตโนมัติ'} onPress={_navToMyTaskShipNotReady} /> */}
        {/* <OpItem title={'บันทึกการชำระอัตโนมัติ'} onPress={_navToMyTaskShipAll} /> */}
      </VStack>
    )
  }

  const _renderPrintShippingLabelsSection = () => {
    const hasPrintingLabelPerm = hasHelperPermission(CONS.PERM_STORE_HELPER.ORDER_PRINT_SHIPPING)
    if (!hasPrintingLabelPerm) {
      return null
    }
    return (
      <VStack w='full' key='_renderPrintShippingLabelsSection'>
        <SectionTitle
          title='พิมพ์เอกสาร'
          subTitle='สำหรับออเดอร์ที่...'
          icon={<SectionIcon inactive name='printer' family='MaterialCommunityIcons' />}
        />
        {/* <OpItem title='พร้อมจัดส่ง (รอฉัน > จัดส่ง)' onPress={_navToMyTaskShip} /> */}
        {/* <OpItem title='ไม่พร้อมจัดส่ง' onPress={_navToMyTaskShipNotReady} /> */}
        {/* <OpItem title='ทั้งหมดที่ต้องจัดส่ง (พร้อม + ไม่พร้อม)' onPress={_navToMyTaskShipAll} /> */}
        <OpItem title='ทั้งหมดที่ต้องจัดส่ง (พร้อม + ไม่พร้อม)' onPress={_navToPrintDocumentOrderListView} />
      </VStack>
    )
  }

  const _renderAutoCompletePayment = () => {
    const hasPaymentPerm = hasHelperPermissions([CONS.PERM_STORE_HELPER.PAYMENT_ADD, CONS.PERM_STORE_HELPER.PAYMENT_EDIT])
    if (!hasPaymentPerm) {
      return null
    }
    return (
      <VStack w='full' key='_renderAutoCompletePayment'>
        <SectionTitle
          title='บันทึกรับชำระอัตโนมัติ'
          icon={<SectionIcon inactive name='cash' style={{ minWidth: 16, width: 22, height: 16, marginLeft: 3 }} />}
          alert={{
            title: 'บันทึกรับชำระอัตโนมัติ',
            message:
              'ใช้เพื่อบันทึกรับชำระครั้งละหลายออเดอร์\n(ออเดอร์ขายของฉันที่ยังไม่ได้บันทึกรับชำระ)\n\n' +
              'เมื่อเริ่มดำเนินการ ระบบจะสร้างและจัดการคิวคำสั่งงานสำหรับแต่ละออเดอร์ เพื่อทำการบันทึกรับชำระเต็มจำนวนตามยอดที่ค้างอยู่',
          }}
        />
        <HStack w='full' space='2' justifyContent='space-between'>
          <OpItem title='เลือกเอง' onPress={_navToAutoCompletePaymentView} />
          <OpItem
            title='เลือกตามเงื่อนไข'
            onPress={() => _navToAutoCompletePaymentBySegmentMultiOpWithBgJobCheck(CONS.BACKGROUND_JOB_TYPES.PAYMENT_CREATE)}
          />
        </HStack>
      </VStack>
    )
  }

  const _renderAutoCompleteShipment = () => {
    const hasShipmentPerm = hasHelperPermissions([CONS.PERM_STORE_HELPER.SHIPPING_ADD, CONS.PERM_STORE_HELPER.SHIPPING_EDIT])
    if (!hasShipmentPerm) {
      return null
    }
    return (
      <VStack w='full' key='_renderAutoCompleteShipment'>
        <SectionTitle
          title='บันทึกจัดส่งอัตโนมัติ'
          icon={<SectionIcon inactive name='truck-delivery' family='MaterialCommunityIcons' />}
          alert={{
            title: 'บันทึกจัดส่งอัตโนมัติ',
            message:
              'ใช้เพื่อบันทึกจัดส่งครั้งละหลายออเดอร์\n(ออเดอร์ขายของฉันที่ยังไม่ได้บันทึกจัดส่ง)\n\n' +
              'เมื่อเริ่มดำเนินการ ระบบจะสร้างและจัดการคิวคำสั่งงานสำหรับแต่ละออเดอร์ เพื่อทำการบันทึกจัดส่ง',
          }}
        />
        <HStack w='full' space='2' justifyContent='space-between'>
          <OpItem title='เลือกเอง' onPress={_navToAutoCompleteShipmentView} />
          <OpItem
            title='เลือกตามเงื่อนไข'
            onPress={() => _navToAutoCompleteShipmentBySegmentMultiOpWithBgJobCheck(CONS.BACKGROUND_JOB_TYPES.SHIPPING_CREATE)}
          />
        </HStack>
      </VStack>
    )
  }

  const _renderMainContent = () => {
    const willRenderContent = [
      _renderPrintShippingLabelsSection(),
      _renderMkpTasksSection(),
      _renderAutoCompletePayment(),
      _renderAutoCompleteShipment(),
    ]

    let hasAtLeastOneRenderableSection = false
    for (const jsxElement of willRenderContent) {
      if (jsxElement) {
        hasAtLeastOneRenderableSection = true
        break
      }
    }

    if (!hasAtLeastOneRenderableSection) {
      return (
        <View style={[S.HEIGHT_FULL, S.WIDTH_FULL, S.PADDING_VERTICAL_4, S.PADDING_HORIZONTAL_12, S.COLUMN_CENTER_TOP]}>
          <Text style={[S.MARGIN_VERTICAL_12, S.TEXT_INACTIVE]}>คุณไม่มีสิทธิ์การใช้งานในส่วนนี้</Text>
        </View>
      )
    }

    return willRenderContent
    // return (
    //   <ScrollView style={[S.HEIGHT_FULL, S.WIDTH_FULL]} contentContainerStyle={[S.PADDING_VERTICAL_4, S.PADDING_HORIZONTAL_12]}>
    //     {/* {_renderPrintShippingLabelsSection()}
    //     {_renderMkpTasksSection()}
    //     {_renderAutoCompletePayment()}
    //     {_renderAutoCompleteShipment()} */}
    //     {willRenderContent}
    //   </ScrollView>
    // )
  }

  const _renderWebContent = () => (
    <View
      style={{
        flex: 1,
        // paddingTop: 8,
        paddingLeft: 8,
        paddingBottom: 8,
        paddingRight: 8,
      }}>
      {_renderMainContent()}
    </View>
  )

  return (
    <>
      <XContainer>
        <XCustomHeader
          headerLeftProps={_getHeaderLeftProps()}
          // headerRightProps={_.isFunction(onRequestClose) ? { closeIcon: true, onPressItem: onRequestClose } : null}
          title='เลือกหลายออเดอร์ เพื่อ...'
        />
        <View
          style={{
            height: 1,
            width: '100%',
            borderTopWidth: 0.5,
            borderTopColor: p.op.isWeb() ? COLORS.BG_LIGHT_GREY : COLORS.TEXT_INACTIVE,
          }}
        />
        {/* {p.op.isWeb() ? _renderWebContent() : _renderMainContent()} */}
        <XContent>
          <VStack w='full' px='3' space='2'>
            {_renderMainContent()}
          </VStack>
        </XContent>
      </XContainer>
      {inProcess ? <InProcessOverlay /> : null}
    </>
  )
}

export default MultipleOrdersOperationMenuView
