import _ from 'lodash'
import BaseUIChannelView from 'xui/modules/channels/BaseUIChannelView'

import * as NavActions from 'x/utils/navigation'
import * as util from 'x/utils/util'

export default class ChannelView extends BaseUIChannelView {
  static displayName = 'ChannelView'

  static navigationOptions = {
    header: null,
  }

  goBack = () => {
    util.navGoBack(this.props)
  }

  navToProductGroupList = () => {
    const { navigation, selectedStore } = this.props
    const { channel } = this.state
    if (_.isNil(channel) || _.isNil(channel.pg_ids)) {
      return
    }
    const { pg_ids } = channel
    if (_.isNil(pg_ids)) {
      navigation.dispatch(
        NavActions.navToChannelAssignProductGroups({
          store_id: selectedStore.get(`id`),
          ug_id: this.state.channel.id,
          // callBackMKP: (pg_ids: { pg_id: number }[]) => this._updateChannelList(pg_ids),
        })
      )
      return
    }
    if (channel.pg_ids.length < 1) {
      navigation.dispatch(
        NavActions.navToChannelAssignProductGroups({
          store_id: selectedStore.get(`id`),
          ug_id: this.state.channel.id,
          callBackMKP: (pgs: { pg_id: number }[]) => this._updateChannelList(pgs),
        })
      )
    } else if (!channel.is_active) {
      this._getShopeeUrl()
    } else {
      this._getShopeeUrl()
      // p.op.showConfirmationOkOnly(``, `เกิดข้อผิดพลาดกรุณาติดต่อทีมงาน`)
    }
  }
}
