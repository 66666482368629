import React from 'react'
import { TouchableOpacity, Image } from 'react-native'

// import p from 'x/config/platform-specific'
import BaseAboutView from 'x/modules/setting/BaseAboutView'
import XCustomHeader from 'xui/components/XCustomHeader'
// import { Input } from 'react-native-elements'
import p from 'x/config/platform-specific'
import * as NavActions from 'x/utils/navigation'
import XText from 'xui/components/XText'
import XCard from 'xui/components/XCard'
import XContactButtons from 'xui/components/XContactButtons'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import Box from 'xui/components/Box'
import HStack from 'xui/components/HStack'
import CONS from 'x/config/constants'
import { MODE, APP_CONFIG } from 'x/config/mode'
import * as util from 'x/utils/util'

export default abstract class BaseUIAboutView extends BaseAboutView {
  abstract _renderVersionDetail: () => void

  _renderCustomHeader = () => (
    <XCustomHeader
      title={p.op.t('NavTitle.about_app', { name: CONS.APP_NAMES[`${APP_CONFIG.app_name}`] })}
      headerLeftProps={{
        backIcon: true,
        onPressItem: () => util.navGoBack(this.props),
      }}
    />
  )

  _renderLogo = () => (
    <HStack>
      {MODE === 'dev' || MODE === 'demo' ? <TouchableOpacity style={{ flex: 1 }} onPress={() => this._changeHost()} /> : <Box flex={1} />}

      <Box>
        <Image
          resizeMode='contain'
          style={{
            flex: 1,
            height: 140,
            width: 140,
            alignSelf: 'center',
          }}
          source={require('../../img/logo_1024.png')}
        />
      </Box>
      {MODE === 'dev' || MODE === 'demo' ? (
        <TouchableOpacity style={{ flex: 1 }} onPress={() => this.props.navigation.dispatch(NavActions.navToDevMenuSubscription)} />
      ) : (
        <Box flex={1} />
      )}
    </HStack>
  )

  _renderSlogan = () => (
    <HStack alignItems='center' justifyContent='center' my='2' w='full'>
      <XText variant='active' bold textAlign='center'>
        {p.op.t('about_view.slogan')}
      </XText>
    </HStack>
  )

  _renderContactButtons = () => {
    if (!APP_CONFIG.about_view.show_contact_info_section) {
      return null
    }
    return (
      <Box w='full'>
        <XContactButtons />
      </Box>
    )
  }

  _renderFBDL = () => {
    if (MODE === 'dev' || MODE === 'demo') {
      return <TouchableOpacity style={{ height: 60, flex: 1 }} onPress={() => this._debugDeepLink()} />
    }
    return null
  }

  render() {
    return (
      <XContainer>
        {this._renderCustomHeader()}
        <XContent p='2'>
          <XCard p='2' w='full'>
            {this._renderLogo()}
            {this._renderSlogan()}
            {this._renderContactButtons()}
            {this._renderVersionDetail()}
            {this._renderFBDL()}
          </XCard>
        </XContent>
      </XContainer>
    )
  }
}
