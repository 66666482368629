// import PropTypes from 'prop-types'
import React from 'react'

import { COLORS } from 'x/config/styles'

import CONS from 'x/config/constants'
// import QRCodeModal from '../../components/QRCodeModal'
// import ForwardIcon from '../../components/ForwardIcon'
import _ from 'lodash'
import XContent from 'xui/components/XContent'
import XCard from 'xui/components/XCard'
import BaseProductGroupListView from 'x/modules/productGroup/BaseProductGroupListView'
import XContainer from 'xui/components/XContainer'
import XCustomHeader from 'xui/components/XCustomHeader'
import XText from 'xui/components/XText'
import VStack from 'xui/components/VStack'
import XIcon from 'xui/components/XIcon'
import XButton from 'xui/components/XButton'

import HStack from 'xui/components/HStack'
import { ProductGroup } from 'x/index'
import ForwardIcon from 'xui/components/ForwardIcon'
import XIconButton from 'xui/components/XIconButton'
import * as util from 'x/utils/util'

export default abstract class BaseUIProductGroupListView extends BaseProductGroupListView {
  _renderCustomHeader = () => {
    const isGoBackButtonHidden = util.getNavParam(this.props, 'isGoBackButtonHidden', false)
    const { submitting, mode } = this.state
    const title = 'รายการราคา'
    let leftProps = isGoBackButtonHidden ? { submitting } : { backIcon: true, onPressItem: () => this._goback(), submitting }

    let rightProps = {
      addIcon: true,
      submitting,
      onPressItem: () => this._addProductGroup(),
    }
    if (mode === 'SORT') {
      leftProps = {
        // @ts-ignore
        label: 'บันทึก',
        submitting,
        onPressItem: () => this._saveNewProductGroupSorted(),
      }
      rightProps = {
        // @ts-ignore
        label: 'ยกเลิก',
        submitting,
        onPressItem: () => this._cancelSorting(),
      }
    }
    return (
      <XCustomHeader
        title={title}
        headerLeftProps={leftProps}
        headerRightProps={rightProps}
        // renderHeaderLeft={() => this.renderLeftBtn()}
        // renderHeaderRight={() => this.renderRightBtn()}
      />
    )
  }

  _renderCardHeader = () => {
    const { mode, editingProductGroups } = this.state
    if (_.isNil(editingProductGroups)) {
      return null
    }
    const pgCount = editingProductGroups.length
    let txtSuggestInViewMode = null
    const visibleViewModeSuggest = mode === CONS.PRODUCT_GROUP_LIST_MODE.VIEW && pgCount > 1
    if (visibleViewModeSuggest) {
      txtSuggestInViewMode =
        'ในกรณีที่มีการระบุหลายราคาให้กับกลุ่มสมาชิกหนึ่งๆ สมาชิกในกลุ่มนั้นๆจะเห็นราคาที่ระบุไว้ลำดับบนที่สุด “เพียงราคาเดียว”\n' +
        '* คุณสามารถระบุรายการราคาให้กลุ่มสมาชิกได้ที่\nเมนูหลัก > สมาชิก > รายการราคา'
    }
    if (mode === 'SORT') {
      txtSuggestInViewMode = 'เลือกลูกศรขึ้นหรือลงเพื่อลำดับความสำคัญใหม่ "บนสุดสำคัญที่สุด" แล้วกดบันทึกหลังจากเรียงลำดับใหม่เสร็จสิ้น'
    }

    if (_.isNil(txtSuggestInViewMode)) {
      return null
    }
    return (
      <XCard p='2' w='full'>
        <XText w='full' variant='inactive'>
          {txtSuggestInViewMode}
        </XText>
      </XCard>
    )
  }

  _renderPGList = (pg: ProductGroup, index: number) => {
    const { mode, editingProductGroups } = this.state
    const disabled = mode === 'SORT'
    return (
      <XCard
        // eslint-disable-next-line react/no-array-index-key
        key={pg.priority}
        disabled={disabled}
        onPress={disabled ? undefined : () => this._viewProductGroup(index, pg)}>
        <XCard.Body>
          <HStack w='full'>
            <VStack flex={1} minH='9' justifyContent='center'>
              <XText variant='active' numberOfLines={1} w='full'>
                {pg.name}
              </XText>
              {pg.description ? (
                <XText variant='inactive' fontSize='sm' numberOfLines={1} w='full'>
                  {pg.description}
                </XText>
              ) : null}
            </VStack>

            {mode === 'SORT' ? (
              <HStack space='1'>
                {index === 0 ? null : <XIconButton name='arrow-circle-o-up' family='FontAwesome' onPress={() => this._sortUp(index)} />}
                {index + 1 === editingProductGroups.length ? null : (
                  <XIconButton name='arrow-circle-o-down' family='FontAwesome' onPress={() => this._sortDown(index)} />
                )}
              </HStack>
            ) : (
              <HStack pr='2' h='40px' alignItems='center'>
                <ForwardIcon />
              </HStack>
            )}
          </HStack>
        </XCard.Body>
      </XCard>
    )
  }

  _renderBody = () => {
    const { editingProductGroups, mode } = this.state
    if (_.isNil(editingProductGroups)) {
      return null
    }
    const isSortMode = mode === 'SORT'
    return (
      <XCard p='2' mt='2' w='full'>
        {isSortMode ? (
          <XText variant='inactive' textAlign='right' py='1'>
            บนสุดสำคัญที่สุด
          </XText>
        ) : null}
        <VStack space='2' w='full'>
          {editingProductGroups.map((pg: ProductGroup, index: number) => this._renderPGList(pg, index))}
        </VStack>
        {isSortMode ? (
          <XText variant='inactive' textAlign='right' py='1'>
            สำคัญน้อยที่สุด
          </XText>
        ) : null}
      </XCard>
    )
  }

  _renderBtnUpdateNewPriority = () => {
    const { mode, editingProductGroups } = this.state
    if (_.isNil(editingProductGroups)) {
      return null
    }
    const pgCount = editingProductGroups.length
    if (mode === 'SORT' || pgCount < 2) {
      return null
    }
    return (
      <XButton
        variant='outline'
        mt='8'
        onPress={() => this._onPressSortProduct()}
        leftIcon={<XIcon name='swap-vertical-bold' family='MaterialCommunityIcons' />}>
        <XText color={COLORS.APP_MAIN}>ลำดับความสำคัญใหม่</XText>
      </XButton>
    )
  }

  _renderSubmitting = () => (
    <XCard p='2' alignItems='center' w='full' justifyContent='center'>
      <XText variant='active' textAlign='center' w='full'>
        กำลังลำดับความสำคัญใหม่ กรุณารอสักครู่..
      </XText>
      <XText variant='active' textAlign='center' w='full'>
        (ขั้นตอนนี้อาจใช้เวลาหลายนาที)
      </XText>
    </XCard>
  )

  render() {
    const { submitting } = this.state
    return (
      <XContainer>
        {this._renderCustomHeader()}
        <XContent p='2'>
          {submitting ? (
            this._renderSubmitting()
          ) : (
            <VStack>
              {this._renderCardHeader()}
              {this._renderBody()}
              {this._renderBtnUpdateNewPriority()}
            </VStack>
          )}
        </XContent>
      </XContainer>
    )
  }
}
