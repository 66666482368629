import React from 'react'
import { View } from 'react-native'
import BaseUICustomerEditProfileView from 'xui/modules/customer/BaseUICustomerEditProfileView'
import { getConfig } from 'x/config/mode'
import { COLORS } from 'x/config/styles'
import * as util from 'x/utils/util'
import _ from 'lodash'
import ImgManager from '../../components/ImgManager'
import ThemeSizeContext from '../../context/ThemeSizeContext'

const config = getConfig()

export default class CustomerEditProfileView extends BaseUICustomerEditProfileView {
  static contextType = ThemeSizeContext

  context!: React.ContextType<typeof ThemeSizeContext>

  goBack = () => {
    util.navGoBack(this.props)
  }

  renderViewImgManager = () => {
    const { isImgUploading, mode } = this.state
    const { contentOneOfThreeColumnWidth } = this.context
    const widthForImg = contentOneOfThreeColumnWidth / 3
    // const canEdit = mode === `VIEW`
    return (
      <View style={{ width: '100%', height: 110, justifyContent: 'center', alignItems: 'center', backgroundColor: COLORS.WHITE }}>
        <View style={{ width: widthForImg }} />
        <View style={{ width: widthForImg, justifyContent: 'center', alignItems: 'center', marginLeft: 20 }}>
          <ImgManager
            ref={this.imgManagerRef}
            fileNamePrefix={config.s3_prefix_type_profile}
            metadata={[
              { key: 'p', width: 2000, height: 2000 },
              { key: 't', width: 1000, height: 1000 },
            ]}
            uploading={_.has(this.state, 'isImgUploading') ? isImgUploading : false}
            readonly
            showUploaded
            maxLength={1}
            shape='circle'
            disabledRemoveSingleImage
          />
        </View>
        <View style={{ width: widthForImg }} />
      </View>
    )
  }
}

// return (
//   <XContainer>
//     <XCustomHeader title={'ProfileView'} headerLeftProps={{ backIcon: true, onPressItem: () => navigation.goBack(navigation.state.key) }} />
//     <XContent style={{ backgroundColor: 'skyblue' }}>
//       <h2>ProfileView</h2>
//       <button onClick={() => {
//         navigation.push('ProfileView', {
//           x: Math.random(),
//         })
//       }}>Next ProfileView</button>
//       <button onClick={() => {
//         navigation.push('ProfileView', {
//           isModal: true,
//           x: Math.random(),
//         })
//       }}>Next ProfileView</button>
//       <button onClick={() => navigation.goBack(null)}>Go BACK</button>
//       <div>{`${JSON.stringify(navigation.state)}`}</div>
//     </XContent>
//   </XContainer>
// )
