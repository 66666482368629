import React from 'react'
import { STYLES, COLORS } from 'x/config/styles'

import { Text, View, TouchableOpacity } from 'react-native'
import _ from 'lodash'
import p from 'x/config/platform-specific'
import BaseReportRequisitionView from 'x/modules/report/BaseReportRequisitionView'
import XCustomHeader from 'xui/components/XCustomHeader'
import * as util from 'x/utils/util'
import HelpButton from 'xui/components/HelpButton'
import { VIEW_WIDTH_TEN } from 'xui/utils/BaseShareUI'
import XIcon from 'xui/components/XIcon'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import Segment from 'xui/components/Segment'
import ShippingTypeSelectorSegmentView from 'xui/components/ShippingTypeSelectorSegmentView'
import XCard from 'xui/components/XCard'
import XText from 'xui/components/XText'
import Box from 'xui/components/Box'
import XButton from 'xui/components/XButton'
import HStack from 'xui/components/HStack'

const { NO_MARGIN_PADDING, FONT_SIZE_NORMAL } = STYLES

const { TEXT_ACTIVE } = COLORS

const FLASH_EXPRESS_HELPER_TEXT = `ใช้ import เข้าโปรแกรมของ Flash โดยจะมีเพียงรายการออเดอร์ที่เลือกประเภทการจัดส่งด้วย Flash Express หรือ Flash Express [COD] เท่านั้น`
const KERRY_EXPRESS_HELPER_TEXT = `ใช้ import เข้าโปรแกรมของ Kerry โดยจะมีเพียงรายการออเดอร์ที่เลือกประเภทการจัดส่งด้วย Kerry Express หรือ Kerry Express [COD] เท่านั้น`

export default abstract class BaseUIReportRequisitionView extends BaseReportRequisitionView {
  static displayName = 'BaseUIReportRequisitionView'

  abstract _downloadReportRequisitionView(url: string, fileName: string): Promise<void>

  abstract renderDateRangePicker(): JSX.Element

  abstract renderDetailShippingTypeCardButton(data: any): void

  _handleOnDownloadFile = async (url: string, fileName: string) => {
    await this._downloadReportRequisitionView(url, fileName)
  }

  _renderTitleCard = (optKey: string) => {
    const txtTitle = this.TXT_TITLES[optKey]
    return (
      <XText variant='active' bold>
        {txtTitle}
      </XText>
    )
  }

  _renderCheckbox = (optKey: string) => {
    const txtDetail = this.TXT_CHECKBOX[optKey]
    // let isActive = true
    const oneStatus = 'กรุณาเลือกอย่างน้อย 1 สถานะ'
    return (
      <TouchableOpacity
        style={[
          STYLES.NO_MARGIN,
          {
            // paddingTop: 11,
            // paddingBottom: 11,
            paddingRight: 12,
            // paddingLeft: 12,
            height: 40,
            justifyContent: 'center',
            // backgroundColor: COLORS.BG_LIGHT_GREY,
          },
        ]}
        onPress={() => {
          if (!this._canRequestMoreColumns()) {
            p.op.alert(p.op.t('Subscription.warning.insufficientTypeTitle'), p.op.t('Subscription.warning.insufficientTypeMsg'))
            return
          }
          if (optKey === 'WAIT_TO_SHIP_ORDERS') {
            if (this.state.WAIT_TO_REFUND_ORDERS === false && this.state.WAIT_TO_SHIP_ORDERS === true) {
              p.op.alert(null, oneStatus)
              return
            }
          } else if (optKey === 'WAIT_TO_REFUND_ORDERS') {
            if (this.state.WAIT_TO_REFUND_ORDERS === true && this.state.WAIT_TO_SHIP_ORDERS === false) {
              p.op.alert(null, oneStatus)
              return
            }
          }

          // @ts-ignore
          this.setState({
            [optKey]: !this.state[optKey],
          })
        }}>
        <Box style={{ flex: 1, flexDirection: 'row' }}>
          <Box style={{ width: 30, justifyContent: 'center' }}>
            <XIcon name={this.state[optKey] ? 'check-square-o' : 'square-o'} family='FontAwesome' />
            {/* <FAIcon
              style={{
                flex: 1,
                fontSize: STYLES.FONT_ICON_NORMAL,
                color: COLORS.APP_MAIN,
              }}
              name={this.state[optKey] ? 'check-square-o' : 'square-o'}
            /> */}
          </Box>
          {VIEW_WIDTH_TEN}
          <Box style={{ flex: 1, justifyContent: 'center' }}>
            <XText allowFontScaling={false} textAlign='left' variant='active'>
              {txtDetail}
            </XText>
          </Box>
        </Box>
      </TouchableOpacity>
    )
  }

  _renderFooter() {
    const { loading } = this.state
    return (
      <HStack w='full' p='1'>
        <XButton
          py='3'
          w='full'
          disabled={loading}
          onPress={() => this._downloadReportRequisitionUrl()}
          leftIcon={<XIcon name='file-excel-o' family='FontAwesome' variant='primary' />}>
          ดาวน์โหลด Excel
        </XButton>
      </HStack>
    )
  }

  _renderRadio = (radioKey: string, showHelpText?: string) => {
    const txtDetail = this.TXT_RADIO[radioKey]
    const paddingTxtDetail = _.isString(showHelpText) ? 4 : 2
    return (
      <Box w='full' py='1'>
        <TouchableOpacity
          style={[
            STYLES.NO_MARGIN,
            {
              // paddingTop: 11,
              // paddingBottom: 11,
              paddingRight: 12,
              // paddingLeft: 12,
              // height: 45,
              justifyContent: 'center',
              // backgroundColor: COLORS.BG_LIGHT_GREY,
            },
          ]}
          onPress={() => {
            if (!this._canRequestMoreColumns()) {
              p.op.alert(p.op.t('Subscription.warning.insufficientTypeTitle'), p.op.t('Subscription.warning.insufficientTypeMsg'))
            } else if (radioKey === this.KEY_RADIO.BTN_RADIO_STANDARD) {
              if (this.state[radioKey]) {
              } else {
                // @ts-ignore
                this.setState({
                  [radioKey]: true,
                  BTN_RADIO_FLASG_EXPRESS: false,
                  BTN_RADIO_KERRY_EXPRESS_1: false,
                  BTN_RADIO_KERRY_EXPRESS_2: false,
                })
              }
            } else if (radioKey === this.KEY_RADIO.BTN_RADIO_FLASG_EXPRESS) {
              if (this.state[radioKey]) {
              } else {
                // @ts-ignore
                this.setState({
                  [radioKey]: true,
                  BTN_RADIO_STANDARD: false,
                  BTN_RADIO_KERRY_EXPRESS_1: false,
                  BTN_RADIO_KERRY_EXPRESS_2: false,
                })
              }
            } else if (radioKey === this.KEY_RADIO.BTN_RADIO_KERRY_EXPRESS_1) {
              if (this.state[radioKey]) {
              } else {
                // @ts-ignore
                this.setState({
                  [radioKey]: true,
                  BTN_RADIO_STANDARD: false,
                  BTN_RADIO_FLASG_EXPRESS: false,
                  BTN_RADIO_KERRY_EXPRESS_2: false,
                })
              }
            } else if (radioKey === this.KEY_RADIO.BTN_RADIO_KERRY_EXPRESS_2) {
              if (this.state[radioKey]) {
              } else {
                // @ts-ignore
                this.setState({
                  [radioKey]: true,
                  BTN_RADIO_STANDARD: false,
                  BTN_RADIO_FLASG_EXPRESS: false,
                  BTN_RADIO_KERRY_EXPRESS_1: false,
                })
              }
            }
          }}>
          <HStack flex={1} w='full'>
            <Box style={{ width: 30, justifyContent: 'center' }}>
              <XIcon
                family='Ionicons'
                // style={{
                //   fontSize: STYLES.FONT_ICON_NORMAL,
                //   color: COLORS.APP_MAIN,
                //   flex: 1,
                //   textAlign: 'left',
                // }}
                name={this.state[radioKey] ? 'radio-button-on' : 'radio-button-off'}
              />
            </Box>
            {/* {VIEW_WIDTH_TEN} */}
            <HStack flex={1} w='full' style={{ justifyContent: 'flex-start', alignItems: 'center' }}>
              <XText textAlign='left' variant='active'>
                {txtDetail}
              </XText>
              {_.isString(showHelpText) ? <HelpButton message={showHelpText} /> : null}
            </HStack>
          </HStack>
        </TouchableOpacity>
      </Box>
    )
  }

  _renderHeaderView = () => (
    <XCustomHeader
      title='รายงานออเดอร์ที่ยังไม่ได้จัดส่ง'
      headerLeftProps={{ backIcon: true, onPressItem: () => util.navGoBack(this.props) }}
    />
  )

  // _renderShippingType = () => {
  //   const { selectedShippingTypeId, optionShippingTypeIndex, BTN_RADIO_STANDARD } = this.state
  //   if (!BTN_RADIO_STANDARD) {
  //     return null
  //   }
  //   const selectedShippingTypeIndex =
  //     selectedShippingTypeId > 0 ? _.findIndex(this.shippingTypeItems, (sItem) => sItem.value === selectedShippingTypeId) : -1

  //   const handleChangeOrderShippingType = (itemInfo) => {
  //     const { item } = itemInfo
  //     if (!item || !item.value) {
  //       return
  //     }
  //     const newShippingTypeId = item.value
  //     util.setStatePromise(this, {
  //       selectedShippingTypeId: newShippingTypeId,
  //     })
  //     // onEditingOrderChange({ key: 'shipping_type_id', value: newShippingTypeId, orderTabIndex })
  //   }

  //   return (
  //     <View style={{ width: '100%' }}>
  //       {VIEW_HEIGHT_TEN}
  //       <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE, fontWeight: 'bold' }}>{`รูปแบบจัดส่ง`}</Text>
  //       <View style={{ height: 25 }}>
  //         <Segment
  //           onSegmentChange={(newIndex: number) => this._onChangeSegmentShippingIndex(newIndex)}
  //           options={this.OPTION_SHIPPING_TYPE}
  //           selectedIndex={optionShippingTypeIndex}
  //         />
  //       </View>
  //       {VIEW_HEIGHT_TEN}
  //       {/* <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE, fontWeight: 'bold' }}>{`รูปแบบจัดส่ง`}</Text> */}
  //       {optionShippingTypeIndex === 1 ? (
  //         <XFlatPicker
  //           // for CREATE mode only
  //           title={'เลือกรูปแบบจัดส่ง'}
  //           placeholder={'กรุณาเลือกรูปแบบจัดส่ง'}
  //           ref={this._shippingTypeCreateModePickerRef}
  //           selectedIndex={selectedShippingTypeIndex}
  //           options={this.shippingTypeItems}
  //           onPick={handleChangeOrderShippingType}
  //           // @ts-ignore
  //           renderSelectedValue={(data: any) => this.renderDetailShippingTypeCardButton(data)}
  //           renderItem={this._renderCommonFlatPickerItem}
  //         />
  //       ) : null}
  //     </View>
  //   )
  // }

  _renderDetailShippingTypeCardButton = (data) => {
    const { item, index } = data
    let txtShippingType = null
    if (index > -1) {
      txtShippingType = this.shippingTypeItems[index].label
    } else if (index === -1 && item && item.value > 0) {
      txtShippingType = 'กรุณาดาวน์โหลดแอปเวอร์ชั่นใหม่ เพื่อแสดงผลข้อมูลนี้'
    } else {
      txtShippingType = 'ยังไม่ได้เลือก'
    }
    return this.renderDetailShippingTypeCardButton(data)
    // return (
    //   <View style={{ width: '100%', flexDirection: 'row' }} key={index}>
    //     <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE }}>{txtShippingType}</Text>
    //   </View>
    // )
  }

  _renderCommonFlatPickerItem = (data) => {
    const { item, index } = data
    return (
      <View style={{ width: '100%', flexDirection: 'row', height: p.op.isWeb() ? 30 : 45, alignItems: 'center' }} key={index}>
        <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE, paddingLeft: 12 }}>{item.label}</Text>
      </View>
    )
  }

  _renderCustomHeaderShippingTypeSelectorSegment = () => {
    const viewHeaderWidth = p.op.isWeb() ? 300 : 360
    return (
      <Box style={{ width: viewHeaderWidth, alignItems: 'flex-start', justifyContent: 'center', height: 30 }}>
        <XText textAlign='left' bold variant='active'>
          รูปแบบจัดส่ง
        </XText>
      </Box>
    )
  }

  _renderShippingType = () => {
    const { selectedShippingTypeId } = this.state
    const getVisibleShippingTypesIds = util.getVisibleShippingTypesIds()
    const visibleShippingTypes =
      getVisibleShippingTypesIds.length < 1 ? util.getDefaultVisibleShippingTypesIds() : getVisibleShippingTypesIds
    return (
      <ShippingTypeSelectorSegmentView
        customHeaderSegment={() => this._renderCustomHeaderShippingTypeSelectorSegment()}
        footerLable='ยืนยัน'
        headertitle='เลือกรูปแบบจัดส่ง'
        activeShippingTypeIds={selectedShippingTypeId}
        visibleShippingTypes={visibleShippingTypes}
        onSubmit={(selectedShippingTypeIds: number[]) => {
          util.setStatePromise(this, {
            selectedShippingTypeId: selectedShippingTypeIds,
          })
        }}
      />
    )
  }

  render() {
    const { optionDateTimeIndex, BTN_RADIO_STANDARD } = this.state
    const IS_EXCEL_STANDARD = BTN_RADIO_STANDARD === true
    return (
      <XContainer>
        {this._renderHeaderView()}
        <XContent>
          <XCard m='2' p='2'>
            <XText variant='active' bold>
              ช่วงเวลาที่ออเดอร์พร้อมส่ง
            </XText>
            <Segment
              onSegmentChange={(newIndex: number) => this._showSeletedChannel(newIndex)}
              options={this.OPTION_DATE_TIME}
              selectedIndex={optionDateTimeIndex}
            />
            {this.renderDateRangePicker()}
          </XCard>
          <XCard m='2' mt='0' p='2'>
            {this._renderTitleCard('FORMAT_EXCEL')}
            {this._renderRadio(this.KEY_RADIO.BTN_RADIO_STANDARD)}
            {this._renderRadio(this.KEY_RADIO.BTN_RADIO_FLASG_EXPRESS, FLASH_EXPRESS_HELPER_TEXT)}
            {this._renderRadio(this.KEY_RADIO.BTN_RADIO_KERRY_EXPRESS_1, KERRY_EXPRESS_HELPER_TEXT)}
            {this._renderRadio(this.KEY_RADIO.BTN_RADIO_KERRY_EXPRESS_2, KERRY_EXPRESS_HELPER_TEXT)}
            <HStack h='2' />
            {IS_EXCEL_STANDARD ? this._renderTitleCard('ORDER_STATUS') : null}
            {IS_EXCEL_STANDARD ? this._renderCheckbox('WAIT_TO_SHIP_ORDERS') : null}
            {IS_EXCEL_STANDARD ? this._renderCheckbox('WAIT_TO_REFUND_ORDERS') : null}
            <HStack h='2' />
            {IS_EXCEL_STANDARD ? this._renderTitleCard('SHOW_MORE_DATA_EXCEL') : null}
            {IS_EXCEL_STANDARD ? this._renderCheckbox('COST') : null}
            {IS_EXCEL_STANDARD ? this._renderCheckbox('TOTAL_COST') : null}
            {/* // TOTAL_ */}
            {this._renderShippingType()}
          </XCard>
        </XContent>
        {this._renderFooter()}
      </XContainer>
    )
  }
}
