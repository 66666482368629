// import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { Image, ViewStyle, TextStyle, TouchableOpacity } from 'react-native'
import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import { COLORS, STYLES } from 'x/config/styles'
import { logRender } from 'x/utils/util'
import _ from 'lodash'
import p from 'x/config/platform-specific'
import XIcon from './XIcon'
import XSwitch from './XSwitch'
import XCard from './XCard'
import XText from './XText'

const { CARD_COMMON, NO_MARGIN_PADDING, FONT_ICON_NORMAL, TXT_LABEL } = STYLES
const { TEXT_INACTIVE } = COLORS

interface ISwitchCard {
  disabled?: boolean
  txtLabel: string
  txtLabelStyle?: ViewStyle
  txtHelp?: {
    title: string
    message: string
  }

  switchValue: boolean
  onToggle: (currentValue: boolean) => void

  additionalComponent?: () => JSX.Element

  prefixIcon?: {
    iconName: string
    iconStyle?: TextStyle
    // message: PropTypes.string.isRequired
  }
  prefixIconImg?: {
    iconImgName: any // require image รูปภาพ
  }

  mode: 'VIEW_EDIT' | 'EDIT'
}

export default class SwitchCard extends PureComponent<ISwitchCard> {
  // static propTypes = {
  //   disabled: PropTypes.bool,
  //   txtLabel: PropTypes.string.isRequired,
  //   txtLabelStyle: PropTypes.any,
  //   txtHelp: PropTypes.shape({
  //     title: PropTypes.string.isRequired,
  //     message: PropTypes.string.isRequired,
  //   }),

  //   switchValue: PropTypes.bool.isRequired,
  //   onToggle: PropTypes.func.isRequired,

  //   additionalComponent: PropTypes.node,

  //   prefixIcon: PropTypes.shape({
  //     iconName: PropTypes.string.isRequired,
  //     // message: PropTypes.string.isRequired,
  //   }),
  //   prefixIconImg: PropTypes.any,

  //   mode: PropTypes.any,
  // }

  static defaultProps = {
    disabled: false,
    txtLabel: 'Example Text Label',
    txtHelp: null,
    switchValue: false,
  }

  constructor(props) {
    super(props)
    this._onPressHelpButton = this._onPressHelpButton.bind(this)
  }

  _onPressHelpButton() {
    const { txtHelp } = this.props
    if (txtHelp && txtHelp.title && txtHelp.message) {
      p.op.alert(txtHelp.title, txtHelp.message)
    }
  }

  render() {
    logRender(this)
    const {
      txtLabel,
      txtHelp,
      switchValue,
      onToggle,
      disabled,
      additionalComponent,
      prefixIcon,
      prefixIconImg,
      mode,
      txtLabelStyle,
    } = this.props

    const ICON_IMG_STYLES = {
      width: 24,
      height: 24,
      // width: 25,
      // height: 17,
      // flex: 0,
      // marginRight: 12,
      // marginLeft: -2,
    }
    // const ICON_STYLES: any = { flex: 0, marginRight: 15, minWidth: 20, marginLeft: -2 }
    // if (!_.isNil(mode)) {
    //   if (mode === 'VIEW_EDIT' || mode === 'EDIT') {
    //     ICON_IMG_STYLES = {
    //       width: 22,
    //       height: 15,
    //       flex: 0,
    //       marginRight: 10,
    //       marginLeft: 3,
    //     }
    //     ICON_STYLES = {
    //       flex: 0,
    //       marginRight: 10,
    //       minWidth: 20,
    //       marginLeft: 6,
    //       fontSize: 18,
    //     }
    //   }
    // }
    return (
      <XCard>
        <XCard.Body>
          <HStack flexWrap='wrap' justifyContent='space-between' space='1'>
            <VStack flex={1} space='1'>
              <TouchableOpacity onPress={txtHelp ? this._onPressHelpButton : null}>
                <HStack flexWrap='wrap' alignItems='center' space='1'>
                  {!_.isNil(prefixIcon) ? (
                    <XIcon
                      inactive
                      name={prefixIcon.iconName}
                      family='MaterialCommunityIcons'
                      style={prefixIcon.iconStyle}
                      // style={_.isNil(prefixIcon.iconStyle) ? ICON_STYLES : prefixIcon.iconStyle}
                    />
                  ) : null}
                  {!_.isNil(prefixIconImg) ? (
                    <Image resizeMode='contain' style={ICON_IMG_STYLES} source={prefixIconImg.iconImgName} />
                  ) : null}
                  <XText
                    variant='inactive'
                    style={txtLabelStyle}
                    // style={_.isNil(txtLabelStyle) ? { fontSize: STYLES.FONT_SIZE_NORMAL, marginLeft: -5 } : txtLabelStyle}
                  >
                    {txtLabel}
                  </XText>
                  {txtHelp ? (
                    <XIcon
                      variant='inactive'
                      name='help-circle-outline'
                      // style={{
                      //   paddingTop: 4,
                      //   padding: 2,
                      //   paddingLeft: 4,
                      //   fontSize: FONT_ICON_NORMAL,
                      //   color: TEXT_INACTIVE,
                      // }}
                    />
                  ) : null}
                </HStack>
                {additionalComponent || null}
              </TouchableOpacity>
            </VStack>
            {/* <Switch
              // @ts-ignore
              trackColor={Platform.OS === 'ios' ? COLORS.APP_MAIN : COLORS.APP_SECONDARY}
              thumbColor={Platform.OS === 'ios' ? null : COLORS.APP_MAIN}
              // deprecated
              // onTintColor={ Platform.OS === 'ios' ? COLORS.APP_MAIN : COLORS.APP_SECONDARY }
              // deprecated
              // thumbTintColor={ Platform.OS === 'ios' ? null : COLORS.APP_MAIN }
              disabled={disabled}
              value={switchValue}
              onValueChange={val => onToggle(val)}
            /> */}
            <XSwitch
              // style={switchStype}
              disabled={disabled}
              value={switchValue}
              onValueChange={onToggle}
            />
          </HStack>
        </XCard.Body>
      </XCard>
    )
  }
}
