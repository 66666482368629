import React from 'react'
import { Image, View, ImageSourcePropType } from 'react-native'

import * as util from 'x/utils/util'
import p from 'x/config/platform-specific'
import { COLORS, STYLES } from 'x/config/styles'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContent from 'xui/components/XContent'
import XContainer from 'xui/components/XContainer'
import BaseStoreSettingXShippingEMSView from 'x/modules/xshipping/BaseStoreSettingXShippingEMSView'
import { VIEW_LOADING } from 'xui/utils/BaseShareUI'
import XSettingRow from 'xui/components/XSettingRow'
import XHelpModal from 'xui/components/XHelpModal'
import FAQ from 'x/config/FAQ'
import { IQuestionsAndAnswers } from 'x/index'
import Box from 'xui/components/Box'
import XText from 'xui/components/XText'
import XButton from 'xui/components/XButton'
import HStack from 'xui/components/HStack'
import XFaqModal from 'xui/components/XFaqModal'
import FAQ_CONSTANTS from 'x/config/FAQ_CONSTANTS'

const IMG_EMS: ImageSourcePropType = require('../../images/courier/logo_ems.png')
const IMG_SHIPJUNG: ImageSourcePropType = require('../../images/courier/shipjung.png')

export default abstract class BaseUIStoreSettingXShippingEMSView extends BaseStoreSettingXShippingEMSView {
  static displayName = 'BaseUIStoreSettingXShippingEMSView'
  // abstract goBack: () => void

  _renderCustomHeader = () => (
    <XCustomHeader
      headerLeftProps={{
        backIcon: true,
        onPressItem: () => {
          util.navGoBack(this.props)
        },
      }}
      title={p.op.t('Order.shippingTypeItems.ems')}
    />
  )

  _renderUseEMSxShipjung = () => {
    const {
      useEMSxShipjung,
      useAutoXShippingIsEMSxShipjungReadyToShip,
      isEMSxShipjungLoading,
      loadingEMSxShipjungHeight,
      loadingEMSxShipjungWidth,
    } = this.state
    const textTitle = 'ใช้งานการขอเลขพัสดุ\n"แบบไม่เก็บเงินปลายทาง"'
    // console.log('useAutoXShippingIsJAndTReadyToShip => ', useAutoXShippingIsJAndTReadyToShip)
    return (
      <View
        onLayout={(event) => {
          const { x, y, width, height } = event.nativeEvent.layout
          util.setStatePromise(this, { loadingEMSxShipjungWidth: width, loadingEMSxShipjungHeight: height })
        }}>
        <XSettingRow
          title={textTitle}
          value={useEMSxShipjung}
          onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
          stateKey='useEMSxShipjung'
          helper={{
            title: 'EMS ออกเลขพัสดุ',
            FAQ: FAQ.XSHIPPING_NOT_COD,
            initiateOpenIndex: 0,
          }}
          backgroundColor={COLORS.APP_SECONDARY}
          // disabled={useJAndT}
        />

        {useEMSxShipjung ? (
          <XSettingRow
            title='ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง'
            value={useAutoXShippingIsEMSxShipjungReadyToShip}
            onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
            stateKey='useAutoXShippingIsEMSxShipjungReadyToShip'
            helper={{
              title: 'ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง',
              FAQ: FAQ.XSHIPPING_READY_TO_SHIP,
              initiateOpenIndex: 0,
            }}
            backgroundColor={COLORS.APP_SECONDARY}
          />
        ) : null}

        {isEMSxShipjungLoading ? VIEW_LOADING(loadingEMSxShipjungWidth, loadingEMSxShipjungHeight) : null}
      </View>
    )
  }

  _renderUseEMSDirect = () => {
    const { useEMS, useAutoShippingIsEMSReadyToShip, isEMSLoading, loadingEMSHeight, loadingEMSWidth } = this.state
    const textTitle = 'ใช้งานการขอเลขพัสดุ\n"แบบไม่เก็บเงินปลายทาง"'
    // console.log('useAutoXShippingIsJAndTReadyToShip => ', useAutoXShippingIsJAndTReadyToShip)
    return (
      <View
        onLayout={(event) => {
          const { x, y, width, height } = event.nativeEvent.layout
          util.setStatePromise(this, { loadingEMSWidth: width, loadingEMSHeight: height })
        }}>
        <XSettingRow
          title={textTitle}
          value={useEMS}
          onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
          stateKey='useEMS'
          helper={{
            title: 'EMS ออกเลขพัสดุ',
            FAQ: FAQ.XSHIPPING_NOT_COD,
            initiateOpenIndex: 0,
          }}
          backgroundColor={COLORS.APP_SECONDARY}
          // disabled={useJAndT}
        />

        {useEMS ? (
          <XSettingRow
            title='ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง'
            value={useAutoShippingIsEMSReadyToShip}
            onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
            stateKey='useAutoShippingIsEMSReadyToShip'
            helper={{
              title: 'ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง',
              FAQ: FAQ.XSHIPPING_READY_TO_SHIP,
              initiateOpenIndex: 0,
            }}
            backgroundColor={COLORS.APP_SECONDARY}
          />
        ) : null}

        {isEMSLoading ? VIEW_LOADING(loadingEMSWidth, loadingEMSHeight) : null}
      </View>
    )
  }

  _renderUseEMSCodDirect = () => {
    const {
      useEMSCod,
      useAutoShippingIsEMSCodReadyToShip,
      useAutoCreateCODPaymentWhenDelivered,
      isEMSCodLoading,
      loadingEMSCodHeight,
      loadingEMSCodWidth,
    } = this.state
    const textTitle = 'ใช้งานการขอเลขพัสดุ\n"แบบเก็บเงินปลายทาง"'
    // console.log('useAutoXShippingIsJAndTReadyToShip => ', useAutoXShippingIsJAndTReadyToShip)
    return (
      <View
        onLayout={(event) => {
          const { x, y, width, height } = event.nativeEvent.layout
          util.setStatePromise(this, { loadingEMSCodWidth: width, loadingEMSCodHeight: height })
        }}>
        <XSettingRow
          title={textTitle}
          value={useEMSCod}
          onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
          stateKey='useEMSCod'
          helper={{
            title: 'EMS ออกเลขพัสดุ',
            FAQ: FAQ.XSHIPPING_COD,
            initiateOpenIndex: 0,
          }}
          backgroundColor={COLORS.APP_SECONDARY}
          // disabled={useJAndT}
        />

        {useEMSCod ? (
          <XSettingRow
            title='ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง'
            value={useAutoShippingIsEMSCodReadyToShip}
            onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
            stateKey='useAutoShippingIsEMSCodReadyToShip'
            helper={{
              title: 'ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง',
              FAQ: FAQ.XSHIPPING_READY_TO_SHIP,
              initiateOpenIndex: 0,
            }}
            backgroundColor={COLORS.APP_SECONDARY}
          />
        ) : null}

        {useEMSCod ? (
          <XSettingRow
            title='บันทึกรับชำระอัตโนมัติเมื่อส่งพัสดุถึงผู้รับแล้ว'
            value={useAutoCreateCODPaymentWhenDelivered}
            onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
            stateKey='useAutoCreateCODPaymentWhenDelivered'
            helper={{
              title: 'บันทึกรับชำระอัตโนมัติเมื่อส่งพัสดุถึงผู้รับแล้ว',
              FAQ: FAQ.XSHIPPING_AUTO_CREATE_COD_PAYMENT_WHEN_DELIVERED,
              initiateOpenIndex: 0,
            }}
            backgroundColor={COLORS.APP_SECONDARY}
          />
        ) : null}

        {isEMSCodLoading ? VIEW_LOADING(loadingEMSCodWidth, loadingEMSCodHeight) : null}
      </View>
    )
  }

  _renderAgreement = (onPressOpenOverlay: () => void) => (
    <XButton onPress={() => onPressOpenOverlay()} w='48' variant='outline' alignSelf='center'>
      <XText variant='primary'>ดูข้อตกลงและวิธีการใช้งาน</XText>
    </XButton>
  )

  _getFAQAgreementForEMSxShipjung = (): IQuestionsAndAnswers[] => {
    const newFQA = []
    FAQ.XSHIPPING_AGREEMENT_EMS_X_SHIPJUNG.map((faq: IQuestionsAndAnswers) => {
      newFQA.push(faq)
    })
    return newFQA
  }

  _getFAQAgreementForEMSDirect = (): IQuestionsAndAnswers[] => {
    const newFQA = []
    FAQ.XSHIPPING_AGREEMENT_EMS_AGREEMENT.map((faq: IQuestionsAndAnswers) => {
      newFQA.push(faq)
    })
    return newFQA
  }

  _renderHowtoFaq = (onPressOpenOverlay: () => void) => (
    <HStack flex={1} alignItems='center' justifyContent='center' mt='2'>
      <XText textDecorationLine='underline' color={COLORS.BRAND_Info} onPress={() => onPressOpenOverlay()}>
        ดูคลิปแนะนำวิธีเปิดใช้งานขอเลขส่ง EMS
      </XText>
    </HStack>
  )

  _renderEMSxShipjung = () => (
    <Box
      _light={{ bg: 'white' }}
      _dark={{ bg: 'gray.500' }}
      borderRadius='md'
      style={{
        // backgroundColor: COLORS.WHITE,
        marginTop: 10,
        marginRight: 6,
        marginLeft: 6,
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 6,
        paddingBottom: 15,
      }}>
      <Box style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
        <Image
          source={IMG_EMS}
          resizeMode='stretch'
          style={{
            // position: 'absolute',
            width: 103,
            height: 20,
            backgroundColor: 'transparent',
            zIndex: 70,
            marginLeft: 0,
          }}
        />
        <XText style={{ fontSize: STYLES.FONT_SIZE_LARGER, color: COLORS.TEXT_ACTIVE, paddingRight: 10, paddingLeft: 20 }}>X</XText>
        <Image
          source={IMG_SHIPJUNG}
          resizeMode='stretch'
          style={{
            // position: 'absolute',
            width: 90,
            height: 58,
            backgroundColor: 'transparent',
            zIndex: 70,
          }}
        />
      </Box>
      <Box style={{ justifyContent: 'center', alignItems: 'center' }}>
        <XText variant='active'>ขอเลขพัสดุจาก EMS ผ่าน Shipjung</XText>
      </Box>

      <XHelpModal
        key='XHelpModalEMS'
        headerTitle='ข้อตกลงและวิธีการใช้งาน'
        FAQ={this._getFAQAgreementForEMSxShipjung()}
        initiateOpenIndex={5}
        renderButton={(onPressOpenOverlay: () => void) => this._renderAgreement(onPressOpenOverlay)}
      />

      <Box style={{ height: 10 }} />
      <Box style={{ paddingBottom: 2, flexDirection: 'row' }}>
        <XText variant='inactive'>{'รูปแบบจัดส่ง: '}</XText>
        <XText variant='active' bold>{`${p.op.t('Order.shippingTypeItems.ems')}`}</XText>
      </Box>
      {this._renderUseEMSxShipjung()}
      <Box style={{ height: 10 }} />
    </Box>
  )

  _renderEMSDirect = () => (
    <Box
      _light={{ bg: 'white' }}
      _dark={{ bg: 'gray.500' }}
      borderRadius='md'
      style={{
        // backgroundColor: COLORS.WHITE,
        marginTop: 10,
        marginRight: 6,
        marginLeft: 6,
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 6,
        paddingBottom: 15,
      }}>
      <Box mt='3' style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
        <Image
          source={IMG_EMS}
          resizeMode='stretch'
          style={{
            // position: 'absolute',
            width: 103,
            height: 20,
            backgroundColor: 'transparent',
            zIndex: 70,
            marginLeft: 0,
          }}
        />
      </Box>
      <Box mt='2' style={{ justifyContent: 'center', alignItems: 'center' }}>
        <XText variant='active'>ขอเลขพัสดุจาก EMS โดยตรง</XText>
      </Box>

      <XHelpModal
        key='XHelpModalEMS'
        headerTitle='ข้อตกลงและวิธีการใช้งาน'
        FAQ={this._getFAQAgreementForEMSDirect()}
        initiateOpenIndex={3}
        renderButton={(onPressOpenOverlay: () => void) => this._renderAgreement(onPressOpenOverlay)}
      />

      <XFaqModal
        key='XHelpModalEMS_HowTo'
        headerTitle='ดูคลิปแนะนำวิธีเปิดใช้งานขอเลขส่ง EMS'
        FAQ={FAQ_CONSTANTS.FAQ_EMS_AND_ECOPOST_XSHIPPING}
        initiateOpenIndex={0}
        renderButton={(onPressOpenOverlay: () => void) => this._renderHowtoFaq(onPressOpenOverlay)}
      />

      <Box style={{ height: 10 }} />
      <Box style={{ paddingBottom: 2, flexDirection: 'row' }}>
        <XText variant='inactive'>{'รูปแบบจัดส่ง: '}</XText>
        <XText variant='active' bold>{`${p.op.t('Order.shippingTypeItems.ems')}`}</XText>
      </Box>
      {this._renderUseEMSDirect()}
      <Box style={{ height: 10 }} />
      <Box style={{ paddingBottom: 2, flexDirection: 'row' }}>
        <XText variant='inactive'>{'รูปแบบจัดส่ง: '}</XText>
        <XText variant='active' bold>{`${p.op.t('Order.shippingTypeItems.ems_cod')}`}</XText>
      </Box>
      {this._renderUseEMSCodDirect()}
    </Box>
  )

  render() {
    return (
      <XContainer>
        {this._renderCustomHeader()}
        <XContent>
          <Box w='full'>
            {this._renderEMSDirect()}
            {this._renderEMSxShipjung()}
          </Box>
        </XContent>
      </XContainer>
    )
  }
}
