import React from 'react'

// import p from 'x/config/platform-specific'
import BaseERPPaymentAccountView from 'x/modules/erp/BaseERPPaymentAccountView'
import XCustomHeader from 'xui/components/XCustomHeader'
// import { Input } from 'react-native-elements'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import XCard from 'xui/components/XCard'
import HelpButton from 'xui/components/HelpButton'
import XText from 'xui/components/XText'
import HStack from 'xui/components/HStack'
import XFaqModal from 'xui/components/XFaqModal'
import FAQ_CONSTANTS from 'x/config/FAQ_CONSTANTS'
import BankIcon from 'xui/components/BankIcon'
import VStack from 'xui/components/VStack'
import XIcon from 'xui/components/XIcon'
import { COLORS } from 'x/config/styles'
import { IPaymentAccount } from 'x/index'
import _ from 'lodash'
import CONS from 'x/config/constants'
import p from 'x/config/platform-specific'

const _renderAgreement = (onPressOpenOverlay: () => void) => <HelpButton onPress={() => onPressOpenOverlay()} />
// const VIEW_WIDTH_TEN = <View style={{ width: 10 }} />

export default abstract class BaseUIERPPaymentAccountView extends BaseERPPaymentAccountView {
  renderCustomHeader = () => {
    const {} = this.state
    return (
      <XCustomHeader
        title='บัญชีรับชำระ'
        headerLeftProps={{ backIcon: true, onPressItem: () => this.goBack() }}
        // headerRightProps={{ label: 'ยกเลิก', onPressItem: () => this.goBack() }}
      />
    )
  }

  _renderTitle = () => {
    const {} = this.state
    return (
      <XCard p='2' w='full'>
        <HStack alignItems='center' w='full'>
          <HStack flex={1}>
            <XText>ระบุบัญชีรับชำระและรหัสบัญชีที่ต้องการให้ XSelly ใช้เชื่อมข้อมูลไปยัง PEAK</XText>
          </HStack>
          <HStack w='8'>
            <XFaqModal
              key='บัญชีรับชำระ'
              headerTitle='บัญชีรับชำระ'
              // @ts-ignore
              FAQ={FAQ_CONSTANTS.FAQ_ERP_PAYMENT_ACCOUNT}
              initiateOpenIndex={[0]}
              renderButton={(onPressOpenOverlay: () => void) => _renderAgreement(onPressOpenOverlay)}
            />
          </HStack>
        </HStack>
      </XCard>
    )
  }

  _renderPaymentAccount = (account: IPaymentAccount, idx: number) => {
    const {} = this.state
    // if (util.isSystemBankID(account.bank_id)) {
    //   return null
    // }
    let bankName = null
    let bankNameKey
    const foundBankIndex = CONS.BANK_INFO.findIndex((sBank) => sBank.id === account.bank_id)
    if (foundBankIndex > -1) {
      bankNameKey = CONS.BANK_INFO[foundBankIndex].key
    } else {
      bankNameKey = CONS.BANK_INFO[0].key
    }
    bankName = p.op.t(`Bank.${bankNameKey}`)

    let textBindAccount = 'ยังไม่ได้ผูกรหัสบัญชั PEAK'
    let textBindAccountColor = COLORS.RED
    const haveBindingAccount = this._getBindAccount(account.id)
    if (!_.isNil(haveBindingAccount)) {
      textBindAccount = 'ผูกรหัสบัญชั PEAK แล้ว'
      textBindAccountColor = COLORS.GREEN
    }
    return (
      <XCard
        p='2'
        // mx='2'
        mt={idx > 0 ? '2' : '0'}
        onPress={() => this._navToERPEditPaymentAccountView(haveBindingAccount, account)}
        key={`account_${idx}`}>
        <HStack>
          <HStack flex={1} alignItems='center'>
            <BankIcon bankId={account.bank_id} />
            <XText ml='2'>{bankName}</XText>
          </HStack>
          <VStack flex={1} justifyContent='center' alignItems='flex-end'>
            <XText ml='2'>{account.name}</XText>
            <XText ml='2'>{account.account_number}</XText>
            <XText ml='2' color={textBindAccountColor}>
              {textBindAccount}
            </XText>
          </VStack>
          <HStack alignItems='center' justifyContent='center' w='10'>
            <XIcon name='arrow-forward' family='Ionicons' />
          </HStack>
        </HStack>
      </XCard>
    )
  }

  _renderPaymentListView = () => {
    const { payments } = this.state
    if (_.isNil(payments)) {
      return null
    }
    return <VStack my='2'>{payments.map((acc, idx) => this._renderPaymentAccount(acc, idx))}</VStack>
  }

  render() {
    return (
      <XContainer>
        {this.renderCustomHeader()}
        <XContent p='2'>
          {this._renderTitle()}
          {this._renderPaymentListView()}
        </XContent>
      </XContainer>
    )
  }
}
