import React from 'react'
import XContent from 'xui/components/XContent'
import XCustomHeader from 'xui/components/XCustomHeader'
import BaseStorePaymentAccountListView from 'x/modules/payment/BaseStorePaymentAccountListView'
import XContainer from 'xui/components/XContainer'
import * as NavActions from 'x/utils/navigation'
import { IPaymentAccount } from 'x/types'
import PaymentAccountCard from 'xui/components/PaymentAccountCard'
import VStack from 'xui/components/VStack'
import * as util from 'x/utils/util'
import _ from 'lodash'
import XSpinner from 'xui/components/XSpinner'
import HStack from 'xui/components/HStack'

export default class BaseUIStorePaymentAccountListView extends BaseStorePaymentAccountListView {
  // static displayName = 'BaseUIStoreSettingXShippingView'
  // abstract goBack: () => void

  renderCustomHeader = () => (
    <XCustomHeader
      headerLeftProps={this.isGoBackButtonHidden() ? undefined : { backIcon: true, onPressItem: this.goBack }}
      headerRightProps={this.isAddButtonHidden() ? undefined : { addIcon: true, onPressItem: this.navToAddBankAccount }}
      title={this.getHeaderTitle()}
    />
  )

  navToAddBankAccount = () => {
    const { selectedStore, navigation } = this.props
    const store_id = selectedStore.get('id')
    navigation.dispatch(
      NavActions.navToBankEditView({
        store_id,
        account: null,
        succeedCallback: this.onSuccessAddPaymentAccount,
      })
    )
  }

  navToEditBankAccount = (paymentAccount: IPaymentAccount) => {
    const { selectedStore, navigation } = this.props
    navigation.dispatch(
      NavActions.navToBankEditView({
        store_id: selectedStore.get('id'),
        account: paymentAccount,
        // succeedCallback: (accounts: IPaymentAccount[]) => {
        //   this.setState({ paymentAccounts: accounts })
        // },
        succeedCallback: this.onSuccessEditPaymentAccount,
      })
    )
  }

  onSuccessAddPaymentAccount = (accounts: IPaymentAccount[]) => {
    this.setState({ paymentAccounts: accounts })
  }

  onSuccessEditPaymentAccount = (accounts: IPaymentAccount[]) => {
    this.setState({ paymentAccounts: accounts })
  }

  // เมื่อกดเลือกบัญชี
  onPressPaymentAccountCard = async (pressedPaymentAccount: IPaymentAccount): Promise<void> => {
    const { navigation } = this.props

    const onPressPaymentAccountCardCallback = util.getNavParam(this.props, 'onPressPaymentAccountCard')
    if (_.isFunction(onPressPaymentAccountCardCallback)) {
      const shouldGoBack = await onPressPaymentAccountCardCallback(pressedPaymentAccount)

      if (shouldGoBack) {
        this.goBack()
      }
      return
    }

    // default pressed
    this.navToEditBankAccount(pressedPaymentAccount)
  }

  renderPaymentAccountCardItem = (account: IPaymentAccount, index: number) => {
    if (util.isSystemBankID(account.bank_id)) {
      return null
    }
    return (
      <PaymentAccountCard key={`PaymentAccountCardItem_${index}`} onPress={this.onPressPaymentAccountCard} data={account} showForwardIcon />
    )
  }

  renderPaymentAccountCardItems = () => {
    const { paymentAccounts = [] } = this.state
    if (!paymentAccounts || paymentAccounts.length === 0) {
      return null
    }

    return (
      <VStack space='2' m='2'>
        {paymentAccounts.map(this.renderPaymentAccountCardItem)}
      </VStack>
    )
  }

  renderContent = () => {
    const { isRefreshing } = this.state
    return (
      <XContent>
        <VStack w='full'>
          {this.renderPaymentAccountCardItems()}
          {isRefreshing ? (
            <HStack w='full' h='80px' alignItems='center' justifyContent='center'>
              <XSpinner variant='inactive' />
            </HStack>
          ) : null}
        </VStack>
      </XContent>
    )
  }

  renderMain = () => (
    <XContainer>
      {this.renderCustomHeader()}
      {this.renderContent()}
    </XContainer>
  )

  render() {
    return this.renderMain()
  }
}
