import React from 'react'

import { connect } from 'react-redux'
import { getSelectedStore, getEditingStore } from 'x/redux/selectors'

import VStack from 'xui/components/VStack'

import { fetchPaymentAccounts, fetchVerifySlipDetail } from 'x/utils/api'
import * as util from 'x/utils/util'
import { logRender } from 'x/utils/util'
import * as xFmt from 'x/utils/formatter'

import XText from 'xui/components/XText'
import XCustomHeader from 'xui/components/XCustomHeader'
import { IVerifySlipDetailViewProps, IVerifySlip, IPaymentAccount } from 'x/types'
import XSpinner from 'xui/components/XSpinner'
import { RefreshControl } from 'react-native'
import XContent from 'xui/components/XContent'
import HStack from 'xui/components/HStack'
import _ from 'lodash'
import time from 'x/utils/time'
import XButton from 'xui/components/XButton'
import XGalleryModal from 'xui/components/XGalleryModal'
import XContainer from 'xui/components/XContainer'
import BankIcon from 'xui/components/BankIcon'

interface IState {
  isInitialized?: boolean
  isRefreshing?: boolean
  isSlipImageShow?: boolean

  verifySlipDetail?: IVerifySlip
  paymentAccounts: IPaymentAccount[]
}

class VerifySlipDetailView extends React.Component<IVerifySlipDetailViewProps, IState> {
  static displayName = 'VerifySlipDetailView'

  isFetching?: boolean

  constructor(props) {
    super(props)

    this.state = {
      isInitialized: false,
      isRefreshing: false,
      isSlipImageShow: false,

      verifySlipDetail: null,
      paymentAccounts: [],
    }
  }

  async componentDidMount() {
    await this._initPaymentAccount()
    await util.setStatePromise(this, { isInitialized: true })
    await this._onRefresh()
  }

  _initPaymentAccount = async () => {
    if (this.isFetching) {
      return
    }
    this.isFetching = true

    const store_id = util.getNavParam(this.props, 'store_id')

    try {
      const paymentAccounts = await fetchPaymentAccounts({ store_id })
      await util.setStatePromise(this, { paymentAccounts })
    } catch (err) {
      console.log('_initPaymentAccount err', err)
    }

    await util.setStatePromise(this, { isRefreshing: false })
    this.isFetching = false
  }

  _onRefresh = async () => {
    if (this.isFetching) {
      return
    }
    this.isFetching = true
    await util.setStatePromise(this, { isRefreshing: true })

    const store_id = util.getNavParam(this.props, 'store_id')
    const verify_slip_id = util.getNavParam(this.props, 'verify_slip_id')

    try {
      const verifySlipDetail = await fetchVerifySlipDetail({ store_id, verify_slip_id })
      // console.log('_onRefresh verifySlipDetail => ', verifySlipDetail)
      await util.setStatePromise(this, { verifySlipDetail })
    } catch (err) {
      console.log('_onRefresh err', err)
    }

    await util.setStatePromise(this, { isRefreshing: false })
    this.isFetching = false
  }

  _goBack = () => {
    util.navGoBack(this.props)
  }

  renderCustomHeader = () => {
    // return null
    const { navigation } = this.props

    return (
      <XCustomHeader
        // headerStyle={fixedAndroidMarginTopStyle}
        headerLeftProps={{ backIcon: true, onPressItem: this._goBack }}
        title='ข้อมูลสลิป'
      />
    )
  }

  openSlipImageModal = () => {
    const { isRefreshing } = this.state
    if (isRefreshing) {
      return
    }
    this.setState({ isSlipImageShow: true })
  }

  closeSlipImageModal = () => {
    const { isRefreshing } = this.state
    if (isRefreshing) {
      return
    }
    this.setState({ isSlipImageShow: false })
  }

  getReceivingStorePaymentAccountFromReceivingPaymentId = (receiving_payment_account_id: number) => {
    if (!receiving_payment_account_id) {
      return null
    }

    const { paymentAccounts } = this.state

    if (!_.isArray(paymentAccounts) || paymentAccounts.length === 0) {
      return null
    }

    const pa = paymentAccounts.find((pai) => pai.id === receiving_payment_account_id)

    if (pa && pa.bank_id) {
      return pa
    }

    return null
  }

  renderVerifySlipDetail = () => {
    const { isInitialized, isRefreshing, verifySlipDetail } = this.state

    if (isRefreshing) {
      return (
        <HStack w='full' h='150px' alignItems='center' justifyContent='center'>
          <XSpinner />
        </HStack>
      )
    }

    if (!isInitialized) {
      return null
    }

    if (_.isNil(verifySlipDetail)) {
      return null
    }

    const {
      verify_slip_id,
      create_payment_validation_status,
      amount,
      sending_bank_code,
      receiving_bank_code,
      sender,
      receiver,
      transaction_ref,
      transaction_time,
      receiving_payment_account_id,
    } = verifySlipDetail

    const hasReceiverInfo = _.isString(transaction_time) && _.isObject(receiver) && _.isString(receiver.name)
    const hasSenderInfo = _.isString(transaction_time) && _.isObject(sender) && _.isString(sender.account_number)

    const sendingBankId = util.convertVerifySlipBankCodeToBankId(sending_bank_code)
    const sendingBankName = util.getBankNameFromBankID(sendingBankId)

    const receivingBankId = util.convertVerifySlipBankCodeToBankId(receiving_bank_code) || 1
    const receivingBankName = util.getBankNameFromBankID(receivingBankId)

    const rspa = this.getReceivingStorePaymentAccountFromReceivingPaymentId(receiving_payment_account_id)

    return (
      <VStack w='full' px='2.5' py='3' space='1'>
        {/* <XText>{JSON.stringify(verifySlipDetail)}</XText> */}

        {_.isNumber(amount) ? (
          <HStack w='full' alignItems='center' justifyContent='flex-end'>
            <XText w='160px' variant='inactive'>
              ยอดสลิป
            </XText>
            <XText flex={1} textAlign='right' bold>
              {xFmt.formatDecimal(amount)}
            </XText>
          </HStack>
        ) : null}

        <HStack w='full' pb='1.5' alignItems='center' justifyContent='flex-end'>
          <XButton h='34px' w='70px' variant='outline' onPress={this.openSlipImageModal}>
            ดูสลิป
          </XButton>
        </HStack>

        {/* <HStack w='full' py='1.5' justifyContent='flex-end' bg='#fafafa'>
          <XText w='160px' variant='inactive'>
            สถานะการยืนยันชำระ
          </XText>
          <XText
            flex={1}
            textAlign='right'
            color={create_payment_validation_status === 'SUCCESS' ? '#60BA60' : COLORS.BRAND_Danger}
            bold={create_payment_validation_status === 'SUCCESS'}>
            {p.op.t(`verifySlipStatus.${create_payment_validation_status}`)}
          </XText>
        </HStack> */}

        {_.isString(transaction_time) ? (
          <HStack w='full' py='1.5' alignItems='center' justifyContent='flex-end'>
            <XText w='160px' variant='inactive'>
              เวลาที่ทำรายการ
            </XText>
            <XText flex={1} textAlign='right'>
              {time.convertServerDateTimeToReadableDateTimeText(transaction_time)}
            </XText>
          </HStack>
        ) : null}

        {hasSenderInfo ? (
          <VStack w='full' py='1.5' bg='#fafafa'>
            <XText variant='inactive'>บัญชีผู้ชำระ</XText>
            <HStack w='full' alignItems='center' justifyContent='flex-end' space='1.5'>
              <HStack w='180px' p='2' alignItems='center' space='1.5' borderRadius='md'>
                <BankIcon bankId={sendingBankId} />
                <XText variant='inactive'>{sendingBankName}</XText>
              </HStack>
              <VStack flex={1} justifyContent='center' alignItems='flex-end'>
                <XText textAlign='right'>{sender.display_name}</XText>
                <XText textAlign='right'>{sender.account_number}</XText>
              </VStack>
            </HStack>
          </VStack>
        ) : null}

        {/* {hasSenderInfo && ((hasReceiverInfo && _.isNil(rspa)) || (_.isObject(rspa) && _.isNumber(rspa.bank_id))) ? (
          <HStack pl='18px' alignItems='center'>
            <XIcon name='long-arrow-down' variant='inactive' family='FontAwesome' />
          </HStack>
        ) : null} */}

        {hasReceiverInfo && _.isNil(rspa) ? (
          <VStack w='full' py='1.5'>
            <XText variant='inactive'>บัญชีผู้รับชำระ</XText>
            <HStack w='full' alignItems='center' justifyContent='flex-end' space='1.5'>
              <HStack w='180px' p='2' alignItems='center' space='1.5' borderRadius='md'>
                <BankIcon bankId={receivingBankId} />
                <XText variant='inactive'>{receivingBankName}</XText>
              </HStack>

              <VStack flex={1} justifyContent='center' alignItems='flex-end'>
                <XText textAlign='right'>{receiver.display_name}</XText>
                <XText textAlign='right'>{receiver.account_number}</XText>
              </VStack>
            </HStack>
          </VStack>
        ) : null}

        {_.isObject(rspa) && _.isNumber(rspa.bank_id) ? (
          <VStack w='full' py='1.5'>
            <XText variant='inactive'>บัญชีผู้รับชำระ</XText>
            <HStack w='full' alignItems='center' justifyContent='flex-end' space='1.5'>
              <HStack w='180px' p='2' alignItems='center' space='1.5' borderRadius='md'>
                <BankIcon bankId={rspa.bank_id} />
                <XText variant='inactive'>{util.getBankNameFromBankID(rspa.bank_id)}</XText>
              </HStack>

              <VStack flex={1} justifyContent='center' alignItems='flex-end'>
                <XText textAlign='right'>{rspa.name}</XText>
                <XText textAlign='right'>{rspa.account_number}</XText>
              </VStack>
            </HStack>
          </VStack>
        ) : null}

        <HStack w='full' py='1.5' alignItems='center' justifyContent='flex-end' bg='#fafafa'>
          <XText w='160px' variant='inactive'>
            รหัสอ้างอิงธุรกรรม
          </XText>
          <XText flex={1} textAlign='right'>
            {transaction_ref}
          </XText>
        </HStack>

        <HStack w='full' py='1.5' alignItems='center' justifyContent='flex-end'>
          <XText w='160px' variant='inactive'>
            รหัสอ้างอิงการตรวจสลิป
          </XText>
          <XText flex={1} textAlign='right'>
            {verify_slip_id}
          </XText>
        </HStack>
      </VStack>
    )
  }

  renderSlipImageModal = () => {
    const { isRefreshing, verifySlipDetail: vsd, isSlipImageShow } = this.state
    if (isRefreshing) {
      return null
    }

    if (!vsd || !_.isString(vsd.img_url)) {
      return null
    }

    return (
      <XGalleryModal
        images={[util.prependHttpsIfNotExists(vsd.img_url)]}
        isVisible={isSlipImageShow}
        onRequestClose={this.closeSlipImageModal}
      />
    )
  }

  renderMain = () => {
    const { isRefreshing } = this.state

    return (
      <XContainer>
        {this.renderCustomHeader()}

        <XContent refreshControl={<RefreshControl onRefresh={this._onRefresh} refreshing={isRefreshing} />}>
          <VStack w='full' p='1.5' bg='white'>
            {this.renderVerifySlipDetail()}
          </VStack>
        </XContent>

        {this.renderSlipImageModal()}
      </XContainer>
    )
  }

  render() {
    logRender(this)
    return this.renderMain()
  }
}

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
    editingStore: getEditingStore(state),
    // subscription: getSubscription(state),
  }),
  (dispatch) => ({
    dispatch,
    // submitEditing: bindActionCreators(StoreActions.submitEditingStore, dispatch),
    // resetEditingStore: bindActionCreators(StoreActions.resetEditingStore, dispatch),
    // storeActions: bindActionCreators(StoreState, dispatch),
  })
)(VerifySlipDetailView)
