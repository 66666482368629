import { connect } from 'react-redux'
import ProductListView from './ProductGroupView'
import { bindActionCreators } from 'redux'
import * as StoreActions from 'x/modules/store/StoreState'
import {
  getSelectedStore,
  getSelectedProductGroups,
  getSelectedUserGroups,
} from 'x/redux/selectors'

export default connect(
  state => ({
    selectedStore: getSelectedStore(state),
    selectedProductGroups: getSelectedProductGroups(state),
    selectedUserGroups: getSelectedUserGroups(state),
  }),
  dispatch => ({
    fetchMyStore: bindActionCreators(StoreActions.fetchMyStore, dispatch),
    createProductGroup: bindActionCreators(StoreActions.createProductGroup, dispatch),
    updateProductGroup: bindActionCreators(StoreActions.updateProductGroup, dispatch),
    deleteProductGroup: bindActionCreators(StoreActions.deleteProductGroup, dispatch),
  })
)(ProductListView)
