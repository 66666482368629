import I18n from 'i18n-js'
// import * as RNLocalize from "react-native-localize"

import en from './locales/en'
import th from './locales/th'

// const locales = RNLocalize.getLocales()
// if (Array.isArray(locales)) {
//   I18n.locale = locales[0].languageTag;
// }

I18n.fallbacks = false
I18n.translations = {
  th,
  en,
}

// type Join<K, P> = K extends string | number ?
//     P extends string | number ?
//     `${K}${'' extends P ? '' : '.'}${P}`
//     : never : never;
//     type Prev = [never, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
//       11, 12, 13, 14, 15, 16, 17, 18, 19, 20, ...0[]]

// type Paths<T, D extends number = 10> = [D] extends [never] ? never : T extends object ?
//     { [K in keyof T]-?: K extends string | number ?
//         `${K}` | Join<K, Paths<T[K], Prev[D]>>
//         : never
//     }[keyof T] : ''

// https://stackoverflow.com/questions/58434389/typescript-deep-keyof-of-a-nested-object
// export type ILocaleTranslateKey = Paths<typeof th>
export type ILocaleTranslateKey = string

// FIXME: Force to Thai for now
// I18n.locales = ['th', 'en']
I18n.defaultLocale = 'th'
I18n.locale = 'th'
// console.log('currentLocale locale = ', I18n.currentLocale())
export default I18n
