// Based from: node_modules\native-base\src\theme\styled-system.ts
import { Platform } from 'react-native'
import _, { get, isNaN, isNil } from 'lodash'
import Color from 'tinycolor2'
// import { resolveValueWithBreakpoint } from '../hooks/useThemeProps/utils'
// import { hasValidBreakpointFormat, transparentize } from './tools'
// import type { ITheme } from '.'
// import type { UseResponsiveQueryParams } from '../utils/useResponsiveQuery'
// import type { UseResponsiveQueryParams } from 'native-base/src/utils/useResponsiveQuery'
import theme from './theme'

const isNumber = (n: any) => typeof n === 'number' && !isNaN(n)

// export const getColor = (rawValue: any, scale: any, theme: IThemeType) => {
export const getColor = (rawValue: any, scale: any, props: any) => {
  // console.log('getColor rawValue => ', rawValue)
  // console.log('getColor scale => ', scale)
  // console.log('getColor props => ', props)
  const alphaMatched = typeof rawValue === 'string' ? rawValue?.match(/:alpha\.\d\d?\d?/) : false

  if (alphaMatched) {
    const colorMatched = rawValue?.match(/^.*?(?=:alpha)/)
    const color = colorMatched ? colorMatched[0] : colorMatched
    const alphaValue = alphaMatched[0].split('.')[1]
    const alphaFromToken = get(theme.opacity, alphaValue, alphaValue)
    // const alphaFromToken = get(theme.opacity, alphaValue, 1)
    const alpha = alphaFromToken ? parseFloat(alphaFromToken) : 1
    // const newColor = transparentize(color, alpha)(theme)
    const newColor = Color(color).setAlpha(alpha).toRgbString()
    return newColor
  }
  return get(scale, rawValue, rawValue)
}

// To handle negative margins
const getMargin = (ni: any, scale: any) => {
  // console.log('getMargin ni => ', ni)
  // console.log('getMargin scale => ', scale)
  let n = ni
  n = convertStringNumberToNumber('margin', n)
  if (!isNumber(n)) {
    return get(scale, n, n)
  }

  const isNegative = n < 0
  const absolute = Math.abs(n)
  const value = get(scale, absolute, absolute)
  if (!isNumber(value)) {
    return isNegative ? `-${value}` : value
  }
  return value * (isNegative ? -1 : 1)
}

const getPadding = (ni: any, scale: any, props: any) => {
  console.log('getPadding ni => ', ni)
  console.log('getPadding scale => ', scale)
  console.log('getPadding props => ', props)
  let n = ni
  n = convertStringNumberToNumber('margin', n)
  if (!isNumber(n)) {
    return get(scale, n, n)
  }

  const isNegative = n < 0
  const absolute = Math.abs(n)
  const value = get(scale, absolute, absolute)
  if (!isNumber(value)) {
    return isNegative ? `-${value}` : value
  }
  return value * (isNegative ? -1 : 1)
}

const getBorderWidth = (n: any, scale: any, props: any) =>
  // console.log('getBorderWidth n => ', n)
  // console.log('getBorderWidth scale => ', scale)
  // console.log('getBorderWidth props => ', props)
  // let n = ni
  // n = convertStringNumberToNumber('margin', n)
  // if (!isNumber(n)) {
  //   return get(scale, n, n)
  // }

  // const isNegative = n < 0
  // const absolute = Math.abs(n)
  // const value = get(scale, absolute, absolute)
  // if (!isNumber(value)) {
  //   return isNegative ? `-${value}` : value
  // }
  get(scale, n, n)

const getScaleOrRaw = (n: any, scale: any, props: any) => {
  console.log('getScaleOrRaw n => ', n)
  console.log('getScaleOrRaw scale => ', scale)
  console.log('getScaleOrRaw props => ', props)
  return get(scale, n, n)
}

export const layout = {
  width: {
    property: 'width',
    scale: 'sizes',
  },
  w: {
    property: 'width',
    scale: 'sizes',
  },
  height: {
    property: 'height',
    scale: 'sizes',
  },
  h: {
    property: 'height',
    scale: 'sizes',
  },
  minWidth: {
    property: 'minWidth',
    scale: 'sizes',
  },
  minW: {
    property: 'minWidth',
    scale: 'sizes',
  },
  minHeight: {
    property: 'minHeight',
    scale: 'sizes',
  },
  minH: {
    property: 'minHeight',
    scale: 'sizes',
  },
  maxWidth: {
    property: 'maxWidth',
    scale: 'sizes',
  },
  maxW: {
    property: 'maxWidth',
    scale: 'sizes',
  },
  maxHeight: {
    property: 'maxHeight',
    scale: 'sizes',
  },
  maxH: {
    property: 'maxHeight',
    scale: 'sizes',
  },
  size: {
    properties: ['width', 'height'],
    scale: 'sizes',
  },
  boxSize: {
    properties: ['width', 'height'],
    scale: 'sizes',
  },
  overflow: true,
  overflowX: true,
  overflowY: true,
  display: true,
  verticalAlign: true,
  textAlign: true,
  textAlignVertical: true,
} as const

export const flexbox = {
  alignItems: true,
  alignContent: true,
  justifyItems: true,
  justifyContent: true,
  flexWrap: true,
  flexDirection: true,
  flexDir: {
    property: 'flexDirection',
  },
  // item
  flex: true,
  flexGrow: true,
  flexShrink: true,
  flexBasis: true,
  justifySelf: true,
  alignSelf: true,
  order: true,
} as const

export const position = {
  position: true,
  zIndex: {
    property: 'zIndex',
  },
  top: {
    property: 'top',
    scale: 'space',
  },
  right: {
    property: 'right',
    scale: 'space',
  },
  bottom: {
    property: 'bottom',
    scale: 'space',
  },
  left: {
    property: 'left',
    scale: 'space',
  },
} as const

export const color = {
  color: {
    property: 'color',
    scale: 'colors',
    transform: getColor,
  },
  tintColor: {
    property: 'tintColor',
    scale: 'colors',
    transform: getColor,
  },
  backgroundColor: {
    property: 'backgroundColor',
    scale: 'colors',
    transform: getColor,
  },
  opacity: {
    property: 'opacity',
    scale: 'opacity',
  },
  bg: {
    property: 'backgroundColor',
    scale: 'colors',
    transform: getColor,
  },
  bgColor: {
    property: 'backgroundColor',
    scale: 'colors',
    transform: getColor,
  },
  background: {
    property: 'backgroundColor',
    scale: 'colors',
    transform: getColor,
  },
  textDecorationColor: {
    property: 'textDecorationColor',
    scale: 'colors',
    transform: getColor,
  },
}

export const border = {
  borderWidth:
    Platform.OS === 'web'
      ? {
          // web
          properties: ['borderTopWidth', 'borderLeftWidth', 'borderBottomWidth', 'borderRightWidth'],
          scale: 'borderWidths',
        }
      : {
          // app
          property: 'borderWidth',
          scale: 'borderWidths',
        },
  borderStyle: {
    property: 'borderStyle',
    scale: 'borderStyles',
  },
  borderColor:
    Platform.OS === 'web'
      ? {
          // web
          properties: ['borderTopColor', 'borderLeftColor', 'borderBottomColor', 'borderRightColor'],
          scale: 'colors',
          transform: getColor,
        }
      : {
          // app
          property: 'borderColor',
          scale: 'colors',
          transform: getColor,
        },
  borderRadius:
    Platform.OS === 'web'
      ? {
          // web
          properties: ['borderTopLeftRadius', 'borderTopRightRadius', 'borderBottomLeftRadius', 'borderBottomRightRadius'],
          scale: 'radii',
        }
      : {
          // app
          property: 'borderRadius',
          scale: 'radii',
        },
  borderTop: {
    property: 'borderTop',
    scale: 'borders',
  },
  borderTopRadius: {
    properties: ['borderTopLeftRadius', 'borderTopRightRadius'],
    scale: 'radii',
  },
  borderLeftRadius: {
    properties: ['borderTopLeftRadius', 'borderBottomLeftRadius'],
    scale: 'radii',
  },
  borderRightRadius: {
    properties: ['borderTopRightRadius', 'borderBottomRightRadius'],
    scale: 'radii',
  },
  borderTopLeftRadius: {
    property: 'borderTopLeftRadius',
    scale: 'radii',
  },
  borderTopRightRadius: {
    property: 'borderTopRightRadius',
    scale: 'radii',
  },
  borderRight: {
    property: 'borderRight',
    scale: 'borders',
  },
  borderBottom: {
    property: 'borderBottom',
    scale: 'borders',
  },
  borderBottomLeftRadius: {
    property: 'borderBottomLeftRadius',
    scale: 'radii',
  },
  borderBottomRightRadius: {
    property: 'borderBottomRightRadius',
    scale: 'radii',
  },
  borderLeft: {
    property: 'borderLeft',
    scale: 'borders',
  },
  borderX: {
    properties: ['borderLeft', 'borderRight'],
    scale: 'borders',
  },
  borderY: {
    properties: ['borderTop', 'borderBottom'],
    scale: 'borders',
  },
  borderTopWidth: {
    property: 'borderTopWidth',
    scale: 'borderWidths',
  },
  borderTopColor: {
    property: 'borderTopColor',
    scale: 'colors',
    transform: getColor,
  },
  borderTopStyle: {
    property: 'borderTopStyle',
    scale: 'borderStyles',
  },
  borderBottomWidth: {
    property: 'borderBottomWidth',
    scale: 'borderWidths',
  },
  borderBottomColor: {
    property: 'borderBottomColor',
    scale: 'colors',
    transform: getColor,
  },
  borderBottomStyle: {
    property: 'borderBottomStyle',
    scale: 'borderStyles',
  },
  borderLeftWidth: {
    property: 'borderLeftWidth',
    scale: 'borderWidths',
  },
  borderLeftColor: {
    property: 'borderLeftColor',
    scale: 'colors',
    transform: getColor,
  },
  borderLeftStyle: {
    property: 'borderLeftStyle',
    scale: 'borderStyles',
  },
  borderRightWidth: {
    property: 'borderRightWidth',
    scale: 'borderWidths',
  },
  borderRightColor: {
    property: 'borderRightColor',
    scale: 'colors',
    transform: getColor,
  },
  borderRightStyle: {
    property: 'borderRightStyle',
    scale: 'borderStyles',
  },
  rounded: {
    property: 'borderRadius',
    scale: 'radii',
  },

  roundedTopLeft: {
    property: 'borderTopLeftRadius',
    scale: 'radii',
  },

  roundedTopRight: {
    property: 'borderTopRightRadius',
    scale: 'radii',
  },

  roundedBottomLeft: {
    property: 'borderBottomLeftRadius',
    scale: 'radii',
  },

  roundedBottomRight: {
    property: 'borderBottomRightRadius',
    scale: 'radii',
  },

  roundedTop: {
    properties: ['borderTopLeftRadius', 'borderTopRightRadius'],
    scale: 'radii',
  },
  borderBottomRadius: {
    properties: ['borderBottomLeftRadius', 'borderBottomRightRadius'],
    scale: 'radii',
  },
  roundedBottom: {
    properties: ['borderBottomLeftRadius', 'borderBottomRightRadius'],
    scale: 'radii',
  },

  roundedLeft: {
    properties: ['borderTopLeftRadius', 'borderBottomLeftRadius'],
    scale: 'radii',
  },

  roundedRight: {
    properties: ['borderTopRightRadius', 'borderBottomRightRadius'],
    scale: 'radii',
  },
}

export const background = {
  backgroundSize: true,
  backgroundPosition: true,
  backgroundRepeat: true,
  backgroundAttachment: true,
  backgroundBlendMode: true,
  bgImage: {
    property: 'backgroundImage',
  },
  bgImg: {
    property: 'backgroundImage',
  },
  bgBlendMode: {
    property: 'backgroundBlendMode',
  },
  bgSize: {
    property: 'backgroundSize',
  },
  bgPosition: {
    property: 'backgroundPosition',
  },
  bgPos: {
    property: 'backgroundPosition',
  },
  bgRepeat: {
    property: 'backgroundRepeat',
  },
  bgAttachment: {
    property: 'backgroundAttachment',
  },
}

export const space = {
  margin: {
    // property: 'margin',
    properties: ['marginTop', 'marginLeft', 'marginBottom', 'marginRight'],
    scale: 'space',
    transform: getMargin,
  },
  m: {
    // property: 'margin',
    properties: ['marginTop', 'marginLeft', 'marginBottom', 'marginRight'],
    scale: 'space',
    transform: getMargin,
  },
  marginTop: {
    property: 'marginTop',
    scale: 'space',
    transform: getMargin,
  },
  mt: {
    property: 'marginTop',
    scale: 'space',
    transform: getMargin,
  },
  marginRight: {
    property: 'marginRight',
    scale: 'space',
    transform: getMargin,
  },
  mr: {
    property: 'marginRight',
    scale: 'space',
    transform: getMargin,
  },
  marginBottom: {
    property: 'marginBottom',
    scale: 'space',
    transform: getMargin,
  },
  mb: {
    property: 'marginBottom',
    scale: 'space',
    transform: getMargin,
  },
  marginLeft: {
    property: 'marginLeft',
    scale: 'space',
    transform: getMargin,
  },
  ml: {
    property: 'marginLeft',
    scale: 'space',
    transform: getMargin,
  },
  marginX: {
    properties: ['marginLeft', 'marginRight'],
    scale: 'space',
    transform: getMargin,
  },
  mx: {
    properties: ['marginLeft', 'marginRight'],
    scale: 'space',
    transform: getMargin,
  },
  marginY: {
    properties: ['marginTop', 'marginBottom'],
    scale: 'space',
    transform: getMargin,
  },
  my: {
    properties: ['marginTop', 'marginBottom'],
    scale: 'space',
    transform: getMargin,
  },

  padding: {
    // property: 'padding',
    properties: ['paddingTop', 'paddingLeft', 'paddingBottom', 'paddingRight'],
    scale: 'space',
    // transform: getPadding,
  },
  p: {
    // property: 'padding',
    properties: ['paddingTop', 'paddingLeft', 'paddingBottom', 'paddingRight'],
    scale: 'space',
    // transform: getPadding,
  },
  paddingTop: {
    property: 'paddingTop',
    scale: 'space',
    // transform: getPadding,
  },
  pt: {
    property: 'paddingTop',
    scale: 'space',
    // transform: getPadding,
  },
  paddingRight: {
    property: 'paddingRight',
    scale: 'space',
    // transform: getPadding,
  },
  pr: {
    property: 'paddingRight',
    scale: 'space',
    // transform: getPadding,
  },
  paddingBottom: {
    property: 'paddingBottom',
    scale: 'space',
    // transform: getPadding,
  },
  pb: {
    property: 'paddingBottom',
    scale: 'space',
    // transform: getPadding,
  },
  paddingLeft: {
    property: 'paddingLeft',
    scale: 'space',
    // transform: getPadding,
  },
  pl: {
    property: 'paddingLeft',
    scale: 'space',
    // transform: getPadding,
  },
  paddingX: {
    properties: ['paddingLeft', 'paddingRight'],
    scale: 'space',
    // transform: getPadding,
  },
  px: {
    properties: ['paddingLeft', 'paddingRight'],
    scale: 'space',
    // transform: getPadding,
  },
  paddingY: {
    properties: ['paddingTop', 'paddingBottom'],
    scale: 'space',
    // transform: getPadding,
  },
  py: {
    properties: ['paddingTop', 'paddingBottom'],
    scale: 'space',
    // transform: getPadding,
  },
  gap: {
    property: 'gap',
    scale: 'space',
    // transform: getPadding,
  },
}

export const typography = {
  fontFamily: {
    property: 'fontFamily',
    scale: 'fonts',
    // transform: (val: any, scale: any, props: any) => {
    //   // console.log('transform fontFamily val => ', val)
    //   // console.log('transform fontFamily scale => ', scale)
    //   // console.log('transform fontFamily props => ', props)
    //   const value = get(scale, val)
    //   return value ? value.toString() : undefined
    // },
  },
  fontSize: {
    property: 'fontSize',
    scale: 'fontSizes',
  },
  fontWeight: {
    property: 'fontWeight',
    scale: 'fontWeights',
    // transform: (val: any, scale: any) => (val ? get(scale, val, val).toString() : val),
    transform: (val: any, scale: any, props: any) => {
      // console.log('fontWeight val => ', val)
      // console.log('fontWeight scale => ', scale)

      // Font Weight of '700' or 'bold' not working in Android for Custom fonts
      // https://github.com/akveo/react-native-ui-kitten/issues/1501
      // https://stackoverflow.com/questions/16993904/android-setting-textview-to-bold-not-working
      if (
        Platform.OS === 'android' &&
        ((_.isNumber(parseInt(val)) && parseInt(val) >= 700) || _.includes(['bold', 'extrabold', 'black', 'extraBlack'], val))
      ) {
        return '600'
      }

      return val ? get(scale, val, val).toString() : val
    },
  },
  lineHeight: {
    property: 'lineHeight',
    scale: 'lineHeights',
  },
  letterSpacing: {
    property: 'letterSpacing',
    scale: 'letterSpacings',
  },
  textAlign: true,
  textAlignVertical: true,
  fontStyle: true,
  wordBreak: true,
  overflowWrap: true,
  textOverflow: true,
  textTransform: true,
  whiteSpace: true,
  textDecoration: { property: 'textDecorationLine' },
  txtDecor: { property: 'textDecorationLine' },
  textDecorationLine: true,
}

const extraProps = {
  outline: true,
  outlineWidth: true,
  outlineColor: true,
  outlineStyle: true,
  shadow: {
    scale: 'shadows',
  },
  cursor: true,
  overflow: true,
  userSelect: { property: 'userSelect' },
}

export const propConfig = {
  ...color,
  ...space,
  ...layout,
  ...flexbox,
  ...border,
  ...position,
  ...typography,
  ...background,
  ...extraProps,
}

// For backward compatibility with 3.0 of props like non token string numbers `<Box mt={"39"} />` => used to get applied as 39px. RN expects fontWeight to be string and crashes with numbers
// https://reactnative.dev/docs/text-style-props#fontweight
const convertStringNumberToNumber = (key: string, value: string) => {
  if (typeof value === 'string' && key !== 'fontWeight' && value && !isNaN(Number(value))) {
    return parseFloat(value)
  }

  return value
}

const getRNKeyAndStyleValue = ({ config, value, key, theme, styledSystemProps, currentBreakpoint }: any) => {
  let style: any = {}
  if (config === true) {
    style = {
      ...style,
      [key]: convertStringNumberToNumber(key, value),
    }
  } else if (config) {
    // @ts-ignore
    const { property, scale, properties, transform } = config
    let val = value

    if (transform) {
      val = transform(val, theme[scale], theme, styledSystemProps.fontSize)
    } else {
      // If a token is not found in the theme
      val = get(theme[scale], value, value)
    }

    if (typeof val === 'string') {
      if (val.endsWith('px')) {
        if (Platform.OS === 'web') {
          parseFloat(val)
        } else {
          val = parseFloat(val.replace('px', ''))
        }
      } else if (val.endsWith('em') && Platform.OS !== 'web') {
        const fontSize = resolveValueWithBreakpoint(styledSystemProps.fontSize, theme.breakpoints, currentBreakpoint, key)
        val = parseFloat(val) * parseFloat(get(theme.fontSizes, fontSize, fontSize))
      }
    }

    val = convertStringNumberToNumber(key, val)

    if (properties) {
      // @ts-ignore
      properties.forEach((property) => {
        style = {
          ...style,
          [property]: val,
        }
      })
    } else if (property) {
      style = {
        ...style,
        [property]: val,
      }
    } else {
      style = {
        ...style,
        ...val,
      }
    }
  }

  // if (key === 'top' || key === 'left' || key === 'bottom' || key === 'right') {
  // if (value === '28px') {
  //   console.log('getRNKeyAndStyleValue key => ', key)
  //   console.log('getRNKeyAndStyleValue config => ', config)
  //   console.log('getRNKeyAndStyleValue value => ', value)
  //   console.log('getRNKeyAndStyleValue style => ', style)
  //   return {}
  // }
  return style
}

export const inValidBreakpointProps = ['style', 'children', 'shadowOffset']
export function hasValidBreakpointFormat(breaks: any, themeBreakpoints?: any, property?: string) {
  if (property && inValidBreakpointProps.indexOf(property) !== -1) {
    return false
  }
  if (Array.isArray(breaks)) {
    return !!breaks.length
  }
  if (typeof breaks === 'object' && breaks !== null) {
    const keys = Object.keys(breaks)
    const themeBreakPointKeys = Object.keys(themeBreakpoints)
    for (let i = 0; i < keys.length; i++) {
      if (themeBreakPointKeys.indexOf(keys[i]) === -1) {
        return false
      }
    }
    return true
  }
  return false
}

/**
 *
 * Checks the property and resolves it if it has breakpoints
 * @param values : value from props
 * @param currentBreakpoint : current value for which breakpoint will be calculated
 * @param property : property name
 * @returns
 */
export const resolveValueWithBreakpoint = (values: any, breakpointTheme: any, currentBreakpoint: number, property: any) => {
  if (hasValidBreakpointFormat(values, breakpointTheme, property)) {
    // Check the last valid breakpoint value from all values
    // If current breakpoint is `md` and we have `base` then `lg`, then last value will be taken(`base` in this case)
    return findLastValidBreakpoint(values, breakpointTheme, currentBreakpoint)
  }
  return values
}

export function findLastValidBreakpoint(values: any, themeBreakpoints: any, currentBreakpoint: number) {
  const valArray = Array.isArray(values) ? values : Object.keys(themeBreakpoints).map((bPoint: string) => values[bPoint])
  return (
    valArray[currentBreakpoint] ??
    valArray
      .slice(0, currentBreakpoint + 1)
      .filter((v: any) => !isNil(v))
      .pop()
  )
}

const orderedBreakPoints = Object.entries(theme.breakpoints).sort((a, b) => a[1] - b[1])

export const getStyleAndFilteredProps = (props: any) => {
  // const { style, theme: tt, debug, currentBreakpoint, getResponsiveStyles, styledSystemProps } = props
  const { style, theme: tt, debug, currentBreakpoint, getResponsiveStyles, styledSystemProps } = props
  let styleFromProps: any = {}
  const dataSet: any = {}
  let responsiveStyles: null | Record<keyof typeof tt.breakpoints, Array<any>> = null

  // const orderedBreakPoints = Object.entries(tt.breakpoints as IThemeType['breakpoints']).sort((a, b) => a[1] - b[1])

  for (const key in styledSystemProps) {
    const rawValue = styledSystemProps[key]

    const config = propConfig[key as keyof typeof propConfig]

    if (hasValidBreakpointFormat(rawValue, tt.breakpoints)) {
      if (!responsiveStyles) responsiveStyles = {}

      const value = rawValue
      if (Array.isArray(value)) {
        value.forEach((v, i) => {
          // @ts-ignore
          if (!responsiveStyles[orderedBreakPoints[i][0]]) {
            // @ts-ignore
            responsiveStyles[orderedBreakPoints[i][0]] = []
          }
          const newStyle = getRNKeyAndStyleValue({
            config,
            value: v,
            key,
            styledSystemProps,
            theme: tt,
            currentBreakpoint,
          })
          // @ts-ignore
          responsiveStyles[orderedBreakPoints[i][0]].push(newStyle)
        })
      } else {
        for (const k in value) {
          const newStyle = getRNKeyAndStyleValue({
            config,
            value: value[k],
            key,
            styledSystemProps,
            theme: tt,
            currentBreakpoint,
          })
          if (!responsiveStyles[k]) {
            responsiveStyles[k] = []
          }
          responsiveStyles[k].push(newStyle)
        }
      }
    } else {
      const value = rawValue
      const newStyle = getRNKeyAndStyleValue({
        config,
        value,
        key,
        styledSystemProps,
        theme: tt,
        currentBreakpoint,
      })

      styleFromProps = {
        ...styleFromProps,
        ...newStyle,
      }
    }
  }

  // if (responsiveStyles) {
  //   const query: UseResponsiveQueryParams = { query: [] }
  //   orderedBreakPoints.forEach((o) => {
  //     const key = o[0]
  //     if (key === 'base') {
  //       if (responsiveStyles) query.initial = responsiveStyles.base
  //     } else if (responsiveStyles)
  //       if (key in responsiveStyles) {
  //         query?.query?.push({
  //           minWidth: o[1],
  //           style: responsiveStyles[key],
  //         })
  //       }
  //   })

  //   const { dataSet: newDataSet, styles } = getResponsiveStyles(query)
  //   dataSet = { ...dataSet, ...newDataSet }
  //   styleFromProps = { ...styleFromProps, ...StyleSheet.flatten(styles) }
  // }

  // if (process.env.NODE_ENV === 'development' && debug) {
  //   /* eslint-disable-next-line */
  //   console.log('style ', debug + ' :: ', {
  //     styleFromProps,
  //     style,
  //     styledSystemProps,
  //   })
  // }

  return {
    // styleSheet: StyleSheet.create({ box: styleFromProps }),
    // styleSheet: { box: styleFromProps },
    styleFromProps,
    dataSet,
  }

  // return styleFromProps
}

// /**
//  * If property is functional in componentTheme, get its returned object
//  *
//  * @param property : name of the prop
//  * @param props : all props
//  * @param theme : provided theme without components
//  * @param componentTheme : component specific theme
//  * @returns
//  */
// export const extractPropertyFromFunction = (property: string, props: any, theme: any, componentTheme: any) => {
//   let propValues
//   // Check if the entry in the theme is a function then calling it with all theme and props as params
//   if (componentTheme && typeof componentTheme[themePropertyMap[property]] === 'function') {
//     const funcProps = componentTheme[themePropertyMap[property]]({
//       theme,
//       ...props,
//     })
//     // Check if returned object from componentTheme is a nested object
//     const isNested: boolean = Object.keys(funcProps).some((key) => funcProps[key] && typeof funcProps[key] === 'object')
//     // If the returned value is nested object then find the property value in it, otherwise return the whole object
//     propValues = isNested ? { ...get(funcProps, `${props[property]}`) } : { ...funcProps }
//   } else {
//     // If the entry is any value other than function then return the whole object or value
//     propValues = get(componentTheme, `${themePropertyMap[property]}.${props[property]}`)
//   }
//   return propValues
// }

// /*
// Merge _props and apply contrastText color if not passed by theme or user
// */
// export function mergeUnderscoreProps(newProps: any, props: any) {
//   const _props = Object.keys(newProps).filter((propName) => propName.startsWith('_'))
//   _props.forEach((propName: string) => {
//     // Adding color based on bg contrast if no color is given
//     // const bg = newProps.bg ?? newProps.backgroundColor;
//     // const textColor = bg
//     //   ? {
//     //       color: useContrastText(
//     //         bg,
//     //         newProps[propName]?.color ?? props[propName]?.color
//     //       ),
//     //     }
//     //   : {};
//     // Overriding calculated props with user added props
//     newProps[propName] = {
//       // ...textColor,
//       ...newProps[propName],
//       ...props[propName],
//     }
//   })
//   return newProps
// }

// /*
//  Extract props from theme props and omit those from props
// */
// /**
//  *
//  * @param props Props passed by the user
//  * @param theme Theme object
//  * @param colorModeProps `colorMode` object
//  * @param componentTheme Theme for specific components
//  * @param currentBreakpoint Current breakpoint values
//  * @returns Extracting props from defaultProps while overriding the props that are already present
//  */
// export function extractProps(
//   props: any,
//   theme: any,
//   colorModeProps = { colorMode: 'light' },
//   componentTheme: any,
//   currentBreakpoint: number
// ) {
//   let newProps: any = {}
//   for (const property in props) {
//     // If the property exists in themePropertyMap then get its value
//     if (themePropertyMap[property]) {
//       const propValues = extractPropertyFromFunction(property, props, theme, componentTheme)
//       if (typeof propValues === 'string' || typeof propValues === 'number') {
//         newProps[property] = propValues
//       } else if (!isNil(propValues)) {
//         for (const nestedProp in propValues) {
//           newProps[nestedProp] = get(theme, `${themePropertyMap[nestedProp]}.${propValues[nestedProp]}`, propValues[nestedProp])
//         }
//       } else if (property === 'shadow') {
//         const shadowProps = theme[themePropertyMap[property]][props[property]]
//         if (!isNil(shadowProps)) {
//           newProps = { ...newProps, ...shadowProps }
//         }
//       } else {
//         newProps[property] = resolveValueWithBreakpoint(props[property], theme.breakpoints, currentBreakpoint, property)
//       }
//     } else {
//       newProps[property] = resolveValueWithBreakpoint(props[property], theme.breakpoints, currentBreakpoint, property)
//     }
//   }
//   return cloneDeep(newProps)
// }

// /*
// Remove props from defaultProps that are already present in props
// */
// export function filterDefaultProps(props: any, defaultProps: any) {
//   const [, resultProps] = themeTools.extractInObject(defaultProps, Object.keys(props))
//   return resultProps
// }

// export function getClosestBreakpoint(values: Record<string, any>, point: number) {
//   const dimValues = Object.values(values)
//   let index = -1
//   const breakpointsObj: any = {}
//   for (let i = 0; i < dimValues.length; i++) {
//     breakpointsObj[dimValues[i]] = i
//   }
//   const breakpoints = Object.keys(breakpointsObj)
//   for (let i = 0; i < breakpoints.length; i++) {
//     if (parseInt(breakpoints[i]) === point) {
//       index = breakpointsObj[breakpoints[i]]
//       break
//     } else if (parseInt(breakpoints[i]) > point && i !== 0) {
//       index = breakpointsObj[breakpoints[i - 1]]
//       break
//     }
//     // If windowWidth is greater than last available breakpoint clamp it to last index
//     else if (parseInt(breakpoints[i]) < point && i === dimValues.length - 1) {
//       index = breakpointsObj[breakpoints[i]]
//       break
//     }
//   }
//   return index
// }

// /**
//  * Takes all prop related data and returns the props that needs to be applied to the component
//  *
//  * @param theme Theme object
//  * @param colorModeProps Color mode information
//  * @param componentTheme Theme object for the specific component
//  * @param props Props passed by the user
//  * @param windowWidth Width of the current window
//  * @returns props to be applied
//  */
// export function calculateProps(theme: any, colorModeProps: any, componentTheme: any, props: any, windowWidth: any) {
//   const currentBreakpoint = getClosestBreakpoint(theme.breakpoints, windowWidth)
//   if (!props) {
//     props = {}
//   }

//   let newProps: any
//   if (componentTheme) {
//     // Extracting props from defaultProps
//     newProps = extractProps(
//       filterDefaultProps(props, componentTheme.defaultProps),
//       theme,
//       colorModeProps,
//       componentTheme,
//       currentBreakpoint
//     )
//     // Extracting props from base style
//     const componentBaseStyle =
//       typeof componentTheme.baseStyle !== 'function'
//         ? componentTheme.baseStyle
//         : componentTheme.baseStyle({
//             theme,
//             ...newProps,
//             ...props,
//             ...colorModeProps,
//           })
//     newProps = mergeWith(
//       newProps,
//       componentBaseStyle,
//       // @ts-ignore
//       (objValue, srcValue, key) => {
//         if (!isNil(objValue)) {
//           delete newProps[key]
//         }
//       }
//     )

//     const variant = props.variant || get(componentTheme, 'defaultProps.variant')
//     // Extracting props from variant
//     if (variant && componentTheme.variants && componentTheme.variants[variant]) {
//       const colorScheme = props.colorScheme || get(componentTheme, 'defaultProps.colorScheme')
//       let variantProps = componentTheme.variants[variant]({
//         ...props,
//         ...newProps,
//         colorScheme,
//         theme,
//         ...colorModeProps,
//       })
//       variantProps = extractProps(variantProps, theme, colorModeProps, componentTheme, currentBreakpoint)
//       // added this to handle order of props
//       newProps = mergeWith(
//         newProps,
//         variantProps,
//         // @ts-ignore
//         (objValue, srcValue, key) => {
//           if (!isNil(objValue)) {
//             delete newProps[key]
//           }
//         }
//       )
//       delete newProps.variant
//       delete newProps.colorScheme
//     }
//   }
//   // Extracting props from normal props
//   const extractedProps = extractProps(props, theme, colorModeProps, componentTheme, currentBreakpoint)
//   // added this to handle order of props
//   // @ts-ignore
//   newProps = mergeWith(newProps, extractedProps, (objValue, srcValue, key) => {
//     if (!isNil(objValue)) {
//       delete newProps[key]
//     }
//   })
//   newProps = mergeUnderscoreProps(newProps, props)
//   return newProps
// }

export type StyledPropConfig = typeof propConfig
