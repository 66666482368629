// import React from 'react'
// import { IPaymentAccount } from 'x/index'
import BaseUIStorePaymentAccountListView from 'xui/modules/payment/BaseUIStorePaymentAccountListView'
// import BasePaymentAccountListView from './BasePaymentAccountListView'
export default class StorePaymentAccountListView extends BaseUIStorePaymentAccountListView {
  static displayName = 'StorePaymentAccountListView'

  // // เมื่อกดเลือกบัญชี
  // onPressPaymentAccountCard = (pressedPaymentAccount: IPaymentAccount) => {
  //   this.navToEditBankAccount(pressedPaymentAccount)
  // }
}
