import React, { Component } from 'react'
import _ from 'lodash'
import * as util from 'x/utils/util'
import { ISelectedStoreMap, ActionApiParams, IProfileMap, IXScreenProps, IAddress, IAnyObject } from 'x/index'
import * as NavActions from 'x/utils/navigation'
import api from 'x/utils/api'
import p from 'x/config/platform-specific'
// export interface IContactListGroup {
//   profiles: IContact[]
// }

export interface IContact {
  first_name: string
  id: number
}

interface IBaseCCustomerListViewProps extends IXScreenProps {
  dispatch: (params: any) => void
  selectedStore: ISelectedStoreMap
  searchMumber: (params: ActionApiParams) => void
  profile: IProfileMap
  contactListGroup: ISearchResult[]
  fetchContactList: () => void
  contactList: {
    first_name: string
    id: number
  }[]
  clearContact: () => void

  addCustomer: (params: ActionApiParams) => void
  // pickAddressToPatch: (address: IAddress) => void
}

interface IBaseCCustomerListViewState {
  // contactGroups: IContactListGroup
  isSearch: boolean
  textSearch: string
  searchResult: ISearchResult[]
}

interface ISearchResult {
  key: string
  data: string[]
}

export default class BaseCCustomerListView extends Component<IBaseCCustomerListViewProps, IBaseCCustomerListViewState> {
  txtNewSearchlRef: React.RefObject<any>

  noResultFoundText: string

  searchResultDefault: ISearchResult[]

  protected constructor(props: IBaseCCustomerListViewProps) {
    super(props)
    this.txtNewSearchlRef = React.createRef()
    this.noResultFoundText = 'ไม่พบผลลัพธ์ที่ต้องการค้นหา'
    this.searchResultDefault = [
      {
        key: 'กรุณาป้อนคำที่ต้องการค้นหา...',
        data: [],
      },
    ]
    this.state = {
      // contactGroups: null,
      isSearch: false,
      textSearch: '',
      searchResult: this.searchResultDefault,
    }
  }

  async componentDidMount() {
    await this._fetchContactList()
  }

  _fetchContactList = async () => {
    this.props.fetchContactList()
  }

  _closeSearch = async () => {
    await util.setStatePromise(this, { isSearch: false, textSearch: '', searchResult: this.searchResultDefault })
    this.props.clearContact()
  }

  _openSearch = async () => {
    await util.setStatePromise(this, { isSearch: true })
    this.configTxtSearchRef()
  }

  configTxtSearchRef = () => {
    if (this.txtNewSearchlRef && this.txtNewSearchlRef.current) {
      setTimeout(() => this.txtNewSearchlRef.current.focus(), 100)
    }
  }

  _dosaveCustomer = async (body): Promise<boolean> => {
    // console.log('_dosaveCustomer => ')
    const { addCustomer, navigation } = this.props
    const data = {
      first_name: body.name,
      last_name: '',
      phone1: body.telephone,
      addresses: [body],
    }
    if (body.address2) {
      // @ts-ignore
      data.address2 = body.address2
    }
    // console.log('data => ', data)
    // return
    const response = await new Promise((resolveEdit) => {
      addCustomer({
        body: data,
        successCallback: resolveEdit,
        failedCallback: () => resolveEdit(null),
      })
    })

    if (response) {
      return true
    }

    return false
  }

  _handleSubmitForm = async (type: string, addr: IAddress): Promise<boolean> => {
    const store_id = util.getNavParam(this.props, 'store_id')
    const profile_id = util.getNavParam(this.props, 'profile_id')
    const body: IAnyObject = { ...addr }
    delete body.id

    if (typeof body.postal_code === 'string' && body.postal_code.length) {
      body.postal_code = parseInt(body.postal_code)
    }

    if (!_.isEmpty(body)) {
      const address_id = addr.id ? addr.id : null
      // console.log('address => // ', address)
      // console.log('address_id => ', address_id)
      if (address_id) {
        body.address_id = address_id
      } else if (type === 'PROFILE') {
        body.addressable_id = profile_id
      } else if (type === 'STORE') {
        body.addressable_id = store_id
      }

      if (type !== 'CUSTOMER') {
        body.type = type
      }

      try {
        const isSuccess = await this._dosaveCustomer(body)
        return isSuccess
      } catch (err) {
        console.log('_handleSubmitForm err => ', err)
      }
    } else {
      p.op.showToast('ยังไม่มีการแก้ไขข้อมูลเกิดขึ้น', 'warning')
    }

    return false
  }

  _navToCCustomerCreateView = () => {
    // this.props.navigation.dispatch(
    //   NavActions.navToAddressView({
    //     // store_id: _.isNil(store_id) ? null : store_id,
    //     headerTitle: 'เพิ่มลูกค้า',
    //     type: 'CUSTOMER',
    //     succeedCallback: null,
    //   })
    // )
    // dispatch(NavActions.navToCCustomerCreateView)

    this.props.navigation.dispatch(
      NavActions.navToAddressFormView({
        headerTitle: 'เพิ่มลูกค้า',
        // type: 'CUSTOMER',
        type: 'customer_address',
        onSubmit: async (address: IAddress) => {
          const isSuccess = await this._handleSubmitForm('CUSTOMER', address)
          return { isGoBack: isSuccess }
        },
      })
    )
  }

  _onContactPressed = (id: number) => {
    const { navigation } = this.props
    // const { actor } = navigation.state.params
    // const { state, goBack } = navigation
    // const { params } = state
    const params = util.getNavParams(this.props)
    const { mode, callBackSelectedAddress } = params
    // console.log('mode // => ', mode)
    navigation.dispatch(
      NavActions.navToContactView({
        contact_id: id,
        callBack: (address) => {
          if (_.isFunction(callBackSelectedAddress)) {
            callBackSelectedAddress(address)
            util.navGoBack(this.props)
          }
        },
        mode,
      })
    )
  }

  _onChangeSearchText = (text: string) => {
    const { contactList } = this.props
    const newDataSearch = [
      {
        key: 'กรุณาป้อนคำที่ต้องการค้นหา...',
        data: [],
      },
    ]
    if (text.length > 0) {
      newDataSearch[0] = {
        key: `ผลลัพธ์สำหรับ "${text}" คือ...`,
        data: _.filter(contactList, (ds) => _.includes(ds.first_name.toUpperCase(), text.toUpperCase())),
      }
      if (newDataSearch[0].data.length <= 0) {
        newDataSearch.push({ key: this.noResultFoundText, data: [] })
      }
    }
    this.setState({
      searchResult: newDataSearch,
      textSearch: text,
    })
  }

  _fetchCustomerAddresses = async (profile_id: number) => {
    // const { navigation } = this.props
    // const { state, goBack } = navigation
    // const { params } = state
    const params = util.getNavParams(this.props)
    const { mode, callBackSelectedAddress } = params
    const res = await api.post('customer', { profile_id })
    // console.log('res => ', res)
    if ('addresses' in res.profile) {
      const { addresses } = res.profile
      // console.log('addresses => ', addresses)
      if (addresses.length > 0) {
        const addressToPatch = addresses[0]
        // console.log('addressToPatch => ', addressToPatch)
        // const { pickAddressToPatch } = this.props
        // pickAddressToPatch(addressToPatch)
        if (_.isFunction(callBackSelectedAddress)) {
          callBackSelectedAddress(addressToPatch)
        }
        util.navGoBack(this.props)
      }
    }
  }
}
