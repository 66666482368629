// Implement ref https://medium.com/@geekrodion/redux-saga-create-react-app-b6484f704d68

import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { MODE } from 'x/config/mode'

// import Reactotron from '../utils/reactotronConfig'

import reducers from './reducers'
import middleware from './middleware'

// const store = createStore(reducers, composeWithDevTools(applyMiddleware(...middleware),  Reactotron.createEnhancer()))
const store =
  MODE === 'dev'
    ? createStore(reducers, composeWithDevTools(applyMiddleware(...middleware)))
    : createStore(reducers, applyMiddleware(...middleware))

export default store
