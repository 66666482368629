import { connect } from 'react-redux'
import { getStoreSelected, getSubscription } from 'x/redux/selectors'
import { getState, getDispatch } from 'x/modules/order/BaseOrderViewConnect'
import StoreSettingStickerView from './StoreSettingStickerView'

export default connect(
  (state) => ({
    ...getState(state),
    selectedStore: getStoreSelected(state),
    subscription: getSubscription(state),
  }),
  (dispatch) => ({
    ...getDispatch(dispatch),
  })
)(StoreSettingStickerView)
