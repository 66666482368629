import React from 'react'
import { ImageStyle, StyleSheet, ImageSourcePropType, Image } from 'react-native'
import NB from 'x/config/nativebase'
import { getColor } from 'x/config/nativebase/styled-system'
import theme from 'x/config/nativebase/theme'

export type XImageIconProps = {
  source: ImageSourcePropType // IconName
  variant?: 'active' | 'inactive' | 'activeDark' | 'danger' | 'success' | 'primary'
  size?: 'xs' | 'sm' | 'md' | 'lg'
  style?: ImageStyle
}

// xs: 4,
// sm: 6,
// md: 8,
// lg: 10,

// '4': 16,
// '5': 20,
// '6': 24,
// '7': 28,
// '8': 32,
// '9': 36,
// '10': 40,

const XImageIcon: React.FC<XImageIconProps> = (props) => {
  const { source, style = {}, variant = 'primary', size = 'sm' } = props
  // const iconVariant = variant
  // const th = useTheme()
  // let color = themeTools.getColor(th, NB.HC.TEXT.PRIMARY())
  let color = getColor(theme, NB.C.L.TEXT.PRIMARY, props)
  let CSize = 24
  // const variant = NB.HC.TEXT.PRIMARY()
  if (variant === 'active') {
    // color = getColor(theme, NB.HC.TEXT.ACTIVE(), props)
    color = getColor(theme, NB.C.L.TEXT.ACTIVE, props)
  } else if (variant === 'inactive') {
    // color = getColor(theme, NB.HC.TEXT.INACTIVE(), props)
    color = getColor(theme, NB.C.L.TEXT.INACTIVE, props)
  } else if (variant === 'activeDark') {
    // color = getColor(theme, NB.HC.TEXT.ACTIVE_DARK(), props)
    color = getColor(theme, NB.C.L.TEXT.ACTIVE_DARK, props)
  } else if (variant === 'danger') {
    // color = getColor(theme, NB.HC.TEXT.DANGER(), props)
    color = getColor(theme, NB.C.L.TEXT.DANGER, props)
  } else if (variant === 'success') {
    // color = getColor(theme, NB.HC.TEXT.SUCCESS(), props)
    color = getColor(theme, NB.C.L.TEXT.SUCCESS, props)
  }
  if (size === 'xs') {
    CSize = 16
  } else if (size === 'sm') {
    CSize = 24
  } else if (size === 'md') {
    CSize = 32
  } else if (size === 'lg') {
    CSize = 40
  }
  // const { colorMode, accessibleColors } = useColorMode()
  return (
    <Image
      // alt='Image Icon'
      resizeMode='contain'
      style={StyleSheet.flatten([
        {
          width: CSize,
          height: CSize,
          tintColor: color,
          // ...style,
        },
        style,
      ])}
      source={source}
    />
  )
}

export default XImageIcon
