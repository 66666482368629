// base from: node_modules\native-base\src\theme\components\button.ts
import { Platform } from 'react-native'
// import { themeTools as tt } from 'native-base'
// import * as nbUtil from 'x/utils/nativebase'

// const { mode } = tt

// const disabledTextColor = () => mode(`muted.500`, `muted.300`)

const baseStyle = (props: Record<string, any>) => {
  const { theme, colorScheme: c = 'primary', borderWidth: bw = '1' } = props
  //   const { primary } = props.theme.colors

  //   const focusRing = mode(
  //     {
  //       boxShadow: `${primary[400]} 0px 0px 0px 2px`,
  //     },
  //     {
  //       boxShadow: `${primary[500]} 0px 0px 0px 2px`,
  //     }
  //   )(props)

  // let computedBorderColor = bc || undefined
  // // if (!bc) {
  // if (c === 'muted' || props.isDisabled) {
  //   // computedBorderColor = c === 'muted' ? predefinedBorderColor : props.isDisabled ? disabledTextColor() : mode(`${c}.300`, `${c}.300`)
  //   computedBorderColor = disabledTextColor()
  // } else {
  //   // computedBorderColor = mode(`${c}.300`, `${c}.300`)
  //   computedBorderColor = mode(`${c}.500`, `${c}.500`)
  // }

  //   // const injectBorderProps = nbUtil.getInjectBorderUtilityProps({
  //   //   ...props,
  //   //   borderWidth: bw || 0,
  //   //   borderColor: computedBorderColor,
  //   // })

  return {
    // ...injectBorderProps,
    borderWidth: bw,
    borderColor: `${c}.500`,
    // borderRadius: 'md', // '4px'
    borderRadius: 'lg', // '4px'
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    _text: {
      fontWeight: 'medium',
      pb: '0.5',
    },
    _web: {
      _disabled: {
        cursor: 'not-allowed',
      },
      _loading: {
        cursor: 'not-allowed',
      },
      cursor: 'pointer',
      userSelect: 'none',
    },
    _focusVisible: {
      _web: {
        outlineWidth: '0',
        // style: { ...focusRing },
      },
    },
    _stack: {
      space: '1.5',
      alignItems: 'center',
    },
    _loading: {
      opacity: '40',
    },
    _disabled: {
      opacity: '40',
      borderColor: 'muted.500',
    },
    _spinner: {
      size: 'sm',
      focusable: false,
    },
  }
}

// const baseStyle = (props: any) => {
//   const { isDisabled = false, isLoading = false, theme, variant } = props
//   const { primary } = theme.colors
//   const focusRing =
//     Platform.OS === 'web'
//       ? mode({ boxShadow: `${primary[400]} 0px 0px 0px 2px`, zIndex: 1 }, { boxShadow: `${primary[500]} 0px 0px 0px 2px`, zIndex: 1 })
//       : {}

//   return {
//     borderRadius: 'sm',
//     flexDirection: 'row',
//     // flexWrap: 'wrap',
//     justifyContent: 'center',
//     alignItems: 'center',
//     _web: {
//       cursor: isDisabled ? 'not-allowed' : isLoading ? 'default' : 'pointer',
//     },
//     _text: {
//       fontWeight: 'medium',
//     },
//     _focusVisible: {
//       style: variant !== 'unstyled' ? { ...focusRing } : {},
//     },
//     _stack: {
//       // flexWrap: 'wrap',
//       space: 2,
//       // space: '1',
//       alignItems: 'center',
//     },
//     _loading: {
//       opacity: '80',
//     },
//     _disabled: {
//       opacity: '50',
//     },
//     _spinner: {
//       size: 'sm',
//       focusable: false,
//     },
//   }
// }

function variantSolid(props: Record<string, any>) {
  const { colorScheme: c = 'primary' } = props
  return {
    _text: {
      color: 'text.50',
      fontWeight: 'bold',
      // bold: true,
    },
    _icon: {
      color: 'text.50',
    },
    _spinner: {
      color: 'text.50',
    },

    _light: {
      bg: `${c}.500`,
      _hover: {
        bg: `${c}.700`,
      },
      _pressed: {
        bg: `${c}.800`,
      },
    },
    _dark: {
      bg: `${c}.500`,
      _hover: {
        bg: `${c}.700`,
      },
      _pressed: {
        bg: `${c}.800`,
      },
    },
  }
}

function variantGhost({ colorScheme = 'primary' }: Record<string, any>) {
  return {
    borderWidth: '0',
    _light: {
      _text: {
        color: `${colorScheme}.500`,
        // color: `${colorScheme}.600`,
      },
      _icon: {
        color: `${colorScheme}.500`,
        // color: `${colorScheme}.600`,
      },
      _spinner: {
        color: `${colorScheme}.500`,
        // color: `${colorScheme}.600`,
      },
      _hover: {
        bg: `${colorScheme}.500:alpha.10`,
        // bg: `${colorScheme}.600:alpha.10`,
      },
      _pressed: {
        bg: `${colorScheme}.500:alpha.20`,
        // bg: `${colorScheme}.600:alpha.20`,
      },
    },
    _dark: {
      _text: {
        color: `${colorScheme}.500`,
      },
      _icon: {
        color: `${colorScheme}.500`,
      },
      _spinner: {
        color: `${colorScheme}.500`,
      },
      _hover: {
        bg: `${colorScheme}.500:alpha.10`,
      },
      _pressed: {
        bg: `${colorScheme}.500:alpha.20`,
      },
    },
  }
}

function variantOutline(props: Record<string, any>) {
  // const { colorScheme: c = 'primary', borderColor: bc, borderWidth: bw = '1' } = props
  const { colorScheme: c = 'primary' } = props
  // const predefinedBorderColor = mode(`muted.200`, `muted.500`)

  // let computedBorderColor = bc || undefined
  // if (!bc) {
  //   if (c === 'muted' || props.isDisabled) {
  //     // computedBorderColor = c === 'muted' ? predefinedBorderColor : props.isDisabled ? disabledTextColor() : mode(`${c}.300`, `${c}.300`)
  //     // computedBorderColor = disabledTextColor()
  //     computedBorderColor = 'muted.500'
  //   } else {
  //     // computedBorderColor = mode(`${c}.300`, `${c}.300`)
  //     // computedBorderColor = mode(`${c}.500`, `${c}.500`)
  //     computedBorderColor = `${c}.500`
  //   }
  // }

  // const injectBorderProps = nbUtil.getInjectBorderUtilityProps({
  //   ...props,
  //   borderWidth: bw || predefinedBorderWidth,
  //   borderColor: computedBorderColor,
  // })
  return {
    // ...baseStyle(props),
    bg: 'trueGray.50',
    borderWidth: '1',
    // borderColor: `${c}.500`,
    // borderColor: bc || `${c}.500`,
    // ...injectBorderProps,
    _light: {
      borderColor: `${c}.500`,
      // borderColor: 'muted.300',
      _text: {
        color: `${c}.500`,
        fontWeight: 'semibold',
      },
      _icon: {
        color: `${c}.500`,
      },
      _spinner: {
        color: `${c}.600`,
      },
      _hover: {
        bg: `${c}.600:alpha.10`,
      },
      _pressed: {
        bg: `${c}.600:alpha.20`,
      },
    },
    _dark: {
      borderColor: `${c}.500`,
      // borderColor: 'muted.700',
      _text: {
        color: `${c}.500`,
        fontWeight: 'semibold',
      },
      _icon: {
        color: `${c}.500`,
      },
      _spinner: {
        color: `${c}.500`,
      },
      _hover: {
        bg: `${c}.500:alpha.10`,
      },
      _pressed: {
        bg: `${c}.500:alpha.20`,
      },
    },
    _disabled: {
      opacity: '40',
      borderColor: 'muted.500',
    },
  }
}

// function variantOutline(props) {
//   const { colorScheme: c, borderColor: bc, borderWidth: bw } = props

//   const predefinedBorderWidth = '1'
//   // const predefinedBorderColor = mode(`muted.200`, `muted.500`)

//   let computedBorderColor = bc || undefined
//   // if (!bc) {
//   if (c === 'muted' || props.isDisabled) {
//     // computedBorderColor = c === 'muted' ? predefinedBorderColor : props.isDisabled ? disabledTextColor() : mode(`${c}.300`, `${c}.300`)
//     computedBorderColor = disabledTextColor()
//   } else {
//     // computedBorderColor = mode(`${c}.300`, `${c}.300`)
//     computedBorderColor = mode(`${c}.500`, `${c}.500`)
//   }

//   const injectBorderProps = nbUtil.getInjectBorderUtilityProps({
//     ...props,
//     borderWidth: bw || predefinedBorderWidth,
//     borderColor: computedBorderColor,
//   })

//   return {
//     ...variantGhost(props),
//     ...injectBorderProps,
//     // bgColor: mode('white', 'dark.50'),
//     // _hover: {
//     //   bg: `${c}.600:alpha.20`,
//     // },
//     bgColor: mode('coolGray.50', 'warmGray.900'),
//   }

//   // const borderColor = mode(`muted.200`, `muted.500`)
//   // const bw = props.borderWidth ? props.borderWidth : '1'
//   // let bc
//   // if (props.borderColor) {
//   //   bc = props.borderColor
//   // }
//   // if (!bc) {
//   //   bc = c === 'muted' ? borderColor : props.isDisabled ? disabledTextColor() : mode(`${c}.300`, `${c}.300`)
//   // }
//   // return {
//   //   // borderWidth: '1',
//   //   // borderColor: c === 'muted' ? borderColor : props.isDisabled ? disabledTextColor(props) : mode(`${c}.300`, `${c}.300`),
//   //   borderTopWidth: bw,
//   //   borderLeftWidth: bw,
//   //   borderBottomWidth: bw,
//   //   borderRightWidth: bw,
//   //   borderTopColor: bc,
//   //   borderLeftColor: bc,
//   //   borderBottomColor: bc,
//   //   borderRightColor: bc,
//   //   ...variantGhost(props),
//   // }
// }

const variants = {
  // Cloned มาจาก src ของ native-base:: แก้ปัญหาเรื่อง outline ไม่มี borders ในเว็บ
  solid: variantSolid,
  ghost: variantGhost,
  outline: variantOutline,
}

const sizes = {
  lg: {
    px: '2.5',
    py: '2.5',
    // px: '3',
    // py: '3',
    _text: {
      fontSize: 'lg',
      // fontSize: 'md',
    },
    _icon: {
      size: 'md',
    },
  },
  md: {
    px: '2.5',
    py: '2',
    // px: '3',
    // py: '2.5',
    _text: {
      fontSize: 'md',
      // fontSize: 'sm',
    },
    _icon: {
      size: 'sm',
    },
  },
  sm: {
    px: '2',
    py: '1.5',
    // px: '3',
    // py: '2',
    _text: {
      fontSize: 'sm',
      // fontSize: 'xs',
    },
    _icon: {
      size: 'sm',
    },
  },
  xs: {
    px: '2',
    py: '1.5',
    // px: '3',
    // py: '2',
    _text: {
      fontSize: 'xs',
      // fontSize: '2xs',
    },
    _icon: {
      size: 'xs',
    },
  },
}

const defaultProps = {
  variant: 'solid',
  size: 'sm',
  colorScheme: 'primary',
}

export default {
  defaultProps,
  baseStyle,
  variants,
  sizes,
}
