import { connect } from 'react-redux'
import { getSelectedStore } from 'x/redux/selectors'
import StorePaymentAccountListView from './StorePaymentAccountListView'

export default connect(
  (state) => ({
    // paymentAccounts: getStorePaymentAccounts(state),
    // fetchAccounts: state.getIn(['bank', 'fetchAccounts']),
    // editingAccounts: state.getIn(['bank', 'editingAccounts']),
    selectedStore: getSelectedStore(state),
  }),
  (dispatch) => ({
    // bankActions: bindActionCreators(BankActions, dispatch),
    // fetchStorePaymentAccounts: bindActionCreators(BankActions.fetchStorePaymentAccounts, dispatch),
  })
)(StorePaymentAccountListView)
