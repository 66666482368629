import React from 'react'
import { Image, ImageSourcePropType, View } from 'react-native'

import * as util from 'x/utils/util'
import p from 'x/config/platform-specific'
import { COLORS, STYLES } from 'x/config/styles'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContent from 'xui/components/XContent'
import XContainer from 'xui/components/XContainer'
import BaseStoreSettingXShippingDHLView from 'x/modules/xshipping/BaseStoreSettingXShippingDHLView'
import { VIEW_LOADING } from 'xui/utils/BaseShareUI'
import XSettingRow from 'xui/components/XSettingRow'
import XHelpModal from 'xui/components/XHelpModal'
import FAQ from 'x/config/FAQ'
import { IQuestionsAndAnswers } from 'x/index'
import Box from 'xui/components/Box'
import XText from 'xui/components/XText'
import XButton from 'xui/components/XButton'

const IMG_DHL: ImageSourcePropType = require('../../images/courier/dhl.png')
const IMG_SHIPJUNG: ImageSourcePropType = require('../../images/courier/shipjung.png')

export default abstract class BaseUIStoreSettingXShippingDHLView extends BaseStoreSettingXShippingDHLView {
  static displayName = 'BaseUIStoreSettingXShippingDHLView'
  // abstract goBack: () => void

  _renderCustomHeader = () => (
    <XCustomHeader
      headerLeftProps={{
        backIcon: true,
        onPressItem: () => {
          util.navGoBack(this.props)
        },
      }}
      title={p.op.t('Order.shippingTypeItems.dhl')}
    />
  )

  _renderUseDHLxShipjung = () => {
    const {
      useDHLEconomyxShipjung,
      useAutoXShippingIsDHLEconomyxShipjungReadyToShip,
      isDHLEconomyxShipjungLoading,
      loadingDHLEconomyxShipjungHeight,
      loadingDHLEconomyxShipjungWidth,
    } = this.state
    const textTitle = 'ใช้งานการขอเลขพัสดุ\n"แบบไม่เก็บเงินปลายทาง"'
    // console.log('useAutoXShippingIsJAndTReadyToShip => ', useAutoXShippingIsJAndTReadyToShip)
    return (
      <View
        onLayout={(event) => {
          const { x, y, width, height } = event.nativeEvent.layout
          util.setStatePromise(this, { loadingDHLEconomyxShipjungWidth: width, loadingDHLEconomyxShipjungHeight: height })
        }}>
        <XSettingRow
          title={textTitle}
          value={useDHLEconomyxShipjung}
          onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
          stateKey='useDHLEconomyxShipjung'
          helper={{
            title: 'DHL ออกเลขพัสดุ',
            FAQ: FAQ.XSHIPPING_NOT_COD,
            initiateOpenIndex: 0,
          }}
          backgroundColor={COLORS.APP_SECONDARY}
          // disabled={useJAndT}
        />

        {useDHLEconomyxShipjung ? (
          <XSettingRow
            title='ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง'
            value={useAutoXShippingIsDHLEconomyxShipjungReadyToShip}
            onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
            stateKey='useAutoXShippingIsDHLEconomyxShipjungReadyToShip'
            helper={{
              title: 'ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง',
              FAQ: FAQ.XSHIPPING_READY_TO_SHIP,
              initiateOpenIndex: 0,
            }}
            backgroundColor={COLORS.APP_SECONDARY}
          />
        ) : null}

        {isDHLEconomyxShipjungLoading ? VIEW_LOADING(loadingDHLEconomyxShipjungWidth, loadingDHLEconomyxShipjungHeight) : null}
      </View>
    )
  }

  _renderDHLXShipjungCOD = () => {
    const {
      useDHLEconomyxShipjungCOD,
      useAutoXShippingIsDHLEconomyxShipjungCODReadyToShip,
      loadingDHLEconomyxShipjungCODHeight,
      loadingDHLEconomyxShipjungCODWidth,
      isDHLEconomyxShipjungCODLoading,
    } = this.state
    const textTitle = 'ใช้งานการขอเลขพัสดุ\n"แบบเก็บเงินปลายทาง"'
    // const isDisabled = useJAndTCOD
    return (
      <View
        onLayout={(event) => {
          const { x, y, width, height } = event.nativeEvent.layout
          util.setStatePromise(this, { loadingDHLEconomyxShipjungCODWidth: width, loadingDHLEconomyxShipjungCODHeight: height })
        }}>
        <XSettingRow
          title={textTitle}
          value={useDHLEconomyxShipjungCOD}
          onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
          stateKey='useDHLEconomyxShipjungCOD'
          helper={{
            title: 'DHL ออกเลขพัสดุ',
            FAQ: FAQ.XSHIPPING_COD,
            initiateOpenIndex: 0,
          }}
          backgroundColor={COLORS.APP_SECONDARY}
          // disabled={useJAndTCOD}
        />

        {useDHLEconomyxShipjungCOD ? (
          <XSettingRow
            title='ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง'
            value={useAutoXShippingIsDHLEconomyxShipjungCODReadyToShip}
            onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
            stateKey='useAutoXShippingIsDHLEconomyxShipjungCODReadyToShip'
            helper={{
              title: 'ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง',
              FAQ: FAQ.XSHIPPING_READY_TO_SHIP,
              initiateOpenIndex: 0,
            }}
            backgroundColor={COLORS.APP_SECONDARY}
          />
        ) : null}

        {isDHLEconomyxShipjungCODLoading ? VIEW_LOADING(loadingDHLEconomyxShipjungCODWidth, loadingDHLEconomyxShipjungCODHeight) : null}
      </View>
    )
  }

  _renderAgreement = (onPressOpenOverlay: () => void) => (
    <XButton onPress={() => onPressOpenOverlay()} w='48' variant='outline' alignSelf='center'>
      <XText variant='primary'>ดูข้อตกลงและวิธีการใช้งาน</XText>
    </XButton>
  )

  _getFAQAgreementForDHLxShipjung = (): IQuestionsAndAnswers[] => {
    const newFQA = []
    FAQ.XSHIPPING_AGREEMENT_DHL_ECONOMY_X_SHIPJUNG.map((faq: IQuestionsAndAnswers) => {
      newFQA.push(faq)
    })
    FAQ.XSHIPPING_AGREEMENT_SHIPJUNG_COMMON.map((faq: IQuestionsAndAnswers) => {
      newFQA.push(faq)
    })
    return newFQA
  }

  _renderDHLxShipjung = () => (
    <Box
      _light={{ bg: 'white' }}
      _dark={{ bg: 'gray.500' }}
      borderRadius='md'
      style={{
        // backgroundColor: COLORS.WHITE,
        marginTop: 10,
        marginRight: 6,
        marginLeft: 6,
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 6,
        paddingBottom: 15,
      }}>
      <Box style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
        <Image
          source={IMG_DHL}
          resizeMode='stretch'
          style={{
            // position: 'absolute',
            width: 96,
            height: 30,
            backgroundColor: 'transparent',
            zIndex: 70,
            marginLeft: 0,
          }}
        />
        <XText style={{ fontSize: STYLES.FONT_SIZE_LARGER, color: COLORS.TEXT_ACTIVE, paddingRight: 10, paddingLeft: 20 }}>X</XText>
        <Image
          source={IMG_SHIPJUNG}
          resizeMode='stretch'
          style={{
            // position: 'absolute',
            width: 90,
            height: 58,
            backgroundColor: 'transparent',
            zIndex: 70,
          }}
        />
      </Box>
      <Box style={{ justifyContent: 'center', alignItems: 'center' }}>
        <XText variant='active'>ขอเลขพัสดุจาก DHL Economy ผ่าน Shipjung</XText>
      </Box>

      <XHelpModal
        key='XHelpModalDHL'
        headerTitle='ข้อตกลงและวิธีการใช้งาน'
        FAQ={this._getFAQAgreementForDHLxShipjung()}
        initiateOpenIndex={4}
        renderButton={(onPressOpenOverlay: () => void) => this._renderAgreement(onPressOpenOverlay)}
      />

      <Box h='2' />
      <Box style={{ paddingBottom: 2, flexDirection: 'row' }}>
        <XText variant='inactive'>{'รูปแบบจัดส่ง: '}</XText>
        <XText variant='active' bold>{`${p.op.t('Order.shippingTypeItems.dhl')}`}</XText>
      </Box>
      {this._renderUseDHLxShipjung()}
      <Box h='2' />
      <Box style={{ paddingBottom: 2, flexDirection: 'row' }}>
        <XText variant='inactive'>{'รูปแบบจัดส่ง: '}</XText>
        <XText variant='active' bold>{`${p.op.t('Order.shippingTypeItems.dhl_cod')}`}</XText>
      </Box>
      {this._renderDHLXShipjungCOD()}
    </Box>
  )

  render() {
    return (
      <XContainer>
        {this._renderCustomHeader()}
        <XContent>
          <Box w='full'>{this._renderDHLxShipjung()}</Box>
        </XContent>
      </XContainer>
    )
  }
}
