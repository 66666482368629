import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
// import { NavigationActions } from 'react-navigation'
import SettingResetPasswordView from './SettingResetPasswordView'
import * as ProfileActions from 'x/modules/profile/ProfileState'
import { getSubscription, getSelectedStore } from 'x/redux/selectors'

export default connect(
  (state) => ({
    profile: state.get('profile'),
    // subscription: getSubscription(state),
    selectedStore: getSelectedStore(state),
  }),
  (dispatch) => {
    return {
      // navigate: bindActionCreators(NavigationActions.navigate, dispatch),
      dispatch,
      fetchUserProfile: bindActionCreators(ProfileActions.fetchUserProfile, dispatch),
    }
  }
)(SettingResetPasswordView)
