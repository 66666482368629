import React from 'react'
import { Text, View, TouchableOpacity } from 'react-native'

import * as util from 'x/utils/util'
import p from 'x/config/platform-specific'
import { COLORS, STYLES } from 'x/config/styles'
import XCustomHeader from 'xui/components/XCustomHeader'
import BaseAddressXShippingView from 'x/modules/address/BaseAddressXShippingView'
import XIcon from 'xui/components/XIcon'
import XInput from 'xui/components/XInput'
import XFlatPicker from 'xui/components/XFlatPicker'
import _ from 'lodash'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import HStack from 'xui/components/HStack'
import Box from 'xui/components/Box'
import XText from 'xui/components/XText'

const TITLE_LABEL_WIDTH = 80
export default abstract class BaseUIAddressXShippingView extends BaseAddressXShippingView {
  static displayName = 'BaseUIAddressXShippingView'
  // abstract goBack: () => void

  _renderCustomHeader = () => (
    <XCustomHeader
      // headerLeftProps={{
      //   backIcon: true,
      //   onPressItem: () => {
      //     const { navigation } = this.props
      //     if (!navigation || !navigation.state) {
      //       return
      //     }
      //     const { state, goBack } = navigation
      //     goBack(state.key)
      //   },
      // }}
      renderHeaderLeft={this._renderHeaderLeft}
      renderHeaderRight={this._renderHeaderRight}
      title='ที่อยู่'
    />
  )

  _renderHeaderLeft = () => (
    // const { navigation } = this.props
    // const { state } = navigation
    <TouchableOpacity style={{ width: 50, height: 50, justifyContent: 'center', paddingLeft: 10 }} onPress={() => this._submitBtn()}>
      <Text style={{ color: COLORS.APP_MAIN }}>{p.op.t('save')}</Text>
    </TouchableOpacity>
  )

  _renderHeaderRight = () => {
    const callBackAddressUpdate = util.getNavParam(this.props, 'callBackAddressUpdate')
    return (
      <TouchableOpacity
        style={{ width: 50, height: 50, justifyContent: 'center', paddingRight: 4 }}
        onPress={() => {
          p.op.showConfirmation(
            'ออกจากหน้านี้',
            'การแก้ไขต่างๆจะไม่ได้รับการบันทึก',
            () => {
              util.navGoBack(this.props)
              if (_.isFunction(callBackAddressUpdate)) {
                callBackAddressUpdate(null)
              }
            },
            () => {},
            'ใช่',
            'ยกเลิก'
          )
        }}>
        <Text style={{ color: COLORS.APP_MAIN }}>{p.op.t('Order.cancelled')}</Text>
      </TouchableOpacity>
    )
  }

  _renderNameInput = () => {
    const { textName } = this.state
    // const { navigation } = this.props
    // const { state } = navigation
    // const { name } = state.params
    const name = util.getNavParam(this.props, 'name')

    let nameDisplay = 'ชื่อ'
    if (!_.isNil(name)) {
      nameDisplay = name
    }
    return (
      <Box style={{ height: 40, flexDirection: 'row', marginTop: 10 }} w='56'>
        <View style={{ width: TITLE_LABEL_WIDTH, justifyContent: 'center' }}>
          <XText style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE }}>{nameDisplay}</XText>
        </View>
        <XInput
          // disabled={falas}
          w='full'
          style={{ textAlign: 'left', fontSize: STYLES.FONT_SIZE_NORMAL, paddingLeft: 4 }}
          value={textName}
          onChangeText={async (text: string) => {
            await util.setStatePromise(this, {
              textName: text,
            })
          }}
          placeholder=''
          placeholderTextColor={COLORS.TEXT_INACTIVE}
          keyboardType='default'
          // isInteger={true}
        />
      </Box>
    )
  }

  _renderPhonInput = () => {
    const { textPhon } = this.state
    return (
      <Box style={{ height: 40, flexDirection: 'row', marginTop: 10 }} w='56'>
        <View style={{ width: TITLE_LABEL_WIDTH, justifyContent: 'center' }}>
          <XText style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE }}>เบอร์โทร</XText>
        </View>
        <XInput
          w='full'
          style={{ textAlign: 'left', fontSize: STYLES.FONT_SIZE_NORMAL, paddingLeft: 4 }}
          value={textPhon}
          onChangeText={async (text: string) => {
            await util.setStatePromise(this, {
              textPhon: text,
            })
          }}
          placeholder=''
          placeholderTextColor={COLORS.TEXT_INACTIVE}
          keyboardType='default'
          // isInteger={true}
        />
      </Box>
    )
  }

  renderSelectedValue = (data: any) => {
    const { item, index, disabled } = data
    // const item = data.item
    // console.log('data => ', data)
    return (
      <HStack w='full' alignItems='center'>
        <View style={{ paddingLeft: 4, width: 190 }}>
          <XText>{item.label}</XText>
        </View>

        <View
          style={{
            borderLeftWidth: 1,
            borderLeftColor: disabled ? COLORS.TEXT_INACTIVE : COLORS.TEXT_INACTIVE,
            height: 25,
            width: 2,
            alignSelf: 'center',
          }}
        />

        <Box style={{ alignItems: 'center', justifyContent: 'center' }}>
          <XIcon name='down' family='AntDesign' style={{ color: disabled ? COLORS.TEXT_INACTIVE : COLORS.APP_MAIN, fontWeight: 'bold' }} />
        </Box>
      </HStack>
    )
  }

  _renderPostcodeInput = () => {
    const { textPostcode } = this.state
    return (
      <Box style={{ height: 40, flexDirection: 'row', marginTop: 10 }} w='56'>
        <View style={{ width: TITLE_LABEL_WIDTH, justifyContent: 'center' }}>
          <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE }}>รหัสไปรษณีย์</Text>
        </View>
        <XInput
          w='full'
          style={{ textAlign: 'left', fontSize: STYLES.FONT_SIZE_NORMAL, paddingLeft: 4 }}
          value={textPostcode}
          onChangeText={async (text: string) => {
            await this._onChangePostcodeText(text)
          }}
          placeholder=''
          placeholderTextColor={COLORS.TEXT_INACTIVE}
          keyboardType='default'
          // isInteger={true}
        />
      </Box>
    )
  }

  _renderProvinceInput = () => {
    const { selectedProvinceIndex, provinceFromZipcode } = this.state
    if (_.isNil(provinceFromZipcode) || provinceFromZipcode.length < 1) {
      return null
    }
    const isDisabled = provinceFromZipcode.length === 1
    const borderColor = isDisabled ? COLORS.TEXT_INACTIVE : COLORS.TEXT_ACTIVE
    const handleChangeOrderShippingType = async (itemInfo) => {
      const { item, index } = itemInfo
      // if (!item || !item.value) {
      //   return
      // }
      // const newShippingTypeId = item.value
      await util.setStatePromise(this, {
        selectedProvinceIndex: index,
      })
      // onEditingOrderChange({ key: 'shipping_type_id', value: newShippingTypeId, orderTabIndex })
    }

    const renderCommonFlatPickerItem = (data) => {
      const { item, index, disabled } = data
      // const shippingState = util.getShippingHasXShippingLabel(item.value)
      // console.log('shippingState => ', shippingState)
      // const UNDER_LINE = p.op.isWeb() ? {} : { borderBottomColor: COLORS.TEXT_INACTIVE, borderBottomWidth: 1 }
      return (
        <HStack w='80' h='10' alignItems='center'>
          <Box flex={1} flexDirection='row' mx='2'>
            <XText variant='active'>{item.label}</XText>
          </Box>
          <XIcon name='arrowright' family='AntDesign' mr='1' />
        </HStack>
      )
    }

    return (
      <View style={{ height: 40, flexDirection: 'row', marginTop: 10 }}>
        <View style={{ width: TITLE_LABEL_WIDTH, justifyContent: 'center' }}>
          <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE }}>จังหวัด</Text>
        </View>
        <View
          style={{
            flex: 1,
            borderWidth: 1,
            alignItems: 'center',
            borderColor,
            flexDirection: 'row',
            // backgroundColor: COLORS.ORANGE_SOFT,
          }}>
          <XFlatPicker
            disabled={isDisabled}
            title='เลือกจังหวัด'
            placeholder='กรุณาเลือกรูปแบบจัดส่ง'
            placeholderStyle={{ marginLeft: 6 }}
            // ref={this._provincePickerRef}
            selectedIndex={selectedProvinceIndex}
            options={provinceFromZipcode}
            onPick={handleChangeOrderShippingType}
            renderSelectedValue={this.renderSelectedValue}
            renderItem={renderCommonFlatPickerItem}
          />
        </View>
      </View>
    )
  }

  _renderDistrictInput = () => {
    const { selectedDistrictIndex, districtFromZipcode } = this.state
    if (_.isNil(districtFromZipcode) || districtFromZipcode.length < 1) {
      return null
    }
    const isDisabled = districtFromZipcode.length === 1
    // const TEST_ADDRESSES = [
    //   {
    //     id: 0,
    //     label: 'ปัว',
    //   },
    //   {
    //     id: 0,
    //     label: 'ปัว 02',
    //   },
    // ]
    const handleChangeOrderShippingType = (itemInfo) => {
      const { item, index } = itemInfo
      // if (!item || !item.value) {
      //   return
      // }
      // const newShippingTypeId = item.value
      util.setStatePromise(this, {
        selectedDistrictIndex: index,
      })
      // onEditingOrderChange({ key: 'shipping_type_id', value: newShippingTypeId, orderTabIndex })
    }

    const renderCommonFlatPickerItem = (data) => {
      const { item, index, disabled } = data
      // const shippingState = util.getShippingHasXShippingLabel(item.value)
      // console.log('shippingState => ', shippingState)
      // const UNDER_LINE = p.op.isWeb() ? {} : { borderBottomColor: COLORS.TEXT_INACTIVE, borderBottomWidth: 1 }
      return (
        <HStack w='80' h='10' alignItems='center'>
          <Box flex={1} flexDirection='row' mx='2'>
            <XText variant='active'>{item.label}</XText>
          </Box>
          <XIcon name='arrowright' family='AntDesign' mr='1' />
        </HStack>
      )
    }
    return (
      <View style={{ height: 40, flexDirection: 'row', marginTop: 10 }}>
        <View style={{ width: TITLE_LABEL_WIDTH, justifyContent: 'center' }}>
          <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE }}>เขต/อำเภอ</Text>
        </View>
        <View
          style={{
            flex: 1,
            borderWidth: 1,
            alignItems: 'center',
            borderColor: isDisabled ? COLORS.TEXT_INACTIVE : COLORS.TEXT_ACTIVE,
            flexDirection: 'row',
            // backgroundColor: COLORS.ORANGE_SOFT,
          }}>
          <XFlatPicker
            disabled={isDisabled}
            title='เลือกจังหวัด'
            placeholder='กรุณาเลือกรูปแบบจัดส่ง'
            placeholderStyle={{ marginLeft: 6 }}
            // ref={this._provincePickerRef}
            selectedIndex={selectedDistrictIndex}
            options={districtFromZipcode}
            onPick={handleChangeOrderShippingType}
            renderSelectedValue={this.renderSelectedValue}
            renderItem={renderCommonFlatPickerItem}
          />
        </View>
      </View>
    )
  }

  _renderSubDistrictInput = () => {
    const { textSubDistrict } = this.state
    return (
      <Box style={{ height: 40, flexDirection: 'row', marginTop: 10 }} w='56'>
        <View style={{ width: TITLE_LABEL_WIDTH, justifyContent: 'center' }}>
          <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE }}>แขวง/ตำบล</Text>
        </View>
        <XInput
          w='full'
          style={{ textAlign: 'left', fontSize: STYLES.FONT_SIZE_NORMAL, paddingLeft: 4 }}
          value={textSubDistrict}
          onChangeText={async (text: string) => {
            await util.setStatePromise(this, {
              textSubDistrict: text,
            })
          }}
          placeholder=''
          placeholderTextColor={COLORS.TEXT_INACTIVE}
          keyboardType='default'
          // isInteger={true}
        />
      </Box>
    )
  }

  _renderAddressInput = () => {
    const { textAddress } = this.state
    return (
      <Box style={{ height: 40, flexDirection: 'row', marginTop: 10 }} w='56'>
        <View style={{ width: TITLE_LABEL_WIDTH, justifyContent: 'center' }}>
          <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE }}>ที่อยู่</Text>
        </View>
        <XInput
          w='full'
          style={{ textAlign: 'left', fontSize: STYLES.FONT_SIZE_NORMAL, paddingLeft: 4 }}
          value={textAddress}
          onChangeText={async (text: string) => {
            await util.setStatePromise(this, {
              textAddress: text,
            })
          }}
          placeholder=''
          placeholderTextColor={COLORS.TEXT_INACTIVE}
          keyboardType='default'
          // isInteger={true}
        />
      </Box>
    )
  }

  _renderTitleDescription = () => {
    // const { navigation } = this.props
    // const { state } = navigation
    // const { titleDescription } = state.params
    const titleDescription = util.getNavParam(this.props, 'titleDescription')
    if (_.isNil(titleDescription)) {
      return null
    }
    return (
      <View style={{ height: 40 }}>
        <XText variant='active'>{titleDescription}</XText>
      </View>
    )
  }

  renderBody = () => (
    <Box m='2' p='2' alignItems='center' flex={1} _dark={{ bg: 'gray.800' }} _light={{ bg: 'white' }}>
      <Box style={{ justifyContent: 'center', paddingTop: 10 }}>
        {this._renderTitleDescription()}
        {this._renderNameInput()}
        {this._renderPhonInput()}
        {this._renderPostcodeInput()}
        {this._renderProvinceInput()}
        {this._renderDistrictInput()}
        {this._renderSubDistrictInput()}
        {this._renderAddressInput()}
      </Box>
    </Box>
  )

  render() {
    return (
      <XContainer>
        {this._renderCustomHeader()}
        <XContent>
          <Box w='full'>{this.renderBody()}</Box>
        </XContent>
      </XContainer>
    )
  }
}
