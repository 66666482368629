import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ResellerListPendingTab from 'xui/modules/userGroup/ResellerListPendingTab'
import * as ResellerStateActions from 'x/modules/reseller/ResellerState'

import {
  getUsersInGroupByKey,
  getIsFetchingUsersInGroupByKey,
  getIsAllFetchedUsersInGroupByKey,
  getCountOfUsersInGroupByKey,
  getShouldFetchOfUsersInGroupByKey,
} from 'x/redux/selectors'

const prefixKey = 'pending'
export default connect(
  (state) => ({
    users: getUsersInGroupByKey(state, prefixKey),
    isFetching: getIsFetchingUsersInGroupByKey(state, prefixKey),
    isAllFetched: getIsAllFetchedUsersInGroupByKey(state, prefixKey),
    count: getCountOfUsersInGroupByKey(state, prefixKey),
    shouldFetch: getShouldFetchOfUsersInGroupByKey(state, prefixKey),
  }),
  (dispatch) => {
    return {
      fetchUserList: bindActionCreators(ResellerStateActions.fetchResellerPendingList, dispatch),
      changeUsersPendingStatus: bindActionCreators(ResellerStateActions.changeUsersPendingStatus, dispatch),
    }
  }
)(ResellerListPendingTab)
