import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as StoreActions from 'x/modules/store/StoreState'
import { getSelectedStore, getEditingStore } from 'x/redux/selectors'
import StoreSettingListView from './StoreSettingListView'

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
    editingStore: getEditingStore(state),
  }),
  (dispatch) => ({
    resetEditingStore: bindActionCreators(StoreActions.resetEditingStore, dispatch),
    // storeActions: bindActionCreators(StoreActions, dispatch),
  })
)(StoreSettingListView)
