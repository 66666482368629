import _ from 'lodash'
import { Platform } from 'react-native'
import NB from '../../nativebase'

function baseStyle(props: any) {
  const { fontWeight = 'normal', fontFamily = 'Prompt-Regular', fontStyle = 'normal', bold, theme } = props

  const injectProps: any = { fontWeight, fontFamily, fontStyle }

  let fw = fontWeight
  // Font Weight of '700' or 'bold' not working in Android for Custom fonts
  // https://github.com/akveo/react-native-ui-kitten/issues/1501
  // https://stackoverflow.com/questions/16993904/android-setting-textview-to-bold-not-working
  if (
    Platform.OS === 'android' &&
    (bold ||
      (_.isNumber(parseInt(fontWeight)) && parseInt(fontWeight) >= 700) ||
      _.includes(['bold', 'extrabold', 'black', 'extraBlack'], fontWeight))
  ) {
    // injectProps.fontWeight = '600'
    // injectProps.fontFamily = 'Prompt-Bold'
    fw = '600'
  }

  if (theme && theme.fontWeights) {
    const fontWeightScale = theme.fontWeights
    const fwValue = fw ? _.get(fontWeightScale, fw, fw).toString() : fw
    const ffValue = theme.fontConfig.Prompt[fwValue][fontStyle] || 'Prompt-Regular'
    if (Platform.OS === 'android') {
      injectProps.fontWeight = bold ? '600' : fwValue
    } else {
      injectProps.fontWeight = bold ? 'bold' : fwValue
    }
    injectProps.fontFamily = bold ? 'Prompt-Bold' : ffValue
  }

  // console.log('text baseStyle props => ', props)
  // console.log('text baseStyle injectProps => ', injectProps)

  return {
    _light: {
      color: 'text.900',
    },
    _dark: {
      color: 'text.50',
    },
    // fontWeight: '400',
    // fontFamily,
    // fontFamily: props && props.bold ? 'Prompt-Bold' : 'Prompt-Regular',
    // fontFamily: props && props.bold ? 'Prompt-Bold' : 'Prompt-Regular',
    // fontFamily: 'Prompt',
    // fontFamily: 'body',
    // fontStyle: 'normal',
    fontSize: 'sm',
    letterSpacing: 'md',
    lineHeight: 'lg',

    ...injectProps,
  }
}

const defaultProps = {
  fontWeight: '500',
  fontFamily: 'Prompt-Regular',
  fontStyle: 'normal',
  variant: 'active',
  colorScheme: 'text',
}
// const baseStyle = (props: Record<string, any>) => ({
//   // color: mode('coolGray.800', 'warmGray.50')(props),
//   // color: mode('coolGray.800', 'warmGray.50'),
//   fontWeight: '500',
//   fontFamily: 'body',
//   fontStyle: 'normal',
//   fontSize: 'sm',
//   letterSpacing: 'md',
//   lineHeight: 'lg',
//   // letterSpacing: 'xl',
//   // lineHeight: 'md',
//   ...variantActive,
// })

const variantPrimary = (props) => ({
  // color: 'primary.500',
  // color: mode(L.TEXT.PRIMARY, D.TEXT.PRIMARY),
  // color: NB.HC.TEXT.PRIMARY(),
  _light: {
    color: NB.C.L.TEXT.PRIMARY,
  },
  _dark: {
    color: NB.C.D.TEXT.PRIMARY,
  },
})

// default text variant
const variantActive = (props) => ({
  // color: mode('coolGray.800', 'warmGray.50'),
  // color: mode(L.TEXT.ACTIVE, D.TEXT.ACTIVE),
  // color: NB.HC.TEXT.ACTIVE(),
  _light: {
    color: NB.C.L.TEXT.ACTIVE,
  },
  _dark: {
    color: NB.C.D.TEXT.ACTIVE,
  },
})

const variantInactive = (props) => ({
  // color: mode('muted.500', 'muted.300'),
  // color: mode(L.TEXT.INACTIVE, D.TEXT.INACTIVE),
  // color: NB.HC.TEXT.INACTIVE(),
  _light: {
    color: NB.C.L.TEXT.INACTIVE,
  },
  _dark: {
    color: NB.C.D.TEXT.INACTIVE,
  },
})

const variantActiveDark = (props) => ({
  // color: mode('coolGray.100', 'warmGray.50'),
  // color: mode(L.TEXT.ACTIVE_DARK, D.TEXT.ACTIVE_DARK),
  // color: NB.HC.TEXT.ACTIVE_DARK(),
  _light: {
    color: NB.C.L.TEXT.ACTIVE_DARK,
  },
  _dark: {
    color: NB.C.D.TEXT.ACTIVE_DARK,
  },
})

const variantDanger = (props) => ({
  // color: mode('danger.500', 'danger.200'),
  // color: mode(L.TEXT.DANGER, D.TEXT.DANGER),
  // color: NB.HC.TEXT.DANGER(),
  _light: {
    color: NB.C.L.TEXT.DANGER,
  },
  _dark: {
    color: NB.C.D.TEXT.DANGER,
  },
})

const variantWarning = (props) => ({
  // color: NB.HC.TEXT.WARNING(),
  _light: {
    color: NB.C.L.TEXT.WARNING,
  },
  _dark: {
    color: NB.C.D.TEXT.WARNING,
  },
})

const variantSuccess = (props) => ({
  // color: mode('success.500', 'success.200'),
  // color: mode(L.TEXT.SUCCESS, D.TEXT.SUCCESS),
  // color: NB.HC.TEXT.SUCCESS(),
  _light: {
    color: NB.C.L.TEXT.SUCCESS,
  },
  _dark: {
    color: NB.C.D.TEXT.SUCCESS,
  },
})

const variants = {
  primary: variantPrimary,
  active: variantActive,
  inactive: variantInactive,
  activeDark: variantActiveDark,
  danger: variantDanger,
  warning: variantWarning,
  success: variantSuccess,
}

export default {
  baseStyle,
  variants,
  defaultProps,
}
