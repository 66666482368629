import { List, Map, fromJS } from 'immutable'
// import * as util from '../../utils/util'
import {
  take,
  takeLatest,
  // takeEvery,
  put,
  call,
  // spawn,
  fork,
  // select,
  all,
} from 'redux-saga/effects'
import _ from 'lodash'
import * as CategoryActions from 'x/modules/category/categoryState'
import ProductSlice from 'x/modules/product/ProductSlice'
import api, { fetchProductDetail } from '../../utils/api'

import CONS from '../../config/constants'
// import actions from '../../config/actions'
import * as ProductActions from '../../modules/product/ProductState'
import { initProduct } from '../../modules/product/ProductState'
import p from '../../config/platform-specific'

import {
  log,
  // deleteImmutableListAtIndex,
} from '../../utils/util'

import { getSelectedUserGroups, getSelectedProductGroups } from '../selectors'

import { ActionApiParams } from '../../index'
import * as ProductState from '../../modules/product/ProductState'
import actions from '../../config/actions'

const { VIEW_SELF, PULL_MY_PRODUCT_TO_ORDER, PULL_PRODUCT } = CONS.PRODUCT_VIEW_MODE

export default function* watchAllProducts() {
  yield all([
    fork(watchCreateProduct),
    fork(watchPullProductToMyStore),
    fork(watchDeleteProduct),
    fork(watchEditProduct),
    fork(watchFetchProduct),
    fork(watchFetchProductList),
    fork(watchNewSearchProductList),
    // fork(watchFetchSellerProductList),
    fork(watchProductListFromVolumeDiscount),
  ])
}

// Product Detail
function* watchCreateProduct() {
  log('In watchCreateProduct')
  while (true) {
    const action = yield take(ProductActions.PRODUCT_CREATE)
    yield call(createProduct, action)
  }
}

function* createProduct(action: ActionApiParams) {
  const { body, successCallback, failedCallback } = action
  const apiOptions = {
    messages: {
      successMsg: 'การสร้างสินค้าสำเร็จ',
      errorMsg: 'การสร้างสินค้าล้มเหลว',
    },
    showSpinner: true,
    axiosOptions: {
      retry: 0,
      timeout: 120000, // long fetch 120 วินาที
    },
  }
  try {
    log('In createProduct body => ', body)
    const res = yield call(api.put, api.PUT_PRODUCT_ADD, body, apiOptions)
    log('In createProduct response => ', res)
    yield call(loadProductFromResponse, res, { mode: VIEW_SELF, shouldFetchList: false, shouldUpdateLocalList: true })
    yield put({ type: actions.SELECTED_STORE_SHOULD_FETCH })

    if (_.isFunction(successCallback)) {
      successCallback(res)
    }
    // yield put(NavActions.goBack) // go back to StoreMyView
  } catch (error) {
    yield put({ type: ProductActions.ERROR_PRODUCT_CREATE })
    if (_.isFunction(failedCallback)) {
      failedCallback(error)
    }
  }
}

// Pull Product to my store
function* watchPullProductToMyStore() {
  log('In watchPullProductToMyStore')
  while (true) {
    const action = yield take(ProductActions.PRODUCT_PULL_TO_MY_STORE)
    yield call(pullProductToMyStore, action)
  }
}

function* pullProductToMyStore(action: ActionApiParams) {
  // const { VIEW_SELF } = CONS.PRODUCT_VIEW_MODE
  const { body, successCallback, failedCallback } = action
  try {
    const apiOptions = {
      messages: {
        successMsg: 'ดึงสินค้าเข้าร้านฉันสำเร็จแล้ว',
        errorMsg: 'เกิดข้อผิดพลาดในการดึงสินค้าเข้าร้าน',
      },
      showSpinner: true,
      axiosOptions: {
        retry: 0,
        timeout: 120000, // long fetch 120 วินาที
      },
    }
    log('In pullProductToMyStore body => ', body)
    const res = yield call(api.put, api.PUT_PRODUCT_PULL, body, apiOptions)
    log('In pullProductToMyStore response => ', res)
    yield call(removePulledProductFromList, body.product_id)
    yield put({ type: actions.SELECTED_STORE_SHOULD_FETCH })
    // yield call(loadProductFromResponse, res, VIEW_SELF, true)
    // yield put(NavActions.goBack) // go back to StoreMyView
    if (_.isFunction(successCallback)) {
      successCallback(res)
    }
  } catch (error) {
    // let errObj = JSON.parse(error.message)
    // // TODO: FIX this MANUAL Error handling
    // if (errObj.error.message === 'The product is already pulled') {
    //   p.op.alert('เกิดข้อผิดพลาด', 'สินค้าชิ้นนี้ถูกดึงเข้าร้านฉันเรียบร้อยแล้ว')
    // }
    // log('_PUT_PRODUCT_PULL__errObj.error: ', errObj.error)
    yield put({ type: ProductActions.ERROR_PRODUCT_CREATE }) // TODO by Keng: No receiver of this action
    if (_.isFunction(failedCallback)) {
      failedCallback(error)
    }
  }
}

// Delete Product
function* watchDeleteProduct() {
  log('In watchDeleteProduct')
  while (true) {
    const action = yield take(ProductActions.PRODUCT_DELETE)
    yield call(deleteProduct, action)
  }
}

function* deleteProduct(action: ActionApiParams) {
  const { body, successCallback, failedCallback } = action
  const apiOptions = {
    messages: {
      successMsg: 'การลบสินค้าสำเร็จ',
      errorMsg: 'การลบสินค้าล้มเหลว',
    },
    showSpinner: true,
  }
  try {
    log('In deleteProduct body => ', body)
    const res = yield call(api.del, api.DELETE_PRODUCT, body, apiOptions)
    log('In deleteProduct res => ', res)
    if (res.products) {
      // Backend will return products (ProductList) but we will delelte our focus product from selectedProducts
      yield put(ProductActions.removePulledProductFromList(body.product_id))
    }

    // yield put({ type: ProductActions.SHOULD_FETCH_LIST })
    if (_.isFunction(successCallback)) {
      successCallback(res)
    }
    log('In deleteProduct response => ', res)
    // yield put(NavActions.goBack) // go back to Product List
  } catch (error) {
    // if (error && error.message) {
    //   let errObj = JSON.parse(error.message)
    //   if (errObj.error.message.includes('product has been ordered')) {
    //     Alert.alert('ไม่สามารถลบสิ้นค้าชิ้นนี้ได้', 'เนื่องจากสินค้าชิ้นนี้ถูกใช้ในออเดอร์แล้ว จึงไม่สามารถลบได้')
    //   }
    // }
    yield put({ type: ProductActions.ERROR_PRODUCT_DELETE })
    if (_.isFunction(failedCallback)) {
      failedCallback(error)
    }
  }
}

function* watchEditProduct() {
  log('In watchEditProduct')
  while (true) {
    const action = yield take(ProductActions.PRODUCT_EDIT)
    yield call(editProduct, action)
  }
}

function* editProduct(action: ActionApiParams) {
  const { body, successCallback, failedCallback } = action
  const apiOptions = {
    messages: {
      successMsg: 'การแก้ไขสินค้าสำเร็จ',
      errorMsg: 'การแก้ไขสินค้าล้มเหลว',
    },
    showSpinner: true,
    axiosOptions: {
      retry: 0,
      timeout: 120000, // long fetch  2 นาที
    },
  }
  try {
    log('In editProduct body => ', body)
    const res = yield call(api.patch, api.PATCH_PRODUCT, body, apiOptions)
    log('In editProduct response => ', res)

    // Load to product detail + Modify product in list
    yield call(loadProductFromResponse, res, { mode: VIEW_SELF, shouldFetchList: false, shouldUpdateLocalList: true })

    // yield put({ type: ProductActions.SHOULD_FETCH_LIST })
    if (_.isFunction(successCallback)) {
      successCallback(res)
    }
    // yield put(NavActions.goBack) // go back to StoreMyView
  } catch (error) {
    yield put({ type: ProductActions.ERROR_PRODUCT_EDIT, error })
    if (_.isFunction(failedCallback)) {
      failedCallback(error)
    }
  }
}

function* watchFetchProduct() {
  log('In watchFetchProduct')
  while (true) {
    const action = yield take(ProductActions.FETCH_PRODUCT)
    yield call(fetchProduct, action)
  }
}

function* fetchProduct(action: {
  body: { [key: string]: any }
  mode: string
  user_group_id?: number
  product_group_ids?: number[]
  shouldFetchList?: boolean
  shouldUpdateLocalList?: boolean
  successCallback?: (res: Response) => void
  failedCallback?: (err: Error) => void
}) {
  const {
    body,
    mode,
    successCallback,
    failedCallback,
    user_group_id,
    product_group_ids,
    shouldFetchList = false,
    shouldUpdateLocalList = false,
  } = action
  if (!body || _.isEmpty(body) || !mode) {
    return
  }

  const apiOptions = {
    // messages: {
    //   successMsg: 'การสร้างสินค้าสำเร็จ',
    //   errorMsg: 'การสร้างสินค้าล้มเหลว',
    // },
    axiosOptions: {
      timeout: 60000,
    },
    showSpinner: true,
  }

  try {
    log('In fetchProduct body => ', body)
    // const res = yield call(api.post, api.POST_PRODUCT, body, apiOptions)
    // @ts-ignore
    const res = yield call(fetchProductDetail, { ...body, mode, user_group_id, product_group_ids })
    yield put(ProductActions.loadProduct(fromJS(res), shouldFetchList, shouldUpdateLocalList))
    log('In fetchProduct response => ', res)
    if (_.isFunction(successCallback)) {
      successCallback(res)
    }
    // yield call(loadProductFromResponse, res, action)
  } catch (error) {
    yield put({ type: ProductActions.ERROR_FETCH_PRODUCT_LIST })
    if (_.isFunction(failedCallback)) {
      failedCallback(error)
    }
  }
}

function* loadProductFromResponse(res, action) {
  if (res.product) {
    yield put(ProductSlice.actions.setFetchedProduct({ product: res.product }))
  }
  const {
    mode = VIEW_SELF,
    shouldFetchList = false,
    shouldUpdateLocalList = false,
    user_group_id = null,
    product_group_ids = null,
  } = action
  if ('product' in res) {
    // let newProduct = initProduct
    // // Sorting variants by id from response
    // let resProduct = _.clone(res.product)
    // let variants = _.clone(resProduct.variants)
    // log('loadProductFromResponse variants before sort => ', variants)
    // variants = _.sortBy(variants, [v => v.pp_id])
    // log('loadProductFromResponse variants after sort => ', variants)
    // resProduct.variants = variants
    // // newProduct = newProduct.mergeDeep(fromJS(res.product)) // Modify for sorting variants
    // newProduct = newProduct.mergeDeep(fromJS(resProduct))
    // let newProduct = initProduct
    // Sorting variants by id from response
    const resProduct = _.cloneDeep(res.product)

    resProduct.img_uris = resProduct.img_uris.filter((iuri) => _.isString(iuri) && iuri.length > 0)
    resProduct.thumbnail_uris = resProduct.thumbnail_uris.filter((iuri) => _.isString(iuri) && iuri.length > 0)
    resProduct.tiny_img_uris = resProduct.tiny_img_uris.filter((iuri) => _.isString(iuri) && iuri.length > 0)

    // Filter ในการ Remove unused PG ของราคา ที่ไม่ได้ใช้ออก (สำหรับเวลา PULL PRODUCTS TO ORDER โดยเฉพาะ)
    const appState = p.op.getAppState()
    const userGroups = getSelectedUserGroups(appState)
    const productGroups = getSelectedProductGroups(appState)
    if (_.isArray(resProduct.product_group_ids) && List.isList(productGroups) && productGroups.size > 0) {
      // ทำการ sort product_group_ids by priority
      // log('before sort product_group_ids in API RESPONSE => ', resProduct.product_group_ids)
      resProduct.product_group_ids.sort((pgA, pgB) => {
        const foundPgA = productGroups.find((pg) => pg.get('id') === pgA)
        const foundPgB = productGroups.find((pg) => pg.get('id') === pgB)
        const priorityA = Map.isMap(foundPgA) ? foundPgA.get('priority') : 0
        const priorityB = Map.isMap(foundPgB) ? foundPgB.get('priority') : 0
        if (priorityA > priorityB) {
          // for desc
          return -1
        }
        if (priorityA < priorityB) {
          return 1
        }
        // Should be impossible
        return 0
      })
      // log('Sorted product_group_ids in API RESPONSE => ', resProduct.product_group_ids)
    }

    if (_.isArray(resProduct.warehouse_ids)) {
      // ทำการ sort warehouse_ids by ids asc
      resProduct.product_group_ids.sort((idA, idB) => (idA > idB ? 1 : -1))
      // forEach อันนี้เป็นของเก่า warehouses ไม่มี code จะไม่รันต่อ
      // resProduct.variants.forEach((v, i) => {
      //   resProduct.variants[i].warehouses.sort((whA, whB) => (whA.wh_id > whB.wh_id ? 1 : -1))
      // })
      for (let i = 0; i < resProduct.variants.length; i++) {
        if (_.isArray(resProduct.variants[i].warehouses)) {
          resProduct.variants[i].warehouses.sort((whA, whB) => (whA.wh_id > whB.wh_id ? 1 : -1))
        }
      }
    }

    if (
      appState &&
      _.isArray(resProduct.product_group_ids) &&
      _.isArray(resProduct.variants) &&
      List.isList(userGroups) &&
      List.isList(productGroups)
    ) {
      // log('XXX loadProductFromResponse ug_id => ', ug_id)
      // log('XXX loadProductFromResponse pg_ids => ', pg_ids)

      if (user_group_id && user_group_id > 0) {
        // ถ้าเป็น UG Mode
        const foundUG = userGroups.find((ug) => ug.get('id') === user_group_id)
        if (Map.isMap(foundUG)) {
          const UgPgRelations = foundUG.get('pgs') || List([])
          const allowedPgs = UgPgRelations.map((pgr) => {
            const allowedPgId = pgr.get('id')
            const foundPG = productGroups.find((pg) => pg.get('id') === allowedPgId)
            if (Map.isMap(foundPG)) {
              return foundPG
            }
            return null
          })
          // log('XXX allowedPgs.toJS() => ', allowedPgs.toJS())
          const sortedAllowedPgs = allowedPgs.sort((pgA, pgB) => {
            const priorityA = pgA.get('priority')
            const priorityB = pgB.get('priority')
            if (priorityA > priorityB) {
              // for desc
              return -1
            }
            if (priorityA < priorityB) {
              return 1
            }

            const idA = pgA.get('id')
            const idB = pgB.get('id')
            if (idA < idB) {
              // for asc
              return -1
            }
            if (priorityA < priorityB) {
              return 1
            }

            // Should be impossible
            return 0
          })
          // log('XXX sortedAllowedPgs.toJS() => ', sortedAllowedPgs.toJS())
          // const mostPriorityPgId = sortedAllowedPgs.getIn([0, 'id'])
          for (let i = 0; i < sortedAllowedPgs.size; i++) {
            const mostPriorityPgId = sortedAllowedPgs.getIn([i, 'id'])
            if (mostPriorityPgId && mostPriorityPgId > 0) {
              let isSetUsagePrice = false
              // const foundAllowPgIdAtIndex = resProduct.product_group_ids.find(ptPgId => ptPgId === mostPriorityPgId)
              for (let variantIndex = 0; variantIndex < resProduct.variants.length; variantIndex++) {
                const { prices = [] } = resProduct.variants[variantIndex]
                for (let priceIndex = 0; priceIndex < prices.length; priceIndex++) {
                  const pricePgId = _.has(prices[priceIndex], 'pg_id') ? prices[priceIndex].pg_id : null
                  const priceByPgId = _.has(prices[priceIndex], 'price') ? prices[priceIndex].price : 0
                  if (pricePgId && pricePgId > 0 && pricePgId === mostPriorityPgId) {
                    resProduct.variants[variantIndex].price = priceByPgId
                    resProduct.variants[variantIndex].pg_id = pricePgId
                    isSetUsagePrice = true
                    // log('XXX mostPriorityPgId => ', mostPriorityPgId)
                    // log('XXX priceByPgId => ', priceByPgId)
                  }
                }
              }
              if (isSetUsagePrice) {
                break
              }
            }
          }
        }
      } else if (_.isArray(product_group_ids) && product_group_ids.length > 0) {
        // ถ้าเป็น PG Mode
        // let visiblePgIds = []
        // resProduct.product_group_ids.forEach(ptPgId => {
        //   const foundInAllowedIndex = pg_ids.findIndex(allowedPgId => allowedPgId === ptPgId)
        //   if (foundInAllowedIndex > -1) {
        //     visiblePgIds.push(ptPgId)
        //   }
        // })
        // resProduct.product_group_ids = visiblePgIds
        let allowedPgs = List([])
        product_group_ids.forEach((allowedPgId) => {
          const foundPG = productGroups.find((pg) => pg.get('id') === allowedPgId)
          if (Map.isMap(foundPG)) {
            allowedPgs = allowedPgs.push(foundPG)
          }
          return null
        })

        // log('XXX allowedPgs.toJS() => ', allowedPgs.toJS())
        const sortedAllowedPgs = allowedPgs.sort((pgA, pgB) => {
          const priorityA = pgA.get('priority')
          const priorityB = pgB.get('priority')
          if (priorityA > priorityB) {
            // for desc
            return -1
          }
          if (priorityA < priorityB) {
            return 1
          }

          const idA = pgA.get('id')
          const idB = pgB.get('id')
          if (idA < idB) {
            // for asc
            return -1
          }
          if (priorityA < priorityB) {
            return 1
          }
          // Should be impossible
          return 0
        })
        const mostPriorityPgId = sortedAllowedPgs.size > 0 ? sortedAllowedPgs.getIn([0, 'id']) : null
        // log('XXX mostPriorityPgId => ', mostPriorityPgId)
        if (mostPriorityPgId && mostPriorityPgId > 0) {
          for (let variantIndex = 0; variantIndex < resProduct.variants.length; variantIndex++) {
            const focusedVariant = resProduct.variants[variantIndex]
            const newPrices = _.has(focusedVariant, 'prices') && _.isArray(focusedVariant.prices) ? focusedVariant.prices.slice() : []
            // let isSetUsagePrice = false
            let priceIndex = 0
            let priceCount = newPrices.length
            while (priceIndex < priceCount) {
              const pricePgId = _.has(newPrices[priceIndex], 'pg_id') ? newPrices[priceIndex].pg_id : null
              const priceByPgId = _.has(newPrices[priceIndex], 'price') ? newPrices[priceIndex].price : 0
              newPrices.pg_id = pricePgId
              if (mostPriorityPgId && pricePgId === mostPriorityPgId && pricePgId && pricePgId > 0) {
                // กรณีจอราคา ที่ filter แล้ว ให้ใช้เป็นราคาหลักราคาเดียวใน variant
                resProduct.variants[variantIndex].pg_id = pricePgId
                resProduct.variants[variantIndex].price = priceByPgId
                // log('XXX mostPriorityPgId => ', mostPriorityPgId)
                // log('XXX variantIndex => ', variantIndex)
                // log('XXX priceByPgId => ', priceByPgId)
                priceIndex++
              } else {
                // กรณีไม่ได้อยู่ใน pg_ids ให้ remove ออกจาก prices ไปเลย
                // resProduct.variants[variantIndex].price = 0
                // log(`XXX removing priceIndex=${priceIndex} => `, newPrices[priceIndex])
                newPrices.splice(priceIndex, 1)
                priceCount = newPrices.length
              }
            }

            if (newPrices.length > 0) {
              // คำนวณ newPrices ได้ แสดงว่าเจอราคาตรงกับ PgIds ให้ replace prices ไปและ price จะถูกเซ็ตตามนั้น
              resProduct.variants[variantIndex].prices = newPrices
            } else {
              // ถ้าไม่มี newPrices หรือ newPrices.length === 0 แสดงว่าไม่มีราคา ให้เซ็ตราคาเป็น 0
              resProduct.variants[variantIndex].pg_id = null
              resProduct.variants[variantIndex].price = 0
            }
            // if (isSetUsagePrice) {
            //   break
            // }
          }
        } else {
          // ถ้าไม่เจอ most priority id ให้บังคับ price = 0
          for (let variantIndex = 0; variantIndex < resProduct.variants.length; variantIndex++) {
            resProduct.variants[variantIndex].price = 0
          }
        }
      }
    }

    // FIXME: MOCKING vds
    // resProduct.seller_vds = [
    //     { min_qty: 1, max_qty: 100, discount_percent: 0 },
    //     { min_qty: 101, max_qty: 200, discount_percent: 3 },
    //     { min_qty: 201, max_qty: 300, discount_percent: 5 },
    // ]

    // resProduct.my_vds = [
    //     { min_qty: 1, max_qty: 5, discount_amount: 0 },
    //     { min_qty: 6, max_qty: 10,  discount_amount: 5 },
    // ]

    let variants = _.cloneDeep(resProduct.variants)
    // log('loadProductFromResponse variants before sort => ', variants)
    variants = _.sortBy(variants, [(v) => v.pp_id])
    // log('loadProductFromResponse variants after sort => ', variants)
    resProduct.variants = variants
    // newProduct = newProduct.mergeDeep(fromJS(res.product)) // Modify for sorting variants
    let newProduct = fromJS(resProduct)
    // log('JS resProduct => ', resProduct)

    // log('Immutable newProduct => ', newProduct.toJS())

    if (mode === PULL_MY_PRODUCT_TO_ORDER) {
      const stockSize = newProduct.get('variants').size
      for (let i = 0; i < stockSize; i++) {
        newProduct = newProduct.setIn(['variants', i, 'qty'], '')
      }
    }

    if (mode === PULL_PRODUCT) {
      const stockSize = newProduct.get('variants').size
      for (let i = 0; i < stockSize; i++) {
        // newProduct = newProduct.setIn(['variants', i, 'c'], newProduct.getIn(['variants', i, 'price']))
        // newProduct = newProduct.setIn(['variants', i, 'price'], '0')
        newProduct = newProduct.setIn(['variants', i, 'cost'], newProduct.getIn(['variants', i, 'price']))
        newProduct = newProduct.setIn(['variants', i, 'price'], '')
        // newProduct = newProduct.setIn(['variants', i, 'price'], newProduct.getIn(['variants', i, 'cost']))
      }
    }

    // log('loadProductFromResponse',resProduct, newProduct.toJS())
    // init/handle shipping_rates and shippingRateStatuses
    if (resProduct.shipping_rates) {
      const shippingRateStatuses = {}
      // let PLACEHOLDER_SHIPPING_TYPE_2 = initProduct.get('shipping_rates').get(0)
      // let PLACEHOLDER_SHIPPING_TYPE_3 = initProduct.get('shipping_rates').get(1)
      let PLACEHOLDER_SHIPPING_TYPE_2 = initProduct.getIn(['shipping_rates', 0])
      let PLACEHOLDER_SHIPPING_TYPE_3 = initProduct.getIn(['shipping_rates', 1])

      resProduct.shipping_rates.map((rate) => {
        shippingRateStatuses[rate.shipping_type_id.toString()] = true
        if (rate.shipping_type_id === CONS.SHIPPING_TYPE_IDS.REGISTER) {
          PLACEHOLDER_SHIPPING_TYPE_2 = null
        } else if (rate.shipping_type_id === CONS.SHIPPING_TYPE_IDS.EMS) {
          PLACEHOLDER_SHIPPING_TYPE_3 = null
        }
        log(rate)
      })
      // Add the placeholder if not already there
      if (PLACEHOLDER_SHIPPING_TYPE_2) {
        resProduct.shipping_rates.push(PLACEHOLDER_SHIPPING_TYPE_2)
      }
      if (PLACEHOLDER_SHIPPING_TYPE_3) {
        resProduct.shipping_rates.push(PLACEHOLDER_SHIPPING_TYPE_3)
      }
      newProduct = newProduct.setIn(['shipping_rates'], fromJS(resProduct.shipping_rates))
      newProduct = newProduct.setIn(['shippingRateStatuses'], fromJS(shippingRateStatuses))
    }

    // Adapter สำหรับแปลงข้อมูล prices แปลงโครงสร้างให้หน้าบ้าน render + validation ได้ง่ายขึ้น
    // "prices": [
    //   {
    //     "id": 10,
    //     "price": "33",
    //     "pg_id": 1,
    //     "hidden": false
    //   }
    // ],
    if (variants[0] && _.has(variants[0], 'prices')) {
      const immuVariants = newProduct.get('variants')
      // log('product saga immuVariants.toJS() => ', immuVariants.toJS())
      immuVariants.forEach((v, vIdx) => {
        const prices = v.get('prices')
        // log('product saga vIdx => ', vIdx)
        // log('product saga prices.toJS() => ', prices.toJS())
        if (List.isList(prices) && prices.size > 0) {
          prices.forEach((vPrice) => {
            // log('product saga vPrice.toJS() => ', vPrice.toJS())
            const pg_id = vPrice.get('pg_id')
            const price = vPrice.get('price')
            // resProduct.variants[vIdx][`price_${pg_id}`] = parseFloat(price)
            const inputPriceKey = `price_${pg_id}`
            newProduct = newProduct.setIn(['variants', vIdx, inputPriceKey], price)
          })
        }
      })
    }

    if (variants[0] && _.has(variants[0], 'warehouses')) {
      const immuVariants = newProduct.get('variants')
      immuVariants.forEach((v, vIdx) => {
        const vWarehouses = v.get('warehouses')
        if (List.isList(vWarehouses) && vWarehouses.size > 0) {
          vWarehouses.forEach((vPrice) => {
            const wh_id = vPrice.get('wh_id')
            const qty = vPrice.get('qty')
            const inputQtyKey = `qty_${wh_id}`
            newProduct = newProduct.setIn(['variants', vIdx, inputQtyKey], qty)
          })
        }
      })
    }

    // Adapter for vds
    //   { min_qty: 1, max_qty: 5, discount_amount: 0, discount_percent: 0 },
    //   { min_qty: 6, max_qty: 10, discount_amount: 10, discount_percent: 10 },
    newProduct = parseVolumeDiscountOf('my', resProduct, newProduct)
    newProduct = parseVolumeDiscountOf('seller', resProduct, newProduct)
    yield put(ProductActions.loadProduct(newProduct, shouldFetchList, shouldUpdateLocalList))
    // yield put({ type: ProductActions.LOAD_PRODUCT, payload: newProduct })
  }
}

// Product List
// function* watchFetchProductList() {
//   log('In watchFetchProductList')
//   // yield takeLatest(ProductActions.FETCH_PRODUCT_LIST, fetchProductList)
//   // yield takeLatest(ProductActions.FETCH_PRODUCT_LIST, fetchProductList)
//   while (true) {
//     const action = yield take(ProductActions.FETCH_PRODUCT_LIST)
//     yield call(fetchProductList, action)
//   }
// }
//
// function* watchNewSearchProductList() {
//   log('In watchNewSearchProductList')
//   yield takeLatest(ProductActions.NEW_SEARCH_PRODUCT_LIST, fetchProductList)
// }
//
// function* fetchProductList(action: { type: string, payload: any, callback: (res: Response) => void }) {
//   // log(body)
//   const callback = action.callback
//   const payload = action.payload
//   log('fetchProductList => ', payload)
//   // const body = { store_id }
//   const apiOptions = {
//     // messages: {
//     //   successMsg: 'การสร้างสินค้าสำเร็จ',
//     //   errorMsg: 'การสร้างสินค้าล้มเหลว',
//     // },
//     showSpinner: false, // disable spinner
//   }
//   let apiRoute
//   if (payload.seller_store_id) {
//     apiRoute = api.POST_SELLER_PRODUCTS
//     // log('api.POST_SELLER_PRODUCTS')
//   } else {
//     apiRoute = api.POST_PRODUCTS
//     // log('api.POST_PRODUCTS')
//   }
//   try {
//     log('In fetchProductList action => ', action)
//     // TODO: Remove the hard-coded sorting
//     // payload.sortBy = 'created_at'
//     payload.sortBy = 'updated_at'
//     payload.sortType = 'desc'
//
//     const res = yield call(api.post, apiRoute, payload, apiOptions)
//     log('In fetchProductList response => ', res)
//
//     if (_.isFunction(callback)) {
//       callback(res)
//     }
//
//     let reqPayload = { reqOffset: payload.offset, reqLimit: payload.limit, res }
//     if (payload.queryTxt) {
//       reqPayload['queryTxt'] = payload.queryTxt
//     }
//
//     yield call(loadProductListFromResponse, reqPayload)
//
//   } catch (error) {
//     yield put({ type: ProductActions.ERROR_FETCH_PRODUCT_LIST })
//   }
// }
function* watchFetchProductList() {
  log('In watchFetchProductList')
  // yield takeLatest(ProductActions.FETCH_PRODUCT_LIST, fetchProductList)
  // yield takeLatest(ProductActions.FETCH_PRODUCT_LIST, fetchProductList)
  while (true) {
    const action = yield take(ProductActions.FETCH_PRODUCT_LIST)
    yield call(fetchProductList, action)
  }
}

function* watchNewSearchProductList() {
  log('In watchNewSearchProductList')
  yield takeLatest(ProductActions.NEW_SEARCH_PRODUCT_LIST, fetchProductList)
}

function* fetchProductList(action: ActionApiParams) {
  // log(body)
  const { body } = action
  log('fetchProductList => ', body)
  // const body = { store_id }
  const apiOptions = {
    // messages: {
    //   successMsg: 'การสร้างสินค้าสำเร็จ',
    //   errorMsg: 'การสร้างสินค้าล้มเหลว',
    // },
    showSpinner: false, // disable spinner
  }
  let apiRoute
  if (body.seller_store_id) {
    apiRoute = api.POST_SELLER_PRODUCTS
    // log('api.POST_SELLER_PRODUCTS')
  } else {
    apiRoute = api.POST_PRODUCTS
    // log('api.POST_PRODUCTS')
  }
  try {
    log('In fetchProductList action => ', action)
    // TODO: Remove the hard-coded sorting
    // body.sortBy = 'created_at'
    body.sortBy = 'updated_at'
    body.sortType = 'desc'

    const res = yield call(api.post, apiRoute, body, apiOptions)
    log('In fetchProductList response => ', res)
    const reqBody: { [key: string]: any } = { reqOffset: body.offset, reqLimit: body.limit, res }
    if (body.queryTxt) {
      reqBody.queryTxt = body.queryTxt
    }

    yield call(loadProductListFromResponse, reqBody)

    // ถ้าเป็นกรณี search แล้วต้องการ product detail
    if (_.has(body, 'returnDetailIfMatchOne') && body.returnDetailIfMatchOne) {
      yield call(loadProductFromResponse, res, body)
    }

    // category
    if (!_.isNil(res.category)) {
      let id = body.store_id
      if (!_.isNil(body.seller_store_id)) {
        id = body.seller_store_id
      }
      const update = { store_id: id, category: res.category }
      yield put({ type: CategoryActions.UPDATE_CATEGORY, payload: update })
    }

    if (_.isFunction(action.successCallback)) {
      const newRes = res
      newRes.storeIdFromRequest = body.store_id
      action.successCallback(newRes)
    }
    // yield put(NavActions.goBack) // go back to StoreMyView
  } catch (error) {
    yield put({ type: ProductActions.ERROR_FETCH_PRODUCT_LIST, error })
    if (_.isFunction(action.failedCallback)) {
      action.failedCallback(error)
    }
  }
}

function* loadProductListFromResponse(reqBody) {
  log('loadProductListFromResponse.reqBody', reqBody)
  if ('products' in reqBody.res) {
    // const { products } = reqBody.res
    // const sortedProducts = _.sortBy(products, [p => p.id])
    // yield put(ProductActions.loadProductList(sortedProducts))
    yield put(ProductActions.loadProductList(reqBody))
  }
  // TODO: Handle else (return error?)
}

function* removePulledProductFromList(productId) {
  log('removePulledProductFromList.payload', productId)
  if (productId) {
    yield put(ProductActions.removePulledProductFromList(productId))
  }
}

// internal function
function parseVolumeDiscountOf(
  discountOf: 'my' | 'seller',
  product: { [key: string]: any },
  newProduct: Map<string, any>
): Map<string, any> {
  const vdKey = _.includes(['my', 'seller'], discountOf) ? `${discountOf}_vds` : null
  const vdTypeKey = _.includes(['my', 'seller'], discountOf) ? `${discountOf}_vds_type` : null

  let parseNewProduct = newProduct

  if (vdKey && vdTypeKey) {
    const focusedVds = _.cloneDeep(product[vdKey])
    if (_.has(product, vdKey) && focusedVds.length >= 2) {
      focusedVds.sort((vdA, vdB) => vdA.min_qty - vdB.min_qty)
      const lastMyVdIndex = focusedVds.length - 1

      // decision of type (percent/baht)
      let vdsType
      if (_.has(focusedVds[lastMyVdIndex], 'discount_percent') && _.isNumber(focusedVds[lastMyVdIndex].discount_percent)) {
        vdsType = 1
      } else if (_.has(focusedVds[lastMyVdIndex], 'discount_amount') && _.isNumber(focusedVds[lastMyVdIndex].discount_amount)) {
        vdsType = 2
      } else {
        vdsType = 0
      }

      let newVds = List([])
      focusedVds.forEach((volDiscount, vdIdx) => {
        const { min_qty, max_qty, discount_amount, discount_percent } = volDiscount
        // const modifiedMaxQty = vdIdx === lastMyVdIndex ? 9999999 : max_qty
        const modifiedMaxQty = vdIdx === lastMyVdIndex && !max_qty ? min_qty + 1 : max_qty
        let discountAmount
        if (vdsType === 1) {
          discountAmount = discount_percent || 0
        } else if (vdsType === 2) {
          discountAmount = discount_amount || 0
        } else {
          discountAmount = 0
        }
        newVds = newVds.push(Map({ begin: min_qty, end: modifiedMaxQty, discount: discountAmount }))
      })
      parseNewProduct = parseNewProduct.set(vdTypeKey, vdsType)
      parseNewProduct = parseNewProduct.set(vdKey, newVds)
    }

    return parseNewProduct
  }
}

function* watchProductListFromVolumeDiscount() {
  log('In watchProductListFromVolumeDiscount')
  while (true) {
    const action = yield take(actions.LOND_PRODUCTS_FROM_VOLUME_DISCOUNT)
    yield call(productListFromVolumeDiscount, action)
  }
}

function* productListFromVolumeDiscount(action: ActionApiParams) {
  try {
    log('In createUser body => ', action.body)
    // @ts-ignore
    const res = yield call(api.post, api.POST_PRODUCTS, action.body)
    log('In createUser response => ', res)
    yield call(loadProductListFromVolumeDiscountByResponse, res)
    // yield call(loadNewUserGroupFromResponse, res)
    if (_.isFunction(action.successCallback)) {
      action.successCallback(res)
    }
    // yield put(NavActions.goBack) // go back to StoreMyView
  } catch (error) {
    log('In createUser error => ', error)
    // yield put({ type: actions.ERROR_CREATE_USER_GROUP, error })
    // if (_.isFunction(action.failedCallback)) {
    //   action.failedCallback(error)
    // }
  }
}

function* loadProductListFromVolumeDiscountByResponse(res) {
  if (!_.isEmpty(res)) {
    yield put(ProductState.setProductsFromVolumeDiscount(res))
  }
}

// function getShortProductItemFromProductDetail(productDetail: { [key: string]: any }): { [key: string]: any } | null {
//   if (!productDetail) {
//     return null
//   }
//   const productDetailMap = fromJS(productDetail)
//   const shortProductDetail: { [key: string]: any } = {}
//   shortProductDetail.id = productDetailMap.get('id')
//
//   // compute product's thumbnail
//   shortProductDetail.t = productDetailMap.getIn(['tiny_img_uris', 0])
//   if (!shortProductDetail.t) {
//     shortProductDetail.t = productDetailMap.getIn(['thumbnail_uris', 0])
//   }
//   if (!shortProductDetail.t) {
//     shortProductDetail.t = productDetailMap.getIn(['img_uris', 0])
//   }
//   shortProductDetail.n = productDetailMap.get('name')
//
//   // compute available_qty
//   const productVariants = productDetailMap.get('variants') || List([])
//   const sumAvailableQty = productVariants.reduce((prevSum, variant) => {
//     return variant.get('available_qty') ? prevSum + parseInt(variant.get('available_qty')) : prevSum
//   }, 0)
//   shortProductDetail.q = sumAvailableQty
//
//   // compute isMyProduct
//   shortProductDetail.m = !_.isNil(productDetailMap.get('parent_id'))
//
//   // populate variants
//   shortProductDetail.v = []
//   productVariants.forEach(vMap => {
//     if (Map.isMap(vMap)) {
//       let shortVariantItem: { [key: string]: any } = {}
//
//       shortVariantItem.i = vMap.get('pp_id')
//       shortVariantItem.n = vMap.get('name')
//
//       // compute variant's prices
//       shortVariantItem.s = []
//       const variantPrices = vMap.get('prices') || []
//       variantPrices.forEach(vPrice => {
//         if (Map.isMap(vMap)) {
//         }
//       })
//     }
//   })
// }
