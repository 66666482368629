import { Map } from 'immutable'
import xCONS from 'x/config/constants'
import { IBaseFetchOrdersCommonParams, IOrdersFilterSetting, ActionApiParams } from 'x/index'
import actions from '../../config/actions'
// import * as util from '../../utils/util'
import { log } from '../../utils/util'
import * as baseOrderListState from './BaseOrderListState'
import { initialState, reducer, INIT } from './BaseOrderListState'

import {
  getOrdersInBaseOrders,
  getStoreOrdersFetchStatusInOrders,
  getStoreOrdersInOrdersForTabName,
  getStoreOrdersFetchStatusForTabName,
  getStoreOrdersFetchStatusForTabNameInOrders,
  getStoreOrdersSummaryInOrders,
  setStoreOrdersInOrders,
  setStoreOrdersTabStatusInOrders,
  setStoreOrdersTabStatusInOrdersForKey,
  setStoreOrderFetchStatusInOrdersForTab,
  setStoreOrdersSummaryInOrders,
  setStoreOrdersBackendTimeInServers,
  // setStoreQueriedOrdersInOrders,
} from '../../redux/selectors'

const func = {
  getOrders: getOrdersInBaseOrders,
  setOrdersInOrders: setStoreOrdersInOrders,
  setBackendTimeInServers: setStoreOrdersBackendTimeInServers,
  setOrdersSummaryInOrders: setStoreOrdersSummaryInOrders,
  getOrdersSummaryInOrders: getStoreOrdersSummaryInOrders,
  getOrdersInOrdersForTabName: getStoreOrdersInOrdersForTabName,
  setOrderFetchStatusInOrdersForTab: setStoreOrderFetchStatusInOrdersForTab,
  getOrdersFetchStatusForTabName: getStoreOrdersFetchStatusForTabName,
  getOrdersFetchStatusInOrders: getStoreOrdersFetchStatusInOrders,
  getOrdersFetchStatusForTabNameInOrders: getStoreOrdersFetchStatusForTabNameInOrders,
  setOrdersTabStatusInOrders: setStoreOrdersTabStatusInOrders,
  setOrdersTabStatusInOrdersForKey: setStoreOrdersTabStatusInOrdersForKey,
}

const _type = {
  ORDERS_RESET_STATE: actions.STORE_ORDERS_RESET_STATE,
  ORDERS_SELECT_SUB_TAB_FOR_KEY: actions.STORE_ORDERS_SELECT_SUB_TAB_FOR_KEY,
  ORDERS_TAB_STATUS_RESET: actions.STORE_ORDERS_TAB_STATUS_RESET,
  ORDERS_FETCH: actions.STORE_ORDERS_FETCH,
  SET_ORDERS_STATE: actions.STORE_SET_ORDERS_STATE,
  ORDERS_SHOULD_FETCH_ALL: actions.STORE_ORDERS_SHOULD_FETCH_ALL, // Additional Type
  ORDERS_SHOULD_FETCH_SINGLE_TAB_KEY: actions.STORE_ORDERS_SHOULD_FETCH_SINGLE_TAB_KEY, // Additional Type
  ORDERS_SET_IS_INITED_SINGLE_TAB_KEY: actions.STORE_ORDERS_SET_IS_INITED_SINGLE_TAB_KEY, // Additional Type
  ORDERS_FILTER_SETTING_RESET: actions.STORE_ORDERS_FILTER_SETTING_RESET,
  ORDERS_FILTER_SETTING_SET: actions.STORE_ORDERS_FILTER_SETTING_SET,
}

// Action creators
export function init() {
  return baseOrderListState.init()
}

export function resetState() {
  return { type: _type.ORDERS_RESET_STATE }
}

export function selectSubTabForKey(mainTabKey, subTabKey) {
  return baseOrderListState.selectSubTabForKey(mainTabKey, subTabKey, actions.STORE_ORDERS_SELECT_SUB_TAB_FOR_KEY)
}

// Deprecated in react-navigation v3
// export function navToOrderDetail(orderId, storeId, isNoInitFetch) {
//   return p.nav.navToOrderDetail(orderId, storeId, isNoInitFetch)
// }

export function resetTabStatus() {
  return baseOrderListState.resetTabStatus(actions.STORE_ORDERS_TAB_STATUS_RESET)
}

export function fetchOrders(params: IBaseFetchOrdersCommonParams) {
  if (!params) {
    return { type: xCONS.ACTION_NO_OP } // skip this op
  }
  const { storeId, orderStoreId, mode, tabName } = params
  log(`StoreOrderListState.fetchOrders.storeId: ${storeId} orderStoreId: ${orderStoreId} mode: ${mode} tabName: ${tabName}`)
  return baseOrderListState.baseFetchOrders({
    // storeId,
    // orderStoreId,
    // mode,
    // tabName,
    ...params,
    type: _type.ORDERS_FETCH,
    getOrdersFetchStatusForTabName: func.getOrdersFetchStatusForTabName,
  })
}

// export function toggleOrderPaymentSelection(orderId, total) {
export function toggleOrderPaymentSelection(orderData: { orderId: number; remaining_forecast_amount: number; cod_amount?: number }) {
  return {
    type: actions.ORDERS_TOGGLE_PAYMENT_SELECTION,
    payload: orderData,
  }
}

export function resetOrdersFilterSetting() {
  return {
    type: actions.STORE_ORDERS_FILTER_SETTING_RESET,
  }
}

export function setOrdersFilterSetting(setting: IOrdersFilterSetting) {
  return {
    type: actions.STORE_ORDERS_FILTER_SETTING_SET,
    payload: setting,
  }
}

interface IOptionsToggleIsCheckOrderId {
  key?: string // key ที่ต้องการจะ group orderId พวกนี้ไว้
}

export function toggleIsCheckOrderId(orderId: number, options: IOptionsToggleIsCheckOrderId) {
  return {
    type: actions.STORE_ORDERS_TOGGLE_IS_CHECK_ORDER_ID,
    payload: { orderId, options },
  }
}

export function checkAllOrderIds(options?: IOptionsToggleIsCheckOrderId) {
  return {
    type: actions.STORE_ORDERS_CHECK_ALL_ORDER_IDS,
    payload: { options },
  }
}

export function uncheckAllOrderIds(options?: IOptionsToggleIsCheckOrderId) {
  return {
    type: actions.STORE_ORDERS_UNCHECK_ALL_ORDER_IDS,
    payload: { options },
  }
}

export function setIsInitedByTabKey(tabKey: string, value = true) {
  return {
    type: actions.STORE_ORDERS_SET_IS_INITED_SINGLE_TAB_KEY,
    payload: { tabKey, value },
  }
}

// export function queryOrders(storeId) {
//   log('StoreOrderListState.queryStoreOrders')
//   return {
//     type: actions.STORE_ORDERS_QUERY,
//     payload: {
//       store_id: storeId,
//       offset: 0,
//       limit: 20,
//       sortBy: 'id',
//       sortType: 'desc',
//       order_mode: 'p', // Default to 's' where 's' = sales order
//       // created_at_from: '2017-06-30 13:23:45',
//       // created_at_to: '2017-07-01 13:23:45',
//       // updated_at_from: '2017-06-30 13:23:45',
//       // updated_at_to: '2017-07-01 13:23:45',
//       // completed_at_from: '2017-06-30 13:23:45',
//       // completed_at_to: '2017-07-01 13:23:45',
//       // order_states: [102, 109]
//       // payment_states: [],
//       // shipping_states: [129],
//       // shipping_type_ids: [3,4,5],
//       // receiver_name: 'กนกว'
//       // sender_name: 'xxx',
//       // seller_store_ids: [12],
//       // expiresInMins: 3600
//     },
//   }
// }
// Reducer
// export default function StoreOrderListStateReducer(state, action = {}) {
//   return baseOrderListState.baseReducer(state, action, func)
// }

// Reducer
export default function StoreOrderListStateReducer(state = initialState, action: ActionApiParams = {}) {
  switch (action.type) {
    case INIT:
      return state
    case _type.ORDERS_RESET_STATE:
      return initialState
    case _type.ORDERS_FILTER_SETTING_RESET: {
      return reducer.resetFilterSetting(state)
    }
    case _type.ORDERS_FILTER_SETTING_SET: {
      return reducer.setFilterSetting(state, action.payload)
    }
    case _type.ORDERS_SHOULD_FETCH_ALL: {
      // Additional case
      return reducer.setShouldFetchAll(state)
    }
    case _type.ORDERS_SHOULD_FETCH_SINGLE_TAB_KEY: {
      // Additional case 2
      return reducer.setShouldFetchSingleTabKey(state, action.payload)
    }
    case _type.ORDERS_SET_IS_INITED_SINGLE_TAB_KEY: {
      // Additional case 3
      return reducer.setIsInitedTabKey(state, action.payload)
    }
    case _type.ORDERS_TAB_STATUS_RESET:
      return reducer.resetOrdersTabStatus(state, action, func.setOrdersTabStatusInOrders, func.getOrders)
    case _type.ORDERS_SELECT_SUB_TAB_FOR_KEY: {
      return reducer.selectSubTabForKey(state, action, func.setOrdersTabStatusInOrdersForKey)
    }
    case _type.ORDERS_FETCH: {
      return reducer.handleFetchOrders(state, action, func.getOrdersFetchStatusInOrders, func.getOrdersFetchStatusForTabNameInOrders)
    }
    case _type.SET_ORDERS_STATE: {
      // console.log('StoreOrderListState.SET_ORDERS_STATE..')
      // console.log('action')
      // console.log(action)
      // console.log(action.payload.toJS())
      let s = reducer.setOrdersState(
        state,
        action,
        func.setOrdersInOrders,
        func.setBackendTimeInServers,
        func.setOrdersSummaryInOrders,
        func.getOrdersSummaryInOrders,
        func.getOrdersInOrdersForTabName,
        func.setOrderFetchStatusInOrdersForTab,
        func.getOrdersFetchStatusInOrders
      )
      if (action.payload.has('shouldResetTabStatus') && action.payload.get('shouldResetTabStatus') === true) {
        // Reset the tab status (both number and selected tab on first available number)
        s = reducer.resetOrdersTabStatus(s, action, func.setOrdersTabStatusInOrders, func.getOrders)
      }
      return s
    }
    case actions.STORE_ORDERS_TOGGLE_IS_CHECK_ORDER_ID: {
      const { orderId, options = {} } = action.payload
      const key = options.key || 'genernal'
      let newState = state
      const isAlreadyHasKey = newState.hasIn(['isCheckOrderIdsMapByKey', key])
      if (!isAlreadyHasKey) {
        newState = newState.setIn(['isCheckOrderIdsMapByKey', key], Map({}))
      }

      const isCheckOrderId = newState.hasIn(['isCheckOrderIdsMapByKey', key, orderId])
      if (isCheckOrderId) {
        newState = newState.deleteIn(['isCheckOrderIdsMapByKey', key, orderId])
      } else {
        newState = newState.setIn(['isCheckOrderIdsMapByKey', key, orderId], true)
      }
      return newState
    }

    case actions.STORE_ORDERS_CHECK_ALL_ORDER_IDS: {
      const { options = {} } = action.payload
      const specificKey = options.key || null
      let newState = state
      let allKeys = []

      if (specificKey) {
        allKeys = [specificKey]
      } else {
        const ordersMapByKey = (newState.get('orders') as Map<string, any>) || Map({})
        allKeys = ordersMapByKey.keySeq().toArray()
      }

      for (const targetKey of allKeys) {
        newState = newState.setIn(['isCheckOrderIdsMapByKey', targetKey], Map({}))
        const orderIds = newState
          .getIn(['orders', targetKey])
          .map((orderListItem) => orderListItem.get('id'))
          .toArray()
        for (const odId of orderIds) {
          newState = newState.setIn(['isCheckOrderIdsMapByKey', targetKey, odId], true)
        }
      }
      return newState
    }

    case actions.STORE_ORDERS_UNCHECK_ALL_ORDER_IDS: {
      const { options = {} } = action.payload
      const specificKey = options.key || null
      let newState = state
      if (!specificKey) {
        newState = newState.set('isCheckOrderIdsMapByKey', Map({}))
      } else {
        newState = newState.setIn(['isCheckOrderIdsMapByKey', specificKey], Map({}))
      }
      return newState
    }
    // case actions.STORE_SET_ORDERS_QUERY_STATE: {
    //   let s = setStoreQueriedOrdersInOrders(state, action.payload)
    //   return s
    // }
    default:
      return state
  }
}
