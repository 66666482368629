// import EntypoIcon from 'react-native-vector-icons/Entypo'

// import BackIcon from '../../components/BackIcon'
// import XTabNavIcon from '../../components/XTabNavIcon'

import BaseUISelectAddOnPackageView from 'xui/modules/subscription/BaseUISelectAddOnPackageView'

export default class SelectAddOnPackageView extends BaseUISelectAddOnPackageView {
  static displayName = 'SelectAddOnPackageView'
}
