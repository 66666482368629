import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as ProductActions from 'x/modules/product/ProductState'
import * as StoreActions from 'x/modules/store/StoreState'
import * as OrderActions from 'x/modules/order/OrderState'
import * as CategoryActions from 'x/modules/category/categoryState'
// import * as BackParamsState from '../backparams/BackParamsState'
import {
  getSellerStores,
  getSelectedStore,
  getSelectedUserGroups,
  getSelectedProductGroups,
  // getMyStoresDetail,
  getSubscription,
  getSelectedProduct,
  getEditingProduct,
} from 'x/redux/selectors'

import SearchableProductListView from './SearchableProductListView'

export default connect(
  (state) => ({
    selectedProduct: getSelectedProduct(state),
    editingProduct: getEditingProduct(state),
    pp: state.getIn(['bp', 'pagePicker']),
    // imgBlockTasks: state.get('imgBlockTasks'),
    // imgBlock_img_uris: state.getIn(['imgBlockTasks', 'img_uris']),
    // imgBlock_thumbnail_uris: state.getIn(['imgBlockTasks', 'thumbnail_uris']),
    imgBlock_status: state.getIn(['imgBlockTasks', 'taskStatus']),
    // selectedStoreID: state.getIn(['stores', 'selectedStore', 'id']),
    subscription: getSubscription(state),
    // For Store's name of Parent Product
    sellerStores: getSellerStores(state),
    selectedStore: getSelectedStore(state),
    selectedUserGroups: getSelectedUserGroups(state),
    selectedProductGroups: getSelectedProductGroups(state),
    // getMyStoresDetail: getMyStoresDetail(state),
  }),
  (dispatch) => ({
    fetchProduct: bindActionCreators(ProductActions.fetchProduct, dispatch),
    addProduct: bindActionCreators(ProductActions.addProduct, dispatch),
    pullProductToMyStore: bindActionCreators(ProductActions.pullProductToMyStore, dispatch),
    deleteProduct: bindActionCreators(ProductActions.deleteProduct, dispatch),
    editProduct: bindActionCreators(ProductActions.editProduct, dispatch),
    revertProduct: bindActionCreators(ProductActions.revertProduct, dispatch),
    onChangeProduct: bindActionCreators(ProductActions.onChangeProduct, dispatch),
    onChangeVariant: bindActionCreators(ProductActions.onChangeVariant, dispatch),
    onChangeProductGroups: bindActionCreators(ProductActions.onChangeProductGroups, dispatch),
    onChangeProductWarehouseIds: bindActionCreators(ProductActions.onChangeProductWarehouseIds, dispatch),
    onToggleShippingRate: bindActionCreators(ProductActions.onToggleShippingRate, dispatch),
    onChangeShippingRate: bindActionCreators(ProductActions.onChangeShippingRate, dispatch),
    addProductVariant: bindActionCreators(ProductActions.addProductVariant, dispatch),
    removeProductVariant: bindActionCreators(ProductActions.removeProductVariant, dispatch),
    undoRemoveProductVariant: bindActionCreators(ProductActions.undoRemoveProductVariant, dispatch),
    selectedProductClear: bindActionCreators(ProductActions.selectedProductClear, dispatch),
    addProductToOrder: bindActionCreators(OrderActions.addProductToOrder, dispatch),
    quickAddProductToOrder: bindActionCreators(OrderActions.quickAddProductToOrder, dispatch),

    shoudFetchProductList: bindActionCreators(ProductActions.shoudFetchProductList, dispatch),

    // fetchSellerProductList: bindActionCreators(ProductActions.fetchSellerProductList, dispatch),
    // productActions: bindActionCreators(ProductActions, dispatch),
    // productActions: bindActionCreators(ProductActions, dispatch),
    // bpActions: bindActionCreators(BackParamsState, dispatch),

    fetchMyStore: bindActionCreators(StoreActions.fetchMyStore, dispatch),

    unpairVolumeDiscount: bindActionCreators(StoreActions.unpairVolumeDiscount, dispatch),
    updateContInVolumeDiscounts: bindActionCreators(StoreActions.updateContInVolumeDiscounts, dispatch),
    loadListVolumeDiscounts: bindActionCreators(StoreActions.loadListVolumeDiscounts, dispatch),
    updateDataVolumeDiscountAndProductGroup: bindActionCreators(ProductActions.updateDataVolumeDiscountAndProductGroup, dispatch),
    fetchCategory: bindActionCreators(CategoryActions.requestCategoryList, dispatch),
  })
)(SearchableProductListView)
