// import EntypoIcon from 'react-native-vector-icons/Entypo'
import p from 'x/config/platform-specific'
import BaseUIInAppPurchasePerPackageView from 'xui/modules/subscription/BaseUIInAppPurchasePerPackageView'
import VStack from 'xui/components/VStack'

// const packageLabelList = ['Bronze', 'Silver', 'Gold', 'Platinum']

export default class InAppPurchasePerPackageView extends BaseUIInAppPurchasePerPackageView {
  static displayName = 'InAppPurchasePerPackageView'

  _fetchSubcriptionIap = async () => {
    return
  }

  _purchase = async () => {
    return
  }

  _subscribe = async () => {
    return
  }

  _endConnection = () => {
    // endConnection()
  }
}
