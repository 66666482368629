import React from 'react'
import { Spinner, HStack } from 'native-base'
import { View, Text, TouchableOpacity, ScrollView } from 'react-native'
import * as util from 'x/utils/util'
import CONS from 'x/config/constants'
import _ from 'lodash'
import { COLORS, STYLES } from 'x/config/styles'
import p from 'x/config/platform-specific'
// import ReportProductDailySales from '../report/ReportProductDailySales'
// import ReportPayment from '../report/ReportPayment'
// import ReportHelperSales from '../report/ReportHelperSales'
import XIcon from 'xui/components/XIcon'
import BaseImportExcelCODView, { iCustomDataShippingTypeSelector } from 'x/modules/importExcel/BaseImportExcelCODView'
import XCustomHeader from 'xui/components/XCustomHeader'
import { IPaymentAccount } from 'x/index'
// import Dropzone from 'react-dropzone'
import { VIEW_HEIGHT_TEN } from 'xui/utils/BaseShareUI'
import PaymentAccountPicker from 'xui/components/PaymentAccountPicker'
import XExampleBtn from 'xui/components/XExampleBtn'
import XDateTimeSinglePicker from 'xui/components/XDateTimeSinglePicker'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import XCard from 'xui/components/XCard'
import XText from 'xui/components/XText'
import Box from 'xui/components/Box'
import XButton from 'xui/components/XButton'
import XFooterImportExcel from 'xui/components/XFooterImportExcel'

export default abstract class BaseUIImportExcelCODView extends BaseImportExcelCODView {
  abstract _handleOnDownloadFile(url: string, fileName: string): Promise<void>

  abstract openLinkOrderToWebView(url: string): void

  abstract onPressUpload(): void

  abstract _renderDropzone(): JSX.Element

  abstract _openLinkOrderToWebView(url: string)

  renderCustomHeader = () => {
    // const { navigation } = this.props
    // const { mode } = this.state
    // const isEditMode = mode === EDIT
    const leftBtn = { backIcon: true, onPressItem: () => this._goBack() }
    // if (isEditMode) {
    //   // @ts-ignore
    //   leftBtn = { label: `บันทึก`, onPressItem: () => util.setStatePromise(this, { mode: VIEW }) }
    // }
    return (
      <XCustomHeader
        title='กระทบยอด COD'
        headerLeftProps={leftBtn}
        // headerRightProps={{ label: `แก้ไข`, onPressItem: () => util.setStatePromise(this, { mode: EDIT }) }}
      />
    )
  }

  _renderDateRangePicker = () => {
    const { selectedDateTime, selectedShippingType } = this.state
    if (_.isNil(selectedShippingType) || !_.isNil(selectedShippingType.post_date)) {
      return null
    }
    // const DATE_LOCAL_DATEPICKER_FORMAT = 'YYYY-MM-DD HH:mm:ss'
    // const dateTimeFormat = util.getTimeFormatYYYYMMDDhhmm(selectedDateTime)
    return (
      <Box w='full' justifyContent='center' zIndex={999} py='2'>
        <XText variant='active'>เวลาในการกระทบยอด COD</XText>
        <XDateTimeSinglePicker selectedDate={selectedDateTime} onChangeDate={(newDate: Date) => this._onChangeDateTime(newDate)} />
      </Box>
    )
  }

  _delayDownloadTemplate = async () => {
    util.setStatePromise(this, {
      disabledDownloadTemplate: true,
    })
    await util.delay(5000)
    util.setStatePromise(this, {
      disabledDownloadTemplate: false,
    })
  }

  _downloadTemplate = async () => {
    this._delayDownloadTemplate()
    const storeId = this.props.selectedStore.get('id')
    const { subscription } = this.props
    if (subscription.get('type') === CONS.SUBSCRIPTION_PACKAGE.FREE) {
      let isDownloadTemplate = false
      await new Promise((resolve) => {
        p.op.showConfirmation(
          '',
          'แพ็กเกจของคุณไม่สามารถอัพโหลดไฟล์ได้ ต้องการดาวโหลด Excel เพิ่มสินค้า ต่อหรือไม่',
          () => {
            isDownloadTemplate = true
            // @ts-ignore
            resolve(null)
          },
          // @ts-ignore
          () => resolve(null)
        )
      })
      if (!isDownloadTemplate) {
        return
      }
    }
    const url = util.getAddProductTemplate({
      store_id: storeId,
    })
    // console.log('url => ', url)
    const now = util.getTimeFormatYYYYMMDDhhmmss()
    const fileName = `mass_product_qty_update_template_${storeId}_${now}.xlsx`
    await this._handleOnDownloadFile(url, fileName)
    // const res = await api.getV2(api.GET_EXCEL_PRODUCT_IMPORT_TEMPLATE, apiOptions)
    // console.log('res => ', res)
    // Linking.openURL(linkDownload)
  }

  _renderDownloadTemplateView = () => {
    const { disabledDownloadTemplate } = this.state
    let textBtnColor = COLORS.TEXT_ACTIVE
    let borderBtnColor = COLORS.APP_MAIN
    if (disabledDownloadTemplate) {
      textBtnColor = COLORS.TEXT_INACTIVE
      borderBtnColor = COLORS.TEXT_INACTIVE
    }
    return (
      <View
        style={{
          width: '100%',
          justifyContent: 'center',
          backgroundColor: COLORS.WHITE,
          paddingLeft: 20,
          paddingRight: 10,
        }}>
        <Text style={{ fontSize: STYLES.FONT_SIZE_LARGER, color: COLORS.TEXT_ACTIVE, paddingTop: 4 }}>
          ใช้เพื่อเพิ่มสินค้า ได้สูงสุดครั้งละ 30 รายการ
        </Text>
        <View style={{ height: 5 }} />
        <Text style={{ fontSize: STYLES.FONT_SIZE_LARGER, color: COLORS.TEXT_ACTIVE, paddingTop: 4, fontWeight: 'bold' }}>ก่อนเริ่ม:</Text>

        <Text style={{ fontSize: STYLES.FONT_SIZE_LARGER, color: COLORS.TEXT_ACTIVE, paddingTop: 4 }}>
          หากสินค้ามีหลายราคา เช่น ราคาปลีก ราคาส่ง ให้สร้างรายการราคาให้ครบถ้วนก่อน
        </Text>
        <TouchableOpacity onPress={() => this.openLinkOrderToWebView('https://help.xselly.com/kb/add-price-list')}>
          <Text
            style={{
              fontSize: STYLES.FONT_SIZE_LARGER,
              color: '#0084FF',
              paddingTop: 4,
              textDecorationLine: 'underline',
            }}>
            (ดูวิธีได้ที่นี่)
          </Text>
        </TouchableOpacity>
        <Text style={{ fontSize: STYLES.FONT_SIZE_LARGER, color: COLORS.TEXT_ACTIVE, paddingTop: 4, fontWeight: 'bold' }}>ขั้นตอน:</Text>

        <Text style={{ fontSize: STYLES.FONT_SIZE_LARGER, color: COLORS.TEXT_ACTIVE, paddingTop: 4 }}>
          1) กดปุ่ม ดาวน์โหลด Excel เพิ่มสินค้า นี้
        </Text>
        {/* <Text style={{ fontSize: STYLES.FONT_SIZE_LARGER, color: COLORS.APP_MAIN, paddingTop: 4 }}>
          {`หมายเหตุ: สามารถอัพโหลดได้เพียง 30 รายการต่อ 1 ครั้งเท่านั้น`}
        </Text> */}
        {VIEW_HEIGHT_TEN}
        <TouchableOpacity
          disabled={disabledDownloadTemplate}
          onPress={() => this._downloadTemplate()}
          style={{
            width: 260,
            flexDirection: 'row',
            height: 30,
            borderColor: borderBtnColor,
            borderRadius: 7,
            borderWidth: 0.8,
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf: 'center',
          }}>
          <XIcon
            name='exclefile1'
            family='AntDesign'
            style={{ flex: 0, minWidth: 25, width: 25, color: borderBtnColor, fontSize: STYLES.FONT_ICON_SMALLER }}
          />
          <Text style={{ fontSize: STYLES.FONT_SIZE_LARGER, color: textBtnColor }}>ดาวน์โหลด Excel เพิ่มสินค้า</Text>
        </TouchableOpacity>
        <View style={{ height: 15 }} />
        <Text style={{ fontSize: STYLES.FONT_SIZE_LARGER, color: COLORS.TEXT_ACTIVE, paddingTop: 4 }}>
          {`2) กรอกข้อมูลสินค้าใหม่ในไฟล์ Excel\n(ได้สูงสุดไฟล์ละ 30 สินค้า)`}
        </Text>
        <View style={{ height: 5 }} />
        <Text style={{ fontSize: STYLES.FONT_SIZE_LARGER, color: COLORS.TEXT_ACTIVE, paddingTop: 4 }}>
          3) ระบุไฟล์ Excel ที่กรอกเสร็จแล้วด้านล่าง แล้วกดปุ่ม "อัพโหลด"
        </Text>
        {VIEW_HEIGHT_TEN}
      </View>
    )
  }

  _onChangePaymentAccount = (selectedPaymentAccount: IPaymentAccount) => {
    // this.setState({ bankAccount: fromJS(selectedPaymentAccount) })
    util.setStatePromise(this, {
      selectedPaymentAccount,
    })
    return selectedPaymentAccount
  }

  _renderSelectorPaymentAccounts = () => {
    const { selectedStore } = this.props
    return (
      <Box
        style={{
          width: '100%',
          justifyContent: 'center',
        }}>
        <XText variant='active'>
          {'ใช้เพื่อบันทึกรับชำระยอดเก็บเงินปลายทาง โดยการนำเข้าไฟล์ Excel ที่ได้รับจากขนส่ง' +
            'เมื่อมีการโอนเงินเข้าบัญชีธนาคารเราแล้ว โดยระบบจะบันทึกรับชำระตามยอดที่ระบุใน Excel ให้สำหรับออเดอร์ทีเข้าเงื่อนไขทั้งหมดนี้...\n' +
            '1. มีการบันทึกจัดส่งด้วยเลขพัสดุที่ตรงกับใน Excel\n' +
            '2. ยังมียอดค้างชำระ\n' +
            '3. ยังไม่ได้ถูกยกเลิก'}
        </XText>
        <XText style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE, paddingTop: 4, fontWeight: 'bold' }}>
          {'\nกรุณาเลือกบัญชีรับชำระ เพื่อใช้บันทึกรับยอด'}
        </XText>
        {/* <View style={{ height: 5 }} /> */}
        <PaymentAccountPicker
          targetStoreId={selectedStore.get('id')}
          onChangePaymentAccount={this._onChangePaymentAccount}
          placeholderText='เลือกบัญชีบันทึกรับชำระ'
        />
      </Box>
    )
  }

  _renderSelectorShippingType = () => {
    const { indexSelectorShippingType } = this.state
    return (
      <Box w='full' justifyContent='center' mt='2'>
        <XText variant='active'>กรุณาเลือกรูปแบบนำเข้า Excel COD</XText>
        <XText variant='inactive'>คุณสามารถกดปุ่ม "ดูตัวอย่าง" ด้านล่าง เพื่อเลือกรูปแบบให้ตรงกับไฟล์ที่ต้องการอัพโหลด</XText>
        <View style={{ height: 5 }} />

        <Box
          style={{ width: '100%', height: p.op.isWeb() ? 225 : 280, borderWidth: 1, borderColor: '#b2b2b2' }}
          borderBottomWidth={1}
          borderTopWidth={1}
          borderLeftWidth={1}
          borderRightWidth={1}
          borderBottomColor={COLORS.TEXT_INACTIVE}
          borderTopColor={COLORS.TEXT_INACTIVE}
          borderLeftColor={COLORS.TEXT_INACTIVE}
          borderRightColor={COLORS.TEXT_INACTIVE}>
          <ScrollView>
            {this.COSTOM_DATA_SHIPPING_TYPE_SELECTOR.map((shippingType: iCustomDataShippingTypeSelector, index: number) => {
              const BG_LIST_COLOR = index % 2 === 0
              return (
                <Box
                  style={{
                    // flex: 0,
                    width: '100%',
                    height: 40,
                    flexDirection: 'row',
                    backgroundColor: BG_LIST_COLOR ? COLORS.BG_LIGHT_GREY : COLORS.WHITE,
                  }}>
                  {/* <View style={{ height: 40 }} /> */}
                  <Box style={{ width: 200, alignItems: 'flex-start', justifyContent: 'center' }}>
                    <TouchableOpacity
                      onPress={() => this._onPressSelectorShippingType(shippingType)}
                      style={{
                        width: 200,
                        alignItems: 'center',
                        // justifyContent: 'center',
                        flexDirection: 'row',
                      }}>
                      {indexSelectorShippingType === shippingType.index ? (
                        <XIcon name='radio-button-on' family='Ionicons' style={{ flex: 0, minWidth: 25, width: 25, paddingLeft: 4 }} />
                      ) : (
                        <XIcon name='radio-button-off' family='Ionicons' style={{ flex: 0, minWidth: 25, width: 25, paddingLeft: 4 }} />
                      )}

                      <XText variant='active' pl='2'>
                        {shippingType.name}
                      </XText>
                    </TouchableOpacity>
                  </Box>

                  <Box style={{ alignItems: 'flex-end', justifyContent: 'center', flex: 1, paddingRight: 6 }}>
                    <XExampleBtn onPress={() => this.openLinkOrderToWebView(shippingType.example)} />
                  </Box>
                </Box>
              )
            })}
          </ScrollView>
        </Box>
      </Box>
    )
  }

  _renderBtnUpload = () => {
    const { fileUpload } = this.state
    const btnBGColor = _.isNil(fileUpload) ? COLORS.BG_LIGHT_GREY : COLORS.APP_MAIN
    const btnBorderColor = _.isNil(fileUpload) ? COLORS.TEXT_INACTIVE : COLORS.APP_MAIN
    const textBtnColor = _.isNil(fileUpload) ? COLORS.TEXT_INACTIVE : COLORS.WHITE
    return (
      <HStack w='full'>
        <HStack style={STYLES.FOOTER_BG} w='full'>
          <XButton
            disabled={_.isNil(fileUpload)}
            flex={1}
            style={{
              flexDirection: 'row',
              borderColor: btnBorderColor,
              backgroundColor: btnBGColor,
              borderWidth: 1,
              marginRight: 4,
              marginLeft: 4,
              borderRadius: 7,
            }}
            onPress={() => this.onPressUpload()}>
            <XIcon
              name='upload'
              family='AntDesign'
              style={{ flex: 0, minWidth: 25, width: 25, color: textBtnColor, fontSize: STYLES.FONT_ICON_SMALLER, fontWeight: 'bold' }}
            />
            <XText color={textBtnColor} bold pl='2'>
              อัพโหลด
            </XText>
          </XButton>
        </HStack>
      </HStack>
    )
    // return (
    //   <View style={{ width: '100%', height: 100, justifyContent: 'center', alignItems: 'center', backgroundColor: COLORS.WHITE }}>
    //     <TouchableOpacity
    //       onPress={() => this.onPressUpload()}
    //       disabled={_.isNil(fileUpload)}
    //       style={{
    //         width: 180,
    //         flexDirection: 'row',
    //         height: 30,
    //         borderColor: btnBorderColor,
    //         backgroundColor: btnBGColor,
    //         borderRadius: 7,
    //         borderWidth: 0.8,
    //         justifyContent: 'center',
    //         alignItems: 'center',
    //       }}>
    //       <XIcon
    //         name={`upload`}
    //         family={`AntDesign`}
    //         style={{ flex: 0, minWidth: 25, width: 25, color: textBtnColor, fontSize: STYLES.FONT_ICON_SMALLER }}
    //       />
    //       <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: textBtnColor, paddingLeft: 4 }}>{`อัพโหลด`}</Text>
    //     </TouchableOpacity>
    //     {/* <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE, paddingLeft: 4, paddingRight: 4, paddingTop: 6 }}>
    //       {`(ระบบอาจใช้เวลาประมาณ 40 วินาที ต่อ 1 บัญชี ดังนั้นเมื่อกดปุ่มอัพโหลด กรุณาเปิดหน้าต่างนี้ทิ้งไว้จนกว่าจะเสร็จ)`}
    //     </Text> */}
    //   </View>
    // )
  }

  renderLoading = () => {
    if (!this.state.isLoading) {
      return null
    }
    return (
      <View
        style={{
          // zIndex: 999,
          position: 'absolute',
          width: '100%',
          height: 800,
          backgroundColor: 'rgba(52, 52, 52, 0.7)',
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0,
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
          marginTop: 0,
        }}>
        <Spinner
          color={COLORS.APP_MAIN}
          size='lg'
          style={{
            width: STYLES.FONT_SIZE_SMALLER,
            height: 34,
            alignSelf: 'center',
            justifyContent: 'center',
            // paddingTop: loadingHeight / 2 - 5,
          }}
        />
      </View>
    )
  }

  renderAfterUpload = () => {
    const { successAddProduct, importErrorColumn, importErrorCode, importErrorRow } = this.state
    let successAddProductText = `เพิ่มสินค้าสำเร็จแล้ว ${successAddProduct} รายการ`
    const errorColumn = !_.isNil(importErrorColumn) ? `column ที่ ${importErrorColumn}` : ``
    const errorRow = !_.isNil(importErrorRow) ? `ลำดับที่ ${importErrorRow}` : ``
    const errorAddProductText = `ไม่สามารถนำเข้าสินค้า${errorRow} ${errorColumn}: ${importErrorCode}
    หากต้องการลองอีกครั้ง ในไฟล์ที่จะแนบมาใหม่ ต้องไม่รวมสินค้าลำดับก่อนหน้าที่นำเข้าสำเร็จไปแล้ว`
    if (!_.isNil(successAddProduct) && successAddProduct === 0) {
      successAddProductText = 'ไม่มีสินค้าที่ถูกเพิ่ม\nกรุณาตรวจสอบความถูกต้องของไฟล์และข้อมูลสินค้าที่ต้องการเพิ่ม แล้วลองใหม่อีกครั้ง'
    }
    return (
      <View style={{ alignItems: 'center', justifyContent: 'center', height: 100 }}>
        {_.isNil(successAddProduct) ? null : (
          <XText
            style={{
              fontSize: STYLES.FONT_SIZE_LARGER_2,
              color: successAddProduct === 0 ? COLORS.APP_MAIN : COLORS.BRAND_Success,
              paddingLeft: 22,
              paddingRight: 14,
            }}>
            {successAddProductText}
          </XText>
        )}

        {_.isNil(importErrorColumn) ? null : (
          <XText
            style={{
              fontSize: STYLES.FONT_SIZE_LARGER_2,
              color: COLORS.BRAND_Danger,
            }}>
            {errorAddProductText}
          </XText>
        )}

        {/* {_.isNil(importErrorCode) ? null : (
          <Text
            style={{
              fontSize: STYLES.FONT_SIZE_LARGER_2,
              color: COLORS.BRAND_Danger,
            }}>{`ข้อผิดพลาดที่พบ ${importErrorCode}`}</Text>
        )} */}
      </View>
    )
  }

  _renderBtnReUpload = () => {
    const btnBGColor = COLORS.APP_MAIN
    const btnBorderColor = COLORS.APP_MAIN
    const textBtnColor = COLORS.WHITE
    return (
      <View style={{ width: '100%', height: 100, justifyContent: 'center', alignItems: 'center', backgroundColor: COLORS.WHITE }}>
        <TouchableOpacity
          onPress={() => {
            util.setStatePromise(this, {
              fileUpload: null,
              isLoading: false,
              successAddProduct: null,
              importErrorColumn: null,
              importErrorCode: null,
            })
          }}
          style={{
            width: 180,
            flexDirection: 'row',
            height: 30,
            borderColor: btnBorderColor,
            backgroundColor: btnBGColor,
            borderRadius: 7,
            borderWidth: 0.8,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <XIcon
            name='refresh'
            family='MaterialIcons'
            style={{ flex: 0, minWidth: 25, width: 25, color: textBtnColor, fontSize: STYLES.FONT_ICON_SMALLER }}
          />
          <XText color='white' pl='2'>
            อัพโหลดอีกครั้ง
          </XText>
        </TouchableOpacity>
        {/* <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE, paddingLeft: 4, paddingRight: 4, paddingTop: 6 }}>
          {`(ระบบอาจใช้เวลาประมาณ 40 วินาที ต่อ 1 บัญชี ดังนั้นเมื่อกดปุ่มอัพโหลด กรุณาเปิดหน้าต่างนี้ทิ้งไว้จนกว่าจะเสร็จ)`}
        </Text> */}
      </View>
    )
  }

  renderBody = () => {
    const { successAddProduct } = this.state
    if (_.isNil(successAddProduct)) {
      return (
        <Box py='2'>
          {this._renderTitleDropzone()}
          {this._renderDropzone()}
        </Box>
      )
    }
    return (
      <Box>
        {this.renderAfterUpload()}
        {this._renderBtnReUpload()}
      </Box>
    )
  }

  _renderTitleDropzone = () => (
    <XText variant='active' bold textAlign='center'>
      กรุณาเลือกไฟล์ Excel สำหรับกระบยอด COD
    </XText>
  )

  _renderTipPDFToExcel = () => {
    const kerryPDFImportIndexes = [5, 6, 7]
    const { indexSelectorShippingType } = this.state
    if (!kerryPDFImportIndexes.includes(indexSelectorShippingType)) {
      return null
    }
    const LINK = 'https://www.ilovepdf.com/pdf_to_excel'
    return (
      <Box w='full' justifyContent='center' py='2'>
        <Box
          py='2'
          alignItems='center'
          justifyContent='center'
          style={{
            backgroundColor: COLORS.FORM_WARNING_BG,
            borderRadius: 7,
            paddingRight: 4,
            paddingLeft: 7,
          }}>
          <XText variant='active' bold>
            Tips: คุณสามารถแปลง PDF เป็น Excel ได้ที่
          </XText>
          {/* <View style={{ height: 4 }} /> */}
          <TouchableOpacity
            onPress={() => this._openLinkOrderToWebView(LINK)}
            style={{
              // width: 75,
              height: 30,
              flexDirection: 'row',
              borderColor: COLORS.APP_MAIN,
              borderWidth: 1,
              borderRadius: 7,
              backgroundColor: COLORS.BG_LIGHT_GREY,
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <XText color={COLORS.APP_MAIN} px='2'>
              {LINK}
            </XText>
          </TouchableOpacity>
        </Box>
      </Box>
    )
  }

  callBackOnPressFooter = async (result: boolean) => {
    // console.log('result => ', result)
    if (!result) {
      this._stopLoading()
      return
    }
    await util.delay(500)
    this._stopLoading()
    this.onPressUpload()
  }

  render() {
    // console.log('selectedStore 02 => ', p.op.getAppState().toJS())
    const { fileUpload, isLoading } = this.state
    const fileSize = fileUpload && fileUpload.size ? fileUpload.size : null
    // const fileName = fileUpload && fileUpload.name ? fileUpload.name : null
    return (
      <XContainer>
        {this.renderCustomHeader()}
        <XContent p='2'>
          <XCard p='2'>
            {/* {this._renderDownloadTemplateView()} */}
            {this._renderSelectorPaymentAccounts()}
            {this._renderSelectorShippingType()}
            {this._renderDateRangePicker()}
            {this._renderTipPDFToExcel()}
            {this.renderBody()}
          </XCard>
        </XContent>
        {/* {this._renderBtnUpload()} */}
        <XFooterImportExcel
          fileSize={fileSize}
          // fileName={fileName}
          callBackOnPress={(result: boolean) => {
            this._startLoading()
            this.callBackOnPressFooter(result)
          }}
          isDisabled={isLoading}
        />
        {this.renderLoading()}
      </XContainer>
    )
  }
}

// <XContent
//   style={[
//     NO_MARGIN_PADDING,
//     {
//       flex: 1,
//       flexDirection: 'column',
//       // backgroundColor: 'tomato',
//     },
//   ]} />
