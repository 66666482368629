import React, { useContext } from 'react'
import OrderCopyTemplateViewContainer from 'xui/modules/order/OrderCopyTemplateViewContainer'
import ThemeSizeContext from '../../context/ThemeSizeContext'

const WebOrderCopyTemplateView = ({ navigation }) => {
  const { contentOneOfThreeColumnWidth } = useContext(ThemeSizeContext)
  return <OrderCopyTemplateViewContainer webNavigation={navigation} webMaxScreenWidth={contentOneOfThreeColumnWidth} />
}

export default WebOrderCopyTemplateView
