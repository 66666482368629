import Sound from 'react-native-web-sound'

class XSellySound {
  soundBeepShort: Sound

  soundRingSuccess: Sound

  soundRingError: Sound

  constructor() {
    // load all sounds from public dir (as the file names will NOT be hashed)
    this.soundBeepShort = new Sound('/sounds/barcode_sound.mp3', (error) => {
      if (error) {
        console.log('failed to load beepError sound', error)
      }
    })

    this.soundRingSuccess = new Sound('/sounds/ring_success.mp3', (error) => {
      if (error) {
        console.log('failed to load beepError sound', error)
      }
    })

    this.soundRingError = new Sound('/sounds/ring_error.mp3', (error) => {
      if (error) {
        console.log('failed to load beepError sound', error)
      }
    })
  }

  playSoundBeepShort() {
    this.soundBeepShort.play()
  }

  playSoundRingSuccess() {
    this.soundRingSuccess.play()
  }

  playSoundRingError() {
    this.soundRingError.play()
  }
}

const xSound = new XSellySound()

export default xSound
