import React from 'react'
import { Image, View, ImageSourcePropType } from 'react-native'
import * as util from 'x/utils/util'
import p from 'x/config/platform-specific'
import { COLORS, STYLES } from 'x/config/styles'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContent from 'xui/components/XContent'
import XContainer from 'xui/components/XContainer'
import BaseStoreSettingXShippingJAndTView from 'x/modules/xshipping/BaseStoreSettingXShippingJAndTView'
import { VIEW_LOADING } from 'xui/utils/BaseShareUI'
import XSettingRow from 'xui/components/XSettingRow'
import XHelpModal from 'xui/components/XHelpModal'
import FAQ from 'x/config/FAQ'
import { IQuestionsAndAnswers } from 'x/index'
import Box from 'xui/components/Box'
import XText from 'xui/components/XText'
import XButton from 'xui/components/XButton'

const IMG_JT: ImageSourcePropType = require('../../images/courier/jt.png')
const IMG_SHIPJUNG: ImageSourcePropType = require('../../images/courier/shipjung.png')

export default abstract class BaseUIStoreSettingXShippingJAndTView extends BaseStoreSettingXShippingJAndTView {
  static displayName = 'BaseUIStoreSettingXShippingJAndTView'
  // abstract goBack: () => void

  _renderCustomHeader = () => (
    <XCustomHeader
      headerLeftProps={{
        backIcon: true,
        onPressItem: () => {
          util.navGoBack(this.props)
        },
      }}
      title={p.op.t('Order.shippingTypeItems.jt')}
    />
  )

  _renderUseJAndT = () => {
    const { useJAndT, useAutoXShippingIsJAndTReadyToShip, isJAntTLoading, loadingJAndTCODWidth, loadingJAndTCODHeight } = this.state
    const textTitle = 'ใช้งานการขอเลขพัสดุ\n"แบบไม่เก็บเงินปลายทาง"'
    // if (_.isNil(senderAddresses)) {
    //   return null
    // }
    // console.log('useAutoXShippingIsJAndTReadyToShip => ', useAutoXShippingIsJAndTReadyToShip)
    return (
      <View
        onLayout={(event) => {
          const { x, y, width, height } = event.nativeEvent.layout
          util.setStatePromise(this, { loadingJAndTCODWidth: width, loadingJAndTCODHeight: height })
        }}>
        <XSettingRow
          title={textTitle}
          value={useJAndT}
          onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
          stateKey='useJAndT'
          helper={{
            title: 'J&T ออกเลขพัสดุ',
            FAQ: FAQ.XSHIPPING_NOT_COD,
            initiateOpenIndex: 0,
          }}
          backgroundColor={COLORS.APP_SECONDARY}
          // disabled={useJAndT}
        />

        {useJAndT ? (
          <XSettingRow
            title='ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง'
            value={useAutoXShippingIsJAndTReadyToShip}
            onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
            stateKey='useAutoXShippingIsJAndTReadyToShip'
            helper={{
              title: 'ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง',
              FAQ: FAQ.XSHIPPING_READY_TO_SHIP,
              initiateOpenIndex: 0,
            }}
            backgroundColor={COLORS.APP_SECONDARY}
          />
        ) : null}

        {isJAntTLoading ? VIEW_LOADING(loadingJAndTCODWidth, loadingJAndTCODHeight) : null}
      </View>
    )
  }

  _renderJAndTCOD = () => {
    const {
      useJAndTCOD,
      useAutoXShippingIsJAndTCODReadyToShip,
      loadingJAntTWidth,
      loadingJAndTHeight,
      isJAndTCODLoading,
      useAutoJAndTCreateCODPaymentWhenDelivered,
    } = this.state
    // if (_.isNil(senderAddresses)) {
    //   return null
    // }
    const textTitle = 'ใช้งานการขอเลขพัสดุ\n"แบบเก็บเงินปลายทาง"'
    // const isDisabled = useJAndTCOD
    return (
      <View
        onLayout={(event) => {
          const { x, y, width, height } = event.nativeEvent.layout
          util.setStatePromise(this, { loadingJAntTWidth: width, loadingJAndTHeight: height })
        }}>
        <XSettingRow
          title={textTitle}
          value={useJAndTCOD}
          onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
          stateKey='useJAndTCOD'
          helper={{
            title: 'J&T ออกเลขพัสดุ',
            FAQ: FAQ.XSHIPPING_COD,
            initiateOpenIndex: 0,
          }}
          backgroundColor={COLORS.APP_SECONDARY}
          // disabled={useJAndTCOD}
        />

        {useJAndTCOD ? (
          <XSettingRow
            title='ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง'
            value={useAutoXShippingIsJAndTCODReadyToShip}
            onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
            stateKey='useAutoXShippingIsJAndTCODReadyToShip'
            helper={{
              title: 'ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง',
              FAQ: FAQ.XSHIPPING_READY_TO_SHIP,
              initiateOpenIndex: 0,
            }}
            backgroundColor={COLORS.APP_SECONDARY}
          />
        ) : null}

        {useJAndTCOD ? (
          <XSettingRow
            title='บันทึกรับชำระอัตโนมัติเมื่อส่งพัสดุถึงผู้รับแล้ว'
            value={useAutoJAndTCreateCODPaymentWhenDelivered}
            onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
            stateKey='useAutoJAndTCreateCODPaymentWhenDelivered'
            helper={{
              title: 'บันทึกรับชำระอัตโนมัติเมื่อส่งพัสดุถึงผู้รับแล้ว',
              FAQ: FAQ.XSHIPPING_AUTO_CREATE_COD_PAYMENT_WHEN_DELIVERED,
              initiateOpenIndex: 0,
            }}
            backgroundColor={COLORS.APP_SECONDARY}
          />
        ) : null}
        {isJAndTCODLoading ? VIEW_LOADING(loadingJAntTWidth, loadingJAndTHeight) : null}
      </View>
    )
  }

  _renderUseJAndTxShipjung = () => {
    const {
      useJAndTxShipjung,
      useAutoXShippingIsJAndTxShipjungReadyToShip,
      isJAntTxShipjungLoading,
      loadingJAndTxShipjungHeight,
      loadingJAntTxShipjungWidth,
    } = this.state
    const textTitle = 'ใช้งานการขอเลขพัสดุ\n"แบบไม่เก็บเงินปลายทาง"'
    // console.log('useAutoXShippingIsJAndTReadyToShip => ', useAutoXShippingIsJAndTReadyToShip)
    return (
      <View
        onLayout={(event) => {
          const { x, y, width, height } = event.nativeEvent.layout
          util.setStatePromise(this, { loadingJAntTxShipjungWidth: width, loadingJAndTxShipjungHeight: height })
        }}>
        <XSettingRow
          title={textTitle}
          value={useJAndTxShipjung}
          onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
          stateKey='useJAndTxShipjung'
          helper={{
            title: 'J&T ออกเลขพัสดุ',
            FAQ: FAQ.XSHIPPING_NOT_COD,
            initiateOpenIndex: 0,
          }}
          backgroundColor={COLORS.APP_SECONDARY}
          // disabled={useJAndT}
        />

        {useJAndTxShipjung ? (
          <XSettingRow
            title='ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง'
            value={useAutoXShippingIsJAndTxShipjungReadyToShip}
            onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
            stateKey='useAutoXShippingIsJAndTxShipjungReadyToShip'
            helper={{
              title: 'ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง',
              FAQ: FAQ.XSHIPPING_READY_TO_SHIP,
              initiateOpenIndex: 0,
            }}
            backgroundColor={COLORS.APP_SECONDARY}
          />
        ) : null}

        {isJAntTxShipjungLoading ? VIEW_LOADING(loadingJAntTxShipjungWidth, loadingJAndTxShipjungHeight) : null}
      </View>
    )
  }

  _renderJAndTxShipjungCOD = () => {
    const {
      useJAndTxShipjungCOD,
      useAutoXShippingIsJAndTxShipjungCODReadyToShip,
      loadingJAndTxShipjungCODHeight,
      loadingJAndTxShipjungCODWidth,
      isJAndTxShipjungCODLoading,
    } = this.state
    const textTitle = 'ใช้งานการขอเลขพัสดุ\n"แบบเก็บเงินปลายทาง"'
    // const isDisabled = useJAndTCOD
    return (
      <View
        onLayout={(event) => {
          const { x, y, width, height } = event.nativeEvent.layout
          util.setStatePromise(this, { loadingJAndTxShipjungCODWidth: width, loadingJAndTxShipjungCODHeight: height })
        }}>
        <XSettingRow
          title={textTitle}
          value={useJAndTxShipjungCOD}
          onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
          stateKey='useJAndTxShipjungCOD'
          helper={{
            title: 'J&T ออกเลขพัสดุ',
            FAQ: FAQ.XSHIPPING_COD,
            initiateOpenIndex: 0,
          }}
          backgroundColor={COLORS.APP_SECONDARY}
          // disabled={useJAndTCOD}
        />

        {useJAndTxShipjungCOD ? (
          <XSettingRow
            title='ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง'
            value={useAutoXShippingIsJAndTxShipjungCODReadyToShip}
            onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
            stateKey='useAutoXShippingIsJAndTxShipjungCODReadyToShip'
            helper={{
              title: 'ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง',
              FAQ: FAQ.XSHIPPING_READY_TO_SHIP,
              initiateOpenIndex: 0,
            }}
            backgroundColor={COLORS.APP_SECONDARY}
          />
        ) : null}

        {isJAndTxShipjungCODLoading ? VIEW_LOADING(loadingJAndTxShipjungCODWidth, loadingJAndTxShipjungCODHeight) : null}
      </View>
    )
  }

  _renderAgreement = (onPressOpenOverlay: () => void) => (
    <XButton onPress={() => onPressOpenOverlay()} w='48' variant='outline' alignSelf='center'>
      <XText variant='primary'>ดูข้อตกลงและวิธีการใช้งาน</XText>
    </XButton>
  )

  _renderJAndTDirect = () => (
    <Box
      _light={{ bg: 'white' }}
      _dark={{ bg: 'gray.500' }}
      borderRadius='md'
      style={{
        // backgroundColor: COLORS.WHITE,
        marginTop: 10,
        marginRight: 6,
        marginLeft: 6,
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 6,
        paddingBottom: 15,
      }}>
      <View style={{ justifyContent: 'center', alignItems: 'center' }}>
        <Image
          source={IMG_JT}
          resizeMode='stretch'
          style={{
            // position: 'absolute',
            width: 144,
            height: 48,
            backgroundColor: 'transparent',
            zIndex: 70,
          }}
        />
      </View>
      <View style={{ justifyContent: 'center', alignItems: 'center' }}>
        <XText variant='active'>ขอเลขพัสดุจาก J&T Express โดยตรง</XText>
      </View>

      <Box h='2' />

      <XHelpModal
        key='XHelpModalFromXSettingRow'
        headerTitle='ข้อตกลงและวิธีการใช้งาน'
        FAQ={FAQ.XSHIPPING_J_AND_T_AGREEMENT}
        initiateOpenIndex={4}
        renderButton={(onPressOpenOverlay: () => void) => this._renderAgreement(onPressOpenOverlay)}
      />

      <Box h='2' />

      <Box justifyContent='center' alignItems='center'>
        <XButton onPress={() => this._navToJAndTConnectView()} w='40'>
          <XText color='white' bold alignSelf='center'>
            ตั้งค่าการลงทะเบียน
          </XText>
        </XButton>
      </Box>

      <View style={{ height: 10 }} />
      <View>
        <View style={{ paddingBottom: 2, flexDirection: 'row' }}>
          <XText variant='inactive'>{'รูปแบบจัดส่ง: '}</XText>
          <XText style={{ fontSize: STYLES.FONT_SIZE_LARGER, color: COLORS.TEXT_ACTIVE, fontWeight: 'bold' }}>{`${p.op.t(
            'Order.shippingTypeItems.jt'
          )}`}</XText>
        </View>
        {this._renderUseJAndT()}
        <View style={{ height: 10 }} />
        <View style={{ paddingBottom: 2, flexDirection: 'row' }}>
          <XText variant='inactive'>{'รูปแบบจัดส่ง: '}</XText>
          <XText style={{ fontSize: STYLES.FONT_SIZE_LARGER, color: COLORS.TEXT_ACTIVE, fontWeight: 'bold' }}>{`${p.op.t(
            'Order.shippingTypeItems.jt_cod'
          )}`}</XText>
        </View>
        {this._renderJAndTCOD()}
      </View>
    </Box>
  )

  _getFAQAgreementForJAndTxShipjung = (): IQuestionsAndAnswers[] => {
    const newFQA = []
    FAQ.XSHIPPING_AGREEMENT_J_AND_T_X_SHIPJUNG.map((faq: IQuestionsAndAnswers) => {
      newFQA.push(faq)
    })
    FAQ.XSHIPPING_AGREEMENT_SHIPJUNG_COMMON.map((faq: IQuestionsAndAnswers) => {
      newFQA.push(faq)
    })
    return newFQA
  }

  _renderJAndTxShipjung = () => (
    <Box
      _light={{ bg: 'white' }}
      _dark={{ bg: 'gray.500' }}
      borderRadius='md'
      style={{
        // backgroundColor: COLORS.WHITE,
        marginTop: 10,
        marginRight: 6,
        marginLeft: 6,
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 6,
        paddingBottom: 15,
      }}>
      <View style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
        <Image
          source={IMG_JT}
          resizeMode='stretch'
          style={{
            // position: 'absolute',
            width: 144,
            height: 48,
            backgroundColor: 'transparent',
            zIndex: 70,
            marginLeft: -20,
          }}
        />
        <XText style={{ fontSize: STYLES.FONT_SIZE_LARGER, color: COLORS.TEXT_ACTIVE, paddingRight: 10 }}>X</XText>
        <Image
          source={IMG_SHIPJUNG}
          resizeMode='stretch'
          style={{
            // position: 'absolute',
            width: 90,
            height: 58,
            backgroundColor: 'transparent',
            zIndex: 70,
          }}
        />
      </View>
      <View style={{ justifyContent: 'center', alignItems: 'center' }}>
        <XText variant='active'>ขอเลขพัสดุจาก J&T Express ผ่าน Shipjung</XText>
      </View>

      <Box h='2' />

      <XHelpModal
        key='XHelpModalFromXSettingRow'
        headerTitle='ข้อตกลงและวิธีการใช้งาน'
        FAQ={this._getFAQAgreementForJAndTxShipjung()}
        initiateOpenIndex={4}
        renderButton={(onPressOpenOverlay: () => void) => this._renderAgreement(onPressOpenOverlay)}
      />

      <Box h='2' />

      <View style={{ paddingBottom: 2, flexDirection: 'row' }}>
        <XText variant='inactive'>{'รูปแบบจัดส่ง: '}</XText>
        <XText style={{ fontSize: STYLES.FONT_SIZE_LARGER, color: COLORS.TEXT_ACTIVE, fontWeight: 'bold' }}>{`${p.op.t(
          'Order.shippingTypeItems.jt_pickup'
        )}`}</XText>
      </View>
      {this._renderUseJAndTxShipjung()}
      <View style={{ height: 10 }} />
      <View style={{ paddingBottom: 2, flexDirection: 'row' }}>
        <XText variant='inactive'>{'รูปแบบจัดส่ง: '}</XText>
        <XText style={{ fontSize: STYLES.FONT_SIZE_LARGER, color: COLORS.TEXT_ACTIVE, fontWeight: 'bold' }}>{`${p.op.t(
          'Order.shippingTypeItems.jt_cod_pickup'
        )}`}</XText>
      </View>
      {this._renderJAndTxShipjungCOD()}
    </Box>
  )

  render() {
    return (
      <XContainer>
        {this._renderCustomHeader()}
        <XContent>
          <Box w='full'>
            {this._renderJAndTDirect()}
            {/* {this._renderJAndTxShipjung()} */}
          </Box>
        </XContent>
      </XContainer>
    )
  }
}
