import { ICustomAlertButton } from './Alert'

const warn = () => console.log("haven't resgiter the Alert yet")

const ar: {
  alert(title: string, message?: string, buttons?: ICustomAlertButton[], options?)
} = {
  // @ts-ignore
  alert: warn,
  // hide: warn,
}

/**
 * this method is to save the referece of the singleton component
 */
export const registerAlert = (ref) => {
  if (ref) {
    console.log('Alert registered')
    ar.alert = ref.alert
    // ar.hide = ref.hide
  } else {
    console.log('Alert unregistered')
    // @ts-ignore
    ar.alert = warn
    // ar.hide = warn
  }
}

/**
 * export the ats component
 */
export { default as AlertInstance } from './Alert'

/**
 * export the open and close method
 */
export default ar
