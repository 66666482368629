import React from 'react'
import constate from 'constate'
import _ from 'lodash'
import CONS from 'x/config/constants'

import { IMkpProductDetail, IMkpProductRequestBody, IProductDetailItem, IXSellyErrorResponse } from 'x/index'

import { delay } from 'x/utils/util'
import { ACTIONS, reducer, initialState, IMkpProductStateByKey } from './MkpProductContextReducer'
import { fetchMkpProductDetail } from './MkpProductContextApi'

export const useMkpProduct = () => {
  // Create reducer
  const [state, dispatch] = React.useReducer(reducer, initialState)

  // Define selector functions
  const getMkpState = (key: string) => (state[key] ? state[key] : null)
  const getMkpProduct = (key: string) => (state[key] ? state[key].mkpProduct : null)
  const getCoreProduct = (key: string) => (state[key] ? state[key].coreProduct : null)
  const getIsLoading = (key: string) => (state[key] ? state[key].isLoading : null)
  const getError = (key: string) => (state[key] ? state[key].error : null)

  // Define lifecycle functions
  // Allocate mkpProduct with key
  const init = (key: string, options?: Partial<IMkpProductStateByKey>) => dispatch({ type: ACTIONS.INIT, key, options })
  // Deallocate mkpProduct with key
  const destroy = (key: string) => dispatch({ type: ACTIONS.DESTROY, key })

  // Define Api handler function
  const fetchMkpProduct = async (key: string, requestBody: IMkpProductRequestBody): Promise<IMkpProductDetail | null> => {
    let shouldThrowError = false
    if (!state[key] || _.isEmpty(state[key])) {
      init(key)
      await delay(50)
    }
    if (state[key] && state[key].isLoading) {
      return null
    }
    setIsLoading(key, true)
    await delay(50)
    const res = await fetchMkpProductDetail({ body: requestBody })

    // @ts-ignore
    if (res && res.product) {
      // @ts-ignore
      const mkpProduct: IMkpProductDetail = _.cloneDeep(res.product)
      // console.log('mkpProduct => ', mkpProduct)

      if (_.isNil(mkpProduct.img_uris) || _.isNil(mkpProduct.thumbnail_uris)) {
        mkpProduct.img_uris = [CONS.IMG_PLACEHOLDER_URL]
        mkpProduct.thumbnail_uris = [CONS.IMG_PLACEHOLDER_URL]
      }

      try {
        // generate paired_pts
        mkpProduct.paired_pts = []
        for (let i = 0; i < mkpProduct.variants.length; i++) {
          const mkpV = mkpProduct.variants[i]
          if (mkpV.paired_pt_id) {
            mkpProduct.paired_pts.push({
              paired_pt_id: mkpV.paired_pt_id,
              paired_pt_name: mkpV.paired_pt_name,
              paired_pt_thumbnail_uri: mkpV.paired_pt_thumbnail_uri,
              paired_pp_id: mkpV.paired_pp_id,
              paired_pp_name: mkpV.paired_pp_name,
              paired_pp_thumbnail_uri: mkpV.paired_pp_thumbnail_uri,
            })
          }
        }
        // mkpProduct.paired_pts = mkpProduct.variants.map((mkpV) => ({
        //   paired_pt_id: mkpV.paired_pt_id,
        //   paired_pt_name: mkpV.paired_pt_name,
        //   paired_pt_thumbnail_uri: mkpV.paired_pt_thumbnail_uri,
        // }))
        // console.log('deduplication mkpProduct.paired_pts => ', mkpProduct.paired_pts)

        const pairedPtIds = mkpProduct.paired_pts.map((ppt) => ppt.paired_pt_id)
        // console.log('deduplication pairedPtIds => ', pairedPtIds)

        const dedupPairedPts = mkpProduct.paired_pts.filter((ppt, index) => pairedPtIds.indexOf(ppt.paired_pt_id) === index)
        // console.log('deduplication dedupPairedPts => ', dedupPairedPts)

        for (let i = 0; i < dedupPairedPts.length; i++) {
          const dpptId = dedupPairedPts[i].paired_pt_id
          dedupPairedPts[i].pairedVariantCount = mkpProduct.variants.reduce((prevCount, v) => {
            if (v.paired_pt_id === dpptId) {
              return prevCount + 1
            }
            return prevCount
          }, 0)
        }

        mkpProduct.paired_pts = dedupPairedPts
        // console.log('deduplication mkpProduct => ', mkpProduct)
      } catch (err) {
        console.log('deduplication paired_pts err => ', err)
      }

      setMkpProduct(key, mkpProduct)
      setError(key, null)
    } else {
      setError(key, res as IXSellyErrorResponse)
      shouldThrowError = true
    }
    await delay(50)
    setIsLoading(key, false)

    if (shouldThrowError) {
      throw res
    }

    // @ts-ignore
    return res && res.product ? res.product : null
  }

  // Define setter function
  const setCoreProduct = (key: string, newCoreProduct: IProductDetailItem) => {
    dispatch({ type: ACTIONS.SET_CORE_PRODUCT, key, newCoreProduct })
  }
  const setMkpProduct = (key: string, newMkpProduct: IMkpProductDetail) => {
    dispatch({ type: ACTIONS.SET_MKP_PRODUCT, key, newMkpProduct })
  }
  const setIsLoading = (key: string, newValue: boolean) => {
    dispatch({ type: ACTIONS.SET_IS_LOADING, key, newValue })
  }
  const setError = (key: string, newError: IXSellyErrorResponse) => {
    dispatch({ type: ACTIONS.SET_ERROR, key, newError })
  }

  return {
    init,
    destroy,
    // ...state,
    getMkpProduct,
    getCoreProduct,
    getMkpState,
    getIsLoading,
    getError,

    fetchMkpProduct,
    setMkpProduct,
    setCoreProduct,
  }
}

export type MkpProductConsumerContextPropsType = ReturnType<typeof useMkpProduct>

export interface IMkpProductContextProps {
  mkpProductContext: MkpProductConsumerContextPropsType
}

export const [MkpProductContextProvider, useMkpProductContext] = constate(useMkpProduct)
