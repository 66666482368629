import _ from 'lodash'

import * as NavActions from 'x/utils/navigation'
import * as util from 'x/utils/util'
import { IProductListViewMyStoreWithVolumeDiscountNavParams, IProductListViewProps, IProductListViewState } from 'x/index'

import BaseUIProductListView from './BaseUIProductListView'

type IProductListViewMyStoreWithVolumeDiscountProps = IProductListViewProps<IProductListViewMyStoreWithVolumeDiscountNavParams>

class ProductListViewMyStoreWithVolumeDiscount extends BaseUIProductListView<
  IProductListViewMyStoreWithVolumeDiscountProps,
  IProductListViewState
> {
  static displayName = 'ProductListViewMyStoreWithVolumeDiscount'

  // override
  isRequiredProductVariants = () => false

  // override
  isAddProductButtonVisible = () => false

  // override
  onPressProductItem = (item) => {
    this.navToProductView(item.data.id)
  }

  // implement abstract
  getHaveNoProductItemText = () => 'ไม่พบรายการสินค้า'

  // override
  // เพิ่ม vd_id เข้าไปในการ filter
  getModifiedRequestBody = (body) => {
    const { navigation } = this.props
    const vdId = util.getNavParam(this.props, 'vd_id')
    const newBody = _.cloneDeep(body)
    if (vdId) {
      newBody.vd_id = vdId
    }
    return newBody
  }

  navToProductView = (product_id: number) => {
    const { navigation } = this.props
    const storeId = util.getNavParam(this.props, 'store_id')
    navigation.dispatch(
      NavActions.navToProductViewMyStore({
        store_id: storeId,
        product_id,
        onSuccessAddProductCallback: this.onSuccessAddProductCallback,
        onSuccessEditProductCallback: this.onSuccessEditProductCallback,
        onSuccessDeleteProductCallback: this.onSuccessDeleteProductCallback,
      })
    )
  }
}

export default ProductListViewMyStoreWithVolumeDiscount
