import React from 'react'
import { COLORS } from 'x/config/styles'

import { View, TouchableOpacity, Linking } from 'react-native'
import BaseReportPeakView from 'x/modules/report/BaseReportPeakView'
import XCustomHeader from 'xui/components/XCustomHeader'
import * as util from 'x/utils/util'
import DateRangePicker from 'xui/components/XDateRangePicker'
import XIcon from 'xui/components/XIcon'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import Segment from 'xui/components/Segment'
import SegmentOrderPaymentStatus from 'xui/components/report/SegmentOrderPaymentStatus'
import SegmentOrderShippingStatus from 'xui/components/report/SegmentOrderShippingStatus'
import XHelpModal from 'xui/components/XHelpModal'
import FAQ from 'x/config/FAQ'
import HelpButton from 'xui/components/HelpButton'
import XCard from 'xui/components/XCard'
import XText from 'xui/components/XText'
import XButton from 'xui/components/XButton'
import HStack from 'xui/components/HStack'
import VStack from 'xui/components/VStack'

export default abstract class BaseUIReportPeakView extends BaseReportPeakView {
  static displayName = 'BaseUIReportPeakView'

  abstract _downloadReportStockView(url: string, fileName: string): Promise<void>

  _handleOnDownloadFile = async (url, fileName) => {
    await this._downloadReportStockView(url, fileName)
  }

  renderHelpBtn = (onPressOpenOverlay: () => void) => (
    <View style={{ width: 30 }}>
      <HelpButton style={{ minWidth: 10, width: 10 }} onPress={() => onPressOpenOverlay()} />
    </View>
  )

  _renderExampleBtn = () => (
    <HStack w='full'>
      <HStack flex={1} alignItems='center'>
        <XText variant='active'>{'ใช้เพื่อไปนำเข้าข้อมูลการขายที่\nโปรแกรมบัญชี PEAK Account'}</XText>
        <XHelpModal
          key='XHelpModalFromXForceCreateShipping'
          headerTitle='PEAK Account'
          FAQ={FAQ.PEAK_REPORT_FAQ}
          initiateOpenIndex={0}
          // renderButton={(onPressOpenOverlay: () => void) => renderHelpBtn(onPressOpenOverlay)}
          renderButton={this.renderHelpBtn}
        />
      </HStack>
      {/* <View style={{ justifyContent: 'center', alignItems: 'flex-end', width: 120 }}> */}
      <TouchableOpacity
        style={{
          paddingLeft: 8,
          paddingRight: 8,
          borderColor: COLORS.APP_MAIN,
          borderRadius: 7,
          borderWidth: 0.7,
          // alignSelf: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          height: 25,
          backgroundColor: COLORS.BG_LIGHT_GREY,
        }}
        onPress={() => Linking.openURL('https://s1.xselly.com/x/samples/rp/PEAKSalesReport.xlsx')}>
        <XText
          style={{
            color: COLORS.APP_MAIN,
          }}>
          ดูตัวอย่าง
        </XText>
      </TouchableOpacity>
      {/* </View> */}
    </HStack>
  )

  _renderDateRangePicker = () => {
    const { selectedDateRange, date_type } = this.state
    return (
      <VStack w='full' space='1' mt='4'>
        <XText variant='active' bold>
          เลือกช่วงวันที่ออเดอร์...
        </XText>
        <Segment
          onSegmentChange={(newIndex) => this.setState({ date_type: newIndex })}
          options={['ถูกสร้าง', 'เสร็จสิ้น', 'พร้อมส่ง', 'กำหนดส่ง']}
          selectedIndex={date_type}
        />
        {/* </View> */}
        {/* <View style={{ height: p.op.isWeb() ? 100 : 80 }}> */}
        <VStack w='full' py='2' h='24'>
          <DateRangePicker
            // disabled={false}
            // allowOnlyDaysFromToday={this._computeAllowDays()}
            // allowDateRangeLimitDays={this.state.limitDay}
            showTime
            oneLineDateTime={false}
            selectedDates={selectedDateRange}
            onChangeDate={this._onChangeDateRange}
          />
        </VStack>
        {/* </View> */}
      </VStack>
    )
  }

  _renderSelctedStatus = () => (
    <View>
      <View style={{ height: 15 }} />
      <SegmentOrderPaymentStatus
        initialIndex={0}
        // titleStyle={{ fontWeight: 'bold' }}
        onSegmentChange={this.onSegmentOrderPaymentStatusChange}
        selectedIndex={this.state.latestOrderPaymentStatusOptionIndex}
      />
      <View style={{ height: 15 }} />
      <SegmentOrderShippingStatus
        initialIndex={0}
        // titleStyle={{ fontWeight: 'bold' }}
        onSegmentChange={this.onSegmentOrderShippingStatusChange}
        selectedIndex={this.state.latestOrderShippingStatusOptionIndex}
      />
    </View>
  )

  _renderMain = () => (
    // const { checkboxListOption } = this.state
    // if (_.isNil(checkboxListOption) || checkboxListOption.length < 1) {
    //   return null
    // }
    <XCard
      w='full'
      p='2'
      onLayout={(event) => {
        const { x, y, width, height } = event.nativeEvent.layout
        util.setStatePromise(this, { loadingWidth: width, loadingHeight: height })
      }}>
      {this._renderExampleBtn()}
      {this._renderDateRangePicker()}
      {this._renderSelctedStatus()}
      {/* <View style={{ height: 60 }} /> */}
    </XCard>
  )

  renderFooter = () => (
    <HStack w='full' p='1'>
      <XButton
        w='full'
        py='3'
        onPress={() => {
          this._showConfirmationDownloadReport()
        }}
        leftIcon={<XIcon family='FontAwesome' name='file-excel-o' size='xs' />}>
        ดาวน์โหลดข้อมูลเพื่อนำเข้า PEAK Account
      </XButton>
    </HStack>
  )

  render() {
    const { isLoading, loadingHeight, loadingWidth } = this.state
    return (
      <XContainer>
        <XCustomHeader
          title='รายงาน Excel PEAK Account'
          headerLeftProps={{ backIcon: true, onPressItem: () => util.navGoBack(this.props) }}
        />
        <XContent p='2'>{this._renderMain()}</XContent>
        {this.renderFooter()}
      </XContainer>
    )
  }
}
