import { connect } from 'react-redux'
// import { getState, getDispatch } from 'x/modules/order/BaseOrderViewConnect'
import { bindActionCreators } from 'redux'
import * as StoreActions from 'x/modules//store/StoreState'
import { getSelectedStore, getMySubscription } from 'x/redux/selectors'
import ChannelView from './ChannelView'

export default connect(
  (state) => ({
    // ...getState(state),
    selectedStore: getSelectedStore(state),
    subscription: getMySubscription(state),
  }),
  (dispatch) => ({
    // ...getDispatch(dispatch),
    getShopeeAuthUrl: bindActionCreators(StoreActions.shopeeAuthUrl, dispatch),
    createUserGroup: bindActionCreators(StoreActions.createUserGroup, dispatch),
    updateNewChannelList: bindActionCreators(StoreActions.updateNewChannelList, dispatch),
    updateChannel: bindActionCreators(StoreActions.updateChannel, dispatch),
    deleteChannel: bindActionCreators(StoreActions.deleteChannel, dispatch),
    syncBackgroundJobs: bindActionCreators(StoreActions.syncBackgroundJobs, dispatch),
    syncStoreBGTask: bindActionCreators(StoreActions.syncMKPBackgroungTasks, dispatch),
    getChannel: bindActionCreators(StoreActions.getChannel, dispatch),
    syncShopInfo: bindActionCreators(StoreActions.syncShopInfo, dispatch),
    syncMKPProduct: bindActionCreators(StoreActions.syncMKPProduct, dispatch),
    syncMKPOrder: bindActionCreators(StoreActions.syncMKPOrder, dispatch),
  })
)(ChannelView)
