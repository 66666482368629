import React from 'react'
// import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'
import BaseERPCreateView from 'x/modules/erp/BaseERPCreateView'
import XCustomHeader from 'xui/components/XCustomHeader'
// import { Input } from 'react-native-elements'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import XCard from 'xui/components/XCard'
import XText from 'xui/components/XText'
import { TouchableOpacity } from 'react-native'
import HStack from 'xui/components/HStack'
import Box from 'xui/components/Box'
import XIcon from 'xui/components/XIcon'
import { COLORS } from 'x/config/styles'
import XButton from 'xui/components/XButton'
import ErpLogo from 'xui/components/ErpLogo'

// const VIEW_HEIGHT_TEN = <View style={{ height: 10 }} />

export default abstract class BaseUIERPCreateView extends BaseERPCreateView {
  renderCustomHeader = () => {
    const isGoBackButtonHidden = util.getNavParam(this.props, 'isGoBackButtonHidden', false)
    // const { isRenderWebView } = this.state
    return (
      <XCustomHeader
        title='สร้างบัญชี'
        headerLeftProps={{ backIcon: true, onPressItem: () => this.goBack() }}
        // headerRight={this._renderHeaderRight()}
      />
    )
  }

  _renderErpList = () => {
    const { erpChannelDetail } = this.state
    // if (_.isNil(erpChannelDetail)) {
    //   return null
    // }
    const isSelected = false
    const mkpImg = <ErpLogo erpSystemId={1} />
    return (
      <XCard p='2' m='2'>
        <XText>เลือกช่องทางบัญชีเชื่อมต่อ</XText>
        <XCard w='full' mt='2' p='2'>
          <TouchableOpacity onPress={() => null} style={{ flex: 1 }}>
            <HStack
              borderRadius='md'
              w='full'
              alignItems='center'
              py='2'
              borderWidth='1'
              borderColor={isSelected ? COLORS.APP_MAIN : COLORS.TEXT_INACTIVE}>
              {isSelected ? (
                <Box w='12' alignItems='center'>
                  <XIcon name='caretright' family='AntDesign' color={COLORS.APP_MAIN} />
                </Box>
              ) : (
                <Box w='12' />
              )}
              <Box style={{ width: 50 }}>{mkpImg}</Box>
              <Box style={{ flex: 1 }}>
                <XText bold fontSize='md' variant='active'>
                  PEAK
                </XText>
              </Box>
            </HStack>
          </TouchableOpacity>
        </XCard>
        {this._renderFooter()}
      </XCard>
    )
  }

  _renderFooter = () => (
    <XButton mt='2' w='full' _text={{ fontSize: 'md', paddingTop: '1' }} onPress={() => this._navToErpCreatePeakView()}>
      ดำเนินการต่อ
    </XButton>
  )

  _renderMain = () => {
    const {} = this.state
    return (
      <XContainer>
        {this.renderCustomHeader()}
        <XContent>{this._renderErpList()}</XContent>
      </XContainer>
    )
  }

  render() {
    return this._renderMain()
  }
}
