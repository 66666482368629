/* eslint-disable no-redeclare */
/* eslint-disable no-dupe-class-members */
import React from 'react'
// import p from 'x/config/platform-specific'
import BaseSelectedProductMultiParcelView from 'x/modules/order/BaseSelectedProductMultiParcelView'
// import { CHANNEL_PICK } from './BaseUIChannelPickView'
import XCustomHeader from 'xui/components/XCustomHeader'
// import { Input } from 'react-native-elements'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import _ from 'lodash'
import XCard from 'xui/components/XCard'
import VStack from 'xui/components/VStack'
import XButton from 'xui/components/XButton'
import HStack from 'xui/components/HStack'
import XIcon from 'xui/components/XIcon'
import XText from 'xui/components/XText'
import { COLORS } from 'x/config/styles'
import { TouchableOpacity, Platform } from 'react-native'
import FastImage from 'react-native-fast-image'
import XOverlay from 'xui/components/XOverlay'
import p from 'x/config/platform-specific'
import XInput from 'xui/components/XInput'

// const _renderAgreement = (onPressOpenOverlay: () => void) => <HelpButton onPress={() => onPressOpenOverlay()} />

export default abstract class BaseUISelectedProductMultiParcelView extends BaseSelectedProductMultiParcelView {
  renderCustomHeader = () => {
    const leftBtn = {
      backIcon: true,
      onPressItem: () => this._goBack(),
    }
    const { parcelIndex } = this.state
    let titleHeader = 'ระบสินค้าสำหรับพัสดุ'
    if (!_.isNil(parcelIndex)) {
      titleHeader = `ระบสินค้าสำหรับพัสดุ #${parcelIndex}`
    }
    return <XCustomHeader title={titleHeader} headerLeftProps={leftBtn} />
  }

  _renderFooter = () => {
    const { order, parcelIndex, parcel } = this.state
    if (_.isNil(order)) {
      return null
    }
    const text = `ระบุสินค้าสำหรับพัสดุ #${parcelIndex}`
    return (
      <XButton disabled={!!_.isNil(parcel)} p='2' mx='2' my='1' onPress={() => this._onPressFooter()}>
        {text}
      </XButton>
    )
  }

  // _renderProducts = () => {
  //   const { order } = this.state
  //   if (_.isNil(order)) {
  //     return null
  //   }
  // }

  _renderHeaderProducts = () => {
    const { parcel } = this.state
    // console.log('parcel => --> ', parcel)
    const countTotal = this._checkMultiParcelCount()
    let countParcel = 0
    if (!_.isNil(parcel)) {
      parcel.items.forEach((item) => {
        countParcel += item.qty
      })
    }
    const isAllDone = countParcel === countTotal
    return (
      <HStack py='2' borderTopWidth='1' borderTopColor={COLORS.TEXT_ACTIVE} borderBottomWidth='1' borderBottomColor={COLORS.TEXT_INACTIVE}>
        <HStack w='10' borderRightWidth='1' borderRightColor={COLORS.TEXT_INACTIVE} justifyContent='center' alignItems='center'>
          <TouchableOpacity onPress={() => this._selectedAllProduct()}>
            <XIcon name={isAllDone ? 'check-circle' : 'circle-o'} family='FontAwesome' color={COLORS.GREEN} />
          </TouchableOpacity>
        </HStack>
        <HStack pl='2'>
          <XText>{`ยืนยันจำนวนแล้ว ${countParcel} จาก ${countTotal}`}</XText>
        </HStack>
      </HStack>
    )
  }

  // _checkProductToList = (product: IOrderProductItem) => {
  //   const { parcels } = this.state
  //   const { pp_id, shipped_qty } = product
  //   let productQty = product.qty - shipped_qty
  //   parcels.forEach((parcel) => {
  //     parcel.items.forEach((item) => {
  //       if (item.pp_id === pp_id) {
  //         if (productQty === item.qty) {
  //           productQty = 0
  //         } else {
  //           productQty -= item.qty
  //         }
  //       }
  //     })
  //   })
  //   return productQty
  // }
  // _checkProductToList ข้างบนคือ code แบบอย่างง่าย

  _renderProductListView = () => {
    const { products, parcel } = this.state
    if (_.isNil(products)) {
      return null
    }
    let indexofProduct = 0
    // 4152973 4161195 4152224
    return (
      <VStack>
        {products.map((product) => {
          const { name } = product
          const SKU = product.sku ? product.sku : null
          const UPC = product.upc ? product.upc : null
          const isEven = indexofProduct % 2 === 0
          let qtyPickNow = 0
          let checkbox = false
          const maxProduct = this._checkProductToList(product)
          let BG_PRODUCT_COLOR = isEven ? COLORS.BG_LIGHT_GREY : COLORS.WHITE
          // console.log('parcel // 01 // => ', parcel)
          // console.log('product // 01 // => ', product)
          if (!_.isNil(parcel)) {
            parcel.items.forEach((item) => {
              if (item.pp_id === product.pp_id) {
                qtyPickNow = item.qty
                if (item.qty === maxProduct) {
                  BG_PRODUCT_COLOR = COLORS.FORM_SUCCESS_BG
                  checkbox = true
                }
              }
            })
          }

          if (maxProduct === 0) {
            return null
          }
          indexofProduct += 1
          return (
            <HStack key={product.pp_id} py='2' borderBottomWidth='1' borderBottomColor={COLORS.TEXT_INACTIVE} bgColor={BG_PRODUCT_COLOR}>
              <HStack w='10' borderRightWidth='1' borderRightColor={COLORS.TEXT_INACTIVE} justifyContent='center' alignItems='center'>
                <TouchableOpacity onPress={() => this._selectedProduct(product)}>
                  <XIcon name={checkbox ? 'check-circle' : 'circle-o'} family='FontAwesome' color={COLORS.GREEN} />
                </TouchableOpacity>
              </HStack>
              <HStack flex={1} pl='1'>
                <FastImage style={{ width: 44, height: 44 }} source={{ uri: product.thumbnail_uris[0] }} resizeMode='cover' />
                <VStack px='1' mr='4'>
                  {p.op.isWeb() ? (
                    <XText w='48' numberOfLines={1}>
                      {name}
                    </XText>
                  ) : (
                    <XText numberOfLines={1}>{name}</XText>
                  )}
                  <XText variant='inactive'>{`ตัวเลือก: ${product.variant}`}</XText>
                  {!_.isNil(SKU) ? <XText variant='inactive'>{`SKU: ${product.variant}`}</XText> : null}
                  {!_.isNil(UPC) ? <XText variant='inactive'>{`UPC: ${product.variant}`}</XText> : null}
                </VStack>
              </HStack>
              <VStack w='12' justifyContent='center' alignItems='center'>
                <XText>{`${qtyPickNow}/${maxProduct}`}</XText>
                <TouchableOpacity
                  onPress={() => this._onPressEditProduct(product, maxProduct)}
                  style={{ marginTop: 4, flex: 1, alignItems: 'center', width: '100%' }}>
                  <XIcon name='edit' family='FontAwesome' />
                </TouchableOpacity>
              </VStack>
            </HStack>
          )
        })}
      </VStack>
    )
  }

  _renderProducts = () => (
    <VStack>
      <HStack justifyContent='center' alignItems='center'>
        <XText bold variant='active'>
          รายการสินค้าที่ต้องจัดส่ง
        </XText>
      </HStack>
      {this._renderHeaderProducts()}
      {this._renderProductListView()}
    </VStack>
  )

  // PAECELS ARTID NOTE 4158683
  // ดูสินค้าร้านฉัน ต้องมีที่ต้องจัดส่งมากกว่า 1 / EDIT Mode ใช้ไม่ได้ / เพิ่ม XShipping

  _renderOverlayEditProduct = () => {
    const { isVisibleOverlayEditProduct, selectedProduct, maxSelectedProduct, inputSelectedProduct } = this.state
    if (_.isNil(selectedProduct) || _.isNil(maxSelectedProduct)) {
      return null
    }
    const productName = selectedProduct.name
    const variantName = selectedProduct.variant
    // const productQty = _.isNumber(selectedProduct.qty) ? `${selectedProduct.qty}` : ''
    // console.log('selectedProduct.qty => ', selectedProduct.qty)
    // console.log('productQty => ', productQty)
    const _onChangeInputQtyText = (newText: string) => {
      // setInputQtyText(newText)
      // const newSelectedProduct = _.cloneDeep(selectedProduct)
      // newSelectedProduct.qty = +newText
      this.setState({
        inputSelectedProduct: +newText > maxSelectedProduct ? maxSelectedProduct : +newText,
      })
    }
    const inPutValue = `${inputSelectedProduct}`

    return (
      <XOverlay
        // contentStyle={{ padding: 8 }}
        contentStyle={{
          width: 300,
          minHeight: 220,
          height: p.op.isWeb() ? 220 : undefined,
          // avoid keyboard style
          ...Platform.select({
            ios: {
              marginBottom: 200,
            },
            // android: {
            //   marginBottom: 35,
            // },
          }),
        }}
        visible={isVisibleOverlayEditProduct}
        onRequestClose={() => this._closeOverlayEditProduct()}>
        <XCustomHeader
          headerRightProps={{ closeIcon: true, onPressItem: () => this._closeOverlayEditProduct() }}
          title='ระบุจำนวนสินค้าสำหรับพัสดุนี้'
        />
        <VStack justifyContent='center' alignItems='center' mt='2'>
          <XText variant='active'>{productName}</XText>
          <XText variant='inactive'>{`ตัวเลือก: ${variantName}`}</XText>
          <HStack h='9' mt='1'>
            <XInput
              placeholder='ระบุจำนวนสินค้า...'
              autoFocus
              textAlign='center'
              focusable
              isInteger
              style={{ flex: 1, maxWidth: 120 }}
              value={inPutValue}
              onChangeText={_onChangeInputQtyText}
              // onSubmitEditing={_onSubmitInputQty}
            />
          </HStack>
          <XText variant='inactive' mt='1'>
            {`(ระบุจำนวนระหว่าง 0-${maxSelectedProduct})`}
          </XText>
          <HStack mt='2'>
            <TouchableOpacity
              onPress={() => this._submitSelectedProduct()}
              style={{
                backgroundColor: COLORS.APP_MAIN,
                borderRadius: 7,
                height: 34,
                width: 80,
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <XText variant='activeDark' bold>
                ยืนยัน
              </XText>
            </TouchableOpacity>
          </HStack>
        </VStack>
      </XOverlay>
    )
  }

  render() {
    return (
      <XContainer>
        {this.renderCustomHeader()}
        {this._renderOverlayEditProduct()}
        <XContent
          p='2'
          // onLayout={event => {
          //   const { x, y, width, height } = event.nativeEvent.layout
          //   util.setStatePromise(this, { loadingWidth: width, loadingHeight: height })
          // }}
        >
          <XCard w='full' p='2' mb='2'>
            <VStack w='full' space='2'>
              {this._renderProducts()}
            </VStack>
          </XCard>
        </XContent>
        {this._renderFooter()}
        {/* {this._loading()} */}
      </XContainer>
    )
  }
}
