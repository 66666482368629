/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-unused-class-component-methods */
import React from 'react'
import _ from 'lodash'
import { IApiOptions, IERPPaymentAccount, IPaymentAccount, ISelectedStoreMap, IXScreenProps } from 'x/types'
import * as util from 'x/utils/util'
import api from 'x/utils/api'
import CONS from 'x/config/constants'
import p from 'x/config/platform-specific'

interface IBaseERPEditPaymentAccountViewProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
}

interface IBaseERPEditPaymentAccountViewState {
  isBindAccount: boolean
  erpPaymentAccount: IERPPaymentAccount
  payment: IPaymentAccount
  erpPaymentAccountCode: string
  isEdit: boolean
  mode: 'EDIT' | 'CREATE'
  bankAccountType: 'CurrentAccount' | 'SavingAccount' | 'FixedDeposit'
}

export default abstract class BaseERPEditPaymentAccountView extends React.Component<
  IBaseERPEditPaymentAccountViewProps,
  IBaseERPEditPaymentAccountViewState
> {
  // abstract async handleSubmitForm(response: any): Promise<void>
  // abstract didMountCheckPermissionForPhone?: () => void
  // validationSchema: Yup.ObjectSchema<any>
  // imgManagerIDRef: React.RefObject<any>
  // webview: React.RefObject<any>

  constructor(props) {
    super(props)
    this.state = {
      isBindAccount: false,
      erpPaymentAccount: null,
      payment: null,
      erpPaymentAccountCode: '',
      isEdit: false,
      mode: 'CREATE',
      bankAccountType: null,
    }
    // this.webview = React.createRef()
    // this.imgManagerIDRef = React.createRef()
  }

  async componentDidMount() {
    const params = util.getNavParams(this.props)
    const { erp_paymen_account, payment } = params
    await util.setStatePromise(this, {
      isBindAccount: !_.isNil(erp_paymen_account) && erp_paymen_account.erp_payment_account_code,
      erpPaymentAccountCode:
        !_.isNil(erp_paymen_account) && erp_paymen_account.erp_payment_account_code ? erp_paymen_account.erp_payment_account_code : '',
      erpPaymentAccount: erp_paymen_account,
      mode: !_.isNil(erp_paymen_account) && erp_paymen_account.erp_payment_account_code ? 'EDIT' : 'CREATE',
      payment,
      bankAccountType: payment.account_type,
    })
  }

  goBack = () => {
    util.navGoBack(this.props)
  }

  _onPressGoBack = () => {
    const { isEdit } = this.state
    if (!isEdit) {
      util.navGoBack(this.props)
      return
    }
    const ms = 'ยังไม่ได้บันทึกข้อมูล  ต้องการออกจากหน้านี้ใช่หรือไม่'
    p.op.showConfirmation(
      '',
      ms,
      () => null,
      () => this.goBack(),
      'อยู่หน้านี้',
      'ออกจากหน้านี้'
    )
  }

  _setEdit = () => {
    this.setState({ isEdit: true })
  }

  _bankTypeOnChange = async (bank_type: string) => {
    // this._setEdit()
    // @ts-ignore
    this.setState({ bankAccountType: bank_type })

    const { payment } = this.state
    const body = {
      store_id: this.props.selectedStore.get('id'),
      account_type: bank_type,
      payment_account_id: payment.id,
    }

    const apiOptions = {
      showSpinner: true,
    }

    const res: { payment_accounts: IPaymentAccount[] } = await api.patch(api.PUT_BANKACCOUNTS, body, apiOptions)
    // console.log('res => ', res)
    if (res.payment_accounts) {
      const params = util.getNavParams(this.props)
      const { callBackUpdatePaymentList } = params
      if (_.isFunction(callBackUpdatePaymentList)) {
        callBackUpdatePaymentList(res.payment_accounts)
      }
    }
  }

  _warningBankAccountType = () => {
    p.op.showConfirmationOkOnly('', 'กรุณาระบุประเภทบัญชี')
  }

  _updateErpPayment = async () => {
    const { selectedStore } = this.props
    const params = util.getNavParams(this.props)
    const { callBackUpdateErpPayment } = params
    const { erpPaymentAccount, payment, erpPaymentAccountCode, bankAccountType } = this.state
    let hasAccountype = false
    CONS.BANK_HAVE_ACCOUNT_TYPE.forEach((bank_id) => {
      if (payment.bank_id === bank_id) {
        hasAccountype = true
      }
    })
    if (hasAccountype && _.isNil(bankAccountType)) {
      this._warningBankAccountType()
      return
    }
    const store_id = selectedStore.get('id')

    const reqBody = {
      store_id,
      erp_ch_id: erpPaymentAccount.erp_ch_id,
      payment_account_id: payment.id,
      erp_payment_account_code: erpPaymentAccountCode,
    }

    const apiOptions: IApiOptions = {
      showSpinner: true,
    }

    const res = await api.postV2('erp/payment_account/update', reqBody, apiOptions)
    // console.log('res => ', res)
    if (res.erp_payment_accounts) {
      p.op.showToast('อัปเดตการผูกรหัสบัญชีเรียบร้อยแล้ว', 'success')
      if (_.isFunction(callBackUpdateErpPayment)) {
        callBackUpdateErpPayment(res.erp_payment_accounts)
      }
      this.goBack()
    }
  }

  _deleteErpPayment = async () => {
    const { selectedStore } = this.props
    const { erpPaymentAccount, payment } = this.state
    const store_id = selectedStore.get('id')
    const reqBody = {
      store_id,
      erp_ch_id: erpPaymentAccount.erp_ch_id,
      payment_account_id: payment.id,
    }

    const apiOptions: IApiOptions = {
      showSpinner: true,
    }

    const res = await api.postV2('erp/payment_account/delete', reqBody, apiOptions)
    // console.log('res => ', res)
    if (res.erp_payment_accounts) {
      p.op.showToast('ยกเลิกการผูกรหัสบัญชีเรียบร้อยแล้ว', 'success')
      const params = util.getNavParams(this.props)
      const { callBackUpdateErpPayment } = params
      if (_.isFunction(callBackUpdateErpPayment)) {
        callBackUpdateErpPayment(res.erp_payment_accounts)
      }
      this.goBack()
    }
  }

  _createErpPayment = async () => {
    const { selectedStore } = this.props
    const { payment, erpPaymentAccountCode, bankAccountType } = this.state
    if (erpPaymentAccountCode.length < 1) {
      p.op.showConfirmationOkOnly('', 'กรุณาระบุเลขที่ช่องทางการเงิน')
      return
    }
    let hasAccountype = false
    CONS.BANK_HAVE_ACCOUNT_TYPE.forEach((bank_id) => {
      if (payment.bank_id === bank_id) {
        hasAccountype = true
      }
    })
    if (hasAccountype && _.isNil(bankAccountType)) {
      this._warningBankAccountType()
      return
    }
    const params = util.getNavParams(this.props)
    const { erpChannel } = params
    // console.log('erpPaymentAccount => ', erpPaymentAccount)
    const store_id = selectedStore.get('id')
    const reqBody = {
      store_id,
      erp_ch_id: erpChannel.id,
      payment_account_id: payment.id,
      erp_payment_account_code: erpPaymentAccountCode,
    }

    const apiOptions: IApiOptions = {
      showSpinner: true,
    }

    const res = await api.postV2('erp/payment_account/create', reqBody, apiOptions)
    // console.log('res => ', res)
    if (res.erp_payment_accounts) {
      p.op.showToast('ผูกรหัสบัญชีเรียบร้อยแล้ว', 'success')
      const params = util.getNavParams(this.props)
      const { callBackUpdateErpPayment } = params
      if (_.isFunction(callBackUpdateErpPayment)) {
        callBackUpdateErpPayment(res.erp_payment_accounts)
      }
      this.goBack()
    }
  }

  _submitErpPayment = async () => {
    const { mode, isBindAccount } = this.state
    if (mode === 'EDIT' && isBindAccount) {
      await this._updateErpPayment()
    }
    if (mode === 'EDIT' && !isBindAccount) {
      await this._deleteErpPayment()
    }
    if (mode === 'CREATE' && isBindAccount) {
      this._createErpPayment()
    }
    if (mode === 'CREATE' && !isBindAccount) {
      this.goBack()
    }
  }
}
