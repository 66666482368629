import React, { Component, RefObject, createRef } from 'react'
import { RefreshControl, FlatList, ViewStyle, StyleSheet, Platform, Dimensions, StyleProp } from 'react-native'
// import { Spinner } from 'native-base'
import _ from 'lodash'

// import useInfiniteListContext from './InfiniteListStateContext'
import CONS from 'x/config/constants'
import { setStatePromise, delay } from 'x/utils/util'
import { COLORS } from 'x/config/styles'
import { ConsumerContextType } from './InfiniteListStateContext'
import XSpinner from '../XSpinner'

export type InfiniteListDisplayMode = 'grid' | 'list'

interface IFetchedItem<ItemType> {
  //  เช่น fetch ครั้งแรกได้มา 20 ชิ้น แต่ทั้งหมดมี 300 ชิ้น ก็จะได้ items 20 ชิ้น และ count = 300
  count?: number // possible count ของ List นี้ว่าทั้งหมดจะมีเท่าไหร่
  items: ItemType[] // data ที่ fetch ได้
}

// ข้อมูลที่ List เตรียมไว้ให้ก่อนที่ข้างนอกจะไปทำการ fetch
interface IPreparedData {
  offset: number // offset ลำดับของ item โดย 0 คือยังเริ่มจากตัวแรก
  limit: number // default = 20 เปลีั่ยนไปตาม fetchLimit
}

export interface IInfiniteListProps<ListItemType> {
  disabledPullRefresh?: boolean // ใช้ปิดการ pull refresh
  isHidden?: boolean // default is false ใช้เพื่อซ่อน list แต่ยังคงเก็บข้อมูลไว้อยู่
  style?: ViewStyle // ผ่าน style ไปยัง FlatList
  contentContainerStyle?: ViewStyle // ผ่าน style ไปยัง FlatList

  displayMode?: InfiniteListDisplayMode // default เป็น grid
  itemWidth?: number // ใส่เพื่อคำนวณ dynamic columns ที่จะเกิดขึ้น
  fetchLimit?: number // default = 20 ใช้บอกว่าครั้งนึงจะ fetch จำนวนเท่าไหร่

  // itemWidth in renderItem is local compute use when parent wasn't manually set itemWidth
  renderItem: (item: { item: ListItemType; index: number; containerWidth?: number; itemWidth: number }) => JSX.Element
  onFetchItems: (preparedData: IPreparedData) => Promise<IFetchedItem<ListItemType>>
  onChangeTotalCount?: (newCount: number) => void
  onChangeNumOfColumns?: (numColumns: number) => void

  ListEmptyComponent?: React.ComponentType<any> | React.ReactElement | null
  renderListEmptyItem?: (info: { containerWidth?: number }) => JSX.Element
}

interface IInfiniteListWithContextProps<T> extends IInfiniteListProps<T> {
  context: ConsumerContextType
}

export interface IInfiniteListState {
  numOfColumns?: number
  maxWidth?: number
  isReadyToRender?: boolean
  itemWidth: number
}

const viewabilityConfig = {
  itemVisiblePercentThreshold: 50,
}

export class InfiniteListBase<ItemType> extends Component<IInfiniteListWithContextProps<ItemType>, IInfiniteListState> {
  static displayName = 'InfiniteList'

  // static contextType = InfiniteListContextConsumer
  // context!: React.ContextType<typeof InfiniteListContextConsumer>

  isScrolling?: boolean

  isRefreshing?: boolean

  flatListRef: RefObject<FlatList<ItemType>>

  constructor(props) {
    super(props)

    const maxWidth = Platform.OS === 'web' ? Math.floor(CONS.SPECIFIC_PLATFORM.WEB.MAIN_CONTENT_WIDTH / 3) : Dimensions.get('screen').width

    this.state = {
      numOfColumns: 2,
      // numOfColumns: 1,
      isReadyToRender: false,
      maxWidth,
      itemWidth: maxWidth,
    }

    this.isScrolling = false
    this.isRefreshing = false
    this.flatListRef = createRef()
  }

  // Lifecycles
  async componentDidMount() {
    // console.log('InfiniteList => this.props.context => ', this.props.context)
    this.props.context.resetState()
    // await delay(500)
    // await this._fetchItems({ offset: 0 })
    await this._handleRefresh()
    await delay(100)
    await setStatePromise<IInfiniteListState>(this, { isReadyToRender: true })
  }

  // async componentDidUpdate(prevProps: IInfiniteListProps<ItemType>, prevState: IInfiniteListState) {
  //   if (isDiffAccuracy(prevState, this.state, ['numOfColumns']) || isDiffAccuracy(prevProps, this.props, ['displayMode'])) {
  //     try {
  //       this.flatListRef.current.forceUpdate()
  //     } catch (error) {
  //       log(error)
  //     }
  //   }
  // }
  // External Methods

  // reset the list to empty and cleared all state
  reset = async () => {
    await this._handleRefresh()
  }

  // ลบ item ออกจาก list เช่น key: 'id' , value: 322111
  // แสดงว่า product item id=322111 จะถูกลบ และอัพเดท count เอง
  removeItemByKey = async (key: string, value: any) => {
    const { count, removeItemByKey } = this.props.context
    const lastCount = count
    removeItemByKey({ key, value })
    await delay(200)
    if (lastCount !== this.props.context.count) {
      this._updateCountToParent(this.props.context.count)
    }
  }

  // prepend item on list
  prependItem = async (item: ItemType) => {
    const { count, prependItem } = this.props.context
    const lastCount = count
    prependItem(item)
    await delay(50)
    if (lastCount !== this.props.context.count) {
      this._updateCountToParent(this.props.context.count)
    }
  }

  // replace item on list
  repalceItemByKey = async (key: string, value: any, newItem: ItemType) => {
    const { count, repalceItemByKey } = this.props.context
    const lastCount = count
    repalceItemByKey({ key, value, newItem })
    await delay(50)
    if (lastCount !== this.props.context.count) {
      this._updateCountToParent(this.props.context.count)
    }
  }

  getItems = () => this.props.context.items

  // Count ของ items ที่มีอยู่ในปัจจุบัน
  getItemCount = () => this.props.context.items.length

  // Count สูงสุดที่ api ส่งมาให้
  getTotalCount = () => this.props.context.count

  // Internal Methods
  _updateCountToParent = (newCount: number) => {
    const { onChangeTotalCount } = this.props
    if (_.isFunction(onChangeTotalCount)) {
      onChangeTotalCount(newCount)
    }
  }

  _updateNumOfColumnsToParent = (newNumColumns: number) => {
    const { onChangeNumOfColumns } = this.props
    if (_.isFunction(onChangeNumOfColumns)) {
      onChangeNumOfColumns(newNumColumns)
    }
  }

  _fetchItems = async (options: { offset?: number; isLoadMore?: true } = {}) => {
    const { fetchLimit = 20, onFetchItems, onChangeTotalCount } = this.props
    const { items, isLoadingByOffset, setIsLoadingByOffset, setCount, loadItems, setIsRefreshing, count } = this.props.context
    // const FETCH_LIMIT = items.length === 0 ? PRODUCTS_FETCH_LIMIT_INIT : PRODUCTS_FETCH_LIMIT_MORE

    // คำนวณ​ fetchOffset โดยอิงจาก fetchLimit โดยปัดให้ลงตัว
    const computeOffsetByLimit = Math.floor(items.length / fetchLimit) * fetchLimit
    const fetchOffset = _.has(isLoadingByOffset, options.offset) ? options.offset : computeOffsetByLimit

    const firstOffsetItem = items[fetchOffset]
    if (options.isLoadMore && firstOffsetItem && !firstOffsetItem.isEmpty) {
      // ถ้าหากเป็นการ fetch more แล้่วใน item ไม่ใช่ empty item ดังนั้นควร ignore fetch
      return
    }
    if (_.has(isLoadingByOffset, fetchOffset) && isLoadingByOffset[fetchOffset]) {
      // ถ้าหากมีการ fetching ใน offset นี้อยู่ ควร ignore อันที่จะมาใหม่
      return
    }

    setIsLoadingByOffset(true, fetchOffset, fetchLimit)

    try {
      const fetchedData = await onFetchItems({ offset: fetchOffset, limit: fetchLimit })

      if (typeof fetchedData.count === 'number') {
        setCount(fetchedData.count)
        this._updateCountToParent(fetchedData.count)
      } else if (
        !_.isNumber(count) &&
        !_.isNumber(fetchedData.count) &&
        _.isArray(fetchedData.items) &&
        fetchedData.items.length > 0 &&
        fetchedData.items.length < fetchLimit
      ) {
        const newCount = this.getItemCount() + fetchedData.items.length // local เก่า + new items ใหม่
        setCount(newCount)
        this._updateCountToParent(newCount)
      } else if (!_.isNumber(count) && !_.isNumber(fetchedData.count) && _.isArray(fetchedData.items) && fetchedData.items.length === 0) {
        const newCount = this.getItemCount() // ใช้ items count ที่มีใน local
        setCount(newCount)
        this._updateCountToParent(newCount)
      }

      if (fetchedData.items) {
        loadItems(fetchedData.items, fetchOffset)
      } else {
        // เพื่อให้ reducer คำนวณการ cut off product items
        loadItems([], fetchOffset)
      }

      setIsLoadingByOffset(false, fetchOffset)
    } catch (err) {
      setIsLoadingByOffset(false, fetchOffset)
    }
  }

  _handleLoadMore = async () => {
    const { hasLoadMore } = this.props.context
    if (!hasLoadMore) {
      return
    }

    this._fetchItems({ isLoadMore: true })
  }

  _handleRefresh = async () => {
    // console.log('_handleRefresh this.isRefreshing => ', this.isRefreshing)
    if (this.isRefreshing) {
      return
    }
    this.isRefreshing = true
    const { isRefreshing, setIsRefreshing } = this.props.context
    // console.log('_handleRefresh isRefreshing => ', isRefreshing)
    if (isRefreshing || this.props.isHidden) {
      this.isRefreshing = false
      return
    }
    this.props.context.resetState()
    setIsRefreshing(true)
    await delay(100)
    await this._fetchItems({ offset: 0 })
    setIsRefreshing(false)
    await delay(100)
    this.isRefreshing = false
  }

  _onScrollBeginDrag = () => {
    this.isScrolling = true
  }

  _onScrollEndDrag = (evt) => {
    this.isScrolling = false
    // console.log('_onScrollEndDrag evt => ', evt)
    // console.log('_onScrollEndDrag evt.nativeEvent => ', evt.nativeEvent)
    // console.log('_onScrollEndDrag evt.currentTarget() => ', evt.currentTarget())
  }

  _onViewableItemsChanged = ({ viewableItems }) => {
    const { fetchLimit, isHidden } = this.props
    const { isRefreshing } = this.props.context
    if (this.isScrolling || this.isRefreshing || isRefreshing || isHidden) {
      return
    }
    // console.log('_onViewableItemsChanged x => ', x)
    if (viewableItems.length > 0) {
      const focusItem = viewableItems[0]
      const isEmptyItem = focusItem.item.isEmpty || false
      if (isEmptyItem) {
        // const { isLoadingByOffset } = this.props.context
        const loadingItemIndex = focusItem.index
        const focusOffset = Math.floor(loadingItemIndex / fetchLimit) * fetchLimit
        // const isLoadingOffset = isLoadingByOffset[focusOffset] || false
        // fetch empty items
        this._fetchItems({ offset: focusOffset })
      }
    }
  }

  // Render Methods
  _renderInfiniteListItem = (listItem) => {
    const { renderItem, itemWidth: iw } = this.props
    const { maxWidth, itemWidth: iws } = this.state
    return renderItem({ containerWidth: maxWidth, itemWidth: iw || iws, ...listItem })
  }

  _renderInfiniteListEmptyComponent = () => {
    const { ListEmptyComponent = undefined, renderListEmptyItem = undefined } = this.props
    if (_.isFunction(renderListEmptyItem)) {
      return renderListEmptyItem({ containerWidth: this.state.maxWidth })
    }
    return ListEmptyComponent
  }

  _getRefreshControl = () => {
    const { disabledPullRefresh = false } = this.props
    if (disabledPullRefresh) {
      return null
    }
    const { isRefreshing } = this.props.context
    return <RefreshControl refreshing={isRefreshing} onRefresh={this._handleRefresh} />
  }

  _onLayout = async (evt) => {
    const { itemWidth: iw } = this.props
    const { maxWidth } = this.state
    // console.log('FlatList onLayout nativeEvent => ', evt.nativeEvent)
    try {
      const { width } = evt.nativeEvent.layout
      let availableColumns = 1
      if (typeof iw === 'number') {
        availableColumns = Math.floor(width / iw)
      }
      const localItemWidth = Math.floor(maxWidth / availableColumns)
      await setStatePromise(this, { numOfColumns: availableColumns, maxWidth: width, itemWidth: localItemWidth })
      this._updateNumOfColumnsToParent(availableColumns)
    } catch (err) {
      // console.error('_onLayout err => ', err)
    }
  }

  _keyExtractor = (item: ItemType, index: number) => {
    // @ts-ignore
    const uniqueId = item && item.id ? item.id : Math.floor(Math.random() * 10000)
    return `IL_Item_${index.toString()}_${uniqueId}`
  }

  getItemWidth = () => {
    const { displayMode = 'grid', itemWidth: iw } = this.props
    const { numOfColumns, maxWidth, itemWidth: iws } = this.state
    if (displayMode === 'grid' && numOfColumns > 1) {
      return iw || iws
    }

    // displayMode === 'list'
    return maxWidth
  }

  getColumnWrapperStyle = (): StyleProp<ViewStyle> => {
    const { displayMode = 'grid' } = this.props
    const { numOfColumns } = this.state
    if (numOfColumns > 1 && displayMode === 'grid') {
      return {
        alignItems: 'flex-start',
        // width: itemWidth * numOfColumns,
        width: this.getItemWidth() * numOfColumns,
      }
    }

    return undefined
  }

  render() {
    const { isHidden = false, displayMode = 'grid', style = {}, contentContainerStyle = {} } = this.props
    const { numOfColumns = 1, isReadyToRender = false } = this.state
    const {
      items,
      isRefreshing,
      hasLoadMore,
      count,
      // isLoadingByOffset,
      // // addItem,
      // removeItem,
      // setIsLoadingByOffset,
      // setIsRefreshing,
      // loadItems,
      // resetState,
      // // allocateEmptyItems,
      // setCount,
    } = this.props.context

    if (!isReadyToRender) {
      return null
    }

    return (
      <FlatList<ItemType>
        ref={this.flatListRef}
        key={`${displayMode}${numOfColumns}`}
        extraData={[count, isHidden]}
        onLayout={this._onLayout}
        style={[{ display: isHidden ? 'none' : 'flex' }, style]}
        contentContainerStyle={[{ alignItems: 'center' }, contentContainerStyle]}
        // ref={flatListRef}
        keyExtractor={this._keyExtractor}
        data={items}
        refreshControl={this._getRefreshControl()}
        renderItem={this._renderInfiniteListItem}
        onEndReachedThreshold={0.8}
        columnWrapperStyle={this.getColumnWrapperStyle()}
        onEndReached={this._handleLoadMore}
        removeClippedSubviews
        numColumns={displayMode === 'grid' ? numOfColumns : 1}
        disableVirtualization
        initialNumToRender={12}
        maxToRenderPerBatch={18}
        viewabilityConfig={viewabilityConfig}
        onViewableItemsChanged={this._onViewableItemsChanged}
        onScrollEndDrag={this._onScrollEndDrag}
        onScrollBeginDrag={this._onScrollBeginDrag}
        ListFooterComponent={
          (isRefreshing && count === 0) || hasLoadMore ? <XSpinner my='2' size='sm' color={COLORS.TEXT_INACTIVE} /> : null
        }
        // ListEmptyComponent={ListEmptyComponent}
        ListEmptyComponent={this._renderInfiniteListEmptyComponent()}
      />
    )
  }
}

// export function withInfiniteListContext(WrappedComponent) {
//   const context = useInfiniteListContext()
//   return <WrappedComponent context={context} />
// }

export default InfiniteListBase

const styles = StyleSheet.create({
  row: {
    flex: 1,
    justifyContent: 'flex-start',
  },
})

// import React, { Fragment, useState, useEffect, useRef } from 'react'
// import { RefreshControl, FlatList } from 'react-native'
// import { Spinner } from 'native-base'
// import _ from 'lodash'

// import { InfiniteListContextProvider, useInfiniteListContext } from './InfiniteListStateContext'
// import CONS from 'x/config/constants'
// import { useStateWithPromise } from 'x/utils/util'
// import { COLORS } from 'x/config/styles'

// export type InfiniteListDisplayMode = 'grid' | 'list'

// const keyExtractor = (item, index) => index.toString()

// interface IFetchedItem<ItemType> {
//   //  เช่น fetch ครั้งแรกได้มา 20 ชิ้น แต่ทั้งหมดมี 300 ชิ้น ก็จะได้ items 20 ชิ้น และ count = 300
//   count?: number // possible count ของ List นี้ว่าทั้งหมดจะมีเท่าไหร่
//   items: ItemType[] // data ที่ fetch ได้
// }

// // ข้อมูลที่ List เตรียมไว้ให้ก่อนที่ข้างนอกจะไปทำการ fetch
// interface IPreparedData {
//   offset: number // offset ลำดับของ item โดย 0 คือยังเริ่มจากตัวแรก
//   limit: number // default = 20 เปลีั่ยนไปตาม fetchLimit
// }

// export interface IInfiniteListProps<ListItemType> {
//   isHidden?: boolean // default is false ใช้เพื่อซ่อน list แต่ยังคงเก็บข้อมูลไว้อยู่

//   displayMode?: InfiniteListDisplayMode // default เป็น grid
//   itemWidth?: number // ใส่เพื่อคำนวณ dynamic columns ที่จะเกิดขึ้น
//   fetchLimit?: number // default = 20 ใช้บอกว่าครั้งนึงจะ fetch จำนวนเท่าไหร่

//   renderItem: (item: { item: ListItemType; index: number }) => JSX.Element
//   onFetchItems: (preparedData: IPreparedData) => Promise<IFetchedItem<ListItemType>>
// }

// const viewabilityConfig = {
//   itemVisiblePercentThreshold: 50,
// }

// function InfiniteList<T>(props: IInfiniteListProps<T>) {
//   const { isHidden = false, displayMode = 'grid', itemWidth, fetchLimit = 20, renderItem, onFetchItems } = props
//   // Contexts
//   const {
//     items,
//     count,
//     isLoadingByOffset,
//     isRefreshing,
//     hasLoadMore,
//     // addItem,
//     removeItem,
//     setIsLoadingByOffset,
//     setIsRefreshing,
//     loadItems,
//     resetState,
//     // allocateEmptyItems,
//     setCount,
//   } = useInfiniteListContext()

//   // Refs
//   // const flatListRef = useRef<FlatList<T>>(null)

//   // States
//   const [numOfColumns, setNumOfColumns] = useStateWithPromise(1)
//   const [isReadyToRender, setIsReadyToRender] = useStateWithPromise(false)

//   // Lifecycles
//   useEffect(() => {
//     initComponent()
//   }, [])

//   const initComponent = async () => {
//     resetState()
//     await setIsReadyToRender(true)
//     await _fetchItems({ offset: 0 })
//   }

//   const _fetchItems = async (options: { offset?: number } = {}) => {
//     // const FETCH_LIMIT = items.length === 0 ? PRODUCTS_FETCH_LIMIT_INIT : PRODUCTS_FETCH_LIMIT_MORE
//     if (_.has(isLoadingByOffset, options.offset) && isLoadingByOffset[options.offset]) {
//       return
//     }
//     const fetchOffset = _.has(isLoadingByOffset, options.offset) ? options.offset : items.length
//     setIsLoadingByOffset(true, fetchOffset, fetchLimit)

//     try {
//       const fetchedData = await onFetchItems({ offset: fetchOffset, limit: fetchLimit })

//       if (typeof fetchedData.count === 'number') {
//         setCount(fetchedData.count)
//       }
//       if (fetchedData.items) {
//         loadItems(fetchedData.items, fetchOffset)
//       } else {
//         // เพื่อให้ reducer คำนวณการ cut off product items
//         loadItems([], fetchOffset)
//       }
//       setIsLoadingByOffset(false, fetchOffset)
//       setIsRefreshing(false)
//     } catch (err) {
//       setIsLoadingByOffset(false, fetchOffset)
//       setIsRefreshing(false)
//     }
//   }

//   const _handleLoadMore = () => {
//     _fetchItems()
//   }

//   const _handleRefresh = () => {
//     if (isRefreshing) {
//       return
//     }
//     resetState()
//     setIsRefreshing(true)
//     _fetchItems({ offset: 0 })
//   }

//   // const _renderInfiniteListItem = ({ item, index }) => {
//   //   return <InfiniteListItem data={item} index={index} onPress={undefined} displayMode='grid' />
//   // }
//   const _renderInfiniteListItem = (listItem) => renderItem(listItem)

//   // console.log('InfiniteListView items => ', items)
//   // console.log('InfiniteListView count => ', count)
//   // console.log('InfiniteListView queryTxt => ', queryTxt)
//   // console.log('InfiniteListView isLoadingByOffset => ', isLoadingByOffset)
//   // console.log('InfiniteListView hasLoadMore => ', hasLoadMore)
//   const _onLayout = (evt) => {
//     // console.log('FlatList onLayout nativeEvent => ', evt.nativeEvent)
//     try {
//       const { width } = evt.nativeEvent.layout
//       let availableColumn = 1
//       if (typeof itemWidth === 'number') {
//         availableColumn = Math.floor(width / itemWidth)
//       }
//       setNumOfColumns(availableColumn)
//     } catch (err) {
//       // console.error('_onLayout err => ', err)
//     }
//   }

//   if (!isReadyToRender) {
//     return null
//   }

//   return (
//     <FlatList<T>
//       key={`${displayMode}${numOfColumns}`}
//       onLayout={_onLayout}
//       style={{ display: isHidden ? 'none' : 'flex' }}
//       // ref={flatListRef}
//       keyExtractor={keyExtractor}
//       data={items}
//       refreshControl={<RefreshControl refreshing={isRefreshing} onRefresh={_handleRefresh} />}
//       renderItem={_renderInfiniteListItem}
//       onEndReachedThreshold={0.8}
//       columnWrapperStyle={
//         numOfColumns > 1 && displayMode === 'grid'
//           ? {
//               flex: 1,
//               justifyContent: 'space-around',
//             }
//           : undefined
//       }
//       onEndReached={_handleLoadMore}
//       removeClippedSubviews={true}
//       numColumns={displayMode === 'grid' ? numOfColumns : 1}
//       disableVirtualization={true}
//       initialNumToRender={12}
//       maxToRenderPerBatch={18}
//       viewabilityConfig={viewabilityConfig}
//       ListFooterComponent={hasLoadMore ? <Spinner color={COLORS.TEXT_INACTIVE} /> : null}
//     />
//   )
// }

// export default InfiniteList
