import React from 'react'
import BaseUIMarketplaceOrderView from 'xui/modules/order/BaseUIMarketplaceOrderView'

import ThemeSizeContext from '../../context/ThemeSizeContext'

export default class MarketplaceOrderView extends BaseUIMarketplaceOrderView {
  static displayName = 'MarketplaceOrderView'

  static contextType = ThemeSizeContext

  context!: React.ContextType<typeof ThemeSizeContext>

  getMaximumColumnsWidthStyle = () => {
    const { contentOneOfThreeColumnWidthStyle, contentHeightStyle } = this.context
    return [contentOneOfThreeColumnWidthStyle, contentHeightStyle]
  }

  getContainerStyle = this.getMaximumColumnsWidthStyle

  getContentStyle = this.getMaximumColumnsWidthStyle
}
