import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import _ from 'lodash'

import api from 'x/utils/api'
import * as bg from 'x/utils/bg'

import { S, COLORS } from 'x/config/styles'
import {
  IApiOptions,
  IBgJobItemShort,
  IBackgroundJobListViewNavParams,
  IBackgroundTaskListViewNavParams,
  IBackgroundJobListResponse,
  IXSellyErrorResponse,
  IBackgroundJobListApiRequest,
  IXScreenProps,
} from 'x/index'

import * as util from 'x/utils/util'
import * as NavActions from 'x/utils/navigation'

import XText from 'xui/components/XText'
import Box from 'xui/components/Box'
import HStack from 'xui/components/HStack'
import InfiniteList, { InfiniteListBase } from '../../components/InfiniteList'
import XCustomHeader from '../../components/XCustomHeader'
import Segment from '../../components/Segment'
import ForwardIcon from '../../components/ForwardIcon'
import ErrorListItem from '../../components/ErrorListItem'

type IProps = IXScreenProps<IBackgroundJobListViewNavParams>

interface IState {
  selectedJobStatusIndex: number
  inProcessCount?: number
  isFetching: boolean
  isTabAlreadyFetchInited: boolean
  isInitialized: boolean
  fetchErrorMessage?: {
    statusCode: number
    statusMessage: string
  }
}

class BackgroundJobListView extends React.Component<IProps, IState> {
  static displayName = 'BackgroundJobListView'

  jobListRef: React.RefObject<InfiniteListBase<IBgJobItemShort>>

  constructor(props) {
    super(props)

    this.state = {
      selectedJobStatusIndex: 0,
      isFetching: false,
      isTabAlreadyFetchInited: false,
      isInitialized: false,
    }

    this.jobListRef = React.createRef()
  }

  _navToJobTaskList = (job: IBgJobItemShort) => {
    const { selectedJobStatusIndex = 0 } = this.state
    const { navigation } = this.props

    const navParam: IBackgroundTaskListViewNavParams = { storeId: this.getFetchStoreId(), jobUUID: job.uuid, initialTaskStatusIndex: 0 }
    if (selectedJobStatusIndex === 1) {
      navParam.initialTaskStatusIndex = 2 // success
      if (job.ec) {
        navParam.initialTaskStatusIndex = 1
      }
    }
    // navigation.navigate('BackgroundTaskList', navParam)
    navigation.dispatch(NavActions.navToBackgroundTaskListView(navParam))
  }

  // Render Methods
  renderBgJobItem = (bgItem: { item?: IBgJobItemShort; index: number; containerWidth?: number }) => {
    const maxWidth = bgItem.containerWidth || undefined
    // const isEven = bgItem.index % 2 === 0
    const job = bgItem.item

    // return (
    //   <VStack w='full' borderBottomWidth='2'>
    //     <XText>{JSON.stringify(job)}</XText>
    //   </VStack>
    // )

    // @ts-ignore
    const successCount = _.isNumber(parseInt(job.dc)) ? parseInt(job.dc) : 0
    // @ts-ignore
    const errorCount = _.isNumber(parseInt(job.ec)) ? parseInt(job.ec) : 0
    const doneCount = successCount + errorCount
    const totalCount = job.tc || 0

    const infoMessageText = bg.getInfoMessageText(job.in)
    // const infoMessageText = !_.isNil(job.in) ? util.getBgTaskErrorText(job.in.msg_key) : null
    // const donePercent = !doneCount ? 0 : Math.floor((doneCount / totalCount) * 100)
    const errorMessageText = _.isString(job.et) ? job.et : null

    const createAtText = job.sa
    const processAtText = _.isString(job.qa) ? job.qa : '(กำลังรอคิว)'

    const jobType = job.t
    const jobTypeText = util.getBgJobTypeText(jobType)

    const isProgressBarHide = (doneCount === 0 && totalCount === 0) || !totalCount || _.isString(errorMessageText)

    return (
      <TouchableOpacity key={`${bgItem.index}`} onPress={() => this._navToJobTaskList(job)}>
        <View
          style={[
            S.WIDTH_FULL,
            S.CARDLIKE_BORDERS,
            // S.CARDLIKE_MARGIN,
            S.CARDLIKE_BODY,
            S.BG_WHITE,
            S.ROW_MIDDLE_BETWEEN,
            S.PADDING_HORIZONTAL_12,
            { width: maxWidth },
          ]}>
          <View style={[S.COLUMN_LEFT_MIDDLE, { width: maxWidth - 74 }]}>
            <View style={[S.WIDTH_FULL, S.ROW_MIDDLE_START]}>
              {/* <XText style={S.TEXT_INACTIVE}>({job.name})</XText> */}
              <XText variant='active' bold>
                {jobTypeText}
              </XText>
            </View>

            <View style={[S.WIDTH_FULL, S.ROW_MIDDLE_START, { marginBottom: isProgressBarHide ? 4 : 0 }]}>
              {/* <XText style={S.TEXT_ACTIVE}>{'สร้างสินค้า Hardcode'}</XText> */}
              <XText style={[S.TEXT_INACTIVE, S.TEXT_SMALL]}>{job.n}</XText>
            </View>

            {isProgressBarHide ? null : (
              <View style={[S.WIDTH_FULL, S.ROW_MIDDLE_BETWEEN, { marginTop: 4 }]}>
                <XText style={S.TEXT_INACTIVE}>ดำเนินการแล้ว</XText>
                {/* <XText style={S.TEXT_ACTIVE}>{`${donePercent}%`}</XText> */}
                <XText style={S.TEXT_ACTIVE}>{`${doneCount}/${totalCount}`}</XText>
              </View>
            )}
            {isProgressBarHide ? null : (
              <View style={[S.WIDTH_FULL, S.ROW_CENTER]}>
                <LoadingBar now={doneCount} max={totalCount} height={12} />
              </View>
            )}

            <View style={[S.WIDTH_FULL, S.ROW_MIDDLE_BETWEEN]}>
              <XText style={[S.TEXT_INACTIVE]}>เข้าคิวเมื่อ:</XText>
              <XText style={[S.TEXT_ACTIVE]}>{createAtText}</XText>
            </View>

            <View style={[S.WIDTH_FULL, S.ROW_MIDDLE_BETWEEN, { marginTop: 4 }]}>
              <XText style={[S.TEXT_INACTIVE]}>เริ่มดำเนินการ:</XText>
              <XText style={[S.TEXT_ACTIVE]}>{processAtText}</XText>
            </View>

            {!_.isNil(infoMessageText) ? (
              <View style={[S.WIDTH_FULL, S.ROW_MIDDLE_BETWEEN, { marginTop: 4 }]}>
                <XText style={[S.TEXT_INACTIVE]}>หมายเหตุ:</XText>
                <XText style={[S.TEXT_ACTIVE]}>{infoMessageText}</XText>
              </View>
            ) : null}

            {errorCount ? (
              <View style={[S.WIDTH_FULL, S.ROW_MIDDLE_BETWEEN, { marginTop: 4 }]}>
                <XText style={[S.TEXT_INACTIVE]}>มีคำสั่งงานที่เกิดข้อผิดพลาด:</XText>
                <XText style={[S.TEXT_DANGER]}>{errorCount}</XText>
              </View>
            ) : null}

            {_.isString(errorMessageText) ? (
              <View style={[S.WIDTH_FULL, S.ROW_MIDDLE_BETWEEN, { marginTop: 4 }]}>
                <XText style={[S.TEXT_INACTIVE]}>ข้อผิดพลาด:</XText>
                <XText style={[S.TEXT_DANGER]}>{errorMessageText}</XText>
              </View>
            ) : null}

            {/* <View style={S.CARDLIKE_BODY}>
            <XText style={S.TEXT_INACTIVE}>Debug: {JSON.stringify(bgItem)}</XText>
          </View> */}
          </View>
          <View style={[S.ROW_CENTER, S.WIDTH_44]}>
            <ForwardIcon />
          </View>
        </View>
      </TouchableOpacity>
    )
  }

  getFetchStoreId = () => {
    const { navigation } = this.props
    const storeId = util.getNavParam(this.props, 'storeId')
    return storeId
  }

  getInitJobUUID = () => {
    const { navigation } = this.props
    const initWithJobUUID = util.getNavParam(this.props, 'initWithJobUUID', null)
    return initWithJobUUID
  }

  fetchBgJobs = async ({ offset, limit }) => {
    await util.setStatePromise(this, { isFetching: true })
    const { selectedJobStatusIndex = 0, inProcessCount, isInitialized = false } = this.state
    // let requestBody = this.getCommonRequestBody({ offset, limit })
    // requestBody = this.getModifiedRequestBody(requestBody)
    // const fetchedData = mockResponse
    const isFirstFetch = offset === 0

    const requestBody: IBackgroundJobListApiRequest = {
      store_id: this.getFetchStoreId(),
      // ref_id: 3333,
      // ref_uuid: 'c7a9f222-7731-4502-87cd-6a25e5c6ced7',
      // ref_string: 'test',
      offset,
      limit,
      return_count: isFirstFetch,
      created_within_mins: 10080,
      status: 'all',
      // "types": ["c.bgjob.abc"]
    }

    switch (selectedJobStatusIndex) {
      case 0:
        requestBody.status = 'inprocess'
        break
      case 1:
        requestBody.status = 'done'
        break
    }

    const apiOptions: IApiOptions = {
      axiosOptions: {
        retry: 0,
        timeout: 60000,
      },
      isErrorAlertDisabled: true,
      isApiV2: true,
    }
    const fetchedData = {
      count: undefined,
      items: [],
    }
    try {
      const res = await api.post<IBackgroundJobListApiRequest, IBackgroundJobListResponse>(
        api.POST_BACKGROUND_JOB_LIST,
        requestBody,
        apiOptions
      )
      if (typeof res.count === 'number') {
        const jobCount = res.count
        fetchedData.count = jobCount
        if (selectedJobStatusIndex === 0 && jobCount !== inProcessCount) {
          await util.setStatePromise(this, { inProcessCount: jobCount })
        }
      }

      if (res.jobs) {
        if (!isInitialized && this.getInitJobUUID() && selectedJobStatusIndex === 0) {
          const initJobUUID = this.getInitJobUUID()
          const foundInitJobIndex = res.jobs.findIndex((j) => j.uuid === initJobUUID)
          if (foundInitJobIndex === -1) {
            await util.delay(100)
            await util.setStatePromise(this, { selectedJobStatusIndex: 1 })
            await util.delay(100)
            return { items: [], count: 0 }
          }
        }

        fetchedData.items = res.jobs
      }
    } catch (e) {
      // console.log('fetchBgJobs err => ', err)
      if (requestBody.offset === 0) {
        const err = e as IXSellyErrorResponse
        await util.setStatePromise(this, { fetchErrorMessage: util.getErrorMessageFromErrorResponse(err) })
      }
    }

    await util.setStatePromise(this, { isFetching: false, isTabAlreadyFetchInited: true, isInitialized: true })

    return {
      items: fetchedData.items,
      count: fetchedData.count,
    }
  }

  _goBack = () => {
    util.navGoBack(this.props)
  }

  _onJobStatusSegmentChange = (newIndex: number) => {
    if (newIndex !== this.state.selectedJobStatusIndex) {
      this.setState({ selectedJobStatusIndex: newIndex, isTabAlreadyFetchInited: false, fetchErrorMessage: null })
    }
  }

  _renderEmptyListItem = ({ containerWidth = undefined }) => {
    const { isTabAlreadyFetchInited, isFetching, fetchErrorMessage } = this.state
    if (!isTabAlreadyFetchInited || isFetching) {
      return null
    }

    if (fetchErrorMessage) {
      return this.renderFetchErrorMessage(containerWidth)
    }

    return (
      <View style={[S.ROW_CENTER, S.PADDING_VERTICAL_12, S.MARGIN_VERTICAL_12, { width: containerWidth }]}>
        <XText style={S.TEXT_INACTIVE}>ยังไม่มีคำสั่งงาน</XText>
      </View>
    )
  }

  // แสดง error message จากการ fetch
  renderFetchErrorMessage = (containerWidth) => {
    const handleshowAlertFetchError = () => util.showAlertFetchError(this.state.fetchErrorMessage)
    return (
      <ErrorListItem
        containerStyle={{ width: containerWidth }}
        onPressShowAlertInfo={handleshowAlertFetchError}
        onPressDoRefresh={this.doRefresh}
      />
    )
  }

  doRefresh = async () => {
    await util.setStatePromise(this, { fetchErrorMessage: null, inProcessCount: null })
    await util.delay(200)
    await this.jobListRef.current.reset()
  }

  getIsGoBackButtonHidden = () => {
    const { navigation } = this.props
    return util.getNavParam(this.props, 'isGoBackButtonHidden', false)
  }

  renderCustomHeader = () => (
    <XCustomHeader
      headerStyle={{ borderBottomWidth: 0 }}
      title='รายการคิวคำสั่งงาน'
      headerLeftProps={this.getIsGoBackButtonHidden() ? undefined : { backIcon: true, onPressItem: this._goBack }}
    />
  )

  render() {
    const { selectedJobStatusIndex = -1, inProcessCount, isFetching } = this.state
    return (
      <View style={[S.HEIGHT_FULL, S.WIDTH_FULL, S.BG_WHITE]}>
        {this.renderCustomHeader()}
        <View style={[S.WIDTH_FULL, S.BG_WHITE, S.PADDING_8, { borderBottomWidth: 0.5, borderBottomColor: COLORS.TEXT_INACTIVE }]}>
          {/* <XText style={S.TEXT_INACTIVE}>หมวดหมู่</XText>
          <View style={[S.ROW_CENTER, S.MARGIN_VERTICAL_4]}>
            <Segment selectedIndex={3} options={['ออเดอร์', 'สินค้า', 'ช่องทางขาย', 'ทั้งหมด']} />
          </View> */}
          <XText style={S.TEXT_INACTIVE}>สถานะ</XText>
          <View style={[S.ROW_CENTER, S.MARGIN_VERTICAL_4]}>
            <Segment
              disabled={isFetching}
              selectedIndex={selectedJobStatusIndex}
              options={[`กำลังดำเนินการ${inProcessCount ? ` (${inProcessCount})` : ''}`, 'เสร็จสิ้น']}
              onSegmentChange={this._onJobStatusSegmentChange}
            />
          </View>
        </View>
        <InfiniteList<IBgJobItemShort>
          key={`${selectedJobStatusIndex}`}
          ref={this.jobListRef}
          displayMode='list'
          style={S.FLEX}
          renderItem={this.renderBgJobItem}
          onFetchItems={this.fetchBgJobs}
          fetchLimit={12}
          renderListEmptyItem={this._renderEmptyListItem}
        />
      </View>
    )
  }
}

const LoadingBar = (props) => {
  const { now = 0, max = 100, height = 24 } = props
  const progress: number = max > 0 ? (now / max) * 100 : 0
  const undone: number = 100 - progress

  return (
    <HStack w='full' borderRadius='lg' overflow='hidden'>
      <Box w={`${progress}%`} bg='success.500' h={`${height}px`} />
      <Box w={`${undone}%`} bg='muted.400' h={`${height}px`} />
    </HStack>
  )

  // return (
  //   <View
  //     style={[S.ROW_CENTER, S.BORDER, { width: '100%', borderColor: 'white', borderRadius: Math.floor(height / 2), overflow: 'hidden' }]}>
  //     <View
  //       style={{
  //         height,
  //         flex: Math.floor(progress / 100),
  //         backgroundColor: COLORS.BRAND_Success,
  //       }}
  //     />
  //     <View
  //       style={{
  //         height,
  //         flex: Math.floor(undone / 100),
  //         backgroundColor: COLORS.GREY,
  //       }}
  //     />
  //     {/* <View style={{ height, flex: undone / 100, backgroundColor: COLORS.TEXT_INACTIVE }} /> */}
  //     {/* <View style={{ height, backgroundColor: COLORS.BRAND_Danger }} /> */}
  //     {/* <View style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, justifyContent: 'center', alignItems: 'center' }}>
  //       <XText
  //         style={[
  //           S.TEXT_ACTIVE_DARK,
  //           { fontWeight: 'bold', textShadowColor: COLORS.TEXT_INACTIVE, textShadowOffset: { width: 2, height: 2 }, textShadowRadius: 2 },
  //         ]}>
  //         {`${Math.floor(progress)}%`}
  //       </XText>
  //     </View> */}
  //   </View>
  // )
}

export default BackgroundJobListView
