import React from 'react'
import { IXTextEditor } from 'x/index'
import VStack from 'xui/components/VStack'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

// const _editor = React.createRef()
const TextEditor: React.FC<IXTextEditor> = (props) => {
  // console.log('props => ', props)
  const onChangeHtmlText = (newText: string) => {
    if (props.readOnly) {
      return
    }
    props.onChange(newText)
  }
  const toolbar = props.readOnly
    ? null
    : [
        // [{ font: [] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ color: [] }], // Add the color button to the toolbar
        [{ header: '1' }, { header: '2' }],
        // ['link', 'image'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ align: [] }],
        // [{ indent: '-1' }, { indent: '+1' }],
        // ['blockquote', 'code-block'],
      ]
  return (
    <VStack key={props.id}>
      <ReactQuill
        theme={props.readOnly ? 'snow' : 'snow'}
        // theme='bubble'
        value={props.htmlValue}
        onChange={onChangeHtmlText}
        readOnly={props.readOnly}
        style={{ minHeight: props.readOnly ? 150 : 250 }}
        modules={{
          toolbar,
        }}
        formats={[
          'header',
          'align',
          'font',
          // 'size',
          'color',
          'bold',
          'italic',
          'underline',
          'strike',
          // 'blockquote',
          'list',
          'bullet',
          'link',
          // 'image',
        ]}
      />
    </VStack>
  )
}

export default TextEditor
