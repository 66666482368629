import { Component } from 'react'
// import xCONS from '../../config/constants'
import dayjs, { Dayjs } from 'dayjs'
import _ from 'lodash'
import CONS from 'x/config/constants'
import * as util from 'x/utils/util'
import { IXScreenProps } from 'x/types'
import p from '../../config/platform-specific'
import { log, getShippingLabelPDFUrl, getShippingLabelPDFFileNameByDates, setStatePromise } from '../../utils/util'

const DEFAULT_LAOUT_LOADING = 0

interface PrintShippingLabelViewProps extends IXScreenProps<{ store_id: number }> {
  history?: any
  mc?: any
  selectedStore?: any
}

interface PrintShippingLabelViewState {
  startDate: Dayjs
  endDate: Dayjs
  selectedOptionPrintFilter: string
  selectedOptionOrderCount: number
  // selectedOptionTimePeriod: string
  selectedOptionTimePeriodModeAtIndex: number
  downloading: boolean

  loadingWidth: number
  loadingHeight: number

  visibleShippingTypes: number[]
  selectedShippingTypeIds: number[]

  isVisibleShippingTypeSelectorOverlayView: boolean
}

export default abstract class BasePrintShippingLabelView extends Component<PrintShippingLabelViewProps, PrintShippingLabelViewState> {
  START_DATE: string

  END_DATE: string

  REQ_DATE_FORMAT: string

  CURRENT_DATE: Dayjs

  OPTIONS_PRINT_FILTER: string[]

  OPTIONS_ORDER_COUNT: string[]

  OPTIONS_TIME_PERIOD_MODE: string[]

  // OPTIONS_TIME_PERIOD: string[]
  OPTIONS_OVERLAY_SELECTED_SHIPPING_TYPE: string[]

  inProcess: boolean

  abstract _getParams(): { store_id: number } | null

  abstract onOpenPDFFromUrl(url: string, fileName: string): Promise<void>

  abstract onOpenPaperangFromUrl(url: string)

  protected constructor(props: PrintShippingLabelViewProps) {
    super(props)

    this.START_DATE = 'START_DATE'
    this.END_DATE = 'END_DATE'
    this.REQ_DATE_FORMAT = 'YYYY-MM-DD%20HH:mm:ss'
    this.CURRENT_DATE = dayjs()

    this.OPTIONS_PRINT_FILTER = ['เฉพาะที่ไม่เคยถูกพิมพ์', 'ทั้งหมด']

    this.OPTIONS_ORDER_COUNT = ['10', '20', '30']
    this.OPTIONS_TIME_PERIOD_MODE = ['ไม่ระบุ', 'ถูกสร้าง', 'พร้อมส่ง', 'กำหนดส่ง']
    // this.OPTIONS_TIME_PERIOD = ['ไม่ระบุ', 'ระบุ']

    this.OPTIONS_OVERLAY_SELECTED_SHIPPING_TYPE = ['ทั้งหมด', 'ระบุ']

    this.inProcess = false

    this.state = {
      startDate: dayjs().startOf('day'),
      endDate: dayjs().endOf('day'),
      selectedOptionPrintFilter: this.OPTIONS_PRINT_FILTER[0],
      selectedOptionOrderCount: 2,
      selectedOptionTimePeriodModeAtIndex: 0,
      // selectedOptionTimePeriod: this.OPTIONS_TIME_PERIOD_MODE[0],
      // selectedOptionTimePeriod: this.OPTIONS_TIME_PERIOD_MODE[1],
      downloading: false,
      loadingHeight: DEFAULT_LAOUT_LOADING,
      loadingWidth: DEFAULT_LAOUT_LOADING,
      selectedShippingTypeIds: [],
      visibleShippingTypes: [],
      isVisibleShippingTypeSelectorOverlayView: false,
    }

    this._onDateChange = this._onDateChange.bind(this)
    // this._setTimePeriod = this._setTimePeriod.bind(this)
    // this._openPDF = this._openPDF.bind(this)
    this._getParams = this._getParams.bind(this)
    this._isTimePeriodUsed = this._isTimePeriodUsed.bind(this)
    this._setSelectedOptionPrintFilter = this._setSelectedOptionPrintFilter.bind(this)
    // this._setSelectedOptionOrderCount = this._setSelectedOptionOrderCount.bind(this)
    // this._setSelectedOptionTimePeriod = this._setSelectedOptionTimePeriod.bind(this)
    this.onSegmentOptionTimePeriodChangeAtIndex = this.onSegmentOptionTimePeriodChangeAtIndex.bind(this)
  }

  // _setTimePeriod({ startH, startM, endH, endM }) {
  //   let startDate = dayjs({ hour: startH, minute: startM })
  //   let endDate = dayjs({ hour: endH, minute: endM })
  //   if (endH === '00' && endM === '00') {
  //     endDate.day(startDate.day() + 1)
  //     // endDate.day(3) // add 1 day
  //   }
  //   this.setState(
  //     {
  //       startDate: startDate,
  //       endDate: endDate,
  //     },
  //   )
  // }

  // _onDateChange(params: { key: string, value: any, mode: string }): void {
  //   const { key, value, mode } = params
  //   // console.log('key: ' + key + ' value: ' + value)
  //   var newState
  //   let newDate = dayjs(value)
  //   // prevent startDate > endDate
  //   if (mode === this.START_DATE) {
  //     newState = {
  //       startDate: newDate,
  //       endDate: newDate.diff(this.state.endDate) > 0 ? newDate : this.state.endDate,
  //     }
  //   } else {
  //     newState = {
  //       startDate: newDate.diff(this.state.startDate) < 0 ? newDate : this.state.startDate,
  //       endDate: newDate,
  //     }
  //   }
  //   this.setState(newState)
  // }

  // _onDateChange(params: { key: string, value: Dayjs, mode: string }): void {
  //   const { key, value, mode } = params
  //   console.log('_onDateChange params: ', params)
  //   // log('_onDateChange key: ' + key + ' value: ' + value)
  //   // log('_onDateChange key: ' + key + ' value: ' + value)
  //   let newState: { startDate: Dayjs, endDate: Dayjs }
  //   let newDate = dayjs(value)
  //   // prevent startDate > endDate
  //   if (mode === this.START_DATE) {
  //     newState = {
  //       startDate: newDate.startOf('day'),
  //       endDate: newDate.diff(this.state.endDate) > 0 ? newDate.endOf('day') : this.state.endDate,
  //     }
  //   } else {
  //     newState = {
  //       startDate: newDate.diff(this.state.startDate) < 0 ? newDate.startOf('day') : this.state.startDate,
  //       endDate: newDate.endOf('day'),
  //     }
  //   }
  //
  //   // if (newState.endDate.clone().startOf('day').diff(this.CURRENT_DATE, 'd') === 0) {
  //   //   newState.endDate = dayjs()
  //   // }
  //
  //   this.setState(newState)
  // }

  componentDidMount() {
    let visibleShippingTypes = util.getVisibleShippingTypesIds()
    if (visibleShippingTypes.length < 1) {
      const defaultVisibleShippingTypes = []
      CONS.ORDER_VIEW_SHIPPING_TYPE_ITEMS.map((shipping) => {
        defaultVisibleShippingTypes.push(shipping.value)
      })
      visibleShippingTypes = defaultVisibleShippingTypes
    }
    util.setStatePromise(this, {
      visibleShippingTypes,
    })
    // console.log('visibleShippingTypes !! => ', visibleShippingTypes)
    /// /
  }

  _onDateChange({ begin, end }) {
    // log('_onDateChange fromDate: ', begin)
    // log('_onDateChange toDate: ', end)
    const newState = { startDate: begin, endDate: end }
    this.setState(newState)
  }

  async _openPDF(type: string): Promise<void> {
    const {
      downloading,
      startDate,
      endDate,
      selectedOptionPrintFilter,
      selectedOptionOrderCount,
      selectedShippingTypeIds,
      selectedOptionTimePeriodModeAtIndex = 0,
    } = this.state

    // const selectedStore = this.props.selectedStore.toJS()
    // const ppr = _.isNil(paperang) ? false : paperang
    const ppr = type === 'paperang'
    const isSticker = type === 'PDFSticker'
    const isA4 = type === 'PDFA4'
    if (this.inProcess) {
      return
    }
    this.inProcess = true

    if (downloading) {
      return
    }

    const canHelperPrintShippingLabel = util.canHelperPrintShippingLabel()
    if (!canHelperPrintShippingLabel) {
      util.warningHelperDontHavePermission()
      this.inProcess = false
      return
    }

    try {
      // BEGIN
      await setStatePromise(this, { downloading: true })

      // delay for dev
      // await new Promise(resolve => {
      //   setTimeout(() => resolve(null), 15000)
      // })

      const { store_id } = this._getParams()
      const only_not_printed = selectedOptionPrintFilter === this.OPTIONS_PRINT_FILTER[0] ? 1 : 0
      const limit = selectedOptionOrderCount === 0 ? '10' : selectedOptionOrderCount === 1 ? '20' : '30'
      const params: { [key: string]: any } = { store_id, only_not_printed, limit }
      // log('_openPDF this._isTimePeriodUsed => ', this._isTimePeriodUsed())
      // log('_openPDF startDate => ', startDate)
      // log('_openPDF endDate => ', endDate)

      let date_range_filter_mode
      if (this._isTimePeriodUsed()) {
        switch (selectedOptionTimePeriodModeAtIndex) {
          // // ไม่ระบุ
          // case 0:
          //   break;
          // // 'ถูกสร้าง'
          case 1:
            date_range_filter_mode = 'created_at'
            break
          // // 'พร้อมส่ง'
          case 2:
            date_range_filter_mode = 'ready_to_ship_date'
            break
          // // 'กำหนดส่ง'
          case 3:
            date_range_filter_mode = 'date_delivery'
            break
        }
        // const PDF_URL_REQ_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss'
        // params.startDate = startDate.format(PDF_URL_REQ_DATE_FORMAT)
        // params.endDate = endDate.format(PDF_URL_REQ_DATE_FORMAT)
        params.startDate = startDate
        params.endDate = endDate
      } else {
        params.startDate = null
        params.endDate = endDate
      }

      // // root4Go is apiHostUrl4Go
      // params.root4Go = ''
      // if (!_.isNil(selectedStore)) {
      //   if (!_.isNil(selectedStore.apiMappings4Go)) {
      //     const print_tpl_ids = selectedStore.apiMappings4Go.print_tpl_ids
      //     if (_.includes(print_tpl_ids, selectedStore.print_template_id)) {
      //       params.root4Go = selectedStore.apiHostUrl4Go
      //     }
      //   }
      // }
      params.isGoApi = true
      params.paperang = ppr
      params.sticker = isSticker
      // console.log('selectedShippingTypeIds => ', selectedShippingTypeIds)
      const visibleShippingTypes = []
      CONS.ORDER_VIEW_SHIPPING_TYPE_ITEMS.map((shipping) => {
        visibleShippingTypes.push(shipping.value)
      })
      if (!_.isNil(selectedShippingTypeIds) && selectedShippingTypeIds.length > 0) {
        params.activeShippingTypeIds = selectedShippingTypeIds
      }
      // log('_openPDF params => ', params)
      let url = getShippingLabelPDFUrl(params)

      const serialzedQueryParamsString = util.serializeObjectToQueryParamsString({ date_range_filter_mode })
      if (serialzedQueryParamsString) {
        url = `${url}&${serialzedQueryParamsString}`
      }
      // console.log('_openPDF url => ', url)

      const fileName = getShippingLabelPDFFileNameByDates(params.startDate, params.endDate)
      // log('_openPDF fileName => ', fileName)
      // await utilN.openPDFFromUrl(url, fileName)
      // log('_openPDF url => ', url)
      // log('_openPDF fileName => ', fileName)
      // console.log(url)
      if (ppr) {
        await this.onOpenPaperangFromUrl(url)
        p.op.aLogEvent(CONS.EVENT_NAME.SHIPPING_LABLE_MULTI_PPR, { s: store_id })
      } else if (isA4) {
        await this.onOpenPDFFromUrl(url, fileName)
        p.op.aLogEvent(CONS.EVENT_NAME.SHIPPING_LABLE_MULTI_A4, { s: store_id })
      } else {
        await this.onOpenPDFFromUrl(url, fileName)
        p.op.aLogEvent(CONS.EVENT_NAME.SHIPPING_LABLE_MULTI_PDF_STICKER, { s: store_id })
      }

      // END
    } catch (e) {
      log('BasePrinShippingLabelView _openPDF e => ', e)
      p.op.alert('เกิดข้อผิดพลาด', 'กรุณาลองใหม่อีกครั้งภายหลัง')
    }

    await setStatePromise(this, { downloading: false })
    this.inProcess = false
  }

  _isTimePeriodUsed(): boolean {
    // return this.state.selectedOptionTimePeriod !== this.OPTIONS_TIME_PERIOD_MODE[0]
    return this.state.selectedOptionTimePeriodModeAtIndex > 0
  }

  _setSelectedOptionPrintFilter(selectedOptionPrintFilter: string): void {
    this.setState({ selectedOptionPrintFilter })
  }

  // _setSelectedOptionOrderCount(selectedOptionOrderCount: string): void {
  //   this.setState({ selectedOptionOrderCount })
  // }

  _setSelectedOptionOrderCountByIndex = (index: number) => {
    this.setState({ selectedOptionOrderCount: index })
  }

  // _setSelectedOptionTimePeriod(selectedOptionTimePeriod: string): void {
  //   this.setState({ selectedOptionTimePeriod })
  // }
  onSegmentOptionTimePeriodChangeAtIndex(selectedOptionTimePeriodModeAtIndex: number): void {
    this.setState({ selectedOptionTimePeriodModeAtIndex })
  }
}
