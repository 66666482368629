import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import _ from 'lodash'

import api from 'x/utils/api'
import { S, COLORS } from 'x/config/styles'
import { IApiOptions, IBgJobItemShort, IBackgroundJobInProcessByTypeViewNavParams, IXScreenProps } from 'x/index'

import * as util from 'x/utils/util'
import * as NavActions from 'x/utils/navigation'

import XText from 'xui/components/XText'
import InfiniteList, { InfiniteListBase } from '../../components/InfiniteList'
import XCustomHeader from '../../components/XCustomHeader'
import ForwardIcon from '../../components/ForwardIcon'
import ErrorListItem from '../../components/ErrorListItem'

type IProps = IXScreenProps<IBackgroundJobInProcessByTypeViewNavParams>

interface IState {
  selectedJobStatusIndex: number
  inProcessCount?: number
  isFetching: boolean
  isAlreadyFetchInited: boolean
  fetchErrorMessage?: {
    statusCode: number
    statusMessage: string
  }
}

// const mockResponse: IBackgroundJobListResponse = {
//   jobs: [
//     {
//       uuid: 'asdfsd-werw-werw-2324wdfertss',
//       n: 'Create_Payment_20200930141458',
//       t: 'payment.create',
//       sa: '2020-09-30-11:50',
//       qa: '2020-09-30-11:50',
//       ca: null,
//       tc: 22,
//       dc: 12,
//       ec: 0,
//       si: 7,
//     },
//     {
//       uuid: 'asdfsd-werw-werw-2324wdfertss',
//       n: 'Create_Payment_2020209343',
//       t: 'payment.create',
//       sa: '2020-09-30-12:50',
//       qa: '2020-09-30-12:50',
//       ca: null,
//       tc: 55,
//       dc: 2,
//       ec: 0,
//       si: 7,
//     },
//     {
//       uuid: 'asdfsd-werw-werw-2324wdfertss',
//       n: 'Create_Payment_20200930141458',
//       t: 'payment.create',
//       sa: '2020-09-30-13:50',
//       qa: null,
//       ca: null,
//       tc: 7,
//       dc: 0,
//       ec: 0,
//       si: 7,
//     },
//   ],
//   count: 3,
// }

class BackgroundJobInProcessByTypeView extends React.Component<IProps, IState> {
  static displayName = 'BackgroundJobInProcessByTypeView'

  jobListRef: React.RefObject<InfiniteListBase<IBgJobItemShort>>

  constructor(props) {
    super(props)

    this.state = {
      selectedJobStatusIndex: 0,
      isFetching: false,
      isAlreadyFetchInited: false,
    }

    this.jobListRef = React.createRef()
  }

  _navToJobTaskList = (job: IBgJobItemShort) => {
    const { navigation } = this.props
    navigation.dispatch(
      NavActions.navToBackgroundTaskListView({ storeId: this.getFetchStoreId(), jobUUID: job.uuid, initialTaskStatusIndex: 0 })
    )
  }

  // Render Methods
  renderBgJobItem = (bgItem: { item?: IBgJobItemShort; index: number; containerWidth?: number }) => {
    const maxWidth = _.isNumber(bgItem.containerWidth) ? bgItem.containerWidth : undefined
    const isEven = bgItem.index % 2 === 0
    const job = bgItem.item
    const successCount = job.dc || 0
    const errorCount = job.ec || 0
    const doneCount = successCount + errorCount
    const totalCount = job.tc

    // const donePercent = !doneCount ? 0 : Math.floor((doneCount / totalCount) * 100)

    const createAtText = job.sa
    const processAtText = _.isString(job.qa) ? job.qa : '(กำลังรอคิว)'

    const jobType = job.t
    const jobTypeText = util.getBgJobTypeText(jobType)

    const btnStyle = _.isNumber(maxWidth) ? { width: maxWidth } : {}
    return (
      <TouchableOpacity style={btnStyle} onPress={() => this._navToJobTaskList(job)}>
        <View style={[S.CARDLIKE_BORDERS, S.CARDLIKE_MARGIN, S.CARDLIKE_BODY, S.BG_WHITE, S.ROW_MIDDLE_BETWEEN, S.PADDING_HORIZONTAL_12]}>
          <View style={[S.COLUMN_LEFT_MIDDLE, { width: 300 }]}>
            <View style={[S.WIDTH_FULL, S.ROW_MIDDLE_START]}>
              {/* <XText style={S.TEXT_ACTIVE}>{'สร้างสินค้า Hardcode'}</XText> */}
              <XText style={S.TEXT_ACTIVE}>{job.n}</XText>
            </View>
            <View style={[S.WIDTH_FULL, S.ROW_MIDDLE_START]}>
              {/* <XText style={S.TEXT_INACTIVE}>({job.name})</XText> */}
              <XText style={S.TEXT_INACTIVE}>{jobTypeText}</XText>
            </View>
            {/* <View style={[S.WIDTH_FULL, S.ROW_MIDDLE_BETWEEN, { marginTop: 4 }]}>
              <XText style={S.TEXT_INACTIVE}>{`ดำเนินการแล้ว`}</XText>
              <XText style={S.TEXT_ACTIVE}>{`${doneCount}/${totalCount}`}</XText>
            </View> */}
            {/* <View style={[S.WIDTH_FULL, S.ROW_CENTER]}>
              <LoadingBar now={doneCount} max={totalCount} height={12} />
            </View> */}
            <View style={[S.WIDTH_FULL, S.ROW_MIDDLE_BETWEEN]}>
              <XText style={[S.TEXT_INACTIVE]}>เข้าคิวเมื่อ:</XText>
              <XText style={[S.TEXT_ACTIVE]}>{createAtText}</XText>
            </View>
            <View style={[S.WIDTH_FULL, S.ROW_MIDDLE_BETWEEN, { marginTop: 4 }]}>
              <XText style={[S.TEXT_INACTIVE]}>เริ่มดำเนินการ:</XText>
              <XText style={[S.TEXT_ACTIVE]}>{processAtText}</XText>
            </View>

            {/* {errorCount ? (
              <View style={[S.WIDTH_FULL, S.ROW_MIDDLE_BETWEEN, { marginTop: 4 }]}>
                <XText style={[S.TEXT_INACTIVE]}>{'มีคำสั่งงานที่เกิดข้อผิดพลาด:'}</XText>
                <XText style={[S.TEXT_DANGER]}>{errorCount}</XText>
              </View>
            ) : null} */}
            {/* <View style={S.CARDLIKE_BODY}>
            <XText style={S.TEXT_INACTIVE}>Debug: {JSON.stringify(bgItem)}</XText>
          </View> */}
          </View>
          <View style={[S.ROW_CENTER, S.WIDTH_44]}>
            <ForwardIcon />
          </View>
        </View>
      </TouchableOpacity>
    )
  }

  getFetchStoreId = () => {
    const { navigation } = this.props
    const storeId = util.getNavParam(this.props, 'storeId')
    return storeId
  }

  getFocusJobType = () => {
    const { navigation } = this.props
    const jobType = util.getNavParam(this.props, 'bgJobType')
    return jobType
  }

  getInitBgJosResponse = () => {
    const { navigation } = this.props
    const initBgJobResponse = util.getNavParam(this.props, 'initBgJobResponse')
    return initBgJobResponse
  }

  getWarningText = () => {
    const { navigation } = this.props
    let warningText = util.getNavParam(this.props, 'warningText', null)
    try {
      if (!warningText && warningText !== '') {
        const bgTypeText = util.getBgJobTypeText(this.getFocusJobType())
        warningText = `โปรดรอสักครู่ เนื่องจากมีคำสั่งงาน "${bgTypeText}" การดำเนินการอยู่แล้ว คุณสามารถกดที่ปุ่มลูกศรเพื่อเข้าไปดูรายละเอียดงานที่กำลังดำเนินการอยู่`
      }
    } catch (err) {
      //
    }
    return warningText
  }

  getWarningTextBgColor = () => {
    const { navigation } = this.props
    const warningTextBgColor = util.getNavParam(this.props, 'warningTextBgColor', COLORS.WHITE)
    return warningTextBgColor
  }

  fetchBgJobs = async ({ offset, limit }) => {
    if (!this.state.isAlreadyFetchInited) {
      const initBgJobResponse = this.getInitBgJosResponse()
      if (initBgJobResponse && initBgJobResponse.jobs) {
        await util.setStatePromise(this, { isFetching: false, isAlreadyFetchInited: true })
        return {
          items: initBgJobResponse.jobs,
          count: initBgJobResponse.count,
        }
      }
    }

    await util.setStatePromise(this, { isFetching: true })
    const { selectedJobStatusIndex, inProcessCount } = this.state
    const isFirstFetch = offset === 0

    const jobType = this.getFocusJobType()
    const requestBody = {
      store_id: this.getFetchStoreId(),
      offset,
      limit,
      return_count: isFirstFetch,
      // created_within_mins: 10080,
      status: 'inprocess',
      types: [jobType],
    }

    const apiOptions: IApiOptions = {
      axiosOptions: {
        retry: 0,
        timeout: 60000,
      },
      isErrorAlertDisabled: true,
      isApiV2: true,
    }
    const fetchedData = {
      count: undefined,
      items: [],
    }
    try {
      const res = await api.post('bg/jobs', requestBody, apiOptions)
      // const res = mockResponse
      if (!res || _.isEmpty(res)) {
        // FIXME: แค่ mock error เพราะดันมี {} กลับมาหลังจาก no internet connection โดยไม่ throw error
        throw {
          statusCode: 503,
          errorMessage: 'Service Unavailable',
        }
      }
      // console.log('doFetchProducts res => ', res)

      if (typeof res.count === 'number') {
        const jobCount = res.count
        fetchedData.count = jobCount
        if (selectedJobStatusIndex === 0 && jobCount !== inProcessCount) {
          await util.setStatePromise(this, { inProcessCount: jobCount })
        }
      }

      if (res.jobs) {
        fetchedData.items = res.jobs
      }
    } catch (err) {
      // console.log('fetchBgJobs err => ', err)
      if (requestBody.offset === 0) {
        await util.setStatePromise(this, { fetchErrorMessage: util.getErrorMessageFromErrorResponse(err) })
      }
    }

    await util.setStatePromise(this, { isFetching: false, isAlreadyFetchInited: true })

    return {
      items: fetchedData.items,
      count: fetchedData.count,
    }
  }

  _goBack = () => {
    util.navGoBack(this.props)
  }

  _onJobStatusSegmentChange = (newIndex: number) => {
    if (newIndex !== this.state.selectedJobStatusIndex) {
      this.setState({ selectedJobStatusIndex: newIndex, isAlreadyFetchInited: false, fetchErrorMessage: null })
    }
  }

  _renderEmptyListItem = ({ containerWidth = undefined }) => {
    const { isAlreadyFetchInited, isFetching, fetchErrorMessage } = this.state
    if (!isAlreadyFetchInited || isFetching) {
      return null
    }

    if (fetchErrorMessage) {
      return this.renderFetchErrorMessage(containerWidth)
    }

    return (
      <View style={[S.ROW_CENTER, S.PADDING_VERTICAL_12, S.MARGIN_VERTICAL_12, { width: containerWidth }]}>
        <XText style={S.TEXT_INACTIVE}>ยังไม่มีคำสั่งงาน</XText>
      </View>
    )
  }

  // แสดง error message จากการ fetch
  renderFetchErrorMessage = containerWidth => {
    const handleshowAlertFetchError = () => util.showAlertFetchError(this.state.fetchErrorMessage)
    return (
      <ErrorListItem
        containerStyle={{ width: containerWidth }}
        onPressShowAlertInfo={handleshowAlertFetchError}
        onPressDoRefresh={this.doRefresh}
      />
    )
  }

  doRefresh = async () => {
    await util.setStatePromise(this, { fetchErrorMessage: null, inProcessCount: null })
    await util.delay(200)
    await this.jobListRef.current.reset()
  }

  render() {
    const { selectedJobStatusIndex, inProcessCount, isFetching } = this.state
    const warningText = this.getWarningText()
    return (
      <View style={S.FLEX}>
        <XCustomHeader
          // headerStyle={{ borderBottomWidth: 0 }}
          title='คิวคำสั่งงานกำลังถูกดำเนินการอยู่'
          headerLeftProps={{ backIcon: true, onPressItem: this._goBack }}
        />
        <View style={[S.WIDTH_FULL, S.PADDING_8]}>
          {warningText && warningText !== '' ? (
            <View style={[S.ROW_CENTER, S.MARGIN_VERTICAL_4, S.BUTTON_OUTLINE, { backgroundColor: this.getWarningTextBgColor() }]}>
              <XText style={[S.TEXT_ACTIVE, S.TEXT_INACTIVE]}>{warningText}</XText>
            </View>
          ) : null}
        </View>
        <InfiniteList<IBgJobItemShort>
          key={`${selectedJobStatusIndex}`}
          ref={this.jobListRef}
          displayMode='list'
          style={S.FLEX}
          renderItem={this.renderBgJobItem}
          onFetchItems={this.fetchBgJobs}
          fetchLimit={12}
          renderListEmptyItem={this._renderEmptyListItem}
          disabledPullRefresh
        />
      </View>
    )
  }
}

// const LoadingBar = ({ now = 0, max = 100, height = 24 }) => {
//   const progress = max === 0 ? 0 : (now / max) * 100
//   const undone = 100 - progress
//   return (
//     <View style={[S.FLEX, S.ROW_CENTER, S.BORDER, { borderColor: 'white', borderRadius: Math.floor(height / 2), overflow: 'hidden' }]}>
//       <View
//         style={{
//           height,
//           flex: progress / 100,
//           backgroundColor: COLORS.BRAND_Success,
//         }}
//       />
//       <View style={{ height, flex: undone / 100, backgroundColor: COLORS.GREY }} />
//     </View>
//   )
// }

export default BackgroundJobInProcessByTypeView
