import React from 'react'
import { View, Image, StyleSheet, Platform, TextProps, Clipboard } from 'react-native'
import _ from 'lodash'
import { Svg, Path, Text as SvgText } from 'react-native-svg'

import CONS from 'x/config/constants'
import { COLORS, STYLES, S, PRODUCT_LIST_ITEM_WIDTH } from 'x/config/styles'
import { delay, isAsyncFunction } from 'x/utils/util'
import { MODE, APP_CONFIG } from 'x/config/mode'
import { ProductListDisplayMode, IProductItemData } from 'x/index'
import * as xFmt from 'x/utils/formatter'
import p from 'x/config/platform-specific'

import XText from 'xui/components/XText'
import XCard from 'xui/components/XCard'
import VStack from 'xui/components/VStack'
import Box from 'xui/components/Box'
import HStack from 'xui/components/HStack'
import XIcon, { XIconProps } from 'xui/components/XIcon'
import XImage from 'xui/components/XImage'

const emptyImage = require('../../images/empty_image.png')

export interface IProductListItemComponentProps {
  disabled?: boolean
  disabledIsSellerProductIcon?: boolean
  disabledPrice?: boolean
  disabledQty?: boolean

  index?: number
  data: IProductItemData
  maxWidth?: number
  displayMode?: ProductListDisplayMode
  onPress?: (item: { index: number; data: IProductItemData }) => void | Promise<void>
  renderBottom?: (item: { index: number; data: IProductItemData }) => JSX.Element
  renderIcon?: (item: { index: number; data: IProductItemData }) => JSX.Element

  numOfColumns?: number // 1 หรือ ส่งต่อมาจาก ProductListItem
}

const styles = StyleSheet.create({
  labelWithIconContainer: {
    marginTop: 2,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  labelIcon: {
    flex: Platform.OS === 'web' ? 1 : 0,
    flexBasis: 'auto',
    minWidth: STYLES.FONT_ICON_SMALLER,
    width: STYLES.FONT_ICON_SMALLER,
    height: STYLES.FONT_ICON_SMALLER,
    fontSize: STYLES.FONT_ICON_SMALLER,
    marginRight: 4,
  },
  // @ts-ignore
  productNameLabel: StyleSheet.flatten([S.TEXT_ACTIVE, S.TEXT_LARGER, { height: 40 }]),
  sellerProductImageIconContainer: {
    position: 'absolute',
    bottom: 4,
    right: 4,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  sellerProductImageIcon: {
    height: STYLES.FONT_ICON_NORMAL - 1,
    width: STYLES.FONT_ICON_NORMAL - 1,
  },
  productContentContainer: {
    paddingTop: 4,
    paddingLeft: 8,
    paddingBottom: 4,
    paddingRight: 8,
  },
  productItemButton: {
    flex: 1,
    backgroundColor: COLORS.WHITE,
  },
})

const DebugInfo = (props: { data: IProductItemData; index }) => {
  if (MODE !== 'dev') {
    return null
  }
  const { index, data } = props
  return (
    <View style={{ backgroundColor: COLORS.BG_LIGHT_GREY, padding: 4 }}>
      <XText style={S.TEXT_INACTIVE}>DEBUG Info::</XText>
      <XText style={S.TEXT_INACTIVE}>{`index = ${index}`}</XText>
      <XText style={S.TEXT_INACTIVE}>{`product_id = ${data.id}`}</XText>
    </View>
  )
}

const LabelWithIcon = (props: { iconProps?: XIconProps; textProps?: TextProps; label: string }) => (
  <HStack
    w='full'
    space='2'
    alignItems='center'
    // style={styles.labelWithIconContainer}
  >
    <XIcon
      inactive
      size='xs'
      // style={StyleSheet.flatten(styles.labelIcon)}
      {...props.iconProps}
    />
    <XText
      flex={1}
      // style={[S.TEXT_ACTIVE, { width: '100%' }]}
      numberOfLines={1}
      {...props.textProps}>
      {props.label}
    </XText>
  </HStack>
)
LabelWithIcon.defaultProps = {
  iconProps: {},
  textProps: {},
}

const ProductNameLabel = (props: { value: string }) => (
  <XText
    // style={styles.productNameLabel}
    minH='10'
    numberOfLines={2}>
    {props.value}
  </XText>
)

const ProductPriceLabel = (props: { disabled?: boolean; value: string }) => {
  if (props.disabled) {
    return null
  }
  return <LabelWithIcon label={props.value} iconProps={{ name: 'pricetag', family: 'Ionicons' }} />
}

const ProductAvailableQtyLabel = (props: { disabled?: boolean; value: string }) => {
  if (props.disabled) {
    return null
  }
  return <LabelWithIcon label={props.value} iconProps={{ name: 'albums', family: 'Ionicons' }} />
}

const SellerProductIcon = (props: { disabled?: boolean; isMyProduct?: boolean }) => {
  if (props && (props.isMyProduct || props.disabled)) {
    return null
  }

  return (
    <Box position='absolute' bottom='1' right='2'>
      <Image source={require('xui/img/tb/tb_seller0.png')} style={styles.sellerProductImageIcon} />
    </Box>
  )
  // return (
  //   <View style={styles.sellerProductImageIconContainer}>
  //     <Image source={require('xui/img/tb/tb_seller0.png')} style={styles.sellerProductImageIcon} />
  //   </View>
  // )
}

const StockWarningฺBar = (props: { disabled?: boolean; qty: number; maxWidth: number }) => {
  if (props.disabled || _.isNil(props.qty)) {
    return null
  }

  let title = null
  let bgColor = null
  if (props.qty === 0) {
    title = CONS.PRODUCT_STOCK.noStock
    bgColor = COLORS.BRAND_Danger
  } else if (props.qty > 0 && props.qty <= 5) {
    title = CONS.PRODUCT_STOCK.lowStock
    bgColor = COLORS.BRAND_Warning
  } else {
    return null
  }

  return <TrapezoidTitle title={title} width={props.maxWidth} bgColor={bgColor} verticalOffset={-2} />
}

const ProductListItem = (props: IProductListItemComponentProps) => {
  if (typeof props.data !== 'object') {
    // throw new Error('ProductListItem is required "data" props')
    return null
  }

  const {
    disabled,
    disabledIsSellerProductIcon,
    disabledPrice,
    disabledQty,
    index,
    data,
    maxWidth = PRODUCT_LIST_ITEM_WIDTH,
    onPress,
    displayMode,
    numOfColumns = 1,
  } = props

  const handlePress = async () => {
    if (isAsyncFunction(onPress)) {
      await onPress({ index, data })
    } else if (typeof onPress === 'function') {
      onPress({ index, data })
    }
    await delay(100)
  }

  let thumbnailURL = emptyImage
  if (_.isArray(data.t) && data.t.length > 0) {
    thumbnailURL = { uri: data.t[0] }
  } else if (_.isString(data.t)) {
    thumbnailURL = { uri: data.t }
  }
  const productName = data.n

  const priceMin = data.i ? parseFloat(data.i) : null
  const priceMax = data.x ? parseFloat(data.x) : null
  const variants = data.v || []
  let availableQty = data.q || 0

  const hasQtyToSell = availableQty > 0
  const isMyProduct = !!data.m

  // ถ้าเป็นสินค้าของฉันถึงจะยอมบอกว่าติดลบ หมายถึงเกิดการจองสินค้าที่ไม่เพียงพอเกิดขึ้นแล้ว
  if (availableQty < 0 && !isMyProduct) {
    availableQty = 0
  }

  const hasZeroWeight = data.z || false

  let priceDisplayText = ''
  if (typeof priceMax === 'number' && typeof priceMin === 'number' && priceMax !== priceMin) {
    priceDisplayText = `${xFmt.formatCurrency(priceMin)} - ${xFmt.currencyFormatter.format(priceMax)}`
  } else if (typeof priceMax === 'number' && typeof priceMin === 'number' && priceMax === priceMin) {
    priceDisplayText = `${xFmt.formatCurrency(priceMax)}`
  } else {
    priceDisplayText = '-' // should be impossible case
  }

  // Calculate Warning Flag sould display ?
  let badgeProductText = null
  let badgeProductBgColor = COLORS.BRAND_Warning // default สีเหลือง (ถ้ามี badgeProductText)

  const variantPrices = variants.map((v) => v.p)
  let hasNullPrice = false
  for (const variantPrice of variantPrices) {
    if (!variantPrice) {
      hasNullPrice = true
      break
    }
  }

  const isEmptyQty = availableQty <= 0 // ดูแล้ว avilable qty ใน product list ไม่ตรงกับใน sum variant qty
  const isLowQty = availableQty > 0 && availableQty <= 5

  // high priority first
  if (isEmptyQty) {
    // พี่เก่งขอเปลี่ยนจาก "หมดสต๊อค" เป็น "ใกล้หมดแล้ว"
    // badgeProductText = CONS.PRODUCT_STOCK.noStock
    badgeProductText = 'ใกล้หมดแล้ว'

    badgeProductBgColor = COLORS.BRAND_Danger
  } else if (isLowQty) {
    badgeProductText = CONS.PRODUCT_STOCK.lowStock
    // badgeProductBgColor = COLORS.BRAND_Info
  }

  // let productButtonLabel = `เพิ่ม ${APP_CONFIG.order.create_order.quick_add_product_to_order_qty} ชิ้นในออเดอร์...`
  // if (!hasQtyToSell) {
  //   productButtonLabel = 'สินค้าหมด'
  // } else if (priceDisplayText === '-') {
  //   productButtonLabel = 'ยังไม่ได้กำหนดราคา'
  // }

  // APP CONFIG
  let isShowStockQty = true
  // const isParent = data.has('parent_id') ? data.get('parent_id') : false
  if (!isMyProduct && !APP_CONFIG.product.show_non_stock_qty) {
    isShowStockQty = false
  }

  // const defaultContainerStyle = displayMode === 'grid' ? S.PRODUCT_LIST_ITEM_CONTAINER_GRIDMODE : S.PRODUCT_LIST_ITEM_CONTAINER_LISTMODE
  const isLeftCol = isLeftColumn(index, numOfColumns)
  const isRightCol = isRightColumn(index, numOfColumns)
  // const computeContainerPaddingStyle = { paddingLeft: isLeftCol ? 6 : 3, paddingRight: isRightCol ? 6 : 3 }

  // <VStack style={[defaultContainerStyle, computeContainerPaddingStyle]}>
  return (
    <VStack w={maxWidth} pt='2' pl={isLeftCol ? '2' : '1'} pr={isRightCol ? '2' : '1'}>
      <XCard
        w='full'
        _web={{
          h: 'full',
        }}
        // flex={1}
        disabled={disabled}
        // style={styles.productItemButton}
        onPress={disabled || _.isNil(data.n) || !_.isFunction(onPress) ? undefined : handlePress}>
        <VStack w='full' space='2'>
          <XImage source={thumbnailURL} style={S.PRODUCT_LIST_ITEM_IMAGE_GRIDMODE} resizeMode='cover' />

          {data.n && _.isFunction(props.renderIcon) ? (
            <Box position='absolute' top='1' left='1'>
              {props.renderIcon({ index, data })}
            </Box>
          ) : null}

          {/* <View style={styles.productContentContainer}> */}
          <VStack w='full' px='2'>
            <HStack w='full'>
              {data.n && !disabledIsSellerProductIcon && !isMyProduct ? (
                <Box pr='1'>
                  <Image source={require('xui/img/tb/tb_seller0.png')} style={styles.sellerProductImageIcon} />
                </Box>
              ) : null}
              <ProductNameLabel value={productName} />
            </HStack>
            <ProductPriceLabel disabled={disabledPrice} value={priceDisplayText} />
            <ProductAvailableQtyLabel disabled={disabledQty} value={availableQty.toString()} />
            {/* <SellerProductIcon disabled={disabledIsSellerProductIcon} isMyProduct={isMyProduct} /> */}
          </VStack>
          {/* </View> */}
          {/* <View style={{ width: '100%' }}>{props.renderBottom({ index, data })}</View> */}
          <VStack w='full' px='2'>
            {data.n ? props.renderBottom({ index, data }) : null}
          </VStack>

          {/* <DebugInfo data={data} index={index} /> */}
          {data.n ? <StockWarningฺBar disabled={disabledQty} maxWidth={maxWidth} qty={availableQty} /> : null}

          <HStack w='full' position='absolute' bottom='0' left='0' right='0' px='1'>
            {data.n && data.id ? <LabelID id={data.id} /> : null}
            {
              // @ts-ignore
              data.n && data.item_id ? <LabelID id={data.item_id} /> : null
            }
          </HStack>
        </VStack>
      </XCard>
    </VStack>
  )
}

const LabelID = (props: { id: string | number }) => {
  const { id = null } = props
  if (!props.id) {
    return null
  }
  return (
    <XText
      variant='inactive'
      fontSize='2xs'
      numberOfLines={1}
      // @ts-ignore
      onPress={() => {
        Clipboard.setString(id.toString())
        p.op.showToast(`คัดลอก ${id} แล้ว`, 'success')
      }}>
      {`ID:${id}`}
    </XText>
  )
}

function isLeftColumn(currentIndex: number, numOfColumns: number) {
  // if (currentIndex === 0) {
  //   return true
  // }
  return (currentIndex + 1) % numOfColumns === 1
}

function isRightColumn(currentIndex: number, numOfColumns: number) {
  return (currentIndex + 1) % numOfColumns === 0
}

function TrapezoidTitle(props: {
  title: string
  width?: number
  height?: number
  bgColor?: string
  textColor?: string
  verticalOffset?: number
}) {
  const {
    title,
    width = PRODUCT_LIST_ITEM_WIDTH,
    height = 28,
    bgColor = COLORS.APP_MAIN,
    textColor = COLORS.WHITE,
    verticalOffset = 0,
  } = props
  return (
    <View
      style={{
        marginTop: 2,
        marginBottom: 2,
        height,
        width,
        overflow: 'hidden',
        position: 'absolute',
        top: verticalOffset,
        left: 0,
        zIndex: 10000,
      }}>
      <Svg width={width} height={height} viewBox={`0 0 ${width} ${Math.floor(width / 5)}`} preserveAspectRatio='xMinYMid'>
        <Path
          d={`M 0 0 L ${width} 0 L ${Math.floor(width * 0.825)} ${Math.floor(width / 5)} L 0 ${Math.floor(width / 5)} L 0 0`}
          fill={bgColor}
          stroke={bgColor}
          strokeWidth={1}
          // markerEnd="url(#Triangle)"
        />
        <SvgText
          fill={textColor}
          strokeWidth={1}
          fontSize={Math.floor(width / 10)}
          // fontWeight={'bold'}
          // fontFamily={'Roboto'}
          x={Math.floor((width / 10) * 0.3)}
          y={Math.floor((width / 10) * 1.4)}
          textAnchor='start'>
          {title}
        </SvgText>
      </Svg>
    </View>
  )
}

ProductListItem.defaultProps = {
  index: undefined,
  displayMode: 'grid',
  renderBottom: () => null,
}

export default ProductListItem
