import React from 'react'
import VStack from 'xui/components/VStack'

import { View, ListRenderItemInfo } from 'react-native'

// import s from './_style'
import * as util from 'x/utils/util'
import { delay, logRender } from 'x/utils/util'
import _ from 'lodash'
// import * as NavActions from '../../services/navigation'

// import AddIcon from '../../components/AddIcon'
// import BackIcon from '../../components/BackIcon'
import { COLORS, STYLES } from 'x/config/styles'

import * as xAcl from 'x/utils/acl'
import xCONS from 'x/config/constants'
// import XStatusBar from '../../components/XStatusBar'
// import XCamera from '../../components/XCamera'
import BaseUIProductListView from 'xui/modules/product/BaseUIProductListView'
import { IProductItemDataMap } from 'x/index'

import XModal from 'xui/components/XModal'
import { CommonActions } from '@react-navigation/native'
import * as NavActions from '../../utils/navigation'
import ThemeSizeContext from '../../context/ThemeSizeContext'
import ProductListItem from './ProductListItem'

const { NAV_HEADER_HEIGHT, SCROLLBAR_WIDTH } = xCONS.SPECIFIC_PLATFORM.WEB
const { CARD_COMMON } = STYLES
const {
  PULL_MY_PRODUCT_TO_ORDER,
  PULL_SELLER_PRODUCT_TO_ORDER,
  PULL_PRODUCT,
  ADD,
  VIEW_SELF,
  EDIT_SELLER,
  EDIT_SELF,
  VIEW_SELLER,
  VIEW_VOLUME_DISCOUNT,
} = xCONS.PRODUCT_VIEW_MODE
const { COMPUTED_USE_PRODUCT_UPC, COMPUTED_USE_PRODUCT_SKU } = xCONS.STORE_SETTINGS
const PRODUCT_ITEM_WIDTH_MAX = 220

class ProductListView extends BaseUIProductListView {
  static displayName = 'ProductListView'

  static navigationOptions = {
    title: 'รายการสินค้า',
    header: null,
    headerLeft: null,
    headerRight: null,
  }

  static contextType = ThemeSizeContext

  context!: React.ContextType<typeof ThemeSizeContext>

  constructor(props) {
    super(props)
    // this.setState({ isVisibleProductCategoryFilterModal: false })
    // this.productItemRefs = {}
  }

  async componentDidMount(): Promise<void> {
    // super.componentDidMount() // completely override
    this.deviceWidth = this.context.contentOneOfThreeColumnWidth - SCROLLBAR_WIDTH
    this.productDescHeight = 60 // line ละ 20 pixel
    this.buttonBottomCardHeight = 40 // For quick add product to order mode
    this._computeProductListItemSize()

    await this._beforeInitDidMount()
    await this._initDidMount()
    await this._afterInitDidMount()
    await this._configCategory()
  }

  // componentDidUpdate(prevProps, prevState) {
  //   super.componentDidUpdate(prevProps, prevState)
  //   const { selectedProducts, searchedProducts, navigation } = this.props
  //   const { isSearch, txtSearch } = this.state
  //   if (prevState.isSearch !== isSearch || prevProps.selectedProducts !== selectedProducts || prevProps.searchedProducts !== searchedProducts) {
  //     let data: any = isSearch ? searchedProducts : selectedProducts
  //     if (data) {
  //       data = data.toArray()
  //     } else {
  //       data = []
  //     }
  //     for (const item of data) {
  //       const productId = item.get('i')
  //       this.productItemRefs[productId] = React.createRef()
  //     }
  //   }
  // }

  handlePressOpenProductCategoryFilterDrawer = (): void => {
    this.setState({ isVisibleProductCategoryFilterModal: true })
  }

  onRequestCloseCategoryFilterView = (): void => {
    this.setState({ isVisibleProductCategoryFilterModal: false })
  }

  async handlePressPullToMyStore(params) {
    // this.props.navigation.goBack('ProductView')
    // util.navGoBack(this.props, 'ProductView')
    util.navPopToRouteName(this.props, 'ProductView', true)
    await delay(100)
    this.props.navigation.dispatch(NavActions.navToProductViewPullProduct(params))
  }

  async handlePressPullMyToOrder(params) {
    // this.props.navigation.goBack('ProductView')
    util.navPopToRouteName(this.props, 'ProductView', true)
    await delay(100)
    this.props.navigation.dispatch(NavActions.navToProductViewPullMyProductToOrder(params))
  }

  async handlePressPullSellerToOrder(params) {
    // util.navGoBack(this.props, 'ProductView')
    util.navPopToRouteName(this.props, 'ProductView', true)
    await delay(100)
    this.props.navigation.dispatch(NavActions.navToProductViewPullSellerProductToOrder(params))
  }

  async handlePressViewMyProduct(params) {
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    // // console.log('ProductListView:: this.props.navigation', this.props.navigation)
    // // this.props.navigation.dispatch(NavActions.navToProductViewSelf(params))
    // // this.props.navigation.popToTop()
    // // const resetAction = StackActions.reset({
    // //   index: 0,
    // //   actions: [
    // //     NavigationActions.navigate({
    // //       key: 'ProductView',
    // //       routeName: 'ProductView',
    // //       params: { mode: xCONS.PRODUCT_VIEW_MODE.VIEW_SELF, ...params },
    // //     }),
    // //   ],
    // // })
    // // this.props.navigation.popToTop()
    // this.props.navigation.goBack('ProductView')
    // await delay(100)
    // this.props.navigation.navigate({
    //   key: 'ProductView',
    //   routeName: 'ProductView',
    //   params: { mode: xCONS.PRODUCT_VIEW_MODE.VIEW_SELF, ...params },
    // })
    // this.props.navigation.navigate({
    //   key: 'ProductView',
    //   routeName: 'ProductView',
    //   params: { mode: xCONS.PRODUCT_VIEW_MODE.VIEW_SELF, ...params },
    // })

    const { navigation } = this.props
    util.navPopToRouteName(this.props, 'ProductView', true)
    await delay(100)
    navigation.dispatch(
      CommonActions.navigate({
        key: 'ProductView',
        name: 'ProductView',
        params: { mode: xCONS.PRODUCT_VIEW_MODE.VIEW_SELF, ...params },
      })
    )

    this.inProcess = false
  }

  async handlePressEtcCase(params) {
    // this.props.navigation.goBack('ProductView')
    // util.navGoBack(this.props, 'ProductView')
    util.navPopToRouteName(this.props, 'ProductView', true)
    await delay(100)
    this.props.navigation.dispatch(NavActions.navToProductView(params))
  }

  // doNavToProductDetailAfterSearchMatchOneProduct(product?: { id: number; [key: string]: any }) {
  //   // FIXME: Implement me
  // }

  navToAddProductView = () => {
    const { navigation } = this.props
    if (!navigation) {
      return
    }

    // const { state } = navigation
    const params = util.getNavParams(this.props)
    if (_.isNil(params) || _.isNil(params.store_id)) {
      return
    }
    const { store_id } = params
    navigation.popToTop()

    setTimeout(() => {
      navigation.dispatch(NavActions.navToProductViewAdd({ store_id }))
    }, 500)
  }

  renderProductListItem = (data: ListRenderItemInfo<IProductItemDataMap>) => {
    const { item, index } = data
    // const { navigation, selectedStore } = this.props
    // const { state } = navigation
    // const s_use_retail_price = selectedStore.get('s_use_retail_price') || false
    // const productId = item.get('id')
    // const productItemRef = _.has(this.productItemRefs, productId) ? this.productItemRefs[productId] : null
    return (
      <ProductListItem
        // ref={productItemRef}
        index={index}
        data={item}
        // mode={state.params.mode}
        showPulledProductIcon={this._showPulledProductIcon()}
        showQuickAddButton={this._showQuickAddButton()}
        // navigation={navigation}
        // isShowRetailPrice={ s_use_retail_price }
        // isUseRetailPrice={ state.params.order_use_retail_price }
        width={this.productWidth}
        height={this.productHeight}
        thumbnailSize={this.thumbnailSize}
        itemMargin={this.itemMargin}
        warningZeroWeight={this._shouldWarningZeroWeight()}
        onPress={this._onProductPress}
        onPressAddOneProductToCart={this._onPressAddOneProductToCart}
        handlePullProductToOrder={this._pullProductToOrder}
        sellerStoreId={util.getNavParam(this.props, 'seller_store_id')}
      />
    )
  }

  // renderBarcodeScanButton = () => {
  //   return null
  // }

  // _renderCustomHeader = () => {
  //   const { navigation, selectedFetchStatus } = this.props
  //   const { txtSearch, catSeletedCategory } = this.state
  //   if (!navigation) {
  //     return null
  //   }

  //   const { state } = navigation
  //   if (!state || _.isNil(state.params) || _.isNil(state.params.mode)) {
  //     return null
  //   }

  //   const { mode } = state.params
  //   let canSearch = selectedFetchStatus && selectedFetchStatus.has('count') && selectedFetchStatus.get('count') > 0
  //   const countText = canSearch ? `${selectedFetchStatus.get('count')} รายการ` : ''
  //   let placeholderSearch = canSearch ? `ค้นหาสินค้าในร้าน ${countText}` : ''
  //   // placeholderSearch = 'สินค้าในร้านฉันทั้งหมด'
  //   if (mode === PULL_PRODUCT) {
  //     placeholderSearch = canSearch ? `ค้นหาสินค้าพร้อมดึง ${countText}` : ''
  //   }
  //   // else if (mode === VIEW_SELLER) {
  //   //   placeholderSearch = `ค้นหาสินค้าในร้านผู้ขายส่ง ${productCount} รายการ`
  //   // }
  //   // else if (mode === PULL_MY_PRODUCT_TO_ORDER) {
  //   //   placeholderSearch = `ค้นหาสินค้าในร้านฉัน ${productCount} รายการ`
  //   // }
  //   if (!_.isNil(catSeletedCategory)) {
  //     placeholderSearch = catSeletedCategory.n
  //     canSearch = true
  //     if (!_.isNil(selectedFetchStatus.get('count')) && selectedFetchStatus.get('count') > 0) {
  //       placeholderSearch = placeholderSearch + ` มี ${selectedFetchStatus.get('count')} รายการสินค้า`
  //     } else {
  //       placeholderSearch = placeholderSearch + ` ไม่พบรายการสินค้า`
  //     }
  //   }

  //   return (
  //     <Header
  //       iosBarStyle='dark-content'
  //       // searchBar
  //       // style={{
  //       //   // backgroundColor: 'white',
  //       //   paddingLeft: 0,
  //       //   paddingRight: 0,
  //       //   minHeight: 44,
  //       //   // paddingTop: 0,
  //       // }}
  //       style={{
  //         flex: 1,
  //         width: this.context.contentOneOfThreeColumnWidth,
  //         height: NAV_HEADER_HEIGHT,
  //       }}>
  //       <XStatusBar />
  //       <Stack style={{ flex: 1, width: this.context.contentOneOfThreeColumnWidth }}>
  //         {!_.includes([VIEW_SELF], mode) ? (
  //           <VStack style={{ flex: 1, width: 54, height: NAV_HEADER_HEIGHT, alignItems: 'center', justifyContent: 'center' }}>
  //             <NavHeaderButton
  //               backIcon={true}
  //               onPressItem={async () => {
  //                 if (this.inProcess) {
  //                   return
  //                 }
  //                 this.inProcess = true
  //                 this.props.navigation.pop()
  //                 await delay(500)
  //                 this.inProcess = false
  //               }}
  //             />
  //           </VStack>
  //         ) : null}

  //         <VStack
  //           style={{
  //             flex: 100,
  //             alignItems: 'center',
  //             justifyContent: 'center',
  //             paddingTop: 0,
  //             paddingLeft: 4,
  //             paddingRight: 4,
  //             maxWidth: 270,
  //           }}>
  //           <Item
  //             style={{
  //               flex: 1,
  //               width: '100%',
  //               height: NAV_HEADER_HEIGHT - 4,
  //               justifyContent: 'center',
  //               alignItems: 'center',
  //               marginLeft: 0,
  //               // backgroundColor: canSearch ? COLORS.APP_SECONDARY : 'transparent',
  //               backgroundColor: canSearch ? COLORS.FORM_PRIMARY_BG : 'transparent',
  //             }}>
  //             {this.renderBarcodeScanButton()}
  //             <XInput
  //               // ref={r => r && r._root && (this.txtSearchRef = r._root)}
  //               //@ts-ignore ref แดง บิวเว็บไม่ได้ ARTID
  //               ref={this.txtSearchRef}
  //               style={{
  //                 fontSize: STYLES.FONT_SIZE_NORMAL,
  //                 color: COLORS.TEXT_ACTIVE,
  //                 flex: 1,
  //               }}
  //               disabled={!canSearch}
  //               autoCapitalize={'none'}
  //               value={txtSearch}
  //               placeholder={placeholderSearch}
  //               placeholderTextColor={COLORS.TEXT_INACTIVE}
  //               // returnKeyType='search'
  //               maxLength={20}
  //               onChangeText={this._onSearchInputChange}
  //               // onChangeText={text => {
  //               //   this.isTxtSearchModified = true
  //               //   this.lastTxtSearch = text
  //               //   if (text.length > 0) {
  //               //     let _isSearch = true
  //               //     if (this.state.isSearch !== _isSearch) {
  //               //       // reset the last item offset as it switches mode
  //               //       this.lastSearchedProductOffset = 0
  //               //       this.flatListRef.scrollToOffset({ x: 0, y: 0, animated: false })
  //               //     }
  //               //     this.setState({ txtSearch: text, isSearch: _isSearch }, () =>
  //               //       this._onSearchProducts({ isLoadMore: false }),
  //               //     )
  //               //   } else {
  //               //     this._deactivateSearch()
  //               //   }
  //               // }}
  //             />
  //             {txtSearch && txtSearch.length > 0 ? (
  //               <TouchableOpacity onPress={this._deactivateSearch}>
  //                 <Icon
  //                   style={{
  //                     paddingTop: 4, // Offset because icon is doesn't crop
  //                     color: COLORS.TEXT_INACTIVE,
  //                     fontSize: STYLES.FONT_ICON_NORMAL,
  //                   }}
  //                   name='md-close-circle'
  //                 />
  //               </TouchableOpacity>
  //             ) : (
  //               <TouchableOpacity
  //                 // onPress={() => {
  //                 //   if (this.txtSearchRef && this.txtSearchRef.focus) {
  //                 //     this.setState({ isSearch: true })
  //                 //     this.txtSearchRef.focus()
  //                 //   }
  //                 // }}
  //                 onPress={this._doFocusSearchInput}
  //                 style={{ display: !canSearch ? 'none' : null }}>
  //                 <Icon
  //                   style={{
  //                     paddingTop: 4, // Offset because icon is doesn't crop
  //                     color: COLORS.TEXT_INACTIVE,
  //                     fontSize: STYLES.FONT_ICON_NORMAL,
  //                   }}
  //                   name='ios-search'
  //                 />
  //               </TouchableOpacity>
  //             )}
  //           </Item>
  //         </VStack>
  //         {this._renderAddProductButtonColumn(mode)}
  //         {this._renderProductCategoryFilterButtonColumn(mode)}
  //       </Stack>
  //       {/* <Button
  //         onPress={() => {
  //           this._onSearchInputChange('5690528476170', true)
  //         }}
  //         success={true}
  //       /> */}
  //     </Header>
  //   )
  // }

  _renderAddProductButtonColumn = (mode) =>
    _.includes([VIEW_SELF], mode) && xAcl.canDoAtSelectedStore(xCONS.PERM_STORE_HELPER.PRODUCT_ADD) ? (
      <VStack style={{ flex: 1, width: 44, height: NAV_HEADER_HEIGHT, alignItems: 'center', justifyContent: 'center' }}>
        {this._renderAddProductButton(mode)}
      </VStack>
    ) : null

  _renderProductCategoryFilterButtonColumn = (mode) => (
    <VStack style={{ flex: 1, width: 44, height: NAV_HEADER_HEIGHT, alignItems: 'center', justifyContent: 'center' }}>
      {this._renderProductCategoryFilterButton()}
    </VStack>
  )

  getScreenWidth = (): number => this.context.contentOneOfThreeColumnWidth

  renderMain = () => {
    logRender(this)
    const { navigation, selectedFetchStatus } = this.props
    const { txtSearch } = this.state
    const { contentHeight, contentOneOfThreeColumnWidth } = this.context
    // const { state } = navigation
    // const { mode } = state.params
    const mode = util.getNavParam(this.props, 'mode')
    const canSearch = selectedFetchStatus && selectedFetchStatus.has('count') && selectedFetchStatus.get('count') > 0
    const countText = canSearch ? `${selectedFetchStatus.get('count')} รายการ` : ''
    let placeholderSearch = canSearch ? `ค้นหาสินค้าในร้าน ${countText}` : ''
    // placeholderSearch = 'สินค้าในร้านฉันทั้งหมด'
    if (mode === PULL_PRODUCT) {
      placeholderSearch = canSearch ? `ค้นหาสินค้าพร้อมดึง ${countText}` : ''
    }
    // const { selectedProducts } = this.props
    // log('_ProductListView_render__products_: ', selectedProducts.toJS())
    // log('_ProductListView_render__products_: ', this.props.products.get('selectedProducts'))
    return (
      <View style={{ flex: 1, width: contentOneOfThreeColumnWidth, height: contentHeight, backgroundColor: COLORS.BG_LIGHT_GREY }}>
        {this._renderCustomHeader()}
        {this._renderCatList()}

        <View
          style={{ flex: 1, height: contentHeight - (56 + 40), width: contentOneOfThreeColumnWidth }}
          // disableKBDismissScroll={true}
          // refreshControl={<RefreshControl
          //   refreshing={ this.state.refreshing }
          //   onRefresh={ this._onRefresh }
          // />}
        >
          {this._renderHelperTab()}
          {this._renderProductList()}
          {/* { this._renderProducts(this.props.products.get('selectedProducts'))} */}

          <XModal
            visible={this.state.isVisibleProductCategoryFilterModal}
            webNumColumns={1}
            // _isVisibleProductCategoryFilterModal
            onRequestClose={this.onRequestCloseCategoryFilterView}
            children={this._renderCategoryFilterView()}
          />
        </View>
      </View>
    )
  }
}
// export default connectStyle(xCONS.APP_NAME + '.ProductListView', s)(ProductListView)
export default ProductListView
