import { connect } from 'react-redux'
import PGSelectorOnlyView from './PGSelectorOnlyView'
import { bindActionCreators } from 'redux'
import * as StoreActions from 'x/modules//store/StoreState'
import { getSelectedStore } from 'x/redux/selectors'

export default connect(
  (state) => ({
    // ...getState(state),
    selectedStore: getSelectedStore(state),
  }),
  (dispatch) => {
    return {
      // ...getDispatch(dispatch),
      getShopeeAuthUrl: bindActionCreators(StoreActions.shopeeAuthUrl, dispatch),
      createUserGroup: bindActionCreators(StoreActions.createUserGroup, dispatch),
      updateNewChannelList: bindActionCreators(StoreActions.updateNewChannelList, dispatch),
      updateChannel: bindActionCreators(StoreActions.updateChannel, dispatch),
      deleteChannel: bindActionCreators(StoreActions.deleteChannel, dispatch),
      syncBackgroundJobs: bindActionCreators(StoreActions.syncBackgroundJobs, dispatch),
      syncStoreBGTask: bindActionCreators(StoreActions.syncMKPBackgroungTasks, dispatch),

      syncShopInfo: bindActionCreators(StoreActions.syncShopInfo, dispatch),
      syncMKPProduct: bindActionCreators(StoreActions.syncMKPProduct, dispatch),
      syncMKPOrder: bindActionCreators(StoreActions.syncMKPOrder, dispatch),
    }
  }
)(PGSelectorOnlyView)
