// import EntypoIcon from 'react-native-vector-icons/Entypo'

// import BackIcon from '../../components/BackIcon'
// import XTabNavIcon from '../../components/XTabNavIcon'

import BaseUISelectPackageView from 'xui/modules/subscription/BaseUISelectPackageView'

export default class SelectPackageView extends BaseUISelectPackageView {
  static displayName = 'SelectPackageView'
}
