import React from 'react'
import { AppState, AppStateStatus, NativeEventSubscription } from 'react-native'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import dayjs, { Dayjs } from 'dayjs'

import { getSelectedStoreOrdersDates, getSelectedStoreOrdersDatesOptionKey, getStoreSelected } from 'x/redux/selectors'
import p from 'x/config/platform-specific'
import CONS from 'x/config/constants'
import * as StoreState from 'x/modules/store/StoreState'
import {
  IDateRange,
  IXDateRangeSelectorOptionKey,
  ActionApiParams,
  ISetSelectedStoreOrdersDatesParams,
  IXDateRangeSelectorChangeParams,
  ISelectedStoreMap,
} from 'x/index'

import * as util from 'x/utils/util'
import _ from 'lodash'
import HStack from '../HStack'
import XText from '../XText'
import XIcon from '../XIcon'
import XDateRangeSelectorButton from '../XDateRangeSelectorButton'

// import * as util from 'x/utils/util'

interface IStoreOrdersCreatedAtDatesSelectorProps {
  selectedStore: ISelectedStoreMap
  selectedStoreOrdersDates: IDateRange
  selectedStoreOrdersDatesOptionKey: IXDateRangeSelectorOptionKey
  fetchInitializeData: (params: ActionApiParams) => void
  setSelectedStoreOrdersDates: (params: ISetSelectedStoreOrdersDatesParams) => void
  // navigation: NavigationStackProp<NavigationRoute>
}
interface IStoreOrdersCreatedAtDatesSelectorState {
  // [key: string]: any
  isSubmitting: boolean
  isInitialized: boolean
  showPopover: boolean
  appState: AppStateStatus
}

class StoreOrdersCreatedAtDatesSelector extends React.Component<
  IStoreOrdersCreatedAtDatesSelectorProps,
  IStoreOrdersCreatedAtDatesSelectorState
> {
  static displayName = 'StoreOrdersCreatedAtDatesSelector'

  // static defaultProps = {
  //   opacity: 1,
  // }

  inProcess?: boolean

  changeEventSubscription: NativeEventSubscription

  constructor(props) {
    super(props)
    this.state = {
      isInitialized: false,
      showPopover: false,
      isSubmitting: false,
      appState: AppState.currentState,
    }
  }

  async componentDidMount(): Promise<void> {
    this.changeEventSubscription = AppState.addEventListener('change', this._handleAppStateChange)

    const { selectedStoreOrdersDatesOptionKey: ssodKey, setSelectedStoreOrdersDates } = this.props
    const selectedStoreOrdersDatesOptionKey = (await p.op.storageGet(
      CONS.STATE_SAVE_KEYS.SELECTED_STORE_ORDERS_DATES_OPTION_KEY,
      false
    )) as IXDateRangeSelectorOptionKey

    if (_.isString(selectedStoreOrdersDatesOptionKey) && selectedStoreOrdersDatesOptionKey !== ssodKey) {
      setSelectedStoreOrdersDates({
        selectedStoreOrdersDates: util.getDateRangeFromOption(selectedStoreOrdersDatesOptionKey),
        selectedStoreOrdersDatesOptionKey,
      })
      await util.delay(200)
    }

    this.setState({ isInitialized: true })
  }

  componentDidUpdate(
    prevProps: Readonly<IStoreOrdersCreatedAtDatesSelectorProps>,
    prevState: Readonly<IStoreOrdersCreatedAtDatesSelectorState>,
    snapshot?: any
  ): void {
    const { selectedStore } = this.props

    // @ts-ignore
    const prevLastFetchedAt = prevProps.selectedStore.get('lastFetchedAt') as Dayjs
    // @ts-ignore
    const currLastFetchedAt = selectedStore.get('lastFetchedAt') as Dayjs

    // ถ้าหากว่าเวลา last fetched ผ่านไปหนึ่งวัน (กรณีไม่ได้ปิดแอปเลย)
    if (
      prevLastFetchedAt &&
      currLastFetchedAt &&
      Math.abs(currLastFetchedAt.startOf('day').diff(prevLastFetchedAt.startOf('day'), 'days')) > 0
    ) {
      this._updateDateRangeSelector()
    }
  }

  componentWillUnmount() {
    // https://github.com/microsoft/react-native-code-push/issues/2378
    // AppState.remove('change', this._handleAppStateChange)
    if (this.changeEventSubscription) {
      this.changeEventSubscription.remove()
    }
  }

  _handleAppStateChange = (nextAppState: AppStateStatus) => {
    if (this.state.appState.match(/inactive|background/) && nextAppState === 'active') {
      // console.log('App has come to the foreground!')
      const { selectedStore } = this.props

      // @ts-ignore
      const currLastFetchedAt = selectedStore.get('lastFetchedAt') as Dayjs
      const today = dayjs().startOf('day')
      // const tomorrow = today.clone().add(1, 'days')
      // const dayDiff = tomorrow.diff(today, 'days')
      // console.log('_handleAppStateChange dayDiff => ', dayDiff)
      const shouldUpdateSelector = Math.abs(today.diff(currLastFetchedAt.startOf('day'), 'days')) > 0
      // console.log('_handleAppStateChange shouldUpdateSelector => ', shouldUpdateSelector)

      if (shouldUpdateSelector) {
        this._updateDateRangeSelector()
      }
    }
    // console.log('_handleAppStateChange nextAppState => ', nextAppState)
    this.setState({ appState: nextAppState })
  }

  _updateDateRangeSelector = () => {
    const { selectedStoreOrdersDatesOptionKey } = this.props
    if (selectedStoreOrdersDatesOptionKey !== 'Custom') {
      this._onXDateRangeSelectorChange({
        dates: util.getDateRangeFromOption(selectedStoreOrdersDatesOptionKey),
        optionKey: selectedStoreOrdersDatesOptionKey,
      })
    }
  }

  // renderDatesSelector = () => {
  //   const { selectedStoreOrdersDates, selectedStoreOrdersDatesOptionKey, setSelectedStoreOrdersDates, fetchInitializeData } = this.props
  //   return (
  //     <VStack
  //       w='300px'
  //       // minW='300px'
  //       // minH='200px'
  //       p='1'
  //       bg='white'
  //       //  borderWidth='1' borderRadius='lg' overflow='hidden'
  //     >
  //       <XText w='full' py='2' textAlign='center' variant='inactive' bold>
  //         เลือกช่วงวันที่
  //       </XText>
  //       <HStack position='absolute' top='1' right='1'>
  //         <XIconButton name='md-close-circle' onPress={this._hidePopover} />
  //       </HStack>
  //       <XDateRangeSelector
  //         // selectedDates={selectedDates}
  //         // selectedOptionKey={selectedOptionKey}
  //         selectedDates={selectedStoreOrdersDates || util.getDateRangeFromOption(selectedStoreOrdersDatesOptionKey)}
  //         selectedOptionKey={selectedStoreOrdersDatesOptionKey}
  //         onChange={(params) => {
  //           // setTimeout(() => {
  //           //   setSelectedDates(params.dates)
  //           // }, 10)

  //           // setTimeout(() => {
  //           //   setSelectedOptionKey(params.optionKey)
  //           // }, 20)

  //           setSelectedStoreOrdersDates({
  //             selectedStoreOrdersDates: params.dates,
  //             selectedStoreOrdersDatesOptionKey: params.optionKey,
  //           })

  //           setTimeout(() => {
  //             // const res: { [key: string]: any } = await new Promise((resolve) => {
  //             fetchInitializeData({
  //               created_at_from: params.dates.begin.format(CONS.SERVER_DATETIME_FORMAT),
  //               created_at_to: params.dates.end.format(CONS.SERVER_DATETIME_FORMAT),
  //               // successCallback: resolve,
  //               // failedCallback: () => resolve(null),
  //             })
  //             // })
  //           }, 100)
  //         }}
  //       />
  //     </VStack>
  //   )
  // }

  renderDisplayDatesLabel = () => {
    const { selectedStoreOrdersDatesOptionKey } = this.props
    if (selectedStoreOrdersDatesOptionKey === CONS.DATERANGE_SELECTOR.OPTION.Custom) {
      return null
    }

    return (
      <HStack p='1' borderRadius='1' borderColor='muted.300' bg='muted.200'>
        <XText t={`dateRangeSelector.options.${selectedStoreOrdersDatesOptionKey}`} />
      </HStack>
    )
  }

  renderDisplayDatesText = () => {
    const { selectedStoreOrdersDates, selectedStoreOrdersDatesOptionKey } = this.props
    const { begin } = selectedStoreOrdersDates
    const { end } = selectedStoreOrdersDates

    const beginFormat = 'D/MMM/YY'
    // let beginFormat = 'D MMMM YYYY'
    // // @ts-ignore
    // const isSameYear = begin.isSame(end, 'year')
    // // @ts-ignore
    // const isSameMonth = begin.isSame(end, 'month')
    // // @ts-ignore
    // const isSameDay = begin.isSame(end, 'day')

    // if (isSameYear) {
    //   beginFormat = 'D MMMM'
    // }

    // if (isSameYear && isSameMonth) {
    //   beginFormat = 'D'
    // }

    return (
      <HStack flex={1} alignItems='center' justifyContent='flex-end'>
        {/* {isSameDay ? null : <XText>{begin.format(beginFormat)}</XText>} */}
        {/* {isSameDay ? null : <XText>{' - '}</XText>} */}
        <XText>{begin.format(beginFormat)}</XText>
        <XText>{' - '}</XText>
        {/* <XText>{end.format('D MMMM YYYY')}</XText> */}
        <XText>{end.format('D/MMM/YY')}</XText>
      </HStack>
    )
  }

  renderDisplayDates = () => {
    const { selectedStoreOrdersDates, selectedStoreOrdersDatesOptionKey } = this.props

    if (!selectedStoreOrdersDates || !selectedStoreOrdersDates.begin || !selectedStoreOrdersDates.end) {
      return null
    }

    return (
      <HStack
        w='full'
        minH='9'
        px='2'
        py='1'
        borderWidth='1'
        borderColor='primary.500'
        borderRadius='lg'
        bg='primary.100'
        alignItems='center'
        space='1'>
        {this.renderDisplayDatesLabel()}
        {this.renderDisplayDatesText()}
        <XIcon name='arrow-drop-down' family='MaterialIcons' />
      </HStack>
    )
  }

  _showPopover = () => this.setState({ showPopover: true })

  _hidePopover = () => this.setState({ showPopover: false })

  _onXDateRangeSelectorChange = async (params: IXDateRangeSelectorChangeParams) => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    await util.setStatePromise(this, { isSubmitting: true })

    const { selectedStoreOrdersDates, selectedStoreOrdersDatesOptionKey, setSelectedStoreOrdersDates, fetchInitializeData } = this.props

    setSelectedStoreOrdersDates({
      selectedStoreOrdersDates: params.dates,
      selectedStoreOrdersDatesOptionKey: params.optionKey,
    })

    await util.delay(100)

    const res: { [key: string]: any } = await new Promise((resolve) => {
      fetchInitializeData({
        created_at_from: params.dates.begin.format(CONS.SERVER_DATETIME_FORMAT),
        created_at_to: params.dates.end.format(CONS.SERVER_DATETIME_FORMAT),
        successCallback: resolve,
        failedCallback: () => resolve(null),
      })
    })

    const { Last7Days, Last30Days, Last60Days, Last90Days } = CONS.DATERANGE_SELECTOR.OPTION
    if (_.includes([Last7Days, Last30Days, Last60Days, Last90Days], params.optionKey)) {
      await p.op.storageSet(CONS.STATE_SAVE_KEYS.SELECTED_STORE_ORDERS_DATES_OPTION_KEY, params.optionKey, false)
    }

    await util.delay(100)
    await util.setStatePromise(this, { isSubmitting: false })
    this.inProcess = false
  }

  render() {
    const { selectedStoreOrdersDates, selectedStoreOrdersDatesOptionKey, setSelectedStoreOrdersDates, fetchInitializeData } = this.props
    const { isInitialized, showPopover, isSubmitting } = this.state

    return (
      <XDateRangeSelectorButton
        // disabled={isSubmitting}
        submitting={isSubmitting}
        title='สร้างออเดอร์ภายใน...'
        selectedDates={selectedStoreOrdersDates || util.getDateRangeFromOption(selectedStoreOrdersDatesOptionKey)}
        selectedOptionKey={selectedStoreOrdersDatesOptionKey}
        onChange={this._onXDateRangeSelectorChange}
        containerProps={
          p.op.isWeb()
            ? undefined
            : {
                // h: '9',
                borderWidth: '0',
                // borderColor: isSubmitting ? 'muted.400:alpha.80' : 'primary.100:alpha.50',
                // bg: isSubmitting ? 'muted.200:alpha.40' : 'muted.50:alpha.30',
                bg: 'black:alpha.10',
                alignItems: 'flex-start',
                // maxW: '250px',
              }
        }
        labelContainerProps={p.op.isWeb() ? undefined : { bg: 'muted.400:alpha.40', borderWidth: '0', borderColor: 'muted.500' }}
        labelTextProps={p.op.isWeb() ? undefined : { color: 'muted.400' }}
        dateLabelProps={p.op.isWeb() ? undefined : { color: 'muted.400' }}
        dateTextProps={p.op.isWeb() ? undefined : { color: 'muted.300' }}
        dropdownIconProps={p.op.isWeb() ? undefined : { color: 'muted.400' }}
      />
    )

    // if (!isInitialized) {
    //   return this.renderDatesSelector()
    // }

    // return (
    //   <VStack w='full'>
    //     <Popover
    //       mode={PopoverMode.RN_MODAL}
    //       // mode={PopoverMode.JS_MODAL}
    //       // mode={PopoverMode.TOOLTIP}
    //       isVisible={showPopover}
    //       animationConfig={{ delay: 100, duration: 100 }}
    //       // placement={PopoverPlacement.BOTTOM}
    //       popoverStyle={{ backgroundColor: 'white', borderRadius: 12, borderWidth: 1, borderColor: '#ccc', overflow: 'hidden' }}
    //       backgroundStyle={{ backfaceVisibility: 'visible', backgroundColor: 'green', opacity: 1 }}
    //       onRequestClose={this._hidePopover}
    //       from={<TouchableOpacity onPress={this._showPopover}>{this.renderDisplayDates()}</TouchableOpacity>}>
    //       {/* <XOverlay visible={showPopover} onRequestClose={this._hidePopover}> */}
    //       {this.renderDatesSelector()}
    //       {/* </XOverlay> */}
    //     </Popover>
    //   </VStack>
    // )
  }
}

export default connect(
  (state) => ({
    selectedStore: getStoreSelected(state),
    // profile: getMyProfile(state),
    selectedStoreOrdersDates: getSelectedStoreOrdersDates(state),
    selectedStoreOrdersDatesOptionKey: getSelectedStoreOrdersDatesOptionKey(state),
  }),
  (dispatch) => ({
    dispatch,
    fetchInitializeData: bindActionCreators(StoreState.fetchInitializeData, dispatch),
    setSelectedStoreOrdersDates: bindActionCreators(StoreState.setSelectedStoreOrdersDates, dispatch),
  })
)(StoreOrdersCreatedAtDatesSelector)
