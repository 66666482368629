import { take, takeEvery, takeLatest, put, call, fork, all } from 'redux-saga/effects'
import * as orderActions from 'x/modules/order/OrderState'
import _ from 'lodash'
import api from '../../utils/api'
import actions from '../../config/actions'
import * as paymentActions from '../../modules/payment/PaymentState'

import p from '../../config/platform-specific'
import { log } from '../../utils/util'
import { ActionApiParams } from '../../index'
import { fetchOrderDetail } from './orders'

// import {
//   getPaymentListReqPayload,
// } from '../selectors'

export default function* watchAllPayments() {
  yield all([
    fork(watchFetchPaymentList),
    fork(watchSubmitPayment),
    fork(watchAutomaticSubmitPayment),
    fork(watchConfirmPayment),
    fork(watchCancelPayment),
  ])
}

function* watchFetchPaymentList() {
  log('In watchFetchPaymentList')
  // while (true) {
  //   const { payload, successCallback, failedCallback } = yield take(paymentActions.LIST_DO_FETCH)
  //   yield call(fetchPaymentList, payload, successCallback, failedCallback)
  // }
  yield takeLatest(paymentActions.LIST_DO_FETCH, fetchPaymentList)
}

// Fetch fetchPayment and prepare for PaymentView
function* fetchPaymentList(action: ActionApiParams) {
  const { body, successCallback, failedCallback } = action
  log('Before api fetch fetchPaymentList payload: ', body)
  const apiOptions = {
    messages: {
      errorMsg: 'เกิดข้อผิดพลาดในการแสดงข้อมูล',
    },
    showSpinner: true,
  }
  try {
    const res = yield call(api.post, api.POST_PAYMENTS, body, apiOptions)
    log('After api fetch fetchPaymentList Response: ', res)
    if (_.has(res, 'payments')) {
      res.listReqPayload = body
      yield put({ type: paymentActions.LIST_LOADED, payload: res })
    }
    if (_.isFunction(successCallback)) {
      successCallback(res)
    }
  } catch (error) {
    log('After api fetch fetchPayment error: ', error)
    if (_.isFunction(failedCallback)) {
      failedCallback(error)
    }
  }
}

function* watchSubmitPayment() {
  log('In watchSubmitPayment')
  while (true) {
    // const { payload } = yield take(paymentActions.PAYMENT_SUBMIT)
    // yield call(submitPayment, payload)
    const action = yield take(paymentActions.PAYMENT_SUBMIT)
    yield call(submitPayment, action)
  }
  // yield takeEvery(paymentActions.PAYMENT_SUBMIT, submitPayment)
}

function* submitPayment(action: ActionApiParams) {
  const { body, successCallback, failedCallback, refreshOrderDetail = false } = action
  log('Before api fetch submitPayment body: ', body)
  try {
    const apiOptions = {
      messages: {
        successMsg: 'แจ้งชำระเงินเสร็จสิ้น',
        errorMsg: 'เกิดข้อผิดพลาดในการแจ้งชำระเงิน',
      },
      showSpinner: false,
      axiosOptions: {
        retry: 0,
        timeout: 60000, // long fetch 60 วินาที
      },
    }
    // keep orderId and storeId for updating order later
    // let storeId = body.store_id
    // let orderId = body.navFromOrderId

    const res = yield call(api.put, api.PUT_PAYMENT, body, apiOptions)
    log('After api fetch submitPayment Response: ', res)
    if (res && _.has(res, ['payment', 'orders']) && _.isArray(res.payment.orders) && res.payment.orders.length > 0) {
      // // Load new order if need
      if (refreshOrderDetail && body.store_id && res.payment.orders[0].id) {
        const targetStoreId = body.store_id
        const targetOrder = res.payment.orders[0]
        // const targetOrderId = _.has(targetOrder, ['parent', 'id']) ? targetOrder.parent.id : targetOrder.id
        const targetOrderId = targetOrder.id
        const orderStoreId = targetOrder.store_id
        if (targetStoreId === orderStoreId) {
          const orderApiBody = { store_id: targetStoreId, order_id: targetOrderId }
          // yield put({ type: actions.ORDER_FETCH, body: orderApiBody })
          // // yield put(orderActions.shouldFetchOrderDetail())
          // yield put(
          //   orderActions.fetchOrderDetail({
          //     body: orderApiBody,
          //     skipOrderReset: true,
          //   }),
          // )
          yield call(fetchOrderDetail, {
            body: orderApiBody,
            skipOrderReset: true,
          })
        } else {
          yield put(orderActions.shouldFetchOrderDetail())
        }
      }

      yield put({ type: actions.STORE_ORDERS_SHOULD_FETCH_ALL }) // Refresh Order List
      yield put({ type: actions.STORE_ORDERS_SHOULD_FETCH_SINGLE_TAB_KEY, payload: 'myTasks_pay' })
      yield put({ type: actions.STORE_ORDERS_SHOULD_FETCH_SINGLE_TAB_KEY, payload: 'myTasks_paySalesOrdersPaid' })
      yield put({ type: actions.STORE_ORDERS_SHOULD_FETCH_SINGLE_TAB_KEY, payload: 'myTasks_payOther' })
      yield put({ type: actions.SELECTED_STORE_SHOULD_FETCH }) // StoreMyView + Donut

      // if (_.isArray(res.orders) && res.orders.length > 0) {
      //   const targetOrder = res.orders[0]
      //   if (_.isObject(targetOrder)) {
      //     yield call(handleOrderResponse, { order: targetOrder })
      //   }
      //   // yield put(orderActions.shouldFetchOrderDetail())
      // }
    }
    // if (storeId && orderId) {
    // yield put({ type: actions.ORDER_FETCH, payload: { store_id: storeId, order_id: orderId } })
    // let currKey = util.getCurrentScreenNavKey()
    // log('currKey')
    // log(currKey)
    // yield put({ type: 'Navigation/BACK', key: currKey })

    // yield put({ type: orderActions.SHOULD_FETCH_ORDER_DETAIL })

    // NavActions.goBack(prevKey)
    // yield put(NavActions.goBack({ key: prevKey }))
    // yield put(NavigationActions.back(prevKey))
    // }
    // yield put(NavActions.goBack)
    // yield put({ type: orderActions.SHOULD_FETCH_ORDER_DETAIL })
    // if (_.has(res, 'payment')) {
    // const { payment } = res

    // refresh order id that this payment is originally navigated from, so the info is up-to-date
    // log('submitPayment.Refreshing order# ' + orderId + ' from storeId: ' + storeId)
    // if (storeId && orderId) {
    //   yield put({ type: actions.ORDER_FETCH, payload: { store_id: storeId, order_id: orderId } })
    // }

    // then notify payment success
    // yield put({ type: paymentActions.PAYMENT_LOAD, payload: payment })

    // }
    if (_.isFunction(successCallback)) {
      successCallback(res)
    }
  } catch (error) {
    log('After api fetch submitPayment error: ', error)
    if (_.isFunction(failedCallback)) {
      failedCallback(error)
    }
  }
}

function* watchAutomaticSubmitPayment() {
  log('In watchAutomaticSubmitPayment')
  while (true) {
    const { payload, callback } = yield take(actions.AUTOMATIC_SUBMIT_PAYMENT)
    yield call(automaticSubmitPayment, payload, callback)
  }
}

function* automaticSubmitPayment(preBody, callback) {
  // จะขาด payment_account_id ต้อง fetch ดู
  const { store_id } = preBody
  log('Before api fetch automaticSubmitPayment preBody: ', preBody)
  try {
    let payment_account_id = null
    // @ts-ignore
    const accResponse = yield call(api.post, api.POST_BANKACCOUNTS, { store_id })
    if (accResponse && accResponse.payment_accounts && accResponse.payment_accounts.length > 0) {
      const pAccounts = _.cloneDeep(accResponse.payment_accounts)
      pAccounts.sort((a, b) => a.id - b.id)
      for (let i = 0; i < pAccounts.length; i++) {
        if (pAccounts[i].bank_id === 1) {
          // bank_id 1 === บัญชีอื่นๆ
          payment_account_id = pAccounts[i].id
          break
        }
      }
    }

    if (payment_account_id) {
      const apiOptions = {
        messages: {
          successMsg: 'บันทึกรับชำระเงินจากลูกค้าอัตโนมัติเสร็จสิ้น',
          errorMsg: 'เกิดข้อผิดพลาดในการบันทึกรับชำระเงินจากลูกค้าอัตโนมัติ',
        },
        showSpinner: true,
      }

      const res = yield call(api.put, api.PUT_PAYMENT, { ...preBody, payment_account_id }, apiOptions)
      log('After api fetch automaticSubmitPayment Response: ', res)
      if (res) {
        log('callback => ', callback)
        if (_.isFunction(callback)) {
          callback()
        }
      }

      yield put(orderActions.shouldFetchOrderDetail())
      yield put({ type: actions.STORE_ORDERS_SHOULD_FETCH_ALL }) // Refresh Order List
      yield put({ type: actions.STORE_ORDERS_SHOULD_FETCH_SINGLE_TAB_KEY, payload: 'myTasks_pay' })
      yield put({ type: actions.STORE_ORDERS_SHOULD_FETCH_SINGLE_TAB_KEY, payload: 'myTasks_paySalesOrdersPaid' })
      yield put({ type: actions.STORE_ORDERS_SHOULD_FETCH_SINGLE_TAB_KEY, payload: 'myTasks_payOther' })
      yield put({ type: actions.SELECTED_STORE_SHOULD_FETCH }) // StoreMyView + Donut
    } else {
      p.op.alert(
        `ไม่สามารถดำเนินการได้`,
        'ระบบอัตโนมัติไม่พบบัญชีรับชำระ ที่มีรูปแบบบัญชี "อื่นๆ" ในร้านของคุณ กรุณาดำเนินการสร้างบัญชีใหม่ด้วยตัวเอง โดยระบุรูปแบบบัญชีเป็น "อื่นๆ"'
      )
    }
  } catch (error) {
    log('After api fetch automaticSubmitPayment error: ', error)
  }
}

function* watchConfirmPayment() {
  log('In watchConfirmPayment')
  // while (true) {
  //   const { payload } = yield take(paymentActions.PAYMENT_CONFIRM)
  //   yield call(confirmPayment, payload)
  // }
  //
  yield takeEvery(paymentActions.PAYMENT_CONFIRM, confirmPayment)
}

// Fetch fetchPayment and prepare for PaymentView
// function* confirmPayment({ body, refreshOrderDetail }) {
function* confirmPayment(action: ActionApiParams) {
  const { body, successCallback, failedCallback } = action
  log('Before api confirmPayment payload: ', body)
  // const _successMsg = body.reject ? 'ปฏิเสธการชำระสำเร็จ' : 'ยืนยันการรับชำระสำเร็จ'
  const apiOptions = {
    // messages: {
    //   successMsg: _successMsg,
    //   errorMsg: 'เกิดข้อผิดพลาดในการยืนยันการชำระ',
    // },
    // showSpinner: true,
    showSpinner: false,
    axiosOptions: {
      retry: 0,
      timeout: 30000, // long fetch 30 วินาที
    },
  }
  try {
    const res = yield call(api.post, api.POST_PAYMENT_CONFIRM, body, apiOptions)
    log('After api fetch confirmPayment Response: ', res)
    yield handleResponseFromMakePaymentDecision(res)
    // // ถ้าสำเร็จหลังบ้านจะส่งกลับมา 1 payment นั้นๆ
    // if (res) {
    //   yield put(paymentActions.shouldFetchPaymentList())
    //   if (refreshOrderDetail) {
    //     yield put(orderActions.shouldFetchOrderDetail())
    //   }
    // }
    // yield put({ type: actions.STORE_ORDERS_SHOULD_FETCH_ALL }) // Refresh Order List
    // yield put({ type: actions.STORE_ORDERS_SHOULD_FETCH_SINGLE_TAB_KEY, payload: 'myTasks_confirmGettingPaid' })
    // yield put({ type: actions.SELECTED_STORE_SHOULD_FETCH }) // StoreMyView + Donut
    //
    // // then notify payment success
    // //
    // // let state = store.getState()
    // // let listReqPayload = getPaymentListReqPayload(state)
    // // log('reloading the payment list with listReqPayload')
    // // log(listReqPayload)
    // // if (listReqPayload && listReqPayload.store_id && listReqPayload.payment_account_id) {
    // //   log('calling fetchPayment list')
    // //   yield call(fetchPaymentList, listReqPayload)
    // // }
    if (_.isFunction(successCallback)) {
      successCallback(res)
    }
  } catch (error) {
    log('After api fetch fetchPayment error: ', error)
    if (_.isFunction(failedCallback)) {
      failedCallback(error)
    }
  }
}

function* handleResponseFromMakePaymentDecision(res) {
  if (res && res.payment) {
    yield put(paymentActions.loadNewPaymentToPaymentList(res.payment))
  }
}

function* watchCancelPayment() {
  log('In watchConfirmPayment')
  yield takeEvery(paymentActions.PAYMENT_CANCEL, cancelPayment)
}

function* cancelPayment(action: ActionApiParams) {
  const { body, successCallback, failedCallback } = action
  log('Before api cancelPayment payload: ', body)
  const apiOptions = {
    showSpinner: false,
    axiosOptions: {
      retry: 0,
      timeout: 30000, // long fetch 30 วินาที
    },
  }
  try {
    const res = yield call(api.post, api.POST_PAYMENT_CANCEL, body, apiOptions)
    log('After api fetch cancelPayment Response: ', res)
    yield handleResponseFromMakePaymentDecision(res)
    if (_.isFunction(successCallback)) {
      successCallback(res)
    }
  } catch (error) {
    log('After api fetch fetchPayment error: ', error)
    if (_.isFunction(failedCallback)) {
      failedCallback(error)
    }
  }
}
