const moment = require('moment')
require('moment/locale/th')
require('moment-timezone')
moment.locale('th')

export function genRandomFileNameWithExtension(fileExtension: string): string[] {
  return [getTimeString(), `${getRandomString()}.${fileExtension}`]
  // [filename, hash.extension]
}

export function genRandomFileName(): string {
  return getTimeString() + getRandomString()
}

export function getTimeString(): string {
  const dateObj = new Date()
  const month = dateObj.getUTCMonth() + 1 // months from 1-12
  const day = dateObj.getUTCDate()
  const year = dateObj.getUTCFullYear()
  const hrs = dateObj.getHours()
  const min = dateObj.getMinutes()
  return year + _prefixZero(month) + _prefixZero(day) + _prefixZero(hrs) + _prefixZero(min)
}

export function getRandomString() {
  return _randomString(10, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ')
}

function _prefixZero(num: number): string {
  if (num < 10) {
    return '0' + num
  } else {
    return num.toString()
  }
}

function _randomString(length: number, chars: string): string {
  let result = ''
  for (let i = length; i > 0; --i) {
    result += chars[Math.floor(Math.random() * chars.length)]
  }
  return result
}

export function getCreateToken(): string {
  const txtRandStr = _randomString(6, 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ')

  // https://stackoverflow.com/questions/3066586/get-string-in-yyyymmdd-format-from-js-date-object
  // Date.prototype.getFromFormat = function(format) {
  //   var yyyy = this.getFullYear().toString();
  //   format = format.replace(/yyyy/g, yyyy)
  //   var mm = (this.getMonth()+1).toString();
  //   format = format.replace(/mm/g, (mm[1]?mm:"0"+mm[0]));
  //   var dd  = this.getDate().toString();
  //   format = format.replace(/dd/g, (dd[1]?dd:"0"+dd[0]));
  //   var hh = this.getHours().toString();
  //   format = format.replace(/hh/g, (hh[1]?hh:"0"+hh[0]));
  //   var ii = this.getMinutes().toString();
  //   format = format.replace(/ii/g, (ii[1]?ii:"0"+ii[0]));
  //   var ss  = this.getSeconds().toString();
  //   format = format.replace(/ss/g, (ss[1]?ss:"0"+ss[0]));
  //   return format;
  // };
  //
  // const now = new Date()
  // let txtNow = now.getFromFormat('yyyymmddhhiiss')
  const now = moment()
  const txtNow = now.format('YYYYMMDDHHmmss')

  return txtNow + txtRandStr
}

export function getCreateTokenNoLowercase(): string {
  const txtRandStr = _randomString(6, '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ')

  // https://stackoverflow.com/questions/3066586/get-string-in-yyyymmdd-format-from-js-date-object
  // Date.prototype.getFromFormat = function(format) {
  //   var yyyy = this.getFullYear().toString();
  //   format = format.replace(/yyyy/g, yyyy)
  //   var mm = (this.getMonth()+1).toString();
  //   format = format.replace(/mm/g, (mm[1]?mm:"0"+mm[0]));
  //   var dd  = this.getDate().toString();
  //   format = format.replace(/dd/g, (dd[1]?dd:"0"+dd[0]));
  //   var hh = this.getHours().toString();
  //   format = format.replace(/hh/g, (hh[1]?hh:"0"+hh[0]));
  //   var ii = this.getMinutes().toString();
  //   format = format.replace(/ii/g, (ii[1]?ii:"0"+ii[0]));
  //   var ss  = this.getSeconds().toString();
  //   format = format.replace(/ss/g, (ss[1]?ss:"0"+ss[0]));
  //   return format;
  // };
  //
  // const now = new Date()
  // let txtNow = now.getFromFormat('yyyymmddhhiiss')
  const now = moment()
  const txtNow = now.format('YYYYMMDDHHmmss')

  return txtNow + txtRandStr
}
