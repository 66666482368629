import BaseUIAddressListView from 'xui/modules/address/BaseUIAddressListView'

export default class AddressListView extends BaseUIAddressListView {
  static navigationOptions = {
    header: null,
    title: null,
    headerLeft: null,
    headerRight: null,
  }
}
