// import { connect } from 'react-redux'
// import PaymentView from './PaymentView'
import { bindActionCreators } from 'redux'
import * as PaymentActions from 'x/modules/payment/PaymentState'
import * as PaymentOrderListActions from 'x/modules/orderlist/PaymentOrderListState'
import * as BankActions from '../bank/BankState'
import * as orderActions from '../order/OrderState'
import { getOrdersToBePaid, getStorePaymentAccounts, getStoreSelected } from '../../redux/selectors'

export function getState(state) {
  return {
    selectedPayment: state.getIn(['payment', 'selectedPayment']),
    editingPayment: state.getIn(['payment', 'editingPayment']),
    img_uris: state.getIn(['imgBlockTasks', 'img_uris']),
    thumbnail_uris: state.getIn(['imgBlockTasks', 'thumbnail_uris']),
    uploadStatus: state.getIn(['imgBlockTasks', 'taskStatus']),
    ordersToBePaid: getOrdersToBePaid(state),

    paymentAccounts: getStorePaymentAccounts(state),
    selectedStore: getStoreSelected(state),
  }
}

export function getDispatch(dispatch) {
  return {
    // paymentActions: bindActionCreators(PaymentActions, dispatch),
    onPaymentChange: bindActionCreators(PaymentActions.onChange, dispatch),
    submitPayment: bindActionCreators(PaymentActions.submitPayment, dispatch),
    addOrder: bindActionCreators(PaymentActions.addOrder, dispatch),
    removeOrder: bindActionCreators(PaymentActions.removeOrder, dispatch),
    requestQRCode: bindActionCreators(PaymentActions.requestQRCode, dispatch),

    fetchStorePaymentAccounts: bindActionCreators(BankActions.fetchStorePaymentAccounts, dispatch),
    selectSellerPaymentAccount: bindActionCreators(PaymentActions.selectSellerPaymentAccount, dispatch),
    shouldFetchOrderDetail: bindActionCreators(orderActions.shouldFetchOrderDetail, dispatch),
    resetPaymentOrderList: bindActionCreators(PaymentOrderListActions.resetState, dispatch),
  }
}
