import React from 'react'
import _ from 'lodash'
import { diff } from 'deep-object-diff'

import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import XCustomHeader from 'xui/components/XCustomHeader'
import VStack from 'xui/components/VStack'
import XCard from 'xui/components/XCard'
import XBarChart from 'xui/components/XBarChart'
// import OrderListFilterButton from 'xui/components/orders/OrderListFilterButton'

import { IDailySaleChartViewProps, IDailySaleChartViewState, IDailySalesDataItem, IDateRange, IOrdersFilter, IXBarChartData } from 'x/types'
import CONS from 'x/config/constants'
import * as util from 'x/utils/util'
import * as api from 'x/utils/api'
import * as xFmt from 'x/utils/formatter'
import * as NavActions from 'x/utils/navigation'

import theme from 'x/config/nativebase/theme'
import HStack from 'xui/components/HStack'
import XIcon from 'xui/components/XIcon'
import XText from 'xui/components/XText'
import Box from 'xui/components/Box'

// import BaseUIChartView from './BaseUIChartView'

export default class DailySaleChartView extends React.Component<IDailySaleChartViewProps, IDailySaleChartViewState> {
  static displayName = 'DailySaleChartView'

  constructor(props) {
    super(props)

    const defaultCreatedDateRangeKey = CONS.DATERANGE_SELECTOR.OPTION.Last30Days
    const dr = util.getDateRangeFromOption(defaultCreatedDateRangeKey)
    const defaultFilterSetting = {
      createOrderDateRangeOptionKey: defaultCreatedDateRangeKey,
      createOrderFrom: dr.begin,
      createOrderTo: dr.end,
    }

    this.state = {
      amountData: [],
      qtyData: [],
      orderData: [],
      profitData: [],

      dailySalesData: [],
      defaultFilterSetting,
      filterSetting: defaultFilterSetting,
      isInitialized: false,
    }
  }

  async componentDidMount(): Promise<void> {
    await this._initCharts()
  }

  _initCharts = async () => {
    const dailySalesData = await this._fetchChartData()
    const amountData = this._generateChartDataByDate(dailySalesData, 'amount')
    const profitData = this._generateChartDataByDate(dailySalesData, 'profit')
    const qtyData = this._generateChartDataByDate(dailySalesData, 'qty')
    const orderData = this._generateChartDataByDate(dailySalesData, 'order')
    // console.log('_initCharts amountData => ', amountData)
    // console.log('_initCharts qtyData => ', qtyData)
    // console.log('_initCharts orderData => ', orderData)
    // console.log('_initCharts dailySalesData => ', dailySalesData)
    // console.log('_initCharts profitData => ', profitData)

    await util.delay(150)
    await util.setStatePromise(this, { dailySalesData, amountData, qtyData, orderData, profitData, isInitialized: true })
  }

  _fetchChartData = async () => {
    const { filterSetting = {} } = this.state
    const store_id = util.getNavParam(this.props, 'store_id')
    try {
      const usageSetting = _.cloneDeep(filterSetting)

      if (this.isExceedLimitDays()) {
        let dr: IDateRange
        if (usageSetting.createOrderDateRangeOptionKey !== 'Custom') {
          dr = util.getDateRangeFromOption(usageSetting.createOrderDateRangeOptionKey)
          dr.begin = dr.end.subtract(44, 'day')
          usageSetting.createOrderDateRangeOptionKey = 'Custom'
          usageSetting.createOrderFrom = dr.begin.startOf('day')
          usageSetting.createOrderTo = dr.end.endOf('day')
        } else {
          usageSetting.createOrderDateRangeOptionKey = 'Last45Days'
        }
      }

      const translatedOrderFilter = util.translateOrdersFilterToRequestBody(usageSetting)
      // console.log('onApplyOrderListFilter translatedOrderFilter => ', translatedOrderFilter)

      const data = await api.fetchDailySalesData({ store_id, ...translatedOrderFilter })
      // console.log('_fetchChartData data => ', data)
      return data
    } catch (err) {
      console.log('_fetchChartData err => ', err)
    }
  }

  _generateChartDataByDate = (dsd: IDailySalesDataItem[], fieldName: keyof IDailySalesDataItem): IXBarChartData[] => {
    try {
      // @ts-ignore
      const d: IXBarChartData[] = dsd.map((ds) => ({ x: ds.date.toString(), y: ds[fieldName] }))
      return d
    } catch (err) {
      return []
    }
  }

  _navToOrderListFilterView = () => {
    const { navigation } = this.props
    const { defaultFilterSetting, filterSetting } = this.state

    navigation.dispatch(
      NavActions.navToOrderListFilterView({
        store_id: util.getNavParam(this.props, 'store_id'),
        defaultSetting: defaultFilterSetting,
        appliedSetting: filterSetting,
        onApplyFilter: this.onApplyOrderListFilter,
        allowDateRangeLimitDays: 45,
      })
    )
  }

  isFiltered = () => {
    const { defaultFilterSetting, filterSetting } = this.state
    const diffFilter = diff(defaultFilterSetting, filterSetting)
    return diffFilter && !_.isEmpty(diffFilter)
  }

  renderFilterBigButton = () => {
    // const { defaultFilterSetting, filterSetting } = this.state

    // return (
    //   <OrderListFilterButton
    //     // forceMkpOnly={this.isForceMkpOnly()}
    //     // defaultSetting={ordersDefaultFilterSetting}
    //     defaultSetting={defaultFilterSetting}
    //     appliedSetting={filterSetting}
    //     onApplyFilter={this.onApplyOrderListFilter}
    //     allowDateRangeLimitDays={45}
    //     renderButton={this._renderCustomFilterButton}
    //   />
    // )
    const isFiltered = this.isFiltered()

    // return <FilterButton isFiltered={isFiltered} onPress={this._navToOrderListFilterView} />
    return this._renderCustomFilterButton(this._navToOrderListFilterView, isFiltered)
  }

  getSumData = (d: IXBarChartData[]) => {
    try {
      // @ts-ignore
      const ds = d.map((di) => parseFloat(di.y))
      const dCount = ds.length
      let sum = 0
      for (let i = 0; i < dCount; i++) {
        sum += ds[i]
      }
      return sum
    } catch (error) {
      return 0
    }
  }

  getAverageData = (d: IXBarChartData[]) => {
    try {
      const dCount = d.length
      const sum = this.getSumData(d)
      return sum / dCount
    } catch (error) {
      return 0
    }
  }

  getMinData = (d: IXBarChartData[]) => {
    try {
      // @ts-ignore
      const ds = d.map((di) => parseFloat(di.y))
      const min = Math.min(...ds)
      return min
    } catch (error) {
      return 0
    }
  }

  getMaxData = (d: IXBarChartData[]) => {
    try {
      // @ts-ignore
      const ds = d.map((di) => parseFloat(di.y))
      return Math.max(...ds)
    } catch (error) {
      return 0
    }
  }

  getUserChoiceCreateOrderDateRange = (): IDateRange => {
    const { filterSetting } = this.state
    const { createOrderDateRangeOptionKey, createOrderFrom, createOrderTo } = filterSetting

    let dr
    if (createOrderDateRangeOptionKey !== 'Custom') {
      dr = util.getDateRangeFromOption(createOrderDateRangeOptionKey)
    } else {
      dr = { begin: createOrderFrom, end: createOrderTo }
    }

    if (!dr || !dr.begin || !dr.end) {
      dr = util.getDateRangeFromOption('Last30Days')
    }
    return dr
  }

  getUsageCreateOrderDateRange = (): IDateRange => {
    const { filterSetting } = this.state
    const { createOrderDateRangeOptionKey, createOrderFrom, createOrderTo } = filterSetting

    if (!this.isExceedLimitDays()) {
      return this.getUserChoiceCreateOrderDateRange()
    }

    let dr: IDateRange
    if (createOrderDateRangeOptionKey !== 'Custom') {
      dr = util.getDateRangeFromOption(createOrderDateRangeOptionKey)
      dr.begin = dr.end.subtract(44, 'day').startOf('day')
    } else {
      dr = util.getDateRangeFromOption('Last45Days')
    }

    if (!dr || !dr.begin || !dr.end) {
      dr = util.getDateRangeFromOption('Last30Days')
    }
    return dr
  }

  getCreateOrderDays = () => {
    try {
      const dr = this.getUserChoiceCreateOrderDateRange()
      return dr.end.add(1, 'second').diff(dr.begin, 'days')
    } catch (error) {
      return 0
    }
  }

  getUsageCreateOrderDays = () => {
    if (!this.isExceedLimitDays()) {
      return this.getCreateOrderDays()
    }

    return 45
  }

  isExceedLimitDays = () => this.getCreateOrderDays() > 45

  _renderCustomFilterButton = (onPressBtn, isFiltered) => {
    // const { defaultFilterSetting, filterSetting } = this.state
    // const {  createOrderFrom, createOrderTo } = filterSetting

    const dr = this.getUsageCreateOrderDateRange()

    return (
      <XCard onPress={onPressBtn}>
        <VStack w='full' px='3' py='2' space='1' alignItems='center'>
          <XText variant='inactive'>ข้อมูลการขาย</XText>

          <HStack w='full' justifyContent='space-between'>
            <XText variant='inactive'>รายงานแสดงตามวันที่:</XText>
            <XText>สร้างออเดอร์</XText>
          </HStack>
          <HStack w='full' justifyContent='space-between'>
            <XText variant='inactive'>ตั้งแต่:</XText>
            <XText>{dr.begin.format(CONS.SERVER_DATETIME_FORMAT)}</XText>
          </HStack>
          <HStack w='full' justifyContent='space-between'>
            <XText variant='inactive'>จนถึง:</XText>
            <XText>{dr.end.format(CONS.SERVER_DATETIME_FORMAT)}</XText>
          </HStack>
          {/* <HStack w='full' justifyContent='space-between'>
            <XText variant='inactive'>ระยะเวลา:</XText>
            <XText>{`${this.getCreateOrderDays()} วัน`}</XText>
          </HStack> */}
          <Box position='absolute' top='8px' right='8px'>
            <XIcon family='MaterialCommunityIcons' name='filter' />
            {isFiltered ? (
              <XIcon
                family='MaterialCommunityIcons'
                variant='success'
                name='check-circle'
                position='absolute'
                bottom='4px'
                right='4px'
                size='12px'
              />
            ) : null}
          </Box>
        </VStack>
      </XCard>
    )
  }

  onApplyOrderListFilter = async (newSetting: IOrdersFilter) => {
    const { filterSetting: oldSetting } = this.state

    // console.log('onApplyOrderListFilter newSetting => ', newSetting)

    const diffSetting = diff(oldSetting, newSetting)

    // console.log('onApplyOrderListFilter diffSetting => ', diffSetting)
    if (!_.isEmpty(diffSetting)) {
      await util.setStatePromise(this, { filterSetting: newSetting, isInitialized: false })
      // await this.clearOrdersAllTabs()
      await util.delay(200)
      await this._initCharts()
      // await this.loadInitOrders(tabName)
    }

    return true
  }

  _renderBox = (label: string, value: string, valueColor: string) => (
    <VStack flex={1} alignItems='center' justifyContent='space-between'>
      <XText fontSize='md' color={valueColor || 'black'} bold>
        {value}
      </XText>

      <XText fontSize='sm' textAlign='center'>
        {label}
      </XText>
    </VStack>
  )

  renderExceedLimitDaysWarning = () => {
    const userChoiceDays = this.getCreateOrderDays()
    return (
      <HStack w='full' px='2' alignItems='center' justifyContent='center' bg='gray.100'>
        <XText
          variant='inactive'
          textAlign='center'>{`ท่านได้ตั้งค่าไว้ ${userChoiceDays} วัน\nแต่ระบบสามารถแสดงข้อมูลตามวันสร้างออเดอร์ ได้สูงสุด 45 วัน`}</XText>
      </HStack>
    )
  }

  renderSummaryData = () => {
    const { isInitialized, amountData, qtyData, orderData, profitData } = this.state

    if (!isInitialized) {
      return null
    }

    return (
      <XCard>
        <VStack w='full' p='2' space='1'>
          <HStack w='full' alignItems='center' justifyContent='center'>
            <XText bold>{`ยอดรวม ${this.getUsageCreateOrderDays()} วัน`}</XText>
          </HStack>
          {this.isExceedLimitDays() ? this.renderExceedLimitDaysWarning() : null}
          {/* <HStack w='full' space='0.5'>
            {this._renderBox('ยอดขาย', xFmt.formatCurrency(Math.round(this.getSumData(amountData))), 'blue.500')}
            <Box borderLeftWidth='1' borderLeftColor='gray.400' />
            {this._renderBox('จำนวนออเดอร์', xFmt.formatDecimal(Math.round(this.getSumData(orderData))), 'primary.500')}
            <Box borderLeftWidth='1' borderLeftColor='gray.400' />
            {this._renderBox('จำนวนสินค้า', xFmt.formatDecimal(Math.round(this.getSumData(qtyData))), 'yellow.700')}
          </HStack> */}
          <HStack w='full' space='0.5'>
            {this._renderBox('ยอดขาย', xFmt.formatCurrency(Math.round(this.getSumData(amountData))), 'blue.500')}
            <Box borderLeftWidth='1' borderLeftColor='gray.200' />
            {this._renderBox('กำไร', xFmt.formatCurrency(Math.round(this.getSumData(profitData))), 'success.500')}
          </HStack>
          <HStack w='full' space='0.5' px='2'>
            <Box w='full' borderBottomWidth='1' borderBottomColor='gray.200' />
          </HStack>
          <HStack w='full' space='0.5'>
            {this._renderBox('จำนวนออเดอร์', xFmt.formatDecimal(Math.round(this.getSumData(orderData))), 'primary.500')}
            <Box borderLeftWidth='1' borderLeftColor='gray.200' />
            {this._renderBox('จำนวนสินค้า', xFmt.formatDecimal(Math.round(this.getSumData(qtyData))), 'yellow.700')}
          </HStack>
        </VStack>
      </XCard>
    )
  }

  renderAmountLineChart = () => {
    const { amountData, isInitialized } = this.state

    if (!isInitialized) {
      return null
    }

    return (
      <XCard>
        <VStack w='full'>
          <XBarChart title='ยอดขาย' labelY='บาท' data={amountData} lineColor={theme.colors.blue[500]} tickFormatY={xFmt.formatDecimal} />
          <VStack w='full' px='3' py='1'>
            <HStack w='full' justifyContent='space-between'>
              <XText variant='inactive'>{`รวม (${this.getUsageCreateOrderDays()} วัน):`}</XText>
              <XText textAlign='right'>{`${xFmt.formatCurrency(this.getSumData(amountData), 2)}`}</XText>
            </HStack>
            <HStack w='full' justifyContent='space-between'>
              <XText variant='inactive'>เฉลี่ย:</XText>
              <XText textAlign='right'>{`${xFmt.formatCurrency(this.getAverageData(amountData), 2)}`}</XText>
            </HStack>
            <HStack w='full' justifyContent='space-between'>
              <XText variant='inactive'>สูงสุด:</XText>
              <XText textAlign='right'>{`${xFmt.formatCurrency(this.getMaxData(amountData), 2)}`}</XText>
            </HStack>
            <HStack w='full' justifyContent='space-between'>
              <XText variant='inactive'>ต่ำสุด:</XText>
              <XText textAlign='right'>{`${xFmt.formatCurrency(this.getMinData(amountData), 2)}`}</XText>
            </HStack>
            {/* <HStack w='full' justifyContent='space-between'>
              <XText variant='inactive'>กำไร:</XText>
              <XText textAlign='right'>{`${xFmt.formatCurrency(this.getSumData(profitData), 2)}`}</XText>
            </HStack> */}
          </VStack>
        </VStack>
      </XCard>
    )
  }

  renderProfitLineChart = () => {
    const { profitData, isInitialized } = this.state

    if (!isInitialized) {
      return null
    }

    return (
      <XCard>
        <VStack w='full'>
          <XBarChart title='กำไร' labelY='บาท' data={profitData} lineColor={theme.colors.success[500]} tickFormatY={xFmt.formatDecimal} />
          <VStack w='full' px='3' py='1'>
            <HStack w='full' justifyContent='space-between'>
              <XText variant='inactive'>{`รวม (${this.getUsageCreateOrderDays()} วัน):`}</XText>
              <XText textAlign='right'>{`${xFmt.formatCurrency(this.getSumData(profitData), 2)}`}</XText>
            </HStack>
            <HStack w='full' justifyContent='space-between'>
              <XText variant='inactive'>เฉลี่ย:</XText>
              <XText textAlign='right'>{`${xFmt.formatCurrency(this.getAverageData(profitData), 2)}`}</XText>
            </HStack>
            <HStack w='full' justifyContent='space-between'>
              <XText variant='inactive'>สูงสุด:</XText>
              <XText textAlign='right'>{`${xFmt.formatCurrency(this.getMaxData(profitData), 2)}`}</XText>
            </HStack>
            <HStack w='full' justifyContent='space-between'>
              <XText variant='inactive'>ต่ำสุด:</XText>
              <XText textAlign='right'>{`${xFmt.formatCurrency(this.getMinData(profitData), 2)}`}</XText>
            </HStack>
          </VStack>
        </VStack>
      </XCard>
    )
  }

  renderQtyLineChart = () => {
    const { qtyData, isInitialized } = this.state

    if (!isInitialized) {
      return null
    }

    return (
      <XCard>
        <VStack w='full'>
          <XBarChart
            title='จำนวนสินค้า'
            labelY='ชิ้น'
            data={qtyData}
            lineColor={theme.colors.yellow[700]}
            tickFormatY={xFmt.formatDecimal}
          />
          <VStack w='full' px='3' py='1'>
            <HStack w='full' justifyContent='space-between'>
              <XText variant='inactive'>{`รวม (${this.getUsageCreateOrderDays()} วัน):`}</XText>
              <XText textAlign='right'>{`${xFmt.formatDecimal(this.getSumData(qtyData))}`}</XText>
            </HStack>
            <HStack w='full' justifyContent='space-between'>
              <XText variant='inactive'>เฉลี่ย:</XText>
              <XText textAlign='right'>{`${xFmt.formatDecimal(this.getAverageData(qtyData))}`}</XText>
            </HStack>
            <HStack w='full' justifyContent='space-between'>
              <XText variant='inactive'>สูงสุด:</XText>
              <XText textAlign='right'>{`${xFmt.formatDecimal(this.getMaxData(qtyData))}`}</XText>
            </HStack>
            <HStack w='full' justifyContent='space-between'>
              <XText variant='inactive'>ต่ำสุด:</XText>
              <XText textAlign='right'>{`${xFmt.formatDecimal(this.getMinData(qtyData))}`}</XText>
            </HStack>
          </VStack>
        </VStack>
      </XCard>
    )
  }

  renderOrderLineChart = () => {
    const { orderData, isInitialized } = this.state

    if (!isInitialized) {
      return null
    }

    return (
      <XCard>
        <VStack w='full'>
          <XBarChart
            title='จำนวนออเดอร์'
            labelY='ออเดอร์'
            data={orderData}
            lineColor={theme.colors.primary[500]}
            tickFormatY={xFmt.formatDecimal}
          />
          <VStack w='full' px='3' py='1'>
            <HStack w='full' justifyContent='space-between'>
              <XText variant='inactive'>{`รวม (${this.getUsageCreateOrderDays()} วัน):`}</XText>
              <XText textAlign='right'>{`${xFmt.formatDecimal(this.getSumData(orderData))}`}</XText>
            </HStack>
            <HStack w='full' justifyContent='space-between'>
              <XText variant='inactive'>เฉลี่ย:</XText>
              <XText textAlign='right'>{`${xFmt.formatDecimal(this.getAverageData(orderData))}`}</XText>
            </HStack>
            <HStack w='full' justifyContent='space-between'>
              <XText variant='inactive'>สูงสุด:</XText>
              <XText textAlign='right'>{`${xFmt.formatDecimal(this.getMaxData(orderData))}`}</XText>
            </HStack>
            <HStack w='full' justifyContent='space-between'>
              <XText variant='inactive'>ต่ำสุด:</XText>
              <XText textAlign='right'>{`${xFmt.formatDecimal(this.getMinData(orderData))}`}</XText>
            </HStack>
          </VStack>
        </VStack>
      </XCard>
    )
  }

  renderContent = () => {
    const { isInitialized } = this.state

    return (
      <XContent>
        <VStack w='full' p='1'>
          {/* <XCard> */}
          <VStack w='full' p='2' space='2'>
            {this.renderFilterBigButton()}
            {this.renderSummaryData()}
            {this.renderAmountLineChart()}
            {this.renderProfitLineChart()}
            {this.renderOrderLineChart()}
            {this.renderQtyLineChart()}
          </VStack>
          {/* </XCard> */}
        </VStack>
      </XContent>
    )
  }

  render() {
    return (
      <XContainer>
        <XCustomHeader title='ยอดประจำวัน' headerLeftProps={{ backIcon: true, onPressItem: () => util.navGoBack(this.props) }} />
        {this.renderContent()}
      </XContainer>
    )
  }
}
