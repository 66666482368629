import React, { useState } from 'react'
import { COLORS } from 'x/config/styles'
import { View, TouchableOpacity, ScrollView } from 'react-native'
import _ from 'lodash'
import HStack from 'xui/components/HStack'
import Box from 'xui/components/Box'
import XIcon from './XIcon'
import XModal from './XModal'
import XText from './XText'

interface IXBankAccountTypeModel {
  onClose?: () => void
  renderButton: (onPressOpenOverlay: () => void) => JSX.Element
  headerTitle: string
  onSelected: (bank_type: string) => void
}

const BANK_ACCOUNT_TYPE = ['CurrentAccount', 'SavingAccount', 'FixedDeposit']

const XBankAccountTypeModel: React.FC<IXBankAccountTypeModel> = ({ onClose, renderButton, headerTitle, onSelected }) => {
  const [isOpenModal, setOpenModal] = useState<boolean>(false)
  // const [selectedOpenAnswersListIndex, setSelectedOpenAnswersListIndex] = useState<number[]>(
  //   _.isNil(initiateOpenIndex) ? [] : [initiateOpenIndex]
  // )

  // useEffect(() => {
  //   // console.log('value  ?? ', value)
  //   setValue(value)
  // }, [value])

  const openModal = () => {
    setOpenModal(true)
  }

  const closeModal = () => {
    setOpenModal(false)
    if (_.isFunction(onClose)) {
      onClose()
    }
  }

  const renderLeftHeader = () => (
    <TouchableOpacity
      onPress={() => closeModal()}
      style={{ width: 50, height: 50, justifyContent: 'center', alignItems: 'center', alignContent: 'center', marginLeft: -10 }}>
      <XIcon
        name='arrowleft'
        family='AntDesign'
        style={{ color: COLORS.APP_MAIN, minWidth: 25, width: 25, textAlign: 'center', flex: 0 }}
      />
    </TouchableOpacity>
  )

  const renderModal = () => {
    if (!isOpenModal) {
      return null
    }
    return (
      <XModal
        title={headerTitle}
        headerLeft={() => renderLeftHeader()}
        onRequestClose={() => closeModal()}
        visible={isOpenModal}
        webWrapperContainerStyle={{ backgroundColor: 'white' }}
        webNumColumns={1}>
        <ScrollView>
          {BANK_ACCOUNT_TYPE.map((bankAccountType: string) => {
            let name = 'บัญชีกระแสรายวัน'
            if (bankAccountType === 'SavingAccount') {
              name = 'บัญชีออมทรัพย์'
            }
            if (bankAccountType === 'FixedDeposit') {
              name = 'บัญชีเงินฝากประจำ'
            }
            return (
              <TouchableOpacity
                onPress={() => {
                  onSelected(bankAccountType)
                  closeModal()
                }}
                key={bankAccountType}>
                <HStack h='12' borderBottomColor='gray.200' borderBottomWidth='0.5'>
                  <Box flex={1} pl='4' justifyContent='center'>
                    <XText variant='active'>{name}</XText>
                  </Box>
                  <Box w='10' justifyContent='center'>
                    <XIcon name='arrowright' family='AntDesign' />
                  </Box>
                </HStack>
              </TouchableOpacity>
            )
          })}
        </ScrollView>
      </XModal>
    )
  }

  return (
    <View>
      {renderButton(openModal)}
      {renderModal()}
    </View>
  )
}

export default XBankAccountTypeModel
