import React from 'react'
import _ from 'lodash'

import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'
import { isImmutable } from 'immutable'

import { IProductGroup, ActionApiParams, IXScreenProps } from 'x/index'

export interface BaseStoreSettingPeakViewProps extends IXScreenProps {
  selectedStore: any
  setEditingStoreGoApi: (params: ActionApiParams) => void
  addStoreSettingGoApi: (params: ActionApiParams) => void
}

export interface BaseStoreSettingPeakViewState {
  selectedProductGroup: IProductGroup | null
  productGroups: { label: string; value: number }[]
  mode: 'VIEW' | 'EDIT' | 'START'
  inputAccountShippingText: string
  inputOtherAccountText: string
  vatTypeIndex: number
  vatValue: string
  issueReceipt: boolean
  selectedProductGroupIndex: number

  isLoading: boolean
  loadingWidth: number
  loadingHight: number
}

export default abstract class BaseStoreSettingPeakView extends React.Component<
  BaseStoreSettingPeakViewProps,
  BaseStoreSettingPeakViewState
> {
  // DISPLAY_TABS: IUserListTab[]
  // _tabRef: React.RefObject<any>
  // helperProfileId: number

  protected constructor(props) {
    super(props)

    this.state = {
      selectedProductGroup: null,
      productGroups: null,
      mode: 'START',
      inputAccountShippingText: '',
      inputOtherAccountText: '',
      vatTypeIndex: 0,
      vatValue: '7',
      issueReceipt: true,
      selectedProductGroupIndex: 0,

      isLoading: false,
      loadingWidth: 0,
      loadingHight: 0,
    }
    // this._tabRef = React.createRef()
  }

  async componentDidMount() {
    // const { navigation, selectedStore } = this.props
    // const { state } = navigation
    // const { tabIndex, permissionList, permission_id } = state.params
    await this.setStartProductGroup()
    await this.setStartMode()
  }

  setStartMode = async () => {
    const { selectedStore } = this.props
    if (selectedStore.has('store_setting') && !_.isNil(selectedStore.get('store_setting'))) {
      const storeSetting = selectedStore.get('store_setting').toJS()
      // console.log('storeSetting => ', storeSetting)
      if (storeSetting.peak_settings_json) {
        const peak = storeSetting.peak_settings_json
        // included|excluded|no_vat
        const vatType = peak.vat_type === 'no_vat' ? 2 : peak.vat_type === 'excluded' ? 1 : 0
        const productGroupList: IProductGroup[] = isImmutable(selectedStore.get('product_groups'))
          ? selectedStore.get('product_groups').toJS()
          : selectedStore.get('product_groups')
        let selectedProductGroup = null
        let selectedProductGroupIndex = 0
        productGroupList.map((product: IProductGroup, idx: number) => {
          if (product.id === peak.base_price_pg_id) {
            selectedProductGroup = product
            selectedProductGroupIndex = idx + 1
          }
        })
        await util.setStatePromise(this, {
          mode: 'VIEW',
          inputAccountShippingText: _.isNil(peak.account_num_shipping_fee) ? '' : peak.account_num_shipping_fee,
          inputOtherAccountText: _.isNil(peak.account_num_other_fee) ? '' : peak.account_num_other_fee,
          selectedProductGroup,
          vatTypeIndex: vatType,
          vatValue: _.isNil(peak.vat_percent) ? '' : peak.vat_percent,
          issueReceipt: peak.issue_receipt !== 0,
          selectedProductGroupIndex,
        })
      } else {
        await util.setStatePromise(this, {
          mode: 'START',
        })
      }
    } else {
      await util.setStatePromise(this, {
        mode: 'START',
      })
    }
  }

  setStartProductGroup = async () => {
    const { selectedStore } = this.props
    const productGroupList: IProductGroup[] = isImmutable(selectedStore.get('product_groups'))
      ? selectedStore.get('product_groups').toJS()
      : selectedStore.get('product_groups')

    // console.log('productGroupList => ', productGroupList)
    const productGroups: { label: string; value: number }[] = [
      {
        label: 'ไม่ระบุ',
        value: 0,
      },
    ]
    if (!_.isNil(productGroupList)) {
      productGroupList.map((pt: IProductGroup, idx: number) => {
        const product: { label: string; value: number } = {
          label: pt.name,
          value: idx + 1,
        }
        productGroups.push(product)
      })
    }
    await util.setStatePromise(this, {
      productGroups,
    })
  }

  _selectedProductGroup = async (opt: { label: string; value: number }, idx: number) => {
    // console.log('opt => ', opt)
    // console.log('idx => ', idx)
    if (this._isViewMode()) {
      return
    }
    const { selectedStore } = this.props
    const productGroupList: IProductGroup[] = isImmutable(selectedStore.get('product_groups'))
      ? selectedStore.get('product_groups').toJS()
      : selectedStore.get('product_groups')

    // console.log('productGroupList => ', productGroupList)

    const selectedProductGroup = idx === 0 ? null : productGroupList[idx - 1]
    // console.log('productGroupList[idx] => ', selectedProductGroup)
    await util.setStatePromise(this, {
      selectedProductGroup,
      selectedProductGroupIndex: idx,
    })
  }

  _isViewMode = () => this.state.mode === 'VIEW'

  startLoading = async () => {
    await util.setStatePromise(this, {
      isLoading: true,
    })
  }

  stopLoading = async () => {
    await util.setStatePromise(this, {
      isLoading: false,
    })
  }

  _saveBtn = async () => {
    const { setEditingStoreGoApi, selectedStore, addStoreSettingGoApi } = this.props
    const {
      isLoading,
      selectedProductGroup,
      issueReceipt,
      vatTypeIndex,
      vatValue,
      inputOtherAccountText,
      inputAccountShippingText,
    } = this.state
    if (isLoading) {
      return
    }
    await this.startLoading()
    // const productGroupList: IProductGroup[] = isImmutable(selectedStore.get('product_groups'))
    //   ? selectedStore.get('product_groups').toJS()
    //   : selectedStore.get('product_groups') included|excluded|no_vat
    // console.log('_saveBtn => ', selectedProductGroup)
    const selectedProductGroupId = _.isNil(selectedProductGroup) ? null : selectedProductGroup.id
    const vatType = vatTypeIndex === 0 ? 'included' : vatTypeIndex === 1 ? 'excluded' : 'no_vat'
    const body: { [key: string]: any } = {
      store_id: selectedStore.get('id'),
      peak_settings_json: {
        base_price_pg_id: selectedProductGroupId,
        issue_receipt: issueReceipt ? 1 : 0,
        vat_type: vatType,
        account_num_shipping_fee: inputAccountShippingText,
        account_num_other_fee: inputOtherAccountText,
      },
    }

    if (vatTypeIndex < 2) {
      if (vatValue === '') {
        p.op.showConfirmationOkOnly('', 'กรุณาระบุจำนวน VAT')
        await this.stopLoading()
        return
      }
      body.peak_settings_json.vat_percent = vatValue
    }

    if (selectedStore.has('store_setting') && !_.isNil(selectedStore.get('store_setting'))) {
      const res: { store: any } = await new Promise((submitted) => {
        setEditingStoreGoApi({
          body,
          successCallback: submitted,
          failedCallback: submitted,
        })
      })
      // console.log('res EDIT => ', res)
      // this.setStartMode()
      await this.stopLoading()
      p.op.showToast('แก้ไข Peak Account เรียบร้อยแล้ว', 'success')
      util.setStatePromise(this, {
        mode: 'VIEW',
      })
    } else {
      const res: { store: any } = await new Promise((submitted) => {
        addStoreSettingGoApi({
          body,
          successCallback: submitted,
          failedCallback: submitted,
        })
      })
      // console.log('res ADD => ', res)
      // this.setStartMode()
      await this.stopLoading()
      p.op.showToast('สร้าง Peak Account เรียบร้อยแล้ว', 'success')
      await util.setStatePromise(this, {
        mode: 'VIEW',
      })
    }
  }
}
