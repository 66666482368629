import { Map, List, fromJS } from 'immutable'
// import ordersInitState from '../orderlist/BaseOrderListState'
import * as ordersInitState from 'x/modules/orderlist/BaseOrderListState'
import _ from 'lodash'
import actions from '../../config/actions'
import {
  getOrdersToBePaidInPayment,
  setOrdersToBePaidInPayment,
  deleteOrderToBePaidInPayment,
  setTotalAmountInPayment,
  setSelectedPaymentListInPayment,
  setEditingPaymentInPayment,
  setPaymentListReqPayloadInPayment,
  setSellerPaymentAccountInPayment,
  getSelectedPaymentListInPayment,
} from '../../redux/selectors'
// import * as util from '../../utils/util'
import CONS from '../../config/constants'
import { ActionApiParams } from '../../index'

const moment = require('moment')
require('moment-timezone')

const initPayment = Map({
  store_id: null,
  payment_account_id: null,
  total_amount: null,
  img_uris: [],
  thumbnail_uris: [],
  orders: ordersInitState.initialState,
  post_date: moment().format(),
  note: null,
  ordersToBePaid: Map({}),
  navFromOrderId: null,
  seller_payment_account: null,
})

// Initial state
const initialState = Map({
  selectedPayment: initPayment,
  editingPayment: initPayment,
  selectedPaymentList: List([]),
  // selectedPaymentList: mockPaymentList,
  // selectedPaymentList: null,
  shouldFetchList: false,
})

// Actions
export const INITIALIZE_STATE = 'PaymentState/INITIALIZE'
export const PAYMENT_RESET = 'PaymentState/PAYMENT_RESET'

export const LIST_DO_FETCH = 'PaymentState/LIST_DO_FETCH'
export const LIST_LOADED = 'PaymentState/LIST_LOADED'

export const PAYMENT_LOAD = 'PaymentState/PAYMENT_LOAD'
export const PAYMENT_REVERT = 'PaymentState/PAYMENT_REVERT'
export const PAYMENT_CHANGE = 'PaymentState/PAYMENT_CHANGE'
export const PAYMENT_ADD_ORDER = 'PaymentState/PAYMENT_ADD_ORDER'
export const PAYMENT_REMOVE_ORDER = 'PaymentState/PAYMENT_REMOVE_ORDER'

export const PAYMENT_SUBMIT = 'PaymentState/PAYMENT_SUBMIT'
export const PAYMENT_CONFIRM = 'PaymentState/PAYMENT_CONFIRM'
export const PAYMENT_CANCEL = 'PaymentState/PAYMENT_CANCEL'
export const PAYMENT_LOAD_TO_PAYMENT_LIST = 'PaymentState/PAYMENT_LOAD_TO_PAYMENT_LIST'

export const PAYMENT_GBPAY_REQUEST_QR = 'PaymentState/PAYMENT_GBPAY_REQUEST_QR'

export const PAYMENT_ALREADY_FETCH_LIST = 'PaymentState/PAYMENT_ALREADY_FETCH_LIST'
export const PAYMENT_SHOULD_FETCH_LIST = 'PaymentState/PAYMENT_SHOULD_FETCH_LIST'

export const SELLER_PAYMENT_ACCOUNT_SELECTED = 'PaymentState/SELLER_PAYMENT_ACCOUNT_SELECTED'

// export const PAYMENT_INVALID = 'PaymentState/PAYMENT_INVALID/SOMETHING_ERROR'

// export function initializePaymentState() {
//   return {
//     type: INITIALIZE_STATE,
//   }
// }

export function selectSellerPaymentAccount(paymentAccount) {
  return {
    type: SELLER_PAYMENT_ACCOUNT_SELECTED,
    payload: paymentAccount,
  }
}

export function reset() {
  return {
    type: PAYMENT_RESET,
  }
}

// saga actions with api
// export function fetch(body, successCallback = null, failedCallback = null) {
//   return {
//     type: LIST_DO_FETCH,
//     payload: body,
//     successCallback,
//     failedCallback,
//   }
// }
export function fetchPaymentList(params: ActionApiParams) {
  return {
    type: LIST_DO_FETCH,
    ...params,
  }
}

// export function submit({ body, refreshOrderDetail, callback }) {
//   return {
//     type: PAYMENT_SUBMIT,
//     payload: { body, refreshOrderDetail, callback },
//   }
// }

export function submitPayment(params: ActionApiParams) {
  return {
    type: PAYMENT_SUBMIT,
    ...params,
  }
}

/// //// Initial Form ///////
export function load(payment) {
  return {
    type: PAYMENT_LOAD,
    payload: payment,
  }
}

/// //// editing payment /////
export function revert() {
  return {
    type: PAYMENT_REVERT,
  }
}

export function shouldFetchPaymentList() {
  return {
    type: PAYMENT_SHOULD_FETCH_LIST,
  }
}
export function alreadyFetchPaymentList() {
  return {
    type: PAYMENT_ALREADY_FETCH_LIST,
  }
}

export function onChange({ key, value, isCurrency }) {
  if (isCurrency) {
    value = value.replace('฿', '')
    value = value.replace(',', '')
  }
  return {
    type: PAYMENT_CHANGE,
    payload: { key, value },
  }
}

export function addOrder({ order_id, name, amount }) {
  return {
    type: PAYMENT_ADD_ORDER,
    payload: fromJS({ order_id, name, amount }),
  }
}

export function removeOrder(orderId) {
  return {
    type: PAYMENT_REMOVE_ORDER,
    payload: orderId,
  }
}

/// / confirm/reject payment
// export function confirmPayment({ store_id, payment_id, refreshOrderDetail }) {
export function confirmPayment(params: ActionApiParams) {
  // util.log('confirmPayment ' + store_id + ' - ' + payment_id)
  return {
    // payload: { body: { store_id, payment_id }, refreshOrderDetail },
    ...params,
    type: PAYMENT_CONFIRM,
  }
}

// export function requestQRCode({ body, successCallback, failedCallback }) {
export function requestQRCode(params: ActionApiParams) {
  // util.log('confirmPayment body => ', body)
  return {
    // payload: body,
    // successCallback,
    // failedCallback,
    ...params,
    type: PAYMENT_GBPAY_REQUEST_QR,
  }
}

// export function rejectPayment({ store_id, payment_id, refreshOrderDetail }) {
export function rejectPayment(params: ActionApiParams) {
  // util.log('rejectPayment ' + store_id + ' - ' + payment_id)
  if (!params || !params.body) {
    return {
      type: CONS.ACTION_NO_OP,
    }
  }
  const rejectParams = _.cloneDeep(params)
  rejectParams.body.reject = true

  return {
    // payload: { body: { store_id, payment_id, reject: true }, refreshOrderDetail },
    ...rejectParams,
    type: PAYMENT_CONFIRM,
  }
}

export function cancelPayment(params: ActionApiParams) {
  return {
    ...params,
    type: PAYMENT_CANCEL,
  }
}

export function loadNewPaymentToPaymentList(payment) {
  return {
    type: PAYMENT_LOAD_TO_PAYMENT_LIST,
    payload: payment,
  }
}

// Reducer
export default function PaymentStateReducer(state = initialState, action: any = {}) {
  const { type, payload } = action
  let newState = state
  switch (type) {
    case INITIALIZE_STATE:
    case PAYMENT_RESET:
      return initialState

    case PAYMENT_LOAD:
      newState = newState.set('selectedPayment', fromJS(payload))
      return setEditingPaymentInPayment(newState, newState.get('selectedPayment'))
    case LIST_LOADED: {
      newState = setPaymentListReqPayloadInPayment(newState, payload.listReqPayload)
      newState = newState.set('shouldFetchList', false)
      return setSelectedPaymentListInPayment(newState, fromJS(payload.payments))
    }
    case PAYMENT_REVERT:
      return setEditingPaymentInPayment(newState, newState.get('selectedPayment'))
    case PAYMENT_CHANGE:
      return newState.setIn(['editingPayment', payload.key], payload.value)
    // case PAYMENT_ADD_ORDER:
    //   return newState.updateIn(['editingPayment', 'orders'], orders => orders.push(payload))
    case PAYMENT_REMOVE_ORDER: {
      const s = deleteOrderToBePaidInPayment(newState, payload)
      return setTotalAmountInPayment(s, 0) // reset the total amount
    }
    case actions.ORDERS_TOGGLE_PAYMENT_SELECTION: {
      if (_.isNil(payload) || _.isNil(payload.orderId) || _.isNil(payload.remaining_forecast_amount)) {
        // Avoiding error when receive empty payload
        return state
      }
      // console.log('actions.ORDERS_TOGGLE_PAYMENT_SELECTION')
      const toBePaidOrders = getOrdersToBePaidInPayment(state)
      // let { orderId, total, isFirstItem } = payload
      const { orderId, remaining_forecast_amount, cod_amount } = payload
      let newToBePaidOrders
      // if (isFirstItem) {
      //   // reset the state
      //   toBePaidOrders = initPayment.get('orderToBePaid')
      // }

      // MUST Convert orderId to String before storing, so the later-comparison is not messed up
      const orderIdStr = String(orderId)
      if (toBePaidOrders.has(orderIdStr)) {
        if (Map.isMap(toBePaidOrders) && toBePaidOrders.size === 1) {
          // บังคับให้มี orderToBePaid อย่างน้อย 1 ตัว
          return state
        }
        newToBePaidOrders = toBePaidOrders.delete(orderIdStr)
      } else {
        // newToBePaidOrders = toBePaidOrders.set(orderIdStr, total)
        newToBePaidOrders = toBePaidOrders.set(orderIdStr, Map({ remaining_forecast_amount, cod_amount }))
      }
      const s = setOrdersToBePaidInPayment(state, newToBePaidOrders)
      return setTotalAmountInPayment(s, 0) // reset the total amount
      // util.log(newToBePaidOrders, newToBePaidOrders.toJS())
      // return setOrdersToBePaidInPayment(state, newToBePaidOrders)
    }
    case actions.ORDERS_TO_BE_PAID_INIT: {
      if (_.isNil(payload) || _.isNil(payload.orderId) || _.isNil(payload.remaining_forecast_amount)) {
        // Avoiding error when receive empty payload
        return state
      }

      // reset the date
      let p = initPayment.set('post_date', moment().format())
      // init with the specified order info
      // const { orderId, amount } = payload
      const { orderId, remaining_forecast_amount, cod_amount } = payload
      const newOrdersToBePaid = {}
      // newOrdersToBePaid[orderId] = amount
      newOrdersToBePaid[orderId] = Map({ remaining_forecast_amount, cod_amount })
      p = p.set('ordersToBePaid', Map(newOrdersToBePaid))

      // set the original order id, so we can refresh it later once successfully submitting payment
      p = p.set('navFromOrderId', orderId)

      return setEditingPaymentInPayment(newState, p)
    }
    case SELLER_PAYMENT_ACCOUNT_SELECTED: {
      return setSellerPaymentAccountInPayment(newState, fromJS(payload))
    }

    case PAYMENT_SHOULD_FETCH_LIST:
      return newState.set('shouldFetchList', true)
    case PAYMENT_ALREADY_FETCH_LIST:
      return newState.set('shouldFetchList', false)

    case PAYMENT_LOAD_TO_PAYMENT_LIST: {
      if (payload && payload.id) {
        let newPaymentList = getSelectedPaymentListInPayment(state)
        const foundPaymentIdx = newPaymentList.findIndex((pm) => pm.get('id') === payload.id)
        if (foundPaymentIdx > -1) {
          newPaymentList = newPaymentList.mergeIn([foundPaymentIdx], fromJS(payload))
          return setSelectedPaymentListInPayment(newState, newPaymentList)
        }
      }
      return state
    }

    default:
      return state
  }
}
