import { connect } from 'react-redux'
import ResellerManageView from './ResellerManageView'
// import { getState, getDispatch } from 'x/modules/order/BaseOrderViewConnect'
import { bindActionCreators } from 'redux'
import * as StoreActions from 'x/modules//store/StoreState'
import { getSelectedStore, getSelectedUserGroups, getLoadingInProgress } from 'x/redux/selectors'
import * as ResellerStateActions from 'x/modules/reseller/ResellerState'

export default connect(
  (state) => ({
    // ...getState(state),
    selectedStore: getSelectedStore(state),
    selectedUserGroups: getSelectedUserGroups(state),
  }),
  (dispatch) => {
    return {
      // ...getDispatch(dispatch),
      kickUser: bindActionCreators(ResellerStateActions.kickJoinedUserFromStore, dispatch),
    }
  }
)(ResellerManageView)
