import { useColorModeValue as mode } from 'native-base'

const L = {
  TEXT: {
    PRIMARY: 'primary.500',
    ACTIVE: 'coolGray.800',
    INACTIVE: 'muted.500',
    ACTIVE_DARK: 'coolGray.100',
    DANGER: 'danger.500',
    WARNING: 'warning.500',
    SUCCESS: 'success.500',
  },
  BG: {
    PRIMARY: 'primary.100',
    ACCENT: 'primary.300',
    CLEAR: 'white',
    DANGER: 'danger.200',
    WARNING: 'warning.200',
    SUCCESS: 'success.200',
    GREY: 'gray.500',
    LIGHT_GREY: 'coolGray.100',
    DARK_GREY: 'gray.700',
  },
}

const D = {
  TEXT: {
    PRIMARY: 'primary.500',
    ACTIVE: 'warmGray.50',
    INACTIVE: 'muted.300',
    ACTIVE_DARK: 'warmGray.50',
    DANGER: 'danger.200',
    WARNING: 'warning.200',
    SUCCESS: 'success.200',
  },
  BG: {
    PRIMARY: 'primary.200',
    ACCENT: 'primary.700',
    CLEAR: 'dark.50',
    DANGER: 'danger.300',
    WARNING: 'warning.300',
    SUCCESS: 'success.300',
    GREY: 'gray.600',
    LIGHT_GREY: 'warmGray.700',
    DARK_GREY: 'gray.800',
  },
}

const HC = {
  TEXT: {
    PRIMARY: () => mode(L.TEXT.PRIMARY, D.TEXT.PRIMARY),
    ACTIVE: () => mode(L.TEXT.ACTIVE, D.TEXT.ACTIVE),
    INACTIVE: () => mode(L.TEXT.INACTIVE, D.TEXT.INACTIVE),
    ACTIVE_DARK: () => mode(L.TEXT.ACTIVE_DARK, D.TEXT.ACTIVE_DARK),
    DANGER: () => mode(L.TEXT.DANGER, D.TEXT.DANGER),
    WARNING: () => mode(L.TEXT.WARNING, D.TEXT.WARNING),
    SUCCESS: () => mode(L.TEXT.SUCCESS, D.TEXT.SUCCESS),
  },
  BG: {
    PRIMARY: () => mode(L.BG.PRIMARY, D.BG.PRIMARY),
    ACCENT: () => mode(L.BG.ACCENT, D.BG.ACCENT),
    CLEAR: () => mode(L.BG.CLEAR, D.BG.CLEAR),
    LIGHT_GREY: () => mode(L.BG.LIGHT_GREY, D.BG.LIGHT_GREY),
  },
}

export default {
  C: { L, D }, // LIGHT/DARK colors
  HC, // Hooks Colors
  U: {
    // UTILITY PROPS
    BG_L_PRIMARY: { bg: L.BG.PRIMARY },
    BG_D_PRIMARY: { bg: D.BG.PRIMARY },
    BG_L_ACCENT: { bg: L.BG.ACCENT },
    BG_D_ACCENT: { bg: D.BG.ACCENT },
    BG_L_CLEAR: { bg: L.BG.CLEAR },
    BG_D_CLEAR: { bg: D.BG.CLEAR },
    BG_L_LIGHT_GREY: { bg: L.BG.LIGHT_GREY },
    BG_D_LIGHT_GREY: { bg: D.BG.LIGHT_GREY },

    TEXT_L_PRIMARY: { color: L.TEXT.PRIMARY },
    TEXT_D_PRIMARY: { color: D.TEXT.PRIMARY },
    TEXT_L_ACTIVE: { color: L.TEXT.ACTIVE },
    TEXT_D_ACTIVE: { color: D.TEXT.ACTIVE },
    TEXT_L_INACTIVE: { color: L.TEXT.INACTIVE },
    TEXT_D_INACTIVE: { color: D.TEXT.INACTIVE },
    TEXT_L_ACTIVE_DARK: { color: L.TEXT.ACTIVE_DARK },
    TEXT_D_ACTIVE_DARK: { color: D.TEXT.ACTIVE_DARK },
    TEXT_L_DANGER: { color: L.TEXT.DANGER },
    TEXT_D_DANGER: { color: D.TEXT.DANGER },
    TEXT_L_SUCCESS: { color: L.TEXT.SUCCESS },
    TEXT_D_SUCCESS: { color: D.TEXT.SUCCESS },

    UPPERLINE: {
      borderTopWidth: '1',
      borderTopColor: L.TEXT.INACTIVE,
    },
    UNDERLINE: {
      borderBottomWidth: '1',
      borderBottomColor: L.TEXT.INACTIVE,
    },
  },
}
