// import EntypoIcon from 'react-native-vector-icons/Entypo'

// Extended
import BaseUIAssistantMemberView from 'xui/modules/store/BaseUIAssistantMemberView'
// Warp to Container
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as StoreActions from 'x/modules/store/StoreState'
import { getSelectedStore, getSubscriptionType } from 'x/redux/selectors'
// import HelpButton from '../../../components/HelpButton'

class AssistantMemberView extends BaseUIAssistantMemberView {
  static displayName = 'AssistantMemberView'

  static navigationOptions = {
    header: null,
  }
}

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
    subscriptionType: getSubscriptionType(state),
  }),
  (dispatch) => ({
    submitEditing: bindActionCreators(StoreActions.submitEditingStore, dispatch),
    resetEditingStore: bindActionCreators(StoreActions.resetEditingStore, dispatch),
    getPermissionMemberList: bindActionCreators(StoreActions.getPermissionMemberList, dispatch),
    changePermission: bindActionCreators(StoreActions.changePermission, dispatch),
    deleteHelperFromPermission: bindActionCreators(StoreActions.deleteHelperFromPermission, dispatch),
    addHelper: bindActionCreators(StoreActions.addHelper, dispatch),
  })
  // @ts-ignore
)(AssistantMemberView)
