import React from 'react'
import * as util from 'x/utils/util'
// import BackIcon from '../../components/BackIcon'
// import XTabNavIcon from '../../components/XTabNavIcon'
// import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
// import SRowItem from '../../components/renderRowLeftAndRight'
import BaseStoreWebsiteView from 'x/modules/storeWebsite/BaseStoreWebsiteView'
import { COLORS, STYLES } from 'x/config/styles'
import XMenuListView from 'xui/components/XMenuListView'
import XCard from 'xui/components/XCard'
import HStack from 'xui/components/HStack'
import XImage from 'xui/components/XImage'
import Box from 'xui/components/Box'
import XText from 'xui/components/XText'
import { TouchableOpacity, LayoutChangeEvent } from 'react-native'
import XIcon from 'xui/components/XIcon'
import _ from 'lodash'
import VStack from 'xui/components/VStack'
import XDropdown from 'xui/components/XDropdown'
import DotDotDotVerticalIcon from 'xui/components/DotDotDotVerticalIcon'
import { IStoreWebsite } from 'x/index'
import XSpinner from 'xui/components/XSpinner'
import * as xFmt from 'x/utils/formatter'
import XSwitch from 'xui/components/XSwitch'

// const _renderAgreement = (onPressOpenOverlay: () => void) => <HelpButton onPress={() => onPressOpenOverlay()} />

export default abstract class BaseUIStoreWebsiteView extends BaseStoreWebsiteView {
  _renderHeader = () => {
    const title = 'จัดการเว็บไซต์'
    return (
      <XCustomHeader
        title={title}
        // @ts-ignore
        headerLeftProps={{ backIcon: true, onPressItem: () => util.navGoBack(this.props) }}
        renderHeaderRight={this._renderHamburgerButton}
      />
    )
  }

  _renderTag = (storeWebsite: IStoreWebsite) => {
    const { type } = storeWebsite
    const templateText = util.getStoreWebstieTagName(type)
    return (
      <Box w='20' h='6' alignItems='center' justifyContent='center' bgColor={COLORS.APP_SECONDARY} borderRadius='7'>
        <XText color={COLORS.WHITE}>{templateText}</XText>
      </Box>
    )
  }

  _renderSectionImg = () => {
    const { storeWebsite } = this.state
    if (_.isNil(storeWebsite)) {
      return null
    }
    return (
      <HStack mt='1'>
        <VStack>
          <XImage
            w='20'
            h='20'
            borderRadius='lg'
            // resizeMode='cover'
            source={{ uri: storeWebsite.img_url }}
          />
          <XText variant='inactive' fontSize='xs'>{`ID:${storeWebsite.id}`}</XText>
        </VStack>

        <HStack flex={1} ml='2'>
          <XText numberOfLines={3} fontSize='md' bold>
            {storeWebsite.name}
          </XText>
        </HStack>
      </HStack>
    )
  }

  _renderSectionTag = () => {
    const { storeWebsite } = this.state
    if (_.isNil(storeWebsite)) {
      return null
    }
    const { type } = storeWebsite
    const templateText = util.getStoreWebstieTagName(type)
    return (
      <HStack w='full'>
        <HStack flex={1}>
          <XText zIndex={999} ml='2' color={COLORS.WHITE}>
            {templateText}
          </XText>
          <Box
            w='32'
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              backgroundColor: 'transparent',
              borderStyle: 'solid',
              borderRightColor: 'transparent',
              borderRightWidth: 22,
              borderTopWidth: 22,
              marginLeft: -8,
            }}
            borderTopColor='primary.500'
          />
        </HStack>
        <HStack flex={1} justifyContent='flex-end'>
          <TouchableOpacity
            style={{
              // width: 52,
              height: 32,
              // backgroundColor: COLORS.BG_LIGHT_GREY,
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 100,
              marginRight: 2,
              marginLeft: 2,
              flexDirection: 'row',
              paddingLeft: 8,
              paddingRight: 8,
            }}
            onPress={() => null}>
            <XIcon zIndex={999} name='edit' family='Feather' color={COLORS.APP_MAIN} />
            <XText zIndex={999} ml='1' color={COLORS.APP_MAIN}>
              แก้ไข
            </XText>
            <HStack
              position='absolute'
              top='0'
              left='0'
              right='0'
              bottom='0'
              opacity={90}
              bgColor={COLORS.BG_LIGHT_GREY}
              borderRadius='full'
            />
          </TouchableOpacity>
        </HStack>
      </HStack>
    )
  }

  _renderBox = (label: string, value: string, valueColor: string) => {
    const { containerWidth } = this.state

    let fontSize = 'md'

    if (containerWidth < 320) {
      fontSize = value.length > 8 ? 'xs' : 'sm'
    }

    return (
      <VStack flex={1} alignItems='center' justifyContent='space-between'>
        <XText fontSize={fontSize} color={valueColor || 'black'} bold>
          {value}
        </XText>

        <XText fontSize='sm' textAlign='center'>
          {label}
        </XText>
      </VStack>
    )
  }

  _renderSummary = () => {
    const { isInitialized } = this.state

    if (!isInitialized) {
      return (
        <HStack w='full' h='9' alignItems='center' justifyContent='center'>
          <XSpinner variant='inactive' />
        </HStack>
      )
    }

    return (
      <TouchableOpacity onPress={() => null}>
        <VStack w='full' space='0.5'>
          <HStack w='full' alignItems='center' justifyContent='center'>
            {/* <Box w='38px' /> */}
            <HStack flex={1} alignItems='center' justifyContent='center'>
              <XText style={{ marginTop: -2 }} variant='inactive' fontSize='xs' textAlignVertical='center' textAlign='center'>
                ข้อมูลออเดอร์ที่สร้างวันนี้
              </XText>
            </HStack>
            {/* <Box w='38px' alignItems='center' justifyContent='center' position='absolute' top='3px' right='0'>
              <ForwardIcon />
            </Box> */}
          </HStack>
          <HStack w='full' space='0.5'>
            {this._renderBox('ยอดขาย', xFmt.formatCurrency(0), 'blue.500')}
            <Box borderLeftWidth='1' borderLeftColor='gray.200' />
            {this._renderBox('กำไร', xFmt.formatCurrency(0), 'success.500')}
            <Box borderLeftWidth='1' borderLeftColor='gray.200' />
            {this._renderBox('ออเดอร์', '0', 'primary.500')}
            <Box borderLeftWidth='1' borderLeftColor='gray.200' />
            {this._renderBox('สินค้า', '0', 'yellow.700')}
          </HStack>
        </VStack>
      </TouchableOpacity>
    )
  }

  _renderCardStatusAndUrl = () => {
    const { storeWebsite } = this.state
    if (_.isNil(storeWebsite)) {
      return null
    }
    return (
      <XCard flex={1} mx='2' alignItems='center' justifyContent='center'>
        <VStack w='full' mt='2' justifyContent='center' alignItems='center'>
          <HStack flex={1}>
            <XText textAlign='center' variant='inactive'>
              การเข้าถึงเว็บไซต์
            </XText>
          </HStack>
          <HStack w='full' borderBottomColor={COLORS.TEXT_INACTIVE} borderBottomWidth='1' mt='1' />
        </VStack>

        <HStack w='full' mt='2' p='2'>
          <HStack flex={1}>
            <XText variant='inactive'>การเข้าถึงจากสาธารณะ</XText>
          </HStack>
          <HStack flex={1} justifyContent='flex-end'>
            <VStack justifyContent='flex-end' alignItems='flex-end'>
              <XSwitch value={false} />
              <XText mt='1' color={COLORS.RED}>
                ปิดการเข้าถึง
              </XText>
            </VStack>
          </HStack>
        </HStack>

        <HStack flex={1} justifyContent='center' m='2'>
          <TouchableOpacity
            style={{
              // width: 52,
              height: 32,
              // backgroundColor: COLORS.BG_LIGHT_GREY,
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 100,
              marginRight: 2,
              marginLeft: 2,
              flexDirection: 'row',
              paddingLeft: 4,
              paddingRight: 8,
            }}
            onPress={() => null}>
            <XIcon zIndex={999} name='eye' family='Ionicons' color={COLORS.APP_MAIN} />
            <XText zIndex={999} color={COLORS.APP_MAIN}>
              ดูเว็บไซต์สาธารณะ
            </XText>
            <HStack
              position='absolute'
              top='0'
              left='0'
              right='0'
              bottom='0'
              opacity={90}
              bgColor={COLORS.BG_LIGHT_GREY}
              borderRadius='full'
            />
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              // width: 52,
              height: 32,
              // backgroundColor: COLORS.BG_LIGHT_GREY,
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 100,
              marginRight: 2,
              marginLeft: 2,
              flexDirection: 'row',
              paddingLeft: 4,
              paddingRight: 8,
            }}
            onPress={() => null}>
            <XIcon zIndex={999} name='copy1' family='AntDesign' color={COLORS.APP_MAIN} />
            <XText ml='1' zIndex={999} color={COLORS.APP_MAIN}>
              คัดลอก URL
            </XText>
            <HStack
              position='absolute'
              top='0'
              left='0'
              right='0'
              bottom='0'
              opacity={90}
              bgColor={COLORS.BG_LIGHT_GREY}
              borderRadius='full'
            />
          </TouchableOpacity>
        </HStack>

        <HStack w='full' p='2'>
          <HStack flex={1}>
            <XText variant='inactive'>ปิดการใช้งานเมื่อ</XText>
          </HStack>
          <HStack flex={1} justifyContent='flex-end'>
            <XText variant='inactive'>{`${storeWebsite.created_at}`}</XText>
          </HStack>
        </HStack>

        <HStack w='full' p='2'>
          <HStack flex={1}>
            <XText variant='inactive'>เผยแพร่ล่าสุดเมื่อ</XText>
          </HStack>
          <HStack flex={1} justifyContent='flex-end'>
            <XText variant='inactive'>{`${storeWebsite.created_at}`}</XText>
          </HStack>
        </HStack>
      </XCard>
    )
  }

  _renderSectionCreateAy = () => {
    const { storeWebsite } = this.state
    return (
      <VStack w='full' mt='2'>
        <HStack w='full'>
          <HStack flex={1}>
            <XText variant='inactive'>สร้างเมื่อ:</XText>
          </HStack>
          <HStack flex={1} justifyContent='flex-end'>
            <XText variant='inactive'>{`${storeWebsite.created_at}`}</XText>
          </HStack>
        </HStack>
      </VStack>
    )
  }

  _renderHamburgerButton = () => {
    const { hamburgerMenuOptions } = this.state
    // console.log('hamburgerMenuOptions => ', hamburgerMenuOptions)
    if (_.isNil(hamburgerMenuOptions) || hamburgerMenuOptions.length < 1) {
      return null
    }
    const isDisabled = false
    // const opts = [{ label: 'สลับการผูกตัวเลือกสินค้า' }]
    // console.log('_renderHamburgerButton => ')
    return (
      <VStack>
        <XDropdown
          disabled={isDisabled}
          key='Hamburger_erp'
          // ref={r => r && (this.hamburgerMenuRef = r)}
          // ref={this.hamburgerMenuRef}
          // dropdownStyle={{
          //   width: 220,
          //   height: 40 * hamburgerMenuOptions.length,
          // }}
          // @ts-ignore
          // showsVerticalScrollIndicator={false}
          // onDropdownWillShow={this._onHamburgerWillShow}
          // onDropdownWillHide={this._onHamburgerWillHide}
          renderRow={this._renderModalDropdownRow}
          onSelect={this._onHamburgerMenuSelect}
          options={hamburgerMenuOptions}>
          <DotDotDotVerticalIcon disabled={isDisabled} />
        </XDropdown>
      </VStack>
    )
  }

  _renderModalDropdownRow = (data: { label: string; isDanger?: boolean; icon?: any; onPress: () => void }): JSX.Element => {
    // log('ModalDropdown renderRow::data => ', data)
    const isDanger = _.has(data, 'isDanger') ? data.isDanger : false
    const icon = _.has(data, 'icon') ? data.icon : null
    let textColor = COLORS.TEXT_ACTIVE
    if (isDanger) {
      textColor = COLORS.FORM_ERROR
    }

    // let textPaddingLeft = -2
    // if (_.isNil(icon)) {
    //   textPaddingLeft = 10
    // }

    return (
      <Box flexDirection='row' py='2' pr='2' justifyContent='center'>
        {_.isNil(icon) ? null : (
          <XIcon
            name={icon.name}
            family={icon.family}
            style={{
              color: textColor,
            }}
          />
        )}
        <XText
          allowFontScaling={false}
          pl='1'
          pt='1'
          style={{
            fontSize: STYLES.FONT_SIZE_LARGER,
            color: textColor,
          }}>
          {data.label}
        </XText>
      </Box>
    )
  }

  _onHamburgerMenuSelect = async (index: number, rowData: any) => {
    // if (this.inSelectHamburger) {
    //   return
    // }
    // this.inSelectHamburger = true
    // log('ModalDropdown:: onSelect index => ', index)
    // log('_onHamburgerMenuSelect ModalDropdown:: onSelect index => ', index)
    const selectedItem = this.state.hamburgerMenuOptions[index]
    // log('ModalDropdown:: onSelect selectedItem => ', selectedItem)
    if (selectedItem && _.isFunction(selectedItem.onPress)) {
      // MUST!! delay for finished render dropdown before call method
      await new Promise((delayOnPress) => {
        setTimeout(delayOnPress, 200)
      })
      // if (!_.isNil(selectedItem.perm)) {
      //   const perm = xAcl.canDoAtSelectedStore(selectedItem.perm)
      //   if (!perm) {
      //     p.op.showConfirmationOkOnly(``, `คุณไม่ได้รับสิทธิ์ในการใช้งานฟีเจอร์นี้`)
      //     // this.inSelectHamburger = false
      //     await util.setStatePromise(this, { isHamburgerMenuOpened: false })
      //     return
      //   }
      // }
      selectedItem.onPress()
      this._afterHamburgerMenuSelectedSafetyClose()
    }
    // this.inSelectHamburger = false
    await util.setStatePromise(this, { isHamburgerMenuOpened: false })
  }

  _afterHamburgerMenuSelectedSafetyClose = () => {
    setTimeout(() => {
      if (this.state.isHamburgerMenuOpened) {
        this.setState({ isHamburgerMenuOpened: false })
      }
    }, 200)
  }

  _onLayout = (event: LayoutChangeEvent) => {
    try {
      const { width } = event.nativeEvent.layout
      this.setState({ containerWidth: width })
    } catch (error) {
      //
    }
  }

  _renderStoreWebsiteDetail = () => {
    const { storeWebsite } = this.state
    if (_.isNil(storeWebsite)) {
      return null
    }
    return (
      <XCard p='2' m='2' onLayout={this._onLayout}>
        {this._renderSectionTag()}
        {this._renderSectionImg()}
        {this._renderSummary()}
        {this._renderSectionCreateAy()}
      </XCard>
    )
  }

  _renderMenu = () => (
    <VStack mt='2'>
      <XMenuListView menuItems={this.MENU_ITRMS} />
    </VStack>
  )

  render() {
    return (
      <XContainer>
        {this._renderHeader()}
        <XContent style={{ backgroundColor: COLORS.BG_LIGHT_GREY }}>
          {this._renderStoreWebsiteDetail()}
          {this._renderCardStatusAndUrl()}
          {this._renderMenu()}
        </XContent>
      </XContainer>
    )
  }
}
