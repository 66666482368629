import { isImmutable, Map, List, fromJS } from 'immutable'
import { ActionApiParams } from 'x/index'
import actions from 'x/config/actions'

const initAddress = Map({
  postal_code: null,
  telephone: null,
  created_at: null,
  name: null,
  sub_district: null,
  district: null,
  province: null,
  updated_at: null,
  deleted_at: null,
  id: null,
  address1: null,
  address2: null,
  shouldPatch: false,
  legal_entity_type: null,
  legal_entity_id: null,
  note: null,
  is_primary: null,
})

// Initial state
const initialState = Map({
  profileAddresses: List([]),
  storeAddresses: List([]),
  selectedAddresses: List([]),
  selectedAddress: initAddress,
  editingAddress: initAddress,
  addresses: Map({}),
  shouldFetchList: false,
})

// Actions
export const ACTION_INITIALIZE = 'AddressState/INITIALIZE'

export const ACTION_ADDRESSES_CLEAR = 'AddressState/ACTION_ADDRESSES_CLEAR'
export const ACTION_ADDRESSES_LOAD = 'AddressState/ACTION_ADDRESSES_LOAD'
export const ACTION_ADDRESSES_APPEND = 'AddressState/ACTION_ADDRESSES_APPEND'

export const ACTION_ADDRESS_CLEAR = 'AddressState/ACTION_ADDRESS_CLEAR'
export const ACTION_ADDRESS_LOAD = 'AddressState/ACTION_ADDRESS_LOAD'
export const ACTION_ADDRESS_SAVE_NEW = 'AddressState/ACTION_ADDRESS_SAVE_NEW'
export const ACTION_ADDRESS_SAVE_EDITING = 'AddressState/ACTION_ADDRESS_SAVE_EDITING'
export const ACTION_ORG_ADDRESS_CREATE = 'AddressState/ACTION_ORG_ADDRESS_CREATE'
export const ACTION_ORG_ADDRESS_EDIT = 'AddressState/ACTION_ORG_ADDRESS_EDIT'

export const ACTION_ADDRESS_DELETE = 'AddressState/ACTION_ADDRESS_DELETE'
export const ACTION_ORG_ADDRESS_DELETE = 'AddressState/ACTION_ORG_ADDRESS_DELETE'
export const ACTION_ADDRESS_DELETE_FAILED = 'AddressState/ACTION_ADDRESS_DELETE_FAILED'

export const ACTION_LOAD_ADDRESS_PROFILE = 'AddressState/ACTION_LOAD_ADDRESS_PROFILE'

export const ACTION_EDITING_ADDRESS_INITIALIZE = 'AddressState/ACTION_EDITING_ADDRESS_INITIALIZE'
export const ACTION_EDITING_ADDRESS_CHANGE = 'AddressState/ACTION_EDITING_ADDRESS_CHANGE'
export const ACTION_EDITING_ADDRESS_REVERT = 'AddressState/ACTION_EDITING_ADDRESS_REVERT'

export const ACTION_EDITING_ADDRESSES_CHANGE = 'AddressState/ACTION_EDITING_ADDRESSES_CHANGE'
export const ACTION_EDITING_ADDRESS_DELETE = 'AddressState/ACTION_EDITING_ADDRESS_DELETE'
export const ACTION_EDITING_ADDRESS_ADD = 'AddressState/ACTION_EDITING_ADDRESS_ADD'

export const ACTION_PROFILE_ADDRESS_LOAD = 'AddressState/ACTION_PROFILE_ADDRESS_LOAD'
export const ACTION_STORE_ADDRESS_LOAD = 'AddressState/ACTION_STORE_ADDRESS_LOAD'

export const ACTION_SET_SHOULD_FETCH_LIST = 'AddressState/ACTION_SET_SHOULD_FETCH_LIST'
export const ACTION_RESET_SHOULD_FETCH_LIST = 'AddressState/ACTION_RESET_SHOULD_FETCH_LIST'

export const ACTION_FIND_ADDRESSES = 'AddressState/ACTION_FIND_ADDRESSES'

export const ACTION_MERGE_ADDRESSES = 'AddressState/ACTION_MERGE_ADDRESSES'

export const GET_CUSTOMER = 'AddressState/GET_CUSTOMER'

// Action creators
export function init() {
  return { type: ACTION_INITIALIZE }
}

// list of address
export function resetList() {
  return {
    type: ACTION_ADDRESSES_CLEAR,
  }
}

export function loadList(addresses) {
  return {
    type: ACTION_ADDRESSES_LOAD,
    payload: isImmutable(addresses) ? addresses : fromJS(addresses),
  }
}

// single address
export function reset() {
  return {
    type: ACTION_ADDRESS_CLEAR,
  }
}

export function load(address) {
  // console.log('address', address)
  return {
    type: ACTION_ADDRESS_LOAD,
    payload: isImmutable(address) ? address : fromJS(address),
  }
}

export function loadToPatch(address) {
  const willPatchAddress = isImmutable(address) ? address.toJS() : address
  willPatchAddress.shouldPatch = true
  return {
    type: ACTION_ADDRESS_LOAD,
    payload: fromJS(willPatchAddress),
  }
}

export function onInit(address) {
  // single field object like: { name: 'hello' }
  return {
    type: ACTION_EDITING_ADDRESS_INITIALIZE,
    payload: address,
  }
}

export function onChange({ key, value }) {
  // single field object like: { name: 'hello' }
  return {
    type: ACTION_EDITING_ADDRESS_CHANGE,
    payload: { key, value },
  }
}

export function saveNew({ body, isStorePickMode, successCallback, failedCallback }) {
  return {
    type: ACTION_ADDRESS_SAVE_NEW,
    payload: body,
    isStorePickMode,
    successCallback,
    failedCallback,
  }
}

export function createOrgAddress({ body, isStorePickMode, successCallback, failedCallback }) {
  return {
    type: ACTION_ORG_ADDRESS_CREATE,
    payload: body,
    isStorePickMode,
    successCallback,
    failedCallback,
  }
}

export function saveEditing({ body, isStorePickMode, successCallback, failedCallback }) {
  return {
    type: ACTION_ADDRESS_SAVE_EDITING,
    payload: body,
    isStorePickMode,
    successCallback,
    failedCallback,
  }
}

export function editOrgAddress({ body, isStorePickMode, successCallback, failedCallback }) {
  return {
    type: ACTION_ORG_ADDRESS_EDIT,
    payload: body,
    isStorePickMode,
    successCallback,
    failedCallback,
  }
}

export function deleteAddress({ body, isStorePickMode, successCallback, failedCallback }) {
  return {
    type: ACTION_ADDRESS_DELETE,
    payload: body,
    isStorePickMode,
    successCallback,
    failedCallback,
  }
}

export function deleteOrgAddress({ body, isStorePickMode, successCallback, failedCallback }) {
  return {
    type: ACTION_ORG_ADDRESS_DELETE,
    payload: body,
    isStorePickMode,
    successCallback,
    failedCallback,
  }
}

export function findAddresses({ body, successCallback, failedCallback }) {
  return {
    type: ACTION_FIND_ADDRESSES,
    body,
    successCallback,
    failedCallback,
  }
}

export function getResellerAddresses({ body, successCallback, failedCallback }) {
  return {
    type: actions.GET_RESELLER_ADDRESSES,
    body,
    successCallback,
    failedCallback,
  }
}

// classified by actor
export function profileAddressesLoad(addresses) {
  return {
    type: ACTION_PROFILE_ADDRESS_LOAD,
    payload: isImmutable(addresses) ? addresses : fromJS(addresses),
  }
}

export function storeAddressesLoad(addresses) {
  return {
    type: ACTION_STORE_ADDRESS_LOAD,
    payload: isImmutable(addresses) ? addresses : fromJS(addresses),
  }
}

export function setShouldFetchList() {
  return {
    type: ACTION_SET_SHOULD_FETCH_LIST,
  }
}

export function resetShouldFetchList() {
  return {
    type: ACTION_RESET_SHOULD_FETCH_LIST,
  }
}

export function loadAddressProfile(body) {
  return {
    type: ACTION_LOAD_ADDRESS_PROFILE,
    payload: body,
  }
}

export function mergeAddresses(addresses: object[]) {
  let newPayload = fromJS(addresses)
  // @ts-ignore
  newPayload = newPayload.toJS()
  const result = newPayload.reduce((map, obj) => {
    // @ts-ignore
    map[obj.id] = obj
    return map
  }, {})
  // return newState.mergeIn(['addresses'], result)
  return result
}

export function getCustomer(params: ActionApiParams) {
  return {
    type: GET_CUSTOMER,
    ...params,
  }
}

// Reducer
export default function AddressStateReducer(state = initialState, action: ActionApiParams = {}) {
  const { type, payload } = action
  let newState = state

  switch (type) {
    case ACTION_INITIALIZE:
    case ACTION_ADDRESS_LOAD:
      newState = newState.set('selectedAddress', payload)
      return newState.set('editingAddress', payload)
    case ACTION_ADDRESS_CLEAR:
      newState = newState.set('selectedAddress', initAddress)
      return newState.set('editingAddress', initAddress)

    case ACTION_ADDRESSES_LOAD:
      newState = newState.set('selectedAddresses', payload)
      // @ts-ignore
      const addresses = isImmutable(payload) ? payload.toJS() : payload
      // @ts-ignore
      const result = mergeAddresses(addresses)
      return newState.mergeIn(['addresses'], result)
    // return newState.set('selectedAddresses', payload)
    case ACTION_MERGE_ADDRESSES:
      return newState.mergeIn(['addresses'], payload)
    case ACTION_ADDRESSES_CLEAR:
      return newState.set('selectedAddresses', initialState.get('selectedAddresses'))
    case ACTION_ADDRESSES_APPEND:
      return newState.updateIn(['selectedAddresses'], (addr) => addr.push(payload))

    case ACTION_EDITING_ADDRESS_INITIALIZE:
      return newState.mergeDeepIn(['editingAddress'], fromJS(payload))
    case ACTION_EDITING_ADDRESS_CHANGE:
      return newState.setIn(['editingAddress', payload.key], payload.value)
    case ACTION_EDITING_ADDRESS_REVERT:
      return newState.set('editingAddress', state.get('selectedContact'))

    case ACTION_EDITING_ADDRESSES_CHANGE:
      return newState.setIn(['editingAddress', 'addresses', payload.index, payload.key], payload.value)
    case ACTION_EDITING_ADDRESS_DELETE:
      return newState.deleteIn(['editingAddress', 'addresses', payload.index])
    case ACTION_EDITING_ADDRESS_ADD:
      const firstName = newState.getIn(['editingAddress', 'first_name'])
      const lastName = newState.getIn(['editingAddress', 'last_name'])
      const addrSize = newState.getIn(['editingAddress', 'addresses']).size
      newState = newState.updateIn(['editingAddress', 'addresses'], (addr) => addr.push(initAddress))
      return newState.setIn(['editingAddress', 'addresses', addrSize, 'name'], `${firstName} ${lastName}`)

    case ACTION_PROFILE_ADDRESS_LOAD:
      return newState.mergeDeepIn(['profileAddresses'], payload)
    case ACTION_STORE_ADDRESS_LOAD:
      return newState.mergeDeepIn(['storeAddresses'], payload)

    case ACTION_SET_SHOULD_FETCH_LIST:
      return newState.set('shouldFetchList', true)
    case ACTION_RESET_SHOULD_FETCH_LIST:
      return newState.set('shouldFetchList', false)

    default:
      return state
  }
}
