import React from 'react'
import XContent from 'xui/components/XContent'
import XCustomHeader from 'xui/components/XCustomHeader'
import BaseAssistantGroupView, { HEADER_CONSTANT, GROUP_ITEM } from 'x/modules/store/Assistant/BaseAssistantGroupView'
import XContainer from 'xui/components/XContainer'
import _ from 'lodash'
import XCard from 'xui/components/XCard'
import XText from 'xui/components/XText'
import VStack from 'xui/components/VStack'
import Box from 'xui/components/Box'
import HStack from 'xui/components/HStack'
import CONS from 'x/config/constants'
import FAQ from 'x/config/FAQ'
import { TouchableOpacity } from 'react-native'
import XIcon from 'xui/components/XIcon'
import { IQuestionsAndAnswers } from 'x/index'
import BackIcon from 'xui/components/BackIcon'
import p from 'x/config/platform-specific'
import { COLORS } from 'x/config/styles'
import XHelpModal from 'xui/components/XHelpModal'
import XInput from 'xui/components/XInput'
import * as util from 'x/utils/util'

const MODE = {
  VIEW: 'VIEW',
  CREATE: 'CREATE',
  EDIT: 'EDIT',
}

export default abstract class BaseUIAssistantGroupView extends BaseAssistantGroupView {
  // abstract goBack: () => void

  _renderHeader = () => {
    const { mode } = this.state
    let title = util.getNavParam(this.props, 'title', 'สร้างสิทธิ์ผู้ช่วย')
    let leftText = 'บันทึก'
    if (_.isNil(title) && mode === MODE.CREATE) {
      title = 'สร้างสิทธิ์ผู้ช่วย'
      leftText = 'สร้าง'
    } else if (mode === MODE.VIEW) {
      title = 'สิทธิ์การใช้งาน'
    }
    return (
      <XCustomHeader title={title} renderHeaderLeft={() => this._renderHeaderLeft(leftText)} renderHeaderRight={this._renderHeaderRight} />
    )
  }

  _renderHeaderRight = () => {
    const { mode } = this.state

    // const cancelBtn = state.params ? state.params.cancelBtn : null
    // const saveBtn = state.params ? state.params.saveBtn : null
    // const onChange = state.params ? state.params.onChange : null
    const handleRightBtn = util.getNavParam(this.props, 'handleRightBtn')
    return _.isNil(mode) || mode === MODE.VIEW ? (
      <TouchableOpacity
        style={{ width: 50, height: 50, justifyContent: 'center', paddingRight: 4 }}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onPress={!_.isNil(handleRightBtn) ? () => handleRightBtn() : {}}>
        <XText variant='primary'>แก้ไข</XText>
      </TouchableOpacity>
    ) : (
      <TouchableOpacity
        style={{ width: 50, height: 50, justifyContent: 'center', paddingRight: 4 }}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onPress={!_.isNil(handleRightBtn) ? () => handleRightBtn() : {}}>
        <XText variant='primary'>{p.op.t('Order.cancelled')}</XText>
      </TouchableOpacity>
    )

    //       _.isNil(mode) || mode === MODE.VIEW ? (
    //         <Button onPress={() => (!_.isNil(handleRightBtn) ? handleRightBtn() : {})}>
    //           <Text style={{ color: COLORS.TEXT_PRIMARY, fontSize: STYLES.FONT_SIZE_NORMAL }}>แก้ไข</Text>
    //         </Button>
    //       ) : (
    //         <Button onPress={() => (!_.isNil(handleRightBtn) ? handleRightBtn() : {})}>
    //           <Text style={{ color: COLORS.TEXT_PRIMARY, fontSize: STYLES.FONT_SIZE_NORMAL }}>ยกเลิก</Text>
    //         </Button>
    //       ),
  }

  _renderHeaderLeft = (text: string) => {
    // const { navigation } = this.props
    // const { state } = navigation
    // const { mode } = this.state
    // const cancelBtn = state.params ? state.params.cancelBtn : null
    // const saveBtn = state.params ? state.params.saveBtn : null
    // const onChange = state.params ? state.params.onChange : null
    const mode = util.getNavParam(this.props, 'mode')

    // const { handleBtn } = state.params
    // const handleRightBtn = state.params ? state.params.handleRightBtn : null
    const handleLeftBtn = util.getNavParam(this.props, 'handleLeftBtn')

    return _.isNil(mode) || mode === MODE.VIEW ? (
      <TouchableOpacity
        style={{ width: 50, height: 50, justifyContent: 'center', paddingLeft: 10 }}
        onPress={() => util.navGoBack(this.props)}>
        <BackIcon />
      </TouchableOpacity>
    ) : (
      <TouchableOpacity
        style={{ width: 50, height: 40, alignItems: 'center', justifyContent: 'center' }}
        onPress={() => (!_.isNil(handleLeftBtn) ? handleLeftBtn() : {})}>
        <XText variant='primary'>{text}</XText>
      </TouchableOpacity>
    )

    //       _.isNil(mode) || mode === MODE.VIEW ? (
    //         <Button onPress={() => (!_.isNil(handleLeftBtn) ? handleLeftBtn() : {})}>
    //           <BackIcon />
    //         </Button>
    //       ) : (
    //         <Button onPress={() => (!_.isNil(handleLeftBtn) ? handleLeftBtn() : {})}>
    //           <Text style={{ color: COLORS.TEXT_PRIMARY, fontSize: STYLES.FONT_SIZE_NORMAL }}>{ledtText}</Text>
    //         </Button>
    //       )
  }

  _renderTitleName = () => (
    <HStack>
      <XText variant='inactive'>ชื่อสิทธิ์การใช้งาน</XText>
    </HStack>
  )

  _renderTitleDescription = () => (
    <HStack>
      <XText variant='inactive'>คำอธิบาย</XText>
    </HStack>
  )

  _renderPermissionLabel = () => (
    <HStack>
      <XText variant='inactive'>สิทธิ์ในการใช้งาน</XText>
    </HStack>
  )

  _renderCheckRow = (key: string, faq?: IQuestionsAndAnswers[]) => {
    const { canDo, mode } = this.state
    // console.log('canDo => ', canDo)
    let checkSquare = canDo[key]
    let value = null
    if (key === CONS.PERM_STORE_HELPER.PRODUCT_CODE_EDIT_UPC || key === CONS.PERM_STORE_HELPER.PRODUCT_CODE_EDIT_SKU) {
      checkSquare = this._findSKUAndUPCCheckBox(key)
    } else if (_.includes(CONS.REPORT_GROUP_KEY.ALL, key)) {
      checkSquare = this._findReportCheckBox(key)
    } else if (_.includes(CONS.DASHBOARD_PERM_REPORTS, key)) {
      checkSquare = this._findDashboardCheckBox(key)
    }
    // if (key === 'report_stock_history') {
    //   console.log('_renderCheckRow CANDO => ', canDo)
    //   console.log('checkSquare => ', checkSquare)
    // }
    value = !checkSquare
    const renderHelpBtn = (onPressOpenOverlay: () => void) => (
      <TouchableOpacity onPress={onPressOpenOverlay} style={{ width: 30, alignItems: 'center', justifyContent: 'center' }}>
        <XIcon name='help-circle-outline' family='Ionicons' style={{ flex: 0, width: 25, minWidth: 25, color: COLORS.TEXT_INACTIVE }} />
      </TouchableOpacity>
    )
    return (
      <TouchableOpacity disabled={mode === MODE.VIEW} onPress={() => this._seleteCheckBoxBtn(key, value)} style={{ flexDirection: 'row' }}>
        <XIcon name={_.isNil(checkSquare) || !checkSquare ? 'square-o' : 'check-square-o'} family='FontAwesome' />
        <XText variant='active' ml='1'>
          {p.op.t(`perm_store_helper.${key}`)}
        </XText>
        {_.isNil(faq) ? null : <XHelpModal headerTitle='FAQ' FAQ={faq} renderButton={renderHelpBtn} initiateOpenIndex={0} />}
      </TouchableOpacity>
    )
  }

  _fineMinLengthGroup = (key: string) => {
    const { canDo } = this.state
    if (key === HEADER_CONSTANT.REPORT) {
      let i = 0
      // eslint-disable-next-line array-callback-return
      CONS.REPORT_GROUP_KEY.REPORT_ORDER.map((report: string) => {
        // eslint-disable-next-line no-bitwise
        if ((canDo.report_order & CONS.REPORT_SET_BIT_BINARY[report]) === CONS.REPORT_SET_BIT_BINARY[report]) {
          i += 1
        }
      })
      // eslint-disable-next-line array-callback-return
      CONS.REPORT_GROUP_KEY.REPORT_INVENTORY.map((report: string) => {
        // eslint-disable-next-line no-bitwise
        if ((canDo.report_inventory & CONS.REPORT_SET_BIT_BINARY[report]) === CONS.REPORT_SET_BIT_BINARY[report]) {
          i += 1
        }
      })
      // eslint-disable-next-line array-callback-return
      CONS.REPORT_GROUP_KEY.REPORT_SHIPPING.map((report: string) => {
        // eslint-disable-next-line no-bitwise
        if ((canDo.report_shipping & CONS.REPORT_SET_BIT_BINARY[report]) === CONS.REPORT_SET_BIT_BINARY[report]) {
          i += 1
        }
      })
      // eslint-disable-next-line array-callback-return
      CONS.REPORT_GROUP_KEY.REPORT_RESELLER.map((report: string) => {
        // eslint-disable-next-line no-bitwise
        if ((canDo.report_reseller & CONS.REPORT_SET_BIT_BINARY[report]) === CONS.REPORT_SET_BIT_BINARY[report]) {
          i += 1
        }
      })
      // eslint-disable-next-line array-callback-return
      CONS.REPORT_GROUP_KEY.REPORT_HELPER.map((report: string) => {
        // eslint-disable-next-line no-bitwise
        if ((canDo.report_helper & CONS.REPORT_SET_BIT_BINARY[report]) === CONS.REPORT_SET_BIT_BINARY[report]) {
          i += 1
        }
      })
      // eslint-disable-next-line array-callback-return
      CONS.REPORT_GROUP_KEY.REPORT_PAYMENT.map((report: string) => {
        // eslint-disable-next-line no-bitwise
        if ((canDo.report_payment & CONS.REPORT_SET_BIT_BINARY[report]) === CONS.REPORT_SET_BIT_BINARY[report]) {
          i += 1
        }
      })
      return i
    }
    let i = 0
    // eslint-disable-next-line array-callback-return
    GROUP_ITEM[key].map((data: string) => {
      if (canDo[data]) {
        i += 1
      }
    })
    return i
  }

  _renderTitleGroupName = (text: string, key: string) => {
    const { selectedAllCheckBox, arrowFunctionShowAndHide, mode } = this.state
    // console.log('selectedAllCheckBox => ', selectedAllCheckBox)
    const onShow = arrowFunctionShowAndHide[key] // แสดง ไอคอนเลือกทั้งหมด && ไอคอนสำหรับพับเก็บข้อมูล
    const onSelect = this._fineMinLengthGroup(key) // คำนวณจากเท่าไร / ทั้งหมด
    let fullGroup = GROUP_ITEM[key].length // / ทั้งหมด
    if (key === HEADER_CONSTANT.REPORT) {
      fullGroup = GROUP_ITEM.report[0].length
    }

    const BG_MAIN_COLOR = (
      <Box
        w='3/4'
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          backgroundColor: 'transparent',
          borderStyle: 'solid',
          borderRightColor: 'transparent',
          borderRightWidth: 22,
          borderTopWidth: 22,
          marginLeft: -2,
        }}
        borderTopColor='primary.500'
      />
    )
    const LINE_MAIN_COLOR = (
      <Box
        w='full'
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          backgroundColor: 'transparent',
          borderTopWidth: 2,
          marginLeft: -2,
        }}
        borderTopColor='primary.500'
      />
    )
    return (
      <HStack>
        {LINE_MAIN_COLOR}
        {BG_MAIN_COLOR}
        <Box w='1/2' h='6'>
          <TouchableOpacity onPress={() => this._onPressSeletedAll(key, selectedAllCheckBox[key])} style={{ flexDirection: 'row' }}>
            {onShow && mode !== MODE.VIEW ? (
              <XIcon
                family='FontAwesome'
                name={_.isNil(selectedAllCheckBox[key]) || !selectedAllCheckBox[key] ? 'square-o' : 'check-square-o'}
                color={COLORS.WHITE}
              />
            ) : null}
            <XText color={COLORS.WHITE} pl='2'>
              {`${text} (${onSelect}/${fullGroup})`}
            </XText>
          </TouchableOpacity>
        </Box>

        <Box w='1/2'>
          <TouchableOpacity onPress={() => this._onPressArrow(key)} style={{ alignSelf: 'flex-end' }}>
            <XIcon
              name={onShow ? 'caretdown' : 'caretup'}
              family='AntDesign'
              // style={{ flex: 0, alignSelf: 'flex-end', minWidth: 30, fontSize: 22, color: APP_MAIN }}
            />
          </TouchableOpacity>
        </Box>
      </HStack>
    )

    // return (
    //   <CardItem style={{ paddingBottom: 8 }}>
    //     <Grid style={{ flex: 1 }}>
    //       <Row style={{ borderTopColor: APP_MAIN, borderTopWidth: 2 }}>
    //         <Col size={100}>
    //           <View
    //             style={{
    //               height: Platform.OS === 'ios' ? 40 : 40,
    //               width: 200,
    //               position: 'absolute',
    //               top: 0,
    //               left: 0,
    //               // top: -32,
    //               // right: 10,
    //               backgroundColor: 'transparent',
    //               borderStyle: 'solid',
    //               borderRightWidth: 21,
    //               borderTopWidth: 21,
    //               borderRightColor: 'transparent',
    //               borderTopColor: COLORS.APP_MAIN,
    //             }}
    //           />
    //           <TouchableOpacity
    //             onPress={() => this._onPressSeletedAll(key, selectedAllCheckBox[key])}
    //             style={{ flexDirection: 'row', paddingLeft: 2, width: 150 }}>
    //             {onShow && mode !== MODE.VIEW ? (
    //               <FAIcon
    //                 style={{
    //                   flex: 0,
    //                   minWidth: 30,
    //                   fontSize: STYLES.FONT_ICON_NORMAL,
    //                   color: COLORS.WHITE,
    //                 }}
    //                 name={_.isNil(selectedAllCheckBox[key]) || !selectedAllCheckBox[key] ? 'square-o' : 'check-square-o'}
    //               />
    //             ) : null}
    //             <Text
    //               style={{
    //                 // marginTop: 2,
    //                 fontSize: STYLES.FONT_SIZE_NORMAL,
    //                 color: COLORS.WHITE,
    //                 justifyContent: 'center',
    //                 alignItems: 'center',
    //                 alignContent: 'center',
    //                 alignSelf: 'center',
    //                 fontWeight: 'bold',
    //                 paddingBottom: 2,
    //                 paddingLeft: this.state[key] ? 0 : 4,
    //               }}>
    //               {`${text} (${onSelect}/${fullGroup})`}
    //             </Text>
    //           </TouchableOpacity>
    //         </Col>
    //         <Col style={{ width: 60 }}>
    //           <TouchableOpacity onPress={() => this._onPressArrow(key)} style={{ width: 60, height: 25, alignSelf: 'flex-end' }}>
    //             <AntDesign
    //               name={onShow ? 'caretdown' : 'caretup'}
    //               style={{ flex: 0, alignSelf: 'flex-end', minWidth: 30, fontSize: 22, color: APP_MAIN }}
    //             />
    //           </TouchableOpacity>
    //         </Col>
    //       </Row>
    //     </Grid>
    //   </CardItem>
    // )
  }

  _renderCheckBoxOptionOrder = () => {
    const { arrowFunctionShowAndHide } = this.state
    return (
      <XCard p='4' borderRadius='xl' w='full'>
        {this._renderTitleGroupName(p.op.t('StoreSetting.HeaderOrder'), HEADER_CONSTANT.ORDER)}
        {arrowFunctionShowAndHide.order ? (
          <VStack space='2' pt='2'>
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.ORDER_ADD)}
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.ORDER_LIST)}
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.ORDER_EDIT)}
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.ORDER_CANCEL)}
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.ORDER_PRINT_SHIPPING)}
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.ORDER_VISIBLE_ONLY_PROFILE_ID, FAQ.ORDER_VISIBLE_ONLY_PROFILE_ID)}
          </VStack>
        ) : null}
      </XCard>
    )
  }

  _renderCheckBoxOptionProduct = () => {
    const { arrowFunctionShowAndHide } = this.state
    return (
      <XCard p='4' borderRadius='xl' w='full'>
        {this._renderTitleGroupName(p.op.t('StoreSetting.HeaderProduct'), HEADER_CONSTANT.PRODUCT)}
        {arrowFunctionShowAndHide.product ? (
          <VStack space='2' pt='2'>
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.PRODUCT_LIST)}
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.PRODUCT_ADD)}
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.PRODUCT_EDIT)}
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.PRODUCT_DELETE)}
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.PRODUCT_PULL)}
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.PRODUCT_STOCK_VIEW)}
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.PRODUCT_STOCK_EDIT)}
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.PRODUCT_COST)}
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.PRODUCT_PRICE)}
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.PRODUCT_CODE_EDIT_UPC)}
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.PRODUCT_CODE_EDIT_SKU)}
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.PRODUCT_WEIGHT)}
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.PRODUCT_SHIPPING_QTY_RATE)}
          </VStack>
        ) : null}
      </XCard>
    )
  }

  _renderCheckBoxOptionPayment = () => {
    const { arrowFunctionShowAndHide } = this.state
    return (
      <XCard p='4' borderRadius='xl' w='full'>
        {this._renderTitleGroupName(p.op.t('e_modules._11'), HEADER_CONSTANT.PAYMENT)}
        {arrowFunctionShowAndHide.payment ? (
          <VStack space='2' pt='2'>
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.PAYMENT_LIST)}
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.PAYMENT_ADD)}
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.PAYMENT_EDIT)}
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.PAYMENT_DELETE)}
          </VStack>
        ) : null}
      </XCard>
    )
  }

  _renderCheckBoxOptionShipping = () => {
    const { arrowFunctionShowAndHide } = this.state
    return (
      <XCard p='4' borderRadius='xl' w='full'>
        {this._renderTitleGroupName(p.op.t('e_modules._12'), HEADER_CONSTANT.SHIPPING)}
        {arrowFunctionShowAndHide.shipping ? (
          <VStack space='2' pt='2'>
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.SHIPPING_LIST)}
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.SHIPPING_ADD)}
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.SHIPPING_EDIT)}
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.SHIPPING_DELETE)}
          </VStack>
        ) : null}
      </XCard>
    )
  }

  _renderCheckBoxOptionDashboard = () => {
    const { arrowFunctionShowAndHide } = this.state
    return (
      <XCard p='4' borderRadius='xl' w='full'>
        {this._renderTitleGroupName(p.op.t('dashboard'), HEADER_CONSTANT.BIN_DASHBOARD)}
        {arrowFunctionShowAndHide.bin_dashboard ? (
          <VStack space='2' pt='2'>
            {this._renderCheckRow(CONS.DASHBOARD_PERM_REPORTS.bin_dashboard)}
          </VStack>
        ) : null}
      </XCard>
    )
  }

  _renderCheckBoxOptionReport = () => {
    const { arrowFunctionShowAndHide } = this.state
    return (
      <XCard p='4' borderRadius='xl' w='full'>
        {this._renderTitleGroupName(p.op.t('e_modules._16'), HEADER_CONSTANT.REPORT)}
        {arrowFunctionShowAndHide.report ? (
          <VStack space='2' pt='2'>
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.REPORT_DAILY_SALES)}
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.REPORT_COMPLETE_SALES)}
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.REPORT_HELPER)}
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.REPORT_RESELLER)}
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.REPORT_SALES_BY_CHANNELS)}

            {this._renderCheckRow(CONS.PERM_STORE_HELPER.REPORT_STOCK)}

            {this._renderCheckRow(CONS.PERM_STORE_HELPER.REPORT_STOCK_HISTORY)}

            {this._renderCheckRow(CONS.PERM_STORE_HELPER.REPORT_PRODUCT_DAILY_SALES)}

            {this._renderCheckRow(CONS.PERM_STORE_HELPER.REPORT_PAYMENT)}

            {this._renderCheckRow(CONS.PERM_STORE_HELPER.REPORT_SHIPPED)}
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.REPORT_REQUISITION)}

            {this._renderCheckRow(CONS.PERM_STORE_HELPER.REPROT_STOCK_CARD)}

            {this._renderCheckRow(CONS.PERM_STORE_HELPER.REPORT_PEAK_ACCOUNT)}
          </VStack>
        ) : null}
      </XCard>
    )
  }

  _renderCheckBoxOptionUG = () => {
    const { arrowFunctionShowAndHide } = this.state
    return (
      <XCard p='4' borderRadius='xl' w='full'>
        {this._renderTitleGroupName('กลุ่มสมาชิก', HEADER_CONSTANT.UG)}
        {arrowFunctionShowAndHide.ug ? (
          <VStack space='2' pt='2'>
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.UG_LIST)}
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.UG_ADD)}
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.UG_EDIT)}
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.UG_DELETE)}
          </VStack>
        ) : null}
      </XCard>
    )
  }

  _renderCheckBoxOptionContact = () => {
    const { arrowFunctionShowAndHide } = this.state
    return (
      <XCard p='4' borderRadius='xl' w='full'>
        {this._renderTitleGroupName('ลูกค้า', HEADER_CONSTANT.CONTACT)}
        {arrowFunctionShowAndHide.contact ? (
          <VStack space='2' pt='2'>
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.CONTACT_LIST)}
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.CONTACT_ADD)}
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.CONTACT_EDIT)}
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.CONTACT_DELETE)}
          </VStack>
        ) : null}
      </XCard>
    )
  }

  _renderCheckBoxOptionMember = () => {
    const { arrowFunctionShowAndHide } = this.state
    return (
      <XCard p='4' pt='2' borderRadius='xl' w='full'>
        {this._renderTitleGroupName('รายการสมาชิกในกลุ่ม', HEADER_CONSTANT.MEMBER)}
        {arrowFunctionShowAndHide.member ? (
          <VStack space='2'>
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.MEMBER_LIST)}
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.MEMBER_ADD)}
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.MEMBER_EDIT)}
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.MEMBER_DELETE)}
          </VStack>
        ) : null}
      </XCard>
    )
  }

  _renderCheckBoxOptionPG = () => {
    const { arrowFunctionShowAndHide } = this.state
    return (
      <XCard p='4' borderRadius='xl' w='full'>
        {this._renderTitleGroupName(p.op.t('e_modules._21'), HEADER_CONSTANT.PG)}
        {arrowFunctionShowAndHide.pg ? (
          <VStack space='2' pt='2'>
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.PG_LIST)}
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.PG_ADD)}
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.PG_EDIT)}
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.PG_DELETE)}
          </VStack>
        ) : null}
      </XCard>
    )
  }

  _renderCheckBoxOptionWarehouse = () => {
    const { arrowFunctionShowAndHide } = this.state
    return (
      <XCard p='4' borderRadius='xl' w='full'>
        {this._renderTitleGroupName('คลัง', HEADER_CONSTANT.WH)}
        {arrowFunctionShowAndHide.wh ? (
          <VStack space='2' pt='2'>
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.WH_LIST)}
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.WH_ADD)}
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.WH_EDIT)}
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.WH_DELETE)}
          </VStack>
        ) : null}
      </XCard>
    )
  }

  _renderCheckBoxOptionMKP = () => {
    const { arrowFunctionShowAndHide } = this.state
    return (
      <XCard p='4' borderRadius='xl' w='full'>
        {this._renderTitleGroupName('ช่องทางขาย', HEADER_CONSTANT.MKP)}
        {arrowFunctionShowAndHide.mkp ? (
          <VStack space='2' pt='2'>
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.MKP_LIST)}
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.MKP_ADD)}
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.MKP_EDIT)}
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.MKP_DELETE)}
          </VStack>
        ) : null}
      </XCard>
    )
  }

  _renderCheckBoxOptionErp = () => {
    const { arrowFunctionShowAndHide } = this.state
    return (
      <XCard p='4' borderRadius='xl' w='full'>
        {this._renderTitleGroupName('ระบบบัญชี', HEADER_CONSTANT.ERP)}
        {arrowFunctionShowAndHide.mkp ? (
          <VStack space='2' pt='2'>
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.ERP_CHANNEL_LIST)}
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.ERP_DOC_CREATE)}
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.ERP_DOC_CANCEL)}
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.ERP_CHANNEL_ADD)}
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.ERP_CHANNEL_EDIT)}
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.ERP_CHANNEL_SET_ACTIVE)}
            {this._renderCheckRow(CONS.PERM_STORE_HELPER.ERP_CHANNEL_DELETE)}
          </VStack>
        ) : null}
      </XCard>
    )
  }

  _renderCardTitle = () => {
    const { departmentName, departmentNameDescription } = this.state
    return (
      <VStack w='full' p='2' pb='0'>
        <XCard p='4' borderRadius='xl' w='full'>
          {this._renderTitleName()}
          <HStack style={{ paddingBottom: 0 }}>
            <XText variant='active'>{departmentName}</XText>
          </HStack>
          {this._renderTitleDescription()}
          <HStack style={{ paddingBottom: 0 }}>
            <XText variant='active'>{departmentNameDescription}</XText>
          </HStack>
          {/* {this._renderPermissionLabel()} */}
        </XCard>
      </VStack>
    )
  }

  _renderCheckBoxList = () => (
    <VStack w='full' p='2' space='2'>
      {this._renderCheckBoxOptionOrder()}
      {this._renderCheckBoxOptionProduct()}
      {this._renderCheckBoxOptionPayment()}
      {this._renderCheckBoxOptionShipping()}
      {this._renderCheckBoxOptionDashboard()}
      {this._renderCheckBoxOptionReport()}
      {this._renderCheckBoxOptionContact()}
      {this._renderCheckBoxOptionUG()}
      {this._renderCheckBoxOptionMember()}
      {this._renderCheckBoxOptionPG()}
      {/* {this._renderCheckBoxOptionWarehouse()} */}
      {this._renderCheckBoxOptionMKP()}
      {this._renderCheckBoxOptionErp()}
    </VStack>
  )

  _renderViewMode = () => (
    <Box w='full'>
      {this._renderCardTitle()}
      {this._renderCheckBoxList()}
    </Box>
  )

  _renderCardTitleCreatOrEditMode = () => {
    const { departmentName, departmentNameDescription } = this.state
    return (
      <VStack w='full' p='2' pb='0'>
        <XCard p='4' borderRadius='xl' w='full'>
          {this._renderTitleName()}
          <XInput
            // onRef={r => xUtil.pushNativeBaseRef(this.inputRefs, `mProduct_price_${idx}_${index}`, r)}
            // onFocus={() => this.setState({ currentRef: `mProduct_price_${idx}_${index}` })}
            // style={{
            //   flex: 0,
            //   // width: '100%',
            //   textAlign: 'left',
            //   // paddingBottom: isIOS ? 2 : 5,
            //   height: 30,
            //   fontSize: STYLES.FONT_SIZE_NORMAL,
            //   // borderBottomWidth: 0,
            //   // color: TEXT_PRIMARY,
            // }}
            keyboardType='default'
            // returnKeyType='done'
            placeholder='เช่น แผนกจัดส่ง'
            value={departmentName}
            maxLength={250}
            onChangeText={(text) => this._onChangeTextDepartmentName(text)}
          />
          {this._renderTitleDescription()}
          <XInput
            // onRef={r => xUtil.pushNativeBaseRef(this.inputRefs, `mProduct_price_${idx}_${index}`, r)}
            // onFocus={() => this.setState({ currentRef: `mProduct_price_${idx}_${index}` })}
            // style={{
            //   flex: 0,
            //   // width: '100%',
            //   textAlign: 'left',
            //   // paddingBottom: isIOS ? 2 : 5,
            //   height: 30,
            //   fontSize: STYLES.FONT_SIZE_NORMAL,
            //   // borderBottomWidth: 0,
            //   // color: TEXT_PRIMARY,
            // }}
            keyboardType='default'
            // returnKeyType='done'
            // placeholder={'เช่น แผนกจัดส่ง'}
            value={departmentNameDescription}
            maxLength={250}
            onChangeText={(text) => this._onChangeTexteDescriptione(text)}
          />
        </XCard>
      </VStack>
    )
  }

  _renderEditAndCreateMode = () => (
    <Box w='full'>
      {this._renderCardTitleCreatOrEditMode()}
      {this._renderCheckBoxList()}
    </Box>
  )

  _renderDeleteBtn = () => {
    if (this.state.mode !== 'EDIT') {
      return null
    }
    return (
      <Box alignItems='center' justifyContent='center'>
        <TouchableOpacity
          style={{
            backgroundColor: COLORS.BRAND_Danger,
            height: 30,
            width: 150,
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 7,
            marginBottom: 4,
          }}
          onPress={() => this._deletePermission()}>
          <XText color='white'>ลบสิทธิ์การใช้งานนี้</XText>
        </TouchableOpacity>
      </Box>
    )
  }

  render() {
    // TODO ซับซ้อนประมาณนึง มีอะไรถาม ARTID ได้เลย
    const { mode } = this.state
    if (_.isNil(mode)) {
      return this._renderHeader()
    }
    return (
      <XContainer>
        {this._renderHeader()}
        <XContent>
          {mode === MODE.VIEW ? this._renderViewMode() : this._renderEditAndCreateMode()}
          {this._renderDeleteBtn()}
        </XContent>
        {/* {loading} */}
      </XContainer>
    )
  }
}
