import { IApiOptions, ISubscriberAddress } from 'x/types'

import _ from 'lodash'
import api from './api'

interface IGetSubscriberAddressRequestBody {
  address_id: number
}

export async function getSubscriberAddress(params: IGetSubscriberAddressRequestBody): Promise<ISubscriberAddress> {
  const { address_id } = params

  if (!address_id) {
    throw new Error('getSubscriberAddress:: Need address_id')
  }

  const apiOptions: IApiOptions = {
    // messages: {
    //   successMsg: 'สำเร็จ',
    //   errorMsg: 'ล้มเหลว',
    // },
    showSpinner: false,
    isApiV2: true,
    axiosOptions: {
      timeout: 18000,
    },
  }

  try {
    // console.log('In getSubscriberAddress:: reqBody => ', params)
    const res = await api.post<IGetSubscriberAddressRequestBody, ISubscriberAddress>('subscription/address/get', params, apiOptions)
    // console.log('In getSubscriberAddress:: response => ', res)

    if (_.isNil(res.id)) {
      throw new Error('getSubscriberAddress:: No "id" in response')
    }

    return res
  } catch (err) {
    console.log('In getSubscriberAddress:: err => ', err)
    throw err
  }
}

interface ICreateSubscriberAddressRequestBody extends Omit<ISubscriberAddress, 'id'> {
  owner_store_id: number // the user_id whom is the owner of this store_id will be used for associating the address with
  // The reason to use "owner_store_id" is that helper can purchase subscription on behave of the owner.
}

export async function createSubscriberAddress(params: ICreateSubscriberAddressRequestBody): Promise<ISubscriberAddress> {
  const { owner_store_id } = params

  if (!owner_store_id) {
    throw new Error('createSubscriberAddress:: Need owner_store_id')
  }

  const apiOptions: IApiOptions = {
    // messages: {
    //   successMsg: 'สำเร็จ',
    //   errorMsg: 'ล้มเหลว',
    // },
    showSpinner: false,
    isApiV2: true,
    axiosOptions: {
      timeout: 18000,
    },
  }

  try {
    // console.log('In createSubscriberAddress:: reqBody => ', params)
    const res = await api.post<ICreateSubscriberAddressRequestBody, ISubscriberAddress>('subscription/address/create', params, apiOptions)
    // console.log('In createSubscriberAddress:: response => ', res)

    if (_.isNil(res.id)) {
      throw new Error('createSubscriberAddress:: No "id" in response')
    }

    return res
  } catch (err) {
    console.log('In createSubscriberAddress:: err => ', err)
    throw err
  }
}



export async function updateSubscriberAddress(params: ISubscriberAddress): Promise<ISubscriberAddress> {
  const { id } = params

  if (!id) {
    throw new Error('updateSubscriberAddress:: Need id')
  }

  const apiOptions: IApiOptions = {
    // messages: {
    //   successMsg: 'สำเร็จ',
    //   errorMsg: 'ล้มเหลว',
    // },
    showSpinner: false,
    isApiV2: true,
    axiosOptions: {
      timeout: 18000,
    },
  }

  try {
    // console.log('In updateSubscriberAddress:: reqBody => ', params)
    const res = await api.post<ISubscriberAddress, ISubscriberAddress>('subscription/address/update', params, apiOptions)
    // console.log('In updateSubscriberAddress:: response => ', res)

    if (_.isNil(res.id)) {
      throw new Error('updateSubscriberAddress:: No "id" in response')
    }

    return res
  } catch (err) {
    console.log('In updateSubscriberAddress:: err => ', err)
    throw err
  }
}
