import React from 'react'
import _ from 'lodash'
import { delay, handleAfterCheckPdpaConfirmationStatus } from 'x/utils/util'
import actions from 'x/config/actions'
import { getAuthenticationToken, setAuthenticationToken } from 'x/utils/authentication'
// import XInput from '../../components/XInput'
import { COLORS } from 'x/config/styles'
import CONS from 'x/config/constants'
import * as util from 'x/utils/util'
import p from 'x/config/platform-specific'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import Spinner from 'xui/components/XSpinner'
import PdpaConfirmation from 'xui/components/singleton/PdpaConfirmation'
import * as NavActions from 'x/utils/navigation'
import { IXDateRangeSelectorOptionKey, IXScreenProps } from 'x/index'

interface ILandingViewProps extends IXScreenProps {
  dispatch: (params: any) => void
  fetchInitializeData: (params: any) => void
}

export default class LandingView extends React.Component<ILandingViewProps> {
  static navigationOptions: () => {
    headerBackTitle: null
    title: 'LandingView'
  }

  async componentDidMount() {
    await this._appInitialize()
  }

  _appInitialize = async () => {
    const isAuth = await this._isAuth()
    if (!isAuth) {
      await p.op.signOut()
      this._navToLogin()
      return
    }

    // const refreshedToken = await this._refreshToken()

    const { navigation } = this.props
    const response: any = await new Promise((resolve) => {
      this.props.dispatch({
        type: actions.LOGIN_REFRESH_APP_TOKEN,
        successCallback: resolve,
        failedCallback: () => resolve(null),
      })
    })

    // console.log('response => ', response)

    if (response && _.isString(response.token)) {
      await setAuthenticationToken(response.token)
      await delay(50)
    }

    if (response) {
      const missing = _.has(response, 'missing') ? response.missing : null
      if (missing === 'email') {
        navigation.dispatch(NavActions.switchToOnboardingEmail({ email: response.default }))
        return
      }
      if (missing === 'username') {
        navigation.dispatch(NavActions.switchToOnboardingUsername)
        return
      }
      if (missing === 'store') {
        navigation.dispatch(NavActions.switchToOnboardingFirstStore)
        return
      }
      // if (!missing) {
      //   navigation.dispatch(NavActions.switchToAuthStackNavigator)
      //   return
      // }
    }

    // console.log('_appInitialize refreshedToken => ', refreshedToken)
    // console.log('_appInitialize typeof refreshedToken => ', typeof refreshedToken)
    // if (!_.isString(refreshedToken)) {
    //   await p.op.signOut()
    //   this._navToLogin()
    //   return
    // }

    await delay(50) // หน่วงเพียงเพื่อให้ปรับใช้ token ตัวใหม่สักครู่
    const initData = await this._initData()
    // console.log('_appInitialize initData => ', initData)
    if (initData && initData.ss && initData.ss.id) {
      const initStoreId = initData.ss.id

      if (_.has(initData, ['profile', 'is_consented']) && initData.profile.is_consented === false) {
        const confirmationStatus = await PdpaConfirmation.show()
        // console.log('_handleNavWhenReceivedLoginResponse confirmationStatus => ', confirmationStatus)
        await handleAfterCheckPdpaConfirmationStatus(confirmationStatus)
        if (confirmationStatus !== 'accepted') {
          this._navToLogin()
          return
        }
      }

      this._navToAuth(initStoreId)
    } else {
      await p.op.signOut()
      this._navToLogin()
    }
  }

  _initData = async (): Promise<{ [key: string]: any } | null> => {
    const { fetchInitializeData, navigation } = this.props

    const ssodKey = (await p.op.storageGet(
      CONS.STATE_SAVE_KEYS.SELECTED_STORE_ORDERS_DATES_OPTION_KEY,
      false
    )) as IXDateRangeSelectorOptionKey
    const additionParams: {
      created_at_from?: string
      created_at_to?: string
    } = {}
    if (_.isString(ssodKey)) {
      const ssodDates = util.getDateRangeFromOption(ssodKey)
      additionParams.created_at_from = ssodDates.begin.format(CONS.SERVER_DATETIME_FORMAT)
      additionParams.created_at_to = ssodDates.end.format(CONS.SERVER_DATETIME_FORMAT)
    }

    const res: { [key: string]: any } = await new Promise((resolve) => {
      fetchInitializeData({
        successCallback: resolve,
        failedCallback: () => resolve(null),
        ...additionParams,
      })
    })
    // if (res && res.ss && res.ss.store_id) {
    //   navigation.setParams({ store_id: res.ss.store_id })
    // }
    return res
  }

  _navToAuth = async (storeId: number) => {
    await delay(100)
    // this.props.navigation.dispatch(switchToAuthSwitchNavigator({ store_id: storeId }))
    this.props.navigation.dispatch(NavActions.resetToAuthStackNavigator({ store_id: storeId }))

    // // ถ้าต้องการ dev หน้าไหนตรงๆ ให้เปลี่ยนชื่อ View แรกที่จะ nav ไปที่นี่
    // this.props.navigation.navigate(
    //   'AuthSwitchNavigator',
    //   { store_id: storeId },
    //   NavigationActions.navigate({
    //     // routeName: 'StoreOrderListStackNavigator',
    //     // routeName: 'ProductListListStackNavigator',
    //     key: 'QuickShipViewStackNavigator',
    //     routeName: 'QuickShipViewStackNavigator',
    //     params: { store_id: storeId },
    //     // @ts-ignore
    //     action: NavigationActions.init({
    //       params: { store_id: storeId },
    //       // key: 'QuickShipView',
    //       // index: 0,
    //       // actions: [
    //       //   NavigationActions.navigate({
    //       //     routeName: 'QuickShipView',
    //       //     params: { store_id: storeId },
    //       //   }),
    //       // ],
    //     }),
    //   })
    // )

    // Linking.openURL(`http://localhost:3000/home/orderlist/myTasks_confirmOrder?store_id=${storeId}`)
  }

  _navToLogin = () => {
    // this.props.navigation.dispatch(switchToLoginView)
    this.props.navigation.dispatch(NavActions.resetToFirstPage)
  }

  _isAuth = async (): Promise<boolean> => {
    const token = await getAuthenticationToken()
    // console.log('token => ', token)
    return _.isString(token) && token.length > 5
  }

  // _refreshToken = async (): Promise<string | null> => {
  //   const refreshedTokenObj: any = await new Promise((resolve) => {
  //     this.props.dispatch({
  //       type: actions.LOGIN_REFRESH_APP_TOKEN,
  //       successCallback: resolve,
  //       failedCallback: () => resolve(null),
  //     })
  //   })
  //   // console.log('refreshedTokenObj => ', refreshedTokenObj)
  //   if (refreshedTokenObj && refreshedTokenObj.token) {
  //     return refreshedTokenObj.token
  //   }
  //   return null
  // }

  render() {
    return (
      <XContainer>
        <XContent contentContainerStyle={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <Spinner color={COLORS.APP_MAIN} />
        </XContent>
      </XContainer>
    )
  }
}
