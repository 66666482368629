import React, { Component } from 'react'
import { log } from 'x/utils/util'
import _ from 'lodash'
import HStack from 'xui/components/HStack'
import { IBarcodeScannerOutput, IProductVariantItemProps } from 'x/index'
import { COLORS, STYLES } from 'x/config/styles'

import CONS from 'x/config/constants'
import XInput from 'xui/components/XInput'
import XText from 'xui/components/XText'
import VStack from 'xui/components/VStack'
import BarcodeScannerModalButton from 'xui/components/BarcodeScannerModalButton'
import XIcon, { XIconFamily } from 'xui/components/XIcon'
import { LayoutChangeEvent, Platform } from 'react-native'
import XIconButton from 'xui/components/XIconButton'

const { NAME, COST, PRICE, PRICE_RETAIL, QTY, AVAILABLE_QTY, WEIGHT, H, SKU, UPC } = CONS.PRODUCT_ATTR

interface IProductVariantItemState {
  containerWidth: number
}

export default class ProductVariantItem extends Component<IProductVariantItemProps, IProductVariantItemState> {
  // inputRef: any

  // state = {
  //   isVisibleBarcodeCamera: false,
  // }

  constructor(props) {
    super(props)
    this.state = {
      containerWidth: 320,
    }
  }

  // componentDidMount() {
  //   this._onInputRef()
  // }

  // shouldComponentUpdate(prevProps: IProductVariantItemProps, prevState) {
  //   if (isDiffAccuracy(prevState, this.state, ['isVisibleBarcodeCamera'])) {
  //     return true
  //   }
  //   if (isDiffAccuracy(prevProps, this.props, ['value', 'isEditable', 'variantIndex'])) {
  //     return true
  //   }

  //   if (_.has(this.props, 'extraData')) {
  //     if ((!prevProps.extraData && this.props.extraData) || prevProps.extraData !== this.props.extraData) {
  //       return true
  //     }
  //   }

  //   return false
  // }
  // _onInputRef = () => {
  //   const { onInputRef } = this.props
  //   if (_.isFunction(onInputRef) && this.inputRef) {
  //     onInputRef(this.inputRef)
  //   }
  // }
  // _onNavRef = (navKey: string): void => {
  //   const { onNavRef } = this.props
  //   if (_.isFunction(onNavRef) && this.inputRef) {
  //     onNavRef(navKey)
  //   }
  // }
  // _onNavFocus = (navKey: string, inputRef: any): void => {
  //   const { onNavFocus } = this.props
  //   if (_.isFunction(onNavFocus) && this.inputRef) {
  //     onNavFocus(navKey, inputRef)
  //   }
  // }

  _onBarcodeRead = async (barcodeOutput: IBarcodeScannerOutput) => {
    const barcodeData = barcodeOutput.data
    this._onInputChange(barcodeData)
  }

  _onInputChange = (newText: string) => {
    const { variantKey, variantIndex, onInputChange, isMoney = false, isNumber = false, isInteger = false } = this.props
    log(`_onInputChange variantKey=${variantKey} variantIndex=${variantIndex} newText => `, newText)

    let edittingNewText = _.cloneDeep(newText)
    // สามารถใช้งานภาษาไทยได้แล้วทั้ง SKU UPC อ้างอิงจาก https://app.clickup.com/t/86cve7ftp
    // const regX = /[^\x00-\x7F]+/g
    // let removeThai
    if (variantKey === 'sku' || variantKey === 'upc') {
      // removeThai = newText.match(regX)
      // ลบ spaces ออก
      edittingNewText = newText.replace(/\s+/g, '')
    }
    // if (!_.isNil(removeThai)) {
    //   return
    // }
    if (_.isFunction(onInputChange)) {
      onInputChange({
        idx: variantIndex,
        key: variantKey,
        value: edittingNewText,
        isMoney,
        isNumber,
        isInteger,
      })
    }
  }

  // _onPressOpenBarcodeCamera = () => this.setState({ isVisibleBarcodeCamera: true })

  // _onPressCloseBarcodeCamera = () => this.setState({ isVisibleBarcodeCamera: false })

  _onLayout = (evt: LayoutChangeEvent) => {
    try {
      const newWidth = evt.nativeEvent.layout.width
      if (newWidth && this.state.containerWidth !== newWidth) {
        this.setState({ containerWidth: newWidth })
      }
    } catch (error) {}
  }

  render() {
    const {
      variantKey,
      variantIndex,
      label,
      additionalLabel = null,
      icon = null,
      iconFamily = 'Ionicons',
      placeholder = null,
      isEditable = false,
      isMoney = false,
      isNumber = false,
      isInteger = false,
      isBarcode = false,
      value = '',
      enableTopSeparator = false,
      // CustomNav
      navKey = null,
      onPressNavToReservedProductInOrders,
    } = this.props
    // log(this, `re-render key=${variantKey} idx=${variantIndex}`)
    // const variantBgColor = (variantIndex % 2) === 0 ? 'white' : COLORS.BG_LIGHT_GREY
    let keyboardType = 'default'
    if (isMoney || isNumber || isInteger) {
      keyboardType = 'numeric'
    }

    // const disabledAndroidQuickFixedInputWrapper = !isMoney && !isNumber && !isInteger
    // const isVisibleBarcodeCamera = _.has(this.state, 'isVisibleBarcodeCamera') ? this.state.isVisibleBarcodeCamera : false

    // let validationType
    // if (isNumber) {
    //   validationType = 'float'
    // }
    // if (isMoney) {
    //   validationType = 'money'
    // }
    // if (isInteger) {
    //   validationType = 'integer'
    // }

    let isMultiline = false

    if (variantKey === CONS.PRODUCT_ATTR.NAME) {
      isMultiline = true
    }

    const isReservedProductButtonVisible =
      !isEditable && _.includes([AVAILABLE_QTY], variantKey) && _.isFunction(onPressNavToReservedProductInOrders)

    const { containerWidth } = this.state
    return (
      <HStack onLayout={this._onLayout} w='full' py='1' space='1' flexWrap='wrap'>
        {/* <HStack w={containerWidth > 350 ? '180px' : '130px'} alignItems='center' space='1'> */}
        <HStack w='174px' space='1'>
          {/* {icon === 'scale' ? ( */}
          {/* <IconMC style={LOCAL_STYLES.variantIcon} name={icon} /> */}
          {/* ) : ( */}
          {/* <Icon style={LOCAL_STYLES.variantIcon} name={icon} /> */}
          {/* )} */}
          <HStack w='28px' h='34px' alignItems='center'>
            {icon ? <XIcon name={icon} family={iconFamily as XIconFamily} inactive /> : null}
          </HStack>

          <VStack flex={1}>
            <XText pt='7px' variant='inactive'>
              {label}
            </XText>
            {additionalLabel ? (
              <XText variant='inactive' fontSize='xs'>
                {additionalLabel}
              </XText>
            ) : null}
          </VStack>
        </HStack>
        {/* <Box flex={1} bg='blue.300' alignItems='flex-end' justifyContent='flex-end'> */}
        <HStack
          // _web={{ w: '36', maxW: '36' }}
          // w='40'
          // w='120px'
          // _web={{ w: '130px' }}
          // w={containerWidth > 500 ? '300px' : '150px'}
          flex={1}
          space='1'
          alignItems='center'>
          {isBarcode && isEditable ? (
            <BarcodeScannerModalButton
              // btnStyle={{ marginRight: 4 }}
              saveStateKey={CONS.STATE_SAVE_KEYS.BARCODE_SCANNER_MODAL}
              label={variantKey.toUpperCase()}
              headerTitle={`สแกน${label}`}
              onBarcodeScanned={this._onBarcodeRead}
            />
          ) : null}

          {isReservedProductButtonVisible ? (
            <XIconButton
              variant='outline'
              // w='11'
              // h='11'
              family='AntDesign'
              name='profile'
              onPress={onPressNavToReservedProductInOrders}
            />
          ) : null}

          {!isEditable ? (
            <HStack flex={1} alignItems='center' justifyContent='flex-end' space='1'>
              {isMoney ? (
                <XText w='28px' textAlign='center'>
                  ฿
                </XText>
              ) : null}
              {variantKey === 'weight' ? (
                <XText w='28px' textAlign='center'>
                  กรัม
                </XText>
              ) : null}
              <XText flex={1} textAlign='right'>
                {value.toString()}
              </XText>
            </HStack>
          ) : (
            <XInput
              flex={1}
              // w='full'
              // FIXME: Quick Fix web layout overflow
              // _web={{ w: isBarcode ? 98 : 80, overflow: 'hidden' }}
              // style={{
              //   ...Platform.select({
              //     web: {
              //       width: isBarcode ? 98 : 80,
              //     },
              //   }),
              // }}
              // flex='1'
              // size='1'
              // onRef={(r) => r && (this.inputRef = r)}
              // keyboardType={keyboardType}
              // onNavFocus={this._onNavFocus}
              // style={LOCAL_STYLES.variantInput}
              // align='right'
              // textAlign={isMoney || isNumber || isInteger ? 'right' : undefined}
              textAlign={_.includes([NAME, SKU, UPC], variantKey) ? 'left' : 'right'}
              textAlignVertical={_.includes([NAME, SKU, UPC], variantKey) ? 'top' : 'center'}
              // navKey={navKey}
              // onNavRef={this._onNavRef}
              // minH={variantKey === NAME && Platform.OS === 'web' ? '150px' : undefined}
              selectTextOnFocus
              placeholder={placeholder}
              isDisabled={!isEditable}
              isMoney={isMoney}
              isInteger={isInteger}
              isNumber={isNumber}
              // validationType={validationType}
              unitLabel={variantKey === WEIGHT ? 'กรัม' : undefined}
              // unitLabelWidth='4'
              onChangeText={this._onInputChange}
              value={value.toString()}
              multiline={_.includes([NAME, SKU, UPC], variantKey) || isMultiline}
              style={_.includes([NAME, SKU, UPC], variantKey) && Platform.OS === 'web' ? { minHeight: 64 } : undefined}
              // onBlur={() => {
              //   // const ipRef = this.refs[`v_${key}_${idx}`]
              //   // if (ipRef && ipRef.forceUpdate) {
              //   //   ipRef.forceUpdate()
              //   // }
              //   this.forceUpdate()
              // }}
              // numberOfLines={1}
              // disabledAndroidWrapper={disabledAndroidQuickFixedInputWrapper}
            />
          )}
        </HStack>
        {/* </Box> */}
      </HStack>
    )
  }
}

const LOCAL_STYLES: { [key: string]: any } = {
  vdRow: {
    padding: 4,
  },
  vdCol: {
    padding: 4,
  },
  colMiddleLeft: {
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  colMiddleCenter: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  colMiddleRight: {
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  variantIcon: {
    color: COLORS.TEXT_INACTIVE,
    fontSize: STYLES.FONT_ICON_NORMAL,
    height: STYLES.FONT_ICON_NORMAL,
    width: STYLES.FONT_ICON_NORMAL,
    textAlign: 'center',
    // paddingRight: 4
  },
  variantLabel: {
    // color: COLORS.TEXT_INACTIVE,
    color: COLORS.TEXT_ACTIVE,
    fontSize: STYLES.FONT_SIZE_NORMAL,
    // fontWeight: 'bold',
    marginTop: 0,
  },
  variantInput: {
    minHeight: 44,
  },
  btnRemoveVariant: {
    fontSize: STYLES.FONT_ICON_LARGER,
    color: COLORS.FORM_ERROR,
  },
  labelInactive: {
    color: COLORS.TEXT_INACTIVE,
    fontSize: STYLES.FONT_SIZE_NORMAL,
    marginTop: 0,
  },
  labelInactiveSmall: {
    color: COLORS.TEXT_INACTIVE,
    fontSize: STYLES.FONT_SIZE_SMALLER,
    marginTop: 0,
  },
  labelActive: {
    color: COLORS.TEXT_ACTIVE,
    fontSize: STYLES.FONT_SIZE_NORMAL,
    marginTop: 0,
  },
  iconInButtonWithBottomLabel: {
    marginLeft: 8,
    marginRight: 8,
    paddingTop: 0,
    paddingBottom: 4,
  },
  labelActiveDarkBottomIconButton: {
    position: 'absolute',
    width: '100%',
    textAlign: 'center',
    bottom: 3,
    color: 'white',
    fontWeight: 'bold',
    fontSize: STYLES.FONT_SIZE_SMALLEST,
  },
}
