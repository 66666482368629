import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as ContactStateActions from 'x/modules/contact/ContactState'
import * as AddressActions from 'x/modules/address/AddressState'
import AddressListView from './AddressListView'
import { selectedAddresses, shouldFetchList, getStoreSelected } from 'x/redux/selectors'

export default connect(
  state => ({
    selectedAddresses: selectedAddresses(state),
    shouldFetchList: shouldFetchList(state),
    selectedStore: getStoreSelected(state),
  }),
  dispatch => {
    return {
      loadList: bindActionCreators(AddressActions.loadList, dispatch),
      resetList: bindActionCreators(AddressActions.resetList, dispatch),
      load: bindActionCreators(AddressActions.load, dispatch),
      loadToPatch: bindActionCreators(AddressActions.loadToPatch, dispatch),

      resetShouldFetchList: bindActionCreators(AddressActions.resetShouldFetchList, dispatch),
      deleteAddress: bindActionCreators(AddressActions.deleteAddress, dispatch),
      customer: bindActionCreators(AddressActions.getCustomer, dispatch),

      saveNew: bindActionCreators(AddressActions.saveNew, dispatch),
      saveEditing: bindActionCreators(AddressActions.saveEditing, dispatch),
      editOrgAddress: bindActionCreators(AddressActions.editOrgAddress, dispatch),
      createAddress: bindActionCreators(AddressActions.createOrgAddress, dispatch),
      addCustomer: bindActionCreators(ContactStateActions.addCustomer, dispatch),
    }
  }
  // @ts-ignore
)(AddressListView)
