import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import _ from 'lodash'
import dayjs, { Dayjs } from 'dayjs'

import { COLORS } from 'x/config/styles'
import CONS from 'x/config/constants'
import HStack from 'xui/components/HStack'
import VStack from 'xui/components/VStack'
import DatePicker from 'react-datepicker'
// import { Portal } from 'react-overlays'
import BaseDateRangePicker from 'x/components/BaseDateRangePicker'
import XInput from 'xui/components/XInput'
import XText from 'xui/components/XText'

const DATEPICKER_TIME_FORMAT = 'HH:mm'
const DATEPICKER_DATE_FORMAT = 'd/MM/yyyy HH:mm'

const INPUT_HEIGHT = 34
// .react-datepicker-wrapper

// // Fixed DatePicker inside Modal :: https://github.com/Hacker0x01/react-datepicker/issues/1111
// const CalendarContainer = ({ children }) => {
//   const el = document.getElementById('calendar-portal')

//   return <Portal container={el}>{children}</Portal>
// }

// const DateTimeInput = (props) => {
//   // console.log('DateTimeInput props => ', props)
//   const { value, onClick, onChange, onBlur } = props
//   return (
//     <View style={{ flex: 1, width: '100%', height: INPUT_HEIGHT }}>
//       <XInput
//         style={{
//           // ...s.txtNormal,
//           color: COLORS.TEXT_PRIMARY,
//           backgroundColor: COLORS.FORM_PRIMARY_BG,
//           // flex: 1,
//           height: INPUT_HEIGHT,
//           width: '100%',
//           marginLeft: 0,
//           marginTop: 0,
//           marginBottom: 0,
//           paddingLeft: 10,
//         }}
//         keyboardType='default'
//         // placeholder={''}
//         // placeholderTextColor={COLORS.TEXT_INACTIVE}
//         value={value}
//         // onChangeText={handleChangeText}
//         // onChangeText={onChange}
//         onChange={onChange}
//         onBlur={onBlur}
//         multiline={false}
//         blurOnSubmit
//         onFocus={onClick}
//       />
//     </View>
//   )
// }

class DateTimeInput extends React.PureComponent<any> {
  static displayName = 'DateTimeInput'

  render() {
    const { value, onClick, onChange, onBlur, showTime = false, selectedDate } = this.props
    // console.log('DateTimeInput this.props => ', this.props)
    // console.log('DateTimeInput value => ', value)

    // const date = dayjs(value, showTime ? CONS.DISPLAY_DATETIME_FORMAT : CONS.DISPLAY_DATE_FORMAT)
    // console.log('DateTimeInput date => ', date)
    // const dateText = dayjs(value, CONS.DISPLAY_DATETIME_FORMAT).format(showTime ? CONS.DISPLAY_DATETIME_FORMAT : CONS.DISPLAY_DATE_FORMAT)
    // console.log('DateTimeInput dateText => ', dateText)
    const dateText = selectedDate.format(showTime ? CONS.DISPLAY_DATETIME_FORMAT : CONS.DISPLAY_DATE_FORMAT)
    return (
      <TouchableOpacity onPress={onClick}>
        <HStack
          w='full'
          minH='8'
          px='2'
          py='1'
          alignItems='center'
          justifyContent='center'
          // borderWidth='1'
          // borderColor='muted.200'
          // borderRadius='lg'
          // bg='primary.100'
        >
          <XText variant='primary'>{dateText}</XText>
        </HStack>
      </TouchableOpacity>
    )

    return (
      <View style={{ flex: 1, width: '100%', height: INPUT_HEIGHT }}>
        <XInput
          style={{
            // ...s.txtNormal,
            color: COLORS.TEXT_PRIMARY,
            backgroundColor: COLORS.FORM_PRIMARY_BG,
            // flex: 1,
            height: INPUT_HEIGHT,
            width: '100%',
            marginLeft: 0,
            marginTop: 0,
            marginBottom: 0,
            paddingLeft: 10,
          }}
          keyboardType='default'
          // placeholder={''}
          // placeholderTextColor={COLORS.TEXT_INACTIVE}
          value={value}
          // onChangeText={handleChangeText}
          // onChangeText={onChange}
          onChange={onChange}
          onBlur={onBlur}
          multiline={false}
          blurOnSubmit
          onFocus={onClick}
        />
      </View>
    )
  }
}

const { Fragment } = React

export default class DateRangePicker extends BaseDateRangePicker {
  static displayName = 'DateRangePicker'

  // _renderDateTimePicker(key) {
  //   const {showTime, selectedDates, labels} = this.props
  //   const {dates, txtLabels, limit } = this.state
  //   const selectedDate = selectedDates && selectedDates[key]
  //     ? selectedDates[key]
  //     : dates[key]
  //
  //   const txtLabel = labels && labels[key]
  //     ? labels[key]
  //     : txtLabels[key]
  //
  //   let minDate = limit[key] && limit[key].min ? limit[key].min : null
  //   if (dayjs.isMoment(minDate)) {
  //     minDate = minDate.format(showTime ? this.DATETIME_FORMAT : this.DATE_FORMAT)
  //   }
  //
  //   let maxDate = limit[key] && limit[key].max ? limit[key].max : null
  //   if (dayjs.isMoment(maxDate)) {
  //     maxDate = maxDate.format(showTime ? this.DATETIME_FORMAT : this.DATE_FORMAT)
  //   }
  //
  //   // log(`_renderDateTimePicker key=${key} selectedDate => `, selectedDate)
  //   // log(`_renderDateTimePicker key=${key} minDate => `, minDate)
  //   // log(`_renderDateTimePicker key=${key} maxDate => `, maxDate)
  //   return (
  //     <Row
  //       key={key}
  //       style={{
  //         flex: 1,
  //         flexDirection: 'row',
  //         alignItems: 'center',
  //         justifyContent: 'center',
  //       }}>
  //       <VStack style={{
  //         flex: 3,
  //         flexDirection: 'row',
  //         alignItems: 'center',
  //         justifyContent: 'center',
  //         // backgroundColor: 'purple',
  //         padding: 8,
  //       }}>
  //         <div
  //           style={{
  //             fontSize: FONT_SIZE_NORMAL,
  //             color: TEXT_INACTIVE,
  //           }}>
  //           { txtLabel }
  //         </div>
  //       </VStack>
  //       <VStack style={{
  //         flex: 7,
  //         flexDirection: 'row',
  //         alignItems: 'center',
  //         justifyContent: 'center',
  //         // backgroundColor: 'grey',
  //         padding: 8,
  //       }}>
  //         <DatePicker
  //           allowFontScaling={false}
  //           showIcon={false}
  //           style={{
  //             flex: 1,
  //             // borderWidth: 0,
  //           }}
  //           date={ selectedDate }
  //           mode={ showTime ? 'datetime' : 'date' }
  //           placeholder='select date'
  //           // format={ showTime ? this.DATETIME_FORMAT : this.DATE_FORMAT }
  //           format={ showTime ? this.DATETIME_FORMAT : this.DATE_FORMAT }
  //           // minDate='2016-05-01'
  //           // maxDate='2016-06-01'
  //           minDate={ minDate }
  //           maxDate={ maxDate }
  //           // getDateStr={(date) => {
  //           //   log('getDateStr date => ', date)
  //           //   return dayjs(date).format(DATE_LOCAL_DATEPICKER_FORMAT )
  //           //   // return dayjs(date).format(showTime ? this.DATETIME_FORMAT : this.DATE_FORMAT)
  //           // }}
  //           confirmBtnText='ตกลง'
  //           cancelBtnText='ยกเลิก'
  //           customStyles={{
  //             // dateIcon: {
  //             //   position: 'absolute',
  //             //   left: 0,
  //             //   top: 4,
  //             //   marginLeft: 0
  //             // },
  //             dateInput: {
  //               position: 'relative',
  //               borderWidth: 0,
  //               borderBottomWidth: 1,
  //               borderColor: TEXT_INACTIVE,
  //             },
  //             dateText: {
  //               fontSize: FONT_SIZE_NORMAL,
  //               color: APP_MAIN,
  //             },
  //             // ... You can check the source to find the other keys.
  //           }}
  //           onDateChange={ (dateStr, dateWithFormat) => {
  //             // log('onDateChange date => ', date)
  //             // log('onDateChange date2 => ', date2)
  //             this._onDateChange(key, dayjs(dateWithFormat))
  //           }}
  //         />
  //       </VStack>
  //     </Row>
  //   )
  // }

  _computeDisabledTime(
    selectedDate: Dayjs,
    minDate: Dayjs | null,
    maxDate: Dayjs | null
  ): {
    disabledHours?: () => number[]
    disabledMinutes?: () => number[]
    disabledSeconds?: () => number[]
  } {
    // log('_computeDisabledTime selectedDate => ', selectedDate)
    // log('_computeDisabledTime minDate => ', minDate)
    // log('_computeDisabledTime maxDate => ', maxDate)
    const isDisabledValue = (targetNum: number, unit: 'h' | 'm' | 's') => {
      const selectedTime = selectedDate.clone().set(unit, targetNum)
      return (minDate && selectedTime.isBefore(minDate, unit)) || (maxDate && selectedTime.isAfter(maxDate, unit))
    }

    const disableUnitArr = (begin: number, end: number, unit: 'h' | 'm' | 's'): number[] => {
      const disableUnits = []
      for (let i = begin; i < end; i++) {
        if (isDisabledValue(i, unit)) {
          disableUnits.push(i)
        }
      }
      return disableUnits
    }

    return {
      disabledHours: () => disableUnitArr(0, 24, 'h'),
      disabledMinutes: () => disableUnitArr(0, 60, 'm'),
      disabledSeconds: () => disableUnitArr(0, 60, 's'),
    }
  }

  _renderDateTimePickerContainer = (key: 'begin' | 'end' | any, index: number) => {
    const { showTime, selectedDates, labels } = this.props
    const { txtLabels, limit } = this.state
    // const { dates, txtLabels, limit } = this.state
    // const selectedDate = selectedDates && selectedDates[key] ? selectedDates[key] : dates[key]
    // const selectedDate = selectedDates && selectedDates[key] ? selectedDates[key] : dates[key]

    const txtLabel = labels && labels[key] ? labels[key] : txtLabels[key]
    return (
      <HStack
        key={`${key}_${index.toString()}`}
        w='full'
        alignItems='center'
        // style={{ height: INPUT_HEIGHT, flexWrap: 'wrap', marginBottom: 8 }}
        borderWidth='1'
        borderColor='muted.200'
        borderRadius='lg'
        bg='primary.100'>
        <HStack
          w='48px'
          h='full'
          pl='2'
          py='0.5'
          alignItems='center'
          bg='muted.100'
          // // justifyContent='center'
          // style={{
          //   height: INPUT_HEIGHT,
          // }}
        >
          <XText variant='inactive'>{txtLabel}</XText>
        </HStack>
        <VStack
          flex={1}
          //  alignItems='center'
          //  justifyContent='center'
          //  style={{ height: INPUT_HEIGHT }}
        >
          {this._renderDateTimePicker(key)}
        </VStack>
      </HStack>
    )
  }

  handleDateTimeChangeAdapter = (date, key: 'begin' | 'end') => {
    // console.log('handleDateTimeChangeAdapter date => ', date)
    // console.log('handleDateTimeChangeAdapter typeof date => ', typeof date)
    // console.log('handleDateTimeChangeAdapter dayjs(date) => ', dayjs(date))
    // console.log('handleDateTimeChangeAdapter new date(date) => ', new date(date))
    // this.setState({ date })
    // this._onChangeDateTime(dayjs(date).format())
    // this._onDateChange(key, dayjs(date))
    this._onDateChange(key, dayjs(date))
  }

  // แก้ปัญหาพิมพ์ลง input ตรงๆ ไม่ได้:: https://github.com/Hacker0x01/react-datepicker/issues/1414
  handleDateTimeChangeAdapterRaw = (evt: React.FocusEvent<HTMLInputElement> | any, key: 'begin' | 'end') => {
    // console.log('handleDateTimeChangeAdapterRaw evt => ', evt)
    // console.log('handleDateTimeChangeAdapterRaw typeof evt => ', typeof evt)
    const isDateEvt = _.isString(evt)
    let newValue = null

    if (isDateEvt) {
      // newValue = dayjs(evt, this._getDateTimeFormat(true), 'th', false)
      newValue = dayjs(evt, DATEPICKER_DATE_FORMAT)
    }

    const isHtmlInputEvt = _.isNil(newValue) && evt && evt.target
    if (isHtmlInputEvt) {
      // newValue = dayjs((evt.target as HTMLInputElement).value, this._getDateTimeFormat(false), 'th', true)
      // console.log('handleDateTimeChangeAdapterRaw evt.target => ', evt.target)
      newValue = dayjs((evt.target as HTMLInputElement).value, DATEPICKER_DATE_FORMAT)
    }
    // console.log('handleDateTimeChangeAdapterRaw (event.target as HTMLInputElement) => ', (event.target as HTMLInputElement))
    // console.log('handleDateTimeChangeAdapterRaw newValue => ', newValue)
    // console.log('handleDateTimeChangeAdapterRaw newValue => ', newValue)
    if (newValue && _.isFunction(newValue.isValid) && newValue.isValid()) {
      // this._onChangeDateTime(dayjs(newValue).format())
      this._onDateChange(key, dayjs(newValue))
    }
  }

  _getDateTimeFormat = (isMoment = false) => {
    const { showTime = false } = this.props
    return showTime ? CONS.DISPLAY_DATETIME_FORMAT : CONS.DISPLAY_DATE_FORMAT

    // if (showTime) {
    //   return isMoment ? 'D/MM/YYYY   HH:mm น.' : 'd/MM/yyyy   HH:mm น.'
    // }
    // return isMoment ? 'D/MM/YYYY' : 'd/MM/yyyy'
  }

  _renderDateTimePicker = (key: 'begin' | 'end') => {
    const { showTime, selectedDates, labels, minDate: minD, maxDate: maxD } = this.props
    const { txtLabels, limit } = this.state
    // const { dates, txtLabels, limit } = this.state
    // const selectedDate = selectedDates && selectedDates[key] ? selectedDates[key] : dates[key]
    const selectedDate = selectedDates && selectedDates[key] ? selectedDates[key] : dayjs()
    // const txtLabel = labels && labels[key] ? labels[key] : txtLabels[key]

    let minDate = limit[key] && limit[key].min ? limit[key].min : this.MIN_DATE
    let maxDate = limit[key] && limit[key].max ? limit[key].max : this.MAX_DATE
    // if (dayjs.isMoment(minDate)) {
    //   // minDate = minDate.format(showTime ? this.DATETIME_FORMAT : this.DATE_FORMAT)
    //   minDate = minDate.toDate()
    // }
    // if (dayjs.isMoment(maxDate)) {
    //   // maxDate = maxDate.format(showTime ? this.DATETIME_FORMAT : this.DATE_FORMAT)
    //   maxDate = maxDate.toDate()
    // }
    if (minD) {
      // minDate = minDate.format(showTime ? this.DATETIME_FORMAT : this.DATE_FORMAT)
      minDate = minD
    }
    if (maxD) {
      // maxDate = maxDate.format(showTime ? this.DATETIME_FORMAT : this.DATE_FORMAT)
      maxDate = maxD
    }

    return (
      <DatePicker
        key={`DatePicker_${key}`}
        portalId='root-portal'
        // popperContainer={CalendarContainer}
        // popperContainer={({ children }) =>
        //   createPortal(
        //     <div style={{ position: 'absolute', backgroundColor: 'red', width: '100%', height: '100%' }}>{children}</div>,
        //     document.body
        //   )
        // }
        popperModifiers={{
          flip: {
            // เอาไว้บอกตัว popper ว่าเราจะจำกัดขอบเขตไว้แค่ช่วงของใน Browser
            boundariesElement: 'window',
          },
        }}
        // calendarContainer={({ children }) => (
        //   <Modal transparent visible>
        //     <VStack w='full' h='full'>
        //       <HStack position='absolute' h='245px' bg='white'>
        //         {children}
        //       </HStack>
        //     </VStack>
        //   </Modal>
        // )}
        minDate={minDate && _.isFunction(minDate.toDate) ? minDate.toDate() : undefined}
        maxDate={maxDate && _.isFunction(maxDate.toDate) ? maxDate.toDate() : undefined}
        selected={selectedDate.toDate()}
        onChange={(newDate) => this.handleDateTimeChangeAdapter(newDate, key)}
        onChangeRaw={(evt) => this.handleDateTimeChangeAdapterRaw(evt, key)}
        // showTimeInput={true}
        // timeInputLabel='เวลา'
        showTimeSelect={showTime}
        disabledKeyboardNavigation
        timeFormat='HH:mm'
        dateFormat='d/MM/yyyy'
        // dateFormat={DATEPICKER_DATE_FORMAT}
        timeIntervals={1}
        // dateFormat="d MMMM yyyy  HH:mm 'น.'"
        // dateFormat="d MMM yyyy  HH:mm 'น.'"
        // dateFormat={this._getDateTimeFormat()}
        timeCaption='เวลา'
        dropdownMode='select'
        // @ts-ignore
        customInput={<DateTimeInput showTime={showTime} selectedDate={selectedDate} />}
        // strictParsing={true}
      />
    )
  }

  // _renderDateTimePicker(key: 'begin'|'end'): JSX.Element {
  //   const { showTime, selectedDates, labels } = this.props
  //   const { dates, txtLabels, limit } = this.state
  //   const selectedDate = selectedDates && selectedDates[key]
  //     ? selectedDates[key]
  //     : dates[key]
  //
  //   const txtLabel = labels && labels[key]
  //     ? labels[key]
  //     : txtLabels[key]
  //
  //   let minDate: Moment|null = limit[key] && limit[key].min ? limit[key].min : null
  //   // if (dayjs.isMoment(minDate)) {
  //   //   minDate = minDate.format(showTime ? this.DATETIME_FORMAT : this.DATE_FORMAT)
  //   // }
  //   let maxDate: Moment|null = limit[key] && limit[key].max ? limit[key].max : null
  //   // if (dayjs.isMoment(maxDate)) {
  //   //   maxDate = maxDate.format(showTime ? this.DATETIME_FORMAT : this.DATE_FORMAT)
  //   // }
  //
  //
  //   return (
  //     <Fragment key={key}>
  //       <VStack
  //         span={6}
  //         style={{ textAlign: 'center', padding: 8 }}>
  //         { txtLabel }
  //       </VStack>
  //       <VStack span={18}>
  //         <DatePicker
  //           allowClear={false}
  //           style={{ width: '100%', marginTop: 4 }}
  //           value={selectedDate}
  //           // locale={localeTH}
  //           format={ showTime ? this.DATETIME_FORMAT : this.DATE_FORMAT }
  //           showTime={this.props.showTime}
  //           disabledDate={ (selectedDate) => {
  //             return (minDate && selectedDate.isBefore(minDate, 'd'))
  //               || (maxDate && selectedDate.isAfter(maxDate, 'd'))
  //           }}
  //           // disabledTime={ (selectedDate) => {
  //           //   log('disabledTime selectedDate => ', selectedDate)
  //           //   return (minDate && selectedDate.isBefore(minDate, 'ms'))
  //           //     || (maxDate && selectedDate.isAfter(maxDate, 'ms'))
  //           // }}
  //           disabledTime={ (selectedDate) => this._computeDisabledTime(selectedDate, minDate, maxDate)}
  //           // onChange={ (value) => this._onDateChange({ key: 'date', mode, value }) }
  //           onChange={ (value, value2) => {
  //             log('onChange value => ', value)
  //             log('onChange value2 => ', value2)
  //             this._onDateChange(key, value)
  //           }}
  //         />
  //       </VStack>
  //     </Fragment>
  //   )
  // }

  render() {
    return (
      <VStack
        w='full'
        space='1'
        // style={
        //   {
        //     // flex: 1,
        //     flexBasis: 'auto',
        //     // flex: 1,
        //     width: '100%',
        //     // height: '100%',
        //     // padding: 4,
        //     flexWrap: 'wrap',
        //   }
        // }
      >
        {/* { this.INPUT_KEYS.map((key: 'begin'|'end') => this._renderDateTimePicker(key)) } */}
        {this.INPUT_KEYS.map(this._renderDateTimePickerContainer)}
      </VStack>
    )
  }
}
