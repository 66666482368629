import React from 'react'
import XIcon from './XIcon'

const DotDotDotVerticalIcon = (props: { disabled?: boolean }) => (
  <XIcon
    family='MaterialCommunityIcons'
    name='dots-vertical'
    minW='6'
    // minH='8'
    // // @ts-ignore
    // textVerticalAlign='center'
    textAlign='center'
    // minH='8'
    variant={props.disabled ? 'inactive' : 'primary'}
    // style={{
    //   alignSelf: 'center',
    //   textAlign: 'center',
    //   color: props.disabled ? COLORS.TEXT_INACTIVE : COLORS.APP_MAIN,
    //   fontSize: STYLES.FONT_ICON_LARGEST,
    //   minWidth: STYLES.FONT_ICON_LARGEST,
    //   width: STYLES.FONT_ICON_LARGEST,
    //   height: STYLES.FONT_ICON_LARGEST,
    //   flex: 0,
    //   // paddingTop: 0,
    //   // จริงๆ 0 มันกลางแล้ว แต่ปรับขึ้นเป็น 2 เพราะสายตาคนมองเทียบกับอย่างอื่นแล้วมันจึงดูไม่กลาง
    //   paddingBottom: Platform.OS === 'android' ? 2 : 0,
    //   // paddingLeft: 0,
    //   // paddingRight: 5,
    // }}
  />
)

export default DotDotDotVerticalIcon
