import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as AddressActions from 'x/modules/address/AddressState'
import { getSelectedStore } from 'x/redux/selectors'
import * as ContactStateActions from 'x/modules/contact/ContactState'
import AddressView from './AddressView'

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
    selectedAddress: state.getIn(['address', 'selectedAddress']),
    editingAddress: state.getIn(['address', 'editingAddress']),
  }),
  (dispatch) => ({
    load: bindActionCreators(AddressActions.load, dispatch),
    reset: bindActionCreators(AddressActions.reset, dispatch),
    onInit: bindActionCreators(AddressActions.onInit, dispatch),
    onChange: bindActionCreators(AddressActions.onChange, dispatch),
    saveNew: bindActionCreators(AddressActions.saveNew, dispatch),
    saveEditing: bindActionCreators(AddressActions.saveEditing, dispatch),
    editOrgAddress: bindActionCreators(AddressActions.editOrgAddress, dispatch),
    createAddress: bindActionCreators(AddressActions.createOrgAddress, dispatch),
    addCustomer: bindActionCreators(ContactStateActions.addCustomer, dispatch),
  })
  // @ts-ignore
)(AddressView)
