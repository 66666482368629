import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import StoreSettingPaperang from './StoreSettingPaperang'
import { getStoreSelected, getSubscription } from 'x/redux/selectors'
import { getState, getDispatch } from 'x/modules/order/BaseOrderViewConnect'

export default connect(
  state => ({
    ...getState(state),
    selectedStore: getStoreSelected(state),
    subscription: getSubscription(state),
  }),
  dispatch => {
    return {
      ...getDispatch(dispatch),
    }
  },
)(StoreSettingPaperang)
