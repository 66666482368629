import React from 'react'
import CommonLogo, { ICommonLogoOptionalProps } from './CommonLogo'

const LOGO = require('../img/about/xselly_logo_340.png')

const XSellyLogo = (props: ICommonLogoOptionalProps) => {
  return <CommonLogo source={LOGO} {...props} />
}

export default XSellyLogo
