import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as OrderActions from 'x/modules/order/OrderState'
import { getDispatch } from 'x/modules/order/BaseOrderViewConnect'
import OrderQuickAddressView from './OrderQuickAddressView'

export default connect(
  (state) => ({
    // selectedStoreID: state.getIn(['stores', 'selectedStore', 'id']),
    editingOrder: state.getIn(['order', 'editingOrder']),
  }),
  (dispatch) => {
    return {
      ...getDispatch(dispatch),
      onChangeAddress: bindActionCreators(OrderActions.onChangeAddress, dispatch),
      editReceiverAddress: bindActionCreators(OrderActions.editReceiverAddress, dispatch),
    }
  }
)(OrderQuickAddressView)
