import React from 'react'
import { IXSegmentPropsExcludedOptions, IXSegmentOption } from 'x/index'
import XSegment from '../XSegment'

type OptionValueType = undefined | number[]
type ISegmentOrderPaymentStatusProps = IXSegmentPropsExcludedOptions<OptionValueType>

const OPTIONS: Array<IXSegmentOption<OptionValueType>> = [
  { label: 'ทั้งหมด', value: undefined },
  { label: 'ครบถ้วน', value: [119] },
  { label: 'ไม่ครบถ้วน', value: [111, 112, 113, 114, 115] },
]

const SegmentOrderPaymentStatus: React.FC<ISegmentOrderPaymentStatusProps> = (props) => {
  return <XSegment options={OPTIONS} title='สถานะการยืนยันรับชำระ' {...props} />
}

SegmentOrderPaymentStatus.defaultProps = {
  initialIndex: 0,
}

export default SegmentOrderPaymentStatus
