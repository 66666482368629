import React, { memo } from 'react'
import { ScrollView } from 'react-native'
import { IScrollViewProps } from 'native-base'
import makeStyledComponent from 'x/config/nativebase/makeStyledComponent'

// import p from 'x/config/platform-specific'
// import { S } from 'x/config/styles'

// interface IXSellyContentProps extends ScrollViewProps {
//   // refreshing?: boolean
//   theme?: object
//   padder?: boolean
//   disableKBDismissScroll?: boolean
//   // enableResetScrollToCoords?: boolean
//   // scrollEnabled?: boolean
//   // style?: StyleProp<ViewStyle>
//   // contentContainerStyle?: StyleProp<ViewStyle>
//   children?: React.ReactNode | React.ReactNode[]
//   forwardedRef?: React.ForwardedRef<ScrollView>
// }
// class XContent extends React.Component<IXSellyContentProps> {
//   // defaultProps = {
//   //   style: {},
//   //   contentContainerStyle: {},
//   // }
//   renderApp = () => {
//     const { forwardedRef, style, contentContainerStyle, ...rest } = this.props
//     return (
//       <ScrollView
//         ref={forwardedRef}
//         {...rest}
//         style={StyleSheet.flatten(style)}
//         contentContainerStyle={StyleSheet.flatten(this.props.contentContainerStyle)}
//       />
//     )
//   }

//   renderWeb = () => {
//     const { forwardedRef, style, ...rest } = this.props
//     return <ScrollView ref={forwardedRef} {...rest} style={[S.FLEX, StyleSheet.flatten(style)]} />
//   }

//   render() {
//     return p.op.isWeb() ? this.renderWeb() : this.renderApp()
//   }
// }

// const WrappedXContent = React.forwardRef<ScrollView, IXSellyContentProps>((props, ref) => {
//   return <XContent {...props} forwardedRef={ref} />
// })
// export default WrappedXContent

const StyledScrollView = makeStyledComponent(ScrollView, 'ScrollView')

type IXSellyContentProps = IScrollViewProps

const XContent = React.forwardRef<ScrollView, IXSellyContentProps>((props, ref) => {
  // const { _contentContainerStyle = {}, contentContainerStyle = {}, ...restProps } = props
  const { _contentContainerStyle = {}, ...restProps } = props
  return (
    <StyledScrollView
      ref={ref}
      // flex={1}
      nestedScrollEnabled
      scrollEnabled
      // bg={useColorModeValue('coolGray.200', 'warmGray.700')}
      // bg='coolGray.200'
      // style={{ backgroundColor: 'red' }}
      // bg='red.500'
      _light={{
        bg: 'coolGray.200',
      }}
      _dark={{
        bg: 'warmGray.700',
      }}
      w='full'
      // _contentContainerStyle={{
      //   flexWrap: 'wrap',
      //   flexDirection: 'column',
      //   // bg: useColorModeValue('coolGray.200', 'warmGray.700'),
      //   ..._contentContainerStyle,
      // }}
      // @ts-ignore
      // contentContainerStyle={[
      //   {
      //     flex: 1,
      //     width: '100%',
      //     flexWrap: 'wrap',
      //     flexDirection: 'column',
      //     // bg: useColorModeValue('coolGray.200', 'warmGray.700'),
      //     // ..._contentContainerStyle,
      //   },
      //   // @ts-ignore
      //   _contentContainerStyle,
      //   contentContainerStyle,
      // ]}
      {...restProps}
    />
  )
})

XContent.displayName = 'XContent'
XContent.defaultProps = {
  style: {},
}

export default memo(XContent)
