import { Map } from 'immutable'
import { ActionApiParams } from 'x/index'
import actions from 'x/config/actions'
import CONS from 'x/config/constants'
import moment from 'moment'

// Initial state
// interface in x/index :: ILoadingInProgress
const initialState = Map({})

// Actions
// export const ACTION_INITIALIZE = 'AddressState/INITIALIZE'

export function setLoadingChannels() {
  const newDate = new Date()
  const DateTimeNow = moment(newDate).format(CONS.SERVER_DATETIME_FORMAT)
  return { type: actions.SET_LOADING_CHANNEL, payload: DateTimeNow }
}

export function unSetLoadingChannels() {
  return { type: actions.UN_SET_LOADING_CHANNEL }
}

// Reducer
export default function LoadingInProgressStateReducer(state = initialState, action: ActionApiParams = {}) {
  const { type, payload } = action
  const newState = state

  switch (type) {
    case actions.SET_LOADING_CHANNEL:
      return newState.set('channels', payload)

    case actions.UN_SET_LOADING_CHANNEL:
      return newState.delete('channels')

    default:
      return state
  }
}
