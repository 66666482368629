import React from 'react'
import _ from 'lodash'
// import { getTxtVDVariants } from 'x/util/util'
// import * as NavActions from '../services/navigation'

import { TouchableOpacity, Linking } from 'react-native'

import HStack from 'xui/components/HStack'
import VStack from 'xui/components/VStack'

// import xCONS from 'x/config/constants'
import BasePGSelectorOnlyView from 'x/modules/product/BasePGSelectorOnlyView'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import XText from 'xui/components/XText'
import XCard from 'xui/components/XCard'
import Box from 'xui/components/Box'
import XHelpModal from 'xui/components/XHelpModal'
import HelpButton from 'xui/components/HelpButton'
import FAQ from 'x/config/FAQ'
import XInput from 'xui/components/XInput'
import XButton from 'xui/components/XButton'
import XIcon from '../../components/XIcon'
import { LAZADA_REAUTH_LINK } from '../channels/ReauthLazadaView'

const _renderAgreement = (onPressOpenOverlay: () => void) => <HelpButton onPress={() => onPressOpenOverlay()} />

export default abstract class BaseUIPGSelectorOnlyView extends BasePGSelectorOnlyView {
  // static displayName = 'BaseUIPGSelectorOnlyView'

  _renderHeader = () => (
    <XCustomHeader
      title='ตั้งค่าการเชื่อมต่อ'
      headerLeftProps={{ label: 'ยกเลิก', onPressItem: () => this._cancelBtn() }}
      headerRightProps={{ label: 'บันทึก', onPressItem: () => this._saveBtn() }}
    />
  )

  renderDescription = () => (
    <HStack flex={1} mt='6'>
      <Box flex={1}>
        <XText variant='inactive'>
          กรุณาเลือกอย่างน้อย 1 ราคาด้านล่าง เพื่อกำหนดให้กับสินค้าที่ถูกสร้างใหม่ใน XSelly ระหว่างการซิงค์กับช่องทางขาย
        </XText>
      </Box>
      <Box>
        <XHelpModal
          key='sync_mode_qty'
          headerTitle='เลือกรายการราคา'
          FAQ={FAQ.MKP_PRODUCT_GROUP}
          initiateOpenIndex={0}
          renderButton={(onPressOpenOverlay: () => void) => _renderAgreement(onPressOpenOverlay)}
        />
      </Box>
    </HStack>
  )

  selectedItem = (index: number) => {
    const { selectedIds } = this.state
    const selected = _.includes(selectedIds, index)
    const newSelectedIds = _.clone(selectedIds)
    if (selected) {
      const indexPosition = newSelectedIds.indexOf(index)
      newSelectedIds.splice(indexPosition, 1)
    } else {
      newSelectedIds.push(index)
    }
    this.setState({
      selectedIds: newSelectedIds,
    })
    // setSelectedIds(newSelectedIds)
  }

  renderCheckboxList = () => {
    const { productGroupLable, selectedIds } = this.state
    if (_.isNil(productGroupLable) || productGroupLable.length < 0) {
      return null
    }
    return (
      <VStack w='full'>
        {productGroupLable.map((lable: string, idx: number) => {
          const selected = _.includes(selectedIds, idx)
          return (
            <TouchableOpacity onPress={() => this.selectedItem(idx)}>
              <HStack borderBottomColor='gray.400' borderBottomWidth='1' py='2' alignItems='center'>
                <HStack w='6'>
                  <XIcon family='FontAwesome' name={selected ? 'check-square-o' : 'square-o'} />
                </HStack>
                <XText pl='2' variant='active'>
                  {lable}
                </XText>
              </HStack>
            </TouchableOpacity>
          )
        })}
      </VStack>
    )
  }

  _renderInputApiKey = () => {
    const { mkp_id, apiKey } = this.state
    const MKP_USE_THIS_RENDER = mkp_id === 2 || mkp_id === 4 || mkp_id === 5
    if (_.isNil(mkp_id) || !MKP_USE_THIS_RENDER) {
      return null
    }

    // console.log('mkp_id => ', mkp_id)

    // mkp_id === 4 (LINE)
    let title = 'กรุณาระบุ LINE SHOPPING API Key'
    let placeHolder = 'วาง "LINE SHOPPING API Key" ได้ที่นี่'
    let note = 'หมายเหตุ: คุณสามารถ "ดูคลิปแนะนำการเตรียมและเชื่อมช่องทางขาย" ในหน้าก่อนหน้านี้ได้'
    // mkp_id === 2 (Lazada)
    const isLazada = mkp_id === 2
    const isShopify = mkp_id === 5
    if (isLazada) {
      title = 'กรุณาระบุ โค้ดสำหรับการเชื่อมต่อ Lazada'
      placeHolder = 'วาง "โค้ดสำหรับการเชื่อมต่อ Lazada" ได้ที่นี่'
    }

    if (isShopify) {
      title = 'กรุณาระบุข้อมูลสำหรับการเชื่อมต่อ Shopify'
      note = 'หมายเหตุ: กรุณาติดต่อทีมงานสำหรับวิธีการเชื่อมต่อ Shopify'
    }

    const LAZADA_TEXT_LINK = () => (isLazada ? <LAZADA_REAUTH_LINK /> : null)

    const availableMkpUesInput = [2, 4]

    return (
      <VStack pb='2'>
        <VStack alignItems='center'>
          <XText variant='active' bold>
            {title}
          </XText>

          {LAZADA_TEXT_LINK()}

          {/* {isLazada ? (
            <TouchableOpacity
              style={{ paddingLeft: 4 }}
              onPress={() => window.open('https://marketplace.lazada.co.th/web/detail.html?articleCode=FW_GOODS-1000011969', '_blank')}>
              <XText color='blue.800' textDecorationLine='underline'>
                กดที่นี่เพื่อเปิดเว็บ Lazada Service Marketplace และซื้อโค้ดการเชื่อมต่อกับ XSelly (เพียง 1 บาท)
              </XText>
            </TouchableOpacity>
          ) : null} */}

          <XText variant='inactive' paddingTop={4}>
            {note}
          </XText>
          {isLazada ? (
            <TouchableOpacity
              style={{ paddingLeft: 4, marginTop: -4, paddingBottom: 4 }}
              onPress={() => Linking.openURL('https://www.youtube.com/watch?v=fFkoyiqgrA4')}
              // onPress={() => window.open('https://www.youtube.com/watch?v=fFkoyiqgrA4', '_blank')}
            >
              <XText color='blue.800' textDecorationLine='underline'>
                หรือ กดที่นี่เพื่อดูคลิปแนะนำการซื้อโค้ดและเชื่อมต่อ
              </XText>
            </TouchableOpacity>
          ) : null}

          {/* <XHelpModal
            key='line_shop'
            headerTitle='LINE SHOPPING'
            FAQ={FAQ.MKP_SYNC_PRODUCT}
            initiateOpenIndex={0}
            renderButton={(onPressOpenOverlay: () => void) => _renderAgreement(onPressOpenOverlay)}
          /> */}
        </VStack>
        {_.includes(availableMkpUesInput, mkp_id) ? (
          <HStack w='full' h={'20'}>
            <XInput
              value={apiKey}
              multiline
              style={{ flex: 1 }}
              placeholder={placeHolder}
              onChangeText={(text) => this.setState({ apiKey: text.trim() })}
            />
          </HStack>
        ) : null}
      </VStack>
    )
  }

  _renderLineApiKeyInfo = () => {
    const { mkp_id } = this.state
    if (_.isNil(mkp_id) || mkp_id !== 4) {
      return null
    }

    const _renderBtn = (onPressOpenOverlay: () => void) => (
      <XButton variant='outline' onPress={() => onPressOpenOverlay()} maxW='400px' w='full'>
        ดูวิธีการเชื่อม LINE SHOPPING
      </XButton>
    )

    return (
      <VStack w='full' alignItems='center' mt='2'>
        <XHelpModal
          key='line_shop'
          headerTitle='ลงทะเบียนเพื่อขอ API Key'
          FAQ={FAQ.LINE_SHOPPING_API_KEY_FAQ}
          initiateOpenIndex={0}
          renderButton={(onPressOpenOverlay: () => void) => _renderBtn(onPressOpenOverlay)}
        />
        {/* <XButton
          variant='outline'
          // w='full'
          maxW='400px'
          onPress={() => Linking.openURL('https://feedback.line.me/enquete/public/16977-STbvntks')}
          mt='2'>
          ลงทะเบียนเพื่อขอ API Key
        </XButton> */}
      </VStack>
    )
  }

  _renderInputShopifyShopDomainName = () => {
    const { mkp_id, shopifyShopDomainName } = this.state
    // use only shopify
    if (_.isNil(mkp_id) || mkp_id !== 5) {
      return null
    }
    const title = 'Shopify Shop Domain Name (xxxxx.myshopify.com)'
    const placeHolder = 'เช่น mystore.myshopify.com'
    return (
      <VStack pb='2'>
        <XText variant='inactive' pb='1'>
          {title}
        </XText>
        <HStack alignItems={'center'}>
          <XInput
            value={shopifyShopDomainName}
            // w='20'
            style={{ flex: 1 }}
            placeholder={placeHolder}
            onChangeText={(text) => this.setState({ shopifyShopDomainName: text.trim() })}
          />
          <XText ml='2'>.myshopify.com</XText>
        </HStack>
      </VStack>
    )
  }

  _renderInputShopifyApiAccessToken = () => {
    const { mkp_id, shopifyApiAccessToken } = this.state
    // use only shopify
    if (_.isNil(mkp_id) || mkp_id !== 5) {
      return null
    }

    const title = 'Admin API Access Token'
    const placeHolder = 'ระบุ Admin API Access token'
    // if (isShopify) {
    //   title = 'กรุณาระบุข้อมูลสำหรับการเชื่อมต่อ Shopify'
    //   placeHolder = 'ระบุ Admin API Access token'
    // }

    return (
      <VStack pb='2'>
        <XText variant='inactive' pb='1'>
          {title}
        </XText>
        <HStack w='full'>
          <XInput
            value={shopifyApiAccessToken}
            // multiline
            style={{ flex: 1 }}
            placeholder={placeHolder}
            onChangeText={(text) => this.setState({ shopifyApiAccessToken: text.trim() })}
          />
        </HStack>
      </VStack>
    )
  }

  _renderInputShopifyApiKey = () => {
    const { mkp_id, shopifyApiKey } = this.state
    // use only shopify
    if (_.isNil(mkp_id) || mkp_id !== 5) {
      return null
    }
    const title = 'API Key'
    const placeHolder = 'ระบุ API Key'
    return (
      <VStack pb='2'>
        <XText variant='inactive' pb='1'>
          {title}
        </XText>
        <HStack w='full'>
          <XInput
            value={shopifyApiKey}
            // multiline
            style={{ flex: 1 }}
            placeholder={placeHolder}
            onChangeText={(text) => this.setState({ shopifyApiKey: text.trim() })}
          />
        </HStack>
      </VStack>
    )
  }

  _renderInputShopifyApiSecret = () => {
    const { mkp_id, shopifyApiSecret } = this.state
    // use only shopify
    if (_.isNil(mkp_id) || mkp_id !== 5) {
      return null
    }
    const title = 'API Secret'
    const placeHolder = 'ระบุ API Secret'
    return (
      <VStack pb='2'>
        <XText variant='inactive' pb='1'>
          {title}
        </XText>
        <HStack w='full'>
          <XInput
            value={shopifyApiSecret}
            // multiline
            style={{ flex: 1 }}
            placeholder={placeHolder}
            onChangeText={(text) => this.setState({ shopifyApiSecret: text.trim() })}
          />
        </HStack>
      </VStack>
    )
  }

  render() {
    // const { disabled = false, productGroups, style = {}, mode } = this.props
    // const { isVisibleSelector, selectedIds } = this.state

    return (
      <XContainer>
        {this._renderHeader()}
        <XContent p='2' w='full'>
          <XCard p='2' w='full'>
            {this._renderInputApiKey()}
            {this._renderInputShopifyApiAccessToken()}
            {this._renderInputShopifyApiKey()}
            {this._renderInputShopifyApiSecret()}
            {this._renderInputShopifyShopDomainName()}
            {this.renderDescription()}
            {this.renderCheckboxList()}
          </XCard>
        </XContent>
      </XContainer>
    )
  }
}
