import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as StoreState from 'x/modules/store/StoreState'
// import * as ProductState from 'x/modules/product/ProductState'
// import * as ProfileActions from 'x/modules/profile/ProfileState'
import { getSelectedStore } from 'x/redux/selectors'
import AutoCompletePaymentBySegmentView from './AutoCompletePaymentBySegmentView'

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
  }),
  (dispatch) => {
    return {
      orderCount: bindActionCreators(StoreState.orderCount, dispatch),
      autoCompleteBySegment: bindActionCreators(StoreState.autoCompleteBySegment, dispatch),
    }
  }
)(AutoCompletePaymentBySegmentView)
