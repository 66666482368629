import React from 'react'
import BaseResellerListView from 'x/modules/reseller/BaseResellerListView'
import { Image, Text, View, TouchableOpacity, FlatList } from 'react-native'
import { COLORS, STYLES } from 'x/config/styles'
import _ from 'lodash'
import XCustomHeader from 'xui/components/XCustomHeader'
import XInput from 'xui/components/XInput'
import * as util from 'x/utils/util'
import XIcon from 'xui/components/XIcon'
import { IOrgPendingList } from 'x/modules/reseller/BaseResellerApprovalListView'

export default abstract class BaseUIResellerListView extends BaseResellerListView {
  abstract renderMain: () => JSX.Element

  abstract navToCustomer: (item: IOrgPendingList) => void

  static displayName = 'BaseUIPaymentListView'

  _renderHeader = () => {
    const leftBtn = { backIcon: true, onPressItem: () => util.navGoBack(this.props) }
    return <XCustomHeader title='ค้นหาตัวแทน' headerLeftProps={leftBtn} />
  }

  _renderProfileItems = ({ index, item }) => {
    if (_.isNil(item)) {
      return null
    }
    const { navigation } = this.props
    const isEven = index % 2 === 0
    const name = _.isNil(item.fn) ? 'ไม่ระบุชื่อ ' : item.fn
    const lastName = _.isNil(item.ln) ? '' : item.ln
    const upper = _.isNil(item.s) ? null : item.s
    // console.log(`item.c`, item)
    return (
      <TouchableOpacity
        onPress={() => this.navToCustomer(item)}
        style={{ backgroundColor: isEven ? COLORS.BG_LIGHT_GREY : COLORS.WHITE, height: 80, width: '100%', flexDirection: 'row' }}>
        <View style={{ width: 50, alignItems: 'center', justifyContent: 'center' }}>
          <Image source={{ uri: item.t }} style={{ width: 40, height: 40, borderRadius: 40 / 2 }} />
        </View>
        <View style={{ flex: 1, flexDirection: 'column', justifyContent: 'center' }}>
          <View style={{ width: '100%', height: 40, flexDirection: 'column', justifyContent: 'center', marginTop: 2 }}>
            <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE, paddingLeft: 4 }}>{`${name} ${lastName}`}</Text>
            <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE, paddingLeft: 4 }}>{`รหัสตัวแทน ${item.c}`}</Text>
          </View>
          {_.isNil(upper) ? (
            <View style={{ width: '100%', height: 40, padding: 2, marginBottom: 4 }} />
          ) : (
            <View
              style={{
                width: '100%',
                height: 40,
                borderWidth: 0.5,
                borderColor: COLORS.RED_SOFT,
                padding: 2,
                borderRadius: 7,
                marginBottom: 8,
              }}>
              <Text
                style={{
                  fontSize: STYLES.FONT_SIZE_NORMAL,
                  color: COLORS.TEXT_ACTIVE,
                  paddingLeft: 4,
                }}>{`แม่ทีม: ${upper.fn} ${upper.ln}`}</Text>
              <Text
                style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE, paddingLeft: 4 }}>{`รหัสตัวแทน ${upper.c}`}</Text>
            </View>
          )}
        </View>
        <View style={{ width: 50, alignItems: 'center', justifyContent: 'center' }}>
          <XIcon family='AntDesign' name='arrowright' style={{ minWidth: 25, width: 25, color: COLORS.TEXT_INACTIVE, flex: 0 }} />
        </View>
      </TouchableOpacity>
    )
  }

  _renderSearchNameInput = (key: string, title: string, placeholder: string) => (
    <View style={{ flex: 1, flexDirection: 'column', paddingLeft: 10, paddingRight: 10 }}>
      <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE }}>{title}</Text>
      <XInput
        // style={{ height: 10 }}
        onSubmitEditing={this._handleKeyEnter}
        value={this.state[key]}
        onChangeText={(val: string) => util.setStatePromise(this, { [key]: val })}
        placeholder={placeholder}
      />
    </View>
  )
  // return (
  //   <View style={{ height: 50, width: '100%', flexDirection: 'row', alignItems: 'center', paddingBottom: 10 }}>
  //     <View style={{ width: 70, paddingLeft: 4 }}>
  //       <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE }}>{title}</Text>
  //     </View>
  //     <View style={{ flex: 1, flexBasis: `auto`, paddingRight: 4 }}>
  //       <XInput
  //         onSubmitEditing={this._handleKeyEnter}
  //         value={this.state[key]}
  //         onChangeText={(val: string) => util.setStatePromise(this, { [key]: val })}
  //         placeholder={placeholder}
  //       />
  //     </View>
  //   </View>
  // )

  _renderSearchBtn = () => (
    <View
      style={{
        height: 44,
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingRight: 4,
        paddingBottom: 4,
      }}>
      <TouchableOpacity
        onPress={async () => await this._onPressClearBtn()}
        style={{
          width: 60,
          height: 30,
          flexDirection: 'row',
          // paddingBottom: 4,
          // paddingRight: 4,
          backgroundColor: COLORS.BG_LIGHT_GREY,
          borderColor: COLORS.APP_MAIN,
          borderWidth: 0.8,
          borderRadius: 7,
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <XIcon
          family='AntDesign'
          style={{
            flex: 0,
            width: 25,
            minWidth: 20,
            fontSize: 15,
            color: COLORS.TEXT_INACTIVE,
            marginLeft: -4,
          }}
          name='closecircle'
        />
        <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.APP_MAIN, paddingLeft: 4 }}>ล้าง</Text>
      </TouchableOpacity>
      <View style={{ width: 10 }} />
      <TouchableOpacity
        onPress={async () => await this._onPressSearchBtn()}
        style={{
          width: 60,
          height: 30,
          flexDirection: 'row',
          // paddingBottom: 4,
          // paddingRight: 4,
          backgroundColor: COLORS.BG_LIGHT_GREY,
          borderColor: COLORS.APP_MAIN,
          borderWidth: 0.8,
          borderRadius: 7,
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <XIcon
          family='AntDesign'
          style={{
            flex: 0,
            width: 20,
            minWidth: 20,
            fontSize: 15,
            color: COLORS.APP_MAIN,
          }}
          name='search1'
        />
        <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.APP_MAIN }}>ค้นหา</Text>
      </TouchableOpacity>
    </View>
  )

  _renderInputView = () => (
    <View>
      <View style={{ flexDirection: 'row', paddingTop: 10, height: 60 }}>
        {this._renderSearchNameInput(`nameText`, `ชื่อ`, `เช่น กนก`)}
        {this._renderSearchNameInput(`lastNameText`, `นามสกุล`, `เช่น เจริญรวยมาก`)}
      </View>
      <View style={{ flexDirection: 'row', paddingTop: 10, height: 60 }}>
        {this._renderSearchNameInput(`emailText`, `อีเมล`, `เช่น xselly_02@gmail.com`)}
        {this._renderSearchNameInput(`phoneText`, `เบอร์โทร`, `เช่น 0874100000`)}
      </View>
      <View style={{ flexDirection: 'row', paddingTop: 10, height: 60 }}>
        {this._renderSearchNameInput(`sellerCodeText`, `รหัสตัวแทน`, `เช่น SS04-00000000`)}
        {this._renderSearchNameInput(`parentResellerText`, `รหัสแม่ทีม`, `เช่น SS04-00000000`)}
      </View>
    </View>
  )

  _renderSearchView = () => (
    <View
      style={{
        flex: 1,
        flexBasis: 'auto',
        // width: '100%',
        backgroundColor: 'white',
        maxHeight: 226,
      }}>
      {/* {this._renderSearchNameInput(`nameText`, `ชื่อ`, `เช่น กนก`)}
        {this._renderSearchNameInput(`lastNameText`, `นามสกุล`, `นามสกุล`)}
        {this._renderSearchNameInput(`sellerCodeText`, `รหัสตัวแทน`, `เช่น SS04-00000000`)} */}
      {this._renderInputView()}
      {this._renderSearchBtn()}
    </View>
  )

  _renderBody = () => {
    const { resellerList } = this.state
    return <FlatList data={resellerList} renderItem={this._renderProfileItems} />
  }

  render() {
    return this.renderMain()
  }
}
