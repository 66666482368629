import { connect } from 'react-redux'
import { getState, getDispatch } from 'x/modules/order/BaseOrderViewConnect'
import ProductWarehousesPicker from './ProductWarehousesPicker'

export default connect(
  (state) => ({
    ...getState(state),
  }),
  (dispatch) => {
    return {
      ...getDispatch(dispatch),
    }
  }
)(ProductWarehousesPicker)
