import React from 'react'

import _ from 'lodash'
// import * as xFmt from '../../utils/formatter'
import { log, setStatePromise } from 'x/utils/util'
// import * as acl from '../../utils/acl'
import { Map } from 'immutable'
import dayjs, { Dayjs } from 'dayjs'

import { IXScreenProps } from 'x/types'
import p from '../../config/platform-specific'
import * as util from '../../utils/util'

// ARTID01
// import DocumentPicker from 'react-native-document-picker';

interface IBaseReportSalesByChannelNavParams {
  store_id: number
}

export interface IBaseReportSalesByChannelViewProps extends IXScreenProps<IBaseReportSalesByChannelNavParams> {
  selectedStore: Map<string, any>
  subscription: any
}

export interface IBaseReportSalesByChannelViewState {
  loading: boolean
  selectedDateRange: { begin: Dayjs; end: Dayjs }
  selectedOpts: {
    REPORT_TYPE?: number
    PERIOD?: number
    [key: string]: number
  }
  // selectedOptionTimePeriod: string
  dateRangeDescription: string
}

export default abstract class BaseReportSalesByChannelView extends React.Component<
  IBaseReportSalesByChannelViewProps,
  IBaseReportSalesByChannelViewState
> {
  inProcess: boolean

  TXT_TITLES: {
    REPORT_TYPE: string
    PERIOD: string
  }

  TXT_WARNING_DATE_RANGE: string

  OPTIONS: {
    REPORT_TYPE: string[]
    PERIOD: string[]
  }

  limitDays: number

  abstract _getParams(): { store_id: number } | null

  abstract _handleOnDownloadFile(url: string, fileName: string): Promise<void>

  protected constructor(props) {
    super(props)
    this.TXT_TITLES = {
      REPORT_TYPE: 'ประเภทรายงาน',
      PERIOD: 'ช่วงเวลาที่ต้องการ',
    }
    this.OPTIONS = {
      REPORT_TYPE: ['สรุปรวมทุกวัน', 'แยกรายวัน'],
      PERIOD: ['7 วัน', '30 วัน', 'ระบุ'],
    }

    this.TXT_WARNING_DATE_RANGE = 'แพ็กเกจปัจจุบันของคุณไม่สามารถเลือกช่วงเวลาเพื่อดูรายการจัดส่งย้อนหลังได้'

    this.state = {
      loading: false,
      selectedOpts: {
        REPORT_TYPE: 0,
        PERIOD: 0,
      },
      selectedDateRange: { begin: dayjs(), end: dayjs() },
      // selectedOptionTimePeriod: this.OPTIONS_TIME_PERIOD[0],
      dateRangeDescription: '',
    }

    this.limitDays = 999 // เพราะหลังบ้านจะรับไม่ไหว
    this.inProcess = false
  }

  _onSegmentWithKeyChange = (optKey: string, newIndex: number) => {
    if (optKey === 'PERIOD' && _.includes([1, 2], newIndex)) {
      const allowDays = this._computeAllowDays()
      if (allowDays < 7) {
        p.op.alert(p.op.t('Subscription.warning.insufficientTypeTitle'), p.op.t('Subscription.warning.insufficientTypeMsg'))
        return
      }
    }
    const selectedOpts = _.cloneDeep(this.state.selectedOpts)
    selectedOpts[optKey] = newIndex
    this.setState({ selectedOpts })
  }

  _onChangeDateRange = (newDates: { begin: Dayjs; end: Dayjs }): void => {
    if (this.props.subscription.get('type') !== 1 && this.props.subscription.get('type') !== 5) {
      const { begin } = newDates
      const now = dayjs()
      if (begin.isBefore(now) && Math.abs(begin.diff(now, 'd')) > +this.limitDays) {
        newDates.begin = dayjs(now).subtract(+this.limitDays, 'day')
        newDates.end = dayjs(now)
        this.setState({ selectedDateRange: newDates })
        return
      }
    }
    this.setState({ selectedDateRange: newDates })
  }

  _isVisibleDateRangePicker(): boolean {
    return this.state.selectedOpts.PERIOD === 2
  }

  _computeAllowDays = (): number => {
    const { subscription } = this.props
    let allowDays = subscription.has('r_dsc_day') && _.isNumber(subscription.get('r_dsc_day')) ? subscription.get('r_dsc_day') : 1
    // log('_computeAllowDays => ', _.isNumber(allowDays) ? allowDays : parseInt(allowDays))
    allowDays = _.isNumber(allowDays) ? allowDays : parseInt(allowDays)
    return allowDays
  }

  _canRequestMoreColumns = (): boolean => util.canRequestExtraExcelColumns(this.props.subscription.get('type'))

  _downloadReportRequisitionUrl = async (): Promise<void> => {
    // ถ้าเป็น IOS และเข้าเงื่อนไขก็ปล่อยผ่าน // https://app.clickup.com/t/86cvy21qt
    const isNoobIos = util.isIosNoobCheckByPass()
    // if (isNoobIos) {
    //   p.op.showConfirmationOkOnly('', p.op.t('Subscription.warning.iosNoobCheckMsg'))
    //   return
    // }
    if (!isNoobIos && util.isPackFreeAndSelectedStoreOwner()) {
      p.op.alert(p.op.t('Subscription.warning.insufficientTypeTitle'), p.op.t('Subscription.warning.insufficientTypeMsg'))
      // this.inProcess = false
      return
    }

    if (this.inProcess) {
      return
    }
    this.inProcess = true

    // TODO: Check for perm on BaseReportProductDailySales line 335?
    // const isHlper = util.isHelper()
    // // if (!isHlper && util.isStoreOwner() && this.props.subscription.get('type') === xCONS.SUBSCRIPTION_PACKAGE.FREE) {
    // if (!isHlper && util.isStoreOwner()) {
    //   p.op.alert(p.op.t('Subscription.warning.insufficientTypeTitle'), p.op.t('Subscription.warning.insufficientTypeMsg'))
    //   this.inProcess = false
    //   return
    // }

    await setStatePromise(this, { loading: true })
    const { navigation } = this.props
    const { selectedOpts, selectedDateRange } = this.state
    const storeId = util.getNavParam(this.props, 'store_id')

    let exportExcel: 1 | 2 = 1
    // ถ้า user เลือก แยกรายวัน
    switch (selectedOpts.REPORT_TYPE) {
      case 0: // report summery
        exportExcel = 1
        break
      case 1: // report detail
        exportExcel = 2
    }

    let startDate = null
    let endDate = null
    let daysInPast = null

    // ถ้า user เลือก 7 วัน
    if (selectedOpts.PERIOD === 0) {
      daysInPast = 7
    }

    // ถ้า user เลือก 30 วัน
    if (selectedOpts.PERIOD === 1) {
      daysInPast = 30
    }

    // ถ้า user เลือก custom time
    if (selectedOpts.PERIOD === 2) {
      startDate = selectedDateRange.begin.format('YYYY-MM-DD')
      endDate = selectedDateRange.end.format('YYYY-MM-DD')
    }

    // const startDateTime = '19-03-2018'
    const url = util.getReportSalesByChannelExcelUrl({
      store_id: storeId,
      daysInPast,
      exportExcel,
      startDate,
      endDate,
    })

    if (selectedOpts.PERIOD !== 2) {
      endDate = dayjs().subtract(1, 'days').format('YYYY-MM-DD')
      startDate = dayjs().subtract(daysInPast, 'days').format('YYYY-MM-DD')
    }
    const fileName = `XSelly_SalesByChannel_${startDate}_to_${endDate}.xlsx`

    // fileName = `${fileName}.xlsx`

    log('_downloadReportRequisitionUrl url => ', url)
    log('_downloadReportRequisitionUrl fileName => ', fileName)
    await this._handleOnDownloadFile(url, fileName)
    await setStatePromise(this, { loading: false })
    await setTimeout(() => {
      this.inProcess = false
    }, 500)
  }
  // ARTID01
  // _testFileSelector = async (): Promise<void> => {
  //   try {
  //     const res = await DocumentPicker.pickSingle({
  //       type: [
  //         DocumentPicker.types.csv,
  //         DocumentPicker.types.xls,
  //         DocumentPicker.types.xlsx,
  //       ],
  //       //There can me more options as well
  //       // DocumentPicker.types.allFiles
  //       // DocumentPicker.types.images
  //       // DocumentPicker.types.plainText
  //       // DocumentPicker.types.audio
  //       // DocumentPicker.types.pdf
  //     });
  //     //Printing the log realted to the file
  //     console.log('res : ' + JSON.stringify(res));
  //     console.log('URI : ' + res.uri);
  //     console.log('Type : ' + res.type);
  //     console.log('File Name : ' + res.name);
  //     console.log('File Size : ' + res.size);
  //   } catch (err) {
  //     //Handling any exception (If any)
  //     if (DocumentPicker.isCancel(err)) {
  //       //If user canceled the document selection
  //       alert('Canceled from single doc picker');
  //     } else {
  //       //For Unknown Error
  //       alert('Unknown Error: ' + JSON.stringify(err));
  //       throw err;
  //     }
  //   }
  // }
}
