import React from 'react'
import { connect } from 'react-redux'
import { List } from 'immutable'
import _ from 'lodash'

import { getState, getDispatch } from 'x/modules/orderlist/StoreOrderSingleTabListViewConnect'
import { IOrderListItem, IOrderListViewState, IPaymentAccountMap, IPaymentOrderListViewProps } from 'x/index'
import CONS from 'x/config/constants'

import * as util from 'x/utils/util'
import XButton from 'xui/components/XButton'
import VStack from 'xui/components/VStack'
import BaseUIOrderListView from './BaseUIOrderListView'

// interface IPaymentOrderListViewProps extends IOrderListViewProps {
//   navigation: NavigationStackProp<NavigationRoute, IPaymentOrderListViewNavParams>
// }

interface IPaymentOrderListView extends IOrderListViewState {
  selectedBankAccount?: IPaymentAccountMap
}

class PaymentOrderListView extends BaseUIOrderListView<IPaymentOrderListViewProps, IPaymentOrderListView> {
  static displayName = 'PaymentOrderListView'

  onPressOrderListItem = (od: IOrderListItem) => {
    // const { navigation } = this.props

    // // navigation.dispatch(
    // //   NavActions.navToOrderDetail({
    // //     order_id: od.id,
    // //     store_id: this.getStoreId(),
    // //   })
    // // )
    // navigation.dispatch(
    //   NavActions.navToOrderListView({
    //     // order_id: od.id,
    //     store_id: this.getStoreId(),
    //     onPressOrderListItem: this._mockOnPressOrderListItem,
    //     onPressSubmitCheckedOrders: this._mockOnPressSubmitCheckedOrders,
    //   })
    // )
    this.toggleCheckOrderListItem(od.id)
  }

  getOverridePreSetupInitialState = (): Partial<IPaymentOrderListView> => {
    const { selectedStore, sellerStores, navigation } = this.props
    const hasReseller = selectedStore.get('has_reseller') || false
    const hasSeller = List.isList(sellerStores) && sellerStores.size > 0

    // ไม่มีทั้ง seller / reseller
    let availableTabKeys = CONS.ORDER_SUMMARY_NO_SELLER_AND_RESELLER

    if (hasSeller) {
      availableTabKeys = CONS.ORDER_SUMMARY_NO_RESELLER
    }

    if (hasReseller) {
      availableTabKeys = CONS.ORDER_SUMMARY_NO_SELLER
    }

    if (hasSeller && hasReseller) {
      availableTabKeys = CONS.ORDER_SUMMARY_ALL
    }

    // const ordersDefaultFilterSetting = util.getNavParam(this.props, 'ordersDefaultFilterSetting', {})
    // const paymentOrder = util.getNavParam(this.props, 'paymentOrder')

    return {
      // isCheckable: true,
      availableTabKeys,
      // ordersDefaultFilterSetting,
      // paymentOrder,
    }
  }

  // componentDidUpdate(prevProps: Readonly<IPaymentOrderListViewProps>, prevState: Readonly<IPaymentOrderListView>, snapshot?: any): void {
  //   // super.componentDidUpdate.apply(this, prevProps, prevState, snapshot)
  //   if (!prevState.isInitialized && this.state.isInitialized) {
  //     const { navigation } = this.props
  //     // const initialOrdersToBePaid = util.getNavParam(this.props, 'initialOrdersToBePaid', [])
  //     const initialOrdersToBePaid = util.getNavParam(this.props, 'initialOrdersToBePaid', {})
  //     // const initialOrdersToBePaidIds = Object.keys(initialOrdersToBePaid)
  //     // // if (initialOrdersToBePaid.length > 0) {
  //     // if (initialOrdersToBePaidIds.length > 0) {
  //     //   initialOrdersToBePaidIds.forEach((orderId) => {
  //     //     setTimeout(() => {
  //     //       this.toggleCheckOrderListItem(parseInt(orderId, 10))
  //     //     }, 1000)
  //     //   })
  //     // }
  //     if (!_.isEmpty(initialOrdersToBePaid)) {
  //       util.setStatePromise(this, { ordersCheckedMap: initialOrdersToBePaid })
  //     }
  //   }
  // }

  async componentDidMount(): Promise<void> {
    await super.componentDidMount.apply(this)
    // const { navigation } = this.props
    // const initialOrdersToBePaid = util.getNavParam(this.props, 'initialOrdersToBePaid', [])
    // if (initialOrdersToBePaid.length > 0) {
    //   // const ordersCheckedMap = {}
    //   initialOrdersToBePaid.forEach((orderId) => {
    //     // ordersCheckedMap[orderId] = true
    //     setTimeout(() => {
    //       this.toggleCheckOrderListItem(orderId)
    //     }, 1500)
    //   })
    //   // this.setState({ ordersCheckedMap })
    // }
    const { navigation } = this.props
    const initialOrdersToBePaid = util.getNavParam(this.props, 'initialOrdersToBePaid', {})
    if (!_.isEmpty(initialOrdersToBePaid)) {
      const newState = { ordersCheckedMap: initialOrdersToBePaid }
      const odKeys = Object.keys(initialOrdersToBePaid)
      for (let i = 0; i < odKeys.length; i++) {
        const odKey = odKeys[i]
        newState[`odc_${odKey}`] = initialOrdersToBePaid[odKey]
      }
      // await util.setStatePromise(this, { ordersCheckedMap: initialOrdersToBePaid })
      await util.setStatePromise(this, newState)
    }
  }

  // getOverrideRequestBody = (): Partial<IFetchOrdersRequestBody> => ({
  //   order_segment: 'my_unshipped_only_and_not_mkp',
  // })

  // getHeaderTitle = () => {
  //   const defaultTitle = this.getTabLabelWithCount(this.state.tabName)
  //   return `บันทึกรับชำระอัตโนมัติ\n${defaultTitle}`
  // }

  // getSubmitCheckOrdersButtonText = () => {
  //   const checkedOrders = this.getCheckedOrderIds()
  //   const checkedCount = checkedOrders.length
  //   return checkedCount > 0 ? 'บันทึกรับชำระอัตโนมัติ' : 'กรุณาเลือกออเดอร์ที่ต้องการบันทึกรับชำระอัตโนมัติ\nโดยแตะปุ่มกลมทางซ้าย'
  // }

  isSubmitCheckOrdersButtonDisabled = () => {
    const checkedOrders = this.getCheckedOrderIds()
    const checkedCount = checkedOrders.length
    return checkedCount === 0
  }

  // onPressSubmitCheckedOrders = async (order_ids: number[]) => {
  // onPressSubmitCheckedOrders = async (checkedOrders: IOrderListItem[]) => {
  //   console.log('onPressSubmitCheckedOrders checkedOrders => ', checkedOrders)
  // }

  _onPressSubmit = async () => {
    if (this.isSubmitting) {
      return
    }
    this.isSubmitting = true
    await util.setStatePromise(this, { isSubmitting: true })

    // await this.onPressSubmitCheckedOrders(this.getCheckedOrderIds())
    await this.onPressSubmitCheckedOrders(this.getCheckedOrders())

    await util.setStatePromise(this, { isSubmitting: false })
    this.isSubmitting = false
    this.goBack()
  }

  renderSubmitCheckOrdersButton = () => {
    const { isCheckable, isInitialized } = this.state
    if (!isCheckable || !isInitialized) {
      return null
    }
    return (
      <VStack w='full' minH='12' px='1' pb='1'>
        <XButton
          w='full'
          size={this.isSubmitCheckOrdersButtonDisabled() ? 'sm' : 'md'}
          minH='10'
          _stack={{ alignItems: 'center' }}
          _text={{ textAlign: 'center' }}
          colorScheme={this.isSubmitCheckOrdersButtonDisabled() ? 'muted' : 'primary'}
          disabled={this.isSubmitCheckOrdersButtonDisabled()}
          // isDisabled={this.isSubmitCheckOrdersButtonDisabled()}
          onPress={this._onPressSubmit}>
          {this.getSubmitCheckOrdersButtonText()}
        </XButton>
      </VStack>
    )
  }
}

// export default OrderListView

export default connect(
  (state) => ({
    ...getState(state),
  }),
  (dispatch) => ({
    ...getDispatch(dispatch),
  })
)(PaymentOrderListView)
