import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as StoreState from 'x/modules/store/StoreState'

import PaymentPTTTransactions from './PaymentPTTTransactions'
import { getSelectedStore, getSelectedCustomOrderView } from 'x/redux/selectors'

// import * as AddressActions from '../address/AddressState'

export default connect(
  (state) => {
    return {
      selectedStore: getSelectedStore(state),
      // selectedCustomOrderView: getSelectedCustomOrderView(state),
    }
  },
  (dispatch) => {
    return {
      // addNewStoreSetting: bindActionCreators(StoreState.addNewStoreSetting, dispatch),
      // pickerSelect: bindActionCreators(bpActions.pickerSelect, dispatch),
      // addCustomOrder: bindActionCreators(PreferenceState.addCustomOrder, dispatch),
      // loadPrefUIOrderView: bindActionCreators(PreferenceState.loadPrefUIOrderView, dispatch),
    }
  }
  // @ts-ignore
)(PaymentPTTTransactions)
