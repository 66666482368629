import React from 'react'
import { ScrollView } from 'react-native'
import _ from 'lodash'
import * as util from 'x/utils/util'
import BaseUIChannelListView from 'xui/modules/channels/BaseUIChannelListView'
// import { getConfig } from 'x/config/mode'
// import { WebView } from 'react-native-webview'
import p from 'x/config/platform-specific'
import { VIEW_HEIGHT_TEN } from 'xui/utils/BaseShareUI'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import Box from 'xui/components/Box'
// import OrdersSummaryDonut from '../../components/OrdersSummaryDonut'

export default class ChannelListView extends BaseUIChannelListView {
  static displayName = 'ChannelListView'

  static navigationOptions = {
    header: null,
  }

  // navToChannelsView = (channel = null) => {
  //   const { navigation, selectedStore, subscription } = this.props
  //   // const { channelList } = this.state
  //   // const type = subscription.get(`type`)
  //   const mkpQuota = subscription.has('mkp_quota') ? subscription.get('mkp_quota') : null
  //   if (_.isNil(mkpQuota)) {
  //     p.op.showConfirmationOkOnly('เกิดข้อผิดพลาด', 'กรุณาปิดหน้าต่างนี้ แล้วลองใหม่อีกครั้ง (mkpq is null)')
  //     return
  //   }
  //   // if (channelList.length >= mkpQuota) {
  //   //   p.op.alert(p.op.t('Subscription.warning.packageFull'), p.op.t('Subscription.warning.insufficientTypeMsg'))
  //   //   return
  //   // }
  //   const mode = _.isNil(channel) ? 'CREATE' : 'VIEW'
  //   if (_.isNil(channel)) {
  //     navigation.dispatch(
  //       NavActions.navToChannelView({
  //         store_id: selectedStore.get('id'),
  //         mode,
  //         channel,
  //         callBackURL: (url: string, channelFromCallbBack: IMKPChannelDetail) => this._callBackURL(url, channelFromCallbBack),
  //         callBackUpdateChannelList: (channels: IMKPChannelDetail[]) => this._callBackUpdateChannelList(channels),
  //         callBackUpdateChannel: (Channel: IMKPChannelDetail) => this._callBackUpdateChannel(Channel),
  //       })
  //     )
  //   } else {
  //     navigation.dispatch(
  //       NavActions.navToChannelView({
  //         store_id: selectedStore.get(`id`),
  //         mode,
  //         channel,
  //         callBackURL: (url: string, channelFromCallbBack: IMKPChannelDetail) => this._callBackURL(url, channelFromCallbBack),
  //         callBackUpdateChannelList: (channels: IMKPChannelDetail[]) => this._callBackUpdateChannelList(channels),
  //         callBackUpdateChannel: (Channel: IMKPChannelDetail) => this._callBackUpdateChannel(Channel),
  //       })
  //     )
  //   }
  // }

  goBack = () => {
    util.navGoBack(this.props)
  }

  renderWebView = () => {
    const { urlWebView } = this.state
    // console.log(`WEB VIEW !! `, urlWebView)
    // `https://reactnative.dev/`
    if (_.isNil(urlWebView) || urlWebView === '') {
      return null
    }
    return null
    // return (
    //   <WebView
    //     ref={this.webview}
    //     source={{ uri: urlWebView }}
    //     onNavigationStateChange={this.handleWebViewNavigationStateChange}
    //     style={{ flex: 1 }}
    //   />
    // )
  }

  // @ts-ignore newNavState
  // eslint-disable-next-line complexity
  handleWebViewNavigationStateChange = (newNavState) => {
    // newNavState looks something like this:
    // {
    //   url?: string;
    //   title?: string;
    //   loading?: boolean;
    //   canGoBack?: boolean;
    //   canGoForward?: boolean;
    // }
    // console.log(`newNavState !! `, newNavState)
    const { url } = newNavState
    if (!url) {
      return
    }
    // console.log('url => ', url)
    // handle certain doctypes
    // if (url.includes('.pdf')) {
    //   // @ts-ignore webview แดง
    //   this.webview.stopLoading()
    //   // open a modal with the PDF viewer
    // }

    // AUTH //
    if (url.includes('https://authresult.xselly.com/?status=fail') || url.includes('https://authresult.xselly.com?status=fail')) {
      util.setStatePromise(this, { isRenderWebView: false })
      p.op.showConfirmationOkOnly('เกิดข้อผิดพลาด', ` กรุณาติดต่อทีมงาน (${url}) `)
      return
    }

    // one way to handle a successful form submit is via query strings
    // if (url.includes('https://authresult.xselly.com/?status=success')) {
    if (url.includes('https://authresult.xselly.com/?status=success') || url.includes('https://authresult.xselly.com?status=success')) {
      // @ts-ignore webview แดง
      this.webview.current.stopLoading()
      const { selectedChannel } = this.state
      util.setStatePromise(this, { isRenderWebView: false })
      if (_.isNil(selectedChannel)) {
        p.op.showConfirmationOkOnly('เชื่อมต่อสำเร็จ', '')
      } else {
        const channelMKPName = util.getMKPName(selectedChannel.mkp_id)
        const alertSuccessTest =
          channelMKPName === '-'
            ? `เชื่อมต่อช่องทางขาย ${selectedChannel.name} สำเร็จแล้ว`
            : `เชื่อมต่อช่องทางขาย ${selectedChannel.name} กับ ${channelMKPName} สำเร็จแล้ว`
        p.op.showConfirmationOkOnly('', alertSuccessTest)
        // const channelMKPName = util.getMKPName(selectedChannel.mkp_id)
        // p.op.showConfirmationOkOnly(``, `เชื่อมต่อช่องทางขาย ${selectedChannel.name} กับ ${channelMKPName} สำเร็จแล้ว`)
      }

      this._getChannels()
      return
    }

    // UN AUTH //
    if (
      url.includes('https://cancelauthresult.xselly.com/?status=success') ||
      url.includes('https://cancelauthresult.xselly.com?status=success')
    ) {
      this.webview.current.stopLoading()
      util.setStatePromise(this, { isRenderWebView: false })
      p.op.showConfirmationOkOnly('', 'ยกเลิกการเชื่อมต่อช่องทางขายเรียบร้อย')
      this._getChannels()
      return
    }

    if (
      url.includes('https://cancelauthresult.xselly.com/?status=fail') ||
      url.includes('https://cancelauthresult.xselly.com?status=fail')
    ) {
      util.setStatePromise(this, { isRenderWebView: false })
      p.op.showConfirmationOkOnly('เกิดข้อผิดพลาด', ` กรุณาติดต่อทีมงาน (${url}) `)
    }
  }

  renderOrdersSummaryDonut = () => {
    const { selectedStore } = this.props
    if (!selectedStore || !selectedStore.has('order_summary')) {
      return null
    }
    return null
    // return (
    //   <OrdersSummaryDonut
    //     onPress={this._onOrdersDonutPress}
    //     orderSummary={selectedStore.get('order_summary')}
    //     orderCount={selectedStore.get('order_count')}
    //   />
    // )
  }

  _renderMain = () => {
    const { isRenderWebView } = this.state
    return (
      <XContainer>
        {this.renderCustomHeader()}
        <XContent p='2'>
          <ScrollView style={{ width: '100%' }}>
            <Box
              flex={1}
              w='full'
              onLayout={(event) => {
                const { x, y, width, height } = event.nativeEvent.layout
                util.setStatePromise(this, { loadingWidth: width, loadingHeight: height })
              }}>
              {this._renderbody()}
              {/* {this._renderbody2()} */}
              {/* {this._renderbody2()}
                {this._renderbody()} */}
            </Box>
          </ScrollView>
          {this._loading()}
        </XContent>
        {VIEW_HEIGHT_TEN}
      </XContainer>
    )
  }
}
