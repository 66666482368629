import React from 'react'
import BaseResellerApprovalListView, { IOrgPendingList } from 'x/modules/reseller/BaseResellerApprovalListView'
import { Image, Text, View, TouchableOpacity, FlatList, Keyboard } from 'react-native'
import { COLORS, STYLES } from 'x/config/styles'
import _ from 'lodash'
import p from 'x/config/platform-specific'
import XCustomHeader from 'xui/components/XCustomHeader'
import XInput from 'xui/components/XInput'
import Segment from 'xui/components/Segment'
import * as util from 'x/utils/util'
import XIcon from 'xui/components/XIcon'

export default abstract class BaseUIProfileView extends BaseResellerApprovalListView {
  static displayName = 'BaseUIPaymentListView'

  abstract openModelUploading: () => void

  abstract renderMain: () => JSX.Element

  abstract navToCustomer: (item: IOrgPendingList) => void

  _renderHeader = () => {
    const leftBtn = { backIcon: true, onPressItem: () => util.navGoBack(this.props) }
    return <XCustomHeader title='อนุมัติตัวแทน' headerLeftProps={leftBtn} />
  }

  _renderProfileItems = ({ index, item }) => {
    // console.log(`item`, item)
    if (_.isNil(item)) {
      return
    }
    // @ts-ignore: FIXME: อย่าลืมประกาศ types
    const { navigation } = this.props
    const isEven = index % 2 === 0
    const name = _.isNil(item.fn) ? 'ไม่ระบุชื่อ ' : item.fn
    const resellerCode = _.isNil(item.c) ? 'ยังไม่มีรหัสตัวแทน' : item.c
    const lastName = _.isNil(item.ln) ? '' : item.ln
    const upper = _.isNil(item.s) ? null : item.s
    return (
      <View style={{ backgroundColor: isEven ? COLORS.BG_LIGHT_GREY : COLORS.WHITE, height: 80, width: '100%', flexDirection: 'row' }}>
        <TouchableOpacity
          onPress={async () => await this._omPressCheckBox(item)}
          style={{ height: 80, width: 40, justifyContent: 'center', alignItems: 'center' }}>
          <XIcon
            family='FontAwesome'
            style={{
              flex: 0,
              fontSize: STYLES.FONT_ICON_NORMAL,
              color: COLORS.APP_MAIN,
            }}
            name={item.isCheckBox ? 'check-square-o' : 'square-o'}
          />
        </TouchableOpacity>

        <TouchableOpacity onPress={() => this.navToCustomer(item)} style={{ height: 80, flex: 1, flexBasis: 'auto', flexDirection: 'row' }}>
          <View style={{ width: 50, alignItems: 'center', justifyContent: 'center', marginLeft: -8 }}>
            <Image source={{ uri: item.t }} style={{ width: 40, height: 40, borderRadius: 40 / 2 }} />
          </View>
          <View style={{ flex: 1, flexDirection: 'column', justifyContent: 'center' }}>
            <View style={{ width: '100%', height: 40, flexDirection: 'column', justifyContent: 'center', marginTop: 2 }}>
              <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE, paddingLeft: 4 }}>{`${name} ${lastName}`}</Text>
              <Text
                style={{
                  fontSize: STYLES.FONT_SIZE_NORMAL,
                  color: COLORS.TEXT_ACTIVE,
                  paddingLeft: 4,
                }}>{`รหัสตัวแทน ${resellerCode}`}</Text>
            </View>
            {_.isNil(upper) ? (
              <View style={{ width: '100%', height: 40, padding: 2, marginBottom: 4 }} />
            ) : (
              <View
                style={{
                  width: '100%',
                  height: 40,
                  borderWidth: 0.5,
                  borderColor: COLORS.RED_SOFT,
                  padding: 2,
                  borderRadius: 7,
                  marginBottom: 8,
                }}>
                <Text
                  style={{
                    fontSize: STYLES.FONT_SIZE_NORMAL,
                    color: COLORS.TEXT_ACTIVE,
                    paddingLeft: 4,
                  }}>{`แม่ทีม: ${upper.fn} ${upper.ln}`}</Text>
                <Text
                  style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE, paddingLeft: 4 }}>{`รหัสตัวแทน ${upper.c}`}</Text>
              </View>
            )}
          </View>
          <View style={{ width: 50, alignItems: 'flex-start', justifyContent: 'center' }}>
            <XIcon family='AntDesign' name='arrowright' style={{ minWidth: 25, width: 25, color: COLORS.TEXT_INACTIVE, flex: 0 }} />
          </View>
        </TouchableOpacity>
      </View>
    )
  }

  _renderApprovalsBtn = () => {
    const { isMainCheckBox, profilesSelcted, profiles } = this.state
    const isProfilesSelctedNotNull = !_.isNil(profilesSelcted)
    const numberAppovals = isProfilesSelctedNotNull ? profilesSelcted.length : ''
    let lableApproval = `กรุณาเลือกตัวแทน`
    let lableReject = `กรุณาเลือกตัวแทน`
    const disabledBtnLable = !!(_.isNil(profilesSelcted) || profilesSelcted.length < 1)
    if (isProfilesSelctedNotNull && profilesSelcted.length > 0) {
      lableApproval = `อนุมัติ ${numberAppovals} คน`
      lableReject = `ปฎิเสธ ${numberAppovals} คน`
    }
    const disableMainCheckBox = !!(_.isNil(profiles) || profiles.length < 1)
    return (
      <View
        style={{
          width: '100%',
          height: 40,
          //  paddingRight: 10,
          flexDirection: 'row',
          alignItems: 'center',
        }}>
        <TouchableOpacity
          disabled={disableMainCheckBox}
          onPress={() => this._onPressMainCheckBox(!isMainCheckBox)}
          style={{ height: 40, width: 40, justifyContent: 'center', alignItems: 'center' }}>
          <XIcon
            family='FontAwesome'
            style={{
              flex: 0,
              fontSize: STYLES.FONT_ICON_NORMAL,
              color: disableMainCheckBox ? COLORS.BG_LIGHT_GREY : COLORS.APP_MAIN,
            }}
            name={isMainCheckBox ? 'check-square-o' : 'square-o'}
          />
        </TouchableOpacity>
        {/* // ปุ่ม กรุณาเลือกตัวแทน */}
        <TouchableOpacity
          disabled={disabledBtnLable}
          onPress={() => this._confirmationApprpovals(`a`)}
          style={{
            height: 30,
            marginRight: 20,
            flex: 1,
            flexBasis: 'auto',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: disabledBtnLable ? COLORS.BG_LIGHT_GREY : COLORS.GREEN,
            borderRadius: 7,
          }}>
          {disabledBtnLable ? null : (
            <XIcon
              family='FontAwesome'
              style={{
                flex: 0,
                width: 25,
                minWidth: 25,
                fontSize: 15,
                color: disabledBtnLable ? COLORS.BG_LIGHT_GREY : COLORS.WHITE,
              }}
              name='check'
            />
          )}
          <Text
            style={{
              fontSize: STYLES.FONT_SIZE_NORMAL,
              color: disabledBtnLable ? COLORS.TEXT_INACTIVE : COLORS.WHITE,
              fontWeight: 'bold',
            }}>
            {lableApproval}
          </Text>
        </TouchableOpacity>

        <TouchableOpacity
          disabled={disabledBtnLable}
          onPress={() => this._confirmationApprpovals(`r`)}
          style={{
            height: 30,
            marginRight: 20,
            flex: 1,
            flexBasis: 'auto',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: disabledBtnLable ? COLORS.BG_LIGHT_GREY : COLORS.BRAND_Danger,
            borderRadius: 7,
          }}>
          {disabledBtnLable ? null : (
            <XIcon
              family='FontAwesome'
              style={{
                flex: 0,
                width: 25,
                minWidth: 25,
                fontSize: 15,
                color: disabledBtnLable ? COLORS.BG_LIGHT_GREY : COLORS.WHITE,
              }}
              name='close'
            />
          )}
          <Text
            style={{
              fontSize: STYLES.FONT_SIZE_NORMAL,
              color: disabledBtnLable ? COLORS.TEXT_INACTIVE : COLORS.WHITE,
              fontWeight: 'bold',
            }}>
            {lableReject}
          </Text>
        </TouchableOpacity>
      </View>
    )
  }

  _renderSearchNameInput = (key: string, title: string, placeholder: string) => (
    <View style={{ flex: 1, flexDirection: 'column', paddingLeft: 10, paddingRight: 10 }}>
      <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE }}>{title}</Text>
      <XInput
        // style={{ height: 10 }}
        onSubmitEditing={this._handleKeyEnter}
        value={this.state[key]}
        onChangeText={(val: string) => util.setStatePromise(this, { [key]: val })}
        placeholder={placeholder}
      />
    </View>
  )
  // return (
  //   <View style={{ height: 50, width: '100%', flexDirection: 'row', alignItems: 'center', paddingBottom: 10 }}>
  //     <View style={{ width: 70, paddingLeft: 4 }}>
  //       <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE }}>{title}</Text>
  //     </View>
  //     <View style={{ flex: 1, flexBasis: `auto`, paddingRight: 4 }}>
  //       <XInput
  //         onSubmitEditing={this._handleKeyEnter}
  //         value={this.state[key]}
  //         onChangeText={(val: string) => util.setStatePromise(this, { [key]: val })}
  //         placeholder={placeholder}
  //       />
  //     </View>
  //   </View>
  // )

  // TODO:: ยังไม่รู้จะประกาศ type ยังไง
  // @ts-ignore
  _handleKeyEnter = async (e) => {
    if (e.key === 'Enter') {
      await this._onPressSearchBtn()
    }
  }

  _onPressSearchBtn = async () => {
    if (!p.op.isWeb()) {
      Keyboard.dismiss()
    }
    const munbers = await this._searchMumber()
    if (_.isNil(munbers)) {
      return
    }
    if (munbers.length < 1) {
      p.op.showConfirmationOkOnly(``, `ไม่พบรายการที่ค้นหา`)
    }
    await util.setStatePromise(this, {
      profiles: munbers,
      isMainCheckBox: false,
    })
  }

  _renderSearchBtn = () => {
    const { indexImportSegment } = this.state
    const isOrgOwnerStore = util.isOrgOwnerStore(this.props.selectedStore)
    return (
      <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
        <View style={{ height: 25, width: 165, paddingLeft: 8 }}>
          {isOrgOwnerStore ? (
            <Segment selectedIndex={indexImportSegment} options={this.OPTIONS_IMPORT} onSegmentChange={this._onChangeImportSement} />
          ) : null}
        </View>
        <View
          style={{
            height: 50,
            flex: 1,
            // width: '100%',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            paddingRight: 8,
            paddingBottom: 4,
            paddingTop: 4,
          }}>
          <TouchableOpacity
            onPress={async () => await this._onPressClearBtn()}
            style={{
              width: 70,
              height: 28,
              flexDirection: 'row',
              // paddingBottom: 4,
              // paddingRight: 4,
              backgroundColor: COLORS.BG_LIGHT_GREY,
              borderColor: COLORS.APP_MAIN,
              borderWidth: 0.8,
              borderRadius: 7,
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <XIcon
              family='AntDesign'
              style={{
                flex: 0,
                width: 25,
                minWidth: 20,
                fontSize: 15,
                color: COLORS.TEXT_INACTIVE,
                marginLeft: -4,
              }}
              name='closecircle'
            />
            <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.APP_MAIN, paddingLeft: 4 }}>ล้าง</Text>
          </TouchableOpacity>
          <View style={{ width: 10 }} />
          <TouchableOpacity
            onPress={async () => await this._onPressSearchBtn()}
            style={{
              width: 70,
              height: 28,
              flexDirection: 'row',
              // paddingBottom: 4,
              // paddingRight: 4,
              backgroundColor: COLORS.BG_LIGHT_GREY,
              borderColor: COLORS.APP_MAIN,
              borderWidth: 0.8,
              borderRadius: 7,
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <XIcon
              family='AntDesign'
              style={{
                flex: 0,
                width: 20,
                minWidth: 20,
                fontSize: 15,
                color: COLORS.APP_MAIN,
              }}
              name='search1'
            />
            <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.APP_MAIN }}>ค้นหา</Text>
          </TouchableOpacity>
        </View>
      </View>
    )
  }

  _renderInputView = () => (
    <View>
      <View style={{ flexDirection: 'row', paddingTop: 10, height: 60 }}>
        {this._renderSearchNameInput(`nameText`, `ชื่อ`, `เช่น กนก`)}
        {this._renderSearchNameInput(`lastNameText`, `นามสกุล`, `เช่น เจริญรวยมาก`)}
      </View>
      <View style={{ flexDirection: 'row', paddingTop: 10, height: 60 }}>
        {this._renderSearchNameInput(`emailText`, `อีเมล`, `เช่น xselly_02@gmail.com`)}
        {this._renderSearchNameInput(`phoneText`, `เบอร์โทร`, `เช่น 0874100000`)}
      </View>
      <View style={{ flexDirection: 'row', paddingTop: 10, height: 60 }}>
        {this._renderSearchNameInput(`sellerCodeText`, `รหัสตัวแทน`, `เช่น SS04-00000000`)}
        {this._renderSearchNameInput(`parentResellerText`, `รหัสแม่ทีม`, `เช่น SS04-00000000`)}
      </View>
    </View>
  )

  _renderSearchBody = () => (
    <View>
      {this._renderHeader()}
      {this.openModelUploading()}
      <View style={{ paddingTop: 4 }} />
      {this._renderInputView()}
      {/* {this._renderSearchNameInput(`nameText`, `ชื่อ`, `เช่น กนก`)}
        {this._renderSearchNameInput(`lastNameText`, `นามสกุล`, `นามสกุล`)}
        {this._renderSearchNameInput(`sellerCodeText`, `รหัสตัวแทน`, `เช่น SS04-00000000`)} */}
      {this._renderSearchBtn()}
      {this._renderApprovalsBtn()}
    </View>
  )

  _renderFlatList = () => {
    const { profiles } = this.state
    return (
      <FlatList
        data={profiles}
        renderItem={this._renderProfileItems}
        // onEndReachedThreshold={0.9}
        // onEndReached={this._handleLoadMore}
      />
    )
  }

  render() {
    return this.renderMain()
  }
}
