import React, { useContext, useState } from 'react'

import { View, StyleSheet, TouchableOpacity } from 'react-native'
import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import Spinner from 'xui/components/XSpinner'
import * as util from 'x/utils/util'
import CONS from 'x/config/constants'
import p from 'x/config/platform-specific'
import { COLORS, STYLES } from 'x/config/styles'
import * as NavActions from 'x/utils/navigation'
import XCustomHeader from 'xui/components/XCustomHeader'
import OrdersSummaryDonut from 'xui/components/OrdersSummaryDonut'
import ForwardIcon from 'xui/components/ForwardIcon'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import StoreOrdersCreatedAtDatesSelector from 'xui/components/store/StoreOrdersCreatedAtDatesSelector'
import { canDoAtSelectedStore, canSeeReport } from 'x/utils/acl'
import { getDonutData, delay } from 'x/utils/util'
import { ISelectedStoreMap, ActionApiParams, IXScreenProps, IVerifySlipCreditSummaryItem } from 'x/index'
import XText from 'xui/components/XText'
import XCard from 'xui/components/XCard'
import _ from 'lodash'
import Box from 'xui/components/Box'
import XIcon from 'xui/components/XIcon'
import VerifySlipIcon from 'xui/components/VerifySlipIcon'
import * as acl from 'x/utils/acl'
import XButton from 'xui/components/XButton'
import { fetchVerifySlipCreditSummary, fetchVerifySlipCreditTrial } from 'x/utils/api'
import * as xFmt from 'x/utils/formatter'
import dayjs from 'dayjs'
import DailySaleBox from 'xui/components/dashboard/DailySaleBox'
import { Map } from 'immutable'
import { getXShippingConfigFromRedux } from 'x/redux/selectors'
import ThemeSizeContext from '../../context/ThemeSizeContext'
// const TestButton = p.comp.getTestButton()

interface IResXShippingBalance {
  balance: {
    store_id: number
    system_id: number
    balance: string
    balance_updated_at: string
  }
}

const styles = StyleSheet.create({
  textShadow: {
    textShadowColor: COLORS.TEXT_INACTIVE,
    textShadowOffset: { width: 0.25, height: 0.25 },
    textShadowRadius: 0.25,
  },
})

// LOCAL STYLES
const LS = StyleSheet.create({
  CUSTOM_MENU_LABEL_INACTIVE: {
    color: COLORS.TEXT_INACTIVE,
    fontSize: STYLES.FONT_SIZE_SMALLER,
    textAlign: 'center',
  },
  LABEL_INACTIVE_SMALLEST: {
    color: COLORS.TEXT_INACTIVE,
    // fontSize: STYLES.FONT_SIZE_SMALLEST,
    textAlign: 'center',
  },
  LABEL_DAILY_SALE_AMOUNT: {
    color: COLORS.BRAND_Info,
    // fontSize: STYLES.FONT_SIZE_NORMAL,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  LABEL_DAILY_PROFIT_AMOUNT: {
    color: COLORS.BRAND_Success,
    // fontSize: STYLES.FONT_SIZE_NORMAL,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  LABEL_DAILY_SALE_ORDER_COUNT: {
    color: COLORS.APP_MAIN,
    // fontSize: STYLES.FONT_SIZE_NORMAL,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  MENU_ICON_IMAGE: {
    // height: 22,
    height: 26,
  },
  MENU_LABEL_COUNT: {
    color: COLORS.APP_MAIN,
    // fontSize: IS_SMALL_DEVICE ? STYLES.FONT_SIZE_LARGER : STYLES.FONT_SIZE_LARGER_2,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  MENU_VALUE_UNIT: {
    // position: 'absolute',
    // right: 4,
    color: COLORS.APP_MAIN,
    // paddingTop: 4,
    // fontSize: IS_SMALL_DEVICE ? STYLES.FONT_SIZE_SMALLEST : STYLES.FONT_SIZE_SMALLER,
    // textAlign: 'center',
    // fontWeight: 'bold',
  },
  MENU_VALUE_UNIT_GREEN: {
    // position: 'absolute',
    // right: 4,
    color: COLORS.GREEN,
    // paddingTop: 4,
    // fontSize: IS_SMALL_DEVICE ? STYLES.FONT_SIZE_SMALLEST : STYLES.FONT_SIZE_SMALLER,
  },
  MENU_VALUE_UNIT_ORANGE: {
    // position: 'absolute',
    // right: 4,
    color: COLORS.ORANGE,
    // paddingTop: 4,
    // fontSize: IS_SMALL_DEVICE ? STYLES.FONT_SIZE_SMALLEST : STYLES.FONT_SIZE_SMALLER,
  },
  MENU_VALUE_UNIT_RED: {
    // position: 'absolute',
    // right: 4,
    color: COLORS.RED,
    // paddingTop: 4,
    // fontSize: IS_SMALL_DEVICE ? STYLES.FONT_SIZE_SMALLEST : STYLES.FONT_SIZE_SMALLER,
  },
  MENU_VALUE_UNIT_DISABLED: {
    // position: 'absolute',
    // right: 4,
    color: COLORS.TEXT_INACTIVE,
    // paddingTop: 4,
    // fontSize: IS_SMALL_DEVICE ? STYLES.FONT_SIZE_SMALLEST : STYLES.FONT_SIZE_SMALLER,
  },
  MENU_COLUMN: {
    // padding: 8,
    // marginVertical: 4,
    // marginHorizontal: 8,
    // height: 50,
    minHeight: 54,
  },
  BADGE_CONTAINER: {
    height: 18,
    borderRadius: 9,
    position: 'absolute',
    top: 2,
    right: 2,
  },
  BADGE_TEXT: {
    color: 'white',
    fontSize: STYLES.FONT_SIZE_SMALLER,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  GRID_COL_MIDDLE_CENTER: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  GRID_COL_MIDDLE_LEFT: {
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  GRID_COL_MIDDLE_RIGHT: {
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  GRID_COL_BORDER_RIGHT: {
    borderColor: COLORS.TEXT_INACTIVE,
    borderRightWidth: 0.5,
  },
  BTN_TOGGLE_SUMMARY: {
    width: 28,
    height: 28,
    position: 'absolute',
    alignItems: 'flex-end',
    top: 0,
    right: 0,
    padding: 2,
    zIndex: 100,
  },
})

const MENU_BUTTON_BY_TYPES = StyleSheet.create({
  '1': {
    // flex: 1,
    backgroundColor: COLORS.APP_MAIN,
    borderWidth: 1,
    borderColor: COLORS.APP_MAIN,
    // borderRadius: 4,
    // borderRadius: 0,
  },
  '2': {
    // flex: 1,
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: COLORS.APP_MAIN,
    // borderRadius: 4,
    // borderRadius: 0,
  },
  '3': {
    // flex: 1,
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: COLORS.TEXT_INACTIVE,
    // borderRadius: 4,
    // borderRadius: 0,
  },
  '4': {
    // flex: 1,
    backgroundColor: COLORS.GREEN_SOFT,
    borderWidth: 1,
    borderColor: COLORS.GREEN,
    // borderRadius: 4,
    // borderRadius: 0,
  },
  '5': {
    // flex: 1,
    backgroundColor: COLORS.ORANGE_SOFT,
    borderWidth: 1,
    borderColor: COLORS.ORANGE,
    // borderRadius: 4,
    // borderRadius: 0,
  },
  '6': {
    // flex: 1,
    backgroundColor: COLORS.RED_SOFT,
    borderWidth: 1,
    borderColor: COLORS.RED,
    // borderRadius: 4,
    // borderRadius: 0,
  },
  '7': {
    // flex: 1,
    backgroundColor: COLORS.BG_LIGHT_GREY,
    borderWidth: 1,
    borderColor: COLORS.TEXT_INACTIVE,
    // borderRadius: 4,
    // borderRadius: 0,
  },
})

const MENU_LABEL_BY_TYPES = StyleSheet.create({
  '1': {
    color: 'white',
    // fontSize: STYLES.FONT_SIZE_NORMAL,
    // textAlign: 'center',
    // fontWeight: 'bold',
  },
  '2': {
    // color: COLORS.TEXT_INACTIVE,
    color: COLORS.APP_MAIN,
    // fontSize: STYLES.FONT_SIZE_NORMAL,
    // textAlign: 'center',
  },
  '3': {
    color: COLORS.TEXT_INACTIVE,
    // fontSize: STYLES.FONT_SIZE_NORMAL,
    // textAlign: 'center',
  },
  // FOR XShipping 4 5 6
  '4': {
    color: COLORS.GREEN,
    // fontSize: STYLES.FONT_SIZE_NORMAL,
    // textAlign: 'center',
  },
  '5': {
    color: COLORS.ORANGE,
    // fontSize: STYLES.FONT_SIZE_NORMAL,
    // textAlign: 'center',
  },
  '6': {
    color: COLORS.RED,
    // fontSize: STYLES.FONT_SIZE_NORMAL,
    // textAlign: 'center',
  },
  '7': {
    color: COLORS.TEXT_INACTIVE,
    // fontSize: STYLES.FONT_SIZE_NORMAL,
    // textAlign: 'center',
  },
})

const DonutMenuContainer = ({ style = {}, children }) => (
  <VStack
    px='2'
    space='1'
    style={StyleSheet.flatten([
      // {
      //   paddingLeft: 4,
      //   paddingRight: 4,
      //   marginTop: 8,
      // },
      style,
    ])}>
    {children}
  </VStack>
)
const DonutMenuItem = (props: { title: string; count?: string | number; onPress: () => void; color?: string }) => {
  const { title, count = '', onPress, color } = props
  return (
    <XCard w='full' onPress={onPress}>
      <XCard.Body>
        <HStack
          w='full'
          minH='9'
          // style={{
          //   height: 44,
          //   borderTopWidth: 1,
          //   borderLeftWidth: 1,
          //   borderBottomWidth: 1,
          //   borderRightWidth: 1,
          //   borderTopColor: COLORS.BG_LIGHT_GREY_ALTERNATIVE,
          //   borderLeftColor: COLORS.BG_LIGHT_GREY_ALTERNATIVE,
          //   borderBottomColor: COLORS.TEXT_INACTIVE,
          //   borderRightColor: COLORS.TEXT_INACTIVE,
          //   marginBottom: 8,
          //   backgroundColor: COLORS.WHITE,
          // }}
        >
          <VStack
            flex={1}
            justifyContent='center'
            my='2'
            mx='1'
            // style={{
            //   justifyContent: 'center',
            //   marginTop: 4,
            //   marginBottom: 4,
            //   paddingLeft: 8,
            // }}
          >
            <XText
              // style={[S.TEXT_ACTIVE, styles.textShadow, { color }]}
              bold
              color={color}>
              {title}
            </XText>
          </VStack>
          <VStack style={{ width: 44, justifyContent: 'center', alignItems: 'center' }}>
            <XText
              // style={[S.TEXT_ACTIVE, styles.textShadow, { color }]}
              bold
              color={color}>
              {count}
            </XText>
          </VStack>
          <VStack style={{ width: 44, justifyContent: 'center', alignItems: 'center' }}>
            <ForwardIcon />
          </VStack>
        </HStack>
      </XCard.Body>
    </XCard>
  )
}

interface IStoreOrdersDonutListViewProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
  shouldFetchStoreInfo: boolean
  fetchMyStoreInfo: (params: ActionApiParams) => void
  updateXShippingBalance: (params: ActionApiParams) => void
  updateSelectedStoreByKey: (key: string, value: any) => void
}

const StoreOrdersDonutListView: React.FC<IStoreOrdersDonutListViewProps> = (props) => {
  const { selectedStore, shouldFetchStoreInfo, fetchMyStoreInfo, navigation, updateXShippingBalance, updateSelectedStoreByKey } = props
  const { contentOneOfThreeColumnWidth } = useContext(ThemeSizeContext)
  const selectedStoreId = selectedStore.get('id')
  const orderSummary = selectedStore.get('order_summary') || Map({})
  const donutData = getDonutData(orderSummary)
  const [isFetchingState, setIsFetchingState] = useState(false)
  const [isVisibleHeaderSummaryReport, setisVisibleHeaderSummaryReportState] = useState(true)
  const [loadingStatusXShippingBalance, setLoadingStatusXShippingBalanceState] = useState(false)
  const [XShippingBalance, setXShippingBalanceState] = useState(0)

  const [verifySlipTotalRemainingCredit, setVerifySlipTotalRemainingCredit] = useState<number | string>('-')
  const [verifySlipRemainingCreditItems, setVerifySlipRemainingCreditItems] = useState<IVerifySlipCreditSummaryItem[]>([])
  const [verifySlipUnusableCreditItems, setVerifySlipUnusableCreditItems] = useState<IVerifySlipCreditSummaryItem[]>([])
  const [isVerifySlipTrialSuccessPressed, setIsVerifySlipTrialSuccess] = useState<boolean>(false)

  const getFunctionNavToStoreOrderSingleTabListView = (tabKey) => async () => {
    navigation.popToTop()
    await delay(100)
    // navigation.dispatch(NavActions.navToStoreOrderSingleTabListView({ tabKey }))
    // navigation.dispatch(NavActions.navToOrderListView({ store_id: selectedStoreId, availableTabKeys: [tabKey] }))
    navigation.dispatch(NavActions.navToOrderListView({ store_id: selectedStoreId, availableTabKeys: [tabKey] }))
  }
  const isShowThisView = canDoAtSelectedStore(CONS.PERM_STORE_HELPER.ORDER_LIST)
  // // const isFocused = navigation.isFocused()
  // const [focusState, setFocusState] = useState(false)
  // const handleEvt = (e) => {
  //   console.log('handleEvt e => ', e)
  //   // const newState = focusStateOfEvent(e.type)
  //   // newState && setFocusState(newState)
  //   // console.log('handleEvt focusState => ', focusState)
  // }

  // const handleEvtwillFocus = async (e) => {
  //   // console.log('handleEvtDidFocus e => ', e)
  //   if (!shouldFetchStoreInfo || isFetchingState) {
  //     return
  //   }
  //   setIsFetchingState(true)
  //   const body = { store_id: selectedStoreId }
  //   //   // https://effily.atlassian.net/browse/XSELLY-845
  //   // Sample body
  //   // body = {
  //   // store_id: 3,
  //   // info_fields: ['order_summary','order_count', 'dailysales', 'product_count']
  //   // }
  //   const response = await new Promise<boolean>((resolve: any) => {
  //     fetchMyStoreInfo({
  //       body,
  //       successCallback: resolve,
  //       failedCallback: () => resolve(false),
  //     })
  //   })

  //   // if (response) {
  //   //   console.log('fetchMyStoreInfo response => ', response)
  //   // }

  //   setIsFetchingState(false)
  // }

  // const handlePressAddOrder = async () => {
  //   // const store_id = selectedStore.get('id')

  //   // navigation.popToTop()
  //   // await delay(50)
  //   // navigation.dispatch(
  //   //   NavigationActions.navigate({
  //   //     key: 'OrderView',
  //   //     routeName: 'OrderView',
  //   //     params: {
  //   //       mode: CONS.ORDER_VIEW_MODE.CREATE,
  //   //       store_id,
  //   //       isModal: true,
  //   //     },
  //   //   })
  //   // )
  // }

  const isTrialDeadlineExpired = () => {
    const today = dayjs()
    const deadline = dayjs('2023-11-02 23:59:59')
    if (today.isAfter(deadline)) {
      return true
    }
    return false
  }

  const fetchTotalRemainingCredit = async () => {
    try {
      const verifySlipCreditSummaryMap = selectedStore.get('verify_slip_credit_summary')
      let verifySlipCreditSummary
      if (Map.isMap(verifySlipCreditSummaryMap)) {
        verifySlipCreditSummary = verifySlipCreditSummaryMap.toJS()
      } else {
        verifySlipCreditSummary = await fetchVerifySlipCreditSummary({
          store_id: selectedStoreId,
          show_remaining_credits: !isTrialDeadlineExpired(),
          show_unusable_credits: !isTrialDeadlineExpired(),
          offset: 0,
          limit: 20,
        })
        updateSelectedStoreByKey('verify_slip_credit_summary', verifySlipCreditSummary)
      }

      // const res = await fetchVerifySlipCreditSummary({
      //   store_id: selectedStoreId,
      //   show_remaining_credits: !isTrialDeadlineExpired(),
      //   show_unusable_credits: !isTrialDeadlineExpired(),
      //   offset: 0,
      //   limit: 20,
      // })

      if (verifySlipCreditSummary && !_.isNil(verifySlipCreditSummary.total_remaining_credit)) {
        setVerifySlipTotalRemainingCredit(verifySlipCreditSummary.total_remaining_credit)
      }
      if (verifySlipCreditSummary && _.isArray(verifySlipCreditSummary.remaining_credits)) {
        setVerifySlipRemainingCreditItems(verifySlipCreditSummary.remaining_credits)
      }
      if (verifySlipCreditSummary && _.isArray(verifySlipCreditSummary.unusable_credits)) {
        setVerifySlipUnusableCreditItems(verifySlipCreditSummary.unusable_credits)
      }
    } catch (err) {
      console.log('fetchTotalRemainingCredit err', err)
    }
  }

  React.useEffect(() => {
    let isMounted = true
    if (isMounted) {
      fetchTotalRemainingCredit()
      _updateXShippingBalance()
    }

    return () => {
      isMounted = false
    }
  }, [])

  // useEffect(() => {
  //   let isMounted = true
  //   // const subsA = navigation.addListener('action', handleEvt)
  //   if (isMounted) {
  //     const subsWF = navigation.addListener('willFocus', handleEvtwillFocus)
  //   }
  //   // const subsDF = navigation.addListener('didFocus', handleEvt)
  //   // const subsWB = navigation.addListener('willBlur', handleEvt)
  //   // const subsDB = navigation.addListener('didBlur', handleEvt)
  //   return () => {
  //     // subsA.remove()
  //     isMounted = false
  //     subsWF.remove()
  //     // subsDF.remove()
  //     // subsWB.remove()
  //     // subsDB.remove()
  //   }
  // }, [])

  // const _navToProductListMkpView = (channel) => {
  // const _navToProductListMkpView = () => {
  //   navigation.dispatch(
  //     NavActions.navToProductListViewMkpView({
  //       // store_id: selectedStore.get('id'),
  //       // mkp_channel_id: channel.id,
  //       // mkp_id: channel.mkp_id,

  //       store_id: selectedStoreId,
  //       // mkp_channel_id: 43499,
  //       mkp_channel_id: 43491,
  //       mkp_id: 1,
  //     })
  //   )
  // }

  const _updateXShippingBalance = async () => {
    // const { selectedStoreId } = this.state
    // const { updateXShippingBalance, selectedStore } = this.props
    const useXshipping = selectedStore.has('s_use_xshipping') ? selectedStore.get('s_use_xshipping') : false
    // console.log('useXshipping => ', useXshipping)
    if (!useXshipping) {
      return
    }
    const hasShipjung = util.hasShipjungShipping()
    if (!hasShipjung) {
      return
    }

    const xShippingConfig = getXShippingConfigFromRedux(p.op.getAppState())

    // const xshippings = selectedStore.get('xshippings')
    if (_.isNil(xShippingConfig) || xShippingConfig.size < 1 || _.isNil(selectedStoreId)) {
      p.op.showToast('เกิดข้อผิดพลาด กรุณาติดต่อทีมงาน XSeely', 'warning')
      return
    }
    const shipjungSystemId = CONS.SYSTEM_ID.SHIPJUNG
    // await util.setStatePromise(this, { loadingStatusXShippingBalance: true })
    setLoadingStatusXShippingBalanceState(true)
    const res: IResXShippingBalance = await new Promise((resolve) => {
      updateXShippingBalance({
        body: {
          store_id: selectedStoreId,
          system_id: shipjungSystemId,
        },
        successCallback: resolve,
        failedCallback: resolve,
      })
    })
    // await xUtil.setStatePromise(this, { loadingStatusXShippingBalance: false })
    setLoadingStatusXShippingBalanceState(false)
    if (res && res.balance) {
      const { balance } = res.balance
      // xUtil.setStatePromise(this, { XShippingBalance: balance })
      // @ts-ignore
      setXShippingBalanceState(balance)
    } else {
      p.op.showToast('ไม่สามารถอัพเดตยอดเงินได้ในขณะนี้ กรุณาทำรายการใหม่ภายหลัง', 'warning')
    }
  }

  const _showXshippingFeilActionSheet = () => {
    const xShippingRequestFailed = orderSummary.has('xShipping_RequestFailed') ? orderSummary.get('xShipping_RequestFailed') : 0
    const xShippingCancelFailed = orderSummary.has('xShipping_CancelFailed') ? orderSummary.get('xShipping_CancelFailed') : 0
    // const asOptions = [
    //   `${p.op.t('xshippingStatus.feil_waiting_me_to_check_one_line')} (${xShippingRequestFailed})`,
    //   `${p.op.t('xshippingStatus.xshipping_cancel_failed_one_line')} (${xShippingCancelFailed})`,
    //   `ปิด`,
    // ]

    const aItems = []
    const navItems = []
    if (xShippingRequestFailed > 0) {
      aItems.push(`${p.op.t('xshippingStatus.feil_waiting_me_to_check_one_line')} (${xShippingRequestFailed})`)
      navItems.push(CONS.XSHIPPING_STATUS_TASKS.XSHIPPING_REQUEST_FAILES)
    }
    if (xShippingCancelFailed > 0) {
      aItems.push(`${p.op.t('xshippingStatus.xshipping_cancel_failed_one_line')} (${xShippingCancelFailed})`)
      navItems.push(CONS.XSHIPPING_STATUS_TASKS.xShipping_CancelFailed)
    }
    if (aItems.length > 0) {
      aItems.push('ปิด')
    }

    const cancelIdx = aItems.length - 1
    const actionCfg = {
      options: aItems,
      cancelButtonIndex: cancelIdx,
      // destructiveButtonIndex: cancelIdx,
      title: 'เลขพัสดุที่ออกโดยระบบ',
    }

    const handleIdxPress = (idx: string | number) => {
      const selectedIdx = _.isString(idx) ? parseInt(idx) : idx
      if (selectedIdx === cancelIdx || selectedIdx === -1) {
        return
      }
      // this._dispatchAction(NavActions.navToStoreOrderSingleTabListView({ tabKey: navItems[selectedIdx] }))
      navigation.dispatch(
        NavActions.navToOrderListView({
          store_id: selectedStoreId,
          availableTabKeys: [navItems[selectedIdx]],
        })
      )
    }

    if (navItems.length === 1) {
      navigation.dispatch(
        NavActions.navToOrderListView({
          store_id: selectedStoreId,
          availableTabKeys: [navItems[0]],
        })
      )
      return
    }

    p.op.showActionSheet(actionCfg, handleIdxPress)
  }

  const _showXshippingInProceedActionSheet = () => {
    // const { selectedStore } = this.props

    const xShippingRequesting =
      orderSummary.has('xShipping_Requesting') && orderSummary.get('xShipping_Requesting') > 0
        ? orderSummary.get('xShipping_Requesting')
        : 0
    const xShippingPendingRequestAuto =
      orderSummary.has('xShipping_PendingRequestAuto') && orderSummary.get('xShipping_PendingRequestAuto') > 0
        ? orderSummary.get('xShipping_PendingRequestAuto')
        : 0
    const xShippingPendingRequestManual =
      orderSummary.has('xShipping_PendingRequestManual') && orderSummary.get('xShipping_PendingRequestManual') > 0
        ? orderSummary.get('xShipping_PendingRequestManual')
        : 0
    const xShippingCancelling =
      orderSummary.has('xShipping_Cancelling') && orderSummary.get('xShipping_Cancelling') > 0
        ? orderSummary.get('xShipping_Cancelling')
        : 0
    const aItems = []
    const navItems = []
    if (xShippingRequesting > 0) {
      aItems.push(`${p.op.t('xshippingStatus.requesting_tracking')} (${xShippingRequesting})`)
      navItems.push(CONS.XSHIPPING_STATUS_TASKS.XSHIPPING_REQUESTING)
    }
    if (xShippingPendingRequestAuto > 0) {
      aItems.push(`${p.op.t('xshippingStatus.auto_request_if_order_ready_to_ship_one_line')} (${xShippingPendingRequestAuto})`)
      navItems.push(CONS.XSHIPPING_STATUS_TASKS.XSHIPPING_PANDING_REQUEST_AUTO)
    }
    if (xShippingPendingRequestManual > 0) {
      aItems.push(`${p.op.t('xshippingStatus.pending_request_manual')} (${xShippingPendingRequestManual})`)
      navItems.push(CONS.XSHIPPING_STATUS_TASKS.XSHIPPING_pENDING_REQUEST_MANUAL)
    }
    if (xShippingCancelling > 0) {
      aItems.push(`${p.op.t('xshippingStatus.cancelling')} (${xShippingCancelling})`)
      navItems.push(CONS.XSHIPPING_STATUS_TASKS.xShipping_Cancelling)
    }
    if (aItems.length > 0) {
      aItems.push('ปิด')
    }
    // else {
    //   p.op.alert('ฉันจัดการออเดอร์ทั้งหมดแล้ว')
    // }

    const cancelIdx = aItems.length - 1
    const actionCfg = {
      options: aItems,
      cancelButtonIndex: cancelIdx,
      // destructiveButtonIndex: cancelIdx,
      title: 'ออเดอร์ที่รอดำเนินการเกี่ยวกับ XShipping',
    }

    const handleIdxPress = (idx: string | number) => {
      const selectedIdx = _.isString(idx) ? parseInt(idx) : idx
      if (selectedIdx === cancelIdx || selectedIdx === -1) {
        return
      }
      // this._dispatchAction(NavActions.navToStoreOrderSingleTabListView({ tabKey: navItems[selectedIdx] }))
      // _navToStoreOrderSingleTabListView(navItems[selectedIdx])
      navigation.dispatch(
        NavActions.navToOrderListView({
          store_id: selectedStoreId,
          availableTabKeys: [navItems[selectedIdx]],
        })
      )
    }

    if (navItems.length === 1) {
      navigation.dispatch(
        NavActions.navToOrderListView({
          store_id: selectedStoreId,
          availableTabKeys: [navItems[0]],
        })
      )
      return
    }

    p.op.showActionSheet(actionCfg, handleIdxPress)
  }

  const renderRightXShippingHeader = () => {
    const hasShipjung = util.hasShipjungShipping()
    // console.log('renderRightXShippingHeader hasShipjung => ', hasShipjung)
    if (!hasShipjung) {
      return null
    }

    // const { loadingStatusXShippingBalance, XShippingBalance } = this.state
    let balance = '-'
    if (!_.isNil(XShippingBalance) && XShippingBalance !== 0) {
      balance = `฿${XShippingBalance}`
    }
    return (
      <HStack flex={1} p='2' alignItems='center' justifyContent='flex-end'>
        <TouchableOpacity
          onPress={loadingStatusXShippingBalance ? undefined : _updateXShippingBalance}
          style={{ flexDirection: 'row' }}
          onLayout={(event) => {
            const { x, y, width, height } = event.nativeEvent.layout
            // util.setStatePromise(this, { loadingWidthXShippingBalance: width, loadingHeightXShippingBalance: height })
          }}>
          <XText variant='inactive' fontSize='xs'>{`เครดิตค่าส่ง: `}</XText>

          {loadingStatusXShippingBalance ? (
            <HStack minW='20' px='2' justifyContent='flex-end'>
              <Spinner size='sm' />
            </HStack>
          ) : (
            <XText px='2' variant='success' bold>
              {balance}
            </XText>
          )}
        </TouchableOpacity>
        <TouchableOpacity onPress={() => window.open('https://oms.boxme.asia/#/account/topup', '_blank')}>
          <XIcon
            success
            style={{ fontSize: 20 }}
            // size={}
            // fontSize='16px'
            family='AntDesign'
            name='pluscircle'
          />
        </TouchableOpacity>
      </HStack>
    )
  }

  const renderHasTrackingView = () => {
    let btnStyle = MENU_BUTTON_BY_TYPES['4']
    // const valueUnitColor = LS.MENU_VALUE_UNIT
    let txtLabelStyle = [
      MENU_LABEL_BY_TYPES['4'],
      // { fontSize: mainFontSize === STYLES.FONT_SIZE_NORMAL ? STYLES.FONT_SIZE_NORMAL : mainFontSize },
    ]
    const menuColumnStyle = LS.MENU_COLUMN
    const text = 'เลขพัสดุพร้อมใช้'
    let onPress = () =>
      navigation.dispatch(
        NavActions.navToOrderListView({
          store_id: selectedStoreId,
          availableTabKeys: [CONS.XSHIPPING_STATUS_TASKS.XSHIPPING_HAS_TRACKING_NO],
        })
      )
    let hasTrackingCount = orderSummary.has(CONS.XSHIPPING_STATUS_TASKS.XSHIPPING_HAS_TRACKING_NO)
      ? orderSummary.get(CONS.XSHIPPING_STATUS_TASKS.XSHIPPING_HAS_TRACKING_NO)
      : 0
    if (hasTrackingCount > 999) {
      hasTrackingCount = 999
    }
    if (hasTrackingCount < 1) {
      txtLabelStyle = [MENU_LABEL_BY_TYPES['3']]
      btnStyle = MENU_BUTTON_BY_TYPES['3']
      // @ts-ignore
      onPress = () => p.op.showConfirmationOkOnly('', 'ไม่มีออเดอร์เลขพัสดุพร้อมใช้', () => null)
    }
    return (
      <VStack style={menuColumnStyle} pt='2' pl='2' flex={1}>
        <TouchableOpacity onPress={() => onPress()} style={[btnStyle, { borderRadius: 8 }]}>
          <VStack w='full' p='1'>
            <VStack w='full' alignItems='center' minH='7' justifyContent='center'>
              <XText numberOfLines={1} style={txtLabelStyle}>
                {`${hasTrackingCount} ออเดอร์`}
              </XText>
              <XText
                w='full'
                // allowFontScaling={false}
                numberOfLines={1}
                // isTruncated={true}
                adjustsFontSizeToFit
                textAlign='center'
                textVerticalAlign='center'
                fontSize='xs'
                // style={[txtLabelStyle, { marginTop: marginTopMainText, height: 30 }, lineHeight]}
                style={txtLabelStyle}>
                {text}
              </XText>
            </VStack>
          </VStack>
        </TouchableOpacity>
      </VStack>
    )
  }

  const renderProceedTrackingView = () => {
    let btnStyle = MENU_BUTTON_BY_TYPES['5']
    // const valueUnitColor = LS.MENU_VALUE_UNIT
    let txtLabelStyle = [
      MENU_LABEL_BY_TYPES['5'],
      // { fontSize: mainFontSize === STYLES.FONT_SIZE_NORMAL ? STYLES.FONT_SIZE_NORMAL : mainFontSize },
    ]
    const menuColumnStyle = LS.MENU_COLUMN
    let text = 'รอดำเนินการ'
    let onPress = () => _showXshippingInProceedActionSheet()

    const xShippingCancelling = orderSummary.has('xShipping_Cancelling') ? orderSummary.get('xShipping_Cancelling') : 0
    const xShippingRequesting = orderSummary.has('xShipping_Requesting') ? orderSummary.get('xShipping_Requesting') : 0
    const xShippingPendingRequestAuto = orderSummary.has('xShipping_PendingRequestAuto')
      ? orderSummary.get('xShipping_PendingRequestAuto')
      : 0
    const xShippingPendingRequestManual = orderSummary.has('xShipping_PendingRequestManual')
      ? orderSummary.get('xShipping_PendingRequestManual')
      : 0
    // const sum = xShippingCancelling + xShippingRequesting + xShippingPendingRequestAuto + xShippingPendingRequestManual

    let hasTrackingCount = xShippingCancelling + xShippingRequesting + xShippingPendingRequestAuto + xShippingPendingRequestManual
    if (hasTrackingCount > 999) {
      hasTrackingCount = 999
    }
    if (hasTrackingCount < 1) {
      txtLabelStyle = [MENU_LABEL_BY_TYPES['3']]
      btnStyle = MENU_BUTTON_BY_TYPES['3']
      text = p.op.t('xshippingStatus.pending')
      // @ts-ignore
      onPress = () => p.op.showConfirmationOkOnly('', 'ไม่มีออเดอร์ที่รอดำเนินการอยู่', () => null)
    }
    return (
      <VStack style={menuColumnStyle} pt='2' pl='2' flex={1}>
        <TouchableOpacity onPress={() => onPress()} style={[btnStyle, { borderRadius: 8 }]}>
          <VStack w='full' p='1'>
            <VStack w='full' alignItems='center' minH='7' justifyContent='center'>
              <XText numberOfLines={1} style={txtLabelStyle}>
                {`${hasTrackingCount} ออเดอร์`}
              </XText>
              <XText
                w='full'
                // allowFontScaling={false}
                numberOfLines={1}
                // isTruncated={true}
                adjustsFontSizeToFit
                textAlign='center'
                textVerticalAlign='center'
                fontSize='xs'
                // style={[txtLabelStyle, { marginTop: marginTopMainText, height: 30 }, lineHeight]}
                style={txtLabelStyle}>
                {text}
              </XText>
            </VStack>
          </VStack>
        </TouchableOpacity>
      </VStack>
    )
  }

  const renderErrorTrackingView = () => {
    let btnStyle = MENU_BUTTON_BY_TYPES['6']
    // const valueUnitColor = LS.MENU_VALUE_UNIT
    let txtLabelStyle = [
      MENU_LABEL_BY_TYPES['6'],
      // { fontSize: mainFontSize === STYLES.FONT_SIZE_NORMAL ? STYLES.FONT_SIZE_NORMAL : mainFontSize },
    ]
    const menuColumnStyle = LS.MENU_COLUMN
    let text = p.op.t('xshippingStatus.feil')
    let onPress = () => _showXshippingFeilActionSheet()

    const xShippingRequestFailed = orderSummary.has('xShipping_RequestFailed') ? orderSummary.get('xShipping_RequestFailed') : 0
    const xShippingCancelFailed = orderSummary.has('xShipping_CancelFailed') ? orderSummary.get('xShipping_CancelFailed') : 0

    let hasTrackingCount = xShippingRequestFailed + xShippingCancelFailed
    if (hasTrackingCount > 999) {
      hasTrackingCount = 999
    }
    if (hasTrackingCount < 1) {
      txtLabelStyle = [MENU_LABEL_BY_TYPES['3']]
      btnStyle = MENU_BUTTON_BY_TYPES['3']
      text = p.op.t('xshippingStatus.feil')
      // @ts-ignore
      onPress = () => p.op.showConfirmationOkOnly('', 'ไม่มีออเดอร์ที่เกิดข้อผิดพลาด', () => null)
    }
    return (
      <VStack style={menuColumnStyle} pt='2' pl='2' flex={1}>
        <TouchableOpacity onPress={() => onPress()} style={[btnStyle, { borderRadius: 8 }]}>
          <VStack w='full' p='1'>
            <VStack w='full' alignItems='center' minH='7' justifyContent='center'>
              <XText numberOfLines={1} style={txtLabelStyle}>
                {`${hasTrackingCount} ออเดอร์`}
              </XText>
              <XText
                w='full'
                // allowFontScaling={false}
                numberOfLines={1}
                // isTruncated={true}
                adjustsFontSizeToFit
                textAlign='center'
                textVerticalAlign='center'
                fontSize='xs'
                // style={[txtLabelStyle, { marginTop: marginTopMainText, height: 30 }, lineHeight]}
                style={txtLabelStyle}>
                {text}
              </XText>
            </VStack>
          </VStack>
        </TouchableOpacity>
      </VStack>
    )
  }

  const _renderXShippingText = () => {
    const xShippingCancelling = orderSummary.has('xShipping_Cancelling') ? orderSummary.get('xShipping_Cancelling') : 0
    const xShippingRequesting = orderSummary.has('xShipping_Requesting') ? orderSummary.get('xShipping_Requesting') : 0
    const xShippingPendingRequestAuto = orderSummary.has('xShipping_PendingRequestAuto')
      ? orderSummary.get('xShipping_PendingRequestAuto')
      : 0
    const xShippingPendingRequestManual = orderSummary.has('xShipping_PendingRequestManual')
      ? orderSummary.get('xShipping_PendingRequestManual')
      : 0
    const xShippingHasTrackingNo = orderSummary.has(CONS.XSHIPPING_STATUS_TASKS.XSHIPPING_HAS_TRACKING_NO)
      ? orderSummary.get(CONS.XSHIPPING_STATUS_TASKS.XSHIPPING_HAS_TRACKING_NO)
      : 0
    const xShippingRequestFailed = orderSummary.has('xShipping_RequestFailed') ? orderSummary.get('xShipping_RequestFailed') : 0
    const xShippingCancelFailed = orderSummary.has('xShipping_CancelFailed') ? orderSummary.get('xShipping_CancelFailed') : 0

    const myTasksShippableOrder = orderSummary.has('myTasks_ShippableOrder') ? orderSummary.get('myTasks_ShippableOrder') : 0
    const xShippingOrders =
      xShippingCancelling +
      xShippingRequesting +
      xShippingPendingRequestAuto +
      xShippingPendingRequestManual +
      xShippingHasTrackingNo +
      xShippingRequestFailed +
      xShippingCancelFailed
    // return (
    //   <VStack size={75} key={sectionIdx} style={{ paddingTop: 0, paddingBottom: 1 }}>
    //     <View>{this._renderXShippingBalance()}</View>
    //   </VStack>
    // )
    return (
      <VStack flex={1}>
        {/* {this._renderXShippingBalance()} */}
        <HStack w='full' py='2' justifyContent='center'>
          <XText fontSize='xs' variant='inactive'>
            <XText bold color={COLORS.BROWN}>{`${xShippingOrders} ออเดอร์`}</XText>
            <XText>{` (จาก ${myTasksShippableOrder}) `}</XText>
            <XText bold color={COLORS.BROWN}>
              ที่ขอเลขพัสดุผ่าน XSelly ได้
            </XText>
          </XText>
        </HStack>
      </VStack>
    )
  }

  const renderXShippingSectionMenu = () => (
    <HStack w='full' flexWrap='wrap'>
      {renderHasTrackingView()}
      {renderProceedTrackingView()}
      {renderErrorTrackingView()}
    </HStack>
  )

  const isVerifySlipTrialButtonVisible = () => {
    // console.log('Just in isVerifySlipTrialButtonVisible')
    if (isVerifySlipTrialSuccessPressed) {
      // console.log('isVerifySlipTrialSuccessPressed = true')
      return false
    }

    if (isTrialDeadlineExpired()) {
      // console.log('isTrialDeadlineExpired = true')
      return false
    }

    let foundVerifySlipCampaignOneAtIndex = verifySlipRemainingCreditItems.findIndex((vsrci) => vsrci.verify_slip_campaign_id === 1)
    if (foundVerifySlipCampaignOneAtIndex >= 0) {
      // console.log(`Remaining.foundVerifySlipCampaignOneAtIndex >= 0: ${foundVerifySlipCampaignOneAtIndex}`)
      return false
    }

    foundVerifySlipCampaignOneAtIndex = verifySlipUnusableCreditItems.findIndex((vsrci) => vsrci.verify_slip_campaign_id === 1)
    if (foundVerifySlipCampaignOneAtIndex >= 0) {
      // console.log(`Unusable.foundVerifySlipCampaignOneAtIndex >= 0: ${foundVerifySlipCampaignOneAtIndex}`)
      return false
    }
    // console.log('isVerifySlipTrialButtonVisible returns true')
    return true
  }

  const onPressVerifySlipTrialButton = async () => {
    try {
      const res = await fetchVerifySlipCreditTrial({ store_id: selectedStoreId })
      console.log('onPressVerifySlipTrialButton res => ', res)
      if (res.id) {
        setIsVerifySlipTrialSuccess(true)
        fetchTotalRemainingCredit()
        await p.op.alertPromise(
          'เติม 1,000 เครดิตเรียบร้อยแล้ว',
          '1,000 เครดิตพร้อมใช้งานแล้ว! คุณสามารถเริ่มใช้งานได้เลย หลังจาก "กดเปิดใช้งานฟีเจอร์และบัญชีรับชำระในหน้าตั้งค่า"'
        )
        navigation.dispatch(NavActions.navToStoreSettingVerifySlip({ store_id: selectedStoreId, isOnboarding: true }))
      }
    } catch (err) {
      console.log('onPressVerifySlipTrialButton err => ', JSON.stringify(err))
    }
  }

  const renderXShippingView = () => {
    const useXshipping = selectedStore.has('s_use_xshipping') ? selectedStore.get('s_use_xshipping') : false
    // console.log('useXshipping => ', useXshipping)
    if (!useXshipping) {
      return null
    }
    return (
      <VStack w='full' mt='1' px='2'>
        <XCard w='full'>
          <HStack w='full' justifyContent='space-between'>
            <Box py='1' pl='3' pr='8'>
              <Box
                position='absolute'
                top={0}
                left={0}
                minH='11'
                minW='166px'
                borderRightWidth='21px'
                borderTopWidth='28px'
                borderRightColor='transparent'
                borderTopColor='primary.500'
              />
              <XText
                // variant='activeDark'
                zIndex={999}
                color='white'
                bold>
                XShipping
              </XText>
            </Box>
            {/* {!_.isNil(section.renderPreMenus) ? section.renderPreMenus(sectionIdx) : null} */}
            {renderRightXShippingHeader()}
          </HStack>
          {/* {!_.isNil(section.renderPreMenus) ? section.renderPreMenus(sectionIdx) : null} */}
          <VStack w='full' pr='2'>
            {renderXShippingSectionMenu()}
            {_renderXShippingText()}
          </VStack>
          {/* {!_.isNil(section.renderPostMenus) ? section.renderPostMenus(sectionIdx) : null} */}
        </XCard>
      </VStack>
    )
  }

  const navToVerifySlipSetting = () => {
    const s_use_verify_slip = selectedStore.has('s_use_verify_slip') ? selectedStore.get('s_use_verify_slip') : false
    navigation.dispatch(NavActions.navToStoreSettingVerifySlip({ store_id: selectedStoreId, isOnboarding: !s_use_verify_slip }))
  }

  const renderVerifySlipBox = () => {
    const s_use_verify_slip = selectedStore.has('s_use_verify_slip') ? selectedStore.get('s_use_verify_slip') : false
    const s_verify_slip_show_menu = selectedStore.has('s_verify_slip_show_menu') ? selectedStore.get('s_verify_slip_show_menu') : false
    const s_verify_slip_promo_text = selectedStore.has('s_verify_slip_promo_text') ? selectedStore.get('s_verify_slip_promo_text') : null

    let isPromoTextShow = _.isString(s_verify_slip_promo_text)

    if (!isTrialDeadlineExpired() && !isVerifySlipTrialButtonVisible()) {
      isPromoTextShow = false
    }

    if (!s_verify_slip_show_menu && !s_use_verify_slip) {
      return null
    }

    return (
      <VStack w='full' mt='1' px='2'>
        <XCard w='full'>
          <HStack w='full' justifyContent='space-between'>
            <Box py='1' pl='3' pr='8'>
              <Box
                position='absolute'
                top={0}
                left={0}
                minH='11'
                minW='166px'
                borderRightWidth='21px'
                borderTopWidth='28px'
                borderRightColor='transparent'
                borderTopColor='primary.500'
              />
              <XText
                // variant='activeDark'
                zIndex={999}
                color='white'
                bold>
                ตรวจสลิปอัตโนมัติ
              </XText>
            </Box>
            <HStack position='absolute' top='0' right='0'>
              <HStack pt='1' pr='2' space='1.5'>
                <VerifySlipIcon success={s_use_verify_slip} inactive={!s_use_verify_slip} />
                <XText variant='inactive' color={s_use_verify_slip ? '#5CB85C' : undefined}>
                  {s_use_verify_slip ? 'เปิดใช้งานอยู่' : 'ปิดใช้งานอยู่'}
                </XText>
              </HStack>
            </HStack>
          </HStack>
          {/* {!_.isNil(section.renderPreMenus) ? section.renderPreMenus(sectionIdx) : null} */}

          <VStack w='full'>
            <VStack w='full' px='2' py='1'>
              <HStack w='full' justifyContent='flex-end' space='1'>
                <VStack flex={1} space='0.5'>
                  {isPromoTextShow ? (
                    <XText minH='32px' fontSize='md' color='#0046D9' bold>
                      {s_verify_slip_promo_text}
                    </XText>
                  ) : null}
                  {acl.isSelectedStoreOwner() && isPromoTextShow && isVerifySlipTrialButtonVisible() ? (
                    <HStack w='full' pt='0.5' pb='1.5' alignItems='center'>
                      <XButton w='full' h='34px' bg='#0046D9' borderColor='#0046D9' onPress={onPressVerifySlipTrialButton}>
                        เริ่มทดลองใช้ ฟรี!
                      </XButton>
                    </HStack>
                  ) : null}
                </VStack>

                {acl.isSelectedStoreOwner() && !s_use_verify_slip ? (
                  <TouchableOpacity onPress={navToVerifySlipSetting}>
                    <HStack
                      w='80px'
                      h='32px'
                      px='1.5'
                      py='0.5'
                      borderWidth='1'
                      borderRadius='lg'
                      borderColor='primary.500'
                      space='1.5'
                      alignItems='center'>
                      <XIcon name='gear' family='FontAwesome' />
                      <XText flex={1} textAlign='center' textAlignVertical='center' variant='primary'>
                        ตั้งค่า
                      </XText>
                    </HStack>
                  </TouchableOpacity>
                ) : null}
              </HStack>

              {!s_use_verify_slip ? (
                <VStack w='full' pt='1'>
                  <XText variant='inactive'>
                    หมดปัญหาสลิปปลอม สลิปซ้ำ ยอดสลิปไม่ตรง โอนผิดบัญชี ให้ระบบยืนยันสลิปที่ถูกต้องให้อัตโนมัติ
                  </XText>
                  {isVerifySlipTrialButtonVisible() ? (
                    <XText variant='inactive'>
                      กดปุ่ม{' '}
                      <XText color='#0046D9' bold>
                        "เริ่มทดลองใช้ ฟรี!"
                      </XText>{' '}
                      เพื่อ <XText bold>รับสิทธิ์และตั้งค่าเปิดการใช้งาน</XText>
                      {acl.isSelectedStoreOwner() ? null : <XText variant='inactive'> (เฉพาะบัญชีเจ้าของร้านที่จะเห็นปุ่มและกดได้)</XText>}
                    </XText>
                  ) : (
                    <XText variant='inactive'>
                      กดปุ่ม{' '}
                      <XText variant='primary' bold>
                        "ตั้งค่า"
                      </XText>{' '}
                      เพื่อ <XText bold>เปิดการใช้งาน</XText> หรือซ่อนเมนูนี้
                      {acl.isSelectedStoreOwner() ? null : <XText variant='inactive'> (เฉพาะบัญชีเจ้าของร้านที่จะเห็นปุ่มและกดได้)</XText>}
                    </XText>
                  )}
                </VStack>
              ) : null}
            </VStack>

            <HStack w='full' px='2' pb='2' space='2'>
              <XButton
                variant='outline'
                borderColor='gray.500'
                w='32%'
                h='54px'
                p='0.5'
                onPress={() => navigation.dispatch(NavActions.navToVerifySlipCreditSummaryView)}>
                <VStack alignItems='center' justifyContent='center'>
                  <XText variant='inactive' numberOfLines={1} fontSize='md'>
                    {_.isString(verifySlipTotalRemainingCredit)
                      ? verifySlipTotalRemainingCredit
                      : xFmt.formatDecimal(verifySlipTotalRemainingCredit)}
                  </XText>
                  <XText w='full' variant='inactive' numberOfLines={1} adjustsFontSizeToFit textAlign='center' textVerticalAlign='center'>
                    เครดิต
                  </XText>
                </VStack>
              </XButton>

              <XButton
                variant='outline'
                borderColor='gray.500'
                w='32%'
                h='56px'
                p='0.5'
                onPress={() => navigation.dispatch(NavActions.navToVerifySlipCreditHistoryView({ store_id: selectedStoreId }))}>
                <VStack alignItems='center' justifyContent='center'>
                  <XIcon inactive name='back-in-time' family='Entypo' />
                  <XText w='full' variant='inactive' numberOfLines={1} adjustsFontSizeToFit textAlign='center' textVerticalAlign='center'>
                    ประวัติเครดิต
                  </XText>
                </VStack>
              </XButton>

              <Box w='32%' />
            </HStack>
          </VStack>
        </XCard>
      </VStack>
    )
  }

  const _renderContent = () => {
    if (!orderSummary || orderSummary.size === 0) {
      return null
    }

    if (isShowThisView) {
      return (
        <>
          {renderXShippingView()}

          {acl.isSelectedStoreOwner() ||
          acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.PAYMENT_LIST) ||
          acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.PAYMENT_EDIT) ||
          acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.PAYMENT_ADD) ||
          acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.PAYMENT_DELETE)
            ? renderVerifySlipBox()
            : null}

          {/* <Text>{'Yo'}</Text> */}
          {/* <TestButton title='this is test button' onPress={() => alert('yo')} /> */}
          <View
            style={{
              paddingTop: 8,
              paddingLeft: 8,
              paddingBottom: 8,
              paddingRight: 8,
            }}>
            <OrdersSummaryDonut
              // onPress={this._onOrdersSummaryDonutPress}
              orderSummary={orderSummary}
              orderCount={selectedStore.get('order_count')}
            />
          </View>

          <DonutMenuContainer>
            {donutData.map((dd, index) => {
              const titleByKey = p.op.t(`OrdersSummaryDonutMenu.${dd.x}`)
              const orderCount = dd.y ? dd.y : null

              if (!dd.y || dd.isHeaderItem) {
                return null
              }

              return (
                <DonutMenuItem
                  key={`DonutMenuItem_${index.toString()}`}
                  title={titleByKey}
                  count={orderCount}
                  color={dd.color}
                  onPress={getFunctionNavToStoreOrderSingleTabListView(dd.x)}
                />
              )
            })}
            {/* <DonutMenuItem title={'bbbbbb'} count={99} />
             <DonutMenuItem title={'ccccc'} />
             <DonutMenuItem title={'ddddd'} /> */}
          </DonutMenuContainer>
          {/* <Button title='yo' onPress={navToTest} /> */}
        </>
      )
    }
    return (
      <HStack alignItems='center' justifyContent='center' mt='10'>
        <XText>คุณไม่สามารถใช้งานฟีเจอร์นี้ได้</XText>
      </HStack>
    )
  }

  // const _renderHeaderSummaryReport = () => {
  //   // const { isVisibleHeaderSummaryReport = true } = this.state
  //   // const { selectedStore } = this.props

  //   if (!selectedStore || !selectedStore.get('id')) {
  //     return null
  //   }

  //   if (!canSeeReport()) {
  //     return null
  //   }

  //   if (!canDoAtSelectedStore(CONS.PERM_STORE_HELPER.REPORT_ORDER, CONS.REPORT_SET_BIT_BINARY.report_daily_sales)) {
  //     return null
  //   }

  //   const _getDailySaleMoney = (text: string | number): number => {
  //     const newNumber: number = _.isString(text) ? parseFloat(text.replace(/,/g, '')) : text
  //     return Math.floor(newNumber)
  //   }
  //   const dailySale = _getDailySaleMoney(selectedStore.getIn(['dailysales', 'p']) || 0)
  //   const dailyProfit = _getDailySaleMoney(selectedStore.getIn(['dailysales', 'f']) || 0)
  //   const dailyOrderCount = selectedStore.getIn(['dailysales', 'o']) || 0
  //   // const lastUpdated = selectedStore.getIn(['dailysales', 'd'])

  //   return isVisibleHeaderSummaryReport ? (
  //     <TouchableWithoutFeedback
  //       onPress={() => navigation.dispatch(NavActions.navToDailySalesReportView({ store_id: selectedStore.get('id') }))}>
  //       <VStack
  //         w='full'
  //         borderBottomColor='muted.200'
  //         borderBottomWidth='1'
  //         _light={{
  //           bg: NB.C.L.BG.CLEAR,
  //         }}
  //         _dark={{
  //           bg: NB.C.D.BG.CLEAR,
  //         }}>
  //         <HStack w='full' p='0.5'>
  //           <VStack flex={1} p='0.5' alignItems='center'>
  //             <XText bold fontSize='md' style={LS.LABEL_DAILY_SALE_AMOUNT}>
  //               {`${dailySale}`}
  //             </XText>
  //             <XText variant='inactive' fontSize='xs'>
  //               ยอดขายวันนี้
  //             </XText>
  //           </VStack>
  //           <Box w='1px' h='full' borderRightColor='muted.200' borderRightWidth='1' />
  //           <VStack flex={1} p='0.5' alignItems='center'>
  //             <XText bold fontSize='md' style={LS.LABEL_DAILY_PROFIT_AMOUNT}>
  //               {`${dailyProfit}`}
  //             </XText>
  //             <XText variant='inactive' fontSize='xs'>
  //               กำไรวันนี้
  //             </XText>
  //           </VStack>
  //           <Box w='1px' h='full' borderRightColor='muted.200' borderRightWidth='1' />
  //           <VStack flex={1} p='0.5' alignItems='center'>
  //             <XText bold fontSize='md' style={LS.LABEL_DAILY_SALE_ORDER_COUNT}>
  //               {dailyOrderCount}
  //             </XText>
  //             <XText variant='inactive' fontSize='xs'>
  //               ออเดอร์ขายวันนี้
  //             </XText>
  //           </VStack>
  //         </HStack>
  //         <TouchableOpacity onPress={() => setisVisibleHeaderSummaryReportState(false)} style={LS.BTN_TOGGLE_SUMMARY}>
  //           <XIcon inactive size='xs' name='ios-close-circle-outline' />
  //         </TouchableOpacity>
  //       </VStack>
  //     </TouchableWithoutFeedback>
  //   ) : (
  //     <HStack w='full' justifyContent='flex-end'>
  //       <TouchableOpacity
  //         onPress={() => setisVisibleHeaderSummaryReportState(true)}
  //         style={{
  //           width: 28,
  //           height: 28,
  //           // flex: 1,
  //           // position: 'absolute',
  //           alignItems: 'flex-end',
  //           top: 0,
  //           right: 0,
  //           padding: 2,
  //           zIndex: 100,
  //         }}>
  //         <XIcon inactive size='xs' name='ios-add-circle-outline' />
  //       </TouchableOpacity>
  //     </HStack>
  //   )
  // }

  const _navToDailySaleChartView = () => {
    navigation.dispatch(NavActions.navToDailySaleChartView({ store_id: selectedStoreId }))
  }

  const _renderHeaderSummaryReport = () => {
    // const { isVisibleHeaderSummaryReport = true } = this.state
    // const { selectedStore } = this.props

    if (!selectedStore || !selectedStore.get('id')) {
      return null
    }

    if (!canSeeReport()) {
      return null
    }

    if (!canDoAtSelectedStore(CONS.PERM_STORE_HELPER.DASHBOARD, CONS.DASHBOARD_BIT_BINARY.bin_dashboard)) {
      return null
    }

    return <DailySaleBox storeId={selectedStoreId} onPress={_navToDailySaleChartView} />
  }

  return (
    <XContainer
    // style={{ width: contentOneOfThreeColumnWidth }}
    >
      <XCustomHeader
        title='ออเดอร์รอฉันดำเนินการ'

        // headerRightProps={{ addIcon: true, onPressItem: handlePressAddOrder }}
      />

      <XContent
      // style={{
      //   width: contentOneOfThreeColumnWidth,
      //   // backgroundColor: COLORS.BG_LIGHT_GREY
      // }}
      >
        <VStack pt='1' px='2' space='1'>
          {_renderHeaderSummaryReport()}
          <StoreOrdersCreatedAtDatesSelector />
        </VStack>
        {isFetchingState ? <Spinner color={COLORS.APP_MAIN} /> : _renderContent()}
        {/* <XButton onPress={_navToProductListMkpView}>Go To ProductListMkpView</XButton> */}
      </XContent>
    </XContainer>
  )
}

// @ts-ignore disable react-navigation header
StoreOrdersDonutListView.navigationOptions = {
  header: null,
}

export default StoreOrdersDonutListView
