import React from 'react'
import { Text, View, TouchableOpacity, TouchableOpacityProps } from 'react-native'

import { STYLES, COLORS } from 'x/config/styles'
import p from 'x/config/platform-specific'
import { ImmutableObject, IMKPChannelDetail } from 'x/index'

import XIcon from '../../components/XIcon'
import MkpLogo from '../../components/MkpLogo'
import ForwardIcon from '../../components/ForwardIcon'

// https://stackoverflow.com/questions/41285211/overriding-interface-property-type-defined-in-typescript-d-ts-file
type Modify<T, R> = Pick<T, Exclude<keyof T, keyof R>> & R

type IChannelItemBarButtonProps = Modify<
  TouchableOpacityProps,
  {
    mkpChannel: ImmutableObject<IMKPChannelDetail>
    onPress: (mkpChannelId: number) => void | Promise<void>
    disabledOnStatusDeactivated?: boolean
  }
>

// interface IChannelItemBarButtonProps extends TouchableOpacityProps {
//   mkpChannel: ImmutableObject<IMKPChannelDetail>
//   onPress: (mkpChannelId: number) => void | Promise<void>
// }

const ChannelItemBarButton = (props: IChannelItemBarButtonProps) => {
  const { disabled = false, mkpChannel, onPress, disabledOnStatusDeactivated } = props
  const mkpChId = mkpChannel.get('id')
  const mkpChName = mkpChannel.get('name')
  const mkpChMkpId = mkpChannel.get('mkp_id')
  // const status = mkpChannel.get('status')

  const onPressItem = () => {
    onPress(mkpChId)
  }

  let isButtonDisabled = disabled

  const isNotActivated = mkpChannel.get('is_active') === false
  if (disabledOnStatusDeactivated && isNotActivated) {
    isButtonDisabled = true
  }

  return (
    <TouchableOpacity
      style={{
        flex: 1,
        height: 50,
        borderWidth: 1,
        borderColor: COLORS.TEXT_INACTIVE,
        flexDirection: 'row',
        backgroundColor: isButtonDisabled ? COLORS.BG_LIGHT_GREY : COLORS.WHITE,
      }}
      {...props}
      disabled={isButtonDisabled}
      onPress={onPressItem}>
      <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
        <View style={{ width: 60, height: 60, justifyContent: 'center', alignItems: 'center' }}>
          <MkpLogo mkpId={mkpChMkpId} width={34} height={34} />
        </View>
        <View style={{ flex: 1, flexDirection: 'column' }}>
          <Text
            style={{
              fontSize: STYLES.FONT_SIZE_NORMAL,
              color: isButtonDisabled ? COLORS.TEXT_INACTIVE : COLORS.TEXT_ACTIVE,
              fontWeight: 'bold',
            }}
            numberOfLines={1}>
            {mkpChName}
          </Text>
          {isNotActivated ? (
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Text style={{ fontSize: STYLES.FONT_SIZE_SMALLER, color: COLORS.TEXT_INACTIVE }} numberOfLines={1}>
                (ยังไม่เปิดใช้งาน)
              </Text>

              <TouchableOpacity
                onPress={() =>
                  p.op.alert(
                    'ช่องทางเชื่อมต่อไม่พร้อมใช้งาน',
                    'สามารถทำการเปิดใช้งานโดยไปที่ ร้านฉัน > ช่องทางขาย แล้วเลือก "ดำเนินการเชื่อมต่อ"'
                  )
                }>
                <XIcon name='help-circle-outline' inactive style={{ flex: 0, minWidth: 20, fontSize: STYLES.FONT_ICON_SMALLER }} />
              </TouchableOpacity>
            </View>
          ) : null}
          {/* <Text style={{ fontSize: STYLES.FONT_SIZE_SMALLER, color: COLORS.TEXT_INACTIVE }} numberOfLines={1}>
            {`mkp_channel_id: ${mkpChMkpId}`}
          </Text> */}
        </View>
      </View>
      <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: 50 }}>
        {isButtonDisabled ? null : <ForwardIcon style={{ flex: 0 }} />}
      </View>
    </TouchableOpacity>
  )
}

export default ChannelItemBarButton
