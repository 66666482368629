import React from 'react'
import { View, Image } from 'react-native'
import _ from 'lodash'
import Icon from 'xui/components/Icon'
// import p from 'x/config/platform-specific'
import { STYLES, COLORS } from 'x/config/styles'
import { ISectionOptionMetadata, IOptionMetadata, IOrdersFilterSetting, IDateRange, IBaseBasicOrdersFilter } from 'x/index'

import dayjs from 'dayjs'
import * as util from 'x/utils/util'
import p from 'x/config/platform-specific'
import VStack from 'xui/components/VStack'
import Box from 'xui/components/Box'
import HStack from 'xui/components/HStack'
import XCheckButton from './XCheckButton'
import XDateRangePicker from './XDateRangePicker'
import Segment from './Segment'
import XIcon from './XIcon'
import XText from './XText'
// import XDateTimeSinglePicker from 'xui/components/XDateTimeSinglePicker'

const imgIconCod = require('../images/cod.png')

// const OPTS_METADATA = [
//   {
//     // key: 'isCOD',
//     // optionType: 'only-one-check-button',
//     title: 'การเก็บเงินปลายทาง [COD]',
//     icon: {
//       src: imgIconCod,
//     },
//     option: [
//       {
//         key: 'isCOD',
//         label: 'เก็บ',
//         value: true,
//       },
//       {
//         key: 'isCOD',
//         label: 'ไม่เก็บ',
//         value: false,
//       },
//     ],
//   },
//   {
//     // key: 'isShipBeforePay',
//     // optionType: 'only-one-check-button',
//     title: 'การจัดส่ง',
//     icon: {
//       name: 'truck-delivery',
//       type: 'MaterialCommunityIcons',
//       iconStyle: { color: COLORS.SUMMARY_ORDER_DONUT_COLOR_SCALE.myTasks_payOther },
//     },
//     option: [
//       {
//         key: 'isShipBeforePay',
//         label: 'จัดส่งก่อนชำระ',
//         value: true,
//       },
//       {
//         key: 'isShipBeforePay',
//         label: 'จัดส่งหลังชำระ',
//         value: false,
//       },
//     ],
//   },
// ]

const BACKGROUND_COLOR = '#fff9ee'

const renderMetadataOptionUnderline = () => (
  <View style={{ marginTop: 8, height: 1, width: '100%', borderTopWidth: 0.5, borderTopColor: COLORS.TEXT_INACTIVE }} />
)

// interface ISeletedSettingBaseBasicOrderFilterView {
//   isCOD: boolean // isCOD = true :: เก็บเงินปลายทาง || false ไม่เก็บเงินปลายทาง || null ไม่เลือกเงื่อนไขนี้เลย
//   isShipBeforePay: boolean // isShipBeforePay = true :: จัดส่งก่อรชำระ || false จัดส่งหลังชำระ || null ไม่เลือกเงื่อนไขนี้เลย
//   hasPrintedAt: boolean // hasPrintedAt = true :: พิมพ์ไปแล้ว || false ยังไม่ได้พิมพ์ || null ไม่เลือกเงื่อนไขนี้เลย
//   segmentHasPrintedAtIndex: number
//   printedAtFrom?: Moment
//   printedAtTo?: Moment

//   segmentCreateAtIndex: number
//   createOrderFrom?: Moment
//   createOrderTo?: Moment
// }

// const newDate = moment().format(CONS.SERVER_DATE_FORMAT)

const initSetting: IBaseBasicOrdersFilter = {
  isCOD: null,
  isShipBeforePay: null,
  hasPrintedAt: null,
  segmentHasPrintedAtIndex: 0,
  printedAtFrom: null,
  printedAtTo: null,

  segmentCreateAtIndex: 0,
  createOrderFrom: null,
  createOrderTo: null,

  segmentDateDeliveryIndex: 0,
  hasDateDelivery: null,
  dateDeliveryFrom: null,
  dateDeliveryTo: null,

  segmentAutoCancelOrderIndex: 0,
  hasExpirationDate: null,
  AutoCancelOrderFrom: null,
  AutoCancelOrderTo: null,

  segmentReadyToShipIndex: 0,
  hasReadyToShipDate: null,
  segmentReadyToShipForDateTimeIndex: 0,
  readyToShipDateFrom: null,
  readyToShipDateTo: null,
}

interface IBaseBasicOrderFilterViewProps {
  defaultSetting?: Partial<IOrdersFilterSetting>
  onChange: (setting: Partial<IBaseBasicOrdersFilter>) => void
  // setting?: Partial<IBaseBasicOrdersFilter> // เว็บต้องใช้
  // onReset?: boolean
}

interface IBaseBasicOrderFilterViewState {
  selectedSetting: Partial<IBaseBasicOrdersFilter>
}

export default class BaseBasicOrderFilterView extends React.Component<IBaseBasicOrderFilterViewProps, IBaseBasicOrderFilterViewState> {
  dateTimePickerAutoCancelOrderRef: React.RefObject<any>

  dateTimePickerDateDeliveryRef: React.RefObject<any>

  constructor(props) {
    super(props)
    this.state = {
      selectedSetting: this.getDefaultSetting(props),
    }
    this.dateTimePickerAutoCancelOrderRef = React.createRef()
    this.dateTimePickerDateDeliveryRef = React.createRef()
  }

  componentDidMount() {
    // const { setting } = this.props
    // console.log('BaseBasic componentDidMount this.state.selectedSetting => ', this.state.selectedSetting)

    // if (p.op.isWeb() && !_.isNil(setting)) {
    //   // this.createResponesCallback(setting)
    //   util.setStatePromise(this, {
    //     selectedSetting: setting,
    //   })
    // }
    const defaultSetting = _.isNil(this.props.defaultSetting) ? this.state.selectedSetting : this.props.defaultSetting
    util.setStatePromise(this, {
      selectedSetting: defaultSetting,
    })
  }

  getDefaultSetting = (forceProps?: IBaseBasicOrderFilterViewProps): Partial<IBaseBasicOrdersFilter> => {
    const { defaultSetting = {} } = this.props
    if (forceProps && forceProps.defaultSetting) {
      return { ...initSetting, ...forceProps.defaultSetting }
    }
    return { ...initSetting, ...defaultSetting }
  }

  resetToDefaultSetting = async () => {
    await util.setStatePromise(this, { selectedSetting: this.getDefaultSetting() })
    this.props.onChange(this.getDefaultSetting())
  }

  renderOptionByMetadata = (metadata: ISectionOptionMetadata, index: number) => {
    const { title, icon, option } = metadata
    // console.log('metadata => ', metadata)
    // console.log('index => ', index)
    const baseIconStyle = {
      fontSize: STYLES.FONT_ICON_SMALLER,
      width: STYLES.FONT_ICON_SMALLER,
      height: STYLES.FONT_ICON_SMALLER,
      marginRight: 4,
      textAlign: 'center',
    }
    const renderTitleIcon = (icon) => {
      const { src, name, type, iconStyle = {} } = icon
      if (src) {
        return <Image source={src} resizeMode='contain' style={[baseIconStyle, iconStyle]} />
      }
      if (name) {
        return <Icon name={name} type={type} style={[baseIconStyle, iconStyle]} />
      }
      return null
    }

    const onPressCheckButton = (args): void => {
      const { name, data } = args
      const { selectedSetting } = this.state
      // console.log('name => ', name)
      // console.log('data => ', data)
      const newSelectedSetting = _.cloneDeep(selectedSetting)
      if (newSelectedSetting[name] === data) {
        newSelectedSetting[name] = null
      } else {
        newSelectedSetting[name] = data
      }
      // console.log('newSelectedSetting => ', newSelectedSetting)
      // setSelectedSetting(newSelectedSetting)
      util.setStatePromise(this, { selectedSetting: newSelectedSetting })
      this.props.onChange(newSelectedSetting)
      // createResponesCallback()
    }

    // eslint-disable-next-line react/no-multi-comp
    const renderCheckButton = (opt: IOptionMetadata, idx: number) => {
      const { key, value, label } = opt
      const { selectedSetting } = this.state
      return (
        <XCheckButton
          key={`${key}_${idx.toString()}`}
          // disabled={this.props.readonly}
          // buttonStyle={{ marginTop: 2, marginBottom: 2, marginRight: 8 }}
          buttonStyle={{ marginTop: 2 }}
          checked={selectedSetting[key] === value}
          name={key}
          label={label}
          data={value}
          onPress={onPressCheckButton}
        />
      )
    }

    const renderMetaDataContent = () => (
      <VStack w='full' space='1'>
        <HStack alignItems='center' space='1'>
          {renderTitleIcon(icon)}
          <XText variant='inactive'>{title}</XText>
        </HStack>
        <HStack w='full' flexWrap='wrap' space='2'>
          {option.map(renderCheckButton)}
        </HStack>
      </VStack>
    )

    if (index > 800) {
      return renderMetaDataContent()
    }

    return (
      <VStack w='full' key={`opt_${index}`}>
        {renderMetaDataContent()}
        {renderMetadataOptionUnderline()}
      </VStack>
    )
  }

  renderFilterHasPrintedAtRange = () => {
    // const setting = getDefaultSetting()
    const { selectedSetting } = this.state
    const hasPrintedAt = selectedSetting.hasPrintedAt || null
    const segmentHasPrintedAtIndex = selectedSetting.segmentHasPrintedAtIndex || 0
    const printedAtFrom = selectedSetting.printedAtFrom || dayjs().startOf('day')
    const printedAtTo = selectedSetting.printedAtTo || dayjs().endOf('day')
    return (
      <VStack w='full' py='2'>
        {this.renderOptionByMetadata(
          {
            title: 'ใบปะหน้าพัสดุ',
            icon: {
              name: 'printer',
              type: 'MaterialCommunityIcons',
              iconStyle: { color: COLORS.TEXT_ACTIVE, marginTop: -2 },
            },
            option: [
              {
                key: 'hasPrintedAt',
                label: 'พิมพ์ไปแล้ว',
                value: true,
              },
              {
                key: 'hasPrintedAt',
                label: 'ยังไม่ได้พิมพ์',
                value: false,
              },
            ],
          },
          // just mock customize index number
          801
        )}
        {/* <View style={{ width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
          <XIcon inactive={true} name={'printer'} family={'MaterialCommunityIcons'} style={s.ICON_TITLE} />
          <XText style={s.TXT_SECTION_TITLE}>{'สถานะใบปะหน้าพัสดุ'}</XText>
        </View> */}

        {/* Separator */}
        {/* <View style={{ height: 8, width: '100%' }} /> */}

        {/* <View style={{ minHeight: 41, width: '100%' }}> */}
        {hasPrintedAt ? (
          <VStack w='full' space='1'>
            <XText variant='inactive'>ภายใน</XText>
            <Segment
              selectedIndex={segmentHasPrintedAtIndex}
              options={['ทั้งหมด', '5 นาที', '12 ชั่วโมง', 'ระบุเวลา']}
              onSegmentChange={this.setSegmentHasPrintedAtIndex}
            />
          </VStack>
        ) : null}
        {/* </View> */}
        {/* Separator */}
        <Box h='1' w='full' />

        {/* ถ้าเลือกระบุเวลา */}
        {/* <View style={{ minHeight: 60, width: '100%' }}> */}
        {hasPrintedAt && segmentHasPrintedAtIndex === 3 ? (
          <XDateRangePicker
            // allowOnlyDaysFromToday={31}
            selectedDates={{ begin: printedAtFrom, end: printedAtTo }}
            onChangeDate={this.setPrintedAtFromTo}
            showTime
            // oneLineDateTime={!p.op.isWeb()}
          />
        ) : null}
        {/* </View> */}

        {/* {renderMetadataOptionUnderline()} */}
      </VStack>
    )
  }

  setPrintedAtFromTo = async (newDateRange: IDateRange) => {
    const { selectedSetting } = this.state
    const newSetting = _.cloneDeep(selectedSetting)
    newSetting.printedAtFrom = newDateRange.begin
    newSetting.printedAtTo = util.changeSecondTime(newDateRange.end, 59)
    // setSelectedSetting(newSetting)
    // createResponesCallback()
    // console.log('setPrintedAtFromTo => ', newSetting)
    await util.setStatePromise(this, { selectedSetting: newSetting })
    this.props.onChange(newSetting)
    // this.createResponesCallback(newSetting)
  }

  setSegmentHasPrintedAtIndex = async (newIndex: number) => {
    const { selectedSetting } = this.state
    const newSetting = _.cloneDeep(selectedSetting)
    newSetting.segmentHasPrintedAtIndex = newIndex

    // set default ของช่วงเวลาพิมพ์ใบปะหน้า
    switch (newIndex) {
      case 0: // ทั้งหมด
        newSetting.printedAtFrom = null
        newSetting.printedAtTo = null
        break
      case 1:
        newSetting.printedAtFrom = dayjs().subtract(5, 'minutes')
        newSetting.printedAtTo = dayjs()
        break
      case 2:
        newSetting.printedAtFrom = dayjs().subtract(12, 'hours')
        newSetting.printedAtTo = dayjs()
        break
      case 3: // ระบุเอง
        newSetting.printedAtFrom = dayjs().startOf('day')
        newSetting.printedAtTo = dayjs().endOf('day')
        break
    }
    // console.log('setSegmentHasPrintedAtIndex => ', newSetting)
    // setSelectedSetting(newSetting)
    // createResponesCallback()
    await util.setStatePromise(this, { selectedSetting: newSetting })
    this.props.onChange(newSetting)
    // this.createResponesCallback(newSetting)
  }

  renderFilterOrderCreate = () => {
    const { selectedSetting } = this.state
    const segmentCreateAtIndex = selectedSetting.segmentCreateAtIndex || 0
    const createOrderFrom = selectedSetting.createOrderFrom || dayjs().startOf('day')
    const createOrderTo = selectedSetting.createOrderTo || dayjs().endOf('day')
    return (
      <VStack w='full' py='2' space='1' style={{ backgroundColor: BACKGROUND_COLOR }}>
        <HStack alignItems='center' space='1'>
          <XIcon variant='active' name='file-document-outline' family='MaterialCommunityIcons' />
          <XText variant='inactive'>สร้างภายใน</XText>
        </HStack>
        <Segment
          selectedIndex={segmentCreateAtIndex}
          options={['ทุกวัน', 'วันนี้', '7 วัน', 'ระบุเวลา']}
          onSegmentChange={this.setSegmentCreateAtIndex}
        />
        <Box w='full' h='2' />
        {segmentCreateAtIndex === 3 ? (
          <VStack w='full'>
            <XDateRangePicker
              // allowOnlyDaysFromToday={31}
              selectedDates={{ begin: createOrderFrom, end: createOrderTo }}
              onChangeDate={this.setCreateOrderAtFromTo}
              showTime
              // oneLineDateTime={!p.op.isWeb()}
            />
          </VStack>
        ) : null}
        {/* {renderMetadataOptionUnderline()} */}
      </VStack>
    )
  }

  setCreateOrderAtFromTo = async (newDateRange: IDateRange) => {
    const { selectedSetting } = this.state
    const newSetting = _.cloneDeep(selectedSetting)
    newSetting.createOrderFrom = newDateRange.begin
    newSetting.createOrderTo = util.changeSecondTime(newDateRange.end, 59)
    // newSetting.createOrderTo = newDateRange.end
    // setSelectedSetting(newSetting)
    // createResponesCallback()
    await util.setStatePromise(this, { selectedSetting: newSetting })
    // this.createResponesCallback(newSetting)
    this.props.onChange(newSetting)
  }

  setSegmentCreateAtIndex = async (newIndex: number) => {
    const { selectedSetting } = this.state
    const newSetting = _.cloneDeep(selectedSetting)
    newSetting.segmentCreateAtIndex = newIndex
    // set default ของช่วงเวลาพิมพ์ใบปะหน้า
    switch (newIndex) {
      case 0: // ทั้งหมด
        newSetting.createOrderFrom = null
        newSetting.createOrderTo = null
        break
      case 1:
        newSetting.createOrderFrom = dayjs().startOf('day')
        newSetting.createOrderTo = dayjs()
        break
      case 2:
        newSetting.createOrderFrom = dayjs().subtract(6, 'day')
        newSetting.createOrderTo = dayjs()
        break
      case 3: // ระบุเอง
        newSetting.createOrderFrom = dayjs().startOf('day')
        newSetting.createOrderTo = dayjs().endOf('day')
        break
    }
    // setSelectedSetting(newSetting)
    await util.setStatePromise(this, { selectedSetting: newSetting })
    this.props.onChange(newSetting)
    // this.createResponesCallback(newSetting)
  }

  // createResponesCallback = (selectedSetting: IBaseBasicOrdersFilter) => {
  //   const response: IBaseBasicOrdersFilter = {
  //     isCOD: selectedSetting.isCOD,
  //     isShipBeforePay: selectedSetting.isShipBeforePay,
  //     hasPrintedAt: selectedSetting.hasPrintedAt,
  //     segmentHasPrintedAtIndex: selectedSetting.segmentHasPrintedAtIndex,
  //     printedAtFrom: selectedSetting.hasPrintedAt === null || selectedSetting.hasPrintedAt === false ? null : selectedSetting.printedAtFrom,
  //     printedAtTo: selectedSetting.hasPrintedAt === null || selectedSetting.hasPrintedAt === false ? null : selectedSetting.printedAtTo,
  //     segmentCreateAtIndex: selectedSetting.segmentCreateAtIndex,
  //     createOrderTo: selectedSetting.createOrderTo,
  //     createOrderFrom: selectedSetting.createOrderFrom,
  //   }
  //   this.props.onChange(response)
  // }

  _renderDateDeliver = () => {
    const { segmentDateDeliveryIndex, dateDeliveryFrom, dateDeliveryTo, hasDateDelivery } = this.state.selectedSetting
    const dateDeliveryFromToRender = _.isNil(dateDeliveryFrom) ? dayjs() : dateDeliveryFrom
    const dateDeliveryToToRender = _.isNil(dateDeliveryTo) ? dayjs() : dateDeliveryTo

    const _setPrintedAtFromTo = async (newDateRange: IDateRange) => {
      const { selectedSetting } = this.state
      const newSetting = _.cloneDeep(selectedSetting)
      newSetting.dateDeliveryFrom = newDateRange.begin
      newSetting.dateDeliveryTo = util.changeSecondTime(newDateRange.end, 59)
      // setSelectedSetting(newSetting)
      // createResponesCallback()
      // console.log('setPrintedAtFromTo => ', newSetting)
      await util.setStatePromise(this, { selectedSetting: newSetting })
      this.props.onChange(newSetting)
      // this.createResponesCallback(newSetting)
    }

    const _setSegmentDateDeliverIndex = (newIndex: number) => {
      const { selectedSetting } = this.state
      const newSetting = _.cloneDeep(selectedSetting)
      newSetting.segmentDateDeliveryIndex = newIndex

      // set default ของช่วงเวลาพิมพ์ใบปะหน้า
      switch (newIndex) {
        case 0: // ทั้งหมด
          newSetting.dateDeliveryFrom = null
          newSetting.dateDeliveryTo = null
          break
        case 1:
          newSetting.dateDeliveryFrom = dayjs().startOf('day')
          newSetting.dateDeliveryTo = dayjs().endOf('day')
          break
        case 2:
          newSetting.dateDeliveryFrom = dayjs(dayjs().add(1, 'day')).startOf('day')
          newSetting.dateDeliveryTo = dayjs(dayjs().add(1, 'day')).endOf('day')
          break
        case 3:
          newSetting.dateDeliveryFrom = dayjs().startOf('day')
          newSetting.dateDeliveryTo = dayjs().endOf('day')
          break
      }
      // console.log('_setSegmentDateDeliverIndex => ', dayjs(dayjs().add(1, 'day')).endOf('day').format('YYYY-MM-DD HH:mm:ss'))
      // setSelectedSetting(newSetting)
      // createResponesCallback()
      util.setStatePromise(this, { selectedSetting: newSetting })
      this.props.onChange(newSetting)
      // this.createResponesCallback(newSetting)
    }

    return (
      <VStack w='full' py='2'>
        {/* <View style={{ flexDirection: 'row', paddingTop: 2 }}>
          <XIcon name={'back-in-time'} family={'Entypo'} style={{ fontSize: STYLES.FONT_ICON_SMALLER, flex: 0, minWidth: 25, width: 25, color: COLORS.TEXT_INACTIVE }} />
          <XText style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE }}>{'วันกำหนดส่ง'}</XText>
        </View> */}
        {this.renderOptionByMetadata(
          {
            title: 'วันกำหนดส่ง',
            icon: {
              name: 'back-in-time',
              type: 'Entypo',
              iconStyle: { color: COLORS.TEXT_ACTIVE },
            },
            option: [
              {
                key: 'hasDateDelivery',
                label: 'มี',
                value: true,
              },
              {
                key: 'hasDateDelivery',
                label: 'ไม่มี',
                value: false,
              },
            ],
          },
          // just mock customize index number
          801
        )}
        {hasDateDelivery ? (
          <View>
            <XText style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE, paddingTop: 4 }}>ภายใน</XText>
            <View style={{ paddingTop: 4 }}>
              <Segment
                selectedIndex={_.isNil(segmentDateDeliveryIndex) ? 0 : segmentDateDeliveryIndex}
                options={['ทั้งหมด', 'วันนี้', 'พรุ่งนี้', 'ระบุเวลา']}
                onSegmentChange={_setSegmentDateDeliverIndex}
              />
            </View>
            {segmentDateDeliveryIndex === 3 ? (
              <View style={{ paddingTop: 6, paddingRight: 4 }}>
                <XDateRangePicker
                  // allowOnlyDaysFromToday={31}
                  selectedDates={{ begin: dateDeliveryFromToRender, end: dateDeliveryToToRender }}
                  onChangeDate={_setPrintedAtFromTo}
                  showTime
                  // oneLineDateTime={!p.op.isWeb()}
                  beginDateLength={{ min: dayjs().subtract(6, 'month'), max: dayjs().add(5, 'year') }}
                />
              </View>
            ) : null}
          </View>
        ) : null}
        {/* {renderMetadataOptionUnderline()} */}
      </VStack>
    )
  }

  _renderAutoCancelOrder = () => {
    const { segmentAutoCancelOrderIndex, AutoCancelOrderFrom, AutoCancelOrderTo, hasExpirationDate } = this.state.selectedSetting
    const AutoCancelOrderFromToRender = _.isNil(AutoCancelOrderFrom) ? dayjs() : AutoCancelOrderFrom
    const AutoCancelOrderToToRender = _.isNil(AutoCancelOrderTo) ? dayjs() : AutoCancelOrderTo

    const _setAutoCancelOrderAtFromTo = async (newDateRange: IDateRange) => {
      const { selectedSetting } = this.state
      const newSetting = _.cloneDeep(selectedSetting)
      newSetting.AutoCancelOrderFrom = newDateRange.begin
      newSetting.AutoCancelOrderTo = util.changeSecondTime(newDateRange.end, 59)
      // setSelectedSetting(newSetting)
      // createResponesCallback()
      // console.log('setPrintedAtFromTo => ', newSetting)
      await util.setStatePromise(this, { selectedSetting: newSetting })
      this.props.onChange(newSetting)
      // this.createResponesCallback(newSetting)
    }

    const _setSegmentAutoCancelOrderIndex = (newIndex: number) => {
      const { selectedSetting } = this.state
      const newSetting = _.cloneDeep(selectedSetting)
      newSetting.segmentAutoCancelOrderIndex = newIndex

      // set default ของช่วงเวลาพิมพ์ใบปะหน้า
      switch (newIndex) {
        case 0:
          newSetting.AutoCancelOrderFrom = null
          newSetting.AutoCancelOrderTo = null
          break
        case 1:
          newSetting.AutoCancelOrderFrom = dayjs().startOf('day')
          newSetting.AutoCancelOrderTo = dayjs().endOf('day')
          break
        case 2:
          newSetting.AutoCancelOrderFrom = dayjs(dayjs().add(1, 'day')).startOf('day')
          newSetting.AutoCancelOrderTo = dayjs(dayjs().add(1, 'day')).endOf('day')
          break
        case 2:
          newSetting.AutoCancelOrderFrom = dayjs().startOf('day')
          newSetting.AutoCancelOrderTo = dayjs().endOf('day')
          break
      }
      // console.log('_setSegmentDateDeliverIndex => ', dayjs(dayjs().add(1, 'day')).endOf('day').format('YYYY-MM-DD HH:mm:ss'))
      // setSelectedSetting(newSetting)
      // createResponesCallback()
      util.setStatePromise(this, { selectedSetting: newSetting })
      this.props.onChange(newSetting)
      // this.createResponesCallback(newSetting)
    }

    return (
      <VStack w='full' py='2' style={{ backgroundColor: BACKGROUND_COLOR }}>
        {/* <View style={{ flexDirection: 'row', paddingTop: 2 }}>
          <XIcon name={'cancel'} family={'MaterialCommunityIcons'} style={{ fontSize: STYLES.FONT_ICON_SMALLER, flex: 0, minWidth: 25, width: 25, color: COLORS.TEXT_INACTIVE }} />
          <XText style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE }}>{p.op.t('StoreSetting.MenuStoreCancelOrderAutomation')}</XText>
        </View> */}
        {this.renderOptionByMetadata(
          {
            title: p.op.t('StoreSetting.MenuStoreCancelOrderAutomation'),
            icon: {
              name: 'cancel',
              type: 'MaterialCommunityIcons',
              iconStyle: { color: COLORS.TEXT_ACTIVE },
            },
            option: [
              {
                key: 'hasExpirationDate',
                label: 'มี',
                value: true,
              },
              {
                key: 'hasExpirationDate',
                label: 'ไม่มี',
                value: false,
              },
            ],
          },
          // just mock customize index number
          801
        )}
        {hasExpirationDate ? (
          <View>
            <XText style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE, paddingTop: 4 }}>ภายใน</XText>
            <View style={{ paddingTop: 2 }}>
              <Segment
                selectedIndex={_.isNil(segmentAutoCancelOrderIndex) ? 0 : segmentAutoCancelOrderIndex}
                options={['ทั้งหมด', 'วันนี้', 'พรุ่งนี้', 'ระบุเวลา']}
                onSegmentChange={_setSegmentAutoCancelOrderIndex}
              />
            </View>
            {segmentAutoCancelOrderIndex === 3 ? (
              <View style={{ paddingTop: 6, paddingRight: 4 }}>
                <XDateRangePicker
                  // allowOnlyDaysFromToday={31}
                  selectedDates={{ begin: AutoCancelOrderFromToRender, end: AutoCancelOrderToToRender }}
                  onChangeDate={_setAutoCancelOrderAtFromTo}
                  showTime
                  // oneLineDateTime={!p.op.isWeb()}
                  beginDateLength={{ min: dayjs().subtract(6, 'month'), max: dayjs().add(5, 'year') }}
                />
              </View>
            ) : null}
          </View>
        ) : null}
        {/* {renderMetadataOptionUnderline()} */}
      </VStack>
    )
  }

  _renderReadyToShip = () => {
    const {
      segmentReadyToShipIndex,
      segmentReadyToShipForDateTimeIndex,
      readyToShipDateFrom,
      readyToShipDateTo,
      hasReadyToShipDate,
    } = this.state.selectedSetting
    const readyToShipDateFromToRender = _.isNil(readyToShipDateFrom) ? dayjs() : readyToShipDateFrom
    const readyToShipDateToRender = _.isNil(readyToShipDateTo) ? dayjs() : readyToShipDateTo

    const _setReadyToShipAtFromTo = async (newDateRange: IDateRange) => {
      const { selectedSetting } = this.state
      const newSetting = _.cloneDeep(selectedSetting)
      newSetting.readyToShipDateFrom = newDateRange.begin
      newSetting.readyToShipDateTo = util.changeSecondTime(newDateRange.end, 59)
      // setSelectedSetting(newSetting)
      // createResponesCallback()
      // console.log('setPrintedAtFromTo => ', newSetting)
      await util.setStatePromise(this, { selectedSetting: newSetting })
      this.props.onChange(newSetting)
      // this.createResponesCallback(newSetting)
    }

    // const _setSegmentReadyToShipIndex = (newIndex: number) => {
    //   const { selectedSetting } = this.state
    //   const newSetting = _.cloneDeep(selectedSetting)
    //   newSetting.segmentReadyToShipIndex = newIndex
    //   util.setStatePromise(this, { selectedSetting: newSetting })
    //   this.props.onChange(newSetting)
    // }

    const _setSegmentReadyToShipForDateTimeIndex = (newIndex: number) => {
      const { selectedSetting } = this.state
      const newSetting = _.cloneDeep(selectedSetting)
      newSetting.segmentReadyToShipForDateTimeIndex = newIndex

      switch (newIndex) {
        case 0:
          newSetting.readyToShipDateFrom = null
          newSetting.readyToShipDateTo = null
          break
        case 1:
          newSetting.readyToShipDateFrom = dayjs(dayjs().subtract(1, 'day')).startOf('day')
          newSetting.readyToShipDateTo = dayjs(dayjs().subtract(1, 'day')).endOf('day')
          break
        case 2:
          newSetting.readyToShipDateFrom = dayjs().startOf('day')
          newSetting.readyToShipDateTo = dayjs().endOf('day')
          break
        case 3: // ระบุเอง
          newSetting.readyToShipDateFrom = dayjs().startOf('day')
          newSetting.readyToShipDateTo = dayjs().endOf('day')
          break
      }
      util.setStatePromise(this, { selectedSetting: newSetting })
      this.props.onChange(newSetting)
    }

    return (
      <VStack w='full' py='2' style={{ backgroundColor: BACKGROUND_COLOR }}>
        {/* <View style={{ flexDirection: 'row', paddingTop: 2 }}>
          <XIcon name={'local-shipping'} family={'MaterialIcons'} style={{ fontSize: STYLES.FONT_ICON_SMALLER, flex: 0, minWidth: 25, width: 25, color: COLORS.TEXT_INACTIVE }} />
          <XText style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE }}>{'สถานะพร้อมส่ง'}</XText>
        </View> */}
        {this.renderOptionByMetadata(
          {
            title: 'สถานะพร้อมส่ง',
            icon: {
              name: 'local-shipping',
              type: 'MaterialIcons',
              iconStyle: { color: COLORS.TEXT_ACTIVE },
            },
            option: [
              {
                key: 'hasReadyToShipDate',
                label: 'พร้อมส่ง',
                value: true,
              },
              {
                key: 'hasReadyToShipDate',
                label: 'ไม่พร้อมส่ง',
                value: false,
              },
            ],
          },
          // just mock customize index number
          801
        )}
        {hasReadyToShipDate ? (
          <View>
            {segmentReadyToShipIndex === 0 || _.isNil(segmentReadyToShipIndex) ? (
              <XText style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE, paddingTop: 4 }}>ภายใน</XText>
            ) : null}
            {segmentReadyToShipIndex === 0 || _.isNil(segmentReadyToShipIndex) ? (
              <View>
                <Segment
                  selectedIndex={_.isNil(segmentReadyToShipForDateTimeIndex) ? 0 : segmentReadyToShipForDateTimeIndex}
                  options={['ทั้งหมด', 'เมื่อวาน', 'วันนี้', 'ระบุเวลา']}
                  onSegmentChange={_setSegmentReadyToShipForDateTimeIndex}
                />
              </View>
            ) : null}
            {segmentReadyToShipForDateTimeIndex === 3 ? (
              <View style={{ paddingTop: 6, paddingRight: 4 }}>
                <XDateRangePicker
                  // allowOnlyDaysFromToday={31}
                  selectedDates={{ begin: readyToShipDateFromToRender, end: readyToShipDateToRender }}
                  onChangeDate={_setReadyToShipAtFromTo}
                  showTime
                  // oneLineDateTime={!p.op.isWeb()}
                />
              </View>
            ) : null}
          </View>
        ) : null}
        {/* {renderMetadataOptionUnderline()} */}
      </VStack>
    )
  }

  _renderPaymentCOD = () => (
    <VStack w='full' py='2'>
      {this.renderOptionByMetadata(
        {
          title: 'การเก็บเงินปลายทาง [COD]',
          icon: {
            src: imgIconCod,
          },
          option: [
            {
              key: 'isCOD',
              label: 'เก็บ',
              value: true,
            },
            {
              key: 'isCOD',
              label: 'ไม่เก็บ',
              value: false,
            },
          ],
        },
        // just mock customize index number
        801
      )}
    </VStack>
  )

  _renderShipping = () => (
    <VStack w='full' py='2' style={{ backgroundColor: BACKGROUND_COLOR }}>
      {this.renderOptionByMetadata(
        {
          // key: 'isShipBeforePay',
          // optionType: 'only-one-check-button',
          title: 'การจัดส่ง',
          icon: {
            name: 'truck-delivery',
            type: 'MaterialCommunityIcons',
            iconStyle: { color: COLORS.SUMMARY_ORDER_DONUT_COLOR_SCALE.myTasks_payOther },
          },
          option: [
            {
              key: 'isShipBeforePay',
              label: 'จัดส่งก่อนชำระ',
              value: true,
            },
            {
              key: 'isShipBeforePay',
              label: 'จัดส่งหลังชำระ',
              value: false,
            },
          ],
        },
        // just mock customize index number
        801
      )}
    </VStack>
  )

  render() {
    return (
      <VStack w='full'>
        {/* mataData: any = ISectionOptionMetadata */}
        {this.renderFilterOrderCreate()}
        {this._renderPaymentCOD()}
        {this._renderShipping()}
        {/* {OPTS_METADATA.map((mataData: any, index: number) => {
          return this.renderOptionByMetadata(mataData, index)
        })} */}
        {this.renderFilterHasPrintedAtRange()}
        {this._renderReadyToShip()}
        {this._renderDateDeliver()}
        {this._renderAutoCancelOrder()}
      </VStack>
    )
  }
}
