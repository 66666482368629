import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as StoreActions from 'x/modules/store/StoreState'
import XContainer from 'xui/components/XContainer'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContent from 'xui/components/XContent'
import HStack from 'xui/components/HStack'
import Box from 'xui/components/Box'
import NB from 'x/config/nativebase'
import { getSelectedStore } from 'x/redux/selectors'
import p from 'x/config/platform-specific'
import XCard from 'xui/components/XCard'
import { ActionApiParams, ISelectedStoreMap, IXScreenProps } from 'x/types'
import XText from 'xui/components/XText'
import XIcon from 'xui/components/XIcon'
import { TouchableOpacity } from 'react-native'
import * as util from 'x/utils/util'

interface IStoreSettingLockOrderAutomationContainerProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
  submitEditing: ActionApiParams
  // subscription: any
}

const StoreSettingLockOrderAutomationContainer: React.FC<IStoreSettingLockOrderAutomationContainerProps> = (props) => {
  const { submitEditing, selectedStore } = props
  // const [visibleShippingTypes, setVisibleShippingTypes] = useState<number[]>([])

  // eslint-disable-next-line react/no-unstable-nested-components
  const _renderHeader = () => (
    <XCustomHeader
      title={p.op.t('StoreSetting.MenuLockOrderAutomation')}
      headerLeftProps={{ backIcon: true, onPressItem: () => util.navGoBack(props) }}
    />
  )

  const onChange = async (isActive: boolean, bitValue: number) => {
    // console.log('isActive => ', isActive)
    // console.log('bitValue => ', bitValue)
    const s_lock_order_mod_at = selectedStore.has('s_lock_order_mod_at') ? selectedStore.get('s_lock_order_mod_at') : 0
    const submitValue = isActive ? s_lock_order_mod_at - bitValue : s_lock_order_mod_at + bitValue
    // console.log('//////// => ', submitValue)
    const response = await new Promise((resolve) => {
      // @ts-ignore
      submitEditing({
        body: { store_id: selectedStore.get('id'), s_lock_order_mod_at: submitValue, is_s_lock_order_mod_at_new_version: true },
        successCallback: resolve,
        failedCallback: () => resolve(null),
      })
    })
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  const _renderBody = () => {
    const s_lock_order_mod_at = selectedStore.has('s_lock_order_mod_at_new') ? selectedStore.get('s_lock_order_mod_at_new') : 0
    // const binaryArr = decimalTobinaryArr(s_lock_order_mod_at, 4)
    // const orderModAtValue = {
    //   '8': binaryArr[0],
    //   '4': binaryArr[1],
    //   '2': binaryArr[2],
    //   '1': binaryArr[3],
    // }

    const orderModOptions = [
      { label: p.op.t('StoreSetting.CheckboxAutoLockOrders._1'), value: 1 },
      { label: p.op.t('StoreSetting.CheckboxAutoLockOrders._2'), value: 2 },
      { label: p.op.t('StoreSetting.CheckboxAutoLockOrders._3'), value: 4 },
      { label: p.op.t('StoreSetting.CheckboxAutoLockOrders._4'), value: 8 },
      // { label: p.op.t('StoreSetting.ChoicesAutoLockOrder._3'), value: 3, txtNote: p.op.t('StoreSetting.NotesAutoLockOrder._3') },
    ]

    return (
      <XCard mx='2' mt='2' mb='2' px='2'>
        <Box w='full' mt='2' mb='2'>
          <XText variant='active'>{p.op.t('StoreSetting.MenuAutoLockOrder')}</XText>
          <XText variant='inactive'>{p.op.t('StoreSetting.NoteAutoLockOrder')}</XText>
          <XText variant='active'>{p.op.t('StoreSetting.AdditionLabelAutoLockOrder')}</XText>
          <Box h='2' />
          {orderModOptions.map((option: { label: string; value: number }) => {
            const isActive = util.checkBitBinaries(s_lock_order_mod_at, option.value)
            return (
              <TouchableOpacity style={{ height: 35 }} onPress={() => onChange(isActive, option.value)}>
                <HStack>
                  <XIcon name={isActive ? 'check-square-o' : 'square-o'} family='FontAwesome' />
                  <XText ml='2' variant={isActive ? 'active' : 'inactive'}>
                    {option.label}
                  </XText>
                </HStack>
              </TouchableOpacity>
            )
          })}
        </Box>
      </XCard>
    )
  }

  return (
    <XContainer>
      {_renderHeader()}
      <XContent px='2' pb='2'>
        <Box w='full' mt='2' borderRadius='md' _light={{ bg: 'white' }} _dark={{ bg: NB.C.D.BG.CLEAR }}>
          {_renderBody()}
        </Box>
        {/* {isLoading ? VIEW_LOADING(windowWidth, windowHeight) : null} */}
      </XContent>
    </XContainer>
  )
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// // @ts-ignore
// StoreSettingLockOrderAutomationContainer.navigationOptions = {
//   header: null,
// }

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
  }),
  (dispatch) => ({
    submitEditing: bindActionCreators(StoreActions.submitEditingStore, dispatch),
    resetEditingStore: bindActionCreators(StoreActions.resetEditingStore, dispatch),
    // storeActions: bindActionCreators(StoreState, dispatch),
  })
)(StoreSettingLockOrderAutomationContainer)
