// import PropTypes from 'prop-types'
import React from 'react'
import { Text, Card, HStack } from 'native-base'
import Box from 'xui/components/Box'

import {
  View,
  RefreshControl,
  FlatList,
  Dimensions,
  ActivityIndicator,
  TouchableOpacity,
  Keyboard,
  Platform,
  StyleSheet,
  ListRenderItemInfo,
  TextInput,
  ScrollView,
} from 'react-native'

// import s from './_style'
import * as util from 'x/utils/util'
import { log, setStatePromise } from 'x/utils/util'
import { IProductListItemMap, IProductListViewOnPressParams, ICategory, IProductItemDataMap, IBarcodeScannerOutput } from 'x/index'

import _ from 'lodash'
// import * as NavActions from '../../services/navigation'
import { Map } from 'immutable'

// import AddIcon from '../../components/AddIcon'
// import BackIcon from '../../components/BackIcon'
import * as NavActions from 'x/utils/navigation'

import { S, COLORS, STYLES } from 'x/config/styles'
// import BaseUIProductListItem from './BaseUIProductListItem'

import * as xAcl from 'x/utils/acl'
import xCONS from 'x/config/constants'
import p from 'x/config/platform-specific'
import BaseProductListView from 'x/modules/product/BaseProductListView'
import CategoryFilterView from 'xui/components/CategoryFilterView'
import XIcon from 'xui/components/XIcon'
import { APP_CONFIG } from 'x/config/mode'
import BarcodeScannerModalButton from 'xui/components/BarcodeScannerModalButton'
import XStatusBar from '../../components/XStatusBar'
import NavHeaderButton from '../../components/NavHeaderButton'
import XInput from '../../components/XInput'
import Icon from '../../components/Icon'

const { CARD_COMMON } = STYLES
const {
  PULL_MY_PRODUCT_TO_ORDER,
  PULL_SELLER_PRODUCT_TO_ORDER,
  PULL_PRODUCT,
  ADD,
  VIEW_SELF,
  EDIT_SELLER,
  EDIT_SELF,
  VIEW_SELLER,
  VIEW_VOLUME_DISCOUNT,
} = xCONS.PRODUCT_VIEW_MODE
const { COMPUTED_USE_PRODUCT_UPC, COMPUTED_USE_PRODUCT_SKU } = xCONS.STORE_SETTINGS
const PRODUCT_ITEM_WIDTH_MAX = 220
const QUICK_ADD_PRODUCT_TO_ORDER_LABEL = `เพิ่ม ${APP_CONFIG.order.create_order.quick_add_product_to_order_qty} ชิ้นในออเดอร์...`

abstract class BaseUIProductListView extends BaseProductListView {
  static displayName = 'BaseUIProductListView'

  // static navigationOptions = ({ navigation }) => {
  //   return { header: null }
  // }
  // static navigationOptions = {
  //   title: 'รายการสินค้า',
  //   header: null,
  //   headerLeft: null,
  //   headerRight: null,
  // }

  deviceWidth: number

  productWidth: number

  productDescHeight: number

  buttonBottomCardHeight: number

  productHeight: number

  thumbnailSize: number

  itemMargin: number

  numberOfColumn: number

  // productItemRefs?: {
  //   [key: number]: RefObject<any>
  // }
  productCategoryScrollableMaxX?: number

  productCategoryLastScrollX?: number

  flatListRef?: React.RefObject<FlatList<IProductListItemMap>>

  // txtSearchRef?: React.RefObject<XInput & Partial<TextInput>>
  txtSearchRef?: React.RefObject<TextInput>

  productCategoryScrollRef?: React.RefObject<ScrollView>

  // Implement NavAction ใน BaseUI ไม่ได้ก็ abstract ต่อไปให้แต่ละ platform จัดการ
  abstract handlePressPullToMyStore(params: IProductListViewOnPressParams): void | Promise<void>

  abstract handlePressPullMyToOrder(params: IProductListViewOnPressParams): void | Promise<void>

  abstract handlePressPullSellerToOrder(params: IProductListViewOnPressParams): void | Promise<void>

  abstract handlePressViewMyProduct(params: IProductListViewOnPressParams): void | Promise<void>

  abstract handlePressEtcCase(params: IProductListViewOnPressParams): void | Promise<void>

  // abstract doNavToProductDetailAfterSearchMatchOneProduct(product?: { id: number; [key: string]: any }): void
  abstract onRequestCloseCategoryFilterView(): void

  abstract handlePressOpenProductCategoryFilterDrawer(): void

  abstract navToAddProductView: () => Promise<void> | void

  abstract renderProductListItem: (data: ListRenderItemInfo<IProductItemDataMap>) => JSX.Element

  // abstract renderBarcodeScanButton: () => JSX.Element
  abstract renderMain: () => JSX.Element

  protected constructor(props) {
    super(props)
    this.deviceWidth = Dimensions.get('screen').width
    this.productDescHeight = 60 // line ละ 20 pixel
    this.buttonBottomCardHeight = 40 // For quick add product to order mode
    this.numberOfColumn = 2
    this.productCategoryScrollRef = React.createRef()
    this.productCategoryLastScrollX = 0
    // this.productWidth = this.deviceWidth * 0.475
    // this.productWidth = this.productWidth > PRODUCT_ITEM_WIDTH_MAX ? PRODUCT_ITEM_WIDTH_MAX : this.productWidth
    // this.productDescHeight = 60 // line ละ 20 pixel
    // this.buttonBottomCardHeight = 40 // For quick add product to order mode
    // this.productHeight = this.productWidth + this.productDescHeight + this.buttonBottomCardHeight
    // this.thumbnailSize = this.productWidth - 10
    // this.itemMargin = this.deviceWidth * 0.025 // 2.5% margin
    // this.numberOfColumn = 2
    this._computeProductListItemSize()
    this.flatListRef = React.createRef()
    this.txtSearchRef = React.createRef()

    // TODO by Keng: To make the layout dynamic i.e. displaying 2,3 or 4 item per row,
    // the numberOfColumn and all the widths must be computed on every
    // onLayout event call https://cmichel.io/how-to-get-the-size-of-a-react-native-view-dynamically
    // For now, just hard-code it to 2
    // if (this.deviceWidth >= 1024) {
    //   this.numberOfColumn = 4
    // } else if (this.deviceWidth >= 768) {
    //   this.numberOfColumn = 3
    // }

    this._renderFooter = this._renderFooter.bind(this)
    this._onProductPress = this._onProductPress.bind(this)
  }

  doNavToProductDetailAfterSearchMatchOneProduct = (selectedProduct?: { id: number; [key: string]: any }): void => {
    let params = this._getParams()
    // @ts-ignore
    if (params.store_id && selectedProduct && selectedProduct.id) {
      params = { ...params, product_id: selectedProduct.id }
      const isProductDetail = _.has(selectedProduct, 'name')
      this.props.navigation.dispatch(
        NavActions.navToProductView({
          ...params,
          skipInitFetchDetail: isProductDetail,
        })
      )
    }
    this.setState({ txtSearch: '', isSearch: false, isSearching: false })
  }

  _computeProductListItemSize = () => {
    // this.deviceWidth = this.getScreenWidthByPlatform()
    this.productWidth = this.deviceWidth * 0.475
    this.productWidth = this.productWidth > PRODUCT_ITEM_WIDTH_MAX ? PRODUCT_ITEM_WIDTH_MAX : this.productWidth
    this.productHeight = this.productWidth + this.productDescHeight + this.buttonBottomCardHeight
    this.thumbnailSize = this.productWidth - 10
    this.itemMargin = this.deviceWidth * 0.025 // 2.5% margin
  }

  // Abstract Method
  _beforeInitDidMount = async (): Promise<void> => {
    // const { mode } = this._getParams()
    const mode = util.getNavParam(this.props, 'mode')
    if (_.includes([PULL_MY_PRODUCT_TO_ORDER, PULL_SELLER_PRODUCT_TO_ORDER], mode)) {
      this.productHeight = this.productWidth + this.productDescHeight + this.buttonBottomCardHeight
    } else {
      this.productHeight = this.productWidth + this.productDescHeight
    }
  }

  _afterInitDidMount = async (): Promise<void> => {
    // no-op
  }

  // @ts-ignore
  _getParams = () => {
    const params = util.getNavParams(this.props)
    if (params) {
      return params
    }
    return {}
  }

  async _onShowQuickAddProductSelector(BTN_LABELS, product, variants, DISPLAY_VARIANTS): Promise<void> {
    // const productId = product.get('id')
    // const btnRef = this.productItemRefs && _.has(this.productItemRefs, productId) ? this.productItemRefs[productId] : null
    const cancelButtonIndex = BTN_LABELS.length - 1
    const selectedIndex = await new Promise<number>((selectIdx) => {
      p.op.showActionSheet(
        {
          // buttonRef: p.op.isWeb() && btnRef ? btnRef : null,
          options: BTN_LABELS,
          cancelButtonIndex,
          // destructiveButtonIndex: -1,
          title: QUICK_ADD_PRODUCT_TO_ORDER_LABEL,
        },
        (buttonIndex: string | number) => {
          log(this, '_onShowQuickAddProductSelector buttonIndex => ', buttonIndex)
          const idx = _.isString(buttonIndex) ? parseInt(buttonIndex) : buttonIndex
          selectIdx(idx)
        }
      )
    })
    if (selectedIndex >= 0 && selectedIndex !== cancelButtonIndex && Map.isMap(variants.get(selectedIndex))) {
      await this._pullProductToOrder(product, DISPLAY_VARIANTS, selectedIndex)
    }
  }

  _handleScroll = (e) => {
    const newY = e.nativeEvent.contentOffset.y
    // log('OLD: ' + this.currScrollPos)
    // log(e)
    if (this.currScrollPos === 0 && newY > 0) {
      // load more as soon as the list is scrolled
      this._handleLoadMore()
    }
    this.currScrollPos = newY
    if (this.state.isSearch) {
      // hide keyboard
      Keyboard.dismiss()
    }
    // log('NEW: ' + this.currScrollPos)
  }

  _renderFooter() {
    const { isSearch, txtSearch } = this.state
    // log('BaseUIProductListView._renderFooter')
    if (this._hasMoreRow()) {
      return (
        <View style={s.listFooter}>
          <ActivityIndicator animating />
        </View>
      )
    }
    return null
  }

  _showPulledProductIcon = (): boolean => {
    // const { mode } = this._getParams()
    const mode = util.getNavParam(this.props, 'mode')
    if (mode && _.includes([PULL_SELLER_PRODUCT_TO_ORDER, VIEW_SELLER], mode)) {
      return true
    }
    return false
  }

  _showQuickAddButton = (): boolean => {
    // const { mode } = this._getParams()
    const mode = util.getNavParam(this.props, 'mode')
    if (mode && _.includes([PULL_MY_PRODUCT_TO_ORDER, PULL_SELLER_PRODUCT_TO_ORDER], mode)) {
      return true
    }
    return false
  }

  // _renderItem({ item, index }) {
  //   const { navigation, selectedStore } = this.props
  //   const { state } = navigation
  //   // const s_use_retail_price = selectedStore.get('s_use_retail_price') || false
  //   return (
  //     <BaseUIProductListItem
  //       index={index}
  //       data={item}
  //       // mode={state.params.mode}
  //       showPulledProductIcon={this._showPulledProductIcon()}
  //       showQuickAddButton={this._showQuickAddButton()}
  //       // navigation={navigation}
  //       // isShowRetailPrice={ s_use_retail_price }
  //       // isUseRetailPrice={ state.params.order_use_retail_price }
  //       width={this.productWidth}
  //       height={this.productHeight}
  //       thumbnailSize={this.thumbnailSize}
  //       itemMargin={this.itemMargin}
  //       warningZeroWeight={this._shouldWarningZeroWeight()}
  //       onPress={this._onProductPress}
  //       onPressAddOneProductToCart={this._onPressAddOneProductToCart}
  //     />
  //   )
  // }

  _shouldWarningZeroWeight = (): boolean => {
    const { selectedStore } = this.props
    const useAutoCalculatShippingCost = selectedStore.get('s_auto_calc_shipping_cost') || false
    const shippingCalculateMode = selectedStore.get('s_shipping_calc_mode') || 1
    if (useAutoCalculatShippingCost) {
      // ยกเว้น โชว์เตือน ไม่ระบุน้ำหนัก ยกเว้น mode 1
      // ref: https://app.asana.com/0/349980817619851/625630510587941
      return shippingCalculateMode !== 1
    }
    return false
  }

  _keyExtractor = (item, index) => index.toString()

  _renderProductList = () => {
    // log('_renderProductList')
    const { selectedProducts, searchedProducts, navigation } = this.props
    const { isSearch, txtSearch } = this.state
    // const { mode } = navigation.state.params
    const mode = util.getNavParam(this.props, 'mode')

    if ((!isSearch && selectedProducts && selectedProducts.size > 0) || (isSearch && txtSearch.length > 2)) {
      let data: any = isSearch ? searchedProducts : selectedProducts
      // log(data, isSearch, searchedProducts)
      if (data) {
        data = data.toArray()
      } else {
        data = []
      }
      return (
        <FlatList
          contentContainerStyle={{
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            // paddingTop: 8,
            // paddingBottom: 4,
            marginTop: 4,
            paddingTop: 12,
            paddingBottom: 12,
          }}
          // ref={r => r && (this.flatListRef = r)}
          // @ts-ignore
          ref={this.flatListRef}
          numColumns={this.numberOfColumn}
          initialNumToRender={20}
          scrollEventThrottle={1}
          maxToRenderPerBatch={40}
          ListFooterComponent={this._renderFooter}
          onScroll={this._handleScroll}
          // onEndReachedThreshold={0.26}
          onEndReachedThreshold={0.3}
          onEndReached={this._handleLoadMore}
          // onEndThreshold={8}
          // horizontal={false}
          // keyExtractor={ (item, index) => index }
          keyExtractor={this._keyExtractor}
          // data={ isSearch ? searchProducts.toArray() : selectedProducts.toArray() }
          data={data}
          // renderItem={ ({ item, index }) => this._renderProductItem({ index, item }) }
          // renderItem={this._renderItem}
          renderItem={this.renderProductListItem}
          // legacyImplementation={true}
          // removeClippedSubviews={true}
          // disableVirtualization={true}
          // shouldItemUpdate={(props,nextProps) => {
          //   return false
          // }}
          refreshControl={<RefreshControl refreshing={this.state.refreshing} onRefresh={this._readyToRefresh} />}
        />
      )
    }

    let emptyText
    const hasZeroRow = this._isDoneLoadingAndHasZeroRow()
    if (hasZeroRow) {
      if (mode === PULL_PRODUCT) {
        emptyText = 'คุณดึงสินค้าจากร้านนี้ไปหมดทุกชนิดแล้ว\nหรือ ร้านค้าไม่มีรายการสินค้าที่คุณสามารถดึงได้'
      } else if (mode === PULL_MY_PRODUCT_TO_ORDER) {
        emptyText = 'ไม่พบรายการสินค้า\nคุณต้องมีสินค้าของคุณเอง หรือดึงสินค้ามาจากร้านขายส่ง จึงจะมีสินค้าที่สามารถใช้เปิดออเดอร์ได้'
      } else if (mode === VIEW_SELF) {
        emptyText = 'ไม่พบรายการสินค้า\nคุณสามารถสร้างสินค้าได้จากปุ่ม (+) ด้านบน'
      } else {
        emptyText = 'ไม่พบรายการสินค้า'
      }
    } else {
      emptyText = 'กำลังโหลดรายการสินค้า...'
    }

    return isSearch && txtSearch.length < 3 ? (
      <View style={s.listFooter}>
        <Text
          style={{
            marginTop: 20,
            textAlign: 'center',
            fontSize: STYLES.FONT_SIZE_NORMAL,
            color: COLORS.TEXT_INACTIVE,
          }}>
          กรุณาพิมพ์พิมพ์ 3 ตัวอักษรขึ้นไป เพื่อเริ่มการค้นหา
        </Text>
      </View>
    ) : (
      <Card
        // @ts-ignore
        style={[CARD_COMMON, { marginTop: 12 }]}>
        <Box>
          <HStack style={{ height: 150, alignItems: 'center' }}>
            <Text
              style={{
                textAlign: 'center',
                fontSize: STYLES.FONT_SIZE_NORMAL,
                color: COLORS.TEXT_INACTIVE,
              }}>
              {emptyText}
            </Text>
            {hasZeroRow ? null : <ActivityIndicator style={{ paddingTop: 30 }} animating />}
          </HStack>
        </Box>
      </Card>
    )
  }

  // _onSearchProducts({ isLoadMore }) {
  //   const { txtSearch } = this.state
  //   if (txtSearch.length > 0) {
  //     let isNewSearch = this.lastSearchedProductOffset === 0
  //     // log('BaseUIProductListView._fetchData from _onSearchProducts')
  //     // log(isNewSearch)
  //     this._fetchData({ isLoadMore, queryTxt: txtSearch, isNewSearch: isNewSearch })
  //   }
  // }

  // _deactivateSearch = () => {
  //   let _isSearch = false
  //   if (this.state.isSearch !== _isSearch) {
  //     // reset the last item offset as it switches mode
  //     this.lastSearchedProductOffset = 0
  //   }
  //   this.setState({ txtSearch: '', isSearch: _isSearch }, () =>
  //     this.flatListRef.scrollToOffset({ x: 0, y: 0, animated: false }),
  //   )
  // }

  _doAfterDeactivateSearch(): void {
    const flatListRef = this.flatListRef.current
    if (flatListRef && _.isFunction(flatListRef.scrollToOffset)) {
      flatListRef.scrollToOffset({ x: 0, y: 0, animated: false })
    }
  }

  // _onProductBarcodeScanned = (data: string): void => {
  _onProductBarcodeScanned = async (barcodeOutput: IBarcodeScannerOutput) => {
    const { data } = barcodeOutput
    if (data) {
      this._onSearchInputChange(data, true)
    }
  }

  _onSearchInputChange = async (text: string, isBarcodeSearch?: boolean): Promise<void> => {
    this.isTxtSearchModified = true
    this.lastTxtSearch = text
    if (text.length > 0) {
      const _isSearch = true
      if (this.state.isSearch !== _isSearch) {
        // reset the last item offset as it switches mode
        this.lastSearchedProductOffset = 0
        const flatListRef = this.flatListRef.current
        // this.flatListRef.scrollToOffset({ x: 0, y: 0, animated: false })
        if (flatListRef && _.isFunction(flatListRef.scrollToOffset)) {
          flatListRef.scrollToOffset({ x: 0, y: 0, animated: false })
        }
      }
      // this.setState({ txtSearch: text, isSearch: _isSearch }, () => this._onSearchProducts({ isLoadMore: false }))
      await setStatePromise(this, { txtSearch: text, isSearch: _isSearch })
      await this._onSearchProducts({ isLoadMore: false, isBarcodeSearch })
    } else {
      await this._deactivateSearch()
    }
  }

  renderBarcodeScanButton = () => {
    // const { mode } = this._getParams()
    const isAvailableBarcodeSearch = this._getIsAvailableBarcodeSearch()
    if (!isAvailableBarcodeSearch) {
      return null
    }
    return (
      <BarcodeScannerModalButton
        btnStyle={[S.HEIGHT_40, S.HEIGHT_40, { marginRight: 4 }]}
        saveStateKey={xCONS.STATE_SAVE_KEYS.BARCODE_SCANNER_MODAL}
        headerTitle='สแกนบาร์โค้ดเพื่อค้นหาสินค้า'
        onBarcodeScanned={this._onProductBarcodeScanned}
      />
    )
  }

  _renderCustomHeader = () => {
    const { navigation, selectedFetchStatus } = this.props
    const { txtSearch, catSeletedCategory } = this.state
    if (!navigation) {
      return null
    }

    const params = util.getNavParams(this.props)
    if (_.isNil(params) || _.isNil(params.mode)) {
      return null
    }

    const { mode } = params
    let canSearch = selectedFetchStatus && selectedFetchStatus.has('count') && selectedFetchStatus.get('count') > 0
    const countText = canSearch ? `${selectedFetchStatus.get('count')} รายการ` : ''
    let placeholderSearch = canSearch ? `ค้นหาสินค้าในร้าน ${countText}` : ''
    // placeholderSearch = 'สินค้าในร้านฉันทั้งหมด'
    if (mode === PULL_PRODUCT) {
      placeholderSearch = canSearch ? `ค้นหาสินค้าพร้อมดึง ${countText}` : ''
    }
    // else if (mode === VIEW_SELLER) {
    //   placeholderSearch = `ค้นหาสินค้าในร้านผู้ขายส่ง ${productCount} รายการ`
    // }
    // else if (mode === PULL_MY_PRODUCT_TO_ORDER) {
    //   placeholderSearch = `ค้นหาสินค้าในร้านฉัน ${productCount} รายการ`
    // }
    if (!_.isNil(catSeletedCategory)) {
      placeholderSearch = catSeletedCategory.n
      canSearch = true
      if (!_.isNil(selectedFetchStatus.get('count')) && selectedFetchStatus.get('count') > 0) {
        placeholderSearch += ` มี ${selectedFetchStatus.get('count')} รายการสินค้า`
      } else {
        placeholderSearch += ` ไม่พบรายการสินค้า`
      }
    }

    return (
      <HStack
        // iosBarStyle='dark-content'
        // searchBar
        style={{
          backgroundColor: '#fff',
          paddingLeft: 0,
          paddingRight: 0,
          height: 44,
          paddingTop: 0,
        }}
        // style={{
        //   marginTop: isIphoneX() ? getStatusBarHeight() - 4 : undefined,
        // }}
      >
        <XStatusBar translucent={false} backgroundColor='#fff' animated={false} />
        <View style={[S.FLEX, S.ROW_BOTTOM_START]}>
          <View style={[S.ROW_CENTER, S.HEIGHT_44, { width: 54 }]}>
            <NavHeaderButton backIcon onPressItem={this._goBack} />
          </View>
          <View style={[S.FLEX, S.ROW_MIDDLE_START, S.HEIGHT_44]}>
            {this.renderBarcodeScanButton()}
            <View
              style={[
                S.FLEX,
                S.ROW_MIDDLE_START,
                S.HEIGHT_40,
                {
                  backgroundColor: canSearch ? COLORS.FORM_PRIMARY_BG : 'transparent',
                },
              ]}>
              <XInput
                // ref={r => r && r._root && (this.txtSearchRef = r._root)}
                ref={this.txtSearchRef}
                style={{
                  fontSize: STYLES.FONT_SIZE_NORMAL,
                  color: COLORS.TEXT_ACTIVE,
                }}
                isDisabled={!canSearch}
                autoCapitalize='none'
                value={txtSearch}
                placeholder={placeholderSearch}
                placeholderTextColor={COLORS.TEXT_INACTIVE}
                // returnKeyType='search'
                maxLength={20}
                onChangeText={this._onSearchInputChange}
              />
              {txtSearch && txtSearch.length > 0 ? (
                <TouchableOpacity onPress={this._deactivateSearch}>
                  <Icon
                    style={{
                      paddingTop: 4, // Offset because icon is doesn't crop
                      color: COLORS.TEXT_INACTIVE,
                      fontSize: STYLES.FONT_ICON_NORMAL,
                    }}
                    name='close-circle'
                  />
                </TouchableOpacity>
              ) : (
                <TouchableOpacity onPress={this._doFocusSearchInput} style={{ display: !canSearch ? 'none' : null }}>
                  <Icon
                    style={{
                      paddingTop: 4, // Offset because icon is doesn't crop
                      color: COLORS.TEXT_INACTIVE,
                      fontSize: STYLES.FONT_ICON_NORMAL,
                    }}
                    name='search'
                  />
                </TouchableOpacity>
              )}
            </View>
          </View>
          {_.includes([VIEW_SELF], mode) && xAcl.canDoAtSelectedStore(xCONS.PERM_STORE_HELPER.PRODUCT_ADD) ? (
            <View style={[S.HEIGHT_44, S.WIDTH_44, S.ROW_CENTER]}>{this._renderAddProductButton(mode)}</View>
          ) : null}

          <View style={[S.HEIGHT_44, S.WIDTH_44, S.ROW_CENTER]}>{this._renderProductCategoryFilterButton()}</View>
        </View>
      </HStack>
    )
  }

  _doFocusSearchInput = () => {
    const txtSearchRef = this.txtSearchRef.current

    if (txtSearchRef && typeof txtSearchRef.focus === 'function') {
      this.setState({ isSearch: true })
      txtSearchRef.focus()
    }
    // else if (txtSearchRef && _.isFunction(txtSearchRef.inputRef.current.focus)) {
    //   txtSearchRef.inputRef.current.focus()
    // }
  }

  _goBack = () => {
    util.navGoBack(this.props)
  }

  _renderAddProductButton = (mode) =>
    _.includes([VIEW_SELF], mode) && xAcl.canDoAtSelectedStore(xCONS.PERM_STORE_HELPER.PRODUCT_ADD) ? (
      // <NavHeaderButton addIcon={true} onPressItem={this.navToAddProductView} style={{ marginRight: 0 }} />
      <TouchableOpacity
        style={{ width: 40, height: 50, justifyContent: 'center', alignItems: 'center' }}
        onPress={this.navToAddProductView}>
        <XIcon family='AntDesign' name='plus' style={{ flex: 0, minWidth: 25 }} />
      </TouchableOpacity>
    ) : null

  _renderProductCategoryFilterButton = () => {
    const { catSeletedCategory } = this.state
    const selected = !_.isNil(catSeletedCategory)
    return (
      // <NavHeaderButton addIcon={true} onPressItem={() => this.drawerCatRef.current._root.open()} />
      <TouchableOpacity
        style={{ width: 40, height: 50, justifyContent: 'center', alignItems: 'center' }}
        onPress={this.handlePressOpenProductCategoryFilterDrawer}>
        {/* <XIcon name='filter' family={`MaterialCommunityIcons`} style={{ flex: 0, minWidth: 25 }} /> */}
        <Icon type='MaterialCommunityIcons' name='filter' style={{ fontSize: STYLES.FONT_ICON_NORMAL, color: COLORS.APP_MAIN }} />
        {selected ? (
          <Icon
            type='MaterialCommunityIcons'
            name='check-circle'
            style={{ color: COLORS.BRAND_Success, fontSize: 12, position: 'absolute', paddingTop: 12, paddingLeft: 12 }}
          />
        ) : null}
      </TouchableOpacity>
    )
  }

  _handleOpenBarcodeCamera = async () => {
    await setStatePromise(this, { isVisibleBarcodeCamera: true })
  }

  _handleCloseBarcodeCamera = async () => {
    await setStatePromise(this, { isVisibleBarcodeCamera: false })
  }

  _renderHelperTab = () => {
    const { txtSearch, isSearch, isSearching, catSeletedCategory } = this.state
    const { searchedFetchStatus } = this.props
    const productCount = _.isNumber(searchedFetchStatus.get('count')) ? searchedFetchStatus.get('count') : 0

    if (isSearch && txtSearch.length > 2) {
      let txtSearchResult
      // log('_renderHelperTab')
      // if (this.props.searchedFetchStatus) {
      //   log(this.props.searchedFetchStatus.toJS())
      // }
      if (this.isTxtSearchModified || isSearching) {
        txtSearchResult = `กำลังค้นหาสินค้าที่มีชื่อประกอบด้วย "${txtSearch}" ...`
      } else {
        txtSearchResult =
          this.props.searchedFetchStatus && this.props.searchedFetchStatus.has('count')
            ? _.isNil(catSeletedCategory)
              ? `${productCount} รายการตรงกับการค้นหา "${txtSearch}"`
              : `${productCount} รายการตรงกับการค้นหา "${txtSearch}" ในหมวดหมู่ "${catSeletedCategory.n}"`
            : `ไม่พบสินค้าที่มีชื่อประกอบด้วย "${txtSearch}"`
      }

      return (
        <View
          style={{
            width: '100%',
            height: 30,
            // backgroundColor: 'red',
            backgroundColor: '#FDFDFD',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <View style={{ flex: 7, flexDirection: 'row', flexWrap: 'wrap', paddingLeft: 5 }}>
            <Text
              style={{
                color: COLORS.TEXT_INACTIVE,
                fontSize: STYLES.FONT_SIZE_SMALLER,
              }}>
              {txtSearchResult}
            </Text>
          </View>

          {/* TODO: Add Filter and change layout feature */}
          {/* <View style={{ flex: 3,flexDirection: 'row', justifyContent: 'flex-end', */}
          {/* alignItems: 'center', paddingRight: 5 }}> */}

          {/* <TouchableOpacity */}
          {/* style={{ paddingRight: 3 }}> */}
          {/* <MCIcon */}
          {/* style={{ color: COLORS.TEXT_INACTIVE, fontSize: STYLES.FONT_ICON_LARGER }} */}
          {/* name='view-list' /> */}
          {/* </TouchableOpacity> */}

          {/* <TouchableOpacity */}
          {/* style={{ paddingRight: 3 }}> */}
          {/* <MCIcon */}
          {/* style={{ color: COLORS.TEXT_ACTIVE, fontSize: STYLES.FONT_ICON_NORMAL - 1 }} */}
          {/* name='view-grid' /> */}
          {/* </TouchableOpacity> */}

          {/* <Text */}
          {/* style={{ */}
          {/* paddingBottom: 3, paddingLeft: 5, paddingRight: 8, */}
          {/* color: COLORS.TEXT_INACTIVE, */}
          {/* fontSize: STYLES.FONT_ICON_NORMAL }}>|</Text> */}

          {/* <TouchableOpacity> */}
          {/* <MCIcon */}
          {/* style={{ color: COLORS.TEXT_PRIMARY, fontSize: STYLES.FONT_ICON_LARGER }} */}
          {/* name='filter' /> */}
          {/* </TouchableOpacity> */}

          {/* </View> */}
        </View>
      )
    }
    return null
  }

  getScreenWidth = (): number => Dimensions.get('screen').width

  _onProductCategoryScrollContentSizeChange = (width: number, height: number) => {
    const w = width - this.getScreenWidth()
    this.productCategoryScrollableMaxX = w > 0 ? w : 0
    this.setState({
      productCategoryScrollViewFlags: {
        isVisibleScrollToBeginButton: this.productCategoryScrollableMaxX > 0 && this.productCategoryLastScrollX > 0,
        isVisibleScrollToEndButton:
          this.productCategoryScrollableMaxX > 0 && this.productCategoryLastScrollX < this.productCategoryScrollableMaxX - 4,
      },
    })
  }

  _handleProductCategoryScroll = (e) => {
    this.productCategoryLastScrollX = e.nativeEvent.contentOffset.x
    this.setState({
      productCategoryScrollViewFlags: {
        isVisibleScrollToBeginButton: this.productCategoryScrollableMaxX > 0 && this.productCategoryLastScrollX > 0,
        isVisibleScrollToEndButton:
          this.productCategoryScrollableMaxX > 0 && this.productCategoryLastScrollX < this.productCategoryScrollableMaxX - 8,
      },
    })
  }

  _handleProductCategoryScrollToBegin = () => {
    try {
      let nextOffset = this.productCategoryLastScrollX - this.getScreenWidth() + 60
      if (nextOffset < 0) {
        nextOffset = 0
      }
      this.productCategoryScrollRef.current.scrollTo({ x: nextOffset, animated: true })
    } catch (err) {
      log(err)
    }
  }

  _handleProductCategoryScrollToEnd = () => {
    try {
      let nextOffset = this.productCategoryLastScrollX + this.getScreenWidth() - 60
      if (nextOffset > this.productCategoryScrollableMaxX) {
        nextOffset = this.productCategoryScrollableMaxX
      }
      this.productCategoryScrollRef.current.scrollTo({ x: nextOffset, animated: true })
    } catch (err) {
      log(err)
    }
  }

  _renderProductCategoryScrollToBeginButton = () => {
    if (!this.state.productCategoryScrollViewFlags.isVisibleScrollToBeginButton) {
      return null
    }

    return (
      <TouchableOpacity
        // onPress={() => this.productCategoryScrollRef.current.scrollTo({ x: 0, animated: true })}
        onPress={this._handleProductCategoryScrollToBegin}
        style={{
          flex: 1,
          position: 'absolute',
          left: 0,
          height: 35,
          width: 22,
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(88,88,88,0.4)',
        }}>
        <XIcon name='arrow-back' />
      </TouchableOpacity>
    )
  }

  _renderProductCategoryScrollToEndButton = () => {
    if (!this.state.productCategoryScrollViewFlags.isVisibleScrollToEndButton) {
      return null
    }

    return (
      <TouchableOpacity
        // onPress={() => this.productCategoryScrollRef.current.scrollToEnd()}
        onPress={this._handleProductCategoryScrollToEnd}
        style={{
          flex: 1,
          position: 'absolute',
          right: 0,
          height: 35,
          width: 22,
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(88,88,88,0.4)',
        }}>
        <XIcon name='arrow-forward' />
      </TouchableOpacity>
    )
  }

  _renderCatListItems = (categories) =>
    categories.map((data: ICategory, index: number) => {
      const marginLeft = index === 0 ? 12 : 12
      const marginRight = index + 1 === categories.length ? 4 : 4
      return (
        <TouchableOpacity
          onPress={() => this._selectedSubCategory(data)}
          key={data.id}
          style={{
            minWidth: 50,
            // width: 50,
            // maxWidth: 120,
            height: 25,
            borderColor: data.l,
            borderWidth: 0.7,
            borderRadius: 7,
            marginLeft,
            marginRight,
            paddingLeft: 4,
            paddingRight: 4,
            justifyContent: 'center',
          }}>
          <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: data.l, textAlign: 'center', paddingHorizontal: 2 }} numberOfLines={1}>
            {data.n}
          </Text>
        </TouchableOpacity>
      )
    })

  _renderCatList = () => {
    // const { categoryList } = this.props
    const { catSupCategory } = this.state
    const categories = _.isNil(catSupCategory) ? [] : catSupCategory
    const bodyHeight = categories.length < 1 ? 0 : 35
    return (
      <View
        style={{
          flex: 0,
          flexBasis: bodyHeight,
          height: bodyHeight,
          // justifyContent: 'center',
          flexDirection: 'row',
          alignItems: 'center',
          // alignContent: 'center',
          backgroundColor: COLORS.BG_SOFT_GREY,
          // alignContent: 'flex-start',
          // alignSelf: 'flex-start',
        }}>
        <ScrollView
          ref={this.productCategoryScrollRef}
          onContentSizeChange={this._onProductCategoryScrollContentSizeChange}
          style={{ maxWidth: this.getScreenWidth() }}
          horizontal
          // showsHorizontalScrollIndicator={p.op.isWeb()}
          showsHorizontalScrollIndicator={false}
          onScroll={p.op.isWeb() ? this._handleProductCategoryScroll : null}
          onMomentumScrollEnd={this._handleProductCategoryScroll}
          onScrollEndDrag={this._handleProductCategoryScroll}>
          {this._renderCatListItems(categories)}
        </ScrollView>
        {p.op.isWeb() ? this._renderProductCategoryScrollToBeginButton() : null}
        {p.op.isWeb() ? this._renderProductCategoryScrollToEndButton() : null}
      </View>
    )
  }

  _renderCategoryFilterView = () => {
    const { categoryList } = this.props
    const { catLevelCategoryList } = this.state
    return (
      <CategoryFilterView
        onRequestCloseView={this.onRequestCloseCategoryFilterView}
        levelCategoryList={catLevelCategoryList}
        categoryList={categoryList.toJS()}
        // selectedCategory={catSeletedCategory}
        // submit={async (category: ISubmitDrawerCategory) => this._callBackSubmitForDrawer(category)}
        onSubmit={this._callBackSubmitForDrawer}
      />
    )
  }

  render() {
    return this.renderMain()
  }

  // render() {
  //   logRender(this)
  //   // const { selectedProducts } = this.props
  //   // log('_BaseUIProductListView_render__products_: ', selectedProducts.toJS())
  //   // log('_BaseUIProductListView_render__products_: ', this.props.products.get('selectedProducts'))
  //   return (
  //     <View style={{ flex: 1 }}>
  //       {this._renderCustomHeader()}
  //       <View
  //         style={{ flex: 1 }}
  //         // disableKBDismissScroll={true}
  //         // refreshControl={<RefreshControl
  //         //   refreshing={ this.state.refreshing }
  //         //   onRefresh={ this._onRefresh }
  //         // />}
  //       >
  //         {this._renderHelperTab()}
  //         {this._renderProductList()}
  //         {/*{ this._renderProducts(this.props.products.get('selectedProducts'))}*/}
  //       </View>
  //     </View>
  //   )
  // }
}
// export default connectStyle(xCONS.APP_NAME + '.BaseUIProductListView', s)(BaseUIProductListView)
export default BaseUIProductListView

export const s = StyleSheet.create({
  // ICON_SELLER_STORE: require('../../img/tb/tb_seller0.png'),
  productContainer: {
    paddingLeft: 10,
    paddingRight: 10,
    // backgroundColor: COLORS.SUCCESS_MAIN,
  },
  variantGrid: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  variantGridColor: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLORS.BG_LIGHT_GREY,
  },
  variantColLeft: {
    // width: '15%',
    // backgroundColor: 'powderblue',
    flex: 3,
    flexDirection: 'column',
    alignItems: 'center',
    padding: 5,
  },
  variantColRight: {
    flex: 17,
    // backgroundColor: 'skyblue',
    justifyContent: 'center',
    padding: 5,
  },
  variantColRightColor: {
    width: '80%',
    backgroundColor: 'rgba(15,185,215,0.2)',
    justifyContent: 'center',
    padding: 5,
  },
  variantFullGrid: {
    width: '100%',
    // backgroundColor: 'skyblue',
    justifyContent: 'center',
    padding: 5,
  },
  variantAddItem: {
    // backgroundColor: 'lightgreen',
    padding: 10,
  },
  cardStyle: {
    elevation: 3,
    width: '100%',
  },
  cardBody: {
    width: '100%',
    height: 220,
  },
  cardImg: {
    alignSelf: 'center',
    width: '100%',
    height: 200,
  },
  textEmptyStore: {
    marginTop: '40%',
    alignSelf: 'center',
  },
  fullBtnContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  txtHeader: {
    fontSize: STYLES.FONT_SIZE_ORDERS_HEADER_ROW2,
    fontWeight: 'bold',
  },
  txtNormal: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
  },
  txtHL: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_PRIMARY,
  },
  txtHLBG: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_PRIMARY,
    backgroundColor: COLORS.FORM_PRIMARY_BG,
  },
  labelMultiple: {
    width: '100%',
    height: 100,
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_INACTIVE,
  },
  txtMultiple: {
    width: '100%',
    minHeight: 100,
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_ACTIVE,
  },
  txtMultipleHL: {
    width: '100%',
    height: 100,
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_PRIMARY,
    backgroundColor: COLORS.FORM_PRIMARY_BG,
  },
  txtLabel: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_INACTIVE,
  },
  txtLabelShippingRate: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_INACTIVE,
    paddingTop: 4,
  },
  txtLabelVariant: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_INACTIVE,
    marginTop: 14,
    height: 32,
    // backgroundColor: 'red',
  },
  txtRight: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    textAlign: 'right',
  },
  txtHLRight: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_PRIMARY,
    backgroundColor: COLORS.FORM_PRIMARY_BG,
    textAlign: 'right',
  },
  labelRight: {
    fontSize: STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_INACTIVE,
    textAlign: 'right',
  },
  input: {
    width: 100,
    alignSelf: 'flex-end',
    paddingRight: 3,
    paddingTop: 0,
    ...Platform.select({
      android: {
        paddingBottom: 5,
      },
    }),
  },
  shippingRateLabel: {
    ...Platform.select({
      android: {
        paddingTop: 8,
      },
      ios: {
        paddingTop: 4,
      },
    }),
  },
  shippingRateInputQty: {
    width: 38,
  },
  shippingRateInputPrice: {
    width: 48,
  },
  shippingRateInputCommon: {
    textAlign: 'center',
    ...Platform.select({
      android: {
        height: 32,
        paddingTop: 0,
        paddingBottom: 8,
      },
      ios: {
        height: 25,
      },
    }),
  },
  shippingRateInputQtyContainer: {
    width: 40,
    ...Platform.select({
      android: {
        height: 32,
      },
      ios: {
        height: 25,
      },
    }),
  },
  shippingRateInputPriceContainer: {
    width: 50,
    height: 25,
  },
  formItem: { paddingTop: 2, paddingBottom: 2, height: 50, borderBottomWidth: 0 },
  formVItem: { paddingTop: 2, paddingBottom: 2, height: 36, borderBottomWidth: 0 },
  formNormalText: { height: 40 },
  listFooter: {
    paddingVertical: 20,
  },
  iconSellerProduct: {
    height: STYLES.FONT_ICON_SMALLEST + 2,
    width: STYLES.FONT_ICON_SMALLEST + 2,
    paddingRight: 4,
    marginTop: -4,
  },
  pItemL3View: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  pItemL3Icon: {
    fontSize: STYLES.FONT_ICON_SMALLEST,
    color: COLORS.TEXT_INACTIVE,
  },
  pItemL3Qty: {
    paddingLeft: 5,
    fontSize: STYLES.FONT_SIZE_SMALLER,
    color: COLORS.TEXT_INACTIVE,
  },
  pItemWeightView: {
    flex: 1,
    alignItems: 'flex-end',
    paddingBottom: 3,
  },
  pItemWeightLabelProdMy: {
    fontSize: 8,
    paddingTop: 1,
    alignItems: 'flex-end',
    color: COLORS.TEXT_INACTIVE,
    paddingRight: 0,
  },
  pItemWeightLabelProdSeller: {
    fontSize: 8,
    paddingTop: 1,
    alignItems: 'flex-end',
    color: COLORS.TEXT_INACTIVE,
    paddingRight: 4,
  },
  pItemWeightLabelBlank: {
    flex: 1,
  },
  txtNoProductNameInfo: {
    flex: 1,
    fontSize: STYLES.FONT_SIZE_SMALLER,
    color: COLORS.TEXT_INACTIVE,
    // backgroundColor: 'red',
    paddingLeft: 15,
    paddingTop: 15,
    paddingBottom: 15,
    // alignSelf: 'center',
  },
})
