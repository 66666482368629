import React, { Component } from 'react'
import _ from 'lodash'
import CONS from 'x/config/constants'
import * as util from 'x/utils/util'
import { ISelectedStoreMap, ActionApiParams, ISelectedAddresses, IProfile, IXScreenProps } from 'x/index'

const { VIEW, EDIT } = CONS.MODE
interface BaseCustomerEditProfileView extends IXScreenProps {
  dispatch: (params: any) => void
  selectedStore: ISelectedStoreMap
  updateOrgMemberProfileDetail: (params: ActionApiParams) => void
}

interface IBaseCustomerViewState {
  isImgUploading: boolean
  mode: string
  // codeId: string
  // nickName: string
  // firstName: string
  // lastName: string
  // gender: number
  // phone: string
  // phone2: string
  // email: string
  // facebook: string
  // line: string
  // instagram: string

  addresses: ISelectedAddresses[]
  profile: IProfile
}

export default class BaseCustomerView extends Component<BaseCustomerEditProfileView, IBaseCustomerViewState> {
  imgManagerRef: React.RefObject<any>

  protected constructor(props: BaseCustomerEditProfileView) {
    super(props)
    this.imgManagerRef = React.createRef()
    this.state = {
      isImgUploading: false,
      mode: VIEW,
      // codeId: '',
      // nickName: '',
      // firstName: '',
      // lastName: '',
      // gender: 0,
      // phone: '',
      // phone2: '',
      // email: '',
      // facebook: '',
      // line: '',
      // instagram: '',

      addresses: null,
      profile: null,
    }
  }

  async componentDidMount() {
    await this._setDefaultProfile()
  }

  _setDefaultProfile = async (changeMode?: string) => {
    // const { navigation } = this.props
    // const { state } = navigation
    // const { params } = state
    const params = util.getNavParams(this.props)
    const mode = _.isNil(changeMode) ? EDIT : changeMode
    // @ts-ignore
    // console.log(`selectedStore`, this.props.selectedStore.toJS())
    // console.log(`params`, params)
    const { profile } = params
    const profileImg = _.isNil(params.profile) ? null : params.profile.thumbnail_uri
    // const mode = _.isNil(params) ? EDIT : EDIT
    const { addresses } = params.profile
    if (this.imgManagerRef && this.imgManagerRef.current) {
      this.imgManagerRef.current.loadImageUrls({
        p: [profileImg],
      })
    }
    // console.log(`mode !!`, mode)
    await util.setStatePromise(this, {
      mode,
      addresses,
      profile,
    })
  }
}
