import React from 'react'
import { COLORS } from 'x/config/styles'

import BaseUIReportShippingView from 'xui/modules/report/BaseUIReportShippingView'
import * as XUtil from 'x/utils/util'
import Box from 'xui/components/Box'
import XText from 'xui/components/XText'
import * as util from '../../utils/util-web'
import DateRangePicker from '../../components/DateRangePicker'

export default class ReportShippingView extends BaseUIReportShippingView {
  _downloadReportShippingView = async (url: string, fileName: string) => {
    await XUtil.setStatePromise(this, { isLoading: true })
    if (url) {
      await util.downloadFileFromUrl({ url, fileName })
    }
    await XUtil.setStatePromise(this, { isLoading: false })
  }

  _renderShippingDateRange = () => {
    const isVisible = this._isVisibleDateRangePicker()
    const isDisabledDateRange = this._computeAllowDays() <= 1
    return isVisible ? (
      <Box w='full' h='32' mt='2'>
        {/* <View style={{ width: '100%', height: 80 }}> */}
        <DateRangePicker
          disabled={isDisabledDateRange}
          allowOnlyDaysFromToday={this._computeAllowDays()}
          allowDateRangeLimitDays={this.state.limitDay}
          selectedDates={this.state.selectedDateRange}
          onChangeDate={this._onChangeDateRange}
        />
        {/* </View> */}
        {isDisabledDateRange ? (
          <XText
            style={{
              // fontSize: FONT_SIZE_SMALLER,
              color: COLORS.TEXT_INACTIVE,
              textAlign: 'center',
            }}>
            {this.TXT_WARNING_DATE_RANGE}
          </XText>
        ) : (
          <XText
            style={{
              // fontSize: FONT_SIZE_SMALLER,
              color: COLORS.APP_MAIN,
              textAlign: 'center',
            }}>
            {this.state.dateRangeDescription}
          </XText>
        )}
      </Box>
    ) : (
      <Box />
    )
  }
}
