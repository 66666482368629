import { connect } from 'react-redux'
// import { bindActionCreators } from 'redux'
// import { NavigationActions } from 'react-navigation'
import ForgottenPasswordView from './ForgottenPasswordView'
// import * as ProfileStateActions from 'x/modules/profile/ProfileState'

export default connect(
  (state) => ({
    profile: state.get('profile'),
  }),
  (dispatch) => ({
    // profileStateActions: bindActionCreators(ProfileStateActions, dispatch),
    dispatch,
  })
)(ForgottenPasswordView)
