import React from 'react'

import { Provider } from 'react-redux'
import { ThemeProvider } from 'react-native-elements'
// import { ThemeProvider as StyledThemeProvider } from 'styled-components/native'
// import NativeBaseTheme from 'x/config/nativebase/theme'
import { RNE_THEME } from 'x/config/styles'
import NativeBaseProvider from 'xui/components/NativeBaseProvider'
import Box from 'xui/components/Box'
import DeveloperMenu from 'xui/test/DeveloperMenu'
// import NativeBaseStoryView from 'xui/test/NativeBaseStoryView'
import { ActionSheetInstance, registerActionSheet } from 'xui/components/singleton/ActionSheet'
import { PdpaConfirmationInstance, registerPdpaConfirmation } from 'xui/components/singleton/PdpaConfirmation'
import { AlertInstance, registerAlert } from 'xui/components/singleton/Alert'
import { MkpProductContextProvider } from 'x/modules/marketplace/MkpProductContext'
// import StyledTheme from 'x/config/nativebase/base/index'
import store from './redux/store'
import sagas from './redux/sagas'
import { sagaMiddleware } from './redux/middleware'
import AppNavigator from './modules/navigator/AppNavigator'
import withClearCache from './ClearCache'

// เพื่อให้ native-base ใช้ toast ได้
// import Root from '../native-base-theme/customComponents/Root'

// import { ActionSheetProvider } from './components/XActionSheet'

// import XSellyCustomStyles from './custom-css-styles'

// // เพื่อให้ layout ของ navigator fit browser ตลอดเวลา
// import FitScreenContainer from './components/FitScreenContainer'

class App extends React.Component {
  componentDidMount() {
    sagaMiddleware.run(sagas)
  }
  // render() {
  //   return (
  //     <Provider store={store}>
  //       <StyleProvider style={getTheme(material)}>
  //         <Root>
  //           <FitScreenContainer>
  //             <AppNavigator enableURLHandling={true} />
  //           </FitScreenContainer>
  //         </Root>
  //       </StyleProvider>
  //     </Provider>
  //   )
  // }

  // handleNavigationChange = (...args) => {
  //   console.log('handleNavigationChange args => ', args)
  // }

  render() {
    return (
      <Provider store={store}>
        <ThemeProvider theme={RNE_THEME}>
          {/* <StyledThemeProvider theme={NativeBaseTheme}> */}
          {/* <Icon type='ionicon' name={'md-checkmark'} /> */}
          <NativeBaseProvider>
            <MkpProductContextProvider>
              <Box flex={1}>
                {/* <XSellyCustomStyles /> */}

                {/* <NativeBaseStoryView /> */}
                <AppNavigator
                // onNavigationStateChange={this.handleNavigationChange}
                // enableURLHandling
                />
                <ActionSheetInstance ref={registerActionSheet} />
                <PdpaConfirmationInstance ref={registerPdpaConfirmation} />
                <AlertInstance ref={registerAlert} />
                <DeveloperMenu />
              </Box>
            </MkpProductContextProvider>
          </NativeBaseProvider>
          {/* </StyledThemeProvider> */}
        </ThemeProvider>
      </Provider>
    )
  }
}

export default withClearCache(App)
