import BaseUIAboutView from 'xui/modules/setting/BaseUIAboutView'

class AboutView extends BaseUIAboutView {
  static displayName = 'AboutView'

  inProcess: boolean

  _renderVersionDetail = () => null

  componentDidMount() {
    // getDeviceInfo().then((deviceInfo) => {
    //   // console.log('AboutView componentDidMount deviceInfo => ', deviceInfo)
    //   this.setState({ deviceInfo })
    // })
    // this._initVersionNumber()
  }
}

export default AboutView
