import React from 'react'
import { TouchableOpacity } from 'react-native'
// import Icon from 'xui/components/Icon'

// import Ionicons from 'react-native-vector-icons/Ionicons'
// import FontAwesome from 'react-native-vector-icons/FontAwesome'
// import MaterialIcons from 'react-native-vector-icons/MaterialIcons'
// import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons'
// import AntDesign from 'react-native-vector-icons/AntDesign'

import { COLORS } from 'x/config/styles'
import XText from './XText'

interface XExampleBtnProps {
  onPress: () => void
  textLable?: string
}

export default class XExampleBtn extends React.Component<XExampleBtnProps> {
  // constructor(props: XExampleBtnProps) {
  //   super(props)
  // }

  render() {
    const { onPress, textLable = 'ดูตัวอย่าง' } = this.props
    return (
      <TouchableOpacity
        onPress={() => onPress()}
        style={{
          borderBottomColor: COLORS.APP_MAIN,
          borderBottomWidth: 1,
          borderTopColor: COLORS.APP_MAIN,
          borderTopWidth: 1,
          borderLeftColor: COLORS.APP_MAIN,
          borderLeftWidth: 1,
          borderRightColor: COLORS.APP_MAIN,
          borderRightWidth: 1,
          borderRadius: 7,
          width: 80,
          alignItems: 'center',
          justifyContent: 'center',
          paddingTop: 4,
          paddingBottom: 4,
          backgroundColor: COLORS.BG_LIGHT_GREY,
        }}>
        <XText color={COLORS.APP_MAIN}>{textLable}</XText>
      </TouchableOpacity>
    )
  }
}
