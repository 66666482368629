import BaseUIResellerManageView from 'xui/modules/reseller/BaseUIResellerManageView'
import * as util from 'x/utils/util'

export default class ResellerManageView extends BaseUIResellerManageView {
  static displayName = 'ResellerManageView'

  static navigationOptions = {
    header: null,
  }

  goBack = () => {
    util.navGoBack(this.props)
  }
}
