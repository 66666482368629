import React from 'react'
import XContent from 'xui/components/XContent'
import XCustomHeader from 'xui/components/XCustomHeader'
import BaseStoreSettingAllPrintingShippingLabelView, {
  IStoreSettingListSections,
  IStoreSettingMenuList,
} from 'x/modules/store/storeSetting/BaseStoreSettingAllPrintingShippingLabelView'
import XContainer from 'xui/components/XContainer'
import HStack from 'xui/components/HStack'
import Box from 'xui/components/Box'
import _ from 'lodash'
import XText from 'xui/components/XText'
import { COLORS } from 'x/config/styles'
import { TouchableOpacity, Image } from 'react-native'
import XIcon from 'xui/components/XIcon'
import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'

export default abstract class BaseUIStoreSettingAllPrintingShippingLabelView extends BaseStoreSettingAllPrintingShippingLabelView {
  // static displayName = 'BaseUIStoreSettingXShippingView'
  // abstract goBack: () => void

  renderCustomHeader = () => (
    <XCustomHeader
      headerLeftProps={{
        backIcon: true,
        onPressItem: () => util.navGoBack(this.props),
      }}
      title={p.op.t('StoreSetting.MenuPrintingAllShippingLabel')}
    />
  )

  _renderSectionList = () => {
    const { STORE_SETTING_LIST_SECTIONS } = this.state
    if (_.isNil(STORE_SETTING_LIST_SECTIONS)) {
      return null
    }
    // console.log('STORE_SETTING_LIST_SECTIONS => ', STORE_SETTING_LIST_SECTIONS)
    return (
      <Box>
        {STORE_SETTING_LIST_SECTIONS.map((MENU: IStoreSettingListSections) => (
          // console.log('MENU => ', MENU)
          <Box key={MENU.txtTitle}>
            <HStack _light={{ bg: COLORS.FORM_PRIMARY_BG }} _dark={{ bg: 'gray.500' }} p='2'>
              <XText variant='active' bold>
                {MENU.txtTitle}
              </XText>
            </HStack>
            {MENU.data.map((item: IStoreSettingMenuList) => this._renderSectionItem(item))}
          </Box>
        ))}
      </Box>
    )
  }

  _renderSectionItem(item: IStoreSettingMenuList) {
    const { navigation } = this.props
    const { labelKey, navAction, iconImage, icon } = item
    return (
      <TouchableOpacity key={labelKey} style={{ flex: 1 }} onPress={() => navigation.dispatch(navAction)}>
        <HStack
          flex={1}
          px='2'
          py='3'
          _light={{ bg: 'white' }}
          _dark={{ bg: 'gray.800' }}
          alignItems='center'
          justifyContent='space-between'
          borderBottomColor='gray.200'
          borderBottomWidth='1'>
          <HStack space='2'>
            {_.isNil(icon) ? null : <XIcon active name={icon.name} family={icon.family} style={icon.style} />}
            {iconImage ? (
              <Image
                resizeMode='contain'
                style={{
                  width: 32,
                  tintColor: COLORS.TEXT_ACTIVE,
                  ...iconImage.style,
                }}
                source={iconImage.name}
              />
            ) : null}
            <XText variant='active' t={`StoreSetting.${labelKey}`} />
          </HStack>
          <XIcon name='arrow-forward' />
        </HStack>
      </TouchableOpacity>
    )
  }

  render() {
    return (
      <XContainer>
        <XContent>
          {this.renderCustomHeader()}
          {this._renderSectionList()}
        </XContent>
      </XContainer>
    )
  }
}
