import p from 'x/config/platform-specific'
import * as NavActions from 'x/utils/navigation'
import * as util from 'x/utils/util'
import { IAnyObject, IProductItemData, IProductListViewMyStoreNavParams, IProductListViewProps, IProductListViewState } from 'x/index'

import BaseUIProductListView from './BaseUIProductListView'

type IProductListViewMyStoreProps = IProductListViewProps<IProductListViewMyStoreNavParams>

class ProductListViewMyStore extends BaseUIProductListView<IProductListViewMyStoreProps, IProductListViewState> {
  static displayName = 'ProductListViewMyStore'

  // override
  isRequiredProductVariants = () => false

  // override
  isAddProductButtonVisible = () => false

  // override
  onPressProductItem = (item) => {
    this.navToProductView(item.data.id)
  }

  // override
  onAfterFetchSeachProducts = async (fetchedData: { count?: number; items: IProductItemData[] }, req: IAnyObject): Promise<void> => {
    if (fetchedData.count === 1 && req.offset === 0 && (req.isSKU || req.isUPC)) {
      const pd = fetchedData.items[0]
      this.navToProductView(pd.id)
    }
  }

  // implement abstract
  getHaveNoProductItemText = () => 'ไม่พบรายการสินค้า\nคุณสามารถสร้างสินค้าได้จากปุ่ม (+) ด้านบน'

  navToProductView = (product_id: number) => {
    const { navigation } = this.props
    const storeId = util.getNavParam(this.props, 'store_id')
    if (p.op.isWeb()) {
      // console.log('navigation.state => ', navigation.state)
      // console.log('navigation.dangerouslyGetParent() => ', navigation.dangerouslyGetParent())
      // remove ProductView Before select new one
      navigation.pop()
    }

    navigation.dispatch(
      NavActions.navToProductViewMyStore({
        store_id: storeId,
        product_id,
        onSuccessAddProductCallback: this.onSuccessAddProductCallback,
        onSuccessEditProductCallback: this.onSuccessEditProductCallback,
        onSuccessDeleteProductCallback: this.onSuccessDeleteProductCallback,
      })
    )
  }
}

export default ProductListViewMyStore
