import React, { Component, PureComponent } from 'react'

import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import _ from 'lodash'
import { List, Map, isImmutable } from 'immutable'
import { IOrderProductItemMap, IOrderVolumeDiscount, IOrderVolumeDiscountMap, IVolumeDiscountMap } from 'x/index'
import { isDiffAccuracy } from 'x/utils/util'
import FastImage from 'react-native-fast-image'
import time from 'x/utils/time'
import XModal from '../XModal'
import RangeAdjuster from '../RangeAdjuster'
import ForwardIcon from '../ForwardIcon'
import XCard from '../XCard'
import XText from '../XText'
// import { COLORS, STYLES } from 'x/config/styles'

// const { FONT_SIZE_NORMAL, FONT_SIZE_LARGER, FONT_SIZE_SMALLER } = STYLES
// const { TEXT_ACTIVE, TEXT_INACTIVE } = COLORS
// const INTL_STYLES: { [key: string]: ViewStyle | TextStyle } = {
//   txtActiveLargeBold: {
//     fontSize: FONT_SIZE_LARGER,
//     color: TEXT_ACTIVE,
//     fontWeight: 'bold',
//   },
//   txtActiveBold: {
//     fontSize: FONT_SIZE_NORMAL,
//     color: TEXT_ACTIVE,
//     fontWeight: 'bold',
//   },
//   txtActive: {
//     fontSize: FONT_SIZE_NORMAL,
//     color: TEXT_ACTIVE,
//   },
//   txtInactive: {
//     fontSize: FONT_SIZE_NORMAL,
//     color: TEXT_INACTIVE,
//   },
//   txtInactiveSmaller: {
//     fontSize: FONT_SIZE_SMALLER,
//     color: TEXT_INACTIVE,
//   },
//   colMiddleCenterFixed: {
//     flex: 0,
//     justifyContent: 'center',
//     alignItems: 'center',
//   },
//   colRightFixedIcon: {
//     flex: 0,
//     width: 34,
//     justifyContent: 'center',
//     alignItems: 'flex-end',
//   },
// }

interface IOrderVolumeDiscountListProps {
  order?: Map<string, any>
  // products: List<IOrderProductItemMap>
  // vdhs: List<IVolumeDiscountMap>
  // data: List<IOrderVolumeDiscountMap>
}
interface IOrderVolumeDiscountListState {
  isVisibleModalIndex: number
}

export default class OrderVolumeDiscountList extends Component<IOrderVolumeDiscountListProps, IOrderVolumeDiscountListState> {
  constructor(props) {
    super(props)
    this.state = {
      isVisibleModalIndex: -1,
    }
  }

  shouldComponentUpdate(prevProps: IOrderVolumeDiscountListProps, prevState: IOrderVolumeDiscountListState) {
    if (isDiffAccuracy(prevState, this.state, ['isVisibleModalIndex'])) {
      return true
    }
    return isDiffAccuracy(prevProps, this.props, ['order'])
  }

  // _keyExtractor = (item, index) => index.toString()

  _getAllProducts = (): List<IOrderProductItemMap> => {
    const { order } = this.props
    if (!Map.isMap(order)) {
      return List([])
    }
    const orderType = order.get('type')
    let products = order.get('products')
    if (orderType !== 3) {
      // for type 1, 2
      const suborders = order.get('suborders') || List([])
      suborders.forEach((subOrder) => {
        const subProducts = subOrder.get('products') || List([])
        products = products.concat(subProducts)
      })
    }
    return products
  }

  _getRelatedProducts = (ppIds: List<number>): List<IOrderProductItemMap> => {
    const products = this._getAllProducts()
    let relatedProduct = List([])
    products.forEach((pp) => {
      if (ppIds.findIndex((ppId) => ppId === pp.get('pp_id')) > -1) {
        relatedProduct = relatedProduct.push(pp)
      }
    })
    return relatedProduct
  }

  _getRelatedVdh = (vdhId: number): IVolumeDiscountMap | null => {
    const vdhs = this._getVdhs()
    if (List.isList(vdhs)) {
      for (let i = 0; i < vdhs.size; i++) {
        const focusedVdhId = vdhs.getIn([i, 'id'])
        if (focusedVdhId === vdhId) {
          return vdhs.get(i)
        }
      }
    }
    return null
  }

  _renderProductImage = (relatedProducts: List<IOrderProductItemMap>) => {
    if (relatedProducts.size === 0) {
      return null
    }
    let img = relatedProducts.getIn([0, 'tiny_uris', 0])
    if (!_.isString(img)) {
      img = relatedProducts.getIn([0, 'thumbnail_uris', 0])
    }
    if (!_.isString(img)) {
      img = relatedProducts.getIn([0, 'img_uris', 0])
    }
    if (!_.isString(img)) {
      return null
    }
    return <FastImage resizeMode={FastImage.resizeMode.cover} style={{ height: 64, width: 64, borderRadius: 8 }} source={{ uri: img }} />
  }

  _renderProductNames = (relatedProducts: List<IOrderProductItemMap>, highlighted?: boolean) => (
    // try {
    //   console.log('relatedProducts => ', relatedProducts.toJS())
    // } catch (error) {
    //   //
    // }

    <VStack w='full'>
      {relatedProducts.map((rp, idx) => (
        <HStack w='full' key={`rp-${idx.toString()}_${rp.get('pp_id')}`}>
          <XText numberOfLines={2}>{`${rp.get('qty')}x ${rp.get('name')}`}</XText>
          {rp.get('variant') && rp.get('variant') !== '' && (
            <XText numberOfLines={1} pl='1' variant='inactive'>
              {`(${rp.get('variant')})`}
            </XText>
          )}
        </HStack>
      ))}
    </VStack>
  )

  // if (relatedProducts.size === 1) {
  //   return (
  //     <VStack w='full'>
  //       <XText
  //         // variant={highlighted ? 'active' : 'inactive'} bold={highlighted}
  //         numberOfLines={2}>{`${relatedProducts.getIn([0, 'qty'])} x ${relatedProducts.getIn([0, 'name'])}`}</XText>
  //     </VStack>
  //   )
  // }
  // const ptName = relatedProducts.getIn([0, 'name'])
  // const ppNameWithCounts = relatedProducts.map((pp) => `${pp.get('qty')} x ${pp.get('variant')}`)
  // // relatedProducts.map((pd: IOrderProductItemMap) => {
  // //   const qty = pd.get('qty')
  // //   const name = pd.get('name')
  // //   const variant = pd.get('variant') || ''
  // //   // console.log('pd.toJS() => ', pd.toJS())
  // //   return (
  // //     <VStack>
  // //       <XText
  // //         w='full'
  // //         //  variant={highlighted ? 'active' : 'inactive'} bold={highlighted}
  // //         numberOfLines={2}>
  // //         {`${qty} x ${name}`}
  // //       </XText>
  // //       <XText variant='inactive'>{variant}</XText>
  // //     </VStack>
  // //   )
  // // })

  // // TODO :: code ชุดเดิม หากไม่มีปัญหาอะไรแล้ว เจออีกครั้งลบทิ้งได้ 28/01/2020 ARTID
  // return (
  //   <VStack>
  //     <XText numberOfLines={2}>{ptName}</XText>
  //     {ppNameWithCounts.map((ppNameWithCount) => (
  //       <XText variant='inactive'>{ppNameWithCount}</XText>
  //     ))}
  //   </VStack>
  // )

  _renderItem = ({ item, index, isLast = false }) => {
    const discountItem = Map.isMap(item) ? item : Map({})
    const ppIds = discountItem.get('pp_ids') || List([])
    // const vdhId = discountItem.get('vd_hid') || 0
    // const vdhId = discountItem.get('vd_history_id') || 0
    const relatedProducts = this._getRelatedProducts(ppIds)
    // const relatedVdh = this._getRelatedVdh(vdhId)

    const { order } = this.props
    if (!Map.isMap(order)) {
      return null
    }
    const orderType = order.get('type')
    // const txtOrderContext = orderType === 3 ? 'ซื้อ' : 'ขาย'
    const currentTotalAmount = discountItem.get('ta')
    const currentDiscountPercent = discountItem.get('dp')
    const currentDiscountAmount = discountItem.get('da')

    let txtDiscountAmount = null
    if (currentDiscountPercent) {
      txtDiscountAmount = `${currentDiscountPercent}%`
    } else if (currentDiscountAmount) {
      txtDiscountAmount = `฿${currentDiscountAmount}`
    }
    if (!txtDiscountAmount) {
      return null
    }

    // const injectTotalAmountColorStyle = {
    //   color: orderType === 3 ? COLORS.BRAND_Success : COLORS.BRAND_Danger,
    // }

    return (
      <OrderVolumeDiscountListItem
        key={`OVD_Item_${index.toString()}`}
        index={index}
        isLast={isLast}
        onPressItem={this._onPressItem}
        // products={relatedProducts}
        // vdh={relatedVdh}
        // data={item}
      >
        <HStack w='full' space='1'>
          <VStack flex={1}>{this._renderProductNames(relatedProducts)}</VStack>
          <VStack w='24' alignItems='center' justifyContent='center'>
            <XText
              fontSize='md'
              bold
              // style={[injectTotalAmountColorStyle]}
              variant={orderType === 3 ? 'success' : 'danger'}>{`฿${currentTotalAmount}`}</XText>
            <XText fontSize='xs' variant='inactive'>{`(${txtDiscountAmount} ต่อชิ้น)`}</XText>
          </VStack>
        </HStack>
      </OrderVolumeDiscountListItem>
    )
  }

  _onCloseModal = () => this.setState({ isVisibleModalIndex: -1 })

  _onPressItem = (selectedIndex: number) => {
    // console.log('OrderVolumeDiscountList _onPressItem selectedIndex => ', selectedIndex)
    // console.log(
    //   'OrderVolumeDiscountList _onPressItem this._isAvailableDiscount(selectedIndex) => ',
    //   this._isAvailableDiscount(selectedIndex),
    // )
    if (this._isAvailableDiscount(selectedIndex)) {
      this.setState({ isVisibleModalIndex: selectedIndex })
    }
  }

  _isAvailableDiscount = (index: number) => {
    const vdDiscounts = this._getVdDiscounts()
    if (index < 0 || vdDiscounts.size === 0) {
      return false
    }
    // return !_.isNil(vdDiscounts.getIn([index, 'vd_hid'])) // check available discount
    return !_.isNil(vdDiscounts.getIn([index, 'vd_history_id'])) // check available discount
  }

  _isVisibleModal = (): boolean => this._isAvailableDiscount(this.state.isVisibleModalIndex)

  _renderVdModal = () => {
    const { order } = this.props
    if (!Map.isMap(order)) {
      return null
    }
    const orderType = order.get('type')
    const txtTitle = `รายละเอียดส่วนลด${orderType === 3 ? 'ซื้อ' : 'ขาย'}ส่ง`
    return (
      <XModal hasBackButton title={txtTitle} visible={this._isVisibleModal()} onRequestClose={this._onCloseModal}>
        {this._renderModalContent()}
      </XModal>
    )
  }

  _renderModalContent = () => {
    const { isVisibleModalIndex } = this.state
    if (_.isNil(isVisibleModalIndex) || isVisibleModalIndex < 0) {
      return null
    }
    const discountItem = this._getVdDiscounts().get(isVisibleModalIndex)
    if (!Map.isMap(discountItem)) {
      return null
    }
    const ppIds = discountItem.get('pp_ids') || List([])
    // const vdhId = discountItem.get('vd_hid') || 0
    const vdhId = discountItem.get('vd_history_id') || 0
    const relatedProducts = this._getRelatedProducts(ppIds)
    const relatedVdh = this._getRelatedVdh(vdhId)
    // console.log('discountItem.toJS() => ', discountItem.toJS())

    if (!Map.isMap(relatedVdh) || !List.isList(relatedProducts) || relatedProducts.size === 0) {
      return null
    }
    const vdType = relatedVdh.get('type') || 0
    const vdVariants = relatedVdh.get('variants') || List([])
    const vdUpdatedAt = relatedVdh.get('updated_at')
    const readableVdUpdatedAt = time.convertServerDateTimeToReadableDateTimeText(vdUpdatedAt)

    // Check current step val
    // const currentDiscountPercent = discountItem.get('dp')
    // const currentDiscountAmount = discountItem.get('da')

    // let currentVdType
    // if (currentDiscountPercent) {
    //   currentVdType = 1
    // } else if (currentDiscountAmount) {
    //   currentVdType = 2
    // } else {
    //   currentVdType = 0
    // }
    const totalQty = relatedProducts.reduce((prevQty: number, product) => prevQty + product.get('qty'), 0)

    let currentStepIndex = -1
    if (vdType > 0) {
      // if (vdType === currentVdType && vdType > 0) {
      // const currentVal = currentVdType === 1 ? currentDiscountPercent : currentDiscountAmount
      for (let i = 0; i < vdVariants.size; i++) {
        // @ts-ignore
        const currVariant = vdVariants.get(i)
        const focusedMin = currVariant.has('min') ? parseInt(currVariant.get('min'), 10) : -1
        const focusedMax = currVariant.has('max') ? parseInt(currVariant.get('max'), 10) : -1
        if (focusedMin !== -1) {
          if (totalQty >= focusedMin) {
            if (focusedMax === -1 || totalQty <= focusedMax) {
              currentStepIndex = i
              break
            }
            // หาไฮไล สำหรับช่องสุดท้าย
            if (totalQty >= focusedMin && (focusedMax === 0 || _.isNil(focusedMax))) {
              currentStepIndex = i
              break
            }
          }
        }
      }
    }

    // Prepare to render VD's detail
    let usePrefixOrSuffix
    const prefix = '฿'
    const suffix = '%'
    if (vdType === 1) {
      usePrefixOrSuffix = prefix
    } else {
      usePrefixOrSuffix = suffix
    }

    // console.log('vdVariants.toJS() => ', vdVariants)

    return (
      <XCard w='full'>
        <XCard.Body>
          <HStack w='full' space='2'>
            {this._renderProductImage(relatedProducts)}
            <VStack flex={1}>{this._renderProductNames(relatedProducts, true)}</VStack>
          </HStack>
          {/* <CardItem> */}
          {/* <XText>DEBUG:: currentStepIndex = {currentStepIndex}</XText> */}
          {/* </CardItem> */}
          <HStack w='full' pt='2'>
            <RangeAdjuster
              mode='free'
              highlightedRowIndex={currentStepIndex}
              disabled
              metadata={[
                {
                  prefix,
                  suffix,
                  usePrefixOrSuffix,
                  key: 'val',
                  label: vdType === 1 ? 'ส่วนลด (บาทต่อชิ้น)' : 'ส่วนลด (%)',
                  span: 2,
                  defaultValue: '',
                  keyboardType: 'numeric',
                  filteredMethod: (newValue: string) => {
                    const newDiscount = !_.isNaN(newValue) && !_.isNil(newValue) ? parseFloat(newValue) : null
                    if (vdType === 1 && _.isNumber(newDiscount)) {
                      if (newDiscount >= 0 && newDiscount <= 100) {
                        return newDiscount
                      }
                      if (newDiscount > 100) {
                        return 100
                      }
                      return 0
                    }
                    if (vdType === 0 && _.isNumber(newDiscount)) {
                      if (newDiscount >= 0) {
                        return newDiscount
                      }
                      return 0
                    }
                    return 0
                  },
                },
              ]}
              // @ts-ignore
              data={vdVariants}
              // onChange={newVds => {
              //   onChangeProduct({ key: vdsKey, value: newVds })
              // }}
              // onChange={this._onChangeVolumeDiscount}
              labelAddButton='เพิ่มช่วงส่วนลด'
              labelLastEnd='ขึ้นไป'
            />
          </HStack>
          <HStack w='full' pt='2'>
            <XText variant='inactive' fontSize='xs'>{`*ตารางนี้เป็นข้อมูลเมื่อ ${readableVdUpdatedAt}`}</XText>
          </HStack>
        </XCard.Body>
      </XCard>
    )
  }

  _getVdDiscounts = (): List<IOrderVolumeDiscountMap> => {
    const { order } = this.props
    if (!Map.isMap(order)) {
      return List([])
    }
    const vd = order.get(`vd_discounts`) as List<IOrderVolumeDiscountMap>
    let noDiscount = true
    if (!_.isNil(vd)) {
      const vdToJS: IOrderVolumeDiscount[] = isImmutable(vd) ? vd.toJS() : vd
      vdToJS.map((data: IOrderVolumeDiscount) => {
        if (!_.isNil(data.da) || !_.isNil(data.dp)) {
          noDiscount = false
        }
      })
    }
    if (noDiscount) {
      return null
    }
    return _.isNil(order.get('vd_discounts')) ? List([]) : order.get('vd_discounts')
  }

  _getVdhs = (): List<IVolumeDiscountMap> => {
    const { order } = this.props
    if (!Map.isMap(order)) {
      return List([])
    }
    return _.isNil(order.get('vdhs')) ? List([]) : order.get('vdhs')
  }

  render() {
    const { order } = this.props
    if (!Map.isMap(order)) {
      return null
    }
    const vdDiscounts = this._getVdDiscounts()
    const vdhs = this._getVdhs()
    if (!List.isList(vdDiscounts) || vdDiscounts.size === 0 || !List.isList(vdhs) || vdhs.size === 0) {
      return null
    }
    // เอกออก เพราะส่วนลดไม่แสดง และไม่เข้าใจว่าตรงนี้มีไว้ทำไม เก็บไว้ก่อน ถ้าไม่มีปัญหา อนาคตค่อยมาลบ by artid
    // let dp = false
    // const newVDDiscounts = vdDiscounts.toJS()
    // newVDDiscounts.map(vd => {
    //   const currentDiscountPercent = vd.dp
    //   if (!_.isNil(currentDiscountPercent)) {
    //     dp = true
    //   }
    // })
    // console.log('dp : ', !dp)
    // if (!dp) {
    //   console.log('dp ^^ ')
    //   return null
    // }
    const orderName = order.get('name')
    const orderType = order.get('type')
    const txtOrderContext = orderType === 3 ? 'ซื้อ' : 'ขาย'
    const txtTitle = `สรุปส่วนลด${txtOrderContext}ส่ง สำหรับออเดอร์${txtOrderContext}#${orderName}`
    return (
      <XCard>
        <XCard.Header>
          <XText variant='active'>{txtTitle}</XText>
        </XCard.Header>
        <XCard.Body px='0' py='0'>
          {/* <FlatList data={vdDiscounts.toArray()} extraData={this.state} keyExtractor={this._keyExtractor} renderItem={this._renderItem} /> */}
          {vdDiscounts.toArray().map((vdi, idx, vdArr) => this._renderItem({ item: vdi, index: idx, isLast: idx === vdArr.length }))}
          {this._renderVdModal()}
        </XCard.Body>
      </XCard>
    )
  }
}

interface IOrderVolumeDiscountListItemProps {
  index: number
  onPressItem: (index: number) => void
  children?: JSX.Element
  isLast?: boolean
}

class OrderVolumeDiscountListItem extends PureComponent<IOrderVolumeDiscountListItemProps> {
  _onPressItem = () => {
    const { index, onPressItem } = this.props
    // console.log('OrderVolumeDiscountListItem _onPressItem index => ', index)
    if (_.isFunction(onPressItem)) {
      onPressItem(_.isNil(index) ? -1 : index)
    }
  }

  render() {
    const { index, children = null, isLast = false } = this.props
    if (_.isNil(index)) {
      return null
    }
    return (
      <XCard w='full' borderWidth='0' borderBottomWidth={isLast ? '0' : '1'} onPress={this._onPressItem}>
        <XCard.Body>
          <HStack w='full' alignItems='center'>
            <VStack flex={1}>{children}</VStack>
            <ForwardIcon inactive />
          </HStack>
        </XCard.Body>
      </XCard>
    )
  }
}
