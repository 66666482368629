/* eslint-disable react/no-unused-state */
import React from 'react'
import { Keyboard } from 'react-native'
import * as util from 'x/utils/util'
import { ISelectedStoreMap, IXScreenProps } from 'x/types'
import platformSpecific from 'x/config/platform-specific'
import _ from 'lodash'

export interface BaseStoreSettingGeneralProps extends IXScreenProps {
  editingStore: any
  onChangeEditingStore: (x: any) => any
  submitEditing: (x: any) => any
  selectedStore: ISelectedStoreMap
}

export interface BaseStoreSettingGeneralState {
  uploading: boolean
  description: string
  name: string
  isLoading: boolean
}
export default abstract class BaseStoreSettingGeneral extends React.Component<BaseStoreSettingGeneralProps, BaseStoreSettingGeneralState> {
  // eslint-disable-next-line react/no-unused-class-component-methods
  imgManagerRef: React.RefObject<any>

  protected constructor(props) {
    super(props)

    this.state = {
      uploading: false,
      description: '',
      name: '',
      isLoading: false,
    }
    // eslint-disable-next-line react/no-unused-class-component-methods
    this.imgManagerRef = React.createRef()
  }

  componentDidMount() {
    this._loadImagesToImgMgr()
    const { editingStore } = this.props
    this.setState({
      name: editingStore.get('name') || '',
      description: editingStore.get('description') || '',
    })
  }

  _loadImagesToImgMgr = () => {
    const { editingStore } = this.props
    const cover_uri = editingStore.get('cover_uri') || null // use for initial image
    const cover_thumbnail_uri = editingStore.get('cover_thumbnail_uri') || null // use for initial image

    if (this.imgManagerRef && this.imgManagerRef.current.loadImageUrls) {
      // const { selectedProduct } = this.props
      const cover_uris = [cover_uri]
      const cover_thumbnail_uris = [cover_thumbnail_uri]
      this.imgManagerRef.current.loadImageUrls({
        p: cover_uris,
        t: cover_thumbnail_uris,
      })
    }
  }

  startLoading = async () => {
    await util.setStatePromise(this, { isLoading: true })
  }

  stopLoading = async () => {
    await util.setStatePromise(this, { isLoading: false })
  }

  submitEditingForm = async () => {
    Keyboard.dismiss()
    const { onChangeEditingStore, selectedStore } = this.props
    const { name, isLoading } = this.state
    if (isLoading) {
      return null
    }
    await this.startLoading()
    const requiredFields = ['name', 'description']
    // const requiredDisplays = ['ชื่อร้าน', 'ภาพปก', 'คำอธิบายร้าน']
    // @ts-ignore
    const body: {
      name: string
      description: string
      cover_uri: string
      cover_thumbnail_uri: string
    } = {}
    if (name.length < 2) {
      platformSpecific.op.showConfirmationOkOnly('กรุณาระบุชื่อร้านของคุณ', 'มีตัวอักษร 2 ตัวอักษรขึ้นไป')
      await this.stopLoading()
      return
    }
    // body.name = name
    // body.description = description

    for (let i = 0; i < requiredFields.length; i++) {
      const key = requiredFields[i]
      const oldValue = selectedStore.get(key)
      const newValue = this.state[key]
      if (oldValue !== newValue) {
        body[key] = newValue
      }
    }

    if (this.imgManagerRef && this.imgManagerRef.current.submitUploadImages) {
      await util.setStatePromise(this, { uploading: true })
      const { p, t, allUploaded } = await this.imgManagerRef.current.submitUploadImages()
      await util.setStatePromise(this, { uploading: false })
      if (allUploaded) {
        onChangeEditingStore({ cover_uri: p[0] })
        onChangeEditingStore({ cover_thumbnail_uri: t[0] })
        await util.delay(1000) // ทดไว้สำหรับยิง action เปลี่ยนรูปภาพ
      } else {
        platformSpecific.op.showConfirmationOkOnly('เกิดข้อผิดพลาด', 'กรุณาลองอัพโหลดรูปใหม่อีกครั้ง')
        await this.stopLoading()
        return
      }
    }

    if (this.props.editingStore.get('cover_uri') && this.props.editingStore.get('cover_thumbnail_uri')) {
      body.cover_uri = this.props.editingStore.get('cover_uri')
      body.cover_thumbnail_uri = this.props.editingStore.get('cover_thumbnail_uri')
    }
    await this._handleSave(body)
  }

  _handleSave = async (reqBody) => {
    if (!_.isEmpty(reqBody)) {
      const { submitEditing, selectedStore } = this.props
      const store_id = selectedStore.get('id') || null
      if (store_id) {
        const response = await new Promise((resolve) => {
          submitEditing({ body: { store_id, ...reqBody }, successCallback: resolve, failedCallback: () => resolve(null) })
        })
        if (response) {
          // console.log('response => ', response)
          await this.stopLoading()
          return response
        }
      } else {
        platformSpecific.op.showToast('เกิดข้อผิดพลาด ไม่พบข้อมูลร้านฉัน หรือมีการเข้าถึงที่ไม่ถูกต้อง', 'warning', 2000)
        await this.stopLoading()
      }
    } else {
      platformSpecific.op.showToast('ไม่ได้มีการแก้ไขค่าใดๆ', 'warning', 2000)
      await this.stopLoading()
    }
    await this.stopLoading()
    return null
  }
}
