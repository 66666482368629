import React, { Component } from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'
import { Button, useColorMode } from 'native-base'
import Box from 'xui/components/Box'

import _ from 'lodash'
import * as auth from 'x/utils/authentication'
import ActionSheet from 'xui/components/singleton/ActionSheet'
import api from 'x/utils/api'
import p from 'x/config/platform-specific'
import CONS from 'x/config/constants'
import { MODE, getConfig } from 'x/config/mode'
import * as util from 'x/utils/util'
// import StorybookUI from 'xui/storybook'
import { isIphoneX } from '../utils/iphone-x-helper'
import NativeBaseStoryView from './NativeBaseStoryView'

// import AsyncStorage from '@react-native-async-storage/async-storage'
// import * as NavActions from 'x/utils/navigation'

const styles = StyleSheet.create({
  circle: {
    position: 'absolute',
    bottom: isIphoneX() ? 10 : 5,
    right: isIphoneX() ? 10 : 5,
    width: 12,
    height: 12,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
    borderRadius: 6,
    backgroundColor: '#fff',
    zIndex: 999,
  },
  menu: {
    backgroundColor: 'white',
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
  },
  menuItem: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    borderTopWidth: 1,
    borderTopColor: '#eee',
    padding: 10,
    height: 60,
  },
  menuItemText: {
    fontSize: 20,
  },
})

enum OPTS {
  LOGIN_WITH_SEED,
  CLEAR_ASYNC_STORAGE,
  // LOG_ASYNC_STORAGE,
  OPEN_NATIVEBASE_STORY,
  OPEN_STORYBOOK,
  TOGGLE_NATIVEBASE_DARKMODE,
  CANCEL,
}

const OPT_LABEL_MAPS = {
  [OPTS.LOGIN_WITH_SEED]: 'Login With Seed (Must Reload)',
  [OPTS.CLEAR_ASYNC_STORAGE]: 'Clear AsyncStorage (เฉพาะใน contants ที่ไม่ dynamic)',
  // [OPTS.LOG_ASYNC_STORAGE]: 'Log AsyncStorage',
  [OPTS.OPEN_NATIVEBASE_STORY]: 'Open NativeBase Story',
  [OPTS.OPEN_STORYBOOK]: 'Open Storybook',
  [OPTS.TOGGLE_NATIVEBASE_DARKMODE]: 'Toggle Dark Mode',
  [OPTS.CANCEL]: 'Cancel',
}

const OPTION_LABELS = Object.keys(OPT_LABEL_MAPS).map((optKey) => OPT_LABEL_MAPS[optKey])

const STORAGE_KEYS = [CONS.AUTHENTICATION_STORAGE_KEY, CONS.FACEBOOK_AUTH_STORAGE_KEY, ...Object.keys(CONS.STORAGE_KEYS)]

interface IDeveloperMenuProps {
  //
}

interface IDeveloperMenuState {
  isStoryBookVisible?: boolean
  isNativeBaseStoryVisible?: boolean
  appApiRoot?: string
  colorToggleCount: number
}

const ColorManager = ({ toggleCount }) => {
  const { toggleColorMode } = useColorMode()
  const [lastCount, setLastCount] = React.useState(0)
  React.useEffect(() => {
    if (typeof toggleCount === 'number' && toggleCount > lastCount) {
      toggleColorMode()
      setLastCount(toggleCount)
    }
  }, [toggleCount, lastCount, toggleColorMode])

  return null
}

export default class DeveloperMenu extends Component<IDeveloperMenuProps, IDeveloperMenuState> {
  static displayName = 'DeveloperMenu'

  inProcess?: boolean

  constructor(props: IDeveloperMenuProps) {
    super(props)
    this.state = {
      isStoryBookVisible: false,
      isNativeBaseStoryVisible: false,
      appApiRoot: getConfig().app_apiRoot,
      colorToggleCount: 0,
    }
  }

  _showDeveloperMenu = async () => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true

    ActionSheet.show(
      {
        options: OPTION_LABELS,
        cancelButtonIndex: OPTS.CANCEL,
      },
      this._handlePressMenu
    )

    await util.delay(500)
    this.inProcess = false
  }

  _handlePressMenu = async (index: string | number) => {
    // @ts-ignore
    // eslint-disable-next-line radix
    const selectedIdx = parseInt(index)
    switch (selectedIdx) {
      case OPTS.LOGIN_WITH_SEED:
        await this._loginWithSeed()
        break

      case OPTS.CLEAR_ASYNC_STORAGE:
        // eslint-disable-next-line no-case-declarations
        const clearStoragePromises: Promise<unknown>[] = []
        for (let i = 0; i < STORAGE_KEYS.length; i++) {
          clearStoragePromises.push(p.op.storageClear(STORAGE_KEYS[i]))
        }
        await Promise.all(clearStoragePromises)
        break

      // case OPTS.LOG_ASYNC_STORAGE: {
      //   // const asKeys = await AsyncStorage.getAllKeys()
      //   const asKeys = STORAGE_KEYS
      //   const asObj = {}
      //   for (let i = 0; i < asKeys.length; i++) {
      //     const asKey = asKeys[i]
      //     // asObj[asKey] = await AsyncStorage.getItem(asKey)
      //     asObj[asKey] = await p.op.storageGet(asKey)
      //   }
      //   console.warn('(╯°□°）╯ Log AsyncStorage')
      //   // console.log(asObj)
      //   break
      // }

      case OPTS.OPEN_NATIVEBASE_STORY: {
        this._openNativeBaseStory()
        break
      }
      case OPTS.OPEN_STORYBOOK: {
        this._openStoryBook()
        break
      }
      case OPTS.TOGGLE_NATIVEBASE_DARKMODE: {
        const { colorToggleCount } = this.state
        this.setState({ colorToggleCount: colorToggleCount + 1 })
        break
      }

      default:
        console.warn('(╯°□°）╯ Do Nothing')
    }
  }

  _loginWithSeed = async () => {
    const response = await api.get('lists')
    if (!response || !_.has(response, 'users')) {
      return
    }
    // console.log('_loginWithSeed__response_: ', response)
    // let data = _.cloneDeep(response.users)
    // data.forEach((d, i) => {
    //   data[i]['label'] = d.id + ': ' + d.username
    //   data[i]['left'] = 'contact'
    // })
    // @ts-ignore
    const userCount = response.users.length
    const lengthOptions = []
    let i = 1
    while (i < userCount && i < 200) {
      const begin = i
      const end = i + 19
      lengthOptions.push(`${begin} - ${end}`)
      i += 20
    }
    lengthOptions.push('ยกเลิก')
    const lengthIndex = await new Promise((resolveLength) => {
      ActionSheet.show(
        {
          options: lengthOptions,
          cancelButtonIndex: lengthOptions.length - 1,
          title: 'เลือก...',
        },
        (buttonIndex) => {
          // @ts-ignore
          // eslint-disable-next-line radix
          const idx = parseInt(buttonIndex)
          if (idx >= lengthOptions.length - 1) {
            resolveLength(-1)
          }
          resolveLength(idx)
        }
      )
    })

    if (lengthIndex < 0 || lengthIndex > 20) {
      return
    }

    // @ts-ignore
    const beginUserIndex = lengthIndex * 20
    const endUserIndex = beginUserIndex + 20

    // @ts-ignore
    const users = response.users.slice(beginUserIndex, endUserIndex)

    const txtUserOptions = users.map((usr) => `${usr.id} - ${usr.username}`)
    txtUserOptions.push('ยกเลิก')

    const buttonIndex = await new Promise((resolveUserIdx) => {
      ActionSheet.show(
        {
          options: txtUserOptions,
          cancelButtonIndex: txtUserOptions.length - 1,
          title: 'เลือก...',
        },
        resolveUserIdx
      )
    })

    // @ts-ignore
    // eslint-disable-next-line radix
    const idx = parseInt(buttonIndex)
    if (!_.isNumber(idx)) {
      return
    }

    const selectedUser = users[idx]
    if (selectedUser && selectedUser.id && selectedUser.token) {
      await auth.setAuthenticationToken(selectedUser.token)
      // FIXME:
      // this._dispatchNavigationAction(NavActions.switchToAppLauncher)
    }
  }

  // _loginWithX = async () => {
  //   const txtPaste = await Clipboard.getString()
  //   // console.log('txtPaste => ', txtPaste)
  //   if (txtPaste.length < 14) {
  //     return
  //   }
  //   const strArr = txtPaste.split('#')
  //   if (strArr.length !== 2) {
  //     return
  //   }

  //   const id = parseInt(strArr[0])
  //   const secret = strArr[1]

  //   let txtFrom = `${settings.app.API_ROOT}/auth/login`
  //   txtFrom = txtFrom.replace(/\//g, '\\/')
  //   const x = xtoken(txtFrom, secret, id)
  //   // console.log('DeveloperMenu.id => ', id)
  //   // console.log('DeveloperMenu.token => ', x)
  //   await auth.setAuthenticationToken(x)
  //   // FIXME:
  //   // this._dispatchNavigationAction(NavActions.switchToAppLauncher)
  // }

  _openNativeBaseStory = () => this.setState({ isNativeBaseStoryVisible: true })

  _closeNativeBaseStory = () => this.setState({ isNativeBaseStoryVisible: false })

  _openStoryBook = () => this.setState({ isStoryBookVisible: true })

  _closeStoryBook = () => this.setState({ isStoryBookVisible: false })

  _renderNativeBaseStoryView = () => {
    const { isNativeBaseStoryVisible } = this.state
    if (!isNativeBaseStoryVisible) {
      return null
    }
    return (
      <Box position='absolute' top='8' _web={{ top: '0' }} right='0' bottom='0' left='0'>
        <Button position='absolute' top='0' right='0' zIndex={999} onPress={this._closeNativeBaseStory}>
          CLOSE
        </Button>
        <NativeBaseStoryView />
      </Box>
    )
  }

  _renderDevMenuButton = () => <TouchableOpacity style={styles.circle} onPress={this._showDeveloperMenu} />

  _renderStoryBook = () => {
    const { isStoryBookVisible } = this.state
    if (!isStoryBookVisible) {
      return null
    }
    return (
      <Box pt='32' bg='white' position='absolute' top='0' left='0' right='0' bottom='0' flex={1}>
        {/* <StorybookUI /> */}
        <Button position='absolute' top='12' right='0' variant='outline' onPress={this._closeStoryBook}>
          Close Storybook
        </Button>
      </Box>
    )
  }

  _renderDevTools = () => {
    const { colorToggleCount } = this.state
    return (
      <>
        {this._renderDevMenuButton()}
        {this._renderNativeBaseStoryView()}
        {this._renderStoryBook()}
        <ColorManager toggleCount={colorToggleCount} />
      </>
    )
  }

  render() {
    // 1st saftey:: Check DEV constants
    if (!__DEV__ || MODE !== 'dev') {
      return null
    }

    // 2nd saftey:: Check api endpoint must contain "dev" text
    const { appApiRoot } = this.state
    if (!appApiRoot || !appApiRoot.includes('dev')) {
      return null
    }

    return this._renderDevTools()
  }
}
