import { getStoreSelected, getSelectedProduct, getMkpChannels } from '../../redux/selectors'

export function getState(state) {
  return {
    selectedStore: getStoreSelected(state),
    selectedProduct: getSelectedProduct(state),
    mkpChannels: getMkpChannels(state),
  }
}

export function getDispatch(dispatch) {
  return {
    dispatch,
    // unlinkProduct: bindActionCreators(something.somemethod?)
  }
}
