import React from 'react'
import HStack from 'xui/components/HStack'

import XInput from 'xui/components/XInput'
import XText from 'xui/components/XText'
import VStack from 'xui/components/VStack'
import XCustomHeader from 'xui/components/XCustomHeader'
import XOverlay from 'xui/components/XOverlay'
import XCheckButton from 'xui/components/XCheckButton'
import XButton from 'xui/components/XButton'
import * as util from 'x/utils/util'

import { IVariantFilterOptions } from 'x/types'

interface IVariantSearchPanelProps {
  visible: boolean
  onRequestClose: () => void
  onSubmit: (filterOptions: IVariantFilterOptions) => void
}

const VariantSearchPanel: React.FC<IVariantSearchPanelProps> = ({ visible, onRequestClose, onSubmit }) => {
  const [isFilterNAME, setIsFilterNAME] = React.useState(true)
  const [isFilterSKU, setIsFilterSKU] = React.useState(true)
  const [isFilterUPC, setIsFilterUPC] = React.useState(true)
  const [filterKeyword, setFilterKeyword] = React.useState('')

  const _onSubmit = async () => {
    const filterKeys = []
    if (isFilterNAME) {
      filterKeys.push('name')
    }

    if (isFilterSKU) {
      filterKeys.push('sku')
    }

    if (isFilterUPC) {
      filterKeys.push('upc')
    }

    onSubmit({
      filterKeys,
      keyword: filterKeyword,
    })

    await util.delay(100)

    onRequestClose()
  }

  const _setIsFilterNAME = (arg) => {
    if (!isFilterSKU && !isFilterUPC) {
      return
    }
    setIsFilterNAME(!arg.checked)
  }

  const _setIsFilterSKU = (arg) => {
    if (!isFilterNAME && !isFilterUPC) {
      return
    }
    setIsFilterSKU(!arg.checked)
  }

  const _setIsFilterUPC = (arg) => {
    if (!isFilterNAME && !isFilterSKU) {
      return
    }
    setIsFilterUPC(!arg.checked)
  }

  return (
    <XOverlay visible={visible} onRequestClose={onRequestClose}>
      <XCustomHeader
        title='กรองตัวเลือกสินค้า'
        headerRightProps={{
          closeIcon: true,
          onPressItem: onRequestClose,
        }}
      />
      <VStack w='full' p='2' space='1'>
        <VStack w='full' space='0.5'>
          <XText variant='inactive'>ค้นหาใน...</XText>
          <HStack w='full' space='1'>
            <XCheckButton label='ชื่อ' checked={isFilterNAME} onPress={_setIsFilterNAME} />
            <XCheckButton label='SKU' checked={isFilterSKU} onPress={_setIsFilterSKU} />
            <XCheckButton label='UPC' checked={isFilterUPC} onPress={_setIsFilterUPC} />
          </HStack>
        </VStack>

        <HStack w='full' space='1' alignItems='center'>
          <XText w='80px' variant='inactive'>
            {'คำค้นหา '}
          </XText>
          <XInput
            autoFocus
            onSubmitEditing={(evt) => {
              console.log('onSubmitEditing evt.nativeEvent => ', evt.nativeEvent)
              _onSubmit()
            }}
            flex={1}
            placeholder='เช่น สีแดง'
            value={filterKeyword}
            onChangeText={(newText) => setFilterKeyword(newText)}
          />
        </HStack>

        <XButton mt='2' onPress={_onSubmit}>
          ค้นหา
        </XButton>
      </VStack>
    </XOverlay>
  )
}

export default VariantSearchPanel
