import React from 'react'
import { Image, View } from 'react-native'
import { STYLES, COLORS } from 'x/config/styles'
import FAIcon from 'react-native-vector-icons/FontAwesome'
import { Spinner } from 'native-base'
import Icon from 'xui/components/Icon'

export function getXsellyLogo(width: number, height: number) {
  return (
    <Image
      // resizeMode='cover'
      style={{
        width,
        height,
        // marginRight: 4,
      }}
      source={require('../img/about/xselly_logo_340.png')}
    />
  )
}

export function getShopeeLogo(width: number, height: number) {
  return (
    <Image
      // resizeMode='cover'
      style={{
        width,
        height,
        // marginRight: 4,
      }}
      source={require('../img/about/logo_shopee.png')}
    />
  )
}

export function getLazadaLogo(width: number, height: number) {
  return (
    <Image
      // resizeMode='cover'
      style={{
        width,
        height,
        // marginRight: 4,
      }}
      source={require('../img/about/logo_lazada.png')}
    />
  )
}

export const VIEW_HEIGHT_TEN = <View style={{ height: 10 }} />
export const VIEW_WIDTH_TEN = <View style={{ width: 10 }} />

export const CHECK_BOX_ICON = (
  <FAIcon
    style={{
      flex: 1,
      fontSize: STYLES.FONT_ICON_NORMAL,
      color: COLORS.APP_MAIN,
    }}
    name='check-square-o'
  />
)
export const UN_CHECK_BOX_ICON = (
  <FAIcon
    style={{
      flex: 1,
      fontSize: STYLES.FONT_ICON_NORMAL,
      color: COLORS.APP_MAIN,
    }}
    name='square-o'
  />
)

export const RADIO = (checked = false) => (
  <Icon
    style={{
      fontSize: STYLES.FONT_ICON_NORMAL,
      color: COLORS.APP_MAIN,
      textAlign: 'left',
    }}
    name={checked ? 'radio-button-on' : 'radio-button-off'}
  />
)

export const VIEW_LOADING = (loadingWidth: number, loadingHeight: number) => (
  <View
    style={{
      // zIndex: 999,
      position: 'absolute',
      width: loadingWidth,
      height: loadingHeight,
      backgroundColor: 'rgba(52, 52, 52, 0.7)',
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      marginBottom: 0,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
    }}>
    <Spinner
      color={COLORS.APP_MAIN}
      size='lg'
      style={{
        width: STYLES.FONT_SIZE_SMALLER,
        height: 34,
        alignSelf: 'center',
        justifyContent: 'center',
        // paddingTop: loadingHeight / 2 - 5,
      }}
    />
  </View>
)

export const CARD = ({ mainStyle = {}, children }) => (
  <View
    style={[
      {
        margin: 0,
        marginLeft: 2,
        marginRight: 2,
        padding: 0,
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 8,
        paddingBottom: 8,
        elevation: 3,
        marginBottom: 8,
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 1.5,
        shadowColor: '#000',
        borderBottomColor: '#ccc',
        borderRightColor: '#ccc',
        flex: 1,
        backgroundColor: COLORS.WHITE,
      },
      mainStyle,
    ]}>
    {children}
  </View>
)
