import { connect } from 'react-redux'
// import { bindActionCreators } from 'redux'
// import { NavigationActions } from 'react-navigation'
import DailySalesReportView from './DailySalesReportView'
import { getState, getDispatch } from 'x/modules/report/BaseReportConnect'
import { getDailySalesReport, getSubscription } from 'x/redux/selectors'

export default connect(
  state => {
    return {
      ...getState(state),
      subscription: getSubscription(state),
      // chartData: getDailySalesReport(state),
    }
  },
  dispatch => getDispatch(dispatch),
)(DailySalesReportView)
