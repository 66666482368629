import React from 'react'
import { IBoxProps } from 'native-base'
import { StyleSheet, Modal as RNModal, ModalProps } from 'react-native'
import { getComputedCustomStyledProps } from 'x/config/nativebase/makeStyledComponent'
import theme from 'x/config/nativebase/theme'

export type IModalProps = IBoxProps & ModalProps

const Modal = React.forwardRef<RNModal, IModalProps>((props, parentRef) => {
  const { style = {}, ...restRNProps } = props
  const { styleFromProps, restProps } = getComputedCustomStyledProps(restRNProps, 'Box')
  return (
    // @ts-ignore
    <RNModal
      // @ts-ignore
      ref={parentRef}
      style={[styleFromProps, StyleSheet.flatten(style)]}
      {...restProps}
    />
  )
})

Modal.displayName = 'Modal'

// @ts-ignore
Modal.defaultProps = { theme }

// @ts-ignore
export default Modal as React.FC<IModalProps>
