import React from 'react'

import { IOrderListDisplayMode, IOrderListDisplayOption } from 'x/index'

import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import XIconButton from '../XIconButton'
import XOverlay from '../XOverlay'
import XCustomHeader from '../XCustomHeader'
import XCard from '../XCard'
import XRadioList from '../XRadioList'
import XNumericInput from '../XNumericInput'
import XText from '../XText'
import XButton from '../XButton'

// type IOrderListDisplayMode = 'list' | 'page'
// export type IOrderListDisplayMode = 'list' | 'page-10' | 'page-30' | 'page-custom'
// export type IOrderListDisplayOption = IXSegmentOption<IOrderListDisplayMode>
interface IOrderListDisplayModeControlResult {
  displayMode: IOrderListDisplayMode
  pageLimit?: number
}
interface IOrderListDisplayModeControlProps {
  disabled?: boolean
  // initialIndex?: number
  initialMode?: IOrderListDisplayMode
  initialPageLimit?: number
  // onChange: (newOpt: IOrderListDisplayOption, newOptIndex: number) => void
  onSubmit: (result: IOrderListDisplayModeControlResult) => void
}

const ORDER_LIST_DISPLAY_OPTIONS: IOrderListDisplayOption[] = [
  {
    label: 'ไม่แบ่งหน้า',
    key: 'list',
    // value: 'list',
  },
  {
    label: 'แบ่งหน้าละ 10',
    key: 'page',
    // value: 'page-10',
    value: 10,
  },
  {
    label: 'แบ่งหน้าละ 30',
    key: 'page',
    value: 30,
    // value: 'page-30',
  },
  {
    label: 'แบ่งหน้าละ 50',
    key: 'page',
    value: 50,
  },
  {
    label: 'แบ่งหน้าละ...',
    key: 'page',
    // value: 'page-custom',
  },
]

const PAGE_LIMIT_MIN = 10
const PAGE_LIMIT_MAX = 100

function getInitialRadioOptionIndex(initMode?: IOrderListDisplayMode, initLimit?: number) {
  // if (!initMode || initMode === 'list')
  //   return 0
  if (initMode === 'page' && initLimit === 10) return 1
  if (initMode === 'page' && initLimit === 30) return 2
  if (initMode === 'page' && initLimit === 50) return 3
  if (initMode === 'page') return 4
  return 0
}

function getInitialCustomPageLimit(initMode?: IOrderListDisplayMode, initLimit?: number) {
  if (initMode === 'page') return initLimit
  return initLimit
}

const OrderListDisplayModeControl: React.FC<IOrderListDisplayModeControlProps> = ({
  disabled = false,
  // onChange,
  // initialIndex,
  initialMode,
  initialPageLimit,
  onSubmit,
}) => {
  const [isSettingVisible, setIsSettingVisible] = React.useState(false)
  const [selectedDisplayModeRadioIndex, setSelectedDisplayModeRadioIndex] = React.useState(
    getInitialRadioOptionIndex(initialMode, initialPageLimit)
  )
  const [editingDisplayModeRadioIndex, setEditingDisplayModeRadioIndex] = React.useState(
    getInitialRadioOptionIndex(initialMode, initialPageLimit)
  )
  const [selectedCustomPageLimit, setSelectedCustomPageLimit] = React.useState(getInitialCustomPageLimit(initialMode, initialPageLimit))
  const [editingCustomPageLimit, setEditingCustomPageLimit] = React.useState(getInitialCustomPageLimit(initialMode, initialPageLimit))
  // const [optionOrdersDisplayModeSegmentAtIndex, setOptionOrdersDisplayModeSegmentAtIndex] = React.useState(initialIndex)

  // React.useEffect(() => {

  //   if (initialMode && initialMode === 'list') {
  //       setSelectedDisplayModeRadioIndex(0)
  //   }
  //   if (initialMode === 'page' && initialPageLimit === 10) {
  //     setSelectedDisplayModeRadioIndex(1)
  //   }
  // }, [])

  // const _onSegmentChangeOrdersDisplayMode = (opt: IOrderListDisplayOption, idx: number) => {
  //   setOptionOrdersDisplayModeSegmentAtIndex(idx)
  //   onChange(opt, idx)
  // }

  // const _handleChangeToListMode = () => {
  //   setOptionOrdersDisplayModeSegmentAtIndex(0)
  //   onChange(DISPLAY_OPTIONS[0], 0)
  // }
  // const _handleChangeToPageMode = () => {
  //   setOptionOrdersDisplayModeSegmentAtIndex(1)
  //   onChange(DISPLAY_OPTIONS[1], 1)
  // }

  const _openSettings = () => {
    setIsSettingVisible(true)
  }

  const _closeSettings = () => {
    setIsSettingVisible(false)
    if (editingCustomPageLimit !== selectedCustomPageLimit) {
      setEditingCustomPageLimit(selectedCustomPageLimit)
    }
    if (selectedDisplayModeRadioIndex !== editingDisplayModeRadioIndex) {
      setEditingDisplayModeRadioIndex(selectedDisplayModeRadioIndex)
    }
  }

  const _onDisplayModeRadioChange = (opt: IOrderListDisplayOption, idx: number) => {
    if (editingDisplayModeRadioIndex !== idx) {
      if (idx !== 4 && editingCustomPageLimit !== PAGE_LIMIT_MAX) {
        setEditingCustomPageLimit(PAGE_LIMIT_MAX)
      }
      setEditingDisplayModeRadioIndex(idx)
    }
  }

  const _onCustomPageLimitChange = (newLimit: number) => {
    setEditingCustomPageLimit(newLimit)
  }

  const _onPressSubmit = () => {
    const pLimit =
      editingDisplayModeRadioIndex === 4 ? editingCustomPageLimit : ORDER_LIST_DISPLAY_OPTIONS[editingDisplayModeRadioIndex].value
    const result: IOrderListDisplayModeControlResult = { displayMode: ORDER_LIST_DISPLAY_OPTIONS[editingDisplayModeRadioIndex].key }
    if (pLimit) {
      result.pageLimit = pLimit
    }
    onSubmit(result)
    setSelectedDisplayModeRadioIndex(editingDisplayModeRadioIndex)
    setSelectedCustomPageLimit(editingCustomPageLimit)
    setIsSettingVisible(false)
  }

  const isExceededCustomPageLimit = editingCustomPageLimit < PAGE_LIMIT_MIN || editingCustomPageLimit > PAGE_LIMIT_MAX

  return (
    <HStack>
      <XIconButton
        disabled={disabled}
        // _icon={{ color: 'primary.400' }}
        // variant='outline'
        // iconVariant='inactive'
        name='settings'
        onPress={_openSettings}
      />
      <XOverlay visible={isSettingVisible} contentStyle={{ width: 320 }} onRequestClose={_closeSettings}>
        <XCard w='full' minH='80'>
          <VStack flex={1} w='full'>
            <XCustomHeader title='ตั้งค่าการแสดงรายการออเดอร์' headerRightProps={{ closeIcon: true, onPressItem: _closeSettings }} />
            {/* <HStack w='full' p='2' space='1' alignItems='center'> */}
            {/* <XText variant='inactive'>แสดงรายการแบบ</XText> */}
            <VStack flex={1} w='full' p='3'>
              {/* <XSegment<IOrderListDisplayMode>
                  options={DISPLAY_OPTIONS} // list mode | page mode
                  selectedIndex={optionOrdersDisplayModeSegmentAtIndex}
                  onSegmentChange={_onSegmentChangeOrdersDisplayMode}
                /> */}
              <XRadioList
                title='แสดงรายการแบบ...'
                selectedIndex={editingDisplayModeRadioIndex}
                options={ORDER_LIST_DISPLAY_OPTIONS}
                onRadioChange={_onDisplayModeRadioChange}
              />
              <VStack w='full' p='1' minH='16' alignItems='center'>
                {editingDisplayModeRadioIndex === 4 ? (
                  <XNumericInput
                    h='9'
                    w='56'
                    minValue={PAGE_LIMIT_MIN}
                    maxValue={PAGE_LIMIT_MAX}
                    value={editingCustomPageLimit}
                    onChangeValue={_onCustomPageLimitChange}
                  />
                ) : null}
                {isExceededCustomPageLimit ? (
                  <XText variant='danger'>{`กรุณาระบุเลขระหว่าง ${PAGE_LIMIT_MIN}-${PAGE_LIMIT_MAX}`}</XText>
                ) : null}
              </VStack>
            </VStack>
            {/* </HStack> */}
            <VStack w='full' p='1'>
              <XButton disabled={isExceededCustomPageLimit} isDisabled={isExceededCustomPageLimit} onPress={_onPressSubmit}>
                ยืนยัน
              </XButton>
            </VStack>
          </VStack>
        </XCard>
      </XOverlay>
    </HStack>
  )

  // if (optionOrdersDisplayModeSegmentAtIndex === 1) {
  //   return (
  //     <XIconButton isDisabled={disabled} disabled={disabled} name='page-multiple' family='Foundation' onPress={_handleChangeToListMode} />
  //   )
  // }

  // return <XIconButton isDisabled={disabled} disabled={disabled} name='list' family='Foundation' onPress={_handleChangeToPageMode} />

  // return (
  //   <HStack w='full' p='2' space='1' alignItems='center'>
  //     <XText variant='inactive'>แสดงรายการแบบ</XText>
  //     <VStack flex={1}>
  //       <XSegment<IOrderListDisplayMode>
  //         options={DISPLAY_OPTIONS} // list mode | page mode
  //         selectedIndex={optionOrdersDisplayModeSegmentAtIndex}
  //         onSegmentChange={_onSegmentChangeOrdersDisplayMode}
  //       />
  //     </VStack>
  //   </HStack>
  // )
}

OrderListDisplayModeControl.displayName = 'OrderListDisplayModeControl'
// OrderListDisplayModeControl.defaultProps = {
//   initialIndex: 0,
// }

export default OrderListDisplayModeControl
