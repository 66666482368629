import React from 'react'
import { ColorMode, NativeBaseProvider as NativeBaseDefaultProvider, NativeBaseProviderProps, StorageManager } from 'native-base'

import p from 'x/config/platform-specific'
import NativeBaseTheme from 'x/config/nativebase/theme'

const NativeBaseProvider: React.FC<NativeBaseProviderProps> = (props) => {
  const [fontSizes, setFontSizes] = React.useState(NativeBaseTheme.fontSizes)

  // React.useEffect(() => {
  //   let isUnmounted = false

  //   if (!isUnmounted) {
  //     setCurrentTheme({
  //       ...currentTheme,
  //       increaseFontSizes: _increaseFontSizes,
  //       decreaseFontSizes: _decreaseFontSizes,
  //     })
  //   }

  //   return () => {
  //     isUnmounted = true
  //   }
  // }, [])

  function _increaseFontSizes() {
    _setNewFontSizes('plus')
  }
  function _decreaseFontSizes() {
    _setNewFontSizes('minus')
  }
  function _setNewFontSizes(op: 'plus' | 'minus') {
    const newFontSizes = { ...fontSizes }
    const fontSizeKeys = Object.keys(newFontSizes)
    for (let i = 0; i < fontSizeKeys.length; i++) {
      const fKey = fontSizeKeys[i]
      const fValue = newFontSizes[fKey] as number
      if (op === 'plus') {
        newFontSizes[fKey] = fValue + 2
      }
      if (op === 'minus') {
        newFontSizes[fKey] = fValue - 2
      }
    }
    setFontSizes(newFontSizes)
  }

  const colorModeManager: StorageManager = {
    get: async () => {
      try {
        const val = await p.op.storageGet('@my-app-color-mode', false)
        return val === 'dark' ? 'dark' : 'light'
      } catch (e) {
        console.log(e)
        return 'light'
      }
    },
    set: async (value: ColorMode) => {
      try {
        await p.op.storageSet('@my-app-color-mode', value, false)
      } catch (e) {
        console.log(e)
      }
    },
  }

  return (
    <NativeBaseDefaultProvider
      {...props}
      // theme={currentTheme}
      // colorModeManager={colorModeManager}
      theme={{
        ...NativeBaseTheme,
        fontSizes,
        increaseFontSizes: _increaseFontSizes,
        decreaseFontSizes: _decreaseFontSizes,
      }}
    />
  )
}

export default NativeBaseProvider
