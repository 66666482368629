import React, { Component } from 'react'
import BaseUICustomOrderView from 'xui/modules/customer/BaseUICustomOrderView'

export default class CustomOrderView extends BaseUICustomOrderView {
  // static displayName = 'CustomOrderView'
  static navigationOptions = {
    header: null,
  }
  // static navigationOptions = ({ navigation }) => {
  //   const { state, goBack } = navigation
  //   const { saveBtn = null, onChangeData = false } = state.params
  //   // let inProcess = state.params ? state.params.inProcess : false
  //   // let handleSaveBtn = state.params ? state.params.handleSaveBtn : null
  //   return {
  //     headerRight: !onChangeData ? (
  //       <View style={{ width: 50 }} />
  //     ) : (
  //       <Button onPress={() => goBack(state.key)}>
  //         <Text
  //           style={{
  //             flex: 1,
  //             width: '100%',
  //             minWidth: 44,
  //             paddingHorizontal: 4,
  //             fontSize: STYLES.FONT_SIZE_NORMAL,
  //             fontWeight: 'bold',
  //             color: COLORS.APP_MAIN,
  //             textAlign: 'center',
  //           }}>
  //           {`ยกเลิก`}
  //         </Text>
  //       </Button>
  //     ),

  //     headerLeft: !onChangeData ? (
  //       <Button onPress={() => navigation.goBack(navigation.state.key)}>
  //         <BackIcon />
  //       </Button>
  //     ) : (
  //       <Button
  //         transparent={true}
  //         // disabled={submitting}
  //         style={{ backgroundColor: 'transparent' }}
  //         onPress={saveBtn}>
  //         <Text
  //           style={{
  //             flex: 1,
  //             width: '100%',
  //             minWidth: 44,
  //             paddingHorizontal: 4,
  //             fontSize: STYLES.FONT_SIZE_NORMAL,
  //             fontWeight: 'bold',
  //             color: COLORS.APP_MAIN,
  //             textAlign: 'center',
  //           }}>
  //           {`บันทึก`}
  //         </Text>
  //       </Button>
  //     ),
  //     title: 'ตั้งค่าหน้า ดูออเดอร์',
  //   }
  // }
}
