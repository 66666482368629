import BaseUIReportResellerView from 'xui/modules/report/BaseUIReportResellerView'
import * as util from '../../utils/util-web'

export default class ReportResellerView extends BaseUIReportResellerView {
  _handleOnDownloadFileByPlatform = async (url: string, fileName: string): Promise<void> => {
    if (url) {
      await util.downloadFileFromUrl({ url, fileName })
    }
  }
}
