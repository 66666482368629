import React from 'react'
import { delay } from 'x/utils/util'

import _ from 'lodash'
import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'
import { ActionApiParams, ISelectedStoreMap, IMKPChannelDetail, ILoadingInProgressMap, IApiOptions, IXScreenProps } from 'x/types'
import { isImmutable } from 'immutable'
import api from 'x/utils/api'
import * as NavActions from 'x/utils/navigation'

interface IBaseChannelListViewProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
  updateNewChannelList: (newChannelList: IMKPChannelDetail[]) => void
  getShopeeAuthUrl: (params: ActionApiParams) => void
  getChannels: (params: ActionApiParams) => void
  subscription: any
  loadingInProgress: ILoadingInProgressMap

  getMkpReAuthUrl: (params: ActionApiParams) => void
}

interface IBaseChannelListViewState {
  isRenderWebView: boolean
  urlWebView: string
  isLoading: boolean
  loadingWidth: number
  loadingHeight: number
  refreshingControl: boolean
  channelList: IMKPChannelDetail[]
  selectedChannel: IMKPChannelDetail

  isShowOverlayInputApiKey: boolean
  inputLineApiKeyText: string
}

export default abstract class BaseChannelListView extends React.Component<IBaseChannelListViewProps, IBaseChannelListViewState> {
  // abstract async handleSubmitForm(response: any): Promise<void>
  // abstract didMountCheckPermissionForPhone?: () => void
  // validationSchema: Yup.ObjectSchema<any>
  // imgManagerIDRef: React.RefObject<any>
  webview: React.RefObject<any>

  constructor(props) {
    super(props)
    this.state = {
      isRenderWebView: false,
      urlWebView: '',
      isLoading: false,
      loadingHeight: 0,
      loadingWidth: 0,
      channelList: null,
      selectedChannel: null,
      refreshingControl: false,

      isShowOverlayInputApiKey: false,
      inputLineApiKeyText: '',
    }
    this.webview = React.createRef()
    // this.imgManagerIDRef = React.createRef()
  }

  async componentDidMount() {
    await this._checkMKPReAuth()
    await this._didMountChannelList()
  }

  _checkMKPReAuth = async () => {
    // const { navigation } = this.props
    // const { state } = navigation
    // const { params } = state
    const params = util.getNavParams(this.props)
    if (params && params.channelReAuth) {
      await this._callAuthChannel(params.channelReAuth)
    }
  }

  _didMountChannelList = async () => {
    const { selectedStore } = this.props
    const channelsStatus = util.channelsStatus()
    let channelList = null
    let isLoading = true
    if (channelsStatus === `COMPLETED`) {
      channelList = selectedStore.get(`channels`)
      isLoading = false
    }
    await util.setStatePromise(this, {
      channelList: !_.isNil(channelList) && isImmutable(channelList) ? channelList.toJS() : !_.isNil(channelList) ? channelList : null,
      isLoading,
    })

    // มี channelList แล้ว ไม่ต้องทำอะไรต่อ
    if (!_.isNil(channelList)) {
      return
    }
    let channels = null
    // loading channels
    if (channelsStatus === 'LOADING') {
      for (let i = 0; i <= 10; i++) {
        const { loadingInProgress } = this.props
        const isChannelLoading = loadingInProgress.has('channels')
        if (!isChannelLoading) {
          channels = this.props.selectedStore.get('channels')
          i = 11
        }
        await delay(2000)
      }
    }
    await util.setStatePromise(this, {
      channelList: !_.isNil(channels) && isImmutable(channels) ? channels.toJS() : !_.isNil(channels) ? channels : null,
      isLoading: false,
    })
  }

  // _callBackChannelsLoadingStatus = (status: `LOADING` | `COMPLETED` | `DONT_HAVE_CHANNELS`) => {
  //   console.log(`_callBackChannelsLoadingStatus `, status)
  // }

  _asyncIfLoadingCompletedCallBack = async (channels: IMKPChannelDetail[]) => {
    // console.log(`_asyncIfLoadingCompletedCallBack `, channels)
  }

  _callBackURL = (url: string, channel: IMKPChannelDetail) => {
    // console.log(`URL : `, url)
    if (_.isString(url)) {
      util.setStatePromise(this, { isRenderWebView: true, urlWebView: url, selectedChannel: channel })
      if (p.op.isWeb()) {
        // window.open(url)
        window.location.replace(url)
      }
    }
  }

  _onOrdersDonutPress = async (donutData) => {
    // console.log(`donutData `, donutData)
    const buttonLabels = []
    const autoMethods = []

    buttonLabels.push('บันทึกรับชำระ')
    autoMethods.push(() => p.op.showConfirmationOkOnly(``, `OKAY !`))

    buttonLabels.push('ยกเลิก')

    p.op.showActionSheet(
      {
        options: buttonLabels,
        cancelButtonIndex: buttonLabels.length - 1,
        title: 'ดำเนินการอัตโนมัติ...',
      },
      (buttonIndex) => {
        const idx = _.isString(buttonIndex) ? parseInt(buttonIndex) : buttonIndex
        const selectedMethod = autoMethods[idx]
        if (_.isFunction(selectedMethod)) {
          selectedMethod()
        }
      }
    )

    setTimeout(() => {}, 200)
  }

  _startLoading = async () => {
    await util.setStatePromise(this, { isLoading: true })
  }

  _stopLoading = async () => {
    await util.setStatePromise(this, { isLoading: false })
  }

  _callAuthChannel = async (channel: IMKPChannelDetail) => {
    // if (isReAuth) {

    const { mkp_id } = channel
    // IF NOT LINE
    if (mkp_id !== 4) {
      await this._startLoading()
      const { getMkpReAuthUrl, selectedStore } = this.props
      const body: { [key: string]: any } = {
        store_id: selectedStore.get(`id`),
        mkp_ch_id: channel.id,
      }
      const response: { auth_url: string } = await new Promise((resolveSave) => {
        getMkpReAuthUrl({
          body,
          // @ts-ignore successCallback แดง
          successCallback: resolveSave,
          failedCallback: () => resolveSave(null),
        })
      })
      // console.log(`response `, response)
      if (!_.isNil(response) && !_.isNil(response.auth_url)) {
        // ถ้าเป็น web ให้กระโดดไปยังหน้าเว็บ
        if (p.op.isWeb()) {
          this._callBackURL(response.auth_url, channel)
          return
        }
        util.setStatePromise(this, { selectedChannel: channel, isRenderWebView: true, urlWebView: response.auth_url })
      }
      await this._stopLoading()
    }
    // IF LINE
    if (mkp_id === 4) {
      const { selectedStore } = this.props
      const { inputLineApiKeyText } = this.state
      const apiOptions = {
        showSpinner: true,
      }
      if (inputLineApiKeyText === '') {
        p.op.showConfirmationOkOnly('', 'กรุณาระบุ LINE SHOPPING API KEY ')
        return
      }
      const body: { [key: string]: any } = {
        store_id: selectedStore.get('id'),
        mkp_ch_id: channel.id,
        api_key: inputLineApiKeyText,
      }
      const res = await api.postV2(api.POST_UPDATE_MKP_CHANNEL_DETAIL, body, apiOptions)
      // console.log('res => ', res)
      if (res.channel) {
        this._callBackUpdateChannel(res.channel)
        await util.setStatePromise(this, {
          selectedChannel: null,
          inputLineApiKeyText: '',
        })
        p.op.showToast('อัพเดตข้อมูลเรียบร้อยแล้ว', 'success')
      }
    }
    // }
  }

  _showOverlayInputApiKey = () => {
    this.setState({
      isShowOverlayInputApiKey: true,
    })
  }

  _closeOverlayInputApiKey = () => {
    this.setState({
      isShowOverlayInputApiKey: false,
    })
  }

  _getChannels = async () => {
    await this._startLoading()
    const { getChannels, selectedStore } = this.props
    const body: { [key: string]: any } = {
      store_id: selectedStore.get('id'),
    }
    const response: { auth_url: string } = await new Promise((resolveSave) => {
      getChannels({
        body,
        // @ts-ignore successCallback แดง
        successCallback: resolveSave,
        failedCallback: () => resolveSave(null),
      })
    })
    // console.log(`response `, response)
    // @ts-ignore
    if (!_.isNil(response) && !_.isNil(response.channels)) {
      // @ts-ignore
      util.setStatePromise(this, { channelList: response.channels })
    }
    await this._stopLoading()
  }

  _callBackUpdateChannelList = (channels: IMKPChannelDetail[]) => {
    const { updateNewChannelList } = this.props
    if (_.isFunction(updateNewChannelList)) {
      updateNewChannelList(channels)
    }
    util.setStatePromise(this, {
      channelList: channels,
    })
  }

  _callBackUpdateChannel = (newChannel: IMKPChannelDetail) => {
    // console.log('newChannel => ', newChannel)
    const { channelList } = this.state
    // console.log('channelList => ', channelList)
    const newChannelList = []
    channelList.forEach((ch: IMKPChannelDetail) => {
      if (ch.id === newChannel.id) {
        newChannelList.push(newChannel)
      } else {
        newChannelList.push(ch)
      }
    })
    this.setState({
      channelList: newChannelList,
    })
    // util.setStatePromise(this, {
    //   channelList: newChannelList,
    // })
  }

  // สำหรับอัพ shopee v1 ไป v2 (ใช้ชั่วคราว)
  _authV2Provisory = async (channel: IMKPChannelDetail) => {
    const { selectedStore } = this.props
    const apiOptions: IApiOptions = {
      showSpinner: true,
    }
    const body: { [key: string]: any } = {
      store_id: selectedStore.get('id'),
      mkp_ch_id: channel.id,
    }

    const response: { auth_url: string } = await api.postV2(api.POST_UPGRADE_SHOPEE_AUTH_TO_V_2, body, apiOptions)
    // console.log('response => ', response)
    if (response.auth_url) {
      this._callBackURL(response.auth_url, channel)
    }
  }

  _navToReauthLazada = (mkp_ch_id: number) => {
    const { navigation, selectedStore } = this.props
    navigation.dispatch(
      NavActions.navToReauthLazadaView({
        store_id: selectedStore.get('id'),
        mkp_ch_id,
        // callBack: (channel: IMKPChannelDetail) => this._callBackUpdateChannel(channel),
        callBackFromLazadaReauth: (channel: IMKPChannelDetail) => this._getChannels(),
      })
    )
  }

  _navToReauthShpify = (mkp_ch_id: number) => {
    const { navigation, selectedStore } = this.props
    navigation.dispatch(
      NavActions.navToReauthShopifyView({
        store_id: selectedStore.get('id'),
        mkp_ch_id,
        // callBack: (channel: IMKPChannelDetail) => this._callBackUpdateChannel(channel),
        callBackFromShopifyReauth: (channel: IMKPChannelDetail) => this._getChannels(),
      })
    )
  }
}
