import React, { forwardRef } from 'react'
// import { Pressable, TouchableOpacity } from 'react-native'
import { IBoxProps } from 'native-base'
import Box from './Box'
// import XButton from './XButton'
import Divider from './Divider'
import XPressable from './XPressable'

type INormalCard = IBoxProps
// type IXCardProps = IPressableProps & INormalCard
interface IXCardProps extends INormalCard {
  disabled?: boolean
  onPress?: () => void | Promise<void>
}

interface IXCardSubComponents {
  Header: React.FC<INormalCard>
  Body: React.FC<INormalCard>
  Footer: React.FC<INormalCard>
}

// const PressableCard: React.FC<IXCardProps> = (props) => {
//   const { onPress, ...restProps } = props as IPressableProps
//   if (typeof onPress === 'function') {
//     return <Pressable _pressed={{ opacity: 60 }} onPress={onPress} {...restProps} />
//   }
//   return <Box {...(props as INormalCard)} />
// }

const XCardBase: React.FC<IXCardProps> = (props) => {
  const { _light = {}, _dark = {}, ...restProps } = props
  return (
    <Box
      borderWidth='1'
      bg='white'
      borderTopColor='gray.300'
      borderLeftColor='gray.300'
      borderBottomColor='gray.400'
      borderRightColor='gray.400'
      _light={{
        // bg: 'white',
        // borderTopWidth: borderWidth,
        // borderLeftWidth: borderWidth,
        // borderBottomWidth: borderWidth,
        // borderRightWidth: borderWidth,
        // borderTopColor: 'gray.300',
        // borderLeftColor: 'gray.300',
        // borderBottomColor: 'gray.400',
        // borderRightColor: 'gray.400',
        ..._light,
      }}
      _dark={{
        bg: 'gray.800',
        // borderTopWidth: borderWidth,
        // borderLeftWidth: borderWidth,
        // borderBottomWidth: borderWidth,
        // borderRightWidth: borderWidth,
        borderTopColor: 'dark.400',
        borderLeftColor: 'dark.300',
        borderBottomColor: 'dark.500',
        borderRightColor: 'dark.600',
        ..._dark,
      }}
      // bg={useColorModeValue('white', 'gray.800')}
      // borderTopColor={useColorModeValue('gray.300', 'dark.400')}
      // borderLeftColor={useColorModeValue('gray.300', 'dark.300')}
      // borderBottomColor={useColorModeValue('gray.400', 'dark.500')}
      // borderRightColor={useColorModeValue('gray.400', 'dark.600')}
      borderRadius='lg'
      flexWrap='wrap'
      overflow='hidden'
      // _light={{
      //   bg: 'white',
      //   _text: { color: 'white' },
      //   borderTopColor: 'gray.300',
      //   borderLeftColor: 'gray.300',
      //   borderBottomColor: 'gray.500',
      //   borderRightColor: 'gray.600',
      //   ..._light,
      // }}
      // _dark={{
      //   bg: 'gray.700',
      //   borderTopColor: 'dark.300',
      //   borderLeftColor: 'dark.300',
      //   borderBottomColor: 'dark.500',
      //   borderRightColor: 'dark.600',
      //   ..._dark,
      // }}
      flexDirection='column'
      {...restProps}
    />
  )
}

const XCard: React.FC<IXCardProps> & IXCardSubComponents = (props) => {
  const { disabled, onPress, ...restProps } = props
  if (typeof onPress === 'function' && !disabled) {
    return (
      <XPressable disabled={disabled} onPress={onPress}>
        {/* {({ isHovered, isPressed }) => {
          const injectUtilityProps: IXCardProps = {}
          if (isHovered) {
            // injectUtilityProps.bg = NB.HC.BG.PRIMARY()
            injectUtilityProps.opacity = 0.8
          }
          if (isPressed) {
            // injectUtilityProps.bg = NB.HC.BG.ACCENT()
            injectUtilityProps.opacity = 0.6
          }
          return <XCardBase {...injectUtilityProps} {...restProps} />
        }} */}
        <XCardBase {...restProps} />
      </XPressable>
    )
  }

  return <XCardBase {...restProps} />
}
XCard.displayName = 'XCard'

const Header: React.FC<INormalCard> = forwardRef((props, ref) => (
  <>
    <Box
      // @ts-ignore
      ref={ref}
      flexDirection='column'
      px='3'
      py='2'
      pt='3'
      {...props}
    />
    <Divider mb='1' />
  </>
))
XCard.Header = Header
XCard.Header.displayName = 'XCardHeader'

const Body: React.FC<INormalCard> = forwardRef((props, ref) => (
  <Box
    // @ts-ignore
    ref={ref}
    w='full'
    flexDirection='column'
    px='3'
    py='2'
    {...props}
  />
))
XCard.Body = Body
XCard.Header.displayName = 'XCardBody'

const Footer: React.FC<INormalCard> = forwardRef((props, ref) => (
  <>
    <Divider mt='1' />
    <Box
      // @ts-ignore
      ref={ref}
      flexDirection='column'
      px='3'
      py='2'
      pb='3'
      {...props}
    />
  </>
))
XCard.Footer = Footer
XCard.Header.displayName = 'XCardFooter'

export default XCard
