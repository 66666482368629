import { FlatList, Button, Text, Heading, useTheme, Card, useColorMode, useColorModeValue, Center, Switch, HStack } from 'native-base'
import Box from 'xui/components/Box'
import React from 'react'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import XCard from 'xui/components/XCard'
import Icon from 'xui/components/Icon'

const LOREM_IPSUM =
  'Lorem Ipsum คืออะไร? Lorem Ipsum คือ เนื้อหาจำลองแบบเรียบๆ ที่ใช้กันในธุรกิจงานพิมพ์หรืองานเรียงพิมพ์ มันได้กลายมาเป็นเนื้อหาจำลองมาตรฐานของธุรกิจดังกล่าวมาตั้งแต่ศตวรรษที่ 16 เมื่อเครื่องพิมพ์โนเนมเครื่องหนึ่งนำรางตัวพิมพ์มาสลับสับตำแหน่งตัวอักษรเพื่อทำหนังสือตัวอย่าง Lorem Ipsum อยู่ยงคงกระพันมาไม่ใช่แค่เพียงห้าศตวรรษ แต่อยู่มาจนถึงยุคที่พลิกโฉมเข้าสู่งานเรียงพิมพ์ด้วยวิธีทางอิเล็กทรอนิกส์'

function ColorPalette({ color }) {
  const { colors } = useTheme()
  return (
    <Box m='2'>
      <Center>
        <Text fontSize='md'>{`${color} palette`}</Text>
      </Center>
      <Box>
        <FlatList
          scrollEnabled={false}
          numColumns={5}
          data={Object.keys(colors[color])}
          keyExtractor={(item, index) => index.toString()}
          renderItem={({ item, index }) => (
            <Box p='1' minW='10' alignItems='center' bgColor={`${color}.${item}`}>
              <Text fontSize='xs' color={index >= 5 ? 'white' : 'black'}>
                {item}
              </Text>
            </Box>
          )}
        />
      </Box>
    </Box>
  )
}

function ColorPalettes() {
  return (
    <Center mt='8' p='2'>
      <Heading size='md'>Color Palettes</Heading>
      <Center flexDirection='row' flexWrap='wrap'>
        <ColorPalette color='primary' />
        <ColorPalette color='secondary' />
        <ColorPalette color='info' />
        <ColorPalette color='success' />
        <ColorPalette color='warning' />
        <ColorPalette color='danger' />
        <ColorPalette color='error' />
        <ColorPalette color='gray' />
        {/* ทุก gray ใน default theme เข้าใจว่าเหมือนกันหมด */}
        {/* <ColorPalette color='muted' /> */}
        {/* <ColorPalette color='warmGray' /> */}
        {/* <ColorPalette color='trueGray' /> */}
        <ColorPalette color='dark' />
      </Center>
    </Center>
  )
}

function ButtonExample() {
  return (
    <>
      <Center mt='8'>
        <Heading size='md'>Button Color Scheme</Heading>
      </Center>
      <Button m='2'>Primary</Button>
      <Button m='2' colorScheme='secondary'>
        Secondary
      </Button>
      <Button m='2' size='sm' isDisabled>
        Disabled
      </Button>

      <Center mt='8'>
        <Heading size='md'>Default Variants</Heading>
      </Center>
      <Button m='2' variant='ghost'>
        ghost
      </Button>
      <Button m='2' variant='link'>
        link
      </Button>
      <Button m='2' variant='outline'>
        outline
      </Button>
      <Button m='2' variant='solid'>
        solid
      </Button>
      <Button m='2' variant='subtle'>
        subtle
      </Button>

      <Center mt='8'>
        <Heading size='md'>Button Usage Example</Heading>
      </Center>
      <Button my='2'>XSelly Primary</Button>
      <Button my='2' mx='auto'>
        XSelly Primary mx=auto
      </Button>
      <Button my='2' isDisabled>
        XSelly Primary isDisabled
      </Button>
      <Button my='2' isLoading>
        XSelly Primary isLoading
      </Button>

      <Button my='2' variant='outline'>
        XSelly Secondary
      </Button>
      <Button
        my='2'
        variant='outline'
        _light={{
          bg: 'white',
        }}
        _dark={{
          bg: 'black',
        }}
        // bg={useColorModeValue('white', 'black')}
      >
        XSelly Secondary bg=white/black
      </Button>
      <Button my='2' mx='auto' variant='outline'>
        XSelly Secondary mx=auto
      </Button>
      <Button my='2' mx='auto' borderWidth='3' variant='outline'>
        XSelly Secondary mx=auto borderWidth=3
      </Button>
      <Button my='2' mx='auto' colorScheme='gray' variant='outline'>
        XSelly Secondary mx=auto colorScheme=gray.500
      </Button>
      <Button my='2' mx='auto' colorScheme='gray' _text={{ color: 'gray.400' }} variant='outline'>
        XSelly Secondary mx=auto colorScheme=gray _text.color=gray.400
      </Button>

      <Button my='2' variant='outline' isDisabled>
        XSelly Secondary isDisabled
      </Button>
      <Button my='2' variant='outline' isLoading>
        XSelly Secondary isLoading
      </Button>

      <Button leftIcon={<Icon name='checkmark' />} my='2'>
        XSelly Primary with Left Icon
      </Button>
      <Button rightIcon={<Icon name='edit' type='FontAwesome' />} my='2'>
        XSelly Primary with Right Icon
      </Button>
      <Button startIcon={<Icon name='send' type='FontAwesome' />} my='2'>
        XSelly Primary with Start Icon
      </Button>
      <Button endIcon={<Icon name='user' type='FontAwesome' />} my='2'>
        XSelly Primary with End Icon
      </Button>

      <Button.Group
        variant='outline'
        direction='column'

        // mx={{
        //   base: 'auto',
        // }}
      >
        <Text>Button Group with column direction</Text>
        <Button _stack={{ flex: 1, justifyContent: 'space-between' }} rightIcon={<Icon name='arrow-forward' />}>
          Outline Button 1 _stack.justifyContent=space-between
        </Button>
        <Button _stack={{ flex: 1, justifyContent: 'space-between' }} rightIcon={<Icon name='arrow-forward' />}>
          Outline Button 2 _stack.justifyContent=space-between
        </Button>
        <Button _stack={{ flex: 1, justifyContent: 'space-between' }} rightIcon={<Icon name='arrow-forward' />}>
          Outline Button 3 _stack.justifyContent=space-between
        </Button>
      </Button.Group>
    </>
  )
}

function TextExample() {
  const { fontSizes, increaseFontSizes, decreaseFontSizes } = useTheme()
  return (
    <Center mt='8' p='2'>
      <Center>
        <Heading size='md'>Normal Text sizes</Heading>
      </Center>
      <Box alignContent='center' justifyContent='center'>
        <Text>{JSON.stringify(fontSizes)}</Text>
        <Box flexDirection='row' alignContent='center' justifyContent='center'>
          <Button mx='2' onPress={() => decreaseFontSizes()} variant='outline'>
            --Aa
          </Button>
          <Button mx='2' onPress={() => increaseFontSizes()} variant='outline'>
            Aa++
          </Button>
        </Box>
      </Box>
      <Card my='2'>
        <Text fontSize='xs'>{`xs Text: ${LOREM_IPSUM}`}</Text>
      </Card>
      <Card my='2'>
        <Text fontSize='sm'>{`sm Text: ${LOREM_IPSUM}`}</Text>
      </Card>
      <Card my='2'>
        <Text fontSize='md'>{`md Text: ${LOREM_IPSUM}`}</Text>
      </Card>
      <Card my='2'>
        <Text fontSize='lg'>{`lg Text: ${LOREM_IPSUM}`}</Text>
      </Card>
    </Center>
  )
}

function ResponsiveExample() {
  return (
    <Box>
      <Center>
        <Heading size={['sm', 'md', 'md', 'md']}>Responsive Example</Heading>
      </Center>
      <Center
        flex={1}
        // px={['5', '5', '3', '2', '2']}
        py='1'
        bg='gray.200'
        // (1.)Prepare flex wrap for Responsive Items
        flexDirection='row' // เรียงแนวนอนจากซ้ายไปขวา
        flexWrap='wrap' // เพื่อให้ item ล้นลงไปแถวล่างได้
      >
        <CardItemInfo title='Item 1' desc={LOREM_IPSUM} />
        <CardItemInfo title='Item 2' desc={LOREM_IPSUM} />
        <CardItemInfo title='Item 3' desc={LOREM_IPSUM} />
        <CardItemInfo title='Item 4' desc={LOREM_IPSUM} />
        <CardItemInfo title='Item 5' desc={LOREM_IPSUM} />
      </Center>
    </Box>
  )
}

function CardItemInfo({ title, desc }) {
  return (
    <Box
      // m='2'
      // p='1'
      // (3.) Padding between Card Items
      px={['5', '5', '3', '2', '2']}
      py={['2', '2', '3', '3', '3']}
      // (2.) Declare Item's responsive Width
      // วางแผนจะดึงแสดงจำนวน items = 1,1,3,4,5 ตามลำดับ ตามขนาดความกว่างจอ
      w={['full', 'full', '1/3', '1/4', '1/5']}>
      <Box
        bg='white'
        // FIXME: borderWidth ธรรมดา ในเว็บไม่สามารถใช้งานได้ ไม่รู้ว่าทำไม
        // Ref: https://github.com/GeekyAnts/NativeBase/issues/4208
        // borderWidth='1'
        borderTopWidth='1'
        borderLeftWidth='1'
        borderBottomWidth='2'
        borderRightWidth='2'
        // borderColor='gray.500'
        borderTopColor='gray.300'
        borderLeftColor='gray.300'
        borderBottomColor='gray.500'
        borderRightColor='gray.600'
        // rounded={['xs', 'sm', 'md', 'lg', 'xl']}
        rounded={{ base: 'md', md: 'xl' }}>
        <Box px='2' p='4'>
          <Heading>{title}</Heading>
        </Box>
        <Box px='2' p='4'>
          <Text style={{ color: 'green' }} color='red.500'>
            {desc}
          </Text>
        </Box>
      </Box>
    </Box>
  )
}

function XCardExample() {
  return (
    <Box>
      <Center>
        <Heading size={['sm', 'md', 'md', 'md']}>XCard Example</Heading>
      </Center>
      <Center
        flex={1}
        // px={['5', '5', '3', '2', '2']}
        py='1'
        // bg='gray.200'
        // (1.)Prepare flex wrap for Responsive Items
        flexDirection='row' // เรียงแนวนอนจากซ้ายไปขวา
        flexWrap='wrap' // เพื่อให้ item ล้นลงไปแถวล่างได้
      >
        <XCardItem title='XCard Item 1' desc={LOREM_IPSUM} footer='XCard Footer 1' />
        <XCardItem title='XCard Item 2' desc={LOREM_IPSUM} />
        <XCardItem desc={`XCard Item 3 :: ${LOREM_IPSUM}`} footer='XCard Footer 3' />
        <XCardItem desc={`XCard Item 4 (Only Body) ${LOREM_IPSUM}`} />
        <XCardItem title='XCard Item 5' desc={LOREM_IPSUM} />
      </Center>
    </Box>
  )
}

function XCardItem(props) {
  const { title, desc, footer } = props
  return (
    <Box
      // m='2'
      // p='1'
      // (3.) Padding between Card Items
      px={['5', '5', '3', '2', '2']}
      py={['2', '2', '3', '3', '3']}
      // (2.) Declare Item's responsive Width
      // วางแผนจะดึงแสดงจำนวน items = 1,1,3,4,5 ตามลำดับ ตามขนาดความกว่างจอ
      w={['full', 'full', '1/3', '1/4', '1/5']}>
      <XCard borderRadius='lg'>
        {title ? (
          <XCard.Header>
            <Text>{title}</Text>
          </XCard.Header>
        ) : null}

        <XCard.Body>
          <Text numberOfLines={4}>{desc}</Text>
        </XCard.Body>

        {footer ? (
          <XCard.Footer>
            <Text>{footer}</Text>
          </XCard.Footer>
        ) : null}
      </XCard>
    </Box>
  )
}

function FooterExample() {
  return (
    <Box
      // position='absolute' bottom='0' left='0' right='0'
      h='12'
      px='1'
      py='1'
      bg='gray.200'
      _dark={{
        bg: 'dark.200',
      }}
      flexDirection='row'>
      <Button flex={1} mx={2} variant='solid'>
        Button 1
      </Button>
      <Button flex={1} mx={2} variant='solid'>
        Button 2
      </Button>
      <Button flex={1} mx={2} variant='solid'>
        Button 3
      </Button>
    </Box>
  )
}

function ColorMode() {
  const { colorMode, toggleColorMode } = useColorMode()
  return (
    <HStack
      alignItems='center'
      _light={{
        bg: 'warmGray.50',
      }}
      _dark={{
        bg: 'coolGray.800',
      }}
      // bg={useColorModeValue('warmGray.50', 'coolGray.800')}
    >
      <Text mx='2' bold fontSize='sm'>
        {useColorModeValue('Light', 'Dark')}
      </Text>
      <Switch onToggle={toggleColorMode} isChecked={colorMode === 'dark'} size='md' />
    </HStack>
  )
}

const NativeBaseStoryView: React.FC = () => (
  <XContainer>
    <Box flexDirection='column' alignItems='center' justifyContent='center'>
      <Heading size='md'>NativeBaseStoryView</Heading>
      <ColorMode />
    </Box>

    <XContent _contentContainerStyle={{ px: '4' }} scrollEnabled showsVerticalScrollIndicator>
      <ResponsiveExample />
      <XCardExample />
      <ButtonExample />
      <ColorPalettes />
      <TextExample />
    </XContent>

    <FooterExample />
  </XContainer>
)

export default NativeBaseStoryView
