import React from 'react'
import { Button, Box } from 'native-base'
import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import { TouchableOpacity, View, Clipboard } from 'react-native'

import { COLORS, STYLES, btnOnMode, textOnBtnMode, S } from 'x/config/styles'

import _ from 'lodash'
import p from 'x/config/platform-specific'
import * as xUtil from 'x/utils/util'

import xCONS from 'x/config/constants'
import { fromJS } from 'immutable'
import XContainer from 'xui/components/XContainer'
import XCard from 'xui/components/XCard'
import XButtonWithIcon from 'xui/components/XButtonWithIcon'
import XText from 'xui/components/XText'
import { IXScreenProps } from 'x/types'
import XCustomHeader from '../../components/XCustomHeader'
import XIcon from '../../components/XIcon'
import XContent from '../../components/XContent'
import XInput from '../../components/XInput'

export interface Props extends IXScreenProps {
  // navigation
  addNotesOrder
  order
  editingOrder
  selectedStore
  fetchOrderNotes
  deleteOrderNote
  addOrderNote
  editOrderNote
  selectOrderNote
}

interface State {
  inputValue: string
  mode: string
  notes: any[]

  showOrderReceiverNote: boolean
  showThisOrderNote: boolean
  showSelectOrderSellNote: boolean
  showInput: boolean
  showNotChoose: boolean

  oldNote: string
  cancalBtn: boolean
}

const { TXT_LABEL_SMALLER, FONT_SIZE_NORMAL } = STYLES

const NUM_SIXTEEN = 16

const CREATE = 'CREATE'
const READ_ONLY_MODE = 'READ_ONLY_MODE'
const READ_ONLY_ORDER_LOCK_MODE = 'READ_ONLY_ORDER_LOCK_MODE'

const BLACK_COLOR = '#000'
const BORDER_COLOR = '#C9C9C9'

export default abstract class BaseUIAddCoverSheetNote extends React.Component<Props, State> {
  static displayName = 'BaseUIAddCoverSheetNote'

  inputRef?: any

  // static navigationOptions = ({ navigation }) => {
  //   const { state, setParams, goBack } = navigation
  //   // const { handleBtn } = state.params
  //   const handleBackBtn = state.params ? state.params.handleBackBtn : null

  //   return {
  //     headerLeft:
  //       state && state.params ? (
  //         <Button
  //           transparent={true}
  //           onPress={() => {
  //             if (!_.isFunction(handleBackBtn)) {
  //               return
  //             } else {
  //               handleBackBtn()
  //             }
  //           }}>
  //           <BackIcon />
  //         </Button>
  //       ) : null,

  //     // headerRight:
  //     //   state && state.params && state.params.checkOnChange ? (
  //     //     <Button
  //     //       transparent
  //     //       onPress={() =>
  //     //         Alert.alert('ยกเลิกการแก้ไข', 'คุณต้องการยกเลิกการแก้ไขข้อมูลในหน้านี้ใช่หรือไม่', [
  //     //           {
  //     //             text: 'ใช่',
  //     //             onPress: () => {
  //     //               xUtil.navGoBack(this.props)
  //     //             },
  //     //           },
  //     //           { text: 'ยกเลิก' },
  //     //         ])
  //     //       }>
  //     //       <XText style={{ color: COLORS.TEXT_PRIMARY, paddingLeft: NUM_SIXTEEN, paddingRight: NUM_SIXTEEN }}>{'ยกเลิก'}</XText>
  //     //     </Button>
  //     //   ) : (
  //     //     <Button
  //     //       transparent
  //     //       onPress={() => {
  //     //         xUtil.navGoBack(this.props)
  //     //       }}>
  //     //       <XText style={{ color: COLORS.TEXT_PRIMARY, paddingLeft: NUM_SIXTEEN, paddingRight: NUM_SIXTEEN }}>{'ยกเลิก'}</XText>
  //     //     </Button>
  //     //   ),
  //     title: 'หมายเหตุบนใบปะหน้า',
  //   }
  // }

  constructor(props) {
    super(props)
    this.state = {
      inputValue: null,
      mode: 'VIEW',
      notes: null,

      showSelectOrderSellNote: false,
      showOrderReceiverNote: false,
      showThisOrderNote: false,
      showInput: false,
      showNotChoose: false,

      oldNote: null,
      cancalBtn: false,
    }
  }

  async componentDidMount() {
    const { navigation, selectedStore } = this.props
    const focusedOrder = this._getFocusedOrder()
    const mode = xUtil.getNavParam(this.props, 'mode')
    let notes = focusedOrder.get('notes')
    const orderType = focusedOrder.get('type')
    let respond

    let showOrderReceiverNote
    let showSelectOrderSellNote
    let showThisOrderNote
    let showInput
    let showNotChoose

    if (mode !== CREATE) {
      await new Promise(async (resolve) => {
        this.props.fetchOrderNotes({
          body: {
            store_id: selectedStore.get('id'),
            order_id: focusedOrder.get('id'),
            type: 2,
          },
          successCallback: (res) => {
            respond = res.note_options
            setTimeout(resolve, 200)
          },
          failedCallback: () => {
            xUtil.navGoBack(this.props)
          },
        })
      })

      // console.log(respond)
      // console.log(mode)
      // const orderType = focusedOrder.get('type')
      // console.log(orderType)
      if (_.isNil(respond) || respond.length < 1) {
        respond = null
      }
      // if (orderType === 1) {
      //   if (_.isNil(respond)) {
      //     showInput = true
      //   } else {
      //     showThisOrderNote = true
      //   }
      //   // this._renderOrderTypeOne()
      // } else if (orderType === 2) {
      //   if (_.isNil(respond)) {
      //     showInput = true
      //   } else {
      //     const myNote = _.find(respond, note => note.created_by === 'm' && note.type === 2)
      //     const r = _.find(respond, note => note.created_by === 'r' && note.type === 2)
      //     if (_.isNil(myNote)) {
      //       showInput = true
      //     } else {
      //       showThisOrderNote = true
      //     }
      //     if (!_.isNil(r)) {
      //       (showOrderReceiverNote = true), (showNotChoose = true)
      //     }
      //   }
      // } else if (orderType === 3) {
      if (_.isNil(respond)) {
        showInput = true
      } else {
        const myNote = _.find(respond, (note) => note.created_by === 'm' && note.type === 2)
        const p = _.find(respond, (note) => note.created_by === 'p' && note.type === 2)
        const r = _.find(respond, (note) => note.created_by === 'r' && note.type === 2)
        if (_.isNil(myNote)) {
          showInput = true
        } else {
          showThisOrderNote = true
        }
        if (!_.isNil(p)) {
          showSelectOrderSellNote = true
          showNotChoose = true
        }
        if (!_.isNil(r)) {
          showOrderReceiverNote = true
          showNotChoose = true
        }
      }
      // } else if (orderType === 4) {
      //   p.op.showToast('ขออภัย คุณไม่สามารถใช้งานตรงนี้ได้', 'warning', 2000)
      //   // setParams({ inProcess: false })
      // }
    } else {
      // CREATE MODE
      // console.log('CREATE MODE')
      if (_.isNil(notes)) {
        notes = fromJS([])
      }
      const note = _.find(notes.toJS(), (note) => note.type === 2)
      if (_.isNil(note)) {
        // const note = _.find(notes, note => note.get('type') === 2)
        showOrderReceiverNote = false
        showSelectOrderSellNote = false
        showThisOrderNote = false
        showInput = true
      } else {
        // const note = _.find(notes, newNote => newNote.get('type') === 2)
        respond = notes.toJS()
        showOrderReceiverNote = false
        showSelectOrderSellNote = false
        showThisOrderNote = true
        showInput = false
      }
    }

    navigation.setParams({
      handleBackBtn: () => {
        this.handleBackBtn()
      },
    })

    if (!showInput && mode !== CREATE) {
      showNotChoose = true
    }

    if (orderType === 3 && mode !== CREATE) {
      showNotChoose = true
    }

    this.setState({
      mode,
      notes: respond,
      showOrderReceiverNote,
      showSelectOrderSellNote,
      showThisOrderNote,
      showInput,
      showNotChoose,
    })
  }

  // _renderOrderTypeOne = () => { }

  // _renderOrderTypeTwo = focusedOrder => { }

  // _renderOrderTypeThree = focusedOrder => { }

  _getFocusedOrder = () => {
    const { editingOrder, navigation } = this.props
    const sourceOrder = editingOrder
    const targetIndex = xUtil.getNavParam(this.props, 'orderTabIndex', 0)
    return xUtil.getFocusedOrder(sourceOrder, targetIndex)
  }

  handleBackBtn = () => {
    const { oldNote, inputValue } = this.state
    if (oldNote !== inputValue) {
      p.op.showConfirmation(
        null,
        'คุณต้องการยกเลิกการแก้ไขจริงๆใช่หรือไม่',
        () => xUtil.navGoBack(this.props),
        () => {
          //
        },
        'ใช่',
        'ไม่ใช่'
      )
    } else {
      xUtil.navGoBack(this.props)
    }
  }

  saveSuccess = () => {
    p.op.showToast('เพิ่มหมายเหตุบนใบปะหน้าเรียบร้อยแล้ว', 'success', 2000)
    xUtil.navGoBack(this.props)
  }

  deleteNoteOrder = () => {
    const focusedOrder = xUtil.getNavParam(this.props, 'focusedOrder')
    const notesOrder = focusedOrder.get('notes')
    let data = xUtil.deleteNotesOrder(notesOrder.toJS(), 2)
    if (_.isEmpty(data)) {
      data = null
    }
    this.props.addNotesOrder(data)
    p.op.showToast('ลบหมายเหตุบนใบปะหน้าเรียบร้อยแล้ว', 'success', 2000)
    xUtil.navGoBack(this.props)
  }

  onChangeText = (text) => {
    // const { navigation } = this.props
    // const { setParams } = navigation
    // this.setState({ inputValue: text }, () => {
    //   setParams({ checkOnChange: true })
    // })
    this.setState({ inputValue: text })
  }

  _renderDeleteBtn = () => {
    const focusedOrder = xUtil.getNavParam(this.props, 'focusedOrder')
    const notesOrder = focusedOrder.get('notes')
    let showDeleteBtn = false
    if (!_.isNil(notesOrder)) {
      notesOrder.map((val) => {
        if (val.get('type') === 2) {
          showDeleteBtn = true
        }
      })
    }
    if (showDeleteBtn) {
      return (
        <HStack style={{ marginVertical: 10 }}>
          <VStack style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <Button
              onPress={async () => {
                // Alert.alert(
                //   'ลบหมายเหตุบนใบปะหน้า',
                //   'คุณต้องการลบหมายเหตุบนใบปะหน้าใช่หรือไม่',
                //   [
                //     {
                //       text: 'ไม่ใช่',
                //       onPress: () => {},
                //       style: 'cancel',
                //     },
                //     { text: 'ใช่', onPress: () => this.deleteNoteOrder() },
                //   ],
                //   { cancelable: false },
                // )
                const isUserConfirm = await p.op.isUserConfirm(
                  'ลบหมายเหตุบนใบปะหน้า',
                  'คุณต้องการลบหมายเหตุบนใบปะหน้าใช่หรือไม่',
                  'ใช่',
                  'ไม่ใช่'
                )
                if (!isUserConfirm) {
                  return
                }
                this.deleteNoteOrder()
              }}
              style={btnStyle}>
              <XText style={txtStyle}>ลบหมายเหตุบนใบปะหน้า</XText>
            </Button>
          </VStack>
        </HStack>
      )
    }
    return null
  }

  _renderInputNote = () => {
    const { mode } = this.state
    let saveMode = 'EDIT'
    if (_.isNil(this.state.oldNote)) {
      saveMode = 'ADD'
    }
    if (mode === READ_ONLY_ORDER_LOCK_MODE) {
      return null
    }
    if (mode === READ_ONLY_MODE) {
      return null
    }
    // if (mode !== CREATE) {
    //   if (mode === READ_ONLY_MODE) {
    //     return null
    //   }
    //   if (mode === READ_ONLY_ORDER_LOCK_MODE) {
    //     return null
    //   }
    // }
    return (
      <HStack w='full' alignItems='center'>
        <HStack minW='60px' alignItems='center' justifyContent='center'>
          <TouchableOpacity
            // style={{
            //   width: 50,
            //   height: 50,
            //   padding: 2,
            //   alignItems: 'center',
            //   justifyContent: 'center',
            // }}
            onPress={() => {
              // this.inputRef._root.focus()
              this.inputRef.focus()
            }}>
            <XIcon
              primary
              // style={{
              //   fontSize: STYLES.FONT_ICON_NORMAL,
              //   color: COLORS.APP_MAIN,
              //   textAlign: 'center',
              // }}
              name={false ? 'radio-button-on' : 'radio-button-off'}
            />
          </TouchableOpacity>
        </HStack>
        <View
          style={[
            S.FLEX,
            S.BORDER,
            S.CARDLIKE_BODY,
            S.CARDLIKE_MARGIN,
            {
              // flex: 1,
              // borderWidth: 1,
              borderColor: BORDER_COLOR,
              // flexWrap: 'wrap',
              // maxWidth: this.getFixedWebLayoutTextLimitWidth(),
            },
          ]}>
          <View style={[S.ROW_MIDDLE_START, { paddingBottom: 0 }]}>
            <XText>เพิ่มหมายเหตุบนใบปะหน้า</XText>
          </View>
          {/* <View style={[S.ROW_MIDDLE_START, { paddingTop: 0 }]}>
            <InputGroup
              // selectTextOnFocusGroup
              // borderType='regular'
              style={{
                borderBottomWidth: 0,
                paddingTop: 5,
                marginTop: 10,
                backgroundColor: COLORS.FORM_PRIMARY_BG,
                width: '100%',
              }}>
              <Input
                // selectTextOnFocus
                // @ts-ignore
                ref={(r) => r && (this.inputRef = r)}
                value={this.state.inputValue}
                onChangeText={(text) => this.onChangeText(text)}
                style={{
                  borderBottomWidth: 0,
                  width: '100%',
                  height: 80,
                  fontSize: STYLES.FONT_SIZE_NORMAL,
                  color: COLORS.TEXT_PRIMARY,
                }}
                placeholder='กรุณากรอกข้อความใหม่ในช่องนี้'
                placeholderTextColor={COLORS.TEXT_INACTIVE}
                multiline
              />
            </InputGroup>
              </View> */}
          <XInput
            // selectTextOnFocus
            w='full'
            // @ts-ignore
            ref={(r) => r && (this.inputRef = r)}
            value={this.state.inputValue}
            onChangeText={(text) => this.onChangeText(text)}
            placeholder='กรุณากรอกข้อความใหม่ในช่องนี้'
            placeholderTextColor={COLORS.TEXT_INACTIVE}
            style={{ minHeight: 200 }}
            multiline
          />
          <HStack w='full' mt='2'>
            <XButtonWithIcon
              flex={1}
              leftIcon={<XIcon name='save' family='FontAwesome' size='xs' />}
              onPress={() => {
                this._saveNote(saveMode)
              }}>
              บันทึก
            </XButtonWithIcon>
            {this.state.cancalBtn ? (
              <XButtonWithIcon
                flex={1}
                ml='2'
                // colorScheme='danger'
                variant='outline'
                leftIcon={<XIcon name='cancel' family='MaterialCommunityIcons' size='xs' />}
                onPress={() => {
                  this.cancalBtn()
                }}>
                ยกเลิก
              </XButtonWithIcon>
            ) : null}

            {/* <TouchableOpacity
              style={{
                width: 70,
                padding: 2,
                borderWidth: 1,
                borderRadius: 7,
                borderColor: COLORS.APP_MAIN,
                backgroundColor: COLORS.APP_MAIN,
                marginLeft: 4,
                alignItems: 'center',
              }}
              onPress={() => {
                this._saveNote(saveMode)
              }}>
              <XText style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: '#fff' }}>บันทึก</XText>
            </TouchableOpacity> */}
            {/* {
              this.state.cancalBtn ? <TouchableOpacity
                style={{
                  width: 70,
                  padding: 2,
                  borderWidth: 1,
                  borderRadius: 7,
                  marginRight: 4,
                  borderColor: COLORS.APP_MAIN,
                  backgroundColor: COLORS.BG_LIGHT_GREY,
                  marginLeft: 4,
                  alignItems: 'center',
                }}
                onPress={() => {
                  this.cancalBtn()
                }}>
                <XText style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.APP_MAIN }}>ยกเลิก</XText>
              </TouchableOpacity> : null
            } */}
          </HStack>
        </View>
      </HStack>
    )
  }

  _renderOrderReceiverNote = () => {
    const { notes } = this.state
    let note = _.find(notes, (note) => note.created_by === 'r' && note.type === 2)
    if (_.isNil(note)) {
      note = { selected: false }
    }
    // if (_.isNil(note.selected)) {
    //   note.selected = false
    // }
    // const pending_shp_lbl_note = this.props.navigation.getParam('pending_shp_lbl_note', false)
    // if (pending_shp_lbl_note) {
    //   return (
    //     <Box style={{ flexDirection: 'column', backgroundColor: COLORS.BRAND_Danger }}>
    //       <XText style={{ fontSize: FONT_SIZE_LARGER, color: '#fff' }}>มีหมายเหตุใบปะหน้าที่รอผู้ซื้อยืนยัน</XText>

    //       <XText style={{ fontSize: FONT_SIZE_LARGER, color: '#fff' }}>กรุณาติดต่อผู้ซื้อเพื่อให้ยืนยันออเดอร์</XText>
    //     </Box>
    //   )
    // }
    return (
      <HStack w='full' alignItems='center'>
        <HStack minW='60px' alignItems='center' justifyContent='center'>
          <TouchableOpacity
            // style={{
            //   width: 50,
            //   height: 50,
            //   padding: 2,
            //   alignItems: 'center',
            //   justifyContent: 'center',
            // }}
            onPress={() => {
              this._selectNoteOption(note.selected, 1)
            }}>
            <XIcon
              primary
              // style={{
              //   fontSize: STYLES.FONT_ICON_NORMAL,
              //   color: COLORS.APP_MAIN,
              //   textAlign: 'center',
              // }}
              name={note.selected ? 'radio-button-on' : 'radio-button-off'}
            />
          </TouchableOpacity>
        </HStack>
        <View
          style={[
            S.FLEX,
            S.BORDER,
            S.CARDLIKE_BODY,
            {
              borderColor: note.selected ? COLORS.APP_MAIN : BORDER_COLOR,
              // borderWidth: 1,
              // margin: 4,
              marginTop: 10,
              // flex: 1,
            },
          ]}>
          <View style={[S.ROW_MIDDLE_START]}>
            <XText flex={1} mr='1'>
              ผู้ซื้อระบุ
            </XText>
            <XButtonWithIcon
              variant='outline'
              leftIcon={<XIcon name='copy' family='FontAwesome' size='xs' />}
              onPress={() => this._copyToClipboard(note.value)}>
              คัดลอก
            </XButtonWithIcon>
          </View>

          <View style={[S.ROW_MIDDLE_START, { paddingTop: 0, paddingBottom: 0, marginLeft: 17, marginRight: 17, marginBottom: 10 }]}>
            <XText w='full'>{note.value}</XText>
          </View>

          {/* <Box style={{ justifyContent: 'flex-start', paddingTop: 0, marginRight: 4, marginLeft: 0 }}>
            <TouchableOpacity
              style={{
                width: 70,
                padding: 2,
                borderWidth: 1,
                borderRadius: 7,
                borderColor: COLORS.APP_MAIN,
                backgroundColor: COLORS.BG_LIGHT_GREY,
                marginLeft: 4,
                alignItems: 'center',
              }}
              onPress={() => {
                this._copyToClipboard(note.value)
              }}>
              <XText style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.APP_MAIN }}>คัดลอก</XText>
            </TouchableOpacity>
          </Box> */}
        </View>
      </HStack>
    )
  }

  _renderSelectOrderSellNote = () => {
    const { notes } = this.state
    let note = _.find(notes, (note) => note.created_by === 'p' && note.type === 2)
    if (_.isNil(note)) {
      note = { selected: false }
    }
    // if (_.isNil(note.selected)) {
    //   note.selected = false
    // }
    // const pending_shp_lbl_note = this.props.navigation.getParam('pending_shp_lbl_note', false)
    // if (pending_shp_lbl_note) {
    //   return (
    //     <Box style={{ flexDirection: 'column', backgroundColor: COLORS.BRAND_Danger }}>
    //       <XText style={{ fontSize: FONT_SIZE_LARGER, color: '#fff' }}>มีหมายเหตุใบปะหน้าที่รอผู้ซื้อยืนยัน</XText>

    //       <XText style={{ fontSize: FONT_SIZE_LARGER, color: '#fff' }}>กรุณาติดต่อผู้ซื้อเพื่อให้ยืนยันออเดอร์</XText>
    //     </Box>
    //   )
    // }
    return (
      <HStack w='full' alignItems='center'>
        <HStack minW='60px' alignItems='center' justifyContent='center'>
          <TouchableOpacity
            // style={{
            //   width: 50,
            //   height: 50,
            //   padding: 2,
            //   alignItems: 'center',
            //   justifyContent: 'center',
            // }}
            onPress={() => {
              this._selectNoteOption(note.selected, 2)
            }}>
            <XIcon
              primary
              // style={{
              //   fontSize: STYLES.FONT_ICON_NORMAL,
              //   color: COLORS.APP_MAIN,
              //   textAlign: 'center',
              // }}
              name={note.selected ? 'radio-button-on' : 'radio-button-off'}
            />
          </TouchableOpacity>
        </HStack>

        <View
          style={[
            S.FLEX,
            S.BORDER,
            S.CARDLIKE_MARGIN,
            S.CARDLIKE_BODY,
            {
              borderColor: note.selected ? COLORS.APP_MAIN : BORDER_COLOR,
              // borderWidth: 1,
              // margin: 4,
              // marginBottom: 10,
              // marginTop: 10,
              // flex: 1,
            },
          ]}>
          <View style={[S.ROW_MIDDLE_START, S.CARDLIKE_BODY, { height: 50 }]}>
            <XText
              flex={1}
              mr='1'
              // style={{ fontSize: FONT_SIZE_NORMAL, flex: 1, justifyContent: 'flex-start' }}
            >
              ฉันระบุ ในออเดอร์ขาย
            </XText>
            <XButtonWithIcon
              variant='outline'
              leftIcon={<XIcon name='copy' family='FontAwesome' size={STYLES.FONT_ICON_SMALLER} />}
              onPress={() => this._copyToClipboard(note.value)}>
              คัดลอก
            </XButtonWithIcon>
          </View>

          <View style={[S.ROW_MIDDLE_START, { paddingTop: 0, paddingBottom: 0, marginLeft: 17, marginRight: 17, marginBottom: 10 }]}>
            <XText w='full'>{note.value}</XText>
          </View>

          {/* <Box style={{ justifyContent: 'flex-start', paddingTop: 0, marginRight: 4, marginLeft: 0 }}>
            <TouchableOpacity
              style={{
                width: 70,
                padding: 2,
                borderWidth: 1,
                borderRadius: 7,
                borderColor: COLORS.APP_MAIN,
                backgroundColor: COLORS.BG_LIGHT_GREY,
                marginLeft: 4,
                alignItems: 'center',
              }}
              onPress={() => {
                this._copyToClipboard(note.value)
              }}>
              <XText style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.APP_MAIN }}>คัดลอก</XText>
            </TouchableOpacity>
          </Box> */}
        </View>
      </HStack>
    )
  }

  // FIXME: O => Artid นี่เป็นการเดา magic number ในฝั่งเว็บไม่ให้ layout พัง ถ้ามีโอกาสให้กลับมาแก้ด้วยครับ
  getFixedWebLayoutTextLimitWidth = () => undefined

  _renderThisOrderNote = () => {
    const { notes, mode } = this.state
    const focusedOrder = this._getFocusedOrder()
    let note
    if (mode === CREATE) {
      const newNote = focusedOrder.get('notes')
      if (_.isNil(newNote)) {
        return
      }
      note = _.find(newNote.toJS(), (note) => note.type === 2)
      if (_.isNil(note)) {
        note = { selected: false }
      } else {
        note.selected = true
      }
    } else {
      note = _.find(notes, (note) => note.created_by === 'm' && note.type === 2)
      if (_.isNil(note)) {
        note = { selected: false }
      }
    }

    let title = 'ฉันระบุ'
    if (focusedOrder.get('type') === 3) {
      title = 'ฉันระบุไว้ในออเดอร์นี้'
    }
    // console.log('_renderThisOrderNote ++++++')
    // console.log(note)
    return (
      <HStack w='full' alignItems='center'>
        <HStack minW='60px' alignItems='center' justifyContent='center'>
          <TouchableOpacity
            // style={{
            //   width: 50,
            //   height: 50,
            //   padding: 2,
            //   alignItems: 'center',
            //   justifyContent: 'center',
            // }}
            onPress={() => {
              this._selectNoteOption(null, 0)
            }}>
            <XIcon
              primary
              // style={{
              //   fontSize: STYLES.FONT_ICON_NORMAL,
              //   color: COLORS.APP_MAIN,
              //   textAlign: 'center',
              // }}
              name={note.selected ? 'radio-button-on' : 'radio-button-off'}
            />
          </TouchableOpacity>
        </HStack>
        <View
          style={[
            S.FLEX,
            S.BORDER,
            S.CARDLIKE_BODY,
            S.CARDLIKE_MARGIN,
            {
              borderColor: note.selected ? COLORS.APP_MAIN : BORDER_COLOR,
              borderWidth: 1,
              // margin: 4,
              // flex: 1,
              // maxWidth: this.getFixedWebLayoutTextLimitWidth(),
              // flexWrap: 'wrap',
            },
          ]}>
          <HStack w='full'>
            <Box flex={1}>
              <XText variant='inactive'>{title}</XText>
            </Box>
            <XButtonWithIcon
              w='24'
              variant='outline'
              leftIcon={<XIcon name='copy' family='FontAwesome' size='xs' />}
              onPress={() => this._copyToClipboard(note.value)}>
              คัดลอก
            </XButtonWithIcon>
          </HStack>

          <View
            style={[
              S.ROW_MIDDLE_START,
              S.CARDLIKE_BODY,
              S.FLEX_WRAP,
              {
                paddingTop: 0,
                paddingBottom: 0,
                marginLeft: 17,
                marginRight: 17,
                // // marginBottom: 10,
                // // maxWidth: this.getFixedWebLayoutTextLimitWidth(),
                // // flexWrap: 'wrap',
                // // O: แก้ไขตัวหนังสือล้นในเว็บ
                // ...Platform.select({
                //   web: {
                //     marginLeft: 0,
                //     marginRight: 0,
                //     paddingLeft: 0,
                //   },
                // }),
              },
            ]}>
            <XText
              w='full'
              // style={{
              //   fontSize: FONT_SIZE_NORMAL,
              //   color: BLACK_COLOR,
              //   marginTop: 2,
              //   marginBottom: 2,
              //   // maxWidth: this.getFixedWebLayoutTextLimitWidth(),
              // }}
            >
              {note.value}
            </XText>
          </View>

          <View>
            {/* <View style={{ flex: 1, justifyContent: 'flex-start', }}>
              <TouchableOpacity
                style={{
                  width: 70,
                  padding: 2,
                  borderWidth: 1,
                  borderRadius: 7,
                  borderColor: COLORS.APP_MAIN,
                  backgroundColor: COLORS.BG_LIGHT_GREY,
                  marginLeft: 4,
                  alignItems: 'center',
                }}
                onPress={() => {
                  this._copyToClipboard(note.value)
                }}>
                <XText style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.APP_MAIN }}>คัดลอก</XText>
              </TouchableOpacity>
            </View> */}
            <HStack w='full'>
              <Box flex={1} />
              <XButtonWithIcon
                w='24'
                variant='outline'
                leftIcon={<XIcon name='edit' family='FontAwesome' size='xs' />}
                onPress={() => this.editNote(note)}>
                แก้ไข
              </XButtonWithIcon>

              {/* <TouchableOpacity
                style={{
                  width: 70,
                  padding: 2,
                  borderWidth: 1,
                  borderRadius: 7,
                  marginRight: 4,
                  borderColor: COLORS.BRAND_Danger,
                  backgroundColor: COLORS.BRAND_Danger,
                  marginLeft: 4,
                  alignItems: 'center',
                }}
                onPress={() => {
                  this.deleteNote(note)
                }}>
                <XText style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: '#fff' }}>ลบ</XText>
              </TouchableOpacity> */}

              {/* <TouchableOpacity
                style={{
                  width: 70,
                  padding: 2,
                  borderWidth: 1,
                  borderRadius: 7,
                  marginRight: 4,
                  borderColor: COLORS.APP_MAIN,
                  backgroundColor: COLORS.BG_LIGHT_GREY,
                  marginLeft: 4,
                  alignItems: 'center',
                }}
                onPress={() => {
                  this.editNote(note)
                }}>
                <XText style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.APP_MAIN }}>แก้ไข</XText>
              </TouchableOpacity> */}
            </HStack>
          </View>
        </View>
      </HStack>
    )
  }

  _renderNotChoose = () => {
    const focusedOrder = this._getFocusedOrder()
    const ignoreShpLblNote = focusedOrder.get('ignore_shp_lbl_note')
    const pendingShpLblNote = focusedOrder.get('pending_shp_lbl_note')
    const orderType = focusedOrder.get('type')
    let notChoose = false
    const { notes, mode } = this.state
    // if (!_.isNil(notes)) {
    //   notes.map(note => {
    //     if (!_.isNil(note.selected)) {
    //       notChoose = false
    //     }
    //   })
    // } else {
    //   notChoose = false
    // }

    if (orderType === 3) {
      if (!_.isNil(notes)) {
        notChoose = true
        notes.map((note) => {
          if (!_.isNil(note.selected)) {
            notChoose = false
          }
        })
      }
    } else if (!_.isNil(ignoreShpLblNote) && ignoreShpLblNote) {
      notChoose = true
    }

    if (mode === CREATE) {
      if (_.isNil(notes)) {
        notChoose = true
      } else {
        notChoose = false
      }
    }
    return (
      <HStack w='full' alignItems='center'>
        <HStack minW='60px' alignItems='center' justifyContent='center'>
          <TouchableOpacity
            // style={{
            //   width: 50,
            //   height: 50,
            //   padding: 2,
            //   alignItems: 'center',
            //   justifyContent: 'center',
            // }}
            onPress={() => {
              this._selectNoteOption(null, 3)
            }}>
            <XIcon
              primary
              // style={{
              //   fontSize: STYLES.FONT_ICON_NORMAL,
              //   color: COLORS.APP_MAIN,
              //   textAlign: 'center',
              // }}
              name={notChoose ? 'radio-button-on' : 'radio-button-off'}
            />
          </TouchableOpacity>
        </HStack>
        <View
          style={[
            S.FLEX,
            S.BORDER,
            S.CARDLIKE_MARGIN,
            S.CARDLIKE_BODY,
            {
              borderColor: notChoose ? COLORS.APP_MAIN : BORDER_COLOR,
              // borderWidth: 1,
              margin: 4,
              marginTop: 10,
              // flex: 1,
              // height: 70,
              // justifyContent: 'center',
              // maxWidth: this.getFixedWebLayoutTextLimitWidth(),
            },
          ]}>
          <VStack w='full'>
            <XText w='full'>ไม่ใช้</XText>
            <XText w='full' variant='inactive'>
              (หากเลือกตัวเลือกนี้ หมายเหตุบนใบปะหน้าที่ฉันระบุสำหรับออเดอร์นี้ ก็จะถูกลบโดยอัตโนมัติ)
            </XText>
          </VStack>
        </View>
      </HStack>
    )
  }

  editNote = (note) => {
    const { mode } = this.state
    const focusedOrder = this._getFocusedOrder()
    const orderType = focusedOrder.get('type')
    if (mode === READ_ONLY_MODE && orderType !== 1) {
      p.op.showConfirmationOkOnly(
        null,
        'อยู่โหมดป้องกันการแก้ไข เนื่องจากออเดอร์นี้ไม่มีสินค้าที่คุณต้องจัดส่งหากต้องการแก้ไข กรุณาแก้ที่ออเดอร์ซื้อ ที่เกี่ยวข้อง'
      )
      return
    }
    if (mode === READ_ONLY_ORDER_LOCK_MODE && orderType !== 1) {
      p.op.showConfirmationOkOnly(
        null,
        'อยู่โหมดป้องกันการแก้ไข เนื่องจากออเดอร์นี้ถูกล็อกอยู่หากต้องการแก้ไข กรุณาแจ้งให้ผู้ขายปลดล็อกก่อน'
      )
      return
    }
    this.setState({
      oldNote: note.value,
      showInput: true,
      showThisOrderNote: false,
      inputValue: note.value,
      cancalBtn: true,
    })
  }

  _saveNote = async (saveMode) => {
    const { mode, inputValue, notes } = this.state
    // const updated_at = utilNative.getTimeFormatYYYYMMDDhhmmss()
    const { selectedStore, navigation } = this.props
    const onSuccessEditNoteCallback = xUtil.getNavParam(this.props, 'onSuccessEditNoteCallback', () => {})

    const focusedOrder = this._getFocusedOrder()
    if (inputValue.trim() === '') {
      p.op.showToast('กรุณาระบุข้อความก่อนบันทึก', 'warning', 2000)
      return
    }
    if (_.isNil(inputValue) || _.isEmpty(inputValue)) {
      p.op.showToast('กรุณาระบุข้อความก่อนบันทึก', 'warning', 2000)
      return
    }
    let newNotes

    if (mode === CREATE) {
      let notesfocusedOrder = focusedOrder.get('notes')
      if (_.isNil(notesfocusedOrder)) {
        notesfocusedOrder = fromJS([])
      }
      if (notesfocusedOrder.toJS().length === 0) {
        this.props.addNotesOrder([
          {
            value: inputValue,
            type: 2,
          },
        ])
        newNotes = [
          {
            value: inputValue,
            type: 2,
          },
        ]
      } else {
        const noteOj = { value: inputValue, type: 2 }
        const data = xUtil.requiredNewArrayNoteOrder(notesfocusedOrder.toJS(), 2, noteOj)
        this.props.addNotesOrder(data)
        newNotes = data
      }
      this.setState({
        showThisOrderNote: true,
        showInput: false,
        inputValue: null,
        showNotChoose: true,
        cancalBtn: true,
        notes: newNotes,
        oldNote: null,
      })
      // p.op.showToast('เพิ่มหมายเหตุบนใบปะหน้าเรียบร้อยแล้ว', 'success', 2000)
    } else if (saveMode === 'ADD') {
      // let updatePurchaseOrders = false
      const orderType = focusedOrder.get('type')
      let suborders = focusedOrder.get('suborders')
      // let orderTypeCheck = false
      let supTitle = 'คุณต้อง เลือกหมายเหตุนี้ที่ออเดอร์ซื้อเองหลังจากยืนยันการเพิ่ม หากต้องการให้ผู้ขายพิมพ์หมายเหตุนี้บนใบปะหน้า'
      if (orderType === 1 || orderType === 2) {
        if (suborders.size === 0) {
          suborders = null
        }
        if (_.isNil(suborders)) {
          supTitle = null
          // orderTypeCheck = true
        }
      }
      if (orderType !== 3 && orderType !== 4) {
        // await new Promise(async resolve => {
        //   Alert.alert(
        //     'ยืนยันการเพิ่มหมายเหตุใบปะหน้าพัสดุ',
        //     supTitle,
        //     [
        //       {
        //         text: 'ยกเลิก',
        //         onPress: () => {
        //           return
        //         },
        //         style: 'cancel',
        //       },
        //       {
        //         text: 'ยืนยัน',
        //         onPress: () => {
        //           // updatePurchaseOrders = true
        //           setTimeout(resolve, 200)
        //         },
        //       },
        //     ],
        //     { cancelable: false },
        //   )
        // })

        const isUserConfirm = await p.op.isUserConfirm('ยืนยันการเพิ่มหมายเหตุใบปะหน้าพัสดุ', supTitle, 'ยืนยัน', 'ยกเลิก')
        if (!isUserConfirm) {
          return
        }
      }

      this.props.addOrderNote({
        body: {
          store_id: selectedStore.get('id'),
          order_id: focusedOrder.get('id'),
          type: 2,
          value: inputValue,
          // updatePurchaseOrders,
        },
        successCallback: (res) => {
          this.setState({
            showThisOrderNote: true,
            showInput: false,
            inputValue: null,
            oldNote: null,
            notes: res.note_options,
            showNotChoose: true,
            cancalBtn: true,
          })
          onSuccessEditNoteCallback()
        },
        failedCallback: () => {
          xUtil.navGoBack(this.props)
          p.op.showToast('พบข้อผิดพลาด กรุณาทำรายการใหม่ภายหลัง', 'danger', 2000)
        },
      })
    } else {
      // EDIT ORDER NOTE
      const noteData = _.find(notes, (note) => note.created_by === 'm' && note.type === 2)
      // const myNote = _.find(notes, note => note.created_by === 'm' && note.type === 2)
      const { updated_at } = noteData
      // const updated_at = utilNative.getTimeFormatYYYYMMDDhhmmss()
      this.props.editOrderNote({
        body: {
          store_id: selectedStore.get('id'),
          order_id: focusedOrder.get('id'),
          note_id: noteData.id,
          value: inputValue,
          updated_at,
        },
        successCallback: (res) => {
          // let newEditNote = noteData
          // newEditNote.value = inputValue
          // newEditNote.updated_at = updated_at
          this.setState({
            notes: res.note_options,
            showThisOrderNote: true,
            showInput: false,
            oldNote: null,
            inputValue: null,
            cancalBtn: true,
          })
          onSuccessEditNoteCallback()
        },
        failedCallback: (err) => {
          let showInput = false
          let showThisOrderNote = false
          let showSelectOrderSellNote = false
          let showNotChoose = false
          let showOrderReceiverNote = false

          const respond = err.error.attach.note_options
          const myNote = _.find(respond, (note) => note.created_by === 'm' && note.type === 2)
          const p = _.find(respond, (note) => note.created_by === 'p' && note.type === 2)
          const r = _.find(respond, (note) => note.created_by === 'r' && note.type === 2)
          if (_.isNil(myNote)) {
            showInput = true
          } else {
            showThisOrderNote = true
            showInput = true
          }
          if (!_.isNil(p)) {
            showSelectOrderSellNote = true
            showNotChoose = true
          }
          if (!_.isNil(r)) {
            showOrderReceiverNote = true
            showNotChoose = true
          }

          this.setState({
            notes: respond,
            showInput,
            showThisOrderNote,
            showSelectOrderSellNote,
            showNotChoose,
            showOrderReceiverNote,
          })
          onSuccessEditNoteCallback()
        },
      })
    }
  }

  cancalBtn = () => {
    // const { navigation, } = this.props
    // const { state } = navigation
    const { inputValue, notes, oldNote } = this.state
    if (!_.isNil(notes)) {
      if (inputValue !== oldNote) {
        p.op.showConfirmation(
          'ยกเลิกการแก้ไข',
          'หากคุณยกเลิก เราจะไม่บันทึกการแก้ไขของคุณ',
          () => {
            let showThisOrderNote = true
            let showInput = false
            const note = _.find(notes, (thisNote) => thisNote.created_by === 'm' && thisNote.type === 2)
            if (_.isNil(note)) {
              showThisOrderNote = false
              showInput = true
            }
            this.setState({
              showThisOrderNote,
              inputValue: null,
              showInput,
              oldNote: null,
            })
          },
          () => {},
          'ใช่',
          'ไม่ใช่'
        )
      } else {
        this.setState({
          showThisOrderNote: true,
          inputValue: null,
          showInput: false,
          oldNote: null,
        })
      }
    } else if (!_.isNil(inputValue) || inputValue !== '') {
      p.op.showConfirmation(
        null,
        'คุณต้องการยกเลิกใช่หรือไม่',
        () => {
          this.setState({ inputValue: null })
        },
        () => {},
        'ใช่',
        'ไม่ใช่'
      )
    }
  }

  deleteNote = async (note) => {
    await new Promise(async (resolve) => {
      p.op.showConfirmation(
        null,
        'คุณต้องการลบหมายเหตุใบปะหน้าใช่หรือไม่',
        () => {
          setTimeout(resolve, 200)
        },
        () => {},
        'ใช่',
        'ไม่ใช่'
      )
    })
    const { mode, notes } = this.state
    const focusedOrder = this._getFocusedOrder()
    const notesOrder = focusedOrder.get('notes')
    if (mode === CREATE) {
      if (_.isNil(notesOrder)) {
        return
      }
      let data = xUtil.deleteNotesOrder(notesOrder.toJS(), 2)
      if (_.isEmpty(data)) {
        data = null
      }
      this.props.addNotesOrder(data)
      this.setState({
        showInput: true,
        showThisOrderNote: false,
        notes: data,
        cancalBtn: true,
        showNotChoose: false,
      })
      // p.op.showToast('ลบหมายเหตุใบปะหน้าเรียบร้อยแล้ว', 'success', 1000)
    } else {
      const updated_at = xUtil.getTimeFormatYYYYMMDDhhmmss()
      const { selectedStore, navigation } = this.props
      this.props.deleteOrderNote({
        body: {
          store_id: selectedStore.get('id'),
          order_id: focusedOrder.get('id'),
          note_id: note.id,
          updated_at,
        },
        successCallback: (res) => {
          const newNotes = []
          notes.map((val) => {
            if (val.id !== note.id) {
              newNotes.push(val)
            }
          })
          this.setState({
            showThisOrderNote: false,
            showInput: true,
            notes: newNotes,
            cancalBtn: true,
          })
          p.op.showToast('ลบหมายเหตุใบปะหน้าเรียบร้อยแล้ว', 'success', 2000)
        },
        failedCallback: () => {
          xUtil.navGoBack(this.props)
          p.op.showToast('พบข้อผิดพลาด กรุณาทำรายการใหม่ภายหลัง', 'danger', 2000)
        },
      })
    }
  }

  _copyToClipboard(note) {
    Clipboard.setString(note)
    p.op.showToast(`คัดลอกลิงก์ ${note} เรียบร้อยแล้ว`, 'success')
  }

  _selectNoteOption = async (selected, option) => {
    const { selectedStore } = this.props
    const { mode } = this.state
    const focusedOrder = this._getFocusedOrder()
    const orderType = focusedOrder.get('type')

    if (mode === READ_ONLY_MODE && orderType !== 1) {
      p.op.showConfirmationOkOnly(
        null,
        'อยู่โหมดป้องกันการแก้ไข เนื่องจากออเดอร์นี้ไม่มีสินค้าที่คุณต้องจัดส่งหากต้องการแก้ไข กรุณาแก้ที่ออเดอร์ซื้อ ที่เกี่ยวข้อง'
      )
      return
    }
    if (mode === READ_ONLY_ORDER_LOCK_MODE && orderType !== 1) {
      p.op.showConfirmationOkOnly(
        null,
        'อยู่โหมดป้องกันการแก้ไข เนื่องจากออเดอร์นี้ถูกล็อกอยู่หากต้องการแก้ไข กรุณาแจ้งให้ผู้ขายปลดล็อกก่อน'
      )
      return
    }
    if (mode === CREATE) {
      this.deleteNote(null)
      return
    }

    if (option === 0) {
      return
    }
    if (selected) {
      return
    }
    const checkMyNote = _.find(this.state.notes, (thisNote) => thisNote.created_by === 'm' && thisNote.type === 2)
    if (!_.isNil(checkMyNote)) {
      await new Promise(async (resolve) => {
        p.op.showConfirmation(
          null,
          'หากคุณเปลี่ยนหมายเหตุบนใบปะหน้า หมายเหตุบนใบปะหน้าที่คุณเพิ่มในออเดอร์นี้จะหายไป ต้องการทำรายการหรือไม่',
          () => {
            setTimeout(resolve, 200)
          },
          () => {},
          'ใช่',
          'ยกเลิก'
        )
      })
    }

    // let oldNote = _.find(this.state.notes, note => note.created_by === 'm' && note.type === 2)

    this.props.selectOrderNote({
      body: {
        store_id: selectedStore.get('id'),
        order_id: focusedOrder.get('id'),
        option,
        type: 2,
      },
      successCallback: (res) => {
        const newNotes = res.note_options
        // let uploadingThisOrderNote = true
        // let note = _.find(res.note_options, note => note.created_by === 'm' && note.type === 1)
        if (option === 3) {
          this.setState({
            notes: newNotes,
            showInput: true,
            inputValue: null,
            showThisOrderNote: false,
            showNotChoose: true,
            cancalBtn: true,
            oldNote: null,
          })
        } else {
          this.setState({
            notes: newNotes,
            showInput: true,
            inputValue: null,
            showThisOrderNote: false,
            cancalBtn: true,
            oldNote: null,
          })
        }
      },
      failedCallback: () => {
        xUtil.navGoBack(this.props)
        p.op.showToast('พบข้อผิดพลาด กรุณาทำรายการใหม่ภายหลัง', 'danger', 2000)
      },
    })
  }

  titleNote = () => {
    const { mode } = this.state
    const focusedOrder = this._getFocusedOrder()
    const pendingShpLblNote = focusedOrder.get('pending_shp_lbl_note')
    const textReadOnlyMode =
      'อยู่โหมดป้องกันการแก้ไข เนื่องจากออเดอร์นี้ไม่มีสินค้าที่คุณต้องจัดส่งหากต้องการแก้ไข กรุณาแก้ที่ออเดอร์ซื้อ ที่เกี่ยวข้อง'
    const textReadOnlyModeLockMode = 'อยู่โหมดป้องกันการแก้ไข เนื่องจากออเดอร์นี้ถูกล็อกอยู่หากต้องการแก้ไข กรุณาแจ้งให้ผู้ขายปลดล็อกก่อน'
    const defaultText = 'หมายเหตุที่เลือกไว้ จะถูกพิมพ์ออกมาบนใบปะหน้าพัสดุ'
    let modeText
    if (mode === READ_ONLY_MODE) {
      modeText = textReadOnlyMode
    } else if (mode === READ_ONLY_ORDER_LOCK_MODE) {
      modeText = textReadOnlyModeLockMode
    }
    return (
      <View>
        {pendingShpLblNote ? (
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: COLORS.FORM_SUCCESS_BG,
              marginTop: 4,
              marginBottom: 4,
              paddingTop: 2,
              paddingBottom: 2,
            }}>
            {/* <View style={{ width: 40, alignItems: 'center' }}>
                <Icon name='lock' style={{ color: COLORS.FORM_SUCCESS }} />
              </View> */}
            <View style={{ flex: 1 }}>
              <XText style={[TXT_LABEL_SMALLER, { textAlign: 'center' }]}>มีหมายเหตุใบปะหน้าที่รอผู้ซื้อยืนยัน</XText>
            </View>
          </View>
        ) : null}
        {!_.isNil(modeText) ? (
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: COLORS.FORM_SUCCESS_BG,
              marginTop: 2,
              marginBottom: 4,
            }}>
            <View style={{ width: 40, alignItems: 'center' }}>
              <XIcon name='lock-closed' family='Ionicons' style={{ color: COLORS.FORM_SUCCESS, flex: 0, minWidth: 25, width: 25 }} />
            </View>
            <View style={{ flex: 1 }}>
              <XText style={[TXT_LABEL_SMALLER, { textAlign: 'center' }]}>{modeText}</XText>
            </View>
          </View>
        ) : null}
        <Box>
          <XText variant='inactive'>{defaultText}</XText>
        </Box>
      </View>
    )
  }

  renderHeader = () => {
    const { navigation } = this.props
    return <XCustomHeader title='หมายเหตุบนใบปะหน้า' headerLeftProps={{ backIcon: true, onPressItem: this.handleBackBtn }} />
  }

  renderContent = () => {
    const { showInput, showThisOrderNote, showSelectOrderSellNote, showOrderReceiverNote, showNotChoose } = this.state
    return (
      <VStack w='full' px='2' py='1'>
        <XCard w='full'>
          <XCard.Body>
            {this.titleNote()}
            {/* { this._renderDeleteBtn() } */}
            {/* <Box>
        <XText variant='inactive'>หมายเหตุที่เลือกไว้ จะถูกพิมพ์ออกมาบนใบปะหน้าพัสดุ</XText>
      </Box> */}

            {showOrderReceiverNote ? this._renderOrderReceiverNote() : null}
            {showSelectOrderSellNote ? this._renderSelectOrderSellNote() : null}
            {showThisOrderNote ? this._renderThisOrderNote() : null}
            {showInput ? this._renderInputNote() : null}
            {showNotChoose ? this._renderNotChoose() : null}
          </XCard.Body>
        </XCard>
      </VStack>
    )
  }

  renderMain = () => (
    <XContainer>
      {this.renderHeader()}
      <XContent>{this.renderContent()}</XContent>
    </XContainer>
  )

  render() {
    return this.renderMain()
  }
}

const btnStyle = {
  ...btnOnMode(xCONS.BTN_MODE.ACTIVE_SECONDARY),
  borderColor: COLORS.BRAND_Danger,
  borderRadius: 4,
}

const txtStyle = {
  ...textOnBtnMode(xCONS.BTN_MODE.ACTIVE_SECONDARY),
  color: COLORS.BRAND_Danger,
}
