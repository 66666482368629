import React from 'react'
import _ from 'lodash'

import { IProductListViewPullMyProductsToSalePageNavParams, IProductListViewProps, IProductListViewState, IProductItemData } from 'x/index'
import * as util from 'x/utils/util'
import p from 'x/config/platform-specific'

import NavHeaderButton from 'xui/components/NavHeaderButton'
import BaseUIProductListView from './BaseUIProductListView'
import AddProductToSalePageButton from './AddProductToSalePageButton'

type IProductListViewPullMyProductsToSalePageProps = IProductListViewProps<IProductListViewPullMyProductsToSalePageNavParams>

const INITIAL_QUICKADD_REF_NUMBER = 500

class ProductListViewPullMyProductsToSalePage extends BaseUIProductListView<
  IProductListViewPullMyProductsToSalePageProps,
  IProductListViewState
> {
  static displayName = 'ProductListViewPullMyProductsToSalePage'

  quickAddButtonRefs?: {
    [key: number]: React.RefObject<AddProductToSalePageButton>
  }

  constructor(props) {
    super(props)
    this.quickAddButtonRefs = {}
    for (let i = 0; i < INITIAL_QUICKADD_REF_NUMBER; i++) {
      this.quickAddButtonRefs[i] = React.createRef()
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.mainProductCount > INITIAL_QUICKADD_REF_NUMBER) {
      for (let i = INITIAL_QUICKADD_REF_NUMBER; i < nextState.mainProductCount; i++) {
        this.quickAddButtonRefs[i] = React.createRef()
      }
    }
    return true
  }

  // override
  isRequiredProductVariants = () => true

  // override
  isAddProductButtonVisible = () => false

  // override
  getHaveNoProductItemText = () =>
    'ไม่พบรายการสินค้า\nคุณต้องมีสินค้าของคุณเอง หรือดึงสินค้ามาจากร้านขายส่ง จึงจะมีสินค้าที่สามารถใช้เปิดออเดอร์ได้'

  // override
  renderProductListItemBottom = (item: { index: number; data: IProductItemData }) => (
    <AddProductToSalePageButton
      w='full'
      ref={this.quickAddButtonRefs[item.index]}
      data={item.data}
      onPress={() => this._onPressAddOneProductToSalePage(item.data, { btnRef: this.quickAddButtonRefs[item.index] })}
    />
  )

  // override
  getModifiedRequestBody = (body) => {
    // const { navigation, selectedStore } = this.props
    // const myStoreId = util.getNavParam(this.props, 'store_id')
    // const sellerStoreId = util.getNavParam(this.props, 'seller_store_id')
    let newBody = _.cloneDeep(body)
    newBody = this._applyUgIdToRequestBody(newBody)
    newBody = this._applyPgIdsToRequestBody(newBody)
    return newBody
  }

  // implement abstract
  onPressProductItem = (item) => {
    // console.log('ProductListViewPullSellersProductsToMyStore onPressProductItem item => ', item)
    // this.navToProductViewPullMyProductToSalePageMode(item.data.id)
  }

  _applyUgIdToRequestBody = (body) => {
    const { navigation, selectedStore } = this.props
    const ug_id = util.getNavParam(this.props, 'ug_id')
    const newBody = _.cloneDeep(body)
    if (ug_id) {
      newBody.user_group_id = ug_id
    }
    return newBody
  }

  _applyPgIdsToRequestBody = (body) => {
    const { navigation, selectedStore } = this.props
    const pg_ids = util.getNavParam(this.props, 'pg_ids')
    const newBody = _.cloneDeep(body)
    if (pg_ids) {
      newBody.product_group_ids = pg_ids
    }
    return newBody
  }

  _onPressAddOneProductToSalePage = async (product: IProductItemData, option?: { btnRef?: any }) => {
    // const { navigation, selectedUserGroups } = this.props

    // const onProductListItemButtonPressed = util.getNavParam(this.props, 'onProductListItemButtonPressed')
    // if (!_.isFunction(onProductListItemButtonPressed)) {
    //   throw new Error('onProductListItemButtonPressed is required')
    // }
    // // console.log('product // => ', product)
    // // console.log('_onPressAddOneProductToSalePage product => ', product)
    // onProductListItemButtonPressed(product)

    // p.op.showToast('yoyoyo', 'success')

    // const { navigation, selectedUserGroups, quickAddProductToSalePage } = this.props
    const onProductListItemButtonPressed = util.getNavParam(this.props, 'onProductListItemButtonPressed')
    if (!_.isFunction(onProductListItemButtonPressed)) {
      throw new Error('onProductListItemButtonPressed is required')
    }
    const mode = util.getNavParam(this.props, 'mode')
    // console.log('mode => -- > ', mode)
    // console.log('_onPressAddOneProductToSalePage product => ', product)
    if (product.v.length > 1) {
      const BUTTON_LABELS = []
      product.v.forEach((variant) => {
        BUTTON_LABELS.push(variant.n)
      })
      BUTTON_LABELS.push('ยกเลิก')
      const selectedIndex = await new Promise<number>((chooseAtIndex) => {
        p.op.showActionSheet(
          {
            options: BUTTON_LABELS,
            cancelButtonIndex: BUTTON_LABELS.length - 1,
            // destructiveButtonIndex: -1,
            title: 'เลือกสินค้าเพื่อเพิ่ม 1 ชิ้นในรายการตัวเลือก...',
          },
          (idx: string | number) => {
            // log('ActionSheet.show idx => ', idx)

            const intIdx = _.isString(idx) ? parseInt(idx) : idx
            chooseAtIndex(intIdx)
          }
        )
      })

      const selectedVariant = [product.v[selectedIndex]]

      // console.log('selectedIndex => ', selectedIndex)
      if (selectedIndex === -1) {
        return
      }
      // console.log('selectedVariant => ', selectedVariant)
      const newProduct = _.clone(product)
      newProduct.v = selectedVariant
      // console.log('newProduct => ', newProduct)
      onProductListItemButtonPressed(newProduct)
      // p.op.showToast(`เพิ่มสินค้า ${selectedVariant[0].n} เรียบร้อยแล้ว`, 'success')
      // @ts-ignore
      if (mode === 'SINGLE_PRODUCT_MODE') {
        util.navGoBack(this.props)
      }
    } else {
      onProductListItemButtonPressed(product)
      // p.op.showToast(`เพิ่มสินค้า ${product.n} เรียบร้อยแล้ว`, 'success')
      // @ts-ignore
      if (mode === 'SINGLE_PRODUCT_MODE') {
        util.navGoBack(this.props)
      }
    }
  }

  // navToProductViewPullMyProductToSalePageMode = (product_id: number) => {
  //   const { navigation } = this.props
  //   const storeId = util.getNavParam(this.props, 'store_id')
  //   // const sellerStoreId = util.getNavParam(this.props, 'seller_store_id')
  //   const pg_ids = util.getNavParam(this.props, 'pg_ids')
  //   const ug_id = util.getNavParam(this.props, 'ug_id')
  //   navigation.dispatch(
  //     NavActions.navToProductViewPullMyProductToSalePage({
  //       store_id: storeId,
  //       product_id,
  //       ug_id,
  //       pg_ids,
  //       onSuccessPullMyProductToSalePageCallback: this._onSuccessPullMyProductToSalePageCallback,
  //     })
  //   )
  // }

  // ทำการ goBack กลับไปยัง OrderView ถ้าหากมีการเลือก Add Product จาก Product Detail
  _onSuccessPullMyProductToSalePageCallback = async () => {
    await util.delay(500)
    util.navGoBack(this.props)
  }

  renderGoBackButton = () => {
    const goBackSalePage = util.getNavParam(this.props, 'goBackSalePage')

    const goBackForSalePage = () => {
      // console.log('Woo Toooo !! ', goBackSalePage)
      this.props.navigation.pop(2)
      // if (_.isFunction(goBackSalePage)) {
      //   // @ts-ignore
      //   goBackSalePage()
      //   this.props.navigation.pop(1)
      // }
    }
    return <NavHeaderButton backIcon onPressItem={() => goBackForSalePage()} />
  }
}

export default ProductListViewPullMyProductsToSalePage
