import React, { useState } from 'react'

import * as util from 'x/utils/util'

import XCustomHeader from 'xui/components/XCustomHeader'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import XCard from 'xui/components/XCard'
import XText from 'xui/components/XText'
import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import XInput from 'xui/components/XInput'
import api from 'x/utils/api'
import p from 'x/config/platform-specific'
import { TouchableOpacity, Linking, Clipboard } from 'react-native'
import XFaqModal from 'xui/components/XFaqModal'
import FAQ_CONSTANTS from 'x/config/FAQ_CONSTANTS'
import XButton from 'xui/components/XButton'
import { COLORS } from 'x/config/styles'

// interface IReauthLazadaViewProps {
//   store_id: number
//   mkp_ch_id: number
// }
// onPress={() => Linking.openURL('https://marketplace.lazada.co.th/web/detail.html?articleCode=FW_GOODS-1000011969')}
export const LAZADA_REAUTH_LINK = () => {
  if (p.op.isWeb()) {
    return (
      <VStack>
        <XText bold>ขั้นตอนการรับ Lazada Auth Code</XText>
        <XText>{`1. กดที่ปุ่มด้านล่างเปิดหน้าเว็บ Lazada Service Marketplace
2. กดซื้อแพ็กเกจ XSelly เชื่อม Lazada (ราคา 1 บาท)
3. (*สำคัญ) ล็อกอินด้วยบัญชีร้านของ Lazada ที่ต้องการเชื่อมต่อ
4. กดปุ่มตามที่แสดงในหน้า (ดูตัวอย่างได้ในคลิปด้านล่าง) เพื่อรับ Lazada Auth Code
5. นำ Lazada Auth Code ที่ได้มาวางในกล่องสีส้มอ่อนด้านล่าง แล้วกดปุ่ม "บันทึก" ด้านขวาบน`}</XText>
        <HStack alignItems='center' justifyContent='center' my='2'>
          <XButton
            onPress={() => Linking.openURL('https://marketplace.lazada.co.th/web/detail.html?articleCode=FW_GOODS-1000011969')}
            h='7'
            p='0'
            variant='outline'>
            <XText color={COLORS.APP_MAIN} fontSize='xs' p='1'>
              เปิดหน้าเว็บ Lazada Service Marketplace
            </XText>
          </XButton>
        </HStack>
      </VStack>
    )
  }
  return (
    <VStack>
      <XText bold>ขั้นตอนการรับ Lazada Auth Code</XText>
      <XText>{`1. กดที่ปุ่มด้านล่างเพื่อคัดลอกลิงก์หน้าเว็บ Lazada Service Marketplace
2. เปิด ลิงก์ที่คัดลอกใน เว็บบราว์เซอร์เช่น Chrome หรือ Edge
3. กดซื้อแพ็กเกจ XSelly เชื่อม Lazada (ราคา 1 บาท)
4. (*สำคัญ) ล็อกอินด้วยบัญชีร้านของ Lazada ที่ต้องการเชื่อมต่อ
5. กดปุ่มตามที่แสดงในหน้า (ดูตัวอย่างได้ในคลิปด้านล่าง) เพื่อรับ Lazada Auth Code
6. นำ Lazada Auth Code ที่ได้มาวางในกล่องสีส้มอ่อนด้านล่าง แล้วกดปุ่ม "บันทึก" ด้านขวาบน`}</XText>
      <HStack alignItems='center' justifyContent='center' my='2'>
        <XButton
          onPress={() => {
            p.op.showToast('คัดลอกลิงก์เรียบร้อยแล้ว', 'success')
            Clipboard.setString('https://marketplace.lazada.co.th/web/detail.html?articleCode=FW_GOODS-1000011969')
          }}
          h='7'
          p='0'
          variant='outline'>
          <XText color={COLORS.APP_MAIN} fontSize='xs' p='1'>
            คัดลอกลิงก์
          </XText>
        </XButton>
      </HStack>
    </VStack>
  )
}

const ReauthLazadaView = (props) => {
  // const { webMaxScreenWidth, setPrefTimeslot, selectedStore, updateNewChannelList } = props
  const store_id = util.getNavParam(props, 'store_id')
  const mkp_ch_id = util.getNavParam(props, 'mkp_ch_id')
  const callBackFromLazadaReauth = util.getNavParam(props, 'callBackFromLazadaReauth')

  const [apiKey, updateChannel] = useState('')
  const [isEdit, setEdit] = useState(false)

  const navBack = () => {
    util.navGoBack(props)
  }

  const _cancelBtn = () => {
    p.op.showConfirmation(
      '',
      'ออกจากหน้านี้ใช่หรือไม่',
      () => navBack(),
      () => null,
      'ออกจากหน้านี้',
      'อยู่ในหน้านี้'
    )
  }

  const _saveBtn = async () => {
    const pattern = /^[a-zA-Z0-9_]+$/
    const isValid = pattern.test(apiKey)
    if (!isValid || apiKey.length < 20) {
      p.op.showConfirmationOkOnly('API Key ไม่ถูกต้อง', 'กรุณาตรวจสอบ Lazada Auth Code แล้วลองใหม่อีกครั้ง', () => null, 'ปิด')
      return
    }
    const apiOptions = {
      showSpinner: true,
    }
    const body = {
      store_id,
      mkp_ch_id,
      code: apiKey,
    }
    // return
    try {
      const res = await api.postV2('mkp/v2/lazada/auth/reauth', body, apiOptions)
      // console.log('res channel => ', res)
      if (res.channel) {
        callBackFromLazadaReauth(res.channel)
        p.op.showToast('เชื่อมต่อเรียบร้อยแล้ว', 'success')
        navBack()
      }
    } catch (error) {
      console.log('error => ', error)
    }
  }

  const leftBtn = isEdit ? { label: 'ยกเลิก', onPressItem: () => _cancelBtn() } : { backIcon: true, onPressItem: () => navBack() }
  const rightBtn = isEdit ? { label: 'บันทึก', onPressItem: () => _saveBtn() } : null

  const _renderTextFAQ = (onPressOpenOverlay: () => void) => (
    <TouchableOpacity style={{ paddingLeft: 4 }} onPress={() => onPressOpenOverlay()}>
      <XText color='blue.800' textDecorationLine='underline'>
        ดูคลิปวิธีเชื่อมต่อ Lazada
      </XText>
    </TouchableOpacity>
  )

  return (
    <XContainer>
      <XCustomHeader
        // headerStyle={{ marginTop: 0 }}
        // headerLeftProps={{
        //   backIcon: true,
        //   onPressItem: navBack,
        // }}
        headerLeftProps={leftBtn}
        headerRightProps={rightBtn}
        title='เชื่อมต่อ Lazada'
      />
      <XContent p='2'>
        <XCard p='2'>
          <VStack pb='2'>
            <VStack alignItems='center'>
              <XText variant='active'>กรุณาระบุ Lazada Auth</XText>
              {/* <XText variant='inactive'>หมายเหตุ: คุณสามารถ "ดูคลิปแนะนำการเตรียมและเชื่อมช่องทางขาย" ในหน้าก่อนหน้านี้ได้</XText> */}
              {/* <TouchableOpacity
                style={{ paddingLeft: 4 }}
                onPress={() => Linking.openURL('https://xselly.com/redirect_to_lazada_service_mkp.html')}
                // onPress={() => window.open('https://marketplace.lazada.co.th/web/detail.html?articleCode=FW_GOODS-1000011969', '_blank')}
              >
                <XText color='blue.800' textDecorationLine='underline'>
                  กดที่นี่เพื่อเปิดเว็บ Lazada Service Marketplace และซื้อโค้ดการเชื่อมต่อกับ XSelly (เพียง 1 บาท)
                </XText>
              </TouchableOpacity> */}
              <LAZADA_REAUTH_LINK />
            </VStack>

            <HStack flex={1} alignItems='center' justifyContent='center'>
              <XFaqModal
                key='Lazada เมื่อหมดอายุ'
                headerTitle='Lazada เมื่อหมดอายุ'
                // @ts-ignore
                FAQ={FAQ_CONSTANTS.FAQ_RE_AUTH_LAZADA}
                initiateOpenIndex={[0]}
                renderButton={(onPressOpenOverlay: () => void) => _renderTextFAQ(onPressOpenOverlay)}
              />
            </HStack>

            <HStack w='full' h='20' mt='1'>
              <XInput
                value={apiKey}
                multiline
                style={{ flex: 1 }}
                onChangeText={(text) => {
                  updateChannel(text.trim())
                  setEdit(true)
                }}
              />
            </HStack>
          </VStack>
        </XCard>
      </XContent>
    </XContainer>
  )
}

// // @ts-ignore disable react-navigation header
// TimeSlotsView.navigationOptions = {
//   header: null,
// }

export default ReauthLazadaView
