import React from 'react'
import { View, StyleProp, ViewStyle, StyleSheet } from 'react-native'

import FAQ, { IFaqKey } from 'x/config/FAQ'

import HelpButton from './HelpButton'
import XHelpModal from './XHelpModal'

interface IFaqHelpButtonProps {
  headerTitle: string
  faqKey: IFaqKey
  containerStyle?: StyleProp<ViewStyle>
  helpBtnStyle?: StyleProp<ViewStyle>
}

const FaqHelpButton: React.FC<IFaqHelpButtonProps> = (props) => {
  if (!props.headerTitle) {
    throw new Error('FaqHelpButton required headerTitle')
  }

  if (!props.faqKey) {
    throw new Error('FaqHelpButton required faqKey')
  }

  const selectedFAQ = FAQ[props.faqKey]

  const renderHelpBtn = (onPressOpenOverlay: () => void) => {
    return (
      <View style={[styles.container, StyleSheet.flatten(props.containerStyle)]}>
        <HelpButton
          // @ts-ignore
          style={StyleSheet.flatten([styles.helpBtn, StyleSheet.flatten(props.helpBtnStyle)])}
          onPress={onPressOpenOverlay}
        />
      </View>
    )
  }

  return <XHelpModal headerTitle={props.headerTitle} FAQ={selectedFAQ} renderButton={renderHelpBtn} />
}

const styles = StyleSheet.create({
  container: {
    // width: 30,
  },
  helpBtn: {
    minWidth: 10,
    width: 10,
  },
})

FaqHelpButton.defaultProps = {
  containerStyle: {},
  helpBtnStyle: {},
}

export default FaqHelpButton
