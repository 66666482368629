import { Map, fromJS } from 'immutable'
import _ from 'lodash'
import actions from '../../config/actions'
// import {
// // getFBAccessToken,
// // setFBAccessTokenInProfile,
// } from '../../redux/selectors'
// import * as util from '../../utils/util'

const {
  MY_SUBSCRIPTION_FETCH,
  MY_SUBSCRIPTION_LOAD,

  MY_SUBSCRIPTION_WARNING_SHOW,
  MY_SUBSCRIPTION_WARNING_HIDE,
  MY_SUBSCRIPTION_QUOTA_WARNING_SHOW,
  MY_SUBSCRIPTION_QUOTA_WARNING_HIDE,
} = actions

const initWarning: {
  type: 'warning' | 'danger' | 'success'
  message: string | null
  closeable: boolean | null
  priority: number | null
} = {
  type: 'warning',
  message: null,
  closeable: true,
  priority: null,
}

// Initial state
const initialState = Map({
  user_id: null,
  billing_date: null,
  startBillingDate: null,
  endBillingDate: null,
  order_count_curr: null,
  order_count_quota: null,
  packages: null,

  lastUpdated: null,
  isActiveWarning: false,
  warning: initWarning,

  isActiveQuotaWarning: false,
  warningQuota: initWarning,
})

// Actions
export const INITIALIZE_STATE = 'MySubscription/INITIALIZE'

export function fetchSubscription(params?: { successCallback: (res) => void; failedCallback: (error) => void }) {
  if (!params) {
    return { type: MY_SUBSCRIPTION_FETCH }
  }
  const { successCallback, failedCallback } = params
  return {
    type: MY_SUBSCRIPTION_FETCH,
    successCallback,
    failedCallback,
  }
}

export function loadSubscription(subscriptionData) {
  return {
    type: MY_SUBSCRIPTION_LOAD,
    payload: subscriptionData,
  }
}

export function showWarning(warningObj = initWarning, successCallback = null) {
  return {
    type: MY_SUBSCRIPTION_WARNING_SHOW,
    payload: warningObj,
    successCallback,
  }
}
export function hideWarning() {
  return {
    type: MY_SUBSCRIPTION_WARNING_HIDE,
  }
}

export function showQuotaWarning(warningObj = initWarning, successCallback = null) {
  return {
    type: MY_SUBSCRIPTION_QUOTA_WARNING_SHOW,
    payload: warningObj,
    successCallback,
  }
}
export function hideQuotaWarning() {
  return {
    type: MY_SUBSCRIPTION_QUOTA_WARNING_HIDE,
  }
}

// Reducer
export default function MySubscriptionStateReducer(state = initialState, action: { [key: string]: any } = { type: null, payload: null }) {
  let newState = state
  const { type, payload } = action

  switch (type) {
    case INITIALIZE_STATE:
    case MY_SUBSCRIPTION_LOAD:
      return newState.mergeDeep(fromJS(payload))

    case MY_SUBSCRIPTION_WARNING_SHOW: {
      const newWarning = _.has(payload, 'priority') ? payload.priority : 0
      const oldWarning = newState.getIn(['warning', 'priority'])
      // console.log('oldWarning.priority => ', oldWarning)
      // console.log(newWarning)
      // ลำดับความสำคัญของการแจ้งเตือน (priority)
      // priority 1 คือ แจ้งเตือนอายุการใช้งานเหลือ 15 วัน
      // priority 2 คือ แจ้งเตือนการเปิดออเดอร์ใกล้เต็มหรือออเดอร์เกินโควต้า
      // priority 3 คือ แจ้งเตือนหากอายุการใช้งานเหลือตั้งแต่ 2 วันลงไป
      if (oldWarning === null || oldWarning < newWarning) {
        // หากไม่มีการแจ้งเตือนหรือการแจ้งเตือนใหม่มี priority ที่สุงกว่า
        if (_.has(action, 'successCallback') && _.isFunction(action.successCallback)) {
          action.successCallback()
        }
        newState = newState.set('isActiveWarning', true)
        return newState.set('warning', payload)
      }
      return state
    }
    case MY_SUBSCRIPTION_WARNING_HIDE:
      newState = newState.set('isActiveWarning', false)
      return newState.set('warning', initWarning)

    case MY_SUBSCRIPTION_QUOTA_WARNING_SHOW: {
      const newWarning = _.has(payload, 'priority') ? payload.priority : 0
      const oldWarning = newState.getIn(['warningQuota', 'priority'])
      // console.log('oldWarning.priority => ', oldWarning)
      // console.log(newWarning)
      // ลำดับความสำคัญของการแจ้งเตือน (priority)
      // priority 1 คือ แจ้งเตือนอายุการใช้งานเหลือ 15 วัน
      // priority 2 คือ แจ้งเตือนการเปิดออเดอร์ใกล้เต็มหรือออเดอร์เกินโควต้า
      // priority 3 คือ แจ้งเตือนหากอายุการใช้งานเหลือตั้งแต่ 2 วันลงไป
      if (oldWarning === null || oldWarning < newWarning) {
        // หากไม่มีการแจ้งเตือนหรือการแจ้งเตือนใหม่มี priority ที่สุงกว่า
        if (_.has(action, 'successCallback') && _.isFunction(action.successCallback)) {
          action.successCallback()
        }
        newState = newState.set('isActiveQuotaWarning', true)
        return newState.set('warningQuota', payload)
      }
      return state
    }
    case MY_SUBSCRIPTION_QUOTA_WARNING_HIDE:
      newState = newState.set('isActiveQuotaWarning', false)
      return newState.set('warningQuota', initWarning)

    default:
      return state
  }
}
