import React from 'react'
import { STYLES, COLORS } from 'x/config/styles'

import BaseUIReportSalesByChannelView from 'xui/modules/report/BaseUIReportSalesByChannelView'
import { View, Text } from 'react-native'
import * as XUtil from 'x/utils/util'
import * as util from '../../utils/util-web'
import DateRangePicker from '../../components/DateRangePicker'
// import { VIEW_HEIGHT_TEN } from 'xui/utils/BaseShareUI'

export default class ReportSalesByChannelView extends BaseUIReportSalesByChannelView {
  _downloadReportShippingView = async (url: string, fileName: string) => {
    await XUtil.setStatePromise(this, { isLoading: true })
    if (url) {
      await util.downloadFileFromUrl({ url, fileName })
    }
    await XUtil.setStatePromise(this, { isLoading: false })
  }

  _renderShippingDateRange = () => {
    const isVisible = this._isVisibleDateRangePicker()
    const isDisabledDateRange = this._computeAllowDays() <= 1
    return isVisible ? (
      <View style={{ height: 80, marginTop: 8 }}>
        {/* {VIEW_HEIGHT_TEN} */}
        {/* <View style={{ height: 80 }}> */}
        <DateRangePicker
          disabled={isDisabledDateRange}
          allowOnlyDaysFromToday={this._computeAllowDays()}
          allowDateRangeLimitDays={this.limitDays}
          selectedDates={this.state.selectedDateRange}
          onChangeDate={this._onChangeDateRange}
          oneLineDateTime={false}
        />
        {/* </View> */}
        <View style={{ width: '100%', height: 30 }}>
          {isDisabledDateRange ? (
            <Text
              style={{
                fontSize: STYLES.FONT_SIZE_SMALLER,
                color: COLORS.TEXT_INACTIVE,
                textAlign: 'center',
              }}>
              {this.TXT_WARNING_DATE_RANGE}
            </Text>
          ) : (
            <Text
              style={{
                fontSize: STYLES.FONT_SIZE_SMALLER,
                color: COLORS.APP_MAIN,
                textAlign: 'center',
              }}>
              {this.state.dateRangeDescription}
            </Text>
          )}
        </View>
      </View>
    ) : (
      <View style={{ width: '100%', height: 10 }} />
    )
  }
}
