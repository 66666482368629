import Reactotron, { trackGlobalErrors } from 'reactotron-react-js'
import { reactotronRedux } from 'reactotron-redux'
import sagaPlugin from 'reactotron-redux-saga'
// import apisaucePlugin from 'reactotron-apisauce' // <--- for api axios tracking

// console.disableYellowBox = true
// const emptyFunc = () => {
//   return
// }
// console.tron = { log: emptyFunc, display: emptyFunc }

// if we're running in DEV mode, then let's connect!
if (process.env.NODE_ENV === 'development') {
  // First, set some configuration settings on how to connect to the app
  Reactotron.configure({
    name: 'XSelly Web Debug',
    // socketIoProperties: {
    //   reconnection: true,
    //   reconnectionDelay: 2000,
    //   reconnectionDelayMax: 5000,
    //   reconnectionAttempts: 5,
    // },
  })
    .use(reactotronRedux())
    // @ts-ignore
    .use(sagaPlugin())
    // .use(apisaucePlugin())
    .use(
      trackGlobalErrors({
        // offline: false
      })
    )
    .connect()

  // @ts-ignore
  console.tron = Reactotron
  // @ts-ignore
  Reactotron.clear()
}

export default process.env.NODE_ENV === 'development' ? Reactotron : null
