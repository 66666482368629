import React from 'react'
import { STYLES } from 'x/config/styles'
import { logRender, delay } from 'x/utils/util'
import BaseUIOrderShipListView from 'xui/modules/order/BaseUIOrderShipListView'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'

import * as NavActions from '../../utils/navigation'
// import ThemeSizeContext from '../../context/ThemeSizeContext'

class OrderShipListView extends BaseUIOrderShipListView {
  inProcess: boolean

  static displayName = 'OrderShipListView'

  // static contextType = ThemeSizeContext

  // context!: React.ContextType<typeof ThemeSizeContext>
  // static navigationOptions = ({ navigation }) => {
  //   const { goBack, state } = navigation
  //   const { txtTitle } = state.params
  //   const title = txtTitle || 'รายการจัดส่ง'
  //   return {
  //     headerLeft: (
  //       <Button
  //         transparent={true}
  //         onPress={() => {
  //           goBack(state.key)
  //         }}>
  //         <BackIcon />
  //       </Button>
  //     ),
  //     title,
  //     // headerTitleStyle: { fontSize: STYLES.FONT_SIZE_NORMAL },
  //     // headerRight: null,
  //   }
  // }

  // navToShipView = async () => {
  //   if (this._inProcess) {
  //     return
  //   }
  //   this._inProcess = true
  //   const { navigation } = this.props
  //   if (navigation) {
  //     const storeId = navigation.getParam('store_id', null)
  //     const orderId = navigation.getParam('order_id', null)
  //     navigation.dispatch(NavActions.navToShipView({ store_id: storeId, order_id: orderId }))
  //   }
  //   await delay(500)
  //   this._inProcess = false
  // }

  // renderContent = () => (
  //   <XContent
  //     style={[
  //       STYLES.NO_MARGIN_PADDING,
  //       { paddingTop: 3, paddingLeft: 3, paddingRight: 3 },
  //       this.context.contentWithNavHeaderHeightStyle,
  //       this.context.contentOneOfThreeColumnWidthStyle,
  //     ]}>
  //     {this._renderPartnerShipment()}
  //   </XContent>
  // )

  // render() {
  //   logRender(this)
  //   if (!this.state.isInitialized) {
  //     return null
  //   }
  //   return (
  //     <XContainer style={[this.context.contentHeightStyle, this.context.contentOneOfThreeColumnWidthStyle]}>
  //       {this.renderCustomHeader()}
  //       {this.renderContent()}
  //     </XContainer>
  //   )
  // }
}

export default OrderShipListView
