import React from 'react'

import BaseUIPaymentListView from 'xui/modules/payment/BaseUIPaymentListView'

import XGalleryModal from '../../components/XGalleryModal'

export default class PaymentListView extends BaseUIPaymentListView {
  static displayName = 'PaymentListView'

  static navigationOptions = {
    header: null,
    title: null,
    headerLeft: null,
    headerRight: null,
  }

  renderXGalleryModal = () => {
    // return null
    const { previewImageUris, previewVisible } = this.state
    return <XGalleryModal isVisible={previewVisible} onRequestClose={this._clearPreviewImages} images={previewImageUris} />
  }

  // renderCustomHeader = () => {
  //   // return null
  //   const { navigation } = this.props
  //   const btnRight = navigation.getParam('btnRight')
  //   let headerRightProps = null
  //   if (btnRight && _.isFunction(btnRight.onPress)) {
  //     headerRightProps = {
  //       addIcon: true,
  //       onPressItem: btnRight.onPress,
  //     }
  //   }
  //   // const fixedAndroidMarginTopStyle = this._getFixedAndroidMarginTopStyle()
  //   return (
  //     <XCustomHeader
  //       // headerStyle={fixedAndroidMarginTopStyle}
  //       headerLeftProps={{ backIcon: true, onPressItem: this._goBack }}
  //       headerRightProps={headerRightProps}
  //       title={this._getTxtHeaderTitle()}
  //     />
  //   )
  // }
}
