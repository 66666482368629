import React from 'react'
import BaseCustomerEditingView from 'x/modules/contact/BaseCustomerEditingView'

import * as util from 'x/utils/util'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import XCard from 'xui/components/XCard'
import { VStack } from 'native-base'
import XText from 'xui/components/XText'
import HStack from 'xui/components/HStack'
import XInput from 'xui/components/XInput'

export default abstract class BaseUICustomerEditingView extends BaseCustomerEditingView {
  goBack = () => {
    util.navGoBack(this.props)
  }

  renderCustomHeader = () => {
    const leftBtn = { backIcon: true, onPressItem: () => this.goBack() }
    return (
      <XCustomHeader
        title='แก้ไขข้อมูลลูกค้า'
        headerLeftProps={leftBtn}
        headerRightProps={{
          label: 'บันทึก',
          onPressItem: () => this._summit(),
        }}
      />
    )
  }

  _renderEditingView = () => (
    <XCard py='2'>
      <VStack space='2'>
        {this._renderInput('name', 'ชื่อ')}
        {this._renderInput('lastName', 'นามสกุล')}
        {this._renderInput('phone', 'เบอร์โทร')}
        {this._renderInput('email', 'อีเมล')}
      </VStack>
    </XCard>
  )

  _renderInput = (key: string, title: string) => (
    <HStack w='full'>
      <HStack w='16' justifyContent='flex-end' alignItems='center'>
        <XText textAlign='center'>{title}</XText>
      </HStack>
      <HStack flex={1} px='2'>
        <XInput flex={1} value={this.state[key]} onChangeText={(text: string) => this._onChangeText(key, text)} />
      </HStack>
    </HStack>
  )

  render() {
    return (
      <XContainer>
        {this.renderCustomHeader()}
        <XContent p='2'>{this._renderEditingView()}</XContent>
      </XContainer>
    )
  }
}
