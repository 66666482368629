import { Component } from 'react'
import _ from 'lodash'
import CONS from 'x/config/constants'
import * as util from 'x/utils/util'
import { ISelectedStoreMap, ActionApiParams, IXScreenProps } from 'x/index'
import p from 'x/config/platform-specific'

const { VIEW, EDIT } = CONS.MODE
interface IBaseResellerApprovalListViewProps extends IXScreenProps {
  dispatch: (params: any) => void
  selectedStore: ISelectedStoreMap
  fetchProfileList: (params: ActionApiParams) => void
  approvalsReseller: (params: ActionApiParams) => void
}

interface IBaseResellerApprovalListViewState {
  profiles: IOrgPendingList[]
  profilesSelcted: IOrgPendingList[]
  isMainCheckBox: boolean
  nameText: string
  lastNameText: string
  sellerCodeText: string
  isModelUploading: boolean
  isLoading: boolean
  emailText: string
  phoneText: string
  parentResellerText: string
  indexImportSegment: number
}

export interface IOrgPendingList {
  i: number
  fn: string
  ln: string
  nn: string
  f: string
  l: string
  t: string
  q: string
  s: {
    fn: string
    ln: string
    c: string
  }
  isCheckBox?: boolean
}

export default class BaseResellerApprovalListView extends Component<
  IBaseResellerApprovalListViewProps,
  IBaseResellerApprovalListViewState
> {
  // imgManagerRef: React.RefObject<any>
  OPTIONS_IMPORT: string[]

  protected constructor(props: IBaseResellerApprovalListViewProps) {
    super(props)
    // this.imgManagerRef = React.createRef()
    this.OPTIONS_IMPORT = ['ที่ IMPORT', 'ที่ สมัครใหม่']
    this.state = {
      profiles: null,
      profilesSelcted: null,
      isMainCheckBox: false,
      lastNameText: '',
      nameText: '',
      sellerCodeText: '',
      isModelUploading: false,
      isLoading: false,
      emailText: '',
      phoneText: '',
      parentResellerText: '',
      indexImportSegment: 0,
    }
  }

  async componentDidMount() {
    await this._loadProfiles()
  }

  _loadProfiles = async () => {
    const { fetchProfileList, selectedStore } = this.props
    const { indexImportSegment } = this.state
    // const organizations = selectedStore.get(`organizations`).toJS()
    // console.log(`organizations`, organizations)
    let isPending = indexImportSegment === 1
    let newIndexImportSegment = 0
    const type = util.getOrgResellerType(selectedStore)
    if (type === 1) {
      newIndexImportSegment = 1
      isPending = true
    }
    const res: { org_pending_list: IOrgPendingList[] } = await new Promise((resolve) => {
      const body: { [key: string]: any } = {
        org_id: 1,
        store_id: selectedStore.get(`id`),
        type: 'p',
        limit: 50,
        offset: 0,
        is_pending_reseller_code: isPending,
      }
      // console.log(`BODY `, body)
      fetchProfileList({
        body,
        // @ts-ignore
        successCallback: resolve,
        // @ts-ignore
        failedCallback: resolve,
      })
    })
    // console.log(`RES => `, res)
    if (res.org_pending_list) {
      util.setStatePromise(this, {
        profiles: res.org_pending_list,
        isModelUploading: false,
      })
      // return res.org_member_list
    } else {
      // return null
    }
    util.setStatePromise(this, { indexImportSegment: newIndexImportSegment })
  }

  _confirmationApprpovals = (mode: string) => {
    // const { profilesSelcted } = this.state
    // const sc = profilesSelcted.length * 40
    // const mn = profilesSelcted.length < 2 ? 1 : sc / 60
    // console.log(`mn => `, mn)
    let lableText = `ระบบอาจใช้เวลาประมาณ 40 วินาที ต่อการอนุมัติ 1 บัญชี`

    if (mode === 'r') {
      lableText = `ต้องการปฎิเสธตัวแทนที่เลือกกรุณากด "ดำเดินการต่อ"`
    }
    p.op.showConfirmation(
      ``,
      `${lableText}`,
      () => this._apprpovalReseller(mode),
      () => {},
      'ดำเนินการต่อ',
      'ยกเลิก'
    )
  }

  _apprpovalReseller = async (mode: string) => {
    if (mode === 'a') {
      await util.setStatePromise(this, {
        isModelUploading: true,
        isLoading: true,
      })
    }

    const { approvalsReseller, selectedStore } = this.props
    const { profilesSelcted } = this.state
    if (_.isNil(profilesSelcted) || profilesSelcted.length < 1) {
      return
    }
    const profileIds = []
    profilesSelcted.map((pf: IOrgPendingList) => {
      profileIds.push(pf.i)
    })
    const store_id = selectedStore.get(`id`)

    // TODO:: ยังหาวิธีประกาศ type ไม่ได้ มีเวลาอย่าลืมแวะดู ARTID
    const res: any = await new Promise((resolve) => {
      const body: { [key: string]: any } = {
        org_id: 1,
        profile_ids: profileIds,
        newStatus: mode,
        store_id,
      }

      approvalsReseller({
        body,
        // @ts-ignore
        successCallback: resolve,
        // @ts-ignore
        failedCallback: resolve,
      })
    })

    if (res.status && res.status === 'ok') {
      const showConfirmationLable = mode === 'a' ? `อนุมัติตัวแทนเรียบร้อยแล้ว` : `ปฎิเสธตัวแทนเรียบร้อยแล้ว`
      p.op.showConfirmationOkOnly(``, `${showConfirmationLable}`)

      await util.setStatePromise(this, {
        profilesSelcted: null,
        profiles: null,
        isMainCheckBox: false,
        isLoading: false,
      })
      await this._loadProfiles()
    } else {
      this._closeModalUploading()
    }
  }

  _handleLoadMore = () => {
    console.log(`LOAD MORE !`, this.state.profiles)
  }

  _onPressMainCheckBox = async (val: boolean) => {
    // console.log(`isMainCheckBox`, val)
    const { profiles } = this.state
    if (_.isNil(profiles) || profiles.length < 1) {
      return
    }
    const newProfiles = []
    profiles.map((profile: IOrgPendingList) => {
      const newProfile = profile
      newProfile.isCheckBox = val
      newProfiles.push(newProfile)
    })
    const newProfilesSelcted = val ? newProfiles : null
    await util.setStatePromise(this, {
      isMainCheckBox: val,
      profiles: newProfiles,
      profilesSelcted: newProfilesSelcted,
    })
  }

  _omPressCheckBox = async (selectedProfile: IOrgPendingList) => {
    const { profiles } = this.state
    const newProfiles = []
    const newProfilesSelcted = []
    profiles.map((profile: IOrgPendingList) => {
      if (profile.i === selectedProfile.i) {
        const newProfile = profile
        newProfile.isCheckBox = _.isNil(selectedProfile.isCheckBox) ? true : !selectedProfile.isCheckBox
        newProfiles.push(newProfile)
        if (newProfile.isCheckBox) {
          newProfilesSelcted.push(newProfile)
        }
      } else {
        newProfiles.push(profile)
        if (profile.isCheckBox) {
          newProfilesSelcted.push(profile)
        }
      }
    })
    await util.setStatePromise(this, {
      profiles: newProfiles,
      profilesSelcted: newProfilesSelcted,
    })
  }

  _onPressClearBtn = async () => {
    await util.setStatePromise(this, {
      profilesSelcted: null,
      profiles: null,
      nameText: '',
      lastNameText: '',
      sellerCodeText: '',
      emailText: '',
      phoneText: '',
      parentResellerText: '',
    })
  }

  _closeModalUploading = async () => {
    await util.setStatePromise(this, {
      isModelUploading: false,
    })
  }

  _onChangeImportSement = async (index: number) => {
    await util.setStatePromise(this, { indexImportSegment: index })
  }

  _searchMumber = async () => {
    const { fetchProfileList, selectedStore } = this.props
    const { nameText, lastNameText, sellerCodeText, emailText, phoneText, parentResellerText, indexImportSegment } = this.state
    const nameTextTrim = nameText.trim()
    const lastNameTextTrim = lastNameText.trim()
    const sellerCodeTextTrim = sellerCodeText.trim()
    const emailTextTrim = emailText.trim()
    const phoneTextTrim = phoneText.trim()
    const parentResellerTextTrim = parentResellerText.trim()
    if (
      nameTextTrim === '' &&
      lastNameTextTrim === '' &&
      sellerCodeTextTrim === '' &&
      emailTextTrim === '' &&
      phoneTextTrim === '' &&
      parentResellerTextTrim === ''
    ) {
      p.op.showConfirmationOkOnly(``, `กรุณากรอกข้อมูลอย่างน้อย 1 ช่อง`)
      return
    }
    if (
      nameTextTrim.length < 2 &&
      lastNameTextTrim.length < 2 &&
      sellerCodeTextTrim.length < 2 &&
      emailTextTrim.length < 2 &&
      phoneTextTrim.length < 2 &&
      parentResellerTextTrim.length < 2
    ) {
      p.op.showConfirmationOkOnly(``, `กรุณากรอกข้อมูลอย่างน้อย 3 ตัวอักษร`)
      return
    }
    // const organizations = selectedStore.get(`organizations`).toJS()
    // console.log(`organizations`, organizations)
    const res: { org_pending_list: IOrgPendingList[] } = await new Promise((resolve) => {
      const body: { [key: string]: any } = {
        org_id: 1,
        store_id: selectedStore.get(`id`),
        type: 'p',
        limit: 50,
        offset: 0,
      }

      if (nameText.length > 2) {
        body.first_name = nameTextTrim
      }
      if (lastNameText.length > 2) {
        body.last_name = lastNameTextTrim
      }
      if (sellerCodeText.length > 2) {
        body.reseller_code = sellerCodeTextTrim
      }
      if (emailTextTrim.length > 2) {
        body.contact_email = emailTextTrim
      }
      if (phoneTextTrim.length > 2) {
        body.phone_number = phoneTextTrim
      }
      if (parentResellerTextTrim.length > 2) {
        body.seller_code = parentResellerTextTrim
      }
      const isOrgOwnerStore = util.isOrgOwnerStore(selectedStore)
      if (isOrgOwnerStore) {
        body.is_pending_reseller_code = indexImportSegment === 1
      } else {
        body.is_pending_reseller_code = true
      }

      // console.log(`BODY `, body)
      fetchProfileList({
        body,
        // @ts-ignore
        successCallback: resolve,
        // @ts-ignore
        failedCallback: resolve,
      })
    })

    if (res.org_pending_list) {
      return res.org_pending_list
    }
    return null
  }
}
