import React from 'react'
import { IScrollViewProps } from 'native-base'
import { StyleSheet, ScrollView } from 'react-native'
import { getComputedCustomStyledProps } from 'x/config/nativebase/makeStyledComponent'
import theme from 'x/config/nativebase/theme'

export type IXScrollViewProps = IScrollViewProps

const XScrollView = React.forwardRef<ScrollView, IXScrollViewProps>((props, parentRef) => {
  const { style = {}, contentContainerStyle = {}, ...restRNProps } = props
  const { styleFromProps, underscoreProps, restProps } = getComputedCustomStyledProps(restRNProps, 'ScrollView')
  const { _contentContainerStyle = {} } = underscoreProps
  const { styleFromProps: styleContentContainerFromProps } = getComputedCustomStyledProps(_contentContainerStyle, 'Box')
  return (
    // @ts-ignore
    <ScrollView
      // @ts-ignore
      ref={parentRef}
      {...restProps}
      style={[styleFromProps, StyleSheet.flatten(style)]}
      contentContainerStyle={[styleContentContainerFromProps, StyleSheet.flatten(contentContainerStyle)]}
    />
  )
})

XScrollView.displayName = 'XScrollView'

// @ts-ignore
XScrollView.defaultProps = { theme }

// @ts-ignore
export default XScrollView
