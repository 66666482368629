// import EntypoIcon from 'react-native-vector-icons/Entypo'

// import BackIcon from '../../components/BackIcon'
// import XTabNavIcon from '../../components/XTabNavIcon'

import BaseUINextPackageDetail from 'xui/modules/subscription/BaseUINextPackageDetail'

export default class NextPackageDetail extends BaseUINextPackageDetail {
  static displayName = 'NextPackageDetail'
}
