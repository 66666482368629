/* eslint-disable react/no-unused-class-component-methods */
import { Component } from 'react'
import api from 'x/utils/api'
import _ from 'lodash'
// import { COLORS, STYLES } from '../../config/styles'
import p from 'x/config/platform-specific'
// import * as xFmt from 'x/utils/formatter'
// import { log, setStatePromise } from 'x/utils/util'
import * as util from 'x/utils/util'
// import * as acl from 'x/utils/acl'
import { fromJS, Map } from 'immutable'
import { ActionApiParams, ISelectedAddresses, IAnyObject } from 'x/index'

import CONS from 'x/config/constants'
import { IAddress, IXScreenProps } from 'x/types'

export interface BaseAddressViewProps extends IXScreenProps {
  load: (address: ISelectedAddresses) => void
  reset: () => void
  onInit: (address: ISelectedAddresses) => void
  selectedAddress: Map<string, any>
  editingAddress: Map<string, any>
  saveNew: any
  saveEditing: (params: ActionApiParams) => void
  selectedStore: Map<string, any>
  createAddress: (params: ActionApiParams) => void
  editOrgAddress: (params: ActionApiParams) => void
  addCustomer: (params: ActionApiParams) => void
}

const defaultAddress: IAddress = {
  name: '',
  address1: '',
  address2: '',
  sub_district: '',
  district: '',
  province: '',
  postal_code: '',
  telephone: '',
  is_primary: false,
  legal_entity_type: 0,
  legal_entity_id: '',
  created_at: '',
  updated_at: '',
}

export interface BaseAddressViewState {
  address: IAddress | null
  segmentLegalEntityTypeIndex: number
  radioLegalEntityTypeMoreId: number
}

export default abstract class BaseAddressView extends Component<BaseAddressViewProps, BaseAddressViewState> {
  // inputRefs: any

  // scrollViewRef: any

  inProcess: boolean

  // ENTITY_OPTION: string[]

  // abstract _saveNewAddress: () => void
  // abstract _saveEditingAddress: () => void
  protected constructor(props) {
    super(props)

    this.state = {
      address: null,

      segmentLegalEntityTypeIndex: 0,
      radioLegalEntityTypeMoreId: 2,
    }
    // this.inputRefs = []
    // this.scrollViewRef = null
    this.inProcess = false
    // this.ENTITY_OPTION = ['ไม่ระบุ', 'บุคคลธรรมดา', 'นิติบุคคล']
  }

  async componentDidMount() {
    // if (Platform.OS === 'android') {
    //   await util.delay(200)
    //   await util.setStatePromise(this, { inputWidth: '100%' })
    // }
    // const { navigation } = this.props
    // const { address } = navigation.state.params
    const address = util.getNavParam(this.props, 'address')
    const legalEntityType = !_.isNil(address) && address.legal_entity_type ? address.legal_entity_type : 0

    const newState = {
      address: _.isNil(address) ? defaultAddress : address,
      segmentLegalEntityTypeIndex: 0,
      radioLegalEntityTypeMoreId: legalEntityType,
    }

    const isLegalEntityIndividual = util.isLegalEntityIndividual(legalEntityType)
    if (isLegalEntityIndividual) {
      newState.segmentLegalEntityTypeIndex = 1
    }

    const isLegalEntityCorporation = util.isLegalEntityCorporation(legalEntityType)
    if (isLegalEntityCorporation) {
      newState.segmentLegalEntityTypeIndex = 2
    }

    await util.setStatePromise(this, newState)
    // if (!_.isNil(isPrimaryOnly) && isPrimaryOnly) {
    //   onChange({ key: 'is_primary', value: isPrimaryOnly })
    // } else if (!_.isNil(isPrimaryOnly) && !isPrimaryOnly) {
    //   onChange({ key: 'is_primary', value: isPrimaryOnly })
    // }
  }

  // UNSAFE_componentWillMount() {
  //   const { navigation, load, reset, onInit } = this.props
  //   const { address, mode, isStorePickMode } = navigation.state.params
  //   const { CUSTOMER_ADD, STORE_ADD, STORE_EDIT, CUSTOMER_EDIT } = CONS.ADDRESS_MODE_KEYS

  //   if (_.includes([CUSTOMER_ADD, STORE_ADD], mode)) {
  //     navigation.setParams({ handleBtn: this._saveNewAddress })
  //     reset()
  //     if (address) {
  //       // FIXME: Manual Set addressable_id
  //       onInit(address)
  //     }
  //   } else if (_.includes([CUSTOMER_EDIT, STORE_EDIT], mode) && address) {
  //     navigation.setParams({ handleBtn: this._saveEditingAddress })
  //     // load(selectedAddresses.get(index))
  //     load(address)
  //   } else {
  //     p.op.showConfirmationOkOnly('เกิดข้อผิดพลาด', 'มีบางพารามิเตอร์ไม่ครบถ้วน หรือ ไม่ถูกต้อง')
  //   }
  // }

  onChangeText = async (key: string, value: string) => {
    const { address } = this.state
    const newAddress: IAddress = _.clone(address)
    newAddress[key] = value
    await util.setStatePromise(this, {
      address: newAddress,
    })
  }

  onChangeIsPrimary = async (value: boolean) => {
    const { address } = this.state
    const newAddress: IAddress = _.clone(address)
    newAddress.is_primary = value
    await util.setStatePromise(this, {
      address: newAddress,
    })
  }

  // _saveNewAddress = async () => {
  //   await this._handleSubmitForm()
  // }

  _handleSubmitForm = async () => {
    // Keyboard.dismiss()
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    const { selectedAddress, editingAddress, navigation } = this.props
    const { address, segmentLegalEntityTypeIndex, radioLegalEntityTypeMoreId } = this.state
    const addressToFromJS = fromJS(address)
    // const { checkBoxPrimary, noteTxt, indexEntityOption, entityTxt } = this.state
    // const { address, mode } = navigation.state.params
    const { STORE_EDIT, CUSTOMER_EDIT, CUSTOMER_ADD, STORE_ADD } = CONS.ADDRESS_MODE_KEYS
    const requiredFields = ['name', 'address1', 'district', 'province', 'postal_code']
    const requiredDisplays = ['ชื่อ', 'ที่อยู่', 'อำเภอ', 'จังหวัด', 'รหัสไปรษณีย์']

    // util.log('_handleSaveEditing mode => ', mode)
    const body: IAnyObject = {}

    // if (_.includes([CUSTOMER_ADD, STORE_ADD], mode)) {
    util.appendToObjectIfImmutableKeyIsNotEmpty('name', addressToFromJS, body, 'string')
    util.appendToObjectIfImmutableKeyIsNotEmpty('address1', addressToFromJS, body, 'string')
    util.appendToObjectIfImmutableKeyIsNotEmpty('address2', addressToFromJS, body, 'string')
    util.appendToObjectIfImmutableKeyIsNotEmpty('sub_district', addressToFromJS, body, 'string')
    util.appendToObjectIfImmutableKeyIsNotEmpty('district', addressToFromJS, body, 'string')
    util.appendToObjectIfImmutableKeyIsNotEmpty('province', addressToFromJS, body, 'string')
    util.appendToObjectIfImmutableKeyIsNotEmpty('postal_code', addressToFromJS, body, 'string')
    util.appendToObjectIfImmutableKeyIsNotEmpty('telephone', addressToFromJS, body, 'string')
    util.appendToObjectIfImmutableKeyIsNotEmpty('email', addressToFromJS, body, 'string')
    util.appendToObjectIfImmutableKeyIsNotEmpty('note', addressToFromJS, body, 'string')

    if (segmentLegalEntityTypeIndex === 2 && (_.isNil(radioLegalEntityTypeMoreId) || radioLegalEntityTypeMoreId < 2)) {
      p.op.alert(
        'คำเตือน',
        `กรุณาเลือกประเภทของ ${CONS.ADDRESS_LEGAL_ENTITY_TYPE_OPTIONS[segmentLegalEntityTypeIndex]} หรือหากไม่ต้องการระบุสามารถปรับสภาพบุคคลเป็น "ไม่ระบุ"`
      )
      this.inProcess = false
      return
    }

    // const entityType = addressToFromJS.get(`legal_entity_type`)
    const entityType = segmentLegalEntityTypeIndex === 0 ? 0 : radioLegalEntityTypeMoreId
    const legalEntityId = addressToFromJS.get(`legal_entity_id`)
    const isPrimiry = addressToFromJS.get(`is_primary`)

    const isLegalEntityIndividual = util.isLegalEntityIndividual(entityType)
    const isLegalEntityCorporation = util.isLegalEntityCorporation(entityType)

    if (entityType > 0) {
      if (!_.isString(legalEntityId) || legalEntityId.length === 0) {
        p.op.alert(
          'คำเตือน',
          `กรุณาระบุ ${CONS.ADDRESS_LEGAL_ENTITY_LABELS[segmentLegalEntityTypeIndex]} หรือหากไม่ต้องการระบุสามารถปรับสภาพบุคคลเป็น "ไม่ระบุ"`
        )
        this.inProcess = false
        return
      }

      const legalEntityIdToTest = legalEntityId.replace(/-/g, '').trim()
      const regThirteenDigit = /^\d{13}$/
      if (isLegalEntityIndividual && !regThirteenDigit.test(legalEntityIdToTest)) {
        p.op.alert('คำเตือน', `กรุณาระบุ ${CONS.ADDRESS_LEGAL_ENTITY_LABELS[1]} เป็นตัวเลข 13 หลัก`)
        this.inProcess = false
        return
      }

      const regTenDigit = /^\d{10}$/
      if (isLegalEntityCorporation && !regTenDigit.test(legalEntityIdToTest) && !regThirteenDigit.test(legalEntityIdToTest)) {
        p.op.alert('คำเตือน', `กรุณาระบุ ${CONS.ADDRESS_LEGAL_ENTITY_LABELS[2]} เป็นตัวเลข 10 หลัก หรือ 13 หลัก`)
        this.inProcess = false
        return
      }
    }

    if (!_.isNil(entityType)) {
      if (entityType === 0) {
        body.legal_entity_type = 0
      } else {
        body.legal_entity_type = entityType
        body.legal_entity_id = legalEntityId.replace(/-/g, '').trim()
      }
    }

    for (let i = 0; i < requiredFields.length; i++) {
      const key = requiredFields[i]
      const warningText = requiredDisplays[i]
      if (!_.has(body, key)) {
        p.op.showConfirmationOkOnly(`ข้อมูลจำเป็นไม่ครบถ้วน`, `กรุณาระบุ "${warningText}"`)
        this.inProcess = false
        return
      }
    }

    if (body.email && body.email.length > 2) {
      body.email = body.email.trim()
      const isEmailValid = util.isEmailValid(body.email)
      if (!isEmailValid) {
        p.op.showConfirmationOkOnly(`อีเมลไม่ถูกต้อง`, 'กรุณาระบุอีเมลในรูปแบบถูกต้อง')
        this.inProcess = false
        return
      }
    }
    // }

    // if (_.includes([STORE_EDIT, CUSTOMER_EDIT], mode)) {
    //   util.appendIfImmutableDataChange(selectedAddress, editingAddress, body, 'name', 'string')
    //   util.appendIfImmutableDataChange(selectedAddress, editingAddress, body, 'address1', 'string')
    //   util.appendIfImmutableDataChange(selectedAddress, editingAddress, body, 'address2', 'string')
    //   util.appendIfImmutableDataChange(selectedAddress, editingAddress, body, 'sub_district', 'string')
    //   util.appendIfImmutableDataChange(selectedAddress, editingAddress, body, 'district', 'string')
    //   util.appendIfImmutableDataChange(selectedAddress, editingAddress, body, 'province', 'string')
    //   util.appendIfImmutableDataChange(selectedAddress, editingAddress, body, 'postal_code', 'string')
    //   util.appendIfImmutableDataChange(selectedAddress, editingAddress, body, 'telephone', 'string')

    //   util.appendIfImmutableDataChange(selectedAddress, editingAddress, body, 'note', 'string')
    //   // util.appendIfImmutableDataChange(selectedAddress, editingAddress, body, 'is_primary', 'string')
    //   const newEntityType = editingAddress.get(`legal_entity_type`)
    //   const oldEntityType = selectedAddress.get(`legal_entity_type`)
    //   const legalEntityId = editingAddress.get(`legal_entity_id`)
    //   if (newEntityType !== oldEntityType) {
    //     if (newEntityType === 0) {
    //       body.legal_entity_type = 0
    //     } else {
    //       body.legal_entity_type = newEntityType
    //       body.legal_entity_id = legalEntityId
    //     }
    //   } else {
    //     util.appendIfImmutableDataChange(selectedAddress, editingAddress, body, 'legal_entity_id', 'string')
    //   }

    //   const oldParmiry = selectedAddress.get(`is_primary`)
    //   const newParmity = editingAddress.get(`is_primary`)
    //   if (oldParmiry !== newParmity) {
    //     body.is_primary = _.isNil(newParmity) ? false : newParmity
    //   }

    //   for (let i = 0; i < requiredFields.length; i++) {
    //     const key = requiredFields[i]
    //     const warningText = requiredDisplays[i]
    //     if (!editingAddress.get(key)) {
    //       p.op.showConfirmationOkOnly(`ข้อมูลจำเป็นไม่ครบถ้วน`, `กรุณาระบุ "${warningText}"`)
    //       this.inProcess = false
    //       return
    //     }
    //   }
    // }

    if (!_.isEmpty(body)) {
      if (body.postal_code) {
        if (!util.isNumberOnly(body.postal_code)) {
          p.op.showConfirmationOkOnly(`ข้อมูลไม่ถูกต้อง`, `กรุณาระบุรหัสไปรษณีย์เป็นตัวเลข`)
          this.inProcess = false
          return
        }
        if (body.postal_code.length !== 5) {
          p.op.showConfirmationOkOnly(`ข้อมูลไม่ถูกต้อง`, `รหัสไปรษณีย์ต้องเป็นตัวเลข 5 ตัวเท่านั้น`)
          this.inProcess = false
          return
        }
        // eslint-disable-next-line radix
        body.postal_code = parseInt(body.postal_code)
      }
      // const { callBackBody } = navigation.state.params
      // if (!_.isFunction(callBackBody)) {
      //   const { addressable_id } = address
      //   if (addressable_id) {
      //     body.addressable_id = addressable_id
      //   }
      // }
      const params = util.getNavParams(this.props)
      const { type, store_id, profile_id } = params
      const address_id = address.id ? address.id : null
      // console.log('address => // ', address)
      // console.log('address_id => ', address_id)
      if (address_id) {
        body.address_id = address_id
      } else if (type === 'PROFILE') {
        body.addressable_id = profile_id
      } else if (type === 'STORE') {
        body.addressable_id = store_id
      }

      if (type !== 'CUSTOMER') {
        body.type = type
      }

      if (!_.isNil(isPrimiry)) {
        // ถ้าเป้นการสร้างลูกค้า isPrimiry ให้ส่งเป็น string เพราะหลังบ้านมีปัญหาเรื่อง boolean
        if (type === 'CUSTOMER') {
          // NOW CUSTOMER NO NEED is_primary
          // body.is_primary = isPrimiry ? 'true' : 'false'
        } else {
          body.is_primary = isPrimiry
        }
      }

      // if (_.includes([STORE_EDIT, STORE_ADD], mode)) {
      //   body.type = 'STORE'
      // } else if (_.includes([CUSTOMER_EDIT, CUSTOMER_ADD], mode)) {
      //   body.type = 'PROFILE'
      // }

      if (!_.isNil(address.note) && address.note !== '') {
        body.note = address.note
      }

      // if (checkBoxPrimary) {
      //   body['is_primary'] = noteTxt
      // }

      // if (indexEntityOption !== 0) {
      //   if (indexEntityOption === 1) {
      //     body['legal_entity_type'] = indexEntityOption
      //     if (entityTxt === '') {
      //       p.op.showConfirmationOkOnly(``, `กรุณากรอก "เลขประจำตัวประชาชน"`)
      //       this.inProcess = false
      //       return
      //     }
      //     body['legal_entity_id'] = entityTxt
      //   } else {
      //     body['legal_entity_type'] = indexEntityOption
      //     if (entityTxt === '') {
      //       p.op.showConfirmationOkOnly(``, `กรุณากรอก "เลขประจำตัวผู้เสียภาษี"`)
      //       this.inProcess = false
      //       return
      //     }
      //     body['legal_entity_id'] = entityTxt
      //   }
      // }
      try {
        if (type !== 'CUSTOMER') {
          await this._doSaveNewAddress(body)
        } else {
          await this._dosaveCustomer(body)
        }
      } catch (err) {
        console.log('_handleSubmitForm err => ', err)
      }
    } else {
      p.op.showToast('ยังไม่มีการแก้ไขข้อมูลเกิดขึ้น', 'warning')
    }
    util.log('_handleSubmitForm body => ', body)

    this.inProcess = false
  }

  _doSaveNewAddress = async (body) => {
    // console.log('BODY => ', body)
    // callBackBody คือ call back จากหน้าสร้างที่อยู่สำหรับองค์กร (SomSai) หลังจากได้ body แล้วก็จะหยุด
    // if (_.isFunction(callBackBody)) {
    //   if (_.isNil(body.telephone)) {
    //     p.op.showConfirmationOkOnly(``, `กรุณาระบุเบอร์โทรศัพท์`)
    //     return
    //   }
    //   callBackBody(body)
    //   // this.props.navigation.navigate('AppLauncherViewContainer')
    //   navigation.goBack(navigation.state.key)
    //   return
    // }
    const apiOptions = {
      messages: {
        successMsg: 'สร้างที่อยู่สำเร็จ',
        // errorMsg: 'สร้างที่อยู่ล้มเหลว',
      },
      showSpinner: true,
    }
    const API_TO_GO = body.address_id ? api.patchV2 : api.putV2
    const res: { addresses: IAddress[] } = await API_TO_GO(api.PUT_ADDRESS, body, apiOptions)

    // const response = await new Promise((resolveSave) => {
    //   saveNew({
    //     body,
    //     // isStorePickMode,
    //     successCallback: resolveSave,
    //     failedCallback: () => resolveSave(null),
    //   })
    // })
    // console.log('response => ', res)
    const succeedCallback = util.getNavParam(this.props, 'succeedCallback')
    if (res.addresses) {
      if (_.isFunction(succeedCallback)) {
        succeedCallback(res.addresses)
      }
      util.navGoBack(this.props)
    }

    // const newBody = body
    // const orgz = selectedStore.get(`org`)
    // if (!_.isNil(orgz) && orgz.size > 0 && !_.isNil(profile)) {
    //   const orgzToJS = orgz.toJS()
    //   newBody.org_id = orgzToJS[0].id
    //   newBody.profile_id = profile.id
    //   delete newBody.type
    //   const response = await new Promise((resolveSave) => {
    //     createAddress({
    //       body: newBody,
    //       isStorePickMode,
    //       successCallback: resolveSave,
    //       failedCallback: () => resolveSave(null),
    //     })
    //   })
    //   // console.log(`response =! `, response)
    //   if (response) {
    //     if (_.isFunction(callbackResponse)) {
    //       // @ts-ignore ยังไม่ได้ประกาศ type response
    //       callbackResponse(response.addresses)
    //     }
    //     navigation.goBack(navigation.state.key)
    //   }
    // } else {
    //   const response = await new Promise((resolveSave) => {
    //     saveNew({
    //       body,
    //       isStorePickMode,
    //       successCallback: resolveSave,
    //       failedCallback: () => resolveSave(null),
    //     })
    //   })

    //   if (response) {
    //     navigation.goBack(navigation.state.key)
    //   }
    // }
  }

  // _saveEditingAddress = async () => {
  //   await this._handleSubmitForm(this._doSaveEditingAddress)
  // }

  _doSaveEditingAddress = async (body) => {
    const { navigation, saveEditing, editOrgAddress, selectedStore } = this.props
    // @ts-ignore
    // const { isStorePickMode, callbackResponse } = navigation.state && navigation.state.params ? navigation.state.params : false
    const isStorePickMode = util.getNavParam(this.props, 'isStorePickMode')
    const callbackResponse = util.getNavParam(this.props, 'callbackResponse')
    const newBody = body
    const orgz = selectedStore.get(`org`)
    if (!_.isNil(orgz) && orgz.size > 0) {
      const orgzToJS = orgz.toJS()
      newBody.org_id = orgzToJS[0].id
      delete newBody.type
      const response = await new Promise((resolveSave) => {
        editOrgAddress({
          body: newBody,
          isStorePickMode,
          successCallback: resolveSave,
          failedCallback: () => resolveSave(null),
        })
      })
      if (response) {
        if (_.isFunction(callbackResponse)) {
          // @ts-ignore ยังไม่ได้ประกาศ type response
          callbackResponse(response.addresses)
        }
        util.navGoBack(this.props)
      }
    } else {
      const response = await new Promise((resolveSave) => {
        saveEditing({
          body,
          isStorePickMode,
          successCallback: resolveSave,
          failedCallback: () => resolveSave(null),
        })
      })
      if (response) {
        if (_.isFunction(callbackResponse)) {
          // @ts-ignore ยังไม่ได้ประกาศ type response
          callbackResponse(response.addresses)
        }
        util.navGoBack(this.props)
      }
    }
  }

  _getPlaceholderInput = (key: string) => {
    let placeholde = ``
    switch (key) {
      case `name`:
        placeholde = 'ระบุชื่อ'
        break
      case `address1`:
        placeholde = 'ระบุที่อยู่'
        break
      case `address2`:
        placeholde = 'ระบุที่อยู่(เพิ่มเติม)'
        break
      case `sub_district`:
        placeholde = 'ระบุแขวง/ตำบล'
        break
      case `district`:
        placeholde = 'ระบุเขต/อำเภอ'
        break
      case `province`:
        placeholde = 'ระบุจังหวัด'
        break
      case `postal_code`:
        placeholde = 'ระบุรหัสไปษณีย์'
        break
      case `telephone`:
        placeholde = 'ระบุเบอร์โทรศัพท์'
        break
      default:
        break
    }
    return placeholde
  }

  _dosaveCustomer = async (body) => {
    // console.log('_dosaveCustomer => ')
    const { addCustomer, navigation } = this.props
    const data = {
      first_name: body.name,
      last_name: '',
      phone1: body.telephone,
      addresses: [body],
    }
    if (body.address2) {
      // @ts-ignore
      data.address2 = body.address2
    }
    // console.log('data => ', data)
    // return
    const response = await new Promise((resolveEdit) => {
      addCustomer({
        body: data,
        successCallback: resolveEdit,
        failedCallback: () => resolveEdit(null),
      })
    })
    if (response) {
      util.navGoBack(this.props)
    }
  }
}
