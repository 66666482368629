import React from 'react'
import { connect, FormikContextType, getIn } from 'formik'
import { StyleSheet, ReturnKeyTypeOptions, TextInput } from 'react-native'
import { COLORS, STYLES } from 'x/config/styles'
import _ from 'lodash'
import XInput, { IXInputProps } from './XInput'
import VStack from './VStack'
import XText from './XText'
import HStack from './HStack'

// import { compose } from 'recompose'

interface IInput extends IXInputProps {
  // readonly formik: FormikContext<any>
  name: string
  // label?: string
  // type?: string
  disabledError?: boolean

  order?: number
  handleRegisterRef?: (inputName: string, componentRef: any) => void
  handleOnSubmitEditing?: (evt: any, inputName: string) => void
  getReturnKeyType?: (inputName: string) => ReturnKeyTypeOptions
}

type XFormikInputProps = IInput & { formik: FormikContextType<string> }

class XFormikInput extends React.PureComponent<XFormikInputProps> {
  static displayName = 'XFormikInput'

  static defaultProps = {
    type: 'text',
    disabledError: false,
    handleRegisterRef: null,
    handleOnSubmitEditing: null,
    getReturnKeyType: null,
  }

  inputRef: React.RefObject<TextInput>

  constructor(props) {
    super(props)

    this.inputRef = React.createRef()
  }
  // _handleChange = (newValue: string) => {
  //   const { formik, name } = this.props
  //   const touch = getIn(formik.touched, name)
  //   formik.setFieldValue(name, newValue)
  //   if (!touch) {
  //     formik.setFieldTouched(name)
  //   }
  // }
  // _handleBlur = evt => {
  //   const { formik, name } = this.props
  //   // formik.handleBlur(evt)
  //   formik.handleBlur(name)
  // }

  componentDidMount(): void {
    setTimeout(this._onRegisterRef, 200)
  }

  // _onRegisterRef = (ref) => {
  _onRegisterRef = () => {
    const { name, order, handleRegisterRef } = this.props
    if (_.isFunction(handleRegisterRef)) {
      // handleRegisterRef(name, ref, order)
      handleRegisterRef(name, this.inputRef.current, order)
    }
  }

  _onSubmitEditing = (evt) => {
    // console.log(`${name}::_onSubmitEditing evt => `, evt)
    const { name, handleOnSubmitEditing } = this.props
    if (_.isFunction(handleOnSubmitEditing)) {
      handleOnSubmitEditing(evt, name)
    }
  }

  _getReturnTypeKey = (): ReturnKeyTypeOptions => {
    const { name, returnKeyType, getReturnKeyType } = this.props
    if (returnKeyType) {
      return returnKeyType
    }

    if (_.isFunction(getReturnKeyType)) {
      return getReturnKeyType(name)
    }

    return 'default'
  }

  render() {
    const { name, formik, disabledError } = this.props
    const value = getIn(formik.values, name)
    const error = getIn(formik.errors, name)
    const touch = getIn(formik.touched, name)

    // let inputWrapperStyle = styles.inputUnderline
    let inputWrapperStyle = {}

    if (touch) {
      inputWrapperStyle = _.isNil(error) ? styles.inputUnderlineSuccess : styles.inputUnderlineError
    }

    // console.log(`${name}:: this.context => `, this.context)

    return (
      <VStack w='full'>
        <HStack w='full' style={inputWrapperStyle}>
          <XInput
            // onRef={this._onRegisterRef}
            w='full'
            minH='9'
            ref={this.inputRef}
            onSubmitEditing={this._onSubmitEditing}
            returnKeyType={this._getReturnTypeKey()}
            value={value}
            // onChangeText={this._handleChange}
            // onBlur={this._handleBlur}
            onChangeText={formik.handleChange(name)}
            onBlur={formik.handleBlur(name)}
            {...this.props}
          />
        </HStack>
        {disabledError ? null : (
          <XText
            variant='danger'
            fontSize='xs'
            // style={styles.txtError}
          >
            {touch && error ? error : ' '}
          </XText>
        )}
      </VStack>
    )
  }
}

const styles = StyleSheet.create({
  txtError: {
    // fontSize: STYLES.FONT_SIZE_SMALLER,
    color: COLORS.BRAND_Danger,
  },
  input: {
    backgroundColor: 'white',
    // fontSize: STYLES.FONT_SIZE_NORMAL,
  },
  inputUnderline: {
    borderBottomWidth: 1,
    borderBottomColor: COLORS.TEXT_INACTIVE,
  },
  inputUnderlineSuccess: {
    borderBottomWidth: 1,
    borderBottomColor: COLORS.BRAND_Success,
  },
  inputUnderlineError: {
    borderBottomWidth: 1,
    borderBottomColor: COLORS.BRAND_Danger,
  },
})

// @ts-ignore
export const Input = connect<IInput, string>(XFormikInput)
// export { Input }

export default Input
