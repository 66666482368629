import React from 'react'
import { TouchableOpacity, Clipboard, Image } from 'react-native'

import _ from 'lodash'
// import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'
import BaseSettingView from 'x/modules/setting/BaseSettingView'
import XCustomHeader from 'xui/components/XCustomHeader'
// import { Input } from 'react-native-elements'
import { COLORS } from 'x/config/styles'
import XIcon from 'xui/components/XIcon'
import p from 'x/config/platform-specific'
import XText from 'xui/components/XText'
import XButton from 'xui/components/XButton'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import Ionicon from 'react-native-vector-icons/Ionicons'
import DeleteAccountButton from 'xui/components/privacy/DeleteAccountButton'
import Box from 'xui/components/Box'
import HStack from 'xui/components/HStack'
import VStack from 'xui/components/VStack'

export default abstract class BaseUISettingView extends BaseSettingView {
  _renderTitleSection = () => {
    const { profile } = this.props
    const thumbnail_uri = profile.get('thumbnail_uri')
    // console.log('thumbnail_uri =<>', thumbnail_uri)

    const username = profile.get('username') || null
    let txtUsername = ''
    if (username) {
      txtUsername = `ชื่อบัญชีผู้ใช้: ${username}`
    }

    const email = profile.get('email') || null
    const txtEmail = `อีเมลบัญชีผู้ใช้: ${email}`

    const first_name = profile.get('first_name') || ''
    const last_name = profile.get('last_name') || null
    let txtFullname = ''
    if (first_name && last_name) {
      txtFullname = `${first_name} ${last_name}`
    } else {
      txtFullname = first_name
    }
    const txtGreeting = `${txtFullname}`

    if (util.isDevMode()) {
      txtUsername += ` (uid: ${profile.get('user_id')} pid: ${profile.get('id')})`
    }
    return (
      <HStack w='full' bgColor='white' py='2' alignItems='center'>
        <Box flex={2} alignItems='center'>
          {thumbnail_uri ? (
            <Image source={{ uri: thumbnail_uri }} style={{ width: 60, height: 60, borderRadius: 90 }} />
          ) : (
            <Ionicon name='person' size={50} />
          )}
        </Box>
        <VStack flex={8} pl='2'>
          <XText variant='active'>{txtGreeting}</XText>
          <XText
            variant='inactive'
            numberOfLines={1}
            onPress={() => {
              Clipboard.setString(username)
              p.op.showToast('คัดลอกชื่อผู้ใช้แล้ว', 'success')
            }}>
            {txtUsername}
          </XText>
          <XText
            variant='inactive'
            numberOfLines={1}
            onPress={() => {
              Clipboard.setString(email)
              p.op.showToast('คัดลอกอีเมลบัญชีผู้ใช้ใช้แล้ว', 'success')
            }}>
            {txtEmail}
          </XText>
        </VStack>
      </HStack>
    )
  }

  _renderMenuListView = () => {
    if (_.isNil(this.MENU_ABOUT_ME)) {
      return null
    }
    return (
      <Box>
        <XText py='2' variant='active' pl='2'>
          เกี่ยวกับฉัน
        </XText>
        {this._renderMenuAboutMe()}
        <HStack h='8' />
        {this._renderMenuAboutXselly()}
      </Box>
    )
  }

  _renderMenuAboutMe = () => {
    if (_.isNil(this.MENU_ABOUT_ME)) {
      return null
    }
    return (
      <VStack bgColor='white'>
        {this.MENU_ABOUT_ME.map((menu: { label: string; icon: JSX.Element; showMenu: boolean; nav: () => void }, idx) => {
          if (!menu.showMenu) {
            return null
          }
          return (
            <TouchableOpacity key={`menum_${idx.toString()}`} onPress={() => menu.nav()}>
              <HStack py={p.op.isWeb() ? '3' : '4'} alignItems='center' borderBottomColor='gray.200' borderBottomWidth='1'>
                <Box pl='2'>{menu.icon}</Box>
                <XText flex={1} pl='6'>
                  {menu.label}
                </XText>
                <XIcon name='arrow-forward' family='Ionicons' mr='2' />
              </HStack>
            </TouchableOpacity>
          )
        })}
      </VStack>
    )
  }

  _renderMenuAboutXselly = () => {
    if (_.isNil(this.MENU_ABOUT_XSELLY)) {
      return null
    }
    return (
      <VStack bgColor='white'>
        {this.MENU_ABOUT_XSELLY.map((menu: { label: string; icon: JSX.Element; showMenu: boolean; nav: () => void }, idx) => {
          if (!menu.showMenu) {
            return null
          }
          return (
            <TouchableOpacity key={`menux_${idx.toString()}`} onPress={() => menu.nav()}>
              <HStack py={p.op.isWeb() ? '3' : '4'} alignItems='center' borderBottomColor='gray.200' borderBottomWidth='1'>
                <Box pl='2'>{menu.icon}</Box>
                <XText flex={1} pl='6'>
                  {menu.label}
                </XText>
                <XIcon name='arrow-forward' family='Ionicons' mr='2' />
              </HStack>
            </TouchableOpacity>
          )
        })}
      </VStack>
    )
  }

  _renderLogoutBtn = () => {
    if (p.op.isWeb()) {
      return null
    }
    return (
      <XButton px='2' py='3' m='2' bgColor={COLORS.BRAND_Danger} onPress={() => this._doSignOut()}>
        <XText bold color='white'>
          ออกจากระบบ
        </XText>
      </XButton>
    )
  }

  _renderDeleteAccountBtn = () => (
    <HStack alignItems='center' justifyContent='center' w='full' mt='10'>
      <DeleteAccountButton onCloseAfterDeleted={this.onCloseOverlayAfterAccountDeleted} />
    </HStack>
  )

  render() {
    return (
      <XContainer>
        <XCustomHeader title={p.op.t('NavTitle.others')} />
        <XContent>
          {this._renderTitleSection()}
          {this._renderMenuListView()}
          {this._renderLogoutBtn()}
          {this._renderDeleteAccountBtn()}
        </XContent>
      </XContainer>
    )
  }
}
