import { bindActionCreators } from 'redux'
import * as ProductActions from './ProductState'
import * as StoreActions from '../store/StoreState'
import * as CategoryActions from '../category/categoryState'
import {
  getSellerStores,
  getSelectedStore,
  // getSelectedUserGroups,
  getSelectedProductGroups,
  getStoreVolumeDiscounts,
  getSelectedProduct,
  getEditingProduct,
} from '../../redux/selectors'

export function getState(state) {
  return {
    selectedProduct: getSelectedProduct(state),
    editingProduct: getEditingProduct(state),
    sellerStores: getSellerStores(state),
    selectedStore: getSelectedStore(state),
    // selectedUserGroups: getSelectedUserGroups(state),
    productGroups: getSelectedProductGroups(state),
    vds: getStoreVolumeDiscounts(state),
  }
}

export function getDispatch(dispatch) {
  return {
    unpairVolumeDiscount: bindActionCreators(StoreActions.unpairVolumeDiscount, dispatch),
    updateContInVolumeDiscounts: bindActionCreators(StoreActions.updateContInVolumeDiscounts, dispatch),
    loadListVolumeDiscounts: bindActionCreators(StoreActions.loadListVolumeDiscounts, dispatch),
    updateDataVolumeDiscountAndProductGroup: bindActionCreators(ProductActions.updateDataVolumeDiscountAndProductGroup, dispatch),
    fetchCategory: bindActionCreators(CategoryActions.requestCategoryList, dispatch),
  }
}
