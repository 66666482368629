import p from 'x/config/platform-specific'
import xCONS from 'x/config/constants'
import { IOrdersFilterSetting, IPaymentFetchOrdersParams, ActionApiParams } from 'x/index'
import actions from '../../config/actions'
// import * as util from '../../utils/util'
import { log } from '../../utils/util'
import * as BaseOrderListState from './BaseOrderListState'
import { initialState, reducer, INIT } from './BaseOrderListState'

import {
  getOrdersInBaseOrders,
  getPaymentOrdersFetchStatusInOrders,
  getPaymentOrdersInOrdersForTabName,
  getPaymentOrdersFetchStatusForTabName,
  getPaymentOrdersFetchStatusForTabNameInOrders,
  getPaymentOrdersSummaryInOrders,
  setPaymentOrdersInOrders,
  setPaymentOrdersTabStatusInOrders,
  setPaymentOrdersTabStatusInOrdersForKey,
  setPaymentOrderFetchStatusInOrdersForTab,
  setPaymentOrdersSummaryInOrders,
  setPaymentOrdersBackendTimeInServers,
} from '../../redux/selectors'

const func = {
  getOrders: getOrdersInBaseOrders,
  setOrdersInOrders: setPaymentOrdersInOrders,
  setBackendTimeInServers: setPaymentOrdersBackendTimeInServers,
  setOrdersSummaryInOrders: setPaymentOrdersSummaryInOrders,
  getOrdersSummaryInOrders: getPaymentOrdersSummaryInOrders,
  getOrdersInOrdersForTabName: getPaymentOrdersInOrdersForTabName,
  setOrderFetchStatusInOrdersForTab: setPaymentOrderFetchStatusInOrdersForTab,
  getOrdersFetchStatusForTabName: getPaymentOrdersFetchStatusForTabName,
  getOrdersFetchStatusInOrders: getPaymentOrdersFetchStatusInOrders,
  getOrdersFetchStatusForTabNameInOrders: getPaymentOrdersFetchStatusForTabNameInOrders,
  setOrdersTabStatusInOrders: setPaymentOrdersTabStatusInOrders,
  setOrdersTabStatusInOrdersForKey: setPaymentOrdersTabStatusInOrdersForKey,
}

const _type = {
  ORDERS_RESET_STATE: actions.PAYMENT_ORDERS_RESET_STATE,
  ORDERS_SELECT_SUB_TAB_FOR_KEY: actions.PAYMENT_ORDERS_SELECT_SUB_TAB_FOR_KEY,
  ORDERS_TAB_STATUS_RESET: actions.PAYMENT_ORDERS_TAB_STATUS_RESET,
  ORDERS_FETCH: actions.PAYMENT_ORDERS_FETCH,
  SET_ORDERS_STATE: actions.PAYMENT_SET_ORDERS_STATE,
  ORDERS_SHOULD_FETCH_ALL: actions.PAYMENT_ORDERS_SHOULD_FETCH_ALL, // Additional Type
  ORDERS_SHOULD_FETCH_SINGLE_TAB_KEY: actions.PAYMENT_ORDERS_SHOULD_FETCH_SINGLE_TAB_KEY, // Additional Type
  ORDERS_SET_IS_INITED_SINGLE_TAB_KEY: actions.PAYMENT_ORDERS_SET_IS_INITED_SINGLE_TAB_KEY, // Additional Type
  ORDERS_FILTER_SETTING_RESET: actions.PAYMENT_ORDERS_FILTER_SETTING_RESET,
  ORDERS_FILTER_SETTING_SET: actions.PAYMENT_ORDERS_FILTER_SETTING_SET,
}

// Action creators
export function init() {
  return BaseOrderListState.init()
}

export function resetState() {
  return { type: _type.ORDERS_RESET_STATE }
}

export function selectSubTabForKey(mainTabKey, subTabKey) {
  return BaseOrderListState.selectSubTabForKey(mainTabKey, subTabKey, _type.ORDERS_SELECT_SUB_TAB_FOR_KEY)
}

export function navToOrderDetail(orderId, storeId, isNoInitFetch) {
  return p.nav.navToOrderDetail(orderId, storeId, isNoInitFetch)
  // return BaseOrderListState.navToOrderDetail(orderId, storeId)
}

export function resetTabStatus() {
  return BaseOrderListState.resetTabStatus(_type.ORDERS_TAB_STATUS_RESET)
}

export function fetchOrders(params: IPaymentFetchOrdersParams) {
  if (!params) {
    return { type: xCONS.ACTION_NO_OP } // skip this op
  }
  const { storeId, orderStoreId, mode, tabName, isRefund } = params
  log(`PaymentOrderListState.fetchOrders.storeId: ${storeId} orderStoreId: ${orderStoreId} mode: ${mode} tabName: ${tabName}`)
  // return BaseOrderListState.fetchOrders(storeId, mode, tabName,
  if (orderStoreId) {
    return BaseOrderListState.baseFetchOrders({
      // storeId,
      // orderStoreId,
      // mode,
      // tabName,
      // isRefund,
      ...params,
      type: _type.ORDERS_FETCH,
      getOrdersFetchStatusForTabName: func.getOrdersFetchStatusForTabName,
    })
  }
  return { type: xCONS.ACTION_NO_OP } // skip this op
}

// export function toggleOrderPaymentSelection(orderId, total) {
export function toggleOrderPaymentSelection(orderData: { orderId: number; remaining_forecast_amount: number; cod_amount?: number }) {
  return {
    type: actions.ORDERS_TOGGLE_PAYMENT_SELECTION,
    payload: orderData,
  }
}

export function resetOrdersFilterSetting() {
  return {
    type: actions.PAYMENT_ORDERS_FILTER_SETTING_RESET,
  }
}

export function setOrdersFilterSetting(setting: IOrdersFilterSetting) {
  return {
    type: actions.PAYMENT_ORDERS_FILTER_SETTING_SET,
    payload: setting,
  }
}

export function setIsInitedByTabKey(tabKey: string, value = true) {
  return {
    type: actions.PAYMENT_ORDERS_SET_IS_INITED_SINGLE_TAB_KEY,
    payload: { tabKey, value },
  }
}

// Reducer
// export default function PaymentOrderListStateReducer(state, action = {}) {
//   return BaseOrderListState.baseReducer(state, action, func)
// }

export default function PaymentOrderListStateReducer(state = initialState, action: ActionApiParams = {}) {
  switch (action.type) {
    case INIT:
      return state
    case _type.ORDERS_RESET_STATE:
      return initialState
    case _type.ORDERS_FILTER_SETTING_RESET: {
      return reducer.resetFilterSetting(state)
    }
    case _type.ORDERS_FILTER_SETTING_SET: {
      return reducer.setFilterSetting(state, action.payload)
    }
    case _type.ORDERS_SHOULD_FETCH_ALL: {
      // Additional case
      return reducer.setShouldFetchAll(state)
    }
    case _type.ORDERS_SHOULD_FETCH_SINGLE_TAB_KEY: {
      // Additional case 2
      return reducer.setShouldFetchSingleTabKey(state, action.payload)
    }
    case _type.ORDERS_SET_IS_INITED_SINGLE_TAB_KEY: {
      // Additional case 3
      return reducer.setIsInitedTabKey(state, action.payload)
    }
    case _type.ORDERS_TAB_STATUS_RESET:
      return reducer.resetOrdersTabStatus(state, action, func.setOrdersTabStatusInOrders, func.getOrders)
    case _type.ORDERS_SELECT_SUB_TAB_FOR_KEY: {
      return reducer.selectSubTabForKey(state, action, func.setOrdersTabStatusInOrdersForKey)
    }
    case _type.ORDERS_FETCH: {
      return reducer.handleFetchOrders(state, action, func.getOrdersFetchStatusInOrders, func.getOrdersFetchStatusForTabNameInOrders)
    }
    case _type.SET_ORDERS_STATE: {
      return reducer.setOrdersState(
        state,
        action,
        func.setOrdersInOrders,
        func.setBackendTimeInServers,
        func.setOrdersSummaryInOrders,
        func.getOrdersSummaryInOrders,
        func.getOrdersInOrdersForTabName,
        func.setOrderFetchStatusInOrdersForTab,
        func.getOrdersFetchStatusInOrders
      )
    }
    default:
      return state
  }
}
