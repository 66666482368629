import React from 'react'
import _ from 'lodash'

import * as NavActions from 'x/utils/navigation'
import { IProductListViewPullMyProductsToOrderNavParams, IProductListViewProps, IProductListViewState, IProductItemData } from 'x/index'
import * as util from 'x/utils/util'

import BaseUIProductListView from './BaseUIProductListView'
import QuickAddProductButton from './QuickAddProductButton'

type IProductListViewPullMyProductsToOrderProps = IProductListViewProps<IProductListViewPullMyProductsToOrderNavParams>

const INITIAL_QUICKADD_REF_NUMBER = 500

class ProductListViewPullMyProductsToOrder extends BaseUIProductListView<
  IProductListViewPullMyProductsToOrderProps,
  IProductListViewState
> {
  static displayName = 'ProductListViewPullMyProductsToOrder'

  quickAddButtonRefs?: {
    [key: number]: React.RefObject<QuickAddProductButton>
  }

  constructor(props) {
    super(props)
    this.quickAddButtonRefs = {}
    for (let i = 0; i < INITIAL_QUICKADD_REF_NUMBER; i++) {
      this.quickAddButtonRefs[i] = React.createRef()
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.mainProductCount > INITIAL_QUICKADD_REF_NUMBER) {
      for (let i = INITIAL_QUICKADD_REF_NUMBER; i < nextState.mainProductCount; i++) {
        this.quickAddButtonRefs[i] = React.createRef()
      }
    }
    return true
  }

  // override
  isRequiredProductVariants = () => true

  // override
  isAddProductButtonVisible = () => false

  // override
  getHaveNoProductItemText = () =>
    'ไม่พบรายการสินค้า\nคุณต้องมีสินค้าของคุณเอง หรือดึงสินค้ามาจากร้านขายส่ง จึงจะมีสินค้าที่สามารถใช้เปิดออเดอร์ได้'

  // override
  renderProductListItemBottom = (item: { index: number; data: IProductItemData }) => (
    <QuickAddProductButton
      w='full'
      ref={this.quickAddButtonRefs[item.index]}
      data={item.data}
      onPress={() => this._onPressAddOneProductToCart(item.data, { btnRef: this.quickAddButtonRefs[item.index] })}
    />
  )

  // override
  getModifiedRequestBody = (body) => {
    // const { navigation, selectedStore } = this.props
    // const myStoreId = util.getNavParam(this.props, 'store_id')
    // const sellerStoreId = util.getNavParam(this.props, 'seller_store_id')
    let newBody = _.cloneDeep(body)
    newBody = this._applyUgIdToRequestBody(newBody)
    newBody = this._applyPgIdsToRequestBody(newBody)
    return newBody
  }

  // implement abstract
  onPressProductItem = (item) => {
    // console.log('ProductListViewPullSellersProductsToMyStore onPressProductItem item => ', item)
    this.navToProductViewPullMyProductToOrderMode(item.data.id)
  }

  _applyUgIdToRequestBody = (body) => {
    const { navigation, selectedStore } = this.props
    const ug_id = util.getNavParam(this.props, 'ug_id')
    const newBody = _.cloneDeep(body)
    if (ug_id) {
      newBody.user_group_id = ug_id
    }
    return newBody
  }

  _applyPgIdsToRequestBody = (body) => {
    const { navigation, selectedStore } = this.props
    const pg_ids = util.getNavParam(this.props, 'pg_ids')
    const newBody = _.cloneDeep(body)
    if (pg_ids) {
      newBody.product_group_ids = pg_ids
    }
    return newBody
  }

  _onPressAddOneProductToCart = async (product: IProductItemData, option?: { btnRef?: any }) => {
    const { navigation, selectedUserGroups, quickAddProductToOrder } = this.props
    const store_id = util.getNavParam(this.props, 'store_id')
    // const seller_store_id = util.getNavParam(this.props, 'seller_store_id')
    const pg_ids = util.getNavParam(this.props, 'pg_ids')
    const ug_id = util.getNavParam(this.props, 'ug_id')
    const btnRef = option && option.btnRef ? option.btnRef : undefined
    const selectedVariantIndex = await util.getActionSheetAddOneProductToCartSelectedVariantIndex({
      selectedProductListItem: product,
      store_id,
      // seller_store_id,
      pg_ids,
      ug_id,
      btnRef,
    })

    if (_.isNil(selectedVariantIndex)) {
      return
    }

    const willPullProductParams = await util.getPullProductParamsFromProductListItem({
      selectedProductListItem: product,
      selectedVariantIndex,
      store_id,
      // seller_store_id,
      pg_ids,
      ug_id,
      selectedUserGroups,
    })

    if (!willPullProductParams) {
      return
    }

    quickAddProductToOrder({
      store_id,
      // seller_store_id,
      orderProducts: [willPullProductParams],
      // callback,
      callback: this.handleOnPullProductToOrderSuccess,

      // for order create validation
      ug_id,
      pg_ids,
    })
  }

  navToProductViewPullMyProductToOrderMode = (product_id: number) => {
    const { navigation } = this.props
    const storeId = util.getNavParam(this.props, 'store_id')
    // const sellerStoreId = util.getNavParam(this.props, 'seller_store_id')
    const pg_ids = util.getNavParam(this.props, 'pg_ids')
    const ug_id = util.getNavParam(this.props, 'ug_id')
    navigation.dispatch(
      NavActions.navToProductViewPullMyProductToOrder({
        store_id: storeId,
        product_id,
        ug_id,
        pg_ids,
        onSuccessPullMyProductToOrderCallback: this._onSuccessPullMyProductToOrderCallback,
      })
    )
  }

  // ทำการ goBack กลับไปยัง OrderView ถ้าหากมีการเลือก Add Product จาก Product Detail
  _onSuccessPullMyProductToOrderCallback = async () => {
    await util.delay(500)
    util.navGoBack(this.props)
  }
}

export default ProductListViewPullMyProductsToOrder
