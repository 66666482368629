import { connect } from 'react-redux'
// import { bindActionCreators } from 'redux'
import { getStoreSelected, getSubscription } from 'x/redux/selectors'
import CompleteSalesReportView from './CompleteSalesReportView'

export default connect(
  (state) => ({
    selectedStore: getStoreSelected(state),
    subscription: getSubscription(state),
  }),
  (dispatch) => {
    return {
      // navigate: bindActionCreators(NavigationActions.navigate, dispatch),
    }
  }
)(CompleteSalesReportView)
