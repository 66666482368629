const warn = () => console.log("haven't resgiter the PdpaConfirmation yet")
const pdol: {
  show: () => Promise<'accepted' | 'rejected' | 'closed'>
} = {
  // @ts-ignore
  show: warn,
  // hide: warn,
}

/**
 * this method is to save the referece of the singleton component
 */
export const registerPdpaConfirmation = (ref) => {
  if (ref) {
    console.log('PdpaConfirmation registered')
    pdol.show = ref.show
    // pdol.hide = ref.hide
  } else {
    console.log('PdpaConfirmation unregistered')
    // @ts-ignore
    pdol.show = warn
    // pdol.hide = warn
  }
}

/**
 * export the ats component
 */
export { default as PdpaConfirmationInstance } from './PdpaConfirmation'

/**
 * export the open and close method
 */
export default pdol
