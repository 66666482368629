import { Component } from 'react'
import _ from 'lodash'
import * as util from 'x/utils/util'
import { ISelectedStoreMap, ActionApiParams, IXScreenProps } from 'x/index'
import p from 'x/config/platform-specific'

interface IOrgPendingList {
  i: number
  fn: string
  ln: string
  nn: string
  f: string
  l: string
  t: string
  q: string
  s: {
    fn: string
    ln: string
    c: string
  }
}

interface IResellerListViewProps extends IXScreenProps {
  dispatch: (params: any) => void
  selectedStore: ISelectedStoreMap
  searchMumber: (params: ActionApiParams) => void
}

interface IResellerListViewState {
  nameText: string
  lastNameText: string
  sellerCodeText: string
  emailText: string
  phoneText: string
  parentResellerText: string
  resellerList: IOrgPendingList[]
}

export default class BaseResellerListView extends Component<IResellerListViewProps, IResellerListViewState> {
  // imgManagerRef: React.RefObject<any>
  protected constructor(props: IResellerListViewProps) {
    super(props)
    // this.imgManagerRef = React.createRef()
    this.state = {
      nameText: '',
      lastNameText: '',
      sellerCodeText: '',
      emailText: '',
      parentResellerText: '',
      phoneText: '',
      resellerList: null,
    }
  }

  _searchMumber = async () => {
    const { searchMumber, selectedStore } = this.props
    const { nameText, lastNameText, sellerCodeText, emailText, phoneText, parentResellerText } = this.state
    const nameTextTrim = nameText.trim()
    const lastNameTextTrim = lastNameText.trim()
    const sellerCodeTextTrim = sellerCodeText.trim()
    const emailTextTrim = emailText.trim()
    const phoneTextTrim = phoneText.trim()
    const parentResellerTextTrim = parentResellerText.trim()
    if (
      nameTextTrim === '' &&
      lastNameTextTrim === '' &&
      sellerCodeTextTrim === '' &&
      emailTextTrim === '' &&
      phoneTextTrim === '' &&
      parentResellerTextTrim === ''
    ) {
      p.op.showConfirmationOkOnly(``, `กรุณากรอกข้อมูลอย่างน้อย 1 ช่อง`)
      return
    }
    if (
      nameTextTrim.length < 2 &&
      lastNameTextTrim.length < 2 &&
      sellerCodeTextTrim.length < 2 &&
      emailTextTrim.length < 2 &&
      phoneTextTrim.length < 2 &&
      parentResellerTextTrim.length < 2
    ) {
      p.op.showConfirmationOkOnly(``, `กรุณากรอกข้อมูลอย่างน้อย 3 ตัวอักษร`)
      return
    }
    // const organizations = selectedStore.get(`organizations`).toJS()
    // console.log(`organizations`, organizations)
    const res: { org_member_list: IOrgPendingList[] } = await new Promise((resolve) => {
      const body: { [key: string]: any } = {
        org_id: 1,
        store_id: selectedStore.get(`id`),
        type: 'm',
      }
      if (nameTextTrim.length > 2) {
        body.first_name = nameTextTrim
      }
      if (lastNameTextTrim.length > 2) {
        body.last_name = lastNameTextTrim
      }
      if (sellerCodeTextTrim.length > 2) {
        body.reseller_code = sellerCodeTextTrim
      }
      if (emailTextTrim.length > 2) {
        body.contact_email = emailTextTrim
      }
      if (phoneTextTrim.length > 2) {
        body.phone_number = phoneTextTrim
      }
      if (parentResellerTextTrim.length > 2) {
        body.seller_code = parentResellerTextTrim
      }
      // console.log(`BODY `, body)
      searchMumber({
        body,
        // @ts-ignore
        successCallback: resolve,
        // @ts-ignore
        failedCallback: resolve,
      })
    })

    if (res.org_member_list) {
      return res.org_member_list
    }
    return null
  }

  _onPressSearchBtn = async () => {
    const munbers = await this._searchMumber()
    if (_.isNil(munbers)) {
      return
    }
    if (munbers.length < 1) {
      await p.op.showConfirmationOkOnly(``, `ไม่พบรายการที่ค้นหา`)
    }
    await util.setStatePromise(this, { resellerList: munbers })
  }

  _onPressClearBtn = async () => {
    await util.setStatePromise(this, {
      resellerList: null,
      nameText: '',
      lastNameText: '',
      sellerCodeText: '',
      emailText: '',
      parentResellerText: '',
      phoneText: '',
    })
  }

  // TODO:: ยังไม่รู้จะประกาศ type ยังไง
  // @ts-ignore
  _handleKeyEnter = async (e) => {
    if (e.key === 'Enter') {
      await this._onPressSearchBtn()
    }
  }
}
