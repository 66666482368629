import { Map, List, fromJS, isImmutable } from 'immutable'
import { ActionApiParams } from 'x/index'

const initContact = Map({
  id: null,
  user_id: null,
  first_name: null,
  last_name: null,
  phone1: null,
  phone2: null,
  line_id: null,
  fb_id: null,
  ig_id: null,
  img_uri: null,
  thumbnail_uri: null,
  contact_email: null,
  display_name: null,
  created_at: null,
  updated_at: null,
  deleted_at: null,
  addresses: List([]),
})

const initAddress = Map({
  postal_code: null,
  telephone: null,
  created_at: null,
  name: null,
  sub_district: null,
  district: null,
  province: null,
  updated_at: null,
  deleted_at: null,
  id: null,
  address1: null,
  address2: null,
})

// Initial state
const initialState = Map({
  resellerList: [],
  resellerListGroup: [],
  customerList: [],
  customerListGroup: [],
  // contactList: [],
  // contactListGroup: [],
  selectedContact: initContact,
  editingContact: initContact,

  shouldFetch: false,
})

// Actions
export const ACTION_INITIALIZE = 'ContactState/INITIALIZE'
export const ACTION_CONTACT_CLEAR = 'ContactState/ACTION_CONTACT_CLEAR'
export const ACTION_CONTACT_REVERT = 'ContactState/ACTION_CONTACT_REVERT'
export const ACTION_CONTACT_SELECTED = 'ContactState/ACTION_CONTACT_SELECTED'

export const ACTION_EDITED_CONTACT_CHANGE = 'ContactState/ACTION_EDITED_CONTACT_CHANGE'
export const ACTION_EDITED_CONTACT_REVERT = 'ContactState/ACTION_EDITED_CONTACT_REVERT'
export const ACTION_EDITED_CONTACT_CLEAR = 'ContactState/ACTION_EDITED_CONTACT_CLEAR'

export const ACTION_EDITED_ADDRESSES_CHANGE = 'ContactState/ACTION_EDITED_ADDRESSES_CHANGE'
export const ACTION_EDITED_ADDRESS_DELETE = 'ContactState/ACTION_EDITED_ADDRESS_DELETE'
export const ACTION_EDITED_ADDRESS_ADD = 'ContactState/ACTION_EDITED_ADDRESS_ADD'

// Injected load address from address api
export const LOAD_NEW_CONTACT_ADDRESSES = 'ContactState/LOAD_NEW_CONTACT_ADDRESSES'

export const ACTION_CONTACT_LIST_LOAD = 'ContactState/ACTION_CONTACT_LIST_LOAD'

// export const ACTION_CUSTOMER_ADD_NEW = 'ContactState/ACTION_CUSTOMER_ADD_NEW'
// export const ACTION_CUSTOMER_ADD_EDITING = 'ContactState/ACTION_CUSTOMER_ADD_EDITING'

export const ACTION_SET_SHOULD_FETCH = 'ContactState/ACTION_SET_SHOULD_FETCH'
export const ACTION_RESET_SHOULD_FETCH = 'ContactState/ACTION_RESET_SHOULD_FETCH'

// Refactor
export const ACTION_CUSTOMER_ADD = 'ContactState/ACTION_CUSTOMER_ADD'
export const ACTION_CUSTOMER_ADD_FAILED = 'ContactState/ACTION_CUSTOMER_ADD_FAILED'
export const ACTION_CUSTOMER_EDIT = 'ContactState/ACTION_CUSTOMER_EDIT'
export const ACTION_CUSTOMER_EDIT_FAILED = 'ContactState/ACTION_CUSTOMER_EDIT_FAILED'
export const ACTION_CUSTOMER_DELETE = 'ContactState/ACTION_CUSTOMER_DELETE'
export const ACTION_CUSTOMER_DELETE_FAILED = 'ContactState/ACTION_CUSTOMER_DELETE_FAILED'
export const ACTION_CUSTOMER_LIST_FETCH = 'ContactState/ACTION_CUSTOMER_LIST_FETCH'
export const ACTION_CUSTOMER_LIST_LOAD = 'ContactState/ACTION_CUSTOMER_LIST_LOAD'
export const ACTION_CUSTOMER_LIST_FETCH_FAILED = 'ContactState/CONTACT_LIST_FETCH_FAILED'

// Action creators
export function init() {
  return { type: ACTION_INITIALIZE }
}

// เลือก profile
export function loadContact(profile) {
  return {
    type: ACTION_CONTACT_SELECTED,
    payload: fromJS(profile),
  }
}

// เลือก RESET ค่าว่าง
export function resetContact() {
  return {
    type: ACTION_CONTACT_CLEAR,
  }
}
export function revertContact() {
  return {
    type: ACTION_CONTACT_REVERT,
  }
}

export function clearContact() {
  return {
    type: ACTION_EDITED_CONTACT_CLEAR,
  }
}

// แก้ไขข้อมูล profile field
export function onChangeContact({ key, value }) {
  return {
    type: ACTION_EDITED_CONTACT_CHANGE,
    payload: { key, value },
  }
}

export function onChangeAddresses({ index, key, value }) {
  return {
    type: ACTION_EDITED_ADDRESSES_CHANGE,
    payload: { index, key, value },
  }
}

export function onDeleteAddress({ index }) {
  return {
    type: ACTION_EDITED_ADDRESS_DELETE,
    payload: { index },
  }
}

export function addAddress() {
  return {
    type: ACTION_EDITED_ADDRESS_ADD,
  }
}

export function loadNewContactAddresses(addresses) {
  return {
    type: LOAD_NEW_CONTACT_ADDRESSES,
    payload: isImmutable(addresses) ? addresses : fromJS(addresses),
  }
}

// Saga Action
// export function fetchContactList() {
//   console.log('_shot saga action fetchContactList__actor_: ', CONS.CONTACT_ACTOR_KEYS.CUSTOMER)
//   return {
//     type: actions.CONTACT_LIST_FETCH,
//     payload: CONS.CONTACT_ACTOR_KEYS.CUSTOMER,
//   }

export function fetchCustomerList() {
  // console.log('fetchCustomerList => ')
  return {
    type: ACTION_CUSTOMER_LIST_FETCH,
  }
}

export function addCustomer({ body, successCallback, failedCallback }) {
  return {
    type: ACTION_CUSTOMER_ADD,
    body,
    successCallback,
    failedCallback,
  }
}

export function editCustomer({ body, successCallback, failedCallback }) {
  return {
    type: ACTION_CUSTOMER_EDIT,
    body,
    successCallback,
    failedCallback,
  }
}

export function deleteCustomer({ body, successCallback, failedCallback }) {
  return {
    type: ACTION_CUSTOMER_DELETE,
    body,
    successCallback,
    failedCallback,
  }
}

// export function saveCustomer() {
//   return {
//     type: ACTION_CUSTOMER_ADD_EDITING,
//   }
// }

// export function fetchContactAdd({ actor }) {
//   return {
//     type: actions.CONTACT_ADD_FETCH,
//     payload: { actor },
//   }
// }

export function setShouldFetch() {
  return {
    type: ACTION_SET_SHOULD_FETCH,
  }
}

export function resetShouldFetch() {
  return {
    type: ACTION_RESET_SHOULD_FETCH,
  }
}

// Reducer
export default function ContactStateReducer(state = initialState, action: ActionApiParams = {}) {
  const { type, payload } = action
  let newState = state

  switch (type) {
    case ACTION_INITIALIZE:
    case ACTION_CONTACT_SELECTED:
      newState = newState.set('selectedContact', payload)
      return newState.set('editingContact', payload)
    case ACTION_CONTACT_CLEAR:
      newState = newState.set('selectedContact', initContact)
      return newState.set('editingContact', initContact)
    case ACTION_CONTACT_REVERT:
      return newState.set('editingContact', newState.get('selectedContact'))

    case ACTION_EDITED_CONTACT_CHANGE:
      return newState.setIn(['editingContact', payload.key], payload.value)
    case ACTION_EDITED_CONTACT_REVERT:
      return newState.set('editingContact', state.get('selectedContact'))
    case ACTION_EDITED_CONTACT_CLEAR:
      return newState.set('editingContact', initContact)

    case ACTION_EDITED_ADDRESSES_CHANGE:
      return newState.setIn(['editingContact', 'addresses', payload.index, payload.key], payload.value)
    case ACTION_EDITED_ADDRESS_DELETE:
      return newState.deleteIn(['editingContact', 'addresses', payload.index])
    case ACTION_EDITED_ADDRESS_ADD:
      const firstName = newState.getIn(['editingContact', 'first_name'])
      const lastName = newState.getIn(['editingContact', 'last_name'])
      const addrSize = newState.getIn(['editingContact', 'addresses']).size
      newState = newState.updateIn(['editingContact', 'addresses'], (addr) => addr.push(initAddress))
      return newState.setIn(['editingContact', 'addresses', addrSize, 'name'], `${firstName} ${lastName}`)

    case ACTION_CONTACT_LIST_LOAD:
      newState = newState.set(payload.keys.list, payload.contactList)
      return newState.set(payload.keys.group, payload.contactListGroup)

    case ACTION_CUSTOMER_LIST_LOAD:
      newState = newState.set('customerList', payload.customerList)
      return newState.set('customerListGroup', payload.customerListGroup)

    case ACTION_SET_SHOULD_FETCH:
      return newState.set('shouldFetch', true)
    case ACTION_RESET_SHOULD_FETCH:
      return newState.set('shouldFetch', false)

    // Receive new address from address saga
    case LOAD_NEW_CONTACT_ADDRESSES:
      newState = newState.setIn(['selectedContact', 'addresses'], payload)
      return newState.setIn(['editingContact', 'addresses'], payload)

    default:
      return state
  }
}
