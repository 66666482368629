import * as Yup from 'yup'

// Initialize custom method
function equalTo(ref, msg) {
  // @ts-ignore
  return this.test({
    name: 'equalTo',
    exclusive: false,
    message: msg || '${path} must be the same as ${reference}',
    params: {
      reference: ref.path,
    },
    test(value) {
      return value === this.resolve(ref)
    },
  })
}

Yup.addMethod(Yup.string, 'equalTo', equalTo)

// Util functions
export function isExactMatchToKey(yupSchema: Yup.Schema<any>, refKey: string, txtError: string): Yup.Schema<any> {
  // @ts-ignore method at:: equalTo is add after init Yup
  return yupSchema.equalTo(Yup.ref(refKey), txtError)
}

// example ==> isRequired(FIRSTNAME)
export function isRequired<T>(yupSchema: Yup.Schema<T>): Yup.Schema<T> {
  // @ts-ignore
  return yupSchema.required('จำเป็นต้องระบุ')
}

// Schema constants
export const FIRSTNAME = Yup.string().min(3, 'กรุณาระบุชื่ออย่างน้อย ${min} ตัวอักษร').max(30, 'กรุณาระบุชื่อไม่เกิน ${max} ตัวอักษร')

export const LASTNAME = Yup.string().min(3, 'กรุณาระบุนามสกุลอย่างน้อย ${min} ตัวอักษร').max(30, 'กรุณาระบุนามสกุลไม่เกิน ${max} ตัวอักษร')

export const NICKNAME = Yup.string().min(2, 'กรุณาระบุชื่ออย่างน้อย ${min} ตัวอักษร').max(30, 'กรุณาระบุชื่อไม่เกิน ${max} ตัวอักษร')

export const PHONE = Yup.string().max(20, 'กรุณาระบุชื่อไม่เกิน ${max} ตัวอักษร')

export const REFERAL_ERSELLER_CODE = Yup.string().min(13, 'กรุณาระบุรหัสผู้แนะนำให้ถูกต้อง').max(13, `กรุณาระบุรหัสผู้แนะนำให้ถูกต้อง`)

export const EMAIL = Yup.string().email('กรุณาระบุอีเมลรูปแบบที่ถูกต้อง เช่น xselly@gmail.com')

export const PASSWORD = Yup.string()
  .min(8, 'กรุณาระบุรหัสผ่านอย่างน้อย ${min} ตัวอักษร')
  .max(30, 'กรุณาระบุรหัสผ่านไม่เกิน ${max} ตัวอักษร')
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
    'รหัสผ่านต้องมีอย่างน้อย 8 ตัว โดยประกอบด้วย "ตัวเลข" ตัวอักษรภาษาอังกฤษ "ตัวพิมพ์เล็ก" และ "ตัวพิมพ์ใหญ่" อย่างน้อยอย่างละ 1 ตัว'
  )

  export const PASSWORD_LOGIN = Yup.string()
  .min(8, 'กรุณาระบุรหัสผ่านอย่างน้อย ${min} ตัวอักษร')
  .max(30, 'กรุณาระบุรหัสผ่านไม่เกิน ${max} ตัวอักษร')


export const PASSWORD_CONFIRMATION = isExactMatchToKey(PASSWORD, 'password', 'กรุณายืนยันรหัสผ่านให้ตรงกัน')

export const USERNAME = Yup.string()
  .matches(/^[a-z][a-z0-9_.]*$/, 'กรุณาระบุชื่อผู้ใช้เป็นภาษาอังกฤษพิมพ์เล็ก อย่างน้อย 4 ตัวและไม่มีช่องว่างต่อท้าย')
  .trim('กรุณาระบุชื่อผู้ใช้ที่ไม่มีช่องว่าง')
  .min(4, 'กรุณาระบุชื่อผู้ใช้ ${min} ตัวอักษร')
  .max(20, 'กรุณาระบุชื่อผู้ใช้ไม่เกิน ${max} ตัวอักษร')
  .strict(true)

export const STORE_NAME = Yup.string().min(2, 'กรุณาระบุชื่อร้าน ${min} ตัวอักษร').max(40, 'กรุณาระบุชื่อผร้านไม่เกิน ${max} ตัวอักษร')

export default Yup
