import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { getSelectedStore } from 'x/redux/selectors'
import * as profileActions from 'x/modules/profile/ProfileState'

import ResellerListView from './ResellerListView'

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
    // myStores: getMyStores(state),
  }),
  (dispatch) => {
    return {
      searchMumber: bindActionCreators(profileActions.fetchProfileList, dispatch),
      // fetchInitializeData: bindActionCreators(StoreState.fetchInitializeData, dispatch),
      // fetchMyStore: bindActionCreators(StoreState.fetchMyStore, dispatch),
      dispatch,
    }
  }
)(ResellerListView)
