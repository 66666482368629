import React from 'react'
import { Text, TouchableOpacity } from 'react-native'

import _ from 'lodash'
import * as util from 'x/utils/util'
import BaseProductWarehousesPicker from 'x/modules/product/BaseProductWarehousesPicker'
import XCustomHeader from 'xui/components/XCustomHeader'
import { STYLES, COLORS } from 'x/config/styles'
import XIcon from 'xui/components/XIcon'
import { IWarehousesDetailMap } from 'x/index'
import p from 'x/config/platform-specific'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import { Pressable } from 'native-base'
import HStack from 'xui/components/HStack'
import XText from 'xui/components/XText'

export default abstract class BaseUIProductWarehousesPicker extends BaseProductWarehousesPicker {
  // abstract _renderMain: () => JSX.Element
  // abstract renderImageDocumentUpload: () => JSX.Element
  // abstract goBack: () => void
  // abstract navToToAddressViewFromStoreMyView: (isPrimary: boolean, address?: ISelectedAddresses) => void

  _renderHeaderRight = () => {
    const { isChangeData } = this.state
    // console.log(`isChangeData_renderHeaderRight `, isChangeData)
    if (isChangeData) {
      return (
        <TouchableOpacity
          onPress={() => {
            p.op.showConfirmation(
              ``,
              `ยกเลิกการแก้ไขใช่หรือไม่`,
              () => this.goBack(),
              () => {},
              `ใช่`,
              `แก้ไขต่อ`
            )
          }}>
          <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.APP_MAIN }}>ยกเลิก</Text>
        </TouchableOpacity>
      )
    }
    return null
  }

  _renderHeaderLeft = () => {
    const { isChangeData } = this.state
    if (isChangeData) {
      return (
        <TouchableOpacity onPress={() => this._onPressSubmit()}>
          <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.APP_MAIN }}>ตกลง</Text>
        </TouchableOpacity>
      )
    }
    return (
      <TouchableOpacity onPress={() => this.goBack()}>
        <XIcon family='AntDesign' name='arrowleft' style={{ flex: 0, fontSize: STYLES.FONT_ICON_NORMAL }} />
      </TouchableOpacity>
    )
  }

  renderCustomHeader = () => (
    <XCustomHeader title='เลือกคลัง' headerLeft={this._renderHeaderLeft()} headerRight={this._renderHeaderRight()} />
  )

  _renderbody = () => {
    const { selectedStore } = this.props
    const { warehoueseIds } = this.state
    if (_.isNil(warehoueseIds)) {
      return null
    }
    const warehouses = selectedStore.get('warehouses')
    // const warehouses = selectedStore.has('warehouses')
    // ? isImmutable(selectedStore.get('warehouses'))
    //   ? selectedStore.get('warehouses').toJS()
    //   : selectedStore.get('warehouses')
    // : null
    // console.log(`warehouses _renderbody `, warehouses)
    if (_.isNil(warehouses)) {
      return null
    }
    return (
      <>
        {warehouses.map((warehouse: IWarehousesDetailMap, index: number) => {
          const whId = warehouse.get('id')
          const checked = _.find(warehoueseIds, (id) => id === whId)
          // const name = _.isNil(warehouse.name) ? 'ยังไม่ได้ระบุชื่อคลัง' : warehouse.name
          const name = util.getWarehouseDisplayName(warehouses, warehouse, index)
          return (
            <Pressable flex={1} w='full' key={index.toString()} onPress={() => this._onPressCheckBox(whId)}>
              <HStack flex={1} py='2' px='3' space='2' alignItems='center' borderBottomWidth='1' borderBottomColor='gray.200'>
                <XIcon family='FontAwesome' name={checked ? 'check-square-o' : 'square-o'} />
                <XText variant={checked ? 'active' : 'inactive'}>{name}</XText>
              </HStack>
            </Pressable>
          )
        })}
      </>
    )
  }

  goBack = () => {
    util.navGoBack(this.props)
  }

  _renderMain = () => (
    <XContainer>
      {this.renderCustomHeader()}
      <XContent _light={{ _contentContainerStyle: { bg: 'white' } }}>{this._renderbody()}</XContent>
    </XContainer>
  )

  render() {
    return this._renderMain()
  }
}
