/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/no-unused-state */
import React from 'react'
import { ISelectedStoreMap, IXScreenProps } from 'x/index'
import * as util from 'x/utils/util'
import _ from 'lodash'

interface IBaseStoreWebsiteSelectedOptionModeViewProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
  subscription: any
}

interface IBaseStoreWebsiteSelectedOptionModeViewState {
  // salePage: IStoreWebsite
}

export default abstract class BaseStoreWebsiteSelectedOptionModeView extends React.Component<
  IBaseStoreWebsiteSelectedOptionModeViewProps,
  IBaseStoreWebsiteSelectedOptionModeViewState
> {
  // abstract async handleSubmitForm(response: any): Promise<void>
  // MENU_ITRMS: IImportExcelMenuItem[]

  constructor(props) {
    super(props)
    this.state = {
      // @ts-ignore
      // salePageList: null,
    }
    // this.webview = React.createRef()
  }

  componentDidMount() {
    // const params = util.getNavParams(this.props)
    // const { salePage } = params
  }

  _onPressMode = (mode: 'SINGLE_PRODUCT_MODE' | 'PRODUCT_MULTI_OPTIONS_MODE' | 'PRODUCT_SET_MODE') => {
    const params = util.getNavParams(this.props)
    const { index, callBack } = params
    if (_.isFunction(callBack)) {
      util.navGoBack(this.props)
      callBack(mode, index)
    }
  }
}
